import React from "react";
import styles from "./bid-status-badge.module.scss";
import cx from "classnames";

export default function BidStatusBadge({ status, size = "md" }) {
	let badgeColor = "";
	switch (status) {
		case "Interview Sent":
		case "Invitation Sent":
		case "Invitation Received":
		case "Proposition Sent":
		case "Proposition Received":
			badgeColor = "yellow";
			break;
		case "Invitation Overdue":
		case "Interview Overdue":
		case "Application Cancelled":
		case "Application Rejected":
			badgeColor = "red";
			break;
		case "Interview Planned":
			badgeColor = "blue";
			break;
		default:
			badgeColor = "green";
	}
	return (
		<div
			className={cx(styles.badge, styles[badgeColor], {
				[styles.badge__sm]: size === "sm"
			})}
		>
			<span>{status}</span>
		</div>
	);
}
