import React, { PureComponent } from "react";
import { Field, reduxForm, change } from "redux-form";
import { Element } from "react-scroll";
import { connect } from "react-redux";
import { compose } from "redux";
import _get from "lodash/get";
import {
	FIFTY,
	HUNDRED,
	ONE_HUNDRED_AND_FIFTY,
	ONE_HUNDRED_AND_TWENTY_FIVE,
	TWO_HUNDRED,
	DATE_FORMAT,
	MAX_HOURS_PER_DAY,
	TIMESHEET_TAGS_STATUS,
	CUSTOM,
	OVERTIME_DURATION,
	HOURLY,
	DAILY
} from "config";
import FormField from "common/FormField";
import DatePickerField from "common/DatePickerField.new";
import StyledSelect from "common/StyledSelect";
import toaster from "common/Toaster";
import { Button } from "common/styled/buttons";
import { FloatItem } from "./TimesheetStyled";
import { utcTimeStamp } from "config/helpers";
import {
	timesheetTagsSelectSelector,
	timesheetTagsProjectNameSelector,
	featureTimesheetTagsSelector
} from "../timesheetSelector";

import { DRAFT } from "config";
import get from "lodash/get";

export const rateOptions = val => {
	const options = [
		{
			value: FIFTY,
			label: `${FIFTY}%`
		},
		{
			value: HUNDRED,
			label: `${HUNDRED}%`
		},
		{
			value: ONE_HUNDRED_AND_TWENTY_FIVE,
			label: `${ONE_HUNDRED_AND_TWENTY_FIVE}%`
		},
		{
			value: ONE_HUNDRED_AND_FIFTY,
			label: `${ONE_HUNDRED_AND_FIFTY}%`
		},
		{
			value: TWO_HUNDRED,
			label: `${TWO_HUNDRED}%`
		}
	];
	if (!val) return options;
	return options.find(opt => +opt.value === val);
};
const FORM_NAME = "add-worktime";
class AddWorkTime extends PureComponent {
	handleSubmit = formProps => {
		const {
			timesheetId,
			editItem,
			featureTimesheetTags,
			tagOptions,
			status,
			onComputeTimesheet,
			isOverTime,
			reportingSettings
		} = this.props;
		const payload = { ...formProps };
		const isRateHourly = reportingSettings.rate_type === HOURLY;
		const isCustomDuration = formProps?.duration?.value === CUSTOM;
		featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
			get(tagOptions, "length", 0) > 0 &&
			(payload.tag = get(payload, "tag.value", ""));
		payload.rate =
			typeof payload.rate === "string" ? payload.rate : payload.rate.value;
		const splitedDayHours =
			!isRateHourly && reportingSettings.hours_representation.split(":");

		payload.hours =
			isRateHourly || isCustomDuration || isOverTime
				? formProps.hours.hours() * 60 + formProps.hours.minutes()
				: formProps.duration.value * Number(splitedDayHours[0]) * 60 +
				  Number(splitedDayHours[1]) * formProps.duration.value;
		payload.duration = undefined;
		payload.is_overtime = isOverTime;
		const current = window.moment();

		payload.date.set({
			hour: current.get("hour"),
			minute: current.get("minute"),
			second: current.get("second")
		});
		if (this.beyondMaxHours(payload.date, payload.hours, editItem)) {
			return toaster.danger(
				`It seems that you have exceeded the amount of hours (${MAX_HOURS_PER_DAY}h) allowed in the same day
					(${window.moment
						.unix(
							utcTimeStamp({
								date: payload.date,
								format: DATE_FORMAT,
								manageTime: false
							})
						)
						.format(DATE_FORMAT)}).`,
				{ duration: 10, id: "err" }
			);
		}
		payload.date = utcTimeStamp({
			date: payload.date,
			format: DATE_FORMAT,
			manageTime: false
		});

		if (status === DRAFT) {
			payload.timesheet_id = timesheetId;
			return this.props.onAddWorkTime(payload);
		}
		payload.hours_representation = window
			.moment(formProps.hours)
			.format("HH:mm");
		const { rate, hours } = payload;

		onComputeTimesheet({
			data: {
				timesheet_id: timesheetId,
				dispute_items: {
					worktimes: {
						edited: [],
						added: [
							{
								rate,
								hours,
								is_overtime: isOverTime
							}
						]
					},
					expenses: {
						edited: [],
						added: []
					}
				}
			},
			payload
		});
	};

	beyondMaxHours = (date, hours) => {
		const worktimes = [
			..._get(this.props, "month.worktimes").filter(
				({ _id }) =>
					this.props.disputedWorkTime
						.map(({ worktime_id }) => worktime_id)
						.indexOf(_id) < 0
			),
			...this.props.disputedWorkTime.map(item => ({
				...item,
				hours: item.hours / 60
			})),
			...this.props.addedWorkTimes.map(item => ({
				...item,
				hours: item.hours / 60
			}))
		];
		let total = hours;

		const worktimesInDate = worktimes.filter(
			worktime =>
				worktime.date >
					utcTimeStamp({
						date: date,
						format: DATE_FORMAT,
						manageTime: true
					}) &&
				worktime.date <=
					utcTimeStamp({
						date: date,
						format: DATE_FORMAT,
						manageTime: true,
						isStart: false
					})
		);

		if (!worktimesInDate.length) return false;

		if (worktimesInDate.length === 1) {
			total = total + worktimesInDate[0].hours * 60;
			return total > MAX_HOURS_PER_DAY * 60; // max per day in minutes
		}

		total +=
			worktimesInDate.reduce((acc, curr) => {
				const accVal = typeof acc === "object" ? acc.hours : acc;
				return accVal + curr.hours;
			}) * 60; // total worked hours in minutes
		return total > MAX_HOURS_PER_DAY * 60; // max per day in minutes
	};
	setHoursByDuration = selectedDuration => {
		const { reportingSettings, dispatch } = this.props;
		const splitedDayHours = reportingSettings.hours_representation.split(":");
		const isHoursOdd = Number(splitedDayHours[0]) % 2 === 1;
		const addedMinutes = isHoursOdd && selectedDuration.value === 0.5 ? 30 : 0;
		var amountOfTime = window.moment().utcOffset(0);
		amountOfTime.set({
			hour: selectedDuration.value * Number(splitedDayHours[0]),
			minute:
				selectedDuration.value * Number(splitedDayHours[1]) + addedMinutes,
			second: 0,
			millisecond: 0
		});
		amountOfTime.toISOString();
		amountOfTime.format();
		if (selectedDuration.value === CUSTOM)
			return dispatch(
				change(FORM_NAME, "hours", window.moment(splitedDayHours[0], "hh"))
			);
		dispatch(change(FORM_NAME, "hours", amountOfTime));
	};
	render() {
		const {
			month,
			disabled,
			handleSubmit,
			tagOptions,
			projectName,
			featureTimesheetTags,
			tagOverlayText,
			getInitialVisibleMonth,
			formValues,
			reportingSettings,
			isOverTime
		} = this.props;
		const minDate = window.moment.unix(month.start_date).toDate();
		const maxDate = window.moment.unix(month.end_date).toDate();

		const hasTag =
			featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
			get(tagOptions, "length", 0) > 0;
		const isCustomDuration = formValues?.duration?.value === CUSTOM;

		const width = {
			date: hasTag ? "16%" : isCustomDuration ? "15%" : "20%",
			description: hasTag ? "26%" : isCustomDuration ? "20%" : "30%",
			tag: "20%",
			hours: hasTag ? "15%" : isCustomDuration ? "15%" : "18%",
			rate: hasTag ? "11%" : isCustomDuration ? "15%" : "16%",
			duration: hasTag ? "11%" : isCustomDuration ? "15%" : "16%",
			button: hasTag ? "12%" : isCustomDuration ? "15%" : "16%"
		};
		const isRateDaily = reportingSettings.rate_type === DAILY;
		return (
			<Element name="add-new-box">
				<div className="add-worktime">
					<form>
						<FloatItem width={width.date}>
							<Field
								classes="inner-icon left-icon"
								icon="icon-calendar"
								name="date"
								label="Date"
								placeholder="Date"
								component={DatePickerField}
								initialVisibleMonth={getInitialVisibleMonth}
								minDate={minDate}
								maxDate={maxDate}
								disabled={disabled}
							/>
						</FloatItem>
						<FloatItem width={width.description}>
							<Field
								type="text"
								label="Description"
								placeholder="Description"
								name="description"
								position="bottom"
								disabled={disabled}
								component={FormField}
								autoComplete="off"
							/>
						</FloatItem>
						{hasTag && (
							<FloatItem width={width.tag} labelMarginBottom={"-2px"}>
								<Field
									placeholder={projectName || "TAG"}
									name="tag"
									label={projectName || "TAG"}
									disabled={disabled}
									options={tagOptions}
									toolTipOverlay={tagOverlayText}
									position="bottom"
									component={StyledSelect}
								/>
							</FloatItem>
						)}
						{isRateDaily && !isOverTime && (
							<FloatItem width={width.duration}>
								<Field
									placeholder="Duration"
									name="duration"
									label="Amount of time"
									disabled={disabled}
									options={OVERTIME_DURATION}
									position="bottom"
									component={StyledSelect}
									style={{ width: "100%" }}
									onChange={this.setHoursByDuration}
								/>
							</FloatItem>
						)}
						{(isCustomDuration || !isRateDaily || isOverTime) && (
							<FloatItem width={width.hours}>
								<Field
									name="hours"
									label="Amount of hours"
									component={FormField}
									defaultValue={
										isOverTime
											? window.moment("1", "hh")
											: window.moment("8", "hh")
									}
									disabled={disabled}
									type="timepicker"
								/>
							</FloatItem>
						)}
						<FloatItem width={width.rate}>
							<Field
								placeholder="Rate"
								name="rate"
								label="Rate"
								disabled={disabled}
								options={rateOptions()}
								position="bottom"
								component={StyledSelect}
								defaultValue={rateOptions(100)}
								tabIndex={0}
								style={{ width: "100%" }}
							/>
						</FloatItem>
						<FloatItem padding={"0"} width={width.button}>
							<Button
								onClick={handleSubmit(this.handleSubmit)}
								disabled={disabled}
								block
								size={14}
								style={{ marginTop: 23 }}
							>
								Add new
							</Button>
						</FloatItem>
					</form>
				</div>
			</Element>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.date) {
		errors.date = "Field can't be empty";
	}
	if (!formProps.hours) {
		errors.hours = "Field can't be empty";
	}
	if (!formProps.duration) {
		errors.duration = "Field can't be empty";
	}
	if (!formProps.description || formProps.description.trim() === "") {
		errors.description = "Field can't be empty";
	}
	if (!formProps.rate) {
		errors.rate = "Field can't be empty";
	}
	if (!formProps.tag) {
		errors.tag = "Field can't be empty";
	}
	return errors;
};

const mapStateToProps = state => {
	return {
		editItem: state.timesheet.editItem,
		featureTimesheetTags: featureTimesheetTagsSelector(state),
		tagOptions: timesheetTagsSelectSelector(state),
		projectName: timesheetTagsProjectNameSelector(state),
		formValues: state.form[FORM_NAME]?.values
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FORM_NAME,
		validate,
		touchOnBlur: false
	})
)(AddWorkTime);
