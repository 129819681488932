import React from "react";
import { SectionBanner } from "./styled";
import { ReactComponent } from "./icons/expectations.svg";
function PreferencesBanner({
	onClose,
	displayPreferencesBanner,
	tabIndex,
	isClient
}) {
	const tabs = tabIndex === 1 || tabIndex === 2;

	return (
		<>
			{displayPreferencesBanner && tabs && !isClient && (
				<SectionBanner>
					<ReactComponent />
					<div className="content-banner">
						In the first part, enter your place of residence, as well as every
						city (in the world!) where you’d be interested in working. The
						second part is related to your expectations and availabilities;
						provide as many details as possible.
					</div>
					<span className="icon-close2" onClick={onClose} />
				</SectionBanner>
			)}
		</>
	);
}

export default PreferencesBanner;
