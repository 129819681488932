import { TIME_FORMAT_24, TIMEZONE, DATETIME_FORMAT } from "config";
import { TEMP_INTERVIEW_LOCATION_TYPES } from "modules/RequestView/utils/constants";

export const getProposeTempInterviewPayload = (
	{ startTime, endTime, attendees, day, note, locationType, customAddress },
	{ bidId, companyAddress, interviewId }
) => {
	let people = attendees[0].children.map(({ id }) => {
		return id;
	});

	const momentDay = convertDate(day);
	const startDate = window.moment(momentDay.clone()).tz(TIMEZONE);
	const endDate = window.moment(momentDay.clone()).tz(TIMEZONE);
	const [startTimeHour, startTimeMinute] = startTime
		.format(TIME_FORMAT_24)
		.split(":");

	const interview_start_date = startDate
		.set({
			hour: startTimeHour,
			minute: startTimeMinute
		})
		.utc()
		.unix();

	const [endTimeHour, endTimeMinute] = endTime
		.format(TIME_FORMAT_24)
		.split(":");
	const interview_end_date = endDate
		.set({
			hour: endTimeHour,
			minute: endTimeMinute
		})
		.utc()
		.unix();

	let payload = {
		id: interviewId,
		application_id: bidId,
		bid_id: bidId,
		recipient_type: "freelancer",
		attendees_users: people,
		description: note,
		interview_start_date,
		interview_end_date,
		location_type: locationType
	};

	if (locationType === TEMP_INTERVIEW_LOCATION_TYPES.searchAddress) {
		payload = {
			...payload,
			...customAddress
		};
	} else if (locationType === TEMP_INTERVIEW_LOCATION_TYPES.companyAddress) {
		payload = {
			...payload,
			...companyAddress
		};
	}

	delete payload.start_date;

	return payload;
};

export const getDeclineTempInterviewPayload = (
	{ startTime, endTime, attendees, day, note, locationType, customAddress },
	{ bidId, companyAddress, interviewId }
) => {
	let people = attendees[0].children.map(({ id }) => {
		return id;
	});

	const momentDay = convertDate(day);
	const startDate = window.moment(momentDay.clone()).tz(TIMEZONE);
	const endDate = window.moment(momentDay.clone()).tz(TIMEZONE);
	const [startTimeHour, startTimeMinute] = startTime
		.format(TIME_FORMAT_24)
		.split(":");

	const interview_start_date = startDate
		.set({
			hour: startTimeHour,
			minute: startTimeMinute
		})
		.utc()
		.unix();

	const [endTimeHour, endTimeMinute] = endTime
		.format(TIME_FORMAT_24)
		.split(":");
	const interview_end_date = endDate
		.set({
			hour: endTimeHour,
			minute: endTimeMinute
		})
		.utc()
		.unix();

	let payload = {
		interview_id: interviewId,
		application_id: bidId,
		bid_id: bidId,
		recipient_type: "freelancer",
		attendees_users: people,
		description: note,
		new_interview_start_date: interview_start_date,
		new_interview_end_date: interview_end_date,
		location_type: locationType
	};

	if (locationType === TEMP_INTERVIEW_LOCATION_TYPES.searchAddress) {
		payload = {
			...payload,
			...customAddress
		};
	} else if (locationType === TEMP_INTERVIEW_LOCATION_TYPES.companyAddress) {
		payload = {
			...payload,
			...companyAddress
		};
	}

	delete payload.start_date;

	return payload;
};

const convertDate = value => {
	return window.moment(
		`${value.day}-${value.month}-${value.year}`,
		"DD-MM-YYYY"
	);
};

export const getProposeTempInterviewFormDefaultValues = interview => {
	const locationType = interview.location_type;
	let customAddress;

	if (locationType === "search_address") {
		customAddress = formatVacancyAddress(getVacancyAddress(interview));
	}

	const defaultStartDate = window.moment().add(5, "minute");
	const defaultEndDate = window.moment().add(65, "minute");

	const attendees = [
		{
			id: 1,
			label: "Collaborators",
			children:
				interview?.attendees_users?.map(({ _id, first_name, last_name }) => {
					return {
						id: _id,
						label: `${first_name} ${last_name}`
					};
				}) || []
		}
	];

	return {
		locationType: locationType,
		customAddress,
		startTime: window.moment.tz(
			`${defaultStartDate.format(DATETIME_FORMAT)}`,
			DATETIME_FORMAT,
			TIMEZONE
		),
		endTime: window.moment.tz(
			`${defaultEndDate.format(DATETIME_FORMAT)}`,
			DATETIME_FORMAT,
			TIMEZONE
		),
		attendees
	};
};

const getVacancyAddress = vacancy => {
	const {
		street,
		number,
		box,
		city,
		zip,
		country,
		latitude,
		iso_country,
		company_street,
		company_number,
		company_box,
		company_city,
		company_zip,
		company_country,
		company_longitude,
		company_iso_country,
		longitude,
		company_latitude
	} = vacancy;

	return {
		street,
		number,
		box,
		city,
		zip,
		country,
		latitude,
		iso_country,
		company_street,
		company_number,
		company_box,
		company_city,
		company_zip,
		company_country,
		company_longitude,
		company_iso_country,
		longitude,
		company_latitude
	};
};

const formatVacancyAddress = ({
	street,
	number,
	box,
	city,
	zip,
	country,
	latitude,
	iso_country,
	company_street,
	company_number,
	company_box,
	company_city,
	company_zip,
	company_country,
	company_longitude,
	company_latitude,
	company_iso_country,
	longitude
}) => {
	return {
		street: street || company_street,
		number: number || company_number,
		box: box || company_box,
		city: city || company_city,
		zip: zip || company_zip,
		country: country || company_country,
		longitude: longitude || company_longitude,
		latitude: latitude || company_latitude,
		iso_country: iso_country || company_iso_country
	};
};
