import React, { useState } from "react";
import {
	Header,
	Body
} from "./components/AddCandidateDrawerCompound/AddCandidateDrawerCompound";
import loadable from "loadable-components";
import useGetFiles from "./hooks/useGetFiles";
import {
	PARCELS_LIST_ID,
	PARCEL_DETAILS_ID,
	SINGLE_CANDIDATE_TAB_ID
} from "./utils/constants";
import ParcelListLoadingState from "common/ParcelsList/components/ParcelListLoadingState/ParcelListLoadingState";
import ParcelLoadingState from "common/ParcelDetails/components/ParcelLoadingState/ParcelLoadingState";

const AddCandidateTabs = loadable(() =>
	import("./components/AddCandidateTabs/AddCandidateTabs")
);

const AddCandidateDrawerCompound = loadable(() =>
	import("./components/AddCandidateDrawerCompound/AddCandidateDrawerCompound")
);

const SingleCandidate = loadable(() =>
	import("common/AddCandidateDrawer/SingleCandidate/SingleCandidate")
);

const BulkUploadFiles = loadable(() =>
	import("common/AddCandidateDrawer/BulkUploadFiles/BulkUploadFiles")
);

const ParcelsList = loadable(() => import("common/ParcelsList/ParcelsList"), {
	LoadingComponent: ParcelListLoadingState
});

const ParcelDetails = loadable(
	() => import("common/ParcelDetails/ParcelDetails"),
	{ LoadingComponent: ParcelLoadingState }
);

const AddCandidateDrawer = ({ onClose, company_id, jobId, stepId }) => {
	const {
		activeTab,
		drawerActiveBody,
		setDrawerActiveBody,
		currentParcelId
	} = useGetFiles();
	const [hasBackButton, setHasBackButton] = useState(false);

	const renderBodyContent = () => {
		switch (drawerActiveBody) {
			case PARCEL_DETAILS_ID:
				return (
					<ParcelDetails currentParcelId={currentParcelId} onClose={onClose} />
				);
			case PARCELS_LIST_ID:
				return (
					<ParcelsList setHasBackButton={setHasBackButton} onClose={onClose} />
				);
			default:
				return (
					<>
						<AddCandidateTabs />
						{activeTab === SINGLE_CANDIDATE_TAB_ID ? (
							<SingleCandidate
								onClose={onClose}
								company_id={company_id}
								jobId={jobId}
								stepId={stepId}
							/>
						) : (
							<BulkUploadFiles
								onClose={onClose}
								jobId={jobId}
								stepId={stepId}
							/>
						)}
					</>
				);
		}
	};

	return (
		<AddCandidateDrawerCompound isOpen={true} onClose={onClose}>
			<Header
				onClose={onClose}
				hasBackButton={hasBackButton}
				onBack={() => {
					setHasBackButton(false);
					setDrawerActiveBody(PARCELS_LIST_ID);
				}}
			/>
			<Body
				style={
					drawerActiveBody === PARCELS_LIST_ID ||
					drawerActiveBody === PARCEL_DETAILS_ID
						? "styles.parcelStyle"
						: ""
				}
			>
				{renderBodyContent()}
			</Body>
		</AddCandidateDrawerCompound>
	);
};

export default AddCandidateDrawer;
