import React from "react";
const UnSortDown = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.99992 3.3335L7.99992 4.46428M7.99992 12.6668L3.33325 8.00016M7.99992 12.6668L7.99992 7.12843M7.99992 12.6668L10.3333 10.3335M12.6666 8.00016L12.1956 8.47111"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.2195 12.0059L4.16357 2.94995"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default UnSortDown;
