import React, { memo } from "react";
import get from "lodash/get";
export const EmptyStateCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/EmptyStateCell/EmptyStateCell"
	)
);
import { ReactComponent as InfoIcon } from "static/icons/alert-circle.svg";
import { Flex } from "rebass";
import Tippy from "@tippyjs/react";
import styles from "./styles.module.scss";
import loadable from "loadable-components";

const PillRenderer = ({ pillConfig, step, tooltipContent }) => {
	return (
		<>
			{get(pillConfig, "label", "") ? (
				<Flex alignItems={"center"} justifyContent={"center"} gap={".5rem"}>
					<div
						className={styles.pill}
						style={{
							color: get(pillConfig, "color", ""),
							border: `1px solid ${get(pillConfig, "borderColor", "")}`
						}}
					>
						{get(pillConfig, "label", "")}
					</div>
					{(tooltipContent || step) && (
						<Tippy
							content={
								tooltipContent ||
								`This candidate is currently in
							the ${step} stage.`
							}
						>
							<InfoIcon className={styles.icon} />
						</Tippy>
					)}
				</Flex>
			) : (
				<EmptyStateCell />
			)}
		</>
	);
};

export default memo(PillRenderer);
