import PropTypes from "prop-types";
import React from "react";
import InvitationTitle from "./components/InvitationTitle";
import InterviewBody from "./components/InvitationBody";
import { StyledModal } from "./styles/styled";
import { EXPIRED } from "config";
import _get from "lodash/get";
import AttendeesList from "./components/AttendeesList";

const InterviewInvitation = props => {
	const {
		onAccept,
		onPropose,
		onCloseModal,
		canDisplayModal,
		interview,
		firstName,
		lastName
	} = props;
	const status = _get(interview, "status");
	const attendeesUsers = _get(interview, "attendees_users", []);
	const disbledAction = status === EXPIRED;
	const acceptBtn = {
		label: "Accept",
		action: onAccept,
		className: "accept-btn",
		type: !disbledAction && "primary",
		disabled: disbledAction
	};

	const proposeBtn = {
		label: "Propose another date/time",
		action: onPropose,
		className: "propose-btn",
		type: disbledAction && "outlined"
	};
	return (
		<StyledModal
			active={canDisplayModal}
			onClose={onCloseModal}
			firstButton={disbledAction ? proposeBtn : acceptBtn}
			secondButton={!disbledAction && proposeBtn}
			showSecondButton={!disbledAction && true}
			title="Interview Invitation"
			showActionFloated={true}
		>
			{disbledAction && (
				<p>
					An interview invitation was attached to the poke. But the date/time of
					that interview is now overdue. Please use the button below to propose
					another date for the interview.
				</p>
			)}
			<InvitationTitle firstName={firstName} lastName={lastName} />
			<InterviewBody interview={interview} />
			<AttendeesList
				attendeesUsers={attendeesUsers}
				description={_get(interview, "description", "")}
			/>
		</StyledModal>
	);
};

InterviewInvitation.propTypes = {
	canDisplayModal: PropTypes.bool,
	onCloseModal: PropTypes.func,
	onPropose: PropTypes.func,
	onAccept: PropTypes.func
};
export default InterviewInvitation;
