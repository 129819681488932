import React, { useState } from "react";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";
import cx from "classnames";
import { ReactComponent as MenuIcon } from "static/icons/actions-icon.svg";
import classnames from "./actions.module.scss";
import useOnClickOutside from "hooks/useOnClickOutside";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import Portal from "common/Portal";

const Actions = ({ file, handleDelete, creator, isDeletingFile }) => {
	const [openMenu, setOpenMenu] = useState(false);
	const [openConfirmationModal, setOpenComfirmationModal] = useState(false);
	const [menuTargetRef, setMenuTargetRef] = useState(false);
	const [menuElementRef, setMenuElementRef] = useState(false);
	const [arrowElementRef, setArrowElementRef] = useState(false);
	const connectedUser = useSelector(onlineUserSelector);

	const { styles, attributes } = usePopper(menuTargetRef, menuElementRef, {
		enabled: true,
		eventsEnabled: true,
		placement: "top",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, -10]
				}
			},
			{ name: "arrow", options: { element: arrowElementRef } }
		]
	});

	useOnClickOutside({ current: menuElementRef }, () => {
		setOpenMenu(false);
	});
	const handleOpenMenu = async () => {
		setOpenMenu(true);
	};

	const handleSetModal = value => () => setOpenComfirmationModal(value);

	const canDelete = creator._id === connectedUser.id;

	return (
		<div className={cx(classnames.container)}>
			<button ref={setMenuTargetRef} onClick={handleOpenMenu}>
				<MenuIcon className={classnames.icon} />
			</button>
			{openMenu && (
				<React.Fragment>
					<div className={classnames.backdrop}></div>
					<div ref={setArrowElementRef} style={styles.arrow}></div>
					<Portal>
						<div
							ref={setMenuElementRef}
							className={classnames.menuItems}
							style={styles.popper}
							{...attributes.popper}
						>
							<button
								onClick={() => window.open(file.path, "_blank")}
								className={classnames.menuItem}
							>
								<span> Preview file</span>
							</button>
							<button className={classnames.menuItem}>
								<a href={file.path}>Download</a>
							</button>

							{canDelete && !file.is_primary && (
								<button
									className={classnames.menuItem}
									onClick={handleSetModal(true)}
								>
									<span>Delete</span>
								</button>
							)}
						</div>
					</Portal>
				</React.Fragment>
			)}
			<ConfirmationModal
				key="modal"
				title={`Are you sure you want to delete this file?`}
				onClose={handleSetModal(false)}
				type={ConfirmationTypes.error}
				firstButton={{
					action: handleDelete,
					label: `YES, DELETE`,
					type: "danger"
				}}
				loading={isDeletingFile}
				active={openConfirmationModal}
			/>
		</div>
	);
};

export default Actions;
