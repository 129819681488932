import React from "react";
import Tooltip from "rc-tooltip";
import get from "lodash/get";
import ChannelCard from "../channelCard";

const PublishVacancyContractsList = ({
	list,
	isContractCanBeUsedForPublish,
	isContractSelected,
	onContractCheckboxChange
}) => {
	return list.map(item => {
		const isContractCantBeUsed = !isContractCanBeUsedForPublish(item);

		const card = (
			<div>
				<ChannelCard
					key={item.contract_id}
					title={get(item, "product.title")}
					channel={item}
					checked={isContractSelected(item.contract_id)}
					handleCheck={() => onContractCheckboxChange(item)}
					disabled={isContractCantBeUsed}
				/>
			</div>
		);

		return isContractCantBeUsed ? (
			<Tooltip
				overlay={
					<span>
						You can&#39;t select this contract because it belong to a different
						group
					</span>
				}
				placement="top"
				trigger="hover"
			>
				{card}
			</Tooltip>
		) : (
			card
		);
	});
};
export default PublishVacancyContractsList;
