import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonComponent = ({
	borderRadius,
	height,
	width,
	color = "#dfe3e8"
}) => (
	<SkeletonTheme style={{ borderRadius }} color={color}>
		<Skeleton width={width} height={height} />
	</SkeletonTheme>
);

export default SkeletonComponent;
