import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styles from "./bid-details-drawer-temp-notes-loading.module.scss";

const BidDetailsDrawerTempNotesLoading = () => {
	return (
		<div className={styles.container}>
			{[1, 2, 3, 4, 6, 7, 8, 9].map(key => (
				<div key={key}>
					<SkeletonTheme
						style={{ borderRadius: 10, marginRight: 16 }}
						color="#dfe3e8"
					>
						<Skeleton width={"50%"} height={16} />
					</SkeletonTheme>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={"100%"} height={16} />
					</SkeletonTheme>
				</div>
			))}
		</div>
	);
};

export default BidDetailsDrawerTempNotesLoading;
