import React from "react";
import FormGroup from "modules/TimesheetTags/components/timesheetReporter/form/formGroup/FormGroup";
import style from "./form.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ToolTip from "common/GlobalTooltip";

const options = [
	{ value: "daily", label: "Daily" },
	{ value: "hourly", label: "Hourly" }
];
const Form = ({ data, isSaving, saveHandler, currencies }) => {
	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
		reset
	} = useForm({
		mode: "onBlur",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false
	});
	const rate = watch("rate");

	return (
		<div className={style.formContainer}>
			<h2 className={style.titleForm}>
				Rate preferences
				<ToolTip
					placement={"right"}
					maxWidth={"300px"}
					align={{
						offset: [15, 0]
					}}
					overlay="Define your company's preferences in terms of rates (daily or hourly) for bids (applications) and timesheets reporting."
				/>
			</h2>
			<form className={style.form} onSubmit={handleSubmit(saveHandler)}>
				<FormGroup
					options={options}
					errors={errors}
					control={control}
					defaultInput={data}
					currencies={currencies}
					rate={rate}
					reset={reset}
				/>

				<div>
					<button disabled={isSaving} className={style.saveBtn} type="submit">
						{isSaving ? `Is Saving ...` : `Save`}
					</button>
				</div>
			</form>
		</div>
	);
};

const schema = yup.object().shape({
	rate: yup.object().required("Please choose a rate type"),
	hours: yup.date().when("rate.value", {
		is: "daily",
		then: yup
			.date()
			.required("Please type hour")
			.test("oneOfRequired", "OPlease type hour", function(item) {
				return (
					window.moment(item).hours() !== 0 ||
					window.moment(item).minutes() !== 0
				);
			})
	}),
	submission: yup.boolean()
});
export default Form;
