import React, { useMemo, useState } from "react";
import styles from "./messaging-tool-new-email-placeholders.module.scss";
import { CustomDropDown } from "common/CustomDropDown";
import capitalize from "lodash/capitalize";
import { INSERT_EMAIL_PLACEHOLDER_COMMAND } from "common/Editor/plugin/EmailPlaceholderPlugin";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";
import Tooltip from "common/Tippy";

export default function MessagingToolNewEmailPlaceholders({
	header,
	editor,
	placeholders,
	isLoading,
	containerClassName
}) {
	const options = useMemo(() => {
		return Object.keys(placeholders).map(p => ({
			label: capitalize(p),
			value: p
		}));
	}, [placeholders]);
	const [entity, setEntity] = useState(null);

	const entityValue = entity ?? options[0]?.value;
	const entityObject = placeholders[entityValue] ?? {};

	return (
		<div className={cx(styles.container, containerClassName)}>
			{isLoading ? (
				<LoadingComponent />
			) : (
				<>
					{header}
					<div className={styles.body}>
						<div className={styles.note}>
							Please make sure to select an entity from the dropdown below
						</div>
						<div className={styles.selectContainer}>
							<CustomDropDown
								inputClassName={styles.select}
								onChange={setEntity}
								value={entityValue}
								placeHolder="Select an entity"
								optionClassName={styles.option}
								labelClassName={styles.optionLabel}
								optionsWrapperClassName={styles.optionsWrapper}
								options={options}
							/>
						</div>
						<div className={styles.placeholders}>
							{Object.entries(entityObject).map(([key, value]) => (
								<Tooltip
									key={key}
									overflow="hidden"
									content={`[${entityValue}.${key}]`}
									theme="dark"
								>
									<button
										className={styles.placeholderButton}
										type="button"
										onClick={() => {
											editor.dispatchCommand(
												INSERT_EMAIL_PLACEHOLDER_COMMAND,
												value
											);
										}}
									>
										{"["}
										{entityValue}.{key}
										{"]"}
									</button>
								</Tooltip>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

const LoadingComponent = () => {
	return (
		<div className={styles.skeleton}>
			<div className={styles.note}>
				<Skeleton height={10} />
				<Skeleton width={100} height={10} />
			</div>
			<div className={styles.selectContainer}>
				<Skeleton width={248} height={40} />
			</div>
			<div className={styles.placeholders}>
				{Array(5)
					.fill(0)
					.map(item => (
						<Skeleton
							key={item}
							width={96}
							height={36}
							className={styles.skeletonPlaceholder}
						/>
					))}
			</div>
		</div>
	);
};
