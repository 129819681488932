import { createSelector } from "reselect";
import _get from "lodash/get";

const getEmailNotificationsSettings = state =>
	_get(state, "notifications.receive_all_emails");
const getRealTimeNotificationsSettings = state =>
	_get(state, "notifications.show_notification_in_real_time");
const getEmailMatchingSelector = state =>
	_get(state, "notifications.receive_matching_jobs_emails");

const getEmailMatchingPeriodSelector = state =>
	_get(state, "notifications.receive_matching_jobs_emails_period");
const getAction = state => state.notifications.inAction;
const getMissionNotifications = state =>
	_get(state, "notifications.data.mission_notifications");
const getNotificationsView = state => _get(state, "notifications.view");
const getNotificationsData = state => _get(state, "notifications.data");
const getSystemNotifications = state =>
	_get(state, "notifications.data.system_notifications");

export const realTimeNotificationsSelector = createSelector(
	getRealTimeNotificationsSettings,
	show_notification_in_real_time => show_notification_in_real_time
);

export const emailNotificationsSelector = createSelector(
	getEmailNotificationsSettings,
	receive_all_emails => receive_all_emails
);
export const emailMatchingSelector = createSelector(
	getEmailMatchingSelector,
	receive_matching_jobs_emails => receive_matching_jobs_emails
);

export const emailMatchingPeriodSelector = createSelector(
	getEmailMatchingPeriodSelector,
	receive_matching_jobs_emails_period => receive_matching_jobs_emails_period
);

export const missionNotificationsSelector = createSelector(
	getMissionNotifications,
	mission_notifications => mission_notifications
);

export const notificationsViewSelector = createSelector(
	getNotificationsView,
	view => view
);

export const notificationsDataSelector = createSelector(
	getNotificationsData,
	data => data
);

export const systemNotificationsSelector = createSelector(
	getSystemNotifications,
	system_notifications => system_notifications
);

export const actionSelector = createSelector(getAction, inAction => inAction);
