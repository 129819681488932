import React, { useEffect } from "react";
import Select from "react-select";
import _get from "lodash/get";
import { WORKTIME, EXPENSE, FILES, DRAFT, APPROVED, OVERTIME } from "config";
import {
	Switcher,
	SwitcherContainer,
	ClearBoth
} from "common/styled/CommonStyled";
import DownloadTimesheet from "./DownloadTimesheet";
import { TRACK_TIMESHEET_EDITION } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";
import { Flex, Box, Text } from "rebass";
import { ACCEPTED } from "config";
import Toaster from "modules/timesheets/components/MultiAction/toaster";
import { ReactComponent as InfoTimeSheet } from "static/icons/info_timesheet.svg";

const unLockTimesheet = timesheetId => {
	return client(TRACK_TIMESHEET_EDITION, {
		body: {
			timesheet_id: timesheetId,
			action: "unlock"
		}
	});
};

const ViewSwitcher = ({
	timesheet,
	view,
	status,
	disputedWorktimeLength,
	disputedExpensesLength,
	disputedOvertimeLength,
	requiredFilesLength,
	requiredFilesEmpty,
	submittedFilesLength,
	submittedAttachmentsLength,
	switchView,
	value,
	filterList,
	isClient,
	isConsultantAdmin,
	timesheetTagName,
	tags,
	timesheetId,
	reportingSetting
}) => {
	const [onUnlockTimesheet] = useMutation(unLockTimesheet);
	useEffect(() => {
		!isClient &&
			status !== DRAFT &&
			status !== ACCEPTED &&
			onUnlockTimesheet(timesheetId);
	}, []);

	const optionsFormatter = options => {
		return (options || []).map(item => ({
			value: item,
			label: item
		}));
	};

	const getTags = () => {
		let tagsList = [];
		if (tags) {
			tagsList = [{ label: "ALL", value: "all" }, ...optionsFormatter(tags)];
		}

		return tagsList;
	};

	return (
		<div id="timesheet-table" className="detail-switcher">
			<div style={{ height: "auto" }} className="container">
				<Flex alignItems="center" justifyContent="space-between">
					<SwitcherContainer bordred={status === DRAFT}>
						<Switcher
							checked={view === WORKTIME}
							onClick={() => switchView(WORKTIME)}
						>
							Worktime{" "}
							{disputedWorktimeLength > 0 && (
								<div className="primaryCircle">{disputedWorktimeLength}</div>
							)}
						</Switcher>
						{reportingSetting.allow_overtime && (
							<Switcher
								checked={view === OVERTIME}
								onClick={() => switchView(OVERTIME)}
							>
								Overtime{" "}
								{disputedOvertimeLength > 0 && (
									<div className="primaryCircle">{disputedOvertimeLength}</div>
								)}
							</Switcher>
						)}
						<Switcher
							checked={view === EXPENSE}
							onClick={() => switchView(EXPENSE)}
						>
							Expenses{" "}
							{disputedExpensesLength > 0 && (
								<div className="primaryCircle">{disputedExpensesLength}</div>
							)}
						</Switcher>
						{!isConsultantAdmin &&
							((status === DRAFT && !requiredFilesEmpty) ||
								submittedFilesLength !== 0 ||
								submittedAttachmentsLength !== 0) && (
								<Switcher
									checked={view === FILES}
									onClick={() => switchView(FILES)}
								>
									Files{" "}
									{requiredFilesLength !== 0 ? (
										<div className="primaryCircle">{requiredFilesLength}</div>
									) : (
										<div style={{ display: "inline" }}>
											({submittedFilesLength + submittedAttachmentsLength})
										</div>
									)}
								</Switcher>
							)}
						{isConsultantAdmin &&
							(submittedFilesLength !== 0 ||
								submittedAttachmentsLength !== 0) && (
								<Switcher
									checked={view === FILES}
									onClick={() => switchView(FILES)}
								>
									Files{" "}
									{(submittedFilesLength !== 0 ||
										submittedAttachmentsLength !== 0) && (
										<div style={{ display: "inline" }}>
											({submittedFilesLength + submittedAttachmentsLength})
										</div>
									)}
								</Switcher>
							)}
						{isClient && tags.length > 0 && (
							<div className="tags-filter">
								<label>{timesheetTagName}</label>
								<Select
									searchable={true}
									clearable={false}
									options={getTags()}
									placeholder={timesheetTagName}
									value={value}
									onChange={filterList}
								/>
							</div>
						)}
					</SwitcherContainer>
					{status === APPROVED && (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								marginTop: "10px",
								width: "100%"
							}}
						>
							<DownloadTimesheet timesheet={timesheet} />
						</div>
					)}
					{status !== APPROVED && reportingSetting.allow_overtime && (
						<Box mr={10} sx={{ width: "80%" }}>
							<Toaster
								icon={<InfoTimeSheet />}
								toasterBackground={"#FFF2F2"}
								padding="10px"
								border="1px solid #FD6950"
								height="37px"
							>
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontStyle: "normal",
										fontWeight: 600,
										fontsize: "14px",
										lineheight: "34px",
										color: "#FD6950"
									}}
								>
									{_get(timesheet, "client.name", "")} has authorized overtime
									in your timesheet
								</Text>
							</Toaster>
						</Box>
					)}
				</Flex>
			</div>
			<ClearBoth />
		</div>
	);
};

export default ViewSwitcher;
