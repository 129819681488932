import Immutable from "seamless-immutable";
import * as types from "../actions/actionTypes";
import { FRONT, BACK } from "config";

const initialState = Immutable({
	isFetching: false,
	currentCompany: {},
	submitted: false,
	showModal: false,
	submitting: false,
	uploadMethod: {
		files: [
			{
				name: FRONT,
				file: "",
				done: false
			},
			{ name: BACK, file: "", done: false }
		],
		method: null,
		isWebcam: false,
		success: false
	}
});

export default function companyReducer(state = initialState, action) {
	switch (action.type) {
		case types.SWITCHED_COMPANY:
			return Immutable.merge(state, { currentCompany: action.company });
		case types.COMPANY_UPDATE_AVATAR:
			const company = action.company;
			company.avatar = action.avatar;
			return Immutable.merge(state, company);
		case types.FLIP_SUBMITTED:
			return Immutable.merge(state, {
				submitted: true
			});
		case types.TOGGLE_SUBMITTING:
			return Immutable.merge(state, {
				submitting: !state.submitting
			});
		case types.SAVE_CURRENT_COMPANY:
			return Immutable.merge(state, {
				currentCompany: action.data
			});
		case types.SWITCH_UPLOADER_METHOD:
			return Immutable.merge(state, {
				uploadMethod: {
					...initialState.uploadMethod,
					method: action.method,
					isWebcam: action.isWebcam
				}
			});

		case types.SET_UPLOADED_FILES:
			return Immutable.merge(state, {
				uploadMethod: {
					method: action.reset ? null : state.uploadMethod.method,
					isWebcam: action.reset ? false : state.uploadMethod.isWebcam,
					files: action.files,
					success: action.success
				}
			});
		default:
			return state;
	}
}
