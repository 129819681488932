import * as types from "../actions/actionTypes";
import immutable from "seamless-immutable";

const InitialState = {
	loading: false,
	loaded: false,
	data: null,
	error: null,
	identityModal: false
};

export default function jobReducer(state = InitialState, action) {
	switch (action.type) {
		case types.FETCH_JOB:
			return Object.assign({}, state, {
				...InitialState,
				loading: true
			});
		case types.FETCH_JOB_SUCCEEDED:
			return Object.assign({}, state, {
				...InitialState,
				data: action.data
			});
		case types.FETCH_JOB_FAILED:
			return Object.assign({}, state, {
				...InitialState,
				error: action.error
			});
		case types.TOGGLE_IDENTITY_MODAL:
			return immutable.merge(state, {
				identityModal: !state.identityModal
			});
		default:
			return state;
	}
}
