import React, { useState } from "react";
import styles from "./bid-details-processes-toggle.module.scss";
import { ReactComponent as Arrow_down } from "static/icons/arrow-down-new.svg";

const BidDetailsProcessesToggle = ({
	title,
	value,
	children,
	isOpen = false
}) => {
	const [isContentVisible, setIsContentVisible] = useState(isOpen);

	const toggle = () => {
		setIsContentVisible(bool => !bool);
	};

	if (title)
		return (
			<div className={styles.container}>
				<div className={styles.header} onClick={toggle}>
					<div className={styles.leftHeader}>
						<span className={styles.headerTitle}>{title}</span>
						{!isContentVisible && value !== undefined && (
							<span className={styles.badge}>{value}</span>
						)}
					</div>
					<Arrow_down
						className={isContentVisible ? undefined : styles.rotate}
						width="24px"
						height="24px"
					/>
				</div>
				<div className={isContentVisible ? styles.visible : styles.hidden}>
					<div className={styles.divider}></div>
					<div className={styles.content}>{children}</div>
				</div>
			</div>
		);

	return (
		<div className={styles.container}>
			<div className={styles.visible}>
				<div className={styles.content}>{children}</div>
			</div>
		</div>
	);
};

export default BidDetailsProcessesToggle;
