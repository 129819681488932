import React from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item } from "rc-menu";

const ProfileActions = ({
	children,
	options = [],
	className = "perm-profile-actions",
	...rest
}) => {
	const handleMenuClick = e => {
		let option = options.find(o => o.key === e.key);
		return option.action();
	};

	return (
		<RcDropDown
			trigger={["click"]}
			align={{
				points: ["bc", "tc"],
				offset: [-4, 0]
			}}
			overlay={
				<Menu onClick={handleMenuClick} className={className}>
					{options.map(o => (
						<Item key={o.key}>
							{o.icon} {o.label}
						</Item>
					))}
				</Menu>
			}
			overlayClassName="ignore-click"
			{...rest}
		>
			{children}
		</RcDropDown>
	);
};

export default ProfileActions;
