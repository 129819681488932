/* eslint-disable no-console */
import { utcTimeStamp } from "config/helpers";

export const getSearchContractsPayload = ({
	filters,
	logicalOperator,
	offset,
	sortBy,
	limit,
	query
}) => {
	return {
		op: logicalOperator,
		fields: formatFilters(filters),
		search: query,
		offset,
		sort_by: sortBy,
		limit
	};
};

export const formatFilters = filters => {
	const definedFilters = filters.filter(({ value }) => {
		if (
			[undefined, null, "", 0].includes(value) ||
			(Array.isArray(value) && value.length === 0) ||
			(value !== null &&
				typeof value === "object" &&
				Object.keys(value).length === 0)
		) {
			return false;
		} else {
			return true;
		}
	});

	const filtersFormatted = definedFilters.map(filter => {
		const { name, operator } = filter;

		let filterFormatted;
		if (
			name === "proposal_date" ||
			name === "start_date" ||
			name === "end_date"
		) {
			filterFormatted = formatFilterTypeDate(filter, name);
		} else if (
			name === "vendor" ||
			name === "request_title" ||
			name === "phone_number" ||
			name === "email"
		) {
			let field = name;
			filterFormatted = {
				value: filter.value,
				field,
				op: operator
			};
		} else if (name === "cost" || name === "rate") {
			filterFormatted = formatNumber(filter);
		} else {
			if (name) {
				filterFormatted = formatFilter(filter);
			}
		}

		return filterFormatted;
	});

	return filtersFormatted;
};

const formatNumber = ({ name, value, operator }) => {
	let newValue = value;
	if (operator === "is_empty") {
		newValue = value === true ? "yes" : "no";
	}
	return {
		value: newValue,
		field: name,
		op: operator
	};
};

const formatFilterTypeDate = (filter, field) => {
	const { value, operator, name } = filter;

	if (!value) {
		return {
			field: field || name,
			value: null,
			op: operator
		};
	}
	let valueFormatted;

	if (!value.start) {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value),
			isStart: false
		});
		valueFormatted = [startDate, endDate];
	} else {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.start)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.end),
			isStart: false
		});

		valueFormatted = [startDate, endDate];
	}

	return {
		field: field || name,
		value: valueFormatted,
		op: operator
	};
};

const formatFilter = filter => {
	const { name, value, operator } = filter;
	const ids = reduceItems(value || []).map(({ id }) => id);

	return {
		field: name,
		value: ids,
		op: operator
	};
};

function reduceItems(items) {
	return items.reduce((acc, item) => [...acc, ...item.children], []);
}

const convertCalendarDateToMS = date => {
	const { year, month, day } = date;
	var dateObject = new Date(`${year}/${month}/${day}`);

	return dateObject;
};
