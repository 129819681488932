import cx from "classnames";
import React, { forwardRef } from "react";
import { ReactComponent as Icon } from "static/icons/search-icon-input.svg";
import styles from "./search-input.module.scss";

const SearchInput = (
	{
		className,
		value,
		onChange,
		variant,
		style,
		placeholder = "Search",
		inputClassName,
		icon = null,
		disabled,
		onFocus,
		renderSearchIconLeft = false,
		searchIconClassName,
		...rest
	},
	ref
) => {
	const handleKeyPress = event => {
		if (event.key === "Enter" && ref) {
			ref.current?.blur();
		}
	};

	return (
		<div
			className={cx(styles.root, className, {
				[styles.borderless]: variant === "borderless",
				[styles.fixed_width]: variant === "fixed_width"
			})}
			style={style}
			data-onboarding-step={rest["data-onboarding-step"]}
		>
			{renderSearchIconLeft && (
				<Icon className={cx(styles.icon, searchIconClassName)} />
			)}
			<input
				value={value}
				onChange={onChange}
				onKeyPress={handleKeyPress}
				className={cx(styles.input, inputClassName)}
				placeholder={placeholder}
				ref={ref}
				disabled={disabled}
				onFocus={onFocus}
				{...rest}
			/>
			{icon ? icon : <Icon className={styles.icon} />}
		</div>
	);
};

export default forwardRef(SearchInput);
