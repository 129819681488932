import React from "react";
import { Box, Flex, Text, Button } from "rebass";

import { ReactComponent as DragHandler } from "static/icons/elements-icons-drag-handle.svg";

const BoxContainer = ({
	title,
	children,
	isDrag,
	setIsOpenModalTab,
	setActiveTab,
	isFetching
}) => {
	return (
		<Box
			backgroundColor="white"
			sx={{
				borderRadius: 6,
				padding: "16px"
			}}
		>
			<Flex mb="16px" justifyContent="space-between" alignItems="center">
				<Flex alignItems="center">
					{isDrag && <DragHandler />}
					<Text
						sx={{
							fontSize: "16px",
							color: "#212b36",
							fontWeight: 600,
							lineHeight: 1.36
						}}
						ml={isDrag ? 2 : 0}
					>
						{title}
					</Text>
				</Flex>

				{!isFetching && (
					<Button
						onClick={() => {
							setIsOpenModalTab();
							setActiveTab();
						}}
						sx={{
							background: "transparent",
							border: "none",
							padding: "0px",
							"&:hover": {
								"& > div": {
									color: "#ff6849"
								}
							}
						}}
					>
						{" "}
						<Text
							sx={{
								fontSize: "16px",
								color: "#2263f1",
								fontWeight: "normal",
								lineHeight: 1.14
							}}
						>
							{" "}
							View more
						</Text>{" "}
					</Button>
				)}
			</Flex>
			{children}
		</Box>
	);
};

export default BoxContainer;
