import React from "react";
import cx from "classnames";
import classnames from "./type.module.scss";
import { ReactComponent as DeleteOptionIcon } from "static/icons/delete-option-icon.svg";

const FileType = ({ isDelete = false, deleteHandler, fileType }) => {
	return (
		<div className={cx(classnames.fileTypeContainer)}>
			<span className={classnames.fileTypeLabel}>{fileType}</span>
			{isDelete && (
				<button onClick={deleteHandler}>
					<DeleteOptionIcon className={classnames.icon} />
				</button>
			)}
		</div>
	);
};

export default FileType;
