/* eslint-disable */
/* eslint-disable react/display-name */
//TODO fix focus on applicants location
import React, { memo, useMemo, forwardRef, useCallback } from "react";
import { AddVacancyCustomDropdown } from "../AddVacancyCustomDropdown";
import { Item } from "react-stately";
import CreateVacancyCard from "../CreateVacancyCard/CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyCardTitle } from "../CreateVacancyCardTitle";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import styles from "./add-vacancy-applicants-card.module.scss";
import { ComboBox } from "common/ComboBox";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import {
	EDUCATION_LEVELS,
	EMPLOYMENT_TYPE,
	EXPECTED_DURATION,
	PERMANENT
} from "config";
import { AddVacancyCustomInput } from "../AddVacancyCustomInput";
import {
	NUMBER_KEYS_CODES,
	VACANCY_STEP_CARDS_IDS
} from "modules/vacancy/create-vacancy/utils/constants";
import { Controller } from "react-hook-form";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";
import useElements from "hooks/useLayoutElements";
import { useCreateVacancyTour } from "modules/user-onboarding/workflows/create-vacancy";
import { HOW_TO_CREATE_VACANCY } from "config/onboarding";
import { RadioGroup } from "common/RadioGroup";
import { Picker } from "common/Picker";
import { getLocations } from "../../utils/helper";
import { useGetApplicantLocations } from "modules/CreateRequestModule/hooks/useGetApplicantLocations";
import { DropDownInput } from "common/DropDownInput";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const APPLICATION_LOCATION_OPTIONS = {
	custom: { value: "custom", label: "Custom" },
	anywhere: { value: "anywhere", label: "Anywhere" }
};

const AddVacancyApplicantsCard = ({
	control,
	errors,
	applicantEducationLevel,
	employmentType
}) => {
	const { mainElement } = useElements();
	const { workflowId } = useOnboardingStore();
	const locationsList = useGetApplicantLocations({ enabled: !workflowId });

	let applicantLocationsArray = [];

	for (const key in APPLICATION_LOCATION_OPTIONS) {
		applicantLocationsArray.push(APPLICATION_LOCATION_OPTIONS[key]);
	}

	const locationsItems = useMemo(() => {
		return locationsList.map(({ value, label }) => (
			<Item key={value.id}>{label}</Item>
		));
	}, [locationsList]);

	const educationLevelItems = useMemo(() => {
		return EDUCATION_LEVELS.map(({ label, value }) => (
			<Item key={value}>{label}</Item>
		));
	}, []);

	const onEducationLevelSelectionChange = useCallback((key, onChange) => {
		const _object = EDUCATION_LEVELS.find(({ value }) => value === key);

		const value = {
			label: _object?.label ?? "",
			value: key
		};

		onChange(value);
	}, []);

	const onEducationLevelInputChange = useCallback(
		(value, onChange) => {
			const _object = {
				label: value,
				value: value === "" ? null : applicantEducationLevel?.value
			};

			onChange(_object);
		},
		[applicantEducationLevel?.value]
	);

	const onKeyPress = e => {
		const pressedKey = e.which;

		if (!NUMBER_KEYS_CODES.includes(pressedKey)) {
			e.preventDefault();
		}
	};

	useCreateVacancyTour();

	return (
		<CreateVacancyCard id={VACANCY_STEP_CARDS_IDS.applicants}>
			<CreateVacancyCardTitle
				data-onboarding-step={`${HOW_TO_CREATE_VACANCY}-3`}
			>
				Applicants
			</CreateVacancyCardTitle>
			<CreateVacancyCardContent className={styles.cardContent}>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Education level</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="applicantEducationLevel"
							control={control}
							render={({ field }) => {
								return (
									<ApplicantEducationLevelController
										mainElement={mainElement}
										{...field}
										items={educationLevelItems}
										onEducationLevelInputChange={onEducationLevelInputChange}
										onEducationLevelSelectionChange={
											onEducationLevelSelectionChange
										}
									/>
								);
							}}
						/>
					</div>
					<CreateVacancyFieldError error={errors.applicantEducationLevel} />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>
							Applicants must be based in
						</CreateVacancyFieldLabel>
						<CreateVacancyInfoIcon>
							Applicants based elsewhere than in the selected location(s) will
							be rejected automatically
						</CreateVacancyInfoIcon>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="applicantLocation"
							control={control}
							render={({ field: { value, onChange, ref } }) => {
								const locationValue = value?.[0]?.children?.[0].type;
								return (
									<>
										<RadioGroup
											className={styles.radioButtonContainer}
											options={applicantLocationsArray}
											value={
												locationValue === "any_where"
													? APPLICATION_LOCATION_OPTIONS.anywhere.value
													: APPLICATION_LOCATION_OPTIONS.custom.value
											}
											onChange={e => {
												if (e.target.value === "anywhere") {
													onChange([
														{
															children: [
																{
																	type: "any_where",
																	id: "",
																	label: "Anywhere"
																}
															]
														}
													]);
												} else {
													onChange([]);
												}
											}}
										/>
										{locationValue === "any_where" ? (
											<DropDownInput
												isDisabled
												placeHolder="Select a country, continent"
												className={styles.applicantLocationInputDisabled}
											/>
										) : (
											<LocationsController
												mainElement={mainElement}
												value={value}
												onChange={onChange}
												ref={ref}
												workflowId={workflowId}
											/>
										)}
									</>
								);
							}}
						/>
					</div>
					<CreateVacancyFieldError error={errors.applicantLocation} />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Employment type</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="employmentType"
							control={control}
							render={({ field }) => (
								<AddVacancyCustomDropdown
									options={EMPLOYMENT_TYPE}
									{...field}
								/>
							)}
						/>
					</div>
					<CreateVacancyFieldError error={errors.employmentType} />
				</CreateVacancyFieldContainer>
				{employmentType !== PERMANENT && (
					<CreateVacancyFieldContainer>
						<CreateVacancyLabelContainer>
							<CreateVacancyFieldLabel>
								Expected duration
							</CreateVacancyFieldLabel>
						</CreateVacancyLabelContainer>
						<div className={styles.inputContainer}>
							<div className={styles.compoundInput}>
								<Controller
									name="duration"
									control={control}
									render={({ field }) => {
										return (
											<AddVacancyCustomInput
												className={styles.durationInput}
												onKeyPress={onKeyPress}
												{...field}
											/>
										);
									}}
								/>
								<Controller
									name="durationType"
									control={control}
									render={({ field }) => {
										return (
											<AddVacancyCustomDropdown
												options={EXPECTED_DURATION}
												inputClassName={styles.durationSelectInput}
												className={styles.durationSelect}
												placeHolder={" "}
												{...field}
											/>
										);
									}}
								/>
							</div>
						</div>
						<div className={styles.expectedDurationErrors}>
							<CreateVacancyFieldError
								className={styles.duration}
								error={errors.duration}
							/>
						</div>
					</CreateVacancyFieldContainer>
				)}
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Number of positions
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="numberOfPositions"
							control={control}
							render={({ field }) => {
								return (
									<AddVacancyCustomInput onKeyPress={onKeyPress} {...field} />
								);
							}}
						/>
					</div>
					<CreateVacancyFieldError error={errors.numberOfPositions} />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Number of colleagues
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="numberColleagues"
							control={control}
							render={({ field }) => {
								return (
									<AddVacancyCustomInput onKeyPress={onKeyPress} {...field} />
								);
							}}
						/>
					</div>
					<CreateVacancyFieldError error={errors.numberColleagues} />
				</CreateVacancyFieldContainer>
			</CreateVacancyCardContent>
		</CreateVacancyCard>
	);
};

export default AddVacancyApplicantsCard;

const LocationsController = memo(
	forwardRef(({ value, onChange, mainElement, workflowId }, ref) => {
		return (
			<Picker
				popoverContainer={mainElement}
				PrimaryHeaderContent={() => "Locations remaining"}
				SecondaryHeaderContent={() => "Locations selected"}
				displayRating={false}
				placement="top"
				isMultipleSelect
				onConfirm={onChange}
				onSkillDeleteFromInput={onChange}
				queryFn={workflowId ? () => [] : getLocations}
				value={value}
				key="locations"
				useServerFilter={false}
				inputClassName={styles.applicantLocationInput}
				ref={ref}
			/>
		);
	})
);

const ApplicantEducationLevelController = memo(
	forwardRef(
		(
			{
				mainElement,
				items,
				value,
				onChange,
				onEducationLevelInputChange,
				onEducationLevelSelectionChange
			},
			ref
		) => {
			return (
				<ComboBox
					popoverContainer={mainElement}
					ref={ref}
					inputClassName={styles.comboxInput}
					selectedOption={null}
					selectedKey={value?.value}
					inputValue={value?.label}
					onInputChange={value => onEducationLevelInputChange(value, onChange)}
					onSelectionChange={value =>
						onEducationLevelSelectionChange(value, onChange)
					}
				>
					{items}
				</ComboBox>
			);
		}
	)
);
