import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import styles from "../cells-render.module.scss";
import loadable from "loadable-components";
import { useFilters } from "../../../../FilterDrawer/hooks/useFilter";
import { useFilters as useSmartFilter } from "common/SmartFilterDrawer/hooks/useFilter";
import { convertNoteDescriptionToText } from "../../../../../config/helpers";

export const EmptyStateCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/EmptyStateCell/EmptyStateCell"
	)
);

const LastNoteCell = ({ data, showButton = true, isSearchCandidate }) => {
	const [showSeeMore, setShowSeeMore] = useState(false);
	const { setSelectedUserId, setShowNoteDrawer } = useFilters();
	const smartFilter = useSmartFilter();

	const ref = useRef();
	let tooltipVisibilityHandler = () => {
		setShowSeeMore(ref?.current?.offsetWidth < ref?.current?.scrollWidth);
	};

	useEffect(() => {
		tooltipVisibilityHandler();
	}, [ref.current?.clientWidth]);

	const handleShowNoteDrawer = useCallback(() => {
		if (isSearchCandidate) {
			smartFilter.setSelectedUserId(data?.data?.profileId);
			smartFilter.setShowNoteDrawer(true);
		} else {
			setSelectedUserId(data?.data?.profileId);
			setShowNoteDrawer(true);
		}
	}, [setSelectedUserId]);

	return (
		<>
			{data.value ? (
				<div className={styles.LastNoteCell}>
					<div ref={ref} className={styles.lastNoteValue}>
						{convertNoteDescriptionToText(data.value)}
					</div>
					{showSeeMore && showButton ? (
						<div className={styles.lastNoteMore} onClick={handleShowNoteDrawer}>
							See more
						</div>
					) : (
						"..."
					)}
				</div>
			) : (
				<EmptyStateCell />
			)}
		</>
	);
};
export default memo(LastNoteCell);
