import React, { Component } from "react";
import "../../../../styles/css/hireme/rc-slider.css";
import RcSlider from "rc-slider";
import { showModal } from "modules/cv/actions";
import { connect } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import {
	PREFERENCE,
	EUR_PER_HOUR,
	WORK_DISTANCE,
	DEFAULT_DISTANCE,
	MINIMUM_DISTANCE
} from "config";
import AddressComposer from "common/AddressComposer";
import CurrencyFormatter from "common/CurrencyFormater";
import SmallChecker from "common/SmallChecker";
import CustomHandle from "common/CustomHandle";
import GlobalTooltip from "common/GlobalTooltip";

class Preferences extends Component {
	constructor(props) {
		super(props);
		this.showModal = this.showModal.bind(this);
	}

	showModal() {
		this.props.dispatch(showModal(PREFERENCE));
	}

	render() {
		const { preference, showModal } = this.props;
		const isObjectEmpty = _isEmpty(preference);

		return (
			<div id="preferences" className="section action-hover">
				{!isObjectEmpty && (
					<div className="section-edit pull-right">
						<button className="btn btn-sm" onClick={this.showModal}>
							<i className="icon-edit-2" />
						</button>
					</div>
				)}
				<div className="section-title">Preferences</div>
				<div className="section-content">
					{!isObjectEmpty && (
						<div>
							<div className="block">
								<h5>
									<span className="heading">I want to work from:</span>
									<GlobalTooltip
										maxWidth="400px"
										overlay="As a default value, the platform uses the address of your legal entity.
											If you're based elsewhere, just edit this field."
									/>
								</h5>
								<div className="block-content">
									<AddressComposer addressData={preference.address} />
								</div>
							</div>
							<div className="block">
								<h5>
									<span className="heading">I am willing to work within:</span>
								</h5>
								<div className="block-content slider col-md-9">
									<RcSlider
										min={MINIMUM_DISTANCE}
										max={WORK_DISTANCE}
										value={preference.distance || DEFAULT_DISTANCE}
										handle={props => (
											<CustomHandle
												{...props}
												tipFormatter={value => `${value}km`}
												className="clickFocus"
												hideTooltips={showModal}
											/>
										)}
									/>
								</div>
							</div>
							<div className="block">
								<div className="block-content">
									<div className="row">
										<div className="col-md-8">
											<h5>
												<span className="heading">commitment</span>
											</h5>
											<SmallChecker
												checked={!!preference.full_time}
												text="Full time"
											/>
											<SmallChecker
												checked={!!preference.part_time}
												text="Part time"
											/>
										</div>
										<div className="col-md-4">
											<h5>
												<span className="heading">Minimum rate</span>
											</h5>
											<div className="rate">
												<CurrencyFormatter
													cent={preference.hourly_budget}
													symbol={EUR_PER_HOUR}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{isObjectEmpty && (
						<div className="empty-section">
							<div className="add btn-center-block" onClick={this.showModal}>
								<i className="fa fa-plus" /> Add Preferences
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		preference: state.cv.preference,
		isFetching: state.cv.isFetching,
		busySection: state.cv.busySection,
		showModal: state.cv.showModal
	};
};

export default connect(mapStateToProps)(Preferences);
