import React from "react";
import styles from "./requests-header.module.scss";
import cx from "classnames";
import { goBack, historyPush } from "config/helpers";
import { ReactComponent as AddIcon } from "static/icons/PlusAdd.svg";
import { ReactComponent as BackArrow } from "static/icons/IconBack.svg";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import get from "lodash/get";
import { getCreateRequestRoute } from "modules/CreateRequestModule/CreateRequestModule";

const requestHeaderTabs = [
	{
		key: "requests",
		notification: "requests",
		label: "Requests"
	},
	{
		key: "candidates",
		notification: "candidates",
		label: "Candidates"
	},
	{
		key: "interviews",
		notification: "request_interviews",
		label: "Interviews"
	},
	{
		key: "contract",
		notification: "contracts",
		label: "Contracts"
	}
];

const RequestsHeader = ({ activeView, onViewChange, overviewCount }) => {
	const list = get(overviewCount, "lists", {});

	const renderNotification = name => {
		const countNotification = get(list, `${name}`, 0);
		return (
			countNotification > 0 && (
				<span className={styles.candidateCount}>+{countNotification}</span>
			)
		);
	};

	const onCreateRequest = () => {
		return historyPush(getCreateRequestRoute(""));
	};
	return (
		<div className={styles.requestsContainer}>
			<div className={styles.headerRequests}>
				<div className={styles.first}>
					<button className={styles.goBackButton} onClick={goBack}>
						<BackArrow />
					</button>
					<h3>Requests</h3>
				</div>
				<div className={styles.btnContainer}>
					<HeaderButton
						text="Create a request"
						className="buttonAdd"
						icon={<AddIcon />}
						onClick={onCreateRequest}
					/>
				</div>
			</div>
			<div className={styles.panel}>
				<nav>
					<div className={styles.tabs}>
						{requestHeaderTabs.map(tab => (
							<button
								key={tab.key}
								onClick={() => {
									onViewChange(tab.key);
								}}
								className={cx(styles.tab, {
									[styles.active]: activeView === tab.key
								})}
							>
								{tab.label}
								{renderNotification(tab.notification)}
							</button>
						))}
					</div>
					<div className={styles.presentationSlider}></div>
				</nav>
			</div>
		</div>
	);
};

export default RequestsHeader;
