import React, { useState, Children, cloneElement } from "react";
import { Box } from "rebass";

const Menu = ({ children, changeHandler }) => {
	const [openMenu, setOpenMenu] = useState(false);

	const onClose = () => setOpenMenu(false);

	const onChange = value => {
		if (changeHandler instanceof Function) changeHandler(value);
	};

	return (
		<Box sx={{ position: "relative" }}>
			{Children.map(children, element =>
				cloneElement(element, {
					openMenu,
					setOpenMenu,
					onChange,
					onClose
				})
			)}
		</Box>
	);
};

export default Menu;
