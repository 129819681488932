import React from "react";
import { useOverlayTrigger } from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import { CategorySelectButton } from "../CategorySelectButton";
import CategorySelectPopover from "../CategorySelectPopover/CategorySelectPopover";

const CategorySelectPopoverTrigger = ({
	label,
	children,
	value,
	onInputClick,
	onClose,
	inputClassName,
	...props
}) => {
	let ref = React.useRef();
	let state = useOverlayTriggerState(props);

	let { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		state,
		ref
	);

	const style = {
		minWidth: `${ref.current?.offsetWidth}px`
	};

	return (
		<>
			<CategorySelectButton
				className={inputClassName}
				{...triggerProps}
				onInputClick={onInputClick}
				buttonRef={ref}
				value={value}
			>
				{label}
			</CategorySelectButton>
			{state.isOpen && (
				<CategorySelectPopover
					{...props}
					onClose={onClose}
					triggerRef={ref}
					state={state}
				>
					{React.cloneElement(children, { ...overlayProps, style })}
				</CategorySelectPopover>
			)}
		</>
	);
};

export default CategorySelectPopoverTrigger;
