import React, { useState } from "react";
import { ReactComponent as PlusIcon } from "static/icons/plusIcon.svg";
import AddressBoxModal from "common/AddressBoxNext/AddressBoxModal";
import { Controller, useForm } from "react-hook-form";
import ImageUploader from "../../ImageUploader/ImageUploader";
import { Button } from "common/Button";
import { useToggle } from "react-use";
import RenderInputAdress from "../../RenderInputAdress";
import get from "lodash/get";
import _pick from "lodash/pick";
import { v4 as uuid } from "uuid";
import styles from "./styles.module.scss";
import useAddCompanySite from "modules/MyCompany/api/useAddCompanySite";
import toast from "react-hot-toast";
import { queryCache } from "react-query";
const AddSiteForm = ({ onClose, onSuccess, setActiveTab }) => {
	const [displayAddressModal, setDisplayAddressModal] = useToggle(false);
	const [siteId, setSiteId] = useState(uuid());

	const { control, watch, handleSubmit, getValues } = useForm({
		shouldUnregister: true,
		defaultValues: {
			name: "",
			avatar: ""
		}
	});

	const [mutate, { isLoading: addSiteLoading }] = useAddCompanySite({
		onSuccess: () => {
			toast("Site added successfully!", {
				position: "bottom-right",
				duration: 2500
			});
			onSuccess();
			setActiveTab(siteId);
			setSiteId(uuid());
			queryCache.invalidateQueries("@getSites");
			onClose();
		},
		onError: error => {
			let errorMessage = get(error, "detail");
			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					toast(
						`${
							typeof get(errorMessage, name) === "string"
								? get(errorMessage, name)
								: get(errorMessage, [name, 0])
						}`,
						{
							position: "bottom-right",
							duration: 2500,
							isFailed: true
						}
					);
				});
			} else {
				toast(errorMessage, { isFailed: true });
			}
		}
	});
	const allField = watch();
	const onSubmit = () => {
		const location = _pick(get(allField, "location", []), [
			"number",
			"street",
			"box",
			"latitude",
			"longitude",
			"city",
			"country",
			"zip",
			"iso_country",
			"viewport"
		]);

		if (!location.box) {
			location.box = "";
		}

		const payload = {
			id: siteId,
			name: get(allField, "name", "").trim(),
			avatar: get(allField, "avatar"),
			location
		};
		mutate(payload);
	};

	return (
		<>
			<form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.formGroup}>
					<label className={styles.formLabel}>
						Company Logo<span className={styles.required}>*</span>
					</label>
					<div className={styles.flexContainer}>
						<Controller
							name="avatar"
							control={control}
							render={({ field: { onChange } }) => (
								<ImageUploader
									onChange={value => {
										onChange(value);
									}}
								/>
							)}
						/>
					</div>
				</div>
				<div className={styles.formGroup}>
					<label className={styles.formLabel}>
						Site name<span className={styles.required}>*</span>
					</label>
					<Controller
						name="name"
						control={control}
						render={({ field: { onChange, value } }) => (
							<input
								className={styles.textInput}
								type={"text"}
								name={"name"}
								placeholder={"Start typing..."}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</div>
				<div className={styles.formGroup}>
					<label className={styles.formLabel}>
						Site address<span className={styles.required}>*</span>
					</label>

					{!get(allField, "location") ? (
						<div>
							<button
								type="button"
								className={styles.addressBtn}
								onClick={setDisplayAddressModal}
							>
								<PlusIcon />
							</button>
						</div>
					) : (
						<RenderInputAdress
							value={get(allField, "location")}
							modeEdit={true}
							setEditAddress={setDisplayAddressModal}
							addSite
						/>
					)}

					<Controller
						name="location"
						control={control}
						render={({ field: { onChange, value } }) => {
							return (
								<AddressBoxModal
									active={displayAddressModal}
									onClose={() => setDisplayAddressModal(false)}
									onSave={loc => {
										setDisplayAddressModal(false);
										onChange(loc);
									}}
									requiredFields={[
										"street",
										"number",
										"city",
										"zip",
										"country"
									]}
									isRequired={true}
									title="Headquarters address"
									label="Company address"
									currentAddress={value}
								/>
							);
						}}
					/>
				</div>
			</form>
			<div className={styles.drawerFooter}>
				<Button
					key={"cancel"}
					variant={"contained-text"}
					text={"Cancel"}
					onClick={onClose}
				/>
				<Button
					key={"add"}
					variant={"contained"}
					text={"Add"}
					icon={<PlusIcon />}
					onClick={handleSubmit(onSubmit)}
					disabled={
						getValues("name")?.trim() === "" ||
						getValues("avatar")?.trim() === "" ||
						Object.keys(getValues("location") || {}).length === 0
					}
					isLoading={addSiteLoading}
				/>
			</div>
		</>
	);
};

export default AddSiteForm;
