export const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};
// const grid = 8;

export const getItemStyle = (_, draggableStyle) => ({
	userSelect: "none",
	...draggableStyle
});

export const move = (
	source,
	destination,
	droppableSource,
	droppableDestination
) => {
	const sourceClone = Array.from(source);
	const destClone = Array.from(destination);
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

export const getListStyle = (/*isDraggingOver*/) => ({
	// background: isDraggingOver ? "lightblue" : "inherit",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	gap: 12
});
