import { useEffect } from "react";

export default function useOnClickOutside(ref, handler, refetch = []) {
	useEffect(() => {
		const listener = event => {
			if (!ref.current || ref.current.contains(event.target)) {
				return;
			}
			handler(event);
		};
		//TODO this will create several eventlisteners because function handler gets created on each render, I think useCallback should be used in the components, also not passing param refetch will create several eventlisteners
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [ref, ...refetch, handler]);
}
