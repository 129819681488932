import React, { useState } from "react";
import { usePopper } from "react-popper";
import cx from "classnames";
import { queryCache } from "react-query";
import get from "lodash/get";
import { REMINDER_BEFORE, REMINDER_AFTER } from "config";
import useUpdateReminder from "modules/Tasks/api/useUpdateReminder";
import classnames from "./reminder.module.scss";
import Loader from "common/Loader";
import useOnClickOutside from "hooks/useOnClickOutside";
import toaster from "common/Toaster";
import { myTaskListKey } from "modules/Tasks/api/useGetTasks";

const Reminder = ({
	isBeforeOption,
	id,
	onClose,
	closeOpenMenu = () => {},
	dueDate,
	reminderTargetRef,
	arrowElementRef,
	position = "left-end",
	offset = [-40, 10],
	setHighlitedRow
}) => {
	const [loading, setLoading] = useState(false);
	const [reminderElementRef, setReminderElementRef] = useState(false);

	let options = isBeforeOption ? REMINDER_BEFORE : REMINDER_AFTER;

	const { styles, attributes } = usePopper(
		reminderTargetRef,
		reminderElementRef,
		{
			enabled: true,
			eventsEnabled: true,
			placement: position,
			modifiers: [
				{
					name: "offset",
					options: {
						offset
					}
				},
				{ name: "arrow", options: { element: arrowElementRef } },
				{
					name: "flip",
					options: {
						fallbackPlacements: ["left-start", "top-start"]
					}
				}
			]
		}
	);

	if (isBeforeOption) {
		const currentTime = window.moment().unix();
		const diff = dueDate - currentTime;
		const newOptions = options.filter(item => {
			return Number(item.value) <= diff / 60;
		});
		if (Boolean(newOptions.length)) {
			options = [...newOptions];
		} else {
			options = REMINDER_AFTER;
		}
	}

	const [mutate] = useUpdateReminder({
		onSuccess: () => {
			setHighlitedRow(id);
			setLoading(false);
			closeOpenMenu();
			onClose();
			toaster.success("Reminder has been modified!");
			queryCache.invalidateQueries(myTaskListKey);
		},
		onError: error => {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					toaster.danger(
						`${name} : ${
							typeof get(errorMessage, name) === "string"
								? get(errorMessage, name)
								: get(errorMessage, name)[0]
						}`
					);
				});
			} else {
				toaster.danger(errorMessage);
			}
		}
	});

	const handleOption = (option, index) => {
		setLoading(index);
		const remindingTime = get(option, "label", "").split(" ")[2];
		mutate({ id, time: get(option, "value"), remindingTime });
	};

	useOnClickOutside({ current: reminderElementRef }, onClose);

	return (
		<div
			ref={setReminderElementRef}
			className={cx(classnames.container)}
			style={styles.popper}
			{...attributes.popper}
		>
			{options.map((option, index) => (
				<button
					onClick={() => handleOption(option, index)}
					key={option.value}
					className={classnames.menuItem}
				>
					{loading === index ? (
						<div className={classnames.loadingElement}>
							{" "}
							<Loader classes={classnames.loader} check={false} />{" "}
						</div>
					) : (
						<span> {option.label}</span>
					)}
				</button>
			))}
		</div>
	);
};

export default Reminder;
