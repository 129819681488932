import React, { useState } from "react";
import style from "./styles.module.scss";
import { ButtonWithIcon } from "../../../../ui";
import TaskOwner from "../../taskOwner/TaskOwner";
import cx from "classnames";
import Mark from "../../../../svg/Mark";
import More from "../../../../svg/More";
import Close from "../../../../svg/Close";
import useMarkAsCompleted from "modules/Tasks/api/useMarkAsCompleted";
import DateTime from "../../dateTime/DateTime";
import { queryCache } from "react-query";
import { tasksList } from "modules/home/api/queriesKeys.js";
import { historyPush } from "config/helpers";
import ConfirmationModalAction from "modules/Tasks/components/confirmationModal/ConfirmationModalAction";
const TaskItem = ({ task }) => {
	const [showMore, setShowMore] = useState(false);
	const [markAsCompleted, { isLoading }] = useMarkAsCompleted();
	const [showModalCompleted, setShowModalCompleted] = useState(false);
	const showMoreHandler = e => {
		e.stopPropagation();
		setShowMore(!showMore);
	};
	const id = task._id;
	const goToTask = id => {
		return historyPush(`/tasks/${id}`);
	};
	const markAsCompletedBtn = () => {
		markAsCompleted(
			{
				task_id: id
			},
			{
				onSuccess: () => {
					queryCache.invalidateQueries(tasksList);
				}
			}
		);
	};
	const showModal = (id, e) => {
		e.stopPropagation();
		setShowModalCompleted(true);
	};
	const closeModal = () => {
		setShowModalCompleted(false);
	};
	return (
		<li className={style.taskItem}>
			<span onClick={() => goToTask(id)}>
				<DateTime date={task.due_date} />
				<div className={style.taskItemTitle}>
					<h3 className={style.title}>{task.title}</h3>
					<ButtonWithIcon
						isLoading={isLoading}
						onClick={e => showModal(id, e)}
						text={"Mark as completed"}
						icon={<Mark />}
					/>
				</div>
				{task.assigned_user._id !== task.created_by._id && (
					<TaskOwner assignedBy={task.created_by} />
				)}
				<div
					className={cx(
						{
							[style.more]: showMore
						},
						style.less
					)}
				>
					<h3 className={style.title}>Description</h3>
					<p className={style.detail}>{task.description}</p>
				</div>

				<ButtonWithIcon
					text={showMore ? "Close" : "Show more"}
					icon={showMore ? <Close /> : <More />}
					onClick={e => showMoreHandler(e)}
				/>
			</span>
			{showModalCompleted && (
				<ConfirmationModalAction
					onClose={closeModal}
					onClick={markAsCompletedBtn}
					modalName={"mark-as-resolved-task"}
					title={`Are you sure you want to mark as completed`}
					type={"success"}
					textAction={"Yes, mark as completed"}
					loading={isLoading}
					typeAction={"success"}
				/>
			)}
		</li>
	);
};

export default TaskItem;
