import React from "react";
import { Flex, Box } from "rebass";
import FunctionsContainer from "./components/functions";
import Interactions from "./components/interactions";
import Notes from "./components/notes";
import PersonalTab from "./components/personalTab";
import Preferences from "./components/preferences";
import SkillsAndLanguages from "./components/SkillsAndLanguages";
import Tab from "./components/tab/tab";
import Tabs from "./components/tab/tabs";

const TabContainer = ({
	activeTab,
	setActiveTab,
	functions,
	preference,
	languages,
	skills,
	headline,
	canEdit,
	mainFunction,
	linkedInProfile,
	active_company,
	refetchProfileData,
	userId,
	openDrawer,
	totalHistory,
	refetchNotes
}) => {
	return (
		<Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
			<Tab id="personal" title="Personal">
				<PersonalTab
					linkedInProfile={linkedInProfile}
					canEdit={canEdit}
					headline={headline}
					active_company={active_company}
					refetchProfileData={refetchProfileData}
					openDrawer={openDrawer}
				/>
			</Tab>
			<Tab id="functions" title="Functions">
				<FunctionsContainer mainFunction={mainFunction} functions={functions} />
			</Tab>
			<Tab id="preferences" title="Preferences">
				<Preferences preference={preference} />
			</Tab>
			<Tab id="skills_languages" title="Skills">
				<SkillsAndLanguages skills={skills} languages={languages} />
			</Tab>
			<Tab
				id="history"
				title={
					<Flex>
						Interactions
						{totalHistory > 0 && (
							<Box
								px={2}
								ml={1}
								sx={{
									borderRadius: "12px",
									color: activeTab === "history" ? "#ff6849" : "white",
									backgroundColor: activeTab !== "history" ? "#ff6849" : "white"
								}}
							>
								{totalHistory}
							</Box>
						)}
					</Flex>
				}
			>
				<Interactions userId={userId} />
			</Tab>
			<Tab id="notes" title="Notes">
				<Notes userId={userId} refetchNotes={refetchNotes} />
			</Tab>
		</Tabs>
	);
};

export default TabContainer;
