import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LineBreakNode } from "lexical";
import { useEffect } from "react";

function PreventLineBreakPlugin() {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		return editor.registerNodeTransform(LineBreakNode, node => {
			node.remove();
		});
	}, [editor]);

	return null;
}

export default PreventLineBreakPlugin;
