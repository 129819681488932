import _get from "lodash/get";
import React from "react";
import _groupBy from "lodash/groupBy";
import styles from "modules/candidate/Profile/components/SkillsCard/skills-card.module.scss";
import EditableSection from "../EditableSection";
import GlobalTooltip from "common/GlobalTooltip";
import EmptyState from "modules/candidate/Profile/components/EmptyState";

export const SkillsByCategory = skills => {
	const categories = _groupBy(skills, "parent_sector.name");
	return categories;
};

const Skills = ({ profileData }) => {
	const sectionSkills = _get(profileData, "update_sections.skills");

	const skills = _get(profileData, "skills", []);
	const skillsBycategory = SkillsByCategory(skills);
	const keys = Object.keys(skillsBycategory);
	const renderItems = (name, score) =>
		score && (
			<>
				<div className={styles.tag}>
					<p>{name ? `${name} (${score}/5)` : "N/A"}</p>
				</div>
			</>
		);

	const countValue =
		skills?.length > 0 &&
		keys.map(item => (
			<div key={item} className={styles.section}>
				<div className={styles.row}>
					{skillsBycategory[item]?.length > 4 &&
						skillsBycategory[item]
							.slice(4)
							.map(({ name, score }) => renderItems(name, score, item))}
				</div>
			</div>
		));
	return (
		<React.Fragment>
			<EditableSection
				title="Skills"
				hasEdit={false}
				sectionUpdate={sectionSkills}
			>
				{skills?.length > 0 ? (
					keys.map(item => (
						<div key={item} className={styles.section}>
							<div className={styles.titleS}>{item}</div>
							<div className={styles.row}>
								{skillsBycategory[item].length > 4
									? skillsBycategory[item]
											.slice(0, 4)
											.map(({ name, score }) => renderItems(name, score, item))
									: skillsBycategory[item].map(({ name, score }) =>
											renderItems(name, score, item)
									  )}
								<div>
									{skillsBycategory[item].length > 4 && (
										<GlobalTooltip
											placement="bottom"
											trigger="hover"
											overlay={countValue}
										>
											<span
												style={{
													height: "25px",
													width: "25px",
													backgroundColor: "#e4e5e7",
													borderRadius: "50%",
													display: "inline-block",
													marginRight: "5px",
													fontSize: "13px",
													color: "#3c3636",
													textAlign: "center",
													padding: "3px"
												}}
											>
												+{skillsBycategory[item].length - 4}
											</span>
										</GlobalTooltip>
									)}
								</div>
							</div>
						</div>
					))
				) : (
					<EmptyState />
				)}
			</EditableSection>
		</React.Fragment>
	);
};

export default Skills;
