//TODO We refactor this
import get from "lodash/get";
import { MISSIONS_LIST_FILTER_ID } from "modules/MissionsList/components/_MissionsListUtils/missionsListUtils";
import { REQUESTS_CANDIDATE_FILTER_ID } from "modules/Requests/RequestsCandidatesList/utils";
import { FREELANCERS_FILTER_ID } from "modules/freelancers/utils/constants";
import { NOTES_FILTER_ID } from "modules/notes/notes-list/components/utils";
import { CONTRACTS_FILTER_ID } from "modules/Requests/contracts/components/utils";
import { REQUESTS_FILTER_ID } from "modules/Requests/components/RequestsTab/components/utils/constants";
import { REQUEST_INTERVIEWS_FILTERS_ID } from "modules/Requests/RequestsInterviewsList/utils";
import isNumber from "lodash/isNumber";
import { VACANCY_FILES_FILTER_ID } from "modules/vacancy-view/components/Files/components/utils";

const filtersRenderMap = {
	candidates: {
		source: "renderLabelValues",
		employment_type: "renderLabelValues",
		currency: "renderLabelValues",
		payroll_country: "renderLabelValues",
		salary_type: "renderLabelValues",
		payment_type: "renderLabelValues",
		extra_benefits: "renderLabelValues",
		category: "renderLabelValues",
		subcategory: "renderLabelValues",
		seniority: "renderLabelValues",
		profile_tags: "renderLabelValues",
		created_by: "renderLabelValues",
		languages: "renderLabelValuesWithRating",
		notes: "renderBooleanValues",
		is_viewed: "renderBooleanValues",
		is_referred: "renderBooleanValues",
		is_activated: "renderBooleanValues",
		interviewed: "renderBooleanValues",
		residence: "renderLocationDescription",
		preferred_location: "renderLocationDescription",
		expected_salary: "renderSalaryValue",
		functions: "renderFunctionsValues",
		skills: "renderSkillsValues",
		created_at: "renderDateValues",
		followed: "renderBooleanValues"
	},
	offers: {
		contract_type: "renderLabelValues",
		proposal_status: "renderLabelValues",
		currency: "renderLabelValues",
		salary_type: "renderLabelValues",
		payment_type: "renderLabelValues",
		extra_benefits: "renderLabelValues",
		category: "renderLabelValues",
		subcategory: "renderLabelValues",
		seniority: "renderLabelValues",
		department: "renderLabelValues",
		functions: "renderLabelValues",
		proposal_made_by: "renderLabelValues",
		amount: "renderSalaryValue",
		payment_period: "renderLabelValues",
		start_date: "renderDateValues",
		proposal_date: "renderDateValues",
		site: "renderLabelValues"
	},
	interviews: {
		department: "renderLabelValues",
		interview_status: "renderLabelValues",
		category: "renderLabelValues",
		subcategory: "renderLabelValues",
		functions: "renderLabelValues",
		seniority: "renderLabelValues",
		attendees: "renderLabelValues",
		organizer: "renderLabelValues",
		interview_date: "renderDateValues",
		interview_type: "renderLabelValues",
		evaluation_score: "renderSalaryValue",
		site: "renderLabelValues"
	},
	vacancies: {
		category: "renderLabelValues",
		subcategory: "renderLabelValues",
		employment_type: "renderLabelValues",
		education_level: "renderLabelValues",
		seniority: "renderLabelValues",
		department: "renderLabelValues",
		functions: "renderLabelValues",
		skills: "renderLabelValues",
		languages: "renderLabelValues",
		created_by: "renderLabelValues",
		status: "renderLabelValues",
		start_date: "renderDateValues",
		created_on: "renderDateValues",
		posted_on: "renderDateValues",
		due_date: "renderDateValues",
		job_location: "renderLocationDescription",
		location_type: "renderLocationTypeValues",
		payroll_country: "renderLabelValues",
		currency: "renderLabelValues",
		payment_time: "renderLabelValues",
		payment_type: "renderLabelValues",
		extra_benefits: "renderLabelValues",
		site_id: "renderLabelValues",
		remote_eligibility: "renderLabelValues",
		priority: "renderLabelValues"
	},
	applications: {
		application_status: "renderLabelValues",
		department: "renderLabelValues",
		category: "renderLabelValues",
		subcategory: "renderLabelValues",
		functions: "renderLabelValues",
		seniority: "renderLabelValues",
		source: "renderLabelValues",
		source_by: "renderLabelValues",
		application_type: "renderLabelValues",
		application_date: "renderDateValues",
		evaluation_score: "renderSalaryValue",
		site: "renderLabelValues"
	},
	[FREELANCERS_FILTER_ID]: {
		source: "renderLabelValues",
		extra_benefits: "renderLabelValues",
		category: "renderLabelValues",
		sub_category: "renderLabelValues",
		seniority: "renderLabelValues",
		time_commitment: "renderLabelValues",
		created_by: "renderLabelValues",
		languages: "renderLabelValuesWithRating",
		interviewed: "renderBooleanValues",
		bid_received: "renderBooleanValues",
		been_on_mission: "renderBooleanValues",
		company_address: "renderLocationDescription",
		preferred_location: "renderLocationDescription",
		functions: "renderFunctionsValues",
		skills: "renderSkillsValues",
		created_on: "renderDateValues",
		followed: "renderBooleanValues",
		availability: "renderLabelValues"
	},
	[NOTES_FILTER_ID]: {
		created_by: "renderLabelValues",
		created_at: "renderDateValues",
		note_type: "renderLabelValues",
		user_id: "renderLabelValues",
		visibility: "renderLabelValues"
	},
	[MISSIONS_LIST_FILTER_ID]: {
		start_date: "renderDateValues",
		end_date: "renderDateValues",
		status: "renderLabelValues",
		profile_type: "renderLabelValues",
		rate_type: "renderLabelValues",
		timesheet_frequency: "renderLabelValues",
		onboarded: "renderBooleanValues",
		department: "renderLabelValues"
	},
	[REQUESTS_CANDIDATE_FILTER_ID]: {
		category: "renderLabelValues",
		profile_type: "renderLabelValues",
		availability: "renderLabelValues",
		matching_score: "renderSalaryValue",
		amount: "renderSalaryValue",
		time_commitment: "renderLabelValues",
		status: "renderLabelValues",
		function: "renderFunctionsValues",
		subcategory: "renderLabelValues",
		seniority: "renderLabelValues",
		application_date: "renderDateValues",
		rate_type: "renderLabelValues",
		company_address: "renderLocationDescription"
	},
	[CONTRACTS_FILTER_ID]: {
		availability: "renderLabelValues",
		profile_type: "renderLabelValues",
		made_by: "renderLabelValues",
		rate_type: "renderLabelValues",
		contract_status: "renderLabelValues",
		proposal_date: "renderDateValues",
		start_date: "renderDateValues",
		end_date: "renderDateValues",
		cost: "renderSalaryValue",
		rate: "renderSalaryValue"
	},
	[REQUESTS_FILTER_ID]: {
		site_id: "renderLabelValues",
		department: "renderLabelValues",
		category: "renderLabelValues",
		subcategory: "renderLabelValues",
		functions: "renderFunctionsValues",
		seniority: "renderLabelValues",
		skills: "renderLabelValues",
		languages: "renderLabelValues",
		contract_type: "renderLabelValues",
		time_commitment: "renderLabelValues",
		rate_type: "renderLabelValues",
		expected_rate: "renderSalaryValue",
		timesheet_frequency: "renderLabelValues",
		overtime: "renderBooleanValues",
		job_location: "renderLocationDescription",
		applicant_location: "renderLabelValues",
		start_date: "renderDateValues",
		end_date: "renderDateValues",
		created_by: "renderLabelValues",
		created_on: "renderDateValues",
		status: "renderLabelValues",
		remote_eligibility: "renderLabelValues"
	},
	[REQUEST_INTERVIEWS_FILTERS_ID]: {
		interview_status: "renderLabelValues",
		attendees: "renderLabelValues",
		organizer: "renderLabelValues",
		interview_date: "renderDateValues",
		interview_type: "renderLabelValues",
		profile_type: "renderLabelValues",
		availability: "renderLabelValues",
		interviewer_location: "renderLocationDescription",
		matching_rate: "renderSalaryValue"
	},
	[VACANCY_FILES_FILTER_ID]: {
		file_name: "renderLabelValues",
		file_type: "renderLabelValues",
		created_at: "renderDateValues",
		created_by: "renderLabelValues",
		allowed_users: "renderLabelValues"
	}
};

export const renderFilterValues = (module_id, field, value) => {
	if (!(module_id in filtersRenderMap)) {
		return "";
	}
	if (typeof value !== "undefined") {
		const functionName = get(filtersRenderMap, [module_id, field], "default");
		switch (functionName) {
			case "renderLabelValues":
				return renderLabelValues(value);
			case "renderLabelValuesWithRating":
				return renderLabelValues(value, true);
			case "renderBooleanValues":
				return renderBooleanValues(value);
			case "renderLocationDescription":
				return renderLocationDescription(value);
			case "renderLocationTypeValues":
				return renderLocationTypeValues(value);
			case "renderSalaryValue":
				return renderSalaryValue(value, field);
			case "renderSkillsValues":
				return renderSkillsValues(value);
			case "renderFunctionsValues":
				return renderFunctionsValues(value);
			case "renderDateValues":
				return renderDateValues(value);
			default:
				return value || "";
		}
	}
};

const renderDateValues = dates => {
	if (dates) {
		if ("start" && "end" in dates) {
			const date_start = formatDate(dates.start.toDate());
			const date_end = formatDate(dates.end.toDate());

			return `${date_start} and ${date_end}`;
		} else {
			return formatDate(dates.toDate());
		}
	}
};

export const renderLocationValues = location => {
	if (!location) {
		return "";
	}
	if (get(location, "description", "") !== "") {
		return get(location, "description");
	}
	return `${get(location, "number", "") && get(location, "number") + " "}${get(
		location,
		"box",
		""
	) && get(location, "box", "") + " "}${get(location, "street", "") &&
		get(location, "street", "") + " "}${get(location, "city", "") &&
		get(location, "city", "") + " "}${get(location, "country", "") &&
		get(location, "country", "") + " "}${get(location, "zip")}`;
};

const renderSkillsValues = skills => {
	return (skills || [])
		.map(skill => {
			return (
				skill.children.map(s => `${s.label} ${s.rating}/5`).join(" - ") +
				` (${skill.label})`
			);
		})
		.join(" - ");
};

const renderFunctionsValues = functions => {
	return (functions || [])
		.map(funtcion => {
			return (
				funtcion.children.map(f => f.label).join(" - ") + ` (${funtcion.label})`
			);
		})
		.join(" - ");
};

const renderBooleanValues = b => {
	return b ? "Yes" : "No";
};

const renderLocationTypeValues = location_type => {
	if (location_type === "on_site") {
		return "On site";
	} else if (location_type === "full_remote") {
		return "Full remote";
	} else {
		return "";
	}
};

const renderLabelValues = (values, withRating = false) => {
	return get(values, "[0].children", [])
		.map(value => {
			return value.label + (withRating ? ` ${value.rating}/5` : "");
		})
		.join(" - ");
};

const renderLocationDescription = value => {
	return (
		get(value, "[0].place.description", "") +
		(+get(value, "[0].radius", 0) > 0
			? " - " + get(value, "[0].radius", 0) + " km"
			: "")
	);
};

const renderSalaryValue = (value, field = "") => {
	if (
		!isNumber(value) &&
		["matching_rate", "matching_score", "amount", "cost", "rate"].includes(
			field
		)
	) {
		return value ? "Yes" : "No";
	}
	return value / 100;
};

export const isEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};

export const validFiltersCount = filters => {
	let valid_filters = [];
	if (filters) {
		valid_filters = filters.filter(filter => {
			return !isEmpty(filter.value);
		});
		return valid_filters.length;
	}
	return 0;
};

export const getSourceByKey = (sources, key) => {
	for (const source in sources) {
		if (sources[source].value === key) {
			return sources[source];
		}
	}
};

const formatDate = date => {
	return new Intl.DateTimeFormat("en-BE", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric"
	}).format(date);
};
