import { FILTERS } from "modules/MessagingTool/utils/constant";
import { MESSAGING_TOOL_LINKS } from "./constant";
import get from "lodash/get";

export function formatData(data = [], hash, currentUserEmail) {
	const path = [
		MESSAGING_TOOL_LINKS.sent.slug,
		MESSAGING_TOOL_LINKS.schedule.slug,
		MESSAGING_TOOL_LINKS.draft.slug
	].includes(hash)
		? "to"
		: "from";
	return data.map(email => {
		return {
			_id: email._id,
			attachments: email.attachments,
			to: email.to,
			bcc: email.bcc,
			cc: email.cc,
			date:
				hash === MESSAGING_TOOL_LINKS.trash.slug
					? email.deleted_at
					: hash === MESSAGING_TOOL_LINKS.schedule.slug
					? email.scheduled_at
					: hash === MESSAGING_TOOL_LINKS.draft.slug
					? email.created_at
					: email.sent_at || email.created_at,
			unread: email.unread,
			folders: email.folders,
			subject: email.subject,
			email_subject: email.email_subject,
			email_content: email.email_content,
			body: email.snippet,
			content: email.content,
			content_placeholders: email.content_placeholders || [],
			replies_participants: (email.replies_participants || [])
				.map(user => ({
					name:
						user.email[0] === currentUserEmail
							? "Me"
							: user.name[0] || user.email[0],
					avatar: user.avatar[0] || ""
				}))
				.filter(user => Boolean(user.name))
				.reverse(),
			count_replies: email.count_replies,
			send_failed: email.send_failed,
			parent_id: email.parent_id,
			user: {
				email: get(email, `${path}[0].email`, ""),
				_id: get(email, `${path}[0].id`, ""),
				name: get(email, `${path}[0].name`, ""),
				avatar: get(email, `${path}[0].avatar`, ""),
				user_function: get(email, `${path}[0].user_function`, "")
			}
		};
	});
}

export const addFiltersToUrl = filters => {
	const queryParam = new URLSearchParams();

	Object.entries(filters).forEach(([key, value]) => {
		if (value)
			if (Array.isArray(value)) {
				if (value.length !== 0) {
					const values = value.join(",");
					queryParam.append(key, values);
				}
			} else {
				queryParam.append(key, value);
			}
	});

	return queryParam.toString();
};

export const getFilterObjectFromQuery = query => {
	const fromMatch = query.match(/from:\((.*?)\)/);
	const from = fromMatch ? fromMatch[1].trim().split(",") : [];

	const toMatch = query.match(/to:\((.*?)\)/);
	const to = toMatch ? toMatch[1].trim().split(",") : [];

	const subjectMatch = query.match(/subject:\((.*?)\)/);
	const subject = subjectMatch ? subjectMatch[1].trim() : null;

	const hasAttachmentMatch = query.match(/has:attachment/);
	const hasAttachment = !!hasAttachmentMatch;

	const wordsMatch = query.match(/words:\((.*?)\)/);
	const words = wordsMatch ? wordsMatch[1].trim() : "";

	const remainingWords = query
		.replace(
			/from:\((.*?)\)|to:\((.*?)\)|subject:\((.*?)\)|has:attachment|words:\((.*?)\)/g,
			""
		)
		.trim();

	return {
		from: from,
		to: to,
		subject: subject,
		has_attachment: hasAttachment,
		words: words + " " + remainingWords
	};
};

export const formatFilterToQuery = filters => {
	if (!filters) return "";
	const { from, to, subject, has_attachment, words } = filters;
	const queryParams = [
		from.length !== 0 ? `from:(${from.join(",")})` : "",
		to.length !== 0 ? `to:(${to.join(",")})` : "",
		subject ? `subject:(${subject})` : "",
		has_attachment ? "has:attachment" : "",
		words ? `words:(${words})` : ""
	]
		.filter(Boolean)
		.join(" ");

	return queryParams;
};

export const formatFilters = filters => {
	if (!filters) return [];
	const fields = [];
	Object.entries(filters).forEach(([key, value]) => {
		if (value)
			fields.push({
				field: FILTERS[key].name,
				op: FILTERS[key].op,
				value
			});
	});

	return fields;
};

export const filterIsEmpty = filters => {
	if (!filters) return true;
	return (
		filters.to.length === 0 &&
		filters.from.length === 0 &&
		!filters.subject &&
		!filters.words
	);
};
