import { getHourlyCost } from "common/Functions";
import { FREELANCER_TYPES } from "../components/_CreateMissionUtils/constant";

export const calculateCost = ({ rate_type, type, rate, settings }) => {
	const { vms_settings } = settings;

	const vmsSettings =
		type === FREELANCER_TYPES[0].value
			? vms_settings.freelancer
			: vms_settings.consultancy;

	const reportingSettings = {
		rate_type: rate_type
	};

	const cost = getHourlyCost(rate, vmsSettings, reportingSettings);
	return Number(cost).toFixed(2);
};
