import React, { useState, useEffect } from "react";
import {
	DialogClose,
	Dialog,
	DialogContent,
	DialogTitle
} from "modules/consultancy/search/CreateNewSupplier/Dialog";
import EditableList from "common/editablelist";
import { getLanguageTooltip } from "config/helpers";
import { notSelectedElements } from "modules/job/selectors";
import s from "./languages.module.scss";
import useGetLanguages from "modules/candidate/Profile/api/useGetLanguages";

const LanguagesModal = ({
	selectedLanguages,
	saveLanguages,
	closeOnSave = true,
	isOpen,
	onClose,
	onLanguageRemove,
	onScoreChange,
	onLanguageSelect,
	onCancelButtonClick
}) => {
	useEffect(() => {
		setLanguages(selectedLanguages);
	}, [selectedLanguages]);

	const { data } = useGetLanguages();

	const [languages, setLanguages] = useState(selectedLanguages);

	const selectLanguage = element => {
		if (element) {
			const selectedLangs = [
				...languages,
				{
					_id: element.value._id,
					name: element.label,
					score: 3
				}
			];
			setLanguages(selectedLangs);
		}
		onLanguageSelect();
	};

	const removeLanguage = element => {
		let selectedLangs = languages.filter(l => l._id !== element.value._id);
		setLanguages(selectedLangs);
		onLanguageRemove();
	};

	const editLanguageScore = (element, score) => {
		let selectedLangs = languages.map(l => {
			return l._id === element.value._id
				? {
						...l,
						score
				  }
				: l;
		});
		setLanguages(selectedLangs);
		onScoreChange();
	};

	const save = () => {
		saveLanguages(languages);
		if (closeOnSave) {
			onClose();
		}
	};

	const formatLanguages = langs =>
		langs.map(l => ({
			value: { score: 3, ...l },
			label: l.language
		}));

	return (
		<Dialog open={isOpen}>
			<DialogContent onEscapeKeyDown={onClose}>
				<DialogTitle>Languages</DialogTitle>
				<div className="content">
					<EditableList
						minHeight={false}
						options={formatLanguages(
							notSelectedElements(data, languages, "name", "language")
						)}
						simpleValue={false}
						itemsLabel="LANGUAGE"
						scoreLabel="PROFICIENCY"
						scorePath="value.score"
						idPath="value._id"
						labelName="value.name"
						onRowChange={selectLanguage}
						removeSelected={removeLanguage}
						tooltipFormater={getLanguageTooltip}
						handleScoreChange={editLanguageScore}
						selectedOptions={formatLanguages(languages)}
						placeholder="Select..."
					/>
				</div>
				<div className={s.dialogFooter}>
					<button
						onClick={() => {
							setLanguages(selectedLanguages);
							onClose();
							onCancelButtonClick();
						}}
						className={s.cancelButton}
					>
						Cancel
					</button>
					<button className={s.mainButton} onClick={save}>
						Save
					</button>
				</div>{" "}
				<DialogClose
					className={s.dialogClose}
					onClick={() => {
						setLanguages(selectedLanguages);
						onClose();
					}}
				>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
};

export default LanguagesModal;
