import React, { useState, useEffect, memo } from "react";
import _forEach from "lodash/forEach";
import * as DateUtils from "./utils";
import cx from "classnames";

const TimeUnit = {
	HOUR: "hour",
	MINUTE: "minute"
};

const ENTER = 13;
const ARROW_UP = 38;
const ARROW_DOWN = 40;

function TimePicker({
	defaultValue,
	disabled = false,
	onChange = () => {},
	maxTime = DateUtils.getDefaultMaxTime(),
	minTime = DateUtils.getDefaultMinTime(),
	value: propsValue,
	className,
	showSeparator = true,
	rootClassName,
	isReadonly = false
}) {
	let value = minTime;
	if (propsValue) {
		value = propsValue;
	} else if (defaultValue) {
		value = defaultValue;
	}

	const getStateFromValue = value => {
		const timeInRange = DateUtils.getTimeInRange(value, minTime, maxTime);
		return {
			hourText: timeInRange.hours(),
			minuteText: formatTime(timeInRange.minutes()),
			value: timeInRange
		};
	};

	const [state, setState] = useState(getStateFromValue(value));
	const [hourText, setHourText] = useState(state.hourText);
	const [minuteText, setMinuteText] = useState(state.minuteText);

	useEffect(() => {
		if (propsValue) {
			let value = state.value;
			value = DateUtils.getTimeInRange(state.value, minTime, maxTime);
			if (
				propsValue !== null &&
				!DateUtils.areSameTime(propsValue, state.value)
			) {
				value = propsValue;
			}
			setState(getStateFromValue(value));
		}
	}, [propsValue]);

	useEffect(() => {
		setTimeout(() => {
			setHourText(getStateFromValue(state.value).hourText);
			setMinuteText(getStateFromValue(state.value).minuteText);
		}, 0);
	}, [state.value]);

	const updateState = value => {
		let newState = value;
		const hasNewValue =
			newState.value !== null &&
			!DateUtils.areSameTime(newState.value, state.value);
		if (propsValue) {
			if (hasNewValue) {
				newState = getStateFromValue(newState.value);
			}
			setState(newState);
		} else {
			if (hasNewValue) {
				setState(getStateFromValue(state.value));
			} else {
				setState({ ...newState, value: newState.value.clone() });
			}
		}
		if (hasNewValue) onChange(newState.value);
	};

	const updateTime = (time, unit) => {
		const newValue = state.value.clone();
		setTimeUnit(unit, time, newValue);
		if (DateUtils.isTimeInRange(newValue, minTime, maxTime)) {
			updateState({ ...state, value: newValue });
		} else {
			updateState(getStateFromValue(state.value));
		}
	};

	const wrapTimeAtUnit = (unit, time) => {
		const max = DateUtils.getTimeUnitMax(unit);
		const min = DateUtils.getTimeUnitMin(unit);

		if (time > max) {
			return min;
		} else if (time < min) {
			return max;
		}
		return time;
	};

	const getTimeUnit = (unit, date) => {
		switch (unit) {
			case TimeUnit.HOUR:
				return date.hours();
			case TimeUnit.MINUTE:
				return date.minutes();
			default:
				throw Error("Invalid TimeUnit");
		}
	};

	const setTimeUnit = (unit, time, date) => {
		switch (unit) {
			case TimeUnit.HOUR:
				date.hours(time);
				break;
			case TimeUnit.MINUTE:
				date.minutes(time);
				break;
			default:
				throw Error("Invalid TimeUnit");
		}
		return date;
	};

	const shiftTime = (unit, amount) => {
		if (disabled) {
			return;
		}
		const newTime = getTimeUnit(unit, state.value) + amount;
		updateTime(wrapTimeAtUnit(unit, newTime), unit);
	};

	const incrementTime = unit => shiftTime(unit, 1);
	const decrementTime = unit => shiftTime(unit, -1);

	const getInputBlurHandler = (e, unit) => {
		updateTime(parseInt(e.target.value, 10), unit);
	};

	const getInputChangeHandler = (e, unit) => {
		const TWO_DIGITS = /^\d{0,2}$/;
		const text = e.target.value;
		if (TWO_DIGITS.test(text)) {
			switch (unit) {
				case TimeUnit.HOUR:
					setHourText(text);
					break;
				case TimeUnit.MINUTE:
					setMinuteText(text);
					break;
				default:
					throw Error("Invalid TimeUnit");
			}
		}
	};

	const getInputKeyDownHandler = (e, unit) => {
		handleKeyEvent(e, {
			[ARROW_UP]: () => incrementTime(unit),
			[ARROW_DOWN]: () => decrementTime(unit),
			[ENTER]: () => {
				e.currentTarget.blur();
			}
		});
	};

	const handleKeyEvent = (e, actions, preventDefault = true) => {
		_forEach(Object.keys(actions), k => {
			const key = Number(k);
			if (e.which === key) {
				if (preventDefault) {
					e.preventDefault();
				}
				actions[key]();
			}
		});
	};

	const handleFocus = e => {
		e.currentTarget.select();
	};

	const renderInput = (unit, value, style) => {
		return (
			<input
				onBlur={e => getInputBlurHandler(e, unit)}
				onChange={e => getInputChangeHandler(e, unit)}
				onKeyDown={e => getInputKeyDownHandler(e, unit)}
				onFocus={handleFocus}
				value={value}
				disabled={disabled}
				className={`form-control ${unit} ${className}`}
				style={style}
				readOnly={isReadonly}
			/>
		);
	};
	return (
		<div
			className={cx("time-picker-input", rootClassName)}
			style={{ display: "flex", alignItems: "center" }}
		>
			{renderInput(TimeUnit.HOUR, hourText, {
				textAlign: "center",
				maxWidth: 70
			})}
			{showSeparator && (
				<span style={{ fontWeight: 500, margin: "0 5px" }}>:</span>
			)}
			{renderInput(TimeUnit.MINUTE, minuteText, {
				textAlign: "center",
				maxWidth: 70
			})}
		</div>
	);
}

function formatTime(time) {
	return time.toString().padStart(2, "0");
}

export default memo(TimePicker);
