import { useQuery } from "react-query";
import { client } from "../../../lib/api-client";
import { LIST_SUPPLIER_INVITATIONS_ENDPOINT } from "../../../config/api-endpoints";

function listInvitations(body) {
	return client(LIST_SUPPLIER_INVITATIONS_ENDPOINT, {
		body
	});
}

export const supplierHistoryQueryKey = "@supplier/list-invitations";

function useListSupplierInvitations({
	search,
	supplier_id,
	category_id,
	tier_id,
	tag = "supplier_history",
	offset,
	limit
} = {}) {
	return useQuery(
		[
			supplierHistoryQueryKey,
			supplier_id,
			search,
			category_id,
			tier_id,
			tag,
			offset,
			limit
		],
		() =>
			listInvitations({
				supplier_id,
				search,
				category_id,
				tier_id,
				tag,
				offset: offset ?? 0,
				limit: limit ?? 5
			}),
		{
			refetchOnWindowFocus: false,
			retry: 0
		}
	);
}

export default useListSupplierInvitations;
