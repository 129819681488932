import React from "react";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";

const ConfirmInterestModal = ({
	confirmInterest,
	toggleConfirmModal,
	displayConfirmModal
}) => {
	return (
		<>
			<ConfirmationModal
				key="confirm_interest_modal"
				onClose={toggleConfirmModal}
				active={displayConfirmModal}
				modal_name="confirm_interest_modal"
				type={ConfirmationTypes.success}
				title="Are you sure you would like to confirm your interest for this vacancy ?"
				content="Once your interest is confirmed, your profile will no longer be anonymous to this company for this vacancy and they will be able to contact you and/or invite you for a first interview."
				firstButton={{
					action: () => {
						confirmInterest();
					},
					label: "YES, CONFIRM INTEREST.",
					type: "success"
				}}
				secondButton={{
					action: toggleConfirmModal,
					label: "NO"
				}}
			/>
		</>
	);
};

export default ConfirmInterestModal;
