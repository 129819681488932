import React, { useState, memo } from "react";
import FilterPill from "common/FilterPill/FilterPill.js";
import styles from "./styles.module.scss";
import get from "lodash/get";

import { ReactComponent as ClearIcon } from "static/icons/clear_icon.svg";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { isEmpty } from "common/FilterDrawer/render_utils";
import loadable from "loadable-components";
import ClearModal from "modules/freelancers/components/ClearModal/ClearModal";

const DeleteNotesFilterModal = loadable(() =>
	import(
		"modules/freelancers/components/DeleteFreelancersFilterModal/DeleteFreelancersFilterModal"
	)
);

const SaveNotesFilterModal = loadable(() =>
	import(
		"modules/freelancers/components/SaveFreelancersFilterModal/SaveFreelancersFilterModal SaveFreelancersFilterModal"
	)
);

const NotesFiltersRecap = ({ filters, module_id, selectedCriteria }) => {
	const [showSaveModal, setShowSaveModal] = useState(false);
	const [showClearModal, setShowClearModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { handleFilterDelete, setShowDrawer, setSelectedFilter } = useFilters();

	const deleteFilterHandler = id => {
		handleFilterDelete(id);
	};

	const displayModalHandler = type => {
		switch (type) {
			case "save":
				setShowSaveModal(true);
				break;
			case "clear":
				setShowClearModal(true);
				break;
			case "delete":
				setShowDeleteModal(true);
				break;
		}
	};

	return (
		<>
			<div className={styles.filters_bar}>
				<div
					className={styles.scrollContainer}
					onClick={() => setShowDrawer(true)}
				>
					<OverlayScrollbarsComponent style={{ maxWidth: "100%" }}>
						{filters?.map((filter, index) => {
							if (!isEmpty(filter.value)) {
								return (
									<div key={index} className={styles.pill_container}>
										<FilterPill
											module_id={module_id}
											filter={filter}
											removable
											onRemove={deleteFilterHandler}
											onClick={() => {
												setSelectedFilter(get(filter, "id", ""));
												setShowDrawer(true);
											}}
										/>
									</div>
								);
							}
						})}
					</OverlayScrollbarsComponent>
				</div>

				<div className={styles.btn_container}>
					<div className={styles.rightContainer}>
						<HeaderButton
							icon={<ClearIcon />}
							text={"Clear"}
							className="buttonClear"
							onClick={() => displayModalHandler("clear")}
						/>
					</div>
				</div>
			</div>

			{showSaveModal && (
				<SaveNotesFilterModal
					filters={filters}
					onClose={() => setShowSaveModal(false)}
				/>
			)}

			{showClearModal && (
				<ClearModal onClose={() => setShowClearModal(false)} />
			)}

			{showDeleteModal && (
				<DeleteNotesFilterModal
					criteria={selectedCriteria}
					onClose={() => setShowDeleteModal(false)}
				/>
			)}
		</>
	);
};

export default memo(NotesFiltersRecap);
