import { ReactComponent as InboxIcon } from "static/icons/quick-add/inbox.svg";
import { ReactComponent as SendIcon } from "static/icons/send-03.svg";
import { ReactComponent as ScheduleIcon } from "static/icons/schedule.svg";
import { ReactComponent as FolderIcon } from "static/icons/folder2.svg";
import { ReactComponent as ArchiveIcon } from "static/icons/archive.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-icon.svg";

export const MESSAGING_TOOL_LINKS = {
	inbox: {
		label: "inbox",
		Icon: InboxIcon,
		slug: "inbox"
	},
	sent: {
		label: "sent",
		Icon: SendIcon,
		slug: "sent"
	},
	schedule: {
		label: "scheduled",
		Icon: ScheduleIcon,
		slug: "scheduled"
	},
	draft: {
		label: "draft",
		Icon: FolderIcon,
		slug: "draft"
	},
	archive: {
		label: "archive",
		Icon: ArchiveIcon,
		slug: "archive"
	},
	trash: {
		label: "trash",
		Icon: TrashIcon,
		slug: "trash"
	}
};

export const FROM_INPUT_LABEL = "FROM";
export const TO_INPUT_LABEL = "TO";

export const FROM = "from";
export const TO = "to";
export const SUBJECT = "subject";
export const HAS_WORDS = "has_words";
export const WORDS = "words";
export const HAS_ATTACHMENT = "has_attachment";
export const PARTICIPANTS_AUTOCOMPLETE_FILTER = "autocomplete_participants";
export const USERS_AUTOCOMPLETE = "autocomplete_users";
export const PARTICIPANTS_LIMIT = 5;
export const SYNC_FINISHED = "finished";
export const SYNC_IN_PROGRESS = "in_progress";
export const SYNC_FAILED = "failed";

export const FILTERS = {
	from: {
		name: "from",
		op: "is_any",
		type: "array"
	},
	to: {
		name: "to",
		op: "is_any",
		type: "array"
	},
	words: {
		name: "words",
		op: "contains",
		type: "text"
	},
	subject: {
		name: "subject",
		op: "contains",
		type: "text"
	},
	has_attachment: {
		name: "attachments",
		op: "is",
		type: "boolean"
	}
};
