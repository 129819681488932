import { useEffect, useRef, useState } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { VIEW_USER_NOTES } from "config/api-endpoints";
import { client } from "lib/api-client";
import { usePaginatedQuery } from "react-query";
import { useDebounce } from "react-use";
import isEqual from "lodash/isEqual";
import { NOTES_FILTER_ID, VACANCY_NOTES_FILTER_ID } from "../util/constants";
import { utcTimeStamp } from "config/helpers";
import { HOW_TO_WORK_WITH_NOTES } from "config/onboarding";
import fakeNotes from "modules/user-onboarding/mockData/notes.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

export const filterData = body => {
	return client(VIEW_USER_NOTES, {
		body
	});
};

export const KEY_FETCH_NOTES = "KEY_FETCH_NOTES";
export const KEY_FETCH_VACANCY_NOTES = "KEY_FETCH_NOTES";

const useFilterNotes = (id, isCandidateView, enabled) => {
	const {
		setQueryError,
		setIsLoading,
		setIsFetching,
		getState,
		setData,
		state
	} = useFilters();

	let filterId = NOTES_FILTER_ID;
	let queryKey = KEY_FETCH_NOTES;
	let tag = "profiles_notes";

	if (!isCandidateView) {
		filterId = VACANCY_NOTES_FILTER_ID;
		queryKey = KEY_FETCH_VACANCY_NOTES;
		tag = "list_notes";
	}

	const {
		query,
		logicalOperator,
		filters,
		sortBy,
		offset,
		filterChangeTrigger,
		limit
	} = getState(filterId);

	const previousFilters = useRef();
	const [body, setBody] = useState();

	const { workflowId } = useOnboardingStore();
	const fetchData =
		workflowId === HOW_TO_WORK_WITH_NOTES
			? () => new Promise(r => r(fakeNotes))
			: () => filterData(body);

	const {
		resolvedData: data,
		isLoading,
		isFetching,
		refetch,
		isFetched
	} = usePaginatedQuery([queryKey, body], fetchData, {
		onError: e => {
			if (e?.detail?.keywords) {
				setQueryError(true);
			}
		},
		refetchOnWindowFocus: false,
		enabled: !!body && enabled
	});

	useEffect(() => {
		setIsLoading(isLoading);
		setIsFetching(isFetching && !isFetched);
	}, [isLoading, isFetching, refetch, isFetched]);

	useEffect(() => {
		setData(data);
		setQueryError(false);
	}, [data]);

	useEffect(() => {
		const validFilters = getValidFilters(filters);
		const globalFilter = {
			validFilters,
			sortBy,
			offset,
			logicalOperator,
			limit,
			id
		};

		const isFiltersChanged = !isEqual(
			{ ...previousFilters.current },
			globalFilter
		);

		if (isFiltersChanged) {
			previousFilters.current = globalFilter;
		}

		if (
			(filterChangeTrigger !== "change" &&
				filterChangeTrigger !== "newFilter" &&
				isFiltersChanged) ||
			(!!id && state.has(filterId)) ||
			filterChangeTrigger === "reset" ||
			filterChangeTrigger === "limitChange"
		) {
			setBody(formatBody());
		}
	}, [
		filters,
		sortBy,
		offset,
		filterChangeTrigger,
		logicalOperator,
		limit,
		id
	]);

	useDebounce(
		() => {
			if (filterChangeTrigger === "change") {
				setBody(formatBody());
			}
		},
		500,
		[filters, query]
	);

	function reduceItems(items) {
		return items.reduce((acc, item) => [...acc, ...item.children], []);
	}

	function formatDate(filter) {
		const { value, operator, name } = filter;

		if (!value) {
			return {
				field: name,
				value: null,
				op: operator
			};
		}
		let valueFormatted;

		if (!value.start) {
			const startDate = utcTimeStamp({
				date: convertCalendarDateToMS(value)
			});

			const endDate = utcTimeStamp({
				date: convertCalendarDateToMS(value),
				isStart: false
			});
			valueFormatted = [startDate, endDate];
		} else {
			const startDate = utcTimeStamp({
				date: convertCalendarDateToMS(value.start)
			});

			const endDate = utcTimeStamp({
				date: convertCalendarDateToMS(value.end),
				isStart: false
			});

			valueFormatted = [startDate, endDate];
		}

		return {
			field: name,
			value: valueFormatted,
			op: operator
		};
	}

	function convertCalendarDateToMS(date) {
		const { year, month, day } = date;
		var dateObject = new Date(`${year}/${month}/${day}`);

		return dateObject;
	}

	function formatFilters(filters) {
		const definedFilters = filters.filter(({ value }) => {
			//Be careful about false value, filtering a filter with false value is a bug
			//In case of expected salary, entering 0 will be filtered, the ideal should be including the salary to the filter!
			if (
				[undefined, null, "", 0].includes(value) ||
				(Array.isArray(value) && value.length === 0) ||
				(value !== null &&
					typeof value === "object" &&
					Object.keys(value).length === 0)
			) {
				return false;
			} else {
				return true;
			}
		});
		const formattedFilters = definedFilters.map(filter => {
			const { name, operator, value } = filter;

			if (name === "created_at") {
				return formatDate(filter);
			} else {
				let filterValue;

				if (Array.isArray(value)) {
					filterValue = reduceItems(value).map(({ id }) => id);
				} else {
					filterValue = value;
				}

				if (name === "vacancy" || name === "profile") {
					return {
						field: "linked_to_notes",
						op: operator,
						value: {
							_id: filterValue,
							entity: name
						}
					};
				} else {
					return {
						field: name,
						op: operator,
						value: filterValue
					};
				}
			}
		});

		return formattedFilters;
	}

	function formatBody() {
		let subData;

		if (isCandidateView) {
			subData = {
				id
			};
		} else {
			subData = {
				vacancy_id: id
			};
		}

		return {
			op: logicalOperator,
			fields: formatFilters(filters),
			search: query,
			offset,
			limit,
			tag,
			field: "created_at",
			sort: Object.keys(sortBy).length === 0 ? "desc" : " asc",
			...subData
		};
	}

	function getValidFilters(filters) {
		//Be careful about false value, filtering a filter with false value is a bug
		//In case of expected salary, entering 0 will be filtered, the ideal should be including the salary to the filter!
		return filters.filter(({ value }) => {
			if (
				[undefined, null, "", 0].includes(value) ||
				(Array.isArray(value) && value.length === 0) ||
				(value !== null &&
					typeof value === "object" &&
					Object.keys(value).length === 0)
			) {
				return false;
			} else {
				return true;
			}
		});
	}

	return refetch;
};

export default useFilterNotes;
