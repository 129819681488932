import Avatar from "common/Avatar";
import React from "react";
import cx from "classnames";
import classnames from "./renderUser.module.scss";
import { ReactComponent as NoUser } from "static/icons/no-user-icon.svg";

const RenderUser = ({ avatar, name, jobTitle, isExternal, isEmpty }) => {
	return (
		<div
			className={cx(classnames.container, {
				[classnames.centerElement]: isEmpty
			})}
		>
			{isEmpty ? (
				<React.Fragment>
					<NoUser />
					<span className={classnames.emptyText}>Not assigned</span>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Avatar
						className={classnames.avatar}
						classNameOnly
						size={30}
						avatar={avatar}
						name={name}
					/>
					<div className={classnames.userDetail}>
						<span className={classnames.name}>{name}</span>
						<span className={classnames.jobTitle}>{jobTitle}</span>

						<span
							className={cx(classnames.status, {
								[classnames.external]: isExternal
							})}
						>
							{isExternal ? "External" : "Internal"}
						</span>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default RenderUser;
