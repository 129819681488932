import React, { memo, useContext, useState } from "react";
import styled, { css } from "styled-components";
import _groupBy from "lodash/groupBy";
import { BOT_ACTION_TYPES, BotContext } from "./botContext";
import DropdownAnswer from "./DropdownAnswer";

export const OptionButton = styled.button`
	border: 2px solid #d0d5d9;
	background: white;
	color: #3d4b60;
	font-family: Roboto, sans-serif;
	font-size: 14px;
	border-radius: 3px;
	padding: 7px 15px;
	margin: 5px;
	font-weight: normal;
	&:hover {
		border-color: ${props =>
			props.disabled && !props.active ? "#d0d5d9" : "#ff6849"};
		color: ${props =>
			props.disabled && !props.active ? "#3d4b60" : "#ff6849"} !important;
	}
	${props =>
		props.active &&
		css`
			border-color: #ff6849;
			color: #ff6849 !important;
		`};
	&.group-container {
		.fa {
			margin-left: 7px;
		}
	}
`;

function ButtonAnswer({
	triggerNextStep,
	emulateSubmitUserMessage,
	options,
	value
}) {
	const { state, dispatch } = useContext(BotContext);
	const [selectedGroup, setSelectedGroup] = useState("");

	const handleChange = selected => {
		let action = {};
		let trigger = 2;
		switch (options) {
			case "subSectors":
				action = {
					type: BOT_ACTION_TYPES.SET_SELECTED_SUB_SECTOR,
					subSector: selected
				};
				trigger = 4;
				break;
			case "functions":
				action = {
					type: BOT_ACTION_TYPES.SET_SELECTED_FUNCTION,
					func: selected
				};
				trigger = 6;
				break;
			case "seniority":
				action = {
					type: BOT_ACTION_TYPES.SET_SELECTED_SENIORITY,
					seniority: selected
				};
				trigger = 8;
				break;
			default:
				action = {
					type: BOT_ACTION_TYPES.SET_SELECTED_SECTOR,
					sector: selected
				};
		}
		dispatch(action);
		emulateSubmitUserMessage(selected.name || selected.label);
		triggerNextStep({ value: selected.name || selected.label, trigger });
	};

	const renderGroups = () => {
		const groups = _groupBy(state[options] || [], "group_name");
		return Object.keys(groups).map(key => {
			if (key && key !== "undefined") {
				const answers = groups[key];
				return (
					<DropdownAnswer
						key={key}
						group={key}
						disabled={!!state[value]}
						answers={answers}
						selected={state[value]}
						handleChange={handleChange}
						isOpen={!state[value] && selectedGroup === key}
						setSelected={setSelectedGroup}
					/>
				);
			}
		});
	};

	return (
		<div
			className={`form-group select-style`}
			style={{
				width: "100%",
				marginBottom: selectedGroup && state[value] && "100px"
			}}
		>
			{renderGroups()}
			{(state[options] || []).map(opt => {
				if (opt.group_name) {
					return;
				}
				return (
					<OptionButton
						key={opt._id || opt.value}
						onClick={
							state[value]
								? null
								: () => {
										setSelectedGroup("");
										return handleChange({
											value: opt._id || opt.value,
											name: opt.name || opt.label
										});
								  }
						}
						disabled={!!state[value]}
						active={
							state[value] &&
							(opt.name || opt.label) ===
								(state[value].name || state[value].label)
						}
					>
						{opt.name || opt.label}
					</OptionButton>
				);
			})}
		</div>
	);
}

export default memo(ButtonAnswer);
