//TODO Using the hook in multiple components trigger a problem!
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import { onlineUserSelector } from "modules/user/selectors/selectors";

export const isMultisiteActive = () => {
	const [isActive, setIsActive] = useState();
	const user = useSelector(onlineUserSelector);

	useEffect(() => {
		const features = _get(user, "features", []);
		const hasMultiSite = features.includes("feature_multisite");
		setIsActive(hasMultiSite);
	}, [user]);

	return isActive;
};
