import { client } from "lib/api-client";
import { useMutation } from "react-query";
import { UPDATE_USER_SETTING } from "config/api-endpoints";

const updateTableLimit = body => {
	return client(UPDATE_USER_SETTING, {
		body
	});
};

function useUpdateTableLimit() {
	return useMutation(updateTableLimit, {
		refetchOnWindowFocus: false,
		retry: 0
	});
}

export default useUpdateTableLimit;
