import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import Dashboard from "common/dashboard/Dashboard";
import Intro from "common/dashboard/Intro";
import { JPG, PNG, DOC, PDF, XLS, PPT, TIME_FORMAT_24 } from "config";
import { fetchUserFiles } from "../actions/filesActions";
import EmptyJob from "common/EmptyComponent";
import Pagination from "common/Pagination";
import {
	TimesheetTableDetails,
	Th,
	Tr,
	Td
} from "modules/timesheets/components/TimesheetStyled";
import { userFilesSelector } from "../selectors/selectors";
import { toggleHelpModal } from "modules/app/actions/ListsActions";
import { formatDate } from "common/Functions";
import SearchBox from "common/search/SearchBox";
import { SearchBoxContainer } from "./Styled";
import { getPolicyRoute } from "../../../common/privacyPolicy/components/PolicyContainer";
import { getTermsAndConditionsRoute } from "../../../common/termsAndConditions/components/TermsAndConditionsContainer";
import { userSelector } from "modules/company/selectors/ProfileSelector";
import Breadcumb from "modules/company/components/BreadcumbSettingPages";

export const getUserFilesRoute = () => {
	return "/settings/files";
};

export const getUserFilesPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			},
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "consultancy",
				roles: ["admin"]
			},
			{
				name: "permanent",
				roles: ["admin"]
			}
		]
	};
};

const UserFiles = ({
	list_files: { data, offset, size, total },
	fetchUserFiles,
	route: { title },
	user
}) => {
	const [search, setSearch] = useState("");

	const isClient = user.type === "client";

	useEffect(() => {
		fetchUserFiles({ offset: 0 });
	}, []);

	const handlePageClick = value => {
		const payload = {
			offset: size * value
		};
		fetchUserFiles(payload);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const payload = {
			search,
			offset: 0
		};
		fetchUserFiles(payload);
	};

	const handleKeywordChange = event => setSearch(event.target.value);

	const resetSearch = () => {
		setSearch("");
		fetchUserFiles({ offset: 0 });
	};

	const TYPE_MAP = {
		jpeg: JPG,
		jpg: JPG,
		png: PNG,
		docx: DOC,
		doc: DOC,
		pdf: PDF,
		xls: XLS,
		xlsx: XLS,
		ppt: PPT,
		pptx: PPT
	};

	const getFileExtension = file => {
		return TYPE_MAP[
			file
				.split(".")
				.pop()
				.toLowerCase()
		];
	};

	const showFilesList = () => {
		return data.map(file => {
			return (
				<Tr key={file._id}>
					<Td align="center">
						<div className="file-icon">
							<span className={`icon-${getFileExtension(file.path) || PDF}`}>
								<span className="path1" />
								<span className="path2" />
								<span className="path3" />
								<span className="path4" />
								<span className="path5" />
								<span className="path6" />
								<span className="path7" />
								<span className="path8" />
							</span>
						</div>
					</Td>
					<Td className="title">{file.file_name}</Td>
					<Td>
						<div>{formatDate(file.created_at)}</div>
						<div className="file-time">
							{formatDate(file.created_at, true, TIME_FORMAT_24)}
						</div>
					</Td>
					<Td align="center">
						<a href={file.path} className="download-file">
							<i className="icon-download" />
						</a>
					</Td>
				</Tr>
			);
		});
	};
	const is_empty = data.length <= 0;

	return (
		<BodyClassName className="gray-bg">
			<div className="notifications-setting">
				{title && (
					<Helmet>
						<title>{title}</title>
					</Helmet>
				)}

				<div className={!isClient ? "dashboard-box" : null}>
					{!isClient ? <Dashboard /> : null}
					<Intro isClient={isClient} icon="fa fa-file-text-o" title="My Files">
						<p>
							Any personal information contained in any specific documents saved
							on our platform will always be processed in accordance with our{" "}
							<Link
								className="button-link"
								target="_blank"
								to={getPolicyRoute()}
							>
								Privacy Policy{" "}
							</Link>
							and our{" "}
							<Link
								className="button-link"
								target="_blank"
								to={getTermsAndConditionsRoute()}
							>
								Website T&Cs
							</Link>
							.
						</p>
					</Intro>
				</div>
				{isClient ? (
					<Breadcumb
						parentItem="Settings"
						currentItem="My Files"
						pathCurrentItem="/settings/files"
					/>
				) : (
					<></>
				)}
				<div
					style={{
						padding: isClient ? "0 15px" : "0"
					}}
					className={isClient ? "full-container" : "container"}
				>
					<SearchBoxContainer>
						<SearchBox
							handleFormSubmit={handleSubmit}
							handleKeywordChange={handleKeywordChange}
							value={search}
							placeholder={"Start typing..."}
							hasReset={search.length > 0}
							resetSearch={resetSearch}
						/>
					</SearchBoxContainer>
					{is_empty && <EmptyJob title={"There is no file to download."} />}
					{!is_empty && (
						<>
							<TimesheetTableDetails className="request-list" spacing="0 3px">
								<thead>
									<Tr>
										<Th width="10%" />
										<Th width="55%">File Name</Th>
										<Th width="15%">Date added</Th>
										<Th width="20%" align="center">
											Download
										</Th>
									</Tr>
								</thead>
								<tbody>{showFilesList()}</tbody>
							</TimesheetTableDetails>
							<Pagination
								total={total}
								size={size}
								handlePageClick={handlePageClick}
								offset={offset}
							/>
						</>
					)}
				</div>
			</div>
		</BodyClassName>
	);
};

const mapStateToProps = state => {
	return {
		list_files: userFilesSelector(state),
		user: userSelector(state)
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ fetchUserFiles, toggleHelpModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserFiles);
