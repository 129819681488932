import React from "react";
import styles from "./email-template-list-item.module.scss";
import { ReactComponent as UserIcon } from "static/icons/email-template/user.svg";
import { ReactComponent as PenIcon } from "static/icons/email-template/pen.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-3.svg";
import { ReactComponent as DuplicateIcon } from "static/icons/duplicate-2.svg";
import { ReactComponent as TagIcon } from "static/icons/tag-2.svg";
import moment from "moment";
import { browserHistory } from "react-router";
import { getEmailTemplateRoute } from "modules/EmailTemplate";
import { CATEGORIES, CATEGORY } from "../../utils/constants";
import { useSelector } from "react-redux";
import cx from "classnames";

export default function EmailTemplateListItem({
	item,
	isActive,
	onClickDelete,
	onClickDuplicate
}) {
	const user = useSelector(state => state.auth.user);

	const onClickDeleteButton = e => {
		e.stopPropagation();
		onClickDelete();
	};

	const onClickDuplicateButton = e => {
		e.stopPropagation();
		onClickDuplicate();
	};

	const onClickItem = () => {
		browserHistory.replace(getEmailTemplateRoute(item._id));
	};

	const isOwner = item?.user_action?._id === user.id;

	return (
		<div
			className={cx(styles.item, { [styles.active]: isActive })}
			onClick={onClickItem}
		>
			<div className={styles.content}>
				<div className={styles.title}>{item.template_name}</div>
				<div className={styles.infos}>
					<div className={styles.categoryTag}>
						<TagIcon width={12} height={12} />
						<span>
							{CATEGORIES.find(c => c.value === item.category)?.label}
						</span>
					</div>
					{!!item.user_action && (
						<div className={styles.infoItem}>
							<div className={styles.icon}>
								<UserIcon />
							</div>
							<span className={styles.label}>
								{item.user_action?.first_name?.[0]}.
								{item.user_action?.last_name}
							</span>
						</div>
					)}
					{!!item.date_action && (
						<div className={styles.infoItem}>
							<div className={styles.icon}>
								<PenIcon />
							</div>
							<span className={styles.label}>
								{moment(item.date_action, "X").format("DD/MM/YYYY")}
							</span>
						</div>
					)}
				</div>
			</div>
			<div className={styles.actions}>
				<button
					type="button"
					onClick={onClickDuplicateButton}
					className={styles.actionButton}
				>
					<DuplicateIcon />
				</button>
				{(item.category !== CATEGORY.EVENTS || isOwner) && (
					<button
						type="button"
						onClick={onClickDeleteButton}
						className={styles.actionButton}
					>
						<TrashIcon />
					</button>
				)}
			</div>
		</div>
	);
}
