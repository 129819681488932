import React from "react";
import classnames from "./renderUser.module.scss";

const RenderUser = ({ name }) => {
	return (
		<div className={classnames.container}>
			<React.Fragment>
				<div className={classnames.userDetail}>
					<span className={classnames.name}>{name}</span>
				</div>
			</React.Fragment>
		</div>
	);
};

export default RenderUser;
