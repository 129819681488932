import Tooltip from "common/Tippy";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Star } from "./components/Star";
import styles from "./react-star.module.scss";
import cx from "classnames";

export default function ReactStar({ className, ...props }) {
	const {
		rating: defaultRating,
		onRate,
		titles = [],
		renderCustomStar
	} = props;
	const ref = useRef();

	const [state, setState] = useState({
		rating: props.rating,
		isRating: false
	});
	const { rating, isRating } = state;
	const [hoveredStar, setHoveredStar] = useState(-1);
	const lastRating = useRef(props.rating);

	function updateState(vals) {
		setState(prevState => ({ ...prevState, ...vals }));
	}

	function willRate(rating, index) {
		setHoveredStar(index);
		updateState({
			rating,
			isRating: true
		});
		const element = document.querySelector(".tippy-box");

		if (element && element.classList.contains(styles.tooltipContainer)) {
			element.classList.remove(styles.tooltipContainer);
		}
	}

	function rate(rating, e) {
		updateState({
			rating,
			isRating: false
		});
		lastRating.current = rating;
		onRate && onRate({ ...e, rating });
	}

	function cancelRate() {
		setHoveredStar(-1);
		updateState({
			rating: lastRating.current,
			isRating: false
		});
	}

	const onInputChange = e => {
		const value = e.target.value;

		updateState({
			rating: value,
			isRating: false
		});
		lastRating.current = value;
		onRate && onRate({ ...e, rating: value });
	};

	const onWheel = event => {
		if (isRating) {
			const elementUnderPointer = document.elementFromPoint(
				event.clientX,
				event.clientY
			);

			const element = ref.current;

			if (
				elementUnderPointer !== element &&
				!element.contains(elementUnderPointer)
			) {
				const ref = document.querySelector(".tippy-box");
				ref.classList.add(styles.tooltipContainer);
			}
		}
	};

	useEffect(() => {
		updateState({
			rating: defaultRating
		});
		lastRating.current = defaultRating;
	}, [defaultRating]);

	const nodes = Array.from(Array(5), (_, i) => {
		const isActive = (!isRating && rating - i >= 1) || (isRating && i < rating);

		return (
			<Tooltip
				key={`star-${i}`}
				content={<div>{titles[i]}</div>}
				theme="dark"
				enableAnimation={false}
				visible={hoveredStar === i}
				className={styles.tippy}
			>
				<div
					onClick={rate.bind(this, i + 1)}
					onMouseOver={willRate.bind(this, i + 1, i)}
				>
					{renderCustomStar ? (
						renderCustomStar(isActive)
					) : (
						<Star isActive={isActive} />
					)}
				</div>
			</Tooltip>
		);
	});
	return (
		<div
			onWheel={onWheel}
			onMouseLeave={cancelRate}
			className={cx(styles.reactRater, className)}
			ref={ref}
		>
			<input
				min={1}
				max={5}
				className={styles.input}
				type="range"
				value={rating}
				onChange={onInputChange}
			/>
			{nodes}
		</div>
	);
}

ReactStar.propTypes = {
	rating: PropTypes.number,
	onRate: PropTypes.func,
	titles: PropTypes.array
};

ReactStar.defaultProps = {
	rating: 0,
	titles: []
};
