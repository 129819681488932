import * as React from "react";

function LapTopIcon(props) {
	return (
		<svg
			width={18}
			height={16}
			viewBox="0 0 18 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M1.667 4.667a2 2 0 012-2h10.666a2 2 0 012 2v6.296a2.37 2.37 0 01-2.37 2.37H4.037a2.37 2.37 0 01-2.37-2.37v0-6.296z"
				stroke={props.stroke}
				strokeWidth={2}
			/>
			<path
				d="M1.667 10H5.78c.563 0 1.098.243 1.47.667v0c.37.423.905.666 1.468.666H9.5c.525 0 1.019-.247 1.333-.666v0c.315-.42.81-.667 1.334-.667h4.166"
				stroke={props.stroke}
				strokeWidth={2}
			/>
		</svg>
	);
}

export default LapTopIcon;
