import React from "react";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import { Draggable } from "react-beautiful-dnd";
import {
	DRAWER_PROPOSE_CONTRACT,
	DRAWER_REJECT_APPLICATION
} from "modules/RequestView/components/drawers/constants";
import { useTempDrawerHelpers } from "modules/RequestView/components/drawers/hooks/useTempDrawerHelpers";
import { RequestViewPipelineCard } from "modules/RequestView/components/RequestPipelineCard/RequestViewPipelineCard";

export default function KanbanCardWrapper({ index, record, stage, jobId }) {
	const {
		initializeAndOpenDrawer: openRejectApplicationDrawer
	} = useTempDrawerHelpers({
		drawerType: DRAWER_REJECT_APPLICATION
	});

	const {
		initializeAndOpenDrawer: openProposeContractDrawer
	} = useTempDrawerHelpers({
		drawerType: DRAWER_PROPOSE_CONTRACT
	});

	// drawers actions
	const onRejectApplication = () => {
		const contract = !_isArray(_get(record, "contract"))
			? _get(record, "contract")
			: null;

		const interview = !_isArray(_get(record, "interview"))
			? _get(record, "interview")
			: null;

		const payload = {
			bidId: record.bid_id,
			contractId: contract?._id,
			interviewId: interview?._id,
			_queryCacheProps: { jobId, stageId: stage._id }
		};
		openRejectApplicationDrawer({ drawerProps: payload });
	};

	const onProposeContract = () => {
		const contract = !_isArray(_get(record, "contract"))
			? _get(record, "contract")
			: null;

		const payload = {
			contract,
			bidId: record.bid_id,
			vmsSetting: _get(record, "vms_settings"),
			candidateName: _get(record, "candidate_name"),
			proposedAmount: _get(record, "proposed_amount"),
			reportingSetting: _get(record, "reporting_settings"),
			_queryCacheProps: { jobId, stageId: stage._id }
		};
		openProposeContractDrawer({ drawerProps: payload });
	};

	const getCardStyles = isDragging => {
		if (isDragging) {
			return {
				borderRadius: "12px",
				border: "1px solid #E2E8F0",
				background: "#FFF",
				boxShadow: "0px 12px 56px -2px rgba(80, 92, 115, 0.16)"
			};
		}

		return {};
	};

	return (
		<Draggable index={index} draggableId={record._id}>
			{(provided, snapshot) => {
				return (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<RequestViewPipelineCard
							data={record}
							onProposeContract={onProposeContract}
							style={getCardStyles(snapshot.isDragging)}
							onRejectApplication={onRejectApplication}
						/>
					</div>
				);
			}}
		</Draggable>
	);
}
