import { useEffect } from "react";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

export const useSetCreateRequestBreadcrumb = () => {
	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([{ name: "Temporary" }, { name: "Request" }]);
	}, []);
};
