import React, { useEffect, useState } from "react";
import { isSameDay } from "@internationalized/date";
import styles from "./add-timesheet-entries-date-picker.module.scss";
import { DatePicker } from "common/DatePicker";
import { ReactComponent as CalendarIconGrey } from "static/icons/calendar-icon-grey.svg";
import { connect } from "react-redux";
import { formatDateToCalendarObject } from "config/helpers";
import ToggleButton from "common/ToggleButton";
import Tooltip from "common/Tippy";
import { ReactComponent as CalendarIcon } from "static/icons/check-calendar.svg";
import { ReactComponent as TooltipIcon } from "static/icons/tooltipIcon.svg";

const AddTimesheetEntriesDatePicker = ({
	onChange: onChangeProp,
	isMultiple,
	timesheet
}) => {
	useEffect(() => {
		if (!isMultiple) setDates([]);
	}, [isMultiple]);

	const [dates, setDates] = useState([]);

	const minValue = formatDateToCalendarObject(timesheet.data.start_date, true);
	const maxValue = formatDateToCalendarObject(timesheet.data.end_date, true);

	const onConfirm = () => {
		onChangeProp(dates);
	};

	const onClear = () => {
		setDates([]);
	};

	const onChange = date => {
		if (isMultiple) {
			if (dates.find(item => isSameDay(item, date))) {
				setDates(dates.filter(item => !isSameDay(item, date)));
			} else {
				setDates([...dates, date]);
			}
		} else {
			setDates([date]);
		}
	};

	const onSelectAll = values => {
		if (isMultiple) {
			const filteredValues = values.filter(date => {
				const { year, month, day } = date;
				const momentDate = window.moment(`${year}-${month}-${day}`, "YYYY-M-D");
				const dayOfWeek = momentDate.day();

				return dayOfWeek !== 0 && dayOfWeek !== 6;
			});
			setDates(filteredValues);
		}
	};

	const isSelectedAll = () => {
		const selectedDatesSet = new Set(
			dates.map(date => `${date.year}-${date.month}-${date.day}`)
		);
		let startDate = window.moment(
			`${minValue.year}-${minValue.month}-${minValue.day}`,
			"YYYY-M-D"
		);
		const endDate = window.moment(
			`${maxValue.year}-${maxValue.month}-${maxValue.day}`,
			"YYYY-M-D"
		);

		let businessDays = 0;
		let selectedBusinessDays = 0;

		while (startDate.isSameOrBefore(endDate, "day")) {
			const dayOfWeek = startDate.day();
			const dateString = `${startDate.year()}-${startDate.month() +
				1}-${startDate.date()}`;

			if (dayOfWeek > 0 && dayOfWeek < 6) {
				businessDays++;
				if (selectedDatesSet.has(dateString)) {
					selectedBusinessDays++;
				}
			}
			startDate.add(1, "day");
		}

		return {
			isSelectedAll: businessDays === selectedBusinessDays && businessDays > 0,
			hasBusinessDays: businessDays > 0
		};
	};

	const onToggle = () => {
		if (isSelectedAll()?.isSelectedAll) onSelectAll([]);
		else {
			let startDate = minValue;
			var result = [];

			while (!isSameDay(startDate, maxValue)) {
				result.push(startDate);
				startDate = startDate.add({
					days: 1
				});
			}

			result.push(startDate);
			onSelectAll(result);
		}
	};

	return (
		<DatePicker
			rootClassName={styles.datePickerRoot}
			inputClassName={styles.datePickerInput}
			value={dates[0]}
			triggerIcon={<CalendarIconGrey />}
			onChange={item => onChange(item)}
			isMultiple
			list={dates}
			onConfirm={onConfirm}
			onClear={onClear}
			minValue={minValue}
			maxValue={maxValue}
			isExpense={!isMultiple}
			onToggle={onToggle}
			isSelectedAll={isSelectedAll}
		>
			{isMultiple && (
				<>
					<div className={styles.selectAll}>
						<CalendarIcon />
						<div>Select all business days</div>
						<Tooltip
							theme="dark"
							content={
								"Quickly select all available business days in the timesheet you are filling out. Use this to save time and ensure you are accurately accounting for your workdays"
							}
						>
							<TooltipIcon width={24} height={24} />
						</Tooltip>

						<ToggleButton
							checked={isSelectedAll().isSelectedAll}
							onChange={onToggle}
							className={styles.toggleButton}
							disabled={isSelectedAll().hasBusinessDays === false}
						/>
					</div>
					<div className={styles.separator} />
				</>
			)}
		</DatePicker>
	);
};

const mapStateToProps = state => ({
	timesheet: state.timesheet
});

export default connect(mapStateToProps)(AddTimesheetEntriesDatePicker);
