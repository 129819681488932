import * as yup from "yup";
import { dateObjectToString } from "config/helpers";
import toaster from "common/Toaster";
import get from "lodash/get";

const numbersValidator = (_, required) =>
	required
		? yup
				.number()
				.required("required")
				.typeError("Amount must be a number")
				.min(0, "Min value 0.")
				.max(10000000, "Max value 10000000.")
				.nullable(true)
		: yup
				.number()
				.typeError("Amount must be a number")
				.min(0, "Min value 0.")
				.max(10000000, "Max value 10000000.")
				.nullable(true);
export const yupRules = {
	maxlength: (maxlength, required) =>
		required
			? yup
					.string()
					.required("* Required field")
					.max(Number(maxlength), `You must enter ${maxlength} caracter`)
			: yup
					.string()
					.max(Number(maxlength), `You must enter ${maxlength} caracter`),
	maxitems: (maxitems, required, isHier) => {
		if (isHier) {
			return required ? yup.array().required("* Required field") : yup.array();
		} else {
			return required
				? yup
						.array()
						.required("* Required field")
						.max(Number(maxitems), `You can choose only ${maxitems} items`)
				: yup
						.array()
						.max(Number(maxitems), `You can choose only ${maxitems} items`);
		}
	},
	email: (_, required) =>
		required
			? yup
					.string()
					.required("* Required field")
					.email("Please enter a valid email address")
			: yup.string().email("Please enter a valid email address"),
	int: numbersValidator,
	float: numbersValidator,
	regex: (regex, required) => {
		return required
			? yup
					.string()
					.matches(
						new RegExp(regex.slice(1, -2), "i"),
						"please enter a valid fomat"
					)
					.required("* Required field")
			: yup
					.string()
					.matches(
						new RegExp(regex.slice(1, -2), "i"),
						"please enter a valid fomat"
					);
	},
	date: (_, required) => {
		return required ? yup.date().required("* Required field") : yup.date();
	},
	required: yup.mixed().required("* Required field")
};

export const isAllFreeChannelsSelected = (
	ChannelsList,
	selectedFreeChannels
) => {
	const freeChannels = ChannelsList.map(item => item.product_id);
	const ids = [...freeChannels, "my_candidates", "talent_pool"];

	return ids.every(item => selectedFreeChannels.indexOf(item) !== -1);
};

export const formatHieOptions = (vonqData, parentKey) => {
	const parent = vonqData.filter(
		item => item.parent === parentKey && item.key !== parentKey
	);
	const isLeave = parent.length === 0;

	if (isLeave) {
		return undefined;
	}

	return parent.map(item => {
		const children = formatHieOptions(vonqData, item.key);
		const newParent = {
			label: item.label,
			value: item.key,
			children
		};

		return newParent;
	});
};

export const checkType = (fieldName, typeToCheck, postingRequirements) => {
	const item = postingRequirements.find(({ name }) => name === fieldName);

	if (item) {
		const { type } = item;
		return type === typeToCheck;
	}
	return false;
};

export const formatPostingRequirementsFormValues = (
	formValues,
	postingRequirements
) => {
	const valuesFormatted = [];

	for (const key in formValues) {
		const valueObject = {
			name: key,
			value: formatContractPostingRequirementField(
				key,
				formValues[key] || "",
				postingRequirements
			)
		};

		valuesFormatted.push(valueObject);
	}

	return valuesFormatted;
};

export const formatContractPostingRequirementField = (
	fieldId,
	value,
	postingRequirements
) => {
	let newValue = value;

	if (checkType(fieldId, "DATE", postingRequirements)) {
		newValue = dateObjectToString(value);
	} else if (value && typeof value === "object" && value.value && value.label) {
		//This case for autcomplete we get object instead of primitive
		newValue = value.value;
	} else if (checkType(fieldId, "TEXTEXPAND", postingRequirements)) {
		newValue = JSON.stringify(value);
	} else if (checkType(fieldId, "HIER", postingRequirements)) {
		newValue = value.slice(-1)[0];
	}

	return newValue;
};

export function onPublishError(err) {
	let result;

	if (get(err, "errors.postingDetails.salaryIndication.period[0]"))
		result = "Salary is required";
	else if (typeof err?.detail === "string") result = err.detail;
	else if (get(err, "detail.name")) result = err.detail.name;
	else result = "An error has occurred";

	toaster.danger(result);
}
