import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { formatDate } from "common/Functions";
import { Flex, Box, Text } from "rebass";
import {
	EXPIRED,
	DECLINED,
	HIRED,
	DRAFT,
	SENT,
	CANCELLED,
	SHORTLISTED,
	SOURCED
} from "config";
import {
	ShortlistText,
	ShortlistDivider,
	PokeInfosContainer,
	RejectedText
} from "./Styled";
import styles from "./poke-details.module.scss";

import StatusLabel from "common/StatusLabel";
import capitalize from "lodash/capitalize";

const PokeDetails = ({
	visible_status,
	status,
	created_at,
	flag,
	clientFullName,
	status_changed_on,
	cancelData,
	is_applied,
	applied_at,
	source_profile,
	rejected_at,
	reasonOfRejectDetail,
	reasonOfReject
}) => {
	let infoTextFirst, infoTextSecond, infoTextThird;

	switch (status) {
		case EXPIRED:
			infoTextFirst =
				"This poke has now expired. Either because the candidate hasn’t replied to it within 4 weeks; or because the vacancy was archived or marked as filled.";
			break;
		case HIRED:
			infoTextFirst = "This candidate was hired to fill the vacancy !";
			break;
		case CANCELLED:
			infoTextFirst = (
				<>
					Application cancelled by
					<strong>
						{cancelData.cancelled_by && cancelData.cancelled_by.first_name
							? `${_get(cancelData, "cancelled_by.first_name")} ${_get(
									cancelData,
									"cancelled_by.last_name"
							  )}`
							: cancelData.cancelled_by.reference}{" "}
					</strong>{" "}
					on{" "}
					<strong>{`${formatDate(_get(cancelData, "cancelled_at"))}`}</strong>
				</>
			);
			infoTextSecond =
				Boolean(_get(cancelData, "cancel_reason")) && `Feedback provided:`;
			infoTextThird =
				Boolean(_get(cancelData, "cancel_reason")) &&
				_get(cancelData, "cancel_reason");
			break;
		default:
			infoTextFirst = "";
			infoTextSecond = "";
			break;
	}

	return (
		<div className="section">
			<Flex justifyContent="space-between">
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontSize: "15px",
						fontWeight: "600",
						letterSpacing: "0.08px",
						color: "rgb(39, 50, 56)"
					}}
				>
					{flag}
				</Text>
				<Box>
					<StatusLabel
						status={status === DRAFT ? SENT : status}
						label={
							status === SHORTLISTED ? capitalize(SOURCED) : visible_status
						}
						right="0"
						px={15}
						isPoke
					/>
				</Box>
			</Flex>

			<PokeInfosContainer>
				sddassada
				{is_applied ? (
					<ShortlistText>
						Candidate proactively applied on {formatDate(applied_at)} via{" "}
						{source_profile} <br />
					</ShortlistText>
				) : null}
				{status === "rejected" && (
					<RejectedText>
						<table>
							<thead>
								<tr>
									<th>Date</th>
									<th>Reason for rejection</th>
									<th>Details</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{formatDate(rejected_at)}</td>

									<td>
										<div className={styles.reasonOfRejection}>
											{reasonOfReject}
										</div>
									</td>

									<td>{reasonOfRejectDetail}</td>
								</tr>
							</tbody>
						</table>
					</RejectedText>
				)}
				<div></div>
				{!is_applied ? (
					<ShortlistText lineHeight="2">
						{`Candidate sourced on ${formatDate(
							created_at
						)} by ${clientFullName}`}
					</ShortlistText>
				) : null}
				{status === DECLINED && (
					<ShortlistText>
						Interest declined by candidate on{" "}
						<i>{formatDate(status_changed_on)}</i>
					</ShortlistText>
				)}
				{(infoTextFirst || infoTextSecond) && <ShortlistDivider />}
				{infoTextFirst && <ShortlistText>{infoTextFirst}</ShortlistText>}
				{infoTextSecond && <ShortlistText>{infoTextSecond}</ShortlistText>}
				{infoTextThird && <ShortlistText>{infoTextThird}</ShortlistText>}
			</PokeInfosContainer>
		</div>
	);
};

PokeDetails.propTypes = {
	status: PropTypes.string.isRequired,
	created_at: PropTypes.number.isRequired,
	flag: PropTypes.string.isRequired
};

export default PokeDetails;
