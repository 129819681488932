import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { JOBS_OVERVIEW } from "config/api-endpoints";

const jobsOverview = (key, { job_type, start_date, end_date }) =>
	client(JOBS_OVERVIEW, {
		body: {
			start_date,
			end_date,
			job_type
		}
	});
export const jobsOverviewKey = "@home/jobsOverview";

const useJobsOverview = (options, queryOptions = {}) =>
	useQuery([jobsOverviewKey, options], jobsOverview, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useJobsOverview;
