import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function EmailTemplateSkeleton() {
	return (
		<div style={{ padding: 16, position: "relative", height: "100%" }}>
			<SkeletonTheme color="#F1F1F1">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						<div style={{ display: "flex", gap: 16, marginBottom: 16 }}>
							<Skeleton width={150} height={20} style={{ borderRadius: 8 }} />
							<Skeleton width={250} height={20} style={{ borderRadius: 8 }} />
						</div>
						<Skeleton width={450} height={40} style={{ borderRadius: 8 }} />
					</div>
					<div style={{ display: "flex", gap: 8 }}>
						<Skeleton width={32} height={32} style={{ borderRadius: 8 }} />
					</div>
				</div>
				<div style={{ height: 12 }} />
				<div>
					<Skeleton width={140} height={20} style={{ borderRadius: 8 }} />
				</div>
				<div style={{ borderTop: "1px solid #e2e8f0", margin: "16px 0" }} />
				<div>
					<Skeleton height={30} width={600} style={{ borderRadius: 8 }} />
				</div>
				<div style={{ borderTop: "1px solid #e2e8f0", margin: "16px 0" }} />
				<div style={{ marginBottom: 12 }}>
					<Skeleton height={30} width={"90%"} style={{ borderRadius: 8 }} />
				</div>
				<div style={{ marginBottom: 12 }}>
					<Skeleton height={30} width={"80%"} style={{ borderRadius: 8 }} />
				</div>
				<div style={{ marginBottom: 12 }}>
					<Skeleton height={30} width={"100%"} style={{ borderRadius: 8 }} />
				</div>
				<div style={{ marginBottom: 12 }}>
					<Skeleton height={30} width={"50%"} style={{ borderRadius: 8 }} />
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						position: "absolute",
						right: 0,
						left: 0,
						bottom: 0,
						padding: 16
					}}
				>
					<div>
						<Skeleton width={200} height={40} style={{ borderRadius: 8 }} />
					</div>
					<div>
						<Skeleton width={150} height={40} style={{ borderRadius: 8 }} />
					</div>
				</div>
			</SkeletonTheme>
		</div>
	);
}
