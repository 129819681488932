import darken from "polished/lib/color/darken";
import { colors } from "config/styles";

export default {
	primary: colors.primaryColor, // background
	primaryColor: colors.white, // color
	primaryHoverColor: colors.white, // color
	primaryHover: colors.primaryHover, // hover color
	primaryBorder: colors.primaryColor, // border color
	primaryHoverBorderColor: colors.primaryHover, // hover border color
	success: colors.updateColor,
	greenButton: colors.greenButton,
	successColor: colors.white,
	successHover: darken(0.1, colors.updateColor),
	successBorder: colors.updateColor,
	successHoverBorderColor: darken(0.1, colors.updateColor),
	secondary: "transparent",
	secondaryHover: colors.linksColor, // hover background color
	secondaryColor: colors.linksColor,
	secondaryHoverColor: colors.white,
	secondaryHoverBorderColor: colors.linksColor,
	secondaryBorder: colors.darkBorderColor, // border color
	secondaryActive: colors.linksColor, // active background color
	secondaryActiveColor: colors.white,
	split: colors.pinkyRedColor,
	splitIcon: colors.darkPinkyRedColor,
	splitHover: colors.primaryColor,
	splitIconHover: colors.primaryHover,
	warning: colors.btnDangerBg,
	warningHover: colors.btnDangerBg,
	warningHoverColor: colors.dangerBg,
	warningHoverBorderColor: colors.btnDangerBorder,
	warningColor: colors.dangerBg,
	warningBorder: colors.btnDangerBorder,
	danger: colors.errorColor,
	dangerColor: colors.white,
	dangerHover: colors.errorColor,
	dangerHoverColor: colors.white,
	white: colors.white,
	whiteColor: colors.inputColor,
	whiteHover: colors.white,
	whiteHoverColor: colors.inputColor,
	gray: colors.linksColor,
	grayColor: colors.white,
	grayHover: colors.linksColor,
	grayHoverColor: colors.white,
	lightGray: colors.lightGrayColor,
	lightGrayColor: colors.white,
	lightGrayHover: colors.lightGrayColor,
	lightGrayHoverColor: colors.white,
	timesheetWarning: colors.timesheetWarning,
	timesheetWarningHover: colors.timesheetWarning,
	timesheetWarningHoverColor: colors.white,
	timesheetWarningHoverBorderColor: colors.timesheetWarning,
	timesheetWarningColor: colors.white,
	timesheetWarningBorder: colors.timesheetWarning,
	//
	outlineDanger: colors.white, // background
	outlineDangerColor: colors.errorColor, // color
	outlineDangerBorder: colors.errorColor // border color
};
