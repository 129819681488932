import React, { useMemo } from "react";
import { PageCard } from "../PageCard";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { connect } from "react-redux";
import get from "lodash/get";
import { PageCardLoading } from "../PageCardLoading";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { SELECTORS_TOUR_EMPLOYER_BRANDING } from "modules/user-onboarding/workflows/employer-branding";
import {
	INTERACTIVE_CONTAINER_STYLES,
	TOUR_EMPLOYER_BRANDING
} from "config/onboarding";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";
import { browserHistory } from "react-router";
import { useTour } from "@reactour/tour";

const useTourData = () => {
	const {
		metaData,
		cleanStore,
		workflowId,
		setMetaData,
		setIsLoading,
		activeOverlay,
		setActiveOverlay
	} = useOnboardingStore();
	const {
		isOpen,
		setSteps,
		setIsOpen,
		currentStep,
		setCurrentStep
	} = useTour();

	const isActiveEmployerBrandingTour = useMemo(
		() => workflowId === TOUR_EMPLOYER_BRANDING,
		[workflowId]
	);

	const closeTour = () => {
		if (isActiveEmployerBrandingTour) {
			setIsOpen(false);
			setActiveOverlay(true);
		}
	};

	const goToFourStep = () => {
		if (isActiveEmployerBrandingTour) setCurrentStep(3);
	};

	// last step close tour
	const onLastStepClose = () => {
		setIsOpen(false); // close tour
		setSteps([]); // clean steps
		if (metaData?.showStartRecrutmentModal) {
			setMetaData({ ...metaData, showModal: true });
		} else {
			cleanStore(); // clean onboarding store
			browserHistory.push("/settings/setup-guide");
		}
	};

	// persist onboarding data
	const { persistData } = useOnboardingSaveData({
		onTourClose: onLastStepClose
	});

	const goLastTourStep = () => {
		if (isActiveEmployerBrandingTour) {
			setActiveOverlay(false);
			setIsLoading(true);
			persistData();
		}
	};

	return {
		closeTour,
		goToFourStep,
		goLastTourStep,
		toasterDuration: isActiveEmployerBrandingTour ? 500 : false,
		selectors: {
			second: SELECTORS_TOUR_EMPLOYER_BRANDING[1],
			third: SELECTORS_TOUR_EMPLOYER_BRANDING[2],
			four: SELECTORS_TOUR_EMPLOYER_BRANDING[3]
		},
		selectorsClassNames: {
			second:
				isActiveEmployerBrandingTour && isOpen && currentStep === 1
					? " highlighted"
					: "",
			third:
				isActiveEmployerBrandingTour && isOpen && currentStep === 2
					? " highlighted"
					: "",
			four:
				isActiveEmployerBrandingTour && isOpen && currentStep === 3
					? " highlighted"
					: ""
		},
		interactiveContainerStyles:
			isActiveEmployerBrandingTour && activeOverlay && currentStep === 3
				? INTERACTIVE_CONTAINER_STYLES
				: {}
	};
};

const CareerPageCard = ({
	className,
	data,
	user,
	site_id,
	isLoading,
	hasMultiSite
}) => {
	const url = get(data, "url_career_page", "");
	const accesToken = get(user, "access_token", "");

	const urlPageCms = `${url.replace(
		"/careers",
		""
	)}/api/c/${accesToken}?page=careers`;

	// tourData
	const tourData = useTourData();

	return (
		<>
			{isLoading ? (
				<PageCardLoading className={className} isLoading={isLoading} />
			) : (
				<PageCard
					originalUrl={`https://${url.split("/")[2]}`}
					feature_name="feature_cms_career_page"
					url={urlPageCms}
					data={data}
					className={className}
					isLoading={isLoading}
					site_id={site_id}
					hasMultiSite={hasMultiSite}
					tourData={tourData}
				/>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps, null)(CareerPageCard);
