import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_PROCESSES } from "config/api-endpoints";

const activeProcesses = (_, { id, limit }) =>
	client(GET_PROCESSES, {
		body: {
			user_id: id,
			limit
		}
	});
export const activeProcessesKey = "@candidate/activeProcesses";

const useActiveProcesses = (options, queryOptions = {}) => {
	const query = useQuery([activeProcessesKey, options], activeProcesses, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
	return {
		...query
	};
};

export default useActiveProcesses;
