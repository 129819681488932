import React from "react";
import { EmptyTableContainer } from "./Styled";
import { ReactComponent as EmptyIcon } from "./empty-table.svg";

const EmptyTable = ({ title, text }) => {
	return (
		<EmptyTableContainer>
			<div className="content">
				<EmptyIcon />
				<div className="empty-title">{title}</div>
				<div className="empty-text">{text}</div>
			</div>
		</EmptyTableContainer>
	);
};

export default EmptyTable;
