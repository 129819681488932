import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import styles from "./general-card.module.scss";
import { ReactComponent as CopyIcon } from "static/icons/copy-icon.svg";
import { ReactComponent as NotificationIcon } from "static/icons/notification-icon-black.svg";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import CopyTooltip from "rc-tooltip";
import Tooltip from "common/Tippy";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import * as copyToClipboard from "copy-to-clipboard";
import ImageUploader from "modules/MyCompany/components/ImageUploader/ImageUploader";
import toast from "react-hot-toast";
import useUpdateSMPicture from "../api/useUpdateSMPicture";
import { queryCache } from "react-query";
import get from "lodash/get";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useTour } from "@reactour/tour";
import { TOUR_EMPLOYER_BRANDING } from "config/onboarding";
import {
	SELECTORS_TOUR_EMPLOYER_BRANDING,
	STEPS_TOUR_EMPLOYER_BRANDING
} from "modules/user-onboarding/workflows/employer-branding";

const useTourData = () => {
	const tourFirstStepRef = useRef(null);
	const { workflowId } = useOnboardingStore();
	const {
		isOpen,
		setSteps,
		setIsOpen,
		setCurrentStep,
		currentStep
	} = useTour();

	const isActiveEmployerBrandingTour = useMemo(
		() => workflowId === TOUR_EMPLOYER_BRANDING,
		[workflowId]
	);

	useEffect(() => {
		if (tourFirstStepRef.current && isActiveEmployerBrandingTour) {
			setSteps(STEPS_TOUR_EMPLOYER_BRANDING); // init steps
			setCurrentStep(0);
			setIsOpen(true);
		}
	}, [tourFirstStepRef, isActiveEmployerBrandingTour]);

	return {
		tourFirstStepRef,
		selectors: {
			first: SELECTORS_TOUR_EMPLOYER_BRANDING[0]
		},
		selectorsClassNames: {
			first:
				isActiveEmployerBrandingTour && isOpen && currentStep === 0
					? " highlighted"
					: ""
		}
	};
};

const GeneralCard = ({
	socialMediaPicture,
	companyPage,
	site_id,
	hasMultiSite,
	isLoading = true
}) => {
	const { selectors, tourFirstStepRef, selectorsClassNames } = useTourData();

	const [copyText, setCopyText] = useState("Copy link");
	const user = useSelector(onlineUserSelector);
	const features = get(user, "features", []);
	const hasEmployerBranding = features.includes("feature_employer_branding");

	const [mutate] = useUpdateSMPicture({
		onSuccess: () => {
			toast("Site updated successfully");
			queryCache.invalidateQueries("myCurrentSite");
		},
		onError: error => {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					toast(
						`${name} : ${
							typeof get(errorMessage, name) === "string"
								? get(errorMessage, name)
								: get(errorMessage, "name[0]")
						}`,
						{ isFailed: true }
					);
				});
			} else {
				toast(errorMessage, { isFailed: true });
			}
		}
	});

	const handleCopy = () => {
		copyToClipboard(companyPage);
		setCopyText("Copied!");
		setTimeout(() => {
			setCopyText("Copy link");
		}, 1500);
	};

	const imageChangeHandler = src => {
		const payload = { path: src };
		if (hasMultiSite) {
			payload.site_id = site_id;
		}
		mutate(payload);
	};

	const notificationContent = (
		<div className={styles.notificationContainer}>
			<div className={styles.item}>
				<div className={styles.circle} />
				<div className={styles.description}>
					This picture is displayed when you share a vacancy on social media via
					wiggli
				</div>
			</div>
			<div className={styles.item}>
				<div className={styles.circle} />
				<div className={styles.description}>
					Recommanded dimensions : 1200x627 px
				</div>
			</div>
			<div className={styles.item}>
				<div className={styles.circle} />
				<div className={styles.description}>Maximum size : 5MB</div>
			</div>
		</div>
	);

	return (
		<div className={styles.container}>
			<h2 className={styles.title}>General</h2>
			<div
				className={hasEmployerBranding ? styles.card : styles.generalSection}
			>
				<div className={styles.socialMediaSection}>
					<div className={styles.label}>
						<span>Social media picture</span>
						<Tooltip content={notificationContent} theme="dark">
							<NotificationIcon className={styles.icon} />
						</Tooltip>
					</div>
					<div
						id={selectors.first}
						ref={tourFirstStepRef}
						className={selectorsClassNames.first}
					>
						{isLoading ? (
							<SkeletonTheme color="#e5e5e5">
								<Skeleton
									className={styles.socialMediaImgSkeleton}
									width={"112px"}
									height={"84px"}
									borderRadius={"8px"}
								/>
							</SkeletonTheme>
						) : (
							<ImageUploader
								onChange={imageChangeHandler}
								src={socialMediaPicture}
								width={"112px"}
								height={"84px"}
								employerBranding
								aspect={16 / 9}
							/>
						)}
					</div>
				</div>
				{hasEmployerBranding && (
					<div className={styles.companySection}>
						<div className={styles.label}>Company page</div>
						<div className={styles.url}>
							{isLoading ? (
								<>
									<SkeletonTheme color="#e5e5e5">
										<Skeleton
											width={"106px"}
											height={"16px"}
											borderRadius={"3px"}
										/>
									</SkeletonTheme>
									<SkeletonTheme color="#e5e5e5">
										<Skeleton
											width={"24px"}
											height={"24px"}
											borderRadius={"3px"}
										/>
									</SkeletonTheme>
								</>
							) : (
								<>
									<a
										className={styles.link}
										href={companyPage}
										target="_blank"
										rel="noopener noreferrer"
									>
										{companyPage}
									</a>
									{companyPage && (
										<CopyTooltip
											placement="top"
											theme="dark"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											overlayClassName={"employer_branding_tooltip"}
											overlay={<div>{copyText}</div>}
										>
											<CopyIcon
												className={styles.copyIcon}
												onClick={handleCopy}
											/>
										</CopyTooltip>
									)}
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps, null)(GeneralCard);
