import { usePaginatedQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_LIST_CHANNELS } from "config/api-endpoints";

const getChannels = (_key, { offset, jobId }) => {
	return client(GET_LIST_CHANNELS, {
		body: {
			display: "all_channels",
			tag: "my_free_channels",
			name: "",
			job_id: jobId,
			limit: 11,
			offset
		}
	});
};

export const GET_CHANNELS_LIST_KEY = "GET_CHANNELS_LIST_KEY";

export const useGetChannels = options => {
	const res = usePaginatedQuery([GET_CHANNELS_LIST_KEY, options], getChannels, {
		refetchOnWindowFocus: false,
		retry: 0
	});
	return res;
};
