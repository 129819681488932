import React, { useRef, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { createCommand, $getSelection, $createTextNode } from "lexical";
import { useEffect } from "react";
import {
	$createPlaceholderLinkNode,
	$isPlaceholderLinkNode,
	PlaceholderLinkNode
} from "common/Editor/nodes/PlaceholderLinkNode";
import styles from "./placeholder-link-plugin.module.scss";
import { SearchInput } from "common/SearchInput";
import { ReactComponent as PlaceholderLinkIcon } from "static/icons/placeholder-link-icon.svg";
import Portal from "common/Portal";
import { usePopper } from "react-popper";
import useOnClickOutside from "hooks/useOnClickOutside";
import cn from "classnames";

export const PLACEHOLDER_LINK_COMMAND = createCommand(
	"PLACEHOLDER_LINK_COMMAND"
);

export default function PlaceholderLinkPlugin() {
	const [editor] = useLexicalComposerContext();
	const [search, setSearch] = useState("");
	const popperElement = useRef();
	const [referenceElement, setReferenceElement] = useState(null);
	const [selectedNode, setSelectedNode] = useState(null);

	const popperInstance = usePopper(referenceElement, popperElement.current, {
		placement: "bottom-start",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [-10, 10]
				}
			}
		]
	});

	useOnClickOutside(popperElement, () => {
		setReferenceElement(null);
	});

	useEffect(() => {
		if (!referenceElement) {
			setSelectedNode(null);
		}
	}, [referenceElement]);

	useEffect(() => {
		if (!editor.hasNodes([PlaceholderLinkNode])) {
			throw new Error(
				"PlaceholderLinkPlugin: PlaceholderLinkNode not registered on editor (initialConfig.nodes)"
			);
		}

		return editor.registerCommand(
			PLACEHOLDER_LINK_COMMAND,
			node => {
				if (node) {
					setSelectedNode(node);
					editor.getEditorState().read(() => {
						setReferenceElement({
							getBoundingClientRect: () =>
								editor.getElementByKey(node.__key).getBoundingClientRect()
						});
					});
				} else {
					setReferenceElement({
						getBoundingClientRect: () =>
							window
								.getSelection()
								.getRangeAt(0)
								.getBoundingClientRect()
					});
				}
				return true;
			},
			0
		);
	}, [editor, popperInstance]);

	const onClickPlaceholder = placeholder => {
		editor.update(() => {
			if (selectedNode) {
				selectedNode.setPlaceholder(placeholder);
				setReferenceElement(null);
			} else {
				const selection = $getSelection();
				if (!selection.isCollapsed()) {
					let shouldRemovePlaceholder = false;
					let textContent = selection.getTextContent();
					selection.getNodes().forEach(node => {
						if (
							$isPlaceholderLinkNode(node) &&
							node.__placeholder === placeholder
						) {
							shouldRemovePlaceholder = true;
						}
					});
					const node = shouldRemovePlaceholder
						? $createTextNode(textContent)
						: $createPlaceholderLinkNode({ text: textContent, placeholder });
					selection.insertNodes([node]);
					setReferenceElement(null);
				}
			}
		});
	};

	const placeholdersEntries = Object.entries(
		PlaceholderLinkNode.__placeholders ?? {}
	).reduce((acc, [entity, placeholders]) => {
		Object.entries(placeholders).forEach(([key, value]) => {
			acc.push([`${entity}.${key}`, value]);
		});
		return acc;
	}, []);

	const filteredList = placeholdersEntries
		.filter(([key]) => key.includes(search))
		.sort((a, b) => {
			if (!selectedNode) return 0;
			if (a[1] === selectedNode.__placeholder) return -1;
			if (b[1] === selectedNode.__placeholder) return 0;
			return 1;
		});

	return (
		<Portal>
			<div
				ref={popperElement}
				className={styles.container}
				style={{
					...popperInstance.styles.popper,
					display: !!referenceElement ? "block" : "none"
				}}
			>
				<div className={styles.header}>
					<SearchInput
						value={search}
						onChange={({ target }) => setSearch(target.value)}
					/>
				</div>
				<div className={styles.body}>
					{filteredList.map(([key, placeholder]) => {
						return (
							<div
								key={placeholder}
								className={cn(
									styles.item,
									selectedNode?.__placeholder === placeholder && styles.selected
								)}
								onClick={() => onClickPlaceholder(placeholder)}
							>
								<PlaceholderLinkIcon />
								<span className={styles.placeholder}>{key}</span>
							</div>
						);
					})}
				</div>
			</div>
		</Portal>
	);
}
