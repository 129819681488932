import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { cancelApplication } from "../../redux/actions";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";

function CancelApplicationModal({
	onClose,
	poke_id,
	job_id,
	cancelApplication
}) {
	const dataCancelApplication = () => {
		const payload = {
			application_ids: [poke_id],
			vacancy_id: job_id
		};
		cancelApplication(payload).then(() => {
			onClose();
		});
	};

	return (
		<ConfirmationModal
			modalName="Cancel-app"
			onClose={onClose}
			type={ConfirmationTypes.error}
			title={"Are you sure you want to cancel this application?"}
			firstButton={{
				action: dataCancelApplication,
				label: "Yes, cancel application",
				type: "warning"
			}}
		/>
	);
}

const mapDispatchToProps = dispatch => ({
	cancelApplication: payload => dispatch(cancelApplication(payload))
});

CancelApplicationModal.propTypes = {
	cancelApplication: PropTypes.func,
	onClose: PropTypes.func
};

export default connect(null, mapDispatchToProps)(CancelApplicationModal);
