import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router";
import checkPasswordRules from "common/PasswordChecker";
import FormField from "common/FormField";
import features from "config/features/GetFeatureFromLocalStorage";
import Dashboard from "common/dashboard/Dashboard";
import { Helmet } from "react-helmet";
import { userSelector } from "modules/company/selectors/ProfileSelector";
import Breadcumb from "modules/company/components/BreadcumbSettingPages";
import { getRecoverPasswordRoute } from "./Authentication/recover/RecoverPassword";
import { Box } from "rebass";
import { CLIENT } from "config";

var ChangePassword = ({
	updatePassword,
	resetFields,
	handleSubmit,
	submitting,
	route: { title },
	user
}) => {
	const isClient = user.type === "client";
	const sendRequest = params => {
		return updatePassword(params, "change-password").then(() => resetFields());
	};

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<div>
				{!isClient ? <Dashboard /> : null}
				{isClient ? (
					<Breadcumb parentItem="Settings" currentItem="Change password" />
				) : (
					<></>
				)}
				<div
					className={`authentication-container ${user.type !== CLIENT &&
						"not-client"}`}
				>
					<Box
						width={456}
						marginTop={user.type === CLIENT ? 160 : 60}
						marginX={"auto"}
					>
						<h3 className="heading">Change your password</h3>
						<p className="description">
							Effortlessly Reset Your Password and Regain Control.
						</p>
						<form className="form" onSubmit={handleSubmit(sendRequest)}>
							<div className="form-field">
								<Field
									name="old_password"
									type="password"
									label="Your Current Password"
									component={FormField}
								/>
							</div>
							<div className="form-field">
								<Field
									name="password"
									type="password"
									label="Your New Password"
									component={FormField}
								/>
							</div>
							<div className="form-field">
								<Field
									name="confirm_password"
									type="password"
									label="Confirm New Password"
									component={FormField}
								/>
							</div>
							<button className="form-btn" disabled={submitting}>
								Update Password
							</button>
							{features.isFeatureEnabled("linked_in_exchange") && (
								<Link
									to={getRecoverPasswordRoute()}
									className="signin-forgot-password"
								>
									{" "}
									Forgot password?
								</Link>
							)}
						</form>
					</Box>
				</div>
			</div>
		</>
	);
};

const validate = formProps => {
	const errors = {};

	Object.keys(formProps).forEach(field => {
		errors[field] = checkPasswordRules(formProps[field]);
	});

	if (!formProps.old_password || formProps.old_password.trim() === "") {
		errors.old_password = "Field can't be empty.";
	}
	if (!formProps.password || formProps.password.trim() === "") {
		errors.password = "Field can't be empty.";
	}
	if (!formProps.confirm_password || formProps.confirm_password.trim() === "") {
		errors.confirm_password = "Field can't be empty.";
	} else if (formProps.confirm_password !== formProps.password) {
		errors.confirm_password = "Passwords don't match.";
	}
	return errors;
};

const mapStateToProps = state => {
	return {
		user: userSelector(state)
	};
};

ChangePassword = reduxForm({
	form: "change-password",
	validate,
	touchOnBlur: false
})(ChangePassword);

export default connect(mapStateToProps, null)(ChangePassword);
