import * as types from "./types";
import _get from "lodash/get";
import { SEARCH_JOB, LIST_SECTORS, LIST_FUNCTIONS } from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";
import { FREELANCER } from "../../../config";

function resetLanguages() {
	return {
		type: types.REST_LANGUAGES
	};
}

export function resetSkills() {
	return {
		type: types.REST_SKILLS
	};
}

function _updateFilter(filter) {
	return {
		type: types.UPDATE_FILTER,
		filter
	};
}

export function updateSkills(skills) {
	return {
		type: types.UPDATE_SKILLS,
		skills
	};
}

export function updateLanguages(languages) {
	return {
		type: types.UPDATE_LANGUAGES,
		languages
	};
}

export function toggleOfflinePopup() {
	return {
		type: types.TOGGLE_OFFLINE_POPUP
	};
}

export const setSubSectors = subSectors => ({
	type: types.SET_SEARCH_SUBSECTORS,
	subSectors
});

export function updateFilter(filter = {}, fetch = true, isPage = false) {
	return dispatch => {
		dispatch(_updateFilter(filter));
		if (fetch)
			dispatch(
				fetchJobs(
					{ ...filter, category: _get(filter, "category.name") },
					isPage
				)
			);
	};
}

export function handleSkillChange(skill, addedSkills, filter, toggle = false) {
	return dispatch => {
		let found = -1;
		for (const [index, sk] of addedSkills.entries()) {
			if (sk.name === skill) {
				found = index;
				break;
			}
		}
		if (found === -1) {
			filter.skills.push(skill);
			addedSkills.push({ name: skill, active: true });
		} else {
			if (toggle) {
				if (addedSkills[found].active) {
					filter.skills = filter.skills.filter(sk => sk !== skill);
				} else {
					filter.skills.push(skill);
				}
				addedSkills[found].active = !addedSkills[found].active;
			}
		}
		dispatch(updateSkills(addedSkills));
		dispatch(updateFilter(filter));
	};
}

export function resetFilter(term = "", userDistance) {
	const filter = {
		title: term,
		category: null,
		subcategory: null,
		function: null,
		estimation: {
			gte: ""
		},
		estimation_type: null,
		project_estimation: null,
		skills: [],
		seniority: [],
		remote_eligibility: [],
		office_days: [],
		location: {
			distance: `${userDistance}km`,
			lat: 0,
			lon: 0,
			iso_country: "",
			viewport: []
		},
		type: [],
		duration: "",
		languages: [],
		offset: 0,
		sort_by: [{ field: "publish_date", order: "desc" }]
	};
	return dispatch => {
		dispatch(updateFilter(filter));
		dispatch(resetLanguages());
		dispatch(resetSkills(filter));
	};
}

export function fetchJobs(filter = {}, isPage) {
	return dispatch => {
		if (!isPage) filter.offset = 0;
		if (!filter.estimation || !filter.estimation.gte) {
			delete filter.estimation;
			delete filter.estimation_type;
		}
		return dispatch(
			AxiosHelper.__post({
				data: {
					...filter,
					category: _get(filter, "category.name", _get(filter, "category"))
				},
				url: SEARCH_JOB,
				REQUEST_SUCCESS: types.FETCHED_JOBS,
				loadingText: "Loading jobs..."
			})
		);
	};
}

export function getSectors({ userType, userSectors }) {
	return dispatch =>
		dispatch(
			AxiosHelper.__post({
				url: LIST_SECTORS,
				loadingText: "Loading sectors...",
				next: data => {
					if (userType === FREELANCER) {
						const categories = data.filter(
							s => userSectors.includes(s._id) && s
						);
						return dispatch({
							type: types.SET_SEARCH_SECTORS,
							data: categories
						});
					} else {
						return dispatch({ type: types.SET_SEARCH_SECTORS, data });
					}
				}
			})
		);
}

export function getFunctions(sector) {
	return dispatch =>
		dispatch(
			AxiosHelper.__post({
				url: LIST_FUNCTIONS,
				data: {
					no_limit: true,
					sector_id: sector._id
				},
				REQUEST_SUCCESS: types.SET_SEARCH_FUNCTIONS,
				loadingText: "Loading functions..."
			})
		);
}

export function clearAllSearchFilters() {
	return {
		type: types.CLEAR_ALL_FILTERS
	};
}
