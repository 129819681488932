import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import _findIndex from "lodash/findIndex";

const initialState = {
	data: [],
	filter: "",
	selected: [],
	category: undefined,
	lastAdded: undefined,
	lastMustHave: undefined,
	pagination: undefined,
	maxItems: 20
};

export const LIST_ACTION_TYPES = {
	SET_DATA: "SET_DATA",
	SET_CATEGORY: "SET_CATEGORY",
	UPDATE_FILTER: "UPDATE_FILTER",
	UPDATE_SELECTED: "UPDATE_SELECTED",
	ADD_ITEM: "ADD_ITEM",
	REMOVE_ITEM: "REMOVE_ITEM",
	ON_SCORE_CHANGE: "ON_SCORE_CHANGE",
	UPDATE_MUST_HAVE: "UPDATE_MUST_HAVE",
	CLEAR_SELECTED: "CLEAR_SELECTED"
};

const reducer = produce((draft, action) => {
	switch (action.type) {
		case LIST_ACTION_TYPES.SET_DATA:
			draft.data = action.data;
			return;
		case LIST_ACTION_TYPES.UPDATE_FILTER:
			draft.filter = action.filter;
			return;
		case LIST_ACTION_TYPES.UPDATE_SELECTED:
			draft.selected = action.selected;
			return;
		case LIST_ACTION_TYPES.ADD_ITEM:
			draft.lastAdded = action.selected._id;
			if (draft.category) {
				draft.selected.push({
					...action.selected
				});
				return;
			}
			draft.selected.push(action.selected);
			return;
		case LIST_ACTION_TYPES.REMOVE_ITEM:
			draft.selected = draft.selected.filter(i => i._id !== action.item._id);
			return;
		case LIST_ACTION_TYPES.ON_SCORE_CHANGE:
			draft.selected[
				_findIndex(draft.selected, i => i._id === action.item._id)
			].score = action.item.score;
			return;
		case LIST_ACTION_TYPES.SET_CATEGORY:
			draft.category = action.category;
			return;
		case LIST_ACTION_TYPES.UPDATE_MUST_HAVE:
			{
				const item =
					draft.selected[
						_findIndex(draft.selected, i => i._id === action.item)
					];
				item.must_have = !item.must_have;
				draft.lastMustHave = item.must_have ? action.item : undefined;
			}
			break;
		case LIST_ACTION_TYPES.CLEAR_DATA:
			return (draft = initialState);
		case LIST_ACTION_TYPES.CLEAR_SELECTED:
			draft.selected = [];
			return;
		default:
			return draft;
	}
});

export const ListContext = createContext({});

export const ListProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<ListContext.Provider value={{ state, dispatch }}>
			{children}
		</ListContext.Provider>
	);
};

ListProvider.propTypes = {
	children: PropTypes.any
};
