import React from "react";
import { CustomDropDown } from "common/CustomDropDown";

export const RemoteEligibilityCustomDropDown = props => (
	<CustomDropDown
		options={[
			{ label: "On-site", value: "on_site" },
			{ label: "Full remote", value: "full_remote" }
		]}
		{...props}
	/>
);
