import React, { Component } from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import {
	FREELANCER_PHONE_PLACEHOLDER,
	FREELANCER_EMAIL_PLACEHOLDER,
	PHONE,
	EMAIL,
	NO_DATA_PLACEHOLDER,
	EXCERPT_LENGTH,
	COMPANY_NAME,
	MANAGER_NAME
} from "config";
import { excerpt, parsePhoneNumber } from "common/Functions";

class BasicInfo extends Component {
	constructor() {
		super();
		this.state = {
			company_name_tracked: false,
			manager_name_tracked: false
		};

		this.viewContactInfo = this.viewContactInfo.bind(this);
	}

	viewInformation = type => {
		const { viewProfile, bid_id } = this.props;
		switch (type) {
			case PHONE:
				this.viewContactInfo(PHONE);
				break;
			case EMAIL:
				this.viewContactInfo(EMAIL);
				break;
			case COMPANY_NAME:
				this.viewContactInfo(COMPANY_NAME);
				break;
			case MANAGER_NAME:
				this.viewContactInfo(MANAGER_NAME);
				break;
			default:
				break;
		}
		viewProfile(bid_id);
	};

	viewContactInfo(type) {
		const { bid_id } = this.props;
		const payload = {
			bid_id,
			type
		};

		this.props.viewContactInfo(payload).then(() => {
			this.setState({ [`${type}_tracked`]: true });
		});
	}

	render() {
		const {
			country,
			city,
			ownCV,
			email,
			mobile,
			isConsultancy,
			ConsultancyName,
			userConsultancy,
			isFreelancer
		} = this.props;

		const countryCityExists = !!country && !!city;
		const cityCountryStr = countryCityExists ? `${country}, ${city}` : "";
		let showTooltip = false;
		if (countryCityExists && cityCountryStr.length > EXCERPT_LENGTH) {
			showTooltip = true;
		}
		const mobileUser = isConsultancy
			? !ownCV
				? userConsultancy.phone
				: mobile
			: mobile;
		const phone = mobileUser && parsePhoneNumber(mobileUser);
		const emailUser = isConsultancy
			? !ownCV
				? userConsultancy.email
				: email
			: email;
		const bumName =
			userConsultancy &&
			userConsultancy.first_name + " " + userConsultancy.last_name;

		const canSeeAddress = isFreelancer ? true : ownCV;

		return (
			<div id="topbar--basic-info">
				<ul className="list-unstyled list-style-none col-md-12">
					{isConsultancy && !ownCV && (
						<li>
							<div>
								<span className="icon-companies" />
								{ConsultancyName && ConsultancyName !== NO_DATA_PLACEHOLDER ? (
									<span className="name">{ConsultancyName}</span>
								) : (
									<div className="placeholder" />
								)}
							</div>
						</li>
					)}
					{isConsultancy && !ownCV && (
						<li>
							<div>
								<span className="icon-user" />
								<span className="name">{bumName}</span>
							</div>
						</li>
					)}
					{canSeeAddress && (
						<li>
							<div>
								<span className="icon-local" />
								{countryCityExists ? (
									showTooltip ? (
										<Tooltip
											placement="top"
											mouseEnterDelay={0}
											mouseLeaveDelay={0}
											overlay={<div>{cityCountryStr}</div>}
										>
											<span className="name">
												{excerpt(`${cityCountryStr}`, 20)}
											</span>
										</Tooltip>
									) : (
										<span className="name">{cityCountryStr}</span>
									)
								) : (
									<div className="placeholder" />
								)}
							</div>
						</li>
					)}
					<li>
						{ownCV ? (
							<div>
								<span className="icon-phone-handle" />
								<span className="name">
									{mobileUser ? phone : FREELANCER_PHONE_PLACEHOLDER}
								</span>
							</div>
						) : (
							<div>
								<span className="icon-phone-handle" />
								{mobileUser && mobileUser !== NO_DATA_PLACEHOLDER ? (
									<span className="name">{phone}</span>
								) : (
									<div className="placeholder" />
								)}
							</div>
						)}
					</li>
					<li>
						{ownCV ? (
							<div>
								<span className="icon-mail" />
								<span className="name">
									{emailUser ? emailUser : FREELANCER_EMAIL_PLACEHOLDER}
								</span>
							</div>
						) : (
							<div>
								<span className="icon-mail" />
								{emailUser && emailUser !== NO_DATA_PLACEHOLDER ? (
									<span className="name">{emailUser}</span>
								) : (
									<div className="placeholder" />
								)}
							</div>
						)}
					</li>
				</ul>
			</div>
		);
	}
}

BasicInfo.propTypes = {
	country: PropTypes.string,
	city: PropTypes.string,
	mobile: PropTypes.string,
	email: PropTypes.string,
	ownCV: PropTypes.bool,
	viewContactInfo: PropTypes.func
};

export default BasicInfo;
