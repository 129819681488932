import produce from "immer";
import { v4 as uuid } from "uuid";
import _get from "lodash/get";
import * as types from "../actions/vacancyFunctionsTypes";

const initialState = {
	functions: [],
	nextFunctions: [],
	sectors: [],
	sectorsExist: false,
	showModal: false
};

export default produce((draft = initialState, action) => {
	switch (action.type) {
		case types.SET_FUNCTIONS: {
			const normalized = JSON.parse(
				JSON.stringify(_get(action, "data", []))
			).map(f => ({
				...f,
				id: uuid()
			}));
			draft.functions = [...normalized];
			if (normalized.length) {
				draft.nextFunctions = [...normalized];
			} else {
				draft.nextFunctions = [
					{
						is_main: true,
						name: null,
						position: 1,
						seniority: null,
						id: uuid(),
						sector: {
							_id: null,
							name: null,
							parent_sector: {
								_id: null,
								name: null,
								group_name: null
							},
							group_name: null
						}
					}
				];
			}
			break;
		}
		case types.SET_SECTORS:
			draft.sectors = action.data;
			draft.sectorsExist = true;
			break;
		case types.ADD_FUNCTION:
			draft.nextFunctions.push({
				is_main: false,
				name: null,
				position: 1,
				seniority: null,
				id: uuid(),
				sector: {
					_id: null,
					name: null,
					parent_sector: {
						_id: null,
						name: null,
						group_name: null
					},
					group_name: null
				}
			});
			break;
		case types.REMOVE_FUNCTION:
			draft.nextFunctions = draft.nextFunctions.filter(f => f.id !== action.id);
			break;
		case types.UPDATE_FUNCTION: {
			const index = draft.nextFunctions.findIndex(
				fun => _get(fun, "id") === _get(action, "fun.id")
			);
			if (index !== -1) {
				draft.nextFunctions[index] = action.fun;
			}
			break;
		}
		case types.UPDATE_MAIN_FUNCTION:
			draft.nextFunctions = draft.nextFunctions.map(fun => {
				fun.is_main = _get(fun, "id") === action.id;
				return fun;
			});
			break;
		case types.RESET_FUNCTIONS:
			draft.nextFunctions = draft.functions;
			break;
		case types.INITIAL_STATE:
			draft = initialState;
			break;
		case types.SHOW_MODAL:
			draft.showModal = true;
			break;
		case types.CLOSE_MODAL:
			draft.showModal = false;
			break;
		default:
			return draft;
	}
});
