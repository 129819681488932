import React from "react";

import { Box, Text } from "./style";
import {
	HISTORY_TYPE,
	HISTORY_TYPE_LABEL,
	hexToRgbA
} from "modules/candidate/components/profileClientView/profileModalTab/components/interactions/constants";
import { SHORTLIST } from "config";

const Status = ({ type, isStatic, staticColor, staticBackground }) => {
	if (isStatic) {
		return (
			<Box background={staticBackground}>
				<Text color={staticColor}>{type === SHORTLIST ? "Sourced" : type}</Text>
			</Box>
		);
	}
	const color = HISTORY_TYPE[type];
	const background = hexToRgbA(HISTORY_TYPE[type], 0.14);
	const text = HISTORY_TYPE_LABEL[type];
	return (
		<Box background={background}>
			<Text color={color}>{text}</Text>
		</Box>
	);
};

export default Status;
