import React from "react";
import { useSelector } from "react-redux";
import { CLIENT } from "config";
import ProfileDefault from "./ProfileDefault";
import ProfileClient from "./ProfileClient";
import { userSelector } from "modules/company/selectors/ProfileSelector";

export const getMyProfileRoute = () => {
	return "/settings/myprofile";
};

export const getMyProfilePermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			},
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
};

export default function MyProfile(props) {
	const user = useSelector(state => userSelector(state));

	return (
		<>
			{user.type === CLIENT ? (
				<ProfileClient {...props} />
			) : (
				<ProfileDefault {...props} />
			)}
		</>
	);
}
