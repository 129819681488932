import React, { useState } from "react";
import { DRAFT, DISPUTED, SUBMITTED, APPROVED, ACCEPTED } from "config";
import { ReactComponent as DisputeTimesheetIcon } from "static/icons/dispute-timesheet-icon.svg";
import { ReactComponent as GlobalActionIcon } from "static/icons/arrow-right-icon.svg";
import { ReactComponent as DiscardChangesIcon } from "static/icons/discard-changes-icon.svg";
import toaster from "common/Toaster";
import { get } from "lodash";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { Button, ButtonsContainer } from "common/styled/buttons";
import Icon from "common/styled/icons";
import {
	ACCEPT_ALL_ENTRIES,
	RESUBMIT_TIMESHEET,
	SUBMIT_TIMESHEET,
	TRACK_TIMESHEET_EDITION
} from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";
import { Flex, Text } from "rebass";
import AddVatNumberModal from "./AddVatNumberModal";
import { useSelector } from "react-redux";
import AddVatResubmitModal from "./AddVatResubmitModal";

const reSubmitTimesheet = data => client(RESUBMIT_TIMESHEET, { body: data });
const submitTimesheet = data => client(SUBMIT_TIMESHEET, { body: data });
const acceptAllEntries = data => client(ACCEPT_ALL_ENTRIES, { body: data });
const unLockTimesheet = timesheetId => {
	return client(TRACK_TIMESHEET_EDITION, {
		body: {
			timesheet_id: timesheetId,
			action: "unlock"
		}
	});
};

const TimesheetActions = ({
	attachments = [],
	status,
	isConsultantAdmin,
	timesheetId,
	disableSubmit,
	disputedWorkTime,
	disputedExpense,
	loadTimeSheet,
	addedWorkTimes,
	addedOverTimes,
	addedExpenses,
	isMultiCheck,
	allItems,
	canApprove,
	companyId,
	setDisputedExpense,
	setDisputedWorkTime,
	setAddedExpense,
	setAddedWorkTime,
	setAddedOverTime,
	clearResubmittedTimesheet
}) => {
	const [showResubmitModal, setShowReSubmitModal] = useState(false);
	const [showSubmitModal, setShowSubmitModal] = useState(false);
	const [showAcceptModal, setShowAcceptModal] = useState(false);
	const [showAddVatModal, setShowAddVatModal] = useState(false);
	const [showAddVatResubmitModal, setShowAddVatResubmitModal] = useState(false);

	const { company_vat: companyVat } = useSelector(state => state.auth.user);
	const { formFiles, uploadedFilesList } = useSelector(state => {
		const {
			data: { required_files, files }
		} = state.timesheet;

		return {
			formFiles: Object.keys(required_files || {}).map(
				requiredFile_id => required_files[requiredFile_id]
			),
			uploadedFilesList: Object.keys(files || {}).map(fileId => files[fileId])
		};
	});

	const requiredFiles = formFiles.filter(
		formFile =>
			formFile.is_required &&
			!uploadedFilesList.some(uploadedFile => uploadedFile._id === formFile._id)
	);

	const toggleAcceptAllEntriesModal = () =>
		setShowAcceptModal(!showAcceptModal);

	const toggleResubmitModal = () => setShowReSubmitModal(!showResubmitModal);
	const toggleSubmitModal = () => setShowSubmitModal(!showSubmitModal);
	const toggleAddVatModal = () => setShowAddVatModal(!showAddVatModal);
	const toggleAddVatResubmitModal = () =>
		setShowAddVatResubmitModal(!showAddVatResubmitModal);

	const [onReSubmitTimesheet] = useMutation(reSubmitTimesheet, {
		onSuccess: () => {
			clearResubmittedTimesheet();
			toggleResubmitModal();
			loadTimeSheet();
		},
		onError: error => {
			if (error && error.status === 422) {
				let errorMsg = get(error, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			} else {
				toaster.danger(get(error, "detail", get(error, "title")));
			}
		}
	});

	const [onSubmitTimesheet, { isLoading }] = useMutation(submitTimesheet, {
		onSuccess: () => {
			toggleSubmitModal();
			loadTimeSheet();
			toaster.success("Timesheet submitted successfully");
		},
		onError: error => {
			if (error && error.status === 422) {
				let errorMsg = get(error, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			} else {
				toaster.danger(get(error, "detail", get(error, "title")));
			}
		}
	});
	const [onUnlockTimesheet] = useMutation(unLockTimesheet);

	const handleOnSubmitTimesheet = () => {
		const data = {
			timesheet_id: timesheetId
		};
		onSubmitTimesheet(data);
	};
	const handleOnReSubmitTimesheet = () => {
		const data = {
			timesheet_id: timesheetId,
			expenses: {
				edited: disputedExpense.map(item => {
					delete item.status;
					return item;
				}),
				added: addedExpenses.map(item => {
					delete item.status;
					delete item.generatedId;
					return item;
				})
			},

			worktimes: {
				edited: disputedWorkTime.map(item => {
					delete item.hours_representation;
					delete item.freelancer_amount;
					delete item.status;
					delete item.duration;
					return item;
				}),
				added: [...addedOverTimes, ...addedWorkTimes].map(item => {
					delete item.hours_representation;
					delete item.freelancer_amount;
					delete item.status;
					delete item.generatedId;
					delete item.duration;
					return item;
				})
			},
			attachments: {
				edited: [],
				added: [
					...attachments.map(item => {
						return {
							name: item.file_name,
							path: item.path
						};
					})
				]
			}
		};

		onReSubmitTimesheet(data);
	};

	const [onAcceptAllEntries, { isLoading: onAcceptLoading }] = useMutation(
		acceptAllEntries,
		{
			onSuccess: () => {
				toggleAcceptAllEntriesModal();
				loadTimeSheet();
			},
			onError: error => {
				if (error && error.status === 422) {
					let errorMsg = get(error, "detail.name");

					if (errorMsg) {
						toaster.danger(errorMsg);
					}
				} else {
					toaster.danger(get(error, "detail", get(error, "title")));
				}
			}
		}
	);

	const handleOnAcceptAllEntries = () => {
		let mappedWorktimesIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);

		let mappedExpensesIds = disputedExpense.map(
			({ worktime_id }) => worktime_id
		);
		let worktimes = allItems.worktimes
			.filter(
				item =>
					canApprove(item.tag) &&
					!mappedWorktimesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
			)
			.map(({ _id }) => _id);
		let overtimes = get(allItems, "overtimes")
			.filter(
				item =>
					canApprove(item.tag) &&
					!mappedWorktimesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
			)
			.map(({ _id }) => _id);
		let expenses = allItems.expenses
			.filter(
				item =>
					canApprove(item.tag) &&
					!mappedExpensesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
			)
			.map(({ _id }) => _id);

		const data = {
			timesheet_id: timesheetId,
			worktimes: [...worktimes, ...overtimes],
			expenses
		};
		onAcceptAllEntries(data);
	};

	const isEdited = [
		disputedExpense,
		disputedWorkTime,
		addedExpenses,
		addedWorkTimes,
		addedOverTimes,
		attachments
	].some(item => item.length > 0);

	const disableAcceptAllEntries = () => {
		let mappedWorktimesIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);

		let mappedExpensesIds = disputedExpense.map(
			({ worktime_id }) => worktime_id
		);
		let worktimes = allItems.worktimes
			.filter(item => {
				return (
					canApprove(item.tag) &&
					!mappedWorktimesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
				);
			})
			.map(({ _id }) => _id);
		let overtime = allItems.overtimes
			.filter(item => {
				return (
					canApprove(item.tag) &&
					!mappedWorktimesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
				);
			})
			.map(({ _id }) => _id);
		let expenses = allItems.expenses
			.filter(item => {
				return (
					canApprove(item.tag) &&
					!mappedExpensesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
				);
			})
			.map(({ _id }) => _id);
		return worktimes.length || overtime.length || expenses.length;
	};

	const displayDiscardChangesButton = [
		disputedExpense.length,
		addedExpenses.length,
		disputedWorkTime.length,
		addedWorkTimes.length,
		addedOverTimes.length,
		attachments.length
	].some(Boolean);

	const discardChanges = () => {
		setDisputedExpense([]);
		setAddedExpense([]);
		setDisputedWorkTime([]);
		setAddedWorkTime([]);
		setAddedOverTime([]);
		onUnlockTimesheet(timesheetId);
	};

	return (
		<React.Fragment>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					position: "absolute",
					zIndex: 998,
					bottom: "71px",
					width: "100%",
					padding: "10px",
					background: "#FFFFFF",
					boxShadow: "0px -4px 21px rgba(33, 43, 54, 0.05)",
					borderRadius: "10px 10px 0px 0px"
				}}
			>
				<Flex width="100%" alignItems="center" justifyContent="space-between">
					<Flex alignItems="center">
						<GlobalActionIcon />
						<Text
							ml="12px"
							sx={{
								fontFamily: "BasierCircle",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "14px",
								lineHeight: "17px"
							}}
						>
							Global action
						</Text>
					</Flex>

					<ButtonsContainer display="flex">
						{status !== DRAFT && status !== APPROVED && !isEdited && (
							<Button
								disabled={!Boolean(disableAcceptAllEntries())}
								color="success"
								size={14}
								onClick={toggleAcceptAllEntriesModal}
								key="accept"
							>
								<Icon className="fa icon-check" /> Approve all entries
							</Button>
						)}
						{status === DRAFT && !isConsultantAdmin && (
							<Button
								disabled={disableSubmit}
								color="success"
								size={14}
								onClick={
									companyVat && requiredFiles.length === 0
										? toggleSubmitModal
										: toggleAddVatModal
								}
								key="accept"
							>
								<Icon className="fa icon-check" /> Submit Timesheet
							</Button>
						)}
						{!isConsultantAdmin &&
							status === SUBMITTED &&
							(isEdited ? (
								<React.Fragment>
									{displayDiscardChangesButton && (
										<button
											className="discard-changes-button"
											onClick={discardChanges}
										>
											<DiscardChangesIcon style={{ marginRight: "8px" }} />
											Discard changes
										</button>
									)}
									<Button
										color="success"
										size={14}
										onClick={
											companyVat
												? toggleResubmitModal
												: toggleAddVatResubmitModal
										}
										key="accept"
									>
										<DisputeTimesheetIcon style={{ marginRight: "6px" }} />{" "}
										Resubmit timesheet
									</Button>
								</React.Fragment>
							) : null)}
						{!isConsultantAdmin &&
							status === DISPUTED &&
							isEdited &&
							!isMultiCheck && (
								<React.Fragment>
									<button
										className="discard-changes-button"
										onClick={discardChanges}
									>
										<DiscardChangesIcon style={{ marginRight: "8px" }} />
										Discard changes
									</button>
									<Button
										color="timesheetWarning"
										size={14}
										onClick={
											companyVat
												? toggleResubmitModal
												: toggleAddVatResubmitModal
										}
										key="accept"
										style={{
											fontFamily: "BasierCircle",
											fontSize: "13px",
											fontWeight: "600",
											backgroundColor: "#E99010",
											color: "white",
											height: "49px"
										}}
									>
										<DisputeTimesheetIcon style={{ marginRight: "6px" }} />{" "}
										Resubmit timesheet
									</Button>
								</React.Fragment>
							)}
					</ButtonsContainer>
				</Flex>
			</div>

			<ConfirmationModal
				active={showResubmitModal}
				modalName="resubmit-timesheet"
				onClose={toggleResubmitModal}
				firstButton={{
					action: handleOnReSubmitTimesheet,
					label: "YES, RE-SUBMIT",
					...(status === DISPUTED && { color: "timesheetWarning" })
				}}
				title={"Are you sure you want to re-submit ?"}
				type={
					status === SUBMITTED
						? ConfirmationTypes.confirmation
						: "timesheet-warning"
				}
			/>

			<ConfirmationModal
				active={showSubmitModal}
				modalName="timesheet-modal"
				onClose={toggleSubmitModal}
				type={ConfirmationTypes.confirmation}
				firstButton={{
					label: "Yes, submit this timesheet",
					action: handleOnSubmitTimesheet
				}}
				title={"Are you sure you want to submit this timesheet?"}
				loading={isLoading}
			/>
			<ConfirmationModal
				active={showAcceptModal}
				modalName="accept-timesheet"
				onClose={toggleAcceptAllEntriesModal}
				firstButton={{
					action: handleOnAcceptAllEntries,
					label: "Yes, approve this timesheet"
				}}
				title={`Are you sure you want to approve this timesheet?`}
				type={ConfirmationTypes.confirmation}
				loading={onAcceptLoading}
			/>
			<AddVatNumberModal
				active={showAddVatModal}
				onClose={toggleAddVatModal}
				timeSheetId={timesheetId}
				companyId={companyId}
				toggleAddVatModal={toggleAddVatModal}
				loadTimeSheet={loadTimeSheet}
				requiredFiles={requiredFiles || []}
				companyVat={companyVat}
			/>
			<AddVatResubmitModal
				active={showAddVatResubmitModal}
				onClose={toggleAddVatResubmitModal}
				handleOnReSubmitTimesheet={handleOnReSubmitTimesheet}
			/>
		</React.Fragment>
	);
};

export default TimesheetActions;
