import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Header as StyledHeader, Status } from "../ProfileStyle";
import { changePokabilityStatus } from "modules/notifications/actions";
import ProfileImage from "modules/company/components/avatar/profileAvatar.js";

import Icon from "common/styled/icons";
import { CV, PUBLIC, ACTIVE } from "config";

function HeaderComponent({
	user: { id, candidate_status, avatar } = {},
	headline = {},
	displayCandidateModal
}) {
	const fullName = `${headline.first_name} ${headline.last_name}`;

	return (
		<StyledHeader isOwnProfile={true}>
			<div className="container" style={{ maxWidth: "1054px", width: "100%" }}>
				<div className="col-sm-2" style={{ marginTop: "6px" }}>
					<ProfileImage user={{ ...headline, id, avatar }} type={CV} />
				</div>

				<div className="col-sm-6">
					<div className="info-permanent">
						<div
							style={{
								marginTop: "2px"
							}}
						>
							<span className="username">{fullName}</span>

							<Status className={candidate_status}>
								{candidate_status === PUBLIC ? ACTIVE : candidate_status}{" "}
							</Status>
						</div>
						<div
							id="contact"
							style={{
								display: "block",
								lineHeight: "2.8"
							}}
						>
							<div
								style={{
									display: "block",
									fontSize: "13px"
								}}
							>
								<span className="icon-mail" />
								<span>{headline.email}</span>
							</div>
							<div
								className="contact-phone"
								style={{
									display: "block",
									fontSize: "13px"
								}}
							>
								<span className="icon-phone-handle" />

								<span>
									{headline.phone ? (
										headline.phone
									) : (
										<div className="placeholder" />
									)}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div
					className="col-sm-4 section-edit"
					style={{
						paddingRight: "18px"
					}}
				>
					<button className="btn-edit-profile" onClick={displayCandidateModal}>
						<Icon className="icon-edit-2" /> EDIT PERSONAL DETAILS
					</button>
				</div>
			</div>
		</StyledHeader>
	);
}
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			changePokabilityStatus
		},
		dispatch
	);
export default connect(null, mapDispatchToProps)(HeaderComponent);
