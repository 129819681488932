const localFeatures =
	JSON.parse(localStorage.getItem("available_features")) || {};
const getFeaturesFromLocalStorage = {
	features: localFeatures,
	isFeatureEnabled: function(feature) {
		return this.features[feature] !== undefined
			? this.features[feature].active
			: false;
	}
};
export const isFeatures = !!localStorage.getItem("available_features");
export default getFeaturesFromLocalStorage;
