import React, { useMemo, useState } from "react";
import { Flex, Box, Text } from "rebass";
import { Scrollbars } from "react-custom-scrollbars";
import FilesUploader from "modules/bids/components/createbid/FilesUploader";

import Modal from "common/modal/Modal";
import { Input } from "@rebass/forms";
import useAddVatNumber from "./hooks/useAddVatNumber";
import _get from "lodash/get";
import { useDispatch } from "react-redux";
import { getMyProfile } from "modules/user/actions/authActions";
import toaster from "common/Toaster";
import get from "lodash/get";

const AcceptContract = ({
	onClose,
	files: propFiles,
	toggleContractConfirmation,
	companyVat,
	isFilesRequired
}) => {
	const [hasRequired, setHasRequired] = useState(false);
	const [files, setFiles] = useState(propFiles);
	const [vatNumber, setVatNumber] = useState(null);
	const [vatError, setVatError] = useState("");
	const [addVatNumber, { isLoading }] = useAddVatNumber();
	const hasEmpty = useMemo(() => files.some(f => f.is_required && !f.path), [
		files
	]);
	const isShowFiles = _get(isFilesRequired, "required_files", []).length > 0;
	const dispatch = useDispatch();

	const onDoneUploading = file => {
		const fs = [...files];
		const index = fs.findIndex(f => f._id === file._id);
		if (index !== -1) {
			fs[index] = { ...fs[index], ...file };
			setFiles(fs);
		}
	};

	const handleFileNameChange = (_id, value) => {
		const fs = [...files];
		const index = files.findIndex(f => f._id === _id);
		if (index !== -1) {
			fs[index].name = value;
			setFiles(fs);
		}
	};
	const handleNext = async files => {
		if (hasEmpty) {
			return setHasRequired(true);
		} else if (!companyVat) {
			if (vatNumber) {
				await addVatNumber(
					{ vat: vatNumber },
					{
						onSuccess: () => {
							dispatch(getMyProfile());
							onClose();
							toggleContractConfirmation(files);
						},
						onError: error => {
							let errorMessage = get(error, "detail");

							if (error.status === 422) {
								if (typeof errorMessage === "object") {
									Object.keys(errorMessage).forEach(name => {
										get(errorMessage, name) &&
											setVatError(
												typeof get(errorMessage, name) === "string"
													? get(errorMessage, name)
													: get(errorMessage, name)[0]
											);
									});
								} else {
									toaster.danger(errorMessage);
								}
								return;
							} else {
								toaster.danger("An error has occurred");
							}
						}
					}
				);
			} else {
				setVatError("VAT number is required");
				return;
			}
		}

		//goToLastStep();
	};

	const onChange = e => {
		setVatNumber(e.target.value);
	};

	return (
		<Modal onClose={onClose}>
			<>
				{isShowFiles && (
					<Box mt={10} mb={60}>
						<Text
							mb={35}
							as={"h2"}
							sx={{
								fontSize: "37.5px",
								fontWeight: "300",
								letterSpacing: "-0.49px",
								color: "rgb(253, 104, 73)"
							}}
						>
							Required document(s)
						</Text>
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "16px",
								letterSpacing: "-0.21px",
								color: "rgb(80, 92, 104)",
								width: "80%"
							}}
							mb={44}
						>
							The company your applying is requiring that you provide the
							following documents to move to the next stage:
						</Text>
						<Flex mb={15}>
							<Box width={1 / 3} mr={16}>
								<Text
									sx={{
										fontFamily: "Roboto",
										fontSize: "14px",
										fontWeight: "bold",
										letterSpacing: "-0.18px",
										color: "#58626b"
									}}
								>
									DOCUMENT TYPE
								</Text>
							</Box>
							<Box width={1 / 3} mr={16}>
								<Text
									sx={{
										fontFamily: "Roboto",
										fontSize: "14px",
										fontWeight: "bold",
										letterSpacing: "-0.18px",
										color: "#58626b"
									}}
								>
									NAME YOUR FILE
								</Text>
							</Box>
							<Box width={1 / 3}>
								<Text
									sx={{
										fontFamily: "Roboto",
										fontSize: "14px",
										fontWeight: "bold",
										letterSpacing: "-0.18px",
										color: "#58626b"
									}}
								>
									UPLOAD YOUR FILE
								</Text>
							</Box>
						</Flex>
						<Scrollbars
							autoHide
							autoHideTimeout={5000}
							autoHideDuration={200}
							autoHeight
							autoHeightMax={260}
							style={{ zIndex: 1, paddingBottom: "16px" }}
							renderTrackHorizontal={props => (
								<div
									{...props}
									style={{ display: "none" }}
									className="track-horizontal"
								/>
							)}
							renderView={props => (
								<div
									{...props}
									style={{ ...props.style, overflowX: "hidden" }}
								/>
							)}
						>
							{files.map(file => (
								<FilesUploader
									key={file._id}
									_id={file._id}
									name={file.name}
									path={file.path || undefined}
									isRequired={file.is_required}
									onDoneUploading={onDoneUploading}
									onNameChange={handleFileNameChange}
								/>
							))}
						</Scrollbars>
						{hasRequired && hasEmpty ? (
							<Box
								sx={{
									color: "#242f43",
									fontWeight: 400,
									backgroundColor: "#fff6f9",
									border: "1px solid #f75356",
									borderRadius: 4,
									padding: 3,
									marginTop: 3
								}}
							>
								<strong>Missing some mandatory documents.</strong> Please upload
								all the required elements.
							</Box>
						) : null}
					</Box>
				)}
				{!companyVat && (
					<Box mt={10} mb={15}>
						<Text
							mb={35}
							as={"h2"}
							sx={{
								fontSize: "37.5px",
								fontWeight: "300",
								letterSpacing: "-0.49px",
								color: "rgb(253, 104, 73)"
							}}
						>
							Add VAT
						</Text>
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "16px",
								letterSpacing: "-0.21px",
								color: "rgb(80, 92, 104)",
								width: "80%"
							}}
							mb={44}
						>
							You need to add your VAT number to proceed
						</Text>
						<Flex mb={15} flexDirection={"column"}>
							<Text
								sx={{
									fontFamily: "Roboto",
									fontSize: "14px",
									fontWeight: "bold",
									letterSpacing: "-0.18px",
									color: "#58626b",
									marginBottom: "10px"
								}}
							>
								VAT number <span style={{ color: "red" }}>*</span>
							</Text>
							<Input
								onChange={onChange}
								id="vat_number"
								name="vat_number"
								type="text"
								placeholder="Type VAT number here"
								value={vatNumber}
								style={{
									border: vatError && "1px solid red"
								}}
							/>
							{vatError && (
								<Text sx={{ color: "red", marginTop: "5px" }}>{vatError}</Text>
							)}
						</Flex>
					</Box>
				)}

				<div className="actions">
					<div className="row">
						<div className="col-md-3">
							<button
								className="btn btn-outline-new btn-block"
								onClick={onClose}
							>
								Cancel
							</button>
						</div>
						<div className="col-md-4">
							<button
								className="btn btn-primary-new btn-block"
								onClick={() => handleNext(files)}
								disabled={isLoading}
							>
								Accept Contract
							</button>
						</div>
					</div>
				</div>
			</>
		</Modal>
	);
};

export default AcceptContract;
