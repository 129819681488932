import React from "react";
import styles from "./bid-details-processes-card.module.scss";
import { BidStatusBadge } from "../BidStatusBadge";
import { BidDetailsStatusTooltip } from "../BidDetailsTooltips";
import { ReactComponent as LoadingIcon } from "static/icons/loading.svg";

const BidDetailsProcessesCard = ({
	title,
	status,
	statusTooltip,
	children,
	isFetching
}) => {
	return (
		<div className={styles.card}>
			{(title || status) && (
				<>
					<div className={styles.header}>
						<span className={styles.title}>{title}</span>
						{isFetching ? (
							<LoadingIcon />
						) : (
							status && (
								<BidDetailsStatusTooltip content={statusTooltip}>
									<span className={styles.status}>
										<BidStatusBadge status={status} />
									</span>
								</BidDetailsStatusTooltip>
							)
						)}
					</div>
					<div className={styles.divider}></div>
				</>
			)}
			{children}
		</div>
	);
};

export default BidDetailsProcessesCard;
