import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import _split from "lodash/split";
import _get from "lodash/get";
import { client } from "lib/api-client";
import { VACANCY_LEVEL } from "config";
import {
	addressObjectToString,
	dateToTimestamp,
	formatDateToCalendarObject,
	generateId,
	isHTML
} from "config/helpers";
import { mdToDraftjs } from "draftjs-md-converter";
import draftToHtml from "draftjs-to-html";
import { FULL_TIME, PART_TIME } from "./constants";
import { DAILY } from "config";

export const formatCategoriesList = list => {
	const valueField = "_id";
	const options = [];

	list.forEach(opt => {
		if (opt.group_name !== "") {
			const groupIdx = options.findIndex(
				grp => grp.label === opt.group_name && !opt.hidden
			);

			if (groupIdx === -1) {
				options.push({
					label: opt.group_name,
					value: opt.group_name,
					id: opt._id,
					children: [
						{
							label: opt.name,
							children: opt.children,
							value: opt[valueField]
						}
					]
				});
			} else {
				const child = {
					id: opt._id,
					label: opt.name,
					value: opt[valueField],
					children: opt.children
				};

				_get(options[groupIdx], "children", []).push(child);
			}
		} else {
			options.push({
				label: opt.name,
				value: opt[valueField],
				id: opt._id,
				subs: opt.children
			});
		}
	});

	return options;
};

export const getExtraBenefitsList = () => {
	return new Promise((resolve, reject) => {
		client(FILTER_SETTINGS_ENDPOINT, {
			body: {
				tag: "list_extra_benefits"
			}
		})
			.then(res => {
				const formattedData = formatExtraBenefitsList(
					res.extra_benefits,
					"Extra benefits"
				);
				resolve(formattedData);
			})
			.catch(e => reject(e));
	});
};

function formatExtraBenefitsList(data, label) {
	const dataFormatted = data.map(item => {
		const { label, value } = item;

		return {
			label,
			id: value
		};
	});

	const parent = [{ id: 1, label, children: dataFormatted }];

	return parent;
}

export const buildEditVacancyInformationsPayload = (values, id) => {
	const {
		description,
		applicantLocation,
		vacancyAddress,
		vacancyAddressType,
		contractType,
		title,
		rate,
		hourlyRate,
		remoteEligibility,
		featuredImage,
		featuredVideo,
		daysPerWeek,
		startDate,
		endDate,
		time,
		hours,
		allowSubmission
	} = values;

	const body = {
		id,
		step: 2,
		job_type: "request",
		start_date: formateDate(startDate),
		end_date: formateDate(endDate),
		full_time: time === FULL_TIME.value,
		part_time: time === PART_TIME.value,
		contract_type: contractType,
		description: description,
		title: title,
		applicants_location: [applicantLocation.value],
		budget: hourlyRate,
		location_type: vacancyAddressType,
		time_type: time,
		rate_type: rate,
		remote_eligibility: remoteEligibility,
		featured_image: featuredImage,
		featured_video: featuredVideo,
		office_days: daysPerWeek,
		allow_overtime: allowSubmission,
		timesheet_frequency: values.timesheetFrequency,
		currency: values.currency.value,
		hours:
			rate === DAILY
				? hours &&
				  window.moment(hours).hours() * 60 + window.moment(hours).minutes()
				: undefined,
		...vacancyAddress.value
	};

	return body;
};

export const formateDate = value => {
	return dateToTimestamp(`${value.day}-${value.month}-${value.year}`);
};

export const getFormDefaultValues = (
	{
		title,
		description,
		applicants_location,
		location_type,
		budget,
		reporting_settings,
		contract_type,
		start_date,
		end_date,
		full_time,
		remote_eligibility,
		featured_video,
		featured_image,
		...rest
	},
	{ applicantLocations }
) => {
	let descriptionInHTML;

	if (isHTML(description)) {
		descriptionInHTML = description;
	} else {
		const rawData = mdToDraftjs(description);
		descriptionInHTML = draftToHtml(rawData);
	}

	const applicantLocationObject = applicantLocations.find(
		({ value }) => value.code === applicants_location[0].code
	);

	const vacancyAddress = formatVacancyAddress(getVacancyAddress(rest));

	return {
		title,
		description: descriptionInHTML,
		applicantLocation: applicantLocationObject,
		vacancyAddressType: location_type,
		hourlyRate: budget,
		contractType: contract_type,
		timesheetFrequency: reporting_settings?.timesheet_frequency,
		currency: {
			value: reporting_settings?.currency?.code
		},
		rate: reporting_settings.rate_type,
		startDate: formatDateToCalendarObject(start_date),
		endDate: formatDateToCalendarObject(end_date),
		time: full_time ? FULL_TIME.value : PART_TIME.value,
		hours: window.moment({
			hours: _split(reporting_settings.hours_representation, ":")[0],
			minutes: _split(reporting_settings.hours_representation, ":")[1]
		}),
		allowSubmission: reporting_settings.allow_overtime,
		vacancyAddress: {
			value: vacancyAddress,
			label: addressObjectToString(vacancyAddress)
		},
		remoteEligibility: remote_eligibility?.type,
		daysPerWeek: remote_eligibility?.office_days,
		featuredVideo: featured_video,
		featuredImage: featured_image
	};
};

export const initializeProfileForm = (
	reset,
	{ group, languages, skills, seniority, category, subcategory, site, ...rest },
	categoriesList
) => {
	reset({
		department: {
			label: group.name,
			value: group._id
		},
		site: {
			label: site?.name,
			value: site?._id
		},
		languages: formatLanguages(languages),
		seniority: formatSeniority(seniority),
		category: formatCategory(
			{
				label: category.name,
				value: category._id
			},
			categoriesList
		),
		subCategory: {
			label: subcategory.name,
			value: subcategory._id
		},
		_function: {
			label: rest["function"],
			value: rest["function"]
		},
		skills: formatSkills(skills)
	});
};

const formatVacancyAddress = ({
	street,
	number,
	box,
	city,
	zip,
	country,
	latitude,
	iso_country,
	company_street,
	company_number,
	company_box,
	company_city,
	company_zip,
	company_country,
	company_longitude,
	company_latitude,
	company_iso_country,
	longitude
}) => {
	return {
		street: street || company_street,
		number: number || company_number,
		box: box || company_box,
		city: city || company_city,
		zip: zip || company_zip,
		country: country || company_country,
		longitude: longitude || company_longitude,
		latitude: latitude || company_latitude,
		iso_country: iso_country || company_iso_country
	};
};

const getVacancyAddress = vacancy => {
	const {
		street,
		number,
		box,
		city,
		zip,
		country,
		latitude,
		iso_country,
		company_street,
		company_number,
		company_box,
		company_city,
		company_zip,
		company_country,
		company_longitude,
		company_iso_country,
		longitude,
		company_latitude
	} = vacancy;

	return {
		street,
		number,
		box,
		city,
		zip,
		country,
		latitude,
		iso_country,
		company_street,
		company_number,
		company_box,
		company_city,
		company_zip,
		company_country,
		company_longitude,
		company_iso_country,
		longitude,
		company_latitude
	};
};

const formatCategory = (value, categoriesList) => {
	const { children } = categoriesList.find(({ _id }) => _id === value.value);

	return {
		...value,
		children
	};
};

const formatSeniority = seniority => {
	return VACANCY_LEVEL.find(({ value }) => value === seniority);
};

const formatLanguages = languages => {
	return languages.map(({ _id, name, score }) => {
		return {
			id: generateId(),
			language: {
				label: name,
				value: _id
			},
			proficiency: score
		};
	});
};

const formatSkills = skills => {
	return skills.map(({ must_have, name, _id, score }) => {
		return {
			_id,
			name,
			proficiency: score,
			mustHave: must_have
		};
	});
};
