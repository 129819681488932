import React, { useState, useRef } from "react";
import { Range } from "rc-slider";
import { RangeValues, RangeContainer } from "./Styled";
import useOnClickOutside from "hooks/useOnClickOutside";

const RangeFilter = ({ value = [0, 100], onChange }) => {
	const [displayRange, setDisplayRange] = useState(false);
	const rangeSelectorRef = useRef();

	useOnClickOutside(rangeSelectorRef, () => setDisplayRange(false));

	return (
		<>
			<RangeValues onClick={() => setDisplayRange(true)}>
				{`${value[0]}% - ${value[1]}%`}
				<span
					className="icon-close"
					onClick={e => {
						onChange([0, 100]);
						setDisplayRange(false);
						e.stopPropagation();
					}}
				/>
			</RangeValues>
			{displayRange && (
				<RangeContainer ref={rangeSelectorRef}>
					<div className="title">Select a range</div>
					<Range
						count={1}
						defaultValue={[0, 100]}
						onChange={onChange}
						value={value}
					/>
				</RangeContainer>
			)}
		</>
	);
};

export default RangeFilter;
