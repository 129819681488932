import { useQuery } from "react-query";
import { WEATHER_API_KEY } from "config";
import axios from "axios";
export const ipUser = "@home/ip_user";

const ListIpUser = async () => {
	return axios(
		`https://api.weatherapi.com/v1/ip.json?key=${WEATHER_API_KEY}&q=auto:ip`
	);
};
const useApiIp = (options, queryOptions = {}) =>
	useQuery([ipUser, options], ListIpUser, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useApiIp;
