import React, { Component } from "react";
import PropTypes from "prop-types";
import Rater from "react-rater";
import _get from "lodash/get";
import _has from "lodash/has";
import _last from "lodash/last";
import GlobalTooltip from "common/GlobalTooltip";
import { excerpt } from "common/Functions";
import ScorerKnob from "./ScorerKnob";
import { IMPORTANCE } from "config";
import {
	ScorerTable,
	ScorerThead,
	Th,
	ScorerTbody,
	Tr,
	Td
} from "./ScorerStyled";
import { ListButton } from "../editablelist/style";

class Scorer extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidUpdate({ items, idPath, scorePath }) {
		const id = _get(_last(this.props.items), idPath);
		if (this.props.items.length > items.length && _has(this.state, id)) {
			this._body.scrollTop = this._body.scrollHeight;
			//initializing score for new items that were previously added and removed
			this.setState({
				[id]: _get(_last(this.props.items), scorePath)
			});
		}
	}

	onChangeScore = ({ rating }, item) => {
		const { idPath } = this.props;
		const id = _get(item, idPath);
		if (!this.state[id] || this.state[id] !== rating) {
			this.setState({
				[id]: rating
			});
		}
	};

	onLeaveScore = item => {
		const { idPath } = this.props;
		this.setState({
			[_get(item, idPath)]: item.score
		});
	};

	render() {
		const {
			items,
			itemsLabel,
			scoreLabel,
			maxScore,
			onRemove,
			labelName,
			idPath,
			scorePath,
			isTag,
			tooltipFormater,
			onScoreChange
		} = this.props;
		return (
			<ScorerTable>
				<ScorerThead>
					<tr>
						<Th>{itemsLabel}</Th>
						{scoreLabel && <Th>{scoreLabel}</Th>}
						<Th />
					</tr>
				</ScorerThead>
				<ScorerTbody ref={ref => (this._body = ref)}>
					{items.map((item, index) => {
						const score =
							this.state[_get(item, idPath)] || _get(item, scorePath);
						const isTooltipActive = _get(item, labelName, "").length >= 14;
						return (
							<Tr key={index}>
								<Td>
									{isTag ? (
										<span>{_get(item, "label")}</span>
									) : (
										<GlobalTooltip
											placement="top"
											overlay={_get(item, labelName)}
											active={isTooltipActive}
											withWrappingDiv={false}
										>
											<span>{excerpt(_get(item, labelName), 14)}</span>
										</GlobalTooltip>
									)}
								</Td>
								{score && (
									<Td>
										<Rater
											total={maxScore}
											rating={score}
											onRating={event => this.onChangeScore(event, item)}
											onCancelRate={() => this.onLeaveScore(item)}
											onRate={event => onScoreChange(item, event.rating)}
											className="reactRater"
										>
											<ScorerKnob
												hasTooltip={true}
												tooltip={tooltipFormater ? tooltipFormater(score) : ""}
											/>
										</Rater>
									</Td>
								)}
								<Td>
									<ListButton onClick={() => onRemove(item)}>
										<span className="icon-close2" />
									</ListButton>
								</Td>
							</Tr>
						);
					})}
				</ScorerTbody>
			</ScorerTable>
		);
	}
}

Scorer.propTypes = {
	items: PropTypes.array.isRequired,
	itemsLabel: PropTypes.string.isRequired,
	scoreLabel: PropTypes.string,
	maxScore: PropTypes.number.isRequired,
	onRemove: PropTypes.func.isRequired,
	onScoreChange: PropTypes.func.isRequired,
	tooltipFormater: PropTypes.func,
	labelName: PropTypes.string,
	idPath: PropTypes.string
};

Scorer.defaultProps = {
	scoreLabel: IMPORTANCE,
	labelName: "name",
	idPath: "_id",
	scorePath: "score"
};

export default Scorer;
