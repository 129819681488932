import React, { useState, useEffect, useRef } from "react";
import get from "lodash/get";
import cx from "classnames";
import { ReactComponent as AddMember } from "static/icons/add-member-icon.svg";
import { ReactComponent as RemoveMember } from "static/icons/remove-member-icon.svg";
import { MenuItem, Label, WrapContent } from "./styles";
import { ReactComponent as ArrowIcon } from "static/icons/arrow_down.svg";

function PanelItems({
	elem,
	functionKey = "user_function",
	handleSelect,
	renderAvatar,
	selectIcon = <AddMember />,
	unSelectIcon = <RemoveMember />,
	includedItems,
	idKey,
	searchKeyword
}) {
	const [open, setOpen] = useState(false);
	const firstUpdate = useRef(false);

	useEffect(() => {
		if (!firstUpdate.current) {
			firstUpdate.current = true;
			return;
		}

		setOpen(true);
	}, [searchKeyword]);

	useEffect(() => {
		setOpen(false);
	}, [searchKeyword.length === 0]);
	useEffect(() => {
		const isShowUserSelected = get(elem, "users", []).some(
			user => includedItems.findIndex(item => item[idKey] === user[idKey]) > -1
		);
		setOpen(isShowUserSelected);
	}, [elem]);

	const isSelected = user => {
		return includedItems.findIndex(item => item[idKey] === user[idKey]) === -1
			? selectIcon
			: unSelectIcon;
	};

	return (
		<WrapContent>
			<div className="panel_collapse">
				<div className="left_side">
					{renderAvatar(elem)}
					<div className="info">
						{` ${get(elem, "name", "")}`}
						<br />
						<Label>{get(elem, "industry", "")}</Label>
					</div>
				</div>
				<button
					className={cx({ ["opened"]: open })}
					type="button"
					onClick={() => setOpen(!open)}
				>
					<ArrowIcon />
				</button>
			</div>
			{open &&
				get(elem, "users", []).map((user, index) => (
					<MenuItem key={index} className="items">
						<div className="left-side">
							{renderAvatar(user)}
							<div className="info">
								<Label fontWeight="600">{`${get(user, "first_name", "")} ${get(
									user,
									"last_name",
									""
								)}`}</Label>
								<Label>{get(user, functionKey, "")}</Label>
							</div>
						</div>
						<button
							className="actions"
							onClick={e => {
								e.stopPropagation();
								e.preventDefault();
								handleSelect(user);
							}}
						>
							{isSelected(user)}
						</button>
					</MenuItem>
				))}
		</WrapContent>
	);
}

export default PanelItems;
