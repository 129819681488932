import styled from "styled-components";

export const SelectContainer = styled.div`
	width: auto;
`;

export const ProfileMenu = styled.div`
	filter: ${({ isLoading }) => isLoading && "blur(1px)"};
	cursor: ${({ isLoading }) => isLoading && "progress"};
	padding-top: 1px;
	background-color: white;
	width: 420px;
	border-radius: 10px;
	box-shadow: 0 0 20px 0px lightgrey;
	opacity: ${({ showMenu }) => (showMenu ? "1" : "0")};
	visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
	transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

	.title {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: #181819;
		margin: 15px 16px 16px 16px;
	}
	.text-field {
		display: flex;
		align-items: center;
		position: relative;
		margin: 0 16px 16px 16px;
		border: 1px solid lightgray;
		padding: 3px 3px 3px 12px;
		border-radius: 4px;
		height: 36px;
		> input {
			outline: none;
			width: 100%;
			height: 100%;
			border: none;
		}
		> svg {
			position: absolute;
			right: 10px;
			cursor: pointer;
		}
	}
	.menu {
		padding: 0;
	}
	.empty-state {
		display: flex;
		align-items: center;
		padding: 20px;
		border-top: 1px solid lightgrey;
	}
	.loader {
		width: 100%;
		height: 100%;
		display: flex;
		position: absolute;
		justify-content: center;
		align-items: center;
		z-index: 9;
	}
`;

export const MenuItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 13px 17px;
	border-bottom: 1px solid lightgray;
	&.items {
		padding-left: 38px;
		border-bottom: none;
		.left-side {
			> img {
				height: 32px;
				width: 32px;
				border-radius: 50%;
			}
			.info {
				margin: 0 10px;
			}
		}
	}
	.left-side {
		display: flex;
		align-items: center;
		> img {
			height: 40px;
			width: 40px;
			border-radius: 50%;
		}
		.info {
			display: flex;
			flex-direction: column;
			margin: 0 20px;
		}
	}
	.actions {
		border: none;
		outline: none;
		background: inherit;
		width: max-content;
		height: max-content;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
	}
`;

export const Label = styled.span`
	font-family: "basiercircle";
	font-style: normal;
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "normal")};
	font-size: 14px;
	line-height: 20px;
	color: ${({ color }) => (color ? color : "#202223")};
	margin: ${({ margin }) => margin && margin};
`;
export const OpenerContainer = styled.div`
	cursor: pointer;
`;
export const CoumpoundSelectContainer = styled.div`
	position: relative;
`;
export const WrapContent = styled.div`
	border-bottom: 1px solid #f2f2f2;
	&:last-child {
		border-bottom: none;
	}
	.panel_collapse {
		display: flex;
		justify-content: space-between;
		padding: 8px 16px;
		.left_side {
			display: flex;
			align-items: center;
			> img {
				display: block;
				width: 32px;
				height: 32px;
				border-radius: 32px;
				margin-right: 10px;
			}
			.info {
				font-family: "basiercircle";
				font-size: 14px;
				line-height: 16px;
				color: #202223;
			}
		}
		button {
			background: none;
			transition: "transform 0.4s ease";
			transform: rotate(-90deg);
			&.opened {
				transform: rotate(0deg);
			}
		}
	}
`;
