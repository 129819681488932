import React, { useEffect } from "react";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

function MyProfileBreadcumb({
	parentItem = "",
	pathParentItem = "",
	currentItem = "",
	pathCurrentItem = ""
}) {
	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: parentItem, path: pathParentItem },
			{ name: currentItem, path: pathCurrentItem }
		]);
	}, []);

	return <div></div>;
}

export default MyProfileBreadcumb;
