import React from "react";
import { Flex, Text } from "rebass";
import Spinner from "common/Spinner";
import styles from "./consultants-loading.module.scss";

const ConsultantsLoading = () => {
	return (
		<div className={styles.container}>
			<Flex
				flexDirection={"column"}
				sx={{ gap: 3 }}
				justifyContent={"center"}
				alignItems={"center"}
				height={"40rem"}
			>
				<Spinner width={32} height={32} color={"#ff6849"} duration={0.5} />
				<Text>Loading, please wait...</Text>
			</Flex>
		</div>
	);
};

export default ConsultantsLoading;
