import styled from "styled-components";

export const AddMailModalContainer = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	position: fixed;
	width: 612px;
	height: auto;
	top: 10%;
	right: 30%;
	z-index: 9999;

	.modal-interview-header {
		display: flex;
		justify-content: space-between;
		padding: 20px;
		.title {
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 28px;
			color: #202223;
		}
		.close-modal {
			background: transparent;
			border: none;
		}
	}
	.modal-interview-body {
		position: relative;
		padding: 20px;
		border-top: 1px solid #e1e3e5;
		border-bottom: 1px solid #e1e3e5;
		.form-group {
			width: 100%;
		}
		.modal-body-text {
			margin-bottom: 9px;
			> span {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				color: #202223;
				display: block;
			}
		}
		.add-mail-input {
			> span {
				&.error {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 14px;
					color: #df006b;
					display: block;
					margin-top: 8px;
				}
				&.merge-profile-text {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 14px;
					color: #916a00;
					display: block;
					margin-top: 8px;
				}
				&.already-shorltlist-profile-text {
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 14px;
					color: #916a00;
					display: block;
					margin-top: 8px;
					.view-profile {
						cursor: pointer;
						margin-left: 8px;
						color: #202223;
						text-decoration: underline;
					}
				}
			}
			> input {
				padding: 3px 3px 3px 12px;
				background: #ffffff;
				border-radius: 4px;
				width: 70%;
				&.error {
					border: 1px solid #df006b;
				}
				::placeholder {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 20px;
					color: #6d7175;
				}
			}
		}
	}
	.modal-interview-footer {
		display: flex;
		justify-content: flex-end;
		padding: 20px;
		.send-interview-button {
			padding: 8px 16px;
			background: linear-gradient(180deg, #f01c29 0%, #ff6849 100%);
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
				inset 0px -1px 0px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			border: none;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #ffffff;
			:disabled {
				background: #babec3;
				cursor: not-allowed;
			}
		}
		.log-interview-button {
			margin-right: 8px;
			border: 1px solid #e1e3e5;
			background: #ffffff;
			padding: 8px 16px;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
			border-radius: 4px;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #202223;
		}
	}
	.wrapper {
		position: relative;
		background: rgba(255, 255, 255, 0.6);
		.loading-state {
			position: absolute;
			top: 50%;
			right: 50%;
		}
	}
`;

export const Backdrop = styled.div`
	background-color: transparent;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: ${props => props["z-index"] || 9999};
	overflow: auto;
	display: flex;
	align-items: flex-start;
`;
