import React from "react";
import Modal from "common/modal";
import { Button } from "common/styled/buttons";
import welcomeFreelancerImg from "static/images/welcome-illustration-freelancer.svg";

function IntroProfileModal({ closeModal, changeTab }) {
	const onProceed = () => {
		closeModal();
		changeTab(1);
	};

	return (
		<Modal className="intro-modal" isOnProceed={true}>
			<div className="content">
				<div
					className="row"
					style={{ textAlign: "center", justifyContent: "center" }}
				>
					<div className="col-md-12">
						<img src={welcomeFreelancerImg} width="250" height="200px" />
						<h3 className="title-intro">Welcome to wiggli!</h3>
						<p style={{ fontSize: "13px", fontWeight: "400", width: "555px" }}>
							This is your profile. It includes your CV, but also a set of data
							used to find you missions that best match your skills &
							expectations. Please take a minute to fill those sections now.
						</p>
						<Button
							className="btn btn-primary"
							style={{ margin: "20px 0px", padding: "15px 70px" }}
							onClick={onProceed}
							size="14"
						>
							Proceed to my profile
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
export default IntroProfileModal;
