import React from "react";
import styles from "./radio-group.module.scss";
import cx from "classnames";

const RadioGroup = ({
	options,
	direction = "row",
	value: valueProp,
	name,
	onChange,
	className: classNameProp
}) => {
	const className =
		direction === "row" ? styles.containerRow : styles.containerColumn;

	return (
		<div className={cx(styles.container, className, classNameProp)}>
			{options.map(({ value, label, customComponent, className }) => {
				return (
					<div key={value} className={styles.itemWrapper}>
						<label className={className}>
							<input
								onChange={onChange}
								name={name}
								checked={value === valueProp}
								type="radio"
								value={value}
							/>
							<span>{label}</span>
						</label>
						{value === valueProp && customComponent}
					</div>
				);
			})}
		</div>
	);
};

export default RadioGroup;
