import React from "react";
import * as yup from "yup";
import { ReactComponent as InfoIconRed } from "static/icons/info-icon-red.svg";

const errorMessage = field => {
	return (
		<span>
			<InfoIconRed /> Please choose a {field}
		</span>
	);
};

export const getSchema = isSiteEnbaled =>
	yup.object().shape({
		mission_title: yup.string().required(errorMessage("mission title")),
		departement: yup.object().required(errorMessage("mission departement")),
		site: isSiteEnbaled && yup.object().required(errorMessage("mission site")),
		freelancer: yup
			.object()
			.nullable(true)
			.test("isRequired", errorMessage("freelancer"), function(value) {
				const { type } = this.parent;
				if (type === "freelancer") {
					return !!value?.value;
				}
				return true;
			}),
		supplier: yup
			.object()
			.nullable(true)
			.test("isRequired", errorMessage("supplier"), function(value) {
				const { type } = this.parent;

				if (type !== "freelancer") {
					return !!value?.value;
				}
				return true;
			}),
		consultant: yup
			.object()
			.nullable(true)
			.test("isRequired", errorMessage("consultant"), function(value) {
				const { type } = this.parent;
				if (type !== "freelancer") {
					return !!value?.value;
				}
				return true;
			}),
		start_date: yup.object().required(errorMessage("start date")),
		end_date: yup
			.object()
			.required(errorMessage("end date"))
			.test("MinValue", "Please provide a valid end date", function(end_date) {
				const { start_date } = this.parent;
				if (end_date < start_date) return false;
				return true;
			}),
		rate: yup.string().required(errorMessage("rate")),
		currency: yup
			.object()
			.test("Required", "Please select a currency.", function(value) {
				return !!value?.value;
			}),
		showSupplierMode: yup.boolean(),
		company_name: yup.string().when("showSupplierMode", {
			is: true,
			then: yup.string().required("Company name is required")
		}),
		po_number: yup.string(),
		company_timesheet_manager_email: yup
			.string()
			.email("Invalid email")
			.when("showSupplierMode", {
				is: true,
				then: yup.string().required("Timesheet Manager Email is required")
			}),
		accounting_manager_email: yup
			.string()
			.email("Invalid email")
			.when("showSupplierMode", {
				is: true,
				then: yup.string().required("Accounting Manager Email is required")
			}),
		selling_price: yup.number().when("showSupplierMode", {
			is: true,
			then: yup
				.number()
				.min(1, "Selling price must be different from 0")
				.typeError("You must enter a number")
		})
	});

export const FREELANCER_TYPES = [
	{ value: "freelancer", label: "Freelancer" },
	{ value: "supplier", label: "Supplier" }
];

export const TIMESHEET_FREQUENCY_TYPES = [
	{ value: "weekly", label: "Weekly" },
	{ value: "monthly", label: "Monthly" }
];
export const RATE_TYPE = [
	{ value: "daily", label: "Daily" },
	{ value: "hourly", label: "Hourly" }
];
