import React, { useState, useRef, useMemo, useEffect } from "react";
import { queryCache } from "react-query";
import cx from "classnames";
import _find from "lodash/find";
import useOnClickOutside from "hooks/useOnClickOutside";
import styles from "./layout-menu.module.scss";
import { ReactComponent as VisibleIcon } from "static/icons/visible-icon.svg";
import { ReactComponent as InvisibleIcon } from "static/icons/invisble-icon.svg";
import { ReactComponent as QuiteIcon } from "static/icons/quite-icon.svg";
import useOverviewStore from "../../Store/useOverviewStore";
import useUpdateUserSettings from "../../api/useUpdateUserSetting";
import toaster from "common/Toaster";

const COLUMNS = [0, 1];

const BLOCKS = [
	{
		id: 0,
		name: "cv",
		label: "Candidate's resume"
	},
	{
		id: 1,
		name: "preferences",
		label: "Preferences"
	},
	{
		id: 2,
		name: "functions",
		label: "Functions"
	},
	{
		id: 3,
		name: "skills",
		label: "Skills"
	},
	{
		id: 4,
		name: "languages",
		label: "Languages"
	},
	{
		id: 5,
		name: "active_process",
		label: "Active processes"
	},
	{
		id: 6,
		name: "profile_tags",
		label: "Profile tags"
	},
	{
		id: 7,
		name: "all_processes",
		label: "All processes"
	},
	{
		id: 8,
		name: "interactions",
		label: "Interactions"
	},
	{
		id: 9,
		name: "notes",
		label: "Notes"
	}
];

const LayoutMenu = ({ setShow }) => {
	const [blocks, setBlocks] = useState([]);

	const { cards, setValue } = useOverviewStore();
	const modalRef = useRef(null);

	useEffect(() => {
		setBlocks(cards.flat());
	}, []);

	useOnClickOutside(modalRef, () => setShow(false));

	const [mutate] = useUpdateUserSettings({
		onError: () => {
			toaster.danger("Error while saving changes.");
			queryCache.invalidateQueries("@permanent-profile/cards");
		}
	});

	const handleClose = e => {
		e.stopPropagation();
		setShow(false);
	};

	const onToggle = block => {
		const newData = [...cards];
		const newCardsData = blocks.map(item =>
			item.name === block.name ? { ...item, is_hidden: !item.is_hidden } : item
		);
		setBlocks(newCardsData);
		const blockPosition = COLUMNS.reduce(
			(acc, item) => {
				if (newData[item].findIndex(item => item.name === block.name) !== -1) {
					acc.columnIndex = item;
					acc.itemIndex = cards[item].findIndex(
						item => item.name === block.name
					);
					return acc;
				}
				return acc;
			},
			{ columnIndex: null, itemIndex: null }
		);

		newData[blockPosition.columnIndex][
			blockPosition.itemIndex
		].is_hidden = !newData[blockPosition.columnIndex][blockPosition.itemIndex]
			.is_hidden;

		newData[blockPosition.columnIndex].push(
			newData[blockPosition.columnIndex].splice(blockPosition.itemIndex, 1)[0]
		);

		setValue("cards", newData);
		mutate({ cards: newData, type: "view_candidate_cards" });
	};

	const onMakeAllItemsVisible = () => {
		const newData = [...cards];
		COLUMNS.map(column =>
			newData[column].map(item => (item.is_hidden = false))
		);
		setBlocks(old => old.map(item => ({ ...item, is_hidden: false })));
		setValue("cards", newData);
		mutate({ cards: newData, type: "view_candidate_cards" });
	};

	const isAllItemsVisible = useMemo(
		() => cards.flat().every(item => !item.is_hidden),
		[cards]
	);

	const renderBlock = block => {
		const liveBlock = _find(blocks, ["name", block?.name]) ?? {};
		return (
			<li key={block.id} onClick={() => onToggle(liveBlock)}>
				{liveBlock.is_hidden ? <InvisibleIcon /> : <VisibleIcon />}
				<span> {block.label} </span>
			</li>
		);
	};

	return (
		<div ref={modalRef} className={styles.root}>
			<div className={styles.header}>
				<h6>Layout customization</h6>
				<button className={styles.closeButton} onClick={handleClose}>
					<QuiteIcon />
				</button>
			</div>
			<div
				className={cx(styles.toggleAll, {
					[styles.istoggleAllDisabled]: isAllItemsVisible
				})}
				disabled={isAllItemsVisible}
				onClick={onMakeAllItemsVisible}
			>
				{isAllItemsVisible ? <InvisibleIcon /> : <VisibleIcon />}
				<span>Make All items visible</span>
			</div>
			<ul>{BLOCKS.map(renderBlock)}</ul>
		</div>
	);
};
export default LayoutMenu;
