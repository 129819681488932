import React from "react";
import { ReactComponent as BinIcon } from "static/icons/bin.svg";

const DeleteButton = ({ deleteClicked, className }) => {
	return (
		<div className={className}>
			<button
				onClick={e => {
					e.stopPropagation();
					deleteClicked();
				}}
			>
				<BinIcon />
			</button>
		</div>
	);
};

export default DeleteButton;
