import React from "react";

import { Field, reduxForm } from "redux-form";
import StyledSelect from "common/StyledSelect";

const FilterJobByStatus = ({ value, options, onFilterChange }) => {
	return (
		<Field
			name="job_status"
			options={options}
			simpleValue={true}
			position={false}
			placeholder={"Filter By Status"}
			component={StyledSelect}
			valueToSet={value}
			onChange={onFilterChange}
		/>
	);
};

export default reduxForm({
	form: "FilterJob-ByStatus",
	touchOnBlur: false
})(FilterJobByStatus);
