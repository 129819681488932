import Modal from "common/modal";
import React from "react";
import companiesStyles from "../companies.module.scss";
import styles from "./modal.module.scss";
import { ReactComponent as IconAlertDanger } from "static/icons/IconAlertDanger.svg";
import { ReactComponent as IconClose } from "static/icons/IconClose.svg";
import FormField from "common/FormField";

export default function ConfirmActionModal({
	onClose,
	children,
	isLoading,
	onConfirm,
	formIsValid,
	title = "Confirm action",
	confirmLabel = "Confirm"
}) {
	return (
		<Modal
			size={"500px"}
			padding={"24px"}
			onClose={onClose}
			loading={isLoading}
			borderRadius={"12px"}
			border={"1px solid #CBD5E1"}
			backgroundColor={"rgba(0,0,0,0.25)"}
			boxShadow={
				"0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
			}
			customCloseBtnRender={onClose => (
				<div className={styles.confirm_modal__close} onClick={onClose}>
					<IconClose />
				</div>
			)}
		>
			<div className={styles.confirm_modal}>
				<h3>{title}</h3>
				{children}
				<div className={styles.confirm_modal__footer}>
					<button
						onClick={onClose}
						className={`${companiesStyles.btn}  ${companiesStyles.btn__link}`}
					>
						Cancel
					</button>
					<button
						onClick={onConfirm}
						className={`${companiesStyles.btn} ${companiesStyles.btn__danger_bordered}`}
						disabled={!formIsValid}
					>
						{confirmLabel}
					</button>
				</div>
			</div>
		</Modal>
	);
}

export function ConfirmModalBody({
	confirmMessage,
	checkbox = false, // format {value, setValue, label}
	icon = <IconAlertDanger />
}) {
	return (
		<div className={styles.confirm_modal__body}>
			<div className={styles.confirm_modal__message}>
				{icon} {confirmMessage}
			</div>
			{checkbox && (
				<FormField
					type="checkbox"
					id="confirm-action"
					checked={checkbox.value}
					placeholder={checkbox.label}
					classes={styles.confirm_modal__containerCheckbox}
					inputContainerClasses={styles.confirm_modal__checkbox}
					onClick={() => {
						checkbox.setValue(!checkbox.value);
					}}
					input={{
						name: "confirm-action",
						onBlur: event => event.preventDefault(),
						onFocus: event => event.preventDefault()
					}}
					meta={{
						touched: false,
						error: false
					}}
				/>
			)}
		</div>
	);
}
