import React from "react";
import cx from "classnames";
import styles from "modules/home/components/Temporary/temporary.module.scss";

export const RenderApplicantsName = ({ name, status }) => {
	return (
		<div className={styles.applicantName}>
			<div>{name}</div>
			<h7 className={cx(styles.companyType, styles[status])}>{status}</h7>
		</div>
	);
};
