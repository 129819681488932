import create from "zustand";

const initialState = {
	bid: null
};

const useSelectedKanbanBid = create(set => ({
	...initialState,
	setBid: value => {
		set({
			bid: value
		});
	}
}));

export default useSelectedKanbanBid;
