import React from "react";
import _get from "lodash/get";
import { ReactComponent as ErrorIcon } from "static/icons/error-field.svg";
import styles from "./../../profile.module.scss";
import cardStyle from "../EditableSection/editable-section.module.scss";

const FirstNameField = ({ errors, isEditMode, register }) => {
	return (
		<div className={styles.personalBox}>
			<div className={styles.personalLabel}>First name</div>
			<div className={cardStyle.info}>
				<input
					{...register("first_name")}
					type="text"
					disabled={!isEditMode}
					placeholder="Claudia"
				/>
				{_get(errors, "first_name.message") && (
					<div className={styles.error}>
						<ErrorIcon />
						{_get(errors, "first_name.message")}
					</div>
				)}
			</div>
		</div>
	);
};
export default FirstNameField;
