import React from "react";
import isEmpty from "lodash/isEmpty";
import s from "../search/search-suppliers.module.scss";
import styles from "../search/filters/IndustryFilter/industry-filter.module.scss";
import { PencilAltIcon } from "@heroicons/react/outline";

function FilterCard({
	name,
	value,
	onEdit,
	onRemove,
	buttonComponent,
	editButtonComponent,
	children
}) {
	return (
		<div className={s.card}>
			<h5 className={s.heading}>{name}</h5>
			{!isEmpty(value) ? (
				<div>
					<div className={s.cardContent}>{children}</div>
					<div className={styles.actions}>
						{editButtonComponent ? (
							editButtonComponent()
						) : (
							<button onClick={onEdit} className={styles.actionButton}>
								<PencilAltIcon width={24} height={24} />
							</button>
						)}
						<button onClick={onRemove} className={styles.removeAction}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			) : (
				<div className={s.emptyCard}>
					{buttonComponent ? (
						buttonComponent()
					) : (
						<button onClick={onEdit}>+ Add {name}</button>
					)}
				</div>
			)}
		</div>
	);
}

export default FilterCard;
