import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_PENDING_APPLICATIONS_ENDPOINT } from "config/api-endpoints";
import { pendingApplicationsListKey } from "modules/home/api/queriesKeys.js";
import { WELCOME_PAGE_PENDING_APPLICATIONS } from "config";

const listPendingApplications = (key, { start_date, end_date }) =>
	client(LIST_PENDING_APPLICATIONS_ENDPOINT, {
		body: {
			start_date,
			end_date,
			tag: WELCOME_PAGE_PENDING_APPLICATIONS
		}
	});

const usePendingAplicationsList = (options, queryOptions = {}) =>
	useQuery([pendingApplicationsListKey, options], listPendingApplications, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
export default usePendingAplicationsList;
