import React from "react";
import Progress from "./Progress";
import Input from "./Input";
import Error from "./Error";
function Uploader({ children }) {
	return <React.Fragment>{children}</React.Fragment>;
}

Uploader.Progress = Progress;
Uploader.Input = Input;
Uploader.Error = Error;

export { Uploader };
