import React from "react";
import styles from "./Checkbox.module.scss";
import cx from "classnames";

const Checkbox = ({
	id,
	checked,
	onChange,
	className,
	width = "1.125rem",
	height = "1.125rem"
}) => {
	return (
		<div className={cx(styles.cntr, className)}>
			<label htmlFor="cbx" className={styles.labelCbx}>
				<input
					id={id}
					type="checkbox"
					defaultChecked={checked}
					className={styles.invisible}
				/>
				<div onClick={onChange}>
					{checked ? (
						<svg
							width={width}
							height={height}
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className={styles.unchecked}
						>
							<rect x="1" y="1" width="14" height="14" rx="3" fill="#00857A" />
							<path
								d="M12.207 4.79312C12.3945 4.98065 12.4998 5.23496 12.4998 5.50012C12.4998 5.76529 12.3945 6.01959 12.207 6.20712L7.20704 11.2071C7.01951 11.3946 6.76521 11.4999 6.50004 11.4999C6.23488 11.4999 5.98057 11.3946 5.79304 11.2071L3.79304 9.20712C3.61088 9.01852 3.51009 8.76592 3.51237 8.50372C3.51465 8.24152 3.61981 7.99071 3.80522 7.8053C3.99063 7.6199 4.24144 7.51473 4.50364 7.51245C4.76584 7.51017 5.01844 7.61096 5.20704 7.79312L6.50004 9.08612L10.793 4.79312C10.9806 4.60565 11.2349 4.50034 11.5 4.50034C11.7652 4.50034 12.0195 4.60565 12.207 4.79312Z"
								fill="white"
							/>
							<rect
								x="1"
								y="1"
								width="14"
								height="14"
								rx="3"
								stroke="#00857A"
								strokeWidth="2"
							/>
						</svg>
					) : (
						<svg
							width={width}
							height={height}
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className={styles.checkbox}
						>
							<rect
								x="0.75"
								y="0.75"
								width="14.5"
								height="14.5"
								rx="2.25"
								fill="white"
							/>
							<rect
								x="0.75"
								y="0.75"
								width="14.5"
								height="14.5"
								rx="2.25"
								stroke="currentStroke"
								strokeWidth="1.5"
							/>
						</svg>
					)}
					{/* <polyline points="4 11 8 15 16 6"></polyline> */}
				</div>
			</label>
		</div>
	);
};
export default Checkbox;
