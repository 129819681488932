import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { VIEW_CONTACT_INFO_API } from "config/api-endpoints";

function viewContactInfo(body) {
	return client(VIEW_CONTACT_INFO_API, {
		body
	});
}

function useViewContactInfo(options) {
	return useMutation(viewContactInfo, options);
}

export default useViewContactInfo;
