export const RESET_FILTER_VACANCY = "RESET_FILTER_VACANCY";
export const UPDATE_FILTER_VACANCY = "UPDATE_FILTER_VACANCY";
export const FETCHED_JOBS_VACANCY = "FETCHED_JOBS_VACANCY";
export const UPDATE_SKILLS_VACANCY = "UPDATE_SKILLS_VACANCY";
export const UPDATE_LANGUAGES_VACANCY = "UPDATE_LANGUAGES_VACANCY";
export const REST_LANGUAGES_VACANCY = "REST_LANGUAGES_VACANCY";
export const REST_SKILLS_VACANCY = "REST_SKILLS_VACANCY";
export const SET_SEARCH_RESET_VACANCY = "SET_SEARCH_RESET_VACANCY";
// sectors
export const SET_SEARCH_SECTORS_VACANCY = "GET_SEARCH_SECTORS_VACANCY";
export const SET_SEARCH_SUBSECTORS_VACANCY = "SET_SEARCH_SUBSECTORS_VACANCY";
export const SET_SEARCH_FUNCTIONS_VACANCY = "SET_SEARCH_FUNCTIONS_VACANCY";
export const FETCH_POKE_DATA = "FETCH_POKE_DATA";
export const TOGGLE_NOTES_MODAL = "TOGGLE_NOTES_MODAL";

export const TOGGLE_PROPOSE_INTERVIEW_MODAL =
	"@vacancy/TOGGLE_PROPOSE_INTERVIEW_MODAL";
export const FETCH_POKE_SUCCEEDED = "@vacancy/FETCH_POKE_SUCCEEDED";
export const TOGGLE_INTERVIEW_MODAL = "@vacancy/TOGGLE_INTERVIEW_MODAL";
export const TOGGLE_REJECT_MODAL = "@vacancy/TOGGLE_REJECT_MODAL";
export const SHOW_VIDEO_CONF = "@vacancy/SHOW_VIDEO_CONF";
export const HIDE_VIDEO_CONF = "@vacancy/HIDE_VIDEO_CONF";
export const TOGGLE_CANCEL_INTERVIEW_MODAL =
	"@vacancy/TOGGLE_CANCEL_INTERVIEW_MODAL";
export const CLEAN_STATE = "CLEAN_STATE";
export const GET_POKES_LIST = "GET_POKES_LIST";
export const SET_POKES_LIST = "SET_POKES_LIST";
