import React, { Component } from "react";
import Identity from "./Identity";
import Skipper from "./Skipper";
import { skippedIdentityConfirmed } from "../actions/identityActions";
import { companySteps } from "./index";
import {
	Span,
	Container,
	Wrapper,
	Divider,
	Line,
	Or
} from "./cvchooser/CVChooserStyled";
import { CLIENT, CONSULTANCY } from "config";
import { OutlineButton, Button } from "common/styled/buttons";
import { getCVChooserRoute } from "./cvchooser/CVChooserContainer";
import { getAccountActivatedRoute } from "./AccountActivated";
import WithUser from "common/WithUser";

export function getIdentityChooserRoute() {
	return "/company/identity";
}

class IdentityChooser extends Component {
	state = {
		selectedStep: null
	};

	pickStep = selectedStep => {
		this.setState({ selectedStep });
	};

	componentDidMount() {
		const { user } = this.props;
		if (user && user.company_type === CONSULTANCY) {
			this.pickStep("upload_id");
		}
	}

	skippedIdentityConfirmed = () => {
		const { user } = this.props;
		const newRoute =
			user.company_type !== CLIENT
				? getCVChooserRoute()
				: getAccountActivatedRoute();
		skippedIdentityConfirmed(newRoute);
	};

	render() {
		const { selectedStep } = this.state;
		const { user } = this.props;
		if (selectedStep === "upload_id") {
			const goBack =
				user.company_type === CONSULTANCY ? undefined : this.pickStep;
			return (
				<Identity
					userType={user.company_type}
					steps={companySteps}
					goBack={goBack}
				/>
			);
		}

		if (selectedStep === "skip_id") {
			return (
				<Skipper
					userType={user.company_type}
					goBack={this.pickStep}
					onConfirm={this.skippedIdentityConfirmed}
				/>
			);
		}

		return (
			<div className="identity">
				<div className="container">
					<h3 className="text-center h3-title">Personal Identity</h3>
				</div>
				<div className="step-description">
					Please upload a front & back copy of your ID card or a copy of
					passport. <br />
					This step is necessary to prevent identity theft and (if applicable)
					to make sure that you are legally authorized to represent the legal
					entity identified through the VAT number initially provided.
				</div>
				<Wrapper>
					<Container>
						<Span className="icon-uploadcv">
							<span className="path1" />
							<span className="path2" />
							<span className="path3" />
							<span className="path4" />
							<span className="path5" />
							<span className="path6" />
							<span className="path7" />
							<span className="path8" />
							<span className="path9" />
							<span className="path10" />
						</Span>
						<Button
							small
							bordered
							medium
							id="upload-id"
							onClick={() => this.pickStep("upload_id")}
						>
							UPLOAD ID
						</Button>
					</Container>
					<Divider>
						<Line />
						<Or>Or</Or>
						<Line />
					</Divider>
					<Container>
						<Span className="icon-skip" />
						<OutlineButton
							small
							medium
							bordered
							id="skip-step"
							onClick={() => this.pickStep("skip_id")}
						>
							SKIP THIS STEP
						</OutlineButton>
					</Container>
				</Wrapper>
			</div>
		);
	}
}

export default WithUser(IdentityChooser);
