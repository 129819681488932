import React, { Component } from "react";
import PropTypes from "prop-types";
import { createMarkup, removeMarkdown } from "../config/helpers";

class Description extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: props.expanded || false
		};
		this.changeExpanded = this.changeExpanded.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.expanded && prevProps.expanded !== this.props.expanded)
			this.setState({ expanded: this.props.expanded });
	}

	changeExpanded(e, expanded = true) {
		e.preventDefault();
		this.setState({ expanded });
	}

	renderOptions(expanded) {
		const {
			showMoreText,
			showLessText,
			showMore,
			viewMoreClassName
		} = this.props;
		if (!showMore) return;
		return expanded ? (
			<button
				className={viewMoreClassName || "desc--action show-less"}
				onClick={e => this.changeExpanded(e, false)}
			>
				{showLessText}
			</button>
		) : (
			showMoreText && (
				<button
					className={viewMoreClassName || "desc--action show-more"}
					onClick={this.changeExpanded}
				>
					{showMoreText}
				</button>
			)
		);
	}

	render() {
		let {
			description = "",
			max,
			parseMarkdown,
			className,
			classNameContent
		} = this.props;
		const { expanded } = this.state;
		let showOption = description.length > max;
		if (description && description.length > max && !expanded) {
			description = `${description.substring(0, max)}...`;
		}
		return (
			<div className={className ? `${className} description` : "description"}>
				{parseMarkdown ? (
					<div
						className={classNameContent || "description-content"}
						dangerouslySetInnerHTML={createMarkup(description)}
					/>
				) : (
					<div className={classNameContent || "description-content"}>
						{removeMarkdown(description)}
					</div>
				)}
				{showOption && this.renderOptions(expanded)}
			</div>
		);
	}
}

Description.propTypes = {
	description: PropTypes.string.isRequired,
	max: PropTypes.number.isRequired,
	showMoreText: PropTypes.string,
	showLessText: PropTypes.string,
	parseMarkdown: PropTypes.bool,
	showMore: PropTypes.bool,
	classNameContent: PropTypes.string,
	viewMoreClassName: PropTypes.string
};

Description.defaultProps = {
	showMoreText: "Show More",
	showLessText: "Show Less",
	parseMarkdown: true,
	showMore: true
};

export default Description;
