import React, { forwardRef } from "react";
import styles from "./paper.module.scss";
import cx from "classnames";

const Paper = (
	{ children, className, addPadding = false, ...propsRest },
	ref
) => {
	return (
		<div
			className={cx(
				styles.paper,
				cx(className, { [styles.padding]: addPadding })
			)}
			ref={ref}
			{...propsRest}
		>
			{children}
		</div>
	);
};

export default forwardRef(Paper);
