import React, { Component } from "react";
import { connect } from "react-redux";
import { cancelBidSelector } from "../../selectors";
import { cancelBid } from "../../actions";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import PropTypes from "prop-types";
import { CONTRACT, INTERVIEW } from "config";

class CancelBidModal extends Component {
	cancelBid = () => {
		const {
			onClose,
			cancelBidAction,
			stepperStatus,
			bidId,
			interviewId,
			contractId
		} = this.props;
		const _id =
			stepperStatus === CONTRACT
				? contractId
				: stepperStatus === INTERVIEW
				? interviewId
				: bidId;
		const payload = {
			bid_id: bidId, // need it for reloading data
			stepper_status: stepperStatus,
			id: _id
		};
		cancelBidAction(payload).then(() => onClose());
	};

	render() {
		const {
			bid: { inAction },
			onClose,
			active
		} = this.props;

		return (
			<ConfirmationModal
				active={active}
				modalName="cancel_bid_modal"
				loading={inAction}
				type={ConfirmationTypes.error}
				firstButton={{
					label: "Yes, cancel this application",
					type: "warning",
					action: this.cancelBid
				}}
				onClose={onClose}
				title="Are you sure you want to cancel this application?"
				content="This action is definitive and irreversible,
				and you will no longer be able to interact with this client/job once the operation has been performed."
			/>
		);
	}
}

const mapStateToProps = state => ({
	bid: cancelBidSelector(state)
});

const mapDispatchToProps = dispatch => ({
	cancelBidAction: payload => dispatch(cancelBid(payload))
});

CancelBidModal.propTypes = {
	interviewId: PropTypes.string,
	contractId: PropTypes.string,
	bidId: PropTypes.string,
	stepperStatus: PropTypes.string,
	cancelBidAction: PropTypes.func,
	onClose: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelBidModal);
