import React, { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { UPLOAD_CANDIDATE_FILES } from "config";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";

// import { ReactComponent as ConfigIcon } from "static/icons/config.svg";
import { ReactComponent as DownloadIcon } from "static/icons/cloud-arrow-down.svg";

import cvPlaceholder from "static/icons/iconPdf.svg";
import styles from "./../../profile.module.scss";
import useFilterSettings from "modules/candidate/Profile/api/useFilterSetting";

const CvSection = ({
	setMenuAction,
	// menuAction,
	id,
	viewFilesClicked,
	primaryCv
}) => {
	const menuActionRef = useRef(null);
	const { data: fileTypes } = useFilterSettings(null, {
		tag: "list_files_type"
	});

	useOnClickOutside(menuActionRef, () => {
		setMenuAction(false);
	});

	const { setDrawerState } = useVacancyStore();

	// const handleOpenUploader = () =>
	// 	setDrawerState({
	// 		open: true,
	// 		component: UPLOAD_CANDIDATE_FILES,
	// 		candidateId: id,
	// 		cvDefaultFormat: fileTypes?.files_type[0],
	// 		isFiletypesInputReadonly: true
	// 	});

	const handleReplacePrimaryItemClick = () => {
		setDrawerState({
			open: true,
			component: UPLOAD_CANDIDATE_FILES,
			candidateId: id,
			cvDefaultFormat: fileTypes?.files_type[0],
			isUploadPrimaryFile: true,
			isFiletypesInputReadonly: true
		});
	};

	return (
		<div className={styles.cvContainer}>
			<div className={styles.flex}>
				<img alt="" src={cvPlaceholder} />
				{primaryCv && <p>{primaryCv.file_name}</p>}
				{primaryCv && (
					<a href={primaryCv.path} className={styles.downloadLink}>
						<DownloadIcon />
					</a>
				)}
				{/* <button
					className={styles.iconBtn}
					onClick={e => {
						e.preventDefault();
						setMenuAction(true);
					}}
				> */}
				{/* <ConfigIcon /> */}
				{/* {menuAction && (
						<div className={styles.cvActions} ref={menuActionRef}>
							<ul>
								<li onClick={handleReplacePrimaryItemClick}>
									<button>Replace primary</button>
								</li>
								<li>
									<button onClick={handleOpenUploader}>Upload new cv</button>
								</li>
								<li>
									<button onClick={() => viewFilesClicked()}>
										View files list
									</button>
								</li>
							</ul>
						</div>
					)}
				</button> */}
				<button
					className={styles.btnCv}
					onClick={handleReplacePrimaryItemClick}
				>
					Upload a new primary CV{" "}
				</button>
				<span>or go to</span>
				<button className={styles.btnCv} onClick={() => viewFilesClicked()}>
					files
				</button>
				<span>
					if you wish to select one from the existing files linked to this
					profile
				</span>
			</div>
		</div>
	);
};
export default CvSection;
