import {
	LIVE,
	DONE,
	ADMIN,
	REFUSED,
	REJECTED,
	DECLINED,
	ACCEPTED,
	CANCELLED,
	SUPER_ADMIN
} from "config";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import { useMemo } from "react";

export const CARD_ACTION_NOTE = "NOTE";
export const CARD_ACTION_PROPOSE_CONTRACT = "PROPOSE_CONTRACT";
export const CARD_ACTION_REJECT_APPLICATION = "REJECT_APPLICATION";
export const CARD_ACTION_PROSPOSE_INTERVIEW = "PROSPOSE_INTERVIEW";

export default function useTempKanbanCardRules() {
	const user = useSelector(state => state.auth.user);
	const job = useSelector(state => state.job.data);

	const canProposeContract = useMemo(() => {
		const groups = _get(user, "groups", []);
		return (
			!user.is_member ||
			(user?.is_member &&
				(user?.role_name === SUPER_ADMIN || user?.role_name === ADMIN)) ||
			groups.some(
				group =>
					group.id === _get(job, "group._id") && group.role_name === ADMIN
			)
		);
	}, [job, user]);

	// todo need to refactor (forked from old code)
	const getCardActions = ({ bidData }) => {
		const userRole = user.role_name;
		const companyId = user.active_company || "";

		const bidStatus = bidData.status;
		const contract = !_isArray(_get(bidData, "contract"))
			? _get(bidData, "contract")
			: null;
		const interview = !_isArray(_get(bidData, "interview"))
			? _get(bidData, "interview")
			: null;

		const interview_id = interview?._id;
		const interview_status = interview?.status;
		const new_interview_end_date = interview?.new_interview_end_date;

		const contract_id = contract?._id;
		const contract_status = contract?.status;
		const contract_last_id = contract?.last_action_company_id;
		// const signed_status = contract?.signed_status;

		// bid status
		const isBidLive = bidStatus === LIVE;
		const isBidCancelled = bidStatus === CANCELLED || bidStatus === REJECTED;
		const processEnded =
			contract_status === CANCELLED ||
			contract_status === REFUSED ||
			interview_status === REFUSED ||
			interview_status === CANCELLED ||
			isBidCancelled;

		// contract status
		const contractDeclined = contract_status === DECLINED;
		const contractAccepted = contract_status === ACCEPTED;
		// const contractIsPendingSignature = signed_status === PENDING_SIGNATURE;
		const isContractLastId = contract_last_id && companyId === contract_last_id;

		// interview status
		const isInterviewDone = interview_status === DONE;
		const interviewCancelled = interview_status === CANCELLED;
		const hasInterviewExpired =
			new_interview_end_date &&
			window
				.moment()
				.isAfter(window.moment.unix(new_interview_end_date), "day");

		const canMakeProposal = _get(bidData, "can_make_proposal", undefined);
		const showTooltipContract =
			!canProposeContract ||
			canMakeProposal === false ||
			(userRole !== ADMIN && userRole !== SUPER_ADMIN);

		let cardActionsList = [];
		let contractTooltipText = "";
		if (processEnded) return { cardActionsList, contractTooltipText };

		if (!isBidCancelled) {
			// *** propose interview rules :
			if (!contract_id && !interview_id) {
				cardActionsList.push(CARD_ACTION_PROSPOSE_INTERVIEW);
			}
			if (!contract_id && (isInterviewDone || hasInterviewExpired)) {
				cardActionsList.push(CARD_ACTION_PROSPOSE_INTERVIEW);
			}
			if (
				!contract_id &&
				interview_id &&
				!hasInterviewExpired &&
				!isInterviewDone &&
				!interviewCancelled
			) {
				cardActionsList.push(CARD_ACTION_PROSPOSE_INTERVIEW);
			}

			// *** reject application rules :
			if (!contractAccepted && isBidLive) {
				if (contract_id && userRole !== ADMIN)
					return { cardActionsList, contractTooltipText };
				cardActionsList.push(CARD_ACTION_REJECT_APPLICATION);
			}

			// ** propose contract rules :
			if (!isContractLastId && contractDeclined) {
				cardActionsList.push(CARD_ACTION_PROPOSE_CONTRACT);
			}
			if (!contract_id) {
				cardActionsList.push(CARD_ACTION_PROPOSE_CONTRACT);

				if (showTooltipContract && canProposeContract) {
					contractTooltipText =
						"You can only make one contract proposition per request.";
				}
				if (showTooltipContract && !canProposeContract) {
					contractTooltipText =
						"Sorry, your account status doesn’t allow you to make contract propositions.";
				}
			}
		}

		cardActionsList.push(CARD_ACTION_NOTE);

		return { cardActionsList, contractTooltipText };
	};

	return { getCardActions };
}
