import React from "react";
import noJobIcon from "../../static/icons/no-job-icon.png";

const EmptySearch = ({ title, text, className }) => {
	return (
		<div className={`empty-search ${className}`}>
			<img src={noJobIcon} alt="No jobs found" />
			<p className="title">
				{title || "We couldn’t find any jobs that match your search criteria."}
			</p>
			<p className="text"> {text}</p>
		</div>
	);
};

export default EmptySearch;
