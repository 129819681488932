import React, { createContext, useReducer } from "react";
import _get from "lodash/get";
import { SEARCH, TEMPLATE } from "config";
import produce from "immer";

const initialState = {
	filters: {
		functions: [],
		skills: [],
		languages: [],
		location: {},
		offset: 0,
		distance: 75,
		keywords: "",
		activeTemplate: undefined,
		sort_by: {
			publish_date: undefined
		},
		except_applied: undefined,
		except_poked: undefined,
		tag: undefined,
		source: {
			applied: undefined,
			poked: undefined,
			added: undefined
		},
		employment_types: [],
		interviewed: undefined,
		residence_only: undefined,
		remoteEligilibityOptions: [],
		daysInOffice: []
	},
	profiles: {
		total: 0,
		offset: 0,
		size: 0,
		data: []
	},
	jobs: {
		total: 0,
		offset: 0,
		size: 0,
		data: []
	},
	templates: [],
	selectedProfile: "",
	selectedJob: "",
	displayHidePermanentlyModal: false,
	displayHideFromSearchModal: false
};

export const ACTION_TYPES = {
	SET_ACTIVE_TEMPLATE: "SET_ACTIVE_TEMPLATE",
	UPDATE_FILTER: "UPDATE_FILTER",
	DELETE_FILTER: "DELETE_FILTER",
	DELETE_FUNCTION: "DELETE_FUNCTION",
	CLEAR_FILTER: "CLEAR_FILTER",
	FETCH_TEMPLATES: "FETCH_TEMPLATES",
	FETCH_PROFILES: "FETCH_PROFILES",
	FETCH_JOBS: "FETCH_JOBS",
	CLEAR_SEARCH_RESULTS: "CLEAR_SEARCH_RESULTS",
	TOGGLE_HIDE_MODAL: "TOGGLE_HIDE_MODAL",
	SELECT_PROFILE_TO_HIDE: "SELECT_PROFILE_TO_HIDE",
	SELECT_JOB_TO_HIDE: "SELECT_JOB_TO_HIDE",
	CLEAR_STATE: "CLEAR_STATE",
	KEYWORD_SEARCH: "KEYWORD_SEARCH",
	REMOVE_REMOTE_OPTION: "REMOVE_REMOTE_OPTION",
	ADD_REMOTE_OPTION: "ADD_REMOTE_OPTION"
};

function reducer(state, action) {
	switch (action.type) {
		case ACTION_TYPES.SET_ACTIVE_TEMPLATE: {
			return {
				...state,
				filters: {
					...state.filters,
					activeTemplate: action.template
						? {
								...action.template,
								label: _get(action, "template.name"),
								value: _get(action, "template._id")
						  }
						: "",
					functions: _get(
						action,
						"template.functions",
						state.filters.functions
					),
					skills: _get(action, "template.skills", state.filters.skills),
					languages: _get(
						action,
						"template.languages",
						state.filters.languages
					),
					location: _get(action, "template.location", state.filters.location),
					distance: _get(action, "template.distance", state.filters.distance),
					except_applied: _get(action, "template.except_applied"),
					except_poked: _get(action, "template.except_poked"),
					keywords: _get(action, "template.keywords", state.filters.keywords),
					employment_types: _get(
						action,
						"template.employment_types",
						state.filters.employment_types
					),
					remoteEligilibityOptions: _get(
						action,
						"template.remote_eligibility",
						state.filters.remoteEligilibityOptions
					),
					daysInOffice: _get(
						action,
						"template.office_days",
						state.filters.daysInOffice
					),
					offset: 0
				}
			};
		}
		case ACTION_TYPES.UPDATE_FILTER: {
			let url = window.location.pathname;
			let search = window.location.search;
			if (search && search.split("=")[0] === TEMPLATE) {
				window.history.pushState(
					{
						path: url
					},
					"",
					url
				);
			}

			let activeTemplate = undefined;
			if (action.name === "offset") {
				activeTemplate = state.filters.activeTemplate;
			}
			return {
				...state,
				filters: {
					...state.filters,
					activeTemplate,
					offset: 0,
					[action.name]: action.value
				}
			};
		}
		case ACTION_TYPES.DELETE_FILTER: {
			let url = window.location.pathname;
			window.history.pushState(
				{
					path: url
				},
				"",
				url
			);
			return {
				...state,
				filters: {
					...state.filters,
					activeTemplate: undefined,
					[action.name]: state.filters[action.name].filter(
						item => item._id !== action.value
					)
				}
			};
		}
		case ACTION_TYPES.DELETE_FUNCTION: {
			const functionIndex = state.filters.functions.findIndex(
				f =>
					f._id === action.fun.id &&
					f.seniority === action.fun.seniority &&
					f.sector.parent_sector._id === action.fun.parent_sector_id &&
					f.sector._id === action.fun.sector_id
			);

			return {
				...state,
				filters: {
					...state.filters,
					functions: state.filters.functions.filter(
						(_, i) => i !== functionIndex
					)
				}
			};
		}
		case ACTION_TYPES.FETCH_TEMPLATES: {
			return { ...state, templates: action.value };
		}
		case ACTION_TYPES.CLEAR_FILTER: {
			return {
				...state,
				filters: {
					...initialState.filters,
					activeTemplate: undefined
				}
			};
		}
		case ACTION_TYPES.FETCH_PROFILES: {
			return {
				...state,
				profiles: action.value
			};
		}

		case ACTION_TYPES.KEYWORD_SEARCH: {
			return {
				...state,
				filters: {
					...state.filters,
					keywords: action.data
				}
			};
		}

		case ACTION_TYPES.SELECT_PROFILE_TO_HIDE: {
			return {
				...state,
				selectedProfile: action.value
			};
		}
		case ACTION_TYPES.SELECT_JOB_TO_HIDE: {
			return {
				...state,
				selectedJob: action.value
			};
		}
		case ACTION_TYPES.TOGGLE_HIDE_MODAL: {
			let modal =
				action.value === SEARCH
					? "displayHideFromSearchModal"
					: "displayHidePermanentlyModal";

			return { ...state, [modal]: !state[modal] };
		}

		case ACTION_TYPES.CLEAR_SEARCH_RESULTS: {
			return {
				...state,
				profiles: initialState.profiles,
				jobs: initialState.jobs
			};
		}
		case ACTION_TYPES.FETCH_JOBS: {
			return {
				...state,
				jobs: action.value
			};
		}
		case ACTION_TYPES.CLEAR_STATE: {
			return {
				...initialState
			};
		}
		case ACTION_TYPES.ADD_REMOTE_OPTION: {
			const newState = produce(state, draft => {
				draft.filters.remoteEligilibityOptions.push(action.value);
			});

			return newState;
		}
		case ACTION_TYPES.REMOVE_REMOTE_OPTION: {
			const { filters } = state;

			const index = filters.remoteEligilibityOptions.findIndex(
				item => item === action.value
			);

			const newState = produce(state, draft => {
				draft.filters.remoteEligilibityOptions.splice(index, 1);
			});

			return newState;
		}
		default:
			return state;
	}
}

export const SearchContext = createContext({});

export const SearchProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<SearchContext.Provider value={{ state, dispatch }}>
			{children}
		</SearchContext.Provider>
	);
};
