import React from "react";
import styles from "./textarea-field.module.scss";

export default function TextareaField({
	rows = 6,
	type = "text",
	...restProps
}) {
	return (
		<textarea
			rows={rows}
			type={type}
			{...restProps}
			className={styles.textareaField}
		/>
	);
}
