import _get from "lodash/get";
import * as types from "./actionTypes";
import {
	GET_USER_LIST_URL,
	DEPARTMENTS_LIST,
	CHECK_EMAIL_INVITATION,
	INVITE_USER,
	RESEND_INVITATION,
	EDIT_USER_ROLE,
	BLOCK_USER_ENDPOINT,
	UNBLOCK_USER_ENDPOINT,
	CREATE_DEPARTMENT,
	UPDATE_DEPARTMENT,
	POKE_CANDIDATE_ENDPOINT,
	DELETE_INVITATION,
	TRANSFERT_OWNERSHIP_ENDPOINT
} from "config/api-endpoints";
import {
	HTTP_POST_METHOD,
	RESPONSE_OK,
	CREATE_USER,
	INVITE_PREVIEW,
	RESPONSE_ACCEPTED,
	ASSIGN_ROLE,
	BLOCKED,
	SUPER_ADMIN
} from "config";
import axios from "config/axios";
import toaster from "common/Toaster";
import AxiosHelper from "config/axios-helper";
import { getMyProfile } from "modules/user/actions/authActions";
import { getBusinessUnitManagersList } from "modules/consultancy/redux/actions";

function toggleModalFetch() {
	return {
		type: types.TOGGLE_MODAL_FETCHING
	};
}

export const setModalErrorMsg = (errorMsg = "") => ({
	type: types.SET_MODAL_ERROR,
	errorMsg
});

function putUsers(users) {
	return {
		type: types.PUT_USERS,
		users
	};
}

function putDepartments(departments) {
	return {
		type: types.PUT_DEPARTMENTS,
		departments
	};
}

export function moveToStep(step, user) {
	return {
		type: types.MOVE_TO_STEP,
		step,
		user
	};
}

function setEmailExists() {
	return {
		type: types.SET_EMAIL_EXISTS
	};
}

function setSuccess() {
	return {
		type: types.SET_SUCCESS
	};
}

export function showCreateUserModal(step, user = null, isEdit = false) {
	return {
		type: types.SHOW_MODAL,
		step,
		user,
		isEdit
	};
}

export function closeModal() {
	return {
		type: types.CLOSE_MODAL
	};
}

export function getUsers({ offset, search }) {
	return AxiosHelper.__post({
		url: GET_USER_LIST_URL,
		next: putUsers,
		data: {
			search,
			limit: 10,
			offset
		}
	});
}

export function getConsultants({ offset, search, tag }) {
	return AxiosHelper.__post({
		url: GET_USER_LIST_URL,
		next: putUsers,
		inAction: false,
		data: {
			search,
			limit: 6,
			offset,
			tag
		}
	});
}

export function getDepartmentsList({ search, offset }) {
	return AxiosHelper.__post({
		url: DEPARTMENTS_LIST,
		data: {
			search,
			limit: 10,
			offset,
			type: "users"
		},
		next: putDepartments
	});
}

export function handleStep(
	step,
	isTourMode,
	nextStep = null,
	payload,
	isEdit = false,
	{ search, offset } = {}
) {
	return dispatch => {
		let url = null;
		switch (step) {
			case INVITE_PREVIEW:
				url = INVITE_USER;
				break;
			case ASSIGN_ROLE:
				if (isEdit) url = EDIT_USER_ROLE;
				break;
			default:
				url = CHECK_EMAIL_INVITATION;
				break;
		}
		if (url === null) {
			return dispatch(moveToStep(nextStep, payload));
		}
		return dispatch(
			AxiosHelper.__post({
				url,
				data: payload,
				isModal: true,
				next: (data, reject, res) => dispatch => {
					if (res.status === RESPONSE_OK) {
						if (step === CREATE_USER) {
							if (!data.exists) return dispatch(moveToStep(nextStep, payload));
							else return dispatch(setEmailExists());
						}
					} else if (res.status === RESPONSE_ACCEPTED) {
						dispatch(getUsers({ search, offset }));

						if (isTourMode) {
							if (isEdit) {
								const userName = `${payload.first_name} ${payload.last_name}`;
								toaster.success(`You have updated ${userName}'s role(s)`);
							} else {
								return dispatch(setSuccess());
							}
						} else {
							const successMessage = isEdit
								? `You have updated ${payload.first_name} ${payload.last_name}'s role(s)`
								: `<h2>User invitation email has successfully been sent to ${payload.first_name} ${payload.last_name}</h2> <h3>Invitation links expire in 72 hours</h3>`;

							toaster.success(successMessage);
						}

						return dispatch(closeModal());
					}
				}
			})
		);
	};
}

export function resendInvite(payload, { search, offset }) {
	return async dispatch => {
		try {
			dispatch(toggleModalFetch());
			const { status } = await axios({
				url: RESEND_INVITATION,
				method: HTTP_POST_METHOD,
				data: JSON.stringify(payload)
			});
			dispatch(toggleModalFetch());
			if (status === RESPONSE_ACCEPTED) {
				toaster.success("The invitation has been resent.");
				dispatch(closeModal());
				dispatch(getUsers({ search, offset }));
			}
		} catch (error) {
			dispatch(toggleModalFetch());
		}
	};
}

export function deleteInvite(payload, options) {
	return async dispatch => {
		try {
			dispatch(toggleModalFetch());
			const { status } = await axios({
				url: DELETE_INVITATION,
				method: HTTP_POST_METHOD,
				data: JSON.stringify(payload)
			});
			dispatch(toggleModalFetch());
			if (status === RESPONSE_ACCEPTED) {
				dispatch(closeModal());
				dispatch(getUsers(options));
				toaster.success("The invitation has been deleted.");
			}
		} catch (error) {
			dispatch(toggleModalFetch());
		}
	};
}

export function transfertOwnership(payload, options) {
	return async dispatch => {
		try {
			dispatch(toggleModalFetch());
			const { status } = await axios({
				url: TRANSFERT_OWNERSHIP_ENDPOINT,
				method: HTTP_POST_METHOD,
				data: JSON.stringify(payload)
			});
			dispatch(toggleModalFetch());
			if (status === RESPONSE_ACCEPTED) {
				dispatch(closeModal());
				dispatch(getUsers(options));
				dispatch(getMyProfile());
				toaster.success("The ownership transfer has been successfully done.");
			}
		} catch (error) {
			const errorMsg = error?.password?.[0] || error?.name;
			dispatch(toggleModalFetch());
			if (errorMsg) dispatch(setModalErrorMsg("Password is incorrect."));
			else {
				dispatch(closeModal());
				toaster.danger("Something went wrong. Please try again!");
			}
		}
	};
}

export function accessChanged(user, isModal = true, options) {
	let isUnblock = user.invitation_status === BLOCKED || user.blocked;
	let payload = {
		status: user.invitation_status,
		id: user._id || user.invitation_id
	};
	if (isUnblock) {
		payload.role_name =
			user.isConsultant || user.role_name === SUPER_ADMIN
				? user.role_name
				: _get(user, "group_roles_name.0.role_name", "");
		payload.group_id = _get(user, "group_roles_name.0._id", "");
	}

	return AxiosHelper.__post({
		data: payload,
		isModal,
		url: isUnblock ? UNBLOCK_USER_ENDPOINT : BLOCK_USER_ENDPOINT,
		REQUEST_SUCCESS: types.CLOSE_MODAL,
		next: () => getUsers(options),
		toastMessage: `${user.first_name} ${user.last_name} has successfully been ${
			isUnblock ? "unblocked" : "blocked"
		}.`
	});
}

export function consultantAccessChanged(user, isModal = true, options) {
	let isUnblock = user.invitation_status === BLOCKED || user.blocked;
	let payload = {
		status: user.invitation_status,
		id: user._id || user.invitation_id
	};
	if (isUnblock) {
		payload.role_name =
			user.isConsultant || user.role_name === SUPER_ADMIN
				? user.role_name
				: _get(user, "group_roles_name.0.role_name", "");
		payload.group_id = _get(user, "group_roles_name.0._id", "");
	}

	return AxiosHelper.__post({
		data: payload,
		isModal,
		url: isUnblock ? UNBLOCK_USER_ENDPOINT : BLOCK_USER_ENDPOINT,
		REQUEST_SUCCESS: types.CLOSE_MODAL,
		next: () => getConsultants(options),
		toastMessage: `${user.first_name} ${user.last_name} has successfully been ${
			isUnblock ? "unblocked" : "blocked"
		}.`
	});
}

export function businessUnitManagerAccessChanged(
	user,
	isModal = true,
	options
) {
	let isUnblock = user.invitation_status === BLOCKED || user.blocked;
	let payload = {
		status: user.invitation_status,
		id: user._id || user.invitation_id
	};
	if (isUnblock) {
		payload.role_name =
			user.isConsultant || user.role_name === SUPER_ADMIN
				? user.role_name
				: _get(user, "group_roles_name.0.role_name", "");
		payload.group_id = _get(user, "group_roles_name.0._id", "");
	}

	return AxiosHelper.__post({
		data: payload,
		isModal,
		url: isUnblock ? UNBLOCK_USER_ENDPOINT : BLOCK_USER_ENDPOINT,
		REQUEST_SUCCESS: types.CLOSE_MODAL,
		next: () => getBusinessUnitManagersList(options),
		toastMessage: `${user.first_name} ${user.last_name} has successfully been ${
			isUnblock ? "unblocked" : "blocked"
		}.`
	});
}

export function toggleDepartmentModal(department = {}) {
	return {
		type: types.TOGGLE_DEPARTMENT_MODAL,
		department
	};
}
export function toggleDepartmentModalSuccess() {
	return {
		type: types.TOGGLE_DEPARTMENT_MODAL_SUCCESS
	};
}

export function createDepartment(
	payload,
	isTourMode,
	showSuccessModalDepartment,
	isEdit = false,
	{ search, offset }
) {
	return AxiosHelper.__post({
		url: isEdit ? UPDATE_DEPARTMENT : CREATE_DEPARTMENT,
		reduxFormName: "department-modal",
		data: payload,
		isModal: true,
		REQUEST_SUCCESS: isTourMode && types.CHANGE_DEPARTMENT_MODAL_STEP,
		next: () => dispatch => {
			if (!isTourMode) {
				const successMessage = isEdit
					? "<h2>The department has been successfully updated</h2> <h3>If applicable, an email has been sent to the users whose role has been impacted by this action.</h3>"
					: "<h2>The department has been successfully added</h2> <h3>If necessary, we've emailed users whose roles were affected by this action.</h3>";

				if (showSuccessModalDepartment === "creation") {
					dispatch(toggleDepartmentModalSuccess());
				}

				!showSuccessModalDepartment && toaster.success(successMessage);
				dispatch(toggleDepartmentModal());
			}

			return dispatch(getDepartmentsList({ search, offset }));
		}
	});
}

export function sendPoke(payload) {
	return AxiosHelper.__post({
		url: POKE_CANDIDATE_ENDPOINT,
		isModal: true,
		data: payload,
		toastMessage: "The candidate has been poked."
	});
}
