import styled from "styled-components";

export const Label = styled.div`
	display: flex;
	gap: 5px;
	.info {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 20px;
		display: flex;
		align-items: center;
		gap: 5px;
		color: rgb(160, 165, 185);
		text-transform: none;
	}
	svg {
		path {
			fill: rgb(160, 165, 185);
		}
	}
`;
