import React, { forwardRef } from "react";
import styles from "./job-address-input.module.scss";
import { ReactComponent as EditIcon } from "static/icons/edit-icon-grey.svg";
import cx from "classnames";

const JobAddressInput = ({ onEdit, value, className }, ref) => {
	return (
		<div className={cx(styles.container, className)}>
			<input
				ref={ref}
				value={value}
				readOnly
				className={styles.input}
				type="text"
			/>
			<button onClick={onEdit} className={styles.button}>
				<EditIcon />
			</button>
		</div>
	);
};

export default forwardRef(JobAddressInput);
