import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_INTERNAL_DATA } from "config/api-endpoints";

function updateLanguages(body) {
	return client(UPDATE_INTERNAL_DATA, {
		body
	});
}
export const updateUserLanguages = "@profile/update-languages";
function useUpdateProfileLangugaes(options) {
	return useMutation(updateLanguages, options);
}

export default useUpdateProfileLangugaes;
