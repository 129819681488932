import styled from "styled-components";

export const ModalContainer = styled.div`
	display: grid;
	grid-template-columns: 30% 70%;
	height: 685px;
	.content {
		height: inherit;
	}
	.menu {
		display: flex;
		flex-direction: column;
		background-color: #eff0f7;
		padding-top: 5%;
	}
`;
export const Fromfiles = styled.div`
	.title {
		font-family: "basiercircle";
		font-weight: 600;
		color: #6e7191;
		margin: 5% 0 0 5%;
		display: flex;
		font-style: normal;
		font-size: 13px;
		line-height: 24px;
	}
	.search-box {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 3% 5%;
		.input {
			position: relative;
			width: 55%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			background: #ffffff;
			border: 1px solid #d9dbe9;
			box-sizing: border-box;
			border-radius: 3px;
			height: 40px;
			> input {
				outline: none;
				padding: 1% 8%;
				border: 1px solid #e2e2e2;
				color: #6e7191;
				width: 100%;
				height: 100%;
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
		}
		.select {
			width: 42%;
			background: #ffffff;
			border: 1px solid #d9dbe9;
			box-sizing: border-box;
			border-radius: 3px;
			.Select-control {
				height: 40px;
				.Select-value {
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.filesList {
		display: grid;
		grid-template-columns: 50% 50%;
		margin: 3% 5%;
	}
	.pagination {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 5%;
		.text {
			font-family: "basiercircle";
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: #a0a3bd;
		}
		.pagination-client {
			margin: 0;
			width: auto;
		}
	}
	.loading {
		height: 250px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
export const FromComputer = styled.div`
	height: 94%;
	position: relative;
	.divider {
		border-top: 1px solid lightgray;
		margin: 5% 10%;
	}
	.title {
		font-family: "basiercircle";
		font-weight: 600;
		font-size: 16px;
		color: #6e7191;
		margin: 5% 0 0 5%;
		display: flex;
	}
	.cards-container {
		margin: 5%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.empty-recent-files {
		margin: 20px auto;
		display: block;
	}
`;
export const EmptyImageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${props => (props.isDragActive ? "80%" : "100%")};
	flex-direction: column;
	margin-top: ${props => (props.Fromfiles ? "150px" : "40px")};
	border: ${props => props.isDragActive && "2px dashed #FD6950"};
	margin: ${props => props.isDragActive && "13% 5% 5% 5%"};
	background: ${props => props.isDragActive && "rgba(253, 105, 80, 0.05)"};
	border-radius: ${props => props.isDragActive && "20px"};
	.drag-title {
		font-family: "basiercircle";
		font-size: 16px;
		line-height: 30px;
		color: #a0a3bd;
		letter-spacing: 0.5px;
		margin: 2%;
		text-transform: capitalize;
	}
	.uploader-button {
		border: none;
		background-color: #a0a3bd;
		font-family: "basiercircle";
		border-radius: 5px;
		color: white;
		padding: 1% 3%;
		cursor: pointer;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 22px;
		&:hover {
			background-color: #6e7191;
		}
	}
`;
export const ImageContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	.image {
		width: 100%;
		height: 100%;
		border-radius: 4px;
	}
	.icon-camera {
		display: none;
		z-index: 10;
	}
	&:hover {
		.image {
			opacity: 0.8;
		}
		.icon-camera {
			position: absolute;
			width: 60px;
			display: block;
		}
		.drop-shadow {
			width: 100%;
			height: 100%;
			background-color: black;
			position: absolute;
			opacity: 0.3;
			border-radius: 3px;
			z-index: 2;
			display: block;
		}
	}
	.drop-shadow {
		display: none;
	}
`;

export const SmallImageContainer = styled.div`
	width: ${props => (props?.width ? props?.width : "98px")};
	height: ${props => (props?.height ? props?.height : "98px")};
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
	position: relative;
	.image {
		width: 100%;
		height: 100%;
	}
	.icon-camera {
		display: none;
		z-index: 10;
	}
	&:hover {
		.image {
			opacity: 0.8;
		}
		.icon-camera {
			position: absolute;
			width: 60px;
			display: block;
		}
		.drop-shadow {
			width: 100%;
			height: 100%;
			background-color: black;
			position: absolute;
			opacity: 0.3;
			border-radius: 3px;
			z-index: 2;
			display: block;
		}
	}
	.drop-shadow {
		display: none;
	}
`;

export const Card = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 5px 0%;
	cursor: pointer;
	width: 100%;
	padding: 3%;
	> img {
		max-width: 60px;
		height: 60px;
		border-radius: 5px;
	}
	.card-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-left: 3%;
		.name {
			font-family: "basiercircle";
			font-weight: 600;
			color: #6e7191;
			word-break: break-word;
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 22px;
		}
		.date {
			font-family: "basiercircle";
			font-weight: 600;
			color: #a0a3bd;
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 22px;
		}
	}
	&:hover {
		background: ${props => (props.recentFile ? "#D9DBE9" : "#EFF0F7")};
		border-radius: 5px;
	}
`;
export const Button = styled.button`
	margin: 2% 10%;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;
	border: none;
	padding: 5%;
	border-radius: 5px;
	box-shadow: 0px 1px 1px #d9dbe9;
	background: ${props => (props.active ? "#FF6849" : "#FFFFFF")};
	color: ${props => (props.active ? "white" : "#6E7191")};
	font-size: 12px;
	font-weight: 600;
	font-family: "basiercircle";
	font-style: normal;
	line-height: 22px;
	.button-content {
		margin-left: 10px;
	}
	.icon {
		.svg-color {
			fill: ${props => (props.active ? "white" : "#6E7191")};
		}
	}
	&:hover {
		background: ${props => (props.active ? "#FF421C" : "#FFFFFF")};
	}
`;
// Crop styles

export const CropContainer = styled.div`
	position: relative;
	width: 100%;
	height: 300px;
	background-color: white;
	border-bottom: 1px solid #eff0f7;
	border-top: 1px solid #eff0f7;
	.reactEasyCrop_CropArea {
		border: 2px solid white;
		color: ${({ cropOutlineColor }) =>
			cropOutlineColor ? cropOutlineColor : "rgba(255, 255, 255, 0.5)"};
	}
`;
export const CropComponentContainer = styled.div`
	.title {
		font-size: 16px;
		font-family: "basiercircle";
		font-weight: 600;
		color: #6e7191;
		margin: 1% 3%;
		font-style: normal;
		line-height: 34px;
		letter-spacing: 0.75px;
	}
	.buttons-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 3% 5%;
		.add-photo {
			display: flex;
			align-items: center;
			outline: none;
			border: 1px solid rgba(0, 0, 0, 0);
			padding: 2%;
			border-radius: 5px;
			flex-direction: column;
			background-color: white;
			justify-content: space-between;
			font-family: "basiercircle";
			letter-spacing: 0.75px;
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 22px;
			color: #6e7191;
			&:hover {
				border: 1px solid lightgray;
			}
		}
		.actions {
			display: flex;
			.apply {
				padding: 12px 28px;
				background: #ff6849;
				border-radius: 3px;
				outline: none;
				border: none;
				color: white;
				margin-left: 2%;
				font-weight: 600;
				font-size: 12px;
				line-height: 22px;
				&:hover {
					background: #ff421c;
				}
			}
			.cancel {
				border: 2px solid lightgray;
				color: #a0a3bd;
				background-color: white;
				border-radius: 3px;
				font-size: 15px;
				font-weight: 600;
				font-family: "basiercircle";
				padding: 10px 21px;
				font-weight: 600;
				font-size: 12px;
				line-height: 22px;
				&:hover {
					border: 2px solid #a0a3bd;
					color: gray;
				}
			}
		}
	}
`;
export const ControlsContainer = styled.div`
	padding: 20px 25px;
	display: flex;
	justify-content: space-between;
	.slider-container {
		width: 40%;
		.labels {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;
			> span {
				font-family: "basiercircle";
				color: #a0a3bd;
				font-weight: 600;
				font-style: normal;
				font-size: 12px;
				line-height: 22px;
			}
			> svg {
				opacity: 0.6;
			}
		}
	}
	.rc-slider {
		height: 16px;
	}
	.rc-slider-track {
		background-color: #e86148;
		height: 5px;
		z-index: 1;
	}
	.rc-slider-step {
		background-color: #eff0f6;
		border-radius: 4px;
		border-color: transparent;
		height: 6px;
	}
	.rc-slider-rail {
		border-radius: 4px;
		height: 5px;
	}
	.rc-slider-handle {
		box-shadow: 0 0 2.5px 0 rgba(152, 153, 156, 0.63);
		background-color: #e86148;
		border: unset;
		width: 18px !important;
		height: 18px !important;
		outline: 0;
		z-index: 2;
		&:focus {
			box-shadow: 0 0 2.5px 0 rgba(152, 153, 156, 2);
		}
		&::before {
			content: " ";
			width: 10px !important;
			height: 10px !important;
			background: white;
			position: absolute;
			top: 23%;
			right: 22%;
			border-radius: 50%;
		}
		&::after {
			content: attr(aria-valuenow);
			color: #e86148;
			font-size: 12px;
			font-weight: 500;
			position: absolute;
			bottom: 100%;
			right: 0;
			border-radius: 50%;
		}
	}
	.rotation {
		.rc-slider-handle {
			&::after {
				content: attr(aria-valuenow) "°" !important;
			}
		}
	}
`;
