import moment from "moment";
import { client } from "lib/api-client";
import { CONTRACTS_DATA_BY_COLUMN_ENDPOINT } from "config/api-endpoints"; // TODO

export const formatEvaluationsList = list => {
	return list.map(item => {
		return {
			id: item._id,
			"Created by":
				item.created_by.first_name + " " + item.created_by.last_name,
			Date: moment(item.created_at * 1000).format("DD/MM/YYYY"),
			Score: item.evaluation_score,
			Candidate: item.profile.first_name + " " + item.profile.last_name,
			Edited: item.updated_at
		};
	});
};

const fetchData = ({ list, tag, search, profile_id }) => {
	return client(CONTRACTS_DATA_BY_COLUMN_ENDPOINT, {
		body: {
			list,
			tag,
			search,
			profile_id
		}
	});
};

export const getCreatedByList = () => {
	return new Promise((resolve, reject) => {
		fetchData({ list: "evaluations", tag: "created_by" })
			.then(res => {
				const list = res.created_by.map(({ client_name, _id }) => ({
					label: client_name,
					id: _id
				}));
				resolve([{ id: 1, label: "Created by", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getVacancyList = profileId => {
	return new Promise((resolve, reject) => {
		fetchData({
			list: "evaluations",
			tag: "filter_evaluation",
			search: "",
			profile_id: profileId
		})
			.then(res => {
				const list = res.jobs.map(({ title, _id }) => ({
					label: title,
					id: _id
				}));
				resolve([{ id: 1, label: "Vacancy", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getScoresList = () => {
	return new Promise((resolve, reject) => {
		fetchData({ list: "evaluations", tag: "score", search: "" })
			.then(res => {
				const list = res.map(({ label, value }) => ({
					id: value,
					label
				}));
				resolve([{ id: 1, label: "Score", children: list }]);
			})
			.catch(e => reject(e));
	});
};
