import React from "react";
import Tooltip from "rc-tooltip";
import _get from "lodash/get";
import styled from "styled-components";
import "rc-tooltip/assets/bootstrap.css";
import { SENIOR } from "config";
import Star from "modules/job/components/jobprofile/Functions/Star";
import { colors } from "config/styles";
import { SubTextHeadline, TitleHeadline } from "../personalTab";
import { Box } from "rebass";

const StarIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	background-color: ${colors.primaryColor};
	width: 19px;
	height: 19px;
	border-radius: 3px;
	float: left;
	margin-top: ${props => props.marginTop || "unset"};
	svg {
		width: 80%;
		height: 70%;
	}
`;

const Functions = ({ mainFunction, otherFunctions }) => {
	const renderCrumb = ({ value, isMain = false, minWidth }) => {
		return (
			<Tooltip
				placement="top"
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				overlay={<div>{value}</div>}
			>
				<li
					style={{
						minWidth,
						height: "100%",
						alignItems: "center",
						display: "flex",
						paddingLeft: isMain && 0
					}}
				>
					{isMain && (
						<StarIconContainer>
							<Star color="white" />
						</StarIconContainer>
					)}
					<span
						style={{
							textOverflow: "ellipsis",
							overflow: "hidden",
							whiteSpace: "nowrap"
						}}
					>
						{value}
					</span>
				</li>
			</Tooltip>
		);
	};

	const renderCrumbs = functions =>
		functions.map(fnc => {
			return (
				<React.Fragment key={fnc._id}>
					<ul
						key={`${fnc._id}-${fnc.seniority}`}
						className={`crumbs ${fnc.is_main ? "main" : ""}`}
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: !fnc.is_main ? "15px" : "0px"
						}}
					>
						{renderCrumb({
							value: _get(fnc, "sector.parent_sector.name", ""),
							isMain: fnc.is_main,
							minWidth: "19%"
						})}
						{renderCrumb({
							value: _get(fnc, "sector.name", ""),
							minWidth: "28%"
						})}
						{renderCrumb({ value: _get(fnc, "name", ""), minWidth: "28%" })}
						{renderCrumb({
							value: _get(fnc, "seniority", SENIOR),
							minWidth: "25%"
						})}
					</ul>
				</React.Fragment>
			);
		});

	return (
		<div id="can-do" style={{ minHeight: "100px" }}>
			<Box>
				<TitleHeadline>MAIN</TitleHeadline>
			</Box>
			{mainFunction.length > 0 ? (
				renderCrumbs(mainFunction)
			) : (
				<SubTextHeadline>N/A</SubTextHeadline>
			)}
			{otherFunctions.length > 0 && (
				<React.Fragment>
					<Box my={3}>
						<TitleHeadline>ADDITIONAL</TitleHeadline>
					</Box>

					{renderCrumbs(otherFunctions)}
				</React.Fragment>
			)}
		</div>
	);
};

export default Functions;
