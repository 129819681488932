import React from "react";
import { CustomInput } from "common/CustomInput";
import { CustomMap } from "common/CustomMap";
import { DatePicker } from "common/DatePicker";
import { OPERATORS } from "common/FilterDrawer/utils";
import { Picker } from "common/Picker";
import { RangeDatePicker } from "common/RangeDatePicker";
import capitalize from "lodash/capitalize";
import { FreelancersBooleanSelect } from "modules/freelancers/components/FreelancersBooleanSelect";
import { FreelancersCurrencyInput } from "modules/freelancers/components/FreelancersCurrencyInput";
import get from "lodash/get";
import { PickerCustomDropDown } from "../components/PickerCustomDropDown/PickerCustomDropDown";
import { formatDate } from "common/Functions";
import { CustomDropDown } from "common/CustomDropDown";
export const REQUESTS_CANDIDATE_PAGE_SIZES = [50, 24, 12];
export const REQUESTS_CANDIDATE_PAGE_SIZE = 24;
export const DISPLAY_TYPE = {
	table: "table",
	card: "card"
};

export const API_DATA_TYPE = "search_candidate_temporary_table";
export const REQUESTS_CANDIDATE_FILTER_ID = "REQUESTS_CANDIDATE_FILTER_ID";
export const REQUESTS_CANDIDATE_MODULE_ID = "REQUESTS_CANDIDATE_MODULE_ID";

const formatLocation = item => {
	if (!item) return "";
	if (!item.street && !item.zip && !item.city && !item.country) return "";
	if (!item.street) return `${item.zip} ${item.city} ${item.country}`;
	return `${item.street},${item.zip} ${item.city} ${item.country}`;
};

const timeCommitementLabel = {
	part_time: "Part time",
	full_time: "Full time"
};

const IsEmptyComponent = props => (
	<CustomDropDown
		options={[
			{
				label: "Yes",
				value: "yes"
			},
			{
				label: "No",
				value: "no"
			}
		]}
		{...props}
	/>
);

export const formatNotesList = list => {
	return list.map(item => {
		return {
			id: item._id,
			"Applicant's name": `${item.user_first_name} ${item.user_last_name}`,
			"Profile type": capitalize(item.company_type),
			Email: item.user_email,
			"Phone Number": item.user_phone,
			Availability: item.availability,
			"Matching score": item.matching_rate,
			"Time commitment": timeCommitementLabel[item.time_commitment],
			Status: item.visible_status,
			Function: get(item, "main_function.function", ""),
			Category: get(item, "main_function.category", ""),
			"Sub-category": get(item, "main_function.subcategory", ""),
			Seniority: capitalize(get(item, "main_function.seniority", "")),
			Reference: item.job?.job_ref,
			Request: item.job?.title,
			"Application Date": formatDate(item.application_date),
			"Rate type": capitalize(item.rate_type),
			Amount: item.proposed_amount,
			Company: item.consultancy?.name || "",
			"Company's address": formatLocation(item.consultancy),
			"Vat number": item.consultancy?.vat || "",
			available_date: item.available_date,
			user_id: item.user_id,
			categories: item.categories,
			subcategories: item.subcategories,
			seniority: item.seniority,
			functions: item.functions,
			job_id: get(item, "job._id", ""),
			currency: get(item, "reporting_settings.currency.code", "")
		};
	});
};

export const TYPES = {
	text: "text",
	postcode: "postcode",
	timeCommitment: "timeCommitment",
	number: "number",
	array: "array",
	boolean: "boolean",
	date: "date",
	address: "address"
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.postcode]: [
		OPERATORS.contains,
		OPERATORS.notContains,
		OPERATORS.startWith
	],
	[TYPES.timeCommitment]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.number]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.multipleSelectCustom]: [
		OPERATORS.is,
		OPERATORS.isNot,
		OPERATORS.anyOf
	],
	[TYPES.boolean]: [OPERATORS.is, OPERATORS.isNot],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[TYPES.address]: [OPERATORS.is]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.address]: {
		default: CustomMap
	},
	[TYPES.postcode]: {
		default: CustomInput
	},
	[TYPES.timeCommitment]: {
		default: Picker
	},
	[TYPES.number]: {
		default: FreelancersCurrencyInput,
		[OPERATORS.isEmpty.value]: IsEmptyComponent
	},
	[TYPES.array]: {
		default: Picker
	},
	[TYPES.multipleSelectCustom]: {
		default: Picker,
		[OPERATORS.is.value]: PickerCustomDropDown
	},
	[TYPES.boolean]: {
		default: FreelancersBooleanSelect
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	}
};

export const columnSortMap = {
	_id: "_id",
	candidate_name: "applicant_name",
	profile_type: "profile_type",
	email: "email",
	phone_number: "phone_number",
	availability: "availability",
	matching_score: "matching_score",
	time_commitment: "time_commitment",
	application_status: "status",
	function: "function",
	sub_category: "subcategory",
	seniority: "seniority",
	reference: "reference",
	request_title: "request_title",
	application_date: "application_date",
	rate_type: "rate_type",
	company: "company",
	company_address: "company_address",
	vat_number: "vat_number",
	amount: "amount"
};

export const columnFiltersMap = {
	_id: "_id",
	candidate_name: "applicant_name",
	profile_type: "profile_type",
	email: "email",
	phone_number: "phone_number",
	availability: "availability",
	matching_score: "matching_score",
	time_commitment: "time_commitment",
	application_status: "status",
	function: "function",
	sub_category: "subcategory",
	seniority: "seniority",
	reference: "reference",
	request_title: "request_title",
	application_date: "application_date",
	rate_type: "rate_type",
	company: "company",
	company_address: "company_address",
	vat_number: "vat_number",
	amount: "amount"
};

export const CHECK_FILTER_COLUMNS = [
	"candidate_name",
	"email",
	"phone_number",
	"request_title",
	"company",
	"vat_number",
	"reference"
];
