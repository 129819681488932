import React from "react";
import { browserHistory } from "react-router";
import _get from "lodash/get";
import style from "./taskDetails.module.scss";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import cx from "classnames";

const RenderLinkedTo = ({ linkedTo, closeDrawer }) => {
	const renderLinkTo = (type, entity) => {
		switch (type) {
			case "permanent":
			case "freelancer":
			case "consultancy":
				return entity;
			case "vacancy":
			case "request":
				return `${entity} ${_get(linkedTo, "job_ref")}`;
		}
	};
	const renderLink = (type, id) => {
		switch (type) {
			case "permanent":
				return getPermanentProfileRoute(id);
			case "freelancer":
				return `/user/profile/${id}`;
			case "consultancy":
				return `/supplier/${id}`;
			case "vacancy":
				return `/vacancy/${id}`;
			case "request":
				return `/job/${id}`;
		}
	};
	const buttonClick = (type, id) => {
		closeDrawer();
		browserHistory.push(renderLink(type, id));
	};
	const renderClickableButton = (title, type, id) => {
		return <button onClick={() => buttonClick(type, id)}>{title}</button>;
	};
	return (
		linkedTo &&
		linkedTo.lenght > 0 && (
			<div className={cx(style.block_link_to, style.root)}>
				<div className={style.col_2}>
					<h3>Link to </h3>
					<p>
						{renderLinkTo(_get(linkedTo, "type"), _get(linkedTo, "entity"))}
					</p>
				</div>
				<div className={style.col_2}>
					<h3 className={style.capitalize}>{_get(linkedTo, "entity")}</h3>
					{renderClickableButton(
						_get(linkedTo, "title")
							? _get(linkedTo, "title")
							: `${_get(linkedTo, "first_name")} ${_get(
									linkedTo,
									"last_name"
							  )}`,
						_get(linkedTo, "type"),
						_get(linkedTo, "_id")
					)}
				</div>
			</div>
		)
	);
};

export default RenderLinkedTo;
