import React from "react";
import styles from "./editable-section.module.scss";

const Actions = ({
	isEdit,
	onEdit,
	onCancel,
	isLoading,
	onSave,
	disable = true
}) => {
	return isEdit ? (
		<div className={styles.flex}>
			<button
				disabled={!disable}
				className={styles.firstAction}
				onClick={onSave}
			>
				{isLoading ? "Saving..." : "Save"}
			</button>{" "}
			<button
				disabled={isLoading}
				className={styles.secondAction}
				type={"button"}
				onClick={onCancel}
			>
				Cancel
			</button>
		</div>
	) : (
		<button type={"button"} onClick={onEdit} className={styles.secondAction}>
			Edit
		</button>
	);
};
export default Actions;
