import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
	CATEGORY,
	DEFAULT_TEMPLATE_VERSION,
	MAX_LENGTH_IN_BYTES,
	NEW_TEMPLATE_SEGMENT_ID
} from "../utils/constants";
import { useGetEmailTemplate } from "../api/useGetEmailTemplate";
import { useEffect, useState } from "react";
import { PUBLIC } from "common/ContentVisibilityModal/utils";
import { v4 as uuid } from "uuid";
import { bytesToSize } from "config/helpers";

export default function useEmailTemplateForm({ templateId }) {
	const [formKey, setFormKey] = useState("");
	const isEditMode = templateId !== NEW_TEMPLATE_SEGMENT_ID;
	const templateQuery = useGetEmailTemplate(
		{ id: templateId },
		{
			enabled: false,
			onSuccess(data) {
				initForm(data);
			}
		}
	);

	const formContext = useForm({
		mode: "onChange",
		resolver: yupResolver(
			yup.object().shape({
				template_name: yup.string().required("Title is required"),
				versions: yup.array().of(
					yup.object().shape({
						content: yup.string().test(
							"content-size",
							({ value }) => {
								return `Content is too long (${bytesToSize(
									new TextEncoder().encode(value).length
								)}), max is ${bytesToSize(MAX_LENGTH_IN_BYTES)}`;
							},
							value => {
								return (
									new TextEncoder().encode(value).length < MAX_LENGTH_IN_BYTES
								);
							}
						)
					})
				)
			})
		),
		defaultValues: {
			id: NEW_TEMPLATE_SEGMENT_ID,
			template_name: "",
			category: CATEGORY.GENERAL,
			visibility: PUBLIC,
			visible: { users: [], groups: [] },
			not_visible: { users: [], groups: [] },
			versions: [
				{
					version: DEFAULT_TEMPLATE_VERSION,
					subject: "",
					content: "",
					placeholders: []
				}
			]
		}
	});

	useEffect(() => {
		if (isEditMode) {
			templateQuery.refetch();
		}
	}, []);

	const initForm = data => {
		formContext.reset({
			id: data._id,
			template_name: data.template_name,
			category: data.category,
			visibility: data.visibility,
			visible: data.visible,
			not_visible: data.not_visible,
			versions: Object.values(data.versions).map(field => ({
				version: field.version,
				subject: field.subject,
				content: field.content,
				placeholders: field.placeholders
			}))
		});
		setFormKey(uuid());
	};

	return { formContext, templateQuery, formKey };
}
