import React from "react";
import Table from "modules/candidate/Profile/components/Table/Table.js";
import {
	CONTRACTS_GROUPS_LIST_LOADING_TABLE_DFINITION,
	CONTRACTS_GROUPS_LIST_LOADING_TABLE_DATA
} from "modules/vonqContract/constants";

const ContractsGroupsListLoadingView = () => (
	<Table
		data={CONTRACTS_GROUPS_LIST_LOADING_TABLE_DATA}
		columns={CONTRACTS_GROUPS_LIST_LOADING_TABLE_DFINITION}
	/>
);

export default ContractsGroupsListLoadingView;
