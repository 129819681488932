import React from "react";
import styles from "./permanent-evaluations-list-empty-state.module.scss";
import { ReactComponent as Icon } from "static/icons/empty-white-folder.svg";

const PermanentEvaluationsListEmptyState = () => {
	return (
		<div className={styles.container}>
			<Icon />
			<div className={styles.title}>No evaluations recorded yet.</div>
			<div className={styles.description}>
				Begin the evaluation process to document candidate skills and
				performance.
			</div>
		</div>
	);
};

export default PermanentEvaluationsListEmptyState;
