import create from "zustand";

const store = create(set => ({
	taskData: {},
	isOpen: false,
	setIsOpen: value => set({ isOpen: value }),
	setTaskData: task => set({ taskData: task })
}));

const useAssignEvaluationTaskStore = () => {
	const { isOpen, taskData, setIsOpen, setTaskData } = store(state => ({
		...state
	}));
	return {
		isOpen,
		taskData,
		setIsOpen,
		setTaskData
	};
};

export default useAssignEvaluationTaskStore;
