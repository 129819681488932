import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.title {
		color: #242f43;
		font-size: 18.3px;
		font-weight: bold;
		line-height: 1.88;
		text-align: center;
	}
	.description {
		font-size: 13.3px;
		line-height: 1.74;
		color: #242f43;
		text-align: center;
		&.no-title {
			margin-top: 20px;
		}
	}
	svg {
		width: 80px;
	}
	button {
		margin-top: 20px;
	}
	.link {
		text-decoration: underline;
		color: #ff6849;
		cursor: pointer;
	}
`;
