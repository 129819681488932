import { useEffect } from "react";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import { usePaginatedQuery } from "react-query";
import fakeCandidates from "modules/user-onboarding/mockData/candidates.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const KEY_FETCH_FAKE_CANDIDATES = "KEY_FETCH_FAKE_CANDIDATES";

const useFilterData = ({ enabled = true }) => {
	const { setIsLoading, setIsFetching, setData } = useFilters();

	const { workflowId } = useOnboardingStore();

	const fetchData = () => new Promise(r => r(fakeCandidates));

	const {
		resolvedData: data,
		isLoading,
		isFetching,
		refetch,
		isFetched
	} = usePaginatedQuery([KEY_FETCH_FAKE_CANDIDATES, workflowId], fetchData, {
		enabled
	});

	useEffect(() => {
		if (enabled) {
			setIsLoading(isLoading);
			setIsFetching(isFetching && !isFetched);
		}
	}, [isLoading, isFetching, refetch, isFetched]);

	useEffect(() => {
		if (data) setData(data);
	}, [data]);

	return refetch;
};

export default useFilterData;
