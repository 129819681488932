import React from "react";
import styles from "./footer.module.scss";
import { ConfirmationModal } from "common/modal";
import { Button } from "common/Button";
import { ReactComponent as TrashIcon } from "static/icons/trash-icon-grey.svg";
import cx from "classnames";

const Footer = ({
	children,
	showDeleteIcon = true,
	onClose,
	onClickLabel,
	onClick,
	className
}) => (
	<div className={cx(styles.buttons, className)}>
		{children ? (
			children
		) : (
			<>
				<Button
					type="button"
					text="Cancel"
					onClick={onClose}
					variant="outlined"
					rootClassName={styles.cancel}
				/>
				<Button
					type="button"
					text={onClickLabel}
					className={styles.deleteButton}
					onClick={onClick}
					icon={showDeleteIcon && <TrashIcon className={styles.trashIcon} />}
				/>
			</>
		)}
	</div>
);

export default Footer;
ConfirmationModal.Footer = Footer;
