import React from "react";
import MessagingToolEmailViewHeader from "./components/MessagingToolEmailViewHeader/MessagingToolEmailViewHeader";
import MessagingToolEmailViewMessage from "./components/MessagingToolEmailViewMessage/MessagingToolEmailViewMessage";
import styles from "./messaging-tool-email-view.module.scss";

const MessagingToolEmailView = ({ children }) => (
	<div className={styles.container}>{children}</div>
);

MessagingToolEmailView.Header = MessagingToolEmailViewHeader;
MessagingToolEmailView.Message = MessagingToolEmailViewMessage;

export default MessagingToolEmailView;
