import { useMemo } from "react";
import useRequestViewDrawerStore from "../store/useRequestViewDrawerStore";

export function useTempDrawerHelpers({ drawerType }) {
	const {
		drawerId,
		drawerProps,
		setDrawerId,
		setDrawerProps,
		cleanDrawerStore
	} = useRequestViewDrawerStore();

	const closeDrawer = () => setDrawerId("");
	const openDrawer = () => setDrawerId(drawerType);

	const initializeAndOpenDrawer = ({ drawerProps }) => {
		setDrawerProps(drawerProps); // init drawer props
		setDrawerId(drawerType); // open drawer
	};

	const isOpen = useMemo(() => drawerId === drawerType, [drawerId]);

	return {
		isOpen,
		openDrawer,
		drawerProps,
		closeDrawer,
		cleanDrawerStore,
		initializeAndOpenDrawer
	};
}
