import React from "react";
import Drawer from "rc-drawer";
import styles from "./add-candidate-drawer-compound.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { ReactComponent as BackIcon } from "static/icons/chevron-left.svg";
import cx from "classnames";
import {
	PARCELS_LIST_ID,
	PARCEL_DETAILS_ID
} from "common/AddCandidateDrawer/utils/constants";
import useGetFiles from "common/AddCandidateDrawer/hooks/useGetFiles";

const AddCandidateDrawerCompound = ({ isOpen, onClose, children }) => {
	return (
		<Drawer
			width="600"
			open={isOpen}
			height="100%"
			level={"root"}
			placement="right"
			onClose={onClose}
			maskClosable={true}
			style={{ zIndex: 1100 }}
		>
			<div className={styles.drawerContainer}>{children}</div>
		</Drawer>
	);
};

export const Header = ({ onClose, hasBackButton = false, onBack }) => {
	return (
		<div className={styles.titleContainer}>
			<div className={styles.flexContainer}>
				{hasBackButton && (
					<button onClick={onBack} className={styles.backBtn}>
						<BackIcon />
					</button>
				)}

				<div className={styles.title}>Add Candidate</div>
			</div>
			<button onClick={onClose} className={styles.closeBtn}>
				<CloseIcon />
			</button>
		</div>
	);
};

export const Body = ({ children }) => {
	const { drawerActiveBody } = useGetFiles();
	const isParcelView = [PARCELS_LIST_ID, PARCEL_DETAILS_ID].includes(
		drawerActiveBody
	);
	return (
		<div
			className={cx(styles.drawerBodyContainer, {
				[styles.parcelStyle]: isParcelView
			})}
		>
			{children}
		</div>
	);
};

export const Footer = ({ children }) => {
	return <div className={styles.drawerFooterContainer}>{children}</div>;
};

export const Divider = () => {
	return <div className={styles.divider} />;
};

export default AddCandidateDrawerCompound;
