import React from "react";

function UserIcon({ fill, width, height }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.9999 7.19999C9.32538 7.19999 10.3999 6.12548 10.3999 4.79999C10.3999 3.47451 9.32538 2.39999 7.9999 2.39999C6.67442 2.39999 5.5999 3.47451 5.5999 4.79999C5.5999 6.12548 6.67442 7.19999 7.9999 7.19999Z"
				fill={fill}
			/>
			<path
				d="M2.3999 14.4C2.3999 11.3072 4.90711 8.79999 7.9999 8.79999C11.0927 8.79999 13.5999 11.3072 13.5999 14.4H2.3999Z"
				fill={fill}
			/>
		</svg>
	);
}

export default UserIcon;
