import React from "react";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";

const DeleteModal = ({
	closeModal,
	deletPendingProfile,
	profileId,
	isLoading
}) => {
	const deletProfile = () => {
		deletPendingProfile(profileId);
		closeModal();
	};

	return (
		<ConfirmationModal
			key="modal"
			title={`Are you sure you want to delete this profile ?`}
			onClose={closeModal}
			type={ConfirmationTypes.error}
			firstButton={{
				action: () => {
					deletProfile();
				},
				label: `YES, DELETE`,
				type: "danger"
			}}
			loading={isLoading}
		/>
	);
};

export default DeleteModal;
