import styled, { css } from "styled-components";

export const ProposeInterviewContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background: #f8f9fa;
	.drawer-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
		padding: 10px;
		.left-side {
			display: flex;
			align-items: center;
			.back-button {
				background: transparent;
				border: none;
				padding: 0;
			}
			.header-title {
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 32px;
				color: #202223;
			}
		}
		.right-side {
			display: flex;
			align-items: center;
			.primary-btn {
				background: linear-gradient(114.44deg, #ff3649 0%, #ff6849 100%);
				border-radius: 4px;
				padding: 6px 10px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #ffffff;
				border: none;
			}
			.outlined-btn {
				margin-right: 8px;
				background: white;
				border-radius: 4px;
				padding: 6px 10px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #303131;
				border: 1px solid #bbbfc3;
				background: #ffffff;
				box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
				border-radius: 4px;
			}
		}
	}
	.drawer-body {
		display: flex;
		justify-content: space-between;
		.button-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 48px;
			.primary-btn {
				background: linear-gradient(114.44deg, #ff3649 0%, #ff6849 100%);
				border-radius: 4px;
				padding: 6px 10px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #ffffff;
				border: none;
			}
			.outlined-btn {
				background: white;
				border-radius: 4px;
				padding: 6px 10px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 16px;
				text-align: center;
				color: #303131;
				border: 1px solid #bbbfc3;
				background: #ffffff;
				box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
				border-radius: 4px;
				margin-top: 10px;
			}
		}
		.form-group.has-error input,
		.form-group.has-error select,
		.form-group.has-error textarea,
		.form-group.has-error .form-control,
		.form-group.has-error .Select-control {
			border-color: #df006b !important ;
		}
		.help-block.inline-error {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			color: #df006b;
		}
		.empty-state-interviews {
			display: flex;
			align-items: center;
			margin-top: 20px;
			.empty-state-text {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				color: #202223;
				margin-left: 10px;
			}
		}
	}
`;

export const PlanInterviewContainer = styled.div`
	background: #ffffff;
	border-radius: 4px;
	padding: 57px 128px;
	width: 65%;
	margin-left: 18px;
	margin-bottom: 20px;
	.form-group > label {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #a8aaac;
		text-transform: none;
	}
	.rc-time-picker-input {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
	}
	.candidate-phone-number {
		margin-bottom: 32px;

		margin-top: 32px;
		.candidate-number [type="tel"].form-control {
			width: 250px;
		}
	}
	.attendes-container {
		position: relative;
		margin-bottom: 32px;
		margin-top: 32px;
		.invite-attendes {
			margin-top: 8px;
			display: flex;
			align-items: center;
			.loading-attendees {
				display: flex;
				align-items: center;
				> span {
					margin-left: 8px;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 14px;

					color: #a8aaac;
				}
				.loader {
					margin-bottom: 0;
					border-top-color: #f01c29;
					> span {
						height: 18px;
						width: 18px;
					}
				}
			}
			.other-invited-users {
				height: 32px;
				width: 32px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #f0f3f5;
				border: none;
				margin-right: 8px;
			}
			.opener {
				display: flex;
				align-items: center;
			}
			.invite-users {
				margin-left: 8px;
			}
			> img {
				height: 32px;
				width: 32px;
			}
		}
		.error-message {
			margin-top: 4px;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			color: #df006b;
		}
	}
	.interview-note {
		margin-bottom: 32px;
		.add-note-form {
			position: relative;
			> textarea {
				width: 100%;
				height: 90px;
				resize: none;
				::placeholder {
					color: #a8aaac;
				}
				&.error {
					border: 1px solid #f54953;
				}
			}
			> p {
				position: absolute;
				right: 20px;
				bottom: 10px;
				font-style: normal;
				font-weight: normal;
				font-size: 12px;
				line-height: 14px;
				color: #a8aaac;
				&.error {
					color: #f54953;
				}
			}
		}
		.input-maxlength.mentions_input-maxlength__1elvo {
			bottom: 5px;
		}
	}
`;

export const Headline = styled.span`
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #a8aaac;
	margin-top: 32px;
	margin-bottom: 8px;
`;

export const SubTitle = styled.span`
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: #181819;
	margin-top: 16px;
	margin-bottom: 8px;
	text-align: center;
`;
export const LocationDescription = styled.p`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	color: #181819;
	margin-top: 8px;
`;

export const PlannedInterviewsContainer = styled.div`
	background: #ffffff;
	border-radius: 4px;
	padding: 16px 24px;
	width: 30%;
	height: fit-content;
	max-height: 100%;
	overflow-y: auto;
	.headline {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #181819;
		display: block;
		margin: 0px 0px 8px 0px;
	}
	.subtitle {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #48494a;
	}
	.content {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: #181819;
		margin: 0;
	}
	.upcoming-interview-title {
		padding-bottom: 8px;
		border-bottom: 1px solid #f0f3f5;
		border-radius: 4px;
	}
	.history-interview-title {
		padding-bottom: 8px;
		border-bottom: 1px solid #f0f3f5;
		border-radius: 4px;
		.headline {
			margin-top: 24px;
		}
	}
	.interview-history {
		margin-bottom: 100px;
	}
`;

export const InterviewStatusContainer = styled.div`
	padding-top: 18px;
	padding-bottom: 8px;
	border-bottom: 1px solid #f0f3f5;
	border-radius: 4px;
	.detail-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-left: ${({ color }) => "3px solid " + color};
	}

	.icon {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

export const Title = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	color: #181819;
	margin: ${({ margin }) => margin};
`;

export const CheckLocationBoxesWrapper = styled.div`
	background: #ffffff;
	border-radius: 4px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	width: 30%;
	height: 197px;
	cursor: pointer;
	border: ${({ isSelected }) =>
		isSelected ? "1px solid #FF6849" : "1px solid #d8dbdd"};
	${props =>
		props.isSelected &&
		css`
			box-shadow: 0px 0px 2px 2px rgba(255, 104, 73, 0.24);
		`};
	.radio-container {
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;

			&:checked ~ .label {
				background-color: white;
				border: 2px solid #ff6849;
			}
			&:checked ~ .label:after {
				display: block;
			}
		}
		.label {
			display: block;
			top: 0;
			left: 0;
			height: 18px;
			width: 18px;
			background: #ffffff;
			border: 2px solid #8c9196;
			border-radius: 50%;
			margin: 0;
			padding: 0;
			font-size: 75%;
		}

		.label:after {
			content: "";
			position: absolute;
			display: none;
		}

		.label:after {
			top: 1px;
			left: 1px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #ff6849;
		}
	}
	:hover {
		box-shadow: 0px 0px 2px 2px rgba(255, 104, 73, 0.24);
		border: 1px solid #ff6849;
		${props =>
			!props.isSelected &&
			css`
				.radio-container {
					.label {
						border: 2px solid #ff6849;
					}
					.label:after {
						display: block;
						border: 2px solid #8c9196;
						background: #fff;
					}
				}
			`};
	}
	.icon-wrapper {
		align-self: center;
		margin-bottom: 4px;
	}
	.checkbox {
		.input-box {
			input[type="checkbox"].styled + label:before {
				border-radius: 50%;
			}
		}
	}
`;

export const InterviewLocationContainer = styled.div`
	.check-location-boxes.wrapper {
		display: flex;
		margin-top: 10px;
		justify-content: space-between;
		align-items: center;
	}
`;

export const LocationModalContainer = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	position: fixed;
	width: 612px;
	height: auto;
	top: 10%;
	right: 30%;
	z-index: 9999;
	.modal-interview-header {
		display: flex;
		justify-content: space-between;
		padding: 20px;
		.title {
			font-style: normal;
			font-weight: normal;
			font-size: 20px;
			line-height: 28px;
			color: #202223;
		}
		.close-modal {
			background: transparent;
			border: none;
		}
	}
	.modal-interview-body {
		position: relative;
		padding: 20px;
		border-top: 1px solid #e1e3e5;
		border-bottom: 1px solid #e1e3e5;
		.form-group {
			width: 100%;
		}
		.modal-body-text {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;

			color: #202223;
			margin-bottom: 17px;
		}
		.interview-location-box {
			display: flex;
			.map-marker-button {
				margin-left: 10px;
				background: #ffffff;
				padding: 8px 12px;
				height: 50px;
				box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
				border-radius: 4px;
				border: 1px solid #babfc3;
			}
		}
	}
	.modal-interview-footer {
		display: flex;
		justify-content: flex-end;
		padding: 20px;
		.add-address-button {
			padding: 8px 16px;
			background: linear-gradient(180deg, #f01c29 0%, #ff6849 100%);
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
				inset 0px -1px 0px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			border: none;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #ffffff;
		}
		.close-button {
			margin-right: 8px;
			border: 1px solid #e1e3e5;
			background: #ffffff;
			padding: 8px 16px;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
			border-radius: 4px;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #202223;
		}
	}
`;

export const LoadingStateContainer = styled.div`
	margin-top: 20px;
	.body {
		display: flex;
		justify-content: space-between;
	}
`;
