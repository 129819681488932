import React from "react";
import s from "./billing-details-data.module.scss";

const BillingDetailsData = ({ data }) => {
	return (
		<div className={s.root}>
			<h5>Billing details</h5>
			<div className={s.box}>
				<div>
					<h4>Vat number</h4>
					<p>{data?.vat || "-"}</p>
				</div>
				<div>
					<h4>bic (bank identifier code)</h4>
					<p>{data?.bic || "-"}</p>
				</div>
				<div>
					<h4>iban (international bank account number)</h4>
					<p>{data?.iban || "-"}</p>
				</div>
			</div>
		</div>
	);
};

export default BillingDetailsData;
