import React, { forwardRef } from "react";
import styles from "./new-email-auto-complete-input.module.scss";
import { SearchInput } from "common/SearchInput";
import debounce from "lodash/debounce";

const NewEmailAutoCompleteInput = (
	{ onFocus, inputValue, setAutocompleteKeyword, disableInput, children },
	ref
) => {
	const debouncedOnSearch = debounce(value => {
		setAutocompleteKeyword(value);
	}, 500);

	const onSearch = e => {
		debouncedOnSearch(e.target.value);
	};

	return (
		<div className={styles.autoComplete}>
			{children}
			<SearchInput
				className={styles.inputWrapper}
				inputClassName={styles.inputClassName}
				variant="borderless"
				onChange={onSearch}
				onFocus={onFocus}
				value={inputValue}
				icon={<></>}
				placeholder=""
				disabled={disableInput}
				ref={ref}
			/>
		</div>
	);
};

export default forwardRef(NewEmailAutoCompleteInput);
