import { DropDownWithSearch } from "common/DropDownWithSearch";
import { Paper } from "common/Paper";
import React, { useState, memo } from "react";
import { OverlayProvider } from "react-aria";
import styles from "./columns-select.module.scss";
import Menu from "./Menu/menu";
import SearchResult from "./SearchResult/SearchResult";
import SubMenu from "./SubMenu/SubMenu";

const ColumnsSelect = ({ value, menu, onChange }) => {
	const [displaySubMenu, setDisplaySubMenu] = useState(false);
	const [children, setChildren] = useState([]);
	const [displayMenu, setDisplayMenu] = useState(false);
	const [filterResult, setFilterResult] = useState([]);
	const [search, setSearch] = useState("");

	const handleClose = () => {
		setSearch("");
		if (displaySubMenu) {
			setDisplaySubMenu(false);
			setFilterResult([]);
		}
		if (displayMenu) {
			setDisplayMenu(false);
			setFilterResult([]);
		}
	};

	const handleHover = id => {
		const item = menu.find(item => item.id === id);

		setDisplaySubMenu(true);
		setChildren(item.children);
	};

	const handleMenuItemClick = item => {
		onChange(item);
		setDisplaySubMenu(false);
		setDisplayMenu(false);
		setSearch("");
	};

	const handleInputClick = () => {
		setDisplayMenu(displayMenu => !displayMenu);
	};

	const handlSearchChange = e => {
		const res = [];
		const search = e.target.value;
		setSearch(search);

		if (e.target.value === "") {
			setFilterResult([]);
			return;
		}

		menu.forEach(menuItem => {
			const items = menuItem.children.filter(item => {
				return item.label.toLowerCase().includes(search.toLowerCase());
			});

			if (items.length > 0) {
				res.push({
					...menuItem,
					children: items
				});
			}
		});

		setFilterResult(res);
		setDisplaySubMenu(false);
	};

	const handleSearchResultItemClick = item => {
		onChange(item);
		setDisplaySubMenu(false);
		setDisplayMenu(false);
		setFilterResult([]);
		setSearch("");
	};

	return (
		<div className={styles.root}>
			<OverlayProvider>
				<DropDownWithSearch
					onClose={handleClose}
					paperClassName={styles.dropDownPaper}
					content={
						<div>
							{!search && <Menu menu={menu} onMenuItemHover={handleHover} />}
							{displaySubMenu && (
								<Paper className={styles.paper}>
									<SubMenu onItemClick={handleMenuItemClick} items={children} />
								</Paper>
							)}
						</div>
					}
					placeHolder="Choose column..."
					value={value.label}
					displayPaper={displayMenu}
					onInputClick={handleInputClick}
					searchResultContent={
						<SearchResult
							onClick={handleSearchResultItemClick}
							items={filterResult}
						/>
					}
					dropdown
					onSearchChange={handlSearchChange}
					variant="parent-width"
				/>
			</OverlayProvider>
		</div>
	);
};

export default memo(ColumnsSelect);
