import { EMAIL_DETAILS } from "config/api-endpoints";
import { client } from "lib/api-client";

const { useQuery } = require("react-query");

const getEmailDetails = body => {
	return client(EMAIL_DETAILS, { body });
};

export const EMAIL_DETAILS_KEY = "EMAIL_DETAILS_KEY";

const useGetEmailDetails = (body, options) =>
	useQuery([EMAIL_DETAILS_KEY, body], () => getEmailDetails(body), {
		retry: 0,
		refetchOnWindowFocus: false,
		...options
	});

export default useGetEmailDetails;
