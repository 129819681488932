import React from "react";
import Avatar from "common/Avatar";
import { Box, Flex, Text } from "rebass";
import { dateCalendar } from "common/Functions";
import { get } from "lodash";

const SingleComment = ({ item }) => {
	let isInternal = get(item, "is_internal", false);
	return (
		<Flex my={30}>
			<Box mr={10}>
				<Avatar name={item.user_name} avatar={item.avatar} size={34} />
			</Box>
			<Box>
				<Flex alignItems="flex-end">
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: 600,
							fontSize: "13px",
							lineHeight: "24px",
							color: "#6E7191",
							textAlign: "left",
							wordBreak: "break-all"
						}}
					>
						{item.user_name}
					</Text>
					<Text
						mx={10}
						sx={{
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: "normal",
							fontSize: "12px",
							lineHeight: "23px",
							color: "#A0A3BD",
							textAlign: "left",
							wordBreak: "break-all"
						}}
					>
						{dateCalendar(item.date)}
					</Text>
					{isInternal && (
						<Box
							sx={{
								padding: "0px 10px",
								background: "#FF6849",
								borderRadius: "100px"
							}}
						>
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: "11px",
									lineHeight: "23px",
									color: "#FFFFFF",
									textAlign: "left"
								}}
							>
								Internal
							</Text>
						</Box>
					)}
				</Flex>

				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: "normal",
						fontSize: "13px",
						lineHeight: "22px",
						color: "#6E7191",
						textAlign: "left",
						wordBreak: "break-all"
					}}
				>
					{item.comment}
				</Text>
			</Box>
		</Flex>
	);
};

export default SingleComment;
