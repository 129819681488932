import React from "react";
const MaximizeIcons = ({
	color,
	stroke,
	width,
	height,
	marginRight,
	marginLeft
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ marginRight: `${marginRight}`, marginLeft: `${marginLeft}` }}
		>
			<path
				d="M9.33333 6.66667L14 2M14 2H10M14 2V6M6.66667 9.33333L2 14M2 14H6M2 14L2 10"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default MaximizeIcons;
