import React from "react";
import cx from "classnames";
import styles from "./field-error.module.scss";

const FieldError = ({ error, className }) => {
	return error ? (
		<div className={cx(styles.container, className)}>
			<div>{error.message}</div>
		</div>
	) : null;
};

export default FieldError;
