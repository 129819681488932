import Immutable from "seamless-immutable";
import {
	FETCH_PERMANENT_PROFILE,
	FETCH_MY_JOBS_SUCCESS,
	RESET_CANDIDATE_PROFILE,
	TOGGLE_MODAL_REMOVE_INTEREST,
	TOGGLE_CANCEL_INTERVIEW_MODAL,
	SET_LIST_ATTENDEES,
	TOGGLE_CANCEL_APPLICATION_MODAL,
	UPDATE_UPLOADING_CV,
	UPDATE_UPLOADING_CV_ERROR
} from "./types";

const initialState = Immutable({
	cv_doc: "",
	front_sheet: {},
	functions: [],
	languages: [],
	skills: [],
	jobList: {
		pokes: []
	},
	removeInterest_modal: {
		show: false
	},
	cancelApplication_modal: {
		show: false
	},
	cancelInterview_modal: {
		show: false,
		data: {},
		type: null
	},
	attendeesList: [],
	isUploadingCv: false,
	hasUploadError: false
});

export default function(state = initialState, action) {
	switch (action.type) {
		case FETCH_PERMANENT_PROFILE:
			return Immutable.merge(state, action.data);
		case FETCH_MY_JOBS_SUCCESS:
			return Immutable.merge(state, { jobList: action.data });
		case RESET_CANDIDATE_PROFILE:
			return initialState;
		case TOGGLE_MODAL_REMOVE_INTEREST:
			return Immutable.merge(state, {
				removeInterest_modal: {
					show: !state.removeInterest_modal.show
				}
			});
		case TOGGLE_CANCEL_INTERVIEW_MODAL:
			return Immutable.merge(state, {
				cancelInterview_modal: {
					show: !state.cancelInterview_modal.show,
					data: action.modal
				}
			});
		case TOGGLE_CANCEL_APPLICATION_MODAL:
			return Immutable.merge(state, {
				cancelApplication_modal: {
					show: !state.cancelApplication_modal.show
				}
			});
		case SET_LIST_ATTENDEES:
			return Immutable.merge(state, {
				attendeesList: action.data.list_users
			});
		case UPDATE_UPLOADING_CV:
			return Immutable.merge(state, {
				isUploadingCv: action.data
			});
		case UPDATE_UPLOADING_CV_ERROR:
			return Immutable.merge(state, {
				hasUploadError: action.data
			});
		default:
			return state;
	}
}
