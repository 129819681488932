import React, { useEffect } from "react";
import "@reach/tabs/styles.css";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import Vacancies from "./components/SearchCandidateContainer/SearchCandidateContainer";

export const getProfileSearchRoute = () => {
	return `/candidates/search`;
};

const ViewSwitcher = () => {
	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Permanent" },
			{
				name: "Candidates",
				path: "/candidates/search"
			}
		]);
	}, []);

	return <Vacancies />;
};

export default ViewSwitcher;
