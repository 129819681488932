// TO DO: change indexes with field id
import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import _get from "lodash/get";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-phone-input-2/lib/style.css";
import { stringify } from "query-string";
import { EXTERNAL_LINKS, PHONES, EMAILS } from "config";
import usePersonalProfile, {
	checkActivateAccount,
	checkPhoneFormat
} from "modules/candidate/Profile/api/usePersonalProfile";
import {
	handleCheckEmail,
	mapFields,
	personalProfileSchema
} from "../../../../utils/helper";

import toaster from "common/Toaster";
import EditableSection from "../EditableSection";
import { PersonalProfileLoading } from "modules/candidate/Profile/components/Loading";

import cardStyle from "../EditableSection/editable-section.module.scss";
import styles from "./../../profile.module.scss";
import { historyPush } from "config/helpers";
import LeaveHook from "common/LeaveHook";
import {
	CANDIDATE_ACTIONS,
	CANDIDATE_TABS,
	PROFILE_TABS
} from "modules/home/config";
import { usePrimary } from "./api/usePrimary";
import { useSaveProfile } from "./api/useSaveProfile";
import { useUnsavedChangesDialog } from "modules/candidate/Profile/Store/useUnsavedChangesDialog";
import EmailFields from "./EmailFields";
import PhoneFields from "./PhoneFields";
import LinkFields from "./LinkFields";
import FirstNameField from "./FirstNameField";
import LastNameField from "./LastNameField";

const profileData = ({
	id,
	personalProfile,
	isLoading,
	cv_id,
	profile_id,
	viewProfileButtonClicked
}) => {
	const [isEditMode, setEditMode] = useState(false);
	const [updated, setUpdated] = useState(false);
	const [changedValue, setChangedValue] = useState({});
	const [canMerge, setCanMerge] = useState([]);
	const [profileData, setProfileData] = useState(personalProfile);
	const [, setDisabledFields] = useState({});
	const [isNewPhoneExists, setIsNewPhoneExists] = useState(false);
	const [isNewEmailExists, setIsNewEmailExists] = useState(false);
	const [isNewExternalProfileExists, setIsNewExternalProfileExists] = useState(
		false
	);
	const [canAddToPool, setCanAddToPool] = useState([]);
	const [isProfileAreadyInPool, setIsProfileAreadyInPool] = useState([]);
	const [isRedirection, setIsRedirection] = useState(false);

	const [
		isUnsavedChangesDialogOpen,
		toggleUnsavedChangesDialogOpen
	] = useUnsavedChangesDialog();

	const setAsPrimary = usePrimary(
		() => {
			setUpdated(true);
		},
		() => {
			setUpdated(true);
		}
	);

	const {
		control,
		register,
		setError,
		watch,
		reset,
		clearErrors,
		formState
	} = useForm({
		mode: "onChange",
		resolver: yupResolver(personalProfileSchema),
		reValidateMode: "onChange",
		shouldUnregister: false
	});

	const {
		fields: emailFields,
		append: appendEmail,
		remove: removeEmail
	} = useFieldArray({
		control,
		name: EMAILS
	});

	const {
		fields: phoneFields,
		append: appendPhone,
		remove: removePhone
	} = useFieldArray({
		control,
		name: PHONES
	});

	const {
		fields: linkFields,
		append: appendLink,
		remove: removeLink
	} = useFieldArray({
		control,
		name: EXTERNAL_LINKS
	});
	const { isDirty: isComponentDirty, errors } = formState;

	useEffect(() => {
		setProfileData(personalProfile);

		if (Boolean(personalProfile)) {
			const first_name = _get(personalProfile, "first_name", "");
			const last_name = _get(personalProfile, "last_name", "");
			const emails = _get(personalProfile, EMAILS, []);
			const phones = _get(personalProfile, PHONES, []);
			const external_links = _get(personalProfile, EXTERNAL_LINKS, []);
			reset({
				first_name,
				last_name,
				emails,
				phones,
				external_links
			});

			const errors = emails.map(() => null);

			setCanMerge(errors);
			setCanAddToPool(errors);
			setIsProfileAreadyInPool(errors);

			setIsNewEmailExists(false);
			setIsNewPhoneExists(false);
			setIsNewExternalProfileExists(false);
		}
	}, [personalProfile]);

	const emails = watch(EMAILS) || [];
	const phones = watch(PHONES) || [];
	const external_links = watch(EXTERNAL_LINKS) || [];

	usePersonalProfile(
		{
			id,
			tag: "personal_profile"
		},
		{
			enabled: updated,
			onSuccess: resolvedData => setProfileData(resolvedData.personal_profile)
		}
	);

	const [saveProfile, { isLoading: isSaving }] = useSaveProfile(() => {
		//TODO remove this after fixing button add new phone, the button doesn't show after save
		setIsNewPhoneExists(false);
		setEditMode(false);
	});

	const handleViewProfileButtonForMergeClick = index => {
		const { profile_id: profileToGo, removed_profile_id } = canMerge[index];

		setIsRedirection(true);
		scrollToTopView();

		if (profile_id === profileToGo) {
			viewProfileButtonClicked(removed_profile_id);
		} else {
			const email = emails[index].email;

			// to avoid the issue of showing dialog unsaved changes when we go to a profile for merge, we wait Component LeaveHook to take new prop isDirty with value false then we do the redirection
			setTimeout(() => {
				goToProfile(profileToGo, removed_profile_id, email);
			}, 200);
		}
	};

	const handleViewCandidateButtonClick = index => {
		const { profile_id } = canAddToPool[index];
		setIsRedirection(true);
		scrollToTopView();

		const email = emails[index].email;
		const params = {
			addToTalentPool: true,
			email,
			tab: CANDIDATE_TABS.PROFILE,
			profileTab: PROFILE_TABS.CANDIDATE_PROFILE,
			action: CANDIDATE_ACTIONS.ADD_TO_CANDIDATES
		};

		const stringified = stringify(params);
		const link = `/permanent/view/${profile_id}?${stringified}`;

		// to avoid the issue of showing dialog unsaved changes when we go to a profile for add to candidates, we wait component LeaveHook to take the new prop isDirty with value false then we do the redirection
		setTimeout(() => {
			historyPush(link);
		}, 200);
	};

	const handleViewCandidateAlreadyInPoolButtonClick = index => {
		const { profile_id } = isProfileAreadyInPool[index];
		setIsRedirection(true);
		scrollToTopView();

		const params = {
			profileAlreadyInPool: true,
			tab: CANDIDATE_TABS.PROFILE,
			profileTab: PROFILE_TABS.CANDIDATE_PROFILE,
			action: CANDIDATE_ACTIONS.CANDIDATE_ALREADY_POOL
		};
		const stringified = stringify(params);
		const link = `/permanent/view/${profile_id}?${stringified}`;

		// to avoid the issue of showing dialog unsaved changes when we go to a profile to check, we wait component LeaveHook to take the new prop isDirty with value false then we do the redirection
		setTimeout(() => {
			historyPush(link);
		}, 200);
	};

	const goToProfile = (id, profileIdToDelete, email) => {
		const params = {
			profileIdToDelete,
			email,
			tab: CANDIDATE_TABS.PROFILE,
			profileTab: PROFILE_TABS.CANDIDATE_PROFILE,
			action: CANDIDATE_ACTIONS.MERGE
		};
		const stringified = stringify(params);
		const link = `/permanent/view/${id}?${stringified}`;

		historyPush(link);
	};

	const scrollToTopView = () => {
		const elements = document.getElementsByClassName("page_container");

		if (elements[0]) {
			elements[0].scrollIntoView();
		}
	};

	const handleCancel = () => {
		if (Boolean(profileData)) {
			const first_name = _get(profileData, "first_name", "");
			const last_name = _get(profileData, "last_name", "");
			const emails = _get(profileData, EMAILS, []);
			const phones = _get(profileData, PHONES, []);
			const external_links = _get(profileData, EXTERNAL_LINKS, []);
			reset({
				first_name,
				last_name,
				emails,
				phones,
				external_links
			});
		}
		clearErrors();
		setEditMode(false);
		resetErrors();
	};

	const resetErrors = () => {
		const emails = _get(personalProfile, EMAILS, []);
		const errors = emails.map(() => null);

		setCanMerge(errors);
		setCanAddToPool(errors);
		setIsProfileAreadyInPool(errors);
	};

	const handleSave = () => {
		const isFormValid = Object.keys(errors).length === 0;

		if (!isFormValid) {
			toaster.danger("Some fields have errors and couldn’t be saved");
			return;
		}

		const mappedEmails = mapFields(emails, "email", emailFields);
		const mappedPhones = mapFields(phones, "phone", phoneFields);
		const mappedLinks = mapFields(external_links, "link", linkFields);

		const data = watch();
		saveProfile({
			cv_id,
			fields_data: {
				tag: "personal_profile",
				value: {
					...data,
					phones: mappedPhones,
					emails: mappedEmails,
					external_links: mappedLinks
				}
			}
		});
	};

	function handleEditButtonClick() {
		setEditMode(true);
		clearErrors();
		setIsNewEmailExists(false);
		setIsNewPhoneExists(false);
		setIsNewExternalProfileExists(false);
	}

	const handleInputEmailKeyDown = event => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleInputEmailSubmit();
		}
	};

	const handleInputEmailBlur = () => {
		handleInputEmailSubmit();
	};

	const handleInputEmailSubmit = async () => {
		if (
			changedValue.fields === EMAILS &&
			emails[changedValue.index]?.email &&
			!_get(errors, `emails[${changedValue.index}].email.message`)
		) {
			try {
				const index = changedValue.index;
				//TODO change tag to  internal_data
				const result = await checkActivateAccount({
					email: emails[changedValue.index].email,
					tag: "internal_data",
					profile_id: profile_id
				});

				handleCheckEmail(
					result,
					index,
					setError,
					canMerge,
					setCanMerge,
					clearErrors,
					canAddToPool,
					setCanAddToPool,
					isProfileAreadyInPool,
					setIsProfileAreadyInPool,
					profile_id
				);

				if (!result?.exists) {
					const mappedEmails = mapFields(emails, "email", emailFields);
					if (isEditMode) return;

					saveProfile({
						cv_id,
						fields_data: {
							tag: "personal_profile",
							value: {
								emails: mappedEmails
							}
						}
					}).then(() => {
						setDisabledFields(prev => ({
							...prev,
							emails: [
								..._get(prev, EMAILS, []),
								emailFields[changedValue.index]?.email
							]
						}));

						setIsNewEmailExists(false);
						resetErrors();
						setError(`emails[${changedValue.index}].email`, {});
					});
				}
			} catch (error) {
				setError(`emails[${changedValue.index}].email`, {
					type: "manual",
					message: _get(error, "detail.email[0]", "Invalid email address.")
				});
			}
		}
	};

	const handleInputPhoneKeyDown = event => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleInputPhoneSubmit();
		}
	};

	const handleInputPhoneBlur = () => {
		handleInputPhoneSubmit();
	};

	const handleInputPhoneSubmit = async () => {
		if (
			changedValue.fields === PHONES &&
			phones[changedValue.index]?.phone?.length > 8 &&
			!_get(errors, `phones[${changedValue.index}].phone.message`)
		) {
			try {
				await checkPhoneFormat({
					phone: phones[changedValue.index]?.phone
				});

				if (isEditMode) return;

				if (!_get(errors, `phones[${phoneFields.length - 1}].phone.message`)) {
					const mappedPhones = mapFields(phones, "phone", phoneFields);

					saveProfile({
						cv_id,
						fields_data: {
							tag: "personal_profile",
							value: {
								phones: mappedPhones
							}
						}
					}).then(() => {
						setDisabledFields(prev => ({
							...prev,
							phones: [
								..._get(prev, PHONES, []),
								phoneFields[changedValue.index]?.id
							]
						}));

						setIsNewPhoneExists(false);
					});
					clearErrors(`phones[${changedValue.index}].phone`);
				}
			} catch (error) {
				setError(`phones[${changedValue.index}].phone`, {
					type: "manual",
					message: _get(
						error,
						"detail.phone[0]",
						"The input does not match a phone number format"
					)
				});
			}
		}
	};

	const handleInputExternalLinkKeyDown = event => {
		if (event.key === "Enter" && !isEditMode) {
			event.preventDefault();
			handleInputExternalLinkSubmit();
		}
	};

	const handleInputExternalLinkBlur = () => {
		if (!isEditMode) {
			handleInputExternalLinkSubmit();
		}
	};

	const handleInputExternalLinkSubmit = async () => {
		if (
			changedValue.fields === EXTERNAL_LINKS &&
			external_links[changedValue.index]?.link &&
			!_get(errors, `external_links[${changedValue.index}].link.message`)
		) {
			const mappedLinks = mapFields(external_links, "link", linkFields);

			saveProfile({
				cv_id,
				fields_data: {
					tag: "personal_profile",
					value: {
						external_links: mappedLinks
					}
				}
			}).then(() => {
				setDisabledFields(prev => ({
					...prev,
					external_links: [
						..._get(prev, EXTERNAL_LINKS, []),
						linkFields[changedValue.index]?.id
					]
				}));
				setIsNewExternalProfileExists(false);
			});
		}
	};

	const handleEmailInputChange = index => {
		setChangedValue({ index, fields: EMAILS });

		const canMergeUpdated = canMerge.map((mergeItem, i) => {
			if (index !== i) return mergeItem;
			else return null;
		});

		setCanMerge(canMergeUpdated);

		const canAddToPoolUpdated = canAddToPool.map((item, i) => {
			if (index !== i) return item;
			else return null;
		});

		setCanAddToPool(canAddToPoolUpdated);

		const alreadyInPoolUpdated = isProfileAreadyInPool.map((item, i) => {
			if (index !== i) return item;
			else return null;
		});

		setIsProfileAreadyInPool(alreadyInPoolUpdated);
	};

	const handleDeleteEmailClick = (e, index) => {
		e.preventDefault();
		removeEmail(index);

		const canMergeUpdated = [
			...canMerge.slice(0, index),
			...canMerge.slice(index + 1)
		];

		setCanMerge(canMergeUpdated);

		const canAddToPoolUpdated = [
			...canAddToPool.slice(0, index),
			...canAddToPool.slice(index + 1)
		];

		setCanAddToPool(canAddToPoolUpdated);

		const alreadyInPoolUpdated = [
			...isProfileAreadyInPool.slice(0, index),
			...isProfileAreadyInPool.slice(index + 1)
		];

		setIsProfileAreadyInPool(alreadyInPoolUpdated);
	};

	const handleAddEmailClick = e => {
		e.preventDefault();
		appendEmail({
			email: "",
			is_primary: emailFields.length === 0
		});
		const canMergeUpdated = [...canMerge, null];

		setCanMerge(canMergeUpdated);

		const canAddToPoolUpdated = [...canAddToPool, null];

		setCanAddToPool(canAddToPoolUpdated);

		const alreadyInPool = [...isProfileAreadyInPool, null];

		setIsProfileAreadyInPool(alreadyInPool);

		setIsNewEmailExists(true);
	};

	const handlePrimaryEmailClick = index => {
		setAsPrimary({
			cv_id,
			entity: EMAILS,
			email: emails[index].email
		});
	};

	const handlePhoneDelete = (e, index) => {
		e.preventDefault();
		removePhone(index);
	};

	const handlePrimaryPhoneClick = index => {
		setAsPrimary({
			cv_id,
			entity: PHONES,
			phone: phones[index].phone
		});
	};

	const handlePhoneChange = (country, formattedValue, index, onChange) => {
		const phoneValue = `${country.countryCode.toUpperCase()} ${formattedValue.replace(
			/\s/g,
			""
		)}`;
		phoneValue !== phones[index].phone &&
			setChangedValue({
				index,
				fields: PHONES
			});
		onChange(phoneValue);
	};

	const handlePhoneAdd = e => {
		e.preventDefault();
		appendPhone({
			phone: "",
			is_primary: phoneFields.length === 0
		});
		setIsNewPhoneExists(true);
	};

	const handleLinkDelete = (e, index) => {
		e.preventDefault();
		removeLink(index);
	};

	const handleLinkChange = index => {
		setChangedValue({ index, fields: EXTERNAL_LINKS });
	};

	const handleLinkPrimaryChange = (e, index) => {
		setAsPrimary({
			//TODO verify this monday whether is working or not, the problem is api is not working
			cv_id,
			entity: EXTERNAL_LINKS,
			link: external_links[index].link
		});
	};

	const handleLinkAdd = e => {
		e.preventDefault();
		appendLink({
			link: "",
			is_primary: external_links.length === 0
		});
		setIsNewExternalProfileExists(true);
	};

	return (
		<LeaveHook
			dirty={isComponentDirty && !isUnsavedChangesDialogOpen && !isRedirection}
			onOpen={toggleUnsavedChangesDialogOpen}
			onStay={toggleUnsavedChangesDialogOpen}
			onLeaveClick={toggleUnsavedChangesDialogOpen}
		>
			<EditableSection
				title="Personal profile"
				isEdit={isEditMode}
				onEdit={handleEditButtonClick}
				onSave={handleSave}
				onCancel={handleCancel}
				isLoading={isSaving}
			>
				{!isLoading ? (
					<form className={styles.personalProfile}>
						<>
							<div className={cardStyle.grid}>
								<FirstNameField
									errors={errors}
									isEditMode={isEditMode}
									register={register}
								/>
								<LastNameField
									errors={errors}
									isEditMode={isEditMode}
									register={register}
								/>
							</div>
							<EmailFields
								canMerge={canMerge}
								personalProfile={personalProfile}
								canAddToPool={canAddToPool}
								isProfileAreadyInPool={isProfileAreadyInPool}
								onChange={handleEmailInputChange}
								onDelete={handleDeleteEmailClick}
								register={register}
								onViewProfileMergeClick={handleViewProfileButtonForMergeClick}
								onAddToPoolClick={handleViewCandidateButtonClick}
								onViewAlreadyInPoolClick={
									handleViewCandidateAlreadyInPoolButtonClick
								}
								onAddEmailClick={handleAddEmailClick}
								emailFields={emailFields}
								isEditMode={isEditMode}
								isNewEmailExists={isNewEmailExists}
								onBlur={handleInputEmailBlur}
								onKeyDown={handleInputEmailKeyDown}
								errors={errors}
								control={control}
								onEmailPrimaryClick={handlePrimaryEmailClick}
								emails={emails}
							/>
							<PhoneFields
								personalProfile={personalProfile}
								isEditMode={isEditMode}
								errors={errors}
								control={control}
								phones={phones}
								phoneFields={phoneFields}
								onPhoneDelete={handlePhoneDelete}
								isNewPhoneExists={isNewPhoneExists}
								onBlur={handleInputPhoneBlur}
								onKeyDown={handleInputPhoneKeyDown}
								onPrimaryPhoneClick={handlePrimaryPhoneClick}
								onPhoneAdd={handlePhoneAdd}
								onChange={handlePhoneChange}
							/>
							<LinkFields
								isEditMode={isEditMode}
								errors={errors}
								control={control}
								external_links={external_links}
								onDelete={handleLinkDelete}
								isNewExternalProfileExists={isNewExternalProfileExists}
								onBlur={handleInputExternalLinkBlur}
								onKeyDown={handleInputExternalLinkKeyDown}
								onPrimaryLinkClick={handleLinkPrimaryChange}
								onAdd={handleLinkAdd}
								onChange={handleLinkChange}
								register={register}
								linkFields={linkFields}
								personalProfile={personalProfile}
							/>
						</>
					</form>
				) : (
					<PersonalProfileLoading />
				)}
			</EditableSection>
		</LeaveHook>
	);
};

export default profileData;
