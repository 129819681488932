import React, { useState } from "react";
import { useDebounce } from "react-use";
import styles from "common/CustomInput/custom-input.module.scss";

const NumberInput = ({ onChange, propsRest }) => {
	const [number, setNumber] = useState(1);

	useDebounce(
		() => {
			handleNumberChange(number);
		},
		1000,
		[number]
	);

	const handleNumberChange = value => {
		onChange(value);
	};

	return (
		<input
			type={"number"}
			min={"0"}
			onChange={event => {
				setNumber(event.target.value);
			}}
			value={number}
			className={styles.input}
			{...propsRest}
		/>
	);
};

export default NumberInput;
