import styled from "styled-components";

export const ButtonCSV = styled.button`
	background: #ff6849;
	margin: 0px 5px;
	border-radius: 3px;
	padding: 12px 22px;
	width: 83px;
	height: 46px;
	border-radius: 2px;
	border: none;

	outline: 0;
	align-items: center;
	display: flex;
	justify-content: center;

	> span {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 22px;
		color: #fff;
		margin-left: 8px;
	}
`;

export const DownloadFile = styled.div`
	display: flex;
	justify-content: space-between;
	width: 11%;
	.download-xls {
		background: ${({ excelLink }) => (excelLink ? "#44CB00" : "#d9dbe9")};
		margin: 0px 5px;
		border-radius: 3px;
		padding: 12px 22px;
		width: 83px;
		height: 46px;
		border: none;
		outline: 0;
		align-items: center;
		display: flex;
		justify-content: center;
		text-decoration: none;
		> span {
			font-family: "BasierCircle";
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 22px;
			color: ${({ excelLink }) => (excelLink ? "#ffffff" : "#a0a3bd")};
			margin-left: 8px;
		}
	}
	.loading-xls {
		margin: 0px 5px;
		padding: 0px 22px;
		width: 83px;
		height: 46px;
		border: none;
		outline: 0;
	}
	.rc-tooltip-placement-topRight .rc-tooltip-arrow {
		right: 35% !important;
	}
`;
