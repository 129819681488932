import get from "lodash/get";
import React from "react";
import { Text, Box } from "rebass";
import BoxContainer from "../boxContainer";
import ListNotes from "./listNotes";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Notes = ({
	isDrag,
	setIsOpenModalTab,
	setActiveTab,
	notes,
	isFetching,
	getNotesLoading
}) => {
	return (
		<BoxContainer
			setIsOpenModalTab={setIsOpenModalTab}
			setActiveTab={setActiveTab}
			isDrag={isDrag}
			title="Notes"
			isFetching={isFetching || getNotesLoading}
		>
			{isFetching || getNotesLoading ? (
				<React.Fragment>
					<Box mb={3}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={100} height={14} />
						</SkeletonTheme>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={200} height={14} />
						</SkeletonTheme>
					</Box>

					<Box mb={3}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={100} height={14} />
						</SkeletonTheme>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={300} height={14} />
						</SkeletonTheme>
					</Box>
					<Box mb={3}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={100} height={14} />
						</SkeletonTheme>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={200} height={14} />
						</SkeletonTheme>
					</Box>
				</React.Fragment>
			) : (
				<React.Fragment>
					{notes.length > 0 ? (
						notes.map((note, index) => (
							<ListNotes
								description={get(note, "description")}
								fullName={
									get(note, "created_by.first_name") +
									" " +
									get(note, "created_by.last_name")
								}
								createdAt={get(note, "created_at")}
								isLast={index === notes.length - 1}
								isFirst={index === 0}
								key={get(note, "created_by.last_name")}
							/>
						))
					) : (
						<Text
							sx={{ fontSize: "16px", color: "#818a92", fontWeight: "normal" }}
						>
							No Notes to show
						</Text>
					)}
				</React.Fragment>
			)}
		</BoxContainer>
	);
};

export default Notes;
