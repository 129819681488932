/* eslint-disable*/
import React, { useRef, useMemo, useEffect } from "react";
import cx from "classnames";
import { useTour } from "@reactour/tour";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { SELECTORS_TOUR_USER_AND_DEPARTEMENT } from "modules/user-onboarding/workflows/create-users-departments";
import { TOUR_USER_AND_DEPARTEMENT } from "config/onboarding";
import { Button as CustomButton } from "common/Button";

const useTourData = () => {
	const tourFirstStepRef = useRef(null);
	const { workflowId } = useOnboardingStore();
	const { setIsOpen, currentStep, isOpen } = useTour();
	const isActiveSetupCompanyTour = useMemo(
		() => workflowId === TOUR_USER_AND_DEPARTEMENT,
		[workflowId]
	);
	useEffect(() => {
		if (tourFirstStepRef.current && isActiveSetupCompanyTour) {
			if (currentStep === 1) {
				setIsOpen(true);
			}
		}
	}, [tourFirstStepRef, isActiveSetupCompanyTour, currentStep]);

	const closeTour = () => {
		if (isActiveSetupCompanyTour) setIsOpen(false);
	};

	const goNextTourStep = () => {
		if (isActiveSetupCompanyTour) browserHistory.push("/settings/users");
	};

	return {
		closeTour,
		goNextTourStep,
		tourFirstStepRef,
		selectors: {
			first: SELECTORS_TOUR_USER_AND_DEPARTEMENT[0],
			second: SELECTORS_TOUR_USER_AND_DEPARTEMENT[1]
		},
		selectorsClassNames: {
			first:
				isActiveSetupCompanyTour && isOpen && currentStep === 0
					? " highlighted"
					: "",
			second:
				isActiveSetupCompanyTour && isOpen && currentStep === 1
					? " highlighted"
					: ""
		}
	};
};

const TourButton = ({
	disabled,
	text,
	icon,
	variant,
	id,
	className,
	onClick
}) => {
	const { closeTour, tourFirstStepRef, selectorsClassNames } = useTourData();

	return (
		<CustomButton
			ref={tourFirstStepRef}
			onClick={() => {
				onClick();
				closeTour();
			}}
			disabled={disabled}
			text={text}
			icon={icon}
			variant={variant}
			id={id}
			className={cx(
				className,
				selectorsClassNames.first,
				selectorsClassNames.second
			)}
		/>
	);
};

export default TourButton;
