import React, { useRef, useState } from "react";
import styles from "./evaluations-list-by-category.module.scss";
import EvaluationsListItem from "../EvaluationsListItem";
import upperFirst from "lodash/upperFirst";
import lowerCase from "lodash/lowerCase";
import useInfiniteScroll from "modules/EvaluationFormsSettings/hooks/useInfiniteScroll";
import {
	TEMPLATES_LIST_LIMIT,
	useGetEvaluationTemplates
} from "modules/EvaluationFormsSettings/api/useGetEvaluationTemplates";
import useTemplateSearchKeyword from "modules/EvaluationFormsSettings/hooks/useTemplateSearchKeyword";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import useDeleteEvaluationTemplate from "../../api/useDeleteEvaluationTemplate";
import loadable from "loadable-components";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { GET_TEMPLATES_QUERY_KEY } from "modules/EvaluationFormsSettings/api/useGetEvaluationTemplates";
import { withRouter, browserHistory } from "react-router";
import { getEvaluationTemplateRoute } from "modules/EvaluationFormsSettings/EvaluationFormsSettings";
import useDuplicateEvaluationTemplate from "../../api/useDuplicateEvaluationTemplate";
import { LoaderFull } from "common/Loader";
import { v4 as uuid } from "uuid";

const ConfirmationModal = loadable(() =>
	import(/* webpackChunkName: "confirmation-modal" */ "../ConfirmationModal")
);

const EvaluationsListByCategory = withRouter(
	({ categoryName, initialData, params: { templateId }, hasSibling }) => {
		const observerTarget = useRef(null);
		const { keywords } = useTemplateSearchKeyword();
		const reduceData = data =>
			data.reduce((acc, val) => [...acc, ...val[categoryName].data], []);
		const {
			data,
			isFetchingMore,
			fetchMore,
			canFetchMore
		} = useGetEvaluationTemplates(
			{
				search: keywords.trim(),
				tag: "by_category",
				category: categoryName
			},
			{
				initialData: [{ [categoryName]: initialData }],
				getFetchMore: (lastGroup, allData) => {
					if (reduceData(allData).length === lastGroup[categoryName].total) {
						return false;
					}
					return lastGroup[categoryName].offset + TEMPLATES_LIST_LIMIT;
				}
			}
		);
		useInfiniteScroll({
			observerTarget,
			callback: () => {
				if (!isFetchingMore) {
					fetchMore();
				}
			}
		});

		const [
			duplicateEvaluation,
			{ isLoading: isDuplicateLoading }
		] = useDuplicateEvaluationTemplate();
		const onItemDuplicate = item => {
			const newId = uuid();
			duplicateEvaluation(
				{
					id: item._id,
					new_template_id: newId
				},
				{
					onSuccess() {
						toaster.success("Evaluation template successfully duplicated.");
						queryCache.invalidateQueries([
							GET_TEMPLATES_QUERY_KEY,
							{ tag: "by_category", category: categoryName }
						]);
						browserHistory.replace(getEvaluationTemplateRoute(newId));
					}
				}
			);
		};

		const [itemToDelete, setItemToDelete] = useState(null);
		const [
			deleteEvaluation,
			{ isLoading: isDeleteLoading }
		] = useDeleteEvaluationTemplate({
			onSuccess() {
				toaster.success("Evaluation template successfully deleted.");
				queryCache.invalidateQueries([
					GET_TEMPLATES_QUERY_KEY,
					{ tag: "by_category", category: categoryName }
				]);
				if (templateId === itemToDelete._id) {
					browserHistory.replace(getEvaluationTemplateRoute(""));
				}
				setItemToDelete(null);
			}
		});
		const onItemDelete = item => {
			setItemToDelete(item);
		};
		const onConfirmDelete = () => {
			deleteEvaluation({
				id: itemToDelete._id
			});
		};

		return (
			<div
				className={hasSibling ? styles.containerWithSibling : styles.container}
			>
				<div className={styles.header}>
					{upperFirst(lowerCase(categoryName))}
				</div>
				<div>
					{reduceData(data).map(item => {
						return (
							<EvaluationsListItem
								key={item._id}
								item={item}
								onClickDuplicate={() => onItemDuplicate(item)}
								onClickDelete={() => onItemDelete(item)}
							/>
						);
					})}
					{canFetchMore && (
						<div
							ref={observerTarget}
							className={styles.infiniteLoadingIndicator}
						>
							{isFetchingMore && <AnimatedLoaderIcon width={20} />}
						</div>
					)}
				</div>

				{!!itemToDelete && (
					<ConfirmationModal
						title="Delete evaluation"
						description={
							<div>
								Are you sure you want to delete&nbsp;
								<span className={styles.templateName}>
									{itemToDelete.template_name}
								</span>
							</div>
						}
						confirmButtonText="Delete"
						onClose={() => setItemToDelete(null)}
						onConfirm={onConfirmDelete}
						isLoading={isDeleteLoading}
					/>
				)}

				{isDuplicateLoading && <LoaderFull />}
			</div>
		);
	}
);

export default EvaluationsListByCategory;
