import create from "zustand";
import { devtools } from "zustand/middleware";

const initialState = {
	drawerId: "",
	drawerProps: null,
	startMissionModal: {
		isOpen: false,
		payload: null
	}
};

const RequestViewDrawerStore = set => ({
	...initialState,
	setDrawerId: drawerId => set(state => ({ ...state, drawerId })),
	setDrawerProps: drawerProps => set(state => ({ ...state, drawerProps })),

	toggleStartMissionModal: isOpen =>
		set(state => ({
			...state,
			startMissionModal: {
				...state.startMissionModal,
				isOpen
			}
		})),
	setStartMissionModalPayload: payload =>
		set(state => ({
			...state,
			startMissionModal: {
				...state.startMissionModal,
				payload
			}
		})),

	cleanDrawerStore: () => set(() => initialState)
});

const useRequestViewDrawerStore = create(devtools(RequestViewDrawerStore));
export default useRequestViewDrawerStore;
