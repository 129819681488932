import React from "react";
import arrowIcon from "static/icons/drop-down-chevron.svg";
import styles from "./menu.module.scss";

const Menu = ({ onMenuItemHover, menu, onCategoryClick }) => {
	return (
		<div className={styles.items}>
			{menu.map(item => {
				const { label, id, children } = item;
				const hasChildren = children?.length > 0;

				return (
					<div
						key={id}
						onMouseEnter={() => onMenuItemHover(id)}
						onClick={() => {
							hasChildren ? null : onCategoryClick(item);
						}}
						className={styles.menuItemWrapper}
					>
						<div className={styles.text}>{label}</div>
						{hasChildren && (
							<img className={styles.arrowIcon} src={arrowIcon} />
						)}
					</div>
				);
			})}
		</div>
	);
};

export default Menu;
