import React from "react";
import styles from "./email-signature-preview.module.scss";
import { ReactComponent as LinkedinIcon } from "static/icons/social/linkedin.svg";
import { ReactComponent as FacebookIcon } from "static/icons/social/facebook.svg";
import { ReactComponent as XIcon } from "static/icons/social/x.svg";
import { ReactComponent as InstagramIcon } from "static/icons/social/instagram.svg";
import { ReactComponent as PhoneIcon } from "static/icons/email-signature/phone.svg";
import { ReactComponent as EmailIcon } from "static/icons/email-signature/email.svg";
import { ReactComponent as HomeIcon } from "static/icons/email-signature/home.svg";
import { useFormContext } from "react-hook-form";

const icons = {
	linkedin: <LinkedinIcon width={24} height={24} />,
	facebook: <FacebookIcon width={24} height={24} />,
	twitter: <XIcon width={24} height={24} />,
	instagram: <InstagramIcon width={24} height={24} />
};

export default function EmailSignaturePreview() {
	const { watch } = useFormContext();

	const {
		avatar,
		full_name,
		position,
		phone,
		email,
		address,
		social_media
	} = watch();

	const isPhoneEmpty = !phone?.value || !phone.countryCode;

	return (
		<div className={styles.container}>
			<div className={styles.card}>
				<div className={styles.header}>
					<div>
						<span className={styles.boldText}>To:</span> Your receipent
					</div>
					<div>
						<span className={styles.boldText}>Subject:</span> Check my awesome
						email signature
					</div>
				</div>
				<div className={styles.content}>
					<div className={styles.contentContainer}>
						{!!avatar && (
							<div className={styles.avatar}>
								<img src={avatar} />
							</div>
						)}
						<div>
							<div className={styles.fullName}>{full_name}</div>
							{!!position && <div className={styles.position}>{position}</div>}
							{!isPhoneEmpty && (
								<div className={styles.info}>
									<div className={styles.icon}>
										<PhoneIcon />
									</div>
									<div>{phone.value}</div>
								</div>
							)}
							{!!email && (
								<div className={styles.info}>
									<div className={styles.icon}>
										<EmailIcon />
									</div>
									<div>{email}</div>
								</div>
							)}
							{!!address && (
								<div className={styles.info}>
									<div className={styles.icon}>
										<HomeIcon />
									</div>
									<div>{address}</div>
								</div>
							)}
							<div className={styles.socialIcons}>
								{social_media.map(({ url, platform }) => {
									if (!url) return null;
									else
										return (
											<a
												key={platform}
												href={url}
												target="_blank"
												rel="noreferrer"
											>
												{icons[platform]}
											</a>
										);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
