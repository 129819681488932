import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import _find from "lodash/find";
import _has from "lodash/has";
import FormField from "common/FormField";
import Modal from "common/modal";
import StyledSelect from "common/StyledSelect";
import { rateOptions } from "./AddWorkTime";
import DatePickerField from "common/DatePickerField";
import { getIconType } from "./ListRenderer";
import { FileIcon } from "./TimesheetStyled";
import CurrencyField from "common/NewCurrencyField";
import { EUR } from "config";
import {
	timesheetTagsSelectSelector,
	timesheetTagsProjectNameSelector
} from "../timesheetSelector";

const validateComponent = value => {
	if (typeof value !== "number" && !value) return "Field is required!";
};

const haveFieldsChanged = (initial, current) => {
	const sameDates =
		window.moment.unix(initial.date).format("YYYY-MM-DD") ===
		window.moment(current.date).format("YYYY-MM-DD");
	const sameHours = initial.hours_representation
		? initial.hours_representation === current.hours.format("HH:mm")
		: true;
	const samePrice = initial.price ? initial.price === current.price : true;
	const sameTag = initial.tag ? initial.tag === current.tag : true;
	const currentRate = initial.rate
		? typeof current.rate === "object"
			? current.rate.value
			: current.rate
		: undefined;
	if (
		!sameDates ||
		!sameHours ||
		!samePrice ||
		!sameTag ||
		(initial.rate && initial.rate !== currentRate) ||
		initial.description !== current.description ||
		initial.comments !== current.comments
	) {
		return true;
	}
	return false;
};

let DisputedTimesheetModal = ({
	view,
	dirty,
	editItem,
	disputedModal,
	toggleDisputedModal,
	handleSubmit,
	isFreelancer,
	isInMediation,
	data: { start_date, end_date },
	isDisputed,
	acceptItem,
	refuseItem,
	dispute,
	initialValue,
	currentValue,
	tagOptions,
	timesheetTagName,
	reportingSettings
}) => {
	const isWorktime = view === "worktime";

	const listName = isWorktime ? "worktimes" : "expenses";

	const doAccept = ({ _id }) => {
		return acceptItem(_id, listName);
	};

	const doRefuse = ({ _id }) => {
		return refuseItem(_id, listName);
	};

	const _editItem = fields => {
		editItem({
			...fields,
			list: listName,
			view_id: isWorktime ? "worktime_id" : "expense_id"
		});
	};

	const haveValuesChanged = dirty
		? haveFieldsChanged(initialValue, currentValue)
		: false;

	const disabled = isInMediation || (isFreelancer ? true : isDisputed);

	const getDisputedClass = field =>
		_has(dispute, field) ? "disputed-item" : "";

	return (
		<Modal
			active={disputedModal}
			onClose={toggleDisputedModal}
			title={
				isFreelancer
					? isDisputed
						? "Review correction"
						: "Client comment"
					: `Dispute ${view}`
			}
			firstButton={{
				action: isFreelancer ? handleSubmit(doRefuse) : toggleDisputedModal,
				label: isFreelancer ? "Refuse" : "Back",
				disabled: isInMediation,
				type: "outlined",
				large: true
			}}
			secondButton={{
				action: handleSubmit(isFreelancer ? doAccept : _editItem),
				label: isFreelancer ? "Accept" : "Save",
				disabled: isInMediation || (isFreelancer ? false : !haveValuesChanged),
				type: "primary",
				large: true
			}}
		>
			<div className="content">
				<div className="row">
					<div className="col-md-11 text-block margin-30">
						{isFreelancer
							? "This item has been corrected by the client. You’ll find his/her correction below (red) as well as the related comment."
							: "Please edit the fields where applicable, then add your comments"}
					</div>
				</div>
				<div className="row">
					<div className="col-md-9">
						<Field
							name="date"
							label="Date"
							validate={validateComponent}
							component={DatePickerField}
							minDate={start_date}
							maxDate={end_date}
							disabled={disabled}
							inputClassName={getDisputedClass("date")}
						/>
					</div>
				</div>

				<div className="row" key="hours">
					{!isWorktime ? (
						<div className="col-md-4">
							<Field
								name="document"
								component={({ input: { value } }) => (
									<div className="form-group">
										<label htmlFor="receipt">Receipt</label>
										{value ? (
											<a
												href={value}
												className="file-icon"
												rel="noopener noreferrer"
												target="_blank"
											>
												<FileIcon className={getIconType(value)} block />
											</a>
										) : (
											<div>
												<strong>N/A</strong>
											</div>
										)}
										<div className="help-block inline-error" />
									</div>
								)}
							/>
						</div>
					) : (
						<div
							className={`col-md-${currentValue.tag ? "4" : "9"}`}
							style={{ width: 180 }}
						>
							<Field
								name="hours"
								label="# of hours"
								validate={validateComponent}
								component={FormField}
								showSecond={false}
								disabled={disabled}
								type="timepicker"
								className={getDisputedClass("hours")}
							/>
						</div>
					)}
					{currentValue.tag && (
						<div className="col-md-5">
							<Field
								name="tag"
								label={timesheetTagName}
								validate={validateComponent}
								options={tagOptions}
								component={StyledSelect}
								disabled={disabled}
								className={getDisputedClass("tag")}
							/>
						</div>
					)}
				</div>

				{isWorktime && (
					<div className="row" key="rate">
						<div className="col-md-9">
							<Field
								name="rate"
								label="Rate"
								validate={validateComponent}
								options={rateOptions()}
								component={StyledSelect}
								disabled={disabled}
								className={getDisputedClass("rate")}
							/>
						</div>
					</div>
				)}
				{!isWorktime && (
					<div className="row" key="amount">
						<div className="col-md-9">
							<Field
								name="price"
								disabled={disabled}
								validate={validateComponent}
								type="text"
								placeholder="Amount"
								label="Amount"
								position="bottom"
								suffix={reportingSettings?.currency?.symbol ?? EUR}
								component={CurrencyField}
								className={getDisputedClass("price")}
								saveOnChange
							/>
						</div>
					</div>
				)}
				<div className="row">
					<div className="col-md-11">
						<Field
							name="description"
							label="Description"
							component={FormField}
							type="textarea"
							disabled={disabled}
							validate={validateComponent}
							className={getDisputedClass("description")}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-11">
						<Field
							name="comments"
							label="Comments"
							component={FormField}
							type="textarea"
							disabled={disabled}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

const selector = formValueSelector("disputed-timesheet");

const mapStateToProps = state => {
	return {
		tagOptions: timesheetTagsSelectSelector(state),
		timesheetTagName: timesheetTagsProjectNameSelector(state),
		dispute: selector(state, "dispute"),
		initialValue: _find(state.timesheet.initialData, {
			_id: selector(state, "_id")
		}),
		currentValue: {
			date: selector(state, "date"),
			hours: selector(state, "hours"),
			rate: selector(state, "rate"),
			tag: selector(state, "tag"),
			price: selector(state, "price"),
			document: selector(state, "document"),
			description: selector(state, "description"),
			comments: selector(state, "comments")
		}
	};
};

DisputedTimesheetModal = reduxForm({
	form: "disputed-timesheet",
	touchOnBlur: false
})(DisputedTimesheetModal);

export default connect(mapStateToProps)(DisputedTimesheetModal);
