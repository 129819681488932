import React, { useState } from "react";
import _get from "lodash/get";
import _find from "lodash/find";
import { formatDate } from "common/Functions";
import HandshakeIcon from "common/Icons/HandshakeIcon";
import {
	INTERNAL_NOTE,
	USER_HIREME_NAME,
	BIDS_HISTORY_LABELS,
	REQUEST_SENT
} from "config";

import {
	HistoryContainer,
	EmptyHistory,
	Table,
	LinkButton,
	RedirectButton
} from "./Styled";
import NotesModal from "modules/bids/components/modals/ViewNotesModal";
import { getClientBidViewRoute } from "modules/bids/components/bidview/client/ClientBidViewContainer";
import { historyPush } from "config/helpers";

export default function History({ history }) {
	const [viewNoteData, setViewNoteData] = useState(undefined);

	const handleViewNote = item => {
		if (item.history_type === "bid") {
			setViewNoteData(item.client_notes);
		} else {
			setViewNoteData([
				{
					_id: item._id,
					user: {
						first_name: _get(item, "user_action.first_name"),
						last_name: _get(item, "user_action.last_name"),
						isSuperAdmin: _get(item, "user_action.is_super_admin")
					},
					created_at: item.created_at,
					description: item.note_data
				}
			]);
		}
	};

	const getHistoryLabel = item => {
		return (
			_get(
				_find(BIDS_HISTORY_LABELS, bid => bid.value === item),
				"label"
			) || ""
		);
	};

	const handleRedirectVacancy = item => {
		const bidId = _get(item, "_id");
		const jobId = _get(item, "job_id");
		if (_get(item, "history_type") === REQUEST_SENT) {
			historyPush(`/job/${jobId}`);
		} else {
			historyPush(getClientBidViewRoute(bidId));
		}
	};

	return (
		<HistoryContainer>
			{history && history.length > 0 ? (
				<>
					<div className="has-been-poked">
						You’ll find below the details of the previous interactions.
					</div>
					<Table>
						<thead>
							<tr>
								<td>DATE</td>
								<td>USER</td>
								<td>TYPE</td>
								<td>FOR THE REQUEST</td>
								<td />
							</tr>
						</thead>
						<tbody>
							{history.map(item => {
								const superAdmin =
									_get(item, "user_action.is_super_admin") === true;

								let notesCounter;
								item.history_type === INTERNAL_NOTE
									? (notesCounter =
											_get(item, "note_data.length", 0) > 0 ? 1 : 0)
									: (notesCounter = _get(item, "client_notes.length", 0));

								return (
									<tr key={item._id}>
										<td>{formatDate(item.created_at)}</td>
										<td>
											{!superAdmin
												? `${_get(item, "user_action.first_name", "")} ${_get(
														item,
														"user_action.last_name",
														""
												  )}`
												: USER_HIREME_NAME}
										</td>
										<td>{getHistoryLabel(item.history_type)}</td>
										<td>
											{item.history_type === "internal_note" ? (
												"NA"
											) : (
												<RedirectButton
													onClick={() => {
														handleRedirectVacancy(item);
													}}
												>
													{_get(item, "job.title", "")}
												</RedirectButton>
											)}
										</td>
										<td>
											{notesCounter > 0 && (
												<LinkButton onClick={() => handleViewNote(item)}>
													{`view notes (${notesCounter})`}
												</LinkButton>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
					{viewNoteData && (
						<NotesModal
							notes={viewNoteData}
							onClose={() => setViewNoteData(undefined)}
						/>
					)}
				</>
			) : (
				<EmptyHistory>
					<HandshakeIcon />
					<p>
						This section will list all previous interactions between this
						candidate and any of your colleagues. <br />
						There has been no interaction yet.
					</p>
				</EmptyHistory>
			)}
		</HistoryContainer>
	);
}
