import React, { useEffect } from "react";
import Drawer from "rc-drawer";
import styles from "./mission-edit-extension-drawer.module.scss";
import { DatePicker } from "common/DatePicker";
import { Controller } from "react-hook-form";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { ReactComponent as CalendarPickerIcon } from "static/icons/calendar-icon-grey.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar2.svg";
import { ReactComponent as BookMark } from "static/icons/bookmark-icon.svg";
import { ReactComponent as SellingPriceIcon } from "static/icons/bank-note-icon.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle.svg";
import CurrencyField from "common/NewCurrencyField";
import { ReactComponent as MoneyBillIcon } from "static/icons/bank-bill-icon.svg";
import TextareaField from "../TextareaField";
import FieldTempError from "../FieldTempError";
import {
	convertCalendarDateToMS,
	renderError,
	shouldDisplayFreelancerCost,
	timestampToDateCalendar,
	utcTimeStamp
} from "config/helpers";
import cx from "classnames";
import { capitalize } from "config/helpers";
import { queryCache } from "react-query";
import toaster from "common/Toaster";
import { useIsDisplayCostEnabled } from "hooks/useIsDisplayCostEnabled";
import { getHourlyCost } from "common/Functions";
import { GET_MISSION_DETAILS_QUERY_KEY } from "modules/MissionsDetails/hooks/useGetMissionDetails";
import { MISSION_HISTORY_QUERY_KEY } from "modules/MissionsDetails/hooks/useGetMissionHistory";
import { MISSION_EXTENSIONS_QUERY_KEY } from "modules/MissionsDetails/hooks/useGetMissionExtensions";
import { getDates, getSuffix, mappingValues } from "./_helper";
import useEditMissionExtension from "modules/MissionsDetails/hooks/useEditMissionExtension";
import { useEditMissionForm } from "modules/MissionsDetails/hooks/useEditMissionForm";
import { getLocalTimeZone, today } from "@internationalized/date";
import {
	compareTimestamps,
	isSupplierMode
} from "modules/MissionsDetails/utils/_helper";
import { COMMENT_MAX_LENGTH } from "modules/MissionsDetails/utils/constant";
import Tooltip from "common/Tippy";
import { LoaderFull } from "common/Loader";

function MissionEditExtensionDrawer({
	isOpen,
	closeDrawer,
	extensionList,
	extensionSelected,
	vmsSettings,
	missionId,
	lastTimesheetDate,
	missionDetails
}) {
	const { startDate, endDate } = getDates(extensionSelected);
	const displayCostEnabled =
		useIsDisplayCostEnabled() && shouldDisplayFreelancerCost(missionDetails);
	const reporting_settings = extensionList.reporting_settings;

	const {
		control,
		formState: { isDirty, isSubmitting },
		errors,
		handleSubmit,
		watch,
		setValue
	} = useEditMissionForm({
		endDate,
		startDate,
		description: extensionSelected.description,
		price: extensionSelected.new_price,
		sellingPrice: extensionSelected.new_selling_price,
		canUserEdit: true,
		isSupplierMode: isSupplierMode(extensionSelected?.invoice_details)
	});

	const rate = watch("rate");
	const startDateValue = utcTimeStamp({
		date: convertCalendarDateToMS(watch("start_date"))
	});
	const currentDay = today(getLocalTimeZone());
	const currentDayToTimestamp = utcTimeStamp({
		date: convertCalendarDateToMS(currentDay)
	});

	let isStartDateDisabled = false;
	let startDateMinValue = lastTimesheetDate;

	if (startDate < currentDayToTimestamp || lastTimesheetDate > startDate) {
		isStartDateDisabled = true;
	}

	if (!lastTimesheetDate || startDate < lastTimesheetDate) {
		startDateMinValue = startDate;
	}

	useEffect(() => {
		if (!reporting_settings || !vmsSettings) return;
		const cost = getHourlyCost(rate, vmsSettings, reporting_settings);
		setValue("cost", Number(cost).toFixed(2));
	}, [rate, vmsSettings, reporting_settings]);

	const [editMissionExtension] = useEditMissionExtension({
		onSuccess: () => {
			toaster.success(`Extension edited`);
			queryCache.invalidateQueries(GET_MISSION_DETAILS_QUERY_KEY);
			queryCache.invalidateQueries(MISSION_HISTORY_QUERY_KEY);
			queryCache.invalidateQueries(MISSION_EXTENSIONS_QUERY_KEY);

			closeDrawer();
		},
		onError: renderError
	});

	const onSubmit = values => {
		const payload = mappingValues(
			values,
			missionId,
			extensionSelected,
			missionDetails
		);

		return editMissionExtension(payload);
	};

	return (
		<Drawer
			open={isOpen}
			width="650"
			height="100%"
			level="root"
			placement="right"
			maskClosable={true}
			onClose={closeDrawer}
		>
			<LoaderFull loading={isSubmitting} />
			<div className={styles.drawerContent}>
				<div className={styles.header}>
					<div className={styles.flex}>
						<div className={styles.title}>{`Edit extension`}</div>
						<button onClick={closeDrawer} className={styles.closeBtn}>
							<CloseIcon />
						</button>
					</div>
					<div className={styles.divider}></div>
				</div>
				<form className={styles.main} onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.body}>
						<span className={styles.intro}>
							Please fill the details of your extension proposition.
						</span>
						<div className={styles.formContainer}>
							<div className={styles.gridCols2}>
								<div className={styles.item}>
									<div className={styles.fieldLabel}>
										<CalendarIcon />
										<label>Start date</label>
									</div>
									<div className={cx(styles.inputContainer)}>
										<Controller
											name="start_date"
											control={control}
											render={({ field: { value, name, onChange, ref } }) => {
												return (
													<DatePicker
														name={name}
														value={value}
														onChange={onChange}
														ref={ref}
														rootClassName={styles.datePickerRoot}
														inputClassName={cx(styles.datePickerInput, {
															[styles.datePickerInputDisabled]:
																lastTimesheetDate > currentDayToTimestamp ||
																startDate < currentDayToTimestamp
																	? compareTimestamps(
																			endDate,
																			lastTimesheetDate
																	  ) >= 0
																	: compareTimestamps(
																			endDate,
																			currentDayToTimestamp
																	  ) >= 0
														})}
														triggerIcon={<CalendarIcon />}
														minValue={timestampToDateCalendar(
															startDateMinValue
														).add({
															days: 1
														})}
														isDisabled={isStartDateDisabled}
													/>
												);
											}}
										/>
									</div>
								</div>
								<div className={styles.item}>
									<div className={styles.fieldLabel}>
										<CalendarIcon />
										<label>End date</label>
										<span className={styles.asterisk}>*</span>
									</div>
									<div className={styles.inputContainer}>
										<Controller
											name="end_date"
											control={control}
											render={({ field: { value, name, onChange, ref } }) => (
												<DatePicker
													name={name}
													value={
														value ||
														timestampToDateCalendar(startDateValue).add({
															days: 1
														})
													}
													onChange={onChange}
													ref={ref}
													rootClassName={styles.datePickerRoot}
													inputClassName={styles.datePickerInput}
													triggerIcon={<CalendarPickerIcon />}
													minValue={
														lastTimesheetDate > startDate
															? timestampToDateCalendar(lastTimesheetDate).add({
																	days: 1
															  })
															: timestampToDateCalendar(startDateValue).add({
																	days: 1
															  })
													}
												/>
											)}
										/>
										<FieldTempError error={errors?.end_date} />
									</div>
								</div>
							</div>

							<div className={styles.gridCols2}>
								<div className={styles.item}>
									<div className={styles.fieldLabel}>
										<MoneyBillIcon />
										<label>
											{capitalize(`${reporting_settings?.rate_type} rate`)}
										</label>
										<span className={styles.asterisk}>*</span>
									</div>

									<Controller
										name="rate"
										control={control}
										render={({ field: { value, onChange } }) => {
											return (
												<CurrencyField
													useGreenTheme
													suffix={getSuffix(reporting_settings)}
													input={{ value, onChange }}
													rootClassName={styles.amountInput}
												/>
											);
										}}
									/>
									<FieldTempError error={errors?.rate} />
								</div>
								{displayCostEnabled && (
									<div className={styles.item}>
										<div className={styles.fieldLabel}>
											<MoneyBillIcon />
											<label>
												{capitalize(`${reporting_settings?.rate_type} cost`)}
											</label>
											<span className={styles.asterisk}>*</span>
										</div>

										<Controller
											name="cost"
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<CurrencyField
														useGreenTheme
														suffix={getSuffix(reporting_settings)}
														input={{ value, onChange }}
														rootClassName={styles.amountInput}
														disabled
													/>
												);
											}}
										/>
									</div>
								)}
							</div>
							{isSupplierMode(missionDetails?.invoice_details) && (
								<div className={styles.item}>
									<div className={styles.fieldLabel}>
										<SellingPriceIcon className={styles.labelIcon} />
										<label>Selling Price</label>
										<span className={styles.asterisk}> *</span>
									</div>
									<div className={styles.sellingPriceContainer}>
										<div
											className={cx(styles.inputContainer, styles.fixedWidth)}
										>
											<Controller
												name="selling_price"
												control={control}
												render={({ field: { value, onChange } }) => {
													return (
														<CurrencyField
															useGreenTheme
															suffix={getSuffix(reporting_settings)}
															input={{
																value:
																	value ||
																	missionDetails?.invoice_details
																		?.selling_price,
																onChange
															}}
															rootClassName={styles.amountInput}
															currencyLabelClassName={
																styles.currencyLabelClassName
															}
														/>
													);
												}}
											/>
										</div>
										<Tooltip
											theme="dark"
											className={styles.tooltip}
											content={
												"This is the agreed-upon rate between your company and the client company for the services provided by the freelancer. The selling price will be displayed in the timesheet sent to the client for approval, ensuring they see the agreed rate instead of the freelancer's original rate."
											}
										>
											<InfoIcon className={styles.infoCircle} />
										</Tooltip>
									</div>
									<FieldTempError error={errors?.selling_price} />
								</div>
							)}

							<div className={styles.item}>
								<div className={styles.fieldLabel}>
									<label>Additional comment</label>
								</div>
								<Controller
									name="description"
									control={control}
									render={({ field }) => {
										const onChange = e =>
											field.onChange(e.target.value?.trimStart());
										return (
											<TextareaField
												maxLength={COMMENT_MAX_LENGTH}
												rows={10}
												{...field}
												onChange={onChange}
												placeholder="Write your comment here."
											/>
										);
									}}
								/>
								<div className={styles.descriptionFooter}>
									<FieldTempError error={errors?.description} />
									<span className={styles.descriptionLength}>
										{watch("description").length}/{COMMENT_MAX_LENGTH}
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.footer}>
						<button
							type="submit"
							className={styles.success}
							disabled={!isDirty || isSubmitting}
						>
							<BookMark />
							Save
						</button>
						<button
							type="button"
							onClick={closeDrawer}
							className={styles.cancel}
						>
							Cancel
						</button>
					</div>
				</form>
			</div>
		</Drawer>
	);
}

export default MissionEditExtensionDrawer;
