import { $generateNodesFromDOM } from "@lexical/html";
import { $getRoot } from "lexical";

export function editorStateHandler(editor, value) {
	const parser = new DOMParser();
	if (value) {
		const dom = parser.parseFromString(value, "text/html");
		if (dom !== null) {
			const nodes = $generateNodesFromDOM(editor, dom);
			const rootNode = $getRoot();
			rootNode.append(...nodes);
		}
	}
}
