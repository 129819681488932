import React from "react";
import EvaluationsListHeader from "../EvaluationsListHeader";
import EvaluationsListByCategory from "../EvaluationsListByCategory";
import styles from "./evaluation-templates-list.module.scss";
import { useGetEvaluationTemplates } from "modules/EvaluationFormsSettings/api/useGetEvaluationTemplates";
import useTemplateSearchKeyword from "modules/EvaluationFormsSettings/hooks/useTemplateSearchKeyword";
import TemplatesNamesListSekeleton from "../TemplatesNamesListSekeleton";
import EvaluationsListEmptyBlock from "../EvaluationsListEmptyBlock/EvaluationsListEmptyBlock";
import sortBy from "lodash/sortBy";

export default function EvaluationTemplatesList() {
	const { keywords } = useTemplateSearchKeyword();
	const { data, isLoading } = useGetEvaluationTemplates({
		search: keywords.trim()
	});

	const list = data ? sortBy(Object.entries(data[0]), ([key]) => key) : [];

	return (
		<div className={styles.container}>
			<EvaluationsListHeader />
			{isLoading ? (
				<TemplatesNamesListSekeleton />
			) : list.length ? (
				<div className={styles.content}>
					{list.map(([key, value]) => {
						return (
							<EvaluationsListByCategory
								hasSibling={list.length > 1}
								key={key}
								categoryName={key}
								initialData={value}
							/>
						);
					})}
				</div>
			) : (
				<EvaluationsListEmptyBlock />
			)}
		</div>
	);
}
