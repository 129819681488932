import React from "react";
import { Text, Box, Flex, Button as ButtonRebass } from "rebass";
import _get from "lodash/get";
import GlobalTooltip from "common/GlobalTooltip";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import { CANDIDATE_POOL, QUERY } from "config";
import LoadingProfile from "../candidatePool/LoadingProfile";
import { historyPush } from "config/helpers";
import Avatar from "common/Avatar/index";
import { ReactComponent as LocationIcon } from "static/icons/icons-location.svg";
import { ReactComponent as ResidenceIcon } from "static/icons/icons-house.svg";
import { ReactComponent as AnynomosProfile } from "static/icons/anynomos-profile.svg";
import usePoolStore from "modules/SearchCandidate/hooks/zustandPool.js";
import Checkbox from "common/Checkbox";
import { searchParser } from "config/helpers";
import { CANDIDATES_FILTER } from "../../../SearchCandidate/utils/constants";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";

const ProfileCard = ({
	profile,
	isLoading,
	selectionBucket,
	include,
	exclude,
	index,
	onCheckboxChange,
	mode,
	checkCandidateSelection
}) => {
	const {
		main_function,
		functions,
		main_location,
		preferred_location = [],
		first_name,
		last_name,
		avatar,
		id,
		reference,
		is_viewed_by_me,
		is_viewed_by_my_company,
		email
	} = profile;

	const { getState } = useFilters();
	const { offset } = getState(CANDIDATES_FILTER);

	const goToProfile = user => {
		const queries = searchParser();
		historyPush(
			getPermanentProfileRoute(
				`${user ? user : id}?list=${CANDIDATE_POOL}&source=list${
					_get(queries, "vacancy-id")
						? `&vacancy-id=${_get(queries, "vacancy-id")}`
						: ""
				}`
			)
		);
	};

	const { currentID, setCurrentId } = usePoolStore(state => ({
		currentID: state.currentID,
		setCurrentId: state.setCurrentId
	}));
	const functionsNames = functions.map(f => (
		<>
			{f.name}
			<br />
		</>
	));

	const locationNames = preferred_location.map(l => {
		return (
			<>
				{`${l.country}, ${l.city}`}
				<br />
			</>
		);
	});
	locationNames.shift();
	const checked = selectionBucket.helpers.checkRowSelection(
		id,
		index + offset,
		"",
		exclude,
		include
	);
	const isSelectAll = mode === QUERY && exclude.length === include.length;

	return (
		<>
			{isLoading ? (
				<LoadingProfile />
			) : (
				<>
					<Box
						className="candidate-card"
						sx={{
							position: "relative",
							boxShadow: "0 0 6px 4px rgba(0, 0, 0, 0.04)",
							backgroundColor: "rgb(255, 255, 255)",
							borderRadius: "6px",
							padding: "24px 8px 14px 8px ",
							transition: "box-shadow 0.3s ease-in-out",
							cursor: "pointer",
							border: checked
								? "1px solid #FD6950"
								: "1px solid rgba(255,255,255,0)",
							"&:hover h5 span": {
								color: "#1686f7"
							},
							"&:hover": {
								boxShadow: "0 0 6px 4px rgba(0, 0, 0, 0.1)"
							}
						}}
						onClick={e => {
							e.preventDefault();
							goToProfile(currentID);
						}}
					>
						{
							<Box
								sx={{
									position: "absolute",
									top: "4px",
									left: "4px"
								}}
							>
								<Checkbox
									id={`item ${index} ${id}`}
									checked={checkCandidateSelection(index, profile)}
									onChange={e => onCheckboxChange(e, id, index, checked)}
								/>
							</Box>
						}
						<Box
							sx={{
								opacity: isSelectAll && !Boolean(email) && 0.4
							}}
						>
							{/* {!isSelectAll && supportedSources.includes(source) && (
								<Box
									sx={{
										width: "22px",
										height: "20px",
										borderRadius: "0 0 4px 4px",
										backgroundColor: "#ffed97",
										position: "absolute",
										top: "0",
										right: "8px",
										display: "flex",
										justifyContent: "center"
									}}
								>
									{source && (
										<GlobalTooltip
											placement="top"
											overlay={renderTooltipData[source].text}
										>
											{renderTooltipData[source].icon}
										</GlobalTooltip>
									)}
								</Box>
							)} */}
							{!Boolean(email) &&
								(mode === QUERY ||
									selectionBucket.state.selectedRows.length > 0) && (
									<Box
										sx={{
											background: "#FD6950",
											borderRadius: " 0px 0px 5px 5px",
											position: "absolute",
											width: "140px",
											height: "21px",
											right: "49px",
											top: "0px",
											color: " #fff",
											textAlign: "center",
											fontFamily: "basiercircle",
											fontSize: "13px",
											lineHeight: "21px"
										}}
									>
										No email address
									</Box>
								)}
							{!is_viewed_by_me &&
								!is_viewed_by_my_company &&
								mode !== QUERY &&
								selectionBucket.state.selectedRows.length === 0 && (
									<Box
										sx={{
											height: "20px",
											borderRadius: "0 0 4px 4px",
											backgroundColor: "#ffed97",
											position: "absolute",
											top: "0",
											right: "9px"
										}}
									>
										<Text
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "12px",
												lineHeight: "20px",
												color: "#2b3946",
												padding: "0 6px"
											}}
										>
											Not viewed
										</Text>
									</Box>
								)}
						</Box>
						<Flex alignItems="center" mb={12} sx={{ minHeight: "50px" }}>
							<Flex
								alignItems="center"
								justifyContent="center"
								mr={"8px"}
								sx={{
									height: "46px",
									border: "3px solid #dfe3e8",
									borderRadius: "46px",
									background: first_name ? "transparent" : "#b8bfc6"
								}}
							>
								{first_name ? (
									<Avatar
										src={avatar}
										size={40}
										name={`${first_name} ${last_name}`}
										radius={40}
									/>
								) : (
									<Box
										width="40px"
										sx={{ textAlign: "center" }}
										className="anonymous-profile"
									>
										<AnynomosProfile />
									</Box>
								)}
							</Flex>
							<Box>
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "18px",
										fontWeight: "bold",
										lineHeight: "1.33",
										color: "#212b36"
									}}
								>
									{first_name ? (
										<>{`${first_name} ${last_name}`}</>
									) : (
										<>Ref: {reference}</>
									)}
								</Text>
								<Text
									mt={"4px"}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "16px",
										lineHeight: "1.25",
										color: "#212b36"
									}}
								>
									{functions.length > 0 ? (
										<GlobalTooltip
											overlayClassName="g-tool-dark top"
											overlay={functionsNames}
											align={{
												offset: [0, 0]
											}}
										>
											<Text
												sx={{
													fontFamily: "BasierCircle",
													fontSize: "16px",
													lineHeight: "1.25",
													color: "#212b36"
												}}
											>
												{_get(main_function, "name")}
												<Text
													as={"span"}
													sx={{
														fontFamily: "BasierCircle",
														fontSize: "16px",
														lineHeight: "1.25",
														color: "#212b36"
													}}
												>
													(+{functions.length})
												</Text>
											</Text>
										</GlobalTooltip>
									) : (
										<GlobalTooltip
											overlayClassName="g-tool-dark top"
											overlay={_get(main_function, "name")}
											align={{
												offset: [0, 0]
											}}
											active={
												_get(main_function, "name") &&
												_get(main_function, "name").length > 41
													? true
													: false
											}
										>
											<Text>
												{_get(main_function, "name") ? (
													_get(main_function, "name").length > 41 ? (
														<Text>
															{_get(main_function, "name").substr(0, 35) +
																"..."}
														</Text>
													) : (
														<Text>{_get(main_function, "name")}</Text>
													)
												) : null}
											</Text>
										</GlobalTooltip>
									)}
								</Text>
							</Box>
						</Flex>
						<Flex
							mb={16}
							sx={{
								padding: "4px 8px",
								borderRadius: "6px",
								backgroundColor: "#f6f7fb"
							}}
						>
							<Box width={1 / 2}>
								<Flex alignItems="center">
									<ResidenceIcon style={{ marginRight: "8px" }} />

									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "14px",
											lineHeight: "1.14",
											color: "#818a92"
										}}
									>
										Residence
									</Text>
								</Flex>

								<Text
									pl={26}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "14px",
										lineHeight: "1.14",
										color: "#212b36"
									}}
								>
									{_get(main_location, "city") ||
									_get(main_location, "country") ? (
										<>{`${_get(main_location, "country")}${
											_get(main_location, "city")
												? `, ${_get(main_location, "city")}`
												: ""
										}`}</>
									) : (
										"N/A"
									)}
								</Text>
							</Box>
							<Box width={1 / 2}>
								<Flex alignItems="center">
									<LocationIcon style={{ marginRight: "8px" }} />

									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "14px",
											lineHeight: "1.14",
											color: "#818a92"
										}}
									>
										Preferred
									</Text>
								</Flex>

								<Text
									pl={26}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "14px",
										lineHeight: "1.14",
										color: "#212b36"
									}}
								>
									{preferred_location.length > 0 ? (
										<Text
											as={"span"}
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "14px",
												lineHeight: "1.14",
												color: "#212b36",
												display: "flex",
												alignItems: "center"
											}}
										>
											<>{`${_get(preferred_location[0], "country")}${
												_get(preferred_location[0], "city")
													? `, ${_get(preferred_location[0], "city")}`
													: ""
											}`}</>
											{preferred_location.length > 1 && (
												<GlobalTooltip
													overlayClassName="g-tool-dark top"
													overlay={locationNames}
													align={{
														offset: [-65, -5]
													}}
												>
													<Text
														as={"span"}
														sx={{
															fontFamily: "BasierCircle",
															fontSize: "14px",
															lineHeight: "1.14",
															color: "#212b36"
														}}
													>
														(+{preferred_location.length - 1})
													</Text>
												</GlobalTooltip>
											)}
										</Text>
									) : (
										<span>{"N/A"}</span>
									)}
								</Text>
							</Box>
						</Flex>

						<Flex justifyContent="flex-end">
							<ButtonRebass
								onClick={e => {
									e.stopPropagation();
									setCurrentId(id);
								}}
								mr={14}
								sx={{
									padding: "0",
									background: "none",
									fontFamily: "BasierCircle",
									fontSize: "14px",
									lineHeight: "1.14",
									color: "#818a92",
									outline: "none"
								}}
							>
								Quick view
							</ButtonRebass>
						</Flex>
					</Box>
				</>
			)}
		</>
	);
};

export default ProfileCard;
