import React from "react";
import { ThumbsLabel } from "common/ThumbsLabel";

const EvaluationAverageScore = ({ value, className }) => {
	let type;

	if (value <= 33) {
		type = "red";
	} else if (value > 33 && value <= 67) {
		type = "orange";
	} else if (value > 67) {
		type = "green";
	}

	return (
		<ThumbsLabel
			className={className}
			suffix={`${value}%`}
			type={type}
			addBackground
		/>
	);
};

export default EvaluationAverageScore;
