import styled from "styled-components";

export const BidListContainer = styled.div`
	position: absolute;
	z-index: 10;
	right: 0;
	width: 900px;
	top: ${props => (props.bottom ? "unset" : "75px")};
	bottom: ${props => (props.bottom ? "75px" : "unset")};
	box-shadow: 0px 2px 21.5px 0 rgba(197, 199, 206, 0.43);
`;

export const IconContainer = styled.div`
	text-align: center;
	border-radius: 50%;
	font-size: 8px;
	background: ${props => (props.rotate ? "#ff6849" : "#e8e9ea")};
	height: 30px;
	width: 30px;
	line-height: ${props => (props.rotate ? "30px" : "35px")};
	cursor: ${props => (props.cursor ? props.cursor : "pointer")};
	color: ${props => (props.rotate ? "#ffffff" : "#333")};
	.rotate-icon {
		transform: rotate(180deg);
		display: inline-block;
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
	}
`;

export const ScrollableTable = styled.div``;

export const ViewContainer = styled.div`
	padding: 30px 15px;
	.top-actions {
		display: inline-block;
		margin-bottom: 10px;
		height: 25px;
		width: 100%;
		button {
			float: right !important;
			top: 10px;
			position: relative;
		}
	}
`;

export const FiltersContainer = styled.div`
	.form-group {
		position: relative;
		margin-bottom: 24px;
		.form-control {
			border: unset;
			background: transparent;
			border-bottom: 1px solid #d6d7dd;
			padding: 0px 30px;
			min-height: 45px !important;
		}
		input.form-control {
			padding-left: 30px;
		}
		.icon-search {
			position: absolute;
			top: 17px;
			left: 8px;
			color: #a5b1bb;
		}
		.input-error {
			border: 1px solid #ff6849 !important;
		}
		.icon-reset-3 {
			position: relative;
			top: 50%;
		}
	}
	.select-label {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: 0.08px;
		color: #273238;
		line-height: 1.43;
	}
	.Select-control {
		width: 190px;
		margin-top: 6px;
		height: 40px;
		border: 2px solid #0676ed;
		border-radius: 2px;
		.Select-value {
			display: flex;
			align-items: center;
		}
	}
	.Select-menu-outer {
		width: 190px;
	}

	.flex {
		display: flex;
		align-items: center;
	}
`;
