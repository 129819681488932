import React from "react";
const UnSortUp = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.00008 12.6665L8.00008 11.5357M8.00008 3.33317L12.6667 7.99984M8.00008 3.33317L8.00008 8.87157M8.00008 3.33317L5.66675 5.6665M3.33341 7.99984L3.80436 7.52889"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.78052 3.99414L11.8364 13.0501"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default UnSortUp;
