import React from "react";
import { Link } from "react-router";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPolicyRoute } from "../privacyPolicy/components/PolicyContainer";
import CheckBoxInput from "../CheckBoxInput";
import styled from "styled-components";
import { ASK_QUESTION } from "../../config";
import { toggleHelpModal } from "../../modules/app/actions/ListsActions";
import { getTermsAndConditionsRoute } from "../termsAndConditions/components/TermsAndConditionsContainer";
function PrivacyWebSiteContact({
	toggleHelpModal,
	isFreelancer,
	isPermanent,
	isConsultant,
	marginTop
}) {
	const Content = styled.div`
		margin-top: ${marginTop ? marginTop : "55px"};
	`;
	const Button = styled.button`
		color: #273238;
		background: none;
		border: none;
		padding: 0 !important;
		text-decoration: underline;
		font: inherit;
		cursor: pointer;
		outline: none;
	`;

	return (
		<Content>
			<Field
				type="checkbox"
				name="accept_policies"
				formGroupClassName="checkbox-input"
				component={CheckBoxInput}
			>
				{isPermanent || isFreelancer
					? "By submitting your email address, CV, and any other personal information to this website, you consent to such information being collected, held, used and disclosed in accordance with our "
					: "By submitting your email address, and any other personal information to this website, you consent to such information being collected, held, used and disclosed inaccordance with our "}
				<Link target="_blank" to={getPolicyRoute()}>
					Privacy Policy
				</Link>{" "}
				and our{" "}
				<Link target="_blank" to={getTermsAndConditionsRoute()}>
					Website T&Cs.
				</Link>
				<br />
				{isFreelancer || isPermanent ? (
					<>
						Any questions about your privacy?{" "}
						<Button onClick={() => toggleHelpModal(ASK_QUESTION)}>
							Contact us
						</Button>
					</>
				) : null}
			</Field>

			{isConsultant && (
				<Field
					type="checkbox"
					name="accept_condition"
					formGroupClassName="checkbox-input"
					component={CheckBoxInput}
				>
					By ticking this box, you confirm that you have obtained the consent
					from any third parties (consultants) for their personal information to
					be submitted to Wiggli or are able to rely on another lawful basis for
					uploading their personal data
				</Field>
			)}
		</Content>
	);
}
const mapDispatchToProps = dispatch =>
	bindActionCreators({ toggleHelpModal }, dispatch);
export default connect(null, mapDispatchToProps)(PrivacyWebSiteContact);
