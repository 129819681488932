import styled from "styled-components";
import { colors } from "config/styles";

export const FormContainer = styled.form`
	.form-group {
		&:after {
			display: none;
		}
		margin-bottom: 31px;
	}

	.btn {
		float: right;
		margin-top: 30px;
		padding: 14px 24px !important;
		width: 100px;
		margin-left: 14px;
		&.btn-outline {
			background-color: white;
			color: ${colors.anchorsColor};
			border: 1.5px solid #b9bdc0;
			&:hover {
				background-color: ${colors.anchorsColor};
				color: ${colors.white};
				border-color: ${colors.anchorsColor};
			}
		}
	}
	.inline-error {
		&.phone {
			margin-top: -31px;
		}
	}
	#accept_policies {
		width: 50px !important;
		height: 60px !important;
		top: -5px !important;
		left: 20px !important;
		z-index: 100;
		a {
			cursor: pointer;
		}
	}
`;
