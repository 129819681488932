import { useQuery } from "react-query";
import { GET_LAST_JOB } from "config/api-endpoints";
import { client } from "lib/api-client";

const getVacancy = () => {
	return client(GET_LAST_JOB, {
		body: {
			channels_info: true,
			job_type: "vacancy"
		}
	});
};

export const GET_LAST_CREATED_VACANCY_QUERY_KEY =
	"GET_LAST_CREATED_VACANCY_QUERY_KEY";

export const useGetLastCreatedVacancy = options => {
	const res = useQuery(GET_LAST_CREATED_VACANCY_QUERY_KEY, getVacancy, {
		refetchOnWindowFocus: false,
		...options
	});

	if (options.enabled) {
		return res;
	} else {
		return {};
	}
};
