import React, { Component } from "react";
import { connect } from "react-redux";
import { switchView, readNotifications } from "../actions";
import { UNREAD, LATEST } from "../../../config";
import _get from "lodash/get";
import {
	notificationsDataSelector,
	notificationsViewSelector,
	missionNotificationsSelector
} from "../selectors/NotificationsSelectors";

class HeaderActions extends Component {
	constructor(props) {
		super(props);
		this.markAllRead = this.markAllRead.bind(this);
	}

	markAllRead() {
		const { view, offset } = this.props;
		this.props.dispatch(readNotifications(true, view, offset));
	}

	render() {
		const { dispatch, view } = this.props;
		return (
			<div className="notifications-header-actions section">
				<div className="view-actions pull-left">
					<button
						onClick={() => dispatch(switchView(LATEST))}
						className={`btn btn-sm ${
							view === LATEST ? "btn-primary" : "btn-white"
						}`}
					>
						Latest
					</button>
					<button
						onClick={() => dispatch(switchView(UNREAD))}
						className={`btn btn-sm ${
							view === UNREAD ? "btn-primary" : "btn-white"
						}`}
					>
						Unread
					</button>
				</div>
				<div className="data-actions pull-right">
					<button
						className="btn btn-white btn-sm mark-all"
						onClick={this.markAllRead}
					>
						<i className="fa fa-check" /> Mark all as read
					</button>
				</div>
				<div className="clearfix" />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		view: notificationsViewSelector(state),
		notifications: notificationsDataSelector(state),
		offset: _get(missionNotificationsSelector(state), "offset", null)
	};
};

export default connect(mapStateToProps)(HeaderActions);
