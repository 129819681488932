import React from "react";
import _get from "lodash/get";
import EditableSection from "../EditableSection";
import cardStyle from "../EditableSection/editable-section.module.scss";
import CopyToClipboard from "modules/candidate/Profile/utils/copyValue";
import ToolTip from "common/GlobalTooltip";
import CurrencyFormatter from "common/CurrencyFormater";

const EmptyState = ({ message }) => {
	return (
		<div className={cardStyle.emptyContainer}>
			<p>{message}</p>
		</div>
	);
};

const CandidateProfileTabCandidatePreferences = ({ profileData }) => {
	const sectionPreferences = _get(profileData, "update_sections.preference");

	const locations = _get(
		profileData,
		"candidate_preferences.front_sheet.preference.preferred_location",
		[]
	);

	const residence = locations
		.filter(loc => loc.is_main)
		.map(item => ({
			...item,
			residence: item?.city ? `${item.city}, ${item.country}` : item.country
		}));

	const fullLocations = locations
		.filter(loc => !loc.is_main)
		.map(item => ({
			...item,
			fullLocation: item?.city ? `${item.city}, ${item.country}` : item.country
		}));

	const extraBenefits = _get(
		profileData,
		"candidate_preferences.front_sheet.preference.extra_benefits",
		[]
	);

	const expectedSalary = _get(
		profileData,
		"candidate_preferences.front_sheet.preference.expected_salary",
		""
	);
	const symbol = _get(
		profileData,
		"candidate_preferences.front_sheet.preference.symbol_expected",
		""
	);
	const paymentType = _get(
		profileData,
		"candidate_preferences.front_sheet.preference.payment_type_expected",
		""
	);
	const paymentTime = _get(
		profileData,
		"candidate_preferences.front_sheet.preference.payment_time_expected",
		""
	);
	const notice = _get(
		profileData,
		"candidate_preferences.front_sheet.preference.notice_period",
		""
	);

	const advantages = _get(
		profileData,
		"candidate_preferences.front_sheet.preference.expected_salary_advantage",
		""
	);

	const SLICE_SIZE = 50;

	return (
		<React.Fragment>
			<EditableSection
				title="Candidate preferences"
				hasEdit={false}
				sectionUpdate={sectionPreferences}
			>
				<div className={cardStyle.profileContainer}>
					<div className={cardStyle.profileBox}>
						<p className={cardStyle.profileLabel}>Employment types</p>
						{_get(
							profileData,
							"candidate_preferences.front_sheet.preference.employment_types",
							[]
						) ? (
							<div className={cardStyle.profileEmpType}>
								{_get(
									profileData,
									"candidate_preferences.front_sheet.preference.employment_types",
									[]
								).map(et => (
									<CopyToClipboard key={et} text={et}>
										<span className={cardStyle.text}>
											{et.replace(/_/g, " ")}
										</span>
									</CopyToClipboard>
								))}
							</div>
						) : (
							<EmptyState />
						)}
					</div>
					<div className={cardStyle.profileBox}>
						<p className={cardStyle.profileLabel}>Residence</p>
						{residence.length > 0 ? (
							<div className={cardStyle.profileInfo}>
								{residence.map(r => (
									<CopyToClipboard key={r} text={r.residence}>
										{r.residence}
									</CopyToClipboard>
								))}
							</div>
						) : (
							<EmptyState />
						)}
					</div>
					<div className={cardStyle.profileBox}>
						<p className={cardStyle.profileLabel}>Preferred locations</p>
						{fullLocations.length > 0 ? (
							<div className={cardStyle.profilePreferedLoc}>
								{fullLocations.map(fl => (
									<CopyToClipboard key={fl} text={fl.fullLocation}>
										{fl.fullLocation}
									</CopyToClipboard>
								))}
							</div>
						) : (
							<EmptyState />
						)}
					</div>
					<div className={cardStyle.secondRow}>
						<div className={cardStyle.profileBox}>
							<p className={cardStyle.profileLabel}>Expected salary</p>
							{expectedSalary || symbol || paymentType || paymentTime ? (
								<div className={cardStyle.profileInfo}>
									<CopyToClipboard
										text={
											(
												<CurrencyFormatter
													symbol={` ${symbol}`}
													cent={expectedSalary}
												/>
											) +
											" " +
											symbol +
											" " +
											paymentType +
											"/" +
											paymentTime
										}
									>
										{
											<CurrencyFormatter
												symbol={` ${symbol}`}
												cent={expectedSalary}
											/>
										}
										<span className={cardStyle.paymentType}>
											{paymentType}
											{"/"}
											{paymentTime}
										</span>
									</CopyToClipboard>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
						<div className={cardStyle.profileBox}>
							<p className={cardStyle.profileLabel}>Payroll country</p>
							{_get(
								profileData,
								"candidate_preferences.front_sheet.preference.payroll_country_expected",
								""
							) ? (
								<div className={cardStyle.profileEmpType}>
									<CopyToClipboard
										text={_get(
											profileData,
											"candidate_preferences.front_sheet.preference.payroll_country_expected",
											""
										)}
									>
										{_get(
											profileData,
											"candidate_preferences.front_sheet.preference.payroll_country_expected",
											""
										)}
									</CopyToClipboard>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
					<div className={cardStyle.profileBox}>
						<p className={cardStyle.profileLabel}>Notice period</p>
						{notice ? (
							<ToolTip
								placement={"left"}
								maxWidth={"30rem"}
								align={{
									offset: [50, 35]
								}}
								overlay={notice}
							>
								<div className={cardStyle.profileInfo}>
									<CopyToClipboard text={notice}>
										{notice.length > SLICE_SIZE
											? notice.slice(0, SLICE_SIZE) + "..."
											: notice}
									</CopyToClipboard>
								</div>
							</ToolTip>
						) : (
							<EmptyState />
						)}
					</div>
					<div className={cardStyle.profileBox}>
						<p className={cardStyle.profileLabel}>Extra benefits</p>
						{extraBenefits.length > 0 ? (
							<div className={cardStyle.profileBenefit}>
								{extraBenefits.map((b, index) => (
									<span key={index}>{_get(b, "label")}</span>
								))}
							</div>
						) : (
							<EmptyState />
						)}
					</div>
					<div className={cardStyle.profileBox}>
						<p className={cardStyle.profileLabel}>Other benefits</p>
						{advantages ? (
							<div className={cardStyle.profileInfo}>{advantages}</div>
						) : (
							<EmptyState />
						)}
					</div>
				</div>
			</EditableSection>
		</React.Fragment>
	);
};
EmptyState.defaultProps = {
	message: "N/A"
};
export default CandidateProfileTabCandidatePreferences;
