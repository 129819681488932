import React, { forwardRef } from "react";
import cx from "classnames";
import { useButton } from "@react-aria/button";
import styles from "./button.module.scss";

const Button = ({ children, className, ...propsRest }, ref) => {
	let { buttonProps } = useButton(propsRest, ref);
	return (
		<button
			className={cx(className, styles.container)}
			ref={ref}
			{...propsRest}
			{...buttonProps}
		>
			{children}
		</button>
	);
};

export default forwardRef(Button);
