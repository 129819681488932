/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import UserRow from "./UserRow";
import {
	TimesheetTableDetails,
	Tr,
	Th
} from "../../../timesheets/components/TimesheetStyled";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import EmptyList from "./EmptyList";

export default ({
	list,
	onMenuSelect,
	isManager,
	isAdminIn,
	connectedUser,
	AddButton
}) => {
	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Settings" },
			{ name: "Users & Departments ", path: "/settings/users" }
		]);
	}, []);

	if (list && list.length > 0)
		return (
			<TimesheetTableDetails className="styled-table" spacing="0 3px">
				<thead>
					<Tr>
						<Th width="20%">Name</Th>
						<Th width="20%">Email</Th>
						<Th width="52%">Role</Th>
						<Th width="8%" style={{ textAlign: "center" }}>
							Actions
						</Th>
					</Tr>
				</thead>
				<tbody>
					{list.map(user => (
						<UserRow
							onMenuSelect={onMenuSelect}
							key={user._id || user.invitation_id}
							connectedUser={connectedUser}
							user={user}
							isManager={isManager}
							isAdminIn={isAdminIn}
						/>
					))}
				</tbody>
			</TimesheetTableDetails>
		);
	return (
		<EmptyList
			AddButton={AddButton}
			title={"No Users Found"}
			description={
				"There are no users in your list. Begin inviting team members and assigning roles to get started."
			}
		/>
	);
};
