import React from "react";
import styles from "./evaluation-card-candidate-label.module.scss";
import cx from "classnames";
import { ReactComponent as LinkIcon } from "static/icons/link-03.svg";
import { ReactComponent as UserIcon } from "static/icons/user-03.svg";

const EvaluationCardCandidateLabel = ({
	containerClassName,
	title,
	isCandidate,
	onClick
}) => {
	return (
		<div onClick={onClick} className={cx(styles.container, containerClassName)}>
			<div className={styles.icon}>
				{isCandidate && <UserIcon />}
				{!isCandidate && <LinkIcon />}
			</div>
			{title && <div className={styles.title}>{title}</div>}
		</div>
	);
};
export default EvaluationCardCandidateLabel;
