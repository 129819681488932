import React, { useContext, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";
import { Flex, Box } from "rebass";
import _get from "lodash/get";

import { FilterInputHolder, ClearFilterButton } from "./style";
import StyledSelect from "common/StyledSelect";
import { ListContext, LIST_ACTION_TYPES } from "./listContext";
import { prepareForSelect } from "../../../config/helpers";
import ClearIcon from "./ClearIcon";

const Filter = ({
	categories,
	selectedSkillsCategory,
	setSkillsCategory,
	showCategories
}) => {
	const { state, dispatch } = useContext(ListContext);
	const [filter, setFilter] = useState(_get(state, "filter"));

	useEffect(() => {
		setFilter(_get(state, "filter"));
		if (showCategories && categories.length === 1) {
			setSkillsCategory({ value: categories[0]._id });
		}
	}, [_get(state, "filter")]);

	const changeFilterAction = value =>
		dispatch({
			type: LIST_ACTION_TYPES.UPDATE_FILTER,
			filter: value
		});

	const debouncedChangeFilterAction = useRef(
		_debounce(changeFilterAction, 500)
	);

	const handleFilterChange = e => {
		const value = e.target.value;
		setFilter(value);
		debouncedChangeFilterAction.current(value);
	};

	const clearFilter = () =>
		dispatch({ type: LIST_ACTION_TYPES.UPDATE_FILTER, filter: "" });

	return (
		<Flex width={1}>
			{showCategories && (
				<Box width={1 / 3}>
					<Box m={"0 15px"}>
						<StyledSelect
							label="Category"
							name="category"
							options={prepareForSelect(categories, "name", "category")}
							placeholder="Choose a Category"
							onChange={c => setSkillsCategory(c)}
							valueToSet={selectedSkillsCategory}
							position={false}
							grouped={true}
							rawData={categories}
							valueField="_id"
							showErrorContainer={false}
							className="category-filter"
						/>
					</Box>
				</Box>
			)}
			<Box width={2 / 3}>
				<Box m={"0 15px"}>
					<label className="upperCase">Filter</label>
					<FilterInputHolder>
						<input
							className="form-control"
							type="text"
							value={filter}
							onChange={handleFilterChange}
						/>
						{filter && (
							<ClearFilterButton
								onClick={clearFilter}
								style={{ background: "transparent" }}
							>
								<ClearIcon width={30} height={30} />
							</ClearFilterButton>
						)}
					</FilterInputHolder>
				</Box>
			</Box>
		</Flex>
	);
};

Filter.propTypes = {
	categories: PropTypes.array,
	setSkillsCategory: PropTypes.func
};

export default Filter;
