import { COMPANY_ADDRESS, SEARCH_ADDRESS, VIDEO_CONFERENCE } from "config";
import React from "react";
import { ReactComponent as BuildingIcon } from "static/icons/building.svg";
import { ReactComponent as MarkerPinIcon } from "static/icons/marker-pin.svg";
import { ReactComponent as VideoRecorderIcon } from "static/icons/video-recorder.svg";

export const ADD_INTERVIEW_NOTE_MAX_LENGTH = 2000;
export const MAX_LENGTH_IN_BYTES = 15728640; // 15mb
export const INTERVIEW_EMAIL_PLACEHOLDERS_CONTEXT =
	"INTERVIEW_EMAIL_PLACEHOLDERS_CONTEXT";

export const LOCATION_TYPES = {
	companyAddress: {
		label: "Company Address",
		value: COMPANY_ADDRESS,
		icon: <BuildingIcon />
	},
	other: {
		label: "Another location",
		value: SEARCH_ADDRESS,
		icon: <MarkerPinIcon />
	},
	online: {
		label: "Online",
		value: VIDEO_CONFERENCE,
		icon: <VideoRecorderIcon />
	}
};
