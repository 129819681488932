import React, { useEffect } from "react";
import Drawer from "rc-drawer";
import usePublishRequestStore from "modules/publishRequest/hooks/usePublishRequestStore";
import styles from "./publish-request-confirmation-drawer.module.scss";
import { ReactComponent as CloseIcon } from "../../icons/x.svg";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { PUBLISHED } from "config";
import toaster from "common/Toaster";
import { historyPush } from "config/helpers";
import usePublishJob from "modules/publishRequest/hooks/usePublishJob";
import useCreateCampaign from "modules/publishRequest/hooks/useCreateCampaign";
import Loader from "common/Loader";
import { PUBLISH_REQUEST_CHANNELS } from "config";
import cx from "classnames";
import get from "lodash/get";
import { Flex } from "rebass";
import CurrencyFormatter from "common/CurrencyFormater";
import { EUR } from "config";

export default function PublishRequestConfirmationDrawer({ jobId }) {
	const {
		isConfirmationDrawerOpen,
		setIsConfirmationDrawerOpen,
		request,
		selectedChannels,
		toggleSelectChannel
	} = usePublishRequestStore();

	const onCloseDrawer = () => setIsConfirmationDrawerOpen(false);

	const onClickUnselect = channel => {
		toggleSelectChannel(channel);
	};

	const [publishJob, { isLoading: isPublishing }] = usePublishJob({
		onSuccess: () => {
			onCloseDrawer();
			toaster.success("Request has successfully been published");
			historyPush(`/job/${jobId}`);
		},
		onError: error => {
			if (typeof error.detail === "string")
				return toaster.danger(get(error, "detail", "An error has occurred"));
			return toaster.danger(get(error, "detail.name", "An error has occurred"));
		}
	});

	const [createCampaign, { isLoading: isRepublishing }] = useCreateCampaign({
		onSuccess: () => {
			onCloseDrawer();
			toaster.success("Request has successfully been republished");
			historyPush(`/job/${jobId}`);
		},
		onError: error => {
			if (typeof error.detail === "string")
				return toaster.danger(get(error, "detail", "An error has occurred"));
			return toaster.danger(get(error, "detail.name", "An error has occurred"));
		}
	});

	const onSubmit = () => {
		if (!selectedChannels.length) return;

		const action = request?.status === PUBLISHED ? createCampaign : publishJob;

		const publishToMyFreelancers = !!selectedChannels.find(
			c => c.product_id === PUBLISH_REQUEST_CHANNELS.my_freelancers_product_id
		);
		const publishToTalentPool = !!selectedChannels.find(
			c => c.product_id === PUBLISH_REQUEST_CHANNELS.talent_pool_product_id
		);
		const externalFreelancerChannels = selectedChannels.filter(
			c => c.product_id === PUBLISH_REQUEST_CHANNELS.job_board_product_id
		);
		const myJobPageChannels = selectedChannels.filter(
			c =>
				![
					PUBLISH_REQUEST_CHANNELS.my_freelancers_product_id,
					PUBLISH_REQUEST_CHANNELS.talent_pool_product_id,
					PUBLISH_REQUEST_CHANNELS.job_board_product_id
				].includes(c.product_id)
		);

		const payload = {
			id: jobId,
			publish_types: {
				my_freelancers: publishToMyFreelancers,
				talent_pool: publishToTalentPool,
				my_job_page: {
					active: myJobPageChannels.length > 0,
					channel_ids: myJobPageChannels.map(c => c.product_id)
				},
				external_freelancers: {
					active: externalFreelancerChannels.length > 0,
					channel_ids: externalFreelancerChannels.map(c => c.product_id)
				}
			},
			publish_version: "en",
			products_specs: []
		};

		action(payload);
	};

	useEffect(() => {
		if (!selectedChannels.length) {
			onCloseDrawer();
		}
	}, [selectedChannels]);

	const isLoading = isPublishing || isRepublishing;

	return (
		<Drawer
			open={isConfirmationDrawerOpen}
			width="584px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={isLoading ? () => null : onCloseDrawer}
		>
			{isLoading && (
				<div className={styles.loaderContainer}>
					<Loader />
				</div>
			)}
			<div className={styles.drawerContent}>
				<header className={styles.header}>
					<h3 className={styles.title}>Selected channels</h3>
					<div
						tabIndex="0"
						role="button"
						onClick={onCloseDrawer}
						className={styles.closeBtn}
					>
						<CloseIcon />
					</div>
				</header>
				<section className={styles.content}>
					<div>
						<div className={styles.sectionTitle}>
							Confirm campaign related costs
						</div>
						<div className={styles.sectionDescription}>
							You are about to{" "}
							{request?.status === PUBLISHED ? "republish" : "publish"} your
							request on the external channels, which will induce the following
							costs:
						</div>
					</div>
					<div className={styles.sectionDivider} />
					<div className={styles.itemsContainer}>
						<div className={styles.itemsContainerDivider}></div>
						{selectedChannels.map(channel => {
							return (
								<React.Fragment key={channel.product_id}>
									<div className={styles.item}>
										<div className={styles.channelName}>
											{channel.channel.name}
										</div>
										<div className={styles.priceValue}>
											<CurrencyFormatter
												euro={channel.vonq_price[0].amount}
												symbol={EUR}
											/>
										</div>
										<div className={styles.itemDivider}></div>
										<div
											className={styles.unselectBtn}
											role="button"
											tabIndex="0"
											aria-label="unselect"
											onClick={() => onClickUnselect(channel)}
										>
											<TrashIcon width={20} height={20} />
										</div>
									</div>
								</React.Fragment>
							);
						})}
					</div>
					<div className={styles.sectionDivider} />
					<div className={styles.totalContainer}>
						<div>Total cost</div>
						<div>
							<CurrencyFormatter
								euro={selectedChannels.reduce(
									(totalCost, channel) =>
										totalCost + channel.vonq_price[0].amount,
									0
								)}
								symbol={EUR}
							/>
						</div>
					</div>
				</section>
				<div className={styles.footer}>
					<Flex sx={{ gap: "8px" }}>
						<div
							onClick={onSubmit}
							role="button"
							tabIndex="0"
							className={cx(
								styles.publishBtn,
								!selectedChannels.length && styles.publishBtnDisabled
							)}
						>
							<CheckIcon />
							<span>
								{request?.status === PUBLISHED
									? "Republish request"
									: "Publish request"}
							</span>
						</div>
						<div
							onClick={onCloseDrawer}
							role="button"
							tabIndex="0"
							className={styles.cancelBtn}
						>
							Cancel
						</div>
					</Flex>
				</div>
			</div>
		</Drawer>
	);
}
