import React from "react";
import { CreateVacancySkeletonCard } from "../CreateVacancySkeletonCard";
import { CreateVacancyCard } from "../CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import styles from "./add-vacancy-salary-card-loading.module.scss";
import { CreateVacancyCardTitle } from "../CreateVacancyCardTitle";

const AddVacancySalaryCardLoading = () => {
	return (
		<CreateVacancyCard className={styles.card}>
			<CreateVacancyCardTitle>Salary</CreateVacancyCardTitle>
			<CreateVacancyCardContent>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Payroll country</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
				<div className={styles.grid5Columns}>
					<CreateVacancyFieldContainer>
						<CreateVacancyFieldLabel>Between</CreateVacancyFieldLabel>
						<CreateVacancySkeletonCard />
					</CreateVacancyFieldContainer>
					<CreateVacancyFieldContainer>
						<CreateVacancyFieldLabel>And</CreateVacancyFieldLabel>
						<CreateVacancySkeletonCard />
					</CreateVacancyFieldContainer>
					<CreateVacancyFieldContainer>
						<CreateVacancyFieldLabel>Currency</CreateVacancyFieldLabel>
						<CreateVacancySkeletonCard />
					</CreateVacancyFieldContainer>
				</div>
				<div className={styles.grid2Columns}>
					<CreateVacancyFieldContainer>
						<CreateVacancyFieldLabel>Payments time</CreateVacancyFieldLabel>
						<CreateVacancySkeletonCard />
					</CreateVacancyFieldContainer>
					<CreateVacancyFieldContainer>
						<CreateVacancyLabelContainer>
							<CreateVacancyFieldLabel>Type</CreateVacancyFieldLabel>
						</CreateVacancyLabelContainer>
						<CreateVacancySkeletonCard />
					</CreateVacancyFieldContainer>
				</div>
				<CreateVacancyFieldContainer>
					<CreateVacancyFieldLabel displayStar={false}>
						Other benefits
					</CreateVacancyFieldLabel>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Extra benefits
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
			</CreateVacancyCardContent>
		</CreateVacancyCard>
	);
};

export default AddVacancySalaryCardLoading;
