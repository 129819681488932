import React from "react";
const HandshakeIcon = () => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 428.7 285.4"
		style={{ enableBackground: "new 0 0 428.7 285.4" }}
	>
		<g>
			<ellipse
				style={{ opacity: "0.13", fill: "#606060" }}
				cx="215.6"
				cy="226.7"
				rx="204"
				ry="43.3"
			/>
			<g>
				<g>
					<path
						style={{ fill: "#E5E5E4" }}
						d="M164.8,167.2c3.6,3.6,4.3,9.1,2.1,13.5c-0.5,1-1.2,1.9-2.1,2.8c-4.5,4.5-11.8,4.5-16.3,0
				c-4.5-4.5-4.5-11.8,0-16.3c1-1,2.1-1.8,3.3-2.3C156.2,162.9,161.4,163.7,164.8,167.2z"
					/>
					<path
						style={{ fill: "#E5E5E4" }}
						d="M181.5,183.8c4.5,4.5,4.5,11.8,0,16.3c-4.5,4.5-11.8,4.5-16.2,0c-4.5-4.5-4.5-11.8,0-16.3
				c1-1,2.1-1.8,3.3-2.3C172.9,179.6,178,180.3,181.5,183.8z"
					/>
					<path
						style={{ fill: "#E5E5E4" }}
						d="M197.8,200.1c3.5,3.5,4.3,8.6,2.4,12.8c-0.6,1.2-1.3,2.4-2.4,3.4c-4.5,4.5-11.8,4.5-16.3,0
				c-4.5-4.5-4.5-11.8,0-16.3C186,195.6,193.3,195.6,197.8,200.1z"
					/>
					<path
						style={{ fill: "#DEDEDE" }}
						d="M296.1,125.1c-2.3,13.1-8.6,25.2-18.1,34.6l-6.5,6.5L221.2,116c-0.1-0.1-0.2-0.2-0.2-0.2
				c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.1-0.1l-6.8-6.9l-16.9,16.9c-2.6,2.6-6,3.9-9.4,3.9
				c-3.4,0-6.8-1.3-9.4-3.9c-5.2-5.2-5.2-13.6,0-18.8l29.1-29.1c1.9-1.9,4-3.5,6.2-4.9c5.4-3.3,11.5-5,17.7-5c4.8,0,9.5,1,13.9,3
				c6.9,3.1,15,1.8,20.4-3.6l9.3-9.3l42.4,42.4l-0.5,0.5l-15.8,15.8C298.4,118.8,296.7,121.8,296.1,125.1z"
					/>
				</g>
				<polygon
					style={{ fill: "#606060" }}
					points="349.3,78.7 322.5,105.6 317,100.1 274.6,57.7 268.8,51.9 295.6,25 		"
				/>
				<path
					style={{ fill: "#FFFFFF" }}
					d="M258.1,182.4l3.3,3.3c2,2,3.1,4.7,3.1,7.4c0,2.7-1,5.3-3.1,7.4c-4.1,4.1-10.7,4.1-14.8,0l-7.8-7.8
			c2,2,3,4.7,3,7.3c0,2.7-1,5.3-3.1,7.4c-4.1,4.1-10.7,4.1-14.8,0l-10.1-10.1c4.1,4.1,4.1,10.7,0,14.8c-2,2-4.7,3.1-7.4,3.1
			c-2.3,0-4.5-0.7-6.4-2.2h0c1.9-4.2,1.1-9.4-2.4-12.8c-4.5-4.5-11.8-4.5-16.3,0c4.5-4.5,4.5-11.8,0-16.3c-3.5-3.5-8.7-4.3-12.9-2.3
			l-0.5-0.5l-0.4-0.4l-0.8,0c2.3-4.3,1.6-9.8-2.1-13.5c-3.5-3.5-8.7-4.3-12.9-2.3l-5.1-5.1c-9.3-9.3-15.6-21.3-18-34.2
			c-0.7-3.6-2.4-6.8-5-9.4l-10.9-10.9l50.6-50.6l14.2,14.2c5,5,12.5,6.5,19.2,4.1c2.8-1,5.7-1.5,8.7-1.5c2.6,0,5.2,0.4,7.7,1.2
			c-2.2,1.4-4.3,3-6.2,4.9L178,106.6c-5.2,5.2-5.2,13.6,0,18.8c2.6,2.6,6,3.9,9.4,3.9c3.4,0,6.8-1.3,9.4-3.9l16.9-16.9l6.8,6.9
			c0,0,0,0,0.1,0.1c0,0,0.1,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2l50.3,50.3l1.4,1.4c2,2,3.1,4.7,3.1,7.4
			c0,2.7-1,5.3-3.1,7.4C268.8,186.5,262.2,186.5,258.1,182.4z"
				/>
				<polygon
					style={{ fill: "#FFFFFF" }}
					points="163.5,54.6 112.9,105.2 108.2,109.9 81.3,83.1 141.1,23.3 168,50.2 		"
				/>
				<path
					style={{ fill: "#606060" }}
					d="M352.7,76.2l-53.7-53.7c-1.7-1.7-4.5-1.7-6.3,0L266,49.3c-0.8,0.8-1.3,2-1.3,3.1c0,1.2,0.5,2.3,1.3,3.1
			l2.7,2.7l-6.2,6.2c-4,4-10.2,5.1-15.4,2.7c-4.9-2.2-10.4-3.4-15.8-3.4c-6.4,0-12.7,1.6-18.3,4.7c-2.3-0.6-4.7-0.9-7.1-0.9
			c-3.5,0-6.9,0.6-10.2,1.8c-5.2,1.9-10.8,0.7-14.6-3.1l-11-11.1l1.3-1.3c0.8-0.8,1.3-2,1.3-3.1c0-1.2-0.5-2.3-1.3-3.1l-26.8-26.8
			c-1.7-1.7-4.5-1.7-6.3,0L78.5,80.6c-1.7,1.7-1.7,4.5,0,6.3l26.8,26.8c0.8,0.8,2,1.3,3.1,1.3s2.3-0.5,3.1-1.3l1.6-1.6l7.8,7.8
			c2,2,3.3,4.4,3.8,7.1c2.6,13.9,9.2,26.5,19.2,36.5l1.5,1.5c-2.8,3-4.4,6.8-4.4,10.9c0,4.3,1.7,8.2,4.7,11.3
			c3.1,3.1,7.2,4.7,11.3,4.7c0.3,0,0.5,0,0.8,0c-0.2,4.3,1.3,8.7,4.6,12c3,3,7,4.7,11.3,4.7c0.1,0,0.2,0,0.4,0
			c-0.1,4.2,1.5,8.4,4.6,11.6c3,3,7,4.7,11.3,4.7c4.3,0,8.2-1.7,11.3-4.7c0.3-0.3,0.5-0.5,0.7-0.8c1.6,0.5,3.2,0.8,4.9,0.8
			c4,0,7.7-1.5,10.5-4.4c1.5-1.5,2.6-3.2,3.4-5.2l0.5,0.5c2.9,2.9,6.7,4.3,10.5,4.3s7.6-1.4,10.5-4.3c1.5-1.5,2.7-3.4,3.4-5.3
			c2.6,1.9,5.7,2.8,8.7,2.8c3.8,0,7.6-1.4,10.5-4.3c2.8-2.8,4.4-6.5,4.4-10.5c0-1.2-0.1-2.4-0.4-3.5c2.8-0.6,5.4-2,7.5-4.1
			c2.8-2.8,4.4-6.5,4.4-10.5c0-3.1-1-6.1-2.7-8.6l3.5-3.5c10.1-10.1,16.8-22.9,19.3-37c0.4-2.4,1.6-4.5,3.4-6.3l13.2-13.2l2.3,2.3
			c0.9,0.9,2,1.3,3.1,1.3c1.1,0,2.3-0.4,3.1-1.3l26.8-26.8c0.8-0.8,1.3-2,1.3-3.1C354,78.1,353.6,77,352.7,76.2z M87.9,83.7
			l53.5-53.5L162,50.8l-53.5,53.5L87.9,83.7z M152,180.9c-1.3-1.3-2.1-3.1-2.1-5c0-1.9,0.7-3.7,2.1-5c1.4-1.4,3.2-2.1,5-2.1
			c1.8,0,3.6,0.7,5,2.1c2.8,2.8,2.8,7.2,0,10C159.3,183.6,154.8,183.6,152,180.9z M173.7,199.6c-1.9,0-3.7-0.7-5-2.1
			c-2.8-2.8-2.8-7.2,0-10c1.4-1.4,3.2-2.1,5-2.1c1.8,0,3.6,0.7,5,2.1c2.8,2.8,2.8,7.2,0,10C177.4,198.9,175.6,199.6,173.7,199.6z
			 M194.9,213.8c-1.3,1.3-3.1,2.1-5,2.1s-3.7-0.7-5-2.1c-2.8-2.8-2.8-7.2,0-10c1.4-1.4,3.2-2.1,5-2.1c1.8,0,3.6,0.7,5,2.1
			C197.7,206.6,197.7,211,194.9,213.8z M270,179.9c-1.1,1.1-2.6,1.8-4.3,1.8c-1.6,0-3.1-0.6-4.3-1.8L218.6,137
			c-1.7-1.7-4.5-1.7-6.3,0c-1.7,1.7-1.7,4.5,0,6.3l46.2,46.2c1.1,1.1,1.8,2.6,1.8,4.3c0,1.6-0.6,3.1-1.8,4.3c-2.4,2.3-6.2,2.3-8.5,0
			l-7.8-7.8c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0l-35.8-35.8c-1.7-1.7-4.5-1.7-6.3,0c-1.7,1.7-1.7,4.5,0,6.3l35.9,35.9
			c1.1,1.1,1.7,2.6,1.7,4.2c0,1.6-0.6,3.1-1.8,4.3c-2.3,2.3-6.2,2.3-8.5,0l-39-39c-1.7-1.7-4.5-1.7-6.3,0c-1.7,1.7-1.7,4.5,0,6.3
			l28.9,28.9c0,0,0,0,0,0c1.1,1.1,1.8,2.6,1.8,4.2c0,1.6-0.6,3.1-1.8,4.3c-1.1,1.1-2.6,1.8-4.3,1.8c-0.4,0-0.8,0-1.1-0.1
			c0.7-4.8-0.8-9.9-4.5-13.6c-3-3-7-4.7-11.3-4.7c-0.1,0-0.2,0-0.4,0c0.1-4.2-1.5-8.4-4.6-11.6c-3-3-7-4.7-11.3-4.7
			c-0.3,0-0.5,0-0.8,0c0.2-4.3-1.3-8.7-4.6-12c-4-4-9.7-5.4-14.8-4.2l-3.2-3.2c-8.7-8.7-14.5-19.8-16.8-31.9
			c-0.8-4.4-3-8.5-6.2-11.7l-7.8-7.8l44.3-44.3l11.1,11.1c4.2,4.2,10,6.5,16,6.5c2.7,0,5.3-0.5,7.9-1.4c1.3-0.5,2.5-0.8,3.9-1
			L175.2,104c-3.3,3.3-5.2,7.8-5.2,12.5c0,4.7,1.8,9.2,5.2,12.5c3.5,3.5,8,5.2,12.5,5.2c4.5,0,9.1-1.7,12.5-5.2l13.7-13.7l3.7,3.7
			c0,0,0,0,0,0l0.2,0.2l0.1,0.1c0,0,0,0,0,0l0,0c0,0,0.1,0.1,0.1,0.1l51.8,51.8c1.1,1.1,1.8,2.6,1.8,4.3
			C271.8,177.2,271.2,178.7,270,179.9z M297.9,113.9c-3.1,3.1-5.1,6.9-5.8,11c-2.2,12.3-8,23.4-16.9,32.3l-3.4,3.4l-28-28
			c0.6,0,1.2,0.1,1.8,0.1c2.8,0,5.7-0.5,8.6-1.7c2.3-0.9,3.3-3.5,2.4-5.8c-0.9-2.3-3.5-3.3-5.8-2.4c-11.4,4.7-25-8.2-26.5-9.7
			c0,0-0.1,0-0.1-0.1l-0.2-0.2c0,0,0,0,0,0l-0.1-0.1c0,0,0,0,0,0l-3.7-3.7l4.2-4.2c1.7-1.7,1.7-4.5,0-6.3c-1.7-1.7-4.5-1.7-6.3,0
			l-7.3,7.3c0,0,0,0,0,0c0,0,0,0,0,0L194,122.8c-3.5,3.5-9.1,3.5-12.6,0c-1.7-1.7-2.6-3.9-2.6-6.3c0-2.4,0.9-4.6,2.6-6.3l29.1-29.1
			c1.6-1.6,3.4-3,5.4-4.2c4.6-2.8,9.9-4.3,15.4-4.3c4.2,0,8.3,0.9,12.1,2.6c8.6,3.9,18.7,2.1,25.3-4.5l6.2-6.2l36.2,36.2
			L297.9,113.9z M322.8,99.9l-47.4-47.4l20.6-20.6l47.4,47.4L322.8,99.9z"
				/>
				<circle style={{ fill: "#DEDEDE" }} cx="327.2" cy="82.7" r="4.4" />
				<circle style={{ fill: "#606060" }} cx="106.2" cy="87.1" r="4.4" />
			</g>
		</g>
	</svg>
);

export default HandshakeIcon;
