import create from "zustand";
import { OVERVIEW } from "modules/home/config";
const initialState = {
	candidateTab: OVERVIEW
};
const useProfileStore = create(set => ({
	...initialState,
	setItem: (key, value) => set({ [key]: value })
}));

export default useProfileStore;
