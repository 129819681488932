import { createSelector } from "reselect";
import _get from "lodash/get";

const getFilter = state => _get(state, "search.filter", []);
const getSectors = state => _get(state, "search.sectors", []);
const getSubSectors = state => _get(state, "search.subSectors", []);
const getFunctions = state => _get(state, "search.functions", []);

export const filterSelector = createSelector(getFilter, filter =>
	JSON.parse(JSON.stringify(filter))
);
export const sectorsSelector = createSelector(getSectors, sectors => sectors);
export const subSectorsSelector = createSelector(
	getSubSectors,
	sectors => sectors
);
export const functionsSelector = createSelector(
	getFunctions,
	functions => functions
);
