import create from "zustand";

const initialState = {
	visibleCategories: []
};

const useTieringStore = create(set => ({
	...initialState,
	setItem: (key, value) => set(() => ({ [key]: value }))
}));

export default useTieringStore;
