import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function EmailSignatureSkeleton() {
	return (
		<div
			style={{
				width: 504,
				padding: 16,
				position: "relative",
				background: "white",
				borderRight: "1px solid #e2e8f0",
				overflow: "hidden"
			}}
		>
			<SkeletonTheme color="#F1F1F1">
				<Skeleton
					width={150}
					height={20}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={80}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={150}
					height={20}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={150}
					height={20}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={150}
					height={20}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
				<Skeleton
					width={"100%"}
					height={40}
					style={{ borderRadius: 8, marginBottom: 16 }}
				/>
			</SkeletonTheme>
		</div>
	);
}
