import React, { useEffect } from "react";
import { Box, Flex, Text, Button } from "rebass";
import BoxContainer from "../boxContainer";

import { ReactComponent as PhoneIcon } from "static/icons/viewCandidate-icons-phone.svg";
import { ReactComponent as EmailIcon } from "static/icons/viewCandidate-icons-email.svg";
import { ReactComponent as LinkedinIcon } from "static/icons/elements-icons-social-linkedin.svg";
import PhoneNumberField from "common/PhoneNumberField";
import MailPhoneSave, {
	SAVE_MAIL,
	SAVE_PHONE_NUMBER,
	SAVE_LINKEDIN_PROFILE
} from "../../HOC/mailPhoneSave";
import get from "lodash/get";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Tooltip from "rc-tooltip";
import CopyValue from "modules/candidate/components/profileClientView/profileModalTab/components/personalTab/copyValue.js";
export const InputField = ({ input: { value, onChange }, hasError }) => {
	return (
		<Box
			sx={{
				border: hasError ? "1px solid #f54953" : "none",
				borderRadius: "3px",
				width: "100%"
			}}
		>
			<input
				value={value}
				onChange={onChange}
				style={{
					border: "none",
					fontSize: "16px",
					color: "#212b36",
					marginLeft: "6px",
					background: "transparent",
					fontWeight: "normal",
					width: "100%"
				}}
			/>
		</Box>
	);
};

const PersonalInfo = ({
	headline,
	isDrag,
	setIsOpenModalTab,
	setActiveTab,
	canEdit,
	save,
	mail,
	phoneNumber,
	setPhoneNumber,
	setMail,
	linkedInProfile,
	setLinkedInUrl,
	linkedInUrl,
	isEditMail,
	setIsEditMail,
	isEditPhone,
	setIsEditPhone,
	isEditLinkedIn,
	setIsEditLinkedIn,
	isSaveMailError,
	isSavePhoneError,
	cancelEdit,
	isAddMailToPoke,
	isFetching
}) => {
	const headlineEmail = get(headline, "email");
	useEffect(() => {
		headlineEmail && !mail && setMail(headlineEmail);
	}, [headlineEmail]);

	const headlinePhone = get(headline, "phone");
	useEffect(() => {
		headlinePhone && !phoneNumber && setPhoneNumber(headlinePhone);
	}, [headlinePhone]);
	useEffect(() => {
		linkedInProfile && !linkedInUrl && setLinkedInUrl(linkedInProfile);
	}, [linkedInProfile]);

	return (
		<BoxContainer
			setActiveTab={setActiveTab}
			setIsOpenModalTab={() => {
				cancelEdit();
				setIsOpenModalTab();
			}}
			isDrag={isDrag}
			isFetching={isFetching}
			title="Personal"
		>
			{isFetching ? (
				<React.Fragment>
					<Flex mb="16px" alignItems="center">
						<PhoneIcon style={{ marginRight: "8px" }} />
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={120} height={14} />
						</SkeletonTheme>
					</Flex>
					<Flex mb="16px" alignItems="center">
						<EmailIcon style={{ marginRight: "8px" }} />
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={150} height={14} />
						</SkeletonTheme>
					</Flex>
					<Flex mb="16px" alignItems="center">
						<LinkedinIcon style={{ marginRight: "8px" }} />
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={200} height={14} />
						</SkeletonTheme>
					</Flex>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Flex
						py={1}
						mb="12px"
						alignItems="center"
						justifyContent="space-between"
					>
						{isEditPhone ? (
							<React.Fragment>
								<PhoneNumberField
									input={{
										onChange: ({ formattedValue }) =>
											setPhoneNumber(formattedValue)
									}}
									classes="view-candidate"
									defaultValue={headline.phone}
									isErrorBlock={false}
									hasError={isSavePhoneError}
								/>
								<Flex alignSelf="flex-start">
									<Button
										onClick={() => {
											headlinePhone === phoneNumber
												? setIsEditPhone(false)
												: save(SAVE_PHONE_NUMBER);
										}}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											}
										}}
										mr={2}
									>
										Save
									</Button>
									<Button
										onClick={() => {
											setIsEditPhone(false);
											setPhoneNumber(headlinePhone);
										}}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											}
										}}
									>
										Cancel
									</Button>
								</Flex>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<Flex alignItems="flex-end">
									<PhoneIcon />
									<Text
										sx={{
											fontSize: "16px",
											color: "#212b36",
											marginLeft: "8px"
										}}
									>
										{phoneNumber ? (
											<CopyValue showCopyButton={"hidden"} text={"0696"}>
												{phoneNumber}
											</CopyValue>
										) : (
											"N/A"
										)}
									</Text>
								</Flex>
								{canEdit && (
									<Button
										onClick={() => setIsEditPhone(true)}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											}
										}}
									>
										{phoneNumber ? "Edit" : "Add"}
									</Button>
								)}
							</React.Fragment>
						)}
					</Flex>
					<Flex
						mb="12px"
						py={1}
						sx={{
							...(isAddMailToPoke && {
								backgroundColor: "white",
								borderRadius: "4px",
								position: "relative",
								zIndex: 99999999999
							})
						}}
						justifyContent="space-between"
					>
						{isEditMail ? (
							<React.Fragment>
								<Flex width={"60%"} alignItems="flex-end">
									<Box width="24px" height="24px">
										<EmailIcon />
									</Box>
									<InputField
										input={{
											value: mail,
											onChange: ({ target: { value } }) => setMail(value)
										}}
										hasError={isSaveMailError}
									/>
								</Flex>
								<Flex width={"30%"} justifyContent="flex-end">
									<Button
										onClick={() => {
											headlineEmail === mail
												? setIsEditMail(false)
												: save(SAVE_MAIL);
										}}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											},
											"&[disabled]": {
												cursor: "not-allowed"
											}
										}}
										mr={2}
										disabled={mail === ""}
									>
										Save
									</Button>
									<Button
										onClick={() => {
											setIsEditMail(false);
											setMail(headlineEmail);
										}}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											}
										}}
									>
										Cancel
									</Button>
								</Flex>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Flex width="80%" alignItems="flex-end">
									<Box width="24px" height="24px">
										<EmailIcon />
									</Box>

									{mail ? (
										<Tooltip
											placement="top"
											overlay={mail}
											trigger="hover"
											overlayClassName="function-tooltip"
										>
											<Text
												sx={{
													fontSize: "16px",
													color: "#212b36",
													marginLeft: "8px",
													padding: "0px",
													fontWeight: "normal",
													height: "26px",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
													width: "80%"
												}}
											>
												{mail ? (
													<CopyValue showCopyButton={"hidden"} text={mail}>
														{mail}
													</CopyValue>
												) : (
													"N/A"
												)}
											</Text>
										</Tooltip>
									) : (
										<Text
											sx={{
												fontSize: "16px",
												color: "#212b36",
												marginLeft: "8px",
												padding: "0px",
												fontWeight: "normal",
												height: "26px",
												overflow: "hidden",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												width: "80%"
											}}
										>
											N/A
										</Text>
									)}
								</Flex>
								{canEdit && (
									<Button
										onClick={() => setIsEditMail(true)}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											}
										}}
									>
										{mail ? "Edit" : "Add"}
									</Button>
								)}
							</React.Fragment>
						)}
					</Flex>
					<Flex
						id="add-email-form"
						py={1}
						alignItems="flex-end"
						justifyContent="space-between"
					>
						{isEditLinkedIn ? (
							<React.Fragment>
								<Flex width={"60%"} alignItems="flex-end">
									<Box width="24px" height="24px">
										<LinkedinIcon />
									</Box>

									<InputField
										input={{
											value: linkedInUrl,
											onChange: ({ target: { value } }) => setLinkedInUrl(value)
										}}
									/>
								</Flex>
								<Flex justifyContent="flex-end" width={"30%"}>
									<Button
										onClick={() => {
											linkedInProfile === linkedInUrl
												? setIsEditLinkedIn(false)
												: save(SAVE_LINKEDIN_PROFILE);
										}}
										mr={2}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											}
										}}
									>
										Save
									</Button>
									<Button
										onClick={() => {
											setIsEditLinkedIn(false);
											setLinkedInUrl(linkedInProfile);
										}}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											}
										}}
									>
										Cancel
									</Button>
								</Flex>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Flex width="70%" alignItems="flex-end">
									<Box width="24px" height="24px">
										<LinkedinIcon />
									</Box>

									{linkedInUrl ? (
										<Tooltip
											placement="top"
											overlay={linkedInUrl}
											trigger="hover"
											overlayClassName="function-tooltip"
										>
											<Text
												sx={{
													fontSize: "16px",
													color: "#212b36",
													marginLeft: "10px",
													padding: "0px",
													fontWeight: "normal",
													height: "26px",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
													width: "calc(100% - 24px)"
												}}
											>
												{linkedInUrl}
											</Text>
										</Tooltip>
									) : (
										<Text
											sx={{
												fontSize: "16px",
												color: "#212b36",
												padding: "0px",
												fontWeight: "normal",
												height: "26px",
												overflow: "hidden",
												marginLeft: "10px",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												width: "calc(100% - 24px)"
											}}
										>
											N/A
										</Text>
									)}
								</Flex>
								{canEdit && (
									<Button
										onClick={() => setIsEditLinkedIn(true)}
										sx={{
											fontSize: "16px",
											color: "#818a92",
											background: "transparent",
											border: "none",
											padding: "0px",
											fontWeight: "normal",
											"&:hover": {
												color: "#ff6849"
											}
										}}
									>
										{linkedInUrl ? "Edit" : "Add"}
									</Button>
								)}
							</React.Fragment>
						)}
					</Flex>
				</React.Fragment>
			)}
		</BoxContainer>
	);
};
// eslint-disable-next-line
export default ({ ...props }) => (
	<MailPhoneSave
		refetchProfileData={props.refetchProfileData}
		linkedInProfile={props.linkedInProfile}
		headline={props.headline}
		render={otherProps => <PersonalInfo {...props} {...otherProps} />}
	/>
);
