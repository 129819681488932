import React from "react";
import styles from "./request-pipeline-video-card.module.scss";
import { ReactComponent as VideoIcon } from "static/icons/cam-2.svg";
import cx from "classnames";

const RequestPipelineVideoCard = ({ className }) => {
	return (
		<div className={cx(styles.container, className)}>
			<VideoIcon />
			<div className={styles.videoCallText}>Video-call</div>
		</div>
	);
};

export default RequestPipelineVideoCard;
