import React from "react";
import Steps, { Step } from "rc-steps";
import PropTypes from "prop-types";
import GlobalTooltip from "common/GlobalTooltip";
import { StepsContainer } from "../styles/StyledInterview";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";

const StepsComponent = ({ steps }) => {
	const Icon = ({ type }) => <span className={`rc-steps-icon icon-${type}`} />;

	const getIcon = (status, index) => {
		switch (status) {
			case "process":
				return <span className="rc-steps-icon-override">{index + 1}</span>;
			case "finish":
				return <Icon type={"check2"} />;
			case "error":
				return <Icon type={"close2"} />;
			default:
				return "";
		}
	};

	return (
		<div className={"steps-container"}>
			<div className={"steps"}>
				<StepsContainer>
					<Steps labelPlacement="vertical">
						{steps.map((step, index) => {
							const isLast = index + 1 === steps.length;
							return step.tooltip ? (
								<GlobalTooltip
									key={index}
									maxWidth="480px"
									overlay={<div>{step.tooltip}</div>}
									overlayClassName="bottom-arrow"
									withWrappingDiv={false}
									align={{
										points: ["tc", "bc"],
										targetOffset: isLast ? ["0", "0"] : ["32%", "0"],
										overflow: { adjustX: true, adjustY: true }
									}}
								>
									<Step
										title={step.label}
										status={step.status}
										icon={getIcon(step.status, index)}
										prefixCls="rc-steps"
										stepNumber={String(index + 1)}
										style={{ cursor: "pointer" }}
									/>
								</GlobalTooltip>
							) : (
								<Step
									key={index}
									title={step.label}
									status={step.status}
									icon={getIcon(step.status, index)}
								/>
							);
						})}
					</Steps>
				</StepsContainer>
			</div>
		</div>
	);
};

StepsComponent.propTypes = {
	steps: PropTypes.array.isRequired
};

StepsComponent.defaultProps = {
	steps: []
};

export default StepsComponent;
