import React, { useState } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { Flex, Box, Text } from "rebass";
import { connect } from "react-redux";
import _has from "lodash/has";
import _get from "lodash/get";
import { useMutation } from "react-query";
import { v4 as uuid4 } from "uuid";

import FormField from "common/FormField";
import PrivacyWebSiteContact from "common/PrivacyWebSiteContact";
import PhoneNumberField from "common/PhoneNumberField";
import { ONLY_CHARS, emailRegex } from "config";
import checkPasswordRules from "common/PasswordChecker";
import { FormContainer } from "./Styled";
import S3Uploader from "common/S3Uploader";
import toaster from "common/Toaster";
import { ReactComponent as AttachFile } from "common/Icons/AttachFile.svg";

import {
	TYPE_PDF,
	TYPE_DOC,
	TYPE_DOCX,
	CV_SIZE_LIMIT,
	PERMANENT
} from "config";
import {
	PRE_SIGNED_URL_OFFLINE,
	GUEST_APPLY_TO_VACANCY_ENDPOINT
} from "config/api-endpoints";
import { client } from "lib/api-client";

const applyToJob = data =>
	client(GUEST_APPLY_TO_VACANCY_ENDPOINT, { body: data });

const OfflineApplicationModal = ({
	onClose,
	submitErrors,
	handleSubmit,
	vacancy_id,
	displayConfirmApplicationModal,
	setNewAccountEmail,
	email,
	displaySignInModal,
	vonqData,
	errorEmail,
	setTab,
	setActive
}) => {
	const [id] = useState(uuid4());
	const [error, setError] = useState(false);
	const [fileUrl, setFileUrl] = useState("");
	const [phoneError, setPhoneError] = useState(false);
	const [errorForm, setErrorForm] = useState(false);
	const [accountType, setAccountType] = useState("");

	const [mutate] = useMutation(applyToJob, {
		onSuccess: () => {
			toaster.success("Your application has successfully been sent!");
			onClose();
			setTab();
			setActive();
			displayConfirmApplicationModal();
			setNewAccountEmail(email);
		},
		onError: error => {
			if (_get(error, "detail.phone")) {
				return setPhoneError(true);
			}
			if (_get(error, "detail.email")) {
				toaster.danger("An account already exists for this email address.");
				errorEmail();
				if (_get(error, "detail.type") === PERMANENT) {
					setNewAccountEmail(email);
					displaySignInModal();
				} else {
					setAccountType(_get(error, "detail.type"));
					//displayEmailUsedModal(true);
					setErrorForm(true);
				}
				onClose();
			}
		}
	});

	const normalizeEmail = value => {
		return value.toLowerCase();
	};

	const progressComponent = ({ percentCompleted }) => {
		if (percentCompleted) {
			return (
				<div className="file-progress-box">
					<div className="size">{`${percentCompleted.toFixed(0)}%`}</div>
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	};
	const onUploadSuccess = data => {
		setFileUrl(data.url);
	};

	const resetFile = () => {
		onUploadSuccess(null);
	};

	const renderUploader = () => (
		<>
			<S3Uploader
				accept={[TYPE_PDF, TYPE_DOC, TYPE_DOCX]}
				id="cv-upload"
				multiple={false}
				folder={"candidate"}
				autoSubmit={true}
				onSuccess={onUploadSuccess}
				onClose={resetFile}
				resetOnSuccess={false}
				isLogin={true}
				onError={error =>
					toaster.danger(error, { id: "uploader-error", duration: 7 })
				}
				progressComponent={progressComponent}
				text={{
					choose: "Upload your CV",
					label: "CV document",
					placeholder: (
						<p
							style={{
								fontFamily: "BasierCircle",
								fontSize: "13px",
								fontWeight: "normal",
								color: "#0f1c46"
							}}
						>
							Upload a .doc, .docx, or pdf file
						</p>
					),
					svg: <AttachFile />
				}}
				preSignedUrl={PRE_SIGNED_URL_OFFLINE}
				preSignedData={{ id: id }}
				sizeLimit={CV_SIZE_LIMIT}
			/>
			<div className="help-block inline-error">
				{error && !fileUrl && <>Please upload a file before submitting</>}
			</div>
		</>
	);

	const submit = ({ accept_policies, first_name, last_name, email, phone }) => {
		if (!fileUrl) {
			return setError(true);
		}
		mutate({
			id,
			first_name,
			last_name,
			phone: phone.formattedValue,
			email,
			accept_policies,
			cv_doc: fileUrl,
			vacancy_id,
			source_id: _get(vonqData, "source_id"),
			campaign_id: _get(vonqData, "campaign_id")
		});
	};
	return (
		<>
			{/* <DescriptionText>
				For the position of <span className="infos">{`"${vacancyTitle}"`}</span>{" "}
				at <span className="infos">{`"${companyName}"`} </span>in{" "}
				<span className="infos"> {`"${city}"`}</span>.
				<div>
					{" "}
					Already have a wiggli account?{" "}
					<button className="link" onClick={loginAndApply}>
						Log in &amp; apply here
					</button>
				</div>
				<div className="bold">
					No account yet? Fill the form below to complete your application
				</div>
			</DescriptionText> */}
			{errorForm ? (
				<Flex
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					mb={33}
				>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "21px",
							fontWeight: "500",
							letterSpacing: "0.11px",
							color: "rgb(193, 69, 69)"
						}}
					>
						Email Already used!
					</Text>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "16px",
							letterSpacing: "0.08px",
							color: "rgb(193, 69, 69)",
							textAlign: "center"
						}}
					>
						You can’t apply on wiggli with this email address as it is linked to
						a {accountType} account. <br />
						If you wish to apply anyway, just use the form below to submit your
						application using another email address.
					</Text>
				</Flex>
			) : null}

			<FormContainer>
				<Flex className="formFieldStyle" mb={0}>
					<Box width={1 / 2} mr={18}>
						<Field
							type="text"
							name="first_name"
							label="First name"
							component={FormField}
						/>
					</Box>
					<Box width={1 / 2}>
						<Field
							type="text"
							name="last_name"
							label="Last name"
							component={FormField}
						/>
					</Box>
				</Flex>
				<Flex className="formFieldStyle" mb={0}>
					<Box width={1 / 2} mr={18}>
						<div className="form-group">
							<Field
								name="phone"
								label="Phone number"
								component={PhoneNumberField}
								placeholder=" "
							/>
							<div className="help-block inline-error phone">
								{phoneError && "The input does not match a phone number format"}
							</div>
						</div>
					</Box>
					<Box width={1 / 2}>
						<Field
							type="text"
							name="email"
							label="Email"
							component={FormField}
							normalize={normalizeEmail}
							hasError={
								(_has(submitErrors, "email_already_exist") &&
									_has(submitErrors, "email")) ||
								errorForm
							}
						/>
					</Box>
				</Flex>

				{renderUploader()}
				<PrivacyWebSiteContact isPermanent={true} marginTop={"30px"} />

				<button onClick={handleSubmit(submit)} className="btn btn-primary">
					Apply
				</button>
			</FormContainer>
		</>
	);
};

const validate = formProps => {
	const errors = {};
	if (!formProps.accept_policies) {
		errors.accept_policies = "You must accept the Privacy Policy.";
	}

	if (!formProps.first_name) {
		errors.first_name = "First name is required.";
	} else if (!ONLY_CHARS.test(formProps.first_name)) {
		errors.first_name = "First name accept only letters.";
	}
	if (!formProps.last_name) {
		errors.last_name = "Last name is required.";
	} else if (!ONLY_CHARS.test(formProps.last_name)) {
		errors.last_name = "Last name accept only letters.";
	}
	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Email is required.";
	} else if (formProps.email && !emailRegex.test(formProps.email)) {
		errors.email = "Email is invalid.";
	}
	if (!formProps.phone || !_get(formProps, "phone.value.length")) {
		errors.phone = "Please enter your phone number.";
	}
	if (!formProps.cv_doc) {
		errors.cv_doc = "Please choose your CV file.";
	}

	errors.password = checkPasswordRules(formProps.password);

	return errors;
};

const selector = formValueSelector("signup-application");
const mapStateToProps = state => {
	return {
		email: selector(state, "email")
	};
};

export default connect(mapStateToProps)(
	reduxForm({
		form: "signup-application",
		validate,
		touchOnBlur: false
	})(OfflineApplicationModal)
);
