import React from "react";
import Notes from "modules/candidate/components/profileClientView/profileModalTab/components/notes";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";

const NotesContainer = ({ profileData, onSeeAllNotesClick, useScroll }) => {
	const user = useSelector(onlineUserSelector);
	const fullName =
		get(profileData, "personal_profile.first_name") +
		" " +
		get(profileData, "personal_profile.last_name");
	return (
		<div>
			<Notes
				profileID={get(profileData, "user_id", "")}
				applicationId={get(profileData, "_id", "")}
				fullName={fullName}
				userId={user.id}
				onSeeMoreClick={onSeeAllNotesClick}
				useScroll={useScroll}
			/>
		</div>
	);
};

export default NotesContainer;
