import React from "react";
import cx from "classnames";
import get from "lodash/get";

import Body from "./Body";
import StatusLabel from "common/StatusLabel";
import { formatDate } from "common/Functions";
import {
	REJECTED,
	CANCELLED,
	INTERVIEW,
	PHONE_SCREEN,
	PHONE_SCREENING,
	OFFER
} from "config";
import styles from "./accordion.module.scss";

const AccordionContent = ({ show, body }) => {
	const type = get(body, "type");
	const interviewStatus = get(body, "interviewStatus");
	const applicationStage = get(body, "applicationStage");
	const applicationVisibleStatus = get(body, "applicationVisibleStatus", "");
	const lastStatusChangedOn = get(body, "lastStatusChangedOn", "");
	const applicationStatus = get(body, "applicationStatus");
	const typeName =
		applicationStatus === REJECTED || applicationStatus === CANCELLED
			? "Withdrawn/Rejected"
			: applicationStage;
	return (
		<div className={cx(styles.accordionContent, { [styles["show"]]: show })}>
			<div className={styles.accordionBody}>
				<div className={styles.titleRow}>
					<div className={styles.flex}>
						<span className={styles.text}>Currently at</span>
						<p className={styles.status}>{typeName}</p>

						<StatusLabel
							status={
								type === OFFER ||
								type === INTERVIEW ||
								type === PHONE_SCREEN ||
								type === PHONE_SCREENING
									? interviewStatus
									: applicationStatus
							}
							label={applicationVisibleStatus}
							isInterview={
								type === INTERVIEW ||
								type === PHONE_SCREEN ||
								type === PHONE_SCREENING
							}
						/>
					</div>
					<div className={styles.flex}>
						<span className={styles.text}>Last update</span>
						<p className={styles.status}>{formatDate(lastStatusChangedOn)}</p>
					</div>
				</div>

				<Body body={body} />
			</div>
		</div>
	);
};
export default AccordionContent;
