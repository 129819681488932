import React, { useEffect, useState } from "react";
import Avatar from "common/Avatar/index";
import {
	BodyContainer,
	ClientName,
	DescriptionDetail,
	InterviewDesc,
	AvatarHolder,
	Title,
	UserSender,
	UserSenderName,
	Content,
	PropositionBox,
	BoxInterview
} from "./styled";
import { formatDate } from "common/Functions";
import {
	TIME_FORMAT_SYMBOL,
	VIDEO_CONFERENCE,
	PERMANENT,
	DECLINED,
	CANCELLED,
	SENT,
	REFUSED,
	DONE,
	EXPIRED,
	ACCEPTED,
	LOCATION_TYPE_PHONE_SCREEN
} from "config";
import Description from "common/Description";
import ContactPerson from "common/contactPerson";
import { ReactComponent as CalendarIcon } from "static/icons/calendar_icon.svg";
import { ReactComponent as LocationIcon } from "static/icons/location-icon.svg";
import { ReactComponent as ClockIcon } from "static/icons/clock-1.svg";
import Calendar from "common/DatePicker/Calendar/Calendar";
import styles from "./interview-details.module.scss";
import cx from "classnames";
import { getDate, groupByDate } from "./utils/helpers";
import { timestampToDateCalendar } from "config/helpers";
import { DATA_MMMM_D_YYYY } from "config";
import { CalendarDate } from "@internationalized/date";
import { FieldError } from "common/FieldError";

const InterviewDetail = props => {
	const {
		invitationStartTime,
		invitationEndTime,
		locationInfo,
		locationType,
		clientName,
		lastUserAction,
		descInterview,
		status,
		userSenderAvatar,
		attendeesUsers = [],
		availabilitySlots = [],
		setInterviewTime,
		interviewTimeSelected
	} = props;
	const slots = groupByDate(availabilitySlots);
	let [focusedDate, setFocusedDate] = React.useState(
		timestampToDateCalendar(availabilitySlots[0]?.start_date)
	);
	const [activeDate, setActiveDate] = useState(null);
	const formatedInvitationDate = formatDate(
		invitationStartTime,
		true,
		DATA_MMMM_D_YYYY
	);
	const { year, month, day } = getDate(activeDate) || {};

	const value =
		year && month && day
			? new CalendarDate(parseInt(year), parseInt(month), parseInt(day))
			: null;
	const formattedInvitationStartTime = formatDate(
		invitationStartTime,
		true,
		TIME_FORMAT_SYMBOL
	);
	const formattedInvitationEndTime = formatDate(
		invitationEndTime,
		true,
		TIME_FORMAT_SYMBOL
	);

	const renderLocationType = locationType => {
		return locationType === "company_address"
			? "Location - Company Address"
			: "Location -  Video Conference";
	};

	useEffect(() => {
		if (availabilitySlots.length === 1) {
			setActiveDate(
				`${focusedDate.year}/${focusedDate.month}/${focusedDate.day}`
			);
			setInterviewTime(availabilitySlots[0]);
		}
	}, [availabilitySlots]);

	const isDateUnavailable = date => {
		return !Object.keys(slots)
			.map(item => item)
			.some(enabledDate => {
				const { year, month, day } = getDate(enabledDate);
				return (
					date.year === parseInt(year) &&
					date.month === parseInt(month) &&
					date.day === parseInt(day)
				);
			});
	};

	const setActiveDateCalendar = date => {
		const activeDate = `${date.year}/${date.month}/${date.day}`;
		setActiveDate(activeDate);
		setInterviewTime(slots[activeDate][0]);
	};
	return (
		<>
			{status === (ACCEPTED || DONE) ? (
				<ClientName>{"Confirmed date & time"}</ClientName>
			) : (
				<ClientName>{`${
					lastUserAction !== PERMANENT ? clientName : "Your"
				} proposition`}</ClientName>
			)}

			<BodyContainer>
				<div className="section-proposition">
					<PropositionBox maxwidth fullWidth>
						<div className="BoxWrapper">
							<BoxInterview height="auto" border="none" boxShadow="none">
								<div className="contentBox">
									<h3 className="titleBox">
										<CalendarIcon />
										{"Date & time"}
									</h3>
									<div
										className={cx(
											"detailsInterview",
											status === ACCEPTED ? "borderless" : ""
										)}
									>
										{status === ACCEPTED ? (
											<>
												<span className="dateInterview">
													{formatedInvitationDate}
												</span>
												<span className="timeInterview">
													{formattedInvitationStartTime}{" "}
													<i className="icon-arrow-right-new" />{" "}
													{formattedInvitationEndTime}
												</span>
											</>
										) : (
											<>
												<Calendar
													onChange={setActiveDateCalendar}
													isMultiple={Array.isArray(value)}
													isDateUnavailable={isDateUnavailable}
													focusedValue={focusedDate}
													onFocusChange={setFocusedDate}
													className={styles.detailsInterviewCalendar}
												/>
												<div className={styles.times}>
													<span className={styles.title}>
														<ClockIcon />
														Select time
													</span>

													<div className={styles.slots}>
														{!activeDate ? (
															<span className={styles.desciption}>
																Please select a date
															</span>
														) : (
															<>
																{slots[activeDate]?.map(time => (
																	<button
																		key={time}
																		className={cx(styles.slot, {
																			[styles.selected]:
																				interviewTimeSelected?.start_date ===
																					time.start_date &&
																				interviewTimeSelected?.end_date ===
																					time.end_date
																		})}
																		onClick={() => setInterviewTime(time)}
																	>
																		{formatDate(
																			time.start_date,
																			true,
																			TIME_FORMAT_SYMBOL
																		)}{" "}
																		-{" "}
																		{formatDate(
																			time.end_date,
																			true,
																			TIME_FORMAT_SYMBOL
																		)}
																	</button>
																))}
															</>
														)}
													</div>
												</div>
											</>
										)}
									</div>
									{interviewTimeSelected?.error && (
										<FieldError
											error={{ message: interviewTimeSelected.error }}
										/>
									)}
								</div>
							</BoxInterview>
							{locationType !== LOCATION_TYPE_PHONE_SCREEN && (
								<BoxInterview height="auto" border="none" boxShadow="none">
									<>
										{locationType === VIDEO_CONFERENCE ? (
											<>
												<div className="contentBox videoConference">
													<h3 className="titleBox">
														<LocationIcon />
														Location - video conference
													</h3>
													<span className="detailsInterview">
														Virtual Meeting Room
													</span>
												</div>
											</>
										) : (
											<>
												<div className="contentBox">
													<h3 className="titleBox">
														<LocationIcon />
														{renderLocationType(locationType)}
													</h3>
													<Content>{locationInfo}</Content>
												</div>
											</>
										)}
									</>
								</BoxInterview>
							)}
						</div>
						<div>
							{attendeesUsers && attendeesUsers.length > 0 && (
								<ContactPerson attendeesUsers={attendeesUsers} />
							)}
						</div>
					</PropositionBox>
				</div>

				{(!attendeesUsers || attendeesUsers.length == 0) && (
					<UserSender>
						<Title>CONTACT PERSON</Title>
						<AvatarHolder>
							<Avatar src={userSenderAvatar} name={clientName} />
						</AvatarHolder>
						<UserSenderName>{clientName}</UserSenderName>
					</UserSender>
				)}
			</BodyContainer>
			{descInterview && status === ACCEPTED && (
				<InterviewDesc>
					<div className="description-title">Message</div>
					<DescriptionDetail>
						<Description description={descInterview} max={100} />
					</DescriptionDetail>
				</InterviewDesc>
			)}
			{descInterview && status !== ACCEPTED && (
				<div className="interview-desc">
					<InterviewDesc>
						<div className="description-title">Message</div>
						<DescriptionDetail>
							<Description description={descInterview} max={100} />
						</DescriptionDetail>
					</InterviewDesc>
				</div>
			)}
		</>
	);
};

export const getInterviewStatusTooltip = (status, islastUserSenderClient) => {
	switch (status) {
		case SENT:
		case DECLINED:
			switch (islastUserSenderClient) {
				case true:
					return "Please reply to the client's interview proposition";
				case false:
					return "Expecting Client's feedback.";
				default:
					return "";
			}
		case REFUSED:
		case CANCELLED:
			switch (islastUserSenderClient) {
				case true:
					return "Your application has been rejected by the client.";
				case false:
					return "You have canceled your application.";
				default:
					return "";
			}
		case DONE:
			return "Expecting Client's feedback.";
		case ACCEPTED:
			return "The interview date, time and place are confirmed.";
		case EXPIRED:
			return "The interview proposition is overdue, please propose another date.";
		default:
			return "";
	}
};
export default InterviewDetail;
