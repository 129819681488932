import React from "react";
import { ReactComponent as Vector } from "static/vectors/evaluation-empty-state.svg";
import styles from "./evaluation-empty-block.module.scss";

export default function EvaluationEmptyBlock() {
	return (
		<div className={styles.container}>
			<div className={styles.emptyBlock}>
				<Vector />
				<h3 className={styles.title}>Explore Your Evaluation Templates</h3>
				<p className={styles.description}>
					Simply by selecting a template from the list on the right to view its
					details and start crafting your own template.
				</p>
			</div>
		</div>
	);
}
