import React from "react";
import {
	CANDIDATES_MODULE_ID,
	SOURCES
} from "modules/SearchCandidate/utils/constants";
import FilterPill from "common/FilterPill/FilterPill";
import get from "lodash/get";
import { getSourceByKey } from "common/FilterDrawer/render_utils";

const FilterPills = ({
	filters,
	data_source,
	parent_styles,
	small = false,
	classes
}) => {
	return (
		<div className={classes.filters_div}>
			{data_source !== "all" && (
				<FilterPill
					module_id={CANDIDATES_MODULE_ID}
					filter={{
						name: "data_source",
						label: "Search on",
						operator: "",
						value: get(getSourceByKey(SOURCES, data_source), "label", "")
					}}
					isButton={false}
					styles={parent_styles}
					variant="marginless"
					small={small}
					localFormatting={true}
				/>
			)}

			{filters.map((filter, index) => {
				return (
					<FilterPill
						module_id={CANDIDATES_MODULE_ID}
						key={index}
						filter={filter}
						isButton={false}
						styles={parent_styles}
						variant="marginless"
						small={small}
						localFormatting={true}
					/>
				);
			})}
		</div>
	);
};

export default FilterPills;
