/* eslint-disable react/display-name */
import React, { useState } from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import _get from "lodash/get";
import Avatar from "common/Avatar/index";
import {
	PENDING,
	EDIT_PROFILE,
	BLOCK_USER,
	UNBLOCK_USER,
	TRANSFERT_OWNERSHIP,
	RESEND_INVITATON,
	SENT,
	ACCEPTED,
	EXPIRED,
	BLOCKED,
	CREATED,
	ROLES,
	PROJECT_MANAGER,
	LIMIT_ITEMS,
	SUPER_ADMIN,
	DELETE_INVITATON
} from "config";
import GlobalTooltip from "common/GlobalTooltip";
import { Tag, CenterTooltip } from "common/styled/CommonStyled";
import { Tr, Td } from "../../../timesheets/components/TimesheetStyled";
import { ReactComponent as SwitchIcon2 } from "static/icons/switch-icon2.svg";
import { ADMIN } from "config";

const isManagerIn = groups =>
	groups.reduce((acc, group) => {
		if (group.role_name === PROJECT_MANAGER) acc.push(group._id);
		return acc;
	}, []);

export default ({
	user,
	onMenuSelect,
	connectedUser,
	isManager,
	isAdminIn
}) => {
	const invitationStatus =
		user.invitation_status === SENT || user.invitation_status === CREATED
			? PENDING
			: user.invitation_status;

	const isOwner = user.is_owner;
	const isConnectedUser = user._id === connectedUser.id;
	const isConnectedUserOwner =
		!connectedUser.is_member && connectedUser.role_name === ADMIN;
	const isConnectedUserSuperAdmin =
		!connectedUser.is_member || connectedUser.role_name === SUPER_ADMIN;
	const isConnectedUserAdmin = !isConnectedUserSuperAdmin && !isManager;
	const isUserBlocked = user.blocked || user.invitation_status === BLOCKED;
	const canManage =
		isConnectedUserSuperAdmin ||
		(!isOwner &&
			isConnectedUserAdmin &&
			isAdminIn.some(
				id => isManagerIn(user.group_roles_name).indexOf(id) !== -1
			));

	const showTooltip =
		isOwner ||
		isConnectedUser ||
		isManager ||
		!canManage ||
		(isConnectedUserAdmin && isUserBlocked);

	let overlay = "You can’t manage your own role.";

	const [expanded, setExpanded] = useState(false);

	if (
		(!canManage && !isConnectedUser) ||
		(isConnectedUserAdmin && isUserBlocked)
	)
		overlay = "You can’t manage this user.";

	if (isManager) overlay = "As a Project Manager, you can’t manage user roles.";

	if (!isManager && !isConnectedUser && isOwner)
		overlay = "You can’t manage your super admin's role.";

	const menu = (
		<Menu onClick={e => onMenuSelect(e, user)} selectable={false}>
			{((user._id && !user.blocked) ||
				(!user._id &&
					user.invitation_status !== BLOCKED &&
					user.invitation_status !== EXPIRED)) && (
				<MenuItem key={EDIT_PROFILE}>
					<i className="icon-pen" /> Edit Profile
				</MenuItem>
			)}
			{/* Logged user is owner and user is not blocked and has role superadmin */}
			{isConnectedUserOwner &&
				user.role_name === SUPER_ADMIN &&
				[CREATED, SENT, ACCEPTED].indexOf(user.invitation_status) !== -1 &&
				!user.blocked &&
				!user.invitation_id && (
					<MenuItem key={TRANSFERT_OWNERSHIP}>
						<span style={{ paddingLeft: "9px", paddingRight: "13px" }}>
							<SwitchIcon2 />
						</span>
						Transfer ownership
					</MenuItem>
				)}
			{isConnectedUserSuperAdmin &&
				[CREATED, SENT, ACCEPTED].indexOf(user.invitation_status) !== -1 &&
				!user.blocked && (
					<MenuItem key={BLOCK_USER}>
						<i className="icon-block" /> Block user
					</MenuItem>
				)}
			{isConnectedUserSuperAdmin &&
				(user.invitation_status === BLOCKED || user.blocked) && (
					<MenuItem key={UNBLOCK_USER}>
						<i className="icon-unblock" /> Unblock user
					</MenuItem>
				)}
			{[CREATED, SENT, EXPIRED].indexOf(user.invitation_status) !== -1 && (
				<MenuItem key={RESEND_INVITATON}>
					<i className="icon-send" /> Resend invitation
				</MenuItem>
			)}
			{[CREATED, SENT, EXPIRED].indexOf(user.invitation_status) !== -1 && (
				<MenuItem key={DELETE_INVITATON}>
					<i className="icon-delete icon-delete-size" /> Delete invitation
				</MenuItem>
			)}
		</Menu>
	);
	const fullName = `${user.first_name} ${user.last_name}`;

	const roleRender = (userGroupeRoles = []) => {
		userGroupeRoles = expanded
			? user.group_roles_name
			: user.group_roles_name.slice(0, LIMIT_ITEMS);

		return !user.invitation_id &&
			!user.is_member &&
			user.role_name === ADMIN ? (
			<Tag>
				<span>Super Admin</span> - owner
			</Tag>
		) : !user.invitation_id &&
		  (user.is_owner || user.role_name === SUPER_ADMIN) ? (
			<Tag>
				<span>Super Admin</span> - all
			</Tag>
		) : userGroupeRoles.length > 0 ? (
			userGroupeRoles.map(department => {
				return (
					<Tag key={department._id}>
						<strong>
							{_get(
								ROLES.find(role => role.name === department.role_name),
								"label",
								""
							)}
						</strong>{" "}
						- {department.name}
					</Tag>
				);
			})
		) : (
			<Tag warning>This user has no role!</Tag>
		);
	};

	const showMore = () => {
		setExpanded(!expanded);
	};

	return (
		<Tr>
			<Td>
				<div className="user">
					<div style={{ display: "flex", marginRight: 10, float: "left" }}>
						<Avatar src={user.avatar} name={fullName} />
					</div>
					<div className="info">
						<span className="name">{fullName}</span>
						{((invitationStatus && invitationStatus !== ACCEPTED) ||
							user.blocked) && (
							<span
								className={`invitation-status ${
									user.blocked ? "blocked" : invitationStatus
								}`}
							>
								{" "}
								{user.blocked ? "User blocked" : `Invite ${invitationStatus}`}
							</span>
						)}
					</div>
				</div>
			</Td>
			<Td>{user.email}</Td>
			<Td className="role">
				{roleRender()}
				{user.group_roles_name.length > LIMIT_ITEMS ? (
					<>
						<Tag className="showMoreBtn" onClick={showMore}>
							{expanded ? "Show less" : "Show more"}
						</Tag>
					</>
				) : null}
			</Td>
			<Td className="actions">
				{showTooltip ? (
					<CenterTooltip>
						<GlobalTooltip placement="top" overlay={overlay} />
					</CenterTooltip>
				) : (
					<RcDropDown
						placement={"bottomCenter"}
						align={{
							points: ["tc", "bc"],
							offset: [-2, 5]
						}}
						trigger={["click"]}
						overlay={menu}
					>
						<div className="dots">
							<div className="dot" />
							<div className="dot" />
							<div className="dot" />
						</div>
					</RcDropDown>
				)}
			</Td>
		</Tr>
	);
};
