//TODO create one label instead multiple
//TODO Hide conditional inputs
import React, { useCallback, useState } from "react";
import { getPostingRequirementsAutoCompleteData } from "../../helper/getPostingRequirementsAutoCompleteData";
import Editor from "common/Editor";
import { useDebounce } from "react-use";
import InputField from "modules/vonqContract/components/InputFiled";
import DatePicker from "common/DatePickerField.js";
import SelectField from "modules/vonqContract/components/SelectField";
import styles from "./posting-requirements-form-input.module.scss";
import useGetAutoCompleteFieldData from "modules/publishJob/api/useGetAutocompleteFieldData";
import { PostingRequirementsCascader } from "../PostingRequirementsCascader";
import { get, isArray } from "lodash";

const PostingRequirementsFormInput = ({
	field: {
		label,
		type,
		name,
		autocomplete,
		options,
		description,
		required,
		display_rules
	},
	value,
	onChange,
	channel,
	errorMessage,
	onFieldChange,
	watch,
	onFetchSuccess
}) => {
	const [showToolbaar, setShowToolbar] = useState(false);
	let parent = display_rules?.show?.[0]?.facet;
	let searchProp = watch(parent);
	const [search, setSearch] = useState("");
	const [autoCompleteCallback, setAutoCompleteCallback] = useState(null);
	const [autocompleteField, SetAutocompleteField] = useState("");
	const { data } = useGetAutoCompleteFieldData(
		{
			term: searchProp?.value,
			product_id: channel.contract_id || channel.channel_id,
			posting_name: name,
			credentials: {}
		},
		{
			enabled:
				parent &&
				type !== "HIER" &&
				type !== "HTMLAREA" &&
				(searchProp?.length ? searchProp?.length > 0 : !!searchProp?.value) &&
				get(options, "length", 0) === 0
		}
	);

	useDebounce(
		() => {
			if (!autoCompleteCallback || !getAutoCompleteData || parent) return;

			getAutoCompleteData(search)
				.then(res => {
					const data = res.map(({ key, label }) => ({
						label,
						value: key
					}));
					onFetchSuccess(name, res);
					autoCompleteCallback(null, { options: data, complete: true });
				})
				.catch(err => {
					autoCompleteCallback(err);
				});
		},
		500,
		[
			search,
			autoCompleteCallback,
			autocompleteField,
			channel,
			getAutoCompleteData
		]
	);

	const onSearch = (search, callback, fieldName) => {
		setSearch(search);
		setAutoCompleteCallback(() => callback);
		SetAutocompleteField(fieldName);
	};

	const getAutoCompleteData = useCallback(
		search => {
			const body = {
				term: search,
				product_id: channel.contract_id || channel.channel_id,
				posting_name: autocompleteField,
				credentials: {}
			};

			return getPostingRequirementsAutoCompleteData(body);
		},
		[getPostingRequirementsAutoCompleteData, channel, autocompleteField]
	);

	const handleSelectChange = value => {
		if (isArray(value)) {
			onChange(value.map(item => item.key));
		} else {
			onChange(value?.key || value);
		}

		onFieldChange(name, value);
	};

	let input;

	if (["SELECT", "AUTOCOMPLETE", "MULTIPLE", "TEXTEXPAND"].includes(type)) {
		let componentType;

		if (autocomplete && !parent) {
			componentType = "async";
		} else if (type === "TEXTEXPAND") {
			componentType = "creatable";
		} else {
			componentType = "notAsync";
		}

		input = (
			<SelectField
				key={name}
				value={value}
				simpleValue
				onChange={handleSelectChange}
				label={
					<>
						{label}
						{required && <span className={styles.red}>*</span>}
					</>
				}
				options={parent && data ? data : options}
				description={description}
				multi={["MULTIPLE", "TEXTEXPAND"].includes(type)}
				labelKey="label"
				valueKey="key"
				error={errorMessage}
				type={componentType}
				name
				onSearch={(search, callback) => onSearch(search, callback, name)}
				className={styles.select}
			/>
		);
	} else if (["TEXT", "TEXTAREA", "STATISCH"].includes(type)) {
		input = (
			<InputField
				key={name}
				value={value}
				onChange={e => {
					onChange(e);
					onFieldChange(name);
				}}
				type="text"
				label={
					<>
						{label}
						{required && <span className={styles.red}>*</span>}
					</>
				}
				description={description}
				error={errorMessage}
				className={styles.input}
			/>
		);
	} else if (type === "DATE") {
		input = (
			<div className={styles.dateContainer}>
				<DatePicker
					className={"start_date"}
					inputClassName={styles.datePicker}
					labelClassName={styles.dateLabel}
					label={
						<>
							{label}
							{required && <span className={styles.red}>*</span>}
						</>
					}
					placeholder="Type date..."
					minDate={window.moment().toDate()}
					input={{
						onChange: e => {
							onChange(e);
							onFieldChange(name);
						},
						value,
						name: name
					}}
					meta={{
						touched: Boolean(errorMessage),
						error: errorMessage
					}}
				/>
			</div>
		);
	} else if (type === "HTMLAREA") {
		return (
			<>
				<label className={styles.label}>
					{label} {required && <span className={styles.asterisk}>*</span>}
				</label>
				<Editor
					placeholder="Start typing..."
					onChange={onChange}
					oldUsers={[]}
					value={value}
					showToolbaar={showToolbaar}
					setShowToolbar={setShowToolbar}
				/>
			</>
		);
	} else {
		input = (
			<PostingRequirementsCascader
				options={options}
				errorMessage={errorMessage}
				label={label}
				required={required}
				onFieldChange={onFieldChange}
				onChange={onChange}
				value={value}
				channel={channel}
				name={name}
				fieldId={watch(display_rules?.show?.[0]?.facet)}
			/>
		);
	}

	return input;
};

export default PostingRequirementsFormInput;
