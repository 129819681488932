import React from "react";
import { useDispatch } from "react-redux";
import { readNotifications } from "../actions";
import { LATEST, UNREAD } from "config";
import styles from "./style.module.scss";
import cx from "classnames";
import { ReactComponent as SettingsIcon } from "static/icons/cog.svg";
import { browserHistory } from "react-router";

const HeaderActions = ({ view, setView, latestCount, unreadCount }) => {
	const dispatch = useDispatch();
	const offset = 0;
	const markAllRead = () => {
		dispatch(readNotifications(true, view, offset));
	};

	return (
		<div className={styles.header}>
			<div className={styles.title}>
				<h1 className={styles.text}>Notifications</h1>
				<button onClick={() => markAllRead} className={styles.mark_as_read}>
					Mark all as read
				</button>
			</div>
			<div className={styles.nav}>
				<div className={styles.filters}>
					<button
						onClick={() => setView(LATEST)}
						className={cx(styles.filter_btn, view === LATEST && styles.active)}
					>
						<span>All</span>
						{!!latestCount && <div className={styles.badge}>{latestCount}</div>}
					</button>
					<button
						onClick={() => setView(UNREAD)}
						className={cx(styles.filter_btn, view === UNREAD && styles.active)}
					>
						<span>Unread</span>
						{!!unreadCount && <div className={styles.badge}>{unreadCount}</div>}
					</button>
				</div>
				<button
					className={styles.settings_btn}
					onClick={() => browserHistory.push("/settings/notifications")}
				>
					<SettingsIcon />
					<span>Settings</span>
				</button>
			</div>
		</div>
	);
};

export default HeaderActions;
