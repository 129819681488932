import React, { useState, useEffect } from "react";
import { Box, Flex } from "rebass";
import BodyClassName from "react-body-classname";
import TableSpend from "./components/TableSpend";
import InfoCompanySection from "modules/SuppliersManagement/components/InfoCompanySection";
import StatisticPerformance from "modules/SuppliersManagement/components/StatisticPerformance";
import CsvButton from "modules/SuppliersManagement/components/CsvButton";
import TopSection from "modules/SuppliersManagement/components/TopSections";

import { useSelector } from "react-redux";
import _get from "lodash/get";
import { useQuery, useMutation } from "react-query";
import { client } from "lib/api-client";
import {
	GET_LIST_COMPANY,
	JOBS_PERFORMANCE,
	EXPORT_DATA
} from "config/api-endpoints";
import { utcTimeStamp } from "config/helpers";
import useOnClickOutside from "hooks/useOnClickOutside";
import toaster from "common/Toaster";
import PusherManager from "config/pusher";
import {
	EVENT_JOBS_PERFORMANCE_EXCEL_GENERATED_TYPE,
	EVENT_REFRESH_DATA
} from "config";
import useSuppliersDepartements from "modules/consultancy/api/useSuppliersDepartement.js";
import useGetCategories from "modules/consultancy/api/useGetCategories.js";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

const getListSuppliers = () => {
	return client(GET_LIST_COMPANY, {
		body: {
			type: "consultancy",
			tag: "spend"
		}
	});
};

const exportData = ({ vendor, startDate, endDate, group_id }) => {
	const notSupplier =
		vendor !== "all" &&
		vendor !== "tier 1" &&
		vendor !== "tier 2" &&
		vendor !== "tier 3";
	const isTiers =
		vendor === "tier 1" || vendor === "tier 2" || vendor === "tier 3";
	return client(EXPORT_DATA, {
		body: {
			tag: "jobs_performance",
			filter_data: {
				vendor_company_id: notSupplier ? vendor : null,
				start_date: startDate ? startDate.unix() : null,
				end_date: endDate ? endDate.unix() : null,
				tier_name: isTiers ? vendor : null,
				group_id,
				tag: "spend"
			}
		}
	});
};

const getJobsPerformance = (
	_,
	supplierId,
	category_id,
	startDate,
	endDate,
	group_id
) => {
	const id = typeof supplierId === "string" ? supplierId : supplierId.value;
	const notSupplier =
		id !== "all" && id !== "tier 1" && id !== "tier 2" && id !== "tier 3";
	const isTiers = id === "tier 1" || id === "tier 2" || id === "tier 3";
	return client(JOBS_PERFORMANCE, {
		body: {
			vendor_company_id: notSupplier ? id : null,
			start_date: startDate,
			end_date: endDate,
			tier_name: isTiers ? id : null,
			category_id,
			group_id,
			tag: "spend"
		}
	});
};
const pickerRef = React.createRef();
const Spend = () => {
	const { data } = useGetCategories();
	const user = useSelector(state => state.auth.user);
	const [supplier, setSupplier] = useState({
		label: "All suppliers",
		value: "all"
	});
	const [displayPicker, setDisplayPicker] = useState(false);
	const [departement, setDepartement] = useState();
	const [category, setCategory] = useState();

	const [startDate, setStartDate] = useState(window.moment().startOf("month"));
	const [endDate, setEndDate] = useState(window.moment().endOf("month"));
	const { data: suppliersDepartement } = useSuppliersDepartements(
		"filter_list_supplier_spent",
		{
			tag: "filter_list_supplier_spent"
		}
	);
	const start_date = utcTimeStamp({
		date: startDate
	});
	const end_date = utcTimeStamp({
		date: endDate,
		isStart: false
	});
	const { data: jobsPerformanceData, isFetching } = useQuery(
		[
			"jobs-performance",
			supplier?.value,
			_get(category, "value", ""),
			start_date,
			end_date,
			_get(departement, "value", "")
		],
		getJobsPerformance,
		{
			refetchOnWindowFocus: false
		}
	);

	const [fileExcel, setFileExcel] = useState(
		_get(jobsPerformanceData, "excel_link", "")
	);

	useEffect(() => {
		setFileExcel(_get(jobsPerformanceData, "excel_link"));
	}, [jobsPerformanceData]);

	const pusherEvent = [
		{
			name: [EVENT_REFRESH_DATA],
			cb: data => {
				if (
					data.message.context.event_type ===
					EVENT_JOBS_PERFORMANCE_EXCEL_GENERATED_TYPE
				) {
					setFileExcel(_get(data, "message.refresh_context.excel_link"));
					window.open(_get(data, "message.refresh_context.excel_link"));
				}
			}
		}
	];

	useEffect(() => {
		if (user && "id" in user) {
			subscribeToPusher(user);
		}
	}, []);

	const subscribeToPusher = user => {
		PusherManager.subscribe(user.id, user.access_token, pusherEvent);
	};
	const { data: suppliersData } = useQuery(
		["list-suppliers"],
		getListSuppliers,
		{
			refetchOnWindowFocus: false
		}
	);

	const [
		onGenerate,
		{ isFetching: isGenerating, isLoading: isLoadingFile }
	] = useMutation(exportData, {
		onSuccess: () => {
			toaster.success("Your data has successfully been exported.");
		}
	});

	const supplierDetails = _get(
		jobsPerformanceData,
		"supplier_detail",
		undefined
	);
	const togglePicker = () => {
		setDisplayPicker(!displayPicker);
	};

	useOnClickOutside(pickerRef, () => {
		setDisplayPicker(false);
	});

	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([
			{ name: "Suppliers" },
			{ name: "Suppliers Management" },
			{ name: "Spent" }
		]);
	}, []);
	return (
		<Box>
			<BodyClassName className="gray-bg">
				<Box className="Vacancies-list-preview">
					<div className="full-container">
						<Flex justifyContent="space-between" alignItems="center">
							<TopSection
								supplier={supplier}
								setSupplier={setSupplier}
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate}
								listSupliers={_get(suppliersData, "consultancies", [])}
								pickerRef={pickerRef}
								displayPicker={displayPicker}
								setDisplayPicker={setDisplayPicker}
								togglePicker={togglePicker}
								departement={departement}
								setDepartement={setDepartement}
								suppliersDepartement={suppliersDepartement}
								category={category}
								setCategory={setCategory}
								categoriesData={data}
							/>

							<CsvButton
								onGenerate={() =>
									onGenerate({
										vendor: supplier?.value,
										startDate,
										endDate,
										group_id: departement?.value
									})
								}
								isGenerating={isGenerating}
								isLoadingFile={isLoadingFile}
								excelLink={fileExcel}
								isFetching={isFetching}
							/>
						</Flex>

						{supplierDetails && (
							<InfoCompanySection supplierDetails={supplierDetails} />
						)}

						<StatisticPerformance
							totalData={_get(jobsPerformanceData, "totals", [])}
							isFetching={isFetching}
						/>

						<TableSpend
							supplier={supplier}
							setSupplierData={setSupplier}
							jobsPerformanceData={_get(jobsPerformanceData, "details", [])}
							isFetching={isFetching}
						/>
					</div>
				</Box>
			</BodyClassName>
		</Box>
	);
};

export default Spend;
