import { IDENTITY_SKIPPED } from "config";
import {
	TRACK_IDENTITY,
	HTTP_GET_CURRENT_COMPANY,
	HIREME_SETTINGS,
	WIRE_TRANSFER
} from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";
import checkStatus from "../components/CheckCurrentStep";
import {
	SAVE_CURRENT_COMPANY,
	SWITCH_UPLOADER_METHOD,
	SET_UPLOADED_FILES
} from "./actionTypes";
import { getIdentityChooserRoute } from "../components/IdentityChooser";
import { getMyProfile } from "../../user/actions/authActions";
import { client } from "lib/api-client";

export function skippedIdentityConfirmed(url) {
	const axiosProps = {
		url: TRACK_IDENTITY,
		data: { step: IDENTITY_SKIPPED },
		next: () => {
			return getMyProfile();
		}
	};
	if (url) {
		axiosProps.newRoute = url;
	}
	return AxiosHelper.post(axiosProps);
}

export async function skipIdentity() {
	await client(TRACK_IDENTITY, { body: { step: IDENTITY_SKIPPED } });
	getMyProfile();
}

export function fetchCurrentCompany(checkStep = true) {
	return AxiosHelper.__get({
		url: HTTP_GET_CURRENT_COMPANY,
		REQUEST_SUCCESS: SAVE_CURRENT_COMPANY,
		next: data => () =>
			checkStep ? checkStatus(data.status, data.type) : Promise.resolve(true)
	});
}

export function saveUploads({ data, next, newRoute, toastMessage, isModal }) {
	return AxiosHelper.post({
		url: TRACK_IDENTITY,
		next: () => () => next(),
		newRoute,
		data,
		toastMessage,
		isModal
	});
}

export function switchMethodDispatch(method = null, isWebcam = false) {
	return {
		type: SWITCH_UPLOADER_METHOD,
		method,
		isWebcam
	};
}

export function setUploadedFiles(files = [], success = false, reset = false) {
	return {
		type: SET_UPLOADED_FILES,
		files,
		success,
		reset
	};
}

export function wireClientTransfert(data, reduxFormName) {
	return AxiosHelper.post({
		url: WIRE_TRANSFER,
		data,
		reduxFormName,
		newRoute: getIdentityChooserRoute()
	});
}

export function loadHiremeSettings() {
	return AxiosHelper.get({
		url: HIREME_SETTINGS
	});
}
