import React, { useState, useRef, useEffect } from "react";
import { DayPickerRangeController } from "react-dates";
import moment from "moment-timezone";
import styled from "styled-components";
import { colors } from "config/styles";
import { utcTimeStamp } from "config/helpers";

moment.updateLocale("en", {
	week: {
		dow: 1
	}
});

export const HandleDatePicker = styled.div`
	height: 260px;
	display: flex;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
	border-radius: 3px;
	position: absolute;
	z-index: 99999;
	bottom: ${({ bottom }) => (bottom ? bottom : "5%")};
	top: ${({ top }) => top && top};
	left: ${({ left }) => left && left};
	right: ${({ right }) => right && right};
	.DayPicker__withBorder {
		box-shadow: unset;
		border-radius: unset;
		border-right: 1px solid ${colors.grayBorder};
		width: unset !important;
		> div {
			display: flex;
		}
		outline: 0;
	}
	.DayPickerKeyboardShortcuts_buttonReset {
		display: none;
	}
	.CalendarDay__selected_span {
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		height: 5px;
		&:last-child {
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
		}
	}

	.CalendarDay__selected_span,
	.CalendarDay__selected_start {
		~ .CalendarDay__selected_span {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.CalendarDay__selected,
	.CalendarDay__selected:active {
		background: ${colors.primaryColor} !important;
		border: unset;
		color: ${colors.white} !important;
		border-radius: 50px;
	}

	.CalendarDay__default {
		border: unset;
		outline: 0;
	}
	.CalendarDay__default:hover {
		background: ${colors.primaryColor} !important;
		color: ${colors.white} !important;
	}
	.CalendarDay__selected_span {
		background: #ffece6 !important;
		border: unset !important;
		color: #000 !important;
	}
	.CalendarDay__selected_span:hover {
		border-radius: 50px !important;
	}
	.CalendarDay__default:hover {
		background: #ffece6;
		color: inherit;
		border-radius: 50px;
		border: unset;
	}
	.CalendarDay__hovered_span {
		background: #ffece6 !important;
		border: unset !important;
		color: #000 !important;
		&:last-child {
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
		}
	}
	.CalendarDay__hovered_span:hover {
		background: none;
	}
`;

const Picker = ({
	setStartDate,
	setEndDate,
	startDate,
	endDate,
	pickerRef,
	position,
	isOpen = false
}) => {
	const monthController = useRef();

	const [focusedInput, setFocusedInput] = useState("startDate");

	useEffect(() => {
		if (focusedInput === "startDate") {
			monthController.current.onMonthSelect(
				monthController.current.month,
				!startDate
					? window
							.moment()
							.startOf("week")
							.format("M")
					: startDate.format("M")
			);
		}
	}, [startDate]);
	useEffect(() => {
		if (!isOpen) setFocusedInput("startDate");
	}, [isOpen]);
	const onDatesChange = ({ startDate, endDate }) => {
		if (focusedInput === "startDate" || endDate === null) {
			return setStartDate(
				window.moment.unix(utcTimeStamp({ date: startDate, isStart: true }))
			);
		}
		setEndDate(endDate);
	};

	return (
		<HandleDatePicker className="date_container" ref={pickerRef} {...position}>
			<DayPickerRangeController
				onDatesChange={onDatesChange}
				focusedInput={focusedInput}
				numberOfMonths={2}
				startDate={!startDate ? window.moment().startOf("week") : startDate}
				endDate={
					focusedInput === "endDate"
						? null
						: endDate
						? endDate
						: window
								.moment()
								.endOf("week")
								.subtract(2, "day")
				}
				renderMonthElement={(...args) => {
					monthController.current = {
						month: args[0].month,
						onMonthSelect: args[0].onMonthSelect
					};
					return args[0].month.format("MMMM YYYY");
				}}
				onFocusChange={focusedInput =>
					setFocusedInput(!focusedInput ? "startDate" : focusedInput)
				}
				initialVisibleMonth={() => window.moment().subtract(2, "month")}
			/>
		</HandleDatePicker>
	);
};

export default Picker;
