import { client } from "lib/api-client";
import { REQUEST_VIEW_KANBAN_DATA } from "config/api-endpoints";
import { useQuery } from "react-query";

const queryFn = body => client(REQUEST_VIEW_KANBAN_DATA, { body });

export const REQUEST_VIEW_KANBAN_DATA_QUERY = "REQUEST_VIEW_KANBAN_DATA_QUERY";

export const useTempPipelineDataApi = (payload, options = {}) =>
	useQuery([REQUEST_VIEW_KANBAN_DATA_QUERY, payload], () => queryFn(payload), {
		retry: 1,
		staleTime: 0,
		refetchOnWindowFocus: false,
		...options
	});
