import styled from "styled-components";

export const FieldBlock = styled.div`
	height: 148px;
	border-radius: 4px;
	background-color: rgb(247, 250, 252);
	position: relative;
	margin-bottom: 20px;
	&.disabled {
		opacity: 0.6;
		label {
			border: solid 1px rgb(37, 112, 183);
			background-color: rgb(231, 243, 255);
			border-radius: 4px;
		}
	}
	label {
		display: block;
		padding: 18px 18px 25px 30px;
		height: 100%;
		margin-bottom: 0;
		cursor: pointer;
		border: solid 1px rgb(229, 235, 241);
		&.active {
			border: solid 1px rgb(37, 112, 183);
			background-color: rgb(231, 243, 255);
			border-radius: 4px;
		}
	}
	.container-label {
		p {
			font-size: 13px;
			color: rgb(61, 75, 96);
		}
		h4 {
			font-size: 15px;
			font-weight: bold;
			color: rgb(34, 41, 53);
			margin-bottom: 10px;
		}
		.iconFieldBlock {
			font-size: 30px;
			margin-bottom: 10px;
			display: inline-block;
			height: 30px;
		}
	}

	.checkmark {
		position: absolute;
		top: 18px;
		right: 18px;
		height: 25px;
		width: 25px;
		border: solid 1px rgb(213, 220, 230);
		background-color: #ffffff;
		border-radius: 50%;
		&:after {
			content: "";
			position: absolute;
			display: none;
		}
	}
	.container-label input:checked ~ .checkmark {
		background-color: #2570b7;
		border: solid 1px #2570b7;
	}
	.container-label input:checked ~ .checkmark:after {
		display: block;
	}
	.container-label .checkmark:after {
		left: 9px;
		top: 4px;
		width: 6px;
		height: 13px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	.icon {
		font-size: 52px;
		margin-bottom: 10px;
		display: block;
	}
	input:checked ~ .radio-btn {
		color: #2570b7;
	}
	.radio-btn {
		color: #d5dce6;
		width: 30px;
		height: 30px;
	}
	> div {
		position: absolute;
		top: 18px;
		right: 18px;
	}
	.text-label {
		color: #525d6f;
		display: block;
		font-weight: normal;
		font-size: 16px;
		strong {
			display: block;
			color: #222935;
		}
	}
`;
