import React from "react";
import { Notice } from "./styled";
function NotificationVacancy({
	count,
	rightDisplay = "12px",
	action = () => {},
	leftDisplay,
	topDisplay,
	border,
	className
}) {
	const isValidCount = count && count !== 0;
	return isValidCount ? (
		<Notice
			className={className}
			right={rightDisplay}
			left={leftDisplay}
			border={border}
			top={topDisplay}
		>
			<div onClick={action}>
				<div className="notice-list">
					<div className="notice-item" />
					<span className="notif-bubble">{count}</span>
				</div>
			</div>
		</Notice>
	) : (
		<></>
	);
}
export default NotificationVacancy;
