import { useQuery } from "react-query";
import { client } from "../../../../lib/api-client";
import { GET_LANGUAGES } from "../../../../config/api-endpoints";

const getLanguages = () => client(GET_LANGUAGES, { method: "post" });

function useGetLanguages() {
	return useQuery("@suppliers/get-languages", getLanguages, {
		refetchOnWindowFocus: false
	});
}

export default useGetLanguages;
