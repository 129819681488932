import React from "react";
import { useMutation } from "react-query";
import { get } from "lodash";
import { connect } from "react-redux";
import toaster from "common/Toaster";
import EditWorkTime from "./EditWorkTime";
import EditExpense from "./EditExpense";
import { documentUploaded, updateAmounts } from "../../actions";
import { FormContainer } from "./styled";
import { client } from "lib/api-client";
import {
	UPDATE_EXPENSE,
	UPDATE_WORKTIME,
	COMPUTE_TIMESHEET
} from "config/api-endpoints";
import {
	timesheetTagsSelectSelector,
	featureTimesheetTagsSelector
} from "../../timesheetSelector";
import {
	DRAFT,
	WORKTIME,
	CLIENT,
	FREELANCER,
	EXPENSE,
	TIMESHEET_TAGS_STATUS
} from "config";

const updateExpense = data => client(UPDATE_EXPENSE, { body: data });
const updateWorktime = data => client(UPDATE_WORKTIME, { body: data });
const computeTimesheet = ({ data }) =>
	client(COMPUTE_TIMESHEET, { body: data });

const EditBox = ({
	dispatch,
	id,
	cancelEdit,
	disabled,
	item,
	month,
	amount,
	currentDocument,
	timesheetId,
	tagOverlayText,
	isClient,
	getInitialVisibleMonth,
	isExpense,
	isWorkTime,
	isOverTime,
	status,
	setDisputedWorkTime,
	setDisputedExpense,
	disputedWorkTime,
	disputedExpense,
	loadTimeSheet,
	hideAmount,
	isMultiSelect,
	type,
	featureTimesheetTags,
	tagOptions,
	hasTag,
	addedWorkTimes,
	canApproveTags,
	isLastItem,
	reportingSettings
}) => {
	const [onComputeTimesheet] = useMutation(computeTimesheet, {
		onSuccess: (data, { payload, view }) => {
			if (view === WORKTIME) {
				const removeLastEdit = disputedWorkTime.filter(
					({ worktime_id }) => worktime_id !== id
				);

				const { amount } = data.dispute_items.worktimes.edited[0];

				setDisputedWorkTime([
					...removeLastEdit,
					{
						worktime_id: id,
						...payload,
						[`${type === CLIENT ? CLIENT : FREELANCER}_amount`]: amount,
						status: "edited"
					}
				]);
				dispatch(updateAmounts(data));
				return;
			}

			if (view === EXPENSE) {
				const removeLastEdit = disputedExpense.filter(
					({ expense_id }) => expense_id !== id
				);
				const { amount } = data.dispute_items.expenses.edited[0];
				setDisputedExpense([
					...removeLastEdit,
					{ expense_id: id, ...payload, price: amount, status: "edited" }
				]);
				dispatch(updateAmounts(data));
				return;
			}
		},
		onError: error => {
			toaster.danger(get(error, "detail.name", get(error, "title")));
		}
	});
	const [onUpdateWorkTime] = useMutation(updateWorktime, {
		onSuccess: () => {
			loadTimeSheet();
			cancelEdit();
		},
		onError: error => {
			toaster.danger(get(error, "detail.name", get(error, "title")));
		}
	});
	const [onUpdateExpense] = useMutation(updateExpense, {
		onSuccess: () => {
			loadTimeSheet();
			cancelEdit();
		},
		onError: error => {
			let errorMsg = get(error, "detail");
			const keys = Object.keys(errorMsg);
			if (keys.length > 0) {
				keys.forEach(name => {
					toaster.danger(errorMsg[name]);
				});
			}

			toaster.danger(get(error, "detail", get(error, "title")));
		}
	});

	const handleUpdateWorkTime = payload => {
		if (status !== DRAFT) {
			cancelEdit();

			const { rate, hours } = payload;
			onComputeTimesheet({
				data: {
					timesheet_id: timesheetId,
					dispute_items: {
						worktimes: {
							edited: [{ _id: id, rate, hours, is_overtime: isOverTime }],
							added: []
						},
						expenses: {
							edited: [],
							added: []
						}
					}
				},

				payload,
				view: WORKTIME
			});
			return;
		}
		payload.timesheet_id = timesheetId;
		return onUpdateWorkTime(payload);
	};

	const handleUpdateExpense = payload => {
		if (status !== DRAFT) {
			cancelEdit();

			const { price } = payload;
			onComputeTimesheet({
				data: {
					timesheet_id: timesheetId,
					dispute_items: {
						worktimes: { edited: [], added: [] },
						expenses: {
							edited: [{ _id: id, amount: price }],
							added: []
						}
					}
				},
				payload,
				view: EXPENSE
			});
			return;
		}
		payload.timesheet_id = timesheetId;
		return onUpdateExpense(payload);
	};

	const isEditHasTag =
		featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
		get(tagOptions, "length", 0) > 0;

	return (
		<FormContainer
			isLastItem={isLastItem}
			style={{ boxShadow: "0 8px 16px #ccc" }}
		>
			{(isWorkTime || isOverTime) && (
				<EditWorkTime
					disabled={disabled}
					item={item}
					month={month}
					amount={amount}
					timesheetId={timesheetId}
					isClient={isClient}
					onUpdateWorkTime={handleUpdateWorkTime}
					getInitialVisibleMonth={getInitialVisibleMonth}
					cancelEdit={cancelEdit}
					hasTag={hasTag}
					status={status}
					id={id}
					isOverTime={isOverTime}
					hideAmount={hideAmount}
					isMultiSelect={isMultiSelect}
					type={type}
					isEditHasTag={isEditHasTag}
					disputedWorkTime={disputedWorkTime}
					addedWorkTimes={addedWorkTimes}
					isLastItem={isLastItem}
					canApproveTags={canApproveTags}
					reportingSettings={reportingSettings}
				/>
			)}
			{isExpense && (
				<EditExpense
					disabled={disabled}
					amount={amount}
					month={month}
					item={item}
					timesheetId={timesheetId}
					currentDocument={currentDocument}
					onUploadDone={data => dispatch(documentUploaded(data, true))}
					onUpdateExpense={handleUpdateExpense}
					tagOverlayText={tagOverlayText}
					getInitialVisibleMonth={getInitialVisibleMonth}
					cancelEdit={cancelEdit}
					isClient={isClient}
					hasTag={hasTag}
					status={status}
					id={id}
					isMultiSelect={isMultiSelect}
					isEditHasTag={isEditHasTag}
					canApproveTags={canApproveTags}
					isLastItem={isLastItem}
					reportingSettings={reportingSettings}
				/>
			)}
		</FormContainer>
	);
};
const mapStateToProps = state => {
	return {
		featureTimesheetTags: featureTimesheetTagsSelector(state),
		tagOptions: timesheetTagsSelectSelector(state)
	};
};

export default connect(mapStateToProps)(EditBox);
