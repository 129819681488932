import React, { useState, useRef } from "react";
import Select from "react-select";
import _debounce from "lodash/debounce";

import { FiltersContainer } from "./Styled";
import SearchBar from "modules/vacancy/components/candidatePool/SearchBar";

const Filters = ({ submitSearch, sort, setSort }) => {
	const [search, setSearch] = useState("");
	const debouncedSubmit = useRef(_debounce(submitSearch, 500));

	return (
		<>
			<FiltersContainer>
				<SearchBar
					searchValue={search}
					onChange={e => {
						setSearch(e.target.value);
						debouncedSubmit.current(e.target.value);
					}}
					showTitle={false}
					onSubmit={e => submitSearch(e, search)}
					clearable={true}
					clearSearch={() => {
						setSearch("");
						submitSearch(null, "");
					}}
					hasKeywordSearch={false}
					placeholder="Start typing (First name, Last name, Job title, Reference)"
				/>
				<div className="select-label">Shorlist date</div>
				<Select
					value={sort}
					options={[
						{
							label: "Oldest to newest",
							value: "asc"
						},
						{
							label: "Newest to oldest",
							value: "desc"
						}
					]}
					clearable={false}
					onChange={selected => setSort(selected.value)}
					onBlur={() => {}}
					className={""}
					placeholder={""}
				/>
			</FiltersContainer>
		</>
	);
};

export default Filters;
