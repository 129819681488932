import { CUSTOM_AUTHENTICATION } from "config/api-endpoints";
import { client } from "lib/api-client";

import { useMutation } from "react-query";

function authenticateWithProvider(body) {
	return client(CUSTOM_AUTHENTICATION, {
		body
	});
}

function useAuthenticateWithProvider() {
	return useMutation(authenticateWithProvider);
}

export default useAuthenticateWithProvider;
