import { FilterDrawerHeader } from "common/FilterDrawer/FilterDrawerHeader";
import Drawer from "rc-drawer";
import React, { memo, useEffect } from "react";
import AddSiteForm from "./AddSiteForm/AddSiteForm";
import styles from "./styles.module.scss";
import { useToggle } from "react-use";

const AddSiteDrawer = ({ title, onClose, onSuccess, setActiveTab }) => {
	const [displayDrawer, setDisplayDrawer] = useToggle(false);

	useEffect(() => {
		setDisplayDrawer(true);
	}, []);

	const onCloseDrawer = () => {
		setDisplayDrawer(false);
	};

	const afterVisibleChange = visibility => {
		if (!visibility) {
			onClose();
		}
	};

	return (
		<Drawer
			open={displayDrawer}
			width="810px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000, backgroundColor: "transparent" }}
			level={"root"}
			maskClosable={true}
			onClose={onCloseDrawer}
			afterVisibleChange={afterVisibleChange}
		>
			<div className={styles.container}>
				<div className={styles.root}>
					<FilterDrawerHeader onClose={onCloseDrawer} title={title} />
					<AddSiteForm
						onClose={onCloseDrawer}
						onSuccess={onSuccess}
						setActiveTab={setActiveTab}
					/>
				</div>
			</div>
		</Drawer>
	);
};

export default memo(AddSiteDrawer);
