import React from "react";
import SearchModal from "common/CustomizableModel/CustomizableModel";

const videoUrl =
	"https://front-cdn.s3.eu-west-1.amazonaws.com/videos/candidates_table.mp4";

const GuideModal = ({ onClose }) => {
	return (
		<SearchModal
			onClose={onClose}
			title="New interface Search candidates"
			displayButtons={false}
			maxwidth={"1200px"}
			size={"1000px"}
			hasCloseIcon
		>
			<div>
				<video width="100%" controls>
					<source src={videoUrl} type="video/mp4" />
				</video>
			</div>
		</SearchModal>
	);
};

export default GuideModal;
