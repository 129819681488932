import React from "react";
import styles from "./profile-view-evaluations-summary-cards.module.scss";
import ProfileViewEvaluationSummaryCard from "../ProfileViewEvaluationSummaryCard/ProfileViewEvaluationSummaryCard";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { findColumn } from "common/FilterDrawer/utils";
import { getColumns } from "../utils/evaluationsFilterFields";
import { EvaluationAverageScore } from "common/EvaluationAverageScore";

const ProfileViewEvaluationSummaryCards = ({ data }) => {
	const { setFilters } = useFilters();

	const onCardClick = item => {
		const data = findColumn("job", getColumns());

		const filters = [
			{
				...data,
				operator: data.operator.value,
				value: [
					{
						id: 1,
						label: "vacancy",
						children: [
							{
								label: item.job.title,
								id: item._id
							}
						]
					}
				]
			}
		];
		setFilters(filters);
	};

	return (
		<div className={styles.summaryCards}>
			{data?.map(item => (
				<ProfileViewEvaluationSummaryCard
					candidate={item?.candidate_name}
					vacancy={item?.job?.title}
					key={item._id}
					onClick={() => onCardClick(item)}
				>
					<EvaluationAverageScore value={item.candidate_score} />
				</ProfileViewEvaluationSummaryCard>
			))}
		</div>
	);
};
export default ProfileViewEvaluationSummaryCards;
