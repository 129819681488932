import React, { useState, useEffect } from "react";
import MembersSelect from "modules/Tasks/components/AddNewTask/Members/MemberSelect.js";
import useListColleagues from "../../../api/useListColleagues";
import useUpdateVisibility from "../../../api/useUpdateVisibility";
import toaster from "common/Toaster";

const Visibility = ({
	fileId,
	allowedUsers,
	disabledUsers,
	isGreenTheme = false,
	canChangeVisibility = true
}) => {
	const [selectedMembers, setSelectedMembers] = useState(allowedUsers);

	const { data } = useListColleagues({ tag: "users_shared_files" });
	useEffect(() => {
		setSelectedMembers(allowedUsers);
	}, [allowedUsers]);
	const [updateVisibility] = useUpdateVisibility({
		onError: () => {
			toaster.danger("Error while changing file visibility");
		}
	});

	const handleClick = val =>
		updateVisibility({
			file_id: fileId,
			allowed_users: val
		});
	return (
		<MembersSelect
			isGreenTheme={isGreenTheme}
			data={data}
			isLoading={false}
			setIncludedItems={setSelectedMembers}
			includedItems={selectedMembers}
			selectTitle="Share with team members"
			inputPlaceholder="Search member..."
			rightPosition="30px"
			top="0"
			membersToshow={1}
			onClick={handleClick}
			disabledUsers={disabledUsers}
			canChangeVisibility={canChangeVisibility}
		/>
	);
};

export default Visibility;
