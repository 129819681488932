import React from "react";
import style from "./empty-state.module.scss";
import { ReactComponent as EmptyIcon } from "static/icons/emptyState.svg";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";

const EmptyState = ({ text, withAction, onClear }) => {
	const { clearFilters } = useFilters();
	return (
		<div className={style.emptyState}>
			<EmptyIcon />
			<p className={style.textGras}>No results found</p>
			<p className={style.textNormal}>{text}</p>
			{withAction && (
				<button className={style.btnClear} onClick={onClear || clearFilters}>
					Clear filters
				</button>
			)}
		</div>
	);
};
export default EmptyState;
