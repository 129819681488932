import React, { Component } from "react";
import { connect } from "react-redux";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import PropTypes from "prop-types";
import { INTERRUPT, EXTEND } from "config";
import {
	toggleExtendModal,
	toggleInterruptModal
} from "../../actions/contractActions";
import _get from "lodash/get";

const initialState = {
	dropdownPosition: "bottomCenter",
	align: {
		points: ["tc", "bc"],
		offset: [0, -25]
	}
};

const calculateProgress = (start_date, end_date) => {
	const now = window.moment();
	const start = window.moment.unix(start_date);
	const end = window.moment.unix(end_date);
	let total = Math.round(((now - start) / (end - start)) * 100);
	total = total < 0 ? 0 : total;
	return total > 100 ? 100 : total;
};

class Actions extends Component {
	constructor() {
		super();
		this.state = initialState;
	}

	onVisibleChange = visible => {
		const { mission, reposition } = this.props;
		const windowHeight = window.innerHeight;
		setTimeout(() => {
			const element = document.getElementsByClassName(`menu-${mission._id}`)[0];
			if (!element) return;
			if (reposition && visible) {
				const boundingBox = element.getBoundingClientRect();
				if (boundingBox.y + boundingBox.height > windowHeight) {
					this.setState({
						dropdownPosition: "topCenter",
						align: {
							points: ["bc", "tc"],
							offset: [0, 41]
						}
					});
				}
			} else if (reposition) {
				this.setState(initialState);
			}
			element.classList.add("rc-shown");
		}, 0);

		this.props.onVisibleChange();
	};

	handleMenuClick = (e, mission) => {
		const { toggleInterruptModal, toggleExtendModal } = this.props;
		switch (e.key) {
			case INTERRUPT:
				return toggleInterruptModal(mission._id);
			case EXTEND:
				return toggleExtendModal(mission);
			default:
				break;
		}
	};

	listMenu = () => {
		const { mission, isClient } = this.props;
		const isInterruptRequested = !!_get(
			mission,
			"interrupt.user_request_interrupt",
			false
		);

		const isInterruptRequestAccepted = !!_get(
			mission,
			"interrupt.interrupt",
			false
		);

		const isExtended = !!_get(mission, "user_requested_extension", false);

		return (
			<Menu onClick={e => this.handleMenuClick(e, mission)} selectable={false}>
				{isClient && (
					<MenuItem
						key={EXTEND}
						className={"rc-full-item"}
						disabled={isExtended || isInterruptRequestAccepted}
					>
						<span className="actions">
							<i className="icon icon-extend" />
							{isExtended ? "Extension Requested" : "Extend Mission"}
						</span>
					</MenuItem>
				)}
				{calculateProgress(mission.start_date, mission.end_date) === 100 &&
				!isInterruptRequestAccepted &&
				!isInterruptRequested ? (
					<></>
				) : (
					<MenuItem
						key={INTERRUPT}
						disabled={isInterruptRequested || isInterruptRequestAccepted}
						className={"rc-full-item"}
					>
						<span className="actions">
							<i className="icon icon-interupt" />
							{isInterruptRequestAccepted
								? "Interruption Made"
								: isInterruptRequested
								? "Interruption Requested"
								: "Interrupt Mission"}
						</span>
					</MenuItem>
				)}
			</Menu>
		);
	};

	render() {
		const { children, reposition, mission } = this.props;
		return (
			<RcDropDown
				trigger={["click"]}
				placement={this.state.dropdownPosition}
				overlay={this.listMenu()}
				overlayClassName={`missions-menu rc-hidden menu-${mission._id}`}
				onVisibleChange={this.onVisibleChange}
				align={reposition && this.state.align}
				onOverlayClick={this.onVisibleChange}
				animation="slide-up"
			>
				{children}
			</RcDropDown>
		);
	}
}

Actions.propTypes = {
	onVisibleChange: PropTypes.func
};

Actions.defaultProps = {
	onVisibleChange: () => {}
};

const mapDispatchToProps = dispatch => {
	return {
		toggleInterruptModal: id => dispatch(toggleInterruptModal(id)),
		toggleExtendModal: mission => dispatch(toggleExtendModal(mission))
	};
};

export default connect(null, mapDispatchToProps)(Actions);
