import React, { useRef, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { ReactComponent as RemoveIcon } from "static/icons/removeIcon.svg";
import classNames from "classnames";
import { OPERATORS } from "common/FilterDrawer/utils";
import get from "lodash/get";
import Tooltip from "common/Tippy";
import { isEmpty, renderFilterValues } from "common/FilterDrawer/render_utils";
import { getOperatorLabel } from "common/FilterDrawer/format_utils";

const FilterPill = ({
	filter,
	onRemove,
	removable,
	variant,
	small,
	onClick,
	styles,
	module_id,
	localFormatting = false,
	isValueFormatted = false
}) => {
	const pillStyle = styles ? styles : classes;
	const ref = useRef();

	const [formatted_filter, setFormattedFilter] = useState({});

	useEffect(() => {
		if (localFormatting) {
			if (get(filter, "name") === "data_source") {
				setFormattedFilter(filter);
			}
		} else {
			setFormattedFilter(filter);
		}
	}, [filter]);
	return (
		<>
			{!isEmpty(get(formatted_filter, "value", "")) && (
				<div
					className={classNames(pillStyle.filters_pill, {
						[pillStyle.no_margin]: variant === "marginless",
						[pillStyle.small]: small
					})}
					onClick={onClick}
				>
					<div
						className={classNames(pillStyle.filters_text, {
							[pillStyle.small]: small
						})}
					>
						{get(formatted_filter, "label", "")}&nbsp;
						{get(formatted_filter, "operator", "") !== "" && (
							<>
								<p className={pillStyle.filter_op}>
									{getOperatorLabel(
										OPERATORS,
										get(formatted_filter, "operator")
									)}
								</p>
								&nbsp;
							</>
						)}
						<Tooltip
							theme="dark"
							content={
								isValueFormatted
									? filter.value
									: renderFilterValues(
											module_id,
											get(formatted_filter, "name", ""),
											get(formatted_filter, "value", "")
									  )
							}
							overflow="hidden"
						>
							<p ref={ref} className={small && pillStyle.small}>
								{isValueFormatted
									? filter.value
									: renderFilterValues(
											module_id,
											get(formatted_filter, "name", ""),
											get(formatted_filter, "value", "")
									  )}
							</p>
						</Tooltip>
					</div>
					{removable && (
						<button
							className={pillStyle.removeBtn}
							onClick={e => {
								e.stopPropagation();
								onRemove(get(formatted_filter, "id"));
							}}
						>
							<RemoveIcon />
						</button>
					)}
				</div>
			)}
		</>
	);
};

export default FilterPill;
