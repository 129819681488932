import React, { useMemo } from "react";
import { get } from "lodash";
import { queryCache } from "react-query";
import { listCountKey } from "modules/home/api/useListCount.js";
import HomeTable from "modules/home/components/Temporary/table";
import useUpcomingMissionsList from "modules/home/api/useUpcomingMissions.js";
import useTemporaryStore from "modules/home/zustand/temporary.store.js";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { CURRENCY_SUFFIX } from "config";
import styles from "modules/home/components/Temporary/temporary.module.scss";
import { historyPush } from "config/helpers";
import { formatDate } from "common/Functions";
import { Line } from "rc-progress";
import { UPCOMING_FINISHERS } from "modules/home/config";
import { RenderApplicantsName } from "modules/home/components/table/helpers.js";
import { upcomingFinishersKey } from "modules/home/api/queriesKeys.js";

const UpcomingFinishers = ({ handleViewAll }) => {
	const { startDate, endDate, temporaryTab, setItem } = useTemporaryStore();

	const { data, isLoading } = useUpcomingMissionsList(
		upcomingFinishersKey,
		{
			start_date:
				startDate && startDate.isValid() ? startDate.unix() : undefined,
			end_date: endDate && endDate.isValid() ? endDate.unix() : undefined,
			tag: UPCOMING_FINISHERS
		},
		{
			enabled: temporaryTab === UPCOMING_FINISHERS,
			onSuccess: res => {
				queryCache.invalidateQueries(listCountKey);
				setItem(
					UPCOMING_FINISHERS,
					res.map(item => item._id)
				);
			}
		}
	);

	const calculateProgress = (start_date, end_date) => {
		const now = window.moment();
		const start = window.moment.unix(start_date);
		const end = window.moment.unix(end_date);
		let total = Math.round(((now - start) / (end - start)) * 100);
		total = total < 0 ? 0 : total;
		return total > 100 ? 100 : total;
	};

	const renderCurrency = data => (
		<CurrencyFormatterRender
			isCent
			value={get(data, "cell.value")}
			suffix={
				CURRENCY_SUFFIX[
					get(data, "cell.row.original.reporting_settings.rate_type")
				]
			}
			currency={get(data, "cell.row.original.reporting_settings.currency.code")}
		/>
	);
	const renderApplicantsName = data => (
		<RenderApplicantsName
			name={`${get(data, "cell.row.original.vendor.first_name")} ${get(
				data,
				"cell.row.original.vendor.last_name"
			)}`}
			status={get(data, "cell.row.original.vendor.type")}
		/>
	);

	const renderProgress = props => {
		const progress = calculateProgress(
			get(props, "cell.row.original.start_date"),
			get(props, "cell.row.original.end_date")
		);
		return (
			<div className={styles.progressContainer}>
				<p className={styles.progress}> {`${progress} %`} </p>
				<Line
					percent={progress}
					strokeWidth="3.5"
					trailWidth="3.5"
					strokeColor="#00A47C"
					trailColor="#AEE9D1"
				/>
			</div>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: "End date",
				accessor: "",
				Cell: props => (
					<div>{formatDate(get(props, "cell.row.original.end_date"))}</div>
				)
			},
			{
				Header: "Consultant / Freelancer",
				accessor: "",
				Cell: renderApplicantsName
			},
			{
				Header: "Mission title",
				accessor: "job.title"
			},
			{
				Header: "Progress",
				accessor: "",
				Cell: renderProgress
			},
			{
				Header: "Rate",
				accessor: "cost",
				isNumber: true,
				Cell: renderCurrency
			},
			{
				Header: "Departement",
				accessor: "group.name"
			},
			{
				Header: "Job ref",
				accessor: "job.job_ref",
				isNumber: true
			}
		],
		[]
	);
	const buttonAction = () => historyPush("/jobs/list");
	const onRowClick = row =>
		historyPush(`/missions/${row._id}?listKey=${UPCOMING_FINISHERS}`);

	return (
		<HomeTable
			isLoading={isLoading}
			columns={columns}
			data={data}
			heading="You have no upcoming starters for this date"
			buttonLabel="Create request"
			buttonAction={buttonAction}
			onRowClick={onRowClick}
			handleViewAll={handleViewAll}
		/>
	);
};

export default UpcomingFinishers;
