import styled, { css } from "styled-components";

export const ApproveButtonContainer = styled.div`
	${({ showTooltip }) =>
		css`
			.g-tool {
				margin: 0 !important;
			}
			button {
				cursor: ${showTooltip ? "not-allowed" : "auto"};
				opacity: ${showTooltip ? 0.6 : 1};
			}
		`}
`;
