import React, { useState } from "react";
import get from "lodash/get";
import { columns } from "./filterHelpers";
import s from "./my-contract.module.scss";
import DisplayResults from "modules/Tasks/components/pagination/DisplayResults.jsx";
import PaginationClient from "modules/Tasks/components/pagination";
import useDeleteContract from "../../api/result/useDeleteContract";
import { queryCache } from "react-query";
import useGetListContract from "api/useGetListContract";
import { getListContractQueryKey } from "api/useGetListContract";
import { MyContractsList } from "modules/vonqContract/components/MyContractsList";
import { MyContractsTabLoadingView } from "modules/vonqContract/components/MyContractsTabLoadingView";

const LIMIT = 8;

const MyContracts = () => {
	const [limit, setLimit] = useState(LIMIT);
	const [offset, setOffset] = useState(0);

	const { resolvedData, isFetching, isLoading } = useGetListContract({
		offset,
		limit
	});

	const [deleteContract, { isLoading: isDeleting }] = useDeleteContract({
		onSuccess: () => {
			queryCache.invalidateQueries(getListContractQueryKey);
			queryCache.invalidateQueries("@VonqContract/getChannels");
		}
	});

	const handleDelete = contract_id => {
		deleteContract({
			contract_id
		});
	};

	const total = get(resolvedData, "pagination.total", 0);

	return (
		<div className={s.container}>
			<div>
				{isLoading || isFetching || isDeleting ? (
					<MyContractsTabLoadingView columns={columns()} />
				) : (
					<MyContractsList
						list={resolvedData.data}
						columns={columns()}
						onDelete={handleDelete}
					/>
				)}
			</div>
			<div className={s.paginationContainer}>
				<DisplayResults
					resolvedData={resolvedData}
					isFetching={isLoading || isDeleting}
					setLimit={limit => {
						setLimit(limit);
						setOffset(0);
					}}
					limit={limit}
					offset={offset}
				/>
				{total > limit && (
					<PaginationClient
						size={limit}
						offset={offset}
						setOffset={setOffset}
						total={total}
					/>
				)}
			</div>
		</div>
	);
};

export default MyContracts;
