import { v4 as uuid } from "uuid";
export const LANGUAGE_LEVELS_LABELS = [
	"Novice",
	"Advanced beginner",
	"Competent",
	"Proficient",
	"Expert"
];

export const SKILLS_LEVELS_LABELS = [
	"Novice",
	"Advanced beginner",
	"Competent",
	"Proficient",
	"Expert"
];

export const VACANCY_STEP_CARDS_IDS = {
	description: "vacancyStepDescriptionCard",
	salary: "vacancyStepSalaryCard",
	additionalInfo: "vacancyStepAdditionalInfoCard",
	applicants: "vacancyStepApplicantsCard"
};

export const PROFILE_STEP = "PROFILE_STEP";
export const VACANCY_STEP = "VACANCY_STEP";
export const QUESTIONNAIRE_STEP = "QUESTIONNAIRE_STEP";

export const EDITOR_MAX_LENGTH = 10000;

export const NUMBER_KEYS_CODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

export const operators = [
	{
		_id: uuid(),
		value: "(",
		label: "(",
		isParentheses: true
	},
	{
		_id: uuid(),
		value: ")",
		label: ")",
		isParentheses: true
	},
	{
		_id: uuid(),
		value: "and",
		label: "AND",
		isOperator: true
	},
	{
		_id: uuid(),
		value: "or",
		label: "OR",
		isOperator: true
	}
];

export const TEXT_TONES_LIST = [
	{ label: "Academic", value: "academic" },
	{ label: "Business", value: "business" },
	{ label: "Casual", value: "casual" },
	{ label: "Conversational", value: "conversational" },
	{ label: "Emotional", value: "emotional" },
	{ label: "Humorous", value: "humorous" },
	{ label: "Informative", value: "informative" }
];

export const JOB_DESCRIPTION_FIELDS = {
	title: "job_title",
	jobDescription: "job_description",
	profileDescription: "profile_description"
};

export const MIN_PROMPT_LENGTH = 10;
export const MIN_WIDTH = 10;
export const MAX_WIDTH = 100;
export const WIDTH_DECREMENT = 10;
