import React from "react";
const ArrowDown = ({ stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 16 16`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ display: "block" }}
		>
			<path
				d="M4.6665 6.66675L7.99984 10.0001L11.3332 6.66675"
				stroke="black"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default ArrowDown;
