import React from "react";
import ConfirmationModal from "common/modal/ConfirmationModal";

const FunctionsWarningModal = ({
	closeModal,
	categoryToDelete,
	confirmDeleteFunction
}) => {
	return (
		<ConfirmationModal
			key="modal"
			title={`Are you sure you want to proceed?`}
			onClose={closeModal}
			type="error"
			content={
				<ul>
					Doing this will clear the skills selected for the following category:
					<li>{categoryToDelete}</li>
				</ul>
			}
			firstButton={{
				action: confirmDeleteFunction,
				type: "danger",
				label: `YES, I'm sure`
			}}
		/>
	);
};

export default FunctionsWarningModal;
