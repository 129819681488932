import React from "react";
const UnpinIcon = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${24} ${24}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.9198 13.9989C18.9194 13.6487 18.5866 13.2327 17.9208 12.4005L16.351 10.4383C16.2211 10.2758 16.1561 10.1946 16.1099 10.1042C16.0689 10.0239 16.039 9.93852 16.0208 9.85026C16.0004 9.75078 16.0004 9.64677 16.0004 9.43875V7.30813C16.0004 7.19301 16.0004 7.13544 16.0069 7.07868C16.0127 7.02825 16.0223 6.97833 16.0357 6.92937C16.0507 6.87424 16.0721 6.8208 16.1149 6.71391L17.1227 4.19423C17.4168 3.45914 17.5638 3.09159 17.5025 2.79655C17.4489 2.53853 17.2956 2.31211 17.0759 2.1665C16.8247 2 16.4289 2 15.6372 2H8.36368C7.57197 2 7.17611 2 6.92494 2.1665M12.0004 15L12.0004 22M12.0004 15H7.32943C6.26373 15 5.73088 15 5.4572 14.7815C5.21919 14.5916 5.08073 14.3035 5.08106 13.9989C5.08143 13.6487 5.4143 13.2327 6.08004 12.4005L7.64982 10.4383C7.77977 10.2758 7.84474 10.1946 7.89095 10.1042C7.93194 10.0239 7.9619 9.93852 7.98001 9.85026C8.00043 9.75078 8.00043 9.64677 8.00043 9.43875V8.37344M12.0004 15H15M21 21L3 3"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default UnpinIcon;
