import { useEffect } from "react";
import {
	FILTER_DEFAULT_VALUE,
	useFilters
} from "common/FilterDrawer/hooks/useFilter";
import {
	MAP_TYPE_COMPONENT,
	MAP_TYPE_OPERATORS,
	EVALUATIONS_FILTER_ID,
	EVALUATIONS_MODULE_ID,
	EVALUATIONS_PAGE_SIZE,
	TYPES
} from "../components/utils";
import { getColumns } from "../components/utils/evaluationsFilterFields";
import useGetLastViewedCandidate from "../../Notes/hooks/useGetLastViewedCandidate";

export const useInitializeFilterDrawer = profileId => {
	const { setFilterSetting, setFilters } = useFilters();
	const { candidateId, setCandidateId } = useGetLastViewedCandidate();

	useEffect(() => {
		setFilterSetting(
			TYPES,
			MAP_TYPE_OPERATORS,
			MAP_TYPE_COMPONENT,
			{},
			null,
			getColumns(profileId),
			EVALUATIONS_FILTER_ID,
			EVALUATIONS_MODULE_ID,
			true,
			{},
			EVALUATIONS_PAGE_SIZE
		);

		if (candidateId !== profileId) {
			setFilters([FILTER_DEFAULT_VALUE]);
			setCandidateId(profileId);
		}
	}, [profileId]);
};
