import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

function filterSettings() {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "list_offices"
		}
	});
}

export const GET_COMPANY_OFFICES_LIST_QUERY_KEY =
	"GET_COMPANY_OFFICES_LIST_QUERY_KEY";

function useGetCompanyOfficesList(queryOptions) {
	return useQuery(GET_COMPANY_OFFICES_LIST_QUERY_KEY, filterSettings, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
}

export default useGetCompanyOfficesList;
