import React from "react";
import get from "lodash/get";
import cx from "classnames";
import styles from "./processes.module.scss";

const Sidebar = ({ active, closed, isLoadingProcesses, statistics }) => {
	const application = get(statistics, "application", "0");
	const hired = get(statistics, "hired", "0");
	const interview = get(statistics, "interview", "0");
	const offer = get(statistics, "offer");
	const phone_screen = get(statistics, "phone_screen", "0");
	const shortlist = get(statistics, "shortlist", "0");

	return !isLoadingProcesses ? (
		<>
			<div className={styles.card}>
				<div className={styles.textGras}>Vacancies processed in</div>
				<div className={styles.description}>
					Number of vacancies in which the candidate is/was being processed
				</div>
				<div className={styles.flex}>
					<div className={cx(styles.box, styles.active)}>
						<p>{active}</p>
						<span>Active</span>
					</div>
					<div className={cx(styles.box, styles.closed)}>
						<p> {closed}</p>
						<span>Closed</span>
					</div>
				</div>
			</div>
			<div className={styles.card}>
				<div className={styles.textGras}>Vacancies progress</div>
				<div className={styles.description}>
					Stats of each time a stage was reached by candidate in all current and
					previous vacancies
				</div>
				<div className={cx(styles.flex, styles.flexColumn)}>
					<div className={cx(styles.box, styles.info)}>
						<p>{application}</p>
						<span>Application</span>
					</div>
					<div className={cx(styles.box, styles.info)}>
						<p>{shortlist}</p>
						<span>Sourced</span>
					</div>

					<div className={cx(styles.box, styles.info)}>
						<p>{phone_screen}</p>
						<span>Phone screen</span>
					</div>
					<div className={cx(styles.box, styles.info)}>
						<p>{interview}</p>
						<span>Interview</span>
					</div>
					<div className={cx(styles.box, styles.info)}>
						<p>{offer}</p>
						<span>Offer</span>
					</div>
					<div className={cx(styles.box, styles.info)}>
						<p>{hired}</p>
						<span>Hired</span>
					</div>
				</div>
			</div>
		</>
	) : null;
};

export default Sidebar;
