import styled from "styled-components";

export const PaginationWrapper = styled.div`
	.pagination-client {
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		.next {
			padding-right: 0px;
			outline: none;
			background: white;
			a {
				font-family: "basiercircle";
				font-style: normal;
				font-weight: 600;
				color: #fd5749;
				padding: 8px 16px;
				font-size: 14px;
				line-height: 20px;
				color: #202223;
			}

			background: #ffffff;
			border: 1px solid #575959;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
			border-radius: 4px;
			::after {
				display: none;
			}

			:hover {
				background: #fff4f4;
				border: 1px solid #fd5749;
				a {
					color: #fd5749;
				}
			}
			&.disabled {
				background: #fafbfb;

				border: 1px solid #d2d5d8;
				> a {
					cursor: not-allowed;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 20px;
					color: #8c9196;
				}
				:hover {
					background: #fafbfb;

					border: 1px solid #d2d5d8;
					a {
						color: #8c9196;
					}
				}
			}
		}
		.previous {
			padding-left: 0px;
			outline: none;
			background: white;

			background: #ffffff;
			border: 1px solid #575959;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
			border-radius: 4px;

			a {
				font-family: "basiercircle";
				font-style: normal;
				font-weight: 600;
				color: #fd5749;
				padding: 8px 16px;
				font-size: 14px;
				line-height: 20px;
				color: #202223;
			}
			::before {
				display: none;
			}
			:hover {
				background: #fff4f4;
				border: 1px solid #fd5749;
				a {
					color: #fd5749;
				}
			}
			&.disabled {
				background: #fafbfb;

				border: 1px solid #d2d5d8;
				> a {
					cursor: not-allowed;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 20px;
					color: #8c9196;
				}
				:hover {
					background: #fafbfb;

					border: 1px solid #d2d5d8;
					a {
						color: #8c9196;
					}
				}
			}
		}
		.active {
			a[role="button"] {
				padding: 9px 14px;
				background: #f1f2f3 !important;
				border-radius: 4px;
				font-family: "BasierCircle";
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;
				color: #575959;
			}
			a:hover,
			a:focus {
				background: #f1f2f3 !important;
			}
		}
		a[role="button"] {
			border-radius: 4px;
		}
		.break-me {
			display: none;
		}
	}
`;
