import React from "react";
import { Box, Text, Flex } from "rebass";
import { TitleHeadline } from "../personalTab";

import { SkillsByCategory } from "modules/candidate/components/profileClientView/candidateDetails/skillsAndLanguages";
import Tooltip from "rc-tooltip";
import { getSkillsTooltip } from "config/helpers";

const SkillsAndLanguages = ({ languages, skills }) => {
	const renderItems = (name, score) =>
		score ? (
			<Tooltip
				placement="top"
				overlay={<div>{getSkillsTooltip(score)}</div>}
				trigger="hover"
				overlayClassName="function-tooltip"
			>
				<Box
					p={2}
					sx={{
						borderRadius: 4,
						marginRight: "10px",
						marginBottom: "10px",
						border: "solid 1px #DBDCDE"
					}}
				>
					<Text
						sx={{
							fontSize: "16px",
							color: "#2b3946",
							fontWeight: "normal"
						}}
					>
						{name ? `${name} (${score}/5)` : "N/A"}
					</Text>
				</Box>
			</Tooltip>
		) : (
			<Box
				p={2}
				sx={{
					borderRadius: 4,
					marginRight: "10px",
					marginBottom: "10px",
					border: "solid 1px #DBDCDE"
				}}
			>
				<Text
					sx={{
						fontSize: "16px",
						color: "#2b3946",
						fontWeight: "normal"
					}}
				>
					N/A
				</Text>
			</Box>
		);
	return (
		<Box>
			<Box mb={3}>
				<TitleHeadline>SKILLS</TitleHeadline>
				{skills.length > 0 ? (
					Object.keys(SkillsByCategory(skills)).map(category => (
						<Box key={category}>
							<Text
								mb={2}
								sx={{
									fontSize: "16px",
									color: "#818a92",
									fontWeight: "normal"
								}}
							>
								{category}
							</Text>

							<Flex flexWrap="wrap">
								{SkillsByCategory(skills)[category].map(({ name, score }) =>
									renderItems(name, score)
								)}
							</Flex>
						</Box>
					))
				) : (
					<Box>
						<Flex flexWrap="wrap">{renderItems()}</Flex>
					</Box>
				)}
			</Box>
			<Box>
				<TitleHeadline>LANGUAGES</TitleHeadline>
				<Flex flexWrap="wrap">
					{languages.length > 0
						? languages.map(({ name, score }) => renderItems(name, score))
						: renderItems()}
				</Flex>
			</Box>
		</Box>
	);
};

export default SkillsAndLanguages;
