//TODO Move hook to root
import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_GDPR_PROFILES } from "config/api-endpoints";

const deleteGdprProfiles = data => {
	return client(DELETE_GDPR_PROFILES, {
		body: { ...data }
	});
};

const useDeleteGdprProfiles = options =>
	useMutation(deleteGdprProfiles, options);

export default useDeleteGdprProfiles;
