import styled from "styled-components";

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px;
	.info {
		display: flex;
		justify-content: center;
		align-items: center;
		.left-arrow {
			width: 40px;
			cursor: pointer;
			margin-right: 7px;
		}
		.title {
			font-family: "basiercircle";
			font-weight: 600;
			font-size: 20px;
			line-height: 25px;
			color: #181819;
			margin-left: 8px;
		}
	}
	.actions {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 72px;
	}
`;
export const CancelButton = styled.button`
	border: ${({ border }) => (border ? border : "1px solid #303131")};
	border-radius: 4px;
	outline: none;
	background-color: ${({ backgroundColor }) =>
		backgroundColor ? backgroundColor : "#f8f9fa"};
	height: 29px;
	margin: ${({ margin }) => margin && margin};
	font-family: "basiercircle";
	font-size: 14px;
	line-height: 16px;
	padding: 0 15px;
	text-align: center;
	color: ${({ color }) => (color ? color : "#181819")};
`;

export const ActionButton = styled.button`
	background: linear-gradient(114.44deg, #ff3649 0%, #ff6849 100%);
	color: ${({ color }) => (color ? color : "#fff")};
	border: 0;
	border-radius: 4px;
	outline: none;
	height: 29px;
	font-family: "basiercircle";
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	padding: 0 25px;
	margin: ${({ margin }) => margin && margin};
`;

export const AddNoteContainer = styled.div`
	position: relative;
	height: 100%;
	cursor: ${({ isLoading }) => isLoading && "progress"};
	.box {
		filter: ${({ isLoading }) => isLoading && "blur(2px)"};
		display: flex;
		flex-direction: column;
		justify-items: flex-start;
		background: white;
		width: 850px;
		margin: auto;
		min-height: 650px;
		margin-top: 20px;
		padding: 0 110px;
		.note {
			margin-top: 20px;
			.mention-label {
				color: #a8aaac;
			}
		}

		.box-title {
			margin: 40px 0 0 0;
			font-family: "basiercircle";
			font-weight: 600;
			font-size: 20px;
			line-height: 25px;
			color: #181819;
			height: min-content;
		}
		.box-sub-title {
			font-family: "basiercircle";
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #48494a;
			margin: 8px 0 0 0;
		}
		.Select {
			width: 310px;
			margin-bottom: 15px;
			.Select-control {
				height: 32px;
				.Select-placeholder {
					display: flex;
					align-items: center;
				}
				.Select-value {
					display: flex !important;
					align-items: center;
				}
			}
		}
		.form-group {
			width: 100%;
			margin-bottom: 15px;
			display: inline-block;
			label {
				font-family: "basiercircle";
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
			}
		}
		.actions {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 40px 0;
			width: 100%;
			height: 100%;
		}
	}
	.loader-container {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 10;
	}
`;
