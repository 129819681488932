import React, { useEffect, useMemo, useState } from "react";
import get from "lodash/get";
import differenceBy from "lodash/differenceBy";
import ListRendererFiles from "./ListRendererFiles";
import immutable from "seamless-immutable";
import findIndex from "lodash/findIndex";
import { getFileExtension } from "common/Functions";
import TimeSheetsModifsAlert from "./TimeSheetsModifsAlert";
import { Th, TimesheetTableDetails } from "./TimesheetStyled";
import EmptyTimesheet from "./EmptyTimesheet";
import {
	APPROVED,
	DISPUTED,
	DOC,
	DOCX,
	EXPENSE,
	FREELANCER,
	JPEG,
	JPG,
	PDF,
	PNG,
	SUBMITTED,
	TIMESHEET_TAGS_STATUS,
	WORKTIME,
	OVERTIME,
	FILES,
	DAILY
} from "config";
import GlobalTooltip from "common/GlobalTooltip";
import { usePrevious } from "hooks";
import { Box, Flex } from "rebass";
import ControlledCheckbox from "./MultiAction/checkbox";
import { client } from "lib/api-client";
import { TRACK_TIMESHEET_EDITION } from "config/api-endpoints";
import { useMutation } from "react-query";
import LeaveHook from "common/LeaveHook";
import Actions from "./cta";
import RendrerItem from "./RendrerItem";
import { setIsEditedTimesheetAfterDisputed } from "../actions";
import { useDispatch } from "react-redux";
import { ACCEPTED } from "config";
import { DRAFT } from "config";
// TODO: fix job-status in this file.

export const getIconType = file => {
	const fileType = getFileExtension(file);
	switch (fileType) {
		case JPG:
		case JPEG:
			return "icon-type-jpeg";
		case PNG:
			return "icon-type-png";
		case DOCX:
		case DOC:
			return "icon-type-docx";
		case PDF:
			return "icon-type-pdf";
		default:
			return "";
	}
};

const lockTimesheet = ({ timesheetId, action }) => {
	return client(TRACK_TIMESHEET_EDITION, {
		body: {
			timesheet_id: timesheetId,
			action
		}
	});
};

const CHECKBOX_STATES = {
	Checked: "Checked",
	Indeterminate: "Indeterminate",
	Empty: "Empty"
};

const ListRenderer = ({
	list,
	view,
	month,
	type,
	isClient,
	isConsultancy,
	status,
	jobId,
	timesheetId,
	toggleDeleteModal,
	timesheetTagName,
	tagOverlayText,
	isDraft,
	toggleDuplicateModal,
	isNotProjectMananger,
	currentDocumentEditMode,
	currentDocumentMultiEditMode,
	disputedWorkTime,
	disputedExpense,
	setDisputedWorkTime,
	setDisputedExpense,
	loadTimeSheet,
	is_edited,
	addedExpenses = [],
	addedWorkTimes = [],
	addedOverTimes = [],
	setAddedExpense,
	setAddedWorkTime,
	setAddedOverTime,
	featureTimesheetTags,
	tagOptions,
	isLocked,
	isConsultantAdmin,
	last_action,
	companyId,
	canApproveTags = [],
	userId,
	adminUsers = [],
	allItems,
	emptyTimesheet,
	emptyTimesheetText,
	selectedStatus,
	selectedTag,
	isEmpty,
	clearResubmittedTimesheet,
	reportingSettings,
	attachments,
	requiredFilesIsEmpty
}) => {
	const [highlighted, setHighlighted] = useState(-1);
	const prevList = usePrevious(list);
	const prevView = usePrevious(view);

	const [editItemId, setEditItemId] = useState("");
	const [checked, setChecked] = useState(CHECKBOX_STATES.Empty);
	const [isAllElementHadChecked, setIsAllElementHasChecked] = useState([]);
	const [checkAll, setCheckAll] = useState(undefined);
	const [isMultiActionsMode, setIsMultiActionsMode] = useState(false);
	const [isMultiEditMode, setIsMultiEditMode] = useState(false);

	const [isExpanded, setIsExpanded] = useState(false);

	const [openTimesheetModifsAlert, setOpenTimesheetModifsAlert] = useState(
		is_edited
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (status === APPROVED) {
			setEditItemId("");
			setIsExpanded("");
			setIsAllElementHasChecked([]);
			setCheckAll(checkAll === false ? undefined : false);
			setChecked(CHECKBOX_STATES.Empty);
		}
	}, [status]);
	useEffect(() => {
		setEditItemId("");
		setIsExpanded("");
		setIsAllElementHasChecked([]);
		setCheckAll(checkAll === false ? undefined : false);
		setChecked(CHECKBOX_STATES.Empty);
	}, [selectedStatus, selectedTag]);

	useEffect(() => {
		setCheckAll(checkAll === false ? undefined : false);
		setIsAllElementHasChecked([]);
		setChecked(CHECKBOX_STATES.Empty);
		setIsExpanded("");
		setEditItemId("");
	}, [view]);

	const handleChange = () => {
		let updatedChecked;
		if (checked === CHECKBOX_STATES.Checked) {
			updatedChecked = CHECKBOX_STATES.Empty;
			setCheckAll(checkAll === false ? undefined : false);
		} else if (checked === CHECKBOX_STATES.Empty) {
			updatedChecked = CHECKBOX_STATES.Checked;
			setCheckAll(true);
		} else if (checked === CHECKBOX_STATES.Indeterminate) {
			updatedChecked = CHECKBOX_STATES.Empty;
			setCheckAll(checkAll === false ? undefined : false);
		}

		setChecked(updatedChecked);
	};
	useEffect(() => {
		if (isAllElementHadChecked.length > 0) {
			!isMultiActionsMode && setIsMultiActionsMode(true);
		} else if (isAllElementHadChecked.length === 0) {
			isMultiActionsMode && setIsMultiActionsMode(false);
		}
		if (isAllElementHadChecked.length === list.length) {
			setChecked(CHECKBOX_STATES.Checked);
		}
		if (isAllElementHadChecked.length === 0) {
			setChecked(CHECKBOX_STATES.Empty);
			setCheckAll(false);
		}
	}, [isAllElementHadChecked]);
	useEffect(() => {
		setOpenTimesheetModifsAlert(is_edited);
	}, [is_edited]);

	const [onLockTimesheet] = useMutation(lockTimesheet);

	useEffect(() => {
		const isEdited = [
			disputedExpense,
			disputedWorkTime,
			addedExpenses,
			addedWorkTimes,
			addedOverTimes,
			attachments || []
		].some(item => item.length > 0);

		if (!isClient && isEdited) {
			onLockTimesheet({ timesheetId, action: "lock" });
		}
	}, [
		get(disputedExpense, "length"),
		get(disputedWorkTime, "length"),
		get(addedExpenses, "length"),
		get(addedWorkTimes, "length"),
		get(addedOverTimes, "length")
	]);

	useEffect(() => {
		let highlightTimer;
		if (isDraft) {
			if (!prevList || !prevList.length) return;
			if (prevList.length < list.length && prevView === view) {
				const diffItem = differenceBy(list, prevList, item => item._id);
				const index = findIndex(list, { _id: diffItem[0]._id });
				setHighlighted(index);
				highlightTimer = setTimeout(() => {
					highlighted && setHighlighted(-1);
				}, 2000);
			}
		}
		return () => isDraft && highlightTimer && clearTimeout(highlightTimer);
	}, [list]);

	const sortListByDate = useMemo(
		() => list => {
			const mappedList = list.map(_item => {
				let disputedItems = isExpense ? disputedExpense : disputedWorkTime;

				let itemIndexId = isExpense ? "expense_id" : "worktime_id";
				let checkItemInDisputedItems = disputedItems.filter(
					disputedItem => disputedItem[itemIndexId] === _item._id
				);

				return checkItemInDisputedItems.length > 0
					? {
							..._item,
							...checkItemInDisputedItems[0],
							edited:
								checkItemInDisputedItems.length > 0
									? _item
									: get(_item, "edited", {})
					  }
					: _item;
			});
			return immutable.asMutable(mappedList).sort((a, b) => {
				return b.date - a.date;
			});
		},
		[list, disputedWorkTime, disputedExpense]
	);

	const isExpense = view === EXPENSE;
	const isWorkTime = view === WORKTIME;
	const isOverTime = view === OVERTIME;
	const hideAmount = !isExpense && isConsultancy && !isNotProjectMananger;
	const isTalent =
		(isConsultancy && !isNotProjectMananger) || type === FREELANCER;
	const hasTag =
		list.some(list => list.tag) ||
		(featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
			(tagOptions || []).length > 0);

	const getInitialVisibleMonth = () => {
		return window.moment.unix(month.start_date);
	};

	const isMultiSelect = list.length > 1;
	const isEdited = [
		disputedExpense,
		disputedWorkTime,
		addedExpenses,
		addedWorkTimes,
		addedOverTimes,
		attachments || []
	].some(item => item.length > 0);

	const sortedList = sortListByDate(list);

	const canApprove = tagItem => {
		if (!isClient) return true;
		if (get(tagOptions, "length", 0) === 0) return true;
		if (tagItem) {
			if (canApproveTags.includes(tagItem)) return true;
			return false;
		} else if (get(tagOptions, "length", 0) > 0 && adminUsers.includes(userId))
			return true;
		else return false;
	};

	const isMultiApprove = () =>
		sortedList.map(({ tag }) => tag).some(tag => canApprove(tag));

	const showCheckboxes =
		isMultiApprove() &&
		sortedList.map(({ status }) => status).some(status => status !== ACCEPTED);

	const noEntriesText =
		"There is no entry requiring your review. Use the filters above to view other/all entries";
	const isRateDaily = get(reportingSettings, "rate_type") === DAILY;

	return (
		<LeaveHook
			onLeave={
				!isClient
					? () => onLockTimesheet({ timesheetId, action: "unlock" })
					: () => {}
			}
			dirty={isEdited}
		>
			<Box mt={emptyTimesheet ? 0 : "30px"}>
				{status !== APPROVED && (
					<TimeSheetsModifsAlert
						active={openTimesheetModifsAlert}
						onClose={() => {
							setOpenTimesheetModifsAlert(false);
							dispatch(setIsEditedTimesheetAfterDisputed(false));
						}}
					/>
				)}
				{status !== APPROVED && (
					<Actions
						attachments={attachments}
						isClient={isClient}
						status={status}
						isConsultantAdmin={isConsultantAdmin}
						disputedExpense={disputedExpense}
						disputedWorkTime={disputedWorkTime}
						timesheetId={timesheetId}
						loadTimeSheet={loadTimeSheet}
						last_action={last_action}
						addedWorkTimes={addedWorkTimes}
						addedOverTimes={addedOverTimes}
						addedExpenses={addedExpenses}
						setAddedExpense={setAddedExpense}
						setAddedWorkTime={setAddedWorkTime}
						setAddedOverTime={setAddedOverTime}
						isLocked={isLocked}
						setDisputedWorkTime={setDisputedWorkTime}
						setDisputedExpense={setDisputedExpense}
						isExpense={isExpense}
						isEdited={isEdited}
						isAllElementHadChecked={isAllElementHadChecked}
						isMultiActionsMode={isMultiActionsMode}
						setIsMultiEditMode={setIsMultiEditMode}
						setIsMultiActionsMode={setIsMultiActionsMode}
						isMultiEditMode={isMultiEditMode}
						itemSelected={isAllElementHadChecked.length}
						disabled={false}
						month={month}
						list={list}
						hasTag={hasTag}
						getInitialVisibleMonth={getInitialVisibleMonth}
						cancelEdit={() => {
							setIsAllElementHasChecked([]);
							setCheckAll(checkAll === false ? undefined : false);
							setChecked(CHECKBOX_STATES.Empty);
							setIsMultiEditMode(false);
						}}
						currentDocument={currentDocumentMultiEditMode}
						isWorkTime={isWorkTime}
						type={type}
						canApproveTags={canApproveTags.map(item => ({
							label: item,
							value: item
						}))}
						allItems={allItems}
						canApprove={canApprove}
						companyId={companyId}
						setIsAllElementHasChecked={setIsAllElementHasChecked}
						setCheckAll={setCheckAll}
						setChecked={setChecked}
						checked={checked}
						isFilesView={view === FILES}
						isOverTime={view === OVERTIME}
						isTimesheetEmpty={isEmpty}
						clearResubmittedTimesheet={clearResubmittedTimesheet}
						reportingSettings={reportingSettings}
					/>
				)}

				{view === FILES && status !== DRAFT ? (
					<ListRendererFiles
						timesheetId={timesheetId}
						isClient={isClient}
						attachments_list={attachments}
						requiredFilesIsEmpty={requiredFilesIsEmpty}
					/>
				) : (
					<div className={!isClient ? "container" : "full-container"}>
						{emptyTimesheet ? (
							<EmptyTimesheet
								selectedStatus={selectedStatus}
								content={noEntriesText}
								view={view}
								tag={emptyTimesheetText}
							/>
						) : (
							<TimesheetTableDetails spacing="0 2px" key="timesheet-list">
								<thead>
									<tr>
										<Th width={"15%"}>
											<Flex alignItems="center">
												{(status === DISPUTED || status === SUBMITTED) &&
													isClient &&
													isMultiSelect &&
													showCheckboxes && (
														<Box
															sx={{
																marginRight: "8px"
															}}
														>
															<ControlledCheckbox
																value={checked}
																onChange={handleChange}
																disabled={!isMultiApprove() || isLocked}
															/>
														</Box>
													)}{" "}
												<span style={{ minWidth: "11px" }}></span>
												Date
											</Flex>
										</Th>
										<Th width={"15%"}>Description</Th>
										{hasTag && (
											<Th width={"10%"}>
												{timesheetTagName}
												{isClient && <GlobalTooltip overlay={tagOverlayText} />}
											</Th>
										)}
										{isExpense && <Th width={"10%"}>Receipt</Th>}
										{!isExpense && (
											<Th align="center" width={"11%"}>
												Amount of time
												{isClient && (
													<GlobalTooltip overlay="This value is expressed in hours and minutes. (e.g. 2 and a half hours will be displayed as 02:30)" />
												)}
												{!isClient && isRateDaily && (
													<GlobalTooltip
														overlay={`${get(
															reportingSettings,
															"hours_representation",
															"0:0"
														).replace(":", "h")} of work time per day`}
														align={{ offset: [-18, 0] }}
													/>
												)}
											</Th>
										)}
										{!isExpense && <Th width={"8%"}>Rate</Th>}
										<Th width={"9%"}>Status</Th>
										{!hideAmount && <Th width={"8%"}>Amount</Th>}
										<Th width="5%"></Th>
										<Th width="8%"></Th>

										<Th width="9%" />
									</tr>
								</thead>
								<tbody>
									{sortedList.map((_item, index) => (
										<RendrerItem
											_item={_item}
											key={index}
											index={index}
											isLastItem={true}
											checked={checked}
											companyId={companyId}
											hasTag={hasTag}
											isExpense={isExpense}
											type={type}
											disputedWorkTime={disputedWorkTime}
											hideAmount={hideAmount}
											disputedExpense={disputedExpense}
											isMultiSelect={isMultiSelect}
											editItemId={editItemId}
											setEditItemId={setEditItemId}
											isExpanded={isExpanded}
											setIsExpanded={setIsExpanded}
											highlighted={highlighted}
											setChecked={setChecked}
											checkAll={checkAll}
											canApprove={canApprove}
											setIsAllElementHasChecked={setIsAllElementHasChecked}
											status={status}
											jobId={jobId}
											timesheetId={timesheetId}
											isTalent={isTalent}
											isClient={isClient}
											isLocked={isLocked}
											isWorkTime={isWorkTime}
											isOverTime={isOverTime}
											toggleDeleteModal={toggleDeleteModal}
											toggleDuplicateModal={toggleDuplicateModal}
											addedExpenses={addedExpenses}
											addedWorkTimes={addedWorkTimes}
											addedOverTimes={addedOverTimes}
											setAddedExpense={setAddedExpense}
											setAddedWorkTime={setAddedWorkTime}
											setAddedOverTime={setAddedOverTime}
											view={view}
											setCheckAll={setCheckAll}
											month={month}
											canApproveTags={canApproveTags}
											loadTimeSheet={loadTimeSheet}
											setDisputedExpense={setDisputedExpense}
											setDisputedWorkTime={setDisputedWorkTime}
											getInitialVisibleMonth={getInitialVisibleMonth}
											currentDocumentEditMode={currentDocumentEditMode}
											timesheetTagName={timesheetTagName}
											showCheckboxes={showCheckboxes}
											isRateDaily={isRateDaily}
											reportingSettings={reportingSettings}
										/>
									))}
								</tbody>
							</TimesheetTableDetails>
						)}
					</div>
				)}
			</Box>
		</LeaveHook>
	);
};

export default ListRenderer;
