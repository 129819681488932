import create from "zustand";

const initialState = { filters: null };

const store = set => ({
	...initialState,
	setFilters: values => set({ filters: values })
});

const useAdvancedFilter = create(store);
export default useAdvancedFilter;
