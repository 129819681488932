import React, { Component } from "react";
import { connect } from "react-redux";
import { getFeatures } from "./actions";
import { LoaderFull } from "../../common/Loader";
import WebSiteDown from "../../common/WebSiteDown";

class FeaturesLoader extends Component {
	componentDidMount() {
		return this.props.dispatch(getFeatures());
	}

	render() {
		const {
			features: { isFeatures, success }
		} = this.props;
		if (isFeatures && !success) return <WebSiteDown />;

		return <LoaderFull text="loading features..." classes="full-loader" />;
	}
}

const mapStateToProps = state => {
	return {
		features: state.features
	};
};

export default connect(mapStateToProps)(FeaturesLoader);
