import create from "zustand";

const initialState = {
	drawerState: {
		open: false,
		component: ""
	},
	indexTab: 0
};

const useVacancyStore = create(set => ({
	...initialState,
	setDrawerState: value => {
		set({
			drawerState: value
		});
	},
	setIndexTab: value => {
		set({
			indexTab: value
		});
	}
}));

export default useVacancyStore;
