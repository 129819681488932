import React from "react";
const SortUpIcon = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.00016 13.1666V3.83325M8.00016 3.83325L3.3335 8.49992M8.00016 3.83325L12.6668 8.49992"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default SortUpIcon;
