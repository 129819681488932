import React, { useEffect, useState, useRef } from "react";
import { useToggle } from "react-use";
import _get from "lodash/get";
import { queryCache } from "react-query";
import EditableSection from "modules/candidate/Profile/Pages/Profile/components/EditableSection";
import { ReactComponent as AddIcon } from "static/icons/focus-add.svg";
import s from "./profile-tags.module.scss";
import SelectTags from "./SelectTags";
import { ReactComponent as DeletItem } from "static/icons/cancelSmall.svg";
import useUpdateInternalData from "modules/candidate/Profile/api/useUpdateInternalData";
import toaster from "common/Toaster";
import { viewOverviewKey } from "modules/candidate/Profile/api/useOverview";
import { editHistoryKey } from "modules/candidate/Profile/api/useEditHistory";
import LeaveHook from "common/LeaveHook";
import useUnsavedChangesDialog from "modules/candidate/Profile/Store/useUnsavedChangesDialog";
import { usePopper } from "react-popper";

const ProfileTags = ({ cv_id, profile_id, tags }) => {
	const [on, toggle] = useToggle(false);
	const [editMode, setEditMode] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const [isDirty, setIsDirty] = useState(false);
	const referenceElement = useRef();
	const popperElement = useRef();
	const [
		isUnsavedChangesDialogOpen,
		toggleUnsavedChangesDialogOpen
	] = useUnsavedChangesDialog();

	useEffect(() => {
		setSelectedTags(tags);
	}, [tags]);
	const [
		updateStatus,
		{ isLoading: isLodingUpdateInternalData }
	] = useUpdateInternalData({
		onSuccess() {
			toaster.success("Candidate tags has successfully been updated.");
			queryCache.invalidateQueries("@candidate/view-Candidate-internal");
			queryCache.invalidateQueries(editHistoryKey);
			queryCache.invalidateQueries(viewOverviewKey);
			setEditMode(false);
		},
		onError(err) {
			toaster.danger(
				_get(err, "detail.fields_data[0]", "Something went wrong")
			);
		}
	});
	const deletTag = val => {
		const filteredTags = selectedTags.filter(tg => tg._id !== val._id);
		setSelectedTags(filteredTags);
		markDirty();
	};
	const onSave = payload => {
		const data = {
			profile_id: profile_id,
			cv_id: cv_id,
			fields_data: {
				tag: "profile_tags",
				value: {
					profile_tags: payload
				}
			}
		};
		updateStatus(data);
		resetDirty();
	};
	const handleAddTag = () => {
		toggle(true);
		setEditMode(true);
		markDirty();
	};

	const markDirty = () => {
		setIsDirty(true);
	};

	const resetDirty = () => {
		setIsDirty(false);
	};

	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "top-start",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, 40]
					}
				}
			]
		}
	);
	return (
		<LeaveHook
			dirty={isDirty && !isUnsavedChangesDialogOpen}
			onOpen={toggleUnsavedChangesDialogOpen}
			onStay={toggleUnsavedChangesDialogOpen}
			onLeaveClick={toggleUnsavedChangesDialogOpen}
		>
			<EditableSection
				title="Profile tags"
				isEdit={editMode}
				onEdit={() => setEditMode(true)}
				onCancel={() => {
					setEditMode(false);
					resetDirty();
				}}
				onSave={() => onSave(selectedTags)}
				isLoading={isLodingUpdateInternalData}
			>
				<div className={s.profileTagsItems}>
					{selectedTags.map(tag => {
						return (
							<span className={s.item} key={tag._id}>
								{tag.value}
								{editMode && (
									<button onClick={() => deletTag(tag)}>
										<DeletItem />
									</button>
								)}
							</span>
						);
					})}

					<SelectTags
						show={on}
						closeSelect={toggle}
						cv_id={cv_id}
						selectedTags={selectedTags}
						setSelectedTags={setSelectedTags}
						ref={popperElement}
						style={{
							...styles.popper,
							zIndex: "99999"
						}}
						attr={attributes.popper}
					/>
				</div>

				<div className={s.btnContainer}>
					<button
						className={s.btnAdd}
						ref={referenceElement}
						onClick={() => handleAddTag()}
					>
						<AddIcon />
						<span>Add tag</span>
					</button>
				</div>
			</EditableSection>
		</LeaveHook>
	);
};

export default ProfileTags;
