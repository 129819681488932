import React from "react";
import styled, { css } from "styled-components";
import MapIcon from "./icons/map";
import EditIcon from "./icons/edit";
import { ADDRESS_VIEW_TYPES } from "config";

const containerWidth = 210;

const Container = styled.div`
	display: flex;
	align-items: flex-start;
	position: absolute;
	right: 10px;
	top: 10px;
	width: ${containerWidth}px;
	background-color: #ffffff;
	padding: 2px;
	border-radius: 3px;
	box-shadow: 0px 0px 10px 0px rgba(67, 90, 111, 0.3),
		0px 0px 0px -4px rgba(67, 90, 111, 0.47);
	margin-bottom: 10px;
	z-index: 10;
`;

const Switcher = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: transparent;
	height: 42px;
	padding: 0 20px 2px;
	text-align: left;
	white-space: nowrap;
	text-transform: uppercase;
	font-weight: 400;
	font-size: 13px;
	color: #58626b;
	border-radius: 3px;
	border: none;
	${props =>
		props.active &&
		css`
			color: #ffffff;
		`};
	z-index: 2;
	outline: none;
`;

const Highlighter = styled.span`
	display: block;
	background: #ff6849;
	width: calc(50% - 4px);
	height: 38px;
	position: absolute;
	top: 4px;
	left: 0;
	transition-delay: 0.1s;
	transition: all 0.25s cubic-bezier(0.18, 0.88, 0.29, 1.08);
	z-index: 1;
	border-radius: 3px;
	transform: translateX(
		${props => (props.active > 0 ? props.active * (containerWidth / 2) : 4)}px
	);
`;

export default function ViewSwitcher(props) {
	const active = props.selected;
	return (
		<Container className="address-switcher">
			<Switcher
				active={active === ADDRESS_VIEW_TYPES.inputs}
				onClick={() => props.onSwitch(ADDRESS_VIEW_TYPES.inputs)}
				type="button"
				className={active === ADDRESS_VIEW_TYPES.inputs ? "is-active" : ""}
			>
				<EditIcon fill="currentColor" />
				&nbsp;<span>Edit</span>
			</Switcher>
			<Switcher
				active={active === ADDRESS_VIEW_TYPES.map}
				onClick={() => props.onSwitch(ADDRESS_VIEW_TYPES.map)}
				type="button"
				className={active === ADDRESS_VIEW_TYPES.map ? "is-active" : ""}
			>
				<MapIcon fill="currentColor" />
				&nbsp;<span>Map</span>
			</Switcher>
			<Highlighter
				className="highlighter"
				active={active === ADDRESS_VIEW_TYPES.map ? 1 : 0}
			/>
		</Container>
	);
}
