import React from "react";
import RadioGroup from "../../RadioGroup/RadioGroup";
import { useFilters } from "../hooks/useFilter";
import styles from "./source-row.module.scss";

const SourceRow = () => {
	const { setSource, getState, currentFilterId } = useFilters();
	const { source, sources } = getState(currentFilterId);

	const handleChange = e => {
		setSource(e.target.value);
	};

	const options = [];
	for (const key in sources) {
		options.push(sources[key]);
	}

	return (
		<div className={styles.root}>
			<div className={styles.text}>Search on</div>
			<RadioGroup options={options} value={source} onChange={handleChange} />
		</div>
	);
};
export default SourceRow;
