import CommunityCriteria from "modules/SearchCandidate/components/CommunityCriteria/DrawerCommunityCriteria";
import React, { useMemo } from "react";
import { Tabs } from "../../Tabs";
import FilterDrawerAdvancedTab from "../FilterDrawerAdvancedTab/FilterDrawerAdvancedTab";
import styles from "./filter-drawer-body.module.scss";

const FilterDrawerBody = ({
	onlyList = false,
	hideTabSaved,
	placeHolder,
	displayAdvancedSearchHead
}) => {
	const tabsArrary = useMemo(() => {
		if (hideTabSaved) {
			return [
				{
					id: 1,
					header: "Advanced",
					Component: FilterDrawerAdvancedTab,
					placeHolder
				}
			];
		} else {
			return [
				{
					id: 1,
					header: "Advanced",
					Component: FilterDrawerAdvancedTab,
					placeHolder,
					hideTabSaved
				},
				{
					id: 3,
					header: "Saved",
					Component: CommunityCriteria,
					hideTabSaved
				}
			];
		}
	}, [hideTabSaved]);
	return (
		<div className={styles.container}>
			{onlyList || hideTabSaved ? (
				<FilterDrawerAdvancedTab
					onlyList={onlyList}
					hideTabSaved={hideTabSaved}
					displayAdvancedSearchHead={displayAdvancedSearchHead}
				/>
			) : (
				// <AdvancedSearch onlyList={onlyList} hideTabSaved={hideTabSaved} />
				<Tabs items={tabsArrary} />
			)}
		</div>
	);
};

export default FilterDrawerBody;
