import { useState, useEffect } from "react";
import { capitalize } from "lodash";
import loadable from "loadable-components";
import {
	API_DATA_TYPE,
	columnFiltersMap,
	columnSortMap,
	TASKS_LIST_FILTER_ID,
	CHECK_FILTER_COLUMNS,
	TASKS_LIST_UNFILTERED_COLUMNS
} from "../utils/constant";
import TasksListSelectColumn from "../components/TasksListSelectColumn";

const TasksListTableCell = loadable(() =>
	import("../components/TasksListTableCell/TasksListTableCell")
);

const LoadingStateCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/LoadingStateCell/LoadingStateCell"
	)
);

const TableMenu = loadable(() =>
	import("common/AgTable/components/MenuColumn")
);

export const useGetColumns = ({
	isLoading,
	isFetching,
	tableRef,
	columns,
	onActionsCellClick
}) => {
	const [columnsList, setColumnsList] = useState([]);

	useEffect(() => {
		const columnsSettings = columns?.map(column => {
			return {
				field: column.colId,
				width: column.width,
				minWidth: 80,
				colId: column.colId,
				hide: column.hide,
				pinned: column.pinned ?? null,
				filter: TableMenu,
				cellRenderer:
					isLoading || isFetching ? LoadingStateCell : TasksListTableCell,
				cellClass: "locked-visible",
				lockVisible: true,
				refGrid: tableRef,
				typeTag: API_DATA_TYPE,
				columnSortMap: columnSortMap,
				columnFiltersMap: columnFiltersMap,
				currentFilterId: TASKS_LIST_FILTER_ID,
				checkFilterColumns: CHECK_FILTER_COLUMNS,
				isSortable: column.sortable,
				headerName: capitalize(column.headerName),
				hideFilter: TASKS_LIST_UNFILTERED_COLUMNS.includes(column.colId)
			};
		});

		const actionColumn = {
			colId: "_selects",
			field: "",
			width: 50,
			resizable: false,
			hide: false,
			filter: false,
			pinned: "left",
			lockVisible: true,
			lockPinned: true,
			lockPosition: "left",
			cellClass: "locked-col lock-pinned centered",
			cellRenderer:
				isLoading || isFetching ? LoadingStateCell : TasksListSelectColumn,
			cellRendererParams: {
				onItemClick: onActionsCellClick
			}
		};

		setColumnsList([actionColumn, ...columnsSettings] ?? []);
	}, [columns, isFetching, isLoading, onActionsCellClick]);

	return columnsList;
};
