import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field, formValueSelector, initialize } from "redux-form";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { OutlineButton } from "common/styled/buttons";
import {
	FloatedBtn,
	RadioBtnResult,
	BtnContainer,
	SelectTitle,
	ErrorLabel
} from "../styles/StyledInterview";
import MultipleSelect from "common/multipleSelect/index";

import {
	IN_DEF_START_TIME,
	IN_DEF_END_TIME,
	SEARCH_ADDRESS,
	COMPANY_ADDRESS,
	VIDEO_CONFERENCE,
	TEXTAREA_MAX_LENGTH,
	TIMEZONE
} from "config";
import TimeChooser from "common/TimeChooser";
import FormField from "common/FormField";
import { isAddressComplete, isEmpty } from "common/Functions";
import videoIcon from "static/icons/videocall.svg";
// import { CONSULTANCY } from "config";
import AddressBox from "common/AddressBox/AddressChooser";
import { handleAddressObject } from "config/helpers";

class ClientInterviewModal extends Component {
	constructor(props) {
		super(props);
		const { previousDate } = props;
		this.state = {
			startTime: IN_DEF_START_TIME,
			endTime: IN_DEF_END_TIME,
			valid: true,
			error: false,
			address: {},
			addressTypo: false,
			isCalendarChanged: previousDate ? true : false,
			hasError: false
		};
	}

	componentDidMount() {
		const {
			user,
			previousValues,
			previousDate,
			interviewDescription
		} = this.props;
		this.initializeReduxForm({
			location_type:
				previousValues && previousValues.location_type
					? previousValues.location_type
					: VIDEO_CONFERENCE,
			start_date: previousDate && previousDate,
			description: interviewDescription && interviewDescription
		});

		this.setState({
			address: {
				street:
					(previousValues && previousValues.street) || user.company_street,
				number:
					(previousValues && previousValues.number) || user.company_number,
				box: (previousValues && previousValues.box) || user.company_box,
				city: (previousValues && previousValues.city) || user.company_city,
				zip: (previousValues && previousValues.zip) || user.company_zip,
				country:
					(previousValues && previousValues.country) || user.company_country,
				longitude:
					(previousValues && previousValues.longitude) ||
					user.company_longitude,
				latitude:
					(previousValues && previousValues.latitude) || user.company_latitude
			}
		});
	}

	initializeReduxForm = data => {
		this.props.initializeReduxForm({
			...this.props.formFields,
			...data
		});
	};

	setAddress = address => {
		const newAddress = {
			street: address.street || "",
			country: address.country || "",
			zip: address.zip || "",
			city: address.city || "",
			number: address.number || "",
			box: address.box || "",
			latitude: address.latitude || "",
			longitude: address.longitude || "",
			formatted_address: address.formatted_address || "",
			iso_country: address.iso_country || ""
		};
		if (
			newAddress.country !== _get(this.state, "address.country") ||
			newAddress.city !== _get(this.state, "address.city")
		) {
			if (
				newAddress.longitude === _get(this.state, "address.city") ||
				newAddress.latitude === _get(this.state, "address.latitude")
			) {
				newAddress.latitude = 0;
				newAddress.longitude = 0;
			}
		}
		this.setState({ address: newAddress }, () => {
			this.state.error && this.setState({ error: !this.isAddressInvalid() });
		});
	};

	handlerStartTime = (value, valid) => {
		const { saveStartTime } = this.props;
		this.setState({ startTime: value, valid });
		saveStartTime(value);
	};

	handlerEndTime = (value, valid) => {
		const { saveEndTime } = this.props;
		this.setState({ endTime: value, valid });
		saveEndTime(value);
	};

	changeCompanyType = (event, value) => {
		const { user } = this.props;
		if (value === COMPANY_ADDRESS || value === SEARCH_ADDRESS) {
			this.setAddress({
				street: user.company_street,
				number: user.company_number,
				box: user.company_box,
				city: user.company_city,
				zip: user.company_zip,
				country: user.company_country,
				longitude: user.company_longitude,
				latitude: user.company_latitude
			});
		}
		if (value === VIDEO_CONFERENCE) {
			this.setAddress({});
		}
	};

	getAddress = () => {
		const { user } = this.props;
		return `${user.company_street ? user.company_street : ""} ${
			user.company_number ? user.company_number : ""
		} ${user.company_street || user.company_number ? "," : ""} ${
			user.company_zip ? user.company_zip : ""
		} ${user.company_city ? user.company_city : ""} ${
			user.company_country ? user.company_country : ""
		}`;
	};

	getAddressObject = () => {
		const { user } = this.props;
		return {
			street: user.company_street,
			number: user.company_number,
			box: user.company_box,
			city: user.company_city,
			zip: user.company_zip,
			country: user.company_country,
			longitude: user.company_longitude,
			latitude: user.company_latitude
		};
	};

	formSubmitted = async formProps => {
		const {
			submitInterviewForm,
			bid_id,
			setPreviousDate,
			selectedAttendees,
			setInterviewDescription
		} = this.props;
		const { start_date } = formProps;
		let attendeesId = [];
		selectedAttendees.map(attendee => {
			attendeesId.push(attendee.value);
		});
		setPreviousDate(start_date);
		const { endTime, startTime, valid, isCalendarChanged } = this.state;
		selectedAttendees.length == 0 &&
			this.setState({
				hasError: true
			});

		if (!valid || selectedAttendees.length == 0) return;
		let payload = {
			...formProps,
			bid_id: bid_id
		};

		if (isCalendarChanged) {
			const startDate = window
				.moment(formProps.start_date.clone())
				.tz(TIMEZONE);
			const endDate = window.moment(formProps.start_date.clone()).tz(TIMEZONE);
			const [startTimeHour, startTimeMinute] = startTime.split(":");
			const interview_start_date = startDate
				.set({
					hour: startTimeHour,
					minute: startTimeMinute
				})
				.utc()
				.unix();

			const [endTimeHour, endTimeMinute] = endTime.split(":");
			const interview_end_date = endDate
				.set({
					hour: endTimeHour,
					minute: endTimeMinute
				})
				.utc()
				.unix();

			payload.interview_start_date = interview_start_date;
			payload.interview_end_date = interview_end_date;
		}

		if (formProps.location_type === SEARCH_ADDRESS) {
			if (this.isAddressInvalid()) {
				return this.setState({ error: true });
			}
		}

		if (formProps.location_type !== VIDEO_CONFERENCE) {
			const finalAddress = await handleAddressObject(this.state.address);

			if (finalAddress.addressTypo) {
				this.setState({
					error: true,
					addressTypo: true
				});
				return this.setState({
					error: false,
					addressTypo: false
				});
			}
			this.setState({
				...this.state,
				...finalAddress
			});
			payload = {
				...payload,
				...this.state.address
			};
		}
		delete payload.start_date;
		setInterviewDescription(payload.description);
		submitInterviewForm({
			...payload,
			isCalendarChanged,
			attendees_users: attendeesId
		});
	};

	isAddressInvalid = () => {
		const { address } = this.state;
		return (
			!_get(address, "street") ||
			!_get(address, "number") ||
			!_get(address, "city") ||
			!_get(address, "country") ||
			!_get(address, "zip")
		);
	};

	handleCalendarChanged = isChanged => {
		this.setState({ isCalendarChanged: isChanged });
	};

	render() {
		const {
			handleSubmit,
			formFields,
			submitFailed,
			handleBackStep,
			previousStartTime,
			previousEndTime,
			attendeesList,
			user,
			setSelectedAttendees,
			selectedAttendees
		} = this.props;
		let attendeesOptions = [];
		let userOption = {
			value: user.id,
			label: `${user.first_name} ${user.last_name}`
		};
		attendeesOptions.push(userOption);
		attendeesList &&
			attendeesList.map(attendee => {
				attendeesOptions.push({
					value: attendee._id,
					label: attendee.user_name
				});
			});

		const { hasError } = this.state;
		const address = this.getAddress();
		return (
			<div className="content">
				<div className="interview_content">
					<TimeChooser
						handleCalendarChanged={this.handleCalendarChanged}
						handlerStartTime={this.handlerStartTime}
						handlerEndTime={this.handlerEndTime}
						label="Interview Date"
						dateClass="col-md-5"
						timeStartClass="col-md-3"
						timeEndClass="col-md-3"
						previousStartTime={previousStartTime}
						previousEndTime={previousEndTime}
						displayResetIcon={true}
					/>
					<div className="row">
						<div className="col-md-11">
							<SelectTitle>Attendee(S)</SelectTitle>
							<MultipleSelect
								options={attendeesOptions}
								defaultValue={userOption}
								value={selectedAttendees}
								handleSelectAttendees={values => {
									this.setState({
										hasError: false
									});
									setSelectedAttendees([...values]);
								}}
								className={hasError && "basic-multi-select select-on-error"}
								hasError={hasError}
							/>
							{hasError && (
								<ErrorLabel className="error-label">
									{"Attendees can't be empty"}
								</ErrorLabel>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col-md-11">
							<Field
								name="description"
								label="Message"
								type="textarea"
								className="col-md-11"
								component={FormField}
								maxLength={TEXTAREA_MAX_LENGTH}
								placeholder={
									"Feel free to use this space to provide any additional information needed for the interview."
								}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-11">
							<div className="radio-group location-selector">
								<label htmlFor="location_type">Location</label>
								<Fragment>
									<Field
										tabIndex={0}
										name="location_type"
										id={COMPANY_ADDRESS}
										component={FormField}
										classes="radio first"
										type="radio"
										value={COMPANY_ADDRESS}
										label="Use company address"
										onChange={this.changeCompanyType}
									/>
									<Field
										tabIndex={0}
										name="location_type"
										id={SEARCH_ADDRESS}
										component={FormField}
										classes="radio"
										type="radio"
										value={SEARCH_ADDRESS}
										label="Use another address"
										onChange={this.changeCompanyType}
									/>
									<Field
										tabIndex={0}
										name="location_type"
										id={VIDEO_CONFERENCE}
										component={FormField}
										classes="radio"
										type="radio"
										value={VIDEO_CONFERENCE}
										label="Video conference"
										onChange={this.changeCompanyType}
									/>
								</Fragment>
							</div>

							{formFields.location_type === COMPANY_ADDRESS &&
								!isAddressComplete(this.getAddressObject()) && (
									<div key="incomplete" className="msg error-msg">
										<div className="msg-content">
											{isEmpty(this.getAddressObject())
												? "No company address has been provided for this account yet. "
												: "Your provided company address is not complete. "}
											To be able to use this option, please ask your account’s
											Super-Admin to fill the missing details in{" "}
											{/* <Link to={getCompanyRoute()}>
												SETTINGS → COMPANY → EDIT ADDRESS{" "}
											</Link> */}
										</div>
									</div>
								)}

							<RadioBtnResult>
								{formFields && formFields.location_type === SEARCH_ADDRESS && (
									<Fragment>
										<AddressBox
											setAddress={this.setAddress}
											isRequired={true}
											showAutoComplete
											name="location"
											address={this.state.address}
											showToast={this.state.error}
											error={
												this.state.error ||
												(this.isAddressInvalid() && submitFailed)
											}
											addressTypo={this.state.addressTypo}
										/>
									</Fragment>
								)}

								{formFields.location_type === VIDEO_CONFERENCE && (
									<div className="video-conf">
										<img src={videoIcon} alt="camera" className="camera" />
										<div>
											<div className="text">Video Conference</div>
											<div>Virtual Meeting Room</div>
										</div>
									</div>
								)}
								{formFields.location_type === COMPANY_ADDRESS && (
									<div className="form-group inner-icon left-icon">
										<div className="input-box">
											<i className="fa fa-map-marker" />
											<input
												className="form-control"
												value={address}
												readOnly
											/>
										</div>
									</div>
								)}
							</RadioBtnResult>
						</div>
					</div>
					<BtnContainer>
						<FloatedBtn
							type={"primary"}
							bordered
							onClick={handleSubmit(this.formSubmitted)}
						>
							Propose
						</FloatedBtn>
						<OutlineButton onClick={() => handleBackStep(false)}>
							Back
						</OutlineButton>
					</BtnContainer>
				</div>
			</div>
		);
	}
}

const validate = formProps => {
	let errors = {};
	if (!formProps.start_date) {
		errors.start_date = "time period can't be empty";
	}

	if (
		formProps.description &&
		formProps.description.length > TEXTAREA_MAX_LENGTH
	) {
		errors.description = `This field should not exceed ${TEXTAREA_MAX_LENGTH} characters`;
	}

	return errors;
};

const selector = formValueSelector("inviteFreelancer");

const mapStateToProps = state => {
	return {
		formFields: selector(state, "start_date", "location_type", "description")
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initializeReduxForm: data => dispatch(initialize("inviteFreelancer", data))
	};
};

ClientInterviewModal.propTypes = {
	interviewDispatch: PropTypes.func.isRequired,
	profile: PropTypes.string.isRequired,
	bid_id: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
	toggleInterviewModal: PropTypes.func.isRequired,
	is_edit: PropTypes.bool.isRequired,
	is_decline: PropTypes.bool.isRequired
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "inviteFreelancer",
		validate,
		touchOnBlur: false
	})
)(ClientInterviewModal);
