import React, { useMemo, useRef } from "react";
import { EB_URL } from "config/api-config";
import { SearchInput } from "common/SearchInput";
import CompanyCard from "../CompanyCard";
import Pagination from "common/Pagination";
import { ReactComponent as IconSearch } from "static/icons/IconSearch.svg";
import { ReactComponent as IconSearchClose } from "static/icons/IconSearchClose.svg";
import { ReactComponent as IconChevronLeft } from "static/icons/IconChevronLeft.svg";
import { ReactComponent as IconChevronRight } from "static/icons/IconChevronRight.svg";
import styles from "../companies.module.scss";
import ConfirmActionModal from "../ConfirmActionModal";
import useCompaniesStore from "../store/useCompaniesStore";
import { LEAVE_POOL, MODALS } from "../ConfirmActionModal/config";
import SearchEmptyState from "../SearchEmptyState";
import { useCompaniesActionsWithModal } from "../hooks/useCompaniesActions";
import { SkeletonList } from "../SkeletonCard";
import "./pagination.scss";
import Loader from "common/Loader";

export default function CompaniesList({
	payload,
	isLoading,
	isFetching,
	onPaginate,
	currentPage,
	searchValue,
	onSearchClear,
	onSearchChange
}) {
	// store and state
	const {
		resetModal,
		searchQuery,
		setFormIsValid,
		modal: {
			actionType,
			formIsValid,
			slug: modalSlug,
			company: selectedCompany
		}
	} = useCompaniesStore();
	const searchRef = useRef(null);

	// api hooks
	const [
		onMutate,
		{ isLoading: modalIsLoading }
	] = useCompaniesActionsWithModal();

	const onClose = () => resetModal();
	const modalBody = useMemo(() => MODALS[modalSlug], [modalSlug]);
	const searchEmptyState = (
		<>
			<div className={styles.companies__count}>
				<span>{payload?.pagination?.total}</span>
				{" companies"}
			</div>
			<SearchEmptyState />
		</>
	);

	const searchIcon = (
		<div
			className={`${styles.search__input_icon} ${
				searchQuery ? styles.icon_close : ""
			}`}
			{...(searchQuery ? { onClick: onSearchClear } : {})}
		>
			{isLoading && searchQuery ? (
				<Loader classes="search-loader" check={false} />
			) : searchQuery ? (
				<IconSearchClose />
			) : (
				<IconSearch />
			)}
		</div>
	);
	return (
		<>
			{/* Confirmation modal  */}
			{modalSlug && (
				<ConfirmActionModal
					onClose={onClose}
					formIsValid={formIsValid}
					isLoading={modalIsLoading}
					loaderStyle={{ borderTopColor: "#00857a" }}
					onConfirm={() => onMutate(actionType, selectedCompany)}
					confirmLabel={actionType === LEAVE_POOL ? "Leave" : "Unfollow"}
				>
					{modalBody.render({
						value: formIsValid,
						setValue: setFormIsValid
					})}
				</ConfirmActionModal>
			)}

			{/* Search bar */}
			<div
				className={`${styles.flex} ${styles.items_center} ${styles.justify_between}`}
			>
				<SearchInput
					ref={searchRef}
					value={searchValue}
					onChange={onSearchChange}
					className={styles.search__input}
					placeholder="Search..."
					icon={searchIcon}
				/>
				<button
					className={`${styles.btn} ${styles.btn__lg} ${styles.btn__green}`}
					onClick={() => window.open(EB_URL + "/companies", "_blank")}
				>
					Discover new companies
				</button>
			</div>

			{/* Companies data  */}
			{isLoading ? (
				<SkeletonList items={9} />
			) : !payload.pagination.total ? (
				searchEmptyState
			) : (
				<>
					<div className={styles.companies__count}>
						<span>{payload.pagination.total}</span>
						{payload.pagination.total === 1 ? " company" : " companies"}
					</div>
					<div className={styles.companies__container}>
						{payload.data.map(item => {
							return (
								<CompanyCard
									record={item}
									key={item._id}
									isFetching={isFetching}
								/>
							);
						})}
					</div>
					<div>
						{/* Pagination */}
						<Pagination
							forcePage={currentPage}
							handlePageClick={onPaginate}
							size={payload.pagination.size}
							total={payload.pagination.total}
							offset={payload.pagination.offset}
							containerClassName="green-pagination"
							previousLabel={disabled => (
								<IconChevronLeft
									className={disabled ? "inactive-chevron" : "active-chevron"}
								/>
							)}
							nextLabel={disabled => (
								<IconChevronRight
									className={disabled ? "inactive-chevron" : "active-chevron"}
								/>
							)}
						/>
					</div>
				</>
			)}
		</>
	);
}
