import { useQuery } from "react-query";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getFrequency = () => {
	return client(NEW_FILTER_SETTINGS, {
		body: {
			list: "requests",
			tag: "timesheet_frequency"
		}
	});
};

export const GET_PREFERRED_FREQUENCY_QUERY_KEY =
	"@request-form/preferred-frequency";

export const useGetTimesheetFrequencySetting = () => {
	const res = useQuery(GET_PREFERRED_FREQUENCY_QUERY_KEY, getFrequency, {
		refetchOnWindowFocus: false
	});

	return res?.data?.timesheet_frequency;
};
