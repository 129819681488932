import create from "zustand";

const initialialState = {
	channelName: "",
	offset: 0,
	tab: ""
};

const useFiltersStore = create(set => ({
	...initialialState,
	setvalue: (key, value) => set({ [key]: value, offset: 0 }),
	setOffset: value => set({ offset: value }),
	reset: () => set(initialialState)
}));

export default useFiltersStore;
