import _get from "lodash/get";
import { getMyProfile } from "modules/user/actions/authActions";
import { useMutation } from "react-query";
import { client } from "lib/api-client";
import {
	NOTIFICATIONS_SETTINGS,
	UPDATE_USER_STATUS
} from "config/api-endpoints";
import { useDispatch, useSelector } from "react-redux";
import { INACTIVE } from "config";
import toaster from "common/Toaster";

const TOASTER_MSG = {
	public: "Your account is now set to active mode!",
	passive: "Your account is now set to passive mode!",
	inactive: "Your account is now set to inactive mode!"
};

export const useUpdateUserStatus = () => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.auth.user);
	const show_notification_in_real_time = _get(
		user,
		"show_notification_in_real_time"
	);
	const receive_matching_jobs_emails_period = _get(
		user,
		"receive_matching_jobs_emails_period"
	);

	const [mutate, { isLoading }] = useMutation(
		body => client(UPDATE_USER_STATUS, { body }),
		{
			onSuccess: (_, body) => {
				if (body.status === INACTIVE) disableNotificationsSettings();
				else {
					dispatch(getMyProfile());
					toaster.success(_get(TOASTER_MSG, `${body.status}`));
				}
			},
			onError: () => toaster.danger("Something went wrong. Please try again!")
		}
	);

	// disable nofification status when inactive
	const [disableNotificationsSettings] = useMutation(
		() => {
			return client(NOTIFICATIONS_SETTINGS, {
				body: {
					receive_all_emails: false,
					receive_matching_jobs_emails: false,
					show_notification_in_real_time,
					receive_matching_jobs_emails_period
				}
			});
		},
		{
			onSuccess: () => {
				dispatch(getMyProfile());
				toaster.success(_get(TOASTER_MSG, INACTIVE));
			},
			onError: () => toaster.danger("Something went wrong. Please try again!")
		}
	);

	return [mutate, { isLoading }];
};
