import React from "react";
import { useDebounce } from "react-use";
import s from "./filters.module.scss";
import useFiltersStore from "../../../store/useFiltersStore";

const ChannelsFilters = () => {
	const [search, setSearch] = React.useState("");
	const { setvalue, reset } = useFiltersStore();

	useDebounce(
		() => {
			setvalue("channelName", search);
		},
		650,
		[search]
	);

	const handleInputChange = e => setSearch(e.target.value);

	return (
		<div className={s.root}>
			<div className={s.header}>
				<label>Filters</label>
				<button
					onClick={() => {
						reset();
						setSearch("");
					}}
				>
					{" "}
					Clear all{" "}
				</button>
			</div>
			<div>
				<label>Channel name</label>
				<input
					type="text"
					onChange={handleInputChange}
					value={search}
					name="channel-name"
					placeholder="Type..."
					className={s.input}
				/>
			</div>
		</div>
	);
};

export default ChannelsFilters;
