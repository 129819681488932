import React from "react";
import _get from "lodash/get";
import { Button } from "common/styled/buttons";
import CurrencyFormatter from "common/CurrencyFormater";
import { SidebarPadding } from "modules/vacancy/components/pokeView/Styled";
import { VacancyInformations } from "modules/candidate/components/poke/styled";
import { Title } from "./Styled";
import { browserHistory } from "react-router";
import {
	INACTIVE,
	REMOTE,
	REMOTE_LABEL,
	EMPLOYMENT_TYPE,
	HYBRID_OPTION,
	REMOTE_ELIGIBITY_OPTIONS
} from "config";
import GlobalTooltip from "common/GlobalTooltip";

const Sidebar = ({
	job,
	displayApplyModal,
	canApplyToJob,
	isLoading,
	templateId,
	isOnline,
	candidateStatus
}) => {
	const redirectToJob = () => {
		let link = `/pokes/${job.poke_id}`;
		if (templateId) {
			link += `?template=${templateId}`;
		}
		link += `?search`;
		browserHistory.push(link);
	};
	const locationTypeRemote = `${_get(job, "location_type")}` === REMOTE;
	const payment_time = _get(job, "payment_time", "yearly");
	const payment_type = _get(job, "payment_type", "gross");
	const symbol = _get(job, "symbol", "€");
	const pokeID = _get(job, "poke_id");

	const displayAction = () => {
		if (canApplyToJob || !isOnline || isLoading) {
			return (
				<div className="actions">
					<Button
						block
						bordered
						small
						onClick={() => {
							!isLoading && displayApplyModal(true);
						}}
						disabled={isLoading}
					>
						Apply for this job
					</Button>
				</div>
			);
		}
		if (candidateStatus === INACTIVE) {
			return (
				<div className="actions inactiveButton">
					<GlobalTooltip
						active={true}
						placement="top"
						mouseEnterDelay={0}
						mouseLeaveDelay={0}
						overlay={"Inactive candidate Can't apply"}
					>
						<Button block bordered small>
							Apply for this job
						</Button>
					</GlobalTooltip>
				</div>
			);
		}
		if (!canApplyToJob && pokeID !== null) {
			return (
				<div className="actions">
					<Button block bordered small onClick={redirectToJob}>
						View application
					</Button>
				</div>
			);
		}
	};
	const getEmploymentType = empType => {
		const employment = EMPLOYMENT_TYPE.find(type => type.value === empType);
		return _get(employment, "label");
	};

	const mergeBenefits = (otherBenefits, extraBenefits) => {
		let extraBenefitsString = "";

		if (extraBenefits) {
			extraBenefitsString = extraBenefits.map(({ label }) => label).join(", ");
		}

		if (otherBenefits && !extraBenefitsString) return otherBenefits;
		else if (!otherBenefits && extraBenefitsString) return extraBenefitsString;
		else if (otherBenefits && extraBenefitsString)
			return `${otherBenefits}, ${extraBenefitsString}`;
		else return "";
	};

	let value;

	if (job) {
		const {
			remote_eligibility: { office_days, type }
		} = job;

		if (office_days && type.includes(HYBRID_OPTION.value)) {
			value = `Hybrid (${office_days} days in office/week)`;
		} else {
			const { label } = REMOTE_ELIGIBITY_OPTIONS.find(
				({ value }) => value === type
			);

			value = label;
		}
	}

	return (
		<div className="sidebar" style={{ marginTop: "68px" }}>
			<div className="section">
				<SidebarPadding>
					{displayAction()}
					<VacancyInformations marginTop={canApplyToJob ? "20px" : "0px"}>
						<Title>COMPANY NAME</Title>
						<span className="data">{_get(job, "company.name")}</span>
						<Title>Remote eligibility</Title>
						<span className="data">{value}</span>
						{isOnline && (
							<>
								<Title>EMPLOYMENT TYPE</Title>
								<div>{getEmploymentType(_get(job, "employment_type"))}</div>
								<div>
									{_get(job, "expected_duration.duration")}{" "}
									{_get(job, "expected_duration.period")}
								</div>
							</>
						)}

						{_get(job, "number_of_colleagues") && (
							<>
								<Title>NUMBER OF COLLEAGUES</Title>
								<span className="data">
									{_get(job, "number_of_colleagues")}
								</span>
							</>
						)}

						<Title>
							{payment_time} {payment_type} salary
						</Title>
						{!_get(job, "salary_range_start") ? (
							"NA"
						) : (
							<span className="data">
								Between{" "}
								<CurrencyFormatter
									cent={_get(job, "salary_range_start")}
									symbol={symbol}
								/>{" "}
								and{" "}
								<CurrencyFormatter
									cent={_get(job, "salary_range_end")}
									symbol={symbol}
								/>
							</span>
						)}
						<Title>Other benefits</Title>
						<span className="data">
							{job?.other_benefits || job?.extra_benefits?.length > 0
								? mergeBenefits(job.other_benefits, job.extra_benefits)
								: "None"}
						</span>
						<Title>Payroll country</Title>
						<span className="data">{_get(job, "payroll_country")}</span>
						<Title>Location</Title>
						{locationTypeRemote ? (
							<div>{REMOTE_LABEL}</div>
						) : (
							<>
								<div>
									{_get(job, "company.street")} {_get(job, "company.number")}
								</div>
								<div>
									{_get(job, "company.zip")} {_get(job, "company.city")}
								</div>
								<div>{_get(job, "company.country")}</div>
							</>
						)}
					</VacancyInformations>
				</SidebarPadding>
			</div>
		</div>
	);
};

export default Sidebar;
