import create from "zustand";

export const useVacancyFilesStore = create(set => ({
	acceptedFiles: [],
	setAcceptedFiles: files => set({ acceptedFiles: files }),

	rejectedFiles: [],
	setRejectedFiles: files => set({ rejectedFiles: files }),

	clearVacancyFilesStore: () => set({ acceptedFiles: [], rejectedFiles: [] })
}));
