import React from "react";

const NoteDrawerHeader = ({ title, onClose }) => {
	return (
		<div className="drawer-header-container">
			<div className="title">{title}</div>
			<div className="icon" onClick={onClose}>
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M15 5L5 15M5 5L15 15" stroke="#64748B" />
				</svg>
			</div>
		</div>
	);
};

export default NoteDrawerHeader;
