import { client } from "lib/api-client";
import { REQUEST_VIEW_KANBAN_SETTINGS } from "config/api-endpoints";
import { useQuery } from "react-query";

const queryFn = body => client(REQUEST_VIEW_KANBAN_SETTINGS, { body });

export const REQUEST_VIEW_KANBAN_SETTINGS_QUERY =
	"REQUEST_VIEW_KANBAN_SETTINGS_QUERY";

export const useTempKanbanSettingsApi = (payload, options = {}) =>
	useQuery(
		[REQUEST_VIEW_KANBAN_SETTINGS_QUERY, payload],
		() => queryFn(payload),
		{
			retry: 1,
			staleTime: 0,
			refetchOnWindowFocus: false,
			...options
		}
	);
