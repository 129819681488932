import React, { Component } from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import GlobalTooltip from "common/GlobalTooltip";
import { connect } from "react-redux";
import { showModal } from "modules/cv/actions";
import { LANGUAGES } from "config";
import { getLanguageTooltip } from "config/helpers";
import EmptyLanguages from "common/EmptyComponent";
import { permanentLanguagesSelector } from "modules/candidate/redux/selectors";
class Languages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			languages: [...props.languages],
			loading: false
		};
	}

	handleEditLangs = () => {
		if (this.props.isPermanent) {
			return this.props.displayPermanentModal();
		}
		this.props.dispatch(showModal(LANGUAGES));
	};

	renderLanguage(lang) {
		return (
			<Tooltip
				placement="top"
				overlay={<div>{getLanguageTooltip(lang.score)}</div>}
				trigger="hover"
				destroyTooltipOnHide={true}
				mouseLeaveDelay={0}
				overlayClassName="g-tool-white"
				key={lang._id}
			>
				<div className="tag">{`${lang.name} ( ${lang.score} / 5 )`}</div>
			</Tooltip>
		);
	}

	renderEmptyLanguages = () => {
		if (this.props.isPermanent && this.props.isClient) {
			return (
				<>
					<EmptyLanguages
						className="no-border small-size"
						title="There is no language added for this Candidate"
					/>
				</>
			);
		}
		if (!this.props.isClient) {
			return (
				<div className="empty-section">
					<div className="add btn-center-block" onClick={this.handleEditLangs}>
						<i className="fa fa-plus" /> Add languages
					</div>
				</div>
			);
		}
	};
	render() {
		let {
			languages,
			ownCV,
			isPermanent,
			permanentLanguages,
			isFreelancer,
			isClient
		} = this.props;
		if (isPermanent && permanentLanguages.length > 0) {
			languages = permanentLanguages;
		}

		return (
			<div id="languages" className="section action-hover">
				{ownCV && languages.length > 0 && !isClient && (
					<div className="section-edit pull-right">
						<button className="btn btn-sm" onClick={this.handleEditLangs}>
							{isFreelancer ? (
								<span className="icon-edit-2" />
							) : (
								<i className="fa fa-pencil" />
							)}
						</button>
					</div>
				)}
				<div className="section-title">
					Languages{" "}
					{ownCV && !isPermanent && (
						<GlobalTooltip
							maxWidth="410px"
							overlay="This last section lets you list the languages that you know and detail your fluency level for each of them. This data is also taken into account in the mission matching process"
						/>
					)}
				</div>
				<div className="section-body">
					{!ownCV && !isPermanent && languages.length === 0 && (
						<div className="alert alert-warning">No information available</div>
					)}

					{languages.length > 0 &&
						languages.map(lang => this.renderLanguage(lang))}

					{languages.length === 0 && this.renderEmptyLanguages()}
				</div>
			</div>
		);
	}
}

Languages.propTypes = {
	languages: PropTypes.array.isRequired
};

const mapStateToProps = state => {
	return {
		languages: state.cv.languages || [],
		isFetching: state.cv.isFetching,
		busySection: state.cv.busySection,
		permanentLanguages: permanentLanguagesSelector(state)
	};
};

export default connect(mapStateToProps)(Languages);
