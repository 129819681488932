import React from "react";
import { PageCard } from "../PageCard";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { connect } from "react-redux";
import get from "lodash/get";
import { PageCardLoading } from "../PageCardLoading";

const MissionPageCard = ({
	className,
	data,
	user,
	site_id,
	isLoading,
	hasMultiSite
}) => {
	const accesToken = get(user, "access_token", "");
	const originalUrl = get(data, "url_mission_list_page", "");

	const urlPageCms = `${originalUrl.replace(
		"/missions",
		""
	)}/api/c/${accesToken}?page=missions`;

	return (
		<>
			{isLoading ? (
				<PageCardLoading className={className} isLoading={isLoading} />
			) : (
				<PageCard
					originalUrl={originalUrl}
					feature_name="feature_cms_mission_page"
					url={urlPageCms}
					data={data}
					className={className}
					isLoading={isLoading}
					site_id={site_id}
					hasMultiSite={hasMultiSite}
					//tourData={tourData}
					title="Mission Page"
				/>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps, null)(MissionPageCard);
