import React, { useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { Link } from "react-router";
import { Flex, Box, Text } from "rebass";
import { useMutation } from "react-query";
import {
	CLIENT,
	FREELANCER,
	DATE_FORMAT_LLLL,
	FULL_TIME_LABEL,
	PART_TIME_LABEL,
	SENT,
	ADMIN,
	PROPOSE_CONTRACT,
	INVITE_INTERVIEW,
	CANCEL_APPLICATION,
	LIVE,
	CANCELLED,
	DECLINED,
	REJECT_APPLICATION,
	ACCEPT,
	ACCEPTED,
	EXPIRED,
	REJECTED,
	PROPOSE_ANOTHER_DATE,
	PROPOSE_ANOTHER_TERM,
	ACCEPT_MISSION,
	DONE,
	REFUSED,
	SIGNED,
	ASK_QUESTION,
	SIGN_CONTRACT,
	HOURLY,
	CURRENCY_SUFFIX
} from "config";
import { formatDate } from "common/Functions";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import GlobalTooltip from "common/GlobalTooltip";
import SideBarDropdown from "./SideBarDropdown";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import PokeNoteModal from "modules/vacancy/components/modals/PokeNoteModal";
import { client } from "lib/api-client";
import { SIGN_CONTRACT_ENDPOINT } from "config/api-endpoints";
import toaster from "common/Toaster";

const signContract = ({ contract_id }) =>
	client(SIGN_CONTRACT_ENDPOINT, {
		body: {
			contract_id
		}
	});
const BidSideBar = ({
	job: { full_time, budget, publish_date, start_date, end_date, company },
	reportingSetting,
	userType,
	canMakeProposal,
	interview,
	contract,
	companyId,
	bidStatus,
	userRole,
	toggleInterviewModal,
	toggleContractModal,
	toggleContractConfirmation,
	toggleRejectApplicationModal,
	acceptInterview,
	cancelBid,
	bidId,
	canProposeContract,
	toggleHelpModal,
	displayOnlyActions,
	fetchBid
}) => {
	const [displayAddNoteModal, setDisplayAddNoteModal] = useState(false);
	const [signContractModal, setSignContractModal] = useState(false);

	const [onSignContract, { isLoading }] = useMutation(signContract, {
		onSuccess: () => {
			fetchBid(bidId);
			setSignContractModal(false);
			toaster.success("The contract has successfully been signed.");
		},
		onError: err => {
			setSignContractModal(false);
			toaster.danger(_get(err, "detail.name", "Somewhere went wrong"));
		}
	});

	const handleActions = (action, disabled = false) => {
		if (disabled) {
			return;
		}
		switch (action) {
			case ACCEPT:
				return acceptInterview({
					interview_id: interview._id,
					token_invitation_id: interview.token_invitation_id,
					bid_id: bidId
				});
			case INVITE_INTERVIEW:
			case PROPOSE_ANOTHER_DATE:
				return toggleInterviewModal(action);
			case CANCEL_APPLICATION:
				return cancelBid(CANCEL_APPLICATION);
			case REJECT_APPLICATION:
				return toggleRejectApplicationModal();
			case ACCEPT_MISSION:
				return toggleContractConfirmation();
			case PROPOSE_CONTRACT:
			case PROPOSE_ANOTHER_TERM:
				return toggleContractModal(PROPOSE_CONTRACT);
			case ASK_QUESTION:
				return toggleHelpModal(ASK_QUESTION);
			case SIGN_CONTRACT:
				return setSignContractModal(true);
			default:
				break;
		}
	};

	const getClassname = (list, shouldDisable) => {
		let primary = "btn btn-primary-2 btn-block btn-sidebar";
		let outline = "btn btn-outline-2 btn-block btn-sidebar";
		let disabled =
			"btn btn-proposal btn-proposal-disabled btn-block btn-sidebar";

		let _length = list.length;

		if (shouldDisable) {
			return disabled;
		}
		if (_length === 0) {
			return primary;
		}
		if (_length >= 1) {
			return outline;
		}
	};

	const getNegativeButtonText = ({
		isClient,
		interview_id,
		contract_id,
		interview_status,
		contract_status,
		isContractLastId,
		isInterviewLastId
	}) => {
		let text = "Reject application";
		if (!interview_id && !contract_id && !isClient) text = "Cancel Application";
		const isClientInInterview = isClient && interview_id;
		const isClientInContract = isClient && contract_id;
		const isFreelancerInInterview = !isClient && interview_id;
		const isFreelancerInContract = !isClient && contract_id;

		if (isClientInInterview) {
			switch (interview_status) {
				case SENT:
				case DECLINED:
				case ACCEPTED:
					text = isInterviewLastId ? "Cancel Interview" : "Refuse interview";
					break;
				default:
					text = "Reject Application";
					break;
			}
		}
		if (isClientInContract) {
			if (contract_status === SENT) text = "Cancel Proposition";
			else text = "Refuse Proposition";
		}

		if (isFreelancerInInterview && isInterviewLastId) {
			text = "Cancel Interview";
		} else if (isFreelancerInInterview && !isInterviewLastId) {
			text = "Refuse Interview";
		}
		if (isFreelancerInContract) {
			if (
				(contract_status === SENT || contract_status === DECLINED) &&
				!isContractLastId
			)
				text = "Refuse Contract Proposition";
			else text = "Cancel Contract Proposition";
		}
		return text;
	};

	const getActions = () => {
		const {
			status: interview_status,

			_id: interview_id,
			last_action_company_id: interviewLastId,
			new_interview_end_date
		} = interview;
		const {
			status: contract_status,
			signed_status,
			_id: contract_id,
			last_action_company_id: contract_last_id
		} = contract;

		let _buttons = [];
		const isClient = userType === CLIENT;
		const isFreelancer = userType === FREELANCER;
		const isBidLive = bidStatus === LIVE;
		const isBidCancelled = bidStatus === CANCELLED || bidStatus === REJECTED;
		const contractDeclined = contract_status === DECLINED;
		const contractSent = contract_status === SENT;
		const contractAccepted = contract_status === ACCEPTED;
		const contractSigned = signed_status === SIGNED;
		const processEnded =
			contract_status === CANCELLED ||
			contract_status === REFUSED ||
			interview_status === REFUSED ||
			interview_status === CANCELLED ||
			isBidCancelled;
		const interviewSent = interview_status === SENT;
		const interviewDeclined = interview_status === DECLINED;
		const interviewExpired = interview_status === EXPIRED;
		const isInterviewLastId = interviewLastId && companyId === interviewLastId;
		const isContractLastId = contract_last_id && companyId === contract_last_id;
		const isInterviewDone = interview_status === DONE;
		const interviewCancelled = interview_status === CANCELLED;
		const hasInterviewExpired =
			new_interview_end_date &&
			window
				.moment()
				.isAfter(window.moment.unix(new_interview_end_date), "day");

		const interviewRefusedByFreelancer =
			isFreelancer && interview_status === REFUSED;

		if (processEnded) return _buttons;

		if (!isBidCancelled) {
			if (isClient && !contract_id && !interview_id) {
				_buttons.push(
					<button
						key="invite-to-interview"
						className={getClassname(_buttons)}
						onClick={() => handleActions(INVITE_INTERVIEW)}
					>
						Propose interview
					</button>
				);
			}
			if (
				!contract_id &&
				interview_id &&
				(interviewDeclined || interviewSent) &&
				!isInterviewLastId &&
				!interviewExpired
			) {
				_buttons.push(
					<button
						key="accept-interview"
						className={getClassname(_buttons)}
						onClick={() => handleActions(ACCEPT)}
					>
						Accept interview
					</button>
				);
			}

			if (
				!contract_id &&
				interview_id &&
				!hasInterviewExpired &&
				!isInterviewDone &&
				!interviewCancelled &&
				!interviewRefusedByFreelancer
			) {
				_buttons.push(
					<button
						key="propose-another-date"
						icon="icon-calendar2"
						className={getClassname(_buttons)}
						onClick={() =>
							handleActions(isClient ? INVITE_INTERVIEW : PROPOSE_ANOTHER_DATE)
						}
					>
						{isClient
							? "Propose another date/location"
							: "Propose another date"}
					</button>
				);
			}

			if (isClient && !contract_id) {
				let showTooltip =
					!canProposeContract ||
					(canMakeProposal !== undefined && !canMakeProposal) ||
					userRole !== ADMIN;

				_buttons.push(
					<GlobalTooltip
						placement={"top"}
						noClassName={true}
						overlayClassName={"g-tool-white top center-text"}
						maxWidth={"300px"}
						key="propose-contract"
						icon="icon-calendar2"
						active={showTooltip}
						overlay={
							<span>
								{!canProposeContract
									? "Sorry, your account status doesn’t allow you to make contract propositions."
									: "You can only make one contract proposition per request."}
							</span>
						}
					>
						<button
							key="propose-contract"
							className={getClassname(_buttons, showTooltip)}
							onClick={() => handleActions(PROPOSE_CONTRACT, showTooltip)}
						>
							Propose Contract
						</button>
					</GlobalTooltip>
				);
			}

			if (
				isClient &&
				!contract_id &&
				(isInterviewDone || hasInterviewExpired)
			) {
				_buttons.push(
					<button
						key="invite-to-interview"
						icon="icon-calendar2"
						className={getClassname(_buttons)}
						onClick={() => handleActions(INVITE_INTERVIEW)}
					>
						Propose interview
					</button>
				);
			}

			if (
				(isFreelancer && contractSent) ||
				(!isContractLastId && contractDeclined)
			) {
				_buttons.push(
					<button
						key="accept-mission"
						className={getClassname(_buttons)}
						onClick={() => handleActions(ACCEPT_MISSION)}
					>
						Accept Contract
					</button>
				);

				_buttons.push(
					<button
						key="propose-other-terms"
						className={getClassname(_buttons)}
						onClick={() => handleActions(PROPOSE_ANOTHER_TERM)}
					>
						Propose other terms
					</button>
				);
			}

			if (!contractAccepted) {
				if (contract_id && isClient && userRole !== ADMIN) return _buttons;
				_buttons.push(
					<button
						className={getClassname(_buttons, !isBidLive)}
						key="cancel_application"
						icon="icon-close"
						onClick={() =>
							handleActions(
								isClient ? REJECT_APPLICATION : CANCEL_APPLICATION,
								!isBidLive
							)
						}
					>
						{getNegativeButtonText({
							isClient,
							interview_id,
							contract_id,
							interview_status,
							contract_status,
							isInterviewLastId,
							isContractLastId
						})}
					</button>
				);
			}
		}

		if (contractAccepted && !contractSigned) {
			_buttons.push(
				<button
					key="contact-support"
					className={getClassname(_buttons)}
					onClick={() => handleActions(SIGN_CONTRACT)}
				>
					Start mission
				</button>
			);
		}

		if (contractSigned) {
			_buttons.push(
				<Link
					key="view-missions"
					className={getClassname(_buttons)}
					to={"/missions/" + contract_id}
				>
					View mission
				</Link>
			);
		}
		if (isClient) {
			_buttons.push(
				<button
					key="add-note"
					className={getClassname(_buttons)}
					onClick={() => setDisplayAddNoteModal(true)}
					icon="icon-add-note"
				>
					Add note
				</button>
			);
		}

		return _buttons;
	};

	const isFreelancer = userType === FREELANCER;
	const onCloseSignContactModal = () => setSignContractModal(false);
	const signContractAction = () =>
		onSignContract({ contract_id: contract._id });
	const actions = getActions();

	if (displayOnlyActions) {
		return (
			<div className="section">
				{actions.length > 0 && (
					<div className="actions">
						{actions.map((action, index) => index < 2 && action)}
						{actions.length > 2 && (
							<SideBarDropdown
								actions={actions}
								handleActions={handleActions}
							/>
						)}
					</div>
				)}
			</div>
		);
	}

	return (
		<div>
			<div className="section">
				<Box
					p={20}
					mb={10}
					sx={{
						borderRadius: "4px",
						boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
						backgroundColor: "rgb(255, 255, 255)"
					}}
				>
					{actions.length > 0 && (
						<Box className="actions">
							{actions.map((action, index) => index < 2 && action)}
							{actions.length > 2 && (
								<SideBarDropdown
									actions={actions}
									handleActions={handleActions}
								/>
							)}
						</Box>
					)}
				</Box>
				<Box
					p={20}
					sx={{
						borderRadius: "4px",
						boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
						backgroundColor: "rgb(255, 255, 255)"
					}}
				>
					{isFreelancer && (
						<>
							<Flex mb={20} justifyContent="space-between" alignItems="center">
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "12px",
										fontWeight: "600",
										letterSpacing: "1.08px",
										color: "rgb(160, 165, 185)",
										textTransform: "uppercase"
									}}
								>
									Company Name
								</Text>
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "14px",
										fontWeight: "500",
										color: "rgb(15, 28, 70)"
									}}
								>
									{_get(company, "name", "")}
								</Text>
							</Flex>
							<Flex mb={20} justifyContent="space-between" alignItems="center">
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "12px",
										fontWeight: "600",
										letterSpacing: "1.08px",
										color: "rgb(160, 165, 185)",
										textTransform: "uppercase"
									}}
								>
									publish date
								</Text>
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "14px",
										fontWeight: "500",
										color: "rgb(15, 28, 70)"
									}}
								>
									{formatDate(publish_date, true, DATE_FORMAT_LLLL)}
								</Text>
							</Flex>
						</>
					)}
					<Flex mb={20} justifyContent="space-between" alignItems="center">
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "12px",
								fontWeight: "600",
								letterSpacing: "1.08px",
								color: "rgb(160, 165, 185)",
								textTransform: "uppercase"
							}}
						>
							{isFreelancer ? "Start - End date" : "Expected date"}
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "14px",
								fontWeight: "500",
								color: "rgb(15, 28, 70)"
							}}
						>
							{formatDate(start_date)} - {formatDate(end_date)}
						</Text>
					</Flex>
					<Flex mb={20} justifyContent="space-between" alignItems="center">
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "12px",
								fontWeight: "600",
								letterSpacing: "1.08px",
								color: "rgb(160, 165, 185)",
								textTransform: "uppercase"
							}}
						>
							Time commitment
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "14px",
								fontWeight: "500",
								color: "rgb(15, 28, 70)"
							}}
						>
							{full_time ? FULL_TIME_LABEL : PART_TIME_LABEL}
						</Text>
					</Flex>
					{!isFreelancer && (
						<Flex mb={20} justifyContent="space-between" alignItems="center">
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "12px",
									fontWeight: "600",
									letterSpacing: "1.08px",
									color: "rgb(160, 165, 185)",
									textTransform: "uppercase"
								}}
							>
								Expected rate
							</Text>
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "14px",
									fontWeight: "500",
									color: "rgb(15, 28, 70)"
								}}
							>
								<CurrencyFormatterRender
									isCent
									value={budget}
									suffix={
										CURRENCY_SUFFIX[_get(reportingSetting, "rate_type", HOURLY)]
									}
									currency={_get(reportingSetting, "currency.code")}
								/>
							</Text>
						</Flex>
					)}
				</Box>
			</div>
			{displayAddNoteModal && (
				<PokeNoteModal
					note={[]}
					onClose={() => setDisplayAddNoteModal(false)}
					bidId={bidId}
					usersList={[]}
					isBid={true}
				/>
			)}
			{signContractModal && (
				<ConfirmationModal
					key="accepte_contract"
					onClose={onCloseSignContactModal}
					active={signContractModal}
					loading={isLoading}
					modalName="accept_contract_modal"
					type={ConfirmationTypes.confirmation}
					title="Are you sure you want to start this mission?"
					firstButton={{
						action: signContractAction,
						label: "Yes, start",
						type: "success"
					}}
					secondButton={{
						action: onCloseSignContactModal,
						label: "No"
					}}
				/>
			)}
		</div>
	);
};

BidSideBar.propTypes = {
	style: PropTypes.object,
	isSticky: PropTypes.bool,
	job: PropTypes.object.isRequired,
	userType: PropTypes.string.isRequired
};

export default BidSideBar;
