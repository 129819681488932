import React, { Component } from "react";
import { Element } from "react-scroll";
import _get from "lodash/get";
import GlobalTooltip from "./GlobalTooltip";
import TimePicker from "common/TimePicker";
import { getMaxIndicatorClassName } from "config";

class FormField extends Component {
	componentDidMount() {
		const { defaultValue, input } = this.props;
		if (defaultValue && !input.value) {
			input.onChange(defaultValue);
		}
	}

	componentDidUpdate({ input: { value, onChange }, maxLength }) {
		const {
			input: { value: newValue },
			defaultValue
		} = this.props;
		if (value && value !== newValue) {
			//extract the maxLength for value if exceeded
			if (maxLength && newValue.length > maxLength) {
				onChange(newValue.substring(0, maxLength));
			}
			//setting default value
			if (defaultValue && !newValue) {
				onChange(defaultValue);
			}
		}
	}

	render() {
		const {
			label,
			labelWithAsterisk = false,
			placeholder,
			maxLength,
			type,
			classes = "",
			disabled,
			inputClasses = "",
			inputContainerClasses = "",
			icon,
			svgPrefixIcon,
			svgSuffixIcon,
			id,
			min,
			max,
			children,
			readOnly = false,
			toolTipOverlay,
			input,
			input: { name, onChange, onBlur, onFocus },
			meta: { touched, error },
			hasError = false,
			displayHelpBlock = true,
			errorComponent = null,
			tabIndex = 0,
			showMaxCharacters = true,
			checked,
			noError = false,
			className = "",
			autoComplete = "on",
			defaultValue,
			clearable = false,
			onClear,
			onClick
		} = this.props;

		const value = _get(this, "props.input.value", "") ?? "";

		const _hasError = hasError || (touched && error);

		const has_error = _hasError ? "has-error has-feedback" : "";

		// prevent key press if the value's length has exceeded the input's maxLength
		const inputKeypressed = event =>
			maxLength && value.length >= maxLength ? event.preventDefault() : event;

		if (type === "timepicker") {
			return (
				<Element
					className={`form-group ${has_error} ${classes}`}
					name={name}
					scrollto="scroll-to-error"
				>
					{label && (
						<label htmlFor={name}>
							{label}{" "}
							{!!labelWithAsterisk && <span className="asterisk">*</span>}
						</label>
					)}
					<div className="position-input-box">
						<div className={`input-box ${icon ? "withIcon" : ""}`}>
							{icon && <i className={icon} />}
							<TimePicker
								name="hours"
								disabled={disabled}
								placeholder={placeholder}
								onChange={onChange}
								tabIndex={tabIndex}
								value={value || defaultValue}
								className={className}
							/>
						</div>
						<div className="help-block inline-error">
							{touched && !hasError && error}
						</div>
					</div>
				</Element>
			);
		}

		if (type === "textarea") {
			return (
				<Element
					className={`form-group ${has_error} ${classes}`}
					name={name}
					scrollto="scroll-to-error"
				>
					{label && (
						<label htmlFor={name}>
							{label}{" "}
							{!!labelWithAsterisk && <span className="asterisk">*</span>}
							{toolTipOverlay && (
								<GlobalTooltip maxWidth="475px" overlay={toolTipOverlay} />
							)}
						</label>
					)}
					<div className="position-input-box">
						<div className="input-box">
							{icon && <i className={icon} />}
							<textarea
								readOnly={readOnly}
								className={`form-control ${className}`}
								id={name}
								{...input}
								placeholder={placeholder}
								tabIndex={tabIndex}
								name={name}
								value={value}
								onKeyPress={inputKeypressed}
								disabled={disabled}
								autoComplete={autoComplete}
							/>
							{maxLength && (
								<p
									className={`input-maxlength ${getMaxIndicatorClassName(
										value.trim().length,
										maxLength
									)}`}
								>
									{value.length}/{maxLength}
								</p>
							)}
							{displayHelpBlock && (
								<div className="help-block inline-error">
									{touched && error}
								</div>
							)}
							{hasError && errorComponent}
						</div>
					</div>
				</Element>
			);
		}

		if (type === "radio") {
			return (
				<Element
					className={`form-group ${classes} ${has_error}`}
					name={name}
					scrollto="scroll-to-error"
				>
					<div className="position-input-box">
						<div className="input-box">
							<input
								disabled={disabled}
								tabIndex={tabIndex}
								className={className}
								id={id}
								{...input}
								type={type}
								name={name}
								autoComplete={autoComplete}
							/>
							{label && <label htmlFor={id}>{label}</label>}
							{children && <label htmlFor={id}>{children}</label>}
						</div>
					</div>
					<div className="help-block inline-error">{touched && error}</div>
				</Element>
			);
		}

		if (type === "checkbox") {
			return (
				<Element
					className={`form-group ${has_error} ${classes}`}
					name={name}
					scrollto="scroll-to-error"
				>
					{label && (
						<label style={{ marginBottom: "20px" }} htmlFor={name}>
							{label}{" "}
							{!!labelWithAsterisk && <span className="asterisk">*</span>}
							{toolTipOverlay && (
								<GlobalTooltip maxWidth="475px" overlay={toolTipOverlay} />
							)}
						</label>
					)}
					<div className="position-input-box">
						<div className={`input-box ${inputContainerClasses}`}>
							<input
								{...input}
								type="checkbox"
								checked={checked}
								onClick={onClick}
								onChange={onClick}
								className={`styled ${className}`}
								tabIndex={tabIndex}
								disabled={disabled}
								autoComplete={autoComplete}
								id={id}
							/>
							<label htmlFor={id}>{placeholder}</label>
							{!noError && (
								<div className="help-block inline-error">
									{touched && error}
								</div>
							)}
						</div>
					</div>
				</Element>
			);
		}

		return (
			<Element
				className={`form-group ${has_error} ${classes}`}
				name={name}
				scrollto="scroll-to-error"
			>
				{label && (
					<label htmlFor={name}>
						{label} {!!labelWithAsterisk && <span className="asterisk">*</span>}
						{toolTipOverlay && (
							<GlobalTooltip maxWidth="475px" overlay={toolTipOverlay} />
						)}
					</label>
				)}
				<div className="position-input-box">
					<div className="input-box">
						{!!icon && <i className={icon} />}
						{!!svgPrefixIcon && svgPrefixIcon}
						<input
							disabled={disabled}
							tabIndex={tabIndex}
							readOnly={readOnly}
							className={`form-control ${inputClasses} ${className}`}
							id={name}
							placeholder={placeholder}
							type={type}
							min={min}
							max={max}
							onChange={onChange}
							onBlur={onBlur}
							onFocus={onFocus}
							value={value}
							onKeyPress={inputKeypressed}
							autoComplete={autoComplete}
						/>
						{!!svgSuffixIcon && svgSuffixIcon}
						{clearable && value.trim().length > 0 && (
							<span className="icon-reset-3" onClick={onClear}></span>
						)}
						{maxLength && showMaxCharacters && (
							<p
								className={`input-maxlength ${getMaxIndicatorClassName(
									value.length,
									maxLength
								)}`}
							>
								{value.length}/{maxLength}
							</p>
						)}
						{displayHelpBlock && (
							<div className="help-block inline-error">
								{touched && !hasError && error}
							</div>
						)}
						{hasError && errorComponent}
					</div>
				</div>
			</Element>
		);
	}
}

export default FormField;
