import React from "react";
import _get from "lodash/get";
import QS from "query-string";
import { FREELANCER, FUNCTIONS, SKILLS } from "config";
import TopSection from "./TopSection";
import Tabs from "./Tabs/Tabs";
import Preferences from "./Tabs/Preferences";
import SideBar from "./sidebar/SideBar";
import Languages from "./Tabs/Languages";
import Functions from "modules/job/components/jobprofile/Functions/Functions";
import BidActions from "./BidActions";
import FreelancerModals from "./modals/FreelancerModals";
import Skills from "./Tabs/FreelancerSkills";
import FreelancerCV from "./Tabs/FreelancerCV";
import HistoryBid from "modules/bids/components/bidview/History";
import FreelancerProfileClientView from "./FreelancerProfileClientView";

const FreelancerProfile = ({
	bid_id,
	_ownCV,
	headline,
	functions,
	activeTab,
	isClient,
	showBack,
	viewProfile,
	cv_docs,
	showModal,
	user,
	status,
	initialSkills,
	id,
	history,
	searchQuery
}) => {
	const cv = _get(cv_docs, "original_cv");
	const cvDoc = _get(cv_docs, "cv_doc");
	const list = QS.parse(window.location.search)?.list;
	return (
		<>
			{_ownCV ? (
				<>
					<TopSection
						showBack={showBack}
						bid_id={bid_id}
						ownCV={_ownCV}
						viewProfile={viewProfile}
						userId={headline && headline._id}
						isFreelancer={true}
					/>
					<div className={` ${_ownCV ? "container" : "client-layout-padding"}`}>
						<div className="col-md-9">
							<Tabs
								functions={functions}
								ownCV={_ownCV}
								profileCompanyType={FREELANCER}
							/>
						</div>
						<div className="row">
							<div className="col-md-9 update">
								{activeTab === 0 && (
									<div
										className="tab-content"
										style={{
											backgroundColor: "#fff",
											border: "1px solid #e1e4e7",
											borderRadius: "4px",
											boxShadow: "0px 2px 1px 0 rgba(197,199,206,0.25)"
										}}
									>
										<FreelancerCV
											cv={cv}
											id={id}
											canUpdateCV={_ownCV}
											cvDoc={cvDoc}
										/>
									</div>
								)}
								{activeTab === 1 && (
									<div className="tab-content">
										<Functions
											functions={functions}
											showModal={() => showModal(FUNCTIONS)}
											canUpdateFunctions={_ownCV}
											isFreelancer
										/>
										<Skills
											skills={initialSkills}
											functionsLength={_get(functions, "length", 0)}
											showModal={() => showModal(SKILLS)}
											ownCV={_ownCV}
										/>
										<Languages
											ownCV={_ownCV}
											isClient={isClient}
											isFreelancer
										/>
									</div>
								)}
								{activeTab === 2 && (
									<div className="tab-content">
										<Preferences ownCV={_ownCV} />
									</div>
								)}
								{activeTab === 3 && (
									<div className="tab-content">
										<HistoryBid history={history} />
									</div>
								)}
							</div>
							{list !== "my-freelancers" && searchQuery !== "search" && (
								<div className="col-md-3 update profile-sidebar">
									{isClient && <BidActions user={user} bidId={bid_id} />}
									<SideBar bidId={bid_id} ownCV={_ownCV} />
								</div>
							)}
						</div>
					</div>
					<FreelancerModals
						bid_id={bid_id}
						newUserId={headline && headline._id}
						userStatus={status}
						functions={functions}
						profileSector={_get(headline, "sector.parent_sector")}
					/>
				</>
			) : (
				<FreelancerProfileClientView
					bid_id={bid_id}
					_ownCV={_ownCV}
					headline={headline}
					functions={functions}
					activeTab={activeTab}
					isClient={isClient}
					showBack={showBack}
					viewProfile={viewProfile}
					cv_docs={cv_docs}
					showModal={showModal}
					user={user}
					status={status}
					initialSkills={initialSkills}
					id={id}
					history={history}
					searchQuery={searchQuery}
				/>
			)}
		</>
	);
};

export default FreelancerProfile;
