import React from "react";
import TourPopover from "modules/user-onboarding/components/TourPopover";
import { ReactComponent as ChevronRightIcon } from "static/icons/chevron-right-icon.svg";
import {
	ONBOARDING_COMMON_STYLES,
	TOUR_EMPLOYER_BRANDING
} from "../../../../config/onboarding";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";
import { browserHistory } from "react-router";

// customize popover component
function StepPopover(props) {
	const {
		record: { title, stepsCount, description, arrowPosition },
		events: { onNext }
	} = props;

	return (
		<TourPopover position={arrowPosition}>
			<TourPopover.Header>{title}</TourPopover.Header>
			<TourPopover.Body>{description}</TourPopover.Body>
			<TourPopover.Footer>
				{!!props.record?.step && (
					<TourPopover.Step>
						{props.record.step}/{stepsCount}
					</TourPopover.Step>
				)}
				<TourPopover.ButtonsWrapper>
					{!!props.events?.onSkip && (
						<TourPopover.Button onClick={props.events.onSkip} variant="link">
							Skip
						</TourPopover.Button>
					)}
					<TourPopover.Button
						onClick={onNext}
						variant={props.record?.nextButtonVariant || "link"}
					>
						{props.record?.nextButtonText || (
							<>
								Next step
								<ChevronRightIcon />
							</>
						)}
					</TourPopover.Button>
				</TourPopover.ButtonsWrapper>
			</TourPopover.Footer>
		</TourPopover>
	);
}

export const SELECTORS_TOUR_EMPLOYER_BRANDING = [
	`${TOUR_EMPLOYER_BRANDING}__0`,
	`${TOUR_EMPLOYER_BRANDING}__1`,
	`${TOUR_EMPLOYER_BRANDING}__2`,
	`${TOUR_EMPLOYER_BRANDING}__3`
];

// steps
export const STEPS_TOUR_EMPLOYER_BRANDING = [
	{
		selector: `#${SELECTORS_TOUR_EMPLOYER_BRANDING[0]}`,
		position: p => [p.left + 145, 300],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					arrowPosition: "left-top",
					title: "Social media picture",
					description:
						"Elevate your online presence effortlessly! Update your social media publication cover with just a click and make your hiring post shine."
				}}
				events={{ onNext }}
			/>
		)
	},
	{
		stepInteraction: false,
		selector: `#${SELECTORS_TOUR_EMPLOYER_BRANDING[1]}`,
		position: p => [p.left + 60, p.bottom + 20],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					arrowPosition: "top-left",
					title: "Activate & deactivate Page",
					description:
						"Control the visibility of your career page with the click of a button. Simply toggle between 'Online' and 'Offline' status to best suit your needs."
				}}
				events={{ onNext }}
			/>
		)
	},
	{
		selector: `#${SELECTORS_TOUR_EMPLOYER_BRANDING[2]}`,
		position: p => [p.left + 165, p.bottom - 20],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					arrowPosition: "left-top",
					title: "Edit career page template",
					description:
						"Customize your career page template in a snap! Click 'Open Editor' to make changes and boost your brand image."
				}}
				events={{ onNext }}
			/>
		)
	},
	//
	{
		selector: `#${SELECTORS_TOUR_EMPLOYER_BRANDING[3]}`,
		position: p => [p.left + 10, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					nextButtonText: "Got it!",
					nextButtonVariant: "default",
					arrowPosition: "top-left",
					title: "Customise career page link",
					description:
						"Customize your career page link by clicking 'Edit button, and enhance your company's online presence in just one click!"
				}}
				events={{ onNext }}
			/>
		)
	}
];

// handle rendering of each step
function ContentComponent(props) {
	const content = props.steps[props.currentStep].content;
	const isLastStep = props.currentStep === props.steps.length - 1;

	// onboarding store
	const {
		metaData,
		cleanStore,
		setMetaData,
		setIsLoading
	} = useOnboardingStore();

	// last step close tour
	const onTourClose = () => {
		// props.setSteps([]); // todo have to clean steps
		if (metaData?.showStartRecrutmentModal) {
			setMetaData({ ...metaData, showModal: true });
		} else {
			cleanStore(); // clean onboarding store
			browserHistory.push("/settings/setup-guide");
		}
	};

	// persist onboarding user settings
	const { persistData } = useOnboardingSaveData({ onTourClose });

	const onNext = () => {
		if (isLastStep) {
			props.setIsOpen(false); // close tour
			setIsLoading(true);
			persistData();
			return;
		}

		props.setCurrentStep(s => s + 1);
	};

	if (typeof content === "function") {
		return content({
			onNext,
			onSkip: onTourClose,
			step: props.currentStep + 1,
			stepsCount: props.steps.length
		});
	}

	return content;
}

// company tour config
export default function useTourEmployerBrandingConfig() {
	return {
		ContentComponent,
		onClickMask: () => null,
		styles: ONBOARDING_COMMON_STYLES,
		disableKeyboardNavigation: true
	};
}
