//TODO Prop overflow hidden is not clear
import cx from "classnames";
import React, { useEffect, useRef, useState, cloneElement } from "react";
import { useEvent } from "react-use";
import Tippy from "@tippyjs/react";
// css
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/translucent.css";
import "tippy.js/animations/shift-away.css";
import styles from "./tippy.module.scss";

const themeMap = { light: "light-border", dark: "translucent" };

// To change Arrow color add this code to your Css file

// .your-classname div::before {
// 	border-bottom-color: #000 !important;
// }

/**
 * @param children
 * @param content {JSX.Element|*}
 * @param overflow {'visible'|'hidden'}
 * @param theme {'light'|'dark'}
 * @param placement
 * @returns {JSX.Element|*}
 */
function Tooltip({
	children,
	content,
	overflow = "visible",
	theme = "light",
	placement = "top",
	visible,
	enableAnimation = true,
	addTooltip = null,
	className,
	disabled = false,
	maxWidth,
	render
}) {
	const [showTooltip, setShowTooltip] = useState(overflow === "visible");

	const ref = useRef();

	let tooltipVisibilityHandler = () => {
		if (overflow !== "visible") {
			setShowTooltip(ref?.current?.offsetWidth < ref?.current?.scrollWidth);
		}
	};

	useEffect(() => {
		tooltipVisibilityHandler();
	}, [content]);

	useEffect(() => {
		if (addTooltip === true || addTooltip === false) {
			setShowTooltip(addTooltip);
		}
	}, [addTooltip]);

	useEvent("resize", tooltipVisibilityHandler);

	let childrenUpdated =
		addTooltip === true || addTooltip === false
			? children
			: cloneElement(children, { ref });

	return showTooltip ? (
		<Tippy
			animation={enableAnimation ? "shift-away" : ""}
			placement={placement}
			theme={themeMap[theme]}
			content={<span>{content}</span>}
			className={cx(styles.tippy, className)}
			visible={visible}
			disabled={disabled}
			maxWidth={maxWidth}
			render={render}
		>
			{childrenUpdated}
		</Tippy>
	) : (
		childrenUpdated
	);
}

export default Tooltip;
