import create from "zustand";
const initialState = {
	suppliersType: "recentSuppliers",
	suppliersStartDate: undefined,
	suppliersEndDate: undefined,
	recentSuppliers: [],
	mySuppliers: [],
	newFreelancer: [],
	isSettedDate: false
};
const useSuppliersStore = create(set => ({
	...initialState,
	setItemSuppliers: (key, value) => set({ [key]: value }),
	reset: () => set({ ...initialState })
}));

export default useSuppliersStore;
