import React, { useState } from "react";
import _get from "lodash/get";
import { formatDate } from "common/Functions";
import HandshakeIcon from "common/Icons/HandshakeIcon";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Pagination from "common/Pagination";
import {
	POKE,
	SHORTLIST,
	INTERNAL_NOTE,
	USER_HIREME_NAME,
	APPLICATION
} from "config";

import {
	HistoryContainer,
	EmptyHistory,
	Table,
	LinkButton,
	RedirectButton
} from "./ProfileStyle";
import NotesModal from "./Modals/ViewNotesModal";
import { client } from "lib/api-client";
import { VIEW_USER_HISTORY } from "config/api-endpoints";
import { historyPush } from "config/helpers";
import { useQuery } from "react-query";
import { PaginationContainer } from "modules/candidate/components/profileClientView/profileModalTab/components/notes/styles";
import { HISTORY_TYPE_LABEL } from "./profileClientView/profileModalTab/components/interactions/constants";

const getHistory = (_, { profileId, offset }) =>
	client(VIEW_USER_HISTORY, {
		body: {
			id: profileId,
			offset
		}
	});

export default function History({ profileId }) {
	const [viewNoteData, setViewNoteData] = useState(undefined);

	const [offset, setOffset] = useState(0);

	const { data, isLoading } = useQuery(
		["interactions", { profileId, offset }],
		getHistory,

		{
			refetchOnWindowFocus: false,
			enabled: profileId
		}
	);

	const size = _get(data, "pagination.size", 0);
	const total = _get(data, "pagination.total", 0);

	const dataSkeleton = new Array(8).fill(
		<tr>
			<td style={{ width: "15%" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={60} height={14} />
				</SkeletonTheme>
			</td>
			<td style={{ width: "19%" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={60} height={14} />
				</SkeletonTheme>
			</td>
			<td style={{ width: "17%" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={60} height={14} />
				</SkeletonTheme>
			</td>
			<td style={{ width: "30%", wordBreak: "break-word" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={60} height={14} />
				</SkeletonTheme>
			</td>
		</tr>
	);

	const handleViewNote = item => {
		switch (item.history_type) {
			case POKE:
			case APPLICATION:
				setViewNoteData(item.client_notes);
				break;
			default:
				setViewNoteData([
					{
						_id: item._id,
						user: {
							first_name: _get(item, "user_action.first_name"),
							last_name: _get(item, "user_action.last_name"),
							isSuperAdmin: _get(item, "user_action.is_super_admin")
						},
						created_at: item.created_at,
						description: item.note_data
					}
				]);
				break;
		}
	};

	const handleRedirectVacancy = item => {
		const vacancyId = _get(item, "job_id");
		historyPush(`/vacancy/${vacancyId}`);
	};
	let history = _get(data, "data", []);
	return (
		<HistoryContainer>
			{isLoading || history.length > 0 ? (
				<>
					<div className="has-been-poked">
						You’ll find below the details of the previous interactions.
					</div>
					<Table>
						<thead>
							<tr>
								<td>DATE</td>
								<td>USER</td>
								<td>TYPE</td>
								<td>FOR THE VACANCY</td>
								<td />
							</tr>
						</thead>
						<tbody>
							{isLoading
								? dataSkeleton
								: history.map(item => {
										const superAdmin =
											_get(item, "user_action.is_super_admin") === true;
										const notesCounter =
											item.history_type === INTERNAL_NOTE ||
											item.history_type === SHORTLIST
												? _get(item, "note_data.length", 0) > 0
													? 1
													: 0
												: _get(item, "number_notes", 0);

										const userName = `${_get(
											item,
											"user_action.first_name",
											""
										)} ${_get(item, "user_action.last_name", "")}`;

										return (
											<tr key={item._id}>
												<td style={{ width: "15%" }}>
													{formatDate(item.created_at)}
												</td>
												<td style={{ width: "19%" }}>
													{!superAdmin
														? userName != " "
															? userName
															: _get(item, "user_action", "")
														: USER_HIREME_NAME}
												</td>
												<td style={{ width: "17%" }}>
													{HISTORY_TYPE_LABEL[item.history_type]}
												</td>
												<td style={{ width: "30%", wordBreak: "break-word" }}>
													{item.history_type === "internal_note" ? (
														"NA"
													) : (
														<RedirectButton
															onClick={() => handleRedirectVacancy(item)}
														>
															{`${item.job.title}`}
														</RedirectButton>
													)}
												</td>
												<td style={{ width: "19%" }}>
													{notesCounter > 0 && (
														<LinkButton onClick={() => handleViewNote(item)}>
															{`view notes (${notesCounter})`}
														</LinkButton>
													)}
												</td>
											</tr>
										);
								  })}
						</tbody>
					</Table>
					{viewNoteData && (
						<NotesModal
							notes={viewNoteData}
							onClose={() => setViewNoteData(undefined)}
						/>
					)}
				</>
			) : (
				<EmptyHistory>
					<HandshakeIcon />
					<p>
						This section will list all previous interactions between this
						candidate and any of your colleagues. <br />
						In this case, the candidate has not yet been poked by someone from
						your company.
					</p>
				</EmptyHistory>
			)}
			{total > size && (
				<PaginationContainer>
					<Pagination
						total={total}
						size={size}
						handlePageClick={page => {
							setOffset(page * size);
						}}
						offset={offset}
						forcePage={Math.ceil(offset / size)}
					/>
				</PaginationContainer>
			)}
		</HistoryContainer>
	);
}
