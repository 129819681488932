import React, { memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import PropTypes from "prop-types";

import { stopJobModal, stopJob } from "modules/job/actions";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { VACANCY, MARK_AS_FILLED } from "config";

function StopVacancy({ job: { id }, onSuccess, stopJob, stopJobModal }) {
	const stopped = () => {
		const payload = { id };

		stopJob(payload, MARK_AS_FILLED, VACANCY).then(
			response => response && onSuccess(response)
		);
	};

	return (
		<>
			<ConfirmationModal
				modalName="stop_job"
				onClose={() => stopJobModal()}
				type={ConfirmationTypes.error}
				title={"Are you sure you would like to mark this vacancy as filled?"}
				firstButton={{
					action: stopped,
					label: "YES, MARK AS FILLED.",
					type: "warning"
				}}
			/>
		</>
	);
}

StopVacancy.propTypes = {
	onSuccess: PropTypes.func
};

StopVacancy.defaultProps = {
	onSuccess: () => {}
};

const mapStateToProps = state => {
	return {
		job: state.job.stopJob
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			stopJobModal,
			stopJob
		},
		dispatch
	);
};

export default compose(
	memo,
	connect(mapStateToProps, mapDispatchToProps)
)(StopVacancy);
