import { createSelector } from "reselect";
import _get from "lodash/get";
import _find from "lodash/find";

const BusinessUnitManagersList = state =>
	_get(state, "consultancy.businessUnitManagersList");
const getBUList = state => _get(state, "consultancy.BUList");
const getActiveModal = state => _get(state, "consultancy.activeModal");
const getCurrentBU = state => _get(state, "consultancy.buid");

export const BusinessUnitManagersListSelector = createSelector(
	BusinessUnitManagersList,
	businessUnitManagersList => businessUnitManagersList
);
export const BUListSelector = createSelector(getBUList, BUList => BUList);
export const activeModalSelector = createSelector(
	getActiveModal,
	activeModal => activeModal
);
export const currentBUSelector = createSelector(
	getCurrentBU,
	getBUList,
	(buid, bu_list) => {
		if (!buid) {
			return undefined;
		}
		return _find(bu_list.data, { _id: buid });
	}
);
