import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_TAXONOMIES } from "config/api-endpoints";

const getTaxonomies = (key, body) =>
	client(GET_TAXONOMIES, {
		body
	});

export const getTaxonomiesQueryKey = "@publish-vacancy/getTaxonomies";

const useGetTaxonomies = (options, queryOptions = {}) =>
	useQuery([getTaxonomiesQueryKey, options], getTaxonomies, {
		refetchOnWindowFocus: false,
		retry: 0,
		enabled: options.enabled,
		...queryOptions
	});

export default useGetTaxonomies;
