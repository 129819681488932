import React, { useState, memo } from "react";
import loadable from "loadable-components";
import FilterPill from "common/FilterPill/FilterPill.js";
import styles from "./styles.module.scss";
import get from "lodash/get";

import { ReactComponent as ClearIcon } from "static/icons/clear_icon.svg";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { isEmpty } from "common/FilterDrawer/render_utils";

const ClearModal = loadable(() => import("../ClearModal/ClearModal"));

const MissionsListFiltersRecap = ({ filters, module_id }) => {
	const [showClearModal, setShowClearModal] = useState(false);

	const { handleFilterDelete, setShowDrawer, setSelectedFilter } = useFilters();

	const deleteFilterHandler = id => {
		handleFilterDelete(id);
	};

	return (
		<>
			<div className={styles.filters_bar}>
				<div
					className={styles.scrollContainer}
					onClick={() => setShowDrawer(true)}
				>
					<OverlayScrollbarsComponent style={{ maxWidth: "100%" }}>
						{filters?.map((filter, index) => {
							if (!isEmpty(filter.value)) {
								return (
									<div key={index} className={styles.pill_container}>
										<FilterPill
											module_id={module_id}
											filter={filter}
											removable
											onRemove={deleteFilterHandler}
											onClick={() => {
												setSelectedFilter(get(filter, "id", ""));
												setShowDrawer(true);
											}}
										/>
									</div>
								);
							}
						})}
					</OverlayScrollbarsComponent>
				</div>

				<div className={styles.btn_container}>
					<div className={styles.rightContainer}>
						<HeaderButton
							icon={<ClearIcon />}
							text={"Clear"}
							className="buttonClear"
							onClick={() => setShowClearModal(true)}
						/>
					</div>
				</div>
			</div>

			{showClearModal && (
				<ClearModal onClose={() => setShowClearModal(false)} />
			)}
		</>
	);
};

export default memo(MissionsListFiltersRecap);
