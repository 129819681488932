import React from "react";
import Tooltip from "rc-tooltip";
import get from "lodash/get";
import {
	FULL_TIME_LABEL,
	PART_TIME_LABEL,
	TITLE_MAX,
	NO_DATA_PLACEHOLDER,
	CANCELLED,
	REFUSED,
	REJECTED,
	ACCEPTED,
	CONTRACT,
	DECLINED,
	SENT
} from "config";
import { formatDate } from "common/Functions";
import BidsAction from "./BidsAction";
import { Tr, Td } from "../../timesheets/components/TimesheetStyled";
import StatusLabel from "common/StatusLabel";

const VendorBidItem = ({
	bid,
	bid: { job, _id },
	openModal,
	viewDetails,
	isConsultancy = false
}) => {
	const _viewDetails = e => {
		if (!e.target.classList.contains("ignore-click")) {
			viewDetails(_id);
		}
	};

	if (!job) return null;
	const consultantName = `${get(bid, "user_first_name", "")} ${get(
		bid,
		"user_last_name",
		""
	)}`;
	const canDisplayNotif =
		bid.visible_status === "New Interview" ||
		bid.visible_status === "New Proposition";
	return (
		<Tr onClick={_viewDetails}>
			<Td padding={"12px 18px"}>{job.job_ref}</Td>
			{isConsultancy && <Td padding={"12px 18px"}>{consultantName}</Td>}
			<Td padding={"12px 18px"}>{get(job, "site.name", "")}</Td>
			<Td padding={"12px 18px"}>
				{job.title ? (
					<span>
						{job.title.length > TITLE_MAX ? (
							<Tooltip
								placement="top"
								mouseEnterDelay={0}
								mouseLeaveDelay={0}
								overlay={<div>{job.title}</div>}
							>
								<span>{`${job.title.substring(0, TITLE_MAX)}...`}</span>
							</Tooltip>
						) : (
							job.title
						)}
					</span>
				) : (
					NO_DATA_PLACEHOLDER
				)}
			</Td>
			<Td padding={"12px 18px"}>{`${formatDate(job.start_date)} - ${formatDate(
				job.end_date
			)}`}</Td>
			<Td padding={"12px 18px"}>
				<span className="time-commit">
					{job.full_time ? FULL_TIME_LABEL : PART_TIME_LABEL}
				</span>
			</Td>
			<Td padding={"12px 18px"}>
				<StatusLabel
					canDisplayNotif={canDisplayNotif}
					status={bid.status === DECLINED ? SENT : bid.status}
					label={bid.visible_status}
				/>
			</Td>
			{bid.status !== CANCELLED &&
			bid.status !== REFUSED &&
			bid.status !== REJECTED &&
			(bid.status !== ACCEPTED || bid.stepper_status !== CONTRACT) ? (
				<BidsAction bid_id={bid._id} openModal={openModal}>
					<Td padding={"12px 18px"} onClick={e => e.stopPropagation()}>
						<div className="dots ignore-click">
							<div className="dot" />
							<div className="dot" />
							<div className="dot" />
						</div>
					</Td>
				</BidsAction>
			) : (
				<Td padding={"12px 18px"} />
			)}
		</Tr>
	);
};

export default VendorBidItem;
