import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import styles from "./page-cms.module.scss";
import EmployerBrandingBody from "./component/EmployerBrandingBody/EmployerBrandingBody";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import MultiSitesHeader from "modules/MyCompany/MultiSitesHeader";
import { useGetPagesSettings } from "./component/api/useGetPagesSettings";
import { useSelector } from "react-redux";
import { getUrlHash } from "config/helpers";

export const getPageCms = () => {
	return "/settings/pages-cms";
};

export const getPageCmsPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const PageCms = () => {
	const setItems = useBreadCrumbs(state => state.setItems);
	const [currentSite, setCurrentSite] = useState("");
	const [tabs, setTabs] = useState([]);
	const [hasMultiSite, setHasMultiSite] = useState(false);
	const [hasCareerPage, setHasCareerPage] = useState(false);
	const [hasJobPage, setHasJobPage] = useState(false);
	const [hasMissionPage, setHasMissionPage] = useState(false);
	const user = useSelector(onlineUserSelector);

	const { data, isLoading, refetch } = useGetPagesSettings();

	useEffect(() => {
		setItems([{ name: "Settings" }, { name: "Employer Branding" }]);
	}, []);

	useEffect(() => {
		const userFeatures = _get(user, "features", []);
		setHasMultiSite(userFeatures.includes("feature_multisite"));
		setHasCareerPage(userFeatures.includes("feature_cms_career_page"));
		setHasJobPage(userFeatures.includes("feature_cms_job_page"));
		setHasMissionPage(userFeatures.includes("feature_cms_mission_page"));
	}, [user]);

	useEffect(() => {
		const id = getUrlHash();

		if (hasMultiSite) {
			const defaultSite = _get(data, "sites", []).find(site =>
				_get(site, "is_default")
			);
			if (!id) {
				setCurrentSite(_get(defaultSite, "_id", ""));
			}
			setTabs(_get(data, "sites", []));
		} else {
			const defaultSite = _get(data, "sites", []).find(site =>
				_get(site, "is_default")
			);
			if (!id) {
				setCurrentSite(_get(defaultSite, "_id", ""));
			}
			setTabs([{ name: _get(data, "company_name", ""), is_default: true }]);
		}
	}, [data, hasMultiSite]);

	useEffect(() => {
		refetch();
	}, [hasMultiSite]);

	useEffect(() => {
		const id = getUrlHash();

		if (id) {
			setCurrentSite(id);
		}
	}, []);

	return (
		<div className={styles.employerBranding}>
			<h1 className={styles.title}>Employer Branding</h1>

			{hasMultiSite && (
				<MultiSitesHeader
					tabs={tabs}
					handleCurrentSiteChange={({ _id }) => {
						setCurrentSite(_id);
						history.pushState(null, null, `#${_id}`);
					}}
					displayAddSiteBtn={false}
					offset={-120}
					currentSite={currentSite}
				/>
			)}

			<div className={styles.body}>
				<EmployerBrandingBody
					site_id={currentSite}
					data={data}
					hasMultiSite={hasMultiSite}
					hasCareerPage={hasCareerPage}
					hasJobPage={hasJobPage}
					hasMissionPage={hasMissionPage}
					isLoadingProp={isLoading}
				/>
			</div>
		</div>
	);
};

export default PageCms;
