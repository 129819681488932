import { ButtonGroup } from "common/ButtonGroup";
import React from "react";
import { ReactComponent as ResetIcon } from "static/icons/reset-icon.svg";
import styles from "./range-date-picker-footer.module.scss";

const RangeDatePickerFooter = ({ onReset }) => {
	const buttons = [
		{
			text: "Reset",
			variant: "text",
			className: styles.button,
			icon: <ResetIcon />,
			onClick: onReset
		}
	];
	return (
		<div className={styles.container}>
			<ButtonGroup buttons={buttons} />
		</div>
	);
};

export default RangeDatePickerFooter;
