//TODO rename header components
import React, { useCallback, useEffect, useRef, useState } from "react";
import produce from "immer";
import { browserHistory } from "react-router";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import { useToggle } from "react-use";
import PageHeader from "modules/SearchCandidate/pageHeader/PageHeader";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import HeaderFilters from "modules/SearchCandidate/HeaderFilters/HeaderFilters";
import style from "modules/SearchCandidate/pageHeader/page-header.module.scss";
import CardsView from "../CardsView/CardsView";
import usePoolStore from "../../hooks/zustandPool";
import { CANDIDATE_POOL } from "config";
import Pagination from "common/NewPagination";
import useRowSelection from "hooks/Selection";
import { CARDS, QUERY, QUICK_VIEW } from "config";
import Checkbox from "common/Checkbox";
import TableView from "../TableView/TableView";
import useGetCandidateColumns from "common/AgTable/api/useGetCandidateColumns";
import FiltersBar from "common/SmartFilterDrawer/components/FiltersBar/FiltersBar";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import { useInitializeFilterDrawer } from "../../hooks/useInitializeFilterDrawer";
import ProfileCardDrawer from "modules/vacancy/components/newSearch/ProfileCardDrawer";
import { historyPush, searchParser } from "config/helpers";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import styles from "./search-candidate-container.module.scss";
import { useFetchProfile } from "modules/SearchCandidate/api/useFetchProfile";
import { viewTypes } from "common/SmartFilterDrawer/hooks/useFilter";
import { ReactComponent as PrevIcon } from "static/icons/chevron-left.svg";
import useGetCommunityCriteria from "modules/SearchCandidate/api/useGetCommunityCriteria";
import useViewUserNotes from "../../../candidate/Profile/api/useViewUserNotes";
import { SearchCandidateNotesDrawer } from "../SearchCandidateNotesDrawer";
import useFilterData from "modules/SearchCandidate/hooks/useFilterCandidates";
import useFilterWithFakeData from "modules/SearchCandidate/hooks/useFilterCandidatesWithFakeData";
import {
	CANDIDATES_MODULE_ID,
	CANDIDATES_FILTER,
	rowsPages
} from "../../utils/constants";
import CandidateActionsDrawer from "modules/vacancy/components/vacancyView/CandidateAction/DrawerComponent";
import get from "lodash/get";
import { getProfileSearchRoute } from "modules/SearchCandidate/ViewSwitcher";
import {
	isEmpty,
	validFiltersCount
} from "common/SmartFilterDrawer/utils/render_utils";
import { formatAllFilters } from "common/SmartFilterDrawer/utils/format_utils";
import { useUserHasSearchByKeyword } from "hooks/useUserHasSearchByKeyword";
import RowsPages from "common/PageSizeSelect/PageSizeSelect";
import { useSourceCandidatesTour } from "modules/user-onboarding/workflows/source-candidate";
import useShortList from "../ShortListDrawer/useShortList";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import {
	HOW_TO_SOURCE_AND_INVITE_CANDIDATE,
	HOW_TO_WORK_WITH_NOTES
} from "config/onboarding";
import SearchCandidateSmartQueryRecap from "../SearchCandidateSmartQueryRecap";
import { reverseSmartQueryParser } from "common/SmartFilterDrawer/utils/helpers";
import { AddCandidateDrawer } from "common/AddCandidateDrawer";
import { UPLOAD_ID } from "common/AddCandidateDrawer/utils/constants";
import useGetFiles from "common/AddCandidateDrawer/hooks/useGetFiles";
import useSetParcelCandidates from "modules/SearchCandidate/hooks/useSetParcelCandidates";

const NewFilterDrawer = React.lazy(() =>
	import("common/SmartFilterDrawer/SmartFilterDrawer")
);

const ShortListDrawer = React.lazy(() => import("../ShortListDrawer"));

const MAX_CANDIDATE = 500;
const TABLE_TAG = "search_candidates_table";

const SearchCandidateContainer = () => {
	const { data: tableSettings } = useGetCandidateColumns(TABLE_TAG);
	useInitializeFilterDrawer();
	const user = useSelector(onlineUserSelector);
	const [showRecap, setShowRecap] = useToggle(true);
	const {
		setShowDrawer,
		setShowNoteDrawer,
		setSelectedUserId,
		getState,
		setActiveView,
		setSelectedCriteria,
		setFilters,
		setOffset,
		setLimit,
		setDisplayKeywordCard,
		clearFilters,
		handleFilterDelete,
		setSelectedFilter,
		setSmartQuery,
		clearSmartQuery
	} = useFilters();
	const userHasSearchByKeyword = useUserHasSearchByKeyword({ user });
	const {
		filters,
		query,
		source,
		data,
		sortBy,
		offset,
		isLoading,
		isFetching,
		logicalOperator,
		limit: pageSize,
		selectedCriteria,
		columns,
		showDrawer,
		activeView,
		showNoteDrawer,
		selectedUserId,
		smartQuerySchema
	} = getState(CANDIDATES_FILTER);
	const [shortListIds, setShortListIds] = useState([]);
	const [, setDisplayLimitInfo] = useState(false);
	const [mode, setMode] = useState(null);
	const [include, setInclude] = useState([]);
	const [exclude, setExclude] = useState([]);
	const [isPrev, setIsPrev] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [vacancy_filters_data, setVacancy_filters_data] = useState();
	const { source: sourceQuery } = searchParser();
	const isFetchingCandidatesEnabled = useRef(
		sourceQuery !== "proactive_applications_list"
	);

	const { workflowId } = useOnboardingStore();
	const job_id = _get(
		browserHistory.getCurrentLocation(),
		"query.vacancy-id",
		""
	);
	const { data: criteriasArray } = useGetCommunityCriteria(
		{ job_id },
		{ enabled: !workflowId }
	);

	const { data: last_note } = useViewUserNotes(
		{
			id: selectedUserId,
			limit: 1
		},
		{ enabled: !workflowId }
	);

	useEffect(() => {
		if (job_id && _get(vacancy_filters_data, "[0]")) {
			const smartQuery = _get(
				vacancy_filters_data,
				"[0].smart_query_filter",
				null
			);
			const criteriaId = _get(vacancy_filters_data, "[0]._id");
			const criteriaName = _get(vacancy_filters_data, "[0].name");
			setSelectedCriteria({
				_id: criteriaId,
				name: criteriaName,
				type: "vacancy"
			});
			if (smartQuery) {
				formatAllFilters(columns, reverseSmartQueryParser(smartQuery)).then(
					results => {
						setSmartQuery(results);
					}
				);
			} else if (_get(vacancy_filters_data, "[0].fields", []).length > 0) {
				clearSmartQuery();
				setSelectedCriteria({
					_id: criteriaId,
					name: criteriaName,
					fields: _get(vacancy_filters_data, "[0].fields", []),
					type: "vacancy"
				});
				formatAllFilters(
					columns,
					_get(vacancy_filters_data, "[0].fields", [])
				).then(results => {
					setFilters(results);
				});
			}
		}
	}, [vacancy_filters_data]);
	useSetParcelCandidates();
	const { currentID, setCurrentId, setSelectData, select_data } = usePoolStore(
		state => ({
			currentID: state.currentID,
			setCurrentId: state.setCurrentId,
			setSelectData: state.setSelectData,
			select_data: state.select_data
		})
	);

	const limit =
		_get(data, "total") < MAX_CANDIDATE ? _get(data, "total") : MAX_CANDIDATE;

	const [
		selectionState,
		{
			toggleRowSelection,
			toggleAllSelection,
			checkRowSelection,
			getSelectionCount,
			togglePack,
			reset
		}
	] = useRowSelection({
		limit: limit
	});

	const selectionBucket = {
		state: selectionState,
		actions: {
			row: toggleRowSelection,
			all: toggleAllSelection,
			pack: togglePack
		},
		helpers: {
			checkRowSelection,
			getSelectionCount,
			reset
		}
	};

	const {
		selectedView,
		setIsNext,
		isNext,
		setView,

		...zustandActions
	} = usePoolStore();

	const isOnboardingActive = [
		HOW_TO_SOURCE_AND_INVITE_CANDIDATE,
		HOW_TO_WORK_WITH_NOTES
	].includes(workflowId);

	const realRefetch = useFilterData(job_id, {
		enabled: isFetchingCandidatesEnabled.current && !isOnboardingActive
	});
	const fakeRefetch = useFilterWithFakeData({ enabled: isOnboardingActive });
	const refetch = isOnboardingActive ? fakeRefetch : realRefetch;

	const updateFilter = (name, value) =>
		zustandActions.updateFilter(name, value);

	const isSelectAll =
		(mode === QUERY && exclude.length === include.length) ||
		(_get(selectionBucket, "state.selectedRows.length") ===
			_get(data, "total", -1) &&
			_get(selectionBucket, "state.selectedRows.length") > 0);

	useEffect(() => {
		let candidate;

		if (selectionBucket.state.selectedRows.length === 1) {
			candidate = data.data.find(
				elem => elem.id === selectionBucket.state.selectedRows[0]
			);
		}
		const condidateWidthEmail = selectionBucket.state.selectedRows.filter(
			item => {
				return (
					data.data
						.filter(item => !item.email)
						.map(profileID => profileID.id)
						.indexOf(item) < 0
				);
			}
		);
		let selectedIds = [];
		if (mode === QUERY) {
			selectedIds =
				offset === 0
					? data.data.map(elm => elm.id).filter(item => !exclude.includes(item))
					: shortListIds;
		} else selectedIds = selectionBucket.state.selectedRows;
		const selectedCandidates = selectedIds
			.map(id => {
				const candidate = data.data.find(item => item.id === id);
				if (
					!select_data.selected_candidates_list
						.map(item => item.id)
						.includes(candidate?.id)
				)
					return { email: candidate?.email, id: candidate?.id };
			})
			.filter(item => !!item?.id);
		const selectedCandidatesList =
			select_data.selected_candidates_list?.filter(item =>
				selectionBucket.state.selectedRows.includes(item.id)
			) || [];
		setSelectData({
			mode,
			selected_candidates: mode === QUERY ? null : condidateWidthEmail,
			selected_candidates_list: [
				...selectedCandidatesList,
				...selectedCandidates
			],
			selected_candidates_for_ShortList:
				mode === QUERY
					? null
					: selectionBucket.state.selectedRows.slice(0, pageSize),
			exclude: mode === QUERY ? exclude : null,
			include: mode === QUERY ? include : null,
			total_item:
				mode === QUERY
					? limit - exclude.length + include.length
					: condidateWidthEmail.length,
			preview_mode: _get(candidate, "first_name", false)
				? {
						first_name: candidate.first_name,
						last_name: candidate.last_name
				  }
				: null,
			shortList:
				mode === QUERY
					? offset === 0
						? data.data
								.map(elm => elm.id)
								.filter(item => !exclude.includes(item))
						: shortListIds
					: []
		});
		if (mode === QUERY && offset === 0) {
			return setShortListIds(
				data.data.map(elm => elm.id).filter(item => !exclude.includes(item))
			);
		}
		if (isSelectAll && _get(data, "total") > MAX_CANDIDATE)
			return setDisplayLimitInfo(true);
		return setDisplayLimitInfo(false);
	}, [
		data,
		selectionBucket.state.selectedRows,
		exclude,
		include,
		mode,
		setDisplayLimitInfo,
		setShortListIds,
		setSelectData,
		isSelectAll,
		offset
	]);

	const {
		data: profileData,
		isFetching: loading,
		refetch: refetchProfile
	} = useFetchProfile(currentID, {
		enabled: !workflowId && currentID,
		onSuccess: () => {
			isNext && setIsNext(false);
		}
	});

	const [
		displayCreateCandidateModal,
		setDisplayCreateCandidateModal
	] = useState(false);

	const selectedCandidatesCounter =
		mode == QUERY
			? limit - exclude.length + include.length
			: _get(selectionBucket, "state.selectedRows.length");

	useEffect(() => {
		if (selectedCandidatesCounter !== 0) {
			reset();
			setMode(null);
		}
	}, [job_id, filters, source, sortBy, query, reset, setMode, logicalOperator]);

	useEffect(() => {
		const job_id = get(
			browserHistory.getCurrentLocation(),
			"query.vacancy-id",
			""
		);
		if (showDrawer && job_id) {
			historyPush(getProfileSearchRoute());
		}
	}, [showDrawer]);

	useEffect(() => {
		if (!isEmpty(tableSettings)) {
			setLimit(tableSettings?.table_settings["size"]);
		}
	}, [tableSettings]);

	useEffect(() => {
		setView(CANDIDATE_POOL);
		isFetchingCandidatesEnabled.current = true;
	}, []);

	useEffect(() => {
		setDisplayKeywordCard(userHasSearchByKeyword);
	}, [userHasSearchByKeyword]);

	useEffect(() => {
		if (job_id && criteriasArray) {
			const res = produce(criteriasArray, draft => {
				if (!draft.length) return;
				const { fields } = draft[0];

				if (fields.length) {
					const index = fields.findIndex(({ field }) => {
						return field === "preferred_location";
					});
					if (index !== -1) {
						draft[0].fields[index].value.distance = 20;
					}
				}
			});
			setVacancy_filters_data(res);
		}
	}, [criteriasArray, job_id]);

	useEffect(() => {
		if (job_id) {
			const remove = browserHistory.listenBefore(() => {
				history.replaceState(null, null, "/candidates/search");
			});
			return () => remove();
		}
	}, [job_id]);
	const { setDrawerActiveBody } = useGetFiles();
	const closeCandidateModal = () => {
		setDrawerActiveBody(UPLOAD_ID);
		setDisplayCreateCandidateModal(false);
	};

	const handleViewChange = () => {
		setActiveView(activeView);
	};
	const handleProfileNameClick = () => {
		setOpenDrawer(false);
		goToProfile();
	};

	const handleFilterDrawerCloseClick = useCallback(() => {
		setShowDrawer(false);
	}, [setShowDrawer]);

	const handleNewFilterClick = () => {
		setShowDrawer(true);
	};
	const checkCandidateSelection = useCallback(
		(index, item) => {
			return checkRowSelection(
				item.id,
				index + offset,
				item.email,
				exclude,
				include
			);
		},
		[offset, include, exclude, checkRowSelection]
	);

	const toggleAll = useCallback(
		checked => {
			if (checked) {
				setExclude([]);
				setInclude([]);
				if (data.total <= data.size) {
					setMode(checked ? "select" : null);

					const candidates = data.data.reduce((acc, item) => {
						if (item.email) {
							acc.push(item.id);
						}
						return acc;
					}, []);
					return toggleAllSelection(candidates);
				} else {
					setMode(checked ? QUERY : null);
					return togglePack(limit);
				}
			} else {
				setMode(null);
				return reset();
			}
		},
		[setExclude, setInclude, reset, togglePack, toggleAllSelection, data]
	);
	const goToProfile = user =>
		historyPush(
			getPermanentProfileRoute(
				`${user ? user : currentID}?list=${CANDIDATE_POOL}&source=list`
			)
		);

	const toggleCard = useCallback(
		(e, id, index, checked) => {
			let inputChecked = e.target.checked;
			inputChecked && !mode && setMode("select");
			e?.stopPropagation?.();

			mode !== QUERY && selectionBucket.actions.row(id);
			if (mode === QUERY) {
				if (checked) {
					setInclude(old => old.filter(item => !(item === id)));
					if (index + _get(data, "offset") <= limit)
						return setExclude(old => [...new Set([...old, id])]);
				} else if (!checked && !isSelectAll) {
					setExclude(old => old.filter(item => !(item === id)));
					if (index + _get(data, "offset") >= limit)
						return setInclude(old => [...new Set([...old, id])]);
				}
			}
		},
		[mode, selectionBucket.state, include, exclude]
	);

	const handleSeeAllNotesClick = () => {
		historyPush(`/permanent/view/${currentID}?&tab=notes`);
		setCurrentId(null);
	};

	let candidateToShowNote;

	if (selectedUserId && data && data.data) {
		const index = data.data.findIndex(({ id }) => id === selectedUserId);

		candidateToShowNote = data.data[index];
	}

	const { setIsOpen: openDrawerShortList } = useShortList();
	useSourceCandidatesTour({
		checkSecondAndFirstItems: () => {
			toggleRowSelection(data.data[1].id);
			toggleRowSelection(data.data[2].id);
		},
		openDrawerShortList: () => openDrawerShortList(true),
		closeDrawerShortList: () => openDrawerShortList(false)
	});
	useEffect(() => {
		return () => {
			openDrawerShortList(false);
			setCurrentId(null);
		};
	}, []);

	const onSubmitSmartQuery = () => {
		handleFilterDrawerCloseClick();
	};

	return (
		<div className={style.root}>
			<PageHeader
				setDisplayCreateCandidateModal={setDisplayCreateCandidateModal}
				selectionBucket={selectionBucket}
				limit={limit}
				MAX_CANDIDATE={MAX_CANDIDATE}
				mode={mode}
				selectedCandidatesCounter={selectedCandidatesCounter}
			/>
			{currentID && (
				<ProfileCardDrawer
					profileList={_get(data, "data", [])}
					offset={data?.offset}
					total={data?.total}
					size={data?.size}
					handleNext={offset => {
						updateFilter("offset", offset + data.size);
					}}
					handlePrev={offset => {
						updateFilter("offset", offset - data.size);
					}}
					setIsNext={value => {
						setIsNext(value);
					}}
					isNext={isNext}
					setIsPrev={setIsPrev}
					isPrev={isPrev}
					refetchProfile={refetchProfile}
					userID={currentID}
					openDrawer={openDrawer}
					setOpenDrawer={setOpenDrawer}
					setUserID={setCurrentId}
					id={currentID}
					goToProfile={goToProfile}
					headline={{
						phone: _get(profileData, "phone"),
						email: _get(profileData, "email")
					}}
					loading={loading}
					profileData={profileData}
					onProfileNameClick={handleProfileNameClick}
					onSeeAllNotesClick={handleSeeAllNotesClick}
				/>
			)}
			<HeaderFilters
				tableSettings={tableSettings}
				toggleRecap={setShowRecap}
				showRecap={showRecap}
				filtersCount={_get(filters, "length", 0)}
				onAddFilterClick={handleNewFilterClick}
				onViewChange={handleViewChange}
				setActiveView={setActiveView}
				activeView={activeView}
				tableTag={TABLE_TAG}
			/>
			{showRecap &&
				smartQuerySchema.length === 0 &&
				(validFiltersCount(filters) > 0 || source !== "all") && (
					<FiltersBar
						filters={filters}
						setDisplayDrawer={handleNewFilterClick}
						module_id={CANDIDATES_MODULE_ID}
						source={source}
						selectedCriteria={selectedCriteria}
						clearFilters={clearFilters}
						onDelete={handleFilterDelete}
						setShowDrawerProps={setShowDrawer}
						setSelectedFilterProps={setSelectedFilter}
					/>
				)}

			{showRecap && validFiltersCount(smartQuerySchema) > 0 && (
				<SearchCandidateSmartQueryRecap
					smartQuerySchema={smartQuerySchema}
					selectedCriteria={selectedCriteria}
				/>
			)}
			{activeView === viewTypes.table && (
				<TableView
					tableSettings={tableSettings}
					isLoading={isLoading}
					resolvedData={data}
					checkCandidateSelection={checkCandidateSelection}
					offset={data?.offset}
					onCheckboxChange={toggleCard}
					isSelectAll={isSelectAll}
					onSelectAllChange={toggleAll}
					selectionState={selectionState.state}
					selectedCandidatesCounter={selectedCandidatesCounter}
					tableTag={TABLE_TAG}
					refetch={refetch}
				/>
			)}
			{activeView === viewTypes.cards && (
				<>
					<div className={styles.counterHeader}>
						<>
							{selectedView === CARDS && (
								<Checkbox
									id="selection"
									checked={isSelectAll}
									onChange={() => toggleAll(!isSelectAll)}
								/>
							)}

							<p className={styles.countLabel}>
								{mode === QUERY ? (
									<span className={styles.grayText}>
										<span className={styles.greenText}>
											{limit - exclude.length + include.length}
										</span>
										&nbsp;selected of&nbsp;
										<span className={styles.greenText}>
											{_get(data, "total")}
										</span>
									</span>
								) : (
									<span className={styles.grayText}>
										<span className={styles.greenText}>
											{_get(selectionBucket, "state.selectedRows.length")}
										</span>
										&nbsp;selected of&nbsp;
										<span className={styles.greenText}>
											{_get(data, "total", 0)
												.toLocaleString()
												.replace(",", " ")}
										</span>
									</span>
								)}
							</p>
						</>
					</div>
					<CardsView
						resolvedData={data}
						isLoading={isLoading}
						isFetching={isFetching}
						selectionBucket={selectionBucket}
						addRow={id => selectionBucket.actions.row(id)}
						toggleCard={toggleCard}
						shortListIds={shortListIds}
						exclude={exclude}
						include={include}
						mode={mode}
						checkCandidateSelection={checkCandidateSelection}
					/>
				</>
			)}

			{_get(data, "total", 0) > pageSize && (
				<div className={styles.containerFooter}>
					<RowsPages
						limit={pageSize}
						setLimit={limit => {
							setLimit(limit);
						}}
						rowsPages={rowsPages}
						refetch={refetch}
						tableTag={TABLE_TAG}
					/>
					<Pagination
						previousLabel={<PrevIcon />}
						nextLabel={<PrevIcon style={{ transform: "rotate(180deg)" }} />}
						total={data?.total}
						size={data?.size}
						handlePageClick={page => {
							const offset = page * data?.size;

							setOffset(offset);
						}}
						offset={data?.offset}
						forcePage={Math.ceil(data?.offset / data?.size)}
						pageRangeDisplayed={data.total > 1000 ? 6 : undefined}
						marginPagesDisplayed={data.total > 1000 ? 0 : undefined}
					/>
				</div>
			)}
			<React.Suspense fallback={null}>
				{showDrawer && (
					<NewFilterDrawer
						onClose={handleFilterDrawerCloseClick}
						placeHolder="Search in name, phone, email & CV content (Boolean strings OK)"
						onSubmit={onSubmitSmartQuery}
					/>
				)}
				{<ShortListDrawer />}
				{displayCreateCandidateModal && (
					<AddCandidateDrawer
						onClose={closeCandidateModal}
						company_id={_get(user, "active_company")}
					/>
				)}
			</React.Suspense>

			<SearchCandidateNotesDrawer
				isDrawerOpen={showNoteDrawer}
				activeNote={(last_note && last_note.data[0]) || { description: "" }}
				toggleDrawer={() => {
					setShowNoteDrawer(false);
					setSelectedUserId(null);
				}}
				candidate={candidateToShowNote}
			/>

			<CandidateActionsDrawer view={QUICK_VIEW} />
		</div>
	);
};
export default SearchCandidateContainer;
