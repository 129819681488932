import React from "react";
import _get from "lodash/get";
import { useQuery } from "react-query";
import CustomVictoryPie from "../components/victoryPie";
import { client } from "lib/api-client";
import ChartLoading from "../components/ChartLoading";
import ChartEmpty from "../components/ChartEmpty";
import ChartError from "../components/ChartError";
import { SUCCESS, LOADING, ERROR } from "config";
const ChartTemporary = () => {
	const fetchDashboardData = () =>
		client("/api/dashboard/dashboard-data", {
			body: { tag: "current_hourly_cost_live_missions" }
		});
	const { status, isFetching, data, refetch } = useQuery(
		["pokes"],
		fetchDashboardData
	);
	const total = _get(data, "current_hourly_cost_live_missions.data");
	const EMPTY_CASE = total && total.length === 0;

	const renderChart = status => {
		switch (status) {
			case ERROR:
				return <ChartError onReload={refetch} />;
			case LOADING:
				return <ChartLoading />;
			case SUCCESS:
				if (EMPTY_CASE) {
					return <ChartEmpty title="Current Hourly cost - live missions" />;
				}
				return (
					<CustomVictoryPie
						chartData={_get(data, "current_hourly_cost_live_missions", [])}
						chartTitle="Current Hourly cost - live missions"
						isFetching={isFetching}
					/>
				);
			default:
				return <></>;
		}
	};
	return <>{renderChart(status)}</>;
};

export default ChartTemporary;
