import React, { useMemo } from "react";
import FieldError from "./FieldError";
import TextareaField from "./TextareaField";
import { Controller } from "react-hook-form";
import styles from "../evaluation-template-render-drawer.module.scss";
import { TEXTAREA_MAX_LENGTH } from "../constants";

export default function ControlledTextareaField({
	name,
	error,
	element,
	control,
	...restProps
}) {
	const { title, is_required } = element;

	const validationRules = useMemo(() => {
		const rules = {
			maxLength: {
				value: TEXTAREA_MAX_LENGTH,
				message: `Please answer should not exceed ${TEXTAREA_MAX_LENGTH} characters.`
			}
		};
		if (is_required) rules.required = "Please answer this question";
		return rules;
	}, [is_required]);

	return (
		<div className={styles.fieldContainer}>
			<label className={styles.fieldLabel}>
				{title} {is_required && <span className={styles.required}>*</span>}
			</label>
			<Controller
				name={name}
				control={control}
				rules={validationRules}
				defaultValue={element?.value || ""}
				render={({ field }) => {
					const onChange = e => field.onChange(e.target.value?.trimStart());
					return (
						<TextareaField {...field} {...restProps} onChange={onChange} />
					);
				}}
			/>
			<FieldError error={error} />
		</div>
	);
}
