/* eslint-disable */
import React, { useEffect } from "react";
import { searchParser } from "config/helpers";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import useLayoutElements from "hooks/useLayoutElements";
import { VideoConference } from "common/VideoConference";

export const getRoute = () => {
	return "/virtual-room";
};

export const getPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const WhereByVirtualRoom = () => {
	const { vacancyName, roomUrl, displayName } = searchParser();
	const { setItems: setBreadCrumbsItems } = useBreadCrumbs(state => state);
	const { header } = useLayoutElements();

	useEffect(() => {
		if (vacancyName) {
			const items = [
				{ name: "Permanent" },
				{ name: "Vacancy", path: "/vacancies/list" },
				{ name: vacancyName }
			];

			setBreadCrumbsItems(items);
		}
	}, []);

	return <VideoConference roomUrl={roomUrl} displayName={displayName} />;
};

export default WhereByVirtualRoom;
