import React, { Component } from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import { SKILLS, REQUEST, CLIENT } from "config";
import _groupBy from "lodash/groupBy";
import { getRandomNumber, getSkillsTooltip } from "config/helpers";
import Star from "common/Icons/Star";
import { CategoryContainer } from "common/StyledCategory";
import Skills from "./Skills";
import cx from "classnames";

class ListItem extends Component {
	renderSkillsByCategory = hideTooltip => {
		const categories = _groupBy(this.props.list, "parent_sector.name");
		let skills = [];

		for (let category in categories) {
			let key = category || getRandomNumber(1, 100);
			skills.push(
				this.props.isVacancy ? (
					<div key={key}>
						<CategoryContainer key={key}>
							{category && category != "undefined" && (
								<div className="category-name">
									{this.props.type === SKILLS ? category : ""}
								</div>
							)}
							{this.renderSkills(categories[category], hideTooltip)}
						</CategoryContainer>
					</div>
				) : (
					<div key={key} id="can-do">
						{this.renderSkills(categories[category], hideTooltip)}
					</div>
				)
			);
		}
		return skills;
	};
	renderSkills = (skills, hideTooltip) => {
		return (
			skills &&
			skills.length !== 0 && (
				<Skills
					skills={skills}
					max={12}
					showMoreText={"•••"}
					showLessText={"↑"}
					readLessLinkClassName={"tag link"}
					readMoreLinkClassName={"tag link"}
					renderOption={skill =>
						skill.must_have ? (
							<Tooltip
								placement="top"
								overlay={
									this.props.userType ? (
										this.props.userType !== CLIENT && (
											<div>
												<div>
													This skill was marked as a <strong>MUST HAVE</strong>{" "}
													by the client.
												</div>
												You won’t be able to bid on this request if you’re
												lacking this skill.
											</div>
										)
									) : (
										<div>
											<div>
												This skill was marked as a <strong>MUST HAVE</strong>.
											</div>
											{this.props.jobType && this.props.jobType === REQUEST
												? "Freelancers and/or consultants lacking this skill won’t be able to apply."
												: "Candidates lacking this skill will be excluded from the potential matches list."}
										</div>
									)
								}
								trigger="hover"
								destroyTooltipOnHide={true}
								mouseLeaveDelay={0}
								overlayClassName="g-tool-white"
								key={skill._id}
							>
								<div className="tag">
									<Star className="must_have_star" />
									{`${skill.name} ( ${skill.score} / 5 )`}
								</div>
							</Tooltip>
						) : hideTooltip ? (
							<div
								key={skill._id}
								className="tag"
							>{`${skill.name} ( ${skill.score} / 5 )`}</div>
						) : (
							<Tooltip
								placement="top"
								overlay={<div>{getSkillsTooltip(skill.score)}</div>}
								trigger="hover"
								destroyTooltipOnHide={true}
								mouseLeaveDelay={0}
								overlayClassName="g-tool-white"
								key={skill._id}
							>
								<div className="tag">{`${skill.name} ( ${skill.score} / 5 )`}</div>
							</Tooltip>
						)
					}
				/>
			)
		);
	};
	render() {
		const { title, list, className = "", hideTooltip = false } = this.props;
		return (
			<div className={cx("section", className)}>
				<div className="section-title">{title}</div>
				{list && this.renderSkillsByCategory(hideTooltip)}
			</div>
		);
	}
}

ListItem.propTypes = {
	title: PropTypes.string,
	list: PropTypes.array,
	scoreMax: PropTypes.number,
	type: PropTypes.string
};

export default ListItem;
