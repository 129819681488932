import React, { Component } from "react";

class StyleButton extends Component {
	constructor() {
		super();
		this.onToggle = this.onToggle.bind(this);
	}

	onToggle(e) {
		e.preventDefault();
		this.props.onToggle(this.props.style);
	}

	render() {
		let className = "RichEditor-styleButton";
		if (this.props.active) className += " RichEditor-activeButton";
		return (
			<span className={className} onMouseDown={this.onToggle}>
				{this.props.icon && <i className={`fa fa-${this.props.icon}`} />}
				{!this.props.icon && this.props.label}
			</span>
		);
	}
}

export default StyleButton;
