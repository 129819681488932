import styled from "styled-components";
import { colors } from "config/styles";

export const Title = styled.div`
	color: ${colors.anchorsColor};
	text-transform: uppercase;
	font-weight: bold;
	line-height: 1.7;
	margin-top: 10px;
`;

export const JobHeader = styled.div`
	display: flex;

	.logo {
		img {
			width: 52px;
			height: 52px;
			object-fit: cover;
			border-radius: 3px;
			margin-right: 10px;
		}
	}

	.logo-placeholder {
		svg {
			width: 60px;
			height: 42px;
			fill: #c3c3c3;
		}
		margin-bottom: 5px;
	}

	.title {
		color: #242f43;
		font-weight: bold;
		font-size: 16.8px;
		margin-right: 8px;
		margin-top: 6px;
		.time {
			color: #8a95a1;
			font-size: 13.3px;
			font-weight: normal;
		}
	}
	margin-bottom: 16px;
`;

export const FunctionsContainer = styled.div`
	#can-do {
		ul.crumbs {
			list-style: none;
			overflow: hidden;
			margin-bottom: 21px;
			padding: 0;

			li {
				float: left;
				color: #fff;
				text-decoration: none;
				padding: 0px 0px 0px 42px !important;
				background-color: #b3bcc6;
				position: relative;
				display: block;
				font-weight: 400;
				&:first-child {
					display: flex;
					padding-left: 14px !important;
					border-radius: 3px 0 0 3px;
				}
				&:last-child {
					text-transform: capitalize;
					padding-right: 14px !important;
					border-radius: 0 3px 3px 0;
					background-color: #8a95a1;
				}
				&:not(:last-child) {
					&:after {
						content: " ";
						display: block;
						width: 0;
						height: 0;
						border-top: 30px solid transparent !important;
						border-bottom: 30px solid transparent !important;
						border-left: 30px solid #b3bcc6 !important;
						position: absolute;
						top: 50%;
						margin-top: -30px !important;
						left: 100%;
						z-index: 2;
					}
					&:before {
						content: " ";
						display: block;
						width: 0;
						height: 0;
						border-top: 30px solid transparent !important;
						border-bottom: 30px solid transparent !important;
						border-left: 30px solid #fff !important;
						position: absolute;
						top: 50%;
						margin-top: -30px !important;
						margin-left: 4px;
						left: 100%;
						z-index: 1;
					}
				}
			}
			&.main {
				li:first-child {
					background-color: ${colors.primaryColor};
					border-right-color:${colors.primaryColor} !important;
					&:after {
						border-left-color: ${colors.primaryColor};
					}
				}
			}
		}
    ;
}
	}
`;

export const Navigation = styled.div`
	padding: 20px;
	margin-bottom: 20px;
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	position: relative;
	.back-to-list {
		position: absolute;
		left: 15px;
		color: ${colors.anchorsColor};
		font-weight: bold;
		margin: unset !important;
		border: 1px solid #616976;
		padding: 6px 23px;
		border-radius: 3px;
		cursor: pointer;
		.icon-container {
			transform: rotate(180deg);
			display: inline-block;
			margin-right: 6px;
			.icon-arrow-right1 {
				font-size: 11px;
				position: relative;
				top: -3px;
			}
		}
	}
	.loader {
		margin-bottom: unset;
		margin-right: 6px;
		span {
			width: 28px;
			height: 28px;
		}
	}

	.actions-container {
		margin-right: 0;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.action {
			color: ${colors.anchorsColor};
			font-weight: bold;
			display: flex;
			align-items: center;
			margin: 0px 14px;
			cursor: pointer;
			border: unset !important;
			background-color: transparent !important;
			&:hover {
				color: #157e76;
			}
			&.disabled {
				cursor: not-allowed;
				opacity: 0.7;
				&:hover {
					color: ${colors.anchorsColor};
				}
			}
		}
		.arrow {
			font-size: 21px;
			margin: 0px 6px;
		}
	}
`;

export const Container = styled.div`
	color: ${colors.inputColor};
	line-height: 1.33;
	font-size: 13.3px;
	font-weight: 400;
	margin: 14px 30px 0px 30px;
	display: flex;
	align-items: center;

	#do-not-show-again {
		margin: unset;
		width: 171px;
		height: 20px;
		z-index: 10;
		cursor: pointer !important;
	}
	label {
		padding-left: 30px !important;
		cursor: pointer !important;
	}
`;

export const FormContainer = styled.div`
	max-width: 774px;
	width: 100%;
	margin: 0 auto;
	.form-group {
		&:after {
			display: none;
		}
		margin-bottom: 31px;
	}

	.btn {
		width: 100%;
		margin-top: 20px;
		padding: 14px 24px !important;
		background-color: #1b9f94;
		&:hover {
			background-color: #1b9f94;
		}
	}
	.inline-error {
		&.phone {
			margin-top: -31px;
		}
	}
	#accept_policies {
		width: 50px !important;
		height: 60px !important;
		top: -5px !important;
		left: 20px !important;
		z-index: 100;
		a {
			cursor: pointer;
		}
	}
`;

export const DescriptionText = styled.div`
	color: #293d58;
	text-align: left;
	font-weight: 400;
	.restart {
		cursor: pointer;
		color: ${colors.primaryColor};
	}

	.headline {
		margin-bottom: 12px;
	}
	.infos {
		color: #2570b7;
	}
	.link {
		text-decoration: underline;
		color: #1b9f94;
		cursor: pointer;

		border: unset !important;
		background-color: transparent !important;
	}
	.bold {
		margin-top: 24px;
		font-weight: bold;
		margin-bottom: 6px;
		font-family: "BasierCircle";
		font-size: 18px;
		font-weight: 600;

		line-height: 2.14;
		letter-spacing: 0.09px;

		color: rgb(37, 55, 88);
	}
	.email {
		text-decoration: underline;
		font-weight: bold;
	}
	.contact-email {
		text-decoration: underline;
	}
`;

export const ConfirmContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 0px;

	svg {
		width: 134px;
	}
	.first-section {
		width: 523px;
		margin-right: 10px;
	}

	.third-section {
		margin-left: 15px;
	}
`;

export const LoginFormContainer = styled.div`
	margin-top: 20px;
`;
