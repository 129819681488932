import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_POSTING_REQUIREMENTS_AUTOCOMPLETE_DATA } from "config/api-endpoints";

const getAutoCompleteData = data => {
	return client(GET_POSTING_REQUIREMENTS_AUTOCOMPLETE_DATA, {
		body: data
	});
};

export const getAutoCompleteDataQueryKey = "GET_AUTOCOMPLETE_DATA_QUERY_KEY";

const useGetAutoCompleteFieldData = (data, options) => {
	return useQuery(
		[getAutoCompleteDataQueryKey, data],
		() => getAutoCompleteData(data),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...options
		}
	);
};

export default useGetAutoCompleteFieldData;
