import create from "zustand";

const initialState = {
	cards: [],
	pdf_doc: []
};

const useOverviewStore = create(set => ({
	...initialState,
	setValue: (key, value) => set(() => ({ [key]: value }))
}));

export default useOverviewStore;
