import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import cx from "classnames";
import styles from "./page-card.module.scss";

const PageCardLoading = ({ className, title = "Career page" }) => {
	return (
		<div className={cx(styles.container, className)}>
			<div className={styles.header}>
				<div className={styles.title}>{title}</div>
				<div className={styles.dropDownLoader}>
					<SkeletonTheme color="#e5e5e5">
						<Skeleton
							width={"10px"}
							height={"10px"}
							style={{ marginTop: "9px" }}
							circle
						/>
					</SkeletonTheme>
					<SkeletonTheme color="#e5e5e5">
						<Skeleton
							className={styles.skeleton}
							width={"45px"}
							height={"16px"}
						/>
					</SkeletonTheme>
				</div>
			</div>
			<div className={styles.card}>
				<div className={styles.buttonWrapper}>
					<SkeletonTheme color="#e5e5e5">
						<Skeleton
							className={styles.skeleton}
							width={"133px"}
							height={"36px"}
							style={{ border: "1px solid #d4d4d4" }}
						/>
					</SkeletonTheme>
				</div>
				<div className={styles.row}>
					<div className={cx(styles.label, styles.admin)}>Admin</div>
					<div>
						<SkeletonTheme color="#e5e5e5">
							<Skeleton width={"21px"} height={"21px"} circle />
						</SkeletonTheme>
					</div>
				</div>
				<div className={styles.row}>
					<div className={cx(styles.label, styles.lastEdited)}>Last edited</div>
					<div className={styles.lastEditedContainer}>
						<SkeletonTheme color="#e5e5e5">
							<Skeleton width={"21px"} height={"21px"} circle />
						</SkeletonTheme>
						<SkeletonTheme color="#e5e5e5">
							<Skeleton
								className={styles.skeleton}
								width={"117px"}
								height={"14px"}
							/>
						</SkeletonTheme>
					</div>
				</div>

				<div className={styles.row}>
					<div className={cx(styles.label, styles.collaborators)}>
						Collaborators
					</div>
					<div className={styles.collaboratorsContainer}>
						<div className={styles.images}>
							<SkeletonTheme color="#e5e5e5">
								<Skeleton
									width={"29px"}
									height={"29px"}
									style={{ border: "2px solid white" }}
									circle
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#e5e5e5">
								<Skeleton
									width={"29px"}
									height={"29px"}
									style={{ border: "2px solid white", marginLeft: "-8px" }}
									circle
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#e5e5e5">
								<Skeleton
									width={"29px"}
									height={"29px"}
									style={{
										border: "2px solid white",
										marginLeft: "-8px",
										marginRight: ".25rem"
									}}
									circle
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#e5e5e5">
								<Skeleton width={"26px"} height={"26px"} circle />
							</SkeletonTheme>
						</div>
					</div>
				</div>

				<div className={styles.row}>
					<div className={cx(styles.label, styles.linklabel)}>Link</div>
					<div className={styles.urlEdit}>
						<SkeletonTheme color="#e5e5e5">
							<Skeleton
								className={styles.skeleton}
								width={"109px"}
								height={"16px"}
							/>
						</SkeletonTheme>
						<div className={styles.buttonsWrapper}>
							<SkeletonTheme color="#e5e5e5">
								<Skeleton width={"21px"} height={"21px"} circle />
							</SkeletonTheme>
							<SkeletonTheme color="#e5e5e5">
								<Skeleton
									className={styles.skeleton}
									width={"21px"}
									height={"21px"}
								/>
							</SkeletonTheme>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PageCardLoading;
