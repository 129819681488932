import React from "react";
import CommentsDrawer from "./CommentsDrawer";

const CommentsBox = ({
	closeCommentsBox,
	dispalyCommentBox,
	id,
	timesheetId,
	view,
	comments,
	loadTimeSheet,
	status,
	isClient
}) => {
	return (
		<CommentsDrawer
			openCommentsDrawer={dispalyCommentBox}
			closeDrawer={closeCommentsBox}
			comments={comments}
			loadTimeSheet={loadTimeSheet}
			id={id}
			timesheetId={timesheetId}
			view={view}
			status={status}
			isClient={isClient}
		/>
	);
};

export default CommentsBox;
