import { useEffect } from "react";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { searchParser } from "config/helpers";
import { getVacancyListRoute } from "modules/vacancy/containers/VacancyListContainer";
import { getVacancyViewRoute } from "modules/vacancy/components/vacancyView";

const useSetPublishVacancyBreadCrumbs = vacancyId => {
	const { setItems } = useBreadCrumbs(state => state);
	const { t, published } = searchParser();

	useEffect(() => {
		const vacancyPath = getVacancyViewRoute(vacancyId);
		const items = [
			{ name: "Vacancy", path: getVacancyListRoute() },
			{ name: t, path: vacancyPath },
			{ name: published === "true" ? "Republish" : "Publish" }
		];

		setItems(items);
	}, []);
};

export default useSetPublishVacancyBreadCrumbs;
