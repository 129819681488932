import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { missionsListSelector, filterSelector } from "../selectors";
import {
	CLIENT,
	FREELANCER,
	CREATED_AT,
	DESC,
	CONSULTANCY,
	PROJECT_MANAGER
} from "config";
import {
	toggleInterruptModal,
	toggleExtendModal,
	interruptMission,
	extendMission,
	fetchMissionsList,
	updateFilter,
	resetFilters
} from "../actions/contractActions";
import { getFilterSettings } from "modules/app/actions/ListsActions";
import { filterSettingsSelectors } from "modules/app/selectors";
import CompanyDashboard from "common/dashboard/Dashboard";
import Contracts from "./OpenedJobs/Contracts";
import Intro from "common/dashboard/Intro";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { userSelector } from "modules/company/selectors/ProfileSelector";
import ExtendMissionModal from "./modal/ExtendMission";

export function getMissionsContainerRoute() {
	return "/jobs/my-jobs/missions";
}

export function getMissionsContainerPermissions() {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "consultancy",
				roles: ["admin", "project_manager"]
			}
		]
	};
}

class MissionsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			interrupt: false,
			id: null
		};
	}

	componentDidMount() {
		this.props.resetFilters();
		this.fetchAPI();
	}
	fetchAPI(payload, settings = true) {
		const {
			user: { type }
		} = this.props;
		(type === CLIENT || type === FREELANCER) &&
			settings &&
			this.props.getFilterSettings();
		this.props.fetchMissionsList(payload);
	}

	handlePageClick = value => {
		const {
			data: {
				missions: {
					pagination: { size }
				}
			},
			user: { type },
			filter
		} = this.props;
		let payload = {
			field: CREATED_AT,
			sort: DESC,
			offset: size * value
		};
		if (type !== CONSULTANCY) {
			payload = { ...filter, ...payload };
		}

		this.fetchAPI(payload, false);
	};

	render() {
		const {
			interruptMission,
			toggleInterruptModal,
			toggleExtendModal,
			extendMissionHandler,
			data: { missions, interrupt, extendMission },
			filter,
			filterSettings,
			updateFilter,
			route: { title: routeTitle },
			user
		} = this.props;
		const isClient = user.type === CLIENT;
		const title = isClient ? "Missions" : "Opened missions";
		const isConsultancy = user.type === CONSULTANCY;
		const isConsultant = isConsultancy && user.role_name === PROJECT_MANAGER;
		const subTitle = isClient ? (
			<>
				This section gives you an overview of all the past and current missions
				within your department/company.
				<br />
				Depending on your role, the “ACTIONS” column allows you to extend or
				interrupt the ongoing missions.
			</>
		) : (
			`The “Missions” section allows you to overview ${
				isConsultant ? "" : "and manage"
			} the missions concluded via wiggli.`
		);

		return (
			<BodyClassName className="opened-jobs gray-bg">
				<div className="jobs-list-preview">
					{routeTitle && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<div className="dashboard-box">
						<CompanyDashboard />
						<Intro icon="fa fa-file-text-o" title={title} subTitle={subTitle} />
					</div>

					<div className="container">
						<Contracts
							toggleInterruptModal={toggleInterruptModal}
							data={missions.contracts}
							pagination={missions.pagination}
							onPageClick={this.handlePageClick}
							isConsultancy={isConsultancy}
							user={user}
							filter={filter}
							filterSettings={filterSettings}
							onFilterChange={updateFilter}
						/>

						<ConfirmationModal
							active={!!interrupt}
							modalName="interrupt_mission"
							onClose={toggleInterruptModal}
							type={ConfirmationTypes.error}
							title={"Are you sure you want to interrupt the mission?"}
							warningText={
								"NOTE THAT THIS ACTION HAS AN IMPACT ON YOUR CONTRACT AND MIGHT INDUCE COSTS"
							}
							content={
								"If so, wiggli will generate the necessary documents to end the mission earlier than stated in the contract."
							}
							firstButton={{
								action: () => interruptMission(interrupt),
								label: "YES, INTERRUPT MISSION",
								type: "warning"
							}}
						/>

						{extendMission._id && (
							<ExtendMissionModal
								mission={extendMission}
								toggleExtendModal={toggleExtendModal}
								extendMission={extendMissionHandler}
							/>
						)}
					</div>
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		data: missionsListSelector(state),
		filter: filterSelector(state),
		user: userSelector(state),
		filterSettings: filterSettingsSelectors(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchMissionsList: payload => dispatch(fetchMissionsList(payload)),
		toggleInterruptModal: id => dispatch(toggleInterruptModal(id)),
		toggleExtendModal: () => dispatch(toggleExtendModal()),
		interruptMission: id => dispatch(interruptMission(id)),
		extendMissionHandler: payload => dispatch(extendMission(payload)),
		getFilterSettings: payload => dispatch(getFilterSettings(payload)),
		updateFilter: filter => dispatch(updateFilter(filter)),
		resetFilters: () => dispatch(resetFilters())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionsContainer);
