import React from "react";
import _get from "lodash/get";
import { Box, Text } from "rebass";
import { Button } from "common/styled/buttons";
import CurrencyFormatter from "common/CurrencyFormater";
import { browserHistory } from "react-router";
import { INACTIVE, REMOTE, REMOTE_LABEL, EMPLOYMENT_TYPE } from "config";
import GlobalTooltip from "common/GlobalTooltip";

const Sidebar = ({
	job,
	canApplyToJob,
	isLoading,
	templateId,
	isOnline,
	candidateStatus,
	setTab,
	setActiveTab
}) => {
	const redirectToJob = () => {
		let link = `/pokes/${job.poke_id}`;
		if (templateId) {
			link += `?template=${templateId}`;
		}
		link += `?search`;
		browserHistory.push(link);
	};
	const locationTypeRemote = `${_get(job, "location_type")}` === REMOTE;
	const payment_time = _get(job, "payment_time", "yearly");
	const payment_type = _get(job, "payment_type", "gross");
	const symbol = _get(job, "symbol", "€");
	const pokeID = _get(job, "poke_id");

	const actions = () => {
		setActiveTab();
		setTab();
	};

	const displayAction = () => {
		if (canApplyToJob || !isOnline || isLoading) {
			return (
				<div className="actions">
					<Button
						block
						bordered
						small
						onClick={() => {
							!isLoading && actions();
						}}
						disabled={isLoading}
						color="greenButton"
					>
						Apply for this job
					</Button>
				</div>
			);
		}
		if (candidateStatus === INACTIVE) {
			return (
				<div className="actions inactiveButton">
					<GlobalTooltip
						active={true}
						placement="top"
						mouseEnterDelay={0}
						mouseLeaveDelay={0}
						overlay={"Inactive candidate Can't apply"}
					>
						<Button block bordered small color="greenButton">
							Apply for this job
						</Button>
					</GlobalTooltip>
				</div>
			);
		}
		if (!canApplyToJob && pokeID !== null) {
			return (
				<div className="actions">
					<Button block bordered small onClick={redirectToJob}>
						View application
					</Button>
				</div>
			);
		}
	};
	const getEmploymentType = empType => {
		const employment = EMPLOYMENT_TYPE.find(type => type.value === empType);
		return _get(employment, "label");
	};
	return (
		<>
			<Box
				sx={{
					borderRadius: "4px",
					backgroundColor: "rgba(229, 232, 236, 0.4)"
				}}
			>
				<Box p={40}> {displayAction()}</Box>
				<Box
					sx={{
						borderTop: "1px solid #e5e6ea",
						padding: "26px 40px 4px 40px"
					}}
				>
					<Box mb={30}>
						<Text
							mb={10}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								fontWeight: "600",
								letterSpacing: "0.09px",
								color: "rgb(37, 55, 88)"
							}}
						>
							Company Name
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								letterSpacing: "0.09px",
								color: "rgb(39, 50, 56)"
							}}
						>
							{_get(job, "company.name")}
						</Text>
					</Box>
					{isOnline && (
						<Box mb={30}>
							<Text
								mb={10}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "16px",
									fontWeight: "600",
									letterSpacing: "0.09px",
									color: "rgb(37, 55, 88)"
								}}
							>
								Employment Type
							</Text>
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "16px",
									letterSpacing: "0.09px",
									color: "rgb(39, 50, 56)"
								}}
							>
								<div>{getEmploymentType(_get(job, "employment_type"))}</div>
								<div>
									{_get(job, "expected_duration.duration")}{" "}
									{_get(job, "expected_duration.period")}
								</div>
							</Text>
						</Box>
					)}
					<Box mb={30}>
						<Text
							mb={10}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								fontWeight: "600",
								letterSpacing: "0.09px",
								color: "rgb(37, 55, 88)",
								textTransform: "capitalize"
							}}
						>
							{payment_time} {payment_type} Salary
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								letterSpacing: "0.09px",
								color: "rgb(39, 50, 56)"
							}}
						>
							{!_get(job, "salary_range_start") ? (
								"NA"
							) : (
								<>
									{" "}
									Between{" "}
									<CurrencyFormatter
										cent={_get(job, "salary_range_start")}
										symbol={symbol}
									/>{" "}
									and{" "}
									<CurrencyFormatter
										cent={_get(job, "salary_range_end")}
										symbol={symbol}
									/>
								</>
							)}
						</Text>
					</Box>
					<Box mb={30}>
						<Text
							mb={10}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								fontWeight: "600",
								letterSpacing: "0.09px",
								color: "rgb(37, 55, 88)",
								textTransform: "capitalize"
							}}
						>
							Other benefits
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								letterSpacing: "0.09px",
								color: "rgb(39, 50, 56)"
							}}
						>
							{_get(job, "other_benefits") || "None"}
						</Text>
					</Box>
					<Box mb={30}>
						<Text
							mb={10}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								fontWeight: "600",
								letterSpacing: "0.09px",
								color: "rgb(37, 55, 88)",
								textTransform: "capitalize"
							}}
						>
							{/* Payroll country */}
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								letterSpacing: "0.09px",
								color: "rgb(39, 50, 56)"
							}}
						>
							{_get(job, "payroll_country")}
						</Text>
					</Box>
					<Box mb={30}>
						<Text
							mb={10}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								fontWeight: "600",
								letterSpacing: "0.09px",
								color: "rgb(37, 55, 88)"
							}}
						>
							Location
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								letterSpacing: "0.09px",
								color: "rgb(39, 50, 56)"
							}}
						>
							{locationTypeRemote ? (
								<div>{REMOTE_LABEL}</div>
							) : (
								<>
									<div>
										{_get(job, "company.street")} {_get(job, "company.number")}
									</div>
									<div>
										{_get(job, "company.zip")} {_get(job, "company.city")}
									</div>
									<div>{_get(job, "company.country")}</div>
								</>
							)}
						</Text>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Sidebar;
