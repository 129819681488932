import React, { useMemo } from "react";
import { queryCache } from "react-query";
import { listCountKey } from "modules/home/api/useListCount.js";
import usePendingAplicationsList from "../../../api/UsePendingApplicationsList";
import usePermanentStore from "../../../zustand/PermanentStore";
import { RenderDate } from "../../../ui";
import { historyPush } from "config/helpers";
import { PENDING_APPLICATIONS } from "modules/home/config";
import PermanentTable from "modules/home/components/permanent/PermanentTable/PermanentTable";

const PendingApplications = ({ handleViewAll }) => {
	const {
		permanentStartDate,
		permanentEndDate,
		setItemPermanent,
		permanentType,
		setVacancyApplications
	} = usePermanentStore();

	const { data, isLoading } = usePendingAplicationsList(
		{
			start_date:
				permanentStartDate && permanentStartDate.isValid()
					? permanentStartDate.unix()
					: undefined,
			end_date:
				permanentEndDate && permanentEndDate.isValid()
					? permanentEndDate.unix()
					: undefined
		},
		{
			enabled: permanentType === PENDING_APPLICATIONS,
			onSuccess: ({ data }) => {
				queryCache.invalidateQueries(listCountKey);

				setItemPermanent(
					PENDING_APPLICATIONS,
					data.map(item => item.profile._id) || {}
				);

				const ids = data.map(({ _id }) => _id);

				setVacancyApplications(ids);
			}
		}
	);

	const pendingApplicationsColumns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: "created_at",
				Cell: props => {
					return <RenderDate date={props.cell.value} />;
				}
			},
			{
				Header: "Applicant's name",
				accessor: "full_name"
			},
			{
				Header: "Vacancy",
				accessor: "job.title"
			},
			{
				Header: "Source",
				accessor: "sources"
			},
			{
				Header: "Department",
				accessor: "group.name"
			}
		],
		[]
	);

	const onRowClick = row =>
		historyPush(`/permanent/view/${row.profile._id}?application_id=${row._id}`);

	return (
		<PermanentTable
			data={data?.data}
			columns={pendingApplicationsColumns}
			onRowClick={onRowClick}
			isLoading={isLoading}
			titleEmptyState={"You have no pending application for today!"}
			handleViewAll={handleViewAll}
		/>
	);
};

export default PendingApplications;
