import create from "zustand";
import _get from "lodash/get";

import { TEMPLATE } from "config";

const initialState = {
	filters: {
		functions: [],
		skills: [],
		languages: [],
		location: {},
		offset: 0,
		distance: 0,
		keywords: "",
		activeTemplate: undefined,
		except_applied: undefined,
		except_poked: undefined,
		tag: undefined,
		source: {
			applied: true,
			poked: true,
			added: true
		},
		employment_types: [],
		interviewed: false,
		not_viewed_by_me: false,
		not_viewed_by_my_company: false,
		residence_only: false,
		no_vacancy_sent: false,
		profile_tags: [],
		data_source: "candidate_profile"
	},
	templates: [],
	profilesIds: [],
	select_data: {
		mode: "",
		selected_candidates: [],
		selected_candidates_for_ShortList: [],
		include: [],
		exclude: [],
		preview_mode: null,
		shortList: []
	},
	isNext: false,
	isPrevious: false,
	selectedView: "cards",
	currentID: null,
	view: "",
	profile_id: ""
};

const usePoolStore = create((set, get) => ({
	...initialState,
	setActiveTemplate: template => {
		const { filters } = get();
		set({
			...get(),
			filters: {
				...filters,
				activeTemplate: template
					? {
							...template,
							label: _get(template, "name"),
							value: _get(template, "_id")
					  }
					: "",
				functions: _get(template, "functions", filters.functions),
				skills: _get(template, "skills", filters.skills),
				languages: _get(template, "languages", filters.languages),
				interviewed: _get(template, "interviewed", filters.interviewed),
				profile_tags: _get(template, "profile_tags", filters.profile_tags),
				data_source: _get(template, "data_source", filters.data_source),
				not_viewed_by_me: _get(
					template,
					"not_viewed_by_me",
					filters.not_viewed_by_me
				),
				not_viewed_by_my_company: _get(
					template,
					"not_viewed_by_my_company",
					filters.not_viewed_by_my_company
				),
				no_vacancy_sent: _get(
					template,
					"no_vacancy_sent",
					filters.no_vacancy_sent
				),
				source: {
					applied: _get(template, "source.applied", filters.source.applied),
					poked: _get(template, "source.poked", filters.source.poked),
					added: _get(template, "source.added", filters.source.added)
				},
				location: _get(template, "location", filters.location),
				distance: _get(template, "distance", filters.distance),
				keywords: _get(template, "keywords", filters.keywords),
				employment_types: _get(
					template,
					"employment_types",
					filters.employment_types
				)
			}
		});
	},
	updateFilter: (name, value) => {
		let url = window.location.pathname;
		let search = window.location.search;
		if (search && search.split("=")[0] === TEMPLATE) {
			window.history.pushState(
				{
					path: url
				},
				"",
				url
			);
		}

		const { filters } = get();

		let activeTemplate = undefined;

		if (name === "offset") {
			activeTemplate = filters.activeTemplate;
		}

		if (name === "location") {
			filters.distance = 0;
		}

		set({
			...get(),
			filters: {
				...filters,
				activeTemplate,
				offset: 0,
				[name]: value
			}
		});
	},
	deleteFilter: (name, value) => {
		let url = window.location.pathname;
		window.history.pushState(
			{
				path: url
			},
			"",
			url
		);
		const { filters } = get();
		set({
			...get(),
			filters: {
				...filters,
				activeTemplate: undefined,
				[name]: filters[name].filter(item => item._id !== value)
			}
		});
	},
	deletTag: tag => {
		const { filters } = get();
		set({
			...get(),
			filters: {
				...filters,
				activeTemplate: undefined,
				profile_tags: filters["profile_tags"].filter(item => item !== tag)
			}
		});
	},
	deleteFunction: deletedFunction => {
		const { filters } = get();

		const functionIndex = filters.functions.findIndex(
			singleFunction =>
				singleFunction._id === deletedFunction.id &&
				singleFunction.seniority === deletedFunction.seniority &&
				singleFunction.sector.parent_sector._id ===
					deletedFunction.parent_sector_id &&
				singleFunction.sector._id === deletedFunction.sector_id
		);

		set({
			...get(),
			filters: {
				...filters,
				functions: filters.functions.filter((_, i) => i !== functionIndex)
			}
		});
	},
	saveTemplates: value => {
		set({
			...get(),
			templates: value
		});
	},
	clearFilter: () => {
		let url = window.location.pathname;

		window.history.pushState(
			{
				path: url
			},
			"",
			url
		);

		set({
			...get(),
			filters: {
				...initialState.filters,
				activeTemplate: undefined
			}
		});
	},
	setKeywordSearch: data => {
		const { filters } = get();
		set({
			...get(),
			filters: {
				...filters,
				keywords: data
			}
		});
	},
	setProfilesIds: data => {
		set({
			...get(),
			profilesIds: data
		});
	},
	setIsNext: value => {
		set({
			...get(),
			isNext: value
		});
	},
	setIsPrevious: value => {
		set({
			...get(),
			isPrevious: value
		});
	},
	setSelectedView: value => {
		set({
			...get(),
			selectedView: value
		});
	},
	setCurrentId: value => {
		set({
			...get(),
			currentID: value
		});
	},
	setProfileId: value => {
		set({
			...get(),
			profile_id: value
		});
	},
	setView: value => {
		set({
			...get(),
			view: value
		});
	},
	setSelectData: value => {
		set({
			...get(),
			select_data: value
		});
	}
}));

export default usePoolStore;
