import React, { useState, useCallback } from "react";
import { Button } from "common/Button";
import { ReactComponent as DeleteIconRed } from "static/icons/delete-icon-red.svg";

import { ReactComponent as UploadIcon } from "static/icons/green-upload-icon.svg";
import { ReactComponent as GreyImage } from "static/icons/grey-image.svg";
import style from "./create-request-additional-info-card-upload-image.module.scss";
import { useDropzone } from "react-dropzone";
import _get from "lodash/get";
import { useMutation } from "react-query";
import { getOrientation } from "get-orientation/browser";
import Modal from "common/modal";
import { getRotatedImage } from "modules/company/components/avatar/utils.js";
import { SUPPORTED_SIZE } from "config";
import toaster from "common/Toaster";
import Loader from "common/Loader";
import { CreateRequestAdditionalInfoCropImage } from "../CreateRequestAdditionalInfoCropImage";
import { uploadToS3 } from "config/helpers";

const ORIENTATION_TO_ANGLE = {
	"3": 180,
	"6": 90,
	"8": -90
};

const CreateRequestAdditionalInfoCardUploadImage = ({
	picture,
	isLoading,
	setFeaturedImageUrl,
	onImageSubmitStart,
	onImageSubmitFinish
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [imageSrc, setImageSrc] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [isSaving, setIsSaving] = useState(false);

	const [upload] = useMutation(response =>
		uploadToS3({ response, file: croppedImage })
	);

	const onFileChange = async files => {
		if (files && files.length > 0) {
			const file = _get(files, "[0]");
			setFileName(_get(file, "name"));
			let imageDataUrl = await readFile(file);
			const orientation = await getOrientation(file);
			const rotation = ORIENTATION_TO_ANGLE[orientation];
			if (rotation) {
				imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
			}
			setImageSrc(imageDataUrl);
			setOpenModal(true);
		}
	};

	function readFile(file) {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	}

	const renderImageSrc = () =>
		Boolean(croppedImage) ? URL.createObjectURL(croppedImage) : picture;

	const onDrop = useCallback(onFileChange, []);
	const { open, getInputProps, getRootProps } = useDropzone({
		onDrop,
		accept: "image/*",
		maxSize: SUPPORTED_SIZE,
		maxFiles: 1,
		onDropRejected: () => toaster.danger("The Maximum size is 5MB")
	});

	const onDelete = () => {
		setCroppedImage(null);
		setFeaturedImageUrl("");
	};

	return (
		<>
			<input
				{...getInputProps()}
				style={{
					display: "none"
				}}
			/>
			<div className="upload-section">
				<div>
					{isLoading || isSaving ? (
						<Loader />
					) : (
						<div className={style.blockFeaturedImage}>
							{croppedImage || picture ? (
								<div className={style.uploadContainer}>
									<div {...getRootProps({ className: "" })}>
										<img
											className={style.image}
											alt="vacancy image"
											src={renderImageSrc()}
										/>

										<div className="drop-shadow" />
									</div>
									<Button
										className={style.uploadButton}
										icon={<UploadIcon />}
										text="Edit image"
										variant="text"
										onClick={open}
									/>
									<Button
										variant="text"
										text="Delete"
										icon={<DeleteIconRed className={style.deleteIcon} />}
										className={style.deleteButton}
										onClick={onDelete}
									/>
								</div>
							) : (
								<div className={style.uploadContainer}>
									<GreyImage />
									<Button
										className={style.uploadButton}
										icon={<UploadIcon />}
										text="Upload image"
										variant="text"
										onClick={open}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			{imageSrc && openModal && (
				<Modal
					fixed={true}
					onClose={() => {
						setOpenModal(false);
						setImageSrc(null);
					}}
					modalName={"crop"}
					padding="0"
					size="900px"
				>
					<CreateRequestAdditionalInfoCropImage
						imageSrc={imageSrc}
						setFeaturedImageUrl={setFeaturedImageUrl}
						setImageSrc={setImageSrc}
						fileName={fileName}
						setOpenModal={setOpenModal}
						setCroppedImage={setCroppedImage}
						upload={upload}
						uploadImage={() => {}}
						onFileChange={onFileChange}
						onMediaLoaded={img => {
							if (img.naturalWidth < 458 && img.naturalHeight < 305)
								return toaster.warning(
									"The dimensions of the image are small, the image may be pixelated, please follow the instructions."
								);
						}}
						cropSize={{ width: 600, height: 300 }}
						cropTitle=""
						cropOutlineColor="rgba(0, 0, 0, 0.5)"
						buttonUploadText="Upload new picture"
						zoomMax={5}
						setIsSaving={setIsSaving}
						onImageSubmitStart={onImageSubmitStart}
						onImageSubmitFinish={onImageSubmitFinish}
					/>
				</Modal>
			)}
		</>
	);
};

export default CreateRequestAdditionalInfoCardUploadImage;
