import _get from "lodash/get";
import React from "react";
import EditableSection from "../EditableSection";
import EmptyState from "modules/candidate/Profile/components/EmptyState";
import cardStyle from "../EditableSection/editable-section.module.scss";
import { ReactComponent as CVimg } from "static/icons/iconPdf.svg";
import { ReactComponent as Cloudimg } from "static/icons/VectordownloadSVG.svg";
import { ReactComponent as Dot } from "static/icons/Ellipsecircle.svg";

const Resumer = ({ profileData }) => {
	const sectionResume = _get(profileData, "update_sections.cv_docs");

	const cvDocs = _get(profileData, "personal_profile.cv_docs", []);

	const primaryCV = cvDocs.filter(cd => cd.is_primary);

	const fileName = primaryCV[0]?.file_name;
	const filePath = primaryCV[0]?.path;
	const motivation = profileData?.description;

	return (
		<React.Fragment>
			<EditableSection
				title="Resume (CV)"
				hasEdit={false}
				sectionUpdate={sectionResume}
			>
				<div className={cardStyle.cvContainer}>
					{primaryCV.length > 0 ? (
						<>
							<div className={cardStyle.resumerRow}>
								<CVimg width={62} height={62} />
								<div className={cardStyle.resumerCol}>
									<div className={cardStyle.resumerCloud}>
										<div className={cardStyle.resumerInfo}>{fileName}</div>
										<a href={filePath}>
											{" "}
											<Cloudimg />
										</a>
									</div>
									<div className={cardStyle.resumeType}>
										<p>Type: Resume</p>
										<Dot />
										<p>Pdf</p>
									</div>
								</div>
							</div>
							{motivation && (
								<>
									<div className={cardStyle.motivationTitle}>Motivation</div>
									<p className={cardStyle.motivationText}>{motivation}</p>
								</>
							)}
						</>
					) : (
						<EmptyState />
					)}
				</div>
			</EditableSection>
		</React.Fragment>
	);
};

export default Resumer;
