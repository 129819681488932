/* eslint-disable react/display-name */

import React, { useEffect } from "react";
import { useTour } from "@reactour/tour";
import styles from "../../components/TourContentComponent/tour-content-component.module.scss";
import TourContentComponent from "../../components/TourContentComponent";
import useOnboardingStore from "../../hooks/useOnboardingStore";
import Portal from "common/Portal";
import { HOW_TO_PUBLISH_VACANCY } from "../../../../config/onboarding";
import { browserHistory } from "react-router";
import fakeVacancies from "../../mockData/vacancies.json";
import fakeChannels from "../../mockData/channels-premium.json";
import isElementLoaded from "../../helpers/isElementLoaded";

const selectorForStep = stepNumber =>
	`[data-onboarding-step=${HOW_TO_PUBLISH_VACANCY}-${stepNumber}]`;

const steps = [
	{
		selector: selectorForStep(0),
		title: "Publish using actions",
		description:
			"Job actions provide the advantage of swiftly executing actions, such as publishing your job, without needing to access the vacancy details page.",
		stepInteraction: false,
		position: p => [p.right + 24, p.top - p.height],
		arrow: "left-bottom"
	},
	{
		selector: selectorForStep(1),
		title: "Access to the vacancy details",
		description:
			"Click to access the vacancy details page, where you can both publish your vacancy and gather more comprehensive information about it.h",
		stepInteraction: false,
		position: p => [p.right, p.top - p.height - 16],
		arrow: "bottom-left"
	},
	{
		selector: selectorForStep(2),
		title: "Publish vacancy",
		description: `Instantly publish your vacancy in a glance. click the 'Publish Vacancy' button and choose your preferred language version.`,
		arrow: "right-top",
		stepInteraction: false
	},
	{
		selector: selectorForStep(3),
		title: "Free channels",
		description:
			"Boost your reach with free channels! Access Wiggli's free channels and your own job pages for effortless publishing.",
		stepInteraction: false,
		position: p => [p.left + 300, p.top + 100],
		arrow: "top-left"
	},
	{
		selector: selectorForStep(4),
		title: "Contracts channels",
		description:
			"Effortlessly publish across your contracted channels. manage them via the Settings/Job Board Contract page.",
		stepInteraction: false,
		position: p => [p.left + 600, p.top - p.height],
		arrow: "bottom-left"
	},
	{
		selector: selectorForStep(5),
		title: "Paid channels",
		description:
			"Expand your reach! Discover other paid channels where you can publish your vacancy by exploring this section.",
		stepInteraction: false,
		position: p => [p.left + 600, p.top],
		arrow: "bottom-left"
	},
	{
		selector: selectorForStep(6),
		title: "Publish vacancy",
		description: `Ready to publish? Once you've selected your channels, simply click the "Publish" button to continue.`,
		stepInteraction: false,
		position: p => [p.right - p.width - 30, p.bottom + 16],
		arrow: "top-right"
	},
	{
		selector: selectorForStep(7),
		title: "Confirm and publish",
		description: `Ensure your campaign costs are accurate by clicking on the "Confirm and Publish" button as the final step.`,
		stepInteraction: false,
		position: p => [p.right - p.width - 50, p.bottom + 16],
		arrow: "top-right"
	}
];

export const usePublishVacancyTour = (props = {}) => {
	const store = useOnboardingStore();
	const tour = useTour();
	const firstVacancyId = fakeVacancies.data[0].id;

	useEffect(() => {
		if (store.workflowId === HOW_TO_PUBLISH_VACANCY) {
			if (!tour.isOpen && store.currentStep === 0) {
				tour.setSteps(steps);
				browserHistory.push("/vacancies/list");
			}

			if (
				!tour.isOpen &&
				store.currentStep === 0 &&
				props.vacancyItemId === firstVacancyId
			) {
				props.showVacancyItemMenu();
				setTimeout(() => {
					tour.setCurrentStep(0);
					tour.setIsOpen(true);
				}, 200);
			}

			if (
				store.currentStep === 1 &&
				tour.currentStep === 0 &&
				props.vacancyItemId === firstVacancyId
			) {
				tour.setCurrentStep(1);
			}

			if (
				store.currentStep === 2 &&
				tour.currentStep === 1 &&
				props.vacancyItemId === firstVacancyId
			) {
				browserHistory.push("/vacancy/fake-id");
			}

			if (
				store.currentStep === 2 &&
				tour.currentStep === 1 &&
				props.showVacancyItemDropdown
			) {
				props.showVacancyItemDropdown();
				isElementLoaded(selectorForStep(2)).then(() => {
					tour.setCurrentStep(2);
				});
			}

			if (store.currentStep === 3 && tour.currentStep === 2) {
				browserHistory.push(`/publish-vacancy/${firstVacancyId}`);
			}

			if (store.currentStep === 3 && tour.currentStep === 2) {
				isElementLoaded(selectorForStep(3)).then(() => {
					tour.setCurrentStep(3);
				});
			}

			if (store.currentStep === 4 && tour.currentStep === 3) {
				isElementLoaded(selectorForStep(4)).then(() => {
					tour.setCurrentStep(4);
				});
			}

			if (store.currentStep === 5 && tour.currentStep === 4) {
				isElementLoaded(selectorForStep(5)).then(() => {
					tour.setCurrentStep(5);
				});
			}

			if (store.currentStep === 6 && tour.currentStep === 5) {
				tour.setCurrentStep(6);
			}

			if (
				store.currentStep === 7 &&
				tour.currentStep === 6 &&
				props.openPublishModal &&
				!props.isPublishDrawerOpen
			) {
				props.openPublishModal();
			}

			if (
				store.currentStep === 7 &&
				tour.currentStep === 6 &&
				props.isPublishDrawerOpen
			) {
				setTimeout(() => {
					tour.setCurrentStep(7);
				}, 300);
			}
		}
	}, [
		store.workflowId,
		store.currentStep,
		tour.currentStep,
		tour.isOpen,
		props.isPublishDrawerOpen
	]);

	return null;
};

export const providerConfig = {
	ContentComponent: TourContentComponent,
	onClickMask: () => null,
	className: styles.popover,
	maskClassName: styles.mask,
	padding: 0,
	scrollSmooth: true,
	disableKeyboardNavigation: true,
	Wrapper: ({ children }) => <Portal>{children}</Portal>
};

export function useFakeSelectedChannels() {
	const { workflowId } = useOnboardingStore();

	if (workflowId === HOW_TO_PUBLISH_VACANCY) {
		return fakeChannels.data.slice(0, 3);
	} else return [];
}
