import { useMutation } from "react-query";
import { client } from "lib/api-client";
import {
	UPDATE_PROFILE_PERSONAL_INFO,
	CHECK_ACTIVATE_ACCOUNT
} from "config/api-endpoints";
import React, { useState } from "react";
import { withRouter } from "react-router";
import toaster from "common/Toaster";
import { get } from "lodash";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import Portal from "common/Portal";
import { LoaderFull } from "common/Loader";

const updateProfilePersonalInfo = data => {
	return client(UPDATE_PROFILE_PERSONAL_INFO, {
		body: {
			...data
		}
	});
};

export const SAVE_MAIL = "save_mail";
export const SAVE_PHONE_NUMBER = "save_phone_number";
export const SAVE_LINKEDIN_PROFILE = "save_linkedin_profile";
const MailPhoneSave = ({
	render,
	router,
	active_company,
	refetchProfileData,
	linkedInProfile,
	headline,
	id: profileId
}) => {
	const {
		params: { id }
	} = router;

	const headlinePhone = get(headline, "phone");
	const headlineEmail = get(headline, "email");

	const [phoneNumber, setPhoneNumber] = useState();
	const [mail, setMail] = useState("");
	const [linkedInUrl, setLinkedInUrl] = useState("");
	const [isEditMail, setIsEditMail] = useState(false);
	const [isEditPhone, setIsEditPhone] = useState(false);
	const [isEditLinkedIn, setIsEditLinkedIn] = useState(false);
	const [isSaveMailError, setIsSaveMailError] = useState(false);
	const [isSavePhoneError, setIsSavePhoneError] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [isMergeProfiles, setIsMergeProfiles] = useState(false);

	const [mutate] = useMutation(updateProfilePersonalInfo, {
		onSuccess: (_, arg) => {
			switch (Object.keys(arg)[1]) {
				case "email":
					setIsEditMail(false);
					toaster.success("Email address successfully added!");
					if (!openModal) refetchProfileData();
					setIsMergeProfiles(false);
					break;
				case "phone":
					setIsEditPhone(false);
					toaster.success("Phone number successfully added!");
					refetchProfileData();
					break;
				case "linked_in":
					setIsEditLinkedIn(false);
					toaster.success("LinkedIn profile link successfully added!");
					refetchProfileData();
					break;
				default:
					break;
			}
		},
		onError: (error, arg) => {
			const field = Object.keys(arg)[1];
			toaster.danger(get(get(error, "detail"), field));
			switch (field) {
				case "email":
					setIsSaveMailError(true);
					setIsMergeProfiles(false);
					break;
				case "phone":
					setIsSavePhoneError(get(get(error, "detail"), field));
					break;
				default:
					break;
			}
		}
	});

	const save = async action => {
		switch (action) {
			case SAVE_MAIL:
				try {
					const { exists, type, can_merge } = await client(
						CHECK_ACTIVATE_ACCOUNT,
						{
							body: {
								email: mail,
								tag: "email",
								company_id: active_company,
								id: id ? id : profileId
							}
						}
					);
					if (exists) {
						if (
							type === "consultancy" ||
							type === "client" ||
							type === "freelancer"
						) {
							toaster.danger("This email already exists!");
							setIsSaveMailError("This email already exists!");
						}
						if (type === "permanent") {
							if (can_merge) {
								setIsSaveMailError("This email is used for another profile!");
								setOpenModal(true);
								return;
							}
							toaster.danger("This email already exists!");
							setIsSaveMailError("This email already exists!");
						}
						return;
					}
					mutate({ id: profileId ? profileId : id, email: mail });
				} catch (error) {
					toaster.danger(get(get(error, "detail"), "email"));
				}

				break;
			case SAVE_PHONE_NUMBER:
				mutate({ id: profileId ? profileId : id, phone: phoneNumber });
				break;
			case SAVE_LINKEDIN_PROFILE:
				mutate({ id: profileId ? profileId : id, linked_in: linkedInUrl });
				break;
			default:
				break;
		}
	};

	const closeModal = () => setOpenModal(false);

	const cancelEdit = () => {
		setIsEditLinkedIn(false);
		setIsEditPhone(false);
		setIsEditMail(false);
		setMail(headlineEmail);
		setPhoneNumber(headlinePhone);
		setLinkedInUrl(linkedInProfile);
	};

	return [
		render({
			save,
			phoneNumber,
			mail,
			setPhoneNumber,
			setMail,
			setLinkedInUrl,
			linkedInUrl,
			isEditMail,
			setIsEditMail,
			isEditPhone,
			setIsEditPhone,
			isEditLinkedIn,
			setIsEditLinkedIn,
			isSaveMailError,
			isSavePhoneError,
			setIsSavePhoneError,
			setIsSaveMailError,
			cancelEdit
		}),
		<Portal key="existing_candidate_modal">
			<ConfirmationModal
				onClose={closeModal}
				active={openModal}
				modalName="existing_candidate"
				title="Existing Candidate"
				type={ConfirmationTypes.warning}
				warningText="This email address is used by another candidate profile!
	Do you want to merge both profiles?"
				firstButton={{
					action: () => {
						mutate({ id: profileId ? profileId : id, email: mail });
						setIsMergeProfiles(true);
						closeModal();
					},
					label: "Merge profiles",
					type: "warning"
				}}
				secondButton={{
					action: closeModal,
					label: "Cancel"
				}}
			/>
		</Portal>,
		<LoaderFull key="loader" loading={isMergeProfiles} />
	];
};

export default withRouter(MailPhoneSave);
