import React, { useEffect } from "react";
import { Overlay, useModalOverlay } from "react-aria";
import { Button } from "common/Button";
import styles from "./cant-delete-pipeline-step-modal.module.scss";
import { ReactComponent as NotificationIcon } from "static/icons/warning-circle.svg";
import { ReactComponent as CloseIcon } from "static/icons/cross-black.svg";
import { IconButton } from "common/IconButton";

const CantDeletePipelineStepModal = ({ onClose, open }) => {
	let ref = React.useRef();
	let { modalProps, underlayProps } = useModalOverlay(
		{ onClose },
		{ isOpen: open },
		ref
	);

	useEffect(() => {
		const onKeydown = function(evt) {
			if (evt.key === "Escape") {
				onClose();
			}
		};

		document.addEventListener("keydown", onKeydown);

		return () => {
			document.body.removeEventListener("keydown", onKeydown);
		};
	}, []);

	return (
		<Overlay>
			<div className={styles.wrapper} ref={ref} {...underlayProps}>
				<div {...modalProps} className={styles.container}>
					<div className={styles.header}>
						<h2 className={styles.title}>Delete step</h2>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</div>
					<div className={styles.body}>
						<NotificationIcon className={styles.notificationIcon} />
						<div className={styles.description}>
							<div className={styles.mainDescription}>
								This step can&apos;t be removed!
							</div>
							<div className={styles.secondaryDescription}>
								Either the step contains candidates, or it&apos;s not deletable
							</div>
						</div>
					</div>
					<div className={styles.buttons}>
						<Button
							rootClassName={styles.cancel}
							text="Cancel"
							variant="outlined"
							onClick={onClose}
						/>
					</div>
				</div>
			</div>
		</Overlay>
	);
};

export default CantDeletePipelineStepModal;
