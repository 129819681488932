import React from "react";
import moment from "moment";
import { CANDIDATE_DATA_BY_COLUMN_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { isEmpty } from "lodash";
import { ReactComponent as PublicIcon } from "static/icons/drawer-notes/publicIcon.svg";
import { ReactComponent as CustomIcon } from "static/icons/drawer-notes/gear.svg";
import { ReactComponent as PrivateIcon } from "static/icons/drawer-notes/privateIcon.svg";

export const formatNotesList = list => {
	return list.map(item => {
		return {
			id: item._id,
			Id: item._id,
			"Note type": item.note_type,
			"Vacancy title": item.job?.title,
			"Creation Date": moment(item.created_at * 1000).format("DD/MM/YYYY"),
			Candidate: !isEmpty(item.user)
				? `${item?.user?.first_name} ${item?.user?.last_name}`
				: "",
			"Created by": !isEmpty(item.created_by)
				? `${item.created_by.first_name} ${item.created_by.last_name}`
				: "",
			Content: item.description,
			Visibility: (
				<p className="visibility_render">
					{renderVisibility(item?.visibility)}
				</p>
			),
			user: item.user,
			vacancy: item.job,
			createdBy: item.created_by,
			visible: item?.visible,
			not_visible: item?.not_visible,
			visibility: item?.visibility
		};
	});
};
const renderVisibility = visibility => {
	switch (visibility) {
		case "public":
			return (
				<>
					<PublicIcon /> Public
				</>
			);
		case "custom":
			return (
				<>
					<CustomIcon /> Custom
				</>
			);
		case "private":
			return (
				<>
					<PrivateIcon /> Only me
				</>
			);
	}
};

export const toSingular = label => {
	return label
		.split(" ")
		.map((word, index) => {
			return index === 0 ? word.slice(0, -1) : word;
		})
		.join(" ");
};

const fetchData = ({ tag, search = "", type = "candidates" }) => {
	return client(CANDIDATE_DATA_BY_COLUMN_ENDPOINT, {
		body: {
			list: type,
			tag,
			search
		}
	});
};

export const getCategories = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "category", search })
			.then(res => {
				const list = res.map(({ name, _id }) => ({
					label: name,
					id: _id
				}));

				resolve([{ id: 1, label: "Categories", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getCreatedBy = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "created_by", type: "list_notes", search })
			.then(res => {
				const list = res.map(({ _id, first_name, last_name }) => ({
					label: `${first_name} ${last_name}`,
					id: _id
				}));
				resolve([{ id: 1, label: "Created by", children: list }]);
			})
			.catch(e => reject(e));
	});
};
export const getVisibility = () => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "visibility", type: "notes" })
			.then(res => {
				const list = res.map(({ label, value }) => ({
					label,
					id: value
				}));
				resolve([{ id: 1, label: "Visibility", children: list }]);
			})
			.catch(e => reject(e));
	});
};
export const getNoteType = () => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "note_type", type: "notes" })
			.then(res => {
				const list = res.map(({ label, value }) => ({
					label,
					id: value
				}));
				resolve([{ id: 1, label: "NoteType", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const isNotesFiltersFull = filters => {
	let list = [];
	if (filters) {
		list = filters.filter(({ value }) => !isNoteFilterEmpty(value));
	}
	return list.length > 0;
};

export const isNoteFilterEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};

export const getCandidate = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "profile", type: "list_notes", search })
			.then(res => {
				const list = res.map(data => ({
					label: `${data.first_name} ${data.last_name}`,
					id: data._id
				}));
				resolve([{ id: 1, label: "Candidate", children: list }]);
			})
			.catch(e => reject(e));
	});
};
