import React from "react";
import GlobalTooltip from "common/GlobalTooltip";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./styles.scss";

const SettingsSwitcher = props => {
	const {
		name,
		style,
		label,
		checked,
		onChange,
		classes,
		disabled,
		overlayText = "",
		tooltipIsActive = false
	} = props;
	return (
		<div className={classes} style={style}>
			<Toggle
				id={name}
				checked={checked}
				icons={false}
				onChange={onChange}
				name={name}
				disabled={disabled}
			/>
			{label && (
				<GlobalTooltip
					noClassName
					placement="top"
					overlay={overlayText}
					withWrappingDiv={false}
					active={tooltipIsActive}
					overlayClassName={"g-tool-dark"}
				>
					<label htmlFor={name} style={{ opacity: !disabled ? "100%" : "40%" }}>
						{label}
					</label>
				</GlobalTooltip>
			)}
		</div>
	);
};

SettingsSwitcher.propTypes = {
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string
};

export default SettingsSwitcher;

export function InlineSwitcher({ options, onChange, value }) {
	return (
		<div className="inline-toggle">
			{options.map(item => {
				return (
					<div
						key={item.value}
						className={`inline-toggle-item ${
							item.value === value ? "enabled" : "disabled"
						}`}
						onClick={() => {
							onChange(item.value);
						}}
					>
						{item.label}
					</div>
				);
			})}
		</div>
	);
}
