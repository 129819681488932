import React from "react";
import {
	StyledSolidButton,
	StyledOutlineButton,
	StyledOutlineEditButton,
	StyledIconButton,
	StyledSplitButton,
	Label,
	StyledButtonGroup,
	StyledButtonsContainer,
	StyledOutlinedLinkButton,
	StyledLinkButton
} from "./style";

// hack to convert desired props to string to fix warnings
const filterProps = (
	props,
	filter = ["small", "large", "block", "has-icon"]
) => {
	let newProps = {};
	Object.keys(props).forEach(key => {
		if (filter.indexOf(key) !== -1)
			return (newProps[key] = props[key].toString());
		return (newProps[key] = props[key]);
	});
	return newProps;
};

export const Button = React.forwardRef((props, forwardedRef) => (
	<StyledSolidButton
		disabled={props.disabled || props.loading}
		ref={forwardedRef}
		{...filterProps(props)}
	>
		<Label loading={props.loading}>{props.children}</Label>
	</StyledSolidButton>
));
Button.displayName = "Button";

export const ButtonsContainer = props => (
	<StyledButtonsContainer {...filterProps(props)} />
);

export const OutlineButton = React.forwardRef((props, forwardedRef) => (
	<StyledOutlineButton
		disabled={props.disabled || props.loading}
		inactive={props.inactive}
		ref={forwardedRef}
		{...filterProps(props)}
	>
		<Label loading={props.loading} has-icon={props.icon}>
			{props.children}
		</Label>
	</StyledOutlineButton>
));
OutlineButton.displayName = "OutlineButton";

export const OutlineEditButton = props => (
	<StyledOutlineEditButton
		disabled={props.disabled || props.loading}
		inactive={props.inactive}
		{...filterProps(props)}
	>
		<Label loading={props.loading} has-icon={props.icon}>
			{props.children}
		</Label>
	</StyledOutlineEditButton>
);

export const LinkButton = props => (
	<StyledLinkButton
		disabled={props.disabled || props.loading}
		{...filterProps(props)}
	>
		<Label loading={props.loading}>{props.children}</Label>
	</StyledLinkButton>
);

export const OutlinedLink = props => (
	<StyledOutlinedLinkButton
		disabled={props.disabled || props.loading}
		{...filterProps(props)}
	>
		<Label loading={props.loading} has-icon={props.icon}>
			{props.children}
		</Label>
	</StyledOutlinedLinkButton>
);

export const ButtonGroup = props => (
	<StyledButtonGroup {...filterProps(props)}>
		{props.children}
	</StyledButtonGroup>
);

export const SplitButton = props => (
	<StyledSplitButton
		disabled={props.disabled || props.loading}
		{...filterProps(props)}
	>
		<Label loading={props.loading} has-icon={props.icon}>
			{props.children}
		</Label>
	</StyledSplitButton>
);

export const IconButton = props => <StyledIconButton {...filterProps(props)} />;
