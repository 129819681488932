import React from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { ReactComponent as FilterIcon } from "static/icons/Iconfilter.svg";
import styles from "./notes-header.module.scss";
import { SearchInput } from "common/SearchInput";
import cx from "classnames";
import { ReactComponent as SortDesc } from "static/icons/sort-desc.svg";
import { ReactComponent as SortAsc } from "static/icons/sort-asc.svg";
import { Button } from "common/Button";
import { ReactComponent as AddIcon } from "static/icons/add-icon-white.svg";
import { validFiltersCount } from "../util/helpers";
import { ReactComponent as Arrow } from "static/icons/ArrowIcon.svg";
import { HOW_TO_WORK_WITH_NOTES } from "config/onboarding";
import { useWorkWithNotesTour } from "modules/user-onboarding/workflows/work-with-notes";

const NotesHeader = ({
	className,
	onAddNoteClick,
	toggleRecap,
	showRecap,
	filterId
}) => {
	const { setShowDrawer, setQuery, getState, setSortBy } = useFilters();
	const { queryError, query, sortBy, filters } = getState(filterId);

	const handleFilterClick = () => {
		setShowDrawer(true);
	};

	const handleQueryChange = e => {
		setQuery(e.target.value);
	};

	const handleSortClick = () => {
		if (Object.keys(sortBy).length === 0) {
			setSortBy({ asc: true });
		} else {
			setSortBy({});
		}
	};

	const isSortDesc = Object.keys(sortBy).length === 0;

	useWorkWithNotesTour({
		openAddNoteDialog: onAddNoteClick
	});

	return (
		<div className={cx(styles.container, className)}>
			<SearchInput
				data-onboarding-step={`${HOW_TO_WORK_WITH_NOTES}-3`}
				className={cx(styles.search, {
					[styles.error]: queryError
				})}
				onChange={handleQueryChange}
				placeholder="Search"
				variant="fixed_width"
				value={query}
			/>
			<HeaderButton
				icon={<FilterIcon />}
				text="Filter"
				className="btnAddFilter"
				onClick={handleFilterClick}
			/>
			<div className={styles.recapButtonContainer}>
				{validFiltersCount(filters) > 0 && (
					<button className={styles.recap_btn} onClick={toggleRecap}>
						<span>{validFiltersCount(filters)}</span>
						<Arrow
							className={
								showRecap ? `${styles.arrow} ${styles.up}` : styles.arrow
							}
						/>
					</button>
				)}
			</div>
			<Button
				rootClassName={styles.sortIcon}
				variant="text"
				icon={isSortDesc ? <SortDesc /> : <SortAsc />}
				text="Date"
				onClick={handleSortClick}
			/>
			<Button
				rootClassName={styles.addIcon}
				variant="contained"
				icon={<AddIcon stroke="#FFFFFF" />}
				text="Add note"
				onClick={onAddNoteClick}
				textClassName={styles.buttonText}
				data-onboarding-step={`${HOW_TO_WORK_WITH_NOTES}-4`}
			/>
		</div>
	);
};

export default NotesHeader;
