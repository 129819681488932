import React, { useEffect } from "react";
import EditableBox from "../EditableBox";
import { useForm } from "react-hook-form";
import { useToggle } from "react-use";
import { useQueryCache } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import _isEmpty from "lodash/isEmpty";
import _every from "lodash/every";
import useUpdateCompanyAdditionalInfo from "../../../api/useUpdateCompanyAdditionalInfo";
import toaster from "common/Toaster";
import { getCurrentCompanyQueryKey } from "../../../api/useGetCompany";
import ContactsForm from "./ContactsForm";
import ContactsData from "./ContactsData";

const schema = object().shape({
	msp_contact_person: object().shape({
		first_name: string(),
		last_name: string(),
		phone: object().shape({
			displayValue: string()
		}),
		email: string().email("Please enter a valid email address.")
	}),
	commercial_contact_person: object().shape({
		first_name: string(),
		last_name: string(),
		phone: object().shape({
			displayValue: string()
		}),
		email: string().email("Please enter a valid email address.")
	})
});

function Contacts({ data }) {
	const {
		handleSubmit,
		register,
		control,
		reset,
		formState: { errors },
		setError
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema),
		defaultValues: {
			msp_contact_person: data?.msp_contact_person,
			commercial_contact_person: data?.commercial_contact_person
		}
	});

	const [isEdit, toggle] = useToggle(false);
	const queryCache = useQueryCache();

	const [update, { isLoading }] = useUpdateCompanyAdditionalInfo({
		onSuccess() {
			toaster.success("Description updated.");
			queryCache.invalidateQueries([getCurrentCompanyQueryKey]);
			toggle(false);
		},
		onError(err) {
			Object.keys(err.detail).forEach(item =>
				setError(`${item}.phone`, { message: `${err.detail[item][0]}` })
			);
		}
	});

	useEffect(() => {
		reset({
			msp_contact_person: {
				...data?.msp_contact_person,
				phone: {
					displayValue: data?.msp_contact_person?.phone?.split(" ")?.[1]
				}
			},
			commercial_contact_person: {
				...data?.commercial_contact_person,
				phone: {
					displayValue: data?.commercial_contact_person?.phone?.split(" ")?.[1]
				}
			}
		});
	}, [data]);

	const onSubmit = fields => {
		const removeFirstZero = phone => {
			if (phone.charAt(0) === "0") return phone.substring(1);
			return phone;
		};
		const msp_phone = _every(
			fields.msp_contact_person.phone.displayValue,
			_isEmpty
		)
			? ""
			: fields.msp_contact_person.phone?.countryData?.iso2 +
			  " +" +
			  fields.msp_contact_person?.phone?.countryData?.dialCode +
			  removeFirstZero(
					fields.msp_contact_person.phone?.displayValue?.replace(
						`+${fields.msp_contact_person?.phone?.countryData?.dialCode}`,
						""
					)
			  );

		const commercial_phone = _every(
			fields.commercial_contact_person.phone.displayValue,
			_isEmpty
		)
			? ""
			: fields.commercial_contact_person.phone?.countryData?.iso2 +
			  " +" +
			  fields.commercial_contact_person?.phone?.countryData?.dialCode +
			  removeFirstZero(
					fields.commercial_contact_person.phone?.displayValue?.replace(
						`+${fields.commercial_contact_person?.phone?.countryData?.dialCode}`,
						""
					)
			  );

		update({
			commercial_contact_person: {
				...fields.commercial_contact_person,
				phone: commercial_phone
			},
			msp_contact_person: {
				...fields.msp_contact_person,
				phone: msp_phone
			}
		});
	};

	return (
		<EditableBox
			name={"Contacts"}
			displayValue={<ContactsData data={data} />}
			onSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			emptyMessage={"Click the button on the top right corner to add contacts."}
			isEdit={isEdit}
			toggle={toggle}
		>
			<ContactsForm control={control} register={register} errors={errors} />
		</EditableBox>
	);
}

export default Contacts;
