import React from "react";
import { usePaginatedQuery } from "react-query";
import NotesList from "./paginations";
import { client } from "lib/api-client";
import { VIEW_USER_NOTES } from "config/api-endpoints";
import { withRouter } from "react-router";
import get from "lodash/get";
import styles from "modules/candidate/components/profileClientView/profileModalTab/components/notes/note.module.scss";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { HOW_TO_WORK_WITH_NOTES } from "config/onboarding";
import fakeNotes from "modules/user-onboarding/mockData/notes.json";

const getNotes = (_key, { id }) => {
	return client(VIEW_USER_NOTES, {
		body: {
			id,
			limit: 5,
			tag: "profiles_notes"
		}
	});
};

const Notes = ({ router, profileID, onSeeMoreClick, useScroll }) => {
	let {
		params: { id }
	} = router;
	if (!id || profileID) {
		id = profileID;
	}

	const { workflowId } = useOnboardingStore();
	const fetchData =
		workflowId === HOW_TO_WORK_WITH_NOTES
			? () => new Promise(r => r(fakeNotes))
			: getNotes;

	//TODO Create hook for this
	const { resolvedData, isFetching, isLoading } = usePaginatedQuery(
		[
			"get-notes",
			{
				// limit: offset,
				id
			},
			workflowId
		],
		fetchData,
		{
			refetchOnWindowFocus: false
		}
	);
	const notes = get(resolvedData, "data", []);
	return (
		<div className={styles.notesContainer}>
			<div className={styles.btnView}>
				<NotesList
					isFetching={isFetching}
					notes={notes}
					isLoading={isLoading}
					routeName={router.location.pathname}
					onSeeMoreClick={onSeeMoreClick}
					useScroll={useScroll}
				/>
			</div>
			{/* <CandidateActionsDrawer job={get(pokeData, "job")} poke={pokeData} /> */}
		</div>
	);
};

export default withRouter(Notes);
