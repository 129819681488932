import React, { forwardRef, memo } from "react";
import cx from "classnames";
import { CustomDropDown } from "common/CustomDropDown";
import styles from "./add-vacancy-custom-dropdown.module.scss";

const AddVacancyCustomDropdown = ({ inputClassName, ...props }, ref) => {
	return (
		<CustomDropDown
			{...props}
			inputClassName={cx(styles.input, inputClassName)}
			ref={ref}
		/>
	);
};

export default memo(forwardRef(AddVacancyCustomDropdown));
