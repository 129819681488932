import React from "react";
const SkillsIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<path
				className="notification-icon"
				d="M361.9,180.3c-1.7,0.2-3.1,0.8-4.1,1.7c3.1-3.4,2.1-11.7-1.6-18.1c-6.4-3.8-14.8-4.7-18.1-1.6
		c0.9-1,1.5-2.4,1.7-4.1L226,272l-45.5,45.5l22.1,22.1l89.8-89.8L361.9,180.3z M361.9,180.3"
			/>
			<path
				className="notification-icon"
				d="M159.5,338.4l20.9-20.9l22.1,22.1l-20.9,20.9L159.5,338.4z M159.5,338.4"
			/>
			<path
				className="notification-icon"
				d="M181.7,360.6l-22.1-22.1l-19.8,19.8c-7.6,7.6-9.3,16-4.3,21l5.5,5.5c5,5,13.4,3.2,21-4.3L181.7,360.6z
		 M181.7,360.6"
			/>
			<path
				className="notification-icon"
				d="M375.8,144.3l-33.4,11.2c5.9-0.8,10.7,3.2,13.8,8.4c5.2,3,9.1,7.8,8.4,13.8L375.8,144.3z M375.8,144.3"
			/>
			<g>
				<defs>
					<rect
						id="SVGID_1_"
						x="105.8"
						y="106.9"
						width="288.2"
						height="288.2"
					/>
				</defs>
				<path
					className="notification-icon-origin"
					d="M391.8,341.2l-21.1-21.1c0,0,0,0,0,0c0,0,0,0,0,0l-70.3-70.3l68.1-68.1c0,0,0,0,0,0c0.3-0.3,0.6-0.6,0.8-1
			c0,0,0,0,0-0.1c0.1-0.2,0.2-0.3,0.3-0.5c0,0,0,0,0-0.1c0.1-0.2,0.2-0.4,0.3-0.7l21.2-63.1c0.7-2,0.2-4.3-1.4-5.8
			c-1.5-1.5-3.7-2-5.8-1.4l-63.2,21.3c0,0,0,0,0,0c-0.2,0.1-0.4,0.1-0.6,0.2c0,0,0,0-0.1,0c-0.2,0.1-0.3,0.2-0.5,0.3
			c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-0.1,0.1-0.1,0.1L250.6,200
			l-91.4-91.4c-2.2-2.2-5.8-2.2-8,0l-44.3,44.3c-1.1,1.1-1.7,2.5-1.7,4c0,1.5,0.6,2.9,1.7,4l91.4,91.4l-41.5,41.5c0,0,0,0,0,0
			c0,0,0,0,0,0L116,334.5c-9.8,9.8-11.6,21.7-4.3,29l25.2,25.2c2.9,2.9,6.7,4.5,10.9,4.5h0c6,0,12.4-3.1,18-8.8l19.8-19.8
			c0,0,0,0,0,0l20.9-20.9c0,0,0,0,0,0c0,0,0,0,0,0l41.5-41.6l91.4,91.4c1.1,1.1,2.5,1.6,4,1.6c1.4,0,2.9-0.5,4-1.6l44.3-44.3
			c1.1-1.1,1.7-2.5,1.7-4C393.4,343.7,392.8,342.2,391.8,341.2L391.8,341.2z M202.6,331.7l-33.9-33.9l41.5-41.5c0,0,0,0,0,0
			c0,0,0,0,0,0l44.3-44.3c0,0,0,0,0,0c0,0,0,0,0,0l70.5-70.5c3,0.5,5.3,3.4,6.4,5.3c3.2,5.3,3.2,10.7,2.5,11.7L204.1,288.3
			c-2.2,2.2-2.2,5.8,0,8c1.1,1.1,2.5,1.6,4,1.6c1.4,0,2.9-0.5,4-1.6l129.8-129.8c1.1-0.7,6.5-0.7,11.8,2.5c1.9,1.1,4.8,3.4,5.3,6.4
			L202.6,331.7z M181.7,352.6l-33.9-33.9l13-13l33.9,33.9L181.7,352.6z M363.7,162.5c-1.7-1.6-3.8-3-6.1-4.2
			c-3.6-1.9-7.9-3.2-12.1-3.4c-0.2-4.2-1.6-8.5-3.4-12.1c-1.2-2.3-2.6-4.4-4.2-6.1l39-13.1L363.7,162.5z M118.8,156.8l36.3-36.3
			l11.3,11.3l-13,13c-1.1,1.1-1.7,2.5-1.7,4c0,1.4,0.6,2.9,1.7,4c1.1,1.1,2.5,1.7,4,1.7c1.4,0,2.9-0.6,4-1.7l13-13l11.3,11.3
			l-7.9,7.9c-1.1,1.1-1.6,2.5-1.6,4c0,1.4,0.5,2.9,1.6,4c1.1,1.1,2.5,1.6,4,1.6c1.4,0,2.9-0.5,4-1.6l7.9-7.9l11.3,11.3l-7.9,7.9
			c-1.1,1.1-1.6,2.5-1.6,4c0,1.4,0.5,2.9,1.6,4c1.1,1.1,2.5,1.6,4,1.6c1.4,0,2.9-0.5,4-1.6l7.9-7.9l11.3,11.3l-13,13
			c-1.1,1.1-1.7,2.5-1.7,4c0,1.4,0.5,2.9,1.7,4c1.1,1.1,2.5,1.6,4,1.6c1.4,0,2.9-0.6,4-1.6l13-13l10.5,10.5l-36.3,36.3L118.8,156.8z
			 M157.9,376.4c-3.4,3.4-7.2,5.5-10.1,5.5c0,0,0,0,0,0c-1.2,0-2.2-0.4-3-1.2l-25.2-25.2c-2.5-2.5-0.7-8,4.3-13l15.8-15.8l33.9,33.9
			L157.9,376.4z M343.5,381.5l-87.4-87.4l5.2-5.2l15.9,15.9c1.1,1.1,2.5,1.7,4,1.7c1.4,0,2.9-0.6,4-1.7c2.2-2.2,2.2-5.8,0-8
			l-15.9-15.9l23.2-23.2l8.6,8.6l-7.9,7.9c-1.1,1.1-1.7,2.5-1.7,4c0,1.4,0.6,2.9,1.7,4c1.1,1.1,2.5,1.7,4,1.7c1.4,0,2.9-0.6,4-1.6
			l7.9-7.9l11.3,11.3l-7.9,7.9c-1.1,1.1-1.7,2.5-1.7,4c0,1.4,0.6,2.9,1.7,4c1.1,1.1,2.5,1.6,4,1.6c1.4,0,2.9-0.6,4-1.6l7.9-7.9
			l11.3,11.3l-13,13c-1.1,1.1-1.6,2.5-1.6,4c0,1.4,0.5,2.9,1.6,4c1.1,1.1,2.5,1.6,4,1.6c1.4,0,2.9-0.5,4-1.6l13-13l11.3,11.3
			l-7.9,7.9c-1.1,1.1-1.7,2.5-1.7,4c0,1.4,0.6,2.9,1.7,4c1.1,1.1,2.5,1.7,4,1.7c1.4,0,2.9-0.6,4-1.7l7.9-7.9l13.2,13.2L343.5,381.5z
			 M343.5,381.5"
				/>
			</g>
			<path d="M295.3,306.9" />
		</g>
	</svg>
);
export default SkillsIcon;
