import React from "react";
import { CreateVacancySkeletonCard } from "../CreateVacancySkeletonCard";
import styles from "./create-vacancy-vacancy-step-loading.module.scss";
import { AddVacancyApplicantsCardLoading } from "../AddVacancyApplicantsCardLoading";
import { AddVacancySalaryCardLoading } from "../AddVacancySalaryCardLoading";
import { AddVacancyAdditionalInfoCardLoading } from "../AddVacancyAdditionalInfoCardLoading";
import { AddVacancyDescriptionCardLoading } from "../AddVacancyDescriptionCardLoading";

const CreateVacancyVacancyStepLoading = () => {
	return (
		<>
			<div className={styles.stepper} />
			<div className={styles.cardsList}>
				<AddVacancyDescriptionCardLoading />
				<AddVacancyApplicantsCardLoading />
				<AddVacancySalaryCardLoading />
				<AddVacancyAdditionalInfoCardLoading />
				<div className={styles.nextButtonContainer}>
					<CreateVacancySkeletonCard width={109.03} />
					<CreateVacancySkeletonCard width={144.36} />
				</div>
			</div>
		</>
	);
};

export default CreateVacancyVacancyStepLoading;
