/* TODO Move onPress to CustomInput */
// TODO Replace checkbox allow submission
/* eslint-disable react/display-name */
import React, { useState, memo, forwardRef, useMemo, useCallback } from "react";
import {
	DAILY,
	REMOTE_ELIGIBITY_OPTIONS,
	CONTRACT_TYPE,
	HYBRID_OPTION
} from "config";
import CreateRequestModal from "../CreateRequestCard/CreateRequestCard";
import { CreateRequestCardContent } from "../CreateRequestCardContent";
import { CreateRequestCardTitle } from "../CreateRequestCardTitle";
import { CreateRequestInfoIcon } from "../CreateRequestInfoIcon";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { CreateRequestCustomInput } from "../CreateRequestCustomInput";
import styles from "./create-request-mission-details-card.module.scss";
import Checkbox from "common/AgTable/components/Checkbox/Checkbox";

import { Controller } from "react-hook-form";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import { JobAddressInput } from "../JobAddressInput";
import { RadioGroup } from "common/RadioGroup";
import { DAILY_HOURLY, VACANCY_STEP_CARDS_IDS } from "../../utils/constants";
import { ComboBox } from "common/ComboBox";
import { useGetApplicantLocations } from "modules/CreateRequestModule/hooks/useGetApplicantLocations";
import { Item } from "react-stately";
import CurrencyField from "common/NewCurrencyField";
import { CreateRequestDatesFields } from "../CreateRequestDatesFields";
import { CreateRequestTimeField } from "../CreateRequestTimeField";
import TimePicker from "common/TimePicker";
import loadable from "loadable-components";
import useElements from "hooks/useLayoutElements";
import { TIMESHEET_FREQUENCY_OPTIONS } from "config";
import { useGetCurrenciesList } from "modules/CreateRequestModule/api/useGetCurrenciesList";

const JobAddressPickerModal = loadable(() =>
	import("../JobAddressPickerModal/JobAddressPickerModal")
);

const CreateRequestMissionDetailsCard = ({
	control,
	setValue,
	watch,
	vacancyAddress,
	vacancyAddressType,
	errors,
	remoteEligibility,
	applicantLocation,
	rate,
	currency,
	currencySetting
}) => {
	const { mainElement } = useElements();
	const [displayAddressPicker, setDisplayAddressPicker] = useState(false);
	const locationsList = useGetApplicantLocations();
	const { data: currenciesList } = useGetCurrenciesList();

	const locationsItems = useMemo(() => {
		return locationsList.map(({ value, label }) => (
			<Item key={value.id}>{label}</Item>
		));
	}, [locationsList]);

	const currenciesItems = useMemo(() => {
		return (currenciesList ?? []).map(c => (
			<Item key={c.code} textValue={`${c.symbol} - ${c.name}`}>
				{c.symbol} - {c.name}
			</Item>
		));
	}, [currenciesList]);

	const selectedCurrency = useMemo(() => {
		const code = currency?.value;
		if (Array.isArray(currenciesList) && code) {
			return currenciesList.find(c => c.code === code);
		}
	}, [currenciesList, currency, currencySetting]);

	const onAddressEdit = () => setDisplayAddressPicker(state => !state);

	const onJobAddressChange = ({ value, type }, onChange) => {
		onChange(value);
		setValue("vacancyAddressType", type);
		setDisplayAddressPicker(false);
	};

	const onAddressDialogCancel = () => {
		setDisplayAddressPicker(false);
	};

	const onDaysPerWeekChange = (e, onChange) => {
		if ((e.target.value >= 1 && e.target.value <= 5) || e.target.value === "")
			onChange(e);
	};

	const onLocationSelectionChange = useCallback(
		(key, onChange) => {
			const _object = locationsList.find(({ value }) => value.id === key);

			const value = {
				label: _object?.label ?? "",
				value: _object?.value
			};

			onChange(value);
		},
		[locationsList]
	);

	const onLocationInputChange = useCallback(
		(value, onChange) => {
			const _object = {
				label: value,
				value: value === "" ? null : applicantLocation?.value
			};

			onChange(_object);
		},
		[applicantLocation?.value]
	);

	const onCurrencySelectionChange = useCallback(
		(key, onChange) => {
			const _object = currenciesList.find(c => c.code === key);

			const value = {
				label: _object?.name ? `${_object?.symbol} - ${_object?.name}` : "",
				value: _object?.code
			};

			onChange(value);
		},
		[currenciesList]
	);

	const onCurrencyInputChange = useCallback(
		(value, onChange) => {
			const _object = {
				label: value,
				value: value === "" ? currencySetting?.code : currency?.value
			};

			onChange(_object);
		},
		[currency?.value]
	);

	return (
		<CreateRequestModal
			className={styles.container}
			id={VACANCY_STEP_CARDS_IDS.missionDetails}
		>
			<CreateRequestCardTitle>Mission details</CreateRequestCardTitle>
			<CreateRequestCardContent className={styles.cardContent}>
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>Contract type</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<Controller
						name="contractType"
						control={control}
						render={({ field: { onChange, value } }) => (
							<RadioGroup
								className={styles.radioButtonContainer}
								options={CONTRACT_TYPE}
								value={value}
								onChange={e => {
									onChange(e.target.value);
								}}
							/>
						)}
					/>
					<CreateRequestFieldError error={errors.remoteEligibility} />
				</CreateRequestFieldContainer>
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>
							Remote eligibility
						</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<Controller
						name="remoteEligibility"
						control={control}
						render={({ field: { onChange, value } }) => (
							<RadioGroup
								className={styles.radioButtonContainer}
								options={REMOTE_ELIGIBITY_OPTIONS}
								value={value}
								onChange={e => {
									onChange(e.target.value);
								}}
							/>
						)}
					/>
					<CreateRequestFieldError error={errors.remoteEligibility} />
				</CreateRequestFieldContainer>
				{remoteEligibility === HYBRID_OPTION.value && (
					<CreateRequestFieldContainer>
						<CreateRequestLabelContainer>
							<CreateRequestFieldLabel displayStar={false}>
								Days in the office
							</CreateRequestFieldLabel>
						</CreateRequestLabelContainer>
						<Controller
							name="daysPerWeek"
							control={control}
							render={({ field: { value, onChange } }) => (
								<div className={styles.daysPerWeekContainer}>
									<CreateRequestCustomInput
										className={styles.daysPerWeekInput}
										value={value}
										onChange={e => onDaysPerWeekChange(e, onChange)}
									/>
									<div className={styles.daysInOfficeLabel}>
										days in office/week
									</div>
								</div>
							)}
						/>
						<CreateRequestFieldError error={errors.daysPerWeek} />
					</CreateRequestFieldContainer>
				)}
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>
							Applicants must be based in
						</CreateRequestFieldLabel>
						<CreateRequestInfoIcon>
							Applicants based elsewhere than in the selected location(s) will
							be rejected automatically
						</CreateRequestInfoIcon>
					</CreateRequestLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="applicantLocation"
							control={control}
							render={({ field: { value, onChange, ref } }) => (
								<LocationsController
									popoverContainer={mainElement}
									value={value}
									onChange={onChange}
									onLocationSelectionChange={onLocationSelectionChange}
									onLocationInputChange={onLocationInputChange}
									locationsItems={locationsItems}
									ref={ref}
								/>
							)}
						/>
					</div>
					<CreateRequestFieldError error={errors.applicantLocation} />
				</CreateRequestFieldContainer>
				<CreateRequestDatesFields
					startDate={watch("startDate")}
					startDateError={errors.startDate}
					endDateError={errors.endDate}
					control={control}
					errors={errors}
				/>

				<CreateRequestTimeField control={control} error={errors.time} />

				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>Location</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<Controller
						name="vacancyAddress"
						control={control}
						render={({ field: { ref, onChange } }) => (
							<>
								<JobAddressInput
									ref={ref}
									value={vacancyAddress?.label}
									onEdit={onAddressEdit}
									className={
										displayAddressPicker ? styles.jobAddressInputFocused : ""
									}
								/>
								{displayAddressPicker && (
									<JobAddressPickerModal
										onSave={value => onJobAddressChange(value, onChange)}
										value={vacancyAddress}
										addressType={vacancyAddressType}
										onCancel={onAddressDialogCancel}
									/>
								)}
							</>
						)}
					/>
					<CreateRequestFieldError error={errors.vacancyAddress} />
				</CreateRequestFieldContainer>

				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>
							Timesheet frequency
						</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<Controller
						name="timesheetFrequency"
						control={control}
						render={({ field: { onChange, value } }) => (
							<RadioGroup
								className={styles.radioButtonContainer}
								options={TIMESHEET_FREQUENCY_OPTIONS}
								value={value}
								onChange={e => {
									onChange(e.target.value);
								}}
							/>
						)}
					/>
					<CreateRequestFieldError error={errors.remoteEligibility} />
				</CreateRequestFieldContainer>

				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>Rate</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<Controller
						name="rate"
						control={control}
						render={({ field: { onChange, value } }) => (
							<RadioGroup
								className={styles.radioButtonContainer}
								options={DAILY_HOURLY}
								value={value}
								onChange={e => {
									onChange(e.target.value);
								}}
							/>
						)}
					/>
					<CreateRequestFieldError error={errors.remoteEligibility} />
				</CreateRequestFieldContainer>

				{rate === DAILY && (
					<>
						<CreateRequestFieldContainer>
							<Controller
								name="hours"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TimePicker
										onChange={onChange}
										value={value}
										name="hoursPerDay"
										className={styles.timePicker}
									/>
								)}
							/>
							<CreateRequestFieldError error={errors.hours} />
						</CreateRequestFieldContainer>
						<CreateRequestFieldContainer>
							<div className={styles.allowSubmissionContainer}>
								<Controller
									name="allowSubmission"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Checkbox
											onChange={() => onChange(!value)}
											checked={value}
											id="allowSubmission"
											className={styles.allowSubmissionCheckbox}
										/>
									)}
								/>
								<div className={styles.allowSubmissionText}>
									Allow submission of overtime (hourly input), on top of the
									amount of hours per day.
								</div>
							</div>
						</CreateRequestFieldContainer>
					</>
				)}
				<div className={styles.rateFieldsContainer}>
					<CreateRequestFieldContainer
						className={styles.currencyFieldContainer}
					>
						<CreateRequestLabelContainer>
							<CreateRequestFieldLabel>Currency</CreateRequestFieldLabel>
						</CreateRequestLabelContainer>
						<div className={styles.inputContainer}>
							<Controller
								name="currency"
								control={control}
								render={({ field: { value, onChange, ref } }) => (
									<CurrenciesController
										popoverContainer={mainElement}
										value={value}
										onChange={onChange}
										onCurrencySelectionChange={onCurrencySelectionChange}
										onCurrencyInputChange={onCurrencyInputChange}
										currenciesItems={currenciesItems}
										ref={ref}
									/>
								)}
							/>
						</div>
						<CreateRequestFieldError error={errors.currency} />
					</CreateRequestFieldContainer>
					<CreateRequestFieldContainer>
						<CreateRequestLabelContainer>
							<CreateRequestFieldLabel displayStar>
								{rate === DAILY
									? "Expected daily rate"
									: "Expected hourly rate"}
							</CreateRequestFieldLabel>
						</CreateRequestLabelContainer>
						<Controller
							name="hourlyRate"
							control={control}
							render={({ field: { value, onChange } }) => (
								<div className={styles.hourlyRateContainer}>
									<CurrencyField
										input={{
											value,
											onChange
										}}
										className={styles.daysPerWeekInput}
										rootClassName={styles.currency}
										useGreenTheme
									/>
									<div className={styles.daysInOfficeLabel}>
										{rate === DAILY
											? `${selectedCurrency?.symbol}/day`
											: `${selectedCurrency?.symbol}/hour`}
									</div>
								</div>
							)}
						/>
						<CreateRequestFieldError error={errors.hourlyRate} />
					</CreateRequestFieldContainer>
				</div>
			</CreateRequestCardContent>
		</CreateRequestModal>
	);
};

export default CreateRequestMissionDetailsCard;

const LocationsController = memo(
	forwardRef(
		(
			{
				popoverContainer,
				value,
				onChange,
				onLocationSelectionChange,
				onLocationInputChange,
				locationsItems
			},
			ref
		) => {
			return (
				<ComboBox
					popoverContainer={popoverContainer}
					ref={ref}
					inputClassName={styles.comboxInput}
					inputValue={value?.label}
					selectedKey={value?.value?.id}
					onSelectionChange={value =>
						onLocationSelectionChange(value, onChange)
					}
					onInputChange={value => onLocationInputChange(value, onChange)}
				>
					{locationsItems}
				</ComboBox>
			);
		}
	)
);

const CurrenciesController = memo(
	forwardRef(
		(
			{
				popoverContainer,
				value,
				onChange,
				currenciesItems,
				onCurrencySelectionChange,
				onCurrencyInputChange
			},
			ref
		) => {
			return (
				<ComboBox
					popoverContainer={popoverContainer}
					ref={ref}
					inputClassName={styles.comboxInput}
					inputValue={value?.label}
					selectedKey={value?.value}
					onSelectionChange={value =>
						onCurrencySelectionChange(value, onChange)
					}
					onInputChange={value => onCurrencyInputChange(value, onChange)}
				>
					{currenciesItems}
				</ComboBox>
			);
		}
	)
);
