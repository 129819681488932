import styled, { css } from "styled-components";

export const StepperContainer = styled.div`
	width: 100%;
	border-radius: 4px;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	${props =>
		props.isJobView &&
		css`
			margin-top: 20px;
			padding-bottom: 0 !important;
		`}
	.step {
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		margin-bottom: 10px;
		.step-box {
			padding: 12px;
			border: solid 1px #ccd7e6;
			color: #616976;
			font-weight: 500;
			line-height: 1.19;
			font-size: 13px;
			border-radius: 4px;
			margin-right: 20px;
			&.active {
				border: solid 1px #46ac9c;
				background-color: rgba(70, 172, 156, 0.08);
				color: #46ac9c;
			}
		}
		.step-count {
			color: #a0a5b9;
			font-size: 11px;
			font-weight: 500;
			line-height: 1.95;
			letter-spacing: 0.22px;
		}

		.step-name {
			line-height: 1.19;
			letter-spacing: normal;
			color: #0f1c46;
			font-size: 13px;
			font-weight: 500;
		}
		.icon-container {
			border-width: 2px;
			height: 16px;
			width: 16px;
			line-height: 2;
			font-weight: 500;
			border-radius: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			background-color: #46ac9c;
			.icon-check2 {
				font-size: 9px;
			}
		}
	}

	.divider {
		width: 50px;
		height: 2px;
		background-color: #ccd7e6;
		margin: 0px 20px;
		position: relative;
		top: -7px;
		&.done {
			background-color: #46ac9c;
		}
	}
`;

export const CreateRequestContainer = styled.div`
	padding-left: 15px;
	padding-top: 20px;
	background-color: #f1f2f5;
	width: 100%;
`;
export const TimesheetReporting = styled.div`
	gap: 11px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-top: 20px;
	.select-label {
		color: #58626b;
		text-transform: uppercase;
	}
`;

export const CreateJobContainer = styled.div`
	width: 100%;
	padding: 20px 30px;
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11);
	min-height: 540px;
	.title {
		color: #273238;
		font-size: 15px;
		font-weight: 600;
		line-height: 1.63;
		letter-spacing: 0.08px;
	}
	.description {
		color: #3d4b60;
		line-height: 1.44;
		letter-spacing: normal;
		font-size: 12.5px;
		font-weight: normal;
		width: 45%;
	}
	.page {
		color: #c9cacb;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 600;
		line-height: 1.95;
		letter-spacing: 0.99px;
	}
	.job {
		display: flex;
		width: 100%;
		align-items: flex-start;
	}
	.first-section {
		width: 49%;
		border-right: 1px solid #dde3e8;
		padding: 0px 20px 20px 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin-top: 20px;
		.inline-error {
			margin-top: -12px !important;
			&.description-error {
				margin-top: -30px !important;
			}
		}
	}
	.second-section {
		margin-top: 20px;
		width: 49%;
		padding: 0px 20px 20px 0px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		&.details {
			padding-left: 20px;
		}
		.inline-error {
			margin-left: 20%;
			margin-top: -12px;
			&.toleft {
				margin-left: 0;
			}
		}
		.contract-type {
			margin-bottom: 12px;
		}
		.row {
			width: 80%;
			margin-left: 0px !important;
			.inline-error {
				margin: unset !important;
				margin-top: -16px !important;
			}
			.col-sm-6 {
				padding: unset !important;
				&:first-of-type {
					padding-right: 5px !important;
				}
				&:last-child {
					padding-left: 5px !important;
				}
			}
		}
		.currency-field {
			margin-top: 20px;
		}
	}
	.functions-container {
		display: flex;
		width: 80%;
		justify-content: space-between;

		.inline-error {
			position: absolute;
			top: 62px;
			left: 0;
		}
	}
`;

export const SelectContainer = styled.div`
	border-radius: 2px;
	border: solid 1px #ccd7e6;
	padding: 5px 20px;
	background-color: white;
	width: ${props => (props.width ? props.width : "80%")} !important;
	margin: ${props => props.margin && props.margin};
	margin-bottom: 12px;
	position: relative;
	.value{
		letter-spacing: 0.1px !important;
		color: #0f1c46 !important;
		font-size: 12px !important;
	}
	.g-tool{
		font-size: 12px;
		height: 12px;
		position: relative;
		top: -5px;
	}

	.Select{
		span{
			margin: unset !important;
		}
	}
	.select-label {
		color: #a0a5b9;
		line-height: 2.15;
		letter-spacing: 0.9px;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 600;
	}
	.Select-control {
		height: 24px;
		border: unset;
		&:hover {
			box-shadow: unset !important;
		}
		.Select-placeholder,
		.Select-input {
			padding: unset !important;
			height: 10px !important;
		}
		.Select-arrow {
			border-width: 3.5px 2.5px 3.5px !important;
		}
	}
	.select-style{
		margin-top: 0 !important;
	}

	.Select-parent{
		display: inline-grid;
		width: 100%;
		height: 20px;
		.Select-menu-outer{
			.Select-menu{
				max-height: 180px;
			}
		}
	}


	.Select-value-label{
		position: relative;
		left: -20px;
		top: -15px;
		line-height: 1.88 !important;
		letter-spacing: 0.1px !important;
		color: #0f1c46 !important;
		font-size: 12px !important;
	}
	.dpt-select{
		.Select-menu{
			padding: 4px 10px !important;;
		}
	}
	.Select-value{
		padding-top: 10px;
	}
	.Select-menu-outer {
		margin-top: 14px !important;
		backdrop-filter: blur(15px);
		box-shadow: 0 0.5px 3px 0 rgba(0, 0, 0, 0.1);
		border: solid 1px rgba(204, 215, 230, 0.6);
		background-color: #ffffff;
		border-radius: 4px;
		left: -22px;
		.Select-menu {
			border-radius: 4px;
			margin-top: unset !important;
			padding: 10px;
		}
		.Select-option {
			color: #0f1c46 !important;
			border: unset !important;
			line-height: 1.75 !important;
			letter-spacing: normal !important;
			font-size: 12px !important;
			border-radius: 4px;
			padding: 7px 10px !important;
			&:focus {
				border: unset !important;
			}
			&:hover {
				background-color: #e8f4fc !important;
			}
			&.is-focused {
				background-color: #e8f4fc !important;
			}
		}
	}
}

`;

export const CtaContainer = styled.div`
	padding: 10px;
	width: 100%;
	border-top: solid 0.5px #dde3e8;
	display: flex;
	justify-content: center;
	margin-top: 10px !important;
	button {
		padding: 10px 15px;
		border: solid 1px #2595e3;
		background-color: rgba(34, 148, 227, 0.1);
		font-size: 13px;
		font-weight: 600;
		line-height: 1.23;
		letter-spacing: normal;
		color: #2294e3;
	}
`;

export const ItemListContainer = styled.div`
	border-radius: 2px;
	border: solid 1px #ccd7e6;
	padding: 10px 20px;
	background-color: white;
	width: ${props => (props.width ? props.width : "80%")} !important;
	margin-bottom: 12px;
	margin-left: 20%;
	.g-tool {
		font-size: 12px;
		height: 12px;
		position: relative;
		top: -5px;
	}
	.list-title {
		color: #a0a5b9;
		line-height: 2.15;
		letter-spacing: 0.9px;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 600;
	}

	.items {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 12px;
	}
	.item {
		color: #5197f1;
		padding: 4px 7px;
		border-radius: 14.5px;
		border: solid 1px #5197f1;
		font-weight: bold;
		font-size: 12px;
		text-transform: uppercase;
		margin-right: 6px;
		margin-bottom: 4px;
		display: flex;
		align-items: center;
		.client_layout {
			width: 16px;
			fill: #5197f1;
			margin-right: 4px;
		}
		&.placeholder {
			color: #dfe0e2;
			border-color: #dfe0e2;
		}
	}
	.icon-edit-2 {
		float: right;
		cursor: pointer;
		font-size: 12px;
	}
`;

export const ActionsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 40px;
	align-items: center;
	font-size: 13px;
	font-weight: 600;
	font-stretch: normal;
	line-height: 1.23;
	letter-spacing: normal;

	.back-to-list-btn {
		color: #616976;
		border: unset;
		background-color: transparent;
		padding: 12px 15px;
		margin-right: 10px;
	}
	.create-request {
		padding: 12px 15px;
		border-radius: 4px;
		background-color: #ff6849;
		border: 1px solid #ff6849;
		color: white;
	}
`;

export const InputContainer = styled.div`
	border-radius: 2px;
	border: solid 1px #ccd7e6;
	padding: 5px 20px;
	background-color: white;
	width: ${props => (props.width ? props.width : "80%")} !important;
	margin-bottom: 12px;
	position: relative;

	${props =>
		props.isFocused &&
		css`
			box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.07);
			border: solid 1px #2294e3;
		`}

	.input-label {
		color: #a0a5b9;
		line-height: 2.15;
		letter-spacing: 0.9px;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 600;
	}

	.request-title {
		min-height: 10px !important;
		padding: 0 !important;
		border: unset !important;
		color: #0f1c46;
		font-size: 12px;
		line-height: 1.88;
		letter-spacing: 0.1px;
		width: 100%;
		outline: none !important;
		&:focus {
			box-shadow: unset !important;
			border: none !important;
		}
	}
`;

export const LocationContainer = styled.div`
	.location-title {
		color: #a0a5b9;
		line-height: 2.15;
		letter-spacing: 0.9px;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 600;
	}

	.location-options {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&.time-options {
			justify-content: unset !important;
			.option {
				margin-right: 16px;
			}
		}
		.option {
			padding: 10px 16px;
			border: solid 1px #d2d2d2;
			background-color: transparent;
			border-radius: 4px;
			font-size: 13px;
			line-height: 1.23;
			letter-spacing: normal;
			text-align: left;
			color: #0f1c46;
			font-weight: 400;
			min-width: 140px;
			cursor: pointer;
			.icon-edit-2 {
				color: #a0a5b9;
				margin-right: 10px;
			}
			.icon-experience {
				color: #a0a5b9;
				margin-right: 10px;
			}

			&.selected {
				border: solid 1px #2595e3;
				background-color: rgba(34, 148, 227, 0.1);

				.icon-edit-2 {
					color: #2595e3;
				}
				.icon-experience {
					color: #2595e3;
				}
			}
		}
	}

	.company-address {
		margin-top: 14px;
		border-left: 2px solid #ff6849;
		border-radius: 4px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		.address-tag {
			padding: 3px 5px;
			background-color: #ff6849;
			border-radius: 4px;
			color: white;
			font-weight: 500;
			font-size: 10px;
			width: 93px;
			margin-top: 4px;
		}
		font-size: 12px;
		color: #0f1c46;
		font-weight: 400;
	}
	.address-box {
		margin-top: 12px;
	}
`;

export const ApplicantResidenceSelect = styled.div``;
