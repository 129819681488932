//TODO We need to avoid duplication, the component already exists in SearchCandidateContainer module
import React, { useState, memo } from "react";
import styles from "./styles.module.scss";
import get from "lodash/get";
import { ReactComponent as ClearIcon } from "static/icons/clear_icon.svg";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import ClearModal from "../../../../../SearchCandidate/components/ClearModal/ClearModal";
import FilterPill from "../FilterPill/FilterPill";

const FiltersBar = ({ filterId }) => {
	const [showClearModal, setShowClearModal] = useState(false);

	const {
		handleFilterDelete,
		setShowDrawer,
		setSelectedFilter,
		getState
	} = useFilters();

	const { filters } = getState(filterId);

	const deleteFilterHandler = id => {
		handleFilterDelete(id);
	};

	const displayModalHandler = () => {
		setShowClearModal(true);
	};

	return (
		<>
			<div className={styles.filters_bar}>
				<div className={styles.scrollContainer}>
					<OverlayScrollbarsComponent style={{ maxWidth: "100%" }}>
						{filters.map((filter, index) => {
							return (
								<div key={index} className={styles.pill_container}>
									<FilterPill
										filterId={filterId}
										filter={filter}
										removable
										onRemove={deleteFilterHandler}
										onClick={() => {
											setSelectedFilter(get(filter, "name", ""));
											setShowDrawer(true);
										}}
									/>
								</div>
							);
						})}
					</OverlayScrollbarsComponent>
				</div>

				<div className={styles.btn_container}>
					<div className={styles.rightContainer}>
						<HeaderButton
							icon={<ClearIcon />}
							text={"Clear"}
							className="buttonClear"
							onClick={() => displayModalHandler("clear")}
						/>
					</div>
				</div>
			</div>

			{showClearModal && (
				<ClearModal onClose={() => setShowClearModal(false)} />
			)}
		</>
	);
};

export default memo(FiltersBar);
