import styled from "styled-components";

export const StatisticWrapper = styled.div`
	width: 100%;
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: repeat(8, minmax(0, 1fr));
	margin-bottom: 29px;
	margin-top: 15px;
`;

export const TopSectionWrapper = styled.div`
	margin-top: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	.section-select {
		margin-right: 20px;
		.select-label,
		.Select-value-label,
		.Select-option {
			font-size: 13px;
			font-weight: 600;
			letter-spacing: 0.08px;
			color: #6e7191;
			line-height: 2.43;
			font-style: normal;
		}
		.Select-value {
			display: flex;
			align-items: center;
		}
		.Select--single {
			width: 250px;
		}
	}
	.section-date-picker {
		position: relative;
		width: 300px;
	}
	.section-button-generator {
		margin-top: 37px;
		button {
			line-height: 0.5;
			height: 45px;
			font-size: 13px;
		}
	}
`;

export const SectionInfoWrapper = styled.div`
	width: 100%;
	height: 100px;
	display: flex;
	flex-direction: row;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
	border-radius: 5px;
	background-color: #ffffff;
	margin-bottom: 30px;
	padding: 16px 24px;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	.section-avatar {
		.cadre-avatar {
			width: 67px;
			height: 67px;
			border-radius: 4px;
			box-shadow: 0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11);
			background-color: #ffffff;
			img {
				width: 58px;
				height: 58px;
			}
		}
	}
	.section {
		display: flex;
		flex-direction: column;
		align-items: left;

		.title {
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 22px;
			color: #a0a3bd;
			text-transform: uppercase;
		}
		.body {
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 22px;
			color: #6e7191;
		}
		.body-name {
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 22px;
			color: #6e7191;
		}
	}
`;
