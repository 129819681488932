import React, { useEffect } from "react";
import { Flex, Text } from "rebass";
import { Label, Checkbox } from "@rebass/forms/styled-components";
import { reduxForm, Field } from "redux-form";
import Modal from "common/modal";
import FormField from "common/FormField";
import StyledSelect from "common/StyledSelect";
import { FormContainer } from "./styledForm";
import {
	REQUIREMENT_BID,
	REQUIREMENT_CONTRACT,
	REQUIREMENT_TIMESHEET_SUBMISSION
} from "config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	resetDocumentModal,
	setIsMandatory
} from "../actions/document.actions";
import { CRUD_ACTIONS } from "config";

function AddDocumentRequirementModal({
	onClose,
	hasStage,
	stageLabel,
	handleSubmit,
	is_mandatory,
	setIsMandatory,
	action,
	resetDocumentModal
}) {
	useEffect(() => {
		return () => resetDocumentModal();
	}, []);

	const getModalAction = () => {
		return action === CRUD_ACTIONS.ADD
			? "Create"
			: action === CRUD_ACTIONS.UPDATE && "Update";
	};

	const getModalTitle = () => {
		return stageLabel
			? `Add a new ${stageLabel} document`
			: action === CRUD_ACTIONS.ADD
			? "Add a document requirement"
			: action === CRUD_ACTIONS.UPDATE && "Update document requirement";
	};

	return (
		<Modal fixed={true} onClose={onClose} title={getModalTitle()}>
			<FormContainer onSubmit={handleSubmit}>
				<Flex flexDirection="column">
					<Field
						type="text"
						name="document"
						label="Document"
						placeholder="Name your document"
						component={FormField}
					/>
					{hasStage && (
						<Field
							type="text"
							name="stage"
							label="Stage"
							placeholder="Select the stage..."
							options={[
								REQUIREMENT_BID,
								REQUIREMENT_CONTRACT,
								REQUIREMENT_TIMESHEET_SUBMISSION
							]}
							component={StyledSelect}
						/>
					)}
					<Text
						mb={4}
						sx={{
							fontWeight: 500,
							color: "#58626b",
							textTransform: "uppercase"
						}}
					>
						Requirement
					</Text>
					<Label sx={{ cursor: "pointer" }}>
						<Checkbox
							id="document-checkbox"
							name="document-checkbox"
							color="#b0bbc2"
							checked={is_mandatory}
							onChange={() => setIsMandatory(!is_mandatory)}
							mr={2}
						/>
						Mandatory
					</Label>
					{/*<Field
						type="checkbox"
						name="requirement"
						label="Requirement"
						placeholder="Mandatory"
						className="mandary_checkbox"
						checked={checkedMandatory}
						input={{ onChange: () => setCheckedMandatory(!checkedMandatory) }}
						component={FormField}
					/>*/}
				</Flex>
				<Flex mt={4}>
					<button
						onClick={onClose}
						className="btn btn-outline"
						style={{ marginLeft: 0, marginTop: 0, marginBottom: 30 }}
					>
						Cancel
					</button>
					<button
						type="submit"
						className="btn btn-primary"
						style={{ marginTop: 0, marginBottom: 30 }}
					>
						{getModalAction()}
					</button>
				</Flex>
			</FormContainer>
		</Modal>
	);
}

const validate = formProps => {
	const errors = {};

	if (!formProps.document) {
		errors.document = "Document name is required.";
	} else if (formProps.document.length > 60) {
		errors.document = "60 character max.";
	}

	if (!formProps.stage) {
		errors.stage = "Stage is required.";
	}

	return errors;
};

const mapStateToProps = state => {
	return {
		initialValues: state.document.formFields,
		is_mandatory: state.document.formFields.is_required
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ resetDocumentModal, setIsMandatory }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "add-document-requirement",
		validate,
		touchOnBlur: false
	})(AddDocumentRequirementModal)
);
