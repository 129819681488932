import React from "react";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import styles from "./new-email-user-tag.module.scss";

const NewEmailUserTag = ({ onDelete, name, item, readOnly }) => {
	return (
		<div className={styles.tag} key={item}>
			<span className={styles.tagLabel}>{item}</span>
			{!readOnly && (
				<button
					className={styles.tagIcon}
					type="button"
					onClick={e => {
						e.preventDefault();
						onDelete(name, item);
					}}
				>
					<CloseIcon className={styles.closeIcon} />
				</button>
			)}
		</div>
	);
};
export default NewEmailUserTag;
