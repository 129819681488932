import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { MANAGE_EMAIL_TEMPLATE } from "config/api-endpoints";

const duplicateTemplate = body => {
	return client(MANAGE_EMAIL_TEMPLATE, {
		body: {
			...body,
			action: "clone"
		}
	});
};

export default function useDuplicateEmailTemplate() {
	return useMutation(duplicateTemplate);
}
