import React, { useState } from "react";
import loadable from "loadable-components";

import { useFormContext } from "react-hook-form";
import { CreateRequestEditButton } from "../CreateRequestEditButton";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestInfoIcon } from "../CreateRequestInfoIcon";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { CreateRequestAddButton } from "../CreateRequestAddButton";
import styles from "./profile-step-languages-field.module.scss";

const ProfileLanguagesEditorDialog = loadable(() =>
	import("../ProfileLanguagesEditorDialog/ProfileLanguagesEditorDialog")
);

const ProfileStepLanguagesField = ({ onSave: onSaveProp }) => {
	const [displayLanguagesModal, setDisplayModalLanguages] = useState(false);
	const { watch } = useFormContext();
	const languagesList = watch("languages");

	const handleAddLanguagesClick = () => setDisplayModalLanguages(true);

	const handleLanguagesModalCancelClick = () => setDisplayModalLanguages(false);

	const handleLanguagesModalEscapeKeyDown = () =>
		setDisplayModalLanguages(false);

	const onSave = list => {
		setDisplayModalLanguages(false);
		onSaveProp(list);
	};

	return (
		<CreateRequestFieldContainer>
			<CreateRequestLabelContainer>
				<CreateRequestFieldLabel>Languages</CreateRequestFieldLabel>
				<CreateRequestInfoIcon>
					Languages are another fundamental part of the matching process.
					Although they are weighted less than skills in the matching score
					calculation, it is still important to properly outline your linguistic
					skills.
				</CreateRequestInfoIcon>
			</CreateRequestLabelContainer>
			{languagesList?.length && (
				<div className={styles.languagesList}>
					{languagesList.map(({ language, proficiency }) => (
						<div key={language.id} className={styles.languageTag}>
							{language.label} ({proficiency}/5)
						</div>
					))}
				</div>
			)}
			{languagesList?.length > 0 ? (
				<CreateRequestEditButton onClick={handleAddLanguagesClick} />
			) : (
				<CreateRequestAddButton onClick={handleAddLanguagesClick} />
			)}
			{displayLanguagesModal && (
				<ProfileLanguagesEditorDialog
					onCancel={handleLanguagesModalCancelClick}
					onEscapeKeyDown={handleLanguagesModalEscapeKeyDown}
					onSave={onSave}
					languagesList={languagesList}
				/>
			)}
		</CreateRequestFieldContainer>
	);
};

export default ProfileStepLanguagesField;
