import React from "react";
import { useMutation } from "react-query";
import Modal from "common/modal";
import { UPDATE_USER_SETTING } from "config/api-endpoints";
import { USER_SETTINGS_OPTIONS, WATCHED_CLONE_HELP } from "config";

import { client } from "lib/api-client";

const closeHelpModal = data => {
	return client(UPDATE_USER_SETTING, { body: data });
};
const videoUrl =
	"https://front-cdn.s3-eu-west-1.amazonaws.com/videos/timehseet-clone.mp4";

function TimesheetHelpModal({ onClose, getMyProfile }) {
	const [mutate] = useMutation(closeHelpModal);
	const closeHelpModalTimesheet = async () => {
		try {
			onClose();
			await mutate({
				type: USER_SETTINGS_OPTIONS,
				user_options: { [WATCHED_CLONE_HELP]: true }
			});
			getMyProfile();
		} catch (error) {}
	};

	return (
		<Modal
			className={"helpmodal"}
			active={true}
			onClose={closeHelpModalTimesheet}
			title="Timesheet clone Guide"
		>
			<div>
				<video width="100%" controls>
					<source src={videoUrl} type="video/mp4" />
				</video>
			</div>
		</Modal>
	);
}

export default TimesheetHelpModal;
