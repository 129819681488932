import * as types from "../actions/actionTypes";

const initialState = {
	bid: null,
	formData: null,
	interrupt: null,
	extendMission: {},
	missions: {},
	filter: {
		status: "",
		job_id: "",
		vendor_id: "",
		field: "created_at",
		offset: 0,
		sort: "desc"
	}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.FETCH_SUCCESS:
			return { ...state, missions: action.data, isFetching: false };
		case types.TOGGLE_INTERRUPT_MODAL:
			return { ...state, interrupt: action.id };
		case types.TOGGLE_EXTEND_MODAL:
			return { ...state, extendMission: action.mission || {} };
		case types.CLOSE_EXTEND_MODAL:
			return { ...state, extendMission: {} };
		case types.UPDATE_FILTER:
			return { ...state, filter: action.filter || initialState.filter };
		case types.RESET_FILTERS:
			return {
				...state,
				filter: {
					status: "",
					job_id: "",
					vendor_id: "",
					field: "created_at",
					offset: 0,
					sort: "desc"
				}
			};
		default:
			return state;
	}
}
