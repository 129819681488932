import React from "react";
import _get from "lodash/get";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Card from "../Card/Card";
import Tag from "../Tag";
import styles from "../Card/card.module.scss";
import { ReactComponent as EmptyState } from "static/icons/exclamation-triangledanger.svg";

const ProfileTagsCard = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	isLoading,
	title,
	provided,
	tags = []
}) => {
	const LoadingSkeleton = (
		<>
			<div className={styles.section}>
				<div className={styles.row}>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={150} height={16} />
					</SkeletonTheme>
				</div>
			</div>
		</>
	);
	return (
		<Card
			provided={provided}
			title={title}
			{...{ onDragEnd, onViewMore, onToggleVisibility }}
		>
			{!isLoading ? (
				tags?.length > 0 ? (
					<>
						<div className={styles.section}>
							<div className={styles.row}>
								{tags.map(tg => (
									<Tag key={_get(tg, "_id")} text={_get(tg, "value")} outline />
								))}
							</div>
						</div>
					</>
				) : (
					<div className={styles.emptyState}>
						<EmptyState />
						<p>No results to show</p>
					</div>
				)
			) : (
				LoadingSkeleton
			)}
		</Card>
	);
};

export default ProfileTagsCard;
