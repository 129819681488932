import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { scrollTo } from "./Functions";
import _isFunction from "lodash/isFunction";
class Pagination extends Component {
	constructor(props) {
		super(props);
		this.getPageNum = this.getPageNum.bind(this);
		this.handlerPaginationClick = this.handlerPaginationClick.bind(this);
	}

	getPageNum() {
		return Math.ceil(this.props.total / this.props.size);
	}

	handlerPaginationClick(target) {
		this.props.handlePageClick(target.selected);
		scrollTo();
	}

	render() {
		const {
			forceSelected,
			initialSelected,
			forcePage,
			previousLabel = "previous",
			nextLabel = "next",
			containerClassName = "",
			...rest
		} = this.props;
		const offset = Number(this.props.offset);
		const currentPage = offset === 0 ? offset : forcePage;
		return (
			<ReactPaginate
				previousLabel={
					_isFunction(previousLabel)
						? previousLabel(!currentPage)
						: previousLabel
				}
				nextLabel={
					_isFunction(previousLabel)
						? nextLabel(currentPage === this.getPageNum() - 1)
						: nextLabel
				}
				breakLabel={<div>...</div>}
				breakClassName={"break-me"}
				pageCount={this.getPageNum()}
				marginPagesDisplayed={2}
				pageRangeDisplayed={3}
				onPageChange={this.handlerPaginationClick}
				containerClassName={"pagination " + containerClassName}
				subContainerClassName={"pages pagination"}
				activeClassName={"active"}
				forcePage={currentPage}
				forceSelected={forceSelected && forceSelected}
				initialSelected={initialSelected && initialSelected}
				{...rest}
			/>
		);
	}
}

Pagination.propTypes = {
	total: PropTypes.number.isRequired,
	size: PropTypes.number.isRequired,
	handlePageClick: PropTypes.func.isRequired
};

export default Pagination;
