export const NEW_TEMPLATE_SEGMENT_ID = "new-template";
export const DEFAULT_TEMPLATE_VERSION = "en";
export const MAX_LENGTH_IN_BYTES = 15728640; // 15mb
export const CATEGORY = {
	EVENTS: "events",
	GENERAL: "general"
};
export const CATEGORIES = [
	{
		label: "General",
		value: CATEGORY.GENERAL
	},
	{
		label: "Events",
		value: CATEGORY.EVENTS
	}
];
