import React, { useState, useRef, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import get from "lodash/get";
import Fuse from "fuse.js";
import useOnClickOutside from "hooks/useOnClickOutside";
import { ProfileMenu, MenuItem, Label } from "./styles";
import { ReactComponent as ClearIcon } from "static/icons/rounded-clear-icon.svg";
import { ReactComponent as AddMember } from "static/icons/add-member-icon.svg";
import { ReactComponent as RemoveMember } from "static/icons/remove-member-icon.svg";
import { ReactComponent as NoDataIcon } from "static/icons/danger-icon.svg";
import Loader from "common/Loader";
import GlobalTooltip from "common/GlobalTooltip";
import Avatar from "common/Avatar";
import classnames from "modules/candidate/Profile/Pages/Files/files.module.scss";
import { ReactComponent as AddGreenMember } from "static/icons/add-member-green-icon.svg";
import { ReactComponent as RemoveGreenMember } from "static/icons/remove-member-green-icon.svg";

function CustomSelect({
	data,
	onChange,
	functionKey = "user_function",
	idKey = "_id",
	selectTitle = "Invite team members",
	SelectIcon = <AddMember />,
	UnSelectIcon = <RemoveMember />,
	open,
	setOpen,
	includedItems,
	setIncludedItems,
	isLoading = false,
	inputPlaceholder = "Search member...",
	onClick = () => {},
	isGreenTheme = false,
	disabledUsers = [] // array of primitive values
}) {
	const [selectData, setSelectData] = useState([]);
	const [searchKeyword, setSearch] = useState("");
	const menuRef = useRef(null);
	const firstMounded = useRef(true);
	useOnClickOutside(menuRef, () => {
		setOpen(false);
		setSearch("");
	});

	useEffect(() => {
		if (!firstMounded.current) {
			onChange && onChange(includedItems);
		}
		firstMounded.current = false;
	}, [includedItems, onChange]);

	useEffect(() => {
		setSelectData(data);
	}, [data]);

	useEffect(() => {
		setSelectData(
			Boolean(searchKeyword)
				? new Fuse(data, {
						keys: ["first_name", "last_name", functionKey],
						threshold: 0.3,
						includeScore: false
				  })
						.search(searchKeyword)
						.map(elem => elem.item)
				: data
		);
	}, [searchKeyword, data]);

	const handleSelect = elem => {
		if (includedItems.findIndex(item => item[idKey] === elem[idKey]) === -1) {
			onClick({ added: [elem[idKey]], retired: [] });
			setIncludedItems(old => [...old, elem]);
		} else {
			onClick({ added: [], retired: [elem[idKey]] });
			setIncludedItems(old =>
				old.filter(item => !(item[idKey] === elem[idKey]))
			);
		}
	};

	const getItemIcon = elem => {
		const isItemIncluded = includedItems.some(
			item => item[idKey] === elem[idKey]
		);
		if (!isItemIncluded) {
			return isGreenTheme ? <AddGreenMember /> : SelectIcon;
		} else {
			return isGreenTheme ? <RemoveGreenMember /> : UnSelectIcon;
		}
	};

	return (
		<ProfileMenu
			ref={menuRef}
			showMenu={open}
			isLoading={isLoading}
			className="select-menu"
			style={{ width: isGreenTheme ? "340px" : "" }}
		>
			{isLoading && (
				<div className="loader">
					<Loader />
				</div>
			)}
			<h4 className="title">{selectTitle}</h4>
			<div className="text-field">
				<input
					placeholder={inputPlaceholder}
					value={searchKeyword}
					onChange={({ target }) => setSearch(target["value"])}
				/>
				<ClearIcon onClick={() => setSearch("")} />
			</div>
			{get(selectData, "length", -1) > 0 ? (
				<Scrollbars
					autoHide
					autoHideTimeout={5000}
					autoHideDuration={200}
					autoHeight
					autoHeightMax={270}
				>
					<ul className="menu">
						{selectData.map((elem, index) => (
							<MenuItem key={index}>
								<div className="left-side">
									<GlobalTooltip
										overlay={get(elem, "user_name")}
										placement="top"
										trigger="hover"
									>
										<Avatar
											className={classnames.avatar}
											name={get(elem, "user_name")}
											avatar={get(elem, "avatar")}
											size={32}
										/>
									</GlobalTooltip>
									<div className="info">
										<Label fontWeight="600">{`${
											get(elem, "name", "")
												? get(elem, "name", "")
												: get(elem, "first_name", "")
										} ${get(elem, "last_name", "")}`}</Label>
										<Label>{get(elem, functionKey, "")}</Label>
									</div>
								</div>
								<button
									className="actions"
									onClick={e => {
										e.stopPropagation();
										e.preventDefault();
										handleSelect(elem);
									}}
									disabled={disabledUsers.includes(elem[idKey])}
								>
									{getItemIcon(elem)}
								</button>
							</MenuItem>
						))}
					</ul>
				</Scrollbars>
			) : (
				<div className="empty-state">
					<NoDataIcon />
					<Label margin="0 0 0 10px">No results to show</Label>
				</div>
			)}
		</ProfileMenu>
	);
}

export default CustomSelect;
