import React from "react";
import PropTypes from "prop-types";
import Modal from "common/modal/Modal";

class DepartmentsListModal extends React.Component {
	closeModal = () => {
		this.props.toggleModal();
	};

	render() {
		return (
			<Modal
				title="My roles"
				onClose={this.closeModal}
				className="departments-modal"
				fixed={true}
				showSecondButton={false}
				maxheight={"400px"}
				firstButton={{
					label: "close window",
					action: this.closeModal,
					type: "primary",
					style: {
						marginTop: "30px",
						width: "180px",
						height: "45px",
						fontSize: "13px",
						lineHeight: 1
					}
				}}
			>
				{this.props.getDepartments()}
			</Modal>
		);
	}
}

DepartmentsListModal.propTypes = {
	getDepartments: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired
};

export default DepartmentsListModal;
