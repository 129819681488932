import React from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import GlobalTooltip from "common/GlobalTooltip";
import _get from "lodash/get";
import styled from "styled-components";
import "rc-tooltip/assets/bootstrap.css";
import { SENIOR } from "config";
import Star from "./Star";
import { colors } from "config/styles";

const StarIconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	background-color: ${colors.primaryColor};
	width: 19px;
	height: 19px;
	border-radius: 3px;
	float: left;
	margin-top: ${props => props.marginTop || "unset"};
	svg {
		width: 80%;
		height: 70%;
	}
`;

function Functions({
	functions,
	showModal,
	hasError,
	canUpdateFunctions = true,
	isFreelancer,
	isPermanent
}) {
	const tooltip = `Select the function(s) that best describes your need and
	 set the seniority that you want your candidates to have in that function
		(this is not a global seniority value).
		Note that you can select up to 5 functions to broaden the scope of your potential matches.`;

	const renderCrumb = ({ value, max = 15, isMain = false }) => {
		if (value.length > max) {
			return (
				<Tooltip
					placement="top"
					mouseEnterDelay={0}
					mouseLeaveDelay={0}
					overlay={<div>{value}</div>}
				>
					<li>
						{isMain && (
							<StarIconContainer>
								<Star color="white" />
							</StarIconContainer>
						)}
						{`${value.substring(0, max)}...`}
					</li>
				</Tooltip>
			);
		}
		return (
			<li>
				{isMain && (
					<StarIconContainer>
						<Star color="white" />
					</StarIconContainer>
				)}
				{value}
			</li>
		);
	};

	const renderCrumbs = () => {
		return functions.map(fnc => (
			<ul
				key={`${fnc._id}-${fnc.seniority}`}
				className={`crumbs ${fnc.is_main ? "main" : ""}`}
			>
				{renderCrumb({
					value: _get(fnc, "sector.parent_sector.name", ""),
					isMain: fnc.is_main
				})}
				{renderCrumb({ value: _get(fnc, "sector.name", "") })}
				{renderCrumb({ value: _get(fnc, "name", "") })}
				{renderCrumb({ value: _get(fnc, "seniority", SENIOR) })}
			</ul>
		));
	};

	return (
		<div
			id="can-do"
			className={
				hasError ? "section action-hover skillsError" : "section action-hover"
			}
			style={{ minHeight: 120 }}
		>
			{canUpdateFunctions && (
				<div className="section-edit pull-right">
					{functions.length > 0 && (
						<button className={`btn btn-sm`} onClick={() => showModal()}>
							{isFreelancer ? (
								<span className="icon-edit-2" />
							) : (
								<i className="fa fa-pencil" />
							)}
						</button>
					)}
				</div>
			)}
			<div className="section-title">
				{canUpdateFunctions && !isPermanent ? (
					<>
						Functions <GlobalTooltip overlay={tooltip} />{" "}
					</>
				) : (
					<> Function(s)</>
				)}
			</div>
			<div className="section-content">
				{isFreelancer && functions.length === 0 && !canUpdateFunctions && (
					<div className="section-content">
						<div className="alert alert-warning">No information available</div>
					</div>
				)}
				{canUpdateFunctions && functions && functions.length === 0 && (
					<div className="empty-section">
						<div className={`add btn-center-block`} onClick={() => showModal()}>
							<i className="fa fa-plus" /> Add functions
						</div>
					</div>
				)}
				{renderCrumbs()}
			</div>
		</div>
	);
}

Functions.propTypes = {
	functions: PropTypes.array.isRequired,
	showModal: PropTypes.func.isRequired
};

export default Functions;
