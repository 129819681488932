import React from "react";

const Star = ({ className, color }) => (
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		viewBox="0 0 286 264.1"
		className={className}
		fill={color}
	>
		<g>
			<g>
				<g>
					<path
						d="M275.5,101.5c-1-3-3.6-5.2-6.8-5.7L187,84L150.5,9.9c-1.4-2.8-4.3-4.6-7.5-4.6c-3.2,0-6.1,1.8-7.5,4.6L99,84L17.3,95.8
				c-3.2,0.5-5.8,2.7-6.8,5.7c-1,3-0.2,6.3,2.1,8.5l59.2,57.6l-13.9,81.4c-0.6,3.1,0.7,6.3,3.3,8.1c2.6,1.9,5.9,2.1,8.8,0.7
				l73.1-38.4l73.1,38.4c1.2,0.7,2.5,1,3.9,1c1.7,0,3.4-0.6,4.9-1.6c2.6-1.9,3.8-5,3.3-8.1l-13.9-81.4l59.2-57.6
				C275.7,107.9,276.5,104.5,275.5,101.5L275.5,101.5z"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default Star;
