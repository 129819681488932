/* eslint-disable */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { FIFTY } from "config";
import { HUNDRED } from "config";

export const useAddEntryForm = isTagsUsed => {
	let schema = {
		date: yup.mixed().test("Required", "Field can't be empty.", function(date) {
			return date.length > 0;
		}),
		time: yup.mixed().test("Required", "Field can't be empty.", function(time) {
			const type = this.parent.type?.value;
			const overtimeDuration = this.parent.overtimeDuration?.value;

			if (
				type === "expense" ||
				(overtimeDuration !== "custom" && overtimeDuration)
			)
				return true;
			else return !!time;
		}),
		amount: yup
			.string()
			.test("Required", "Field can't be empty.", function(amount) {
				if (this.parent?.type?.value !== "expense") return true;
				else return !!amount;
			})
			.test("Range", "Amount must be between 0 and 1000.", function(amount) {
				if (this.parent?.type?.value !== "expense") return true;
				const amountNumber = parseFloat(amount);
				return (
					!isNaN(amountNumber) && amountNumber >= 0 && amountNumber <= 100000
				);
			}),
		comment: yup.string().required("Field can't be empty.")
	};

	if (isTagsUsed) {
		schema.tag = yup
			.object()
			.test("Required", "Field can't be empty.", function(value) {
				return !!value?.value;
			});
	}

	const res = useForm({
		shouldUnregister: true,
		resolver: yupResolver(yup.object().shape(schema)),
		defaultValues: {
			type: {
				label: "Worktime",
				value: "worktime"
			},
			rate: HUNDRED,
			date: []
		}
	});

	return res;
};
