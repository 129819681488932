import React, { useEffect, useRef } from "react";
import { get } from "lodash";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { queryCache } from "react-query";
import Box from "../Box";
import usePermanentStore from "../../zustand/PermanentStore";
import style from "./styles.module.scss";
import { historyPush } from "config/helpers";
import { HeaderEnd } from "modules/home/containers/interview/header.jsx";
import PendingApplications from "./pendingApplications/PendingApplications";
import OverdueJobs from "./overdueJobs/OverdueJobs";
import OpenJobs from "./openJobs/OpenJobs";
import TabCount from "modules/home/components/TabCount.jsx";
import {
	PENDING_APPLICATIONS,
	OPEN_JOBS,
	JOBS_OVERDUE,
	PROACTIVE_APPLICATIONS
} from "modules/home/config";
import {
	pendingApplicationsListKey,
	openJobsListKey,
	overdueJobsListKey
} from "modules/home/api/queriesKeys.js";
import { ProactiveApplicationsTab } from "./ProactiveApplicationsTab";
import { GET_PROACTIVE_APPLICATIONS_QUERY_KEY } from "modules/home/api/useGetProactiveApplicationsList";

const Permanent = ({ listCount }) => {
	const permanentTypeKey = "permanentType";
	const urlToRedirect = useRef("/vacancies/list?view=candidates");
	const {
		permanentType,
		setItemPermanent,
		permanentStartDate,
		permanentEndDate,
		permanentRecepientType
	} = usePermanentStore();

	useEffect(() => {
		getUrlState(permanentType);
	}, []);

	const handleTabsChange = index => {
		switch (index) {
			case 0:
				setItemPermanent(permanentTypeKey, PENDING_APPLICATIONS);
				queryCache.invalidateQueries(pendingApplicationsListKey);
				urlToRedirect.current = "/vacancies/list?view=candidates";
				break;
			case 1:
				setItemPermanent(permanentTypeKey, PROACTIVE_APPLICATIONS);
				queryCache.invalidateQueries(GET_PROACTIVE_APPLICATIONS_QUERY_KEY);
				urlToRedirect.current =
					"/candidates/search?source=proactive_applications_list";
				break;
			case 2:
				setItemPermanent(permanentTypeKey, OPEN_JOBS);
				queryCache.invalidateQueries(openJobsListKey);
				urlToRedirect.current = "/vacancies/list?view=vacancies";
				break;
			case 3:
				setItemPermanent(permanentTypeKey, JOBS_OVERDUE);
				queryCache.invalidateQueries(overdueJobsListKey);
				urlToRedirect.current = "/vacancies/list?view=vacancies";
				break;
		}
	};

	const getUrlState = type => {
		switch (type) {
			case PENDING_APPLICATIONS:
				urlToRedirect.current = "/vacancies/list?view=candidates";
				break;
			case PROACTIVE_APPLICATIONS:
				urlToRedirect.current =
					"/candidates/search?source=proactive_applications_list";
				break;
			case OPEN_JOBS:
				urlToRedirect.current = "/vacancies/list?view=vacancies";
				break;
			case JOBS_OVERDUE:
				urlToRedirect.current = "/vacancies/list?view=vacancies";
				break;
			default:
				urlToRedirect.current = "/vacancies/list?view=candidates";
				break;
		}
	};

	const getTypeIndex = type => {
		switch (type) {
			case PENDING_APPLICATIONS:
				return 0;
			case PROACTIVE_APPLICATIONS:
				return 1;
			case OPEN_JOBS:
				return 2;
			case JOBS_OVERDUE:
				return 3;
			default:
				return 0;
		}
	};

	const handleViewAll = () => {
		historyPush(urlToRedirect.current);
	};

	const TabsPermanent = () => {
		return (
			<TabList>
				<Tab>
					Pending applications
					<TabCount count={get(listCount, "pending_applications", 0)} />
				</Tab>
				<Tab>
					Proactive applications
					<TabCount count={get(listCount, "proactive_applications", 0)} />
				</Tab>
				<Tab>
					Open jobs <TabCount count={get(listCount, "open_jobs", 0)} />
				</Tab>
				<Tab>
					Jobs overdue <TabCount count={get(listCount, "jobs_overdue", 0)} />
				</Tab>
			</TabList>
		);
	};

	return (
		<Tabs
			defaultIndex={getTypeIndex(permanentType)}
			onChange={handleTabsChange}
		>
			<Box
				headerEnd={
					<HeaderEnd
						start_date={permanentStartDate}
						end_date={permanentEndDate}
						setItem={setItemPermanent}
						start_date_Key={"permanentStartDate"}
						end_date_key={"permanentEndDate"}
						recipient_type={permanentRecepientType}
						handleViewAll={handleViewAll}
					/>
				}
				headerStart={<TabsPermanent />}
			>
				<div className={style.tabsContainer}>
					<TabPanels>
						<TabPanel>
							<PendingApplications handleViewAll={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<ProactiveApplicationsTab onViewAllClick={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<OpenJobs handleViewAll={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<OverdueJobs handleViewAll={handleViewAll} />
						</TabPanel>
					</TabPanels>
				</div>
			</Box>
		</Tabs>
	);
};

export default Permanent;
