import {
	getLocalTimeZone,
	isSameDay,
	isSameMonth,
	today
} from "@internationalized/date";
import { useCalendarCell } from "react-aria";
import cx from "classnames";
import React from "react";
import styles from "./calendar-cell.module.scss";

const CalendarCell = ({ state, date, currentMonth }) => {
	let ref = React.useRef();

	let {
		cellProps,
		buttonProps,
		isSelected,
		isDisabled,
		formattedDate
	} = useCalendarCell({ date }, state, ref);

	let isSelectionStart = state.highlightedRange
		? isSameDay(date, state.highlightedRange.start)
		: isSelected;
	let isSelectionEnd = state.highlightedRange
		? isSameDay(date, state.highlightedRange.end)
		: isSelected;

	const isStartEnd = isSelectionStart || isSelectionEnd;
	const isOutsideMonth = !isSameMonth(currentMonth, date);

	const todayDate = today(getLocalTimeZone());
	const isCurrenDay = isSameDay(date, todayDate);

	return (
		<td
			{...cellProps}
			className={cx(styles.td, {
				[styles.disabledCell]: isDisabled,
				[styles.tdMiddleRange]: isSelected && !isOutsideMonth
			})}
		>
			<div
				{...buttonProps}
				ref={ref}
				hidden={isOutsideMonth}
				className={cx(styles.cell, {
					[styles.selected]: isStartEnd,
					[styles.middleRange]: isSelected && !isStartEnd
				})}
			>
				<div
					className={cx(styles.cellContent, {
						[styles.cellContentSelected]: isSelected,
						[styles.disabledCellContent]: isDisabled,
						[styles.currentDay]: isCurrenDay
					})}
				>
					{formattedDate}
				</div>
			</div>
		</td>
	);
};

export default CalendarCell;
