/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { client } from "lib/api-client";
import CurrencyFormatter from "common/CurrencyFormater";
import { formatDate } from "common/Functions";
import _get from "lodash/get";
import TimesheetActionFiles from "./TimesheetActionFiles";
import {
	M_Y_FORMAT,
	DATE_FORMAT,
	APPROVED,
	APPROVAL_DATE,
	SUBMISSION_DATE,
	IN_MEDIATION,
	IN_MEDIATION_LABEL,
	TIME_FORMAT_24,
	TIMESHEET_FREQUENCY_WEEKLY
} from "config";
import {
	StatusLabel,
	TimesheetTableDetails,
	Th,
	Tr,
	Td,
	FileIcon
} from "./TimesheetStyled";
import ExtendedTimesheetIcon from "./Icons/ExtendedTimesheetIcon";
import GlobalTooltip from "common/GlobalTooltip";
import { colors } from "config/styles";
import Loader from "common/Loader";
import { EXPORT_TIMESHEET_DATA } from "config/api-endpoints";

const generateFileAction = data => {
	return client(EXPORT_TIMESHEET_DATA, { body: data });
};

const RowTableTimesheet = ({
	timesheet,
	timesheetMonth,
	viewDetails,
	view
}) => {
	const [loadPdf, setLoadPdf] = useState(false);
	const [loadXls, setLoadXls] = useState(false);
	const [mutate] = useMutation(generateFileAction);

	let generatedDayExcel = "";
	let generatedTimeExcel = "";
	if (timesheet.excel_link_download) {
		const fileDate = _get(
			timesheet.excel_link_download.split("_"),
			"1",
			""
		).split(".")[0];
		generatedDayExcel = formatDate(fileDate);
		generatedTimeExcel = formatDate(fileDate, true, TIME_FORMAT_24);
	}

	let generatedDayPdf = "";
	let generatedTimePdf = "";
	if (timesheet.pdf_link_download) {
		const fileDate = _get(
			timesheet.pdf_link_download.split("_"),
			"1",
			""
		).split(".")[0];
		generatedDayPdf = formatDate(fileDate);
		generatedTimePdf = formatDate(fileDate, true, TIME_FORMAT_24);
	}

	useEffect(() => {
		setLoadXls(false);
	}, [timesheet.excel_link_download]);
	useEffect(() => {
		setLoadPdf(false);
	}, [timesheet.pdf_link_download]);

	const generateFile = async type => {
		try {
			if (type === "pdf") {
				setLoadPdf(true);
			} else {
				setLoadXls(true);
			}
			await mutate({ id: timesheet._id, tag: type });
		} catch (error) {}
	};
	const timesheet_frequency =
		timesheet?.reporting_settings?.timesheet_frequency ===
		TIMESHEET_FREQUENCY_WEEKLY
			? "week"
			: "month";
	return (
		<Tr pointer key={timesheet._id}>
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				{formatDate(
					timesheet[`${view === APPROVED ? APPROVAL_DATE : SUBMISSION_DATE}`],
					DATE_FORMAT
				)}
			</Td>
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				{_get(timesheet, "job.title")}
			</Td>
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				{_get(timesheet, "user.first_name")} {_get(timesheet, "user.last_name")}
			</Td>
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				{timesheetMonth}{" "}
				{timesheet.extended && (
					<GlobalTooltip
						trigger={["hover"]}
						overlay={`This timesheet has been generated
								because your mission was extended
								during ${timesheetMonth}.
								It covers the work performed for the rest of that ${timesheet_frequency}.`}
						align={{
							points: ["bc", "tc"],
							offset: [0, 0]
						}}
					>
						<div>
							<ExtendedTimesheetIcon
								fill={colors.icon}
								hoverFill={colors.primary}
								width={20}
							/>
						</div>
					</GlobalTooltip>
				)}
			</Td>
			{view === APPROVED && (
				<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
					{<CurrencyFormatter cent={timesheet.total_amount_client} />}
				</Td>
			)}
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				<StatusLabel block status={timesheet.status}>
					{timesheet.status === IN_MEDIATION
						? IN_MEDIATION_LABEL
						: timesheet.status}
				</StatusLabel>
			</Td>
			{view === APPROVED && (
				<>
					<Td className="generateFiles" padding={"12px 18px"}>
						{loadPdf ? (
							<Loader classes={"small"}></Loader>
						) : (
							<>
								{timesheet.pdf_link_download ? (
									<GlobalTooltip
										trigger={["hover"]}
										overlay={`Generated ${generatedDayPdf} @ ${generatedTimePdf}`}
										align={{
											points: ["bc", "tc"],
											offset: [10, -10]
										}}
										active={generatedDayPdf && generatedTimePdf ? true : false}
									>
										<a
											href={`https://${timesheet.pdf_link_download?.slice(7)}`}
											target="_blank"
											rel="noreferrer noopener"
										>
											<FileIcon className="icon-pdf-download downloadIcones">
												<span className="path1"></span>
												<span className="path2"></span>
												<span className="path3"></span>
												<span className="path4"></span>
												<span className="path5"></span>
												<span className="path6"></span>
												<span className="path7"></span>
												<span className="path8"></span>
												<span className="path9"></span>
											</FileIcon>
										</a>
									</GlobalTooltip>
								) : (
									<FileIcon className="icon-pdf downloadIcones">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
										<span className="path4"></span>
										<span className="path5"></span>
										<span className="path6"></span>
										<span className="path7"></span>
										<span className="path8"></span>
									</FileIcon>
								)}
							</>
						)}

						{loadXls ? (
							<Loader classes={"small"}></Loader>
						) : (
							<>
								{timesheet.excel_link_download ? (
									<GlobalTooltip
										trigger={["hover"]}
										overlay={`Generated ${generatedDayExcel} @ ${generatedTimeExcel} `}
										align={{
											points: ["bc", "tc"],
											offset: [10, -10]
										}}
										active={
											generatedDayExcel && generatedTimeExcel ? true : false
										}
									>
										<a
											href={`https://${timesheet.excel_link_download?.slice(
												7
											)}`}
											target="_blank"
											rel="noreferrer noopener"
										>
											<FileIcon className="icon-xls-download downloadIcones">
												<span className="path1"></span>
												<span className="path2"></span>
												<span className="path3"></span>
												<span className="path4"></span>
												<span className="path5"></span>
												<span className="path6"></span>
												<span className="path7"></span>
												<span className="path8"></span>
												<span className="path9"></span>
											</FileIcon>
										</a>
									</GlobalTooltip>
								) : (
									<FileIcon className="icon-xls downloadIcones">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
										<span className="path4"></span>
										<span className="path5"></span>
										<span className="path6"></span>
										<span className="path7"></span>
									</FileIcon>
								)}
							</>
						)}
					</Td>
					<TimesheetActionFiles generateFile={generateFile}>
						<Td onClick={e => e.stopPropagation()}>
							<GlobalTooltip
								trigger={["hover"]}
								overlay={"Generate PDF/XLS"}
								align={{
									points: ["bc", "tc"],
									offset: [0, 0]
								}}
							>
								<span
									style={{ fontSize: "20px", cursor: "pointer" }}
									className="icon-retakepic refreshIcone"
								></span>
							</GlobalTooltip>
						</Td>
					</TimesheetActionFiles>
				</>
			)}
		</Tr>
	);
};

export default ({ timesheets, view, viewDetails }) => (
	<TimesheetTableDetails spacing="0 3px">
		<thead>
			<tr>
				<Th width={"14%"}>
					{view === APPROVED ? "Approval" : "Submission"} date
				</Th>
				<Th width={"16%"}>Mission title</Th>
				<Th width={"12%"}>Consultant</Th>
				<Th width={"10%"}>Month</Th>
				{view === APPROVED && <Th width={"8%"}>Total amount</Th>}
				<Th width={"12%"} align={"center"}>
					Status
				</Th>
				{view === APPROVED && (
					<>
						<Th width={"10%"} align={"center"}>
							Files
						</Th>
						<Th width={"4%"}></Th>
					</>
				)}
			</tr>
		</thead>
		<tbody>
			{timesheets.map((timesheet, index) => {
				const timesheetMonth = formatDate(
					timesheet.start_date,
					true,
					M_Y_FORMAT
				);
				return (
					<RowTableTimesheet
						key={index}
						timesheet={timesheet}
						timesheetMonth={timesheetMonth}
						viewDetails={viewDetails}
						view={view}
					/>
				);
			})}
		</tbody>
	</TimesheetTableDetails>
);
