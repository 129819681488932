import React from "react";
import cx from "classnames";
import styles from "./tour-popover.module.scss";

export default function TourPopover({ children, position = "top-right" }) {
	return (
		<div
			className={cx(styles.popoverContainer, styles[`radius-for-${position}`])}
		>
			<Arrow position={position} />
			{children}
		</div>
	);
}

function Arrow({ position = "top-right" }) {
	return (
		<div className={cx(styles.popoverArrow, styles[`arrow-${position}`])}>
			<svg
				width="24"
				height="16"
				viewBox="0 0 24 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 16C0 16 26.2314 15.9999 23.8467 16C21.4621 16.0001 19.0774 16.0001 15.5004 13.0001C13.128 11.0104 7.08418 5.94146 3.28256 2.75306C1.98107 1.66151 -7.46135e-07 2.58905 -6.5163e-07 4.28768L0 16Z"
					fill="currentColor"
				/>
			</svg>
		</div>
	);
}

function Header({ children }) {
	return <h3 className={styles.popoverHeader}>{children}</h3>;
}
function Body({ children }) {
	return <p className={styles.popoverBody}>{children}</p>;
}

function Footer({ children }) {
	return <div className={styles.popoverFooter}>{children}</div>;
}
function Step({ children }) {
	return <p className={styles.popoverStep}>{children}</p>;
}
function ButtonsWrapper({ children }) {
	return <div className={styles.popoverBtnWrapper}>{children}</div>;
}
function Button({ children, variant = "default", ...restProps }) {
	const variantClassName =
		variant === "link"
			? styles.popoverButton__link
			: styles.popoverButton__default;

	return (
		<button
			{...restProps}
			className={`${styles.popoverButton} ${variantClassName}`}
		>
			{children}
		</button>
	);
}

TourPopover.Body = Body;
TourPopover.Step = Step;
TourPopover.Header = Header;
TourPopover.Button = Button;
TourPopover.Footer = Footer;
TourPopover.ButtonsWrapper = ButtonsWrapper;
