import React, { memo } from "react";
import { ReactComponent as DeleteIcon } from "static/icons/xDelete.svg";
import style from "../../my-company.module.scss";

const ToggleInput = ({
	modeEdit,
	value,
	onChange,
	isSuperAdmin = false,
	isOwner = false,
	removeInput,
	initialValue = ""
}) => {
	const canEditEmpty = isSuperAdmin && modeEdit && !initialValue;
	const canEdit = isOwner && modeEdit;
	return (
		<>
			{canEdit || canEditEmpty ? (
				<div className={style.inputStyleContainer}>
					<input
						className={style.inputStyle}
						type="text"
						name="name"
						value={value}
						onChange={onChange}
					/>
					{removeInput && (
						<button className={style.deletBtn} onClick={() => removeInput()}>
							<DeleteIcon />
						</button>
					)}
				</div>
			) : (
				<div className={style.textLabel}>{value}</div>
			)}
		</>
	);
};

export default memo(ToggleInput);
