import { createSelector } from "reselect";
import _get from "lodash/get";

const getPreference = state => {
	const preference = _get(state, "cv.preference", {});
	const formattedPref = {
		...preference,
		...preference.address
	};
	delete formattedPref.address;
	return formattedPref;
};

export const preferenceSelector = createSelector(
	getPreference,
	preference => preference
);
