//TODO remove fake data request id
//TODO use constant for request informations route
import { useAddRequestDetails } from "./useAddRequestDetails";
import { historyPush } from "config/helpers";
import { buildEditVacancyInformationsPayload } from "../utils/helper";

export const useCreateRequest = () => {
	const [addVacancyVacancyInformations, { isLoading }] = useAddRequestDetails();

	const onPublish = (values, vacancyId, { onSuccess, ...rest }) => {
		const body = buildEditVacancyInformationsPayload(values, vacancyId);

		addVacancyVacancyInformations(body, {
			onSuccess: () => {
				onSuccess?.();
				historyPush(`/job/${vacancyId}`);
			},
			...rest
		});
	};

	return [onPublish, isLoading];
};
