import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { SAVE_FEATURE_TIMESHEET_SETTINGS } from "config/api-endpoints";

const saveTimesheet = data => {
	return client(SAVE_FEATURE_TIMESHEET_SETTINGS, {
		body: { ...data }
	});
};

const useUpdateTimesheets = (options, queryOptions = {}) =>
	useMutation(saveTimesheet, {
		...queryOptions
	});
export default useUpdateTimesheets;
