import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { OVERVIEW_ENDPOINT } from "config/api-endpoints";
import fakeProfile from "modules/user-onboarding/mockData/profile.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import {
	HOW_TO_SOURCE_AND_INVITE_CANDIDATE,
	HOW_TO_WORK_WITH_NOTES
} from "config/onboarding";

const viewOverview = (_, { id, email }) =>
	client(OVERVIEW_ENDPOINT, {
		body: {
			id,
			limit: 4,
			email: email ?? undefined
		}
	});
export const viewOverviewKey = "@candidate/viewOverview";

const useViewOverview = (options, queryOptions = {}) => {
	const { workflowId } = useOnboardingStore();
	const fetchData = [
		HOW_TO_SOURCE_AND_INVITE_CANDIDATE,
		HOW_TO_WORK_WITH_NOTES
	].includes(workflowId)
		? () => new Promise(r => r(fakeProfile))
		: viewOverview;

	return useQuery([viewOverviewKey, options, workflowId], fetchData, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
};

export default useViewOverview;
