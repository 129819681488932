import React from "react";
const SortDownIcon = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.99967 3.3335V12.6668M7.99967 12.6668L12.6663 8.00016M7.99967 12.6668L3.33301 8.00016"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default SortDownIcon;
