import * as React from "react";
import cx from "classnames";
import styles from "./create-vacancy-language-tag.module.scss";

const CreateVacancyLanguageTag = ({
	children,
	key,
	label,
	hasError,
	className
}) => {
	return (
		<div
			className={cx(styles.tag, className, {
				[styles.hasError]: hasError
			})}
			key={key}
		>
			<label className={styles.tagLabel}>{label}</label>
			<hr className={styles.tagHr} />
			{children}
		</div>
	);
};

export default React.memo(CreateVacancyLanguageTag);
