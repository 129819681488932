import React from "react";
import EmptyState from "modules/home/components/EmptyState.jsx";
import suppliersemptystate from "../../images/suppliersemptystate.png";

const SuppliersEmptyState = ({ onClick, label, icon, heading }) => {
	return (
		<EmptyState
			illustration={<img src={suppliersemptystate} />}
			heading={heading}
			firstAction={{
				label,
				onClick,
				icon
			}}
		/>
	);
};

export default SuppliersEmptyState;
