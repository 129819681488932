import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";

function getCandidateRejection() {
	return client(NEW_FILTER_SETTINGS, {
		body: {
			list: "custom_fields_manager",
			tag: "candidate_rejection"
		}
	});
}

export const GET_CUSTOM_FIELD_OPTIONS_KEY = "GET_CANDIDATE_REJECTION_KEY";

const useGetCandidateRejection = queryOptions => {
	return useQuery(GET_CUSTOM_FIELD_OPTIONS_KEY, getCandidateRejection, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
};

export default useGetCandidateRejection;
