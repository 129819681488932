import React from "react";

const MenuButton = ({ setOpenMenu, children, styles }) => {
	return (
		<button
			style={{
				border: "none",
				padding: "0px",
				backgroundColor: "transparent",

				...styles
			}}
			onClick={() => setOpenMenu(true)}
		>
			{children}
		</button>
	);
};

export default MenuButton;
