import { useEffect, useState } from "react";
import loadable from "loadable-components";
import { capitalize } from "lodash";
import {
	API_DATA_TYPE,
	CHECK_FILTER_COLUMNS,
	MISSIONS_LIST_FILTER_ID,
	columnFiltersMap,
	columnSortMap
} from "../components/_MissionsListUtils/missionsListUtils";
import MissionsListSelectColumn from "../components/MissionsListSelectColumn/MissionsListSelectColumn";
import MissionsListTableCell from "../components/MissionsListTableCell/MissionsListTableCell";

const TableMenu = loadable(() =>
	import("common/AgTable/components/MenuColumn")
);
const LoadingStateCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/LoadingStateCell/LoadingStateCell"
	)
);

export const useMissionsListColumns = ({
	isLoading,
	isFetching,
	isSelectAll,
	onSelectAll,
	tableRef,
	columns
}) => {
	const [columnsList, setColumnsList] = useState([]);

	useEffect(() => {
		const columnsSettings = columns?.map(column => {
			return {
				field: column.headerName,
				width: column.width,
				minWidth: `${column.colId === "last_mission" ? 160 : 80}`,
				colId: column.colId,
				hide: column.hide,
				pinned: column.pinned ?? null,
				filter: TableMenu,
				cellRenderer:
					isLoading || isFetching ? LoadingStateCell : MissionsListTableCell,
				cellClass: "locked-visible",
				lockVisible: true,
				refGrid: tableRef,
				typeTag: API_DATA_TYPE,
				currentFilterId: MISSIONS_LIST_FILTER_ID,
				checkFilterColumns: CHECK_FILTER_COLUMNS,
				columnSortMap: columnSortMap,
				columnFiltersMap: columnFiltersMap,
				isSortable: column.sortable,
				headerName: capitalize(column.headerName)
			};
		});

		const newColumns = [
			{
				colId: "_selects",
				field: "",
				width: 50,
				resizable: false,
				hide: false,
				filter: false,
				pinned: "left",
				lockVisible: true,
				lockPinned: true,
				lockPosition: "left",
				cellClass: "locked-col lock-pinned centered",
				cellRenderer:
					isLoading || isFetching ? LoadingStateCell : MissionsListSelectColumn
			},
			...(columnsSettings ?? [])
		];
		setColumnsList(newColumns);
	}, [columns, onSelectAll, isFetching, isLoading, isSelectAll]);

	return columnsList;
};
