import usePoolStore from "modules/vacancy/components/candidatePool/zustandPool";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import { withRouter } from "react-router";
import { usePaginatedQuery } from "react-query";
import { OFFSET, CANDIDATE_POOL, SEARCH_POOL } from "config";
import { client } from "lib/api-client";
import {
	SEARCH_PROFILE_ENDPOINT,
	API_CANDIDATE_POOL
} from "config/api-endpoints";
import { get } from "lodash";
import { useMemo } from "react";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getProfiles = (_key, filters, view) => {
	return client(
		view === CANDIDATE_POOL ? API_CANDIDATE_POOL : SEARCH_PROFILE_ENDPOINT,
		{
			body: {
				...filters,
				tag: view === CANDIDATE_POOL ? SEARCH_POOL : undefined
			}
		}
	);
};

function ViewCandidateHelper({
	render,
	location: { search },
	router,
	onNextClick,
	onPreviousClick
}) {
	const {
		params: { id: profile_id }
	} = router;

	const {
		filters,
		updateFilter,
		setProfilesIds,
		profilesIds,
		setIsNext,
		setIsPrevious,
		isNext,
		isPrevious,
		view
	} = usePoolStore(state => ({
		filters: state.filters,
		setProfilesIds: state.setProfilesIds,
		updateFilter: state.updateFilter,
		profilesIds: state.profilesIds,
		setIsNext: state.setIsNext,
		setIsPrevious: state.setIsPrevious,
		isNext: state.isNext,
		isPrevious: state.isPrevious,
		setIsFetching: state.setIsFetching,
		view: state.view
	}));

	const currentIndex = profilesIds.indexOf(profile_id);

	const goToProfile = id =>
		router.replace(getPermanentProfileRoute(id) + search);

	const { workflowId } = useOnboardingStore();
	const { resolvedData } = usePaginatedQuery(
		["profiles", filters, view],
		getProfiles,
		{
			onSuccess: resolvedData => {
				const profiles_ids = resolvedData.data.map(profile => profile.id);
				resolvedData.data.length > 0 && setProfilesIds(profiles_ids);
				updateFilter(OFFSET, resolvedData.offset);
				if (isNext) {
					goToProfile(profiles_ids[0]);
					setIsNext(false);
				}
				if (isPrevious) {
					goToProfile(profiles_ids[profiles_ids.length - 1]);
					setIsPrevious(false);
				}
			},
			refetchOnWindowFocus: false,
			enabled: !workflowId
		}
	);

	const size = get(resolvedData, "size", 0);
	const total = get(resolvedData, "total", 0);

	const nextProfile = () => {
		const currentIndex = profilesIds.indexOf(profile_id);

		if (currentIndex < profilesIds.length - 1) {
			goToProfile(profilesIds[currentIndex + 1]);
			onNextClick?.();
			return;
		}
		setIsNext(true);

		updateFilter(OFFSET, filters.offset + size);
	};

	const previousProfile = () => {
		const currentIndex = profilesIds.indexOf(profile_id);

		if (currentIndex > 0) {
			goToProfile(profilesIds[currentIndex - 1]);
			onPreviousClick?.();
			return;
		}
		setIsPrevious(true);
		updateFilter(OFFSET, Math.max(filters.offset - size, 0));
	};

	const isPreviousDisabled =
		filters.offset === 0 && profilesIds.indexOf(profile_id) === 0;
	const isNextDisabled =
		filters.offset + size >= total &&
		profilesIds.indexOf(profile_id) === profilesIds.length - 1;
	const nextProfileID = useMemo(
		() =>
			profilesIds.indexOf(profile_id) !== profilesIds.length - 1
				? profilesIds[currentIndex + 1]
				: profilesIds[currentIndex - 1],
		[currentIndex]
	);

	return render({
		previousProfile,
		nextProfile,
		isNextDisabled,
		isPreviousDisabled,
		goToProfile,
		nextProfileID
	});
}

// TODO I think we need to create hook instead HOC
export default withRouter(ViewCandidateHelper);
