import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import styles from "./missions-details-container.module.scss";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { MissionDetailsCollapse } from "./components/MissionDetailsCollapse";
import { MissionDetailsHeader } from "./components/MissionDetailsHeader";
import useGetMissionDetails from "./hooks/useGetMissionDetails";
import { useSelector } from "react-redux";
import useSessionCardDisplay from "./hooks/useSessionCardDisplay";
import { MissionExtensionsList } from "./components/MissionExtensionsList";
import {
	FEATURE_TIMESHEET_TAGS,
	FEATURE_EDIT_MISSION,
	FEATURE_EXTEND_MISSION,
	FEATURE_INTERRUPT_MISSION
} from "config";
import loadable from "loadable-components";
import { dateToTimestamp, timestampToDateCalendar } from "config/helpers";
import { getLocalTimeZone, today } from "@internationalized/date";
import {
	_getInterruptedStartDate,
	_getSortedExtensionList,
	getTooltipContent
} from "./utils/_helper";
import { isUserHasRole } from "hooks/isUserHasRole";
import { PROJECT_MANAGER } from "config";

const MissionEditDrawer = loadable(() =>
	import("./components/MissionEditDrawrer")
);

const ExtendMissionDrawer = loadable(() =>
	import(
		"modules/MissionsList/components/ExtendMissionDrawer/ExtendMissionDrawer"
	)
);
const MissionInterruptDrawer = loadable(() =>
	import("./components/MissionInterruptDrawer/MissionInterruptDrawer")
);

const MissionDetailsPermissions = loadable(() =>
	import("./components/MissionDetailsPermissions/MissionDetailsPermissions")
);

const MissionDetailsHistory = loadable(() =>
	import("./components/MissionDetailsHistory/MissionDetailsHistory")
);

const MissionTimesheets = loadable(() =>
	import("./components/MissionTimesheets/MissionTimesheets")
);

const MissionDetailsInfo = loadable(() =>
	import("./components/MissionDetailsInfo/MissionDetailsInfo")
);

export const getMissionDetailsContainerRoute = id => {
	return `/missions/${id || `:id`}`;
};

export const getMissionDetailsContainerPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

function MissionDetailsContainer({ params: { id } }) {
	const [openInterruptDrawer, setOpenInterruptDrawer] = useState(false);
	const [openExtensionDrawer, setOpenExtensionDrawer] = useState(false);
	const [openEditMissionDrawer, setOpenEditMissionDrawer] = useState(false);
	const isProjectManager = isUserHasRole(PROJECT_MANAGER);
	const [tooltipContent, setTooltipContent] = useState({});

	const [settings, toggle] = useSessionCardDisplay();
	const { data } = useGetMissionDetails(id);

	const [permissionCount, setPermissionCount] = useState(0);
	const [extensionsCount, setExtensionsCount] = useState(0);

	const features = useSelector(state => state.auth?.user?.features);
	const hasFeatUpdateTags = features.includes(FEATURE_TIMESHEET_TAGS);
	const canUserEditMission = features.includes(FEATURE_EDIT_MISSION);
	const canUserExtendMission = features.includes(FEATURE_EXTEND_MISSION);
	const canUserInterruptMission = features.includes(FEATURE_INTERRUPT_MISSION);

	const isInterrupted =
		data?.status === "interrupted" || data?.interrupt !== "";

	const currentDay = today(getLocalTimeZone());
	const isStartDateInFuture =
		timestampToDateCalendar(data?.start_date) > currentDay;

	const isMissionFinished = data?.status === "finished";

	const lastExtension =
		data && _getSortedExtensionList(data.extended, "new_mission_start_date");

	const isLastExtensionInFuture =
		lastExtension &&
		currentDay < timestampToDateCalendar(lastExtension.new_mission_start_date);

	const setItems = useBreadCrumbs(state => state.setItems);

	const lastTimesheetDate = data?.last_timesheet_date;

	useEffect(() => {
		setItems([
			{ name: "Temporary" },
			{
				name: "Missions",
				path: "/missions"
			},
			{
				name: data?.job?.title
			}
		]);
	}, [data]);

	const onOpenExtensionDrawer = () => {
		setOpenExtensionDrawer(true);
	};
	const onOpenInterruptDrawer = () => {
		setOpenInterruptDrawer(true);
	};

	const interrupteDate = _getInterruptedStartDate(
		data?.reporting_settings?.timesheet_frequency,
		dateToTimestamp(`${currentDay.day}-${currentDay.month}-${currentDay.year}`)
	);

	const isInterruptDisabled = interrupteDate / 1000 > data?.end_date;

	const isExtensionEnabled = isMissionFinished;

	const isInterruptDisable =
		isInterrupted ||
		isMissionFinished ||
		isStartDateInFuture ||
		isInterruptDisabled;

	useEffect(() => {
		const tooltipContent = getTooltipContent({
			isMissionFinished,
			isStartDateInFuture,
			isInterrupted,
			isLastExtensionInFuture,
			isInterruptDisabled
		});

		setTooltipContent(tooltipContent);
	}, [
		isMissionFinished,
		isStartDateInFuture,
		isInterrupted,
		isLastExtensionInFuture,
		isInterruptDisabled
	]);

	return (
		<div className={styles.container}>
			<MissionDetailsHeader
				onOpenExtensionDrawer={onOpenExtensionDrawer}
				onOpenInterruptDrawer={onOpenInterruptDrawer}
				setOpenEditMissionDrawer={setOpenEditMissionDrawer}
				isExtensionEnabled={isExtensionEnabled}
				isInterruptDisable={isInterruptDisable}
				tooltipContent={tooltipContent}
				canUserEditMission={canUserEditMission}
				canUserExtendMission={canUserExtendMission}
				canUserInterruptMission={canUserInterruptMission}
			/>
			<div className={styles.separator}></div>
			<MissionDetailsInfo id={id} />
			<MissionDetailsCollapse
				title="Timesheets overview"
				isOpen={settings.timeSheets}
				onToggle={() => toggle("timeSheets")}
			>
				<MissionTimesheets title={data?.job.title} missionId={id} />
			</MissionDetailsCollapse>

			<div className={styles.flex}>
				<div className={styles.flexColumn}>
					<MissionDetailsCollapse
						title="Extensions"
						isOpen={settings.extensions}
						onToggle={() => toggle("extensions")}
						count={extensionsCount}
					>
						<MissionExtensionsList
							mission_id={id}
							isInterrupted={isInterrupted}
							setExtensionsCount={setExtensionsCount}
							onOpenExtensionDrawer={onOpenExtensionDrawer}
							missionDetails={data}
							lastExtension={lastExtension}
							isExtensionEnabled={isExtensionEnabled}
							vmsSettings={data?.vms_settings}
							lastTimesheetDate={lastTimesheetDate}
							canUserEditMission={canUserEditMission}
						/>
					</MissionDetailsCollapse>
					{hasFeatUpdateTags && !isProjectManager && (
						<MissionDetailsCollapse
							title="Permissions"
							isOpen={settings.permissions}
							onToggle={() => toggle("permissions")}
							count={permissionCount}
						>
							<MissionDetailsPermissions
								contract_id={_get(data, "_id")}
								isEnabled={_get(data, "timesheet_tags.is_enabled")}
								timesheetTagsName={_get(
									data,
									"timesheet_tags.timesheet_tags_name"
								)}
								setPermissionCount={setPermissionCount}
							/>
						</MissionDetailsCollapse>
					)}
				</div>
				<MissionDetailsCollapse
					title="History"
					isOpen={settings.history}
					onToggle={() => toggle("history")}
				>
					<MissionDetailsHistory
						id={id}
						openEditDrawer={() => setOpenEditMissionDrawer(true)}
					/>
				</MissionDetailsCollapse>
			</div>

			{openInterruptDrawer && (
				<MissionInterruptDrawer
					isOpen={openInterruptDrawer}
					onClose={() => setOpenInterruptDrawer(false)}
					onSuccess={() => setOpenInterruptDrawer(false)}
					missionId={id}
					interrupteDate={interrupteDate}
				/>
			)}

			{openExtensionDrawer && (
				<ExtendMissionDrawer
					isOpen={openExtensionDrawer}
					closeDrawer={() => setOpenExtensionDrawer(false)}
					data={data}
				/>
			)}

			{openEditMissionDrawer && (
				<MissionEditDrawer
					isOpen={openEditMissionDrawer}
					closeDrawer={() => setOpenEditMissionDrawer(false)}
					missionInfos={data}
					isInterrupted={isInterrupted}
					isMissionFinished={isMissionFinished}
				/>
			)}
		</div>
	);
}

export default MissionDetailsContainer;
