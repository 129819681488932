import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { ADD_COMPANY_SITE } from "config/api-endpoints";

const addSite = data => {
	return client(ADD_COMPANY_SITE, {
		body: { ...data }
	});
};

const useAddCompanySite = options => {
	return useMutation(addSite, options);
};

export default useAddCompanySite;
