import { useQuery } from "react-query";
import { client } from "../../../lib/api-client";
import { LIST_SKILLS } from "../../../config/api-endpoints";

const listSkills = body =>
	client(LIST_SKILLS, {
		body: {
			...body,
			limit: 150,
			offset: 0
		}
	});

const useListSkills = ({ sector, term }) => {
	return useQuery(
		["@supplier/list-skills", sector, term],
		() => listSkills({ sector_id: sector, term }),
		{
			refetchOnWindowFocus: false,
			enabled: sector
		}
	);
};

export default useListSkills;
