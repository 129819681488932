//TODO We can use reset to initialize filter
import { useEffect } from "react";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import { searchParser } from "config/helpers";
import {
	COLUMNS,
	COMPONENT_BY_OPERATOR,
	OPERATORS_BY_TYPE,
	OPTION_TYPES,
	SOURCES,
	CANDIDATES_FILTER,
	CANDIDATES_MODULE_ID
} from "../utils/constants";
import { findColumn, reduceColumns } from "../utils/helpers";

function getValues() {
	const columnsReduced = reduceColumns();
	const column = findColumn(columnsReduced, "source");
	const value = [
		{
			id: 1,
			label: "sources",
			children: [
				{ label: "proactive application", id: "proactive application" }
			]
		}
	];
	const filter = {
		name: column.name,
		type: column.type,
		operator: column.operator.value,
		label: column.label,
		payload: column.payload,
		value
	};

	return [filter];
}

export const useInitializeFilterDrawer = () => {
	const { setFilterSetting, resetFilters, isFilterExist } = useFilters();

	useEffect(() => {
		const { source } = searchParser();
		let options;

		if (source === "proactive_applications_list") {
			options = {
				filters: getValues(),
				isLoading: true,
				isFetching: true
			};
			if (isFilterExist(CANDIDATES_FILTER)) {
				resetFilters(CANDIDATES_FILTER);
			}
		}

		setFilterSetting(
			OPTION_TYPES,
			OPERATORS_BY_TYPE,
			COMPONENT_BY_OPERATOR,
			SOURCES,
			null,
			COLUMNS,
			CANDIDATES_FILTER,
			CANDIDATES_MODULE_ID,
			true,
			options
		);
	}, []);
};
