import { browserHistory } from "react-router";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _get from "lodash/get";
import * as types from "./actionTypes";
import axiosInstance from "config/axios";
import { HTTP_POST_METHOD, RESPONSE_OK, PROJECT_MANAGER } from "config";
import {
	LIST_BIDS_ENDPOINT,
	LIST_BIDS,
	GET_BID,
	CANCEL_BID_ENDPOINT
} from "config/api-endpoints";
import toaster from "common/Toaster";
import AxiosHelper from "config/axios-helper";
import { prepareForSelect } from "config/helpers";
import { consultantsGroupsSelector } from "../selectors";

export function toggleAction() {
	return { type: types.TOGGLE_ACTION };
}

export const setConsultants = consultants => ({
	type: types.SET_CONSULTANTS,
	consultants
});

export const getConsultants = unit => {
	return (dispatch, getState) => {
		const groups = consultantsGroupsSelector(getState());
		const users = _filter(_get(_find(groups, { _id: unit }), "users", {}), {
			role_name: PROJECT_MANAGER
		});
		return dispatch(setConsultants(prepareForSelect(users, "user_name")));
	};
};

function vendorDoneFetching(pagination, isPagination, listBids) {
	return {
		type: types.BIDS_LIST_DONE_VENDOR,
		pagination,
		isPagination,
		listBids
	};
}

export function clientDoneFetching(
	pagination,
	isPagination,
	listBids,
	jobInfo
) {
	return {
		type: types.BIDS_LIST_DONE_CLIENT,
		pagination,
		isPagination,
		listBids,
		jobInfo
	};
}

function writeBidData(data) {
	return {
		type: types.WRITE_BID_DATA,
		data
	};
}

export function cancelBidSuccess() {
	return dispatch => {
		dispatch(toggleAction());
	};
}

function setFreelancerBidsList(status) {
	return {
		type: types.FILTER_BY_STATUS,
		status
	};
}

export function filterFreelancerBidsList(payload, status) {
	return dispatch => {
		dispatch(setFreelancerBidsList(status));
		dispatch(freelancerBidsList(payload));
	};
}

export function freelancerBidsList(data = { offset: 0 }) {
	return AxiosHelper.__post({
		data,
		url: LIST_BIDS_ENDPOINT,
		next: data => dispatch => {
			const pagination = data.pagination;
			return dispatch(
				vendorDoneFetching(
					pagination,
					pagination.total > pagination.size,
					data.bids
				)
			);
		}
	});
}

export function consultancyListBids(payload = {}) {
	return freelancerBidsList(payload);
}

export function clientListBids(data) {
	return dispatch => {
		dispatch(toggleAction());
		axiosInstance({
			method: HTTP_POST_METHOD,
			url: LIST_BIDS,
			data: JSON.stringify(data)
		})
			.then(response => {
				if (response && response.status === RESPONSE_OK) {
					const pagination = response.data.pagination;
					const listBids = response.data.bids;
					const jobInfo = response.data.job;
					const isPagination =
						response.data.pagination.total > response.data.pagination.size;
					dispatch(
						clientDoneFetching(pagination, isPagination, listBids, jobInfo)
					);
				}
			})
			.catch(() => {
				browserHistory.push("/404");
			});
	};
}

export function getBidData(bid_id) {
	return async dispatch => {
		dispatch(toggleAction());
		const response = await axiosInstance({
			method: HTTP_POST_METHOD,
			url: GET_BID,
			data: JSON.stringify({ bid_id })
		});
		if (response && response.status === RESPONSE_OK) {
			if (response && response.status === RESPONSE_OK) {
				dispatch(writeBidData(response.data));
				return response;
			}
		}
	};
}

export function cancelBid({ bid_id, stepper_status, id }) {
	return dispatch => {
		dispatch(toggleAction());
		return axiosInstance({
			method: HTTP_POST_METHOD,
			url: CANCEL_BID_ENDPOINT,
			data: JSON.stringify({
				id,
				stepper_status
			})
		})
			.then(response => {
				dispatch(cancelBidSuccess());
				dispatch(getBidData(bid_id)).then(response => {
					dispatch({ type: types.FETCH_BID_SUCCEEDED, data: response.data });
					dispatch(freelancerBidsList());
					toaster.success("The application has been cancelled.");
				});
				return !!response.data;
			})
			.catch(error => {
				dispatch(toggleAction());
				throw error;
			});
	};
}
