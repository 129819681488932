import React from "react";
const CompanyIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<rect
				x="300.6"
				y="135.4"
				className="notification-icon"
				width="39.8"
				height="27.5"
			/>
			<polygon
				className="notification-icon"
				points="159.5,135.8 196.9,135.8 196.9,164.3 159.5,161.9 	"
			/>
			<polygon
				className="notification-icon"
				points="269,135.8 269,164.3 231,162.4 231,135.8 	"
			/>
			<rect
				x="250"
				y="312.4"
				className="notification-icon"
				width="37.8"
				height="56"
			/>
			<g transform="translate(1 1)">
				<path
					className="notification-icon-origin"
					d="M367.9,372.7H130.1c-2.9,0-4.8-1.9-4.8-4.8V111c0-2.9,1.9-4.8,4.8-4.8h237.9c2.9,0,4.8,1.9,4.8,4.8v256.9
			C372.7,370.8,370.8,372.7,367.9,372.7z M134.8,363.2h228.4V115.8H134.8V363.2z"
				/>
				<path
					className="notification-icon-origin"
					d="M387,391.7H111c-2.9,0-4.8-1.9-4.8-4.8v-19c0-2.9,1.9-4.8,4.8-4.8H387c2.9,0,4.8,1.9,4.8,4.8v19
			C391.7,389.8,389.8,391.7,387,391.7z M115.8,382.2h266.4v-9.5H115.8V382.2z"
				/>
				<path
					className="notification-icon-origin"
					d="M196.7,168.1h-38.1c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8h38.1c2.9,0,4.8,1.9,4.8,4.8v28.5
			C201.4,166.2,199.5,168.1,196.7,168.1z M163.4,158.6h28.5v-19h-28.5V158.6z"
				/>
				<path
					className="notification-icon-origin"
					d="M196.7,220.5h-38.1c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8h38.1c2.9,0,4.8,1.9,4.8,4.8v28.5
			C201.4,218.6,199.5,220.5,196.7,220.5z M163.4,210.9h28.5v-19h-28.5V210.9z"
				/>
				<path
					className="notification-icon-origin"
					d="M268,220.5H230c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8H268c2.9,0,4.8,1.9,4.8,4.8v28.5
			C272.8,218.6,270.9,220.5,268,220.5z M234.7,210.9h28.5v-19h-28.5V210.9z"
				/>
				<path
					className="notification-icon-origin"
					d="M339.4,220.5h-38.1c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8h38.1c2.9,0,4.8,1.9,4.8,4.8v28.5
			C344.2,218.6,342.3,220.5,339.4,220.5z M306.1,210.9h28.5v-19h-28.5V210.9z"
				/>
				<path
					className="notification-icon-origin"
					d="M268,272.8H230c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8H268c2.9,0,4.8,1.9,4.8,4.8V268
			C272.8,270.9,270.9,272.8,268,272.8z M234.7,263.3h28.5v-19h-28.5V263.3z"
				/>
				<path
					className="notification-icon-origin"
					d="M339.4,272.8h-38.1c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8h38.1c2.9,0,4.8,1.9,4.8,4.8V268
			C344.2,270.9,342.3,272.8,339.4,272.8z M306.1,263.3h28.5v-19h-28.5V263.3z"
				/>
				<path
					className="notification-icon-origin"
					d="M196.7,272.8h-38.1c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8h38.1c2.9,0,4.8,1.9,4.8,4.8V268
			C201.4,270.9,199.5,272.8,196.7,272.8z M163.4,263.3h28.5v-19h-28.5V263.3z"
				/>
				<path
					className="notification-icon-origin"
					d="M268,168.1H230c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8H268c2.9,0,4.8,1.9,4.8,4.8v28.5
			C272.8,166.2,270.9,168.1,268,168.1z M234.7,158.6h28.5v-19h-28.5V158.6z"
				/>
				<path
					className="notification-icon-origin"
					d="M339.4,168.1h-38.1c-2.9,0-4.8-1.9-4.8-4.8v-28.5c0-2.9,1.9-4.8,4.8-4.8h38.1c2.9,0,4.8,1.9,4.8,4.8v28.5
			C344.2,166.2,342.3,168.1,339.4,168.1z M306.1,158.6h28.5v-19h-28.5V158.6z"
				/>
				<path
					className="notification-icon-origin"
					d="M287.1,372.7H249c-2.9,0-4.8-1.9-4.8-4.8v-57.1c0-2.9,1.9-4.8,4.8-4.8h38.1c2.9,0,4.8,1.9,4.8,4.8v57.1
			C291.8,370.8,289.9,372.7,287.1,372.7z M253.8,363.2h28.5v-47.6h-28.5V363.2z"
				/>
				<path
					className="notification-icon-origin"
					d="M249,372.7h-38.1c-2.9,0-4.8-1.9-4.8-4.8v-57.1c0-2.9,1.9-4.8,4.8-4.8H249c2.9,0,4.8,1.9,4.8,4.8v57.1
			C253.8,370.8,251.9,372.7,249,372.7z M215.7,363.2h28.5v-47.6h-28.5V363.2z"
				/>
				<path
					className="notification-icon-origin"
					d="M277.5,296.6h-57.1c-2.9,0-4.8-1.9-4.8-4.8s1.9-4.8,4.8-4.8h57.1c2.9,0,4.8,1.9,4.8,4.8
			S280.4,296.6,277.5,296.6z"
				/>
			</g>
		</g>
	</svg>
);
export default CompanyIcon;
