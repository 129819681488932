import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
//components
import FreelancerBidView from "./FreelancerBidView";
import {
	toggleInterviewModal,
	fetchBid,
	interviewDispatch,
	acceptInterview,
	cancelInterview,
	refuseInterview,
	acceptContract,
	toggleContractModal,
	ProposeContract,
	showVideoConf,
	hideVideoConf
} from "../../../actions/bidActions";
import { bidSelector, showVideoConfSelector } from "../../../selectors";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { getSteps } from "../client/ClientBidViewContainer";
import { toggleHelpModal } from "modules/app/actions/ListsActions";

export const getFreelancerBidViewRoute = id => {
	return `/jobs/freelancer/bids/${id || `:id`}`;
};

export const getFreelancerBidViewPermission = () => {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			}
		]
	};
};

export class FreelancerBidViewContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: []
		};
	}

	componentDidMount() {
		const { params } = this.props;
		if (params && params.id) {
			this.props.actions.fetchBid(params.id);
		}
		const hasVideoCall = _get(this.props, "location.query.videocall", false);
		if (Boolean(hasVideoCall)) {
			this.props.actions.showVideoConf();
		}
	}

	componentDidUpdate({ params }) {
		const {
			params: { id },
			actions
		} = this.props;
		if (id && params.id !== id) {
			actions.fetchBid(id);
		}
	}

	handleAcceptInterview = (data, nextAction) => {
		this.props.actions.acceptInterview(data, nextAction);
	};

	render() {
		const {
			route,
			params,
			bid,
			user,
			actions: {
				toggleInterviewModal,
				interviewDispatch,
				cancelInterview,
				refuseInterview,
				acceptContract,
				toggleContractModal,
				ProposeContract,
				toggleHelpModal,
				showVideoConf: showVideoConfAction,
				hideVideoConf
			},
			showVideoConf
		} = this.props;
		return (
			<FreelancerBidView
				route={route}
				params={params}
				{...bid}
				user={user}
				toggleInterviewModal={toggleInterviewModal}
				acceptInterview={this.handleAcceptInterview}
				interviewDispatch={interviewDispatch}
				onCancel={cancelInterview}
				onRefuse={refuseInterview}
				acceptContract={acceptContract}
				ProposeContract={ProposeContract}
				toggleContractModal={toggleContractModal}
				getSteps={getSteps}
				toggleHelpModal={toggleHelpModal}
				showVideoConf={showVideoConf}
				onCallEnd={hideVideoConf}
				onCallStart={showVideoConfAction}
				files={this.state.files}
				setFiles={files => this.setState({ files })}
			/>
		);
	}
}

FreelancerBidViewContainer.propTypes = {
	actions: PropTypes.object.isRequired,
	params: PropTypes.shape({
		id: PropTypes.string.isRequired
	}).isRequired,
	bid: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	bid: bidSelector(state),
	user: onlineUserSelector(state),
	showVideoConf: showVideoConfSelector(state)
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(
		{
			fetchBid,
			toggleInterviewModal,
			interviewDispatch,
			acceptInterview,
			cancelInterview,
			refuseInterview,
			ProposeContract,
			acceptContract,
			toggleContractModal,
			toggleHelpModal,
			showVideoConf,
			hideVideoConf
		},
		dispatch
	)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FreelancerBidViewContainer);
