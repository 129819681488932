import { useQuery } from "react-query";
import { FILTER_SETTINGS_NOTES } from "config/api-endpoints";
import { client } from "lib/api-client";
import { API_DATA_TYPE } from "../utils/constant";

const getSettings = () => {
	return client(FILTER_SETTINGS_NOTES, {
		body: { tag: "search_tasks_table" }
	});
};

export const GET_TASKS_TABLE_SETTINGS_QUERY_KEY = `@getColumnsSettings_${API_DATA_TYPE}`;

function useGetTasksTableSettings() {
	return useQuery(GET_TASKS_TABLE_SETTINGS_QUERY_KEY, getSettings, {
		refetchOnWindowFocus: false
	});
}

export default useGetTasksTableSettings;
