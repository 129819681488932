import React from "react";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { scrollTo } from "./Functions";
import { ReactComponent as Dots } from "static/icons/LabelmoreDots.svg";
import cx from "classnames";

const Pagination = ({
	forceSelected,
	initialSelected,
	forcePage,
	offset,
	marginPagesDisplayed,
	pageRangeDisplayed,
	previousLabel,
	nextLabel,
	handlePageClick,
	total,
	size,
	className
}) => {
	const num_offset = Number(offset);
	const num_size = Number(size);

	const getPageNum = () => {
		return Math.ceil(total / num_size);
	};

	const handlerPaginationClick = target => {
		handlePageClick(target.selected);
		scrollTo();
	};

	return (
		<ReactPaginate
			previousLabel={previousLabel}
			nextLabel={nextLabel}
			breakLabel={<Dots />}
			breakClassName={"break-me"}
			pageCount={getPageNum()}
			marginPagesDisplayed={marginPagesDisplayed}
			pageRangeDisplayed={pageRangeDisplayed}
			onPageChange={handlerPaginationClick}
			containerClassName={cx("client-pagination", className)}
			subContainerClassName={"pages pagination"}
			activeClassName={"active"}
			forcePage={num_offset === 0 ? num_offset : forcePage}
			forceSelected={forceSelected && forceSelected}
			initialSelected={initialSelected && initialSelected}
		/>
	);
};

Pagination.propTypes = {
	total: PropTypes.number.isRequired,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	handlePageClick: PropTypes.func.isRequired
};
Pagination.defaultProps = {
	marginPagesDisplayed: 2,
	pageRangeDisplayed: 3,
	nextLabel: ">",
	previousLabel: "<"
};

export default Pagination;
