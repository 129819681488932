import React from "react";
import get from "lodash/get";
import Checkbox from "common/Checkbox";
import CurrencyFormatter from "common/CurrencyFormater";
import s from "./channelCard.module.scss";
import { EUR } from "config";
import GlobalTooltip from "common/GlobalTooltip";
import useFiltersStore from "../../store/useFiltersStore";
import cx from "classnames";

const currency = {
	EUR,
	USD: " $",
	GBP: " £"
};

const currencyInVonqIndex = {
	EUR: 0,
	GBP: 1,
	USD: 2
};

const RenderText = ({ text }) => (
	<GlobalTooltip
		wrapperClassName={s.globalToolTip}
		placement="top"
		trigger="hover"
		overlay={text}
	>
		<div className={s.descriptionWrapper}>
			<span className={s.description}>{text}</span>
		</div>
	</GlobalTooltip>
);

const ChannelCard = (
	{
		src,
		id,
		title = "",
		description,
		channel,
		isContract,
		isChannel,
		checked,
		handleCheck,
		disabled
	},
	ref
) => {
	const currencyFilter = useFiltersStore(store => store.cururency) || {
		value: "EUR"
	};

	const amount = get(
		channel,
		`vonq_price[${currencyInVonqIndex[currencyFilter?.value]}].amount`,
		0
	);

	return (
		<div ref={ref} className={s.root}>
			<div className={s.checkboxContainer}>
				<Checkbox
					id={channel?.product_id ?? channel?.contract_id ?? id ?? "selection5"}
					checked={checked}
					onChange={handleCheck}
					disabled={disabled}
				/>
			</div>
			{src && (
				<div className={s.logoContainer}>
					<img src={src} />
				</div>
			)}
			<GlobalTooltip
				wrapperClassName={s.globalToolTip}
				placement="top"
				trigger="hover"
				overlay={title}
			>
				<h6 className={cx(s.title, { [s.marginZero]: !description })}>
					{title}
				</h6>
			</GlobalTooltip>
			{description && <RenderText text={description} maxSize={50} />}
			{(isContract || isChannel) && (
				<div className={s.cardBottomContainer}>
					<div className={s.info}>
						{isContract && <span>ddddddd</span>}
						{isChannel && (Boolean(amount) || amount === 0) ? (
							<span className={s.channelDuration}>
								{get(channel, "duration.period", 0)}{" "}
								{get(channel, "duration.range", "days")}
								{" - "}
								<CurrencyFormatter
									euro={get(
										channel,
										`vonq_price[${
											currencyInVonqIndex[currencyFilter?.value]
										}].amount`,
										0
									)}
									symbol={
										currency[
											get(
												channel,
												`vonq_price[${
													currencyInVonqIndex[currencyFilter?.value]
												}].currency`,
												EUR
											)
										]
									}
								/>
							</span>
						) : (
							<span className={s.freeChannel}> FREE </span>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default React.forwardRef(ChannelCard);
