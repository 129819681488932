import React from "react";
import { Flex, Box } from "rebass";
import range from "lodash/range";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const DrawerSkeleton = () => {
	return (
		<Box
			sx={{
				height: "100%",
				padding: "24px 24px 0px 24px"
			}}
		>
			<Flex
				sx={{
					minHeight: "calc(100% - 75px) ",
					flexDirection: "column"
				}}
			>
				<Flex justifyContent="space-between" alignItems="center">
					<Flex>
						<Flex
							alignItems="center"
							mr={16}
							sx={{
								position: "relative"
							}}
						>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton circle={true} height={60} width={60} />
							</SkeletonTheme>
							<Box
								sx={{
									width: "16px",
									height: "16px",
									borderRadius: "16px",
									position: "absolute",
									right: "0",
									bottom: "10px",
									border: "2px solid #ffffff",
									background: "#818a92"
								}}
							></Box>
						</Flex>
						<Box>
							<Box mb={12}>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10 }}
										width={211}
										height={16}
									/>
								</SkeletonTheme>
							</Box>
							<Box mb={12}>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10 }}
										width={141}
										height={16}
									/>
								</SkeletonTheme>
							</Box>
							<Flex>
								<Box mr={"8px"}>
									<SkeletonTheme color="#dfe3e8">
										<Skeleton
											style={{ borderRadius: 10 }}
											width={20}
											height={16}
										/>
									</SkeletonTheme>
								</Box>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10 }}
										width={60}
										height={16}
									/>
								</SkeletonTheme>
							</Flex>
						</Box>
					</Flex>
					<Box
						sx={{
							borderRadius: "6px",
							border: "solid 1px #dfe3e8",
							padding: "19px 16px 18px 11px",
							width: "264px"
						}}
					>
						<Flex mb={30}>
							<Box mr={"10px"}>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10 }}
										width={20}
										height={16}
									/>
								</SkeletonTheme>
							</Box>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 10 }}
									width={150}
									height={16}
								/>
							</SkeletonTheme>
						</Flex>
						<Flex>
							<Box mr={"10px"}>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10 }}
										width={20}
										height={16}
									/>
								</SkeletonTheme>
							</Box>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 10 }}
									width={150}
									height={16}
								/>
							</SkeletonTheme>
						</Flex>
					</Box>
				</Flex>
				<Flex
					mt={16}
					sx={{
						flexGrow: "1",
						border: "16px solid #dfe3e8",
						borderBottom: "none",
						borderRadius: "6px"
					}}
				>
					<Box>
						<Box mt={45} ml={32} mb={40}>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 10, marginBottom: "16px" }}
									width={171}
									height={16}
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 10, marginBottom: "16px" }}
									width={70}
									height={16}
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton style={{ borderRadius: 10 }} width={90} height={16} />
							</SkeletonTheme>
						</Box>

						{range(3).map(i => (
							<Box key={i} ml={32} mb={25}>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10, marginBottom: "22px" }}
										width={130}
										height={16}
									/>
								</SkeletonTheme>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10, marginBottom: "16px" }}
										width={171}
										height={16}
									/>
								</SkeletonTheme>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10, marginBottom: "16px" }}
										width={390}
										height={16}
									/>
								</SkeletonTheme>
								<SkeletonTheme color="#dfe3e8">
									<Skeleton
										style={{ borderRadius: 10, marginBottom: "16px" }}
										width={530}
										height={16}
									/>
								</SkeletonTheme>
							</Box>
						))}
					</Box>
				</Flex>
			</Flex>
			<Box sx={{ height: "72px" }}>
				<Flex
					pt={16}
					pb={16}
					justifyContent="space-between"
					alignItems="center"
				>
					<Flex>
						<SkeletonTheme color="#dfe3e8">
							<Skeleton
								style={{ borderRadius: 10, marginRight: 16 }}
								width={60}
								height={16}
							/>
						</SkeletonTheme>
						<SkeletonTheme color="#dfe3e8">
							<Skeleton style={{ borderRadius: 10 }} width={60} height={16} />
						</SkeletonTheme>
					</Flex>
					<Box>
						<Flex>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 6, marginRight: 16 }}
									width={82}
									height={40}
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 6, marginRight: 16 }}
									width={40}
									height={40}
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 6, marginRight: 16 }}
									width={40}
									height={40}
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 6, marginRight: 16 }}
									width={40}
									height={40}
								/>
							</SkeletonTheme>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton style={{ borderRadius: 6 }} width={40} height={40} />
							</SkeletonTheme>
						</Flex>
					</Box>
				</Flex>
			</Box>
		</Box>
	);
};

export default DrawerSkeleton;
