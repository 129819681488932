import React from "react";
import { TextHeadline, TitleHeadline, SubTextHeadline } from "../personalTab";
import { Flex, Box } from "rebass";
import get from "lodash/get";

const Preferences = ({ preference }) => {
	const renderResidence = (location = []) => {
		const residency = location.find(l => l.is_main);
		return (
			<>
				{get(residency, "city") || get(residency, "country") ? (
					<>
						{`${get(residency, "country")}${
							get(residency, "city") ? `, ${get(residency, "city")}` : ""
						}`}{" "}
						{`${residency.length > 0 ? `(+${residency.length - 1})` : ""}`}
					</>
				) : (
					"N/A"
				)}
			</>
		);
	};
	const renderPreferredLocation = (location = []) => {
		const preferred = location
			.filter(l => !l.is_main)
			.map((pref, index) => {
				return (
					<SubTextHeadline key={index}>
						{get(pref, "city") || get(pref, "country") ? (
							<>
								{`${get(pref, "country")}${
									get(pref, "city") ? `, ${get(pref, "city")}` : ""
								}`}
								<br />
							</>
						) : (
							""
						)}
					</SubTextHeadline>
				);
			});
		return preferred;
	};
	return (
		<Box>
			<Box>
				<TitleHeadline>LOCATIONS</TitleHeadline>

				<Flex>
					<Box width={1 / 2}>
						<TextHeadline>Residence</TextHeadline>
						<SubTextHeadline>
							{renderResidence(get(preference, "preferred_location", []))}
						</SubTextHeadline>
					</Box>
					<Box width={1 / 2}>
						<TextHeadline>Preferred location (s)</TextHeadline>
						{get(preference, "preferred_location", []).length > 1 ? (
							renderPreferredLocation(get(preference, "preferred_location", []))
						) : (
							<SubTextHeadline>N/A</SubTextHeadline>
						)}
					</Box>
				</Flex>
			</Box>
			<Box mt={24}>
				<TitleHeadline>EMPLOYMENT</TitleHeadline>
				<Flex>
					<Box width={1 / 2}>
						<TextHeadline>Expected salary</TextHeadline>
						<SubTextHeadline>
							{get(preference, "expected_salary") || "N/A"}
						</SubTextHeadline>
					</Box>
					<Box width={1 / 2}>
						<TextHeadline>Notice</TextHeadline>
						<SubTextHeadline>
							{get(preference, "notice_period") || "N/A"}
						</SubTextHeadline>
					</Box>
				</Flex>
				<Flex mt={24}>
					<Box width={1 / 2}>
						<TextHeadline>Employment type</TextHeadline>
						<SubTextHeadline>
							{get(preference, "employment_types.0") || "N/A"}
						</SubTextHeadline>
					</Box>
					<Box width={1 / 2}>
						<TextHeadline>Extras</TextHeadline>
						<SubTextHeadline>
							{get(preference, "expected_salary_advantage") || "N/A"}
						</SubTextHeadline>
					</Box>
				</Flex>
			</Box>
		</Box>
	);
};

export default Preferences;
