import React from "react";
import s from "./result.module.scss";
import useGetChannels from "../../../api/result/useGetChannels";
import Pagination from "common/Pagination";
import ChannelCard from "../../../components/ChannelCard";
import CardSkeleton from "../../../components/ChannelCard/CardSkeleton";
import get from "lodash/get";
import useFiltersStore from "../../../store/useFiltersStore";
import AddContractDrawer from "../result/AddContract";
import EmptyState from "../../../components/emptyState";

const ChannelsResult = () => {
	const { channelName, offset, setOffset } = useFiltersStore();

	const { data, isFetching } = useGetChannels({
		offset,
		name: channelName,
		mc_enabled: true,
		currency: "EUR",
		exclude_recommended: true,
		recommended: true,
		sort_by: "relevant",
		limit: 20
	});

	const handlePageClick = page =>
		setOffset(page * get(data, "pagination.limit"));

	return (
		<div>
			{isFetching ? (
				<div className={s.cardsContainer}>
					{Array.from(Array(25).keys()).map((_, index) => (
						<CardSkeleton key={index} />
					))}
				</div>
			) : get(data, "data.length", 0) ? (
				<div className={s.cardsContainer}>
					{get(data, "data", []).map((channel, index) => (
						<ChannelCard key={index} channel={channel} />
					))}
				</div>
			) : (
				<EmptyState />
			)}
			{get(data, "pagination.total") > get(data, "pagination.limit") && (
				<Pagination
					total={get(data, "pagination.total")}
					size={get(data, "pagination.limit")}
					offset={get(data, "pagination.offset")}
					handlePageClick={handlePageClick}
				/>
			)}
			<AddContractDrawer />
		</div>
	);
};

export default ChannelsResult;
