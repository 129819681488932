import { socialMediaImages } from "./constants";

export const signatureHtmlTemplate = signature => {
	const {
		signature_full_name,
		signature_phone,
		signature_avatar,
		signature_social_media = [],
		signature_position,
		signature_email,
		signature_address
	} = signature;

	const socialMediaHtml = signature_social_media
		.map(
			link => `
				<a href=${
					link.url
				} target="_blank" rel="noreferrer" style="text-decoration: none; display: block; float: left; margin-right: 10px">
					<img data-id="signature" src=${
						socialMediaImages[link.platform]
					} width="24" height="24">
				</a>
			`
		)
		.join("\n");

	const avatarTemplate = signature_avatar
		? `
				<div style="display:inline-block;" class="PlaygroundEditorTheme__paragraph" dir="ltr">
					<img data-id="signature" src=${signature_avatar} alt="avatar" width="110" height="110">
				</div>
			`
		: "";
	const fullNameTemplate = signature_full_name
		? `
        <tr>
					<td style="padding: 0 0 0 10px; margin-bottom:5px; vertical-align: top;">
						<div class="PlaygroundEditorTheme__paragraph" dir="ltr">
							<span style="font-size:18px; font-weight:600; margin-bottom:5px;display: inline-block;">
								${signature_full_name}
							</span>        
						</div>
					</td>
        </tr>
			`
		: "";
	const positionTemplate = signature_position
		? `
        <tr>
					<td style="padding: 0 0 0 10px; vertical-align: top;">
						<div class="PlaygroundEditorTheme__paragraph" dir="ltr">
							<span style="margin-bottom:5px;display: inline-block;">
								${signature_position}
							</span>        
						</div>
					</td>
        </tr>
			`
		: "";

	const phoneTemplate = signature_phone
		? `
        <tr>
					<td style="display:inline-block; height:24px; padding: 0 0 0 10px; vertical-align: top;">
						<div style="display:inline-block; vertical-align: top" class="PlaygroundEditorTheme__paragraph" dir="ltr">
							<img data-id="signature" src="https://mcusercontent.com/88c83061cea4f39823efacefc/images/44535882-e772-a918-003a-5e72496f8e5f.png" width="20" height="20">
						</div>
						<div style="display:inline-block;margin-left: 10px;" class="PlaygroundEditorTheme__paragraph" dir="ltr">
							<span>
								${signature_phone}
							</span>        
						</div>
					</td>
        </tr>
			`
		: "";

	const emailTemplate = signature_email
		? `
        <tr>
					<td style="display: inline-block; height:24px; padding: 0 0 0 10px; vertical-align: top;">
						<div style="display:inline-block; vertical-align: top" class="PlaygroundEditorTheme__paragraph" dir="ltr">
							<img data-id="signature" src="https://mcusercontent.com/88c83061cea4f39823efacefc/images/9d126666-2c23-afc7-39ce-a95e3e25e185.png" width="20" height="20">
						</div>
						<div style="display:inline-block;margin-left: 10px;" class="PlaygroundEditorTheme__paragraph" dir="ltr">
							<span>
								${signature_email}
							</span>        
						</div>
					</td>
        </tr>
			`
		: "";
	const addressTemplate = signature_address
		? `
				<tr>
					<td style="display: inline-block; height:24px; padding: 0 0 0 10px; vertical-align: top;">
						<div style="display:inline-block; vertical-align: top" class="PlaygroundEditorTheme__paragraph" dir="ltr">
							<img data-id="signature" src="https://mcusercontent.com/88c83061cea4f39823efacefc/images/9cc0aa8b-5490-8fc1-8a9a-a43e3ff786a1.png" width="20" height="20">
						</div>
						<div style="display:inline-block;margin-left: 10px;" class="PlaygroundEditorTheme__paragraph" dir="ltr">
							<span>
								${signature_address}
							</span>        
						</div>
					</td>
				</tr>
			`
		: "";
	const htmlOuput = `
		<table style="display:block; border-top: 1px solid #e2e8f0; padding: 20px 0px; margin-top: 20px; color:#334155;">
			<colgroup>
				<col>
				<col>
			</colgroup>
			<tbody>
				<tr>
					<td style="display:flex;">
						${avatarTemplate}
						<table style="display:inline-block;">
							<tbody>
								${fullNameTemplate}
								${positionTemplate}
								${phoneTemplate}
								${emailTemplate}
								${addressTemplate}
								<tr style="margin-top: 10px;">
									<td style="padding: 5px 0 0 10px; vertical-align: top;">
										${socialMediaHtml}
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</tbody>
		</table>
	`;

	return htmlOuput;
};
