import React from "react";
import Skeleton from "react-loading-skeleton";
import styles from "./messaging-tool-new-email-skeleton.module.scss";

const MessagingToolNewEmailSkeleton = () => {
	return (
		<div className={styles.container}>
			<div>
				<Skeleton width={200} height={20} />
			</div>
			<br />
			<br />
			<br />
			<div>
				<Skeleton width={500} height={20} />
			</div>
			<br />
			<div>
				<Skeleton width={400} height={20} />
			</div>
			<br />
			<br />
			<br />
			<div>
				<Skeleton width={100} height={20} />
			</div>
			<br />
			<div>
				<Skeleton width={100} height={20} />
			</div>
		</div>
	);
};

export default MessagingToolNewEmailSkeleton;
