import React, { useEffect } from "react";
import get from "lodash/get";

import AllProcesses from "./AllProcesses";
import Accordion from "../../components/Accordion";
import Loading from "../../components/Accordion/Loading.js";
import Sidebar from "./Sidebar";
import useActiveProcess from "modules/candidate/Profile/api/useActiveProcesses";
import { getActivePrecesses } from "../../utils/helper";
import styles from "./processes.module.scss";
import EmptyState from "../../components/EmptyState";
import { useToggle } from "react-use";
import { PROCESSES } from "modules/home/config";
import { searchParser } from "config/helpers";

import useOpenStartEvaluationDrawer from "../PermanentViewEvaluationsTab/components/ProfileViewStartEvaluationDrawer/hooks/useOpenStartEvaluationDrawer";
import useOpenTemplateEvaluationDrawer from "../PermanentViewEvaluationsTab/components/ProfileViewEvaluationTemplateFormDrawer/hooks/useOpenTemplateEvaluationDrawer";
import useAssignEvaluationTaskStore from "../PermanentViewEvaluationsTab/components/ProfileViewAssignEvaluationTaskDrawer/hooks/useAssignEvaluationTaskStore";
import loadable from "loadable-components";
import useFormStoreEvaluationQuick from "common/StartEvaluationDrawer/components/EvaluationQuickAddForm/hooks/useFormStoreEvaluationQuick";
import useFormStoreCandidateEvaluation from "common/StartEvaluationDrawer/components/EvaluateCandidateForm/hooks/useFormStoreCandidateEvaluation";
import useFormStoreAssignEvaluation from "common/StartEvaluationDrawer/components/AssignEvaluationToMemberForm/hooks/useFormStoreAssignEvaluation";

const ProfileViewEvaluationTemplateFormDrawer = loadable(() =>
	import(
		"../PermanentViewEvaluationsTab/components/ProfileViewEvaluationTemplateFormDrawer"
	)
);
const ProfileViewAssignEvaluationTaskDrawer = loadable(() =>
	import(
		"../PermanentViewEvaluationsTab/components/ProfileViewAssignEvaluationTaskDrawer"
	)
);
const ProfileViewStartEvaluationDrawer = loadable(() =>
	import(
		"../PermanentViewEvaluationsTab/components/ProfileViewStartEvaluationDrawer"
	)
);

const Processes = ({ id }) => {
	const { tab } = searchParser();
	const enabled = tab === PROCESSES;

	const { data, isLoading, invalidate } = useActiveProcess(
		{ id },
		{
			enabled
		}
	);
	const [on, toggle] = useToggle(false);
	const pokes = get(data, "pokes", []);
	const active = get(data, "active", 0);
	const closed = get(data, "closed", 0);
	const statistics = get(data, "statistics");

	const slicedData = pokes.length > 10 ? pokes.slice(0, 10) : pokes;
	const restDate = pokes.length > 10 && pokes.slice(10);

	const {
		isOpen: isOpenStartEvaluationDrawer
	} = useOpenStartEvaluationDrawer();
	const {
		isOpen: isOpenTemplateEvaluationDrawer
	} = useOpenTemplateEvaluationDrawer();
	const {
		isOpen: isOpenAssignEvaluationDrawer
	} = useAssignEvaluationTaskStore();

	const {
		resetFormValues: resetQuickEvaluationForm
	} = useFormStoreEvaluationQuick();
	const {
		resetFormValues: resetCandidateEvaluationForm
	} = useFormStoreCandidateEvaluation();
	const {
		resetFormValues: resetAssignEvaluationForm
	} = useFormStoreAssignEvaluation();
	useEffect(() => {
		if (!isOpenStartEvaluationDrawer) {
			resetQuickEvaluationForm();
			resetAssignEvaluationForm();
			resetCandidateEvaluationForm();
		}
	}, [isOpenStartEvaluationDrawer]);

	return (
		<div className={styles.container}>
			<div className={styles.activeProcess}>
				<div className={styles.title}>Active Processes</div>
				{!isLoading ? (
					<div className={styles.activeProcess}>
						{pokes?.length > 0 ? (
							slicedData.map(process => {
								const { header, body } = getActivePrecesses(process);
								return (
									<Accordion
										key={process._id}
										header={header}
										body={body}
										invalidate={invalidate}
										application_id={process._id}
									/>
								);
							})
						) : (
							<div className={styles.emptyWrapper}>
								<EmptyState />
							</div>
						)}
						{restDate &&
							on &&
							restDate.map(process => {
								const { header, body } = getActivePrecesses(process);
								return (
									<Accordion
										key={process._id}
										header={header}
										body={body}
										invalidate={invalidate}
										idPoke={process._id}
									/>
								);
							})}
					</div>
				) : (
					<Loading />
				)}
				{restDate && (
					<button className={styles.showMore} onClick={toggle}>
						{on ? "Show less" : "Show more"}
					</button>
				)}
			</div>

			<div className={styles.sidebar}>
				<Sidebar {...{ active, closed, isLoading, statistics }} />
			</div>
			<div className={styles.allVacancies}>
				<div className={styles.title}>All vacancies</div>
				<AllProcesses candidateId={id} />
			</div>

			{isOpenStartEvaluationDrawer && <ProfileViewStartEvaluationDrawer />}
			{isOpenTemplateEvaluationDrawer && (
				<ProfileViewEvaluationTemplateFormDrawer />
			)}
			{isOpenAssignEvaluationDrawer && (
				<ProfileViewAssignEvaluationTaskDrawer />
			)}
		</div>
	);
};
export default Processes;
