import React, { useRef } from "react";
import Tooltip from "../../../common/Tippy";
import styles from "./multiSites_header.module.scss";

const SiteRender = ({ list, key, handleClick }) => {
	const ref = useRef();
	const type = "Hidden";
	return (
		<Tooltip
			key={key}
			content={list.name}
			overflow={"hidden"}
			theme="dark"
			placement="top"
		>
			<li
				ref={ref}
				className={styles.dropDownLi}
				onClick={() => handleClick(list, type)}
			>
				{list.name}
			</li>
		</Tooltip>
	);
};
export default SiteRender;
