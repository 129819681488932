import React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
	$createEmailSignatureNode,
	EmailSignatureNode
} from "common/Editor/nodes/EmailSignatureNode";
import {
	$createParagraphNode,
	$insertNodes,
	$isRootOrShadowRoot,
	$nodesOfType,
	COMMAND_PRIORITY_EDITOR,
	createCommand
} from "lexical";
import { $wrapNodeInElement } from "@lexical/utils";

export const INSERT_EMAIL_SIGNATURE_COMMAND = createCommand(
	"INSERT_EMAIL_SIGNATURE_COMMAND"
);

const EmailSignaturePlugin = ({ renderEmailSignature }) => {
	const [editor] = useLexicalComposerContext();
	React.useEffect(() => {
		if (!editor.hasNodes([EmailSignatureNode])) {
			throw new Error(
				"EmailSignaturePlugin: EmailSignatureNode not registered on editor"
			);
		}
		editor.registerCommand(
			INSERT_EMAIL_SIGNATURE_COMMAND,
			payload => {
				const nodes = $nodesOfType(EmailSignatureNode);
				if (nodes.length === 0) {
					const decoratorNode = $createEmailSignatureNode(payload);
					const firstParagraphe = $createParagraphNode();
					$insertNodes([
						firstParagraphe,
						$createParagraphNode(),
						decoratorNode
					]);
					if ($isRootOrShadowRoot(decoratorNode.getParentOrThrow())) {
						$wrapNodeInElement(decoratorNode, $createParagraphNode);
					}
					firstParagraphe.selectStart();
				}
				return true;
			},
			COMMAND_PRIORITY_EDITOR
		);

		if (editor) {
			renderEmailSignature({ editor });
		}
	}, [editor, renderEmailSignature]);

	return null;
};

export default EmailSignaturePlugin;
