import React, { Component, Fragment } from "react";
import Tooltip from "rc-tooltip";
import PropTypes from "prop-types";
import cx from "classnames";

class GlobalTooltip extends Component {
	state = {
		visible: this.props.defaultVisible,
		hasOverflowingText: false
	};

	componentDidMount() {
		window.addEventListener("scroll", this.closePopup, false);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.closePopup);
		this.willUnmount = true;
	}

	onVisibleChange = visible => {
		if (!this.willUnmount && visible !== this.state.visible) {
			this.setState({ visible });
		}
	};

	componentDidUpdate() {
		if (this.props.scrolling && this.state.visible) {
			this.closePopup();
		}
	}

	closePopup = () => {
		this.onVisibleChange(false);
	};

	updateOverflow = e => {
		const el = e.target;
		if (!this.state.hasOverflowingText && el.scrollWidth > el.clientWidth) {
			this.setState({ hasOverflowingText: true });
			if (el.textContent !== this.state.text) {
				this.setState({ visible: true });
			}
		} else {
			this.setState({ hasOverflowingText: false });
		}
	};

	render() {
		const {
			active,
			placement,
			eventTrigger,
			overlayClassName,
			noClassName,
			maxWidth,
			overlay,
			children,
			mouseLeaveDelay,
			mouseEnterDelay,
			destroyTooltipOnHide,
			withWrappingDiv,
			style,
			ellipsisMode,
			textAlign,
			wrapperClassName
		} = this.props;

		const ellipsisStyle = {
			overflow: "hidden",
			overflowWrap: "break-word",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			wordBreak: "break-all"
		};

		const kids = children || <span className="icon-info" />;

		if (!active) {
			return kids;
		}

		const align = placement !== "top" ? this.props.align : {};

		if (ellipsisMode && !this.state.hasOverflowingText) {
			return (
				<div style={ellipsisStyle} onMouseEnter={this.updateOverflow}>
					{children}
				</div>
			);
		}

		const TooltipComponent = (
			<Tooltip
				placement={placement}
				align={align}
				trigger={[eventTrigger, "focus"]}
				overlayClassName={`${
					noClassName ? "" : "g-tool-white"
				} ${overlayClassName}`}
				overlay={<div style={{ maxWidth, textAlign }}>{overlay}</div>}
				mouseLeaveDelay={mouseLeaveDelay}
				mouseEnterDelay={mouseEnterDelay}
				destroyTooltipOnHide={destroyTooltipOnHide}
				onVisibleChange={this.onVisibleChange}
				visible={this.state.visible}
				style={style}
			>
				{kids}
			</Tooltip>
		);

		if (ellipsisMode && this.state.hasOverflowingText) {
			return <div style={ellipsisStyle}>{TooltipComponent}</div>;
		}

		if (withWrappingDiv) {
			return (
				<div className={noClassName ? "" : cx("g-tool", wrapperClassName)}>
					{TooltipComponent}
				</div>
			);
		}

		return <Fragment>{TooltipComponent}</Fragment>;
	}
}

GlobalTooltip.propTypes = {
	placement: PropTypes.string,
	overlayClassName: PropTypes.string,
	maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	eventTrigger: PropTypes.string,
	noClassName: PropTypes.bool,
	mouseLeaveDelay: PropTypes.number,
	mouseEnterDelay: PropTypes.number,
	destroyTooltipOnHide: PropTypes.bool,
	defaultVisible: PropTypes.bool,
	active: PropTypes.bool,
	withWrappingDiv: PropTypes.bool,
	overlay: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	align: PropTypes.shape({
		offset: PropTypes.array
	}),
	style: PropTypes.object,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	scrolling: PropTypes.bool
};

GlobalTooltip.defaultProps = {
	placement: "topLeft",
	align: {
		offset: [-54, -6]
	},
	overlayClassName: "",
	maxWidth: "370px",
	eventTrigger: "hover",
	noClassName: false,
	mouseLeaveDelay: 0.1,
	mouseEnterDelay: 0,
	destroyTooltipOnHide: true,
	defaultVisible: false,
	active: true,
	withWrappingDiv: true
};
export default GlobalTooltip;
