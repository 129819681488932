import { browserHistory } from "react-router";
import _get from "lodash/get";
import { GET_BID, VIEW_CONTACT_INFO_API } from "config/api-endpoints";
import { PROPOSE_SUCCESS, CLIENT } from "config";
import * as types from "./actionTypes";
import AxiosHelper from "config/axios-helper";
import {
	DECLINE_INTERVIEW,
	INVITE_USER_TO_INTERVIEW,
	EDIT_INTERVIEW,
	ACCEPT_INTERVIEW,
	CANCEL_INTERVIEW,
	REFUSE_INTERVIEW,
	PROPOSE_MISSION,
	REJECT_APPLICATION_ENDPOINT,
	PROPOSE_OTHER_TERMS,
	ACCEPT_MISSION,
	READ_MY_PROFILE,
	VIEW_PROFILE
} from "config/api-endpoints";
import { getJobListContainerRoute } from "../../job/components/joblist/jobListContainer";
import { getFreelancerBidViewRoute } from "../components/bidview/freelancer";

export function toggleInterviewModal(modal = null) {
	return {
		type: types.TOGGLE_INTERVIEW_MODAL,
		modal
	};
}

export function toggleContractModal(modal) {
	return {
		type: types.TOGGLE_CONTRACT_MODAL,
		show: !!modal,
		modal
	};
}

export const toggleRejectApplicationModal = () => ({
	type: types.TOGGLE_REJECT_MODAL
});

export const showVideoConf = () => ({
	type: types.SHOW_VIDEO_CONF
});

export const hideVideoConf = () => ({
	type: types.HIDE_VIDEO_CONF
});

/* bid actions */

export function viewContactInfo(payload) {
	return AxiosHelper.__post({
		url: VIEW_CONTACT_INFO_API,
		data: payload
	});
}

export function fetchBid(id) {
	return (dispatch, getState) => {
		let userType = _get(getState(), "auth.user.company_type");
		return dispatch(
			AxiosHelper.__post({
				url: GET_BID,
				data: { bid_id: id },
				REQUEST_SUCCESS: types.FETCH_BID_SUCCEEDED
			})
		).catch(({ status }) => {
			if (status === 403) {
				if (userType === CLIENT) {
					browserHistory.push(getJobListContainerRoute());
				} else {
					browserHistory.push(getFreelancerBidViewRoute());
				}
			}
		});
	};
}

/*Interview actions*/

export function interviewDispatch(
	payload,
	is_decline = false,
	is_edit = false
) {
	return AxiosHelper.__post({
		data: payload,
		isModal: true,
		url: is_decline
			? DECLINE_INTERVIEW
			: is_edit
			? EDIT_INTERVIEW
			: INVITE_USER_TO_INTERVIEW,
		next: () => fetchBid(payload.bid_id),
		toastMessage: is_decline
			? "New interview date has been successfully proposed"
			: "Interview was successfully sent"
	});
}

export function acceptInterview(data, nextAction) {
	return AxiosHelper.__post({
		data,
		isModal: true,
		url: ACCEPT_INTERVIEW,
		next: () => {
			nextAction && nextAction();
			return fetchBid(data.bid_id);
		},
		toastMessage: "Invitation accepted"
	});
}

export function cancelInterview(data) {
	return AxiosHelper.__post({
		data,
		url: CANCEL_INTERVIEW,
		next: () => fetchBid(data.bid_id),
		toastMessage: "Interview successfully cancelled"
	});
}

export function refuseInterview(data) {
	return AxiosHelper.__post({
		data,
		url: REFUSE_INTERVIEW,
		next: () => fetchBid(data.bid_id),
		toastMessage: "Interview successfully cancelled"
	});
}

export function rejectApplication(payload) {
	return AxiosHelper.__post({
		url: REJECT_APPLICATION_ENDPOINT,
		next: () => fetchBid(payload.bid_id),
		toastMessage: "The application has been rejected.",
		isModal: true,
		data: payload
	});
}

/* Contract Actions */

export function ProposeContract(payload, otherTerms = false) {
	return AxiosHelper.__post({
		data: payload,
		isModal: true,
		url: otherTerms ? PROPOSE_OTHER_TERMS : PROPOSE_MISSION,
		next: () => dispatch => {
			dispatch(toggleContractModal(PROPOSE_SUCCESS));
			return dispatch(fetchBid(payload.bid_id));
		}
	});
}

export function acceptContract({ contract_id, bid_id, files }) {
	return AxiosHelper.__post({
		url: ACCEPT_MISSION,
		data: { id: contract_id, files },
		next: () => fetchBid(bid_id),
		isModal: true,
		toastMessage: `Congratulations for coming to an agreement! wiggli is currently generating the related documents, we’ll get back to you as soon as they’re ready.`
	});
}

export function loadProfile(id) {
	return AxiosHelper.__post({
		url: id ? VIEW_PROFILE : READ_MY_PROFILE,
		data: id ? { id } : {},
		PRE_REQUEST: types.LOAD_PROFILE,
		next: data => _doneLoading(data, !id)
	});
}

function _doneLoading(data, ownCV) {
	return {
		type: types.LOADED_PROFILE,
		data,
		ownCV
	};
}
