import { useQuery } from "react-query";
import { LIST_SECTORS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getCategories = () =>
	client(LIST_SECTORS, {
		body: {}
	});

export const useGetCategoriesList = () => {
	const res = useQuery("sectors", getCategories, {
		refetchOnWindowFocus: false,
		staleTime: Infinity
	});

	return res;
};
