import create from "zustand";
import { produce } from "immer";

const useCreateVacancyConditions = create(set => ({
	conditions: [],
	setConditions: conditions => set(() => ({ conditions: conditions })),
	addCondition: condition =>
		set(state => {
			const { conditions } = state;

			const newConditions = produce(conditions, draft => {
				draft.push(condition);
			});

			return { conditions: newConditions };
		}),

	deleteCondition: conditionId =>
		set(state => {
			const { conditions } = state;
			const newConditions = produce(conditions, draft => {
				const index = conditions.findIndex(
					condition => condition.id === conditionId
				);
				draft.splice(index, 1);
			});

			return { conditions: newConditions };
		}),
	requireCondition: condition =>
		set(state => {
			const { conditions } = state;
			const newConditions = produce(conditions, draft => {
				const index = conditions.findIndex(item => item.id === condition.id);

				draft[index]["is_required"] = !draft[index].is_required;
			});

			return { conditions: newConditions };
		}),
	saveCondition: (conditionId, data) =>
		set(state => {
			const { conditions } = state;
			const newConditions = produce(conditions, draft => {
				const index = conditions.findIndex(item => item.id === conditionId);

				draft[index] = {
					id: conditionId,
					...data
				};
			});
			return { conditions: newConditions };
		}),
	clearConditions: () => set(() => ({ conditions: [] }))
}));

export default useCreateVacancyConditions;
