import React from "react";
import _get from "lodash/get";
import cx from "classnames";
import styles from "./bid-details-processes.module.scss";
import { useSelector } from "react-redux";
import Tooltip from "common/Tippy";
import { BidDetailsProcessesToggle } from "../BidDetailsProcessesToggle";
import {
	BidDetailsProcessesContractCard,
	BidDetailsProcessesInterviewCard,
	BidDetailsProcessesBidCard
} from "../BidDetailsProcessesCard";
import BidDetailsJoinMeeting from "../BidDetailsProcessesCard/BidDetailsJoinMeeting";
import BidDetailsCongratulationMassage from "../BidDetailsProcessesCard/BidDetailsCongratulationMassage";
import {
	ACCEPTED,
	ARCHIVED,
	CLIENT,
	DECLINED,
	CANCELLED,
	CONFIRMED,
	REFUSED,
	SIGNED,
	EXPIRED,
	DONE,
	VIDEO_CONFERENCE,
	SENT
} from "config";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { ReactComponent as DownloadIcon } from "static/icons/Icondownload.svg";
import { ReactComponent as FileIcon } from "static/icons/file-icon1.svg";
import { ReactComponent as FilePdfIcon } from "static/icons/file-pdf-icon.svg";
import { ReactComponent as FileXlsIcon } from "static/icons/file-xls-icon.svg";
import { ReactComponent as FileJpgIcon } from "static/icons/file-jpg-icon.svg";

export default function BidDetailsProcesses({ data, isLoading, isFetching }) {
	const user = useSelector(onlineUserSelector);

	const hasInterviewProposition =
		!!_get(data, "interview") && !Array.isArray(_get(data, "interview"));
	const hasOldInterviewProposition =
		!!_get(data, "interview.old_interview_start_date") &&
		_get(data, "interview.old_interview_start_date") !== "";
	const isInterviewConfirmed = [ACCEPTED, DONE, CONFIRMED].includes(
		_get(data, "interview.status")
	);
	const hasContractProposition =
		_get(data, "contract") && !Array.isArray(_get(data, "contract"));
	const hasOldContractProposition =
		!!_get(data, "contract.old_proposition") &&
		_get(data, "contract.old_proposition") != 0;
	const isContractAccepted = _get(data, "contract.status") === ACCEPTED;
	const isContractSigned = _get(data, "contract.signed_status") === SIGNED;
	const isContractAcceptedOrSigned = isContractAccepted || isContractSigned;
	const displayOldInterview =
		hasOldInterviewProposition &&
		(!isInterviewConfirmed || _get(data, "interview.status") === ARCHIVED) &&
		!isContractSigned;
	const displayJoinMeeting =
		isInterviewConfirmed &&
		_get(data, "interview.location_type") === VIDEO_CONFERENCE &&
		!!_get(data, "interview.room_url");
	const openBidToggle = !hasInterviewProposition && !hasContractProposition;

	const isLastUserSenderClient =
		_get(data, "last_user_bid_action.type") === CLIENT;

	const getInterviewStatusTooltip = (status, isLastUserSenderClient) => {
		switch (status) {
			case SENT:
			case DECLINED:
				return isLastUserSenderClient
					? "Expecting applicant’s feedback."
					: "Please reply to the applicant's alternate interview proposition.";

			case REFUSED:
			case CANCELLED:
				return isLastUserSenderClient
					? "You have rejected this application."
					: "The freelance has canceled his/her application.";

			case EXPIRED:
				return "The interview proposition is overdue, please propose another date.";

			case ACCEPTED:
				return "The interview date, time and place are confirmed.";

			case DONE:
				return "As a next step, you can either, propose a contract, organise another interview, or reject the freelancer's application";

			default:
				return "";
		}
	};

	const getContractStatusTooltip = (status, isLastUserSenderClient) => {
		switch (status) {
			case SENT:
			case DECLINED:
				return isLastUserSenderClient
					? "Expecting applicant’s feedback."
					: "Please reply to the freelancer's proposition.";

			case CANCELLED:
			case REFUSED:
				return isLastUserSenderClient
					? "You have rejected this application."
					: "The freelance has canceled his/her application.";

			case ACCEPTED:
				return (
					<span>
						Congratulations for coming to an agreement! <br />
						wiggli is currently generating the related documents.
					</span>
				);

			case SIGNED:
				return "All contracts are signed and this is now an actual mission.";

			default:
				return "";
		}
	};

	const CustomFileIcon = ({ path }) => {
		const ext = path?.split(".").at(-1);
		if (ext === "pdf") return <FilePdfIcon size={20} />;
		if (ext === "xls") return <FileXlsIcon size={20} />;
		return <FileJpgIcon size={20} />;
	};

	return (
		<div className={styles.container}>
			{isContractSigned && (
				<BidDetailsCongratulationMassage
					primaryText="Congratulations, All contracts are signed."
					secondText={"This is now an actual mission."}
				/>
			)}
			{hasContractProposition && (
				<BidDetailsProcessesToggle
					title="Contract proposition"
					isOpen={hasContractProposition}
				>
					<BidDetailsProcessesContractCard
						data={data}
						isFetching={isFetching}
						user={user}
						displayStatus={true}
						statusTooltip={getContractStatusTooltip(
							_get(data, "status"),
							isLastUserSenderClient
						)}
					/>
					{hasOldContractProposition && !isContractAcceptedOrSigned && (
						<BidDetailsProcessesContractCard
							data={data}
							isFetching={isFetching}
							user={user}
							isOldProposition={true}
							statusTooltip={getContractStatusTooltip(
								_get(data, "status"),
								isLastUserSenderClient
							)}
						/>
					)}

					{_get(data, "contract.files")?.length > 0 && (
						<div className={styles.card}>
							<div className={styles.content}>
								<div className={cx(styles.item, styles.item__message)}>
									<div className={styles.content_item_labelHead}>
										<FileIcon size={20} />
										<div className={styles.content_item_labelHead_label}>
											Attached files
										</div>
									</div>
									<div className={styles.files}>
										{_get(data, "contract.files").map(file => {
											return (
												<Tooltip
													content={file.field_name}
													theme="dark"
													placement="top"
													key={file._id}
												>
													<div
														key={file._id}
														className={styles.file}
														onClick={() => {
															window.open(file.path, "_blank");
														}}
													>
														<CustomFileIcon path={file.path} />
														<span className={styles.label}>
															{file.field_name}
														</span>
														<DownloadIcon size={20} />
													</div>
												</Tooltip>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					)}
				</BidDetailsProcessesToggle>
			)}
			{hasInterviewProposition && (
				<BidDetailsProcessesToggle
					title="Interview"
					isOpen={hasInterviewProposition && !hasContractProposition}
				>
					{displayJoinMeeting && (
						<BidDetailsJoinMeeting
							room_url={_get(data, "interview.room_url")}
							user={_get(data, "profile_detail")}
						/>
					)}

					<BidDetailsProcessesInterviewCard
						data={data}
						isFetching={isFetching}
						user={user}
						displayStatus={!hasContractProposition}
						statusTooltip={getInterviewStatusTooltip(
							_get(data, "status"),
							isLastUserSenderClient
						)}
					/>
					{displayOldInterview && (
						<BidDetailsProcessesInterviewCard
							data={data}
							isFetching={isFetching}
							user={user}
							isOldProposition={true}
							displayStatus={!hasInterviewProposition}
							statusTooltip={getInterviewStatusTooltip(
								_get(data, "status"),
								isLastUserSenderClient
							)}
						/>
					)}
				</BidDetailsProcessesToggle>
			)}
			{data && (
				<BidDetailsProcessesToggle title="Bid" isOpen={openBidToggle}>
					<BidDetailsProcessesBidCard
						data={data}
						isLoading={isLoading}
						displayStatus={!hasInterviewProposition && !hasContractProposition}
					/>
				</BidDetailsProcessesToggle>
			)}
		</div>
	);
}
