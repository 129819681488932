import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const MIN_LENGTH = 8;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\\{};':"`~[\]\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=\\{};':"`~[\]\\|,.<>/?]/;

export const useChangePasswordForm = () => {
	const schema = yup.object().shape({
		old_password: yup
			.string()
			.required("Please enter your old password")
			.matches(
				PASSWORD_REGEX,
				"At least one letter, one uppercase letter, one number and one special character"
			)
			.min(MIN_LENGTH, "At least 8 characters long"),
		password: yup
			.string()
			.required("Please enter your new password")
			.matches(
				PASSWORD_REGEX,
				"At least one letter, one uppercase letter, one number and one special character"
			)
			.min(MIN_LENGTH, "At least 8 characters long"),
		confirm_password: yup
			.string()
			.required("Please confirm your new password")
			.matches(
				PASSWORD_REGEX,
				"At least one letter, one uppercase letter, one number and one special character"
			)
			.min(MIN_LENGTH, "At least 8 characters long")
			.oneOf([yup.ref("password"), null], "Passwords don't match")
	});

	return useForm({
		shouldUnregister: true,
		reValidateMode: "onChange",
		resolver: yupResolver(schema),
		defaultValues: {
			old_password: "",
			password: "",
			confirm_password: ""
		}
	});
};
