import cx from "classnames";
import Tooltip from "common/Tippy";
import React, { useRef } from "react";
import addIcon from "static/icons/add-icon-chip.svg";
import deleteIcon from "static/icons/delete-icon-chip.svg";
import styles from "./chip.module.scss";

const Chip = ({
	text,
	isDelete = false,
	onClick,
	onDelete,
	content,
	content_text,
	className
}) => {
	const icon = isDelete ? deleteIcon : addIcon;
	const ref = useRef();

	return (
		<div
			role="button"
			tabIndex="0"
			aria-pressed={true}
			className={cx(styles.chip, { [styles.chipDelete]: isDelete }, className)}
			onClick={onClick}
		>
			{text && (
				<Tooltip theme="dark" content={text} overflow="hidden">
					<div ref={ref} className={styles.text}>
						{text}
					</div>
				</Tooltip>
			)}
			{content && (
				<Tooltip theme="dark" content={content_text} overflow="hidden">
					<div ref={ref} className={styles.text}>
						{content}
					</div>
				</Tooltip>
			)}

			<div className={styles.iconWrapper}>
				{isDelete ? (
					<svg
						width="1.125rem"
						height="1.125rem"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={styles.icon}
						src={icon}
						onClick={onDelete}
					>
						<path
							d="M11.3333 4.66675L4.66663 11.3334M4.66663 4.66675L11.3333 11.3334"
							stroke="#d4d4d4"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				) : (
					<svg
						width="1.125rem"
						height="1.125rem"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={styles.icon}
						src={icon}
						onClick={onDelete}
					>
						<path
							d="M8.00016 3.33398V12.6673M3.3335 8.00065H12.6668"
							stroke="#d4d4d4"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				)}
			</div>
		</div>
	);
};

export default Chip;
