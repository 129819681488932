import React from "react";
import style from "./notes-table-empty-state.module.scss";
import { ReactComponent as EmptyIcon } from "static/icons/emptyState.svg";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";

const NotesTableEmptyState = () => {
	const { clearFilters } = useFilters();
	return (
		<div className={style.emptyState}>
			<EmptyIcon />
			<p className={style.textGras}>No results found</p>
			<p className={style.textNormal}>No note matching your search criteria.</p>
			<button className={style.btnClear} onClick={clearFilters}>
				Clear filters
			</button>
		</div>
	);
};
export default NotesTableEmptyState;
