import React, { Component } from "react";
import PropTypes from "prop-types";

class Skills extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: props.expanded || false
		};
		this.renderSkills = this.renderSkills.bind(this);
		this.changeExpanded = this.changeExpanded.bind(this);
		this.renderOptions = this.renderOptions.bind(this);
	}

	componentDidUpdate({ expanded }) {
		if (expanded && expanded !== this.props.expanded)
			this.setState({ expanded: this.props.expanded });
	}

	renderSkills() {
		let {
			skills,
			max = 6,
			onSkillClick,
			selectedSkills,
			renderOption
		} = this.props;
		const { expanded } = this.state;
		const skillsLngt = skills.length;
		if (skillsLngt > max && !expanded) skills = skills.slice(0, max);
		return skills.map(skill => {
			const value = typeof skill === "object" ? skill.value : skill;
			const isActive =
				selectedSkills &&
				selectedSkills.findIndex(sk => sk.name === value && sk.active) !== -1;
			return renderOption ? (
				renderOption(skill)
			) : (
				<div
					key={value}
					className={`label${isActive ? " active" : ""}`}
					onClick={() => onSkillClick(value)}
				>
					{value}
				</div>
			);
		});
	}

	changeExpanded(e, expanded = true) {
		e.preventDefault();
		this.setState({ expanded });
	}

	renderOptions(expanded) {
		const {
			showMoreText,
			showLessText,
			readMoreLinkClassName,
			readLessLinkClassName,
			showMore
		} = this.props;
		if (!showMore) return <div className="label">{showMoreText}</div>;
		return expanded ? (
			<button
				className={readMoreLinkClassName}
				onClick={e => this.changeExpanded(e, false)}
			>
				{showLessText}
			</button>
		) : (
			<button
				className={readLessLinkClassName}
				onClick={e => this.changeExpanded(e)}
			>
				{showMoreText}
			</button>
		);
	}

	render() {
		const { expanded } = this.state;
		const showOption = this.props.skills.length > this.props.max;
		return (
			<div className="skills">
				{this.renderSkills()}
				{showOption && this.renderOptions(expanded)}
			</div>
		);
	}
}

Skills.propTypes = {
	skills: PropTypes.array.isRequired,
	max: PropTypes.number.isRequired,
	showMoreText: PropTypes.string,
	showLessText: PropTypes.string,
	readMoreLinkClassName: PropTypes.string,
	readLessLinkClassName: PropTypes.string,
	onSkillClick: PropTypes.func,
	showMore: PropTypes.bool
};

Skills.defaultProps = {
	showMoreText: "Show more",
	showLessText: "Show less",
	readMoreLinkClassName: "action--read-more",
	readLessLinkClassName: "action--read-less",
	onSkillClick: () => {},
	showMore: true
};

export default Skills;
