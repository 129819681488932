import React, { useRef } from "react";
import { useMenuItem } from "@react-aria/menu";
import { useKeyboard } from "react-aria";
import { ReactComponent as CheckIcon } from "static/icons/IconCheck.svg";
import styles from "./menu.module.scss";
import Tooltip from "common/Tippy";

const MenuItem = ({ item, state, onAction, selectedKey }) => {
	const ref = useRef();
	const text_ref = useRef();
	let { menuItemProps } = useMenuItem(
		{
			key: item.key,
			onAction
		},
		state,
		ref
	);

	let { keyboardProps } = useKeyboard({
		onKeyDown: e => expand(e)
	});

	const expand = e => {
		const selected = state.collection.keyMap.get(
			state.selectionManager.focusedKey
		);

		if (e.key === "Enter") {
			onAction(item.key);
			return;
		}

		if (selected && selected.key === item.key) {
			if (e.key === "ArrowUp") {
				if (selected.prevKey) {
					state.selectionManager.setFocusedKey(selected.prevKey);
				}
			} else if (e.key === "ArrowDown") {
				if (selected.nextKey) {
					state.selectionManager.setFocusedKey(selected.nextKey);
				}
			}
		}

		return;
	};

	return (
		<li {...menuItemProps} {...keyboardProps} ref={ref} className={styles.item}>
			<div className={styles.check}>
				{selectedKey === item.key && <CheckIcon />}
			</div>
			<Tooltip content={item.rendered} theme="dark" overflow="hidden">
				<span ref={text_ref}>{item.rendered}</span>
			</Tooltip>
		</li>
	);
};

export default MenuItem;
