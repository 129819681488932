import React, { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";
import s from "./skills.module.scss";
import useGetCategories from "../../../api/useGetCategories";
import { XIcon } from "@heroicons/react/solid";
import { useDebounce } from "react-use";
import useFiltersStore from "../searchStore";

function Skills({
	control,
	register,
	data,
	selected,
	onSelect,
	onRemove,
	searchTerm,
	isLoading,
	functions: propsFunctions,
	store
}) {
	const cartRef = useRef();
	const { functions, setTerm } = (store ?? useFiltersStore)(state => ({
		functions: state.functions,
		setTerm: state.setTerm
	}));

	const functionsData = propsFunctions ?? functions;

	useDebounce(() => setTerm(searchTerm), 500, [searchTerm]);
	const { data: categories } = useGetCategories();

	const groupedSkills = _groupBy(selected, "parent_sector.name");

	useEffect(() => {
		if (!cartRef.current) return;
		cartRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [groupedSkills, cartRef]);

	return (
		<div className={s.root}>
			<div className={s.categorySelect}>
				<Controller
					control={control}
					name={"category"}
					render={({ field: { onChange, value } }) => (
						<Select
							onChange={onChange}
							value={value}
							options={
								!functionsData?.length
									? categories?.map(c => ({ label: c.name, value: c._id }))
									: functionsData?.map(
											f =>
												f?.category ?? {
													label: f?.parent_sector?.name,
													value: f?.parent_sector?._id
												}
									  )
							}
						/>
					)}
				/>
			</div>
			<div className={s.search}>
				<input
					type={"text"}
					{...register("term")}
					placeholder={"Find by name"}
				/>
			</div>
			<div className={s.categorySkills}>
				{data?.length ? (
					data?.map(skill => (
						<button key={skill._id} onClick={() => onSelect(skill)}>
							{skill.name}
						</button>
					))
				) : (
					<p>
						{searchTerm
							? "No skills found matching your search term."
							: isLoading
							? "Loading skill..."
							: "Select a category from the dropdown above to start."}
					</p>
				)}
			</div>
			<div className={s.cart}>
				{_map(groupedSkills, (_, key, val) => (
					<div key={key}>
						<label>{key}</label>
						<ul>
							{_map(val[key], skill => (
								<li className={s.skillItem} key={skill._id}>
									{skill.name}
									<button onClick={() => onRemove(skill)}>
										<XIcon height={16} width={16} color={"red"} />
									</button>
								</li>
							))}
						</ul>
					</div>
				))}
				<div ref={cartRef} />
			</div>
		</div>
	);
}

export default Skills;
