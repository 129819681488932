import React, { useState } from "react";
import { useToggle } from "react-use";
import _get from "lodash/get";
import { queryCache } from "react-query";
import EditableSection from "modules/candidate/Profile/Pages/Profile/components/EditableSection";
import s from "./functions.module.scss";
import ToolTip from "common/GlobalTooltip";
import { ReactComponent as AddIcon } from "static/icons/focus-add.svg";
import { ReactComponent as StarIcon } from "static/icons/star-outline.svg";
import { ReactComponent as SeparatorIcon } from "static/icons/separator-point.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle.svg";
import styles from "modules/candidate/Profile/components/Card/card.module.scss";
import FunctionsDialog from "./FunctionsDialog";
import useUpdateInternalData from "modules/candidate/Profile/api/useUpdateInternalData";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import { profileOverviewKey } from "modules/candidate/Profile/api/useViewCandidateInternal";
import { editHistoryKey } from "modules/candidate/Profile/api/useEditHistory";
import { ReactComponent as BotIcon } from "static/icons/bot.svg";
import LeaveHook from "common/LeaveHook";
import useUnsavedChangesDialog from "modules/candidate/Profile/Store/useUnsavedChangesDialog";
import { viewOverviewKey } from "modules/candidate/Profile/api/useOverview";

const CandidateFunctions = ({ functions = [], data, cv_id, skills }) => {
	const [on, toggle] = useToggle(false);
	const [isDirty, setIsDirty] = useState(false);

	const [
		isUnsavedChangesDialogOpen,
		toggleUnsavedChangesDialogOpen
	] = useUnsavedChangesDialog();

	const getSkillsCategories = skills => {
		let categories = [
			...new Set(
				skills.map(s => _get(s, "parent_sector._id", undefined)).filter(Boolean)
			)
		];

		return categories;
	};

	const [
		updateStatus,
		{ isLoading: isLodingUpdateInternalData }
	] = useUpdateInternalData({
		onSuccess() {
			toaster.success("Candidate Functions has successfully been updated.");
			queryCache.invalidateQueries(profileOverviewKey);
			queryCache.invalidateQueries(editHistoryKey);
			queryCache.invalidateQueries(viewOverviewKey);
			toggle(false);
			resetDirty();
		},
		onError(err) {
			toaster.danger(
				_get(err, "detail.fields_data[0]", "Something went wrong")
			);
		}
	});

	const onSave = payload => {
		const listFunctions = payload.map(fnc => {
			return {
				_id: _get(fnc, "_id"),
				name: _get(fnc, "name"),
				seniority: _get(fnc, "seniority"),
				sector_id: _get(fnc, "sector._id"),
				position: _get(fnc, "position"),
				is_main: _get(fnc, "is_main")
			};
		});
		const data = {
			cv_id: cv_id,
			fields_data: {
				tag: "functions",
				value: listFunctions
			}
		};
		updateStatus(data);
	};

	const handleAddFunctionButtonClick = () => {
		markDirty();
	};

	const handleRemoveFunctionButtonClick = () => {
		markDirty();
	};

	const handleUpdateFunctionButtonClick = () => {
		markDirty();
	};

	const handleMainChange = () => {
		markDirty();
	};

	const handleCancelButtonClick = () => {
		resetDirty();
	};

	const markDirty = () => {
		setIsDirty(true);
	};

	const resetDirty = () => {
		setIsDirty(false);
	};

	return (
		<LeaveHook
			dirty={isDirty && !isUnsavedChangesDialogOpen}
			onOpen={toggleUnsavedChangesDialogOpen}
			onStay={toggleUnsavedChangesDialogOpen}
			onLeaveClick={toggleUnsavedChangesDialogOpen}
		>
			<EditableSection
				title="Functions"
				isEdit={false}
				onEdit={() => toggle(true)}
				onCancel={() => {
					resetDirty();
					toggle(false);
				}}
				isLoading={false}
			>
				{isLodingUpdateInternalData && <LoaderFull />}
				{functions.length > 0 && (
					<div className={s.content}>
						<div className={s.section}>
							{functions.map(item => (
								<div key={item.name} className={styles.section}>
									<div className={s.row}>
										<StarIcon fill={item.is_main ? "#F01C29" : "#BABEC3"} />
										<span className={styles.textNormal}>{item.name}</span>
										<SeparatorIcon />
										<span className={styles.textNormal}>
											{item.seniorityLabel}
										</span>
										<ToolTip
											placement={"right"}
											maxWidth={"30rem"}
											align={{
												offset: [15, 0]
											}}
											overlay={item.tooltip}
										>
											<InfoIcon className={styles.flex} />
										</ToolTip>
										{item.is_bot && <BotIcon />}
									</div>
								</div>
							))}
						</div>
					</div>
				)}

				<button className={s.btnAdd} onClick={toggle}>
					<AddIcon />
					<span>Add function</span>
				</button>
				{data && (
					<FunctionsDialog
						isOpen={on}
						onClose={() => {
							toggle(false);
							resetDirty();
						}}
						functions={data}
						onSave={onSave}
						isLoading={isLodingUpdateInternalData}
						skillsCategories={getSkillsCategories(skills)}
						removeSkills={() => {}}
						onAddFunctionButtonClick={handleAddFunctionButtonClick}
						onRemoveFunctionButtonClick={handleRemoveFunctionButtonClick}
						onUpdateFunctionButtonClick={handleUpdateFunctionButtonClick}
						onMainChange={handleMainChange}
						onCancelButtonClick={handleCancelButtonClick}
						on
					/>
				)}
			</EditableSection>
		</LeaveHook>
	);
};

export default CandidateFunctions;
