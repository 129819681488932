import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { usePrevious } from "react-use";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import Button from "../../Button/Button";
import { FilterRow } from "../FilterRow";
import { useFilters } from "../hooks/useFilter";
import LogicalOperatorField from "../LogicalOperatorField";
import SourceRow from "../SourceRow/SourceRow";
import styles from "./advanced-search.module.scss";

const ROW_HEIGHT = 36.75;

function AdvancedSearch({ hideTabSaved, onlyList = false }) {
	const {
		handleFilterDelete,
		handleFilterAdd,
		getState,
		currentFilterId
	} = useFilters();

	const { filters, selectedFilter } = getState(currentFilterId);
	const scrollbars = useRef();
	const [filtersLength, setFiltersLength] = useState(filters);
	const prevFiltersLength = usePrevious(filters.length);

	const scrollToHandler = index => {
		scrollbars.current.view.scroll({
			top: index * ROW_HEIGHT,
			behavior: "smooth"
		});
	};

	useEffect(() => {
		if (prevFiltersLength < filters.length) {
			const scrollHeight = scrollbars.current.getScrollHeight();
			scrollbars.current.view.scroll({
				top: scrollHeight,
				behavior: "smooth"
			});
		}
		setFiltersLength(filtersLength);
	}, [filters.length]);

	useEffect(() => {
		if (selectedFilter) {
			const index = filters.findIndex(filter => filter.id === selectedFilter);
			if (index !== -1) {
				scrollToHandler(index);
			}
		}
	}, [selectedFilter]);

	return (
		<div className={styles.root}>
			{hideTabSaved || (!onlyList && <SourceRow />)}
			<Scrollbars
				ref={scrollbars}
				autoHeight
				autoHeightMin={hideTabSaved ? `calc(100vh - 300px)` : `450px`}
			>
				<ul className={styles.filters}>
					{filters.map((filter, index) => (
						<li key={filter.id} className={styles.filterWrapper}>
							{index === 0 ? (
								<div className={styles.where}>Where</div>
							) : (
								<LogicalOperatorField
									className={styles.logicalOperator}
									disabled={index !== 1}
								/>
							)}
							<div
								className={
									filter.id && selectedFilter === filter.id
										? styles.highlighted
										: ""
								}
							>
								<FilterRow filter={filter} />
							</div>

							<div
								onClick={() => handleFilterDelete(filter.id)}
								className={styles.deleteIconWrapper}
							>
								<svg
									width="1.125rem"
									height="1.125rem"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.3333 4.66675L4.66663 11.3334M4.66663 4.66675L11.3333 11.3334"
										stroke="#d4d4d4"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</li>
					))}
				</ul>
				<Button
					text="Add"
					icon={<AddIcon />}
					onClick={handleFilterAdd}
					variant="outlined"
				/>
			</Scrollbars>
		</div>
	);
}

export default React.memo(AdvancedSearch);
