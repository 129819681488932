import { Chip } from "common/Chip";
import { DropDownWithSearch } from "common/DropDownWithSearch";
import React, { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete-newest-version/lib/usePlacesAutocompleteService";
import styles from "./custom-map.module.scss";
import { EmptySearchCard } from "./EmptySearchCard";
import RadiusField from "./RadiusField/RadiusField";
import LoadingSpinner from "common/LoadingSpinner/LoadingSpinner";
import cx from "classnames";

const CustomMap = ({
	onConfirm,
	value,
	displayRadius = true,
	rootClassname,
	inputClassname,
	inputContent
}) => {
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [locationsWithDetails, setlocationsWithDetails] = useState([]);
	const [search, setSearch] = useState("");
	const [radius, setRadius] = useState("");
	const [loadingLocationDetails, setLoadingLocationDetails] = useState(false);

	const {
		placePredictions,
		getPlacePredictions,
		placesService,
		isPlacePredictionsLoading
	} = usePlacesService({
		apiKey: "AIzaSyBDMLcvixgtVZU2krjfHP_WJIjqlAHF2pE"
	});

	const [displayPaper, setDisplayPaper] = useState(false);

	useEffect(() => {
		if (displayPaper) {
			const selected = Array.isArray(value) ? value : [];

			if (selected.length) {
				setSelectedLocations([selected[0].place]);
				setRadius(selected[0].radius);
			}
		}
	}, [value, displayPaper]);

	useEffect(() => {
		if (placePredictions.length) {
			const places = [];
			let counter = 0;

			const placesFiltered = placePredictions.filter(
				({ types }) => !types.includes("continent")
			);

			setLoadingLocationDetails(true);
			placesFiltered.forEach((placePrediction, i) => {
				placesService?.getDetails(
					{
						placeId: placePrediction.place_id
					},
					placeDetails => {
						places[i] = {
							...placeDetails,
							description: placePrediction.description
						};
						counter++;
						if (counter === placesFiltered.length) {
							setlocationsWithDetails(places);
							setLoadingLocationDetails(false);
						}
					}
				);
			});
		}
	}, [placePredictions]);

	const handleSearchChange = evt => {
		setSearch(evt.target.value);
		getPlacePredictions({ input: evt.target.value });
	};

	const handleInputClick = () => {
		setDisplayPaper(displayPaper => !displayPaper);
	};

	const handleLocationClick = location => {
		setSelectedLocations([location]);
		setRadius("");
	};

	const handleConfirmClick = () => {
		if (selectedLocations.length === 0) return;
		setSearch("");
		const locations = [
			{ place: selectedLocations[0], radius: radius === "" ? 0 : +radius }
		];
		onConfirm(locations);
		setSelectedLocations([]);
		setRadius("");
		setDisplayPaper(false);
	};

	const handleResetClick = () => {
		setSelectedLocations([]);
	};

	const ids = selectedLocations.map(({ place_id }) => place_id);
	const locationsFiltered = locationsWithDetails.filter(
		location => !ids.includes(location.place_id)
	);

	const handleValueDelete = e => {
		e.stopPropagation();
		onConfirm([]);
	};

	const handleClose = () => {
		setSearch("");
		setDisplayPaper(false);
		setSelectedLocations([]);
	};

	const handleDeleteClick = () => {
		setSelectedLocations([]);
		setRadius("");
	};

	const handleRadiusChange = e => {
		if (+e.target.value > 0) {
			setRadius(parseInt(e.target.value, 10));
		} else {
			setRadius("");
		}
	};

	let tags = <EmptySearchCard />;

	if (isPlacePredictionsLoading || loadingLocationDetails) {
		tags = (
			<div className={styles.loaderContainer}>
				<LoadingSpinner className={styles.loader} />
			</div>
		);
	} else if (selectedLocations.length) {
		tags = (
			<div className={styles.container}>
				<div className={styles.header}>
					<div>Selected location</div>
					<div className={styles.line} />
				</div>
				<div className={styles.resultContainer}>
					{selectedLocations.map(place => {
						return (
							<Chip
								onClick={() => handleDeleteClick()}
								key={place.place_id}
								text={`${place.description} ${
									+radius > 0 ? "- " + radius + " km" : ""
								}`}
								className={styles.resultChip}
								isDelete
							/>
						);
					})}
				</div>
				{displayRadius && (
					<RadiusField
						radius={radius.toString()}
						onChange={handleRadiusChange}
					/>
				)}
			</div>
		);
	} else if (search) {
		tags = (
			<div className={styles.container}>
				<div className={styles.header}>
					<div className={styles.number}>{locationsFiltered.length}</div>
					<div>results</div>
					<div className={styles.line} />
				</div>
				<div className={styles.resultContainer}>
					{locationsFiltered.map(place => {
						return (
							<Chip
								onClick={() => handleLocationClick(place)}
								key={place.place_id}
								text={`${place.description}`}
								className={styles.resultChip}
							/>
						);
					})}
				</div>
			</div>
		);
	}

	const confirmedValuesChips = inputContent ? (
		inputContent
	) : value?.length ? (
		<div className={styles.valueWrapper}>
			{value.map(location => {
				return (
					<Chip
						key={location.place}
						text={`${location.place.description} ${
							+location.radius > 0 ? "- " + location.radius + "km" : ""
						}`}
						onClick={handleValueDelete}
					/>
				);
			})}
		</div>
	) : null;

	return (
		<DropDownWithSearch
			displayPaper={displayPaper}
			displayFooter={true}
			onSearchChange={handleSearchChange}
			content={tags}
			onInputClick={handleInputClick}
			onConfirmClick={handleConfirmClick}
			onResetClick={handleResetClick}
			value={confirmedValuesChips}
			placeHolder="Search for a location"
			onClose={handleClose}
			dropDownInputClassName={cx(styles.dropDownInput, inputClassname)}
			rootClassname={rootClassname}
		/>
	);
};

CustomMap.displayName = "CustomMap";

export default CustomMap;
