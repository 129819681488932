import React from "react";
import styles from "./empty-cv.module.scss";
import { ReactComponent as Icon } from "static/icons/note-grey.svg";

const EmptyCV = () => (
	<div className={styles.container}>
		<Icon />
		<div className={styles.title}>No resume (CV) available.</div>
		<div className={styles.description}>
			this profile Has not uploaded his cv yet
		</div>
	</div>
);

export default EmptyCV;
