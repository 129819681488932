import React, { forwardRef } from "react";
import { useTreeState } from "@react-stately/tree";
import { useMenu } from "@react-aria/menu";
import styles from "../MenuColumn/menu-column.module.scss";
import MenuItem from "../MenuItem/MenuItem";

function Menu(props, refParam) {
	let ref = React.useRef();

	// Create state based on the incoming props

	let state = useTreeState({ ...props, selectionMode: "none" });
	// Get props for the menu element

	let { menuProps } = useMenu(props, state, ref);
	return (
		<ul
			{...menuProps}
			ref={value => {
				if (refParam) refParam.current = value;
				ref.current = value;
			}}
			style={{
				...props.style
			}}
			className={styles.ulMenu}
		>
			{[...state.collection].map(item => (
				<MenuItem key={item.key} item={item} state={state} />
			))}
		</ul>
	);
}
export default forwardRef(Menu);
