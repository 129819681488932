import styled, { css } from "styled-components";
import { colors } from "config/styles";

export const Content = styled.div`
	width: 100%;
	.residency-text {
		position: relative;
		top: -10px;
		color: ${colors.anchorsColor};
	}
`;

export const LocationBox = styled.div`
	align-items: center;
	max-width: 170px;
	height: 31.5px;
	border-radius: 3px;
	box-shadow: 0 0 19.8px 0.2px rgba(214, 214, 214, 0.24);
	border: solid 1px
		${props => (props.main ? colors.primaryColor : colors.locationBox)};
	background-color: ${colors.white};
	text-align: center;
	vertical-align: middle;
	line-height: 31.5px;
	font-size: 12px;
	font-weight: 500;
	color: ${colors.anchorsColor};
	${props =>
		props.overflow &&
		css`
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`};
	${props =>
		props.flex &&
		css`
			display: flex;
		`};
	text-transform: capitalize;
	margin-right: 10px;
	padding: 0 5px 0 5px;
	margin-bottom: 10px;
	i {
		font-size: 12px;
	}
`;

export const LocationTextBox = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const GrayBox = styled.div`
	min-height: 180px;
	margin-bottom: 8px;
	position: relative;
	border: solid 1px ${colors.sectionBorderColor};
	border-radius: 2px;
	background-color: ${colors.lineHover};
	padding: 10px;
`;

export const LocationBoxContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const ListButton = styled.button`
	background-color: unset;
	color: ${colors.deleteButton};
	border: none;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	font-size: 14px;
	font-weight: 400;
	padding: 2px 2px;
	outline: 0;
	line-height: 14px;
	&:hover {
		background-color: ${colors.locationBox};
		color: ${colors.white};
	}
	&:disabled {
		&:hover {
			background-color: ${colors.locationBoxLightGray};
			box-shadow: none;
		}
	}
`;

export const Counter = styled.div`
	width: 100%;
	float: right;
	height: 25px;
	text-align: right;
	div {
		float: left;
		color: ${colors.errorRed};
	}
`;

export const Container = styled.div`
	.address-input-container {
		display: flex;
		width: 100%;

		.address-input {
			width: 80%;
		}
		button {
			margin-left: 16px;
			width: 125px;
			height: 50px;
		}
	}
`;
