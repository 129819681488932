import React, { useEffect } from "react";
import { Box } from "rebass";

const Tab = ({ id, title, setTabs, activeTab, children }) => {
	useEffect(() => {
		setTabs(tabs => [...tabs, { id, title }]);
	}, []);
	return (
		id === activeTab && (
			<Box
				flex={1}
				p={26}
				sx={{ border: "3px solid #FFFFFF", borderRadius: "0px 5px 5px 5px" }}
			>
				{children}
			</Box>
		)
	);
};

export default Tab;
