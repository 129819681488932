import React from "react";
import Card from "modules/candidate/Profile/components/Card/Card.js";
import _get from "lodash/get";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ReactComponent as EmptyState } from "static/icons/exclamation-triangledanger.svg";
import styles from "../all-processes-card.module.scss";
// import Title from "./TitleIcon";

const AllProcesses = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	isLoading,
	processes,
	title
}) => {
	const statistics = _get(processes, "statistics");

	const application = _get(statistics, "application", "0");
	const hired = _get(statistics, "hired", "0");
	const interview = _get(statistics, "interview", "0");
	const offer = _get(statistics, "offer", "0");
	const phone_screen = _get(statistics, "phone_screen", "0");
	const shortlist = _get(statistics, "shortlist", "0");
	return (
		<Card title={title} {...{ onDragEnd, onViewMore, onToggleVisibility }}>
			{!isLoading ? (
				<div className={styles.section}>
					<div className={styles.row}>
						<div className={styles.column}>
							<div className={styles.processSquare}>
								<p>{application}</p>
							</div>
							<div className={styles.title}>Application</div>
						</div>
						<div className={styles.column}>
							<div className={styles.processSquare}>
								<p>{hired}</p>
							</div>
							<div className={styles.title}>Hired</div>
						</div>
						<div className={styles.column}>
							<div className={styles.processSquare}>
								<p>{interview}</p>
							</div>
							<div className={styles.title}>Interview</div>
						</div>
						<div className={styles.column}>
							<div className={styles.processSquare}>
								<p>{offer}</p>
							</div>
							<div className={styles.title}>Offer</div>
						</div>
						<div className={styles.column}>
							<div className={styles.processSquare}>
								<p>{phone_screen}</p>
							</div>
							<div className={styles.title}>Phone Screen</div>
						</div>
						<div className={styles.column}>
							<div className={styles.processSquare}>
								<p>{shortlist}</p>
							</div>
							<div className={styles.title}>Shortlist</div>
						</div>
					</div>
				</div>
			) : !Boolean(statistics?.length) ? (
				<div className={styles.emptyState}>
					<EmptyState />
					<p>No results to show</p>
				</div>
			) : (
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={200} height={16} />
				</SkeletonTheme>
			)}
		</Card>
	);
};

export default AllProcesses;
