import React from "react";
import loadable from "loadable-components";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";

export const LoadingStateCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/LoadingStateCell/LoadingStateCell"
	)
);
export const ResidenceCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/ResidenceCell/ResidenceCell"
	)
);
export const GenericCell = loadable(() =>
	import("common/AgTable/components/RenderCellsGlobal/GenericCell/GenericCell")
);
import { CANDIDATES_FILTER } from "modules/SearchCandidate/utils/constants";

export const LastNoteCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/LastNoteCell/LastNoteCell"
	)
);
export const ExternalProfil = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/ExternalProfilCell/ExternalProfilCell"
	)
);
export const SkillsCell = loadable(() =>
	import("common/AgTable/components/RenderCellsGlobal/SkillsCell/SkillsCell")
);
export const PreferredLocationsCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/PreferredLocationsCell/PreferredLocationsCell"
	)
);
export const ExtraBenifitsCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/ExtraBenifitsCell/ExtraBenifitsCell"
	)
);
export const InterviewCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/InterviewCell/InterviewCell"
	)
);
export const EmailsPhonesCells = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/EmailsPhonesCells/EmailsPhonesCells"
	)
);
export const FunctionsCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/FunctionsCell/FunctionsCell"
	)
);
export const CategoriesSenCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/CategoriesSenioritiesCell/CategoriesSenioritiesCell"
	)
);
export const ProfileTagsCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/ProfileTagsCell/ProfileTagsCell"
	)
);

const RenderCells = props => {
	const { getState } = useFilters();
	const { isLoading, isFetching } = getState(CANDIDATES_FILTER);

	const cells = {
		_id: <GenericCell data={props} isNumber />,
		first_name: <GenericCell data={props} />,
		last_name: <GenericCell data={props} />,
		email_address: <EmailsPhonesCells data={props} isEmail />,
		phone_number: <EmailsPhonesCells data={props} />,
		employment_type: <CategoriesSenCell data={props} isEmploymentType />,
		residence: <ResidenceCell data={props} />,
		preferred_locations: <PreferredLocationsCell data={props} />,
		expected_salary: <GenericCell data={props} isNumber />,
		currency_salary: <GenericCell data={props} />,
		payroll_country: <GenericCell data={props} />,
		salary_type: <GenericCell data={props} />,
		notice_period: <GenericCell data={props} isNumber />,
		extra_benefits: <ExtraBenifitsCell data={props} />,
		other_extra_benefits: <ExtraBenifitsCell data={props} />,
		gross_net: <GenericCell data={props} />,
		category: <CategoriesSenCell data={props} />,
		sub_category: <CategoriesSenCell data={props} />,
		seniority: <CategoriesSenCell data={props} />,
		functions: <FunctionsCell data={props} />,
		skills: <SkillsCell data={props} />,
		languages: <SkillsCell data={props} />,
		profile_tags: <ProfileTagsCell data={props} />,
		processes: <GenericCell data={props} />,
		interactions: <GenericCell data={props} />,
		notes: <GenericCell data={props} />,
		last_note: <LastNoteCell data={props} isSearchCandidate />,
		profile_created_by: <GenericCell data={props} />,
		profile_created_on: <GenericCell data={props} />,
		is_interviewed: <InterviewCell data={props} />,
		is_activated: <InterviewCell data={props} />,
		is_viewed: <GenericCell data={props} />,
		is_referred: <GenericCell data={props} />,
		source: <GenericCell data={props} />,
		zip_code: <GenericCell data={props} />,
		external_profile: <ExternalProfil data={props} />,
		is_followed: <GenericCell data={props} />
	};
	return (
		<>
			{isLoading || isFetching ? (
				<LoadingStateCell />
			) : (
				cells[`${props.column.colId}`]
			)}
		</>
	);
};

export default RenderCells;
