import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { REQUESTS_OVERVIEW } from "config/api-endpoints";

const requestsOverview = body => client(REQUESTS_OVERVIEW, { body });

export const GET_REQUESTS_OVERVIEW_QUERY_KEY = `GET_REQUESTS_OVERVIEW_QUERY_KEY`;

const useGetRequestsStatistics = (options, queryOptions = {}) =>
	useQuery(
		[GET_REQUESTS_OVERVIEW_QUERY_KEY, options],
		() => requestsOverview(options),
		{
			retry: 0,
			refetchOnWindowFocus: false,
			...queryOptions
		}
	);

export default useGetRequestsStatistics;
