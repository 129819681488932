import React, { useState } from "react";
import { ReactComponent as PlayIconGreen } from "static/icons/play-icon-green.svg";
import Modal from "common/modal";
import {
	PlayIconContainer,
	VideoImage,
	VideoContainer,
	VideoWrapper
} from "./Styled";
import styles from "./video-card.module.scss";
import embed from "embed-video";

const VideoCard = ({ url, className = "" }) => {
	const [displayModal, setDisplayModal] = useState(false);
	const [image, setImage] = useState();

	const onPlay = () => {
		setDisplayModal(true);
	};

	const onClose = () => {
		setDisplayModal(false);
	};

	embed.image(url, { image: "maxresdefault" }, (_, thumbnail) => {
		setImage(thumbnail?.src);
	});

	return (
		<>
			{displayModal && (
				<Modal className={styles.modal} onClose={onClose}>
					<div
						dangerouslySetInnerHTML={{
							__html: embed(url, {
								query: { autoplay: 1, mute: 1 },
								attr: {
									allow: "autoplay",
									style: "width: 707px; height: 434px "
								}
							})
						}}
					/>
				</Modal>
			)}
			<VideoWrapper className={className}>
				<VideoContainer onClick={onPlay}>
					<VideoImage
						style={{
							background: `url(${image})`
						}}
					>
						<PlayIconContainer>
							<PlayIconGreen className={styles.playIconOrange} />
						</PlayIconContainer>
					</VideoImage>
				</VideoContainer>
			</VideoWrapper>
		</>
	);
};
export default VideoCard;
