import React from "react";
import styles from "./bid-details-processes-card.module.scss";
import { ReactComponent as CheckVerifiedIcon } from "static/icons/check-verified.svg";

const BidDetailsCongratulationMassage = ({ primaryText, secondText }) => {
	return (
		<div className={styles.message}>
			<div className={styles.message_item}>
				<CheckVerifiedIcon />
				<span className={styles.primary}>{primaryText}</span>
			</div>
			<div className={styles.message_item}>
				<span className={styles.seconder}>{secondText}</span>
			</div>
		</div>
	);
};

export default BidDetailsCongratulationMassage;
