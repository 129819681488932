import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DUPLICATE_EVALUATION_TEMPLATE } from "config/api-endpoints";

const duplicateTemplate = body => {
	return client(DUPLICATE_EVALUATION_TEMPLATE, {
		body
	});
};

export default function useDuplicateEvaluationTemplate(options = {}) {
	return useMutation(duplicateTemplate, {
		retry: 0,
		...options
	});
}
