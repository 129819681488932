import create from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState = {
	jobs: null,
	filters: {},
	offsets: []
};

const serachJobsStore = set => ({
	...initialState,
	// setters
	setJobs: jobs => set(state => ({ ...state, jobs })),
	setFilters: filters => set(state => ({ ...state, filters })),
	setOffsets: offsets => set(state => ({ ...state, offsets })),
	// clear store
	resetStore: () => set(() => initialState)
});

const useJobsStore = create(
	persist(devtools(serachJobsStore), {
		name: "search-jobs-store"
	})
);
export default useJobsStore;
