import React from "react";
import styles from "./bid-actions-select.module.scss";
import cx from "classnames";
import Tooltip from "common/Tippy";
import { Link } from "react-router";

const BidPrimaryAction = ({ bidActionsManager }) => {
	const option = bidActionsManager.getPrimaryAction();
	return option ? (
		option.status === "disabled" ? (
			<Tooltip
				content={option.status === "disabled" && option.tooltipText}
				theme="dark"
				placement="top"
			>
				{option.to ? (
					<Link
						key={option.key}
						className={cx(
							styles.primaryBtn,
							styles[option.status || "success"]
						)}
						to={option.to}
					>
						{option.label}
					</Link>
				) : (
					<button
						className={cx(
							styles.primaryBtn,
							styles[option.status || "success"]
						)}
						onClick={option.status === "disabled" ? () => {} : option.action}
					>
						<span>{option.label}</span>
					</button>
				)}
			</Tooltip>
		) : option.to ? (
			<Link
				key={option.key}
				className={cx(styles.primaryBtn, styles[option.status || "success"])}
				to={option.to}
			>
				{option.label}
			</Link>
		) : (
			<button
				className={cx(styles.primaryBtn, styles[option.status || "success"])}
				onClick={option.status === "disabled" ? () => {} : option.action}
			>
				<span>{option.label}</span>
			</button>
		)
	) : (
		<></>
	);
};

export default BidPrimaryAction;
