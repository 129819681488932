import React from "react";
import ButtonAnswer from "./ButtonAnswer";
import LocationAnswer from "./LocationAnswer";
import InputAnswer from "./InputAnswer";
import { BOT_ACTION_TYPES } from "./botContext";
import { ONE_MILLION, EUR } from "config";

const MAX_NOTICE_CHARS = 2000;

const permanentSteps = [
	{
		id: "0",
		message: `
        Welcome to wiggli! Great to hear you’re not a robot; I am one 🤖😃.
        I will assist you in building up your profile before my human counterpart takes over for a quick chat.
        What functions’ category best describes your expertise?
      `,
		trigger: "1"
	},
	{
		id: "1",
		component: <ButtonAnswer options={"sectors"} value="selectedSector" />,
		waitAction: true
	},
	{
		id: "2",
		message: ({ previousValue }) =>
			`Alright! What sub-category of functions in ${previousValue} do you work in?`,
		trigger: "3"
	},
	{
		id: "3",
		component: (
			<ButtonAnswer options={"subSectors"} value="selectedSubSector" />
		),
		waitAction: true
	},
	{
		id: "4",
		message:
			"Great; which function best describes your current/latest/next job title?",
		trigger: "5"
	},
	{
		id: "5",
		component: <ButtonAnswer options={"functions"} value="selectedFunction" />,
		waitAction: true
	},
	{
		id: "6",
		message: "Nice! What seniority do you have in this role?",
		trigger: "7"
	},
	{
		id: "7",
		component: <ButtonAnswer options={"seniority"} value="selectedSeniority" />,
		waitAction: true
	},
	{
		id: "8",
		message: `
			Perfect! Now that I know a little more about what you do;
			let’s go through what you’re interested in!
			Which is the main location (city) you’re ready to work from?
		`,
		trigger: "9"
	},
	{
		id: "9",
		component: <LocationAnswer />,
		waitAction: true
	},
	{
		id: "pre-salary",
		message: `
			Nice place! Although I’ve never been there… I’ve never been anywhere… 😢 But this isn’t about me!
			For which annual gross salary (in €) would you be ready to consider a new challenge?
		`,
		trigger: "salary"
	},
	{
		id: "salary",
		component: (
			<InputAnswer
				trigger="pre-notice"
				value="selectedExpectation"
				placeholder="Type your expected salary"
				actionType={BOT_ACTION_TYPES.SET_SELECTED_EXPECTATION}
				numbersOnly={true}
				min={1}
				max={ONE_MILLION}
				errorMessage={`Salary should be between 1 ${EUR} and ${ONE_MILLION} ${EUR}`}
			/>
		),
		waitAction: true
	},
	{
		id: "pre-notice",
		message: `
			🤑🤑🤑😉 And how long would your notice period be if you were to leave your current employer today?
			 (if none, just type NA)
		`,
		trigger: "notice"
	},
	{
		id: "notice",
		component: (
			<InputAnswer
				trigger="end"
				value="selectedNoticePeriod"
				placeholder="Type your notice period"
				actionType={BOT_ACTION_TYPES.SET_SELECTED_NOTICE_PERIOD}
				maxLength={MAX_NOTICE_CHARS}
				errorMessage={`Field should have a maximum of ${MAX_NOTICE_CHARS} characters`}
			/>
		),
		waitAction: true
	},
	{
		id: "end",
		message: `
			OK, I now have sufficient information to assign you to a specialized (human!) job hunter.
			This person will contact you shortly to fine tune your wiggli profile and anonymize your CV.
			You’ll then be completely incognito on the platform;
			and companies will poke you for vacancies, solely based on your skills, experiences and expectations.
			If interested, it’s up to you to let them know who you are.
			You can now close this window; and simply wait for your dream job to come to you 😃
		`,
		end: true
	}
];

const freelancerSteps = [
	{
		id: "0",
		message: `
		Welcome to wiggli! I will assist you in building up your profile before my human counterpart takes over for a quick chat. What functions’
		category best describes your current expertise? (note that you'll be able to add more functions to your profile after registration)
  `,
		trigger: "1"
	},
	{
		id: "1",
		component: <ButtonAnswer options={"sectors"} value="selectedSector" />,
		waitAction: true
	},
	{
		id: "2",
		message: ({ previousValue }) =>
			`Alright! What sub-category of functions in ${previousValue} do you work in?`,
		trigger: "3"
	},
	{
		id: "3",
		component: (
			<ButtonAnswer options={"subSectors"} value="selectedSubSector" />
		),
		waitAction: true
	},
	{
		id: "4",
		message: "Great; which function best describes you?",
		trigger: "5"
	},
	{
		id: "5",
		component: <ButtonAnswer options={"functions"} value="selectedFunction" />,
		waitAction: true
	},
	{
		id: "6",
		message: "Nice! What seniority do you have in this role?",
		trigger: "7"
	},
	{
		id: "7",
		component: <ButtonAnswer options={"seniority"} value="selectedSeniority" />,
		waitAction: true
	},
	{
		id: "8",
		message: `
		OK, thank you! I now have sufficient information to generate your basic account. 
		Please activate it within the next 2 hours, 
		using the link I have just sent to your email address. 
	`,
		end: true
	}
];

const steps = {
	freelancer: freelancerSteps,
	permanent: permanentSteps
};
export default steps;
