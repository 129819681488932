import { yupResolver } from "@hookform/resolvers/yup";
import { parsePhoneNumber } from "libphonenumber-js";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useGetEmailSignature } from "../api/useGetEmailSignature";
import prependHttp from "prepend-http";

const URL_REGEX = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/;

export const schema = yup.object().shape({
	full_name: yup.string().required("Full name is required"),
	position: yup.string().required("Job title is required"),
	email: yup.string().email("Invalid email address"),
	phone: yup
		.object()
		.test("phone", "Invalid phone number", value => {
			if (value?.countryCode && value?.value) {
				try {
					const res = parsePhoneNumber(value.value, {
						extract: false
					});
					return res.isValid();
				} catch (error) {
					return false;
				}
			}
			return true;
		})
		.nullable(),
	social_media: yup.array(
		yup.object().shape({
			platform: yup.string(),
			url: yup.string().test("is-url-valid", "URL is not valid", value => {
				if (!value) return true;
				return URL_REGEX.test(prependHttp(value));
			})
		})
	)
});

const defaultSocialMedia = [
	{
		platform: "linkedin",
		url: ""
	},
	{
		platform: "twitter",
		url: ""
	},
	{
		platform: "instagram",
		url: ""
	},
	{
		platform: "facebook",
		url: ""
	}
];

export default function useEmailSignatureForm() {
	const formContext = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			avatar: "",
			full_name: "",
			position: "",
			phone: "",
			email: "",
			address: "",
			social_media: defaultSocialMedia
		}
	});

	const signatureQuery = useGetEmailSignature({
		onSuccess(data) {
			const prepareSocialMedia = () => {
				return defaultSocialMedia.map(i => {
					const savedSocialMediaItem = data.social_media.find(
						s => s.platform === i.platform
					);
					return {
						url: savedSocialMediaItem?.url ?? i.url,
						platform: i.platform
					};
				});
			};
			formContext.reset({
				...data,
				phone: data.phone
					? {
							value: data.phone.split(" ")[1],
							countryCode: data.phone.split(" ")[0].toUpperCase()
					  }
					: null,
				social_media: prepareSocialMedia()
			});
		}
	});

	return { formContext, signatureQuery };
}
