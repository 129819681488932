import React from "react";
import _get from "lodash/get";
import { Flex, Box, Text } from "rebass";
import { PreferencesContainer } from "./Styled";
import CurrencyFormatter from "common/CurrencyFormater";
import { EUR, EMPLOYMENT_TYPE } from "config";
import GlobalTooltip from "common/GlobalTooltip";

const renderMainLocation = (locations = []) => {
	const residency = locations.find(l => l.is_main);

	return `${_get(residency, "city") ? `${residency.city},` : ""} ${_get(
		residency,
		"country"
	)}`;
};

const renderLocations = prefered_locations => {
	const locations = prefered_locations.filter(l => !l.is_main);

	return locations.map((l, index) => {
		if (l.is_main) {
			return <></>;
		}
		return `${index > 0 ? " - " : ""}${
			_get(l, "city") ? `${l.city},` : ""
		} ${_get(l, "country")}`;
	});
};

const employmentType = (employment_type, index) => {
	const typeOfEmployment = EMPLOYMENT_TYPE.find(
		type => type.value === employment_type
	);
	return (
		<Text mr={"6px"}>
			{index !== 0 ? "-" : null} {typeOfEmployment.label}{" "}
		</Text>
	);
};

const MAX_ADVANTAGES_LENGTH = 90;
const Preferences = ({ preference }) => {
	return (
		<PreferencesContainer>
			<div className="section">
				<div className="title">Location</div>
				<div className="section-content">
					<Flex alignItems="center" mb={12}>
						<Box width={4 / 12}>
							<Text
								sx={{
									letterSpacing: "0.99px",
									color: "#a0a5b9",
									textTransform: "uppercase",
									fontSize: "11px",
									fontWeight: "600"
								}}
							>
								Place of residence
							</Text>
						</Box>
						<Box width={8 / 12}>
							<div className="preference-data">
								{renderMainLocation(_get(preference, "preferred_location", []))}
							</div>
						</Box>
					</Flex>

					<Flex alignItems="center" mb={12}>
						<Box width={4 / 12}>
							<Text
								sx={{
									letterSpacing: "0.99px",
									color: "#a0a5b9",
									textTransform: "uppercase",
									fontSize: "11px",
									fontWeight: "600"
								}}
							>
								Preferred Location(S)
							</Text>
						</Box>
						<Box width={8 / 12}>
							<div className="preference-data">
								{renderLocations(_get(preference, "preferred_location", []))}
							</div>
						</Box>
					</Flex>
				</div>
			</div>
			<div className="section">
				<div className="title">Employment &amp; Salary</div>
				<div className="section-content">
					<Flex alignItems="center" mb={12}>
						<Box width={4 / 12}>
							<Text
								sx={{
									letterSpacing: "0.99px",
									color: "#a0a5b9",
									textTransform: "uppercase",
									fontSize: "11px",
									fontWeight: "600"
								}}
							>
								Employment type
							</Text>
						</Box>
						<Box width={8 / 12}>
							<div className="preference-data">
								{_get(preference, "employment_types", []).map((type, index) =>
									employmentType(type, index)
								)}
							</div>
						</Box>
					</Flex>

					<Flex alignItems="center" mb={12}>
						<Box width={4 / 12}>
							<Text
								sx={{
									letterSpacing: "0.99px",
									color: "#a0a5b9",
									textTransform: "uppercase",
									fontSize: "11px",
									fontWeight: "600"
								}}
							>
								Expected Salary
							</Text>
						</Box>
						<Box width={8 / 12}>
							<div className="preference-data">
								<Flex width={"100%"}>
									<Flex
										sx={{
											maxWidth: "160px",
											width: "100%"
										}}
									>
										{_get(preference, "expected_salary") ? (
											<CurrencyFormatter
												cent={_get(preference, "expected_salary")}
												symbol={` ${_get(preference, "symbol_expected", EUR)}`}
											/>
										) : (
											"-"
										)}

										<Flex ml={10}>
											{_get(preference, "payment_type_expected") && (
												<Text
													sx={{
														textTransform: "capitalize",
														fontWeight: "normal"
													}}
												>
													{_get(preference, "payment_type_expected")}
												</Text>
											)}

											{_get(preference, "payment_time_expected") && (
												<Text
													sx={{
														fontWeight: "normal",
														textTransform: "capitalize"
													}}
												>
													/{_get(preference, "payment_time_expected")}
												</Text>
											)}
										</Flex>
									</Flex>
									<Box>
										{_get(preference, "expected_salary_advantage") &&
											(_get(preference, "expected_salary_advantage", "")
												.length < MAX_ADVANTAGES_LENGTH ? (
												<div className="advantages expected">
													{_get(preference, "expected_salary_advantage")}
												</div>
											) : (
												<GlobalTooltip
													placement="top"
													overlay={_get(
														preference,
														"expected_salary_advantage"
													)}
													overlayClassName="g-tool-dark"
												>
													<div className="advantages">
														{`${_get(
															preference,
															"expected_salary_advantage"
														).substr(0, MAX_ADVANTAGES_LENGTH)}...`}
													</div>
												</GlobalTooltip>
											))}
									</Box>
								</Flex>
							</div>
						</Box>
					</Flex>

					<Flex alignItems="center" mb={12}>
						<Box width={4 / 12}>
							<Text
								sx={{
									letterSpacing: "0.99px",
									color: "#a0a5b9",
									textTransform: "uppercase",
									fontSize: "11px",
									fontWeight: "600"
								}}
							>
								Notice Period
							</Text>
						</Box>
						<Box width={8 / 12}>
							<div className="preference-data">
								{_get(preference, "notice_period", "-")}
							</div>
						</Box>
					</Flex>
				</div>
			</div>
		</PreferencesContainer>
	);
};

export default Preferences;
