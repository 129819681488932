import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import RcDropDown from "rc-dropdown";
import _get from "lodash/get";
import Menu, { Item as MenuItem } from "rc-menu";
import { client } from "lib/api-client";
import { EXPORT_TIMESHEET_DATA } from "config/api-endpoints";
import Loader from "common/Loader";
import useOnClickOutside from "hooks/useOnClickOutside";
import { MenuItemWrapper, ButtonTimesheetActionFiles } from "./TimesheetStyled";
import { TIME_FORMAT_24 } from "config";
import { formatDate } from "common/Functions";

const dropdownRef = React.createRef();

function DownloadTimesheet({ timesheet }) {
	const generateFileAction = data => {
		return client(EXPORT_TIMESHEET_DATA, { body: data });
	};

	const [isOpen, setIsOpen] = useState(false);
	const [loadPdf, setLoadPdf] = useState(false);
	const [loadXls, setLoadXls] = useState(false);
	const [mutate] = useMutation(generateFileAction);

	useOnClickOutside(dropdownRef, () => {
		setIsOpen(false);
	});

	let generatedDayExcel = "";
	let generatedTimeExcel = "";
	if (timesheet.excel_link_download) {
		const fileDate = _get(
			timesheet.excel_link_download.split("_"),
			"1",
			""
		).split(".")[0];
		generatedDayExcel = formatDate(fileDate);
		generatedTimeExcel = formatDate(fileDate, true, TIME_FORMAT_24);
	}

	let generatedDayPdf = "";
	let generatedTimePdf = "";

	if (timesheet.pdf_link_download) {
		const fileDate = _get(
			timesheet.pdf_link_download.split("_"),
			"1",
			""
		).split(".")[0];
		generatedDayPdf = formatDate(fileDate);
		generatedTimePdf = formatDate(fileDate, true, TIME_FORMAT_24);
	}

	useEffect(() => {
		setLoadXls(false);
	}, [timesheet.excel_link_download]);
	useEffect(() => {
		setLoadPdf(false);
	}, [timesheet.pdf_link_download]);

	const generateFile = async type => {
		try {
			if (type === "pdf") {
				setLoadPdf(true);
			} else {
				setLoadXls(true);
			}
			await mutate({ id: timesheet._id, tag: type });
		} catch (error) {}
	};

	const pdfLink = !_get(timesheet, "pdf_link_download", "")
		? undefined
		: `https://${_get(timesheet, "pdf_link_download", "").slice(7)}`;
	const excelLink = !_get(timesheet, "excel_link_download", "")
		? undefined
		: `https://${_get(timesheet, "excel_link_download", "").slice(7)}`;

	const TimesheetActionFiles = ({ children }) => {
		const listMenu = () => {
			return (
				<MenuItemWrapper ref={dropdownRef}>
					<Menu selectable={false}>
						<MenuItem>
							<div className="row rowMenu">
								<div className="col-sm-8 textGenerate">
									Generate PDF{" "}
									{generatedDayPdf && generatedTimePdf ? (
										<span>{`Generated ${generatedDayPdf} @ ${generatedTimePdf}`}</span>
									) : null}
								</div>
								<div className="col-sm-4 iconesWrapper">
									<div className="row rowMenu">
										<div className="col-sm-6 wrapperLoaderDocument">
											{loadPdf ? (
												<Loader classes={"small"}></Loader>
											) : pdfLink ? (
												<a
													href={`https://${timesheet?.pdf_link_download?.slice(
														7
													)}`}
													target="_blank"
													rel="noopener noreferrer"
													className="linkDocument"
												>
													<span className="icon-pdf-download downloadIcones">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
														<span className="path7"></span>
														<span className="path8"></span>
														<span className="path9"></span>
													</span>
												</a>
											) : null}
										</div>
										<div className="col-sm-6 btnGenerateFile">
											<span
												className="icon-generate-svg refreshIcone"
												onClick={() => generateFile("pdf")}
											></span>
										</div>
									</div>
								</div>
							</div>
						</MenuItem>
						<MenuItem>
							<div className="row rowMenu">
								<div className="col-sm-8 textGenerate">
									Generate XLS{" "}
									{generatedDayExcel && generatedTimeExcel ? (
										<span>{`Generated ${generatedDayExcel} @ ${generatedTimeExcel}`}</span>
									) : null}
								</div>
								<div className="col-sm-4 iconesWrapper">
									<div className="row rowMenu">
										<div className="col-sm-6 wrapperLoaderDocument">
											{loadXls ? (
												<Loader classes={"small"}></Loader>
											) : excelLink ? (
												<a
													href={`https://${timesheet?.excel_link_download?.slice(
														7
													)}`}
													target="_blank"
													rel="noopener noreferrer"
													className="linkDocument"
												>
													<span className="icon-xls-download downloadIcones">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
														<span className="path6"></span>
														<span className="path7"></span>
														<span className="path8"></span>
														<span className="path9"></span>
													</span>
												</a>
											) : null}
										</div>
										<div className="col-sm-6 btnGenerateFile">
											<span
												className="icon-generate-svg refreshIcone"
												onClick={() => generateFile("xlsx")}
											></span>
										</div>
									</div>
								</div>
							</div>
						</MenuItem>
					</Menu>
				</MenuItemWrapper>
			);
		};

		return (
			<RcDropDown
				trigger={["click"]}
				overlay={listMenu()}
				overlayClassName="timesheetDownloadDropDown"
				visible={isOpen}
				align={{
					points: ["tc", "bc"],
					offset: [30, 4]
				}}
			>
				{children}
			</RcDropDown>
		);
	};
	return (
		<>
			<TimesheetActionFiles>
				<ButtonTimesheetActionFiles
					onClick={() => setIsOpen(true)}
					className={isOpen ? "active" : null}
				>
					Generate PDF/XLS <i className="fa fa-chevron-down" />
				</ButtonTimesheetActionFiles>
			</TimesheetActionFiles>
		</>
	);
}

export default DownloadTimesheet;
