import React from "react";
import DefaultButton from "common/Buttons";
import { Text } from "rebass";
import { ReactComponent as SendVacancy } from "static/icons/send-vacancy-icon.svg";
import Tooltip from "rc-tooltip";

import styled from "styled-components";

const Backdrop = styled.div`
	background-color: rgba(57, 65, 72, 0.81);
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9999;
	overflow: auto;
	display: flex;
	align-items: flex-start;
`;

const SendVacancyButton = ({
	canPoke,
	toggleSendVacancyModal,
	canEdit,
	setIsAddMailToPoke,
	isAddMailToPoke
}) => {
	const Overlay = () => (
		<Text sx={{ fontSize: "14px", fontWeight: "normal", color: "#dfe3e8" }}>
			This action requires the email of candidate
			<DefaultButton
				onPress={() => {
					setIsAddMailToPoke(true);
					document
						.getElementById("add-email-form")
						.scrollIntoView({ behavior: "smooth" });
					setTimeout(() => {
						setIsAddMailToPoke(false);
					}, 2000);
				}}
				padding="0px 8px"
				backgroundColor="#212b36"
			>
				<Text sx={{ fontSize: "14px", fontWeight: "normal", color: "#2263f1" }}>
					Add email
				</Text>
			</DefaultButton>
		</Text>
	);
	return !canPoke && canEdit ? (
		<React.Fragment>
			<Tooltip
				placement="bottom"
				overlay={Overlay()}
				trigger="hover"
				overlayClassName="function-tooltip"
			>
				<button
					style={{
						backgroundColor: "#dfe3e8",
						border: "none",
						borderRadius: "6px",
						marginLeft: "8px",
						height: "40px",
						width: "40px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<SendVacancy />
				</button>
			</Tooltip>
			{isAddMailToPoke && <Backdrop />}
		</React.Fragment>
	) : (
		<button
			style={{
				backgroundColor: "#dfe3e8",
				border: "none",
				borderRadius: "6px",
				marginLeft: "8px",
				width: "40px",
				height: "40px",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}
			disabled={!canPoke}
			onClick={toggleSendVacancyModal}
		>
			<SendVacancy />
		</button>
	);
};

export default SendVacancyButton;
