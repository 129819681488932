import React from "react";
import { useFormContext } from "react-hook-form";
import loadable from "loadable-components";
import { ReactComponent as CheckIcon } from "static/icons/check.svg";
import { ReactComponent as SendIcon } from "static/icons/send.svg";
import { get } from "lodash";
import { Button } from "common/Button";
import styles from "./messaging-tool-new-email-warnings.module.scss";
import cx from "classnames";
import { TO } from "common/MessagingToolNewEmail/utils/constant";

const MessagingToolNewEmailWarningModal = loadable(() =>
	import(
		"../MessagingToolNewEmailWarningModal/MessagingToolNewEmailWarningModal"
	)
);

const MesssagingToolNewEmailWarnings = ({
	showSubjectWarning,
	setShowSubjectWarning,
	isLoading,
	sendEmail
}) => {
	const {
		clearErrors,
		formState: { errors }
	} = useFormContext();

	const onClose = () => {
		clearErrors();
	};

	return (
		<>
			{errors[TO] && (
				<MessagingToolNewEmailWarningModal
					description={get(errors, `${TO}.message`, "")}
					headerTitle="No Recipient!"
					onClose={onClose}
				>
					<Button
						className={cx(styles.confirmButtonClassName)}
						text="OK"
						icon={<CheckIcon />}
						onClick={onClose}
						type="button"
					/>
				</MessagingToolNewEmailWarningModal>
			)}
			{get(errors, `${TO}[0]`) && (
				<MessagingToolNewEmailWarningModal
					description={get(errors, `${TO}[0].message`)}
					headerTitle="Invalid Email"
					onClose={onClose}
				>
					<Button
						className={cx(styles.confirmButtonClassName)}
						text="OK"
						icon={<CheckIcon />}
						onClick={onClose}
						type="button"
					/>
				</MessagingToolNewEmailWarningModal>
			)}
			{showSubjectWarning && (
				<MessagingToolNewEmailWarningModal
					description={
						"Send this message without a subject or text in the body?"
					}
					headerTitle="No Subject!"
					onClose={() => setShowSubjectWarning(false)}
					showIcon={false}
					isLoading={isLoading}
				>
					<Button
						text="Cancel"
						variant="outlined"
						onClick={() => setShowSubjectWarning(false)}
						className={styles.cancelButton}
						type="button"
					/>
					<Button
						text="Send"
						icon={<SendIcon className={styles.sendIcon} />}
						onClick={() => sendEmail?.()}
					/>
				</MessagingToolNewEmailWarningModal>
			)}
			{errors.content_placeholders && (
				<MessagingToolNewEmailWarningModal
					description={"Placeholders cannot be empty!"}
					headerTitle="Empty placeholders"
					onClose={onClose}
				>
					<Button
						className={cx(styles.confirmButtonClassName)}
						text="Ok"
						icon={<CheckIcon />}
						onClick={onClose}
					/>
				</MessagingToolNewEmailWarningModal>
			)}
		</>
	);
};

export default MesssagingToolNewEmailWarnings;
