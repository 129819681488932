import React from "react";
import classnames from "./status.module.scss";
import { ReactComponent as DeleteOptionIcon } from "static/icons/delete-option-icon.svg";
import {
	HISTORY_TYPE,
	HISTORY_TYPE_LABEL,
	hexToRgbA
} from "modules/candidate/components/profileClientView/profileModalTab/components/interactions/constants.js";

const StatusLabel = ({ isDelete = false, deleteHandler, status }) => {
	const renderHistoryType = status => (
		<div
			className={classnames.statusContainer}
			style={{
				backgroundColor: hexToRgbA(HISTORY_TYPE[status], 0.14),
				color: HISTORY_TYPE[status]
			}}
		>
			<span className={classnames.statusLabel}>
				{HISTORY_TYPE_LABEL[status]}
			</span>
			{isDelete && (
				<button onClick={deleteHandler}>
					<DeleteOptionIcon className={classnames.icon} />
				</button>
			)}
		</div>
	);

	return <>{renderHistoryType(status)}</>;
};

export default StatusLabel;
