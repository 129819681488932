import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./email-template-form.module.scss";
import { ReactComponent as EditIcon } from "static/icons/email-template/edit-2.svg";
import { ReactComponent as UserIcon } from "static/icons/email-template/user.svg";
import { ReactComponent as DoubleChevronIcon } from "static/icons/email-template/double-chevron-right.svg";
import { ReactComponent as PlanetIcon } from "static/icons/email-template/planet.svg";
import { ReactComponent as LockIcon } from "static/icons/email-template/lock.svg";
import { ReactComponent as SettingsIcon } from "static/icons/email-template/settings.svg";
import { ReactComponent as SaveIcon } from "static/icons/email-template/save.svg";
import { ReactComponent as CloseIcon } from "static/icons/email-template/close.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-2.svg";
import EmailTemplateLanguagesTab from "../EmailTemplateLanguagesTab";
import Editor from "common/Editor";
import { NEW_TEMPLATE_SEGMENT_ID } from "modules/EmailTemplate/utils/constants";
import useEmailTemplateForm from "modules/EmailTemplate/hooks/useEmailTemplateForm";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import FieldError from "../FieldError/FieldError";
import useSaveEmailTemplate from "modules/EmailTemplate/api/useSaveEmailTemplate";
import toaster from "common/Toaster";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import { browserHistory } from "react-router";
import { getEmailTemplateRoute } from "modules/EmailTemplate";
import { editorStateHandler } from "modules/EmailTemplate/utils/helpers";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { createPortal } from "react-dom";
import { useGetEmailTemplateLanguages } from "modules/EmailTemplate/api/useGetEmailTemplateLanguages";
import { GET_EMAIL_TEMPLATES_QUERY_KEY } from "../../api/useGetEmailTemplates";
import { queryCache } from "react-query";
import { useGetEmailTemplatePlaceholders } from "modules/EmailTemplate/api/useGetEmailTemplatePlaceholders";
import ContentVisibilityModal from "common/ContentVisibilityModal";
import { CUSTOM, PRIVATE, PUBLIC } from "common/ContentVisibilityModal/utils";
import EmailTemplateSkeleton from "../EmailTemplateSkeleton";
import EmailTemplateDropdownActions from "../EmailTemplateDropdownActions";
import {
	EMAIL_TEMPLATE_VIEW,
	EmailPlaceholderNode
} from "common/Editor/nodes/EmailPlaceholderNode";
import MessagingToolNewEmailPlaceholders from "common/Editor/plugin/EmailPlaceholderPlugin/components/MessagingToolNewEmailPlaceholders";
import EmailTemplateLangEmptyBlock from "../EmailTemplateLangEmptyBlock";
import loadable from "loadable-components";
const ConfirmationModal = loadable(() => import("common/ConfirmationModal"));
import { Header, Description, Footer } from "common/ConfirmationModal";
import { Button } from "common/Button";
import EmailTemplateCategoryDropdown from "../EmailTemplateCategoryDropdown/EmailTemplateCategoryDropdown";
import { PlaceholderLinkNode } from "common/Editor/nodes/PlaceholderLinkNode";
import { TOUR_EMAIL_TEMPLATES } from "config/onboarding";
import { useEmailTemplatesTour } from "modules/user-onboarding/workflows/emailTemplates";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const visibilityOptions = {
	[PUBLIC]: {
		label: "Everyone",
		icon: <PlanetIcon width={18} height={18} />
	},
	[PRIVATE]: {
		label: "Only me",
		icon: <LockIcon width={18} height={18} />
	},
	[CUSTOM]: {
		label: "Custom",
		icon: <SettingsIcon width={18} height={18} />
	}
};

export default function EmailTemplateForm({ templateId }) {
	const searchParams = new URLSearchParams(window.location.search);

	const isEditMode = templateId !== NEW_TEMPLATE_SEGMENT_ID;
	const [showToolbar, setShowToolbar] = useState(false);
	const [showDiscardWarning, setShowDiscardWarning] = useState(false);
	const [showVisibilityModal, setShowVisibilityModal] = useState(false);
	const [locationBeforeUnload, setLocationBeforeUnload] = useState("");
	const editorRef = useRef();
	const {
		data: templateLanguages,
		isLoading: isLanguagesLoading
	} = useGetEmailTemplateLanguages();
	const {
		data: placeholdersData,
		isLoading: isPlaceholdersLoading
	} = useGetEmailTemplatePlaceholders();
	const { formContext, templateQuery, formKey } = useEmailTemplateForm({
		templateId
	});
	const [mutate, { isLoading }] = useSaveEmailTemplate();
	const placeholdersContainer = useRef(null);
	const [showPlacholdersUi, setShowPlaceholdersUi] = useState(false);
	const versionsField = useFieldArray({
		control: formContext.control,
		name: "versions",
		keyName: "_id"
	});
	const lang =
		searchParams.get("lang") || versionsField?.fields?.[0]?.version || "";

	const onSubmit = data => {
		const generatedId = isEditMode ? templateId : uuid();
		const versions = {};
		data.versions.forEach(field => {
			versions[field.version] = {};
			versions[field.version]._id = uuid();
			versions[field.version].version = field.version;
			versions[field.version].subject = field.subject;
			versions[field.version].content = field.content;
			versions[field.version].placeholders = field.placeholders.map(
				placeholder => placeholder.value
			);
			versions[field.version].language = templateLanguages[field.version];
		});
		const body = {
			...data,
			id: generatedId,
			versions
		};
		mutate(
			{
				isEditMode,
				body
			},
			{
				onSuccess() {
					toaster.success("Email template successfully saved.");
					if (isEditMode) {
						const isDefaultTemplate = !templateQuery.data.user_id; // when template is generated by core
						if (isDefaultTemplate) {
							queryCache
								.invalidateQueries(GET_EMAIL_TEMPLATES_QUERY_KEY)
								.then(([query]) => {
									const newTemplate = query.state?.data?.[0]?.data?.[0];
									if (newTemplate) {
										browserHistory.replace({
											pathname: getEmailTemplateRoute(newTemplate._id),
											query: { lang },
											state: { forceNavigation: true }
										});
									}
								});
						} else {
							queryCache.invalidateQueries(GET_EMAIL_TEMPLATES_QUERY_KEY);
							templateQuery.refetch();
						}
					} else {
						queryCache.invalidateQueries(GET_EMAIL_TEMPLATES_QUERY_KEY);
						browserHistory.replace({
							pathname: getEmailTemplateRoute(generatedId),
							query: { lang },
							state: { forceNavigation: true }
						});
					}
					setShowDiscardWarning(false);
				}
			}
		);
	};

	const renderPlaceholdersPortal = () => {
		if (showPlacholdersUi && placeholdersContainer.current) {
			return createPortal(
				<MessagingToolNewEmailPlaceholders
					containerClassName={styles.placeholderContainerClassName}
					header={
						<div className={styles.palceholderHeader}>
							<span>Placeholders</span>
							<button
								type="button"
								onClick={() => setShowPlaceholdersUi(false)}
							>
								<CloseIcon width={16} height={16} />
							</button>
						</div>
					}
					editor={editorRef?.current}
					isLoading={isPlaceholdersLoading}
					placeholders={filteredPlaceholders?.normalPlaceholders ?? {}}
				/>,
				placeholdersContainer.current
			);
		}
		return null;
	};

	const onChangeLang = lang => {
		browserHistory.replace({
			pathname: getEmailTemplateRoute(
				isEditMode ? templateId : NEW_TEMPLATE_SEGMENT_ID
			),
			query: { lang },
			state: { forceNavigation: true }
		});
	};

	const handleBeforeUnload = e => {
		if (formContext.formState.isDirty) {
			e.returnValue = true;
			e.preventDefault();
		}
	};

	const handleRouteLeave = location => {
		if (location.state?.forceNavigation) {
			return true;
		}
		setLocationBeforeUnload(location);
		if (formContext.formState.isDirty) {
			setShowDiscardWarning(true);
			return false;
		}
	};

	useEffect(() => {
		const removeListener = browserHistory.listenBefore(handleRouteLeave);
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
			removeListener();
		};
	}, []);

	const onDiscard = () => {
		formContext.reset();
		setShowDiscardWarning(false);
		requestAnimationFrame(() => {
			browserHistory.push(locationBeforeUnload.pathname);
		});
	};

	const filteredPlaceholders = useMemo(() => {
		if (!placeholdersData?.placeholders) {
			return null;
		}
		const normalPlaceholders = {};
		const linkPlaceholders = {};
		Object.entries(placeholdersData?.placeholders).forEach(
			([entity, placeholders]) => {
				Object.entries(placeholders).forEach(([key, value]) => {
					if (key === "link") {
						linkPlaceholders[entity] ??= {};
						linkPlaceholders[entity][key] = value;
					} else {
						normalPlaceholders[entity] ??= {};
						normalPlaceholders[entity][key] = value;
					}
				});
			}
		);
		return {
			normalPlaceholders,
			linkPlaceholders
		};
	}, [placeholdersData]);

	const { workflowId } = useOnboardingStore();
	useEmailTemplatesTour({
		setShowPlaceholdersUi
	});

	if (templateQuery.isLoading || isLanguagesLoading || isPlaceholdersLoading) {
		return <EmailTemplateSkeleton />;
	}

	PlaceholderLinkNode.__placeholders = filteredPlaceholders?.linkPlaceholders;
	EmailPlaceholderNode.__placeholders =
		filteredPlaceholders?.normalPlaceholders;
	EmailPlaceholderNode.__view = EMAIL_TEMPLATE_VIEW;

	const date = templateQuery.data?.updated_at ?? templateQuery.data?.created_at;

	return (
		<FormProvider {...formContext} key={formKey}>
			<form
				className={styles.container}
				onSubmit={formContext.handleSubmit(onSubmit)}
			>
				{isEditMode && templateQuery.data && (
					<div className={styles.header}>
						<div className={styles.infos}>
							{date && (
								<div className={styles.info}>
									<div className={styles.icon}>
										<EditIcon width={18} height={18} />
									</div>
									<span>
										Last Edit:{" "}
										{moment(date, "X").format("DD/MM/YYYY [at] hh:mm a")}
									</span>
								</div>
							)}
							{templateQuery.data?.created_by && (
								<div className={styles.info}>
									<span className={styles.by}>By</span>
									<div className={styles.icon}>
										<UserIcon width={18} height={18} />
									</div>
									<span>
										{templateQuery.data?.created_by?.first_name}{" "}
										{templateQuery.data?.created_by?.last_name}
									</span>
								</div>
							)}
						</div>
						<EmailTemplateDropdownActions
							template={templateQuery.data}
							formData={formContext.getValues()}
							lang={lang}
						/>
					</div>
				)}
				<div className={styles.formContainer}>
					<div className={styles.formAndFooter}>
						<div className={styles.form}>
							<div className={styles.titleField}>
								<input
									{...formContext.register("template_name")}
									type="text"
									className={styles.titleInput}
									placeholder="Enter email template title ..."
								/>
								<FieldError
									error={formContext.formState.errors.template_name}
								/>
							</div>
							<Controller
								name="category"
								control={formContext.control}
								render={({ field: { onChange, value } }) => {
									return (
										<EmailTemplateCategoryDropdown
											onChange={onChange}
											value={value}
										/>
									);
								}}
							/>
							<EmailTemplateLanguagesTab
								onChange={onChangeLang}
								value={lang}
								versionsField={versionsField}
								templateLanguages={templateLanguages}
							/>
							{!versionsField.fields.length ? (
								<EmailTemplateLangEmptyBlock />
							) : (
								versionsField.fields.map((field, index) => {
									return lang !== field.version ? null : (
										<React.Fragment key={field.version}>
											<div className={styles.subjectHeader}>
												<div>Subject:</div>
												<div className={styles.subjectInputContainer}>
													<input
														{...formContext.register(
															`versions.${index}.subject`
														)}
														className={styles.subjectEditor}
														placeholder="Enter subject..."
													/>
												</div>
												<button
													data-onboarding-step={`${TOUR_EMAIL_TEMPLATES}-3`}
													type="button"
													onClick={() => setShowPlaceholdersUi(v => !v)}
													className={styles.placeholdersButton}
												>
													<span>
														<span className={styles.bracket}>{"["}</span>
														Placeholders
														<span className={styles.bracket}>{"]"}</span>
													</span>
													<DoubleChevronIcon />
												</button>
											</div>
											<div className={styles.editorWrapper}>
												<Controller
													name={`versions.${index}.content`}
													control={formContext.control}
													render={({ field: { onChange, value } }) => {
														return (
															<Editor
																useMentionPlugin={false}
																showToolbaar={showToolbar}
																setShowToolbar={setShowToolbar}
																onChange={onChange}
																value={value}
																editorStateHandler={editor =>
																	editorStateHandler(editor, value)
																}
																scrollClassName={styles.editorScroll}
																contentEditorClassName={styles.editorContent}
																placeholder="Enter email template content..."
																placeholderClassName={styles.placeholder}
																useEmailPlaceholderPlugin={true}
																onEmailPlaceholdersChange={list => {
																	formContext.setValue(
																		`versions.${index}.placeholders`,
																		list
																	);
																}}
																onPlaceholdersLinkChange={list => {
																	formContext.setValue(
																		`versions.${index}.link_placeholders`,
																		list
																	);
																}}
																editorRef={editorRef}
															/>
														);
													}}
												/>
											</div>
											<div className={styles.contentHelper}>
												<FieldError
													className={styles.contentError}
													error={
														formContext.formState.errors.versions?.[index]
															?.content
													}
												/>
											</div>
										</React.Fragment>
									);
								})
							)}
						</div>
						<div className={styles.footer}>
							<div className={styles.visibility}>
								<div>Visible to</div>
								<button
									type="button"
									onClick={() => setShowVisibilityModal(true)}
									className={styles.visibilityBtn}
								>
									{visibilityOptions[formContext.watch("visibility")].icon}
									<span>
										{visibilityOptions[formContext.watch("visibility")].label}
									</span>
								</button>
							</div>
							<button
								data-onboarding-step={`${TOUR_EMAIL_TEMPLATES}-5`}
								className={styles.saveBtn}
								disabled={
									workflowId
										? false
										: !formContext.formState.isDirty || isLoading
								}
							>
								{isLoading ? (
									<AnimatedLoaderIcon width={24} />
								) : (
									<>
										<SaveIcon />
										<span>Save</span>
									</>
								)}
							</button>
						</div>
					</div>
					<div ref={placeholdersContainer} />
				</div>
			</form>
			{renderPlaceholdersPortal()}
			{showVisibilityModal && (
				<ContentVisibilityModal
					title="Who can see this email?"
					setShowModal={setShowVisibilityModal}
					setVisibility={value => {
						formContext.setValue("visibility", value, { shouldDirty: true });
					}}
					setCustomPrivacy={({ visible, not_visible }) => {
						formContext.setValue("visible", visible, { shouldDirty: true });
						formContext.setValue("not_visible", not_visible, {
							shouldDirty: true
						});
					}}
					visibilityData={{
						visibility: formContext.watch("visibility"),
						visible: formContext.watch("visible"),
						not_visible: formContext.watch("not_visible")
					}}
				/>
			)}
			{!!showDiscardWarning && (
				<ConfirmationModal
					width="600px"
					onClose={() => setShowDiscardWarning(null)}
					isLoading={isLoading}
				>
					<Header title="Unsaved Changes" />
					<Description>
						<div>
							You have unsaved changes. Are you sure you want to leave this page
							without saving?
						</div>
					</Description>
					<Footer className={styles.modalFooter}>
						<Button
							text="Cancel"
							className={styles.cancelButton}
							onClick={() => setShowDiscardWarning(false)}
						/>
						<Button
							icon={<TrashIcon />}
							text="Discard"
							className={styles.discardButton}
							onClick={onDiscard}
						/>
						<Button
							icon={<SaveIcon />}
							text="Save changes"
							onClick={formContext.handleSubmit(onSubmit)}
						/>
					</Footer>
				</ConfirmationModal>
			)}
		</FormProvider>
	);
}
