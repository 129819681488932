import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass";
import cx from "classnames";
import { queryCache } from "react-query";
import _get from "lodash/get";
import QS from "query-string";
import ProfileAvatar from "./profileAvatar";
import { ReactComponent as AddNote } from "static/icons/element-add-shortlist.svg";
import { ReactComponent as OptionsIcon } from "static/icons/icon-options.svg";
import { ReactComponent as AddShortlist } from "static/icons/element-add-note.svg";
import { ReactComponent as AddShortListActive } from "static/icons/elem-add-note-active.svg";
import { ReactComponent as PreviousIcon } from "static/icons/element-arrow-left.svg";
import { ReactComponent as ResidenceIcon } from "static/icons/elements-icons-house.svg";
import { ReactComponent as NextIcon } from "static/icons/element-arrow-right.svg";
import { ReactComponent as InfoIcon } from "static/icons/elements-icons-infos.svg";
import { ReactComponent as AddTaskIcon } from "static/icons/btnAddTask.svg";
import ViewCandidateHelper from "modules/candidate/components/profileClientView/HOC/navigationHelper";
import AnoProfile from "./anoProfile";
import DefaultButton from "common/Buttons";
import { dateCalendar } from "common/Functions";
import DropDown from "./DropDown";
import ProfileActions from "./cta";
import Tooltip from "rc-tooltip";
import SendVacancyButton from "./SendVacancyButton";
import useSendVacancy from "modules/vacancy/components/candidatePool/SendVacancy/useSendVacancy";
import usePoolStore from "modules/vacancy/components/candidatePool/zustandPool";
import useShortList from "modules/vacancy/components/candidatePool/ShortListDrawer/useShortList";
import { CANDIDATE } from "config";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

const Header = ({
	headline,
	isAnonymous,
	reference,
	canPoke,
	canShortlist,
	addNoteHandler,
	previousProfile,
	nextProfile,
	preference,
	totalViews,
	lastViewedBy,
	isNextDisabled,
	isPreviousDisabled,
	usersViewsData,
	canEdit,
	setIsAddMailToPoke,
	isAddMailToPoke,
	userId,
	createdBy,
	refetchProfileData,
	canResendActivationEmail,
	canDelete,
	nextProfileID,
	goToProfile,
	profileId
}) => {
	const [displayCtas, setDisplayCtas] = useState(false);
	const {
		setIsOpen: openDrawerTask,
		setEntity,
		setSelectedEntityOption
	} = useAddNewTaskStore();
	const { setIsOpen } = useSendVacancy();
	const { setSelectData, setView, setProfileId } = usePoolStore();
	const { setIsOpen: openDrawerShortList } = useShortList();
	const qsVacancyId = QS.parse(window.location.search);

	const idVacancy = _get(qsVacancyId, "history")?.split("=")[1];

	const fullName = `${headline.first_name} ${headline.last_name}`;

	let options = usersViewsData.map(userDiewData => ({
		viewedAt: userDiewData.viewed_date,
		jobTitle: userDiewData.user_info.user_function,
		avatar:
			userDiewData.user_info._id === userId
				? false
				: userDiewData.user_info.avatar,
		fullName:
			userDiewData.user_info._id === userId
				? _get(lastViewedBy, "user_info._id", "") === userId
					? "Last seen by you"
					: "You"
				: userDiewData.user_info.full_name,
		isAvatar: userDiewData.user_info._id !== userId
	}));
	const openTaskDrawer = () => {
		openDrawerTask(true);
		setEntity(CANDIDATE);
		setSelectedEntityOption({
			label: `${headline.first_name} ${headline.last_name}`,
			value: profileId
		});
	};

	const renderResidence = (location = []) => {
		const residency = location.find(l => l.is_main);
		return (
			<>
				{_get(residency, "city") || _get(residency, "country") ? (
					<>{`${_get(residency, "country")}${
						_get(residency, "city") ? `, ${_get(residency, "city")}` : ""
					}`}</>
				) : (
					"N/A"
				)}
			</>
		);
	};

	const Overlay = (
		<Text
			as={"span"}
			sx={{
				fontSize: "14px",

				fontWeight: "normal",

				lineHeight: "1.14",

				color: "#dfe3e8"
			}}
		>
			Profile created by:{" "}
			<Text
				as={"span"}
				sx={{
					fontSize: "14px",

					fontWeight: "normal",

					lineHeight: "1.14",

					color: "#2263f1"
				}}
			>
				{createdBy !== null
					? createdBy._id === userId
						? "You"
						: `${createdBy.first_name || ""} ${createdBy.last_name || ""}`
					: null}
			</Text>
		</Text>
	);

	return (
		<Box
			sx={{
				borderRadius: 6,
				boxShadow: "0 0 6px 4px rgba(0, 0, 0, 0.04)",
				padding: "24px",
				backgroundColor: "#ffffff",
				marginBottom: "24px"
			}}
		>
			<Flex
				alignItems="center"
				alignContent="center"
				justifyContent="space-between"
			>
				<Flex width="46%">
					<Box mb={2} alignSelf="flex-start">
						{!isAnonymous ? (
							<ProfileAvatar
								src={headline.avatar}
								name={fullName}
								size={80}
								fontSize={24}
								backgroundColor="#818a92"
								status={_get(headline, "candidate_status", false)}
							/>
						) : (
							<AnoProfile />
						)}
					</Box>

					<Box alignSelf="center">
						<Flex mb="2px" alignItems="center">
							<Text
								mr={2}
								as={"h2"}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "24px",
									fontWeight: 600,
									color: "#212b36",
									marginLeft: "12px",
									lineHeight: 1.17
								}}
							>
								{isAnonymous ? `REF : ${reference}` : fullName}
							</Text>
							{createdBy !== null && createdBy.first_name && (
								<Tooltip
									placement="bottom"
									overlay={Overlay}
									trigger="hover"
									overlayClassName="function-tooltip"
								>
									<InfoIcon />
								</Tooltip>
							)}
						</Flex>

						{_get(headline, "main_function.name", "") && (
							<Text
								mb="2px"
								ml={12}
								as={"h3"}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "18px",
									color: "#212b36",
									fontWeight: "normal",
									lineHeight: 1.17
								}}
							>
								{_get(headline, "main_function.name")}
							</Text>
						)}

						{_get(preference, "preferred_location", []).length > 0 && (
							<Flex alignItems="flex-end" ml={10}>
								<ResidenceIcon />

								<Text
									sx={{
										fontSize: "16px",
										color: "#818a92",
										marginLeft: "8px",
										fontWeight: "normal",
										lineHeight: 1.17
									}}
								>
									{renderResidence(_get(preference, "preferred_location"))}
								</Text>
							</Flex>
						)}
					</Box>
				</Flex>
				<Flex width="44%" alignItems="center">
					<Tooltip
						placement="bottom"
						overlay="Send vacancy"
						trigger="hover"
						overlayClassName={cx("function-tooltip", {
							["hide"]: !canPoke
						})}
					>
						<div>
							<SendVacancyButton
								toggleSendVacancyModal={() => {
									setSelectData({
										mode: "select",
										selected_candidates: [profileId],
										preview_mode: {
											first_name: headline.first_name,
											last_name: headline.last_name
										},
										total_item: 1
									});
									setView("candidate");
									setProfileId(profileId);
									setIsOpen(true);
								}}
								canEdit={canEdit}
								canPoke={canPoke}
								setIsAddMailToPoke={setIsAddMailToPoke}
								isAddMailToPoke={isAddMailToPoke}
							/>
						</div>
					</Tooltip>
					<Tooltip
						placement="bottom"
						overlay="Add to vacancy"
						trigger="hover"
						overlayClassName="function-tooltip"
					>
						<Button
							mx={2}
							onClick={() => {
								setSelectData({
									mode: "select",
									selected_candidates_for_ShortList: [profileId],
									total_item: 1
								});
								openDrawerShortList(true);
								setProfileId(profileId);
								queryCache.invalidateQueries("getVacanciesShortList");
							}}
							disabled={!canShortlist}
							sx={{
								background: "transparent",
								border: "none",
								height: "40px",
								padding: "0px",
								"&:disabled": {
									cursor: "not-allowed"
								}
							}}
						>
							{idVacancy ? <AddShortListActive /> : <AddShortlist />}
						</Button>
					</Tooltip>
					<Tooltip
						placement="bottom"
						overlay="Add task"
						trigger="hover"
						overlayClassName="function-tooltip"
					>
						<Button
							mr={2}
							onClick={openTaskDrawer}
							sx={{
								background: "transparent",
								border: "none",
								height: "40px",
								padding: "0px"
							}}
						>
							<AddTaskIcon />
						</Button>
					</Tooltip>
					<Tooltip
						placement="bottom"
						overlay="Add note"
						trigger="hover"
						overlayClassName="function-tooltip"
					>
						<Button
							mr={2}
							onClick={addNoteHandler}
							sx={{
								background: "transparent",
								border: "none",
								height: "40px",
								padding: "0px"
							}}
						>
							<AddNote />
						</Button>
					</Tooltip>
					<Box sx={{ position: "relative", height: "40px" }}>
						<Button
							onClick={() => setDisplayCtas(true)}
							sx={{
								background: "transparent",
								border: "none",
								position: "relative",
								height: "40px",
								padding: "0px"
							}}
						>
							<OptionsIcon />
						</Button>

						<ProfileActions
							canResendActivationEmail={canResendActivationEmail}
							refetchProfileData={refetchProfileData}
							canDelete={canDelete}
							nextProfile={nextProfileID}
							goToProfile={goToProfile}
							displayCtas={displayCtas}
							closeCtas={() => setDisplayCtas(false)}
						/>
					</Box>
					<Box
						marginLeft={3}
						marginRight={3}
						sx={{ borderRight: "2px solid #dfe3e8", height: "40px" }}
					></Box>
					<Box>
						{totalViews > 0 ? (
							<DropDown options={options}>
								{" "}
								<Text
									sx={{
										fontSize: "16px",
										color: "#018aee",
										fontWeight: "normal"
									}}
								>
									Viewers ({totalViews})
								</Text>
							</DropDown>
						) : (
							<Text
								sx={{
									fontSize: "16px",
									color: "#018aee",
									fontWeight: "normal"
								}}
							>
								Viewers (0)
							</Text>
						)}

						<Flex alignItems="center" justifyContent="center">
							{_get(lastViewedBy, "user_info.first_name", "") && (
								<React.Fragment>
									{" "}
									<DropDown options={options}>
										<Text
											sx={{
												fontSize: "12px",
												fontWeight: 500,
												color: "#818a92",
												textDecoration: "underline"
											}}
										>
											{_get(lastViewedBy, "user_info.first_name", "")}{" "}
											{_get(lastViewedBy, "user_info.last_name", "").slice(
												0,
												1
											)}
										</Text>
									</DropDown>
									<Box
										alignSelf="center"
										mx={2}
										width={4}
										height={4}
										sx={{ backgroundColor: "#2b3946", borderRadius: "50%" }}
									></Box>
									<Text
										sx={{
											fontSize: "12px",
											fontWeight: 400,
											color: "#818a92"
										}}
									>
										{dateCalendar(_get(lastViewedBy, "viewed_date", null))}
									</Text>
								</React.Fragment>
							)}
						</Flex>
					</Box>
				</Flex>

				<Flex justifyContent="flex-end" width="10%">
					<DefaultButton
						padding="0px 8px"
						isDisabled={isPreviousDisabled}
						onClick={previousProfile}
						backgroundColor="white"
					>
						<PreviousIcon />
					</DefaultButton>
					<DefaultButton
						padding="0px 8px"
						isDisabled={isNextDisabled}
						onClick={nextProfile}
						backgroundColor="white"
					>
						<NextIcon />
					</DefaultButton>
				</Flex>
			</Flex>
		</Box>
	);
};
// eslint-disable-next-line
export default props => (
	<ViewCandidateHelper
		render={({ ...otherProps }) => <Header {...props} {...otherProps} />}
	/>
);
