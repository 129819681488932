import React from "react";
import Portal from "common/Portal";
import AddNoteModal from "modules/candidate/components/Modals/AddNoteModal";

const NoteModal = ({ profileId, nextAction, displayAddNoteModal, onClose }) => {
	return (
		displayAddNoteModal && (
			<Portal>
				<AddNoteModal
					profile_id={profileId}
					nextAction={nextAction}
					onClose={onClose}
				/>
			</Portal>
		)
	);
};

export default NoteModal;
