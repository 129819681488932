import styled from "styled-components";
import { colors } from "config/styles";

export const Container = styled.div`
	.section {
		border: solid 0.5px ${colors.grayBorder} !important;
		box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
		border-radius: 4px;
		min-height: unset !important;
		height: auto !important ;
		padding: 15px !important;

		.btn {
			margin-bottom: unset;
		}
		.btn:not(:last-of-type) {
			margin-bottom: 10px;
		}
		.btn-outline-new {
			margin-left: unset !important;
		}
	}
`;
