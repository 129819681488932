/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { useMutation } from "react-query";
import { client } from "lib/api-client";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { formatDate } from "common/Functions";
import {
	DATE_FORMAT,
	APPROVED,
	IN_MEDIATION,
	IN_MEDIATION_LABEL,
	DISPUTED,
	ACCEPTED,
	IN_REVIEW,
	TIMESHEET_FREQUENCY_WEEKLY
} from "config";
import {
	StatusLabel,
	TimesheetTableDetails,
	Th,
	Tr,
	Td,
	FileIcon
} from "./TimesheetStyled";
import ExtendedTimesheetIcon from "./Icons/ExtendedTimesheetIcon";
import GlobalTooltip from "common/GlobalTooltip";
import { colors } from "config/styles";
import { formatTimesheetPeriod, getHours } from "config/helpers";
import Loader from "common/Loader";
import TimesheetActionFiles from "./TimesheetActionFiles";
import { EXPORT_TIMESHEET_DATA } from "config/api-endpoints";

const generateFileAction = data => {
	return client(EXPORT_TIMESHEET_DATA, { body: data });
};

const RowTableTimesheet = ({
	timesheet,
	viewDetails,
	view,
	isConsultancy,
	timesheetPeriod
}) => {
	const [loadPdf, setLoadPdf] = useState(false);
	const [loadXls, setLoadXls] = useState(false);
	const [mutate] = useMutation(generateFileAction);

	useEffect(() => {
		setLoadXls(false);
	}, [timesheet.excel_link_download]);
	useEffect(() => {
		setLoadPdf(false);
	}, [timesheet.pdf_link_download]);

	const generateFile = async type => {
		try {
			if (type === "pdf") {
				setLoadPdf(true);
			} else {
				setLoadXls(true);
			}
			await mutate({ id: timesheet._id, tag: type });
		} catch (error) {}
	};

	const defineTimesheetStatus = (month, status) => {
		const list = [...month.worktimes, ...month.expenses];
		let definedStatus = status;
		const mappedListStatus = list.map(item => item.status);
		if (
			status !== APPROVED &&
			(mappedListStatus.includes(DISPUTED) ||
				mappedListStatus.includes(ACCEPTED) ||
				status === DISPUTED)
		)
			definedStatus = IN_REVIEW;
		return definedStatus;
	};
	const timesheet_frequency =
		timesheet?.reporting_settings?.timesheet_frequency ===
		TIMESHEET_FREQUENCY_WEEKLY
			? "week"
			: "month";
	return (
		<Tr key={timesheet._id}>
			{view === APPROVED && (
				<Td test onClick={() => viewDetails(timesheet)}>
					{formatDate(timesheet.approval_date, DATE_FORMAT)}
				</Td>
			)}
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				{get(timesheet, "job.title")}
			</Td>
			{isConsultancy && (
				<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
					{get(timesheet, "user.first_name")} {get(timesheet, "user.last_name")}
				</Td>
			)}
			{isConsultancy && (
				<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
					{get(timesheet, "user_consultancy.first_name")}{" "}
					{get(timesheet, "user_consultancy.last_name")}
				</Td>
			)}
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				{get(timesheet, "client.company_name")}
			</Td>
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				{timesheetPeriod}{" "}
				{timesheet.extended && (
					<GlobalTooltip
						trigger={["hover"]}
						overlay={`This timesheet has been generated
									because your mission was extended
									during ${timesheetPeriod}. It allows you to report
									your work for the rest of that ${timesheet_frequency}.`}
						align={{
							points: ["bc", "tc"],
							offset: [0, 0]
						}}
					>
						<div>
							<ExtendedTimesheetIcon
								fill={colors.icon}
								hoverFill={colors.primary}
								width={20}
							/>
						</div>
					</GlobalTooltip>
				)}
			</Td>
			{view === APPROVED && (
				<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
					{isConsultancy ? (
						<>
							{getHours(timesheet.total_worktimes_hours)}{" "}
							<span style={{ color: "#ff6849", fontSize: 15 }}>&amp;</span>{" "}
							<CurrencyFormatterRender
								isCent
								value={timesheet.total_expenses}
								currency={timesheet?.reporting_settings?.currency?.code}
							/>
						</>
					) : (
						<CurrencyFormatterRender
							isCent
							value={timesheet.total_amount_freelancer}
							currency={timesheet?.reporting_settings?.currency?.code}
						/>
					)}
				</Td>
			)}
			<Td onClick={() => viewDetails(timesheet)} padding={"12px 18px"}>
				<StatusLabel
					block
					status={defineTimesheetStatus(timesheet.month, timesheet.status)}
				>
					{timesheet.status === IN_MEDIATION
						? IN_MEDIATION_LABEL
						: defineTimesheetStatus(timesheet.month, timesheet.status)}
				</StatusLabel>
			</Td>
			{view === APPROVED && (
				<>
					<Td padding={"12px 18px"}>
						{loadPdf ? (
							<Loader classes={"small"}></Loader>
						) : (
							<a href={timesheet.pdf_link_download}>
								<FileIcon className="icon-pdf downloadIcones">
									<span className="path1"></span>
									<span className="path2"></span>
									<span className="path3"></span>
									<span className="path4"></span>
									<span className="path5"></span>
									<span className="path6"></span>
									<span className="path7"></span>
									<span className="path8"></span>
								</FileIcon>
							</a>
						)}

						{loadXls ? (
							<Loader classes={"small"}></Loader>
						) : (
							<a href={timesheet.excel_link_download}>
								<FileIcon className="icon-xls downloadIcones">
									<span className="path1"></span>
									<span className="path2"></span>
									<span className="path3"></span>
									<span className="path4"></span>
									<span className="path5"></span>
									<span className="path6"></span>
									<span className="path7"></span>
								</FileIcon>
							</a>
						)}
					</Td>
					<TimesheetActionFiles generateFile={generateFile}>
						<Td style={{ padding: "6px" }} onClick={e => e.stopPropagation()}>
							<GlobalTooltip
								trigger={["hover"]}
								overlay={"Generate a Pdf/xls"}
								align={{
									points: ["bc", "tc"],
									offset: [0, 0]
								}}
							>
								<span
									style={{ fontSize: "20px", cursor: "pointer" }}
									className="icon-retakepic refreshIcone"
								></span>
							</GlobalTooltip>
						</Td>
					</TimesheetActionFiles>
				</>
			)}
		</Tr>
	);
};

export default ({ timesheets, view, viewDetails, isConsultancy }) => (
	<TimesheetTableDetails spacing="0 3px">
		<thead>
			<tr>
				{view === APPROVED && <Th width={"15%"}>Approval date</Th>}
				<Th width={"20%"}>Mission title</Th>
				{isConsultancy && <Th width={"10%"}>CONSULTANT</Th>}
				{isConsultancy && <Th width={"10%"}>BUSINESS UNIT</Th>}
				<Th width={"10%"}>Company</Th>
				<Th width={"10%"}>Period</Th>
				{view === APPROVED && (
					<Th width={"10%"}>
						{isConsultancy ? "Timesheet total" : "Total amount"}
					</Th>
				)}
				<Th width={"12%"} align={"center"}>
					Status
				</Th>
				{view === APPROVED && (
					<>
						<Th width={"10%"} align={"center"}>
							Files
						</Th>
						<Th width={"4%"}></Th>
					</>
				)}
			</tr>
		</thead>
		<tbody>
			{timesheets.map((timesheet, index) => {
				const period = get(timesheet, "period", null);
				const frequency = get(
					timesheet,
					"reporting_settings.timesheet_frequency",
					null
				);
				const timesheetPeriod = formatTimesheetPeriod(frequency, period);

				return (
					<RowTableTimesheet
						key={index}
						timesheet={timesheet}
						viewDetails={viewDetails}
						timesheetPeriod={timesheetPeriod}
						view={view}
						isConsultancy={isConsultancy}
					/>
				);
			})}
		</tbody>
	</TimesheetTableDetails>
);
