import React from "react";
import Modal from "common/modal";
import getStartCover from "static/images/getStartCover.jpg";

import style from "./get-started-dialog.module.scss";

const GetStartedDialog = ({ onClose, onGetStarted }) => {
	return (
		<Modal
			size={"724px"}
			padding={"0px"}
			borderRadius={"24px"}
			border={"2px solid #E2E8F0"}
			buttonCloseClassName={"hide"}
			style={{
				background: "#FFF",
				boxShadow: "0px -2px 154px 0px rgba(0, 0, 0, 0.09)",
				overflow: "hidden"
			}}
			backgroundColor={"#F7F8FA"}
			zIndexOverlay={"9999999"}
		>
			<div className={style.imageCover}>
				<img alt="Welcome to Wiggli" src={getStartCover} />
			</div>
			<div className={style.dialogContainer}>
				<h2>Welcome to Wiggli</h2>
				<h3>the smarter way to hire top talents! </h3>
				<p>
					Before you get started, we recommend taking our onboarding experience
					to set-up your account & discover how wiggli can simplify your hiring
					process and hire top talent!
				</p>
				<div className={style.ctaBlock}>
					<button
						className={style.getStartedBtn}
						onClick={() => onGetStarted()}
					>
						Get started{" "}
					</button>
					<button className={style.skipBtn} onClick={() => onClose()}>
						skip{" "}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default GetStartedDialog;
