import React from "react";
import ConfirmationModal, {
	Header,
	Description,
	Footer
} from "common/ConfirmationModal";
import { Button } from "common/Button";
import { ReactComponent as CheckIcon } from "static/icons/checkIcon2.svg";
import styles from "./vacancy-view-missing-placeholder-warning.module.scss";

const VacancyViewMissingPlaceholderWarning = ({
	onClose,
	placeholderLabel,
	isLoading,
	showIcon
}) => {
	return (
		<ConfirmationModal width="567px" onClose={onClose} isLoading={isLoading}>
			<Header title="Placeholder missing!" />
			<Description showIcon={showIcon}>
				<div className={styles.description}>
					Please include a placeholder <span className={styles.bracket}>[</span>
					{placeholderLabel}
					<span className={styles.bracket}>]</span> before sending the message.
				</div>
			</Description>
			<Footer onClose={onClose}>
				<Button text="Ok" icon={<CheckIcon />} onClick={onClose} />
			</Footer>
		</ConfirmationModal>
	);
};

export default VacancyViewMissingPlaceholderWarning;
