import React from "react";
import styles from "./request-details-tab.module.scss";
import RequestInformationsSidebar from "../jobView/details/RequestInformationsSidebar";
import RequestViewMain from "../jobView/details/RequestViewMain";

const RequestDetailsTab = ({ job }) => {
	return job.id ? (
		<div className={styles.container}>
			<div className={styles.main}>
				<RequestViewMain job={job} />
			</div>

			<div className={styles.sidebar}>
				<RequestInformationsSidebar job={job} />
			</div>
		</div>
	) : null;
};

export default RequestDetailsTab;
