import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { queryCache } from "react-query";
import { useToggle } from "react-use";
import EditableBox from "../EditableBox";
import BillingDetailsData from "./BillingDetailsData";
import BillingDetailsForm from "./BillingDetailsForm";
import useUpdateCompanyAdditionalInfo from "../../../api/useUpdateCompanyAdditionalInfo";
import toaster from "common/Toaster";
import { getCurrentCompanyQueryKey } from "../../../api/useGetCompany";

const BillingDetails = ({ data }) => {
	const [on, toggle] = useToggle(false);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();
	const [update, { isLoading }] = useUpdateCompanyAdditionalInfo({
		onSuccess() {
			toaster.success("Billing details updated.");
			queryCache.invalidateQueries([getCurrentCompanyQueryKey]);
			toggle(false);
		},
		onError() {}
	});
	useEffect(() => {
		reset({
			vat_number: data?.vat,
			bic: data?.bic,
			iban: data?.iban
		});
	}, [data]);

	return (
		<EditableBox
			onSave={handleSubmit(update)}
			toggle={toggle}
			isEdit={on}
			isLoading={isLoading}
			displayValue={<BillingDetailsData data={data} />}
		>
			<BillingDetailsForm register={register} errors={errors} />
		</EditableBox>
	);
};

export default BillingDetails;
