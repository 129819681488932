import React, { useRef, useState } from "react";
import Sidebar from "../Sidebar";
import JobHeader from "../JobHeader";
import Navigation from "../Navigation";
import SimilarJobs from "../SimilarJobs";
import { LoaderFull } from "common/Loader";
import JobDescription from "../JobDescription";
import {
	applyToJob,
	useJobDetails,
	trackDoNotShowAgain
} from "../../api/useJobDetails";
import { useMutation } from "react-query";
import styles from "./permanent-job-details.module.scss";

import _get from "lodash/get";
import toaster from "common/Toaster";
import JobActions from "../JobActions";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfile } from "modules/user/actions/authActions.js";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import ReferralDRawerForm from "../../../../candidate/components/referral/ReferralDRawerForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import loadable from "loadable-components";
import DoNotShowAgain from "modules/permanent/jobDetailsPage/components/DoNotShowAgain";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import useApplyVacancyQuestionnaire from "../../hooks/useApplyVacancyQuestionnaire";
import {
	getQuestionnaireData,
	getQuestionnaireSchema
} from "common/QuestionnaireForm/utils/helpers";
import * as yup from "yup";

const QuestionnaireFormModal = loadable(() =>
	import(
		"modules/permanent/jobDetailsPage/components/JobDetails/QuestionnaireFormModal/QuestionnaireFormModal"
	)
);

const JobDetails = () => {
	let { pathname, search } = window.location;
	const jobId = pathname.split("/")[pathname.split("/").length - 1];
	const jobLang = (search.includes("job_lang") && search.split("=")[1]) || null;

	// job details query
	const body = { id: jobId, version: jobLang };
	const { data, isLoading, refetch } = useJobDetails(body);

	const methods = useForm({
		shouldUnregister: true,
		resolver: yupResolver(
			yup.object().shape(getQuestionnaireSchema(_get(data, "questionnaire")))
		)
	});

	// state
	const [checked, setChecked] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isReferralFormOpen, setIsReferralFormOpen] = useState(false);

	// store
	const dispatch = useDispatch();
	const candidateStatus = useSelector(
		state => state.auth.user.candidate_status
	);
	const applyWithoutModal = useSelector(
		state => state.auth.user.apply_to_vacancy_modal
	);

	const hasQuestionnaire = data?.questionnaire?.length > 0;

	// mutation
	const [doNotShowAgain] = useMutation(trackDoNotShowAgain);

	const [mutate, { isLoading: applyJobIsLoading }] = useMutation(applyToJob, {
		onSuccess: () => {
			toaster.success("Your application has successfully been sent!");
			setConfirmationModal(false);
			refetch();
			dispatch(getMyProfile());
		},
		onError: e => {
			if (e && e.status === 422) {
				let errorMsg = _get(e, "detail.name");
				if (errorMsg) toaster.danger(errorMsg);
			}
			refetch();
		}
	});

	const [
		mutateQuestionnaire,
		{ isLoading: isLoadingApplyQuestionnaire }
	] = useApplyVacancyQuestionnaire({
		onSuccess: () => {
			toaster.success("Your application has successfully been sent!");
			setConfirmationModal(false);
			refetch();
			dispatch(getMyProfile());
		},
		onError: e => {
			if (e && e.status === 422) {
				let errorMsg = _get(e, "detail.name");
				if (errorMsg) toaster.danger(errorMsg);
			}
			refetch();
		}
	});

	// actions
	const sendJobApplication = () => {
		const body = {
			version: jobLang,
			vacancy_id: jobId
			// todo decrepated ?
			// source_id: _get(vonqData, "source_id"),
			// campaign_id: _get(vonqData, "campaign_id")
		};
		mutate(body); // fire apply job

		// fire doNotShowAgain settings
		if (checked) doNotShowAgain();
	};
	const handleApplyToJob = () => {
		if (hasQuestionnaire) {
			return setConfirmationModal(true);
		} else {
			if (applyWithoutModal) return sendJobApplication();
			setConfirmationModal(true);
		}
	};

	const pokeID = _get(data, "poke_id");
	const canApplyToJob = _get(data, "can_apply");
	const applyJobProps = {
		pokeID,
		canApplyToJob,
		candidateStatus,
		isLoading: applyJobIsLoading,
		displayApplyModal: handleApplyToJob
	};

	const sidebarActionsRef = useRef(null);
	const { isVisible } = useIntersectionObserver(sidebarActionsRef, {
		root: null,
		threshold: 1,
		rootMargin: "-75px"
	});

	const onClickRefer = () => {
		setIsReferralFormOpen(true);
	};

	const onSubmitQuestionnaire = formData => {
		const body = {
			version: jobLang,
			vacancy_id: jobId,
			questionnaire: getQuestionnaireData(formData, _get(data, "questionnaire"))
		};

		mutateQuestionnaire(body);
	};

	return (
		<div className="container">
			{isLoading ? (
				<LoaderFull loading={isLoading} />
			) : (
				<>
					<Navigation jobId={jobId} jobLang={jobLang} />
					<div className={styles.body}>
						{/* JobHeader */}
						<JobHeader data={data}>
							{!isVisible && (
								<div className={styles.headerActions}>
									<JobActions
										onClickRefer={onClickRefer}
										applyJobProps={applyJobProps}
									/>
								</div>
							)}
						</JobHeader>
						{/* JobBody */}
						<div className={styles.columns}>
							{/* JobDescription */}
							<div className={styles.main}>
								<JobDescription data={data} />
							</div>
							{/* Sidebar */}
							<div className={styles.sidebar}>
								<div ref={sidebarActionsRef} className={styles.sidebarActions}>
									<JobActions
										onClickRefer={onClickRefer}
										className={styles.btnFull}
										applyJobProps={applyJobProps}
									/>
								</div>
								<Sidebar data={data} />
							</div>
						</div>
						{/* SimilarJobs */}
						{!!_get(data, "similar_jobs.length") &&
							!!data.similar_jobs.filter(job => job?.description).length && (
								<SimilarJobs
									jobs={data.similar_jobs.filter(job => job.description)}
								/>
							)}
					</div>
				</>
			)}

			<ReferralDRawerForm
				vacancyId={_get(data, "_id")}
				campaignId={_get(data, "current_campaign_id")}
				version={_get(data, "version")}
				isOpen={isReferralFormOpen}
				onClose={() => setIsReferralFormOpen(false)}
				questionnaireData={_get(data, "questionnaire")}
			/>

			{hasQuestionnaire ? (
				<QuestionnaireFormModal
					active={confirmationModal}
					onClose={() => setConfirmationModal(false)}
					questionnaireData={_get(data, "questionnaire")}
					onSubmit={onSubmitQuestionnaire}
					methods={methods}
					isLoadingApplyQuestionnaire={isLoadingApplyQuestionnaire}
				/>
			) : (
				<ConfirmationModal
					doNotShowAgain={true}
					active={confirmationModal}
					loading={applyJobIsLoading}
					modalName="apply-to-vacancy"
					type={ConfirmationTypes.confirmation}
					onClose={() => setConfirmationModal(false)}
					firstButton={{ label: "Yes", action: sendJobApplication }}
					title="Are you sure you would like to apply to this position?"
					content="Once your application is confirmed, your profile will no longer be anonymous to this company."
					doNotShowComponent={() => (
						<DoNotShowAgain checked={checked} setChecked={setChecked} />
					)}
				/>
			)}
		</div>
	);
};

export default JobDetails;
