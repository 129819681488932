import React from "react";
import { DetailContainer, DetailLabel, DetailColor } from "../styled";
import GlobalTooltip from "common/GlobalTooltip";

const ChartDetails = ({ data, colors, permanent }) => {
	let MAX_CHARACTER = 18;

	colors.forEach((color, colorIndex) => {
		data.forEach((element, dataIndex) => {
			if (dataIndex == colorIndex) {
				element.color = color;
			}
		});
	});
	const subStringLabel = label => {
		let labelLength = label.length;
		let sub = label.substring(MAX_CHARACTER, 0);
		return labelLength > MAX_CHARACTER ? `${sub} ...` : label;
	};
	return (
		<DetailContainer permanent={permanent}>
			{data.map((elm, index) => {
				return (
					<div key={index} className="legend-container">
						<DetailColor color={elm.color} permanent={permanent} />
						<GlobalTooltip
							overlay={elm.status}
							placement="top"
							active={elm.status.length > MAX_CHARACTER}
							overlayClassName="g-tool-dark"
						>
							<DetailLabel>{subStringLabel(elm.status)}</DetailLabel>
						</GlobalTooltip>
					</div>
				);
			})}
		</DetailContainer>
	);
};

export default ChartDetails;
