//TODO rename type to a clear name
import { yupResolver } from "@hookform/resolvers/yup";
import { EXPECTED_DURATION, PERMANENT, ONSITE_OPTION } from "config";
import { addressObjectToString, getHTMLTextLength } from "config/helpers";
import { useGetUser } from "hooks/useGetUser";
import { useForm } from "react-hook-form";
import { object, array, string, mixed } from "yup";
import { EDITOR_MAX_LENGTH } from "../utils/constants";

export const useVacancyForm = () => {
	const user = useGetUser();

	const schema = object().shape({
		description: array()
			.min(1, "Please select the language of your job description content.")
			.of(
				object().shape({
					jobTitle: string().required("Value is mandatory"),
					jobDescription: string().test(
						"Required",
						"Job description is invalid",
						function(value) {
							const length = getHTMLTextLength(value);

							if (length > 0 && length <= EDITOR_MAX_LENGTH) {
								return true;
							} else {
								return !!value?.value;
							}
						}
					),
					profileDescription: string().test(
						"Required",
						"Profile description is invalid",
						function(value) {
							const length = getHTMLTextLength(value);

							if (length > 0 && length <= EDITOR_MAX_LENGTH) {
								return true;
							} else {
								return !!value?.value;
							}
						}
					)
				})
			),
		applicantEducationLevel: object().test(
			"Required",
			"Please select an education level",
			function(value) {
				return !!value?.value;
			}
		),
		applicantLocation: mixed().test(
			"Required",
			"Please select applicants location",
			function(value) {
				return value?.[0]?.children?.length > 0;
			}
		),
		duration: mixed()
			.test("Required", "Duration is invalid", function(value) {
				if (this.parent.employmentType === PERMANENT) return true;
				else return !!value;
			})
			.test("Length", "Should not exceed 100", function(value) {
				if (this.parent.employmentType === PERMANENT) return true;
				else if (value > 99 || value < 1) return false;
				else return true;
			}),
		dueDate: object()
			.required("Please select due date")
			.nullable(),
		payrollCountry: object().test(
			"Required",
			"Please select payroll country",
			function(value) {
				return !!value?.value;
			}
		),
		currency: mixed().test("Required", "Please select currency", function(
			value
		) {
			const { salaryMax, salaryMin } = this.parent;
			const isValid = (!salaryMax && !salaryMin) || !!value?.value;

			return isValid;
		}),
		salaryMin: mixed().test("Required", "Min salary is invalid", function(
			salary
		) {
			const { salaryMax } = this.parent;

			let isValid = true;

			if (salaryMax && salary) {
				isValid = salary < salaryMax;
			} else {
				isValid = salaryMax ? false : true;
			}

			return isValid;
		}),
		salaryMax: mixed().test("Required", "Max salary is invalid", function(
			salary
		) {
			const { salaryMin } = this.parent;

			if (salaryMin && !salary) {
				return false;
			} else {
				return true;
			}
		}),
		paymentTime: mixed().test(
			"Required",
			"Please select payement time",
			function(value) {
				const { salaryMax, salaryMin } = this.parent;
				const isValid = (!salaryMax && !salaryMin) || !!value;

				return isValid;
			}
		),
		type: mixed().test("Required", "Please select payment type", function(
			value
		) {
			const { salaryMax, salaryMin } = this.parent;
			const isValid = (!salaryMax && !salaryMin) || !!value;

			return isValid;
		}),
		employmentType: string().required("Please select an employment type"),
		featuredVideo: string()
			.url("FeaturedVideo must be a valid URL")
			.nullable(),
		vacancyAddress: object().test(
			"Required",
			"Please select vacancy address",
			function(value) {
				if (value?.value) {
					return true;
				} else {
					return false;
				}
			}
		),
		openingReason: object()
			.test("Required", "Please select an opening reason", function(value) {
				return !!value?.value;
			})
			.nullable()
	});

	const res = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			description: [],
			extraBenefits: [],
			durationType: EXPECTED_DURATION[0].value,
			vacancyAddress: getAddressDefaultValue(user),
			vacancyAddressType: "company_address",
			remoteEligibility: ONSITE_OPTION.value
		},
		shouldUnregister: false
	});

	return res;
};

const getAddressDefaultValue = data => {
	const value = {
		street: data.street || data.company_street,
		number: data.number || data.company_number,
		box: data.box || data.company_box,
		city: data.city || data.company_city,
		zip: data.zip || data.company_zip,
		country: data.country || data.company_country,
		longitude: data.longitude || data.company_longitude,
		latitude: data.latitude || data.company_latitude,
		iso_country: data.iso_country || data.company_iso_country
	};

	const label = addressObjectToString(value);

	return {
		label,
		value
	};
};
