import React from "react";
import _get from "lodash/get";
import { BoxWrapper, BoxItem, Title, EditInput } from "./styled";
import cx from "classnames";
import useWindowSize from "hooks/useWindowSize";

const fields = [
	{
		label: "street",
		required: true,
		group: "1"
	},
	{
		label: "number",
		required: true,
		group: "2"
	},
	{
		label: "box",
		required: false,
		group: "2"
	},
	{
		label: "city",
		required: true,
		group: "2"
	},
	{
		label: "zip",
		required: true,
		group: "2"
	},
	{
		label: "country",
		required: true,
		group: "3"
	}
];

export default function AddressEdit(props) {
	const handleInputKeyPress = (e, number) => {
		if (number && (e.which < 48 || e.which > 57)) {
			return e.preventDefault();
		}
	};

	const handleInputChange = e => {
		const { value, name } = e.target;
		props.onAddressChange({
			...props.address,
			formatted_address: "",
			[name]: value
		});
	};

	const renderLocationElement = ({
		value,
		label,
		className,
		noError,
		required,
		number
	}) => {
		const hasError = props.error && required && !value && !noError;
		const requiredFields = props.requiredFields || [
			"street",
			"number",
			"city",
			"zip",
			"country"
		];
		return (
			<BoxItem className={className} key={label}>
				<Title className="panel-title">{label}</Title>
				<EditInput
					value={value || ""}
					hasError={hasError && requiredFields.includes(label)}
					placeholder={hasError ? `Please enter a valid ${label}.` : ""}
					name={label}
					onChange={handleInputChange}
					onKeyPress={e => handleInputKeyPress(e, number)}
					autoComplete="off"
				/>
			</BoxItem>
		);
	};

	const groupedFields = (props.fields || fields).reduce((acc, field) => {
		(acc[field.group] = acc[field.group] || []).push(field);
		return acc;
	}, {});

	const isMobile = useWindowSize();

	return (
		<BoxWrapper key="BoxWrapper" className="panel" error={props.error}>
			{Object.keys(groupedFields).map(key => (
				<div
					className={cx("row group", {
						["mobileRow"]: isMobile
					})}
					key={key}
				>
					{groupedFields[key].map(field =>
						renderLocationElement({
							label: field.label,
							className: cx("col-sm-5 element", {
								["mobileAdressLabel"]: isMobile
							}),

							required:
								_get(props, "requiredFields", []).length > 0
									? props.requiredFields.includes(field.label)
									: field.required,
							value: props.address[field.label],
							number: field.number
						})
					)}
				</div>
			))}
		</BoxWrapper>
	);
}
