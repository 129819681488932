import { COMPUTE_TIMESHEET } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

const computeTimesheet = data => client(COMPUTE_TIMESHEET, { body: data });

export const useComputeTimesheet = (options = {}) => {
	return useMutation(computeTimesheet, {
		...options
	});
};
