import React from "react";
import { v4 as uuid } from "uuid";
import NoteDrawer from "modules/candidate/Profile/Pages/Notes/NoteDrawer";
import { CANDIDATE_TABS } from "modules/home/config";
import { stringify } from "query-string";
import { historyPush } from "config/helpers";
import useNotesStore from "modules/notes/notes.store";

const SearchCandidateNotesDrawer = ({
	isDrawerOpen,
	activeNote,
	toggleDrawer,
	candidate
}) => {
	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));

	const handleViewMoreClick = () => {
		const params = {
			tab: CANDIDATE_TABS.NOTES
		};
		const stringified = stringify(params);
		const link = `/permanent/view/${candidate.id}?${stringified}`;

		historyPush(link);
	};

	const handleAddNoteClick = () => {
		addNote({
			id: uuid(),
			canSelectCandidate: true,
			type: "QUICK_NOTE",
			payload: {
				candidate: {
					value: candidate.id,
					label: `${candidate.first_name} ${candidate.last_name}`,
					profile_id: candidate.id
				}
			}
		});
	};

	return (
		<NoteDrawer
			isDrawerOpen={isDrawerOpen}
			activeNote={activeNote}
			toggleDrawer={toggleDrawer}
			onViewMore={handleViewMoreClick}
			candidateData={candidate}
			onAddNoteClick={handleAddNoteClick}
			showPagination={false}
		/>
	);
};
export default SearchCandidateNotesDrawer;
