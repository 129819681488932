import React from "react";
import "./styles.scss";

export default function SettingsRadioBtn({
	name,
	label,
	checked,
	onChange,
	icon = null,
	description = null
}) {
	return (
		<div className="radio-container">
			<label>
				<input onChange={onChange} type="radio" name={name} checked={checked} />
				<div className="custom-radio">
					<div className="description">
						<div className="description-title">
							{icon} {label}
						</div>
						{description && <p>{description}</p>}
					</div>
				</div>
			</label>
		</div>
	);
}
