import React, { useContext, useEffect, useState } from "react";
import _get from "lodash/get";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Box, Flex, Text } from "rebass";
import { Header as StyledHeader } from "../ProfileStyle";
import Avatar from "common/Avatar/index";
import GlobalTooltip from "common/GlobalTooltip";
import { SearchContext, ACTION_TYPES } from "common/newSearch/context";
import { ReactComponent as Mail } from "static/icons/mail-v2.svg";
import { ReactComponent as Phone } from "static/icons/phone-v2.svg";
import { ReactComponent as MainFunction } from "static/icons/mail-function.svg";
import { ReactComponent as Lock } from "static/icons/lock.svg";
import { ReactComponent as AnynomosProfile } from "static/icons/anynomos-profile.svg";
import { ReactComponent as EmptyFunction } from "modules/vacancy/components/candidatePool/icons/missing-function.svg";
import BackButton from "common/Buttons/BackButton";

export default function HeaderComponent({
	headline = {},
	reference,
	isAnonymous
}) {
	const { dispatch } = useContext(SearchContext);
	const [loaderSkeleton, setLoaderSkeleton] = useState(true);
	const fullName = `${headline.first_name} ${headline.last_name}`;

	useEffect(() => {
		return () => {
			if (window.location.pathname !== "/vacancies/search") {
				return dispatch({ type: ACTION_TYPES.CLEAR_FILTER });
			}
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setLoaderSkeleton(false);
		}, 1500);
	}, []);

	return (
		<StyledHeader className="header-client-view">
			<Box className="full-container" mb={20} sx={{ width: "fit-content" }}>
				<BackButton />
			</Box>
			<Flex
				sx={{
					backgroundColor: "rgb(248, 249, 250)",
					padding: "28px 72px 0px 28px"
				}}
				justifyContent="space-between"
			>
				<Flex>
					<Box
						mr={30}
						sx={{
							width: "125px",
							height: "125px",
							padding: "6px",
							borderRadius: "2px",
							boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.11)",
							border: "solid 0.5px rgb(221, 227, 232)",
							backgroundColor: "rgb(255, 255, 255)",
							position: "relative",
							top: "14px"
						}}
					>
						{!isAnonymous ? (
							<Avatar
								src={headline.avatar}
								name={fullName}
								size={110}
								radius={0}
							/>
						) : (
							<Flex sx={{ position: "relative" }}>
								<Flex
									bg="#b8bfc6"
									width="110px"
									height="110px"
									sx={{ borderRadius: "2px" }}
									justifyContent="center"
								>
									<AnynomosProfile />
								</Flex>
								<Flex
									alignItems="center"
									justifyContent="center"
									sx={{
										width: "32px",
										height: "32px",
										boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
										border: "solid 2.5px rgb(255, 255, 255)",
										backgroundColor: "rgb(126, 130, 142)",
										position: "absolute",
										top: "-20px",
										right: "-20px",
										borderRadius: "32px"
									}}
									className="avatar-lock"
								>
									<GlobalTooltip
										placement="top"
										overlay="Candidates’ profiles remain anonymous until they confirm their interest for a vacancy. To send them your vacancy, simply poke the candidate!"
									>
										<Lock />
									</GlobalTooltip>
								</Flex>
							</Flex>
						)}
					</Box>
					<Box mt={22}>
						<Text
							mb={12}
							as={"h2"}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "18px",
								fontWeight: "500",
								color: "#0f1c46"
							}}
						>
							{isAnonymous ? (
								<>
									{reference === undefined ? (
										<SkeletonTheme color="#d8dce2">
											<Skeleton width={100} height={8} />
										</SkeletonTheme>
									) : (
										`REF : ${reference}`
									)}
								</>
							) : (
								<>
									{fullName === undefined ? (
										<SkeletonTheme color="#d8dce2">
											<Skeleton width={100} height={8} />
										</SkeletonTheme>
									) : (
										fullName
									)}
								</>
							)}
						</Text>

						<Flex alignItems="center" mb={12}>
							{_get(headline, "main_function.name") !== undefined ? (
								<MainFunction />
							) : loaderSkeleton ? (
								<SkeletonTheme color="#d8dce2">
									<Skeleton width={20} height={8} />
								</SkeletonTheme>
							) : (
								<EmptyFunction />
							)}
							<Text
								as={"h3"}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "15px",
									color: "#0f1c46",
									marginLeft: "6px"
								}}
							>
								{_get(headline, "main_function.name") === undefined ? (
									loaderSkeleton ? (
										<SkeletonTheme color="#d8dce2">
											<Skeleton width={100} height={8} />
										</SkeletonTheme>
									) : (
										" Main function not added yet"
									)
								) : (
									_get(headline, "main_function.name")
								)}
							</Text>
						</Flex>

						<Flex alignItems="center">
							{headline.candidate_status === undefined ? null : (
								<Text
									as={"span"}
									sx={{
										width: "12px",
										height: "12px",
										border:
											headline.candidate_status === "inactive"
												? "solid 2px #95a1b3"
												: "solid 2px #00ba7d",
										backgroundColor: "rgb(255, 255, 255)",
										marginRight: "5px",
										borderRadius: "12px"
									}}
								></Text>
							)}
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "12px",
									fontWeight: "600",
									letterSpacing: "0.6px",
									color: "rgb(72, 83, 101)",
									textTransform: "uppercase"
								}}
							>
								{headline.candidate_status === undefined ? (
									<SkeletonTheme color="#d8dce2">
										<Skeleton width={100} height={8} />
									</SkeletonTheme>
								) : headline.candidate_status === "inactive" ? (
									"inactive"
								) : (
									"active"
								)}
							</Text>
						</Flex>
					</Box>
				</Flex>
				{!isAnonymous ? (
					<Flex mt={42}>
						<Flex mr={40}>
							<Flex
								mr={20}
								alignItems="center"
								justifyContent="center"
								sx={{
									width: "32px",
									height: "32px",
									backgroundColor: "rgb(238, 239, 242)",
									borderRadius: "32px"
								}}
							>
								<Phone />
							</Flex>
							<Box>
								<Text
									as={"h4"}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "12px",
										fontWeight: "600",
										letterSpacing: "1.08px",
										color: "rgb(117, 123, 134)",
										textTransform: "uppercase"
									}}
								>
									Phone{" "}
								</Text>
								<Text
									as={"span"}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "14px",
										color: "#0f1c46"
									}}
								>
									{headline.phone ? (
										headline.phone
									) : (
										<span className="placeholder" />
									)}
								</Text>
							</Box>
						</Flex>
						<Flex>
							<Flex
								mr={20}
								alignItems="center"
								justifyContent="center"
								sx={{
									width: "32px",
									height: "32px",
									backgroundColor: "rgb(238, 239, 242)",
									borderRadius: "32px"
								}}
							>
								<Mail />
							</Flex>
							<Box>
								<Text
									as={"h4"}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "12px",
										fontWeight: "600",
										letterSpacing: "1.08px",
										color: "rgb(117, 123, 134)",
										textTransform: "uppercase"
									}}
								>
									Email{" "}
								</Text>
								<Text
									as={"span"}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "14px",
										color: "#0f1c46"
									}}
								>
									{headline.email}
								</Text>
							</Box>
						</Flex>
					</Flex>
				) : null}
			</Flex>
		</StyledHeader>
	);
}
