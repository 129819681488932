import { useEffect } from "react";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

export const useSetCreateVacancyBreadcrumb = () => {
	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([{ name: "Permanent" }, { name: "Vacancies" }]);
	}, []);
};
