import React from "react";

import { ReactComponent as AlertIcon } from "static/icons/alertIcon2.svg";
import Modal from "common/modal";
import { ReactComponent as CloseIcon } from "static/icons/closeModal.svg";
import styles from "./delete-department-modal.module.scss";
import AxiosHelper from "config/axios-helper";
import DeleteButton from "modules/freelancers/components/DeleteFreelancersFilterModal/DeleteButton";

import { DELETE_DEPARTMENT_ENDPOINT } from "config/api-endpoints";

const DeleteDepartmentModal = ({ active, onClose, selected, reloadList }) => {
	const deleteDepartment = async () => {
		AxiosHelper.post({
			url: DELETE_DEPARTMENT_ENDPOINT,
			data: {
				id: selected._id
			},
			toastMessage: "Successfully deleted the department",
			isModal: true
		}).then(() => {
			onClose();
			reloadList();
		});
	};

	return (
		<>
			<Modal
				size={"620px"}
				padding={"0px"}
				borderRadius={"12px"}
				buttonCloseClassName={"hide"}
				style={{
					background: "#FFF",
					boxShadow:
						"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
					overflow: "hidden"
				}}
				active={active}
			>
				<div className={styles.modalContainer}>
					<div className={styles.headerModal}>
						<h3>Delete department</h3>
						<button onClick={() => onClose()}>
							<CloseIcon />
						</button>
					</div>
					<div className={styles.contenuModal}>
						<div className={styles.modal__alert}>
							<AlertIcon />
						</div>
						<p className={styles.modal__text}>
							{`Are you sure you would like to delete the department '${selected.name}'?`}
						</p>
					</div>
					<div className={styles.footer}>
						<button className={styles.cancelBtn} onClick={() => onClose()}>
							Cancel
						</button>
						<DeleteButton onClick={() => deleteDepartment()} />
					</div>
				</div>
			</Modal>
		</>
	);
};

export default DeleteDepartmentModal;
