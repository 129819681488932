import { CONTRACT, MISSION, INTERVIEW, NOTE } from "config";

export const DROPPABLE_TYPE_STAGE = "DROPPABLE_TYPE_STAGE";
export const DROPPABLE_TYPE_CARD = "DROPPABLE_TYPE_CARD";

export const STAGE_TYPE_BID = "bid";
export const STAGE_TYPE_INTERNAL_REVIEW = "internal_review";
export const STAGE_TYPE_INTERVIEW = "interview";
export const STAGE_TYPE_CONTRACT = "contract";
export const STAGE_TYPE_MISSION = "mission";

export const TRIGGERS = [NOTE, INTERVIEW, CONTRACT, MISSION];
