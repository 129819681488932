import React from "react";
import styles from "./notes-tab-total-count.module.scss";

const NotesTabTotalCount = ({ selectCount, isLoading, isFetching, total }) => {
	return (
		<p className={styles.results_count}>
			<span>
				{selectCount > 0 && (
					<span className={styles.grayText}>
						<span className={styles.greenText}>
							{selectCount.toLocaleString().replace(",", " ")}
						</span>
						&nbsp;selected of&nbsp;
					</span>
				)}
			</span>
			<span className={styles.results_count}>
				{isLoading || isFetching ? (
					"Loading..."
				) : (
					<span className={styles.results_count}>
						{total === 0 ? (
							"No result found"
						) : (
							<span className={styles.grayText}>
								<span className={styles.greenText}>
									{total?.toLocaleString()?.replace(",", " ")}
								</span>
								&nbsp;
								{total > 1 ? "results" : "result"} in total
							</span>
						)}
					</span>
				)}
			</span>
		</p>
	);
};

export default NotesTabTotalCount;
