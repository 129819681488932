import React, { useEffect, useState } from "react";
import { CoumpoundSelect } from "common/CustomSelect";
import Portal from "common/ListFilter/components/Portal";

const UsersSelect = ({
	isLoading,
	rightPosition,
	includedItems,
	setIncludedItems,
	data,
	setOpen,
	open,
	id,
	selectTitle,
	inputPlaceholder
}) => {
	const [focusedNode, setfocusedNode] = useState(false);

	useEffect(() => {
		id && setfocusedNode(document.getElementById(id));
	}, [id]);

	let nodeRect = { x: 0, y: 0 };
	if (focusedNode) {
		nodeRect = focusedNode.getBoundingClientRect();
	}

	return (
		<Portal id={id} nodeRect={nodeRect}>
			<CoumpoundSelect.Select
				isLoading={isLoading}
				rightPosition={rightPosition}
				includedItems={includedItems}
				setIncludedItems={setIncludedItems}
				data={data}
				setOpen={setOpen}
				open={open}
				inputPlaceholder={inputPlaceholder}
				selectTitle={selectTitle}
				right={-311}
				functionKey="name"
			></CoumpoundSelect.Select>
		</Portal>
	);
};
export default UsersSelect;
