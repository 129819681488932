import {
	PROFILE_STEP,
	VACANCY_STEP
} from "modules/vacancy/create-vacancy/utils/constants";
import React from "react";
import { ReactComponent as CheckInsideCircleIcon } from "static/icons/check-inside-circle.svg";
import { ReactComponent as CheckboxIcon } from "static/icons/check-icon-box.svg";
import { ReactComponent as GreyCircleIcon } from "static/icons/grey-circle.svg";
import styles from "./create-vacancy-stepper.module.scss";
import cx from "classnames";

const CreateVacancyStepper = ({
	actifStep,
	onVacancyClick,
	onBack,
	onQuestionnaireClick
}) => {
	return actifStep === PROFILE_STEP ? (
		<div className={styles.stepsContainer}>
			<button className={cx(styles.button, styles.profileButton)}>
				<CheckboxIcon />
				<div className={styles.profileLabel}>Profile</div>
			</button>
			<div className={cx(styles.line, styles.lineGrey)} />
			<button onClick={onVacancyClick} className={cx(styles.button)}>
				<GreyCircleIcon />
				<div className={cx(styles.vacancyLabel, styles.vacancyLabelGrey)}>
					Vacancy
				</div>
			</button>
			<div className={cx(styles.line, styles.lineGrey)} />

			<button className={cx(styles.button, styles.vacancyButton)}>
				<GreyCircleIcon />

				<div
					className={cx(
						styles.questionnaireLabel,
						styles.questionnaireLabelGrey
					)}
				>
					Questionnaire
				</div>
			</button>
		</div>
	) : actifStep === VACANCY_STEP ? (
		<div className={styles.stepsContainer}>
			<button onClick={onBack} className={styles.button}>
				<CheckInsideCircleIcon />
				<div className={styles.profileLabel}>Profile</div>
			</button>
			<div className={cx(styles.line, styles.lineGreen)} />
			<button className={cx(styles.button, styles.vacancyButton)}>
				<CheckboxIcon />
				<div className={cx(styles.vacancyLabel, styles.vacancyLabelGreen)}>
					Vacancy
				</div>
			</button>
			<div className={cx(styles.line, styles.lineGrey)} />
			<button
				className={cx(styles.button, styles.questionnaireButton)}
				onClick={onQuestionnaireClick}
			>
				<GreyCircleIcon />

				<div
					className={cx(
						styles.questionnaireLabel,
						styles.questionnaireLabelGrey
					)}
				>
					Questionnaire
				</div>
			</button>
		</div>
	) : (
		<div className={styles.stepsContainer}>
			<button onClick={onBack} className={styles.button}>
				<CheckInsideCircleIcon />
				<div className={styles.profileLabel}>Profile</div>
			</button>
			<div className={cx(styles.line, styles.lineGreen)} />
			<button
				className={cx(styles.button, styles.vacancyButton)}
				onClick={onBack}
			>
				<CheckInsideCircleIcon />

				<div className={cx(styles.vacancyLabel, styles.vacancyLabelGreen)}>
					Vacancy
				</div>
			</button>
			<div className={cx(styles.line, styles.lineGreen)} />

			<button className={cx(styles.button, styles.questionnaireButton)}>
				<CheckboxIcon />
				<div
					className={cx(
						styles.questionnaireLabel,
						styles.questionnaireLabelGreen
					)}
				>
					Questionnaire
				</div>
			</button>
		</div>
	);
};

export default CreateVacancyStepper;
