import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { TIMESHEET_LIST } from "config/api-endpoints";
import { DESC } from "config";
import { timesheetToReviewKey } from "modules/home/api/queriesKeys.js";

const TAG = "timesheet_to_review_welcome_page";

const listTimesheetToReview = (key, { start_date, end_date }) =>
	client(TIMESHEET_LIST, {
		body: {
			start_date,
			end_date,
			tag: TAG,
			sort: DESC
		}
	});

const useTimesheetToReview = (options, queryOptions = {}) =>
	useQuery([timesheetToReviewKey, options], listTimesheetToReview, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useTimesheetToReview;
