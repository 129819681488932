import React, { useState } from "react";
import Drawer from "rc-drawer";
import get from "lodash/get";
import cx from "classnames";
import Loader from "common/Loader";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { ReactComponent as AddIcon } from "static/icons/PlusAdd.svg";
import { ReactComponent as SeeAllNote } from "static/images/notes/IconLeft.svg";
import { ReactComponent as User } from "static/images/notes/user.svg";
import { ReactComponent as ArrowLeft } from "static/images/notes/chevron-left.svg";
import { ReactComponent as ArrowRight } from "static/images/notes/IconRight.svg";
import styles from "./note-drawer.module.scss";
import Note from "modules/candidate/Profile/components/NotesCard/Note";
import useNotesStore from "modules/notes/notes.store";
import { v4 as uuid } from "uuid";

const HEAD_HEIGHT = 53;
const FOOTER_HEIGHT = 65;

const NoteDrawer = ({
	isDrawerOpen,
	toggleDrawer,
	nextNote,
	previousNote,
	hasNext,
	hasPrevious,
	activeNote,
	total,
	activeNoteIdx,
	isFetching,
	onViewMore,
	candidateData,
	payload = {},
	isfromPageVacancy = false,
	onAddNoteClick,
	showPagination = true,
	displayFooter = true
}) => {
	const viewAllNote = () => {
		toggleDrawer();
		onViewMore(activeNote.user._id);
	};

	const [isHover, setIsHover] = useState(false);
	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));

	const showPopup = () => {
		toggleDrawer();

		if (onAddNoteClick) {
			onAddNoteClick();
		} else {
			//TODO Move this code to the parent and use just prop onAddNoteClick to avoid unecessary complicated code
			addNote({
				id: uuid(),
				canSelectCandidate: isfromPageVacancy ? false : true,
				type: "QUICK_NOTE",
				payload: isfromPageVacancy
					? payload
					: {
							candidate: {
								value: candidateData?.user_id,
								label: `${candidateData?.personal_profile?.first_name} ${candidateData?.personal_profile?.last_name}`,
								profile_id: candidateData?.user_id
							}
					  }
			});
		}
	};

	return (
		<Drawer
			open={isDrawerOpen}
			width={`852px`}
			height={"100%"}
			placement={"right"}
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={toggleDrawer}
			className={"drawer"}
		>
			<div className={cx(styles.root, { [styles.contentLoading]: isFetching })}>
				{!isFetching ? (
					<>
						<div className={styles.topHeader}>
							<div className={styles.name__conidate}>
								<User />{" "}
								<h2 className={styles.title}>
									{get(activeNote, "user.first_name")}{" "}
									{get(activeNote, "user.last_name")}
								</h2>
							</div>
							<svg
								className={styles.close__drawer}
								onClick={toggleDrawer}
								onMouseEnter={() => setIsHover(true)}
								onMouseLeave={() => setIsHover(false)}
								width="1.75rem"
								height="1.75rem"
								viewBox="0 0 1.75rem 1.75rem"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M17.8542 7.39575L7.64587 17.6041M7.64587 7.39575L17.8542 17.6041"
									stroke={isHover ? "#262626" : "#A3A3A3"}
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className={styles.profile}>
							<Note
								key={activeNote?.createdAt}
								note={activeNote}
								showText={true}
								noteContainerMaxHeight={
									window.innerHeight - HEAD_HEIGHT - FOOTER_HEIGHT - 100
								}
							/>
						</div>
						{displayFooter && (
							<div className={styles.drawer__footer}>
								<div className={styles.big__item}>
									<HeaderButton
										onClick={showPopup}
										text="Add Note"
										className="buttonAddNotes"
										icon={<AddIcon />}
									/>
									<HeaderButton
										icon={<SeeAllNote />}
										text="See all notes"
										className="btnAddFilter"
										onClick={() => viewAllNote()}
									/>
								</div>
								{showPagination && (
									<div className={styles.small__item}>
										<div className={styles.navigation}>
											<button
												className={styles.chevron}
												onClick={() => previousNote(activeNoteIdx)}
												disabled={!hasPrevious}
											>
												<ArrowLeft />
											</button>
											<span>
												<span className={styles.value__pagination}>
													{activeNoteIdx + 1} / {total}
												</span>
											</span>
											<button
												className={styles.chevron}
												onClick={() => nextNote(activeNoteIdx)}
												disabled={!hasNext}
											>
												<ArrowRight />
											</button>
										</div>
									</div>
								)}
							</div>
						)}
					</>
				) : (
					<div className={styles.loader_container}>
						<Loader check={false} />
					</div>
				)}
			</div>
		</Drawer>
	);
};

export default NoteDrawer;
