import React, { Component } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import _find from "lodash/find";
import JobProfile from "./JobProfile";
import { formValueSelector, change, initialize } from "redux-form";
import {
	createJob,
	setJobDataField,
	getCategories,
	getFunctions,
	setScore,
	setSkills,
	setFunctions,
	getLastJob,
	getGroups,
	getSkills,
	getListCurrencies
} from "../../actions";
import { togglePropose, loadLanguages } from "modules/app/actions/ListsActions";
import {
	selectFormatedSkillsList,
	selectFormatedLanguagesList,
	selectJob
} from "../../selectors";
import { loadSkills } from "modules/app/actions/ListsActions";
import { VACANCY, REQUEST, JOBS } from "config";

export const getJobProfileRoute = id => {
	return `/jobs/post/profile${
		id !== undefined ? (id.length ? "/" + id : "") : "(/:id)"
	}`;
};

export const getVacancyProfileRoute = id => {
	return `/vacancy/post/profile${id !== undefined ? `/${id}` : "(/:id)"}`;
};

class JobProfileContainer extends Component {
	componentDidMount() {
		const {
			languages,
			loadLanguages,
			getCategories,
			params,
			getLastJob,
			getGroups,
			getListCurrencies
		} = this.props;

		if (!languages || !languages.length) {
			loadLanguages();
		}

		getGroups();
		getListCurrencies();

		getCategories().then(categories => {
			if (_get(params, "id")) {
				getLastJob({ id: _get(params, "id") }).then(data => {
					this.initReduxForm(data, categories);
				});
			}
		});
	}

	initReduxForm = (data, categories) => {
		if (data && !!data._id) {
			const subcategories = _get(
				_find(categories, { name: data.category }),
				"children",
				[]
			);
			let _category = data.category;
			let _subcategory = data.subcategory;
			if (categories && categories.length) {
				if (typeof _category !== "object" && categories.length)
					_category = _find(categories, { name: data.category });
				if (typeof _subcategory !== "object" && subcategories.length)
					_subcategory = _find(subcategories, { name: data.subcategory });
			}

			let initialState = {
				group_id: {
					value: data.group._id,
					label: data.group.name
				},
				skills: data.skills,
				languages: data.languages
			};
			if (this.jobType() === REQUEST) {
				initialState = {
					...initialState,
					category: {
						value: _category._id,
						_id: _category._id,
						label: _category.name
					},
					subcategory: {
						value: _subcategory._id,
						label: _subcategory.name
					},
					function: data.function,
					seniority: data.seniority
				};
			} else {
				this.props.setFunctions(data.functions);
			}
			this.props.initializeReduxForm(initialState);
		}
	};

	jobType = () => {
		const pathname = _get(window, "location.pathname");
		let source;
		if (pathname) {
			source = _get(pathname.split("/"), "1");
		}
		return source === JOBS ? REQUEST : VACANCY;
	};

	render() {
		return <JobProfile {...this.props} job_type={this.jobType()} />;
	}
}

const selector = formValueSelector("createJobTerm");

const mapStateToProps = state => {
	return {
		...selectJob(state),
		connectedUser: state.auth.user,
		skills: selectFormatedSkillsList(state),
		languages: selectFormatedLanguagesList(state),
		formFields: selector(
			state,
			"category",
			"subcategory",
			"function",
			"skills",
			"languages"
		)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLastJob: payload => dispatch(getLastJob(payload)),
		setSkills: skills => dispatch(setSkills(skills)),
		getCategories: () => dispatch(getCategories()),
		getGroups: () => dispatch(getGroups()),
		setFunctions: functions => dispatch(setFunctions(functions)),
		loadLanguages: () => dispatch(loadLanguages()),
		getFunctions: payload => dispatch(getFunctions(payload)),
		setJobDataField: (value, field) => dispatch(setJobDataField(value, field)),
		getSkills: payload => dispatch(getSkills(payload)),
		setScore: (item, score, field) => dispatch(setScore(item, score, field)),
		createJob: (payload, edit = false) =>
			dispatch(createJob(payload, edit, "createJobTerm")),
		changeReduxForm: (fieldName, value) =>
			dispatch(change("createJobTerm", fieldName, value)),
		initializeReduxForm: data => dispatch(initialize("createJobTerm", data)),
		togglePropose: (sector, term) => dispatch(togglePropose(sector, term)),
		loadSkills: (value, sector) => dispatch(loadSkills(value, sector)),
		getListCurrencies: () => dispatch(getListCurrencies())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(JobProfileContainer);
