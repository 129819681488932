import styled from "styled-components";

export const Box = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
	background-color: ${props => props.background};
	padding: 2px 8px;
	border-radius: 10px;
`;
export const Text = styled.p`
	font-family: "basiercircle";
	font-size: 14px;
	color: ${props => props.color} !important;
	margin: 0;

	&:first-letter {
		text-transform: uppercase;
	}
`;
