import React, { Component } from "react";
import { connect } from "react-redux";
import BodyClassName from "react-body-classname";

import CompanyDashboard from "../../../common/dashboard/Dashboard";
import Intro from "../../../common/dashboard/Intro";
import SystemNotifications from "./SystemNotifications";
import MissionNotifications from "./MissionNotifications";
import HeaderActions from "./HeaderActions";
import { LoaderFull } from "../../../common/Loader";
import { Helmet } from "react-helmet";

export const getNotificationsRoute = () => {
	return "/notifications";
};

class NotificationsCenter extends Component {
	render() {
		const {
			isFetching,
			route: { title }
		} = this.props;
		if (isFetching)
			return <LoaderFull classes="full-loader" text="Loading notifications" />;
		return (
			<BodyClassName className="company gray-bg">
				<div id="notifications-center">
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<div className="dashboard-box">
						<CompanyDashboard />
						<Intro icon="fa fa-clock-o" title="Notifications center" />
					</div>
					<div className="container notifs">
						<div>
							{isFetching && <LoaderFull boxClasses="absolute" />}
							<HeaderActions />
							{!isFetching && <SystemNotifications />}
							{!isFetching && <MissionNotifications />}
						</div>
					</div>
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		isFetching: state.notifications.isFetching
	};
};

export default connect(mapStateToProps)(NotificationsCenter);
