import React from "react";
import { useButton } from "@react-aria/button";

const Button = props => {
	let ref = React.useRef();
	let { buttonProps } = useButton(props, ref);
	return (
		<button
			onClick={props.onClick}
			className={props.className}
			{...buttonProps}
			ref={ref}
		>
			{props.children}
		</button>
	);
};

export default Button;
