import { useQuery } from "react-query";
import { GET_CURRENCIES } from "config/api-endpoints";
import { client } from "lib/api-client";

const getCurrencies = token => {
	let config = {
		body: {}
	};

	if (token) {
		config = {
			...config,
			headers: {
				Authorization: `Bearer ${token}`
			}
		};
	}
	return client(GET_CURRENCIES, config);
};

export const useGetCurrenciesList = (token, options) => {
	const res = useQuery(GET_CURRENCIES, () => getCurrencies(token), {
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		...options
	});

	return res;
};
