const prefix = "@VACANCY/"; // make the actions unique to avoid collision
// Functions
export const ADD_FUNCTION = `${prefix}ADD_FUNCTION`;
export const REMOVE_FUNCTION = `${prefix}REMOVE_FUNCTION`;
export const UPDATE_FUNCTION = `${prefix}UPDATE_FUNCTION`;
export const UPDATE_MAIN_FUNCTION = `${prefix}UPDATE_MAIN_FUNCTION`;
export const RESET_FUNCTIONS = `${prefix}RESET_FUNCTIONS`;
export const SET_FUNCTIONS = `${prefix}SET_FUNCTIONS`;
export const SET_SECTORS = `${prefix}SET_SECTORS`;
export const SHOW_MODAL = `${prefix}FUNCTIONS_SHOW_MODAL`;
export const CLOSE_MODAL = `${prefix}FUNCTIONS_CLOSE_MODAL`;
export const INITIAL_STATE = `${prefix}FUNCTIONS_INITIAL_STATE`;
