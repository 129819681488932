import React, { useState } from "react";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import Portal from "common/Portal";

const Actions = ({ handleDelete, contractId, isDeleting }) => {
	const [open, setOpen] = useState(false);

	return (
		<div>
			<button
				onClick={() => {
					setOpen(true);
				}}
			>
				Delete
			</button>
			<Portal>
				<ConfirmationModal
					active={open}
					key="modal"
					title={`Are you sure you want to delete this contract ?`}
					onClose={() => setOpen(false)}
					type={ConfirmationTypes.error}
					firstButton={{
						action: () => {
							handleDelete(contractId);
						},
						label: `YES, DELETE`,
						type: "danger"
					}}
					loading={isDeleting}
				/>
			</Portal>
		</div>
	);
};

export default Actions;
