/* eslint-disable react/display-name */
import React from "react";
import {
	FocusScope,
	mergeProps,
	useDialog,
	useModal,
	useOverlay
} from "react-aria";
import styles from "./popover.module.scss";

const Popover = React.forwardRef(
	({ children, isOpen, onClose, style, paperStyle, ...otherProps }, ref) => {
		// Handle interacting outside the dialog and pressing
		// the Escape key to close the modal.
		let { overlayProps } = useOverlay(
			{
				onClose,
				isOpen,
				isDismissable: true
			},
			ref
		);

		// Hide content outside the modal from screen readers.
		let { modalProps } = useModal();

		// Get props for the dialog and its title
		let { dialogProps } = useDialog({}, ref);

		return (
			<FocusScope restoreFocus>
				<div
					className={styles.popover}
					{...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
					ref={ref}
					style={{ ...style, ...paperStyle }}
				>
					{children}
				</div>
			</FocusScope>
		);
	}
);

export default Popover;
