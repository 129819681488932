import React from "react";
import styles from "./add-timesheet-entries-explanatory-card.module.scss";
import { IconButton } from "common/IconButton";
import { ReactComponent as CloseIcon } from "static/icons/close-icon.svg";

const AddTimesheetEntriesExplanatoryCard = ({ onClose }) => {
	return (
		<div className={styles.container}>
			<div className={styles.textContainer}>
				<div className={styles.bulb}>💡</div>
				<div className={styles.text}>
					Upload your file, and we’ll automatically create entries for you.
				</div>
			</div>
			<div className={styles.buttonContainer}>
				<IconButton onClick={onClose} className={styles.closeButton}>
					<CloseIcon />
				</IconButton>
			</div>
		</div>
	);
};

export default AddTimesheetEntriesExplanatoryCard;
