import React, { useCallback, useMemo, useState } from "react";
import styles from "./vacancy-view-send-offer-drawer-body.module.scss";
import { Controller, useFormContext } from "react-hook-form";
import { CustomDropDown } from "common/CustomDropDown";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import NewCurrencyField from "common/NewCurrencyField";
import { PAYMENTS_TYPE, PAYMENTS_TIME, EMPLOYMENT_TYPE } from "config";
import { DatePicker } from "common/DatePicker";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-icon-grey.svg";
import { ReactComponent as StarsIcon } from "static/icons/stars-03.svg";
import { ReactComponent as LocationIcon } from "static/icons/marker-pin-04.svg";
import { ReactComponent as UploadIcon } from "static/icons/upload-02.svg";
import TextareaField from "modules/RequestView/components/drawers/components/TextareaField";
import VacancyViewSendOfferLocationField from "../VacancyViewSendOfferLocationField/VacancyViewSendOfferLocationField";
import VacancyViewSendOfferFileUploader from "../VacancyViewSendOfferFileUploader/VacancyViewSendOfferFileUploader";
import { getLocalTimeZone, today } from "@internationalized/date";
import FieldError from "common/MessagingToolNewEmail/components/FieldError";

const VancancyViewSendOfferDrawerBody = ({
	files,
	setFiles,
	currenciesList,
	isVisible
}) => {
	const {
		control,
		setValue,
		watch,
		formState: { errors }
	} = useFormContext();
	const [symbol, setSymbol] = useState("");
	const currencyCode = watch("currency");

	useMemo(() => {
		if (!currenciesList) return;
		const selectedCurrency = currenciesList?.find(
			({ code }) => code == currencyCode?.value
		);
		if (!selectedCurrency) return;
		setSymbol(selectedCurrency?.symbol);
	}, [currencyCode?.value, currenciesList]);

	const onSelectionChange = useCallback((key, onChange, data) => {
		const value = {
			label: data?.label,
			value: key
		};

		onChange(value);
	}, []);

	return (
		<div
			className={styles.container}
			style={{ display: isVisible ? "flex" : "none" }}
		>
			<div className={styles.row}>
				<div className={styles.field}>
					<label className={styles.label}>Contract type</label>
					<Controller
						control={control}
						name="contract_type"
						render={({ field: { onChange, value, name } }) => (
							<CustomDropDown
								name={name}
								value={value}
								onChange={onChange}
								options={EMPLOYMENT_TYPE}
								inputClassName={styles.dropDownInput}
							/>
						)}
					/>
					<FieldError error={errors.contract_type} />
				</div>
				<div className={styles.field}>
					<label className={styles.label}>Currency</label>
					<Controller
						name="currency"
						control={control}
						render={({ field: { value, onChange } }) => {
							return (
								<ComboBox
									inputRootClassName={styles.comboxInput}
									inputValue={value?.label}
									selectedKey={value?.value}
									placeholder={"Select a currency"}
									onChange={onChange}
									onSelectionChange={key => {
										onSelectionChange(key, onChange, value);
									}}
								>
									{(currenciesList || []).map(({ code, symbol, name }) => (
										<Item key={code}>{`${symbol} - ${name}`}</Item>
									))}
								</ComboBox>
							);
						}}
					/>
					<FieldError error={errors.currency?.value} />
				</div>
				<div className={styles.field}>
					<label className={styles.label}>Amount</label>
					<Controller
						control={control}
						name="amount"
						render={({ field: { onChange, value } }) => (
							<NewCurrencyField
								input={{
									value,
									onChange
								}}
								rootClassName={styles.currency}
								useGreenTheme
								suffix={symbol}
								suffixClassName={styles.currencySuffix}
							/>
						)}
					/>
					<FieldError error={errors.amount} />
				</div>
				<div className={styles.field}>
					<label className={styles.label}>Salary type</label>
					<Controller
						control={control}
						name="salary_type"
						render={({ field: { onChange, value, name } }) => (
							<CustomDropDown
								name={name}
								value={value}
								onChange={onChange}
								options={PAYMENTS_TYPE}
								inputClassName={styles.dropDownInput}
							/>
						)}
					/>
					<FieldError error={errors.salary_type} />
				</div>
				<div className={styles.field}>
					<label className={styles.label}>Payment period</label>
					<Controller
						control={control}
						name="payment_periode"
						render={({ field: { onChange, value, name } }) => (
							<CustomDropDown
								name={name}
								value={value}
								onChange={onChange}
								options={PAYMENTS_TIME}
								inputClassName={styles.dropDownInput}
							/>
						)}
					/>
					<FieldError error={errors.payment_periode} />
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.field}>
					<label className={styles.label}>
						<CalendarIcon /> Start date
					</label>
					<Controller
						control={control}
						name="start_date"
						render={({ field }) => (
							<DatePicker
								minValue={today(getLocalTimeZone())}
								rootClassName={styles.datePickerRoot}
								inputClassName={styles.datePickerInput}
								triggerIcon={<CalendarIcon />}
								{...field}
							/>
						)}
					/>
					<FieldError error={errors.start_date} />
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.field}>
					<label className={styles.label}>
						<StarsIcon /> Extra benefits
					</label>
					<Controller
						control={control}
						name="extra_benefits"
						render={({ field }) => {
							const onChange = e => field.onChange(e.target.value?.trimStart());
							return (
								<TextareaField
									rows={2}
									{...field}
									onChange={onChange}
									placeholder="Write a note to the interview attendees..."
								/>
							);
						}}
					/>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.field}>
					<label className={styles.label}>
						<LocationIcon /> Location
					</label>
					<div className={styles.locationWrapper}>
						<VacancyViewSendOfferLocationField
							inputClassName={styles.dropDownInput}
						/>
					</div>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.field}>
					<label className={styles.label}>
						<UploadIcon /> Additional Files (Pdf, Docx)
					</label>
					<Controller
						name="files"
						control={control}
						render={() => (
							<VacancyViewSendOfferFileUploader
								setValue={setValue}
								files={files}
								setFiles={setFiles}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default VancancyViewSendOfferDrawerBody;
