import React from "react";
import styles from "./sub-menu.module.scss";

const SubMenu = ({ items, onItemClick }) => {
	return items.map(item => (
		<div
			key={item.name}
			className={styles.subMenuItem}
			onClick={() => onItemClick(item)}
		>
			{item.label}
		</div>
	));
};

export default SubMenu;
