import React, { useCallback, useState } from "react";
import Slider from "rc-slider";
import Cropper from "react-easy-crop";
import { useMutation } from "react-query";
import _get from "lodash/get";
import { useDropzone } from "react-dropzone";
import { getCroppedImg } from "./utils";
import { client } from "lib/api-client";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import { ReactComponent as PivoteIcon } from "static/icons/pivote-icon4.svg";
import { ReactComponent as ZoomIcon } from "static/icons/zoom-icon.svg";
import { ReactComponent as GaleryIcon } from "static/icons/Galery-icon.svg";
import {
	CropContainer,
	ControlsContainer,
	CropComponentContainer
} from "./style";
import toaster from "common/Toaster";
import { SUPPORTED_SIZE } from "config";
const preSignUrl = file =>
	client(PRE_SIGNED_URL, {
		body: {
			name: _get(file, "name"),
			folder: "avatar",
			size: _get(file, "size"),
			type: _get(file, "type")
		}
	});

export default function Crop({
	imageSrc,
	setImageSrc,
	fileName,
	setOpenModal,
	type,
	setCroppedImage,
	user_id,
	profile_id,
	upload,
	uploadImage,
	onFileChange,
	uploadFile,
	cropSize = { width: 250, height: 250 },
	aspect = 1,
	onMediaLoaded = () => {},
	cropTitle = "Edit photo",
	zoomMax = 3,
	cropOutlineColor,
	buttonUploadText = "Upload new photo",
	hasMultiSite = false,
	site_id,
	setUploadFile
}) {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [rotation, setRotation] = useState(0);
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);

	const showCroppedImage = useCallback(async () => {
		const splitFileName = fileName.split(".");
		splitFileName.pop();
		const newFileName = splitFileName.join(".");
		try {
			const croppedImage = await getCroppedImg(
				imageSrc,
				croppedAreaPixels,
				rotation
			);

			setCroppedImage(croppedImage);
			setImageSrc(null);
			onDrop({
				name: `${newFileName}.png`,
				size: _get(croppedImage, "size"),
				type: _get(croppedImage, "type")
			});
			setOpenModal(false);
		} catch (e) {
			return e;
		}
	}, [imageSrc, croppedAreaPixels, rotation]);

	const [onDrop] = useMutation(file => preSignUrl(file), {
		onSuccess: res => {
			setUploadFile && setUploadFile(true);
			upload(res).then(() =>
				uploadImage({
					url: `${res.action}/${res.file_name}`,
					type,
					user_id,
					file_name: fileName,
					profile_id,
					uploadFile,
					site_id,
					hasMultiSite
				})
			);
		}
	});

	const onDropFile = useCallback(onFileChange, []);
	const { open, getInputProps } = useDropzone({
		onDrop: onDropFile,
		accept: "image/*",
		maxSize: SUPPORTED_SIZE,
		onDropRejected: () => toaster.danger("The Maximum size is 5MB")
	});

	const handleCancel = () => setImageSrc(null);

	return (
		<CropComponentContainer>
			<div className="title">{cropTitle} </div>
			<input
				{...getInputProps()}
				style={{
					display: "none"
				}}
			/>
			<CropContainer cropOutlineColor={cropOutlineColor}>
				<Cropper
					image={imageSrc}
					crop={crop}
					rotation={rotation}
					zoom={zoom}
					aspect={aspect}
					onCropChange={setCrop}
					onRotationChange={setRotation}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
					cropSize={aspect !== 1 ? undefined : cropSize}
					onMediaLoaded={onMediaLoaded}
				/>
			</CropContainer>
			<ControlsContainer>
				<div className="slider-container">
					<div className="labels">
						<span>Zoom</span>
						<ZoomIcon />
					</div>
					<Slider
						className="zoom"
						value={zoom}
						min={0.8}
						max={zoomMax}
						step={0.1}
						aria-labelledby="Zoom"
						onChange={setZoom}
					/>
				</div>
				<div className="slider-container">
					<div className="labels">
						<span>Pivot</span>
						<PivoteIcon />
					</div>
					<Slider
						className="rotation"
						value={rotation}
						min={0}
						max={360}
						step={1}
						aria-labelledby="Pivote"
						onChange={setRotation}
					/>
				</div>
			</ControlsContainer>
			<div className="buttons-container">
				<button className="add-photo" onClick={open}>
					<GaleryIcon />
					<span>{buttonUploadText}</span>
				</button>
				<div className="actions">
					<button className="cancel" onClick={handleCancel}>
						Cancel
					</button>
					<button className="apply" onClick={showCroppedImage}>
						Apply
					</button>
				</div>
			</div>
		</CropComponentContainer>
	);
}
