import React from "react";
import { Text, Flex } from "rebass";
import Modal from "common/modal";
import { Button } from "common/styled/buttons";

import { ReactComponent as ShowModfis } from "static/icons/remove_red_eye.svg";

const TimeSheetsModifsAlert = ({ active, onClose }) => {
	const scrollToModifs = () => {
		const element = document.getElementById("timesheet-table");
		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest"
			});
		}

		onClose();
	};
	return active ? (
		<Modal active={active} isOnProceed>
			<Flex alignItems="center" justifyContent="space-between">
				<Text
					mr={15}
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: "normal",
						fontSize: "14px",
						lineHeight: "34px",
						color: "#6E7191"
					}}
				>
					Changes have been published in the timesheet
				</Text>
				<Button onClick={scrollToModifs} small>
					<Flex alignItems="center">
						<ShowModfis style={{ marginRight: "12px" }} />
						Show now
					</Flex>
				</Button>
			</Flex>
		</Modal>
	) : (
		<></>
	);
};

export default TimeSheetsModifsAlert;
