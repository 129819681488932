import React from "react";
import { Box, Flex, Text } from "rebass";
import _get from "lodash/get";
import ProfileAvatar from "modules/candidate/components/profileClientView/Header/profileAvatar";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ReactComponent as ResidenceIcon } from "static/icons/elements-icons-house.svg";

import AnoProfile from "modules/candidate/components/profileClientView/Header/anoProfile";

const DrawerHeader = ({
	headline = {},
	isAnonymous = false,
	reference = {},
	preference = {}
}) => {
	const fullName = `${headline.first_name} ${headline.last_name}`;
	const renderResidence = (location = []) => {
		const residency = location.find(l => l.is_main);
		return (
			<>
				{_get(residency, "city") || _get(residency, "country") ? (
					<>{`${_get(residency, "country")}${
						_get(residency, "city") ? `, ${_get(residency, "city")}` : ""
					}`}</>
				) : (
					"N/A"
				)}
			</>
		);
	};
	return (
		<Flex>
			<Box mb={2} alignSelf="flex-start">
				{!isAnonymous ? (
					<ProfileAvatar
						src={headline.avatar}
						name={fullName}
						size={110}
						backgroundColor="#818a92"
						status={false}
					/>
				) : (
					<AnoProfile />
				)}
			</Box>

			<Box alignSelf="center">
				<Text
					mb={12}
					as={"h2"}
					sx={{
						fontFamily: "BasierCircle",
						fontSize: "30px",
						fontWeight: 600,
						color: "#212b36",
						marginLeft: "12px"
					}}
				>
					{isAnonymous ? (
						<>
							{reference === undefined ? (
								<SkeletonTheme color="#d8dce2">
									<Skeleton width={100} height={8} />
								</SkeletonTheme>
							) : (
								`REF : ${reference}`
							)}
						</>
					) : (
						<>
							{fullName === undefined ? (
								<SkeletonTheme color="#d8dce2">
									<Skeleton width={100} height={8} />
								</SkeletonTheme>
							) : (
								fullName
							)}
						</>
					)}
				</Text>

				{_get(headline, "main_function.name", "") && (
					<Text
						mb={12}
						ml={12}
						as={"h3"}
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "18px",
							color: "#212b36"
						}}
					>
						{_get(headline, "main_function.name")}
					</Text>
				)}

				{_get(preference, "preferred_location", []).length > 0 && (
					<Flex alignItems="flex-end" ml={10}>
						<ResidenceIcon />

						<Text
							sx={{
								fontSize: "16px",
								color: "#818a92",
								marginLeft: "8px",
								fontWeight: "normal",
								lineHeight: 1.17
							}}
						>
							{renderResidence(_get(preference, "preferred_location"))}
						</Text>
					</Flex>
				)}
			</Box>
		</Flex>
	);
};

export default DrawerHeader;
