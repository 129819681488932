import React from "react";
import { Box, Text } from "rebass";

const DetailCard = ({ label, detail, gridColumn }) => {
	return (
		<Box
			sx={{
				background: "#FFFFFF",
				border: "1px solid #D3D9DE",
				borderRadius: 5,
				padding: "20px",
				gridColumn
			}}
		>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "11px",
					lineHeight: "24px",
					color: "#A0A3BD"
				}}
			>
				{label}
			</Text>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: " 18px",
					lineHeight: "18px",
					color: "#6E7191"
				}}
			>
				{detail}
			</Text>
		</Box>
	);
};

export default DetailCard;
