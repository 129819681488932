import React, { useEffect, useState } from "react";
import Loader from "common/Loader";
import PROFILE_IMAGE from "static/images/Profile-100.jpg";
import { ReactComponent as CameraIcon } from "static/icons/camera.svg";
import { bytesToSize, uploadToS3 } from "config/helpers";
import styles from "./avatar-uploader.module.scss";
import { useDropzone } from "react-dropzone";
import toaster from "common/Toaster";
import { client } from "lib/api-client";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import { useMutation } from "react-query";
import cn from "classnames";

const SUPPORTED_SIZE = 1048576;
const ACCEPT = "image/*";
const S3_FOLDER = "mailbox";
const REJECTIONS = {
	"file-invalid-type": "Invalid file type.",
	"file-too-large": `The file size should not exceed ${bytesToSize(
		SUPPORTED_SIZE
	)}.`
};

export default function AvatarUploader({ onChange, value }) {
	const [preview, setPreview] = useState(value || PROFILE_IMAGE);

	const {
		getRootProps,
		getInputProps,
		fileRejections,
		isDragActive
	} = useDropzone({
		accept: ACCEPT,
		maxFiles: 1,
		maxSize: SUPPORTED_SIZE,
		onDrop: acceptedFiles => {
			uploadMutation(acceptedFiles[0]);
		}
	});

	const preSignS3 = file => {
		return client(PRE_SIGNED_URL, {
			body: {
				name: file.name,
				folder: S3_FOLDER,
				size: file.size,
				type: file.type
			}
		});
	};

	const upload = async file => {
		const response = await preSignS3(file);
		const result = await uploadToS3({ response, file });
		return result;
	};

	const [uploadMutation, { isLoading }] = useMutation(upload, {
		onSuccess(result) {
			setPreview(result.url);
			onChange(result.url);
		}
	});

	useEffect(() => {
		if (fileRejections.length) {
			fileRejections.forEach(({ errors }) => {
				errors.forEach(error =>
					toaster.danger(REJECTIONS[error.code], {
						duration: 8,
						id: error.code
					})
				);
			});
		}
	}, [fileRejections]);

	return (
		<div
			className={cn(styles.container, isDragActive && styles.dragActive)}
			role="button"
			tabIndex={0}
			{...getRootProps()}
		>
			<input {...getInputProps()} />
			<div className={styles.image}>
				{isLoading ? (
					<Loader check={false} classes={styles.loader} />
				) : (
					<>
						<img src={preview} />
						<div className={styles.indicator}>
							<CameraIcon />
						</div>
					</>
				)}
			</div>
			<div className={styles.description}>
				<div className={styles.title}>Upload new image</div>
				<div className={styles.helper}>
					Max file size - {bytesToSize(SUPPORTED_SIZE)}
				</div>
			</div>
		</div>
	);
}
