import create from "zustand";
import storeUtils from "common/FilterBox/utils";

const initialState = {
	offset: 0,
	sort: "",
	// fields: [],
	search: "",
	isEditedBy: true,
	isEditedField: true,
	isPreviousValue: true,
	isEditedValue: true
};

const usePrecessesStore = create((set, get) => ({
	filters: initialState,
	limit: 8,
	rows: [],
	setLimit: limit => set({ limit }),
	...storeUtils(get, set, initialState)
}));

export default usePrecessesStore;
