import useOnClickOutside from "hooks/useOnClickOutside";
import SettingsSwitcher from "modules/notifications/components/settings/SettingsSwitcher";
import React, { useRef } from "react";
import classnames from "./searchParams.module.scss";

//TODO: make components work dynamically
const Params = ({
	isDescriptionSearch,
	isTitleSearch,
	onClose,
	historyEdit,
	isEditedBy,
	isEditedField,
	isPreviousValue,
	isEditedValue,
	updateFilters
}) => {
	const ref = useRef();
	useOnClickOutside(ref, onClose);
	const handleChange = (key, value) => e => {
		e.stopPropagation();
		updateFilters(key, value);
	};
	return (
		<div ref={ref} className={classnames.paramsContainer}>
			<span className={classnames.title}>Search In</span>
			{!historyEdit ? (
				<>
					<div className={classnames.taskParams}>
						<SettingsSwitcher
							label={"Task Title"}
							checked={isTitleSearch}
							onChange={handleChange("isTitleSearch", !isTitleSearch)}
							name={"title_search"}
							classes="toggle-search"
						/>
					</div>
					<div className={classnames.taskParams}>
						<SettingsSwitcher
							label={"Task Description"}
							checked={isDescriptionSearch}
							onChange={handleChange(
								"isDescriptionSearch",
								!isDescriptionSearch
							)}
							name={"description_search"}
							classes="toggle-search"
						/>
					</div>
				</>
			) : (
				<>
					<div className={classnames.taskParams}>
						<SettingsSwitcher
							label={"Edited By"}
							checked={isEditedBy}
							onChange={handleChange("isEditedBy", !isEditedBy)}
							name={"edited_by"}
							classes="toggle-search"
						/>
					</div>
					<div className={classnames.taskParams}>
						<SettingsSwitcher
							label={"Edited Field"}
							checked={isEditedField}
							onChange={handleChange("isEditedField", !isEditedField)}
							name={"edited_field"}
							classes="toggle-search"
						/>
					</div>
					<div className={classnames.taskParams}>
						<SettingsSwitcher
							label={"Previous Value"}
							checked={isPreviousValue}
							onChange={handleChange("isPreviousValue", !isPreviousValue)}
							name={"previous_value"}
							classes="toggle-search"
						/>
					</div>
					<div className={classnames.taskParams}>
						<SettingsSwitcher
							label={"New Value"}
							checked={isEditedValue}
							onChange={handleChange("isEditedValue", !isEditedValue)}
							name={"new_value"}
							classes="toggle-search"
						/>
					</div>
				</>
			)}
		</div>
	);
};

export default Params;
