import { usePaginatedQuery } from "react-query";
import _omit from "lodash/omit";
import _map from "lodash/map";
import { client } from "../../../lib/api-client";

export const SEARCH_SUPPLIERS_QUERY_KEY = "@suppliers/search";

const searchSuppliers = body =>
	client("/api/companies/search-suppliers", { body });

const useSearchSuppliers = ({ tag, store }) => {
	const {
		name,
		industry,
		services,
		offset,
		languages,
		location,
		physical_presence,
		functions,
		skills,
		size
	} = store(state => ({
		name: state.name,
		industry: state.industry,
		services: state.services,
		languages: state.languages,
		offset: state.offset,
		location: state.location,
		physical_presence: state.physical_presence,
		functions: state.functions,
		skills: state.skills,
		size: state.size
	}));

	const {
		resolvedData: data,
		isFetching,
		isLoading,
		isError,
		error
	} = usePaginatedQuery(
		[
			SEARCH_SUPPLIERS_QUERY_KEY,
			tag,
			industry,
			services,
			languages,
			name,
			offset,
			location,
			physical_presence,
			functions,
			skills,
			size
		],
		() =>
			searchSuppliers({
				tag,
				company_name: name,
				industries: _map(industry, ind => ind.label),
				services: services.map(s => s.value),
				languages: languages.map(l => ({ _id: l.value, name: l.label })),
				offset,
				location: location
					? {
							..._omit(location, "placeId"),
							types: [physical_presence ? "offices" : "geo_coverage"],
							box: "",
							number: "",
							street: "",
							zip: ""
					  }
					: undefined,
				functions: _map(functions, f => ({
					name: f.function?.label ?? "",
					_id: f.function?.value ?? "",
					sector: {
						_id: f.subCategory?.value,
						name: f.subCategory?.label,
						parent_sector: {
							_id: f.category?.value,
							name: f.category?.label
						}
					}
				})),
				skills: _map(skills, s => _omit(s, "status")),
				size
			}),
		{
			retry: false,
			refetchOnWindowFocus: false
		}
	);

	return { data, isFetching, isLoading, isError, error };
};

export default useSearchSuppliers;
