import React from "react";
import { Box, Flex } from "rebass";
import TimeSheetProgress from "modules/timesheets/components/TimeSheetsDetails/TimeShetProgress";
import DetailCard from "./DetailCard";

const TimeSheetDetails = ({
	startDate,
	endDate,
	jobTitle,
	companyName,
	missionProgress,
	details = [],
	externalCompany,
	externalManager
}) => {
	return (
		<Flex>
			<TimeSheetProgress
				startDate={startDate}
				endDate={endDate}
				jobTitle={jobTitle}
				companyName={companyName}
				missionProgress={missionProgress}
				externalCompany={externalCompany}
				externalManager={externalManager}
			/>
			<Box
				ml={10}
				sx={{
					display: "grid",
					gridGap: "1.5rem",
					gridTemplateColumns: "repeat(2, minmax(0, 1fr))"
				}}
			>
				{details.map(({ label, detail, gridColumn }) => (
					<DetailCard
						key={label}
						label={label}
						detail={detail}
						gridColumn={gridColumn}
					/>
				))}
			</Box>
		</Flex>
	);
};

export default TimeSheetDetails;
