import React from "react";
import _get from "lodash/get";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { browserHistory } from "react-router";
import { CTA, CTAIcon } from "modules/job/components/jobprofile/RequestProfile";
import { ADMIN } from "config";
import MenuWithCTA from "common/MenuWithCTA";
import { getMyConsultantsRoute } from "../MyConsultants";

export const GetBUSelect = ({
	options,
	role_name,
	label,
	name,
	is_member,
	onChange,
	onClose
}) => {
	const goToBusinessUnit = () => {
		onClose && onClose();
		if (window.location.pathname === getMyConsultantsRoute()) {
			window.location.hash = "create_business_units";
		} else {
			browserHistory.push(getMyConsultantsRoute() + "#create_business_units");
		}
	};

	const disabled = role_name !== ADMIN || is_member;

	const getCTA = () => (
		<CTA size={11} small large onClick={goToBusinessUnit}>
			Create a business unit
			<CTAIcon className="icon-newtab-arrow" />
		</CTA>
	);

	return (
		<Field
			clearable={false}
			component={MenuWithCTA}
			name={name}
			className="select-user-wrapper"
			label={label}
			key="select"
			placeholder="Select a business unit"
			emptyText="You have no business units"
			options={options}
			position={false}
			searchable={false}
			disabled={disabled}
			onChange={onChange}
			cta={disabled ? null : getCTA}
		/>
	);
};

class BUMSecondStep extends React.Component {
	componentDidMount() {
		if ("group_roles_name" in this.props.invitedUser) {
			const business_unit = _get(this.props, "invitedUser.group_roles_name.0");
			this.props.change("business_unit", {
				value: business_unit._id,
				label: business_unit.name
			});
		}
	}
	render() {
		const {
			role_name,
			handleSubmit,
			ManagerBUList,
			sendInvitation
		} = this.props;

		const formatList = BUList => {
			return BUList.map(bu => ({ label: bu.name, value: bu._id }));
		};
		const _sendInvitation = ({ business_unit }) => {
			sendInvitation(business_unit);
		};

		return (
			<form
				className="grey-box"
				style={{ marginBottom: "40px" }}
				onSubmit={handleSubmit(_sendInvitation)}
				id="invite-form"
			>
				<p>Please select the Business Unit that this person will manage.</p>
				<div className="form-group">
					<label>Business unit:</label>
					<GetBUSelect
						options={formatList(ManagerBUList)}
						role_name={role_name}
						label={true}
						name="business_unit"
					/>
				</div>
			</form>
		);
	}
}

const validate = formProps => {
	const errors = {};

	if (!formProps.business_unit) {
		errors.business_unit = "Business unit is required";
	}

	return errors;
};

export default compose(
	reduxForm({
		form: "business-unit",
		validate,
		touchOnBlur: false
	})
)(BUMSecondStep);
