import * as React from "react";
import capitalize from "lodash/capitalize";
import { OPERATORS } from "common/FilterDrawer/utils";
import { CustomInput } from "common/CustomInput";
import { CustomMap } from "common/CustomMap";
import { Picker } from "common/Picker";
import { formatDate } from "common/Functions";
import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { FreelancersCurrencyInput } from "modules/freelancers/components/FreelancersCurrencyInput";
import { FreelancersBooleanSelect } from "modules/freelancers/components/FreelancersBooleanSelect";
import startCase from "lodash/startCase";
import { PickerCustomDropDown } from "../components/PickerCustomDropDown/PickerCustomDropDown";
import { CustomDropDown } from "common/CustomDropDown";

export const REQUESTS_INTERVIEWS_PAGE_SIZES = [50, 24, 12];
export const REQUESTS_INTERVIEWS_PAGE_SIZE = 24;

export const DISPLAY_TYPE = {
	table: "table",
	card: "card"
};

export const API_DATA_TYPE = "search_interviews_temporary_table";
export const REQUEST_INTERVIEWS_FILTERS_ID = "REQUEST_INTERVIEWS_FILTERS_ID";
export const REQUEST_INTERVIEWS_MODULE_ID = "REQUEST_INTERVIEWS_MODULE_ID";

export const columnSortMap = {
	candidate_name: "candidate_name",
	organizer: "organizer",
	attendees: "attendees",
	profile_type: "profile_type",
	phone_number: "phone_number",
	email: "email",
	request_title: "request_title",
	interview_date: "interview_date",
	interview_type: "interview_type",
	matching_rate: "matching_rate",
	interview_status: "interview_status",
	interviewer_location: "interviewer_location",
	availability: "availability"
};

export const columnFilterMap = {
	candidate_name: "candidate_name",
	organizer: "organizer",
	attendees: "attendees",
	profile_type: "profile_type",
	phone_number: "phone_number",
	email: "email",
	request_title: "request_title",
	interview_date: "interview_date",
	interview_type: "interview_type",
	matching_rate: "matching_rate",
	interview_status: "interview_status",
	interviewer_location: "interviewer_location",
	availability: "availability"
};

export const CHECK_FILTER_COLUMNS = [
	"candidate_name",
	"phone_number",
	"request_title",
	"email"
];

export const TYPES = {
	text: "text",
	postcode: "postcode",
	timeCommitment: "timeCommitment",
	number: "number",
	array: "array",
	boolean: "boolean",
	date: "date",
	address: "address"
};

const IsEmptyComponent = props => (
	<CustomDropDown
		options={[
			{
				label: "Yes",
				value: "yes"
			},
			{
				label: "No",
				value: "no"
			}
		]}
		{...props}
	/>
);

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.postcode]: [
		OPERATORS.contains,
		OPERATORS.notContains,
		OPERATORS.startWith
	],
	[TYPES.timeCommitment]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.number]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.customMultiSelect]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.boolean]: [OPERATORS.is, OPERATORS.isNot],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[TYPES.address]: [OPERATORS.is]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.address]: {
		default: CustomMap
	},
	[TYPES.postcode]: {
		default: CustomInput
	},
	[TYPES.timeCommitment]: {
		default: Picker
	},
	[TYPES.number]: {
		default: FreelancersCurrencyInput,
		[OPERATORS.isEmpty.value]: IsEmptyComponent
	},
	[TYPES.array]: {
		default: Picker
	},
	[TYPES.customMultiSelect]: {
		default: Picker,
		[OPERATORS.is.value]: PickerCustomDropDown
	},
	[TYPES.boolean]: {
		default: FreelancersBooleanSelect
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	}
};

const formatLocation = item => {
	if (!item.street && !item.zip && !item.city && !item.country) return "";
	if (!item.street) return `${item.zip} ${item.city} ${item.country}`;
	return `${item.street},${item.zip} ${item.city} ${item.country}`;
};
export const formatInterviewsList = list => {
	return list.map(item => {
		return {
			id: item._id,
			"Applicant's name": `${item.applicant.first_name} ${item.applicant.last_name}`,
			Organizer: item.organizer.user_name,
			"Attendee(s)": item.attendees_users,
			"Profile type": capitalize(item.applicant.type),
			"Phone number": item.applicant.phone,
			Email: item.applicant.email,
			Request: item.job.title,
			"Proposal Date": formatDate(item.interview_date),
			"Interview type": startCase(item.interview_type),
			Location: formatLocation(item),
			Availability: item.applicant.availability_status,
			"Matching Score": item.matching_rate,
			Status: item.status,
			bid_id: item.bid_id,
			applicant: item.applicant,
			availability_date: formatDate(item.applicant.availability_date),
			job: item.job
		};
	});
};

export const statusInterviewConfig = {
	sent: {
		label: "Interview Sent",
		color: "#F59E0B"
	},
	draft: {
		label: "Interview Sent",
		color: "#F59E0B"
	},
	declined: {
		label: "New Interview",
		color: "#F59E0B"
	},
	expired: {
		label: "Interview Overdue",
		color: "#DC2626"
	},
	accepted: {
		label: "Interview Planned",
		color: "#1570EF"
	},
	done: {
		label: "Interview Done",
		color: "#039855"
	},
	refused: {
		label: "Interview Canceled",
		color: "#000000",
		borderColor: "#D4D4D4"
	},
	cancelled: {
		label: "Interview Canceled",
		color: "#000000",
		borderColor: "#D4D4D4"
	},
	archived: {
		label: "Interview Archived",
		color: "#334155",
		borderColor: "#334155"
	}
};

export const isFiltersFull = filters => {
	let list = [];
	if (filters) {
		list = filters.filter(({ value }) => !isFilterEmpty(value));
	}
	return list.length > 0;
};

export const isFilterEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};
