import React, { useContext, useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import _find from "lodash/find";
import _get from "lodash/get";
import Modal from "common/modal";
import ListContainer from "common/editablelist/Next/ListContainer";
import {
	ListContext,
	LIST_ACTION_TYPES
} from "common/editablelist/Next/listContext";
import { PROPOSE_SKILL } from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";

function SkillsModal({
	loadSkills,
	skills,
	initialSkills,
	sector,
	contentHeight,
	isRequest,
	closeModal,
	onSave,
	showMustHave = true,
	categories,
	sectors,
	isSearch = false,
	cartTitle,
	saveActionName,
	modalHeadText,
	saveActionStyle = {},
	canPropose = true
}) {
	const { state, dispatch } = useContext(ListContext);
	const [formError, setFormError] = useState(false);

	let cats = [];

	if (isSearch && categories.length === 0) {
		cats = sectors;
	} else {
		cats = (sectors || []).reduce((acc, s) => {
			if (categories.find(c => c.sector.parent_sector._id === s._id)) {
				acc.push(s);
			}
			return acc;
		}, []);
	}

	useEffect(() => {
		loadSkills({
			sector: _get(state, "category")
				? _get(state, "category")
				: _get(sector, "sector.parent_sector._id"),
			term: state.filter
		});
	}, [state.filter, _get(state, "category"), _get(sector, "_id")]);

	const tooltipText = (
		<>
			Marking a skill as <strong>MUST HAVE</strong> will
			{isRequest
				? " prevent freelancers or consultants who don’t have that skill from applying on this request."
				: " exclude the candidates lacking that skill from the potential matches results."}
			You can mark up to 7 skills as <strong>MUST HAVE</strong>.{" "}
		</>
	);

	const handleClose = () => closeModal(true);
	const handleSave = () => {
		if (!state.selected.length) return setFormError(true);
		onSave(state.selected);
	};

	const setSkillsCategory = category => {
		if (category) {
			dispatch({
				type: LIST_ACTION_TYPES.SET_CATEGORY,
				category: category.value
			});
		}
	};

	const handleProposeSkill = item => {
		const category = isRequest
			? sector
			: _find(sectors, s => s._id === state.category);
		const _id = category._id;
		const name = category.label || category.name;
		const payload = {
			...item,
			skill_id: item._id,
			sector: {
				_id,
				name
			}
		};
		return AxiosHelper.post({
			url: PROPOSE_SKILL,
			isModal: true,
			loadingText: "Proposing new skill...",
			toastMessage: "Successfully proposed the skill!",
			data: payload
		});
	};

	return (
		<Modal
			className="skills-modal"
			title="Skills"
			fixed={false}
			size={"1200px"}
			onClose={handleClose}
			firstButton={{
				label: saveActionName
					? saveActionName
					: isRequest
					? "Save to job profile"
					: !isSearch
					? "Save to vacancy profile"
					: "Save to search",
				type: "primary",
				action: handleSave
			}}
			actionsStyle={{
				margin: "-30px -30px",
				padding: "15px 15px 30px",
				backgroundColor: "#f1f2f6",
				borderRadius: "0 0 3px 3px",
				...saveActionStyle
			}}
		>
			<>
				{modalHeadText ? modalHeadText : <></>}
				<div
					className="content"
					style={{
						backgroundColor: "#f1f2f5",
						height: contentHeight ? `${contentHeight}%` : "78%",
						margin: "0 -30px 15px"
					}}
				>
					<div className="row">
						<div className="col-md-12">
							<ListContainer
								data={skills}
								cartTitle={
									cartTitle
										? cartTitle
										: isRequest
										? "Job skills"
										: "Vacancy skills"
								}
								selected={initialSkills}
								formError={formError}
								sector={sector}
								tooltipText={tooltipText}
								categories={cats}
								selectedSkillsCategory={_get(state, "category")}
								setSkillsCategory={setSkillsCategory}
								showCategories={!isRequest}
								showMustHave={showMustHave}
								onPropose={handleProposeSkill}
								canPropose={canPropose}
							/>
						</div>
					</div>
				</div>
			</>
		</Modal>
	);
}

SkillsModal.propTypes = {
	skills: PropTypes.array
};

export default memo(SkillsModal);
