import React from "react";
import { Button } from "common/Button";
import { ReactComponent as AddIcon } from "static/icons/add-icon-grey.svg";
import styles from "./create-request-add-button.module.scss";

const CreateRequestAddButton = ({ onClick }) => {
	return (
		<Button
			text="Add"
			icon={<AddIcon />}
			variant="outlined"
			className={styles.addButton}
			textClassName={styles.addButtonText}
			onClick={onClick}
		/>
	);
};

export default CreateRequestAddButton;
