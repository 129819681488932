import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GENERATE_JOB_POSTING_ENDPOINT } from "config/api-endpoints";

const generateDescription = body =>
	client(GENERATE_JOB_POSTING_ENDPOINT, { body });

export const GENERATE_VACANCY_DESCRIPTION_QUERY_KEY =
	"GENERATE_VACANCY_DESCRIPTION_QUERY_KEY";

export const useGenerateVacancyDescription = (body, options = {}) => {
	return useQuery(
		[GENERATE_VACANCY_DESCRIPTION_QUERY_KEY, body],
		() => generateDescription(body),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...options
		}
	);
};
