import React from "react";
import { ConfirmationModal } from "common/modal";
import styles from "./description.module.scss";
import { ReactComponent as NotificationIcon } from "static/icons/warning-circle.svg";

const Description = ({ children, showIcon = true }) => (
	<div className={styles.descriptionContainer}>
		{showIcon && <NotificationIcon className={styles.notificationIcon} />}
		<div className={styles.description}>{children}</div>
	</div>
);

export default Description;

ConfirmationModal.Description = Description;
