import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _get from "lodash/get";
import { isLoggedInSelector } from "modules/user/selectors/selectors";
import TermsAndConditions from "./TermsAndConditions";

export const getTermsAndConditionsRoute = () => {
	return "https://www.wiggli.io/terms-of-use";
};

class TermsAndConditionsContainer extends Component {
	state = {
		activeSection: "permanent"
	};

	componentDidMount() {
		require("smoothscroll-polyfill").polyfill();
		const hash = _get(this.props, "location.hash");
		if (hash) {
			this.setState({
				activeSection: hash.split("-")[0].replace("#", "")
			});
		} else {
			window.location.hash = "#permanent-preface";
		}
	}

	handleSectionChange = newSection => {
		this.setState({
			activeSection: newSection
		});
	};

	getCurrentRoute = props => {
		const defaultActive = "preamble";
		const hash = _get(props, "location.hash");
		if (!hash) {
			return defaultActive;
		}
		return hash.replace(`#`, "");
	};

	render() {
		const { isLoggedIn, ...props } = this.props;
		return (
			<TermsAndConditions
				currentRoute={this.getCurrentRoute(props)}
				isLoggedIn={isLoggedIn}
				activeSection={this.state.activeSection}
				handleSectionChange={this.handleSectionChange}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		isLoggedIn: isLoggedInSelector(state)
	};
};

export default connect(mapStateToProps)(
	withRouter(TermsAndConditionsContainer)
);
