import React from "react";
import _get from "lodash/get";
import Skills from "modules/job/components/jobprofile/Skills";
import GlobalTooltip from "common/GlobalTooltip";

const FreelancerSkills = ({ functionsLength, skills, showModal, ownCV }) => {
	return (
		<div id="skills" className="section action-hover">
			{ownCV && _get(skills, "length") > 0 && (
				<div className="section-edit pull-right">
					<button className="btn btn-sm" onClick={showModal}>
						<span className="icon-edit-2" />
					</button>
				</div>
			)}
			<div className="section-title">
				Skills{" "}
				{ownCV && (
					<GlobalTooltip
						maxWidth="600px"
						overlay={
							<span>
								The skills that you select are instrumental to both the
								candidate/request matching process and your matching score
								calculation. <br />
								Make sure to be as precise and candid as possible in the
								selection of your skills. <br />A well thought out
								skills-selection will allow you to quickly find more matching
								missions.
							</span>
						}
					/>
				)}
			</div>
			<div className="section-content">
				{!ownCV && _get(skills, "length") === 0 && (
					<div className="alert alert-warning">No information available</div>
				)}
				{ownCV && _get(skills, "length") === 0 && (
					<div className="empty-section">
						<div className="add btn-center-block" onClick={showModal}>
							<i className="fa fa-plus" /> Add skills
						</div>
					</div>
				)}
				{_get(skills, "length") !== 0 && (
					<>
						<Skills data={skills} />
						{!_get(skills, "length", 0) && (
							<div className="empty-section">
								<div
									className={`add btn-center-block ${
										functionsLength === 0 ? `disabled-button` : ""
									}`}
									onClick={functionsLength > 0 ? showModal : null}
								>
									<i className="fa fa-plus" /> Add skills
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default FreelancerSkills;
