import React, { Fragment } from "react";
import styles from "./evaluation-details-card-body.module.scss";
import { ReactComponent as NoIcon } from "static/icons/thumbs-down.svg";
import { ReactComponent as AcceptableIcon } from "static/icons/thumbs-up2.svg";
import { ReactComponent as ExcellentIcon } from "static/icons/thumbs-up.svg";
import cx from "classnames";
import { SCORE_QUESTION_ANSWERS_OPTIONS } from "config";
import { ELEMENT_TYPES } from "../../../modules/EvaluationFormsSettings/utils/constants";

const renderQuestion = (element, contentClassName) => {
	return (
		element.type === ELEMENT_TYPES.question &&
		element.title && (
			<div className={styles.questionContainer}>
				<span className={styles.questionTitle}>
					{element.title}&nbsp;{element.is_required ? "*" : ""}
				</span>
				<span className={cx(styles.answer, contentClassName)}>
					{element.value}
				</span>
			</div>
		)
	);
};

const renderBooleanQuestion = item => {
	return (
		item.type === ELEMENT_TYPES.yes_no && (
			<div className={styles.questionContainer}>
				<span className={styles.questionTitle}>
					{item.title}&nbsp;{item.is_required ? "*" : ""}
				</span>
				<div className={styles.yesNo}>
					<span
						className={item.value === "true" ? styles.active : styles.disactive}
					>
						Yes
					</span>
					<span
						className={
							item.value === "false" ? styles.active : styles.disactive
						}
					>
						No
					</span>
				</div>
			</div>
		)
	);
};

const renderSection = element => {
	return (
		element.type === ELEMENT_TYPES.section &&
		element.title && <span className={styles.section}>{element.title}</span>
	);
};

const renderScoreCardTitle = element => {
	return (
		element.type === ELEMENT_TYPES.score_card &&
		element.title && <span className={styles.scoreTitle}>{element.title}</span>
	);
};

const renderItems = (element, contentClassName) => {
	return element.items.map((item, itemIndex) => {
		return (
			<Fragment key={itemIndex}>
				{renderScoreCardItems(item)}
				{renderQuestion(item, contentClassName)}
				{renderBooleanQuestion(item)}
			</Fragment>
		);
	});
};

const renderScoreCardIcons = score => {
	return (
		<div className={styles.scoreCardIcons}>
			<div
				className={cx(styles.thumbs, {
					[styles.blackThumbs]: score !== SCORE_QUESTION_ANSWERS_OPTIONS.bad
				})}
			>
				<NoIcon />
			</div>
			<div
				className={cx(styles.thumbs, {
					[styles.blackThumbs]:
						score !== SCORE_QUESTION_ANSWERS_OPTIONS.acceptable
				})}
			>
				<AcceptableIcon />
			</div>
			<div
				className={cx(styles.thumbs, {
					[styles.blackThumbs]:
						score !== SCORE_QUESTION_ANSWERS_OPTIONS.excellent
				})}
			>
				<ExcellentIcon />
			</div>
		</div>
	);
};

const renderScoreCardItems = element => {
	return (
		element.type === ELEMENT_TYPES.score_card && (
			<div className={styles.scoreCardContainer}>
				{element.items.map((item, itemIndex) => (
					<>
						<div className={styles.scoreCard} key={itemIndex}>
							<div className={styles.scoreCardTitle}>{item.title}</div>
							{renderScoreCardIcons(item.value)}
						</div>
						{itemIndex !== element.items.length - 1 && (
							<hr className={styles.hr} />
						)}
					</>
				))}
			</div>
		)
	);
};

const EvaluationDetailsCardBody = ({ form, note, contentClassName }) => {
	return (
		<div className={styles.evaluationCardBody}>
			{form.map((element, index) => (
				<div className={styles.contentClassName} key={index}>
					{renderQuestion(element, contentClassName)}
					{renderSection(element)}
					{renderScoreCardTitle(element)}
					{element.items && (
						<div className={styles.innerQuestions}>
							{renderItems(element, contentClassName)}
						</div>
					)}
					{renderScoreCardItems(element)}
					{renderBooleanQuestion(element)}
				</div>
			))}
			{note && (
				<>
					<hr className={styles.lastDivider} />
					<div className={styles.noteContainer}>
						<span className={styles.noteLabel}>Note</span>
						<div className={styles.answer}>
							<div dangerouslySetInnerHTML={{ __html: note }} />
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default EvaluationDetailsCardBody;
