import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import FormField from "common/FormField";
import { removeInterest } from "../../redux/actions";
import Modal, { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { WrapperModal } from "../styled";

function RemoveInterestModal({
	onClose,
	handleSubmit,
	poke_id,
	removeInterestAction,
	condidateSide
}) {
	const [showWarning, setShowWarning] = useState(false);
	const stopped = () => {
		if (!showWarning) {
			return setShowWarning(true);
		}
	};

	const dataCancelInterview = fields => {
		const payload = {
			id: poke_id,
			reason: fields.feedback
		};
		removeInterestAction(payload).then(() => {
			onClose();
		});
	};

	return (
		<>
			<Modal
				onClose={onClose}
				title={"Remove Interest"}
				firstButton={{
					action: onClose,
					label: "Cancel",
					type: "outlined",
					large: true
				}}
				secondButton={{
					action: handleSubmit(stopped),
					label: "Remove Interest",
					type: "primary",
					large: true
				}}
				active={!showWarning}
			>
				<div className="content">
					<WrapperModal>
						<div className="row">
							<div className="col-md-10">
								<p className="txt-modal">
									Use the box below to let the{" "}
									{condidateSide ? "client" : "condidate"} know why you have
									decided to remove the interest
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<Field
									name="feedback"
									type="textarea"
									className="col-md-11"
									component={FormField}
									maxLength="500"
									placeholder={
										"Your feedback, which will be sent to the Client."
									}
								/>
							</div>
						</div>
					</WrapperModal>
				</div>
			</Modal>

			{showWarning && (
				<ConfirmationModal
					modalName="stop_job"
					onClose={() => setShowWarning(false)}
					type={ConfirmationTypes.error}
					title={"Are you sure you want to remove this interest?"}
					firstButton={{
						action: handleSubmit(dataCancelInterview),
						label: "Yes, remove interest",
						type: "warning"
					}}
				/>
			)}
		</>
	);
}

const mapDispatchToProps = dispatch => ({
	removeInterestAction: payload => dispatch(removeInterest(payload))
});

const validate = formProps => {
	const errors = {};
	if (formProps.feedback && formProps.feedback.length > 500) {
		errors.feedback = "This field should not exceed 500 characters";
	}

	if (!formProps.feedback || formProps.feedback.trim() === "") {
		errors.feedback = "Please provide your feedback.";
	}
	return errors;
};

RemoveInterestModal.propTypes = {
	removeInterestAction: PropTypes.func,
	onClose: PropTypes.func
};

export default compose(
	connect(null, mapDispatchToProps),
	reduxForm({
		form: "remove-interest",
		validate,
		touchOnBlur: false
	})
)(RemoveInterestModal);
