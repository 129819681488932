import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_USER_SETTING } from "config/api-endpoints";
import toaster from "common/Toaster";

export default function useUpdateSettings() {
	return useMutation(body => client(UPDATE_USER_SETTING, { body }), {
		onError: () => {
			toaster.danger("Something went wrong. Please try again!");
		}
	});
}
