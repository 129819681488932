import { usePaginatedQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_CONTRACTS_GROUPS_LIST } from "config/api-endpoints";

const getContractsGroups = body =>
	client(GET_CONTRACTS_GROUPS_LIST, {
		body
	});

export const GET_CONTRACTS_GROUPS_QUERY_KEY = "GET_CONTRACTS_GROUPS_QUERY_KEY";

const useGetContractsGroupsList = (body, queryOptions = {}) =>
	usePaginatedQuery(
		[GET_CONTRACTS_GROUPS_QUERY_KEY, body],
		() => getContractsGroups(body),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...queryOptions
		}
	);

export default useGetContractsGroupsList;
