import _get from "lodash/get";
import React from "react";
import styles from "modules/candidate/Profile/components/SkillsCard/skills-card.module.scss";
import EditableSection from "../EditableSection";
import Tag from "modules/candidate/Profile/components/Tag";
import EmptyState from "modules/candidate/Profile/components/EmptyState";

const Languages = ({ profileData }) => {
	const sectionLanguages = _get(profileData, "update_sections.languages");

	const languages = _get(profileData, "languages", []);
	return (
		<React.Fragment>
			<EditableSection
				title="Languages"
				hasEdit={false}
				sectionUpdate={sectionLanguages}
			>
				<div className={styles.section}>
					<div className={styles.row}>
						{languages.length > 0 ? (
							languages.map(language => (
								<Tag
									key={_get(language, "_id")}
									text={`${_get(language, "name")} (${_get(
										language,
										"score"
									)}/5)`}
								/>
							))
						) : (
							<EmptyState />
						)}
					</div>
				</div>
			</EditableSection>
		</React.Fragment>
	);
};

export default Languages;
