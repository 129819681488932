import React from "react";
import BoxContainer from "./box";
import { Box, Text } from "rebass";
import {
	DISPUTED,
	ACCEPTED,
	IN_REVIEW,
	EDITED,
	APPROVED,
	SUBMITTED,
	DRAFT
} from "config";

const statusBackground = {
	disputed: "#F9A937",
	draft: "#A0A3BD",
	submitted: "#1F5DC6",
	in_mediation: "#f75356",
	approved: "#36ba90",
	accepted: "#36ba90",
	edited: "#FD6950",
	in_review: "#FFC107"
};

const statusLabel = {
	disputed: DISPUTED,
	draft: DRAFT,
	submitted: SUBMITTED,
	in_mediation: "In mediation",
	approved: APPROVED,
	accepted: APPROVED,
	edited: EDITED,
	in_review: IN_REVIEW
};

const defineTimesheetStatus = (list, status) => {
	let definedStatus = status;
	const mappedListStatus = list.map(item => item.status);
	if (
		status !== APPROVED &&
		(mappedListStatus.includes(DISPUTED) ||
			mappedListStatus.includes(ACCEPTED) ||
			status === DISPUTED)
	)
		definedStatus = "in_review";
	return definedStatus;
};

const TimesheetStatus = ({ status, submittedBy, isClient, allItems }) => {
	return (
		<BoxContainer isClient={isClient}>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "16px",
					lineHeight: "24px",
					color: "#6E7191"
				}}
			>
				TIMESHEET STATUS
			</Text>
			<Box
				mr={10}
				sx={{
					background: statusBackground[defineTimesheetStatus(allItems, status)],
					borderRadius: "3px",
					padding: "10px",
					display: "inline-block"
				}}
			>
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "12px",
						lineHeight: "22px",
						color: "#FFFFFF",
						textTransform: "uppercase"
					}}
				>
					{statusLabel[defineTimesheetStatus(allItems, status)]}
				</Text>
			</Box>

			{submittedBy && (
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "16px",
						lineHeight: "24px",
						color: "#6E7191",
						display: "inline-block"
					}}
				>
					by {submittedBy}
				</Text>
			)}
		</BoxContainer>
	);
};

export default TimesheetStatus;
