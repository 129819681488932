import React from "react";

const FormError = props => {
	return (
		<div className="form-error">
			<span className="icon-error" />
			{props.message}
		</div>
	);
};

export default FormError;
