import React from "react";
import Drawer from "rc-drawer";
import Avatar from "common/Avatar";
import styles from "./evaluation-template-form-drawer.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { ReactComponent as ArrowLeftIcon } from "static/icons/arrow-left-icon.svg";
import cx from "classnames";
import { FormProvider, useForm } from "react-hook-form";
import { getFormTemplateInitialValues } from "./components/EvaluationTemplateRender/helpers";

function EvaluationTemplateFormDrawer({ isOpen, onClose, children }) {
	return (
		<Drawer
			width="700"
			open={isOpen}
			height="100%"
			level={"root"}
			placement="right"
			onClose={onClose}
			maskClosable={true}
			style={{ zIndex: 1100 }}
		>
			<div className={styles.drawerContainer}>{children}</div>
		</Drawer>
	);
}

function Header({ children }) {
	return <div className={styles.drawerHeaderContainer}>{children}</div>;
}

function Body({ children, onSubmit, template }) {
	const methods = useForm({
		defaultValues: {
			...getFormTemplateInitialValues(template?.elements || []),
			evaluation_score: template?.evaluation_score || "",
			evaluation_note: template?.evaluation_note || ""
		}
	});

	return (
		<div className={styles.drawerBodyContainer}>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
			</FormProvider>
		</div>
	);
}

function Divider() {
	return <div className={styles.divider} />;
}

function Title({ title, onClose, onPrevious }) {
	return (
		<div className={styles.titleContainer}>
			<div className={styles.flexContainer}>
				{!!onPrevious && (
					<button onClick={onPrevious} className={styles.closeBtn}>
						<ArrowLeftIcon />
					</button>
				)}
				<div className={styles.title}>{title}</div>
			</div>
			<button onClick={onClose} className={styles.closeBtn}>
				<CloseIcon />
			</button>
		</div>
	);
}

function Tabs({ tabs, activeTab, onChange }) {
	return (
		<nav className={styles.tabsContainer}>
			{tabs.map(({ id, component }) => (
				<button
					key={id}
					onClick={() => onChange(id)}
					className={cx(styles.tabItem, { [styles.active]: id === activeTab })}
				>
					{component}
				</button>
			))}
		</nav>
	);
}
function TabPanel({ className = "", children }) {
	return (
		<div className={cx(styles.tabPanelContainer, className)}>{children}</div>
	);
}

function CandidateInfo({ avatar, candidate_function, candidate_name }) {
	return (
		<div className={styles.candidateCardContainer}>
			<Avatar
				size={56}
				radius={12}
				avatar={avatar}
				classNameOnly={true}
				name={candidate_name}
				className={styles.avatar}
			/>
			<div className={styles.candidateDescription}>
				<h3 className={styles.candidateName}>{candidate_name}</h3>
				<p className={styles.vacancyTitle}>
					{candidate_function?.main_function}
					{candidate_function?.count > 0 && (
						<span className={styles.vacancyTitle}>
							{` (${candidate_function?.count})`}
						</span>
					)}
				</p>
			</div>
		</div>
	);
}

EvaluationTemplateFormDrawer.Body = Body;
EvaluationTemplateFormDrawer.Tabs = Tabs;
EvaluationTemplateFormDrawer.Title = Title;
EvaluationTemplateFormDrawer.Header = Header;
EvaluationTemplateFormDrawer.Divider = Divider;
EvaluationTemplateFormDrawer.TabPanel = TabPanel;
EvaluationTemplateFormDrawer.CandidateInfo = CandidateInfo;
export default EvaluationTemplateFormDrawer;
