import React from "react";
import { ReactComponent as CalendrierIcon } from "static/icons/calendrier-icon.svg";
import { InterviewStatusContainer } from "../styles";

const statusColor = {
	sent: "#B98900",
	done: "#008060",
	cancelled: "#D82C0D",
	accepted: "#4D13D1"
};
const InterviewStatus = ({ label, date, detail, status }) => {
	return (
		<InterviewStatusContainer color={statusColor[status]}>
			<span className="headline"> {label} </span>
			<div className="detail-wrapper">
				<div className="icon">
					<CalendrierIcon
						style={{ fill: statusColor[status], margin: "0px 7px" }}
					/>
					<span className="content">{date}</span>
				</div>

				<span className="content">{detail}</span>
			</div>
		</InterviewStatusContainer>
	);
};

export default InterviewStatus;
