//TODO This component is duplicate, we can use one component
import React, { useRef, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { ReactComponent as RemoveIcon } from "static/icons/removeIcon.svg";
import classNames from "classnames";
import { OPERATORS } from "common/FilterDrawer/utils";
import {
	formatFilterStore2,
	getOperatorLabel
} from "common/FilterDrawer/format_utils";
import get from "lodash/get";
import Tooltip from "common/Tippy";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";

const isEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};

const formatDate = date => {
	return new Intl.DateTimeFormat("en-BE", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric"
	}).format(date);
};

const renderLabelValues = (values, withRating = false) => {
	return get(values, "[0].children", [])
		.map(value => {
			return value.label + (withRating ? ` ${value.rating}/5` : "");
		})
		.join(" - ");
};

const renderDateValues = dates => {
	if (dates) {
		if ("start" && "end" in dates) {
			const date_start = formatDate(dates.start.toDate());
			const date_end = formatDate(dates.end.toDate());

			return `${date_start} and ${date_end}`;
		} else {
			return formatDate(dates.toDate());
		}
	}
};

const renderBooleanValues = b => {
	return b ? "Yes" : "No";
};

const renderFilterValues = (field, value, operator) => {
	if (typeof value !== "undefined") {
		switch (field) {
			case "note_type":
				return renderLabelValues(value);

			case "created_by":
				return renderLabelValues(value);
			case "vacancy":
				if (operator === "is_empty") {
					return renderBooleanValues(value);
				} else {
					return renderLabelValues(value);
				}
			case "created_at":
				return renderDateValues(value);
			case "profile":
				return renderLabelValues(value);
			default:
				return value || "";
		}
	}
};

const FilterPill = ({
	filter,
	onRemove,
	removable,
	variant,
	small,
	onClick,
	styles,
	localFormatting = false,
	filterId
}) => {
	const pillStyle = styles ? styles : classes;
	const { getState } = useFilters();
	const { columns } = getState(filterId);
	const ref = useRef();

	const [formatted_filter, setFormattedFilter] = useState({});

	useEffect(() => {
		if (localFormatting) {
			setFormattedFilter(formatFilterStore2(columns, filter));
		} else {
			setFormattedFilter(filter);
		}
	}, [filter]);

	return (
		<>
			{!isEmpty(get(formatted_filter, "value", "")) && (
				<div
					className={classNames(pillStyle.filters_pill, {
						[pillStyle.no_margin]: variant === "marginless",
						[pillStyle.small]: small
					})}
					onClick={onClick}
				>
					<div
						className={classNames(pillStyle.filters_text, {
							[pillStyle.small]: small
						})}
					>
						{get(formatted_filter, "label", "")}&nbsp;
						{get(formatted_filter, "operator", "") !== "" && (
							<>
								<p className={pillStyle.filter_op}>
									{getOperatorLabel(
										OPERATORS,
										get(formatted_filter, "operator")
									)}
								</p>
								&nbsp;
							</>
						)}
						<Tooltip
							theme="dark"
							content={renderFilterValues(
								get(formatted_filter, "name", ""),
								get(formatted_filter, "value", ""),
								get(formatted_filter, "operator")
							)}
							overflow="hidden"
						>
							<p ref={ref} className={small && pillStyle.small}>
								{renderFilterValues(
									get(formatted_filter, "name", ""),
									get(formatted_filter, "value", ""),
									get(formatted_filter, "operator")
								)}
							</p>
						</Tooltip>
					</div>
					{removable && (
						<button
							className={pillStyle.removeBtn}
							onClick={() => onRemove(get(formatted_filter, "id"))}
						>
							<RemoveIcon />
						</button>
					)}
				</div>
			)}
		</>
	);
};

export default FilterPill;
