import create from "zustand";
import { VACANCY_CANDIDATES_LIST } from "modules/home/config";
const initialState = {
	permanentType: "pendingApplications",
	permanentStartDate: "",
	permanentEndDate: "",
	isSettedDate: false,
	pendingApplications: [],
	openJobs: [],
	jobsOverdue: [],
	[VACANCY_CANDIDATES_LIST]: [],
	vacancyAppliations: []
};
const usePermanentStore = create((set, get) => ({
	...initialState,
	setItemPermanent: (key, value) => set({ [key]: value }),
	getVacancyCandidatesList: () => {
		const state = get(() => state);

		return state[VACANCY_CANDIDATES_LIST];
	},
	setVacancyCandidatesList: value => set({ [VACANCY_CANDIDATES_LIST]: value }),
	getVacancyApplications: () => {
		const state = get(() => state);

		return state.vacancyAppliations;
	},
	setVacancyApplications: value => set({ vacancyAppliations: value }),
	reset: () => set({ ...initialState })
}));

export default usePermanentStore;
