import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import { Button } from "rebass";
import { usePaginatedQuery, useQuery } from "react-query";
import { browserHistory } from "react-router";

import { client } from "lib/api-client";
import {
	FILTER_SETTINGS_ENDPOINT,
	LIST_APPLICATIONS_ENDPOINT
} from "config/api-endpoints";
import Filters from "./Filters";
import { ViewContainer } from "./Styled";
import { formatDate } from "common/Functions";
import Pagination from "common/PaginationClient";
import Table from "common/Table";
import Status from "common/Table/Status";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { INACTIVE } from "config";
import { historyPush } from "config/helpers";

const getInterviews = (_key, { sort, filters, search }) => {
	return client(LIST_APPLICATIONS_ENDPOINT, {
		body: {
			sort,
			search,
			...filters,
			tag: "shortlists"
		}
	});
};

const getSettings = () => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "filter_shortlists_list_tag"
		}
	});
};

const renderStatus = data => (
	<>
		<Status status={_get(data, "value")} />
		{_get(data, "value")}
	</>
);
const renderProfileName = data => (
	<>
		{_get(data, "value.first_name")
			? `${_get(data, "value.first_name")} ${_get(data, "value.last_name")}`
			: _get(data, "value.reference")}
	</>
);

const getTableColumns = settings => [
	{
		Header: `Candidate's name/Ref`,
		accessor: "profile",
		Cell: renderProfileName,
		width: 140,
		hasFilter: true,
		filter: "profile_id",
		filterOptions: formatSettings(_get(settings, "poked_profile", []))
	},
	{
		Header: "Department",
		accessor: "group.name",
		hasFilter: true,
		filter: "group_id",
		filterOptions: formatSettings(_get(settings, "list_groups", [])),
		width: 120
	},
	{
		Header: "Vacancy",
		accessor: "job.title",
		hasFilter: true,
		filter: "vacancy_id",
		filterOptions: formatSettings(_get(settings, "jobs_title", [])),
		width: 280
	},
	{
		Header: "Date",
		accessor: "created_at",
		Cell: props => formatDate(_get(props, "value")),
		width: 80,
		hasFilter: true,
		disabledFilter: true
	},
	{
		Header: "Sourced by",
		accessor: "shortlisted_by.full_name",
		hasFilter: true,
		filter: "shortlisted_by",
		filterOptions: formatSettings(_get(settings, "shortlisted_by", []))
	},
	{
		Header: "Status",
		accessor: "visible_status",
		hasFilter: true,
		Cell: renderStatus,
		filter: "status",
		filterOptions: formatSettings(_get(settings, "status", []))
	}
];

const formatSettings = settings => {
	return settings.map(setting => {
		if (typeof setting === "string") {
			return {
				label: setting,
				value: setting
			};
		}
		return {
			label: _get(
				setting,
				"title",
				_get(setting, "name", _get(setting, "full_name"))
			),
			value: _get(setting, "_id")
		};
	});
};

const Shortlistslist = () => {
	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState({});
	const [sort, setSort] = useState("desc");

	const { resolvedData, isFetching } = usePaginatedQuery(
		["loadPokes", { search, sort, filters }],
		getInterviews
	);

	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([{ name: "Permanent" }, { name: "Sourced", path: "/shortlists" }]);
	}, []);
	const settingsData = useQuery(["loadSettings"], getSettings);

	const handlePageClick = value => {
		setFilters({ ...filters, offset: _get(resolvedData, "size") * value });
	};

	const handleRowClick = row => {
		historyPush(`/vacancies/client/poke/${_get(row, "original._id")}`);
	};

	const shouldDisableRow = row => {
		let isActive = _get(row, "original.profile.candidate_status") !== INACTIVE;
		return !isActive;
	};
	const handleProfilClick = row => {
		browserHistory.push(`/permanent/view/${row.original.profile._id}`);
	};

	const inActiveMessageTooltip = row => (
		<>
			Since your contact, the candidate has changed his/her status to inactive
			as (s)he is no longer open for opportunities.
			<br />
			<Button
				sx={{
					background: "none",
					color: "#333",
					padding: 0,
					textDecoration: "underline",
					outline: "none"
				}}
				onClick={() => handleProfilClick(row)}
			>
				Click here
			</Button>{" "}
			to view his/her profile
		</>
	);

	return (
		<ViewContainer>
			<Filters
				submitSearch={value => {
					setSearch(value);
				}}
				settingsData={settingsData}
				setSort={setSort}
				sort={sort}
			/>
			<Table
				filters={filters}
				setFilters={setFilters}
				columns={getTableColumns(_get(settingsData, "data", {}))}
				data={_get(resolvedData, "data")}
				isLoading={isFetching}
				emptyTitle={"No sourced"}
				emptyText={"There is no sourced to display yet."}
				onRowClick={handleRowClick}
				shouldDisableRow={shouldDisableRow}
				disabledRowOverlay={inActiveMessageTooltip}
			/>
			<Pagination
				total={_get(resolvedData, "total")}
				size={_get(resolvedData, "size")}
				offset={_get(resolvedData, "offset")}
				handlePageClick={handlePageClick}
			/>
		</ViewContainer>
	);
};

export const getShortlistslistRoute = () => `/shortlists`;

export const getShortlistslistPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default Shortlistslist;
