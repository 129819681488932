import { browserHistory } from "react-router";
import _get from "lodash/get";
import _filter from "lodash/filter";
import _find from "lodash/find";
import * as types from "./actionTypes";
import { WORKTIME, LIVE, START_DATE, DESC, OVERTIME, EXPENSE } from "config";
import AxiosHelper from "config/axios-helper";
import {
	READ_TIMESHEET,
	ADD_WORKTIME,
	ADD_EXPENSE,
	DELETE_EXPENSE,
	DELETE_WORKTIME,
	GET_TIME,
	SUBMIT_TIMESHEET,
	ACCEPT_TIMESHEET,
	ACCEPT_DISPUTE_TIMESHEET,
	ACCEPT_REFUSE_DISPUTED_TIMESHEET,
	COMPUTE_TIMESHEET,
	TIMESHEET_LIST
} from "config/api-endpoints";
import { initialize } from "redux-form";
import { utcTimeStamp } from "config/helpers";

export function initializeTimesheet() {
	return {
		type: types.INITIALIZE_TIMESHEET
	};
}

export function documentUploaded(data, editMode = false, multiEdit = false) {
	return {
		type: types.HANDLE_DOCUMENT_UPLOAD,
		data,
		editMode,
		multiEdit
	};
}

export function switchView(view) {
	return {
		type: types.SWITCH_VIEW,
		view
	};
}

export function refuseItem(_id, list) {
	return {
		type: types.REFUSE_DISPUTED_ITEM,
		list,
		_id
	};
}

export function acceptItem(_id, list) {
	return {
		type: types.APPROVE_DISPUTED_ITEM,
		list,
		_id
	};
}

export function editItem({
	list,
	view_id,
	date,
	hours,
	rate,
	tag,
	_id,
	comments,
	description,
	amount,
	...item
}) {
	return async dispatch => {
		let _item;
		if (list === "worktimes") {
			const repHours = hours.format("HH:mm");
			let minutes = repHours.split(":");
			minutes = parseFloat(minutes[0]) * 60 + parseFloat(minutes[1]);
			_item = {
				...item,
				comments: comments || "",
				description,
				date: utcTimeStamp({ date, manageTime: false }),
				back_hours: minutes,
				rate: typeof rate === "number" ? rate : rate.value,
				hours: minutes,
				hours_representation: repHours
			};
		} else {
			_item = {
				comments: comments || "",
				description,
				date: utcTimeStamp({ date, manageTime: false }),
				amount: amount,
				...item,
				document: item.document || ""
			};
		}

		if (tag) _item.tag = typeof tag === "string" ? tag : tag.value;
		await dispatch({
			type: types.EDIT_ITEM,
			list,
			view_id,
			_id,
			item: _item
		});
		dispatch(computeTimesheet());
	};
}

export function toggleAction(action, item_id, userType) {
	return {
		type: types.TOGGLE_ACTION,
		action,
		item_id,
		userType
	};
}

export function toggleGeneralDispute() {
	return {
		type: types.TOGGLE_GENERAL_DISPUTE
	};
}

export function showReasonModal(item) {
	return {
		type: types.SHOW_REASON_MODAL,
		item
	};
}

export function toggleDeleteModal(item = {}) {
	return {
		type: types.TOGGLE_DELETE_MODAL,
		item
	};
}

export function toggleDisputeModal() {
	return {
		type: types.TOGGLE_DISPUTE_MODAL
	};
}

export function closeReasonModal() {
	return {
		type: types.CLOSE_REASON_MODAL
	};
}

export function disputeEntry(payload) {
	return {
		type: types.DISPUTE_ENTRY,
		payload
	};
}

export function switchStatusView(status) {
	return {
		type: types.SWITCH_STATUS_VIEW,
		status
	};
}

export function toggleDisputeWarning() {
	return {
		type: types.TOGGLE_DISPUTE_WARNING
	};
}

export function toggleReSubmitTimesheet() {
	return {
		type: types.TOGGLE_RESUBMIT_TIMESHEET
	};
}

export function toggleDisputedModal(data = {}) {
	return dispatch => {
		dispatch({
			type: types.TOGGLE_DISPUTED_MODAL,
			item: data
		});
		if (data) {
			let { hours, date, dispute, hours_representation } = data;
			if (hours_representation || (dispute && dispute.hours_representation)) {
				let hoursList = _get(
					dispute,
					"hours_representation",
					hours_representation
				).split(":");
				hours = window.moment({
					hour: hoursList[0],
					minute: hoursList[1]
				});
			}

			dispatch(
				initialize("disputed-timesheet", {
					...data,
					dispute,
					...dispute,
					hours,
					date: window.moment.unix(_get(dispute, "date", date)).toDate()
				})
			);
		}
	};
}

export function toggleEditTimesheet(value) {
	return {
		type: types.TOGGLE_DISPUTE_TIMESHEET,
		data: value
	};
}

// async actions
export function readTimeSheet(job_id, timesheet_id) {
	return dispatch => {
		dispatch(getTime());
		return dispatch(
			AxiosHelper.__post({
				url: READ_TIMESHEET,
				data: {
					job_id,
					timesheet_id
				},
				REQUEST_SUCCESS: types.WRITE_TIMESHEET,
				loadingText: "Loading timesheet..."
			})
		).catch(error => {
			if (error.status === 404)
				return browserHistory.push("/reporting/timesheets");
		});
	};
}

export function getTimesheetList({
	status = LIVE,
	field = START_DATE,
	sort = DESC
} = {}) {
	let hash = window.location.hash;
	return AxiosHelper.__post({
		url: TIMESHEET_LIST,
		data: {
			status: hash ? hash.substring(1) : status,
			field,
			sort
		},
		loadingText: "Loading timesheets...",
		REQUEST_SUCCESS: types.SAVE_TIMESHEET_LIST
	});
}

function getTime() {
	return AxiosHelper.__get({
		url: GET_TIME,
		REQUEST_SUCCESS: types.SET_TIME
	});
}

export function addWorkTime(payload, jobId, timesheetId) {
	return AxiosHelper.__post({
		url: ADD_WORKTIME,
		data: payload,
		toastMessage: "Worktime added.",
		next: () => readTimeSheet(jobId, timesheetId)
	});
}

export function addExpense(payload, jobId, timesheetId) {
	return AxiosHelper.__post({
		url: ADD_EXPENSE,
		data: {
			...payload,
			document: payload.document || ""
		},
		toastMessage: "Expense added.",
		REQUEST_SUCCESS: types.EMPTY_CURRENT_DOCUMENT,
		next: () => readTimeSheet(jobId, timesheetId)
	});
}

export const resetCurrentDocument = () => ({
	type: types.EMPTY_CURRENT_DOCUMENT
});

export const resetCurrentDocumentModeEdit = () => ({
	type: types.EMPTY_CURRENT_DOCUMENT_EDIT_MODE
});

export const resetCurrentDocumentMultiEditMode = () => ({
	type: types.EMPTY_CURRENT_DOCUMENT_MULTI_EDIT_MODE
});

export function deleteItemHandler({ jobId, timesheet_id, ...payload }) {
	return dispatch => {
		const url = {
			[WORKTIME]: DELETE_WORKTIME,
			[EXPENSE]: DELETE_EXPENSE,
			[OVERTIME]: DELETE_WORKTIME
		};
		return dispatch(
			AxiosHelper.__post({
				url: url[payload.type],
				data: { ...payload, timesheet_id },
				toastMessage: `${payload.type} deleted.`,
				next: () => readTimeSheet(jobId, timesheet_id)
			})
		).then(() => dispatch(toggleDeleteModal()));
	};
}

export function acceptAll(timesheet_id, job_id) {
	return AxiosHelper.__post({
		url: ACCEPT_TIMESHEET,
		data: { timesheet_id },
		isModal: true,
		toastMessage: "The timesheet has been approved! ",
		next: () => readTimeSheet(job_id, timesheet_id)
	});
}

export function sendDisputedList(data, job_id, isClient) {
	const isApproved =
		!_get(data, "expenses.refused.length") &&
		!_get(data, "worktimes.refused.length");
	const url = isClient
		? ACCEPT_DISPUTE_TIMESHEET
		: ACCEPT_REFUSE_DISPUTED_TIMESHEET;
	let message = "Timesheet disputed!";
	if (!isClient) {
		message =
			"The changes have been saved and the timesheet is now in mediation!";
		if (isApproved)
			message =
				"The changes have been saved and the timesheet is now approved!";
	}

	return (dispatch, getState) => {
		if (isClient) {
			let month = _get(getState(), "timesheet.data.month", {});
			let accepted_expenses = _filter(
				month.expenses,
				item => !_find(data.expenses.disputed, { expense_id: item._id })
			);
			let accepted_worktime = _filter(
				month.worktimes,
				item => !_find(data.worktimes.disputed, { worktime_id: item._id })
			);
			data.expenses.accepted = accepted_expenses.map(item => item._id);
			data.worktimes.accepted = accepted_worktime.map(item => item._id);
		}
		return dispatch(
			AxiosHelper.__post({
				url: url,
				toastMessage: message,
				next: () => readTimeSheet(job_id, data.timesheet_id),
				isModal: true,
				loadingText: isApproved ? "Submitting changes..." : null,
				data: data
			})
		);
	};
}

export function submitTimesheet(timesheet_id, job_id) {
	return AxiosHelper.__post({
		url: SUBMIT_TIMESHEET,
		data: { timesheet_id },
		isModal: true,
		toastMessage: "The timesheet has been submitted!",
		next: () => readTimeSheet(job_id, timesheet_id)
	});
}

export function updateAmounts(response) {
	return {
		type: types.UPDATE_COMPUTED,
		...response
	};
}

function computeTimesheet() {
	return (dispatch, getState) => {
		const worktimes = _get(getState(), "timesheet.data.month.worktimes", []);
		const expenses = _get(getState(), "timesheet.data.month.expenses", []);
		const timesheet_id = _get(getState(), "timesheet.data._id");
		let disputedWorktimes = worktimes.reduce((acc, item) => {
			if (item.disputed) {
				acc.push({
					_id: item._id,
					hours: item.back_hours,
					rate: item.rate
				});
			}

			return acc;
		}, []);
		let disputedExpenses = expenses.reduce((acc, item) => {
			if (item.disputed)
				acc.push({
					_id: item._id,
					amount: item.price
				});
			return acc;
		}, []);
		return dispatch(
			AxiosHelper.__post({
				url: COMPUTE_TIMESHEET,
				data: {
					timesheet_id,
					dispute_items: {
						worktimes: disputedWorktimes,
						expenses: disputedExpenses
					}
				},
				next: response => updateAmounts(response)
			})
		);
	};
}

export function getTimesheetDownloadPdf(data) {
	return {
		type: types.CATCH_NOTIFICATIONS_TIMESHEETS_PDF,
		data
	};
}
export function getTimesheetDownloadXls(data) {
	return {
		type: types.CATCH_NOTIFICATIONS_TIMESHEETS_XLS,
		data
	};
}

export function setFileForm(fields) {
	return {
		type: types.SET_FILE_FORM,
		fields
	};
}

export function setFieldIndex(index) {
	return {
		type: types.SET_FIELD_INDEX,
		index
	};
}

export function setIsEditedTimesheetAfterResubmit(data) {
	return {
		type: types.CATCH_NOTIFICATIONS_EVENT_TIMESHEET_RESUBMITTED,
		data
	};
}
export function setIsEditedTimesheetAfterDisputed(data) {
	return {
		type: types.CATCH_NOTIFICATIONS_EVENT_TIMESHEET_DISPUTED,
		data
	};
}
