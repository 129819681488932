/*
		All Features endpoints
*/
export const GET_ALL_FEATURES_URL = "/api/companies/get-global-features";
export const GET_USERS_AND_GROUPS = "/api/common/filter-settings";
/*
		Change password endpoint
 */
export const CHANGE_PASSWORD = "/api/users/change-password";
export const RESET_TPASSWORD = "/api/users/reset-password";
export const CHECK_PASSWORD_EXPIRATION = "/api/users/reset-password-data";
export const RECOVER_PASSWORD = "/api/users/recover-password";

/*
		 Common APIs
 */
export const VAT_CHECKER_API = "/api/common/vat-checker";
export const GET_LANGUAGES = "/api/common/get-languages";
export const GET_COUNTRIES = "/api/common/get-countries";
export const PRE_SIGNED_URL = "/api/common/pre-signed-url";
export const PRE_SIGNED_URL_OFFLINE = "/api/common/pre-signed-url-offline";
export const FILTER_SETTINGS_ENDPOINT = "/api/common/filter-settings";
export const FILTER_SETTINGS_SITES = "/api/common/new-filter-settings";
export const CONTRACTS_DATA_BY_COLUMN_ENDPOINT =
	"/api/common/new-filter-settings";
export const ADD_MANAGER_TS_PHONE =
	"/api/users/change-manager-timesheet-phone-number";
export const RECAPTCHA_VERIFY_ENDPOINT = "/api/common/recaptcha";

/*
		User Signup
*/
export const SIGN_UP = "/api/users/sign-up";
export const SIGN_IN = "/api/users/sign-in";
export const GET_MY_PROFILE = "/api/users/get-my-profile";
export const LOGOUT = "/api/users/logout";
export const CREATE_TOKEN = "/api/users/create-token";
export const ADD_NOTE = "/api/users/add-note";
export const EDIT_NOTE = "/api/users/edit-note";
export const DELETE_NOTE = "/api/users/delete-note";
export const ADD_NOTE_BID = "/api/bids/add-note";
export const GET_NOTE = "/api/notes/view-note-details";

/*
		Users list endpoints
 */
export const GET_USER_LIST_URL = "/api/companies/get-users-list";
export const ASSIGN_ROLE_TO_USER_URL = "/api/companies/assign-role-to-user";
export const REVOKE_ROLE_FROM_USER_URL = "/api/companies/revoke-role-from-user";
/*
		Role list endpoints
 */
export const GET_ROLE_LIST_URL = "/api/companies/get-role-list";
export const CREATE_ROLE_IN_COMPANY_URL =
	"/api/companies/create-role-in-company";
export const REMOVE_ROLE_FROM_COMPANY_URL =
	"/api/companies/remove-role-from-company";
export const ASSIGN_FEATURES_TO_ROLE_URL =
	"/api/companies/assign-features-to-role";
export const REVOKE_FEATURES_FROM_ROLE_URL =
	"/api/companies/revoke-features-from-role";
export const AVAILABLE_FEATURES = "/api/companies/available-features";
export const TRACK_IDENTITY = "/api/companies/track-identity";
/*
		Invite user endpoints
 */
export const INVITE_USER = "/api/companies/invite-user";
export const CHECK_INVITATION = "/api/companies/check-invitation";
export const CHECK_EMAIL_INVITATION = "/api/companies/check-email-invitation";
export const CHECK_PHONE = "/api/common/check-phone";
export const ANSWER_INVITATION = "/api/companies/answer-invitation";
export const RESEND_INVITATION = "/api/companies/resend-invitation";
export const EDIT_USER_ROLE = "/api/companies/edit-user-role";
export const BLOCK_USER_ENDPOINT = "/api/companies/block-user";
export const UNBLOCK_USER_ENDPOINT = "/api/companies/unblock-user";
export const CHOOSE_PASSWORD = "api/users/choose-password";
export const CHECK_ACTIVATE_ACCOUNT = "/api/users/check-activate-account";
export const POKE_CANDIDATE_ENDPOINT = "api/poke/poke-candidate";
export const DELETE_INVITATION = "api/companies/delete-invitation";
/*
		Company API
 */
export const HTTP_GET_CURRENT_COMPANY = "/api/companies/get-my-current-company";
export const HTTP_GET_COMPANY_SITE = "/api/companies/view-company-site";
export const HTTP_UPDATE_COMPANY_ADRESSE =
	"/api/companies/update-my-company-address";
export const HTTP_UPDATE_COMPANY_ADDITIONAL_INFO =
	"/api/companies/update-my-company-additional-info";
export const HTTP_SWITCH_COMPANY = "/api/users/switch-company";
export const GROUP_LIST = "/api/companies/list-group";
export const DEPARTMENTS_LIST = GROUP_LIST;
export const UPDATE_GROUP = "/api/companies/update-group";
export const CREATE_GROUP = "/api/companies/create-group";
export const CREATE_DEPARTMENT = CREATE_GROUP;
export const UPDATE_DEPARTMENT = UPDATE_GROUP;
export const LIST_FEATURE_PACKS = "/api/companies/list-feature-packs";
export const UPDATE_FEATURE_PACKS = "/api/companies/update-feature-packs-user";
export const SEARCH_USER = "/api/companies/search-user";
export const UPDATE_PROFILE = "/api/users/update-profile";
export const HIREME_SETTINGS = "/api/common/settings-hireme";
export const CHANGE_COMPANY_STATUS_ENDPOINT = "/api/companies/change-status";
export const UPDATE_COMPANY_ADDRESS =
	"/api/companies/update-my-company-address";
export const DELETE_DEPARTMENT_ENDPOINT = "api/companies/remove-group";
export const TRANSFERT_OWNERSHIP_ENDPOINT =
	"api/companies/transfer-company-ownership";
/*
 Activate Account
 */
export const ACTIVATE_ACCOUNT = "/api/users/activate-account";
export const RESEND_ACTIVATE_EMAIL = "/api/users/resend-activation-email";

export const INTERACTIONS_FILTER_SETTINGS = "/api/common/new-filter-settings";

/*
		Account Payment
*/
export const ACCOUNT_PAYMENT = "/api/payment/make-payment";
export const LIST_CARDS = "/api/payment/list-cards";
export const WIRE_TRANSFER = "/api/payment/wire-transfer";
export const PAY_IN = "/api/payment/pay-in";

/*
		Processes endpoints
*/
export const GET_PROCESSES = "/api/poke/active-processes";
export const ALL_PROCESSES = "/api/poke/all-processes";

/*
		CV endpoints
*/

export const UPDATE_CV = "/api/cv/update-content";
export const READ_MY_PROFILE = "/api/cv/read-my-profile";
export const VIEW_PROFILE = "/api/cv/view-profile";
export const CREATE_MY_PROFILE = "/api/cv/create-my-profile";
export const UPDATE_PROFILE_ADDITIONAL_INFO =
	"/api/cv/update-profile-additional-info";
export const UPDATE_USER_STATUS = "/api/cv/change-user-status";
export const ADD_SKILLS = "/api/cv/add-skills";
export const UPDATE_SKILLS = "/api/cv/update-skills";
export const MY_SKILLS = "/api/cv/my-skills";
export const PROPOSE_SKILL = "/api/cv/propose-skill";
export const ADD_EXPERIENCE = "/api/cv/add-experience";
export const UPDATE_EXPERIENCE = "/api/cv/update-experience";
export const REMOVE_EXPERIENCE = "/api/cv/remove-experience";
export const ADD_EDUCATION = "/api/cv/add-education";
export const UPDATE_EDUCATION = "/api/cv/update-education";
export const REMOVE_EDUCATION = "/api/cv/remove-education";
export const MY_EDUCATIONS = "/api/cv/my-educations";
export const MY_ADDITIONALINFO = "/api/cv/my-additional-info";
export const MY_FUNCTIONS = "/api/cv/my-functions";
export const UPDATE_LANGUAGES = "/api/cv/update-languages";
export const UPDATE_INTERNAL_DATA =
	"/api/companies/update-internal-profile-data";
export const MY_LANGUAGES = "/api/cv/my-languages";
export const LIST_SKILLS = "/api/cv/list-skills";
export const GUEST_LIST_SKILLS = "/api/cv/guest-list-skills";
export const MY_EXPERIENCES = "/api/cv/my-experiences";
export const LIST_SECTORS = "/api/cv/list-sectors";
export const UPDATE_FUNCTIONS = "/api/cv/update-functions";
export const TRACK_STEP_URL = "/api/cv/track-step";
export const JOB_CV = "/api/jobs/job-cv";
export const UPLOAD_CV = "api/cv/upload-cv";
export const VIEW_CANDIDATE_ENDPOINT = "/api/cv/view-candidate";
export const OVERVIEW_ENDPOINT = "/api/cv/profile-overview";
export const GET_JOBS_BY_VACANCY = "api/jobs/job-data";
export const GET_JOBS_BY_REQUEST = "api/jobs/job-data";
export const SAVE_PREFERRED_LOCATION_ENDPOINT =
	"/api/cv/update-preferred-location";
export const SAVE_PREFERENCES_ENDPOINT = "/api/cv/update-preference";
export const GET_PROFILE_OVERVIEW = "/api/cv/profile-overview";
export const SET_AS_PRIMARY = "/api/companies/set-as-primary";
export const PROFILE_HOSTORY_LIST = "/api/users/user-history";

/*
		JOB endpoints
 */
export const CREATE_NEW_JOB = "/api/jobs/create-job-general-description";
export const GLOBAL_LANGUAGES = "/api/common/get-languages";
export const LIST_CATEGORIES = "/api/cv/list-sectors";
export const LIST_FUNCTIONS = "/api/cv/list-functions";
export const GUEST_LIST_FUNCTIONS = "/api/cv/guest-list-function";
export const ADD_BOT_FUNCTION = "/api/cv/add-bot-function";
export const CREATE_NEW_JOB_PRICING = "/api/jobs/create-job-pricing";
export const GET_LAST_JOB = "/api/jobs/get-last-job";
export const POST_JOB = "/api/jobs/publish-job";
export const MY_JOBS_LIST = "/api/jobs/my-list-jobs";
export const ARCHIVED_JOBS_LIST = "/api/jobs/get-archived-job";
export const DELETE_JOB = "/api/jobs/delete-job";
export const FILL_JOB = "/api/jobs/fill-job";
export const CLONE_JOB = "/api/jobs/clone-job";
export const PUBLISHED_JOB = "/api/jobs/publish-job";
export const EDIT_JOB_LOCATION = "/api/jobs/edit-job-location";
export const UNPUBLISH_JOB = "/api/jobs/un-publish-job";
export const EDIT_JOB = "/api/jobs/edit-job";
export const UPDATE_PREFERENCE = "/api/cv/update-preference";
export const MY_PREFERENCE = "/api/cv/my-preference";
export const UPDATE_AVATAR = "/api/cv/update-avatar";
export const MANAGE_AVATAR_UPLOAD = "/api/common/manage-upload-avatar";
export const CV_PROGRESS = "/api/cv/progress-cv";
export const SEARCH_PROFILE_ENDPOINT = "/api/cv/search-profile";
export const NOTES_DATA_ENDPOINT = "/api/note/my-list-notes";
export const CONTRACTS_DATA_ENDPOINT =
	"/api/contracts/list-contract-propositions";
export const GET_ACTIVE_JOBS = "/api/jobs/get-active-jobs";
export const SEND_REQUEST = "/api/users/send-a-request";
export const SEND_OFFER = "/api/offer/send-offer";
export const CREATE_JOB_QUESTIONNAIRE = "/api/jobs/create-job-questionnaire";
/*
 BIDS endpoints
 */
export const LIST_BIDS = "/api/bids/list-bids";
export const INVITE_USER_TO_INTERVIEW =
	"/api/interviews/invite-user-to-interview";
export const EDIT_INTERVIEW = "/api/interviews/edit-interview";
export const INTERVIEW_ACCEPTATION = "/api/interviews/interview-data";
export const ACCEPT_INTERVIEW = "/api/interviews/accept-interview";
export const REFUSE_INTERVIEW = "/api/interviews/refuse-interview";
export const LIST_INTERVIEW = "/api/interviews/list-interviews";
export const GET_BID = "/api/bids/bid-data";
export const CANCEL_BID_ENDPOINT = "/api/bids/cancel-bid";
export const CANCEL_INTERVIEW = "/api/interviews/cancel-interview";
export const LIST_BIDS_ENDPOINT = "/api/bids/my-list-bids";
export const DECLINE_INTERVIEW = "/api/interviews/decline-interview";
export const REJECT_APPLICATION_ENDPOINT = "/api/bids/reject-bid";

// request view kanban
export const REQUESTS_OVERVIEW = "/api/jobs/get-requests-overview";
export const REQUEST_DATA_ENDPOINT = "/api/jobs/my-list-jobs";
export const REQUEST_VIEW_KANBAN_SETTINGS = "/api/jobs/kanban-view-setting";
export const REQUEST_VIEW_KANBAN_DATA = "/api/jobs/pipeline-data";
export const REQUEST_VIEW_KANBAN_MOVE_ELEMENT = "/api/jobs/move-element";

/*
		Search jobs
*/
export const SEARCH_JOB = "/api/jobs/search-job";
export const PREVIEW_JOB = "/api/jobs/preview-job";
export const TRACK_VIEWS = "/api/common/track-views";
export const HIDE_PROFILE_ENDPOINT = "/api/companies/hide-user";
export const GUEST_SEARCH_JOB = "/api/jobs/guest-search-job";
export const GUEST_PREVIEW_JOB = "/api/jobs/guest-preview-job";
/*
	Propose bid
*/
export const PROPOSE_BID = "/api/bids/propose-bid";
export const ADJUST_BID = "/api/bids/adjust-bid";

/*
	Contract endpoints
*/
export const DATA_MISSION = "/api/contracts/mission-proposal-data";
export const ACCEPT_MISSION = "/api/contracts/accept-mission";
export const CREATE_MISSION = "/api/contracts/create-mission";
export const CANCEL_MISSION = "/api/contracts/cancel-mission-proposal";
export const EXPIRED_CONTRACT = "/api/contracts/expired-contract";
export const INTERRUPT_MISSION = "/api/contracts/interrupt-mission";
export const EXTEND_MISSION = "/api/contracts/extend-mission";
export const EDIT_MISSION = "/api/contracts/edit-mission";
export const EDIT_MISSION_EXTENSION = "/api/contracts/edit-mission-extension";
export const EDIT_TIMESHEET_MANAGER =
	"/api/contracts/update-mission-manager-ts";
export const LIST_CONTRACTS = "/api/contracts/list-contracts";
export const LIST_PROPOSALS = "/api/contracts/list-proposals";
export const MISSION_PROPOSAL_DATA = "/api/contracts/mission-proposal-data";
export const PROPOSE_MISSION = "/api/contracts/propose-mission";
export const PROPOSE_OTHER_TERMS = "api/contracts/decline-mission-proposal";
export const REFUSE_MISSION = "/api/contracts/refuse-mission";
export const DECLINE_MISSION = "/api/contracts/decline-mission-proposal";
export const LIST_MEMBERS = "/api/cv/list-members";
export const LIST_INTERVIEWRS = "/api/interviews/list-interviewers";
export const LIST_INTERVIEWRS_CALENDAR =
	"/api/interviews/list-interviewers-calendar";
export const LIST_MANAGERS = "/api/contracts/list-manager-timesheet";
export const REFUSE_CONTRACT = "/api/contracts/refuse-mission";
/**
 * Timesheets endpoints
 */
export const READ_TIMESHEET = "/api/timesheet/read-timesheet";
export const SUBMIT_TIMESHEET = "/api/timesheet/submit-timesheet";
export const LIST_TIMESHEET = "/api/timesheet/list-timesheet";
export const TIMESHEET_LIST = "/api/timesheet/timesheet-list";
export const ADD_WORKTIME = "/api/timesheet/add-worktime";
export const ADD_EXPENSE = "/api/timesheet/add-expense";
export const UPDATE_WORKTIME = "/api/timesheet/update-worktime";
export const UPDATE_EXPENSE = "/api/timesheet/update-expense";
export const DELETE_WORKTIME = "/api/timesheet/delete-worktime";
export const DELETE_EXPENSE = "/api/timesheet/delete-expense";
export const DISPUTE_WORKTIME = "/api/timesheet/dispute-worktime";
export const DISPUTE_EXPENSE = "/api/timesheet/dispute-expense";
export const ACCEPT_WORKTIME = "/api/timesheet/accept-worktime";
export const ACCEPT_EXPENSE = "/api/timesheet/accept-expense";
export const ACCEPT_DISPUTED_WORKTIME =
	"/api/timesheet/accept-disputed-worktime";
export const ACCEPT_DISPUTED_EXPENSE = "/api/timesheet/accept-disputed-expense";
export const REFUSE_DISPUTED_WORKTIME =
	"/api/timesheet/refuse-disputed-worktime";
export const REFUSE_DISPUTED_EXPENSE = "/api/timesheet/refuse-disputed-expense";
export const READ_WEEK = "/api/timesheet/read-week";
export const GET_TIME = "/api/common/time";
export const ACCEPT_TIMESHEET = "/api/timesheet/accept-timesheet";
export const ACCEPT_WORKTIMES = "/api/timesheet/accept-worktimes";
export const ACCEPT_EXPENSES = "/api/timesheet/accept-expenses";
export const ACCEPT_DISPUTE_TIMESHEET =
	"/api/timesheet/accept-dispute-timesheet";
export const ACCEPT_ALL_ENTRIES = "/api/timesheet/accept-all-entries";
export const ACCEPT_REFUSE_DISPUTED_TIMESHEET =
	"/api/timesheet/accept-refuse-disputed-timesheet";
export const COMPUTE_TIMESHEET = "/api/timesheet/compute-change";
export const DUPLICATE_WORKTIME = "/api/timesheet/clone-worktime";
export const EXPORT_TIMESHEET_DATA = "/api/timesheet/export-timesheet-data";
export const TRACK_USER_ONBOARDING = "/api/users/track-user-onboarding"; //to be removed
export const SEND_COMMENT = "/api/timesheet/send-comment";
export const RESUBMIT_TIMESHEET = "/api/timesheet/resubmit-timesheet";
export const TRACK_TIMESHEET_EDITION = "/api/timesheet/track-timesheet-edition";
export const VERIFY_ACCESS_CODE = "/api/external/verify-access-code";
export const RESEND_ACCESS_CODE =
	"/api/timesheet/resend-external-manager-access-code";
/*
		Invoice
*/
export const LIST_INVOICES = "/api/invoices/list-invoices";
export const VIEW_INVOICE = "/api/invoices/view-invoice";
export const ADD_PO_NUMBER = "/api/invoices/add-po-number";
export const EDIT_INVOICE = "/api/invoices/edit-invoice";
export const COMPUTE_INVOICE = "/api/invoices/compute-vendor-cost";
// Notifications
export const LIST_NOTIFICATIONS = "/api/notifications/list-notification";
export const NOTIFICATIONS_AUTH = "/api/notifications/auth";
export const READ_NOTIFICATIONS = "/api/notifications/read-notifications";
export const TOTAL_NOTIFICATIONS = "/api/notifications/total-notifications";
export const LATEST_NOTIFICATIONS = "/api/notifications/latest-notifications";
export const NOTIFICATIONS_SETTINGS = "/api/users/setting-notification";

/*
		Dashboard
 */
export const DASHBOARD = "/api/dashboard/my-dashboard";
/*
		Get help
*/
export const ASK_QUESTION_ENDPOINT = "/api/emails/ask-question";
export const REPORT_ERROR_ENDPOINT = "/api/emails/report-error";
export const VIEW_CONTACT_INFO_API = "/api/common/view-contact-information";

export const VIEW_DASHBOARD_INFO_API = "/api/dashboard/authorize";

/*
	Vacancy
*/
export const LIST_POKES_ENDPOINT = "/api/poke/list-pokes";
export const ACCEPT_INTERVIEW_ENDPOINT = "api/interviews/accept-interview";
export const EDIT_INTERVIEW_ENDPOINT = "/api/interviews/decline-interview";
export const APPLY_TO_VACANCY_ENDPOINT = "/api/poke/apply-to-vacancy";
export const GUEST_APPLY_TO_VACANCY_ENDPOINT =
	"/api/poke/guest-apply-to-vacancy";
export const GET_CHANNELS_PERFORMANCE = "/api/jobs/get-campaigns-performance";
export const MANAGE_STEP = "/api/jobs/manage-step";
export const REFER_TO_JOB_ENDPOINT = "/api/poke/refer-friend-to-job";
export const EVALUATIONS_DATA_ENDPOINT = "/api/evaluations/list-evaluations";
export const DELETE_EVALUATION = "/api/evaluations/delete-evaluation";
export const EVALUATIONS_SUMMARY_DATA_ENDPOINT =
	"/api/evaluations/evaluation-summary";

export const EDIT_EVALUATION = "/api/evaluations/edit-evaluation";

/**
		Poke
 */

export const FETCH_POKE_ENDPOINT = "/api/poke/poke-data";
export const CHANGE_POKE_STATUS_ENDPOINT = "/api/poke/change-poke-status";
export const ADD_POKE_NOTE = "/api/poke/add-note";
export const CHANGE_POKABILITY_ENDPOINT = "/api/users/change-pokability-status";
export const CANCEL_POKE_ENDPOINT = "/api/poke/cancel-poke";
export const REMOVE_INTEREST_ENDPOINT = "api/poke/remove-interest";
export const ADD_TALENT_PIPELINE = "api/poke/add-to-talent-pipeline";
export const ACCEPT_OFFER = "/api/offer/accept-offer";
export const REFUSE_OFFER = "/api/offer/refuse-offer";
export const CANCEL_OFFER = "/api/offer/cancel-offer";
export const PROPOSE_ANOTHER_OFFER = "/api/offer/edit-offer";
export const LIST_OFFERS = "/api/offers/list-offers";

/**
		files
 */
export const VIEW_USER_FILES = "/api/users/list-files";

export const BOT_FRONT_SHEET_ITEM_ENDPOINT = "/api/cv/add-bot-front-sheet-item";

export const CREATE_SEARCH_TEMPLATE_ENDPOINT =
	"/api/users/create-search-template";
export const REMOVE_SEARCH_TEMPLATE_ENDPOINT =
	"/api/users/remove-search-template";
export const LIST_SEARCH_TEMPLATES_ENDPOINT = "/api/users/list-search-template";

/**
		shortlist
 */

export const ADD_TO_SHORTLIST_ENDPOINT = "/api/poke/add-to-shortlist";
export const REMOVE_SHORTLIST_ENDPOINT = "/api/poke/remove-from-shortlist";

/**
		DASHBOARD
 */

export const DASHBOARD_DATA_ENDPOINT = "/api/dashboard/dashboard-data";
export const CLOSE_JOB = "/api/jobs/close-job";
export const CANCEL_APPLICATION_ENDPOINT = "/api/poke/cancel-application";

/**
		CURRENCIES
 */

export const GET_CURRENCIES = "/api/admin/get-currencies";
export const LIST_VONQ_CHANNELS = "/api/admin/list-vonq-channels";
export const UPDATE_FEATURE_SETTINGS = "/api/admin/update-feature-settings";

export const FEATURE_SETTINGS = "/api/companies/feature-settings";

// hide job
export const HIDE_JOB = "api/jobs/hide-job";

export const LIST_INDUSTRIES_ENDPOINT = "/api/common/get-list-industries";

export const UPDATE_BILLING_DETAILS = "api/companies/update-billing-details";

export const CREATE_CANDIDATE_ENDPOINT = "/api/companies/create-user";
export const REMOVE_FROM_CANDIDATE_POOL_ENDPOINT =
	"/api/users/remove-from-candidate-pool";
export const REMOVE_FROM_EMAIL = "/api/companies/remove-from-email";
export const LIST_CANDIDATE_POOL = "/api/users/candidate-pool";
export const LIST_PENDING_ACTIVATION_LIST =
	"/api/users/list-pending-activation-users";
export const USER_RESEND_ACTIVATION_EMAIL =
	"/api/users/user-resend-activation-email";
export const API_CANDIDATE_POOL = "/api/users/candidate-pool";
export const UPDATE_PROFILE_PERSONAL_INFO =
	"/api/users/update-profile-personal-info";

export const REQUESTS_INTERVIEWS = "/api/interviews/list-interviews";
export const DELETE_PROFILE = "/api/companies/delete-profile";
export const LIST_APPLICATIONS_ENDPOINT = "/api/poke/get-applications-list";
export const OFFRES_LIST_ENDPOINT = "/api/offers/list-offers";
export const LIST_INTERVIEWS_ENDPOINT = "/api/interviews/list-interviews";

export const CREATE_CAMPAIGN = "/api/companies/create-campaign";

// job views

export const JOBS_VIEWS_TRACK = "/api/jobs/track-views";

export const LIST_CONTRACT_PROPOSITIONS_ENDPOINT =
	"/api/contracts/list-contract-propositions";
export const GET_LIST_COMPANY = "/api/companies/get-list-company";

export const JOBS_PERFORMANCE = "/api/companies/jobs-performance";

export const EXPORT_DATA = "/api/companies/export-data";

export const MOVE_TIER = "/api/companies/move-tier-consultancies";

export const SETTINGS_TIER = "/api/companies/feature-settings";

export const SEARCH_TIER = "/api/companies/search-tiers-consultancies";
export const UPDATE_FEATURE_SETTINGS_TIER =
	"/api/companies/update-feature-settings";

export const MANAGE_DOCUMENTS = "/api/companies/manage-document";
export const GET_DOCUMENTS = "/api/companies/get-manage-document";
export const UPDATE_DOCUMENT_SETTINGS =
	"/api/companies/update-document-settings";

export const REQUIRED_DOCUMENTS = "/api/timesheet/required-documents";

export const PAGE_SETTINGS = "/api/cms/page-settings";
export const MANAGE_CMS_ACCESS = "/api/cms/manage-cms-access";

//Dynamic Content

export const MANAGE_DYNAMIC_CONTENT = "/api/companies/manage-dynamic-content";
export const GET_DYNAMIC_CONTENT = "/api/companies/get-dynamic-content";

export const DYNAMIC_CONTENT_CREATE_NEW_CONDITION =
	"/api/companies/create-dynamic-content-condition";
// Vonq

export const GET_LIST_CHANNELS = "/api/companies/get-list-channels";
export const GET_TAXONOMIES = "/api/companies/get-taxonomies";

//Mission details

export const MISSION_DETAILS = "/api/contracts/mission-details";
export const MISSION_EXTENSIONS = "/api/contracts/get-mission-extensions-list";
export const MISSION_HISTORY = "/api/contracts/get-mission-history";
export const UPDATE_MISSION_MANAGER =
	"/api/contracts/update-mission-manager-ts";

export const UPDATE_USER_SETTING = "/api/users/update-user-setting";

export const VIEW_USER_NOTES = "/api/users/view-user-notes";

export const FILTER_SETTINGS_NOTES = "/api/common/filter-settings";
export const FILTER_SETTINGS_CONTRACTS = "/api/common/filter-settings";
export const VIEW_USER_HISTORY = "/api/users/user-history";

export const MANAGE_MISSION_TAGS = "/api/contracts/manage-mission-tags";

export const UPDATE_MISSION_TAG_OPTIONS =
	"/api/contracts/update-mission-tag-options";
export const ALLOCATE_MISSION_TAGS = "/api/contracts/allocate-mission-tags";
export const GET_MISSION_TAGS = "/api/contracts/get-mission-tags";
export const UPDATE_SOCIAL_MEDIA_PICTURE =
	"/api/companies/update-social-media-picture";
export const SEND_VACANCY = "/api/jobs/send-vacancy";

export const LIST_LATEST_INTERVIEWS = "/api/interviews/list-latest-interviews";

export const ADD_NEW_STEP = "/api/jobs/add-new-step";

export const MOVE_ELEMENT = "/api/jobs/move-element";
export const KANBAN_VIEW_SETTING = "/api/jobs/kanban-view-setting";
export const PIPELINE_DATA = "/api/jobs/pipeline-data";
export const CHANGE_POKABILITY_STATUS = "/api/poke/change-poke-status";
export const REQUALIFY_APPLICATION = "/api/poke/requalify-application";
export const REJECTED_LIST = "/api/poke/rejected-list";
export const TOGGLE_NOTIFICATION = "/api/jobs/toggle-notification";

export const LIST_ACTIVITIES = "/api/common/list-activities";
export const SHORTLIST_CANDIDATE = "/api/poke/shortlist-candidate";
export const NOTIFY_USERS = "/api/poke/notify-users";
export const JOBS_OVERVIEW = "/api/jobs/job-overview";
export const LIST_PENDING_APPLICATIONS_ENDPOINT =
	"/api/poke/get-applications-list";

export const LIST_OVERDUE_JOBS_ENDPOINT = "/api/jobs/my-list-jobs";

export const LIST_OPEN_JOBS_ENDPOINT = "/api/jobs/my-list-jobs";

export const LIST_WEATHER_ENDPOINT =
	"https://api.weatherapi.com/v1/forecast.json";

export const VIEW_COMPANY_ENDPOINT = "/api/companies/view-company";
export const LIST_SUPPLIER_INVITATIONS_ENDPOINT =
	"/api/companies/list-supplier-invitations";
export const INVITE_TO_SUPPLIER_LIST_ENDPOINT =
	"/api/companies/invite-to-supplier-list";
export const UPDATE_COMPANY_EXPERTISE_ENDPOINT =
	"/api/companies/update-company-expertise";
export const UPDATE_COMPANY_LOCATIONS_ENDPOINT =
	"/api/companies/update-company-locations";
export const CHANGE_SUPPLIER_INVITATION_STATUS_ENDPOINT =
	"/api/companies/change-supplier-invitation-status";
export const VIEW_SUPPLIER_INVITATION_ENDPOINT =
	"/api/companies/view-supplier-invitation";
export const CHECK_ACTIVATE_ACCOUNT_ENDPOINT =
	"/api/users/check-activate-account";
export const NEW_FILTER_SETTINGS = "/api/common/new-filter-settings";
export const LIST_TASKS = "/api/task/get-list-tasks";
export const SEARCH_SUPPLIERS = "/api/companies/search-suppliers";
export const CREATE_TASK = "/api/task/create-task";
export const VIEW_TASK = "/api/task/view-task";

export const TASK_MARK_AS_COMPLETE = "/api/task/mark-as-completed";
export const TASK_DELETE = "/api/task/delete-task";

export const LIST_UPCOMING_MISSIONS = "/api/contracts/list-upcoming-missions";
export const LIST_SUPPLIERS_ENDPOINT = "/api/jobs/supplier-overview";
export const WELCOME_PAGE_MY_SUPPLIERS = "";
export const VACANCY_RATIO = "/api/jobs/vacancy-ratio";

export const TIMESHEET_REPORTING_SETTINGS = "/api/companies/reporting-settings";
export const SAVE_FEATURE_TIMESHEET_SETTINGS =
	"/api/company/manage-reporting-settings";

export const MULTIPLE_INVITATION_TO_SUPPLIERS_LIST =
	"/api/companies/move-tier-consultancies";

export const RESEND_SUPPLIET_INVITATION =
	"/api/companies/resend-supplier-invitation";
export const SIGN_CONTRACT_ENDPOINT = "/api/contracts/sign-contract";
export const UPDATE_REMINDER = "/api/task/update-reminder";
export const EDIT_TASK = "/api/task/edit-task";
export const LIST_CANDIDATE_FILES_ENDPOINT = "/api/users/list-candidate-files";
export const UPLOAD_CANDIDATE_FILES_ENDPOINT =
	"/api/users/upload-candidate-files";
export const DELETE_CANDIDATE_FILE_ENDPOINT =
	"/api/users/delete-file-candidate";
export const CHANGE_FILE_VISIBILITY_ENDPOINT =
	"/api/users/change-file-visibility";

export const CREATE_JOB_BOARD_CONTRACT_ENDPOINT =
	"/api/companies/create-job-board-contract";
export const LIST_JOB_BOARD_CONTRACT_ENDPOINT =
	"/api/companies/list-job-board-contracts";
export const SET_FILE_PRIMARY = "/api/companies/set-as-primary";
export const UPDATE_INTERNAL_PROFILE_DATA =
	"/api/companies/update-internal-profile-data";
export const MERGE_PROFILES = "/api/companies/merge-profile";
export const ADD_ALL_TO_INTERNAL = "/api/companies/add-all-to-internal-data";
export const ADD_PROFILE_TO_POOL = "/api/companies/add-to-pool";
export const EDITION_HISTORY = "/api/companies/list-internal-edition-history";
export const LIST_PROFILE_TAGS = "/api/companies/list-profile-tags";
export const CANDIDATE_DATA_BY_COLUMN_ENDPOINT =
	"/api/common/new-filter-settings";
// export const CONTRACTS_DATA_BY_COLUMN_ENDPOINT = "/api/common/filter-settings";
export const FILTER_SETTING_ENDPOINT = "/api/common/new-filter-settings";
export const CANDIDATE_FILTER_DATA = "/api/cv/search-profile";
export const LIST_COMMUNITY_CRITERIA_ENDPOINT =
	"/api/interviews/list-interviews";
export const VACANCIES_OVERVIEW = "/api/jobs/get-vacancies-overview";
export const GET_CONTRACT_DETAILS =
	"/api/companies/get-job-board-product-details";
export const DELETE_CONTRACT = "/api/companies/delete-job-board-contract";
export const VALIDATE_CONTRACT_POSTING_REQUIREMENTS =
	"/api/common/validate-posting-requirements";
export const GET_POSTING_REQUIREMENTS_AUTOCOMPLETE_DATA =
	"/api/common/auto-complete-posting-requirements";
export const ADD_COMPANY_SITE = "/api/companies/add-company-site";
export const UPDATE_SITE = "/api/companies/update-site";
export const DELETE_SITE = "/api/companies/delete-company-site";
export const UPDATE_EB_SETTINGS =
	"/api/companies/update-employer-branding-settings";
export const GET_PROACTIVE_APPLICATIONS_LIST =
	"/api/poke/get-applications-list";
export const ADD_NEW_CONTRACTS_GROUP =
	"/api/companies/create-job-board-contract-group";
export const GET_CONTRACTS_GROUPS_LIST =
	"/api/companies/list-job-board-contract-groups";
export const UPDATE_CONTRACTS_GROUP = "/api/companies/edit-job-contract-group";
export const DELETE_CONTRACTS_GROUP =
	"/api/companies/delete-job-contract-group";

/* Permanent Apis */
export const COMPANIES_LIST = "/api/companies/list-companies";
export const MANAGE_FOLLOW_COMPANY = "/api/companies/manage-follow-company";
export const USER_ACTIVE_PROCESSES = "/api/users/count-user-active-processes";
export const USER_DELETE_ACCOUNT = "/api/users/delete-user-account";
export const DELETE_GDPR_PROFILE = "/api/DELETE_GDPR_PROFILE";
export const GET_GDPR_PERMANENT_INFORMATIONS = "/api/users/view-user-history";
export const FETCH_GDPR_LIST = "/api/users/list-revoked-users";
export const DELETE_GDPR_PROFILES = "/api/users/delete-user-history";

/* onboarding */
export const EB_HELP_CENTER_DATA = "/api/eb/help-center";
export const USER_ONBOARDING_STEPS = "/api/users/view-user-onboarding-steps";

/*  evaluation module */
export const CREATE_EVALUATION_TEMPLATE =
	"/api/evaluation/create-evaluation-template";
export const EVALUATE_CANDIADTE_ENDPOINT = "/api/evaluations/add-evaluation";
export const GET_EVALUATION_TEMPLATES_ENDPOINT =
	"/api/evaluation/list-evaluation-templates";
export const VIEW_EVALUATION_TEMPLATE_ENDPOINT =
	"/api/evaluation/view-evaluation-template";
export const EDIT_EVALUATION_TEMPLATE =
	"/api/evaluation/edit-evaluation-template";
export const GET_EVALUATION_TEMPLATE =
	"/api/evaluation/view-evaluation-template";
export const DUPLICATE_EVALUATION_TEMPLATE =
	"/api/evaluation/clone-evaluation-template";
export const DELETE_EVALUATION_TEMPLATE =
	"/api/evaluation/delete-evaluation-template";
export const LIST_EVALUATION_TEMPLATES =
	"/api/evaluation/list-evaluation-templates";
export const EDIT_CANDIDATE_EVALUATION = "/api/evaluations/edit-evaluation";
export const EVALUATE_CANDIADTE_VIEW_DETAILS =
	"/api/evaluation/view-evaluation";
export const ADD_TIMESHEET_ENTRIES = "/api/timesheet/add-timesheet-entries";

export const GET_SINGLE_CANDIDATE_DATA = "/api/cv/parse-document";
export const ADD_PROCESS_USERS = "/api/companies/process-users";
export const GET_PROCESS_USERS = "/api/common/filter-settings";
export const QUESTIONNARE_APPLY_VACANCY = "/api/poke/apply-to-vacancy";

export const VALIDATE_PUBLISH_VACANCY = "/api/companies/validate-campaign";

// Messaging Tool

export const GET_AUTHENTICATION_DETAILS = "/api/messaging-tool/filter-options";
export const CUSTOM_AUTHENTICATION =
	"/api/messaging-tool/custom-authentication";
export const SYNC_EMAILS = "/api/messaging-tool/sync-emails";
// email template module
export const LIST_EMAIL_TEMPLATES = "/api/messaging-tool/list-email-templates";
export const GET_EMAIL_TEMPLATE = "/api/messaging-tool/get-email-template";
export const MANAGE_EMAIL_TEMPLATE =
	"/api/messaging-tool/manage-email-templates";
export const DUPLICATE_EMAIL_TEMPLATE =
	"/api/evaluation/clone-evaluation-template";
export const DELETE_EMAIL_TEMPLATE =
	"/api/evaluation/delete-evaluation-template";

export const DRAFT_EMAIL = "/api/messaging-tool/draft-message";
export const SEND_EMAIL = "/api/messaging-tool/send-message";
export const REPLY_TO_EMAIL = "/api/messaging-tool/reply-to-message";
export const FORWARD_EMAIL = "/api/messaging-tool/forward-message";
export const MESSAGING_TOOL_FILTER_OPTIONS =
	"/api/messaging-tool/filter-options";
export const LIST_EMAILS = "/api/messaging-tool/list-emails";
export const EMAIL_DETAILS = "/api/messaging-tool/view-message";
export const EMAIL_ACTION = "/api/messaging-tool/mailbox-actions";
export const DOWNLOAD_ATTACHMENT =
	"/api/messaging-tool/download-message-attachment";
// email signature
export const GET_EMAIL_SIGNATURE = "/api/messaging-tool/user-email-signature";
export const UPDATE_EMAIL_SIGNATURE =
	"/api/messaging-tool/user-email-signature";
export const VALIDATE_LOCATION_ENDPOINT = "/api/common/validate-location";
export const MANAGE_CUSTOM_FIELDS_MANAGER_ENDPOINT =
	"/api/companies/manage-custom-fields-manager";

export const ADD_COMPANY_VAT_ENDPOINT = "/api/companies/add-company-vat";
export const LIST_JOB_FILES_ENDPOINT = "/api/jobs/list-job-files";
export const MANAGE_FILE_ENDPOINT = "/api/jobs/manage-file";

export const ENHANCE_JOB_POSTING_ENDPOINT = "/api/job/enhance-job-posting";
export const GENERATE_JOB_POSTING_ENDPOINT = "/api/job/generate-job-posting";
export const ENHANCE_SELECTED_TEXT_ENDPOINT = "/api/job/enhance-selected-text";
export const EXTRACT_TIMESHEET =
	"/api/timesheet/extract-timesheet-attachment-data";
