import React from "react";
import NoteTypeSelect from "./NoteType";
import ApplicationSelect from "./Application";
import MentionBox from "common/MentionsInput";
import {
	CancelButton,
	ActionButton
} from "modules/vacancy/components/vacancyView/CandidateAction/components/styles.js";
import CandidateSelect from "./CandidateSelect";

function AddNoteRendrer({ children }) {
	return <React.Fragment>{children}</React.Fragment>;
}
AddNoteRendrer.Select = NoteTypeSelect;
AddNoteRendrer.ApplicationSelect = ApplicationSelect;
AddNoteRendrer.MentionBox = MentionBox;
AddNoteRendrer.CancelButton = CancelButton;
AddNoteRendrer.ActionButton = ActionButton;
AddNoteRendrer.CandidateSelect = CandidateSelect;

export { AddNoteRendrer };
