import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router";
import FormField from "common/FormField";
import StyledSelect from "common/StyledSelect";
import { GetBUSelect } from "../bum/BUMSecondStep";
import {
	LEVEL,
	PROJECT_MANAGER,
	emailRegex,
	TYPE_PDF,
	TYPE_DOC,
	TYPE_DOCX,
	CV_SIZE_LIMIT
} from "config";

import {
	getSectorList,
	getFunctionList,
	checkEmailInvitation
} from "../../redux/actions";
import { prepareForSelect } from "common/Functions";
import { getPolicyRoute } from "../../../../common/privacyPolicy/components/PolicyContainer";
import S3Uploader from "common/S3Uploader";
import toaster from "common/Toaster";

import { PRE_SIGNED_URL_OFFLINE } from "config/api-endpoints";
import { v4 as uuid4 } from "uuid";

class ConsultantStep extends Component {
	state = {
		rawSectors: [],
		sectors: [],
		subcategories: [],
		functions: [],
		fileUrl: "",
		id: uuid4(),
		error: false
	};

	componentDidMount() {
		const { group, isManager } = this.props;
		getSectorList().then(({ rawSectors, sectors }) => {
			this.setState({ rawSectors, sectors });
		});

		if (group && isManager) {
			this.props.change("name", { value: group.id, label: group.name });
		}
	}

	getSubCategories = cat => {
		this.setState({
			subcategories: prepareForSelect(cat.children),
			functions: []
		});
		this.props.change("function", "");
		this.props.change("seniority", "");
	};

	getFunctions = sector => {
		getFunctionList(sector.value, true).then(data => {
			this.setState({ functions: data });
		});
		this.props.change("function", "");
		this.props.change("seniority", "");
	};

	resetSeniority = () => {
		this.props.change("seniority", "");
	};

	submitForm = ({
		email,
		first_name,
		last_name,
		name,
		sector,
		function: func,
		seniority
	}) => {
		let data = {
			email,
			first_name,
			last_name,
			role_name: PROJECT_MANAGER,
			group_id: name.value,
			sector: sector.value,
			function: func.value,
			seniority: seniority.value,
			business_unit: this.business_unit,
			doc: this.state.fileUrl
		};

		if (!this.state.fileUrl) {
			return this.setState({ error: true });
		}
		return checkEmailInvitation({ email }).then(() => {
			this.props.sendInvitation(data);
		});
	};

	onChangeBU = business_unit => {
		this.business_unit = business_unit;
	};

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	progressComponent = ({ percentCompleted }) => {
		if (percentCompleted) {
			return (
				<div className="file-progress-box">
					<div className="size">{`${percentCompleted.toFixed(0)}%`}</div>
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	};

	onUploadSuccess = data => {
		this.setState({ fileUrl: data.url });
	};

	resetFile = () => {
		this.onUploadSuccess(null);
	};

	renderUploader = () => (
		<>
			<S3Uploader
				accept={[TYPE_PDF, TYPE_DOC, TYPE_DOCX]}
				id="cv-upload"
				multiple={false}
				folder={"candidate"}
				autoSubmit={true}
				onSuccess={this.onUploadSuccess}
				onClose={this.resetFile}
				resetOnSuccess={false}
				isLogin={true}
				onError={error =>
					toaster.danger(error, { id: "uploader-error", duration: 7 })
				}
				progressComponent={this.progressComponent}
				text={{
					choose: "Upload CV",
					label: "CV document",
					placeholder: "Upload a file from your computer.",
					icon: { text: "CV", class: "fa fa-file-o" }
				}}
				preSignedUrl={PRE_SIGNED_URL_OFFLINE}
				preSignedData={{ id: this.state.id }}
				sizeLimit={CV_SIZE_LIMIT}
			/>
			<div className="help-block inline-error">
				{this.state.error && !this.state.fileUrl && (
					<>Please upload a file before submitting</>
				)}
			</div>
		</>
	);

	render() {
		const { rawSectors, sectors, subcategories, functions } = this.state;
		const { role_name, is_member, handleSubmit, BUList } = this.props;

		return (
			<div id="personal-info" className="content">
				<form
					className="row"
					id="invite-form"
					onSubmit={handleSubmit(this.submitForm)}
				>
					<div className="col-md-11">
						<div className="row">
							<div className="col-md-8 form-group">
								<label>Business unit</label>
								<GetBUSelect
									options={prepareForSelect(BUList)}
									onChange={this.onChangeBU}
									name="name"
									role_name={role_name}
									is_member={is_member}
									label={true}
								/>
							</div>

							<div className="col-md-6">
								<Field
									name="first_name"
									label="First name"
									placeholder="User first name"
									component={FormField}
								/>
							</div>
							<div className="col-md-6">
								<Field
									name="last_name"
									label="Last name"
									placeholder="User last name"
									component={FormField}
								/>
							</div>

							<div className="col-md-12">
								<Field
									name="email"
									label="Email"
									component={FormField}
									placeholder="User email"
									normalize={this.normalizeEmail}
								/>
							</div>

							<div className="col-md-12">{this.renderUploader()}</div>

							<div className="col-md-12">
								<Field
									name="category"
									label="Category"
									options={sectors}
									onChange={this.getSubCategories}
									placeholder="Choose a Category"
									position={false}
									grouped={true}
									rawData={rawSectors}
									valueField="_id"
									component={StyledSelect}
								/>
							</div>

							<div className="col-md-12">
								<Field
									name="sector"
									label="Main Sub-Category"
									disabled={!subcategories.length}
									onChange={this.getFunctions}
									options={subcategories}
									placeholder={
										!subcategories.length
											? "Choose a category first"
											: "Choose a Sub-Category"
									}
									position={false}
									component={StyledSelect}
									toolTipOverlay="Groups of functions within the above-mentioned category"
								/>
							</div>

							<div className="col-md-8">
								<Field
									name="function"
									label="Main Function"
									disabled={!functions.length}
									options={functions}
									onChange={this.resetSeniority}
									placeholder={
										!functions.length
											? "Choose a subcategory first"
											: "Choose a function"
									}
									position={false}
									component={StyledSelect}
									toolTipOverlay="The job title that best describes your current role within the sub-category of functions chosen earlier. Note that you will be able to add more functions later in the profile completion process; should it be applicable based on your professional experience."
								/>
							</div>
							<div className="col-md-4">
								<Field
									name="seniority"
									options={LEVEL}
									label="Seniority"
									placeholder="Choose Seniority"
									position="left"
									component={StyledSelect}
									toolTipOverlay="The amount of experience that you have in the function (previous field)"
								/>
							</div>
							<div className="col-md-12">
								<p style={{ fontSize: "13px", fontWeight: "400" }}>
									By submitting the name and email address, and any other
									personal information of the Consultant, you confirm you have
									obtained their consent to do so or can rely on another lawful
									basis for their data to be processed. Their information will
									be
									<br /> collected, held, used and disclosed in accordance with
									our{" "}
									<Link
										target="_blank"
										to={getPolicyRoute()}
										className="link-url"
									>
										Privacy Policy .
									</Link>
								</p>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const validate = props => {
	const errors = {};
	const fields = ["email", "first_name", "last_name"];
	const nested = ["category", "sector", "function", "seniority", "name"];
	fields.map(field => {
		if (!props[field]) {
			errors[field] = "This field is required.";
		}
		return field;
	});

	nested.map(field => {
		if (!props[field] || !props[field].value) {
			errors[field] = "This field is required.";
		}
		return field;
	});

	if (props.email && !emailRegex.test(props.email)) {
		errors.email = "Email is invalid";
	}

	return errors;
};

export default reduxForm({
	form: "add-consultant",
	touchOnBlur: false,
	validate
})(ConsultantStep);
