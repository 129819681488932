import React from "react";
import Button from "../Button/Button";
import styles from "./calendar-grid-header.module.scss";
import cx from "classnames";
import { useDateFormatter } from "react-aria";

const CalendarGridHeader = ({
	Icon,
	buttonProps,
	buttonPosition = "left",
	state,
	offset = {}
}) => {
	let monthDateFormatter = useDateFormatter({
		month: "long",
		year: "numeric",
		timeZone: state.timeZone
	});

	const title = monthDateFormatter.format(
		state.visibleRange.start.add(offset).toDate(state.timeZone)
	);
	const arr = title.split(" ");

	return (
		<div className={styles.header}>
			<Button
				className={cx(styles.button, {
					[styles.buttonLeft]: buttonPosition === "left",
					[styles.buttonRight]: buttonPosition === "right"
				})}
				{...buttonProps}
			>
				<Icon className={styles.chevronIcon} />
			</Button>
			<div className={styles.text}>
				<span className={styles.span}>{arr[0]}</span>
				{arr[1]}
			</div>
		</div>
	);
};

export default CalendarGridHeader;
