import styled, { css } from "styled-components";

export const DashboardBox = styled.div`
	background: #f4f5f8;
	padding-top: 32px;
	padding-bottom: 40px;
	min-height: 85vh;
`;

export const Title = styled.h2`
	font-size: 20px;
	font-weight: 500;
	font-style: normal;
	color: #0f1c46;
	position: relative;
	margin-top: 15px;

	@media (max-width: 659px) {
		font-size: 20px;
		font-weight: 500;
		font-style: normal;
		color: #0f1c46;
		margin-bottom: 8px;
	}
`;

export const Notice = styled.p`
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	color: #3d4b60;
`;

export const SwitcherContainer = styled.ul`
	overflow: hidden;
	list-style: none;
	margin: 0;
	padding: 0;
	background-color: rgb(215, 220, 224);
	border-radius: 20.5px;
`;
export const Switcher = styled.li`
	float: right;
	cursor: pointer;
	background-color: rgb(215, 220, 224);
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	color: rgb(61, 75, 96);
	padding: 8px 23px;

	border-radius: 20.5px;

	font-family: "BasierCircle";

	${props =>
		props.active &&
		css`
			border-radius: 20.5px;
			box-shadow: none;
			background-color: rgb(255, 255, 255);
			color: #242f43;
		`};
`;
export const ChartContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: auto;
	align-content: flex-start;
	margin-top: 30px;
	justify-content: center;
	.chart {
		flex: 1 1 auto;
		margin-right: 10px;
		max-width: 560px;
		width: 100%;
		background: #fff;
		margin-bottom: 10px;
		height: 400px;
		position: relative;
		.chart-title {
			color: #0f1c46;
			letter-spacing: 0.05px;
			line-height: 2.15;
			font-weight: 500;
			font-size: 16px;
			padding: 20px 20px 0 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			span {
				width: 24px;
				height: 24px;
			}
			.icon-check {
				display: none;
			}
		}
	}
`;

export const TitleSkeleton = styled.div`
	margin-bottom: 35px;
`;
export const TitleChart = styled.div`
	position: absolute;
	top: 28px;
	left: 20px;
	h2 {
		font-size: 16px;
		color: #0f1c46;
		margin-bottom: 8px;
	}
`;
export const NoDataAvailable = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	.Box {
		text-align: center;
		.icone {
			color: #d8dce2;
			margin-bottom: 20px;
			font-size: 70px;
		}
		h2 {
			font-size: 16px;
			color: #0f1c46;
			margin-bottom: 8px;
		}
		h3 {
			font-size: 14px;
			color: #0f1c46;
			font-weight: 400;
		}
	}
`;
export const FailedData = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	background: rgba(255, 255, 255, 0.8);
	.Box {
		text-align: center;
		padding: 48px;
		.icone {
			color: #f75356;
			margin-bottom: 15px;
			font-size: 40px;
			display: block;
		}
		h2 {
			font-size: 16px;
			color: #f75356;
			margin-bottom: 8px;
		}
		h3 {
			font-size: 14px;
			color: #0f1c46;
			line-height: 18px;
			font-weight: 400;
		}
		button {
			border-radius: 2px;
			background: #fe6455;
			font-size: 18px;
			font-weight: 600;
			font-style: normal;
			color: #ffffff;
			border: 0;
			padding: 8px 24px;
			outline: 0;
			&:hover {
				background-color: #fc5035;
			}
		}
	}
`;
export const Loading = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 70%;
	margin: 0 auto;
	height: 100%;
	&.job-data {
		height: 75%;
	}
	.SkeletonBlocksLeft {
		span {
			display: block;
			margin-bottom: 20px;
			margin-top: 6px;
		}
	}

	.SkeletonBlocksRight {
		span {
			display: block;
			margin-bottom: 8px;
			margin-top: 0;
			border-radius: 0;
		}
	}
`;

export const ChartLegend = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 20px 0px 20px;

	.legend-item {
		display: flex;
		align-items: center;
		color: #273238;
		letter-spacing: 0.03px;
		font-weight: 500;
	}
	.circle {
		width: 10px;
		height: 10px;
		border-radius: 50px;
		margin-right: 7px;
		display: inline-block;
		&.circle-blue {
			background-color: #0099fa;
		}
		&.circle-orange {
			background-color: #f7b422;
		}
		&.circle-green {
			background-color: #56bd5b
}`;
