import styled, { css } from "styled-components";
import { colors } from "config/styles";
import { YELLOW_INDICATOR, RED_INDICATOR } from "config";

export const ListItemContainer = styled.div`
	margin-right: 10px;
	float: left;
	display: flex;
	flex-direction: ${props => (props.row ? "row" : "column")};
	margin-bottom: 10px;
`;

export const ListItem = styled.div`
	border: ${({ highlight }) =>
		highlight ? "1px solid #ff6849" : "1px solid #c1c1c1"};
	border-radius: 3px;
	background-color: #ffffff;
	height: 50px;
	display: flex;
	align-items: center;
	padding: 10px;
	justify-content: space-between;
	${props =>
		props.proposing &&
		!props.presenter &&
		css`
			margin-right: 10px;
		`};
	.reactRater {
		height: 100%;
		> div {
			height: 100%;
			display: inline-flex;
			align-items: center;
		}
	}
`;

export const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	${props =>
		props.column &&
		css`
			flex-direction: column;
			justify-content: center;
			height: 425px;
		`};
`;

export const SkillLabel = styled.label`
	width: 164px;
	max-width: 164px;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 0;
	margin-left: 0;
	color: #58626b;
`;

export const ListButton = styled.button`
	background-color: #ffffff;
	color: #9fa7ae;
	border: none;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	font-size: 7px;
	font-weight: 400;
	border: 0;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	&:hover {
		background-color: #c1c1c1;
		color: #ffffff;
	}
	&:disabled {
		&:hover {
			background-color: #e5e8e9;
			box-shadow: none;
		}
	}
`;

export const ErrorBlock = styled.div`
	color: #f76d75;
	font-weight: 400;
	font-size: 13px;
	> a {
		color: #f76c75;
		text-decoration: underline;
		font-size: 13px;
	}
`;

export const FilterInputHolder = styled.div`
	position: relative;
`;

export const ClearFilterButton = styled(ListButton)`
	position: absolute;
	top: 50%;
	right: 5px;
	transform: translate(-50%, -50%);
	&:hover {
		background-color: #c1c1c1;
		color: #ffffff;
		box-shadow: none;
	}
`;

export const CheckIcon = styled.div`
	width: 42px;
	height: 42px;
	background-color: #36ba90;
	border-radius: 50%;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 25px;
`;

export const SkillNameHolder = styled.div`
	color: #58626b;
	font-size: 20px;
	margin-bottom: 15px;
`;

export const HelpBlock = styled.div`
	color: #58626b;
	font-size: 13px;
	font-weight: 400;
	a {
		font-size: 13px;
		font-weight: 500;
	}
`;

export const Title = styled.span`
	margin-left: 176px;
	position: relative;
	color: ${colors.mustHaveTextColor};
	letter-spacing: 0.27px;
	font-weight: bold;
	span {
		margin-left: 2px;
	}
	.star {
		position: absolute;
		left: -23px;
		top: 0;
		width: 19px;
		fill: ${colors.primaryColor};
	}
`;

export const MustHaveInput = styled.input`
	opacity: 0;
	z-index: 2;
	position: absolute;
	left: 46%;
	margin-top: 0 !important;
	width: 20px;
	height: 20px;
	cursor: pointer;
`;

export const MustHaveLabel = styled.label`
	position: relative;
	.star {
		position: absolute;
		left: -26px;
		top: -6px;
		width: 19px;
		fill: ${colors.primaryColor};
		&.gray {
			fill: ${colors.scrubColor};
		}
	}
`;

export const AddedMessage = styled.div`
	background-color: ${colors.bidList};
	border-radius: 1.5px;
	padding: 3px 7px;
	color: ${colors.white};
	position: absolute;
	transform: translateX(-50%);
	font-weight: 500;
	transition: 0.4s;
	width: 140px;
	left: -16px;
	top: -35px;
	opacity: ${props => {
		return props.showMessage ? 0.89 : 0;
	}};
`;

export const MustHaveCounter = styled.div`
	float: left;
	position: relative;
	font-weight: 400;
	text-align-last: end;
	font-size: 14px;
	margin: 5px 0 0;
	color: ${props =>
		(props.color === YELLOW_INDICATOR.className && colors.warningColor) ||
		(props.color === RED_INDICATOR.className && colors.dropdownPauseColor) ||
		colors.pColor};
`;
