import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import {
	FRONT,
	BACK,
	CARD_ID_FOLDER,
	TYPE_PDF,
	TYPE_PJPEG,
	TYPE_JPEG,
	TYPE_PNG,
	TYPE_DOC,
	USE_UPLOADER,
	USE_WEBCAM,
	IDENTITY_COMPLETED,
	UPLOADED,
	WEBCAM
} from "config";
import toaster from "common/Toaster";
import MethodChooser from "./MethodChooser";
import IdentityUploader from "common/S3Uploader";
import { getMyProfile } from "modules/user/actions/authActions";
import WebcamSuccess from "./WebcamSuccess";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import {
	saveUploads,
	switchMethodDispatch,
	setUploadedFiles
} from "../actions/identityActions";
import {
	companyInfoSelector,
	uploaderMethodSelector
} from "../selectors/createCompanySelectors";
import { getCVChooserRoute } from "./cvchooser/CVChooserContainer";
import { toggleIdentityModal } from "../../TempRequestDetailsModule/actions/JobViewActions";

class IdentityUploaderContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			disabled: true,
			identity: [{ type: CARD_ID_FOLDER, url: "" }],
			fileFormat: [TYPE_PDF, TYPE_PJPEG, TYPE_JPEG, TYPE_PNG, TYPE_DOC]
		};
	}

	componentDidMount() {
		const camFrontFile = this.getFile(FRONT);
		const camBackFile = this.getFile(BACK);

		if (!!camFrontFile && !!camBackFile) {
			this.props.dispatch(
				setUploadedFiles(
					[
						{
							name: FRONT,
							file: camFrontFile,
							done: !!camFrontFile
						},
						{ name: BACK, file: camBackFile, done: !!camBackFile }
					],
					true,
					true
				)
			);
		}
	}

	getFile = name => {
		return (
			localStorage.getItem(`webcam-id-${name}-${this.props.user_id}`) || ""
		);
	};

	removeLocalFiles = () => {
		this.props.uploadMethod.files.forEach(file =>
			localStorage.removeItem(`webcam-id-${file.name}-${this.props.user_id}`)
		);
		return Promise.resolve(true);
	};

	fileUploaded = ({ url, name, size, type }, side) => {
		let success = false;

		const files = this.props.uploadMethod.files.map(file => {
			if (file.name === side) {
				return {
					...file,
					file: url,
					filename: name,
					filetype: type,
					size
				};
			}
			return file;
		});

		if (files.findIndex(file => file.file === "") === -1) success = true;

		this.props.dispatch(setUploadedFiles(files, success));
	};

	onUploadError = error => {
		toaster.danger(error);
	};

	onClose = () => {
		this.setState({ disabled: true });
	};

	next = () => {
		if (this.props.isModal) {
			this.props.dispatch(toggleIdentityModal()); // close modal
		}

		this.props.dispatch(getMyProfile()); // update profile
		this.props.dispatch(switchMethodDispatch());
		return this.removeLocalFiles();
	};

	saveFiles = () => {
		let {
			uploadMethod: { isWebcam, files },
			isModal
		} = this.props;
		files = files.map(file => {
			return {
				type: CARD_ID_FOLDER,
				url: file.file,
				side: file.name
			};
		});
		return saveUploads({
			data: {
				identity: files,
				step: IDENTITY_COMPLETED,
				identity_type: isWebcam ? WEBCAM : UPLOADED
			},
			newRoute: isModal ? "" : getCVChooserRoute(),
			next: this.next,
			toastMessage: "Identity uploaded successfully",
			isModal: isModal
		});
	};

	progressComponent = data => {
		if (data) {
			return (
				<div className="file-progress-box">
					<div className="size">{`${data.percentCompleted.toFixed(0)}%`}</div>
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${data.percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	};

	renderUploader = () => {
		return (
			<div className="id-uploader">
				<IdentityUploader
					accept={this.state.fileFormat}
					id="identity-front"
					multiple={false}
					folder={CARD_ID_FOLDER}
					autoSubmit={true}
					onSuccess={result => this.fileUploaded(result, FRONT)}
					resetOnSuccess={false}
					onError={this.onUploadError}
					onClose={this.onClose}
					progressComponent={this.progressComponent}
					text={{
						label: "Front",
						placeholder: "Drag file here or browse for a file to upload.",
						icon: { text: "ID", class: "fa fa-file-o" },
						choose: "Choose file"
					}}
				/>
				<IdentityUploader
					accept={this.state.fileFormat}
					id="identity-back"
					multiple={false}
					folder={CARD_ID_FOLDER}
					autoSubmit={true}
					onSuccess={result => this.fileUploaded(result, BACK)}
					resetOnSuccess={false}
					onError={this.onUploadError}
					onClose={this.onClose}
					progressComponent={this.progressComponent}
					text={{
						label: "Back",
						placeholder: "Drag file here or browse for a file to upload.",
						icon: { text: "ID", class: "fa fa-file-o" },
						choose: "Choose file"
					}}
				/>
			</div>
		);
	};

	switchMethod = (nextMethod, reset) => {
		const {
			uploadMethod: { isWebcam }
		} = this.props;

		this.props.dispatch(
			switchMethodDispatch(
				nextMethod,
				nextMethod === null ? isWebcam : nextMethod === WEBCAM
			)
		);

		if (reset) this.removeLocalFiles();
	};

	render() {
		const {
			uploadMethod: { method, success, files },
			chooserHeight,
			isModal
		} = this.props;
		return (
			<div className="identity">
				{method === null && !success && (
					<MethodChooser height={chooserHeight} onChoose={this.switchMethod} />
				)}

				{method === USE_UPLOADER && !success && (
					<div
						className={
							isModal ? "col-sm-10 col-sm-offset-1" : "col-sm-6 col-sm-offset-3"
						}
					>
						<form>
							{this.renderUploader()}
							<div className="actions action">
								<div className="divider">Or</div>
								<button
									className="btn-usecam btn-link"
									onClick={() => this.switchMethod(USE_WEBCAM)}
								>
									use webcam
								</button>
							</div>
						</form>
					</div>
				)}

				{success && (
					<div className="post-upload">
						<WebcamSuccess files={files} onProceed={this.saveFiles} />
						<h3>Upload again?</h3>
						<MethodChooser
							height={chooserHeight}
							onChoose={method => this.switchMethod(method, true)}
						/>
					</div>
				)}
			</div>
		);
	}
}

IdentityUploaderContainer.propTypes = {
	isModal: PropTypes.bool
};

IdentityUploaderContainer.defaultProps = {
	isModal: false,
	chooserHeight: "200px"
};

const mapStateToProps = state => {
	return {
		user_id: onlineUserSelector(state).id,
		companyInfo: companyInfoSelector(state),
		uploadMethod: uploaderMethodSelector(state)
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "identityUploader",
		touchOnBlur: false
	})
)(IdentityUploaderContainer);
