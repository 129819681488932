import React from "react";
import styles from "./permanent-view-evaluation-summary-loading.module.scss";
import { PermanentEvaluationsCustomSkeleton } from "../PermanentEvaluationsCustomSkeleton";
import { EvaluationsStatisticsSegments } from "common/EvaluationsStatisticsSegments";
import { ThumbsLabel } from "common/ThumbsLabel";

const PermanentViewEvaluationSummaryLoading = () => {
	const statistics = [
		{
			label: "No",
			percent: "00",
			width: "33%",
			icon: <ThumbsLabel className={styles.thumbs} type="red" />,
			backgroundColor: "#94A3B8"
		},
		{
			label: "Acceptable",
			percent: "00",
			width: "33%",
			icon: <ThumbsLabel className={styles.thumbs} type="orange" />,
			backgroundColor: "#CBD5E1"
		},
		{
			label: "Excellent",
			percent: "00",
			width: "33%",
			icon: <ThumbsLabel className={styles.thumbs} type="green" />,
			backgroundColor: "#F1F5F9"
		}
	];

	return (
		<div>
			<div className={styles.title}>Candidate Scores</div>
			<hr />
			<div>
				<EvaluationsStatisticsSegments
					total={1}
					stats={statistics}
					suffix="%"
				/>
			</div>
			<hr />{" "}
			<div className={styles.list}>
				{[1, 2, 3, 4].map(item => {
					return (
						<div key={item} className={styles.card}>
							<div className={styles.avatar}>
								<PermanentEvaluationsCustomSkeleton
									className={styles.avatar}
									width={42}
									height={42}
								/>
							</div>
							<div className={styles.profileContainer}>
								<PermanentEvaluationsCustomSkeleton width={217} height={15} />
								<PermanentEvaluationsCustomSkeleton width={141} height={10} />
							</div>
							<div className={styles.thumbs}>
								<PermanentEvaluationsCustomSkeleton width={79} height={36} />
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default PermanentViewEvaluationSummaryLoading;
