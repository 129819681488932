import create from "zustand";

const initialialState = {
	open: false,
	products_specs: [],
	freeChannels: {
		my_candidates: false,
		talent_pool: false
	}
};

const usePostingRequirementStore = create(set => ({
	...initialialState,
	setvalue: (key, value) => {
		set({ [key]: value });
	},
	reset: () => {
		set(initialialState);
	},
	clearSelectedContractsList: () => {
		set({ products_specs: [] });
	}
}));

export default usePostingRequirementStore;
