import React, { Component } from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import { ScorerScrub } from "./ScorerStyled";

class ScorerKnob extends Component {
	render() {
		const {
			onClick,
			onMouseEnter,
			isDisabled,
			tooltip,
			hasTooltip,
			isActive,
			willBeActive
		} = this.props;

		return hasTooltip && tooltip ? (
			<Tooltip
				placement="top"
				overlay={<div>{tooltip}</div>}
				trigger="hover"
				overlayClassName="overlay g-tool-white"
				mouseLeaveDelay={0}
			>
				<ScorerScrub
					active={isActive || willBeActive}
					onClick={isDisabled ? null : onClick}
					onMouseEnter={isDisabled ? null : onMouseEnter}
				/>
			</Tooltip>
		) : (
			<ScorerScrub
				active={isActive || willBeActive}
				onClick={isDisabled ? null : onClick}
				onMouseEnter={isDisabled ? null : onMouseEnter}
			/>
		);
	}
}

ScorerKnob.defaultProps = {
	willBeActive: false,
	isActive: false,
	isActiveHalf: false,
	isDisabled: false,
	hasTooltip: false
};

ScorerKnob.propTypes = {
	isActive: PropTypes.bool,
	isActiveHalf: PropTypes.bool,
	willBeActive: PropTypes.bool,
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func
};

export default ScorerKnob;
