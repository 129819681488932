import _get from "lodash/get";
import { getMyProfile } from "modules/user/actions/authActions";
import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { NOTIFICATIONS_SETTINGS } from "config/api-endpoints";
import { useDispatch, useSelector } from "react-redux";
import toaster from "common/Toaster";

const TOASTER_MSG = {
	receive_all_emails: {
		active: "Email notifications are now activated!",
		inactive: "Email notifications are now deactivated!"
	},
	receive_matching_jobs_emails: {
		active: "Matching vacancies via email are now activated!",
		inactive: "Matching vacancies via email are now deactivated!"
	},
	show_notification_in_real_time: {
		active: "Real time notifications are now activated!",
		inactive: "Real time notifications are now deactivated!"
	}
};

export const useUpdateNotifications = () => {
	const dispatch = useDispatch();
	const user = useSelector(state => state.auth.user);

	const allEmails = _get(user, "receive_all_emails", false);
	const realTimeNotifications = _get(
		user,
		"show_notification_in_real_time",
		false
	);
	const matchingJobsAlerts = _get(user, "receive_matching_jobs_emails", false);
	const alertsPeriod = _get(
		user,
		"receive_matching_jobs_emails_period",
		undefined
	);

	const updateNotifications = ({ name, value }) => {
		const receiveMailIsDisabled =
			name === "receive_all_emails" && !value ? true : false;
		return client(NOTIFICATIONS_SETTINGS, {
			body: {
				receive_all_emails: allEmails,
				show_notification_in_real_time: realTimeNotifications,
				receive_matching_jobs_emails_period: alertsPeriod,
				receive_matching_jobs_emails: receiveMailIsDisabled
					? false
					: matchingJobsAlerts,
				[name]: value
			}
		});
	};

	const [mutate, { isLoading }] = useMutation(updateNotifications, {
		onSuccess: (_, body) => {
			dispatch(getMyProfile());
			if (_get(TOASTER_MSG, body.name))
				toaster.success(
					body.value
						? _get(TOASTER_MSG, `${body.name}.active`)
						: _get(TOASTER_MSG, `${body.name}.inactive`)
				);
		},
		onError: () => toaster.danger("Something went wrong. Please try again!")
	});

	return [mutate, { isLoading }];
};
