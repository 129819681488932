import { useEffect } from "react";
import { usePaginatedQuery } from "react-query";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { FETCH_GDPR_LIST } from "config/api-endpoints";
import { client } from "lib/api-client";
import { GDPR_LIST_FILTER } from "../utils/constants";
import useDebouncedValue from "hooks/useDebouncedValue";

const fetchGdprList = body => {
	return client(FETCH_GDPR_LIST, {
		body
	});
};

export const KEY_FETCH_GDPR_LIST = "KEY_FETCH_GDPR_LIST";

const useFetchGdprList = () => {
	const { setIsLoading, setIsFetching, getState, setData } = useFilters();
	const { query, sortBy, offset, limit } = getState(GDPR_LIST_FILTER);
	const queryDebounced = useDebouncedValue(query);

	const body = {
		offset,
		sort_by: sortBy,
		limit,
		search: queryDebounced
	};

	const {
		resolvedData: data,
		isLoading,
		isFetching,
		isFetched,
		refetch
	} = usePaginatedQuery(
		[KEY_FETCH_GDPR_LIST, body],
		() => fetchGdprList(body),
		{
			refetchOnWindowFocus: false,
			enabled: !!limit
		}
	);

	useEffect(() => {
		setIsLoading(isLoading);
		setIsFetching(isFetching && !isFetched);
	}, [isLoading, isFetching, isFetched]);

	useEffect(() => {
		setData(data);
	}, [data]);

	return { refetch };
};

export default useFetchGdprList;
