import React from "react";
import styles from "./evaluations-list-item.module.scss";
import { ReactComponent as TrashIcon } from "static/icons/trash-3.svg";
import { ReactComponent as DuplicateIcon } from "static/icons/duplicate-2.svg";
import { browserHistory } from "react-router";
import { getEvaluationTemplateRoute } from "modules/EvaluationFormsSettings/EvaluationFormsSettings";

export default function EvaluationsListItem({
	item,
	onClickDelete,
	onClickDuplicate
}) {
	const onGoToTemplate = () => {
		browserHistory.replace(getEvaluationTemplateRoute(item._id));
	};

	const onClickDeleteButton = e => {
		e.stopPropagation();
		onClickDelete();
	};

	const onClickDuplicateButton = e => {
		e.stopPropagation();
		onClickDuplicate();
	};

	return (
		<div className={styles.container} onClick={onGoToTemplate}>
			<div className={styles.title}>{item.template_name}</div>
			<div className={styles.actions}>
				<button
					type="button"
					onClick={onClickDuplicateButton}
					className={styles.actionButton}
				>
					<DuplicateIcon />
				</button>
				<button
					type="button"
					onClick={onClickDeleteButton}
					className={styles.actionButton}
				>
					<TrashIcon />
				</button>
			</div>
		</div>
	);
}
