import React, { useContext, useEffect } from "react";
import SimpleChatBot from "react-simple-chatbot";
import _get from "lodash/get";
import _find from "lodash/find";
import styled from "styled-components";
import { BOT_ACTION_TYPES, BotContext } from "./botContext";
import AxiosHelper from "config/axios-helper";
import {
	GUEST_LIST_FUNCTIONS,
	LIST_SECTORS,
	ADD_BOT_FUNCTION,
	BOT_FRONT_SHEET_ITEM_ENDPOINT
} from "config/api-endpoints";
import steps from "./steps";
import BotIcon from "./icons/hiremebot.svg";
import UserIcon from "./icons/usericon.svg";
import useWindowSize from "hooks/useWindowSize";

const ChatBoxContainer = styled.div`
	.rsc-ts-user {
		.rsc-ts-bubble {
			background-color: #ff6849 !important;
			color: #ffffff !important;
		}
	}
`;

function ChatBot({ email, type }) {
	const { state, dispatch } = useContext(BotContext);

	useEffect(() => {
		AxiosHelper.post({
			url: LIST_SECTORS,
			isLogin: true
		}).then(res =>
			dispatch({ type: BOT_ACTION_TYPES.SET_SECTORS, sectors: res })
		);
	}, []);

	useEffect(() => {
		if (state.selectedFunction) {
			AxiosHelper.post({
				url: ADD_BOT_FUNCTION,
				data: {
					email: email,
					hash_key: email,
					type,
					functions: [
						{
							_id: state.selectedFunction.value,
							name: state.selectedFunction.name,
							seniority: state.selectedSeniority.value,
							position: 1,
							sector_id: state.selectedSubSector.value,
							is_main: true
						}
					]
				},
				isLogin: true,
				type: "no-loader"
			});
		}
	}, [state.selectedSeniority]);

	useEffect(() => {
		if (state.selectedSubSector) {
			AxiosHelper.post({
				url: GUEST_LIST_FUNCTIONS,
				data: { sector_id: state.selectedSubSector.value },
				isLogin: true,
				type: "no-loader"
			}).then(res =>
				dispatch({ type: BOT_ACTION_TYPES.SET_FUNCTIONS, functions: res })
			);
		}
	}, [state.selectedSubSector]);

	useEffect(() => {
		dispatch({
			type: BOT_ACTION_TYPES.SET_SUB_SECTORS,
			subSectors: _get(
				_find(state.sectors, s => s._id === state.selectedSector.value),
				"children",
				[]
			)
		});
	}, [state.selectedSector]);

	useEffect(() => {
		let payload = {
			email: email,
			hash_key: email
		};
		const callApi =
			state.selectedLocation ||
			state.selectedExpectation ||
			state.selectedNoticePeriod;
		if (state.selectedLocation) {
			payload.preferred_location = [state.selectedLocation];
		}
		if (state.selectedExpectation) {
			delete payload.preferred_location;
			payload.expected_salary = +state.selectedExpectation * 100;
		}
		if (state.selectedNoticePeriod) {
			delete payload.expected_salary;
			payload.notice_period = state.selectedNoticePeriod;
		}
		if (!callApi) return;
		AxiosHelper.post({
			url: BOT_FRONT_SHEET_ITEM_ENDPOINT,
			data: payload,
			isLogin: true,
			type: "no-loader"
		});
	}, [
		state.selectedLocation,
		state.selectedExpectation,
		state.selectedNoticePeriod
	]);

	const isMobile = useWindowSize();

	return (
		<ChatBoxContainer className="chatbot__container">
			<SimpleChatBot
				userDelay={100}
				steps={steps[type]}
				bubbleStyle={{
					backgroundColor: "#f0f5f4",
					borderRadius: 5,
					color: "#193651",
					boxShadow: "none",
					fontFamily: "'Roboto', sans-serif",
					fontSize: 14,
					fontWeight: 400,
					maxWidth: "80%"
				}}
				contentStyle={{
					backgroundColor: "white",
					height: "100%",
					margin: 0,
					border: "1px solid #e4e8ec",
					padding: isMobile ? "30px 0px" : "30px 35px",
					borderRadius: 3
				}}
				customStyle={{
					backgroundColor: "transparent",
					color: "#3d4b60",
					boxShadow: "none"
				}}
				style={{
					width: "100%",
					boxShadow: "none",
					marginTop: !isMobile ? 50 : "",
					borderRadius: 0,
					minHeight: "80vh",
					zIndex: isMobile ? "unset" : 1
				}}
				footerStyle={{ display: "none" }}
				headerComponent={<div />}
				avatarStyle={{
					borderRadius: 9999,
					marginTop: -40,
					boxShadow: "none"
				}}
				botAvatar={BotIcon}
				userAvatar={UserIcon}
			/>
		</ChatBoxContainer>
	);
}

export default ChatBot;
