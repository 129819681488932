import React, { useEffect, useRef, useState } from "react";
import styles from "./file-uploader.module.scss";
import { ReactComponent as PaperclipIcon } from "static/icons/referral/paperclip.svg";
import { ReactComponent as FileIcon } from "static/icons/referral/file.svg";
import { ReactComponent as TrashIcon } from "static/icons/referral/trash.svg";
import { ReactComponent as CheckIcon } from "static/icons/referral/check-circle.svg";
import { ReactComponent as LoadingIcon } from "static/icons/spinner.svg";
import { VisuallyHidden, useDrop } from "react-aria";
import cx from "classnames";
import toaster from "common/Toaster";
import { bytesToSize, uploadToS3 } from "config/helpers";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import { client } from "lib/api-client";
import { TYPE_PDF, TYPE_DOC, TYPE_DOCX } from "config";
const ACCEPT = [TYPE_PDF, TYPE_DOC, TYPE_DOCX];

export default function FileUploader({
	onChange: onUrlChange,
	isExtractingLoading,
	onStartUpload
}) {
	const [file, setFile] = useState(null);
	const [progress, setProgress] = useState(0);
	const ref = useRef(null);
	const inputRef = useRef(null);

	useEffect(() => {
		if (file) {
			upload();
		}
	}, [file]);

	const { dropProps, isDropTarget } = useDrop({
		ref,
		async onDrop(e) {
			const item = e.items.find(item => item.kind === "file");
			if (item) {
				if (ACCEPT.includes(item.type)) {
					onStartUpload();
					const file = await item.getFile();
					setFile(file);
				} else {
					toaster.danger("The file format/extension is not valid.");
				}
			}
		}
	});

	const onClick = () => {
		inputRef.current.click();
	};

	const onChange = e => {
		onStartUpload();
		const file = e.target.files[0];
		setFile(file);
		e.target.value = "";
	};

	const upload = async () => {
		setProgress(0);
		const response = await preSignS3();
		const result = await uploadToS3({
			response,
			file,
			onProgress(progress) {
				setProgress(Math.floor(progress));
			}
		});
		onUrlChange?.(result.url);
	};

	const preSignS3 = () => {
		return client(PRE_SIGNED_URL, {
			body: {
				name: file.name,
				folder: "cv",
				size: file.size,
				type: file.type
			}
		});
	};

	const onRemoveFile = () => {
		setFile(null);
		onUrlChange?.("");
	};

	if (file) {
		return (
			<div className={styles.previewContainer}>
				<div className={styles.fileInfos}>
					<div className={styles.iconWrapper}>
						<FileIcon />
					</div>
					<div className={styles.name}>{file.name}</div>
					<div>{bytesToSize(file.size)}</div>
				</div>
				<div className={styles.fileStatus}>
					{!!progress &&
						(progress < 100 ? (
							<div className={styles.progress}>
								<div className={styles.bar}>
									<div style={{ width: `${progress}%` }}></div>
								</div>
								<div className={styles.percentage}>{progress}%</div>
							</div>
						) : isExtractingLoading ? (
							<div className={styles.spinner}>
								<LoadingIcon />
							</div>
						) : (
							<div className={styles.completed}>
								<span className={styles.label}>Completed</span>
								<CheckIcon />
								<span
									onClick={onRemoveFile}
									role="button"
									className={styles.deleteIcon}
								>
									<TrashIcon />
								</span>
							</div>
						))}
				</div>
			</div>
		);
	}

	return (
		<div
			{...dropProps}
			role="button"
			tabIndex={0}
			ref={ref}
			className={cx(
				styles.uploaderContainer,
				isDropTarget && styles.isDropTarget
			)}
			onClick={onClick}
		>
			<div className={styles.iconWrapper}>
				<PaperclipIcon />
			</div>
			<VisuallyHidden>
				<input
					type="file"
					ref={inputRef}
					onChange={onChange}
					accept={ACCEPT.join(", ")}
				/>
			</VisuallyHidden>
			<div>
				<div>
					<span className={styles.highlighted}>Click to upload</span> or drag
					and drop
				</div>
				<div>Doc, Docx PDF format required.</div>
			</div>
		</div>
	);
}
