export const API_URL = "https://api.develop.hme.ovh";
export const LOCAL_URL = "http://localhost:3000";
export const EB_URL = "https://www.wiggli.io";
// Pusher creds
export const PUSHER_KEY = "3ee12a696bda6e118eca";
export const PUSHER_CLUSTER = "eu";
export const HOME_URL =
	process.env.NODE_ENV === "development"
		? "http://localhost:3000/sign-in"
		: "http://front.develop.hme.ovh/sign-in";
export const HIREME_BLOG_URL = "https://blog.wiggli.io";
export const IS_BETA = true;
export const COOKIE_DOMAIN = "app.wiggli.io";
export const REQUEST_DEMO_URL = "https://www.wiggli.io/demo";
// ReCaptcha
export const RECAPTCHA_SITE_KEY = "6Ld2EKQUAAAAAHSd3LgKIjXIP89UF9weagxLOqKz";
export const IP_DATA_API_KEY =
	"e9331a96b51093c032df70151608a32413ba55559975ae462b9dfc4b";
export const WEATHER_API_KEY = "9fab6a0192204e67b31160627212812";
export const GOOGLE_ANALYTICS = "UA-XXXX-1";
