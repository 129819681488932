import styled, { css } from "styled-components";
import { colors } from "config/styles";
import { YELLOW_INDICATOR, RED_INDICATOR } from "config";

export const ListItemContainer = styled.div`
	margin-right: 10px;
	display: flex;
	flex-direction: ${props => (props.row ? "row" : "column")};
	margin-bottom: 10px;
	max-height: 50px;
	${props =>
		props.cart &&
		css`
			margin-bottom: 5px;
			margin-right: 0;
		`};
`;

export const ListItem = styled.div`
	border: ${({ highlight }) =>
		highlight
			? `1px solid ${colors.primaryColor}`
			: `1px solid ${colors.skillsItemBg}`};
	border-radius: 3px;
	background-color: white;
	display: flex;
	align-items: center;
	padding: 10px;
	height: 50px;
	justify-content: space-between;
	${props =>
		props.proposing &&
		css`
			margin-right: 10px;
		`};
	.reactRater {
		height: 100%;
		display: flex;
		> div {
			height: 100%;
			display: inline-flex;
			align-items: center;
		}
	}
	${props =>
		props.cart &&
		css`
			height: 50px !important;
			margin-bottom: 5px !important;
		`};
`;

export const FlexContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	${props =>
		props.column &&
		css`
			flex-direction: column;
			justify-content: center;
			height: 425px;
		`};
`;

export const SkillLabel = styled.label`
	width: 164px;
	max-width: 164px;
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 0;
	margin-left: 0;
	color: ${colors.linksColor};
`;

export const ButtonContainer = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

export const ListButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: unset;
	color: ${colors.deleteButton};
	border: none;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	padding: 2px 3px;
	outline: 0;
	font-size: 7px;
	font-weight: 400;
	margin-left: ${props => (props.marginLeft ? props.marginLeft : "unset")};
	&:hover {
		background-color: ${colors.skillsItemBg};
		color: white;
	}
	&:disabled {
		&:hover {
			background-color: ${colors.disabledListItemRemoveButton};
			box-shadow: none;
		}
	}
`;

export const ErrorBlock = styled.div`
	color: ${colors.refuse};
	font-weight: 400;
	font-size: 13px;
	> a {
		color: ${colors.refuseText};
		text-decoration: underline;
		font-size: 13px;
	}
`;

export const FilterInputHolder = styled.div`
	position: relative;
`;

export const ClearFilterButton = styled.button`
	width: 33px;
	height: 33px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	right: -5px;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	outline: none;
	border: none;
	border-radius: 50%;
	outline: none;
	&:hover {
		svg {
			path {
				fill: #b1b1b1;
			}
		}
	}
`;

export const CheckIcon = styled.div`
	width: 42px;
	height: 42px;
	background-color: ${colors.stepperIconColor};
	border-radius: 50%;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 25px;
`;

export const SkillNameHolder = styled.div`
	color: ${colors.anchorsColor};
	font-size: 20px;
	margin-bottom: 15px;
`;

export const HelpBlock = styled.div`
	color: ${colors.anchorsColor};
	font-size: 13px;
	font-weight: 400;
	a {
		font-size: 13px;
		font-weight: 500;
	}
`;

export const WithCategoryFilter = styled.div`
	width: 66.66666666666666%;
	display: flex;
	align-items: center;
`;

export const CategoryName = styled.div`
	font-size: 14px;
	font-weight: bold;
	color: ${colors.anchorsColor};
	line-height: 2.23;
	text-align: left;
`;

export const ListContainer = styled.div`
	border: 1px solid ${colors.sectionBorderColor};
	box-shadow: rgba(209, 212, 215, 0.24) 0px 1.5px 30.7px 0.3px;
	background-color: ${colors.white};
	height: 357px;
	overflow: hidden;
`;

export const CartContainer = styled.div`
	overflow: hidden;
	border: 1px solid ${colors.sectionBorderColor};
	height: 357px;
	background-color: ${colors.lineHover};
	margin-top: -7px;
`;

export const EmptySkills = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	line-height: 1.54;
	font-weight: 300;
	font-size: 22px;
	letter-spacing: normal;
	color: ${colors.darkGray};
	padding: 15px;
	text-align: center;
	.icon-info {
		font-size: 38px;
		color: ${colors.btnTrColor};
		margin-bottom: 15px;
	}
`;

export const Title = styled.span`
	position: relative;
	color: ${colors.mustHaveTextColor};
	letter-spacing: 0.27px;
	font-weight: bold;
	span {
		margin-left: 2px;
	}
	.star {
		width: 19px;
		fill: ${colors.primaryColor};
	}
`;

export const MustHaveInput = styled.input`
	opacity: 0;
	z-index: 2;
	position: absolute;
	left: 46%;
	margin-top: 0 !important;
	width: 20px;
	height: 20px;
	cursor: pointer;
`;

export const MustHaveLabel = styled.label`
	position: relative;
	.star {
		position: absolute;
		left: -26px;
		top: -6px;
		width: 19px;
		fill: ${colors.primaryColor};
		&.gray {
			fill: ${colors.scrubColor};
		}
	}
`;

export const AddedMessage = styled.div`
	background-color: ${colors.bidList};
	border-radius: 1.5px;
	padding: 3px 7px;
	color: ${colors.white};
	position: absolute;
	transform: translateX(-50%);
	font-weight: 500;
	transition: 0.4s;
	width: 140px;
	left: -16px;
	top: -35px;
	opacity: ${props => {
		return props.showMessage ? 0.89 : 0;
	}};
`;

export const MustHaveCounter = styled.div`
	float: left;
	position: relative;
	font-weight: 400;
	text-align-last: end;
	font-size: 14px;
	margin: 5px 0 0;
	color: ${props =>
		(props.color === YELLOW_INDICATOR.className && colors.warningColor) ||
		(props.color === RED_INDICATOR.className && colors.dropdownPauseColor) ||
		colors.pColor};
`;

export const ProposeItemHolder = styled.div`
	width: 50%;
	padding: 15px;
	${ListItemContainer} {
		margin-bottom: 5px;
	}
`;

export const CartHead = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const ClearAll = styled.button`
	background: transparent;
	&:hover {
		text-decoration: underline;
	}
`;
