import React from "react";
import _get from "lodash/get";
import { Flex } from "rebass";
import { Channel, TagsChannel } from "./styled";
import { excerpt } from "common/Functions";
import { EUR, NAME_CHANNEL_VALUE_MAX, HIREME_CHANNEL_ID } from "config";
import CurrencyFormatter from "common/CurrencyFormater";
import GlobalTooltip from "common/GlobalTooltip";

import { ReactComponent as TrashCan } from "static/icons/trashcan.svg";
import Calendar from "static/icons/calendare2.svg";

const ChannelComponent = ({
	channel,
	handleSelectedChannel,
	filteredSelected,
	isActiveChannel = false,
	removeSelectedChannel
}) => {
	return (
		<Channel isActiveChannel={isActiveChannel}>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				alignContent="center"
				height="40px"
			>
				<div className="logoChannel" style={{ width: "70px" }}>
					<img
						src={_get(channel, "logo_url[0].url", "")}
						alt=""
						style={{
							width: "90%",
							display: "block",
							maxHeight: "40px"
						}}
					/>
				</div>
				<div className="actionChannel">
					{isActiveChannel ? (
						(channel.product_id !== HIREME_CHANNEL_ID ||
							filteredSelected.length === 1) && (
							<TrashCan
								onClick={() => removeSelectedChannel(channel.product_id)}
								className="trash-img"
							/>
						)
					) : (
						<span
							onClick={() => handleSelectedChannel(channel)}
							className="icon-save-template"
						></span>
					)}
				</div>
			</Flex>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flexWrap: "wrap"
				}}
				className="desc"
			>
				<GlobalTooltip
					placement="top"
					overlay={_get(channel, "title", _get(channel, "channel.name", ""))}
					withWrappingDiv={false}
					overlayClassName="g-tool-dark"
					active={
						_get(channel, "title", _get(channel, "channel.name", "")).length >
						NAME_CHANNEL_VALUE_MAX
					}
					maxWidth="200px"
				>
					<div className="channel-value">
						{excerpt(
							_get(channel, "title", _get(channel, "channel.name", "")),
							NAME_CHANNEL_VALUE_MAX
						)}
					</div>
				</GlobalTooltip>

				<div
					style={{
						display: "flex",
						fontSize: "12px",
						width: "100%"
					}}
				>
					<TagsChannel>
						<CurrencyFormatter
							euro={_get(channel, "vonq_price[0].amount", 0)}
							symbol={EUR}
						/>
					</TagsChannel>
					<TagsChannel>
						<img
							src={Calendar}
							style={{
								width: "14px",
								height: "14px",
								marginRight: 6
							}}
						/>
						{_get(channel, "duration.period", 0)}{" "}
						{_get(channel, "duration.range", "days")}
					</TagsChannel>
				</div>
			</div>
		</Channel>
	);
};

export default ChannelComponent;
