import React from "react";
import styles from "./messaging-tool-sidebar.module.scss";
import { Link } from "react-router";
import cx from "classnames";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";
import { getMessagingToolRoute } from "modules/MessagingTool/MessagingTool";
import useMessagingToolUnreadCounter from "modules/MessagingTool/hooks/useMessagingToolUnreadCounter";

const MessagingToolSidebar = ({ hash }) => {
	const { unreadCounter } = useMessagingToolUnreadCounter();
	return (
		<nav className={styles.sidebar}>
			<h3 className={styles.title}>My email</h3>
			<ul className={styles.nav}>
				{Object.values(MESSAGING_TOOL_LINKS).map(({ label, Icon, slug }) => (
					<li
						key={slug}
						className={cx(styles.navItem, {
							[styles.active]: slug === hash
						})}
					>
						<Link
							to={getMessagingToolRoute(slug)}
							className={cx(styles.link, {
								[styles.active]: slug === hash
							})}
						>
							<Icon
								className={cx(styles.icon, {
									[styles.active]: slug === hash
								})}
							/>
							<span className={styles.label}>{label}</span>
							{!!unreadCounter[slug] && (
								<span
									className={cx(styles.counter, {
										[styles.draft]: slug === MESSAGING_TOOL_LINKS.draft.slug
									})}
								>
									{unreadCounter[slug]}
								</span>
							)}
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
};

export default MessagingToolSidebar;
