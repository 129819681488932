import styled, { css } from "styled-components";
import { colors } from "config/styles";

export const FiltersContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
	.head {
		margin-bottom: 10px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.title {
		color: ${colors.inputColor};
		font-size: 15px;
		font-weight: bold;
		line-height: normal;
		text-align: left;
	}
	.clearFilters {
		font-size: 13px;
		color: ${colors.clearFilterColor};
		font-weight: normal;
		line-height: normal;
		cursor: pointer;

		span:not(:first-of-type) {
			&:hover {
				color: #242f43;
			}
		}
		.icon-clear-filter {
			margin-right: 4px;
		}
	}
	.btn-primary {
		text-transform: unset;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: normal;
		width: 100%;
		margin-top: 10px;
		.icon-save-template {
			margin-right: 5px;
			font-size: 16px;
			vertical-align: text-bottom;
		}
	}
	.sort {
		width: 100%;
		margin-bottom: 7px;
	}
`;

export const Filter = styled.div`
	min-height: 136px;
	background-color: ${colors.white};
	width: fit-content;
	min-width: 100%;
	padding: 13px 7px 13px 15px;
	border-radius: 4px;
	box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
	border: solid 0.5px ${colors.grayBorder};
	margin-bottom: 7px;
	.btn-search-search {
		padding: 10px 0px;
		width: 100%;
		float: none;
		margin-top: 16px;
		margin-bottom: 10px;
		font-weight: 500;
		border-radius: 0;
		background: #616976;
	}
	&.checkbox-filter {
		min-height: 100px;
		.checkbox-container {
			width: 100%;
			display: ${props => (props.isSource ? "block" : "flex")};
			align-items: baseline;
			&.flex {
				align-items: center;
				margin-bottom: 6px;
				.input-box {
					display: flex !important;
					.radio_input {
						margin-right: 9px;
					}
				}
			}
			.input-box {
				display: grid !important;
				grid-template-columns: 56% 22% 22%;
				width: 100%;
				margin-bottom: 8px;
				input[type="checkbox"].styled + label {
					padding-left: ${props => props.isSource && "30px"};
					font-size: ${props => props.isSource && "13px"};
				}
				input[type="radio"].styled {
					padding-left: ${props => props.isSource && "30px"};
					font-size: ${props => props.isSource && "13px"};
					font-weight: ${props => props.isSource && "500"};
				}
				.radio_input {
					display: flex;
					position: relative;
					justify-content: center;
					input[type="radio"] {
						opacity: 0;
						width: 20px;
						position: absolute;
						height: 20px;
						cursor: pointer;
						&:checked + .radio_control {
							background: radial-gradient(#fd6950 30%, rgba(255, 0, 0, 0) 40%);
						}
					}
					.radio_control {
						display: block;
						width: 20px;
						height: 20px;
						background: #d9dbe9;
						border-radius: 50%;
						border: 1px solid #d9dbe9;
						box-sizing: border-box;
					}
				}
				> label {
					padding: 0;
					font-family: "basiercircle";
					font-weight: 600;
					font-size: 13px;
					line-height: 22px;
					color: #6e7191;
				}
			}
		}
	}
	.g-tool {
		margin-left: unset !important;
	}
	.form-control {
		resize: none;
		padding-right: 2px;
		margin-top: 4px;
		min-height: 100px;
		background: white !important;
		border: ${props => props.keywords && `1px solid ${colors.primaryColor}`};
	}
	.title {
		font-size: 14px;
		margin-bottom: 25px;
		position: relative;
		display: grid;
		grid-template-columns: 56% 22% 22%;
		font-family: "basiercircle";
		line-height: 20px;
		.col-1 {
			color: #4e4b66;
		}
		.col-2,
		.col-3 {
			color: #a0a3bd;
			text-align: center;
			word-break: break-word;
		}
		.icon-wrapper {
			padding: 7px;
			float: right;
			position: absolute;
			right: 0;
			top: -7px;
			border-radius: 4px;
			&:hover {
				cursor: pointer;
				background-color: ${colors.primaryColor};
				span {
					color: ${colors.white};
				}
			}
			.icon-pen-2,
			.icon-edit-2 {
				color: ${colors.btnTrColor};
				font-size: 15px;
			}
		}
	}
	.btn-gray {
		padding: 8px 20px;
		background-color: rgba(231, 233, 235, 0.6);
		border-radius: 4px;
		color: ${colors.clearFilterColor};
		font-weight: bold;
		text-align: center;
		text-transform: unset;
		font-size: 14px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		transition: unset;
		cursor: pointer;
		span {
		}
		span:first-of-type {
			color: #acb4c3;
			margin-right: 5px;
		}
		&:hover,
		&:active,
		&:focus {
			span {
				color: ${colors.white} !important;
			}
			background-color: ${colors.primaryColor} !important;
		}
	}
	.sector {
		font-size: 14px;
		font-weight: bold;
		color: ${colors.anchorsColor};
		margin-bottom: 5px;
		margin-left: 5px;
	}
	.scrubber {
		padding: 0;
		margin-top: -6px;
		text-align: right;
		font-weight: 400;
		margin-right: 7px;
		.scrubber-input {
			padding: 3px 5px;
			border-radius: 4px;
			border: 1px solid #e2e2e2;
			outline: none;
			width: 50px;
			margin-right: 8px;
			cursor: ew-resize;
			text-align: center;
		}
	}
	.rc-slider {
		.rc-slider-track {
			background-color: ${colors.primaryColor};
		}
		.handle {
			border-color: ${colors.primaryColor};
			.handle-middle {
				display: none;
			}
		}
	}
	${props =>
		props.distance &&
		css`
			min-height: 100px;
			.rc-slider-disabled {
				background-color: unset !important;
			}
		`}

	.disabled-filter {
		cursor: not-allowed !important;
		opacity: 0.65;
	}

	.count-pill {
		padding: 2px 8px;
		margin-left: 8px;
		font-size: 13px;
		font-weight: 400;
		color: #202223;
		background: #e4e5e7;
		border-radius: 10px;
	}
`;
export const Function = styled.ul`
	list-style: none;
	overflow: hidden;
	margin-bottom: 0px;
	padding: 0;
	font-size: 13px;
	letter-spacing: 0.2px;
	font-weight: 400;

	li {
		float: left;
		color: ${colors.white};
		text-decoration: none;
		padding: 6px 0 6px 15px;
		position: relative;
		display: block;
		margin-bottom: 10px;
		margin-left: 6px;
		&.parent-sector-search {
			background-color: #ff6849;
			color: #fff;
			padding-left: 10px;
			&:not(:last-child) {
				&:after {
					content: " ";
					display: block;
					width: 0;
					height: 0;
					border-top: 15px solid transparent;
					border-bottom: 15px solid transparent;
					border-left: 14px solid ${colors.primaryColor};
					position: absolute;
					top: 50%;
					margin-top: -15px;
					left: 100%;
					z-index: 2;
				}
				&:before {
					content: " ";
					display: block;
					width: 0;
					height: 0;
					border-top: 16px solid transparent;
					border-bottom: 18px solid transparent;
					border-left: 15px solid ${colors.white};
					position: absolute;
					top: 50%;
					margin-top: -17px;
					margin-left: 4px;
					left: 100%;
					z-index: 1;
				}
			}
			&:last-child {
				padding-right: 10px;
				border-radius: 0 3px 3px 0;
				background-color: #eaebef;
				color: ${colors.inputColor};
				text-transform: Capitalize;
			}
		}
		&.sector-search {
			background-color: #fc795e;
			color: #fff;
			&:not(:last-child) {
				&:after {
					content: " ";
					display: block;
					width: 0;
					height: 0;
					border-top: 15px solid transparent;
					border-bottom: 15px solid transparent;
					border-left: 14px solid #fc795e;
					position: absolute;
					top: 50%;
					margin-top: -15px;
					left: 100%;
					z-index: 2;
				}
				&:before {
					content: " ";
					display: block;
					width: 0;
					height: 0;
					border-top: 16px solid transparent;
					border-bottom: 18px solid transparent;
					border-left: 15px solid ${colors.white};
					position: absolute;
					top: 50%;
					margin-top: -17px;
					margin-left: 4px;
					left: 100%;
					z-index: 1;
				}
			}
			&:last-child {
				padding-right: 10px;
				border-radius: 0 3px 3px 0;
				background-color: #eaebef;
				color: ${colors.inputColor};
				text-transform: Capitalize;
			}
		}
		&.function-search {
			background-color: #fc866e;
			color: #fff;
			&:not(:last-child) {
				&:after {
					content: " ";
					display: block;
					width: 0;
					height: 0;
					border-top: 15px solid transparent;
					border-bottom: 15px solid transparent;
					border-left: 14px solid #fc866e;
					position: absolute;
					top: 50%;
					margin-top: -15px;
					left: 100%;
					z-index: 2;
				}
				&:before {
					content: " ";
					display: block;
					width: 0;
					height: 0;
					border-top: 16px solid transparent;
					border-bottom: 18px solid transparent;
					border-left: 15px solid ${colors.white};
					position: absolute;
					top: 50%;
					margin-top: -17px;
					margin-left: 4px;
					left: 100%;
					z-index: 1;
				}
			}
			&:last-child {
				padding-right: 10px;
				border-radius: 0 3px 3px 0;
				background-color: #eaebef;
				color: ${colors.inputColor};
				text-transform: Capitalize;
			}
		}
		&:last-child {
			padding-right: 10px;
			border-radius: 0 3px 3px 0;
			background-color: #eaebef;
			color: ${colors.inputColor};
			text-transform: Capitalize;
		}
	}
`;

export const ItemsWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: left;
	flex-wrap: wrap;
	margin-bottom: 10px;
	.g-tool {
		cursor: pointer;
		margin-left: unset !important;
	}
`;

export const Item = styled.div`
	padding: 4px 10px;
	background-color: #eaebef;
	border-radius: 3px;
	color: ${colors.inputColor};
	font-size: 14px;
	font-weight: 400;
	margin-right: 6px;
	max-width: 46%;
	margin-bottom: 5px;
	text-transform: Capitalize;
	&:hover {
		background-color: #f4f4f5;
	}
	.icon-close {
		color: #c4c6ce;
		vertical-align: middle;
		margin-left: 5px;
		font-size: 14px;
		font-weight: bold;
		cursor: pointer;
		&:hover {
			color: ${colors.inputColor};
		}
	}
	.must_have_star {
		fill: ${colors.primaryColor};
		width: 15px;
		margin-right: 5px;
		position: relative;
		top: 0;
		vertical-align: middle;
	}
	${props =>
		props.hasTooltip &&
		css`
			max-width: 100%;
		`};
	${props =>
		props.mustHave &&
		css`
			background-color: #fffbfa;
			border: solid 1px ${colors.primaryColor};
			box-shadow: 0px 1px 3.5px 0 rgba(197, 199, 206, 0.4);
			&:hover {
				background-color: #fffbfa;
				cursor: pointer;
			}
		`}
`;

export const ShowMore = styled.button`
	font-size: 14px;
	color: ${colors.clearFilterColor};
	font-weight: bold;
	cursor: pointer;
	margin-top: 10px;
	background: none;
	outline: none;
	border: none;
	span:first-of-type {
		color: #acb4c3;
	}
	&:hover {
		span {
			color: ${colors.primaryColor};
		}
	}
`;

export const LocationContainer = styled.div`
	display: flex;
	justify-content: left;
	.icon-local {
		color: #3185cf;
		margin-right: 5px;
		position: relative;
		top: 3px;
	}
	.address {
		font-size: 14px;
		line-height: 1.53;
		font-weight: 400;
		color: ${colors.inputColor};
		text-align: left;
	}
`;

export const SelectWrapper = styled.div`
	padding-right: 7px;
	position: relative;
	.icon {
		position: absolute;
		top: 16px;
		left: 0;
	}
	.template-label {
		font-size: 15px;
		font-weight: bold;
		color: ${colors.inputColor};
		line-height: normal;
		letter-spacing: normal;
		margin-bottom: 10px;
	}
	.select-template-search {
		.Select-arrow-zone {
			cursor: pointer;
			display: table-cell;
			position: relative;
			text-align: center;
			vertical-align: middle;
			width: 25px;
			padding-right: 20px;
			padding-left: 10px;
		}
	}
`;

export const SearchResultsContainer = styled.div`
	width: 100%;
	margin-top: 50px;
	.results-count {
		color: ${colors.inputColor};
		font-size: 15px;
		font-weight: 400;
		line-height: normal;
		text-align: right;
		margin-right: 25px;
	}
	.results-container {
		min-height: 595px;

		.results {
			display: flex;
			align-items: center;
			margin-top: 10px;
			flex-wrap: wrap;
			&.jobs {
				margin-top: unset;
			}
		}
	}
`;

export const ProfileItem = styled.div`
	background-color: ${colors.white};
	border-radius: 4px;
	box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
	border: solid 0.5px ${colors.grayBorder};
	width: 48%;
	height: 235px;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: 0.3s;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 5px 5px 0.1px rgba(197, 199, 206, 0.4);
		.first-section {
			.function {
				.name {
					color: ${colors.primaryColor};
				}
			}
		}
		.third-section {
			.icon-container {
				background-color: ${colors.primaryColor};
				border: 1px solid ${colors.primaryColor};
				span {
					color: ${colors.white};
				}
			}
		}
	}
	.first-section {
		padding: 25px 20px;
		border-bottom: 1px solid #ebeef0;
		position: relative;
		.profile-actions{
			display: block;
			position: absolute;
			right: 10px;
			top: 7px;
			.dots{
				display: inline;
				position: relative;
				top: -2px;
			}
		}
	}

		.function {
			cursor: pointer;
			.name {
				color: #242f43;
				font-size: 15px;
				line-height: normal;
				font-weight: bold;
				margin-bottom: 15px;
				transition: 0.3s;
				span {
					font-weight: normal;
				}
			}
		}
		.location {
			color: ${colors.inputColor};
			font-size: 14px;

			.g-tool {
				color: ${colors.inputColor} !important;
				font-size: 14px !important;
				margin-left: unset !important;
			}
			.icon-local {
				color: #3185cf;
				margin-right: 7px;
				position: relative;
				top: 1px;
				left: 3px;
			}
		}
	}
	.second-section {
		border-bottom: 1px solid #ebeef0;
		display: flex;
		align-items: center;
		justify-content: right;
		font-size: 14px;

		.salary {
			padding: 20px 0;
			border-right: 1px solid #ebeef0;
			width: 50%;
		}
		.advantages {
			padding: 20px 0 20px 10px;
			width: 50%;

			.content {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.center {
				text-align: center;
			}
		}
		.title {
			color: ${colors.clearFilterColor};
			font-weight: normal;
			line-height: normal;
			text-align: center;
			margin-bottom: 5px;
		}
		.content {
			color: ${colors.inputColor};
			font-weight: normal;
			line-height: normal;
			text-align: center;
		}
	}
	.third-section {
		padding: 12px 20px;
		font-size: 14px;
		display: flex !important;
		align-items: center !important ;
		justify-content: space-between !important;

		.circle {
			height: 10px;
			width: 10px;
			display: inline-block;
			border-radius: 50%;
			margin-right: 7px;
			vertical-align: middle;
		}
		.ref {
			color: ${colors.clearFilterColor};
			letter-spacing: 0.98px;
			line-height: normal;
			.circle {
				border: 1px solid ${colors.primaryColor};
				background-color: ${colors.white};
			}
		}
		.fullName {
			color: ${colors.inputColor};
			line-height: normal;
			font-weight: normal;
			.circle {
				background-color: ${colors.greenTextColor};
			}
		}
		.icon-container {
			width: 30px;
			height: 30px;
			position: relative;
			border: 1px solid #f0f2f4;
			border-radius: 50%;
			transition: 0.3s;
			cursor: pointer;
			&:hover {
				background-color: ${colors.primaryColor};
				border: 1px solid ${colors.primaryColor};
				span {
					color: ${colors.white};
				}
			}
			.icon-arrow-right1 {
				color: ${colors.primaryColor};
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 12px;
			}
		}
	}
`;

export const SelectOption = styled.div`
	.Select-option {
		position: relative;
		.icon-close {
			display: none;
			color: #999;
			font-size: 18px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 16px;

			&:hover {
				color: #d0021b;
			}
		}

		&:hover {
			background-color: ${colors.hoverBgColor};
			color: ${colors.dropdownColor};
			border-left: 4px solid ${colors.primaryColor};
			.icon-close {
				display: inline;
			}
		}
	}
`;

export const EmptySearchContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 150px;

	img {
		width: 114px;
		height: 71px;
		margin-bottom: 15px;
	}
	.title {
		font-size: 16px !important;
		font-weight: bold;
		color: #242f43;
		text-align: center;
	}
	.paragraph {
		color: ${colors.inputColor};
		font-size: 14px;
		font-weight: normal;
	}
`;

export const SearchLimitMessage = styled.span`
	font-size: 14px;
	font-weight: normal;
	line-height: normal;
	color: ${colors.grayText};
	letter-spacing: normal;
	text-align: center;
	position: absolute;
	top: 23%;
	left: 50%;
	transform: translateX(-50%);
`;

export const DistanceContainer = styled.div`
	margin-top: 10px;
	.residence-checkbox {
		margin-top: 20px;
		.residence-label {
			color: #3d4b60;
			font-weight: bold;
			padding-left: 30px;
			display: flex;
			align-items: flex-start;
		}

		#residence {
			width: 21px;
			height: 20px;
			margin-top: 0;
			margin-left: 0;
		}
		.residence-text {
			display: flex;
			.g-tool {
				margin-left: 6px !important;
			}
		}
	}
`;
