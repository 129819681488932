import React, { useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { Flex, Box, Text } from "rebass";
import FeedbackModal from "./FeedbackModal";
//components
import GlobalTooltip from "common/GlobalTooltip";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { formatDate } from "common/Functions";
import AttachedFiles from "common/AttachedFiles";
//config
import {
	DATE_FORMAT_LLLL,
	TWO_HUNDRED,
	CLIENT,
	BID,
	REJECTED,
	DAILY,
	HOURLY,
	CURRENCY_SUFFIX
} from "config";
import Description from "common/Description";
import Fee from "./client/Fee";
import StatusLabel from "common/StatusLabel";
import ToolTip from "common/GlobalTooltip";

const BidDetails = ({
	visible_status,
	status,
	created_at,
	proposed_amount,
	margin_value,
	available_date,
	description,
	get_status_tooltip,
	maxWidth,
	type: userType,
	stepperStatus,
	bid_details
}) => {
	const isClient = userType === CLIENT;
	const isBid = stepperStatus === BID;
	const files = _get(bid_details, "files", []);

	const [showMore, setShowMore] = useState(false);

	return (
		<Box
			p={20}
			sx={{
				borderRadius: "4px",
				boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
				backgroundColor: "rgb(255, 255, 255)"
			}}
		>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				pb={20}
				sx={{
					borderBottom: "1px solid #dde3e8"
				}}
			>
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontSize: "15px",
						fontWeight: "600",
						letterSpacing: "0.08px",
						color: "rgb(39, 50, 56)"
					}}
				>
					Bid Details
				</Text>
				{isBid && (
					<GlobalTooltip
						placement="top"
						eventTrigger="hover"
						maxWidth={maxWidth}
						overlay={<span>{get_status_tooltip}</span>}
					>
						<StatusLabel status={status} label={visible_status} px={15} />
					</GlobalTooltip>
				)}
			</Flex>

			<Box mt={24}>
				<Text
					mb={12}
					sx={{
						fontFamily: "BasierCircle",
						fontSize: "12px",
						fontWeight: "600",
						letterSpacing: "1.08px",
						color: "rgb(160, 165, 185)",
						textTransform: "uppercase"
					}}
				>
					creation date
				</Text>
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontSize: "16px",
						fontWeight: "500",
						letterSpacing: "0.08px",
						color: "rgb(51, 51, 51)"
					}}
				>
					{formatDate(created_at, true, DATE_FORMAT_LLLL)}
				</Text>
			</Box>

			<Flex mt={48}>
				<Box pr={20} width={1 / 2} sx={{ borderRight: "1px solid #b0b9c6" }}>
					<Flex mb={22} justifyContent="space-between" alignItems="center">
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "12px",
								fontWeight: "600",
								letterSpacing: "1.08px",
								color: "rgb(160, 165, 185)",
								textTransform: "uppercase"
							}}
						>
							{_get(bid_details, "reporting_settings.rate_type", HOURLY)} rate
							{_get(bid_details, "reporting_settings.rate_type", HOURLY) ===
								DAILY && (
								<ToolTip
									placement={"right"}
									maxWidth={"250px"}
									align={{
										offset: [15, 0]
									}}
									overlay={`${_get(
										bid_details,
										"reporting_settings.hours_representation"
									).replace(":", "h")} of work time per day`}
								/>
							)}
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "14px",
								fontWeight: "500",
								color: "rgb(15, 28, 70)"
							}}
						>
							<Flex alignItems="center">
								<Box mr={"8px"}>
									<CurrencyFormatterRender
										isCent
										value={proposed_amount}
										suffix={
											CURRENCY_SUFFIX[
												_get(
													bid_details,
													"reporting_settings.rate_type",
													HOURLY
												)
											]
										}
										currency={_get(
											bid_details,
											"reporting_settings.currency.code"
										)}
									/>
								</Box>

								{isClient && (
									<Fee
										marginValue={margin_value}
										reportingSettings={_get(bid_details, "reporting_settings")}
										suffix={
											CURRENCY_SUFFIX[
												_get(
													bid_details,
													"reporting_settings.rate_type",
													HOURLY
												)
											]
										}
									/>
								)}
							</Flex>
						</Text>
					</Flex>

					<Flex mb={22} justifyContent="space-between" alignItems="center">
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "12px",
								fontWeight: "600",
								letterSpacing: "1.08px",
								color: "rgb(160, 165, 185)",
								textTransform: "uppercase"
							}}
						>
							available from
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "14px",
								fontWeight: "500",
								color: "rgb(15, 28, 70)"
							}}
						>
							{formatDate(available_date)}
						</Text>
					</Flex>
					<Flex mb={22} justifyContent="space-between" alignItems="center">
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "12px",
								fontWeight: "600",
								letterSpacing: "1.08px",
								color: "rgb(160, 165, 185)",
								textTransform: "uppercase"
							}}
						></Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "14px",
								fontWeight: "500",
								color: "rgb(15, 28, 70)"
							}}
						></Text>
					</Flex>
				</Box>
				<Box pl={20} width={1 / 2}>
					<div className="subtitle">motivation</div>
					<div className="content-description">
						<Description
							description={description}
							max={TWO_HUNDRED}
							parseMarkdown={true}
						/>
					</div>
				</Box>
			</Flex>

			{files.length > 0 ? <AttachedFiles files={files} /> : <></>}

			{status === REJECTED && (
				<FeedbackModal
					active={showMore}
					onClose={() => setShowMore(false)}
					text={get_status_tooltip}
				/>
			)}
		</Box>
	);
};

BidDetails.propTypes = {
	status: PropTypes.string.isRequired,
	created_at: PropTypes.number.isRequired,
	proposed_amount: PropTypes.number.isRequired,
	margin_value: PropTypes.number.isRequired,
	available_date: PropTypes.number.isRequired,
	description: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
		.isRequired,
	get_status_tooltip: PropTypes.string.isRequired,
	flag: PropTypes.string.isRequired,
	maxWidth: PropTypes.string,
	type: PropTypes.string.isRequired
};

BidDetails.defaultProps = {
	maxWidth: "400px",
	margin_value: 0
};

export default BidDetails;
