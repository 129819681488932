import React from "react";
import styles from "./questionnaire-form-empty-state-file-uploaded.module.scss";
import { ReactComponent as FileUploadedEmptyStateIcon } from "static/icons/file-uploaded-empty-state.svg";

const QuestionnaireFormEmptyStateFileUploaded = () => {
	return (
		<div className={styles.emptyStateContainer}>
			<FileUploadedEmptyStateIcon className={styles.emptyState} />
			<p className={styles.emptyStateText}>No file uploaded</p>
		</div>
	);
};

export default QuestionnaireFormEmptyStateFileUploaded;
