import React from "react";
import style from "./empty-state.module.scss";
import { ReactComponent as EmptyIcon } from "static/icons/emptyState.svg";

const EmptyState = ({ description, title }) => {
	return (
		<div className={style.emptyState}>
			<EmptyIcon />
			<p className={style.textGras}>{title}</p>
			<p className={style.textNormal}>{description}</p>
		</div>
	);
};
export default EmptyState;
