import React from "react";
import Tooltip from "rc-tooltip";
import cx from "classnames";
import s from "./input.module.scss";
import { ReactComponent as InfoIcon } from "static/icons/info-icon.svg";

const InputField = ({
	type,
	label,
	placeholder,
	value,
	onChange,
	description,
	error,
	className
}) => {
	return (
		<div className={s.root}>
			{label && (
				<label>
					<span>{label}</span>{" "}
					{description && (
						<Tooltip
							placement="right"
							overlay={
								<div dangerouslySetInnerHTML={{ __html: description }} />
							}
							trigger="hover"
							destroyTooltipOnHide={true}
							overlayClassName="g-tool-white"
						>
							<InfoIcon />
						</Tooltip>
					)}
				</label>
			)}
			<input
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				className={cx({ [s.inputError]: Boolean(error) }, className)}
			/>
			{error && <span className={s.error}>{error}</span>}
		</div>
	);
};

export default InputField;
