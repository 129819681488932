import React, { memo } from "react";
import cardStyle from "./EditableSection/editable-section.module.scss";
import PersonalProfile from "./CandidateProfile/PersonalProfile";
import CandidateProfileTabCandidatePreferences from "./CandidateProfile/CandidateProfileTabCandidatePreferences";
import Functions from "./CandidateProfile/Functions";
import Skills from "./CandidateProfile/Skills";
import Languages from "./CandidateProfile/Languages";
import Resumer from "./CandidateProfile/Resumer";
import useViewCandidate from "../../../api/useViewCandidate";
import { searchParser } from "config/helpers";

const CandidateProfile = ({ id }) => {
	const { email } = searchParser();

	const { data: profileData } = useViewCandidate({
		id,
		is_internal: false,
		email
	});

	return (
		<React.Fragment>
			<div className={cardStyle.root}>
				<PersonalProfile profileData={profileData} />
				<Resumer profileData={profileData} />
				<CandidateProfileTabCandidatePreferences profileData={profileData} />
				<Functions profileData={profileData} />
				<Skills profileData={profileData} />
				<Languages profileData={profileData} />
			</div>
		</React.Fragment>
	);
};

export default memo(CandidateProfile);
