import React, { useState, useRef } from "react";
import _get from "lodash/get";
import Fuse from "fuse.js";
import { FilterContainer, SearchWrapper } from "./styled";
import { Flex, Box } from "rebass";
import Select from "react-select";
import get from "lodash/get";
import { ReactComponent as GetRecommendationIcon } from "static/icons/recommendations.svg";
import { ReactComponent as ResetFilters } from "static/icons/reset.svg";

import CustomOption from "common/CustomOption";

import debounce from "lodash/debounce";

const ChannelsModalFilters = ({
	filter,
	setFilter,
	clearAllFilters,
	selectedFunction,
	selectedJobTitle,
	selectedIndustry,
	setSelectedIndustry,
	setSelectedJobTitle,
	setSelectedLocation,
	setSelectedFunction,
	setSearchChannel,
	taxonomies,
	selectedLocation,
	setSearchLocation,
	setIsRecommendation
}) => {
	const [searchFunction, setSearchFunction] = useState("");

	const onSearch = e => {
		setFilter(e.target.value);
		debouncedChangeFilterAction.current(e.target.value);
	};
	const functions = get(taxonomies, "functions", []);
	const locations = get(taxonomies, "locations", []);
	const industries = get(taxonomies, "industries", []);

	const jobTitles = get(taxonomies, "job_titles", []);

	const _options = !searchFunction
		? functions
		: new Fuse(functions, {
				keys: ["name", "name"],
				threshold: 0.3
		  })
				.search(searchFunction)
				.map(({ item }) => item);

	const debouncedChangeFilterAction = useRef(
		debounce(value => setSearchChannel(value), 500)
	);
	const debouncedSearchLocationAction = useRef(
		debounce(term => setSearchLocation(term), 500)
	);

	const searchLocation = term => {
		debouncedSearchLocationAction.current(term);
		return term;
	};

	const isResetFilter =
		_get(selectedFunction, "label", "") ||
		_get(selectedJobTitle, "label", "") ||
		_get(selectedIndustry, "label", "") ||
		_get(selectedLocation, "label", "") ||
		filter;

	const GetRecommendation = () => setIsRecommendation(true);

	const selectFunctionHandler = option => {
		const label = _get(option, "name", "");
		const value = _get(option, "id", "");
		if (!label || !value)
			setSelectedJobTitle({
				value: "",
				label: ""
			});

		setSelectedFunction({ label, value });
	};

	const filterFunctionSelectOption = option => {
		let isFiltered = false;
		_options.forEach(_option =>
			_option.id === option.id ? (isFiltered = true) : null
		);
		return isFiltered;
	};

	const resetFilter = () => {
		setFilter("");
		clearAllFilters();
	};
	return (
		<FilterContainer>
			<Flex justifyContent="space-between" mb={"20px"}>
				<SearchWrapper>
					<div className="searchBlock">
						<input
							value={filter}
							name="search.value"
							onChange={onSearch}
							type="text"
							className="searchChannels"
							placeholder={"Search Channels"}
						/>
						<span className="icon-search"></span>
					</div>
				</SearchWrapper>
				<button
					className="btn btn-get-recommendations"
					onClick={GetRecommendation}
				>
					<GetRecommendationIcon className="get-recommendation-icon" />
					Get recommendations
				</button>
			</Flex>
			<Flex>
				<Box
					sx={{
						flexGrow: 1,
						marginRight: 10
					}}
				>
					<Select
						className="categories-select"
						classNamePrefix="select"
						searchable={true}
						onChange={option => selectFunctionHandler(option)}
						onInputChange={data => setSearchFunction(data)}
						options={_options}
						optionComponent={props => <CustomOption {...props} />}
						value={_get(selectedFunction, "value", null) && selectedFunction}
						placeholder="Functions"
						filterOption={filterFunctionSelectOption}
						clearable={true}
					/>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						marginRight: 10
					}}
				>
					<Select
						value={_get(selectedIndustry, "value", null) && selectedIndustry}
						options={industries.map(({ id, name }) => ({
							label: name,
							value: id
						}))}
						clearable={true}
						onChange={value => setSelectedIndustry(value)}
						searchable={true}
						placeholder="Industry"
					/>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						marginRight: 10
					}}
				>
					<Select
						value={_get(selectedJobTitle, "value", null) && selectedJobTitle}
						options={jobTitles.map(({ id, name }) => ({
							label: name,
							value: id
						}))}
						clearable={true}
						onChange={value => setSelectedJobTitle(value)}
						searchable={true}
						placeholder="Job title"
					/>
				</Box>
				<Box
					sx={{
						flexGrow: 1,
						marginRight: 10
					}}
				>
					<Select
						value={_get(selectedLocation, "value", null) && selectedLocation}
						options={locations.map(({ id, fully_qualified_place_name }) => ({
							label: fully_qualified_place_name,
							value: id
						}))}
						clearable={true}
						onInputChange={term => searchLocation(term)}
						onChange={value => setSelectedLocation(value)}
						searchable={true}
						placeholder="Location"
					/>
				</Box>
			</Flex>

			<Box minHeight={52}>
				{isResetFilter && (
					<button className="btn btn-reset-filter" onClick={resetFilter}>
						<ResetFilters className="icon-refresh" />
						Reset filter
					</button>
				)}
			</Box>
		</FilterContainer>
	);
};

export default ChannelsModalFilters;
