import React from "react";
import { ReactComponent as Status } from "static/icons/status-icon.svg";
import { ReactComponent as DueDateIcon } from "static/icons/due-date-icon.svg";
import { ReactComponent as LinkToIcon } from "static/icons/link_to_icon.svg";
import { ReactComponent as PriorityIcon } from "static/icons/priority-icon.svg";
import { ReactComponent as UserIcon } from "static/icons/user-icon.svg";
import { ReactComponent as CreationDateIcon } from "static/icons/creation-date-icon.svg";
import { ReactComponent as ReminderIcon } from "static/icons/status-icon.svg";
import {
	REMINDER_BEFORE,
	REMINDER_AFTER,
	VALUE_OPERATORS,
	DATE_OPERATORS,
	DUE_DATE,
	REMINDER,
	PRIORITY,
	LINK_TO,
	CREATED_BY,
	ASSIGNED_TO,
	CREATION_DATE,
	STATUS,
	VACANCY
} from "config";
import { client } from "lib/api-client";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { FREELANCER, REQUEST } from "config";
import FilterBox from "common/FilterBox";
import useTasksStore from "../../store/useTaskStore";
import { v4 as uuid } from "uuid";

const getFilterSettings = (tag, search) => {
	return client(NEW_FILTER_SETTINGS, {
		body: {
			list: "tasks",
			tag,
			search
		}
	});
};

const sortBylist = [
	{
		field: STATUS,
		icon: <Status />,
		label: "Status",
		options: []
	},
	{
		field: DUE_DATE,
		icon: <DueDateIcon />,
		label: "Due date",
		options: []
	},
	{
		field: PRIORITY,
		icon: <PriorityIcon />,
		label: "Priority",
		options: []
	},
	{
		field: LINK_TO,
		icon: <LinkToIcon />,
		label: "Link to"
	},
	{
		field: CREATED_BY,
		icon: <UserIcon />,
		label: "Created by",
		options: []
	},
	{
		field: ASSIGNED_TO,
		icon: <UserIcon />,
		label: "Assigned to"
	},
	{
		field: CREATION_DATE,
		icon: <CreationDateIcon />,
		label: "Creation date",
		options: []
	}
];

const filterList = [
	{
		field: STATUS,
		icon: <Status />,
		label: "Status",
		options: [],
		filterOptions: () => getFilterSettings(STATUS),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: DUE_DATE,
		icon: <DueDateIcon />,
		label: "Due date",
		options: [],
		selectedOption: "",
		oprators: DATE_OPERATORS,
		isMultipleSelect: false,
		id: uuid()
	},
	{
		field: REMINDER,
		icon: <ReminderIcon />,
		label: "Reminder",
		options: [],
		filterOptions: () => [...REMINDER_BEFORE, ...REMINDER_AFTER],
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: false,
		id: uuid()
	},
	{
		field: PRIORITY,
		icon: <PriorityIcon />,
		label: "Priority",
		options: [],
		filterOptions: () => getFilterSettings(PRIORITY),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: LINK_TO,
		icon: <LinkToIcon />,
		label: "Link to",
		options: [
			{
				value: "candidate",
				label: "Candidate",
				filterOptions: () => getFilterSettings("candidate")
			},
			{
				value: "freelancer",
				label: "Freelancer",
				filterOptions: () => getFilterSettings(FREELANCER)
			},
			{
				value: "supplier",
				label: "Supplier",
				filterOptions: () => getFilterSettings("supplier")
			},
			{
				value: "vacancy",
				label: "Vacancy",
				filterOptions: (_, search) => getFilterSettings(VACANCY, search)
			},
			{
				value: "request",
				label: "Request",
				filterOptions: (_, search) => getFilterSettings(REQUEST, search)
			}
		],
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: CREATED_BY,
		icon: <UserIcon />,
		label: "Created by",
		options: [],
		filterOptions: () => getFilterSettings(CREATED_BY),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: ASSIGNED_TO,
		icon: <UserIcon />,
		label: "Assigned to",
		options: [
			{
				label: "Internal",
				value: "internal",
				filterOptions: () => getFilterSettings("assigned_user_internal")
			},
			{
				label: "External",
				value: "external",
				filterOptions: () => getFilterSettings("assigned_user_external")
			}
		],
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: CREATION_DATE,
		icon: <CreationDateIcon />,
		label: "Creation date",
		options: [],
		selectedOption: "",
		oprators: DATE_OPERATORS,
		isMultipleSelect: false,
		id: uuid()
	}
];

const Filter = ({
	searchKeyword,
	setSearchKeyword,
	isDescriptionSearch,
	isTitleSearch,
	updateFilters
}) => {
	return (
		<FilterBox
			{...{
				searchKeyword,
				setSearchKeyword,
				isDescriptionSearch,
				isTitleSearch,
				updateFilters,
				sortBylist,
				filterList,
				store: useTasksStore
			}}
		></FilterBox>
	);
};

export default Filter;
