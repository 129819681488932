import React, { useState, useRef } from "react";
import cx from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import style from "modules/company/components/VariableMargins/components/customMenu/menu.module.scss";
import useOnClickOutside from "hooks/useOnClickOutside";

export const MenuItem = ({ children, ...rest }) => (
	<div className={style.menuItem} {...rest}>
		{children}
	</div>
);

const Menu = ({ openerNode, children }) => {
	const [open, setOpen] = useState(false);
	const menuRef = useRef(null);

	useOnClickOutside(menuRef, () => {
		setOpen(false);
	});
	const handleOpen = () => setOpen(old => !old);
	return (
		<div className={style.menuContainer} ref={menuRef}>
			<div className={style.opener} onClick={handleOpen}>
				{openerNode}
			</div>
			{open && (
				<div id="menuContainer" className={cx("menu", style.itemsContainer)}>
					<Scrollbars
						autoHide
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={300}
					>
						{children({ setOpen })}
					</Scrollbars>
				</div>
			)}
		</div>
	);
};

export default Menu;
