/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import DepartmentRow from "./DepartmentRow";
import {
	TimesheetTableDetails,
	Tr,
	Th
} from "../../../timesheets/components/TimesheetStyled";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import EmptyList from "./EmptyList";

export default ({ list, connectedUser, onMenuSelect, AddButton }) => {
	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Settings" },
			{ name: "Departments ", path: "/settings/users" }
		]);
	}, []);
	if (list && list.length > 0)
		return (
			<TimesheetTableDetails className="styled-table" spacing="0 3px">
				<thead>
					<Tr>
						<Th width="20%">Name</Th>
						<Th width="30%">Users</Th>
						<Th width="8%" style={{ textAlign: "center" }}>
							Actions
						</Th>
					</Tr>
				</thead>
				<tbody>
					{list.map((department, index) => (
						<DepartmentRow
							key={index}
							department={department}
							connectedUser={connectedUser}
							onMenuSelect={onMenuSelect}
						/>
					))}
				</tbody>
			</TimesheetTableDetails>
		);
	return (
		<EmptyList
			AddButton={AddButton}
			title={"No Departments Created Yet"}
			description={
				"It looks like you haven’t created any departments yet. Start building your organizational structure by adding a new department."
			}
		/>
	);
};
