import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { client } from "lib/api-client";
import { CHECK_ACTIVATE_ACCOUNT } from "config/api-endpoints";
import * as yup from "yup";
import get from "lodash/get";
import toaster from "common/Toaster";
import { historyPush } from "config/helpers";
import { CONSULTANCY, CLIENT, FREELANCER, PERMANENT } from "config";
import styles from "./vacancy-view-add-mail-modal.module.scss";
import { CustomInput } from "common/CustomInput";
import cx from "classnames";
import { FieldError } from "common/FieldError";
import CustomizableModel from "common/CustomizableModel/CustomizableModel";
import { createPortal } from "react-dom";
import { ModalFooter } from "common/CustomizableModel/components/ModalFooter";
import { ModalSaveButton } from "common/CustomizableModel/components/ModalSaveButton";
import { ModalCancelButton } from "common/CustomizableModel/components/ModalCancelButton";
import { Head } from "common/CustomizableModel/components/Head";
import { useUpdatePersonalInfo } from "api/useUpdatePersonalInfo";

const VacancyViewAddMailModal = ({
	profileId,
	onEmailAdded,
	activeCompany,
	labelKey = "interview",
	setShowAddMailModal,
	vacancyId,
	closeDrawer,
	logInterviewHandler
}) => {
	const [isMergeProfiles, setIsMergeProfiles] = useState(false);
	const [isAlreadyShortlisted, setIsAlreadyShortlisted] = useState(false);
	const [applicationId, setApplicationId] = useState("");
	const [userId, setUserId] = useState("");

	const schema = yup.object().shape({
		email: yup
			.string()
			.email("This email is invalid")
			.required("Email is required")
	});

	const {
		handleSubmit,
		control,
		getValues,
		formState: {
			errors: { email: emailError }
		},
		setError,
		watch
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema),
		defaultValues: {
			email: ""
		}
	});

	let { email } = watch();

	const [mutate, { isLoading }] = useUpdatePersonalInfo({
		onSuccess: () => {
			onEmailAdded(getValues("email"));
			setIsMergeProfiles(false);
			setShowAddMailModal(false);
		},
		onError: error => {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					get(errorMessage, name) &&
						toaster.danger(
							`${name} : ${
								typeof get(errorMessage, name) === "string"
									? get(errorMessage, name)
									: get(errorMessage, name)[0]
							}`
						);
				});
			} else {
				toaster.danger(errorMessage);
			}

			setIsMergeProfiles(false);
		}
	});

	const onSubmit = async ({ email, callUpdatePersonalInfo }) => {
		try {
			const {
				exists,
				type,
				already_shortlisted,
				application_id,
				can_merge,
				user_id
			} = await client(CHECK_ACTIVATE_ACCOUNT, {
				body: {
					email,
					tag: "email",
					company_id: activeCompany,
					vacancy_id: vacancyId,
					id: profileId
				}
			});
			if (exists) {
				if (type === CONSULTANCY || type === CLIENT || type === FREELANCER) {
					setError("email", {
						type: "manual",
						message: "This email already exists"
					});
				}
				if (type === PERMANENT) {
					if (already_shortlisted) {
						setIsAlreadyShortlisted(true);
						setApplicationId(application_id);
						setUserId(user_id);
						return;
					}
					if (can_merge) {
						setIsMergeProfiles(true);
						return;
					}
					setError("email", {
						type: "manual",
						message: "This email already exists"
					});
				}

				return;
			}

			if (callUpdatePersonalInfo && !already_shortlisted) {
				mutate({ id: profileId, email });
			}
		} catch (error) {
			toaster.danger(get(get(error, "detail"), "email"));
			setError("email", {
				type: "manual",
				message: get(get(error, "detail"), "email")
			});
		}
	};

	const errorMessage = get(emailError, "message", "");

	const mergeProfileHandler = () => {
		if (isMergeProfiles) {
			mutate({ id: profileId, email });
		} else {
			handleSubmit(data =>
				onSubmit({ ...data, callUpdatePersonalInfo: true })
			)();
		}
	};

	const viewProfile = () => {
		closeDrawer?.();
		historyPush(`/permanent/view/${userId}?application_id=${applicationId}`);
	};

	return createPortal(
		<CustomizableModel
			onClose={() => setShowAddMailModal(false)}
			className={styles.modal}
			displayButtons={false}
			showHead={false}
		>
			<div className={styles.grid}>
				<Head
					title="Ooops, Email not found!"
					onClose={() => setShowAddMailModal(false)}
				/>

				<div className={styles.container}>
					<div>
						<div className="modal-interview-body">
							<span className={styles.description}>
								There is no email address for this candidate.
							</span>
							&nbsp;
							<span className={styles.description}>
								Please provide the address to which the {labelKey} notification
								can be sent.
							</span>
							<div className={styles.inputContainer}>
								<Controller
									name="email"
									control={control}
									render={({ field: { onChange, value } }) => {
										return (
											<CustomInput
												type="email"
												value={value}
												onChange={({ target }) => {
													if (isMergeProfiles) {
														setIsMergeProfiles(false);
													}
													if (isAlreadyShortlisted) {
														setIsAlreadyShortlisted(false);
													}
													onChange(target.value);
												}}
												placeholder="name@example.com"
												className={cx(
													styles.input,
													errorMessage ? "error" : ""
												)}
												onBlur={handleSubmit(onSubmit)}
											/>
										);
									}}
								/>
								{errorMessage && <FieldError error={emailError} />}
								{isMergeProfiles && (
									<FieldError
										error={{
											message:
												"This email address is used by another candidate profile which you can merge in one profile!"
										}}
									/>
								)}
								{isAlreadyShortlisted && (
									<FieldError>
										This email address is used by another candidate in this
										vacancy!
										<span
											className={styles.viewProfileLink}
											onClick={viewProfile}
										>
											View profile
										</span>
									</FieldError>
								)}
							</div>
						</div>
					</div>
				</div>
				<ModalFooter className={styles.footer}>
					<ModalCancelButton
						text={`Log ${labelKey}`}
						onClick={logInterviewHandler}
					/>
					<ModalSaveButton
						onClick={mergeProfileHandler}
						disabled={
							errorMessage || !email || isAlreadyShortlisted || isLoading
						}
						text={
							isMergeProfiles
								? `Merge and send ${labelKey} notification`
								: `Send ${labelKey} notification`
						}
					/>
				</ModalFooter>
			</div>
		</CustomizableModel>,
		document.body
	);
};

export default VacancyViewAddMailModal;
