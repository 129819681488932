import { useQuery, queryCache } from "react-query";
import { client } from "lib/api-client";
import { VIEW_USER_HISTORY } from "config/api-endpoints";

const getUserHistory = (_key, body) =>
	client(VIEW_USER_HISTORY, {
		body
	});

export const userHistoryKey = "@candidate/user_history";

const invalidate = queryCache.invalidateQueries("viewCandidate");

const useUserHistory = (options, queryOptions = {}) => {
	const query = useQuery(["userHistory", options], getUserHistory, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
	return {
		...query,
		invalidate
	};
};

export default useUserHistory;
