import React, { useState } from "react";
import _get from "lodash/get";
import { useMutation } from "react-query";

import Modal from "common/modal";
import AddressBox from "common/AddressBox/AddressChooser";
import {
	Container,
	GrayBox,
	Content,
	LocationTextBox,
	LocationBox,
	LocationBoxContainer,
	ListButton,
	Counter
} from "./Styled";
import GlobalTooltip from "common/GlobalTooltip";
import { client } from "lib/api-client";

import { MAX_PREFERRED_LOCATION, LOADING } from "config";
import { SAVE_PREFERRED_LOCATION_ENDPOINT } from "config/api-endpoints";
import toaster from "common/Toaster";

import { handleAddressObject } from "config/helpers";

const SaveLocations = data =>
	client(SAVE_PREFERRED_LOCATION_ENDPOINT, { body: data });

const PreferredLocationModal = ({
	onClose,
	preferred_location,
	reloadProfile,
	preferencesCompleted,
	isEmptyLangSkillsFnc,
	redirectToFunctions
}) => {
	const [address, setAddress] = useState({});
	const [locations, setLocations] = useState(preferred_location);
	const [error, setError] = useState(false);
	const [locationError, setLocationError] = useState("");

	const [mutate, { status }] = useMutation(SaveLocations, {
		onSuccess: () => {
			toaster.success("Successfully edited your location.");
			onClose();
			reloadProfile();
		},
		onError: e => {
			if (e && e.status === 422) {
				let errorObj = _get(e, "detail.preferred_location[0]");
				if (errorObj) {
					let keys = Object.keys(errorObj);
					toaster.danger(errorObj[keys[0]]);
				}
			}
		}
	});

	const isAddressInvalid = address => {
		return (
			!_get(address, "street") ||
			!_get(address, "number") ||
			!_get(address, "city") ||
			!_get(address, "country") ||
			!_get(address, "zip")
		);
	};
	const handleAddressChange = data => {
		const newAddress = {
			street: data.street || "",
			country: data.country || "",
			zip: data.zip || "",
			city: data.city || data.administrative_area_level_1 || "",
			number: data.number || "",
			box: data.box || "",
			latitude: data.latitude || "",
			longitude: data.longitude || "",
			formatted_address: data.formatted_address || "",
			iso_country: data.iso_country,
			is_main: false
		};

		if (
			newAddress.country !== _get(address, "country") ||
			newAddress.city !== _get(address, "city")
		) {
			if (
				newAddress.longitude === _get(address, "city") ||
				newAddress.latitude === _get(address, "latitude")
			) {
				newAddress.latitude = 0;
				newAddress.longitude = 0;
			}
		}
		setAddress(newAddress);
		if (isAddressInvalid(newAddress)) {
			setError(true);
		}
	};

	const addLocation = async data => {
		let exists = locations.find(l => {
			if (l.city === data.city && l.country === data.country) {
				return l;
			}
		});
		if (exists) {
			return setLocationError("This location was already added.");
		}
		const { address: finalAddress } = await handleAddressObject(data, true);
		delete finalAddress.formatted_address;
		setLocations([...locations, finalAddress]);
		setAddress({});
	};

	const changeMainLocation = item => {
		if (item.is_main) {
			return;
		}
		const newLocations = locations.map(location => {
			if (
				location.city === item.city &&
				location.country === item.country &&
				location.longitude === item.longitude &&
				location.latitude === item.latitude
			) {
				return { ...location, is_main: true };
			}
			return { ...location, is_main: false };
		});

		setLocations(newLocations);
	};

	const deleteLocation = data => {
		let newLocations = locations.filter(l => {
			return l.city !== data.city && l.country && data.country;
		});

		setLocations(newLocations);
	};

	const submit = async () => {
		if (locations.length < 0 || !locations.find(l => l.is_main)) {
			return setLocationError("Please add at least one main location.");
		}

		await mutate({
			preferred_location: locations
		});
		preferencesCompleted && isEmptyLangSkillsFnc && redirectToFunctions();
	};
	return (
		<Modal
			title={"Preferred location"}
			onClose={onClose}
			firstButton={{
				label: "Save",
				type: "primary",
				action: submit
			}}
			loading={status === LOADING}
		>
			<Content>
				<span className="residency-text">
					After entering (a) location(s), use the check box to identify your
					place of residence.
				</span>
				<GrayBox>
					<LocationBoxContainer>
						{locations.map(item => {
							const location = `${item.city ? `${item.city},` : ""} ${
								item.country
							}`;
							const fullLocation = `${location}, ${item.latitude}, ${item.longitude}`;
							return (
								<LocationBox key={location} flex main={item.is_main || false}>
									<div className="checkbox checkbox-primary">
										<div
											className="input-box"
											style={{ width: "100%", display: "flex" }}
										>
											<input
												type="checkbox"
												className="styled"
												id={fullLocation}
												checked={item.is_main}
												onChange={() => changeMainLocation(item)}
											/>
											<label htmlFor={fullLocation}></label>
										</div>
									</div>
									<GlobalTooltip
										placement={"top"}
										overlay={location}
										ellipsisMode={true}
										overlayClassName="g-tool-dark"
										withWrappingDiv={false}
									>
										<LocationTextBox name="location_box">
											{location}
										</LocationTextBox>
									</GlobalTooltip>
									<ListButton>
										<span
											onClick={() => deleteLocation(item)}
											className="icon-close2"
										/>
									</ListButton>
								</LocationBox>
							);
						})}
					</LocationBoxContainer>
				</GrayBox>
				<Counter>
					<div className="help-block inline-error">{locationError}</div>
					{locations.length}/{MAX_PREFERRED_LOCATION}
				</Counter>
			</Content>
			<Container>
				<AddressBox
					setAddress={handleAddressChange}
					isRequired={true}
					showAutoComplete
					name="location"
					address={address}
					addButton={() => addLocation(address)}
					showToast={error}
					addDisabled={!address.country}
				/>
			</Container>
		</Modal>
	);
};

export default PreferredLocationModal;
