import React, { useState, useEffect } from "react";
import { Flex, Box } from "rebass";
import _get from "lodash/get";
import styled from "styled-components";
import {
	BidsBody,
	TableWrapper,
	JobBidsList,
	Trthead,
	Thead
} from "modules/job/components/JobViewStyled";
import { MAX_CHANNEL_DISPLAY, EUR } from "config";
import { IconContainer } from "modules/vacancy/components/list/style";
import PerformanceList from "./PerformanceList";
import { formatDate } from "common/Functions";
import CurrencyFormatter from "common/CurrencyFormater";
import { ReactComponent as Danger } from "static/icons/dangerIcon.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Th = styled.th`
	border: 0;
	color: #54585c;
	font-weight: 500;
	text-transform: uppercase;
	border-bottom: ${props => (props.border ? "none" : "1px solid #e2e2e2")};
	padding: 15px;
	width: ${props =>
		(props.count && "13%") || (props.name && "13%") || (props.expend && "9%")};
	cursor: normal;
	letter-spacing: normal;
	font-size: 12px;
`;
const Td = styled.td`
	padding: 15px;
	width: ${props =>
		(props.count && "13%") || (props.name && "13%") || (props.expend && "9%")};
	cursor: normal;
	word-break: break-word;
	border-bottom: ${props => (props.border ? "none" : "1px solid #e2e2e2")};
	font-size: 13px;
	vertical-align: middle;
	font-weight: ${props => (props.campaing ? 500 : "normal")};
	color: #3d4b60;

	.channel-name {
		display: flex;
		align-items: center;
	}
	position: relative;
	&:first-child:hover {
		border-left: 3px solid #ff6849;
		padding-left: 12px;
	}

	&.active-performance {
		border-left: 3px solid #ff6849;
		padding-left: 12px;
	}
	&:first-child {
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
	}
	&:last-child {
		border-bottom-right-radius: 2px;
		border-top-right-radius: 2px;
	}
`;

const Tr = styled.tr`
	height: ${props => props.height && props.height};
	background-color: #ffffff;
	cursor: pointer;
	&:last-child {
		${Td} {
			border-bottom: 0 !important;
		}
	}
	&:hover {
		background-color: #f1f2f5;
		border-left: 2px solid red;
	}
	&.active-row {
		background-color: #f1f2f5;
	}
`;

function RequestPerformance({ campaigns, isLoading }) {
	const [dataCampaing, setDataCampaing] = useState([]);
	const [campaignId, setCampaignId] = useState(null);

	useEffect(() => {
		setDataCampaing(campaigns[0]);
		setCampaignId(_get(campaigns[0], "campaign_id"));
	}, [campaigns]);
	const expandPerformanceList = campaign => {
		setDataCampaing(campaign);
		if (campaign.campaign_id === campaignId) {
			setCampaignId(campaignId);
		} else {
			setCampaignId(campaign.campaign_id);
		}
	};
	const skeletonLoading = new Array(
		(
			<Tr className={"active-row"}>
				<Td
					border
					count
					campaing
					style={{
						textAlign: "left",
						width: "11%"
					}}
					expend
					className={"active-performance"}
				>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={40} height={14} />
					</SkeletonTheme>
				</Td>
				<Td
					border
					name
					style={{
						textAlign: "center",
						width: "14%"
					}}
				>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={150} height={14} />
					</SkeletonTheme>
				</Td>
				<Td
					border
					name
					style={{
						textAlign: "left",
						width: "16%"
					}}
				>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={150} height={14} />
					</SkeletonTheme>
				</Td>
				<Td
					border
					name
					style={{
						textAlign: "right",
						width: "11%"
					}}
				>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={80} height={14} />
					</SkeletonTheme>
				</Td>
				<Td
					border
					count
					style={{
						textAlign: "center",
						width: "14%"
					}}
				>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={40} height={14} />
					</SkeletonTheme>
				</Td>
				<Td
					border
					count
					style={{
						textAlign: "center",
						width: "12%"
					}}
				>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={40} height={14} />
					</SkeletonTheme>
				</Td>
				<Td
					border
					count
					style={{
						textAlign: "center",
						width: "14%"
					}}
				>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={40} height={14} />
					</SkeletonTheme>
				</Td>
				<Td border style={{ width: "8%" }}>
					<IconContainer rotate>
						<i className={"icon-down-arrow rotate-icon"} />
					</IconContainer>
				</Td>
			</Tr>
		)
	);
	return (
		<>
			<Flex>
				<Box width={7 / 12} mr={2}>
					<TableWrapper noScroll bg>
						<JobBidsList marginBottom="4px">
							<Thead>
								<Trthead bg="#e5e8ec">
									<Th
										border
										count
										style={{
											textAlign: "center",
											width: "11%"
										}}
									>
										Campaign #
									</Th>
									<Th
										border
										name
										style={{
											textAlign: "center",
											width: "14%"
										}}
									>
										Created on
									</Th>
									<Th
										border
										name
										style={{
											textAlign: "left",
											width: "16%"
										}}
									>
										Created by
									</Th>
									<Th
										border
										name
										style={{
											textAlign: "right",
											width: "11%"
										}}
									>
										Cost
									</Th>
									<Th
										border
										count
										style={{
											textAlign: "center",
											width: "14%"
										}}
									>
										# of channels
									</Th>
									<Th
										border
										count
										style={{
											textAlign: "center",
											width: "12%"
										}}
									>
										# of clicks
									</Th>
									<Th
										border
										count
										style={{
											textAlign: "center",
											width: "14%"
										}}
									>
										# of applicants
									</Th>
									<Th
										border
										expend
										style={{
											width: "8%"
										}}
									></Th>
								</Trthead>
							</Thead>
						</JobBidsList>
						{isLoading || _get(campaigns, "length", -1) > 0 ? (
							<BidsBody
								style={{
									overflow: campaigns?.length <= MAX_CHANNEL_DISPLAY && "unset"
								}}
								scroll={campaigns?.length > MAX_CHANNEL_DISPLAY}
							>
								<JobBidsList borderSpacing>
									<tbody>
										{isLoading
											? skeletonLoading
											: campaigns.map((campaign, index) => {
													return (
														<Tr
															key={campaign.campaign_id}
															className={
																_get(campaign, "campaign_id") === campaignId &&
																"active-row"
															}
															onClick={() =>
																campaigns.length > 1
																	? expandPerformanceList(campaign)
																	: null
															}
														>
															<Td
																border
																count
																campaing
																style={{
																	textAlign: "left",
																	width: "11%"
																}}
																expend
																className={
																	_get(campaign, "campaign_id") ===
																		campaignId && "active-performance"
																}
															>
																{index + 1}
															</Td>
															<Td
																border
																name
																style={{
																	textAlign: "center",
																	width: "14%"
																}}
															>
																{formatDate(campaign.created_on, true)}
															</Td>
															<Td
																border
																name
																style={{
																	textAlign: "left",
																	width: "16%"
																}}
															>
																{`${_get(
																	campaign,
																	"created_by.first_name"
																)} ${_get(campaign, "created_by.last_name")}`}
															</Td>
															<Td
																border
																name
																style={{
																	textAlign: "right",
																	width: "11%"
																}}
															>
																<CurrencyFormatter
																	cent={_get(campaign, "cost.amount", 0)}
																	symbol={EUR}
																/>
															</Td>
															<Td
																border
																count
																style={{
																	textAlign: "center",
																	width: "14%"
																}}
															>
																{_get(campaign, "num_channels")}
															</Td>
															<Td
																border
																count
																style={{
																	textAlign: "center",
																	width: "12%"
																}}
															>
																{_get(campaign, "num_clicks")}
															</Td>
															<Td
																border
																count
																style={{
																	textAlign: "center",
																	width: "14%"
																}}
															>
																{_get(campaign, "num_applicants")}
															</Td>
															<Td border style={{ width: "8%" }}>
																<IconContainer
																	rotate={
																		_get(
																			campaign,
																			"campaign_id",
																			campaignId
																		) === campaignId
																	}
																>
																	<i
																		className={
																			_get(
																				campaign,
																				"campaign_id",
																				campaignId
																			) === campaignId
																				? "icon-down-arrow rotate-icon"
																				: "icon-down-arrow"
																		}
																	/>
																</IconContainer>
															</Td>
														</Tr>
													);
											  })}
									</tbody>
								</JobBidsList>
							</BidsBody>
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									marginTop: "35px",
									alignItems: "center"
								}}
							>
								<Danger style={{ marginRight: "10px" }} />
								<>No results to show </>
							</div>
						)}
					</TableWrapper>
				</Box>
				<Box width={5 / 12}>
					<PerformanceList isLoading={isLoading} campaing={dataCampaing} />
				</Box>
			</Flex>
		</>
	);
}

export default RequestPerformance;
