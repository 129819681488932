import React from "react";
import { connect } from "react-redux";
import { isLoggedInSelector } from "modules/user/selectors/selectors";
import Policy from "./Policy";

export const getPolicyRoute = () => {
	return "https://www.wiggli.io/privacy-policy";
};

const PolicyContainer = ({ isLoggedIn }) => <Policy isLoggedIn={isLoggedIn} />;

const mapStateToProps = state => {
	return {
		isLoggedIn: isLoggedInSelector(state)
	};
};

export default connect(mapStateToProps)(PolicyContainer);
