import React from "react";
import { Text } from "rebass";

export const ListTabs = ({ isActiveTab, title, clickHandler }) => {
	return (
		<button
			onClick={clickHandler}
			style={{
				marginRight: "14px",
				padding: "5px 12px",
				borderRadius: "4px",
				backgroundColor: isActiveTab ? "#FFE9E8" : "white",
				border: "none"
			}}
		>
			<Text
				sx={{
					fontSize: "14px",
					color: isActiveTab ? "#F01C29" : "#303131",
					fontWeight: "500"
				}}
			>
				{title}
			</Text>
		</button>
	);
};
