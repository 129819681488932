import React from "react";
import { ReactComponent as InfoIcon } from "static/icons/info-circle-dark-grey.svg";
import Tooltip from "common/Tippy";
import styles from "./bid-details-tooltips.module.scss";

const FeeTooltip = () => {
	return (
		<Tooltip
			className={styles.tooltip}
			content={
				<div className={styles.container}>
					Wiggli’s fee, which will be added to the rate invoiced by this
					applicant during the mission.
				</div>
			}
			theme="dark"
			placement="bottom"
		>
			<InfoIcon />
		</Tooltip>
	);
};

export default FeeTooltip;
