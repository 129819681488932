import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
import {
	HEADLINE,
	LANGUAGES,
	PREFERENCE,
	FUNCTIONS,
	SKILLS,
	FIRST_LOGIN
} from "config";
import axiosHelper from "config/axios-helper";
import { UPDATE_FUNCTIONS, UPDATE_SKILLS } from "config/api-endpoints";
import FunctionsModal from "modules/job/components/jobprofile/Functions/FunctionsModal";
import SkillsModal from "common/newSearch/Modals/SkillsModal";
import LanguagesModal from "./LanguagesModal";
import PersonalInfoModal from "./PersonalInfoModal";
import PreferenceModal from "./PreferenceModal";
import {
	closeModal,
	removeEducation,
	removeExperience,
	reloadSection,
	loadProfile,
	changeTab
} from "../../actions";
import { getSkills } from "modules/job/actions";
import { ListProvider } from "common/editablelist/Next/listContext";
import { getMyProfile } from "modules/user/actions/authActions";
import IntroProfileModal from "./IntroProfileModal";

class ModalsContainer extends Component {
	closeModal = () => {
		this.props.closeModal();
	};

	saveFunctions = (functions, userId) => {
		const { isConsultancy, bid_id } = this.props;
		const formattedFunctions = functions.map(f => ({
			is_main: f.is_main || false,
			name: f.name,
			position: f.position,
			sector_id: f.sector._id,
			seniority: f.seniority,
			_id: f._id
		}));
		return axiosHelper
			.post({
				url: UPDATE_FUNCTIONS,
				data: { functions: formattedFunctions, user_id: userId },
				isModal: true,
				toastMessage: "Functions updated!"
			})
			.then(() => {
				this.props.loadProfile(isConsultancy ? bid_id : null);
				this.closeModal();
				if (!isConsultancy) {
					this.props.getMyProfile();
				}
			});
	};

	saveSkills = (skills, userId) => {
		const { isConsultancy, bid_id } = this.props;
		const formattedSkills = skills.map(s => ({
			_id: s._id,
			name: s.name,
			score: s.score,
			parent_sector: s.parent_sector
		}));
		return axiosHelper
			.post({
				url: UPDATE_SKILLS,
				data: { skills: formattedSkills, user_id: userId },
				isModal: true,
				toastMessage: "Skills updated!"
			})
			.then(() => {
				this.props.loadProfile(isConsultancy ? bid_id : null);
				this.closeModal();
			});
	};
	getSkillsCategories = skills => {
		let categories = [
			...new Set(
				skills.map(s => _get(s, "parent_sector._id", undefined)).filter(Boolean)
			)
		];

		return categories;
	};
	render() {
		const {
			showModal,
			modalName,
			userId: cvId,
			newUserId: userId,
			userStatus,
			group,
			functions,
			initialSkills
		} = this.props;
		const { isConsultancy, bid_id } = this.props;
		if (!showModal) return null;
		switch (modalName) {
			case FUNCTIONS:
				return (
					<div className="create-job">
						<FunctionsModal
							functions={functions}
							onClose={this.closeModal}
							onSave={this.saveFunctions}
							closeOnSave={false}
							skillsCategories={this.getSkillsCategories(initialSkills)}
							removeSkills={() => {}}
							saveActionName={"Save to my profile"}
							isFreelancer={true}
							userId={userId}
						/>
					</div>
				);
			case SKILLS:
				return (
					<ListProvider>
						<SkillsModal
							skills={initialSkills}
							saveSkills={this.saveSkills}
							closeModal={this.closeModal}
							selectedFunctions={functions}
							showMustHave={false}
							cartTitle={"My skills"}
							saveActionName={"Save to my profile"}
							closeOnSave={false}
							userId={userId}
							modalHeadText={
								<div className="skills-intro">
									<span className="icon-softwarning" />
									It’s important that you be precise about your skill mastery as
									the mission matching process’s accuracy and your credibility
									depend on it.
								</div>
							}
							saveActionStyle={{
								padding: "15px 30px 30px"
							}}
						/>
					</ListProvider>
				);
			case LANGUAGES:
				return <LanguagesModal userId={userId} />;
			case HEADLINE:
				return (
					<PersonalInfoModal
						group={group}
						userId={isConsultancy ? bid_id : cvId}
						userStatus={userStatus}
					/>
				);
			case FIRST_LOGIN:
				return (
					<IntroProfileModal
						closeModal={this.props.closeModal}
						changeTab={this.props.changeTab}
					/>
				);
			case PREFERENCE:
				return <PreferenceModal userId={userId} cvId={cvId} />;
			default:
				return null;
		}
	}
}

const mapStateToProps = state => {
	return {
		showModal: state.cv.showModal,
		modalName: state.cv.modalName,
		modalData: state.cv.modalData,
		initialSkills: state.cv.skills,
		activeTab: state.cv.activeTab
	};
};

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			removeEducation,
			removeExperience,
			closeModal,
			reloadSection,
			getSkills,
			loadProfile,
			getMyProfile,
			changeTab
		},
		dispatch
	)
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsContainer);
