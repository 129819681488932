/* eslint-disable react/display-name */
import React from "react";
import { ConfirmModalBody } from "../ConfirmActionModal";

export const MUTE = "mute";
export const UNMUTE = "unmute";
export const FOLLOW = "follow";
export const UNFOLLOW = "unfollow";
export const LEAVE_POOL = "leave-pool";
export const LEAVE_POOL_IS_FOLLOW = "leave-pool-when-follow";
export const LEAVE_POOL_IS_UNFOLLOW = "leave-pool-when-unfollow";
export const LEAVE_POOL_MULTI_IS_FOLLOW = "leave-pool-when-follow-multisite";
export const LEAVE_POOL_MULTI_IS_UNFOLLOW =
	"leave-pool-when-unfollow-multisite";

const checkboxLabel =
	"By checking this box, I confirm my consent for the deletion of my data from the database of this company.";
export const MODALS = {
	[UNFOLLOW]: {
		render: () => (
			<ConfirmModalBody
				confirmMessage={
					<p>
						You will stop receiving job alerts from this company.
						<br /> Are you sure you want to proceed ?
					</p>
				}
			/>
		)
	},
	[LEAVE_POOL_IS_FOLLOW]: {
		render: checkboxState => (
			<ConfirmModalBody
				checkbox={{ ...checkboxState, label: checkboxLabel }}
				confirmMessage={
					<p>
						You will be removed from the talent pool.
						<br />
						Are you sure you want to proceed ?
					</p>
				}
			/>
		)
	},
	[LEAVE_POOL_IS_UNFOLLOW]: {
		render: checkboxState => (
			<ConfirmModalBody
				checkbox={{ ...checkboxState, label: checkboxLabel }}
				confirmMessage={
					<p>
						You will be removed from the talent pool of this company and stop
						receiving its job alerts.
					</p>
				}
			/>
		)
	},
	[LEAVE_POOL_MULTI_IS_FOLLOW]: {
		render: checkboxState => (
			<ConfirmModalBody
				checkbox={{ ...checkboxState, label: checkboxLabel }}
				confirmMessage={
					<p>
						You will be removed from the talent pool of this company and its
						subsidiaries. <br /> Are you sure you want to proceed ?
					</p>
				}
			/>
		)
	},
	[LEAVE_POOL_MULTI_IS_UNFOLLOW]: {
		render: checkboxState => (
			<ConfirmModalBody
				checkbox={{ ...checkboxState, label: checkboxLabel }}
				confirmMessage={
					<p>
						You will be removed from the talent pool of this company and its
						subsidiaries and stop receiving their job alerts.
					</p>
				}
			/>
		)
	}
};

export const getModalSlug = (company, actionType) => {
	if (actionType === UNFOLLOW) return UNFOLLOW;
	else if (actionType === LEAVE_POOL) {
		if (company.is_multisite_enabled) {
			if (company.is_followed) return LEAVE_POOL_MULTI_IS_FOLLOW;
			else return LEAVE_POOL_MULTI_IS_UNFOLLOW;
		} else {
			if (company.is_followed) return LEAVE_POOL_IS_FOLLOW;
			else return LEAVE_POOL_IS_UNFOLLOW;
		}
	}
	return "";
};
