import React, { Component, Fragment } from "react";
import BodyClassName from "react-body-classname";
import { browserHistory } from "react-router";
import { LoaderFull } from "common/Loader";
import CompanyDashboard from "common/dashboard/Dashboard";
import Intro from "common/dashboard/Intro";
import Pagination from "common/Pagination";
import EmptyBids from "common/EmptyComponent";
import VendorBidItem from "../VendorBidItem";
import { getFreelancerBidViewRoute } from "../bidview/freelancer";
import CancelBidModal from "../modals/CancelBidModal";
import {
	TimesheetTableDetails,
	Th
} from "../../../timesheets/components/TimesheetStyled";
import { Helmet } from "react-helmet";
import FilterByStatus from "../../../job/components/FilterByStatus";

class FreelancerBidsList extends Component {
	constructor() {
		super();
		this.showListBids = this.showListBids.bind(this);
	}

	state = {
		showBidModal: false,
		my_job_status: ""
	};

	filterList = status => {
		const payload = {
			offset: 0
		};
		if (status !== "active_jobs") payload.status = status;
		this.props.filterFreelancerBidsList(payload, status);
		this.setState({ my_job_status: status });
	};

	handlePageClick = value => {
		const {
			bids: { pagination },
			getBidsList
		} = this.props;
		const status = this.state.my_job_status;
		const payload = {
			offset: pagination.size * value
		};
		if (status !== "active_jobs") payload.status = status;
		getBidsList(payload);
	};

	goToBidView(id) {
		browserHistory.push(getFreelancerBidViewRoute(id));
	}

	showListBids() {
		const { listBids } = this.props.bids;
		return listBids.map(bid => {
			return (
				<VendorBidItem
					bid={bid}
					key={bid._id}
					viewDetails={this.goToBidView}
					openModal={() => this.toggleModal(bid)}
				/>
			);
		});
	}

	selected_bid = {
		_id: "",
		stepper_status: "",
		interview_id: "",
		contract_id: ""
	};

	toggleModal = bid => {
		if (bid) {
			this.selected_bid = bid;
		}
		this.setState({ showBidModal: !this.state.showBidModal });
	};

	render() {
		const {
			bids: { listBids, inAction, isPagination, pagination },
			route: { title }
		} = this.props;

		const { showBidModal } = this.state;

		const listBidsEmpty = listBids.length <= 0;

		return (
			<BodyClassName className="opened-jobs gray-bg">
				<div className="bids-box">
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<div className="dashboard-box">
						<CompanyDashboard />
						<Intro subTitle="This page displays an overview of all mission requests that you have interacted with." />
					</div>
					<div className="container">
						<div className="my-jobs-filter">
							<FilterByStatus filterList={this.filterList} myJobs={true} />
						</div>
						{listBidsEmpty && (
							<EmptyBids
								title="No bid placed so far"
								paragraph="Bidding on requests from clients is the first step toward working on your first mission via wiggli. To do so, just use the link below to look for missions matching your skills and expectations."
								actionLink="/jobs/search"
								actionText="Search missions"
							/>
						)}
						{!listBidsEmpty && (
							<Fragment>
								<TimesheetTableDetails spacing="0 3px">
									<thead>
										<tr>
											<Th width="8%">job ref</Th>
											<Th width="16%">company name</Th>
											<Th width="15%">title</Th>
											<Th width="20%">start / end date</Th>
											<Th width="14%">time commitment</Th>
											<Th align="center" width="16%">
												status
											</Th>
											<Th width="8%">actions</Th>
										</tr>
									</thead>
									<tbody>{this.showListBids()}</tbody>
								</TimesheetTableDetails>

								{isPagination && (
									<Pagination
										total={pagination.total}
										size={pagination.size}
										handlePageClick={this.handlePageClick}
										offset={pagination.offset}
									/>
								)}

								{inAction && (
									<LoaderFull boxClasses="no-text" classes="full-loader" />
								)}
							</Fragment>
						)}

						<CancelBidModal
							key="modal"
							bidId={this.selected_bid._id}
							stepperStatus={this.selected_bid.stepper_status}
							interviewId={this.selected_bid.interview_id}
							contractId={this.selected_bid.contract_id}
							active={showBidModal}
							onClose={this.toggleModal}
						/>
					</div>
				</div>
			</BodyClassName>
		);
	}
}

export default FreelancerBidsList;
