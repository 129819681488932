import { client } from "lib/api-client";
import {
	DECLINE_INTERVIEW,
	INVITE_USER_TO_INTERVIEW
} from "config/api-endpoints";
import { useMutation } from "react-query";

const inviteUserToInterview = ({ isDeclineInterview = false, ...data }) => {
	return client(
		isDeclineInterview ? DECLINE_INTERVIEW : INVITE_USER_TO_INTERVIEW,
		{
			body: {
				...data
			}
		}
	);
};

export function useInviteUserToInterview() {
	return useMutation(inviteUserToInterview);
}
