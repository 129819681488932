import React, { Component } from "react";
import PropTypes from "prop-types";

const Loader = ({
	loading = true,
	done = false,
	check = true,
	classes = "",
	style = null
}) => {
	if (!loading && !done) return null;
	const className = done ? "loading done" : "loading loadingAnimating";
	return (
		<div className={`loader ${classes} ${className}`}>
			{style ? <span style={style} /> : <span />}
			{check && <i className="fa icon-check" aria-hidden="true" />}
		</div>
	);
};

class LoaderFull extends Component {
	render() {
		const {
			classes,
			text,
			children,
			boxClasses,
			loading,
			style = null
		} = this.props;

		if (!loading) return null;

		return (
			<div className={`loading-box ${boxClasses}`}>
				<div className="inner">
					<Loader classes={classes} check={false} style={style} />
					{text && text}
					{children && <div className="children">{children}</div>}
				</div>
			</div>
		);
	}
}

LoaderFull.propTypes = {
	boxClasses: PropTypes.string
};

LoaderFull.defaultProps = {
	loading: true,
	boxClasses: "",
	classes: ""
};

export { Loader, LoaderFull };
export default Loader;
