import React from "react";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tooltip from "rc-tooltip";
import { OutlinedLink } from "common/styled/buttons";
import CompanyDashboard from "common/dashboard/Dashboard";
import Icon from "common/styled/icons";
import { MatchesTopBar, SearchCriterias, ProfilesContainer } from "./Styled";
import EmptyMatches from "common/search/EmptySearch";
import { fetchJobs, updateFilter } from "modules/vacancy/actions";
import {
	profilesListSelector,
	jobDetailsSelector,
	searchProfileSelector,
	filterSelector
} from "modules/vacancy/selectors";
import ProfileItem from "../profileSearch/ProfileItem";
import Pagination from "common/Pagination";
import { getSkillsTooltip } from "config/helpers";
import { getLanguageTooltip } from "config/helpers";
import Star from "common/Icons/Star";
import { getVacancyViewRoute } from "../vacancyView";

class PotentialMatches extends React.Component {
	componentDidMount() {
		this.fetchPotentialMatches();
	}

	fetchPotentialMatches() {
		const { id } = this.props.params;
		this.props.fetchJobs({ id });
	}

	renderSkillsAndLanguages(array, getTooltipOverlay) {
		return array.map(element => {
			return element.must_have ? (
				<Tooltip
					key={element._id}
					placement="top"
					overlay={
						<div>
							<div>
								This skill was marked as a <strong>MUST HAVE</strong>.
							</div>
							Candidates lacking this skill will be excluded from the potential
							matches list.
						</div>
					}
					trigger="hover"
					destroyTooltipOnHide={true}
					mouseLeaveDelay={0}
					overlayClassName="g-tool-white"
				>
					<div className="box">
						<Star className="must_have_star" />
						{`${element.name} ( ${element.score} / 5 )`}
					</div>
				</Tooltip>
			) : (
				<Tooltip
					key={element._id}
					placement="top"
					overlay={<div>{getTooltipOverlay(element.score)}</div>}
					trigger="hover"
					destroyTooltipOnHide={true}
					mouseLeaveDelay={0}
					overlayClassName="g-tool-white"
				>
					<div className="box">
						{`${element.name} ( ${element.score} / 5 )`}
					</div>
				</Tooltip>
			);
		});
	}
	renderPotentialMatches = profiles => {
		return profiles.map(profile => {
			return (
				<ProfileItem
					key={profile.id}
					vacancyId={this.props.params.id}
					profile={profile}
					className="potential-match"
				/>
			);
		});
	};
	handlePageClick = selected => {
		const filter = { ...this.props.filter };
		const { search } = this.props;
		if (filter.offset === search.size * selected) return;
		filter.offset = search.size * selected;
		filter.id = this.props.params.id;
		this.props.updateFilter(filter, true, true);
	};
	render() {
		const {
			route: { title },
			profiles,
			job,
			search: { total, size },
			filter
		} = this.props;
		const vacancyId = this.props.params.id;
		return (
			<BodyClassName className="jobs gray-bg">
				<div id="job-search">
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<CompanyDashboard />
					<div className="container">
						<OutlinedLink
							has-icon
							small={true}
							to={getVacancyViewRoute(vacancyId)}
						>
							<Icon moveOnHover className="icon-arrow-left" />
							Back to vacancy
						</OutlinedLink>
						<MatchesTopBar>
							<div className="title">POTENTIAL MATCHES</div>
							<div className="description">
								You will find below the candidates that could be potentially
								interesting for your vacancy, based on the fact that they match
								- part of - the following criterias:
							</div>
							<SearchCriterias>
								<div className="criteria">
									<div className="title">Profile</div>
									<div className="content">
										{job.category} - {job.subcategory} - {job.function}
									</div>
								</div>
								<div className="criteria">
									<div className="title">Skills</div>
									<div className="content">
										{this.renderSkillsAndLanguages(
											job.skills,
											getSkillsTooltip
										)}
									</div>
								</div>
								<div className="criteria">
									<div className="title">Languages</div>
									<div className="content">
										{this.renderSkillsAndLanguages(
											job.languages,
											getLanguageTooltip
										)}
									</div>
								</div>
								<div className="criteria">
									<div className="title">Location</div>
									<div className="content">
										<div className="address">
											<span>{job.street}</span>
											<span>
												{job.zip} {job.city}
											</span>
											<span className="country">{job.country}</span>
										</div>
									</div>
								</div>
							</SearchCriterias>
						</MatchesTopBar>
						{profiles.length === 0 ? (
							<EmptyMatches
								title="There is no potential match at this stage."
								text={
									<span>
										Given wiggli’s dynamic talent pool, this can change anytime,
										through new candidates registration via our talent
										acquisition officers or updated candidate status. <br />
										Make sure to regularly check the potential matches on your
										vacancy; or use to SEARCH tab to broaden the scope of your
										search.
									</span>
								}
								className="empty-matches"
							/>
						) : (
							<>
								<ProfilesContainer>
									{this.renderPotentialMatches(profiles)}
								</ProfilesContainer>
								<div className="ac">
									<Pagination
										total={total}
										size={size}
										handlePageClick={this.handlePageClick}
										offset={filter.offset}
										initialSelected={filter.offset / size}
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</BodyClassName>
		);
	}
}

export const getPotentialMatchesRoute = vacancyId =>
	`/vacancy/${vacancyId || `:id`}/matches`;

export const getViewPotentialMatchesPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const mapStateToProps = state => ({
	profiles: profilesListSelector(state),
	job: jobDetailsSelector(state),
	search: searchProfileSelector(state),
	filter: filterSelector(state)
});
const mapDispatchToProps = dispatch =>
	bindActionCreators({ fetchJobs, updateFilter }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PotentialMatches);
