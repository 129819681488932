import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

const getConsultant = (consultant, keyword) =>
	client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "supplier_consultant",
			consultancy_company_id: consultant,
			search: keyword
		}
	});

export const GET_CONSULTANT_QUERY_KEY = "GET_CONSULTANT_QUERY_KEY";

export const useGetConsultantList = (consultant, value) => {
	const res = useQuery(
		[GET_CONSULTANT_QUERY_KEY, consultant, value],
		() => getConsultant(consultant, value),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			enabled: !!consultant,
			keepPreviousData: true
		}
	);

	return res;
};
