import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { EDIT_JOB_LOCATION } from "config/api-endpoints";

const editJobLocation = data => {
	return client(EDIT_JOB_LOCATION, {
		body: data
	});
};

const useEditJobLocation = (queryOptions = {}) =>
	useMutation(editJobLocation, queryOptions);
export default useEditJobLocation;
