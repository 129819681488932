import React, { useState } from "react";
import { usePopper } from "react-popper";
import useOnClickOutside from "hooks/useOnClickOutside";
import cx from "classnames";
import get from "lodash/get";
import { ReactComponent as MenuIcon } from "static/icons/menu-icon-tasks.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete-icon-tasks.svg";
import { ReactComponent as MarkAsCompleteIcon } from "static/icons/mark-as-complete-icon.svg";
import { ReactComponent as ReminderIcon } from "static/icons/reminder-icon.svg";
import { ReactComponent as EditIcon } from "static/icons/edit-tasks-icon.svg";
import classnames from "./action.module.scss";
import Reminder from "./reminder";
import { COMPLETED, UPCOMING } from "config";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

const Actions = ({
	userConnected,
	createdBy,
	assignedUser,
	id,
	status,
	markAsComplete,
	deleteTask,
	dataTask,
	dueDate,
	setHighlitedRow
}) => {
	const [openMenu, setOpenMenu] = useState(false);
	const [openReminderMenu, setOpenReminderMenu] = useState(false);
	const [menuTargetRef, setMenuTargetRef] = useState(false);
	const [menuElementRef, setMenuElementRef] = useState(false);
	const [reminderTargetRef, setReminderTargetRef] = useState(false);
	const [arrowElementRef, setArrowElementRef] = useState(false);
	const { setIsOpen, setTaskData } = useAddNewTaskStore();

	useOnClickOutside(
		{ current: menuElementRef },
		() => {
			if (!openReminderMenu) {
				setOpenMenu(false);
			}
		},
		[openReminderMenu]
	);

	const { styles, attributes } = usePopper(menuTargetRef, menuElementRef, {
		enabled: true,
		eventsEnabled: true,
		placement: "top",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, -10]
				}
			},
			{ name: "arrow", options: { element: arrowElementRef } }
		]
	});

	const handleOpenMenu = async () => {
		setOpenMenu(true);
	};
	const handleOpenReminderMenu = () => setOpenReminderMenu(true);
	const closeOpenMenu = () => setOpenMenu(false);
	const closeReminderMenu = () => setOpenReminderMenu(false);

	const canEdit =
		get(userConnected, "id") === get(createdBy, "_id") && status !== COMPLETED;
	const canDelete =
		get(userConnected, "id") === get(createdBy, "_id") && status !== COMPLETED;
	const canMarkedAsComplted =
		get(userConnected, "id") === get(assignedUser, "_id") &&
		status !== COMPLETED;

	const canAddReminder =
		get(userConnected, "id") === get(assignedUser, "_id") &&
		status !== COMPLETED;

	const isBeforeOptions = status === UPCOMING;

	const openEditTask = () => {
		setTaskData(dataTask);
		setIsOpen(true);
	};

	const showActionMenu =
		canEdit || canDelete || canMarkedAsComplted || canAddReminder;

	return (
		<div
			className={cx(classnames.container, {
				[classnames.isEmpty]: !showActionMenu
			})}
		>
			<button
				ref={setMenuTargetRef}
				disabled={!showActionMenu}
				onClick={handleOpenMenu}
			>
				<MenuIcon className={classnames.icon} />
			</button>
			{openMenu && (
				<React.Fragment>
					<div className={classnames.backdrop}></div>
					<div ref={setArrowElementRef} style={styles.arrow}></div>
					<div
						ref={setMenuElementRef}
						className={classnames.menuItems}
						style={styles.popper}
						{...attributes.popper}
					>
						{canEdit && (
							<button onClick={openEditTask} className={classnames.menuItem}>
								<EditIcon /> <span> Edit</span>
							</button>
						)}
						{canMarkedAsComplted && (
							<button className={classnames.menuItem} onClick={markAsComplete}>
								<MarkAsCompleteIcon /> <span> Mark as completed</span>
							</button>
						)}
						{canDelete && (
							<button className={classnames.menuItem} onClick={deleteTask}>
								<DeleteIcon /> <span>Delete</span>
							</button>
						)}
						{canAddReminder && (
							<React.Fragment>
								<button
									onClick={handleOpenReminderMenu}
									className={classnames.menuItem}
									ref={setReminderTargetRef}
								>
									<ReminderIcon />
									<span> Reminder</span>
								</button>
								{openReminderMenu && (
									<div className={classnames.container}>
										<Reminder
											id={id}
											onClose={closeReminderMenu}
											closeOpenMenu={closeOpenMenu}
											isBeforeOption={isBeforeOptions}
											dueDate={dueDate}
											reminderTargetRef={reminderTargetRef}
											arrowElementRef={arrowElementRef}
											setHighlitedRow={setHighlitedRow}
										/>
									</div>
								)}
							</React.Fragment>
						)}
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default Actions;
