import React from "react";
import Form from "modules/TimesheetTags/components/timesheetReporter/form/Form";

const TimesheetReporting = ({
	isLoadingContainer,
	data,
	isSaving,
	saveHandler,
	currencies = []
}) => {
	return (
		<div>
			<Form
				isLoadingContainer={isLoadingContainer}
				data={data}
				isSaving={isSaving}
				saveHandler={saveHandler}
				currencies={currencies}
			/>
		</div>
	);
};

export default TimesheetReporting;
