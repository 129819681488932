import React from "react";
import { Headline } from "../styles";
const maxLength = 10000;
const AddInterviewNote = ({
	onChange,
	value,
	placeholder = "Write a note to the interview attendees..."
}) => {
	return (
		<div className="interview-note">
			<Headline>Interview note</Headline>
			<div className="add-note-form">
				<textarea
					id="note"
					placeholder={placeholder}
					className={
						"add-note-text" + (value.length === maxLength ? "error" : "")
					}
					value={value}
					onChange={({ target }) => {
						if (target.value.length <= maxLength) {
							onChange(target.value);
							return;
						}
					}}
				/>
				<p
					className={
						"max-length " + (value.length === maxLength ? "error" : "")
					}
				>
					{value.length} / {maxLength}
				</p>
			</div>
		</div>
	);
};

export default AddInterviewNote;
