import React from "react";
import CustomSelect from "common/CustomSelect/Select";
import CustomGroupedSelect from "./CustomGroupedSelect";
import Renderer from "./Renderer";
import Opener from "./Opener";
import { CoumpoundSelectContainer } from "./styles";

function CoumpoundSelect({ children }) {
	return <CoumpoundSelectContainer>{children}</CoumpoundSelectContainer>;
}
CoumpoundSelect.Select = CustomSelect;
CoumpoundSelect.CustomGroupedSelect = CustomGroupedSelect;
CoumpoundSelect.Renderer = Renderer;
CoumpoundSelect.Opener = Opener;

export { CoumpoundSelect };
