import React from "react";
import { ReactComponent as Hide } from "static/icons/hide.svg";
import HeaderButton from "modules/SearchCandidate/HeaderButton";

const HideButton = ({ onClick, isLoading }) => {
	return (
		<HeaderButton
			icon={<Hide />}
			text="Delete"
			className="btnDelete"
			variant="big"
			onClick={onClick}
			isLoading={isLoading}
		/>
	);
};

HideButton.displayName = "HideButton";

export default HideButton;
