import React from "react";
import _get from "lodash/get";
import { Controller } from "react-hook-form";
import AddField from "../AddField";
import ToolTip from "common/GlobalTooltip";
import { ReactComponent as ErrorIcon } from "static/icons/error-field.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete.svg";
import styles from "./../../profile.module.scss";
import cardStyle from "../EditableSection/editable-section.module.scss";
import { EXTERNAL_LINKS } from "config";
import personalProfileStyles from "./personal-profile.module.scss";

const LinkFields = ({
	isEditMode,
	errors,
	control,
	external_links,
	onDelete,
	isNewExternalProfileExists,
	onBlur: onBlurProp,
	onKeyDown,
	onPrimaryLinkClick,
	onAdd,
	onChange: onChangeProp,
	register,
	linkFields,
	personalProfile
}) => {
	function isNewExternalLink(externalLink) {
		const externalLinks = _get(personalProfile, EXTERNAL_LINKS, []);
		const index = externalLinks.findIndex(
			externalLInkObject => externalLInkObject.link === externalLink
		);

		return index === -1;
	}

	return (
		<div className={cardStyle.grid}>
			{linkFields.map((item, index) => {
				const error = _get(errors, `external_links[${index}].link.message`);

				const { name, ref, onChange, onBlur } = register(
					`external_links[${index}].link`
				);

				return (
					<div key={item.id} className={styles.externalLinkBox}>
						{index === 0 && (
							<div className={styles.personalLink}>
								External profile
								<ToolTip
									placement={"right"}
									maxWidth={"30rem"}
									align={{
										offset: [15, 0]
									}}
									overlay={
										"Link to a social/professional network profile or online portfolio"
									}
								>
									<InfoIcon className={styles.infoIcon} />
								</ToolTip>
							</div>
						)}
						<div className={cardStyle.info}>
							<input
								defaultValue={`${item.link}`}
								type="text"
								disabled={!(isNewExternalLink(item.link) || isEditMode)}
								placeholder="Github.com/claudia.cjames"
								onChange={e => {
									onChange(e);
									onChangeProp(index);
								}}
								onBlur={e => {
									onBlur(e);
									onBlurProp(e);
								}}
								onKeyDown={onKeyDown}
								name={name}
								ref={ref}
							/>
							{isEditMode && !error ? (
								<button
									className={styles.deleteItem}
									disabled={item.is_primary}
									onClick={e => onDelete(e, index)}
								>
									<DeleteIcon />
									Delete item
								</button>
							) : _get(errors, `external_links[${index}].link.message`) ? (
								<div className={styles.error}>
									<ErrorIcon />
									{_get(errors, `external_links[${index}].link.message`)}{" "}
								</div>
							) : (
								(!isNewExternalProfileExists ||
									!(index === linkFields.length - 1)) && (
									<span className={styles.radio_input}>
										<Controller
											name={`external_links[${index}].is_primary`}
											control={control}
											render={({ field: { value } }) => {
												return (
													<input
														type="radio"
														className="styled"
														name={`external_links.is_primary`}
														checked={value}
														onChange={e => onPrimaryLinkClick(e, index)}
														id={item.id}
													/>
												);
											}}
										/>
										<span className={styles.radio_control}></span>
										<label
											className={personalProfileStyles.radioButtonLabel}
											htmlFor={item.id}
										>
											Set as primary
										</label>
									</span>
								)
							)}{" "}
						</div>
					</div>
				);
			})}

			<div className={styles.addBox}>
				{linkFields.length === 0 && (
					<div className={cardStyle.label}>External profile</div>
				)}
				{!(isEditMode || isNewExternalProfileExists) && (
					<AddField
						onClick={onAdd}
						label={"Add external profile"}
						disabled={
							isEditMode ??
							_get(
								errors,
								`external_links[${external_links.length - 1}].link.message`,
								""
							).length > 5 ??
							(external_links.length !== 0 &&
								!Boolean(external_links[external_links.length - 1]?.link))
						}
					/>
				)}
			</div>
		</div>
	);
};
export default LinkFields;
