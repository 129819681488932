import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import Drawer from "rc-drawer";
import cx from "classnames";
import _get from "lodash/get";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import _find from "lodash/find";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "static/icons/delete-tag-multi-select-input.svg";
import AddEmailModal from "modules/SearchCandidate/components/ShortListDrawer/AddEmailModal";
import ShortListingDoneModal from "modules/SearchCandidate/components/ShortListDrawer/ShortListingDoneModal";
import useShortList from "modules/SearchCandidate/components/ShortListDrawer/useShortList";
import { searchParser } from "config/helpers";
import {
	FILTER_SETTINGS_ENDPOINT,
	SHORTLIST_CANDIDATE,
	KANBAN_VIEW_SETTING
} from "config/api-endpoints";
import { APPLICATION, SHORTLIST } from "config";
import { client } from "lib/api-client";
import toaster from "common/Toaster";
import Loader from "common/Loader";
import usePoolStore from "modules/SearchCandidate/hooks/zustandPool";
import style from "./shortListDrawer.module.scss";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getVacancies = () => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "vacancy_shortlist"
		}
	});
};

const getKanbanViewSetting = (_key, job_id) =>
	client(KANBAN_VIEW_SETTING, {
		body: {
			job_id
		}
	});
const shortListCandidate = data => client(SHORTLIST_CANDIDATE, { body: data });
const getListCandidate = data =>
	client(FILTER_SETTINGS_ENDPOINT, { body: data });

const ShortListDrawer = () => {
	const { select_data, profile_id, setProfileId } = usePoolStore();
	const activeCompany = useSelector(state => state.auth.user.active_company);
	const searchQueries = searchParser();

	const mode = _get(select_data, "mode");
	const selectedCandidates = _get(
		select_data,
		"selected_candidates_for_ShortList"
	);
	const shortListIds = _get(select_data, "shortList");

	let IdsShortList = [];

	if (mode === "query") {
		IdsShortList = shortListIds;
	} else {
		IdsShortList = selectedCandidates;
	}
	const { isOpen, setIsOpen } = useShortList();
	const [shortListDone, setShortListDone] = useState(false);
	const [addEmailModal, setAddEmailModal] = useState(false);
	const schema = yup.object().shape({
		select_vacancy: yup.object().required("Please select a vacancy"),
		step: yup.object().required("Please choose a step")
	});
	const {
		control,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
		reset
	} = useForm({
		resolver: yupResolver(schema),
		shouldUnregister: false,
		defaultValues: {}
	});

	const allField = watch();

	const onSubmit = values => {
		const payload = {
			job_id: _get(values, "select_vacancy.value"),
			profile_ids: IdsShortList,
			step_id: _get(values, "step.value")
		};
		mutate(payload);
	};

	const closeDrawer = () => {
		setIsOpen(false);
		reset();
		setProfileId("");
	};

	const { workflowId } = useOnboardingStore();
	const { data = [] } = useQuery(
		["getVacanciesShortList", { profile_id }],
		getVacancies,
		{
			refetchOnWindowFocus: false,
			enabled: !workflowId,
			onSuccess: data => {
				const vacanciesOptions = data.vacancies?.map(item => ({
					label: item.title,
					value: item._id
				}));
				const vacancy = _find(vacanciesOptions, [
					"value",
					_get(searchQueries, "vacancy-id")
				]);
				setValue("select_vacancy", vacancy);
			}
		}
	);

	const { data: vacancySteps, isLoading: stepsLoading } = useQuery(
		["getClientVacancies", _get(allField, "select_vacancy.value")],
		getKanbanViewSetting,
		{
			enabled: Boolean(_get(allField, "select_vacancy.value")),
			refetchOnWindowFocus: false,
			onSuccess: res => {
				if (Boolean(_get(searchQueries, "step"))) {
					const step = _find(res, ["type", _get(searchQueries, "step")]);
					return setValue("step", { label: step.name, value: step._id });
				} else {
					const step = _find(res, ["type", SHORTLIST]);
					return setValue("step", { label: step.name, value: step._id });
				}
			}
		}
	);
	const vacanciesOptions = data.vacancies?.map(item => ({
		label: item.title,
		value: item._id
	}));
	const kanbanSteps = Boolean(_get(vacancySteps, "length", 0))
		? vacancySteps
				.filter(item => _get(item, "type") !== APPLICATION)
				.map(item => ({ label: item.name, value: item._id }))
		: [];

	const [mutate, { isLoading }] = useMutation(shortListCandidate, {
		onSuccess: () => {
			setShortListDone(true);
			setIsOpen(false);
		},
		onError: error => {
			toaster.danger(_get(error, "detail.name"));
		}
	});

	const [
		mutateListCandidate,

		{ data: ListCandidate = [], isLoading: isLoadingListCandidate }
	] = useMutation(getListCandidate, {
		onSuccess: () => {
			setAddEmailModal(true);
			setShortListDone(false);
		},
		onError: error => {
			toaster.danger(_get(error, "detail.name"));
		}
	});
	const notify = elem => {
		let arr = [];
		elem ? (arr = [...IdsShortList, elem]) : (arr = IdsShortList);
		const payload = {
			tag: "list_users",
			profile_ids: arr
		};
		mutateListCandidate(payload);
	};
	return (
		<>
			<Drawer
				open={isOpen}
				width={`380px`}
				height={"100%"}
				placement={"right"}
				style={{ zIndex: 1000 }}
				level={"root"}
				maskClosable={true}
				onClose={closeDrawer}
				className="shortList_drawer"
			>
				<div
					className={cx(
						{ [style.rootLoading]: isLoading },
						{ [style.root]: !isLoading }
					)}
				>
					<div className={style.header_drawer}>
						<div className={style.flex_align_center}>
							<h2 className={style.title_drawer}>Add to vacancy</h2>
							<div className={style.recipients_box}>
								{IdsShortList && IdsShortList.length}{" "}
								{IdsShortList && IdsShortList.length === 1
									? "candidate"
									: "candidates"}
							</div>
						</div>
						<button className={style.button} onClick={closeDrawer}>
							<CloseIcon />
						</button>
					</div>
					<form className={style.form_fields} onSubmit={handleSubmit(onSubmit)}>
						<div className={style.stepField}>
							<h4>Select a vacancy</h4>
							<Controller
								name="select_vacancy"
								control={control}
								render={({ field: { onChange, value } }) => {
									return (
										<Select
											clearable={false}
											searchable={true}
											options={vacanciesOptions}
											onChange={option => onChange(option)}
											value={value}
											className="select_vacancy_shortList"
										/>
									);
								}}
							/>
							{_get(errors, "select_vacancy.message") && (
								<div className={style.errors}>
									{_get(errors, "select_vacancy.message")}
								</div>
							)}
						</div>
						<div className={style.stepField}>
							<Controller
								control={control}
								name="step"
								render={({ field: { onChange, value } }) => (
									<>
										<h4>Step in the process</h4>
										<Select
											value={value}
											options={kanbanSteps}
											placeholder="Select step"
											onChange={onChange}
											clearable={false}
											searchable={true}
											style={{ width: "100%", marginTop: "unset" }}
											disabled={stepsLoading}
											isLoading={stepsLoading}
										/>
										{_get(errors, "step.message") && (
											<div className={style.errors}>
												{_get(errors, "step.message")}
											</div>
										)}
									</>
								)}
							/>
						</div>
					</form>
				</div>
				<div
					className={cx(
						{ [style.footer_drawer_loading]: isLoading },
						{ [style.footer_drawer]: !isLoading }
					)}
				>
					<button className={style.buttonCancel} onClick={closeDrawer}>
						Cancel
					</button>{" "}
					<button
						className={style.buttonSave}
						onClick={handleSubmit(onSubmit)}
						disabled={isLoading}
					>
						Add to vacancy
					</button>
				</div>
				{isLoading && (
					<div className={style.loader_container}>
						<Loader check={false} />
					</div>
				)}
			</Drawer>
			{shortListDone && (
				<ShortListingDoneModal
					setShortListDone={setShortListDone}
					setAddEmailModal={setAddEmailModal}
					notify={notify}
					isLoadingListCandidate={isLoadingListCandidate}
					countShortList={IdsShortList.length}
					VacancyId={_get(searchQueries, "vacancy-id")}
					jobId={_get(allField, "select_vacancy.value")}
					reset={reset}
				/>
			)}
			{addEmailModal && (
				<AddEmailModal
					setShortListDone={setShortListDone}
					setAddEmailModal={setAddEmailModal}
					ListCandidate={ListCandidate}
					jobId={allField.select_vacancy?.value}
					activeCompany={activeCompany}
					notify={notify}
					isLoadingListCandidate={isLoadingListCandidate}
					VacancyId={_get(searchQueries, "vacancy-id")}
				/>
			)}
		</>
	);
};

export default ShortListDrawer;
