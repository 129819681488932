import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field, formValueSelector, initialize } from "redux-form";
import PropTypes from "prop-types";
import _get from "lodash/get";
import omit from "lodash/omit";
import {
	DATE_FORMAT,
	CURRENCY_SUFFIX,
	MAX_CURRENCY_VALUE,
	CLIENT,
	DECLINED,
	TEXTAREA_MAX_LENGTH_500
} from "config";
import CurrencyField from "common/NewCurrencyField";
import DatePickerField from "common/DatePickerField";
import FormField from "common/FormField";
import Modal from "common/modal/Modal";
import { getHourlyCost } from "common/Functions";
import { utcTimeStamp } from "config/helpers";
import { onlineUserSelector } from "modules/user/selectors/selectors.js";
import FilesRequired from "./FilesRequired";

class ProposeContractModal extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			hasEmptyFiles: false,
			isRequired: false,
			files: []
		};
		this.initializeReduxForm = this.initializeReduxForm.bind(this);
		this.hourlyRateChanged = this.hourlyRateChanged.bind(this);
		this.formSubmitted = this.formSubmitted.bind(this);
		this.checkFiles = this.checkFiles.bind(this);
	}

	componentDidMount() {
		const {
			contract: { new_proposition },
			proposed_amount,
			userType,
			reportingSetting,
			vmsSetting
		} = this.props;
		const isClient = userType === CLIENT;
		const start_date = _get(new_proposition, "start_date");
		const end_date = _get(new_proposition, "end_date");
		const hourly_rate = _get(new_proposition, "rate", proposed_amount);

		this.initializeReduxForm({
			hourly_rate: hourly_rate,
			hourly_cost: getHourlyCost(hourly_rate, vmsSetting, reportingSetting),
			start_date: start_date && window.moment.unix(start_date),
			end_date: end_date && window.moment.unix(end_date),
			description: isClient ? _get(new_proposition, "description") : ""
		});
	}

	initializeReduxForm(data) {
		this.props.initializeReduxForm({
			...this.props.formFields,
			...data
		});
	}

	hourlyRateChanged(e, value) {
		const { vmsSetting, reportingSetting } = this.props;
		this.initializeReduxForm({
			hourly_cost: getHourlyCost(value, vmsSetting, reportingSetting)
		});
	}

	checkFiles(files, docs) {
		this.setState({
			hasEmptyFiles: files,
			files: docs
		});
	}

	formSubmitted(formProps) {
		if (this.state.hasEmptyFiles) {
			this.setState({ isRequired: true });
		} else {
			const { bid_id, ProposeContract, userType, contract } = this.props;
			const isClient = userType === CLIENT;
			const otherTerms = !isClient || contract.status === DECLINED;

			const start_date = utcTimeStamp({ date: formProps.start_date });
			const end_date = utcTimeStamp({
				date: formProps.end_date,
				isStart: false
			});

			this.setState({
				loading: true
			});

			const payload = {
				price: formProps.hourly_rate,
				[otherTerms ? "new_mission_start_date" : "start_date"]: start_date,
				[otherTerms ? "new_mission_end_date" : "end_date"]: end_date,
				description: formProps.description,
				[otherTerms ? "id" : "bid_id"]: otherTerms ? contract._id : bid_id,
				bid_id,
				files: this.state.files
					.map(f => omit(f, "account_types", "is_required", "created_at"))
					.filter(f => f.path)
			};

			ProposeContract(payload, otherTerms).catch(() =>
				this.setState({
					loading: false
				})
			);
		}
	}

	closeModal = () => {
		this.props.toggleContractModal(false);
	};

	startDateChanged = value => {
		const {
			formFields: { end_date }
		} = this.props;
		const moment = window.moment;
		const startDate = moment(value, DATE_FORMAT);
		const endDate =
			typeof end_date === "object"
				? end_date
				: moment.unix(end_date, DATE_FORMAT);

		if (endDate && moment(startDate).isAfter(endDate, "day")) {
			this.initializeReduxForm({ end_date: "" });
		}
	};

	render() {
		const {
			handleSubmit,
			formFields,
			userType,
			files,
			reportingSetting
		} = this.props;

		const isClient = userType === CLIENT;
		const minStartDate = window.moment().toDate();
		const minEndDate = formFields.start_date
			? window
					.moment(formFields.start_date, DATE_FORMAT)
					.add(1, "day")
					.toDate()
			: minStartDate;
		return (
			<Modal
				loading={this.state.loading}
				title={isClient ? "Contract Proposition" : "Propose other terms"}
				onClose={this.closeModal}
				className="contract-modal"
				isProposeClassName={"props-close-btn"}
				fixed={true}
				intro={"Please confirm the details of your proposition."}
				confirmMessage={`Once confirmed, the ${
					isClient ? "applicant" : "client"
				} will receive your proposition and be able to give a feedback.`}
				firstButton={{
					label: "cancel",
					action: this.closeModal,
					type: "outlined"
				}}
				secondButton={{
					label: "confirm",
					action: handleSubmit(this.formSubmitted),
					type: "primary",
					style: { width: "200px" }
				}}
			>
				<div className="content">
					<div className="row">
						<div className="col-md-5">
							<Field
								name="hourly_rate"
								label={`${_get(reportingSetting, "rate_type")} rate`}
								suffix={
									_get(reportingSetting, "currency.symbol") +
									CURRENCY_SUFFIX[_get(reportingSetting, "rate_type")]
								}
								component={CurrencyField}
								onChange={this.hourlyRateChanged}
							/>
						</div>

						{isClient && (
							<div className="col-md-5">
								<Field
									name="hourly_cost"
									label={`${_get(reportingSetting, "rate_type")} cost`}
									suffix={
										_get(reportingSetting, "currency.symbol") +
										CURRENCY_SUFFIX[_get(reportingSetting, "rate_type")]
									}
									component={CurrencyField}
									disabled={true}
								/>
							</div>
						)}
					</div>

					<div className="row mt-15">
						<div className="col-sm-5">
							<Field
								tabIndex={0}
								classes="inner-icon left-icon"
								icon="fa fa-calendar"
								name="start_date"
								label="Mission Start Date"
								minDate={minStartDate}
								component={DatePickerField}
								onChange={this.startDateChanged}
							/>
						</div>
						<div className="col-sm-5">
							<Field
								tabIndex={0}
								classes="inner-icon left-icon"
								icon="fa fa-calendar"
								name="end_date"
								minDate={minEndDate}
								label="Mission End date"
								component={DatePickerField}
							/>
						</div>
					</div>

					<div className="row mt-15">
						<div className="col-md-11">
							<Field
								name="description"
								label="ADDITIONAL COMMENTS"
								type="textarea"
								className="col-md-11"
								component={FormField}
								maxLength={TEXTAREA_MAX_LENGTH_500}
								placeholder={`Feel free to add any information that you’d like to share with the ${
									isClient ? "applicant" : "client"
								}.`}
							/>
						</div>
					</div>
					{!isClient && files.length > 0 ? (
						<FilesRequired
							files={files}
							checkFiles={this.checkFiles}
							hasRequired={this.state.isRequired}
						/>
					) : (
						<></>
					)}
				</div>
			</Modal>
		);
	}
}

const validate = formProps => {
	const errors = {};

	if (!formProps.hourly_rate)
		errors.hourly_rate = "Please define an hourly rate.";

	if (
		formProps.hourly_rate &&
		(formProps.hourly_rate <= 0 || formProps.hourly_rate > MAX_CURRENCY_VALUE)
	) {
		errors.hourly_rate = "The hourly rate should be between 1€ and 1000€";
	}

	if (!formProps.start_date)
		errors.start_date = "Please specify a mission start date.";

	if (!formProps.end_date)
		errors.end_date = "Please specify a mission end date.";

	if (
		formProps.description &&
		formProps.description.length > TEXTAREA_MAX_LENGTH_500
	) {
		errors.description = `This field should not exceed ${TEXTAREA_MAX_LENGTH_500} characters`;
	}

	return errors;
};

const selector = formValueSelector("propose-contract");

const mapStateToProps = state => {
	return {
		user: onlineUserSelector(state),
		formFields: selector(
			state,
			"hourly_rate",
			"hourly_cost",
			"start_date",
			"end_date",
			"description"
		)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initializeReduxForm: data => dispatch(initialize("propose-contract", data))
	};
};

ProposeContractModal.propTypes = {
	proposed_amount: PropTypes.number.isRequired,
	bid_id: PropTypes.string.isRequired,
	toggleContractModal: PropTypes.func.isRequired
};

ProposeContractModal.defaultProps = {
	userType: CLIENT,
	contract: {}
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "propose-contract",
		validate,
		touchOnBlur: false
	})
)(ProposeContractModal);
