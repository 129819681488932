import create from "zustand";

export const profileViewStore = create(set => ({
	isOpen: false,
	isFromCardActions: false,
	isFromProcesses: false,
	applicationId: "",
	refetch: () => {},
	setApplicationId: value => set({ applicationId: value }),
	setIsOpen: value => set({ isOpen: value }),
	setIsFromProcesses: value => set({ isFromProcesses: value }),
	setIsFromCardActions: value => set({ isFromCardActions: value }),
	setRefetch: value => set({ refetch: value })
}));

const useProfileView = () => {
	const {
		isOpen,
		setIsOpen,
		applicationId,
		setApplicationId,
		isFromProcesses,
		setIsFromProcesses,
		setIsFromCardActions,
		isFromCardActions,
		refetch,
		setRefetch
	} = profileViewStore(state => ({ ...state }));
	return {
		isOpen,
		isFromCardActions,
		setIsOpen,
		applicationId,
		setApplicationId,
		setIsFromProcesses,
		isFromProcesses,
		setIsFromCardActions,
		refetch,
		setRefetch
	};
};

export default useProfileView;
