import { OPERATORS } from "common/FilterDrawer/utils";
import { CustomInput } from "common/CustomInput";
import { Picker } from "common/Picker";
import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { ContractsBooleanSelect } from "../ContractsBooleanSelect/ContractsBooleanSelect";
import { ContractsCurrencyInput } from "../ContractsCurrencyInput/ContractsCurrencyInput";
import { PickerWithOneValue } from "../PickerWithOneValue/PickerWithOneValue";

export const CONTRACTS_MAX_SELECT = 24;

export const CONTRACTS_PAGE_SIZES = [50, 24, 12];

export const CONTRACTS_FILTER_ID = "CONTRACTS_FILTER_ID";

export const CONTRACTS_MODULE_ID = "CONTRACTS_MODULE_ID";

export const DISPLAY_TYPE = {
	table: "table",
	card: "card"
};

export const API_CONTRACT_DATA_TYPE = "search_contract_temporary_table";

export const CONTRACTS_PAGE_SIZE = 24;

export const TYPES = {
	text: "text",
	number: "number",
	array: "array",
	date: "date",
	customMultiSelect: "customMultiSelect"
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.number]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.customMultiSelect]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.boolean]: [OPERATORS.is, OPERATORS.isNot],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.number]: {
		default: ContractsCurrencyInput,
		[OPERATORS.isEmpty.value]: ContractsBooleanSelect
	},
	[TYPES.array]: {
		default: Picker
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	},
	[TYPES.customMultiSelect]: {
		default: Picker,
		[OPERATORS.is.value]: PickerWithOneValue
	}
};

export const columnSortMap = {
	_id: "_id",
	vendor: "vendor",
	made_by: "made_by",
	profile_type: "profile_type",
	phone_number: "phone_number",
	email: "email",
	request_title: "request_title",
	proposal_date: "proposal_date",
	rate: "rate",
	cost: "cost",
	rate_type: "rate_type",
	availability: "availability",
	start_date: "start_date",
	end_date: "end_date",
	contract_status: "contract_status"
};

export const columnFiltersMap = {
	_id: "_id",
	vendor: "vendor",
	made_by: "made_by",
	profile_type: "profile_type",
	phone_number: "phone_number",
	email: "email",
	request_title: "request_title",
	proposal_date: "proposal_date",
	rate: "rate",
	cost: "cost",
	rate_type: "rate_type",
	availability: "availability",
	start_date: "start_date",
	end_date: "end_date",
	contract_status: "contract_status"
};

export const CHECK_FILTER_COLUMNS = [
	"vendor",
	"request_title",
	"phone_number",
	"email"
];
