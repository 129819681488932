import React from "react";
import CustomDropDown from "common/CustomDropDown/CustomDropDown";
import DropDownInput from "common/DropDownInput/DropDownInput";
import { useFilters } from "../hooks/useFilter";
import { getComponentByOperator, findFilterPayload } from "../utils";

const ValueField = ({ filter }) => {
	const { value, type, operator, name: id } = filter;
	const { setFilterValue, getState, currentFilterId } = useFilters();
	const { componentsByOperator, columns } = getState(currentFilterId);

	function onChange(e, eventType) {
		const value = e && e.target ? e.target.value : e;

		setFilterValue(filter.id, value, e?.type || eventType);
	}

	const handleReset = () => {
		setFilterValue(filter.id, null, "reset");
	};

	const renderComponent = () => {
		let componentToRender;
		const componentId = `${id}-${operator}`;

		const Component = getComponentByOperator(
			type,
			operator,
			componentsByOperator
		);

		const name = Component.displayName || Component?.type?.displayName;

		if (name === "Picker") {
			const {
				primaryHeaderContent,
				secondaryHeaderContent,
				displayRating,
				isMultipleSelect,
				queryFn,
				useServerFilter
			} = findFilterPayload(id, columns);

			componentToRender = (
				<Component
					key={componentId}
					PrimaryHeaderContent={primaryHeaderContent}
					SecondaryHeaderContent={secondaryHeaderContent}
					displayRating={displayRating}
					isMultipleSelect={isMultipleSelect}
					onConfirm={onChange}
					onSkillDeleteFromInput={onChange}
					value={value}
					queryFn={queryFn}
					queryId={id}
					useServerFilter={useServerFilter}
				/>
			);
		} else if (name === "CustomDropDown") {
			const { options } = findFilterPayload(id, columns);

			componentToRender = (
				<CustomDropDown
					key={componentId}
					options={options}
					value={value}
					onChange={onChange}
				/>
			);
		} else if (name === "DatePicker" || name === "RangeDatePicker") {
			componentToRender = (
				<Component
					key={componentId}
					value={value}
					onReset={handleReset}
					onChange={onChange}
				/>
			);
		} else if (name === "CustomMap") {
			const payload = findFilterPayload(id, columns);

			componentToRender = (
				<Component
					key={componentId}
					value={value}
					onConfirm={onChange}
					displayRadius={payload?.displayRadius}
				/>
			);
		} else {
			componentToRender = (
				<Component key={componentId} value={value} onChange={onChange} />
			);
		}

		return componentToRender;
	};

	if (!operator) return <DropDownInput key={id} isDisabled />;
	return renderComponent();
};

export default ValueField;
