import { useQuery } from "react-query";
import { GET_LAST_JOB } from "config/api-endpoints";
import { client } from "lib/api-client";

const getRequest = () => {
	return client(GET_LAST_JOB, {
		body: {
			job_type: "request"
		}
	});
};

export const GET_LAST_CREATED_REQUEST_QUERY_KEY =
	"GET_LAST_CREATED_REQUEST_QUERY_KEY";

export const useGetLastCreatedRequest = options => {
	const res = useQuery(GET_LAST_CREATED_REQUEST_QUERY_KEY, getRequest, {
		refetchOnWindowFocus: false,
		...options
	});

	if (options.enabled) {
		return res;
	} else {
		return {};
	}
};
