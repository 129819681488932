import React, { useEffect, useState } from "react";
import styles from "./bid-details.module.scss";
import cx from "classnames";
import _get from "lodash/get";
import Drawer from "rc-drawer";
import loadable from "loadable-components";
import { queryCache } from "react-query";
import { browserHistory } from "react-router";
import { NOTES_TAB_ID, RESUME_TAB_ID, TABS } from "./utils/constant";
import { BidDetailsHeader } from "./components/BidDetailsHeader";
import { addHashToUrl, getUrlHash, removeURLParameter } from "config/helpers";
import { BidDetailsResumeTab } from "./components/BidDetailsResumeTab";
import useGetBidDetails from "./hooks/useGetBidDetails";
import { BidDetailsNotesTab } from "./components/BidDetailsNotesTab";
import { BidDetailsProcessesTab } from "./components/BidDetailsProcessesTab";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import useGetTempProfile from "modules/ConsultantFreelancerView/hooks/useGetTempProfile";
import { BidDetailsPagination } from "./components/BidDetailsPagination";
import useViewContactInfo from "./hooks/useViewContactInfo";
import useOpenBidDetailsDrawer from "./hooks/useOpenBidDetailsDrawer";
import { ProposeInterviewToFreelancerDrawer } from "../ProposeInterviewToFreelancerDrawer";
import { BIDS_QUERY_KEY } from "modules/Requests/RequestsCandidatesList/hooks/useGetTempList";
import { KEY_FETCH_TEMP_INTERVIEWS } from "modules/Requests/RequestsInterviewsList/hooks/useFilterApplications";
import { GET_CONTRACTS_QUERY_KEY } from "modules/Requests/contracts/hooks/useGetContractsList";

const AcceptContractModal = loadable(() =>
	import("../drawers/components/AcceptContractModal")
);
const AcceptInterviewModal = loadable(() =>
	import("../drawers/components/AcceptInterviewModal")
);
const SignContractModal = loadable(() =>
	import("../drawers/components/SignContractModal")
);
import { REQUEST_VIEW_KANBAN_DATA_QUERY } from "../kanban/hooks/useTempPipelineDataApi";
import useSelectedKanbanBid from "modules/RequestView/hooks/useSelectedKanbanBid";
import BidDetailsUnlockProfile from "./components/BidDetailsUnlockProfile";

const goToFreelancerProfile = id => {
	return `/user/profile/${id}?source=list&list=bid&from=${window.location.pathname}`;
};

export default function BidDetailsDrawer() {
	const [displayDrawer, setDisplayDrawer] = useState(true);
	const { bidId, setOpen } = useOpenBidDetailsDrawer();
	const [
		showProposeTempInterviewDrawer,
		setShowProposeTempInterviewDrawer
	] = useState(false);
	const [showAcceptInterviewModal, setShowAcceptInterviewModal] = useState(
		false
	);
	const [showAcceptContractModal, setShowAcceptContractModal] = useState(false);
	const [showSignContractModal, setShowSignContractModal] = useState(false);
	const { data, isLoading, isFetching, refetch } = useGetBidDetails(bidId);
	const { data: profileData } = useGetTempProfile(bidId, true);
	const { setBid: setSelectedBid } = useSelectedKanbanBid();

	const [activeTab, setActiveTab] = useState(TABS[0].id);

	const [unlockProfileMutate] = useViewContactInfo({
		onSuccess: () => {
			refetch();
			queryCache.invalidateQueries([REQUEST_VIEW_KANBAN_DATA_QUERY]);
			queryCache.invalidateQueries([BIDS_QUERY_KEY]);
			queryCache.invalidateQueries([KEY_FETCH_TEMP_INTERVIEWS]);
			queryCache.invalidateQueries([GET_CONTRACTS_QUERY_KEY]);
		}
	});

	const [unlockProfileMutateAndGoToProfile] = useViewContactInfo({
		onSuccess: () => {
			browserHistory.push(goToFreelancerProfile(bidId));
		}
	});

	const firstName = _get(data, "profile_detail.first_name");
	const lastName = _get(data, "profile_detail.last_name");
	const candidate_name = firstName + " " + lastName;
	useEffect(() => {
		if (data) {
			setSelectedBid({
				...data,
				bid_id: data._id,
				candidate_name
			});
		}
	}, [data]);

	useEffect(() => {
		const id = getUrlHash();
		if (id) {
			setActiveTab(id);
		}
	}, []);

	const onTabClick = id => {
		setActiveTab(id);
		addHashToUrl(id);
	};

	const handleUnlockProfile = () => {
		unlockProfileMutate({
			id: bidId,
			type: "contact",
			activity_type: "bid"
		});
	};

	const unlockedAndGoToProfile = () => {
		unlockProfileMutateAndGoToProfile({
			id: bidId,
			type: "contact",
			activity_type: "bid"
		});
	};

	const onClose = () => {
		setDisplayDrawer(false);
		browserHistory.push(removeURLParameter(window.location.href, ["bid_id"]));
	};

	const afterVisibleChange = visibility => {
		if (!visibility) setOpen(false);
	};

	return (
		<Drawer
			open={displayDrawer}
			width="700"
			height="100%"
			placement="right"
			style={{
				zIndex: 100
			}}
			level={"root"}
			maskClosable={true}
			onClose={onClose}
			afterVisibleChange={afterVisibleChange}
		>
			<div className={styles.drawerContent}>
				<div className={styles.header}>
					<div className={styles.headerInline}>
						<BidDetailsPagination
							idPrevBid={_get(data, "previous")}
							idNextBid={_get(data, "next")}
						/>
						<button onClick={onClose} className={styles.closeBtn}>
							<CloseIcon />
						</button>
					</div>
					<div className={styles.divider}></div>
				</div>
				<div className={styles.headerContainer}>
					<BidDetailsHeader
						data={data}
						isLoading={isLoading}
						profileData={profileData}
						unlockedAndGoToProfile={unlockedAndGoToProfile}
						onProposeInterviewDrawer={() =>
							setShowProposeTempInterviewDrawer(true)
						}
						onAcceptInterviewModal={() => setShowAcceptInterviewModal(true)}
						onAcceptContractModal={() => setShowAcceptContractModal(true)}
						onSignContractAction={() => setShowSignContractModal(true)}
					/>
					{data && !_get(data, "show_profile") && (
						<BidDetailsUnlockProfile
							handleUnlockProfile={handleUnlockProfile}
						/>
					)}
				</div>
				<div className={styles.tabPanel}>
					<nav className={styles.tabs}>
						{TABS.map(({ id, label }) => (
							<button
								key={id}
								onClick={() => onTabClick(id)}
								className={cx(styles.tab, {
									[styles.active]: id === activeTab
								})}
							>
								{label}
							</button>
						))}
					</nav>
					<div className={styles.presentationSlider}></div>
				</div>
				<div className={styles.panel}>
					{activeTab === RESUME_TAB_ID ? (
						<BidDetailsResumeTab path={_get(profileData, "cv_docs.cv_doc")} />
					) : activeTab === NOTES_TAB_ID ? (
						<BidDetailsNotesTab id={_get(data, "profile_detail._id")} />
					) : (
						<BidDetailsProcessesTab
							data={data}
							isLoading={isLoading}
							isFetching={isFetching}
						/>
					)}
				</div>
			</div>
			{showProposeTempInterviewDrawer && (
				<ProposeInterviewToFreelancerDrawer
					onClose={() => setShowProposeTempInterviewDrawer(false)}
					onSuccess={() => {
						refetch();
						setShowProposeTempInterviewDrawer(false);
					}}
				/>
			)}
			{showAcceptInterviewModal && (
				<AcceptInterviewModal
					onClose={() => setShowAcceptInterviewModal(false)}
				/>
			)}
			{showAcceptContractModal && (
				<AcceptContractModal
					onClose={() => setShowAcceptContractModal(false)}
				/>
			)}
			{showSignContractModal && (
				<SignContractModal onClose={() => setShowSignContractModal(false)} />
			)}
		</Drawer>
	);
}
