import React from "react";
import styled from "styled-components";
import { browserHistory } from "react-router";

import { colors } from "config/styles";
import Modal from "common/modal";

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 16px;
	.title {
		color: #242f43;
		font-weight: bold;
		margin-bottom: 8px;
		font-size: 16px;
		&.primary {
			color: ${colors.primaryColor};
			font-size: 24px;
			margin-bottom: 20px;
		}
	}
	.content {
		color: #242f43;
		font-weight: normal;
		font-size: 13px;
		line-height: 1.74;
		text-align: center;
		margin-bottom: 20px;
		width: 100%;
		h3 {
			font-size: 18px;
			font-weight: bold;
			color: rgb(36, 47, 67);
		}
		.icon {
			font-size: 60px;
			margin-bottom: 26px;
			display: inline-block;
		}
	}
	.cta {
		color: #242f43;
		font-weight: bold;
		font-size: 13px;
		margin-bottom: 14px;
	}
	.actions {
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		button {
			margin-right: 24px;
			height: 51px;
			padding: 14px 20px !important;
			font-size: 16px !important;
			text-transform: unset !important;
			margin-right: 20px;
			&.btn.btn-primary-outline {
				margin-right: 0;
			}
		}
	}
`;

const PublishRequestErrorModal = ({
	onClose,
	jobId,
	hasIndustry,
	hasLogo,
	siteId,
	isDefault
}) => {
	const redirectToEditProfile = () =>
		browserHistory.push(
			`/settings/my-company?source=${jobId}&siteId=${siteId}&isDefault=${isDefault}`
		);

	const displayContent = () => {
		if (!hasIndustry && !hasLogo) {
			return (
				<>
					<h3>Industry and company logo needed</h3>
					You need to define your industry in your profile page, and your
					company logo, to publish this vacancy on external channels.
				</>
			);
		}

		if (hasIndustry && !hasLogo) {
			return (
				<>
					<h3>Company logo needed</h3>
					You need to upload a company logo to your profile page to publish this
					vacancy on external channels.
				</>
			);
		}

		if (!hasIndustry && hasLogo) {
			return (
				<>
					<h3>Industry needed</h3>
					You need to define your industry in your profile page to publish this
					vacancy on external channels.{" "}
				</>
			);
		}
	};

	return (
		<Modal fixed onClose={onClose} modalName={"publish-error"}>
			<ModalContent>
				<div className="content">
					<span className="icon icon-needed-element">
						<span className="path1"></span>
						<span className="path2"></span>
						<span className="path3"></span>
						<span className="path4"></span>
						<span className="path5"></span>
						<span className="path6"></span>
						<span className="path7"></span>
						<span className="path8"></span>
						<span className="path9"></span>
						<span className="path10"></span>
						<span className="path11"></span>
						<span className="path12"></span>
						<span className="path13"></span>
						<span className="path14"></span>
						<span className="path15"></span>
						<span className="path16"></span>
						<span className="path17"></span>
						<span className="path18"></span>
						<span className="path19"></span>
						<span className="path20"></span>
						<span className="path21"></span>
						<span className="path22"></span>
						<span className="path23"></span>
						<span className="path24"></span>
					</span>
					{displayContent()}
				</div>
				<div className="actions">
					{(!hasIndustry || !hasLogo) && (
						<button
							className="btn btn-primary"
							onClick={() => redirectToEditProfile()}
						>
							Edit your profile
						</button>
					)}
				</div>
			</ModalContent>
		</Modal>
	);
};

export default PublishRequestErrorModal;
