import React, { Component } from "react";
import { connect } from "react-redux";
import {
	formValueSelector,
	change,
	initialize,
	clearFields,
	getFormSubmitErrors
} from "redux-form";
import { browserHistory } from "react-router";
import _get from "lodash/get";
//components
import {
	getLastJob,
	createJobStep2,
	resetJobData,
	getListCurrencies
} from "../../actions";
import JobRequest from "./JobRequest";
import {
	selectJobInformation,
	selectAuthenticatedUser,
	currenciesListSelector
} from "../../selectors";
import { getJobProfileRoute, getVacancyProfileRoute } from "..";
import {
	COMPANY_ADDRESS,
	PART_TIME,
	FULL_TIME,
	DATE_FORMAT,
	VACANCY,
	REQUEST,
	JOBS
} from "config";
export const getJobRequestRoute = id => {
	return `/jobs/post/request${id !== undefined ? `/${id}` : `(/:id)`}`;
};
export const getVacancyRequestRoute = id => {
	return `/vacancy/post/request${id !== undefined ? `/${id}` : `(/:id)`}`;
};

class JobRequestContainer extends Component {
	componentDidMount() {
		const {
			params: { id },
			getLastJob,
			jobInfo
		} = this.props;
		const pathname = _get(window, "location.pathname");
		let source;
		if (pathname) {
			source = _get(pathname.split("/"), "1");
		}
		const job_type = source === JOBS ? REQUEST : VACANCY;
		const isRequest = job_type === REQUEST;
		const requiredField = isRequest
			? jobInfo.category
			: _get(jobInfo, "functions.length", 0) > 0;
		if (!id && !requiredField) {
			const profileRoute = isRequest
				? getJobProfileRoute
				: getVacancyProfileRoute;
			return browserHistory.push(profileRoute(""));
		}
		if (
			!(jobInfo && jobInfo.id) ||
			(jobInfo && jobInfo.id && id !== jobInfo.id)
		) {
			getLastJob({ id, job_type }).then(this.initializeForm);
		} else {
			this.initializeForm(jobInfo);
		}
		this.props.getListCurrencies();
	}

	initializeForm = ({
		title,
		description,
		start_date,
		end_date,
		hourly_budget,
		street,
		number,
		box,
		city,
		zip,
		country,
		iso_country,
		full_time = FULL_TIME,
		longitude,
		latitude,
		location_type = COMPANY_ADDRESS,
		payroll_country,
		other_benefits,
		salary_range_start,
		salary_range_end,
		currency,
		payment_time,
		payment_type,
		education_level,
		contract_type,
		employment_type,
		expected_duration
	}) => {
		const payrollCountry = payroll_country
			? { label: payroll_country, value: payroll_country }
			: "";
		return this.props.initializeReduxForm({
			title: title,
			description: description,
			start_date: this.formatDate(start_date),
			end_date: this.formatDate(end_date),
			hourly_budget: hourly_budget,
			street: street,
			number: number,
			box: box,
			city: city,
			zip: zip,
			country: country,
			iso_country,
			time_type: full_time ? FULL_TIME : PART_TIME,
			longitude: longitude,
			latitude: latitude,
			location_type: location_type,
			payroll_country: payrollCountry,
			other_benefits,
			salary_range_start,
			salary_range_end,
			currency,
			payment_time,
			payment_type,
			education_level,
			contract_type,
			employment_type,
			expected_duration: _get(expected_duration, "duration", ""),
			expected_duration_period: _get(expected_duration, "period", "")
		});
	};

	formatDate = date => {
		return date ? window.moment.unix(date).format(DATE_FORMAT) : undefined;
	};

	render() {
		const {
			params,
			jobInfo,
			formFields,
			authenticatedUser,
			changeReduxForm,
			initializeReduxForm,
			clearReduxFormFields,
			createJobStep2,
			submitErrors,
			currencies
		} = this.props;
		const pathname = _get(window, "location.pathname");
		let source;
		if (pathname) {
			source = _get(pathname.split("/"), "1");
		}
		const jobType = source === JOBS ? REQUEST : VACANCY;

		return (
			<JobRequest
				params={params}
				jobInfo={jobInfo}
				formFields={formFields}
				changeReduxForm={changeReduxForm}
				initializeReduxForm={initializeReduxForm}
				clearReduxFormFields={clearReduxFormFields}
				authenticatedUser={authenticatedUser}
				createJobStep2={createJobStep2}
				job_type={jobType}
				submitErrors={submitErrors}
				currencies={currencies}
				query={_get(this.props, "location.query")}
			/>
		);
	}
}
const selector = formValueSelector("JobFormSecondPart");

const mapStateToProps = state => {
	return {
		formFields: selector(
			state,
			"title",
			"description",
			"time_type",
			"estimation",
			"estimation_type",
			"hours_per_day",
			"days_per_month",
			"hourly_budget",
			"payment_term",
			"start_date",
			"end_date",
			"location_type",
			"street",
			"number",
			"city",
			"country",
			"zip",
			"currency",
			"payment_time",
			"payment_type",
			"contract_type",
			"employment_type",
			"expected_duration",
			"expected_duration_period"
		),
		jobInfo: selectJobInformation(state),
		authenticatedUser: selectAuthenticatedUser(state),
		submitErrors: getFormSubmitErrors("JobFormSecondPart")(state),
		currencies: currenciesListSelector(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLastJob: payload => dispatch(getLastJob(payload)),
		initializeReduxForm: data =>
			dispatch(initialize("JobFormSecondPart", data)),
		changeReduxForm: (field, value) =>
			dispatch(change("JobFormSecondPart", field, value)),
		clearReduxFormFields: field =>
			dispatch(clearFields("JobFormSecondPart", false, true, field)),
		createJobStep2: (payload, edit = false, reduxFormName) =>
			dispatch(createJobStep2(payload, edit, reduxFormName)),
		resetJobData: () => dispatch(resetJobData()),
		getListCurrencies: () => dispatch(getListCurrencies())
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(JobRequestContainer);
