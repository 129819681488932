import React, { useEffect, useState } from "react";
import { ButtonCSV, DownloadFile } from "./Styled";
import { ReactComponent as SoftWareDownload } from "static/icons/generate-file.svg";
import { ReactComponent as DownloadXls } from "static/icons/download-xls.svg";
import { ReactComponent as NoFileToDownload } from "static/icons/no-file-to-download.svg";
import Loader from "common/Loader";
import { Box, Button, Flex, Text } from "rebass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import get from "lodash/get";
import { formatDate } from "common/Functions";
import { TIME_FORMAT_24 } from "config";
import GlobalTooltip from "common/GlobalTooltip";
import { ReactComponent as RegenerateXSLFile } from "static/icons/regenerate-xsl-file.svg";

const CsvButton = ({
	onGenerate,
	isGenerating,
	isLoadingFile,
	excelLink,
	isFetching
}) => {
	const [loadingDate, setLoadingDate] = useState(false);
	const [generateDate, setGenerateDate] = useState("");

	useEffect(() => {
		if (excelLink) {
			setGenerateDate(get(excelLink.split("_"), "1", "").replace(".xlsx", ""));
			setLoadingDate(false);
		}
	}, [excelLink]);
	const CSVOverlay = (
		<Text
			sx={{
				fontFamily: "BasierCircle",
				fontSize: "13px",
				fontStyle: "normal",
				fontWeight: 600,
				lineHeight: "24px",
				letterSpacing: "0px",
				color: "#A0A3BD"
			}}
		>
			No file exported click to export your file
		</Text>
	);

	const XLSOverlay = (
		<React.Fragment>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontSize: "12px",
					fontStyle: "normal",
					fontWeight: 600,
					lineHeight: "22px",
					letterSpacing: "0px",
					color: "#A0A3BD"
				}}
			>
				Last data exported :
			</Text>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontSize: "13px",
					fontStyle: "normal",
					fontWeight: 600,
					lineHeight: "24px",
					letterSpacing: "0px"
				}}
			>
				{formatDate(generateDate)}{" "}
				<Text
					sx={{
						fontFamily: "BasierCircle",
						display: "inline-block",
						fontSize: "13px",
						fontStyle: "italic",
						fontWeight: 600,
						lineHeight: "24px",
						letterSpacing: "0px",
						marginLeft: "3px",

						color: "#ff6849"
					}}
				>
					@ {formatDate(generateDate, true, TIME_FORMAT_24)}
				</Text>
			</Text>
		</React.Fragment>
	);

	const regenerateOverlay = (
		<Text
			sx={{
				fontFamily: "BasierCircle",
				fontSize: "13px",
				fontStyle: "normal",
				fontWeight: 600,
				lineHeight: "24px",
				letterSpacing: "0px",
				color: "#A0A3BD"
			}}
		>
			Generate your data to have a new export
		</Text>
	);

	return (
		<Flex alignItems="center">
			<Box mr={16}>
				{isFetching ||
					(loadingDate && !excelLink && (
						<SkeletonTheme style={{ borderRadius: 10 }} color="#DFE3E8">
							<Skeleton width={130} height={10} />
						</SkeletonTheme>
					))}
			</Box>

			<DownloadFile excelLink={excelLink}>
				{excelLink ? (
					<React.Fragment>
						{isGenerating || isLoadingFile ? (
							<div className="loading-xls">
								<Loader check={false} />
							</div>
						) : (
							<React.Fragment>
								<GlobalTooltip placement="top" overlay={regenerateOverlay}>
									<Button
										disabled={isGenerating || isLoadingFile}
										onClick={() => {
											onGenerate();
											setLoadingDate(true);
										}}
										sx={{
											display: "flex",
											background: "transparent"
										}}
									>
										<Text
											mr={10}
											sx={{
												fontFamily: "BasierCircle",
												fontStyle: "normal",
												fontWeight: 600,
												fontSize: "13px",
												lineHeight: "24px",
												color: "#A0A3BD"
											}}
										>
											Generate
										</Text>
										<RegenerateXSLFile />
									</Button>
								</GlobalTooltip>

								<GlobalTooltip placement="top" overlay={XLSOverlay}>
									<a className="download-xls" href={excelLink}>
										<div style={{ height: "22px" }}>
											{excelLink ? <DownloadXls /> : <NoFileToDownload />}
										</div>

										<span>XLS</span>
									</a>
								</GlobalTooltip>
							</React.Fragment>
						)}
					</React.Fragment>
				) : generateDate ? (
					<GlobalTooltip placement="top" overlay={CSVOverlay}>
						<ButtonCSV
							disabled={isGenerating || isLoadingFile}
							onClick={() => {
								onGenerate();
								setLoadingDate(true);
							}}
						>
							<div>
								<SoftWareDownload />
							</div>
							<span>CSV</span>
						</ButtonCSV>
					</GlobalTooltip>
				) : (
					<ButtonCSV
						disabled={isGenerating || isLoadingFile}
						onClick={() => {
							onGenerate();
							setLoadingDate(true);
						}}
					>
						<div>
							<SoftWareDownload />
						</div>
						<span>CSV</span>
					</ButtonCSV>
				)}
			</DownloadFile>
		</Flex>
	);
};

export default CsvButton;
