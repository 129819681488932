import * as types from "./vacancyFunctionsTypes";
import AxiosHelper from "config/axios-helper";
import { LIST_SECTORS } from "config/api-endpoints";

export function getSectors() {
	return dispatch =>
		dispatch(
			AxiosHelper.__post({
				url: LIST_SECTORS,
				loadingText: "Loading sectors...",
				isModal: true,
				isLogin: true,
				next: data => {
					return dispatch({ type: types.SET_SECTORS, data });
				}
			})
		);
}

export function showModalAction() {
	return {
		type: types.SHOW_MODAL
	};
}

export function closeModalAction() {
	return {
		type: types.CLOSE_MODAL
	};
}

export function setFunctions(data) {
	return {
		type: types.SET_FUNCTIONS,
		data
	};
}

export function addFunction() {
	return {
		type: types.ADD_FUNCTION
	};
}

export function removeFunction(id) {
	return {
		type: types.REMOVE_FUNCTION,
		id
	};
}

export function updateFunction(fun) {
	return {
		type: types.UPDATE_FUNCTION,
		fun
	};
}

export function updateMainFunction(id) {
	return {
		type: types.UPDATE_MAIN_FUNCTION,
		id
	};
}

export function resetFunctions() {
	return {
		type: types.RESET_FUNCTIONS
	};
}

export function initialFunctions() {
	return {
		type: types.INITIAL_STATE
	};
}
