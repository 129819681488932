import React from "react";
import cx from "classnames";
import styles from "./create-request-card.module.scss";

const CreateRequestModal = ({ className, children, id }) => (
	<div id={id} className={cx(styles.container, className)}>
		{children}
	</div>
);

export default CreateRequestModal;
