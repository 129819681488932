import React from "react";
import style from "modules/SearchCandidate/pageHeader/page-header.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/IconClose-1.svg";
import { ReactComponent as CheckIcon } from "static/icons/IconCheckFlag.svg";
import { motion } from "framer-motion";

const FeatureAlert = ({ onClose, icon, children }) => {
	const spring = {
		type: "spring",
		duration: 1
	};

	return (
		<motion.div
			transition={spring}
			exit={{ right: -408 }}
			animate={{ right: 31 }}
			initial={{ right: -408 }}
			className={style.subContainer}
		>
			<div>{icon ? icon : <CheckIcon />}</div>
			<div>{children}</div>
			<button onClick={onClose} className={style.closeButton}>
				<CloseIcon />
			</button>
		</motion.div>
	);
};

export default FeatureAlert;
