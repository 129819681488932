import React from "react";
import {
	buildStyles,
	CircularProgressbarWithChildren
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Text } from "rebass";

const CircleProgress = ({ value, textColor = "#FD6950" }) => {
	return (
		<CircularProgressbarWithChildren
			value={value}
			minValue={1}
			maxValue={100}
			styles={buildStyles({
				trailColor: "#D3D9DE",
				pathColor: textColor
			})}
		>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "20px",
					lineHeight: "38px",
					color: textColor,
					letterSpacing: "0"
				}}
			>
				{value}%
			</Text>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "10px",
					lineHeight: "12px",
					color: textColor,
					letterSpacing: "0"
				}}
			>
				Progress
			</Text>
		</CircularProgressbarWithChildren>
	);
};

export default CircleProgress;
