import { useEffect } from "react";
import useBreadCrumbsLayout from "common/Layouts/breadcrumbs.store";

export const useBreadCrumbs = item => {
	const { setItems } = useBreadCrumbsLayout(state => state);

	useEffect(() => {
		setItems(item);
	}, []);
};
