import React from "react";
import classnames from "./empty-state-link-to.module.scss";

const EmptyStateLinkTo = () => (
	<div className={classnames.container}>
		<span>No entity defined</span>
	</div>
);

export default EmptyStateLinkTo;
