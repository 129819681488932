import styled from "styled-components";
import { keyframes } from "styled-components";
import {
	color,
	space,
	textStyle,
	layout,
	position,
	border
} from "styled-system";

const pulse = keyframes`
	0% {

		box-shadow: 0 0 0 0 rgb(96, 96, 96,0.1);
	}

	70% {

		box-shadow: 0 0 0 2px rgb(96, 96, 96,0.2);
	}

	100% {

		box-shadow: 0 0 0 0 rgb(96, 96, 96,0.3);
	}
`;
export default styled("div")`
	display: inline-flex;
	&::first-letter {
		text-transform: capitalize;
	}
	justify-content: center;
	align-items: center;
	min-width: ${props => (props.isVacancies ? "125px" : "120px")};
	font-size: 12px;
	border-radius: 3px;
	height: 25px;
	text-transform: inherit;
	font-weight: 400;
	position: relative;
	border: ${props => props.filled && "2px solid #ff6849"};
	text-wrap: nowrap;
	${color};
	${space};
	${textStyle};
	${layout};
	${position};
	${border};
	.notification-list {
		position: absolute;
		right: -12px;
		top: -8px;
		.notif-bubble {
			position: static;
		}
	}
	.icon-check {
		font-size: 22px;
		width: 25px;
		position: absolute;
		left: 4px;
		top: -2px;
	}
	&.applyCancelled {
		min-width: 150px;
	}
	&.pulse {
		animation: ${pulse} 1300ms infinite;
	}
`;

export const FlexBox = styled.div`
	display: inline-flex;
	align-items: center;
	border-radius: 15px;
	padding: 2px 10px 2px 10px;
	color: ${props => (props.color ? props.color : null)};
	background: ${props =>
		props.backgroundColor ? props.backgroundColor : null};
`;
