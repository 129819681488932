import React, { useMemo } from "react";
import { get } from "lodash";
import { queryCache } from "react-query";
import _map from "lodash/map";
import { listCountKey } from "modules/home/api/useListCount.js";
import HomeTable from "modules/home/components/Temporary/table";
import useBidsList from "modules/home/api/useBidsList.js";
import { RenderDate } from "modules/home/ui";
import useTemporaryStore from "modules/home/zustand/temporary.store.js";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { CURRENCY_SUFFIX, WELCOME_PAGE } from "config";
import { historyPush } from "config/helpers";
import { RenderApplicantsName } from "modules/home/components/table/helpers.js";
import { NEW_BID } from "modules/home/config";
import { getClientBidViewRoute } from "modules/RequestView/components/BidDetailsDrawer/utils/constant";

const renderCurrency = data => {
	return (
		<CurrencyFormatterRender
			isCent
			value={get(data, "cell.value")}
			suffix={
				CURRENCY_SUFFIX[
					get(data, "cell.row.original.reporting_settings.rate_type")
				]
			}
			currency={get(data, "cell.row.original.reporting_settings.currency.code")}
		/>
	);
};

const renderApplicantsName = data => (
	<RenderApplicantsName
		name={`${get(data, "cell.row.original.user_first_name")} ${get(
			data,
			"cell.row.original.user_last_name"
		)}`}
		status={get(data, "cell.row.original.company_type")}
	/>
);

const NewBidsList = ({ handleViewAll }) => {
	const { startDate, endDate, temporaryTab, setItem } = useTemporaryStore();

	const { data, isLoading } = useBidsList(
		{
			start_date:
				startDate && startDate.isValid() ? startDate.unix() : undefined,
			end_date: endDate && endDate.isValid() ? endDate.unix() : undefined
		},
		{
			enabled: temporaryTab === NEW_BID,
			onSuccess: res => {
				queryCache.invalidateQueries(listCountKey);
				setItem(
					NEW_BID,
					_map(res || [], item => item._id)
				);
			}
		}
	);

	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: "created_at",
				Cell: props => {
					return <RenderDate date={props.cell.value} />;
				}
			},
			{
				Header: "Request title",
				accessor: "job.title"
			},
			{
				Header: "Amount",
				accessor: "proposed_amount",
				isNumber: true,
				Cell: renderCurrency
			},
			{
				Header: "Applicant's name",
				accessor: "",
				Cell: renderApplicantsName
			},
			{
				Header: "Department",
				accessor: "group.name"
			},
			{
				Header: "Available from",
				accessor: "available_date",
				Cell: props => {
					return <RenderDate date={props.cell.value} />;
				}
			}
		],
		[]
	);
	const buttonAction = () => historyPush("/jobs/list");
	const onRowClick = row =>
		historyPush(
			getClientBidViewRoute(
				row._id,
				row.job._id,
				`&source=${WELCOME_PAGE}&list=bids&listKey=${NEW_BID}`
			)
		);

	return (
		<HomeTable
			isLoading={isLoading}
			columns={columns}
			data={data}
			heading="You have no bids for this date"
			buttonLabel="Create request"
			buttonAction={buttonAction}
			onRowClick={onRowClick}
			handleViewAll={handleViewAll}
		/>
	);
};

export default NewBidsList;
