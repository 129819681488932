import React from "react";
import PropTypes from "prop-types";
import { USE_WEBCAM, USE_UPLOADER } from "config";
import {
	Wrapper,
	Divider,
	Line,
	Or,
	Container
} from "./cvchooser/CVChooserStyled";
import WebcamIcon from "static/icons/webcam.svg";
import ImageIcon from "static/icons/image.svg";
import { Button } from "common/styled/buttons";

const MethodChooser = ({ onChoose, height }) => {
	return (
		<Wrapper nomargin>
			<Container height={height}>
				<img width={70} src={WebcamIcon} alt="Webcam" />
				<Button small medium onClick={() => onChoose(USE_WEBCAM)}>
					Use Webcam
				</Button>
			</Container>
			<Divider height={height}>
				<Line />
				<Or>Or</Or>
				<Line />
			</Divider>
			<Container height={height}>
				<img width={70} src={ImageIcon} alt="Uploader" />
				<Button small medium onClick={() => onChoose(USE_UPLOADER)}>
					Upload id
				</Button>
			</Container>
		</Wrapper>
	);
};

MethodChooser.propTypes = {
	onChoose: PropTypes.func.isRequired
};

export default MethodChooser;
