import React from "react";
import get from "lodash/get";
import Pagination from "common/Pagination";
import s from "./publish-vacancy-premium-channels.module.scss";
import Checkbox from "common/Checkbox";
import CardSkeleton from "../channelCard/CardSkeleton";
import ChannelCard from "../channelCard";
import { HIREME_CHANNEL_ID } from "config";
import useFiltersStore from "../../store/useFiltersStore";
import useConfirmationStore from "modules/publishJob/store/useConfirmationStore";
import { HOW_TO_PUBLISH_VACANCY } from "config/onboarding";

const PublishVacancyPremiumChannels = ({
	isContractSelected,
	channels,
	handlePageClick,
	isLoading,
	channelSelectionBucket,
	handlePremiumChannelCheckboxChange,
	selectedChannelsCounter
}) => {
	const { setChannelsOffset } = useFiltersStore();
	const {
		addPage: addChannelsToConfirmedList,
		removePage: RemoveChannelsFromConfirmedList
	} = useConfirmationStore();
	const { selectedRows: selectedChannels } = channelSelectionBucket.state;

	const isCurrentListSelected = () => {
		const ids = channels.data.map(({ product_id }) => product_id);
		return ids.every(id => selectedChannels.includes(id));
	};

	const handleSelectAllCheckboxChange = () => {
		const channelsList = channels.data;
		const channelsIds = channelsList.map(({ product_id }) => product_id);

		if (isCurrentListSelected()) {
			if (isContractSelected) {
				RemoveChannelsFromConfirmedList(channelsList, isContractSelected);
				return channelSelectionBucket.actions.remove(
					channelsIds.filter(item => item !== HIREME_CHANNEL_ID)
				);
			} else {
				RemoveChannelsFromConfirmedList(channelsList);
				return channelSelectionBucket.actions.remove(channelsIds);
			}
		} else {
			addChannelsToConfirmedList(channelsList);
			return channelSelectionBucket.actions.all(channelsIds);
		}
	};

	return (
		<div className={s.container}>
			{!isLoading && (
				<div className={s.selectAllCheckbox}>
					<Checkbox
						id="selection"
						checked={isCurrentListSelected()}
						onChange={handleSelectAllCheckboxChange}
						label="All Channels &nbsp;"
						labelClassName={s.allChannelsLabel}
					/>
					<span>
						{selectedChannelsCounter != 0 && `(${selectedChannelsCounter})`}
					</span>
				</div>
			)}
			<div
				className={s.channelsContainer}
				data-onboarding-step={`${HOW_TO_PUBLISH_VACANCY}-5`}
			>
				{isLoading
					? Array.from(Array(8).keys()).map((_, index) => (
							<CardSkeleton key={index} />
					  ))
					: get(channels, "data", []).map(item => (
							<ChannelCard
								key={item.product_id}
								title={get(item, "title") || item.channel.name}
								description={get(item, "description")}
								src={get(item, "logo_url[0].url")}
								channel={item}
								checked={channelSelectionBucket.helpers.isChannelSelected(
									item.product_id
								)}
								handleCheck={e => handlePremiumChannelCheckboxChange(e, item)}
								isChannel
							/>
					  ))}
			</div>
			{!isLoading &&
				get(channels, "pagination.total") >
					get(channels, "pagination.limit") && (
					<Pagination
						total={get(channels, "pagination.total")}
						size={get(channels, "pagination.limit")}
						offset={get(channels, "pagination.offset")}
						handlePageClick={handlePageClick(setChannelsOffset, channels)}
					/>
				)}
		</div>
	);
};

export default PublishVacancyPremiumChannels;
