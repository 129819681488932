import { createSelector } from "reselect";
import _get from "lodash/get";
import { prepareForSelect } from "config/helpers";

const getBid = state => _get(state, "bid");
const bids = state => _get(state, "bids");
const getUser = state => _get(state, "auth.user", {});
const getShowVideoConf = state => _get(state, "bid.showVideoConf", false);
const getConsultants = state => _get(state, "bids.consultants", []);
const getBusinessUnits = state => _get(state, "job.groups.groups", []);
const getConsultantsGroups = state =>
	_get(state, "job.groups.consultants_group", []);
const cancelBid = state => {
	return {
		inAction: state.bids.inAction
	};
};

export const cancelBidSelector = createSelector(
	cancelBid,
	cancelBid => cancelBid
);
export const authenticatedUserSelector = createSelector(getUser, user => user);
export const showVideoConfSelector = createSelector(
	getShowVideoConf,
	showVideoConf => showVideoConf
);
export const bidSelector = createSelector(getBid, bid => bid);
export const bidListSelector = createSelector(bids, bids => bids);
export const businessUnitsSelector = createSelector(getBusinessUnits, list =>
	prepareForSelect(list)
);
export const consultantsGroupsSelector = createSelector(
	getConsultantsGroups,
	list => list
);
export const consultantsSelector = createSelector(getConsultants, list => list);
