import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { endpoints } from "config/axios-helper";
import toaster from "common/Toaster";

export const useUpdatePassword = onClose => {
	const [mutate, { isLoading }] = useMutation(
		body => client(endpoints.CHANGE_PASSWORD, { body }),
		{
			onSuccess: () => {
				toaster.success("Your password has successfully been updated.");
				onClose();
			},
			onError: err => {
				const errorMsg =
					err?.detail?.name || "Something went wrong. Please try again!";
				toaster.danger(errorMsg);
			}
		}
	);

	return [mutate, { isLoading }];
};
