import React from "react";
import styled, { css } from "styled-components";
import { ADDRESS_VIEW_TYPES } from "config";
import { ReactComponent as IconEditMap } from "static/icons/IconMap.svg";
import { ReactComponent as IconLeftBlack } from "static/icons/IconMaps.svg";

const Container = styled.div`
	display: flex;
	align-items: center;
	background-color: #ffffff;
`;

const Switcher = styled.button`
	display: flex;
	background: none;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #737373;
	align-items: center;
	gap: 6px;
	padding: 8px 12px;
	height: 30px;
	line-height: 30px;
	${props =>
		props.active &&
		css`
			background: #f9fafb;
			border-width: 1px 1px 0px 1px;
			border-style: solid;
			border-color: #e5e5e5;
			font-weight: 600;
			color: #525252;
			border-radius: 5px 5px 0px 0px;
			position: relative;
			top: 1px;
		`};
`;

export default function ViewSwitcher(props) {
	const active = props.selected;

	return (
		<Container className={props.className}>
			<Switcher
				active={active === ADDRESS_VIEW_TYPES.inputs}
				onClick={() => props.onSwitch(ADDRESS_VIEW_TYPES.inputs)}
				type="button"
			>
				<IconEditMap /> Edit
			</Switcher>
			<Switcher
				active={active === ADDRESS_VIEW_TYPES.map}
				onClick={() => props.onSwitch(ADDRESS_VIEW_TYPES.map)}
				type="button"
			>
				<IconLeftBlack /> Map
			</Switcher>
		</Container>
	);
}
