import React from "react";
import { Box, Text } from "rebass";
import { FileIcon, Td, Tr } from "../TimesheetStyled";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { CLIENT, DATE_FORMAT, FREELANCER } from "config";
import { getIconType } from "../ListRenderer";
import get from "lodash/get";

const ExpandedItem = ({
	isExpanded,
	id,
	hasEdits,
	editedElements,
	mergeEdited,
	hasTag,
	isExpense,
	type,
	hideAmount,
	reportingSettings
}) => {
	return (
		isExpanded === id &&
		hasEdits && (
			<Tr style={{ boxShadow: "0 8px 16px #ccc" }}>
				<Td color={editedElements.includes("date") ? "#FD6950" : ""}>
					{window.moment.unix(mergeEdited.date).format(DATE_FORMAT)}
				</Td>
				<Td color={editedElements.includes("description") ? "#FD6950" : ""}>
					{mergeEdited.description}
				</Td>
				{hasTag && (
					<Td color={editedElements.includes("tag") ? "#FD6950" : ""}>
						{mergeEdited.tag && (
							<span className="job-status tag-status">{mergeEdited.tag}</span>
						)}
					</Td>
				)}
				{isExpense && (
					<Td color={editedElements.includes("document") ? "#FD6950" : ""}>
						{mergeEdited.document ? (
							<a
								href={mergeEdited.document}
								className="file-icon"
								rel="noopener noreferrer"
								target="_blank"
							>
								<FileIcon className={getIconType(mergeEdited.document)} />
							</a>
						) : (
							"N/A"
						)}
					</Td>
				)}
				{!isExpense && (
					<Td
						color={
							editedElements.includes("hours_representation") ? "#FD6950" : ""
						}
						align="center"
					>
						{mergeEdited.hours_representation}
					</Td>
				)}
				{!isExpense && (
					<Td
						color={editedElements.includes("rate") ? "#FD6950" : ""}
					>{`${mergeEdited.rate}%`}</Td>
				)}
				<Td />
				{!hideAmount && (
					<Td
						color={
							editedElements.includes(
								`${type === CLIENT ? CLIENT : FREELANCER}_amount`
							) || editedElements.includes(`price`)
								? "#FD6950"
								: ""
						}
					>
						<CurrencyFormatterRender
							isCent
							value={
								mergeEdited[
									`${type === CLIENT ? CLIENT : FREELANCER}_amount`
								] ||
								mergeEdited.price ||
								0
							}
							currency={get(reportingSettings, "currency.code")}
						/>
					</Td>
				)}
				<Td>
					<Box
						sx={{
							background: "#FFFFFF",
							opacity: "0.5",
							border: "1.3px solid #60CFAC",
							borderRadius: "100px",
							display: "flex",
							justifyContent: "center"
						}}
					>
						<Text
							sx={{
								color: "#60CFAC",
								fontFamily: "BasierCircle",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "12px",
								lineHeight: "22px"
							}}
						>
							Original
						</Text>
					</Box>
				</Td>
				<Td />
				<Td />
			</Tr>
		)
	);
};

export default ExpandedItem;
