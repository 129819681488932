import React from "react";

import { ReactComponent as CalendarOneIcon } from "static/icons/calendar-one.svg";
import { ReactComponent as ClockIcon } from "static/icons/clock-1.svg";
import styles from "./planned-interviews-history.module.scss";
import useVacancyStore from "../../vacancyStore";
import { useGetLatestInterviews } from "../../hooks/useGetLatestInterviews";
import moment from "moment";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import usePokeData from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";

export default function PlannedInterviewsHistory({ job }) {
	const { drawerState } = useVacancyStore();
	const { data: pokeData } = usePokeData({
		id: job ? null : drawerState.applicationId
	});
	const { data, isLoading } = useGetLatestInterviews({
		vacancyId: (job ?? pokeData?.job)._id,
		profileId: drawerState.profileId
	});

	return (
		<div className={styles.history}>
			<div className={styles.historyHeader}>
				<ClockIcon />
				<div>
					<div className={styles.historyTitle}>History of Interviews</div>
					<div className={styles.historyInterviewer}>
						<span>with </span>
						<span className={styles.interviewerName}>
							{drawerState.candidateName}
						</span>
					</div>
				</div>
			</div>
			<div className={styles.historyList}>
				{isLoading ? (
					<SkeletonTheme color="#F1F1F1">
						{Array.from({ length: 8 }).map((_, index) => (
							<Skeleton
								key={index}
								width={"100%"}
								height={10}
								style={{ borderRadius: 99 }}
							/>
						))}
					</SkeletonTheme>
				) : !data.history.length ? (
					<div>
						There are no past interview. Once interviews are conducted, they
						will appear here.
					</div>
				) : (
					data.history.map(item => (
						<div key={item._id} className={styles.historyItem}>
							<div className={styles.historyDate}>
								<CalendarOneIcon />
								<span>
									{moment
										.unix(item.new_interview_start_date)
										.format("DD/MM/YYYY")}
								</span>
							</div>
							<div className={styles.historyTime}>
								{`${moment
									.unix(item.new_interview_start_date)
									.format("HH:mm")} - ${moment
									.unix(item.new_interview_end_date)
									.format("HH:mm")}`}
							</div>
						</div>
					))
				)}
			</div>
		</div>
	);
}
