import React, { useState, useRef, useEffect } from "react";
import s from "./candidate-preferences.module.scss";
import cx from "classnames";
import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";
import useOnClickOutside from "hooks/useOnClickOutside";
import { ReactComponent as CloseBanner } from "static/icons/cancelSmall.svg";
import { ReactComponent as AddIcon } from "static/icons/focus-add.svg";
import { EMPLOYMENT_TYPE_CHECKBOX, LABEL_TYPE_EMPLOYMENT } from "config";

const EmploymentType = ({ onChange, data, isEdit, onEdit, addClicked }) => {
	const dropdownRef = useRef(null);
	const [openSelect, setOpenSelect] = useState(false);
	const [employmentType, setEmploymentType] = useState(
		EMPLOYMENT_TYPE_CHECKBOX
	);
	useEffect(() => {
		if (data.length > 0) {
			const arr = [
				...data.map(el => ({
					value: el,
					checked: true,
					label: LABEL_TYPE_EMPLOYMENT[el]
				})),
				...employmentType
			];
			const uniqueTypesByValue = _uniqBy(arr, "value");
			setEmploymentType(uniqueTypesByValue);
		}
	}, [data]);

	const handleCheckBox = item => {
		const indexOfElm = employmentType.findIndex(
			obj => _get(obj, "value") === _get(item, "value")
		);
		setEmploymentType(
			Object.values({
				...employmentType,
				[indexOfElm]: {
					...employmentType[indexOfElm],
					checked: !_get(item, "checked")
				}
			})
		);
		onChange(
			Object.values({
				...employmentType,
				[indexOfElm]: {
					...employmentType[indexOfElm],
					checked: !_get(item, "checked")
				}
			}).filter(p => p.checked)
		);
	};

	useOnClickOutside(dropdownRef, () => {
		setOpenSelect(false);
	});

	const handleAddEmplymentType = () => {
		onEdit();
		setOpenSelect(true);
		addClicked?.();
	};

	return (
		<div className={s.extraBenifits}>
			<div className={s.extraBenifitItems}>
				{employmentType
					.filter(type => type.checked)
					.map((p, index) => {
						return (
							<span key={index} className={s.item}>
								{p.label}
								{isEdit && (
									<button onClick={() => handleCheckBox(p)}>
										<CloseBanner />
									</button>
								)}
							</span>
						);
					})}

				<button
					className={cx(s.btnAdd, {
						[s["spaceLeft"]]: employmentType.length > 0
					})}
					onClick={() => handleAddEmplymentType()}
				>
					<AddIcon />
					<span>Add employment type </span>
				</button>
			</div>

			{openSelect && (
				<div className={s.listExtraBenifits} ref={dropdownRef}>
					<ul>
						{employmentType.map((item, index) => {
							return (
								<li key={index}>
									<input
										type="checkbox"
										checked={_get(item, "checked")}
										onClick={() => handleCheckBox(item)}
									/>
									<label>{_get(item, "label")}</label>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};
export default EmploymentType;
