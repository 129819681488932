/* eslint-disable react/display-name */
import style from "modules/SearchCandidate/pageHeader/page-header.module.scss";
import React, { useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { SearchInput } from "common/SearchInput";
import { ReactComponent as AddIcon } from "static/icons/addGreenIcon.svg";
import EmptyState from "common/SectionsMenu/components/MenuEmptyState/MenuEmptyState";
import useGetSites from "modules/MyCompany/api/useGetSites";
import get from "lodash/get";
import Tooltip from "common/Tippy";
import styles from "common/SectionsMenu/components/MenuEmptyState/styles.module.scss";
import Fuse from "fuse.js";

const SitesDropDown = ({
	onChange,
	setDisplayDrawer,
	setOpenModal,
	siteId
}) => {
	const [search, setSearch] = useState("");
	const { data, isLoading } = useGetSites({ key: "dropDownKey" });
	const liRefs = useRef([]);

	const filteredResults = !search
		? get(data, "site_list", [])
				.filter(opt => opt?._id !== siteId)
				.map(item => ({ item }))
		: new Fuse(get(data, "site_list", []), {
				keys: ["name"],
				threshold: 0.3
		  }).search(search);

	return (
		<div className={style.menuContainer}>
			<div className={style.inputContainer}>
				<SearchInput
					value={search}
					onChange={e => {
						setSearch(e.target.value);
					}}
					variant="borderless"
				/>
			</div>
			{!isLoading ? (
				filteredResults?.length > 0 ? (
					<Scrollbars
						autoHide
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={300}
					>
						<ul>
							{filteredResults?.map((opt, idx) => (
								<Tooltip
									key={get(opt, "item._id")}
									content={get(opt, "item.name")}
									theme="dark"
									overflow="hidden"
								>
									<li
										ref={el => (liRefs.current[idx] = el)}
										className={style.selectItems}
										onClick={() => {
											onChange(opt?.item);
										}}
									>
										{get(opt, "item.name")}
									</li>
								</Tooltip>
							))}
						</ul>
					</Scrollbars>
				) : (
					<EmptyState />
				)
			) : (
				<p className={styles.loading}>Loading...</p>
			)}

			<div className={style.separator}></div>
			<button
				onClick={() => {
					setOpenModal(false), setDisplayDrawer();
				}}
				className={style.btnNewSite}
			>
				<AddIcon /> New site
			</button>
		</div>
	);
};

export default SitesDropDown;
