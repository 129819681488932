import * as types from "../actions/actionTypes";

const initialState = {
	isFetching: true,
	inAction: false,
	consultants: [],
	listBids: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.TOGGLE_ACTION:
			return Object.assign({}, state, { inAction: !state.inAction });
		case types.BIDS_LIST_DONE_VENDOR:
			return Object.assign({}, state, {
				isFetching: false,
				inAction: false,
				listBids: action.listBids,
				pagination: action.pagination,
				isPagination: action.isPagination
			});
		case types.BIDS_LIST_DONE_CLIENT:
			return Object.assign({}, state, {
				isFetching: false,
				inAction: false,
				listBids: action.listBids,
				jobInfo: action.jobInfo,
				pagination: action.pagination,
				isPagination: action.isPagination
			});
		case types.WRITE_BID_DATA:
			const bids = [...state.listBids];
			const bidIndex = bids.findIndex(bid => bid._id === action.data._id);
			bids[bidIndex] = action.data;
			return Object.assign({}, state, {
				listBids: bids,
				inAction: false
			});
		case types.SET_CONSULTANTS:
			return Object.assign({}, state, {
				consultants: action.consultants
			});
		default:
			return state;
	}
}
