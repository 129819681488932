import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { ADD_PROFILE_TO_POOL } from "config/api-endpoints";

function addProfileToPool(body) {
	return client(ADD_PROFILE_TO_POOL, {
		body
	});
}

export const addProfileToPoolKey = "@profile/addProfileToPool";

function useAddProfileToPool(options) {
	return useMutation(addProfileToPool, options);
}

export default useAddProfileToPool;
