import React from "react";
import { Button } from "common/Button";
import { ReactComponent as EditIcon } from "static/icons/edit-icon-grey.svg";
import styles from "./create-vacancy-edit-button.module.scss";

const CreateVacanyEditButton = ({ onClick }) => {
	return (
		<Button
			text="Edit"
			icon={<EditIcon />}
			variant="outlined"
			className={styles.editButton}
			textClassName={styles.editButtonText}
			onClick={onClick}
		/>
	);
};

export default CreateVacanyEditButton;
