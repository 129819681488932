import React from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import Skeleton from "react-loading-skeleton";
import _get from "lodash/get";
import companyImage from "static/images/Company.jpg";
import { ReactComponent as AddTaskIcon } from "static/icons/addTaskIcon.svg";
import s from "./view-supplier.module.scss";
import Block from "./Block";
import { SUPPLIER } from "config";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

function Heading({ data, isLoading }) {
	const contact_msp = data?.msp_contact_person ?? {};
	const contact_commercial = data?.commercial_contact_person ?? {};
	const {
		setIsOpen: openDrawerTask,
		setEntity,
		setSelectedEntityOption
	} = useAddNewTaskStore();

	const openTaskDrawer = () => {
		openDrawerTask(true);
		setEntity(SUPPLIER);
		setSelectedEntityOption({
			label: data.name,
			value: data._id
		});
	};
	return (
		<Block className={s.heading}>
			<div className={s.companyInfo}>
				{isLoading ? (
					<Skeleton height={80} width={80} />
				) : (
					<img
						alt={"company logo"}
						src={data?.avatar !== "" ? data?.avatar : companyImage}
						width={80}
						height={80}
					/>
				)}

				<div className={s.info}>
					{isLoading ? <Skeleton width={200} /> : <h3>{data?.name}</h3>}
					{isLoading ? (
						<Skeleton width={150} height={8} />
					) : (
						<span>
							{_get(data, "city", false) && _get(data, "country", false)
								? `${_get(data, "city")}, ${_get(data, "country")}`
								: "-"}
						</span>
					)}
				</div>
			</div>
			<div className={s.contactsBlock}>
				<ul className={s.contacts}>
					<li className={s.contactsItem}>
						<h4>Commercial contact person</h4>
						{isLoading ? (
							<Skeleton width={150} />
						) : (
							<span>
								{contact_commercial.first_name} {contact_commercial.last_name}
							</span>
						)}
						<ul>
							<li>
								<PhoneIcon width={16} height={16} />{" "}
								{isLoading ? (
									<Skeleton width={130} />
								) : (
									contact_commercial.phone ?? "-"
								)}
							</li>
							<li>
								<MailIcon width={16} height={16} />
								{isLoading ? (
									<Skeleton width={130} />
								) : (
									contact_commercial.email ?? "-"
								)}
							</li>
						</ul>
					</li>
					<li className={s.contactsItem}>
						<h4>Administrative contact person</h4>
						<span>
							{isLoading ? (
								<Skeleton width={150} />
							) : (
								<>
									{contact_msp.first_name} {contact_msp.last_name}
								</>
							)}
						</span>
						<ul>
							<li>
								<PhoneIcon width={16} height={16} />
								{isLoading ? (
									<Skeleton width={130} />
								) : (
									contact_msp.phone ?? "-"
								)}
							</li>
							<li>
								<MailIcon width={16} height={16} />
								{isLoading ? (
									<Skeleton width={130} />
								) : (
									contact_msp.email ?? "-"
								)}
							</li>
						</ul>
					</li>
				</ul>
				<div className={s.blockCta}>
					<button onClick={openTaskDrawer}>
						<AddTaskIcon /> Add task
					</button>
				</div>
			</div>
		</Block>
	);
}

export default Heading;
