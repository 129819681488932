import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { TASK_MARK_AS_COMPLETE } from "config/api-endpoints";

const markAsCompleted = data => {
	return client(TASK_MARK_AS_COMPLETE, {
		body: data
	});
};

const useMarkTaskCompleted = (_, queryOptions = {}) =>
	useMutation(markAsCompleted, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useMarkTaskCompleted;
