import React, { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import CopyToClipboard from "../../utils/copyValue";
import styles from "./contact-info-Modal.module.scss";
import prependHttp from "prepend-http";

const ContactInfoModal = ({ label, data = [], onClose, headerLinks }) => {
	const modalRef = useRef(null);
	useOnClickOutside(modalRef, () => {
		onClose();
	});
	return (
		<div ref={modalRef} className={styles.root}>
			<h3>{label}</h3>
			<ul>
				{data.map((item, index) => {
					return (
						<li key={index}>
							{headerLinks ? (
								<CopyToClipboard text={item}>
									<a
										href={prependHttp(item)}
										target="_blank"
										rel="noreferrer noopener"
										className={styles.path}
									>
										{item}
									</a>
								</CopyToClipboard>
							) : (
								<CopyToClipboard text={item}>{item}</CopyToClipboard>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};
export default ContactInfoModal;
