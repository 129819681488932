import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tooltip from "rc-tooltip";
import _get from "lodash/get";
import { showModal } from "modules/cv/actions";
import Skills from "common/Skills";
import { SKILLS } from "config";
import GlobalTooltip from "common/GlobalTooltip";
import { getSkillsTooltip } from "config/helpers";
import EmptySkills from "common/EmptyComponent";
import { permanentSkillsSelector } from "modules/candidate/redux/selectors";
import { SkillCategoryContainer } from "modules/candidate/components/styled";

class Competences extends Component {
	constructor(props) {
		super(props);
	}

	handleEditSkills = () => {
		if (this.props.isPermanent) {
			return this.props.displayPermanentModal();
		}
		this.props.dispatch(showModal(SKILLS));
	};

	renderEmptySkills = () => {
		if (this.props.isPermanent && this.props.isClient) {
			return (
				<EmptySkills
					className="no-border small-size"
					title="There is no skill added for this Candidate"
				/>
			);
		}
		if (!this.props.isClient) {
			return (
				<div className="empty-section">
					<div className="add btn-center-block" onClick={this.handleEditSkills}>
						<i className="fa fa-plus" /> Add skills
					</div>
				</div>
			);
		}
	};
	renderSkillsByCategory = () => {
		const categories = {};
		const skills =
			this.props.permanentSkills.length > 0
				? this.props.permanentSkills
				: this.props.skills;
		skills.map(skill => {
			return categories[_get(skill, "parent_sector.name")]
				? categories[_get(skill, "parent_sector.name")].push(skill)
				: (categories[_get(skill, "parent_sector.name")] = [skill]);
		});
		let skillsByCategory = [];
		for (let category in categories) {
			skillsByCategory.push(
				<SkillCategoryContainer key={category}>
					<div className="category-name">{category}</div>
					{this.renderSkills(categories[category])}
				</SkillCategoryContainer>
			);
		}
		return skillsByCategory;
	};
	renderSkills = skills => {
		return (
			<Skills
				skills={skills}
				max={12}
				showMoreText={"•••"}
				showLessText={"↑"}
				readLessLinkClassName={"tag link"}
				readMoreLinkClassName={"tag link"}
				renderOption={skill => (
					<Tooltip
						placement="top"
						overlay={<div>{getSkillsTooltip(skill.score)}</div>}
						trigger="hover"
						destroyTooltipOnHide={true}
						mouseLeaveDelay={0}
						overlayClassName="g-tool-white"
						key={skill._id}
					>
						<div className="tag">{`${skill.name} ( ${skill.score} / 5 )`}</div>
					</Tooltip>
				)}
			/>
		);
	};
	render() {
		let { skills, ownCV, isPermanent, permanentSkills, isClient } = this.props;
		if (isPermanent && permanentSkills.length > 0) {
			skills = permanentSkills;
		}
		return (
			<div id="skills" className="section action-hover">
				{ownCV && skills.length > 0 && !isClient && (
					<div className="section-edit pull-right">
						<button className="btn btn-sm" onClick={this.handleEditSkills}>
							<i className="fa fa-pencil" />
						</button>
					</div>
				)}
				<div className="section-title">
					Skills{" "}
					{ownCV && !isPermanent && (
						<GlobalTooltip
							maxWidth="600px"
							overlay={
								<span>
									The skills that you select are instrumental to both the
									candidate/request matching process and your matching score
									calculation. <br />
									Make sure to be as precise and candid as possible in the
									selection of your skills. <br />A well thought out
									skills-selection will allow you to quickly find more matching
									missions.
								</span>
							}
						/>
					)}
				</div>
				<div className="section-content">
					{!ownCV && !isPermanent && skills.length === 0 && (
						<div className="alert alert-warning">No information available</div>
					)}
					{skills.length !== 0 && (
						<>
							{isPermanent
								? this.renderSkillsByCategory()
								: this.renderSkills(skills)}
						</>
					)}
					{skills.length === 0 && this.renderEmptySkills()}
				</div>
				<div className="clearfix" />
			</div>
		);
	}
}

Competences.propTypes = {
	skills: PropTypes.array.isRequired
};

const mapStateToProps = state => {
	return {
		skills: state.cv.skills || [],
		isFetching: state.cv.isFetching,
		busySection: state.cv.busySection,
		permanentSkills: permanentSkillsSelector(state)
	};
};

export default connect(mapStateToProps)(Competences);
