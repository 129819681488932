import React, { useState } from "react";
import styles from "./request-view-pipeline-card.module.scss";
import { browserHistory } from "react-router";
import { RequestPipelineCardHead } from "../components/RequestPipelineCardHead";
import { RequestPipelineCardBody } from "../components/RequestPipelineCardBody";
import { RequestPipelineCardFooter } from "../components/RequestPipelineCardFooter";
import { RequestPipelineInnerCard } from "../components/RequestPipelineInnerCard";
import { RequestPipelineDateCard } from "../components/RequestPipelineDateCard";
import { RequestPipelineMoneyCard } from "../components/RequestPipelineMoneyCard";
import { RequestPipelineVideoCard } from "../components/RequestPipelineVideoCard";
import { RequestPipelineFilesCard } from "../components/RequestPipelineFilesCard";
import { formatDate } from "common/Functions";
import { addQueryParamToPath, generateId } from "config/helpers";
import useNotesStore from "modules/notes/notes.store";
import { ProposeInterviewToFreelancerDrawer } from "modules/RequestView/components/ProposeInterviewToFreelancerDrawer";
import useCurrentRequest from "modules/RequestView/hooks/useCurrentRequest";
import useSelectedKanbanBid from "modules/RequestView/hooks/useSelectedKanbanBid";
import { RequestPipelineOnSiteCard } from "modules/RequestView/components/RequestPipelineOnSiteCard";
import {
	TIME_FORMAT_SYMBOL,
	CURRENCY_PER_DAY,
	CURRENCY_PER_HOUR
} from "config";
import RequestPipelineCardContainer from "../RequestPipelineCardContainer";
import { BidStatusBadge } from "../../BidDetailsDrawer/components/BidStatusBadge";
import useOpenBidDetailsDrawer from "../../BidDetailsDrawer/hooks/useOpenBidDetailsDrawer";
import RequestPipelineSignedBadge from "../components/RequestPipelineSignedBadge/RequestPipelineSignedBadge";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";

const RequestViewPipelineCard = ({
	data,
	style,
	onProposeContract,
	onRejectApplication
}) => {
	const { requestId } = useCurrentRequest();
	const { setBid: setSelectedBid } = useSelectedKanbanBid();
	const { setBidId, setOpen } = useOpenBidDetailsDrawer();
	const [
		showProposeTempInterviewDrawer,
		setShowProposeTempInterviewDrawer
	] = useState(false);

	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));
	const onProposeInterview = () => {
		setSelectedBid(data);
		setShowProposeTempInterviewDrawer(true);
	};

	const onAddNote = () => {
		addNote({
			type: "BID_NOTE",
			id: generateId(),
			payload: {
				candidate: {
					label: data.candidate_name,
					profile_id: data._id
				},
				requestId: requestId
			}
		});
	};

	const renderInterviewStatus = () => {
		if (data?.interview?.status && !data?.contract?.status)
			return <BidStatusBadge status={data.visible_status} size="sm" />;
	};

	const renderContractStatus = () => {
		if (data?.contract?.status)
			return <BidStatusBadge status={data.visible_status} size="sm" />;
	};

	const convertToHourMin = date => {
		return formatDate(date, true, TIME_FORMAT_SYMBOL);
	};

	const displayProposedAmount = () => {
		const suffix =
			data.reporting_settings.rate_type === "daily"
				? CURRENCY_PER_DAY
				: CURRENCY_PER_HOUR;
		const currencyCode = data.reporting_settings?.currency?.code;

		return (
			<CurrencyFormatterRender
				suffix={suffix}
				isCent
				value={data.proposed_amount}
				currency={currencyCode}
			/>
		);
	};
	const displayContractAmount = () => {
		const suffix =
			data.reporting_settings.rate_type === "daily"
				? CURRENCY_PER_DAY
				: CURRENCY_PER_HOUR;
		const currencyCode = data.reporting_settings?.currency?.code;

		return (
			<CurrencyFormatterRender
				suffix={suffix}
				isCent
				value={data.contract.new_proposition.rate}
				currency={currencyCode}
			/>
		);
	};

	const OnOpenBidDrawer = () => {
		setBidId(data.bid_id);
		setOpen(true);

		browserHistory.replace({
			pathname: window.location.pathname,
			search: addQueryParamToPath(window.location.search, "bid_id", data.bid_id)
		});
	};

	const onCardClick = () => {
		setSelectedBid(data);
		OnOpenBidDrawer();
	};

	const missionIsStarted = data?.contract?.signed_status === "signed";

	return (
		<>
			<RequestPipelineCardContainer onClick={onCardClick} style={style}>
				<RequestPipelineCardHead
					avatar={data.avatar}
					name={data.candidate_name}
					type={data.company_type}
					matchingScore={data.matching_rate}
				/>
				<RequestPipelineCardBody>
					{missionIsStarted && <RequestPipelineSignedBadge />}
					{/* contract */}
					{data?.contract?.new_proposition?.rate && (
						<RequestPipelineInnerCard>
							<div className={styles.flex}>
								<div className={styles.contractText}>Contract</div>
								{renderContractStatus()}
							</div>
							<div className={styles.separator}></div>
							<div className={styles.grid}>
								<RequestPipelineMoneyCard
									amount={displayContractAmount()}
									className={styles.marginRight15}
									tooltipContent={
										data.reporting_settings.rate_type === "daily"
											? "Daily rate"
											: "Hourly rate"
									}
								/>
								<RequestPipelineDateCard
									tooltipContent="Mission start date"
									className={styles.marginRight15}
								>
									{formatDate(data.contract?.new_proposition?.start_date)}
								</RequestPipelineDateCard>
							</div>
						</RequestPipelineInnerCard>
					)}
					{/* interview  */}
					{!!data?.interview?.new_interview_start_date && (
						<RequestPipelineInnerCard>
							<div className={styles.flex}>
								<div className={styles.interviewText}>Interview</div>
								{renderInterviewStatus()}
							</div>
							<div className={styles.separator}></div>
							<div className={styles.grid}>
								<RequestPipelineDateCard
									tooltipContent="Interview date"
									className={styles.marginRight15}
								>
									{`${formatDate(
										data.interview.new_interview_start_date
									)}, ${convertToHourMin(
										data.interview.new_interview_start_date
									)}`}
								</RequestPipelineDateCard>
								{data.interview.location_type === "video_conference" ? (
									<RequestPipelineVideoCard />
								) : (
									<RequestPipelineOnSiteCard />
								)}
							</div>
						</RequestPipelineInnerCard>
					)}
					{/* bid  */}
					<RequestPipelineInnerCard>
						<div className={styles.grid}>
							<RequestPipelineMoneyCard
								amount={displayProposedAmount()}
								className={styles.marginRight15}
								tooltipContent={
									data.reporting_settings.rate_type === "daily"
										? "Daily rate"
										: "Hourly rate"
								}
							/>
							<RequestPipelineDateCard className={styles.marginRight15}>
								{formatDate(data.created_at)}
							</RequestPipelineDateCard>
							<RequestPipelineFilesCard count={data.count_files} />
						</div>
					</RequestPipelineInnerCard>
				</RequestPipelineCardBody>

				<RequestPipelineCardFooter
					bidData={data}
					onAddNote={onAddNote}
					notesCount={data.count_notes}
					onProposeContract={onProposeContract}
					onRejectApplication={onRejectApplication}
					onInterviewButtonClick={onProposeInterview}
				/>
			</RequestPipelineCardContainer>
			{/* // TODO move outside */}
			{showProposeTempInterviewDrawer && (
				<ProposeInterviewToFreelancerDrawer
					onClose={() => setShowProposeTempInterviewDrawer(false)}
					onSuccess={() => setShowProposeTempInterviewDrawer(false)}
				/>
			)}
		</>
	);
};

export default RequestViewPipelineCard;
