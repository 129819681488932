import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { readNotifications, listNotifications } from "../actions";
import NotificationRow from "./common/NotificationRow";
import Pagination from "../../../common/Pagination";
import { Scrollbars } from "react-custom-scrollbars";
import EmptyNotifications from "./common/EmptyNotifications";
import {
	notificationsViewSelector,
	missionNotificationsSelector
} from "../selectors/NotificationsSelectors";

class MissionNotifications extends Component {
	constructor(props) {
		super(props);
		this.readNotification = this.readNotification.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);
	}

	readNotification(notification) {
		notification = JSON.parse(JSON.stringify(notification));
		browserHistory.push(notification.message.url);
		this.props.dispatch(readNotifications(notification._id));
	}

	renderMission(mission) {
		if (!mission) return;
		return (
			<div className="mission" key={mission._id}>
				<div className="col-md-4">
					<h5>Mission</h5>
					<h4>{mission.title}</h4>
				</div>
				<div className="col-md-8">
					<Scrollbars
						autoHide
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={580}
					>
						{this.renderNotifications(mission.notifications)}
					</Scrollbars>
				</div>
			</div>
		);
	}

	renderNotifications(notifications) {
		return notifications.map(notification => {
			return (
				<NotificationRow
					onRead={notif => this.readNotification(notif)}
					key={notification._id}
					notification={notification}
				/>
			);
		});
	}

	handlePageClick(selected) {
		const { data, view } = this.props;
		const offset = data.size * selected;
		this.props.dispatch(listNotifications(offset, view));
	}

	render() {
		const { data } = this.props;
		return (
			<div>
				<div className="mission-notifications section">
					{data &&
						data.missions.length !== 0 &&
						data.missions.map(mission => this.renderMission(mission))}
					{data && data.missions.length === 0 && (
						<div className="empty-missions">
							<div className="col-md-4">
								<h4>Mission notifications</h4>
							</div>
							<div className="col-md-8">
								<EmptyNotifications />
							</div>
						</div>
					)}
				</div>
				{data && data.total !== 0 && (
					<Pagination
						total={data.total}
						size={data.size}
						handlePageClick={this.handlePageClick}
						initialSelected={data.offset / data.size}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		view: notificationsViewSelector(state),
		data: missionNotificationsSelector(state)
	};
};

export default connect(mapStateToProps)(MissionNotifications);
