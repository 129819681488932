/* eslint-disable react/display-name */
import React from "react";

export default ({ onClose }) => {
	return (
		<div className="invite-success">
			<div className="icon">
				<i className="icon icon-check" />
			</div>

			<h3 className="title">Your bid has successfully been submitted!</h3>

			<div className="bid-message">
				Your bid (along with all accompanying information) has successfully been
				sent to the client. After reviewing your bid, the client will select one
				of the following three alternatives:
				<ul className="ul-message">
					<li>Begin negotiating a contract offer right away.</li>
					<li>Request an interview.</li>
					<li>Decline your submitted bid.</li>
				</ul>
			</div>

			<button className="btn btn-faded bid-button" onClick={onClose}>
				Close window
			</button>
		</div>
	);
};
