import React from "react";
import { get } from "lodash";
import Box from "modules/home/components/Box.jsx";
import Card from "modules/home/containers/vacancyRatio/card/index.jsx";
import styles from "modules/home/containers/vacancyRatio/ratio.module.scss";
import { ReactComponent as ApplicationIcon } from "static/icons/application_home_icon.svg";
import { ReactComponent as ShortlistIcon } from "static/icons/shortlist_home_icon.svg";
import { ReactComponent as AdvertIcon } from "static/icons/advert_home_icon.svg";
import { ReactComponent as HiredIcon } from "static/icons/hired_home_icon.svg";
import { ReactComponent as InterviewIcon } from "static/icons/interview_home_icon.svg";
import { ReactComponent as OfferIcon } from "static/icons/offer_home_icon.svg";
import Loader from "common/Loader";
import { HeaderEnd } from "modules/home/containers/interview/header.jsx";
import { HeaderStart } from "modules/home/components/headers.js";
import useRatioStore from "modules/home/containers/vacancyRatio/ratio.store.js";
import useVacancyRatio from "modules/home/api/useVacancyRatio.js";

const VacancyRatio = () => {
	const { start_date, end_date, isSettedDate, setItem } = useRatioStore();

	const { data, isLoading } = useVacancyRatio({
		start_date:
			start_date && start_date.isValid() ? start_date.unix() : undefined,
		end_date: end_date && end_date.isValid() ? end_date.unix() : undefined
	});

	const DATA = [
		{
			iconFrom: <AdvertIcon className={styles.icon} />,
			iconTo: <ApplicationIcon className={styles.icon} />,
			from: "Advert",
			to: "Applications",
			data: get(data, "adverts_to_application", 0)
		},
		{
			iconFrom: <ApplicationIcon className={styles.icon} />,
			iconTo: <InterviewIcon className={styles.icon} />,
			from: "applications",
			to: "interview",
			data: get(data, "applications_to_interview", 0)
		},
		{
			iconFrom: <ShortlistIcon className={styles.icon} />,
			iconTo: <InterviewIcon className={styles.icon} />,
			from: "shortlist",
			to: "interview",
			data: get(data, "shortlists_to_interview", 0)
		},
		{
			iconFrom: <InterviewIcon className={styles.icon} />,
			iconTo: <OfferIcon className={styles.icon} />,
			from: "Interview",
			to: "Offer",
			data: get(data, "interviews_to_offer", 0)
		},
		{
			iconFrom: <ApplicationIcon className={styles.icon} />,
			iconTo: <OfferIcon className={styles.icon} />,
			from: "Applications",
			to: "Offer",
			data: get(data, "applications_to_offer", 0)
		},
		{
			iconFrom: <OfferIcon className={styles.icon} />,
			iconTo: <HiredIcon className={styles.icon} />,
			from: "Offer",
			to: "Hired",
			data: get(data, "offers_to_hired", 0)
		}
	];

	return (
		<Box
			headerEnd={
				<HeaderEnd
					start_date={start_date}
					end_date={end_date}
					setItem={setItem}
					start_date_Key={"start_date"}
					end_date_key={"end_date"}
					isSettedDate={isSettedDate}
				/>
			}
			headerStart={<HeaderStart label="Vacancy ratio" />}
		>
			{isLoading ? (
				<div className={styles.loaderContainer}>
					{" "}
					<Loader />{" "}
				</div>
			) : (
				<div className={styles.container}>
					{DATA.map(item => (
						<Card key={item.from} {...item} />
					))}
				</div>
			)}
		</Box>
	);
};

export default VacancyRatio;
