import React from "react";
import styles from "./add-entries-drawer-rows-card.module.scss";
import { TimesheetEntryRow } from "modules/timesheets/components/TimesheetEntryRow";
import { AddTimesheetEntriesCard } from "modules/timesheets/components/AddTimesheetEntriesDrawerBody/AddTimesheetEntriesCard";
import useEntries from "modules/timesheets/hooks/useEntries";

const AddEntriesDrawerRowsCard = ({
	list = [],
	title = "Worktimes",
	icon,
	showAmountInDrawer = true,
	timesheet,
	entriesErrors
}) => {
	const { deleteEntry } = useEntries();

	const onDelete = (id, type) => {
		deleteEntry(timesheet?.data?._id, id, `${type}s`);
	};

	const getTime = ({ type, overtimeDuration, time }) => {
		let hours;
		if (
			type.value === "worktime" &&
			overtimeDuration &&
			overtimeDuration?.value !== "custom"
		) {
			const splitedDayHours = timesheet?.data?.reporting_settings?.hours_representation.split(
				":"
			);
			const isHoursOdd = Number(splitedDayHours[0]) % 2 === 1;

			const addedMinutes = isHoursOdd && overtimeDuration.value == 0.5 ? 30 : 0;
			var amountOfTime = window.moment().utcOffset(0);
			amountOfTime.set({
				hour: overtimeDuration.value * Number(splitedDayHours[0]),
				minute:
					overtimeDuration.value * Number(splitedDayHours[1]) + addedMinutes,
				second: 0,
				millisecond: 0
			});
			if (overtimeDuration.value === "0.5")
				hours = `${amountOfTime.hours()}h ${amountOfTime.minutes()}m`;
			else
				hours = `${amountOfTime.hours()}h ${
					amountOfTime.minutes().toString().length > 1
						? amountOfTime.minutes()
						: amountOfTime.minutes() + "0"
				}m`;
		} else {
			let minutes = time.minutes();
			hours = `${time.hours()}h ${
				minutes.toString().length > 1 ? minutes : "0" + minutes
			}m`;
		}

		return hours;
	};

	return (
		<AddTimesheetEntriesCard>
			<div className={styles.head}>
				{icon}
				<div className={styles.mainTitle}>{title}</div>
				<div className={styles.secondaryTitle}>
					&nbsp;/&nbsp; {list.length} {list.length === 1 ? "entry" : "entries"}
				</div>
			</div>
			<div className={styles.separator} />
			<div className={styles.list}>
				{list
					.sort((a, b) => a.date.day - b.date.day)
					.map(
						(
							{
								_id,
								type,
								date,
								comment,
								recipet,
								rate,
								tag,
								amount,
								calculatedAmount
							},
							i
						) => {
							return (
								<TimesheetEntryRow
									key={_id}
									type={type.value}
									onDelete={() => onDelete(_id, type.value)}
									comment={comment}
									amount={amount}
									tag={tag?.value}
									recipet={recipet || ""}
									rate={rate}
									time={type.value === "expense" ? "" : getTime(list[i])}
									date={date}
									calculatedAmount={calculatedAmount}
									showAmountInDrawer={showAmountInDrawer}
									timesheet={timesheet}
									error={entriesErrors[_id] || ""}
								/>
							);
						}
					)}
			</div>
		</AddTimesheetEntriesCard>
	);
};

export default AddEntriesDrawerRowsCard;
