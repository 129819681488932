import { createSelector } from "reselect";
import _get from "lodash/get";

const getFilter = state => _get(state, "search_profile.filter", []);
const getSectors = state => _get(state, "search_profile.sectors", []);
const getSubSectors = state => _get(state, "search_profile.subSectors", []);
const getFunctions = state => _get(state, "search_profile.functions", []);
const getSearchProfile = state => _get(state, "search_profile");
const getNotes = state => _get(state, "viewPokeReducer");
const getJobDetails = state => _get(state, "search_profile.job_detail");
const getProfilesList = state => _get(state, "search_profile.data");
const getShowVideoConf = state =>
	_get(state, "viewPokeReducer.showVideoConf", false);
const getPokesList = state => _get(state, "viewPokeReducer.pokes_list", {});

export const pokesListSelector = createSelector(
	getPokesList,
	pokes_list => pokes_list
);

export const searchProfileSelector = createSelector(
	getSearchProfile,
	filter => filter
);
export const filterSelector = createSelector(getFilter, filter => filter);
export const sectorsSelector = createSelector(getSectors, sectors => sectors);
export const subSectorsSelector = createSelector(
	getSubSectors,
	sectors => sectors
);
export const functionsSelector = createSelector(
	getFunctions,
	functions => functions
);

export const notesSelector = createSelector(getNotes, notes => notes);

export const jobDetailsSelector = createSelector(
	getJobDetails,
	details => details
);

export const profilesListSelector = createSelector(
	getProfilesList,
	profiles => profiles
);

export const showVideoConfSelector = createSelector(
	getShowVideoConf,
	showVideoConf => showVideoConf
);
