// CHECK THIS FILE AND REMOVE UNNECESSARY CODE
import BackButton from "common/Buttons/BackButton";
import { formatDate } from "common/Functions";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import Pagination from "common/PaginationClient";
import Spinner from "common/Spinner";
import { Button } from "common/styled/buttons";
import Icon from "common/styled/icons";
import Table from "common/Table";
import Status from "common/Table/Status";
import {
	ACTIVE_VACANCIES,
	ACTIVE_VACANCIES_LABEL,
	ALL,
	DELETED,
	DRAFT,
	LIVE_VACANCIES,
	MARK_AS_FILLED,
	VACANCY,
	VACANCY_STATUS
} from "config";
import { FILTER_SETTINGS_ENDPOINT, MY_JOBS_LIST } from "config/api-endpoints";
import { historyPush, searchParser } from "config/helpers";
import { client } from "lib/api-client";
import _find from "lodash/find";
import _get from "lodash/get";
import PublishJob from "modules/job/components/modals/PublishJob";
import { jobToPublishSelector } from "modules/job/selectors";
import { getNewVacancyProfileRoute } from "modules/vacancy/create-vacancy";
import React, { useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { usePaginatedQuery, useQuery } from "react-query";
import { connect } from "react-redux";
import { Box, Flex, Text } from "rebass";
import MarkAsFilled from "../modals/MarkAsFilled";
import StopVacancy from "../modals/StopVacancy";
import Actions from "./Actions";
import Filters from "./Filters";
import useVacancyListStore from "./useVacancyListStore";

const getVacancyList = (_key, { sort, status, page, filters, search }) => {
	return client(MY_JOBS_LIST, {
		body: {
			status,
			sort,
			...filters,
			job_type: VACANCY,
			search,
			offset: page * 9
		}
	});
};

function VacancyList({
	resetJobData,
	stopJob,
	route: { title: routeTitle },
	jobToPublish
} = {}) {
	const [filters, setFilters] = useState({
		offset: 0,
		status: LIVE_VACANCIES
	});

	useEffect(() => {
		if (filters.status === undefined) {
			setFilters({
				...filters,
				status: ALL
			});
		}
	}, [filters]);
	const [search, setSearch] = useState("");

	const [sort, setSort] = useState("desc");

	const { page, status, setPage } = useVacancyListStore();

	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Permanent" },
			{ name: "Vacancies", path: "/vacancies/list" }
		]);
	}, []);

	const {
		isLoading,
		resolvedData: vacancyList,
		refetch,
		isFetching,
		isFetched
	} = usePaginatedQuery(
		["getVacancyList", { status, sort, page, filters, search }],
		getVacancyList
	);

	const stopPropagation = e => {
		if (e) e.stopPropagation();
	};

	const handleDisplayPublish = vacancy => {
		historyPush(`/vacancy/${vacancy.id}`);
	};

	const getSettings = () => {
		return client(FILTER_SETTINGS_ENDPOINT, {
			body: {
				tag: "permanent_vacancy"
			}
		});
	};
	const settingsData = useQuery(["loadSettings"], getSettings);
	const searchQueries = searchParser();

	const viewVacancyDetails = (row, e) => {
		const vacancy = _get(row, "original");
		const id = vacancy.id;
		const step = vacancy.step;
		const status = vacancy.status;
		if (status === DELETED && step < 3) {
			return;
		}
		if (!e.target.classList.contains("ignore-click")) {
			if (_get(vacancy, "status") === DRAFT && _get(vacancy, "step") === 2) {
				historyPush(`/vacancy/create/request/${id}`);
			} else {
				resetJobData();
				const link = `/vacancy/${id}`;
				historyPush(link);
			}
		}
	};

	const renderStatus = data => {
		return (
			<Flex
				sx={{
					cursor: "pointer",
					width: "100%",
					height: "100%",
					alignItems: "center"
				}}
			>
				<Status status={_get(data, "value")} />
				{_get(
					_find(VACANCY_STATUS, status => data.value === status.value),
					"label",
					""
				)}
			</Flex>
		);
	};

	const renderActions = data => {
		const vacancy = _get(data, "cell.row.original");
		return (
			<Actions
				status={vacancy.status}
				vacancy={vacancy}
				id={vacancy.id}
				reposition={true}
				hasPoke={vacancy.number_pokes > 0}
				publishModal={handleDisplayPublish}
			>
				<div onClick={stopPropagation}>
					{isLoading ? (
						<Skeleton />
					) : (
						<div id="actions-btn" className="dots ignore-click">
							<div className="dot ignore-click" />
							<div className="dot ignore-click" />
							<div className="dot ignore-click" />
						</div>
					)}
				</div>
			</Actions>
		);
	};

	const renderCreatedAt = data => {
		const vacancy = _get(data, "cell.row.original");
		return (
			<Flex
				sx={{
					cursor: "pointer",
					width: "100%",
					height: "100%",
					alignItems: "center"
				}}
			>
				{formatDate(vacancy.created_at, true)}
			</Flex>
		);
	};

	const renderRef = data => {
		const vacancy = _get(data, "cell.row.original");
		return (
			<Flex
				sx={{
					cursor: "pointer",
					width: "100%",
					height: "100%",
					alignItems: "center"
				}}
			>
				{vacancy.job_ref}
			</Flex>
		);
	};

	const renderJobTitle = data => {
		const vacancy = _get(data, "cell.row.original");
		return (
			<Flex
				sx={{
					cursor: "pointer",
					width: "100%",
					height: "100%",
					alignItems: "center"
				}}
			>
				{vacancy.title}
			</Flex>
		);
	};

	const renderDepartement = data => {
		const vacancy = _get(data, "cell.row.original");
		return (
			<Flex
				sx={{
					cursor: "pointer",
					width: "100%",
					height: "100%",
					alignItems: "center"
				}}
			>
				{_get(vacancy, "group.name")}
			</Flex>
		);
	};

	const renderApplications = data => {
		const vacancy = _get(data, "cell.row.original");
		return (
			<Flex
				sx={{
					cursor: "pointer",
					width: "100%",
					height: "100%",
					alignItems: "center"
				}}
			>
				{vacancy.number_pro_active_application}
			</Flex>
		);
	};
	const renderShortlist = data => {
		const vacancy = _get(data, "cell.row.original");
		return (
			<Flex
				sx={{
					cursor: "pointer",
					width: "100%",
					height: "100%",
					alignItems: "center"
				}}
			>
				{vacancy.number_shortlisted_pokes}
			</Flex>
		);
	};

	const renderInterviewDone = data => {
		const vacancy = _get(data, "cell.row.original");
		return (
			<Flex
				sx={{
					cursor: "pointer",
					width: "100%",
					height: "100%",
					alignItems: "center"
				}}
			>
				{vacancy.number_interviews_count}
			</Flex>
		);
	};

	const getTableColumns = settings => [
		{
			Header: "REF",
			accessor: "job_ref",
			width: 110,
			hasFilter: true,
			filter: "job_ref",
			filterOptions: formatSettings(_get(settings, "job_ref", [])),
			Cell: renderRef
		},
		{
			Header: "POSTED DATE",
			accessor: "created_at",
			Cell: renderCreatedAt,
			hasFilter: true,
			disabledFilter: true,
			width: 130
		},
		{
			Header: "JOB TITLE",
			accessor: "title",
			hasFilter: true,
			filter: "job_id",
			filterOptions: formatSettings(_get(settings, "jobs", [])),
			Cell: renderJobTitle,
			width: 150
		},
		{
			Header: `DEPARTMENT`,
			accessor: "group.name",
			hasFilter: true,
			filter: "group_id",
			filterOptions: formatSettings(_get(settings, "groups", [])),
			Cell: renderDepartement,
			width: 130
		},
		{
			Header: "APPLICATIONS",
			accessor: "number_pro_active_application",
			Cell: renderApplications,
			width: 110
		},
		{
			Header: "SHOURCED",
			accessor: "number_shortlisted_pokes",
			Cell: renderShortlist,
			width: 85
		},

		{
			Header: "INTERVIEWS DONE",
			accessor: "number_interviews_count",
			Cell: renderInterviewDone,
			width: 90
		},
		{
			Header: "STATUS",
			accessor: "status",
			Cell: renderStatus,
			width: 160,
			hasFilter: true,
			filter: "status",
			filterOptions: formatSettings(_get(settings, "status", []))
		},
		{
			Header: "ACTIONS",
			Cell: renderActions,
			width: 70,
			onClick: e => e.stopPropagation()
		}
	];

	const publishNewVacancy = () => {
		resetJobData();
		historyPush(getNewVacancyProfileRoute(""));
	};

	const handlePageClick = value => {
		setPage(value);
	};

	const getActionModal = () => {
		if (!stopJob || !stopJob.id) {
			return;
		}
		switch (stopJob.mode) {
			case MARK_AS_FILLED:
				return <MarkAsFilled onSuccess={refetch} />;
			default:
				return <StopVacancy onSuccess={refetch} />;
		}
	};

	const formatSettings = (settings, isDate) => {
		return settings.map(setting => {
			if (typeof setting === "string" || typeof setting === "number") {
				const renderStatus = setting => {
					if (setting === "deleted") {
						return "Archived";
					}
					if (setting === "closed") {
						return "Closed to applications";
					}
					if (setting === "opened") {
						return "Open for internal use";
					}
					if (setting === ACTIVE_VACANCIES) {
						return ACTIVE_VACANCIES_LABEL;
					}
					return (
						<Text as={"span"} sx={{ textTransform: "capitalize" }}>
							{setting}
						</Text>
					);
				};
				return {
					label: renderStatus(setting),
					value: setting
				};
			}

			return {
				label: isDate
					? formatDate(_get(setting, "name"))
					: _get(
							setting,
							"title",
							_get(setting, "name", _get(setting, "full_name"))
					  ),
				value: _get(setting, "_id")
			};
		});
	};

	return (
		<BodyClassName className="my-job list-Vacancies gray-bg">
			<Box pl={15} pr={15} className="Vacancies-list-preview">
				{routeTitle && (
					<Helmet>
						<title>{routeTitle}</title>
					</Helmet>
				)}

				<div className="full-container">
					<div className="top-actions" style={{ position: "relative" }}>
						{Boolean(_get(searchQueries, "from", "") === "home") && (
							<BackButton
								style={{ justifyContent: "left", marginTop: "20px" }}
							/>
						)}
						<Box mt={10}>
							<Filters
								submitSearch={value => {
									setSearch(value);
								}}
								settingsData={settingsData}
								setSort={setSort}
								sort={sort}
							/>
						</Box>
						<Flex
							sx={{
								position: "absolute",
								bottom: "0",
								right: "0"
							}}
							justifyContent="flex-end"
						>
							<Flex mr={2} justifyContent={"center"} alignItems={"center"}>
								{isFetching && !isLoading && (
									<Spinner
										color={"#ff6849"}
										duration={"0.5"}
										sx={{ borderWidth: 3 }}
									/>
								)}
							</Flex>
							<Box>
								<Button
									size={14}
									style={{
										paddingTop: 12,
										paddingBottom: 12,
										float: "left"
									}}
									onClick={publishNewVacancy}
								>
									<Icon className="fa fa-plus" />
									Create a new vacancy
								</Button>
							</Box>
						</Flex>
					</div>

					<>
						<Table
							filters={filters}
							setFilters={setFilters}
							setPage={setPage}
							columns={getTableColumns(_get(settingsData, "data", []))}
							data={_get(vacancyList, "data")}
							isLoading={isLoading}
							isFetching={isFetching && !isFetched}
							emptyTitle={"No vacancies"}
							emptyText={"You haven’t any vacancies yet."}
							onRowClick={viewVacancyDetails}
						/>
						{getActionModal()}

						<Pagination
							total={vacancyList?.total || 0}
							size={vacancyList?.size || 0}
							handlePageClick={handlePageClick}
							offset={page}
							forcePage={Math.ceil(vacancyList?.offset / vacancyList?.size)}
						/>
						{jobToPublish && <PublishJob job={jobToPublish} />}
					</>
				</div>
			</Box>
		</BodyClassName>
	);
}

const mapStateToProps = state => ({
	jobToPublish: jobToPublishSelector(state)
});

export default connect(mapStateToProps)(VacancyList);
