import React, { useEffect, useState } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import get from "lodash/get";
import { queryCache } from "react-query";
import Box from "modules/home/components/Box.jsx";
import useTemporaryStore from "modules/home/zustand/temporary.store.js";
import style from "modules/home/components/permanent/styles.module.scss";
import { historyPush } from "config/helpers";
import { HeaderEnd } from "modules/home/containers/interview/header.jsx";
import {
	NewBidsList,
	NewContractList,
	TimesheetToReview,
	RequestList,
	UpcomingStarters,
	UpcomingFinishers
} from "modules/home/components/Temporary";
import {
	NEW_BID,
	NEW_CONTRACT,
	TIMESHEET_TO_REVIEW,
	UPCOMING_STARTERS,
	UPCOMING_FINISHERS,
	REQUEST
} from "modules/home/config";
import TabCount from "modules/home/components/TabCount.jsx";
import {
	bidsListKey,
	contractListKey,
	requestKey,
	timesheetToReviewKey,
	upcomingFinishersKey,
	upcomingStartersKey
} from "modules/home/api/queriesKeys.js";
const TEMPORARY_TYPE = "temporaryTab";

const Temporary = ({ listCount }) => {
	const [redirectPath, setRedirectPath] = useState("/bids");
	const { startDate, endDate, setItem, temporaryTab } = useTemporaryStore();
	useEffect(() => {
		getUrlState(temporaryTab);
	}, []);
	const handleTabsChange = index => {
		switch (index) {
			case 0:
				{
					setRedirectPath("/bids");
					setItem(TEMPORARY_TYPE, NEW_BID);
					queryCache.invalidateQueries(bidsListKey);
				}
				break;
			case 1:
				{
					setRedirectPath("/contract-propositions");
					setItem(TEMPORARY_TYPE, NEW_CONTRACT);
					queryCache.invalidateQueries(contractListKey);
				}
				break;
			case 2:
				{
					setRedirectPath("/temporary/timesheets");
					setItem(TEMPORARY_TYPE, TIMESHEET_TO_REVIEW);
					queryCache.invalidateQueries(timesheetToReviewKey);
				}
				break;
			case 3:
				{
					setRedirectPath("/missions");
					setItem(TEMPORARY_TYPE, UPCOMING_STARTERS);
					queryCache.invalidateQueries(upcomingStartersKey);
				}
				break;
			case 4:
				{
					setRedirectPath("/missions");
					setItem(TEMPORARY_TYPE, UPCOMING_FINISHERS);
					queryCache.invalidateQueries(upcomingFinishersKey);
				}
				break;
			case 5:
				{
					setRedirectPath("/jobs/list");
					setItem(TEMPORARY_TYPE, REQUEST);
					queryCache.invalidateQueries(requestKey);
				}
				break;
		}
	};
	const getUrlState = type => {
		switch (type) {
			case NEW_BID:
				setRedirectPath("/bids");
				break;
			case NEW_CONTRACT:
				setRedirectPath("/contract-propositions");
				break;
			case TIMESHEET_TO_REVIEW:
				setRedirectPath("/temporary/timesheets");
				break;
			case UPCOMING_STARTERS:
				setRedirectPath("/missions");
				break;
			case UPCOMING_FINISHERS:
				setRedirectPath("/missions");
				break;
			case REQUEST:
				setRedirectPath("/jobs/list");
				break;
			default:
				setRedirectPath("/bids");
				break;
		}
	};
	const getTypeIndex = type => {
		switch (type) {
			case NEW_BID:
				return 0;
			case NEW_CONTRACT:
				return 1;
			case TIMESHEET_TO_REVIEW:
				return 2;
			case UPCOMING_STARTERS:
				return 3;
			case UPCOMING_FINISHERS:
				return 4;
			case REQUEST:
				return 5;
			default:
				return 0;
		}
	};
	const handleViewAll = () => {
		return historyPush(`${redirectPath}?from=home`);
	};
	const TabsTemporary = () => {
		return (
			<TabList>
				<Tab>
					New bid <TabCount count={get(listCount, "new_bid", 0)} />
				</Tab>
				<Tab>
					New contract <TabCount count={get(listCount, "new_contract", 0)} />
				</Tab>
				<Tab>
					Timesheet to review{" "}
					<TabCount count={get(listCount, "timesheet_to_review", 0)} />
				</Tab>
				<Tab>
					Upcoming starters{" "}
					<TabCount count={get(listCount, "upcoming_starters", 0)} />
				</Tab>
				<Tab>
					Upcoming finishers{" "}
					<TabCount count={get(listCount, "upcoming_finishers", 0)} />
				</Tab>
				<Tab>
					Request <TabCount count={get(listCount, "request", 0)} />
				</Tab>
			</TabList>
		);
	};
	return (
		<Tabs defaultIndex={getTypeIndex(temporaryTab)} onChange={handleTabsChange}>
			<Box
				headerEnd={
					<HeaderEnd
						start_date={startDate}
						end_date={endDate}
						setItem={setItem}
						start_date_Key={"startDate"}
						end_date_key={"endDate"}
						handleViewAll={handleViewAll}
					/>
				}
				headerStart={<TabsTemporary />}
			>
				<div className={style.tabsContainer}>
					<TabPanels>
						<TabPanel>
							<NewBidsList handleViewAll={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<NewContractList handleViewAll={handleViewAll} />{" "}
						</TabPanel>
						<TabPanel>
							<TimesheetToReview handleViewAll={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<UpcomingStarters handleViewAll={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<UpcomingFinishers handleViewAll={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<RequestList handleViewAll={handleViewAll} />
						</TabPanel>
					</TabPanels>
				</div>
			</Box>
		</Tabs>
	);
};

export default Temporary;
