import React, { useState } from "react";
import { Box, Flex } from "rebass";
import Container from "../Container";
import SubContainer from "../SubContainer";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import cx from "classnames";
import { client } from "lib/api-client";
import { RESEND_ACTIVATE_EMAIL } from "config/api-endpoints";
import { useMutation } from "react-query";
import toaster from "common/Toaster";
import SignupSuccess from "../SignUp/SignUpSuccess";

export function getResendActivationRoute() {
	return "/resend-activation";
}

const resendActivationEmail = email =>
	client(RESEND_ACTIVATE_EMAIL, { body: { email } });

const schema = yup.object().shape({
	email: yup
		.string()
		.required("Please enter your email.")
		.email("Please enter a valid email.")
});

export default function ResendActivation() {
	const [isSuccess, setIsSuccess] = useState(false);
	const { formState, handleSubmit, register, getValues, watch } = useForm({
		resolver: yupResolver(schema)
	});

	const [mutate, { isLoading }] = useMutation(resendActivationEmail, {
		onSuccess: () => {
			toaster.success("The activation email has successfully been resent.");
			setIsSuccess(true);
		},
		onError: error => {
			if (typeof error.detail === "string") {
				toaster.danger(error.detail);
			} else {
				const errorMessage =
					error.detail?.email?.[0] ?? error.detail?.name?.[0];
				if (errorMessage) {
					toaster.danger(errorMessage);
				}
			}
		}
	});

	const onSubmit = () => {
		mutate(getValues("email"));
	};

	return (
		<Flex height={"100%"}>
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<title>Resend Email Activation</title>
			</Helmet>
			<Box flexGrow={1} display={"flex"} alignItems={"center"}>
				<Container>
					{isSuccess ? (
						<SignupSuccess email={watch("email")} />
					) : (
						<SubContainer>
							<h3 className="heading">Resend Email Activation</h3>
							<p className="description">
								Enter your email address below to have your activation email
								resent to you. <br />
								If needed, don&#39;t hesitate to{" "}
								<Link className="link" to="/ask-question">
									contact our support team!
								</Link>
							</p>
							<form className="form" onSubmit={handleSubmit(onSubmit)}>
								<div className="form-field">
									<div
										className={cx("form-group", {
											"has-error": !!formState.errors.email
										})}
									>
										<label htmlFor="email">Email</label>
										<input
											{...register("email")}
											id="email"
											className="form-control"
											placeholder="example@gmail.com"
										/>
										{!!formState.errors.email && (
											<div className="help-block inline-error">
												{formState.errors.email.message}
											</div>
										)}
									</div>
								</div>
								<button className="form-btn" disabled={isLoading}>
									{isLoading ? (
										<AnimatedLoaderIcon width={24} />
									) : (
										"Resend activation email"
									)}
								</button>
							</form>
						</SubContainer>
					)}
				</Container>
			</Box>
		</Flex>
	);
}
