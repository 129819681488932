import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_NOTE } from "config/api-endpoints";

function deleteNote(body) {
	return client(DELETE_NOTE, {
		body
	});
}

function useDeleteNote(options) {
	return useMutation(deleteNote, options);
}

export default useDeleteNote;
