import React, { useEffect, useState } from "react";
import loadable from "loadable-components";
import EvaluationCard from "common/EvaluationCard/EvaluationCard";
import EvaluationCardHeader from "common/EvaluationCard/EvaluationCardHeader/EvaluationCardHeader";
import EvaluationCardProfileOverview from "common/EvaluationCard/EvaluationCardProfileOverview/EvaluationCardProfileOverview";
import EvaluationCardActions from "common/EvaluationCard/EvaluationCardActions/EvaluationCardActions";
import { ReactComponent as DividerIcon } from "static/icons/divider.svg";
import QuickEvaluationCardBody from "common/EvaluationCard/QuickEvaluationCardBody/QuickEvaluationCardBody";
import EvaluationCardCandidateLabel from "common/EvaluationCard/EvaluationCardCandidateLabel/EvaluationCardCandidateLabel";
import EvaluationCardEditButton from "common/EvaluationCard/EvaluationCardEditButton/EvaluationCardEditButton";
import EvaluationCardDeleteButton from "common/EvaluationCard/EvaluationCardDeleteButton/EvaluationCardDeleteButton";
import EvaluationCardScoreStatus from "common/EvaluationCard/EvaluationCardScoreStatus/EvaluationCardScoreStatus";
import styles from "./permanent-evaluations-list.module.scss";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { historyPush } from "config/helpers";
import useGetPermanentEvaluations from "modules/candidate/Profile/api/useGetPermanentEvaluations";
import usePermanentId from "modules/candidate/Profile/Store/usePermanentId";
import usePermanentToEvaluate from "../ProfileViewEvaluationTemplateFormDrawer/hooks/usePermanentToEvaluate";
import useOpenTemplateEvaluationDrawer from "../ProfileViewEvaluationTemplateFormDrawer/hooks/useOpenTemplateEvaluationDrawer";
import useOpenEditQuickEvaluation from "../ProfileEditQuickEvaluationDrawer/hooks/useOpenEditQuickEvaluation";
import useEditQuickEvaluationData from "../ProfileEditQuickEvaluationDrawer/hooks/useEditQuickEvaluationData";
import Card from "modules/candidate/Profile/components/Card";
import { PermanentEvaluationsListEmptyState } from "../PermanentEvaluationsListEmptyState";
import EvaluationDetailsCardBody from "common/EvaluationCard/EvaluationDetailsCardBody/EvaluationDetailsCardBody";
import { browserHistory } from "react-router";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const PermanentViewEvaluationDetailsDrawer = loadable(() =>
	import(
		"modules/candidate/components/PermanentViewEvaluationDetailsDrawer/PermanentViewEvaluationDetailsDrawer"
	)
);

const ProfileViewEvaluationTemplateFormDrawer = loadable(() =>
	import(
		"../ProfileViewEvaluationTemplateFormDrawer/ProfileViewEvaluationTemplateFormDrawer"
	)
);

const ProfileEditQuickEvaluationDrawer = loadable(() =>
	import("../ProfileEditQuickEvaluationDrawer/ProfileEditQuickEvaluationDrawer")
);

const DeleteEvaluationModal = loadable(() =>
	import("../DeleteEvaluationModal/DeleteEvaluationModal")
);

const PermanentEvaluationsList = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility
}) => {
	const [evaluationToDelete, setEvaluationToDelete] = useState(null);
	const user = useSelector(onlineUserSelector);
	const { id } = usePermanentId();
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
	const { setData: setEvaluationTemplateData } = usePermanentToEvaluate();
	const {
		isOpen: isOpenTemplateEvaluationDrawer,
		setIsOpen: setIsOpenEvaluationTemplate
	} = useOpenTemplateEvaluationDrawer();
	const {
		isOpen: isOpenEditQuickEvaluation,
		setIsOpen: setIsOpenQuickEvaluation
	} = useOpenEditQuickEvaluation();
	const { setData: setQuickEvaluationData } = useEditQuickEvaluationData();

	const { workflowId } = useOnboardingStore();
	const { data } = useGetPermanentEvaluations(
		{
			fields: [],
			limit: 5,
			offset: 0,
			op: "and",
			profile_id: id,
			search: "",
			sort_by: {}
		},
		{ enabled: !workflowId }
	);

	const onDelete = _id => {
		setEvaluationToDelete(_id);
		setShowConfirmDeleteModal(true);
	};

	const onEditEvaluation = evaluation => {
		const template = {
			...evaluation,
			elements: evaluation?.evaluation_form || []
		};

		const drawerData = {
			template,
			is_edit_mode: true,
			vacancy_id: evaluation?.job?._id,
			vacancy_name: evaluation?.job?.title,
			avatar: evaluation?.profile?.avatar,
			candidate_id: evaluation?.profile?._id,
			candidate_name: evaluation?.profile?.candidate_name
		};

		if (!!template.elements.length) {
			setEvaluationTemplateData(drawerData);
			setIsOpenEvaluationTemplate(true);
		} else {
			setQuickEvaluationData(drawerData);
			setIsOpenQuickEvaluation(true);
		}
	};

	const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
	const hasEvaluationQueryParams = new URLSearchParams(
		window.location.search
	).has("view_evaluation_id");

	useEffect(() => setShowDetailsDrawer(hasEvaluationQueryParams), [
		hasEvaluationQueryParams
	]);

	const redirectToEvaluationDetails = evaluationId => {
		const urlParams = new URLSearchParams(window.location.search);
		urlParams.set("view_evaluation_id", evaluationId);
		browserHistory.push(`${window.location.pathname}?${urlParams.toString()}`);
	};
	const redirectToEvaluationList = () => {
		const urlParams = new URLSearchParams(window.location.search);
		urlParams.delete("view_evaluation_id");
		browserHistory.push(`${window.location.pathname}?${urlParams.toString()}`);
	};

	const getFormFirstQuestion = form => {
		const question = form[0];

		if (question.type !== "section") {
			return [question];
		} else {
			return [
				{
					...question,
					items: question.items.slice(0, 1)
				}
			];
		}
	};

	if (!data) return null;

	return (
		<Card
			typeBtn="evaluations"
			title={<h2>Evaluations</h2>}
			{...{ onDragEnd, onViewMore, onToggleVisibility }}
		>
			{data.evaluations.length === 0 ? (
				<PermanentEvaluationsListEmptyState />
			) : (
				<div className={styles.root}>
					{data.evaluations.map(evaluation => (
						<EvaluationCard className={styles.card} key={evaluation._id}>
							<EvaluationCardHeader>
								<EvaluationCardProfileOverview
									name={evaluation.created_by.client_name}
									date={evaluation.created_by.date}
									edited={evaluation.edited}
									avatar={evaluation.created_by.avatar}
								>
									<EvaluationCardCandidateLabel
										containerClassName={styles.evaluationCandidate}
										title={evaluation.job.title}
										onClick={() =>
											historyPush(`/vacancy/${evaluation.job._id}`)
										}
									/>
								</EvaluationCardProfileOverview>
								<EvaluationCardActions>
									{user.id === evaluation.created_by._id && (
										<>
											<EvaluationCardEditButton
												onClick={() => onEditEvaluation(evaluation)}
											/>

											<EvaluationCardDeleteButton
												onClick={() => onDelete(evaluation._id)}
											/>

											<DividerIcon />
										</>
									)}
									<EvaluationCardScoreStatus
										score={evaluation.evaluation_score}
									/>
								</EvaluationCardActions>
							</EvaluationCardHeader>
							<hr className={styles.hr} />
							{evaluation.evaluation_form[0] ? (
								<>
									<EvaluationDetailsCardBody
										form={getFormFirstQuestion(evaluation.evaluation_form)}
										contentClassName={styles.evaluationOverview}
									/>
									<hr className={styles.hr} />
									<div className={styles.btnContainer}>
										<button
											onClick={() =>
												redirectToEvaluationDetails(evaluation._id)
											}
											className={styles.btn}
										>
											<span className={styles.btnTitle}>Show details</span>
										</button>
									</div>
								</>
							) : (
								<QuickEvaluationCardBody note={evaluation.evaluation_note} />
							)}
						</EvaluationCard>
					))}
					{showConfirmDeleteModal && (
						<DeleteEvaluationModal
							onClose={() => setShowConfirmDeleteModal(false)}
							payload={{ id: evaluationToDelete }}
						/>
					)}
					{isOpenTemplateEvaluationDrawer && (
						<ProfileViewEvaluationTemplateFormDrawer />
					)}
					{isOpenEditQuickEvaluation && <ProfileEditQuickEvaluationDrawer />}

					{showDetailsDrawer && (
						<PermanentViewEvaluationDetailsDrawer
							onClose={() => redirectToEvaluationList()}
							onDeleteSuccess={() => redirectToEvaluationList()}
						/>
					)}
				</div>
			)}
		</Card>
	);
};

export default PermanentEvaluationsList;
