import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "rebass";
import _get from "lodash/get";
import LineProgress from "common/progress/LineProgress";
import Avatar from "common/Avatar/index";
import { getLabel } from "config/helpers";
import { AVAILABILITY_STATUS, NO_DATA_PLACEHOLDER, CONSULTANCY } from "config";
import { parsePhoneNumber, excerpt } from "common/Functions";
import { ConsultancyContactInfos, ViewContactInformations } from "../Styled";
import GlobalTooltip from "common/GlobalTooltip";
import ManagerIcon from "../../shared/icons/ManagerIcon";
import { browserHistory } from "react-router";

const goToFreelancerProfile = id => {
	return `/user/profile/${id}?source=list&list=bid&from=${window.location.pathname}`;
};

class BidHeadLine extends Component {
	constructor() {
		super();
		this.state = {
			tracked: false
		};
	}

	render() {
		const {
			bid_id,
			matchingScore,
			accountType,
			userConsultancy,
			companyName,
			showProfile,
			viewContactInfo,
			fetchBid
		} = this.props;
		const first_name = _get(this.props, "profile.first_name", null);
		const last_name = _get(this.props, "profile.last_name", null);
		const avatar = _get(this.props, "profile.avatar", null);
		const seniority = _get(this.props, "profile.seniority", null);
		let _function = _get(this.props, "profile.function");
		_function = _function || {};
		const availability_status = _get(
			this.props,
			"profile.availability_status",
			null
		);
		const isConsultancy = accountType === CONSULTANCY;
		const email = isConsultancy
			? _get(this.props, "userConsultancy.email", null)
			: _get(this.props, "profile.email", null);
		const phone = isConsultancy
			? _get(this.props, "userConsultancy.phone", null)
			: _get(this.props, "profile.phone", null);
		const formatedPhone =
			phone && phone !== NO_DATA_PLACEHOLDER && parsePhoneNumber(phone);

		const unlockProfile = async () => {
			await viewContactInfo({
				id: bid_id,
				type: "contact",
				activity_type: "bid"
			});
			await fetchBid(bid_id);
		};

		const unlockedAndGoToProfile = async () => {
			await viewContactInfo({
				id: bid_id,
				type: "contact",
				activity_type: "bid"
			});
			browserHistory.push(goToFreelancerProfile(bid_id));
		};

		const overlay =
			"The applicant is not freelance but employed by a consultancy.The contact information are the ones of the consultant’s business unit manager who is in charge of arranging interviews and potential contract negotiation.";

		return (
			<div id="bids">
				<Flex
					justifyContent="space-between"
					sx={{
						backgroundColor: "rgb(248, 249, 250)",
						padding: "26px 60px"
					}}
					pb={0}
					pt={0}
				>
					<Flex>
						<Flex
							justifyContent=" center"
							width="115px"
							height="115px"
							mr={38}
							sx={{
								padding: "7px",
								borderRadius: "4px",
								boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
								backgroundColor: "rgb(255, 255, 255)",
								position: "relative",
								top: "32px"
							}}
						>
							<span onClick={unlockedAndGoToProfile} className="clickable">
								<Avatar
									src={avatar}
									size={105}
									name={`${first_name} ${last_name}`}
									radius={0}
								/>
							</span>
						</Flex>
						<Box mt={36}>
							<Text
								as={"h3"}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "16px",
									fontWeight: "500",
									color: "rgb(15, 28, 70)",
									marginBottom: "6px"
								}}
							>
								<span
									onClick={unlockedAndGoToProfile}
									className="clickable"
									style={{ textDecoration: "none", color: "rgb(15,28,70)" }}
								>
									{`${first_name} ${last_name}`}
								</span>
							</Text>
							<Box mb={"8px"}>
								{seniority && (
									<Text as={"span"} className="seniority">
										{seniority}
									</Text>
								)}{" "}
								<Text
									as={"span"}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "14px",
										color: "rgb(15, 28, 70)"
									}}
									className="function"
								>
									{_function.name}
								</Text>
							</Box>
							<Text
								as={"span"}
								ml={0}
								className={`availability_status ${availability_status}`}
							>
								{getLabel(AVAILABILITY_STATUS, availability_status)}
							</Text>
						</Box>
					</Flex>
					<Flex mt={36}>
						{isConsultancy ? (
							<>
								<Box mr={32}>
									<Text
										mb={18}
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "12px",
											fontWeight: "600",
											letterSpacing: "1.08px",
											color: "rgb(160, 165, 185)"
										}}
									>
										PROFILE TYPE
									</Text>
									<Box>
										<span className="bid_details">Consultancy</span>
										<GlobalTooltip maxWidth="440px" overlay={overlay} />
									</Box>
								</Box>
								<Box mr={32}>
									<Text
										mb={18}
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "12px",
											fontWeight: "600",
											letterSpacing: "1.08px",
											color: "rgb(160, 165, 185)"
										}}
									>
										{`MANAGER'S CONTACT DETAILS`}
									</Text>
									<Box>
										<ConsultancyContactInfos>
											<div>
												<ManagerIcon />
												<span>
													{showProfile ? (
														userConsultancy.first_name ? (
															`${userConsultancy.first_name} ${userConsultancy.last_name}`
														) : (
															<span className="placeholder" />
														)
													) : (
														<span className="placeholder" />
													)}
												</span>
											</div>
											<div>
												{/* <CompanyIcon /> */}
												<span className="icon-building" />
												<span>
													{showProfile ? (
														companyName === NO_DATA_PLACEHOLDER ||
														!companyName ? (
															<span className="placeholder" />
														) : (
															companyName
														)
													) : (
														(companyName === NO_DATA_PLACEHOLDER ||
															!companyName) && <span className="placeholder" />
													)}
												</span>
											</div>
											<div>
												<span className="icon-phone-handle" />
												<span>
													{showProfile ? (
														phone === NO_DATA_PLACEHOLDER || !phone ? (
															"-----"
														) : (
															formatedPhone
														)
													) : (
														<span className="placeholder" />
													)}
												</span>
											</div>
											<div style={{ width: "unset" }}>
												<span className="icon-mail" />
												<span>
													{showProfile ? (
														email === NO_DATA_PLACEHOLDER || !email ? (
															<span className="placeholder" />
														) : (
															excerpt(email, 31)
														)
													) : (
														(email === NO_DATA_PLACEHOLDER || !email) && (
															<span className="placeholder" />
														)
													)}
												</span>
											</div>
										</ConsultancyContactInfos>
									</Box>
								</Box>
							</>
						) : (
							<>
								<Box mr={32}>
									<Text
										mb={18}
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "12px",
											fontWeight: "600",
											letterSpacing: "1.08px",
											color: "rgb(160, 165, 185)",
											textTransform: "uppercase"
										}}
									>
										Phone
									</Text>
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "14px",
											color: "rgb(15, 28, 70)"
										}}
									>
										{showProfile ? (
											phone === NO_DATA_PLACEHOLDER || !phone ? (
												"-----"
											) : (
												formatedPhone
											)
										) : (
											<span className="placeholder" />
										)}
									</Text>
								</Box>
								<Box mr={32}>
									<Text
										mb={18}
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "12px",
											fontWeight: "600",
											letterSpacing: "1.08px",
											color: "rgb(160, 165, 185)",
											textTransform: "uppercase"
										}}
									>
										Email
									</Text>
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "14px",
											color: "rgb(15, 28, 70)"
										}}
									>
										{showProfile ? (
											email === NO_DATA_PLACEHOLDER || !email ? (
												<span className="placeholder" />
											) : (
												email
											)
										) : (
											(email === NO_DATA_PLACEHOLDER || !email) && (
												<span className="placeholder" />
											)
										)}
									</Text>
								</Box>
							</>
						)}

						<Box>
							<Text
								mb={0}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "12px",
									fontWeight: "600",
									letterSpacing: "1.08px",
									color: "rgb(160, 165, 185)"
								}}
							>
								MATCHING SCORE
							</Text>
							<Box className="lineProgress">
								<LineProgress
									percentStyle={true}
									percent={matchingScore}
									trailColor={"rgba(15, 200, 85, 0.2)"}
									strokeColor={"#0fc855"}
									width={"132px"}
								/>
							</Box>
						</Box>
					</Flex>
					{showProfile ? (
						<ViewContactInformations style={{ pointerEvents: "none" }}>
							<span className="icon-lockfiled-open" />
						</ViewContactInformations>
					) : (
						<ViewContactInformations locked onClick={unlockProfile}>
							<GlobalTooltip
								placement={"top"}
								noClassName={true}
								overlayClassName={"g-tool-white top center-text"}
								overlay={"View contact informations"}
							>
								<div className="test">
									<span className="icon-lockfiled" />
								</div>
							</GlobalTooltip>
						</ViewContactInformations>
					)}
				</Flex>
			</div>
		);
	}
}

BidHeadLine.propTypes = {
	profile: PropTypes.object.isRequired,
	viewContactInfo: PropTypes.func.isRequired,
	bid_id: PropTypes.string.isRequired,
	matchingScore: PropTypes.number,
	viewsTrack: PropTypes.array
};

BidHeadLine.defaultProps = {
	matchingScore: 0
};

export default BidHeadLine;
