import React from "react";
const SortUpGreen = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="20" height="20" rx="10" fill="#E6F3F2" />
			<path
				d="M10 15.3334V4.66675M10 4.66675L6 8.66675M10 4.66675L14 8.66675"
				stroke="#00857A"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default SortUpGreen;
