import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_EVALUATION_TEMPLATE } from "config/api-endpoints";

const deleteTemplate = body => {
	return client(DELETE_EVALUATION_TEMPLATE, {
		body
	});
};

export default function useDeleteEvaluationTemplate(options = {}) {
	return useMutation(deleteTemplate, {
		retry: 0,
		...options
	});
}
