import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_PROACTIVE_APPLICATIONS_LIST } from "config/api-endpoints";
import { PROACTIVE_APPLICATIONS } from "modules/home/config";
import usePermanentStore from "modules/home/zustand/PermanentStore";

export const GET_PROACTIVE_APPLICATIONS_QUERY_KEY =
	"GET_PROACTIVE_APPLICATIONS_QUERY_KEY";

const getProactiveApplicationsList = body => {
	return client(GET_PROACTIVE_APPLICATIONS_LIST, {
		body
	});
};

const generatePayload = (startData, endDate) => {
	return {
		tag: "proactive_applications",
		start_date: startData && startData.isValid() ? startData.unix() : undefined,
		end_date: endDate && endDate.isValid() ? endDate.unix() : undefined,
		offset: 0
	};
};

const useGetProactiveApplicationsList = (options = {}) => {
	const {
		permanentType,
		permanentStartDate,
		permanentEndDate
	} = usePermanentStore();

	const isTabActive = permanentType === PROACTIVE_APPLICATIONS;
	let payload = isTabActive
		? generatePayload(permanentStartDate, permanentEndDate)
		: null;

	const res = useQuery(
		[GET_PROACTIVE_APPLICATIONS_QUERY_KEY, payload],
		() => getProactiveApplicationsList(payload),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			staleTime: Infinity,
			enabled: isTabActive,
			...options
		}
	);

	return res;
};

export default useGetProactiveApplicationsList;
