import React from "react";
import PropTypes from "prop-types";
import Modal from "./index";
import {
	ConfirmationTitle,
	ConfirmationP,
	ContentHolder,
	WarningText,
	WarningIcon
} from "./ModalStyled";

export const ConfirmationTypes = {
	error: "error",
	warning: "warning",
	confirmation: "confirmation"
};

const ConfirmationModal = ({
	onClose,
	firstButton,
	secondButton,
	title,
	content,
	warningText,
	type,
	loading,
	active,
	modalName,
	doNotShowAgain,
	doNotShowComponent
}) => {
	return (
		<Modal
			active={active}
			fixed={true}
			onClose={onClose}
			loading={loading}
			isConfirmationModal={true}
			firstButton={{
				label: "Yes",
				...firstButton
			}}
			secondButton={{
				label: "No",
				action: onClose,
				...secondButton
			}}
			actionsClassName="actions"
			modalName={modalName}
			buttonCloseClassName={"modalStyleButton"}
			className={modalName}
		>
			<ContentHolder type={type}>
				{title && <ConfirmationTitle>{title}</ConfirmationTitle>}
				{warningText && (
					<WarningText>
						<WarningIcon className="icon-warning" />
						<span>{warningText}</span>
					</WarningText>
				)}
				{content && <ConfirmationP>{content}</ConfirmationP>}
			</ContentHolder>
			{doNotShowAgain && doNotShowComponent()}
		</Modal>
	);
};

ConfirmationModal.defaultProps = {
	type: ConfirmationTypes.confirmation
};

ConfirmationModal.propTypes = {
	firstButton: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClose: PropTypes.func
};

export default ConfirmationModal;
