import immutable from "seamless-immutable";
import * as types from "../actions/actionTypes";
import {
	SYSTEM_NOTIFICATIONS,
	MISSION_NOTIFICATIONS,
	SYSTEM,
	LATEST,
	MAX_REALTIME_NOTIFICATIONS
} from "config";

const initialState = immutable({
	data: {},
	latestNotifications: {},
	isFetching: true,
	view: LATEST,
	dropdown: false,
	notificationsCount: 0,
	realtime: [],
	show_notification_in_real_time: false,
	receive_all_emails: false,
	receive_matching_jobs_emails: false,
	receive_matching_jobs_emails_period: "",
	inAction: false
});

export default function(state = initialState, action) {
	let notifications = [];
	let realtime = [];
	switch (action.type) {
		case types.WRITE_NOTIFICATIONS:
			notifications = action.data;
			return immutable.merge(state, {
				data: notifications,
				isFetching: false
			});
		case types.WRITE_LATEST_NOTIFICATIONS:
			return immutable.merge(state, {
				latestNotifications: action.notifications,
				isFetching: false
			});
		case types.WRITE_NOTIFICATION:
			const { notification } = action;
			let found = false;
			realtime = JSON.parse(JSON.stringify(state.realtime));
			notifications = JSON.parse(JSON.stringify(state.data));
			if (realtime.length >= MAX_REALTIME_NOTIFICATIONS) {
				realtime[MAX_REALTIME_NOTIFICATIONS - 1] = notification;
			} else realtime.push(notification);
			if (notification.message.type === SYSTEM) {
				notifications[SYSTEM_NOTIFICATIONS].push(notification);
			} else {
				for (const mission of notifications[MISSION_NOTIFICATIONS].missions) {
					if (notification.job_id === mission._id) {
						mission.notifications.push(notification);
						found = true;
						break;
					}
				}
				if (!found && notification.job_id) {
					notifications[MISSION_NOTIFICATIONS].missions.push({
						_id: notification.job_id,
						title: notification.job_title,
						notifications: [notification]
					});
				}
			}
			return immutable.merge(state, { realtime, data: notifications });
		case types.TOGGLE_NOTIFICATIONS_DROPDOWN:
			return immutable.merge(state, { dropdown: !state.dropdown });
		case types.SWITCH_VIEW:
			return immutable.merge(state, { view: action.view });
		case types.CLEAR_NOTIFICATIONS:
			return immutable.merge(state, { realtime: [] });
		case types.REMOVE_NOTIFICATION:
			realtime = JSON.parse(JSON.stringify(state.realtime));
			const filteredData = realtime.filter(
				n => n._id !== action.notification._id
			);

			return immutable.merge(state, { realtime: filteredData });
		case types.WINDOW_FOCUSED:
			return immutable.merge(state, { windowFocused: true });
		case types.WINDOW_BLURRED:
			return immutable.merge(state, { windowFocused: false });
		case types.WRITE_NOTIFICATION_COUNT:
			return immutable.merge(state, { notificationsCount: action.count });
		case types.WRITE_NOTIFICATION_SETTINGS:
			return immutable.merge(state, {
				show_notification_in_real_time: action.show_notification_in_real_time,
				receive_all_emails: action.receive_all_emails,
				receive_matching_jobs_emails: action.receive_matching_jobs_emails,
				receive_matching_jobs_emails_period:
					action.receive_matching_jobs_emails_period
			});
		case types.TOGGLE_IN_ACTION:
			return immutable.merge(state, {
				inAction: !state.inAction
			});
		default:
			return state;
	}
}
