import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import TimeChooser from "common/TimeChooser";
import {
	IN_DEF_END_TIME,
	IN_DEF_START_TIME,
	TEXTAREA_MAX_LENGTH,
	TIMEZONE,
	VIDEO_CONFERENCE
} from "config";
import FormField from "common/FormField";
import GlobalTooltip from "common/GlobalTooltip";
import CheckBoxInput from "common/CheckBoxInput";
import Modal from "common/modal/Modal";
import videoIcon from "static/icons/videocall.svg";

class FreelancerInterview extends Component {
	state = {
		start_time: IN_DEF_START_TIME,
		end_time: IN_DEF_END_TIME,
		valid: true,
		loading: false
	};

	closeModal = e => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.props.toggleInterviewModal();
	};

	handlerStartTime = (value, valid) => {
		this.setState({ start_time: value, valid });
	};

	handlerEndTime = (value, valid) => {
		this.setState({ end_time: value, valid });
	};

	getCompanyAddress = () => {
		const { interview } = this.props;
		return `${interview.street} ${interview.number}, ${interview.box} ${interview.zip} ${interview.city} ${interview.country}`;
	};

	formSubmitted = formProps => {
		const { interviewDispatch, interview, bid_id } = this.props;
		const { end_time, start_time, valid } = this.state;
		if (!valid) return;

		const startDate = window.moment(formProps.start_date.clone()).tz(TIMEZONE);
		const endDate = window.moment(formProps.start_date.clone()).tz(TIMEZONE);

		const [startTimeHour, startTimeMinute] = start_time.split(":");
		const new_interview_start_date = startDate
			.set({
				hour: startTimeHour,
				minute: startTimeMinute
			})
			.utc()
			.unix();

		const [endTimeHour, endTimeMinute] = end_time.split(":");
		const new_interview_end_date = endDate
			.set({
				hour: endTimeHour,
				minute: endTimeMinute
			})
			.utc()
			.unix();

		const payload = {
			description: formProps.description,
			new_interview_start_date,
			new_interview_end_date,
			bid_id: bid_id,
			interview_id: interview._id
		};

		this.setState({ loading: true });
		interviewDispatch(payload, true)
			.then(() => {
				this.closeModal();
			})
			.catch(() => {
				this.setState({ loading: false });
			});
	};

	render() {
		const { handleSubmit, profile, interview } = this.props;
		const { loading } = this.state;
		return (
			<Modal
				loading={loading}
				title="Propose Another Interview Date"
				onClose={this.closeModal}
				className="interview-modal freelancer-interview"
				fixed={false}
				firstButton={{
					label: "PROPOSE AN ALTERNATIVE DATE",
					type: "primary",
					action: handleSubmit(this.formSubmitted)
				}}
			>
				<div className="content">
					<form onSubmit={handleSubmit(this.formSubmitted)}>
						<div className="profile client_profile">
							<div>
								Arrange Interview with <span>{profile}</span>
							</div>
						</div>

						<TimeChooser
							handlerStartTime={this.handlerStartTime}
							handlerEndTime={this.handlerEndTime}
							label="Interview Date"
							dateClass="col-md-5"
							timeStartClass="col-md-3"
							timeEndClass="col-md-3"
						/>

						<div className="row desc-block">
							<div className="col-md-11">
								<Field
									name="description"
									label="Description"
									type="textarea"
									className="col-md-11"
									component={FormField}
									maxLength={TEXTAREA_MAX_LENGTH}
									placeholder={
										"You may use this space to provide the client with any needed information regarding why you would like to schedule your interview for your proposed alternative date."
									}
								/>
							</div>
						</div>

						<div className="row">
							<div className="col-md-11">
								<div className="radio-group location-selector">
									<div className="form-group inner-icon right-icon">
										<label htmlFor="location_type">
											Location{" "}
											<GlobalTooltip
												maxWidth="265px"
												overlay={
													"The interview location is chosen by the client. If the location doesn’t suit you, simply mention it in the “DESCRIPTION” box above."
												}
											/>
										</label>
										{interview.location_type === VIDEO_CONFERENCE ? (
											<div className="video-conf">
												<img src={videoIcon} alt="camera" className="camera" />
												<div>
													<div className="text">Video Conference</div>
													<div>Virtual Meeting Room</div>
												</div>
											</div>
										) : (
											<div className="input-box">
												<i className="fa fa-map-marker" />
												<input
													className="form-control"
													value={this.getCompanyAddress()}
													readOnly
												/>
											</div>
										)}
									</div>
								</div>
								<div className="alt-date-checker">
									<span>
										As soon as you submit your alternative proposed date, your
										contact information will be revealed to the client so that
										they may be able to reach you directly.
									</span>
									<Field
										name="alt_date_checker"
										type="checkbox"
										component={CheckBoxInput}
									>
										<span>I understand and agree to this.</span>
									</Field>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.start_date) {
		errors.start_date = "Time period can't be empty.";
	}

	if (
		formProps.description &&
		formProps.description.length > TEXTAREA_MAX_LENGTH
	) {
		errors.description = `This field should not exceed ${TEXTAREA_MAX_LENGTH} characters`;
	}

	if (!formProps.description || formProps.description.trim() === "") {
		errors.description = "Description can't be empty.";
	}

	if (!formProps.alt_date_checker) {
		errors.alt_date_checker =
			"You must agree to the fact that the client will be able to see your contact information.";
	}

	return errors;
};

FreelancerInterview.propTypes = {
	interviewDispatch: PropTypes.func.isRequired,
	profile: PropTypes.string.isRequired,
	bid_id: PropTypes.string.isRequired,
	interview: PropTypes.object.isRequired,
	toggleInterviewModal: PropTypes.func.isRequired
};

export default reduxForm({
	form: "interviewNewProposal",
	validate,
	touchOnBlur: false
})(FreelancerInterview);
