import { useMemo } from "react";
import { withRouter } from "react-router";
import useListPagination from "hooks/useListPagination";
import { searchParser } from "config/helpers";
import { getPermanentProfileRoute } from "modules/candidate/Profile";

function VacanyNavigationHOC({
	render,
	location: { search },
	router,
	onPreviousClick,
	onNextClick
}) {
	const {
		params: { id: profile_id }
	} = router;

	const { payload } = searchParser();
	const { candidateIds: ids } = JSON.parse(payload);

	const {
		state: { previousDisabled, nextDisabled },
		nextId,
		previousId
	} = useListPagination({
		data: ids,
		id: profile_id
	});

	const currentIndex = ids.indexOf(profile_id);

	const nextProfileID = useMemo(
		() =>
			ids.indexOf(profile_id) !== ids.length - 1
				? ids[currentIndex + 1]
				: ids[currentIndex - 1],
		[currentIndex, ids, profile_id]
	);

	const goToProfile = id => {
		router.replace(getPermanentProfileRoute(id) + search);
	};

	const handleNext = () => {
		goToProfile(ids[currentIndex + 1]);
		nextId();
		onNextClick?.();
	};

	const handlePrevious = () => {
		goToProfile(ids[currentIndex - 1]);
		previousId();
		onPreviousClick?.();
	};

	return render({
		previousProfile: handlePrevious,
		nextProfile: handleNext,
		nextProfileID,
		isNextDisabled: nextDisabled,
		isPreviousDisabled: previousDisabled,
		goToProfile
	});
}

// TODO I think we need to create hook instead HOC
export default withRouter(VacanyNavigationHOC);
