import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

const GET_USER_OFFICES_QUERY = "GET_USER_OFFICES_QUERY";

const getUserOffices = () => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "list_offices"
		}
	});
};

export const useGetOffices = options => {
	const res = useQuery([GET_USER_OFFICES_QUERY], getUserOffices, {
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		...options
	});

	return res;
};
