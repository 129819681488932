import React from "react";

const CustomIcon = () => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="40" height="40" rx="20" fill="#F8FAFC" />
			<path
				d="M17.395 27.3711L17.9795 28.6856C18.1532 29.0768 18.4368 29.4093 18.7957 29.6426C19.1547 29.8759 19.5736 30.0001 20.0017 30C20.4298 30.0001 20.8488 29.8759 21.2077 29.6426C21.5667 29.4093 21.8502 29.0768 22.0239 28.6856L22.6084 27.3711C22.8164 26.9047 23.1664 26.5159 23.6084 26.26C24.0532 26.0034 24.5677 25.8941 25.0784 25.9478L26.5084 26.1C26.934 26.145 27.3636 26.0656 27.7451 25.8713C28.1265 25.6771 28.4434 25.3763 28.6573 25.0056C28.8714 24.635 28.9735 24.2103 28.951 23.7829C28.9285 23.3555 28.7825 22.9438 28.5306 22.5978L27.6839 21.4344C27.3825 21.0171 27.2214 20.5148 27.2239 20C27.2238 19.4866 27.3864 18.9864 27.6884 18.5711L28.535 17.4078C28.7869 17.0618 28.933 16.6501 28.9554 16.2227C28.9779 15.7953 28.8759 15.3705 28.6617 15C28.4478 14.6292 28.1309 14.3285 27.7495 14.1342C27.3681 13.94 26.9385 13.8605 26.5128 13.9056L25.0828 14.0578C24.5722 14.1114 24.0576 14.0021 23.6128 13.7456C23.1699 13.4883 22.8199 13.0974 22.6128 12.6289L22.0239 11.3144C21.8502 10.9232 21.5667 10.5907 21.2077 10.3574C20.8488 10.1241 20.4298 9.99993 20.0017 10C19.5736 9.99993 19.1547 10.1241 18.7957 10.3574C18.4368 10.5907 18.1532 10.9232 17.9795 11.3144L17.395 12.6289C17.188 13.0974 16.8379 13.4883 16.395 13.7456C15.9503 14.0021 15.4357 14.1114 14.925 14.0578L13.4906 13.9056C13.0649 13.8605 12.6353 13.94 12.2539 14.1342C11.8725 14.3285 11.5556 14.6292 11.3417 15C11.1275 15.3705 11.0255 15.7953 11.048 16.2227C11.0705 16.6501 11.2165 17.0618 11.4684 17.4078L12.315 18.5711C12.617 18.9864 12.7796 19.4866 12.7795 20C12.7796 20.5134 12.617 21.0137 12.315 21.4289L11.4684 22.5922C11.2165 22.9382 11.0705 23.3499 11.048 23.7773C11.0255 24.2047 11.1275 24.6295 11.3417 25C11.5558 25.3706 11.8727 25.6712 12.2541 25.8654C12.6355 26.0596 13.065 26.1392 13.4906 26.0944L14.9206 25.9422C15.4313 25.8886 15.9458 25.9979 16.3906 26.2544C16.8351 26.511 17.1868 26.902 17.395 27.3711Z"
				stroke="#334155"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.9999 23C21.6568 23 22.9999 21.6569 22.9999 20C22.9999 18.3431 21.6568 17 19.9999 17C18.3431 17 16.9999 18.3431 16.9999 20C16.9999 21.6569 18.3431 23 19.9999 23Z"
				stroke="#334155"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CustomIcon;
