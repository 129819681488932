import React from "react";
import ConfirmationModal, {
	Header,
	Description,
	Footer
} from "common/ConfirmationModal";

const MessagingToolNewEmailWarningModal = ({
	onClose,
	description,
	headerTitle,
	isLoading,
	showIcon,
	children
}) => {
	return (
		<ConfirmationModal width="567px" onClose={onClose} isLoading={isLoading}>
			<Header title={headerTitle} />
			<Description showIcon={showIcon}>
				<div>{description}</div>
			</Description>
			<Footer onClose={onClose}>{children}</Footer>
		</ConfirmationModal>
	);
};

export default MessagingToolNewEmailWarningModal;
