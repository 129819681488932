import React, { Fragment, useState, useEffect, useRef } from "react";
import _map from "lodash/map";
import { Flex } from "rebass/styled-components";
import { TiersDetails } from "../Styled";

const CategoryTiering = ({ data, register }) => {
	const filled = useRef(false);
	const [tiers, setTiers] = useState([]);

	useEffect(() => {
		if (data) {
			if (data?.tiers) {
				setTiers(
					_map(data.tiers, t => {
						if (t.name !== "marketplace") {
							return t;
						}
					}).filter(Boolean)
				);
			}
			filled.current = true;
		}
	}, [data]);

	return (
		<TiersDetails>
			{tiers.map(
				tier =>
					tier.name !== "blocked" && (
						<Fragment key={tier.name}>
							<ul>
								<li className="title">
									<h3>{tier.name}</h3>
								</li>
								<li>
									<Flex
										justifyContent={"space-between"}
										alignItems={"center"}
										flex={1}
									>
										<span>Visibility from day </span>
										<input
											type="number"
											{...register(`tiers[${tier._id}].visibility_from_day`)}
											defaultValue={tier.settings.visibility_from_day}
											min={0}
										/>
									</Flex>
								</li>
								<li>
									<Flex
										justifyContent={"space-between"}
										alignItems={"center"}
										flex={1}
									>
										<span>Can submit until day </span>
										<input
											type="number"
											{...register(`tiers[${tier._id}].can_submit_till_day`)}
											defaultValue={tier.settings.can_submit_till_day}
											min={0}
										/>
									</Flex>
								</li>
								<li>
									<Flex
										justifyContent={"space-between"}
										alignItems={"center"}
										flex={1}
									>
										<span># of submission per request </span>
										<input
											type="number"
											{...register(
												`tiers[${tier._id}].number_of_submission_per_request`
											)}
											defaultValue={
												tier.settings.number_of_submission_per_request
											}
											min={0}
										/>
									</Flex>
								</li>
							</ul>
						</Fragment>
					)
			)}
		</TiersDetails>
	);
};

export default CategoryTiering;
