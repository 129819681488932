import { createSelector } from "reselect";
import _get from "lodash/get";
import _map from "lodash/map";
import { notSelectedElements } from "modules/job/selectors";
import { languagesSelector } from "modules/app/selectors";

const getCv = state => _get(state, "cv", {});
const getCvHeadline = state => _get(state, "cv.headline", {});
const getConsultancyFunction = state => _get(state, "cv.functions.0", {});
const getCvAddress = state => _get(state, "cv.preference.address", {});
const getCvSuggestedSkills = state => _get(state, "cv.suggestedSkills", []);
const getCvSkills = state => _get(state, "cv.skills", []);
const getSector = state =>
	_get(state, "cv.headline.sector.parent_sector", null);
const getSaving = state => _get(state, "cv.saving", false);
const headLineFunction = state => _get(state, "cv.headline.function", null);

const formatForSelect = (skills = []) =>
	_map(skills, skill => ({ _id: skill._id, name: skill.name, score: 0 }));

const getIsProcessingFile = state => _get(state, "cv.isProcessingFile");

const getCvDocs = state => _get(state, "cv.cv_docs", {});

export const cvDocsSelector = createSelector(getCvDocs, cv_docs => cv_docs);

export const cvHeadlineSelector = createSelector(
	getCvHeadline,
	headline => headline
);
export const consultancyFunctionSelector = createSelector(
	getConsultancyFunction,
	data => data
);

export const cvAddressSelector = createSelector(
	getCvAddress,
	address => address
);

export const getSelectedLanguages = state =>
	_get(state, "cv.editLanguages", []);

export const getSelectedSkills = state => _get(state, "cv.editSkills", []);

export const languagesListSelector = createSelector(
	languagesSelector,
	getSelectedLanguages,
	(languages, selected) =>
		notSelectedElements(languages, selected, "name", "label")
);

export const suggestedSkillsSelector = createSelector(
	getCvSuggestedSkills,
	skills => formatForSelect(skills)
);

export const skillsSelector = createSelector(
	getSelectedSkills,
	skills => skills
);
export const initialSkills = createSelector(getCvSkills, skills => skills);
export const sectorSelector = createSelector(getSector, sector => sector);
export const headLineFunctionSelector = createSelector(
	headLineFunction,
	hFunction => hFunction
);
export const savingSelector = createSelector(getSaving, saving => saving);
export const cvSelector = createSelector(getCv, cv => cv);

export const processingFileSelector = createSelector(
	getIsProcessingFile,
	data => data
);
