import React, { useState } from "react";
import { ReactComponent as StarIcon } from "static/icons/star-and-plus.svg";
import { ReactComponent as ChevronDown } from "static/icons/chevron-down-16px.svg";
import s from "./ai-tools-list.module.scss";
import { ReactComponent as ChevronRight } from "static/icons/chevron-right.svg";
import {
	aiToolsOptions,
	PLACEHOLDER_AI_TOOLS_COMMAND
} from "common/Editor/utils/constants";

const AIToolsList = ({ editor }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [activeItem, setActiveItem] = useState(null);

	return (
		<div className={s.root}>
			<button
				className={s.aiToolsButton}
				onClick={() => {
					setShowMenu(prev => !prev);
				}}
			>
				<StarIcon width={20} height={20} />
				<span>AI tools</span>
				<ChevronDown />
			</button>

			{showMenu && (
				<div className={s.aiOptions}>
					<div className={s.aiOptionsMenu}>
						{aiToolsOptions.map(item => {
							return (
								<button
									key={item.id}
									className={s.aiOptionsMenuItemContainer}
									onClick={() => {
										if (item.children) {
											setActiveItem(activeItem === item.id ? null : item.id);
										} else {
											editor.dispatchCommand(
												PLACEHOLDER_AI_TOOLS_COMMAND,
												item
											);
										}
									}}
								>
									<div className={s.aiOptionsMenuLabel}>
										{item.icon}
										<span>{item.label}</span>
									</div>
									{item.children && <ChevronRight />}
								</button>
							);
						})}
					</div>

					{activeItem && (
						<div className={s.aiOptionsMenu}>
							{aiToolsOptions
								.find(item => item.id === activeItem)
								.children.map(child => (
									<button
										key={child.name}
										className={s.aiOptionsMenuItemContainer}
										onClick={() =>
											editor.dispatchCommand(
												PLACEHOLDER_AI_TOOLS_COMMAND,
												child
											)
										}
									>
										<span>{child.label}</span>
									</button>
								))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default AIToolsList;
