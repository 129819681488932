/* eslint-disable react/display-name */
import React from "react";
import { historyPush } from "config/helpers";
import { getNewVacancyProfileRoute } from "modules/vacancy/create-vacancy";
import { stringify } from "query-string";
import Modal from "common/modal";
import styles from "./resend-invite.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/closeModal.svg";

export default ({
	onClose,
	onBack: onBackProp,
	isEdit,
	vacancyId,
	createdDepartment
}) => {
	const onBack = () => {
		onBackProp();
		setTimeout(() => {
			const params = stringify({
				departmentName: createdDepartment.name,
				departmentId: createdDepartment._id
			});

			if (vacancyId === "creation") {
				historyPush(`/vacancy/create/profile?${params}`);
			} else {
				historyPush(`${getNewVacancyProfileRoute(vacancyId)}?${params}`);
			}
		}, 500);
	};

	return (
		<Modal
			size={"575px"}
			padding={"0px"}
			borderRadius={"12px"}
			buttonCloseClassName={"hide"}
			style={{
				background: "#FFF",
				boxShadow:
					"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
				overflow: "hidden"
			}}
		>
			<div className={styles.modalContainer}>
				<div className={styles.headerModal}>
					<h3>Department creation </h3>
					<button onClick={() => onClose()}>
						<CloseIcon />
					</button>
				</div>
				<div className={styles.contenuModal}>
					<h3 className={styles.title}>
						{" "}
						The department has been successfully {isEdit ? "updated" : "added"}
					</h3>
					<p className={styles.text}>
						If applicable, an email has been sent to the users whose role has
						been impacted by this action.
					</p>
				</div>
				<div className={styles.footer}>
					<button className={styles.cancelBtn} onClick={() => onClose()}>
						Close window
					</button>
					<button className={styles.submitBtn} onClick={() => onBack()}>
						<span>Go back to your vacancy</span>
					</button>
				</div>
			</div>
		</Modal>
	);
};
