import React from "react";
import styles from "./questionnaire-form-file-uploaded.module.scss";
import { ReactComponent as DownloadIcon } from "static/icons/download.svg";
import { QuestionnaireFormLabel } from "../QuestionnaireFormLabel";
import QuestionnaireFormEmptyStateFileUploaded from "./QuestionnaireFormEmptyStateFileUploaded";
import { uploadedFileIcon } from "common/QuestionnaireForm/utils/helpers";

export default function QuestionnaireFormFileUploaded({
	description,
	value = [],
	is_required: isRequired,
	className
}) {
	const onDownload = file => {
		const link = document.createElement("a");
		link.href = file;
		link.download = file.split("/").pop();
		link.click();
	};

	return (
		<div className={styles.rootContainer}>
			<QuestionnaireFormLabel
				isRequired={isRequired}
				description={description}
				className={className}
			/>
			{value?.length > 0 ? (
				<div className={styles.gridContainer}>
					{value.map((file, index) => (
						<div key={index} className={styles.fileUploaderContainer}>
							<div className={styles.container}>
								<div className={styles.fileIcon}>
									{uploadedFileIcon(file.file_name)}
								</div>
								<div className={styles.fileNameContentContainer}>
									<div className={styles.fileName}>{file.file_name}</div>
								</div>
							</div>
							<button
								onClick={() => onDownload(file.path)}
								className={styles.downloadIcon}
							>
								<DownloadIcon />
							</button>
						</div>
					))}
				</div>
			) : (
				<QuestionnaireFormEmptyStateFileUploaded />
			)}
		</div>
	);
}
