import React from "react";
import styles from "./search-candidate-smart-query-recap.module.scss";
import { ReactComponent as SaveIcon } from "static/icons/bookmark-icon-filter.svg";
import { ReactComponent as EditIcon } from "static/icons/edit-filter.svg";
import { ReactComponent as DeleteIcon } from "static/icons/trash-01.svg";
import SmartQueryFilterRecap from "common/SmartQueryFilterRecap";
import { isEmpty } from "common/SmartFilterDrawer/utils/render_utils";
import loadable from "loadable-components";
import {
	CLEAR_MODAL,
	DELETE_MODAL,
	SAVE_MODAL
} from "modules/SearchCandidate/utils/constants";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import useAdvancedFilterDrawerTabs from "common/SmartFilterDrawer/hooks/useAdvancedFilterDrawerTabs";
import {
	SMART_QUERY_CARD,
	TABS_IDS
} from "common/SmartFilterDrawer/utils/constant";
import useNewFilterDrawerCard from "common/SmartFilterDrawer/hooks/useNewFilterDrawerCard";
import { ReactComponent as EraserIcon } from "static/icons/eraser.svg";

const SaveModal = loadable(() =>
	import("../SmartQuerySaveTemplateModal/SmartQuerySaveTemplateModal")
);
const ClearModal = loadable(() => import("../ClearModal/ClearModal"));
const DeleteModal = loadable(() =>
	import(
		"modules/SearchCandidate/components/CommunityCriteria/DeleteModal/DeleteModal"
	)
);
const SearchCandidateSmartQueryRecap = ({
	smartQuerySchema,
	selectedCriteria
}) => {
	const [modal, setModal] = React.useState("");
	const { setShowDrawer, clearSmartQuery } = useFilters();
	const { setActiveTab } = useAdvancedFilterDrawerTabs();
	const { setActiveCard } = useNewFilterDrawerCard();

	const onClear = () => {
		clearSmartQuery();
	};

	const onClose = () => {
		setModal("");
	};

	const onEditSmartQuery = () => {
		setActiveTab(TABS_IDS.SMART_QUERY);
		setActiveCard(SMART_QUERY_CARD);
		setShowDrawer(true);
	};

	return (
		<div className={styles.recapContainer}>
			<div className={styles.querySchemaRecap}>
				{smartQuerySchema.map(item =>
					!isEmpty(item.value) ? (
						<SmartQueryFilterRecap key={item.id} filter={item} />
					) : null
				)}
			</div>
			<div className={styles.actions}>
				<hr className={styles.divider} />
				{!selectedCriteria?._id ? (
					<button className={styles.cta} onClick={() => setModal(SAVE_MODAL)}>
						<SaveIcon className={styles.iconCta} />
					</button>
				) : null}
				<button className={styles.cta} onClick={onEditSmartQuery}>
					<EditIcon className={styles.iconCta} />
				</button>
				{selectedCriteria?._id ? (
					<button className={styles.cta} onClick={() => setModal(DELETE_MODAL)}>
						<DeleteIcon />
					</button>
				) : null}
				<button className={styles.cta} onClick={() => setModal(CLEAR_MODAL)}>
					<EraserIcon className={styles.iconCta} />
				</button>
			</div>
			{modal === SAVE_MODAL && (
				<SaveModal filters={smartQuerySchema} onClose={onClose} />
			)}
			{modal === CLEAR_MODAL && (
				<ClearModal onClose={onClose} clearFunction={onClear} />
			)}
			{modal === DELETE_MODAL && (
				<DeleteModal criteria={selectedCriteria} onClose={onClose} />
			)}
		</div>
	);
};

export default SearchCandidateSmartQueryRecap;
