import { useEffect } from "react";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

const useSetRequestDetailsBreadCrumbs = title => {
	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([
			{ name: "Temporary" },
			{ name: "Request", path: "/jobs/list" },
			{
				name: title || "---"
			}
		]);
	}, [title]);
};

export default useSetRequestDetailsBreadCrumbs;
