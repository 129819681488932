import React from "react";
import { useToggle } from "react-use";
import { Checkbox, Label } from "@rebass/forms/styled-components";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import FilterCard from "../../../shared/FilterCard";
import s from "./geographical-coverage.module.scss";
import GeographicalCoverageFilterDialog from "./GeographicalCoverageFilterDialog";
import Portal from "../../../../../common/Portal";

function GeographicalCoverageFilter({ store }) {
	const [on, toggle] = useToggle(false);
	const {
		location,
		physical_presence,
		setLocation,
		setPhysicalPresence
	} = store(state => ({
		location: state.location,
		physical_presence: state.physical_presence,
		setLocation: state.setLocation,
		setPhysicalPresence: state.setPhysicalPresence
	}));

	return (
		<>
			<FilterCard
				name={"Geographical Coverage"}
				onEdit={toggle}
				onRemove={() => setLocation(undefined)}
				value={location}
			>
				<div className={s.content}>
					<span>
						<LocationMarkerIcon width={18} height={18} />{" "}
						{location?.city ? location?.city + ", " : ""}
						{location?.country}
					</span>
					<Label>
						<Checkbox
							name={"physical_presence"}
							checked={physical_presence}
							onChange={e => setPhysicalPresence(e.target.checked)}
						/>
						Physical presence only
					</Label>
				</div>
			</FilterCard>
			<Portal>
				<GeographicalCoverageFilterDialog
					isOpen={on}
					onClose={() => toggle(false)}
					store={store}
				/>
			</Portal>
		</>
	);
}

export default GeographicalCoverageFilter;
