import React, { useMemo } from "react";
import { EUR, EUR_CURRENCY, CURRENCY_LOCALE } from "../config";
import { useGetCurrenciesList } from "api/useGetCurrenciesList";

export function CurrencyFormatter({
	value,
	minimumFractionDigits = 2,
	locale = CURRENCY_LOCALE,
	currencySymbol = EUR
}) {
	return (
		new Intl.NumberFormat(locale, {
			style: "decimal",
			minimumFractionDigits
		}).format(value) + ` ${currencySymbol}`
	);
}

function CurrencyFormatterRender({
	className = "",
	suffix = "",
	value,
	currency = EUR_CURRENCY,
	minimumFractionDigits,
	token,
	locale,
	isCent = false
}) {
	const { data } = useGetCurrenciesList(token, {
		refetchOnWindowFocus: false,
		staleTime: Infinity
	});

	const currenciesList = Array.isArray(data) ? data : [];

	const currencySymbol = useMemo(() => {
		return currenciesList.find(c => c.code === currency)?.symbol;
	}, [currenciesList, currency]);

	const formattedValue = CurrencyFormatter({
		value: isCent ? value / 100 : value,
		currency,
		minimumFractionDigits,
		locale,
		currencySymbol
	});

	return (
		<span className={className} style={{ whiteSpace: "nowrap" }}>
			{formattedValue}
			{suffix}
		</span>
	);
}

export default CurrencyFormatterRender;
