import React from "react";
import Select from "react-select";
//import "react-select/dist/react-select.css";
import { XIcon } from "@heroicons/react/solid";
import cx from "classnames";
import { useEvent } from "react-use";
import s from "./select.module.scss";
import "./select.scss";

const Option = props => {
	const handleMouseEnter = () => {
		props.onFocus();
	};

	const handleMouseDown = event => {
		event.preventDefault();
		event.stopPropagation();
		props.onSelect(props.option, event);
	};

	useEvent("mouseenter", handleMouseEnter);

	return (
		<div className={cx(s.newSelectOption)} onMouseDown={handleMouseDown}>
			<span>{props.option.label}</span>
		</div>
	);
};

const Value = props => {
	const isMulti = Boolean(props.onRemove);
	return (
		<div className={cx(s.newSelectValue, { [s.multiValue]: isMulti })}>
			<span>{props.value.label}</span>
			{isMulti ? (
				<button type={"button"} onClick={() => props.onRemove(props.value)}>
					<XIcon height={16} width={16} />
				</button>
			) : null}
		</div>
	);
};

function CustomSelect({ value, onChange, ...rest }) {
	const Component = rest.async ? Select.Async : Select;
	return (
		<Component
			multi={true}
			value={value}
			onChange={onChange}
			className={cx(s.newSelect, "custom_new_select")}
			optionComponent={Option}
			valueComponent={Value}
			{...rest}
		/>
	);
}

export default CustomSelect;
