import React, { useEffect, useState } from "react";
import { useGetChannels } from "modules/publishRequest/hooks/useGetChannels";
import styles from "./channels-list.module.scss";
import ChannelCard from "../ChannelCard";
import CardSkeleton from "../ChannelCard/CardSkeleton";
import Pagination from "common/Pagination";
import usePublishRequestStore from "modules/publishRequest/hooks/usePublishRequestStore";

export default function ChannelsList({ jobId, siteId }) {
	const [offset, setOffset] = useState(0);
	const { data, isLoading, isFetching } = useGetChannels({ offset, jobId });
	const { toggleSelectChannel } = usePublishRequestStore();

	useEffect(() => {
		if (siteId && data?.data?.length) {
			const channelSelectedByDefault = data.data.find(
				c => c.site_id === siteId
			);
			if (channelSelectedByDefault) {
				toggleSelectChannel(channelSelectedByDefault);
			}
		}
	}, [siteId, data]);

	const handlePageClick = page => {
		setOffset(page * data.pagination.limit);
	};

	return (
		<div>
			<div className={styles.title}>All channels</div>
			<div className={styles.list}>
				{isLoading || isFetching ? (
					<SkeletonList />
				) : (
					data.data.map(channel => (
						<div key={channel.product_id} className={styles.itemWrapper}>
							<ChannelCard channel={channel} />
						</div>
					))
				)}
			</div>
			{!isLoading && data?.pagination?.total > data?.pagination?.limit && (
				<div className={styles.paginationContainer}>
					<Pagination
						total={data.pagination.total}
						size={data.pagination.limit}
						offset={offset}
						handlePageClick={handlePageClick}
					/>
				</div>
			)}
		</div>
	);
}

function SkeletonList() {
	return Array.from(Array(12)).map((_, index) => (
		<div key={index} className={styles.itemWrapper}>
			<CardSkeleton />
		</div>
	));
}
