import React, { forwardRef } from "react";
import cx from "classnames";
import styles from "./create-vacancy-card.module.scss";

const CreateVacancyCard = ({ className, children, id, ...rest }, ref) => (
	<div
		data-onboarding-step={rest["data-onboarding-step"]}
		ref={ref}
		className={cx(styles.container, className)}
		id={id}
	>
		{children}
	</div>
);

export default forwardRef(CreateVacancyCard);
