import React from "react";
import _get from "lodash/get";
import RcDropDown from "rc-dropdown";
import Menu, { Item } from "rc-menu";
import { OutlineButton, Button } from "common/styled/buttons";
import { CANCELLED, REJECTED, HIRED, DECLINED, APPLIED, SENT } from "config";

const PokeActions = ({
	pokeStage = "shortlist",
	pokeStatus = "pending",
	interviewStatus,
	offerStatus,
	canAccept,
	actions
}) => {
	const refuseOffer = {
		name: "Refuse offer",
		action: actions.refuseOffer
	};

	const acceptOffer = {
		name: "Accept offer",
		action: actions.acceptOffer
	};

	const proposeAnotherInterview = {
		name: "Propose another interview",
		action: actions.ProposeOtherInterview
	};

	const acceptInterview = {
		name: "Accept interview",
		action: actions.acceptInterview
	};

	const cancelApplication = {
		name: "Cancel application",
		action: actions.cancelApplication
	};

	const possibleActions = {
		offer: {
			sent: [
				acceptOffer,
				refuseOffer,
				pokeStatus === APPLIED && cancelApplication,
				(interviewStatus === DECLINED || interviewStatus === SENT) &&
					proposeAnotherInterview,
				((interviewStatus === DECLINED && canAccept) ||
					interviewStatus === SENT) &&
					acceptInterview
			],
			draft: [
				acceptOffer,
				refuseOffer,
				pokeStatus === APPLIED && cancelApplication,
				(interviewStatus === DECLINED || interviewStatus === SENT) &&
					proposeAnotherInterview,
				((interviewStatus === DECLINED && canAccept) ||
					interviewStatus === SENT) &&
					acceptInterview
			],
			refused: [
				pokeStatus === APPLIED && cancelApplication,
				(interviewStatus === DECLINED || interviewStatus === SENT) &&
					proposeAnotherInterview,
				((interviewStatus === DECLINED && canAccept) ||
					interviewStatus === SENT) &&
					acceptInterview
			],
			accepted: [
				(interviewStatus === DECLINED || interviewStatus === SENT) &&
					proposeAnotherInterview,
				((interviewStatus === DECLINED && canAccept) ||
					interviewStatus === SENT) &&
					acceptInterview
			],
			cancelled: [
				pokeStatus === APPLIED && cancelApplication,
				(interviewStatus === DECLINED || interviewStatus === SENT) &&
					proposeAnotherInterview,
				((interviewStatus === DECLINED && canAccept) ||
					interviewStatus === SENT) &&
					acceptInterview
			]
		},
		apply: {
			applied: [pokeStatus === APPLIED && cancelApplication]
		},
		shortlist: {
			applied: [pokeStatus === APPLIED && cancelApplication],
			//TODO removed on sprint73
			shortlisted: [],
			sent: [],
			cancelled: []
		},
		interview: {
			sent: [
				proposeAnotherInterview,
				acceptInterview,
				pokeStatus === APPLIED && cancelApplication
			],
			accepted: [pokeStatus === APPLIED && cancelApplication],
			declined: [
				canAccept && acceptInterview,
				proposeAnotherInterview,
				pokeStatus === APPLIED && cancelApplication
			],
			expired: [pokeStatus === APPLIED && cancelApplication],
			done: [pokeStatus === APPLIED && cancelApplication],
			rejected: [],
			cancelled: [cancelApplication]
		}
	};

	let stageActions =
		pokeStatus === REJECTED || pokeStatus === CANCELLED
			? []
			: pokeStatus === HIRED
			? []
			: _get(
					possibleActions,
					`${pokeStage}.${offerStatus ?? interviewStatus ?? pokeStatus}`,
					[]
			  ).filter(Boolean);

	const showMore = stageActions.length > 3;
	const visibleActions = showMore ? stageActions.slice(0, 1) : stageActions;
	const moreActions = stageActions.slice(1, stageActions.length);

	return (
		<>
			{visibleActions.map((action, index) => {
				const ActionButton = !Boolean(index) ? Button : OutlineButton;
				return (
					<ActionButton
						key={action.name}
						small
						mb={2}
						onClick={() => action.action()}
					>
						{action.icon && (
							<i className={action.icon} style={{ marginRight: 5 }} />
						)}
						{action.name}
					</ActionButton>
				);
			})}
			{showMore && (
				<RcDropDown
					trigger={["click"]}
					overlay={
						<Menu className="sidebar-actions">
							{moreActions.map(action => (
								<Item key={action.name} onClick={() => action.action()}>
									<span>{action.name}</span>
								</Item>
							))}
						</Menu>
					}
					overlayClassName="ignore-click sidebar-actions-container"
				>
					<OutlineButton
						small
						block
						style={{ marginBottom: "10px" }}
						className="poke-more-options"
					>
						More options
					</OutlineButton>
				</RcDropDown>
			)}
		</>
	);
};

export default PokeActions;
