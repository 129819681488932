import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_FEATURE_SETTINGS_TIER } from "config/api-endpoints";

const updateFeatureSettings = body =>
	client(UPDATE_FEATURE_SETTINGS_TIER, {
		body: {
			...body,
			feature_name: "feature_variable_margins",
			name: "variable margins"
		}
	});

function useUpdateFeatureSettings(options) {
	return useMutation(updateFeatureSettings, options);
}

export default useUpdateFeatureSettings;
