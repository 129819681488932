import React from "react";
import styles from "./mission-details-collapse.module.scss";
import { ReactComponent as Arrow_down } from "static/icons/arrow-down-new.svg";

const MissionDetailsCollapse = ({
	title,
	count,
	children,
	isOpen,
	onToggle,
	badge = "badge"
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.header} onClick={onToggle}>
				<div className={styles.leftHeader}>
					<span className={styles.headerTitle}>{title}</span>
					{!isOpen && Number.isInteger(count) && count > 0 && (
						<span className={styles[badge]}>{count}</span>
					)}
				</div>
				<Arrow_down
					className={isOpen ? undefined : styles.rotate}
					width="24px"
					height="24px"
				/>
			</div>
			<div className={isOpen ? styles.visible : styles.hidden}>
				<div className={styles.divider}></div>
				<div className={styles.content}>{children}</div>
			</div>
		</div>
	);
};

export default MissionDetailsCollapse;
