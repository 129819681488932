import React from "react";
import ClientTimesheetActions from "./ClientTimesheetActions";
import TimesheetActions from "./TimesheetActions";
import "./cta.css";
import { get } from "lodash";
import { ACCEPTED } from "config";
import { DRAFT } from "config";

const Actions = ({
	attachments,
	isClient,
	isLocked,
	status,
	timesheetId,
	disputedWorkTime,
	disputedExpense,
	setDisputedWorkTime,
	setDisputedExpense,
	loadTimeSheet,
	last_action,
	isConsultantAdmin,
	addedWorkTimes,
	addedOverTimes,
	addedExpenses,
	isExpense,
	isEdited,
	isAllElementHadChecked,
	isMultiActionsMode,
	setIsMultiEditMode,
	setIsMultiActionsMode,
	isMultiEditMode,
	month,
	list,
	hasTag,
	getInitialVisibleMonth,
	cancelEdit,
	currentDocument,
	isWorkTime,
	type,
	canApproveTags,
	allItems,
	canApprove,
	companyId,
	setIsAllElementHasChecked,
	setCheckAll,
	setChecked,
	checked,
	setAddedExpense,
	setAddedWorkTime,
	setAddedOverTime,
	isTimesheetEmpty,
	clearResubmittedTimesheet,
	isOverTime,
	reportingSettings
}) => {
	const disableAcceptAllEntries = () => {
		let mappedWorktimesIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);

		let mappedExpensesIds = disputedExpense.map(
			({ worktime_id }) => worktime_id
		);
		let worktimes = allItems.worktimes
			.filter(item => {
				return (
					canApprove(item.tag) &&
					!mappedWorktimesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
				);
			})
			.map(({ _id }) => _id);
		let overtimes = get(allItems, "overtimes", [])
			.filter(item => {
				return (
					canApprove(item.tag) &&
					!mappedWorktimesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
				);
			})
			.map(({ _id }) => _id);
		let expenses = allItems.expenses
			.filter(item => {
				return (
					canApprove(item.tag) &&
					!mappedExpensesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
				);
			})
			.map(({ _id }) => _id);
		return worktimes.length || overtimes.length || expenses.length;
	};

	if (
		!isClient &&
		!Boolean(disableAcceptAllEntries()) &&
		!isEdited &&
		status !== DRAFT
	)
		return null;

	return !isClient ? (
		<TimesheetActions
			status={status}
			isConsultantAdmin={isConsultantAdmin}
			disputedExpense={disputedExpense}
			disputedWorkTime={disputedWorkTime}
			timesheetId={timesheetId}
			loadTimeSheet={loadTimeSheet}
			last_action={last_action}
			addedWorkTimes={addedWorkTimes}
			addedOverTimes={addedOverTimes}
			addedExpenses={addedExpenses}
			isExpense={isExpense}
			isEdited={isEdited}
			isMultiCheck={isAllElementHadChecked.length}
			allItems={allItems}
			canApprove={canApprove}
			companyId={companyId}
			setDisputedWorkTime={setDisputedWorkTime}
			setDisputedExpense={setDisputedExpense}
			setAddedExpense={setAddedExpense}
			setAddedWorkTime={setAddedWorkTime}
			setAddedOverTime={setAddedOverTime}
			clearResubmittedTimesheet={clearResubmittedTimesheet}
			isOverTime={isOverTime}
			attachments={attachments}
		/>
	) : (
		<ClientTimesheetActions
			isLocked={isLocked}
			isAllElementHadChecked={isAllElementHadChecked}
			status={status}
			timesheetId={timesheetId}
			disputedWorkTime={disputedWorkTime}
			disputedExpense={disputedExpense}
			setDisputedWorkTime={setDisputedWorkTime}
			setDisputedExpense={setDisputedExpense}
			loadTimeSheet={loadTimeSheet}
			last_action={last_action}
			isExpense={isExpense}
			isMultiCheck={isAllElementHadChecked.length}
			isClient={isClient}
			isMultiActionsMode={isMultiActionsMode}
			setIsMultiEditMode={setIsMultiEditMode}
			setIsMultiActionsMode={setIsMultiActionsMode}
			isMultiEditMode={isMultiEditMode}
			month={month}
			list={list}
			hasTag={hasTag}
			getInitialVisibleMonth={getInitialVisibleMonth}
			cancelEdit={cancelEdit}
			currentDocument={currentDocument}
			isWorkTime={isWorkTime}
			type={type}
			canApproveTags={canApproveTags}
			allItems={allItems}
			canApprove={canApprove}
			companyId={companyId}
			setIsAllElementHasChecked={setIsAllElementHasChecked}
			setCheckAll={setCheckAll}
			setChecked={setChecked}
			checked={checked}
			isTimesheetEmpty={isTimesheetEmpty}
			isOverTime={isOverTime}
			setAddedOverTime={setAddedOverTime}
			addedOverTimes={addedOverTimes}
			reportingSettings={reportingSettings}
		/>
	);
};

export default Actions;
