import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_SOCIAL_MEDIA_PICTURE } from "config/api-endpoints";

function updateSMPicture(data) {
	return client(UPDATE_SOCIAL_MEDIA_PICTURE, {
		body: data
	});
}

function useUpdateSMPicture(options) {
	return useMutation(updateSMPicture, options);
}

export default useUpdateSMPicture;
