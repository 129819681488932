import { CompositeDecorator } from "draft-js";
import { findEntities } from "./helpers";
import Link from "./Link";

const decorator = ({ clickLink }) =>
	new CompositeDecorator([
		{
			strategy: (contentBlock, callback, contentState) => {
				return findEntities("LINK", contentBlock, callback, contentState);
			},
			component: Link,
			props: { clickLink, target: "_blank" }
		}
	]);

export default decorator;
