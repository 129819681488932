import React from "react";
import { Letter } from "react-letter";
import root from "react-shadow";
import styles from "./render-email.module.scss";

export default function RenderEmail({ html }) {
	return (
		<root.div mode="closed" className={styles.message}>
			<Letter html={html} />
		</root.div>
	);
}
