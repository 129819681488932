import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_SITE } from "config/api-endpoints";

function deleteSite(body) {
	return client(DELETE_SITE, {
		body
	});
}
function useDeleteSite(options) {
	return useMutation(deleteSite, options);
}

export default useDeleteSite;
