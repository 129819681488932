import React, { useRef, useState } from "react";
import { ReactComponent as Dots } from "static/icons/dots-horizontal.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import s from "./popover.module.scss";
import { usePopper } from "react-popper";
import Portal from "common/Portal";

function BasicPopover({ value }) {
	const [openMenu, setOpenMenu] = useState(false);
	const referenceElement = useRef();
	const popperElement = useRef();

	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "top-start",
			modifiers: [
				{
					name: "flip",
					options: {
						fallbackPlacements: ["bottom-start", "right"]
					}
				},
				{
					name: "offset",
					options: {
						offset: [25, -5]
					}
				}
			]
		}
	);

	useOnClickOutside(popperElement, () => {
		setOpenMenu(false);
	});
	const handleClick = () => {
		setOpenMenu(old => !old);
	};

	return (
		<div className={s.container}>
			<Portal>
				<div
					className={s.container}
					ref={popperElement}
					style={{
						...styles.popper,
						visibility: openMenu ? "visible" : "hidden"
					}}
					{...attributes.popper}
				>
					<div className={s.menu}>
						<span className={s.value}>{value}</span>{" "}
					</div>
				</div>
			</Portal>
			<button className={s.btn} onClick={handleClick} ref={referenceElement}>
				<span
					style={{
						fontSize: "13px",
						fontWeight: "bold"
					}}
				>
					<Dots />
				</span>
			</button>
		</div>
	);
}

export default BasicPopover;
