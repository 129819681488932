import Immutable from "seamless-immutable";
import * as types from "../actions/actionTypes";

const initialState = Immutable({
	proposeSkill: false,
	proposeSector: null,
	proposedSubCategory: null,
	proposedSkill: null,
	showGetHelp: false,
	showHelpModal: "",
	fields: {},
	loading: false,
	categories: [],
	subcategories: [],
	functions: [],
	skills: [],
	languages: [],
	allCountries: [],
	euCountries: [],
	filterSettings: {},
	axiosHelper: {
		inAction: false,
		succeded: false,
		failed: false,
		isModal: false
	}
});

export default function(state = initialState, action) {
	switch (action.type) {
		case types.TOGGLE_PROPOSE:
			return Immutable.merge(state, {
				proposeSkill: !state.proposeSkill,
				proposeSector: action.sector,
				proposedSkill: action.skill,
				proposedSubCategory: action.subcategory
			});
		case types.TOGGLE_GET_HELP:
			return Immutable.merge(state, { showGetHelp: !state.showGetHelp });
		case types.TOGGLE_HELP_MODAL:
			return Immutable.merge(state, {
				showHelpModal: action.modal,
				fields: action.fields || {}
			});
		case types.FILL_HELP_FORM:
			return Immutable.merge(state, {
				fields: action.fields || {}
			});
		case types.SET_CATEGORIES:
			return Immutable.merge(state, {
				categories: action.data
			});
		case types.SET_SUBCATEGORIES:
			return Immutable.merge(state, {
				subcategories: action.data
			});
		case types.SET_FUNCTIONS:
			return Immutable.merge(state, {
				functions: action.data
			});
		case types.SET_SKILLS:
			return Immutable.merge(state, {
				skills: action.data
			});
		case types.SET_LANGUAGES:
			return Immutable.merge(state, {
				languages: action.data
			});
		case types.TOGGLE_LOADING:
			return Immutable.merge(state, {
				loading: !state.loading
			});
		case types.SET_COUNTRIES:
			const key = action.all ? "allCountries" : "euCountries";
			return Immutable.merge(state, {
				[key]: action.data
			});
		// AxiosHelper actions
		case types.AXIOS_PRE_REQUEST:
		case types.ACTION_CREATOR:
			return Immutable.merge(state, {
				axiosHelper: { ...initialState.axiosHelper, inAction: true, ...action }
			});
		case types.AXIOS_REQUEST_SUCCESS:
			return Immutable.merge(state, {
				axiosHelper: {
					...initialState.axiosHelper,
					inAction: false,
					...action,
					succeded: true
				}
			});
		case types.AXIOS_REQUEST_FAIL:
			return Immutable.merge(state, {
				axiosHelper: {
					...initialState.axiosHelper,
					...action,
					inAction: false,
					failed: true
				}
			});
		case types.SET_FILTER_SETTINGS: {
			return Immutable.merge(state, {
				filterSettings: action.data
			});
		}
		default:
			return state;
	}
}
