import React, { useEffect, useState } from "react";
import Rcslider from "rc-slider";

import { MAXIMUM_SEARCH_DISTANCE } from "config";
import CustomHandle from "common/search/CustomHandle";
import Scrubber from "common/Scrubber";
import { DistanceContainer } from "../Styled";
import GlobalTooltip from "common/GlobalTooltip";

const DistanceFilter = ({
	distance,
	updateDistance,
	isClient,
	residence_only,
	updateResidenceOnly,
	showDistance
}) => {
	const [currentDistance, setCurrentDistance] = useState(distance);

	useEffect(() => {
		setCurrentDistance(distance);
	}, [distance]);

	return (
		<DistanceContainer>
			{showDistance && (
				<>
					<div className="title">Distance</div>
					<div className="npl col-md-8">
						<Rcslider
							min={0}
							max={MAXIMUM_SEARCH_DISTANCE}
							defaultValue={currentDistance}
							value={currentDistance}
							handle={props => (
								<CustomHandle {...props} tipFormatter={value => `${value}km`} />
							)}
							onChange={value => setCurrentDistance(value)}
							onAfterChange={() => updateDistance(currentDistance)}
						/>
					</div>
					<div className="scrubber">
						<Scrubber
							onAfterChange={value => {
								if (value !== currentDistance) {
									setCurrentDistance(value);
									updateDistance(value);
								}
							}}
							value={currentDistance}
							min={0}
							max={MAXIMUM_SEARCH_DISTANCE}
							debounce={true}
						/>
						km
					</div>
				</>
			)}
			<div className="clear" />
			{isClient && (
				<div className="residence-checkbox">
					<div className="checkbox checkbox-primary">
						<div
							className="input-box"
							style={{ width: "100%", display: "flex" }}
						>
							<input
								type="checkbox"
								className="styled"
								id={"residence"}
								checked={residence_only}
								onChange={() => {
									updateResidenceOnly(!residence_only);
								}}
							/>
							<label htmlFor={"residence"} className="residence-label">
								<div className="residence-text">
									Place of residence only{" "}
									<GlobalTooltip
										overlay={
											"This option allows you to display only the candidates who currently live in the radius of the above-mentioned location"
										}
									/>
								</div>
							</label>
						</div>
					</div>
				</div>
			)}
		</DistanceContainer>
	);
};

export default DistanceFilter;
