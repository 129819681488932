import styled from "styled-components";
import { colors } from "config/styles";

export const Title = styled.span`
	font-size: 25px;
	font-weight: 300;
	line-height: 0.8;
	color: ${colors.anchorsColor};
	display: block;
	margin-top: 10px;
`;

export const NoteContainer = styled.div`
	background-color: #f8f8f9;
	border: 1px solid #eef0f1;
	padding: 20px;
	margin-bottom: 13px;
	font-weight: 400;
	div {
		color: #68717f;
	}
	p {
		font-weight: 400;
		font-size: 14px;
		margin-top: 10px;
	}
	button {
		font-size: 14px;
	}
`;

export const SkillCategoryContainer = styled.div`
	padding: 5px 0px;
	margin-top: -10px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	.category-name {
		color: ${colors.anchorColor};
		font-weight: bold;
		line-height: 2.23;
		text-align: left;
	}
`;

export const WrapperModal = styled.div`
	.txt-modal {
		font-size: 14px;
		color: #193651;
		margin-bottom: 45px;
	}
	#reject_type {
		margin: 0;
	}
`;
export const WrapperModalCancelInterview = styled.div`
	.txt-modal {
		font-size: 14px;
		color: #193651;
		margin-bottom: 45px;
	}
`;

const CvContainer = styled.div`
	background-color: ${colors.white};
	padding: 30px;
	border: solid 1px ${colors.sectionBorderColor};
	margin-bottom: 20px;
	.canvasCv {
		border-right: 1px solid #eef0f1;
		padding-right: 20px;
		padding-left: 0;
	}
	.loader {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}
	.file-progress-box {
		margin-top: 11px;
		bottom: 12px;
		.file-progress-bar {
			height: 8px;
			border-radius: 4px;
			background-color: ${colors.bodyBg};
			overflow: hidden;
		}
		.size {
			float: right;
			font-size: 12px;
			font-weight: 500;
			text-align: left;
			color: ${colors.linksColor};
			margin-left: 15px;
			margin-top: -2px;
			line-height: 14px;
		}
		.file-sr-only {
			border-radius: 4px;
			height: 100%;
			width: 1%;
			background-color: ${colors.primaryColor};
		}
	}
`;

export { CvContainer };

export const LoadingFile = styled.div`
	display: inline-block;
	width: 180px;
	height: 140px;
	position: relative;
	background-color: ${colors.white};
	border: solid 1px ${colors.loaderBaseColor};
	border-radius: 5px;
	box-shadow: 0 0 10px 4px rgba(235, 238, 243, 0.69);
	padding: 20px 8px;
	top: 26px;
	margin-right: 20px;
	&.cv-content {
		box-shadow: none;
		border: none;
		top: -20px;
	}
	.loading {
		margin-top: 10px !important;
	}
	.loader {
		position: absolute !important;
		left: 50%;
		transform: translateX(-50%);
		.error {
			border-color: ${colors.bodyBg} !important;
		}
		.icon-close2 {
			font-size: 20px;
			font-weight: 900;
			position: absolute;
			left: 50%;
			top: 50%;
			color: ${colors.errorRed};
			transform: translate(-50%, -50%);
		}
	}
	.text {
		font-size: 11px;
		font-weight: 500;
		line-height: 1.84;
		letter-spacing: 0.88px;
		text-align: left;
		font-style: normal;
		font-stretch: normal;
		color: ${colors.anchorsColor};
		text-transform: uppercase;
		position: absolute;
		top: 70%;
		left: 10%;
		&.error {
			transition: 0.1s;
			top: 65%;
			color: ${colors.errorRed};
			text-align: left;
			left: unset;
			font-size: 10px;
		}
	}
`;
