/* eslint-disable react/display-name */
import React from "react";
import BodyClassName from "react-body-classname";
import loadable from "loadable-components";
import { Helmet } from "react-helmet";

const PrivacyPolicyText = loadable(() =>
	import(/* webpackChunkName: "privacypolicytext" */ "./PrivacyPolicyText")
);

export default ({ isLoggedIn }) => {
	const bodyClass = !isLoggedIn ? "fluid-container" : "";
	return (
		<BodyClassName className={`static-page policy-page gray-bg ${bodyClass}`}>
			<div className="static-page-container">
				{!isLoggedIn && (
					<Helmet>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1"
						/>
					</Helmet>
				)}

				<div className="container">
					<h1>wiggli - Privacy Policy</h1>
					<PrivacyPolicyText />
				</div>
			</div>
		</BodyClassName>
	);
};
