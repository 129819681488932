import { getJobViewRoute } from "modules/RequestView/RequestView";
import { getCreateJobRoute, getCreateVacancyRoute } from "./CreateJob";
import {
	getJobProfileRoute,
	getVacancyProfileRoute
} from "./jobprofile/JobProfileContainer";
import {
	getJobRequestRoute,
	getVacancyRequestRoute
} from "./jobrequest/JobRequestContainer";
import { getJobPreviewRoute, getVacancyPreviewRoute } from "./JobPreview";

import { getVacancyViewRoute } from "modules/vacancy/components/vacancyView";
getJobViewRoute;

export {
	getJobProfileRoute,
	getJobRequestRoute,
	getJobPreviewRoute,
	getCreateJobRoute,
	getCreateVacancyRoute,
	getVacancyProfileRoute,
	getVacancyRequestRoute,
	getVacancyPreviewRoute,
	getJobViewRoute,
	getVacancyViewRoute
};
