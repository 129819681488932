import React from "react";
import { Box, Flex, Text } from "rebass";
import { ReactComponent as NoNotificationsBox } from "static/icons/no-notifications-box-2.svg";

export default function NotificationsCenterEmptyBlock({ height = 440 }) {
	return (
		<Flex
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height={height}
		>
			<NoNotificationsBox />
			<Box height={24} />
			<Text
				sx={{
					fontFamily: "Work Sans",
					fontSize: "20px",
					fontWeight: 500,
					color: "#334155"
				}}
			>
				No notifications available
			</Text>
			<Box height={8} />
			<Text
				sx={{
					fontFamily: "Work Sans",
					fontSize: "15px",
					textAlign: "center",
					color: "#334155"
				}}
			>
				You haven’t received any notification yet
			</Text>
		</Flex>
	);
}
