import React from "react";
import styles from "./add-vacancy-enhance-text-dialog.module.scss";
import { CreateVacancyModal } from "../CreateVacancyModal";
import { ModalContent } from "../CreateVacancyModal/components/ModalContent";
import { ModalFooter } from "../CreateVacancyModal/components/ModalFooter";
import { ModalCancelButton } from "../CreateVacancyModal/components/ModalCancelButton";
import { ModalSaveButton } from "../CreateVacancyModal/components/ModalSaveButton";
import { CustomTextArea } from "common/CustomTextArea";
import { CustomDropDown } from "common/CustomDropDown";
import { TEXT_TONES_LIST } from "../../utils/constants";
import { useEnhanceTextForm } from "../../hooks/useEnhanceTextForm";
import { Controller } from "react-hook-form";
import { ReactComponent as StarIcon } from "static/icons/star-and-plus.svg";
import { Button } from "common/Button";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { ReactComponent as RecordingIcon } from "static/icons/recording-icon.svg";

const AddVacancyEnhanceTextDialog = ({
	field,
	onEscapeKeyDown,
	onCancel,
	onGenerate,
	isLoading,
	placeholder
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useEnhanceTextForm();

	return (
		<CreateVacancyModal
			onClose={onEscapeKeyDown}
			className={styles.createVacancyModal}
		>
			<div className={styles.header}>
				<div className={styles.titleContainer}>
					<StarIcon width={25} height={25} color="#00857A" />
					<h2 className={styles.modalTitle}>Enhance your {field}</h2>
					<Button
						text="new"
						className={styles.newButton}
						textClassName={styles.newButtonText}
					/>
				</div>
				<button onClick={onCancel} className={styles.closeBtn}>
					<CloseIcon />
				</button>
			</div>

			<ModalContent>
				<div className={styles.modalContentContainer}>
					<div className={styles.grid}>
						<div className={styles.label}>
							Use AI-Powered prompts to enhance your {field}
						</div>
						<Controller
							control={control}
							name="prompt"
							render={({ field: { onChange, value } }) => (
								<CustomTextArea
									className={styles.prompt}
									placeholder={placeholder}
									value={value}
									onChange={onChange}
								/>
							)}
						/>
						{errors?.prompt && (
							<span className={styles.error}>{errors.prompt.message}</span>
						)}

						<Controller
							name="tone"
							control={control}
							render={({ field: { value, onChange } }) => (
								<CustomDropDown
									className={styles.textTone}
									options={TEXT_TONES_LIST}
									placeHolder="Select Tone"
									value={value}
									onChange={onChange}
									inputClassName={styles.input}
									inputIcon={<RecordingIcon width={20} height={20} />}
								/>
							)}
						/>
					</div>
					<div className={styles.note}>
						<StarIcon width={35} height={35} color="#00857A" />
						<span className={styles.noteText}>
							Our AI generator is pretty clever, but it still needs your expert
							touch. Think of it as your sous-chef!
						</span>
					</div>
				</div>
			</ModalContent>
			<ModalFooter className={styles.modalFooter}>
				<ModalCancelButton onClick={onCancel} />
				<ModalSaveButton
					text="Generate"
					onClick={handleSubmit(onGenerate)}
					icon={<StarIcon />}
					isLoading={isLoading}
				/>
			</ModalFooter>
		</CreateVacancyModal>
	);
};

export default AddVacancyEnhanceTextDialog;
