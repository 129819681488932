import Avatar from "common/Avatar";
import React from "react";
import { Box, Flex } from "rebass";
import { SectionInfoWrapper } from "./Styled";

const InfoCompanySection = ({ supplierDetails }) => {
	return (
		<SectionInfoWrapper>
			<Flex alignItems="center">
				<Box mr={14}>
					<div className="section-avatar">
						<Avatar
							avatar={supplierDetails?.avatar}
							name={supplierDetails?.name}
							size={60}
						></Avatar>
					</div>
				</Box>

				<Box>
					<div className="section">
						<p className="title">SUPPLIER’s NAME</p>
						<p className="body-name">{supplierDetails?.name}</p>
					</div>
				</Box>
			</Flex>

			<Box>
				<div className="section">
					<p className="title">contact</p>
					<p className="body">
						{supplierDetails?.first_name} {supplierDetails?.last_name}
					</p>
				</div>
			</Box>
			<Box>
				<div className="section">
					<p className="title">PHONE</p>
					<p className="body">{supplierDetails?.phone}</p>
				</div>
			</Box>
			<Box>
				<div className="section">
					<p className="title">EMAIL</p>
					<p className="body">{supplierDetails?.email}</p>
				</div>
			</Box>
			<Box>
				<div className="section">
					<p className="title">WEBSITE</p>
					<p className="body">{supplierDetails?.website ?? "N/A"}</p>
				</div>
			</Box>
		</SectionInfoWrapper>
	);
};
export default InfoCompanySection;
