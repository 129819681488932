import { browserHistory } from "react-router";
import { getIdentityChooserRoute } from "./IdentityChooser";
import { getCVChooserRoute } from "./cvchooser/CVChooserContainer";
import { getAccountActivatedRoute } from "./AccountActivated";
import { STATUS_ACTIVATED, STATUS_IDENTITY, STATUS_CV } from "config";

const checkStatus = status => {
	switch (status) {
		case STATUS_IDENTITY:
			browserHistory.push({
				pathname: getIdentityChooserRoute(),
				state: "identity"
			});
			break;
		case STATUS_CV:
			browserHistory.push({
				pathname: getCVChooserRoute(),
				state: "cv"
			});
			break;
		case STATUS_ACTIVATED:
			browserHistory.push({
				pathname: getAccountActivatedRoute(),
				state: "congratulation"
			});
			break;
		default:
			break;
	}
	return Promise.resolve(true);
};

export default checkStatus;
