import React, { memo } from "react";
import { CreateRequestSkeletonCard } from "../CreateRequestSkeletonCard";
import { CreateRequestModal } from "../CreateRequestCard";
import { CreateRequestCardContent } from "../CreateRequestCardContent";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestInfoIcon } from "../CreateRequestInfoIcon";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import styles from "./create-request-step-one-loading.module.scss";

const CreateRequestStepOneLoading = ({ hasMultiSites }) => {
	return (
		<>
			<div className={styles.stepper} />
			<div className={styles.cardsList}>
				<CreateRequestModal className={styles.card}>
					<CreateRequestCardContent>
						<CreateRequestFieldContainer>
							<CreateRequestLabelContainer>
								<CreateRequestFieldLabel>Department</CreateRequestFieldLabel>
								<CreateRequestInfoIcon>
									Please choose the department for which you are hiring. The
									users of the selected department will be able to see and
									interact with candidates for this vacancy (poke candidates,
									add notes on applications, etc..).
								</CreateRequestInfoIcon>
							</CreateRequestLabelContainer>
							<CreateRequestSkeletonCard />
						</CreateRequestFieldContainer>
						{hasMultiSites && (
							<CreateRequestFieldContainer>
								<CreateRequestFieldLabel>Site</CreateRequestFieldLabel>
								<CreateRequestSkeletonCard />
							</CreateRequestFieldContainer>
						)}
						<CreateRequestFieldContainer>
							<div className={styles.grid2Columns}>
								<CreateRequestFieldContainer>
									<CreateRequestFieldLabel>Category</CreateRequestFieldLabel>
									<CreateRequestSkeletonCard />
								</CreateRequestFieldContainer>
								<CreateRequestFieldContainer>
									<CreateRequestLabelContainer>
										<CreateRequestFieldLabel>
											Sub-category
										</CreateRequestFieldLabel>
									</CreateRequestLabelContainer>
									<CreateRequestSkeletonCard />
								</CreateRequestFieldContainer>
							</div>
						</CreateRequestFieldContainer>
						<CreateRequestFieldContainer>
							<div className={styles.grid2Columns}>
								<CreateRequestFieldContainer>
									<CreateRequestFieldLabel>Function</CreateRequestFieldLabel>
									<CreateRequestSkeletonCard />
								</CreateRequestFieldContainer>
								<CreateRequestFieldContainer>
									<CreateRequestLabelContainer>
										<CreateRequestFieldLabel>Seniority</CreateRequestFieldLabel>
									</CreateRequestLabelContainer>
									<CreateRequestSkeletonCard />
								</CreateRequestFieldContainer>
							</div>
						</CreateRequestFieldContainer>
						<CreateRequestFieldContainer>
							<CreateRequestLabelContainer>
								<CreateRequestFieldLabel>Skills</CreateRequestFieldLabel>
								<CreateRequestInfoIcon>
									The skills are an essential part of the candidate/vacancy
									matching process. Be as precise as possible (without being too
									demanding) in the selection of the skills needed and the
									weight/importance they have for the role.
								</CreateRequestInfoIcon>
							</CreateRequestLabelContainer>
							<div className={styles.skillsSkeletonContainer}>
								<CreateRequestSkeletonCard useRandomWidth />
								<CreateRequestSkeletonCard useRandomWidth />
								<CreateRequestSkeletonCard useRandomWidth />
							</div>
						</CreateRequestFieldContainer>
						<CreateRequestFieldContainer>
							<CreateRequestLabelContainer>
								<CreateRequestFieldLabel>Languages</CreateRequestFieldLabel>
								<CreateRequestInfoIcon>
									Languages are another fundamental part of the matching
									process. Although they are weighted less than skills in the
									matching score calculation, it is still important to properly
									outline your linguistic skills.
								</CreateRequestInfoIcon>
							</CreateRequestLabelContainer>
							<div className={styles.languagesSkeletonContainer}>
								<CreateRequestSkeletonCard useRandomWidth />
								<CreateRequestSkeletonCard useRandomWidth />
							</div>
						</CreateRequestFieldContainer>
					</CreateRequestCardContent>
				</CreateRequestModal>
				<div className={styles.nextButtonContainer}>
					<CreateRequestSkeletonCard width="78px" />
				</div>
			</div>
		</>
	);
};

export default memo(CreateRequestStepOneLoading);
