import React from "react";
import styles from "./form-builder-element-section.module.scss";
import { useFieldArray, useFormContext } from "react-hook-form";
import FormBuilderAddElementDropdown from "../FormBuilderAddElementDropdown";
import FormBuilderRenderElements from "../FormBuilderRenderElements";
import get from "lodash/get";
import FieldError from "../FieldError";
import { ELEMENT_LABELS } from "../../utils/constants";
import FormBuilderDragButton from "../FormBuilderDragButton";
import FormBuilderActionDuplicate from "../FormBuilderActionDuplicate";
import FormBuilderActionDelete from "../FormBuilderActionDelete";
import cx from "classnames";

export default function FormBuilderElementSection({
	path,
	element,
	onDelete,
	onDuplicate,
	dragHandleProps,
	isPresentational,
	disableDrop
}) {
	const {
		register,
		formState: { errors }
	} = useFormContext();
	const fieldArrayName = `${path}.items`;
	const itemsFieldArray = useFieldArray({
		name: fieldArrayName
	});

	return (
		<div
			className={cx(styles.container, { [styles.shadow]: isPresentational })}
		>
			<div className={styles.header}>
				<FormBuilderDragButton
					className={styles.dragIcon}
					dragHandleProps={dragHandleProps}
				/>
				<div className={styles.title}>{ELEMENT_LABELS.section}</div>
				<div className={styles.inputContainer}>
					<input
						{...(isPresentational
							? { value: element.title }
							: register(`${path}.title`))}
						className={styles.input}
						placeholder="Enter section name"
					/>
					<FieldError
						error={get(errors, path)?.title}
						className={styles.titleError}
					/>
				</div>
				<FormBuilderActionDuplicate onClick={onDuplicate} />
				<FormBuilderActionDelete onClick={onDelete} />
			</div>
			<div className={styles.content}>
				<FieldError
					error={get(errors, path)?.items}
					className={styles.contentError}
				/>
				<FormBuilderRenderElements
					id={element.id}
					isPresentational={isPresentational}
					presentationalItems={element.items}
					disableDrop={disableDrop}
					fieldArray={itemsFieldArray}
					fieldArrayName={fieldArrayName}
				/>
				<div className={styles.addContainer}>
					<FormBuilderAddElementDropdown
						fieldArrayName={fieldArrayName}
						isSection
					/>
				</div>
			</div>
		</div>
	);
}
