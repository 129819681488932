import React from "react";
import { DecoratorNode } from "lexical";
import EmailSignatureDecorator from "../plugin/EmailSignaturePlugin/components/EmailSignatureDecorator/EmailSignatureDecorator";
import { signatureHtmlTemplate } from "../plugin/EmailSignaturePlugin/utils/helpers";

export class EmailSignatureNode extends DecoratorNode {
	signature = {};
	constructor(signature, key) {
		super(key);
		this.signature = signature;
	}

	static getType() {
		return "email-signature-decorator";
	}

	static clone(node) {
		return new EmailSignatureNode(node.signature, node.__key);
	}

	createDOM() {
		const element = document.createElement("span");
		element.style.display = "inline-block";
		element.style.width = "100%";
		return element;
	}

	exportDOM() {
		const element = document.createElement("span");
		if (this.signature) {
			element.setAttribute("data-signature", JSON.stringify(this.signature));
			element.innerHTML = signatureHtmlTemplate({ ...this.signature });
		}
		return { element };
	}

	static importJSON(serializedNode) {
		const node = $createEmailSignatureNode(serializedNode.signature);
		return node;
	}

	static importDOM() {
		return {
			span: domNode => {
				if (domNode.hasAttribute("data-signature"))
					return {
						conversion: domNode => {
							const signature = JSON.parse(
								domNode.getAttribute("data-signature")
							);
							return { node: $createEmailSignatureNode(signature) };
						},
						priority: 3
					};
				return null;
			}
		};
	}

	exportJSON() {
		return {
			type: EmailSignatureNode.getType(),
			version: 1,
			signature: this.signature
		};
	}

	updateDOM() {
		return false;
	}
	setSignature(signature) {
		const self = this.getWritable();
		self.signature = signature;
	}

	decorate() {
		return (
			<EmailSignatureDecorator
				nodeKey={this.__key}
				signature={this.signature}
			/>
		);
	}
}

export function $createEmailSignatureNode(signature) {
	return new EmailSignatureNode(signature);
}

export function $isEmailSignatureNode(node) {
	return node instanceof EmailSignatureNode;
}
