import React from "react";
import styles from "./enhance-with-ai-button.module.scss";
import { ReactComponent as StarIcon } from "static/icons/star-and-plus.svg";

const EnhanceWithAIButton = ({ onClick }) => {
	return (
		<div className={styles.container}>
			<div className={styles.newLabelContainer}>New</div>
			<button onClick={onClick} className={styles.enhanceBtnContainer}>
				<StarIcon height={20} width={20} />
				<div className={styles.textBtn}>Enhance with AI</div>
			</button>
		</div>
	);
};

export default EnhanceWithAIButton;
