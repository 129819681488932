export const SUBMIT_PROPOSAL = "SUBMIT_PROPOSAL";
export const MAKE_PROPOSAL = "MAKE_PROPOSAL";
export const CLOSE_PROPOSAL = "CLOSE_PROPOSAL";
export const DONE_FETCHING = "DONE_FETCHING";
export const GET_MEMBERS = "GET_MEMBERS";
export const SWITCH_FREELANCER = "SWITCH_FREELANCER";
export const TOGGLE_ACTION = "BID_TOGGLE_ACTION";
export const BIDS_LIST_DONE_VENDOR = "BIDS_LIST_DONE_VENDOR";
export const BIDS_LIST_DONE_CLIENT = "BIDS_LIST_DONE_CLIENT";
export const WRITE_BID_DATA = "WRITE_BID_DATA";
export const FETCH_BID_SUCCEEDED = "FETCH_BID_SUCCEEDED";
export const TOGGLE_INTERVIEW_MODAL = "TOGGLE_INTERVIEW_MODAL";
export const TOGGLE_CONTRACT_MODAL = "TOGGLE_CONTRACT_MODAL";
export const TOGGLE_REJECT_MODAL = "TOGGLE_REJECT_MODAL";
export const SHOW_VIDEO_CONF = "SHOW_VIDEO_CONF";
export const HIDE_VIDEO_CONF = "HIDE_VIDEO_CONF";
export const SET_CONSULTANTS = "SET_CONSULTANTS";
export const FILTER_BY_STATUS = "FILTER_BY_STATUS";

export const LOAD_PROFILE = "LOAD_PROFILE";
export const LOADED_PROFILE = "LOADED_PROFILE";
