import { useEffect, useState } from "react";

export default function useIntersectionObserver(containerRef, options) {
	const [isVisible, setIsVisible] = useState(false);

	const callbackFunction = entries => {
		const [entry] = entries;
		setIsVisible(entry.isIntersecting);
	};

	useEffect(() => {
		const observer = new IntersectionObserver(callbackFunction, options);
		if (containerRef.current) observer.observe(containerRef.current);

		const ref = containerRef.current;
		return () => {
			if (ref) observer.unobserve(ref);
		};
	}, [containerRef, options]);

	return { isVisible };
}
