import { useQueryCache } from "react-query";
import { COMPANIES_LIST_QUERY, useCompaniesMutation } from "./useCompaniesApi";
import toaster from "common/Toaster";
import {
	FOLLOW,
	LEAVE_POOL,
	MUTE,
	UNFOLLOW,
	UNMUTE
} from "../ConfirmActionModal/config";
import useCompaniesStore from "../store/useCompaniesStore";

export const useCompaniesActions = () => {
	const queryCache = useQueryCache();
	const [mutate, { isLoading }] = useCompaniesMutation();

	const onMutate = (action, company) => {
		const payload = {
			action,
			site_id: company.site._id,
			company_id: company.site.company_id
		};
		return mutate(payload, {
			onSuccess: () => {
				queryCache.invalidateQueries(COMPANIES_LIST_QUERY);
				const message = getToasterMsg(action, company);
				if (message) toaster.success(message);
			}
		});
	};

	const getToasterMsg = (action, company) =>
		action === FOLLOW
			? "You are now following " + company.site.name + "!"
			: action === MUTE
			? "Notifications from " + company.site.name + " are now turned off!"
			: action === UNMUTE
			? "Notifications from " + company.site.name + " are now turned on!"
			: "";

	return [onMutate, { isLoading }];
};

export const useCompaniesActionsWithModal = () => {
	const queryCache = useQueryCache();
	const [mutate, { isLoading }] = useCompaniesMutation();

	const { resetModal } = useCompaniesStore();
	const onClose = () => resetModal();

	const onMutate = (action, company) => {
		const payload = {
			action,
			company_id: company.site.company_id,
			...([UNFOLLOW].includes(action) && {
				site_id: company.site._id
			})
		};
		return mutate(payload, {
			onSuccess: () => {
				queryCache.invalidateQueries(COMPANIES_LIST_QUERY);
				const message = getToasterMsg(action, company);
				if (message) toaster.success(message);
				onClose();
			}
		});
	};

	const getToasterMsg = (action, company) =>
		action === LEAVE_POOL
			? "You are no longer in the talent pool of " + company.site.name + "!"
			: action === UNFOLLOW
			? "You are no longer following " + company.site.name + "!"
			: "";

	return [onMutate, { isLoading }];
};
