import React from "react";
import { v4 as uuid } from "uuid";
import useNotesStore from "modules/notes/notes.store";
import cx from "classnames";
import PropTypes from "prop-types";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { ReactComponent as AddIcon } from "static/icons/PlusAdd.svg";
import { ReactComponent as Drag } from "static/icons/drag.svg";
import { ReactComponent as Hide } from "static/icons/hide.svg";
import { useOverviewContext } from "modules/candidate/Profile/Pages/Overview/useOverviewContext.js";
import styles from "./card.module.scss";
const Card = ({
	children,
	title,
	extraOptions,
	hasBorderTop,
	isDragging,
	onDragEnd,
	onViewMore,
	notes,
	onToggleVisibility,
	typeBtn,
	candidateData
}) => {
	const { provided } = useOverviewContext();
	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));
	const showPopup = () => {
		addNote({
			id: uuid(),
			type: "QUICK_NOTE",
			canSelectCandidate: true,
			payload: {
				candidate: {
					value: candidateData?.user_id,
					label: `${candidateData?.personal_profile?.first_name} ${candidateData?.personal_profile?.last_name}`,
					profile_id: candidateData?.user_id
				}
			}
		});
	};
	return (
		<div
			onDragEnd={onDragEnd}
			className={cx(
				styles.container,
				{ [styles["darg"]]: isDragging },
				{ [styles["drop"]]: hasBorderTop }
			)}
		>
			<div className={styles.header}>
				<div className={cx(styles.flex)}>
					<button {...provided.dragHandleProps}>
						<Drag />
					</button>
					{title}
				</div>
				<div className={styles.flex}>
					{extraOptions}
					<button className={styles.hide} onClick={onToggleVisibility}>
						<Hide />
					</button>
				</div>
			</div>
			<div
				className={styles.content}
				style={{ padding: notes?.length > 0 ? "0" : "1rem" }}
			>
				{children}
			</div>
			{typeBtn === "condidate__note" && notes?.length >= 4 ? (
				<div className={styles.section_see_all}>
					<button className={styles.btn_see_all} onClick={onViewMore}>
						<svg
							width="1.125rem"
							height="1.125rem"
							viewBox="0 0 1.125rem 1.125rem"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.3332 9.33333V11.2C13.3332 11.9467 13.3332 12.3201 13.1879 12.6053C13.06 12.8562 12.8561 13.0602 12.6052 13.188C12.32 13.3333 11.9466 13.3333 11.1999 13.3333H9.3332M6.66653 2.66667H4.79987C4.05313 2.66667 3.67976 2.66667 3.39455 2.81199C3.14366 2.93982 2.93969 3.1438 2.81186 3.39468C2.66653 3.6799 2.66653 4.05326 2.66653 4.8V6.66667M9.99987 6L13.9999 2M13.9999 2H9.99987M13.9999 2V6M6.6665 13.5H4.79984C4.0531 13.5 3.67973 13.5 3.39452 13.3547C3.14363 13.2268 2.93966 13.0229 2.81183 12.772C2.6665 12.4868 2.6665 12.1134 2.6665 11.3667V10.4333V9.5"
								stroke="#525252"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<span className={styles.textBtn}>See all notes</span>
					</button>
				</div>
			) : (
				(typeBtn !== "condidate__note" || typeBtn === "") && (
					<div className={styles.footer__card}>
						<HeaderButton
							onClick={onViewMore}
							text="View More"
							className="viewMoreButton"
						/>
					</div>
				)
			)}
			{/* call func add note */}
			{typeBtn === "condidate__note" && (
				<div className={styles.footer__card}>
					<HeaderButton
						onClick={showPopup}
						text="Add Note"
						className="buttonAddNotes"
						icon={<AddIcon />}
					/>
				</div>
			)}
		</div>
	);
};
Card.propTypes = {
	title: PropTypes.node,
	isDragging: PropTypes.bool,
	hasBorderTop: PropTypes.bool,
	extraOptions: PropTypes.node,
	children: PropTypes.node.isRequired,
	isDraggable: PropTypes.bool,
	onViewMore: PropTypes.func.isRequired,
	onToggleVisibility: PropTypes.func.isRequired
};

Card.defaultProps = {
	title: "",
	isDragging: false,
	hasBorderTop: false,
	extraOptions: undefined,
	isDraggable: true
};

export default Card;
