import React from "react";
import { connect } from "react-redux";
import { useMutation } from "react-query";
import { reset, initialize } from "redux-form";
import { v4 as uuid4 } from "uuid";
import {
	addWorkTime,
	addExpense,
	documentUploaded,
	updateAmounts,
	resetCurrentDocument
} from "../actions";
import AddWorkTime from "./AddWorkTime";
import AddExpense from "./AddExpense";
import AddFiles from "./AddFiles";
import { client } from "lib/api-client";
import toaster from "common/Toaster";
import { get } from "lodash";
import { COMPUTE_TIMESHEET } from "config/api-endpoints";
import {
	DRAFT,
	WORKTIME,
	CLIENT,
	FREELANCER,
	EXPENSE,
	FILES,
	HUNDRED,
	OVERTIME
} from "config";

const computeTimesheet = ({ data }) =>
	client(COMPUTE_TIMESHEET, { body: data });

const AddNewBox = props => {
	const {
		timesheetId,
		view,
		switchView,
		status,
		month,
		currentDocument,
		isFetching,
		dispatch,
		tagOverlayText,
		onFilesActionFinish,
		requiredFilesIsEmpty,
		attachmentsLength,
		addedWorkTimes,
		addedExpenses,
		setAddedWorkTime,
		setAddedExpense,
		isViewFile,
		jobId,
		type,
		disputedWorkTime,
		addedOverTimes,
		setAddedOverTime,
		reportingSettings
	} = props;

	const [onComputeTimesheet] = useMutation(computeTimesheet, {
		onSuccess: (data, { payload }) => {
			if (view === WORKTIME) {
				const { amount } = data.dispute_items.worktimes.added[0];
				setAddedWorkTime([
					...addedWorkTimes,
					{
						...payload,
						[`${type === CLIENT ? CLIENT : FREELANCER}_amount`]: amount,
						status: "draft",
						generatedId: uuid4()
					}
				]);
				dispatch(
					initialize("add-worktime", {
						rate: `${HUNDRED}`
					})
				);
				dispatch(updateAmounts(data));
				return;
			}
			if (view === OVERTIME) {
				const { amount } = data.dispute_items.worktimes.added[0];
				setAddedOverTime([
					...addedOverTimes,
					{
						...payload,
						[`${type === CLIENT ? CLIENT : FREELANCER}_amount`]: amount,
						status: "draft",
						generatedId: uuid4()
					}
				]);
				dispatch(
					initialize("add-worktime", {
						rate: `${HUNDRED}`
					})
				);
				dispatch(updateAmounts(data));
				return;
			}

			if (view === EXPENSE) {
				const { amount } = data.dispute_items.expenses.added[0];
				setAddedExpense([
					...addedExpenses,
					{ ...payload, price: amount, status: "draft", generatedId: uuid4() }
				]);
				dispatch(resetCurrentDocument());
				dispatch(reset("add-expense"));
				dispatch(updateAmounts(data));
				return;
			}
		},
		onError: error => {
			toaster.danger(get(error, "detail.name", get(error, "title")));
		}
	});

	const onAdd = (payload, type = WORKTIME) => {
		if (type === WORKTIME)
			dispatch(addWorkTime(payload, jobId, timesheetId)).then(() =>
				dispatch(initialize("add-worktime", { rate: `${HUNDRED}` }))
			);
		else if (type === EXPENSE)
			dispatch(addExpense(payload, jobId, timesheetId)).then(() =>
				dispatch(reset("add-expense"))
			);
	};

	const getInitialVisibleMonth = () => {
		return window.moment.unix(props.month.start_date);
	};

	return (
		<div className="add-new-box">
			<div className="container">
				{view === WORKTIME || view === OVERTIME ? (
					<AddWorkTime
						disabled={isFetching}
						month={month}
						timesheetId={timesheetId}
						onAddWorkTime={payload => onAdd(payload)}
						tagOverlayText={tagOverlayText}
						getInitialVisibleMonth={getInitialVisibleMonth}
						addedWorkTimes={addedWorkTimes}
						addedOverTimes={addedOverTimes}
						status={status}
						onComputeTimesheet={onComputeTimesheet}
						disputedWorkTime={disputedWorkTime}
						isOverTime={Boolean(view === OVERTIME)}
						reportingSettings={reportingSettings}
					/>
				) : view === EXPENSE ? (
					<AddExpense
						disabled={isFetching}
						month={month}
						timesheetId={timesheetId}
						currentDocument={currentDocument}
						onUploadDone={data => dispatch(documentUploaded(data))}
						onAddExpense={payload => onAdd(payload, EXPENSE)}
						tagOverlayText={tagOverlayText}
						getInitialVisibleMonth={getInitialVisibleMonth}
						addedExpenses={addedExpenses}
						setAddedExpense={setAddedExpense}
						status={status}
						onComputeTimesheet={onComputeTimesheet}
						reportingSettings={reportingSettings}
					/>
				) : (
					view === FILES &&
					status === DRAFT &&
					(!requiredFilesIsEmpty || attachmentsLength > 0) &&
					!isViewFile && (
						<AddFiles
							disabled={isFetching}
							timesheet_id={timesheetId}
							onActionFinish={onFilesActionFinish}
							switchView={switchView}
							onComputeTimesheet={onComputeTimesheet}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default connect()(AddNewBox);
