import * as types from "./types";
import {
	SEARCH_PROFILE_ENDPOINT,
	LIST_SECTORS,
	LIST_FUNCTIONS,
	FETCH_POKE_ENDPOINT,
	ADD_POKE_NOTE,
	DECLINE_INTERVIEW,
	INVITE_USER_TO_INTERVIEW,
	EDIT_INTERVIEW,
	ACCEPT_INTERVIEW,
	CANCEL_INTERVIEW,
	REFUSE_INTERVIEW,
	REJECT_APPLICATION_ENDPOINT,
	GET_JOBS_BY_VACANCY,
	ADD_NOTE_BID
} from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";
import { browserHistory } from "react-router";

function resetLanguages() {
	return {
		type: types.REST_LANGUAGES_VACANCY
	};
}

export function initializeProfileSearch() {
	return {
		type: types.SET_SEARCH_RESET_VACANCY
	};
}

function resetSkills() {
	return {
		type: types.REST_SKILLS_VACANCY
	};
}

function _updateFilter(filter) {
	return {
		type: types.UPDATE_FILTER_VACANCY,
		filter
	};
}

export function updateSkills(skills) {
	return {
		type: types.UPDATE_SKILLS_VACANCY,
		skills
	};
}

export function updateLanguages(languages) {
	return {
		type: types.UPDATE_LANGUAGES_VACANCY,
		languages
	};
}

export const setSubSectors = subSectors => ({
	type: types.SET_SEARCH_SUBSECTORS_VACANCY,
	subSectors
});

export function updateFilter(
	filter = {},
	fetch = true,
	isPage = false,
	reset = false
) {
	return dispatch => {
		dispatch(_updateFilter(filter));
		if ((filter.id && !isPage) || reset) {
			browserHistory.replace("/vacancies/search");
			delete filter.id;
		}
		if (fetch) dispatch(fetchJobs(filter, isPage));
	};
}

export function handleSkillChange(skill, addedSkills, filter, toggle = false) {
	return dispatch => {
		let found = -1;
		for (const [index, sk] of addedSkills.entries()) {
			if (sk.name === skill) {
				found = index;
				break;
			}
		}
		if (found === -1) {
			filter.skills.push(skill);
			addedSkills.push({ name: skill, active: true });
		} else {
			if (toggle) {
				if (addedSkills[found].active) {
					filter.skills = filter.skills.filter(sk => sk !== skill);
				} else {
					filter.skills.push(skill);
				}
				addedSkills[found].active = !addedSkills[found].active;
			}
		}
		dispatch(updateSkills(addedSkills));
		dispatch(updateFilter(filter));
	};
}

export function resetFilter(term = "", userDistance) {
	const filter = {
		title: term,
		category: null,
		subcategory: null,
		function: null,
		skills: [],
		seniority: [],
		location: {
			distance: `${userDistance}km`,
			lat: 0,
			lon: 0
		},
		languages: [],
		offset: 0
	};

	return dispatch => {
		dispatch(updateFilter(filter, true, false, true));
		dispatch(resetLanguages());
		dispatch(resetSkills(filter));
	};
}

export function fetchJobs(filter = {}, isPage) {
	return dispatch => {
		if (!isPage) filter.offset = 0;
		if (!filter.estimation || !filter.estimation.gte) {
			delete filter.estimation;
			delete filter.estimation_type;
		}
		return dispatch(
			AxiosHelper.__post({
				data: filter,
				url: SEARCH_PROFILE_ENDPOINT,
				REQUEST_SUCCESS: types.FETCHED_JOBS_VACANCY,
				loadingText: "Loading Profiles..."
			})
		);
	};
}

export function getSectors() {
	return dispatch =>
		dispatch(
			AxiosHelper.__post({
				url: LIST_SECTORS,
				loadingText: "Loading sectors...",
				next: data => {
					return dispatch({ type: types.SET_SEARCH_SECTORS_VACANCY, data });
				}
			})
		);
}

export function getFunctions(sector) {
	return dispatch =>
		dispatch(
			AxiosHelper.__post({
				url: LIST_FUNCTIONS,
				data: {
					no_limit: true,
					sector_id: sector._id
				},
				REQUEST_SUCCESS: types.SET_SEARCH_FUNCTIONS_VACANCY,
				loadingText: "Loading functions..."
			})
		);
}

export function fetchPoke(payload) {
	return AxiosHelper.__post({
		url: FETCH_POKE_ENDPOINT,
		data: payload,
		next: data => {
			return {
				type: types.FETCH_POKE_DATA,
				data
			};
		}
	});
}

export function addNotePoke(payload) {
	return AxiosHelper.__post({
		url: ADD_POKE_NOTE,
		data: payload,
		isModal: true,
		next: () => fetchPoke({ id: payload.poke_id })
	});
}

export function addNoteBid(payload) {
	return AxiosHelper.__post({
		url: ADD_NOTE_BID,
		data: payload,
		isModal: true
	});
}

export function toggleNotesModal(modal = null) {
	return {
		type: types.TOGGLE_NOTES_MODAL,
		modal
	};
}
export function toggleProposeInterviewModal(modal = null) {
	return {
		type: types.TOGGLE_PROPOSE_INTERVIEW_MODAL,
		modal
	};
}

export function toggleCancelInterviewModal(modal = null) {
	return {
		type: types.TOGGLE_CANCEL_INTERVIEW_MODAL,
		modal
	};
}

export function interviewDispatch(
	payload,
	is_decline = false,
	is_edit = false
) {
	return AxiosHelper.__post({
		data: payload,
		isModal: true,
		url: is_decline
			? DECLINE_INTERVIEW
			: is_edit
			? EDIT_INTERVIEW
			: INVITE_USER_TO_INTERVIEW,
		next: () => fetchPoke({ id: payload.application_id }),
		toastMessage: is_decline
			? "New interview date has been successfully proposed"
			: "Interview was successfully sent"
	});
}

export function acceptInterview(data, nextAction) {
	return AxiosHelper.__post({
		data,
		url: ACCEPT_INTERVIEW,
		isModal: true,
		next: () => {
			nextAction && nextAction();
			return fetchPoke({ id: data.poke_id });
		},
		toastMessage: "Invitation accepted"
	});
}

export function cancelInterview(data) {
	return AxiosHelper.__post({
		data,
		url: CANCEL_INTERVIEW,
		isModal: true,
		loadingText: "Canceling the interview...",
		next: () => fetchPoke({ id: data.poke_id }),
		toastMessage: "Interview successfully cancelled"
	});
}

export function refuseInterview(data) {
	return AxiosHelper.__post({
		data,
		url: REFUSE_INTERVIEW,
		isModal: true,
		loadingText: "...",
		next: () => fetchPoke(data.bid_id),
		toastMessage: "Interview successfully cancelled"
	});
}

export function rejectApplication(payload) {
	return AxiosHelper.__post({
		url: REJECT_APPLICATION_ENDPOINT,
		next: () => fetchPoke(payload.bid_id),
		toastMessage: "The application has been rejected.",
		isModal: true,
		data: payload
	});
}

export function getPokesListExp(job_id) {
	return AxiosHelper.__post({
		url: GET_JOBS_BY_VACANCY,
		data: { id: job_id },
		next: data => setPokesList(data.poke_list)
	});
}

export const setPokesList = data => ({
	type: types.SET_POKES_LIST,
	data
});

export const showVideoConf = () => ({
	type: types.SHOW_VIDEO_CONF
});

export const hideVideoConf = () => ({
	type: types.HIDE_VIDEO_CONF
});

export function toggleInterviewModal(modal = null) {
	return {
		type: types.TOGGLE_INTERVIEW_MODAL,
		modal
	};
}
export const toggleRejectApplicationModal = () => ({
	type: types.TOGGLE_REJECT_MODAL
});

export function cleanState() {
	return {
		type: types.CLEAN_STATE
	};
}
