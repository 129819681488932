import React, { useState, useEffect } from "react";
import { Line } from "rc-progress";
import { Controller } from "react-hook-form";
import Select from "react-select";
import cx from "classnames";
import { useSelector } from "react-redux";
import MembersSelect from "modules/Tasks/components/AddNewTask/Members/MemberSelect.js";
import useListColleagues from "../../../../api/useListColleagues";
import s from "./upload-box.module.scss";
import { ReactComponent as ClearIcon } from "static/icons/rounded-clear-icon.svg";
import useFilterSettings from "../../../../api/useFilterSetting";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import _find from "lodash/find";

const FileCard = ({
	file,
	control,
	sibbling,
	onRemoveFile,
	progress,
	errors,
	isFiletypesInputReadonly,
	item
}) => {
	const [selectedMembers, setSelectedMembers] = useState([]);
	const connectedUser = useSelector(onlineUserSelector);
	const { data: colleagues } = useListColleagues({ tag: "list_client_task" });

	useEffect(() => {
		if (Boolean(colleagues?.length) && !item?.allowed_users) {
			const user = _find(colleagues, ["_id", connectedUser?.company_owner]);
			setSelectedMembers([user]);
		} else if (item?.allowed_users) {
			setSelectedMembers(item?.allowed_users);
		}
	}, [file, colleagues, item]);

	const { data: fileTypes, isLoading } = useFilterSettings(null, {
		tag: "list_files_type"
	});

	return (
		<div className={cx(s.fileCardContainer)}>
			<div className={cx(s.row)}>
				<h6>{file?.name}</h6>
				<button onClick={onRemoveFile}>
					<ClearIcon />
				</button>
			</div>
			<div className={cx(s.row)}>
				<Controller
					name={`${sibbling}.allowed_users`}
					control={control}
					render={({ field: { onChange, value } }) => (
						<MembersSelect
							value={value}
							onChange={onChange}
							data={colleagues}
							isLoading={false}
							setIncludedItems={setSelectedMembers}
							includedItems={selectedMembers}
							selectTitle="Share with team members"
							inputPlaceholder="Search a member..."
						/>
					)}
				/>
				<Controller
					name={`${sibbling}.document_type`}
					control={control}
					render={({ field: { onChange, value } }) => {
						return (
							<div>
								<Select
									className={cx(s.select, {
										[s.selectError]: Boolean(errors?.document_type?.message)
									})}
									onChange={onChange}
									clearable={false}
									options={fileTypes?.files_type || []}
									value={value}
									isLoading={isLoading}
									disabled={isFiletypesInputReadonly}
								/>
								{errors?.document_type?.message && (
									<div className={s.error}>{errors.document_type.message} </div>
								)}
							</div>
						);
					}}
				/>
			</div>
			<Line
				percent={progress}
				strokeWidth="0.5"
				strokeColor="#00BA88"
				trailColor="white"
				className={s.progressLine}
			/>
		</div>
	);
};

export default FileCard;
