import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import loadable from "loadable-components";

const TermsAndConditionsText = loadable(() =>
	import(
		/* webpackChunkName: "termsandconditionstext" */ "./termsAndConditions/components/TermsAndConditionsText"
	)
);

const PrivacyPolicyText = loadable(() =>
	import(
		/* webpackChunkName: "privacypolicytext" */ "./privacyPolicy/components/PrivacyPolicyText"
	)
);

class AcceptTermsAndPolicyModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			actionsSectionClass: ""
		};
		this.acceptHandler = this.acceptHandler.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	closeModal(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.props.onModalToggle();
	}

	acceptHandler() {
		this.props.acceptHandler();
		this.props.onModalToggle();
	}

	handleScroll() {
		if (
			!this.state.actionsSectionClass &&
			this._termsConditions.scrollTop >=
				this._termsConditions.scrollHeight - 500
		) {
			this.setState({
				actionsSectionClass: "show"
			});
		}
	}

	render() {
		const { title, mode } = this.props;
		return (
			<BodyClassName className="no-scroll">
				<div className="static-page modal-terms-policy">
					<div className="modal-section modal-terms">
						<div className="title">{title}</div>
						<div className="close-btn">
							<span className="close" onClick={this.closeModal}>
								×{" "}
							</span>
						</div>
						<div className="content">
							<div className="static-page-container">
								<div
									ref={ref => (this._termsConditions = ref)}
									onScroll={this.handleScroll}
								>
									{mode === "terms" ? (
										<TermsAndConditionsText />
									) : (
										<PrivacyPolicyText />
									)}
								</div>
							</div>
							<div
								className={`modal-actions ${this.state.actionsSectionClass}`}
							>
								<button
									className="btn btn-primary"
									onClick={this.acceptHandler}
								>
									accept & continue
								</button>
								<button
									className="btn btn-white large"
									onClick={this.closeModal}
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
					<div className="backdrop" />
				</div>
			</BodyClassName>
		);
	}
}

export default AcceptTermsAndPolicyModal;
