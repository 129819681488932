import create from "zustand";
const initialState = {
	drawerIsOpen: false
};
const useOpenDrawerSupplier = create(set => ({
	...initialState,
	toggleDrawer: value => set(() => ({ drawerIsOpen: value }))
}));

export default useOpenDrawerSupplier;
