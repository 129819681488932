import styled from "styled-components";
import { colors } from "config/styles";

export const BoxWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 83vh;
	z-index: 1000;
	position: fixed;
`;

export const Backdrop = styled.div`
	width: 100vw;
	height: 110vh;
	background-color: #000;
	opacity: 0.5;
	z-index: 1;
	position: absolute;
`;

export const GiftBox = styled.div`
	height: 460px;
	width: 750px;
	background: ${colors.white};
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 3px;
	z-index: 2;
`;

export const GiftBoxIcon = styled.img`
	width: 270px;
	margin: 34px auto;
`;

export const GiftBoxTitle = styled.div`
	font-size: 26px;
	font-weight: 300;
	color: ${colors.anchorsColor};
	margin-bottom: 18px;
`;

export const GiftBoxContent = styled.div`
	font-size: 15px;
	font-weight: 400;
	color: ${colors.inputColor};
	padding: 0 60px;
	text-align: center;
	margin-bottom: 50px;
`;

export const CloseButton = styled.div`
	position: absolute;
	top: 20px;
	right: 25px;
	font-size: 21px;
	font-weight: 700;
	line-height: 1;
	color: ${colors.black};
	text-shadow: 0 1px 0 ${colors.white};
	opacity: 0.2;
	cursor: pointer;
	border: none;
	&:hover,
	&:focus {
		text-decoration: none;
		opacity: 0.5;
	}
`;

export const FunctionBox = styled.div`
	min-height: 130px;
`;

export const ButtonConfirmPicture = styled.button`
	display: flex;
	border-radius: 2.5px;
	background-color: ${colors.successColor};
	margin-right: 25px;
	padding: 15px;
	color: ${colors.white};
	align-items: center;
	text-transform: uppercase;
	border: none;
	width: 220px;
	text-align: center;
	justify-content: center;
	position: relative;
	.text {
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		margin-left: 15px;
	}
	.icon-check {
		font-size: 23px;
		position: absolute;
		left: 17px;
	}
`;

export const ButtonRetakePicture = styled.button`
	margin-left: 25px;
	padding: 13.5px 20px;
	background-color: transparent;
	border: 1.5px solid ${colors.TryAgainBorderColor};
	border-radius: 1.5px;
	text-transform: uppercase;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: ${colors.TryAgainBorderColor};
`;
