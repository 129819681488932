import toaster from "common/Toaster";
import { client } from "lib/api-client";
import { useMutation } from "react-query";
import { REQUEST_VIEW_KANBAN_MOVE_ELEMENT } from "config/api-endpoints";

export default function useTempKanbanMoveElement() {
	return useMutation(
		body => client(REQUEST_VIEW_KANBAN_MOVE_ELEMENT, { body }),
		{
			onError: () => {
				toaster.danger("Something went wrong. Please try again!");
			}
		}
	);
}
