import React from "react";
import styles from "./request-details-card-empty-state.module.scss";
import { ReactComponent as FliesIcon } from "static/icons/flies-icon.svg";

const RequestDetailsCardEmptyState = () => {
	return (
		<div className={styles.container}>
			<div className={styles.text}>Start by selecting a request</div>
			<FliesIcon />
		</div>
	);
};

export default RequestDetailsCardEmptyState;
