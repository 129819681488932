import React from "react";
import { ReactComponent as CompassIcon } from "static/icons/compass.svg";
import { ReactComponent as MagicWandIcon } from "static/icons/magic-wand-02.svg";
import { v4 as uuid } from "uuid";
export const filtersRenderMap = {
	source: "renderLabelValues",
	employment_type: "renderLabelValues",
	currency: "renderLabelValues",
	payroll_country: "renderLabelValues",
	salary_type: "renderLabelValues",
	payment_type: "renderLabelValues",
	extra_benefits: "renderLabelValues",
	category: "renderLabelValues",
	subcategory: "renderLabelValues",
	seniority: "renderLabelValues",
	profile_tags: "renderLabelValues",
	created_by: "renderAvatarValues",
	languages: "renderLabelValuesWithRating",
	notes: "renderBooleanValues",
	is_viewed: "renderBooleanValues",
	is_referred: "renderBooleanValues",
	is_activated: "renderBooleanValues",
	interviewed: "renderBooleanValues",
	residence: "renderLocationDescription",
	preferred_location: "renderLocationDescription",
	expected_salary: "renderSalaryValue",
	functions: "renderFunctionsValues",
	skills: "renderSkillsValues",
	created_at: "renderDateValues",
	followed: "renderBooleanValues"
};

export const TABS_IDS = {
	SMART_QUERY: "SMART_QUERY",
	SAVED_FILTERS: "SAVED_FILTERS",
	ADVANCED_FILTER: "ADVANCED_FILTER"
};
export const KEYWORDS_CARD = "KEYWORDS_CARD";
export const ADVANCED_FILTER_CARD = "ADVANCED_FILTER_CARD";
export const COMMUNITIES_FILTERS = "COMMUNITIES_FILTERS";
export const VACANCIES_FILTERS = "VACANCIES_FILTERS";
export const SMART_QUERY_CARD = "SMART_QUERY_CARD";
export const SORT_CHANGE = "SORT_CHANGE";
export const LIMIT_CHANGE = "LIMIT_CHANGE";
export const OFFSET_CHANGE = "OFFSET_CHANGE";

export const SMART_QUERY_TRANSITION_TIP = "SMART_QUERY_TRANSITION_TIP";
export const ADVANCED_FILTER_TRANSITION_TIP = "ADVANCED_FILTER_TRANSITION_TIP";

export const TABS_LIST = [
	{
		id: TABS_IDS.ADVANCED_FILTER,
		label: "Advanced filter",
		tooltip:
			"Advanced filter typically goes beyond basic filtering options and provides users with more complex criteria for refining data. This can include multiple conditions, logical operators (AND, OR) and other advanced filtering mechanisms. It aims to allow user to filter data based on multiple criteria such as date range, numerical values, or specific text patterns."
	},
	{
		id: TABS_IDS.SMART_QUERY,
		label: "Smart Query",
		tooltip:
			"The smart query builder filter is a powerful tool designed to empower users in constructing complex queries for specific profiles. This feature incorporates logical operators, allowing users to set conditions and specify relationships between different profile attributes. The builder stands out by providing unparalleled flexibility, high precision, and a user-friendly interface, enabling users to navigate seamlessly and efficiently."
	},
	{
		id: TABS_IDS.SAVED_FILTERS,
		label: "Saved filters"
	}
];

export const operators = [
	{
		value: "(",
		label: "(",
		isParentheses: true
	},
	{
		value: ")",
		label: ")",
		isParentheses: true
	},
	{
		_id: uuid(),
		value: "and",
		label: "AND",
		isOperator: true
	},
	{
		_id: uuid(),

		value: "or",
		label: "OR",
		isOperator: true
	}
];

export const LOGICAL_OPERATORS = {
	and: { label: "and", value: "and" },
	or: { label: "or", value: "or" }
};

export const DATA_SOURCE_OPTIONS = {
	internalData: {
		label: "My Data",
		value: "internal_data"
	},
	candidateProfile: {
		label: "Candidates Data",
		value: "candidate_profile"
	},
	all: {
		label: "All",
		value: "all"
	}
};

export const COMMUNITIES_HEADER_DATA = {
	type: "communities",
	description:
		"Easily find, modify and apply your saved filters for a smooth and efficient experience.",
	icon: <CompassIcon />
};

export const VACANCIES_HEADER_DATA = {
	type: "vacancies",
	description:
		"Quickly find job matches with ready-to-use filters based on job criteria.",
	icon: <MagicWandIcon />
};

export const getFilterDrawerCardProps = type => {
	const isCommunitiesType = type === COMMUNITIES_HEADER_DATA.type;
	return {
		description: isCommunitiesType
			? COMMUNITIES_HEADER_DATA.description
			: VACANCIES_HEADER_DATA.description,
		iconHeader: isCommunitiesType
			? COMMUNITIES_HEADER_DATA.icon
			: VACANCIES_HEADER_DATA.icon,
		id: isCommunitiesType ? COMMUNITIES_FILTERS : VACANCIES_FILTERS
	};
};

export const CONDIDATE_TEMPLATE_TYPE = {
	COMMUNITIES: "communities",
	VACANCIES: "vacancies"
};
export const DATE_LANG = "en-BE";

export const OPERATORS = {
	is: { value: "is", label: "is" },
	isNot: { value: "is_not", label: "is not" },
	gt: { value: "gt", label: "is greater than" },
	between: { value: "range", label: "between" },
	contains: { value: "contains", label: "contains" },
	notContains: { value: "not_contains", label: "does not contain" },
	anyOf: { value: "is_any", label: "is any of" },
	isEmpty: { value: "is_empty", label: "is empty" },
	before: { value: "before", label: "before" },
	after: { value: "after", label: "after" },
	eq: { value: "is", label: "equals" },
	lessThan: { value: "<", label: "is less than" },
	greaterThan: { value: ">", label: "is greater than" },
	startWith: { value: "begins_with", label: "starts with" },
	lessThanOrEqual: { value: "<=", label: "is less than or equal" },
	greaterThanOrEqual: { value: "=>", label: "is greater than or equal" },
	keyword: { value: "keyword", label: "Boolean strings" }
};
