import styled, { css } from "styled-components";
import { Element } from "react-scroll";
import { colors } from "config/styles";

export const ModalElement = styled(Element)`
	background-color: ${colors.white};
	clear: both;
	border-radius: ${props => (props.borderRadius ? props.borderRadius : "6px")};
	padding: ${props => (props.padding ? props.padding : "30px")};
	margin: auto;
	max-width: ${props => props.maxwidth};
	max-height: ${props => props.maxheight};
	min-height: ${props => props.minHeight};
	height: ${props => props.height};
	position: relative;
	width: ${props => (props.large ? "1200px" : props.size ? props.size : "770px")};
	box-shadow: ${props => props.boxShadow};
	border: ${props => props.border};
	&.intro-modal {
		width: 750px;
		height: 425px;
		position: relative;
		display: flex;
		flex-direction: column;
		-webkit-box-align: center;
		align-items: center;
		z-index: 2;
		background: rgb(255, 255, 255);
		border-radius: 3px;
	}
	&#candidate-info{
		.content{
			.error_phone{
				input{
					border:1px solid ${colors.primaryColor};
				}
			}
		}
	}
	&.intro-modal-permanent {
		width:auto;
		position: relative;
		display: flex;
		flex-direction: column;
		-webkit-box-align: center;
		align-items: center;
		z-index: 2;
		background: rgb(255, 255, 255);
		border-radius: 3px;
		.content{
			line-height: 2;
		}
		svg{
			width: 100px;
			margin-bottom: 30px;
			margin-top: 30px;
}
		}
		.title-intro{
			font-size: 16px;
			font-weight: bold;
			line-height: 0;
		}
		p.content-first-siginin{
			font-size: 13px;
			font-weight: 500;
			width: 560px;
    		line-height: 1.7;
		}
	}
	${props =>
		props.large &&
		css`
			height: 900px;
		`};
	.title-intro {
		font-size: 16px;
		font-weight: 500;
		margin-top: 12px;
		margin-bottom: 20px;
	}
	.stepsActions {
		margin-top: 50px;
		div:first-of-type {
			float: right;
			margin-left: 15px;
		}
		button {
			text-transform: uppercase;
		}
	}
	&.CancelInterviewModal {
		.actions {
			margin-top: 22px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-end;
			align-items: stretch;
			align-content: stretch;
		}
	}
	&.alignButtonModalRight {
		.actions {
			margin-top: 22px;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-end;
			align-items: stretch;
			align-content: stretch;
		}
	}
`;

export const ContentHolder = styled.div`
	padding: 7px 0 20px;
	background-color: ${props =>
		(props.type === "error" && colors.dangerBg) ||
		(props.type === "warning" && colors.primaryColor) ||
		(props.type === "timesheet-warning" && "#F9A937") ||
		(props.type === "confirmation" && colors.greenModalBgColor)};

	a {
		color: ${colors.white};
	}
`;
export const ConfirmationTitle = styled.h3`
	padding: 35px 30px 18px;
	margin: 0;
	font-size: 22px;
	font-weight: 400;
	line-height: 1.2;
	color: ${colors.white};
`;

export const ConfirmationP = styled.p`
	padding: 0 30px;
	font-size: 14px;
	display: block;
	font-weight: 400;
	line-height: 22px;
	color: ${colors.white};
`;

export const Backdrop = styled.div`
	background-color: ${props =>
		props.backgroundColor || "rgba(57, 65, 72, 0.81)"};
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: ${props => props["z-index"] || props.zIndexOverlay || 10000};
	overflow: auto;
	display: flex;
	align-items: flex-start;
`;

export const ModalTitle = styled.div`
	color: ${colors.primaryColor};
	${props =>
		props.centered
			? css`
					text-align: center;
			  `
			: ``};
	font-size: 30px;
	font-weight: 300;
	margin-bottom: 30px;
	line-height: 100%;
	&.adressBoxNextTitle {
		font-family: "basierCircle";
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 24px;
		letter-spacing: -0.5px;
		color: #171717;
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 20px;
	}
`;

export const ModalCloseBtn = styled.div`
	position: absolute;
	top: 15px;
	right: 15px;
	font-size: 21px;
	font-weight: 700;
	line-height: 1;
	color: ${colors.lightDarkGray};
	text-shadow: 0 1px 0 ${colors.white};
	cursor: pointer;
	z-index: 100;
	.closeModal {
		padding: 0;
		svg {
			width: 10px;
			height: 10px;
			path {
				fill: #000;
			}
		}
		&:hover {
			background-color: transparent;
		}
	}
	button {
		width: 32px;
		height: 32px;
		display: inline-flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: center;
		justify-content: center;
		outline: none;
		border-radius: 0.25rem;
		transition: all 0.2s ease 0s;
		flex: 0 0 auto;
		border: none;
		background: none;
		color: #a8aaac;
		&.modalStyleButton {
			color: #fff;
		}
		&:hover {
			background-color: rgba(0, 0, 0, 0.06);
		}
		&:active {
			background-color: rgba(0, 0, 0, 0.08);
		}
	}
`;

export const ConfirmationModal = styled(ModalElement)`
	line-height: 1.7;
	font-size: 13.3px;
	border-radius: 2px;
	padding: 0;
	width: 530px;
	min-height: ${props => props.minHeight};

	.actions {
		background-color: ${colors.white};
		padding: 30px;
		overflow: visible;
		width: 100%;

		button {
			font-weight: 400;
			font-size: 14px;
			height: 51px;
		}
	}
`;

export const FloatItem = styled.div`
	float: ${props => (props.showButtonOnRight ? "right" : "left")};
	margin: ${props => props.margin || 0};
`;

export const ModalIntro = styled.div`
	display: block;
	color: ${colors.introColor};
	padding: 8px 0;
	font-size: 14px;
	font-weight: normal;
	border-radius: 3px;
	word-wrap: normal;
	margin: 0 0 15px;
	white-space: normal;
	width: 100%;
`;

export const ModalConfirmMessage = styled.div`
	font-size: 12.5px;
	color: ${colors.inputColor};
	margin-bottom: 30px;
	font-weight: 400;
`;

export const ActionsContainer = styled.div`
	overflow: visible;
	.float-right-btn {
		float: right;
	}
`;

export const Clear = styled.div`
	clear: both;
`;

export const WarningText = styled.div`
	font-size: 16px;
	line-height: 150%;
	padding: 0 30px 20px;
	color: ${colors.white};
	text-transform: lowercase;
	display: flex;
	align-items: center;
	span::first-letter {
		text-transform: uppercase;
	}
`;

export const WarningIcon = styled.i`
	font-size: 30px;
	margin-right: 22px;
`;
