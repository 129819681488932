import create from "zustand";
import { REMINDER_BEFORE } from "config";
import { PRIORITY_OPTIONS } from "common/AddTaskDrawerCompound/constants";

const initialState = {
	formValues: {
		vacancy: { value: "" },
		template: { value: "" }
	},
	drawerFormValues: {
		task_title: "",
		member_internal: [],
		member_external: [],
		description_task: "",
		completed_task: false,
		selected_entity: undefined,
		select_reminder: REMINDER_BEFORE[0],
		select_priority: PRIORITY_OPTIONS[0].value,
		select_linkTo: { value: "candidate", label: "Candidate" }
	}
};

const store = set => ({
	...initialState,
	setFormValues: formValues => set(state => ({ ...state, formValues })),
	setDrawerFormValues: drawerFormValues =>
		set(state => ({ ...state, drawerFormValues })),
	resetFormValues: () => set(() => initialState)
});

const useFormStoreAssignEvaluation = create(store);
export default useFormStoreAssignEvaluation;
