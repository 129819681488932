import React, { useEffect, useState, useMemo, memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import _get from "lodash/get";
import _findIndex from "lodash/findIndex";
import styled from "styled-components";

import Row from "./Row";
import Modal from "common/modal";
import ConfirmationModal from "common/modal/ConfirmationModal";
import {
	addFunction,
	removeFunction,
	updateFunction,
	updateMainFunction,
	resetFunctions,
	setFunctions,
	getSectors
} from "modules/job/actions/vacancyFunctionsActions";
import {
	vacancyNextFunctionsSelector,
	vacancySectorsSelector,
	hasMadeChangesSeclector,
	removedCategoriesWithSkillsSelector
} from "modules/job/selectors/vacancyFunctionsSelectors";

const ErrorMessage = styled.div`
	background-color: #ffedee;
	border: 1px solid #ffacb1;
	color: #ab686c;
	border-radius: 3px;
	font-size: 14px;
	width: 40%;
	margin: 20px 30px;
	display: flex;
	align-items: center;
	padding: 15px 0;
	ul {
		margin: 0;
	}
`;

const LinkButton = styled.button`
	background-color: transparent;
	border: none;
	color: #ff6849;
	outline: none;
	text-transform: uppercase;
	padding: 5px 0;
`;

const MAX_FUNCTIONS = 5;

function FunctionsModal({
	onClose,
	functions,
	nextFunctions,
	sectors,
	rawSectors,
	getSectors,
	addFunction,
	removeFunction,
	updateFunction,
	updateMainFunction,
	onSave,
	resetFunctions,
	setFunctions,
	skillsCategories,
	removeSkills,
	isMainRequired = true,
	isSearch = false,
	closeOnSave = true,
	maxAllowedFunctions = MAX_FUNCTIONS,
	saveActionName,
	isFreelancer = false,
	isOnline = true,
	userId
}) {
	const [hasError, setHasError] = useState(false);
	const [showWarning, setShowWarning] = useState(false);

	const hasIncompleteFunctions = useMemo(
		() => nextFunctions.some(f => !f._id || !f.seniority),
		[nextFunctions]
	);

	const hasNoMain = useMemo(() => nextFunctions.every(f => !f.is_main), [
		nextFunctions
	]);

	const hasNoCategory = useMemo(
		() => nextFunctions.some(f => !f.sector.parent_sector._id),
		[nextFunctions]
	);

	const getNamedCategories = useMemo(
		() =>
			functions.reduce((acc, fun) => {
				const index = _findIndex(
					nextFunctions,
					f => fun.sector.parent_sector._id === f.sector.parent_sector._id
				);
				if (
					index === -1 &&
					_get(skillsCategories, "length", []) > 0 &&
					skillsCategories.indexOf(fun.sector.parent_sector._id) !== -1
				) {
					acc.push(fun.sector.parent_sector.name);
				}
				return acc;
			}, []),
		[nextFunctions]
	);

	const getCategoriesToDeleteFromSearch = () => {
		let categories = {};
		let categoriesToDelete = [];
		nextFunctions.map(f => {
			if (!categories[f.sector.parent_sector._id]) {
				categories[f.sector.parent_sector._id] = f.sector.parent_sector.name;
			}
		});
		for (let category in skillsCategories) {
			if (!categories[category]) {
				categoriesToDelete.push(skillsCategories[category]);
			}
		}
		return categoriesToDelete;
	};

	useEffect(() => {
		getSectors();
		setFunctions(functions);
	}, [getSectors]);

	const save = () => {
		if (hasIncompleteFunctions || (isMainRequired && hasNoMain)) {
			if (!isSearch) {
				return setHasError(true);
			}
			if (isSearch && hasNoCategory) {
				return setHasError(true);
			}
		}

		if (isSearch) {
			if (!nextFunctions.length) {
				return setHasError(true);
			}
			if (
				nextFunctions.length > 0 &&
				getCategoriesToDeleteFromSearch().length > 0 &&
				!showWarning
			) {
				return setShowWarning(true);
			} else if (showWarning) {
				removeSkills(getCategoriesToDeleteFromSearch());
			}
		} else {
			if (getNamedCategories.length && !showWarning) {
				return setShowWarning(true);
			}
			if (showWarning) {
				removeSkills(getNamedCategories);
			}
		}
		onSave(nextFunctions, userId);

		return closeOnSave && onBeforeClose();
	};

	const onBeforeClose = () => {
		resetFunctions();
		onClose();
	};

	const update = fnc => updateFunction(fnc);
	const remove = fnc => removeFunction(fnc.id);

	const renderRow = fun => {
		const category = _get(fun, "sector.parent_sector._id");
		const subSectors = category
			? sectors.reduce((acc, sector) => {
					if (sector._id === category) {
						acc = sector.children.map(child => ({
							value: child._id,
							label: child.name
						}));
					}
					return acc;
			  }, [])
			: [];
		return (
			<Row
				fnc={fun}
				key={fun.id}
				onUpdate={update}
				onRemove={remove}
				sectors={sectors}
				rawSectors={rawSectors}
				subSectors={subSectors}
				onMainChange={handleMainChange}
				usedFunctions={nextFunctions
					.map(f => ({ function: f._id, seniority: f.seniority }))
					.filter(Boolean)}
				hasError={hasError}
				isMainRequired={isMainRequired}
				isFreelancer={isFreelancer}
				isOnline={isOnline}
			/>
		);
	};

	const handleMainChange = _id => {
		updateMainFunction(_id);
	};

	return (
		<>
			{!showWarning && (
				<Modal
					id="functions-modal-vacancy"
					title="Functions"
					onClose={onBeforeClose}
					size={"1100px"}
					fixed={true}
					variant={"fnc"}
					firstButton={{
						action: save,
						type: "primary",
						label: `${
							saveActionName
								? saveActionName
								: !isSearch
								? "Save to vacancy"
								: "Save to search"
						}`
					}}
				>
					<div className="content col-md-12">
						<table
							className={`table modal-table ${
								isMainRequired ? "" : "main-not-required"
							}`}
						>
							<thead>
								<tr>
									{isMainRequired && (
										<th width="3%" style={{ paddingLeft: 30 }}>
											Main
										</th>
									)}
									<th width="26%">Category</th>
									<th width="26%">Sub-category</th>
									<th width="26%">Function</th>
									<th width="17%">Seniority</th>
									<th width="3%" />
								</tr>
							</thead>
							<tbody>{nextFunctions.map(fnc => renderRow(fnc))}</tbody>
						</table>
						<div
							className={`add-function ${
								nextFunctions.length < maxAllowedFunctions
									? ""
									: "hidden-button"
							}`}
						>
							{nextFunctions.length < maxAllowedFunctions && (
								<LinkButton onClick={addFunction}>
									<i className="fa fa-plus" /> Add a function
								</LinkButton>
							)}
							<div className={"counter"}>
								{nextFunctions.length} / {maxAllowedFunctions}
							</div>
						</div>
						<div className="clearfix" />
						{hasError &&
							((isMainRequired && hasNoMain) || hasIncompleteFunctions) && (
								<ErrorMessage>
									<ul>
										{hasIncompleteFunctions && (
											<li>Some functions are incomplete!</li>
										)}
										{isMainRequired && hasNoMain && (
											<li>At least one main function should be chosen!</li>
										)}
									</ul>
								</ErrorMessage>
							)}
					</div>
				</Modal>
			)}
			{showWarning && (
				<ConfirmationModal
					key="modal"
					title={`Are you sure you want to proceed?`}
					onClose={() => setShowWarning(false)}
					type="error"
					content={
						<div>
							Doing this will clear the skills selected for the following
							categories:
							{isSearch ? (
								<ul>
									{getCategoriesToDeleteFromSearch().map(c => (
										<li key={c}>{c}</li>
									))}
								</ul>
							) : (
								<ul>
									{getNamedCategories.map(c => (
										<li key={c}>{c}</li>
									))}
								</ul>
							)}
						</div>
					}
					firstButton={{
						action: save,
						type: "danger",
						label: `YES, I'm sure`
					}}
				/>
			)}
		</>
	);
}

FunctionsModal.propTypes = {
	functions: PropTypes.array,
	subSectors: PropTypes.array,
	onClose: PropTypes.func,
	sector: PropTypes.object,
	dispatch: PropTypes.func,
	userId: PropTypes.string,
	companyId: PropTypes.string
};

const selector = formValueSelector("functions-info");

const mapStateToProps = state => {
	return {
		nextFunctions: vacancyNextFunctionsSelector(state),
		categoryValue: selector(state, "category"),
		subCategoryValue: selector(state, "subcategory"),
		sectors: vacancySectorsSelector(state),
		rawSectors: vacancySectorsSelector(state),
		hasMadeChanges: hasMadeChangesSeclector(state),
		removedCategoriesWithSkills: removedCategoriesWithSkillsSelector(state)
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			addFunction,
			removeFunction,
			updateFunction,
			updateMainFunction,
			resetFunctions,
			setFunctions,
			getSectors
		},
		dispatch
	);

export default memo(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(
		reduxForm({
			form: "vacancy-functions-modal"
		})(FunctionsModal)
	)
);
