//TODO create hook to check whether feature multisite is enable or not
import React, { useEffect } from "react";
import Drawer from "rc-drawer";
import cx from "classnames";
import { uniq } from "lodash";
import { Scrollbars } from "react-custom-scrollbars";
import get from "lodash/get";
import { ReactComponent as QuiteIcon } from "static/icons/quite-icon.svg";
import s from "./publish-vacancy-confirm-drawer.module.scss";
import useConfirmationStore from "../../store/useConfirmationStore";
import CurrencyFormatter from "common/CurrencyFormater";
import { ReactComponent as CloseIcon } from "static/icons/Primary_close_icon.svg";
import usePublishJob from "../../api/usePublishJob";
import useCreateCampaign from "../../api/useCreateCampaign";
import { historyPush, searchParser } from "config/helpers";
import usePostingRequirementStore from "../../store/usePostingRequirementStore";
import Loader from "common/Loader";
import toaster from "common/Toaster";
import { HIREME_CHANNEL_ID, EUR } from "config";
import {
	useFakeSelectedChannels,
	usePublishVacancyTour
} from "modules/user-onboarding/workflows/publish-vacancy";
import { HOW_TO_PUBLISH_VACANCY } from "config/onboarding";
import { onPublishError } from "modules/publishJob/helper/helper";
import usePublishVacancyValidation from "hooks/usePublishVacancyValidation";

const channelsName = {
	my_candidates: "My candidates",
	my_job_page: "My job page",
	talent_pool: "Wiggli talent pool"
};

const PublishVacancyConfirmDrawer = ({
	id,
	contractSelectionBucket,
	channelSelectionBucket,
	isPublished,
	freeChannelSelectionBucket
}) => {
	const {
		open,
		setvalue,
		selectedChannels,
		add,
		dynamicFreeChannels,
		addDynamicFreeChannel
	} = useConfirmationStore();

	// fake channels used for onboarding
	const fakeSelectedChannels = useFakeSelectedChannels();
	const {
		freeChannels,
		products_specs,
		setvalue: setPostingRequirement,
		vacancy
	} = usePostingRequirementStore();

	const [publishJob, { isLoading }] = usePublishJob({
		onSuccess: () => {
			setvalue("open", false);
			toaster.success("Vacancy has successfully been published");
			historyPush(`/vacancy/${id}?view=performance`);
		},
		onError: err => {
			onPublishError(err);
		}
	});

	const [createCampaign, { isLoading: isRePublishing }] = useCreateCampaign({
		onSuccess: () => {
			setvalue("open", false);
			toaster.success("Vacancy has successfully been republished");
			historyPush(`/vacancy/${id}?view=performance`);
		},
		onError: err => {
			onPublishError(err);
		}
	});

	const [
		validatePublishVacancy,
		{ isLoading: validatePublishVacancyLoading }
	] = usePublishVacancyValidation();

	const { l } = searchParser();

	const onSubmit = () => {
		const action = isPublished ? createCampaign : publishJob;
		const payload = {
			vacancy,
			id,
			publish_types: {
				...freeChannels,
				my_job_page: {
					active: dynamicFreeChannels.length > 0,
					channel_ids: dynamicFreeChannels.map(item => {
						return item.product_id;
					})
				},
				external_candidates: {
					active:
						Boolean(channelSelectionBucket.state.selectedRows.length) ||
						Boolean(contractSelectionBucket.state.selectedRows.length),
					channel_ids: [
						...channelSelectionBucket.state.selectedRows,
						...uniq(products_specs.map(({ productId }) => productId))
					]
				}
			},
			publish_version: l,
			products_specs: products_specs.map(elem => ({
				contractId: elem.contractId,
				postingRequirements: elem.postingRequirements,
				productId: elem.productId
			}))
		};
		if (selectedChannels.length > 1 || products_specs.length > 0) {
			validatePublishVacancy(payload, {
				onSuccess: () => {
					action(payload);
				},
				onError: err => {
					onPublishError(err);
				}
			});
		} else {
			action(payload);
		}
	};

	const handleFreeChannelDeleteClick = elem => {
		setPostingRequirement("freeChannels", {
			...freeChannels,
			[elem]: false
		});
		freeChannelSelectionBucket.actions.row(
			elem,
			Boolean(contractSelectionBucket.state.selectedRows.length)
		);
	};
	const handleChannelDeleteClick = item => {
		add(item, Boolean(contractSelectionBucket.state.selectedRows.length));
		channelSelectionBucket.actions.row(
			item.product_id,
			Boolean(contractSelectionBucket.state.selectedRows.length)
		);
	};

	const handleContractDeleteClick = id => {
		setPostingRequirement(
			"products_specs",
			products_specs.filter(contract => contract.contractId !== id)
		);
		contractSelectionBucket.actions.row(id);
	};

	usePublishVacancyTour({
		isPublishDrawerOpen: open
	});

	useEffect(() => {
		if (
			open &&
			Object.keys(freeChannels).filter(channel => freeChannels[channel])
				.length === 0 &&
			dynamicFreeChannels.length === 0 &&
			products_specs.length === 0 &&
			selectedChannels.length === 0 &&
			fakeSelectedChannels.length === 0
		) {
			setvalue("open", false);
		}
	}, [
		freeChannels,
		dynamicFreeChannels,
		products_specs,
		selectedChannels,
		fakeSelectedChannels,
		open
	]);

	return (
		<Drawer
			open={open}
			width={`650px`}
			height={"100%"}
			placement={"right"}
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={() => setvalue("open", false)}
			classname="addContract"
		>
			{(isLoading || isRePublishing || validatePublishVacancyLoading) && (
				<div className={s.loaderContainer}>
					<Loader />
				</div>
			)}
			<div
				className={cx(s.container, {
					[s.isBlur]:
						isLoading || isRePublishing || validatePublishVacancyLoading
				})}
			>
				<div className={s.header}>
					<label>selected channels </label>
					<button onClick={() => setvalue("open", false)}>
						<QuiteIcon />{" "}
					</button>
				</div>
				<div className={s.subHeader}> Confirm campaign related costs</div>
				<div className={s.subHeader2}>
					<div>You are about to publish your vacancy on job boards.</div>
					<div>This will induce the following costs:</div>
				</div>
				<div className={s.scrollableContainer}>
					<Scrollbars
						autoHide
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={500}
					>
						{Object.keys(freeChannels).map(
							elem =>
								freeChannels[elem] && (
									<div className={s.row} key={elem}>
										<span>{channelsName[elem]}</span>
										<div className={s.rightSide}>
											<CurrencyFormatter euro={0} symbol={EUR} />
											<CloseIcon
												onClick={() => handleFreeChannelDeleteClick(elem)}
											/>
										</div>
									</div>
								)
						)}
						{dynamicFreeChannels.map(item => (
							<div className={s.row} key={item.product_id}>
								<span>{item.channel.name}</span>
								<div className={s.rightSide}>
									<CurrencyFormatter
										euro={item.vonq_price[0].amount}
										symbol={EUR}
									/>
									<CloseIcon
										onClick={() => {
											addDynamicFreeChannel(item);
											freeChannelSelectionBucket.actions.row(
												item.product_id,
												Boolean(
													contractSelectionBucket.state.selectedRows.length
												)
											);
										}}
									/>
								</div>
							</div>
						))}
						{products_specs.map(({ contractId, product_title }) => (
							<div className={s.row} key={contractId}>
								<span>{product_title}</span>
								<div className={s.rightSide}>
									<CurrencyFormatter euro={0} symbol={EUR} />
									<CloseIcon
										onClick={() => handleContractDeleteClick(contractId)}
									/>
								</div>
							</div>
						))}
						{(fakeSelectedChannels.length
							? fakeSelectedChannels
							: selectedChannels
						).map(item => {
							let isRemoveDisabled =
								item.product_id === HIREME_CHANNEL_ID &&
								(selectedChannels.length > 1 || products_specs.length > 0);

							return (
								<div className={s.row} key={item.product_id}>
									<span>{item.title || item.channel.name}</span>
									<div className={s.rightSide}>
										<CurrencyFormatter
											euro={item.vonq_price[0].amount}
											symbol={EUR}
										/>
										<CloseIcon
											className={cx({
												[s.disabled]: isRemoveDisabled
											})}
											onClick={() => {
												if (!isRemoveDisabled) handleChannelDeleteClick(item);
											}}
										/>
									</div>
								</div>
							);
						})}
					</Scrollbars>
				</div>
				<div className={s.totalContainer}>
					<span className={s.totalLabel}>Total cost</span>
					<CurrencyFormatter
						className={s.totalCount}
						euro={(fakeSelectedChannels.length
							? fakeSelectedChannels
							: selectedChannels
						).reduce(
							(totalCost, amount) =>
								totalCost + get(amount, "vonq_price[0].amount", 0),
							0
						)}
						symbol={EUR}
					/>
				</div>
				<div className={s.actions}>
					<button
						className={s.primary}
						onClick={onSubmit}
						data-onboarding-step={`${HOW_TO_PUBLISH_VACANCY}-7`}
						disabled={
							isRePublishing || isLoading || validatePublishVacancyLoading
						}
					>
						Confirm and {isPublished ? "republish" : "publish"}
					</button>
					<button
						className={s.secondary}
						onClick={() => setvalue("open", false)}
					>
						Cancel
					</button>
				</div>
			</div>
		</Drawer>
	);
};

export default PublishVacancyConfirmDrawer;
