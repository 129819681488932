export const colors = {
	primaryColor: "#ff6849",
	primaryHover: "#fc5035",
	successColor: "#36ba90",
	errorColor: "#f75356",
	warningColor: "#f9a937",
	infoColor: "#45b2d2",
	toasterSuccessColor: "#49c09a",
	borderColor: "#e2e2e2",
	darkBorderColor: "#b9bdc0",
	inputBorderColor: "#8d8d8d",
	gray: "#f6f6f6",
	darkGray: "#394047",
	lightDarkGray: "#989fa6",
	lightGray: "#7f8992",
	lighterGray: "#d1d8dc",
	lockIconGray: "#b8bfc6",
	white: "#ffffff",
	black: "#000000",
	sidebarBtnActive: "#fa6669",
	panelBg: "#fbfbfb",
	panelColor: "#7496aa",
	panelHeadColor: "#505f75",
	refuse: "#f76d75",
	refuseText: "#f76c75",
	panelBorderColor: "#e9eef1",
	greenTextColor: "#3ebb90",
	greenModalBgColor: "#5bc4a3",
	anchorsColor: "#58626b",
	errorBgcolor: "#f54953",
	notification: "#fafbfd",
	notificationBorder: "#e2e2e2",
	hoverBgColor: "#fafafb",
	linksColor: "#58626b",
	bidList: "#51585e",
	lineHover: "#f7f8f9",
	paused: "#f75356",
	inputColor: "#3d4b60",
	accordionSub: "#5c6d86",
	senior: "#4eb2d7",
	avatar: "#e9f0f4",
	bullColor: "#909aa3",
	class_label: "#9ba2a7",
	sectionBorderColor: "#e4e7ee",
	labelBorderColor: "#d0d5d9",
	stoppedColor: "#ffbf64",
	arrow: "#9ea0a3",
	btnDisabled: "#d2d7db",
	divider: "#f0f1f4",
	pacItem: "#5c636a",
	jobData: "#969a9e",
	bodyBg: "#f1f2f5",
	contactColor: "#505c68",
	btnTrColor: "#8a95a1",
	btn_faded: "#bbc2c9",
	accepted: "#53c79f",
	cancel: "#FDC9D0",
	live: "#ffc168",
	dropdownColor: "#626b74",
	dropdownPauseColor: "#ed5151",
	updateColor: "#60cfac",
	stepperDividerColor: "#bdc7d1",
	stepperTitleColor: "#8b99a6",
	stepperIconColor: "#36ba90",
	disabledGray: "#888e95",
	btnBlueColor: "#5dacd0",
	editProfileBorder: "#dcdfe6",
	cvSidebarColor: "#3d4d65",
	sidebarBorderColor: "#e4e7ee",
	dividerBgColor: "#f0f1f4",
	darkOrange: "#f1684d",
	checkboxBorderColor: "#bcc7cd",
	skillsIntroBg: "#e3f2fa",
	skillsIntroBorder: "#b3cfdd",
	skillsIntroColor: "#31708f",
	disabledSelect: "#f7f8fa",
	alertColor: "#ab686c",
	alertBg: "#ffedee",
	alertBorder: "#ffacb1",
	dangerBg: "#f34b57",
	btnDangerBg: "#fee6e8",
	btnDangerBorder: "#f6d8db",
	backdropBg: "#394148",
	cvBorderColor: "#e0e1e4",
	introColor: "#737373",
	scrubColor: "#d1d4d7",
	editIconColor: "#6e767e",
	cvItemBlockColor: "#858f9b",
	pinkyRedColor: "#fc7a81",
	darkPinkyRedColor: "#e5656d",
	timesheetBorder: "#cacaca",
	lightGrayColor: "#c0c4c7",
	invitationDescription: "#f8f8f9",
	loaderBaseColor: "#eef0f1",
	invitationDescriptionBorder: "#22a6e5",
	timesheetTh: "#a5b1bb",
	icon: "#879098",
	switcher: "#ced3da",
	deleteButton: "#9fa7ae",
	submitted: "#1959c9",
	submittedLabelBg: "#6dd4dc",
	tag: "#3D4B63",
	notifBorderColor: " #f3f4f5",
	pColor: "#707a82",
	notifColor: "#515d6f",
	grayBodyBg: "#f3f4f6",
	onboarded: "#1a91cf",
	sectionBorder: "#e4e8ec",
	bidConsultancyIcon: "#ffd99a",
	interestConfirmed: "#1a91ce",
	grayBorder: "#e1e4e7",
	placeholderColor: "#b8bcc0",
	noAdvantagePlaceholder: "#a8aeb4",
	TryAgainBorderColor: "#c9c9c9",
	extendedColor: "#1959c9",
	mustHaveTextColor: "#909097",
	grayText: "#768089",
	clearFilterColor: "#727e96",
	skillsItemBg: "#c1c1c1",
	disabledListItemRemoveButton: "#e5e8e9",
	depSelect: "#d7d9e0",
	blackText: "#242f43",
	shortlistedColor: "#a9afb3",
	submittedColor: "#1959c9",
	closeColor: "#f9a938",
	internalColor: "#3c78d8",
	appliedColor: "#6d9eeb",
	greenButton: "#1b9f94",
	tagBlue: "#0b6dba",
	tagBlueLight: "rgba(81, 151, 241, 0.14)",
	tagGreen: "#0b7e36",
	tagGreenLight: "#b6e8cc",
	tagPurple: "#893798",
	tagPurpleLight: "rgba(137, 55, 152, 0.14)",
	tagGrey: "#969696",
	tagGreyLight: "#f8f9fa",
	tagOrangeLight: "#ffd38f",
	tagOrange: "#9f7943",
	openGrey: "#f6f9fb",
	powderBlue: "#ccd7e6",
	timesheetWarningBg: "#E99010",
	timesheetWarning: "#F9A937",
	ultraBlue: "#FFC107"
};

export const utilities = {
	panelPadding: "20px",
	borderRadius: "4px"
};
