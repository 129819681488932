import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import FloatingSideBar from "common/FloatingSideBar";
import {
	CLIENT,
	FREELANCER,
	DATE_FORMAT_LLLL,
	FULL_TIME_LABEL,
	PART_TIME_LABEL,
	SENT,
	ADMIN,
	PROPOSE_CONTRACT,
	INVITE_INTERVIEW,
	CANCEL_APPLICATION,
	LIVE,
	CANCELLED,
	DECLINED,
	REJECT_APPLICATION,
	ACCEPT,
	ACCEPTED,
	EXPIRED,
	REJECTED,
	PROPOSE_ANOTHER_DATE,
	PROPOSE_ANOTHER_TERM,
	ACCEPT_MISSION,
	DONE,
	REFUSED,
	SIGNED,
	ASK_QUESTION,
	CONTRACT_TYPE,
	ACCEPT_MISSION_REQUIRED_FILES,
	CURRENCY_SUFFIX
} from "config";
import { formatDate } from "common/Functions";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import GlobalTooltip from "common/GlobalTooltip";
import SideBarDropdown from "./SideBarDropdown";
import { SidebarPadding } from "modules/vacancy/components/pokeView/Styled";

const BidSideBar = ({
	style,
	isSticky,
	job: {
		full_time,
		hourly_budget,
		publish_date,
		start_date,
		end_date,
		site,
		contract_type,
		reporting_settings
	},
	userType,
	canMakeProposal,
	interview,
	contract,
	companyId,
	bidStatus,
	userRole,
	toggleInterviewModal,
	toggleContractModal,
	toggleContractConfirmation,
	toggleContractConfirmationRequiredFiles,
	toggleRejectApplicationModal,
	acceptInterview,
	cancelBid,
	bidId,
	canProposeContract,
	toggleHelpModal,
	displayOnlyActions,
	companyVat
}) => {
	const isExpired = _get(interview, "status") === EXPIRED;
	const requiredFiles = _get(contract, "required_files", []);
	const handleActions = (action, disabled = false) => {
		if (disabled) {
			return;
		}
		switch (action) {
			case ACCEPT:
				return acceptInterview({
					interview_id: interview._id,
					token_invitation_id: interview.token_invitation_id,
					bid_id: bidId
				});
			case INVITE_INTERVIEW:
			case PROPOSE_ANOTHER_DATE:
				return toggleInterviewModal(action);
			case CANCEL_APPLICATION:
				return cancelBid(CANCEL_APPLICATION);
			case REJECT_APPLICATION:
				return toggleRejectApplicationModal();
			case ACCEPT_MISSION:
				return toggleContractConfirmation();
			case ACCEPT_MISSION_REQUIRED_FILES:
				return toggleContractConfirmationRequiredFiles();
			case PROPOSE_CONTRACT:
			case PROPOSE_ANOTHER_TERM:
				return toggleContractModal(PROPOSE_CONTRACT);
			case ASK_QUESTION:
				return toggleHelpModal(ASK_QUESTION);
			default:
				break;
		}
	};

	const getClassname = (list, shouldDisable) => {
		let primary = "btn btn-primary-2 btn-block btn-sidebar";
		let outline = "btn btn-outline-2 btn-block btn-sidebar";
		let disabled =
			"btn btn-proposal btn-proposal-disabled btn-block btn-sidebar";

		let _length = list.length;

		if (shouldDisable) {
			return disabled;
		}
		if (_length === 0) {
			return primary;
		}
		if (_length >= 1) {
			return outline;
		}
	};

	const getNegativeButtonText = ({
		isClient,
		interview_id,
		contract_id,
		interview_status,
		contract_status,
		isContractLastId,
		isInterviewLastId
	}) => {
		let text = "Reject application";
		if (!interview_id && !contract_id && !isClient) text = "Cancel Application";
		const isClientInInterview = isClient && interview_id;
		const isClientInContract = isClient && contract_id;
		const isFreelancerInInterview = !isClient && interview_id;
		const isFreelancerInContract = !isClient && contract_id;

		if (isClientInInterview) {
			switch (interview_status) {
				case SENT:
				case DECLINED:
				case ACCEPTED:
					text = isInterviewLastId ? "Cancel Interview" : "Refuse interview";
					break;
				default:
					text = "Reject Application";
					break;
			}
		}
		if (isClientInContract) {
			if (contract_status === SENT) text = "Cancel Proposition";
			else text = "Refuse Proposition";
		}

		if (isFreelancerInInterview && isInterviewLastId) {
			text = "Cancel Interview";
		} else if (isFreelancerInInterview && !isInterviewLastId) {
			text = "Refuse Interview";
		}

		if (isFreelancerInContract) {
			if (
				(contract_status === SENT || contract_status === DECLINED) &&
				!isContractLastId
			)
				text = "Refuse Contract Proposition";
			else text = "Cancel Contract Proposition";
		}
		return text;
	};

	const getActions = () => {
		const {
			status: interview_status,

			_id: interview_id,
			last_action_company_id: interviewLastId,
			new_interview_end_date
		} = interview;
		const {
			status: contract_status,
			signed_status,
			_id: contract_id,
			last_action_company_id: contract_last_id
		} = contract;

		let _buttons = [];
		const isClient = userType === CLIENT;
		const isFreelancer = userType === FREELANCER;
		const isBidLive = bidStatus === LIVE;
		const isBidCancelled = bidStatus === CANCELLED || bidStatus === REJECTED;
		const contractDeclined = contract_status === DECLINED;
		const contractSent = contract_status === SENT;
		const contractAccepted = contract_status === ACCEPTED;
		const contractSigned = signed_status === SIGNED;
		const processEnded =
			contract_status === CANCELLED ||
			contract_status === REFUSED ||
			interview_status === REFUSED ||
			interview_status === CANCELLED ||
			isBidCancelled;
		const interviewSent = interview_status === SENT;
		const interviewDeclined = interview_status === DECLINED;
		const interviewExpired = interview_status === EXPIRED;
		const isInterviewLastId = interviewLastId && companyId === interviewLastId;
		const isContractLastId = contract_last_id && companyId === contract_last_id;
		const isInterviewDone = interview_status === DONE;
		const interviewCancelled = interview_status === CANCELLED;
		const hasInterviewExpired =
			new_interview_end_date &&
			window
				.moment()
				.isAfter(window.moment.unix(new_interview_end_date), "day");

		const interviewRefusedByFreelancer =
			isFreelancer && interview_status === REFUSED;

		if (processEnded) return _buttons;

		if (!isBidCancelled) {
			if (isClient && !contract_id && !interview_id) {
				_buttons.push(
					<button
						key="invite-to-interview"
						className={getClassname(_buttons)}
						onClick={() => handleActions(INVITE_INTERVIEW)}
					>
						Propose interview
					</button>
				);
			}

			if (
				!contract_id &&
				interview_id &&
				(interviewDeclined || interviewSent) &&
				!isInterviewLastId &&
				!interviewExpired
			) {
				_buttons.push(
					<button
						key="accept-interview"
						className={getClassname(_buttons)}
						onClick={() => handleActions(ACCEPT)}
					>
						Accept interview
					</button>
				);
			}

			if (
				!contract_id &&
				interview_id &&
				!hasInterviewExpired &&
				!isInterviewDone &&
				!interviewCancelled &&
				!interviewRefusedByFreelancer
			) {
				_buttons.push(
					<button
						key="propose-another-date"
						icon="icon-calendar2"
						className={getClassname(_buttons)}
						onClick={() =>
							handleActions(isClient ? INVITE_INTERVIEW : PROPOSE_ANOTHER_DATE)
						}
					>
						{isClient
							? "Propose another date/location"
							: "Propose another date"}
					</button>
				);
			}

			if (isClient && !contract_id) {
				let showTooltip =
					!canProposeContract ||
					(canMakeProposal !== undefined && !canMakeProposal) ||
					userRole !== ADMIN;

				_buttons.push(
					<GlobalTooltip
						placement={"top"}
						noClassName={true}
						overlayClassName={"g-tool-white top center-text"}
						maxWidth={"300px"}
						key="propose-contract"
						icon="icon-calendar2"
						active={showTooltip}
						overlay={
							<span>
								{!canProposeContract
									? "Sorry, your account status doesn’t allow you to make contract propositions."
									: "You can only make one contract proposition per request."}
							</span>
						}
					>
						<button
							key="propose-contract"
							className={getClassname(_buttons, showTooltip)}
							onClick={() => handleActions(PROPOSE_CONTRACT, showTooltip)}
						>
							Propose Contract
						</button>
					</GlobalTooltip>
				);
			}

			if (
				isClient &&
				!contract_id &&
				(isInterviewDone || hasInterviewExpired)
			) {
				_buttons.push(
					<button
						key="invite-to-interview"
						icon="icon-calendar2"
						className={getClassname(_buttons)}
						onClick={() => handleActions(INVITE_INTERVIEW)}
					>
						Propose interview
					</button>
				);
			}

			if (
				(isFreelancer && contractSent) ||
				(!isContractLastId && contractDeclined)
			) {
				_buttons.push(
					<button
						key="accept-mission"
						className={getClassname(_buttons)}
						onClick={() =>
							requiredFiles.length > 0 || !companyVat
								? handleActions(ACCEPT_MISSION_REQUIRED_FILES)
								: handleActions(ACCEPT_MISSION)
						}
					>
						Accept Contract
					</button>
				);

				_buttons.push(
					<button
						key="propose-other-terms"
						className={getClassname(_buttons)}
						onClick={() => handleActions(PROPOSE_ANOTHER_TERM)}
					>
						Propose other terms
					</button>
				);
			}

			if (!contractAccepted && !isExpired) {
				if (contract_id && isClient && userRole !== ADMIN) return _buttons;
				_buttons.push(
					<button
						className={getClassname(_buttons, !isBidLive)}
						key="cancel_application"
						icon="icon-close"
						onClick={() =>
							handleActions(
								isClient ? REJECT_APPLICATION : CANCEL_APPLICATION,
								!isBidLive
							)
						}
					>
						{getNegativeButtonText({
							isClient,
							interview_id,
							contract_id,
							interview_status,
							contract_status,
							isInterviewLastId,
							isContractLastId
						})}
					</button>
				);
			}
		}

		if (contractAccepted && !contractSigned) {
			_buttons.push(
				<button
					key="contact-support"
					className={getClassname(_buttons)}
					onClick={() => handleActions(ASK_QUESTION)}
				>
					Contact support
				</button>
			);
		}

		return _buttons;
	};

	const isFreelancer = userType === FREELANCER;

	const actions = getActions();

	if (displayOnlyActions) {
		return (
			<div className="section">
				{actions.length > 0 && (
					<div className="actions">
						{actions.map((action, index) => index < 2 && action)}
						{actions.length > 2 && (
							<SideBarDropdown
								actions={actions}
								handleActions={handleActions}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
	const contractType = CONTRACT_TYPE.map(contract => {
		if (contract.value === contract_type) {
			return contract.label;
		}
	});

	return (
		<div className="col-md-3">
			<FloatingSideBar isSticky={isSticky} style={style} bottomOffset={99}>
				<div className="section">
					<SidebarPadding>
						{actions.length > 0 && (
							<div className="actions">
								{actions.map((action, index) => index < 2 && action)}
								{actions.length > 2 && (
									<SideBarDropdown
										actions={actions}
										handleActions={handleActions}
									/>
								)}
							</div>
						)}
						{isFreelancer && (
							<Fragment>
								<h5>Company Name</h5>
								<span>{_get(site, "name", "")}</span>
								<div className="divider" />
								<h5>publish date</h5>
								<span>{formatDate(publish_date, true, DATE_FORMAT_LLLL)}</span>
								<div className="divider" />
							</Fragment>
						)}
						{isFreelancer && (
							<>
								<h5>CONTRACT TYPE</h5>
								<span>{contractType}</span>
								<div className="divider" />
							</>
						)}
						<h5>{isFreelancer ? "Start - End date" : "Expected date"}</h5>
						<span>
							{formatDate(start_date)} - {formatDate(end_date)}
						</span>
						<div className="divider" />

						<h5>Time commitment</h5>
						<span className="time-commit">
							{full_time ? FULL_TIME_LABEL : PART_TIME_LABEL}
						</span>

						{!isFreelancer && (
							<Fragment>
								<div className="divider" />
								<h5>Expected rate</h5>
								<span>
									<CurrencyFormatterRender
										isCent
										value={hourly_budget}
										suffix={CURRENCY_SUFFIX[reporting_settings?.rate_type]}
										currency={reporting_settings?.currency?.code}
									/>
								</span>
							</Fragment>
						)}
					</SidebarPadding>
				</div>
			</FloatingSideBar>
		</div>
	);
};

BidSideBar.propTypes = {
	style: PropTypes.object,
	isSticky: PropTypes.bool,
	job: PropTypes.object.isRequired,
	userType: PropTypes.string.isRequired
};

export default BidSideBar;
