// This is a quick fix to navigate between candidates in search candidates, the best solution is to fix and use usePoolSore
import { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import useListPagination from "hooks/useListPagination";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import { CANDIDATES_FILTER } from "modules/SearchCandidate/utils/constants";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";

function SearchCandidateNavigationHOC({
	render,
	location: { search },
	router,
	onPreviousClick,
	onNextClick
}) {
	const {
		params: { id: profile_id }
	} = router;

	const { getState } = useFilters();
	const { data } = getState(CANDIDATES_FILTER);

	const [ids, setIds] = useState([]);

	useEffect(() => {
		if (data) {
			setIds(data.data.map(profile => profile.id));
		}
	}, [data]);

	const { nextId, previousId } = useListPagination({
		data: ids,
		id: profile_id
	});

	const previousDisabled = ids.indexOf(profile_id) === 0;
	const nextDisabled = ids.indexOf(profile_id) === ids.length - 1;

	const currentIndex = ids.indexOf(profile_id);

	const nextProfileID = useMemo(
		() =>
			ids.indexOf(profile_id) !== ids.length - 1
				? ids[currentIndex + 1]
				: ids[currentIndex - 1],
		[currentIndex, ids, profile_id]
	);

	const goToProfile = id => {
		router.replace(getPermanentProfileRoute(id) + search);
	};

	const handleNext = () => {
		goToProfile(ids[currentIndex + 1]);
		nextId();
		onNextClick?.();
	};

	const handlePrevious = () => {
		goToProfile(ids[currentIndex - 1]);
		previousId();
		onPreviousClick?.();
	};

	return render({
		previousProfile: handlePrevious,
		nextProfile: handleNext,
		nextProfileID,
		isNextDisabled: nextDisabled,
		isPreviousDisabled: previousDisabled,
		goToProfile
	});
}

export default withRouter(SearchCandidateNavigationHOC);
