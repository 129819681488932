import React, { PureComponent } from "react";
import Portal from "./Portal";
import { css } from "styled-components";

class NestedOption extends PureComponent {
	state = {
		expanded: false
	};

	componentDidUpdate(prevProps) {
		if (this.props.isFocused !== prevProps.isFocused)
			this.setState({ expanded: this.props.isFocused });
	}

	toggleExpanded = e => {
		if (e.type === "mouseenter") this.props.onFocus(this.props.option, e);
		this.setState(prevState => {
			return {
				expanded: !prevState.expanded
			};
		});
	};

	selectOption = (e, opt) => {
		this.props.onSelect(opt, e);
	};

	render() {
		const { option, isFocused, isClient } = this.props;
		const { expanded } = this.state;
		const hidden = option.hidden === undefined ? false : option.hidden;
		const hasSubMenu = option.subs && option.subs.length > 0;
		const classNames = `Select-option${
			expanded || isFocused ? " is-focused" : ""
		}`;
		const focusedNode = document.querySelector(".Select-menu-outer");
		let nodeRect = { x: 0, y: 0 };
		if (focusedNode) {
			nodeRect = focusedNode.getBoundingClientRect();
		}

		return (
			<div className={`${hasSubMenu ? "Select-sub-menu" : "Select-elm"}`}>
				{!hidden && (
					<div
						key={option._id}
						onMouseEnter={this.toggleExpanded}
						onClick={hasSubMenu ? null : e => this.selectOption(e, option)}
						className={classNames}
					>
						{option.label}
						{hasSubMenu ? <i className="fa fa-chevron-right" /> : null}
					</div>
				)}
				{!hidden && hasSubMenu && expanded && (
					<div
						className={`nested-menu ${isClient ? "client-nested-menu" : ""}`}
						// onMouseLeave={this.toggleExpanded}
					>
						<Portal
							onMouseLeave={this.toggleExpanded}
							elementClassName={`nested-menu ${
								isClient ? "client-nested-menu" : ""
							}`}
							elementStyle={css`
								position: absolute;
								top: ${nodeRect.y + window.scrollY + 1}px;
								left: ${nodeRect.x + nodeRect.width - 1}px;
								z-index: 9999;
								border: 1px solid #ccc;
								box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
							`.join("")}
						>
							<div className={`${isClient ? "Select-menu-client-layout" : ""}`}>
								{option.subs.map((opt, i) => (
									<div
										className={`Select-option sub-menu`}
										onClick={e => this.selectOption(e, opt)}
										key={i}
									>
										{opt.label}
									</div>
								))}
							</div>
						</Portal>
					</div>
				)}
			</div>
		);
	}
}

export default NestedOption;
