import React from "react";
import styles from "../../messaging-tool-email-list.module.scss";
import { ReactComponent as TrashIcon } from "static/icons/trash-05.svg";
import Tooltip from "common/Tippy";

export default function MessagingToolEmailDeleteButton({
	onClick,
	tooltipText
}) {
	return (
		<Tooltip content={tooltipText} theme="dark">
			<button
				className={styles.cta}
				onClick={e => {
					e.stopPropagation();
					onClick(e);
				}}
			>
				<TrashIcon className={styles.icon} width={22} height={22} />
			</button>
		</Tooltip>
	);
}
