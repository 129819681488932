import React from "react";
import styles from "./group-avatars.module.scss";
import Tooltip from "common/Tippy";
import cx from "classnames";
import { CustomAvatar } from "common/CustomAvatar";

function GroupAvatars({ collaborators }) {
	return (
		<div className={styles.images}>
			{collaborators?.map(({ avatar, _id, first_name, last_name }, i) => {
				const fullName = first_name + " " + last_name;
				return (
					<Tooltip key={_id} content={fullName} theme="dark">
						<div className={styles.avatarWrapper}>
							<CustomAvatar
								src={avatar}
								variant="small"
								fullName={fullName}
								rootClassName={cx(styles.collaboratorPicture, {
									[styles.firstCollaboratorPicture]: i === 0
								})}
								imgClassName={cx(styles.picture)}
								avatarClassName={cx(styles.avatarWithBorder, styles.avatar, {
									[styles.firstCollaboratorPicture]: i === 0
								})}
								avatarSize={30}
								rootStyle={{
									zIndex: i
								}}
								avatarStyle={{
									zIndex: i
								}}
							/>
						</div>
					</Tooltip>
				);
			})}
		</div>
	);
}

export default GroupAvatars;
