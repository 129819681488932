import React, { useState } from "react";
import { useSelector } from "react-redux";
import BodyClassName from "react-body-classname";
import { Box } from "rebass";

import SystemNotifications from "./SystemNotificationsClient";
import MissionNotifications from "./MissionNotificationsClient";
import HeaderActions from "./HeaderActionsClient";
import { LoaderFull } from "../../../common/Loader";
import { Helmet } from "react-helmet";
import styles from "./style.module.scss";
import { LATEST } from "config";

export const getNotificationsClientRoute = () => {
	return "/notifications-center";
};

export default function NotificationsCenter({ route: { title } }) {
	const isFetching = useSelector(state => state.notifications.isFetching);
	const [view, setView] = useState(LATEST);
	const [latestCount, setLatestCount] = useState(0);
	const [unreadCount, setUnreadCount] = useState(0);
	if (isFetching)
		return <LoaderFull classes="full-loader" text="Loading notifications" />;

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<BodyClassName>
				<Box p={20}>
					<div className={styles.notifications_container}>
						<HeaderActions
							view={view}
							setView={setView}
							latestCount={latestCount}
							unreadCount={unreadCount}
						/>
						<div className={styles.content}>
							<SystemNotifications
								view={view}
								setLatestCount={setLatestCount}
								setUnreadCount={setUnreadCount}
							/>
							<div style={{ height: 16 }} />
							<MissionNotifications view={view} />
						</div>
					</div>
				</Box>
			</BodyClassName>
		</>
	);
}
