import React, { useEffect, useState } from "react";
import { StyledCheckbox } from "./styled";

const CHECKBOX_STATES = {
	Checked: "Checked",
	Indeterminate: "Indeterminate",
	Empty: "Empty"
};

const SampleCheckbox = ({
	setChecked: setControlledChecked,
	checkAll,
	setIsAllElementHasChecked,
	id,
	disabled
}) => {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		!disabled && setChecked(checkAll);
	}, [checkAll]);

	useEffect(() => {
		if (!checked) {
			return setIsAllElementHasChecked(array =>
				array.filter(item => item !== id)
			);
		}
		setIsAllElementHasChecked(array => [...array, id]);
	}, [checked]);

	const onChange = e => {
		e.stopPropagation();
		setChecked(!checked);
		setControlledChecked(CHECKBOX_STATES.Indeterminate);
	};
	return (
		<StyledCheckbox>
			<div className="checkbox checkbox-primary">
				<div
					className="input-box"
					style={{
						width: "100%",
						display: "block",
						marginTop: "11px"
					}}
				>
					<input
						type="checkbox"
						className="styled"
						onChange={onChange}
						checked={disabled ? false : checked}
						id={`item-${id}`}
						name={`item-${id}`}
						style={{
							height: 0,
							width: 0,
							margin: 0
						}}
						disabled={disabled}
					/>
					<label
						style={{
							width: "20px",
							padding: 0,
							opacity: disabled ? 0.25 : 1,
							cursor: disabled ? "not-allowed" : "pointer"
						}}
						htmlFor={`item-${id}`}
					></label>
				</div>
			</div>
		</StyledCheckbox>
	);
};

export default SampleCheckbox;
