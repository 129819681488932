import React from "react";
import { DismissButton, Overlay, usePopover } from "react-aria";
import { Paper } from "common/Paper";
import cx from "classnames";

function ComboBoxPopover({
	popoverContainer,
	children,
	state,
	style,
	className,
	...props
}) {
	let ref = React.useRef();
	let { popoverRef = ref } = props;
	let { popoverProps } = usePopover(props, state);

	return (
		<Overlay portalContainer={popoverContainer}>
			<Paper
				{...popoverProps}
				ref={popoverRef}
				style={{
					...popoverProps.style,
					...style
				}}
				className={cx(className)}
			>
				{children}
				<DismissButton onDismiss={state.close} />
			</Paper>
		</Overlay>
	);
}

export default ComboBoxPopover;
