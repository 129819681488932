/* eslint-disable react/display-name */
import React from "react";
import { EUR, OFFLINE } from "config";
import _get from "lodash/get";
import {
	BidsBody,
	TableWrapperShadow,
	JobBidsList,
	Trthead,
	Thead
} from "modules/job/components/JobViewStyled";

import {
	Th,
	Td,
	Tr,
	Header,
	Title,
	Content
} from "modules/vacancy/components/vacancyView/PerformanceStyle";

import Status from "common/Table/Status";
import { Flex, Text } from "rebass";
import GlobalTooltip from "common/GlobalTooltip";
import Avatar from "common/Avatar/index";
import { ReactComponent as LinkIcon } from "static/icons/link-icon.svg";
import prependHttp from "prepend-http";
import { ReactComponent as AnonymousProfile } from "static/icons/anynomos-profile.svg";
import { ReactComponent as Danger } from "static/icons/dangerIcon.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const PerformanceList = ({ campaing, isLoading }) => {
	const skeletonLoading = new Array(3).fill(
		<Tr key={Math.floor(Math.random() * 11)}>
			<Td border name style={{ width: "20%" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={150} height={14} />
				</SkeletonTheme>
			</Td>
			<Td border style={{ width: "16%" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={40} height={14} />
				</SkeletonTheme>
			</Td>
			<Td border style={{ width: "14%" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={40} height={14} />
				</SkeletonTheme>
			</Td>
			<Td border applicant style={{ width: "20%" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={40} height={14} />
				</SkeletonTheme>
			</Td>
			<Td border status style={{ width: "22%" }}>
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={100} height={14} />
				</SkeletonTheme>
			</Td>
			<Td isLink={false} border style={{ width: "8%" }}>
				{" "}
				<LinkIcon className="link-icon" />{" "}
			</Td>
		</Tr>
	);
	return (
		<>
			<TableWrapperShadow>
				<Header>
					<div className="box">
						<Title>Request reference </Title>
						{isLoading ? (
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={40} height={14} />
							</SkeletonTheme>
						) : (
							<Content number>{_get(campaing, "job_ref", "")}</Content>
						)}
					</div>
					<div className="box">
						<Title>Language Version </Title>
						{isLoading ? (
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={40} height={14} />
							</SkeletonTheme>
						) : (
							<Content>{_get(campaing, "version", "").toUpperCase()}</Content>
						)}
					</div>
					<div className="box">
						<Title>Created BY </Title>
						{isLoading ? (
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={40} height={14} />
							</SkeletonTheme>
						) : (
							<Content className="avatar-content">
								{_get(campaing, "created_by.avatar") ? (
									<Avatar
										src={_get(campaing, "created_by.avatar", "")}
										size={25}
									/>
								) : (
									<AnonymousProfile />
								)}
								<span className="text">
									{`${_get(campaing, "created_by.first_name", "")} ${(_get(
										campaing,
										"created_by.last_name"
									),
									"")}`}
								</span>
							</Content>
						)}
					</div>
				</Header>
			</TableWrapperShadow>
			<TableWrapperShadow noScroll>
				<JobBidsList>
					<Thead>
						<Trthead>
							<Th border name style={{ width: "20%" }}>
								Channel
							</Th>
							<Th border count style={{ width: "16%" }}>
								Cost
							</Th>
							<Th border count style={{ width: "14%" }}>
								# Of Click
							</Th>
							<Th border applicant style={{ width: "20%" }}>
								# Of Applicants
							</Th>
							<Th border status style={{ width: "22%" }}>
								Status
							</Th>
							<Th border link style={{ width: "8%" }}>
								Link
							</Th>
						</Trthead>
					</Thead>
				</JobBidsList>
				<BidsBody scroll>
					{isLoading || campaing?.channels?.length > 0 ? (
						<JobBidsList>
							<tbody>
								{isLoading
									? skeletonLoading
									: campaing?.channels?.map((channel, index) => {
											const { title, name } = channel;
											const channelName = title || name;

											return (
												<Tr key={index}>
													<Td border name style={{ width: "20%" }}>
														{channelName?.length > 16 ? (
															<>
																<GlobalTooltip
																	placement="top"
																	eventTrigger="hover"
																	overlay={channelName}
																>
																	<Text
																		as={"span"}
																		sx={{
																			fontSize: "13px",
																			fontWeight: "500",
																			color: "#3d4b60"
																		}}
																	>
																		{channelName.substr(0, 16)}
																		{"..."}
																	</Text>
																</GlobalTooltip>
															</>
														) : (
															channelName
														)}
													</Td>
													<Td border count style={{ width: "16%" }}>
														{`${_get(channel, "cost[0].amount", 0)} ${EUR}`}
													</Td>
													<Td border count style={{ width: "14%" }}>
														{channel.clicks}
													</Td>
													<Td border applicant style={{ width: "20%" }}>
														{channel.applicants}
													</Td>
													<Td border status style={{ width: "22%" }}>
														{" "}
														<Flex alignItems="center">
															<Status status={_get(channel, "status")} />{" "}
															<Text sx={{ textTransform: "capitalize" }}>
																{_get(channel, "status")}
															</Text>{" "}
														</Flex>{" "}
													</Td>
													<Td
														border
														channelStatus={_get(channel, "status")}
														isLink={_get(channel, "link")}
														link
														style={{ width: "8%" }}
													>
														{" "}
														<LinkIcon
															className="link-icon"
															onClick={() =>
																_get(channel, "status") !== OFFLINE
																	? window.open(
																			prependHttp(_get(channel, "link"))
																	  )
																	: null
															}
														/>{" "}
													</Td>
												</Tr>
											);
									  })}
							</tbody>
						</JobBidsList>
					) : (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								height: "90px"
							}}
						>
							<Danger style={{ marginRight: "10px" }} />
							<>No results to show </>
						</div>
					)}
				</BidsBody>
			</TableWrapperShadow>
		</>
	);
};

PerformanceList.displayName = "PerformanceList";

export default PerformanceList;
