//TODO We refactor handleOperatorChange
//We created helpers to serialize filter
import produce from "immer";
import _get from "lodash/get";
import { v4 as uuid } from "uuid";
import create from "zustand";
import { persist } from "zustand/middleware";
import {
	deserializeFilters,
	serializeFilters,
	updateMap
} from "../utils/helpers";
import { LOGICAL_OPERATORS, operators } from "../utils/constant";
import {
	insertOperators,
	schemValidator
} from "common/SmartFilterDrawer/utils/helpers";

const FILTER_DEFAULT_VALUE = {
	id: uuid(),
	name: "",
	operator: "",
	value: "",
	type: "",
	label: ""
};

const FILTERS_DEFAULT_VALUE = [FILTER_DEFAULT_VALUE];

export const viewTypes = {
	table: "table",
	cards: "cards"
};

const state = {
	filters: FILTERS_DEFAULT_VALUE,
	logicalOperator: LOGICAL_OPERATORS.and.value,
	data: null,
	source: "",
	query: "",
	queryError: "",
	optionTypes: {},
	operatorsByType: {},
	componentsByOperator: {},
	sources: {},
	filterChangeTrigger: "",
	columns: [],
	filterFnc: null,
	sortBy: {},
	offset: 0,
	limit: null,
	selectedFilter: "",
	isLoading: false,
	isFetching: false,
	showDrawer: false,
	selectedCriteria: {},
	activeView: viewTypes.table,
	selectedUserId: "",
	showNoteDrawer: false,
	displayKeywordCard: true,
	module_id: "",
	isRecapOpen: false,
	smartQuerySchema: [],
	smartQueryActiveIndex: null,
	smartQueryError: null,
	isPortalOpen: false,
	portalFilterData: {},
	invalidElementsIndex: [],
	targetTab: null,
	isFilterApplied: false,
	isSmartQueryfilled: false
};

export const useFilters = create(
	persist(
		(set, get) => ({
			isHydrationCompleted: false,
			state: new Map(),
			currentFilterId: -1,
			setTargetTab: targetTab => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						targetTab
					});
					return { state: stateUpdated };
				});
			},
			setInvalidElementsIndex: invalidElementsIndex => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						invalidElementsIndex
					});
					return { state: stateUpdated };
				});
			},
			handleFilterDelete: id => {
				set(({ state, currentFilterId }) => {
					const { filters } = state.get(currentFilterId);

					const filtersUpdated = produce(filters, draft => {
						const index = filters.findIndex(filter => filter.id === id);

						draft.splice(index, 1);
						if (!draft.length) draft.push(FILTER_DEFAULT_VALUE);
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						filters: filtersUpdated,
						selectedCriteria: {},
						filterChangeTrigger: "filterDelete",
						offset: 0
					});

					return { state: stateUpdated };
				});
			},
			setFilters: filters => {
				const new_filters = [];

				filters.forEach(filter => {
					new_filters.push({ id: uuid(), ...filter });
				});

				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						filters: new_filters,
						offset: 0,
						filterChangeTrigger: "newFilters",
						source: "all"
					});
					return { state: stateUpdated };
				});
			},
			handleFilterAdd: (filter, event = "newFilter") => {
				let new_filter = {
					...FILTER_DEFAULT_VALUE,
					id: uuid(),
					name: "",
					operator: "",
					value: "",
					type: "",
					label: ""
				};

				if (filter) {
					new_filter = {
						...new_filter,
						...filter,
						operator: _get(filter, "operator.value", "")
					};
				}
				set(({ state, currentFilterId }) => {
					const { filters } = state.get(currentFilterId);

					const stateUpdated = updateMap(state, currentFilterId, {
						filters: [...filters, new_filter],
						filterChangeTrigger: event,
						offset: 0
					});

					return { state: stateUpdated };
				});
			},
			replaceFilter: (filter, filterChangeTrigger = "columnChange") => {
				set(({ state, currentFilterId }) => {
					const { filters } = state.get(currentFilterId);

					const index = filters.findIndex(
						filterItem => filterItem.id === filter.id
					);

					const filtersUpdated = produce(filters, draft => {
						draft[index] = filter;
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						filters: filtersUpdated,
						filterChangeTrigger,
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			clearFilters: () => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						filters: [FILTER_DEFAULT_VALUE],
						selectedCriteria: {},
						offset: 0,
						query: "",
						source: "all",
						selectedFilter: ""
					});

					return { state: stateUpdated };
				});
			},
			setFilterValue: (id, value, event) => {
				set(({ state, currentFilterId }) => {
					const { filters } = state.get(currentFilterId);
					const index = filters.findIndex(filter => filter.id === id);

					const filtersUpdated = produce(filters, draft => {
						draft[index].value = value;
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						filters: filtersUpdated,
						filterChangeTrigger: event,
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			setLogicalOperator: logicalOperator => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						logicalOperator: logicalOperator,
						filterChangeTrigger: "logicalOperatorChange",
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			setSource: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						source: value,
						filterChangeTrigger: "sourceChange",
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			setSortBy: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						sortBy: value,
						filterChangeTrigger: "sortChange",
						offset: 0
					});

					return { state: stateUpdated };
				});
			},
			setQuery: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						query: value,
						filterChangeTrigger: "change",
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			setOffset: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						offset: value,
						filterChangeTrigger: "offsetChange"
					});

					return { state: stateUpdated };
				});
			},
			setActiveView: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						activeView: value
					});

					return { state: stateUpdated };
				});
			},
			setLimit: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						limit: value,
						filterChangeTrigger: "limitChange"
					});

					return { state: stateUpdated };
				});
			},
			setRecapState: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						isRecapOpen: value,
						filterChangeTrigger: "recapChange"
					});

					return { state: stateUpdated };
				});
			},
			resetFilters: (
				filterId,
				{ isLoading, isFetching, logicalOperator, filters } = {}
			) => {
				set(({ state: innerState, currentFilterId }) => {
					const {
						optionTypes,
						operatorsByType,
						componentsByOperator,
						sources,
						filterFnc,
						columns,
						displayKeywordCard,
						data,
						activeView,
						limit
					} = innerState.get(filterId || currentFilterId);

					let sourceDefaultValue;
					if (sources) {
						const keys = Object.keys(sources);
						const key = keys[0];
						sourceDefaultValue = sources[key]?.value;
					}

					const stateUpdated = updateMap(
						innerState,
						filterId || currentFilterId,
						{
							...state,
							isLoading: isLoading ?? state.isLoading,
							isFetching: isFetching ?? state.isFetching,
							logicalOperator: logicalOperator ?? state.logicalOperator,
							filters: filters || state.filters,
							optionTypes,
							operatorsByType,
							componentsByOperator,
							sources,
							filterFnc,
							source: sourceDefaultValue,
							columns,
							offset: 0,
							displayKeywordCard,

							selectedCriteria: {},
							data,
							activeView,
							filterChangeTrigger: "reset",
							selectedFilter: "",
							limit: limit || state.limit
						}
					);

					return { state: stateUpdated };
				});
			},
			setData: data => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, { data });

					return { state: stateUpdated };
				});
			},
			setShowDrawer: value => {
				set(({ state, currentFilterId }) => {
					let obj = { showDrawer: value };

					if (value === false) {
						obj.selectedFilter = "";
					}

					const stateUpdated = updateMap(state, currentFilterId, obj);

					return { state: stateUpdated };
				});
			},
			setShowNoteDrawer: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						showNoteDrawer: value
					});

					return { state: stateUpdated };
				});
			},
			setIsLoading: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						isLoading: value
					});

					return { state: stateUpdated };
				});
			},
			setIsFetching: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						isFetching: value
					});

					return { state: stateUpdated };
				});
			},
			setQueryError: error => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						queryError: error
					});

					return { state: stateUpdated };
				});
			},
			setFilterSetting: (
				optionTypes,
				operatorsByType,
				componentsByOperator,
				sources,
				filterFnc,
				columns,
				id,
				module_id,
				displayKeywordCard = true,
				{ filters, isLoading, isFetching, query, logicalOperator } = {},
				limit
			) => {
				set(({ state: innerState }) => {
					const value = innerState.get(id);
					if (value && !value?.columns) {
						//We get query and array from localstorage but fields columns doesn't exist, we should initialize fields sources, columns ...
						let sourceDefaultValue;
						if (sources) {
							const keys = Object.keys(sources);
							const key = keys[0];
							sourceDefaultValue = sources[key]?.value;
						}

						const newMap = new Map();

						newMap.set(id, {
							...state,
							sortBy: value.sortBy,
							offset: value.offset,
							query: value.query,
							filters: value.filters,
							limit: value.limit,
							optionTypes,
							operatorsByType,
							componentsByOperator,
							sources,
							source: sourceDefaultValue,
							filterFnc,
							columns,
							module_id,
							displayKeywordCard,
							isLoading: isLoading ?? state.isLoading,
							isFetching: isFetching ?? state.isFetching,
							logicalOperator: logicalOperator ?? state.logicalOperator
						});
						return {
							state: new Map([...innerState, ...newMap]),
							currentFilterId: id
						};
					} else if (value) {
						const currentState = innerState.get(id);
						const stateUpdated = updateMap(innerState, id, {
							filters: filters ?? currentState.filters,
							isLoading: isLoading ?? currentState.isLoading,
							isFetching: isFetching ?? currentState.isFetching,
							query: query ?? currentState.query,
							logicalOperator: logicalOperator ?? currentState.logicalOperator
						});

						return { currentFilterId: id, state: stateUpdated };
					} else {
						let sourceDefaultValue;
						if (sources) {
							const keys = Object.keys(sources);
							const key = keys[0];
							sourceDefaultValue = sources[key]?.value;
						}

						const newMap = new Map();

						newMap.set(id, {
							...state,
							optionTypes,
							operatorsByType,
							componentsByOperator,
							sources,
							source: sourceDefaultValue,
							filterFnc,
							columns,
							module_id,
							displayKeywordCard,
							filters: filters ?? state.filters,
							query: query ?? state.query,
							isLoading: isLoading ?? state.isLoading,
							isFetching: isFetching ?? state.isFetching,
							logicalOperator: logicalOperator ?? state.logicalOperator,
							limit: limit
						});
						return {
							state: new Map([...innerState, ...newMap]),
							currentFilterId: id
						};
					}
				});
			},
			setFilter: filter => {
				set(({ state, currentFilterId }) => {
					const { filters } = state.get(currentFilterId);
					const new_filters = {
						id: uuid(),
						...filter,
						operator: filter.operator.value
					};

					const stateUpdated = updateMap(state, currentFilterId, {
						filters: [...filters, new_filters],
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			setFilterColumnValue: filter => {
				set(({ state, currentFilterId }) => {
					const { filters } = state.get(currentFilterId);
					const index = filters.findIndex(el => el.id === filter.id);

					const filtersUpdated = produce(filters, draft => {
						draft[index].value = filter.value;
						draft[index].operator = filter.operator.value;
						draft[index].type = filter.type;
						draft[index].label = filter.label;
						draft[index].name = filter.name;
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						filters: filtersUpdated,
						filterChangeTrigger: "change",
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			setSelectedCriteria: criteria => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						selectedCriteria: criteria
					});

					return { state: stateUpdated };
				});
			},
			setSelectedUserId: userId => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						selectedUserId: userId
					});

					return { state: stateUpdated };
				});
			},
			getState: id => {
				const { state: innerState } = get();

				return innerState.get(id) || state;
			},
			setSelectedFilter: value => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						selectedFilter: value
					});

					return { state: stateUpdated };
				});
			},
			setFilterColumns: columns => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						columns
					});

					return { state: stateUpdated };
				});
			},
			isFilterExist: id => {
				const filtersMap = get().state;
				return filtersMap.has(id);
			},
			setHydrationCompleted: value => {
				set(() => {
					return { isHydrationCompleted: value };
				});
			},
			setDisplayKeywordCard: show => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						displayKeywordCard: show
					});

					return { state: stateUpdated };
				});
			},
			addSmartQuery: (filter, event = "newFilter") => {
				set(({ state, currentFilterId }) => {
					const { smartQuerySchema, smartQueryActiveIndex } = state.get(
						currentFilterId
					);
					let newFilter = produce(smartQuerySchema, draft => {
						if (smartQueryActiveIndex !== null) {
							let index = smartQueryActiveIndex > 0 ? smartQueryActiveIndex : 0;
							if (filter.name) {
								draft.splice(index, 0, {
									...filter,
									id: uuid(),
									operator: _get(filter, "operator.value", "")
								});
							} else
								draft.splice(index, 0, {
									...filter,
									id: uuid()
								});
						} else if (filter.name)
							draft.push({
								...filter,
								id: uuid(),
								operator: _get(filter, "operator.value", "")
							});
						else
							draft.push({
								...filter,
								id: uuid()
							});
					});
					if (!smartQueryActiveIndex) newFilter = insertOperators(newFilter);
					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: newFilter,
						smartQueryActiveIndex: null,
						smartQueryError: null,
						filterChangeTrigger: event,
						offset: 0
					});

					return { state: stateUpdated };
				});
			},
			setSmartQuery: filters => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: filters,
						offset: 0,
						filterChangeTrigger: "newFilters",
						source: "all"
					});
					return { state: stateUpdated };
				});
			},
			handlePortalFilterClick: () => {},
			setHandlePortalFilterClick: handlePortalFilterClick => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						handlePortalFilterClick
					});
					return { state: stateUpdated };
				});
			},
			importConditions: conditions => {
				set(({ state, currentFilterId }) => {
					const { smartQuerySchema } = state.get(currentFilterId);
					let newFilter = produce(smartQuerySchema, draft => {
						draft.push(...conditions);
					});
					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: newFilter,
						smartQueryActiveIndex: null,
						smartQueryError: null,
						offset: 0,
						filterChangeTrigger: "newFilters"
					});

					return { state: stateUpdated };
				});
			},

			setOperator: (operatorId, index) => {
				set(({ state, currentFilterId }) => {
					const { smartQuerySchema } = state.get(currentFilterId);
					const filtersUpdated = produce(smartQuerySchema, draft => {
						let selectedOperator = operators.find(e => e._id === operatorId);
						draft[index] = { id: uuid(), ...selectedOperator };
						let newList = schemValidator(draft);
						draft = newList;
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: filtersUpdated,
						offset: 0,
						filterChangeTrigger: "newFilters"
					});

					return { state: stateUpdated };
				});
			},
			setSmartQueryError: error => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						smartQueryError: error
					});
					return { state: stateUpdated };
				});
			},
			setSmartQueryHasError: arrayOfIndex => {
				set(({ state, currentFilterId }) => {
					const { smartQuerySchema } = state.get(currentFilterId);
					const parsedSmartQuery = produce(smartQuerySchema, draft => {
						arrayOfIndex.forEach(i => {
							draft[i]["hasError"] = true;
						});
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: parsedSmartQuery
					});

					return { state: stateUpdated };
				});
			},
			setSmartQueryActiveIndex: smartQueryActiveIndex => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						smartQueryActiveIndex
					});

					return { state: stateUpdated };
				});
			},
			deleteSmartQueryLastItem: () => {
				set(({ state, currentFilterId }) => {
					const { smartQuerySchema } = state.get(currentFilterId);

					const newSmartQuery = produce(smartQuerySchema, draft => {
						const last = draft.length - 1;
						draft.splice(last, 1);
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: newSmartQuery,
						selectedCriteria: {},
						filterChangeTrigger: "filterDelete",
						offset: 0
					});

					return { state: stateUpdated };
				});
			},
			deletSmartQueryItem: id => {
				set(({ state, currentFilterId }) => {
					const { smartQuerySchema } = state.get(currentFilterId);

					const newSmartQuery = produce(smartQuerySchema, draft => {
						const index = draft.findIndex(e => e.id === id);
						draft.splice(index, 1);
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: newSmartQuery,
						selectedCriteria: {},
						filterChangeTrigger: "filterDelete",
						offset: 0
					});

					return { state: stateUpdated };
				});
			},
			replaceSmartQueryFilter: (
				filter,
				filterChangeTrigger = "columnChange"
			) => {
				set(({ state, currentFilterId }) => {
					const { smartQuerySchema } = state.get(currentFilterId);

					const index = smartQuerySchema.findIndex(
						filterItem => filterItem.id === filter.id
					);

					const filtersUpdated = produce(smartQuerySchema, draft => {
						draft[index] = filter;
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: filtersUpdated,
						filterChangeTrigger,
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			clearSmartQuery: () => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: [],
						smartQueryActiveIndex: null,
						smartQueryError: null,
						selectedCriteria: {},
						offset: 0,
						query: "",
						source: "all",
						selectedFilter: ""
					});

					return { state: stateUpdated };
				});
			},
			setSmartQueryFilterValue: (id, value, filterChangeTrigger) => {
				set(({ state, currentFilterId }) => {
					const { smartQuerySchema } = state.get(currentFilterId);
					const index = smartQuerySchema.findIndex(filter => filter.id === id);

					const filtersUpdated = produce(smartQuerySchema, draft => {
						draft[index].value = value;
					});

					const stateUpdated = updateMap(state, currentFilterId, {
						smartQuerySchema: filtersUpdated,
						filterChangeTrigger,
						offset: 0,
						selectedCriteria: {}
					});

					return { state: stateUpdated };
				});
			},
			setIsPortalOpen: isPortalOpen =>
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						isPortalOpen
					});
					return { state: stateUpdated };
				}),
			setIsSmartQueryfilled: isSmartQueryfilled => {
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						isSmartQueryfilled
					});
					return { state: stateUpdated };
				});
			},
			setPortalFilterData: portalFilterData =>
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						portalFilterData
					});
					return { state: stateUpdated };
				}),
			setIsFilterApplied: isFilterApplied =>
				set(({ state, currentFilterId }) => {
					const stateUpdated = updateMap(state, currentFilterId, {
						isFilterApplied
					});
					return { state: stateUpdated };
				})
		}),
		{
			name: "filters", // name of the item in the storage (must be unique)
			serialize: serializeFilters,
			deserialize: deserializeFilters
		}
	)
);
