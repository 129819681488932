import React, { memo, useEffect, useRef, useCallback } from "react";
import useEventListener from "./use-event-listener";
import style from "../multiSites_header.module.scss";

export const TabMeasurer = memo(function TabMeasurer({
	tabs,
	activator,
	offset = 0,
	handleMeasurement: handleMeasurementProp
}) {
	const containerNode = useRef(null);
	const animationFrame = useRef(null);

	const handleMeasurement = useCallback(() => {
		if (animationFrame.current) {
			cancelAnimationFrame(animationFrame.current);
		}

		animationFrame.current = requestAnimationFrame(() => {
			if (!containerNode.current) {
				return;
			}

			const containerWidth = containerNode.current.offsetWidth - offset;
			const hiddenTabNodes = containerNode.current.children;
			const hiddenTabNodesArray = Array.from(hiddenTabNodes);
			const hiddenTabWidths = hiddenTabNodesArray.map(node => {
				return Math.ceil(node.getBoundingClientRect().width);
			});
			const disclosureWidth = hiddenTabWidths.pop() || 0;

			handleMeasurementProp({
				containerWidth,
				disclosureWidth,
				hiddenTabWidths
			});
		});
	}, [handleMeasurementProp]);
	useEffect(() => {
		handleMeasurement();
	}, [tabs, handleMeasurement]);
	useEventListener("resize", handleMeasurement);

	const tabsMarkup = tabs.map((tab, index) => {
		return (
			<button
				key={`${index}${tab._id}Hidden`}
				id={`${tab._id}Measurer`}
				className={style.tab}
			>
				{tab?.name}
			</button>
		);
	});

	return (
		<div className={style.containerTabs} ref={containerNode}>
			{tabsMarkup}
			{activator}
		</div>
	);
});
