import React from "react";
import { LocationContainer } from "../Styled";

const Location = ({ location }) => (
	<LocationContainer>
		<span className="icon-local" />
		<div className="address">
			<div>
				{location.street} {location.number}
			</div>
			<div>
				{location.zip} {location.city}
			</div>
			<div>{location.country}</div>
		</div>
	</LocationContainer>
);

export default Location;
