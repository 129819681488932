import React, { Component } from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";

class Scrubber extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMouseDown: false,
			initialMousePos: null,
			initialValue: 0,
			value: props.value,
			steps: props.steps,
			mounted: true
		};
		if (props.debounce)
			this.debouncedChange = _debounce(props.onAfterChange, 500);
	}

	componentDidUpdate({ value }) {
		if (value !== this.props.value) {
			this.setState({
				value: this.constrain(this.props.value, this.props.min, this.props.max)
			});
		}
	}

	componentWillUnmount() {
		if (this.props.debounce) this.debouncedChange.cancel();
		document.removeEventListener("mousemove", this.handleMouseMove);
		document.removeEventListener("mouseup", this.handleMouseUp);
		this.setState({ mounted: false });
	}

	handleMouseDown = e => {
		if (!this.state.mounted) return;
		const initialMousePos = {
			x: e.clientX,
			y: e.clientY
		};

		this.setState({
			initialMousePos,
			initialValue: this.state.value,
			isMouseDown: true
		});

		document.addEventListener("mousemove", this.handleMouseMove);
		document.addEventListener("mouseup", this.handleMouseUp);
	};

	handleMouseMove = e => {
		if (this.state.isMouseDown) {
			const { min, max } = this.props;
			let newValue =
				this.state.initialValue +
				(e.clientX - this.state.initialMousePos.x) * this.state.steps;

			newValue = this.constrain(newValue, min, max);

			this.setState({
				value: newValue
			});
		}
	};

	handleMouseUp = () => {
		this.props.onAfterChange(this.state.value);
		this.setState({
			isMouseDown: false
		});
	};

	handleInput = e => {
		if (e.which < 48 || e.which > 57) return e.preventDefault();
		this.setState({ value: e.target.value });
	};

	handleChange = e => {
		e.persist(); // Take care of React's event pooling
		const { min, max, debounce } = this.props;
		if (e.target.value.trim() === "") {
			this.setState({
				value: ""
			});
			return this.debouncedChange.cancel();
		}
		const value = parseFloat(e.target.value);
		const constrainVal = this.constrain(value, min, max);
		this.setState(
			{
				value: constrainVal
			},
			() => {
				if (debounce) {
					return this.debouncedChange(this.state.value);
				}
			}
		);
	};

	handleBlur = e => {
		let { value } = this.state;
		const { min, max } = this.props;
		value = isNaN(parseFloat(e.target.value))
			? min
			: parseFloat(e.target.value);
		const constrainVal = this.constrain(value, min, max);
		this.setState(
			{
				value: constrainVal
			},
			() => {
				this.props.onAfterChange(constrainVal);
			}
		);
	};

	constrain = (value, min, max, decimals) => {
		decimals = typeof decimals !== "undefined" ? decimals : 0;
		if (min !== undefined && max !== undefined) {
			return this.round(
				Math.min(Math.max(parseFloat(value), min), max),
				decimals
			);
		}
		return value;
	};

	round = (value, decimals) => {
		return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
	};

	render() {
		return (
			<input
				className={`scrubber-input ${this.props.className}`}
				onMouseDown={this.handleMouseDown}
				onKeyPress={this.handleInput}
				onChange={this.handleChange}
				onBlur={this.handleBlur}
				value={this.state.value}
				disabled={this.props.disabled}
			/>
		);
	}
}

Scrubber.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	steps: PropTypes.number,
	value: PropTypes.number,
	onAfterChange: PropTypes.func,
	debounce: PropTypes.bool
};

Scrubber.defaultProps = {
	min: 0,
	max: 500,
	steps: 1,
	value: 7,
	onAfterChange: () => {},
	debounce: false
};

export default Scrubber;
