import React from "react";
import styles from "./search-empty-state.module.scss";
import { ReactComponent as SearchCompaniesEmptyIcon } from "static/icons/search-companies-empty-state-icon.svg";

export default function SearchEmptyState() {
	return (
		<div className={styles.container}>
			<div className={styles.heading}>
				<h1>No companies found for your search</h1>
				<p>Try refining your search by using different keywords</p>
			</div>
			<SearchCompaniesEmptyIcon />
		</div>
	);
}
