import React from "react";
import _get from "lodash/get";
import cx from "classnames";
import { Controller } from "react-hook-form";
import { ReactComponent as ErrorIcon } from "static/icons/error-field.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete.svg";
import { EMAILS } from "config";
import styles from "./../../profile.module.scss";
import personalProfileStyles from "./personal-profile.module.scss";
import AddField from "../AddField";
import cardStyle from "../EditableSection/editable-section.module.scss";

const EmailFields = ({
	canMerge,
	personalProfile,
	canAddToPool,
	isProfileAreadyInPool,
	onChange: onChangeProp,
	onDelete,
	register,
	onViewProfileMergeClick,
	onAddToPoolClick,
	onViewAlreadyInPoolClick,
	onAddEmailClick,
	emailFields,
	isEditMode,
	isNewEmailExists,
	onBlur: onBlurProp,
	onKeyDown,
	errors,
	control,
	onEmailPrimaryClick,
	emails
}) => {
	const isNewEmail = email => {
		const emails = _get(personalProfile, EMAILS, []);
		const index = emails.findIndex(emailObject => emailObject.email === email);

		return index === -1;
	};

	return (
		<div className={cx(cardStyle.grid, styles.MarginBottom2)}>
			{emailFields.map((item, index) => {
				let marginBottom = "auto";

				const { name, ref, onChange, onBlur } = register(
					`emails[${index}].email`
				);
				if (canMerge[index]) {
					marginBottom = "5rem";
				} else if (isProfileAreadyInPool[index]) {
					marginBottom = "5.3rem";
				} else if (canAddToPool[index]) {
					marginBottom = "4.1rem";
				}
				return (
					<div key={item.id} className={styles.emailBox}>
						{index === 0 && <div className={styles.personalLabel}>Email</div>}
						<div
							className={cardStyle.info}
							style={{
								marginBottom
							}}
						>
							<input
								defaultValue={`${item.email}`}
								type="text"
								disabled={!(isEditMode || isNewEmail(item.email))}
								placeholder="Claudia.james@CJhernandez.com"
								onChange={e => {
									onChange(e);
									onChangeProp(index);
								}}
								onKeyDown={onKeyDown}
								onBlur={e => {
									onBlur(e);
									onBlurProp(e);
								}}
								name={name}
								ref={ref}
							/>
							{isEditMode && !_get(errors, `emails[${index}].email.message`) ? (
								<button
									type="button"
									className={styles.deleteItem}
									disabled={item.is_primary}
									onClick={e => onDelete(e, index)}
								>
									<DeleteIcon />
									Delete item
								</button>
							) : _get(errors, `emails[${index}].email.message`) &&
							  (isEditMode || isNewEmail(item.email)) ? (
								<div className={personalProfileStyles.emailErrorContainer}>
									<ErrorIcon />
									<div className={personalProfileStyles.primaryError}>
										{_get(errors, `emails[${index}].email.message`)}
									</div>
									{canMerge[index] && (
										<div className={personalProfileStyles.mergeError}>
											This email address is used by another candidate profile
											which you can merge in one profile.&nbsp;
											<div
												className={personalProfileStyles.link}
												onClick={() => onViewProfileMergeClick(index)}
											>
												View profile
											</div>
										</div>
									)}

									{canAddToPool[index] && (
										<div className={personalProfileStyles.errorAddToPool}>
											Add this profile to the pool.&nbsp;
											<div
												className={personalProfileStyles.link}
												onClick={() => onAddToPoolClick(index)}
											>
												View candidate
											</div>
										</div>
									)}

									{isProfileAreadyInPool[index] && (
										<div className={personalProfileStyles.errorCandidateInPool}>
											This user is already a member of your talent pool&nbsp;
											<div
												className={personalProfileStyles.link}
												onClick={() => onViewAlreadyInPoolClick(index)}
											>
												View candidate
											</div>
										</div>
									)}
								</div>
							) : (
								!isNewEmail(item.email) && (
									<span className={styles.radio_input}>
										<Controller
											name={`emails[${index}].is_primary`}
											control={control}
											render={({ field: { value } }) => {
												return (
													<input
														type="radio"
														className="styled"
														name={`emails.is_primary`}
														checked={value}
														value={value}
														onChange={() => onEmailPrimaryClick(index)}
														onBlur={onBlur}
														onKeyDown={onKeyDown}
														id={item.id}
													/>
												);
											}}
										/>
										<span className={styles.radio_control}></span>
										<label
											className={personalProfileStyles.radioButtonLabel}
											htmlFor={item.id}
										>
											Set as primary
										</label>
									</span>
								)
							)}{" "}
						</div>
					</div>
				);
			})}

			<div className={styles.addBox}>
				{emailFields.length === 0 && (
					<div className={cardStyle.label}>Email</div>
				)}
				{!(isEditMode || isNewEmailExists) && (
					<AddField
						onClick={onAddEmailClick}
						label="Add email"
						disabled={
							isEditMode ??
							_get(errors, `emails[${emailFields.length - 1}].email.message`) ??
							(emails.length !== 0 &&
								!Boolean(emails[emails.length - 1]?.email))
						}
					/>
				)}
			</div>
		</div>
	);
};
export default EmailFields;
