import React from "react";
import { removeCountryCodeFromNumber } from "config/helpers";
import ProactiveApplicationMotivationText from "../components/permanent/ProactiveApplicationMotivationText/ProactiveApplicationMotivationText";
import { RenderDate } from "../ui";

const DateCell = props => {
	return <RenderDate date={props.cell.value} />;
};

export const PROACTIVE_APPLICATIONS_TABLE_DEFINITION = [
	{
		Header: "Date",
		accessor: "created_at",
		Cell: DateCell
	},
	{
		Header: "Applicant's name",
		accessor: "applicant_name"
	},
	{
		Header: "Email",
		accessor: "email"
	},
	{
		Header: "Phone",
		accessor: "phone",
		Cell: props => {
			const value = props.cell.value;

			return removeCountryCodeFromNumber(value);
		}
	},
	{
		Header: "Motivation",
		accessor: "motivation",
		Cell: ProactiveApplicationMotivationText
	}
];
