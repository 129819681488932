import React from "react";
// import styles from "./create-request-subcategory-field.module.scss";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { ComboBox } from "common/ComboBox";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import { Controller } from "react-hook-form";
import { Item } from "react-stately";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import useElements from "hooks/useLayoutElements";

const CreateRequestSubcategoryField = ({
	error,
	control,
	list = [],
	subCategory,
	setValue
}) => {
	const { mainElement } = useElements();
	const getSubCategoryById = key => {
		return list.find(({ _id }) => _id === key);
	};

	const onSubCategorySelectionChange = (key, onChange) => {
		const _object = getSubCategoryById(key);
		const value = {
			label: _object?.name,
			value: key
		};

		if (subCategory.value !== key) {
			setValue("_function", { label: "", value: null });
		}
		onChange(value);
	};

	const onSubCategoryInputChange = (value, onChange) => {
		const newValue = value === "" ? null : subCategory.value;

		const _object = {
			label: value,
			value: newValue
		};

		if (subCategory.value !== newValue) {
			setValue("_function", { label: "", value: null });
		}
		onChange(_object);
	};
	return (
		<CreateRequestFieldContainer>
			<CreateRequestLabelContainer>
				<CreateRequestFieldLabel displayStar>
					Sub-category
				</CreateRequestFieldLabel>
			</CreateRequestLabelContainer>
			<Controller
				name="subCategory"
				control={control}
				render={({ field: { value, onChange } }) => {
					return (
						<ComboBox
							popoverContainer={mainElement}
							displayButton={false}
							selectedKey={value?.value}
							inputValue={value?.label}
							onInputChange={value => onSubCategoryInputChange(value, onChange)}
							onSelectionChange={key =>
								onSubCategorySelectionChange(key, onChange)
							}
						>
							{list.map(({ _id, name }) => (
								<Item key={_id}>{name}</Item>
							))}
						</ComboBox>
					);
				}}
			/>

			<CreateRequestFieldError error={error} />
		</CreateRequestFieldContainer>
	);
};

export default CreateRequestSubcategoryField;
