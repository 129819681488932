import { useQuery } from "react-query";
import { GET_CURRENCIES } from "config/api-endpoints";
import { client } from "lib/api-client";

const getCurrencies = () => {
	return client(GET_CURRENCIES, {
		body: {}
	});
};

export const useGetCurrenciesList = () => {
	const res = useQuery(GET_CURRENCIES, getCurrencies, {
		staleTime: Infinity
	});

	return res;
};
