import React from "react";
import { StyledSelect } from "./styled";
const MultipleSelect = ({
	options,
	value,
	handleSelectAttendees,
	className,
	hasError
}) => {
	// const [multiValue, setMultiValue] = useState([defaultValue]);
	const handleChangeSelect = value => {
		handleSelectAttendees(value);
	};
	return (
		<StyledSelect
			multi
			name="colors"
			options={options}
			className={className ? className : "basic-multi-select"}
			classNamePrefix="select"
			clearable={false}
			onChange={handleChangeSelect}
			searchable={true}
			value={value}
			hasError={hasError}
		/>
	);
};

export default MultipleSelect;
