import React, { useRef, useState } from "react";
import styles from "./bid-actions-select.module.scss";
import cx from "classnames";
import { ReactComponent as DotHorizontal } from "static/icons/dots-horizontal.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { Link } from "react-router";
import Tooltip from "common/Tippy";

const BidActionSelect = ({ bidActionsManager }) => {
	const menuRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);

	const options = bidActionsManager.getActionsExcludingPrimary();

	useOnClickOutside(menuRef, () => {
		setIsOpen(false);
	});

	const toggleDropdown = () => {
		setIsOpen(value => !value);
	};

	return options.length ? (
		<div ref={menuRef} className={styles.customSelect}>
			<button className={styles.more} onClick={toggleDropdown}>
				<DotHorizontal />
			</button>
			<div className={isOpen ? styles.visible : styles.hidden}>
				<ul className={styles.optionsList}>
					{options.map(
						({
							key,
							Icon,
							label,
							action,
							status,
							to,
							tooltipText,
							displayToolTip
						}) => {
							const link = to ? (
								<Link
									key={key}
									className={cx(
										styles.option,
										status === "disabled" ? styles.option_disabled : undefined
									)}
									to={to}
								>
									<Icon />
									<span
										className={cx(
											styles.text,
											status === "danger" ? styles.text_danger : undefined
										)}
									>
										{label}
									</span>
								</Link>
							) : (
								<li
									key={key}
									className={cx(
										styles.option,
										status === "disabled" ? styles.option_disabled : undefined
									)}
									onClick={status !== "disabled" && action}
								>
									<Icon />
									<span
										className={cx(
											styles.text,
											status === "danger" ? styles.text_danger : undefined
										)}
									>
										{label}
									</span>
								</li>
							);

							return status === "disabled" || displayToolTip ? (
								<Tooltip
									content={
										(status === "disabled" || displayToolTip) && tooltipText
									}
									theme="dark"
									placement="top"
									key={key}
								>
									{link}
								</Tooltip>
							) : (
								link
							);
						}
					)}
				</ul>
			</div>
		</div>
	) : (
		<></>
	);
};

export default BidActionSelect;
