import create from "zustand";

const initialState = {
	page: 0,
	searchQuery: "",
	modal: {
		slug: "",
		company: null,
		actionType: "",
		formIsValid: false
	}
};

const companiesStore = set => ({
	...initialState,
	// modal setter
	setModalSlug: slug =>
		set(state => ({ ...state, modal: { ...state.modal, slug } })),
	setSelectedCompany: company =>
		set(state => ({ ...state, modal: { ...state.modal, company } })),
	setActionType: actionType =>
		set(state => ({ ...state, modal: { ...state.modal, actionType } })),
	setFormIsValid: formIsValid =>
		set(state => ({ ...state, modal: { ...state.modal, formIsValid } })),
	// companies filter setter
	setSearchQuery: searchQuery => set(state => ({ ...state, searchQuery })),
	setPage: page => set(state => ({ ...state, page })),
	// clear store
	resetStore: () => set(() => initialState),
	resetModal: () =>
		set(state => ({ ...state, modal: { ...initialState.modal } }))
});

const useCompaniesStore = create(companiesStore);
export default useCompaniesStore;
