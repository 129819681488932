import create from "zustand";

const useCurrentRequest = create(set => ({
	requestId: null,
	setRequest: value => {
		set({
			requestId: value
		});
	}
}));

export default useCurrentRequest;
