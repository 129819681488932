import { yupResolver } from "@hookform/resolvers/yup";
import { CONTRACT_TYPE, EXPECTED_DURATION, ONSITE_OPTION } from "config";
import { addressObjectToString, getHTMLTextLength } from "config/helpers";
import { useGetUser } from "hooks/useGetUser";
import { useForm } from "react-hook-form";
import { mixed, object, string } from "yup";
import { DAILY_HOURLY, EDITOR_MAX_LENGTH, FULL_TIME } from "../utils/constants";
import { formateDate } from "../utils/helper";
import { TIMESHEET_FREQUENCY_MONTHLY } from "config";

export const useRequestDetailsForm = () => {
	const user = useGetUser();

	const schema = object().shape({
		title: string().required("Value is mandatory"),
		description: string().test("Required", "Value is mandatory", function(
			value
		) {
			const length = getHTMLTextLength(value);

			if (length > 0 && length <= EDITOR_MAX_LENGTH) {
				return true;
			} else {
				return !!value?.value;
			}
		}),
		hourlyRate: string().required("Value is mandatory"),
		applicantLocation: object().test(
			"Required",
			"Please select applicants location.",
			function(value) {
				return !!value?.value;
			}
		),
		hours: string().test("Required", "Value is mandatory", function(value) {
			const { rate } = this.parent;

			if (rate === DAILY_HOURLY[0].value && !value) {
				return false;
			} else {
				return true;
			}
		}),
		featuredVideo: string()
			.url("FeaturedVideo must be a valid URL")
			.nullable(),
		startDate: mixed().test("Required", "Start date is invalid", function(
			startDate
		) {
			let isValid = true;

			if (!startDate) {
				isValid = false;
			}

			return isValid;
		}),
		endDate: mixed().test("Required", "End date is invalid", function(endDate) {
			if (!endDate) {
				return false;
			} else {
				const { startDate } = this.parent;

				if (formateDate(startDate) > formateDate(endDate)) {
					return false;
				} else {
					return true;
				}
			}
		}),
		vacancyAddress: object().test(
			"Required",
			"Please select vacancy address",
			function(value) {
				if (value?.value) {
					return true;
				} else {
					return false;
				}
			}
		),
		currency: object().test("Required", "Please select a currency.", function(
			value
		) {
			return !!value?.value;
		})
	});
	const res = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			description: [],
			durationType: EXPECTED_DURATION[0].value,
			vacancyAddress: getAddressDefaultValue(user),
			vacancyAddressType: "company_address",
			remoteEligibility: ONSITE_OPTION.value,
			rate: DAILY_HOURLY[0].value,
			timesheetFrequency: TIMESHEET_FREQUENCY_MONTHLY,
			contractType: CONTRACT_TYPE[0].value,
			time: FULL_TIME.value,
			hours: window.moment({
				hours: 8,
				minutes: 0
			})
		},
		shouldUnregister: false
	});

	return res;
};

const getAddressDefaultValue = data => {
	const value = {
		street: data.street || data.company_street,
		number: data.number || data.company_number,
		box: data.box || data.company_box,
		city: data.city || data.company_city,
		zip: data.zip || data.company_zip,
		country: data.country || data.company_country,
		longitude: data.longitude || data.company_longitude,
		latitude: data.latitude || data.company_latitude,
		iso_country: data.iso_country || data.company_iso_country
	};

	const label = addressObjectToString(value);

	return {
		label,
		value
	};
};
