import React from "react";
import chartSvg from "static/images/chart.svg";
import { NoDataAvailable, TitleChart } from "../styled";

function ChartEmpty({ title }) {
	return (
		<>
			<img src={chartSvg} width="560" height="360px" />
			<NoDataAvailable>
				<TitleChart>
					<h2>{title}</h2>
				</TitleChart>
				<div className="Box">
					<span className="icon-empty-folder icone">
						<span className="path1"></span>
						<span className="path2"></span>
						<span className="path3"></span>
						<span className="path4"></span>
					</span>
					<h2>No data available</h2>
					<h3>Looks like there’s no data.</h3>
				</div>
			</NoDataAvailable>
		</>
	);
}

export default ChartEmpty;
