import React, { useState } from "react";
import { DISPUTED, SUBMITTED } from "config";
import MultiAction from "../MultiAction";
import { ReactComponent as DisputeTimesheetIcon } from "static/icons/dispute-timesheet-icon.svg";
import { ReactComponent as GlobalActionIcon } from "static/icons/arrow-right-icon.svg";
import { ReactComponent as DiscardChangesIcon } from "static/icons/discard-changes-icon.svg";
import GlobalTooltip from "common/GlobalTooltip";
import { Button, ButtonsContainer } from "common/styled/buttons";
import toaster from "common/Toaster";
import Icon from "common/styled/icons";
import { Text, Flex } from "rebass";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import {
	ACCEPT_DISPUTE_TIMESHEET,
	ACCEPT_WORKTIMES,
	ACCEPT_EXPENSES,
	ACCEPT_ALL_ENTRIES,
	ACCEPT_TIMESHEET
} from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";
import { get } from "lodash";
import { ACCEPTED } from "config";
import { ApproveButtonContainer } from "./styles";

const CHECKBOX_STATES = {
	Checked: "Checked",
	Indeterminate: "Indeterminate",
	Empty: "Empty"
};

const disputeTimesheet = data =>
	client(ACCEPT_DISPUTE_TIMESHEET, { body: data });

const acceptAllEntries = data => client(ACCEPT_ALL_ENTRIES, { body: data });

const acceptTimesheet = data => client(ACCEPT_TIMESHEET, { body: data });

const ClientTimesheetActions = ({
	isLocked,
	status,
	timesheetId,
	disputedWorkTime,
	disputedExpense,
	setDisputedWorkTime,
	setDisputedExpense,
	loadTimeSheet,
	isExpense,
	isMultiCheck,
	isClient,
	isMultiActionsMode,
	setIsMultiEditMode,
	setIsMultiActionsMode,
	isMultiEditMode,
	month,
	list,
	hasTag,
	getInitialVisibleMonth,
	cancelEdit,
	currentDocument,
	isWorkTime,
	isOverTime,
	type,
	canApproveTags,
	isAllElementHadChecked,
	allItems,
	canApprove,
	companyId,
	setIsAllElementHasChecked,
	setCheckAll,
	setChecked,
	checked,
	isTimesheetEmpty = false,
	reportingSettings
}) => {
	const [showDisputeModal, setShowDisputeModal] = useState(false);
	const [showAcceptModal, setShowAcceptModal] = useState(false);
	const [showAcceptTimesheetModal, setShowAcceptTimesheetModal] = useState(
		false
	);
	const [showAcceptAllEntriesModal, setAcceptAllEntriesModal] = useState(false);

	const toggleAcceptTimesheetModal = () =>
		setShowAcceptTimesheetModal(!showAcceptTimesheetModal);
	const toggleAcceptModal = () => setShowAcceptModal(!showAcceptModal);
	const toggleDisputeModal = () => setShowDisputeModal(!showDisputeModal);
	const toggleAcceptAllEntriesModal = () =>
		setAcceptAllEntriesModal(!showAcceptAllEntriesModal);

	const clearResubmittedTimesheet = () => {
		setDisputedExpense([]);
		setDisputedWorkTime([]);
	};
	const [onDisputeTimesheet] = useMutation(disputeTimesheet, {
		onSuccess: () => {
			clearResubmittedTimesheet();
			toggleDisputeModal();
			loadTimeSheet();
		},
		onError: error => {
			if (error && error.status === 422) {
				let errorMsg = get(error, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			} else {
				toaster.danger(get(error, "detail", get(error, "title")));
			}
		}
	});

	const accepteTimesheet = data =>
		client(!isExpense ? ACCEPT_WORKTIMES : ACCEPT_EXPENSES, { body: data });
	const [onAcceptTimesheet] = useMutation(accepteTimesheet, {
		onSuccess: () => {
			toggleAcceptModal();
			cancelEdit();
			loadTimeSheet();
		},
		onError: error => {
			if (error && error.status === 422) {
				let errorMsg = get(error, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			} else {
				toaster.danger(get(error, "detail", get(error, "title")));
			}
		}
	});

	const [onAcceptAllEntries] = useMutation(acceptAllEntries, {
		onSuccess: () => {
			toggleAcceptAllEntriesModal();
			loadTimeSheet();
		},
		onError: error => {
			if (error && error.status === 422) {
				let errorMsg = get(error, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			} else {
				toaster.danger(get(error, "detail", get(error, "title")));
			}
		}
	});

	const [onAcceptEmptyTimesheet] = useMutation(acceptTimesheet, {
		onSuccess: () => {
			toggleAcceptTimesheetModal();
			loadTimeSheet();
		},
		onError: error => {
			if (error && error.status === 422) {
				let errorMsg = get(error, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			} else {
				toaster.danger(get(error, "detail", get(error, "title")));
			}
		}
	});

	const handleOnDisputeTimesheet = () => {
		const data = {
			timesheet_id: timesheetId,
			expenses: {
				edited: disputedExpense.map(item => {
					delete item.status;
					return item;
				})
			},
			worktimes: {
				edited: disputedWorkTime.map(item => {
					delete item.hours_representation;
					delete item.client_amount;
					delete item.status;
					delete item.duration;
					return item;
				})
			}
		};

		onDisputeTimesheet(data);
	};

	const handleOnAcceptTimesheet = () => {
		let mappedWorktimesIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);

		let mappedExpensesIds = disputedExpense.map(
			({ worktime_id }) => worktime_id
		);
		let worktimes = allItems.worktimes
			.filter(item => {
				if (isAllElementHadChecked.includes(item._id))
					return (
						canApprove(item.tag) &&
						!mappedWorktimesIds.includes(item._id) &&
						item.status !== ACCEPTED &&
						get(item, "last_user_action.active_company", false) !== companyId
					);

				return false;
			})
			.map(({ _id }) => _id);
		let overtimes = get(allItems, "overtimes", [])
			.filter(item => {
				if (isAllElementHadChecked.includes(item._id))
					return (
						canApprove(item.tag) &&
						!mappedWorktimesIds.includes(item._id) &&
						item.status !== ACCEPTED &&
						get(item, "last_user_action.active_company", false) !== companyId
					);

				return false;
			})
			.map(({ _id }) => _id);
		let expenses = allItems.expenses
			.filter(item => {
				if (isAllElementHadChecked.includes(item._id))
					return (
						canApprove(item.tag) &&
						!mappedExpensesIds.includes(item._id) &&
						item.status !== ACCEPTED &&
						get(item, "last_user_action.active_company", false) !== companyId
					);

				return false;
			})
			.map(({ _id }) => _id);
		const data = {
			timesheet_id: timesheetId,
			...(isExpense
				? { expenses }
				: { worktimes: [...worktimes, ...overtimes] })
		};
		onAcceptTimesheet(data);
	};

	const handleOnAcceptAllEntries = () => {
		let mappedWorktimesIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);

		let mappedExpensesIds = disputedExpense.map(
			({ worktime_id }) => worktime_id
		);
		let worktimes = allItems.worktimes
			.filter(
				item =>
					canApprove(item.tag) &&
					!mappedWorktimesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
			)
			.map(({ _id }) => _id);
		let overtimes = allItems.overtimes
			.filter(
				item =>
					canApprove(item.tag) &&
					!mappedWorktimesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
			)
			.map(({ _id }) => _id);
		let expenses = allItems.expenses
			.filter(
				item =>
					canApprove(item.tag) &&
					!mappedExpensesIds.includes(item._id) &&
					item.status !== ACCEPTED &&
					get(item, "last_user_action.active_company", false) !== companyId
			)
			.map(({ _id }) => _id);
		const data = {
			timesheet_id: timesheetId,
			worktimes: [...worktimes, ...overtimes],
			expenses
		};
		onAcceptAllEntries(data);
	};

	const isEdited = disputedExpense.length > 0 || disputedWorkTime.length > 0;

	const disableAcceptAllEntries = () => {
		let mappedWorktimesIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);

		let mappedExpensesIds = disputedExpense.map(
			({ worktime_id }) => worktime_id
		);
		let worktimes = allItems.worktimes
			.filter(item => {
				return (
					(item.status === SUBMITTED && canApprove(item.tag)) ||
					(canApprove(item.tag) &&
						!mappedWorktimesIds.includes(item._id) &&
						item.status !== ACCEPTED &&
						get(item, "last_user_action.active_company", false) !== companyId)
				);
			})
			.map(({ _id }) => _id);
		let overtimes = allItems.overtimes
			.filter(item => {
				return (
					(item.status === SUBMITTED && canApprove(item.tag)) ||
					(canApprove(item.tag) &&
						!mappedWorktimesIds.includes(item._id) &&
						item.status !== ACCEPTED &&
						get(item, "last_user_action.active_company", false) !== companyId)
				);
			})
			.map(({ _id }) => _id);
		let expenses = allItems.expenses
			.filter(item => {
				return (
					(item.status === SUBMITTED && canApprove(item.tag)) ||
					(canApprove(item.tag) &&
						!mappedExpensesIds.includes(item._id) &&
						item.status !== ACCEPTED &&
						get(item, "last_user_action.active_company", false) !== companyId)
				);
			})
			.map(({ _id }) => _id);
		return worktimes.length || overtimes.length || expenses.length;
	};

	const showTooltipAcceptAllEntries = () => {
		let mappedWorktimesIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);

		let mappedExpensesIds = disputedExpense.map(
			({ worktime_id }) => worktime_id
		);
		let worktimes = allItems.worktimes
			.filter(item => {
				return (
					((item.status === SUBMITTED || item.status === ACCEPTED) &&
						canApprove(item.tag)) ||
					(canApprove(item.tag) &&
						!mappedWorktimesIds.includes(item._id) &&
						item.status !== ACCEPTED &&
						get(item, "last_user_action.active_company", false) !== companyId)
				);
			})
			.map(({ _id }) => _id);
		let expenses = allItems.expenses
			.filter(item => {
				return (
					((item.status === SUBMITTED || item.status === ACCEPTED) &&
						canApprove(item.tag)) ||
					(canApprove(item.tag) &&
						!mappedExpensesIds.includes(item._id) &&
						item.status !== ACCEPTED &&
						get(item, "last_user_action.active_company", false) !== companyId)
				);
			})
			.map(({ _id }) => _id);
		return worktimes.length || expenses.length;
	};

	const RenderGlobalAction = () => {
		const displayDiscardChangesButton =
			Boolean(disputedExpense.length) || Boolean(disputedWorkTime.length);
		const discardChanges = () => {
			setDisputedExpense([]);
			setDisputedWorkTime([]);
		};

		return (
			(status === SUBMITTED || status === DISPUTED) && (
				<ButtonsContainer display="flex">
					{isEdited && !Boolean(isMultiCheck) ? (
						<React.Fragment>
							{displayDiscardChangesButton && (
								<button
									className="discard-changes-button"
									onClick={discardChanges}
								>
									<DiscardChangesIcon style={{ marginRight: "8px" }} />
									Discard changes
								</button>
							)}
							<Button
								size={14}
								key="dispute"
								onClick={() => setShowDisputeModal(true)}
								px={30}
								py={8}
								color="timesheetWarning"
								disabled={isLocked}
								style={{
									fontFamily: "BasierCircle",
									fontSize: "13px",
									fontWeight: "600",
									backgroundColor: "#E99010",
									color: "white",
									height: "49px"
								}}
							>
								<DisputeTimesheetIcon style={{ marginRight: "6px" }} />
								Dispute timesheet
							</Button>
						</React.Fragment>
					) : isTimesheetEmpty ? (
						<Button
							disabled={isLocked}
							color="success"
							size={14}
							onClick={toggleAcceptTimesheetModal}
							key="accept"
						>
							<Icon className="fa icon-check" /> Approve timesheet
						</Button>
					) : (
						<ApproveButtonContainer
							showTooltip={!Boolean(disableAcceptAllEntries())}
						>
							<GlobalTooltip
								placement="top"
								active={!Boolean(showTooltipAcceptAllEntries())}
								overlay={
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontStyle: "normal",
											fontWeight: 400,
											fontSize: "12px",
											lineHeight: "18px",
											color: "#fff"
										}}
									>
										You can only view the content of this timesheet
									</Text>
								}
								trigger="hover"
								overlayClassName={`timesheet-edit-tooltip`}
							>
								<Button
									disabled={
										isLocked ||
										!Boolean(
											[
												...allItems.expenses,
												...allItems.worktimes,
												...allItems.overtimes
											].length
										)
									}
									color="success"
									size={14}
									onClick={() => {
										if (Boolean(disableAcceptAllEntries())) {
											toggleAcceptAllEntriesModal();
										}
									}}
									key="accept"
								>
									<Icon className="fa icon-check" /> Approve all entries
								</Button>
							</GlobalTooltip>
						</ApproveButtonContainer>
					)}
				</ButtonsContainer>
			)
		);
	};

	const RenderMultiActionMode = () => (
		<Flex>
			<Button
				color="danger"
				size={14}
				onClick={() => {
					setIsMultiEditMode(true);
					setIsMultiActionsMode(false);
				}}
				style={{
					marginRight: "10px"
				}}
				disabled={isLocked}
				key="accept"
			>
				<Icon className="fa icon-check" /> Edit{" "}
				{isExpense ? "expense" : isOverTime ? "overtime" : "worktime"}
			</Button>
			<Button
				color="success"
				size={14}
				onClick={() => {
					toggleAcceptModal();
					setIsMultiActionsMode(false);
				}}
				disabled={isLocked || !Boolean(disableAcceptAllEntries())}
				key="accept"
			>
				<Icon className="fa icon-check" /> Approve{" "}
				{isExpense ? "expense" : isOverTime ? "overtime" : "worktime"}
			</Button>
		</Flex>
	);

	return (
		<div className={!isMultiEditMode ? "" : "backdrop"}>
			<div
				style={{
					position: !isMultiEditMode ? "absolute" : "fixed",
					zIndex: 998,
					bottom: 0,
					left: !isMultiEditMode ? "unset" : "100px",
					width: "calc(100% - 100px )",
					padding: "10px",
					background: "#FFFFFF",
					boxShadow: "0px -4px 21px rgba(33, 43, 54, 0.05)",
					borderRadius: "10px 10px 0px 0px",
					margin: !isMultiEditMode ? "0px -15px" : 0
				}}
			>
				<Flex width="100%" alignItems="center" justifyContent="space-between">
					{!isMultiEditMode && (
						<Flex alignItems="center">
							<GlobalActionIcon />
							<Text
								ml="12px"
								sx={{
									fontFamily: "BasierCircle",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: "14px",
									lineHeight: "17px"
								}}
							>
								{!isMultiActionsMode ? (
									"Global action"
								) : (
									<React.Fragment>
										Choose your action on{" "}
										<Text
											sx={{
												color: "#FD6950",
												display: "inline-block"
											}}
										>
											{isMultiCheck}
										</Text>{" "}
										selected entries{" "}
									</React.Fragment>
								)}
							</Text>
						</Flex>
					)}
					{isMultiEditMode ? (
						<MultiAction
							itemSelected={isAllElementHadChecked.length}
							disabled={false}
							month={month}
							list={list}
							timesheetId={timesheetId}
							isClient={isClient}
							hasTag={hasTag}
							getInitialVisibleMonth={getInitialVisibleMonth}
							cancelEdit={cancelEdit}
							currentDocument={currentDocument}
							isExpense={isExpense}
							isWorkTime={isWorkTime}
							isOverTime={isOverTime}
							loadTimeSheet={loadTimeSheet}
							isAllElementHadChecked={isAllElementHadChecked}
							disputedWorkTime={disputedWorkTime}
							disputedExpense={disputedExpense}
							setDisputedWorkTime={setDisputedWorkTime}
							setDisputedExpense={setDisputedExpense}
							type={type}
							canApproveTags={canApproveTags}
							reportingSettings={reportingSettings}
						/>
					) : isMultiActionsMode ? (
						<RenderMultiActionMode />
					) : (
						<RenderGlobalAction />
					)}
				</Flex>
			</div>
			<ConfirmationModal
				active={showAcceptAllEntriesModal}
				modalName="accept-timesheet"
				onClose={toggleAcceptAllEntriesModal}
				firstButton={{
					action: handleOnAcceptAllEntries,
					label: "Yes, approve this timesheet"
				}}
				title={`Are you sure you want to approve this timesheet?`}
				type={ConfirmationTypes.confirmation}
			/>
			<ConfirmationModal
				active={showAcceptModal}
				modalName="accept-worktimes"
				onClose={() => {
					toggleAcceptModal();
					if (checked) {
						setIsAllElementHasChecked([]);
						setCheckAll(checkAll => (checkAll === false ? undefined : false));
						setChecked(CHECKBOX_STATES.Empty);
					}
				}}
				firstButton={{
					action: () => {
						handleOnAcceptTimesheet();
					},
					label: `Yes, approve this timesheet`,
					disabled: !isClient && status === SUBMITTED
				}}
				title={`Are you sure you want to approve this timesheet ?`}
				type={ConfirmationTypes.confirmation}
			/>
			<ConfirmationModal
				active={showAcceptTimesheetModal}
				modalName="accept-timesheets"
				onClose={toggleAcceptTimesheetModal}
				firstButton={{
					action: () => {
						onAcceptEmptyTimesheet({ timesheet_id: timesheetId });
					},
					label: "Yes, approve this timesheet"
				}}
				title="Are you sure you want to approve this timesheet ?"
				type={ConfirmationTypes.confirmation}
			/>
			<ConfirmationModal
				active={showDisputeModal}
				modalName="dispute-timesheet"
				onClose={toggleDisputeModal}
				firstButton={{
					action: handleOnDisputeTimesheet,
					label: `Yes, dispute this timesheet`,
					color: "timesheetWarning"
				}}
				secondButton={{
					label: "Cancel"
				}}
				title={`Are you sure you want to disputed this timesheet ?`}
				type={"timesheet-warning"}
			/>
		</div>
	);
};

export default ClientTimesheetActions;
