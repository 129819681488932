import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { MIN_PROMPT_LENGTH } from "../utils/constants";

export const useEnhanceTextForm = () => {
	const schema = yup.object().shape({
		prompt: yup
			.string()
			.required("Description is required and can't be empty")
			.min(
				MIN_PROMPT_LENGTH,
				`Description must be at least ${MIN_PROMPT_LENGTH} characters long`
			)
	});

	const formMethods = useForm({
		resolver: yupResolver(schema)
	});

	return formMethods;
};
