import React, { useEffect, useState } from "react";
import cx from "classnames";
import { formatDate } from "common/Functions";
import styles from "./mission-extensions-list.module.scss";
import { MISSION_EXTENSIONS_COLUMNS } from "modules/MissionsDetails/utils/constant";
import { CustomSkeletonCard } from "common/CustomSkeletonCard";
import useGetMissionExtensions from "modules/MissionsDetails/hooks/useGetMissionExtensions";
import { MissionExtensionDetailsDrawer } from "../MissionExtensionDetailsDrawer";
import { ReactComponent as ExpandIcon } from "static/icons/expand-2-icon.svg";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { CURRENCY_SUFFIX, HOURLY, SUPER_ADMIN, USER_HIREME_NAME } from "config";
import { useIsDisplayCostEnabled } from "hooks/useIsDisplayCostEnabled";
import { MissionDetailsEmptyState } from "../MissionDetailsEmptyState";
import { ReactComponent as CalendarPlus } from "static/icons/calendar-plus-grey.svg";
import { ReactComponent as EditIcon } from "static/icons/edit-grey.svg";
import MissionEditExtensionDrawer from "../MissionEditExtensionDrawer";
import { FEATURE_EXTEND_MISSION } from "config";
import { useSelector } from "react-redux";
import { shouldDisplayFreelancerCost } from "config/helpers";

const MissionExtensionsList = ({
	mission_id,
	isInterrupted,
	setExtensionsCount,
	onOpenExtensionDrawer,
	missionDetails,
	lastExtension,
	isExtensionEnabled,
	vmsSettings,
	lastTimesheetDate,
	canUserEditMission
}) => {
	const features = useSelector(state => state.auth?.user?.features);
	const canUserExtendMission = features.includes(FEATURE_EXTEND_MISSION);

	const { isLoading, data: extensionList } = useGetMissionExtensions(
		mission_id
	);

	const [extensionSelected, setExtensionSelected] = useState(null);
	const [selectedExtension, setSelectedExtension] = useState(null);
	const isDisplayCostEnabled =
		useIsDisplayCostEnabled() && shouldDisplayFreelancerCost(missionDetails);
	const isMissionFinished = missionDetails?.status === "finished";

	let isEmptyState =
		!extensionList?.extensions ||
		(Object.values(extensionList?.extensions)?.length === 0 && !isLoading);

	useEffect(() => {
		setExtensionsCount(Object.values(extensionList?.extensions || {})?.length);
	}, [extensionList]);

	const onRowSelected = item => {
		setExtensionSelected(item);
	};

	const onDrawerClose = () => {
		setExtensionSelected(null);
	};

	const displayEditExtensionIcon =
		!isMissionFinished && !isInterrupted && canUserEditMission;

	return (
		<>
			<table
				className={cx(styles.table, {
					[styles.emptyTable]: isEmptyState
				})}
			>
				<tr className={cx(styles.tr, styles.header)}>
					{MISSION_EXTENSIONS_COLUMNS.map(({ headerName, colId }) => {
						if (
							(colId === "daily_cost" || colId === "hourly_cost") &&
							!isDisplayCostEnabled
						)
							return;
						if (
							(colId === "daily_rate" || colId === "daily_cost") &&
							missionDetails?.reporting_settings?.rate_type === "hourly"
						)
							return;
						if (
							(colId === "hourly_rate" || colId === "hourly_cost") &&
							missionDetails?.reporting_settings?.rate_type === "daily"
						)
							return;
						return (
							<th
								key={headerName}
								className={cx(styles.th, {
									[styles.thCostNotDisplay]: !isDisplayCostEnabled
								})}
							>
								{headerName}
							</th>
						);
					})}
				</tr>
				{isLoading ? (
					Array.from(Array(6)).map((_, index) => (
						<tr key={index} className={cx(styles.tr, styles.tr_loading)}>
							<td className={styles.td}>
								<ExpandIcon />
							</td>
							{Array.from(Array(isDisplayCostEnabled ? 5 : 4)).map(
								(_, index) => (
									<td
										key={index}
										className={cx(styles.td, {
											[styles.tdCostNotDisplay]: !isDisplayCostEnabled
										})}
									>
										<CustomSkeletonCard
											minWidth={200}
											maxWidth={250}
											style={{
												borderRadius: "10px"
											}}
										/>
									</td>
								)
							)}
						</tr>
					))
				) : isEmptyState ? (
					<div className={styles.emptyState}>
						<MissionDetailsEmptyState text="No extension has been made.">
							{canUserExtendMission && (
								<button
									className={cx(styles.extend, {
										[styles.extendDisable]: isInterrupted || !isExtensionEnabled
									})}
									disabled={isInterrupted || !isExtensionEnabled}
									onClick={() => onOpenExtensionDrawer()}
								>
									<CalendarPlus />
									Extend Mission
								</button>
							)}
						</MissionDetailsEmptyState>
					</div>
				) : (
					Object.values(extensionList?.extensions)?.map(item => {
						const islastExtension = item?._id == lastExtension?._id;
						const name =
							item.user.type === SUPER_ADMIN
								? USER_HIREME_NAME
								: item.user.first_name + " " + item.user.last_name;
						return (
							<tr key={item._id} className={cx(styles.tr, styles.row)}>
								<td className={styles.td}>
									<span onClick={() => onRowSelected(item)}>
										<ExpandIcon />
									</span>
									{displayEditExtensionIcon && islastExtension && (
										<span
											onClick={() => {
												setSelectedExtension(item);
											}}
										>
											<EditIcon />
										</span>
									)}
								</td>
								<td
									className={cx(styles.td, {
										[styles.tdCostNotDisplay]: !isDisplayCostEnabled
									})}
								>
									{name}
								</td>
								<td
									className={cx(styles.td, {
										[styles.tdCostNotDisplay]: !isDisplayCostEnabled
									})}
								>
									{formatDate(item.new_mission_start_date)}
								</td>
								<td
									className={cx(styles.td, {
										[styles.tdCostNotDisplay]: !isDisplayCostEnabled
									})}
								>
									{formatDate(item.new_mission_end_date)}
								</td>
								<td
									className={cx(styles.td, {
										[styles.tdCostNotDisplay]: !isDisplayCostEnabled
									})}
								>
									<CurrencyFormatterRender
										className={styles.textNoWrap}
										isCent
										value={item.new_price || 0}
										currency={extensionList?.reporting_settings?.currency?.code}
										suffix={
											CURRENCY_SUFFIX[
												extensionList?.reporting_settings?.rate_type || HOURLY
											]
										}
									/>
								</td>
								{isDisplayCostEnabled && (
									<td className={styles.td}>
										{item?.new_cost !== item.new_price ? (
											<CurrencyFormatterRender
												className={styles.textNoWrap}
												isCent
												value={item.new_cost || 0}
												currency={
													extensionList?.reporting_settings?.currency?.code
												}
												suffix={
													CURRENCY_SUFFIX[
														extensionList?.reporting_settings?.rate_type ||
															HOURLY
													]
												}
											/>
										) : (
											"-"
										)}
									</td>
								)}
							</tr>
						);
					})
				)}
			</table>

			{selectedExtension && (
				<MissionEditExtensionDrawer
					isOpen={selectedExtension}
					closeDrawer={() => setSelectedExtension(null)}
					extensionList={extensionList}
					extensionSelected={selectedExtension}
					vmsSettings={vmsSettings}
					missionId={mission_id}
					lastTimesheetDate={lastTimesheetDate}
					missionDetails={missionDetails}
				/>
			)}

			{extensionSelected && (
				<MissionExtensionDetailsDrawer
					isOpen={!!extensionSelected}
					onClose={onDrawerClose}
					missionExtensionDetails={extensionSelected}
					reporting_settings={extensionList?.reporting_settings}
					mission_id={mission_id}
					drawerTitle="Extension details"
				/>
			)}
		</>
	);
};

export default MissionExtensionsList;
