import { useQuery } from "react-query";
import { GET_LAST_JOB } from "config/api-endpoints";
import { client } from "lib/api-client";

const getVacancy = body => {
	return client(GET_LAST_JOB, {
		body
	});
};

export const GET_VACANCY_QUERY_KEY = "GET_VACANCY_QUERY_KEY";

export const useGetVacancy = (body, options) => {
	const res = useQuery([GET_VACANCY_QUERY_KEY, body], () => getVacancy(body), {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});

	return res;
};
