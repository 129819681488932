import React, { forwardRef } from "react";
import styles from "./messaging-tool-auto-complete-input.module.scss";
import { SearchInput } from "common/SearchInput";

const MessagingToolAutoCompleteInput = (
	{ onChange, onFocus, onKeyDown, inputValue, children },
	ref
) => {
	return (
		<div className={styles.autoComplete} onKeyDown={onKeyDown}>
			{children}
			<SearchInput
				className={styles.inputWrapper}
				inputClassName={styles.inputClassName}
				variant="borderless"
				onChange={onChange}
				onFocus={onFocus}
				value={inputValue}
				icon={<></>}
				placeholder=""
				ref={ref}
			/>
		</div>
	);
};

export default forwardRef(MessagingToolAutoCompleteInput);
