import React from "react";
import styles from "modules/home/components/filters/filters.module.scss";
import cx from "classnames";
const Button = ({ onClick, label, disabled }) => (
	<button
		className={cx(styles["button"], { [styles["disabled"]]: disabled })}
		onClick={onClick}
		disabled={disabled}
	>
		{label}{" "}
	</button>
);

export default Button;
