/* eslint-disable no-unused-vars */
import moment from "moment";
import { MONTHLY } from "./constant";
import isArray from "lodash/isArray";

export const _getSortedExtensionList = (items, value) => {
	if (!items) return null;
	const sortedItems = [...items];
	return sortedItems.sort((a, b) => b[value] - a[value])[0];
};

export const _addOneMonthToDate = date => {
	return moment(date * 1000)
		.add({ months: 1 })
		.startOf("month");
};

export const _addOneWeekToDate = date => {
	return moment(date * 1000).add({ days: 7 });
};

export const _getInterruptedStartDate = (timesheet_frequency, value) => {
	if (timesheet_frequency == MONTHLY) return _addOneMonthToDate(value);
	return _addOneWeekToDate(value);
};

export const getTooltipContent = ({
	isMissionFinished,
	isStartDateInFuture,
	isInterrupted,
	isLastExtensionInFuture,
	isInterruptDisabled
}) => {
	const finishedContent =
		"You cannot extend or interrupt a mission that has already been marked as completed. If you have additional requirements, consider starting a new mission.";
	const futureMissionContent =
		"You cannot extend or interrupt a mission that is scheduled for the future. Please wait until the mission's start date to make any changes.";
	const interruptContent =
		"You cannot interrupt a mission that has already reached the last month and Timesheets have been generated.";
	const lastExtensionContent =
		"No further extensions can be added until the current extension period is reached.";
	if (isStartDateInFuture) {
		return {
			extensionContent: futureMissionContent,
			interruptContent: futureMissionContent
		};
	}
	if (isInterrupted) {
		return {
			extensionContent: interruptContent,
			interruptContent: interruptContent
		};
	}
	if (isLastExtensionInFuture) {
		return {
			interruptContent: null,
			extensionContent: lastExtensionContent
		};
	}
	if (isInterruptDisabled) {
		return {
			extensionContent: null,
			interruptContent: interruptContent
		};
	}

	return {};
};

export const isSupplierMode = invoiceDetails => {
	if (isArray(invoiceDetails) && invoiceDetails?.length === 0) return false;
	if (!invoiceDetails) return false;
	return true;
};

export function compareTimestamps(timestamp1, timestamp2) {
	const date1 = new Date(timestamp1 * 1000);
	const date2 = new Date(timestamp2 * 1000);

	const day1 = date1.getDate();
	const month1 = date1.getMonth();
	const year1 = date1.getFullYear();

	const day2 = date2.getDate();
	const month2 = date2.getMonth();
	const year2 = date2.getFullYear();

	if (
		year1 < year2 ||
		(year1 === year2 && month1 < month2) ||
		(year1 === year2 && month1 === month2 && day1 < day2)
	) {
		return -1;
	} else if (year1 === year2 && month1 === month2 && day1 === day2) {
		return 0;
	} else {
		return 1;
	}
}
