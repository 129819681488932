import React from "react";
import styles from "./propose-interview-to-freelancer-drawer-footer.module.scss";

const ProposeInterviewToFreelancerDrawerFooter = ({ onSubmit, onClose }) => {
	return (
		<div className={styles.footer}>
			<button
				onClick={onSubmit}
				className={`${styles.btn} ${styles.btnDanger}`}
			>
				Propose interview
			</button>
			<button
				type="button"
				onClick={onClose}
				className={`${styles.btn} ${styles.btnLink}`}
			>
				Cancel
			</button>
		</div>
	);
};

export default ProposeInterviewToFreelancerDrawerFooter;
