import React, {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useRef,
	useState
} from "react";
import { useMutation } from "react-query";
import { getOrientation } from "get-orientation/browser";
import _get from "lodash/get";
import { useDropzone } from "react-dropzone";
import Modal from "common/modal";
import Loader from "common/Loader";
import toaster from "common/Toaster";
import { MANAGE_AVATAR_UPLOAD } from "config/api-endpoints";
import { client } from "lib/api-client";
import { ReactComponent as CameraIcon } from "static/icons/camera.svg";
import profileImage from "static/images/Profile-100.jpg";
import CompanyImage from "static/images/Company.jpg";
import Tabs from "./Tabs";
import CropComponent from "./Crop";
import { getRotatedImage } from "./utils";
import { ImageContainer } from "./style";
import { UPDATE, PROFILE, SUPPORTED_SIZE } from "config";
import Portal from "../../../../common/Portal";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { uploadToS3 } from "config/helpers";
import styles from "./avatar.module.scss";

const ORIENTATION_TO_ANGLE = {
	"3": 180,
	"6": 90,
	"8": -90
};

const saveImage = data => {
	client(MANAGE_AVATAR_UPLOAD, {
		body: {
			path: _get(data, "url"),
			type: _get(data, "type"),
			id: _get(data, "profile_id"),
			user_id: _get(data, "user_id"),
			action: UPDATE,
			file_name: _get(data, "file_name"),
			...(_get(data, "uploadFile") && { upload_file: true }),
			...(_get(data, "hasMultiSite") && { site_id: _get(data, "site_id") })
		}
	});
};

export default forwardRef(function ProfileAvatar(
	{ user, type, onSuccess = () => {}, hasMultiSite, site_id },
	ref
) {
	const userOnline = useSelector(onlineUserSelector);
	const [openModal, setOpenModal] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [imageSrc, setImageSrc] = useState(null);
	const [uploadFile, setUploadFile] = useState(false);
	const [fileName, setFileName] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);

	const inputFile = useRef(null);

	useImperativeHandle(ref, () => ({
		open: () => setOpenModal(true)
	}));

	const onFileChange = async files => {
		if (files && files.length > 0) {
			const file = _get(files, "[0]");
			setFileName(_get(file, "name"));
			let imageDataUrl = await readFile(file);
			const orientation = await getOrientation(file);
			const rotation = ORIENTATION_TO_ANGLE[orientation];
			if (rotation) {
				imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
			}

			setImageSrc(imageDataUrl);
			setUploadFile(true);
		}
	};

	function readFile(file) {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	}

	const [uploadImage, { isLoading: isSaving }] = useMutation(saveImage, {
		onSuccess: () => {
			onSuccess();
			setUploadFile(false);
		}
	});
	const [upload, { isLoading: isUploading }] = useMutation(response =>
		uploadToS3({ response, file: croppedImage })
	);

	const onDrop = useCallback(onFileChange, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: "image/*",
		maxSize: SUPPORTED_SIZE,
		onDropRejected: () =>
			toaster.danger(
				`The Maximum size is ${SUPPORTED_SIZE / Math.pow(1024, 2)}MB`
			)
	});
	const onClose = () => {
		setOpenModal(false);
		setImageSrc(null);
		setUploadFile(false);
		setActiveTab(0);
	};
	const renderImageSrc = () => {
		return croppedImage
			? URL.createObjectURL(croppedImage)
			: user.avatar
			? user.avatar
			: type === PROFILE
			? profileImage
			: CompanyImage;
	};

	return (
		<div style={{ width: "100%", height: "100%" }}>
			<ImageContainer onClick={() => setOpenModal(true)}>
				{isUploading || isSaving ? (
					<Loader check={false} />
				) : (
					<>
						<img alt={""} className="image" src={renderImageSrc()} />
						<CameraIcon className="icon-camera" />
						<div className="drop-shadow" />
					</>
				)}
			</ImageContainer>
			{openModal && (
				<Portal>
					<Modal
						fixed={true}
						onClose={onClose}
						modalName={"crop"}
						padding="0"
						size="900px"
						buttonCloseClassName={styles.buttonCloseClassName}
					>
						{imageSrc ? (
							<CropComponent
								imageSrc={imageSrc}
								setImageSrc={setImageSrc}
								fileName={fileName}
								setOpenModal={setOpenModal}
								type={type}
								setCroppedImage={setCroppedImage}
								profile_id={user.id}
								user_id={user._id || _get(userOnline, "id")}
								upload={upload}
								uploadImage={uploadImage}
								inputFile={inputFile}
								onFileChange={onFileChange}
								uploadFile={uploadFile}
								hasMultiSite={hasMultiSite}
								site_id={site_id}
							/>
						) : (
							<Tabs
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								setImageSrc={setImageSrc}
								setFileName={setFileName}
								getRootProps={getRootProps}
								getInputProps={getInputProps}
								imageSrc={imageSrc}
								isDragActive={isDragActive}
							/>
						)}
					</Modal>
				</Portal>
			)}
		</div>
	);
});
