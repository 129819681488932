/* eslint-disable react/display-name */
import React, { Component } from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import { RichUtils } from "draft-js";
import prependHttp from "prepend-http";

import {
	createEntity,
	removeEntity,
	findEntityInSelection,
	getEntities,
	extendSelectionByData
} from "./helpers";
import LinkInput from "./Input";
import { URL_REGEX } from "config/index";
import toaster from "common/Toaster";

class RichEditor extends Component {
	static propTypes = {
		entityType: PropTypes.string,
		editorState: PropTypes.object,
		position: PropTypes.object,
		onChange: PropTypes.func,
		onClose: PropTypes.func
	};
	constructor(props) {
		super();
		const { entityType, editorState } = props;
		this.entity = findEntityInSelection(editorState, entityType);
		this.state = {
			value: this.entity !== null ? this.entity.entity.getData().url : ""
		};
	}

	UNSAFE_componentWillMount() {
		const { entityType, editorState, onChange } = this.props;
		if (this.entity !== null) {
			this.editorStateBackup = extendSelectionByData(
				editorState,
				getEntities(editorState, entityType, this.entity.entityKey)
			);
		} else {
			this.editorStateBackup = editorState;
		}
		onChange(RichUtils.toggleInlineStyle(this.editorStateBackup, "SELECTED"));
	}

	componentWillUnmount() {
		this.props.onChange(this.editorStateBackup);
	}

	handleClickOutside = () => {
		this.props.onClose();
	};

	applyValue = e => {
		if (e.keyCode === 13) {
			e.preventDefault();
			const { value } = this.state;
			if (!URL_REGEX.test(value)) {
				return toaster.danger("Please enter a valid URL.", {
					id: "invalid-url"
				});
			}
			const { entityType, onClose } = this.props;
			this.editorStateBackup = createEntity(
				this.editorStateBackup,
				entityType,
				{ url: prependHttp(value) }
			);
			onClose();
		}
	};

	_onRemoveClick = () => {
		const { entityType, onClose } = this.props;
		this.editorStateBackup = removeEntity(this.editorStateBackup, entityType);
		onClose();
	};

	_onInputChange = e => {
		this.setState({ value: e.target.value });
	};

	render() {
		const { value } = this.state;
		const { editorState } = this.props;
		return (
			<LinkInput
				value={value}
				placeholder="Enter an url and press enter"
				onKeyDown={this.applyValue}
				onChange={this._onInputChange}
				onClickRemove={this._onRemoveClick}
				enableRemove={this.entity !== null}
				editorState={editorState}
			/>
		);
	}
}

export default onClickOutside(RichEditor);
