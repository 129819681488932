/* TODO Move onPress to CustomInput */
/* eslint-disable react/display-name */
import React from "react";
import CreateRequestModal from "../CreateRequestCard/CreateRequestCard";
import { CreateRequestCardContent } from "../CreateRequestCardContent";
import { CreateRequestCardTitle } from "../CreateRequestCardTitle";
import { CreateRequestInfoIcon } from "../CreateRequestInfoIcon";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { CreateRequestCustomInput } from "../CreateRequestCustomInput";
import styles from "./create-request-additional-info-card.module.scss";
import { Controller } from "react-hook-form";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import { CreateRequestAdditionalInfoCardUploadImage } from "../CreateRequestAdditionalInfoCardUploadImage";
import { VACANCY_STEP_CARDS_IDS } from "modules/CreateRequestModule/utils/constants";

const CreateRequestAdditionalInfoCard = ({
	control,
	errors,
	onImageSubmitStart,
	onImageSubmitFinish
}) => {
	return (
		<CreateRequestModal id={VACANCY_STEP_CARDS_IDS.additionalInfo}>
			<CreateRequestCardTitle>Additional info</CreateRequestCardTitle>
			<CreateRequestCardContent className={styles.cardContent}>
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel displayStar={false}>
							Featured image
						</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<Controller
						name="featuredImage"
						control={control}
						render={({ field: { value, onChange } }) => (
							<CreateRequestAdditionalInfoCardUploadImage
								setFeaturedImageUrl={onChange}
								picture={value}
								onImageSubmitStart={onImageSubmitStart}
								onImageSubmitFinish={onImageSubmitFinish}
							/>
						)}
					/>
					<CreateRequestFieldError error={errors.featuredImage} />
				</CreateRequestFieldContainer>
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel displayStar={false}>
							Featured video
						</CreateRequestFieldLabel>
						<CreateRequestInfoIcon>
							The featured video will appear in the job description. It helps
							the candidates to get a close vision of the job.
							<br />
							(ex. : Youtube, Vimeo, ...)
						</CreateRequestInfoIcon>
					</CreateRequestLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="featuredVideo"
							control={control}
							render={({ field }) => (
								<CreateRequestCustomInput placeholder="Video URL" {...field} />
							)}
						/>
					</div>
					<CreateRequestFieldError error={errors.featuredVideo} />
				</CreateRequestFieldContainer>
			</CreateRequestCardContent>
		</CreateRequestModal>
	);
};

export default CreateRequestAdditionalInfoCard;
