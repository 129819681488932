import React from "react";
import { queryCache } from "react-query";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
	CancelButton,
	ActionButton
} from "modules/vacancy/components/vacancyView/CandidateAction/components/styles.js";
import Header from "modules/vacancy/components/vacancyView/CandidateAction/components/DrawerHeader.jsx";
import { ReactComponent as UploadFile } from "static/icons/upload_file_icon_drawer.svg";
import UploadBox from "./UploadBox/UploadBox";
import useUploadCandidateFiles from "../../../api/useUploadCandidateFiles";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import toaster from "common/Toaster";
import { getListCandidateFilesKey } from "modules/candidate/Profile/api/useListCandidateFiles";
import { viewOverviewKey } from "../../../api/useOverview";
import { personalProfileKey } from "modules/candidate/Profile/api/usePersonalProfile";

const UploadFiles = () => {
	const {
		control,
		formState: { errors },
		handleSubmit,
		getValues,
		setValue
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema)
	});

	const { fields, remove } = useFieldArray({
		control,
		name: "items"
	});
	const { setDrawerState, drawerState } = useVacancyStore();

	const {
		candidateId,
		cvDefaultFormat,
		isUploadPrimaryFile,
		isFiletypesInputReadonly
	} = drawerState;

	const onClose = () => setDrawerState({ open: false });

	const [mutate] = useUploadCandidateFiles({
		onSuccess: () => {
			toaster.success("Files has successfully been uploaded");
			queryCache.invalidateQueries(getListCandidateFilesKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(personalProfileKey);
			queryCache.invalidateQueries(viewOverviewKey);
			onClose();
		}
	});

	const onSubmit = values => {
		const files = values.items.map(({ allowed_users, document_type, file }) => {
			const fileObject = {
				file_type: document_type?.value,
				file_name: file.name,
				path: file.path,
				allowed_users: allowed_users?.map(item => item._id) || []
			};

			if (isUploadPrimaryFile) {
				fileObject.is_primary = true;
			}
			return fileObject;
		});
		mutate({ profile_id: candidateId, files });
	};

	const headerTitle = isUploadPrimaryFile ? "Upload file" : "Upload files";

	return (
		<div>
			<Header icon={<UploadFile />} title={headerTitle} onClose={onClose}>
				<CancelButton margin="0 8px 0 0" onClick={onClose}>
					Cancel
				</CancelButton>
				<ActionButton onClick={handleSubmit(onSubmit)}>Done</ActionButton>
			</Header>
			<UploadBox
				control={control}
				fields={fields}
				remove={remove}
				onSubmit={handleSubmit(onSubmit)}
				errors={errors}
				cvDefaultFormat={cvDefaultFormat}
				uploadOneFile={!!isUploadPrimaryFile}
				isFiletypesInputReadonly={isFiletypesInputReadonly}
				setValue={setValue}
				getValues={getValues}
			/>
		</div>
	);
};

export default UploadFiles;

const schema = yup.object().shape({
	items: yup.array().of(
		yup.object().shape({
			document_type: yup.object().required("* Missing document type")
		})
	)
});
