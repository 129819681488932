import React from "react";
import styled from "styled-components";

const IconWrapper = styled.div`
	.st0 {
		fill: #dedede;
	}
	.st1 {
		opacity: 0.13;
		fill: #606060;
		enable-background: new;
	}
	.st2 {
		fill: #606060;
	}
`;

const ProcessingFile = () => (
	<IconWrapper>
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 215.7 154.4"
		>
			<g id="Layer_2"></g>
			<g id="Layer_1">
				<g>
					<polygon className="st0" points="120.3,17.1 120.3,37 140.5,37 		" />
					<ellipse className="st1" cx="108" cy="130.6" rx="105.9" ry="22.5" />
					<g>
						<g>
							<path
								className="st2"
								d="M60.6,17.9v103.6c0,2.1,1.7,3.8,3.8,3.8h74.8c2.1,0,3.8-1.7,3.8-3.8V36.8c0,0,0-0.1,0-0.1
					c0-0.2,0-0.4-0.1-0.5c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.4-0.6l-20.6-20.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.1,0-0.1,0-0.2-0.1
					c-0.2-0.1-0.4-0.1-0.5-0.1c0,0-0.1,0-0.1,0H64.4C62.3,14.1,60.6,15.8,60.6,17.9z M131.8,34.7h-7.7c-1,0-1.7-0.8-1.7-1.7v-7.7
					c0-1.5,1.9-2.3,2.9-1.2l7.7,7.7C134.1,32.8,133.3,34.7,131.8,34.7z M66.5,18.2h50.1c1,0,1.7,0.8,1.7,1.7V35
					c0,2.1,1.7,3.8,3.8,3.8h15.1c1,0,1.7,0.8,1.7,1.7v78.9c0,1-0.8,1.7-1.7,1.7H66.5c-1,0-1.7-0.8-1.7-1.7V20
					C64.7,19,65.5,18.2,66.5,18.2z"
							/>
							<path
								className="st2"
								d="M147.1,110.5v0.7c0,1,0.8,1.7,1.7,1.7h2.7c2.1,0,3.8-1.7,3.8-3.8V5.5c0-2.1-1.7-3.8-3.8-3.8H76.8
					c-2.1,0-3.8,1.7-3.8,3.8v2.7c0,1,0.8,1.7,1.7,1.7h0.7c1,0,1.7-0.8,1.7-1.7V7.6c0-1,0.8-1.7,1.7-1.7h70.7c1,0,1.7,0.8,1.7,1.7
					v99.5c0,1-0.8,1.7-1.7,1.7h-0.7C147.9,108.8,147.1,109.6,147.1,110.5z"
							/>
							<path
								className="st2"
								d="M78.8,42.9h46c1,0,1.7,0.8,1.7,1.7v0.7c0,1-0.8,1.7-1.7,1.7h-46c-1,0-1.7-0.8-1.7-1.7v-0.7
					C77.1,43.7,77.9,42.9,78.8,42.9z"
							/>
							<path
								className="st2"
								d="M78.8,30.6h13c1,0,1.7,0.8,1.7,1.7V33c0,1-0.8,1.7-1.7,1.7h-13c-1,0-1.7-0.8-1.7-1.7v-0.7
					C77.1,31.4,77.9,30.6,78.8,30.6z"
							/>
							<path
								className="st2"
								d="M78.8,55.3h29.5c1,0,1.7,0.8,1.7,1.7v0.7c0,1-0.8,1.7-1.7,1.7H78.8c-1,0-1.7-0.8-1.7-1.7V57
					C77.1,56.1,77.9,55.3,78.8,55.3z"
							/>
							<path
								className="st2"
								d="M115.9,55.3h8.9c1,0,1.7,0.8,1.7,1.7v0.7c0,1-0.8,1.7-1.7,1.7h-8.9c-1,0-1.7-0.8-1.7-1.7V57
					C114.1,56.1,114.9,55.3,115.9,55.3z"
							/>
						</g>
						<g>
							<g>
								<g>
									<path
										className="st2"
										d="M88,83.4c-0.7-0.7-1.8-0.7-2.4,0l-1.9,1.9c3.1-10,13.6-15.6,23.6-12.6c3.3,1,6.3,2.9,8.6,5.4
							c0.6,0.7,1.6,0.8,2.3,0.2l0,0c0.8-0.6,0.9-1.8,0.3-2.5c-7.7-8.6-20.8-10-30.1-2.9c-3.4,2.5-5.9,6-7.4,9.9
							c-0.4,1.2-1.9,1.4-2.8,0.6l0,0c-0.7-0.7-1.8-0.7-2.4,0l0,0c-0.7,0.7-0.7,1.8,0,2.4l5,5c0.7,0.7,1.8,0.7,2.4,0l5-5
							C88.7,85.1,88.7,84.1,88,83.4L88,83.4z"
									/>
									<path
										className="st2"
										d="M120.5,90.8l-5,5c-0.7,0.7-0.7,1.8,0,2.4l0,0c0.7,0.7,1.8,0.7,2.4,0l1.9-1.9c-3,10-13.6,15.7-23.6,12.7
							c-3.5-1.1-6.6-3.1-8.9-5.8c-0.6-0.7-1.6-0.8-2.3-0.3l0,0c-0.8,0.6-1,1.7-0.3,2.5c3.4,4,8.2,6.7,13.4,7.6
							c1.2,0.2,2.4,0.3,3.6,0.3c9.4,0,17.6-5.8,20.9-14.4c0.4-1.2,1.9-1.4,2.8-0.6l0,0c0.7,0.7,1.8,0.7,2.4,0l0,0
							c0.7-0.7,0.7-1.8,0-2.4l-5-5C122.3,90.1,121.2,90.1,120.5,90.8z M121.7,94.5L121.7,94.5l0.2,0L121.7,94.5z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</IconWrapper>
);

export default ProcessingFile;
