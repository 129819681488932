import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import appReducer from "./modules/app/reducer";
import {
	authReducer,
	signupReducer,
	profileReducer,
	filesReducer
} from "./modules/user/reducers";
import menu from "./modules/connectedMenu/reducer";
import features from "./config/features/reducer";
import companyReducer from "./modules/company/reducers/companyReducer";
import usersListReducer from "./modules/company/reducers/usersListReducer";
import checkInvitationReducer from "./modules/company/reducers/checkInvitationReducer";
import searchReducer from "./modules/jobSearch/reducers/searchReducer";
import searchProfileReducer from "./modules/vacancy/reducers/searchProfileReducer";
import viewPokeReducer from "./modules/vacancy/reducers/viewPokeReducer";
import proposalReducer from "./modules/bids/reducers";
import bids from "./modules/bids/reducers/bids";
import bidReducer from "./modules/bids/reducers/bidReducer";
import contractReducer from "./modules/contract/reducers/contractReducer";
import cvReducer from "./modules/cv/reducers";
import timeSheetReducer from "./modules/timesheets/reducer";
import NotificationsReducer from "./modules/notifications/reducer";
import jobReducer from "./modules/job/reducers";
import jobViewReducer from "./modules/TempRequestDetailsModule/reducers/jobViewReducer";
import consultancyReducer from "./modules/consultancy/redux/reducer";
import pokeReducer from "./modules/candidate/redux/pokeReducer";
import candidateReducer from "./modules/candidate/redux/reducer";
import { LOGOUT_SUCCESS } from "./modules/user/actions/actionTypes";
import vacancyFunctionsReducer from "./modules/job/reducers/functionsReducer";
import documentReducer from "./modules/company/components/DocumentManagement/reducers/document.reducer";

const hiremeReducer = combineReducers({
	auth: authReducer,
	signup: signupReducer,
	profile: profileReducer,
	menu,
	features,
	bids,
	bid: bidReducer,
	app: appReducer,
	cv: cvReducer,
	contract: contractReducer,
	company: companyReducer,
	search: searchReducer,
	search_profile: searchProfileReducer,
	proposal: proposalReducer,
	form: formReducer,
	timesheet: timeSheetReducer,
	notifications: NotificationsReducer,
	job: jobReducer,
	usersList: usersListReducer,
	checkInvitation: checkInvitationReducer,
	jobView: jobViewReducer,
	consultancy: consultancyReducer,
	poke: pokeReducer,
	permanent: candidateReducer,
	viewPokeReducer: viewPokeReducer,
	userFiles: filesReducer,
	vacancyFunctions: vacancyFunctionsReducer,
	document: documentReducer
});

const rootReducer = (state, action) => {
	if (action.type === LOGOUT_SUCCESS) {
		state = undefined;
	}
	return hiremeReducer(state, action);
};

export default rootReducer;
