import React from "react";
import { PageCard } from "../PageCard";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { connect } from "react-redux";
import { PageCardLoading } from "../PageCardLoading";

const JobPageCard = ({
	className,
	data,
	user,
	site_id,
	isLoading,
	hasMultiSite
}) => {
	const originalUrl = data.url_job_list_page || "";

	const urlJobPage = `${originalUrl.replace("/jobs", "")}/api/c/${
		user.access_token
	}?page=jobs`;

	return (
		<>
			{isLoading ? (
				<PageCardLoading
					className={className}
					isLoading={isLoading}
					title="Job page"
				/>
			) : (
				<PageCard
					url={urlJobPage}
					originalUrl={originalUrl}
					feature_name="feature_cms_job_page"
					data={data}
					title="Job page"
					className={className}
					site_id={site_id}
					hasMultiSite={hasMultiSite}
				/>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps, null)(JobPageCard);
