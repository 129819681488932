import React from "react";
import Select from "react-select";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { useDebounce } from "react-use";
import s from "./channels-filter-card.module.scss";
import useFiltersStore from "../../store/useFiltersStore";
import useGetTaxonomies from "../../api/useGetTaxonomies";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const LANGUAGES = [
	{ label: "English", value: "en" },
	{ label: "Dutch", value: "nl" },
	{ label: "German", value: "de" }
];

const CURRENCIES = [
	{ label: "Euro", value: "EUR" },
	{ label: "US Dollar", value: "USD" },
	{ label: "British Pound Sterling", value: "GBP" }
];

const ChannelsFilterCard = ({
	isRecommendation,
	setIsRecommendation,
	jobId
}) => {
	const [search, setSearch] = React.useState("");

	const {
		region,
		industry,
		jobTitle,
		jobFunction,
		setvalue,
		cururency,
		regionInput,
		language,
		display,
		sortBy,
		reset
	} = useFiltersStore();

	const { workflowId } = useOnboardingStore();
	const { data, isLoading, isFetching } = useGetTaxonomies(
		{
			location: regionInput,
			title: jobFunction?.name || "",
			id: isRecommendation ? jobId : undefined,
			enabled: !workflowId
		},
		{
			onSuccess: data => {
				setIsRecommendation(false);
				const functions = get(data, "recommendations.functions", {});
				if (
					Boolean(Object.keys(functions).length) ||
					Boolean(get(functions, "length", 0))
				) {
					setvalue("jobFunction", {
						id: Number(functions.vonqId),
						name: functions.description
					});
				}
				const industries = get(data, "recommendations.industries");
				if (get(industries, "length", 0) > 0) {
					setvalue("industry", industries[0]);
				}
				const locations = get(data, "recommendations.locations");
				if (get(locations, "length", 0) > 0) {
					setvalue("region", locations[0]);
				}
			}
		}
	);

	useDebounce(
		() => {
			setvalue("channelName", search);
		},
		650,
		[search]
	);

	const handleInputChange = key => e => setvalue(key, e.target.value);
	const handleSelectChange = key => value => setvalue(key, value);

	return (
		<div className={s.root}>
			<div className={s.header}>
				<label>Filters</label>
				<button
					onClick={() => {
						reset();
						setSearch("");
					}}
				>
					{" "}
					Clear all{" "}
				</button>
			</div>
			<div>
				<label>Channel name</label>
				<input
					type="text"
					onChange={e => setSearch(e.target.value)}
					value={search}
					name="channel-name"
					placeholder="Type..."
					className={s.input}
				/>
			</div>
			<div>
				<label>Region</label>
				<Select
					clearable={true}
					options={get(data, "locations", [])}
					valueKey="id"
					onInputChange={debounce(handleSelectChange("regionInput"), 750)}
					labelKey="fully_qualified_place_name"
					onChange={handleSelectChange("region")}
					value={region}
					placeholder="Start typing to show regions..."
					className={s.select}
					isLoading={isLoading || isFetching}
				/>
			</div>
			<div>
				<label>Industry</label>
				<Select
					clearable={true}
					options={get(data, "industries", [])}
					onChange={handleSelectChange("industry")}
					valueKey="id"
					labelKey="name"
					value={industry}
					placeholder="Select industry"
					className={s.select}
				/>
			</div>
			<div>
				<label>Job function</label>
				<Select
					clearable={true}
					options={get(data, "functions", [])}
					onChange={value => {
						handleSelectChange("jobFunction")(value);
						setvalue("jobTitle", null);
					}}
					valueKey="id"
					labelKey="name"
					value={jobFunction}
					placeholder="Select job function"
					className={s.select}
				/>
			</div>
			<div>
				<label>Job title</label>
				<Select
					clearable={true}
					options={get(data, "job_titles", [])}
					onChange={handleSelectChange("jobTitle")}
					valueKey="id"
					labelKey="name"
					value={jobTitle}
					placeholder="Select job title"
					className={s.select}
					isLoading={isLoading || isFetching}
				/>
			</div>
			<div onChange={handleInputChange("display")}>
				<h5>Display</h5>
				<div className={s.radioContainer}>
					<span className={s.radio_input}>
						<input
							type="radio"
							className="styled"
							name={"display"}
							value="all_channels"
							checked={display === "all_channels"}
						/>
						<span className={s.radio_control}></span>
					</span>
					<h6>All channels</h6>
				</div>
				<div className={s.radioContainer}>
					<span className={s.radio_input}>
						<input
							type="radio"
							className="styled"
							value="recommendations_only"
							name={"display"}
							checked={display === "recommendations_only"}
						/>
						<span className={s.radio_control}></span>
					</span>
					<h6>Recommendations only</h6>
				</div>
				<div className={s.radioContainer}>
					<span className={s.radio_input}>
						<input
							type="radio"
							className="styled"
							name={"display"}
							value="exclude_recommendations"
							checked={display === "exclude_recommendations"}
						/>
						<span className={s.radio_control}></span>
					</span>
					<h6>Exclude recommendations</h6>
				</div>
			</div>
			<div>
				<h5>View channels in</h5>
				<label>language</label>
				<Select
					clearable={true}
					options={LANGUAGES}
					onChange={handleSelectChange("language")}
					value={language}
					placeholder="Select language"
					className={s.select}
				/>
			</div>
			<div>
				<label>Price currency</label>
				<Select
					clearable={true}
					options={CURRENCIES}
					onChange={handleSelectChange("cururency")}
					value={cururency}
					placeholder="Select currency"
					className={s.select}
				/>
			</div>
			<div onChange={handleInputChange("sortBy")}>
				<h5>Sort by</h5>
				<div className={s.radioContainer}>
					<span className={s.radio_input}>
						<input
							type="radio"
							className="styled"
							name="sort_by"
							value="relevant"
							checked={sortBy === "relevant"}
						/>
						<span className={s.radio_control}></span>
					</span>
					<h6>Relevent first</h6>
				</div>
				<div className={s.radioContainer}>
					<span className={s.radio_input}>
						<input
							type="radio"
							className="styled"
							name="sort_by"
							value="recent"
							checked={sortBy === "recent"}
						/>
						<span className={s.radio_control}></span>
					</span>
					<h6>Recent first</h6>
				</div>
			</div>
		</div>
	);
};

export default ChannelsFilterCard;
