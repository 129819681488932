import React from "react";
import { DismissButton, Overlay, usePopover } from "react-aria";
import styles from "./category-select-popover.module.scss";

const CategorySelectPopover = ({
	children,
	state,
	offset = 8,
	onClose,
	...props
}) => {
	let popoverRef = React.useRef();
	let { popoverProps, underlayProps } = usePopover(
		{
			...props,
			offset,
			popoverRef
		},
		{ ...state, close: onClose }
	);

	return (
		<Overlay>
			<div {...underlayProps} className={styles.underlay} />
			<div {...popoverProps} ref={popoverRef} className={styles.popover}>
				<DismissButton onDismiss={state.close} />
				{children}
				<DismissButton onDismiss={state.close} />
			</div>
		</Overlay>
	);
};

export default CategorySelectPopover;
