import React, { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import CopyToClipboard from "../../utils/copyValue";
import styles from "./contact-quick-view.module.scss";

const ContactInfoModal = ({ label, data = [], onClose }) => {
	const modalRef = useRef(null);
	useOnClickOutside(modalRef, () => {
		onClose();
	});
	return (
		<div ref={modalRef} className={styles.rootQuick}>
			<h3>{label}</h3>
			<ul>
				{data.map((item, index) => {
					return (
						<li key={index}>
							<CopyToClipboard text={item}>{item}</CopyToClipboard>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
export default ContactInfoModal;
