import React from "react";
import styles from "./styles.module.scss";
import Modal from "common/modal";
import useDeleteAccountStore from "./store";
import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import FeedBackStep from "./Steps/FeedBackStep";
import DeleteAccountStep from "./Steps/DeleteAccountStep";
import { ReactComponent as IconClose } from "static/icons/IconClose.svg";

let DeleteAccount = ({ onClose }) => {
	const { currentStep, modalLoading } = useDeleteAccountStore();

	return (
		<Modal
			size={"500px"}
			padding={"24px"}
			onClose={onClose}
			borderRadius={"12px"}
			loading={modalLoading}
			border={"1px solid #CBD5E1"}
			backgroundColor={"rgba(0,0,0,0.25)"}
			loaderStyle={{ borderTopColor: "#00857a" }}
			boxShadow={
				"0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
			}
			customCloseBtnRender={onClose => (
				<div className={styles.modal__close} onClick={onClose}>
					<IconClose />
				</div>
			)}
		>
			<div className={styles.title}>Delete account</div>
			<>
				{currentStep === 1 && <FirstStep onClose={onClose} />}
				{currentStep === 2 && <SecondStep />}
				{currentStep === 3 && <FeedBackStep />}
				{currentStep === 4 && <DeleteAccountStep onClose={onClose} />}
			</>
		</Modal>
	);
};

export default DeleteAccount;
