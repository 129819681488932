import React, { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import style from "./vonq-contract.module.scss";
import BodyClassName from "react-body-classname";
import AddContract from "./pages/AddContract";
import MyContract from "./pages/MyContract";
import { ContractsGroupsTab } from "./pages/ContractsGroupsTab";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import styles from "./vonq-contract.module.scss";
import { SaveContractsGroupsDrawer } from "./pages/ContractsGroupsTab/components/SaveContractsGroupsDrawer";
import useAddContractsGroup from "modules/vonqContract/api/useAddNewContractsGroup";

const TABS = ["add_contract", "my_contracts", "groups"];

const VonqContract = () => {
	const [groupToAdd, setGroupToAdd] = useState();
	const [tab, setTab] = useState(0);
	const [addContractGroupsError, setAddContractsGroupError] = useState(false);
	const setItems = useBreadCrumbs(state => state.setItems);

	const isAddContractTabActif = tab === 0;
	const isMyContractsTabActif = tab === 1;
	const isGroupsTabActif = tab === 2;

	const options = {
		onSuccess: () => setGroupToAdd(null),
		onError: () => setAddContractsGroupError("Group name exist")
	};

	const [
		addContractsGroup,
		{ isLoading: isAddContractsGroupLoading }
	] = useAddContractsGroup(options);

	useEffect(() => {
		const hash = window.location.hash.replace("#", "");
		const tab = TABS.indexOf(hash) !== -1 ? TABS.indexOf(hash) : 0;
		setTab(tab);
		setItems([
			{ name: "Settings" },
			{ name: "My contracts ", path: "/settings/job-board-contracts" }
		]);
	}, []);

	const handleChange = index => {
		setTab(index);
		window.location.hash = TABS[index];
	};

	const handleAddGroupClick = () => setGroupToAdd({});

	const handleContractsDrawerClose = () => {
		setGroupToAdd(null);
		setAddContractsGroupError("");
	};

	const handleContractsGroupSaveClick = groupName =>
		addContractsGroup({
			name: groupName
		});

	const handleGroupNameInputChange = () => {
		setAddContractsGroupError("");
	};

	return (
		<BodyClassName className="vonq-contract">
			<div className={styles.container}>
				<Tabs
					index={tab}
					className={style.tabsContainer}
					onChange={handleChange}
				>
					<TabList>
						<Tab> Add contract </Tab>
						<Tab> My contracts </Tab>
						<Tab> Groups </Tab>
					</TabList>
					<TabPanels>
						<TabPanel>{isAddContractTabActif && <AddContract />}</TabPanel>
						<TabPanel>{isMyContractsTabActif && <MyContract />}</TabPanel>
						<TabPanel>{isGroupsTabActif && <ContractsGroupsTab />}</TabPanel>
					</TabPanels>
				</Tabs>
				{isGroupsTabActif && (
					<button
						onClick={handleAddGroupClick}
						className={styles.addGroupButton}
					>
						Add group
					</button>
				)}
				{groupToAdd && (
					<SaveContractsGroupsDrawer
						contractGroup={groupToAdd}
						onClose={handleContractsDrawerClose}
						header="Add a new group"
						subHeader="Add a new group"
						displaySavingLoading={isAddContractsGroupLoading}
						onSave={handleContractsGroupSaveClick}
						errorMessage={addContractGroupsError}
						onInputChange={handleGroupNameInputChange}
					/>
				)}
			</div>
		</BodyClassName>
	);
};

export default VonqContract;

export const getVonqContractRoute = () => `settings/job-board-contracts`;

export const getVonqContractPermission = () => ({
	type: [
		{
			name: "client",
			roles: ["admin", "project_manager"]
		}
	]
});
