import cx from "classnames";
import React, { forwardRef } from "react";
import {
	DismissButton,
	FocusScope,
	mergeProps,
	useDialog,
	useModal,
	useOverlay
} from "react-aria";
import styles from "./popover.module.scss";

const Popover = (
	{ children, onClose, isOpen, style, paperStyle, ...otherProps },
	popoverRef
) => {
	// Handle interacting outside the dialog and pressing
	// the Escape key to close the modal.
	let { overlayProps } = useOverlay(
		{
			onClose,
			isOpen,
			isDismissable: true
		},
		popoverRef
	);

	// Hide content outside the modal from screen readers.
	let { modalProps } = useModal();

	// Get props for the dialog and its title
	let { dialogProps } = useDialog({}, popoverRef);

	// Add a hidden <DismissButton> component at the end of the popover
	// to allow screen reader users to dismiss the popup easily.
	return (
		<FocusScope contain restoreFocus>
			<div
				className={cx(styles.popover, {})}
				{...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
				ref={popoverRef}
				style={{ ...style, ...paperStyle }}
			>
				{children}
				<DismissButton onDismiss={onClose} />
			</div>
		</FocusScope>
	);
};

export default forwardRef(Popover);
