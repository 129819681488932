import React from "react";
import style from "./styles.module.scss";
import TasksItems from "./tasksItems/TasksItems";
import Box from "../Box";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import useTasksStore from "modules/home/zustand/TasksStore";
import {
	HeaderEnd,
	HeaderStart
} from "modules/home/containers/interview/header.jsx";
import { historyPush } from "config/helpers";

const Tasks = () => {
	const {
		tasksType,
		setItemTasks,
		tasks,
		tasksStartDate,
		tasksEndDate,
		tasksRecepientType,
		totalTasks
	} = useTasksStore();
	const tasksTypeKey = "tasksType";
	const myTasks = "myTasks";
	const assignedToMe = "assignedToMe";
	const tagMyTasks = "by_me";
	const tagAssignedToMe = "assigned_to_me";

	const handleViewAll = () => {
		return historyPush("/tasks");
	};
	const handleTabsChange = index => {
		setItemTasks(tasksTypeKey, index === 0 ? myTasks : assignedToMe);
	};
	return (
		<Box
			headerEnd={
				<HeaderEnd
					start_date={tasksStartDate}
					end_date={tasksEndDate}
					setItem={setItemTasks}
					start_date_Key={"tasksStartDate"}
					end_date_key={"tasksEndDate"}
					recipient_type={tasksRecepientType}
					handleViewAll={handleViewAll}
					isSettedDate={true}
				/>
			}
			headerStart={
				<HeaderStart title={"Tasks"} data={tasks} count={totalTasks} />
			}
		>
			<div className={style.tasksContainer}>
				<Tabs
					defaultIndex={tasksType === myTasks ? 0 : 1}
					onChange={handleTabsChange}
				>
					<TabList>
						<Tab>My tasks</Tab>
						<Tab>Assigned to me</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<TasksItems type={myTasks} tagType={tagMyTasks} />
						</TabPanel>
						<TabPanel>
							<TasksItems type={assignedToMe} tagType={tagAssignedToMe} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</div>
		</Box>
	);
};

export default Tasks;
