import React from "react";
import styles from "./permanent-evaluations-list-loading.module.scss";
import { PermanentEvaluationsCustomSkeleton } from "../PermanentEvaluationsCustomSkeleton";

const PermanentEvaluationsListLoading = () => {
	return (
		<div className={styles.list}>
			{[1, 2, 3].map((_, index) => (
				<div key={index} className={styles.card}>
					<PermanentEvaluationsCustomSkeleton width={42} height={42} />
					<div className={styles.nameContainer}>
						<PermanentEvaluationsCustomSkeleton width={291} height={15} />
						<PermanentEvaluationsCustomSkeleton width={189} height={10} />
					</div>
					{[1, 2, 3].map(value => (
						<PermanentEvaluationsCustomSkeleton
							key={value}
							width={36}
							height={36}
						/>
					))}
					<PermanentEvaluationsCustomSkeleton
						className={styles.date}
						width={189}
						height={15}
					/>
					<div className={styles.body}>
						<PermanentEvaluationsCustomSkeleton width={714} height={27} />
					</div>
				</div>
			))}
		</div>
	);
};

export default PermanentEvaluationsListLoading;
