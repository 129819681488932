import React from "react";
import styles from "./evaluation-card-edit-button.module.scss";
import { ReactComponent as EditIcon } from "static/icons/edit-03.svg";

const EvaluationCardEditButton = ({ onClick }) => {
	return (
		<button className={styles.button} onClick={onClick}>
			<div className={styles.icon}>
				<EditIcon />
			</div>
		</button>
	);
};
export default EvaluationCardEditButton;
