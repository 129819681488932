import * as types from "../actions/types";
import Immutable from "seamless-immutable";

const initialState = {
	loading: false,
	notes_modal: {
		show: false,
		data: {}
	},
	proposeInterview_modal: {
		show: false,
		data: {},
		type: null
	},
	cancelInterview_modal: {
		show: false,
		data: {},
		type: null
	},
	loaded: false,
	data: null,
	error: null,
	rejectModal: false,
	contract_modal: {
		show: false,
		type: null
	},
	showVideoConf: false,
	pokes_list: {}
};

export default function(state = { ...initialState }, action) {
	switch (action.type) {
		case types.TOGGLE_NOTES_MODAL:
			return Immutable.merge(state, {
				notes_modal: {
					show: !state.notes_modal.show,
					data: action.modal
				}
			});
		case types.TOGGLE_PROPOSE_INTERVIEW_MODAL:
			return Immutable.merge(state, {
				proposeInterview_modal: {
					show: !state.proposeInterview_modal.show,
					data: action.modal
				}
			});
		case types.SHOW_VIDEO_CONF:
			return Immutable.merge(state, { showVideoConf: true });
		case types.HIDE_VIDEO_CONF:
			return Immutable.merge(state, { showVideoConf: false });
		case types.TOGGLE_CANCEL_INTERVIEW_MODAL:
			return Immutable.merge(state, {
				cancelInterview_modal: {
					show: !state.cancelInterview_modal.show,
					data: action.modal
				}
			});
		case types.SET_POKES_LIST:
			return Immutable.merge(state, {
				pokes_list: action.data
			});
		default:
			return state;
	}
}
