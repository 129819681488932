import styled from "styled-components";

export const StyledTd = styled.td`
	.select-tooltip {
		.Select-control {
			table-layout: fixed;
		}

		.Select-value {
			overflow: hidden;
			max-width: calc(100% - 20px) !important;
		}
	}
`;
