import React, { createRef, useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { Element } from "react-scroll";
import _get from "lodash/get";
import GlobalTooltip from "./GlobalTooltip";
import { isEmptyAddress } from "config/helpers";

const inputRef = createRef();

const composeAddress = addressObj => {
	if (isEmptyAddress(addressObj)) return "";
	return `${_get(addressObj, "street", "")} ${_get(addressObj, "number", "")}${
		addressObj.number && addressObj.box ? " / " : " "
	}${_get(addressObj, "box", "")} ${_get(addressObj, "zip", "")} ${
		_get(addressObj, "city") ? `${_get(addressObj, "city")},` : ""
	} ${_get(addressObj, "country", "")}`
		.replace(/\s\s+/g, " ")
		.replace(/\s,+/g, ", ");
};

const GoogleAutocomplete = ({
	meta: { error, touched },
	input,
	placeholder,
	types,
	label,
	overlay,
	name,
	address,
	showMapMarker = true,
	save,
	isMain = false,
	disabled = false,
	index,
	onChange,
	classNameLabel
}) => {
	const [addressText, setAddressText] = useState(composeAddress(address));
	useEffect(() => {
		if (!inputRef.current) {
			return;
		}
		window.google.maps.event.addDomListener(
			inputRef.current.refs.input,
			"keydown",
			event => {
				if (event.keyCode === 13) {
					event.preventDefault();
				}
			}
		);
	}, []);

	useEffect(() => {
		setAddressText(address.formatted_address || composeAddress(address));
	}, [address]);

	const handleAddressChange = place => {
		const address = {};
		if (place.geometry && input && input.onChange) {
			place.address_components.map(elem => {
				if (elem.types[0] === "country") {
					address[elem.types[0]] = elem.long_name;
					address.iso_country = elem.short_name;
					return;
				}
				return (address[elem.types[0]] = elem.long_name);
			});
			address.latitude = place.geometry.location.lat();
			address.longitude = place.geometry.location.lng();
			address.street =
				address.route ||
				address.neighborhood ||
				address.premise ||
				address.sublocality_level_1 ||
				address.sublocality_level_2 ||
				address.sublocality_level_3 ||
				address.sublocality_level_4 ||
				address.sublocality_level_5 ||
				address.subpremise ||
				address.sublocality ||
				address.jpns ||
				"";
			address.country = address.country || "";
			address.is_main = isMain;
			address.zip = address.postal_code || "";
			address.latitude = address.latitude || "";
			address.longitude = address.longitude || "";
			address.city =
				address.locality ||
				address.administrative_area_level_1 ||
				address.administrative_area_level_2 ||
				address.administrative_area_level_3 ||
				address.administrative_area_level_4 ||
				address.administrative_area_level_5 ||
				"";
			address.number = address.street_number || "";
			address.box = address.box || "";
			address.formatted_address = place.formatted_address || "";
			address.iso_country = address.iso_country || "";
			address.geometry = place.geometry;
		}
		save && save(address, index);
		setAddressText(address.formatted_address);

		return input.onChange(address);
	};

	const hasError = error && touched;

	const has_error = hasError ? "has-error has-feedback" : "";

	return (
		<Element
			className={`form-group inner-icon right-icon ${has_error}`}
			name={name}
			scrollto="scroll-to-error"
		>
			{label && (
				<label htmlFor="full_adresse" className={classNameLabel}>
					{label}
					{overlay && <GlobalTooltip maxWidth="400px" overlay={overlay} />}
				</label>
			)}
			<div className="input-box adressBoxNext">
				{showMapMarker && <i className="fa fa-map-marker" />}
				<Autocomplete
					className="form-control address-control"
					onPlaceSelected={handleAddressChange}
					placeholder={placeholder}
					types={types}
					ref={inputRef}
					value={addressText}
					onChange={e => {
						setAddressText(e.target.value);
						onChange && onChange(e.target.value);
					}}
					disabled={disabled}
				/>
			</div>
			{hasError && (
				<div className="help-block inline-error">{hasError && error}</div>
			)}
		</Element>
	);
};

GoogleAutocomplete.defaultProps = {
	meta: {},
	types: ["geocode"],
	placeholder: "Start typing an address"
};

export default GoogleAutocomplete;
