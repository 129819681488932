import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import { renderError } from "config/helpers";
import { client } from "lib/api-client";
import fakeData from "modules/user-onboarding/mockData/default-email.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const { useQuery } = require("react-query");

function getDefaultEmail() {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body: {
			filter: "default_email"
		}
	});
}

export const MESSAGING_TOOL_DEFAULT_EMAIL = "MESSAGING_TOOL_DEFAULT_EMAIL";

function useGetDefaultEmail() {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery(MESSAGING_TOOL_DEFAULT_EMAIL, () => {
			return Promise.resolve(fakeData);
		});
	}

	return useQuery(MESSAGING_TOOL_DEFAULT_EMAIL, getDefaultEmail, {
		onError: renderError,
		refetchOnWindowFocus: false,
		retry: 0
	});
}

export default useGetDefaultEmail;
