import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
	APPROVED,
	ASK_QUESTION,
	PENDING_REVIEW,
	REFUSED,
	IDENTITY_COMPLETED
} from "config";
import { toggleHelpModal } from "../modules/app/actions/ListsActions";
import { makeProposal } from "../modules/bids/actions/proposalActions";
import { getFreelancerBidViewRoute } from "modules/bids/components/bidview/freelancer";
import GlobalTooltip from "./GlobalTooltip";
import { Button, LinkButton } from "common/styled/buttons";
import {
	identityStatusSelector,
	companyInformationStatusSelector,
	identityStepSelector
} from "modules/TempRequestDetailsModule/selectors/jobSelectors";
import { toggleIdentityModal } from "modules/TempRequestDetailsModule/actions/JobViewActions";
import styles from "./bid-maker.module.scss";
import cx from "classnames";

const BidMaker = ({
	id,
	companyInformationStatus,
	identityStatus,
	dispatch,
	buttonText,
	identityStep,
	canMakeBid,
	maxWidth,
	placement,
	align,
	bid_id,
	overlayClassName,
	eventTrigger,
	hasRequiredMustHave,
	isConsultancy,
	userAccountStatus,
	className
}) => {
	const consultancyOverlay = () => {
		if (identityStatus === REFUSED) {
			return (
				<span>
					Your provided ID document has been refused. Please{" "}
					<span
						className="link"
						onClick={() => dispatch(toggleHelpModal(ASK_QUESTION))}
					>
						contact us
					</span>{" "}
					for more information.
				</span>
			);
		}
		if (
			identityStatus === PENDING_REVIEW ||
			companyInformationStatus === PENDING_REVIEW
		) {
			return (
				<span>
					Our support team is currently in the process of reviewing your ID
					document. You’ll be able to bid on missions as soon as your ID
					document has been validated.
				</span>
			);
		}
	};

	const overlay = () => {
		return (
			<span>
				Our support team is currently in the process of reviewing your account.
				You’ll be able to bid on missions as soon as your profile has been
				activated.
			</span>
		);
	};

	const makeBid = () => {
		if (isConsultancy) {
			return identityStep === IDENTITY_COMPLETED
				? dispatch(makeProposal(id))
				: dispatch(toggleIdentityModal());
		}
		return dispatch(makeProposal(id));
	};

	if (
		identityStep === IDENTITY_COMPLETED &&
		(identityStatus !== APPROVED || companyInformationStatus !== APPROVED)
	) {
		return (
			<GlobalTooltip
				placement={placement}
				noClassName={true}
				align={align}
				trigger={eventTrigger}
				overlayClassName={overlayClassName}
				maxWidth={maxWidth}
				overlay={consultancyOverlay()}
				active={true}
			>
				<Button
					className={cx(styles.button, styles.disable, className)}
					small
					block
				>
					{buttonText}
				</Button>
			</GlobalTooltip>
		);
	}
	if (!isConsultancy && userAccountStatus === PENDING_REVIEW) {
		return (
			<GlobalTooltip
				placement={placement}
				noClassName={true}
				align={align}
				trigger={eventTrigger}
				overlayClassName={overlayClassName}
				maxWidth={maxWidth}
				overlay={overlay()}
				active={true}
			>
				<Button
					className={cx(styles.button, styles.disable, className)}
					small
					block
				>
					{buttonText}
				</Button>
			</GlobalTooltip>
		);
	}

	if (!hasRequiredMustHave) {
		return (
			<GlobalTooltip
				placement={placement}
				noClassName={true}
				align={align}
				trigger={eventTrigger}
				overlayClassName={overlayClassName}
				maxWidth={maxWidth}
				active={true}
				overlay={
					<>
						{!isConsultancy ? (
							<>
								You can’t bid on this request as your profile is lacking one or
								more skill(s) which was/were marked as a{" "}
								<strong>MUST HAVE</strong> by the client.
								<div>
									Check the SKILLS section of this request and don’t hesitate to
									adapt your profile if applicable.
								</div>
							</>
						) : (
							<>
								You can’t bid on this request as your consultant(s) profile(s)
								is/are lacking one or more skill(s) which was/were marked as a{" "}
								<strong>MUST HAVE</strong> by the client.{" "}
								<div>
									Check the SKILLS section of this request and don’t hesitate to
									adapt your profile(s) if applicable.
								</div>{" "}
							</>
						)}
					</>
				}
			>
				<Button
					className={cx(styles.button, styles.disable, className)}
					small
					block
				>
					{buttonText}
				</Button>
			</GlobalTooltip>
		);
	}

	if (!canMakeBid && bid_id) {
		return (
			<LinkButton
				className={cx(styles.button, className)}
				to={getFreelancerBidViewRoute(bid_id)}
				block
				small
			>
				View Your Application
			</LinkButton>
		);
	}

	return (
		<Button
			className={cx(styles.button, className)}
			onClick={makeBid}
			small
			block
		>
			{buttonText}
		</Button>
	);
};

BidMaker.propTypes = {
	buttonText: PropTypes.string,
	id: PropTypes.string.isRequired,
	bid_id: PropTypes.string
};

BidMaker.defaultProps = {
	buttonText: "Make a bid",
	placement: "top",
	overlayClassName: "g-tool-white top center-text",
	maxWidth: "300px",
	canMakeBid: true,
	className: ""
};

const mapStateToProps = state => {
	return {
		identityStatus: identityStatusSelector(state),
		companyInformationStatus: companyInformationStatusSelector(state),
		identityStep: identityStepSelector(state)
	};
};

export default connect(mapStateToProps)(BidMaker);

//TODO Create a reusable permissions checker
