import React from "react";
import { PlannedInterviewsContainer, Title } from "../styles";
import UpcomingInterviews from "./UpcomingInterviews";
import InterviewsHistory from "./InterviewsHistory";
import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { LIST_LATEST_INTERVIEWS } from "config/api-endpoints";
import get from "lodash/get";

const getListLatestInterviews = (_, vacancyId, profileId) =>
	client(LIST_LATEST_INTERVIEWS, {
		body: {
			profile_id: profileId,
			vacancy_id: vacancyId
		}
	});

const PlannedInterviews = ({ vacancyId, profileId, candidateName }) => {
	const { data, isLoading } = useQuery(
		["list-latest-interviews", vacancyId, profileId],
		getListLatestInterviews
	);
	return (
		<PlannedInterviewsContainer>
			<Title margin="0px 0px 16px 0px">Planned interviews</Title>
			<UpcomingInterviews
				isLoading={isLoading}
				data={get(data, "upcoming", [])}
			/>
			<InterviewsHistory
				isLoading={isLoading}
				data={get(data, "history", [])}
				candidateName={candidateName}
			/>
		</PlannedInterviewsContainer>
	);
};
export default PlannedInterviews;
