import { SearchInput } from "common/SearchInput";
import React, { useRef, useState, useEffect } from "react";
import styles from "./request-view-kanban-search.module.scss";
import { ReactComponent as IconSearch } from "static/icons/IconSearch.svg";
import { ReactComponent as IconSearchClose } from "static/icons/IconSearchClose.svg";
import useRequestViewKanbanStore from "../../kanban/store/useRequestViewKanbanStore";
import useDebouncedValue from "hooks/useDebouncedValue";

export default function RequestViewKanbanSearch() {
	const searchRef = useRef(null);
	const [searchValue, setSearchValue] = useState("");
	const { searchQuery, setSearchQuery } = useRequestViewKanbanStore();

	const debouncedSearchValue = useDebouncedValue(searchValue);
	useEffect(() => setSearchQuery(debouncedSearchValue), [debouncedSearchValue]);

	const onSearchChange = e => {
		const keywords = e.target.value.trimStart();
		setSearchValue(keywords);
	};

	const onSearchClear = () => {
		setSearchValue("");
		setSearchQuery("");
	};

	const searchIcon = (
		<div
			className={`${styles.search__input_icon} ${
				searchQuery ? styles.icon_close : ""
			}`}
			{...(searchQuery ? { onClick: onSearchClear } : {})}
		>
			{searchQuery ? <IconSearchClose /> : <IconSearch />}
		</div>
	);

	return (
		<SearchInput
			ref={searchRef}
			icon={searchIcon}
			value={searchValue}
			onChange={onSearchChange}
			placeholder="Quick search..."
			className={styles.search__input}
		/>
	);
}
