import React, { useMemo, useState } from "react";
import { ReactComponent as IconInfo } from "static/icons/IconInfo.svg";
import useUpdateUserSettings from "../ModalVideo/api/useUpdateUserSetting";
import {
	ONBOARDING_STEPS_QUERY,
	useOnboardingSteps
} from "modules/setupGuide/api/useOnboardingSteps";
import styles from "./setup-guide-card.module.scss";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import {
	TOUR_EMPLOYER_BRANDING,
	TOUR_INTERFACE_NAVIGATION,
	TOUR_SETUP_COMPANY,
	TOUR_USER_AND_DEPARTEMENT
} from "config/onboarding";
import SetupGuideCardStepRow from "../SetupGuideCardStepRow";
import { SkeletonLoader } from "../SkeletonCardStep";
import { browserHistory } from "react-router";
import { queryCache } from "react-query";
import { useTour } from "@reactour/tour";
import { STEPS_TOUR_USER_AND_DEPARTEMENT } from "modules/user-onboarding/workflows/create-users-departments";

const SETUP_GUIDE_STEPS_KEYS = {
	watch_intro_video_step: "onboarding_watch_intro_video_step",
	employer_branding_step: "onboarding_employer_branding_step",
	create_users_and_departments: "onboarding_user_and_department_step",
	interface_navigation_tour_step: "onboarding_interface_navigation_tour_step",
	profile_and_company_setup_step: "onboarding_profile_and_company_setup_step"
};

export default function SetupGuideCard() {
	const { setSteps, setCurrentStep, setIsOpen } = useTour();
	const { isLoading, data, isError } = useOnboardingSteps({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { metaData, setMetaData, setWorkflowId } = useOnboardingStore();

	// ** video intro api mutation
	const [mutate] = useUpdateUserSettings();
	const onCloseModalVideo = key => {
		setIsModalOpen(false);
		const payload = {
			type: "onboarding_options",
			onboarding_options: {
				onboarding_type: "onboarding_steps_state",
				value: key
			}
		};

		mutate(payload, {
			onSuccess: () => {
				const showStartRecrutmentModal = lastSetupGuideStepKey === key;
				queryCache.invalidateQueries(ONBOARDING_STEPS_QUERY);
				// show start recrutment modal
				if (showStartRecrutmentModal)
					setMetaData({ ...metaData, showModal: true });
			}
		});
	};

	// ** setup guide progress
	const progress = useMemo(
		() =>
			!isLoading && data
				? Math.round(
						(data.filter(item => item?.is_done).length / data.length) * 100
				  )
				: 0,
		[data, isLoading]
	);
	// ** last onboarding step key
	const lastSetupGuideStepKey = useMemo(() => {
		if (!isLoading && data) {
			const uncheckedStepsKeys = data
				.filter(item => !item?.is_done)
				.map(item => item.key);
			return uncheckedStepsKeys.length === 1 ? uncheckedStepsKeys[0] : false;
		}
		return false;
	}, [data, isLoading]);

	// ** events
	const onStepClick = ({ key, type, isDone }) => {
		if (type === "video") {
			setIsModalOpen(true);
			return;
		}

		const showStartRecrutmentModal = lastSetupGuideStepKey === key;
		switch (key) {
			case SETUP_GUIDE_STEPS_KEYS.profile_and_company_setup_step:
				browserHistory.push("/settings/myprofile");
				setWorkflowId(TOUR_SETUP_COMPANY); // set workflow id
				setMetaData({
					stepKey: key,
					stepIsDone: isDone,
					showStartRecrutmentModal
				});
				break;

			case SETUP_GUIDE_STEPS_KEYS.interface_navigation_tour_step:
				browserHistory.push("/home");
				setWorkflowId(TOUR_INTERFACE_NAVIGATION); // set workflow id
				setMetaData({
					stepKey: key,
					stepIsDone: isDone,
					showStartRecrutmentModal
				});
				break;

			case SETUP_GUIDE_STEPS_KEYS.create_users_and_departments:
				browserHistory.push("/settings/users/user");
				setWorkflowId(TOUR_USER_AND_DEPARTEMENT); // set workflow id
				setMetaData({
					stepKey: key,
					stepIsDone: isDone,
					showStartRecrutmentModal
				});
				setSteps(STEPS_TOUR_USER_AND_DEPARTEMENT); // init steps
				setCurrentStep(0);
				setIsOpen(true);
				break;

			case SETUP_GUIDE_STEPS_KEYS.employer_branding_step:
				browserHistory.push("/settings/pages-cms");
				setWorkflowId(TOUR_EMPLOYER_BRANDING); // set workflow id
				setMetaData({
					stepKey: key,
					stepIsDone: isDone,
					showStartRecrutmentModal
				});
				break;

			default:
				null;
		}
	};
	const onSkipOnboarding = () => browserHistory.push("/home");

	const progressClassName =
		progress === 100
			? `${styles.badge} ${styles.badgeSuccess}`
			: `${styles.badge}`;

	const urlParams = new URLSearchParams(window.location.search);
	const showSkipButton =
		urlParams.has("type") && urlParams.get("type") === "first_login";

	if (isError) {
		browserHistory.push("/404");
		return <></>;
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h3>Set-up guide</h3>
				{!!progress && (
					<div className={progressClassName}>{progress}% complete</div>
				)}
			</div>
			<div className={styles.body}>
				<div className={styles.banner}>
					<IconInfo />
					Complete these simple steps to get you account up and running
				</div>
				<div className={styles.stepsContainer}>
					{isLoading ? (
						<SkeletonLoader />
					) : (
						data.map((record, index) => (
							<SetupGuideCardStepRow
								key={index}
								counter={index + 1}
								record={{ ...record }}
								isModalOpen={isModalOpen}
								onCloseModalVideo={onCloseModalVideo}
								onStepClick={() =>
									onStepClick({
										key: record.key,
										type: record.type,
										isDone: !!record?.is_done
									})
								}
							/>
						))
					)}
				</div>
			</div>
			{!isLoading && showSkipButton && (
				<button
					onClick={onSkipOnboarding}
					className={`${styles.btn} ${styles.btnLink}`}
				>
					Skip
				</button>
			)}
		</div>
	);
}
