import React, { useState } from "react";
import style from "modules/MyCompany/components/DeleteModal/delete-modal.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/CloseX.svg";
import FirstStepCommponent from "./FirstStepComponent";
import get from "lodash/get";
import SecondStepComponent from "./SecondStepComponent";
import { ReactComponent as RightIcon } from "static/icons/rightIcon.svg";
import { ReactComponent as LeftIcon } from "static/icons/LeftIcon.svg";
import { ReactComponent as DeleteIcon } from "static/icons/IconDelete.svg";
import cx from "classnames";
import Modal from "common/modal";
import useDeleteSite from "modules/MyCompany/api/useDeleteSite";
import { queryCache } from "react-query";
import toast from "react-hot-toast";
import { ReactComponent as DeleteToastIcon } from "static/icons/TrashOutline.svg";
import LoadingSpinner from "common/LoadingSpinner/LoadingSpinner";

const DeleteModal = ({
	setOpenModal,
	setDisplayDrawer,
	id,
	dataSite,
	onDeleteSiteSuccess
}) => {
	const [check, setCheck] = useState(false);
	const [step, setStep] = useState(0);
	const [linkedVacancyId, setLinkedVacancyId] = useState();
	const [selectedOption, setSelectedOption] = useState("");
	const [requestSite, setRequestSite] = useState("");

	const handleCheck = () => {
		setCheck(!check);
	};

	const vacanciesCount = get(dataSite, "count_vacancies");
	const isCareerPage = get(dataSite, "pages.url_career_page");
	const isJobPage = get(dataSite, "pages.url_job_list_page");

	const [mutateDeleteSite, { isLoading: deleteSiteLoading }] = useDeleteSite({
		onSuccess: () => {
			onDeleteSiteSuccess();
			queryCache.invalidateQueries("@getSites");
			toast("Successfully deleted", {
				position: "bottom-right",
				duration: 2500,
				icon: <DeleteToastIcon />
			});
			setOpenModal(false);
		},
		onError: error => {
			if (error.status === 422) {
				const message = error.detail.name;
				message &&
					toast(message, {
						isFailed: true
					});
			}
		}
	});

	const vacancySelectHandler = selectedVacancy => {
		setLinkedVacancyId(get(selectedVacancy, "_id", ""));
	};

	const modalConditions = {
		hasBoth: [
			<FirstStepCommponent
				key={1}
				handleCheck={handleCheck}
				check={check}
				vacanciesCount={vacanciesCount}
				requestCount={dataSite?.count_requests}
				isJobPage={isJobPage}
				isCareerPage={isCareerPage}
				setDisplayDrawer={setDisplayDrawer}
				setOpenModal={setOpenModal}
				onChange={vacancySelectHandler}
				siteId={id}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				requestSite={requestSite}
				setRequestSite={setRequestSite}
			/>,
			<SecondStepComponent key={2} />
		],
		hasVacancies: [
			<FirstStepCommponent
				key={1}
				hideSection="vacancies"
				vacanciesCount={vacanciesCount}
				requestCount={dataSite?.count_requests}
				isJobPage={isJobPage}
				isCareerPage={isCareerPage}
				setDisplayDrawer={setDisplayDrawer}
				setOpenModal={setOpenModal}
				onChange={vacancySelectHandler}
				siteId={id}
				selectedOption={selectedOption}
				setSelectedOption={setSelectedOption}
				requestSite={requestSite}
				setRequestSite={setRequestSite}
			/>,
			<SecondStepComponent key={2} />
		],
		hasJobPage: [
			<FirstStepCommponent
				key={1}
				hideSection="jobPage"
				handleCheck={handleCheck}
				check={check}
				vacanciesCount={vacanciesCount}
				requestCount={dataSite?.count_requests}
				isJobPage={isJobPage}
				isCareerPage={isCareerPage}
				setDisplayDrawer={setDisplayDrawer}
				setOpenModal={setOpenModal}
			/>,
			<SecondStepComponent key={2} />
		],
		hasNone: [<SecondStepComponent key={2} />]
	};

	const getDisplayedStep = (hasVacancies, isJobPage) => {
		if (hasVacancies && isJobPage) {
			return "hasBoth";
		}
		if (hasVacancies) {
			return "hasVacancies";
		}
		if (isJobPage) {
			return "hasJobPage";
		}
		return "hasNone";
	};

	const displayedStepKey = getDisplayedStep(
		vacanciesCount > 0 || dataSite?.count_requests > 0,
		isJobPage
	);
	const StepsKeyLength = get(modalConditions, `[${displayedStepKey}].length`);

	const checkCondition =
		displayedStepKey === "hasBoth"
			? !check ||
			  (get(selectedOption, "name", "") === "" && vacanciesCount !== 0) ||
			  (get(requestSite, "name", "") === "" && dataSite?.count_requests !== 0)
			: displayedStepKey === "hasVacancies"
			? get(selectedOption, "name", "") === "" ||
			  get(requestSite, "name", "") === ""
			: !check;

	return (
		<Modal
			size={
				StepsKeyLength > 1 && step === 1
					? "500px"
					: StepsKeyLength > 1 && displayedStepKey === "hasBoth"
					? "750px"
					: "500px"
			}
			padding={"1.5rem"}
			backgroundColor={"rgba(0,0,0,0.25)"}
			borderRadius={"8px"}
			boxShadow={
				"0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
			}
			border={"1px solid #D4D4D4"}
			isOnProceed
		>
			<div className={style.header}>
				<h3 className={style.modalTitle}>Delete site</h3>
				<CloseIcon
					className={style.closeIcon}
					onClick={() => setOpenModal(false)}
				/>
			</div>
			{get(modalConditions, [displayedStepKey, step], <></>)}
			{StepsKeyLength > 1 && step === 0 && (
				<div className={style.btnContainer}>
					<button
						className={style.nextBtn}
						disabled={checkCondition}
						onClick={() => setStep(1)}
					>
						Next <RightIcon />
					</button>
				</div>
			)}
			<div
				className={cx(style.btnsContainer, {
					[style.noBack]: StepsKeyLength <= 1
				})}
			>
				{StepsKeyLength > 1 && step === 1 && (
					<button className={style.backBtn} onClick={() => setStep(0)}>
						<LeftIcon />
						Back
					</button>
				)}
				{StepsKeyLength === step + 1 && (
					<div className={style.btnsRow}>
						<button
							className={style.cancelBtn}
							onClick={() => setOpenModal(false)}
						>
							Cancel
						</button>
						<button
							className={style.deleteBtn}
							onClick={() => {
								mutateDeleteSite({
									id,
									vacancy_site_id: linkedVacancyId,
									request_site_id: requestSite._id
								});
							}}
						>
							{!deleteSiteLoading ? (
								<>
									<DeleteIcon /> Delete
								</>
							) : (
								<LoadingSpinner />
							)}
						</button>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default DeleteModal;
