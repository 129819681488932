import styled, { css } from "styled-components";
import { colors, utilities } from "config/styles";

const InfoContainer = styled.div`
	float: left;
	margin-top: ${props => (props.isPokeHead ? "0px" : "15px")};
	color: ${colors.anchorsColor};
	font-weight: normal;
	min-width: 150px;
	i {
		margin-right: 10px;
	}
	.placeholder {
		width: 70% !important;
	}
`;
const PhoneContainer = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	.phone_number {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 13px;
		color: #202223;
	}
	.icon-phone-handle {
		font-size: 16px;
	}
`;
const EmailContainer = styled.div`
	display: flex;
	align-items: center;
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #202223;
	i {
		margin-top: 10px;
		font-size: 16px;
	}
`;

const Divider = styled.div`
	float: left;
	border-left: 1px solid ${colors.sectionBorderColor};
	height: 80px;
	margin: 16px 30px 0px 0px;
`;

const ProfileContainer = styled.div`
	float: left;
	width: 450px;
	color: ${colors.anchorsColor};
	font-weight: normal;
	margin: ${({ margin }) => margin && margin};
	.ProfileContainer-label {
		font-family: "basiercircle";
		font-style: normal;
		font-size: 12px;
		line-height: 16px;
		color: #6d7175;
		text-transform: capitalize;
		min-width: 150px;
	}

	.ProfileContainer-salary {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	.ProfileContainer-location {
		display: flex;
		align-items: center;
	}
	.profile-expected_salary {
		font-weight: 500;
		color: #202223;
		margin-right: 4px;
	}
	.ProfileContainer-advantages {
		margin-left: 10px;
		font-size: 14px;
		margin-top: -2px;
		color: #202223;
		&:hover {
			cursor: pointer;
		}
	}
	.location_box {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
	}
`;

const ShortlistText = styled.div`
	text-align: center;
	font-weight: normal;
	padding: 32px 0;
	background-color: #fafbfb;
	color: #aeb4b9;
	line-height: ${props => (props.lineHeight ? props.lineHeight : 1.5)};
	i {
		font-weight: 500;
		font-style: initial;
	}
	.additionalComments {
		margin-top: 0;
		margin-bottom: 8px;
	}
	button {
		outline: 0;
		margin-top: 0;
		line-height: 36px;
		border: 0;
		background-color: #ffffff;
		font-size: 13px;
		font-weight: bold;
		color: #293d58;
		i {
			color: #ff6849;
			margin-left: 16px;
			font-size: 10px;
			-webkit-transition: 0.6s ease-out;
			-moz-transition: 0.6s ease-out;
			transition: 0.6s ease-out;
			display: inline-block;
			transform: rotate(89deg);
			&.toggle-down {
				transform: rotate(-92deg);
				display: inline-block;
			}
		}
	}
`;

const ShortlistDivider = styled.div`
	background-color: ${colors.divider};
	height: 4px;
	width: 50px;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const AddNoteButton = styled.button`
	font-weight: bold;
	background-color: ${colors.white};
	outline: none !important;
	text-decoration: none;
	color: ${colors.anchorsColor};
	border: 1.5px solid ${colors.darkBorderColor};
	margin-bottom: 10px;
	padding: 14px 20px;
	display: flex;
	align-items: center;
	transition: 0.2s;
	&:hover {
		color: white;
		background-color: ${colors.anchorsColor};
		border-color: ${colors.anchorsColor};
	}
	cursor: pointer;
	i {
		float: left;
		font-size: 24px;
	}
	div {
		width: 100%;
		margin-left: -21px;
	}
`;

const ProposeInterviewButton = styled.button`
	font-weight: bold;
	background-color: ${colors.primaryColor};
	outline: none !important;
	text-decoration: none;
	color: ${colors.white};
	border: none;
	margin-bottom: 10px;
	padding: 14px 20px;
	cursor: pointer;
	i {
		float: left;
		font-size: 24px;
		margin-top: -6px;
	}
	div {
		width: 100%;
		margin-left: -21px;
	}
`;

export const PrimaryButton = styled.button`
	font-weight: bold;
	background-color: ${colors.primaryColor};
	outline: none !important;
	text-decoration: none;
	color: ${colors.white};
	border: none;
	margin-bottom: 10px;
	padding: 14px 20px;
	cursor: pointer;
	i {
		float: left;
		font-size: 24px;
		margin-top: -6px;
	}
	div {
		width: 100%;
		margin-left: -21px;
	}
`;

const PokeNotesTableDetails = styled.table`
	width: 100%;
	border: 0;
	table-layout: fixed;
	margin: ${props => props.margin || 0};
	border-spacing: ${props => props.spacing || 0};
	border-collapse: ${props => (props.collapse ? "collapse" : "initial")};
	> table > thead > tr > th {
		font-family: "basiercircle";
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
	}
	.g-tool {
		.cancelled {
			color: #fc4f56;
			font-size: 20px;
		}
		.done {
			height: 20px;
			width: 20px;
			line-height: 2;
			font-weight: 500;
			text-align: center;
			border-radius: 26px;
			font-size: 12px;
			background: #36ba90;
			color: #fff;
		}
	}
`;

const Td = styled.td`
	border-radius: 1.5px;
	background-color: ${colors.white};
	padding: 18px 18px !important;
	height: 44px;
	font-size: 13px;
	font-weight: 400;
	text-transform: none;
	line-height: 1.3;
	color: ${colors.anchorsColor};
	text-align: ${props => props.align || "left"};
	border: 0;
	.rest-attendees {
		font-size: 13px;
		font-weight: 400;
		text-transform: none;
		color: #58626b;
	}
	.view-more {
		font-size: 12px;
		font-weight: 600;
		color: ${colors.disabledGray};
		&:hover {
			text-decoration: underline;
			color: ${colors.primaryColor};
		}
	}
	&:first-child {
		border-left: ${props =>
			!props.subTotal && "3px solid transparent"} !important;
	}
	&:last-child {
		border-right: none !important;
	}
	width: ${props => props.width || "auto"};
	${props =>
		props.ellipsis &&
		css`
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`};
`;

const Tr = styled.tr`
	cursor: ${props => (props.onClick ? "pointer" : "default")};
	&:hover {
		${Td}:first-child {
			border-left-color: ${colors.primaryColor} !important;
		}
		${Td} {
			background-color: unset !important;
		}
	}
	float: ${props => props.float || "none"};
`;

const Th = styled.th`
	border-radius: 1.5px;
	background-color: ${colors.white};
	padding: 18px !important;
	font-size: 12.5px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #202223;
	text-transform: capitalize;
	border: 0;
	border-bottom: 4px solid ${colors.bodyBg};
	text-align: ${props => props.align || "left"};
	width: ${props => props.width || "auto"};
`;

const Section = styled.div`
	.past-interviews {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #202223;
		font-family: "basiercircle";
		margin-left: 15px;
	}
	.flagwrapper_notes {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #202223;
		font-family: "basiercircle";
		margin-left: 15px;
	}
	.request-list {
		margin-top: 20px;
	}
`;
const FlagContainer = styled.div`
	left: -3px !important;
	.no-flag-notes {
		margin-left: 15px;
	}
`;
const PokeInfosContainer = styled.div`
	padding: 20px 0;
`;

const PhonePlaceholder = styled.span`
	margin-top: 8px;
`;
const EmailPlaceholder = styled.span`
	margin-bottom: 3px;
`;

const CandidateInfo = styled.div`
	display: flex;
	align-items: center;
	.g-tool {
		color: inherit !important;
		font-size: inherit !important;
		vertical-align: unset !important;
		margin-left: unset !important;
	}
	.function {
		width: 112px;
		.function-content {
			display: initial;
		}
	}
`;
const SidebarPadding = styled.div`
	padding-left: 10px;
	padding-right: 10px;
`;

const Placeholder = styled.span`
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.79;
	letter-spacing: normal;
	text-align: left;
	color: ${colors.placeholderColor};
	align-self: ${props => (props.center ? "center" : "unset")};
`;

const PillButton = styled.button`
	border-radius: 9999px;
	height: 30px;
	font-weight: 500;
	padding: 0;
	line-height: 1;
	margin-top: 5px;
	border-radius: 9999px;
	background-color: white;
	color: ${colors.primaryColor};
	border: 2px solid ${colors.primaryColor};
	text-transform: uppercase;
	width: 110px;
	font-size: 12px;
	outline: none;
	&:hover {
		background-color: ${colors.primaryColor};
		color: white;
	}
`;

const ActionsContainer = styled.div`
	button {
		height: 41px;
		border-radius: 3px;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		touch-action: manipulation;
		user-select: none;
		width: 100%;
		line-height: 15px;
		padding: 0px 20px;
		transition: all 0.5s;
	}
	.rc-dropdown{
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 0.9px;
		color: #626b74;
		padding: 7px 8px;
		.rc-dropdown-menu-item{
			display: block;
			padding: 6px 10px;
			width: 100%;
		}
	}
}
`;

const VideoBlock = styled.div`
	background-color: ${colors.white};
	min-height: 50px;
	color: ${colors.linksColor};
	margin-bottom: 5px;
	display: flex;
	font-weight: 500;
	img {
		width: 20px;
	}
`;

const AvatarHolder = styled.div`
	display: inline-block;
	width: 45px;
	vertical-align: middle;
	margin-right: 14px;
	> .avatar {
		border-radius: 0;
	}
`;
const Backdrop = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	opacity: 0.93;
	z-index: -1;
	background-color: ${colors.white};
`;

const BoxInterview = styled.div`
	max-width: 270px;
	width: 100%;
	height: ${({ height }) => (height ? height : "112px")};
	border-radius: 4px;
	box-shadow: ${({ boxShadow }) =>
		boxShadow ? boxShadow : "0px 2px 1px 0 rgba(197, 199, 206, 0.25)"};
	border: ${({ border }) => (border ? border : "solid 1px #e1e4e7")};
	background-color: #ffffff;
	align-items: center;
	display: flex;
	margin-right: 5px;
	&:last-child {
		margin-left: 10px;
	}
	.videoConference {
		line-height: 23px;
		span {
			font-size: 14px;
			color: #242f43;
		}
		.video_conf_details {
			margin-left: 30px;
			font-family: "basiercircle";
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 20px;
			color: #202223;
		}
	}
	.titleBox {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: #6d7175;
		display: flex;
		align-items: center;
		margin-bottom: 4px;
		> svg {
			min-width: 20px;
			margin-right: 10px;
		}
	}
	.icon-date-location {
		color: rgba(255, 104, 73, 0.96);
		font-size: 26px;
		margin-right: 16px;
		margin-left: 18px;
		.video-interview-icon {
			font-size: 20px !important;
		}
	}
	.detailsInterview {
		margin-left: 30px;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
		span {
			display: inline-block;
			line-height: 22px;
			&.dateInterview {
				border-right: 1px solid #e4e8ec;
				padding-right: 8px;
				margin-right: 8px;
			}
			&.timeInterview {
				i {
					color: rgba(255, 104, 73, 0.96);
					font-size: 12px;
				}
			}
		}
	}
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	max-width: ${props => (props.maxwidth ? "230px" : "none")};
	padding: 10px 10px;
	border-radius: ${utilities.borderRadius};
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};
	border: 1px dashed
		${props =>
			props.orange ? colors.primaryColor : colors.stepperDividerColor};
	${props =>
		props.right
			? `border-left:none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;`
			: `border-right: 1px dashed ${props =>
					props.orange ? colors.primaryColor : colors.stepperDividerColor};
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;`};
`;
const BoxContent = styled.div`
	text-align: center;
	font-weight: normal;
	line-height: 20px;
	font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
`;
const BoxTitle = styled.div`
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};
	text-align: center;
`;
const CancelledBlock = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;
const CancelledHelp = styled.div`
	color: ${colors.inputColor};
	font-weight: 400;
`;
const CancelledTitle = styled.h5`
	color: ${colors.paused};
	font-size: 18px;
`;
const Content = styled.div`
	margin-left: 30px;
	font-family: "basiercircle";
	font-size: 14px;
	line-height: 20px;
	line-height: 1.6;
	color: ${props =>
		props.color
			? props.color
			: props.orange
			? colors.primaryColor
			: colors.anchorsColor};

	font-weight: normal;
	word-break: break-word;
	${props => props.location && `text-align: center; font-size:12px`};
`;
const FlexBoxWrapper = styled.div`
	width: ${props => (props.width ? props.width : "auto")};
	display: flex;
	height: ${({ height }) => (height !== "auto" ? `${height}px` : height)};
	> div {
		flex-basis: 50%;
	}
`;

const FlexBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Icon = styled.i`
	font-size: 40px;
	color: ${colors.paused};
`;
const IconHolder = styled.div`
	margin-bottom: 10px;
`;

const InterviewWrapper = styled.div`
	padding: 35px 0px 0 0;

	&.interview-modal {
		padding: 0px;
		max-width: 580px;
		margin: 0 auto;
	}
	.descriptionInterview {
		margin-top: 25px;
	}
	.box_title {
		font-family: "basiercircle";
		font-size: 12px;
		line-height: 16px;
		color: #6d7175;
		text-transform: none;
		display: flex;
		align-items: center;
		> svg {
			minwidth: 20px;
			margin-right: 10px;
		}
	}
	${props =>
		props.justify &&
		css`
			justify-content: space-between;

			> div {
				flex-grow: 1;
			}
		`};
`;
const PropositionBox = styled.div`
	width: ${props => (props.fullWidth ? "100%" : "50%")};
	display: inline-block;
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};
	margin: ${props => (props.maxwidth ? "0 0 0 0" : "30px 30px 30px 0")};
	font-weight: 400;
	.title {
		max-width: ${props => (props.maxwidth ? "100%" : "none")};
		color: ${props =>
			props.orange ? colors.primaryColor : colors.anchorsColor};
		text-align: left;
		font-weight: 500;
		font-size: 15px;
		text-transform: none;
		line-height: 14px;
		margin-bottom: 18px;
		div {
			font-weight: normal;
			font-size: 12px;
			margin-top: 3px;
		}
	}
	.date {
		text-align: center;
		font-weight: 300;
		line-height: 20px;
	}
	.BoxWrapper {
		display: flex;
		width: 100%;
		align-items: baseline;
		justify-content: space-between;
		&.title {
			margin-bottom: 13px;
			&.modal-proposition {
				color: #242f43;
			}
		}
	}
`;
const Time = styled.div`
	font-size: 15px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 25px;
	text-align: center;
`;
const Title = styled.div`
	color: ${colors.anchorsColor};
	font-weight: 500;
	font-size: 12.5px;
	text-transform: uppercase;
	line-height: 14px;
	margin-bottom: 12px;
`;
const UserSender = styled.div`
	line-height: 14px;
	padding: 31px;
	width: 50%;
`;
const UserSenderName = styled.div`
	display: inline-block;
	vertical-align: middle;
	font-size: 13.5px;
	font-weight: 400;
	text-transform: capitalize;
	color: ${colors.darkGray};
`;

const DescriptionContainer = styled.div`
	padding: 15px;
	background-color: #f8f8f9;
	border: solid 1px #eef0f1;
	border-radius: 4px;
	margin: 0px 95px 0px 65px;
	margin-bottom: ${props => (props.marginBottom ? "10px" : "40px")};
	.title {
		color: #242f43;
		font-weight: bold;
		font-size: 15px;
	}
`;
const DetailInterview = styled.div`
	padding: 30px 16px 0px 15px;
	.box_title {
		display: flex;
		align-items: center;
		text-transform: capitalize;
		font-family: "basiercircle";
		font-size: 12px;
		color: #6d7175;
		> svg {
			min-width: 30px;
		}
	}
`;

const InterviewCanceledLabel = styled.div`
	color: #ff6849;
	text-align: center;
	font-weight: 300;
	font-size: 18px;
`;

const RejectedText = styled.div`
	margin-top: 15px;
	th {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12.5px;
		line-height: 16px;
		align-items: center;
		min-width: 174px;
		padding-bottom: 8px;
		color: #5c5f62;
	}
	td {
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: normal;
		font-size: 12.5px;
		line-height: 16px;
		min-width: 174px;
		color: #202223;
	}
`;

export {
	BoxInterview,
	UserSenderName,
	UserSender,
	Title,
	Time,
	PropositionBox,
	InterviewWrapper,
	IconHolder,
	Icon,
	FlexBoxWrapper,
	Content,
	CancelledTitle,
	CancelledHelp,
	CancelledBlock,
	BoxTitle,
	BoxContent,
	Box,
	Backdrop,
	AvatarHolder,
	VideoBlock,
	InfoContainer,
	PhoneContainer,
	Divider,
	ProfileContainer,
	ShortlistText,
	ShortlistDivider,
	AddNoteButton,
	PokeNotesTableDetails,
	Td,
	Tr,
	Th,
	Section,
	FlagContainer,
	EmailContainer,
	PokeInfosContainer,
	PhonePlaceholder,
	CandidateInfo,
	EmailPlaceholder,
	SidebarPadding,
	Placeholder,
	PillButton,
	ActionsContainer,
	ProposeInterviewButton,
	FlexBox,
	DescriptionContainer,
	DetailInterview,
	InterviewCanceledLabel,
	RejectedText
};
