import find from "lodash/find";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { REQUEST_VIEW_KANBAN_DATA_QUERY } from "./hooks/useTempPipelineDataApi";

export const canDragStage = ({ kanbanStages, endIndex }) => {
	const updatedKanbanStages = [...kanbanStages];
	const [currentStageAtEndIndex] = updatedKanbanStages.splice(endIndex, 1);

	if (!currentStageAtEndIndex.step_settings.can_move) {
		toaster.warning("Moving the stage to that position is not allowed.");
		return false;
	}

	return true;
};

export const reorderStages = ({ kanbanStages, startIndex, endIndex }) => {
	const updatedKanbanStages = [...kanbanStages];
	const [removed] = updatedKanbanStages.splice(startIndex, 1);
	updatedKanbanStages.splice(endIndex, 0, removed);
	return updatedKanbanStages;
};

export function invalidateStagesDataQuery({
	jobId,
	kanbanStages,
	invalidateStagesIds = []
}) {
	// invalidate all stages
	if (!invalidateStagesIds.length) {
		queryCache.invalidateQueries([
			REQUEST_VIEW_KANBAN_DATA_QUERY,
			{ job_id: jobId }
		]);
		return;
	}

	// invalidate specific stages
	invalidateStagesIds.map(stageId => {
		const stage = find(kanbanStages, s => s._id === stageId);
		queryCache.invalidateQueries([
			REQUEST_VIEW_KANBAN_DATA_QUERY,
			{
				job_id: jobId,
				steps: [stage.name]
			}
		]);
	});
}
