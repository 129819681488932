import React from "react";
// import styles from "./create-request-function-field.module.scss";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { ComboBox } from "common/ComboBox";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import { Controller } from "react-hook-form";
import { Item } from "react-stately";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { useGetFunctionsList } from "api/useGetFunctionsList";
import { useEffect } from "react";
import useElements from "hooks/useLayoutElements";

const CreateRequestFunctionField = ({
	error,
	control,
	subCategory,
	_function,
	setValue
}) => {
	const { mainElement } = useElements();
	const { data: list } = useGetFunctionsList(
		{ no_limit: true, sector_id: subCategory },
		{
			enabled: !!subCategory
		}
	);

	useEffect(() => {
		//When we get _function from server we get only name that's why we added this
		if (_function?.label && _function?.label === _function?.value && list) {
			const value = list.find(item => item.name === _function.label);

			//Sometimes function doesnt exist in the list due to an update

			setValue("_function", {
				value: value?._id,
				label: _function?.label
			});
		}
	}, [list, _function]);

	const getFunctionById = id => {
		return list?.find(({ _id }) => _id === id);
	};

	const onFunctionSelectionChange = (key, onChange) => {
		const functionObject = getFunctionById(key);

		const value = {
			label: functionObject?.name,
			value: key
		};

		onChange(value);
	};

	const onFunctionInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : _function.value
		};

		onChange(valueObject);
	};

	return (
		<CreateRequestFieldContainer>
			<CreateRequestLabelContainer>
				<CreateRequestFieldLabel displayStar>Function</CreateRequestFieldLabel>
			</CreateRequestLabelContainer>
			<Controller
				name="_function"
				control={control}
				render={({ field: { value, onChange } }) => {
					return (
						<ComboBox
							popoverContainer={mainElement}
							displayButton={false}
							selectedKey={value?.value}
							inputValue={value?.label}
							onInputChange={value => onFunctionInputChange(value, onChange)}
							onSelectionChange={key =>
								onFunctionSelectionChange(key, onChange)
							}
						>
							{(list || []).map(({ _id, name }) => (
								<Item key={_id}>{name}</Item>
							))}
						</ComboBox>
					);
				}}
			/>

			<CreateRequestFieldError error={error} />
		</CreateRequestFieldContainer>
	);
};

export default CreateRequestFunctionField;
