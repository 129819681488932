import React from "react";
import styles from "./request-pipeline-files-card.module.scss";
import { ReactComponent as UploadFile } from "static/icons/upload-file.svg";
import cx from "classnames";

const RequestPipelineFilesCard = ({ count, className }) => {
	return (
		<div className={cx(styles.container, className)}>
			<UploadFile height={20} width={20} />
			<div className={cx(styles.dayRate)}>{count}</div>
		</div>
	);
};

export default RequestPipelineFilesCard;
