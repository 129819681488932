import { renderError } from "config/helpers";
import { FORWARD_EMAIL } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

function forwardEmail(body) {
	return client(FORWARD_EMAIL, { body });
}

const useForwardEmail = options => {
	return useMutation(forwardEmail, {
		...options,
		onError: renderError
	});
};

export default useForwardEmail;
