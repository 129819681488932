import React, {
	useRef,
	useCallback,
	useEffect,
	useMemo,
	useState
} from "react";
import * as ReactDOM from "react-dom";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";
import {
	LexicalTypeaheadMenuPlugin,
	MenuOption,
	useBasicTypeaheadTriggerMatch
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import MentionNode, { $createMentionNode } from "../../nodes/MentionNode";
import useGetMentions from "common/Editor/api/useGetMentions";
import { DEPARTMENT } from "config";
import { removeTags } from "config/helpers";

const PUNCTUATION =
	"\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";
const DocumentMentionsRegex = {
	NAME,
	PUNCTUATION
};
const CapitalizedNameMentionsRegex = new RegExp(
	"(^|[^#])((?:" + DocumentMentionsRegex.NAME + "{" + 1 + ",})$)"
);
const PUNC = DocumentMentionsRegex.PUNCTUATION;
const TRIGGERS = ["@"].join("");
// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";
// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
	"(?:" +
	"\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
	" |" + // E.g. " " in "Josh Duck"
	"[" +
	PUNC +
	"]|" + // E.g. "-' in "Salier-Hellendag"
	")";
const LENGTH_LIMIT = 75;
const AtSignMentionsRegex = new RegExp(
	"(^|\\s|\\()(" +
		"[" +
		TRIGGERS +
		"]" +
		"((?:" +
		VALID_CHARS +
		VALID_JOINS +
		"){0," +
		LENGTH_LIMIT +
		"})" +
		")$"
);
// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;
// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
	"(^|\\s|\\()(" +
		"[" +
		TRIGGERS +
		"]" +
		"((?:" +
		VALID_CHARS +
		"){0," +
		ALIAS_LENGTH_LIMIT +
		"})" +
		")$"
);
// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 10;

function useMentionLookupService(mentionString, mentions, oldUsers) {
	const [results, setResults] = useState([]);
	const { data } = useGetMentions(oldUsers);

	useEffect(() => {
		if (!data?.list_users) {
			return;
		}

		const dummyLookupService = {
			search(string, mentions, callback) {
				let results = filterUsers(data.list_users, mentions, string);

				callback(results);
			}
		};

		dummyLookupService.search(mentionString, mentions, newResults => {
			//filter all-current-string
			setResults(newResults ? newResults : []);
		});
	}, [mentionString, data, mentions]);

	return { results, data };
}

function filterUsers(users, mentions, text) {
	const usersFiltered = users.filter(({ _id, user_name }) => {
		if (_id === DEPARTMENT._id) {
			return (
				user_name.toLowerCase().startsWith(text?.toLowerCase()) &&
				mentions.length === 0
			);
		} else {
			return (
				user_name.toLowerCase().startsWith(text?.toLowerCase()) &&
				!mentions.includes(_id)
			);
		}
	});

	return usersFiltered;
}

function checkForCapitalizedNameMentions(text, minMatchLength) {
	const match = CapitalizedNameMentionsRegex.exec(text);
	if (match !== null) {
		// The strategy ignores leading whitespace but we need to know it's
		// length to add it to the leadOffset
		const maybeLeadingWhitespace = match[1];
		const matchingString = match[2];
		if (matchingString != null && matchingString.length >= minMatchLength) {
			return {
				leadOffset: match.index + maybeLeadingWhitespace.length,
				matchingString,
				replaceableString: matchingString
			};
		}
	}
	return null;
}

function checkForAtSignMentions(text, minMatchLength) {
	let match = AtSignMentionsRegex.exec(text);
	if (match === null) {
		match = AtSignMentionsRegexAliasRegex.exec(text);
	}
	if (match !== null) {
		// The strategy ignores leading whitespace but we need to know it's
		// length to add it to the leadOffset
		const maybeLeadingWhitespace = match[1];
		const matchingString = match[3];
		if (matchingString.length >= minMatchLength) {
			return {
				leadOffset: match.index + maybeLeadingWhitespace.length,
				matchingString,
				replaceableString: match[2]
			};
		}
	}
	return null;
}

function getPossibleQueryMatch(text) {
	const match = checkForAtSignMentions(text, 0);
	return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}
class MentionTypeaheadOption extends MenuOption {
	constructor(user_name, _id) {
		super(user_name);
		this._id = _id;
		this.user_name = user_name;
	}
}

function MentionsTypeaheadMenuItem({
	index,
	isSelected,
	onClick,
	onMouseEnter,
	option
}) {
	let className = "item";
	if (isSelected) {
		className += " selected";
	}
	return (
		<li
			key={option.key._id}
			tabIndex={-1}
			className={className}
			ref={option.setRefElement}
			role="option"
			aria-selected={isSelected}
			id={"typeahead-item-" + index}
			onMouseEnter={onMouseEnter}
			onClick={onClick}
		>
			<span className="text">@ {option.key.user_name}</span>
		</li>
	);
}

export default function NewMentionsPlugin({
	addMention,
	mentions,
	id,
	oldUsers,
	setMentions,
	content
}) {
	const hasMentionAll = useRef(false);
	const [editor] = useLexicalComposerContext();
	const [queryString, setQueryString] = useState(null);
	const { results, data: usersList } = useMentionLookupService(
		queryString,
		mentions,
		oldUsers
	);
	const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
		minLength: 0
	});

	useEffect(() => {
		if (content?.includes(DEPARTMENT._id)) {
			hasMentionAll.current = true;
		}
	}, []);

	useEffect(() => {
		const deleteListener = editor.registerMutationListener(
			MentionNode,
			nodeMutations => {
				var isMentionDeleted = false;
				for (const [, mutation] of nodeMutations) {
					if (mutation === "destroyed") {
						isMentionDeleted = true;
					}
				}
				editor.update(() => {
					const html = $generateHtmlFromNodes(editor, null);
					const comment = removeTags(html).trim().length > 0 ? html : "";

					if (!isMentionDeleted) return;

					if (comment.includes(DEPARTMENT._id)) {
						hasMentionAll.current = true;
						return;
					} else {
						if (hasMentionAll.current && !comment.includes(DEPARTMENT._id)) {
							setMentions([], id);
							hasMentionAll.current = false;
							return;
						}
					}

					let newMentions = mentions.filter(item => comment.includes(item));
					setMentions(newMentions, id);
				});
			}
		);

		return () => deleteListener();
	}, [editor, mentions, setMentions, id, hasMentionAll]);

	const options = useMemo(
		() =>
			results
				?.map(
					result =>
						new MentionTypeaheadOption(result, (<i className="icon user" />))
				)
				.slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
		[results]
	);

	const onSelectOption = useCallback(
		(selectedOption, nodeToReplace, closeMenu) => {
			editor.update(() => {
				const userId = selectedOption.key._id;
				if (userId === DEPARTMENT._id) {
					const list = usersList.list_users
						.map(({ _id }) => _id)
						.filter(item => item !== DEPARTMENT._id);
					setMentions(list, id);
				} else {
					addMention(userId, id);
				}
				const mentionNode = $createMentionNode(selectedOption?.key);
				if (nodeToReplace) {
					nodeToReplace.replace(mentionNode);
				}
				mentionNode.select();
				closeMenu();
			});
		},
		[editor, usersList, id, addMention, setMentions]
	);
	const checkForMentionMatch = useCallback(
		text => {
			const mentionMatch = getPossibleQueryMatch(text);

			const slashMatch = checkForSlashTriggerMatch(text, editor);
			if (!mentionMatch && text.includes("@")) {
				return {
					leadOffset: 0,
					matchingString: "",
					replaceableString: "@"
				};
			}
			return !slashMatch && mentionMatch ? mentionMatch : null;
		},
		[checkForSlashTriggerMatch, editor]
	);
	return (
		<LexicalTypeaheadMenuPlugin
			onQueryChange={setQueryString}
			onSelectOption={onSelectOption}
			triggerFn={checkForMentionMatch}
			options={options}
			menuRenderFn={(
				anchorElement,
				{ selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
			) => {
				return anchorElement?.current && results.length > 0
					? ReactDOM.createPortal(
							<div className="typeahead-popover mentions-menu">
								<ul>
									{options &&
										options.length > 0 &&
										options.map((option, i) => (
											<MentionsTypeaheadMenuItem
												index={i}
												isSelected={selectedIndex === i}
												onClick={() => {
													setHighlightedIndex(i);
													selectOptionAndCleanUp(option);
												}}
												onMouseEnter={() => {
													setHighlightedIndex(i);
												}}
												key={option.key}
												option={option}
											/>
										))}
								</ul>
							</div>,
							anchorElement?.current
					  )
					: null;
			}}
		/>
	);
}
