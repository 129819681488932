import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import React, { useEffect } from "react";
import styles from "./email-template.module.scss";
import EmailTemplateEmptyBlock from "./components/EmailTemplateEmptyBlock";
import EmailTemplateSidebar from "./components/EmailTemplateSidebar";
import EmailTemplateForm from "./components/EmailTemplateForm/EmailTemplateForm";

export const getEmailTemplateRoute = templateId => {
	return `/settings/email-templates${
		templateId !== undefined
			? templateId
				? `/${templateId}`
				: ""
			: "(/:templateId)"
	}`;
};

export const getEmailTemplatePermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default function EmailTemplate({ params: { templateId } }) {
	const setBreadcrumbs = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setBreadcrumbs([{ name: "Settings" }, { name: "Email templates" }]);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.contentLayout}>
				<EmailTemplateSidebar />
				<div className={styles.content}>
					{templateId ? (
						<EmailTemplateForm templateId={templateId} key={templateId} />
					) : (
						<EmailTemplateEmptyBlock />
					)}
				</div>
			</div>
		</div>
	);
}
