import React from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import Card from "../Card";
import ToolTip from "common/GlobalTooltip";
import EmptyState from "../EmptyState";

import { ReactComponent as StarIcon } from "static/icons/star-outline.svg";
import { ReactComponent as SeparatorIcon } from "static/icons/separator-point.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle.svg";

import styles from "../Card/card.module.scss";

const FunctionsCard = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	isLoading,
	title,
	functions
}) => {
	return (
		<Card title={title} {...{ onDragEnd, onViewMore, onToggleVisibility }}>
			{isLoading ? (
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={200} height={16} />
				</SkeletonTheme>
			) : functions.length > 0 ? (
				functions.map(item => (
					<div key={item.name} className={styles.section}>
						<div className={styles.row}>
							<StarIcon fill={item.is_main ? "#F01C29" : "#BABEC3"} />
							<span className={styles.textNormal}>{item.name}</span>
							<SeparatorIcon />
							<span className={styles.textNormal}>{item.seniorityLabel}</span>
							<ToolTip
								placement={"right"}
								maxWidth={"30rem"}
								align={{
									offset: [15, 0]
								}}
								overlay={item.tooltip}
							>
								<InfoIcon className={styles.flex} />
							</ToolTip>
						</div>
					</div>
				))
			) : (
				<EmptyState />
			)}
		</Card>
	);
};
FunctionsCard.prototype = {
	...Card.prototype,
	isLoading: PropTypes.bool.isRequired,
	functions: PropTypes.arrayOf(
		PropTypes.shape({
			is_main: PropTypes.bool.isRequired,
			name: PropTypes.string.isRequired,
			tooltip: PropTypes.string.isRequired,
			seniorityLabel: PropTypes.string.isRequired
		})
	)
};
export default FunctionsCard;
