import React from "react";
import cx from "classnames";
import s from "./block.module.scss";

function Block({ title, children, className }) {
	return (
		<div className={cx(s.root, className)}>
			{title ? <h4>{title}</h4> : null}
			{children}
		</div>
	);
}
export default Block;
