import React from "react";
import get from "lodash/get";
import styles from "modules/home/containers/vacancyRatio/card/card.module.scss";

const Card = ({ iconFrom, iconTo, from, to, data }) => {
	return (
		<div className={styles.container}>
			<div className={styles.flexCenter}>
				{iconFrom}
				{iconTo}
				<div className={styles.text}>
					<span className={styles.displayBlock}>{from}</span>
					to {to}
				</div>
			</div>
			<div className={styles.numbers}>
				{get(data, "ratio", 0)} : {get(data, "ratio_target", 0)}
			</div>
		</div>
	);
};

export default Card;
