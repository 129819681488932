import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { browserHistory } from "react-router";
import Modal from "common/modal";
import { Button, OutlineButton } from "common/styled/buttons";
import { Message, ActionsContainer } from "../ProfileStyle";
import MenuWithCTA from "common/MenuWithCTA";
import styled from "styled-components";
import Icon from "common/styled/icons";
import { getVacancyProfileRoute } from "modules/job/components";
import FormField from "common/FormField";
import { TEXTAREA_MAX_LENGTH_500 } from "config";
import { createMarkup } from "config/helpers";
import { Scrollbars } from "react-custom-scrollbars";

export const CTA = styled(Button)`
	padding: 10px 7px;
	padding-right: 35px;
	padding-left: 35px;
	letter-spacing: 2px;
`;

export const CTAIcon = styled(Icon)`
	position: absolute;
	right: -3px;
	top: 7px;
	font-size: 10px !important;
`;

const PokeModal = ({
	changeSelectedVacancy,
	selectedVacancy,
	vacancies = [],
	toggleModal,
	company_name,
	first_name,
	sendPoke,
	handleSubmit,
	vacancyId,
	enableAdditionalComment,
	changeReduxForm
}) => {
	const [additionalComment, setAdditionalComment] = useState("");

	const vacanciesWithLabel = vacancies.map(vacancy => {
		return {
			value: vacancy._id,
			label: vacancy.title,
			_id: vacancy._id
		};
	});

	const defaultVacancy = vacanciesWithLabel.find(vacancy => {
		return vacancy.value === vacancyId;
	});

	useEffect(() => {
		if (defaultVacancy) {
			changeReduxForm("vacancy", defaultVacancy);
			changeSelectedVacancy(defaultVacancy);
		}
	}, [vacancyId]);

	const selectVacancy = vacancy => {
		changeSelectedVacancy(vacancy);
	};
	return (
		<Modal onClose={toggleModal} title={"Poke Candidate"}>
			<form onSubmit={handleSubmit(sendPoke)}>
				<Field
					label="Vacancy"
					name="vacancy"
					type="select"
					options={vacanciesWithLabel}
					value={selectedVacancy}
					defaultValue={defaultVacancy || ""}
					onChange={selectVacancy}
					placeholder={"Please select a vacancy"}
					component={MenuWithCTA}
					position={false}
					searchable={true}
					clearable={false}
					emptyText="You have no vacancies"
					cta={() => (
						<CTA
							size={14}
							small
							large
							onClick={() => browserHistory.push(getVacancyProfileRoute(""))}
						>
							Create a new vacancy
							<CTAIcon className="icon-newtab-arrow" />
						</CTA>
					)}
				/>
				{enableAdditionalComment && (
					<Field
						label="Additional comment"
						name="comment"
						type="textarea"
						component={FormField}
						placeholder={"Message"}
						maxLength={TEXTAREA_MAX_LENGTH_500}
						onBlur={event => {
							event.preventDefault();
							setAdditionalComment(event.target.value);
						}}
						className="textarea-no-resize"
					/>
				)}
				<Message>
					<p className="title">Message</p>
					<div className="message-box">
						Dear, <br /> <br />I have come across your profile and think it can
						be a good match for a vacancy currently open within our company,{" "}
						{company_name}.
						<br />
						<br />
						The job title is &quot;
						{selectedVacancy.label
							? selectedVacancy.label
							: "Please select a vacancy"}
						&quot;, you can learn more about it via this link <br /> <br />
						Should you be interested in learning more about it, please confirm
						your interest so we can organise a first interview. (There is a
						&quot;CONFIRM INTEREST&quot; button on the vacancy description,
						which you can find in your &quot;MY JOBS&quot; section)
						<br />
						<br />
						{additionalComment && (
							<>
								<div className="additional-comment">
									<div className="title">additional comments</div>
									<Scrollbars
										autoHide
										autoHideTimeout={5000}
										autoHideDuration={200}
										autoHeight
										autoHeightMax={120}
									>
										<div
											dangerouslySetInnerHTML={createMarkup(additionalComment)}
										/>
									</Scrollbars>
								</div>
							</>
						)}
						Looking forward to getting your feedback!
						<br />
						<br />
						Kind regards,
						<br />
						<br />
						{first_name}
					</div>
				</Message>
				<ActionsContainer>
					<Button type="primary" bordered>
						Poke candidate
					</Button>
					<OutlineButton onClick={toggleModal}>Cancel</OutlineButton>
				</ActionsContainer>
			</form>
			<div />
		</Modal>
	);
};

const validate = formProps => {
	const errors = {};

	if (!formProps.vacancy) {
		errors.vacancy = "Please select a vacancy.";
	}
	return errors;
};
export default reduxForm({
	form: "send-poke",
	validate,
	touchOnBlur: false
})(PokeModal);
