import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import FormField from "common/FormField";
import DatePickerField from "common/DatePickerField.new";
import S3Uploader from "common/S3Uploader";
import {
	DATE_FORMAT,
	TYPE_PDF,
	EUR,
	TYPE_JPEG,
	TYPE_PNG,
	TYPE_PJPEG,
	MAX_CURRENCY_VALUE,
	TIMESHEET_TAGS_STATUS
} from "config";
import { Element } from "react-scroll";
import toaster from "common/Toaster";
import CurrencyField from "common/NewCurrencyField";
import { Button } from "common/styled/buttons";
import { FloatItem } from "./TimesheetStyled";
import { utcTimeStamp } from "config/helpers";
import StyledSelect from "common/StyledSelect";
import {
	timesheetTagsSelectSelector,
	timesheetTagsProjectNameSelector,
	featureTimesheetTagsSelector
} from "../timesheetSelector";
import { DRAFT } from "config";
import { get } from "lodash";

class AddExpense extends PureComponent {
	state = {
		uploading: false
	};

	handleSubmit = formProps => {
		const {
			timesheetId,
			currentDocument,
			tagOptions,
			featureTimesheetTags,
			onComputeTimesheet
		} = this.props;
		const payload = { ...formProps };
		featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
			get(tagOptions, "length", 0) > 0 &&
			(payload.tag = get(payload, "tag.value", ""));

		const current = window.moment();

		payload.date.set({
			hour: current.get("hour"),
			minute: current.get("minute"),
			second: current.get("second")
		});

		payload.date = utcTimeStamp({
			date: payload.date,
			format: DATE_FORMAT,
			manageTime: false
		});

		payload.document = currentDocument;
		if (this.props.status === DRAFT) {
			payload.timesheet_id = timesheetId;
			return this.props.onAddExpense(payload);
		}
		onComputeTimesheet({
			data: {
				timesheet_id: timesheetId,
				dispute_items: {
					worktimes: { edited: [], added: [] },
					expenses: {
						edited: [],
						added: [{ amount: payload.price }]
					}
				}
			},
			payload
		});
	};

	progressComponent = data => {
		if (data) {
			return (
				<div className="file-progress-box">
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${data.percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	};

	uploadDone = data => {
		this.setState({ uploading: false });
		this.props.onUploadDone(data);
	};

	uploadError = error => {
		toaster.danger(error);
	};

	render() {
		const {
			month,
			disabled,
			handleSubmit,
			currentDocument,
			projectName,
			tagOptions,
			featureTimesheetTags,
			tagOverlayText,
			getInitialVisibleMonth,
			reportingSettings
		} = this.props;
		const filled =
			currentDocument || (this.props.editItem && this.props.editItem.document);
		const hasTag =
			featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
			get(tagOptions, "length", 0) > 0;
		const width = {
			date: hasTag ? "16%" : "20%",
			description: hasTag ? "26%" : "30%",
			tag: "20%",
			receipt: hasTag ? "10%" : "14%",
			amount: hasTag ? "16%" : "20%",
			button: hasTag ? "12%" : "16%"
		};

		return (
			<Element name="add-new-box">
				<div>
					<form onSubmit={handleSubmit(this.handleSubmit)}>
						<FloatItem width={width.date}>
							<Field
								classes="inner-icon left-icon"
								disabled={disabled}
								icon="icon-calendar"
								name="date"
								label="Date"
								placeholder="Date"
								component={DatePickerField}
								minDate={window.moment.unix(month.start_date).toDate()}
								maxDate={window.moment.unix(month.end_date).toDate()}
								initialVisibleMonth={getInitialVisibleMonth}
							/>
						</FloatItem>
						<FloatItem width={width.description}>
							<Field
								disabled={disabled}
								type="text"
								label="Description"
								placeholder="Description"
								name="description"
								position="bottom"
								component={FormField}
								autoComplete="off"
							/>
						</FloatItem>
						{hasTag && (
							<FloatItem width={width.tag} labelMarginBottom={"-2px"}>
								<Field
									placeholder={projectName || "TAG"}
									name="tag"
									label={projectName || "TAG"}
									options={tagOptions}
									disabled={disabled}
									position="bottom"
									toolTipOverlay={tagOverlayText}
									component={StyledSelect}
								/>
							</FloatItem>
						)}
						<FloatItem width={width.receipt} className="upload-holder">
							<div
								style={{
									position: "relative"
								}}
								className="form-goup"
							>
								<label>RECEIPT</label>
								<S3Uploader
									id="document-uploader"
									onStart={() => this.setState({ uploading: true })}
									className={`${filled ? "filled" : ""}`}
									onSuccess={this.uploadDone}
									onError={this.uploadError}
									folder="timesheet"
									chooseButton={false}
									cancelButton={false}
									text={{
										placeholder: "",
										icon: { class: "icon-upload" }
									}}
									multiple={false}
									progressComponent={this.progressComponent}
									autoSubmit={true}
									accept={[TYPE_PDF, TYPE_JPEG, TYPE_PNG, TYPE_PJPEG]}
									disabled={disabled}
								/>
								{filled && <i className="fa fa-check" />}
							</div>
						</FloatItem>
						<FloatItem width={width.amount}>
							<Field
								disabled={disabled}
								type="text"
								placeholder="Amount"
								icon="icon-cash-money"
								label="Amount"
								name="price"
								position="bottom"
								suffix={reportingSettings?.currency?.symbol ?? EUR}
								component={CurrencyField}
							/>
						</FloatItem>
						<FloatItem padding="0" width={width.button}>
							<Button
								type="submit"
								disabled={disabled || this.state.uploading}
								block
								size={14}
								style={{ marginTop: 23 }}
							>
								Add new
							</Button>
						</FloatItem>
					</form>
				</div>
			</Element>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.date) {
		errors.date = "Field can't be empty";
	}
	if (!formProps.price) {
		errors.price = "Field can't be empty";
	}
	if (!formProps.tag) {
		errors.tag = "Field can't be empty";
	}
	if (
		formProps.price &&
		(formProps.price <= 0 || formProps.price >= MAX_CURRENCY_VALUE)
	) {
		errors.price = "The price should be between 1 and 1000€";
	}
	if (!formProps.description || formProps.description.trim() === "") {
		errors.description = "Field can't be empty";
	}
	return errors;
};

const mapStateToProps = state => {
	return {
		editItem: state.timesheet.editItem,
		featureTimesheetTags: featureTimesheetTagsSelector(state),
		tagOptions: timesheetTagsSelectSelector(state),
		projectName: timesheetTagsProjectNameSelector(state),
		initialValues: {
			date: state.timesheet.editItem
				? window.moment.unix(state.timesheet.editItem.date).format(DATE_FORMAT)
				: "",
			description: state.timesheet.editItem
				? state.timesheet.editItem.description
				: "",
			price: state.timesheet.editItem ? state.timesheet.editItem.price : "",
			document: state.timesheet.editItem
				? state.timesheet.editItem.document
				: ""
		}
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "add-expense",
		validate,
		touchOnBlur: false,
		enableReinitialize: true
	})
)(AddExpense);
