import React from "react";
import styles from "./form-builder-element-yes-no.module.scss";
import { useFormContext } from "react-hook-form";
import get from "lodash/get";
import FieldError from "../FieldError";
import { ELEMENT_LABELS } from "../../utils/constants";
import FormBuilderDragButton from "../FormBuilderDragButton";
import FormBuilderActionToggleRequired from "../FormBuilderActionToggleRequired";
import FormBuilderActionDuplicate from "../FormBuilderActionDuplicate";
import FormBuilderActionDelete from "../FormBuilderActionDelete";
import cx from "classnames";

export default function FormBuilderElementYesNo({
	element,
	path,
	onToggleRequired,
	onDelete,
	onDuplicate,
	dragHandleProps,
	isPresentational
}) {
	const {
		register,
		formState: { errors }
	} = useFormContext();

	return (
		<div
			className={cx(styles.container, { [styles.shadow]: isPresentational })}
		>
			<FormBuilderDragButton
				className={styles.dragHandler}
				dragHandleProps={dragHandleProps}
			/>
			<div className={styles.subContainer}>
				<div className={styles.header}>
					<div className={styles.title}>{ELEMENT_LABELS.yes_no}</div>
					<FormBuilderActionToggleRequired
						onClick={onToggleRequired}
						value={element.is_required}
					/>
					<FormBuilderActionDuplicate onClick={onDuplicate} />
					<FormBuilderActionDelete onClick={onDelete} />
				</div>
				<div className={styles.inputContainer}>
					<input
						className={styles.input}
						placeholder="Enter question content"
						{...(isPresentational
							? { value: element.title }
							: register(`${path}.title`))}
					/>
					<FieldError
						error={get(errors, path)?.title}
						className={styles.error}
					/>
				</div>
			</div>
		</div>
	);
}
