/* eslint-disable react/no-unescaped-entities */
import React from "react";
import {
	BodyContainer,
	PropositionBox,
	BoxInterview,
	Content
} from "../styles/styled";
import { formatDate } from "common/Functions";
import { TIME_FORMAT_SYMBOL, DATA_MMMM_D_YYYY, VIDEO_CONFERENCE } from "config";

const InvitationBody = props => {
	const { interview } = props;
	const {
		new_interview_start_date,
		new_interview_end_date,
		city,
		country,
		location_type,
		street,
		zip
	} = interview || {};
	const formatedInvitationDate = formatDate(
		new_interview_start_date,
		true,
		DATA_MMMM_D_YYYY
	);
	const formattedInvitationStartTime = formatDate(
		new_interview_start_date,
		true,
		TIME_FORMAT_SYMBOL
	);
	const formattedInvitationEndTime = formatDate(
		new_interview_end_date,
		true,
		TIME_FORMAT_SYMBOL
	);

	const getLocationInfo = (city, country, street, zip) => {
		return city
			? renderAdress(city, country, street, zip)
			: "Virtual meeting room";
	};

	const renderAdress = (city, country, street, zip) => {
		return (
			<div className="adress-grid">
				<span>{street}</span>
				<span>{`${zip} ${city}`}</span>
				<span>{country}</span>
			</div>
		);
	};

	const renderLocationType = locationType => {
		return locationType === "company_address"
			? "Location - Company Address"
			: "Location -  Video Conference";
	};

	return (
		<BodyContainer>
			<div className="section-proposition">
				<PropositionBox maxwidth fullWidth>
					<div className="BoxWrapper">
						<BoxInterview>
							<div className="icon-date-location">
								<i className="icon-date-time" />
							</div>
							<div className="contentBox">
								<h3 className="titleBox">{"Date & time"}</h3>
								<div className="detailsInterview">
									<span className="dateInterview">
										{formatedInvitationDate}
									</span>
									<span className="timeInterview">
										{formattedInvitationStartTime}{" "}
										<i className="icon-arrow-right-new" />{" "}
										{formattedInvitationEndTime}
									</span>
								</div>
							</div>
						</BoxInterview>

						<BoxInterview>
							<>
								{location_type === VIDEO_CONFERENCE ? (
									<>
										<div className="icon-date-location">
											<i className="icon-location-virtual-room video-interview-icon" />
										</div>

										<div className="contentBox videoConference">
											<h3 className="titleBox">Location - video conference</h3>
											<span>Virtual Meeting Room</span>
										</div>
									</>
								) : (
									<>
										<div className="icon-date-location">
											<i className="icon-adresse-propose" />
										</div>
										<div className="contentBox">
											<h3 className="titleBox">
												{renderLocationType(location_type)}
											</h3>
											<Content>
												{getLocationInfo(city, country, street, zip)}
											</Content>
										</div>
									</>
								)}
							</>
						</BoxInterview>
					</div>
				</PropositionBox>
			</div>
		</BodyContainer>
	);
};

export default InvitationBody;
