import toaster from "common/Toaster";
import { client } from "lib/api-client";
import { COMPANIES_LIST, MANAGE_FOLLOW_COMPANY } from "config/api-endpoints";
import { useMutation, useQuery } from "react-query";

const REACT_QUERY_RETRY = 1;
const REACT_QUERY_STALE_TIME = 0;
const KEEP_PREVIOUS_DATA = false;
const REFECH_ON_WINDOW_FOCUS = false;

const queryFn = body => client(COMPANIES_LIST, { body });

export const COMPANIES_LIST_QUERY = "COMPANIES_LIST_QUERY";
export const useCompaniesList = (payload, options = {}) =>
	useQuery([COMPANIES_LIST_QUERY, payload], () => queryFn(payload), {
		retry: REACT_QUERY_RETRY,
		keepPreviousData: KEEP_PREVIOUS_DATA,
		refetchOnWindowFocus: REFECH_ON_WINDOW_FOCUS,
		staleTime: REACT_QUERY_STALE_TIME,
		...options
	});

export function useCompaniesMutation() {
	return useMutation(body => client(MANAGE_FOLLOW_COMPANY, { body }), {
		onError: () => {
			toaster.danger("Something went wrong. Please try again!");
		}
	});
}
