import React from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import get from "lodash/get";
import { SelectContainer } from "./style";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { ReactComponent as ErrorIcon } from "common/react-hook-form-components/icons/elements-icons-error.svg";

const getApplications = profile_id =>
	client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "list_vacancies",
			profile_id: profile_id
		}
	});

const formatApplicationData = applications => {
	return applications.map(application => {
		return {
			value: get(application, "_id", ""),
			label: get(application, "job_title", "")
		};
	});
};

export default function Application({
	label,
	onChange,
	placeholder = "Choose vacancy",
	value,
	name,
	error,
	profile_id
}) {
	const { data } = useQuery(["applications", profile_id], () =>
		getApplications(profile_id)
	);

	return (
		<>
			{data && get(data, "list_vacancies").length > 0 && (
				<SelectContainer className="note_type" error={Boolean(error)}>
					{label && <label className="select-label">{label}</label>}
					<Select
						clearable={true}
						options={formatApplicationData(get(data, "list_vacancies", []))}
						onChange={onChange}
						value={value}
						name={name}
						placeholder={placeholder}
					/>
					{error && (
						<span className="error">
							<ErrorIcon />
							<span className="text">{error.message}</span>
						</span>
					)}
				</SelectContainer>
			)}
		</>
	);
}
