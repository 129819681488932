import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import GlobalTooltip from "common/GlobalTooltip";
import { SwitcherWrapper, ToggleTooltip } from "./Styled";

const SettingsSwitcher = props => {
	const {
		name,
		label,
		checked,
		onChange,
		disabled,
		overlay,
		overlayText,
		isPermanentView
	} = props;
	return (
		<SwitcherWrapper>
			{label && (
				<label>
					{label}
					{isPermanentView && (
						<GlobalTooltip
							placement="top"
							textAlign="center"
							overlay={overlayText}
						/>
					)}
				</label>
			)}

			{disabled ? (
				<GlobalTooltip placement="top" overlay={overlay}>
					<ToggleTooltip isPermanentView={isPermanentView}>
						<Toggle
							id={name}
							checked={checked}
							icons={{
								checked: null,
								unchecked: null
							}}
							onChange={onChange}
							disabled={disabled}
						/>
					</ToggleTooltip>
				</GlobalTooltip>
			) : (
				<ToggleTooltip isPermanentView={isPermanentView}>
					<Toggle
						id={name}
						checked={checked}
						icons={{
							checked: null,
							unchecked: null
						}}
						onChange={onChange}
						name={name}
					/>
				</ToggleTooltip>
			)}
		</SwitcherWrapper>
	);
};

SettingsSwitcher.propTypes = {
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string
};

export default SettingsSwitcher;
