import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import Avatar from "common/Avatar/index";
import { browserHistory, Link } from "react-router";
import { connect } from "react-redux";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { CURRENCY_SUFFIX } from "config";
import {} from "common/styled/buttons";
import { formatDate } from "common/Functions";
import EmptyList from "./EmptyList";
import { getClientBidViewRoute } from "modules/RequestView/components/BidDetailsDrawer/utils/constant";

const OtherBids = props => {
	const getJobID = () => {
		return props.list.length ? _get(props, "list.0.job._id") : null;
	};

	return (
		<div className="bids-list">
			{props.list.length > 0 ? (
				<div>
					<ul>
						{props.list.map((item, i) => (
							<li
								key={i}
								onClick={() =>
									browserHistory.push(
										getClientBidViewRoute(
											_get(item, "_id"),
											_get(item, "job._id")
										)
									)
								}
							>
								<div className="info">
									<div style={{ marginRight: 10 }}>
										<Avatar
											src={item.avatar}
											name={`${item.user_first_name} ${item.user_last_name}`}
										/>
									</div>
									<div className="details">
										<div className="username">{`${item.user_first_name} ${item.user_last_name}`}</div>
										<div className="date">
											{formatDate(item.created_at, true)}
										</div>
									</div>
								</div>
								<div className="rate">
									<CurrencyFormatterRender
										isCent
										value={item.proposed_amount}
										suffix={
											CURRENCY_SUFFIX[
												_get(item, "reporting_settings.rate_type", "")
											]
										}
										currency={_get(item, "reporting_settings.currency.code")}
									/>
								</div>
							</li>
						))}
					</ul>
					<div className="actions">
						<Link to={`/job/${getJobID()}`}>Show more ...</Link>
					</div>
				</div>
			) : (
				<EmptyList title="No other bids available." />
			)}
		</div>
	);
};

OtherBids.propTypes = {
	list: PropTypes.array.isRequired
};

const mapStateToProps = state => {
	return {
		jobInfo: state.bids.jobInfo
	};
};

export default connect(mapStateToProps)(OtherBids);
