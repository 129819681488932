import React from "react";
import { Box } from "rebass";
import { useState } from "react";
import styled from "styled-components";
import { getInitials, hashCode } from "common/Avatar/utils";
import colors from "common/Avatar/utils/colors";
import { CANDIDATE_STATUS } from "./constants";

const AvatarContainer = styled.div`
	width: ${props => props.size}px;
	height: ${props => props.size}px;
	> img {
		border-radius: ${props =>
			props.radius === undefined ? "50%" : `${props.radius}`};
	}
	position: relative;
	display: inline-flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	background-color: ${props => props.backgroundColor};
	border: ${props => (!props.isProfileBorder ? "none" : "4px solid #dde2e7")};
	border-radius: 50%;
	padding: 2px;
`;

const AvatarText = styled.div`
	font-size: ${props => props.fontSize || props.size / 2.6}px;
	line-height: ${props => (props.size == 40 ? "34" : props.size)}px;
	font-weight: 400;
	color: ${props => props.textColor};
`;

const ProfileAvatar = ({
	size = 40,
	radius,
	name,
	forceInitials,
	src,
	backgroundColor,
	textColor,
	className,
	fallbackText,
	status = false,
	isProfileBorder = true,
	isFetching = false,
	fontSize
}) => {
	const [imageFailedLoading, setImageFailedLoading] = useState(false);
	const imgNotAvailable = !src || imageFailedLoading;
	const initials = getInitials(name, fallbackText);
	const hash = hashCode(name);
	const keys = Object.keys(colors);
	const key = keys[hash % keys.length];
	const color = colors[key];
	const containerBackgroundColor = !imgNotAvailable
		? "white"
		: backgroundColor || color.background;

	return (
		<AvatarContainer
			size={size}
			radius={radius}
			backgroundColor={containerBackgroundColor}
			className={className}
			status={status}
			isProfileBorder={isProfileBorder}
		>
			{(imgNotAvailable || forceInitials) && !isFetching && (
				<AvatarText
					fontSize={fontSize}
					size={size}
					textColor={textColor || color.text}
				>
					{initials}
				</AvatarText>
			)}
			{!imgNotAvailable && !isFetching && (
				<img
					src={src}
					alt={name}
					width={size || "auto"}
					height="100%"
					onError={() => setImageFailedLoading(true)}
				/>
			)}
			{status && (
				<Box
					sx={{
						borderRadius: "50%",
						background: CANDIDATE_STATUS[status],
						width: 18,
						height: 18,
						position: "absolute",
						top: "78%",
						border: "3px solid #ffffff",
						left: "75%"
					}}
				></Box>
			)}
		</AvatarContainer>
	);
};

export default ProfileAvatar;
