import React, { useEffect, useRef, useState } from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { Box, Flex, Text } from "rebass/styled-components";
import { Input } from "@rebass/forms";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import toaster from "common/Toaster";
import { SUPPORTED_SIZE } from "config";
import { uploadToS3 } from "config/helpers";

const MyFilesModal = React.lazy(() => import("./MyFilesModal"));

const preSignUrl = file =>
	client(PRE_SIGNED_URL, {
		body: {
			name: file.name,
			folder: "files",
			size: file.size,
			type: file.type
		}
	});

const REJECTIONS = {
	"file-invalid-type": "Invalid file type.",
	"file-too-large": "File is larger than 5MB."
};

const FilesUploader = ({
	name,
	_id,
	path,
	onNameChange,
	isRequired,
	onDoneUploading,
	accept = [
		"image/jpg",
		"image/jpeg",
		"image/png",
		"application/pdf",
		"application/msword",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/excel",
		"application/vnd.ms-excel",
		"application/x-excel",
		"application/x-msexcel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/mspowerpoint",
		"application/powerpoint",
		"application/vnd.ms-powerpoint",
		"application/x-mspowerpoint",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation"
	],
	hasNameInput = true
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [file, setFile] = useState(undefined);
	const [progress, setProgress] = useState(undefined);

	const label = useRef(name);

	const [upload, { isLoading: isUploading }] = useMutation(
		response => uploadToS3({ response, file, onProgress: setProgress }),
		{
			onSuccess: res => {
				onDoneUploading({
					name: !hasNameInput ? file.name : name,
					path: res.url,
					_id
				});
			}
		}
	);

	const [onDrop] = useMutation(preSignUrl, {
		onSuccess: upload
	});

	const {
		open,
		getRootProps,
		getInputProps,
		isDragActive,
		fileRejections
	} = useDropzone({
		onDrop: file => {
			setFile(file[0]);
			onDrop(file[0]);
		},
		noClick: true,
		multiple: false,
		maxSize: SUPPORTED_SIZE, // 5Mb in bytes,
		accept
	});

	useEffect(() => {
		if (fileRejections.length) {
			fileRejections.forEach(({ errors }) => {
				errors.forEach(error =>
					toaster.danger(REJECTIONS[error.code], {
						duration: 8,
						id: error.code
					})
				);
			});
		}
	}, [fileRejections]);

	const handleFilePick = file => {
		onDoneUploading({
			name: file.file_name,
			_id,
			path: file.path
		});
	};

	const listMenu = () => {
		return (
			<Menu selectable={false}>
				<MenuItem key="upload" onClick={open}>
					<Text
						as={"span"}
						sx={{
							fontSize: "12px",
							fontWeight: "600",
							color: "rgb(160, 165, 185)"
						}}
					>
						FROM COMPUTER
					</Text>
				</MenuItem>
				<MenuItem key="files" onClick={() => setIsOpen(true)}>
					<Text
						as={"span"}
						sx={{
							fontSize: "12px",
							fontWeight: "600",
							color: "rgb(160, 165, 185)"
						}}
					>
						FROM WIGGLI FILES
					</Text>
				</MenuItem>
				<input {...getInputProps()} />
			</Menu>
		);
	};

	return (
		<Flex mb={15} alignItems="center" pt={"2px"}>
			{hasNameInput && (
				<Box width={1 / 3} mr={16}>
					<Text
						sx={{
							fontFamily: "Roboto",
							fontSize: "16px",
							fontWeight: "bold",
							letterSpacing: "-0.21px",
							color: "rgb(80, 92, 104);"
						}}
					>
						{label.current}
					</Text>
					{isRequired ? (
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "10px",
								letterSpacing: "-0.13px",
								color: "rgb(253, 104, 73)"
							}}
						>
							Required
						</Text>
					) : null}
				</Box>
			)}
			{hasNameInput && (
				<Box width={1 / 3} mr={16}>
					<Input
						onChange={e => onNameChange(_id, e.target.value)}
						id="file-1"
						name="file-1"
						type="text"
						placeholder="Type the name of your file…"
						value={name}
					/>
				</Box>
			)}
			<Box width={hasNameInput ? 1 / 3 : 1}>
				<Flex
					sx={{
						position: "relative",
						height: 52,
						borderRadius: 3,
						border: `dashed 2px ${isDragActive ? "#2595e3" : "#d0d0d0"}`,
						"&:focus": {
							outline: "none"
						}
					}}
					justifyContent="space-between"
					alignItems="center"
					pl={20}
					pr={12}
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					{isUploading ? (
						<Box
							sx={{
								transition: "width 0.25s ease-in-out",
								willChange: "width",
								position: "absolute",
								left: 0,
								height: "100%",
								width: `${progress}%`,
								backgroundColor: "rgba(34, 148, 227, 0.1)"
							}}
						></Box>
					) : null}
					<Text
						sx={{
							fontFamily: "Roboto",
							fontSize: "14px",
							lineHeight: "1.5",
							letterSpacing: "-0.18px",
							color: "rgb(187, 198, 211)",
							overflow: "hidden",
							textOverflow: "ellipsis",
							width: "50%",
							whiteSpace: "nowrap"
						}}
					>
						{file
							? file.name
							: isDragActive
							? "Drop here..."
							: "Drag your file or"}
					</Text>
					<RcDropDown
						trigger={["click"]}
						overlay={listMenu()}
						overlayClassName="ignore-click"
						disabled={isUploading}
						align={{
							points: ["tc", "bc"],
							offset: [0, 10]
						}}
					>
						<Text
							as={"h3"}
							sx={{
								width: "81px",
								height: "28px",
								borderRadius: "2px",
								fontWeight: 400,
								backgroundColor: path ? "#3ebb90" : "rgb(182, 190, 198)",
								fontFamily: "Roboto",
								fontSize: "14px",
								letterSpacing: "-0.18px",
								color: "rgb(255, 255, 255)",
								cursor: "pointer",
								textAlign: "center",
								lineHeight: "28px"
							}}
						>
							{isUploading
								? `${progress ? progress.toFixed(0) : 0}%`
								: path
								? "Re-upload"
								: "Upload"}
						</Text>
					</RcDropDown>
				</Flex>
				<React.Suspense fallback="Opening 'My Files'">
					{isOpen ? (
						<MyFilesModal
							onClose={() => setIsOpen(false)}
							onAdd={handleFilePick}
						/>
					) : null}
				</React.Suspense>
			</Box>
		</Flex>
	);
};

export default FilesUploader;
