import React from "react";
import styles from "./tour-content-component.module.scss";
import cx from "classnames";
import useOnboardingStore from "../../hooks/useOnboardingStore";
import { browserHistory } from "react-router";
import useLayoutElements from "hooks/useLayoutElements";

export default function TourContentComponent(props) {
	const { setCurrentStep, cleanStore } = useOnboardingStore();
	const { scrollarea } = useLayoutElements();
	const isLastStep = props.currentStep === props.steps.length - 1;

	const { title, description, arrow } = props.steps[props.currentStep];

	const onClickNext = () => {
		setCurrentStep(props.currentStep + 1);
	};

	const onClickSkip = () => {
		cleanStore();
		props.setCurrentStep(0);
		props.setIsOpen(false);
		browserHistory.push("/");
		if (scrollarea) {
			scrollarea.scroll({ top: 0, behavior: "smooth" });
		}
	};

	return (
		<div
			className={cx(
				styles.popoverContentContainer,
				styles[`radius-for-${arrow}`]
			)}
		>
			<div className={cx(styles.arrow, styles[`arrow-${arrow}`])}>
				<svg
					width="24"
					height="16"
					viewBox="0 0 24 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 16C0 16 26.2314 15.9999 23.8467 16C21.4621 16.0001 19.0774 16.0001 15.5004 13.0001C13.128 11.0104 7.08418 5.94146 3.28256 2.75306C1.98107 1.66151 -7.46135e-07 2.58905 -6.5163e-07 4.28768L0 16Z"
						fill="currentColor"
					/>
				</svg>
			</div>
			<div className={styles.title}>{title}</div>
			<div className={styles.description}>{description}</div>
			<div className={styles.footer}>
				<span>
					{props.currentStep + 1}/{props.steps.length}
				</span>
				<div className={styles.control}>
					{isLastStep ? (
						<button className={styles.nextBtn} onClick={onClickSkip}>
							Got it!
						</button>
					) : (
						<>
							<button className={styles.skipBtn} onClick={onClickSkip}>
								Skip
							</button>
							<button className={styles.nextBtn} onClick={onClickNext}>
								Next
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
