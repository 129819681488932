import React from "react";
import Rater from "react-rater";
import ScorerKnob from "./ScorerKnob";

const SimpleScorer = ({
	maxScore,
	item,
	onScoreChange,
	tooltipFormater,
	readOnly,
	disabled = false
}) => {
	const _onScoreChange = ({ rating }) =>
		onScoreChange({ ...item, score: rating });

	return (
		<Rater
			interactive={!readOnly && !disabled}
			total={maxScore}
			rating={item.score}
			onRate={disabled ? null : _onScoreChange}
			className="reactRater"
		>
			<ScorerKnob hasTooltip={!disabled} tooltip={tooltipFormater(1)} />
			<ScorerKnob hasTooltip={!disabled} tooltip={tooltipFormater(2)} />
			<ScorerKnob hasTooltip={!disabled} tooltip={tooltipFormater(3)} />
			<ScorerKnob hasTooltip={!disabled} tooltip={tooltipFormater(4)} />
			<ScorerKnob hasTooltip={!disabled} tooltip={tooltipFormater(5)} />
		</Rater>
	);
};

export default SimpleScorer;
