import React from "react";
import { Box, Text } from "rebass";
import _get from "lodash/get";
import { formatDate } from "common/Functions";
import { ReactComponent as ImageIcon } from "static/icons/featured-image-icon.svg";
import { ReactComponent as Location } from "static/icons/Vectorlocation.svg";
import { ReactComponent as VideoIcon } from "static/icons/video-icon.svg";
import { ReactComponent as Hashtag } from "static/icons/Hash-numbersignref.svg";
import { ReactComponent as Department } from "static/icons/businessDept.svg";
import { ReactComponent as SiteIcon } from "static/icons/siteIcon.svg";
import { ReactComponent as Employment } from "static/icons/Vectoremptype.svg";
import { ReactComponent as LaptopIcon } from "static/icons/laptop-icon.svg";
import { ReactComponent as ClockIcon } from "static/icons/clock.svg";
import { ReactComponent as Creator } from "static/icons/VectorPERSON.svg";
import { ReactComponent as Time } from "static/icons/access_timeTime.svg";
import { ReactComponent as Channels } from "static/icons/channelCHANNEL.svg";
import { ReactComponent as CurrencyIcon } from "static/icons/currency.svg";
import DetailsComponent from "modules/vacancy-view/components/jobDetails/DetailsComponents";
import PublishComponent from "modules/vacancy-view/components/jobDetails/PublishComponent";
import s from "modules/vacancy-view/components/jobDetails/job-details.module.scss";
import styles from "./job-details.module.scss";
import {
	CONTRACT_TYPE,
	FULL_TIME_LABEL,
	PART_TIME_LABEL,
	HYBRID_OPTION,
	REMOTE_ELIGIBITY_OPTIONS,
	CURRENCY_SUFFIX
} from "config";
import useRequestViewCurrentTab from "modules/RequestView/hooks/useRequestViewCurrentTab";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import AddressComposer from "common/AddressComposer";
import { TIMESHEET_FREQUENCY_MONTHLY } from "config";

function RequestInformationsSidebar({ job }) {
	const { setIndexTab } = useRequestViewCurrentTab();
	const contractType = CONTRACT_TYPE.find(
		contract => contract.value === job.contract_type
	);
	const published_by = _get(job, "publishing_information.published_by", {});
	const publishingData = _get(job, "publishing_information", {});

	const handleTabsChange = () => {
		setIndexTab(1);
	};

	let data = [
		{
			icon: <Hashtag />,
			title: "Request Reference",
			value: _get(job, "job_ref", "")
		},
		{
			icon: <CurrencyIcon />,
			title: `Expected ${
				{
					hourly: "Hourly",
					daily: "Daily"
				}[_get(job, "reporting_settings.rate_type")]
			} Rate`,
			value: (
				<CurrencyFormatterRender
					isCent
					value={_get(job, "budget")}
					currency={_get(job, "reporting_settings.currency.code")}
					suffix={CURRENCY_SUFFIX[_get(job, "reporting_settings.rate_type")]}
				/>
			)
		},
		{
			icon: <CurrencyIcon />,
			title: "Currency",
			value: `${_get(job, "reporting_settings.currency.code")} (${_get(
				job,
				"reporting_settings.currency.symbol"
			)})`
		},
		{
			icon: <LaptopIcon className={styles.laptopIcon} />,
			title: "Remote eligibility",
			value: () => {
				if (!job.remote_eligibility) {
					return "---";
				}

				const {
					remote_eligibility: { type, office_days }
				} = job;

				let value;

				if (office_days && type.includes(HYBRID_OPTION.value)) {
					value = `Hybrid (${office_days} days in office/week)`;
				} else {
					const res = REMOTE_ELIGIBITY_OPTIONS.find(
						({ value }) => value === type
					);

					value = res?.label || "---";
				}

				return value;
			}
		},
		{
			icon: <Employment />,
			title: "Contract type",
			value: contractType?.label
		},
		{
			icon: <ClockIcon />,
			title: "Timesheet frequence",
			value:
				job.reporting_settings?.timesheet_frequency ===
				TIMESHEET_FREQUENCY_MONTHLY
					? "Monthly"
					: "Weekly"
		},
		{
			icon: <Location />,
			title: "Expected dates",
			value: (
				<>
					{formatDate(_get(job, "start_date"))} -{" "}
					{formatDate(_get(job, "end_date"))}
				</>
			)
		},
		{
			icon: <Location />,
			title: "Location",
			value: <AddressComposer addressData={job} />
		},
		{
			icon: <SiteIcon />,
			title: "Site",
			value: _get(job, "site.name", "")
		},
		{
			icon: <ClockIcon />,
			title: "Time-commitment",
			value: job.full_time ? FULL_TIME_LABEL : PART_TIME_LABEL
		},
		{
			icon: <Department />,
			title: "Department",
			value: _get(job, "group.name", "")
		},
		{
			icon: <Location />,
			title: "Applicant location",
			value:
				job.applicants_location?.[0].type === "any_where"
					? "Anywhere"
					: _get(job, "applicants_location[0].name")
		}
	];

	if (job.featured_image) {
		data = [
			...data,
			{
				icon: <ImageIcon />,
				title: "Featured image",
				value: <img className={styles.featuredImage} src={job.featured_image} />
			}
		];
	}

	const publishData = [
		{
			icon: <Creator />,
			title: "Published by",
			value: (
				<>
					{_get(published_by, "user_first_name")}{" "}
					{_get(published_by, "user_last_name")}
				</>
			)
		},
		{
			icon: <Time />,
			title: "Published on",
			value: formatDate(_get(job, "publishing_information.publish_date"))
		},
		{
			icon: <Channels />,
			title: "Internal Channels",
			value:
				_get(job, "publishing_information.internal_channels.length", -1) > 0 ? (
					<div>
						{_get(job, "publishing_information.internal_channels", []).map(
							ch => {
								return <div key={ch.channel_name}> {ch.channel_name}</div>;
							}
						)}
					</div>
				) : null
		},
		{
			icon: <Channels />,
			title: "External Channels",
			value:
				_get(job, "publishing_information.external_channels.length", -1) > 0 ? (
					<div>
						{_get(job, "publishing_information.external_channels", []).map(
							ch => {
								return <div key={ch.channel_name}> {ch.channel_name}</div>;
							}
						)}
					</div>
				) : null
		}
	];

	if (job.featured_video) {
		data = [
			...data,
			{
				icon: <VideoIcon />,
				title: "Featured video",
				value: (
					<a
						className={styles.featuredVideo}
						href={job.featured_video}
						target="_blank"
						rel="noopener noreferrer"
					>
						{job.featured_video}
					</a>
				)
			}
		];
	}

	return (
		<>
			<Box
				style={{
					marginLeft: "20px",
					marginBottom: "15px",
					backgroundColor: "white",
					padding: "15px",
					boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)"
				}}
			>
				<div className={s.boxLabel}>Publishing Information</div>
				{Object.keys(publishingData).length > 0 ? (
					<>
						{publishData.map(item => (
							<>
								{item.value && (
									<PublishComponent
										key={item.title}
										icon={item.icon}
										title={item.title}
										value={item.value}
									/>
								)}
							</>
						))}
						{_get(job, "publishing_information.channel_count") > 3 ? (
							<div className={s.countContainer}>
								<div className={s.countText}>
									{_get(job, "publishing_information.channel_count") - 3} more
									channels.
								</div>
								<button onClick={handleTabsChange} className={s.countBtn}>
									View all
								</button>
							</div>
						) : (
							""
						)}
					</>
				) : (
					<div
						style={{
							marginLeft: "7px",
							marginTop: "15px",
							marginBottom: "15px"
						}}
					>
						<Text>This request is not yet published.</Text>
					</div>
				)}
			</Box>

			<Box
				style={{
					marginLeft: "20px",
					backgroundColor: "white",
					padding: "15px",
					boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)"
				}}
			>
				<div className={s.boxLabel}>Request Informations</div>
				{data.map(item => (
					<DetailsComponent
						key={item.title}
						icon={item.icon}
						title={item.title}
						value={typeof item.value === "function" ? item.value() : item.value}
					/>
				))}
			</Box>
		</>
	);
}

export default RequestInformationsSidebar;
