import React from "react";
import EmptyStateIcon from "static/icons/empty-state-list-tasks.svg";
import { ReactComponent as AddNewTasks } from "static/icons/add-new-task-icon.svg";
import classnames from "./emptyState.module.scss";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

const EmptyState = ({ isAddNewTask = true }) => {
	const { setIsOpen } = useAddNewTaskStore();
	const openDrawerNewTask = () => {
		setIsOpen(true);
	};
	return (
		<div className={classnames.container}>
			<img src={EmptyStateIcon} />
			<span className={classnames.emptyStateText}>
				You have no tasks in this list!
			</span>
			{isAddNewTask && (
				<button
					onClick={openDrawerNewTask}
					className={classnames.addNewTaskButton}
				>
					<span>Add new task</span> <AddNewTasks />{" "}
				</button>
			)}
		</div>
	);
};

export default EmptyState;
