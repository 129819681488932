import { Button } from "common/Button";
import ConfirmationModal, {
	Description,
	Footer,
	Header
} from "common/ConfirmationModal";
import React from "react";
import styles from "./accept-interview-modal.module.scss";
const AcceptInterviewModal = ({
	acceptInterview,
	closeModal,
	displayModal
}) => {
	return (
		<>
			{displayModal ? (
				<ConfirmationModal width="567px" onClose={closeModal}>
					<Header title={"Accept interview!"} />
					<Description showIcon={false}>
						<div>Are you sure you want to accept the interview</div>
					</Description>
					<Footer onClose={closeModal}>
						<Button
							text="Cancel"
							variant="outlined"
							onClick={closeModal}
							className={styles.cancelButton}
							type="button"
						/>
						<Button text="Accept" onClick={acceptInterview} />
					</Footer>
				</ConfirmationModal>
			) : null}
		</>
	);
};

export default AcceptInterviewModal;
