/* eslint-disable react/display-name */
import React from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import GlobalTooltip from "common/GlobalTooltip";
import { Tag, CenterTooltip } from "common/styled/CommonStyled";
import { EDIT_DEPARTMENT, DELETE_DEPARTMENT, SUPER_ADMIN, ROLES } from "config";
import { Tr, Td } from "../../../timesheets/components/TimesheetStyled";

export default ({ department, onMenuSelect, connectedUser }) => {
	const isConnectedUserSuperAdmin =
		!connectedUser.is_member || connectedUser.role_name === SUPER_ADMIN;

	const tag = user =>
		(department.user.length === 1 || user.role_name !== SUPER_ADMIN) && (
			<Tag key={user._id}>
				{user.role_name === SUPER_ADMIN ? (
					<span>Super Admin</span>
				) : (
					<strong>
						{ROLES.find(role => role.name === user.role_name).label}
					</strong>
				)}{" "}
				- {user.first_name} {user.last_name}
			</Tag>
		);

	return (
		<Tr>
			<Td>
				<span className="name">{department.name}</span>
			</Td>
			<Td className="role">{department.user.map(tag)}</Td>
			<Td className="actions">
				{isConnectedUserSuperAdmin ? (
					<RcDropDown
						placement={"bottomCenter"}
						align={{
							points: ["tc", "bc"],
							offset: [-2, 5]
						}}
						trigger={["click"]}
						overlay={
							<Menu
								onClick={e => onMenuSelect(e, department)}
								selectable={false}
							>
								<MenuItem key={EDIT_DEPARTMENT}>
									<i className="icon-pen" /> Edit Department
								</MenuItem>
								{department.can_remove && (
									<MenuItem key={DELETE_DEPARTMENT}>
										<span className="icon-delete delete-department" />
										Delete Department
									</MenuItem>
								)}
							</Menu>
						}
					>
						<div className="dots">
							<div className="dot" />
							<div className="dot" />
							<div className="dot" />
						</div>
					</RcDropDown>
				) : (
					<CenterTooltip>
						<GlobalTooltip
							placement="top"
							overlay="You don’t have permission to edit this department."
						/>
					</CenterTooltip>
				)}
			</Td>
		</Tr>
	);
};
