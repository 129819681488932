import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { browserHistory } from "react-router";
import { Flex, Box, Text } from "rebass";
import _get from "lodash/get";
import _has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import {
	CANCELLED,
	SENT,
	DECLINED,
	REFUSED,
	ACCEPTED,
	EXPIRED,
	DONE,
	PERMANENT,
	DRAFT,
	APPLICATION
} from "config";
import { PENDING_APPLICATIONS } from "modules/home/config";
import InterviewDetails from "../InterviewDetails";
import PokeDetails from "../PokeDetails";
import NotesDetails from "../NotesDetails";
import PhoneScreen from "../PhoneScreen";
import PokeHeadLine from "./PokeHeadLine";
import PokeNoteModal from "../../modals/PokeNoteModal";
import PokeSideBar from "../PokeSideBar";
import ClientInterviewModal from "../../modals/ClientInterview";
import InterviewsHistory from "./InterviewsHistory";
import InterviewDetailsModal from "./InterviewDetailsModal";
import AcceptInterviewModal from "common/AcceptInterviewModal";
import OfferDetails from "modules/vacancy/components/pokeView/client/OfferDetails.jsx";
import { Tabs, TabPanel } from "@reach/tabs";
import { client } from "lib/api-client";
import { VIEW_CANDIDATE_ENDPOINT } from "config/api-endpoints";
import ResumeTab from "modules/vacancy/components/pokeView/client/Tabs/ResumeTab";
import PreferencesTab from "modules/vacancy/components/pokeView/client/Tabs/Preferences";
import History from "modules/candidate/components/History";
import FunctionsTab from "modules/vacancy/components/pokeView/client/Tabs/FunctionsSkillsTab";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { getClientPokeViewRoute } from "modules/vacancy/components/pokeView/client/ClientPokeViewContainer";
import {
	StyledTabList,
	StyledTab,
	StyledTabPanels
} from "modules/vacancy/components/pokeView/client/Tabs/Styled";
import BackButton from "common/Buttons/BackButton";
import CandidateActionsDrawer from "modules/vacancy/components/vacancyView/CandidateAction/DrawerComponent";
import { searchParser, goBack } from "config/helpers";
import useListPagination from "hooks/useListPagination.js";
import useInterviewStore from "modules/home/containers/interview/interviewStore.js";
import usePermanentStore from "modules/home/zustand/PermanentStore.js";
import { WELCOME_PAGE } from "config";

const viewProfile = (_key, id) => {
	return client(VIEW_CANDIDATE_ENDPOINT, {
		body: {
			id
		}
	});
};

function ClientPokeView({
	params,
	route,
	fetchPoke,
	poke,
	toggleNotesModal,
	notes,
	toggleProposeInterviewModal,
	user,
	interviewDispatch,
	onCallEnd,
	onCallStart,
	showVideoConf,
	acceptInterview,
	changeReduxForm,
	attendeesList,
	fetchAttendees
}) {
	const stepperStatus = _get(poke, "stepper_status");
	const usersList = _get(poke, "list_users", []);
	const firstName = _get(poke, "profile.headline.first_name", "");
	const lastName = _get(poke, "profile.headline.last_name", "");

	const idPrevPoke = _get(poke, "pagination.preview");
	const idNextPoke = _get(poke, "pagination.next");

	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Permanent" },
			{ name: "Applications", path: "/applications" },
			{
				name: _get(poke, "job.title", ""),
				path: `/vacancy/${_get(poke, "job._id")}`
			},
			{ name: `${firstName} ${lastName} ` ?? "loading..." }
		]);
	}, [firstName, lastName, poke]);

	const [
		displayInterviewDetailsModal,
		setDisplayInterviewDetailsModal
	] = useState(false);
	const [
		displayAcceptInterviewConfirmation,
		setDisplayAcceptInterviewConfirmation
	] = useState(false);

	const [selectedInterview, setSelectedInterview] = useState({});
	const hasInterview = _has(poke, "interview._id");
	const interviewStatus = _get(poke, "interview.status");
	const interviewLocation = _get(poke, "interview.location_type");
	const hasMultipleInterviews = _get(poke, "interviews", []).length > 0;
	const shouldEditInterview =
		_get(poke, "confirmed") === false &&
		(_get(poke, "interview.status") === SENT ||
			_get(poke, "interview.status") === ACCEPTED ||
			_get(poke, "interview.status") === EXPIRED) &&
		(_get(poke, "status") === SENT || _get(poke, "status") === DRAFT);

	//getting utl param
	const searchParams = window.location.search;
	const urlParams = new URLSearchParams(searchParams).get("tab");
	const [tabIndex, setTabIndex] = useState(parseInt(urlParams) || 0);

	const searchQueries = searchParser();

	const welcomePageTemporaryStore = usePermanentStore();
	const temporaryInterviews = useInterviewStore();
	const listNavigationData =
		searchQueries.listKey === PENDING_APPLICATIONS
			? _get(welcomePageTemporaryStore, searchQueries.listKey, [])
			: _get(temporaryInterviews, searchQueries.listKey, []);

	const { state, nextId, previousId } = useListPagination({
		data: listNavigationData,
		id: params.id
	});

	useEffect(() => {
		browserHistory.replace(
			`/vacancies/client/poke/${state.id}${window.location.search}`
		);
	}, [state.id]);

	const handlePrevious = () => {
		searchQueries.source === WELCOME_PAGE
			? previousId()
			: handlePreviousNextClick(idPrevPoke);
	};
	const handleNext = () => {
		searchQueries.source === WELCOME_PAGE
			? nextId()
			: handlePreviousNextClick(idNextPoke);
	};
	const previousDisabled =
		searchQueries.source === WELCOME_PAGE
			? state.previousDisabled
			: !idPrevPoke;
	const nextDisabled =
		searchQueries.source === WELCOME_PAGE ? state.nextDisabled : !idNextPoke;

	const handleTabsChange = index => {
		window.history.replaceState({}, "", `#${index}`);
		setTabIndex(index);
	};

	const { data: profileData, isLoading } = useQuery(
		["view-profile", _get(poke, "profile._id", "")],
		viewProfile,
		{
			refetchOnWindowFocus: false,
			enabled: _get(poke, "profile._id", "")
		}
	);
	const skills = _get(profileData, "skills", []);
	const functions = _get(profileData, "functions", []);
	const languages = _get(profileData, "languages", []);

	const isEmptyPreferences = isEmpty(
		_get(profileData, "front_sheet.preference.preferred_location", [])
	);
	const isEmptyLangSkillsFnc =
		isEmpty(languages) && isEmpty(skills) && isEmpty(functions);

	const handlePreviousNextClick = pokeId => {
		browserHistory.replace(`${getClientPokeViewRoute(pokeId)}${searchParams}`);
	};
	const handleBack = () => {
		_get(searchQueries, "history", false)
			? goBack()
			: browserHistory.push(`/vacancy/${poke.job._id}`);
	};
	const getInterviewStatusTooltip = (status, islastUserSenderClient) => {
		switch (status) {
			case SENT:
			case DECLINED:
				switch (islastUserSenderClient) {
					case true:
						return "Expecting applicant’s feedback.";
					case false:
						return "Please reply to the applicant's alternate interview proposition.";
					default:
						return "";
				}
			case REFUSED:
			case CANCELLED:
				switch (islastUserSenderClient) {
					case true:
						return "";
					case false:
						return "";
					default:
						return "";
				}

			case EXPIRED:
				return "The interview proposition is overdue, please propose another date.";
			case ACCEPTED:
				return "The interview date, time and place are confirmed.";
			case DONE:
				return "As a next step, you can either, propose a contract, organise another interview, or reject the freelancer's application";
			default:
				return "";
		}
	};

	const acceptInterviewModalAction = () => {
		acceptInterview(
			{
				poke_id: poke._id,
				interview_id: _get(poke, "interview._id"),
				token_invitation_id: _get(poke, "interview.token_invitation_id")
			},
			() => setDisplayAcceptInterviewConfirmation(false)
		);
	};

	const renderApplicationDetails = () => {
		return (
			<>
				{Object.keys(_get(poke, "offer")).length > 0 && (
					<OfferDetails offer={_get(poke, "offer")} />
				)}
				{hasInterview &&
					interviewStatus !== DONE &&
					interviewStatus !== CANCELLED &&
					interviewLocation !== "phone_screen" && (
						<Box>
							<InterviewDetails
								data={_get(poke, "interview", {})}
								user={user}
								userTarget={_get(poke, "profile.headline", {})}
								stepperStatus={stepperStatus}
								visible_status={_get(poke, "interview.visible_status", "")}
								status={_get(poke, "interview.visible_status", "")}
								getInterviewStatusTooltip={getInterviewStatusTooltip}
								showVideoConf={showVideoConf}
								onCallStart={onCallStart}
								onCallEnd={onCallEnd}
								hasInterview={hasInterview}
							/>
						</Box>
					)}
				{poke.client_notes && poke.client_notes.length > 0 && (
					<Box
						mb={10}
						sx={{
							borderRadius: "4px",
							boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
							backgroundColor: "rgb(255, 255, 255)"
						}}
						p={20}
					>
						<NotesDetails
							flag="Notes"
							notes={poke.client_notes}
							toggleNotesModal={toggleNotesModal}
						/>
					</Box>
				)}
				{hasMultipleInterviews && (
					<Box
						mb={10}
						sx={{
							borderRadius: "4px",
							boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
							backgroundColor: "rgb(255, 255, 255)"
						}}
						p={20}
					>
						<InterviewsHistory
							flag={"Interview"}
							visible_status={poke.interview.visible_status}
							status={poke.interview.status}
							displayInterviewDetailsModal={() =>
								setDisplayInterviewDetailsModal(true)
							}
							selectInterview={setSelectedInterview}
							interviews={_get(poke, "interviews")}
						/>
					</Box>
				)}
				{hasInterview && interviewLocation === "phone_screen" && (
					<PhoneScreen poke={poke} />
				)}
				<Box
					mb={10}
					sx={{
						borderRadius: "4px",
						boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
						backgroundColor: "rgb(255, 255, 255)"
					}}
					p={20}
				>
					<PokeDetails
						visible_status={poke.visible_status}
						status={poke.status}
						created_at={poke.created_at}
						flag="Candidate status"
						stepperStatus={stepperStatus}
						confirmed={poke.confirmed}
						confirmed_at={poke.confirmed_at}
						status_changed_on={poke.status_changed_on}
						clientFullName={`${poke.client.first_name} ${poke.client.last_name}`}
						cancelData={_get(poke, "cancel_data")}
						additional_comment={_get(poke, "additional_comment", {})}
						is_applied={_get(poke, "is_applied")}
						source_profile={_get(poke, "source_profile", "")}
						applied_at={_get(poke, "applied_at")}
						rejected_at={_get(poke, "cancel_data.cancelled_at")}
						reasonOfRejectDetail={_get(poke, "cancel_comments")}
						reasonOfReject={_get(poke, "rejection_reason")}
					/>
				</Box>
			</>
		);
	};

	const renderPokeSideBar = () => {
		return (
			<Box
				p={20}
				mb={10}
				sx={{
					borderRadius: "4px",
					boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
					backgroundColor: "rgb(255, 255, 255)"
				}}
			>
				<PokeSideBar
					offer={poke.offer}
					poke_id={poke._id}
					user={user}
					status={poke.status}
					stepperStatus={stepperStatus}
					toggleProposeInterviewModal={toggleProposeInterviewModal}
					interview={poke.interview}
					acceptInterview={() => setDisplayAcceptInterviewConfirmation(true)}
					changeReduxForm={changeReduxForm}
					attendeesList={attendeesList}
					fetchAttendees={fetchAttendees}
					jobDetails={poke.job}
					profile={poke.profile}
					fetchPoke={fetchPoke}
					fullName={`${firstName} ${lastName}`}
				/>
			</Box>
		);
	};

	return (
		<BodyClassName className="bid gray-bg poke">
			<div className="view-job-details">
				{route.title && (
					<Helmet>
						<title>{route.title}</title>
					</Helmet>
				)}
				{poke && poke.job && (
					<Box mt={20} className="full-container">
						<Flex
							pr={15}
							pl={15}
							mb={10}
							justifyContent="space-between"
							alignItems="center"
						>
							<BackButton onClick={handleBack} />
							<Flex>
								<Text
									onClick={handlePrevious}
									as={"button"}
									disabled={previousDisabled}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "11px",
										fontWeight: "600",
										letterSpacing: "0.99px",
										color: "rgb(97, 105, 118)",
										textTransform: "uppercase",
										border: "none",
										background: "none",
										outline: "none",
										"&[disabled]": {
											color: "rgb(160,165,185)",
											cursor: "not-allowed"
										}
									}}
									mr={"10px"}
								>
									&larr; Previous
								</Text>
								<Text
									onClick={handleNext}
									as={"button"}
									disabled={nextDisabled}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "11px",
										fontWeight: "600",
										letterSpacing: "0.99px",
										color: "rgb(97, 105, 118)",
										textTransform: "uppercase",
										border: "none",
										background: "none",
										outline: "none",
										"&[disabled]": {
											color: "rgb(160,165,185)",
											cursor: "not-allowed"
										}
									}}
								>
									next &rarr;
								</Text>
							</Flex>
						</Flex>
						<Box pr={15} pl={15}>
							<Tabs index={tabIndex} onChange={handleTabsChange}>
								<Box
									mb={12}
									sx={{
										borderRadius: "4px",
										boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
										backgroundColor: "rgb(255, 255, 255)",
										position: "relative"
									}}
								>
									<PokeHeadLine
										profile={poke.profile}
										fetchPoke={fetchPoke}
										pokeId={params.id}
										contractStatus={_get(poke, "status")}
										finalStatus={_get(poke, "main_status")}
									/>
									<Flex justifyContent="space-between" alignItems="center">
										<Flex justifyContent="flex-start">
											<StyledTabList>
												<StyledTab>Applications details</StyledTab>
												<StyledTab>Cv content</StyledTab>
												{!isEmptyPreferences ? (
													<StyledTab>Preferences</StyledTab>
												) : (
													<></>
												)}

												<StyledTab>History</StyledTab>
												{!isEmptyLangSkillsFnc ? (
													<StyledTab>Functions &amp; skills</StyledTab>
												) : (
													<></>
												)}
											</StyledTabList>
										</Flex>
									</Flex>
								</Box>
								<Flex>
									<Box mr={10} width={9 / 12}>
										<StyledTabPanels>
											<TabPanel>
												<Box
													mb={10}
													sx={{
														borderRadius: "4px",
														boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)"
													}}
												>
													{renderApplicationDetails()}
												</Box>
											</TabPanel>
											<TabPanel>
												<Box mb={10}>
													<ResumeTab
														cv_doc={_get(profileData, "cv_doc")}
														pdf_doc={_get(profileData, "pdf_doc")}
														loading={isLoading}
													/>
												</Box>
											</TabPanel>
											{!isEmptyPreferences ? (
												<TabPanel>
													<Box
														mb={10}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<PreferencesTab
															preference={_get(
																profileData,
																"front_sheet.preference"
															)}
														/>
													</Box>
												</TabPanel>
											) : (
												<></>
											)}

											<TabPanel>
												<Box
													mb={10}
													sx={{
														borderRadius: "4px",
														boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
														backgroundColor: "rgb(255, 255, 255)"
													}}
												>
													<History profileId={_get(profileData, "user_id")} />
												</Box>
											</TabPanel>
											{!isEmptyLangSkillsFnc ? (
												<TabPanel>
													<Box
														mb={10}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
														id="resume"
													>
														<Box id="can-do">
															<FunctionsTab
																functions={_get(profileData, "functions", [])}
																skills={_get(profileData, "skills", [])}
																languages={_get(profileData, "languages", [])}
															/>
														</Box>
													</Box>
												</TabPanel>
											) : (
												<></>
											)}
										</StyledTabPanels>
									</Box>
									<Box width={3 / 12}>{renderPokeSideBar()}</Box>
								</Flex>
							</Tabs>
						</Box>

						{notes.proposeInterview_modal.show && (
							<ClientInterviewModal
								interviewDispatch={interviewDispatch}
								toggleProposeInterviewModal={toggleProposeInterviewModal}
								interview={_get(poke, "interview", {})}
								job_title={poke.job.title}
								is_edit={shouldEditInterview && hasInterview}
								is_decline={
									!shouldEditInterview &&
									hasInterview &&
									_get(poke, "interview.status") !== CANCELLED &&
									_get(poke, "interview.status") !== DONE
								}
								bid_id={poke._id}
								user={user}
								profile={poke.profile}
								type={PERMANENT}
								listUsers={_get(poke, "list_users")}
								attendees_users={_get(poke, "interview.attendees_users")}
							/>
						)}
						{notes.notes_modal.show && (
							<PokeNoteModal
								active={notes.notes_modal.show}
								note={notes.notes_modal}
								onClose={toggleNotesModal}
								pokeId={poke._id}
								usersList={usersList}
							/>
						)}
						{displayInterviewDetailsModal && (
							<InterviewDetailsModal
								closeModal={() => setDisplayInterviewDetailsModal(false)}
								data={selectedInterview}
								userId={user.id}
							/>
						)}
						<AcceptInterviewModal
							acceptInterview={acceptInterviewModalAction}
							closeModal={() => setDisplayAcceptInterviewConfirmation(false)}
							displayModal={displayAcceptInterviewConfirmation}
						/>
					</Box>
				)}
				<CandidateActionsDrawer job={poke.job} poke={poke} view={APPLICATION} />
			</div>
		</BodyClassName>
	);
}
export default ClientPokeView;
