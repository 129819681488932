import React, { useEffect, useState } from "react";
import { useMenu } from "@react-aria/menu";
import { useTreeState } from "@react-stately/tree";
import MenuSection from "./MenuSection";

const Menu = props => {
	let ref = React.useRef();
	let state = useTreeState({
		...props,
		selectionMode: "none"
	});
	let [sectionsState, setSectionsState] = useState({
		activeSection: props.activeSection
	});
	let [sectionsCount, setSectionsCount] = useState({});

	let { menuProps } = useMenu(
		{ ...props, shouldFocusWrap: "false" },
		state,
		ref
	);

	useEffect(() => {
		let sectionsStateObj = {};
		let sectionsCountObj = {};
		props.sections.forEach(section => {
			sectionsStateObj[section.type] = false;
			sectionsCountObj[section.type] = section.count;
		});
		setSectionsState(prev => ({ ...prev, ...sectionsStateObj }));
		setSectionsCount(sectionsCountObj);
	}, [props.sections]);

	return (
		<ul {...menuProps} ref={ref}>
			{[...state.collection].map(item => (
				<MenuSection
					key={item.key}
					section={item}
					isLoading={props.isLoading}
					state={state}
					onAction={props.onAction}
					sectionsState={sectionsState}
					sectionsCount={sectionsCount}
					setSectionsState={sectionsState =>
						setSectionsState(prev => ({ ...prev, ...sectionsState }))
					}
					isSearching={props.isSearching}
					selectedKey={props.selectedKey}
					setActiveSection={props.setActiveSection}
				/>
			))}
		</ul>
	);
};

export default Menu;
