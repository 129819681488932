import React, { useEffect, useState } from "react";
import VariableMargins from "modules/company/components/VariableMargins";
import SuppliersPerformance from "modules/SuppliersManagement/components/SuppliersPerformance";
import Tab from "./tabs/tab";
import Tabs from "./tabs/tabs";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { Box } from "rebass";
import { withRouter } from "react-router";
import Spend from "./components/spend";
import Invitations from "../company/components/VariableMargins/components/Invitations";
import PendingUsers from "modules/SuppliersManagement/components/pendingUsers";

export const getSuppliersManagement = () => {
	return `/suppliers-management`;
};

export const getSuppliersManagementPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const listTabsId = [
	"#tiering",
	"#suppliers-performance",
	"#spent",
	"#history",
	"#pending_users"
];

const SuppliersManagement = ({ location, router }) => {
	const { hash } = location;
	const [activeTab, setActiveTab] = useState(
		hash ? hash.split("?").shift() : "#tiering"
	);

	const handleTabsChange = tabId => {
		router.replace({ ...location, hash: tabId });
		setActiveTab(tabId);
	};

	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([{ name: "Suppliers" }, { name: "Suppliers Management" }]);
		if (listTabsId.includes(hash)) {
			setActiveTab(hash);
		}
	}, []);
	return (
		<Box p={20}>
			<Tabs setActiveTab={handleTabsChange} activeTab={activeTab}>
				<Tab id="#tiering" title="Tiering">
					<VariableMargins />
				</Tab>
				<Tab id="#suppliers-performance" title="Suppliers performance">
					<SuppliersPerformance />
				</Tab>
				<Tab id="#spent" title="Spent">
					<Spend />
				</Tab>
				<Tab id="#history" title="Invitations history">
					<Invitations />
				</Tab>
				<Tab id="#pending_users" title="Suppliers creation">
					<PendingUsers />
				</Tab>
			</Tabs>
		</Box>
	);
};

export default withRouter(SuppliersManagement);
