import React from "react";
import styles from "./unpublish-warning-modal.module.scss";
import ConfirmationModal, { Header, Footer } from "common/ConfirmationModal";
import { Button } from "common/Button";
import { Controller, useForm } from "react-hook-form";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useGetClosingReasons from "../hooks/useGetClosingReasons";
import Checkbox from "common/AgTable/components/Checkbox/Checkbox";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import { VACANCY_CLOSING_PATH } from "modules/CustomFieldsManager/utils/constants";
import { PUBLISHED } from "config";
import { getCustomFieldsManagerRoute } from "modules/CustomFieldsManager/getRouteConfig";
import { browserHistory } from "react-router";

export default function UnpublishWarningModal({ onClose, onContinue, status }) {
	const { data: closingReasonsList } = useGetClosingReasons();
	const [container, setContainer] = React.useState(null);

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(
			yup.object().shape({
				closingReason: yup
					.object()
					.test("Required", "Please select a reason", function(value) {
						return !!value?.value;
					}),
				allowSubmission:
					status === PUBLISHED &&
					yup
						.boolean()
						.oneOf(
							[true],
							"Please check the box to confirm the closing to application of the vacancy"
						)
						.required(
							"Please check the box to confirm the closing to application of the vacancy"
						)
			})
		),
		shouldUnregister: false
	});

	const onClosingReasonInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("closingReason")?.selectedKey
		};

		onChange(valueObject);
	};
	const onClosingReasonSelectionChange = (key, onChange) => {
		const value = {
			label: getClosingReasonById(key)?.value ?? "",
			value: key
		};

		onChange(value);
	};
	const getClosingReasonById = id => {
		return closingReasonsList?.options.find(({ _id }) => _id === id);
	};

	const onSubmit = values => {
		onContinue(values.closingReason.label);
	};

	return (
		<ConfirmationModal onClose={onClose} width={"580px"}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Header title={"Close to applications!"} />

				<div
					className={styles.descriptionContainer}
					ref={ref => setContainer(ref)}
				>
					<div className={styles.reasonContainer}>
						<div className={styles.reasonLabel}>
							Reason for closing position
							<span className={styles.required}>*</span>
						</div>
						<Controller
							name="closingReason"
							control={control}
							render={({ field: { value, onChange } }) => {
								return (
									<>
										<ComboBox
											popoverContainer={container}
											inputValue={value?.label}
											selectedKey={value?.value}
											onInputChange={value =>
												onClosingReasonInputChange(value, onChange)
											}
											onSelectionChange={key =>
												onClosingReasonSelectionChange(key, onChange)
											}
											button={
												<Button
													text="Add new closing reason"
													variant="text"
													textClassName={styles.addNewClosingReasonButtonText}
													className={styles.addClosingReasonButton}
													icon={<AddIcon />}
													onClick={() => {
														browserHistory.replace(
															getCustomFieldsManagerRoute(VACANCY_CLOSING_PATH)
														);
													}}
												/>
											}
										>
											{closingReasonsList?.options?.map(({ _id, value }) => {
												return <Item key={_id}>{value}</Item>;
											})}
										</ComboBox>
										{errors.closingReason && (
											<div className={styles.error}>
												{errors.closingReason.message}
											</div>
										)}
									</>
								);
							}}
						/>
					</div>
					{status === PUBLISHED && (
						<div className={styles.container}>
							<Controller
								name="allowSubmission"
								control={control}
								render={({ field: { onChange, value } }) => (
									<>
										<div className={styles.allowSubmissionContainer}>
											<Checkbox
												onChange={() => onChange(!value)}
												checked={value}
												id="allowSubmission"
												className={styles.allowSubmissionCheckbox}
											/>
											<div className={styles.warningDescription}>
												This status switch will unpublish the vacancy. The
												potential costs related to the initial publication (job
												Boards, sponsored ads, etc) will not be refunded.
											</div>
										</div>
										{errors.allowSubmission && (
											<div className={styles.error}>
												{errors.allowSubmission.message}
											</div>
										)}
									</>
								)}
							/>
						</div>
					)}
				</div>
				<Footer>
					<Button
						type="button"
						text="Cancel"
						onClick={onClose}
						variant="outlined"
						rootClassName={styles.cancel}
					/>
					<Button
						type="submit"
						text={"Continue"}
						className={styles.deleteButton}
					/>
				</Footer>
			</form>
		</ConfirmationModal>
	);
}
