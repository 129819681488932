/* eslint-disable */
import React from "react";
import { searchParser } from "config/helpers";
import { VideoConference } from "common/VideoConference";

export const getRoute = () => {
	return `/consultant/virtual-room`;
};

export const getPermissions = () => {
	return {
		type: [
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
};

const WhereByConsultantVirtualRoom = () => {
	const { roomUrl, displayName } = searchParser();

	return <VideoConference roomUrl={roomUrl} displayName={displayName} />;
};

export default WhereByConsultantVirtualRoom;
