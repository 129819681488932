import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import style from "../../my-company.module.scss";

const InvoicingDetailsEmptyState = () => {
	return (
		<>
			<div className={style.headerBlock}>
				<h3>Invoicing details</h3>
			</div>
			<div className={style.card}>
				<div className={style.field}>
					<div className={style.label}>Company name</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>Legal form</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>Contact person</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>Invoicing email</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
			</div>
		</>
	);
};

export default InvoicingDetailsEmptyState;
