/* eslint-disable */
import React from "react";
import "./whereby-embed.module.scss";

const WherebyEmbed = ({
	roomUrl = { roomUrl },
	displayName = { displayName }
}) => {
	return (
		<whereby-embed
			minimal="on"
			room={roomUrl}
			displayName={displayName}
			background="off"
			leaveButton="on"
			chat="on"
			floatSelf="on"
			screenshare="on"
		></whereby-embed>
	);
};

export default WherebyEmbed;
