import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import cx from "classnames";
import style from "../../my-company.module.scss";

const GeneralInformationsEmptyState = () => {
	return (
		<>
			<div className={style.headerBlock}>
				<h3>General informations</h3>
			</div>
			<div className={style.card}>
				<div className={cx(style.field, style.avatar)}>
					<div className={style.label}>Company logo</div>
					<div className={cx(style.infoLabel, style.flex)}>
						<SkeletonTheme style={{ borderRadius: "83px" }} color="#E5E5E5">
							<Skeleton width={86} height={86} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>Commercial name</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>VAT number</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>Headquarters address</div>
					<div className={cx(style.infoLabel)}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>Company website</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>Industries</div>
					<div className={style.infoLabel}>
						<div className={style.chipsContainer}>
							<span className={style.industriesChip}>
								<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
									<Skeleton width={137} height={16} />
								</SkeletonTheme>
							</span>
							<span className={style.industriesChip}>
								<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
									<Skeleton width={54} height={16} />
								</SkeletonTheme>
							</span>
						</div>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>General phone number</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={style.label}>Number of employees</div>
					<div className={style.infoLabel}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
			</div>
		</>
	);
};

export default GeneralInformationsEmptyState;
