import React, { useEffect } from "react";
import loadable from "loadable-components";
import styles from "./mission-list.module.scss";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

import useGetMissionsListTableSettings from "./api/useGetMissionsListTableSettings";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import {
	API_DATA_TYPE,
	MISSIONS_LIST_FILTER_ID,
	MISSIONS_LIST_PAGE_SIZES
} from "./components/_MissionsListUtils/missionsListUtils";
import useGetMissionsList from "./hooks/useGetMissionsList";
import { useInitializeFilterDrawer } from "./hooks/useInitializeFilterDrawer";
import MissionListTotalCount from "./components/MissionsListTotalCount/MissionListTotalCount";
import PageSizeSelect from "common/PageSizeSelect/PageSizeSelect";

import _get from "lodash/get";
import MissionsListHeader from "./components/MissionsListHeader/MissionsListHeader";
import { validFiltersCount } from "common/FilterDrawer/render_utils";
import MissionsListFiltresRecap from "./components/MissionsListFiltresRecap/MissionsListFiltresRecap";
import { isMissionsListFiltersFull } from "./components/_MissionsListUtils/missionsListHelper";
import { useDisplayRecap } from "./hooks/useMissionsLIstDisplayRecap";
import MissionsListPrimaryHeader from "./components/MissionsListPrimaryHeader/MissionsListPrimaryHeader";
import MissionListTable from "./components/MissionsListTable/MissionsListTable";
import { useIsCreateMissionEnabled } from "hooks/useIsCreateMissionEnabled";

const FilterDrawer = loadable(() => import("common/FilterDrawer/FilterDrawer"));

const MissionsListTabPagination = loadable(() =>
	import("./components/MissionsListTabPagination/MissionsListTabPagination")
);

const MissionsList = () => {
	const { data: tableSettings } = useGetMissionsListTableSettings();
	const isCreateMissionEnabled = useIsCreateMissionEnabled();
	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([{ name: "Temporary" }, { name: "Missions" }]);
	}, []);
	useInitializeFilterDrawer();
	const { refetch } = useGetMissionsList();
	const { getState, setLimit, setOffset, setShowDrawer } = useFilters();
	const {
		isFetching,
		data,
		isLoading,
		limit,
		filters,
		showDrawer,
		selectedCriteria
	} = getState(MISSIONS_LIST_FILTER_ID);

	useEffect(() => {
		setLimit(_get(tableSettings, "table_settings")?.size);
	}, [tableSettings]);

	const onPageClick = async page => {
		await setOffset(page * data?.pagination?.size);
	};

	const {
		displayRecap: showFiltersRecap,
		toggleDisplayRecap
	} = useDisplayRecap();

	return tableSettings ? (
		<div className={`${styles.container} ${styles.root}`}>
			<div className={styles.vacanciesContent}>
				<MissionsListPrimaryHeader
					isCreateMissionEnabled={isCreateMissionEnabled}
				/>
				<MissionsListHeader
					columns={tableSettings?.ordered_columns}
					filters={filters}
					onFilterClick={() => setShowDrawer(true)}
					onRecapClick={toggleDisplayRecap}
				/>

				{showFiltersRecap && isMissionsListFiltersFull(filters) && (
					<MissionsListFiltresRecap
						module_id={MISSIONS_LIST_FILTER_ID}
						filters={filters}
						selectedCriteria={selectedCriteria}
					/>
				)}

				<MissionListTotalCount
					isLoading={isLoading}
					isFetching={isFetching}
					total={data?.pagination?.total}
				/>

				<MissionListTable
					missionsList={data?.contracts}
					columns={tableSettings?.columns}
					isLoading={isLoading}
					isFetching={isFetching}
				/>

				<div className={styles.containerFooter}>
					<PageSizeSelect
						limit={limit}
						setLimit={setLimit}
						refetch={refetch}
						rowsPages={MISSIONS_LIST_PAGE_SIZES}
						tableTag={API_DATA_TYPE}
					/>

					{data?.pagination?.total > data?.pagination?.size && (
						<MissionsListTabPagination
							total={data?.pagination?.total}
							size={data?.pagination?.size}
							offset={data?.pagination?.offset}
							onPageClick={onPageClick}
						/>
					)}
					{showDrawer && (
						<FilterDrawer
							onClose={() => setShowDrawer(false)}
							displayDrawer={showDrawer}
							hideTabSaved
							onlyList
							displayAdvancedSearchHead={false}
							title={
								validFiltersCount(filters) === 0
									? "Filter"
									: `Filter (${validFiltersCount(filters)})`
							}
						/>
					)}
				</div>
			</div>
		</div>
	) : null;
};

export const getClientMissionsListRoute = () => {
	return "/missions";
};
export const getClientMissionsListPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default MissionsList;
