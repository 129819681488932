const storeUtils = (get, set, initialState) => ({
	updateSearch: search => {
		const { filters } = get();
		set({ filters: { ...filters, search } });
	},
	updateFilters: (key, value) => {
		const { filters } = get();
		set({ filters: { ...filters, [key]: value } });
	},
	updateOffset: offset => {
		const { filters } = get();
		set({ filters: { ...filters, offset } });
	},
	setGlobalOperator: op => {
		const { filters } = get();
		const { fields } = filters;
		set({ filters: { op, fields } });
	},
	setSortByField: (field, order) => {
		const { filters } = get();
		set({ filters: { ...filters, field, sort: order } });
	},
	setFields: field => {
		const { filters } = get();
		const { fields } = filters;
		set({
			filters: {
				...filters,
				fields: [...fields, field]
			}
		});
	},
	updateFields: (field, index) => {
		const { filters } = get();
		const { fields } = filters;
		set({
			filters: {
				...filters,
				fields: [...fields.slice(0, index), field, ...fields.slice(index + 1)]
			}
		});
	},
	deleteField: index => {
		const { filters } = get();
		const { fields } = filters;

		set({
			filters: {
				...filters,
				fields: [...fields.slice(0, index), ...fields.slice(index + 1)]
			}
		});
	},
	saveRows: rows => {
		const state = get();
		set({
			...state,
			rows
		});
	},
	clearFilters: () => {
		const { filters } = get();
		set({
			filters: {
				...filters,
				fields: [],
				rows: []
			}
		});
	},
	clearAll: () => {
		set({
			filters: initialState,
			rows: []
		});
	}
});

export default storeUtils;
