import React from "react";
import PropTypes from "prop-types";
import styles from "./star.module.scss";
import { ReactComponent as GreenSquare } from "static/icons/square-icon-green.svg";
import { ReactComponent as EmptySquare } from "static/icons/square-icon-empty.svg";

const Star = ({ isActive }) => {
	return (
		<div className={styles.reactRaterStar}>
			{isActive ? <GreenSquare /> : <EmptySquare />}
		</div>
	);
};

Star.defaultProps = {
	isActive: false
};

Star.propTypes = {
	isActive: PropTypes.bool
};

export default Star;
