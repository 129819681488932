import React, { Component } from "react";
import { browserHistory } from "react-router";
import _get from "lodash/get";
import Steps from "common/steps";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FilesStep from "./FilesStep";
import {
	CREATE_BID,
	CREATE_BID_STEPS,
	CREATE_BID_STEPS_FILES,
	STEP_2,
	STEP_3,
	CONSULTANCY,
	ADMIN
} from "config";
import Success from "./Success";
import { getFreelancerBidsListRoute } from "../freelancerBidsList/FreelancerBidsListContainer";
import Modal from "common/modal";
import { utcTimeStamp } from "config/helpers";
import omit from "lodash/omit";

class CreateBidPopup extends Component {
	state = {
		step: 0,
		bid: null
	};

	createBid = () => {
		const { bid } = this.state;
		const { user, job_id, proposeBid, files } = this.props;
		const filesData = files ? files : [];
		const bidDate = utcTimeStamp({ date: bid.available_date });
		const payload = {
			proposed_amount: bid.proposed_amount,
			description: bid.description,
			available_date: bidDate,
			user_id: user.id,
			files: filesData
				.map(f => omit(f, "account_types", "is_required", "created_at"))
				.filter(f => f.path),
			job_id
		};
		if (user.company_type === CONSULTANCY) {
			payload.consultant_id = bid.consultant_id;
			payload.consultancy_group_id = bid.consultancy_group_id;
		}
		return proposeBid(payload).then(() => {
			this.setState({ step: files ? 3 : 2 });
		});
	};

	goToSecondStep = formProps => {
		const {
			user: { groups, active_role, company_type },
			requiredFiles
		} = this.props;
		let payload = {
			business_unit: formProps.business_unit,
			consultant: formProps.consultant,
			available_date: formProps.available_date,
			proposed_amount: formProps.proposed_amount,
			description: formProps.description
		};
		if (company_type === CONSULTANCY) {
			const isConsultancyDirector = active_role === ADMIN;
			const consultancyGroupID = !isConsultancyDirector
				? _get(groups, "0.id")
				: formProps.business_unit.value;
			payload.consultant_id = formProps.consultant.value;
			payload.consultancy_group_id = consultancyGroupID;
		}

		requiredFiles
			? this.goToFileStep(payload)
			: this.setState({ step: 1, bid: payload });
	};

	goBackToFirstStep = () => {
		this.setState({ step: 0 });
	};

	goToList = () => {
		const { user } = this.props;
		const isConsultancy = user.company_type === CONSULTANCY;
		this.props.closeProposal();
		// TODO: use getConsultancyBidsListRoute after merge of #1478
		browserHistory.push(
			isConsultancy ? "/jobs/search" : getFreelancerBidsListRoute()
		);
	};

	goToFileStep = payload => {
		this.setState({ step: 1, bid: payload });
		//this.goToLastStep(payload);
	};

	goToLastStep = () => {
		this.setState({ step: 2 });
	};

	render() {
		const { step, bid } = this.state;
		const {
			isFetching,
			closeProposal,
			initializeReduxForm,
			businessUnits,
			consultants,
			getConsultants,
			setConsultants,
			files,
			setFiles,
			user: { company_type, active_role, groups },
			reportingSetting
		} = this.props;

		const previewFiles = files
			? files
					.map(f => omit(f, "account_types", "is_required", "created_at"))
					.filter(f => f.path)
			: [];
		const isConsultancy = company_type === CONSULTANCY;
		const isConsultancyDirector = isConsultancy && active_role === ADMIN;
		const businessManagerUnit = !isConsultancyDirector
			? groups["0"]
			: undefined;
		return (
			<Modal
				loading={isFetching}
				onClose={
					step === files ? STEP_3 : STEP_2 ? this.goToList : closeProposal
				}
			>
				{step === (files ? 3 : 2) ? (
					<Success onClose={this.goToList} />
				) : (
					<div className="propose-modal-bid">
						<div className="title">
							{isConsultancy ? "Make a Bid" : "Submit Your Bid"}
						</div>
						<Steps
							steps={files ? CREATE_BID_STEPS_FILES : CREATE_BID_STEPS}
							activeStep={step}
						/>
						{step === 0 && (
							<>
								<FirstStep
									bid={bid}
									goToSecondStep={this.goToSecondStep}
									closeProposal={closeProposal}
									initializeReduxForm={initializeReduxForm}
									isConsultancy={isConsultancy}
									isConsultancyDirector={isConsultancyDirector}
									businessManagerUnit={businessManagerUnit}
									businessUnits={businessUnits}
									consultants={consultants}
									getConsultants={getConsultants}
									setConsultants={setConsultants}
									reportingSetting={reportingSetting}
								/>
							</>
						)}
						{step === 1 && files && (
							<FilesStep
								goToLastStep={this.goToLastStep}
								closeProposal={closeProposal}
								files={files}
								setFiles={setFiles}
							/>
						)}
						{step === (files ? 2 : 1) && (
							<SecondStep
								bid={bid}
								data={isConsultancy ? CREATE_BID[2] : CREATE_BID[1]}
								goBackToFirstStep={this.goBackToFirstStep}
								createBid={this.createBid}
								isConsultancy={isConsultancy}
								files={previewFiles}
								reportingSetting={reportingSetting}
							/>
						)}
					</div>
				)}
			</Modal>
		);
	}
}

export default CreateBidPopup;
