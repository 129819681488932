import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { RESEND_SUPPLIET_INVITATION } from "config/api-endpoints";

function resedInvitation(body) {
	return client(RESEND_SUPPLIET_INVITATION, {
		body
	});
}

function useResendInvitation(options) {
	return useMutation(resedInvitation, options);
}

export default useResendInvitation;
