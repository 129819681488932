import React, { memo } from "react";

import VacancyProfile from "./VacancyProfile";
import RequestProfile from "./RequestProfile";
import { VACANCY } from "config";
import { ListProvider } from "common/editablelist/Next/listContext";

function JobProfile({ job_type, ...props }) {
	const ProfileComponent =
		job_type === VACANCY ? VacancyProfile : RequestProfile;
	return (
		<ListProvider>
			<ProfileComponent {...props} />
		</ListProvider>
	);
}

export default memo(JobProfile);
