import { useEffect } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import {
	MAP_TYPE_COMPONENT,
	MAP_TYPE_OPERATORS,
	MISSIONS_LIST_FILTER_ID,
	MISSIONS_LIST_MODULE_ID,
	MISSIONS_LIST_PAGE_SIZES,
	TYPES
} from "../components/_MissionsListUtils/missionsListUtils";
import { COLUMNS } from "../components/_MissionsListUtils/missionsListFilterFields";
import { flatMapDeep } from "lodash";
import { LIVE } from "config";

export const useInitializeFilterDrawer = () => {
	const {
		setFilterSetting,
		getState,
		handleFilterAdd,
		clearFilters
	} = useFilters();
	const { columns, filters } = getState(MISSIONS_LIST_FILTER_ID);

	useEffect(() => {
		setFilterSetting(
			TYPES,
			MAP_TYPE_OPERATORS,
			MAP_TYPE_COMPONENT,
			{},
			null,
			COLUMNS,
			MISSIONS_LIST_FILTER_ID,
			MISSIONS_LIST_MODULE_ID,
			true,
			{},
			MISSIONS_LIST_PAGE_SIZES
		);
	}, []);

	const configStatus = {
		live: "Live",
		finished: "Finished",
		interrupted: "Interrupted"
	};

	useEffect(() => {
		const new_columns = flatMapDeep(columns, el => {
			return el.children;
		});
		const filter_data = new_columns.find(el => el.name === "status");
		if (filters.length === 1 && filters[0]?.name === "") {
			clearFilters();
			handleFilterAdd({
				...filter_data,
				operator: { value: "is_any", label: "is any of" },
				value: [
					{
						id: 1,
						label: "status",
						children: [
							{
								label: configStatus[LIVE],
								id: LIVE,
								rating: 1
							}
						]
					}
				]
			});
		}
	}, [columns]);
};
