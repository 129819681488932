//We use compound component pattern
import Drawer from "rc-drawer";
import React, { memo } from "react";
import styles from "./filter-drawer.module.scss";
import FilterDrawerBody from "./FilterDrawerBody/FilterDrawerBody";
import FilterDrawerFooter from "./FilterDrawerFooter/FilterDrawerFooter";
import FilterDrawerHeader from "./FilterDrawerHeader/FilterDrawerHeader";

const FilterDrawer = ({
	displayDrawer = false,
	onClose,
	hideTabSaved,
	placeHolder,
	onlyList,
	displayAdvancedSearchHead = true,
	title = "Filter"
}) => {
	return (
		displayDrawer && (
			<Drawer
				open={displayDrawer}
				width="810px"
				height="100%"
				placement="right"
				style={{ zIndex: 1000, backgroundColor: "transparent" }}
				level={"root"}
				maskClosable={true}
				onClose={onClose}
			>
				<div className={styles.container}>
					<div className={styles.root}>
						<FilterDrawerHeader onClose={onClose} title={title} />
						<FilterDrawerBody
							displayAdvancedSearchHead={displayAdvancedSearchHead}
							hideTabSaved={hideTabSaved}
							placeHolder={placeHolder}
							onlyList={onlyList}
						/>
						<FilterDrawerFooter onClose={onClose} />
					</div>
				</div>
			</Drawer>
		)
	);
};

export default memo(FilterDrawer);
