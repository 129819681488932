import styled, { css } from "styled-components";
import { colors } from "config/styles";

export const BoxWrapper = styled.div`
	padding: 25px;
	color: ${colors.panelColor};
	border: ${({ error }) =>
		error
			? `1px solid ${colors.errorBgcolor}`
			: `1px solid ${colors.borderColor}`};
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 31px;
`;

export const BoxItem = styled.div`
	margin-top: 10px;
	min-height: 50px !important;
	font-size: 14px;
`;

export const Title = styled.div`
	color: ${colors.panelColor};
	font-size: 15px;
	font-weight: 400;
	margin-bottom: 5px;
	display: block;
`;

export const Desc = styled.div`
	color: ${({ error }) => (error ? colors.refuse : colors.panelHeadColor)};
	min-height: 20px !important;
	text-transform: ${({ error }) => (error ? "none" : "uppercase")};
	font-weight: ${({ error }) => (error ? 400 : 500)};
`;

export const EditInput = styled.input`
	border: none;
	outline: none;
	border-bottom: 0.5px solid #b9bdc0;
	margin-bottom: 10px;
	padding: 6px 0;
	color: #3d4b60;
	width: 100%;
	&:focus {
		border-color: #58626b;
	}
	${props =>
		props.hasError &&
		css`
			border-color: #f76d75;
			::placeholder {
				color: #f76d75;
			}
		`};
`;
