import cx from "classnames";
import React, { forwardRef } from "react";
import Tooltip from "common/Tippy";
import { ReactComponent as ChevronDown } from "static/icons/chevron-down-16px.svg";
import styles from "./combo-box-input.module.scss";
import { useButton } from "react-aria";

const ComboBoxInput = (
	{
		value,
		placeHolder,
		className,
		onChange,
		filterValue,
		inputProps,
		buttonProps,
		buttonRef,
		inputClassName,
		buttonClassName,
		isFocused,
		disabledInput,
		isReadOnly
	},
	ref
) => {
	const _content = filterValue || value || placeHolder;
	const [inputRef, inputContainerRef, inputRef2] = ref;
	let { buttonProps: newButtonProps } = useButton(buttonProps, buttonRef);

	let addTooltip = false;

	if (inputRef.current) {
		addTooltip =
			inputRef.current.offsetWidth < inputRef.current.scrollWidth ||
			inputProps.value.length > 55;
	}

	return (
		<div
			ref={elt => {
				inputContainerRef.current = elt;
				buttonRef.current = elt;
			}}
			className={cx(styles.root, className, {
				[styles.content]: !!value,
				[styles.open]: isFocused
			})}
			{...newButtonProps}
			disabled={disabledInput}
		>
			<Tooltip
				addTooltip={addTooltip}
				theme="dark"
				content={
					<span className={styles.tooltipContent}>{inputProps.value}</span>
				}
			>
				<input
					ref={elt => {
						if (inputRef) {
							inputRef.current = elt;
						}

						if (inputRef2) {
							inputRef2.current = elt;
						}

						if (!Array.isArray(ref)) {
							ref.current = elt;
						}
					}}
					className={cx(styles.input, inputClassName)}
					value={_content}
					onChange={onChange}
					{...inputProps}
					autoComplete="none"
					readOnly={disabledInput || isReadOnly}
					placeholder={placeHolder}
				/>
			</Tooltip>

			<button
				type="button"
				disabled={disabledInput}
				className={cx(styles.button, buttonClassName)}
			>
				<ChevronDown />
			</button>
		</div>
	);
};

export default forwardRef(ComboBoxInput);
