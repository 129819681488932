import React from "react";
import { Text } from "rebass";
import { goBack } from "config/helpers";

export default function BackButton({
	onClick,
	style,
	label = "Back",
	icon,
	className
}) {
	return (
		<Text
			onClick={onClick || goBack}
			className={className}
			sx={{
				fontFamily: "BasierCircle",
				fontSize: "14px",
				fontWeight: "600",
				color: "rgb(161, 165, 170)",
				cursor: "pointer",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				gap: "4px",
				...style
			}}
		>
			{icon ? (
				icon
			) : (
				<Text
					mr={10}
					sx={{
						color: "rgb(36, 47, 67)",
						fontFamily: "BasierCircle",
						fontSize: "16px"
					}}
					as={"span"}
					className="arrow"
				>
					&larr;
				</Text>
			)}
			{label}
		</Text>
	);
}
