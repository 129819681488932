import React from "react";
import SkeletonComponent from "../Skeleton";

import cardStyle from "../../Pages/Profile/components/EditableSection/editable-section.module.scss";
import styles from "../../Pages/Profile/profile.module.scss";

const PersonalProfileLoading = () => {
	return (
		<>
			<div className={cardStyle.grid}>
				<div className={cardStyle.box}>
					<div className={cardStyle.label}>
						<SkeletonComponent width={60} height={14} borderRadius={10} />
					</div>
					<div className={cardStyle.info}>
						<SkeletonComponent width={247} height={36} borderRadius={10} />
					</div>
				</div>
				<div className={cardStyle.box}>
					<div className={cardStyle.label}>
						<SkeletonComponent width={60} height={14} borderRadius={10} />
					</div>
					<div className={cardStyle.info}>
						<SkeletonComponent width={247} height={36} borderRadius={10} />
					</div>
				</div>
			</div>
			<div className={cardStyle.grid}>
				<div className={cardStyle.box}>
					<div className={cardStyle.label}>
						<SkeletonComponent width={60} height={14} borderRadius={10} />
					</div>
					<div className={cardStyle.info}>
						<SkeletonComponent width={247} height={36} borderRadius={10} />
					</div>
				</div>
			</div>
			<div className={cardStyle.grid}>
				<div className={cardStyle.box}>
					<div className={cardStyle.label}>
						<SkeletonComponent width={60} height={14} borderRadius={10} />
					</div>
					<div className={cardStyle.info}>
						<SkeletonComponent width={247} height={36} borderRadius={10} />
					</div>
				</div>
			</div>
			<div className={cardStyle.grid}>
				<div className={cardStyle.box}>
					<div className={cardStyle.label}>
						<SkeletonComponent width={60} height={14} borderRadius={10} />
					</div>
					<div className={cardStyle.info}>
						<SkeletonComponent width={247} height={36} borderRadius={10} />
					</div>
				</div>
			</div>
			<div className={styles.cvContainer}>
				<SkeletonComponent width={60} height={14} borderRadius={10} />
				<div className={styles.flex}>
					<SkeletonComponent width={60} height={60} borderRadius={10} />
					<SkeletonComponent width={240} height={16} borderRadius={10} />
				</div>
			</div>
		</>
	);
};

export default PersonalProfileLoading;
