import React from "react";
import chartSvg from "static/images/chart.svg";
import { FailedData } from "../styled";

function ChartError({ onReload }) {
	return (
		<>
			<img src={chartSvg} width="560" height="360px" />
			<FailedData>
				<div className="Box">
					<span className="icon-warning icone"></span>
					<h2>Failed to load data</h2>
					<h3>Failed to load data, please try again.</h3>
					<button onClick={onReload}>
						<span className="icon-retakepic"></span>
					</button>
				</div>
			</FailedData>
		</>
	);
}

export default ChartError;
