import React, { useState, useEffect } from "react";
import * as copyToClipboard from "copy-to-clipboard";
import styled from "styled-components";
import Tooltip from "rc-tooltip";
import { ReactComponent as CopyIcon } from "static/icons/file-copy-alt.svg";
import { ReactComponent as CheckIcon } from "static/icons/check-icon-dark.svg";
const Wrapper = styled.span`
	white-space: initial;
	.with-copy {
		display: flex;
		align-items: center;
		cursor: pointer;
		visibility: ${props =>
			props.showCopyButton
				? props.showCopyButton === "always"
					? "visible"
					: "hidden"
				: "visible"};
	}
	&:hover .with-copy {
		visibility: visible;
	}
`;

const CopyValue = ({ children, ...props }) => {
	const [copied, setCopy] = useState(false);

	useEffect(() => {
		setTimeout(() => setCopy(false), 1000);
	}, [copied]);

	const handleCopy = () => {
		let { text } = props;
		text = (text || "").toString().trim();
		copyToClipboard(text);
		setCopy(prev => !prev);
	};

	return (
		<Wrapper {...props}>
			{children}
			&nbsp;
			<Tooltip
				placement="top"
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				overlay={<div>{copied ? "Copied!" : "Copy to clipboard"}</div>}
			>
				{!copied ? (
					<CopyIcon onClick={handleCopy} />
				) : (
					<CheckIcon width={18} height={18} />
				)}
			</Tooltip>
		</Wrapper>
	);
};
export default CopyValue;
