import React, { Component } from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import _get from "lodash/get";
import {
	ARCHIVE_REQUEST,
	DRAFT,
	PAUSE_REQUEST,
	UPDATE,
	PUBLISHED,
	UNPUBLISHED,
	DUPLICATED,
	MARK_AS_FILLED,
	DELETED,
	FILLED,
	CLOSED_TO_NEW_APPLICANTS,
	OPEN_FOR_INTERNAL_USE,
	VACANCY,
	PUBLISH_PROACTIVE,
	FEATURE_PRO_ACTIVE_APPLICATIONS,
	DUPLICATE,
	OPEN_FOR_INTERNAL_USE_LABEL
} from "config";
import {
	editVacancy,
	duplicateJob,
	stopJobModal,
	publishRequestWarning
} from "modules/job/actions";
import UnpublishWarningModal from "common/UnpublishWarningModal";
const initialState = {
	dropdownPosition: "bottomCenter",
	align: {
		points: ["tc", "bc"],
		offset: [0, 0]
	},
	displayWarningModal: false
};

class Actions extends Component {
	state = initialState;

	onVisibleChange = visible => {
		const { id, reposition } = this.props;
		const windowHeight = window.innerHeight;
		setTimeout(() => {
			const element = document.getElementsByClassName(`menu-${id}`)[0];
			if (!element) return;
			if (reposition && visible) {
				const boundingBox = element.getBoundingClientRect();
				if (boundingBox.y + boundingBox.height > windowHeight) {
					this.setState({
						dropdownPosition: "topCenter",
						align: {
							points: ["bc", "tc"],
							offset: [-4, 32]
						}
					});
				}
			} else if (reposition) {
				this.setState(initialState);
			}
			element.classList.add("rc-shown");
		}, 0);

		this.props.onVisibleChange();
	};

	handleMenuClick = (e, vacancy) => {
		const { stopJobModal, duplicateJob } = this.props;
		switch (e.key) {
			case PUBLISHED:
				return this.props.publishRequestWarning(this.props.vacancy);
			case PUBLISH_PROACTIVE:
				return this.props.publishModal(vacancy);
			case UPDATE:
				return editVacancy(vacancy.id);
			case DUPLICATED:
				return duplicateJob(vacancy.id, vacancy.type);
			case CLOSED_TO_NEW_APPLICANTS:
				return this.setState({ displayWarningModal: true });
			case PAUSE_REQUEST:
				return stopJobModal({
					id: vacancy.id,
					title: vacancy.title,
					mode: PAUSE_REQUEST
				});
			case ARCHIVE_REQUEST:
				return stopJobModal({
					id: vacancy.id,
					title: vacancy.title,
					mode: ARCHIVE_REQUEST
				});
			case MARK_AS_FILLED:
				return stopJobModal({
					id: vacancy.id,
					title: vacancy.title,
					pokes: vacancy.poke_list || vacancy.profile_list,
					mode: MARK_AS_FILLED
				});
			default:
				break;
		}
	};

	listMenu = () => {
		const { vacancy, status, is_preview = false, hasPoke, user } = this.props;
		const isVacancy = _get(vacancy, "type") === VACANCY;
		const canPublish =
			status === DRAFT ||
			status === UNPUBLISHED ||
			status === CLOSED_TO_NEW_APPLICANTS ||
			status === OPEN_FOR_INTERNAL_USE;

		const canOpenForInterUse = status === CLOSED_TO_NEW_APPLICANTS;

		const canEdit = status !== FILLED && status !== DELETED;
		const canMarkAsFilled =
			status !== FILLED && status !== DELETED && status !== DRAFT;

		const canArchive = status === DRAFT || status === CLOSED_TO_NEW_APPLICANTS;
		const featureProActive =
			_get(user, "features", []).indexOf(FEATURE_PRO_ACTIVE_APPLICATIONS) !==
			-1;

		return (
			<Menu onClick={e => this.handleMenuClick(e, vacancy)} selectable={false}>
				{isVacancy
					? !is_preview &&
					  canPublish &&
					  featureProActive && (
							<MenuItem
								key={PUBLISH_PROACTIVE}
								className={`ignore-click ${PUBLISHED}`}
							>
								<span className="ignore-click">
									<i className="icon icon-check ignore-click" />
									Publish
								</span>
							</MenuItem>
					  )
					: canPublish && (
							<MenuItem key={PUBLISHED} className={`ignore-click ${PUBLISHED}`}>
								<span className="ignore-click">
									<i className="icon icon-check ignore-click" />
									Publish
								</span>
							</MenuItem>
					  )}

				{canOpenForInterUse &&
					this.props.buttonOfVacancy !== OPEN_FOR_INTERNAL_USE_LABEL && (
						<MenuItem
							key={PUBLISHED}
							className={canPublish ? `ignore-click` : "ignore-click published"}
						>
							<span className="ignore-click">
								<i className="icon icon-check ignore-click" />
								Open internal use
							</span>
						</MenuItem>
					)}
				{(status === OPEN_FOR_INTERNAL_USE || status === PUBLISHED) && (
					<MenuItem
						key={CLOSED_TO_NEW_APPLICANTS}
						className={`ignore-click ${CLOSED_TO_NEW_APPLICANTS}`}
					>
						<span className="ignore-click">
							<i className="icon icon-close-vacancy ignore-click" />
							Close to applications
						</span>
					</MenuItem>
				)}

				{!hasPoke && canEdit && (
					<MenuItem key={UPDATE} className={`ignore-click ${UPDATE}`}>
						<span className="ignore-click">
							<i className="icon icon-edit ignore-click" />
							Edit
						</span>
					</MenuItem>
				)}
				{this.props.buttonOfVacancy !== DUPLICATE && (
					<MenuItem key={DUPLICATED} className={`ignore-click ${DUPLICATED}`}>
						<span className="ignore-click">
							<i className="icon icon-Duplicate ignore-click" />
							Duplicate
						</span>
					</MenuItem>
				)}

				{canMarkAsFilled && (
					<MenuItem
						key={MARK_AS_FILLED}
						className={`ignore-click ${MARK_AS_FILLED}`}
					>
						<span className="ignore-click">
							<i className="icon icon-markasfilled ignore-click" />
							Mark as filled
						</span>
					</MenuItem>
				)}

				{status === PUBLISHED && !is_preview && !isVacancy && (
					<MenuItem
						key={PAUSE_REQUEST}
						className={`ignore-click ${PAUSE_REQUEST}`}
					>
						<span className="ignore-click">
							<i className="icon icon-pause-cycle ignore-click" />
							Unpublish
						</span>
					</MenuItem>
				)}

				{canArchive && (
					<MenuItem
						key={ARCHIVE_REQUEST}
						className={`ignore-click ${ARCHIVE_REQUEST}`}
					>
						<span className="ignore-click">
							<span className="icon icon-archive ignore-click">
								<span className="path1 ignore-click" />
								<span className="path2 ignore-click" />
								<span className="path3 ignore-click" />
								<span className="path4 ignore-click" />
								<span className="path5 ignore-click" />
								<span className="path6 ignore-click" />
							</span>
							Archive
						</span>
					</MenuItem>
				)}
			</Menu>
		);
	};

	render() {
		const {
			children,
			id,
			reposition,
			vacanciesDropdown,
			vacancy,
			stopJobModal
		} = this.props;

		return (
			<>
				<RcDropDown
					trigger={["click"]}
					placement={this.state.dropdownPosition}
					overlay={this.listMenu()}
					overlayClassName={`ignore-click menu-${id} rc-hidden ${vacanciesDropdown}`}
					onVisibleChange={this.onVisibleChange}
					align={reposition && this.state.align}
					onOverlayClick={this.onVisibleChange}
					animation="slide-up"
				>
					{children}
				</RcDropDown>
				{this.state.displayWarningModal && (
					<UnpublishWarningModal
						onClose={() => this.setState({ displayWarningModal: false })}
						onContinue={value => {
							this.setState({ displayWarningModal: false });
							return stopJobModal({
								id: vacancy.id,
								title: vacancy.title,
								mode: CLOSED_TO_NEW_APPLICANTS,
								close_reason: value
							});
						}}
						status={vacancy.status}
					/>
				)}
			</>
		);
	}
}

Actions.propTypes = {
	onVisibleChange: PropTypes.func
};

Actions.defaultProps = {
	onVisibleChange: () => {}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			duplicateJob,
			stopJobModal,
			publishRequestWarning
		},
		dispatch
	);
};
const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
