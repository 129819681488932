import * as Types from "./actionsTypes";

export const initDocumentModal = payload => ({
	type: Types.INIT_ADD_MODAL,
	payload
});

export const resetDocumentModal = () => ({
	type: Types.RESET_ADD_MODAL
});

export const setSelected = id => ({
	type: Types.SET_SELECTED,
	id
});

export const setIsMandatory = is => ({
	type: Types.SET_IS_MANDATORY,
	is
});
