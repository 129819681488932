import React from "react";
import loadable from "loadable-components";
import Scroller from "react-scroll";
import GlobalTooltip from "common/GlobalTooltip";
import CurrencyFlag from "react-currency-flags";
import { ReactComponent as ErrorIcon } from "common/react-hook-form-components/icons/elements-icons-error.svg";
import _get from "lodash/get";

const Select = loadable(() =>
	import(/* webpackChunkName: "react-select" */ "react-select")
);

function CurrenciesSymbolSelect(props) {
	const singleValue = props => {
		return (
			<div className="Select-value">
				<span className="Select-value-label">
					<CurrencyFlag
						currency={
							props.value.code !== null && props.value.code !== ""
								? props?.value?.code?.toUpperCase()
								: "EUR"
						}
						height={14}
						width={18}
						className="currency-value"
					/>
					{props.children}
				</span>
			</div>
		);
	};

	const renderOption = option => {
		return (
			<>
				<CurrencyFlag
					currency={
						option.code !== null && option.code !== ""
							? option.code.toUpperCase()
							: "EUR"
					}
					height={14}
					width={18}
					className="currency-value"
				/>
				{option.label.toUpperCase()}
			</>
		);
	};

	const {
		label,
		input,
		meta: { touched, error },
		placeholder,
		disabled = false,
		clearable = false,
		simpleValue = false,
		classes,
		toolTipOverlay,
		currenciesData
	} = props;

	const hasError = touched && error;

	let classesToAdd = hasError ? "has-error has-feedback" : "";

	if (label) {
		classesToAdd += " has-label";
	} else {
		classesToAdd += " label-no";
	}

	const Element = Scroller.Element;
	let value =
		typeof input?.value === "object" && "length" in input.value
			? ""
			: input?.value?.value || input.value;

	const code = _get(input, "value.code");
	if (code) {
		value = code;
	}

	let currencies = currenciesData;

	currencies = currencies.map(item => ({
		label: item.code,
		value: item.code,
		code: item.code,
		symbol: item.symbol
	}));
	return (
		<Element
			className={`form-group select-style ${classesToAdd}`}
			name={input.name}
			scrollto="scroll-to-error"
		>
			{label && (
				<label
					style={toolTipOverlay && { marginBottom: -1 }}
					htmlFor={input.name}
				>
					{label}
					{toolTipOverlay && (
						<GlobalTooltip maxWidth="480px" overlay={toolTipOverlay} />
					)}
				</label>
			)}
			<Select
				name={input.name}
				value={value}
				options={currencies}
				clearable={clearable}
				placeholder={placeholder}
				simpleValue={simpleValue}
				searchable={true}
				matchPos="start"
				matchProp="label"
				onChange={input.onChange}
				onBlur={() => input.onBlur(input.value)}
				disabled={disabled}
				className={classes}
				valueComponent={singleValue}
				optionRenderer={renderOption}
			/>
			{hasError && (
				<div className="help-block inline-error">
					<ErrorIcon /> {hasError}
				</div>
			)}
		</Element>
	);
}

export default CurrenciesSymbolSelect;
