import React from "react";
import { queryCache } from "react-query";
import SearchModal from "common/CustomizableModel/CustomizableModel";
import DeleteButton from "./DeleteButton";
import get from "lodash/get";

import toast from "react-hot-toast";
import styles from "./styles.module.scss";
import {
	KEY_FETCH_NOTES,
	KEY_FETCH_VACANCY_NOTES
} from "modules/candidate/Profile/Pages/Notes/hooks/useFilterNotes";
import { KEY_FETCH_CANDIDATES } from "modules/SearchCandidate/hooks/useFilterCandidates";
import useDeleteNote from "../../hooks/useDeleteNote";
import { GET_NOTES_QUERY_KEY } from "../../hooks/useGetNotesList";

const DeleteModal = ({ payload, onClose }) => {
	const [mutate, { isLoading }] = useDeleteNote({
		onSuccess: () => {
			toast("Note deleted!");
			queryCache.invalidateQueries("list_notes");
			queryCache.invalidateQueries("list_users");
			queryCache.invalidateQueries("@candidate/userNote");
			queryCache.invalidateQueries("getNotes");
			queryCache.invalidateQueries("getDataPipeline");
			queryCache.invalidateQueries(KEY_FETCH_NOTES);
			queryCache.invalidateQueries(KEY_FETCH_VACANCY_NOTES);
			queryCache.invalidateQueries(KEY_FETCH_CANDIDATES);
			queryCache.invalidateQueries("get-notes");
			queryCache.invalidateQueries(GET_NOTES_QUERY_KEY);
			onClose();
		},

		onError: error => {
			let errorMessage = get(error, "detail");
			if (error.status === 422) {
				if (typeof errorMessage === "object") {
					Object.keys(errorMessage).forEach(name => {
						get(errorMessage, name) &&
							toast(
								`${name} : ${
									typeof get(errorMessage, name) === "string"
										? get(errorMessage, name)
										: get(errorMessage, name)[0]
								}`,
								{ isFailed: true }
							);
					});
				} else {
					toast(errorMessage, { isFailed: true });
				}
				return;
			}
		}
	});
	const deleteNote = payload => {
		mutate(payload);
	};

	return (
		<SearchModal
			title="Delete Note"
			actionButton={
				<DeleteButton
					onClick={() => deleteNote(payload)}
					isLoading={isLoading}
				/>
			}
			onClose={onClose}
		>
			<p className={styles.modal__text}>
				Are you sure you want to delete this note ?
			</p>
		</SearchModal>
	);
};

export default DeleteModal;
