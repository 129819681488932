import get from "lodash/get";

const renderLabelValues = (values, withRating = false) => {
	return get(values, "[0].children", [])
		.map(value => {
			return value.label + (withRating ? ` ${value.rating}/5` : "");
		})
		.join(" - ");
};

const formatDate = date => {
	return new Intl.DateTimeFormat("en-BE", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric"
	}).format(date);
};

const renderDateValues = dates => {
	if (dates) {
		if ("start" && "end" in dates) {
			const date_start = formatDate(dates.start.toDate());
			const date_end = formatDate(dates.end.toDate());

			return `${date_start} and ${date_end}`;
		} else {
			return formatDate(dates.toDate());
		}
	}
};

export const renderLocationValues = location => {
	if (!location) {
		return "";
	}
	if (get(location, "description", "") !== "") {
		return get(location, "description");
	}
	return `${get(location, "number", "") && get(location, "number") + " "}${get(
		location,
		"box",
		""
	) && get(location, "box", "") + " "}${get(location, "street", "") &&
		get(location, "street", "") + " "}${get(location, "city", "") &&
		get(location, "city", "") + " "}${get(location, "country", "") &&
		get(location, "country", "") + " "}${get(location, "zip")}`;
};

export const renderFilterValues = (field, value) => {
	if (!isEmpty(value)) {
		switch (field) {
			case "amount":
			case "payment_period":
				return value / 100;
			case "employment_type":
			case "currencies":
			case "salary_type":
			case "payment_type":
			case "extra_benefits":
			case "category":
			case "sub_category":
			case "seniority":
			case "department":
			case "functions":
			case "proposal_made_by":
				return renderLabelValues(value);
			case "start_date":
			case "proposal_date":
				return renderDateValues(value);
			default:
				return value || "";
		}
	}
};

export const isEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};

export const validFiltersCount = filters => {
	let valid_filters = [];
	if (filters) {
		valid_filters = filters.filter(filter => {
			return !isEmpty(filter.value);
		});
		return valid_filters.length;
	}
	return 0;
};
