import Loader from "common/Loader";
import React from "react";
import style from "./buttonWithIcon.module.scss";

const ButtonWithIcon = ({ text, icon, onClick, isLoading }) => {
	return (
		<button className={style.buttonWithIcon} onClick={onClick}>
			{isLoading ? (
				<div className={style.loader}>
					<Loader check={false} />
				</div>
			) : (
				<>
					{text}
					<span className={style.icon}>{icon}</span>
				</>
			)}
		</button>
	);
};

export default ButtonWithIcon;
