//TODO remove props from store
import React, { useState } from "react";
import _get from "lodash/get";
import CopyTooltip from "rc-tooltip";
import { Flex, Box, Text } from "rebass";
import { stringify } from "query-string";
import { OpenVirtualRoomButton } from "common/OpenVirtualRoomButton";
import {
	Title,
	Content,
	CancelledTitle,
	CancelledHelp,
	VideoBlock
} from "./Styled";
import {
	TIME_FORMAT_SYMBOL,
	DATA_MMMM_D_YYYY,
	CLIENT,
	CANCELLED,
	ACCEPTED,
	REFUSED,
	TWO_HUNDRED,
	INTERVIEW,
	DONE,
	VIDEO_CONFERENCE,
	CONFIRMED
} from "config";
import AddressComposer from "common/AddressComposer";
import Description from "common/Description";
import GlobalTooltip from "common/GlobalTooltip";
import videoIcon from "static/icons/videocall.svg";
import StatusLabel from "common/StatusLabel";
import ContactPerson from "common/contactPerson";
import { ReactComponent as ContratRefusedIcon } from "static/icons/icon-contart-refused.svg";
import { historyPush } from "config/helpers";
import { getRoute as getVirtualRoomPath } from "modules/WhereByVirtualRoom";
import { getRoute as getFreelancerVirtualRoomPath } from "modules/WherebyFreelancerVirtualRoom";
import { formatDate } from "common/Functions";
import { FREELANCER } from "config";
import * as copyToClipboard from "copy-to-clipboard";
import { OpenVirtualRoomCopyButton } from "common/OpenVirtualRoomButton/OpenVirtualRoomCopyButton";

const InterviewDetails = ({
	data,
	user,
	userTarget,
	stepperStatus,
	maxWidth,
	getInterviewStatusTooltip,
	visible_status
}) => {
	const hasOldDate = data.old_interview_start_date !== "";
	const attendeesUsers = _get(data, "attendees_users");
	const [tooltipText, setTooltipText] = useState("Copy link");

	const islastUserSenderClient =
		data.last_user_interview_action?.type === CLIENT;

	const isClient = user.company_type === CLIENT;

	const isInterview = stepperStatus === INTERVIEW;

	const status = data.status;

	const clientSender = `${data.user_sender?.first_name} ${data.user_sender?.last_name}`;

	const lastSender = `${data.last_user_interview_action?.first_name} ${data.last_user_interview_action?.last_name}`;

	const labeltwo = hasOldDate ? "new proposition" : "proposition";
	const clientInterviewSender =
		user.id === data.user_sender?._id ? "Your" : `${clientSender}'s`;

	const label = islastUserSenderClient
		? // if last sender is the client
		  isClient
			? `${clientInterviewSender} ${labeltwo}`
			: `${lastSender}'s ${labeltwo}`
		: // if last sender is the freelancer
		isClient
		? `${lastSender}'s ${labeltwo}`
		: `Your ${labeltwo}`;

	const isInterviewConfirmed = [ACCEPTED, DONE, CONFIRMED].includes(status);
	const isVideoConf = data.location_type === VIDEO_CONFERENCE;
	const isVideoConfOld =
		data.old_interview_address &&
		data.old_interview_address.location_type === VIDEO_CONFERENCE;

	const handleOpenRoomClick = () => {
		const { company_type, first_name, last_name } = user;

		const params = stringify({
			roomUrl: data.room_url,
			displayName: `${first_name} ${last_name}`
		});

		if (company_type === FREELANCER) {
			historyPush(`${getFreelancerVirtualRoomPath()}?${params}`, true);
		} else {
			historyPush(`${getVirtualRoomPath()}?${params}`, true);
		}
	};

	const onCopy = () => {
		copyToClipboard(data?.room_info?.roomUrl);
		setTooltipText("Copied!");
		setTimeout(() => {
			setTooltipText("Copy link");
		}, 1500);
	};

	return (
		<React.Fragment>
			{isInterviewConfirmed && isVideoConf && data.room_url && (
				<VideoBlock>
					{user.company_type === FREELANCER ? (
						<OpenVirtualRoomButton onClick={handleOpenRoomClick} />
					) : (
						<OpenVirtualRoomButton
							onClick={handleOpenRoomClick}
							copyButton={
								<CopyTooltip
									placement="top"
									theme="dark"
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									overlayClassName={"employer_branding_tooltip"}
									overlay={<div>{tooltipText}</div>}
								>
									<OpenVirtualRoomCopyButton onCopy={onCopy} />
								</CopyTooltip>
							}
						/>
					)}
				</VideoBlock>
			)}
			<Box
				mb={10}
				p={20}
				sx={{
					borderRadius: "4px",
					boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
					backgroundColor: "rgb(255, 255, 255)",
					position: "relative"
				}}
			>
				<Flex
					justifyContent="space-between"
					mb={48}
					alignItems="center"
					sx={{
						backgroundColor: () =>
							status === CANCELLED || status === REFUSED ? "#F9E6F1" : "white",
						borderRadius: "4px",
						padding: "10px 12px"
					}}
				>
					{status === CANCELLED || status === REFUSED ? (
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "15px",
								fontWeight: "600",
								letterSpacing: "0.08px",
								color: "rgb(39, 50, 56)",
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<ContratRefusedIcon />
							<Box sx={{ marginLeft: "15px" }}>
								<CancelledTitle>Interview {status}</CancelledTitle>
								<CancelledHelp>
									Your interview with{" "}
									<b>
										{isClient
											? `${userTarget.first_name} ${userTarget.last_name}`
											: clientSender}
									</b>{" "}
									has been {status}.
								</CancelledHelp>
							</Box>
						</Text>
					) : (
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "15px",
								fontWeight: "600",
								letterSpacing: "0.08px",
								color: "rgb(39, 50, 56)"
							}}
						>
							Interview
						</Text>
					)}
					<Box sx={{ opacity: 0.7 }}>
						{isInterview && (
							<GlobalTooltip
								placement="top"
								eventTrigger="hover"
								noClassName={true}
								maxWidth={maxWidth}
								overlay={
									<span>
										{getInterviewStatusTooltip(status, islastUserSenderClient)}
									</span>
								}
							>
								<StatusLabel
									right={0}
									status={status}
									label={visible_status}
									isInterview
									px={15}
								/>
							</GlobalTooltip>
						)}
					</Box>
				</Flex>

				<div className="section-proposition">
					<Flex mt={46}>
						{!isInterviewConfirmed && hasOldDate && (
							<>
								<Flex mb={20}>
									<Box>
										<Flex flexDirection="column">
											<Text
												mb={28}
												sx={{
													fontFamily: "BasierCircle",
													fontSize: "15px",
													fontWeight: "600",
													letterSpacing: "0.08px",
													color: "rgb(39, 50, 56)"
												}}
											>
												Old proposition
											</Text>
											<Flex alignItems="center">
												<Flex
													flexDirection="column"
													alignItems="center"
													pr={15}
													mr={15}
													sx={{
														minWidth: "90px",
														borderRight: "1px solid #f1f2f5"
													}}
												>
													<Text
														sx={{
															fontFamily: "BasierCircle",
															fontSize: "10px",
															fontWeight: "600",
															letterSpacing: "0.86px",
															color: "#ff6849"
														}}
													>
														{formatDate(
															data.old_interview_start_date,
															true,
															"MMMM"
														)}
													</Text>
													<Text
														sx={{
															fontFamily: "BasierCircle",
															fontSize: "30px",
															letterSpacing: "0.15px",
															color: "rgb(39, 50, 56)",
															borderBottom: "solid 2px rgb(112, 112, 112)"
														}}
													>
														{formatDate(
															data.old_interview_start_date,
															true,
															"D"
														)}
													</Text>
													<Text
														sx={{
															fontFamily: "BasierCircle",
															fontSize: "12px",
															letterSpacing: "0.1px",
															color: "rgb(15, 28, 70)"
														}}
													>
														{formatDate(
															data.old_interview_start_date,
															true,
															"YYYY"
														)}
													</Text>
												</Flex>
												<Box sx={{ minWidth: "300px" }}>
													<Text
														sx={{
															fontFamily: "BasierCircle",
															fontSize: "12px",
															fontWeight: "600",
															letterSpacing: "1.08px",
															color: "rgb(160, 165, 185)"
														}}
													>
														DATE & TIME
													</Text>
													<Text
														sx={{
															fontFamily: "BasierCircle",
															fontSize: "14px",
															color: "rgb(15, 28, 70)"
														}}
													>
														{formatDate(
															data.old_interview_start_date,
															true,
															DATA_MMMM_D_YYYY
														)}{" "}
														{formatDate(
															data.old_interview_start_date,
															true,
															TIME_FORMAT_SYMBOL
														)}{" "}
														-{" "}
														{formatDate(
															data.old_interview_end_date,
															true,
															TIME_FORMAT_SYMBOL
														)}
													</Text>
													<Box mt={20}>
														<Text
															sx={{
																fontFamily: "BasierCircle",
																fontSize: "12px",
																fontWeight: "600",
																letterSpacing: "1.08px",
																color: "rgb(160, 165, 185)"
															}}
														>
															LOCATION
														</Text>
														<Text
															sx={{
																fontFamily: "BasierCircle",
																fontSize: "14px",
																color: "rgb(15, 28, 70)"
															}}
														>
															{!isInterviewConfirmed && (
																<div>
																	{isVideoConfOld ? (
																		<div className="video-conf">
																			<img
																				src={videoIcon}
																				alt="camera"
																				className="box-camera"
																			/>
																			<div>
																				<div className="text">
																					Video Conference
																				</div>
																				<div className="box-text">
																					Virtual Meeting Room
																				</div>
																			</div>
																		</div>
																	) : (
																		<AddressComposer
																			addressData={data.old_interview_address}
																			noClass={true}
																		/>
																	)}
																</div>
															)}
														</Text>
													</Box>
												</Box>
											</Flex>
										</Flex>
									</Box>
								</Flex>
							</>
						)}
						<>
							<Flex mb={20}>
								<Box>
									<Flex flexDirection="column">
										<Text
											mb={28}
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "15px",
												fontWeight: "600",
												letterSpacing: "0.08px",
												color: "rgb(39, 50, 56)"
											}}
										>
											{isInterviewConfirmed ? " Confirmed date & time" : label}
										</Text>
										<Flex alignItems="center">
											<Flex
												flexDirection="column"
												alignItems="center"
												pr={15}
												mr={15}
												sx={{
													minWidth: "90px",
													borderRight: "1px solid #f1f2f5"
												}}
											>
												<Text
													sx={{
														fontFamily: "BasierCircle",
														fontSize: "10px",
														fontWeight: "600",
														letterSpacing: "0.86px",
														color: "#ff6849"
													}}
												>
													{formatDate(
														data.new_interview_start_date,
														true,
														"MMMM"
													)}
												</Text>
												<Text
													sx={{
														fontFamily: "BasierCircle",
														fontSize: "30px",
														letterSpacing: "0.15px",
														color: "rgb(39, 50, 56)",
														borderBottom: "solid 2px rgb(112, 112, 112)"
													}}
												>
													{formatDate(data.new_interview_start_date, true, "D")}
												</Text>
												<Text
													sx={{
														fontFamily: "BasierCircle",
														fontSize: "12px",
														letterSpacing: "0.1px",
														color: "rgb(15, 28, 70)"
													}}
												>
													{formatDate(
														data.new_interview_start_date,
														true,
														"YYYY"
													)}
												</Text>
											</Flex>
											<Box sx={{ minWidth: "300px" }}>
												<Text
													sx={{
														fontFamily: "BasierCircle",
														fontSize: "12px",
														fontWeight: "600",
														letterSpacing: "1.08px",
														color: "rgb(160, 165, 185)"
													}}
												>
													DATE & TIME
												</Text>
												<Text
													sx={{
														fontFamily: "BasierCircle",
														fontSize: "14px",
														color: "rgb(15, 28, 70)"
													}}
												>
													{formatDate(
														data.new_interview_start_date,
														true,
														DATA_MMMM_D_YYYY
													)}{" "}
													{formatDate(
														data.new_interview_start_date,
														true,
														TIME_FORMAT_SYMBOL
													)}{" "}
													-{" "}
													{formatDate(
														data.new_interview_end_date,
														true,
														TIME_FORMAT_SYMBOL
													)}
												</Text>
												<Box mt={20}>
													<Text
														sx={{
															fontFamily: "BasierCircle",
															fontSize: "12px",
															fontWeight: "600",
															letterSpacing: "1.08px",
															color: "rgb(160, 165, 185)"
														}}
													>
														LOCATION
													</Text>
													<Text
														sx={{
															fontFamily: "BasierCircle",
															fontSize: "14px",
															color: "rgb(15, 28, 70)"
														}}
													>
														{!isInterviewConfirmed && (
															<div>
																{isVideoConf ? (
																	<div className="video-conf">
																		<img
																			src={videoIcon}
																			alt="camera"
																			className="box-camera"
																		/>
																		<div>
																			<div className="text-orange">
																				Video Conference
																			</div>
																			<div className="box-text-orange">
																				Virtual Meeting Room
																			</div>
																		</div>
																	</div>
																) : (
																	<>
																		<AddressComposer
																			addressData={data}
																			noClass={true}
																		/>
																	</>
																)}
															</div>
														)}
														{isInterviewConfirmed && (
															<div>
																{isVideoConf ? (
																	<div>
																		<div className="video-conf">
																			<img
																				src={videoIcon}
																				alt="camera"
																				className="box-camera"
																			/>
																			<div>
																				<div className="text text-orange">
																					Video Conference
																				</div>
																				<div className="box-text box-text-orange">
																					Virtual Meeting Room
																				</div>
																			</div>
																		</div>
																	</div>
																) : (
																	<>
																		<AddressComposer
																			addressData={data}
																			noClass={true}
																		/>
																	</>
																)}
															</div>
														)}
													</Text>
												</Box>
											</Box>
										</Flex>
									</Flex>
								</Box>
							</Flex>

							{attendeesUsers && attendeesUsers.length > 0 && (
								<Box>
									<ContactPerson attendeesUsers={attendeesUsers} />
								</Box>
							)}
						</>
					</Flex>
					<div style={{ margin: "0 10px" }}>
						<div className="row">
							{data.description && !isInterviewConfirmed && (
								<div className={"col-md-12"}>
									<Title>Message</Title>
									<Content>
										<Description
											parseMarkdown={false}
											description={data.description}
											max={TWO_HUNDRED}
										/>
									</Content>
								</div>
							)}
						</div>
					</div>
				</div>
			</Box>
		</React.Fragment>
	);
};

InterviewDetails.defaultProps = {
	maxWidth: "400px",
	margin_value: 0,
	userTarget: {}
};

export default InterviewDetails;
