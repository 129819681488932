/* eslint-disable react/jsx-key, react/display-name */
import React, { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { useQueryCache } from "react-query";
import cx from "classnames";
import { Text, Flex } from "rebass";
import create from "zustand";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { useDebounce } from "react-use";
import { XCircleIcon } from "@heroicons/react/outline";
import useListSupplierInvitations, {
	supplierHistoryQueryKey
} from "modules/consultancy/api/useListSupplierInvitations";
import { formatDate } from "config/helpers";
import s from "./invitations.module.scss";
import Pagination from "common/Pagination";
import useInviteToSupplierList from "modules/consultancy/api/useInviteToSupplierList";
import toaster from "common/Toaster";
import Spinner from "common/Spinner";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

function TableActions({ status, supplierId, tierName, categoryId }) {
	const queryCache = useQueryCache();
	const [resend, { isLoading }] = useInviteToSupplierList({
		onSuccess() {
			toaster.success("Invitation resent.");
			queryCache.invalidateQueries([supplierHistoryQueryKey]);
		}
	});

	const handleResend = () => {
		resend({
			supplier_id: supplierId,
			tier_name: tierName,
			category_ids: [categoryId]
		});
	};

	const Resend = (
		<button
			key={"resend"}
			className={s.resendButton}
			disabled={isLoading}
			onClick={handleResend}
		>
			{isLoading ? "Sending" : "Re-send"}
		</button>
	);
	const actionsPool = {
		refused: [Resend]
	};
	return actionsPool[status] ?? <span style={{ padding: 6 }}>N/A</span>;
}

const useOffset = create(set => ({
	offset: 0,
	setOffset: offset => set({ offset })
}));

const statusLabelMap = {
	sent: "Pending",
	draft: "Pending"
};

const size = 24;

function Invitations() {
	const setItems = useBreadCrumbs(state => state.setItems);
	const [search, setSearch] = useState("");
	const [term, setTerm] = useState("");
	useEffect(() => {
		setItems([
			{ name: "Suppliers" },
			{ name: "Suppliers Management", path: "/suppliers-management#tiering" },
			{ name: "Invitations" }
		]);
	}, []);

	useDebounce(() => setTerm(search), 500, [search]);

	const { setOffset, offset } = useOffset(state => ({
		offset: state.offset,
		setOffset: state.setOffset
	}));
	const { data, isLoading } = useListSupplierInvitations({
		limit: size,
		search: term,
		offset
	});

	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: row => formatDate(row.created_at)
			},
			{
				Header: "Supplier",
				accessor: "supplier.company_name"
			},
			{
				Header: "Category",
				accessor: "category.name"
			},
			{
				Header: "Tier",
				accessor: "tier.name"
			},
			{
				Header: "Inviter",
				accessor: row =>
					row.created_by.first_name + " " + row.created_by.last_name
			},
			{
				Header: "Contract file",
				accessor: row =>
					_isEmpty(row.tier?.tier_link) ? (
						"N/A"
					) : (
						<a
							className={s.fileLink}
							target={"_blank"}
							rel="noopener noreferrer"
							href={_get(row, "tier.tier_link.link")}
						>
							{_get(row, "tier.tier_link.file_name")}
						</a>
					)
			},

			{
				Header: "Status",
				accessor: row => (
					<div className={cx(s.tableStatus, s[row.status])}>
						{statusLabelMap[row.status] ?? row.status}
					</div>
				)
			},
			{
				Header: "Actions",
				Cell: ({ row: { original } }) => (
					<TableActions
						supplierId={original.supplier._id}
						tierName={original.tier.name}
						categoryId={original.category._id}
						status={original.status}
					/>
				)
			}
		],
		[data]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		data: data?.data ?? [],
		columns
	});

	return (
		<div className={s.root}>
			<div className={s.searchContainer}>
				<input
					type="text"
					onChange={e => setSearch(e.target.value)}
					value={search}
					className={s.searchInput}
					placeholder={
						"Type to search by supplier, category, tier, inviter and status..."
					}
				/>
				{term && (
					<button
						onClick={() => {
							setSearch("");
							setTerm("");
						}}
					>
						<XCircleIcon width={24} height={24} color={"#757593"} />
					</button>
				)}
			</div>

			{isLoading ? (
				<Flex
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Spinner color={"#ff6849"} />
					<Text mt={2}>Loading, please wait...</Text>
				</Flex>
			) : data?.data?.length > 0 ? (
				<table className={s.table} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map(row => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map(cell => {
										return (
											<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			) : (
				<Flex py={4} justifyContent={"center"}>
					<h5>No previous invitations found.</h5>
				</Flex>
			)}
			{data?.pagination?.total > data?.pagination?.size && (
				<Pagination
					total={data?.pagination.total}
					size={data?.pagination.size}
					handlePageClick={page => setOffset(page * size)}
					containerClassName={cx("pagination", s.pagination)}
				/>
			)}
		</div>
	);
}

export default Invitations;
