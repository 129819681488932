import React from "react";
import { styled, keyframes } from "@stitches/react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
// const DialogPrimitive = () => <div></div>;

const overlayShow = keyframes({
	"0%": { opacity: 0 },
	"100%": { opacity: 0.7 }
});

const contentShow = keyframes({
	"0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
	"100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" }
});

const StyledOverlay = styled(DialogPrimitive.Overlay, {
	backgroundColor: "#000",
	opacity: 0.7,
	position: "fixed",
	inset: 0,
	animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`
});

function Root({ children, ...props }) {
	return (
		<DialogPrimitive.Root {...props}>
			<StyledOverlay />
			{children}
		</DialogPrimitive.Root>
	);
}

const StyledContent = styled(DialogPrimitive.Content, {
	backgroundColor: "white",
	borderRadius: 6,
	boxShadow:
		"hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
	position: "fixed",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	padding: 25,
	animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
	"&:focus": {
		outline: "none"
	},
	variants: {
		size: {
			md: {
				width: "450px"
			},
			lg: {
				width: "650px"
			},
			xl: {
				width: "850px"
			},
			xxl: {
				width: "1100px"
			}
		}
	},
	defaultVariants: {
		size: "md"
	}
});

const StyledTitle = styled(DialogPrimitive.Title, {
	margin: 0,
	fontWeight: 600,
	fontSize: 17,
	fontFamily: "BasierCircle",
	color: "#394047"
});

const StyledDescription = styled(DialogPrimitive.Description, {
	margin: "10px 0 20px",
	fontSize: 15,
	lineHeight: 1.5
});

export const Dialog = Root;
export const DialogContent = StyledContent;
export const DialogTitle = StyledTitle;
export const DialogDescription = StyledDescription;
export const DialogClose = DialogPrimitive.Close;
