import React from "react";
import styled from "styled-components";
import { Button } from "common/styled/buttons";
import Modal from "common/modal";

import { ReactComponent } from "../icons/profile-loading.svg";

const Container = styled.div`
	padding: 0 15px;
	.company-logo {
		max-width: 120px;
		max-height: 50px;
		margin-top: 4%;
	}
	.title-intro {
		font-family: "basiercircle";
		font-size: 40px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.15;
		letter-spacing: normal;
		text-align: center;
		color: #212b36;
		margin: 8% 0 2% 0;
	}
	.content-first-siginin {
		font-family: "basiercircle";
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: normal;
		text-align: center;
		color: #212b36;
		margin: 1% 0 10% 0;
	}
	.bottom-text {
		font-family: "basiercircle";
		font-size: 16px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.38;
		letter-spacing: normal;
		color: #212b36;
	}
	.buttons-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.ignore {
			background-color: transparent;
			border: none;
			color: #212b36;
			font-size: 16px;
			font-family: "basiercircle";
			outline: none;
		}
	}
`;
function FirstSigninModal({ closeModal, onEdit }) {
	const companyLogo = localStorage.getItem("companyLogo");
	const companyName = localStorage.getItem("companyName");

	const DefaultText = () => (
		<React.Fragment>
			<ReactComponent />
			<h3 className="title-intro">Welcome to wiggli!</h3>
			<p className="content-first-siginin">
				In order to enjoy the best experience on your account and to find the
				jobs that are interesting for you, please complete the function & skills
				tab as well as your preferences.
			</p>
			<p className="content-first-siginin">
				You can also choose the status of your account and manage your
				visibility to all companies.
			</p>
		</React.Fragment>
	);

	return (
		<Modal fixed={true} className="intro-modal-permanent" onClose={closeModal}>
			<Container>
				<div
					className="row"
					style={{ textAlign: "center", justifyContent: "center" }}
				>
					{companyLogo !== "undefined" && companyName !== "undefined" ? (
						<>
							{" "}
							<img
								className="company-logo"
								src={companyLogo}
								alt={companyName}
							/>
							<h3 className="title-intro">Congratulations!</h3>
							<h5 className="content-first-siginin">
								{`You have successfully joined ${companyName}'s Applicant Tracking System`}
							</h5>
							<h6 className="bottom-text">
								Increase your chances of being selected
							</h6>
						</>
					) : (
						<DefaultText />
					)}
					<div className="buttons-container">
						<Button
							className="btn btn-primary"
							style={{ margin: "30px 0px 20px 0px", padding: "12px 65px" }}
							onClick={onEdit}
							size="14"
						>
							Edit your profile
						</Button>
						<button className="ignore" onClick={closeModal}>
							Ignore
						</button>
					</div>
				</div>
			</Container>
		</Modal>
	);
}
export default FirstSigninModal;
