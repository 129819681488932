import { useMemo } from "react";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { COMPLETED } from "config";
import { TASKS_LIST_ROWS_ACTIONS } from "../utils/constant";

export const useGetActions = ({ status, assigned_user, created_by }) => {
	const { id: userId } = useSelector(onlineUserSelector);

	const actions = useMemo(() => {
		let res = [];
		const isCompleted = status.toLowerCase() === COMPLETED;

		if (userId === created_by._id && !isCompleted) {
			res = res.concat([
				TASKS_LIST_ROWS_ACTIONS.delete,
				TASKS_LIST_ROWS_ACTIONS.edit
			]);
		}
		if (userId === assigned_user._id && !isCompleted) {
			res = res.concat([
				TASKS_LIST_ROWS_ACTIONS.markAsComplete,
				TASKS_LIST_ROWS_ACTIONS.reminder
			]);
		}

		return res;
	}, [created_by, assigned_user, status]);

	return actions;
};
