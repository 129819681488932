import styled, { css } from "styled-components";

export const StyledTable = styled.table`
	border-spacing: 0 3px;
	margin-top: 20px;
	width: 100%;
	tbody:before {
		content: "";
		display: block;
		line-height: 12px;
		height: 12px;
	}

	tbody {
		tr {
			border-bottom: 2px solid transparent;
		}
	}
`;

export const Th = styled.th`
	padding: 15px;
	background-color: #e5e8ec;
	color: #6b7075;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.65;
	position: relative;
	cursor: pointer;
	.head {
		display: flex;
		align-items: center;
		position: relative;
		.icon-down-arrow {
			font-size: 5px;
			margin-left: 4px;
			cursor: pointer;
			transition: 0.2s;
			&.hidden-filter {
				transform: rotate(-90deg);
			}
		}
	}
	.resizer {
		width: 2px;
		height: 100%;
		background: #0f72e5;
		position: absolute;
		right: 0;
		top: 0;
		display: none;
	}
	.Select-control {
		margin-top: 10px;
		height: 30px;
		cursor: text;
		border: unset;
		&:hover {
			border: unset;
		}
		.Select-arrow-zone {
			display: none;
		}
		.Select-value {
			display: flex;
			align-items: center;
		}
		.Select-clear-zone {
			position: relative;
			right: 5px;
		}
	}
	.is-disabled {
		.Select-control {
			background-color: #f1f2f5 !important;
		}
	}


	${props =>
		props.isStatus &&
		css`
			.Select-control {
				text-transform: capitalize;
			}
			.Select-option {
				text-transform: capitalize;
			}
		`}

	${props =>
		(props.selectedFilter || props.clicked) &&
		css`
			background-color: #d8eefc !important;
			.resizer {
				display: unset;
			}
		`}
	${props =>
		props.isRange &&
		css`
			background-color: #e5e8ec !important;
			.resizer {
				display: none;
			}
		`}
`;

export const Td = styled.td`
	padding: ${({ padding }) => (padding ? padding : "15px")};
	font-size: 13px;
	background-color: #fff;
	color: #0f1c46;
	font-weight: 400;
	display: flex;
	align-items: center;
	cursor: pointer;
	.skeleton-container {
		width: 100%;
	}

	.g-tool {
		font-size: 13px;
		margin-left: 0;
	}

	.appl-type {
		padding: 2px 4px;
		letter-spacing: 0.29px;
		font-weight: 500;
		font-size: 10px;
		line-height: 1.68;
		border-radius: 2px;
		text-transform: uppercase;
		text-align: center;
		max-width: 120px;
		&.freelancer {
			background-color: #ffd38f;
			color: #9f7943;
			width: 75px;
		}
		&.consultancy {
			color: #0b6dba;
			background-color: #dff0f9;
			width: 82px;
		}
	}
	.appl-name {
		.g-tool {
			display: unset !important ;
			color: unset !important;
			font-size: unset !important;
			vertical-align: unset !important;
			margin-left: 0px !important;
		}
	}
`;

export const FiltersContainer = styled.div`
	.form-group {
		position: relative;
		margin-bottom: 24px;
		.form-control {
			border: unset;
			background: transparent;
			border-bottom: 1px solid #d6d7dd;
			padding: 0px 30px;
			min-height: 45px !important;
		}
		input.form-control {
			padding-left: 30px;
		}
		.icon-search {
			position: absolute;
			top: 17px;
			left: 8px;
			color: #a5b1bb;
		}
		.input-error {
			border: 1px solid #ff6849 !important;
		}
		.icon-reset-3 {
			position: relative;
			top: 50%;
		}
	}
	.select-label {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: 0.08px;
		color: #273238;
		line-height: 1.43;
	}
	.Select-control {
		width: 190px;
		margin-top: 6px;
		height: 40px;
		border: 2px solid #0676ed;
		border-radius: 2px;
		.Select-value {
			display: flex;
			align-items: center;
		}
	}
	.Select-menu-outer {
		width: 190px;
	}
`;

export const ViewContainer = styled.div`
	padding: 30px 15px;
`;

export const StatusCircle = styled.div`
	width: 14px;
	height: 14px;
	background-color: transparent;
	border: 2px solid ${props => props.color && props.color};
	border-radius: 50%;
	display: inline-block;
	margin-right: 4px;
`;

export const EmptyTableContainer = styled.div`
	width: 100%;
	height: 280px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;

	svg {
		width: 140px;
	}

	.content {
		color: #0b283e;
		text-align: center;

		.empty-title {
			font-weight: 500;
			line-height: 1.19;
			margin-top: 10px;
			margin-bottom: 4px;
		}
		.empty-text {
			font-weight: 400;
			line-height: 1.5;
		}
	}
`;

export const RangeValues = styled.div`
	border: 2px solid white;
	width: 100%;
	background-color: white;
	border-radius: 2px;
	margin-top: 8px;
	height: 31px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	color: #333333;
	display: flex;
	justify-content: space-between;
	.icon-close {
		color: #999;
		font-weight: 600;
	}
`;

export const RangeContainer = styled.div`
	padding: 7px 10px 14px 10px;
	background-color: white;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
	position: absolute;
	width: 160px;
	overflow: hidden;
	margin-top: 8px;
	z-index: 10;
	border: 2px solid transparent;
	border-radius: 2px;

	.title {
		color: #54585c;
		line-height: 1.75;
		letter-spacing: normal;
		text-align: left;
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 4px;
	}
	.rc-slider {
		height: 16px;
	}
	.rc-slider-track {
		background-color: #0fc855;
		height: 5px;
		z-index: 1;
	}
	.rc-slider-step {
		background-color: #c0f8d5;
		border-radius: 4px;
		border-color: transparent;
		height: 5px;
	}
	.rc-slider-rail {
		border-radius: 4px;
		height: 5px;
	}
	.rc-slider-handle {
		box-shadow: 0 0 2.5px 0 rgba(152, 153, 156, 0.63);
		background-color: #ffffff;
		border: unset;
		width: 16px !important;
		height: 16px !important;
		outline: 0;
		z-index: 2;
		&:focus {
			box-shadow: 0 0 2.5px 0 rgba(152, 153, 156, 2);
		}
	}
`;
