import React from "react";
import { ReactComponent as PersonIcon } from "static/icons/single-candidate-tab-icon.svg";
import { ReactComponent as MultiPersonIcon } from "static/icons/multi-person-candidate-icon.svg";
import * as yup from "yup";
import { TYPE_PDF, TYPE_DOC, TYPE_DOCX } from "config";
import { parsePhoneNumber } from "libphonenumber-js";

export const SINGLE_CANDIDATE_TAB_ID = "SINGLE_CANDIDATE_TAB_ID";
export const BULK_UPLOAD_TAB_ID = "BULK_UPLOAD_TAB_ID";

export const ADD_CANDIDATE_TABS = [
	{
		id: SINGLE_CANDIDATE_TAB_ID,
		label: "Single candidate",
		icon: <PersonIcon />
	},
	{
		id: BULK_UPLOAD_TAB_ID,
		label: "Bulk upload",
		icon: <MultiPersonIcon />
	}
];

export const FILE_SIZE_LIMIT_MB = 5 * 1024 * 1024;

export const ALLOWED_FILES_TYPES = [TYPE_PDF, TYPE_DOC, TYPE_DOCX];
export const ALLOWED_FILES_MAX = 30;

export const UPLOAD_ID = "UPLOAD_ID";
export const PARCEL_DETAILS_ID = "PARCEL_DETAILS_ID";
export const PARCELS_LIST_ID = "PARCELS_LIST_ID";

export const FILE_TYPES = {
	PDF: "PDF",
	DOC: "DOC",
	DOCX: "DOCX"
};

export const NAME_REGEX = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;
const isObject = value =>
	typeof value === "object" && value !== null && !Array.isArray(value);
const isArray = value => Array.isArray(value);

yup.addMethod(yup.mixed, "objectOrArrayOfObjects", function() {
	return this.test("objectOrArrayOfObjects", "CV is Required", value => {
		if (value === null || value === undefined) {
			return true;
		}

		if (isArray(value)) {
			return value.every(isObject);
		}

		return isObject(value);
	});
});

export const schema = yup.object().shape({
	cv_doc: yup
		.mixed()
		.objectOrArrayOfObjects()
		.required("CV is required"),
	first_name: yup
		.string()
		.trim()
		.required("First name is required")
		.matches(NAME_REGEX, "First name can only contain Latin letters."),
	last_name: yup
		.string()
		.trim()
		.required("Last name is required")
		.matches(NAME_REGEX, "Last name can only contain Latin letters."),
	email: yup.string().email("Invalid Email Format"),
	phone: yup.object().test("phone", "Invalid phone number", value => {
		if (value?.countryCode && value?.value) {
			try {
				const res = parsePhoneNumber(value.value, {
					extract: false
				});
				return res.isValid();
			} catch (error) {
				return false;
			}
		}
		return true;
	})
});

export const bulkUploadSchema = yup.object().shape({
	cv_doc: yup
		.mixed()
		.objectOrArrayOfObjects()
		.required("CV is required")
});

export const BLOCKED_ACCOUNT = "blocked account";
export const CANDIDATE_ALREADY_EXISTS_NOT_SUBSCRIBED =
	"candidate already exists";
export const ACCOUNT_EXIST_SUBSCRIBED = "account exists";

export const addCandidateErrorHandler = errorType => {
	switch (errorType) {
		case BLOCKED_ACCOUNT:
			return {
				title: "Candidate blocked",
				description: "This candidate is blocked"
			};
		case CANDIDATE_ALREADY_EXISTS_NOT_SUBSCRIBED:
			return {
				title: "Candidate already exist",
				description:
					"This candidate already has a wiggli account.We will let him/her know that you wish add him/her to your candidate pool."
			};
		case ACCOUNT_EXIST_SUBSCRIBED:
			return {
				title: "Candidate already exist",
				description:
					"This candidate already has a wiggli account & member of your talent pool."
			};
	}
};
