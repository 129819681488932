import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const LoadingSpinner = ({ className }) => {
	return (
		<div className={cn(styles.spinnerContainer, className)}>
			<div className={styles.spinner}></div>
		</div>
	);
};

export default LoadingSpinner;
