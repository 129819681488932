import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";

import CompanyDashboard from "common/dashboard/Dashboard";
import Intro from "common/dashboard/Intro";
import EmptyBids from "common/EmptyComponent";
import Pagination from "common/Pagination";
import { consultancyListBids } from "../../actions";
import VendorBidItem from "../VendorBidItem";
import CancelBidModal from "../modals/CancelBidModal";
// TODO: move this table style to a more suitable place 'common?'
import {
	TimesheetTableDetails,
	Th
} from "modules/timesheets/components/TimesheetStyled";
import { getConsultancyBidViewRoute } from "../bidview/consultancy/ConsultancyBidViewContainer";
import FilterByStatus from "../../../job/components/FilterByStatus";

export const getConsultancyBidsListRoute = () => {
	return "/jobs/consultancy/bids";
};

class ConsultancyBidsList extends Component {
	state = {
		showBidModal: false,
		active: false,
		my_job_status: ""
	};

	selected_bid = {
		_id: "",
		stepper_status: "",
		interview_id: "",
		contract_id: ""
	};

	toggleModal = bid => {
		if (bid) {
			this.selected_bid = bid;
		}
		this.setState({ showBidModal: !this.state.showBidModal });
	};

	showListBids() {
		const { listBids } = this.props.bids;
		return listBids.map(bid => {
			return (
				<VendorBidItem
					bid={bid}
					isConsultancy={true}
					key={bid._id}
					viewDetails={this.goToBidView}
					openModal={() => this.toggleModal(bid)}
				/>
			);
		});
	}

	goToBidView = id => {
		browserHistory.push(getConsultancyBidViewRoute(id));
	};

	getBidsList = (payload = {}) => {
		this.props.consultancyListBids(payload);
	};

	filterList = status => {
		const payload = {
			offset: 0
		};
		if (status !== "active_jobs") payload.status = status;
		this.props.filterConsultancyBidsList(payload, status);
		this.setState({ my_job_status: status });
	};

	handlePageClick = value => {
		const {
			bids: { pagination },
			getBidsList
		} = this.props;
		const status = this.state.my_job_status;
		const payload = {
			offset: pagination.size * value
		};
		if (status !== "active_jobs") payload.status = status;
		getBidsList(payload);
	};

	render() {
		const {
			bids: { listBids, pagination },
			route: { title }
		} = this.props;
		const { showBidModal } = this.state;
		const listBidsEmpty = listBids.length <= 0;
		return (
			<BodyClassName className="opened-jobs gray-bg">
				<div className="bids-box">
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<div className="dashboard-box">
						<CompanyDashboard />
						<Intro
							subTitle={`This page lists all the mission requests with which you have interacted. To view the details of an application, simply click on it in the list below.`}
						/>
					</div>
					<div className="container">
						<div className="my-jobs-filter">
							<FilterByStatus filterList={this.filterList} myJobs={true} />
						</div>
						{listBidsEmpty ? (
							<EmptyBids
								title="No bid placed so far"
								paragraph="Bidding on requests from clients is the first step towards finding missions for your consultancy via wiggli. To do so, just use the link below to look for missions matching your consultants’ skills and expectations."
								actionLink="/jobs/search"
								actionText="Search missions"
							/>
						) : (
							<React.Fragment>
								<TimesheetTableDetails spacing="0 3px">
									<thead>
										<tr>
											<Th width="8%">job ref</Th>
											<Th width="12%">consultant</Th>
											<Th width="16%">company name</Th>
											<Th width="15%">title</Th>
											<Th width="20%">start / end date</Th>
											<Th width="14%">time commitment</Th>
											<Th align="center" width="17%">
												status
											</Th>
											<Th width="8%">actions</Th>
										</tr>
									</thead>
									<tbody>{this.showListBids()}</tbody>
								</TimesheetTableDetails>
								<Pagination
									total={pagination.total}
									size={pagination.size}
									handlePageClick={this.handlePageClick}
									offset={pagination.offset}
								/>
							</React.Fragment>
						)}
						<CancelBidModal
							key="modal"
							bidId={this.selected_bid._id}
							stepperStatus={this.selected_bid.stepper_status}
							interviewId={this.selected_bid.interview_id}
							contractId={this.selected_bid.contract_id}
							active={showBidModal}
							onClose={this.toggleModal}
						/>
					</div>
				</div>
			</BodyClassName>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	...bindActionCreators({ consultancyListBids }, dispatch)
});

const mapStateToProps = state => {
	return {
		bids: state.bids,
		isFetchingMembers: state.proposal.isFetching,
		company_type: state.auth.user.company_type
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ConsultancyBidsList);
