import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_OVERDUE_JOBS_ENDPOINT } from "config/api-endpoints";
import { WELCOME_PAGE_OVERDUE_JOBS } from "config";
import { overdueJobsListKey } from "modules/home/api/queriesKeys.js";

const listOverdueJobs = (key, { start_date, end_date }) =>
	client(LIST_OVERDUE_JOBS_ENDPOINT, {
		body: {
			start_date,
			end_date,
			job_type: "vacancy",
			tag: WELCOME_PAGE_OVERDUE_JOBS
		}
	});

const useOverdueJobsList = (options, queryOptions = {}) =>
	useQuery([overdueJobsListKey, options], listOverdueJobs, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useOverdueJobsList;
