import React, { useEffect, useState } from "react";
import cx from "classnames";
import get from "lodash/get";

import CandidateActionsDrawer from "modules/vacancy/components/vacancyView/CandidateAction/DrawerComponent";
import styles from "./accordion.module.scss";
import { formatDate } from "common/Functions";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import usePokeData from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";

const AccordionHeader = ({ show, toggle, header, application_id }) => {
	const title = get(header, "title", "");
	const userName = get(header, "user_name", "");
	const visibleStatus = get(header, "visibleStatus", "");
	const dueDate = get(header, "due_date");
	const [pokeId, setPokeId] = useState(null);

	const { setIsOpen, setApplicationId, setIsFromProcesses } = useProfileView();

	const openDrawer = () => {
		setPokeId(application_id);
		setIsOpen(true);
		setApplicationId(application_id);
		setIsFromProcesses(true);
	};
	const { data: pokeData } = usePokeData({ id: pokeId });

	useEffect(() => {
		setPokeId(application_id);
	}, [application_id]);

	return (
		<>
			<div className={styles.accordionHeader}>
				<div className={styles.header} onClick={toggle}>
					<div className={styles.title}>
						<span
							className={cx(styles.arrowDown, { [styles["show"]]: show })}
						/>
						{title}
					</div>
					<div className={styles.description}>
						<p>By {userName}</p>
						<p>{visibleStatus}</p>
						<p>Due: {dueDate ? formatDate(dueDate) : "--/--/----"}</p>
					</div>
				</div>
				<div className={styles.action}>
					<button onClick={() => openDrawer()}>Full view</button>
				</div>
			</div>
			{pokeData && (
				<CandidateActionsDrawer job={get(pokeData, "job")} poke={pokeData} />
			)}
		</>
	);
};
export default AccordionHeader;
