import React from "react";
import { ReactComponent as LeftIcon } from "static/icons/arrow-left.svg";
import cx from "classnames";
import styles from "./messaging-tool-email-view-header.module.scss";
import Skeleton from "react-loading-skeleton";
import MessagingToolEmailViewConversationActions from "../MessagingToolEmailViewConversationActions/MessagingToolEmailViewConversationActions";

const MessagingToolEmailViewHeader = ({
	onBack,
	isLoading,
	subject,
	emailData,
	onClickReply,
	onClickForward,
	hash
}) => {
	return (
		<header className={styles.header}>
			<button className={cx(styles.cta, styles.back)} onClick={onBack}>
				<LeftIcon className={styles.leftIcon} />
			</button>
			{isLoading ? (
				<Skeleton width={482} height={20} />
			) : (
				<h3 className={styles.title}>{subject}</h3>
			)}
			<MessagingToolEmailViewConversationActions
				onBack={onBack}
				emailData={emailData}
				onClickReply={onClickReply}
				onClickForward={onClickForward}
				hash={hash}
			/>
		</header>
	);
};

export default MessagingToolEmailViewHeader;
