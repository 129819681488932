export const displayAttendeesList = (attendeesList = [], userSender) => {
	let commonString = "";
	let restString = "";
	let first = userSender;
	let restAttendees = [];
	let objToReturn = {};
	const isValidArray = attendeesList.length !== 0;
	if (isValidArray) {
		first = attendeesList[0];
		restAttendees = attendeesList.slice(1);
		if (restAttendees.length !== 0) {
			restAttendees.map(item => {
				commonString += item.first_name + " " + item.last_name + "," + " ";
			});
			restString = commonString.slice(0, -2);
		}
	}
	const isValidRestArray = restAttendees.length !== 0;
	objToReturn = {
		isValidArray,
		isValidRestArray,
		restString,
		first,
		restAttendees
	};
	return objToReturn;
};

export const displayAttendeesDetails = (attendeesList = [], userSender) => {
	let commonString = "";
	let restString;
	if (attendeesList && attendeesList.length !== 0) {
		attendeesList.map(item => {
			commonString += item.first_name + " " + item.last_name + "," + " ";
		});
		restString = commonString.slice(0, -2);
		return restString;
	} else {
		return `${userSender.first_name} ${userSender.last_name}`;
	}
};
