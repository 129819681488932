import React from "react";
import styles from "./add-timesheet-entries-banner.module.scss";
import { ReactComponent as RefreshIcon } from "static/icons/refresh2.svg";
import { ReactComponent as NotificationIcon } from "static/icons/notification2.svg";
import { ReactComponent as ErrorIcon } from "static/icons/error-icon.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-icon.svg";
import { ReactComponent as InfoIcon } from "static/icons/Icon-info.svg";
import classNames from "classnames";
import { IconButton } from "common/IconButton";

const AddTimesheetEntriesBanner = ({ type, message, onClose }) => {
	return (
		<div className={classNames(styles.container, styles[`container_${type}`])}>
			<div className={styles.content}>
				{type === "success" && <RefreshIcon />}
				{type === "warning" && <NotificationIcon />}
				{type === "danger" && <ErrorIcon />}
				{type === "info" && (
					<div className={styles.infoIcon}>
						<InfoIcon width={20} height={20} />
					</div>
				)}
				{message}
			</div>
			<IconButton onClick={onClose} className={styles.closeButton}>
				<CloseIcon className={styles.closeIcon} width={12} height={12} />
			</IconButton>
		</div>
	);
};

export default AddTimesheetEntriesBanner;
