import React from "react";
import styles from "./custom-avatar.module.scss";
import cx from "classnames";
import Avatar from "common/Avatar/index";

const CustomAvatar = ({
	fullName,
	src,
	rootClassName,
	imgClassName,
	avatarClassName,
	avatarSize = 26,
	rootStyle,
	avatarStyle,
	variant
}) => {
	return src ? (
		<div style={rootStyle} className={cx(styles.imgContainer, rootClassName)}>
			<img
				className={cx(styles.img, imgClassName, {
					[styles.small]: variant === "small"
				})}
				src={src}
			/>
		</div>
	) : (
		<Avatar
			className={cx(styles.avatar, avatarClassName)}
			size={avatarSize}
			name={fullName}
			radius={0}
			style={avatarStyle}
		/>
	);
};

export default CustomAvatar;
