import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_EMAIL_SIGNATURE } from "config/api-endpoints";

const updateSignature = ({ body }) => {
	return client(UPDATE_EMAIL_SIGNATURE, {
		body
	});
};

export default function useUpdateEmailSignature() {
	return useMutation(updateSignature);
}
