import React from "react";
import * as yup from "yup";
import { QUESTIONNARE_TYPE_OF_FIELDS } from "./constants";
import { values } from "lodash";
import { getFileExtension } from "common/Functions";
import { ReactComponent as PdfIcon } from "static/icons/iconPdfQuestionnaire.svg";
import { ReactComponent as DocIcon } from "static/icons/iconDoc.svg";
import { ReactComponent as XlsIcon } from "static/icons/iconXls.svg";
import { ReactComponent as PptIcon } from "static/icons/iconPpt.svg";
import { ReactComponent as ImageIcon } from "static/icons/iconImage.svg";

const { MULTIPLE_CHOICE, FILE_UPLOAD, CHECKBOX } = QUESTIONNARE_TYPE_OF_FIELDS;

export const getQuestionnaireData = (formData, QuestionnaireData) => {
	const fieldsArray = Object.keys(formData)
		.map(key => {
			const [indexData, ...fieldParts] = key.split("_");
			const field = fieldParts.join("_");
			return { indexData: parseInt(indexData, 10), field };
		})
		.sort((a, b) => a.indexData - b.indexData)
		.map(item => item.field);

	return fieldsArray
		.map((field, indexData) => {
			const key = `${indexData}_${field}`;
			const item = QuestionnaireData?.find(
				(item, index) =>
					item.field === field && index.toString() === indexData.toString()
			);

			if (item) {
				const value =
					field === MULTIPLE_CHOICE &&
					!Array.isArray(formData[key]) &&
					formData[key]
						? [formData[key]]
						: formData[key];
				return value || value === false ? { ...item, value } : item;
			}
		})
		.filter(Boolean);
};

export const getQuestionnaireSchema = questionnaireData => {
	return questionnaireData?.reduce((acc, item, index) => {
		if (!item.is_required) return acc;
		if (
			(item.field === MULTIPLE_CHOICE && !item.one_answer_allowed) ||
			item.field === FILE_UPLOAD
		) {
			acc[`${index}_${item.field}`] = yup
				.array()
				.min(1, "This field is required")
				.required("This field is required");
		} else if (item.field === CHECKBOX) {
			acc[`${index}_${item.field}`] = yup
				.bool()
				.oneOf([true], "This field is required")
				.required("This field is required");
		} else {
			acc[`${index}_${item.field}`] = yup
				.mixed()
				.required("This field is required");
		}

		return acc;
	}, {});
};

export function formatData(data) {
	const questionnaireData = {};
	const formData = {};

	for (const key in data) {
		if (
			values(QUESTIONNARE_TYPE_OF_FIELDS).some(substring =>
				key.includes(substring)
			)
		) {
			questionnaireData[key] = data[key];
		} else {
			formData[key] = data[key];
		}
	}

	return { questionnaireData, formData };
}

export const uploadedFileIcon = fileName => {
	const fileType = getFileExtension(fileName);
	switch (fileType) {
		case "pdf":
			return <PdfIcon />;
		case "doc":
		case "docx":
			return <DocIcon />;
		case "png":
		case "jpg":
		case "jpeg":
		case "pjpeg":
			return <ImageIcon />;
		case "xls":
		case "xlsx":
			return <XlsIcon />;
		case "ppt":
		case "pptx":
			return <PptIcon />;
	}
};
