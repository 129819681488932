import React, { memo } from "react";
import { useForm, Controller } from "react-hook-form";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogTitle
} from "../../CreateNewSupplier/Dialog";
import s from "../../CreateNewSupplier/create-supplier.module.scss";
import useGetIndustries from "../../../api/filters/useGetIndustries";
import styles from "./industry-filter-dialog.module.scss";
import Select from "../../../shared/Select";

function IndustryFilterDialog({ isOpen, onClose, store }) {
	const { data, isLoading } = useGetIndustries();
	const { setIndustry, industry } = store(state => ({
		setIndustry: state.setIndustry,
		industry: state.industry
	}));

	const { handleSubmit, control } = useForm();

	const onSubmit = fields => {
		setIndustry(fields.industry);
		onClose();
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent onEscapeKeyDown={onClose}>
				<DialogTitle>Filter by Industry</DialogTitle>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name={"industry"}
						render={({ field: { onChange, value } }) => (
							<Select
								name={"industry"}
								onChange={onChange}
								value={value}
								options={data?.map(industry => ({
									value: industry._id,
									label: industry.name
								}))}
							/>
						)}
					/>

					<div className={s.dialogFooter}>
						<button onClick={onClose} className={s.cancelButton}>
							Cancel
						</button>
						<button
							disabled={isLoading}
							type={"submit"}
							className={s.mainButton}
						>
							{!industry ? "Add to filters" : "Update filter"}
						</button>
					</div>
				</form>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default memo(IndustryFilterDialog);
