import React from "react";
import styles from "./pages.module.scss";
import CareerPageCard from "../CareerPageCard/CareerPageCard";
import JobPageCard from "../JobPageCard/JobPageCard";
import MissionPageCard from "../MissionPageCard/MissionPageCard";

const Pages = ({
	careerPageData,
	jobPageData,
	isLoading,
	site_id,
	hasMultiSite,
	hasCareerPage,
	hasJobPage,
	hasMissionPage,
	missionPageData
}) => {
	return (
		<div className={styles.container}>
			{hasCareerPage && (
				<CareerPageCard
					data={careerPageData}
					className={styles.card}
					isLoading={isLoading}
					site_id={site_id}
					hasMultiSite={hasMultiSite}
				/>
			)}
			{hasJobPage && (
				<JobPageCard
					data={jobPageData}
					className={styles.card}
					isLoading={isLoading}
					site_id={site_id}
					hasMultiSite={hasMultiSite}
				/>
			)}
			{hasMissionPage && (
				<MissionPageCard
					data={missionPageData}
					className={styles.card}
					isLoading={isLoading}
					site_id={site_id}
					hasMultiSite={hasMultiSite}
				/>
			)}
		</div>
	);
};

export default Pages;
