import React from "react";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";
import styles from "./permanent-evaluations-custom-skeleton.module.scss";

const PermanentEvaluationsCustomSkeleton = ({
	width,
	height,
	style = { borderRadius: 8 },
	className
}) => {
	return (
		<Skeleton
			className={cx(styles.container, className)}
			style={style}
			width={width}
			height={height}
		/>
	);
};

export default PermanentEvaluationsCustomSkeleton;
