import React, { useEffect, Suspense } from "react";
import { useToggle } from "react-use";
import get from "lodash/get";
import { getData } from "../../utils/notesHelper";
import useNotesStore from "../../Store/useNotesStore";
import styles from "./notes.module.scss";
import Note from "modules/candidate/Profile/components/NotesCard/Note";
import { useInitializeFilterDrawer } from "./hooks/useInitializeFilterDrawer";
import NotesHeader from "./NotesHeader/NotesHeader";
import { NOTES_FILTER_ID } from "./util/constants";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import useFilterNotes from "./hooks/useFilterNotes";
import { ReactComponent as PrevIcon } from "static/icons/chevron-left.svg";
import { v4 as uuid } from "uuid";
import NoteDrawer from "./NoteDrawer";
import { validFiltersCount } from "./util/helpers";
import FiltersBar from "../Notes/FiltersBar/FiltersBar";
import { searchParser } from "config/helpers";
import { NOTES } from "modules/home/config";
import LoadingView from "./components/LoadingView/LoadingView";
import loadable from "loadable-components";

export const Pagination = loadable(() => import("common/NewPagination"));
export const EmptyNote = loadable(() =>
	import(
		"modules/candidate/Profile/components/NotesCard/components/EmptyNote/EmptyNote"
	)
);

const FilterDrawer = React.lazy(() =>
	import("common/FilterDrawer/FilterDrawer")
);

const ProfileNotes = ({ id, addNote, candidateData }) => {
	const { tab } = searchParser();
	const enabled = tab === NOTES;

	useInitializeFilterDrawer(id);
	useFilterNotes(id, true, enabled);
	const [showRecap, setShowRecap] = useToggle(true);
	const { getState, setShowDrawer, setOffset } = useFilters();

	const {
		showDrawer,
		data: resolvedData,
		isFetching,
		isLoading,
		offset,
		filters,
		query
	} = getState(NOTES_FILTER_ID);

	const {
		setActiveNoteIdx,
		activeNoteIdx,
		toggleDrawer,
		isDrawerOpen
	} = useNotesStore();

	const data = getData(isFetching, resolvedData);

	useEffect(() => {
		setActiveNoteIdx(0);
	}, [data]);

	const handleClose = () => {
		setShowDrawer(false);
	};

	const handleNoteClick = index => {
		toggleDrawer();
		setActiveNoteIdx(index);
	};

	const showPopup = () => {
		addNote({
			type: "CANDIDATE_NOTE",
			id: uuid(),
			canSelectCandidate: true,
			payload: {
				candidate: {
					value: candidateData?.user_id,
					label: `${candidateData?.personal_profile?.first_name} ${candidateData?.personal_profile?.last_name}`,
					profile_id: candidateData?.user_id
				}
			}
		});
	};

	const isFilterEmpty = () => {
		const definedFilters = filters.filter(({ value }) => {
			//Be careful about false value, filtering a filter with false value is a bug
			if (
				[undefined, null, "", 0].includes(value) ||
				(Array.isArray(value) && value.length === 0) ||
				(value !== null &&
					typeof value === "object" &&
					Object.keys(value).length === 0)
			) {
				return false;
			} else {
				return true;
			}
		});

		return definedFilters.length === 0 && query === "";
	};

	return (
		<div className={styles.noteContainer}>
			<div className={styles.container}>
				<NotesHeader
					onAddNoteClick={showPopup}
					className={styles.notesHeader}
					toggleRecap={setShowRecap}
					showRecap={showRecap}
					filterId={NOTES_FILTER_ID}
				/>
				{showRecap && validFiltersCount(filters) > 0 && (
					<FiltersBar filterId={NOTES_FILTER_ID} />
				)}
				{isLoading || isFetching ? (
					<div className={styles.section__notes}>
						<LoadingView />
					</div>
				) : get(resolvedData, "data", []).length > 0 ? (
					<>
						<p className={styles.results_count}>
							{get(resolvedData, "pagination.total") === 0 ? (
								"No result found"
							) : (
								<span className={styles.results_count}>
									<span className={styles.greenText}>
										{get(resolvedData, "pagination.total")?.toLocaleString()}
									</span>
									&nbsp;
									{get(resolvedData, "pagination.total") > 1
										? "notes"
										: "note"}{" "}
									in total
								</span>
							)}
						</p>
						<div className={styles.section__notes}>
							{get(resolvedData, "data", []).length > 0
								? get(resolvedData, "data", []).map((note, index) => {
										return (
											<Note
												key={note._id}
												note={note}
												onClick={() => handleNoteClick(index)}
											/>
										);
								  })
								: null}
						</div>
						<div style={{ position: "relative" }}>
							<Pagination
								previousLabel={<PrevIcon />}
								nextLabel={<PrevIcon style={{ transform: "rotate(180deg)" }} />}
								total={get(resolvedData, "pagination.total")}
								size={get(resolvedData, "pagination.size")}
								handlePageClick={page => {
									setOffset(page * get(resolvedData, "pagination.size"));
								}}
								offset={offset}
								forcePage={Math.ceil(
									get(resolvedData, "pagination.offset") /
										get(resolvedData, "pagination.size")
								)}
								pageRangeDisplayed={
									get(resolvedData, "pagination.total") > 1000 ? 6 : undefined
								}
								marginPagesDisplayed={
									get(resolvedData, "pagination.total") > 1000 ? 0 : undefined
								}
								className={styles.pagination}
							/>
						</div>
					</>
				) : (
					<div className={styles.empty__section__note}>
						<EmptyNote
							message={
								isFilterEmpty()
									? "Start by adding a note"
									: "No notes matching your search criteria"
							}
						/>
					</div>
				)}
			</div>
			{isDrawerOpen && (
				<NoteDrawer
					{...{
						isDrawerOpen,
						toggleDrawer,
						activeNote: get(resolvedData, "data")[activeNoteIdx],
						activeNoteIdx: activeNoteIdx,
						displayFooter: false
					}}
				/>
			)}

			<Suspense fallback={null}>
				{showDrawer && (
					<FilterDrawer
						onlyList={true}
						displayDrawer={showDrawer}
						onClose={handleClose}
					/>
				)}
			</Suspense>
		</div>
	);
};

export default ProfileNotes;
