/* eslint-disable react/display-name */ import React from "react";
import BodyClassName from "react-body-classname";
import loadable from "loadable-components";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { colors } from "config/styles";

const TermsAndConditionsText = loadable(() =>
	import(
		/* webpackChunkName: "termsandconditionstext" */ "./TermsAndConditionsText"
	)
);

const TOC = loadable(() =>
	import(/* webpackChunkName: "termsandconditionstoc" */ "./TOC")
);

const FlexContainer = styled.div`
	display: flex;
	background-color: ${colors.white};
	margin-right: auto;
	margin-left: auto;
	max-width: 1170px;
	@media only screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

const Content = styled.div`
	padding: 50px 40px;
`;

export default ({
	isLoggedIn,
	currentRoute,
	activeSection,
	handleSectionChange
}) => {
	const bodyClass = !isLoggedIn ? "fluid-container" : "";
	return (
		<BodyClassName
			className={`static-page terms-conditions-page gray-bg ${bodyClass}`}
		>
			<div className="static-page-container">
				{!isLoggedIn && (
					<Helmet>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1"
						/>
					</Helmet>
				)}

				<FlexContainer>
					<div className="sidebar">
						<TOC
							activeSection={activeSection}
							handleSectionChange={handleSectionChange}
						/>
					</div>
					<Content>
						<h1>GENERAL TERMS AND CONDITIONS – wiggli.io </h1>
						<TermsAndConditionsText
							currentRoute={currentRoute}
							activeSection={activeSection}
						/>
					</Content>
				</FlexContainer>
			</div>
		</BodyClassName>
	);
};
