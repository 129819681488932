import React from "react";
import styles from "./thumbs-label.module.scss";
import { ReactComponent as RedThumbs } from "static/icons/thumbs-down.svg";
import { ReactComponent as OrangeThumbs } from "static/icons/thumbs-up2.svg";
import { ReactComponent as GreenThumbs } from "static/icons/thumbs-up.svg";
import cx from "classnames";

const ThumbsLabel = ({ type, suffix, addBackground, className }) => {
	let feedback = "";

	if (type === "green") {
		feedback = <GreenThumbs className={styles.icon} />;
	} else if (type === "orange") {
		feedback = <OrangeThumbs className={styles.icon} />;
	} else {
		feedback = <RedThumbs className={styles.icon} />;
	}

	return (
		<div
			className={cx(styles.container, className, {
				[styles.containerWithBackground]: addBackground
			})}
		>
			{feedback}{" "}
			{suffix && <span className={cx(styles.suffix, className)}>{suffix}</span>}
		</div>
	);
};

export default ThumbsLabel;
