import React, { useEffect } from "react";
import { browserHistory } from "react-router";
import loadable from "loadable-components";
import get from "lodash/get";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import FreelancerHeader from "./components/FreelancerHeader";
import {
	viewTypes,
	useFreelancerFilters
} from "./components/FreelancerHeader/freelancersStore";
import styles from "./components/FreelancerHeader/freelancersHeader.module.scss";

const PendingActivation = loadable(() =>
	import("./pendingActivation/PendingActivation")
);

const FreelancersTab = loadable(() =>
	import("./components/FreelancersTab/FreelancersTab")
);

export const getFreelancersListRoute = () => {
	return "/freelancers/list";
};

export const getFreelancersListPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const FreelancerList = () => {
	const { activeView, setActiveView } = useFreelancerFilters();
	const handleViewChange = view => {
		setActiveView(view);
		browserHistory.replace(`${getFreelancersListRoute()}?view=${view}`);
	};

	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([
			{ name: "Temporary" },
			{
				name: "Freelancers",
				path: "/freelancers/list"
			}
		]);

		setActiveView(
			get(
				browserHistory.getCurrentLocation(),
				"query.view",
				viewTypes.my_freelancers
			)
		);
	}, []);

	return (
		<div className={styles.root}>
			<FreelancerHeader
				activeView={activeView}
				setActiveView={setActiveView}
				handleViewChange={handleViewChange}
			/>
			<div className={styles.vacanciesContent}>
				{activeView === viewTypes.pending_activation && <PendingActivation />}
				{activeView === viewTypes.my_freelancers && <FreelancersTab />}
			</div>
		</div>
	);
};

export default FreelancerList;
