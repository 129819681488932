import React from "react";
import { ReactComponent as ArrowDown } from "static/icons/arrow-right-new.svg";

const ArrowComponent = ({ width, height, stroke, color, className }) => {
	return (
		<ArrowDown
			width={width}
			height={height}
			style={{ color: color }}
			strokeWidth={stroke}
			className={className}
		/>
	);
};

export default ArrowComponent;
