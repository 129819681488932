import styled from "styled-components";
import { colors } from "config/styles";

export const SearchResultsContainer = styled.div`
	width: 100%;
	margin-top: 50px;
	.results-count {
		color: ${colors.inputColor};
		font-size: 15px;
		font-weight: 400;
		line-height: normal;
		text-align: right;
		margin-right: 25px;
		&.jobs {
			height: 28px;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			align-items: center;
			position: relative;
			top: -5px;
			right: 22px;
			.loader {
				margin-bottom: unset;
				margin-right: 6px;
				span {
					width: 28px;
					height: 28px;
				}
			}
		}
	}
	.results-container {
		min-height: 595px;
		.results {
			display: flex;
			align-items: center;
			margin-top: 10px;
			flex-wrap: wrap;
			&.jobs {
				margin-top: unset;
			}
		}
	}
`;
export const EmptySearchContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 122px;

	img {
		width: 114px;
		height: 71px;
		margin-bottom: 15px;
	}
	.title {
		font-size: 16px !important;
		font-weight: bold;
		color: #242f43;
		text-align: center;
	}
	.paragraph {
		color: ${colors.inputColor};
		font-size: 14px;
		font-weight: normal;
	}
`;

export const SearchLimitMessage = styled.span`
	font-size: 14px;
	font-weight: normal;
	line-height: normal;
	color: ${colors.grayText};
	letter-spacing: normal;
	text-align: center;
	position: absolute;
	top: 23%;
	left: 50%;
	transform: translateX(-50%);
`;

export const JobItem = styled.div`
	background-color: ${colors.white};
	border-radius: 4px;
	box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
	border: solid 0.5px ${colors.grayBorder};
	width: 48%;
	height: 265px;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: 0.3s;
	position: relative;
	cursor: pointer;

	&:hover {
		box-shadow: 0px 5px 5px 0.1px rgba(197, 199, 206, 0.4);
	}
	.content {
		padding: 15px;
		.head {
			display: flex;
			align-items: center;
			height: 94px;
			margin-left: -15px;
			margin-right: -15px;
			margin-top: -15px;
			background-color: #f7f8f9;
			padding: 15px;
			.logo {
				width: 56px;
				height: 56px;
				border-radius: 3px;
				margin-right: 6px;
				img {
					width: 100%;
					height: 100%;
					border-radius: 3px;
				}
			}
			.empty-logo {
				padding: 5px;
				width: 40px;
				height: 40px;
				border-radius: 3px;
				background-color: #ebeef0;
				margin-right: 6px;
				svg {
					width: 100%;
					height: 100%;
					fill: #c3c3c3;
				}
			}
			.title {
				color: #242f43;
				font-weight: bold !important;
				font-size: 15px;
				margin-left: 4px;
			}
			.location-date-container {
				margin-top: 2px;
				display: flex;
				align-items: center;
				color: #3d4b60 !important;
				font-size: 12px !important;

				.g-tool {
					font-size: 10px !important;
					color: #3d4b60 !important;
					margin: unset !important;
				}
				.location {
					margin-left: 3px;
					margin-right: 6px;
					font-weight: normal;
				}
				.icon-local,
				.icon-inverted-watch {
					color: ${colors.primaryColor};
					position: relative;
					top: 1px;
					left: -1px;
				}
				.icon-inverted-watch {
					margin-right: 2px;
				}
			}
			.company-container {
				margin-top: 2px;
				display: flex;
				align-items: center;
				color: #3d4b60 !important;
				font-size: 12px !important;
				.company {
					margin-left: 3px;
					margin-right: 6px;
					font-weight: normal;
					text-transform: capitalize;
					svg {
						width: 13px;
						height: 15px;
						position: relative;
						top: 1px;
						left: -3px;
					}
				}
			}
			.skeleton {
				margin-left: 20px;
				width: 100%;
			}

			.dots {
				cursor: pointer;
				text-align: center;
				margin-left: 8px;
				position: absolute;
				top: 12px;
				right: 10px;
				.dot {
					width: 5px;
					height: 5px;
					background-color: ${colors.bullcolor};
					border-radius: 50%;
					vertical-align: middle;
					display: inline-block;
					margin-right: 3px;
				}
				&:hover {
					.dot {
						background-color: ${colors.inputColor};
					}
				}
			}
		}
		.description {
			margin-top: 16px;
			font-size: 13.3px;
			color: #3d4b60;
			line-height: 1.53;
			font-weight: normal;
		}
		.skills {
			margin-top: 16px;
			display: flex;
			flex-wrap: wrap;
			.skill {
				padding: 3px 8px;
				background-color: #eaebef;
				color: #3d4b60;
				font-size: 11px;
				font-weight: normal;
				margin-right: 8px;
				border-radius: 3px;
				margin-bottom: 8px;
			}
		}
		.more-skills {
			color: #8a95a1;
			font-size: 13px;
			font-weight: normal;
		}
		.skeleton-content {
			margin-top: 20px;
		}
	}

	.footer {
		padding: 14px;
		height: 43px;
		background-color: #ffffff;
		color: #8a95a1;
		font-size: 13.3px;
		font-weight: normal;
		position: absolute;
		bottom: 1px;
		width: 100%;
		&.skeleton {
			margin-top: 30px;
		}
		.icons {
			float: right;
			svg {
				width: 50px;
			}
		}
	}
`;

export const SkeletonContainer = styled.div`
	padding: 15px;
`;

export const Backdrop = styled.div`
	opacity: 1;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 9999;
	background-color: transparent;
	backdrop-filter: blur(2px);
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.flag-container {
		svg {
			width: 75px;
			height: 75px;
		}
		margin-bottom: 10px;
		margin-top: 20px;
	}
	.title {
		color: #242f43;
		font-weight: normal;
		margin-bottom: 8px;
		text-align: center;
		&.primary {
			color: ${colors.primaryColor};
			font-size: 26px;
			margin-bottom: 20px;
		}
		.title-track {
			font-weight: bold;
			color: #293d58;
			position: relative;
			font-size: 14px;
		}
	}
	.content {
		color: #242f43;
		font-weight: normal;
		font-size: 13px;
		line-height: 1.74;
		text-align: center;
		margin-bottom: 20px;
	}
	.cta {
		color: #242f43;
		font-weight: bold;
		font-size: 13px;
		margin-bottom: 14px;
	}
	.actions {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			margin-right: 24px;
			height: 51px;
			padding: 14px 20px !important;
			font-size: 16px !important;
			text-transform: unset !important;
		}
		margin-bottom: 20px;
	}
	.title-track {
		font-weight: bold;
		color: #293d58;
		position: relative;
	}
`;
