import React, { useRef } from "react";
import { Select } from "@rebass/forms";
import { Controller } from "react-hook-form";
import { useToggle } from "react-use";
import Skeleton from "react-loading-skeleton";
import Portal from "common/Portal";
import RSelect from "modules/consultancy/shared/Select";
import useGetIndustries from "../../../api/filters/useGetIndustries";
import useGetLanguages from "../../../api/filters/useGetLangauges";
import FunctionsDialog from "../../../search/filters/ExpertiseFilter/FunctionsDialog";
import SkillsDialog from "../../../search/filters/ExpertiseFilter/SkillsDialog";
import AddressBoxModal from "common/AddressBox/AddressBoxModal";
import s from "./rest-fields-form.module.scss";
import AddressComposer from "../../../../../common/AddressComposer";
import Tag from "../../../view-supplier/Tag";
import { PencilIcon } from "@heroicons/react/outline";

const servicesOptions = [
	{
		label: "Contingent workers",
		value: "Contingent workers"
	},
	{ label: "Employed contractors", value: "Employed contractors" },
	{
		label: "Independent contractors (Freelancers)",
		value: "Independent contractors (Freelancers)"
	},
	{
		label: "Temp agency workers (interim)",
		value: "Temp agency workers (interim)"
	},
	{ label: "Permanent recruitment", value: "Permanent recruitment" }
];

function RestFieldsForm({
	register,
	control,
	functions,
	skills,
	data,
	errors,
	address
}) {
	const autoComplete = useRef(
		new window.google.maps.places.AutocompleteService()
	);
	const { data: industryData, isLoading } = useGetIndustries();
	const { data: languagesData } = useGetLanguages();
	const [showCategoriesDialog, toggleCategoriesDialog] = useToggle(false);
	const [showSkillsDialog, toggleSkillsDialog] = useToggle(false);
	const [showAddressDialog, toggleAddressDialog] = useToggle(false);

	const handleLoadOptions = (input, cb) => {
		if (!input) {
			return Promise.resolve({ options: [] });
		}

		autoComplete.current.getPlacePredictions(
			{
				input,
				types: ["(regions)"]
			},
			function(data) {
				cb(null, {
					options: data
						? data.map(d => ({ label: d.description, value: d.place_id }))
						: [],
					complete: false
				});
			}
		);
	};

	return (
		<div className={s.root}>
			<div className={s.fieldContainer}>
				<h4>Industries</h4>
				{isLoading ? (
					<Skeleton height={50} />
				) : (
					<Controller
						name={"industry"}
						control={control}
						render={({ field: { onChange, value } }) => (
							<RSelect
								{...register("industry")}
								defaultValue={data?.industry?._id}
								onChange={onChange}
								value={value}
								options={industryData?.map(industry => ({
									value: industry._id,
									label: industry.name
								}))}
							/>
						)}
					/>
				)}
				<div className={s.fieldError}>
					{errors["industry"] ? errors["industry"]?.message : null}
				</div>
			</div>
			<div className={s.fieldContainer}>
				<h4>Services proposed</h4>
				<Controller
					control={control}
					name={"services"}
					render={({ field: { onChange, value } }) => (
						<RSelect
							className={s.SelectControl}
							searchable={false}
							value={value}
							onChange={onChange}
							options={servicesOptions}
						/>
					)}
				/>
				<div className={s.fieldError}>
					{errors["services"] ? errors["services"]?.message : null}
				</div>
			</div>
			<div className={s.fieldContainer}>
				<h4>Covered categories</h4>
				<div>
					{(functions ?? data?.functions)?.map(f => (
						<Tag key={f._id}>{f?.category?.label ?? f.parent_sector?.name}</Tag>
					))}
					<button type={"button"} onClick={() => toggleCategoriesDialog(true)}>
						<PencilIcon width={16} height={16} />
					</button>
				</div>
				<Controller
					name={"functions"}
					control={control}
					render={({ field: { onChange, value } }) => (
						<Portal>
							<FunctionsDialog
								isOpen={showCategoriesDialog}
								onClose={() => toggleCategoriesDialog(false)}
								onSubmit={onChange}
								selected={value}
								config={{
									title: "Covered categories",
									saveAction: "Save"
								}}
							/>
						</Portal>
					)}
				/>
				<div className={s.fieldError}>
					{errors["functions"] ? errors["functions"]?.message : null}
				</div>
			</div>
			<div className={s.fieldContainer}>
				<h4>Covered skills</h4>
				<div>
					{(skills ?? data?.skills)?.map(s => (
						<Tag key={s._id}>{s.name}</Tag>
					))}
					<button type={"button"} onClick={() => toggleSkillsDialog(true)}>
						<PencilIcon width={16} height={16} />
					</button>
				</div>
				<Controller
					name={"skills"}
					control={control}
					render={({ field: { onChange, value } }) => (
						<Portal>
							<SkillsDialog
								isOpen={showSkillsDialog}
								onClose={() => toggleSkillsDialog(false)}
								onSubmit={onChange}
								selected={data?.skills ?? value}
								functions={functions ?? data?.functions}
								config={{
									title: "Covered skills",
									saveAction: "Save"
								}}
							/>
						</Portal>
					)}
				/>
				<div className={s.fieldError}>
					{errors["skills"] ? errors["skills"]?.message : null}
				</div>
			</div>
			<div className={s.fieldContainer}>
				<h4>Number of employees</h4>
				<Select {...register("size")}>
					<option value={"0-50"}>0 - 50</option>
					<option value={"51-100"}>51 - 100</option>
					<option value={"101-200"}>101 - 200</option>
					<option value={"201-300"}>201 - 300+</option>
				</Select>
				<div className={s.fieldError}>
					{errors["size"] ? errors["size"]?.message : null}
				</div>
			</div>
			<div className={s.fieldContainer}>
				<h4>HQ address</h4>
				<div>
					<AddressComposer addressData={address ?? data} />
					<button type={"button"} onClick={() => toggleAddressDialog(true)}>
						<PencilIcon width={16} height={16} />
					</button>
				</div>
				<Portal>
					<Controller
						name={"address"}
						control={control}
						render={({ field: { onChange, value } }) => (
							<AddressBoxModal
								active={showAddressDialog}
								onClose={toggleAddressDialog}
								onSave={address => {
									toggleAddressDialog(false);
									onChange(address);
								}}
								requiredFields={["street", "number", "city", "zip", "country"]}
								isRequired={true}
								title="Edit Address"
								label="Company address"
								currentAddress={value}
							/>
						)}
					/>
				</Portal>
				<div className={s.fieldError}>
					{errors["address"] ? errors["address"]?.message : null}
				</div>
			</div>
			<div className={s.fieldContainer}>
				<h4>Other offices in</h4>
				<Controller
					control={control}
					name={"offices"}
					render={({ field: { onChange, value } }) => (
						<RSelect
							async={true}
							value={value}
							loadOptions={handleLoadOptions}
							onChange={onChange}
							cache={false}
							initialValue={null}
							multi={true}
							placeholder={"Search for cities..."}
						/>
					)}
				/>
				<div className={s.fieldError}>
					{errors["offices"] ? errors["offices"]?.message : null}
				</div>
			</div>
			<div className={s.fieldContainer}>
				<h4>Geographical coverage</h4>
				<Controller
					control={control}
					name={"geo_coverage"}
					render={({ field: { onChange, value } }) => (
						<RSelect
							async={true}
							value={value}
							loadOptions={handleLoadOptions}
							onChange={onChange}
							cache={false}
							initialValue={null}
							multi={true}
							placeholder={"Search for cities..."}
						/>
					)}
				/>
				<div className={s.fieldError}>
					{errors["geo_coverage"] ? errors["geo_coverage"]?.message : null}
				</div>
			</div>
			<div className={s.fieldContainer}>
				<h4>Spoken languages</h4>
				<Controller
					control={control}
					name={"languages"}
					render={({ field: { onChange, value } }) => (
						<RSelect
							value={value}
							closeOnSelect={false}
							onChange={onChange}
							options={
								languagesData
									? languagesData.map(l => ({
											label: l.language,
											value: l._id
									  }))
									: []
							}
							placeholder={"Add languages..."}
						/>
					)}
				/>
				<div className={s.fieldError}>
					{errors["languages"] ? errors["languages"]?.message : null}
				</div>
			</div>
		</div>
	);
}

export default RestFieldsForm;
