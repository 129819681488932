import React, { memo, useState, useEffect, useContext } from "react";
import { Tabs, Tab, TabPanels } from "@reach/tabs";
import { Box, Flex } from "rebass";
import { withRouter } from "react-router";
import _get from "lodash/get";
import { isEmpty } from "lodash";
import QS from "query-string";
import { browserHistory } from "react-router";

import Skills from "modules/cv/components/Tabs/Skills";
import Languages from "modules/cv/components/Tabs/Languages";
import {
	Container,
	Background,
	StyledTabsList,
	StyledTabPanel,
	CvContainer
} from "./ProfileStyle";
import FrontSheet from "./FrontSheet";
import History from "./History";
import HideFromSearchModal from "common/ProfileActions/HideFromSearchModal";
import HidePermanentlyModal from "common/ProfileActions/HidePermanentlyModal";
import { SearchContext } from "common/newSearch/context";
import AddShortListModal from "./Modals/AddShortListModal";
import CandidateView from "./Header/CandidateView";
import ClientView from "./Header/ClientView";
import AddNoteModal from "./Modals/AddNoteModal";
import RemoveShortlistModal from "modules/vacancy/components/vacancyView/RemoveShortlistModal";
import PreferencesModal from "./Modals/PreferencesModal";
import PreferredLocationModal from "./Modals/PreferredLocationModal";
import LanguagesModal from "common/newSearch/Modals/LanguagesModal";
import SkillsModal from "common/newSearch/Modals/SkillsModal";
import { ListProvider } from "common/editablelist/Next/listContext";
import FunctionsModal from "modules/job/components/jobprofile/Functions/FunctionsModal";
import {
	UPDATE_LANGUAGES,
	UPDATE_FUNCTIONS,
	UPDATE_SKILLS
} from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";
import FirstSigninModal from "./Modals/FirstSigninModal";

import {
	FIRST_LOGIN,
	ADD_SHORTLIST,
	ADD_NOTE,
	HIDE_FROM_RESULTS,
	HIDE_PERMANENTLY,
	REMOVE_SHORTLIST,
	INACTIVE,
	PASSIVE
} from "config";
import PreferencesBanner from "./Banner/PreferencesBanner";
import FSLBanner from "./Banner/FSLBanner";
import Functions from "modules/job/components/jobprofile/Functions/Functions";
import CandidateProfileModal from "./candidateProfileModal";
import UnsubscribeModal from "modules/candidate/components/unsubscribe/UnsubscribeModal";
import { searchParser } from "config/helpers";
import ProfileClientView from "./profileClientView";
import CvContent from "./profileClientView/CvViewer";
import Portal from "common/Portal";

function ProfileContainer({
	user,
	functions,
	headline,
	isAnonymous = false,
	toggleModal,
	reference,
	templateId,
	pdf_doc,
	preference,
	history,
	numberPokes,
	vacancies,
	location: { query },
	profile_id,
	is_hidden_permanently = false,
	is_hidden_from_search = false,
	fetchProfile,
	vacancyId,
	isShortlisted = false,
	isClient,
	languages,
	skills,
	viewProfile,
	modalName,
	cv_docs,
	isFetching,
	canEdit,
	canPoke,
	canShortlist,
	cardLabels,
	mainFunction,
	totalViews,
	lastViewedBy,
	usersViewsData,
	linkedInProfile,
	active_company,
	refetchProfileData,
	userId,
	createdBy,
	canResendActivationEmail,
	canDelete
}) {
	const [tabIndex, setTabIndex] = useState(0);
	const [displayPreferencesBanner, setDisplayPreferencesBanner] = useState(
		true
	);

	const [displayFSLBanner, setDisplayFSLBanner] = useState(true);
	const { state } = useContext(SearchContext);

	let template = _get(state, "templates", []).find(t => t._id === templateId);

	const shortlistId = _get(query, "shortlist", "");
	const search = QS.parse(window.location.search);
	const [
		displayHidePermanentlyModal,
		setDisplayHidePermanentlyModal
	] = useState(false);
	const [displayHideFromSearchModal, setDisplayHideFromSearchModal] = useState(
		false
	);
	const [displayShortlistModal, setDisplayShortlistModal] = useState(false);
	const [displayAddNoteModal, setDisplayAddNoteModal] = useState(false);
	const [
		displayRemoveShortlistModal,
		setDisplayRemoveShortlistModal
	] = useState(false);

	const [editPreferencesModal, setEditPreferencesModal] = useState(false);
	const [editPreferredLocationModal, setEditPreferredLocationModal] = useState(
		false
	);

	const [displayLanguagesModal, setDisplayLanguagesModal] = useState(false);
	const [displaySkillsModal, setDisplaySkillsModal] = useState(false);
	const [displayFunctionsModal, setDisplayFunctionsModal] = useState(false);

	const [displayFirstSigninModal, setDisplayFirstSigninModal] = useState(
		Boolean(!_get(search, "from_poke", true))
	);
	const [displayCandidateModal, setDisplayCandidateModal] = useState(false);
	const [displayUnsubscribeModal, setDisplayUnsubscribeModal] = useState(false);
	const [employmentType, setEmploymentType] = useState(
		_get(preference, "employment_types", [])
	);

	useEffect(() => {
		if (_get(query, "unsubscribe", "")) {
			setDisplayUnsubscribeModal(true);
		}
	}, []);

	const setModal = () => {
		if (functions.length === 0) {
			setDisplayFunctionsModal(true);
		} else if (skills.length === 0) {
			setDisplaySkillsModal(true);
		} else {
			setDisplayLanguagesModal(true);
		}
	};
	useEffect(() => {
		const hash = window.location.hash;
		if (hash) {
			handleTabsChange(Number(hash.replace("#", "") || 0));
			if (search) {
				switch (search.action) {
					case "location":
						setEditPreferredLocationModal(true);
						browserHistory.push("/my-cv");
						break;
					case "preferences":
						setEditPreferencesModal(true);
						browserHistory.push("/my-cv");
						break;
					case "skillsLanguages":
						setModal();
						browserHistory.push("/my-cv");
						break;
					default:
						break;
				}
			}
		}
	}, [window.location.hash, window.location.search]);

	const TabListWithBackground = props => (
		<Background className={props.className}>
			<StyledTabsList {...props} />
		</Background>
	);

	let tabs = ["Cv Content", "Preferences", "Functions, Skills & Languages"];
	const isEmptyPreferences = isEmpty(
		_get(preference, "preferred_location", [])
	);

	const isEmptyLangSkillsFnc =
		isEmpty(languages) && isEmpty(skills) && isEmpty(functions);

	if (isClient) {
		if (isEmptyPreferences) {
			tabs = tabs.filter(t => t !== "Preferences");
		}
		if (isEmptyLangSkillsFnc) {
			tabs = tabs.filter(t => t !== "Functions, Skills & Languages");
		}
	}

	const isOwnProfile = !!user;

	if (!isOwnProfile) {
		const counter = numberPokes ? `(${numberPokes})` : "";
		tabs.push(`History ${counter}`);
	}

	const handleTabsChange = index => {
		window.history.replaceState({}, "", `#${index}`);
		setTabIndex(index);
	};

	const Header = isOwnProfile ? CandidateView : ClientView;

	const getSkillsCategories = skills => {
		let categories = [
			...new Set(
				skills.map(s => _get(s, "parent_sector._id", undefined)).filter(Boolean)
			)
		];

		return categories;
	};
	const saveLanguages = data => {
		AxiosHelper.post({
			url: UPDATE_LANGUAGES,
			data: { languages: data },
			isModal: true,
			toastMessage: "Successfully updated your languages."
		}).then(() => {
			setDisplayLanguagesModal(false);
			viewProfile();
		});
	};
	const saveSkills = data => {
		const skills = data.map(d => {
			delete d.must_have;
			return d;
		});
		AxiosHelper.post({
			url: UPDATE_SKILLS,
			data: { skills },
			isModal: true,
			toastMessage: "Successfully updated your skills."
		}).then(() => {
			setDisplaySkillsModal(false);
			viewProfile();
		});
	};

	const saveFunctions = data => {
		const functions = data.map(d => {
			return {
				is_main: d.is_main,
				name: d.name,
				position: d.position,
				seniority: d.seniority,
				_id: d._id,
				sector_id: d.sector._id
			};
		});

		AxiosHelper.post({
			url: UPDATE_FUNCTIONS,
			data: { functions },
			isModal: true,
			toastMessage: "Successfully updated your functions."
		}).then(() => {
			setDisplayFunctionsModal(false);
			viewProfile();
		});
	};

	const handleChangeEmployment = name => {
		let data = employmentType;
		if (data.indexOf(name) === -1) {
			data = [...data, name];
		} else {
			data = data.filter(type => type !== name);
		}

		return setEmploymentType(data);
	};

	const renderFrontSheet = () => (
		<StyledTabPanel>
			<Container>
				<FrontSheet
					isOwnProfile={isOwnProfile}
					preference={preference}
					displayPreferencesModal={() => setEditPreferencesModal(true)}
					displayPreferredLocationModal={() => {
						setEditPreferredLocationModal(true);
					}}
				/>
			</Container>
		</StyledTabPanel>
	);

	const renderSkillsLangsFnc = () => (
		<StyledTabPanel>
			<Container>
				<Functions
					functions={functions}
					ownCV={!isAnonymous}
					isPermanent={true}
					canUpdateFunctions={!isClient}
					isClient={isClient}
					showModal={() => setDisplayFunctionsModal(true)}
				/>

				<Skills
					isPermanent={true}
					ownCV={!isAnonymous}
					isClient={isClient}
					displayPermanentModal={() => setDisplaySkillsModal(true)}
				/>
				<Languages
					isPermanent={true}
					ownCV={!isAnonymous}
					isClient={isClient}
					displayPermanentModal={() => setDisplayLanguagesModal(true)}
				/>
			</Container>
		</StyledTabPanel>
	);

	const getActions = () => {
		let actions = [];

		if (
			headline.candidate_status !== INACTIVE &&
			headline.candidate_status !== PASSIVE
		) {
			actions.push({
				key: ADD_SHORTLIST,
				label: "Add to sourced",
				action: () => setDisplayShortlistModal(true)
			});
		}

		let searchString = searchParser();
		if (_get(searchString, "shortlist") && isShortlisted) {
			actions.push({
				key: REMOVE_SHORTLIST,
				label: "Remove from shortlist",
				action: () => setDisplayRemoveShortlistModal(true)
			});
		}
		actions.push({
			key: ADD_NOTE,
			label: "Add note to profile",
			action: () => setDisplayAddNoteModal(true)
		});
		if (!is_hidden_permanently) {
			actions.push({
				key: HIDE_PERMANENTLY,
				label: "Hide permanently",
				action: () => setDisplayHidePermanentlyModal(true)
			});
			if (templateId && !is_hidden_from_search) {
				actions.push({
					key: HIDE_FROM_RESULTS,
					label: "Hide from results",
					action: () => setDisplayHideFromSearchModal(true)
				});
			}
		}

		return actions;
	};

	const closeFirstSigningModal = () => {
		setDisplayFirstSigninModal(false);
		localStorage.removeItem("companyLogo");
		localStorage.removeItem("companyName");
		localStorage.removeItem("poke_id");
	};

	const onEditFirstSigningModal = () => {
		window.location.hash = 2;
		setDisplayFirstSigninModal(false);
		localStorage.removeItem("companyLogo");
		localStorage.removeItem("companyName");
	};

	return (
		<Box>
			{isClient ? (
				<ProfileClientView
					functions={functions}
					preference={preference}
					languages={languages}
					skills={skills}
					headline={headline}
					pdf_doc={pdf_doc}
					isAnonymous={isAnonymous}
					reference={reference}
					profileId={profile_id}
					canEdit={canEdit}
					canPoke={canPoke}
					canShortlist={canShortlist}
					profileActions={getActions()}
					isFetching={isFetching}
					cardLabels={cardLabels}
					mainFunction={mainFunction}
					totalViews={totalViews}
					lastViewedBy={lastViewedBy}
					usersViewsData={usersViewsData}
					linkedInProfile={linkedInProfile}
					active_company={active_company}
					refetchProfileData={refetchProfileData}
					userId={userId}
					createdBy={createdBy}
					canResendActivationEmail={canResendActivationEmail}
					canDelete={canDelete}
				/>
			) : (
				<React.Fragment>
					<Header
						user={user}
						headline={headline}
						isAnonymous={isAnonymous}
						toggleModal={toggleModal}
						reference={reference}
						templateId={templateId}
						locationQuery={query}
						profile_id={profile_id}
						showHideFromSearch={() => setDisplayHideFromSearchModal(true)}
						showHidePermanently={() => setDisplayHidePermanentlyModal(true)}
						is_hidden_permanently={is_hidden_permanently}
						is_hidden_from_search={is_hidden_from_search}
						displayShortlistModal={() => setDisplayShortlistModal(true)}
						displayAddNoteModal={() => setDisplayAddNoteModal(true)}
						displayRemoveShortlistModal={() =>
							setDisplayRemoveShortlistModal(true)
						}
						displayCandidateModal={() => {
							setDisplayCandidateModal(true);
						}}
						isShortlisted={isShortlisted}
						viewProfile={viewProfile}
						cv_docs={cv_docs}
					/>
					<Tabs index={tabIndex} onChange={handleTabsChange}>
						<TabListWithBackground
							className={
								isOwnProfile ? "view-candidate-tabs" : "view-client-tabs"
							}
						>
							{tabs.map(name => (
								<Tab key={name}>{name}</Tab>
							))}
						</TabListWithBackground>

						<CvContainer>
							{tabIndex === 1
								? !_get(user, "preferences_completed") && (
										<PreferencesBanner
											displayPreferencesBanner={displayPreferencesBanner}
											onClose={() => setDisplayPreferencesBanner(false)}
											tabIndex={tabIndex}
											isClient={isClient}
										/>
								  )
								: !_get(user, "functions_skills_languages_completed") && (
										<FSLBanner
											displayFSLBanner={displayFSLBanner}
											onClose={() => setDisplayFSLBanner(false)}
											tabIndex={tabIndex}
											isClient={isClient}
										/>
								  )}
						</CvContainer>

						<TabPanels>
							<Flex sx={{ padding: "0 15px" }}>
								<Box
									bg={isClient && "#ffffff"}
									width={isClient ? 8 / 12 : 12 / 12}
									p={isClient ? 22 : 0}
									mr={isClient ? 16 : 0}
									sx={{
										borderRadius: isClient ? "4px" : "0",
										boxShadow: isClient
											? "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)"
											: "none",
										backgroundColor: isClient
											? "rgb(255, 255, 255)"
											: "transparent"
									}}
								>
									<StyledTabPanel>
										<CvContainer>
											<CvContent
												pdf_doc={_get(cv_docs, "cv_profile", "")}
												isHeadline={false}
											/>
										</CvContainer>
									</StyledTabPanel>
									{isClient
										? !isEmptyPreferences && renderFrontSheet()
										: renderFrontSheet()}
									{isClient
										? !isEmptyLangSkillsFnc && renderSkillsLangsFnc()
										: renderSkillsLangsFnc()}
									{!isOwnProfile ? (
										<StyledTabPanel>
											<Container width={"100%"}>
												<History history={history} />
											</Container>
										</StyledTabPanel>
									) : (
										<></>
									)}
								</Box>
							</Flex>
						</TabPanels>
					</Tabs>
				</React.Fragment>
			)}
			<HideFromSearchModal
				active={displayHideFromSearchModal}
				closeModal={() => setDisplayHideFromSearchModal(false)}
				profile_id={profile_id}
				selectedTemplate={template}
				nextAction={fetchProfile}
			/>
			<HidePermanentlyModal
				active={displayHidePermanentlyModal}
				closeModal={() => setDisplayHidePermanentlyModal(false)}
				profile_id={profile_id}
				nextAction={fetchProfile}
			/>
			<RemoveShortlistModal
				active={displayRemoveShortlistModal}
				id={shortlistId}
				nextAction={() => {
					fetchProfile();
					let url = window.location.pathname;
					window.history.pushState(
						{
							path: `${url}?vacancy=${vacancyId}`
						},
						"",
						`${url}?vacancy=${vacancyId}`
					);
				}}
				closeModal={() => setDisplayRemoveShortlistModal(false)}
			/>
			{displayShortlistModal && (
				<Portal>
					<AddShortListModal
						onClose={() => setDisplayShortlistModal(false)}
						vacancyId={vacancyId}
						profile_id={profile_id}
						vacancies={vacancies}
						nextAction={fetchProfile}
					/>
				</Portal>
			)}
			{displayAddNoteModal && (
				<Portal>
					<AddNoteModal
						profile_id={profile_id}
						nextAction={fetchProfile}
						onClose={() => setDisplayAddNoteModal(false)}
					/>
				</Portal>
			)}
			{editPreferencesModal && (
				<PreferencesModal
					onClose={() => setEditPreferencesModal(false)}
					expected_salary={_get(preference, "expected_salary")}
					expected_salary_advantage={_get(
						preference,
						"expected_salary_advantage"
					)}
					notice_period={_get(preference, "notice_period")}
					reloadProfile={viewProfile}
					preferences={preference}
					preferencesCompleted={_get(user, "preferences_completed")}
					isEmptyLangSkillsFnc={isEmptyLangSkillsFnc}
					redirectToFunctions={() => (window.location.hash = "#2")}
					handleChangeEmployment={handleChangeEmployment}
					employmentType={employmentType}
				/>
			)}
			{editPreferredLocationModal && (
				<PreferredLocationModal
					onClose={() => setEditPreferredLocationModal(false)}
					preferred_location={_get(preference, "preferred_location")}
					reloadProfile={viewProfile}
					preferencesCompleted={_get(user, "preferences_completed")}
					isEmptyLangSkillsFnc={isEmptyLangSkillsFnc}
					redirectToFunctions={() => (window.location.hash = "#2")}
				/>
			)}
			{displayLanguagesModal && (
				<LanguagesModal
					closeModal={() => setDisplayLanguagesModal(false)}
					selectedLanguages={languages}
					saveLanguages={saveLanguages}
					closeOnSave={false}
				/>
			)}
			{displaySkillsModal && (
				<ListProvider>
					<SkillsModal
						skills={skills}
						saveSkills={saveSkills}
						closeModal={() => setDisplaySkillsModal(false)}
						selectedFunctions={functions}
						showMustHave={false}
						saveActionName={"Save to profile"}
						isSearch={false}
						closeOnSave={false}
						cartTitle={"Your skills"}
					/>
				</ListProvider>
			)}
			{displayFunctionsModal && (
				<div className="create-job">
					<FunctionsModal
						functions={functions}
						onClose={() => setDisplayFunctionsModal(false)}
						onSave={saveFunctions}
						closeOnSave={false}
						skillsCategories={getSkillsCategories(skills)}
						removeSkills={() => {}}
						saveActionName={"Save to my profile"}
					/>
				</div>
			)}
			{modalName === FIRST_LOGIN && displayFirstSigninModal && (
				<FirstSigninModal
					closeModal={closeFirstSigningModal}
					onEdit={onEditFirstSigningModal}
				/>
			)}
			{displayCandidateModal && (
				<CandidateProfileModal
					closeModal={() => setDisplayCandidateModal(false)}
					headline={headline}
					reloadProfile={viewProfile}
					user={user}
					cv_docs={cv_docs}
				/>
			)}
			{displayUnsubscribeModal && (
				<UnsubscribeModal
					onClose={() => {
						setDisplayUnsubscribeModal(false);
						const url = window.location.pathname;
						window.history.pushState(
							{
								path: url
							},
							"",
							url
						);
					}}
					company_id={_get(query, "unsubscribe", "")}
				/>
			)}
		</Box>
	);
}

export default memo(withRouter(ProfileContainer));
