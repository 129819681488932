import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getInitials, hashCode } from "./utils";
import colors from "./utils/colors";

const AvatarContainer = styled.div`
	width: ${props => props.size}px;
	height: ${props => props.size}px;
	border-radius: ${props =>
		props.radius === undefined ? "9999px" : `${props.radius}px`};
	position: relative;
	display: inline-flex;
	flex-shrink: 0;
	justify-content: center;
	background-color: ${props => props.backgroundColor};
	overflow: hidden;
	.inner-border {
		position: absolute;
		inset: 0;
		border-radius: inherit;
		border: 1px solid #00000014;
	}
`;

const AvatarText = styled.div`
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: ${props => props.size / 2.6}px;
	line-height: ${props => props.size}px;
	font-weight: 400;
	color: ${props => props.textColor};
`;

function Avatar({
	size = 40,
	radius,
	name,
	forceInitials,
	src,
	backgroundColor,
	textColor,
	className,
	fallbackText,
	onMouseLeave,
	onMouseEnter,
	style
}) {
	const [imageFailedLoading, setImageFailedLoading] = useState(false);
	const imgNotAvailable = !src || imageFailedLoading;
	const initials = getInitials(name, fallbackText);
	const hash = hashCode(name);
	const keys = Object.keys(colors);
	const key = keys[hash % keys.length];
	const color = colors[key];
	const containerBackgroundColor = !imgNotAvailable
		? "white"
		: backgroundColor || color.background;

	return (
		<AvatarContainer
			size={size}
			radius={radius}
			backgroundColor={containerBackgroundColor}
			className={className}
			tabIndex={-1}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={style}
		>
			{(imgNotAvailable || forceInitials) && (
				<AvatarText size={size} textColor={textColor || color.text}>
					{initials}
				</AvatarText>
			)}
			{!imgNotAvailable && (
				<img
					src={src}
					alt={name}
					width="auto"
					height="100%"
					onError={() => setImageFailedLoading(true)}
				/>
			)}
			<div className="inner-border"></div>
		</AvatarContainer>
	);
}

Avatar.propTypes = {
	size: PropTypes.number,
	name: PropTypes.string,
	forceInitials: PropTypes.bool,
	image: PropTypes.string,
	color: PropTypes.string,
	fallbackText: PropTypes.string,
	src: PropTypes.string,
	backgroundColor: PropTypes.string,
	textColor: PropTypes.string,

	className: PropTypes.string
};

export default memo(Avatar);
