import React, { Component } from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
	ARCHIVE_REQUEST,
	DRAFT,
	PAUSE_REQUEST,
	UPDATE,
	PUBLISHED,
	UNPUBLISHED,
	DUPLICATED,
	ADD_TASK
} from "config";

import {
	publishRequest,
	editJob,
	duplicateJob,
	stopJobModal
} from "../actions";
import { ReactComponent as AddTaskIcon } from "static/icons/addTaskIcon.svg";
import { REQUEST } from "config";

const initialState = {
	dropdownPosition: "bottomCenter",
	align: {
		points: ["tc", "bc"],
		offset: [-1, -16]
	}
};

class JobActions extends Component {
	constructor() {
		super();
		this.state = initialState;
	}

	onVisibleChange = visible => {
		const {
			job: { id },
			reposition
		} = this.props;
		const windowHeight = window.innerHeight;
		setTimeout(() => {
			const element = document.getElementsByClassName(`menu-${id}`)[0];
			if (!element) return;
			if (reposition && visible) {
				const boundingBox = element.getBoundingClientRect();
				if (boundingBox.y + boundingBox.height > windowHeight) {
					this.setState({
						dropdownPosition: "topCenter",
						align: {
							points: ["bc", "tc"],
							offset: [-2, 30]
						}
					});
				}
			} else if (reposition) {
				this.setState(initialState);
			}
			element.classList.add("rc-shown");
		}, 0);

		this.props.onVisibleChange();
	};

	handleMenuClick = (e, job) => {
		const {
			dispatch,
			openDrawerTask,
			setEntity,
			setSelectedEntityOption
		} = this.props;
		e.stopPropagation?.();
		e.preventDefault?.();
		switch (e.key) {
			case PUBLISHED:
				return publishRequest(job.id, job.step);
			case UPDATE:
				return editJob(job.id);
			case DUPLICATED:
				return dispatch(duplicateJob(job.id, job.type));
			case PAUSE_REQUEST:
				return dispatch(
					stopJobModal({
						id: job.id,
						title: job.title,
						mode: PAUSE_REQUEST
					})
				);
			case ADD_TASK:
				setEntity(REQUEST);
				setSelectedEntityOption({
					label: job.title,
					value: job.id
				});
				return openDrawerTask(true);
			case ARCHIVE_REQUEST:
				return dispatch(
					stopJobModal({
						id: job.id,
						title: job.title,
						mode: ARCHIVE_REQUEST
					})
				);
			default:
				break;
		}
	};

	listMenu = () => {
		const { job, is_preview = false } = this.props;
		const isDraftOrPaused = job.status === DRAFT || job.status === UNPUBLISHED;
		return (
			<Menu
				onClick={e => this.handleMenuClick(e, job)}
				selectable={false}
				className={"actionsRequestList"}
			>
				{!is_preview && isDraftOrPaused && (
					<MenuItem key={PUBLISHED} className={`ignore-click ${PUBLISHED}`}>
						<span className="ignore-click">
							<i className="icon icon-check ignore-click" />
							Publish
						</span>
					</MenuItem>
				)}

				{job.status === DRAFT && (
					<MenuItem key={UPDATE} className={`ignore-click ${UPDATE}`}>
						<span className="ignore-click">
							<i className="icon icon-edit ignore-click" />
							Edit
						</span>
					</MenuItem>
				)}

				<MenuItem key={DUPLICATED} className={`ignore-click ${DUPLICATED}`}>
					<span className="ignore-click">
						<i className="icon icon-Duplicate ignore-click" />
						Duplicate
					</span>
				</MenuItem>

				{job.status === PUBLISHED && (
					<MenuItem
						key={PAUSE_REQUEST}
						className={`ignore-click ${PAUSE_REQUEST}`}
					>
						<span className="ignore-click">
							<i className="icon icon-pause-cycle ignore-click" />
							UNPUBLISH
						</span>
					</MenuItem>
				)}

				{isDraftOrPaused && (
					<MenuItem
						key={ARCHIVE_REQUEST}
						className={`ignore-click ${ARCHIVE_REQUEST}`}
					>
						<span className="ignore-click">
							<span className="icon icon-archive ignore-click">
								<span className="path1 ignore-click" />
								<span className="path2 ignore-click" />
								<span className="path3 ignore-click" />
								<span className="path4 ignore-click" />
								<span className="path5 ignore-click" />
								<span className="path6 ignore-click" />
							</span>
							Archive
						</span>
					</MenuItem>
				)}
				<MenuItem key={ADD_TASK} className={`ignore-click ${ADD_TASK}`}>
					<span className="ignore-click itemWithSvg">
						<AddTaskIcon /> Add task
					</span>
				</MenuItem>
			</Menu>
		);
	};

	render() {
		const { children, job, reposition } = this.props;
		return (
			<RcDropDown
				trigger={["click"]}
				placement={this.state.dropdownPosition}
				overlay={this.listMenu()}
				overlayClassName={`ignore-click menu-${job.id} rc-hidden`}
				onVisibleChange={this.onVisibleChange}
				align={reposition && this.state.align}
				onOverlayClick={this.onVisibleChange}
				animation="slide-up"
			>
				{children}
			</RcDropDown>
		);
	}
}

JobActions.propTypes = {
	onVisibleChange: PropTypes.func
};

JobActions.defaultProps = {
	onVisibleChange: () => {}
};

export default connect()(JobActions);
