import React, { useState } from "react";
import { useMutation } from "react-query";
import { Flex, Text } from "rebass";
import Switch from "react-switch";
import get from "lodash/get";
import { Button } from "common/styled/buttons";
import { SEND_COMMENT } from "config/api-endpoints";
import toaster from "common/Toaster";
import { ReactComponent as SendComment } from "static/icons/send-comments.svg";
import { ReactComponent as CheckedToggle } from "static/icons/toggle-checked-comments.svg";
import { ReactComponent as UncheckedToggle } from "static/icons/toggle-unchecked-comments.svg";
import { client } from "lib/api-client";
import GlobalTooltip from "common/GlobalTooltip";
import { WORKTIME, EXPENSE, OVERTIME } from "config";

const addComment = data => client(SEND_COMMENT, { body: data });

const AddComment = ({ view, timesheetId, id, loadTimeSheet, maxLength }) => {
	const [comment, setComment] = useState("");
	const [checked, setChecked] = useState(false);

	const [mutate, { isLoading }] = useMutation(addComment, {
		onSuccess: () => {
			setComment("");
			loadTimeSheet();
			setChecked(false);
		},
		onError: error => {
			let errorMsg = get(error, "detail");
			const keys = Object.keys(errorMsg);
			if (keys.length > 0) {
				keys.forEach(name => {
					toaster.danger(errorMsg[name]);
				});
			}

			toaster.danger(get(error, "detail", get(error, "title")));
		}
	});

	const sendComment = e => {
		e.preventDefault();
		e.stopPropagation();
		if (!comment.trim().length) {
			return;
		}
		const type = {
			[WORKTIME]: WORKTIME,
			[EXPENSE]: EXPENSE,
			[OVERTIME]: WORKTIME
		};
		return mutate({
			timesheet_id: timesheetId,
			type_item: type[view], // 'worktime' or 'expense'
			item_id: id,
			comment,
			is_internal_comment: checked
		});
	};

	const getTextLength = () => {
		return comment.length;
	};

	return (
		<form onSubmit={sendComment}>
			<Flex
				onClick={e => {
					e.stopPropagation();
				}}
				sx={{ gap: 2 }}
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Flex
					sx={{
						outline: "none",
						position: "relative",
						flex: 1,

						"> p": {
							margin: 0,
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: "normal",
							fontSize: "12px",
							lineHeight: "22px",
							color: "#6E7191"
						}
					}}
					flexDirection="column"
				>
					<Flex
						alignItems="center"
						justifyContent="space-between"
						sx={{
							background: "#F1F2F5",
							padding: "10px",
							borderRadius: "5px",
							"> input": {
								background: "#F1F2F5",
								border: "none",
								width: "100%"
							},
							".react-switch-handle": {
								top: "0px !important",
								"> div": {
									pointerEvents: "auto !important"
								},
								".g-tool": {
									margin: 0
								}
							}
						}}
					>
						<input
							placeholder="Type your comment"
							value={comment}
							onChange={e => {
								e.stopPropagation();
								if (maxLength && e.target.value.length <= maxLength) {
									setComment(e.target.value);
								}
								if (!maxLength) setComment(e.target.value);
							}}
						/>
						<Switch
							onChange={() => setChecked(checked => !checked)}
							checked={checked}
							uncheckedHandleIcon={
								<UncheckedToggle style={{ height: "28px", width: "28px" }} />
							}
							checkedHandleIcon={
								<GlobalTooltip
									placement="top"
									overlay={
										checked ? (
											<Text
												sx={{
													fontFamily: "BasierCircle",
													fontStyle: "normal",
													fontWeight: 400,
													fontSize: "12px",
													lineHeight: "18px",
													color: "#fff",
													whiteSpace: "break-spaces",
													display: "inline-block"
												}}
											>
												The message will only be visible to members of{" "}
												<Text
													sx={{
														color: "#d69b4d",
														whiteSpace: "break-spaces",
														display: "inline-block"
													}}
												>
													company
												</Text>
											</Text>
										) : (
											<Text
												sx={{
													fontFamily: "BasierCircle",
													fontStyle: "normal",
													fontWeight: 400,
													fontSize: "12px",
													lineHeight: "18px",
													color: "#fff",
													whiteSpace: "break-spaces",
													display: "inline-block"
												}}
											>
												The message will be visible to the{" "}
												<Text
													sx={{
														color: "#d69b4d",
														whiteSpace: "break-spaces",
														display: "inline-block"
													}}
												>
													vendor{" "}
												</Text>
												and{" "}
												<Text
													sx={{
														color: "#d69b4d",
														whiteSpace: "break-spaces",
														display: "inline-block"
													}}
												>
													company
												</Text>
											</Text>
										)
									}
									trigger="hover"
									overlayClassName={`timesheet-comment-tooltip${
										checked ? " comment-tooltip-arrow" : ""
									}`}
								>
									<CheckedToggle style={{ height: "28px", width: "28px" }} />
								</GlobalTooltip>
							}
							offColor="#6E7191"
							onColor="#FD6950"
							offHandleColor="#6E7191"
							onHandleColor="#FD6950"
							uncheckedIcon={null}
							checkedIcon={null}
						/>
					</Flex>

					{maxLength && (
						<span
							style={{
								alignSelf: "flex-end",
								fontSize: 13,
								fontWeight: 500
							}}
						>
							{getTextLength()}/{maxLength}
						</span>
					)}
				</Flex>

				<Button disabled={!comment || isLoading} type="submit" size={14}>
					<SendComment />
				</Button>
			</Flex>
		</form>
	);
};

export default AddComment;
