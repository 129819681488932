import React, { useEffect } from "react";
import { Filters } from "modules/home/components/filters";
import styles from "modules/home/containers/interview/interview.module.scss";
import { utcTimeStamp } from "config/helpers";

export const HeaderEnd = ({
	options,
	handleViewAll,
	start_date_Key,
	end_date_key,
	start_date,
	end_date,
	recipient_type,
	setItem,
	recipient_key,
	disabled,
	isSettedDate
}) => {
	useEffect(() => {
		if (!Boolean(start_date))
			setItem(
				start_date_Key,
				isSettedDate
					? window.moment.unix(utcTimeStamp({ date: "", isStart: true }))
					: window.moment("").startOf("day")
			);
		if (!Boolean(end_date))
			setItem(
				end_date_key,
				isSettedDate
					? window.moment.unix(utcTimeStamp({ date: "", isStart: false }))
					: window.moment("").endOf("day")
			);
	}, []);

	const storeSetter = key => val => {
		if (key === start_date_Key)
			setItem(end_date_key, window.moment("").endOf("day"));
		setItem(key, val);
	};
	const resetDatePicker = () => {
		setItem(end_date_key, window.moment(""));
		setItem(start_date_Key, window.moment(""));
	};
	return (
		<div className={styles["headerEnd"]}>
			<Filters>
				<Filters.DatePicker
					setStartDate={storeSetter(start_date_Key)}
					setEndDate={storeSetter(end_date_key)}
					startDate={start_date}
					endDate={end_date}
					position={{ top: "30px", right: 0 }}
					resetDatePicker={resetDatePicker}
					end_date_key={end_date_key}
				/>
				{options && (
					<Filters.TypeSelect
						options={options}
						onChange={storeSetter(recipient_key)}
						value={recipient_type}
					/>
				)}

				{handleViewAll && (
					<Filters.RedirectButton
						label="View all"
						onClick={handleViewAll}
						disabled={disabled}
					/>
				)}
			</Filters>
		</div>
	);
};
export const HeaderStart = ({ title, count }) => {
	return (
		<div className={styles["headerStart"]}>
			<span className={styles["label"]}>{title}</span>
			<span className={styles["counter"]}>{count}</span>
		</div>
	);
};
