import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userTypeSelector } from "modules/user/selectors/selectors";
import { onlineUserSectorSelector } from "modules/user/selectors/selectors";
import { languagesSelector } from "../../../app/selectors";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import CompanyDashboard from "common/dashboard/Dashboard";
import * as Actions from "../../actions";
import Sidebar from "common/search/Sidebar";
import Topbar from "common/search/Topbar";
import ProfileList from "./ProfileList";
import Pagination from "common/Pagination";
import EmptySearch from "common/search/EmptySearch";
import { loadLanguages } from "../../../app/actions/ListsActions";
import { Helmet } from "react-helmet";
import {
	functionsSelector,
	subSectorsSelector,
	sectorsSelector,
	filterSelector,
	searchProfileSelector
} from "modules/vacancy/selectors";
import { SearchLimitMessage } from "../../styled";
import { PROFILE_SEARCH_MAX_RESULTS, PROFILE_SEARCH_MAX_OFFSET } from "config";

export const getProfileSearchRoute = () => {
	return `/vacancies/search`;
};

export const getProfileSearchPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

class ProfileSearch extends Component {
	handlePageClick = selected => {
		const filter = { ...this.props.filter };
		const { search } = this.props;
		if (filter.offset === search.size * selected) return;
		filter.offset = search.size * selected;
		this.props.updateFilter(filter, true, true);
	};

	handleSkillChange = skill => {
		const { addedSkills } = this.props;
		const filter = { ...this.props.filter };
		this.props.handleSkillChange(skill, addedSkills, filter, true);
	};

	componentWillUnmount() {
		this.props.initializeProfileSearch();
	}

	render() {
		const {
			addedSkills,
			route: { title },
			params: { id },
			search: { data, total, size },
			filter,
			sector,
			userType,
			sectors,
			subSectors,
			functions,
			addedLanguages,
			user,
			languages
		} = this.props;
		!id && delete filter.id;
		const filterVacancy = id ? { ...filter, id } : filter;
		return (
			<BodyClassName className="jobs gray-bg">
				<div id="job-search">
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<CompanyDashboard />
					<div className="container">
						<div className="row">
							<div className="col-md-3">
								<Sidebar
									filter={filterVacancy}
									addedSkills={addedSkills}
									addedLanguages={addedLanguages}
									user={user}
									languages={languages}
									updateFilter={this.props.updateFilter}
									updateSkills={this.props.updateSkills}
									updateLanguages={this.props.updateLanguages}
									loadLanguages={this.props.loadLanguages}
									fetchJobs={this.props.fetchJobs}
									resetFilter={this.props.resetFilter}
									handleSkillChange={this.props.handleSkillChange}
									vacancy={true}
								/>
							</div>
							<div className="col-md-9 search-result">
								<Topbar
									filter={filterVacancy}
									sector={sector}
									userType={userType}
									sectors={sectors}
									subSectors={subSectors}
									functions={functions}
									getSectors={this.props.getSectors}
									updateFilter={this.props.updateFilter}
									setSubSectors={this.props.setSubSectors}
									getFunctions={this.props.getFunctions}
									vacancy={true}
								/>
								{data.length > 0 && (
									<ProfileList
										jobs={data}
										onSkillClick={this.handleSkillChange}
										selectedSkills={addedSkills}
										vacancyId={id}
									/>
								)}
								{data.length === 0 && (
									<EmptySearch
										title={
											"There is no candidate matching your search criteria."
										}
									/>
								)}

								{data.length > 0 && (
									<div className="ac">
										{total >= PROFILE_SEARCH_MAX_RESULTS &&
											filter.offset === PROFILE_SEARCH_MAX_OFFSET && (
												<SearchLimitMessage>
													To see more profiles, please fine tune your search
												</SearchLimitMessage>
											)}
										<Pagination
											total={
												total > PROFILE_SEARCH_MAX_RESULTS
													? PROFILE_SEARCH_MAX_RESULTS
													: total
											}
											size={size}
											handlePageClick={this.handlePageClick}
											offset={filter.offset}
											initialSelected={filter.offset / size}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		filter: filterSelector(state),
		sector: onlineUserSectorSelector(state),
		userType: userTypeSelector(state),
		sectors: sectorsSelector(state),
		subSectors: subSectorsSelector(state),
		functions: functionsSelector(state),
		search: searchProfileSelector(state),
		addedSkills: searchProfileSelector(state).addedSkills,
		addedLanguages: searchProfileSelector(state).addedLanguages,
		user: onlineUserSelector(state),
		languages: languagesSelector(state)
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...Actions, loadLanguages }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSearch);
