import React from "react";
import { ReactComponent as AddIcon } from "static/icons/PlusAdd.svg";
import styles from "./tasks-list-head.module.scss";
import HeaderButton from "../HeaderButton/HeaderButton";
import { goBack } from "config/helpers";
import { ReactComponent as BackArrow } from "static/icons/IconBack.svg";

const TasksListHead = ({ onAddTask }) => {
	return (
		<div className={styles.headerContainer}>
			<div className={styles.headerCandidate}>
				<div className={styles.first}>
					<button className={styles.goBackButton} onClick={goBack}>
						<BackArrow />
					</button>
					<h3>Tasks list</h3>
				</div>

				<div className={styles.btnContainer}>
					<HeaderButton
						onClick={onAddTask}
						text="Add task"
						className="buttonAdd"
						icon={<AddIcon />}
					/>
				</div>
			</div>
		</div>
	);
};

export default TasksListHead;
