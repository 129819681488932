import { createSelector } from "reselect";
import _get from "lodash/get";

const getSignUp = state => _get(state, "signup");
const getVatData = state => _get(state, "signup.data.vat");
const getUserName = state => _get(state, "signup.data.name");
const getUserEmail = state => _get(state, "signup.data.email");
const getLoading = state => _get(state, "signup.loading");
const getOnlineUser = state => _get(state, "auth.user");
const getUserType = state => _get(state, "auth.user.company_type");
const getIsLoggedIn = state => _get(state, "auth.isLoggedIn");
const getLastActive = state => _get(state, "auth.lastActive");
const getErrorMessage = state => _get(state, "auth.errorMessage");
const getIsFetching = state => _get(state, "auth.isFetching");
const geUserBlocked = state => _get(state, "auth.userBlocked");
const getEmail = state => _get(state, "auth.email");
const getIsAccount = state => _get(state, "auth.is_account");
const getSearch = state => _get(state, "search");
const getUserFiles = state => _get(state, "userFiles");
const getBannerToBeReviewed = state =>
	_get(state, "profile.displayBannerToBeReviewed");
const getDisplayCookiePolicy = state =>
	_get(state, "profile.displayCookiePolicy");

export const signUpSelector = createSelector(getSignUp, signup => signup);
export const VatDataSelector = createSelector(getVatData, vatData => vatData);
export const userNameSelector = createSelector(getUserName, name => name);
export const userEmailSelector = createSelector(getUserEmail, email => email);
export const loadingSelector = createSelector(getLoading, loading => loading);
export const userTypeSelector = createSelector(getUserType, type => type);
export const onlineUserSelector = createSelector(getOnlineUser, user => user);
export const onlineUserSectorSelector = createSelector(
	onlineUserSelector,
	user => _get(user, "sector")
);
export const onlineUserSectorsSelector = createSelector(
	onlineUserSelector,
	user => _get(user, "sectors")
);
export const searchSelector = createSelector(getSearch, search =>
	JSON.parse(JSON.stringify(search))
);
export const isLoggedInSelector = createSelector(
	getIsLoggedIn,
	isLoggedIn => isLoggedIn
);
export const lastActiveSelector = createSelector(
	getLastActive,
	lastActive => lastActive
);
export const errorMessageSelector = createSelector(
	getErrorMessage,
	errorMessage => errorMessage
);
export const isFetchingSelector = createSelector(
	getIsFetching,
	isFetching => isFetching
);
export const userBlockedSelector = createSelector(
	geUserBlocked,
	userBlocked => userBlocked
);
export const emailSelector = createSelector(getEmail, email => email);
export const isAccountSelector = createSelector(
	getIsAccount,
	isAccount => isAccount
);

export const userFilesSelector = createSelector(getUserFiles, files => files);

export const bannerToBeReviewedSelector = createSelector(
	getBannerToBeReviewed,
	banner => banner
);

export const displayCookiePolicySelector = createSelector(
	getDisplayCookiePolicy,
	displayCookiePolicy => displayCookiePolicy
);
