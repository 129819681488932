import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import React, { useEffect } from "react";
import styles from "./email-signature.module.scss";
import EmailSignatureForm from "./components/EmailSignatureForm";
import EmailSignaturePreview from "./components/EmailSignaturePreview";
import useEmailSignatureForm from "./hooks/useEmailSignatureForm";
import { FormProvider } from "react-hook-form";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import { ReactComponent as SaveIcon } from "static/icons/email-template/save.svg";
import useUpdateEmailSignature from "./api/useUpdateEmailSignature";
import { GET_EMAIL_SIGNATURE_QUERY_KEY } from "./api/useGetEmailSignature";
import { queryCache } from "react-query";
import toaster from "common/Toaster";
import EmailSignatureSkeleton from "./components/EmailSignatureSkeleton";
import { formatPhone } from "config/helpers";
import prependHttp from "prepend-http";

export const getEmailSignatureRoute = () => {
	return "/settings/email-signature";
};

export const getEmailSignaturePermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default function EmailSignature() {
	const setBreadcrumbs = useBreadCrumbs(state => state.setItems);
	const { formContext, signatureQuery } = useEmailSignatureForm();
	const [updateEmailSignature, { isLoading }] = useUpdateEmailSignature();

	useEffect(() => {
		setBreadcrumbs([{ name: "Settings" }, { name: "Email signature" }]);
	}, []);

	const onSubmit = data => {
		const prepareData = () => {
			let phone = "";
			const isPhoneEmpty = !data.phone?.value || !data.phone?.countryCode;
			if (!isPhoneEmpty) {
				phone = formatPhone(data.phone.countryCode, data.phone.value);
			}

			return {
				...data,
				phone,
				social_media: data.social_media
					.filter(i => i.url)
					.map(i => ({
						platform: i.platform,
						url: prependHttp(i.url)
					}))
			};
		};
		updateEmailSignature(
			{
				body: prepareData()
			},
			{
				onSuccess() {
					toaster.success("Email signature successfully saved.");
					queryCache.invalidateQueries(GET_EMAIL_SIGNATURE_QUERY_KEY);
				}
			}
		);
	};

	return (
		<FormProvider {...formContext}>
			<form
				className={styles.container}
				onSubmit={formContext.handleSubmit(onSubmit)}
			>
				<div className={styles.contentLayout}>
					<div className={styles.header}>
						<span>Email Signature</span>
						<button
							className={styles.saveBtn}
							disabled={!formContext.formState.isDirty || isLoading}
						>
							{isLoading ? (
								<AnimatedLoaderIcon width={24} />
							) : (
								<>
									<SaveIcon />
									<span>Save</span>
								</>
							)}
						</button>
					</div>
					<div className={styles.content}>
						{signatureQuery.isLoading ? (
							<EmailSignatureSkeleton />
						) : (
							<EmailSignatureForm />
						)}
						<EmailSignaturePreview />
					</div>
				</div>
			</form>
		</FormProvider>
	);
}
