import React, { useEffect, useState } from "react";
import Drawer from "rc-drawer";
import styles from "./drawer-form.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/referral/x.svg";
import { ReactComponent as HelpIcon } from "static/icons/referral/help.svg";
import Checkbox from "./Checkbox";
import FileUploader from "./FileUploader";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import cx from "classnames";
import { getMaxIndicatorClassName } from "config";
import GlobalTooltip from "common/GlobalTooltip";
import { v4 as uuid4 } from "uuid";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import toaster from "common/Toaster";
import useReferFriendToJob from "./api/useReferFriendToJob";
import { ReactComponent as StarIcon } from "static/icons/star-05.svg";
import PhoneInput from "common/PhoneInput/new-phone-input";
import { formatPhone, generateId, getFileName } from "config/helpers";
import useGetSingleCandidate from "./hooks/useGetSingleCandidate";
import { parsePhoneNumber } from "libphonenumber-js";
import { PHONE_DEFAULT_COUNTRY } from "config";
import QuestionnaireForm, {
	QuestionnaireFormQuestionItem
} from "common/QuestionnaireForm";
import {
	formatData,
	getQuestionnaireData,
	getQuestionnaireSchema
} from "common/QuestionnaireForm/utils/helpers";

const NAME_REGEX = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;
const COMMENT_MAX_LENGTH = 1000;

export default function ReferralDRawerForm({
	vacancyId,
	campaignId,
	version,
	isOpen,
	onClose,
	questionnaireData
}) {
	const schema = yup.object().shape({
		cv: yup.string().required("Resume is required"),
		firstName: yup
			.string()
			.required("First name is required")
			.matches(NAME_REGEX, "First name can only contain Latin letters."),
		lastName: yup
			.string()
			.required("Last name is required")
			.matches(NAME_REGEX, "Last name can only contain Latin letters."),
		email: yup
			.string()
			.required("Email is required")
			.email("Email is not valid"),
		phone: yup
			.object()
			.required("Phone number is required")
			.test("invalid", "Phone number is invalid", function(value) {
				try {
					const res = parsePhoneNumber(value?.value, {
						extract: false
					});
					return res.isValid();
				} catch (error) {
					return false;
				}
			}),
		confirmation: yup.boolean().isTrue("Confirmation is required"),
		...getQuestionnaireSchema(questionnaireData)
	});
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		reset,
		setError,
		watch,
		setValue
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema)
	});

	const [getSingleCandidate] = useGetSingleCandidate({
		onSuccess: () => setIsExtractingLoading(false),
		onError: error => {
			setIsExtractingLoading(false);
			toaster.danger(error.message);
		}
	});

	const [isExtractingLoading, setIsExtractingLoading] = useState(false);

	const isPhoneEmpty = !watch("phone")?.value || !watch("phone").countryCode;

	const shouldProcessCV = !(
		watch("firstName") &&
		watch("firstName") &&
		watch("email")
	);

	const onStartUpload = () => {
		setIsExtractingLoading(true);
	};

	useEffect(() => {
		if (watch("cv") && (shouldProcessCV || isPhoneEmpty)) {
			getSingleCandidate(
				{
					cv_doc: watch("cv"),
					file_name: getFileName(watch("cv")),
					id: generateId()
				},
				{
					onSuccess: ({ data }) => {
						if (!watch("firstName")) {
							setValue("firstName", data.first_name, { shouldValidate: true });
						}

						if (!watch("lastName")) {
							setValue("lastName", data.last_name, { shouldValidate: true });
						}

						if (!watch("email")) {
							setValue("email", data.email, { shouldValidate: true });
						}

						if (isPhoneEmpty) {
							let extractedPhone;
							if (isPhoneEmpty) {
								try {
									const res = parsePhoneNumber(data.phone, {
										extract: false
									});
									if (res.isValid()) {
										extractedPhone = {
											value: res.number,
											countryCode: res.country
										};
									}
								} catch (error) {
									extractedPhone = {
										value: data.phone,
										countryCode: PHONE_DEFAULT_COUNTRY
									};
								}
								setValue("phone", extractedPhone, { shouldValidate: true });
							}
						}
					}
				}
			);
		} else {
			setIsExtractingLoading(false);
		}
	}, [watch("cv")]);

	const [mutate, { status }] = useReferFriendToJob({
		onSuccess: () => {
			toaster.success("Your referral has been successfully sent!");
			onCloseDrawer();
		},
		onError: error => {
			if (error?.detail?.cv_doc) {
				setError("cv", { type: "custom", message: error.detail.cv_doc });
			}
			if (error?.detail?.first_name) {
				setError("firstName", {
					type: "custom",
					message: error.detail.first_name
				});
			}
			if (error?.detail?.last_name) {
				setError("lastName", {
					type: "custom",
					message: error.detail.last_name
				});
			}
			if (error?.detail?.phone) {
				setError("phone.value", {
					type: "custom",
					message: error.detail.phone
				});
			}
			if (error?.detail?.email) {
				setError("email", { type: "custom", message: error.detail.email });
			}
			if (error?.detail?.accept_policies) {
				setError("confirmation", {
					type: "custom",
					message: error.detail.accept_policies
				});
			}
			if (error?.detail?.referral_comment) {
				setError("comment", {
					type: "custom",
					message: error.detail.referral_comment
				});
			}
		}
	});

	const onSubmit = data => {
		const { questionnaireData: questionnaire } = formatData(data);

		mutate({
			cv_doc: data.cv,
			first_name: data.firstName,
			last_name: data.lastName,
			phone: formatPhone(data.phone.countryCode, data.phone.value),
			email: data.email,
			accept_policies: data.confirmation,
			version: version,
			vacancy_id: vacancyId,
			id: uuid4(),
			tag: "online_referral_job",
			campaign_id: campaignId,
			referral_comment: data.comment,
			questionnaire: getQuestionnaireData(questionnaire, questionnaireData)
		});
	};

	const onCloseDrawer = () => {
		reset();
		onClose();
	};

	// prevent comment from exceeding max length
	const commentValue = watch("comment") ?? "";
	useEffect(() => {
		if (COMMENT_MAX_LENGTH && commentValue.length > COMMENT_MAX_LENGTH) {
			setValue("comment", commentValue.substring(0, COMMENT_MAX_LENGTH));
		}
	}, [commentValue]);

	return (
		<Drawer
			open={isOpen}
			width="480px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={onCloseDrawer}
		>
			{isOpen && (
				<form
					className={styles.drawerContent}
					onSubmit={handleSubmit(onSubmit)}
				>
					<header className={styles.header}>
						<h3 className={styles.title}>Refer a friend</h3>
						<div
							role="button"
							tabIndex="0"
							onClick={onCloseDrawer}
							className={styles.button}
						>
							<CloseIcon />
						</div>
					</header>
					<section className={styles.section}>
						<div className={styles.note}>
							To refer someone, kindly provide the contact details and resume of
							the person you are referring.
						</div>

						<div className={styles.field}>
							<div className={styles.label}>
								Upload CV <span className={styles.asterisk}>*</span>
							</div>
							<div className={styles.descriptionContainer}>
								<StarIcon />
								<div className={styles.description}>
									Simply upload CV, and our smart system will automatically
									extract all information and fill up other fields .
								</div>
							</div>

							<Controller
								name="cv"
								control={control}
								render={({ field: { onChange } }) => {
									return (
										<FileUploader
											onChange={onChange}
											isExtractingLoading={isExtractingLoading}
											onStartUpload={onStartUpload}
										/>
									);
								}}
							/>
							{errors.cv && (
								<div className={styles.fieldError}>{errors.cv.message}</div>
							)}
						</div>

						<div className={styles.resumeHelper}>
							<span>You dont have a cv?</span>
							<GlobalTooltip
								withWrappingDiv={false}
								placement="top"
								overlay={
									"We sugguest that you generate the candidate's CV from their Linkedin account to finish your referral process"
								}
								maxWidth={"260px"}
								overlayClassName={cx("g-tool-dark", styles.tooltip)}
							>
								<HelpIcon />
							</GlobalTooltip>
						</div>

						<label className={styles.field}>
							<div className={styles.label}>
								First name <span className={styles.asterisk}>*</span>
							</div>
							<input
								className={cx(
									styles.input,
									errors.firstName && styles.hasError
								)}
								placeholder="Enter first name"
								{...register("firstName")}
							/>
							{errors.firstName && (
								<div className={styles.fieldError}>
									{errors.firstName.message}
								</div>
							)}
						</label>

						<label className={styles.field}>
							<div className={styles.label}>
								Last name <span className={styles.asterisk}>*</span>
							</div>
							<input
								className={cx(styles.input, errors.lastName && styles.hasError)}
								type="text"
								placeholder="Enter last name"
								{...register("lastName")}
							/>
							{errors.lastName && (
								<div className={styles.fieldError}>
									{errors.lastName.message}
								</div>
							)}
						</label>

						<label className={styles.field}>
							<div className={styles.label}>
								Email <span className={styles.asterisk}>*</span>
							</div>
							<input
								className={cx(styles.input, errors.email && styles.hasError)}
								placeholder="Enter email address"
								{...register("email")}
							/>
							{errors.email && (
								<div className={styles.fieldError}>{errors.email.message}</div>
							)}
						</label>

						<div className={styles.field}>
							<div className={styles.label}>
								Phone number <span className={styles.asterisk}>*</span>
							</div>
							<Controller
								name="phone"
								control={control}
								render={({ field: { onChange } }) => {
									return (
										<PhoneInput
											onChange={(value, countryCode) => {
												onChange({
													value,
													countryCode: countryCode
												});
											}}
											value={watch("phone")}
										/>
									);
								}}
							/>
							{errors.phone && (
								<div className={styles.fieldError}>{errors.phone.message}</div>
							)}
						</div>
						<label className={styles.field}>
							<div className={styles.label}>Comment about this person</div>
							<div style={{ position: "relative" }}>
								<textarea
									className={cx(
										styles.textarea,
										errors.comment && styles.hasError
									)}
									type="text"
									placeholder="Leave a comment about this person"
									{...register("comment")}
								/>
								<div className={styles.maxLength}>
									<span
										className={cx(
											"input-maxlength",
											getMaxIndicatorClassName(
												commentValue.length,
												COMMENT_MAX_LENGTH
											)
										)}
									>
										{commentValue.length}/{COMMENT_MAX_LENGTH}
									</span>
								</div>
							</div>
							{errors.comment && (
								<div className={styles.fieldError}>
									{errors.comment.message}
								</div>
							)}
						</label>
						<div className={styles.divider}></div>

						<div className={styles.field}>
							<Controller
								name="confirmation"
								control={control}
								defaultValue={false}
								render={({ field: { onChange, value } }) => (
									<Checkbox
										className={styles.checkboxConfirmation}
										onChange={onChange}
										isSelected={value}
									>
										<div className={styles.checkboxLabel}>
											I hereby confirm that I’m authorized to share the
											candidate’s information listed above.
											{errors.confirmation && (
												<div className={styles.fieldError}>
													{errors.confirmation.message}
												</div>
											)}
										</div>
									</Checkbox>
								)}
							/>
						</div>
						{questionnaireData?.length > 0 && (
							<QuestionnaireForm className={styles.questionnaireForm}>
								{questionnaireData.map((item, index) => {
									const field = `${index}_${item.field}`;
									return (
										<>
											<Controller
												key={field}
												name={field}
												control={control}
												render={({ field: { onChange, value } }) => (
													<QuestionnaireFormQuestionItem
														{...item}
														onChange={onChange}
														value={value}
														cancelBtn
													/>
												)}
											/>
											{errors[field] && (
												<div
													className={cx(
														styles.fieldError,
														styles.questionnaireError
													)}
												>
													{errors[field]?.message}
												</div>
											)}
										</>
									);
								})}
							</QuestionnaireForm>
						)}
					</section>
					<div className={styles.footer}>
						<button className={styles.submitBtn}>
							{status === "loading" ? (
								<AnimatedLoaderIcon width={24} />
							) : (
								"Submit referral"
							)}
						</button>
						<button
							type="button"
							className={styles.cancelBtn}
							onClick={onCloseDrawer}
						>
							Cancel
						</button>
					</div>
				</form>
			)}
		</Drawer>
	);
}
