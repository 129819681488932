/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import _get from "lodash/get";
import { useMutation, usePaginatedQuery } from "react-query";
import BodyClassName from "react-body-classname";
import CompanyDashboard from "common/dashboard/Dashboard";
import TemplateSelect from "common/newSearch/TemplateSelect";
import { ACTION_TYPES, SearchContext } from "common/newSearch/context";
import SearchFilters from "./SearchFilters";
import {
	DISTANCE,
	SKILLS,
	FUNCTIONS,
	LANGUAGES,
	LOCATION,
	KEYWORDS,
	OFFSET,
	SORT_BY,
	EXCEPT_APPLIED,
	EXCEPT_POKED,
	POKES,
	HYBRID_OPTION
} from "config";
import { browserHistory } from "react-router";
import FunctionsModal from "modules/job/components/jobprofile/Functions/FunctionsModal";
import SkillsModal from "common/newSearch/Modals/SkillsModal";
import LanguagesModal from "common/newSearch/Modals/LanguagesModal";
import AddressBoxModal from "common/AddressBox/AddressBoxModal";
import CreateTemplateModal from "common/newSearch/Modals/CreateTemplateModal";
import FunctionsWarningModal from "common/newSearch/Modals/FunctionsWarningModal";
import { ListProvider } from "common/editablelist/Next/listContext";
import { searchParser } from "config/helpers";
import SearchResults from "./SearchResults";
import {
	SEARCH_JOB,
	LIST_SEARCH_TEMPLATES_ENDPOINT,
	GUEST_SEARCH_JOB
} from "config/api-endpoints";
import { client } from "lib/api-client";

import JobView from "../Jobview";
import HideJobModal from "./HideJobModal";
import JobSearchOnline from "./onlineMode";

const getJobs = (_key, filters, isOnline) => {
	return client(isOnline ? SEARCH_JOB : GUEST_SEARCH_JOB, {
		body: {
			...filters
		}
	});
};

const getTemplates = () => {
	return client(LIST_SEARCH_TEMPLATES_ENDPOINT, {
		body: {}
	});
};

const JobSearch = ({ isOnline = false, vacancyId, isDirectApply }) => {
	const { state, dispatch } = useContext(SearchContext);
	const [displayLanguagesModal, setDisplayLanguagesModal] = useState(false);
	const [displayAddressModal, setDisplayAddressModal] = useState(false);
	const [displayFunctionsModal, setDisplayFunctionsModal] = useState(false);
	const [displaySkillsModal, setDisplaySkillsModal] = useState(false);
	const [displayTemplateModal, setDisplayTemplateModal] = useState(false);
	const [dispalyHideJobModal, setDisplayHideJobModal] = useState(false);
	const [
		displayFunctionsWarningModal,
		setDisplayFunctionsWarningModal
	] = useState(false);

	const [displayJobView, setDisplayJobView] = useState(false);
	const [selectedJob, setSelectedJob] = useState({});

	const [functionToDelete, setFunctionToDelete] = useState("");
	const [jobIndex, setJobIndex] = useState(null);

	const {
		functions,
		skills,
		languages,
		location,
		distance,
		activeTemplate,
		keywords,
		sort_by,
		except_applied,
		except_poked,
		offset,
		employment_types
	} = state.filters;

	useEffect(() => {
		if (!displayJobView) {
			refetch();
		}
	}, [displayJobView]);

	useEffect(() => {
		if (searchParser().template && !activeTemplate) {
			return;
		}
	}, [state.filters]);

	useEffect(() => {
		if (isOnline) {
			fetchTemplates();
		}

		updateFilter("tag", "vacancy");

		return () => {
			const nextRoute = window.location.pathname.split("/")[1];
			if (nextRoute !== POKES) {
				dispatch({ type: ACTION_TYPES.CLEAR_STATE });
			}
		};
	}, []);

	useEffect(() => {
		if (vacancyId) {
			setDisplayJobView(true);
		}
	}, [vacancyId]);

	const {
		status,
		resolvedData,
		isFetching,
		error,
		refetch
	} = usePaginatedQuery(
		["search-jobs", formatFilters(state.filters), isOnline],
		getJobs,
		{
			refetchOnWindowFocus: false,
			onSuccess: res => {
				_get(res, `data[${jobIndex}].id`, false) &&
					_get(res, `data[${jobIndex}].version`, false) &&
					browserHistory.push(
						`/jobs/search/${_get(res, `data[${jobIndex}].id`)}?job_lang=${_get(
							res,
							`data[${jobIndex}].version`
						)}`
					);
			}
		}
	);

	const [searchTemplates] = useMutation(getTemplates);

	const deleteFilter = (name, value) =>
		dispatch({ type: ACTION_TYPES.DELETE_FILTER, name, value });

	const deleteFunction = fun =>
		dispatch({ type: ACTION_TYPES.DELETE_FUNCTION, fun });

	const updateFilter = (name, value) =>
		dispatch({ type: ACTION_TYPES.UPDATE_FILTER, name, value });
	const clearFilters = () => {
		dispatch({ type: ACTION_TYPES.CLEAR_FILTER });
		if (!isOnline) {
			updateFilter("tag", "vacancy");
		}
	};
	const handleSaveLocation = async location => {
		const formattedLocation = {
			city: location.city,
			country: location.country,
			number: location.number || "",
			box: location.box || "",
			street: location.street || "",
			zip: location.zip || "",
			latitude: location.latitude,
			longitude: location.longitude,
			viewport: location.viewport,
			iso_country: location.iso_country
		};
		return updateFilter(LOCATION, formattedLocation);
	};

	const handleSaveFunctions = functions => {
		const cleanFunctions = functions.map(f => {
			return {
				_id: f._id || null,
				name: f.name || null,
				sector: f.sector || null,
				seniority: f.seniority || null
			};
		});

		return updateFilter(FUNCTIONS, cleanFunctions);
	};

	const handleDeleteFunction = func => {
		let isCategoryStillInUse = functions.some(
			f =>
				_get(f, "sector.parent_sector._id") === func.parent_sector_id &&
				(f._id !== func.id ||
					(f._id === func.id && f.seniority !== func.seniority))
		);

		const isInUse = skills.find(
			s => s.parent_sector._id === func.parent_sector_id
		);

		if (isInUse && functions.length >= 1 && !isCategoryStillInUse) {
			setFunctionToDelete(func);
			setDisplayFunctionsWarningModal(true);
		} else {
			deleteFunction(func);
		}
	};

	const confirmDeleteFunction = () => {
		const categoryToDelete = functionToDelete.parent_sector_name;

		handleRemoveSkillsByCategories([categoryToDelete]);
		deleteFilter(FUNCTIONS, functionToDelete.id);
		setDisplayFunctionsWarningModal(false);
	};

	const handleRemoveSkillsByCategories = categories => {
		const updatedSkills = skills.filter(s => {
			return !categories.includes(_get(s, "parent_sector.name"));
		});
		return updateFilter(SKILLS, updatedSkills);
	};

	const getSkillsCategories = skills => {
		let categories = {};
		skills.forEach(s => {
			if (!categories[s.parent_sector._id]) {
				categories[s.parent_sector._id] = s.parent_sector.name;
			}
		});
		return categories;
	};

	const selectTemplate = template => {
		dispatch({ type: ACTION_TYPES.SET_ACTIVE_TEMPLATE, template });
	};

	const templates = _get(state, "templates", []).map(template => ({
		...template,
		label: template.name,
		value: template._id
	}));
	const filterByEmployment = name => {
		let data = state.filters.employment_types;
		if (data.indexOf(name) === -1) {
			data = [...data, name];
		} else {
			data = data.filter(type => type !== name);
		}

		return updateFilter("employment_types", data);
	};

	const fetchTemplates = async () => {
		const data = await searchTemplates();

		dispatch({
			type: ACTION_TYPES.FETCH_TEMPLATES,
			value: data
		});
	};

	function formatFilters(filters) {
		const newFilters = {
			...filters,
			remote_eligibility: filters.remoteEligilibityOptions
		};

		delete newFilters.daysInOffice;
		delete newFilters.remoteEligilibityOptions;

		if (
			filters.remoteEligilibityOptions.includes(HYBRID_OPTION.value) &&
			filters.daysInOffice.length > 0
		) {
			newFilters.office_days = filters.daysInOffice;
		}

		return newFilters;
	}

	return (
		<>
			{isOnline ? (
				<>
					<JobSearchOnline
						vacancyId={vacancyId}
						jobIndex={jobIndex}
						setJobIndex={setJobIndex}
					/>
				</>
			) : (
				<>
					{window.location.pathname.split("/").length === 2 ? (
						<BodyClassName className="jobs gray-bg">
							<div id="job-search">
								{isOnline && <CompanyDashboard />}
								<div className="container">
									<div className="row">
										{isOnline && (
											<div className="col-md-12">
												<TemplateSelect
													selectedTemplate={activeTemplate}
													templates={templates}
													selectTemplate={selectTemplate}
													fetchTemplates={fetchTemplates}
													clearFilters={clearFilters}
													isClient={false}
												/>
											</div>
										)}

										<div className="col-md-4">
											<SearchFilters
												functions={functions}
												skills={skills}
												languages={languages}
												location={location}
												distance={distance}
												updateDistance={distance =>
													updateFilter(DISTANCE, distance)
												}
												setDisplayFunctionsModal={setDisplayFunctionsModal}
												setDisplaySkillsModal={setDisplaySkillsModal}
												setDisplayLanguagesModal={setDisplayLanguagesModal}
												setDisplayAddressModal={setDisplayAddressModal}
												setDisplayTemplateModal={setDisplayTemplateModal}
												handleDeleteFunction={handleDeleteFunction}
												deleteFilter={deleteFilter}
												clearFilters={clearFilters}
												keywords={keywords}
												updateKeywordsValue={v => updateFilter(KEYWORDS, v)}
												updateSortBy={v => updateFilter(SORT_BY, v)}
												sortBy={sort_by}
												except_applied={except_applied}
												except_poked={except_poked}
												updateIsApplied={v => updateFilter(EXCEPT_APPLIED, v)}
												updateIsPoked={v => updateFilter(EXCEPT_POKED, v)}
												keywordError={_get(error, "detail.keywords", [])[0]}
												isOnline={isOnline}
												filterByPermanent={name => filterByEmployment(name)}
												filterByFixedTerm={name => filterByEmployment(name)}
												filterByInternShip={name => filterByEmployment(name)}
												employment_type={employment_types}
											/>
										</div>
										<div className="col-md-8" style={{ padding: "unset" }}>
											<SearchResults
												jobs={resolvedData}
												updateOffset={value => updateFilter(OFFSET, value)}
												status={status}
												isFetching={isFetching}
												setDisplayJobView={setDisplayJobView}
												setSelectedJob={setSelectedJob}
												templateId={_get(activeTemplate, "_id")}
												dispalyHideJob={() => {
													setDisplayHideJobModal(true);
												}}
												isOnline={isOnline}
											/>
										</div>
									</div>
								</div>
							</div>
						</BodyClassName>
					) : (
						<JobView
							job={selectedJob}
							offset={offset}
							updateOffset={value => updateFilter(OFFSET, value)}
							jobsData={resolvedData}
							setSelectedJob={setSelectedJob}
							searchStatus={status}
							templateId={_get(activeTemplate, "_id")}
							isOnline={isOnline}
							isDirectAccess={!!vacancyId}
							vacancyId={vacancyId}
							isDirectApply={isDirectApply}
						/>
					)}
				</>
			)}

			{displayLanguagesModal && (
				<LanguagesModal
					closeModal={() => setDisplayLanguagesModal(false)}
					saveLanguages={languages => updateFilter(LANGUAGES, languages)}
					selectedLanguages={languages}
				/>
			)}

			<AddressBoxModal
				active={displayAddressModal}
				onClose={() => setDisplayAddressModal(false)}
				onSave={location => {
					handleSaveLocation(location);
					setDisplayAddressModal(false);
				}}
				title="Edit Address"
				label="Company address"
				currentAddress={location}
				requiredFields={["country", "city"]}
			/>
			{displayFunctionsModal && (
				<div className="create-job">
					<FunctionsModal
						functions={functions}
						onClose={() => setDisplayFunctionsModal(false)}
						onSave={handleSaveFunctions}
						removeSkills={handleRemoveSkillsByCategories}
						isMainRequired={false}
						skillsCategories={getSkillsCategories(skills)}
						isSearch={true}
						isOnline={isOnline}
					/>
				</div>
			)}
			{displaySkillsModal && (
				<ListProvider>
					<SkillsModal
						skills={skills}
						saveSkills={skills => updateFilter(SKILLS, skills)}
						closeModal={() => setDisplaySkillsModal(false)}
						selectedFunctions={functions}
						isSearch={true}
						saveActionName={"Save to search"}
						showMustHave={false}
						canPropose={false}
						isOnline={isOnline}
					/>
				</ListProvider>
			)}
			{displayTemplateModal && (
				<CreateTemplateModal
					closeModal={() => setDisplayTemplateModal(false)}
					filters={state.filters}
					fetchTemplates={fetchTemplates}
					selectTemplate={selectTemplate}
					isClient={false}
				/>
			)}
			{displayFunctionsWarningModal && (
				<FunctionsWarningModal
					closeModal={() => setDisplayFunctionsWarningModal(false)}
					categoryToDelete={functionToDelete.parent_sector_name}
					confirmDeleteFunction={confirmDeleteFunction}
				/>
			)}

			{dispalyHideJobModal && (
				<HideJobModal
					closeModal={() => setDisplayHideJobModal(false)}
					nextAction={refetch}
					jobId={state.selectedJob}
				/>
			)}
		</>
	);
};

export default JobSearch;
