import React from "react";
import styles from "./email-template-category-dropdown.module.scss";
import { ReactComponent as ChevronIcon } from "static/icons/chevron-down.svg";
import { ReactComponent as TagIcon } from "static/icons/tag-2.svg";
import {
	MenuTrigger,
	Popover,
	Menu,
	Button,
	MenuItem
} from "react-aria-components";
import cx from "classnames";
import { CATEGORIES } from "modules/EmailTemplate/utils/constants";

export default function EmailTemplateCategoryDropdown({
	onChange,
	value: propValue = CATEGORIES[0].value
}) {
	const selectedCategory = CATEGORIES.find(({ value }) => value === propValue);

	return (
		<div className={styles.container}>
			<MenuTrigger>
				<Button type="button" className={styles.trigger}>
					<TagIcon />
					<span className={styles.label}>{selectedCategory.label}</span>
					<ChevronIcon />
				</Button>
				<Popover className={styles.popover}>
					<Menu onAction={onChange} className={styles.menu}>
						{CATEGORIES.map(({ label, value }) => (
							<MenuItem
								id={value}
								key={value}
								className={cx(styles.menuItem, {
									[styles.selected]: value === propValue
								})}
							>
								<span>{label}</span>
							</MenuItem>
						))}
					</Menu>
				</Popover>
			</MenuTrigger>
		</div>
	);
}
