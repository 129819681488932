//TODO move category list to root to improve performance
//TODO Fix use cases of scroll
//TODO Pass categories via props, don't fetch data
import React, { useState, useEffect, useMemo, useRef } from "react";
import { ModalContent } from "../CreateVacancyModal/components/ModalContent";
import { ModalFooter } from "../CreateVacancyModal/components/ModalFooter";
import { ModalSaveButton } from "../CreateVacancyModal/components/ModalSaveButton";
import { ModalCancelButton } from "../CreateVacancyModal/components/ModalCancelButton";
import { ReactComponent as InfoIconYellow } from "static/icons/info-icon-yellow.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-icon-black-light.svg";
import { ReactComponent as InfoIconGreyTriangle } from "static/icons/info-icon-grey-triangle.svg";
import { ReactComponent as DeleteIconGrey } from "static/icons/delete-button-black.svg";
import styles from "./skills-picker-modal.module.scss";
import { SearchInput } from "common/SearchInput";
import produce from "immer";
import { useGetCategoriesList } from "modules/vacancy/create-vacancy/api/useGetCategoriesList";
import { formatCategoriesList } from "modules/vacancy/create-vacancy/utils/helper";
import CreateVacancyModal from "../CreateVacancyModal/CreateVacancyModal";
import {
	GET_SKILLS_QUERY_KEY,
	useGetSkillsList
} from "modules/vacancy/create-vacancy/api/useGetSkillsList";
import useDebouncedValue from "hooks/useDebouncedValue";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";
import { SKILLS_LEVELS_LABELS } from "modules/vacancy/create-vacancy/utils/constants";
import { ReactStar } from "common/ReactStar";
import Tooltip from "common/Tippy";
import { CategorySelect } from "../CategorySelect";
import { ReactComponent as SkillsIcon } from "static/icons/skills-icon.svg";
import { generateId } from "config/helpers";
import { useSuggestSkill } from "../../api/useSuggestSkill";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import LoadingSpinner from "common/LoadingSpinner/LoadingSpinner";

const SkillsPickerModal = ({
	onEscapeKeyDown,
	onSave: onSaveProp,
	onCancel,
	chooseCategories = [],
	skillsList: skillsListProp,
	choosenCategories = []
}) => {
	const ref = useRef();
	const [searchTerm, setSearchTerm] = useState("");
	const [category, setCategory] = useState();
	const [choosenSkills, setChoosenSkills] = useState([]);
	const [error, setError] = useState(false);
	const debouncedSearch = useDebouncedValue(searchTerm);
	const [ignoreDebounce, setIgnoreDebounce] = useState(false);
	const saveClicked = useRef(false);

	const [
		proposeSkill,
		{ isLoading: isSuggestSkillLoading }
	] = useSuggestSkill();
	const { data: categoriesList } = useGetCategoriesList();
	const { data: skillsData, isLoading } = useGetSkillsList(
		{
			limit: 150,
			offset: 0,
			sector_id: category?.value,
			term: ignoreDebounce ? searchTerm : debouncedSearch
		},
		{
			enabled: !!category
		}
	);

	const categoriesListFormatted = useMemo(
		() => (categoriesList ? formatCategoriesList(categoriesList) : []),
		[categoriesList]
	);

	const skillsList = skillsData?.data ? skillsData.data : [];

	useEffect(() => {
		if (skillsListProp) {
			setChoosenSkills(skillsListProp);
		}
	}, [skillsListProp]);

	useEffect(() => {
		if (debouncedSearch === searchTerm) {
			setIgnoreDebounce(false);
		}
	}, [debouncedSearch, searchTerm]);

	useEffect(() => {
		if (choosenCategories?.length && categoriesListFormatted.length) {
			const selectedCateogory = choosenCategories[0];
			const parent = categoriesListFormatted.find(({ children }) => {
				if (children) {
					const ids = children.map(({ value }) => value);
					return ids.includes(selectedCateogory.value);
				} else {
					return false;
				}
			});

			const category = { ...selectedCateogory, parent };
			setCategory(category);
		}
	}, [choosenCategories, categoriesListFormatted]);

	const onDeleteItem = id => {
		const list = choosenSkills.filter(skill => skill._id !== id);
		setChoosenSkills(list);

		if (saveClicked.current) {
			validate(list);
		}
	};

	const onUnselectedSkillRatingChange = (id, proficiency) => {
		const selectedItem = skillsList.find(skill => skill._id === id);
		const list = [
			...choosenSkills,
			{
				...selectedItem,
				proficiency,
				mustHave: false,
				categoryId: category.value
			}
		];

		setChoosenSkills(list);

		setError("");

		if (unselectedSkillsList.length === 1) {
			setSearchTerm("");
			setIgnoreDebounce(true);
		}
	};

	const handleSearchChange = event => {
		setSearchTerm(event.target.value);
	};

	const onChoosenSkillRatingChange = (id, value) => {
		const skills = produce(choosenSkills, draft => {
			const selectedSkill = draft.find(skill => skill._id === id);
			selectedSkill.proficiency = value;
		});

		setChoosenSkills(skills);
	};

	const onCategoryChange = value => setCategory(value);

	const onSave = () => {
		saveClicked.current = true;
		const error = validate(choosenSkills);

		if (!error) onSaveProp(choosenSkills);
	};

	const validate = list => {
		let error;

		if (list.length === 0) {
			error = "Skills list can not be empty";
		}

		setError(error);
		return error;
	};

	const filterCategories = categories => {
		const newCategories = categories?.map(category => {
			const { children } = category;

			if (children) {
				const newChildren = children.filter(({ value }) =>
					chooseCategories.includes(value)
				);

				return {
					...category,
					children: newChildren
				};
			} else {
				return category;
			}
		});

		return newCategories.filter(({ children, id }) => {
			if (children?.length > 0) {
				return true;
			} else if (chooseCategories.includes(id)) {
				return true;
			} else {
				return false;
			}
		});
	};

	const categoriesFiltered = useMemo(
		() => filterCategories(categoriesListFormatted),
		[categoriesListFormatted]
	);

	const chosenSkillsIds = choosenSkills.map(({ _id }) => _id);

	const unselectedSkillsList = skillsList.filter(
		({ _id }) => !chosenSkillsIds.includes(_id)
	);

	const suggest = !!searchTerm && unselectedSkillsList.length === 0;
	let suggestedSkill;

	if (suggest) {
		suggestedSkill = {
			_id: generateId(),
			name: searchTerm
		};
	}

	const onMustHaveClick = index => {
		setChoosenSkills(list => {
			const newList = produce(list, draft => {
				const value = draft[index].mustHave;

				draft[index].mustHave = !value;
			});

			return newList;
		});
	};

	const onSuggestSkill = proficiency => {
		const parent_sector = {
			group_name: category.parent ? category.parent.label : "",
			name: category.label,
			_id: category.value
		};

		const sector = {
			_id: category.parent ? category.parent.id : category.value,
			name: category.label
		};

		const payload = {
			sector,
			parent_sector,
			_id: suggestedSkill._id,
			proficiency,
			mustHave: false,
			categoryId: category.value,
			name: suggestedSkill.name,
			skill_id: suggestedSkill._id
		};

		proposeSkill(payload, {
			onSuccess: () => {
				setError("");
				toaster.success("Successfully proposed the skill!");
				queryCache.invalidate(GET_SKILLS_QUERY_KEY);
			}
		});

		setChoosenSkills([
			...choosenSkills,
			{
				parent_sector,
				_id: suggestedSkill._id,
				proficiency,
				mustHave: false,
				categoryId: category.value,
				name: suggestedSkill.name
			}
		]);
		setSearchTerm("");
		setIgnoreDebounce(true);
	};

	return (
		<CreateVacancyModal
			className={styles.dialogContent}
			onClose={onEscapeKeyDown}
		>
			<h2 className={styles.modalTitle}>Skills</h2>
			<ModalContent>
				<div className={styles.contentContainer}>
					<div className={styles.label}>Category</div>
					<div className={styles.label}>Filter</div>
					<div className={styles.separator} />
					<div className={styles.addedSkillsLabel}>
						Added skills (
						<InfoIconYellow />
						<div className={styles.infoContainer}>&nbsp; = &nbsp;must have</div>
						&nbsp;
						<Tooltip
							content={
								<div className={styles.notificationContainer}>
									Marking a skill as <b>must have</b> will exclude the
									candidates lacking that skill from the potential matches
									results. You can mark up to 7 skills as must have.
								</div>
							}
							theme="dark"
						>
							<InfoIcon />
						</Tooltip>
						)
					</div>
					<div className={styles.categorySelectWrapper}>
						<CategorySelect
							onChange={onCategoryChange}
							menu={categoriesFiltered}
							value={category?.label}
						/>
					</div>
					<SearchInput
						placeholder="Start typing..."
						className={styles.searchInputContainer}
						inputClassName={styles.searchInput}
						onChange={handleSearchChange}
						value={searchTerm}
					/>
					{(isLoading || isSuggestSkillLoading) && (
						<div className={styles.loading}>
							<LoadingSpinner className={styles.spinner} />
						</div>
					)}
					{chooseCategories.length > 0 ? (
						!isLoading &&
						!isSuggestSkillLoading && (
							<div className={styles.skillsListContainer}>
								{suggestedSkill ? (
									<div className={styles.newSkillContainer}>
										<div className={styles.skill}>
											<Tooltip
												overflow="hidden"
												theme="dark"
												content={suggestedSkill.name}
											>
												<div className={styles.skillName}>
													{suggestedSkill.name}
												</div>
											</Tooltip>

											<div className={styles.star}>
												<ReactStar
													rating={0}
													onRate={e => onSuggestSkill(e.rating)}
													titles={SKILLS_LEVELS_LABELS}
												/>
											</div>
										</div>
										<div className={styles.addSkillMessage}>
											We can’t find this skill, add a new one!
										</div>
									</div>
								) : (
									unselectedSkillsList.map(({ _id, name }) => (
										<div key={_id} className={styles.skill}>
											<Tooltip overflow="hidden" theme="dark" content={name}>
												<div ref={ref} className={styles.skillName}>
													{name}
												</div>
											</Tooltip>
											<div className={styles.star}>
												<ReactStar
													rating={0}
													onRate={e =>
														onUnselectedSkillRatingChange(_id, e.rating)
													}
													titles={SKILLS_LEVELS_LABELS}
												/>
											</div>
										</div>
									))
								)}
							</div>
						)
					) : (
						<div className={styles.chooseFunctionMessage}>
							<div className={styles.chooseMessageLabel}>
								To add a skill, start by adding a function from the previous
								section
							</div>
							<SkillsIcon />
						</div>
					)}
					<div className={styles.choosenSkillsContainer}>
						{choosenSkills.map(({ _id, name, proficiency, mustHave }, i) => (
							<div className={styles.choosenSkillRow} key={_id}>
								<button
									onClick={() => onMustHaveClick(i)}
									className={styles.mustHaveButton}
								>
									{mustHave ? <InfoIconYellow /> : <InfoIconGreyTriangle />}
								</button>
								<Tooltip overflow="hidden" theme="dark" content={name}>
									<div className={styles.skillName}>{name}</div>
								</Tooltip>
								<div className={styles.chooseSkillStar}>
									<ReactStar
										rating={proficiency}
										onRate={e => onChoosenSkillRatingChange(_id, e.rating)}
										titles={SKILLS_LEVELS_LABELS}
									/>
								</div>
								<button
									className={styles.deleteButton}
									aria-labelledby="delete-button-label"
									onClick={() => onDeleteItem(_id)}
								>
									<span id="delete-button-label" hidden>
										Delete skill
									</span>

									<DeleteIconGrey />
								</button>
							</div>
						))}
					</div>
				</div>
			</ModalContent>
			<ModalFooter className={styles.modalFooter}>
				{error ? (
					<CreateVacancyFieldError error={{ message: error }} />
				) : (
					<div />
				)}
				<ModalCancelButton onClick={onCancel} />
				<ModalSaveButton onClick={onSave} />
			</ModalFooter>
		</CreateVacancyModal>
	);
};

export default SkillsPickerModal;
