import styled from "styled-components";
import Modal from "common/modal/Modal";

export const SelectTitle = styled.div`
	display: block;
	font-weight: 500;
	color: #58626b;
	text-transform: uppercase;
	margin-bottom: 5px;
`;

export const ErrorLabel = styled.div`
	color: #f76d75;
	height: auto;
	line-height: 16px;
	min-height: 31px;
	padding: 0px 0 10px 15px;
	text-transform: none;
	white-space: normal;
	clear: both;
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
`;

export const StyledModal = styled(Modal)`
	.modal-actions {
		float: right;
		margin-top: 30px;
	}
	.modal-actions div:first-child {
		float: right;
		margin-left: 15px;
	}
`;

export const NoteContainer = styled.div`
	background-color: #f8f8f9;
	border: 1px solid #eef0f1;
	padding: 20px;
	margin-bottom: 13px;
	font-weight: 400;
	div {
		color: #68717f;
	}
	p {
		font-weight: 400;
		font-size: 14px;
		margin-top: 10px;
	}
	button {
		font-size: 14px;
	}
`;
