import React from "react";
import { filtersRenderMap, operators } from "./constant";
import { v4 as uuid } from "uuid";
import get from "lodash/get";
import isNumber from "lodash/isNumber";
import NewMultipleSelectItems from "common/NewMultipleSelect/NewMultipleSelectItems";
import { dateToTimestamp } from "config/helpers";
import { formatDateToCalendarObject } from "config/helpers";
import produce from "immer";
import flatMapDeep from "lodash/flatMapDeep";
import { DATE_LANG } from "./constant";
import isEmpty from "lodash/isEmpty";

export const FILTER_TRANSITION_TIP_PREFERENCES =
	"filter_transition_tip_preferences";

export const setNewFilterTransitionTipPreferences = (key, value) => {
	const transitionTipPreferences = JSON.parse(
		localStorage.getItem(FILTER_TRANSITION_TIP_PREFERENCES)
	);
	localStorage.setItem(
		FILTER_TRANSITION_TIP_PREFERENCES,
		JSON.stringify({
			...transitionTipPreferences,
			[key]: value
		})
	);
};

export const getNewFilterTransitionTipPreferences = key => {
	const attribute = JSON.parse(
		localStorage.getItem(FILTER_TRANSITION_TIP_PREFERENCES)
	);
	return attribute?.[key];
};

export const renderFilterValues = (field, value) => {
	const functionName = get(filtersRenderMap, field, "default");
	switch (functionName) {
		case "renderLabelValues":
			return renderLabelValues(value);
		case "renderLabelValuesWithRating":
			return renderLabelValues(value, true);
		case "renderBooleanValues":
			return renderBooleanValues(value);
		case "renderLocationDescription":
			return renderLocationDescription(value);
		case "renderLocationTypeValues":
			return renderLocationTypeValues(value);
		case "renderSalaryValue":
			return renderSalaryValue(value, field);
		case "renderSkillsValues":
			return renderSkillsValues(value);
		case "renderFunctionsValues":
			return renderFunctionsValues(value);
		case "renderDateValues":
			return renderDateValues(value);
		case "renderAvatarValues":
			return renderAvatarValues(value);
		default:
			return value || "";
	}
};

export function schemValidator(smartQuerySchema) {
	let stack = [...smartQuerySchema];

	for (let index = 0; index < smartQuerySchema.length; index++) {
		const element = smartQuerySchema[index];
		if (
			element?.name &&
			["and", "or"].includes(smartQuerySchema[index + 3]?.value) &&
			smartQuerySchema[index + 1]?.value !== smartQuerySchema[index + 3]?.value
		) {
			// insert "("
			stack.splice(index, 0, {
				id: uuid(),
				...operators[0] /* ( */
			});

			// insert ")"
			stack.splice(index + 4, 0, {
				id: uuid(),
				...operators[1] /* ) */
			});
		}
	}

	return stack;
}

export function insertOperators(smartQuerySchema) {
	let newList = [...smartQuerySchema];
	if (smartQuerySchema.length === 2) {
		if (smartQuerySchema[0]?.name && smartQuerySchema[1]?.name)
			newList.splice(1, 0, {
				id: uuid(),
				...operators[2] /* AND */
			});
		return newList;
	}

	for (let index = 0; index < smartQuerySchema.length; index++) {
		const element = smartQuerySchema[index];
		const groupOperator = smartQuerySchema[index + 1];
		const operator = smartQuerySchema[index + 3];
		if (
			(element?.name && smartQuerySchema[index + 1]?.name) ||
			(element?.name &&
				["and", "or"].includes(operator?.value) &&
				["and", "or"].includes(groupOperator?.value) &&
				groupOperator?.value !== operator?.value &&
				!["(", ")"].includes(smartQuerySchema[index + 2]?.value))
		) {
			// insert "("
			newList.splice(index, 0, {
				id: uuid(),
				...operators[0] /* ( */
			});
			// insert "AND"
			if (newList[index + 2]?.value !== "and")
				newList.splice(index + 2, 0, {
					id: uuid(),
					...operators[2] /* AND */
				});
			// insert ")"
			newList.splice(index + 4, 0, {
				id: uuid(),
				...operators[1] /* ) */
			});
		}
	}

	return newList;
}

export function flatColumns(columns) {
	return columns.flatMap(item => item.children || []);
}

export function isSmartQueryValid(smartQuerySchema) {
	if (!smartQuerySchema.length) return true;

	for (let i = 0; i < smartQuerySchema.length; i++) {
		const element = smartQuerySchema[i];

		if (!element.isParentheses) return true;
	}

	return false;
}

export function checkIfSchemaIsCorrect(smartQuerySchema) {
	let stack = [];
	let operationsStack = [];
	let unmatchedParentheses = [];
	let filterStack = [];
	let error = null;
	const parentheses = [")", "("];
	smartQuerySchema.forEach((element, index) => {
		// Check parentheses
		if (element.value === "(") {
			stack.push(index);
		} else if (element.value === ")") {
			if (!stack.length) unmatchedParentheses.push(index);
			stack.pop();
		}

		// Check operators
		if (["and", "or"].includes(element.value)) {
			if (
				(smartQuerySchema[index - 1]?.value !== ")" &&
					!smartQuerySchema[index - 1]?.name) ||
				(smartQuerySchema[index + 1]?.value !== "(" &&
					!smartQuerySchema[index + 1]?.name)
			) {
				operationsStack.push(index);
			}
		}
		//Check if the element between two filters is AND, OR

		if (parentheses.includes(element.value)) {
			if (element.value === ")" && smartQuerySchema[index + 1]?.value === "(") {
				error = "Between two groups must be an AND or OR";
				stack.push(...[index, index + 1]);
			}
			if (element.value === "(" && smartQuerySchema[index + 1]?.value === ")") {
				error = "Group can't be empty";
				stack.push(...[index, index + 1]);
			}
		}

		if (element.name && smartQuerySchema[index + 1]?.name) {
			stack.push(index);
			error = `Insert AND, OR between two filters`;
		}

		if (
			element.name &&
			(smartQuerySchema[index - 1]?.value === ")" ||
				smartQuerySchema[index + 1]?.value === "(")
		) {
			stack.push(index);
			error = `Insert AND, OR between filter and ( , )`;
		}
		// if (isEmpty(element.value)) filterStack.push(index); // Check if filter item is empty or doesn't have a value
	});

	unmatchedParentheses = unmatchedParentheses.concat([
		...stack,
		...operationsStack,
		...filterStack
	]);
	return {
		correct: unmatchedParentheses.length === 0,
		unmatchedParentheses,
		error
	};
}

export function parseSmartQuery(smartQuerySchema) {
	let index = 0;
	let lastOperator = ""; // if there is a sequence of the same operator just push them to the same array.

	function parseGroup() {
		const item = smartQuerySchema[index];
		index++; // skip if item is "("
		if (item?.value === "(") {
			const group = parseTerm();
			index++; // skip ")"
			return group;
		} else {
			return item;
		}
	}

	function parseTerm() {
		let left = parseGroup();
		while (
			index < smartQuerySchema.length &&
			(smartQuerySchema[index]?.value === "or" ||
				smartQuerySchema[index]?.value === "and")
		) {
			const operator = smartQuerySchema[index].value;
			index++; // skip operator
			const right = parseGroup();
			if (lastOperator === operator && left[operator])
				left[operator].push(right);
			else left = { [operator]: [left, right] };
			lastOperator = operator;
		}
		if (index) {
			if (left?.field) left = { and: [left] };
			return left;
		}
	}

	return parseTerm();
}

const renderDateValues = dates => {
	if (dates) {
		if ("start" && "end" in dates) {
			const dateStart = formatDate(dates.start.toDate());
			const dateEnd = formatDate(dates.end.toDate());

			return `${dateStart} and ${dateEnd}`;
		} else {
			return formatDate(dates.toDate());
		}
	}
};

export const updateMap = (mapParam, id, _object) => {
	const innerObject = mapParam.get(id);

	const newObject = {
		...innerObject,
		..._object
	};

	const newMap = produce(mapParam, draft => {
		draft.set(id, newObject);
	});

	return newMap;
};

export const serializeFilters = data => {
	const valueToStore = new Map();

	for (const [key, value] of data.state.state) {
		if (key === "FREELANCERS_FILTER_ID") {
			const newFilter = value.filters.map(
				({ id, label, name, operator, type, value }) => {
					let newValue = value;
					let valueType = "generic";
					if (value[0]?.place) {
						valueType = "address";
						newValue = formatTypeLocation({ value }, true);
					} else if (value?.start && value?.end) {
						valueType = "date";
						newValue = [
							dateToTimestamp(
								`${value.start.day}-${value.start.month}-${value.start.year}`
							),
							dateToTimestamp(
								`${value.end.day}-${value.end.month}-${value.end.year}`
							)
						];
					} else if (value?.day && value?.month && value?.year) {
						valueType = "date";
						newValue = [
							dateToTimestamp(`${value.day}-${value.month}-${value.year}`)
						];
					}
					return {
						id,
						label,
						name,
						operator,
						type,
						value: newValue,
						valueType
					};
				}
			);
			valueToStore.set(key, {
				query: value.query,
				filters: newFilter,
				offset: value.offset,
				sortBy: value.sortBy,
				limit: value.limit
			});
		}
	}

	return JSON.stringify(Array.from(valueToStore.entries()));
};

export const deserializeFilters = async value => {
	const data = JSON.parse(value);
	const state = new Map();

	for (const [key, value] of data) {
		state.set(key, {
			...value,
			filters: await Promise.all(
				value.filters.map(async ({ valueType, value, ...rest }) => {
					let newValue;

					if (value.viewport) {
						newValue = await formatLocation(value);
					} else if (valueType === "date" && value?.length === 2) {
						newValue = {
							start: formatDateToCalendarObject(value[0]),
							end: formatDateToCalendarObject(value[1])
						};
					} else if (valueType === "date" && value.length === 1) {
						newValue = formatDateToCalendarObject(value[0]);
					} else {
						newValue = value;
					}

					return {
						...rest,
						value: newValue
					};
				})
			)
		});
	}

	return {
		state: {
			state,
			isHydrationCompleted: true
		}
	};
};

export const formatLocation = async value => {
	let searchObj = {};
	if ("place_id" in value) {
		searchObj = { placeId: value.place_id };
	} else {
		searchObj = {
			location: { lat: value.latitude, lng: value.longitude }
		};
	}
	const location = await getLocation(searchObj);

	return [
		{
			place: {
				...location,
				description: renderLocationValues(value)
			},
			radius: get(value, "distance", 0)
		}
	];
};

const renderLocationValues = location => {
	if (!location) {
		return "";
	}
	if (get(location, "description", "") !== "") {
		return get(location, "description");
	}
	return `${get(location, "number", "") && get(location, "number") + " "}${get(
		location,
		"box",
		""
	) && get(location, "box", "") + " "}${get(location, "street", "") &&
		get(location, "street", "") + " "}${get(location, "city", "") &&
		get(location, "city", "") + " "}${get(location, "country", "") &&
		get(location, "country", "") + " "}${get(location, "zip")}`;
};

const renderSkillsValues = skills => {
	return (skills || [])
		.map(skill => {
			return (
				skill.children.map(s => `${s.label} ${s.rating}/5`).join(" - ") +
				` (${skill.label})`
			);
		})
		.join(" / ");
};

const renderFunctionsValues = functions => {
	return (functions || [])
		.map(funtcion => {
			return (
				funtcion.children.map(f => f.label).join(" - ") + ` (${funtcion.label})`
			);
		})
		.join(" / ");
};

const renderBooleanValues = b => {
	return b ? "Yes" : "No";
};

const renderLocationTypeValues = locationType => {
	if (locationType === "on_site") {
		return "On site";
	} else if (locationType === "full_remote") {
		return "Full remote";
	} else {
		return "";
	}
};

const renderLabelValues = (values, withRating = false) => {
	return get(values, "[0].children", [])
		.map(value => {
			return value.label + (withRating ? ` ${value.rating}/5` : "");
		})
		.join(" - ");
};

const renderLocationDescription = value => {
	return (
		get(value, "[0].place.description", "") +
		(+get(value, "[0].radius", 0) > 0
			? " - " + get(value, "[0].radius", 0) + " km"
			: "")
	);
};

const renderAvatarValues = value => {
	return <NewMultipleSelectItems selectedItems={value[0]?.children} />; // TODO refactor this
};

const renderSalaryValue = (value, field = "") => {
	if (
		!isNumber(value) &&
		["matching_rate", "matching_score", "amount", "cost", "rate"].includes(
			field
		)
	) {
		return value ? "Yes" : "No";
	}
	return value / 100;
};

const formatDate = date => {
	return new Intl.DateTimeFormat("en-BE", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric"
	}).format(date);
};

export function reverseSmartQueryParser(query) {
	const result = [];

	function processCondition(condition) {
		if (condition.and || condition.or) {
			result.push({
				id: uuid(),
				value: "(",
				label: "(",
				isParentheses: true
			});

			const subConditions = condition.and || condition.or;
			subConditions.forEach((subCondition, index) => {
				processCondition(subCondition);
				if (index < subConditions.length - 1) {
					result.push({
						id: uuid(),
						value: condition.and ? "and" : "or",
						label: condition.and ? "AND" : "OR",
						isOperator: true
					});
				}
			});

			result.push({
				id: uuid(),
				value: ")",
				label: ")",
				isParentheses: true
			});
		} else {
			result.push({
				id: uuid(),
				label: condition.field,
				field: condition.field,
				op: condition.op,
				value: condition.value
			});
		}
	}

	processCondition(query);
	result.pop();
	result.shift();
	return result;
}
const getLocation = searchObj => {
	const geocoder = new window.google.maps.Geocoder();
	return new Promise(resolve => {
		geocoder.geocode(searchObj).then(response => {
			resolve(get(response, "results[0]"));
		});
	});
};

export const formatTypeLocation = (filter, forSearch = false) => {
	const { value } = filter;
	const newValue = value || [];

	const valuesFormatted = newValue.map(({ place, radius }) => {
		const address = {};

		place.address_components.map(elem => {
			if (elem.types[0] === "country") {
				address[elem.types[0]] = elem.long_name;
				address.iso_country = elem.short_name;
				return;
			}
			return (address[elem.types[0]] = elem.long_name);
		});

		address.latitude = place.geometry.location.lat();
		address.longitude = place.geometry.location.lng();
		address.street =
			address.route ||
			address.neighborhood ||
			address.premise ||
			address.sublocality_level_1 ||
			address.sublocality_level_2 ||
			address.sublocality_level_3 ||
			address.sublocality_level_4 ||
			address.sublocality_level_5 ||
			address.subpremise ||
			address.sublocality ||
			address.jpns ||
			"";
		address.country = address.country || "";
		address.is_main = false;
		address.zip = address.postal_code || "";
		address.latitude = address.latitude || "";
		address.longitude = address.longitude || "";
		address.city =
			address.locality ||
			address.administrative_area_level_1 ||
			address.administrative_area_level_2 ||
			address.administrative_area_level_3 ||
			address.administrative_area_level_4 ||
			address.administrative_area_level_5 ||
			"";
		address.number = address.street_number || "";
		address.box = address.box || "";
		address.iso_country = address.iso_country || "";

		const values = place?.geometry?.viewport;
		let viewport = null;

		if (values) {
			viewport = {
				northeast: {
					lat: values.getNorthEast().lat(),
					lng: values.getNorthEast().lng()
				},
				southwest: {
					lat: values.getSouthWest().lat(),
					lng: values.getSouthWest().lng()
				}
			};
		}

		const typesSupportedAutoComplete = [
			"locality",
			"sublocality",
			"postal_code",
			"country",
			"administrative_area_level_1",
			"administrative_area_level_2",
			"administrative_area_level_3",
			"locality",
			"political"
		];

		let isSendViewport = false;

		typesSupportedAutoComplete.forEach(type => {
			if (place.types.includes(type)) isSendViewport = true;
		});

		const res = {
			zip: address.zip,
			country: address.country,
			number: address.number,
			iso_country: address.iso_country,
			city: address.city,
			street: address.street,
			latitude: address.latitude,
			is_main: address.is_main,
			box: address.box,
			longitude: address.longitude,
			distance_unit: "km",
			distance: parseInt(radius),
			viewport: isSendViewport ? viewport : [],
			place_id: forSearch ? place.place_id : undefined
		};

		return res;
	});

	return valuesFormatted.length ? valuesFormatted[0] : null;
};

export const getComponentByOperator = (type, operator, componentByOperator) => {
	const node = componentByOperator[type];
	if (node && node[operator]) {
		return node[operator];
	} else {
		return node.default;
	}
};

export const findFilterPayload = (id, columns) => {
	const fields = flatMapDeep(columns, el => {
		return el.children ? el.children : el;
	});

	const { payload } = fields.find(({ name }) => name === id);
	return payload;
};

export const SavedFiltersformatDate = (date, formatOptions) => {
	return new Intl.DateTimeFormat(DATE_LANG, formatOptions).format(
		window.moment.unix(date)
	);
};

export function reverseSavedFiltersSmartQueryParser(query, sectionId) {
	const result = [];
	let fieldsCounter = 0;

	function processCondition(condition) {
		if (condition.and || condition.or) {
			result.push({
				id: uuid(),
				value: "(",
				label: "(",
				isParentheses: true
			});

			const subConditions = condition.and || condition.or;
			subConditions.forEach((subCondition, index) => {
				processCondition(subCondition);
				if (index < subConditions.length - 1) {
					result.push({
						id: uuid(),
						value: condition.and ? "and" : "or",
						label: condition.and ? "AND" : "OR",
						isOperator: true
					});
				}
			});

			result.push({
				id: uuid(),
				value: ")",
				label: ")",
				isParentheses: true
			});
		} else {
			fieldsCounter++;
			result.push({
				id: uuid(),
				_id: sectionId,
				label: condition.field,
				field: condition.field,
				op: condition.op,
				value: condition.value
			});
		}
	}

	processCondition(query);
	if (result.length > 0 && result[0].isParentheses && result[0].value === "(") {
		result.shift();
	}
	if (
		result.length > 0 &&
		result[result.length - 1].isParentheses &&
		result[result.length - 1].value === ")"
	) {
		result.pop();
	}
	return { smartQuerySchema: result, fieldsCounter };
}

export const getSmartQuerySchemaAndCounter = section => {
	if (section?.smart_query_filter) {
		return reverseSavedFiltersSmartQueryParser(
			section?.smart_query_filter,
			section?._id
		);
	}

	return {
		advancedFilterSchema: section?.fields || [],
		fieldsCounter: section?.fields?.length
	};
};

export function countEmptyValues(data) {
	const ignoredValues = ["or", "and", "(", ")"];

	const count = data.reduce((count, item) => {
		if (ignoredValues.includes(item.label)) {
			return count;
		}
		if (!Object.prototype.hasOwnProperty.call(item, "value")) {
			return count + 1;
		}
		if (typeof item.value === "boolean") {
			return count;
		}
		return count + isEmpty(item.value);
	}, 0);

	const isValueEmpty = count > 0;

	return { count, isValueEmpty };
}
