import Immutable from "seamless-immutable";
import { FETCH_USER_FILES } from "../actions/actionTypes";

const initialState = {
	data: [],
	offset: 0
};

export default function(state = initialState, action) {
	switch (action.type) {
		case FETCH_USER_FILES:
			return Immutable.merge(state, action.data);
		default:
			return state;
	}
}
