import React from "react";
import { ReactComponent as AddIcon } from "static/icons/PlusAdd.svg";
import style from "./missions-list-primary-header.module.scss";
import HeaderButton from "modules/freelancers/components/HeaderButton/HeaderButton";
import { browserHistory } from "react-router";
import { goBack } from "config/helpers";
import { ReactComponent as BackArrow } from "static/icons/IconBack.svg";
import { getCreateMissionsRoute } from "modules/CreateMission/CreateMission";

const MissionsListPrimaryHeader = ({ isCreateMissionEnabled }) => {
	return (
		<div className={style.headerContainer}>
			<div className={style.headerCandidate}>
				<div className={style.first}>
					<button onClick={goBack}>
						<BackArrow />
					</button>
					<h3>Missions</h3>
				</div>

				{isCreateMissionEnabled && (
					<div className={style.btnContainer}>
						<HeaderButton
							onClick={() => browserHistory.push(getCreateMissionsRoute())}
							text="Create mission"
							className="buttonAdd"
							icon={<AddIcon />}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default MissionsListPrimaryHeader;
