import React, { useState, useContext } from "react";

import _get from "lodash/get";
import { queryCache } from "react-query";
import { useToggle } from "react-use";
import _groupBy from "lodash/groupBy";
import _find from "lodash/find";
import GlobalTooltip from "common/GlobalTooltip";
import EditableSection from "modules/candidate/Profile/Pages/Profile/components/EditableSection";
import s from "./skills.module.scss";
import styles from "modules/candidate/Profile/components/SkillsCard/skills-card.module.scss";
import { ReactComponent as AddIcon } from "static/icons/focus-add.svg";
import SkillsDialog from "./SkillsDialog";
import {
	ListContext,
	ListProvider
} from "common/editablelist/Next/listContext";
import useUpdateInternalData from "modules/candidate/Profile/api/useUpdateInternalData";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import useLoadSkills from "./api/useLoadSkills";
import useListSectors from "./api/useListSectors";
import { profileOverviewKey } from "modules/candidate/Profile/api/useViewCandidateInternal";
import { editHistoryKey } from "modules/candidate/Profile/api/useEditHistory";
import Portal from "common/Portal";
import { ReactComponent as BotIcon } from "static/icons/bot.svg";
import LeaveHook from "common/LeaveHook";
import useProposeSkill from "./api/useProposeSkill";
import useUnsavedChangesDialog from "modules/candidate/Profile/Store/useUnsavedChangesDialog";
import { viewOverviewKey } from "modules/candidate/Profile/api/useOverview";

export const SkillsByCategory = skills => {
	const categories = _groupBy(skills, "parent_sector.name");
	return categories;
};

const Skills = ({
	skills,
	selectedFunctions = [],
	cartTitle,
	showMustHave = true,
	saveActionName,
	isSearch,
	modalHeadText,
	saveActionStyle,
	canPropose = true,
	cv_id
}) => {
	const [on, toggle] = useToggle(false);
	const [skillsOptions, setSkillsOptions] = useState([]);
	const [isDirty, setIsDirty] = useState(false);
	const { state } = useContext(ListContext);
	const [
		isUnsavedChangesDialogOpen,
		toggleUnsavedChangesDialogOpen
	] = useUnsavedChangesDialog();

	const skillsBycategory = SkillsByCategory(skills);
	const keys = Object.keys(skillsBycategory);

	const onClose = () => toggle(false);

	const { data = [] } = useListSectors();

	const [mutate] = useLoadSkills({
		onSuccess: res => {
			setSkillsOptions(res.data || []);
		},
		onError: error => {
			toaster.danger(_get(error, "detail", "Something went wrong"));
		}
	});

	const [mutateSkills] = useProposeSkill({
		onSuccess: () => {
			toaster.success("Successfully proposed the skill!");
			resetDirty();
		},
		onError: error => {
			toaster.danger(_get(error, "detail", "Something went wrong"));
		}
	});

	const loadSkills = ({ sector, term }) => {
		if (sector) {
			mutate({
				sector_id: sector,
				limit: 150,
				offset: 0,
				term
			});
		}
	};

	const renderItems = (name, score, is_bot) =>
		score && (
			<div className={styles.tag}>
				<p>{name ? `${name} (${score}/5)` : "N/A"}</p>
				{is_bot && <BotIcon className={s.botIcon} />}
			</div>
		);

	const countValue =
		skills?.length > 0 &&
		keys.map(item => (
			<div key={item} className={styles.section}>
				<div className={styles.row}>
					{skillsBycategory[item]?.length > 4 &&
						skillsBycategory[item]
							.slice(4)
							.map(({ name, score, is_bot }) =>
								renderItems(name, score, is_bot, item)
							)}
				</div>
			</div>
		));

	const [
		updateStatus,
		{ isLoading: isLodingUpdateInternalData }
	] = useUpdateInternalData({
		onSuccess() {
			toaster.success("Candidate skills has successfully been updated.");
			queryCache.invalidateQueries(profileOverviewKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(editHistoryKey);
			//TODO create hook for this logic useDirty()
			resetDirty();
			onClose();
		},
		onError(err) {
			toaster.danger(
				_get(err, "detail.fields_data[0]", "Something went wrong")
			);
		}
	});

	const onSave = payload => {
		const listskills = payload.map(sk => {
			return {
				name: _get(sk, "name"),
				parent_sector: _get(sk, "parent_sector"),
				score: _get(sk, "score"),
				_id: _get(sk, "_id")
			};
		});
		const data = {
			cv_id: cv_id,
			fields_data: {
				tag: "skills",
				value: listskills
			}
		};

		updateStatus(data);
	};

	const markDirty = () => {
		setIsDirty(true);
	};

	const resetDirty = () => {
		setIsDirty(false);
	};

	const handleProposeSkill = item => {
		markDirty();
		const category = _find(data, s => s._id === state.category);
		const _id = category._id;
		const name = category.label || category.name;
		const payload = {
			...item,
			skill_id: item._id,
			sector: {
				_id,
				name
			}
		};
		return mutateSkills(payload);
	};

	const handleCancelButtonClick = () => {
		resetDirty();
	};

	const handleScoreChange = () => {
		markDirty();
	};

	const handleDelete = () => {
		markDirty();
	};

	const handleSkillSelect = () => {
		markDirty();
	};

	return (
		<LeaveHook
			dirty={isDirty && !isUnsavedChangesDialogOpen}
			onOpen={toggleUnsavedChangesDialogOpen}
			onStay={toggleUnsavedChangesDialogOpen}
			onLeaveClick={toggleUnsavedChangesDialogOpen}
		>
			<EditableSection
				title="Skills"
				isEdit={false}
				onEdit={() => toggle(true)}
				onCancel={() => {
					toggle(false);
					resetDirty();
				}}
				isLoading={false}
			>
				{isLodingUpdateInternalData && <LoaderFull />}
				{skills?.length > 0 &&
					keys.map(item => (
						<div key={item} className={styles.section}>
							<div className={styles.title}>{item}</div>
							<div className={styles.row}>
								{skillsBycategory[item].length > 4
									? skillsBycategory[item]
											.slice(0, 4)
											.map(({ name, score, is_bot }) =>
												renderItems(name, score, is_bot, item)
											)
									: skillsBycategory[item].map(({ name, score, is_bot }) =>
											renderItems(name, score, is_bot, item)
									  )}
								<div>
									{skillsBycategory[item].length > 4 && (
										<GlobalTooltip
											placement="bottom"
											trigger="hover"
											overlay={countValue}
										>
											<span
												style={{
													height: "25px",
													width: "25px",
													backgroundColor: "#e4e5e7",
													borderRadius: "50%",
													display: "inline-block",
													marginRight: "5px",
													fontSize: "13px",
													color: "#3c3636",
													textAlign: "center",
													padding: "3px"
												}}
											>
												+{skillsBycategory[item].length - 4}
											</span>
										</GlobalTooltip>
									)}
								</div>
							</div>
						</div>
					))}
				<button className={s.btnAdd} onClick={toggle}>
					<AddIcon />
					<span>Add skill</span>
				</button>
				{data.length > 0 && (
					<Portal>
						<ListProvider>
							<SkillsDialog
								isOpen={on}
								onClose={() => {
									toggle(false);
									resetDirty();
								}}
								loadSkills={loadSkills}
								skills={skillsOptions}
								initialSkills={skills}
								onSave={onSave}
								contentHeight={84}
								isRequest={false}
								showMustHave={showMustHave}
								sectors={data}
								categories={selectedFunctions}
								isSearch={isSearch}
								cartTitle={cartTitle}
								saveActionName={saveActionName}
								modalHeadText={modalHeadText}
								saveActionStyle={saveActionStyle}
								canPropose={canPropose}
								onPropse={handleProposeSkill}
								onCancelButtonClick={handleCancelButtonClick}
								onScoreChange={handleScoreChange}
								onDelete={handleDelete}
								onSkillSelect={handleSkillSelect}
							/>
						</ListProvider>
					</Portal>
				)}
			</EditableSection>
		</LeaveHook>
	);
};

export default Skills;
