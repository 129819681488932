import React from "react";
import Modal from "common/modal";
import _get from "lodash/get";
import { displayAttendeesDetails } from "common/displayAttendees";
import { InterviewCanceledLabel } from "./styled";
import { formatDate } from "common/Functions";
import {
	TIME_FORMAT_SYMBOL,
	DATA_MMMM_D_YYYY,
	VIDEO_CONFERENCE,
	DONE,
	ONE_HUNDRED_AND_FIFTY,
	CANCELLED,
	CONFIRMED,
	ACCEPTED
} from "config";
import AddressComposer from "common/AddressComposer";
import Description from "common/Description";
import { groupByDate } from "./utils/helpers";
import s from "./interview-details.module.scss";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-plus-2.svg";
import { ReactComponent as MapPinIcon } from "static/icons/map-pin-2.svg";
import { ReactComponent as UsersIcon } from "static/icons/users.svg";
import { ReactComponent as VideoIcon } from "static/icons/video-recorder.svg";
import { ReactComponent as CommentIcon } from "static/icons/comment-1.svg";

const InterviewDetailsModal = ({ closeModal, data }) => {
	const cancelledBy = _get(data, "cancelled_by", null);
	const cancelInterviewAt = _get(data, "cancelled_at");
	const isVideoConf = data.location_type === VIDEO_CONFERENCE;
	const { first_name, last_name } = data && data.last_user_interview_action;

	const cancelDay =
		cancelledBy !== null
			? formatDate(data.cancelled_at, true, DATA_MMMM_D_YYYY)
			: formatDate(cancelInterviewAt, true, DATA_MMMM_D_YYYY);
	const cancelAt =
		cancelledBy !== null
			? formatDate(data.cancelled_at, true, TIME_FORMAT_SYMBOL)
			: formatDate(cancelInterviewAt, true, TIME_FORMAT_SYMBOL);

	const isInterviewConfirmed = [ACCEPTED, DONE, CONFIRMED].includes(
		data.status
	);

	const availabilitySlots = groupByDate(
		data.availability_slots || [
			{
				start_date: data.new_interview_start_date,
				end_date: data.new_interview_end_date
			}
		]
	);

	return (
		<Modal
			onClose={closeModal}
			title={"Interview details"}
			fixed
			firstButton={{
				label: "Close",
				type: "outlined",
				action: closeModal
			}}
			showSecondButton={false}
		>
			<div>
				{cancelledBy !== null ? (
					<InterviewCanceledLabel>{`Interview cancelled by ${data.cancelled_by.first_name} ${data.cancelled_by.last_name} on ${cancelDay} ${cancelAt}`}</InterviewCanceledLabel>
				) : (
					data.status === CANCELLED && (
						<InterviewCanceledLabel>{`Interview cancelled by ${first_name} ${last_name} on ${cancelDay} ${cancelAt}`}</InterviewCanceledLabel>
					)
				)}

				<div className={s.infosContainer}>
					<div className={s.dateAndLocationContainer}>
						<div>
							<div className={s.sectionTitle}>
								<CalendarIcon />
								<span>
									{data.status === DONE
										? "Confirmed date & time"
										: "Your proposition"}
								</span>
							</div>
							{isInterviewConfirmed ? (
								<div>
									<span>
										{formatDate(
											data.new_interview_start_date,
											true,
											DATA_MMMM_D_YYYY
										)}
									</span>
									&nbsp; - &nbsp;
									<span>
										{formatDate(
											data.new_interview_start_date,
											true,
											TIME_FORMAT_SYMBOL
										)}
										&nbsp;
										<i className="icon-arrow-right-new" />
										&nbsp;
										{formatDate(
											data.new_interview_end_date,
											true,
											TIME_FORMAT_SYMBOL
										)}
									</span>
								</div>
							) : (
								<div className={s.detailsInfo}>
									{Object.keys(availabilitySlots).map((key, index) => (
										<div key={index} className={s.dateInfo}>
											<div className={s.date}>{key}</div>
											<div className={s.timeSlots}>
												{availabilitySlots[key].map((slot, index) => (
													<span key={index} className={s.time}>
														{formatDate(
															slot.start_date,
															true,
															TIME_FORMAT_SYMBOL
														)}{" "}
														-{" "}
														{formatDate(
															slot.end_date,
															true,
															TIME_FORMAT_SYMBOL
														)}
													</span>
												))}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
						<div>
							{isVideoConf ? (
								<div>
									<div className={s.sectionTitle}>
										<VideoIcon />
										<span>Location - video conference</span>
									</div>
									<span>Virtual Meeting Room</span>
								</div>
							) : (
								<div>
									<div className={s.sectionTitle}>
										<MapPinIcon />
										<span>Location</span>
									</div>
									<div>
										<AddressComposer addressData={data} noClass={true} />
									</div>
								</div>
							)}
						</div>
					</div>
					{data.description && (
						<div className={s.descriptionSection}>
							<div className={s.sectionTitle}>
								<CommentIcon />
								<span>Message</span>
							</div>
							<Description
								parseMarkdown={false}
								description={data.description}
								max={ONE_HUNDRED_AND_FIFTY}
							/>
						</div>
					)}
					<div className={s.attendeesContainer}>
						<div className={s.sectionTitle}>
							<UsersIcon />
							<span>Attendees</span>
						</div>
						<div></div>
						{displayAttendeesDetails(
							_get(data, "attendees_users"),
							_get(data, "user_sender")
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default InterviewDetailsModal;
