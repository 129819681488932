import React from "react";
import PropTypes from "prop-types";
import { LinkButton } from "common/styled/buttons";
const EmptyList = ({ title, action }) => (
	<div className="empty-list">
		<span className="icon-nobid">
			<span className="path1" />
			<span className="path2" />
			<span className="path3" />
			<span className="path4" />
		</span>
		<div className="title">{title}</div>
		{action && (
			<LinkButton small px={25} py={8} to={action.link}>
				{action.text}
			</LinkButton>
		)}
	</div>
);

EmptyList.propTypes = {
	title: PropTypes.string.isRequired,
	action: PropTypes.shape({
		text: PropTypes.string.isRequired,
		link: PropTypes.string.isRequired
	})
};
export default EmptyList;
