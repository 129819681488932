import React from "react";
import PropTypes from "prop-types";
import StyleButton from "./StyleButton";

const InlineStyleControls = ({ onToggle, editorState, types }) => {
	const currentStyle = editorState.getCurrentInlineStyle();

	return (
		<div className="RichEditor-controls">
			{types.map(type => (
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={onToggle}
					style={type.style}
					icon={type.icon}
				/>
			))}
		</div>
	);
};

InlineStyleControls.propTypes = {
	types: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			style: PropTypes.string.isRequired,
			icon: PropTypes.string
		})
	).isRequired
};

InlineStyleControls.defaultProps = {
	types: [
		{ label: "B", style: "BOLD", icon: "bold" },
		{ label: "I", style: "ITALIC", icon: "italic" }
	]
};

export default InlineStyleControls;
