import React from "react";
import { CustomSkeletonCard } from "common/CustomSkeletonCard";

const BidDetailsSkeletonCard = ({
	minWidth = 80,
	maxWidth = 220,
	height,
	style = {},
	className
}) => {
	return (
		<CustomSkeletonCard
			height={height}
			minWidth={minWidth}
			maxWidth={maxWidth}
			style={{
				...style,
				borderRadius: "10px"
			}}
			className={className}
		/>
	);
};

export default BidDetailsSkeletonCard;
