import { useEffect } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import {
	GDPR_FILTER_ID,
	GDPR_LIST_FILTER,
	PAGE_SIZE
} from "../utils/constants";

export const useInitializeFilterDrawer = () => {
	const { setFilterSetting } = useFilters();

	useEffect(() => {
		setFilterSetting(
			null,
			null,
			null,
			null,
			null,
			[],
			GDPR_LIST_FILTER,
			GDPR_FILTER_ID,
			true,
			{},
			PAGE_SIZE
		);
	}, []);
};
