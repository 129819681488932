import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import BidItem from "./BidItem";
import EmptyList from "./EmptyList";
import {
	getFreelancerBidsListRoute,
	getConsultancyBidsListRoute
} from "modules/bids/components";

const MyBids = ({ bids, isConsultancy }) => {
	return (
		<div className="bids-list">
			{bids.length > 0 ? (
				<Fragment>
					<ul>
						{bids.map((bid, i) => (
							<BidItem bid={bid} key={i} isConsultancy={isConsultancy} />
						))}
					</ul>
					<div className="actions">
						<Link
							to={
								isConsultancy
									? getConsultancyBidsListRoute()
									: getFreelancerBidsListRoute()
							}
						>
							Show more ...
						</Link>
					</div>
				</Fragment>
			) : (
				<EmptyList
					title={`${
						isConsultancy ? "This consultant has" : "You have"
					} no bid in the board yet.`}
					action={{ text: "browse new jobs", link: "/jobs/search" }}
				/>
			)}
		</div>
	);
};

MyBids.propTypes = {
	bids: PropTypes.array.isRequired
};

export default MyBids;
