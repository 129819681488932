import React from "react";
import { Link } from "react-router";
import styles from "./permanent-similar-jobs.module.scss";
import JobCard from "modules/candidate/components/search/JobCardOnline";

export default function SimilarJobs({ jobs }) {
	const renderJobs = () => {
		return jobs.map((job, index) => {
			if (index > 2) return <></>;
			return (
				<JobCard
					job={job}
					key={index}
					index={index}
					isOnline={false} // * hide profile actions
					isLoading={false}
					style={{ width: "100%", margin: 0 }}
				/>
			);
		});
	};

	return (
		<div className={styles.similarJobsContainer}>
			<div className={styles.similarJobsHeader}>
				<h3>Similar job opportunities</h3>
				<Link to={"/jobs/search"}>View all jobs</Link>
			</div>
			<div className={styles.similarJobsGrid}>{renderJobs()}</div>
		</div>
	);
}
