import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import _get from "lodash/get";
import { useForm } from "react-hook-form";
import { browserHistory } from "react-router";
import BannerHelp from "./HelpBanner";
import MarginForm from "./components/MarginForm";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import useGetFeatureSettings, {
	getFeatureSettingsQueryKey
} from "./api/useGetFeatureSettings";
import useUpdateFeatureSettings from "./api/useUpdateFeatureSettings";
import toaster from "common/Toaster";
import { useQueryCache } from "react-query";
import CategoriesTabs from "modules/company/components/VariableMargins/components/Tabs.jsx";

export const getSuppliersManagement = () => {
	return "/settings/suppliers-management";
};

export const getSuppliersManagementPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const VariableMargins = () => {
	const queryCache = useQueryCache();
	const [displayBanner, setDisplayBanner] = useState(true);
	const [search, setSearch] = useState("");
	const [category, setCategory] = useState(undefined);

	const { handleSubmit, register, watch, control, setValue } = useForm({
		shouldUnregister: true,
		defaultValues: {
			freelancer: {
				request_visibility_day: 0,
				can_submit_till_day: 0,
				number_of_submission_per_request: 0
			},
			consultancy: {
				request_visibility_day: 0,
				can_submit_till_day: 0,
				number_of_submission_per_request: 0
			}
		}
	});

	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([
			{ name: "Suppliers" },
			{ name: "Suppliers Management" },
			{ name: "Tiering" }
		]);
	}, []);

	const { data } = useGetFeatureSettings(
		"@suppliers/get-visible-categories",
		{
			category_id: category?._id,
			feature_name: "feature_variable_margins",
			search
		},
		{
			onSuccess: () => {
				queryCache.invalidateQueries("@suppliers/get_visible_categories");
			},
			onError: error => {
				if (error && error.status === 403) {
					browserHistory.push("/");
				}
			}
		}
	);

	const consultancy = watch("consultancy");
	const isTiersManagement = consultancy?.tiers_management === "active";

	const [updateSettings, { isLoading }] = useUpdateFeatureSettings({
		onSuccess() {
			queryCache.invalidateQueries(getFeatureSettingsQueryKey);
			queryCache.invalidateQueries("@suppliers/get-visible-categories");
			toaster.success("Update successful.");
		}
	});

	const saveChanges = fields => {
		updateSettings({
			id: data?.feature_settings?._id,
			freelancer: {
				active: fields.freelancer.active,
				request_visibility_day: parseInt(
					fields.freelancer.request_visibility_day
				)
			},
			consultancy: {
				tiers_management: fields.consultancy.tiers_management === "active",
				request_visibility_day: parseInt(
					fields.consultancy.request_visibility_day || 0
				)
			},
			tiers: [],
			tier_moves: []
		});
	};
	const onSave = handleSubmit(saveChanges);
	return (
		<>
			<BodyClassName className="gray-bg container-tiers">
				<div>
					{displayBanner && (
						<BannerHelp onClose={() => setDisplayBanner(false)} />
					)}
					<form>
						<MarginForm
							data={_get(data, "feature_settings")}
							tiersManagement={isTiersManagement}
							isLoading={isLoading}
							watch={watch}
							control={control}
							setValue={setValue}
							register={register}
							onSave={onSave}
						/>
						<CategoriesTabs
							category={category}
							setCategory={setCategory}
							isTiersManagement={isTiersManagement}
							register={register}
							search={search}
							setSearch={setSearch}
							handleSubmit={handleSubmit}
						/>
					</form>
				</div>
			</BodyClassName>
		</>
	);
};
export default VariableMargins;
