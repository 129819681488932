import { createSelector } from "reselect";
import _get from "lodash/get";

const getPermanent = state => _get(state, "permanent");
const getPermanentLanguages = state => _get(state, "permanent.languages");
const getPermanentSkills = state => _get(state, "permanent.skills");
const getPoke = state => _get(state, "poke");
const getJobList = state => _get(state, "permanent.jobList");
const getModalRemoveInterest = state =>
	_get(state, "permanent.removeInterest_modal");
const getModalCancelInterview = state =>
	_get(state, "permanent.cancelInterview_modal");
const getCancelApplication = state =>
	_get(state, "permanent.cancelApplication_modal");
const getAttendeesList = state => _get(state, "permanent.attendeesList");
const getIsUploading = state => _get(state, "permanent.isUploadingCv");
const getUploadError = state => _get(state, "permanent.hasUploadError");

export const attendeesListSelector = createSelector(
	getAttendeesList,
	attendeesList => attendeesList
);
export const permanentSelector = createSelector(
	getPermanent,
	permanent => permanent
);

export const permanentLanguagesSelector = createSelector(
	getPermanentLanguages,
	permanentLanguages => permanentLanguages
);

export const permanentSkillsSelector = createSelector(
	getPermanentSkills,
	permanentSkills => permanentSkills
);
export const pokeSelector = createSelector(getPoke, poke => poke);

export const jobListSelector = createSelector(getJobList, jobList => jobList);

export const modalRemoveInterestSelector = createSelector(
	getModalRemoveInterest,
	modalRemoveInterest => modalRemoveInterest
);
export const modalCancelApplicationSelector = createSelector(
	getCancelApplication,
	modalCancelApplication => modalCancelApplication
);

export const modalCancelInterviewSelector = createSelector(
	getModalCancelInterview,
	modalCancelInterview => modalCancelInterview
);

export const isUploadingCvSelector = createSelector(
	getIsUploading,
	data => data
);
export const hasUploadingErrorSelector = createSelector(
	getUploadError,
	data => data
);
