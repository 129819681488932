import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import cx from "classnames";
import { queryCache } from "react-query";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import _get from "lodash/get";
import _every from "lodash/every";
import _isObject from "lodash/isObject";
import _pickBy from "lodash/pickBy";
import EditableSection from "modules/candidate/Profile/Pages/Profile/components/EditableSection";
import { ReactComponent as AddIcon } from "static/icons/focus-add.svg";
import NewCountryFlagSelect from "common/CountryFlagSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import EmploymentType from "./EmploymentType";
import { PREFERRED_COUNTRIES } from "config";
import { handleAddressObject } from "config/helpers";

import s from "./candidate-preferences.module.scss";
import ExtraBenifits from "./ExtraBenifits";
import { client } from "lib/api-client";
import { GET_COUNTRIES } from "config/api-endpoints";
import GoogleAutocomplete from "common/GoogleAutocomplete";
import CurrenciesSymbolSelect from "common/CurrenciesSymbolSelect";
import useVacancy from "modules/vacancy/create-vacancy/api/useVacancy";
import Select from "react-select/lib/Select";
import useUpdateInternalData from "modules/candidate/Profile/api/useUpdateInternalData";
import CurrencyField from "common/NewCurrencyField";
import toaster from "common/Toaster";
import * as yup from "yup";
import { viewOverviewKey } from "modules/candidate/Profile/api/useOverview";
import { profileOverviewKey } from "modules/candidate/Profile/api/useViewCandidateInternal";
import { editHistoryKey } from "modules/candidate/Profile/api/useEditHistory";
import { ReactComponent as DeleteIcon } from "static/icons/delete.svg";
import LeaveHook from "common/LeaveHook";
import useUnsavedChangesDialog from "modules/candidate/Profile/Store/useUnsavedChangesDialog";

export const paymentsData = [
	{ label: "Monthly", name: "Monthly", value: "monthly" },
	{ label: "Yearly", name: "Yearly", value: "yearly" },
	{ label: "Hourly", name: "Hourly", value: "hourly" }
];
export const typeData = [
	{ label: "Gross", name: "Gross", value: "gross" },
	{ label: "Net", name: "Net", value: "net" }
];

const getCountries = () =>
	client(GET_COUNTRIES, {
		body: {
			all: true
		}
	});

const CandidatePreferences = ({
	data,
	profile_id,
	cv_id,
	isInternalLoading
}) => {
	const [isDisabled, setIsDisabled] = useState(true);
	const [locations, setLocations] = useState([]);
	// const [locationError, setLocationError] = useState("");
	const [loc, setLoc] = useState([]);
	const [residenceValue, setResidenceValue] = useState("");

	const [
		isUnsavedChangesDialogOpen,
		toggleUnsavedChangesDialogOpen
	] = useUnsavedChangesDialog();

	useEffect(() => {
		setLoc(
			_get(data, "front_sheet.preference.preferred_location", []).filter(
				l => !l.is_main
			)
		);
		setLocations(
			..._get(data, "front_sheet.preference.preferred_location", []).filter(
				l => l.is_main
			)
		);
	}, [data]);

	const [extraBenifitsItems, setEextraBenifitsItems] = useState([]);
	const [employmentType, setEmploymentType] = useState([]);

	const schema = object().shape({
		items: yup.array().of(
			yup.object().shape({
				name: yup.object().required("Field can't be empty")
			})
		),
		payroll_country: yup.object().when("expected_salary", {
			is: expected_salary => expected_salary,
			then: yup
				.object()
				.nullable("Please select an option.")
				.required("Please select an option.")
		}),
		currency: yup.object().when("expected_salary", {
			is: expected_salary => expected_salary,
			then: yup
				.object()
				.nullable("Please select an option.")
				.required("Please select an option.")
		}),
		payment_time: yup.object().when("expected_salary", {
			is: expected_salary => expected_salary,
			then: yup
				.object()
				.nullable("Please select an option.")
				.required("Please select an option.")
		}),
		payment_type: yup.object().when("expected_salary", {
			is: expected_salary => expected_salary,
			then: yup
				.object()
				.nullable("Please select an option.")
				.required("Please select an option.")
		})
	});

	const { currencies = [], isLoading: isVacancyLoading } = useVacancy();

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { isDirty, errors }
	} = useForm({
		resolver: yupResolver(schema),
		shouldUnregister: true,
		defaultValues: {
			items: _get(data, "front_sheet.preference.preferred_location", [])
				.filter(l => !l.is_main)
				.map(l => ({
					name: l
				})),
			expected_salary: _get(data, "front_sheet.preference.expected_salary")
		}
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: "items"
	});
	const allFields = watch();

	const addLocation = async (data, index) => {
		// let exists = locations.find(l => {
		// 	if (l.city === data.city && l.country === data.country) {
		// 		return l;
		// 	}
		// });
		// if (exists) {
		// 	return setLocationError("This location was already added.");
		// }
		const { address: finalAddress } = await handleAddressObject(data, true);
		const adressCleaned = _pickBy(finalAddress, function(_, key) {
			return (
				key === "street" ||
				key === "country" ||
				key === "zip" ||
				key === "city" ||
				key === "number" ||
				key === "box" ||
				key === "latitude" ||
				key === "longitude" ||
				key === "iso_country" ||
				key === "is_main" ||
				key === "viewport"
			);
		});
		if (_get(data, "is_main")) {
			setLocations(adressCleaned);
		} else {
			if (loc[index] !== undefined) {
				loc[index] = adressCleaned;
			} else {
				setLoc([...loc, adressCleaned]);
			}
		}
	};

	const { data: countries = [], isLoading: isCountriesLoading } = useQuery(
		["getCountries"],
		getCountries,
		{
			refetchOnWindowFocus: false,
			staleTime: Infinity
		}
	);

	const [
		updateStatus,
		{ isLoading: updateInternalDataLoading }
	] = useUpdateInternalData({
		onSuccess() {
			toaster.success("Candidate preferences has successfully been updated.");
			queryCache.invalidateQueries(profileOverviewKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(editHistoryKey);
			setIsDisabled(true);
		},
		onError(err) {
			toaster.danger(
				_get(err, "detail.fields_data[0]", "Something went wrong")
			);
		}
	});

	const handleRemoveItem = index => {
		const arr = loc;
		arr.splice(index, 1);
		setLoc(arr);
		remove(index);
	};

	const handleUpdateInternalData = dataform => {
		updateStatus({
			profile_id: profile_id,
			cv_id: cv_id,
			fields_data: {
				tag: "candidate_preferences",
				value: {
					preferred_location: locations ? [locations, ...loc] : [...loc],
					expected_salary: _get(
						dataform,
						"expected_salary",
						_get(data, "front_sheet.preference.expected_salary")
					),
					notice_period: _get(
						dataform,
						"notice_period",
						_get(data, "front_sheet.preference.notice_period")
					),
					currency_expected: _get(
						dataform,
						"currency.value",
						_get(data, "front_sheet.preference.currency_expected")
					),
					payment_time_expected: _get(
						dataform,
						"payment_time.value",
						_get(data, "front_sheet.preference.payment_time_expected")
					),
					payment_type_expected: _get(
						dataform,
						"payment_type.value",
						_get(data, "front_sheet.preference.payment_type_expected", "")
					),
					payroll_country_expected: _get(
						dataform,
						"payroll_country.value",
						_get(data, "front_sheet.preference.payroll_country_expected")
					),
					employment_types: _every(
						_get(allFields, "employment_type", []),
						_isObject
					)
						? _get(allFields, "employment_type", []).map(t => t.value)
						: _get(allFields, "employment_type", []),
					extra_benefits: _get(
						allFields,
						"extra_benefits",
						extraBenifitsItems
					).map(ex => ex.value)
				}
			}
		});
	};

	const handleCancel = () => {
		setIsDisabled(true);
		resetForm();
	};

	useEffect(() => {
		if (isVacancyLoading || isCountriesLoading || isInternalLoading) return;
		resetForm();
	}, [data, currencies, countries]);

	const resetForm = () => {
		const mainLocation = _get(
			data,
			"front_sheet.preference.preferred_location",
			[]
		)
			.filter(l => l.is_main)
			// we create new object in order to reexecute useEffect of GoogleAutoComplete
			.map(e => ({ ...e }));

		if (mainLocation.length > 0) {
			setResidenceValue(mainLocation[0]);
		} else {
			setResidenceValue("");
		}

		setEextraBenifitsItems(
			_get(data, "front_sheet.preference.extra_benefits", [])
		);

		setEmploymentType(
			_get(data, "front_sheet.preference.employment_types", [])
		);

		const extraBenefits = _get(
			data,
			"front_sheet.preference.extra_benefits",
			[]
		);

		const employmentType = _get(
			data,
			"front_sheet.preference.employment_types",
			[]
		);

		const paymentTypeValue =
			typeData[
				typeData
					.map(i => i.value)
					.indexOf(
						_get(data, "front_sheet.preference.payment_type_expected", "")
					)
			];

		const currencyValue =
			currencies[
				currencies
					.map(i => i.code)
					.indexOf(_get(data, "front_sheet.preference.currency_expected", ""))
			];

		const payrollCountryValue =
			countries[
				countries
					.map(i => i.country)
					.indexOf(
						_get(data, "front_sheet.preference.payroll_country_expected", "")
					)
			];

		const paymentTimeValue =
			paymentsData[
				paymentsData
					.map(i => i.value)
					.indexOf(
						_get(data, "front_sheet.preference.payment_time_expected", "")
					)
			];

		const noticePeriode = _get(data, "front_sheet.preference.notice_period");

		const expectedSalary = _get(data, "front_sheet.preference.expected_salary");

		const items = _get(data, "front_sheet.preference.preferred_location", [])
			.filter(l => !l.is_main)
			.map(l => ({
				name: l
			}));

		reset({
			extra_benefits: extraBenefits,
			employment_type: employmentType,
			payment_type: paymentTypeValue,
			currency: currencyValue,
			payroll_country: payrollCountryValue,
			payment_time: paymentTimeValue,
			notice_period: noticePeriode,
			residsence_location: mainLocation[0] || {},
			items,
			expected_salary: expectedSalary
		});
	};

	return (
		<LeaveHook
			dirty={isDirty && !isUnsavedChangesDialogOpen}
			onOpen={toggleUnsavedChangesDialogOpen}
			onStay={toggleUnsavedChangesDialogOpen}
			onLeaveClick={toggleUnsavedChangesDialogOpen}
		>
			<EditableSection
				title="Candidate preferences"
				isEdit={!isDisabled}
				onEdit={() => {
					setIsDisabled(false);
				}}
				onSave={handleSubmit(handleUpdateInternalData)}
				onCancel={() => {
					handleCancel();
				}}
				isLoading={updateInternalDataLoading}
			>
				<div className={s.field}>
					<div className={s.box}>
						<div className={s.label}>Employment type</div>

						<Controller
							name="employment_type"
							control={control}
							render={({ field: { onChange } }) => (
								<EmploymentType
									onChange={onChange}
									data={employmentType}
									isEdit={!isDisabled}
									onEdit={() => {
										setIsDisabled(false);
									}}
								/>
							)}
						/>
					</div>
				</div>
				<div className={s.field}>
					<div className={s.flex}>
						<div className={s.box}>
							<div className={s.label}>Residence</div>
							<div className={s.info}>
								<Controller
									name="residsence_location"
									control={control}
									render={({ field: { onChange, value = {} } }) => (
										<GoogleAutocomplete
											input={{
												onChange: value => {
													onChange(value);
													setResidenceValue(value);
												}
											}}
											name={"address"}
											address={value}
											save={addLocation}
											isMain={true}
											onChange={() => {
												setResidenceValue("");
												onChange("");
											}}
											disabled={isDisabled}
										/>
									)}
								/>
							</div>
							<div className={s.errorMsg}>
								{_get(errors, "residsence_location.message")}
							</div>
						</div>
					</div>
				</div>
				<div className={s.field}>
					<div className={s.label}>Prefered Locations</div>
					<div className={cx(s.flex, s.itemCenterNone)}>
						{fields.map((pr, index) => (
							<div className={s.box} key={pr.id}>
								<div
									className={cx(s.info, {
										[s.error]: _get(errors, `items[${index}].name.message`)
									})}
								>
									<Controller
										name={`items[${index}].name`}
										control={control}
										render={({ field: { onChange, value = {} } }) => (
											<GoogleAutocomplete
												input={{ onChange: onChange }}
												name={"address"}
												address={value}
												save={addLocation}
												disabled={isDisabled || !residenceValue}
												index={index}
												onChange={() => onChange("")}
											/>
										)}
									/>
								</div>
								{!isDisabled && (
									<button
										className={s.deleteItem}
										onClick={() => handleRemoveItem(index)}
									>
										<DeleteIcon />
										Delete item
									</button>
								)}
							</div>
						))}

						<button
							className={s.btnAdd}
							onClick={() => {
								append({ name: undefined });

								// setAddress([...address, {}]);
								setIsDisabled(false);
							}}
						>
							<AddIcon />
							<span>Add location</span>
						</button>
					</div>
					{/* {locationError && <div className={s.errorMsg}>{locationError}</div>} */}
				</div>

				<div className={s.field}>
					<div className={s.flex}>
						<div className={s.box}>
							<div className={s.label}>Expected salary</div>
							<div className={s.info}>
								<Controller
									control={control}
									name="expected_salary"
									render={({
										field: { onChange: onChangeForm, value = value * 100, name }
									}) => {
										return (
											<CurrencyField
												disabled={isDisabled}
												className="amount"
												input={{
													onChange: newValue => {
														if (value === newValue) return;
														onChangeForm(newValue);
													},
													value,
													name
												}}
												meta={{
													touched: Boolean(
														_get(errors, "expected_salary.message")
													),
													error: _get(errors, "expected_salary.message")
												}}
											/>
										);
									}}
								/>
							</div>
							<div className={s.errorMsg}>
								{_get(errors, "expected_salary.message")}
							</div>
						</div>

						<div className={s.box}>
							<div className={s.label}>Currency</div>
							<div className={s.info}>
								<Controller
									name="currency"
									control={control}
									render={({ field: { onChange, value = [] } }) => (
										<CurrenciesSymbolSelect
											name="currency"
											input={{ value, onChange, onBlur: () => {} }}
											input_classes="currency"
											preferredCountries={PREFERRED_COUNTRIES}
											placeholder="Select..."
											currenciesData={currencies ?? []}
											meta={{
												touched: _get(errors, "currency.message", false)
											}}
											disabled={isDisabled}
										/>
									)}
								/>
							</div>
							<div className={s.errorMsg}>
								{_get(errors, "currency.message")}
							</div>
						</div>

						<div className={s.box}>
							<div className={s.label}>Payroll country</div>
							<div className={s.info}>
								<Controller
									name="payroll_country"
									control={control}
									render={({ field: { onChange, value } }) =>
										!isCountriesLoading && (
											<>
												<NewCountryFlagSelect
													name="payroll_country"
													disabled={isDisabled}
													input_classes="countries"
													preferredCountries={PREFERRED_COUNTRIES}
													placeholder="Select..."
													customList={countries}
													input={{
														value:
															_get(value, "country", "") ||
															_get(value, "value", "") ||
															[],
														onChange: value => {
															onChange(value);
															let currency = _get(value, "currency", false);

															if (currency) {
																currency = {
																	label: currency.code,
																	value: currency.code,
																	code: currency.code,
																	symbol: currency.symbol
																};
																setValue("currency", currency);
															}
														},
														onBlur: () => {}
													}}
													meta={{
														touched: _get(errors, "payroll_country.message")
													}}
												/>
											</>
										)
									}
								/>
							</div>
							<div className={s.errorMsg}>
								{_get(errors, "payroll_country.message")}
							</div>
						</div>
					</div>
				</div>
				<div className={s.field}>
					<div className={s.flex}>
						<div className={s.box}>
							<div className={s.label}>Salary type</div>
							<div className={s.info}>
								<Controller
									name="payment_time"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											showCta={false}
											label="PAYMENTS TIME"
											options={paymentsData}
											searchable={true}
											value={value}
											clearable={false}
											onChange={option => onChange(option)}
											showError={_get(errors, "payment_time.message", false)}
											errorMessage={_get(errors, "payment_time.message")}
											disabled={isDisabled}
										/>
									)}
								/>
							</div>
							<div className={s.errorMsg}>
								{_get(errors, "payment_time.message")}
							</div>
						</div>
						<div className={s.box}>
							<div className={s.label}>Gross/Net</div>
							<div className={s.info}>
								<Controller
									name="payment_type"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select
											showCta={false}
											label="TYPE"
											options={typeData}
											searchable={true}
											value={value}
											clearable={false}
											onChange={option => onChange(option)}
											showError={_get(errors, "payment_type.message", false)}
											errorMessage={_get(errors, "payment_type.message")}
											disabled={isDisabled}
										/>
									)}
								/>
							</div>
							<div className={s.errorMsg}>
								{_get(errors, "payment_type.message")}
							</div>
						</div>
					</div>
				</div>
				<div className={s.field}>
					<div className={s.box}>
						<div className={s.label}>Notice period</div>
						<div className={s.info}>
							<Controller
								name="notice_period"
								control={control}
								render={({ field: { onChange, value } }) => (
									<input
										type="text"
										onChange={onChange}
										value={value}
										name="notice"
										disabled={isDisabled}
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<Controller
					name="extra_benefits"
					control={control}
					render={({ field: { onChange } }) => (
						<ExtraBenifits
							extraBenifitsItems={extraBenifitsItems}
							onExtraAdd={setEextraBenifitsItems}
							onChange={onChange}
							isEdit={!isDisabled}
							onEdit={() => {
								setIsDisabled(false);
							}}
						/>
					)}
				/>
			</EditableSection>
		</LeaveHook>
	);
};

export default CandidatePreferences;
