import React, { useState } from "react";

export default function ExtendedTimesheetIcon({
	fill = "#fff",
	hoverFill = "red",
	width = 15
}) {
	const [highlighted, setHighlighted] = useState(false);
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 286 286"
			onMouseOver={() => setHighlighted(true)}
			onMouseOut={() => setHighlighted(false)}
			fill={highlighted ? hoverFill : fill}
			width={width}
		>
			<path
				id="path1"
				d="M73.5,35.3h178.2v178.2H274V13H73.5V35.3z M225.4,273.3H13.7V61.6h211.7V273.3z M203,84H36v167h167V84z"
			/>
		</svg>
	);
}
