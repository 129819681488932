import React from "react";
import { ReactComponent as NoSearchResult } from "static/icons/NoSearchResult.svg";
import styles from "./styles.module.scss";

const MenuEmptyState = () => {
	return (
		<div className={styles.container}>
			<NoSearchResult />
			<p className={styles.title}>No results found</p>
			<p className={styles.suggestion}>Search for something else</p>
		</div>
	);
};

export default MenuEmptyState;
