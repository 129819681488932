import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { TASK_DELETE } from "config/api-endpoints";

const deleteTask = data => {
	return client(TASK_DELETE, {
		body: { ...data }
	});
};

const useDeleteTask = (options, queryOptions = {}) =>
	useMutation(deleteTask, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useDeleteTask;
