import React, { useEffect } from "react";
import { useGetRequest } from "./hooks/useGetRequest";
import Header from "./components/Header";
import ChannelsList from "./components/ChannelsList";
import PublishRequestConfirmationDrawer from "./components/PublishRequestConfirmationDrawer";
import usePublishRequestBreadCrumbs from "./hooks/usePublishRequestBreadCrumbs";
import styles from "./style.module.scss";
import usePublishRequestStore from "./hooks/usePublishRequestStore";

export default function PublishRequest({ params: { id } }) {
	const { data: request } = useGetRequest(id);
	const { setRequest, cleanStore } = usePublishRequestStore();
	usePublishRequestBreadCrumbs();

	useEffect(() => {
		setRequest(request);
	}, [request]);

	useEffect(() => {
		return () => cleanStore();
	}, []);

	return (
		<div className={styles.content}>
			<Header />
			<ChannelsList jobId={id} siteId={request?.site?._id} />
			<PublishRequestConfirmationDrawer jobId={id} />
		</div>
	);
}

export const getPublishRequestRoute = id => `/publish-request/${id ?? ":id"}`;

export const getPublishRequestPermission = () => ({
	type: [
		{
			name: "client",
			roles: ["admin", "project_manager"]
		}
	]
});
