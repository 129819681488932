import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_COMPANY_EXPERTISE_ENDPOINT } from "config/api-endpoints";

function updateCompanyExpertise(body) {
	return client(UPDATE_COMPANY_EXPERTISE_ENDPOINT, { body });
}

function useUpdateCompanyExpertise(config) {
	return useMutation(updateCompanyExpertise, config);
}

export default useUpdateCompanyExpertise;
