import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_EB_SETTINGS } from "config/api-endpoints";

function updateSite(data) {
	return client(UPDATE_EB_SETTINGS, {
		body: {
			tag: "site_feature_settings",
			...data
		}
	});
}

function useUpdateSite(options) {
	return useMutation(updateSite, options);
}

export default useUpdateSite;
