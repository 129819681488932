import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { MERGE_PROFILES } from "config/api-endpoints";

function mergeProfiles(body) {
	return client(MERGE_PROFILES, {
		body
	});
}
export const mergeProfilesKey = "@profile/mergeProfiles";
function useMergeProfiles(options) {
	return useMutation(mergeProfiles, options);
}

export default useMergeProfiles;
