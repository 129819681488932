import { useQuery } from "react-query";
import { client } from "../../../lib/api-client";
import { FILTER_SETTINGS_NOTES } from "../../../config/api-endpoints";
import {
	HOW_TO_PUBLISH_VACANCY,
	HOW_TO_SOURCE_AND_INVITE_CANDIDATE,
	HOW_TO_WORK_WITH_NOTES
} from "config/onboarding";
import fakeSettings from "modules/user-onboarding/mockData/vacancies-filter-settings.json";
import fakeCandidatesSettings from "modules/user-onboarding/mockData/candidates-filter-settings.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getColumns = tag => client(FILTER_SETTINGS_NOTES, { body: { tag } });

const getFetchFunction = (workflowId, tag) => {
	if (workflowId === HOW_TO_PUBLISH_VACANCY)
		return () => new Promise(resolve => resolve(fakeSettings));
	else if (
		workflowId === HOW_TO_SOURCE_AND_INVITE_CANDIDATE ||
		workflowId === HOW_TO_WORK_WITH_NOTES
	)
		return () => new Promise(resolve => resolve(fakeCandidatesSettings));
	else return () => getColumns(tag);
};

function useGetCandidateColumns(tag) {
	const { workflowId } = useOnboardingStore();
	const fetchData = getFetchFunction(workflowId, tag);

	return useQuery([`@getColumnsSettings_${tag}`, tag, workflowId], fetchData, {
		refetchOnWindowFocus: false
	});
}

export default useGetCandidateColumns;
