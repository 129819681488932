import React, { useContext, useEffect, useMemo, createRef, memo } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import _get from "lodash/get";

import { ListContext } from "./listContext";
import {
	ListItemContainer,
	ListItem,
	SkillLabel,
	ListContainer,
	EmptySkills,
	HelpBlock,
	ProposeItemHolder,
	FlexContainer,
	CheckIcon,
	SkillNameHolder
} from "./style";
import { getSkillsTooltip } from "config/helpers";
import { excerpt } from "common/Functions";
import GlobalTooltip from "common/GlobalTooltip";
import SimpleScorer from "../../scorer/Simple";
import { HOME_URL } from "config/api-config";

function List({
	onSelect,
	title,
	hasCategory,
	showCategories,
	canPropose,
	showSuccess
}) {
	const { state } = useContext(ListContext);

	const listRef = createRef();

	useEffect(() => {
		if (_get(state, "data", []).length && listRef.current) {
			listRef.current.scrollTop = 0;
		}
	}, [_get(state, "data")]);

	const items = useMemo(() => {
		let { data = {}, selected } = state;

		return (
			data && data.filter(i => selected.findIndex(s => s._id === i._id) === -1)
		);
	}, [_get(state, "selected"), _get(state, "data")]);

	const proposing = _get(state, "filter") && canPropose && items.length === 0;

	const handleSelect = item => {
		onSelect(item);
	};

	const renderItem = ({ item }) => {
		const isSkillDisabled = state.selected.length === 20;
		return (
			<ListItemContainer key={item._id}>
				<GlobalTooltip
					placement="right"
					align={{ offset: [2, 0] }}
					withWrappingDiv={false}
					active={isSkillDisabled}
					eventTrigger="click"
					overlay={`Sorry, You’ve reached 20 skills `}
				>
					<ListItem>
						<GlobalTooltip
							placement="top"
							overlay={item.name}
							withWrappingDiv={false}
							active={item.name.length > 21}
							proposing={item.proposing || false}
						>
							<SkillLabel>{excerpt(item.name, 21)}</SkillLabel>
						</GlobalTooltip>
						<SimpleScorer
							tooltipFormater={getSkillsTooltip}
							maxScore={5}
							item={item}
							disabled={isSkillDisabled}
							onScoreChange={handleSelect}
						/>
					</ListItem>
				</GlobalTooltip>
			</ListItemContainer>
		);
	};

	const renderEmpty = () => {
		if (canPropose) {
			const isSelected = _get(state, "selected", []).find(
				s =>
					s.name.toLowerCase() === state.filter.toLowerCase() &&
					_get(s, "parent_sector._id") === _get(state, "category")
			);

			if (_get(state, "filter") && !items.length && !isSelected) {
				return (
					<ProposeItemHolder>
						{renderItem({
							item: { _id: uuid(), name: state.filter, proposing: true }
						})}
						<HelpBlock>We can’t find this skill, add a new one!</HelpBlock>
					</ProposeItemHolder>
				);
			}
		}
		if (_get(state, "filter") && !items.length) {
			return (
				<EmptySkills>
					<span className="icon-info" />
					<div>
						No skills found for the search term <strong>{state.filter}</strong>
					</div>
				</EmptySkills>
			);
		}
	};

	const scrollBarsRenderView = ({ style, ...props }) => {
		const viewStyle = {
			padding: 15,
			display: "grid",
			gridTemplateColumns: "1fr 1fr",
			gridTemplateRows: "repeat(auto-fill, 55px)"
		};
		return (
			<div
				style={{ ...style, ...viewStyle }}
				className="skills-container"
				{...props}
			/>
		);
	};

	return (
		<div style={{ margin: "0 15px" }}>
			<label className="upperCase">{title}</label>
			<ListContainer proposing={proposing} ref={listRef}>
				{showCategories && !hasCategory && (
					<EmptySkills>
						<span className="icon-info" />
						To add skills, start by selecting a category, using the dropdown in
						the upper left corner.
					</EmptySkills>
				)}
				{!showSuccess && renderEmpty()}
				{showSuccess && (
					<FlexContainer column>
						<CheckIcon className="icon icon-check" />
						<SkillNameHolder>&quot;{state.filter}&quot;</SkillNameHolder>
						<HelpBlock>
							has successfully been added ! See our{" "}
							<a href={`${HOME_URL}/faq`}>FAQ</a> for more information.
						</HelpBlock>
					</FlexContainer>
				)}
				<Scrollbars
					style={{ height: "100%" }}
					renderView={scrollBarsRenderView}
				>
					{items?.map(item => renderItem({ item }))}
				</Scrollbars>
			</ListContainer>
		</div>
	);
}

List.propTypes = {
	hasCategory: PropTypes.bool,
	title: PropTypes.string,
	onSelect: PropTypes.func,
	selectedCategories: PropTypes.array
};

List.defaultProps = {
	title: "All skills"
};

export default memo(List);
