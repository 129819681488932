import React, { useMemo } from "react";
import { get } from "lodash";
import { queryCache } from "react-query";
import _map from "lodash/map";
import { listCountKey } from "modules/home/api/useListCount.js";
import HomeTable from "modules/home/components/Temporary/table";
import useRequestList from "modules/home/api/useRequestList.js";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { CURRENCY_SUFFIX, REQUEST } from "config";
import useTemporaryStore from "modules/home/zustand/temporary.store.js";
import { historyPush } from "config/helpers";
import { formatDate } from "common/Functions";
import { RenderDate } from "modules/home/ui";
import StatusLabel from "modules/vacancy-view/components/activities/StatusLabel";

const RequestList = ({ handleViewAll }) => {
	const { startDate, endDate, temporaryTab, setItem } = useTemporaryStore();

	const { data, isLoading } = useRequestList(
		{
			start_date:
				startDate && startDate.isValid() ? startDate.unix() : undefined,
			end_date: endDate && endDate.isValid() ? endDate.unix() : undefined
		},
		{
			enabled: temporaryTab === REQUEST,
			onSuccess: res => {
				queryCache.invalidateQueries(listCountKey);
				setItem(
					REQUEST,
					_map(res || [], item => item._id)
				);
			}
		}
	);
	const renderCurrency = data => (
		<CurrencyFormatterRender
			isCent
			value={get(data, "cell.value")}
			suffix={
				CURRENCY_SUFFIX[
					get(data, "cell.row.original.reporting_settings.rate_type")
				]
			}
			currency={get(data, "cell.row.original.reporting_settings.currency.code")}
		/>
	);

	const columns = useMemo(
		() => [
			{
				Header: "Created date",
				accessor: "created_at",
				Cell: props => <RenderDate date={props.cell.value} />
			},
			{
				Header: "Request title",
				accessor: "title"
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => <StatusLabel status={props.cell.value} />
			},
			{
				Header: "Bid",
				accessor: "number_bids",
				isNumber: true
			},
			{
				Header: "Interview",
				accessor: "count_interviews",
				isNumber: true
			},
			{
				Header: "Contract proposition",
				accessor: "count_contracts",
				isNumber: true
			},
			{
				Header: "Expected start date",
				Cell: props => (
					<div>
						{" "}
						{formatDate(get(props, "cell.row.original.start_date"))} -{" "}
						{formatDate(get(props, "cell.row.original.end_date"))}{" "}
					</div>
				)
			},
			{
				Header: "Expected rate",
				accessor: "budget",
				isNumber: true,
				Cell: renderCurrency
			}
		],
		[]
	);
	const buttonAction = () => historyPush("/jobs/list");
	const onRowClick = row => historyPush(`/job/${row._id}?listKey=${REQUEST}`);

	return (
		<HomeTable
			isLoading={isLoading}
			columns={columns}
			data={data}
			heading="You have no request for this date"
			buttonLabel="Create request"
			buttonAction={buttonAction}
			onRowClick={onRowClick}
			handleViewAll={handleViewAll}
		/>
	);
};

export default RequestList;
