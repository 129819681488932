import React, { useState } from "react";
import { useMutation, queryCache } from "react-query";
import { browserHistory } from "react-router";
import { Scrollbars } from "react-custom-scrollbars";
import _get from "lodash/get";
import cx from "classnames";
import Modal from "common/modal";
import Avatar from "common/Avatar/index";

import { NOTIFY_USERS } from "config/api-endpoints";
import { client } from "lib/api-client";
import toaster from "common/Toaster";
import EmailHandler from "./EmailHandler";
import AddEmailSkeleton from "./AddEmailSkeleton";

import style from "./shortListDrawer.module.scss";
import usePoolStore from "modules/SearchCandidate/hooks/zustandPool";

const notifyUsers = data => client(NOTIFY_USERS, { body: data });

const AddEmailModal = ({
	setShortListDone,
	setAddEmailModal,
	ListCandidate,
	jobId,
	activeCompany,
	notify: refetchListCandidates,
	isLoadingListCandidate,
	VacancyId
}) => {
	const { setCurrentId } = usePoolStore(state => ({
		setCurrentId: state.setCurrentId
	}));
	const userId = _get(ListCandidate, "users", []).map(user => user._id);

	const [warning, setWarning] = useState(false);
	const [disableNotifyBtn, setDisableNotifyBtn] = useState(false);
	const closeModal = () => {
		setShortListDone(false);
		setAddEmailModal(false);
		setCurrentId(userId[0]);
	};
	const emailAdressesMissing = _get(ListCandidate, "users", []).filter(
		user => !user.email
	).length;

	const countCandidatesWithEmail = _get(ListCandidate, "users", []).filter(
		user => user.email
	).length;

	const candidateIds = _get(ListCandidate, "users", [])
		.filter(user => user.email)
		.map(user => user._id);

	const [mutate, { isLoading }] = useMutation(notifyUsers, {
		onSuccess: () => {
			setAddEmailModal(false);
			queryCache.invalidateQueries("my-candidates-profiles");
			VacancyId
				? browserHistory.push("/vacancy/" + VacancyId)
				: browserHistory.push("/vacancy/" + jobId);
		},
		onError: error => {
			toaster.danger(_get(error, "detail.name"));
		}
	});

	const notify = () => {
		const payload = {
			profile_ids: candidateIds,
			job_id: jobId
		};
		if (emailAdressesMissing === 0) {
			mutate(payload);
		}
		if (warning) {
			mutate(payload);
			setWarning(false);
		} else {
			setWarning(true);
			return;
		}
	};

	const renderRowTable = data => {
		return (
			<tbody>
				{_get(data, "users", []).map(row => {
					return (
						<tr key={row._id}>
							<td>
								<div className={style.candidateName}>
									<Avatar
										size={32}
										src={_get(row, "avatar")}
										name={`${_get(row, "first_name")} ${_get(
											row,
											"last_name"
										)}`}
									/>
									<span>{`${_get(row, "first_name")} ${_get(
										row,
										"last_name"
									)}`}</span>
								</div>
							</td>
							<td>{_get(row, "function") ? _get(row, "function") : "N/A"}</td>
							<td>
								{_get(row, "email") ? (
									_get(row, "email")
								) : (
									<EmailHandler
										activeCompany={activeCompany}
										profileId={_get(row, "_id")}
										notify={refetchListCandidates}
										setDisableNotifyBtn={setDisableNotifyBtn}
									/>
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		);
	};
	return (
		<>
			<Modal
				className={style.modalAddEmail}
				onClose={closeModal}
				size={"800px"}
				padding={"24px"}
				loading={isLoading}
			>
				<h3>Add email addresses</h3>
				{emailAdressesMissing > 0 && !warning && (
					<p>
						<strong>{emailAdressesMissing} candidates</strong>
						don&apos;t have an e-mail address. Add it using the module below so
						we can notify them.
					</p>
				)}
				{warning && emailAdressesMissing > 0 && (
					<p className={style.missingAdresses}>
						<strong>({emailAdressesMissing})</strong>email addresses missing! Do
						you want to notify the existing and ignore others?
					</p>
				)}

				<Scrollbars
					autoHide
					autoHideTimeout={5000}
					autoHideDuration={200}
					autoHeight
					autoHeightMax={300}
				>
					{isLoadingListCandidate ? (
						<AddEmailSkeleton />
					) : (
						<table className={style.tableCandidate}>
							<thead>
								<tr>
									<th width="40%">Candidate</th>
									<th width="30%">Function</th>
									<th width="30%">Email</th>
								</tr>
							</thead>
							{renderRowTable(ListCandidate)}
						</table>
					)}
				</Scrollbars>
				<div className={cx(style.footer_drawer, style.footerAddEmail)}>
					<button className={style.buttonCancel} onClick={closeModal}>
						Skip for now
					</button>{" "}
					<button
						className={style.buttonSave}
						onClick={notify}
						disabled={disableNotifyBtn || countCandidatesWithEmail === 0}
					>
						Notify
					</button>
				</div>
			</Modal>
		</>
	);
};

export default AddEmailModal;
