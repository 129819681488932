import React from "react";
import styles from "./evaluations-statistics-segments.module.scss";
import { ReactComponent as DividerIcon } from "static/icons/divider.svg";
import cx from "classnames";

const EvaluationsStatisticsSegments = ({ stats, total, suffix = "" }) => {
	return (
		<div className={styles.summaryRecap}>
			<div className={styles.summaryChart}>
				{stats.map(item => {
					const width = item.width || `${(item.percent / total) * 100}%`;
					return (
						width !== "0%" && (
							<div
								key={item.label}
								style={{
									background: item.backgroundColor,
									height: "13px",
									flex: "1 0 0",
									borderRadius: "3px",
									maxWidth: width
								}}
							></div>
						)
					);
				})}
			</div>
			<div className={styles.summaryScoresRecap}>
				{stats.map((item, index) => (
					<div key={item.label} className={styles.summaryScoresRecap}>
						<div
							className={cx({
								[styles.summaryScoresRecapElement]: index,
								[styles.summaryScoresRecapFirstElement]: !index
							})}
						>
							<div className={styles.score}>
								{item.icon}
								<span className={styles.scoreStatus}>{item.label}</span>
							</div>
							<span className={styles.scoreTotal}>
								{item.percent}
								{suffix}
							</span>
						</div>

						{index !== stats.length - 1 && (
							<div className={styles.divider}>
								<DividerIcon />
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};
export default EvaluationsStatisticsSegments;
