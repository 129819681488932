import React from "react";
import _get from "lodash/get";
import Star from "common/Icons/Star";
import GlobalTooltip from "common/GlobalTooltip";
import { SKILLS, SEARCH_CONFIG } from "config";
import { ItemsWrapper, Item, ShowMore } from "../Styled";

const Skills = ({ skills, deleteFilter, openModal }) => {
	let sectors = {};
	let groupedSkills = [];
	let displayedGroups = 0;
	let groupCount = 0;

	const {
		MAXIMUM_DISPLAYED_CATEGORIES,
		MAXIMUM_DISPLAYED_SKILLS,
		MAXIMUM_SKILL_LENGTH
	} = SEARCH_CONFIG;

	skills.forEach(skill => {
		if (sectors[_get(skill, "parent_sector.name")]) {
			sectors[_get(skill, "parent_sector.name")].push(skill);
		} else {
			sectors[_get(skill, "parent_sector.name")] = [skill];
		}
	});
	for (let sector in sectors) {
		let displayedSkillsCount = 0;
		groupCount += 1;
		if (displayedGroups < MAXIMUM_DISPLAYED_CATEGORIES) {
			groupedSkills.push(
				<div style={{ marginBottom: "0px" }} key={sector}>
					<div className="sector">{`${sector} ${
						sectors[sector].length > MAXIMUM_DISPLAYED_SKILLS
							? `(+${sectors[sector].length - MAXIMUM_DISPLAYED_SKILLS})`
							: ""
					}`}</div>
					<ItemsWrapper>
						{sectors[sector].map(skill => {
							if (displayedSkillsCount < MAXIMUM_DISPLAYED_SKILLS) {
								displayedSkillsCount += 1;
								return skill.name.length > MAXIMUM_SKILL_LENGTH ? (
									<GlobalTooltip
										key={skill._id}
										placement="top"
										mouseEnterDelay={0}
										mouseLeaveDelay={0}
										overlay={skill.name}
										overlayClassName="g-tool-dark"
									>
										<Item
											hasTooltip
											key={skill._id}
											mustHave={skill.must_have}
											className="skill-item"
										>
											{skill.must_have && <Star className="must_have_star" />}
											{`${skill.name.substr(0, MAXIMUM_SKILL_LENGTH)}... (${
												skill.score
											}/5)`}

											<span
												className="icon-close"
												onClick={() => deleteFilter(SKILLS, skill._id)}
											></span>
										</Item>
									</GlobalTooltip>
								) : (
									<Item
										key={skill._id}
										mustHave={skill.must_have}
										className="skill-item"
									>
										{skill.must_have && <Star className="must_have_star" />}
										{`${skill.name} (${skill.score}/5)`}
										<span
											className="icon-close"
											onClick={() => deleteFilter(SKILLS, skill._id)}
										></span>
									</Item>
								);
							}
							return <></>;
						})}
					</ItemsWrapper>
				</div>
			);
			displayedGroups += 1;
		}
	}
	return (
		<>
			{groupedSkills}
			{displayedGroups < groupCount && (
				<ShowMore onClick={openModal}>
					<span> + </span>
					<span>({groupCount - displayedGroups}) Show more</span>
				</ShowMore>
			)}
		</>
	);
};

export default Skills;
