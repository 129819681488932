import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

function filterSettings(key, { tag }) {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag
		}
	});
}

function useFilterSettings(
	tag = "@profile/filtter-settings",
	options,
	queryOptions
) {
	return useQuery([tag, options], filterSettings, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
}

export default useFilterSettings;
