import React, { useEffect, useRef, useState } from "react";
import Table from "common/AgTable/AgTable";
import { generateEmptyArray } from "config/helpers";
import {
	API_DATA_TYPE,
	MISSIONS_LIST_SIZES
} from "../_MissionsListUtils/missionsListUtils";
import { useMissionsListColumns } from "../../hooks/useMissionsListColumns";
import MissionsListTableEmptyState from "../MissionsListTableEmptyState/MissionsListTableEmptyState";
import { formatMissionsList } from "../_MissionsListUtils/missionsListHelper";
import { getMissionDetailsContainerRoute } from "modules/MissionsDetails";
import { browserHistory } from "react-router";

const MissionListTable = ({
	columns: columnsProps,
	missionsList: missionsListProp,
	isLoading,
	isFetching
}) => {
	const [missionsList, setMissionsList] = useState();

	const ref = useRef(null);

	useEffect(() => {
		if (missionsListProp) {
			const list = formatMissionsList(missionsListProp);
			setMissionsList(list);
		}
	}, [missionsListProp]);

	const onRowClick = ({ column, data }) => {
		const colId = column.colId;
		if (colId === "_selects") return;
		browserHistory.push(getMissionDetailsContainerRoute(data.id));
	};

	const columns = useMissionsListColumns({
		columns: columnsProps,
		isFetching,
		isLoading,
		tableRef: ref
	});

	return (
		<Table
			rows={
				isLoading || isFetching
					? generateEmptyArray(MISSIONS_LIST_SIZES)
					: missionsList
			}
			columnsSettings={columns}
			ref={ref}
			typeTag={API_DATA_TYPE}
			noRowsOverlayComponent={MissionsListTableEmptyState}
			onCellClicked={onRowClick}
		/>
	);
};
export default MissionListTable;
