import React from "react";
import _get from "lodash/get";
import style from "../../my-company.module.scss";
import cx from "classnames";
import { ReactComponent as IconLeftBlack } from "static/icons/IconLeftBlack.svg";
import { ReactComponent as DeleteIcon } from "static/icons/xDelete.svg";

const RenderInputAdress = ({
	value,
	modeEdit,
	setEditAddress,
	removeInput,
	addSite,
	setAddressIndex
}) => {
	const fullAdress = `${_get(value, "street", "") &&
		_get(value, "street") + " "}${_get(value, "number", "") &&
		_get(value, "number", "") + " "}${_get(value, "box", "") &&
		_get(value, "box", "") + " "}${_get(value, "zip", "") &&
		_get(value, "zip", "") + " "}${_get(value, "city", "") &&
		_get(value, "city", "") + " "}${_get(value, "country", "")}`;

	return (
		<>
			{!addSite ? (
				<span className={cx(style.adresse, { [style.editInput]: modeEdit })}>
					{fullAdress && fullAdress}
					{removeInput && modeEdit && (
						<button className={style.deletBtn} onClick={() => removeInput()}>
							<DeleteIcon />
						</button>
					)}
				</span>
			) : (
				<span className={style.siteAddress}>{fullAdress && fullAdress}</span>
			)}

			{modeEdit && (
				<button
					type="button"
					className={style.btn}
					onClick={() => {
						setEditAddress(true);
						setAddressIndex?.();
					}}
				>
					<IconLeftBlack />
				</button>
			)}
		</>
	);
};

export default RenderInputAdress;
