import React from "react";
const SystemIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<g>
				<polygon
					className="notification-icon"
					points="202.9,201.1 202.9,269.3 312.4,307.3 312.4,160.9"
				/>
			</g>
			<g>
				<g>
					<path
						className="notification-icon-origin"
						d="M328.5,233.3c0-8.2-5.2-15.2-12.5-17.8v-59.1l-111.2,38.9c-1.1-0.7-2.4-1.1-3.7-1.1h-67.5
				c-4,0-7.2,3.2-7.2,7.2v13.6c-10.4,2.1-18.1,11.2-18.1,22.2c0,11,7.8,20.1,18.1,22.2v9.4c0,4,3.2,7.2,7.2,7.2h18.6v50.8
				c0,9.9,8,17.9,17.9,17.9h1.9c9.9,0,17.9-8,17.9-17.9v-50.8H201c1.6,0,3.1-0.6,4.3-1.4L316,313.4v-62.2
				C323.3,248.5,328.5,241.6,328.5,233.3L328.5,233.3z M183.1,326.9c0,6.1-5,11.1-11.1,11.1H170c-6.1,0-11.1-5-11.1-11.1v-50.2h24.2
				V326.9z M115,237.3c0-7.2,4.8-13.2,11.3-15.2v30.4C119.8,250.5,115,244.4,115,237.3L115,237.3z M133.1,269v-9.1c0,0,0,0,0.1,0
				v-45.1c0,0-0.1,0-0.1,0v-13.3c0-0.2,0.2-0.4,0.4-0.4H200v68.2h-66.5C133.3,269.3,133.1,269.2,133.1,269L133.1,269z M208.2,268.5
				v-67c0,0,0,0,0-0.1l101-35.3v137.7L208.2,268.5z M316,243.6v-20.5c3.4,2.1,5.7,5.9,5.7,10.3C321.7,237.7,319.4,241.5,316,243.6
				L316,243.6z M316,243.6L316,243.6z"
					/>
					<path
						className="notification-icon-origin"
						d="M172,346.4H170c-10.7,0-19.5-8.7-19.5-19.5v-49.2h-17.1c-4.8,0-8.7-3.9-8.7-8.7v-8.2
				c-10.6-2.7-18.1-12.4-18.1-23.5c0-11.1,7.6-20.7,18.1-23.5v-12.4c0-4.8,3.9-8.7,8.7-8.7H201c1.4,0,2.7,0.3,3.9,0.9l112.6-39.4
				v60.2c7.5,3.2,12.5,10.6,12.5,18.9c0,8.2-5,15.7-12.5,18.8v63.4l-112-39.2c-1.4,0.8-2.9,1.3-4.5,1.3h-9.5v49.2
				C191.5,337.6,182.7,346.4,172,346.4z M133.5,195.8c-3.1,0-5.6,2.5-5.6,5.6v14.9l-1.2,0.3c-9.8,2-16.9,10.7-16.9,20.7
				c0,10,7.1,18.7,16.9,20.7l1.2,0.3V269c0,3.1,2.5,5.6,5.6,5.6h20.2v52.3c0,9,7.3,16.4,16.4,16.4h1.9c9,0,16.4-7.3,16.4-16.4v-52.3
				H201c1.2,0,2.3-0.4,3.4-1.1l0.7-0.5l0.8,0.3l108.6,38V250l1-0.4c6.9-2.5,11.5-9,11.5-16.3c0-7.3-4.6-13.9-11.5-16.3l-1-0.4v-58
				l-109.9,38.4l-0.6-0.4c-0.9-0.6-1.9-0.8-2.9-0.8H133.5z M172,339.5H170c-7,0-12.7-5.7-12.7-12.7v-51.8h27.3v51.8
				C184.6,333.9,179,339.5,172,339.5z M160.5,278.3v48.6c0,5.3,4.3,9.5,9.6,9.5h1.9c5.3,0,9.6-4.3,9.6-9.5v-48.6H160.5z M310.7,306
				l-104.1-36.4v-69.2l1-0.4l103-36V306z M209.7,267.4l97.8,34.2V168.3l-97.8,34.2V267.4z M201.5,270.9h-68.1
				c-1.1,0-1.9-0.8-1.9-1.9v-10.7h0.1v-42l-0.1-1.6v-13.3c0-1.1,0.9-1.9,1.9-1.9h68.1V270.9z M134.7,267.7h63.7v-65.1h-63.7v10.5
				l0.1,1.6v46.7h-0.1V267.7z M127.9,254.5l-2-0.6c-7.3-2.2-12.4-9-12.4-16.7c0-7.6,5.1-14.5,12.4-16.7l2-0.6V254.5z M124.8,224.3
				c-4.9,2.3-8.2,7.4-8.2,12.9c0,5.5,3.3,10.6,8.2,12.9V224.3z M314.4,246.4v-26.1l2.4,1.5c4,2.5,6.4,6.8,6.4,11.6
				c0,4.7-2.4,9.1-6.4,11.6L314.4,246.4z M317.5,226.4v13.9c1.7-1.9,2.6-4.3,2.6-6.9C320.1,230.8,319.2,228.3,317.5,226.4z"
					/>
				</g>
			</g>
			<g>
				<path
					className="notification-icon-origin"
					d="M366.7,182.8l-19.9,19.9c-1.9,1.9-5.1,1.9-7,0l0,0c-1.9-1.9-1.9-5.1,0-7l19.9-19.9c1.9-1.9,5.1-1.9,7,0v0
			C368.7,177.7,368.7,180.8,366.7,182.8z"
				/>
				<path
					className="notification-icon-origin"
					d="M343.3,205.7c-1.7,0-3.3-0.6-4.6-1.9c-2.5-2.5-2.5-6.7,0-9.2l19.9-19.9c1.2-1.2,2.9-1.9,4.6-1.9
			s3.4,0.7,4.6,1.9c1.2,1.2,1.9,2.9,1.9,4.6c0,1.7-0.7,3.4-1.9,4.6l-19.9,19.9C346.6,205.1,345,205.7,343.3,205.7z M363.2,175.9
			c-0.9,0-1.8,0.4-2.4,1l-19.9,19.9c-1.3,1.3-1.3,3.5,0,4.8c1.3,1.3,3.5,1.3,4.8,0l19.9-19.9c0.6-0.6,1-1.5,1-2.4s-0.4-1.8-1-2.4
			S364.1,175.9,363.2,175.9z"
				/>
			</g>
			<g>
				<path
					className="notification-icon-origin"
					d="M359.7,290.9L339.8,271c-1.9-1.9-1.9-5.1,0-7v0c1.9-1.9,5.1-1.9,7,0l19.9,19.9c1.9,1.9,1.9,5.1,0,7l0,0
			C364.8,292.9,361.7,292.9,359.7,290.9z"
				/>
				<path
					className="notification-icon-origin"
					d="M363.2,293.9c-1.7,0-3.4-0.7-4.6-1.9l-19.9-19.9c-1.2-1.2-1.9-2.9-1.9-4.6s0.7-3.4,1.9-4.6
			c2.5-2.5,6.7-2.5,9.2,0l19.9,19.9c2.5,2.5,2.5,6.7,0,9.2C366.6,293.3,365,293.9,363.2,293.9z M343.3,264.1c-0.9,0-1.7,0.3-2.4,1
			c-0.6,0.6-1,1.5-1,2.4s0.4,1.8,1,2.4l19.9,19.9c1.3,1.3,3.5,1.3,4.8,0c1.3-1.3,1.3-3.5,0-4.8l-19.9-19.9
			C345,264.4,344.2,264.1,343.3,264.1z"
				/>
			</g>
			<g>
				<path
					className="notification-icon-origin"
					d="M386.4,238.3h-28.2c-2.7,0-5-2.2-5-5l0,0c0-2.7,2.2-5,5-5h28.2c2.7,0,5,2.2,5,5l0,0
			C391.4,236.1,389.1,238.3,386.4,238.3z"
				/>
				<path
					className="notification-icon-origin"
					d="M386.4,239.9h-28.2c-3.6,0-6.5-2.9-6.5-6.5s2.9-6.5,6.5-6.5h28.2c3.6,0,6.5,2.9,6.5,6.5
			S390,239.9,386.4,239.9z M358.2,230c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4h28.2c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4H358.2z"
				/>
			</g>
		</g>
	</svg>
);
export default SystemIcon;
