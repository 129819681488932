import { useMutation } from "react-query";
import { EDIT_JOB } from "config/api-endpoints";
import { client } from "lib/api-client";

const editVacancy = body => {
	return client(EDIT_JOB, {
		body
	});
};

export const useEditVacancyApi = () => {
	return useMutation(editVacancy);
};
