/* eslint-disable react/prop-types */
import React, { useState } from "react";
import * as copyToClipboard from "copy-to-clipboard";
import styled from "styled-components";
import Tooltip from "rc-tooltip";

const Wrapper = styled.span`
	white-space: initial;
	.with-copy {
		cursor: pointer;
		visibility: ${props =>
			props.showCopyButton
				? props.showCopyButton === "always"
					? "visible"
					: "hidden"
				: "visible"};
	}
	&:hover .with-copy {
		visibility: visible;
	}
`;

const CopyValue = ({ children, ...props }) => {
	const [copied, setCopy] = useState(false);

	const handleCopy = () => {
		let { text } = props;
		text = (text || "").toString().trim();
		copyToClipboard(text);
		setCopy({ copied: true }, () => {
			setTimeout(() => {
				setCopy({ copied: false });
			}, 1000);
		});
	};

	return (
		<Wrapper {...props}>
			{children}
			&nbsp;
			<Tooltip
				placement="top"
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				overlay={<div>{copied ? "Copied!" : "Copy to clipboard"}</div>}
			>
				<i
					className={`with-copy fa ${copied ? "fa-check" : "fa-clipboard"}`}
					onClick={handleCopy}
				/>
			</Tooltip>
		</Wrapper>
	);
};
export default CopyValue;
