import React from "react";
import _get from "lodash/get";
import { useSelector } from "react-redux";
import SettingsSwitcher, { InlineSwitcher } from "./SettingsSwitcher";
import { useUpdateNotifications } from "modules/user/api/useUpdateNotifications";
import { Box } from "rebass/styled-components";
import globalStyles from "../styles.module.scss";
import { EMAILS_PERIOD_PERM, INACTIVE } from "config";
import styles from "./styles.module.scss";
import Loader from "common/Loader";

const NotificationsSettings = () => {
	const user = useSelector(state => state.auth.user);
	const allEmails = _get(user, "receive_all_emails", false);
	const userIsInactive = _get(user, "candidate_status") === INACTIVE;
	const matchingJobsAlerts = _get(user, "receive_matching_jobs_emails", false);

	const [updateNotifications, { isLoading }] = useUpdateNotifications();
	const onSwitch = event => {
		const {
			target: { name, checked }
		} = event;
		updateNotifications({ name, value: checked });
	};

	return (
		<div
			className={globalStyles.white_box}
			style={{ marginBottom: "32px", position: "relative" }}
		>
			{isLoading && (
				<Box sx={{ position: "absolute", top: "30px", right: "30px" }}>
					<Loader check={false} classes={styles.notifications__loader} />
				</Box>
			)}
			<div className={globalStyles.white_box__title}>Notifications</div>
			<div className={styles.notifications__settings}>
				<div className={styles.notifications__leftColumn}>
					<div>
						<SettingsSwitcher
							label={"Receive all notifications via e-mail"}
							checked={_get(user, "receive_all_emails", false)}
							onChange={onSwitch}
							name={"receive_all_emails"}
							classes="notifications-toggle"
							disabled={userIsInactive}
							tooltipIsActive={userIsInactive}
							overlayText={
								"Only supported when status is on “active” or “passive” mode!"
							}
						/>
					</div>
					<div>
						<SettingsSwitcher
							label={"Show notifications in real time"}
							checked={_get(user, "show_notification_in_real_time", false)}
							onChange={onSwitch}
							name={"show_notification_in_real_time"}
							classes="notifications-toggle"
						/>
					</div>
				</div>

				<div className={styles.notifications__rightColumn}>
					<SettingsSwitcher
						label={"Receive matching vacancies via email"}
						checked={_get(user, "receive_matching_jobs_emails", false)}
						onChange={onSwitch}
						name={"receive_matching_jobs_emails"}
						classes="notifications-toggle"
						disabled={!allEmails || userIsInactive}
						tooltipIsActive={!allEmails || userIsInactive}
						overlayText={
							userIsInactive
								? "Only supported when status is on “active” or “passive” mode!"
								: "Only supported when notifications via e-mail are enabled!"
						}
					/>
					{matchingJobsAlerts && (
						<InlineSwitcher
							options={EMAILS_PERIOD_PERM}
							onChange={value =>
								updateNotifications({
									name: "receive_matching_jobs_emails_period",
									value
								})
							}
							value={_get(
								user,
								"receive_matching_jobs_emails_period",
								undefined
							)}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default NotificationsSettings;
