import React from "react";
import styles from "./email-signature-form.module.scss";
import cx from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as LinkedinIcon } from "static/icons/social/linkedin.svg";
import { ReactComponent as FacebookIcon } from "static/icons/social/facebook.svg";
import { ReactComponent as XIcon } from "static/icons/social/x.svg";
import { ReactComponent as InstagramIcon } from "static/icons/social/instagram.svg";
import PhoneInput from "common/PhoneInput/new-phone-input";
import AvatarUploader from "../AvatarUploader/AvatarUploader";

export default function EmailSignatureForm() {
	const {
		formState: { errors },
		register,
		control
	} = useFormContext();

	return (
		<div className={styles.container}>
			<div className={styles.sectionTitle}>General</div>
			<div className={styles.field}>
				<Controller
					name="avatar"
					control={control}
					render={({ field: { onChange, value } }) => {
						return <AvatarUploader onChange={onChange} value={value} />;
					}}
				/>
			</div>
			<label className={styles.field}>
				<div className={styles.label}>
					Full name <span className={styles.asterisk}> *</span>
				</div>
				<input
					className={cx(styles.input, errors.full_name && styles.hasError)}
					{...register("full_name")}
					placeholder="Enter full name"
				/>
				{errors.full_name && (
					<div className={styles.fieldError}>{errors.full_name.message}</div>
				)}
			</label>
			<label className={styles.field}>
				<div className={styles.label}>
					Job title <span className={styles.asterisk}> *</span>
				</div>
				<input
					className={cx(styles.input, errors.position && styles.hasError)}
					{...register("position")}
					placeholder="Enter job title"
				/>
				{errors.position && (
					<div className={styles.fieldError}>{errors.position.message}</div>
				)}
			</label>
			<div className={styles.sectionTitle}>Contacts</div>
			<div className={styles.field}>
				<div className={styles.label}>Phone number</div>
				<Controller
					name="phone"
					control={control}
					render={({ field: { onChange, value } }) => {
						return (
							<PhoneInput
								onChange={(value, countryCode) => {
									onChange({
										value,
										countryCode: countryCode?.toUpperCase?.()
									});
								}}
								value={value}
							/>
						);
					}}
				/>
				{errors.phone && (
					<div className={styles.fieldError}>{errors.phone.message}</div>
				)}
			</div>
			<label className={styles.field}>
				<div className={styles.label}>Email</div>
				<input
					className={cx(styles.input, errors.email && styles.hasError)}
					{...register("email")}
					placeholder="Enter email"
				/>
				{errors.email && (
					<div className={styles.fieldError}>{errors.email.message}</div>
				)}
			</label>
			<label className={styles.field}>
				<div className={styles.label}>Address</div>
				<input
					className={cx(styles.input, errors.address && styles.hasError)}
					{...register("address")}
					placeholder="Enter address"
				/>
				{errors.address && (
					<div className={styles.fieldError}>{errors.address.message}</div>
				)}
			</label>
			<div className={styles.sectionTitle}>Social Media</div>
			<label className={styles.field}>
				<div className={styles.socialInputContainer}>
					<LinkedinIcon width={28} height={28} />
					<input
						className={cx(
							styles.input,
							errors.social_media?.[0]?.url && styles.hasError
						)}
						{...register("social_media.0.url")}
						placeholder="Enter Linkedin url"
					/>
				</div>
				{errors.social_media?.[0]?.url && (
					<div className={styles.fieldError}>
						{errors.social_media[0].url.message}
					</div>
				)}
			</label>
			<label className={styles.field}>
				<div className={styles.socialInputContainer}>
					<XIcon width={28} height={28} />
					<input
						className={cx(
							styles.input,
							errors.social_media?.[1]?.url && styles.hasError
						)}
						{...register("social_media.1.url")}
						placeholder="Enter Twitter url"
					/>
				</div>
				{errors.social_media?.[1]?.url && (
					<div className={styles.fieldError}>
						{errors.social_media[1].url.message}
					</div>
				)}
			</label>
			<label className={styles.field}>
				<div className={styles.socialInputContainer}>
					<InstagramIcon width={28} height={28} />
					<input
						className={cx(
							styles.input,
							errors.social_media?.[2]?.url && styles.hasError
						)}
						{...register("social_media.2.url")}
						placeholder="Enter Instagram url"
					/>
				</div>
				{errors.social_media?.[2]?.url && (
					<div className={styles.fieldError}>
						{errors.social_media[2].url.message}
					</div>
				)}
			</label>
			<label className={styles.field}>
				<div className={styles.socialInputContainer}>
					<FacebookIcon width={28} height={28} />
					<input
						className={cx(
							styles.input,
							errors.social_media?.[3]?.url && styles.hasError
						)}
						{...register("social_media.3.url")}
						placeholder="Enter Facebook url"
					/>
				</div>
				{errors.social_media?.[3]?.url && (
					<div className={styles.fieldError}>
						{errors.social_media[3].url.message}
					</div>
				)}
			</label>
		</div>
	);
}
