import React from "react";
import get from "lodash/get";
import Table from "modules/home/components/table/Table.jsx";
import EmptyState from "modules/home/components/EmptyState.jsx";
import emptyStateImage from "modules/home/components/permanent/images/pendingapplicationsemptystate.png";
import Loader from "common/Loader";
import style from "modules/home/components/permanent/pendingApplications/styles.module.scss";
import { ReactComponent as PlusIcon } from "modules/home/svg/Plus.svg";

const HomeTable = ({
	columns,
	data,
	onRowClick,
	isLoading,
	heading,
	buttonLabel,
	buttonAction,
	handleViewAll
}) => {
	if (isLoading)
		return (
			<div className={style["loader_container"]}>
				<Loader />{" "}
			</div>
		);
	if (!Boolean(get(data, "length")))
		return (
			<div className={style["loader_container"]}>
				<EmptyState
					illustration={<img src={emptyStateImage} />}
					heading={heading}
					firstAction={{
						label: buttonLabel,
						onClick: buttonAction,
						icon: <PlusIcon />
					}}
				/>
			</div>
		);

	return (
		<Table
			data={data}
			columns={columns}
			onRowClick={onRowClick}
			handleViewAll={handleViewAll}
		/>
	);
};

export default HomeTable;
