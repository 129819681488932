import React, { Component } from "react";
import PropTypes from "prop-types";
import _debounce from "lodash/debounce";

class AutoComplete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: props.data || [],
			term: props.value || "",
			isVisible: false
		};
		if (this.props.onChange) {
			this.onChange = _debounce(this.props.onChange, 500, {
				leading: false,
				trailing: true
			});
		}
	}

	focus = () => {
		if (this.props.showOnFocus) {
			this.setState({ isVisible: true });
		}
	};

	filterResults = results => {
		const { keys } = this.props;
		const filteredResults = [];
		for (let result of results) {
			filteredResults.push({
				value: result[keys["value"]],
				label: result[keys["label"]]
			});
		}
		return filteredResults;
	};

	hideMenu = e => {
		if (
			this.state.isVisible &&
			!e.target.classList.contains("auto-complete") &&
			!e.target.classList.contains("auto-complete-input")
		) {
			this.setState({ isVisible: false });
		}
	};

	componentDidUpdate({ data, value }) {
		if (this.props.data) {
			if (data.length !== this.props.data.length) {
				this.setState({ data: this.filterResults(this.props.data) });
			} else {
				const equals = data.every((od, i) => od._id === this.props.data[i]._id);
				if (!equals) {
					this.setState({ data: this.filterResults(this.props.data) });
				}
			}
		}
		if (this.props.value !== value) {
			this.setState({ term: this.props.value });
		}
	}

	componentDidMount() {
		if (this.props.async && !this.props.data) {
			throw new Error("Please provide a data prop");
		} else if (this.props.async) {
			this.props.makeRequest();
		} else {
			this.setState({
				data: this.filterResults(this.props.data)
			});
		}
		document.addEventListener("click", this.hideMenu);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.hideMenu);
	}

	setValue = item => {
		let term = item.value;
		this.props.onSelect(item);
		if (this.props.emptyOnSelect) term = "";
		this.setState({ isVisible: false, term });
	};

	handleTextChange = e => {
		e.persist();
		const { value } = e.target;
		if (this.props.onChange) this.onChange(value);
		this.setState({ term: value });
	};

	render() {
		const { term, data } = this.state;
		const { className, placeholder } = this.props;
		return (
			<div>
				<input
					type="text"
					onFocus={this.focus}
					onChange={this.handleTextChange}
					className="form-control auto-complete-input"
					placeholder={placeholder}
					value={term}
				/>
				{this.state.isVisible && data.length > 0 && (
					<div className={`auto-complete ${className}`}>
						<ul className="list-unstyled">
							{data.map((matched, i) => (
								<li
									key={i}
									className="option"
									onClick={() => this.setValue(matched)}
								>
									{matched.label}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

AutoComplete.propTypes = {
	showOnFocus: PropTypes.bool,
	data: PropTypes.array,
	value: PropTypes.string,
	keys: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired
};

AutoComplete.defaultProps = {
	data: []
};

export default AutoComplete;
