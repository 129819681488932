import React from "react";
import styles from "./generate-description-skeleton.module.scss";
import { ReactComponent as StarsIcon } from "static/icons/3-stars.svg";
import cx from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { MAX_WIDTH, MIN_WIDTH, WIDTH_DECREMENT } from "../../utils/constants";

const generateLines = count => {
	return Array.from({ length: count }, (_, index) => {
		const width =
			Math.max(MAX_WIDTH - index * WIDTH_DECREMENT, MIN_WIDTH) + "%";
		return (
			<SkeletonTheme color="#dadee4" key={index}>
				<Skeleton width={width} />
			</SkeletonTheme>
		);
	});
};

const GenerateContentSkeleton = ({ multiLines }) => {
	const lineCount = multiLines ? 6 : 1;
	const lines = generateLines(lineCount);

	return (
		<div
			className={cx(styles.container, {
				[styles.multiLines]: multiLines
			})}
		>
			{lineCount === 1 ? (
				<div className={styles.icon}>
					<StarsIcon width={26} height={24} />
				</div>
			) : (
				<div className={styles.iconWrapper}>
					<div className={styles.icon}>
						<StarsIcon width={26} height={24} />
					</div>
				</div>
			)}

			<div className={styles.textLineSkeletonContainer}>{lines}</div>
		</div>
	);
};

export default GenerateContentSkeleton;
