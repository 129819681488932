import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { closeModal, editLanguages, updateLanguages } from "../../actions";
import { loadLanguages } from "../../../app/actions/ListsActions";
import EditableList from "common/editablelist";
import FormError from "./FormError";
import { getLanguageTooltip } from "../../../../config/helpers";
import {
	languagesListSelector,
	getSelectedLanguages
} from "../../selectors/cvSelectors";
import Modal from "../../../../common/modal/Modal";

class LanguagesModal extends Component {
	state = {
		error: false
	};

	formatLanguages(list = []) {
		if (!list.length) return list;

		return list.map(lang => ({
			value: { score: 1, ...lang },
			label: lang.language
		}));
	}

	initError = (error = false) => {
		this.setState({ error });
	};

	closeModal = e => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.props.closeModal(false);
	};

	componentDidMount() {
		if (!this.props.languages || !this.props.languages.length) {
			this.props.loadLanguages();
		}
	}

	saveLanguages = () => {
		const list = this.props.selectedLanguages;
		if ((!list || !list.length) && !this.state.error) {
			return this.initError(true);
		}
		this.props
			.updateLanguages(list, this.props.userId)
			.then(() => this.closeModal());
	};

	getSelectedIndex = (list, label) =>
		_.findIndex(list, option => option.name === label);

	removeSelected = element => {
		const selectedLang = [...this.props.selectedLanguages];
		const index = this.getSelectedIndex(
			selectedLang,
			_.get(element, "value.name")
		);
		if (index !== -1) {
			selectedLang.splice(index, 1);
			this.props.editLanguages(selectedLang);
		}
	};

	handleScoreChange = (element, rating) => {
		const selectedLang = [...this.props.selectedLanguages];
		const index = this.getSelectedIndex(
			selectedLang,
			_.get(element, "value.name")
		);
		if (index !== -1) {
			selectedLang[index] = {
				...selectedLang[index],
				score: rating
			};
			this.props.editLanguages(selectedLang);
		}
	};

	onRowChange = element => {
		if (element) {
			const selectedLang = [
				...this.props.selectedLanguages,
				{
					_id: element.value,
					name: element.label,
					score: 1
				}
			];
			this.props.editLanguages(selectedLang);
			if (this.state.error) {
				this.initError(false);
			}
		}
	};

	render() {
		const {
			isFetching,
			closeModal,
			languages,
			selectedLanguages,
			loading
		} = this.props;

		const { error } = this.state;

		return (
			<Modal
				fixed={false}
				onClose={closeModal}
				loading={loading || isFetching}
				title="Languages"
				overlay="Languages are another fundamental part of the matching process.
				Although they are weighted less than skills in the matching score calculation,
				it is still important to properly outline your linguistic skills."
				firstButton={{
					action: this.saveLanguages,
					type: "primary",
					label: "Save to my profile"
				}}
			>
				{error && (
					<FormError
						message={
							"You are required to add at least one language in order to proceed."
						}
					/>
				)}
				<div className="content">
					<EditableList
						minHeight={true}
						label="Languages"
						options={languages}
						simpleValue={false}
						itemsLabel="LANGUAGE"
						scoreLabel="PROFICIENCY"
						scorePath="value.score"
						idPath="value._id"
						labelName="value.name"
						onRowChange={this.onRowChange}
						removeSelected={this.removeSelected}
						tooltipFormater={getLanguageTooltip}
						handleScoreChange={this.handleScoreChange}
						selectedOptions={this.formatLanguages(selectedLanguages)}
						placeholder="Type your desired language, then select the closest match."
					/>
				</div>
			</Modal>
		);
	}
}

LanguagesModal.propTypes = {
	languages: PropTypes.array.isRequired,
	userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	languages: languagesListSelector(state),
	selectedLanguages: getSelectedLanguages(state)
});

const mapDispatchToProps = dispatch => ({
	closeModal: (value = true) => dispatch(closeModal(value)),
	loadLanguages: () => dispatch(loadLanguages()),
	editLanguages: list => dispatch(editLanguages(list)),
	updateLanguages: (list, userId) => dispatch(updateLanguages(list, userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesModal);
