//TODO Remove all hooks useGetLanguages from the project and use this hook
import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_LANGUAGES } from "config/api-endpoints";

const getLanguages = () => client(GET_LANGUAGES);

function useGetLanguages(options = {}) {
	return useQuery("get-languages", getLanguages, {
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		retry: 0,
		...options
	});
}

export default useGetLanguages;
