import styled from "styled-components";
import Modal from "common/modal";
import { colors } from "config/styles";

export const StyledModal = styled(Modal)`
	.actions {
		float: right;
	}
	.accept-btn {
		width: 170px;
	}
`;

export const Title = styled.div`
	font-size: 16px;
	font-family: roboto;
	font-weight: 400;
	color: #ff6849;
	text-align: center;
	margin-top: 10px;
`;

export const TextTitle = styled.div`
	font-size: 15px;
	font-weight: 400;
	text-align: center;
`;

export const BodyContainer = styled.div`
	display: flex;
	padding: 10px 25px 10px 25px;
	width: 95%;
	margin-left: 25px;
	.section-proposition {
		width: 100%;
		display: inline-block;
	}
	.adress-grid {
	}

	.timeDate-container {
		margin-right: 10px;

		.icon-date-time {
			font-size: x-large;
			color: #ff6849;
		}

		.icon-date-location {
			padding: 7px 15px 0px 7px;
		}
		.icon-date-adress {
			align-self: center;
			padding: 7px 15px 0px 7px;
		}
	}

	.location-container {
		.adress-divider {
			.location-title {
				font-weight: bold;
				font-size: 13px;
				height: 20px;
			}
		}
	}
	.timeDate-container,
	.location-container {
		border-radius: 4px;
		box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
		border: solid 0.5px #e1e4e7;
		background-color: #ffffff;
		display: flex;
		width: 50%;
		padding: 20px 0px 20px 25px;
		height: 100px;
		.location-divider {
		}
		.location-title {
			display: block;
			margin-bottom: 6px;
		}
		.location-title,
		.timeDate-title {
			font-weight: bold;
			font-size: 13px;
			height: 13px;
		}

		.location-infos {
			font-size: 14px;
			font-weight: 400;
		}
		.timeDate-adress {
			height: fit-content;
			align-self: center;

			.timeDate-title {
				height: 20px;
			}
		}
		.timeDate-infos {
			.timeDate-values {
				display: flex;
			}
			.date-value {
				margin-right: 10px;
				font-size: 14px;
				font-weight: 400;
			}
			.time-value {
				margin-left: 10px;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.video_conf_icon {
			width: 55px;
			position: relative;
			top: 5px;
		}

		svg {
			width: 50px;
			height: auto;
			margin-top: -10px;

			path {
				fill: #ff6849;
			}
		}
	}
`;
export const Divider = styled.div`
	border-left: 2px solid #e4e8ec;
	height: 15px;
	margin-top: 2px;
`;

export const AttendeesContainer = styled.div`
	font-size: 13px;
	font-weight: 400;
	padding: 0px 0px 45px 50px;

	div,
	p {
		color: #3d4b60 !important;
		font-size: 14px !important;
		font-weight: 400 !important;
		display: inline-block !important;
	}
	.infos-label {
		margin-right: 30px;
	}
	.description: {
		display: inline-block !important;
	}
	.section {
		display: flex !important;
	}
`;

export const PropositionBox = styled.div`
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};
	margin: ${props => (props.maxwidth ? "0 0 0 0" : "30px 30px 30px 0")};
	font-weight: 400;
	.title {
		max-width: ${props => (props.maxwidth ? "100%" : "none")};
		color: ${props =>
			props.orange ? colors.primaryColor : colors.anchorsColor};
		text-align: left;
		font-weight: bold;
		font-size: 15px;
		text-transform: none;
		line-height: 14px;
		margin-bottom: 18px;
		div {
			font-weight: normal;
			font-size: 12px;
			margin-top: 3px;
		}
	}
	.date {
		text-align: center;
		font-weight: 300;
		line-height: 20px;
	}
	.BoxWrapper {
		display: flex;
		width: 100%;
		&.title {
			margin-bottom: 13px;
			&.modal-proposition {
				color: #242f43;
			}
		}
	}
`;

export const BoxInterview = styled.div`
	max-width: 270px;
	width: 100%;
	height: 112px;
	border-radius: 4px;
	box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
	border: solid 1px #e1e4e7;
	background-color: #ffffff;
	align-items: center;
	display: flex;
	&:last-child {
		margin-left: 10px;
	}
	.videoConference {
		line-height: 23px;
		span {
			font-size: 14px;
			color: #242f43;
		}
	}
	.titleBox {
		font-size: 13px;
		font-weight: bold;
		color: #242f43;
		margin-bottom: 4px;
	}
	.icon-date-location {
		color: rgba(255, 104, 73, 0.96);
		font-size: 26px;
		margin-right: 16px;
		margin-left: 18px;
		.video-interview-icon {
			font-size: 20px !important;
		}
	}
	.detailsInterview {
		line-height: 20px;
		span {
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #242f43;
			display: inline-block;
			line-height: 22px;
			&.dateInterview {
				border-right: 1px solid #e4e8ec;
				padding-right: 8px;
				margin-right: 8px;
			}
			&.timeInterview {
				i {
					color: rgba(255, 104, 73, 0.96);
					font-size: 12px;
				}
			}
		}
	}
`;

export const Content = styled.div`
	line-height: 1.6;
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};

	font-weight: normal;
	word-break: break-word;
	${props => props.location && `text-align: center; font-size:12px`};
	.adress-grid {
		span {
			display: block;
		}
	}
`;
