/* eslint-disable react/display-name */

import React, { useEffect } from "react";
import { useTour } from "@reactour/tour";
import styles from "../../components/TourContentComponent/tour-content-component.module.scss";
import TourContentComponent from "../../components/TourContentComponent";
import useOnboardingStore from "../../hooks/useOnboardingStore";
import Portal from "common/Portal";
import { TOUR_MAILBOX } from "../../../../config/onboarding";
import { browserHistory } from "react-router";
import isElementLoaded from "../../helpers/isElementLoaded";
import useNotesStore from "modules/notes/notes.store";

const selectorForStep = stepNumber =>
	`[data-onboarding-step=${TOUR_MAILBOX}-${stepNumber}]`;

const steps = [
	{
		selector: selectorForStep(0),
		title: `Click on Mailbox icon`,
		description: `Access the mail feature by clicking the envelope icon in the top navigation bar.`,
		stepInteraction: false,
		position: p => [p.left - 380, p.top + 50],
		arrow: "top-right"
	},
	{
		selector: selectorForStep(1),
		title: `Sync your Mail`,
		description: `Sync with Google mail or Outlook to enable sending, scheduling, and managing emails directly from the platform.`,
		stepInteraction: false,
		position: p => [p.right - 30, p.top + 35],
		arrow: "top-left"
	},
	{
		selector: selectorForStep(2),
		title: `Sync your Mail`,
		description: `Sync with Google mail or Outlook to enable sending, scheduling, and managing emails directly from the platform.`,
		stepInteraction: false,
		position: p => [p.left - 416, p.top + 16],
		arrow: "right-top"
	},
	{
		selector: selectorForStep(3),
		title: `Click on New Mail`,
		description: `Create a new email by clicking the "New Mail" button.h`,
		stepInteraction: false,
		position: p => [p.left - 416, p.top + 16],
		arrow: "right-top"
	},
	{
		selector: selectorForStep(4),
		title: `Add a template`,
		description: `Choose from predefined email templates to speed up the composition process.`,
		stepInteraction: false,
		position: p => [p.left - 416, p.top + 16],
		arrow: "right-top"
	},
	{
		selector: selectorForStep(5),
		title: `Add placeholders`,
		description: `Insert dynamic fields like candidate name or interview date that will automatically fill with relevant details.`,
		stepInteraction: false,
		position: p => [p.left - 320, p.top + 40],
		arrow: "top-right"
	},
	{
		selector: selectorForStep(6),
		title: `Schedule mail`,
		description: `Set a specific date and time to send the email automatically.`,
		stepInteraction: false,
		position: p => [p.right - 18, p.top - 180],
		arrow: "bottom-left"
	},
	{
		selector: selectorForStep(7),
		title: `Or send mail`,
		description: `If you're ready, hit send to deliver the email immediately.`,
		stepInteraction: false,
		position: p => [p.left - 360, p.top - 180],
		arrow: "bottom-right"
	}
];

export const useMailboxTour = (props = {}) => {
	const store = useOnboardingStore();
	const tour = useTour();
	const { clearStore } = useNotesStore(({ clearStore }) => ({ clearStore }));

	useEffect(() => {
		if (store.workflowId === TOUR_MAILBOX) {
			if (!tour.isOpen && store.currentStep === 0) {
				tour.setSteps(steps);
				tour.setCurrentStep(0);
				tour.setIsOpen(true);
			}

			if (store.currentStep === 1 && tour.currentStep === 0) {
				browserHistory.push("/mail/inbox");
				isElementLoaded(selectorForStep(1)).then(() => {
					tour.setCurrentStep(1);
				});
			}

			if (store.currentStep === 2 && tour.currentStep === 1) {
				browserHistory.push("/settings/myprofile");
				isElementLoaded(selectorForStep(2)).then(() => {
					tour.setCurrentStep(2);
				});
			}

			if (store.currentStep === 3 && tour.currentStep === 2) {
				browserHistory.push("/mail/inbox");
				isElementLoaded(selectorForStep(3)).then(() => {
					tour.setCurrentStep(3);
				});
			}

			if (store.currentStep === 4 && tour.currentStep === 3) {
				props.onAddNewEmail?.();
				isElementLoaded(selectorForStep(4)).then(() => {
					document.querySelector(selectorForStep(4)).click();
					tour.setCurrentStep(4);
				});
			}

			if (store.currentStep === 5 && tour.currentStep === 4) {
				document.querySelector(selectorForStep(5)).click();
				tour.setCurrentStep(5);
			}

			if (store.currentStep === 6 && tour.currentStep === 5) {
				document.querySelector(selectorForStep(6)).click();
				tour.setCurrentStep(6);
			}

			if (store.currentStep === 7 && tour.currentStep === 6) {
				props.setShowScheduleModal?.(false);
				tour.setCurrentStep(7);
			}

			return () => {
				if (store.currentStep === 7 && tour.currentStep === 7 && tour.isOpen) {
					clearStore();
				}
			};
		}
	}, [store.workflowId, store.currentStep, tour.currentStep, tour.isOpen]);

	return null;
};

export const providerConfig = {
	ContentComponent: TourContentComponent,
	onClickMask: () => null,
	className: styles.popover,
	maskClassName: styles.mask,
	padding: 0,
	scrollSmooth: true,
	disableKeyboardNavigation: true,
	Wrapper: ({ children }) => <Portal>{children}</Portal>
};
