import React, { useState, useCallback, useEffect } from "react";
import { Item, TabList, TabPanels, Tabs } from "@react-spectrum/tabs";
import styles from "./tabs.module.scss";
import cx from "classnames";

const CustomTabs = ({
	items,
	headerClassName,
	rootClassName,
	selectedKey,
	children,
	onTabChange,
	tabsPanelClassName,
	forceRender
}) => {
	const [key, setKey] = useState();

	useEffect(() => {
		if (selectedKey && key !== selectedKey) {
			setKey(selectedKey);
		}
	}, [selectedKey, key]);

	const handleKeyChange = useCallback(
		key => {
			setKey(key);
			onTabChange?.(key);
		},
		[setKey, onTabChange]
	);

	const tabs = items.map(({ header, id }) => (
		<Item key={id}>
			<div className={cx(styles.title, { [styles.selected]: key == id })}>
				{header}
			</div>
		</Item>
	));

	const components =
		children ||
		items.map(({ Component, id, hideTabSaved, placeHolder }) => (
			<Item key={id}>
				<Component hideTabSaved={hideTabSaved} placeHolder={placeHolder} />
			</Item>
		));

	return (
		<Tabs
			UNSAFE_className={cx(styles.root, rootClassName)}
			selectedKey={key}
			onSelectionChange={handleKeyChange}
		>
			<TabList
				key={forceRender}
				UNSAFE_className={cx(styles.tabsHeader, headerClassName)}
			>
				{tabs}
			</TabList>
			<TabPanels UNSAFE_className={tabsPanelClassName}>{components}</TabPanels>
		</Tabs>
	);
};

export default CustomTabs;
