/* eslint-disable no-prototype-builtins */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import Container from "../Container";
import SubContainer from "../SubContainer";
import FormField from "common/FormField";
import { reduxForm, Field, setSubmitFailed, change } from "redux-form";
import { searchParser } from "config/helpers";
import { emailRegex, FREELANCER, FIRST_LOGIN } from "config";
import { createToken } from "modules/user/actions/authActions";
import _get from "lodash/get";
import { Link } from "react-router";
import { ReactComponent as EmailIcon } from "static/icons/email.svg";
import { ReactComponent as EyeIcon } from "static/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "static/icons/eye-off.svg";
import { Box } from "rebass";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import WindowSizeProvider from "common/WindowSizeProvider";
import cx from "classnames";
import { ReactComponent as WiggliIcon } from "static/icons/wiggli-logo.svg";
import { ReactComponent as AppStoreIcon } from "static/icons/mobile-app-store.svg";
import { ReactComponent as GooglePlayIcon } from "static/icons/mobile-google-play.svg";

class LoginForm extends Component {
	state = {
		showPassword: false
	};

	componentDidUpdate(prevProps) {
		if (
			_get(this.props, "newEmail") &&
			_get(prevProps.newEmail) !== _get(this.props, "newEmail")
		) {
			this.props.dispatch(change("signin", "email", this.props.newEmail));
		}
	}

	createToken = formProps => {
		const searchParams = searchParser();
		if (
			!formProps.hasOwnProperty("email") ||
			!formProps.hasOwnProperty("password")
		) {
			return this.props.dispatch(
				setSubmitFailed("signin", "email", "password")
			);
		}
		if (this.props.type === FREELANCER) {
			this.props.showModal(FIRST_LOGIN);
		}
		return this.props.dispatch(
			createToken(
				formProps,
				this.props.shouldRedirect,
				this.props.redirectTo,
				null,
				searchParams
			)
		);
	};

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	render() {
		const { handleSubmit, headings, inAction, anyTouched } = this.props;
		const isLoading = inAction && anyTouched;
		return (
			<WindowSizeProvider>
				{isMobile => (
					<Box
						flexGrow={1}
						display={"flex"}
						alignItems={"center"}
						paddingY={20}
					>
						<Container className={cx({ mobileLoginContainer: isMobile })}>
							<SubContainer
								className={cx({ mobileLoginSubContainer: isMobile })}
							>
								{isMobile && <WiggliIcon />}

								<div>
									{!!headings ? (
										headings
									) : (
										<>
											<h1
												className={cx("heading", {
													mobileHeading: isMobile,
													mobileLogin: isMobile
												})}
											>
												Log in
											</h1>
											<div
												className={cx("description", {
													mobileDescription: isMobile
												})}
											>
												Please enter your credentials to login.
											</div>
										</>
									)}
									<form
										className="form"
										onSubmit={handleSubmit(this.createToken)}
									>
										<div
											className={cx("form-field", {
												mobileFormField: isMobile
											})}
										>
											<Field
												classes="inner-icon left-icon"
												svgPrefixIcon={
													<span className="icon">
														<EmailIcon />
													</span>
												}
												name="email"
												label="Email"
												placeholder="Enter your email"
												normalize={this.normalizeEmail}
												type="text"
												component={FormField}
											/>
										</div>
										<div
											className={cx("form-field", {
												mobileFormField: isMobile
											})}
										>
											<Field
												classes="inner-icon right-icon"
												svgSuffixIcon={
													<span
														role="button"
														className="icon"
														onClick={() =>
															this.setState(state => ({
																...state,
																showPassword: !state.showPassword
															}))
														}
													>
														{this.state.showPassword ? (
															<EyeOffIcon />
														) : (
															<EyeIcon />
														)}
													</span>
												}
												name="password"
												label="Password"
												placeholder="••••••••"
												type={this.state.showPassword ? "text" : "password"}
												component={FormField}
											/>
										</div>
										<div style={{ marginBottom: 20 }}>
											<Link
												className={cx("link", {
													mobileDescription: isMobile
												})}
												to="/recover-password"
											>
												Forgot password?
											</Link>
										</div>
										<button
											onClick={handleSubmit(this.createToken)}
											className="form-btn"
											disabled={isLoading}
										>
											{isLoading ? <AnimatedLoaderIcon width={24} /> : "Log in"}
										</button>
									</form>
									<div
										className={cx("description sign-up-cta", {
											mobileDescription: isMobile
										})}
									>
										{`Don't have an account?`}{" "}
										<Link
											className={cx("link", {
												mobileDescription: isMobile
											})}
											to="/sign-up"
										>
											Sign up
										</Link>
									</div>
								</div>

								<div
									className={cx("mobileApps", {
										mobileIconsOnDesktop: !isMobile
									})}
								>
									<p className="mobileDescription">
										{" "}
										Recruiting on the go? Try Wiggli on mobile.{" "}
									</p>
									<div className="mobileAppsIcons">
										<a
											className="link mobileDescription"
											href="https://apps.apple.com/ma/app/wiggli-client/id6475047294
											"
										>
											<AppStoreIcon />
										</a>
										<a
											className="link mobileDescription"
											href="https://play.google.com/store/apps/details?id=io.wiggli.app
											"
										>
											<GooglePlayIcon />
										</a>
									</div>
								</div>
							</SubContainer>
						</Container>
					</Box>
				)}
			</WindowSizeProvider>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Please enter your email.";
	} else if (!emailRegex.test(formProps.email)) {
		errors.email = "Please enter a valid email.";
	}
	if (!formProps.password || formProps.password.trim() === "") {
		errors.password = "Please enter your password.";
	}
	return errors;
};

LoginForm.defaultProps = {
	shouldRedirect: true
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			change
		},
		dispatch
	);

const mapStateToProps = state => ({
	inAction: state.app.axiosHelper.inAction
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "signin",
		validate,
		touchOnBlur: false
	})
)(LoginForm);
