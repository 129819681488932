import styled from "styled-components";

export const FiltersContainer = styled.div`
	.form-group {
		position: relative;
		margin-bottom: 24px;
		.form-control {
			border: unset;
			background: transparent;
			border-bottom: 1px solid #d6d7dd;
			padding: 0px 30px;
			min-height: 45px !important;
		}
		input.form-control {
			padding-left: 30px;
		}
		.icon-search {
			position: absolute;
			top: 17px;
			left: 8px;
			color: #a5b1bb;
		}
		.input-error {
			border: 1px solid #ff6849 !important;
		}
		.icon-reset-3 {
			position: relative;
			top: 50%;
		}
	}
	.select-label {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: 0.08px;
		color: #273238;
		line-height: 1.43;
	}
	.Select-control {
		width: 190px;
		margin-top: 6px;
		height: 40px;
		border: 2px solid #0676ed;
		border-radius: 2px;
		.Select-value {
			display: flex;
			align-items: center;
		}
	}
	.Select-menu-outer {
		width: 190px;
	}
`;

export const ViewContainer = styled.div`
	padding: 30px 15px;
	.status-label {
		font-weight: 500;
	}
`;
