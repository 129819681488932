import React from "react";
import Menu from "./menu";
import MenuButton from "./menuButton";
import MenuItem from "./menuItem";
import MenuList from "./menuList";
import EmptyMenu from "./emptyMenu";

import { get } from "lodash";

const DropDown = ({ options, changeHandler, children, styles }) => {
	return (
		<Menu changeHandler={changeHandler}>
			<MenuButton styles={styles}>{children}</MenuButton>

			<MenuList>
				{get(options, "length", 0) > 0 ? (
					options.map(option => (
						<MenuItem key={option.fullName} option={option} />
					))
				) : (
					<EmptyMenu />
				)}
			</MenuList>
		</Menu>
	);
};

export default DropDown;
