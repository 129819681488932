/* eslint-disable react/display-name */
import React, { memo, useCallback, useMemo } from "react";
import { Item } from "react-stately";
import CreateVacancyCard from "../CreateVacancyCard/CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyCardTitle } from "../CreateVacancyCardTitle";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import { ComboBox } from "common/ComboBox";
import styles from "./add-vacancy-salary-card.module.scss";
import { AddVacancyCustomInput } from "../AddVacancyCustomInput";
import { AddVacancyCustomDropdown } from "../AddVacancyCustomDropdown";
import { Picker } from "common/Picker";
import { getExtraBenefitsList } from "modules/vacancy/create-vacancy/utils/helper";
import { VACANCY_STEP_CARDS_IDS } from "modules/vacancy/create-vacancy/utils/constants";
import { Controller } from "react-hook-form";
import NewCurrencyField from "common/NewCurrencyField";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";
import { forwardRef } from "react";
import { useGetCurrenciesList } from "../../api/useGetCurrenciesList";
import { PREFERRED_COUNTRIES } from "config";
import { mapOrder } from "config/helpers";
import useElements from "hooks/useLayoutElements";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const PAYMENT_TIME_TYPES = [
	{ label: "Monthly", value: "monthly" },
	{ label: "Yearly", value: "yearly" },
	{ label: "Hourly", value: "hourly" }
];

const SALARY_TYPES = [
	{ label: "Gross", value: "gross" },
	{ label: "Net", value: "net" }
];

const AddVacancySalaryCard = ({
	countriesList,
	errors,
	control,
	setValue,
	clearErrors,
	payrollCountry,
	currency,
	salaryMin,
	triggerFormValidation,
	isSubmitted
}) => {
	const { mainElement } = useElements();
	const getCountryById = useCallback(
		id => {
			return countriesList.find(({ code }) => code === id);
		},
		[countriesList]
	);

	const { workflowId } = useOnboardingStore();
	const { data: currenciesList } = useGetCurrenciesList({
		enabled: !workflowId
	});

	const countriesItems = useMemo(() => {
		if (!countriesList) return [];

		const list = mapOrder(countriesList, PREFERRED_COUNTRIES, "code");

		return list?.map(({ country, code }) => <Item key={code}>{country}</Item>);
	}, [countriesList]);

	const currenciesItems = useMemo(() => {
		return (currenciesList || []).map(({ code }) => (
			<Item key={code}>{code}</Item>
		));
	}, [currenciesList]);

	const onCountrySelectionChange = useCallback(
		(key, onChange) => {
			const countryObject = getCountryById(key);
			const currencyObject = {
				label: countryObject?.currency?.code,
				value: countryObject?.currency?.code
			};

			const value = {
				label: countryObject?.country ?? "",
				value: key
			};

			setValue("currency", currencyObject);
			if (errors.currency) {
				clearErrors("currency");
			}
			onChange(value);
		},
		[getCountryById, errors.currency]
	);

	const onCountryInputChange = useCallback((value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : payrollCountry?.value
		};

		onChange(valueObject);
	}, []);

	const onCurrencyInputChange = useCallback((value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : currency?.value
		};

		onChange(valueObject);
	}, []);

	const onCurrencySelectionChange = useCallback((key, onChange) => {
		const value = {
			label: key ?? "",
			value: key || ""
		};

		onChange(value);
	}, []);

	return (
		<CreateVacancyCard id={VACANCY_STEP_CARDS_IDS.salary}>
			<CreateVacancyCardTitle>Salary</CreateVacancyCardTitle>
			<CreateVacancyCardContent className={styles.cardContent}>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Payroll country</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="payrollCountry"
							control={control}
							render={({ field }) => (
								<PayrollCountryController
									mainElement={mainElement}
									onCountryInputChange={onCountryInputChange}
									onCountrySelectionChange={onCountrySelectionChange}
									countriesItems={countriesItems}
									{...field}
								/>
							)}
						/>
					</div>
					<CreateVacancyFieldError error={errors.payrollCountry} />
				</CreateVacancyFieldContainer>
				<div className={styles.salaryContainer}>
					<CreateVacancyFieldContainer>
						<CreateVacancyLabelContainer>
							<CreateVacancyFieldLabel displayStar={false}>
								Between
							</CreateVacancyFieldLabel>
						</CreateVacancyLabelContainer>
						<div className={styles.inputContainer}>
							<Controller
								name="salaryMin"
								control={control}
								render={({ field: { onChange, ...rest } }) => (
									<SalaryMinController
										rootClassName={styles.salaryMinRoot}
										className={styles.salaryMin}
										{...rest}
										onChange={e => {
											onChange(e);
											if (isSubmitted) {
												triggerFormValidation("salaryMax");
												triggerFormValidation("currency");
											}
										}}
									/>
								)}
							/>
						</div>
						<CreateVacancyFieldError
							className={styles.error}
							error={errors.salaryMin}
						/>
					</CreateVacancyFieldContainer>
					<CreateVacancyFieldContainer>
						<CreateVacancyLabelContainer>
							<CreateVacancyFieldLabel displayStar={false}>
								And
							</CreateVacancyFieldLabel>
						</CreateVacancyLabelContainer>
						<Controller
							name="salaryMax"
							control={control}
							render={({ field: { ref, onChange, value } }) => (
								<SalaryMaxController
									onChange={e => {
										onChange(e);
										if (isSubmitted) {
											triggerFormValidation("salaryMin");
											triggerFormValidation("currency");
										}
									}}
									value={value}
									ref={ref}
									salaryMin={salaryMin}
									errors={errors}
									clearErrors={clearErrors}
								/>
							)}
						/>
						<CreateVacancyFieldError
							className={styles.error}
							error={errors.salaryMax}
						/>
					</CreateVacancyFieldContainer>
					<CreateVacancyFieldContainer>
						<CreateVacancyLabelContainer>
							<CreateVacancyFieldLabel displayStar={false}>
								Currency
							</CreateVacancyFieldLabel>
						</CreateVacancyLabelContainer>
						<Controller
							name="currency"
							control={control}
							render={({ field: { value, ref, onChange } }) => {
								return (
									<CurrencyController
										mainElement={mainElement}
										value={value}
										ref={ref}
										onChange={onChange}
										onCurrencyInputChange={onCurrencyInputChange}
										onCurrencySelectionChange={onCurrencySelectionChange}
										currenciesItems={currenciesItems}
									/>
								);
							}}
						/>
						<CreateVacancyFieldError
							className={styles.error}
							error={errors.currency}
						/>
					</CreateVacancyFieldContainer>
				</div>

				<div className={styles.paymentContainer}>
					<CreateVacancyFieldContainer>
						<CreateVacancyLabelContainer>
							<CreateVacancyFieldLabel displayStar={false}>
								Payments time
							</CreateVacancyFieldLabel>
						</CreateVacancyLabelContainer>
						<Controller
							name="paymentTime"
							control={control}
							render={({ field }) => (
								<AddVacancyCustomDropdown
									options={PAYMENT_TIME_TYPES}
									inputClassName={styles.durationSelectInput}
									className={styles.durationSelect}
									placeHolder={" "}
									{...field}
								/>
							)}
						/>
						<CreateVacancyFieldError error={errors.paymentTime} />
					</CreateVacancyFieldContainer>
					<CreateVacancyFieldContainer>
						<CreateVacancyLabelContainer>
							<CreateVacancyFieldLabel displayStar={false}>
								Type
							</CreateVacancyFieldLabel>
						</CreateVacancyLabelContainer>
						<Controller
							name="type"
							control={control}
							render={({ field }) => (
								<AddVacancyCustomDropdown
									options={SALARY_TYPES}
									inputClassName={styles.durationSelectInput}
									className={styles.durationSelect}
									placeHolder={" "}
									{...field}
								/>
							)}
						/>
						<CreateVacancyFieldError error={errors.type} />
					</CreateVacancyFieldContainer>
				</div>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Extra benefits
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="extraBenefits"
							control={control}
							render={({ field }) => (
								<ExtraBenefitsInput
									getExtraBenefitsList={
										workflowId ? () => [] : getExtraBenefitsList
									}
									{...field}
								/>
							)}
						/>
					</div>
					<CreateVacancyFieldError error={errors.extraBenefits} />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Other benefits
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="otherBenefits"
							control={control}
							render={({ field }) => (
								<AddVacancyCustomInput
									type="text"
									placeHolder="Company car, meal vouchers, insurance, phone, bonus, ..."
									{...field}
								/>
							)}
						/>
					</div>
					<CreateVacancyFieldError error={errors.otherBenefits} />
				</CreateVacancyFieldContainer>
			</CreateVacancyCardContent>
		</CreateVacancyCard>
	);
};

export default memo(AddVacancySalaryCard);

const CurrencyController = memo(
	forwardRef(
		(
			{
				mainElement,
				value,
				onChange,
				onCurrencyInputChange,
				onCurrencySelectionChange,
				currenciesItems
			},
			ref
		) => {
			return (
				<ComboBox
					popoverContainer={mainElement}
					ref={ref}
					inputValue={value?.label}
					selectedKey={value?.value}
					onSelectionChange={value =>
						onCurrencySelectionChange(value, onChange)
					}
					onInputChange={value => onCurrencyInputChange(value, onChange)}
				>
					{currenciesItems}
				</ComboBox>
			);
		}
	)
);

const CountryController = memo(
	forwardRef(
		(
			{
				mainElement,
				value,
				onChange,
				countriesItems,
				onCountryInputChange,
				onCountrySelectionChange
			},
			ref
		) => {
			return (
				<ComboBox
					popoverContainer={mainElement}
					ref={ref}
					inputClassName={styles.comboxInput}
					selectedKey={value?.value}
					inputValue={value?.label}
					onInputChange={value => onCountryInputChange(value, onChange)}
					onSelectionChange={value => onCountrySelectionChange(value, onChange)}
				>
					{countriesItems}
				</ComboBox>
			);
		}
	)
);

const PayrollCountryController = memo(
	forwardRef(
		(
			{
				mainElement,
				onCountryInputChange,
				onCountrySelectionChange,
				countriesItems,
				value,
				onChange
			},
			ref
		) => {
			("render PayrollCountryController");
			return (
				<CountryController
					mainElement={mainElement}
					onCountryInputChange={onCountryInputChange}
					onCountrySelectionChange={onCountrySelectionChange}
					countriesItems={countriesItems}
					value={value}
					onChange={onChange}
					ref={ref}
				/>
			);
		}
	)
);

const SalaryMinController = memo(
	forwardRef(({ value, onChange, ...props }, ref) => {
		return (
			<NewCurrencyField
				placeholder="0,00"
				ref={ref}
				input={{ value, onChange }}
				{...props}
			/>
		);
	})
);

const SalaryMaxController = memo(
	forwardRef(({ onChange, errors, salaryMin, clearErrors, value }, ref) => {
		return (
			<NewCurrencyField
				placeholder="0,00"
				rootClassName={styles.salaryMinRoot}
				className={styles.salaryMax}
				ref={ref}
				input={{
					value,
					onChange: value => {
						if (salaryMin && errors.salaryMin) {
							if (salaryMin < value) {
								clearErrors("salaryMin");
							}
						}
						onChange(value);
					}
				}}
			/>
		);
	})
);

const ExtraBenefitsInput = memo(
	forwardRef(({ getExtraBenefitsList, onChange, value }, ref) => {
		return (
			<Picker
				queryFn={getExtraBenefitsList}
				displayRating={false}
				PrimaryHeaderContent={() => "Extra benefits remained"}
				SecondaryHeaderContent={() => "Extra benefits selected"}
				useServerFilter={false}
				queryId={"extra_benfits_list"}
				onConfirm={onChange}
				onSkillDeleteFromInput={onChange}
				value={value}
				inputClassName={styles.pickerInput}
				ref={ref}
			/>
		);
	})
);
