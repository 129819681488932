import React, { useMemo } from "react";
import get from "lodash/get";
import { queryCache } from "react-query";
import { listCountKey } from "modules/home/api/useListCount.js";
import useSuppliersStore from "modules/home/zustand/SuppliersStore";
import useNewFreelancers from "modules/home/api/UseNewFreelancers";
import { Table } from "modules/home/components";
import SuppliersEmptyState from "modules/home/components/emptyState/SuppliersEmptyState";
import Loader from "common/Loader";
import style from "./styles.module.scss";
import { NEW_FREELANCER } from "modules/home/config";
import { historyPush } from "config/helpers";
import { ReactComponent as Plus } from "modules/home/svg/Plus.svg";
import { getFreelancersListRoute } from "modules/freelancers";
const NewFreelancer = ({ handleViewAll }) => {
	const {
		suppliersStartDate,
		suppliersEndDate,
		suppliersType,
		setItemSuppliers
	} = useSuppliersStore();
	const { data, isLoading } = useNewFreelancers(
		{
			start_date:
				suppliersStartDate && suppliersStartDate.isValid()
					? suppliersStartDate.unix()
					: undefined,
			end_date:
				suppliersEndDate && suppliersEndDate.isValid()
					? suppliersEndDate.unix()
					: undefined
		},
		{
			enabled: suppliersType === NEW_FREELANCER,
			onSuccess: res => {
				setItemSuppliers(NEW_FREELANCER, res.map(item => item._id) || []);
				queryCache.invalidateQueries(listCountKey);
			}
		}
	);

	const newFreelancerColumns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "full_name"
			},
			{
				Header: "Function",
				accessor: "function"
			}
		],
		[]
	);
	const clickOnRow = row => {
		historyPush(
			`/user/profile/${row?._id}?source=list&listKey=${NEW_FREELANCER}`
		);
	};
	const ClickOnES = () => {
		historyPush(getFreelancersListRoute());
	};
	if (isLoading)
		return (
			<div className={style["loader_container"]}>
				<Loader />{" "}
			</div>
		);
	if (!Boolean(get(data, "length")))
		return (
			<div className={style["loader_container"]}>
				{" "}
				<SuppliersEmptyState
					heading={"You have no freelancers added recently!"}
					onClick={ClickOnES}
					label={"Add new freelancer"}
					icon={<Plus />}
				/>
			</div>
		);

	return (
		<Table
			data={data}
			columns={newFreelancerColumns}
			onRowClick={clickOnRow}
			handleViewAll={handleViewAll}
		/>
	);
};

export default NewFreelancer;
