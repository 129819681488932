import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import CurrencyFormatter from "common/CurrencyFormater";
import { browserHistory } from "react-router";
import { formatDate } from "common/Functions";
import { EUR_SUFFIX } from "config";
import { getFreelancerBidViewRoute } from "modules/bids/components";
import { getConsultancyBidViewRoute } from "modules/bids/components/bidview/consultancy/ConsultancyBidViewContainer";

const BidItem = ({ bid, isConsultancy }) => {
	return (
		<li
			onClick={() =>
				browserHistory.push(
					isConsultancy
						? getConsultancyBidViewRoute(bid._id)
						: getFreelancerBidViewRoute(bid._id)
				)
			}
		>
			<>
				<div className="bid-infos">
					<h3 className="title">{_get(bid, "job.title")}</h3>
					<div className="rate">
						<CurrencyFormatter
							symbol={EUR_SUFFIX[_get(bid, "reporting_settings.rate_type", "")]}
							cent={bid.proposed_amount}
						/>
					</div>
				</div>
				<div className="mybid-details">
					<div className="date">{formatDate(bid.created_at)}</div>
				</div>
			</>
		</li>
	);
};

BidItem.propTypes = {
	bid: PropTypes.object.isRequired
};

export default BidItem;
