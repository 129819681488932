import React from "react";
import { Link, browserHistory } from "react-router";
import { PERMANENT, SIGN_UP_EMAIL_CONFIRMATION, FREELANCER } from "config";
import SuccessBot from "./SuccessBot";
import SuccessCreate from "./SuccessCreatePermanent";
import SubContainer from "../SubContainer";
import { fillHelpForm } from "modules/app/actions/ListsActions";
import cx from "classnames";
import useWindowSize from "hooks/useWindowSize";

const SignupSuccess = ({ name, email, resendActivateEmail, params }) => {
	const handleNeedHelp = () => {
		const fields = {
			name,
			email,
			type: SIGN_UP_EMAIL_CONFIRMATION
		};
		fillHelpForm(fields);
		browserHistory.push("/ask-question");
	};

	const handleResendEmail = () => {
		resendActivateEmail(email);
	};

	const isTalent = params?.type === FREELANCER || params?.type === PERMANENT;
	const successSignUpTalent = () => {
		if (params?.type === PERMANENT) {
			return (
				<SuccessCreate email={email} handleResendEmail={handleResendEmail} />
			);
		}
		if (params?.type === FREELANCER) {
			return <SuccessBot email={email} type={params.type} />;
		}
	};

	const isMobile = useWindowSize();

	return (
		<div>
			{isTalent ? (
				successSignUpTalent()
			) : (
				<SubContainer width={isMobile ? "auto" : 460}>
					<div className="success-content">
						<h3
							className={cx("heading", {
								["mobileHeading"]: isMobile
							})}
						>
							Please confirm your email address.
						</h3>
						<p
							className={cx("description", {
								["mobileDescription"]: isMobile
							})}
						>
							We have sent an email to: <span className="email">{email}</span>
						</p>
						<p
							className={cx("description", {
								["mobileDescription"]: isMobile
							})}
						>
							In order to progress with your account creation, please check your
							inbox and click on the provided link to confirm your email
							address.
						</p>
						<div
							className={cx("divider", {
								["mobileDivider"]: isMobile
							})}
						></div>
						<div className="instructions">
							<h4
								className={cx("heading", {
									["mobileDescription"]: isMobile
								})}
							>
								If you haven’t received the confirmation email, please try the
								following:
							</h4>
							<div
								className={cx("item", {
									["mobileDescription"]: isMobile
								})}
							>
								Check your junk mail folder and/or spam filter.
							</div>
							<div
								className={cx("item", {
									["mobileDescription"]: isMobile
								})}
							>
								Make sure the provided email is correct:
							</div>
							<div
								className={cx("item", {
									["mobileDescription"]: isMobile
								})}
							>
								<span className="email">{email}</span>
							</div>
							<Link to="/ask-question">
								<div
									className={cx("item", {
										["mobileDescription"]: isMobile
									})}
								>
									Contact our support team.
								</div>
							</Link>
							<button
								className={cx("item link", {
									["mobileDescription"]: isMobile
								})}
								onClick={handleNeedHelp}
							>
								I need help verifying my email.
							</button>
							{!!resendActivateEmail && (
								<button
									className={cx("item link", {
										["mobileDescription"]: isMobile
									})}
									onClick={handleResendEmail}
								>
									Resend the confirmation email.
								</button>
							)}
							<div style={{ height: isMobile ? 20 : 30 }}></div>
							<button
								className="form-btn"
								onClick={() => browserHistory.push("/")}
							>
								Return to homepage
							</button>
						</div>
					</div>
				</SubContainer>
			)}
		</div>
	);
};

export default SignupSuccess;
