import React from "react";
import { ReactComponent as Vector } from "static/vectors/evaluations-list-empty-state.svg";
import styles from "./evaluations-list-empty-block.module.scss";

export default function EvaluationsListEmptyBlock() {
	return (
		<div className={styles.container}>
			<div className={styles.emptyBlock}>
				<Vector />
				<h3 className={styles.title}>No results found</h3>
				<p className={styles.description}>
					We couldn&apos;t find any matches for your search. <br />
					Please try again with different keywords
				</p>
			</div>
		</div>
	);
}
