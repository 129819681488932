import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import Table from "common/AgTable/AgTable";
import TableMenu from "common/AgTable/components/MenuColumn";
import SelectColumn from "common/AgTable/components/SelectColumn";
import RenderCells from "./RenderCells/RenderCells";
import moment from "moment";
import { ActionsHeader } from "common/AgTable/components/ActionsHeader";
import HidePermanentlyModal from "./HidePermanentlyModal/HidePermanentlyModal";
import DeleteModal from "./DeleteModal/DeleteModal";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import capitalize from "lodash/capitalize";
import LoadingStateCell from "common/AgTable/components/RenderCellsGlobal/LoadingStateCell/LoadingStateCell";
import { columnFiltersMap, columnSortMap } from "./utils/utils";
import { CANDIDATES_FILTER } from "modules/SearchCandidate/utils/constants";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import { CANDIDATE_POOL } from "config";
import { browserHistory } from "react-router";
import EmptyData from "common/SmartFilterDrawer/components/SavedFiltersTab/components/EmptyData/EmptyData";
import { ReactComponent as EmptyStateIcon } from "static/icons/empty-state-potential-matches.svg";
import { ReactComponent as StarIcon } from "static/icons/star-04.svg";
import { ReactComponent as EditIcon } from "static/icons/edit-filter.svg";
import styles from "./table-container.module.scss";
import EmptySearch from "common/AgTable/components/EmptyState/EmptyState";
import get from "lodash/get";
import { formatAllFilters } from "common/SmartFilterDrawer/utils/format_utils";
import { reverseSavedFiltersSmartQueryParser } from "common/SmartFilterDrawer/utils/helpers";
import { historyPush } from "config/helpers";
import { getProfileSearchRoute } from "modules/SearchCandidate/ViewSwitcher";

const typeTag = "search_candidates_table";

const checkFilterColumns = [
	"first_name",
	"last_name",
	"email_address",
	"phone_number",
	"_id"
];
const disabledClickCell = [
	"email_address",
	"phone_number",
	"extra_benefits",
	"other_extra_benefits",
	"skills",
	"languages",
	"profile_tags",
	"_selects",
	"preferred_locations",
	"last_note"
];

const disabledClickCellLoading = "LOADING";

const TableContainer = ({
	columns,
	data,
	checkCandidateSelection,
	offset,
	onCheckboxChange: _onCheckboxChange,
	isSelectAll,
	onSelectAllChange,
	selectionState,
	refetch
}) => {
	const {
		getState,
		setSmartQuery,
		clearFilters,
		clearSmartQuery,
		setShowDrawer,
		setSelectedCriteria
	} = useFilters();
	const { isLoading, isFetching, columns: columnsFilter } = getState(
		CANDIDATES_FILTER
	);
	const [rowsData, setRowData] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedCandidate, setSelectedCandidate] = useState();

	const potentialMatches = get(
		data,
		"potential_matches.smart_query_filter",
		null
	);
	const potentialMatchesTotal = get(data, "potential_matches.total", 0);
	const goToProfile = params => {
		const id = params.data.profileId;
		browserHistory.push(
			getPermanentProfileRoute(`${id}?list=${CANDIDATE_POOL}&source=list`),
			true
		);
	};

	const handleShowModal = value => {
		setShowModal(value);
	};
	const handleSetSelectedCandidate = value => {
		setSelectedCandidate({
			id: value.profileId,
			name: value.name,
			type: value.type
		});
		setShowModal(true);
	};

	const gridRef = useRef();
	const filterColumns = useMemo(() => {
		const columnsSettings = columns?.map(column => {
			return {
				field: column.headerName,
				width: column.width,
				minWidth: `${column.colId === "last_note" ? 160 : 80}`,
				colId: column.colId,
				hide: column.hide,
				pinned: column.pinned ?? null,
				filter: TableMenu,
				cellRenderer: isLoading || isFetching ? LoadingStateCell : RenderCells,
				cellClass: "locked-visible",
				lockVisible: true,
				refGrid: gridRef,
				typeTag: typeTag,
				columnSortMap: columnSortMap,
				columnFiltersMap: columnFiltersMap,
				currentFilterId: CANDIDATES_FILTER,
				checkFilterColumns: checkFilterColumns,
				isSortable: column.sortable,
				headerName: capitalize(column.headerName)
			};
		});
		return [
			{
				colId: "_selects",
				field: "isSelected",
				width: 103.25,
				resizable: false,
				hide: false,
				filter: false,
				pinned: "left",
				lockVisible: true,
				lockPinned: true,
				lockPosition: "left",
				cellClass: "locked-col lock-pinned",
				cellRenderer: isLoading || isFetching ? LoadingStateCell : SelectColumn,
				headerComponent: ActionsHeader,
				headerComponentParams: {
					checked: isSelectAll,
					onSelectChange: onSelectAllChange
				},
				cellRendererParams: {
					handleSetSelectedCandidate: handleSetSelectedCandidate
				}
			},
			...(columnsSettings ?? [])
		];
	}, [
		columns,
		isSelectAll,
		onSelectAllChange,
		selectionState,
		isFetching,
		isLoading
	]);
	// Filtered the data based on what we need in ROW and rename the valueName
	const filterData = data => {
		let newData = [];
		data?.map((item, i) => {
			const checked = checkCandidateSelection(i, item);
			const rowIndex = i + offset;
			const obj = {
				Id: item.reference,
				profileId: item.id,
				"First name": item.first_name,
				"Last name": item.last_name,
				"Email address": item.is_internal ? item.emails : item.email,
				"Phone number": item.is_internal ? item.phones : item.phone,
				"External profile": item.external_links,
				"Employment type": item.employment_types,
				Residence: item.main_location,
				"Preferred locations": item.preferred_location,
				"Expected salary": (item.expected_salary / 100)
					?.toLocaleString()
					.replace(",", " "),
				"Salary type": item.payment_time_expected,
				Currency: item.symbol_expected,
				Country: item.payroll_country,
				Salary: item.payment_time_expected?.toLocaleString().replace(",", " "),
				"Gross/Net": item.payment_type_expected,
				"Notice period": item.notice_period,
				"Extra benefits": item.extra_benefits,
				"Other extra benefits": item.other_extra_benefits,
				Category: item.categories,
				"Sub category": item.subcategories,
				Functions: [item.main_function, ...item.functions],
				Seniority: item.seniority,
				Skills: item.skills,
				Languages: item.languages,
				"Profile tags": item.profile_tags,
				can_delete: item.can_delete,
				can_resend_activation_email: item.can_resend_activation_email,
				Processes: "NA",
				Interactions: "NA",
				Notes: item.note ? "Yes" : "No",
				Source: item.source,
				Viewed: item.is_viewed_by_me ? "Yes" : "No",
				Activated: item.is_active ? "Yes" : "No",
				Interviewed: item.interviewed ? "Yes" : "No",
				Referred: item.is_referred ? "Yes" : "No",
				Postcode: item?.main_location?.zip,
				"Profile created by":
					item?.created_by?.last_name || item?.created_by?.first_name
						? `${item.created_by.last_name} ${item.created_by.first_name}`
						: "",
				"Profile created on": moment(item.registration_date * 1000).format(
					"DD/MM/YYYY"
				),
				"Last note": item?.note?.description ?? "",
				Followed: item.is_followed ? "Yes" : "No",
				isSelected: {
					onCheckboxChange: e => {
						_onCheckboxChange(e, item.id, rowIndex, checked);
					},
					selected: checked
				}
			};
			return newData.push(obj);
		});
		setRowData(newData);
	};
	const loadingFakeData = Array(12).fill({});

	useEffect(() => {
		filterData(data?.data);
	}, [data, offset]);

	useEffect(() => {
		if (gridRef.current.api) {
			gridRef.current.api.forEachNode(function(node, i) {
				const value = checkCandidateSelection(i, {
					...node.data,
					id: node.data.profileId
				});
				const rowIndex = i + offset;

				node.setDataValue("_selects", {
					selected: value,
					onCheckboxChange: e =>
						_onCheckboxChange(e, node.data.profileId, rowIndex, value)
				});
			});
		}

		gridRef.current.potentialMatches = potentialMatches;
		gridRef.current.potentialMatchesTotal = potentialMatchesTotal;
	}, [
		potentialMatches,
		checkCandidateSelection,
		selectionState,
		_onCheckboxChange,
		data,
		gridRef.current?.api,
		offset
	]);

	const onOptimizeFilter = () => {
		const job_id = get(
			browserHistory.getCurrentLocation(),
			"query.vacancy-id",
			""
		);
		if (job_id) historyPush(getProfileSearchRoute());

		// closure probleme
		// function always memoize the first value event if potential matches has changed after
		const potentialMatchesRef = gridRef.current.potentialMatches;
		if (potentialMatchesRef) {
			const { smartQuerySchema } = reverseSavedFiltersSmartQueryParser(
				potentialMatchesRef
			);
			formatAllFilters(columnsFilter, smartQuerySchema).then(results => {
				setSmartQuery(results);
				setSelectedCriteria({});
			});
		}
	};

	const onClear = () => {
		clearFilters();
		clearSmartQuery();
	};

	return (
		<>
			<Table
				rows={isLoading || isFetching ? loadingFakeData : rowsData}
				columnsSettings={filterColumns}
				ref={gridRef}
				typeTag={typeTag}
				disabledClickCell={
					isLoading || isFetching ? disabledClickCellLoading : disabledClickCell
				}
				onCellClicked={goToProfile}
				noRowsOverlayComponent={() =>
					gridRef.current.potentialMatches ? (
						<EmptyData
							icon={<EmptyStateIcon />}
							title={"No Matches Found"}
							description={
								<span>
									You&apos;ve got{" "}
									<span className={styles.potentialMatchesTotal}>
										{gridRef.current.potentialMatchesTotal}
									</span>{" "}
									candidates who match your job, but your filter&apos;s too
									complex. Let&apos;s simplify it and uncover even more
									potential candidates
								</span>
							}
						>
							<div className={styles.actionsContainer}>
								<button className={styles.optimize} onClick={onOptimizeFilter}>
									<StarIcon className={styles.iconMagic} />
									Simplify filter
								</button>
								<button
									className={styles.edit}
									onClick={() => setShowDrawer(true)}
								>
									<EditIcon className={styles.iconEdit} />
								</button>
							</div>
						</EmptyData>
					) : (
						<EmptySearch
							text={"No candidate matching your search criteria."}
							withAction
							onClear={onClear}
						/>
					)
				}
			/>
			{selectedCandidate?.type === "HIDE" && showModal && (
				<HidePermanentlyModal
					maxwidth={"400px"}
					padding={"24px"}
					candidate={selectedCandidate}
					onClose={() => handleShowModal(false)}
					refetch={refetch}
				/>
			)}

			{selectedCandidate?.type === "DELETE" && showModal && (
				<DeleteModal
					maxwidth={"400px"}
					padding={"24px"}
					candidate={selectedCandidate}
					onClose={() => handleShowModal(false)}
					refetch={refetch}
				/>
			)}
		</>
	);
};

export default memo(TableContainer);
