import { useDateSegment } from "react-aria";
import cx from "classnames";
import React, { useRef } from "react";
import styles from "./date-segment.module.scss";

const DateSegment = ({ segment, state }) => {
	let ref = useRef();
	let { segmentProps } = useDateSegment(segment, state, ref);

	return (
		<div {...segmentProps} ref={ref} className={styles.segment}>
			<span
				className={cx({
					[styles.separator]: segment.text === "/",
					[styles.label]: segment.text !== "/",
					[styles.text]: !segment.isPlaceholder
				})}
			>
				{!isNaN(segment.text) ? segment.text.padStart(2, "0") : segment.text}
			</span>
		</div>
	);
};

export default DateSegment;
