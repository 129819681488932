import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { HTTP_UPDATE_COMPANY_ADDITIONAL_INFO } from "config/api-endpoints";

function updateCompanyAdditionalInfo(payload) {
	return client(HTTP_UPDATE_COMPANY_ADDITIONAL_INFO, {
		body: payload
	});
}

function useUpdateCompanyAdditionalInfo(options) {
	return useMutation(updateCompanyAdditionalInfo, options);
}

export default useUpdateCompanyAdditionalInfo;
