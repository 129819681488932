import React, { useRef } from "react";
import styles from "./messaging-tool-search-dropdown.module.scss";
import Avatar from "common/Avatar";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";
import useOnClickOutside from "hooks/useOnClickOutside";

const MessaginToolSearchDropdown = ({
	data,
	isLoading,
	className,
	onSelect,
	setActiveInput
}) => {
	const ref = useRef();

	useOnClickOutside(ref, () => {
		setActiveInput("");
	});

	return (
		<div ref={ref} className={cx(styles.dropdown, className)}>
			{isLoading && <MessaginToolSearchDropdownLoading />}
			{data?.length === 0 && <span>This user doesn&apos;t exist</span>}
			{data?.length > 0 && (
				<>
					{data?.map(user => (
						<div
							className={styles.listItem}
							key={user._id}
							onClick={e => {
								e.preventDefault();
								onSelect(user);
							}}
							role="button"
						>
							<Avatar
								size={32}
								name={`${user.full_name}`}
								avatar={user.avatar}
							/>
							<div className={styles.userInfo}>
								<span className={styles.username}>{`${user.full_name}`}</span>
								<span className={styles.email}>{user.email}</span>
							</div>
						</div>
					))}
				</>
			)}
		</div>
	);
};

const MessaginToolSearchDropdownLoading = () => {
	return Array(5)
		.fill(0)
		.map(item => (
			<div className={styles.listItem} key={item}>
				<Skeleton circle width={38} height={38} />
				<div className={styles.userInfo}>
					<Skeleton width={82} height={11} />
					<Skeleton width={113} height={11} />
				</div>
			</div>
		));
};
MessaginToolSearchDropdown.displayName = "MessaginToolSearchDropdown";

export default MessaginToolSearchDropdown;
