/* eslint-disable react/display-name */
import React, { useRef, useEffect, useMemo, useCallback, memo } from "react";
import { Item as TabItem } from "@react-spectrum/tabs";
import useGetLanguagesList from "api/useGetLanguagesList";
import cx from "classnames";
import { Button } from "common/Button";
import { ComboBox } from "common/ComboBox";
import { Tabs } from "common/Tabs";
import { generateId } from "config/helpers";
import { VACANCY_STEP_CARDS_IDS } from "modules/vacancy/create-vacancy/utils/constants";
import { useState } from "react";
import { Item } from "react-stately";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import { ReactComponent as EmptyStarGreen } from "static/icons/empty-star-green.svg";
import { AddVacancyDescriptionForm } from "../AddVacancyDescriptionForm";
import Card from "../CreateVacancyCard/CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyCardTitle } from "../CreateVacancyCardTitle";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import styles from "./add-vacancy-description-card.module.scss";
import useElements from "hooks/useLayoutElements";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const AddVacancyDescriptionCard = ({
	onLanguageChange,
	descriptionError,
	setValue,
	clearErrors,
	descriptionList = [],
	append,
	remove,
	usedLanguages,
	triggerFormValidation,
	isFormSubmitted,
	vacancy,
	vacancyId
}) => {
	const { mainElement } = useElements();
	const [chosenLanguage, setChosenLanguage] = useState(null);
	const [selectedTabs, setSelectedTabs] = useState();
	const languageInput = useRef();
	const jobTitleInput = useRef();
	const titleFocus = useRef(false);
	const descriptionFocus = useRef(false);
	const profileDescriptionFocus = useRef(false);

	const jobDescriptionInput = useRef();
	const profileDescriptionInput = useRef();

	const [primaryTabKey, setPrimaryTabKey] = useState("");

	const { workflowId } = useOnboardingStore();
	const { data: languagesList } = useGetLanguagesList({ enabled: !workflowId });

	useEffect(() => {
		if (descriptionError?.message) {
			languageInput.current.focus();
		} else if (Array.isArray(descriptionError)) {
			for (const i in descriptionError) {
				const {
					jobTitle,
					jobDescription,
					profileDescription
				} = descriptionError[i];
				const versionId = descriptionList[i].generatedId;
				const isActif = selectedTabs === versionId;

				if (isActif) {
					if (jobTitle) {
						jobTitleInput.current.focus();
					} else if (jobDescription) {
						jobDescriptionInput.current.focus();
						jobDescriptionInput.current.scrollIntoView();
					} else if (profileDescription) {
						profileDescriptionInput.current.focus();
						profileDescriptionInput.current.scrollIntoView();
					}
				} else {
					if (jobTitle) {
						titleFocus.current = true;
					} else if (jobDescription) {
						descriptionFocus.current = true;
					} else if (profileDescription) {
						profileDescriptionFocus.current = true;
					}
					setSelectedTabs(versionId);
				}
				break;
			}
		}
	}, [descriptionError]);

	const onJobTitleFocus = () => {
		titleFocus.current = false;
	};

	const onDescriptionFocus = () => {
		descriptionFocus.current = false;
	};

	const onProfileDescriptionFocus = () => {
		profileDescriptionFocus.current = false;
	};

	const onLanguageSelectionChange = useCallback(
		key => {
			const _object = languagesList.find(({ _id }) => _id === key);

			const value = {
				label: _object?.language ?? "",
				value: key
			};

			setChosenLanguage(value);
		},
		[languagesList]
	);

	const onLanguageInputChange = useCallback(value => {
		const valueObject = {
			label: value,
			value: value === "" ? null : chosenLanguage?.value
		};
		setChosenLanguage(valueObject);
	}, []);

	const onAdd = () => {
		const language = languagesList.find(
			({ _id }) => _id === chosenLanguage.value
		);

		const item = {
			generatedId: generateId(),
			jobTitle: "",
			jobDescription: "",
			profileDescription: "",
			primary: descriptionList.length === 0,
			languageObject: language,
			isDescriptionGenerated: false
		};

		append(item);
		setChosenLanguage(null);
		clearErrors("description");
		setSelectedTabs(item.generatedId);
	};

	const onDelete = index => {
		remove(index);
	};

	const onSetPrimaryClick = index => {
		descriptionList.forEach((_, i) => {
			const isPrimary = i === index;

			setValue(`description.${i}.primary`, isPrimary);
		});

		setPrimaryTabKey(`${index}${true}`);
		setSelectedTabs(descriptionList[index].generatedId);
	};

	const tabsList = descriptionList.map(
		({ primary, languageObject, generatedId }) => {
			return {
				id: generatedId,
				header: (
					<div className={styles.tabsTitleContainer}>
						{primary && <EmptyStarGreen className={styles.starIcon} />}
						<div>{languageObject?.language}</div>
					</div>
				)
			};
		}
	);

	const onTabChange = key => {
		setSelectedTabs(key);
	};

	const languagesItems = useMemo(() => {
		const unusedLanguages = languagesList?.filter(
			({ language }) => !usedLanguages.includes(language)
		);

		const options = unusedLanguages?.map(({ _id, language }) => (
			<Item key={_id}>{language}</Item>
		));

		return options;
	}, [languagesList, JSON.stringify(usedLanguages)]);

	const isAddButtonDisabled = !chosenLanguage?.value;

	return (
		<Card id={VACANCY_STEP_CARDS_IDS.description}>
			<CreateVacancyCardTitle>Description</CreateVacancyCardTitle>
			<CreateVacancyCardContent className={styles.cardContent}>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Version</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<ComboBox
							popoverContainer={mainElement}
							value={chosenLanguage}
							placeholder="Select a language..."
							selectedOption={null}
							inputClassName={styles.comboxBoxInput}
							buttonClassName={styles.comboxBoxButton}
							inputValue={chosenLanguage?.label}
							Tabs={chosenLanguage?.value}
							onSelectionChange={onLanguageSelectionChange}
							onInputChange={onLanguageInputChange}
							ref={languageInput}
						>
							{languagesItems}
						</ComboBox>
						<Button
							onClick={onAdd}
							className={styles.addButton}
							disabled={isAddButtonDisabled}
							text="Add"
							icon={
								<AddIcon
									className={cx(styles.addIcon, {
										[styles.addIconDisabled]: isAddButtonDisabled
									})}
								/>
							}
						/>
					</div>
					<CreateVacancyFieldError
						error={descriptionError?.length ? null : descriptionError}
					/>
				</CreateVacancyFieldContainer>
				{descriptionList.length > 0 && (
					<>
						<Tabs
							forceRender={primaryTabKey}
							rootClassName={styles.tabsRoot}
							headerClassName={styles.tabsHeader}
							items={tabsList}
							selectedKey={selectedTabs}
							onTabChange={onTabChange}
							tabsPanelClassName={styles.tabPanels}
						>
							{descriptionList.map(
								({ primary, generatedId, languageObject }, index) => {
									return (
										<TabItem key={generatedId}>
											<AddVacancyDescriptionForm
												onDelete={() => onDelete(index)}
												onSetPrimaryClick={() => onSetPrimaryClick(index)}
												isPrimary={primary}
												fieldName={`description.${index}`}
												setValue={setValue}
												onLanguageChange={onLanguageChange}
												index={index}
												ref={[
													jobTitleInput,
													jobDescriptionInput,
													profileDescriptionInput
												]}
												titleFocus={titleFocus.current}
												onJobTitleFocus={onJobTitleFocus}
												descriptionFocus={descriptionFocus.current}
												onDescriptionFocus={onDescriptionFocus}
												profileDescriptionFocus={
													profileDescriptionFocus.current
												}
												onProfileDescriptionFocus={onProfileDescriptionFocus}
												isFormSubmitted={isFormSubmitted}
												triggerFormValidation={triggerFormValidation}
												vacancy={vacancy}
												languageId={descriptionList[index].generatedId}
												vacancyId={vacancyId || vacancy?._id}
												language={languageObject.language}
											/>
										</TabItem>
									);
								}
							)}
						</Tabs>
					</>
				)}
			</CreateVacancyCardContent>
		</Card>
	);
};
export default memo(AddVacancyDescriptionCard);
