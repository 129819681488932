import React from "react";
import folder from "static/images/folder.jpg";
import { EmptySearchContainer } from "modules/vacancy/components/newSearch/Styled";

const EmptySearch = () => (
	<EmptySearchContainer padding={"50px"}>
		<img alt={"No results found"} src={folder} />
		<div className="title">No document requirement found</div>
	</EmptySearchContainer>
);
export default EmptySearch;
