import React, { useState, useEffect } from "react";
import { Flex, Box, Text, Button } from "rebass";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import { browserHistory } from "react-router";

import _get from "lodash/get";
import { useQuery, useMutation } from "react-query";
import Description from "common/Description";
import JobFunctions from "common/JobFunctions";
import ListItem from "common/ListItem";
import {
	MAX_LANGUAGE_WEIGHT,
	MAX_SKILL_WEIGHT,
	SKILLS,
	LANGUAGES,
	VACANCY,
	LOADING,
	EDUCATION_LEVELS,
	PUBLISHED,
	USER_SETTINGS_OPTIONS,
	JOB_SEARCH_SIZE,
	APPLY_TO_VACCANCY_MODAL
} from "config";

import { client } from "lib/api-client";
import {
	PREVIEW_JOB,
	APPLY_TO_VACANCY_ENDPOINT,
	UPDATE_USER_SETTING,
	GUEST_PREVIEW_JOB
} from "config/api-endpoints";
import Sidebar from "./Sidebar";
import { JobHeader, Navigation, Container } from "./Styled";
import "rc-drawer/assets/index.css";
import toaster from "common/Toaster";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { LoaderFull } from "common/Loader";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { ReactComponent } from "../icons/empty-logo-company.svg";
import Loader from "common/Loader";
import { formatDate } from "common/Functions";
import OfflineApplicationModal from "./OfflineApplicationModal";
import ConfirmOfflineApplicationModal from "./ConfirmOfflineApplicationModal";
import SignInModal from "./SignInModal";
import EmailAlreadyUsedModal from "./EmailAlreadyUsedModal";
import { searchParser } from "config/helpers";
import { getJobExpiredRoute } from "common/JobExpired";

import { ReactComponent as IdIcon } from "common/Icons/IdIcon.svg";

const getJobData = (key, id, isOnline, version) =>
	client(isOnline ? PREVIEW_JOB : GUEST_PREVIEW_JOB, {
		body: { id, version }
	});

const applyToJob = data => client(APPLY_TO_VACANCY_ENDPOINT, { body: data });

const trackDoNotShowAgain = () =>
	client(UPDATE_USER_SETTING, {
		body: {
			type: USER_SETTINGS_OPTIONS,
			user_options: { [APPLY_TO_VACCANCY_MODAL]: true }
		}
	});

const JobView = ({
	user,
	job,
	jobsData,
	offset,
	updateOffset,
	setSelectedJob,
	templateId,
	isOnline,
	isDirectAccess,
	vacancyId
}) => {
	const [checked, setChecked] = useState(false);
	const [tab, setTab] = useState(false);
	const [activeTab, setActiveTab] = useState("first");
	const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
		false
	);
	const [displayWarningModal, setDisplayWarningModal] = useState(false);
	const [
		displayOfflineApplicationModal,
		setDisplayOfflineApplicationModal
	] = useState(false);
	const [
		displayConfirmApplicationModal,
		setDisplayConfirmApplicationModal
	] = useState(false);
	const [newAccountEmail, setNewAccountEmail] = useState("");
	const [newAccountType, setNewAccountType] = useState("");
	const [errorForm, setErrorForm] = useState(false);
	const [displaySignInModal, setDisplaySignInModal] = useState(false);
	const [displayEmailUsedModal, setDisplayEmailUsedModal] = useState(false);
	const [vonqData, setVonqData] = useState({});

	const jobIds = _get(jobsData, "data", []).map(j => j.id);

	const [jobIndex, setJobIndex] = useState(job.index);

	useEffect(() => {
		if (isDirectAccess) {
			const search = searchParser();
			if (_get(search, "vq_source") && _get(search, "vq_campaign")) {
				setVonqData({
					source_id: search.vq_source,
					campaign_id: search.vq_campaign
				});
			}
		}
	}, []);

	useEffect(() => {
		jobIds[jobIndex] && loadNewJob(jobIndex);
	}, [jobsData]);

	useEffect(() => {
		setJobIndex(job.index);
	}, [job]);

	let vacId = jobIds[jobIndex];

	if (isDirectAccess) {
		vacId = vacancyId;
	}

	const query = useQuery(
		["vacancy", vacId, isOnline, _get(job, "version")],
		getJobData,
		{
			enabled: vacId
		},
		{
			onError: () => {
				if (vacId) {
					toaster.danger("404: not found.");
					browserHistory.push("/404");
				}
			}
		}
	);

	const data = query.data;
	const isLoadingJob = query.status === LOADING;
	const isFetching = query.isFetching;

	const [mutate, { status: applyStatus }] = useMutation(applyToJob, {
		onSuccess: () => {
			toaster.success("Your application has successfully been sent!");
			setDisplayConfirmationModal(false);
			query.refetch();
		},
		onError: e => {
			if (e && e.status === 422) {
				let errorMsg = _get(e, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			}
			query.refetch();
		}
	});

	const [doNotShowAgain] = useMutation(trackDoNotShowAgain);

	const applyWithoutModal = user.apply_to_vacancy_modal;
	const sendJobApplication = () => {
		mutate({
			vacancy_id: vacId,
			version: _get(job, "version"),
			source_id: _get(vonqData, "source_id"),
			campaign_id: _get(vonqData, "campaign_id")
		});
		if (checked) {
			doNotShowAgain();
		}
	};

	const handleApplyToJob = () => {
		if (applyWithoutModal) {
			return sendJobApplication();
		}
		return setDisplayConfirmationModal(true);
	};

	const loadNewJob = () => {
		const newJob = _get(jobsData, "data", []).find((j, i) => i === jobIndex);
		if (newJob) {
			setSelectedJob({ ...newJob, index: jobIndex });
		}
		query.refetch();
	};

	const fetchNewJobs = () => {
		let newOffset = offset;
		if (jobIndex === JOB_SEARCH_SIZE - 1) {
			setJobIndex(0);
			newOffset += JOB_SEARCH_SIZE;
		} else if (jobIndex === 0) {
			setJobIndex(JOB_SEARCH_SIZE - 1);
			newOffset -= JOB_SEARCH_SIZE;
		}
		updateOffset(newOffset);
	};

	useEffect(() => {
		if (
			_get(query, "data.status") &&
			_get(query, "data.status") !== PUBLISHED
		) {
			return browserHistory.push(getJobExpiredRoute());
		}
	}, [_get(query, "data")]);

	return (
		<>
			<LoaderFull loading={isLoadingJob} />
			<BodyClassName className="job-view gray-bg">
				<Box
					mt={60}
					sx={{
						maxWidth: "1382px",
						width: "100%",
						margin: "0 auto"
					}}
				>
					<Navigation>
						{isDirectAccess && (
							<>
								<div
									onClick={() => browserHistory.push("/vacancies")}
									className="back-to-list"
								>
									<div className="icon-container">
										<span className="icon-arrow-right1" />
									</div>
									Back to list
								</div>
								{!isLoadingJob && isFetching && <Loader />}
								{/* <div className="actions-container">
									<button
										className={`action ${isFirstJob ? "disabled" : ""}`}
										onClick={() => handlePreviousClick()}
									>
										<span className="arrow" style={{ color: "#a1a5aa", fontSize: "15px" }}>{"<"}</span> Previous
											</button>
									<button
										className={`action ${isLastJob ? "disabled" : ""}`}
										onClick={() => handleNextClick()}
									>
										Next<span className="arrow" style={{ color: "#a1a5aa", fontSize: "15px" }}> {">"}</span>
									</button>
								</div> */}
							</>
						)}
					</Navigation>
					<Box
						sx={{
							padding: "33px 0 40px",
							borderRadius: "4px",
							boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
							backgroundColor: "rgb(255, 255, 255)"
						}}
					>
						<Flex
							alignItems="center"
							sx={{
								alignItems: "center",
								justifyContent: "center",
								paddingBottom: "16px",
								borderBottom: "1px solid #f1f2f5"
							}}
						>
							<JobHeader>
								{_get(data, "company.avatar") ? (
									<>
										<div className="logo">
											<img src={_get(data, "company.avatar")} />
										</div>
									</>
								) : (
									<div className="logo-placeholder">
										<ReactComponent />
									</div>
								)}
							</JobHeader>
						</Flex>
						<Flex
							alignItems="center"
							justifyContent="center"
							flexDirection="column"
							pt={40}
						>
							<Text
								as={"h1"}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "25px",
									fontWeight: "600",
									lineHeight: "1.28",
									letterSpacing: "0.15px",
									textAlign: "center",
									color: "rgb(37, 55, 88)"
								}}
							>
								{_get(data, "title")}
							</Text>
							<Text as={"h2"} sx={{ fontSize: "14px" }} mt={10}>
								<Text
									as={"span"}
									mr={1}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "16px",
										lineHeight: "1.06",
										color: "#1b9f94"
									}}
								>
									{_get(data, "group.name")}
								</Text>
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "16px",
										lineHeight: "1.06",
										color: "#a0a5b9"
									}}
									as={"span"}
								>
									<Text
										as={"span"}
										sx={{
											textTransform: "capitalize",
											display: "inline-block"
										}}
									>
										{_get(data, "employment_type")}
									</Text>{" "}
									· {_get(data, "city")} · Published on{" "}
									{formatDate(_get(data, "publish_date"))}{" "}
								</Text>
							</Text>
						</Flex>
					</Box>
					{tab ? (
						<Box
							mt={"6px"}
							sx={{
								padding: "26.5px 31.2px 26.5px 66.5px",
								borderRadius: "4px",
								boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
								backgroundColor: "rgb(255, 255, 255)"
							}}
						>
							<Flex
								justifyContent="space-between"
								alignItems="center"
								sx={{
									width: "774px",
									margin: "0 auto",
									maxWidth: "100%",
									position: "relative",
									right: "28px"
								}}
							>
								<Flex>
									<Flex
										alignItems="center"
										justifyContent="center"
										mr={24}
										sx={{
											width: "60px",
											height: "60px",
											backgroundColor: "rgb(97, 105, 118)",
											borderRadius: "60px"
										}}
									>
										<IdIcon style={{ width: "32px" }} />
									</Flex>
									<Box>
										<Text
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "21px",
												fontWeight: "500",
												color: "rgb(37, 55, 88)"
											}}
										>
											Already a Wiggli member ?
										</Text>
										<Text
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "16px",
												lineHeight: "1.78",
												letterSpacing: "0.08px",
												color: "rgb(39, 50, 56)"
											}}
										>
											Log in now to complete your application!
										</Text>
									</Box>
								</Flex>
								<Box>
									<Button
										onClick={() => {
											setNewAccountEmail("");

											setDisplaySignInModal(true);
										}}
										sx={{
											padding: "12px 52px",
											border: "solid 1px rgb(119, 134, 153)",
											backgroundColor: "rgb(97, 105, 118)",
											fontFamily: "BasierCircle",
											fontSize: "15px",
											fontWeight: "500",
											color: "rgb(255, 255, 255)",
											outline: "none"
										}}
									>
										Log in & apply here
									</Button>
								</Box>
							</Flex>
						</Box>
					) : null}

					<Box
						mt={"6px"}
						sx={{
							padding: "26.5px 77.5px 53px 56.5px",
							borderRadius: "4px",
							boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
							backgroundColor: "rgb(255, 255, 255)"
						}}
					>
						<Flex alignItems="center" justifyContent="center" mb={90}>
							<Text
								onClick={() => {
									setTab(!tab);
									setActiveTab("first");
								}}
								mr={3}
								className={activeTab === "first" ? "active" : null}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "15px",
									fontWeight: "500",
									lineHeight: "1.23",
									letterSpacing: "normal",
									color: "rgb(149, 161, 179)",
									paddingBottom: "6px",
									cursor: "pointer",
									"&.active": {
										color: "#253758",
										borderBottom: "2px solid #1b9f94"
									}
								}}
							>
								Job description
							</Text>
							<Text
								onClick={() => {
									setTab(!tab);
									setActiveTab("second");
								}}
								className={activeTab === "second" ? "active" : null}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "15px",
									fontWeight: "500",
									lineHeight: "1.23",
									letterSpacing: "normal",
									color: "rgb(149, 161, 179)",
									paddingBottom: "6px",
									cursor: "pointer",
									"&.active": {
										color: "#253758",
										borderBottom: "2px solid #1b9f94"
									}
								}}
							>
								Application form
							</Text>
						</Flex>
						{tab ? (
							<>
								<OfflineApplicationModal
									onClose={() => setDisplayOfflineApplicationModal(false)}
									vacancy_id={vacId}
									companyName={_get(data, "company.name")}
									vacancyTitle={_get(data, "title")}
									city={_get(data, "company.city")}
									setNewAccountEmail={setNewAccountEmail}
									displayConfirmApplicationModal={() =>
										setDisplayConfirmApplicationModal(true)
									}
									displaySignInModal={() => setDisplaySignInModal(true)}
									setNewAccountType={setNewAccountType}
									displayEmailUsedModal={() => setDisplayEmailUsedModal(true)}
									loginAndApply={() => {
										setNewAccountEmail("");

										setDisplaySignInModal(true);
									}}
									errorEmail={() => setErrorForm(true)}
									vonqData={vonqData}
									displayOfflineApplicationModal={
										displayOfflineApplicationModal
									}
									setTab={() => setTab(!tab)}
									setActive={() => setActiveTab("first")}
								/>
							</>
						) : (
							<>
								<Box>
									<Flex>
										<Box width={8 / 12} mr={60}>
											<Text
												mb={21}
												sx={{
													fontFamily: "BasierCircle",
													fontSize: "24px",
													fontWeight: "500",
													lineHeight: "1.6",
													letterSpacing: "0.12px",
													color: "rgb(37, 55, 88)"
												}}
											>
												Description
											</Text>
											<Text
												sx={{
													fontFamily: "BasierCircle",
													fontSize: "16px",
													lineHeight: "1.8",
													color: "#0f1c46"
												}}
											>
												<Description
													description={_get(data, "description", "")}
													parseMarkdown={true}
													showMore
													max={2000}
													showMoreText="Read more"
													className="jobOfflineDesc"
												/>
											</Text>
										</Box>
										<Box width={4 / 12}>
											<Sidebar
												job={data}
												displayApplyModal={handleApplyToJob}
												canApplyToJob={_get(data, "can_apply")}
												isLoading={applyStatus === LOADING}
												templateId={templateId}
												isOnline={isOnline}
												candidateStatus={user.candidate_status}
												setTab={() => setTab(!tab)}
												setActiveTab={() => setActiveTab("second")}
											/>
										</Box>
									</Flex>
								</Box>
							</>
						)}
					</Box>
					{tab ? null : (
						<Box className="view-job-details ">
							<Box className="content-inner jobdetails" m={0} p={0}>
								<Box
									mt={"6px"}
									sx={{
										borderRadius: "4px",
										boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
										backgroundColor: "rgb(255, 255, 255)"
									}}
									pl={36}
								>
									<JobFunctions functions={_get(data, "functions", [])} />
								</Box>
								<Box
									mt={"6px"}
									sx={{
										borderRadius: "4px",
										boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
										backgroundColor: "rgb(255, 255, 255)"
									}}
									pl={36}
								>
									<ListItem
										title={"Skills"}
										list={_get(data, "skills", [])}
										scoreMax={MAX_SKILL_WEIGHT}
										type={SKILLS}
										jobType={VACANCY}
										isVacancy={true}
									/>
								</Box>
								<Box
									mt={"6px"}
									sx={{
										borderRadius: "4px",
										boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
										backgroundColor: "rgb(255, 255, 255)"
									}}
									pl={36}
								>
									<ListItem
										title={"Languages"}
										list={_get(data, "languages", [])}
										scoreMax={MAX_LANGUAGE_WEIGHT}
										type={LANGUAGES}
									/>
								</Box>
								<Box
									mt={"6px"}
									p={"25px 20px"}
									sx={{
										borderRadius: "4px",
										boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
										backgroundColor: "rgb(255, 255, 255)"
									}}
									pl={56}
								>
									<Box className="section-title">Education level</Box>
									<Box>
										{EDUCATION_LEVELS.map(level => {
											if (level.value === _get(data, "education_level")) {
												return <>{level.label}</>;
											}
											return <></>;
										})}
									</Box>
								</Box>
							</Box>
						</Box>
					)}
				</Box>
			</BodyClassName>
			<ConfirmationModal
				active={displayConfirmationModal}
				modalName="apply-to-vacancy"
				onClose={() => setDisplayConfirmationModal(false)}
				firstButton={{
					action: sendJobApplication,
					label: "Yes"
				}}
				title="Are you sure you would like to apply to
                this position?"
				content="Once your application is confirmed, your profile will
                no longer be anonymous to this company."
				type={ConfirmationTypes.confirmation}
				loading={applyStatus === LOADING}
				doNotShowAgain={true}
				doNotShowComponent={() => (
					<DoNotShowAgain checked={checked} setChecked={setChecked} />
				)}
			/>
			<ConfirmationModal
				active={displayWarningModal}
				modalName="fetch-jobs"
				onClose={() => setDisplayWarningModal(false)}
				firstButton={{
					action: () => {
						fetchNewJobs();
						setDisplayWarningModal(false);
					},
					label: "Yes, load new jobs."
				}}
				title="Are you sure you would like load new jobs."
				content="Doing so will update the list of jobs displayed when you go back to the search page."
				type={ConfirmationTypes.confirmation}
				loading={isLoadingJob}
			/>

			{displayConfirmApplicationModal && (
				<ConfirmOfflineApplicationModal
					companyName={_get(data, "company.name")}
					newAccountEmail={newAccountEmail}
					onClose={() => setDisplayConfirmApplicationModal(false)}
				/>
			)}
			{displaySignInModal && (
				<SignInModal
					companyName={_get(data, "company.name")}
					vacancyTitle={_get(data, "title")}
					city={_get(data, "company.city")}
					vacancy_id={vacId}
					onClose={() => setDisplaySignInModal(false)}
					templateId={templateId}
					newEmail={newAccountEmail}
					vonqData={vonqData}
					data={data}
					errorForm={errorForm}
				/>
			)}
			{displayEmailUsedModal && (
				<EmailAlreadyUsedModal
					companyName={_get(data, "company.name")}
					vacancyTitle={_get(data, "title")}
					city={_get(data, "company.city")}
					accountType={newAccountType}
					onClose={() => {
						setDisplayEmailUsedModal(false);
					}}
					backAction={() => {
						setDisplayEmailUsedModal(false);
					}}
				/>
			)}
		</>
	);
};

const DoNotShowAgain = ({ checked, setChecked }) => {
	return (
		<Container>
			<div className="checkbox checkbox-primary">
				<input
					id={"do-not-show-again"}
					type="checkbox"
					onChange={() => setChecked(!checked)}
					checked={checked}
					value={checked}
					className="styled"
					name="do-not-show"
				/>
				<label htmlFor={"do-not-show"}>Do not show this again</label>
			</div>
		</Container>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps)(JobView);
