import { useState } from "react";
import { useMutation, usePaginatedQuery, useQuery } from "react-query";
import {
	GET_LIST_CHANNELS,
	GET_TAXONOMIES,
	CREATE_CAMPAIGN
} from "config/api-endpoints";
import { client } from "lib/api-client";
import { RESPONSE_CODE_422 } from "config";
import { getCampaignsPerformance } from "modules/job/actions";
import { useDispatch } from "react-redux";
import toaster from "common/Toaster";

import get from "lodash/get";

const useChannels = id => {
	const [searchChannel, setSearchChannel] = useState("");
	const [isRecommendation, setIsRecommendation] = useState(false);
	const [searchLocation, setSearchLocation] = useState("");
	const [offset, setOffset] = useState(0);
	const [selectedFunction, setSelectedFunction] = useState({
		value: "",
		label: ""
	});
	const [selectedJobTitle, setSelectedJobTitle] = useState({
		value: "",
		label: ""
	});
	const [selectedIndustry, setSelectedIndustry] = useState({
		value: "",
		label: ""
	});
	const [selectedLocation, setSelectedLocation] = useState({
		value: "",
		label: ""
	});
	const dispatch = useDispatch();
	const getListChannels = () =>
		client(GET_LIST_CHANNELS, {
			body: {
				offset: searchChannel ? 0 : offset,
				job_function_id: get(selectedFunction, "value", ""),
				job_title_id: get(selectedJobTitle, "value", ""),
				exact_location_id: get(selectedLocation, "value", ""),
				industry_id: get(selectedIndustry, "value", ""),
				name: searchChannel
			}
		});
	const getTaxonomies = () =>
		client(GET_TAXONOMIES, {
			body: {
				title: get(selectedFunction, "label", ""),
				location: searchLocation,
				id: isRecommendation ? id : ""
			}
		});

	const { data, isFetching: getListChannelsLoader } = usePaginatedQuery(
		[
			offset,
			selectedFunction,
			selectedJobTitle,
			selectedIndustry,
			selectedLocation,
			searchChannel
		],
		getListChannels,
		{
			refetchOnWindowFocus: false
		}
	);

	const { data: taxonomies, status } = useQuery(
		[selectedFunction, searchLocation, isRecommendation],
		getTaxonomies,
		{
			onSuccess: data => {
				setIsRecommendation(false);
				const functions = get(data, "recommendations.functions");
				if (get(functions, "length", 0) > 0) {
					const { name, id } = functions[0];
					setSelectedFunction({
						label: name,
						value: id
					});
				}
				const industries = get(data, "recommendations.industries");
				if (get(industries, "length", 0) > 0) {
					const { name, id } = industries[0];
					setSelectedIndustry({
						label: name,
						value: id
					});
				}
				const locations = get(data, "recommendations.locations");
				if (get(locations, "length", 0) > 0) {
					const { fully_qualified_place_name, id } = locations[0];
					setSelectedLocation({
						label: fully_qualified_place_name,
						value: id
					});
				}
			}
		}
	);

	const createCampaign = ({
		jobId,
		selectedChannels,
		publishLang,
		publish_types
	}) => {
		client(CREATE_CAMPAIGN, {
			body: {
				id: jobId,
				publish_types: {
					...publish_types,
					external_candidates: {
						active: true,
						channel_ids: selectedChannels.map(channel => channel.product_id)
					}
				},
				publish_version: publishLang
			}
		}).then(() => dispatch(getCampaignsPerformance(jobId)));
	};

	const [
		createCampaignFunction,
		{ isLoading: createCampaignLoader }
	] = useMutation(createCampaign, {
		onSuccess: (_, { onClose, onAfterSave, setIndexTab = () => {} }) => {
			toaster.success("Your vacancy has successfully been published.");
			setIndexTab && setIndexTab(2);
			onClose();
			onAfterSave();
		},
		onError: error => {
			if (get(error, "status") === RESPONSE_CODE_422) {
				const message = get(error, "detail");
				toaster.danger(message);
			}
		}
	});

	const clearAllFilters = () => {
		setSelectedFunction({
			value: "",
			label: ""
		});
		setSelectedJobTitle({
			value: "",
			label: ""
		});
		setSelectedIndustry({
			value: "",
			label: ""
		});
		setSelectedLocation({
			value: "",
			label: ""
		});
		setSearchChannel("");
	};
	return {
		clearAllFilters,
		setOffset,
		offset,
		selectedFunction,
		selectedJobTitle,
		selectedIndustry,
		selectedLocation,
		searchChannel,
		setSelectedIndustry,
		setSelectedJobTitle,
		setSelectedLocation,
		setSelectedFunction,
		data,
		isLoading: status === "loading",
		taxonomies,
		setSearchChannel,
		setSearchLocation,
		setIsRecommendation,
		createCampaignFunction,
		createCampaignLoader,
		getListChannelsLoader
	};
};

export default useChannels;
