import { historyPush } from "config/helpers";
import { useEditVacancyApi } from "../api/useEditVacancyApi";
import { buildEditVacancyInformationsPayload } from "../utils/helper";

export const useEditRequestStepTwo = () => {
	const [mutate, { isLoading }] = useEditVacancyApi();

	const editVacancy = (values, vacancyId, { onSuccess, ...rest }) => {
		const body = buildEditVacancyInformationsPayload(values, vacancyId);

		mutate(body, {
			onSuccess: () => {
				onSuccess?.();
				historyPush(`job/${vacancyId}`);
			},
			...rest
		});
	};

	return [editVacancy, isLoading];
};
