import EmptySearch from "common/AgTable/components/EmptyState/EmptyState.js";
import MyCandidatesSkeletonCards from "modules/vacancy/components/candidatePool/MyCandidatesSkeletonCards";
import ProfileCard from "modules/vacancy/components/newSearch/NewProfileCard.js";
import React from "react";
import { Box } from "rebass";
import _get from "lodash/get";

const CardsView = ({
	resolvedData,
	isLoading,
	isFetching,
	selectionBucket,
	addRow,
	toggleCard,
	include,
	exclude,
	mode,
	checkCandidateSelection
}) => {
	return (
		<>
			{isLoading ? (
				<MyCandidatesSkeletonCards />
			) : _get(resolvedData, "data", []).length > 0 ? (
				<Box
					sx={{
						display: "grid",
						gridGap: 3,
						gridTemplateColumns: "repeat(auto-fill, minmax(310px, 1fr))"
					}}
				>
					{_get(resolvedData, "data", []).map((profile, i) => (
						<div key={i}>
							<ProfileCard
								key={profile.id}
								profile={profile}
								size={resolvedData.size}
								isLoading={isFetching}
								selectionBucket={selectionBucket}
								addRow={addRow}
								onCheckboxChange={toggleCard}
								include={include}
								exclude={exclude}
								index={i}
								mode={mode}
								checkCandidateSelection={checkCandidateSelection}
							/>{" "}
						</div>
					))}
				</Box>
			) : (
				<EmptySearch
					text={"No candidate matching your search criteria."}
					withAction
				/>
			)}
		</>
	);
};

export default CardsView;
