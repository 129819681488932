import React, { useState } from "react";
import toast from "react-hot-toast";
import { ContractsGroupsList } from "./components/ContractsGroupsList";
import { SaveContractsGroupsDrawer } from "./components/SaveContractsGroupsDrawer";
import Pagination from "common/Pagination";
import styles from "./contracts-groups-tab.module.scss";
import useGetContractsGroupsList from "modules/vonqContract/api/useGetContractsGroupList";
import { DeleteContractsGroupConfirmationDialog } from "./components/DeleteContractsGroupConfirmationDialog";
import { ContractsGroupsListLoadingView } from "./components/ContractsGroupsListLoadingView";
import useDeleteContractsGroup from "modules/vonqContract/api/useDeleteContractsGroup";
import { GROUPS_CONTRACTS_LIST_PAGE_SIZE } from "modules/vonqContract/constants";
import useUpdateContractsGroup from "modules/vonqContract/api/useUpdateContractsGroup";

const ContractsGroupsTab = () => {
	const [groupToDelete, setGroupToDelete] = useState();
	const [groupToModify, setGroupToModify] = useState();
	const [offset, setOffset] = useState(0);
	const [updateContractsGroupError, setUpdateContractsGroupError] = useState(
		""
	);

	const {
		data: contractsGroupsData,
		refetch: refetchContractsGroupsList,
		isLoading
	} = useGetContractsGroupsList({
		limit: GROUPS_CONTRACTS_LIST_PAGE_SIZE,
		offset
	});

	const [
		deleteContractsGroup,
		{ isLoading: isContractsGroupDeleteLoading }
	] = useDeleteContractsGroup({
		onSuccess: () => refetchContractsGroupsList(),
		onError: error => {
			const message = error.errors?.[0] || "An error was occurred.";

			toast(message, { isFailed: true });
		}
	});

	const options = {
		onSuccess: () => {
			setGroupToModify(null);
			refetchContractsGroupsList();
		},
		onError: () => setUpdateContractsGroupError("The name is already used")
	};

	const [
		updateContractsGroup,
		{ isLoading: isUpdateContractsGroupLoading }
	] = useUpdateContractsGroup(options);

	let contractsGroupsList;
	let contractsGroupsListTotal;
	let displayPagination;

	if (contractsGroupsData) {
		contractsGroupsList = contractsGroupsData.data;
		contractsGroupsListTotal = contractsGroupsData.pagination.total;
		displayPagination =
			contractsGroupsListTotal > GROUPS_CONTRACTS_LIST_PAGE_SIZE;
	}

	const handleConfirmedDeleteClick = () => {
		deleteContractsGroup({ group_id: groupToDelete._id });
		setGroupToDelete(null);
	};

	const handleModifyContractsGroupClick = group => setGroupToModify(group);

	const handleDeleteContractsGroupClick = group => setGroupToDelete(group);

	const handleConfirmationModalClose = () => setGroupToDelete(null);

	const handleContractsDrawerClose = () => {
		setGroupToModify(null);
		setUpdateContractsGroupError("");
	};

	const handlePageClick = page =>
		setOffset(page * GROUPS_CONTRACTS_LIST_PAGE_SIZE);

	const handleContractsGroupSaveClick = groupName =>
		updateContractsGroup({
			name: groupName,
			group_id: groupToModify._id
		});

	const handleGroupNameInputChange = () => {
		setUpdateContractsGroupError("");
	};

	return (
		<div className={styles.container}>
			{isLoading || isContractsGroupDeleteLoading ? (
				<ContractsGroupsListLoadingView />
			) : (
				<>
					<ContractsGroupsList
						onModifyClick={handleModifyContractsGroupClick}
						onDeleteClick={handleDeleteContractsGroupClick}
						list={contractsGroupsList}
					/>
					{displayPagination && (
						<Pagination
							total={contractsGroupsListTotal}
							size={GROUPS_CONTRACTS_LIST_PAGE_SIZE}
							offset={offset}
							handlePageClick={handlePageClick}
						/>
					)}
				</>
			)}
			{groupToModify && (
				<SaveContractsGroupsDrawer
					contractGroup={groupToModify}
					onClose={handleContractsDrawerClose}
					header="Edit group"
					subHeader="Edit group"
					displaySavingLoading={isUpdateContractsGroupLoading}
					onSave={handleContractsGroupSaveClick}
					updateContractsGroupError={updateContractsGroupError}
					onInputChange={handleGroupNameInputChange}
				/>
			)}
			{groupToDelete && (
				<DeleteContractsGroupConfirmationDialog
					onClose={handleConfirmationModalClose}
					onConfirm={handleConfirmedDeleteClick}
				/>
			)}
		</div>
	);
};

export default ContractsGroupsTab;
