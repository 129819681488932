import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
	$createParagraphNode,
	$insertNodes,
	$isRootOrShadowRoot,
	$nodesOfType,
	COMMAND_PRIORITY_EDITOR,
	createCommand
} from "lexical";
import { $wrapNodeInElement } from "@lexical/utils";

import {
	$createEmailPlaceholderNode,
	EmailPlaceholderNode
} from "../../nodes/EmailPlaceholderNode";
import { useEffect } from "react";
import uniqBy from "lodash/uniqBy";
import { PlaceholderLinkNode } from "common/Editor/nodes/PlaceholderLinkNode";

export const INSERT_EMAIL_PLACEHOLDER_COMMAND = createCommand(
	"INSERT_EMAIL_PLACEHOLDER_COMMAND"
);

export default function EmailPlaceholderPlugin({ onEmailPlaceholdersChange }) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		if (!editor.hasNodes([EmailPlaceholderNode])) {
			throw new Error(
				"EmailPlaceholderPlugin: EmailPlaceholderNode not registered on editor (initialConfig.nodes)"
			);
		}

		return editor.registerCommand(
			INSERT_EMAIL_PLACEHOLDER_COMMAND,
			payload => {
				const { key, entity } = EmailPlaceholderNode.getEntity(payload);
				const placeholder = {
					entity,
					key,
					value: payload
				};
				const decoratorNode = $createEmailPlaceholderNode(placeholder);
				$insertNodes([decoratorNode]);
				if ($isRootOrShadowRoot(decoratorNode.getParentOrThrow())) {
					$wrapNodeInElement(decoratorNode, $createParagraphNode).selectEnd();
				}
				return true;
			},
			COMMAND_PRIORITY_EDITOR
		);
	}, [editor]);

	const onUpdate = () => {
		const linkPlaceholders = uniqBy(
			$nodesOfType(PlaceholderLinkNode).map(({ __placeholder, __key }) => ({
				__placeholder,
				__key
			})),
			"__key"
		);
		const otherPlaceholders = uniqBy(
			$nodesOfType(EmailPlaceholderNode).map(
				({ __placeholder: { value }, __key }) => ({
					__placeholder: value,
					__key
				})
			),
			"__key"
		);

		onEmailPlaceholdersChange?.([
			...otherPlaceholders.map(({ __placeholder }) => {
				const { key, entity } = EmailPlaceholderNode.getEntity(__placeholder);
				return {
					entity,
					key,
					value: __placeholder
				};
			}),
			...linkPlaceholders.map(({ __placeholder }) => {
				const { key, entity } = PlaceholderLinkNode.getEntity(__placeholder);
				return {
					entity,
					key,
					value: __placeholder
				};
			})
		]);
	};

	useEffect(() => {
		return editor.registerMutationListener(PlaceholderLinkNode, () => {
			editor.update(onUpdate);
		});
	}, [editor, onEmailPlaceholdersChange]);

	useEffect(() => {
		return editor.registerMutationListener(EmailPlaceholderNode, () => {
			editor.update(onUpdate);
		});
	}, [editor, onEmailPlaceholdersChange]);

	return null;
}
