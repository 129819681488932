import React from "react";
import { ARCHIVE_REQUEST } from "config";
import { connect } from "react-redux";
import { stopJobModal, stopJob } from "../../actions";
import PropTypes from "prop-types";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { bindActionCreators } from "redux";

function StopJob({
	job: { id, mode, title },
	onSuccess,
	stopJobModal,
	stopJob
}) {
	const closeModal = e => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		stopJobModal();
	};

	const stopped = () => {
		stopJob({ id }, mode).then(response => {
			if (response) {
				onSuccess(response);
			}
		});
	};

	const shouldArchive = mode === ARCHIVE_REQUEST;
	const requestTitle = title ? `the request “${title}” ?` : "this request ?";

	let modalTitle = `Are you sure you would like to unpublish ${requestTitle}`;

	if (shouldArchive) {
		modalTitle = (
			<>
				Are you sure you would like to archive {!title && <br />}
				{requestTitle}
			</>
		);
	}

	return (
		<ConfirmationModal
			modalName="stop_job"
			onClose={closeModal}
			type={ConfirmationTypes.error}
			title={modalTitle}
			content={
				shouldArchive &&
				"By doing so, you will automatically reject all the applications made for this mission and won’t be able to interact with them. This includes the applicants with whom you have interview planned or are negotiating a contract with."
			}
			firstButton={{
				action: stopped,
				label: `Yes, ${shouldArchive ? "archive" : "unpublish"} this request.`,
				type: "warning"
			}}
		/>
	);
}

const mapStateToProps = state => {
	return {
		job: state.job.stopJob
	};
};

StopJob.propTypes = {
	onSuccess: PropTypes.func
};

StopJob.defaultProps = {
	onSuccess: () => {}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			stopJobModal,
			stopJob
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(StopJob);
