import React, { useEffect } from "react";
import RequestPerformance from "./RequestPerformance";
import _get from "lodash/get";
import { useGetRequestPerformance } from "../../hooks/useGetRequestPerformance";

const RequestViewPerformance = ({ jobId, indexTab }) => {
	const { data, isLoading, refetch } = useGetRequestPerformance({ id: jobId });

	useEffect(() => {
		if (indexTab == 1) refetch();
	}, [indexTab]);

	return (
		<div className="full-container" style={{ marginTop: "10px" }}>
			<RequestPerformance
				isLoading={isLoading}
				campaigns={_get(data, "campaigns", [])}
			/>
		</div>
	);
};
export default RequestViewPerformance;
