import React, { useMemo, useState } from "react";
import get from "lodash/get";
import _find from "lodash/find";
import { OfferDetailsContainer, Label } from "./styles";
import { ReactComponent as ContractIcon } from "static/icons/contract-icon.svg";
import { ReactComponent as CurrencyIcon } from "static/icons/currency.svg";
import { ReactComponent as SalaryIcon } from "static/icons/salary-icon.svg";
import { ReactComponent as SalaryTypeIcon } from "static/icons/salary_type.svg";
import { ReactComponent as PaymentTypeIcon } from "static/icons/payment_periode.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar_icon.svg";
import { ReactComponent as ExtraBenifitsIcon } from "static/icons/extra-benifits-icon.svg";
import { ReactComponent as FileIcon } from "static/icons/offer-file-icon.svg";
import StatusLabel from "common/StatusLabel";
import {
	EMPLOYMENT_TYPE,
	PAYMENTS_TYPE,
	PAYMENTS_TIME,
	PERMANENT,
	DRAFT,
	DATE_FORMAT,
	SENT,
	ACCEPTED,
	REFUSED,
	CANCELLED
} from "config";

export const DataRenderer = ({ icon, title, value }) => (
	<div className="item">
		<Label>
			{icon} {title}
		</Label>
		<span className="value">{value}</span>
	</div>
);

const NUMBER_OF_Files = 2;
function OfferDetails({ offer, type }) {
	const [showFiles, setShowFiles] = useState(NUMBER_OF_Files);
	const data = [
		{
			icon: <ContractIcon />,
			title: "Contract type",
			value: get(
				_find(EMPLOYMENT_TYPE, ["value", get(offer, "contract_type")]),
				"label",
				"--"
			)
		},
		{
			icon: <CurrencyIcon />,
			title: "Currency",
			value: `${get(offer, "symbol", "--")} ${get(offer, "currency", "--")}`
		},
		{
			icon: <SalaryIcon />,
			title: "Salary",
			value: get(offer, "amount", "--")
				.toString()
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
		},
		{
			icon: <SalaryTypeIcon />,
			title: "Salary type",
			value: get(
				_find(PAYMENTS_TYPE, ["value", get(offer, "payment_type")]),
				"label",
				"--"
			)
		},
		{
			icon: <PaymentTypeIcon />,
			title: "Payment period",
			value: get(
				_find(PAYMENTS_TIME, ["value", get(offer, "payment_time")]),
				"label",
				"--"
			)
		},
		{
			icon: <CalendarIcon />,
			title: "Date of start",
			value: window.moment
				.unix(get(offer, "start_date", "--"))
				.format(DATE_FORMAT)
		},
		{
			icon: <ExtraBenifitsIcon />,
			title: "Extra benefits",
			value: get(offer, "other_benefits") || "N/A"
		}
	];

	const statusLabel = useMemo(() => {
		switch (offer.status) {
			case SENT:
				return type === PERMANENT ? "New offer" : "Offer sent";
			case ACCEPTED:
				return "Offer accepted";
			case CANCELLED:
				return "Offer cancelled";
			case REFUSED:
				return "Offer refused";

			default:
				return type === PERMANENT ? "New offer" : "Offer sent";
		}
	}, [offer.status]);
	const handleDownload = path => {
		if (path) window.open(path);
	};
	const displayedFiles = get(offer, "files", []).slice(0, showFiles);
	const otherFiles = get(offer, "files", []).slice(showFiles);

	return (
		<OfferDetailsContainer>
			<div>
				<label className="box-label">Job offer</label>
				<div className="first_row">
					{data.slice(0, 5).map(item => (
						<DataRenderer
							key={item.title}
							icon={item.icon}
							title={item.title}
							value={item.value}
						/>
					))}
				</div>
				<div className="second_row">
					{data.slice(5).map(item => (
						<DataRenderer
							key={item.title}
							icon={item.icon}
							title={item.title}
							value={item.value}
						/>
					))}
				</div>

				{Boolean(displayedFiles.length) && (
					<div className="files">
						<Label>
							<FileIcon /> Additional files
						</Label>
						<div className="value">
							{displayedFiles.map(file => (
								<div className="file" key={file.name}>
									<span
										onClick={() => handleDownload(file.path)}
										className="text"
									>
										{file.name}
									</span>
								</div>
							))}
							{Boolean(otherFiles.length) ? (
								<span className="other_files">
									{`${otherFiles.length} more ${
										otherFiles.length === 1 ? "file" : "files"
									}`}
									<span
										className="view_all"
										onClick={() => setShowFiles(get(offer, "files.length", -1))}
									>
										View all
									</span>
								</span>
							) : displayedFiles.length > NUMBER_OF_Files ? (
								<span className="other_files">
									{`${get(offer, "files.length", -1)} files`}
									<span
										className="view_all"
										onClick={() => setShowFiles(NUMBER_OF_Files)}
									>
										Show less
									</span>
								</span>
							) : null}
						</div>
					</div>
				)}
			</div>
			<div className="status">
				<StatusLabel
					status={offer.status === DRAFT ? SENT : offer.status}
					label={statusLabel}
				/>
			</div>
		</OfferDetailsContainer>
	);
}

export default OfferDetails;
