import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import RcSlider from "rc-slider";
import {
	Field,
	initialize,
	reduxForm,
	formValueSelector,
	SubmissionError
} from "redux-form";
import _get from "lodash/get";
import { closeModal, reloadSection, loadJobs } from "../../actions";
import { getMyProfile } from "../../../user/actions/authActions";
import CustomHandle from "common/CustomHandle";
import { UPDATE_PREFERENCE } from "config/api-endpoints";
import {
	PREFERENCE,
	HOURLY_BUDGET,
	EUR_PER_HOUR,
	WORK_DISTANCE,
	DEFAULT_DISTANCE,
	MAX_CURRENCY_VALUE,
	MINIMUM_DISTANCE
} from "config";
import { scrollToFirstError } from "common/Functions";
import CurrencyField from "common/NewCurrencyField";
import GlobalTooltip from "common/GlobalTooltip";
import Scrubber from "common/Scrubber";
import { preferenceSelector } from "../../selectors/ModalSelectors";
import FormField from "common/FormField";
import Modal from "common/modal";
import AxiosHelper from "config/axios-helper";
import AddressBox from "common/AddressBox/AddressChooser";
import { isEmptyAddress, getAddressOnly } from "config/helpers";
import { handleAddressObject } from "config/helpers";

class PreferenceModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			preference: { ...props.preference },
			error: false,
			addressTypo: false
		};
	}

	componentDidUpdate({ hourly_budget: prevHourlyBudget }) {
		const { preference } = this.state;
		const { hourly_budget } = this.props;
		if (
			HOURLY_BUDGET in this.props &&
			hourly_budget !== prevHourlyBudget &&
			preference.hourly_budget !== hourly_budget
		) {
			this.setState({
				preference: {
					...preference,
					hourly_budget
				}
			});
		}
	}

	handleDistanceChange = distance => {
		const { preference } = this.state;
		this.setState({
			preference: {
				...preference,
				distance
			}
		});
	};

	scrubberChange = value => {
		const { preference } = this.state;
		if (value === preference.distance) return;
		this.handleDistanceChange(value);
	};

	handleCommitmentChange = e => {
		const { preference } = this.state;
		const { name, checked } = e.target;
		preference[name] = checked;
		this.setState({ preference });
	};

	getViewport = value => {
		if (value) {
			return {
				northeast: {
					lat: value.getNorthEast().lat(),
					lng: value.getNorthEast().lng()
				},
				southwest: {
					lat: value.getSouthWest().lat(),
					lng: value.getSouthWest().lng()
				}
			};
		} else {
			return null;
		}
	};

	save = async () => {
		const { preference } = this.state;
		const {
			valid,
			getMyProfile,
			loadJobs,
			closeModal,
			reloadSection
		} = this.props;
		const address = getAddressOnly(preference);
		if (!valid) return;

		if (!preference.part_time && !preference.full_time) {
			throw new SubmissionError({
				full_time_part_time: "You have to choose at least one option"
			});
		}

		if (this.isAddressInvalid() || isEmptyAddress(address)) {
			return this.setState({ error: true });
		}
		const finalAddress = await handleAddressObject(address);

		if (finalAddress.addressTypo) {
			this.setState({
				error: true,
				addressTypo: true
			});
			return this.setState({
				error: false,
				addressTypo: false
			});
		}
		this.setState({
			...this.state,
			error: false,
			addressTypo: false,
			preference: {
				...this.state.preference,
				...finalAddress.address
			}
		});
		address.latitude = finalAddress.address.latitude;
		address.longitude = finalAddress.address.longitude;
		address.iso_country = finalAddress.address.iso_country;
		address.viewport =
			this.getViewport(finalAddress.address?.geometry?.viewport) ||
			preference.viewport;

		delete address.geometry;

		const preferenceToSave = {
			address,
			full_time: preference.full_time,
			part_time: preference.part_time,
			distance: preference.distance || DEFAULT_DISTANCE,
			hourly_budget: preference.hourly_budget
		};

		return AxiosHelper.post({
			url: UPDATE_PREFERENCE,
			isModal: true,
			toastMessage: "Info updated",
			reduxFormName: "preference",
			data: { preference: preferenceToSave, user_id: this.props.userId }
		}).then(() => {
			getMyProfile();
			if (preference.distance > 0) loadJobs();
			closeModal(false);
			reloadSection(PREFERENCE, this.props.userId);
		});
	};

	handleAddressChange = address => {
		const newPref = { ...this.state.preference };
		newPref.street = address.street || "";
		newPref.number = address.number || "";
		newPref.city = address.city || "";
		newPref.country = address.country || "";
		newPref.zip = address.zip || "";
		newPref.box = address.box || "";
		newPref.latitude = address.latitude || 0;
		newPref.longitude = address.longitude || 0;
		newPref.formatted_address = address.formatted_address || "";
		newPref.iso_country = address.iso_country || "";
		newPref.geometry = address.geometry || "";

		if (
			address.country !== _get(this.state, "preference.country") ||
			address.city !== _get(this.state, "preference.city")
		) {
			newPref.latitude = 0;
			newPref.longitude = 0;
		}
		this.setState({ preference: newPref });
	};

	isAddressInvalid = () => {
		const { preference } = this.state;
		return (
			!_get(preference, "street") ||
			!_get(preference, "number") ||
			!_get(preference, "city") ||
			!_get(preference, "country") ||
			!_get(preference, "zip")
		);
	};

	getTimeError = () => {
		if (
			this.props.submitFailed &&
			!this.state.preference.full_time &&
			!this.state.preference.part_time
		) {
			return "Please select at least one time commitment.";
		}
		return " ";
	};

	render() {
		const { handleSubmit, closeModal, submitFailed } = this.props;
		const { preference } = this.state;
		const address = this.state.preference;
		return (
			<Modal
				id="preferences-modal"
				title="What I want"
				firstButton={{
					label: "Save to my profile",
					type: "primary",
					action: handleSubmit(this.save)
				}}
				secondButton={{ label: "Cancel", action: closeModal }}
				onClose={closeModal}
			>
				<div className="help-block mt--15">
					This data is strictly confidential and solely used to suggest you
					missions matching your expectations.
				</div>
				<form onSubmit={handleSubmit(this.save)}>
					<div className="content">
						<div className="row">
							<div className="col-md-11">
								<div id="address">
									<AddressBox
										setAddress={this.handleAddressChange}
										showAutoComplete
										name="location"
										label={"I WANT TO WORK FROM"}
										address={address}
										addressTypo={this.state.addressTypo}
										error={
											this.state.error ||
											(submitFailed && this.isAddressInvalid())
										}
										overlay={`As a default value, the platform uses the address of your legal entity.
											If you're based elsewhere, just edit this field.`}
									/>
								</div>
							</div>
							<div className="clearfix" />
							<div className="col-md-12">
								<h5>Distance</h5>
								<div className="km-amount">
									Amount of kilometres (from the above-mentioned address) within
									which I’m willing to work
								</div>
							</div>
							<div className="col-md-11">
								<div className="col-md-9">
									<RcSlider
										min={MINIMUM_DISTANCE}
										max={WORK_DISTANCE}
										value={preference.distance || DEFAULT_DISTANCE}
										handle={props => (
											<CustomHandle
												{...props}
												tipClass="tooltipWithModal"
												tipFormatter={value => `${value}km`}
												trigger={["hover"]}
											/>
										)}
										onChange={this.handleDistanceChange}
									/>
								</div>
								<div className="scrubber col-md-3">
									<Scrubber
										min={MINIMUM_DISTANCE}
										max={WORK_DISTANCE}
										value={preference.distance || DEFAULT_DISTANCE}
										onAfterChange={this.scrubberChange}
										debounce={true}
									/>
									km
								</div>
							</div>
							<div className="clearfix" />
							<div className="col-md-8">
								<h5>
									Time commitment{" "}
									<GlobalTooltip
										maxWidth="400px"
										overlay="Define if you want to be noticed when part or fulltime opportunities come up (or both)."
									/>
								</h5>
								<div className="checkbox-holder">
									<div className="first-check col-md-4">
										<Field
											component={FormField}
											label="Full time"
											type="checkbox"
											name="full_time"
											checked={preference.full_time}
											onChange={this.handleCommitmentChange}
											onClick={this.handleCommitmentChange}
											className="styled"
											id="fulltime"
											noError={true}
										/>
									</div>
									<div className="col-md-6">
										<Field
											component={FormField}
											label="Part time"
											type="checkbox"
											name="part_time"
											checked={preference.part_time}
											onChange={this.handleCommitmentChange}
											onClick={this.handleCommitmentChange}
											className="styled"
											id="parttime"
											noError={true}
										/>
									</div>
								</div>
								<div className="help-block inline-error">
									{this.getTimeError()}
								</div>
							</div>
							<div className="clearfix" />
							<div className="col-md-4">
								<Field
									name="hourly_budget"
									suffix={EUR_PER_HOUR}
									component={CurrencyField}
									label="Minimum hourly rate"
									tooltipTitle="This value will reduce the mission suggestions only to opportunities
										for which the budget of the client is at least equivalent to your minimum hourly rate."
								/>
							</div>
						</div>
					</div>
				</form>
			</Modal>
		);
	}
}

const validate = formprops => {
	const errors = {};
	if (!formprops.full_time && !formprops.part_time) {
		errors.full_time = "Please select at least one time commitment.";
	}
	if (!formprops.hourly_budget || formprops.hourly_budget === "") {
		errors.hourly_budget = "This field is required";
	}
	if (!/\d/.test(formprops.hourly_budget)) {
		errors.hourly_budget = "This field must be a number";
	}
	if (
		formprops.hourly_budget &&
		(formprops.hourly_budget <= 0 ||
			formprops.hourly_budget >= MAX_CURRENCY_VALUE)
	) {
		errors.hourly_budget = "The hourly rate should be between 1€ and 1000€";
	}
	if (
		!formprops.street ||
		!formprops.number ||
		!formprops.zip ||
		!formprops.city ||
		!formprops.country
	) {
		errors.location = "Please enter a full valid address.";
	}

	if (!formprops.street) {
		errors.street = "Enter a valid street.";
	}
	if (!formprops.number) {
		errors.number = "Enter a valid number.";
	}
	if (!formprops.zip) {
		errors.zip = "Enter a valid zip code.";
	}
	if (!formprops.city) {
		errors.city = "Enter a valid city.";
	}
	if (!formprops.country) {
		errors.country = "Enter a valid country.";
	}
	return errors;
};

const selector = formValueSelector("preference");

const mapStateToProps = state => {
	const preference = preferenceSelector(state);
	return {
		preference,
		initialValues: preference,
		next_step: state.cv.next_step,
		errors:
			state.form.preference && state.form.preference.submitErrors
				? state.form.preference.submitErrors
				: {},
		hourly_budget: selector(state, "hourly_budget")
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initializeReduxForm: data => dispatch(initialize("preference", data)),
		closeModal: (value = true) => dispatch(closeModal(value)),
		getMyProfile: () => dispatch(getMyProfile()),
		reloadSection: (value, userId) => dispatch(reloadSection(value, userId)),
		loadJobs: () => dispatch(loadJobs())
	};
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "preference",
		validate,
		touchOnBlur: false,
		onSubmitFail: errors => scrollToFirstError(errors)
	})
)(PreferenceModal);
