/*eslint-disable*/
import React, { Fragment, useMemo, useEffect, useState } from "react";
import Drawer from "rc-drawer";
import { browserHistory } from "react-router";
import {
	STEPS,
	CREATE_USER,
	ASSIGN_ROLE,
	INVITE_PREVIEW,
	RESEND_INVITE,
	BLOCK_USER,
	UNBLOCK_USER,
	DELETE_INVITE,
	TRANSFERT_OWNERSHIP
} from "config";
import CreateUser from "./CreateUser";
import AssignRole from "./AssignRole";
import InvitePreview from "./InvitePreview";
import InviteSuccess from "./InviteSuccess";
import ResendInvite from "./ResendInvite";
import ChangeUserAccessModal from "./ChangeUserAccessModal";
import DeleteInvitation from "./DeleteInvitation";

import TransfertOwnership from "./TransfertOwnership";
import { SUCCESS_USER_CREATED } from "config";

import { useTour } from "@reactour/tour";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { TOUR_USER_AND_DEPARTEMENT } from "config/onboarding";

const useTourData = () => {
	const { workflowId } = useOnboardingStore();
	const { setIsOpen, currentStep, isOpen, setCurrentStep } = useTour();
	const isActiveSetupCompanyTour = useMemo(
		() => workflowId === TOUR_USER_AND_DEPARTEMENT,
		[workflowId]
	);
	const goNextTourStep = () => {
		if (isActiveSetupCompanyTour) {
			browserHistory.push("/settings/users");
			setCurrentStep(1);
			setIsOpen(true);
		}
	};

	return {
		goNextTourStep,
		isActiveSetupCompanyTour
	};
};

const ModalUI = ({
	step,
	onClose,
	handleStep,
	exists,
	user,
	users,
	adminName,
	modalSuccess,
	onResend,
	onAccessChanged,
	connectedUser,
	onDeleteInvitation,
	onTranfertOwnership,
	isEdit,
	currentUserGroupRolesName,
	toggleDepartmentModal,
	moveToStep
}) => {
	const { goNextTourStep, isActiveSetupCompanyTour } = useTourData();
	const [isClosing, setIsClosing] = useState(false);
	const [isOpenDrawer, setisOpenDrawer] = useState(false);
	const [assignRoleDataForm, setAssignRoleDataForm] = useState({});
	const [assignDepartment, setAssignDepartment] = useState({});
	const closeDrawer = () => {
		setIsClosing(true);
		onClose();
		goNextTourStep();
	};
	useEffect(() => {
		requestAnimationFrame(() => {
			setisOpenDrawer(true);
		});
	}, []);
	const currentStep = STEPS.findIndex(stp => stp.name === step);
	const activeStep = STEPS[currentStep] || {};
	const showStepper =
		!modalSuccess &&
		[RESEND_INVITE, BLOCK_USER].indexOf(activeStep.name) === -1 &&
		!isEdit;

	if (step === BLOCK_USER || step === UNBLOCK_USER)
		return (
			<ChangeUserAccessModal
				isUnblock={step === UNBLOCK_USER}
				user={user}
				onClose={onClose}
				onAccessChanged={onAccessChanged}
			/>
		);
	if (step === DELETE_INVITE)
		return (
			<DeleteInvitation
				user={user}
				onClose={onClose}
				onDeleteInvitation={onDeleteInvitation}
			/>
		);

	if (step === TRANSFERT_OWNERSHIP)
		return (
			<TransfertOwnership
				user={user}
				onClose={onClose}
				connectedUser={connectedUser}
				onTranfertOwnership={onTranfertOwnership}
			/>
		);

	if (step === SUCCESS_USER_CREATED) {
		return <InviteSuccess onClose={closeDrawer} user={user} />;
	}

	if (step === RESEND_INVITE) {
		return <ResendInvite user={user} onResend={onResend} onClose={onClose} />;
	}
	return (
		<Drawer
			open={isOpenDrawer}
			width="546px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={closeDrawer}
			className={isClosing ? "fade-out" : ""}
			duration="0.3s"
		>
			<Fragment>
				<div className="content">
					{(() => {
						switch (step) {
							case CREATE_USER:
								return (
									<CreateUser
										handleStep={handleStep}
										emailExists={exists}
										onClose={closeDrawer}
										adminName={adminName}
										labelPlacement="vertical"
										currentStep={currentStep}
										showStepper={showStepper}
										isModeTour={isActiveSetupCompanyTour}
										user={user}
									/>
								);
							case ASSIGN_ROLE:
								return (
									<AssignRole
										user={user}
										isEdit={isEdit}
										handleStep={handleStep}
										onClose={closeDrawer}
										connectedUser={connectedUser}
										connectedUserRoles={currentUserGroupRolesName}
										departmentList={users.groups_list}
										currentStep={currentStep}
										showStepper={showStepper}
										toggleDepartmentModal={toggleDepartmentModal}
										isModeTour={isActiveSetupCompanyTour}
										moveToStep={moveToStep}
										assignRoleDataForm={assignRoleDataForm}
										setAssignRoleDataForm={setAssignRoleDataForm}
										assignDepartment={assignDepartment}
										setAssignDepartment={setAssignDepartment}
									/>
								);
							case INVITE_PREVIEW:
								return (
									<InvitePreview
										user={user}
										handleStep={handleStep}
										onClose={closeDrawer}
										adminName={adminName}
										currentStep={currentStep}
										showStepper={showStepper}
										isModeTour={isActiveSetupCompanyTour}
										moveToStep={moveToStep}
									/>
								);

							default:
								return null;
						}
					})()}
				</div>
			</Fragment>
		</Drawer>
	);
};

export default ModalUI;
