import Immutable from "seamless-immutable";
import * as types from "../actions/actionTypes";
import { PREFERENCE } from "config";

const initialState = Immutable({
	showModal: false,
	proposing: false,
	modalName: "",
	modalData: null,
	isFetching: true,
	busySection: null,
	activeTab: 0,
	jobs: [],
	ownCV: true,
	saving: false,
	suggestedSkills: [],
	editSkills: [],
	editLanguages: [],
	isProcessingFile: false,
	cv_docs: {}
});

export default function(state = initialState, action) {
	const { type } = action;
	switch (type) {
		case types.SHOW_MODAL:
			return Immutable.merge(state, {
				showModal: true,
				modalName: action.modal,
				modalData: action.data
			});
		case types.CLOSE_MODAL:
			return Immutable.merge(state, {
				showModal: false,
				modalName: ""
			});
		case types.LOAD_PROFILE:
			return Immutable.merge(state, { isFetching: true });
		case types.LOADED_PROFILE:
			return Immutable.merge(state, {
				isFetching: false,
				ownCV: action.ownCV,
				...action.data,
				editLanguages: action.data.languages,
				editSkills: action.data.skills
			});
		case types.CHANGE_TAB:
			return Immutable.merge(state, { activeTab: action.tab });
		case types.RELOAD_SECTION:
			return Immutable.merge(state, {
				isFetching: true,
				busySection: action.section
			});
		case types.RELOADED_SECTION:
			let data = action.data;
			if (state.busySection === PREFERENCE) {
				data = action.data[0];
			}
			return Immutable.merge(state, {
				isFetching: false,
				[state.busySection]: data,
				busySection: null
			});
		case types.CHANGE_AVATAR:
			return Immutable.merge(state, {
				headline: { ...state.headline, avatar: action.avatar }
			});
		case types.WRITE_JOBS:
			return Immutable.merge(state, { jobs: action.jobs });
		case types.UPDATE_SELECTED_SKILLS:
			return Immutable.merge(state, {
				editSkills: action.skills
			});
		case types.UPDATE_SELECTED_LANGUAGES:
			return Immutable.merge(state, {
				editLanguages: [...action.languages],
				languages: action.saving ? [...action.languages] : [...state.languages]
			});
		case types.PUT_SUGGESTED_SKILLS:
			return Immutable.merge(state, {
				suggestedSkills: action.data || []
			});
		case types.CLEAR_PROFILE_DATA:
			return initialState;
		case types.PROCESSED_CV_FILE:
			return Immutable.merge(state, {
				isProcessingFile: false,
				cv_docs: {
					...state.cv_docs,
					original_cv: action.data,
					converted_cv: {
						...state.converted_cv,
						objects: ""
					}
				}
			});
		case types.UPDATE_IS_PROCESSING:
			return Immutable.merge(state, {
				isProcessingFile: action.data
			});
		default:
			return state;
	}
}

// selectors

export const hasCurrent = (state, items) => {
	const data = state.modalData;
	const isEdit = !!data;
	return (
		state[items].findIndex(item => {
			if (isEdit) {
				return data._id !== item._id && item.is_current;
			}
			return item.is_current;
		}) !== -1
	);
};
