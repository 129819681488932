import React from "react";
import cx from "classnames";
import get from "lodash/get";
import styles from "./note-custom-visibility-list.module.scss";
import { ReactComponent as UserIcon } from "static/icons/custom_visibility_user.svg";
import { CustomAvatar } from "common/CustomAvatar";
const NoteCustomVisibilityListTags = ({ users = [], groups = [] }) => {
	return (
		<>
			<div className={styles.images}>
				{users.map((user, index) => (
					<div className={styles.avatarWrapper} key={user._id}>
						<CustomAvatar
							src={user.avatar}
							variant="small"
							fullName={`${get(user, "first_name")} ${get(user, "last_name")}`}
							rootClassName={cx(styles.collaboratorPicture, {
								[styles.firstCollaboratorPicture]: index === 0
							})}
							imgClassName={cx(styles.picture)}
							avatarClassName={cx(styles.avatarWithBorder, styles.avatar, {
								[styles.firstCollaboratorPicture]: index === 0
							})}
							avatarSize={30}
							rootStyle={{
								zIndex: index
							}}
							avatarStyle={{
								zIndex: index
							}}
						/>
					</div>
				))}
			</div>

			{groups.map(group => (
				<div className={styles.tag} key={group._id}>
					<span className={styles.departementIcon}>
						<UserIcon />
					</span>
					<span className={styles.tagName}>{group.name}</span>
				</div>
			))}
		</>
	);
};
export default NoteCustomVisibilityListTags;
