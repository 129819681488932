import React from "react";
import FilterColumn from "../FilterColumn";
import { OperatorsField } from "../OperatorsField";
import { ValueField } from "../ValueField";
import styles from "./filter-row.module.scss";
import ColumnDropDown from "../ColumnDropDown/ColumnDropDown";
import { useFilters } from "../hooks/useFilter";

const FilterRow = ({ filter }) => {
	const { getState, currentFilterId } = useFilters();
	const { columns } = getState(currentFilterId);

	const isSimpleFields = !columns[0]?.children;

	return (
		<div className={styles.root}>
			{isSimpleFields ? (
				<ColumnDropDown filter={filter} />
			) : (
				<FilterColumn filter={filter} />
			)}
			<OperatorsField filter={filter} />
			<ValueField filter={filter} />
		</div>
	);
};
export default React.memo(FilterRow);
