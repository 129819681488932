import React from "react";
import SupplierNameFilter from "./SupplierNameFilter";
import IndustryFilter from "./IndustryFilter/IndustryFilter";
import ExpertiseFilter from "./ExpertiseFilter/ExpertiseFilter";
import ServicesFilter from "./ServicesFilter/ServicesFilter";
import LanguagesFilter from "./LanguagesFilter/LanguagesFilter";
import GeographicalCoverageFilter from "./GeographicalCoverageFilter/GeographicalCoverageFilter";
import SizeFilter from "./SizeFilter/SizeFilter";
import s from "../search-suppliers.module.scss";
import useFiltersStore from "./searchStore";
import useMySuppliersStore from "./mySuppliersStore";

function SearchSuppliersFilter({ isSearch }) {
	const store = isSearch ? useFiltersStore : useMySuppliersStore;
	return (
		<div className={s.filtersContainer}>
			<SupplierNameFilter store={store} />
			<IndustryFilter store={store} />
			<ExpertiseFilter store={store} />
			<ServicesFilter store={store} />
			<LanguagesFilter store={store} />
			<GeographicalCoverageFilter store={store} />
			<SizeFilter store={store} />
		</div>
	);
}

export default SearchSuppliersFilter;
