// auth action types
// export const SWITCH_ROLE = "SWITCH_ROLE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const TOGGLE_WELCOME = "TOGGLE_WELCOME";
export const TOGGLE_GET_STARTED_DIALOG = "TOGGLE_GET_STARTED_DIALOG";

export const DISABLE_ACCOUNT = "DISABLE_ACCOUNT";
export const USER_BLOCKED = "USER_BLOCKED";

// Sign up
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const FAILED_VAT = "FAILED_VAT";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const STORE_USER_ID = "STORE_USER_ID";
export const SET_SIGNUP_ADDRESS = "SET_SIGNUP_ADDRESS";
export const FETCH_USER_FILES = "FETCH_USER_FILES";

export const HIDE_TO_BE_REVIEWED_BANNER = "HIDE_TO_BE_REVIEWED_BANNER";

export const HIDE_COOKIE_POLICY = "HIDE_COOKIE_POLICY";
