import React from "react";
import { useToggle } from "react-use";
import { XIcon } from "@heroicons/react/solid";
import FilterCard from "../../../shared/FilterCard";
import LanguagesFilterDialog from "./LanguagesFilterDialog";
import s from "../ServicesFilter/service-filter.module.scss";
import Portal from "../../../../../common/Portal";

function LanguagesFilter({ store }) {
	const [on, toggle] = useToggle(false);
	const { languages, setLanguages } = store(state => ({
		languages: state.languages,
		setLanguages: state.setLanguages
	}));

	const removeLanguage = language => {
		setLanguages(languages.filter(l => l.value !== language.value));
	};

	return (
		<>
			<FilterCard
				name={"Languages"}
				onEdit={toggle}
				onRemove={() => setLanguages([])}
				value={languages}
			>
				<ul className={s.list}>
					{languages.map(language => (
						<li className={s.listItem} key={language.value}>
							<span>{language.label}</span>
							<button onClick={() => removeLanguage(language)}>
								<XIcon width={16} height={16} />
							</button>
						</li>
					))}
				</ul>
			</FilterCard>
			<Portal>
				<LanguagesFilterDialog
					store={store}
					isOpen={on}
					onClose={() => toggle(false)}
				/>
			</Portal>
		</>
	);
}

export default LanguagesFilter;
