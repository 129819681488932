/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Modal from "common/modal/StepsModal";
import InterviewStepProactive from "./components/InterviewStepProactive";
const InterviewModal = ({
	toggleModal,
	user,
	companyType,
	sendInterview,
	attendeesList = [],
	fetchAttendees,
	displayProposeInerview
}) => {
	useEffect(() => {
		fetchAttendees();
	}, []);
	const [startTime, saveStartTime] = useState(null);
	const [endTime, saveEndTime] = useState(null);

	const [previousDate, setPreviousDate] = useState(null);
	const [interviewFormValues, setInterviewFormValues] = useState({});

	const [interviewDescription, setInterviewDescription] = useState("");
	const [selectedAttendees, setSelectedAttendees] = useState([]);

	const submitInterviewData = values => {
		let ApiData;

		ApiData = {
			interview: {
				...values
			}
		};
		delete ApiData.interview.isCalendarChanged;
		sendInterview(ApiData);
	};

	const profile = "";
	const is_edit = false;
	const is_decline = false;
	const job_title = "";
	const userConsultancy = "";

	return (
		<Modal
			active={displayProposeInerview}
			title={"Propose interview"}
			onClose={toggleModal}
			showActions={false}
		>
			<InterviewStepProactive
				profile={profile}
				is_edit={is_edit}
				is_decline={is_decline}
				job_title={job_title}
				userConsultancy={userConsultancy}
				companyType={companyType}
				user={user}
				submitInterviewForm={submitInterviewData}
				handleBackStep={toggleModal}
				previousValues={interviewFormValues}
				previousDate={previousDate}
				setPreviousDate={setPreviousDate}
				saveStartTime={saveStartTime}
				saveEndTime={saveEndTime}
				previousStartTime={startTime}
				previousEndTime={endTime}
				attendeesList={attendeesList}
				setSelectedAttendees={setSelectedAttendees}
				selectedAttendees={selectedAttendees}
				setInterviewDescription={setInterviewDescription}
				interviewDescription={interviewDescription}
			/>
		</Modal>
	);
};

export default InterviewModal;
