import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NotificationsSettings from "./NotificationsSettings";
import { onlineUserSelector } from "modules/user/selectors/selectors";

export const getNotificationsSettingsRoute = () => {
	return "/settings/notifications";
};
export class NotificationsSettingsContainer extends Component {
	render() {
		const { route, user } = this.props;
		return <NotificationsSettings route={route} user={user} />;
	}
}

NotificationsSettingsContainer.propTypes = {
	user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps)(NotificationsSettingsContainer);
