import React, { useState, useEffect } from "react";
import { LocationModalContainer } from "../../styles";
import MapContainer from "common/AddressBox/MapView/MapContainer";
import AddressEdit from "common/AddressBox/AddressEdit";
import ViewSwitcher from "common/AddressBox/ViewSwitcher";
import styled from "styled-components";
import { ReactComponent as CloseButton } from "static/icons/close-button.svg";
import { ADDRESS_VIEW_TYPES } from "config";
import GoogleAutocomplete from "common/GoogleAutocomplete";
import toaster from "common/Toaster";
import Portal from "common/Portal";

const ViewContainer = styled.div`
	position: relative;
`;

const LocationModal = ({
	setAddress: addAddress,
	address: addedAddress,
	addressTypo,
	label,
	showToast = true,
	setShowLocationModal,
	addressError,
	setAddressError,
	closeLocationModal
}) => {
	const [view, setView] = useState(ADDRESS_VIEW_TYPES.inputs);
	const [address, setAddress] = useState(addedAddress);

	useEffect(() => {
		if (addressError) {
			setView(ADDRESS_VIEW_TYPES.inputs);

			showToast &&
				toaster.warning(
					addressTypo
						? `Please make sure to double check the spelling of both the city and the country.`
						: `We couldn't get all the required fields from the address you've selected,
					please fill the remaining fields to continue.`,
					{ duration: 15, id: "incomplete-address" }
				);
		}
	}, [addressError]);

	const isValidAddress = () => {
		let valid = true;
		const requiredFields = ["street", "number", "city", "zip", "country"];
		requiredFields.forEach(field => {
			if (!address[field]) {
				valid = false;
			}
		});
		return valid;
	};
	const handleAddAddress = e => {
		e.stopPropagation();
		if (isValidAddress()) {
			addAddress(address);
			setShowLocationModal(false);
			return;
		}
		setAddressError(true);
	};

	return (
		<Portal>
			<LocationModalContainer>
				<div className="modal-interview-header">
					<span className="title">Interview location</span>
					<button
						onClick={e => {
							e.stopPropagation();
							setShowLocationModal(false);
							closeLocationModal();
						}}
						className="close-modal"
					>
						<CloseButton />
					</button>
				</div>
				<div className="modal-interview-body">
					<span className="modal-body-text">
						Please add the address of your interview
					</span>
					<div className="interview-location-box">
						<GoogleAutocomplete
							input={{ onChange: setAddress }}
							name={"address"}
							placeholder="address"
							address={address}
							showMapMarker={true}
						/>
					</div>
					<ViewContainer>
						<ViewSwitcher selected={view} onSwitch={type => setView(type)} />
						{view === ADDRESS_VIEW_TYPES.inputs && (
							<AddressEdit
								address={address}
								error={addressError}
								label={label}
								onAddressChange={setAddress}
							/>
						)}
						{view === ADDRESS_VIEW_TYPES.map && (
							<div
								style={{
									position: "relative",
									width: "100%",
									height: 368,
									marginBottom: 31
								}}
							>
								<MapContainer address={address} onAddressChange={setAddress} />
							</div>
						)}
					</ViewContainer>
				</div>
				<div className="modal-interview-footer">
					<button
						onClick={e => {
							e.stopPropagation();
							setShowLocationModal(false);
							closeLocationModal();
						}}
						className="close-button"
					>
						Cancel
					</button>
					<button onClick={handleAddAddress} className="add-address-button">
						Add address
					</button>
				</div>
			</LocationModalContainer>
		</Portal>
	);
};

export default LocationModal;
