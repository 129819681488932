import React, { useEffect, useMemo, useState, useRef } from "react";
import _debounce from "lodash/debounce";
import useTasksStore from "./store/useTaskStore";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { browserHistory } from "react-router";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import FilterBox from "./components/FilterBox";
import Table from "./components/table";
import Date from "./components/table/date";
import Priority from "./components/table/priority";
import RenderUser from "./components/table/renderUser";
import Status from "./components/table/status";
import Actions from "./components/table/action";
import classnames from "./tasks.module.scss";
import get from "lodash/get";
import { CONSULTANCY, PERMANENT, FREELANCER, VACANCY, REQUEST } from "config";
import PaginationClient from "./components/pagination";
import SkeletonComponent from "./components/skeleton";
import useGetTasks from "./api/useGetTasks";
import TaskDetailsDrawer from "modules/Tasks/components/TaskDetails";
import useMarkAsCompleted from "modules/Tasks/api/useMarkAsCompleted";
import useDeleteTask from "modules/Tasks/api/useDeleteTask";
import { myTaskListKey } from "modules/Tasks/api/useGetTasks";
import { getTaskDetailsKey } from "modules/Tasks/components/TaskDetails/api/useTaskDetails";
import { queryCache } from "react-query";
import ConfirmationModalAction from "modules/Tasks/components/confirmationModal/ConfirmationModalAction";
import Tooltip from "rc-tooltip";
import EmptyStateLinkTo from "./components/table/EmptyStateLinkTo";
import DisplayResults from "./components/pagination/DisplayResults";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

export const getTasksRoute = () => {
	return "/tasks(/:id)";
};

export const getListTasksRoute = () => {
	return "/tasks";
};

export const getTasksPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export const getLinks = (type, id) => {
	switch (type) {
		case CONSULTANCY:
			return "/supplier/" + id + "?source=tasks";
		case PERMANENT:
			return "/permanent/view/" + id;
		case FREELANCER:
			return "user/profile/" + id + "?source=tasks";
		case VACANCY:
			return "/vacancy/" + id + "?source=tasks&listKey=tasks&history=tasks";
		case REQUEST:
			return "/job/" + id;
		default:
			return "";
	}
};

export const dataSkeleton = new Array(9).fill({
	title: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	link_to: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	status: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	due_date: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	priority: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	created_by: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	assigned_user: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	creation_date: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	action: <SkeletonComponent width={20} height={14} borderRadius={10} />
});

const Tasks = ({ params }) => {
	const { id } = params;
	const [searchKeyword, setSearchKeyword] = useState("");
	const [isOpenDetailsDrawer, setIsOpenDetailsDrawer] = useState(false);
	const [taskID, setTaskID] = useState(null);
	const [markAsCompleted, { isLoading: isSaving }] = useMarkAsCompleted();
	const [deleteTask, { isLoading: isDeleting }] = useDeleteTask();
	const user = useSelector(onlineUserSelector);

	const [showModalDelete, setShowModalDelete] = useState(false);
	const [showModalCompleted, setShowModalCompleted] = useState(false);

	const { setHighlitedRow, highlitedRow } = useAddNewTaskStore();

	const {
		filters,
		updateOffset,
		updateSearch,
		updateFilters,
		setLimit,
		limit
	} = useTasksStore();
	const {
		offset,
		fields,
		field,
		search,
		isTitleSearch,
		isDescriptionSearch
	} = filters;

	const setItems = useBreadCrumbs(state => state.setItems);
	const idModal = useRef();
	useEffect(() => {
		setItems([{ name: "Tasks" }]);
		setSearchKeyword(search);
	}, []);
	useEffect(() => {
		setTaskID(id);
		setIsOpenDetailsDrawer(true);
	}, [id]);

	useEffect(() => {
		setTimeout(() => setHighlitedRow(null), 2000);
	}, [highlitedRow]);

	const elilminateEmptyFieldsValues = fields.filter(item =>
		get(
			item,
			"value.length",
			get(item, "value._id.length", get(item, "value.time", false))
		)
	);

	let { resolvedData, isFetching } = useGetTasks({
		...filters,
		limit,
		fields: elilminateEmptyFieldsValues,
		...((isTitleSearch || isDescriptionSearch) &&
			search && {
				search: {
					value: search,
					title: isTitleSearch,
					description: isDescriptionSearch
				}
			})
	});

	const redirectToEntity = (e, entity) => {
		e.stopPropagation();
		const link = getLinks(
			get(entity, "linked_to.type", ""),
			get(entity, "linked_to._id", "")
		);
		link && browserHistory.push(link);
	};
	const markAsCompletedBtn = () => {
		markAsCompleted(
			{
				task_id: idModal.current
			},
			{
				onSuccess: () => {
					queryCache.invalidateQueries(getTaskDetailsKey);
					queryCache.invalidateQueries(myTaskListKey);
					setShowModalCompleted(false);
					setHighlitedRow(idModal.current);
				}
			}
		);
	};
	const deleteTaskBtn = () => {
		deleteTask(
			{
				task_id: idModal.current
			},
			{
				onSuccess: () => {
					queryCache.invalidateQueries(myTaskListKey);
					setShowModalDelete(false);
				}
			}
		);
	};
	const showModalDeleteClick = id => {
		idModal.current = id;
		setShowModalDelete(true);
	};
	const showModalClick = id => {
		idModal.current = id;
		setShowModalCompleted(true);
	};
	const closeModal = () => {
		setShowModalDelete(false);
		setShowModalCompleted(false);
	};
	const data = useMemo(
		() =>
			isFetching
				? dataSkeleton
				: get(resolvedData, "tasks", []).map(item => {
						const fullName =
							get(item, "created_by.first_name", "") +
							" " +
							get(item, "created_by.last_name", "");
						const assignedFullName =
							get(item, "assigned_user.first_name", "") +
							" " +
							get(item, "assigned_user.last_name", "");

						const id = get(item, "_id");
						const status = get(item, "status", "");
						const title = get(item, "title", "");
						const renderedTitle =
							title.length > 46 ? (
								<Tooltip
									overlayClassName={classnames.tooltip}
									placement="top"
									trigger="hover"
									overlay={title}
								>
									<span className={classnames.title}>
										{title.slice(0, 43) + "..."}
									</span>
								</Tooltip>
							) : (
								<span className={classnames.title}>{title}</span>
							);

						const linkTo = get(item, "linked_to.entity", undefined);

						return {
							title: renderedTitle,
							link_to: linkTo ? (
								<button
									className={classnames.linkToButton}
									onClick={e => redirectToEntity(e, item)}
								>
									<span className={classnames.linkTo}>{linkTo}</span>
								</button>
							) : (
								<EmptyStateLinkTo />
							),
							status: <Status status={get(item, "status", "")} />,
							due_date: <Date date={get(item, "due_date", "")} isHour />,
							priority: <Priority priority={get(item, "priority", "")} />,
							created_by: (
								<RenderUser
									name={fullName}
									avatar={get(item, "created_by.avatar")}
									jobTitle={get(item, "created_by.user_function", "")}
								/>
							),
							assigned_user: (
								<RenderUser
									name={assignedFullName}
									avatar={get(item, "assigned_user.avatar")}
									jobTitle={get(item, "assigned_user.user_function", "")}
									isExternal={get(item, "assigned_user.is_external", false)}
									isEmpty={
										!Boolean(Object.keys(get(item, "assigned_user", {})).length)
									}
								/>
							),
							creation_date: <Date date={get(item, "created_at", "")} />,
							action: (
								<Actions
									assignedUser={get(item, "assigned_user", {})}
									createdBy={get(item, "created_by", {})}
									userConnected={user}
									markAsComplete={() => showModalClick(id)}
									deleteTask={() => showModalDeleteClick(id)}
									status={status}
									isSaving={isSaving}
									isDeleting={isDeleting}
									id={id}
									dataTask={item}
									dueDate={get(item, "due_date", "")}
									setHighlitedRow={setHighlitedRow}
								/>
							)
						};
				  }),
		[isFetching, isSaving, isDeleting]
	);
	const total = get(resolvedData, "pagination.total", "");
	const size = get(resolvedData, "pagination.size", "");
	const handleRowClick = (row, index, fetchedData) => {
		browserHistory.push(`/tasks/${get(fetchedData[index], "_id")}`);
		queryCache.invalidateQueries(getTaskDetailsKey);
	};

	const debouncedChangeFilterAction = useRef(_debounce(updateSearch, 500));

	const handelSearchChange = value => {
		setSearchKeyword(value);
		debouncedChangeFilterAction.current(value);
		updateOffset(0);
	};

	return (
		<div className={classnames.container}>
			<div className={classnames.filterBox}>
				<FilterBox
					searchKeyword={searchKeyword}
					setSearchKeyword={handelSearchChange}
					isDescriptionSearch={isDescriptionSearch}
					isTitleSearch={isTitleSearch}
					updateFilters={updateFilters}
				/>
			</div>
			<div className={classnames.listTasks}>
				<Table
					data={data}
					isFetching={isFetching}
					onRowClick={handleRowClick}
					fetchedData={get(resolvedData, "tasks", [])}
					sortedField={field}
					highlitedRow={highlitedRow}
				/>
			</div>
			{get(resolvedData, "tasks.length", 0) > 0 && (
				<div className={classnames.paginationContainer}>
					<DisplayResults
						resolvedData={resolvedData}
						isFetching={isFetching}
						updateOffset={updateOffset}
						offset={offset}
						setLimit={setLimit}
						limit={limit}
					/>

					<PaginationClient
						size={size}
						offset={offset}
						setOffset={updateOffset}
						total={total}
					/>
				</div>
			)}
			{taskID && (
				<TaskDetailsDrawer
					id={taskID}
					isOpenDetailsDrawer={isOpenDetailsDrawer}
					setIsOpenDetailsDrawer={setIsOpenDetailsDrawer}
					openModal={() => showModalClick(taskID)}
					isSaving={isSaving}
					backLink={getListTasksRoute}
					userConnected={user}
				/>
			)}
			{showModalDelete && (
				<ConfirmationModalAction
					onClose={closeModal}
					onClick={deleteTaskBtn}
					modalName={"delete-task"}
					type={"warning"}
					title={`Are you sure you want to delete task?`}
					textAction={"Yes, delete this task"}
					typeAction={"warning"}
					loading={isDeleting}
				/>
			)}
			{showModalCompleted && (
				<ConfirmationModalAction
					onClose={closeModal}
					onClick={markAsCompletedBtn}
					modalName={"mark-as-resolved-task"}
					title={`Are you sure you want to mark task as completed?`}
					type={"success"}
					textAction={"Yes, mark as completed"}
					loading={isSaving}
					typeAction={"success"}
				/>
			)}
		</div>
	);
};

export default Tasks;
