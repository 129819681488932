import React from "react";
import { Box } from "rebass";
import _merge from "lodash/merge";

import { keyframes } from "@emotion/core";

const rotate = keyframes`
   0% { transform: rotate(0deg) }
  100% { transform: rotate(1turn) }
`;

const Spinner = React.forwardRef(function Spinner({ sx, ...props }, ref) {
	return (
		<Box
			ref={ref}
			as="span"
			sx={_merge(
				{
					display: "inline-block",
					border: "2px solid",
					borderRadius: 9999,
					animation: `${props.duration ?? "0.8"}s infinite ${rotate}`,
					animationTimingFunction: "linear",
					borderColor: props.trackColor ?? "#ccc",
					borderLeftColor: props.color ?? "black",
					width: 32,
					height: 32
				},
				sx
			)}
			{...props}
		/>
	);
});

export default Spinner;
