import React, { Component } from "react";
import _get from "lodash/get";
import {
	EUR_PER_HOUR,
	EUR_PER_DAY,
	DATE_FORMAT,
	CURRENCY_SUFFIX
} from "config";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { createMarkup } from "config/helpers";
import AttachedFiles from "common/AttachedFiles";

export default class SecondStep extends Component {
	render() {
		const {
			data,
			bid,
			goBackToFirstStep,
			createBid,
			isConsultancy,
			files,
			reportingSetting
		} = this.props;
		const suffix = {
			hourly: EUR_PER_HOUR,
			daily: EUR_PER_DAY,
			label: {
				hourly: "Hourly",
				daily: "Daily"
			}
		};

		return (
			<div className="second-step">
				<div className="grey-box">
					<div className="text-notif">{data.message}</div>
					{isConsultancy && (
						<React.Fragment>
							<div className="wrap">
								<b>Business unit:</b>
								<p>
									{_get(bid, "business_unit.label", _get(bid, "business_unit"))}
								</p>
							</div>
							<div className="wrap">
								<b>Consultant:</b>
								<p>{bid.consultant.label}</p>
							</div>
						</React.Fragment>
					)}
					<div className="wrap">
						<b>{isConsultancy ? "Available from:" : "I’m available from:"}</b>
						<p>{bid.available_date.format(DATE_FORMAT)}</p>
					</div>
					<div className="wrap">
						<b>
							{isConsultancy
								? `${suffix.label[_get(reportingSetting, "rate_type")]} rate:`
								: "My rate:"}
						</b>
						<p>
							<CurrencyFormatterRender
								isCent
								value={bid.proposed_amount}
								suffix={CURRENCY_SUFFIX[_get(reportingSetting, "rate_type")]}
								currency={_get(reportingSetting, "currency.code")}
							/>
						</p>
					</div>
					<div className="wrap">
						<b>{isConsultancy ? "Motivation" : "My motivation"}:</b>
						<div dangerouslySetInnerHTML={createMarkup(bid.description)} />
					</div>
					{files.length > 0 ? (
						<div className="wrap">
							<AttachedFiles files={files} preview={true} />
						</div>
					) : (
						<></>
					)}
				</div>
				<div className="actions second">
					<div className="row">
						<div className="col-md-3">
							<button
								className="btn btn-outline-new btn-block"
								onClick={goBackToFirstStep}
							>
								Edit
							</button>
						</div>
						<div className="col-md-4">
							<button
								className="btn btn-primary-new btn-block"
								onClick={createBid}
							>
								Send
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
