import React from "react";

const InvoiceIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<path
				className="notification-icon"
				d="M322.5,129.8L298,111.3L274.4,129L249.8,108l-24,21.3l-24.3-21.2l-24.3,20.7l-24-19.3v260.8h193.2V109.6
		L322.5,129.8z M322.5,129.8"
			/>
			<g>
				<path
					className="notification-icon-origin"
					d="M365.8,394.6H133.9c-2.7,0-4.8-2.2-4.8-4.8v-58c0-2.7,2.2-4.8,4.8-4.8s4.8,2.2,4.8,4.8v53.1h222.2v-53.1
			c0-2.7,2.2-4.8,4.8-4.8c2.7,0,4.8,2.2,4.8,4.8v58C370.6,392.4,368.4,394.6,365.8,394.6L365.8,394.6z M365.8,394.6"
				/>
				<path
					className="notification-icon-origin"
					d="M351.1,109c0-0.3-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.9c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.1-0.1-0.1-0.2-0.2
			c-0.2-0.2-0.5-0.5-0.8-0.7c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.6-0.2-1-0.2c-0.1,0-0.2,0-0.3,0
			c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0-0.7,0.1-1,0.2c-0.3,0.1-0.6,0.3-0.9,0.5c-0.2,0.1-0.4,0.2-0.5,0.3l-21.2,16.9l-21.2-16.9
			c-1.8-1.4-4.3-1.4-6,0l-21.2,16.9l-21.1-16.9c-1.8-1.4-4.3-1.4-6,0l-21.1,16.9l-21.1-16.9c-1.8-1.4-4.3-1.4-6,0l-21.1,16.9
			l-21.1-16.9c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.5c-0.3-0.1-0.6-0.2-1-0.2c-0.2,0-0.4-0.1-0.6-0.1
			c-0.1,0-0.2,0-0.3,0.1c-0.3,0-0.7,0.1-1,0.2c-0.3,0.1-0.6,0.2-0.8,0.3c-0.3,0.1-0.5,0.3-0.7,0.5c-0.3,0.2-0.5,0.4-0.8,0.7
			c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.2,0.6-0.2,1c-0.1,0.2-0.1,0.4-0.1,0.6
			v260.8c0,2.7,2.2,4.8,4.8,4.8h193.2c2.7,0,4.8-2.2,4.8-4.8V109.6C351.2,109.4,351.2,109.2,351.1,109L351.1,109z M158.1,365.6v-246
			l16.3,13c1.8,1.4,4.3,1.4,6,0l21.1-16.9l21.1,16.9c1.8,1.4,4.3,1.4,6,0l21.1-16.9l21.1,16.9c1.8,1.4,4.3,1.4,6,0l21.1-16.9
			l21.2,16.9c1.8,1.4,4.3,1.4,6,0l16.3-13v246H158.1z M158.1,365.6"
				/>
			</g>
			<path
				className="notification-icon-origin"
				d="M201.5,283.5h-19.3c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8h19.3c2.7,0,4.8,2.2,4.8,4.8
		C206.4,281.3,204.2,283.5,201.5,283.5L201.5,283.5z M201.5,283.5"
			/>
			<path
				className="notification-icon-origin"
				d="M317.5,283.5h-96.6c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8h96.6c2.7,0,4.8,2.2,4.8,4.8
		C322.3,281.3,320.1,283.5,317.5,283.5L317.5,283.5z M317.5,283.5"
			/>
			<path
				className="notification-icon-origin"
				d="M201.5,254.5h-19.3c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8h19.3c2.7,0,4.8,2.2,4.8,4.8
		C206.4,252.3,204.2,254.5,201.5,254.5L201.5,254.5z M201.5,254.5"
			/>
			<path
				className="notification-icon-origin"
				d="M317.5,254.5h-96.6c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8h96.6c2.7,0,4.8,2.2,4.8,4.8
		C322.3,252.3,320.1,254.5,317.5,254.5L317.5,254.5z M317.5,254.5"
			/>
			<path
				className="notification-icon-origin"
				d="M201.5,225.5h-19.3c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8h19.3c2.7,0,4.8,2.2,4.8,4.8
		C206.4,223.4,204.2,225.5,201.5,225.5L201.5,225.5z M201.5,225.5"
			/>
			<path
				className="notification-icon-origin"
				d="M317.5,225.5h-96.6c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8h96.6c2.7,0,4.8,2.2,4.8,4.8
		C322.3,223.4,320.1,225.5,317.5,225.5L317.5,225.5z M317.5,225.5"
			/>
		</g>
	</svg>
);

export default InvoiceIcon;
