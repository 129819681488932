import React from "react";
import Pagination from "common/PaginationClient";
import { PaginationWrapper } from "./styles";

const PaginationClient = ({ total, size, offset, setOffset }) => {
	const handlePageClick = page => {
		setOffset(page * size);
	};

	return (
		total > size && (
			<PaginationWrapper>
				<Pagination
					total={total}
					size={size}
					offset={offset}
					handlePageClick={handlePageClick}
					forcePage={Math.ceil(offset / size)}
					pageRangeDisplayed={0}
					marginPagesDisplayed={0}
				/>
			</PaginationWrapper>
		)
	);
};

export default PaginationClient;
