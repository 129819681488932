import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

const TAG = "list_count_welcome_page";

const listCount = () =>
	client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: TAG
		}
	});

export const listCountKey = "@home/list_count";

const useListCount = (options, queryOptions = {}) =>
	useQuery([listCountKey, options], listCount, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default useListCount;
