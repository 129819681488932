import { useQuery } from "react-query";
import { getUserCountryData } from "config/helpers";

export const GET_COUNTRY_DATA_QUERY_KEY = "GET_COUNTRY_DATA_QUERY_KEY";

export const useGetCountryData = (options = {}) => {
	const res = useQuery(GET_COUNTRY_DATA_QUERY_KEY, getUserCountryData, {
		...options,
		refetchOnWindowFocus: false,
		retry: false
	});
	return res;
};
