import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import BodyClassName from "react-body-classname";
import _get from "lodash/get";
import _remove from "lodash/remove";
import styled from "styled-components";
import { browserHistory } from "react-router";
import { MAX_LANGUAGE_WEIGHT, ADMIN, VACANCY } from "config";
//components
import MenuWithCTA from "common/MenuWithCTA";
import { getGlobalOptions } from "../../selectors";
import LeaveHook from "common/LeaveHook";
import { getCreateVacancyRoute } from "../CreateJob";
import EditableList from "common/editablelist/EditableList";
import { getLanguageTooltip } from "config/helpers";
import { Button, ButtonsContainer, OutlinedLink } from "common/styled/buttons";
import Icon from "common/styled/icons";
import GlobalTooltip from "common/GlobalTooltip";
import SkillsModal from "../SkillsModal";
import { scrollToFirstError } from "common/Functions";
import FunctionsContainer from "./Functions/FunctionsContainer";
import SkillsSection from "./Skills";

const CTA = styled(Button)`
	padding: 10px 35px;
	letter-spacing: 2px;
	font-weight: bold;
`;

const CTAIcon = styled(Icon)`
	position: absolute;
	right: -3px;
	top: 7px;
	font-size: 10px !important;
`;

class JobProfile extends PureComponent {
	constructor() {
		super();
		this.state = {
			showErrors: {},
			showSkillsModal: false
		};
	}

	componentDidUpdate({ formFields }) {
		const { subcategory, category } = formFields;
		const newCategory = _get(this.props, "formFields.category");
		const newSubcategory = _get(this.props, "formFields.subcategory");
		if (newCategory && newCategory !== category) {
			this.loadSkills(newCategory);
		}
		if (newSubcategory && newSubcategory !== subcategory) {
			this.getFunctions({ value: newSubcategory._id || newSubcategory.value });
		}
	}

	getFunctions = ({ value }) => {
		if (!value) return;
		const data = {
			sector_id: value,
			no_limit: true,
			offset: 0
		};
		this.props.getFunctions(data);
	};

	onSubmitJob = (formProps = {}) => {
		const { createJob, functions, params } = this.props;
		const data = JSON.parse(JSON.stringify(this.props.data));
		const payload = {
			functions: functions.map(f => ({
				is_main: f.is_main,
				name: f.name,
				sector_id: f.sector._id,
				seniority: f.seniority,
				_id: f._id
			})),
			languages: data.languages,
			skills: data.skills.map(s => {
				delete s.status;
				s.must_have = s.must_have || false;
				return s;
			}),
			id: data.id || data._id,
			step: 1,
			group_id: formProps.group_id.value,
			job_type: VACANCY
		};
		return createJob(payload, !!params.id);
	};

	loadSkills = ({ sector, term }) => {
		if (!sector) return;
		this.props.getSkills({
			sector_id: sector,
			limit: 150,
			offset: 0,
			term
		});
	};

	openSkillsModal = () => {
		this.setState({ showSkillsModal: true });
	};

	addSkills = skills => {
		skills && this.props.setJobDataField(skills, "skills");
		this.setState({ showSkillsModal: false });
	};

	handleRemoveSkills = sectors => {
		const { data } = this.props;
		const skills = data.skills.filter(
			skill => sectors.indexOf(skill.parent_sector.name) === -1
		);
		this.props.setJobDataField(skills, "skills");
	};

	addLanguage = language => {
		language &&
			this.props.setJobDataField(
				[
					...this.props.data.languages,
					{ score: 1, name: language.label, _id: language._id }
				],
				"languages"
			);
	};

	removeLanguage = language => {
		const languages = [...this.props.data.languages];
		_remove(languages, option => option._id === language._id);
		this.props.setJobDataField(languages, "languages");
		if (!_get(languages, "length")) this.props.changeReduxForm("languages", "");
	};

	handleLanguageScoreChange = (language, score) => {
		this.props.setScore(language, score, "languages");
	};

	handleFunctionsSave = functions => {
		this.props.setFunctions(functions);
	};

	handleProposeSkill = e => {
		e.preventDefault();
		const { formFields, togglePropose } = this.props;
		togglePropose(_get(formFields, "category._id"), this.state.term);
	};

	getListRoute = () => {
		return "/vacancies/list";
	};

	render() {
		const {
			handleSubmit,
			submitting,
			data,
			functions = [],
			languages = [],
			skills = [],
			categories,
			dirty,
			connectedUser: { active_role },
			submitFailed
		} = this.props;

		const skillsError = submitFailed && _get(data, "skills.length") === 0;
		const functionsError = submitFailed && _get(functions, "length", 0) === 0;
		const groups = JSON.parse(JSON.stringify(this.props.groups));
		const { showSkillsModal } = this.state;
		const isDirty =
			dirty || _get(functions, "length") > 0 || _get(data, "skills.length") > 0;
		const departmentTooltip =
			"Please choose the department for which you are hiring. The users of the selected department will be able to see and interact with candidates for this vacancy (poke candidates, add notes on applications, etc..).";
		const skillsTooltip = `The skills are an essential part of the
					 candidate/vacancy matching process. Be as
					 precise as possible (without being too
					 demanding) in the selection of the skills
					 needed and the weight/importance they
					 have for the role.`;

		return (
			<BodyClassName className="job-term">
				<div style={{ padding: "20px 15px" }}>
					{showSkillsModal && (
						<SkillsModal
							loadSkills={this.loadSkills}
							skills={skills}
							initialSkills={data.skills}
							sector={this.props.formFields.category}
							onSave={this.addSkills}
							contentHeight={84}
							closeModal={() => this.setState({ showSkillsModal: false })}
							isRequest={false}
							showMustHave={true}
							sectors={categories}
							categories={functions}
						/>
					)}
					<LeaveHook dirty={isDirty} route={getCreateVacancyRoute()}>
						<div className="row">
							<div className="col-md-5 col-sm-12">
								<Field
									name="group_id"
									label="Department"
									options={getGlobalOptions(groups, "_id")}
									position={false}
									searchable={true}
									clearable={false}
									component={MenuWithCTA}
									cta={
										active_role !== ADMIN
											? null
											: () => (
													<CTA
														size={14}
														small
														large
														onClick={() =>
															browserHistory.push("/settings/users")
														}
													>
														Create a new department
														<CTAIcon className="icon-newtab-arrow" />
													</CTA>
											  )
									}
									toolTipOverlay={departmentTooltip}
								/>
							</div>

							<div className="col-md-9">
								<FunctionsContainer
									functions={functions}
									onSave={this.handleFunctionsSave}
									sectors={categories}
									limit={5}
									skillsCategories={[
										...new Set(
											data.skills
												.map(s => _get(s, "parent_sector._id", undefined))
												.filter(Boolean)
										)
									]}
									removeSkills={this.handleRemoveSkills}
									hasError={functionsError}
								/>
								<div className="row">
									<div className="col-md-11">
										<div className="help-block inline-error">
											{functionsError && "Value is required and can't be empty"}
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-9 col-sm-12">
								<div
									className={
										skillsError
											? "section action-hover skillsError"
											: "section action-hover"
									}
									style={{ minHeight: 180 }}
								>
									{_get(data, "skills.length") > 0 && (
										<div className="section-edit pull-right">
											<button
												className="btn btn-sm"
												onClick={this.openSkillsModal}
											>
												<i className="fa fa-pencil" />
											</button>
										</div>
									)}
									<div className="section-title">
										<label>
											Skills <GlobalTooltip overlay={skillsTooltip} />
										</label>
									</div>
									<SkillsSection data={data.skills} />
									{!_get(data, "skills.length") && (
										<div className="empty-section">
											<div
												className={`add btn-center-block ${
													_get(functions, "length") === 0
														? `disabled-button`
														: ""
												}`}
												onClick={
													_get(functions, "length") > 0
														? this.openSkillsModal
														: null
												}
											>
												<i className="fa fa-plus" /> Add skills
											</div>
										</div>
									)}
								</div>

								<div className="row">
									<div className="col-md-11">
										<div className="help-block inline-error">
											{skillsError && "Value is required and can't be empty"}
										</div>
									</div>
								</div>
								<div className="form-group selectable-control dropdown-profile create-vacancy-languages">
									<Field
										name="languages"
										label="Languages"
										options={languages}
										simpleValue={false}
										position={false}
										itemsLabel="language"
										scorePath="score"
										idPath="_id"
										labelName="name"
										component={EditableList}
										maxScore={MAX_LANGUAGE_WEIGHT}
										onRowChange={this.addLanguage}
										removeSelected={this.removeLanguage}
										tooltipFormater={getLanguageTooltip}
										scoreLabel="PROFICIENCY"
										handleScoreChange={this.handleLanguageScoreChange}
										selectedOptions={data.languages}
										onSelectResetsInput={true}
										placeholder="Begin typing the language you would like to add, then select the closest match from the drop-down list."
										toolTipOverlay="Languages are another fundamental part of the matching process.
										Although they are weighted less than skills in the matching score calculation,
										it is still important to properly outline your linguistic skills."
									/>
								</div>
							</div>
							<div className="col-xs-12">
								<div className="job-action">
									<div className="pull-right">
										<ButtonsContainer>
											<OutlinedLink
												large
												has-icon
												size={14}
												to={this.getListRoute()}
											>
												<Icon moveOnHover className="icon-arrow-left" />
												Back
											</OutlinedLink>
											<Button
												bordered
												large
												size={14}
												disabled={submitting}
												onClick={handleSubmit(this.onSubmitJob)}
											>
												Next step
											</Button>
										</ButtonsContainer>
									</div>
								</div>
							</div>
						</div>
					</LeaveHook>
				</div>
			</BodyClassName>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.group_id) errors.group_id = "Please select a department.";
	if (formProps.category) {
		if (!formProps.function) errors.function = "Please select a function.";
		if (!formProps.seniority) errors.seniority = "Please select a seniority.";
		if (!formProps.skills) errors.skills = "Please select at least one skill.";
		if (!formProps.subcategory) {
			errors.seniority = "Please begin by selecting a subcategory.";
			errors.function = "Please begin by selecting a subcategory.";
			errors.subcategory = "Please select a subcategory.";
		}
	} else {
		errors.category = "Please select a category.";
		errors.subcategory = "Please begin by selecting a category.";
		errors.function = "Please begin by selecting a category.";
		errors.seniority = "Please begin by selecting a category.";
		errors.skills = "Please begin by selecting a category.";
	}

	if (!formProps.languages)
		errors.languages = "Please select at least one language.";

	return errors;
};

export { CTA, CTAIcon };

export default reduxForm({
	form: "createJobTerm",
	validate,
	touchOnBlur: false,
	onSubmitFail: errors => scrollToFirstError(errors)
})(JobProfile);
