import Pusher from "pusher-js";
import { API_URL, CHANNEL, PUSHER_CLUSTER, PUSHER_KEY } from "config";
import { NOTIFICATIONS_AUTH } from "config/api-endpoints";

const PusherManager = {
	instance: null,
	events: [],
	logout: () => {},
	subscribe: function(id, access_token, events = []) {
		const channelName = `${CHANNEL}-${id}`;
		this.instance = new Pusher(PUSHER_KEY, {
			cluster: PUSHER_CLUSTER,
			authEndpoint: `${API_URL}${NOTIFICATIONS_AUTH}`,
			auth: {
				headers: {
					Authorization: `Bearer ${access_token}`
				}
			}
		});
		const channel = this.instance.subscribe(channelName);
		this.logout = () => {
			this.events = [];
			this.instance.unsubscribe(channelName);
			this.instance.disconnect();
		};
		this.events = events;
		channel.bind_global(this.handlePusherEvent.bind(this));
	},
	handlePusherEvent: function(event, data) {
		this.events.forEach(evt => {
			if (
				evt.name === event ||
				evt.name.includes(event) ||
				evt.name === data.event ||
				evt.name.includes(data.event)
			) {
				evt.cb(data);
			}
		});
	},
	unsubscribe: function() {
		if (this.instance) {
			this.logout();
		}
	}
};

export default PusherManager;
