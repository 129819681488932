import React, { Component } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import { browserHistory } from "react-router";
import { CLIENT, APPROVED, LIVE, DESC, START_DATE } from "config";
import CompanyDashboard from "common/dashboard/Dashboard";
import Intro from "common/dashboard/Intro";
import EmptyTimesheets from "common/EmptyComponent";
import SortListJob from "./SortTimesheetList";
import { getLocalUser } from "config/helpers";
import FreelancerList from "./FreelancerList";
import ClientList from "./ClientList";
import { getTimesheetList } from "../actions";
import { CONSULTANCY } from "config";

export const getListTimesheetsRoute = () => {
	return "/reporting/timesheets";
};

export const getListTimesheetsPermission = () => {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "client",
				roles: ["admin", "project_manager"]
			},
			{
				name: "consultancy",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

class ListTimesheets extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userType: getLocalUser().company_type,
			data: {
				status: LIVE,
				field: START_DATE,
				sort: DESC
			}
		};
	}

	componentDidMount() {
		this.getTimesheetList();
	}

	getTimesheetList = () => {
		this.props.getTimesheetList(this.state.data);
	};

	handleDirection = e => {
		const data = this.state.data;
		data.sort = e.target.value;
		this.setState(
			{
				data
			},
			() => {
				this.getTimesheetList();
			}
		);
	};

	handleField = e => {
		const data = this.state.data;
		data.field = e.target.value;
		this.setState(
			{
				data
			},
			() => {
				this.getTimesheetList();
			}
		);
	};

	switchView = view => {
		window.location.hash = view;
		this.setState(
			{
				data: {
					status: view,
					field: START_DATE,
					sort: DESC
				}
			},
			() => {
				this.getTimesheetList();
			}
		);
	};

	viewDetails = timesheet => {
		browserHistory.push(`/timesheet/${timesheet.job._id}/${timesheet._id}`);
	};

	render() {
		const {
			userType,
			data: { status }
		} = this.state;
		const currentView = window.location.hash
			? window.location.hash.substring(1)
			: status;
		const {
			route: { title },
			timesheetList
		} = this.props;
		const isNotEmpty = timesheetList.length > 0;
		const isClient = userType === CLIENT;
		const subTitle = isClient
			? ""
			: "In here, you’ll find the time and expense sheets that you need to fill in order to be able to invoice your work at the end of the month. Each time and expense sheet is available for edition & submission during the whole month.";

		const ListComponent = isClient ? ClientList : FreelancerList;
		return (
			<BodyClassName className="list-timesheets gray-bg">
				<div id="timesheets">
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<div className="dashboard-box">
						<CompanyDashboard />

						<Intro
							icon="fa fa-file-text-o"
							title="Timesheets"
							subTitle={subTitle}
						/>
					</div>
					<div className="container">
						<div className="opened-jobs">
							<div className="switcher">
								<div
									className={currentView === LIVE ? "btn btn-primary" : "btn"}
									onClick={() => this.switchView(LIVE)}
								>
									LIVE
								</div>
								<div
									className={
										currentView === APPROVED ? "btn btn-primary" : "btn"
									}
									onClick={() => this.switchView(APPROVED)}
								>
									{APPROVED}
								</div>
							</div>

							<SortListJob
								data={this.state.data}
								view={currentView}
								isClient={isClient}
								handleField={this.handleField}
								handleDirection={this.handleDirection}
							/>
						</div>
						<div>
							{isNotEmpty ? (
								<ListComponent
									view={currentView}
									timesheets={timesheetList}
									viewDetails={this.viewDetails}
									isConsultancy={userType === CONSULTANCY}
								/>
							) : (
								<EmptyTimesheets
									title={
										currentView === LIVE
											? "No timesheet to process"
											: "No Approved Timesheet Available"
									}
								/>
							)}
						</div>
					</div>
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => ({
	timesheetList: _get(state, "timesheet.timesheetList")
});

const mapDispatchToProps = dispatch => ({
	getTimesheetList: data => dispatch(getTimesheetList(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ListTimesheets);
