import { useEffect } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import {
	MAP_TYPE_COMPONENT,
	MAP_TYPE_OPERATORS,
	TASKS_LIST_FILTER_ID,
	TASKS_LIST_MODULE_ID,
	TYPES,
	TASKS_LIST_PAGE_SIZE
} from "../utils/constant";
import { COLUMNS } from "../utils/fieldsList";

export const useInitializeTasksFilterDrawer = () => {
	const { setFilterSetting } = useFilters();

	useEffect(() => {
		setFilterSetting(
			TYPES,
			MAP_TYPE_OPERATORS,
			MAP_TYPE_COMPONENT,
			{},
			null,
			COLUMNS,
			TASKS_LIST_FILTER_ID,
			TASKS_LIST_MODULE_ID,
			true,
			{},
			TASKS_LIST_PAGE_SIZE
		);
	}, []);
};
