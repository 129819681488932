import React, { useState } from "react";
import BidDetailsNote from "./BidDetailsNote";
import styles from "./bid-details-notes.module.scss";
import useUserNote from "../../hooks/useUserNote";
import BidDetailsEmptyNote from "../BidDetailsEmptyNote";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { ReactComponent as Back } from "static/images/notes/goBack.svg";
import { BidDetailsDrawerTempNotesLoading } from "modules/notes/notes-list/components/BidDetailsDrawerTempNotesLoading";

export default function BidDetailsNotesTab({ id }) {
	const { data: notesList, isLoading } = useUserNote(id);
	const [noteToExpand, setNoteToExpand] = useState("");

	const handleExpandClick = note => {
		setNoteToExpand(note);
	};

	const onBackClick = () => {
		setNoteToExpand("");
	};
	if (isLoading || !id) {
		return <BidDetailsDrawerTempNotesLoading />;
	}

	if (noteToExpand) {
		return (
			<div className={styles.notes}>
				<HeaderButton
					icon={<Back />}
					text="Back"
					buttonClassName={styles.backButton}
					onClick={onBackClick}
				/>
				<BidDetailsNote showText note={noteToExpand} />
			</div>
		);
	}

	if (notesList && Array.isArray(notesList.data) && notesList.data.length > 0)
		return (
			<div className={styles.notes}>
				{notesList?.data?.map(note => (
					<BidDetailsNote
						onClick={() => handleExpandClick(note)}
						note={note}
						key={note._id}
					/>
				))}
			</div>
		);

	return (
		<div className={styles.notes}>
			<BidDetailsEmptyNote />
		</div>
	);
}
