import React, { useMemo } from "react";
import get from "lodash/get";
import { queryCache } from "react-query";
import _map from "lodash/map";
import { listCountKey } from "modules/home/api/useListCount.js";
import useSuppliersStore from "modules/home/zustand/SuppliersStore";
import useMySuppliers from "modules/home/api/UseMySuppliers";
import { Table } from "modules/home/components";
import SuppliersEmptyState from "modules/home/components/emptyState/SuppliersEmptyState";
import Loader from "common/Loader";
import style from "./styles.module.scss";
import { MY_SUPPLIERS } from "modules/home/config";
import { historyPush } from "config/helpers";
import { WELCOME_PAGE } from "config";
import { ReactComponent as Plus } from "modules/home/svg/Plus.svg";

const MySuppliers = ({ handleViewAll }) => {
	const {
		suppliersStartDate,
		suppliersEndDate,
		suppliersType,
		setItemSuppliers
	} = useSuppliersStore();
	const { data, isLoading } = useMySuppliers(
		{
			start_date:
				suppliersStartDate && suppliersStartDate.isValid()
					? suppliersStartDate.unix()
					: undefined,
			end_date:
				suppliersEndDate && suppliersEndDate.isValid()
					? suppliersEndDate.unix()
					: undefined
		},
		{
			enabled: suppliersType === MY_SUPPLIERS,
			onSuccess: res => {
				setItemSuppliers(
					MY_SUPPLIERS,
					_map(res || [], item => item._id)
				);
				queryCache.invalidateQueries(listCountKey);
			}
		}
	);
	const mySuppliersColumns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "supplier_name"
			},
			{
				Header: "Consultants on mission",
				accessor: "total_consultants"
			}
		],
		[]
	);
	const clickOnRow = row => {
		historyPush(
			`/supplier/${row?._id}?source=${WELCOME_PAGE}&list=applications&tab=1&listKey=${MY_SUPPLIERS}`
		);
	};
	const ClickOnES = () => {
		historyPush(`/supplier/added`);
	};
	if (isLoading)
		return (
			<div className={style["loader_container"]}>
				<Loader />{" "}
			</div>
		);
	if (!Boolean(get(data, "length")))
		return (
			<div className={style["loader_container"]}>
				{" "}
				<SuppliersEmptyState
					heading={"You have no suppliers added recently!"}
					onClick={ClickOnES}
					label={"Add new supplier"}
					icon={<Plus />}
				/>
			</div>
		);

	return (
		<Table
			data={data}
			columns={mySuppliersColumns}
			onRowClick={clickOnRow}
			handleViewAll={handleViewAll}
		/>
	);
};

export default MySuppliers;
