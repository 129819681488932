import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _forEach from "lodash/forEach";
import { object, string } from "yup";
import _isObject from "lodash/isObject";
import { Dialog, DialogClose, DialogContent, DialogTitle } from "./Dialog";
import s from "./create-supplier.module.scss";
import useCreateNewSupplier from "../../api/useCreateNewSupplier";
// import useCheckActivateAccount from "../../api/useCheckActivateAccount";

const schema = object().shape({
	email: string()
		.email("Please enter a valid email.")
		.required("This field is required.")
});

function CreateNewSupplier({ onClose, isOpen }) {
	const [createSupplier, { isLoading, error }] = useCreateNewSupplier({
		onClose
	});
	const {
		handleSubmit,
		register,
		setError,
		formState: { errors }
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema)
	});

	// FIXME: finish after backend fix
	// const { data, isLoading: isChecking } = useCheckActivateAccount({
	// 	email: watch("email")
	// });

	const onSubmit = async ({ email }) => {
		await createSupplier({ email });
	};

	useEffect(() => {
		if (typeof error?.detail === "object") {
			_forEach(error?.detail, (err, key) => setError(key, { message: err[0] }));
		}
	}, [error]);

	return (
		<Dialog open={isOpen}>
			<DialogContent onEscapeKeyDown={onClose}>
				<DialogTitle>Create a new supplier</DialogTitle>
				<form className={s.form} onSubmit={handleSubmit(onSubmit)}>
					{error?.detail && !_isObject(error?.detail) ? (
						<div className={s.alert}>{error?.detail}</div>
					) : null}
					<label htmlFor="email">Supplier email address</label>
					<input
						className={s.input}
						id={"email"}
						type="email"
						{...register("email")}
					/>
					{errors?.["email"] ? (
						<span className={s.inputError}>{errors?.["email"].message}</span>
					) : null}
					<div className={s.dialogFooter}>
						<button
							disabled={isLoading}
							onClick={onClose}
							className={s.cancelButton}
						>
							Cancel
						</button>
						<button
							disabled={isLoading}
							type={"submit"}
							className={s.mainButton}
						>
							Create supplier
						</button>
					</div>
				</form>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default CreateNewSupplier;
