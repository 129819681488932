//TODO We remove the first PublishErrorModal
import React, { useState, useRef, memo } from "react";
import PropTypes from "prop-types";
import style from "./vacancy-actions.module.scss";
import {
	APPROVED,
	FILLED,
	DELETED,
	DRAFT,
	OFFERED,
	PUBLISHED,
	UNPUBLISHED
} from "config";
import useOnClickOutside from "hooks/useOnClickOutside";
import { ReactComponent as ArrowIcon } from "static/icons/chevron-down.svg";
import RequestViewActionsList from "../RequestViewHead/RequestViewActionsList";
import useShowAddNewPipelineBidStepDrawer from "modules/RequestView/hooks/useShowAddNewPipelineBidStepDrawer";
import { historyPush } from "config/helpers";
import { getPublishRequestRoute } from "modules/publishRequest";
import PublishRequestErrorModal from "modules/publishRequest/components/PublishRequestErrorModal";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

const RequestViewHeadPublishButton = ({ job, connectedUser }) => {
	const { status } = job;

	const { setShowAddNewStepDrawer } = useShowAddNewPipelineBidStepDrawer();
	const [menuAction, setMenuAction] = useState(false);
	const [, setShowMenu] = useState(false);
	const [showPublishErrorModal, setShowPublishErrorModal] = useState(false);
	const {
		setIsOpen: openDrawerTask,
		setEntity,
		setSelectedEntityOption
	} = useAddNewTaskStore();

	const containerRef = useRef(null);
	const menuActionRef = useRef(null);
	const refSubMenu = useRef(null);

	useOnClickOutside(containerRef, () => {
		setShowMenu(false);
	});
	useOnClickOutside(menuActionRef, event => {
		if (refSubMenu.current && refSubMenu.current.contains(event.target)) {
			return;
		}
		setMenuAction(false);
	});

	const canShowMoreActions = status !== DELETED && status !== FILLED;

	const onAddNewStepClick = () => {
		setShowAddNewStepDrawer(true);
	};

	const onRepublish = () => {
		historyPush(getPublishRequestRoute(job.id));
	};

	const isRequestPublishable = () => {
		const { site } = job;
		const { avatar, industries } = site;
		return !!avatar && industries.length > 0;
	};

	const publishRequest = () => {
		if (isRequestPublishable()) {
			historyPush(getPublishRequestRoute(job.id));
		} else {
			setShowPublishErrorModal(true);
		}
	};

	const getMainButton = status => {
		const {
			company_identity_status: idStatus,
			company_payment_status: paymentStatus,
			company_information_status: companyInformationStatus
		} = connectedUser;
		switch (status) {
			case PUBLISHED:
				return {
					text: "Republish",
					action: onRepublish,
					disabled: false,
					disabledText: ""
				};
			case DRAFT:
			case UNPUBLISHED:
				return {
					text: "Publish",
					action: publishRequest,
					disabled:
						idStatus !== APPROVED ||
						(paymentStatus !== APPROVED && paymentStatus !== OFFERED) ||
						companyInformationStatus !== APPROVED,
					disabledText:
						"You may not publish mission requests until your ID and fee payment have been approved by our support team."
				};
			default:
				return {
					text: status,
					action: null,
					disabled: true,
					disabledText: "Status undefined.."
				};
		}
	};

	return (
		<>
			<div className={style.root}>
				{canShowMoreActions && (
					<div ref={menuActionRef} className={style.actionsMenu}>
						<button
							onClick={() => setMenuAction(true)}
							className={style.moreActionBtn}
						>
							<div className={style.moreActionText}>More actions </div>
							<ArrowIcon />
						</button>
						{menuAction && (
							<RequestViewActionsList
								openDrawerTask={openDrawerTask}
								setEntity={setEntity}
								setSelectedEntityOption={setSelectedEntityOption}
								vacancy={job}
								refSubMenu={refSubMenu}
								onAddNewStepClick={onAddNewStepClick}
							/>
						)}
					</div>
				)}

				<div className={style.potentialButton}>
					{!!getMainButton(status).action && (
						<button onClick={getMainButton(status).action}>
							{getMainButton(status).text}
						</button>
					)}
				</div>
			</div>

			{showPublishErrorModal && (
				<PublishRequestErrorModal
					onClose={() => setShowPublishErrorModal(false)}
					jobId={job?.id}
					siteId={job?.site?._id}
					hasIndustry={job?.site?.industries?.length > 0}
					hasLogo={!!job?.site?.avatar}
					isDefault={!!job?.site?.is_default}
				/>
			)}
		</>
	);
};

RequestViewHeadPublishButton.defaultProps = {
	job: {}
};

RequestViewHeadPublishButton.propTypes = {
	style: PropTypes.object,
	isSticky: PropTypes.bool,
	job: PropTypes.object.isRequired
};

export default memo(RequestViewHeadPublishButton);
