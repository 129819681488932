import get from "lodash/get";
import React from "react";
import { Text, Flex, Box } from "rebass";
import BoxContainer from "../boxContainer";

import { ReactComponent as ResidenceIcon } from "static/icons/elements-icons-house.svg";
import { ReactComponent as SalaryIcon } from "static/icons/elements-icons-money-usd.svg";
import CurrencyFormatter from "common/CurrencyFormater";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const getAddress = preferred_location => {
	const street = get(preferred_location, "street", "");
	const number = get(preferred_location, "number", "");
	const zip = get(preferred_location, "zip", "");
	const city = get(preferred_location, "city", "");
	const country = get(preferred_location, "country", "");

	let composedStreetAndNumber = "";
	if (street || number)
		composedStreetAndNumber = `${number ? number + " " : ""}${street}, `;
	return `${composedStreetAndNumber}${city && city + ", "}${zip &&
		zip + ", "}${country && country}`;
};

const Preferences = ({
	preference,
	isDrag,
	setIsOpenModalTab,
	setActiveTab,
	isFetching
}) => {
	const renderResidence = (location = []) => {
		const residency = location.filter(l => !l.is_main);
		return (
			<>
				{get(residency[0], "city") || get(residency[0], "country") ? (
					<>
						{`${get(residency[0], "country")}${
							get(residency[0], "city") ? `, ${get(residency[0], "city")}` : ""
						}`}{" "}
						{`${residency.length > 1 ? `(+${residency.length - 1})` : ""}`}
					</>
				) : (
					"N/A"
				)}
			</>
		);
	};
	return (
		<BoxContainer
			setActiveTab={setActiveTab}
			setIsOpenModalTab={setIsOpenModalTab}
			isDrag={isDrag}
			title="Preferences"
			isFetching={isFetching}
		>
			{isFetching ? (
				<React.Fragment>
					<Flex mb={2} alignItems="center">
						<ResidenceIcon style={{ marginRight: "8px" }} />
						<Box>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={250} height={14} />
							</SkeletonTheme>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={170} height={14} />
							</SkeletonTheme>
						</Box>
					</Flex>
					<Flex mb={2} alignItems="center">
						<SalaryIcon style={{ marginRight: "8px" }} />
						<Box>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={170} height={14} />
							</SkeletonTheme>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={250} height={14} />
							</SkeletonTheme>
						</Box>
					</Flex>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Flex mb={2} alignItems="flex-start">
						<ResidenceIcon />
						<Box sx={{ marginLeft: "8px" }}>
							<Text mb={2} sx={{ fontSize: "16px", color: "#818a92" }}>
								Preferred locations
							</Text>

							<Text
								sx={{
									fontSize: "16px",
									color: "#212b36",
									fontWeight: "normal"
								}}
							>
								{renderResidence(get(preference, "preferred_location", []))}
							</Text>
						</Box>
					</Flex>
					<Flex alignItems="flex-start">
						<SalaryIcon />
						<Box sx={{ marginLeft: "8px" }}>
							<Text mb={2} sx={{ fontSize: "16px", color: "#818a92" }}>
								Salary
							</Text>

							<Text
								sx={{
									fontSize: "16px",
									color: "#212b36",
									textTransform: "capitalize"
								}}
							>
								{get(preference, "expected_salary") &&
								get(preference, "payment_time_expected") ? (
									<React.Fragment>
										<CurrencyFormatter
											cent={get(preference, "expected_salary")}
											symbol={" " + get(preference, "currency_expected")}
										/>{" "}
										- {get(preference, "payment_time_expected")}{" "}
									</React.Fragment>
								) : (
									"N/A"
								)}
							</Text>
						</Box>
					</Flex>
				</React.Fragment>
			)}
		</BoxContainer>
	);
};

export default Preferences;
