import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { getOrientation } from "get-orientation/browser";
import _get from "lodash/get";
import { useDropzone } from "react-dropzone";
import Modal from "common/modal";
import Tabs from "modules/company/components/avatar/Tabs";
import CropComponent from "modules/company/components/avatar/Crop";
import { getRotatedImage } from "modules/company/components/avatar/utils";
import { SmallImageContainer } from "modules/company/components/avatar/style";
import { SUPPORTED_SIZE } from "config";
import styles from "./styles.module.scss";
import Portal from "common/Portal";
import get from "lodash/get";
import toaster from "common/Toaster";
import { ReactComponent as CameraIcon } from "../../../../static/icons/camera.svg";
import { uploadToS3 } from "config/helpers";

const ORIENTATION_TO_ANGLE = {
	"3": 180,
	"6": 90,
	"8": -90
};

export default function ImageUploader({
	type,
	src,
	width = "98px",
	height = "98px",
	onChange = () => {},
	employerBranding,
	cropSize,
	aspect = 1
}) {
	const [openModal, setOpenModal] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [imageSrc, setImageSrc] = useState(null);
	const [uploadFile, setUploadFile] = useState(false);
	const [fileName, setFileName] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);

	const inputFile = useRef(null);

	const uploadImage = imageData => {
		onChange(get(imageData, "url", ""));
	};

	const onFileChange = async files => {
		if (files && files.length > 0) {
			const file = _get(files, "[0]");
			setFileName(_get(file, "name"));
			let imageDataUrl = await readFile(file);
			const orientation = await getOrientation(file);
			const rotation = ORIENTATION_TO_ANGLE[orientation];
			if (rotation) {
				imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
			}

			setImageSrc(imageDataUrl);
			setUploadFile(true);
		}
	};

	function readFile(file) {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	}

	const [upload] = useMutation(response =>
		uploadToS3({ response, file: croppedImage })
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: onFileChange,
		accept: "image/*",
		maxSize: SUPPORTED_SIZE,
		onDropRejected: file => {
			const errors = _get(file, "[0].errors", []);
			errors.forEach(error => {
				toaster.danger(_get(error, "message", ""), { isFailed: true });
			});
		}
	});
	const onClose = () => {
		setOpenModal(false);
		setImageSrc(null);
		setUploadFile(false);
		setActiveTab(0);
	};
	const renderImageSrc = () => {
		return Boolean(croppedImage) ? URL.createObjectURL(croppedImage) : src;
	};

	return (
		<div>
			<div className={styles.uploaderContainer}>
				{(src || croppedImage) && (
					<SmallImageContainer
						width={width}
						height={height}
						onClick={() => setOpenModal(true)}
					>
						<img
							alt={""}
							className="image"
							style={{ objectFit: "cover" }}
							src={!uploadFile ? src : renderImageSrc()}
						/>
						<CameraIcon className="icon-camera" />
						<div className="drop-shadow" />
					</SmallImageContainer>
				)}

				{!employerBranding && (
					<>
						{src || croppedImage ? null : (
							<button
								type="button"
								className={styles.uploadBtn}
								onClick={() => setOpenModal(true)}
							>
								<i className="fa fa-upload"></i>
								Upload image
							</button>
						)}
					</>
				)}
			</div>

			{openModal && (
				<Portal>
					<Modal
						fixed={true}
						onClose={onClose}
						modalName={"crop"}
						padding="0"
						size="900px"
					>
						{imageSrc ? (
							<CropComponent
								imageSrc={imageSrc}
								setImageSrc={setImageSrc}
								fileName={fileName}
								setOpenModal={setOpenModal}
								type={type}
								setCroppedImage={setCroppedImage}
								upload={upload}
								uploadImage={uploadImage}
								inputFile={inputFile}
								onFileChange={onFileChange}
								uploadFile={uploadFile}
								setUploadFile={setUploadFile}
								cropSize={cropSize}
								aspect={aspect}
							/>
						) : (
							<Tabs
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								setImageSrc={setImageSrc}
								setFileName={setFileName}
								getRootProps={getRootProps}
								getInputProps={getInputProps}
								imageSrc={imageSrc}
								isDragActive={isDragActive}
							/>
						)}
					</Modal>
				</Portal>
			)}
		</div>
	);
}
