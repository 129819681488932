import React, { PureComponent } from "react";
import { Transition, animated } from "react-spring/renderprops";
import styled from "styled-components";
import GlobalTooltip from "common/GlobalTooltip";

const DropDown = styled.li`
	position: relative;
	list-style: none;
	.fa {
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
	> ul {
		display: none;
		position: absolute;
		left: 100%;
		top: 0;
		background-color: #fff;
		width: 100%;
		list-style: none;
		padding: 0 0 0 10px;
		box-shadow: 0 0 7.4px 0.1px rgba(218, 224, 242, 0.48);
		border: solid 0.5px rgba(57, 64, 71, 0.1);
	}
	&:hover {
		> ul {
			display: block;
		}
	}
`;

class Menu extends PureComponent {
	menuRef = React.createRef();

	state = {
		isOpen: false
	};

	componentDidMount() {
		document.addEventListener("click", this.onClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.onClickOutside);
	}

	onClickOutside = evt => {
		if (!this.menuRef.current || this.menuRef.current.contains(evt.target)) {
			return;
		}
		this.setState({ isOpen: false });
	};

	toggleMenu = () => {
		this.setState(prevState => ({ isOpen: !prevState.isOpen }));
	};

	handleItemClick = item => {
		const { hideOnSelect, onSelect } = this.props;
		if (hideOnSelect) {
			this.setState({ isOpen: false });
		}
		onSelect(item);
	};

	renderItems = data => {
		if (!this.props.grouped) {
			return data.map((item, i) => (
				<li key={i} onClick={() => this.handleItemClick(item)}>
					{item.name}
				</li>
			));
		}
		const group = data.reduce((acc, value) => {
			(acc[value.group_name] = acc[value.group_name] || []).push(value);
			return acc;
		}, {});
		return Object.keys(group).map(key => {
			if (key) {
				return (
					<DropDown key={key}>
						{key} <i className="fa fa-chevron-right" />
						<ul className="sub-menu">
							{group[key].map(item => (
								<li key={item._id} onClick={() => this.handleItemClick(item)}>
									{item.name}
								</li>
							))}
						</ul>
					</DropDown>
				);
			}
			return group[key].map((item, i) => (
				<li key={i} onClick={() => this.handleItemClick(item)}>
					{item.name}
				</li>
			));
		});
	};

	render() {
		const {
			data,
			defaultText,
			isDisabled,
			overlayText,
			children,
			grouped
		} = this.props;
		const { isOpen } = this.state;
		return (
			<div ref={this.menuRef}>
				{children ? (
					children(isOpen, this.toggleMenu)
				) : (
					<React.Fragment>
						{!isDisabled ? (
							<div className="menu-trigger" onClick={this.toggleMenu}>
								{defaultText} <i className="fa fa-chevron-down" />
							</div>
						) : (
							<GlobalTooltip overlay={overlayText} noClassName={true}>
								<div className="menu-trigger disabled">
									{defaultText} <i className="fa fa-chevron-down" />
								</div>
							</GlobalTooltip>
						)}
						{data.length > 0 && (
							<Transition
								items={isOpen}
								from={{ maxHeight: 0, opacity: 0 }}
								enter={{ maxHeight: 300, opacity: 1 }}
								leave={{ maxHeight: 0, opacity: 0 }}
								config={{ duration: 220 }}
							>
								{show =>
									show &&
									(props => (
										<animated.div style={props} className={"menu-holder"}>
											<div className={`menu ${grouped ? "grouped" : ""}`}>
												<ul className="list-unstyled">
													{this.renderItems(data)}
												</ul>
											</div>
										</animated.div>
									))
								}
							</Transition>
						)}
					</React.Fragment>
				)}
			</div>
		);
	}
}

Menu.defaultProps = {
	hideOnSelect: true,
	onSelect: () => ({}),
	defaultText: "Select...",
	isDisabled: false,
	overlayText: "This item is disabled"
};

export default Menu;
