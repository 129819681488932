import React from "react";
import folder from "static/images/folder.jpg";
import { EmptySearchContainer } from "./Styled";

const EmptySearch = () => (
	<EmptySearchContainer>
		<img alt={"No results found"} src={folder} />
		<div className="title">No result found</div>
		<div className="paragraph">
			There is no job matching your search criteria.
		</div>
	</EmptySearchContainer>
);
export default EmptySearch;
