import React, { useEffect, useRef, useState } from "react";
import CandidatePreferences from "./CandidatePreferences";
import useViewCandidateInternal from "modules/candidate/Profile/api/useViewCandidateInternal";
import _get from "lodash/get";
import Languages from "modules/candidate/Profile/Pages/Profile/components/Languages/Languages";
import s from "../profile.module.scss";
import Functions from "modules/candidate/Profile/Pages/Profile/components/Functions";
import { getFunctionsData } from "modules/candidate/Profile/utils/helper";
import PersonalProfile from "./PersonalProfile";
import Skills from "modules/candidate/Profile/Pages/Profile/components/Skills";
import ProfileTags from "./ProfileTags";
import Loader from "common/Loader";

import PrimaryCV from "./PrimaryCV";

const InternalData = ({
	id,
	viewFilesMenuItemClicked,
	viewProfileButtonClicked,
	addProfileToPoolButtonClicked,
	isLoadingAddProfile
}) => {
	const personalProfileSection = useRef(null);
	const primaryCV = useRef(null);
	const candidatePreferencesSection = useRef(null);
	const functionsSection = useRef(null);
	const skillsSection = useRef(null);
	const profileTagsSection = useRef(null);
	const languagesSection = useRef(null);

	const [personalProfile, setPersonalProfile] = useState();
	const [candidatePreferences, setCandidatePreferences] = useState();

	const { data, isLoading, invalidate } = useViewCandidateInternal(
		{ id },
		{
			onSuccess: data => {
				const personalProfile = _get(data, "personal_profile");

				// we create new object in order to reexecute useEffect in PersonalProfile and CandidatePreferences to reset dirty state, because when we submit the data with the same original values, useEffect doesn't reexecute because the dependency data doesn't change
				const personalProfileUpdated = personalProfile
					? { ...personalProfile }
					: personalProfile;

				const candidatePreferences = _get(data, "candidate_preferences");
				const candidatePreferencesUpdated = candidatePreferences
					? { ...candidatePreferences }
					: candidatePreferences;

				setPersonalProfile(personalProfileUpdated);
				setCandidatePreferences(candidatePreferencesUpdated);
			}
		}
	);
	const profile_id = _get(data, "user_id");
	const cv_id = _get(data, "cv_id");

	const sections = {
		personal_profile: personalProfileSection,
		cv: primaryCV,
		candidatePreferences: candidatePreferencesSection,
		functions: functionsSection,
		skills: skillsSection,
		profileTags: profileTagsSection,
		languages: languagesSection
	};

	useEffect(() => {
		const hash = window.location.hash;

		const tag = hash.split("#")[1];
		if (tag !== "default") {
			sections[tag]?.current.scrollIntoView({
				behavior: "smooth",
				block: "center"
			});
		}
	}, [window.location.hash]);

	return (
		<React.Fragment>
			<div className={s.internalContainer}>
				<div ref={sections["personal_profile"]}>
					<PersonalProfile
						id={id}
						personalProfile={personalProfile}
						isLoading={isLoading}
						invalidate={invalidate}
						cv_id={cv_id}
						profile_id={profile_id}
						viewFilesMenuItemClicked={viewFilesMenuItemClicked}
						viewProfileButtonClicked={viewProfileButtonClicked}
					/>
				</div>
				<div ref={sections["cv"]}>
					<PrimaryCV
						id={id}
						viewFilesMenuItemClicked={viewFilesMenuItemClicked}
						personalProfile={personalProfile}
					/>
				</div>
				<div ref={sections["candidatePreferences"]}>
					{!isLoading && (
						<CandidatePreferences
							data={candidatePreferences}
							profile_id={profile_id}
							cv_id={cv_id}
							isInternalLoading={isLoading}
						/>
					)}
				</div>
				<div ref={sections["functions"]}>
					{!isLoading && (
						<Functions
							functions={getFunctionsData(data)}
							data={_get(data, "functions")}
							profile_id={profile_id}
							cv_id={cv_id}
							skills={_get(data, "skills", [])}
						/>
					)}
				</div>
				<div ref={sections["skills"]}>
					<Skills
						skills={_get(data, "skills", [])}
						selectedFunctions={_get(data, "functions", [])}
						cv_id={cv_id}
					/>
				</div>
				<div ref={sections["languages"]}>
					<Languages
						languages={_get(data, "languages", [])}
						data={data}
						isLoading={isLoading}
						isPermanent={true}
					/>
				</div>
				<div ref={sections["profileTags"]}>
					<ProfileTags
						cv_id={cv_id}
						profile_id={profile_id}
						tags={_get(data, "profile_tags", [])}
					/>
				</div>
				{!_get(data, "is_subscribed") && !isLoading && (
					<div className={s.overlay}>
						<div className={s.overlayContent}>
							<h2>This candidate is not in your pool yet!</h2>
							<h3>
								In order to add data to <span>{_get(data, "full_name")}</span>{" "}
								you need to add him/her to &quot;My candidates&quot;
							</h3>
							{isLoadingAddProfile && <Loader check={false} />}
							{!isLoadingAddProfile && (
								<button
									className={s.secondaryButton}
									onClick={() => addProfileToPoolButtonClicked()}
								>
									Add to my candidates
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default InternalData;
