import React, { memo } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { formatDate } from "common/Functions";
import { PokeNotesTableDetails, Th, Tr, Td, Section } from "../Styled";
import GlobalTooltip from "common/GlobalTooltip";
import _get from "lodash/get";
import { displayAttendeesList } from "common/displayAttendees";
import StatusLabel from "common/StatusLabel";
import {
	VIDEO_CONFERENCE,
	TIME_FORMAT_SYMBOL,
	COMPANY_ADDRESS,
	SEARCH_ADDRESS,
	REMOTE,
	PHONE_SCREEN
} from "config";

const INTERVIEW_LABELS = {
	[REMOTE]: "Video interview",
	[VIDEO_CONFERENCE]: "Video interview",
	[COMPANY_ADDRESS]: "Company Address",
	[SEARCH_ADDRESS]: "Another Address",
	[PHONE_SCREEN]: "Phone screening"
};

const InterviewHistory = ({ interviews = [] }) => {
	const showNotes = () => {
		return interviews.map(item => {
			const {
				isValidArray,
				isValidRestArray,
				restString,
				first,
				restAttendees
			} = displayAttendeesList(
				_get(item, "attendees_users"),
				_get(item, "user_sender")
			);
			let interviewStartTime = formatDate(
				item.new_interview_start_date,
				true,
				TIME_FORMAT_SYMBOL
			);
			let interviewEndTime = formatDate(
				item.new_interview_end_date,
				true,
				TIME_FORMAT_SYMBOL
			);
			let interviewDay = formatDate(item.new_interview_start_date);
			return (
				<Tr key={item._id}>
					<Td>{formatDate(item.created_at, true)}</Td>
					<Td>
						{`${first.first_name} ${first.last_name}`}

						{isValidArray && isValidRestArray && (
							<GlobalTooltip
								placement="top"
								overlay={`${restString}`}
								overlayClassName="g-tool-dark"
							>
								<div className="rest-attendees">(+{restAttendees.length})</div>
							</GlobalTooltip>
						)}
					</Td>
					<Td>{INTERVIEW_LABELS[item.location_type]}</Td>
					<Td className="detailsInterview">
						<span className="dateInterview">{interviewDay}</span>
						<span className="timeInterview">
							{interviewStartTime}
							<i className="icon-arrow-right-new" />
							{interviewEndTime}{" "}
						</span>
					</Td>
					<Td>
						<StatusLabel status={item.status} label={item.status} />
					</Td>
				</Tr>
			);
		});
	};

	return (
		<Section className="section interviews-history">
			<span className="past-interviews">Interview history</span>

			<Scrollbars
				autoHide
				autoHideTimeout={5000}
				autoHideDuration={200}
				autoHeight
				autoHeightMax={324}
			>
				<PokeNotesTableDetails className="request-list">
					<thead>
						<Tr>
							<Th width="17%">Created date</Th>
							<Th width="22%">attendees</Th>
							<Th width="18%">Location</Th>
							<Th width="25%">Date and time</Th>
							<Th width="12%">Status</Th>
						</Tr>
					</thead>
					<tbody>{showNotes()}</tbody>
				</PokeNotesTableDetails>
			</Scrollbars>
		</Section>
	);
};

export default memo(InterviewHistory);
