import React from "react";
import arrowIcon from "static/icons/drop-down-chevron.svg";
import styles from "./menu.module.scss";

const Menu = ({ onMenuItemHover, menu }) => {
	return (
		<div className={styles.items}>
			{menu.map(({ label, icon, id }) => {
				return (
					<div
						key={id}
						onMouseEnter={() => onMenuItemHover(id)}
						className={styles.menuItemWrapper}
					>
						<img src={icon} />
						<div className={styles.text}>{label}</div>
						<img className={styles.arrowIcon} src={arrowIcon} />
					</div>
				);
			})}
		</div>
	);
};

export default Menu;
