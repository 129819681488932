import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import _forEach from "lodash/forEach";
import AxiosHelper from "config/axios-helper";
import { HTTP_POST_METHOD, API_URL } from "../config";
import { bytesToSize, s3Url } from "config/helpers";
import { PRE_SIGNED_URL } from "../config/api-endpoints";
import { connect } from "react-redux";
import { change } from "redux-form";
import _get from "lodash/get";
class S3Uploader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			progress: 0,
			progressObject: {},
			dragover: false,
			errorMessage: "",
			filesList: [],
			done: false,
			uploading: props.uploading || false
		};
		// binding methods
		this.handleChange = this.handleChange.bind(this);
		this.addAndUpload = this.addAndUpload.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.reset = this.reset.bind(this);
	}

	reset() {
		const { progressOnly } = this.props;
		if (!progressOnly) this._fileHolder.value = "";
		this.setState({ progressObject: {}, filesList: [], uploading: false });
		if (this.props.onClose) this.props.onClose();
	}

	preSignS3 = file => {
		return AxiosHelper.post({
			url: this.props.preSignedUrl,
			// isModal => true, so that the generic loader doesn't show (avatar has its own loader bar)
			isModal: true,
			isLogin: this.props.isLogin,
			data: {
				name: file.name,
				folder: this.props.folder,
				size: file.size,
				type: file.type,
				...this.props.preSignedData
			}
		});
	};

	submitForm = () => {
		const { filesList } = this.state;
		// if no files were added don't do anything
		if (!filesList.length) return;
		for (let file of filesList) {
			this.preSignS3(file).then(data => {
				const formData = this.createFormData({
					preSignData: data,
					fileData: file
				});
				// make the request
				this.setState({ uploading: true });
				if (this.props.onStart) this.props.onStart(file);
				return this.callAPI({ data, formData }).then(() => {
					if (this.props.resetOnSuccess) this.reset();
					this.props.onSuccess({
						name: file.name,
						url: s3Url(data),
						type: file.type,
						bucket: data.bucket,
						size: file.size,
						folder: this.props.folder
					});
					return true;
				});
			});
		}
	};

	handleProgress = e => {
		const progressObject = {
			loaded: e.loaded,
			total: e.total,
			percentCompleted: Math.round((e.loaded * 100) / e.total)
		};
		this.setState({ progressObject });

		if (this.props.onProgress) {
			this.props.onProgress(e);
		}
	};

	createFormData = ({ preSignData, fileData, useBlob = false }) => {
		const formData = new FormData();
		fileData = useBlob ? this.dataURItoBlob(fileData) : fileData;
		formData.append("acl", preSignData["acl"]);
		formData.append("key", preSignData["key"]);
		formData.append("X-Amz-Credential", preSignData["X-Amz-Credential"]);
		formData.append("X-Amz-Algorithm", preSignData["X-Amz-Algorithm"]);
		formData.append("X-Amz-Date", preSignData["X-Amz-Date"]);
		formData.append("Policy", preSignData["Policy"]);
		formData.append("X-Amz-Signature", preSignData["X-Amz-Signature"]);
		formData.append("file", fileData);
		return formData;
	};

	callAPI = ({ data, formData }) => {
		return axios(data.action, {
			processData: false,
			contentType: false,
			method: this.props.method,
			data: formData,
			onUploadProgress: this.handleProgress
		});
	};

	removeFile(index) {
		const { filesList } = this.state;
		filesList.splice(index, 1);
		this.setState({ filesList, progressObject: {} });
		if (filesList.length === 0) {
			this.reset();
		}
	}

	checkFiles = files => {
		const { filesList } = this.state;
		const { multiple, maxFiles, sizeLimit } = this.props;
		let list = [];
		_forEach(files, file => {
			if (this.props.accept && !this.props.accept.includes(file.type)) {
				// ignore file which type doesn't match accept
				this.props.onError("The file format/extension is not valid.");
				if (files.length === 1) {
					this.reset();
				}
			} else if (sizeLimit && file.size > sizeLimit) {
				this.props.onError(
					`The file size should not exceed ${bytesToSize(sizeLimit)}.`
				);
				this.reset();
			} else {
				list.push(file);
			}
		});

		if (multiple) {
			list = list.reduce((acc, file) => {
				if (maxFiles > 0 && maxFiles !== filesList.length) {
					acc = acc.push(file);
				}
				return acc;
			}, []);
		} else {
			if (list[0]) {
				if (this.props.onFileAdded) this.props.onFileAdded(list[0]);
				list = [list[0]];
			}
		}
		return list;
	};

	addFiles = files => {
		const { autoSubmit } = this.props;
		let list = this.checkFiles(files);
		this.setState(
			{
				filesList: list
			},
			() => {
				if (autoSubmit && list.length) {
					if (this.props.preUploadCheck) {
						// Promise.resolve to convert anything coming back from
						// preUploadCheck to a promise
						Promise.resolve(this.props.preUploadCheck(list)).then(result => {
							if (result) this.submitForm();
						});
					} else {
						this.submitForm();
					}
				}
			}
		);
	};

	dataURItoBlob(dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		let byteString;
		if (dataURI.split(",")[0].indexOf("base64") >= 0) {
			byteString = atob(dataURI.split(",")[1]);
		} else {
			byteString = decodeURI(dataURI.split(",")[1]);
		}
		// separate out the mime component
		let mimeString = dataURI
			.split(",")[0]
			.split(":")[1]
			.split(";")[0];
		// write the bytes of the string to a typed array
		let ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ia], { type: mimeString });
	}

	addAndUpload(file, data) {
		return new Promise((resolve, reject) => {
			this.preSignS3(file).then(response => {
				if (response) {
					const formData = this.createFormData({
						preSignData: response,
						fileData: data,
						useBlob: true
					});
					// make the request
					this.setState({ uploading: true });
					this.callAPI({ data: response, formData })
						.then(() => {
							if (!this.props.progressOnly && this.props.resetOnSuccess)
								this.reset();
							resolve({
								url: s3Url(response),
								type: file.type,
								action: response.action,
								size: file.size,
								folder: this.props.folder
							});
						})
						.catch(error => {
							reject(error);
						});
				}
			});
		});
	}

	handleChange(e) {
		const files = e.target.files;
		const { dispatch, formName } = this.props;
		if (!files) return;
		this.addFiles(files);
		if (formName)
			return dispatch(change(formName, "cv_name", _get(files, "[0].name", "")));
	}

	handleEvents(e) {
		e.preventDefault();
		e.stopPropagation();
		if (e.type === "dragover" || e.type === "dragenter")
			this.setState({ dragover: true });
		if (e.type === "dragend" || e.type === "dragleave" || e.type === "drop") {
			this.setState({ dragover: false });
			if (e.type === "drop") this.addFiles(e.dataTransfer.files);
		}
	}

	componentDidMount() {
		if (this.props.progressOnly || !this.props.draggable) return;
		const holder = this._fileHolder;
		const events = [
			"drag",
			"dragstart",
			"dragend",
			"dragleave",
			"drop",
			"dragover",
			"dragenter"
		];
		events.forEach(event => {
			holder.addEventListener(event, e => this.handleEvents(e));
		});
	}

	componentWillUnmount() {
		if (this.props.progressOnly || !this.props.draggable) return;
		const holder = this._fileHolder;
		const events = [
			"drag",
			"dragstart",
			"dragend",
			"dragleave",
			"drop",
			"dragover",
			"dragenter"
		];
		events.forEach(event => {
			holder.removeEventListener(event, e => this.handleEvents(e));
		});
	}

	renderFileRow(file, index) {
		return <div key={index}> {this.props.progressComponent()}</div>;
	}

	render() {
		const {
			text,
			id,
			chooseButton = true,
			cancelButton = true,
			disabled,
			className,
			progressOnly,
			progressComponent,
			showQueue,
			autoSubmit,
			multiple,
			defaultClassName,
			containerClassName,
			error,
			readOnly = false,
			showSubmitButton,
			file_name,
			customRender
		} = this.props;

		const { progressObject, uploading, filesList } = this.state;

		let classes = this.state.dragover ? "drag-active " : "";

		const onCancel = e => {
			e.stopPropagation();
			this.removeFile(0);
		};

		return (
			<div
				className={`s3-uploader ${className && className} ${
					uploading ? "busy" : ""
				} ${customRender ? "upload-custom-render" : ""}`}
				id={id}
			>
				{!progressOnly && (
					<div className={`droppable-holder ${classes}`}>
						{text.label && (
							<label htmlFor={id}>
								{text.label}{" "}
								{!!text.labelWithAsterisk && (
									<span className="asterisk">*</span>
								)}
							</label>
						)}

						<div className={containerClassName + " file-container"}>
							<div className={"fake_file " + defaultClassName}>
								{customRender ? (
									customRender({
										progressObject,
										uploading,
										filesList,
										onCancel
									})
								) : (
									<>
										{!!(text.icon && text.icon.class) && (
											<i className={`${text.icon.class}`} aria-hidden="true">
												<span>{text.icon.text}</span>
											</i>
										)}

										{text.svg && (
											<span
												style={{
													width: "13px",
													position: "absolute",
													fill: "#8e9195",
													top: "21px"
												}}
											>
												{text.svg}
											</span>
										)}

										<div className="placeholder-btn">
											{filesList[0] && filesList[0].name}{" "}
											{!filesList[0] && file_name && file_name}
											{!filesList[0] && !file_name && text.placeholder}
										</div>

										{chooseButton && !uploading && !filesList[0] && (
											<div className={text.className || "fake-button"}>
												{text.choose}
											</div>
										)}
									</>
								)}

								{!readOnly && (
									<input
										className="file-field"
										type="file"
										ref={ref => {
											this._fileHolder = ref;
										}}
										name="files[]"
										id={id}
										onChange={this.handleChange}
										multiple={multiple}
										disabled={disabled}
									/>
								)}
								{!customRender && cancelButton && uploading && filesList[0] && (
									<span onClick={onCancel} className="cancel">
										×
									</span>
								)}
							</div>
						</div>
					</div>
				)}

				{showQueue && progressComponent && (
					<div className="queue">
						{progressObject.total ? progressComponent(progressObject) : error}
					</div>
				)}

				{!autoSubmit && filesList.length > 0 && showSubmitButton && (
					<div className="submit">
						<button onClick={this.submitForm}>{text.button}</button>
					</div>
				)}
			</div>
		);
	}
}

S3Uploader.propTypes = {
	accept: PropTypes.array,
	method: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.string.isRequired,
	multiple: PropTypes.bool,
	autoSubmit: PropTypes.bool,
	url: PropTypes.string,
	text: PropTypes.object,
	showQueue: PropTypes.bool,
	maxFiles: PropTypes.number,
	chooseButton: PropTypes.bool,
	cancelButton: PropTypes.bool,
	resetOnSuccess: PropTypes.bool,
	draggable: PropTypes.bool,
	uploading: PropTypes.bool,
	showUploaderWhileUploading: PropTypes.bool,
	isLogin: PropTypes.bool,
	preSignedUrl: PropTypes.string,
	preSignedData: PropTypes.object,
	showSubmitButton: PropTypes.bool,
	// Callbacks
	onFileAdded: PropTypes.func,
	onStart: PropTypes.func,
	onSuccess: PropTypes.func.isRequired,
	onError: PropTypes.func.isRequired,
	onProgress: PropTypes.func,
	onDone: PropTypes.func,
	preUploadCheck: PropTypes.func
};

S3Uploader.defaultProps = {
	method: HTTP_POST_METHOD,
	name: "files",
	multiple: true,
	autoSubmit: false,
	resetOnSuccess: true,
	maxFiles: 0,
	uploading: false,
	progressOnly: false,
	defaultClassName: "file",
	containerClassName: "",
	isLogin: false,
	preSignedUrl: PRE_SIGNED_URL,
	preSignedData: {},
	showSubmitButton: true,
	// 0 = no limit
	url: API_URL,
	text: {
		label: "",
		choose: "Click to choose",
		placeholder: "select file or drag it here!",
		button: "Upload",
		uploading: "Uploading...",
		done: "Done",
		restart: "Upload more?",
		retry: "Try again!",
		labelWithAsterisk: false
	},
	formName: "",
	showQueue: true,
	draggable: true,
	onError: () => {},
	onSuccess: () => {}
};
const mapStateToProps = state => {
	return {
		file_name: _get(state, "form.create-freelancer-step-1.values.cv_name", null)
	};
};

export default connect(mapStateToProps, null)(S3Uploader);
