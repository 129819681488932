import React, { memo } from "react";
import PropTypes from "prop-types";
import { getClientBidViewRoute } from "modules/bids/components/bidview/client/ClientBidViewContainer";
import { formatDate } from "common/Functions";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import LineProgress from "common/progress/LineProgress";
import { MAX_BID_DISPLAY, FREELANCER, CURRENCY_SUFFIX } from "config";
import {
	ListBidsBody,
	TableExpandWrapper,
	JobBidsList,
	Trthead,
	Thead,
	Tr,
	Td,
	Th
} from "./BidsListStyled";
import StatusLabel from "common/StatusLabel";
import { Text } from "rebass";
import EmptyBids from "common/EmptyComponent";
import _get from "lodash/get";
import { historyPush } from "config/helpers";

const BidsList = ({ total, application_list }) => {
	const renderBid = bid => {
		return (
			<Tr
				key={bid._id}
				onClick={() => historyPush(getClientBidViewRoute(bid._id))}
			>
				<Td style={{ width: "14%", padding: "15px 12px" }}>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "14px",
							color: "rgb(15, 28, 70)"
						}}
					>{`${bid.user_first_name} ${bid.user_last_name}`}</Text>
					<Text
						sx={{
							padding: "3px 6px",
							borderRadius: "2px",
							backgroundColor:
								bid.company_type === FREELANCER
									? "#ffd38f"
									: "rgb(223, 240, 249)",
							textAlign: "center",
							display: "inline-block",
							color: bid.company_type === FREELANCER ? "#9f7943" : "#0b6dba",
							fontSize: "10px",
							textTransform: "uppercase"
						}}
					>
						{bid.company_type}
					</Text>
				</Td>
				<Td style={{ width: "11%", padding: "15px 0px" }}>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "14px",
							color: "rgb(15, 28, 70)"
						}}
					>
						{formatDate(bid.created_at)}
					</Text>
				</Td>
				<Td style={{ width: "21%", padding: "15px 0px" }}>
					<LineProgress
						strokeColor="#0fc855"
						width="90%"
						percent={bid.matching_rate}
					/>{" "}
				</Td>
				<Td style={{ width: "12%", padding: "15px 0px" }}>
					{_get(bid, "preference[0].address.country", "NA")}
				</Td>
				<Td style={{ width: "11%", padding: "15px 0px" }}>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "14px",
							color: "rgb(15, 28, 70)"
						}}
					>
						<CurrencyFormatterRender
							isCent
							value={bid.proposed_amount}
							suffix={
								CURRENCY_SUFFIX[_get(bid, "reporting_settings.rate_type")]
							}
							currency={_get(bid, "reporting_settings.currency.code")}
						/>
					</Text>
				</Td>
				<Td style={{ width: "11%", padding: "15px 0px" }}>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "14px",
							color: "rgb(15, 28, 70)"
						}}
					>
						{formatDate(bid.available_date)}
					</Text>
				</Td>
				<Td style={{ width: "7%", padding: "15px 5px" }}>{bid.source}</Td>
				<Td style={{ width: "15%", padding: "15px 5px" }}>
					<StatusLabel
						status={bid.status}
						label={bid.visible_status}
						isInterview
					/>
				</Td>
			</Tr>
		);
	};
	return (
		<>
			{application_list.length === 0 ? (
				<EmptyBids
					style={{ margin: "5px 0" }}
					title="No bid placed so far"
					paragraph="Bidding on requests from clients is the first step toward working on your first mission via wiggli. To do so, just use the link below to look for missions matching your skills and expectations."
				/>
			) : (
				<TableExpandWrapper
					className="expandWrapper"
					style={{ border: "none" }}
				>
					<JobBidsList>
						<Thead>
							<Trthead
								style={{
									background: "#e5e8ec",
									color: "#6b7075"
								}}
							>
								<Th
									style={{
										width: "14%",
										padding: "15px 12px",
										color: "#6b7075",
										fontFamily: "BasierCircle",
										fontSize: "13px",
										fontWeight: "500"
									}}
								>
									Name
								</Th>
								<Th
									style={{
										width: "11%",
										padding: "15px 0px",
										color: "#6b7075",
										fontFamily: "BasierCircle",
										fontSize: "13px",
										fontWeight: "500"
									}}
								>
									Date
								</Th>
								<Th
									style={{
										width: "21%",
										padding: "15px 0px",
										color: "#6b7075",
										fontFamily: "BasierCircle",
										fontSize: "13px",
										fontWeight: "500"
									}}
								>
									Matching score
								</Th>
								<Th
									style={{
										width: "12%",
										padding: "15px 0px",
										color: "#6b7075",
										fontFamily: "BasierCircle",
										fontSize: "13px",
										fontWeight: "500"
									}}
								>
									location
								</Th>
								<Th
									style={{
										width: "11%",
										padding: "15px 0px",
										color: "#6b7075",
										fontFamily: "BasierCircle",
										fontSize: "13px",
										fontWeight: "500"
									}}
								>
									Rate
								</Th>
								<Th
									style={{
										width: "11%",
										padding: "15px 0px",
										color: "#6b7075",
										fontFamily: "BasierCircle",
										fontSize: "13px",
										fontWeight: "500"
									}}
								>
									Available from
								</Th>
								<Th
									style={{
										width: "7%",
										padding: "15px 5px",
										textAlign: "center",
										color: "#6b7075",
										fontFamily: "BasierCircle",
										fontSize: "13px",
										fontWeight: "500"
									}}
								>
									Source
								</Th>
								<Th
									style={{
										width: "15%",
										padding: "15px 5px",
										textAlign: "center",
										color: "#6b7075",
										fontFamily: "BasierCircle",
										fontSize: "13px",
										fontWeight: "500"
									}}
								>
									Status
								</Th>
							</Trthead>
						</Thead>
					</JobBidsList>
					<tr
						style={{
							height: "10px",
							background: "#f1f2f5",
							display: "block",
							border: "none"
						}}
					>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<ListBidsBody scroll={total > MAX_BID_DISPLAY}>
						<JobBidsList>
							<tbody>{application_list.map(renderBid)}</tbody>
						</JobBidsList>
					</ListBidsBody>
				</TableExpandWrapper>
			)}
		</>
	);
};

BidsList.propTypes = {
	application_list: PropTypes.array.isRequired,
	total: PropTypes.number.isRequired
};

export default memo(BidsList);
