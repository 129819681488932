import React, { useState } from "react";
import Drawer from "rc-drawer";
import { ReactComponent as QuitIcon } from "static/icons/quite-icon.svg";
import styles from "./save-contracts-groups-drawer.module.scss";
import InputField from "modules/vonqContract/components/InputFiled";
import Loader from "common/Loader";

const SaveContractsGroupsDrawer = ({
	onClose,
	contractGroup,
	header,
	subHeader,
	onSave,
	displaySavingLoading,
	errorMessage,
	onInputChange
}) => {
	const { name } = contractGroup;
	const [groupName, setGroupName] = useState(name);
	const [fieldError, setFieldError] = useState("");
	const [groupNameInitialValue] = useState(name);

	const handleAddGroupClick = () => {
		if (!groupName) {
			setFieldError("Group name is required");
		} else if (groupName === groupNameInitialValue) {
			setFieldError("Group name exist");
		} else {
			onSave(groupName);
		}
	};

	const handleInputChange = e => {
		setGroupName(e.target.value);
		setFieldError("");
		onInputChange();
	};

	return (
		<Drawer
			open
			width="650px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000 }}
			level="root"
			maskClosable={true}
			onClose={onClose}
			classname="addContract"
		>
			{displaySavingLoading && (
				<div className={styles.loaderContainer}>
					<Loader />
				</div>
			)}
			<div>
				<div className={styles.header}>
					<label className={styles.title}>{header}</label>
					<button className={styles.quitButton} onClick={onClose}>
						<QuitIcon />
					</button>
				</div>
				<div className={styles.subHeader}>{subHeader}</div>
				<div className={styles.inputContainer}>
					<InputField
						value={groupName}
						onChange={handleInputChange}
						type="text"
						label={
							<>
								Name <span style={{ color: "red" }}>*</span>
							</>
						}
						error={fieldError || errorMessage}
					/>
				</div>
				<div className={styles.buttonsContainer}>
					<button className={styles.addButton} onClick={handleAddGroupClick}>
						save
					</button>
					<button className={styles.cancelButton} onClick={onClose}>
						Cancel
					</button>
				</div>
			</div>
		</Drawer>
	);
};

export default SaveContractsGroupsDrawer;
