import styled from "styled-components";

export const Value = styled.span`
	display: inline-flex;
	justify-content: space-between;
	background-color: #f6f8fa;
	border: 1px solid #e7e7e7;
	color: #626b74;
	font-size: 14px;
	line-height: 1;
	margin: 2.5px 5px;
	vertical-align: top;
	border-radius: 3px;
`;

export const Label = styled.span`
	padding: 7px;
	color: #626b74;
	font-size: 12px;
`;

export const RemoveBtn = styled.span`
	cursor: pointer;
	background-color: #e4eaf0;
	color: #626b74;
	display: flex;
	align-items: center;
	width: 24px;
	justify-content: center;
	font-size: 16px;
`;
