import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import _differenceBy from "lodash/differenceBy";
import { useForm } from "react-hook-form";
import {
	DialogTitle,
	Dialog,
	DialogContent,
	DialogClose
} from "../../CreateNewSupplier/Dialog";
import s from "../../CreateNewSupplier/create-supplier.module.scss";
import Skills from "./Skills";
import useGetSkills from "../../../api/useGetSkills";
import useFiltersStore from "../searchStore";

function SkillsDialog({
	isOpen,
	onClose,
	onSubmit: propsSubmit,
	config = {
		title: "Filter by Expertise (Skills)",
		saveAction: "Add to filters"
	},
	functions,
	selected: propsSelected,
	store
}) {
	const { skills, setSkills, term } = (store ?? useFiltersStore)(state => ({
		skills: state.skills,
		term: state.term,
		setSkills: state.setSkills
	}));
	const [selected, setSelected] = useState(propsSelected ?? []);
	const { control, handleSubmit, watch, register } = useForm();

	const onSubmit = e => {
		e.stopPropagation();
		e.preventDefault();
		onClose();
		const cb = propsSubmit ?? setSkills;
		return handleSubmit(() => cb(selected))(e);
	};

	useEffect(() => {
		setSelected(propsSelected ?? skills);
	}, [skills, setSelected, propsSelected]);

	const sector = watch("category")?.value;
	const searchTerm = watch("term");

	const { data, isLoading, isFetching } = useGetSkills({
		term,
		sector
	});

	return (
		<Dialog open={isOpen}>
			<DialogContent size={"xl"} onEscapeKeyDown={onClose}>
				<DialogTitle>{config?.title}</DialogTitle>
				<form className={s.form} onSubmit={onSubmit}>
					<Skills
						control={control}
						register={register}
						data={_differenceBy(data?.data, selected, "_id")}
						selected={selected}
						onSelect={skill => setSelected(selected => [...selected, skill])}
						onRemove={skill =>
							setSelected(selected => selected.filter(s => s._id !== skill._id))
						}
						searchTerm={searchTerm}
						isLoading={isLoading || isFetching}
						functions={functions}
						store={store}
					/>
					<div className={s.dialogFooter}>
						<button onClick={onClose} className={s.cancelButton}>
							Cancel
						</button>
						<button type={"submit"} className={s.mainButton}>
							{config?.saveAction}
						</button>
					</div>
				</form>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>
						<XIcon height={16} width={16} />
					</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default SkillsDialog;
