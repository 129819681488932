import React from "react";
import VerticalDivider from "modules/home/components/VerticalDivider.jsx";
import { ReactComponent as CalendarIcon } from "static/icons/green-calendar.svg";
import { ReactComponent as ClockIcon } from "static/icons/green-clock.svg";
import style from "modules/home/components/styles.module.scss";
import { formatDate } from "common/Functions";
import { TIME_FORMAT_SYMBOL } from "config";

const DateTimeDisplayer = ({
	createdAt,
	interviewStartDate,
	interviewEndDate
}) => {
	return (
		<div className={style.dateTimeDisplayer}>
			<VerticalDivider /> <CalendarIcon />
			<span className={style.time}>
				{formatDate(createdAt)}{" "}
			</span> <ClockIcon />{" "}
			<span className={style.time}>
				{`${formatDate(
					interviewStartDate,
					true,
					TIME_FORMAT_SYMBOL
				)} - ${formatDate(interviewEndDate, true, TIME_FORMAT_SYMBOL)}`}{" "}
			</span>
		</div>
	);
};

export default DateTimeDisplayer;
