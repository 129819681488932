import React, { useState } from "react";
import styles from "./bid-details-processes-card.module.scss";
import { ReactComponent as VideoIcon } from "static/icons/video-recorder-icon.svg";
import { ReactComponent as LinkIcon } from "static/icons/link-dark-grey-icon.svg";
import { getRoute as getVirtualRoomPath } from "modules/WhereByVirtualRoom";
import { getRoute as getFreelancerVirtualRoomPath } from "modules/WherebyFreelancerVirtualRoom";
import { historyPush } from "config/helpers";
import * as copyToClipboard from "copy-to-clipboard";
import CopyTooltip from "rc-tooltip";
import { stringify } from "query-string";
import { FREELANCER } from "config";

const BidDetailsJoinMeeting = ({ room_url, user }) => {
	const [tooltipText, setTooltipText] = useState("Copy link");

	const handleOpenRoomClick = () => {
		const { company_type, first_name, last_name } = user;
		const params = stringify({
			roomUrl: room_url,
			displayName: `${first_name} ${last_name}`
		});
		if (company_type === FREELANCER) {
			historyPush(`${getFreelancerVirtualRoomPath()}?${params}`, true);
		} else {
			historyPush(`${getVirtualRoomPath()}?${params}`, true);
		}
	};

	const onCopy = () => {
		copyToClipboard(room_url);
		setTooltipText("Copied!");
		setTimeout(() => {
			setTooltipText("Copy link");
		}, 1500);
	};
	return (
		<div className={styles.card}>
			<div className={styles.meeting}>
				<div className={styles.item}>
					<VideoIcon />
					<span className={styles.title}>Virtual meeting room</span>
				</div>
				<div className={styles.item}>
					<CopyTooltip
						placement="top"
						theme="dark"
						mouseEnterDelay={0}
						mouseLeaveDelay={0}
						overlayClassName={"employer_branding_tooltip"}
						overlay={<div>{tooltipText}</div>}
					>
						<LinkIcon onClick={onCopy} />
					</CopyTooltip>
					<button className={styles.btn} onClick={handleOpenRoomClick}>
						Join Meeting Room
					</button>
				</div>
			</div>
		</div>
	);
};

export default BidDetailsJoinMeeting;
