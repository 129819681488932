import React, { useState, memo } from "react";
import { AdvancedSearch } from "../AdvancedSearch";
import AdvancedTabCardHeader from "../AdvancedTabCardHeader/AdvancedTabCardHeader";
import { useFilters } from "../hooks/useFilter";
import { validFiltersCount } from "../render_utils";
import styles from "./advanced-tabs-advanced-card.module.scss";

const AdvancedTabsAdvancedCard = ({
	hideTabSaved,
	onlyList,
	displayAdvancedSearchHead
}) => {
	const [displayBody, setDisplayBody] = useState(true);
	const { getState, currentFilterId } = useFilters();

	const { filters } = getState(currentFilterId);
	const count = validFiltersCount(filters || []);

	const handleHeaderClick = () => {
		setDisplayBody(displayBody => !displayBody);
	};
	return (
		<div className={styles.container}>
			{displayAdvancedSearchHead && (
				<AdvancedTabCardHeader
					onClick={handleHeaderClick}
					iconDirection={displayBody ? "bottom" : "right"}
					title="Advanced"
					count={count}
				/>
			)}
			{displayBody && (
				<AdvancedSearch
					className={styles.body}
					hideTabSaved={hideTabSaved}
					onlyList={onlyList}
				/>
			)}
		</div>
	);
};

export default memo(AdvancedTabsAdvancedCard);
