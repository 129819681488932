import React from "react";
import { useToggle } from "react-use";

import AccordionHeader from "./AccordionHeader";
import AccordionContent from "./AccordionContent";
import styles from "./accordion.module.scss";

const Accordion = ({ header, body, application_id }) => {
	const [on, toggle] = useToggle(false);

	return (
		<div className={styles.accordion}>
			<AccordionHeader
				show={on}
				toggle={toggle}
				header={header}
				application_id={application_id}
			/>
			<AccordionContent show={on} toggle={toggle} body={body} />
		</div>
	);
};
export default Accordion;
