/* eslint-disable no-useless-escape */
import React from "react";
import PhoneNumberField from "common/PhoneNumberField";
import { Headline } from "../styles";
import { isValidPhoneNumber } from "config/validators";
import { PHONE_DEFAULT_COUNTRY } from "config";
import get from "lodash/get";

const PhoneNumber = ({ onChange, value, error }) => {
	return (
		<div className="candidate-phone-number">
			<Headline>Candidate phone number</Headline>
			<PhoneNumberField
				input={{
					onChange: ({ countryData, value }) => {
						const countryCode = get(countryData, "iso2", PHONE_DEFAULT_COUNTRY);
						value = value.replace(/^0/, "");
						let formattedValue = `${countryCode.toUpperCase()} +${
							countryData.dialCode
						}${value}`;

						onChange(formattedValue);
					}
				}}
				classes="candidate-number"
				defaultValue={value}
				validate={isValidPhoneNumber}
				isErrorBlock={true}
				hasError={error}
				meta={{ touched: error, error }}
			/>
		</div>
	);
};

export default PhoneNumber;
