import { useQuery } from "react-query";
import { client } from "../../../lib/api-client";
import { LIST_CATEGORIES } from "../../../config/api-endpoints";

const getCategories = () => client(LIST_CATEGORIES, { method: "post" });

const useGetCategories = () => {
	return useQuery("@suppliers/get-categories", getCategories, {
		retry: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity
	});
};

export default useGetCategories;
