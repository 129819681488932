import React from "react";
import cx from "classnames";
import Skeleton from "react-loading-skeleton";
import { getRandomNumber } from "config/helpers";
import styles from "./create-vacancy-skeleton-card.module.scss";

const CreateVacancySkeletonCard = ({
	width: widthProp,
	height = 44,
	style = { borderRadius: 8 },
	className,
	useRandomWidth
}) => {
	let width = useRandomWidth ? getRandomNumber(70, 200) : widthProp;

	return (
		<Skeleton
			className={cx(styles.container, className)}
			style={style}
			width={width}
			height={height}
		/>
	);
};

export default CreateVacancySkeletonCard;
