import React from "react";
import { ReactComponent as ResetIcon } from "static/icons/IconclearFilters.svg";
import { ReactComponent as SearchIcon } from "static/icons/search-icon-white.svg";
import ButtonGroup from "../../ButtonGroup/ButtonGroup";
import { useFilters } from "../hooks/useFilter";
import styles from "./filter-drawer-footer.module.scss";

const FilterDrawerFooter = ({ onClose }) => {
	const { resetFilters } = useFilters();

	const handleSearchClick = () => {
		onClose();
	};

	const buttons = [
		{
			variant: "contained",
			text: "Search",
			onClick: handleSearchClick,
			icon: <SearchIcon />
		},
		{
			variant: "text",
			text: "Clear",
			onClick: () => resetFilters(),
			icon: <ResetIcon />
		}
	];

	return (
		<div className={styles.container}>
			<ButtonGroup gap=".5rem" buttons={buttons} />
		</div>
	);
};

export default FilterDrawerFooter;
