import React, { useState } from "react";
import { DayPickerSingleDateController, CalendarDay } from "react-dates";
import { useMutation } from "react-query";
import _get from "lodash/get";
import styled from "styled-components";
import Toggle from "react-toggle";

import { isBusinessDay } from "config/helpers";
import { DUPLICATE_WORKTIME, COMPUTE_TIMESHEET } from "config/api-endpoints";
import { client } from "lib/api-client";
import { colors } from "config/styles";

import toaster from "common/Toaster";
import Modal from "common/modal";
import { LOADING } from "config";
import { formatDate } from "common/Functions";
import {
	DRAFT,
	CLIENT,
	FREELANCER,
	DATE_FORMAT,
	MAX_HOURS_PER_DAY,
	OVERTIME
} from "config";
import get from "lodash/get";
import { utcTimeStamp } from "config/helpers";

const Container = styled.div`
	margin-bottom: 20px;
	padding: 0px 50px;
	display: flex;
	align-items: center;
	flex-direction: column;

	.apply-to-all {
		color: ${colors.anchorsColor};
		margin-bottom: 10px;
		font-weight: bold;
		display: flex;
		align-items: center;

		.toggle-notification {
			padding: unset;
			margin-left: 14px;
		}
		svg {
			display: none;
		}
	}
	.info-text {
		width: 52%;
		margin-bottom: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #3d4b60;
	}

	.DayPicker {
		padding: 10px 0px;
		.DayPicker_weekHeader_li {
			small {
				color: #888e95 !important;
				font-weight: bold !important;
			}
		}

		.DayPicker_transitionContainer {
			height: 280px !important;
		}
	}
	.CalendarMonth_table {
		td {
			border: unset;
			color: rgba(43, 46, 73, 0.87);
			height: 33px !important;
			&.CalendarDay__selected {
				color: white;
			}

			&.CalendarDay__selected {
				clip-path: circle(41% at 50% 52%);
				.weekend-day {
					background-color: ${colors.primaryColor};
					color: ${colors.white};
				}
			}
			&.CalendarDay__blocked_out_of_range {
				opacity: 0.5;
				cursor: not-allowed;
			}

			.weekend-day {
				background-color: #f4f4f4;
				color: #394148;
				height: 34px !important;
			}
		}
	}
`;

const duplicateWorktime = data =>
	client(DUPLICATE_WORKTIME, {
		body: data
	});

const computeTimesheet = ({ data }) =>
	client(COMPUTE_TIMESHEET, { body: data });
const DuplicateItemModal = ({
	closeModal,
	worktime,
	reloadTimesheet,
	month,
	status: timesheetStatus,
	addedWorkTimes,
	setAddedWorkTime,
	// addedOverTimes,
	// setAddedOverTime,
	type,
	timesheetId,
	disputedWorkTime,
	view
}) => {
	const { moment } = window;
	const [selectedDays, setSelectedDays] = useState([]);
	const [applyToAll, setApplyToAll] = useState(false);
	const [currentMonth, setCurrentMonth] = useState(
		moment.unix(month.start_date)
	);

	const [onComputeTimesheet] = useMutation(computeTimesheet, {
		onSuccess: (data, { result }) => {
			const { amount } = data.dispute_items.worktimes.added[0];
			const addAmountToResult = result.map(item => ({
				...item,
				[`${type === CLIENT ? CLIENT : FREELANCER}_amount`]: amount
			}));
			setAddedWorkTime([...addedWorkTimes, ...addAmountToResult]);
			closeModal();
		},
		onError: error => {
			toaster.danger(get(error, "detail.name", get(error, "title")));
		}
	});

	const [mutate, { status }] = useMutation(duplicateWorktime, {
		onSuccess: () => {
			toaster.success("Successfully duplicated the worktime");
			closeModal();
			reloadTimesheet();
		},
		onError: e => {
			toaster.danger(_get(e, "detail.name"));
		}
	});

	const formatDay = day =>
		window
			.moment(day.clone())
			.hour(12)
			.unix();

	const handleApplyToAll = () => {
		if (applyToAll) {
			setApplyToAll(!applyToAll);
			return setSelectedDays([]);
		}

		const daysCount = currentMonth.daysInMonth();

		const month = moment(currentMonth)
			.startOf("Month")
			.format("MM/YYYY");
		const daysInMonth = [];
		for (let i = 1; i <= daysCount; i++) {
			let date = moment(`${i}/${month}`, "DD/MM/YYYY");
			if (isBusinessDay(date.day()) && !isOutsideRange(date)) {
				daysInMonth.push(formatDay(date));
			}
		}

		setSelectedDays(daysInMonth);
		return setApplyToAll(!applyToAll);
	};

	const selectDay = day => {
		if (!isOutsideRange(day)) {
			const formattedDay = formatDay(day);
			if (selectedDays.indexOf(formattedDay) === -1) {
				return setSelectedDays([...selectedDays, formattedDay]);
			}

			return setSelectedDays(selectedDays.filter(d => d !== formattedDay));
		}
	};

	const isSelected = day => {
		if (!day) return;
		return selectedDays.includes(formatDay(day));
	};
	const isOutsideRange = day => {
		const minDate = window.moment.unix(month.start_date).toDate();
		const maxDate = window.moment.unix(month.end_date).toDate();

		return (
			(minDate && day.isBefore(minDate)) || (maxDate && day.isAfter(maxDate))
		);
	};

	const submit = async () => {
		try {
			const selectedWorktime = formatDate(_get(worktime, "item.date"));

			let date = selectedDays;

			if (applyToAll) {
				date = selectedDays.filter(d => formatDate(d) !== selectedWorktime);
			}

			if (timesheetStatus === DRAFT) {
				return await mutate({
					hours: _get(worktime, "item.hours") * 60,
					rate: _get(worktime, "item.rate"),
					date,
					description: _get(worktime, "item.description"),
					tag: _get(worktime, "item.tag"),
					timesheet_id: _get(worktime, "timesheet_id"),
					is_overtime: view === OVERTIME
				});
			} else {
				const mappedDates = date.map(date => {
					const startDate = utcTimeStamp({
						date: window.moment.unix(date).format(DATE_FORMAT),
						format: DATE_FORMAT,
						manageTime: true
					});
					const endDate = utcTimeStamp({
						date: window.moment.unix(date).format(DATE_FORMAT),
						format: DATE_FORMAT,
						manageTime: true,
						isStart: false
					});

					return {
						date: {
							startDate,
							endDate
						},
						hours: _get(worktime, "item.hours") * 60
					};
				});

				const isOver24Hours = beyondMaxHours(mappedDates);

				if (isOver24Hours) {
					return toaster.danger(
						`It seems that you have exceeded the amount of hours (${MAX_HOURS_PER_DAY}h) allowed in the same day
							(${window.moment.unix(isOver24Hours).format(DATE_FORMAT)}).`,
						{ duration: 10, id: "err" }
					);
				}

				const data = date.map(() => ({
					hours: _get(worktime, "item.hours") * 60,
					rate: _get(worktime, "item.rate")
				}));
				const result = date.map(date => ({
					hours: _get(worktime, "item.hours") * 60,
					rate: _get(worktime, "item.rate"),
					hours_representation: _get(worktime, "item.hours_representation"),
					date,
					description: _get(worktime, "item.description"),
					tag: _get(worktime, "item.tag"),
					timesheet_id: _get(worktime, "timesheet_id")
				}));

				onComputeTimesheet({
					data: {
						timesheet_id: timesheetId,
						dispute_items: {
							worktimes: { edited: [], added: data },
							expenses: {
								edited: [],
								added: []
							}
						}
					},
					result
				});

				return;
			}
		} catch (e) {}
	};

	const beyondMaxHours = dates => {
		const worktimes = _get(month, "worktimes");
		const hoursToMinutes = worktimes.map(item => ({
			...item,
			hours: item.hours * 60
		}));
		const mappedDiputedWorktimeIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);
		const mergedList = hoursToMinutes.map(item => {
			const indexOfItemIndisputedItems = mappedDiputedWorktimeIds.indexOf(
				item._id
			);
			if (indexOfItemIndisputedItems >= 0) {
				return {
					...disputedWorkTime[indexOfItemIndisputedItems]
				};
			}
			return item;
		});

		const totalHoursInEachDay = dates.map(item => {
			let obj = item;
			[...mergedList, ...addedWorkTimes].forEach(listItem => {
				if (
					listItem.date > item.date.startDate &&
					listItem.date < item.date.endDate
				) {
					obj = {
						...item,
						hours: obj.hours + listItem.hours
					};
				}
			});

			return obj;
		});
		let dayIndex = null;

		const isOver24HinDay = totalHoursInEachDay.map(item => {
			if (item.hours > MAX_HOURS_PER_DAY * 60) {
				if (!dayIndex) {
					dayIndex = item.date.startDate;
				}
				return true;
			}
			return false;
		});

		return isOver24HinDay.some(item => !!item) ? dayIndex : false;
	};

	return (
		<Modal
			title={`Duplicate ${view}`}
			onClose={closeModal}
			firstButton={{
				label: "Cancel",
				type: "outlined",
				action: closeModal
			}}
			secondButton={{
				label: "Save",
				type: "primary",
				action: submit,
				disabled: selectedDays.length === 0
			}}
			loading={status === LOADING}
		>
			<Container>
				<div className="apply-to-all">
					Apply to all business day{" "}
					<span className="toggle-notification">
						<Toggle
							id="select_all"
							checked={applyToAll}
							onChange={handleApplyToAll}
						/>
					</span>
				</div>
				<div className="info-text">
					or select the date(s) (multiple choice allowed) on which you would
					like to clone this entry.
				</div>
				<DayPickerSingleDateController
					numberOfMonths={1}
					onDateChange={selectDay}
					onNextMonthClick={setCurrentMonth}
					onPrevMonthClick={setCurrentMonth}
					initialVisibleMonth={() => moment.unix(month.start_date)} // PropTypes.func or null,
					focused={true}
					renderCalendarDay={props => {
						const { day, modifiers, key } = props;

						if (day) {
							if (isSelected(day)) {
								modifiers && modifiers.add("selected");
							} else {
								modifiers && modifiers.delete("selected");
							}
							if (isOutsideRange(day)) {
								modifiers && modifiers.add("blocked-out-of-range");
							}
						}

						return (
							<CalendarDay
								{...props}
								modifiers={modifiers}
								renderDayContents={day => {
									return (
										<span
											className={
												isBusinessDay(key, [0, 1, 2, 3, 4]) ? "" : "weekend-day"
											}
										>
											{day.format("D")}
										</span>
									);
								}}
							/>
						);
					}}
				/>
			</Container>
		</Modal>
	);
};

export default DuplicateItemModal;
