import React from "react";
import styles from "./breadcrumb-list.module.scss";
import Tooltip from "common/Tippy";
import cx from "classnames";

const renderCrumb = ({ value, minWidth, id }) => {
	return (
		<li
			key={id}
			style={{
				minWidth,
				height: "100%",
				alignItems: "center",
				display: "flex"
			}}
		>
			<Tooltip overflow="visible" theme="dark" content={value}>
				<span className={styles.text}>{value}</span>
			</Tooltip>
		</li>
	);
};

const WIDTH_LIST = ["19%", "28%", "28%", "25%"];

const BreadcrumbList = ({ list }) => {
	return (
		<div className={styles.container} id="can-do">
			<ul
				className={cx(`crumbs`, styles.list)}
				style={{ display: "flex", alignItems: "center", width: "100%" }}
			>
				{list.map((item, i) =>
					renderCrumb({ id: i, value: item, minWidth: WIDTH_LIST[i] })
				)}
			</ul>
		</div>
	);
};

export default BreadcrumbList;
