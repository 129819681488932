import React from "react";
import { Field, reduxForm } from "redux-form";
import { useMutation } from "react-query";
import { connect } from "react-redux";
import { compose } from "redux";
import {
	FIFTY,
	HUNDRED,
	ONE_HUNDRED_AND_FIFTY,
	ONE_HUNDRED_AND_TWENTY_FIVE,
	TWO_HUNDRED,
	DATE_FORMAT,
	TIMESHEET_TAGS_STATUS,
	MAX_HOURS_PER_DAY
} from "config";
import Icon from "common/styled/icons";
import { ReactComponent as CancelIcon } from "static/icons/cancel-icon.svg";
import FormField from "common/FormField";
import DatePickerField from "common/DatePickerField.new";
import StyledSelect from "common/StyledSelect";
import { Button } from "common/styled/buttons";
import { utcTimeStamp } from "config/helpers";
import toaster from "common/Toaster";
import {
	timesheetTagsSelectSelector,
	timesheetTagsProjectNameSelector,
	featureTimesheetTagsSelector
} from "../../timesheetSelector";
import { Flex, Box } from "rebass";
import { updateAmounts } from "../../actions";

import { FormContainerMultipleEdit } from "./styled";
import { client } from "lib/api-client";
import { COMPUTE_TIMESHEET } from "config/api-endpoints";
import { CLIENT, FREELANCER } from "config";
import { get } from "lodash";

const getHoursInEachDay = (itemSelected, list, hours) => {
	let totalHoursInEachDay = [];

	itemSelected.forEach(item => {
		const mappedListIds = list.map(
			({ _id, worktime_id }) => _id || worktime_id
		);
		const mappedStartDay = totalHoursInEachDay.map(({ date }) => date);
		const itemIndex = mappedListIds.indexOf(item);

		const startDate = utcTimeStamp({
			date: window.moment.unix(list[itemIndex].date),
			format: DATE_FORMAT,
			manageTime: true
		});
		const endDate = utcTimeStamp({
			date: window.moment.unix(list[itemIndex].date),
			format: DATE_FORMAT,
			manageTime: true,
			isStart: false
		});
		if (
			mappedStartDay.some(
				item =>
					list[itemIndex].date > item.startDate &&
					list[itemIndex].date < item.endDate
			)
		) {
			return;
		}

		totalHoursInEachDay = [
			...totalHoursInEachDay,
			{
				date: { startDate, endDate },
				hours: hours
			}
		];

		list.forEach((listItem, index) => {
			if (
				mappedListIds[index] !== item &&
				listItem.date > startDate &&
				listItem.date < endDate
			) {
				totalHoursInEachDay[totalHoursInEachDay.length - 1] = {
					date: { startDate, endDate },
					hours:
						totalHoursInEachDay[totalHoursInEachDay.length - 1].hours +
						listItem.hours
				};
			}
		});
	});

	let dayIndex = null;
	const isOver24HinDay = totalHoursInEachDay.map(item => {
		if (item.hours > MAX_HOURS_PER_DAY * 60) {
			if (!dayIndex) {
				dayIndex = item.date.startDate;
			}
			return true;
		}
		return false;
	});

	return isOver24HinDay.some(item => !!item) ? dayIndex : false;
};

export const rateOptions = val => {
	const options = [
		{
			value: FIFTY,
			label: `${FIFTY}%`
		},
		{
			value: HUNDRED,
			label: `${HUNDRED}%`
		},
		{
			value: ONE_HUNDRED_AND_TWENTY_FIVE,
			label: `${ONE_HUNDRED_AND_TWENTY_FIVE}%`
		},
		{
			value: ONE_HUNDRED_AND_FIFTY,
			label: `${ONE_HUNDRED_AND_FIFTY}%`
		},
		{
			value: TWO_HUNDRED,
			label: `${TWO_HUNDRED}%`
		}
	];
	if (!val) return options;
	return options.find(opt => +opt.value === val);
};

const computeTimesheet = ({ data }) =>
	client(COMPUTE_TIMESHEET, { body: data });

const EditWorkTime = ({
	month,
	disabled,
	tagOptions,
	projectName,
	featureTimesheetTags,
	tagOverlayText,
	getInitialVisibleMonth,
	isAllElementHadChecked,
	disputedWorkTime,
	setDisputedWorkTime,
	dispatch,
	list,
	type,
	itemSelected,
	canApproveTags,
	isClient,
	dirty,
	...props
}) => {
	const [onComputeTimesheet] = useMutation(computeTimesheet, {
		onSuccess: (data, { payload }) => {
			const { cancelEdit } = props;
			const mappedIDS = data.dispute_items.worktimes.edited.map(
				({ _id }) => _id
			);

			const mappedDiputedWorktimeIds = disputedWorkTime.map(
				({ worktime_id }) => worktime_id
			);
			const disputedItem = [...new Set(isAllElementHadChecked)].map(id => {
				const index = mappedDiputedWorktimeIds.indexOf(id);
				const listIndex = list.map(({ _id }) => _id).indexOf(id);
				const amount =
					data.dispute_items.worktimes.edited[mappedIDS.indexOf(id)].amount;
				if (index >= 0) {
					return {
						...disputedWorkTime[index],
						...payload,
						[`${type === CLIENT ? CLIENT : FREELANCER}_amount`]: amount,
						status: "edited"
					};
				}
				const {
					rate,
					date,
					description,
					hours,
					hours_representation,
					tag
				} = list[listIndex];

				return {
					worktime_id: id,
					...{
						rate,
						date,
						description,
						hours: hours * 60,
						hours_representation,
						tag
					},
					...payload,
					[`${type === CLIENT ? CLIENT : FREELANCER}_amount`]: amount,
					status: "edited"
				};
			});

			const removeDuplicateItem = disputedWorkTime.filter(({ worktime_id }) => {
				return [...new Set(isAllElementHadChecked)].indexOf(worktime_id) < 0;
			});

			const result = [...disputedItem, ...removeDuplicateItem];

			setDisputedWorkTime(result);

			dispatch(updateAmounts(data));
			cancelEdit();
		},
		onError: error => {
			toaster.danger(get(error, "detail.name", get(error, "title")));
		}
	});
	const hasTag =
		featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
		get(tagOptions, "length", 0) > 0;

	const beyondMaxHours = (date, hours) => {
		const worktimes = [
			...get(month, "worktimes").filter(
				({ _id }) =>
					disputedWorkTime.map(({ worktime_id }) => worktime_id).indexOf(_id) <
					0
			),
			...disputedWorkTime.map(item => ({ ...item, hours: item.hours / 60 }))
		];
		let total = hours;
		if (hours === false) {
			const filtredWorktimes = worktimes.filter(({ _id }) => {
				return [...new Set(isAllElementHadChecked)].indexOf(_id) >= 0;
			});
			if (filtredWorktimes.length === 1) {
				total += filtredWorktimes[0].hours * 60;
			} else {
				total +=
					filtredWorktimes.reduce((acc, curr) => {
						const accVal = typeof acc === "object" ? acc.hours : acc;
						return accVal + curr.hours;
					}, 0) * 60; // total worked hours in minutes
			}
		}

		const filtredWorktimes = worktimes.filter(({ _id }) => {
			return [...new Set(isAllElementHadChecked)].indexOf(_id) < 0;
		});

		const worktimesInDate = filtredWorktimes.filter(
			worktime =>
				worktime.date >
					utcTimeStamp({
						date: date,
						format: DATE_FORMAT,
						manageTime: true
					}) &&
				worktime.date <
					utcTimeStamp({
						date: date,
						format: DATE_FORMAT,
						manageTime: true,
						isStart: false
					})
		);
		if (!worktimesInDate.length) return total > MAX_HOURS_PER_DAY * 60;
		if (worktimesInDate.length === 1) {
			total += worktimesInDate[0].hours * 60;
			return total > MAX_HOURS_PER_DAY * 60; // max per day in minutes
		}
		total +=
			worktimesInDate.reduce((acc, curr) => {
				const accVal = typeof acc === "object" ? acc.hours : acc;
				return accVal + curr.hours;
			}) * 60; // total worked hours in minutes
		return total > MAX_HOURS_PER_DAY * 60; // max per day in minutes
	};

	const handleSubmit = formProps => {
		const { cancelEdit, timesheetId, isOverTime } = props;
		const payload = { ...formProps };
		payload.is_overtime = isOverTime;
		if (payload.tag) {
			hasTag &&
				(payload.tag =
					typeof payload.tag === "string"
						? payload.tag
						: get(payload, "tag.value", ""));
		}

		if (payload.rate) {
			payload.rate =
				typeof payload.rate === "string" ? payload.rate : payload.rate.value;
			if (!payload.hours) {
				const mappedDiputedWorktimeIds = disputedWorkTime.map(
					({ worktime_id }) => worktime_id
				);
				const disputedItem = [...new Set(isAllElementHadChecked)].map(id => {
					const index = mappedDiputedWorktimeIds.indexOf(id);
					const listIndex = list.map(({ _id }) => _id).indexOf(id);
					if (index >= 0) {
						return {
							_id: id,
							rate: payload.rate,
							hours: disputedWorkTime[index].hours
						};
					}

					return {
						_id: id,
						rate: get(payload, "rate", null),
						hours: list[listIndex].hours * 60
					};
				});

				onComputeTimesheet({
					data: {
						timesheet_id: timesheetId,
						dispute_items: {
							worktimes: { edited: disputedItem, added: [] },
							expenses: {
								edited: [],
								added: []
							}
						}
					},
					payload
				});
			}
		}

		if (payload.date) {
			const current = window.moment();
			if (payload.hours) {
				if (
					beyondMaxHours(
						payload.date,
						(formProps.hours.hours() * 60 + formProps.hours.minutes()) *
							itemSelected
					)
				) {
					return toaster.danger(
						`It seems that you have exceeded the amount of hours (${MAX_HOURS_PER_DAY}h) allowed in the same day
						(${window.moment
							.unix(
								utcTimeStamp({
									date: payload.date,
									format: DATE_FORMAT,
									manageTime: false
								})
							)
							.format(DATE_FORMAT)}).`,
						{ duration: 10, id: "err" }
					);
				}
			} else {
				if (beyondMaxHours(payload.date, false)) {
					return toaster.danger(
						`It seems that you have exceeded the amount of hours (${MAX_HOURS_PER_DAY}h) allowed in the same day
						(${window.moment
							.unix(
								utcTimeStamp({
									date: payload.date,
									format: DATE_FORMAT,
									manageTime: false
								})
							)
							.format(DATE_FORMAT)}).`,
						{ duration: 10, id: "err" }
					);
				}
			}
			payload.date.set({
				hour: current.get("hour"),
				minute: current.get("minute"),
				second: current.get("second")
			});
			payload.date = utcTimeStamp({
				date: payload.date,
				format: DATE_FORMAT,
				manageTime: false
			});
		}
		if (payload.hours) {
			payload.hours = formProps.hours.hours() * 60 + formProps.hours.minutes();
			payload.hours_representation = window
				.moment(formProps.hours)
				.format("HH:mm");

			const mappedDiputedWorktimeIds = disputedWorkTime.map(
				({ worktime_id }) => worktime_id
			);
			const disputedItem = [...new Set(isAllElementHadChecked)].map(id => {
				const index = mappedDiputedWorktimeIds.indexOf(id);
				const listIndex = list.map(({ _id }) => _id).indexOf(id);
				if (index >= 0) {
					return {
						_id: id,
						rate: payload.rate || disputedWorkTime[index].rate,
						hours: payload.hours
					};
				}

				return {
					_id: id,
					rate: get(payload, "rate", null) || list[listIndex].rate,
					hours: payload.hours
				};
			});

			if (!payload.date) {
				const hoursToMinutes = list.map(item => ({
					...item,
					hours: item.hours * 60
				}));

				const mergedList = hoursToMinutes.map(item => {
					const indexOfItemIndisputedItems = mappedDiputedWorktimeIds.indexOf(
						item._id
					);
					if (indexOfItemIndisputedItems >= 0) {
						return {
							...disputedWorkTime[indexOfItemIndisputedItems]
						};
					}
					return item;
				});
				const isOver24Hours = getHoursInEachDay(
					[...new Set(isAllElementHadChecked)],
					mergedList,
					payload.hours
				);
				if (isOver24Hours) {
					return toaster.danger(
						`It seems that you have exceeded the amount of hours (${MAX_HOURS_PER_DAY}h) allowed in the same day
						(${window.moment.unix(isOver24Hours).format(DATE_FORMAT)}).`,
						{ duration: 10, id: "err" }
					);
				}
			}

			onComputeTimesheet({
				data: {
					timesheet_id: timesheetId,
					dispute_items: {
						worktimes: { edited: disputedItem, added: [] },
						expenses: {
							edited: [],
							added: []
						}
					}
				},
				payload
			});
		}

		const mappedDiputedWorktimeIds = disputedWorkTime.map(
			({ worktime_id }) => worktime_id
		);
		const disputedItem = [...new Set(isAllElementHadChecked)].map(id => {
			const index = mappedDiputedWorktimeIds.indexOf(id);
			const listIndex = list.map(({ _id }) => _id).indexOf(id);
			if (index >= 0) {
				return {
					...disputedWorkTime[index],
					...payload,
					status: "edited"
				};
			}

			const {
				rate,
				date,
				description,
				hours,
				hours_representation,
				tag
			} = list[listIndex];

			return {
				worktime_id: id,
				...{
					rate,
					date,
					description,
					hours: payload.hours ? hours : hours * 60,
					hours_representation,
					tag
				},
				...payload,
				status: "edited"
			};
		});

		const removeDuplicateItem = disputedWorkTime.filter(({ worktime_id }) => {
			return [...new Set(isAllElementHadChecked)].indexOf(worktime_id) < 0;
		});

		const result = [...disputedItem, ...removeDuplicateItem];

		setDisputedWorkTime(result);

		cancelEdit();
	};

	const minDate = window.moment.unix(month.start_date).toDate();
	const maxDate = window.moment.unix(month.end_date).toDate();

	return (
		<React.Fragment>
			<Flex
				sx={{
					gridArea: "cta"
				}}
				justifyContent="flex-end"
				alignItems="center"
			>
				<button className="cancel-button" onClick={props.cancelEdit}>
					<CancelIcon style={{ marginRight: "8px" }} /> Cancel
				</button>
				<Button
					color="success"
					small
					onClick={props.handleSubmit(handleSubmit)}
					key="accept"
					style={{
						height: "45px"
					}}
					disabled={!dirty}
				>
					<Icon className="fa icon-check" style={{ marginRight: "8px" }} />{" "}
					Apply
				</Button>
			</Flex>

			<FormContainerMultipleEdit>
				<Flex width="100%" justifyContent="space-between" alignItems="center">
					<Box>
						<Field
							classes="inner-icon left-icon"
							icon="icon-calendar"
							name="date"
							label="Date"
							placeholder="Date"
							component={DatePickerField}
							initialVisibleMonth={getInitialVisibleMonth}
							minDate={minDate}
							maxDate={maxDate}
							disabled={disabled}
						/>
					</Box>

					<Box
						sx={{
							flex: "1"
						}}
						mx={30}
					>
						<Field
							type="text"
							placeholder="Description"
							label="Description"
							name="description"
							position="bottom"
							disabled={disabled}
							component={FormField}
							autoComplete="off"
						/>
					</Box>

					{hasTag && (
						<Box minWidth="180px">
							<Field
								placeholder={projectName || "TAG"}
								label={projectName || "TAG"}
								name="tag"
								disabled={disabled}
								options={
									isClient
										? canApproveTags.length > 0
											? canApproveTags
											: tagOptions
										: tagOptions
								}
								toolTipOverlay={tagOverlayText}
								menuPosition="top"
								component={StyledSelect}
							/>
						</Box>
					)}

					<Box mx={30}>
						<Field
							name="hours"
							label="Amount of hours"
							component={FormField}
							disabled={disabled}
							type="timepicker"
						/>
					</Box>

					<Box mr={24} minWidth="80px">
						<Field
							placeholder="Rate"
							name="rate"
							label="Rate"
							disabled={disabled}
							options={rateOptions()}
							menuPosition="top"
							component={StyledSelect}
							tabIndex={0}
						/>
					</Box>
				</Flex>
			</FormContainerMultipleEdit>
		</React.Fragment>
	);
};

const mapStateToProps = state => {
	return {
		editItem: state.timesheet.editItem,
		featureTimesheetTags: featureTimesheetTagsSelector(state),
		tagOptions: timesheetTagsSelectSelector(state),
		projectName: timesheetTagsProjectNameSelector(state)
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "multi-edit-worktime",
		touchOnBlur: false
	})
)(EditWorkTime);
