import React from "react";
import _get from "lodash/get";
import loadable from "loadable-components";
import Scroller from "react-scroll";
import ReactCountryFlag from "react-country-flag";

import GlobalTooltip from "common/GlobalTooltip";
import countryData from "config/countries.json";
import { mapOrder } from "config/helpers";

const Select = loadable(() =>
	import(/* webpackChunkName: "react-select" */ "react-select")
);

function CountryFlagSelect(props) {
	const singleValue = props => {
		return (
			<div className="Select-value">
				<span className="Select-value-label">
					<ReactCountryFlag
						styleProps={{
							marginRight: "6px",
							bottom: 1
						}}
						code={props && props.value.code.toLowerCase()}
						svg
					/>
					{props.children}
				</span>
			</div>
		);
	};

	const renderOption = option => {
		return (
			<>
				<ReactCountryFlag
					styleProps={{
						marginRight: "6px",
						bottom: 1
					}}
					code={option.code.toLowerCase()}
					svg
				/>
				{option.label}
			</>
		);
	};

	const {
		label,
		input,
		meta: { touched, error },
		placeholder,
		disabled = false,
		clearable = false,
		simpleValue = false,
		classes,
		toolTipOverlay,
		preferredCountries
	} = props;

	const hasError = touched && error;

	let classesToAdd = hasError ? "has-error has-feedback" : "";

	if (label) {
		classesToAdd += " has-label";
	} else {
		classesToAdd += " label-no";
	}

	const Element = Scroller.Element;
	const value =
		typeof input.value === "object" && "length" in input.value
			? ""
			: input.value.value || input.value;

	let countries = _get(props, "customList", countryData.all);
	if (preferredCountries) {
		countries = mapOrder(countries, preferredCountries, "code");
	}

	countries = countries.map(item => ({
		label: item.country,
		value: item.country,
		code: item.code,
		currency: item.currency
	}));
	return (
		<Element
			className={`form-group select-style ${classesToAdd}`}
			name={input.name}
			scrollto="scroll-to-error"
		>
			{label && (
				<label
					style={{ marginBottom: toolTipOverlay ? -1 : 6 }}
					htmlFor={input.name}
				>
					{label}
					{toolTipOverlay && (
						<GlobalTooltip maxWidth="480px" overlay={toolTipOverlay} />
					)}
				</label>
			)}
			<Select
				name={input.name}
				value={value}
				options={countries}
				clearable={clearable}
				placeholder={placeholder}
				simpleValue={simpleValue}
				searchable={true}
				matchPos="start"
				matchProp="label"
				onChange={input.onChange}
				onBlur={() => input.onBlur(input.value)}
				disabled={disabled}
				className={classes}
				valueComponent={singleValue}
				optionRenderer={renderOption}
			/>
			{hasError && <div className="help-block inline-error">{hasError}</div>}
		</Element>
	);
}

export default CountryFlagSelect;
