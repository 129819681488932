import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { useQuery } from "react-query";

const getSites = () =>
	client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "site_list",
			list: "vacancies"
		}
	});

function useGetSites({ key = "@getSites", queryOptions }) {
	return useQuery(key, getSites, {
		refetchOnWindowFocus: false,
		...queryOptions
	});
}

export default useGetSites;
