import React from "react";
import CustomDropDown from "common/CustomDropDown/CustomDropDown";
import { CONSULTANCY, FREELANCER } from "config";

const ProfileTypeSelect = props => (
	<CustomDropDown
		{...props}
		options={[
			{ value: FREELANCER, label: "Freelancer" },
			{ value: CONSULTANCY, label: "Consultancy" }
		]}
	/>
);

export default ProfileTypeSelect;
