import React from "react";
import Button from "../Button/Button";
import styles from "./calendar-grid-header.module.scss";
import cx from "classnames";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

const CalendarGridHeader = ({
	prevButtonProps,
	nextButtonProps,
	title,
	classNames
}) => {
	const arr = title.split(" ");

	return (
		<div className={cx(styles.header, classNames?.h40)}>
			<Button
				className={cx(classNames?.whiteButton || styles.button, {
					[styles.buttonLeft]: true
				})}
				{...prevButtonProps}
			>
				<ChevronLeftIcon className={styles.chevronIcon} />
			</Button>
			<div className={cx(styles.text, classNames?.bigText)}>
				<span className={styles.span}>{arr[0]}</span>
				{arr[1]}
			</div>

			<Button
				className={cx(classNames?.whiteButton || styles.button, {
					[styles.buttonRight]: true
				})}
				{...nextButtonProps}
			>
				<ChevronRightIcon className={styles.chevronIcon} />
			</Button>
		</div>
	);
};

export default CalendarGridHeader;
