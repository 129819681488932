import styled from "styled-components";

export const TabsContainer = styled.div`
	&#languagesKey {
		[data-reach-tab-list] {
			background-color: white;
			margin-bottom: 0;
		}

		[data-reach-tab][data-selected] {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			svg {
				display: block;
			}
			color: #f95a51;
			border-bottom: 2px solid #ffe1db;
			border-top: 2px solid #fff4f4;
			border-right: 2px solid #fff4f4;
			border-left: 2px solid #fff4f4;
			background-color: #fff4f4;
			font-family: "basiercircle";
			font-weight: normal;
		}
		[data-reach-tab] {
			svg {
				display: none;
			}
			color: black;
			text-transform: uppercase;
			font-family: "basiercircle";
			margin: 5px;
			border-radius: 3px;
			font-weight: 500;
			font-size: 14px;
		}
		.title {
			font-family: "basiercircle";
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			color: #6d7175;
			margin-bottom: 8px;
		}
		.arabic {
			direction: rtl;
		}
	}
`;
