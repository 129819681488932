import React from "react";
import Select from "react-select";
import _get from "lodash/get";
import { TopSectionWrapper } from "./Styled";
import DatePicker from "./DatePicker";

const TopSection = ({
	supplier,
	setSupplier,
	category,
	setCategory,
	categoriesData,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	listSupliers,
	pickerRef,
	displayPicker,
	setDisplayPicker,
	togglePicker,
	departement,
	setDepartement,
	suppliersDepartement
}) => {
	const ds = [
		{ name: "All suppliers", _id: "all" },
		{ name: "Tier 1", _id: "tier 1" },
		{ name: "Tier 2", _id: "tier 2" },
		{ name: "Tier 3", _id: "tier 3" },
		...listSupliers
	];
	const dataOptions = ds.reduce((list, d) => {
		list.push({
			value: d._id,
			label: d.name
		});
		return list;
	}, []);

	return (
		<TopSectionWrapper>
			<div className="section-select">
				<Select
					value={departement}
					options={_get(suppliersDepartement, "groups", []).map(
						departement => ({
							value: departement._id,
							label: departement.name
						})
					)}
					onChange={setDepartement}
					placeholder={"Select a departement..."}
					clearable={false}
				/>
			</div>
			<div className="section-select">
				<Select
					value={category}
					options={categoriesData?.map(category => ({
						value: category._id,
						label: category.name
					}))}
					onChange={setCategory}
					placeholder={"Select a Category..."}
					clearable={false}
				/>
			</div>
			<div className="section-select">
				<Select
					value={supplier}
					options={dataOptions}
					onChange={supplier => setSupplier(supplier)}
					placeholder={"Select Supplier..."}
					clearable={false}
				/>
			</div>
			<div className="section-date-picker">
				<DatePicker
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					pickerRef={pickerRef}
					displayPicker={displayPicker}
					setDisplayPicker={setDisplayPicker}
					togglePicker={togglePicker}
				/>
			</div>
		</TopSectionWrapper>
	);
};
export default TopSection;
