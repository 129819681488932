// Global actions
export const WRITE_TIMESHEET = "WRITE_TIMESHEET";
export const WRITE_ERROR = "WRITE_ERROR";
export const START_FETCHING = "START_FETCHING";
export const INITIALIZE_TIMESHEET = "INITIALIZE_TIMESHEET";
export const STOP_FETCHING = "STOP_FETCHING";
export const SWITCH_VIEW = "SWITCH_VIEW";
export const SWITCH_STATUS_VIEW = "SWITCH_STATUS_VIEW";
export const HANDLE_DOCUMENT_UPLOAD = "HANDLE_DOCUMENT_UPLOAD";
export const SET_TIME = "SET_TIME";
export const TOGGLE_ACTION = "TOGGLE_ACTION";
export const ACCEPT_ALL = "ACCEPT_ALL";
export const SHOW_REASON_MODAL = "SHOW_REASON_MODAL";
export const TOGGLE_DELETE_MODAL = "TOGGLE_DELETE_MODAL";
export const TOGGLE_DISPUTE_MODAL = "TOGGLE_DISPUTE_MODAL";
export const CLOSE_REASON_MODAL = "CLOSE_REASON_MODAL";
export const EMPTY_CURRENT_DOCUMENT = "EMPTY_CURRENT_DOCUMENT";
export const EMPTY_CURRENT_DOCUMENT_EDIT_MODE =
	"EMPTY_CURRENT_DOCUMENT_EDIT_MODE";
export const EMPTY_CURRENT_DOCUMENT_MULTI_EDIT_MODE =
	"EMPTY_CURRENT_DOCUMENT_MUTI_EDIT_MODE";
export const SAVE_TIMESHEET_LIST = "SAVE_TIMESHEET_LIST";
// Vendor actions

export const UPDATE_ITEM = "UPDATE_ITEM";
export const EDIT_ITEM = "EDIT_ITEM";
export const UPDATE_COMPUTED = "UPDATE_COMPUTED";
// Client actions
export const DISPUTE_ENTRY = "DISPUTE_ENTRY";

// Vendor disputed
export const ACCEPT_DISPUTED_EXPENSE = "ACCEPT_DISPUTED_EXPENSE";
export const ACCEPT_DISPUTED_WORKTIME = "ACCEPT_DISPUTED_WORKTIME";
export const REFUSE_DISPUTED_EXPENSE = "REFUSE_DISPUTED_EXPENSE";
export const REFUSE_DISPUTED_WORKTIME = "REFUSE_DISPUTED_WORKTIME";
export const TOGGLE_DISPUTED_MODAL = "TOGGLE_DISPUTED_MODAL";
export const APPROVE_DISPUTED_ITEM = "APPROVE_DISPUTED_ITEM";
export const REFUSE_DISPUTED_ITEM = "REFUSE_DISPUTED_ITEM";
// Dispute warning
export const TOGGLE_DISPUTE_WARNING = "TOGGLE_DISPUTE_WARNING";
export const TOGGLE_DISPUTE_TIMESHEET = "TOGGLE_DISPUTE_TIMESHEET";

export const TOGGLE_RESUBMIT_TIMESHEET = "TOGGLE_RESUBMIT_TIMESHEET";

//toggle disputed
export const TOGGLE_GENERAL_DISPUTE = "TOGGLE_GENERAL_DISPUTE";

export const CATCH_NOTIFICATIONS_TIMESHEETS_XLS =
	"CATCH_NOTIFICATIONS_TIMESHEETS_XLS";
export const CATCH_NOTIFICATIONS_TIMESHEETS_PDF =
	"CATCH_NOTIFICATIONS_TIMESHEETS_PDF";
export const CATCH_NOTIFICATIONS_EVENT_TIMESHEET_RESUBMITTED =
	"CATCH_NOTIFICATIONS_EVENT_TIMESHEET_RESUBMITTED";
export const CATCH_NOTIFICATIONS_EVENT_TIMESHEET_DISPUTED =
	"CATCH_NOTIFICATIONS_EVENT_TIMESHEET_DISPUTED";

// file form fields
export const SET_FILE_FORM = "SET_FILE_FORM";
export const SET_FIELD_INDEX = "SET_FIELD_INDEX";
