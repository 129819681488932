import React, { useMemo, memo } from "react";
import find from "lodash/find";
import { hexToRgbA } from "modules/candidate/components/profileClientView/profileModalTab/components/interactions/constants";
import {
	CONFIRMED,
	HIRED,
	ACCEPTED,
	LIVE,
	PUBLISHED,
	EXPIRED,
	DECLINED,
	FILLED,
	SENT,
	DRAFT,
	SIGNED,
	CANCELLED,
	REFUSED,
	REJECTED,
	DISPUTED,
	IN_MEDIATION,
	DONE,
	SUBMITTED,
	ONBOARDED,
	UNPUBLISHED,
	DELETED,
	EXTENDED,
	INTERRUPTED,
	POKE_DECLINED,
	SHORTLISTED,
	INTERVIEW_DECLINED,
	FINISHED,
	APPROVED,
	OPEN_FOR_INTERNAL_USE,
	CLOSED_TO_NEW_APPLICANTS,
	APPLIED,
	APPLY_CANCELLED,
	REQUEST_INTERRUPTION
} from "config";
import { colors } from "config/styles";
import StyledLabel, { FlexBox } from "./style";
import cx from "classnames";

const COLORS_BY_STATUS = [
	{
		status: [CONFIRMED, ONBOARDED, DONE],
		color: "white",
		backgroundColor: colors.interestConfirmed
	},
	{
		status: [OPEN_FOR_INTERNAL_USE],
		color: "white",
		backgroundColor: colors.internalColor
	},
	{
		status: [FILLED],
		color: colors.primaryColor,
		borderColor: colors.primaryColor,
		backgroundColor: "#ff6849",
		borderWidth: "0.5px",
		borderStyle: "solid"
	},
	{
		status: [HIRED, ACCEPTED, LIVE, SIGNED, APPROVED],
		color: "white",
		backgroundColor: colors.stepperIconColor
	},
	{
		status: [PUBLISHED],
		color: "white",
		backgroundColor: colors.greenModalBgColor
	},
	{
		status: [EXPIRED],
		color: colors.anchorsColor,
		backgroundColor: colors.lighterGray
	},
	{
		status: [SENT, UNPUBLISHED, INTERVIEW_DECLINED],
		color: "white",
		backgroundColor: colors.stoppedColor
	},
	{
		status: [CANCELLED, REFUSED, REJECTED],
		color: colors.anchorsColor,
		backgroundColor: colors.btnDisabled
	},
	{
		status: [EXPIRED, DISPUTED, IN_MEDIATION, DECLINED],
		color: "white",
		backgroundColor: colors.paused
	},
	{
		status: [SUBMITTED],
		color: "white",
		backgroundColor: colors.submittedColor
	},
	{
		status: [DRAFT],
		color: "white",
		backgroundColor: colors.disabledGray
	},
	{
		status: [DELETED],
		color: colors.anchorsColor,
		backgroundColor: colors.bodyBg
	},
	{
		status: [EXTENDED],
		color: "white",
		backgroundColor: colors.extendedColor
	},
	{
		status: [INTERRUPTED, POKE_DECLINED],
		color: "white",
		backgroundColor: colors.errorColor
	},
	{
		status: [SHORTLISTED],
		color: colors.stepperIconColor,
		borderColor: colors.stepperIconColor,
		borderWidth: "1px",
		borderStyle: "solid",
		backgroundColor: "white"
	},
	{
		status: [FINISHED],
		color: "white",
		backgroundColor: colors.primaryColor
	},
	{
		status: [CLOSED_TO_NEW_APPLICANTS],
		color: "white",
		backgroundColor: colors.closeColor
	},
	{
		status: [APPLIED],
		color: "white",
		backgroundColor: colors.appliedColor
	},
	{
		status: [APPLY_CANCELLED],
		color: colors.anchorsColor,
		backgroundColor: colors.btnDisabled,
		className: "applyCancelled"
	},
	{
		status: [REQUEST_INTERRUPTION],
		color: "white",
		backgroundColor: "#C44569"
	}
];

function StatusVacancy({
	status,
	label,
	isPoke,
	isInterview,
	is_applied,
	isVacancies,
	className,
	...rest
}) {
	const getColors = status => {
		let finalStatus = status;

		if (isPoke && status === DECLINED) {
			finalStatus = POKE_DECLINED;
		}
		if (isInterview && status === DECLINED) {
			finalStatus = INTERVIEW_DECLINED;
		}
		if (status === CANCELLED && is_applied) {
			finalStatus = APPLY_CANCELLED;
		}

		if (status === REJECTED && is_applied) {
			finalStatus = APPLY_CANCELLED;
		}
		return (
			find(COLORS_BY_STATUS, obj => {
				return obj.status.includes(finalStatus);
			}) || {
				color: "white",
				backgroundColor: "white"
			}
		);
	};

	const statusColor = useMemo(() => getColors(status), [status]);
	return (
		<FlexBox borderStatus={hexToRgbA(statusColor.backgroundColor, 0.14)}>
			<StyledLabel
				backgroundColor={statusColor.backgroundColor}
				color={statusColor.color}
				borderColor={statusColor.borderColor || ""}
				borderWidth={statusColor.borderWith}
				borderStyle={statusColor.borderStyle}
				filled={status === FILLED}
				className={cx(statusColor.className)}
				{...rest}
				isVacancies={isVacancies}
			></StyledLabel>
			<div className={className}>{label}</div>
		</FlexBox>
	);
}

export default memo(StatusVacancy);
