import React, { Component } from "react";
import Menu, { Item as MenuItem } from "rc-menu";
import "rc-menu/assets/index.css";
import PropTypes from "prop-types";
import { duplicateJob, stopJobModal } from "modules/job/actions";
import { connect } from "react-redux";
import { historyPush } from "config/helpers";
import { getCreateRequestRoute } from "modules/CreateRequestModule/CreateRequestModule";
import style from "./request-view-head.module.scss";
import cx from "classnames";
import {
	ADD_NEW_STEP,
	PUBLISHED,
	ARCHIVE_REQUEST,
	UNPUBLISHED,
	DRAFT,
	REQUEST,
	ADD_TASK,
	UPDATE,
	UNPUBLISH,
	PAUSE_REQUEST,
	DUPLICATED
} from "config";

const initialState = {
	dropdownPosition: "bottomCenter",
	align: {
		points: ["tc", "bc"],
		offset: [0, 0]
	},
	displayWarningModal: false,
	displayPublishErrorDialog: false
};

class RequestViewActionsList extends Component {
	state = initialState;

	onVisibleChange = visible => {
		const { id, reposition } = this.props;
		const windowHeight = window.innerHeight;
		setTimeout(() => {
			const element = document.getElementsByClassName(`menu-${id}`)[0];
			if (!element) return;
			if (reposition && visible) {
				const boundingBox = element.getBoundingClientRect();
				if (boundingBox.y + boundingBox.height > windowHeight) {
					this.setState({
						dropdownPosition: "topCenter",
						align: {
							points: ["bc", "tc"],
							offset: [-4, 32]
						}
					});
				}
			} else if (reposition) {
				this.setState(initialState);
			}
			element.classList.add("rc-shown");
		}, 0);

		this.props.onVisibleChange();
	};

	onUnpublish = () => {
		this.props.dispatch(
			stopJobModal({
				id: this.props.vacancy.id,
				title: this.props.vacancy.title,
				mode: PAUSE_REQUEST
			})
		);
	};

	handleMenuClick = e => {
		const { vacancy } = this.props;

		switch (e.key) {
			case DUPLICATED:
				return this.props.dispatch(duplicateJob(vacancy.id, vacancy.type));

			case UNPUBLISH:
				return this.onUnpublish();

			case UPDATE:
				return historyPush(getCreateRequestRoute(vacancy.id));

			case ARCHIVE_REQUEST:
				return this.props.dispatch(
					stopJobModal({
						id: vacancy.id,
						title: vacancy.title,
						mode: ARCHIVE_REQUEST
					})
				);

			case ADD_NEW_STEP:
				this.props.onAddNewStepClick();
				break;

			case ADD_TASK:
				this.props.setEntity(REQUEST);
				this.props.setSelectedEntityOption({
					label: vacancy.title,
					value: vacancy.id
				});
				return this.props.openDrawerTask(true);

			default:
				break;
		}
	};

	listMenu = () => {
		const isDraftOrPaused =
			this.props.vacancy.status === DRAFT ||
			this.props.vacancy.status === UNPUBLISHED;

		return (
			<Menu
				onClick={e => this.handleMenuClick(e)}
				selectable={false}
				className={"vacancy_options_menu"}
			>
				<li className="headlineMenuVacancy">Request options</li>
				<MenuItem key={DUPLICATED} className={`ignore-click ${DUPLICATED}`}>
					<span className="ignore-click">Duplicate</span>
				</MenuItem>
				{this.props.vacancy.status === PUBLISHED && (
					<MenuItem key={UNPUBLISH} className={`ignore-click ${UNPUBLISH}`}>
						<span className="ignore-click">Unpublish</span>
					</MenuItem>
				)}
				{this.props.vacancy.status === DRAFT && (
					<MenuItem key={UPDATE} className={`ignore-click ${UPDATE}`}>
						<span className="ignore-click">Edit</span>
					</MenuItem>
				)}
				{isDraftOrPaused && (
					<MenuItem
						key={ARCHIVE_REQUEST}
						className={`ignore-click ${ARCHIVE_REQUEST}`}
					>
						Archive
					</MenuItem>
				)}
				<MenuItem key={ADD_TASK} className={`ignore-click ${ADD_TASK}`}>
					Add task
				</MenuItem>
				<MenuItem key={ADD_NEW_STEP} className={`ignore-click ${ADD_NEW_STEP}`}>
					Add step
				</MenuItem>
			</Menu>
		);
	};

	render() {
		return (
			<div className={cx(style.menu, "menu-actions")}>{this.listMenu()}</div>
		);
	}
}

RequestViewActionsList.propTypes = {
	onVisibleChange: PropTypes.func
};

RequestViewActionsList.defaultProps = {
	onVisibleChange: () => {}
};

export default connect()(RequestViewActionsList);
