import React from "react";
import { get } from "lodash";
import ListMenu from "../../ListMenu";
import TimeChooser from "./TimeChooser";
import DateRange from "./TimeChooser/DateRange";
import UsersSelect from "./CompoundSelect";
import {
	DUE_DATE,
	REMINDER,
	PRIORITY,
	LINK_TO,
	CREATED_BY,
	ASSIGNED_TO,
	CREATION_DATE,
	CREATED_AT,
	STATUS,
	LINK_TO_NOTES,
	NOTE_TYPE,
	INTERACTIONS,
	USER_ID,
	ALLOWED_USERS,
	TYPE
} from "config";
import { ReactComponent as EmptyState } from "static/icons/empty-state-lis-menu.svg";

const RenderFilterOptionsMenu = ({
	field,
	op,
	closeMenu,
	onSelect,
	value,
	openFilterOptions,
	options,
	searchKeyword,
	setSearchKeyword,
	filtredOptions,
	selectedOption,
	id
}) => {
	let selectTitle = "";
	let inputPlaceholder = "";

	switch (field) {
		case NOTE_TYPE:
		case STATUS:
		case PRIORITY:
		case INTERACTIONS:
		case TYPE:
			const filtredStatusOptions =
				options &&
				options.filter(item => !(value && value.includes(item.value)));

			const handleOnSelect = option => {
				onSelect([...value, get(option, "value")]);
				closeMenu();
			};
			return (
				openFilterOptions && (
					<ListMenu
						closeMenu={closeMenu}
						options={filtredStatusOptions}
						onSelect={handleOnSelect}
						isSearchable={false}
						searchKeyword={searchKeyword}
						setSearchKeyword={setSearchKeyword}
						id={id}
					/>
				)
			);
		case REMINDER:
			return (
				openFilterOptions && (
					<ListMenu
						closeMenu={closeMenu}
						options={options}
						onSelect={value => {
							onSelect(value);
							closeMenu();
						}}
						isSearchable={false}
						searchKeyword={searchKeyword}
						setSearchKeyword={setSearchKeyword}
						id={id}
					/>
				)
			);
		case ALLOWED_USERS:
			const includedItemsAllowedUsers = options.filter(item =>
				value.includes(get(item, "_id"))
			);
			return (
				openFilterOptions && (
					<UsersSelect
						isLoading={false}
						rightPosition
						top={5}
						includedItems={includedItemsAllowedUsers}
						setIncludedItems={cb => onSelect(cb(includedItemsAllowedUsers))}
						data={options}
						setOpen={closeMenu}
						open
						id={id}
						selectTitle="Visibility"
						inputPlaceholder="Search a member..."
					></UsersSelect>
				)
			);
		case CREATED_BY:
			const includedItemsCreactedBy = options.filter(item =>
				value.includes(get(item, "_id"))
			);
			return (
				openFilterOptions && (
					<UsersSelect
						isLoading={false}
						rightPosition
						top={5}
						includedItems={includedItemsCreactedBy}
						setIncludedItems={cb => onSelect(cb(includedItemsCreactedBy))}
						data={options}
						setOpen={closeMenu}
						open
						id={id}
						selectTitle="Created by"
						inputPlaceholder="Search a member..."
					></UsersSelect>
				)
			);
		case USER_ID:
			const includedItemsUser = options.filter(item =>
				value.includes(get(item, "_id"))
			);
			return (
				openFilterOptions && (
					<UsersSelect
						isLoading={false}
						rightPosition
						top={5}
						includedItems={includedItemsUser}
						setIncludedItems={cb => onSelect(cb(includedItemsUser))}
						data={options}
						setOpen={closeMenu}
						open
						id={id}
						selectTitle="User"
						inputPlaceholder="Search a member..."
					></UsersSelect>
				)
			);
		case ASSIGNED_TO:
			const includedItemsAssigned = options.filter(item =>
				value.includes(get(item, "_id"))
			);
			selectTitle =
				get(selectedOption, "value") === "internal"
					? "Assign to colleague"
					: "Assign to supplier";
			inputPlaceholder =
				get(selectedOption, "value") === "internal"
					? "Search a colleague..."
					: "Search a supplier...";

			return (
				openFilterOptions && (
					<UsersSelect
						isLoading={false}
						rightPosition
						top={5}
						includedItems={includedItemsAssigned}
						setIncludedItems={cb => onSelect(cb(includedItemsAssigned))}
						data={options}
						setOpen={closeMenu}
						open
						id={id}
						selectTitle={selectTitle}
						inputPlaceholder={inputPlaceholder}
					></UsersSelect>
				)
			);
		case CREATED_AT:
		case CREATION_DATE:
		case DUE_DATE:
			if (op !== "range") {
				return (
					openFilterOptions && (
						<TimeChooser
							onChange={onSelect}
							value={value}
							closeMenu={closeMenu}
							id={id}
						/>
					)
				);
			}
			return (
				openFilterOptions && (
					<DateRange
						onChange={onSelect}
						closeMenu={closeMenu}
						value={value}
						id={id}
					/>
				)
			);
		case LINK_TO_NOTES:
		case LINK_TO:
			if (
				get(value, "entity", "") === "candidate" ||
				get(value, "entity", "") === "freelancer" ||
				get(value, "entity", "") === "profile" ||
				get(value, "entity", "") === "supplier"
			) {
				const includedItems = options.filter(item =>
					get(value, "_id", []).includes(get(item, "_id"))
				);
				selectTitle =
					get(selectedOption, "value") === "candidate"
						? "Add candidate"
						: get(selectedOption, "value") === "profile"
						? "Add profile"
						: get(selectedOption, "value") === "freelancer"
						? "Add freelancer"
						: "Add supplier";
				inputPlaceholder =
					get(selectedOption, "value") === "candidate"
						? "Search a candidate..."
						: get(selectedOption, "value") === "profile"
						? "Search a profile..."
						: get(selectedOption, "value") === "freelancer"
						? "Search a freelancer..."
						: "Search a supplier...";
				return (
					openFilterOptions && (
						<UsersSelect
							isLoading={false}
							rightPosition
							top={5}
							includedItems={includedItems}
							setIncludedItems={cb => onSelect(cb(includedItems))}
							data={options}
							setOpen={closeMenu}
							open
							id={id}
							selectTitle={selectTitle}
							inputPlaceholder={inputPlaceholder}
						></UsersSelect>
					)
				);
			}
			const newOptions =
				filtredOptions &&
				filtredOptions
					.map(item => ({
						label: item.title,
						value: item._id
					}))
					.filter(item => !get(value, "_id", []).includes(get(item, "value")));

			return (
				openFilterOptions && (
					<ListMenu
						closeMenu={closeMenu}
						options={newOptions}
						onSelect={option => {
							onSelect(option);
							closeMenu();
						}}
						isSearchable={true}
						searchKeyword={searchKeyword}
						setSearchKeyword={setSearchKeyword}
						fullWidth
						id={id}
						emptyStateIcon={<EmptyState />}
						emptyStateText={
							"You have no " +
							(get(value, "entity", "") === "request"
								? "requests"
								: "vacancies")
						}
					/>
				)
			);
		default:
			return (
				openFilterOptions && (
					<ListMenu
						closeMenu={closeMenu}
						options={options}
						onSelect={onSelect}
						id={id}
					/>
				)
			);
	}
};

export default RenderFilterOptionsMenu;
