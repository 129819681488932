import React, { useMemo } from "react";
import "./g-tooltip.scss";
import styles from "../companies.module.scss";
import GlobalTooltip from "common/GlobalTooltip";
import ButtonWithDropdown from "../ButtonWithDropdown";
import useCompaniesStore from "../store/useCompaniesStore";
import { ReactComponent as IconJobs } from "static/icons/Iconjobs.svg";
import { ReactComponent as IconUsers } from "static/icons/IconUsers.svg";
import { ReactComponent as IconLocation } from "static/icons/IconLocation.svg";
import { ReactComponent as IconGreenCheck } from "static/icons/IconGreenCheck.svg";
import { ReactComponent as IconTalendPool } from "static/icons/IconTalendPool.svg";
import { ReactComponent as IconIndustries } from "static/icons/IconIndustries.svg";
import { ReactComponent as IconDisableNofication } from "static/icons/IconDisableNofication.svg";
import { ReactComponent as IconGreenNotification } from "static/icons/IconGreenNotification.svg";
import {
	MUTE,
	UNMUTE,
	FOLLOW,
	UNFOLLOW,
	LEAVE_POOL,
	getModalSlug
} from "../ConfirmActionModal/config";
import { useCompaniesActions } from "../hooks/useCompaniesActions";

const COMPANY_NAME_MAX_LENGTH = 22;
const INDUSTRIES_MAX_LENGTH = 44;
const JOBS_MAX_LENGTH = 40;
const TOOLTIP_DELAY = 0.5; // 1/2 second
export default function CompanyCard({ record, isFetching }) {
	const {
		setModalSlug,
		setActionType,
		setFormIsValid,
		setSelectedCompany
	} = useCompaniesStore();

	// api hooks
	const [onMutate, { isLoading }] = useCompaniesActions();

	// actions to fire confirmation modal
	const onLeave = () => {
		setActionType(LEAVE_POOL);
		setSelectedCompany(record);
		setModalSlug(getModalSlug(record, LEAVE_POOL));
	};
	const onUnfollow = () => {
		setFormIsValid(true);
		setActionType(UNFOLLOW);
		setSelectedCompany(record);
		setModalSlug(getModalSlug(record, UNFOLLOW));
	};

	const offices = record.site.offices;
	const industries = record.site.industries.map(item => item.name).join(" • ");
	const officesCount = offices.length ? `(+${offices.length})` : "";
	const officesOverlay = (
		<div className="overlay-container">
			<div>
				<h6>Headquarters</h6>
				<p>{`${record.site.main_location.city}, ${record.site.main_location.country}`}</p>
			</div>
			<div>
				<h6>Offices</h6>
				{offices.map((item, index) => {
					return <p key={index}>{`${item.city}, ${item.country}`}</p>;
				})}
			</div>
		</div>
	);
	const dropdownOptions = [
		{
			value: "company-page",
			label: "Company page",
			href: record.links?.company_page_url
		},
		{
			value: "open-jobs-page",
			label: "Open jobs",
			href: record.links?.job_page_url
		}
	];

	const disableActions = useMemo(() => isFetching || isLoading, [
		isFetching,
		isLoading
	]);

	const activeJobsTooltip =
		`${record.open_jobs} job open Your profile matches ${record.matched_jobs} jobs`
			.length >= JOBS_MAX_LENGTH;

	return (
		<div className={styles.card__container}>
			{/* Card heading  */}
			<div className={styles.card__header}>
				<div className={styles.card__title}>
					<div
						className={styles.card__avatar}
						style={{ backgroundImage: `url(${record.site.avatar})` }}
					></div>
					<GlobalTooltip
						noClassName
						placement="top"
						maxWidth="250px"
						withWrappingDiv={false}
						overlay={record.site.name}
						mouseEnterDelay={TOOLTIP_DELAY}
						overlayClassName={"g-tool-dark"}
						active={record.site.name.length >= COMPANY_NAME_MAX_LENGTH}
					>
						<h3 className={styles.line_clamp}>{record.site.name}</h3>
					</GlobalTooltip>
				</div>
				<div className={styles.card__icons}>
					{record.is_subscribed && (
						<GlobalTooltip
							noClassName
							placement="top"
							withWrappingDiv={false}
							overlay={"Leave talent pool"}
							mouseEnterDelay={TOOLTIP_DELAY}
							overlayClassName={"g-tool-dark"}
						>
							<IconTalendPool
								onClick={onLeave}
								disabled={disableActions}
								className={`${disableActions ? "i-secondary-50" : ""}`}
							/>
						</GlobalTooltip>
					)}
					<GlobalTooltip
						noClassName
						placement="top"
						withWrappingDiv={false}
						mouseEnterDelay={TOOLTIP_DELAY}
						overlayClassName={"g-tool-dark"}
						overlay={
							record.is_job_alert_active
								? "Mute job alerts"
								: "Unmute job alerts"
						}
					>
						{!record.is_job_alert_active ? (
							<IconDisableNofication
								disabled={disableActions}
								onClick={() => onMutate(UNMUTE, record)}
								className={`${disableActions ? "i-danger-50" : ""}`}
							/>
						) : (
							<IconGreenNotification
								disabled={disableActions}
								onClick={() => onMutate(MUTE, record)}
								className={`${disableActions ? "i-success-50" : ""}`}
							/>
						)}
					</GlobalTooltip>
				</div>
			</div>

			{/* Card body */}
			<div className={styles.card__body}>
				{/* industries */}
				<div>
					<IconIndustries />
					{industries ? (
						<GlobalTooltip
							noClassName
							placement="top"
							maxWidth="250px"
							overlay={industries}
							withWrappingDiv={false}
							mouseEnterDelay={TOOLTIP_DELAY}
							overlayClassName={"g-tool-dark"}
							active={industries.length >= INDUSTRIES_MAX_LENGTH}
						>
							<p className={styles.line_clamp}>{industries}</p>
						</GlobalTooltip>
					) : (
						<span className={styles.empty_box} />
					)}
				</div>
				{/* offices */}
				<div>
					<IconLocation />
					{record.site.main_location ? (
						<p className={styles.line_clamp}>
							{`${record.site.main_location.city}, ${record.site.main_location.country}`}
							{officesCount && (
								<GlobalTooltip
									noClassName
									placement="top"
									maxWidth="190px"
									overlay={officesOverlay}
									mouseEnterDelay={TOOLTIP_DELAY}
									overlayClassName={"g-tool-dark"}
									withWrappingDiv={false}
								>
									<span>{officesCount}</span>
								</GlobalTooltip>
							)}
						</p>
					) : (
						<span className={styles.empty_box} />
					)}
				</div>
				{/* employees */}
				<div>
					<IconUsers />
					{record.site.total_employees ? (
						<p className={styles.line_clamp}>
							{`+${record.site.total_employees} employees`}
						</p>
					) : (
						<span className={styles.empty_box} />
					)}
				</div>
				{/* jobs */}
				<div>
					<IconJobs />
					<p className={styles.line_clamp}>
						{record.open_jobs === 1
							? `${record.open_jobs} job open`
							: `${record.open_jobs} jobs open`}

						{!!record.matched_jobs && (
							<GlobalTooltip
								noClassName
								placement="top"
								withWrappingDiv={false}
								active={activeJobsTooltip}
								mouseEnterDelay={TOOLTIP_DELAY}
								overlayClassName={"g-tool-dark"}
								overlay={
									" Your profile matches " + record.matched_jobs + " jobs"
								}
							>
								<span className={styles.green__text}>
									<IconGreenCheck />
									{" Your profile matches " + record.matched_jobs + " jobs"}
								</span>
							</GlobalTooltip>
						)}
					</p>
				</div>
			</div>

			{/* Card footer */}
			<div className={styles.card__footer}>
				{!record.is_followed ? (
					<button
						disabled={disableActions}
						onClick={() => onMutate(FOLLOW, record)}
						className={`${styles.btn} ${styles.btn__success}`}
					>
						Follow
					</button>
				) : (
					<button
						onClick={onUnfollow}
						disabled={disableActions}
						className={`${styles.btn} ${styles.btn__danger}`}
					>
						Unfollow
					</button>
				)}

				{(record.links?.company_page_url || record.links?.job_page_url) && (
					<ButtonWithDropdown
						label="Visit"
						options={dropdownOptions}
						classes={{
							btnClasses: styles.links__dropdown_btn,
							btnOpenedClasses: `${styles.links__dropdown_btn} ${styles.links__dropdown_btn_active}`,
							menuClasses: styles.links__dropdown,
							menuItemClasses: styles.links__dropdown_items
						}}
					/>
				)}
			</div>
		</div>
	);
}
