import React from "react";
import { ReactComponent as Vector } from "static/vectors/account-suspended.svg";
import styles from "./style.module.scss";

const AccountBlocked = ({ is_account, onGetHelp }) => {
	return (
		<div className={styles.emptyBlock}>
			<Vector />
			<div className={styles.title}>Access suspended</div>
			<div className={styles.description}>
				{is_account ? (
					<span>
						Your account access has been suspended. For more information
					</span>
				) : (
					<span>
						Please contact your account admin and request that a new invitation
						be sent to you.
					</span>
				)}
			</div>
			{is_account ? (
				<button onClick={onGetHelp} className={styles.button}>
					Contact our support team
				</button>
			) : (
				<a href="mailto:contact@wiggli.io" className={styles.button}>
					Contact admin
				</a>
			)}
		</div>
	);
};

export default AccountBlocked;
