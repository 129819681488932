import React from "react";
import Drawer from "rc-drawer";
import _get from "lodash/get";
import { DrawerContainer } from "./style";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore/index.js";
import RejectApplication from "../components/Rejection";
import CancelInterview from "../components/CancelInterview";
import {
	OFFER,
	REJECT_APPLICATION,
	CANCEL_INTERVIEW,
	UPLOAD_CANDIDATE_FILES
} from "config";
import UploadCandidateFiles from "modules/candidate/Profile/Pages/Files/uploadFiles";
import { PHONE_SCREENING, ADD_INTERVIEW } from "config";
import { VancancyViewSendOfferDrawer } from "../../VacancyViewProposeOffer";
import { VancancyViewProposeInterviewDrawer } from "../../VacancyViewProposeInterview";
import VacancyViewPhoneScreenDrawer from "../../VacancyViewPhoneScreen/VacancyViewPhoneScreenDrawer/VacancyViewPhoneScreenDrawer";

const CandidateActionsDrawer = ({
	job,
	poke,
	onSuccess,
	isFromVacanciesTable = false
}) => {
	const { drawerState, setDrawerState } = useVacancyStore();
	const renderComponent = () => {
		switch (_get(drawerState, "component")) {
			case REJECT_APPLICATION:
				return (
					<RejectApplication
						clearSelection={_get(drawerState, "clearSelection")}
						poke={poke}
						job={job}
						isFromVacanciesTable={isFromVacanciesTable}
						onSuccess={onSuccess}
					/>
				);
			case CANCEL_INTERVIEW:
				return (
					<CancelInterview
						poke={poke}
						isFromVacanciesTable={isFromVacanciesTable}
						onSuccess={onSuccess}
					/>
				);
			case UPLOAD_CANDIDATE_FILES:
				return <UploadCandidateFiles />;

			default:
				return;
		}
	};

	if (_get(drawerState, "component") === OFFER) {
		return (
			<DrawerContainer>
				<VancancyViewSendOfferDrawer
					isOpen={_get(drawerState, "open")}
					onClose={() => setDrawerState({ open: false })}
					vacancyId={_get(job, "id", _get(job, "_id"))}
					profileId={_get(drawerState, "profile_id")}
					job={job}
					offer={_get(poke, "offer", {})}
					poke={poke}
					isFromVacanciesTable={isFromVacanciesTable}
					onSuccess={onSuccess}
				/>
			</DrawerContainer>
		);
	}
	if (_get(drawerState, "component") === ADD_INTERVIEW) {
		return (
			<DrawerContainer>
				<VancancyViewProposeInterviewDrawer
					isOpen={_get(drawerState, "open")}
					onClose={() => setDrawerState({ open: false, component: "" })}
					job={job}
					isFromVacanciesTable={isFromVacanciesTable}
					poke={poke}
				/>
			</DrawerContainer>
		);
	}

	if (_get(drawerState, "component") === PHONE_SCREENING) {
		return (
			<DrawerContainer>
				<VacancyViewPhoneScreenDrawer
					isOpen={_get(drawerState, "open")}
					onClose={() => setDrawerState({ open: false, component: "" })}
					job={job}
					isFromVacanciesTable={isFromVacanciesTable}
					poke={poke}
				/>
			</DrawerContainer>
		);
	}

	return (
		<DrawerContainer>
			<Drawer
				open={_get(drawerState, "open")}
				width={`calc(100% - 100px)`}
				height={"calc(100% - 64px)"}
				maskStyle={{ background: "0" }}
				contentWrapperStyle={{
					background: "red",
					position: "absolute",
					bottom: "0"
				}}
				placement={"right"}
				style={{ zIndex: 1000 }}
				level={"root"}
				maskClosable={true}
				onClose={() => setDrawerState({ open: false })}
				wrapperClassName="candidate-actions"
			>
				{renderComponent()}
			</Drawer>
		</DrawerContainer>
	);
};

export default CandidateActionsDrawer;
