import React from "react";
import styles from "./create-request-time-field.module.scss";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { Controller } from "react-hook-form";
import { RadioGroup } from "common/RadioGroup";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import {
	FULL_TIME,
	PART_TIME
} from "modules/CreateRequestModule/utils/constants";

const CreateRequestTimeField = ({ control, error }) => {
	return (
		<CreateRequestFieldContainer>
			<CreateRequestLabelContainer>
				<CreateRequestFieldLabel>Commitment</CreateRequestFieldLabel>
			</CreateRequestLabelContainer>
			<Controller
				name="time"
				control={control}
				render={({ field: { onChange, value } }) => (
					<RadioGroup
						className={styles.radioButtonContainer}
						options={[FULL_TIME, PART_TIME]}
						value={value}
						onChange={e => {
							onChange(e.target.value);
						}}
					/>
				)}
			/>
			<CreateRequestFieldError error={error} />
		</CreateRequestFieldContainer>
	);
};

export default CreateRequestTimeField;
