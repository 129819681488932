import React from "react";
import cx from "classnames";
import classnames from "./priority.module.scss";
import { ReactComponent as DeleteOptionIcon } from "static/icons/delete-option-icon.svg";

const Priority = ({ priority, isDelete = false, deleteHandler }) => (
	<div
		className={cx(classnames.priorityContainer, {
			[classnames.high]: priority === "high",
			[classnames.low]: priority === "low",
			[classnames.medium]: priority === "medium",
			[classnames.justifyContent]: isDelete,
			[classnames.emptyState]: !priority
		})}
	>
		<span className={classnames.priorityLabel}>
			{priority ? priority : "undefined"}
		</span>
		{isDelete && (
			<button onClick={deleteHandler}>
				<DeleteOptionIcon className={classnames.icon} />
			</button>
		)}
	</div>
);

export default Priority;
