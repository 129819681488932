export const BREADCRUMBS_ITEMS = [{ name: "Settings" }, { name: "PENDING" }];
export const PENDING_FREELANCER_FILTER_ID = "PENDING_FREELANCER_FILTER_ID";
export const PENDING_FREELANCER_LIST_FILTER = "PENDING_FREELANCER_LIST_FILTER";
export const TYPE_TAG = "search_pending_activations_table";
export const PAGE_SIZE_LIST = [50, 24, 12];
export const PAGE_SIZE = 12;
export const EMPTY_ARRAY = Array(12).fill({});
export const RESEND_ACTIVATION_LIMIT = 25;
export const DELETE_LIMIT = 50;
export const TAG_CODE_COUNTRIES = "countries";
export const TAG_CODE_FREELANCERS = "freelancers";

export const COLUMNS = [
	{
		colId: "created_by",
		headerName: "Added by",
		width: 140,
		hide: false,
		filter: false,
		sortable: true
	},
	{
		colId: "email",
		headerName: "Email",
		width: 249,
		hide: false,
		filter: false,
		sortable: true
	},
	{
		colId: "phone",
		headerName: "Phone",
		width: 249,
		hide: false,
		filter: false,
		sortable: true
	},
	{
		colId: "first_name",
		headerName: "First name",
		width: 140,
		hide: false,
		filter: false,
		sortable: true
	},
	{
		colId: "last_name",
		headerName: "Last name",
		width: 140,
		hide: false,
		filter: false,
		sortable: true
	},
	{
		colId: "created_at",
		headerName: "Added on",
		width: 249,
		hide: false,
		filter: false,
		sortable: true
	},
	{
		colId: "last_email_sent",
		headerName: "Last email sent",
		width: 249,
		hide: false,
		filter: false,
		sortable: false
	},
	{
		colId: "cv",
		headerName: "CV",
		width: 122,
		hide: false,
		filter: false,
		sortable: false
	},
	{
		colId: "category",
		headerName: "Category",
		width: 250,
		hide: false,
		filter: true,
		sortable: true
	},
	{
		colId: "seniority",
		headerName: "Seniority",
		width: 250,
		hide: false,
		filter: true,
		sortable: true
	},
	{
		colId: "sub_category",
		headerName: "Sub-category",
		width: 250,
		hide: false,
		filter: true,
		sortable: true
	},
	{
		colId: "company",
		headerName: "Company",
		width: 250,
		hide: false,
		filter: true,
		sortable: true
	},
	{
		colId: "company_address",
		headerName: "Company's address",
		width: 250,
		hide: false,
		filter: true,
		sortable: false
	},
	{
		colId: "vat_number",
		headerName: "VAT number",
		width: 250,
		hide: false,
		filter: true,
		sortable: true
	}
];

export const columnSortMap = {
	added_by: "added_by",
	email: "email",
	phone: "phone",
	last_name: "last_name",
	first_name: "first_name",
	added_date: "added_date"
};
