import React from "react";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";

const DeclineInterestModal = ({
	declineInterest,
	toggleDeclineModal,
	displayDeclineModal
}) => {
	return (
		<>
			<ConfirmationModal
				key="confirm_interest_modal"
				onClose={toggleDeclineModal}
				active={displayDeclineModal}
				modal_name="confirm_interest_modal"
				type={ConfirmationTypes.error}
				title="Are you sure you would like to decline ?"
				content="Once you have declined, neither you nor the client will be able to interact with each other for this vacancy."
				firstButton={{
					action: () => {
						declineInterest();
					},
					label: "YES, DECLINE.",
					type: "warning"
				}}
				secondButton={{
					action: toggleDeclineModal,
					label: "NO"
				}}
			/>
		</>
	);
};

export default DeclineInterestModal;
