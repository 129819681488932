import { useEffect, useRef, useState } from "react";
import { usePaginatedQuery } from "react-query";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { client } from "lib/api-client";
import { useDebounce } from "react-use";
import isEqual from "lodash/isEqual";
import { TIMESHEETS_FILTER_ID } from "../utils/constants";
import { TIMESHEET_LIST } from "config/api-endpoints";
import { getSearchTimesheetsPayload } from "../utils/getSearchTimesheetsPayload";

const filterData = body => {
	return client(TIMESHEET_LIST, {
		body
	});
};

export const QUERY_KEY = "@timesheetsModule/fetch-timesheets";

const useFetchTimesheetsList = () => {
	const {
		setQueryError,
		setIsLoading,
		setIsFetching,
		getState,
		setData
	} = useFilters();

	const {
		query,
		logicalOperator,
		filters,
		sortBy,
		offset,
		filterChangeTrigger,
		limit
	} = getState(TIMESHEETS_FILTER_ID);

	const previousFilters = useRef();
	const [body, setBody] = useState(formatBody());

	const { refetch, isFetching, isLoading, isFetched, data } = usePaginatedQuery(
		[QUERY_KEY, body],
		() => filterData(body),
		{
			onError: e => {
				if (e?.detail?.keywords) {
					setQueryError(true);
				}
			},
			refetchOnWindowFocus: false,
			enabled: !!body.limit
		}
	);

	useEffect(() => {
		setIsLoading(isLoading);
		setIsFetching(isFetching && !isFetched);
	}, [isLoading, isFetching, refetch, isFetched]);

	useEffect(() => {
		if (data) {
			setData(data);
			setQueryError(false);
		}
	}, [data]);

	useEffect(() => {
		const validFilters = getValidFilters(filters);
		const globalFilter = {
			validFilters,
			sortBy,
			offset,
			logicalOperator,
			limit
		};

		const isFiltersChanged = !isEqual(previousFilters.current, globalFilter);

		if (isFiltersChanged) {
			previousFilters.current = globalFilter;
		}
		if (
			(filterChangeTrigger !== "change" &&
				filterChangeTrigger !== "newFilter" &&
				isFiltersChanged) ||
			filterChangeTrigger === "reset" ||
			filterChangeTrigger === "limitChange"
		) {
			setBody(formatBody());
		}
	}, [filters, sortBy, offset, filterChangeTrigger, logicalOperator, limit]);

	useDebounce(
		() => {
			if (filterChangeTrigger === "change") {
				setBody(formatBody());
			}
		},
		500,
		[filters, query]
	);

	function formatBody() {
		return getSearchTimesheetsPayload({
			filters,
			logicalOperator,
			offset,
			sortBy,
			limit,
			query
		});
	}

	function getValidFilters(filters = []) {
		// Be careful about false value, filtering a filter with false value is a bug
		// In case of expected salary, entering 0 will be filtered, the ideal should be including the salary to the filter!
		return filters.filter(({ value }) => {
			if (
				[undefined, null, "", 0].includes(value) ||
				(Array.isArray(value) && value.length === 0) ||
				(value !== null &&
					typeof value === "object" &&
					Object.keys(value).length === 0)
			) {
				return false;
			} else {
				return true;
			}
		});
	}

	return refetch;
};

export default useFetchTimesheetsList;
