import React from "react";
import cx from "classnames";
import styles from "./accordion-rx.module.scss";
import * as Accordion from "@radix-ui/react-accordion";

const AccordionRx = React.forwardRef(
	({ children, className, ...props }, forwardedRef) => (
		<Accordion.Root
			{...props}
			ref={forwardedRef}
			className={cx(styles.accordionRoot, className)}
		>
			{children}
		</Accordion.Root>
	)
);
AccordionRx.displayName = "AccordionRx";

const AccordionTrigger = React.forwardRef(
	({ children, className, ...props }, forwardedRef) => (
		<Accordion.Header className={styles.accordionHeader}>
			<Accordion.Trigger
				{...props}
				ref={forwardedRef}
				className={cx(styles.accordionTrigger, className)}
			>
				{children}
			</Accordion.Trigger>
		</Accordion.Header>
	)
);
AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent = React.forwardRef(
	({ children, className, ...props }, forwardedRef) => (
		<Accordion.Content
			{...props}
			ref={forwardedRef}
			className={cx(styles.accordionContent, className)}
		>
			{children}
		</Accordion.Content>
	)
);
AccordionContent.displayName = "AccordionContent";

AccordionRx.Item = Accordion.Item;
AccordionRx.Content = AccordionContent;
AccordionRx.Trigger = AccordionTrigger;
export default AccordionRx;
