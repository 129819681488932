import React, { useEffect, useMemo } from "react";
import { browserHistory } from "react-router";
import styles from "./department-drawer.module.scss";
import { useTour } from "@reactour/tour";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { TOUR_USER_AND_DEPARTEMENT } from "config/onboarding";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";

const useTourData = () => {
	const { workflowId, cleanStore, setIsLoading } = useOnboardingStore();
	const { setIsOpen, setSteps } = useTour();
	const isActiveSetupCompanyTour = useMemo(
		() => workflowId === TOUR_USER_AND_DEPARTEMENT,
		[workflowId]
	);

	// last step close tour
	const onLastStepClose = () => {
		// todo add start recrutment modal
		setIsOpen(false); // close tour
		setSteps([]); // clean steps
		cleanStore(); // clean oboarding store
		browserHistory.push("/settings/setup-guide");
	};
	// persist onboarding data
	const { persistData } = useOnboardingSaveData({
		onTourClose: onLastStepClose
	});

	const goLastTourStep = () => {
		if (isActiveSetupCompanyTour) {
			setIsLoading(true);
			persistData();
		}
	};

	return {
		goLastTourStep,
		isActiveSetupCompanyTour
	};
};

const FooterDrawer = ({
	submitForm,
	onClose,
	setIsTourMode,
	isTourMode,
	isSaving
}) => {
	const { goLastTourStep, isActiveSetupCompanyTour } = useTourData();
	useEffect(() => {
		setIsTourMode(isActiveSetupCompanyTour);
	}, [isTourMode]);
	return (
		<div className={styles.footer}>
			<button
				className={styles.submitBtn}
				onClick={() => {
					submitForm();
				}}
				disabled={isSaving}
			>
				Save
			</button>
			<button
				type="button"
				className={styles.cancelBtn}
				onClick={() => {
					onClose();
					goLastTourStep();
				}}
			>
				Cancel
			</button>
		</div>
	);
};

export default FooterDrawer;
