import React from "react";

const ConsultantTabs = ({ isActive, onChange, ownCV, userType }) => (
	<>
		<li className={isActive(0)} onClick={() => onChange(0)}>
			cv
		</li>

		<li className={isActive(1)} onClick={() => onChange(1)}>
			Functions, skills and languages
		</li>
		{ownCV && (
			<li className={isActive(2)} onClick={() => onChange(2)}>
				Expectations
			</li>
		)}

		{userType === "client" ? (
			<li className={isActive(3)} onClick={() => onChange(3)}>
				History
			</li>
		) : (
			<></>
		)}
	</>
);

export default ConsultantTabs;
