import { createSelector } from "reselect";
import _get from "lodash/get";

const getVacancyFunctions = state => _get(state, "vacancyFunctions.functions");
const getVacancyNextFunctions = state =>
	_get(state, "vacancyFunctions.nextFunctions");
const getShowVacancyFunctionsModal = state =>
	_get(state, "vacancyFunctions.showModal");
const getVacancySectors = state => _get(state, "vacancyFunctions.sectors");
const getVacancySectorsExist = state =>
	_get(state, "vacancyFunctions.sectorsExist");
const getHasMadeChanges = state => _get(state, "vacancyFunctions.showModal");
const getRemovedCategoriesWithSkills = state =>
	_get(state, "vacancyFunctions.showModal");

export const vacancyFunctionsSelector = createSelector(
	getVacancyFunctions,
	funs => funs
);

export const vacancyNextFunctionsSelector = createSelector(
	getVacancyNextFunctions,
	funs => funs
);

export const showVacancyFunctionsModalSelector = createSelector(
	getShowVacancyFunctionsModal,
	show => show
);

export const vacancySectorsSelector = createSelector(
	getVacancySectors,
	sectors => sectors
);

export const vacancySectorsExistSelector = createSelector(
	getVacancySectorsExist,
	sectorsExist => sectorsExist
);

export const hasMadeChangesSeclector = createSelector(
	getHasMadeChanges,
	madeChanges => madeChanges
);

export const removedCategoriesWithSkillsSelector = createSelector(
	getRemovedCategoriesWithSkills,
	removed => removed
);
