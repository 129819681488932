import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import { Flex, Box } from "rebass";
import {
	DashboardBox,
	SwitcherContainer,
	Switcher,
	ChartContainer
} from "./styled";
import VacancyPerformanceChart from "./containers/VacancyPerformanceChart";
import RequestPerformanceChart from "./containers/RequestPerformanceChart";
import ChartTotalPokes from "./containers/ChartTotalPokes";
import ChartCurrentHourlyCost from "./containers/ChartCurrentHourlyCost";
import { PERMANENT, TEMPORARY } from "config";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import ListFilter from "common/ListFilter";

export const getDashboardRoute = () => {
	return "/dashboard";
};

export const getDashboardPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

function Dashboard() {
	const [switcherState, setSwitcherState] = useState(PERMANENT);

	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Dashboard", path: "/" },
			{ name: switcherState ?? "loading..." }
		]);
	}, [switcherState]);

	useEffect(() => {
		const dashboardEvent = () => {
			const userFeatures = (
				JSON.parse(localStorage.getItem("current_user")) || {}
			).features;
			if (userFeatures && userFeatures.indexOf("feature_dashboard") === -1) {
				return browserHistory.push("/");
			}
		};

		window.addEventListener("storage", dashboardEvent());
		return () => window.removeEventListener("storage", dashboardEvent());
	}, []);

	return (
		<>
			<Helmet>
				<title>Dashboard</title>
			</Helmet>
			<BodyClassName className="dashboard-Box">
				<DashboardBox>
					<ListFilter />
					<div className="full-container">
						<Flex pr={15} pl={15} justifyContent=" flex-end">
							<Box>
								<SwitcherContainer paddingTop="0" className="SwitcherDashboard">
									<Switcher
										active={switcherState === TEMPORARY}
										onClick={() => setSwitcherState(TEMPORARY)}
									>
										Temporary
									</Switcher>
									<Switcher
										active={switcherState === PERMANENT}
										onClick={() => setSwitcherState(PERMANENT)}
									>
										Permanent
									</Switcher>
								</SwitcherContainer>
							</Box>
						</Flex>

						<Box>
							{switcherState === TEMPORARY ? (
								<>
									<ChartContainer>
										<div className="chart">
											<RequestPerformanceChart />
										</div>
										<div className="chart">
											<ChartCurrentHourlyCost />
										</div>
									</ChartContainer>
								</>
							) : (
								<>
									<ChartContainer>
										<div className="chart">
											<VacancyPerformanceChart />
										</div>
										<div className="chart">
											<ChartTotalPokes />
										</div>
									</ChartContainer>
								</>
							)}
						</Box>
					</div>
				</DashboardBox>
			</BodyClassName>
		</>
	);
}

export default Dashboard;
