import React from "react";
import TourPopover from "modules/user-onboarding/components/TourPopover";
import { ReactComponent as ChevronRightIcon } from "static/icons/chevron-right-icon.svg";
import {
	ONBOARDING_COMMON_STYLES,
	TOUR_SETUP_COMPANY
} from "../../../../config/onboarding";
import { browserHistory } from "react-router";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";

// customize popover component
function StepPopover(props) {
	const {
		record: { title, stepsCount, description, arrowPosition },
		events: { onNext }
	} = props;

	return (
		<TourPopover position={arrowPosition}>
			<TourPopover.Header>{title}</TourPopover.Header>
			<TourPopover.Body>{description}</TourPopover.Body>
			<TourPopover.Footer>
				{!!props.record?.step && (
					<TourPopover.Step>
						{props.record.step}/{stepsCount}
					</TourPopover.Step>
				)}
				<TourPopover.ButtonsWrapper>
					{!!props.events?.onSkip && (
						<TourPopover.Button onClick={props.events.onSkip} variant="link">
							Skip
						</TourPopover.Button>
					)}
					<TourPopover.Button
						onClick={onNext}
						variant={props.record?.nextButtonVariant || "link"}
					>
						{props.record?.nextButtonText || (
							<>
								Next step
								<ChevronRightIcon />
							</>
						)}
					</TourPopover.Button>
				</TourPopover.ButtonsWrapper>
			</TourPopover.Footer>
		</TourPopover>
	);
}

export const SELECTORS_TOUR_SETUP_COMPANY = [
	`${TOUR_SETUP_COMPANY}__0`,
	`${TOUR_SETUP_COMPANY}__1`,
	`${TOUR_SETUP_COMPANY}__2`,
	`${TOUR_SETUP_COMPANY}__3`,
	`${TOUR_SETUP_COMPANY}__4`
];

// steps for admin role
export const STEPS_TOUR_SETUP_COMPANY = [
	{
		selector: `#${SELECTORS_TOUR_SETUP_COMPANY[0]}`,
		position: p => [p.left - 275, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Profile image",
					arrowPosition: "top-right",
					description:
						"Effortlessly uploading a profile image. Let your identity shine and enhance your engagement across the platform."
				}}
				events={{ onNext }}
			/>
		)
	},
	{
		selector: `#${SELECTORS_TOUR_SETUP_COMPANY[1]}`,
		position: p => [p.left - 275, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					arrowPosition: "top-right",
					title: "Click to edit Profile",
					description:
						"Take control of your personal information! Simply click the edit button to enter the edit mode and easily add or update your personal details."
				}}
				events={{ onNext }}
			/>
		)
	},
	{
		selector: `#${SELECTORS_TOUR_SETUP_COMPANY[2]}`,
		position: p => [p.left + 30, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					arrowPosition: "top-left",
					title: "Click to edit company",
					description:
						"Update your company info in seconds! Just click 'Edit' to access the edit mode and modify your company details."
				}}
				events={{ onNext }}
			/>
		)
	},
	{
		selector: `#${SELECTORS_TOUR_SETUP_COMPANY[3]}`,
		position: p => [p.left - 355, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					arrowPosition: "top-right",
					title: "Click to Add / edit invoicing details",
					description:
						"Keep your invoicing details up-to-date in just a click! Click 'Edit' to access the edit mode and easily modify your billing information."
				}}
				events={{ onNext }}
			/>
		)
	},
	{
		selector: `#${SELECTORS_TOUR_SETUP_COMPANY[4]}`,
		position: p => [p.left - 355, p.bottom - 270],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					nextButtonText: "Got it!",
					nextButtonVariant: "default",
					arrowPosition: "bottom-right",
					title: "Click to Add & edit Offices address",
					description:
						"Stay organized with office addresses! Use this feature to add and edit different office locations, making it easier to create and manage vacancies."
				}}
				events={{ onNext }}
			/>
		)
	}
];
// steps for member role
export const STEPS_TOUR_SETUP_PROFILE = [
	{
		selector: `#${SELECTORS_TOUR_SETUP_COMPANY[0]}`,
		position: p => [p.left - 275, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Profile image",
					arrowPosition: "top-right",
					description:
						"Effortlessly uploading a profile image. Let your identity shine and enhance your engagement across the platform."
				}}
				events={{ onNext }}
			/>
		)
	},
	{
		selector: `#${SELECTORS_TOUR_SETUP_COMPANY[1]}`,
		position: p => [p.left - 275, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					nextButtonText: "Got it!",
					arrowPosition: "top-right",
					nextButtonVariant: "default",
					title: "Click to edit Profile",
					description:
						"Take control of your personal information! Simply click the edit button to enter the edit mode and easily add or update your personal details."
				}}
				events={{ onNext }}
			/>
		)
	}
];

// handle rendering of each step
function ContentComponent(props) {
	const content = props.steps[props.currentStep].content;
	const isLastStep = props.currentStep === props.steps.length - 1;

	// onboarding store
	const {
		metaData,
		cleanStore,
		setMetaData,
		setIsLoading
	} = useOnboardingStore();

	// last step close tour
	const onTourClose = () => {
		// props.setSteps([]); // todo have to clean steps
		if (metaData?.showStartRecrutmentModal) {
			setMetaData({ ...metaData, showModal: true });
		} else {
			cleanStore(); // clean onboarding store
			browserHistory.push("/settings/setup-guide");
		}
	};

	// persist onboarding user settings
	const { persistData } = useOnboardingSaveData({ onTourClose });

	const onNext = () => {
		if (isLastStep) {
			props.setIsOpen(false); // close tour
			setIsLoading(true);
			persistData();
			return;
		}

		// next is the third step ( user role is admin )
		if (props.currentStep === 1) {
			props.setIsOpen(false);
			browserHistory.push("/settings/my-company");
			return;
		}

		props.setCurrentStep(s => s + 1);
	};

	if (typeof content === "function") {
		return content({
			onNext,
			onSkip: onTourClose,
			step: props.currentStep + 1,
			stepsCount: props.steps.length
		});
	}

	return content;
}

// company tour config
export default function useTourSetupCompanyConfig() {
	return {
		ContentComponent,
		onClickMask: () => null,
		styles: ONBOARDING_COMMON_STYLES,
		disableKeyboardNavigation: true
	};
}
