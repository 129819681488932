export const STEP_ONE = 1;
export const STEP_TWO = 2;
export const ADDRESS_PICKED_FIELDS = [
	"street",
	"number",
	"box",
	"city",
	"zip",
	"country",
	"longitude",
	"latitude",
	"iso_country"
];
export const VACANCY_VIEW_PROPOSE_OFFER = "vacancy_view_propose_offer";
