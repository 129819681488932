import { Chip } from "common/Chip";
import { Rating } from "common/Rating";
import React from "react";
import styles from "./picker-body.module.scss";

const PickerBody = ({
	remainItems,
	onItemClick,
	selectedItems,
	onItemDeleteClick,
	onRating,
	PrimaryHeaderContent,
	SecondaryHeaderContent,
	displayRating
}) => {
	return (
		<div className={styles.container}>
			{selectedItems.map(({ label, children, id }) => {
				return (
					<div key={id} className={styles.card}>
						<div className={styles.header}>
							<div className={styles.number}>{children.length}</div>
							<div>
								<SecondaryHeaderContent
									parent={label}
									singular={children.length === 1}
								/>
							</div>
							<div className={styles.line} />
						</div>
						<div
							className={displayRating ? styles.chipsColumn : styles.chipsRow}
						>
							{children.map((elt, itemIndex) => {
								const { label, id: childId, rating } = elt;
								const chipSelectedContent = (
									<div className={styles.selectedSkill} content={label}>
										<span className={styles.label}>{label}</span>

										{displayRating && (
											<Rating
												onChange={value => onRating(childId, id, value)}
												value={rating}
											/>
										)}
									</div>
								);
								return (
									<Chip
										key={`${id}-${itemIndex}`}
										id={childId}
										onClick={() => onItemDeleteClick(elt, id)}
										isDelete
										content={chipSelectedContent}
									/>
								);
							})}
						</div>
					</div>
				);
			})}
			{remainItems.map(parentItem => {
				const { label, id, children } = parentItem;

				return (
					<div key={id} className={styles.card}>
						{children?.length > 0 && (
							<div className={styles.header}>
								<div className={styles.number}>{children.length}</div>
								<PrimaryHeaderContent
									parent={label}
									singular={children.length === 1}
								/>
								<div className={styles.line} />
							</div>
						)}

						<div className={styles.tags}>
							<div className={styles.wrapper}>
								{children.map((item, itemIndex) => {
									const { label, id: idChild } = item;

									return (
										<Chip
											id={idChild}
											onClick={() => onItemClick(item, parentItem)}
											key={`${idChild}-${itemIndex}`}
											content={<span className={styles.text}>{label}</span>}
											content_text={label}
										/>
									);
								})}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default PickerBody;
