import React from "react";
import PropTypes from "prop-types";

const ExpiredView = ({ icon, iconClassName = "", children, alt = "image" }) => {
	return (
		<div className="invitation-expired">
			{icon && (
				<img alt={alt} src={icon} className={`expired-img ${iconClassName}`} />
			)}
			<div className="expired-children">{children}</div>
		</div>
	);
};

ExpiredView.propTypes = {
	icon: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
	alt: PropTypes.string
};

export default ExpiredView;
