import styled from "styled-components";

export const StyledCheckbox = styled.div`
	.checkbox-container {
		.input-box {
			margin-top: 6px;
			input[type="checkbox"].styled + label:before {
				margin-top: 6px;
			}
		}
	}
`;
