import { useEffect } from "react";
import { getJobViewRoute } from "modules/job/components";
import { getJobListContainerRoute } from "modules/job/components/joblist/jobListContainer";
import useBreadCrumbsStore from "common/Layouts/breadcrumbs.store";
import usePublishRequestStore from "./usePublishRequestStore";
import { PUBLISHED } from "config";

export default function usePublishRequestBreadCrumbs() {
	const { setItems } = useBreadCrumbsStore(state => state);
	const { request } = usePublishRequestStore();

	useEffect(() => {
		if (request?.id) {
			const items = [
				{ name: "Request", path: getJobListContainerRoute() },
				{ name: request.title, path: getJobViewRoute(request.id) },
				{
					name: request.status === PUBLISHED ? "Republish" : "Publish"
				}
			];

			setItems(items);
		}
	}, [request]);
}
