import React, { useEffect, useRef, useState } from "react";
import get from "lodash/get";
import { CoumpoundSelect as CompoundSelect } from "common/CustomSelect";
import { ReactComponent as AddIcon } from "static/icons/invited_People.svg";
import s from "./members.module.scss";
import GlobalTooltip from "common/GlobalTooltip";
import { usePopper } from "react-popper";
import Portal from "common/Portal";
import Avatar from "common/Avatar";
import classnames from "modules/candidate/Profile/Pages/Files/files.module.scss";
const idKey = "_id";
function MembersSelect({
	data,
	label,
	onChange,
	isLoading,
	includedItems,
	setIncludedItems,
	selectTitle,
	inputPlaceholder,
	rightPosition,
	groupedItems,
	membersToshow = 3,
	onClick,
	bottom,
	top,
	disabledUsers,
	isGreenTheme = false,
	canChangeVisibility = true
}) {
	const [open, setOpen] = useState(false);
	const firstMounded = useRef(true);
	const referenceElement = useRef();
	const popperElement = useRef();

	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "bottom",
			modifiers: [
				{
					name: "flip",
					options: {
						fallbackPlacements: ["top", "right"]
					}
				}
			]
		}
	);

	useEffect(() => {
		if (!firstMounded.current) {
			onChange && onChange(includedItems);
		}
		firstMounded.current = false;
	}, [includedItems, onChange]);

	return (
		<div className={s.container} ref={referenceElement}>
			{label && <label className={s.label}>{label}</label>}
			<CompoundSelect>
				<div className={s.assignedMembers}>
					<CompoundSelect.Renderer>
						<ul className={s.list}>
							{includedItems.slice(0, membersToshow).map(elem => (
								<li className={s.item} key={elem[idKey]}>
									<div className={s.itemData}>
										<GlobalTooltip
											overlay={`${get(elem, "first_name")} ${get(
												elem,
												"last_name"
											)}`}
											placement="top"
											trigger="hover"
										>
											<Avatar
												className={classnames.avatar}
												name={`${get(elem, "first_name")} ${get(
													elem,
													"last_name"
												)}`}
												avatar={get(elem, "avatar")}
												size={30}
											/>
										</GlobalTooltip>
									</div>
								</li>
							))}
						</ul>
					</CompoundSelect.Renderer>
					{includedItems.length - membersToshow > 0 && (
						<span className={s.restOfUsers}>{`+${includedItems.length -
							membersToshow}`}</span>
					)}
					{canChangeVisibility && (
						<CompoundSelect.Opener className={s.opener} setOpen={setOpen}>
							<AddIcon />
						</CompoundSelect.Opener>
					)}
				</div>

				{groupedItems ? (
					<Portal>
						<div
							ref={popperElement}
							style={{
								...styles.popper,
								pointerEvents: open ? "auto" : "none",
								zIndex: 10000
							}}
							{...attributes.popper}
						>
							<CompoundSelect.CustomGroupedSelect
								data={data}
								open={open}
								setOpen={setOpen}
								includedItems={includedItems}
								setIncludedItems={setIncludedItems}
								idKey={idKey}
								functionKey="main_function.name"
								isLoading={isLoading}
								selectTitle={selectTitle}
								inputPlaceholder={inputPlaceholder}
								rightPosition={rightPosition}
							/>
						</div>
					</Portal>
				) : (
					<Portal>
						<div
							ref={popperElement}
							style={{
								...styles.popper,
								pointerEvents: open ? "auto" : "none",
								zIndex: 10000
							}}
							{...attributes.popper}
						>
							<CompoundSelect.Select
								isGreenTheme={isGreenTheme}
								data={data}
								open={open}
								setOpen={setOpen}
								includedItems={includedItems}
								setIncludedItems={setIncludedItems}
								idKey={idKey}
								functionKey="main_function.name"
								isLoading={isLoading}
								selectTitle={selectTitle}
								inputPlaceholder={inputPlaceholder}
								rightPosition={rightPosition}
								onClick={onClick}
								bottom={bottom}
								top={top}
								disabledUsers={disabledUsers}
							/>
						</div>
					</Portal>
				)}
			</CompoundSelect>
		</div>
	);
}

export default MembersSelect;
