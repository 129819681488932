/* eslint-disable react/display-name */
import React from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import _get from "lodash/get";
import styled, { css } from "styled-components";
import { colors } from "config/styles";

const Container = styled.div`
	color: ${colors.anchorsColor};
	font-size: 14px;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 10px;
	.icon-arrow-right1 {
		color: ${colors.primaryColor};
		font-size: 11px;
		position: relative;
		transform: rotate(90deg);
		margin-left: 10px;
		transition: 0.3s;
		${props =>
			props.displayMore &&
			css`
				transform: rotate(270deg);
			`}
	}
	.more-actions {
		margin: 3px 0;
	}
`;

export default ({ actions, handleActions }) => {
	let dropdown = "btn btn-dropdown btn-block";

	const SecondaryActions = ({ handleClick, actions, ...restProps }) => (
		<Menu
			{...restProps}
			onClick={({ key }) => handleClick(key)}
			selectable={false}
		>
			{actions.map((action, index) => {
				let children = _get(action, "props.children");
				let new_action = {
					...action,
					props: {
						...action.props,
						className: _get(action, "props.className") ? dropdown : "",
						children:
							typeof children === "object"
								? {
										...children,
										props: {
											...children.props,
											className: dropdown
										}
								  }
								: children
					}
				};

				return (
					index >= 2 && (
						<MenuItem key={index} className="bid-dropdown-item">
							<i className={action.props.icon} />
							{new_action}
						</MenuItem>
					)
				);
			})}
		</Menu>
	);

	return (
		<RcDropDown
			trigger={["click"]}
			placement="bottomCenter"
			overlay={
				<SecondaryActions handleClick={handleActions} actions={actions} />
			}
			align={{
				points: ["tc", "bc"],
				offset: [0, 10]
			}}
		>
			<Container>
				<button className="btn btn-outline-2 btn-block btn-sidebar">
					More options
				</button>
				{/* <span className="more-actions"> </span>
				<span className="icon-arrow-right1"></span> */}
			</Container>
		</RcDropDown>
	);
};
