import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_SEARCH_TEMPLATES_ENDPOINT } from "config/api-endpoints";

const getCommunityCriteria = body => {
	return client(LIST_SEARCH_TEMPLATES_ENDPOINT, {
		body
	});
};

const useGetCommunityCriteria = (options, queryOptions = {}) =>
	useQuery(
		["getCommunityCriteria", options],
		() => getCommunityCriteria(options),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...queryOptions
		}
	);

export default useGetCommunityCriteria;
