import React, { cloneElement, useState } from "react";
import { useListBox, useOption } from "react-aria";
import styles from "./combo-box-list-box.module.scss";

function ComboBoxListBox({ button, ...propsRest }) {
	let ref = React.useRef();
	const [buttonHovered, setButtonHovered] = useState(false);
	let { listBoxRef = ref, state } = propsRest;
	let { listBoxProps } = useListBox(propsRest, state, listBoxRef);

	const optionsComponents = (
		<ul {...listBoxProps} ref={listBoxRef} className={styles.optionsWrapper}>
			{[...state.collection].map(item => (
				<Option
					shouldFocus={!buttonHovered}
					key={item.key}
					item={item}
					state={state}
				/>
			))}
		</ul>
	);

	return button ? (
		<div>
			{optionsComponents}
			<div className={styles.separator} />
			<div className={styles.buttonContainer}>
				{cloneElement(button, {
					onMouseOver: () => setButtonHovered(true),
					onMouseLeave: () => setButtonHovered(false)
				})}
			</div>
		</div>
	) : (
		optionsComponents
	);
}

function Option({ item, state, shouldFocus }) {
	let ref = React.useRef();
	let { optionProps, isFocused } = useOption({ key: item.key }, state, ref);

	let backgroundColor;
	let color = "black";

	if (isFocused && shouldFocus) {
		backgroundColor = "#f5f5f5";
	}

	return (
		<li
			{...optionProps}
			ref={ref}
			style={{ background: backgroundColor, color: color }}
		>
			<div className={styles.option}>{item.rendered}</div>
		</li>
	);
}

export default ComboBoxListBox;
