import React, { useRef, useState } from "react";
import { Box } from "rebass";
import { ReactComponent as HideProfile } from "static/icons/elements-icons-hide.svg";
import { ReactComponent as ResendLink } from "static/icons/elements-icons-send.svg";
import { ReactComponent as DeleteProfile } from "static/icons/elements-icons-delete-forever.svg";
import { useMutation } from "react-query";

import {
	USER_RESEND_ACTIVATION_EMAIL,
	DELETE_PROFILE
} from "config/api-endpoints";
import { client } from "lib/api-client";
import toaster from "common/Toaster";
import DeleteModal from "modules/vacancy/components/candidatePool/ModalDeleteProfil";
import { withRouter } from "react-router";
import HidePermanentlyModal from "common/ProfileActions/HidePermanentlyModal";
import get from "lodash/get";
import Menu from "./menu";
import useOnClickOutside from "hooks/useOnClickOutside";

const resendInvitation = data =>
	client(USER_RESEND_ACTIVATION_EMAIL, { body: data });
const deletProfile = data => client(DELETE_PROFILE, { body: data });

const ProfileActions = ({
	router,
	refetchProfileData,
	canResendActivationEmail,
	canDelete,
	nextProfile,
	goToProfile,
	displayCtas,
	closeCtas
}) => {
	const actionRef = useRef(null);

	const [deleteProfileModal, setDeleteProfileModal] = useState(false);
	const [
		displayHidePermanentlyModal,
		setDisplayHidePermanentlyModal
	] = useState(false);
	const {
		params: { id }
	} = router;
	const [mutate] = useMutation(resendInvitation, {
		onSuccess: () => {
			toaster.success("The activation email has successfully been resent.");
			refetchProfileData();
		},
		onError: error => {
			toaster.danger(
				get(error, "detail.name", get(error, "detail", get(error, "title")))
			);
		}
	});
	const [mutateProfil] = useMutation(deletProfile, {
		onSuccess: () => {
			toaster.success("profile Deleted");
			goToProfile(nextProfile);
		},
		onError: error => {
			toaster.danger(
				get(
					error,
					"detail.profile_ids",
					get(error, "detail", get(error, "title"))
				)
			);
		}
	});
	const deletPendingProfile = () => {
		mutateProfil({ profile_ids: [id] });
	};
	const sendInvitation = () => {
		mutate({ profile_ids: [id] });
	};

	const actions = [
		{
			label: "Hide permanently",
			action: () => {
				setDisplayHidePermanentlyModal(true);
				closeCtas();
			},
			icon: <HideProfile className="icon" />,
			mb: canResendActivationEmail || canDelete ? 8 : 0
		},
		{
			...(canResendActivationEmail && {
				label: "Resend activation link",
				action: () => {
					sendInvitation();
					closeCtas();
				},
				icon: <ResendLink className="icon" />,
				mb: canDelete ? 8 : 0
			})
		},
		{
			...(canDelete && {
				label: "Delete",
				action: () => {
					setDeleteProfileModal(true);
					closeCtas();
				},
				icon: <DeleteProfile className="icon" />,
				mb: 0
			})
		}
	];

	useOnClickOutside(actionRef, () => {
		closeCtas();
	});

	return (
		<React.Fragment>
			{displayCtas && (
				<Box
					sx={{
						position: "absolute",
						zIndex: 99999,
						top: "-30px",
						left: "-60px",
						minWidth: "250px"
					}}
					ref={actionRef}
				>
					<Menu actions={actions} />
				</Box>
			)}

			{deleteProfileModal && (
				<DeleteModal
					closeModal={() => setDeleteProfileModal(false)}
					deletPendingProfile={deletPendingProfile}
					profileId={[id]}
				/>
			)}

			<HidePermanentlyModal
				active={displayHidePermanentlyModal}
				closeModal={() => setDisplayHidePermanentlyModal(false)}
				profile_id={id}
				nextAction={() => goToProfile(nextProfile)}
			/>
		</React.Fragment>
	);
};

export default withRouter(ProfileActions);
