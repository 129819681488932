import React from "react";
import AxiosHelper from "config/axios-helper";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { HIDE_PROFILE_ENDPOINT } from "config/api-endpoints";

const HideProfilePermanently = ({
	active,
	closeModal,
	profile_id,
	nextAction
}) => {
	const hideProfile = () => {
		return AxiosHelper.post({
			url: HIDE_PROFILE_ENDPOINT,
			data: {
				profile_id
			},
			toastMessage: "Profile hidden permanently",
			isModal: true
		}).then(() => {
			nextAction && nextAction();
			closeModal();
		});
	};

	return (
		<ConfirmationModal
			active={active}
			modalName="hide-permanently"
			onClose={closeModal}
			firstButton={{
				action: hideProfile,
				label: "YES, HIDE PERMANENTLY.",
				type: "danger"
			}}
			title="Are you sure you would like to hide
            this profile permanently?"
			content={
				<>
					This candidate will no longer appear in any of your future searches.
				</>
			}
			type={ConfirmationTypes.error}
		/>
	);
};

export default HideProfilePermanently;
