import { useEffect, useRef, useState, useLayoutEffect } from "react";

export function useBounds(ref) {
	const [dimensions, setDimensions] = useState({});
	useLayoutEffect(() => {
		if (ref && ref.current) {
			setDimensions(ref.current.getBoundingClientRect().toJSON());
		}
	}, [ref]);
	return [ref, dimensions];
}

export function useScript(src) {
	const [state, setState] = useState({
		loaded: false,
		error: false
	});

	useEffect(() => {
		let script = document.createElement("script");
		script.src = src;
		script.async = true;

		// Script event listener callbacks for load and error
		const onScriptLoad = () => {
			setState({
				loaded: true,
				error: false
			});
		};

		const onScriptError = () => {
			script.remove();

			setState({
				loaded: true,
				error: true
			});
		};

		script.addEventListener("load", onScriptLoad);
		script.addEventListener("error", onScriptError);

		document.body.appendChild(script);

		return () => {
			script.removeEventListener("load", onScriptLoad);
			script.removeEventListener("error", onScriptError);
		};
	}, [src]);

	return [state.loaded, state.error];
}

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}
