import React from "react";
import { StepperContainer } from "./Styled";
import { PROFILE, REQUEST, PREVIEW } from "config";
const Stepper = ({ step, isPublished, isJobView, customSteps, isVacancy }) => {
	const defaultSteps = [
		{
			boxText: "01",
			step: 1,
			stepName: "Profile",
			active: step === PROFILE,
			done: step === REQUEST || step === PREVIEW
		},
		{
			boxText: "02",
			step: 2,
			stepName: isVacancy ? "Vacancy details" : "Request details",
			active: step === REQUEST,
			done: step === PREVIEW
		},
		{
			boxText: "03",
			step: 3,
			stepName: "Review & Publish",
			active: step === PREVIEW,
			done: isPublished
		}
	];

	const steps = customSteps || defaultSteps;

	return (
		<StepperContainer isJobView={isJobView}>
			{steps.map((s, index) => {
				return (
					<>
						<div className="step">
							<div
								className={`step-box ${s.active ? "active" : ""} ${
									s.done ? "active" : ""
								}`}
							>
								{s.done ? (
									<div className="icon-container">
										<span className="icon-check2" />
									</div>
								) : (
									s.boxText
								)}
							</div>
							<div>
								<div className="step-count">STEP {s.step}</div>
								<div className="step-name">{s.stepName}</div>
							</div>
						</div>
						{index !== steps.length - 1 && (
							<div className={`divider ${s.done ? "done" : ""}`} />
						)}
					</>
				);
			})}
		</StepperContainer>
	);
};

export default Stepper;
