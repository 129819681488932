import React, { useState } from "react";
import BodyClassName from "react-body-classname";
import { StickyContainer } from "react-sticky";
import _get from "lodash/get";
import {
	MAX_LANGUAGE_WEIGHT,
	MAX_SKILL_WEIGHT,
	VACANCY,
	SKILLS,
	WELCOME_BACK,
	WELCOME,
	REMOTE,
	REMOTE_LABEL
} from "config";
import JobTopbar from "./JobTopbar";
import Steps from "modules/bids/components/shared/stepper";
import CompanyDashboard from "common/dashboard/Dashboard";
import ListItem from "common/ListItem";
import AddressComposer from "common/AddressComposer";
import GlobalTooltip from "common/GlobalTooltip";
import PokeDetails from "./PokeDetails";
import PokeSidebar from "./PokeSidebar";
import ConfirmInterestModal from "./ConfirmInterestModal";
import DeclineInterestModal from "./DeclineInterestModal";
import JobFunctions from "../../../../common/JobFunctions";
import InterviewInvitation from "common/InterviewInvitation";
import ProposeInterviewModal from "./ProposeInterviewModal";
import RemoveInterestModal from "../Modals/RemoveInterestModal";
import CancelApplicationModal from "../Modals/CancelApplicationModal";
import { OutlinedLink } from "common/styled/buttons";
import CancelInterview from "../Modals/CancelInterview";
import AcceptInterviewModal from "common/AcceptInterviewModal";
import { getCandidateJobListRoute } from "modules/candidate/components/JobList";
import FirstSigninModal from "../Modals/FirstSigninModal";
import IncompleteProfileModal from "./IncompleteProfileModal";
import { browserHistory } from "react-router";
import "./job-details-permanent.scss";
import QuestionnaireForm, {
	QuestionnaireFormQuestionItem
} from "common/QuestionnaireForm";
import LexicalContentView from "common/LexicalContentView";

const PokeView = ({
	poke,
	client,
	job,
	steps,
	pokeDate,
	status,
	visible_status,
	confirmInterest,
	declineInterest,
	toggleConfirmModal,
	toggleDeclineModal,
	toggleHelpModal,
	displayConfirmModal,
	displayDeclineModal,
	status_changed_on,
	accountStatus,
	confirmed,
	confirmedAt,
	job_status,
	cancelData,
	canDisplayInvitation,
	confirmInterview,
	closeInvitationModal,
	hasInterview,
	interview,
	canDisplayPropose,
	ProposeOtherInterview,
	closeProposeModal,
	editInterview,
	openInterviewInvitation,
	user,
	additionalComment,
	modalCancelInterview,
	toggleCancelInterviewModal,
	modalRemoveInterest,
	toggleModalRemoveInterest,
	toggleCancelApplicationModal,
	modalCancelApplication,
	query,
	pathname,
	questionnaireData,
	setInterviewTimeError
}) => {
	const stepperStatus = _get(poke, "stepper_status");
	const is_applied = _get(poke, "is_applied");
	const [
		displayAcceptInterviewConfirmation,
		setDisplayAcceptInterviewConfirmation
	] = useState(false);

	const [displayIncompleteModal, setDisplayIncompleteModal] = useState(
		_get(query, "search") === WELCOME_BACK
	);
	const [displayFirstSigninModal, setDisplayFirstSigninModal] = useState(
		_get(query, "type") === WELCOME
	);

	const getButtonProps = () => {
		let key = _get(Object.keys(query), "0", "");
		let value = _get(Object.values(query), "0", "");

		if (key === "template" || key === "search") {
			return {
				label: "BACK TO SEARCH",
				link: `/jobs/search${value ? `?template=${value}` : ""}`
			};
		}

		return {
			label: "BACK TO MY JOBS",
			link: getCandidateJobListRoute()
		};
	};

	const closeFirstSigningModal = () => {
		const queryWithoutType = { ...query };
		delete queryWithoutType.type;
		browserHistory.replace({ pathname, query: queryWithoutType });
		setDisplayFirstSigninModal(false);
		localStorage.removeItem("companyLogo");
		localStorage.removeItem("companyName");
	};
	const onEditFirstSigningModal = () => {
		setDisplayFirstSigninModal(false);
		localStorage.removeItem("companyLogo");
		localStorage.removeItem("companyName");
		browserHistory.push(`/my-cv?from_poke=true&#2`);
	};

	return (
		<BodyClassName className={`bid gray-bg job_details_permanent`}>
			<div className="view-job-details" id="job-details-permanent-container">
				<CompanyDashboard />
				<div className="container">
					{!is_applied ? (
						<>
							<Steps steps={steps || []} />
							{query && (
								<OutlinedLink to={getButtonProps().link} small has-icon>
									<i className="icon-arrow-left" />
									{getButtonProps().label}
								</OutlinedLink>
							)}
						</>
					) : (
						<OutlinedLink to={getButtonProps().link} small has-icon>
							<i className="icon-arrow-left" />
							{getButtonProps().label}
						</OutlinedLink>
					)}

					<div className="top-bar">
						<JobTopbar
							title={_get(job, "title")}
							category={_get(job, "category")}
							subcategory={_get(job, "subcategory")}
							_function={_get(job, "function")}
							seniority={_get(job, "seniority")}
							type={VACANCY}
							job={job}
						/>
					</div>
				</div>
				<StickyContainer>
					<div className="container">
						<div className="row">
							<div className="col-md-9 content-inner">
								<PokeDetails
									pokeDate={pokeDate}
									clientName={`${_get(client, "first_name")} ${_get(
										client,
										"last_name"
									)}`}
									companyName={_get(client, "company_name")}
									title={_get(job, "title")}
									status={status}
									confirmed={confirmed}
									confirmedAt={confirmedAt}
									visible_status={visible_status}
									status_changed_on={status_changed_on}
									job_status={job_status}
									cancelData={cancelData}
									hasInterview={hasInterview}
									interview={interview}
									stepperStatus={stepperStatus}
									additional_comment={additionalComment}
									interviewStatus={_get(interview, "visible_status")}
									user={user}
									interviews={_get(poke, "interviews")}
									userSenderAvatar={_get(interview, "user_sender.avatar")}
									pokeId={_get(poke, "_id")}
									cancelInterviewAt={_get(poke, "cancel_data.cancelled_at")}
									is_applied={_get(poke, "is_applied")}
									applied_at={_get(poke, "applied_at")}
									cancelApplication_data={_get(poke, "cancel_data")}
									cancel_comments={_get(poke, "cancel_comments")}
									offer={_get(poke, "offer", {})}
									poke_type={_get(poke, "poke_type")}
									interviewTimeSelected={_get(poke, "data.interview_time")}
								/>
								{job && (
									<>
										<div className="section">
											<div className="section-title">JOB DESCRIPTION</div>
											<LexicalContentView
												description={_get(job, "description")}
											/>

											<div />
										</div>
										{_get(job, "profile_description") && (
											<div className="section">
												<div className="section-title">PROFILE DESCRIPTION</div>
												<LexicalContentView
													description={_get(job, "profile_description")}
												/>
												<div />
											</div>
										)}
									</>
								)}
								<JobFunctions functions={_get(job, "functions", [])} />
								<ListItem
									title={"Skills"}
									list={_get(job, "skills", [])}
									scoreMax={MAX_SKILL_WEIGHT}
									type={SKILLS}
									isVacancy={true}
								/>
								<ListItem
									title={"Languages"}
									list={_get(job, "languages")}
									scoreMax={MAX_LANGUAGE_WEIGHT}
								/>
								<div className="section">
									<div className="section-title">LOCATION</div>
									{_get(poke, "location_type") === REMOTE ? (
										REMOTE_LABEL
									) : (
										<AddressComposer addressData={job} />
									)}
								</div>
								<div className="section job-info">
									<ul>
										<li>
											<i className="icon-requestreference" />
											<h5>
												Job reference{" "}
												<GlobalTooltip
													placement="top"
													eventTrigger="hover"
													overlay={
														<div>
															When contacting our support team regarding a job,
															please be sure to mention its reference. This will
															help our support team assist you quickly and
															efficiently.
														</div>
													}
												/>
											</h5>
											<p>{_get(job, "job_ref")}</p>
										</li>
										<li>
											<i className="icon-departement" />
											<h5>Department</h5>
											<p>{_get(job, "group.name", "")}</p>
										</li>
									</ul>
								</div>
								{questionnaireData && (
									<div className="section">
										<div className="section-title">QUESTIONNAIRE</div>
										<QuestionnaireForm className="questionnairePoKeView">
											{questionnaireData.map((question, index) => {
												return (
													<QuestionnaireFormQuestionItem
														key={index}
														{...question}
														isReadOnly
														isQuestionnaireResult
													/>
												);
											})}
										</QuestionnaireForm>
									</div>
								)}
							</div>
							<div className="col-md-3">
								<PokeSidebar
									poke={poke}
									salary_range_start={_get(job, "salary_range_start")}
									salary_range_end={_get(job, "salary_range_end")}
									payroll_country={_get(job, "payroll_country")}
									other_benefits={_get(job, "other_benefits")}
									payment_time={_get(job, "payment_time", "yearly")}
									payment_type={_get(job, "payment_type", "gross")}
									symbol={_get(job, "symbol", "€")}
									stepperStatus={_get(poke, "stepper_status")}
									company_name={_get(client, "company_name")}
									declineInterest={declineInterest}
									status={status}
									toggleConfirmModal={toggleConfirmModal}
									toggleDeclineModal={toggleDeclineModal}
									accountStatus={accountStatus}
									toggleHelpModal={toggleHelpModal}
									openInterviewInvitation={openInterviewInvitation}
									ProposeOtherInterview={ProposeOtherInterview}
									hasInterview={hasInterview}
									interview={interview}
									toggleModalRemoveInterest={toggleModalRemoveInterest}
									toggleCancelInterviewModal={toggleCancelInterviewModal}
									visible_status={visible_status}
									confirmed={confirmed}
									user={user}
									setDisplayAcceptInterviewConfirmation={
										setDisplayAcceptInterviewConfirmation
									}
									setInterviewTimeError={setInterviewTimeError}
									confirmInterview={confirmInterview}
									toggleCancelApplicationModal={toggleCancelApplicationModal}
									offer={_get(poke, "offer")}
								/>
							</div>
						</div>
						{_get(modalRemoveInterest, "show") && (
							<RemoveInterestModal
								poke_id={poke._id}
								onClose={toggleModalRemoveInterest}
								condidateSide={true}
							/>
						)}
						{_get(modalCancelApplication, "show") && (
							<CancelApplicationModal
								poke_id={poke._id}
								job_id={job._id}
								onClose={toggleCancelApplicationModal}
								condidateSide={true}
							/>
						)}
						{_get(modalCancelInterview, "show") && (
							<CancelInterview
								poke_id={poke._id}
								onClose={toggleCancelInterviewModal}
								token_invitation_id={_get(
									poke,
									"interview.token_invitation_id"
								)}
								interview_id={_get(poke, "interview._id")}
							/>
						)}
						<ConfirmInterestModal
							displayConfirmModal={displayConfirmModal}
							toggleConfirmModal={toggleConfirmModal}
							confirmInterest={confirmInterest}
						/>
						<DeclineInterestModal
							displayDeclineModal={displayDeclineModal}
							toggleDeclineModal={toggleDeclineModal}
							declineInterest={declineInterest}
						/>
						<InterviewInvitation
							canDisplayModal={canDisplayInvitation}
							onCloseModal={closeInvitationModal}
							interview={interview}
							closeFirstSigningModal
							onPropose={ProposeOtherInterview}
							firstName={_get(client, "first_name")}
							lastName={_get(client, "last_name")}
							onAccept={() => setDisplayAcceptInterviewConfirmation(true)}
						/>
						<AcceptInterviewModal
							acceptInterview={() =>
								confirmInterview(true, () =>
									setDisplayAcceptInterviewConfirmation(false)
								)
							}
							closeModal={() => setDisplayAcceptInterviewConfirmation(false)}
							displayModal={displayAcceptInterviewConfirmation}
						/>
						<ProposeInterviewModal
							active={canDisplayPropose}
							title="Propose another date/time"
							onClose={closeProposeModal}
							onPropose={editInterview}
							user={user}
							interview={interview}
						/>
					</div>
				</StickyContainer>
				{job && displayIncompleteModal && (
					<IncompleteProfileModal
						vacancyTitle={_get(job, "title")}
						companyName={_get(client, "company_name")}
						onClose={() => {
							setDisplayIncompleteModal(false);
							const url = window.location.pathname;
							window.history.pushState(
								{
									path: url
								},
								"",
								url
							);
						}}
						isWelcome={_get(query, "type") === WELCOME}
					/>
				)}
				{job && displayFirstSigninModal && (
					<FirstSigninModal
						closeModal={closeFirstSigningModal}
						onEdit={onEditFirstSigningModal}
					/>
				)}
			</div>
		</BodyClassName>
	);
};

export default PokeView;
