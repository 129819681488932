import React from "react";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import Portal from "common/Portal";

const DeleteContractsGroupConfirmationDialog = ({ onClose, onConfirm }) => {
	const modalDeleteButtonConfig = {
		action: onConfirm,
		label: "YES, DELETE",
		type: "danger"
	};

	return (
		<Portal>
			<ConfirmationModal
				active={true}
				title="Are you sure you want to delete this group ?"
				onClose={onClose}
				type={ConfirmationTypes.error}
				firstButton={modalDeleteButtonConfig}
			/>
		</Portal>
	);
};

export default DeleteContractsGroupConfirmationDialog;
