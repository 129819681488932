import React from "react";
import InterviewStatus from "./InterviewStatus";
import EmptyState from "./EmptyState";
import get from "lodash/get";
import moment from "moment";
import LoadingState from "./LoadingState";

const InterviewHistory = ({ candidateName, data, isLoading }) => {
	return (
		<div className="interview-history">
			<div className="history-interview-title">
				<span className="headline">History</span>
				<span className="subtitle">
					Last Interviews planned with “{candidateName}”
				</span>
			</div>
			{isLoading ? (
				new Array(2).fill(<LoadingState />)
			) : get(data, "length", 0) > 0 ? (
				data.map(item => (
					<InterviewStatus
						key={item._id}
						label={item.full_name}
						date={moment
							.unix(item.new_interview_start_date)
							.format("DD/MM/YYYY")}
						detail={`${moment
							.unix(item.new_interview_start_date)
							.format("HH:mm")} - ${moment
							.unix(item.new_interview_end_date)
							.format("HH:mm")}`}
						color="#00BA88"
						status={item.status}
					/>
				))
			) : (
				<EmptyState />
			)}
		</div>
	);
};

export default InterviewHistory;
