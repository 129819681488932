import React from "react";
import Tooltip from "rc-tooltip";
import { stringify } from "query-string";
import { Controller } from "react-hook-form";
import get from "lodash/get";
import { ReactComponent as InfoIcon } from "static/icons/info-icon.svg";
import styles from "./contract-credentials-fields-list.module.scss";
import InputField from "modules/vonqContract/components/InputFiled";
import SelectField from "../SelectField/index";
import { ReactComponent as CheckIcon } from "static/icons/check-circle.svg";

const fieldRules = {
	required: "This field is required."
};

const ContractCredentialsFieldsList = ({
	fields,
	clearErrors,
	control,
	errors,
	channelId,
	formValues
}) => {
	const getRedirectURl = url => {
		const params = stringify({
			channelId,
			formValues: JSON.stringify(formValues),
			callbackUrl: window.location.href
		});

		return `${url}&${params}`;
	};

	return (
		<div className={styles.fieldsContainer}>
			{fields.map(({ name, label, description, options, url }) => {
				const id = `credentials.${name}`;

				const error = get(errors, id) || errors[name];

				return (
					<Controller
						name={id}
						key={name}
						control={control}
						rules={fieldRules}
						render={({ field: { onChange, value } }) => {
							if (url && name !== "feed-random") {
								return (
									<div className={styles.autenticateInput}>
										<label className={styles.label}>
											<span>
												{label}&nbsp;
												<span className={styles.red}>*</span>
											</span>
											{description && (
												<Tooltip
													placement="right"
													overlay={
														<div
															dangerouslySetInnerHTML={{ __html: description }}
														/>
													}
													trigger="hover"
													destroyTooltipOnHide={true}
													overlayClassName="g-tool-white"
												>
													<InfoIcon />
												</Tooltip>
											)}
										</label>
										{value ? (
											<div className={styles.authenticatedBox}>
												<CheckIcon />
												<div>You are authenticated</div>
											</div>
										) : (
											<a className={styles.link} href={getRedirectURl(url)}>
												Initialize authentication
											</a>
										)}
										{error && (
											<div className={styles.error}>{error.message}</div>
										)}
									</div>
								);
							} else if (Array.isArray(options)) {
								return (
									<SelectField
										value={value}
										onChange={({ key }) => {
											if (error?.type === "manual") clearErrors(name);
											onChange(key);
										}}
										label={label}
										options={options}
										description={description}
										labelKey="label"
										valueKey="key"
										isRequired={true}
										error={error?.message}
										className={styles.select}
									/>
								);
							} else {
								return (
									<InputField
										value={value}
										onChange={e => {
											if (error?.type === "manual") clearErrors(name);
											onChange(e);
										}}
										type="text"
										label={
											<>
												{label} <span style={{ color: "red" }}>*</span>
											</>
										}
										description={description}
										error={error?.message}
									/>
								);
							}
						}}
					/>
				);
			})}
		</div>
	);
};

export default ContractCredentialsFieldsList;
