import React from "react";
import PropTypes from "prop-types";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { connect } from "react-redux";
import { UNPUBLISHED } from "config";

const BidsAction = ({ children, openModal }) => {
	const handleMenuClick = () => {
		openModal();
	};

	const listMenu = () => {
		return (
			<Menu onClick={handleMenuClick} selectable={false}>
				<MenuItem key={UNPUBLISHED} className={`ignore-click ${UNPUBLISHED}`}>
					<span className="ignore-click">
						<i className="icon icon-delete ignore-click" />
						Cancel Application
					</span>
				</MenuItem>
			</Menu>
		);
	};

	return (
		<RcDropDown
			trigger={["click"]}
			overlay={listMenu()}
			overlayClassName="ignore-click"
			align={{
				points: ["tc", "bc"],
				offset: [0, -14]
			}}
		>
			{children}
		</RcDropDown>
	);
};

BidsAction.propTypes = {
	bid_id: PropTypes.string.isRequired
};

export default connect()(BidsAction);
