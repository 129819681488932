import styled, { css } from "styled-components";
import { TabList, TabPanel } from "@reach/tabs";
import { colors } from "config/styles";

export const Header = styled.div`
	color: ${colors.contactColor};
	padding: 0 15px;
	width: 100%;
	background-color: #ffffff;
	margin-bottom: 5px;
	&.header-client-view {
		background-color: transparent;
		margin-bottom: 0;
	}
	.info-permanent {
		font-size: 13px;
		margin-top: 40px;
		margin-left: 30px;
		@media (max-width: 991px) {
			margin-left: 0px;
		}
	}
	.uploader {
		height: ${props => (props.isOwnProfile ? "170px" : "150px")};
		width: ${props => props.isOwnProfile && "170px"};
		position: relative;
		background: ${colors.disabledSelect};
		border: 2px solid #eaebee;
		border-radius: 3px;
		text-align: center;
		.center {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			transform: translate(-50%, -50%);
		}
		img {
			margin-bottom: 15px;
		}
		h5 {
			color: ${colors.contactColor};
			text-transform: none;
			font-size: 13px;
			margin-bottom: 0;
		}
		.placeholder-btn {
			max-width: 100%;
			float: none;
			color: ${colors.contactColor};
			font-weight: 300;
			font-size: 14px;
			line-height: 1.3;
			font-size: 13px;
		}
		.file-container {
			border: none;
			text-align: center;
			.fake_file {
				text-align: center;
				.placeholder-btn {
					font-size: 11px;
					color: ${colors.contactColor};
					float: left;
					max-width: 100% !important;
					overflow: hidden;
					line-height: 20px;
					font-weight: normal;
				}
			}
		}
		.file-progress-box {
			margin-top: 40px;
			width: 80%;
			height: 10px;
			position: absolute;
			top: 70%;
			left: 50%;
			transform: translate(-50%, -50%);
			.file-progress-bar {
				height: 10px;
			}
		}
	}
	.avatar-holder {
		position: relative;
		transition: all 0.25s ease-in-out;
		margin-bottom: ${props => (props.isOwnProfile ? "0px" : "30px")};
		.btn-round {
			width: 30px;
			height: 30px;
			position: absolute;
			top: -10px;
			right: 10px;
			z-index: 5;
			padding: 0;
			display: none;
			background-color: ${colors.paused};
			.icon-trashcan {
				font-size: 12px;
			}
		}
		.dropshadow {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: rgba(0, 0, 0, 0.4);
			display: none;
		}
		.slim-uploader {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: ${props => (props.isOwnProfile ? "170px" : "150px")};
			height: ${props => (props.isOwnProfile ? "170px" : "150px")};
			.droppable-holder,
			.s3-uploader,
			.file-container {
				width: 100%;
				height: 100%;
			}
			.s3-uploader {
				display: none;
			}
			.file-container {
				border: none;
				.fake_file {
					padding: 0;
					.fake-button {
						position: absolute;
						bottom: -5px;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 75%;
						float: none;
						font-size: 12px;
						font-weight: 400;
						letter-spacing: 0.5px;
						color: ${colors.white};
						padding: 13px 5px;
						border-radius: 1.5px;
						background-color: ${colors.primaryColor};
						text-transform: uppercase;
						line-height: 100%;
					}
					.placeholder-btn {
						display: none;
					}
				}
			}
			.busy {
				display: block;
				.file-progress-box {
					position: absolute;
					top: 50%;
					height: 8px;
					width: 80%;
					left: 50%;
					transform: translate(-50%, -50%);
					margin-top: 0;
				}
			}
		}
		&:hover {
			.slim-uploader {
				.dropshadow,
				.s3-uploader {
					display: block;
				}
			}
			.btn-round {
				display: block;
			}
		}
	}
	.section-edit {
		padding-right: 18px;
		.btn-edit-profile {
			border: 1.5px solid ${colors.primaryColor};
			border-radius: 4px;
			background-color: #fff;
			padding: 8px 12px;
			color: ${colors.primaryColor};
			font-weight: 500;
			font-size: 11px;
			line-height: normal;
			text-transform: inherit;
			outline: none;
			float: right;
			&:hover,
			&:active {
				background-color: ${colors.primaryColor};
				color: ${colors.white};
				outline: none;
			}
			.icon-edit-2 {
				margin-right: 12px;
				font-size: 16px;
			}
		}
	}

	.username {
		text-align: center;
		color: ${colors.bidList};
		text-align: left;
		font-weight: 500;
		font-size: ${props => props.isOwnProfile && "16px"};
		text-transform: ${props => props.isOwnProfile && "capitalize"};
	}
	.circle {
		width: 15px;
		height: 15px;
		border: 2px solid red;
		border-radius: 50%;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	p {
		display: inline-block;
	}
	.function {
		margin-top: 20px;
		line-height: 1px;
	}
	#contact {
		margin-top: 18px;
		line-height: 1;
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 400;
		div {
			max-width: 60%;
			display: inline-block;
			&.contact-phone {
				margin-right: 20px;
			}
		}
		.placeholder {
			width: 150px;
			margin-left: 0;
			top: 0;
			&::before {
				height: 11px;
			}
			&::after {
				display: none;
			}
		}
	}
	.icon-phone-handle {
		margin-right: ${props => (props.isOwnProfile ? "10px" : "6px")};
		font-weight: 600;
		color: ${props => props.isOwnProfile && colors.primaryColor};
	}
	.icon-mail {
		margin-right: ${props => (props.isOwnProfile ? "10px" : "6px")};
		vertical-align: middle;
		font-size: 15px;
		font-weight: 600;
		color: ${props => props.isOwnProfile && colors.primaryColor};
	}
	.title {
		width: 370px;
		font-weight: bold;
		color: ${colors.bidList};
		display: inline-block;
		text-align: center;
		position: relative;
		.reference {
			color: ${colors.contactColor};
			letter-spacing: 1px;
			margin-left: 10px;
			font-weight: 400;
		}
	}
	.btn-edit-profile {
		padding: 10px 40px;
		margin-top: ${props => (props.isOwnProfile ? "22px" : "20px")};
	}
`;

export const Status = styled.span`
	border-radius: 3px;
	color: white;
	right: 0;
	font-weight: 600;
	padding: 4px 7px;
	font-size: 11px;
	text-transform: capitalize;
	margin-left: 10px;
	display: inline-block;
	position: relative;
	top: -3px;
	&.passive {
		background-color: ${colors.stoppedColor};
	}
	&.public {
		background-color: ${colors.stepperIconColor};
	}
	&.inactive {
		background-color: ${colors.paused};
	}
	&.anonymous {
		background-color: ${colors.submittedColor};
	}
`;

export const TabsContainer = styled.div`
	background-color: white;
	padding: 30px;
	text-align: center;
	height: 80px;
	margin-bottom: 20px;
`;

export const Container = styled.div`
	max-width: ${props => props.width || 990}px;
	margin: 0 auto;
	width: 100%;
	background-color: white;
	padding: 30px 30px 0 30px;
`;
export const CvContainer = styled.div`
	max-width: ${props => props.width || 990}px;
	margin: 0 auto;
	width: 100%;
`;

export const Section = styled.div`
	background-color: white;

	padding: ${props => props.padding || "40px 40px"};
	.title {
		color: ${colors.linksColor};
		font-weight: 500;
		margin-bottom: 20px;
	}
`;

export const FrontSheet = styled.table`
	border: 1px solid ${colors.sectionBorderColor};
	border-radius: 10px;
	width: 100%;
	color: ${colors.inputColor};
	font-weight: 400;
	.location-container {
		display: flex;
		flex-wrap: wrap;
		max-width: 100%;
	}

	.benefitsLabel {
		display: flex;
		flex-wrap: wrap;
		gap: 7px;
		.extraContainer {
			border: 0.5px solid gray;
			padding: 5px 10px 5px 10px;
			border-radius: 3px;
			color: #58626b;
			font-weight: 500;
			font-size: 12px;
			box-shadow: 0 0 19.8px 0.2px rgb(214 214 214 / 24%);
		}
	}

	.employment-container {
		display: flex;
		flex-wrap: wrap;
		max-width: 100%;
	}
	td {
		padding: 20px 25px;
		&:first-child {
			font-weight: 500;
			text-transform: uppercase;
			width: 40%;
		}
		.location {
			border: 1px solid ${colors.disabledGray};
			border-radius: 3px;
			padding: 10px;
			margin-right: 10px;
		}
	}
	tr {
		border-bottom: 1px solid ${colors.sectionBorderColor};
	}
	.amount {
		font-weight: 500;
		margin-right: 10px;
	}
	.advantages {
		width: 50%;
		margin-left: 20px;
		color: #3d4b60;
	}
	.salary {
		width: 60%;
	}
	.salary-td {
		display: flex;
	}
	th {
		border-left: 1px solid ${colors.sectionBorderColor};
		text-align: center;
		.btn-sm {
			color: #58626b;
			&:hover {
				color: #fff;
				background-color: #8b95a1;
			}
			&.front-sheet {
				background-color: unset !important;
				&:hover {
					background-color: #8b95a1 !important;
				}
			}
		}
	}
`;

export const LocationTextBox = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
export const LocationBox = styled.div`
	max-width: 170px;
	height: 31.5px;
	border-radius: 3px;
	box-shadow: 0 0 19.8px 0.2px rgba(214, 214, 214, 0.24);
	border: solid 0.5px ${colors.locationBox};
	background-color: ${colors.white};
	text-align: center;
	vertical-align: middle;
	line-height: 31.5px;
	font-size: 12px;
	font-weight: 500;
	color: ${colors.anchorsColor};
	${props =>
		props.overflow &&
		css`
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		`};
	${props =>
		props.flex &&
		css`
			display: flex;
		`};
	text-transform: capitalize;
	margin-right: 10px;
	padding: 0 10px 0 10px;
	margin-bottom: 10px;
	i {
		font-size: 12px;
	}
`;

export const Message = styled.div`
	margin-bottom: 40px;
	font-size: 16px;
	position: relative;
	.title {
		color: ${colors.anchorsColor};
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
	}
	.message-box {
		padding: 20px;
		background-color: ${colors.invitationDescription};
		color: ${colors.inputColor};
		border: 1px solid ${colors.loaderBaseColor};
		font-size: 14px;
		font-weight: 400;
	}
	a {
		color: ${colors.primaryColor};
		font-weight: 500;
	}
	.additional-comment {
		position: relative;
		left: -21px;
		border-left: 3px solid ${colors.primaryColor};
		padding-left: 18px;
		width: 106%;
		margin-bottom: 15px;
		p {
			padding-right: 30px;
			color: ${colors.anchorsColor} !important;
			font-weight: 400 !important;
			font-size: 14px !important;
		}
	}
`;

export const ActionsContainer = styled.div`
	float: left;
	button:first-of-type {
		margin-right: 30px;
	}
`;

export const AnonymousAvatar = styled.div`
	position: relative;
	i {
		border: 2px solid white;
		border-radius: 50%;
		background-color: ${colors.lockIconGray};
		color: white;
		padding: 6px 9px;
		position: absolute;
		right: 0;
		top: 20px;
		width: 37px;
		height: 37px;
		font-size: 19px;
		&:hover {
			background-color: ${colors.anchorsColor};
		}
	}
	margin-bottom: 5px;
`;

export const Head = styled.div`
	background-color: ${colors.white};
	padding: 20px;
	margin-bottom: 10px;
	color: ${colors.timesheetTh};
	font-weight: 500;
	div {
		width: 100%;
	}
	span:not(:last-child) {
		margin-right: 24%;
	}
`;
export const HistoryContainer = styled.div`
	.has-been-poked {
		color: ${colors.darkGray};
		background-color: ${colors.white};
		padding: 20px;
		margin-bottom: 20px;
		font-weight: 400;
		border-left: 3px solid ${colors.primaryColor};
		> span {
			color: ${colors.primaryColor};
		}
	}
`;
export const HistoryItem = styled.div`
	background-color: ${colors.white};
	padding: 20px;
	margin-bottom: 3px;
	div {
		width: 100%;
	}
	span:not(:last-child) {
		margin-right: 30%;
	}
`;
export const Table = styled.table`
	width: 100%;
	thead {
		background-color: ${colors.white};

		color: ${colors.timesheetTh};
		font-weight: 500;
		border-bottom: 7px solid ${colors.grayBodyBg};
		padding: 2%;
	}
	tbody {
		font-weight: 400;
		background-color: ${colors.white};
		tr {
			border-bottom: 3px solid ${colors.grayBodyBg};
		}
	}
	td {
		background-color: ${colors.white};
		padding: 2%;
	}
`;

export const EmptyHistory = styled.div`
	background-color: ${colors.white};
	padding: 60px;
	text-align: center;
	margin-bottom: 30px;
	svg {
		width: 80px;
		margin-bottom: 20px;
	}
	p {
		font-weight: 400;
		font-size: 16px;
	}
`;

export const StyledTabsList = styled(TabList)`
	text-align: center;
	height: 60px;
	display: flex;
	justify-content: center;
	width: ${props => props.width || 560}px;
	margin: 0 auto 20px;
	z-index: 1;
	background: white !important;
	button {
		color: ${colors.disabledGray};
		cursor: pointer;
		height: 60px;
		background-color: transparent;
		border: none;
		font-weight: 400;
		border-bottom: 2px solid transparent;
		padding: 0.25em 1em;
		&:focus {
			outline: none;
		}
		&[aria-selected="true"] {
			border-bottom: 2px solid ${colors.primaryColor};
			color: ${colors.linksColor};
			font-weight: 500;
		}
		&:active {
			background: #ffffff !important;
		}
	}
`;

export const StyledTabPanel = styled(TabPanel)`
	&:focus {
		outline: none;
	}
`;

export const Background = styled.div`
	height: 60px;
	width: 100%;
	margin-bottom: 10px;
	padding: 0 15px;
	&.view-candidate-tabs {
		background: #ffffff;
	}
	&.view-client-tabs {
		[data-reach-tab-list] {
			width: 100%;
			justify-content: initial;
			padding-left: 158px;
		}
	}
`;

export const LinkButton = styled.button`
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	color: ${colors.disabledGray};
	font-size: 13px;
	font-weight: 500;
	&:hover {
		color: ${colors.primaryColor};
		text-decoration: underline;
	}
`;

export const RedirectButton = styled.button`
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	outline: none;
	padding: 0;
	text-align: left;
	&:hover {
		color: ${colors.primaryColor};
		text-decoration: underline;
	}
`;

export const AvatarContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: ${props => props.marginBottom || "unset"};
`;

export const CVContainer = styled.div`
	max-height: ${props => props.maxHeight || "1000"}px;
	width: ${props => props.width || "800"}px;
	margin: 0 auto;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	button {
		height: 45px;
		margin-top: 20px !important;
		width: 183px;
		&:first-child {
			margin-right: 15px;
		}
	}
`;
export const EmploymentBox = styled.div`
	max-width: 170px;
	height: 31.5px;
	border-radius: 3px;
	box-shadow: 0 0 19.8px 0.2px rgba(214, 214, 214, 0.24);
	border: solid 0.5px;
	background-color: #ffffff;
	text-align: center;
	vertical-align: middle;
	line-height: 31.5px;
	font-size: 12px;
	font-weight: 500;
	color: #58626b;
	text-transform: capitalize;
	margin-right: 10px;
	padding: 0 10px 0 10px;
	margin-bottom: 10px;
`;
