import React, { Component } from "react";
import { Link } from "react-router";
import {
	DATE_FORMAT,
	FULL_TIME,
	FULL_TIME_LABEL,
	PART_TIME_LABEL,
	REMOTE,
	REMOTE_LABEL
} from "config";
import { isDateValid } from "common/Functions";
import Description from "common/Description";
import Skills from "common/Skills";
import { getLocalUser, isHTML } from "config/helpers";
import { getJobViewContainerRoute } from "modules/TempRequestDetailsModule";
import LexicalContentView from "common/LexicalContentView";

class JobItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedMode: false
		};
		const user = getLocalUser();
		this.isLoggedIn = !!user;
		this.has_cv = this.isLoggedIn && user.has_cv;
	}

	toggleExpandedMode = e => {
		e.preventDefault();
		this.setState(prevState => {
			return { expandedMode: !prevState.expandedMode };
		});
	};

	render() {
		const { job } = this.props;
		const { expandedMode } = this.state;
		return (
			<div className="job">
				<Link to={getJobViewContainerRoute(job.id)}>
					<div className="job-budget">
						<div className="duration">
							<span className="time-type">
								{job.type === FULL_TIME ? FULL_TIME_LABEL : PART_TIME_LABEL}
							</span>
						</div>
					</div>
					<h4 className="job-title">{job.title}</h4>
					<ul className="info list-unstyled">
						<li className="location">
							{job.location_type === REMOTE ? (
								<>
									<i className="icon icon-local" />
									{REMOTE_LABEL}
								</>
							) : (
								<>
									<i className="icon icon-local" />
									{`${job.country} ${job.city}`}
								</>
							)}
						</li>
						<li className="date">
							<i className="icon icon-inverted-watch" /> From{" "}
							{isDateValid(job.start_date, true)
								? window.moment.unix(job.start_date).format(DATE_FORMAT)
								: "-"}{" "}
							{" - "}
							{isDateValid(job.end_date, true)
								? window.moment.unix(job.end_date).format(DATE_FORMAT)
								: "-"}
						</li>
						<li className="date">
							<i className="icon icon-Posted" /> Posted on:{" "}
							{isDateValid(job.publish_date, true, DATE_FORMAT)
								? window.moment.unix(job.publish_date).format(DATE_FORMAT)
								: "-"}
						</li>
					</ul>
					<div className="description">
						{isHTML(job.description) ? (
							<LexicalContentView
								description={`${job.description.substring(0, 500)}...`}
							/>
						) : (
							<Description
								description={job.description}
								max={500}
								parseMarkdown={false}
								showMore={false}
								showMoreText="Read more"
								expanded={expandedMode}
							/>
						)}
					</div>
					<div className="skills">
						<div className="row skills-row">
							<div className="col-md-12">
								<Skills
									max={4}
									skills={job.skills}
									showMoreText={"•••"}
									showLessText={"↑"}
									readLessLinkClassName={"tag link"}
									readMoreLinkClassName={"tag link"}
									showMore={false}
									expanded={expandedMode}
								/>
							</div>
						</div>
					</div>
					<div className="clearfix" />
				</Link>
			</div>
		);
	}
}

export default JobItem;
