import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReportErrorModal from "./ReportErrorModal";
import AskQuestionModal from "./AskQuestionModal";
import { ASK_QUESTION, CONSULTANCY, REPORT_ERROR, GLOBAL } from "config";
import {
	REPORT_ERROR_ENDPOINT,
	ASK_QUESTION_ENDPOINT
} from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";
import styles from "./style.module.scss";
import cx from "classnames";
import toaster from "common/Toaster";
import Drawer from "rc-drawer";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";

function ModalUI(props) {
	const {
		type,
		isLoggedIn,
		fields,
		onClose,
		user,
		maskStyle = {},
		disableAnimation = false
	} = props;
	const isReport = type === REPORT_ERROR;

	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		requestAnimationFrame(() => {
			setIsOpen(true);
		});
	}, []);

	const handleSubmit = formProps => {
		const { isLoggedIn, user, fields, type } = props;
		const isReport = type === REPORT_ERROR;
		formProps = Object.assign({}, formProps);

		if (isLoggedIn) {
			formProps.name = `${user.first_name} ${user.last_name}`;
			formProps.email = user.email;
		}

		if (fields.message) {
			formProps.message = `${fields.message}\n\n${formProps.name}`.replace(
				/\n\n/g,
				"<br />"
			);
		}

		return AxiosHelper.post({
			url: isReport ? REPORT_ERROR_ENDPOINT : ASK_QUESTION_ENDPOINT,
			reduxFormName: isReport ? "help-report-error" : "help-ask-question",
			isLogin: true,
			isModal: true,
			data: {
				...formProps,
				type: (fields && fields.type) || GLOBAL,
				...fields.payload,
				link: window.location.href
			}
		}).then(() => {
			toaster.success(
				{
					[REPORT_ERROR]: "Your report regarding an issue has been submitted.",
					[ASK_QUESTION]: "Your question has been successfully submitted."
				}[props.type],
				{ duration: 10 }
			);
			onCloseDrawer();
		});
	};

	const onCloseDrawer = () => {
		if (disableAnimation) {
			onClose();
		} else {
			setIsOpen(false);
		}
	};

	const afterVisibleChange = visibility => {
		if (!visibility) {
			onClose();
		}
	};

	return (
		<Drawer
			open={disableAnimation ? true : isOpen}
			width="480px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			afterVisibleChange={afterVisibleChange}
			maskStyle={maskStyle}
		>
			<div className={styles.drawerContent}>
				<div className={styles.header}>
					<div className={styles.flex}>
						<div className={styles.title}>
							{isReport ? "Report an issue" : "Ask us a Question"}
						</div>
						<button onClick={onCloseDrawer} className={styles.closeBtn}>
							<span>Close</span>
							<CloseIcon />
						</button>
					</div>
					<div className={styles.divider}></div>
				</div>
				<section
					className={cx(
						styles.body,
						user.type === CONSULTANCY && styles.in_consultancy
					)}
				>
					{isReport ? (
						<ReportErrorModal
							isLoggedIn={isLoggedIn}
							onSubmit={handleSubmit}
							onClose={onCloseDrawer}
							fields={fields}
						/>
					) : (
						<AskQuestionModal
							isLoggedIn={isLoggedIn}
							onSubmit={handleSubmit}
							onClose={onCloseDrawer}
							fields={fields}
						/>
					)}
				</section>
			</div>
		</Drawer>
	);
}

ModalUI.propTypes = {
	onClose: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired
};

export default ModalUI;
