import React, { Component } from "react";
import { connect } from "react-redux";
import ConsultancyBidsList from "./ConsultancyBidsList";
import { consultancyListBids, filterFreelancerBidsList } from "../../actions";
import { bidListSelector } from "../../selectors";

export const getConsultancyBidsListRoute = () => {
	return "/jobs/consultancy/bids";
};

export const getConsultancyBidListPermission = () => {
	return {
		type: [
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
};

class ConsultancyBidListContainer extends Component {
	componentDidMount() {
		this.filterConsultancyBidsList({ offset: 0 }, "active_jobs");
	}

	getBidsList = (payload = {}) => {
		this.props.dispatch(consultancyListBids(payload));
	};

	filterConsultancyBidsList = (payload, status) => {
		this.props.dispatch(filterFreelancerBidsList(payload, status));
	};

	render() {
		const { bids, route } = this.props;
		return (
			<ConsultancyBidsList
				route={route}
				bids={bids}
				getBidsList={this.getBidsList}
				filterConsultancyBidsList={this.filterConsultancyBidsList}
			/>
		);
	}
}

const mapStateToProps = state => ({
	bids: bidListSelector(state)
});

export default connect(mapStateToProps)(ConsultancyBidListContainer);
