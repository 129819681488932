import React, { useState } from "react";
import styles from "./email-template-dropdown-actions.module.scss";
import { ReactComponent as DotsIcon } from "static/icons/email-template/dots-vertical.svg";
import { ReactComponent as DuplicateIcon } from "static/icons/duplicate-2.svg";
import { ReactComponent as EmailIcon } from "static/icons/email.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-2.svg";
import {
	MenuTrigger,
	Popover,
	Menu,
	Button,
	MenuItem
} from "react-aria-components";
import { v4 as uuid } from "uuid";
import useDuplicateEmailTemplate from "../../api/useDuplicateEmailTemplate";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { browserHistory } from "react-router";
import { getEmailTemplateRoute } from "../../EmailTemplate";
import { GET_EMAIL_TEMPLATES_QUERY_KEY } from "../../api/useGetEmailTemplates";
import { LoaderFull } from "common/Loader";
import useDeleteEmailTemplate from "../../api/useDeleteEmailTemplate";
import loadable from "loadable-components";
import { Header, Description, Footer } from "common/ConfirmationModal";
import { CATEGORY } from "modules/EmailTemplate/utils/constants";
import { useSelector } from "react-redux";
import useNotesStore from "modules/notes/notes.store";
import { generateId } from "config/helpers";
import { NEW_MAIL } from "modules/notes/constants";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
const ConfirmationModal = loadable(() => import("common/ConfirmationModal"));

const ACTIONS = {
	DUPLICATE: "duplicate",
	SEND_MAIL: "send-mail",
	DELETE: "delete"
};

export default function EmailTemplateDropdownActions({
	template,
	formData,
	lang
}) {
	const user = useSelector(state => state.auth.user);
	const { setActiveNote, addNote: addEmail } = useNotesStore();

	const onAction = action => {
		switch (action) {
			case ACTIONS.DUPLICATE:
				onDuplicate();
				break;
			case ACTIONS.SEND_MAIL:
				onAddNewEmail();
				break;
			case ACTIONS.DELETE:
				onDelete();
				break;
		}
	};

	const onAddNewEmail = () => {
		const id = generateId();
		setActiveNote(id);
		const templateToUseInEmail = formData.versions.find(
			v => v.version === lang
		);
		addEmail({
			id,
			type: NEW_MAIL,
			...EMPTY_EMAIL,
			template: {
				content: templateToUseInEmail.content,
				subject: templateToUseInEmail.subject,
				_id: template._id ?? id
			}
		});
	};

	const [
		dublicateTemplate,
		{ isLoading: isDuplicateLoading }
	] = useDuplicateEmailTemplate();
	const onDuplicate = () => {
		const newId = uuid();
		dublicateTemplate(
			{
				id: template._id,
				new_email_template_id: newId
			},
			{
				onSuccess() {
					toaster.success("Email template successfully duplicated.");
					queryCache.invalidateQueries(GET_EMAIL_TEMPLATES_QUERY_KEY);
					browserHistory.replace(getEmailTemplateRoute(newId));
				}
			}
		);
	};

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [
		deleteTemplate,
		{ isLoading: isDeleteLoading }
	] = useDeleteEmailTemplate();
	const onDelete = () => {
		setShowDeleteModal(true);
	};
	const onConfirmDelete = () => {
		deleteTemplate(
			{
				id: template._id
			},
			{
				onSuccess() {
					toaster.success("Email template successfully deleted.");
					queryCache.invalidateQueries(GET_EMAIL_TEMPLATES_QUERY_KEY);
					browserHistory.replace({
						pathname: getEmailTemplateRoute(""),
						state: { forceNavigation: true }
					});
				}
			}
		);
	};

	const isOwner = template?.created_by?._id === user.id;

	return (
		<>
			<MenuTrigger>
				<Button className={styles.optionsButton}>
					<DotsIcon />
				</Button>
				<Popover className={styles.popover}>
					<Menu onAction={onAction} className={styles.menu}>
						<MenuItem id={ACTIONS.DUPLICATE} className={styles.menuItem}>
							<DuplicateIcon width={16} height={16} />
							<span>Duplicate</span>
						</MenuItem>
						<MenuItem id={ACTIONS.SEND_MAIL} className={styles.menuItem}>
							<EmailIcon width={16} height={16} />
							<span>Send Mail</span>
						</MenuItem>
						{(template.category !== CATEGORY.EVENTS || isOwner) && (
							<MenuItem id={ACTIONS.DELETE} className={styles.menuItem}>
								<TrashIcon width={16} height={16} />
								<span>Delete</span>
							</MenuItem>
						)}
					</Menu>
				</Popover>
			</MenuTrigger>

			{!!showDeleteModal && (
				<ConfirmationModal
					onClose={() => setShowDeleteModal(null)}
					isLoading={isDeleteLoading}
				>
					<Header title="Delete email template" />
					<Description>
						<div>
							Are you sure you want to delete&nbsp;
							<span className={styles.templateName}>
								{template.template_name}
							</span>
						</div>
					</Description>
					<Footer
						onClickLabel="Delete"
						onClose={() => setShowDeleteModal(null)}
						onClick={onConfirmDelete}
					/>
				</ConfirmationModal>
			)}

			{isDuplicateLoading && <LoaderFull />}
		</>
	);
}
