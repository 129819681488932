import React, { useCallback } from "react";
import styles from "./email-template-sidebar.module.scss";
import { ReactComponent as EditIcon } from "static/icons/email-template/edit.svg";
import { ReactComponent as SearchIcon } from "static/icons/email-template/search.svg";
import EmailTemplateList from "../EmailTemplateList";
import debounce from "lodash/debounce";
import useTemplateSearchKeyword from "modules/EmailTemplate/hooks/useTemplateSearchKeyword";
import { browserHistory } from "react-router";
import { getEmailTemplateRoute } from "modules/EmailTemplate";
import { NEW_TEMPLATE_SEGMENT_ID } from "modules/EmailTemplate/utils/constants";
import { TOUR_EMAIL_TEMPLATES } from "config/onboarding";

export default function EmailTemplateSidebar() {
	const { setKeywords } = useTemplateSearchKeyword();
	const onInputChange = useCallback(debounce(setKeywords, 700), []);

	return (
		<div className={styles.sidebar}>
			<div className={styles.header}>
				<div className={styles.titleAndBtn}>
					<div className={styles.title}>Email templates</div>
					<button
						data-onboarding-step={`${TOUR_EMAIL_TEMPLATES}-2`}
						className={styles.newTemplateBtn}
						onClick={() =>
							browserHistory.push(
								getEmailTemplateRoute(NEW_TEMPLATE_SEGMENT_ID)
							)
						}
					>
						<EditIcon />
						<span>New</span>
					</button>
				</div>
				<div className={styles.search}>
					<div className={styles.icon}>
						<SearchIcon />
					</div>
					<input
						onChange={({ target }) => onInputChange(target.value)}
						type="text"
						placeholder="Search for Templates"
					/>
				</div>
			</div>
			<EmailTemplateList />
		</div>
	);
}
