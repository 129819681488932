import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { StickyContainer } from "react-sticky";
import _get from "lodash/get";
import _has from "lodash/has";
//components
import BidDetails from "../BidDetails";
import CompanyDashboard from "common/dashboard/Dashboard";
import Steps from "../../../components/shared/stepper";
import ListItem from "common/ListItem";
import GlobalTooltip from "common/GlobalTooltip";
import { getConsultancyBidsListRoute } from "../../consultancyBidList/ConsultancyBidListContainer";
import AddressComposer from "common/AddressComposer";
import ContractDetails from "../ContractDetails";
import AcceptInterviewModal from "common/AcceptInterviewModal";
import AcceptContract from "../../modals/AcceptContract";
import omit from "lodash/omit";

//config
import {
	MAX_SKILL_WEIGHT,
	MAX_LANGUAGE_WEIGHT,
	CANCELLED,
	LIVE,
	BID,
	ON_MISSION,
	INTERVIEW,
	CONTRACT,
	PROPOSE_ANOTHER_DATE,
	REFUSED,
	FREELANCER,
	PROPOSE_CONTRACT,
	PROPOSE_SUCCESS,
	REJECTED,
	ACCEPTED,
	SIGNED,
	DONE,
	EXPIRED,
	SENT,
	DECLINED,
	SUPER_ADMIN,
	CLIENT
} from "config";
//helper
import FreelancerInterview from "../../modals/FreelancerInterview";
import InterviewDetails from "../InterviewDetails";
import CancelBidModal from "../../modals/CancelBidModal";
import {
	ConfirmationModal,
	ConfirmationTypes,
	SuccessModal
} from "common/modal";
import BidSideBar from "../BidSideBar";
import ProposeContractModal from "../../modals/ProposeContractModal";
import { OutlinedLink } from "common/styled/buttons";
import { Helmet } from "react-helmet";
import JobTopbar from "modules/candidate/components/poke/JobTopbar";
import styles from "./consultancy-job-description.module.scss";
import Description from "common/Description";
import { isHTML } from "config/helpers";
import LexicalContentView from "common/LexicalContentView";

export const getIndexOfStepper = data => {
	const activeStepper = _get(data, "stepper_status");
	const contractSigned = _get(data, "contract.signed_status") === SIGNED;
	if (activeStepper === BID) {
		return 0;
	}
	if (activeStepper === INTERVIEW) {
		return 1;
	}
	if (activeStepper === CONTRACT) {
		return 2;
	}
	if (activeStepper === ON_MISSION && contractSigned) {
		return 4;
	}
	if (activeStepper === ON_MISSION) {
		return 3;
	}
	return -1;
};

export default class FreelancerBidView extends Component {
	state = {
		showBidModal: false,
		active: false,
		displayAcceptInterviewConfirmation: false,
		requiredFilesModal: false,
		filesPayload: []
	};

	cancelBid = () => {
		this.setState({ showBidModal: true });
	};

	closeCancelBidModal = () => {
		this.setState({ showBidModal: false });
	};

	getStatusTooltip = ({ status, rejectionReason, rejectionComment }) => {
		const lastActionType = _get(this.props, "data.last_user_bid_action.type");
		switch (status) {
			case REJECTED:
			case CANCELLED:
				switch (lastActionType) {
					case CLIENT:
						if (rejectionReason === "other") {
							return rejectionComment;
						}
						return rejectionReason;
					case FREELANCER:
						return "You have canceled your application.";
					case SUPER_ADMIN:
						return "wiggli's support team has canceled your application.";
					default:
						return "";
				}
			case LIVE:
				return "Expecting Client feedback.";
			default:
				return "";
		}
	};

	getInterviewStatusTooltip(status, islastUserSenderClient) {
		switch (status) {
			case SENT:
			case DECLINED:
				switch (islastUserSenderClient) {
					case true:
						return "Please reply to the client's interview proposition";
					case false:
						return "Expecting Client's feedback.";
					default:
						return "";
				}
			case REFUSED:
			case CANCELLED:
				switch (islastUserSenderClient) {
					case true:
						return "Your application has been rejected by the client.";
					case false:
						return "You have canceled your application.";
					default:
						return "";
				}
			case DONE:
				return "Expecting Client's feedback.";
			case ACCEPTED:
				return "The interview date, time and place are confirmed.";
			case EXPIRED:
				return "The interview proposition is overdue, please propose another date.";
			default:
				return "";
		}
	}

	getContractStatusTooltip(status, islastUserSenderClient) {
		switch (status) {
			case SENT:
			case DECLINED:
				switch (islastUserSenderClient) {
					case true:
						return "Please reply to the client's contract proposition.";
					case false:
						return "Expecting Client Feedback.";
					default:
						return "";
				}
			case CANCELLED:
			case REFUSED:
				switch (islastUserSenderClient) {
					case true:
						return "Your application has been rejected by the client.";
					case false:
						return "You have canceled your application.";
					default:
						return "";
				}
			case ACCEPTED:
				return (
					<span>
						{" "}
						Congratulations for coming to an agreement! <br />
						wiggli is currently generating the related documents.
					</span>
				);
			case SIGNED:
				return "All contracts are signed and this is now an actual mission.";
			default:
				return "";
		}
	}

	closeContractModal = () => {
		this.props.toggleContractModal(false);
	};

	renderModals = () => {
		const {
			interview_modal,
			interviewDispatch,
			data,
			params,
			toggleInterviewModal,
			contract_modal,
			ProposeContract
		} = this.props;

		switch (interview_modal.type) {
			case PROPOSE_ANOTHER_DATE:
				return (
					<FreelancerInterview
						interviewDispatch={interviewDispatch}
						toggleInterviewModal={toggleInterviewModal}
						bid_id={params.id}
						interview={_get(data, "interview", {})}
						profile={`${_get(data, "interview.user_sender.first_name")} ${_get(
							data,
							"interview.user_sender.last_name"
						)}`}
					/>
				);
			default:
				break;
		}
		if (contract_modal.show) {
			switch (contract_modal.type) {
				case PROPOSE_CONTRACT:
					return (
						<ProposeContractModal
							toggleContractModal={this.closeContractModal}
							bid_id={params.id}
							contract={data.contract}
							proposed_amount={data.proposed_amount}
							ProposeContract={ProposeContract}
							userType={FREELANCER}
							files={_get(data, "contract.required_files", [])}
							reportingSetting={data.reporting_settings}
						/>
					);
				case PROPOSE_SUCCESS:
					return (
						<SuccessModal
							className="contract-modal"
							onClose={this.closeContractModal}
							title="Other Terms Proposed"
							buttonCloseClassName={"props-close-btn"}
							description={
								<span>
									Your adapted proposition has successfully been sent!
									<br /> You’ll receive a feedback as soon as one is provided.
								</span>
							}
						/>
					);
				default:
					break;
			}
		}
		return null;
	};

	toggleContractConfirmation = files => {
		const data = files !== undefined ? files : [];
		this.setState({ active: !this.state.active });
		this.setState({ filesPayload: data });
	};

	toggleContractConfirmationRequiredFiles = () => {
		this.setState({ requiredFilesModal: !this.state.requiredFilesModal });
	};

	acceptContract = () => {
		this.props
			.acceptContract({
				contract_id: _get(this.props, "data.contract._id"),
				bid_id: _get(this.props, "params.id"),
				files: this.state.filesPayload
					.map(f => omit(f, "account_types", "is_required", "created_at"))
					.filter(f => f.path)
			})
			.then(() => this.toggleContractConfirmation())
			.catch(() => this.toggleContractConfirmation());
	};

	acceptInterviewModalAction = () => {
		const { acceptInterview, data, params } = this.props;

		acceptInterview(
			{
				interview_id: _get(data, "interview._id", {}),
				token_invitation_id: _get(data, "interview.token_invitation_id", {}),
				bid_id: params.id
			},
			() => this.closeAcceptInterviewModal()
		);
	};
	displayAcceptInterviewModal = () => {
		this.setState({
			displayAcceptInterviewConfirmation: true
		});
	};
	closeAcceptInterviewModal = () => {
		this.setState({
			displayAcceptInterviewConfirmation: false
		});
	};

	render() {
		const {
			route,
			data,
			user,
			toggleInterviewModal,
			params: { id },
			toggleContractModal,
			toggleHelpModal,
			getSteps,
			onCallEnd,
			onCallStart,
			showVideoConf
		} = this.props;

		const { showBidModal, active } = this.state;

		const hasContract = _get(data, "contract._id");

		const hasInterview = _has(data, "interview._id");

		const stepperStatus = _get(data, "stepper_status");

		const jobDescription = _get(data, "job.description", "");

		return (
			<BodyClassName className="bid gray-bg">
				<div className="view-job-details">
					{route.title && (
						<Helmet>
							<title>{route.title}</title>
						</Helmet>
					)}
					<CompanyDashboard />

					<div className="container">
						<Steps steps={getSteps(data)} />
					</div>

					{data && data.job && (
						<div className="container">
							<div className="top-bar">
								<JobTopbar
									isConsultancy={true}
									title={_get(data, "job.title", "")}
									category={_get(data, "job.category", "")}
									subcategory={_get(data, "job.subcategory", "")}
									_function={_get(data, "job.function", "")}
									seniority={_get(data, "job.seniority", "")}
									consutlantAvatar={_get(data, "user_avatar", "")}
									consutlantName={`${_get(data, "user_first_name", "")} ${_get(
										data,
										"user_last_name",
										""
									)}`}
								/>
							</div>

							<StickyContainer>
								<div className="row">
									<div className="col-md-9 content-inner">
										{hasContract && (
											<ContractDetails
												userType={FREELANCER}
												user={user}
												userTarget={data.profile_detail}
												contract={data.contract}
												stepperStatus={stepperStatus}
												visible_status={data.visible_status}
												getContractStatusTooltip={this.getContractStatusTooltip}
												reportingSetting={data.reporting_settings}
											/>
										)}
										{hasInterview && (
											<InterviewDetails
												data={data.interview}
												user={user}
												userTarget={data.profile_detail}
												stepperStatus={stepperStatus}
												visible_status={data.visible_status}
												getInterviewStatusTooltip={
													this.getInterviewStatusTooltip
												}
												showVideoConf={showVideoConf}
												onCallStart={onCallStart}
												onCallEnd={onCallEnd}
											/>
										)}
										<BidDetails
											status={data.status}
											created_at={data.created_at}
											proposed_amount={data.proposed_amount}
											available_date={data.available_date}
											description={data.description}
											visible_status={data.visible_status}
											get_status_tooltip={this.getStatusTooltip({
												status: data.status,
												rejectionReason: data.rejection_reason,
												rejectionComment: data.rejection_comments
											})}
											flag="YOUR BID"
											maxWidth={"250px"}
											type={user.company_type}
											stepperStatus={stepperStatus}
											bid_details={data}
										/>
										<div className="section">
											<div className="section-title">REQUEST DESCRIPTION</div>
											<div className={styles.jobDescription}>
												{isHTML(jobDescription) ? (
													<LexicalContentView description={jobDescription} />
												) : (
													<Description
														description={jobDescription}
														parseMarkdown={true}
														max={500}
													/>
												)}
											</div>
										</div>

										<ListItem
											title="Skills"
											list={_get(data, "job.skills", [])}
											scoreMax={MAX_SKILL_WEIGHT}
										/>

										<ListItem
											title="Languages"
											list={_get(data, "job.languages", [])}
											scoreMax={MAX_LANGUAGE_WEIGHT}
										/>

										<div className="section">
											<div className="section-title">LOCATION</div>
											<AddressComposer addressData={data.job} />
										</div>

										<div className="section job-info">
											<ul>
												<li>
													<i className="icon-requestreference" />
													<h5>
														Request reference{" "}
														<GlobalTooltip
															placement="top"
															eventTrigger="hover"
															overlay={
																<div>
																	When contacting our support team regarding a
																	request, please be sure to mention its request
																	reference. This will help our support team
																	assist you quickly and efficiently.
																</div>
															}
														/>
													</h5>
													<p>{_get(data, "job.job_ref", "")}</p>
												</li>
											</ul>
										</div>
									</div>
									<BidSideBar
										userType={FREELANCER}
										job={_get(data, "job", {})}
										cancelBid={this.cancelBid}
										bidStatus={data.status}
										stepperStatus={getIndexOfStepper(data)}
										interview={_get(data, "interview", {})}
										contract={_get(data, "contract", {})}
										toggleInterviewModal={toggleInterviewModal}
										companyId={user.active_company}
										acceptInterview={this.displayAcceptInterviewModal}
										toggleContractConfirmation={this.toggleContractConfirmation}
										bidId={id}
										toggleContractModal={toggleContractModal}
										toggleHelpModal={toggleHelpModal}
										toggleContractConfirmationRequiredFiles={
											this.toggleContractConfirmationRequiredFiles
										}
									/>
								</div>

								<OutlinedLink
									to={getConsultancyBidsListRoute()}
									small
									has-icon
									style={{ paddingLeft: 95 }}
								>
									<i className="icon-arrow-left" />
									Back
								</OutlinedLink>
							</StickyContainer>

							{this.renderModals()}

							<CancelBidModal
								active={showBidModal}
								bidId={id}
								interviewId={_get(data, "interview._id", "")}
								contractId={_get(data, "contract._id", "")}
								stepperStatus={_get(data, "stepper_status")}
								onClose={this.closeCancelBidModal}
							/>

							<ConfirmationModal
								onClose={this.toggleContractConfirmation}
								modalName="accept_contract"
								firstButton={{
									action: this.acceptContract,
									label: "YES, ACCEPT THIS PROPOSITION"
								}}
								title="Are you sure you want to accept this contract proposition?"
								content="Once accepted, the terms of the proposition will be used by
									wiggli to generate the contracts covering the mission."
								type={ConfirmationTypes.confirmation}
								active={active}
								loading={false}
							/>
							<AcceptInterviewModal
								acceptInterview={this.acceptInterviewModalAction}
								closeModal={this.closeAcceptInterviewModal}
								displayModal={this.state.displayAcceptInterviewConfirmation}
							/>
							{this.state.requiredFilesModal ? (
								<AcceptContract
									onClose={this.toggleContractConfirmationRequiredFiles}
									files={_get(data, "contract.required_files", [])}
									toggleContractConfirmation={this.toggleContractConfirmation}
									companyVat={user.company_vat}
								/>
							) : (
								<></>
							)}
						</div>
					)}
				</div>
			</BodyClassName>
		);
	}
}
