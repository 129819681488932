import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { VALIDATE_CONTRACT_POSTING_REQUIREMENTS } from "config/api-endpoints";

const validatePostingRequirements = data => {
	return client(VALIDATE_CONTRACT_POSTING_REQUIREMENTS, {
		body: data
	});
};

const useValidatePostingRequirements = (queryOptions = {}) =>
	useMutation(validatePostingRequirements, queryOptions);

export default useValidatePostingRequirements;
