import React, { Component } from "react";
import {
	ASC,
	DESC,
	START_DATE,
	STATUS,
	APPROVED,
	MISSION_TITLE,
	FREELANCER_NAME,
	SUBMISSION_DATE,
	APPROVAL_DATE,
	COMPANY_NAME,
	TOTAL_AMOUNT_CLIENT,
	TOTAL_AMOUNT_FREELANCER
} from "config";

class SortTimesheetList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortByVisible: false
		};
	}

	showSortMenu = sortByVisible => {
		this.setState({ sortByVisible });
	};

	handleField = e => {
		this.showSortMenu(false);
		this.props.handleField(e);
	};

	handleDirection = e => {
		this.showSortMenu(false);
		this.props.handleDirection(e);
	};

	render() {
		const { sortByVisible } = this.state;
		const {
			data: { field, sort },
			isClient,
			view
		} = this.props;
		const TOTAL_AMOUNT = isClient
			? TOTAL_AMOUNT_CLIENT
			: TOTAL_AMOUNT_FREELANCER;
		return (
			<div className="sort-box">
				<div className="sort-actions">
					<div
						onClick={() => {
							this.showSortMenu(!sortByVisible);
						}}
					>
						Sort by <i className="fa fa-angle-down" />
					</div>

					{sortByVisible && (
						<div className="sorted-menu">
							<div className="group">
								<label>Sort By fields</label>
								{(isClient || view === APPROVED) && (
									<div className="checkbox checkbox-primary">
										<input
											onChange={this.handleField}
											checked={
												view === APPROVED
													? field === APPROVAL_DATE
													: field === SUBMISSION_DATE
											}
											type="checkbox"
											name={view === APPROVED ? APPROVAL_DATE : SUBMISSION_DATE}
											className="styled"
											id={view === APPROVED ? APPROVAL_DATE : SUBMISSION_DATE}
											value={
												view === APPROVED ? APPROVAL_DATE : SUBMISSION_DATE
											}
										/>
										<label
											htmlFor={
												view === APPROVED ? APPROVAL_DATE : SUBMISSION_DATE
											}
											className="io"
										>
											{view === APPROVED ? "Approval" : "Submission"} date
										</label>
									</div>
								)}
								{view === APPROVED && (
									<div className="checkbox checkbox-primary">
										<input
											onChange={this.handleField}
											checked={field === TOTAL_AMOUNT}
											type="checkbox"
											name={TOTAL_AMOUNT}
											className="styled"
											id={TOTAL_AMOUNT}
											value={TOTAL_AMOUNT}
										/>
										<label htmlFor={TOTAL_AMOUNT} className="io">
											Total amount
										</label>
									</div>
								)}
								<div className="checkbox checkbox-primary">
									<input
										onChange={this.handleField}
										checked={
											!isClient
												? field === COMPANY_NAME
												: field === FREELANCER_NAME
										}
										type="checkbox"
										name={!isClient ? COMPANY_NAME : FREELANCER_NAME}
										className="styled"
										id={!isClient ? COMPANY_NAME : FREELANCER_NAME}
										value={!isClient ? COMPANY_NAME : FREELANCER_NAME}
									/>
									<label
										htmlFor={!isClient ? COMPANY_NAME : FREELANCER_NAME}
										className="io"
									>
										{!isClient ? "Company" : "Consultant"} name
									</label>
								</div>
								<div className="checkbox checkbox-primary">
									<input
										onChange={this.handleField}
										checked={field === MISSION_TITLE}
										type="checkbox"
										name={MISSION_TITLE}
										className="styled"
										id={MISSION_TITLE}
										value={MISSION_TITLE}
									/>
									<label htmlFor={MISSION_TITLE} className="io">
										Mission title
									</label>
								</div>
								<div className="checkbox checkbox-primary">
									<input
										onChange={this.handleField}
										checked={field === START_DATE}
										type="checkbox"
										name={START_DATE}
										className="styled"
										id={START_DATE}
										value={START_DATE}
									/>
									<label htmlFor={START_DATE} className="io">
										Month
									</label>
								</div>
								<div className="checkbox checkbox-primary">
									<input
										onChange={this.handleField}
										checked={field === STATUS}
										type="checkbox"
										name={STATUS}
										className="styled"
										id={STATUS}
										value={STATUS}
									/>
									<label htmlFor={STATUS} className="io">
										Status
									</label>
								</div>
							</div>
							<div className="group">
								<label>Direction</label>
								<div className="checkbox checkbox-primary">
									<input
										onChange={this.handleDirection}
										checked={sort === DESC}
										type="checkbox"
										name={DESC}
										className="styled"
										id={DESC}
										value={DESC}
									/>
									<label htmlFor={DESC} className="io">
										Desc
									</label>
								</div>
								<div className="checkbox checkbox-primary">
									<input
										onChange={this.handleDirection}
										checked={sort === ASC}
										type="checkbox"
										name={ASC}
										className="styled"
										id={ASC}
										value={ASC}
									/>
									<label htmlFor={ASC} className="io">
										Asc
									</label>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default SortTimesheetList;
