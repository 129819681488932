/* eslint-disable */
/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { historyPush } from "config/helpers";
import { getNewVacancyProfileRoute } from "modules/vacancy/create-vacancy";
import { stringify } from "query-string";
import { browserHistory } from "react-router";
import Modal from "common/modal";

import styles from "./create-user.module.scss";
import coverInviteSuccessModal from "static/images/inviteSuccess.jpg";

import { useTour } from "@reactour/tour";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { TOUR_USER_AND_DEPARTEMENT } from "config/onboarding";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";

const useTourData = () => {
	const { workflowId, cleanStore, setIsLoading } = useOnboardingStore();
	const { setIsOpen, setSteps } = useTour();
	const isActiveSetupCompanyTour = useMemo(
		() => workflowId === TOUR_USER_AND_DEPARTEMENT,
		[workflowId]
	);

	// last step close tour
	const onLastStepClose = () => {
		// todo add start recrutment modal
		setIsOpen(false); // close tour
		setSteps([]); // clean steps
		cleanStore(); // clean oboarding store
		browserHistory.push("/settings/setup-guide");
	};
	// persist onboarding data
	const { persistData } = useOnboardingSaveData({
		onTourClose: onLastStepClose
	});

	const goLastTourStep = () => {
		if (isActiveSetupCompanyTour) {
			setIsLoading(true);
			persistData();
		}
	};

	return {
		goLastTourStep
	};
};

export default ({
	onClose,
	onBack: onBackProp,
	isEdit,
	vacancyId,
	createdDepartment
}) => {
	const { goLastTourStep } = useTourData();
	const onBack = () => {
		onBackProp();
		setTimeout(() => {
			const params = stringify({
				departmentName: createdDepartment.name,
				departmentId: createdDepartment._id
			});

			if (vacancyId === "creation") {
				historyPush(`/vacancy/create/profile?${params}`);
			} else {
				historyPush(`${getNewVacancyProfileRoute(vacancyId)}?${params}`);
			}
		}, 500);
	};

	return (
		<Modal
			size={"530px"}
			padding={"0px"}
			borderRadius={"12px"}
			buttonCloseClassName={"hide"}
			style={{
				background: "#FFF",
				boxShadow:
					"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
				overflow: "hidden"
			}}
		>
			<div className={styles.imageCover}>
				<img alt="Welcome to Wiggli" src={coverInviteSuccessModal} />
			</div>

			<div className={styles.inviteSuccesContainer}>
				<h3>
					{" "}
					The department has been successfully {isEdit ? "updated" : "added"}
				</h3>
				<p>
					If applicable, an email has been sent to the users whose role has been
					impacted by this action.
				</p>

				{vacancyId ? (
					<>
						<button onClick={onBack}>Go back to your vacancy</button>
						<button onClick={onClose}>Close window</button>
					</>
				) : (
					<button
						onClick={() => {
							onClose();
							goLastTourStep();
						}}
					>
						Close window
					</button>
				)}
			</div>
		</Modal>
	);
};
