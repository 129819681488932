import React, { useRef } from "react";
import cx from "classnames";
import Avatar from "common/Avatar";
import Tooltip from "common/Tippy";
import Checkbox from "common/Checkbox";
import s from "./supplier-card.module.scss";
import { historyPush } from "config/helpers";

const invitationStatusMap = {
	pending: "Invitation pending.",
	accepted: "Invitation accepted."
};

function SupplierCard({
	invitationStatus,
	data = {},
	toggleCard,
	selectionBucket,
	index,
	offset
}) {
	const addressRef = useRef();
	const locationsRef = useRef();
	// const industryRef = useRef();
	const firstLocation = data?.other_locations
		.filter(item => item.type !== "offices")
		.slice(0, 1)
		.map(l => `${l.city}, ${l.country}`)
		.join(", ");
	const firstOffice = data?.other_locations
		.filter(item => item.type === "offices")
		.slice(0, 1)
		.map(l => `${l.city}, ${l.country}`)
		.join(", ");
	const hq = [data?.main_location?.city, data?.main_location.country].filter(
		Boolean
	);
	const checked = selectionBucket.helpers.checkRowSelection(
		data?.id,
		index + offset,
		null
	);
	const handleCardClick = () => historyPush(`/supplier/${data?.id}`);
	return (
		<div
			className={cx(s.root, {
				[s.rootOverlay]: checked
			})}
			onClick={handleCardClick}
		>
			<Checkbox
				id={`item ${index} ${data?.id}`}
				checked={checked}
				onChange={e => toggleCard(e, data?.id)}
			/>
			{invitationStatus ? (
				<Tooltip
					content={invitationStatusMap[invitationStatus]}
					placement={"right"}
				>
					<div className={cx(s.invitationStatus, s[invitationStatus])} />
				</Tooltip>
			) : null}
			<div className={s.heading}>
				<Avatar
					avatar={data.companyavatar}
					name={data.name}
					className={s.avatar}
				/>
				<div>
					<h5 className={s.name}>{data.name}</h5>
					<Tooltip overflow={"hidden"} theme={"dark"} content={hq.join(", ")}>
						<div className={s.address} ref={addressRef}>
							<span>{hq.length > 0 ? hq.join(", ") : "-"}</span>
						</div>
					</Tooltip>
				</div>
			</div>
			<div className={s.content}>
				<div className={s.grayBox}>
					<div>
						<p>Languages spoken</p>
						<span>
							{data.languages?.length > 0
								? data.languages.map(l => l.name).join(", ")
								: "-"}
						</span>
					</div>
					<div>
						<p># of employees</p>
						<span>
							{data?.size
								? `${data.size?.from ?? ""} - ${data.size?.to ?? ""}`
								: "-"}
						</span>
					</div>
				</div>
				<div className={s.grayBox}>
					<div className={s.locations}>
						<p>
							Offices{" "}
							{data?.other_locations.filter(item => item.type === "offices")
								.length > 0 ? (
								<span>
									(
									{
										data?.other_locations.filter(
											item => item.type === "offices"
										).length
									}
									)
								</span>
							) : null}
						</p>
						<Tooltip overflow={"hidden"} theme={"dark"} content={firstOffice}>
							<div ref={locationsRef}>
								{data?.other_locations.filter(item => item.type === "offices")
									.length > 0
									? firstOffice
									: "-"}
							</div>
						</Tooltip>
					</div>
					<div className={s.locations}>
						<p>
							Geographic coverage{" "}
							{data?.other_locations.length > 0 ? (
								<span>
									(
									{
										data?.other_locations.filter(
											item => item.type !== "offices"
										).length
									}
									)
								</span>
							) : null}
						</p>
						<Tooltip overflow={"hidden"} theme={"dark"} content={firstLocation}>
							<div ref={locationsRef}>
								{data?.other_locations.filter(item => item.type !== "offices")
									.length > 0
									? firstLocation
									: "-"}
							</div>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SupplierCard;
