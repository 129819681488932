import React from "react";
import cx from "classnames";
import { INACTIVE } from "config";
// import GlobalTooltip from "common/GlobalTooltip";
import { browserHistory } from "react-router";
import styles from "../JobDetails/permanent-job-details.module.scss";

export default function JobActions({ applyJobProps, className, onClickRefer }) {
	return (
		<>
			<ApplyJobButton {...applyJobProps} className={className} />
			<button
				onClick={onClickRefer}
				className={cx(`${styles.btn} ${styles.btnSuccess}`, className)}
			>
				Refer a friend
			</button>
		</>
	);
}

const ApplyJobButton = ({
	pokeID,
	isLoading,
	className,
	canApplyToJob,
	candidateStatus,
	displayApplyModal
}) => {
	const redirectToJob = () => {
		const link = `/pokes/${pokeID}?search`;
		browserHistory.push(link);
	};

	if (canApplyToJob || isLoading) {
		return (
			<button
				disabled={isLoading}
				onClick={() => !isLoading && displayApplyModal(true)}
				className={cx(`${styles.btn} ${styles.btnGreen}`, className)}
			>
				Apply for this job
			</button>
		);
	}

	if (!canApplyToJob && pokeID !== null) {
		return (
			<button
				onClick={redirectToJob}
				className={cx(`${styles.btn} ${styles.btnGreen}`, className)}
			>
				View application
			</button>
		);
	}

	if (candidateStatus === INACTIVE) {
		return (
			<button
				disabled
				className={cx(`${styles.btn} ${styles.btnGreen}`, className)}
			>
				Apply for this job
			</button>
		);
	}
	return <></>;
};
