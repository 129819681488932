import styled from "styled-components";

export const SelectContainer = styled.div`
	.select-label {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #a8aaac;
		background: none;
		border: none;
		margin: 0;
		padding: 10px 0;
	}
	.Select-control {
		border-color: ${({ error }) => error && "#ff6849"};
	}
	.Select-option {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.error {
		display: flex;
		align-items: center;
		margin: 2% 0;
		.text {
			font-family: "BasierCircle";
			font-size: 14px;
			line-height: 1.14;
			color: #ff6849;
			margin-left: 5px;
		}
	}
`;
