import React, { useState, useRef, useLayoutEffect } from "react";
import styles from "./clamp-content.module.scss";

const ClampContent = ({ html }) => {
	const contentWrapper = useRef();
	const [isBigContent, setIsBigContent] = useState(false);
	const [showAll, setShowAll] = useState(false);

	useLayoutEffect(() => {
		if (contentWrapper.current) {
			setIsBigContent(
				contentWrapper.current.offsetHeight <
					contentWrapper.current.scrollHeight
			);
		}
	}, [html]);

	return showAll ? (
		<>
			<div className={styles.unclampedContent}>
				<div dangerouslySetInnerHTML={html} />
			</div>
			<button
				className={styles.seeLessButton}
				onClick={() => setShowAll(false)}
			>
				See less
			</button>
		</>
	) : (
		<div>
			<div className={styles.clampedContent}>
				<div dangerouslySetInnerHTML={html} ref={contentWrapper} />
			</div>
			{isBigContent && (
				<button
					className={styles.seeMoreButton}
					onClick={() => setShowAll(true)}
				>
					View more
				</button>
			)}
		</div>
	);
};

export default ClampContent;
