import React, { useState } from "react";
import loadable from "loadable-components";
import styles from "./evaluations-settings-page-header.module.scss";
import { ReactComponent as PencilIcon } from "static/icons/pen.svg";

const CreateEvaluationFormDrawer = loadable(() =>
	import(
		/* webpackChunkName: "create-evaluation-drawer" */ "../CreateEvaluationFormDrawer"
	)
);

export default function EvaluationsSettingsPageHeader() {
	const [isOpen, setIsOpen] = useState(false);

	const onClickNewTemplate = () => {
		setIsOpen(true);
	};

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Evaluation forms</h1>
			<button onClick={onClickNewTemplate} className={styles.createBtn}>
				<PencilIcon />
				<span>New Template</span>
			</button>
			<CreateEvaluationFormDrawer
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</div>
	);
}
