import React from "react";
import styles from "./mission-supplier-mode-card-body.module.scss";
import { ReactComponent as BuildingIcon } from "static/icons/building-2.svg";
import { ReactComponent as UserIcon } from "static/icons/user-03.svg";
import { ReactComponent as SellingPriceIcon } from "static/icons/bank-note-icon.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle.svg";
import { CustomInput } from "common/CustomInput";
import cx from "classnames";
import { FieldError } from "common/FieldError";
import get from "lodash/get";
import Tooltip from "common/Tippy";
import { Controller } from "react-hook-form";
import NewCurrencyField from "common/NewCurrencyField";

const MissionSupplierModeCardBody = ({
	register,
	errors,
	fieldsClassName,
	symbol,
	control,
	rateType
}) => {
	return (
		<div className={cx(styles.fields, fieldsClassName)}>
			<div className={styles.field}>
				<label className={styles.label}>
					<BuildingIcon className={styles.icon} />
					Company Name
				</label>
				<CustomInput
					{...register("company_name")}
					className={styles.input}
					placeholder="Enter company name"
				/>
				{errors?.company_name && (
					<FieldError
						className={styles.marginBottom}
						error={get(errors, "company_name")}
					/>
				)}
			</div>
			<div className={styles.field}>
				<label className={styles.label}>
					<SellingPriceIcon className={styles.icon} />
					{rateType} Selling Price
				</label>
				<div className={styles.sellingPriceContainer}>
					<div className={styles.inputContainer}>
						<Controller
							name="selling_price"
							control={control}
							render={({ field: { value = "", onChange } }) => (
								<NewCurrencyField
									input={{
										value,
										onChange
									}}
									className={styles.currencyField}
									rootClassName={styles.currency}
									suffix={symbol}
									currencyLabelClassName={styles.currencyLabelClassName}
									useGreenTheme
								/>
							)}
						/>
					</div>
					<Tooltip
						theme="dark"
						className={styles.tooltip}
						content={
							"This is the agreed-upon rate between your company and the client company for the services provided by the freelancer. The selling price will be displayed in the timesheet sent to the client for approval, ensuring they see the agreed rate instead of the freelancer's original rate."
						}
					>
						<InfoIcon className={styles.infoCircle} />
					</Tooltip>
				</div>
				{errors?.selling_price && (
					<FieldError
						className={styles.marginBottom}
						error={get(errors, "selling_price")}
					/>
				)}
			</div>
			<div className={styles.field}>
				<label className={styles.label}>
					<UserIcon className={styles.icon} />
					Timesheet Manager Email
				</label>
				<CustomInput
					{...register("company_timesheet_manager_email")}
					className={styles.input}
					placeholder="Enter timesheet manager email"
				/>
				{errors?.company_timesheet_manager_email && (
					<FieldError
						className={styles.marginBottom}
						error={get(errors, "company_timesheet_manager_email")}
					/>
				)}
			</div>
			<div className={styles.field}>
				<label className={styles.label}>
					<UserIcon className={styles.icon} />
					Accounting Manager Email
				</label>
				<CustomInput
					{...register("accounting_manager_email")}
					className={styles.input}
					placeholder="Enter accounting manager email"
				/>
				{errors?.accounting_manager_email && (
					<FieldError
						className={styles.marginBottom}
						error={get(errors, "accounting_manager_email")}
					/>
				)}
			</div>
		</div>
	);
};

export default MissionSupplierModeCardBody;
