import React from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";

const TimesheetActionFiles = ({ children, generateFile }) => {
	const listMenu = () => {
		return (
			<Menu selectable={false}>
				<MenuItem
					onClick={data => {
						data.domEvent.stopPropagation();
						generateFile("pdf");
					}}
					className={`ignore-click`}
				>
					<span className="ignore-click">
						<span className="icon-pdf">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
							<span className="path4"></span>
							<span className="path5"></span>
							<span className="path6"></span>
							<span className="path7"></span>
							<span className="path8"></span>
						</span>
						Generate PDF
					</span>
				</MenuItem>
				<MenuItem
					onClick={data => {
						data.domEvent.stopPropagation();
						generateFile("xlsx");
					}}
					className={`ignore-click`}
				>
					<span className="ignore-click">
						<span className="icon-xls">
							<span className="path1"></span>
							<span className="path2"></span>
							<span className="path3"></span>
							<span className="path4"></span>
							<span className="path5"></span>
							<span className="path6"></span>
							<span className="path7"></span>
						</span>
						Generate XLS
					</span>
				</MenuItem>
			</Menu>
		);
	};

	return (
		<RcDropDown
			trigger={["click"]}
			overlay={listMenu()}
			overlayClassName="ignore-click"
			align={{
				points: ["tc", "bc"],
				offset: [0, -14]
			}}
		>
			{children}
		</RcDropDown>
	);
};

export default TimesheetActionFiles;
