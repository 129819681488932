import React, { memo } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _orderBy from "lodash/orderBy";
import {
	DroppableContainer,
	StyledDroppable,
	StyledDraggable,
	DroppableHeader
} from "./Styled";
import Box from "common/styled/Box";
import profileIcon from "static/images/profile-icon.png";
import { LOADING } from "config";
import Loader from "common/Loader";
import HeaderKanban from "./HeaderKanban";
import Tooltip from "../../../../../common/Tippy";

const styleByStatus = {
	sent: {
		backgroundColor: "#FFEA8A",
		color: "#B98900"
	},
	draft: {
		backgroundColor: "#FFEA8A",
		color: "#B98900"
	},
	accepted: {
		backgroundColor: "#00A47C"
	},
	refused: {
		backgroundColor: "#FD5749"
	},
	expired: {
		backgroundColor: "#FD5749"
	}
};

const statusLabel = {
	draft: {
		label: "Pending",
		tooltip: "This supplier has not accepted your invitation yet."
	},
	sent: {
		label: "Pending",
		tooltip: "This supplier has not accepted your invitation yet."
	},
	accepted: {
		label: "Accepted",
		tooltip: "This supplier has accepted your invitation."
	},
	refused: {
		label: "Refused",
		tooltip: "This supplier has refused your invitation."
	}
};

export const InvitationStatus = styled.div(({ status }) => ({
	padding: "1px 15px",
	borderRadius: 10,
	textTransform: "capitalize",
	color: "white",
	width: "fit-content",
	...styleByStatus[status]
}));

function Kanban({
	tiers,
	status,
	featureID,
	categoryId,
	isFetching,
	payload,
	setPayload,
	data
}) {
	const handleDragEnd = (result = {}) => {
		const { source, destination } = result;

		if (!destination) {
			return;
		}

		if (
			source.droppableId === destination.droppableId &&
			source.index === destination.index
		) {
			return;
		}

		let body = payload;

		if (source.droppableId !== destination.droppableId) {
			let start = _find(tiers, { _id: source.droppableId });
			if (start.name === "marketplace") {
				start = start.consultancies.data;
			} else {
				start = start.consultancies;
			}

			let finish = _find(tiers, { _id: destination.droppableId });

			const isMarketplace = finish.name;
			if (finish.name === "marketplace") {
				finish = finish.consultancies.data;
			} else {
				finish = finish.consultancies;
			}

			const startIndex = _findIndex(tiers, { _id: source.droppableId });
			const finishIndex = _findIndex(tiers, { _id: destination.droppableId });

			const [removed] = start?.splice(source.index, 1);
			finish?.splice(destination.index, 0, removed);

			const cardIndex = _findIndex(payload, { _id: removed._id });

			if (cardIndex !== -1) {
				const card = body[cardIndex];
				if (isMarketplace || tiers[finishIndex]._id === card.tier_source_id) {
					body = body.filter(item => item._id !== body[cardIndex]._id);
				} else {
					body[cardIndex].tier_destination_id = tiers[finishIndex]._id;
				}
			} else {
				body = [
					...body,
					{
						tier_destination_id: tiers[finishIndex]._id,
						tier_source_id: tiers[startIndex]?._id,
						_id: removed._id
					}
				];
			}
			setPayload(body);
		}
	};

	const renderHeader = tier => {
		const tierName = tier.name;
		switch (tierName) {
			case "tier 1":
				return (
					<HeaderKanban
						tierId={tier?._id}
						categoryId={categoryId}
						featureId={featureID}
						title={"Tier 1"}
						featureData={data}
						link={tier.tier_link}
					/>
				);
			case "tier 2":
				return (
					<HeaderKanban
						tierId={tier?._id}
						categoryId={categoryId}
						featureId={featureID}
						title={"Tier 2"}
						featureData={data}
						link={tier.tier_link}
					/>
				);
			case "tier 3":
				return (
					<HeaderKanban
						tierId={tier?._id}
						categoryId={categoryId}
						featureId={featureID}
						title={"Tier 3"}
						featureData={data}
						link={tier.tier_link}
					/>
				);
			case "marketplace":
				return (
					<HeaderKanban
						name={"blocked"}
						link={tier.tier_link}
						title={"Marketplace"}
					/>
				);
		}
	};

	return (
		<>
			{(status === LOADING || isFetching) && <Loader check={false} />}
			<DragDropContext onDragEnd={handleDragEnd}>
				<Flex sx={{ gap: "1.5rem" }}>
					{_orderBy(tiers, "name")?.map(tier => {
						const consultancies =
							tier.name === "marketplace"
								? tier.consultancies?.data
								: tier.consultancies;
						const isMarketplace = tier.name === "marketplace";
						return (
							<DroppableContainer key={tier._id}>
								<DroppableHeader isMarketplace={isMarketplace}>
									{renderHeader(tier)}
								</DroppableHeader>
								<Droppable
									type="column"
									direction="vertical"
									ignoreContainerClipping
									droppableId={tier._id}
								>
									{(provided, snapshot) => (
										<StyledDroppable
											isDraggingOver={snapshot.isDraggingOver}
											draggingFromThisWith={snapshot.draggingFromThisWith}
											ref={provided.innerRef}
											isMarketplace={isMarketplace}
										>
											{consultancies?.map((item, index) => (
												<Draggable
													key={item._id}
													draggableId={item._id}
													index={index}
												>
													{(provided, snapshot) => (
														<StyledDraggable
															ref={provided.innerRef}
															isDragging={snapshot.isDragging}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
														>
															<Flex
																sx={{
																	alignSelf: "flex-end"
																}}
															>
																<InvitationStatus status={item.status}>
																	<Tooltip
																		content={statusLabel[item.status]?.tooltip}
																		overflow={"visible"}
																	>
																		<span>
																			{statusLabel[item.status]?.label ??
																				item.status}
																		</span>
																	</Tooltip>
																</InvitationStatus>
															</Flex>
															<Flex
																justifyContent="space-between"
																alignItems="center"
															>
																<img
																	src={item.avatar || profileIcon}
																	width={32}
																	height={32}
																	alt={""}
																/>
																<Box flexBox flexDirection="column">
																	<label
																		style={{
																			fontSize: "14px",
																			overflow: "hidden",
																			textOverflow: "ellipsis"
																		}}
																	>
																		{item.name}
																	</label>
																	{item.vat && (
																		<div
																			style={{
																				fontSize: "12px",
																				color: "#a0a5b9",
																				fontWeight: 400
																			}}
																		>
																			<span>VAT</span> {item.vat}
																		</div>
																	)}
																</Box>
															</Flex>
														</StyledDraggable>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</StyledDroppable>
									)}
								</Droppable>
							</DroppableContainer>
						);
					})}
				</Flex>
			</DragDropContext>
		</>
	);
}

Kanban.propTypes = {
	tiers: PropTypes.array,
	featureID: PropTypes.string,
	companyID: PropTypes.string,
	refetch: PropTypes.func
};

export default memo(Kanban);
