import React from "react";
import styles from "./permanent-view-evaluation-summary-empty-state.module.scss";
import { EvaluationsStatisticsSegments } from "common/EvaluationsStatisticsSegments";
import { ThumbsLabel } from "common/ThumbsLabel";
import { ReactComponent as Team } from "static/icons/team.svg";

const PermanentViewEvaluationSummaryEmptyState = () => {
	const statistics = [
		{
			label: "No",
			percent: "00",
			width: "33%",
			icon: <ThumbsLabel className={styles.thumbs} type="red" />,
			backgroundColor: "#94A3B8"
		},
		{
			label: "Acceptable",
			percent: "00",
			width: "33%",
			icon: <ThumbsLabel className={styles.thumbs} type="orange" />,
			backgroundColor: "#CBD5E1"
		},
		{
			label: "Excellent",
			percent: "00",
			width: "33%",
			icon: <ThumbsLabel className={styles.thumbs} type="green" />,
			backgroundColor: "#F1F5F9"
		}
	];

	return (
		<div>
			<div className={styles.title}>Candidate Scores</div>
			<hr />
			<div>
				<EvaluationsStatisticsSegments
					total={1}
					stats={statistics}
					suffix="%"
				/>
			</div>
			<hr />
			<div className={styles.descriptionContainer}>
				<Team className={styles.icon} />
				<div className={styles.noCandidates}>No evaluations.</div>
				<div className={styles.description}>
					Start evaluating now to track and compare candidate performance
					effortlessly.
				</div>
			</div>
		</div>
	);
};

export default PermanentViewEvaluationSummaryEmptyState;
