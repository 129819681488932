import React from "react";
import { Controller } from "react-hook-form";
import SelectField from "modules/vonqContract/components/SelectField";

const fieldRules = {
	required: "This field is required."
};

const ContractsGroupSelect = ({
	control,
	clearErrors,
	error,
	contractsGroupsList
}) => {
	return (
		<Controller
			name="group"
			control={control}
			rules={fieldRules}
			render={({ field: { onChange, value } }) => (
				<SelectField
					value={value}
					onChange={value => {
						if (error?.type === "manual") {
							clearErrors("group");
						}
						onChange(value);
					}}
					label={
						<>
							Group <span style={{ color: "red" }}>*</span>
						</>
					}
					options={contractsGroupsList}
					error={error?.message}
					labelKey="name"
				/>
			)}
		/>
	);
};

export default ContractsGroupSelect;
