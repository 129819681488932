import React from "react";
import cx from "classnames";
import classnames from "./status.module.scss";
import { ReactComponent as DeleteOptionIcon } from "static/icons/delete-option-icon.svg";

const StatusLabel = ({
	isDelete = false,
	deleteHandler,
	status,
	isDefault = false
}) => {
	return (
		<div
			className={cx(classnames.statusContainer, {
				[classnames.upcoming]: status === "upcoming",
				[classnames.completed]: status === "completed",
				[classnames.overdue]: status === "overdue",
				[classnames.isDefault]: isDefault,
				[classnames.justifyContent]: isDelete
			})}
		>
			<span className={classnames.statusLabel}>{status}</span>
			{isDelete && (
				<button onClick={deleteHandler}>
					<DeleteOptionIcon className={classnames.icon} />
				</button>
			)}
		</div>
	);
};

export default StatusLabel;
