import CreateVacancyModule, {
	getNewCreateVacancyRoute,
	getNewVacancyProfileRoute,
	getNewVacancyRequestRoute,
	getNewCreateVacancyPermission
} from "./CreateVacancyModule";

export {
	CreateVacancyModule,
	getNewCreateVacancyRoute,
	getNewVacancyProfileRoute,
	getNewVacancyRequestRoute,
	getNewCreateVacancyPermission
};
