import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_BIDS_ENDPOINT } from "config/api-endpoints";
import { bidsListKey } from "modules/home/api/queriesKeys.js";

const WELCOME_PAGE_BIDS = "welcome_page_bids";

const listBids = (key, { start_date, end_date }) =>
	client(LIST_BIDS_ENDPOINT, {
		body: {
			start_date,
			end_date,
			tag: WELCOME_PAGE_BIDS
		}
	});

const useBidsList = (options, queryOptions = {}) =>
	useQuery([bidsListKey, options], listBids, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useBidsList;
