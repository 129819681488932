import { useMutation } from "react-query";
import { queryCache } from "react-query";
import { client } from "lib/api-client";
import { ADD_NEW_CONTRACTS_GROUP } from "config/api-endpoints";
import { GET_CONTRACTS_GROUPS_QUERY_KEY } from "./useGetContractsGroupList";

function addGroup(body) {
	return client(ADD_NEW_CONTRACTS_GROUP, {
		body
	});
}

function useAddContractsGroup(queryOptions) {
	return useMutation(addGroup, {
		...queryOptions,
		refetchOnWindowFocus: false,
		retry: 0,
		onSuccess: () => {
			queryCache.invalidateQueries(GET_CONTRACTS_GROUPS_QUERY_KEY);
			queryOptions.onSuccess();
		}
	});
}

export default useAddContractsGroup;
