import create from "zustand";

const initialValues = {
	start_date: undefined,
	end_date: undefined,
	isSettedDate: false
};
const useRatioStore = create(set => ({
	...initialValues,
	setItem: (key, value) => set({ [key]: value }),
	resetRatio: () => set({ ...initialValues })
}));

export default useRatioStore;
