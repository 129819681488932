import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";

function getClosingReasonsSettings() {
	return client(NEW_FILTER_SETTINGS, {
		body: {
			list: "custom_fields_manager",
			tag: "vacancy_closing"
		}
	});
}

export const GET_CUSTOM_FIELD_OPTIONS_KEY = "GET_VACANCY_CLOSING_KEY";

const useGetClosingReasons = queryOptions => {
	return useQuery(GET_CUSTOM_FIELD_OPTIONS_KEY, getClosingReasonsSettings, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
};

export default useGetClosingReasons;
