import React from "react";
import styles from "./bid-details-processes-card.module.scss";
import { ReactComponent as XFileIcon } from "static/icons/file-x.svg";

const BidDetailsRejectedMessage = ({ title, text }) => {
	return (
		<div className={styles.reject}>
			<div className={styles.reject_item}>
				<XFileIcon />
				<span className={styles.primary}>{title}</span>
			</div>
			<div className={styles.reject_item}>
				<span className={styles.seconder}>{text}</span>
			</div>
		</div>
	);
};

export default BidDetailsRejectedMessage;
