import { useMutation, useQuery } from "react-query";
import _get from "lodash/get";
import { REQUEST, VACANCY } from "config";
import { browserHistory } from "react-router";
import { client } from "lib/api-client";
import {
	GROUP_LIST,
	LIST_SECTORS,
	LIST_FUNCTIONS,
	CREATE_NEW_JOB,
	EDIT_JOB,
	GET_CURRENCIES,
	GET_LAST_JOB,
	CREATE_NEW_JOB_PRICING,
	NEW_FILTER_SETTINGS
} from "config/api-endpoints";
import toaster from "common/Toaster";
import useGetlistExtraBenefits from "modules/candidate/Profile/Pages/Profile/components/CandidatePreferences/api/useExtraBenifits";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getDepartments = () =>
	client(GROUP_LIST, {
		body: {
			type: "jobs"
		}
	});
const getSites = () =>
	client(NEW_FILTER_SETTINGS, {
		body: {
			list: "vacancies",
			tag: "site_list"
		}
	});
const getCategories = () =>
	client(LIST_SECTORS, {
		body: {}
	});
const getFunctions = () =>
	client(LIST_FUNCTIONS, {
		body: {}
	});

const getCurrencies = () =>
	client(GET_CURRENCIES, {
		body: {}
	});

const createVacancy = data =>
	client(CREATE_NEW_JOB, {
		body: {
			...data
		}
	});

const createVacancyPricing = data =>
	client(CREATE_NEW_JOB_PRICING, {
		body: {
			...data
		}
	});
const editVacancy = data =>
	client(EDIT_JOB, {
		body: data
	});

export const getLastJob = (key, id, step) => {
	if (id || step === REQUEST) {
		let body = {
			job_type: VACANCY,
			channels_info: true
		};
		if (id) {
			body.id = id;
		}
		return client(GET_LAST_JOB, {
			body
		});
	}
};

const useVacancy = (id, step, setError, enableSite) => {
	const { data: groupsData } = useQuery("groups", getDepartments, {
		refetchOnWindowFocus: false,
		staleTime: Infinity
	});

	const { data: siteData } = useQuery("sites", getSites, {
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		enabled: enableSite
	});

	const { data: sectorsData } = useQuery("sectors", getCategories, {
		refetchOnWindowFocus: false,
		staleTime: Infinity
	});

	const { data: functionsData } = useQuery(["functions"], getFunctions, {
		refetchOnWindowFocus: false,
		staleTime: Infinity
	});

	const { data: currencies } = useQuery(["currencies"], getCurrencies, {
		refetchOnWindowFocus: false,
		staleTime: Infinity
	});

	const {
		data: jobData,
		isFetching: isFetchingJobData,
		refetch,
		isLoading: isLoadingJobData
	} = useQuery(["get-last-job", id, step], getLastJob, {
		refetchOnWindowFocus: false
	});
	const { workflowId } = useOnboardingStore();
	const { data: extra_benefits } = useGetlistExtraBenefits(
		null,
		{
			tag: "list_extra_benefits"
		},
		{ refetchOnWindowFocus: false, staleTime: Infinity, enabled: !workflowId }
	);

	const [requestStep, { isLoading: isSavingRequest }] = useMutation(
		id ? editVacancy : createVacancyPricing,
		{
			onSuccess: () => {
				browserHistory.push(`/vacancy/${jobData.id}`);
			},
			onError: e => {
				if (e && e.status === 422) {
					let errorMsg = _get(e, "detail");
					if (errorMsg) {
						Object.keys(errorMsg).forEach(name => {
							setError(name, {
								type: "manual",
								message: _get(errorMsg, name)[0]
							});
							if (name === "number")
								toaster.danger(`${name} : ${_get(errorMsg, name)[0]}`);
						});
					}
				} else {
					toaster.danger("An error has occurred");
				}
			}
		}
	);
	const [profileStep, { isLoading: isSavingProfile }] = useMutation(
		id ? editVacancy : createVacancy,
		{
			onSuccess: () => {
				toaster.success(`Vacancy successfully ${id ? "edited" : "created"}`);

				browserHistory.push(`/vacancy/create/request/${id ? id : ""}`);
			},
			onError: e => {
				if (e && e.status === 422) {
					let errorMsg = _get(e, "detail");
					if (errorMsg) {
						Object.keys(errorMsg).forEach(name =>
							setError(name, {
								type: "manual",
								message: _get(errorMsg, name)[0]
							})
						);
					}
				} else {
					toaster.danger("An error has occurred");
				}
			}
		}
	);

	return {
		groupsData,
		sectorsData,
		functionsData,
		currencies,
		extra_benefits,
		siteData,
		jobData,
		profileStep,
		requestStep,
		isSavingProfile,
		refetch,
		isSavingRequest,
		isLoading: isFetchingJobData,
		isLoadingJobData
	};
};

export default useVacancy;
