import React from "react";
import _get from "lodash/get";

import { SearchResultsContainer } from "./Styled";
import JobCard from "./JobCardOnline";
import Pagination from "common/Pagination";
import { LOADING } from "config";

import Loader from "common/Loader";
import EmptySearch from "./EmptySearch";

const SearchResults = ({
	jobs,
	updateOffset,
	isFetching,
	status,
	setSelectedJob,
	templateId,
	setDisplayJobView,
	dispalyHideJob,
	isOnline
}) => {
	const renderJobs = () => {
		return _get(jobs, "data", []).map((job, index) => (
			<JobCard
				key={index}
				job={job}
				index={index}
				isLoading={status === LOADING}
				setSelectedJob={setSelectedJob}
				setDisplayJobView={setDisplayJobView}
				templateId={templateId}
				dispalyHideJob={dispalyHideJob}
				isOnline={isOnline}
			/>
		));
	};
	return (
		<SearchResultsContainer>
			{_get(jobs, "data", []).length > 0 ? (
				<>
					<div className="results-count jobs">
						{isFetching && <Loader />}
						<>{jobs.total} results found</>
					</div>
					<div className="results-container">
						<div className="results jobs">{renderJobs()}</div>
					</div>
					{jobs.total > 8 && (
						<div style={{ position: "relative" }}>
							<Pagination
								total={jobs.total}
								size={jobs.size}
								handlePageClick={page => {
									updateOffset(page * jobs.size);
								}}
								offset={jobs.offset}
								forcePage={Math.ceil(jobs.offset / jobs.size)}
							/>
						</div>
					)}
				</>
			) : (
				<>
					<div className="results-count jobs">{isFetching && <Loader />}</div>
					<EmptySearch />
				</>
			)}
		</SearchResultsContainer>
	);
};
export default SearchResults;
