import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import RenderFilterOptionsMenu from "./RenderFilterOptionsMenu";
import RenderFilterOptionsValues from "./RenderFilterOptionsValues";
import classnames from "./render-filter-options.module.scss";

const RenderFilterOptions = ({
	field,
	op,
	optionsFunction,
	closeMenu,
	onSelect,
	value,
	openFilterOptions,
	selectedOption,
	clickHandler,
	operator,
	deleteFilterValue,
	showMoreTitle,
	id
}) => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [options, setOptions] = useState([]);
	const user = useSelector(state => state.auth.user);
	const { data, isLoading, refetch } = useQuery(
		["loadOptions" + field + get(selectedOption, "label"), searchKeyword],
		optionsFunction,
		{
			refetchOnWindowFocus: false,
			keepPreviousData: false,
			enabled: optionsFunction,
			onSuccess: data => {
				const sortedDate = data.sort((x, y) =>
					x.email === user.email ? -1 : y.email === user.email ? 1 : 0
				);
				!searchKeyword && setOptions(sortedDate);
			}
		}
	);
	useEffect(() => {
		if (optionsFunction) {
			refetch();
		}
	}, [optionsFunction]);

	const filtredOptions = isLoading ? [] : data;

	const isAddIcon =
		get(options, "length", 0) ===
		get(value, "length", get(value, "_id.length", ""));

	return (
		<React.Fragment>
			<RenderFilterOptionsValues
				field={field}
				clickHandler={clickHandler}
				value={value}
				operator={operator}
				deleteFilterValue={deleteFilterValue}
				showMoreTitle={showMoreTitle}
				isAddIcon={!isAddIcon}
				onSelect={onSelect}
				options={options}
				selectedOption={selectedOption}
			/>
			<div id={id} className={classnames.listMenuWrraper}>
				<RenderFilterOptionsMenu
					field={field}
					options={options}
					searchKeyword={searchKeyword}
					setSearchKeyword={setSearchKeyword}
					closeMenu={closeMenu}
					onSelect={onSelect}
					value={value}
					openFilterOptions={openFilterOptions}
					op={op}
					selectedOption={selectedOption}
					filtredOptions={filtredOptions}
					id={id}
				/>
			</div>
		</React.Fragment>
	);
};
export default RenderFilterOptions;
