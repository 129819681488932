import React from "react";
import { useMutation } from "react-query";
import Modal from "common/modal";
import { ConfirmContainer, DescriptionText } from "./Styled";
import { CONTACT_US_URL, LOADING } from "config";
import { RESEND_ACTIVATE_EMAIL } from "config/api-endpoints";
import { client } from "lib/api-client";
import toaster from "common/Toaster";
import { ReactComponent as MailBox } from "common/Icons/mail-box.svg";

const resendActivationEmail = email =>
	client(RESEND_ACTIVATE_EMAIL, { body: { email } });

const ConfirmOfflineApplicationModal = ({
	onClose,
	companyName,
	newAccountEmail,
	displayOfflineApplicationModal
}) => {
	const [mutate, { status }] = useMutation(resendActivationEmail, {
		onSuccess: () => {
			toaster.success("The activation email has successfully been resent.");
		},
		onError: error => {
			toaster.danger(error.detail);
		}
	});

	const resendEmail = () => {
		mutate(newAccountEmail);
	};

	return (
		<Modal
			fixed={true}
			onClose={onClose}
			modalName={"offline-application-confirmation"}
			id="offline-application-confirmation"
			loading={status === LOADING}
			maxwidth="671px"
		>
			<ConfirmContainer>
				<>
					<MailBox />
				</>
				<div style={{ width: "500px" }}>
					<DescriptionText>
						<div className="bold">Please confirm your email address.</div>
						<div
							style={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								color: "rgb(39, 50, 56)"
							}}
						>
							Your application is ready to be sent to
							<span className="infos">{` "${companyName}"`}</span>! Just confirm
							it using the link that we just sent you via email.
						</div>
					</DescriptionText>
				</div>

				<div style={{ width: "500px" }}>
					<DescriptionText>
						<div className="bold">Didn’t receive the email? </div>
						<div
							style={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								color: "rgb(39, 50, 56)",
								marginBottom: "8px"
							}}
						>
							1. Is <span className="email"></span>
							{newAccountEmail} your correct email without typos? If not,{" "}
							<span
								className="restart"
								onClick={() => {
									onClose();
									displayOfflineApplicationModal();
								}}
							>
								you can restart the application process.
							</span>
						</div>
						<div
							style={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								color: "rgb(39, 50, 56)",
								marginBottom: "8px"
							}}
						>
							2. Check your spam folder.
						</div>
						<div
							style={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								color: "rgb(39, 50, 56)",
								marginBottom: "8px"
							}}
						>
							3. Add <span className="contact-email">noreply@wiggli.io </span>to
							your contacts
						</div>
						<div
							style={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								color: "rgb(39, 50, 56)",
								marginBottom: "8px"
							}}
						>
							4. Click{" "}
							<span className="link" onClick={resendEmail}>
								here
							</span>{" "}
							to resend the email
						</div>
						<div className="bold">Still having trouble? </div>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={CONTACT_US_URL}
							className="link"
						>
							Contact us
						</a>
					</DescriptionText>
					<button
						onClick={() => onClose()}
						style={{
							padding: "17px",
							borderRadius: "4px",
							backgroundColor: "rgb(27, 159, 148)",
							fontFamily: "BasierCircle",
							fontSize: "15.5px",
							fontWeight: "500",
							color: "rgb(255, 255, 255)",
							border: "none",
							marginTop: "56px",
							display: "block",
							width: "100%"
						}}
					>
						Ok, Got it!
					</button>
				</div>
			</ConfirmContainer>
		</Modal>
	);
};

export default ConfirmOfflineApplicationModal;
