import { GET_NOTE } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";

const getNoteDetails = body => {
	return client(GET_NOTE, {
		body
	});
};

export const GET_NOTE_DETAILS = "getNoteDetails";

function useGetNoteDetails(options) {
	return useQuery(
		[GET_NOTE_DETAILS, options.note_id],
		() => getNoteDetails(options),
		{
			refetchOnWindowFocus: false
		}
	);
}
export default useGetNoteDetails;
