import React from "react";

const InterviewIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<path
				className="notification-icon"
				d="M376.7,234v77.4c0,1.4-1.2,2.6-2.6,2.6h-11.2c-3.5,0-6.4,2.7-6.6,6.1c0,0.1,0,20,0,20l-24.1-24.1
		c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c-1.2-1-2.7-1.7-4.4-1.7h-85.6c-1.4,0-2.6-1.2-2.6-2.6V234c0-1.4,1.2-2.6,2.6-2.6
		h132.4C375.6,231.4,376.7,232.6,376.7,234z M345.5,272.7c0-3.7-3-6.7-6.7-6.7s-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7
		S345.5,276.3,345.5,272.7z M314.6,272.7c0-3.7-3-6.7-6.7-6.7s-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7S314.6,276.3,314.6,272.7z
		 M283.7,272.7c0-3.7-3-6.7-6.7-6.7c-3.7,0-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7C280.8,279.3,283.7,276.3,283.7,272.7z"
			/>
			<g>
				<path
					className="notification-icon-origin"
					d="M338.9,266c3.7,0,6.7,3,6.7,6.7c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7C332.2,269,335.2,266,338.9,266z"
				/>
				<path
					className="notification-icon-origin"
					d="M225.9,282h-31.4l-46.9,46.9c-1.3,1.3-3,1.9-4.7,1.9c-0.9,0-1.7-0.2-2.5-0.5c-2.5-1-4.1-3.5-4.1-6.1V282H124
			c-8.8,0-15.9-7.1-15.9-15.9V152.6c0-8.8,7.1-15.9,15.9-15.9h189.1c8.8,0,15.9,7.1,15.9,15.9v65.5h45.2c8.8,0,15.9,7.1,15.9,15.9
			v77.4c0,8.8-7.1,15.9-15.9,15.9h-4.6v28.9c0,2.7-1.6,5.1-4.1,6.1c-0.8,0.3-1.7,0.5-2.5,0.5c-1.7,0-3.4-0.7-4.7-1.9l-33.6-33.6
			h-82.9c-8.8,0-15.9-7.1-15.9-15.9V282z M315.7,218.1v-65.5c0-1.4-1.2-2.6-2.6-2.6H124c-1.4,0-2.6,1.2-2.6,2.6v113.4
			c0,1.4,1.2,2.6,2.6,2.6h18.9c3.5,0,6.3,2.7,6.6,6.1c0,0.2,0,33.4,0,33.4l37.5-37.5c1.2-1.2,2.7-1.8,4.2-1.9c0.1,0,24.7,0,34.6,0
			V234c0-8.8,7.1-15.9,15.9-15.9L315.7,218.1L315.7,218.1z M376.7,311.4V234c0-1.4-1.2-2.6-2.6-2.6H241.8c-1.4,0-2.6,1.2-2.6,2.6
			v77.4c0,1.4,1.2,2.6,2.6,2.6h85.6c1.7,0,3.2,0.6,4.4,1.7c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0,0,0.1,0.1l24.1,24.1c0,0,0-19.8,0-20
			c0.3-3.4,3.1-6.1,6.6-6.1h11.2C375.6,313.9,376.7,312.8,376.7,311.4z"
				/>
				<path
					className="notification-icon-origin"
					d="M308,266c3.7,0,6.7,3,6.7,6.7c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7C301.3,269,304.3,266,308,266z"
				/>
				<path
					className="notification-icon-origin"
					d="M279.1,180.7c3.7,0,6.7,3,6.7,6.7c0,3.7-3,6.7-6.7,6.7h-121c-3.7,0-6.7-3-6.7-6.7c0-3.7,3-6.7,6.7-6.7H279.1z
			"
				/>
				<path
					className="notification-icon-origin"
					d="M277.1,266c3.7,0,6.7,3,6.7,6.7c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7C270.4,269,273.4,266,277.1,266z"
				/>
				<path
					className="notification-icon-origin"
					d="M204,224.7c3.7,0,6.7,3,6.7,6.7c0,3.7-3,6.7-6.7,6.7h-45.9c-3.7,0-6.7-3-6.7-6.7c0-3.7,3-6.7,6.7-6.7H204z"
				/>
			</g>
		</g>
	</svg>
);

export default InterviewIcon;
