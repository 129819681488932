import React, { useState, useMemo, useCallback } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import _get from "lodash/get";
import _diffBy from "lodash/differenceBy";
import _filter from "lodash/filter";
import { useMutation } from "react-query";
import {
	HeaderModal,
	Label,
	Channels,
	EmptySearchContainer,
	ActiveChannel,
	ALLChannel,
	PaginationWrapper
} from "./styled";
import Modal from "common/modal";
import Box from "common/styled/Box";
import { client } from "lib/api-client";
import { PUBLISHED_JOB } from "config/api-endpoints";
import toaster from "common/Toaster";
import folder from "static/images/folder.jpg";
import { RESPONSE_CODE_422, EUR, HIREME_CHANNEL_ID } from "config";
import CurrencyFormatter from "common/CurrencyFormater";
import get from "lodash/get";
import useChannels from "./use-channels";
import Pagination from "common/Pagination";
import ChannelsModalFilters from "./ChannelsModalFilters";
import ChannelComponent from "./Channel";
import ConfirmChannels from "./ConfirmChannels";
import Portal from "common/Portal";
import { queryCache } from "react-query";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore/index.js";

const EmptySearch = ({ title }) => (
	<EmptySearchContainer>
		<img alt={"No results found"} src={folder} />
		<div className="title">{title}</div>
	</EmptySearchContainer>
);

function ChannelsModal({
	onClose,
	onAfterSave,
	isActive,
	jobId,
	no_verified_candidates,
	verified_candidates,
	myJobPage,
	title,
	isNewCampaing,
	previousChannels,
	publishLang,
	publish_types
}) {
	const [filter, setFilter] = useState("");
	const [selectedChannels, setSelectedChannels] = useState([]);
	const [selectedChannelsOffset, setSelectedChannelsOffset] = useState(0);
	const [showCofirmModal, setShowCofirmModal] = useState(false);

	const {
		setOffset,
		offset,
		data,
		isLoading,
		clearAllFilters,
		selectedFunction,
		selectedJobTitle,
		selectedIndustry,
		setSelectedIndustry,
		setSelectedJobTitle,
		setSelectedLocation,
		setSelectedFunction,
		setSearchChannel,
		taxonomies,
		setIsRecommendation,
		selectedLocation,
		setSearchLocation,
		createCampaignFunction,
		createCampaignLoader,
		getListChannelsLoader
	} = useChannels(jobId);

	const channels = get(data, "data", []);
	const { setIndexTab } = useVacancyStore();

	const publishVacancy = () =>
		client(PUBLISHED_JOB, {
			body: {
				id: jobId,
				publish_types: {
					talent_pool: no_verified_candidates,
					my_candidates: verified_candidates,
					my_job_page: myJobPage,
					external_candidates: {
						active: true,
						channel_ids: selectedChannels.map(channel => channel.product_id)
					}
				},
				publish_version: publishLang
			}
		});

	const [onSave, { status }] = useMutation(publishVacancy, {
		onSuccess: () => {
			toaster.success("Your vacancy has successfully been published.");
			queryCache.invalidateQueries("getCampaignPerformance");
			setIndexTab(2);
			onClose();
			onAfterSave();
		},
		onError: error => {
			if (_get(error, "status") === RESPONSE_CODE_422) {
				const message = _get(error, "detail");
				if (message instanceof Object) return toaster.danger(message.name);
				return toaster.danger(message);
			}
		}
	});

	const crossedData = useMemo(
		() => _diffBy(channels, selectedChannels, "product_id"),
		[channels, selectedChannels]
	);

	const previousCrossedData = useMemo(
		() => _diffBy(previousChannels, selectedChannels, "product_id"),
		[previousChannels, selectedChannels]
	);

	const filteredCrossedData = crossedData.map(item => ({ item }));

	const filteredSelected = selectedChannels.map(item => ({ item }));

	const clearAll = e => {
		e.preventDefault();
		setSelectedChannels([]);
		setOffset(0);
	};

	// todo HIREME_CHANNEL_ID
	const handleSelectedChannel = channel => {
		const hiremeChannel = selectedChannels.find(
			ch => ch.product_id === HIREME_CHANNEL_ID
		);
		if (!hiremeChannel && channel.product_id !== HIREME_CHANNEL_ID) {
			const hireme = crossedData.find(
				cs => cs.product_id === HIREME_CHANNEL_ID
			);
			setSelectedChannels([hireme, channel, ...selectedChannels]);
		} else {
			setSelectedChannels([...selectedChannels, channel]);
		}
	};
	const addAll = e => {
		e.preventDefault();
		setSelectedChannels([...crossedData, ...selectedChannels]);
	};
	const AddPreviousChannel = e => {
		e.preventDefault();
		setSelectedChannels([...previousCrossedData, ...selectedChannels]);
	};

	const removeSelectedChannel = id => {
		setSelectedChannels(
			_filter(selectedChannels, channel => channel.product_id !== id)
		);
	};

	const secondButtonAction = () => setShowCofirmModal(true);

	const onCloseConfirmChannels = () => setShowCofirmModal(false);

	const paginatedActiveChannels = useMemo(() => {
		const newArray = filteredSelected.slice(
			selectedChannelsOffset,
			selectedChannelsOffset + 25
		);
		if (newArray.length === 0 && filteredSelected.length > 0) {
			setSelectedChannelsOffset(selectedChannelsOffset - 25);
			return [];
		}
		return newArray;
	}, [filteredSelected]);

	const memoizedCreateCampaign = useCallback(() => {
		createCampaignFunction({
			jobId,
			selectedChannels,
			publishLang,
			publish_types,
			onClose,
			onAfterSave,
			setIndexTab
		});
	}, [selectedChannels]);

	const totalCost = filteredSelected.reduce(
		(totalCost, amount) =>
			totalCost + get(amount, "item.vonq_price[0].amount", 0),
		0
	);

	return (
		<Portal>
			<Modal
				active={isActive}
				onClose={onClose}
				className="ModalSelectChannels"
				loading={
					status === "loading" ||
					isLoading ||
					getListChannelsLoader ||
					createCampaignLoader
				}
				showButtonOnRight
				firstButton={{
					action:
						totalCost > 0
							? secondButtonAction
							: isNewCampaing
							? memoizedCreateCampaign
							: onSave,
					label: isNewCampaing ? "Republish" : "Publish",
					type: "primary",
					large: true,
					disabled: filteredSelected.length === 0 || createCampaignLoader
				}}
				style={{ width: "1100px" }}
			>
				<HeaderModal>{title}</HeaderModal>

				<ChannelsModalFilters
					filter={filter}
					setFilter={setFilter}
					jobId={jobId}
					clearAllFilters={clearAllFilters}
					selectedFunction={selectedFunction}
					selectedJobTitle={selectedJobTitle}
					selectedIndustry={selectedIndustry}
					setSelectedIndustry={setSelectedIndustry}
					setSelectedJobTitle={setSelectedJobTitle}
					setSelectedLocation={setSelectedLocation}
					setSelectedFunction={setSelectedFunction}
					setSearchChannel={setSearchChannel}
					taxonomies={taxonomies}
					selectedLocation={selectedLocation}
					setSearchLocation={setSearchLocation}
					setIsRecommendation={setIsRecommendation}
				/>
				<Box flexBox mb={"20px"}>
					<Box width={2 / 3} mr={"20px"}>
						<Box
							flexBox
							alignItems="center"
							justifyContent={"space-between"}
							mr={"20px"}
							mb={"10px"}
						>
							<Box>
								<Label sx={{ color: "#273238", fontWeight: 600 }}>
									ALL channels ({get(data, "total")})
								</Label>
							</Box>
							{isNewCampaing && (
								<Box>
									<a
										className="LinkModal"
										href="/"
										onClick={AddPreviousChannel}
									>
										<span className="ButtonLink-text">
											Add previously used ({previousCrossedData.length})
											&gt;&gt;
										</span>
									</a>
								</Box>
							)}

							<Box>
								{get(data, "total") && (
									<a className="LinkModal" href="/" onClick={addAll}>
										<span className="ButtonLink-text">Add all &gt;&gt;</span>
									</a>
								)}
							</Box>
						</Box>
						<ALLChannel>
							<Channels className="channels-container" mr={"20px"}>
								<Scrollbars
									autoHide
									autoHideTimeout={5000}
									autoHideDuration={200}
									autoHeight
									autoHeightMax={430}
									style={{ zIndex: 1, paddingBottom: "16px" }}
									renderTrackHorizontal={props => (
										<div
											{...props}
											style={{ display: "none" }}
											className="track-horizontal"
										/>
									)}
									renderView={props => (
										<div
											{...props}
											style={{
												...props.style,
												marginBottom: 0,
												overflowX: "hidden",
												display: "grid",
												gridTemplateColumns:
													filteredCrossedData.length > 0 ? "50% 50%" : "unset",

												maxHeight: 430,
												columnGap: 0,
												rowGap: 10
											}}
										/>
									)}
								>
									<>
										{filteredCrossedData.length > 0 ? (
											filteredCrossedData.map(({ item: channel }) => (
												<ChannelComponent
													key={channel.product_id}
													channel={channel}
													handleSelectedChannel={handleSelectedChannel}
												/>
											))
										) : channels.length > 0 ? (
											<EmptySearch
												title={"You have added all channels of this page"}
											/>
										) : (
											<EmptySearch title={"No result to show"} />
										)}
									</>
								</Scrollbars>
							</Channels>
							<PaginationWrapper>
								{data && data.total > 50 && (
									<Pagination
										nextLabel={">>"}
										previousLabel={"<<"}
										total={data.total}
										size={data.size}
										handlePageClick={page => {
											setOffset(page * data.size);
										}}
										offset={offset}
										forcePage={Math.ceil(offset / data.size)}
									/>
								)}
							</PaginationWrapper>
						</ALLChannel>
					</Box>

					<Box width={1 / 3}>
						<Box
							mb={"10px"}
							flexBox
							alignItems="center"
							justifyContent={"space-between"}
						>
							<Box>
								<Label sx={{ color: "#273238", fontWeight: 600 }}>
									Active channels ({filteredSelected.length})
								</Label>
							</Box>
							<Box>
								{filteredSelected.length > 0 && (
									<a className="LinkModal" href="/" onClick={clearAll}>
										<span className="ButtonLink-text ButtonLink-text-red">
											Clear all &times;
										</span>
									</a>
								)}
							</Box>
						</Box>
						<ActiveChannel>
							<Channels className="channels-container">
								<Scrollbars
									autoHide
									autoHideTimeout={5000}
									autoHideDuration={200}
									autoHeight
									autoHeightMax={362}
									style={{ zIndex: 1, paddingBottom: "16px" }}
									renderTrackHorizontal={props => (
										<div
											{...props}
											style={{ display: "none" }}
											className="track-horizontal"
										/>
									)}
									renderView={props => (
										<div
											{...props}
											style={{
												...props.style,
												marginBottom: 0,
												overflowX: "hidden",

												maxHeight: 362
											}}
										/>
									)}
								>
									{filteredSelected.length > 0 ? (
										paginatedActiveChannels.map(({ item: channel }) => (
											<ChannelComponent
												removeSelectedChannel={removeSelectedChannel}
												key={channel.product_id}
												filteredSelected={filteredSelected}
												channel={channel}
												isActiveChannel
											/>
										))
									) : (
										<EmptySearch title={"No channels added"} />
									)}
								</Scrollbars>
							</Channels>
							<div className="divider" />
							<div className="campaign-total">
								<span>Campaign Total Cost: </span>
								<span className="currency-formatter">
									<CurrencyFormatter
										euro={filteredSelected.reduce(
											(totalCost, amount) =>
												totalCost +
												_get(amount, "item.vonq_price[0].amount", 0),
											0
										)}
										symbol={EUR}
									/>
								</span>
							</div>
							<PaginationWrapper minHeight={35}>
								{filteredSelected && filteredSelected.length > 25 && (
									<Pagination
										nextLabel={">>"}
										previousLabel={"<<"}
										total={filteredSelected.length}
										size={25}
										handlePageClick={page => {
											setSelectedChannelsOffset(page * 25);
										}}
										offset={selectedChannelsOffset}
										forcePage={Math.ceil(selectedChannelsOffset / 25)}
									/>
								)}
							</PaginationWrapper>
						</ActiveChannel>
					</Box>
				</Box>
			</Modal>
			{showCofirmModal && totalCost > 0 && (
				<ConfirmChannels
					onClose={onCloseConfirmChannels}
					data={filteredSelected}
					isNewCampaing={isNewCampaing}
					memoizedCreateCampaign={memoizedCreateCampaign}
					onSave={onSave}
					status={status}
					isLoading={isLoading}
					getListChannelsLoader={getListChannelsLoader}
					createCampaignLoader={createCampaignLoader}
				/>
			)}
		</Portal>
	);
}

export default ChannelsModal;
