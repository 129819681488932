import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { XIcon } from "@heroicons/react/solid";
import { useForm } from "react-hook-form";
import { array, object } from "yup";
import {
	DialogTitle,
	Dialog,
	DialogContent,
	DialogClose
} from "../../CreateNewSupplier/Dialog";
import s from "../../CreateNewSupplier/create-supplier.module.scss";
import Functions from "./Functions";
import useFiltersStore from "../searchStore";

function FunctionsDialog({
	isOpen,
	onClose,
	onSubmit: propsSubmit,
	config = {
		title: "Filter by Expertise (Functions)",
		saveAction: "Add to filters"
	},
	selected: propsFunctions,
	store
}) {
	const { functions, setFunctions } = (store ?? useFiltersStore)(state => ({
		functions: state.functions,
		setFunctions: state.setFunctions
	}));
	const {
		control,
		handleSubmit,
		reset,
		watch,
		setValue,
		formState: { errors }
	} = useForm({
		shouldUnregister: true,
		defaultValues: {
			row: propsFunctions ?? functions
		},
		resolver: yupResolver(
			object().shape({
				row: array().of(
					object({
						category: object().required("Category is required.")
					}).nullable()
				)
			})
		)
	});

	useEffect(() => {
		if (!functions?.length && !propsFunctions?.length) return;
		reset({
			row: propsFunctions ?? functions
		});
	}, [reset, functions, propsFunctions]);

	const onSubmit = e => {
		e.stopPropagation();
		e.preventDefault();
		const cb = propsSubmit ?? setFunctions;
		return handleSubmit(fields => {
			cb(fields.row);
			onClose();
		})(e);
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent size={"xl"} onEscapeKeyDown={onClose}>
				<DialogTitle>{config?.title}</DialogTitle>
				<form className={s.form} onSubmit={onSubmit}>
					<Functions
						setValue={setValue}
						getValues={watch}
						control={control}
						errors={errors}
					/>
					<div className={s.dialogFooter}>
						<button onClick={onClose} className={s.cancelButton}>
							Cancel
						</button>
						<button type={"submit"} className={s.mainButton}>
							{config?.saveAction}
						</button>
					</div>
				</form>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>
						<XIcon height={16} width={16} />
					</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default FunctionsDialog;
