import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { SET_FILE_PRIMARY } from "config/api-endpoints";

function setAsPrimary(body) {
	return client(SET_FILE_PRIMARY, {
		body
	});
}
export const updateFilePrimary = "@profile/update-primary";
function useUpdateFilePrimary(options) {
	return useMutation(setAsPrimary, options);
}

export default useUpdateFilePrimary;
