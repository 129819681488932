import React from "react";
import Modal from "common/modal";
import { ReactComponent as IconClose } from "static/icons/closeVideoModal.svg";
import style from "./modal-video.module.scss";
import { extractVideoIdYoutube } from "config/helpers";

const ModalVideo = ({ onClose, videoLink }) => {
	const videoId = extractVideoIdYoutube(videoLink);

	return (
		<Modal
			size={"992px"}
			padding={"0px"}
			borderRadius={"8px"}
			buttonCloseClassName={"hide"}
			style={{
				background: "#FFF"
			}}
			backgroundColor={"rgba(40, 47, 56, 0.60)"}
			customCloseBtnRender={onClose => (
				<div className={style.modal__close} onClick={() => onClose()}>
					<IconClose />
				</div>
			)}
			onClose={onClose}
		>
			<div className={style.videoContainer}>
				<iframe
					src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`}
					frameBorder="0"
					allowFullScreen
					allow="autoplay"
				></iframe>
			</div>
		</Modal>
	);
};

export default ModalVideo;
