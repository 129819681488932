import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change } from "redux-form";
import _get from "lodash/get";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import {
	fetchPoke,
	toggleNotesModal,
	toggleProposeInterviewModal
} from "../../../actions";
import { pokeSelector } from "../../../../candidate/redux/selectors";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { attendeesListSelector } from "modules/candidate/redux/selectors";
import { getListAttendees } from "modules/candidate/redux/actions";
import { HTTP_FORBIDDEN, ATTENDEES_LIST_TAG } from "config";
import {
	interviewDispatch,
	acceptInterview,
	showVideoConf,
	hideVideoConf,
	cleanState
} from "../../../actions";
import { notesSelector, showVideoConfSelector } from "../../../selectors";
import { getVacancyListRoute } from "modules/vacancy/containers/VacancyListContainer";
import ClientPokeView from "./ClientPokeView";
import { historyPush } from "config/helpers";
import { getPermanentProfileRoute } from "modules/candidate/Profile";

export const getClientPokeViewRoute = id => {
	return `/vacancies/client/poke/${id || `:id`}`;
};

export const getClientPokeViewPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export class ClientPokeViewContainer extends Component {
	componentDidMount() {
		const {
			params: { id },
			actions
		} = this.props;
		if (id) {
			actions.fetchPoke({ id }).catch(err => {
				const errorCode = _get(err, "data.status");
				if (errorCode === HTTP_FORBIDDEN) {
					browserHistory.push(getVacancyListRoute());
				}
			});
		}
	}

	componentDidUpdate({ params }) {
		const {
			params: { id },
			actions,
			poke
		} = this.props;
		if (id && params.id !== id) {
			actions.fetchPoke({ id });
		}
		if (poke.profile) {
			historyPush(
				`${getPermanentProfileRoute(poke.profile._id)}?application_id=${id}`
			);
		}
	}

	componentWillUnmount() {
		const { actions } = this.props;
		actions.cleanState();
	}
	changeReduxForm = (field, value) => {
		const { actions } = this.props;
		actions.change("send-interview", field, value);
	};
	fetchAttendees = () => {
		const id = _get(this.props, "poke.job._id");
		this.props.actions.getListAttendees({
			tag: ATTENDEES_LIST_TAG,
			vacancy_id: id
		});
	};
	render() {
		const {
			route,
			params,
			poke,
			notes,
			user,
			attendeesList,
			actions: {
				fetchPoke,
				toggleNotesModal,
				toggleProposeInterviewModal,
				interviewDispatch,
				acceptInterview,
				showVideoConf: showVideoConfAction,
				hideVideoConf
			},
			showVideoConf
		} = this.props;
		return (
			<ClientPokeView
				route={route}
				params={params}
				poke={poke}
				fetchPoke={fetchPoke}
				notes={notes}
				toggleNotesModal={toggleNotesModal}
				toggleProposeInterviewModal={toggleProposeInterviewModal}
				interviewDispatch={interviewDispatch}
				acceptInterview={acceptInterview}
				user={user}
				showVideoConf={showVideoConf}
				onCallEnd={hideVideoConf}
				onCallStart={showVideoConfAction}
				changeReduxForm={this.changeReduxForm}
				attendeesList={attendeesList}
				fetchAttendees={this.fetchAttendees}
			/>
		);
	}
}

ClientPokeViewContainer.propTypes = {
	actions: PropTypes.object.isRequired,
	params: PropTypes.shape({
		id: PropTypes.string.isRequired
	}).isRequired,
	poke: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	notes: notesSelector(state),
	poke: pokeSelector(state),
	user: onlineUserSelector(state),
	showVideoConf: showVideoConfSelector(state),
	attendeesList: attendeesListSelector(state)
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(
		{
			fetchPoke,
			toggleNotesModal,
			toggleProposeInterviewModal,
			interviewDispatch,
			acceptInterview,
			showVideoConf,
			hideVideoConf,
			cleanState,
			change,
			getListAttendees
		},
		dispatch
	)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClientPokeViewContainer);
