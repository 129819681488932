import React from "react";
import styles from "../../CommunityCriteria/DeleteModal/styles.module.scss";
import toaster from "common/Toaster";
import get from "lodash/get";
import DeleteButton from "./DeleteButton";
import CustomizableModel from "common/CustomizableModel/CustomizableModel";
import useDeleteProfile from "../../../../candidate/Profile/api/useDeleteProfile";

const DeleteModal = ({ candidate, onClose, refetch }) => {
	const [mutateProfil, { isLoading }] = useDeleteProfile({
		onSuccess: () => {
			//300 ms is a random value because the API doesn't wait for a complete deletion
			//This should be fixed
			setTimeout(() => {
				toaster.success("Profile had been Deleted");
				onClose();
				refetch?.();
			}, 300);
		},
		onError: error => {
			if (error) {
				toaster.danger(
					get(error, "detail.name") ? error.detail.name : error.detail
				);
				onClose();
			}
			toaster.danger(
				get(error, "detail.name") ? error.detail.name : error.detail
			);
			onClose();
			refetch?.();
		}
	});

	const deletePendingProfile = id => mutateProfil({ profile_ids: [id] });
	return (
		<>
			<CustomizableModel
				title="Delete candidate"
				actionButton={
					<DeleteButton
						onClick={() => deletePendingProfile(get(candidate, "id", ""))}
						isLoading={isLoading}
					/>
				}
				onClose={onClose}
			>
				<p className={styles.modal__text}>
					Are you sure you want to delete :{" "}
					<span className={styles.modal__criteria}>
						{get(candidate, "name", "")}
					</span>
				</p>
			</CustomizableModel>
		</>
	);
};

export default DeleteModal;
