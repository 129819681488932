import React from "react";
import styles from "./ProfileClient.module.scss";
import { ReactComponent as IconInfo } from "static/icons/IconInfo.svg";

export default function Banner({ children }) {
	return (
		<div className={styles.banner}>
			<IconInfo />
			{children}
		</div>
	);
}
