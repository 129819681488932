import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_CONTRACTS_GROUP } from "config/api-endpoints";

function deleteGroup(body) {
	return client(DELETE_CONTRACTS_GROUP, {
		body
	});
}

function useDeleteContractsGroup(queryOptions = {}) {
	return useMutation(deleteGroup, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
}

export default useDeleteContractsGroup;
