import React from "react";
import ToolTip from "common/GlobalTooltip";
import PropTypes from "prop-types";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";

const Fee = ({ marginValue, suffix, reportingSettings }) => (
	<div id="fee">
		+{" "}
		<CurrencyFormatterRender
			isCent
			value={marginValue || 0}
			suffix={suffix}
			currency={reportingSettings?.currency?.code}
		/>{" "}
		<ToolTip
			placement={"right"}
			maxWidth={"300px"}
			align={{
				offset: [15, 0]
			}}
			overlay="wiggli’s fee, which will be added to the rate invoiced by this applicant during the mission."
		/>
	</div>
);

Fee.propTypes = {
	marginValue: PropTypes.number.isRequired
};

export default Fee;
