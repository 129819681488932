import styled, { css } from "styled-components";

export const StyledButton = styled.button`
	border-radius: 4px;
	font-size: ${({ fontSize }) => (fontSize ? fontSize + "px" : "13px")};
	font-weight: 600;
	padding: ${({ padding }) => (padding ? padding : "14px 24px")};
	border: ${({ border }) => border || "none"};
	line-height: 1.23;
	background-color: ${({ backgroundColor }) => backgroundColor};

	.loading {
		margin-bottom: 0px;
		> span {
			width: 16px;
			height: 16px;
			border-top-color: ${({ fontColor }) => fontColor || "#ff6849"};
		}
	}
	&:disabled {
		${({ disabledBackgroundColor }) =>
			disabledBackgroundColor
				? css`
						background-color: ${disabledBackgroundColor};
				  `
				: css`
						opacity: 0.5;
				  `};

		cursor: not-allowed;
	}
	&:focus-visible {
		outline: none;
	}
`;

export const ContainedButtonStyle = styled(StyledButton)`
	border: ${({ borderColor }) => "2px solid " + (borderColor || "transparent")};
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: ${({ fontColor }) => fontColor};
	&:hover {
		background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
		border: ${({ hoverBackgroundColor }) =>
			"2px solid " + hoverBackgroundColor};
	}
`;

export const OutlinedButtonStyle = styled(StyledButton)`
	background-color: transparent;
	border: ${({ borderColor }) =>
		borderColor ? "2px solid " + borderColor : "2px solid transparent"};
	color: ${({ fontColor, backgroundColor }) => fontColor || backgroundColor};
	&:hover,
	&:focus {
		color: ${({ fontColor }) => fontColor || "#ffffff"};
		background-color: ${({ backgroundColor }) => backgroundColor};
	}
`;

export const IconButtonStyle = styled(StyledButton)`
	display: flex;
	align-items: center;
	border: ${({ borderColor }) => "2px solid " + (borderColor || "transparent")};
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: ${({ fontColor }) => fontColor};
	.icon {
		fill: ${({ fontColor }) => fontColor};
		margin-right: ${({ space }) => space || "10px"};
		width: 18px;
		height: 18px;
	}
	&:hover,
	&:focus {
		background-color: ${({ fontColor }) => fontColor};
		color: #fff;
		.icon {
			fill: #fff;
		}
	}
`;

export const PrimaryButtonStyle = styled(StyledButton)`
	color: #ffffff;
	background-color: #ff6849;
	border: 2px solid transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: ${({ minWidth }) => minWidth || "190px"};
	${({ disabledBackgroundColor }) =>
		!disabledBackgroundColor &&
		css`
			&:hover,
			&:focus {
				background-color: #fc5035;
				border: 2px solid #fc5035;
			}
		`};
`;
