import { useInfiniteQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_EVALUATION_TEMPLATES } from "config/api-endpoints";

export const TEMPLATES_LIST_LIMIT = 7;

function getList(_, body, offset = 0) {
	return client(LIST_EVALUATION_TEMPLATES, {
		body: {
			...body,
			offset,
			limit: TEMPLATES_LIST_LIMIT
		}
	});
}

export const GET_TEMPLATES_QUERY_KEY = "GET_TEMPLATES_QUERY_KEY";

export function useGetEvaluationTemplates(body, options = {}) {
	return useInfiniteQuery([GET_TEMPLATES_QUERY_KEY, body], getList, {
		...options,
		retry: 0,
		refetchOnWindowFocus: false
	});
}
