import { usePaginatedQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_CANDIDATE_FILES_ENDPOINT } from "config/api-endpoints";

const getListCandidateFiles = (_key, body) =>
	client(LIST_CANDIDATE_FILES_ENDPOINT, {
		body
	});
export const getListCandidateFilesKey = "@Profile/getListCandidateFiles";
const useGetListFiles = (options, queryOptions = {}) =>
	usePaginatedQuery(
		[getListCandidateFilesKey, options],
		getListCandidateFiles,
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...queryOptions
		}
	);

export default useGetListFiles;
