import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { LIST_SKILLS } from "config/api-endpoints";

function getSkills(data) {
	return client(LIST_SKILLS, { body: data });
}

function useLoadSkills(options) {
	return useMutation(getSkills, options);
}

export default useLoadSkills;
