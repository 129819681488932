import React, { useState, useRef, useEffect } from "react";
import { reduxForm, Field, change } from "redux-form";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DayPickerRangeController } from "react-dates";

import {
	ChooseDate,
	HandleDatePicker,
	NavButtonLeft,
	NavButtonRight
} from "./Styled";
import { DATE_FORMAT } from "config";
import StyledSelect from "common/StyledSelect";
import FormField from "common/FormField";

moment.updateLocale("en", {
	week: {
		dow: 1
	}
});

const form = "date-picker";

function Picker({
	handleSubmit,
	setStartDate,
	setEndDate,
	startDate,
	endDate,
	presets,
	activePreset,
	dispatch,
	setActivePreset,
	togglePicker,
	pickerRef
}) {
	const monthController = useRef();

	const [focusedInput, setFocusedInput] = useState("startDate");

	useEffect(() => {
		startDate &&
			dispatch(change(form, "startDate", startDate.format(DATE_FORMAT)));
		endDate && dispatch(change(form, "endDate", endDate.format(DATE_FORMAT)));
	}, [startDate, endDate]);

	useEffect(() => {
		if (focusedInput === "startDate" && startDate) {
			monthController.current.onMonthSelect(
				monthController.current.month,
				startDate.format("M")
			);
		}
	}, [startDate]);

	const onApply = ({ startDate, endDate }) => {
		if (!startDate || !endDate) {
			return;
		}
		const days = window.moment
			.duration(window.moment(endDate).diff(startDate))
			.asDays();
		if (days < 0) {
			return;
		}
		setStartDate(
			window.moment(startDate, DATE_FORMAT).set({
				hour: 0,
				minute: 0
			})
		);
		setEndDate(
			window.moment(endDate, DATE_FORMAT).set({
				hour: 23,
				minute: 59
			})
		);
		togglePicker();
	};

	const onChangeDateSelect = field => {
		const fieldValue = typeof field === "string" ? field : field.value;
		if (field) {
			if (field.run) {
				field.run();
			}
			setActivePreset(fieldValue);
		}
	};

	const onDatesChange = ({ startDate, endDate }) => {
		if (focusedInput === "startDate" || endDate === null) {
			return setStartDate(startDate);
		}

		setEndDate(endDate);
	};

	return (
		<HandleDatePicker ref={pickerRef}>
			<DayPickerRangeController
				onDatesChange={onDatesChange}
				focusedInput={focusedInput}
				numberOfMonths={2}
				startDate={startDate}
				endDate={focusedInput === "endDate" ? null : endDate}
				renderMonthElement={(...args) => {
					monthController.current = {
						month: args[0].month,
						onMonthSelect: args[0].onMonthSelect
					};
					return args[0].month.format("MMMM YYYY");
				}}
				navPrev={
					<NavButtonLeft>
						<i className="icon-down-arrow" />
					</NavButtonLeft>
				}
				navNext={
					<NavButtonRight>
						<i className="icon-down-arrow" />
					</NavButtonRight>
				}
				onFocusChange={focusedInput =>
					setFocusedInput(!focusedInput ? "startDate" : focusedInput)
				}
				renderCalendarInfo={() => (
					<ChooseDate onSubmit={handleSubmit(onApply)}>
						<Field
							label="Date Range"
							name="date_range"
							options={presets}
							component={StyledSelect}
							onChange={onChangeDateSelect}
							defaultValue={activePreset}
							position={false}
							placeholder="Select ..."
							searchable={true}
							clearable={true}
						/>
						{activePreset !== "all_times" ? (
							<div style={{ display: "flex" }}>
								<Field
									label="Start Date"
									name="startDate"
									component={FormField}
									classes="input-start-date"
								/>

								<Field
									label="End Date"
									name="endDate"
									component={FormField}
									classes="input-end-date"
								/>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									width: "221px",
									color: "#000"
								}}
							>
								All Time
							</div>
						)}

						<button type="submit" style={{ display: "none" }}></button>
					</ChooseDate>
				)}
			/>
		</HandleDatePicker>
	);
}

const validate = ({ startDate, endDate }) => {
	const errors = {};
	if (!startDate) {
		errors.startDate = "Field is required !";
	}
	if (!endDate) {
		errors.endDate = "Field is required !";
	}
	return errors;
};

Picker.propTypes = {
	dispatch: PropTypes.func,
	handleSubmit: PropTypes.func,
	setStartDate: PropTypes.func,
	setEndDate: PropTypes.func,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	presets: PropTypes.array,
	onClose: PropTypes.func,
	activePreset: PropTypes.string,
	setActivePreset: PropTypes.func
};

export default reduxForm({
	form,
	validate,
	touchOnBlur: false
})(Picker);
