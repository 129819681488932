import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const LoadingProfile = () => {
	return (
		<>
			<SkeletonTheme color="#d8dce2">
				<div className="content">
					<div className="head">
						<div>
							<Skeleton width={40} height={40} />
						</div>
						<div className="skeleton">
							<Skeleton width={"80%"} />
							<Skeleton width={"80%"} height={8} />
						</div>
					</div>
					<div className="skeleton-content">
						<Skeleton width={"100%"} />
						<Skeleton width={"100%"} />
						<Skeleton width={"40%"} height={10} />
					</div>
				</div>
				<div className="footer skeleton">
					<Skeleton width={"40%"} height={10} />
				</div>
			</SkeletonTheme>
		</>
	);
};

export default LoadingProfile;
