import React from "react";
import _get from "lodash/get";
import cx from "classnames";
import styles from "./bid-details-processes-card.module.scss";
import { ReactComponent as CoinsIcon } from "static/icons/coins.svg";
import { ReactComponent as CoinsStackedIcon } from "static/icons/coins-stacked.svg";
import { ReactComponent as AlarmClockIcon } from "static/icons/alarm-clock.svg";
import { ReactComponent as MessageTextSquareIcon } from "static/icons/message-text-square.svg";
import { BidDetailsProcessesCard } from ".";
import { GroupAvatars } from "../GroupAvatars";
import { formatDate } from "common/Functions";
import {
	APPLICATION_CANCELLED_LABEL,
	APPLICATION_REJECTED_LABEL,
	CLIENT,
	D_T_M_Y_CALENDAR_FORMAT,
	TIME_FORMAT_SYMBOL,
	ACCEPTED,
	DONE,
	CONFIRMED,
	VIDEO_CONFERENCE
} from "config";
import BidDetailsAddressComposer from "./BidDetailsAddressComposer";
import Description from "common/Description";
import { parseMentions } from "config/helpers";
import BidDetailsRejectedMessage from "./BidDetailsRejectedMessage";

export default function BidDetailsProcessesInterviewCard({
	data,
	isFetching,
	user,
	isOldProposition,
	displayStatus,
	statusTooltip
}) {
	const isInterviewConfirmed = [ACCEPTED, DONE, CONFIRMED].includes(
		_get(data, "status")
	);
	const isVideoConf = data?.interview?.location_type === VIDEO_CONFERENCE;
	const isVideoConfOld =
		_get(data, "interview.old_interview_address") &&
		_get(data, "interview.old_interview_address.location_type") ===
			VIDEO_CONFERENCE;

	const clientName = `${_get(
		data,
		"interview.last_user_interview_action.first_name"
	)} ${_get(data, "interview.last_user_interview_action.last_name")}`;
	const candidateFullName = `${_get(data, "profile_detail.first_name")} ${_get(
		data,
		"profile_detail.last_name"
	)}`;
	const isLastUserSenderClient =
		_get(data, "interview.last_user_interview_action.type") === CLIENT;
	const isInterviewAccepted = _get(data, "interview.status") === ACCEPTED;
	const labelTwo =
		_get(data, "interview.last_user_interview_action._id") === user.id
			? "Your"
			: `${clientName}'s`;

	const description =
		isOldProposition || isInterviewAccepted
			? ""
			: _get(data, "interview.description");

	const defaultTitle = !isOldProposition
		? isInterviewAccepted
			? // when contracts are signed, we look for the other user, because the user sender is now the one who accepted not the one who sent the proposition
			  isLastUserSenderClient
				? `${candidateFullName}'s Proposition`
				: `${labelTwo} proposition`
			: // else we show lastSender's name and if the sender is the connected user, we show 'Your'
			isLastUserSenderClient
			? `Your proposition`
			: `${labelTwo} proposition`
		: "Old proposition";

	const hasContractProposition =
		_get(data, "contract") && !Array.isArray(_get(data, "contract"));
	const isBidRejected = [
		APPLICATION_CANCELLED_LABEL,
		APPLICATION_REJECTED_LABEL
	].includes(_get(data, "visible_status"));

	return (
		<BidDetailsProcessesCard
			title={defaultTitle}
			status={displayStatus && _get(data, "visible_status")}
			statusTooltip={statusTooltip}
			isFetching={isFetching}
		>
			<div
				className={cx(
					styles.content,
					isFetching ? styles.content_loading : undefined
				)}
			>
				{!isOldProposition && isBidRejected && !hasContractProposition && (
					<>
						<BidDetailsRejectedMessage
							title="Interview refused"
							text={`Your interview with ${candidateFullName} has been ${
								isLastUserSenderClient ? "rejected" : "cancelled"
							}.`}
						/>
						<div className={styles.insideDivider}></div>
					</>
				)}
				<div className={styles.item}>
					<div className={styles.labelHead}>
						<CoinsIcon size={20} />
						<div className={styles.label}>Attendee(s)</div>
					</div>
					<div className={styles.value}>
						<GroupAvatars
							collaborators={_get(data, "interview.attendees_users")}
						/>
					</div>
				</div>
				<div className={styles.item}>
					<div className={styles.labelHead}>
						<CoinsStackedIcon size={20} />
						<div className={styles.label}>Location</div>
					</div>
					{!isInterviewConfirmed && (
						<>
							{(isVideoConfOld && isOldProposition) ||
							(isVideoConf && !isOldProposition) ? (
								<div className={styles.value}>Video Conference</div>
							) : (
								<BidDetailsAddressComposer
									addressData={
										isOldProposition
											? _get(data, "interview.old_interview_address")
											: _get(data, "interview")
									}
									className={styles.value}
								/>
							)}
						</>
					)}
				</div>
				<div className={styles.item}>
					<div className={styles.labelHead}>
						<AlarmClockIcon size={20} />
						<div className={styles.label}>Date & time</div>
					</div>
					<div className={styles.value}>
						{formatDate(
							_get(
								data,
								isOldProposition
									? "interview.old_interview_start_date"
									: "interview.new_interview_start_date"
							),
							true,
							D_T_M_Y_CALENDAR_FORMAT
						) +
							" - " +
							formatDate(
								_get(
									data,
									isOldProposition
										? "interview.old_interview_end_date"
										: "interview.new_interview_end_date"
								),
								true,
								TIME_FORMAT_SYMBOL
							)}
					</div>
				</div>
				{description?.length > 0 && (
					<div className={cx(styles.item, styles.item__message)}>
						<div className={styles.labelHead}>
							<MessageTextSquareIcon size={20} />
							<div className={styles.label}>Message</div>
						</div>
						<Description
							description={parseMentions(description)}
							max={230}
							parseMarkdown={true}
							showMoreText="View more"
							classNameContent={styles.value}
							viewMoreClassName={styles.viewMore}
						/>
					</div>
				)}
			</div>
		</BidDetailsProcessesCard>
	);
}
