import { client } from "lib/api-client";
import { USER_ONBOARDING_STEPS } from "config/api-endpoints";
import { useQuery } from "react-query";

const REACT_QUERY_RETRY = 1;
const REACT_QUERY_STALE_TIME = 0;
const KEEP_PREVIOUS_DATA = false;
const REFECH_ON_WINDOW_FOCUS = false;

const queryFn = body => client(USER_ONBOARDING_STEPS, { body });

export const ONBOARDING_STEPS_QUERY = "ONBOARDING_STEPS_QUERY";
export const useOnboardingSteps = (payload, options = {}) =>
	useQuery([ONBOARDING_STEPS_QUERY, payload], () => queryFn(payload), {
		retry: REACT_QUERY_RETRY,
		keepPreviousData: KEEP_PREVIOUS_DATA,
		refetchOnWindowFocus: REFECH_ON_WINDOW_FOCUS,
		staleTime: REACT_QUERY_STALE_TIME,
		...options
	});
