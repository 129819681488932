import create from "zustand";

export const MIN_STEP = 1;
export const MAX_STEP = 4;

const initialState = {
	hasPrev: false,
	hasNext: true,
	modalLoading: false,
	currentStep: MIN_STEP,
	feedBack: null // format { value , label }
};

const deleteAccountStore = set => ({
	...initialState,
	incrementStep: () =>
		set(state => ({ ...state, currentStep: state.currentStep + 1 })),
	decrementStep: () =>
		set(state => ({ ...state, currentStep: state.currentStep - 1 })),
	setHasPrev: hasPrev => set(state => ({ ...state, hasPrev })),
	setHasNext: hasNext => set(state => ({ ...state, hasNext })),
	setFeedback: feedBack => set(state => ({ ...state, feedBack })),
	setModalLoading: modalLoading => set(state => ({ ...state, modalLoading })),
	resetStore: () => set(() => initialState)
});

const useDeleteAccountStore = create(deleteAccountStore);
export default useDeleteAccountStore;
