export const SWITCH_COMPANY = "SWITCH_COMPANY";
export const SWITCHED_COMPANY = "SWITCHED_COMPANY";
export const COMPANY_UPDATE_AVATAR = "COMPANY_UPDATE_AVATAR";
// Users List
export const GET_USERS_LIST = "GET_USERS_LIST";
export const PUT_USERS = "PUT_USERS";
export const PUT_DEPARTMENTS = "PUT_DEPARTMENTS";
export const TOGGLE_FETCHING = "TOGGLE_FETCHING";
export const TOGGLE_MODAL_FETCHING = "TOGGLE_MODAL_FETCHING";
export const SHOW_MODAL = "SHOW_MODAL";
export const CHECK_INVITATION = "CHECK_INVITATION";
export const INVITATION_FOUND = "INVITATION_FOUND";
export const INVITATION_ACCEPTED = "INVITATION_ACCEPTED";
export const ALREADY_ACCEPTED = "ALREADY_ACCEPTED";
export const ACCOUNT_BLOCKED = "ACCOUNT_BLOCKED";
export const SET_EXPIRED = "SET_EXPIRED";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const MOVE_TO_STEP = "MOVE_TO_STEP";
export const SET_EMAIL_EXISTS = "SET_EMAIL_EXISTS";
export const SET_SUCCESS = "SET_SUCCESS";
export const RESEND_INVITE = "RESEND_INVITE";
export const SET_MODAL_ERROR = "SET_MODAL_ERROR";
// CV light
export const FLIP_SUBMITTED = "FLIP_SUBMITTED";
export const TOGGLE_SUBMITTING = "TOGGLE_SUBMITTING";
export const SAVE_CURRENT_COMPANY = "SAVE_CURRENT_COMPANY";
export const SWITCH_UPLOADER_METHOD = "SWITCH_UPLOADER_METHOD";
export const UPLOADER_SUCCESS = "UPLOADER_SUCCESS";
export const SET_UPLOADED_FILES = "SET_UPLOADED_FILES";
export const SWITCH_USER_VIEW = "SWITCH_USER_VIEW";
export const TOGGLE_DEPARTMENT_MODAL = "TOGGLE_DEPARTMENT_MODAL";
export const CHANGE_DEPARTMENT_MODAL_STEP = "CHANGE_DEPARTMENT_MODAL_STEP";
export const TOGGLE_DEPARTMENT_MODAL_SUCCESS =
	"TOGGLE_DEPARTMENT_MODAL_SUCCESS";
