import React from "react";
import styles from "./request-pipeline-card-container.module.scss";

const RequestPipelineCardContainer = ({ children, ...rest }) => {
	return (
		<div {...rest} className={styles.container}>
			{children}
		</div>
	);
};

export default RequestPipelineCardContainer;
