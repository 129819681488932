import React, { useEffect } from "react";
import { Field, reduxForm, SubmissionError, change } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import _get from "lodash/get";
import {
	FIFTY,
	HUNDRED,
	ONE_HUNDRED_AND_FIFTY,
	ONE_HUNDRED_AND_TWENTY_FIVE,
	TWO_HUNDRED,
	DATE_FORMAT,
	MAX_HOURS_PER_DAY,
	OVERTIME_DURATION,
	CUSTOM,
	HOURLY,
	DAILY
} from "config";
import FormField from "common/FormField";
import DatePickerField from "common/DatePickerField.new";
import StyledSelect from "common/StyledSelect";
import toaster from "common/Toaster";
import { Button, OutlineButton } from "common/styled/buttons";
import { FloatItem, Td } from "../TimesheetStyled";
import { utcTimeStamp } from "config/helpers";
import {
	timesheetTagsSelectSelector,
	timesheetTagsProjectNameSelector,
	featureTimesheetTagsSelector
} from "../../timesheetSelector";
import { Flex } from "rebass";
import Menu, {
	MenuItem
} from "modules/company/components/VariableMargins/components/customMenu";
import { ReactComponent as ArrowDown } from "modules/timesheets/components/Icons/arrow_down.svg";

export const rateOptions = val => {
	const options = [
		{
			value: FIFTY,
			label: `${FIFTY}%`
		},
		{
			value: HUNDRED,
			label: `${HUNDRED}%`
		},
		{
			value: ONE_HUNDRED_AND_TWENTY_FIVE,
			label: `${ONE_HUNDRED_AND_TWENTY_FIVE}%`
		},
		{
			value: ONE_HUNDRED_AND_FIFTY,
			label: `${ONE_HUNDRED_AND_FIFTY}%`
		},
		{
			value: TWO_HUNDRED,
			label: `${TWO_HUNDRED}%`
		}
	];
	if (!val) return options;
	return options.find(opt => +opt.value === val);
};
const FORM_NAME = "edit-worktime";
const EditWorkTime = ({
	month,
	disabled,
	tagOptions,
	projectName,
	tagOverlayText,
	getInitialVisibleMonth,
	item,
	isClient,
	amount,
	id,
	isEditHasTag,
	hasTag,
	hideAmount,
	dirty,
	disputedWorkTime,
	addedWorkTimes,
	canApproveTags,
	isLastItem,
	isOverTime,
	reportingSettings,
	formValues,
	dispatch,
	...props
}) => {
	useEffect(() => {
		if (item) {
			const {
				date,
				rate,
				description,
				tag,
				hours_representation,
				daily_time
			} = item;
			const duration =
				Number(daily_time) === 1
					? OVERTIME_DURATION[0]
					: Number(daily_time) === 0.5
					? OVERTIME_DURATION[0]
					: OVERTIME_DURATION[2];
			description &&
				props.initialize({
					date: window.moment.unix(date),
					rate,
					hours: window.moment(hours_representation, "HH:mm"),
					description,
					tag,
					duration
				});
		}
	}, []);

	const handleSubmit = formProps => {
		const payload = { ...formProps };
		const { tag } = item;
		const isRateHourly = reportingSettings.rate_type === HOURLY;
		const isCustomDuration = formProps?.duration?.value === CUSTOM;
		const splitedDayHours =
			!isRateHourly && reportingSettings.hours_representation.split(":");
		isEditHasTag &&
			(payload.tag =
				typeof payload.tag === "string"
					? payload.tag
					: _get(payload, "tag.value", ""));
		payload.rate =
			typeof payload.rate === "string" || typeof payload.rate === "number"
				? payload.rate
				: payload.rate.value;

		payload.hours =
			isRateHourly || isCustomDuration || isOverTime
				? formProps.hours.hours() * 60 + formProps.hours.minutes()
				: formProps.duration.value * Number(splitedDayHours[0]) * 60 +
				  formProps.duration.value * Number(splitedDayHours[1]);

		payload.is_overtime = isOverTime;
		if (
			item.date !==
			utcTimeStamp({
				date: payload.date,
				format: DATE_FORMAT,
				manageTime: false
			})
		) {
			const current = window.moment();

			payload.date.set({
				hour: current.get("hour"),
				minute: current.get("minute"),
				second: current.get("second")
			});
		}

		if (beyondMaxHours(payload.date, payload.hours)) {
			return toaster.danger(
				`It seems that you have exceeded the amount of hours (${MAX_HOURS_PER_DAY}h) allowed in the same day
					(${window.moment
						.unix(
							utcTimeStamp({
								date: payload.date,
								format: DATE_FORMAT,
								manageTime: false
							})
						)
						.format(DATE_FORMAT)}).`,
				{ duration: 10, id: "err" }
			);
		}
		payload.date = utcTimeStamp({
			date: payload.date,
			format: DATE_FORMAT,
			manageTime: false
		});

		payload.worktime_id = id;
		payload.hours_representation = window
			.moment(formProps.hours)
			.format("HH:mm");
		if (tag && isEditHasTag && !payload.tag) {
			const errors = {};
			return new Promise((_, reject) => {
				errors.tag = "Tag field can't be empty";
				reject(new SubmissionError(errors));
			});
		}
		props.onUpdateWorkTime(payload);
	};

	const beyondMaxHours = (date, hours) => {
		const worktimes = [
			..._get(month, "worktimes")
				.filter(
					({ _id }) =>
						disputedWorkTime
							.map(({ worktime_id }) => worktime_id)
							.indexOf(_id) < 0
				)
				.filter(({ _id }) => _id !== id),
			...disputedWorkTime
				.filter(({ worktime_id }) => worktime_id !== id)
				.map(item => ({ ...item, hours: item.hours / 60 })),
			...addedWorkTimes.map(item => ({ ...item, hours: item.hours / 60 }))
		];

		let total = hours;

		const worktimesInDate = worktimes.filter(
			worktime =>
				worktime.date >
					utcTimeStamp({
						date: date,
						format: DATE_FORMAT,
						manageTime: true
					}) &&
				worktime.date <
					utcTimeStamp({
						date: date,
						format: DATE_FORMAT,
						manageTime: true,
						isStart: false
					})
		);

		if (!worktimesInDate.length) return false;
		if (worktimesInDate.length === 1) {
			total = total + worktimesInDate[0].hours * 60;
			return total > MAX_HOURS_PER_DAY * 60; // max per day in minutes
		}

		total +=
			worktimesInDate.reduce((acc, curr) => {
				const accVal = typeof acc === "object" ? acc.hours : acc;
				return accVal + curr.hours;
			}) * 60; // total worked hours in minutes
		return total > MAX_HOURS_PER_DAY * 60; // max per day in minutes
	};
	const setHoursByDuration = selectedDuration => {
		const splitedDayHours = reportingSettings.hours_representation.split(":");
		const isHoursOdd = Number(splitedDayHours[0]) % 2 === 1;
		const addedMinutes = isHoursOdd && selectedDuration.value === 0.5 ? 30 : 0;
		var amountOfTime = window.moment().utcOffset(0);
		amountOfTime.set({
			hour: selectedDuration.value * Number(splitedDayHours[0]),
			minute:
				selectedDuration.value * Number(splitedDayHours[1]) + addedMinutes,
			second: 0,
			millisecond: 0
		});
		amountOfTime.toISOString();
		amountOfTime.format();
		if (selectedDuration.value === CUSTOM)
			return dispatch(
				change(FORM_NAME, "hours", window.moment(splitedDayHours[0], "hh"))
			);
		return dispatch(change(FORM_NAME, "hours", amountOfTime));
	};
	const minDate = window.moment.unix(month.start_date).toDate();
	const maxDate = window.moment.unix(month.end_date).toDate();
	const isRateDaily = reportingSettings.rate_type === DAILY;
	const isCustomDuration = formValues?.duration?.value === CUSTOM;
	const MENU_DATA = OVERTIME_DURATION.filter(item => item.value !== CUSTOM);
	return (
		<React.Fragment>
			<Td>
				<FloatItem>
					<Field
						classes="inner-icon left-icon"
						icon="icon-calendar"
						name="date"
						placeholder="Date"
						component={DatePickerField}
						initialVisibleMonth={getInitialVisibleMonth}
						minDate={minDate}
						maxDate={maxDate}
						disabled={disabled}
					/>
				</FloatItem>
			</Td>

			<Td>
				<FloatItem>
					<Field
						type="text"
						placeholder="Description"
						name="description"
						position="bottom"
						disabled={disabled}
						component={FormField}
						autoComplete="off"
					/>
				</FloatItem>
			</Td>

			{isEditHasTag ? (
				<Td>
					<FloatItem width={"100%"}>
						<Field
							placeholder={projectName || "TAG"}
							name="tag"
							disabled={disabled}
							options={
								isClient
									? canApproveTags.length > 0
										? canApproveTags
										: tagOptions
									: tagOptions
							}
							toolTipOverlay={tagOverlayText}
							position="bottom"
							component={StyledSelect}
							menuPosition={isLastItem ? "top" : "bottom"}
						/>
					</FloatItem>
				</Td>
			) : hasTag ? (
				<Td></Td>
			) : null}

			<Td>
				{isRateDaily && !isCustomDuration && !isOverTime && (
					<FloatItem width={"100%"}>
						<Field
							placeholder="Duration"
							name="duration"
							disabled={disabled}
							options={OVERTIME_DURATION}
							position="bottom"
							component={StyledSelect}
							tabIndex={0}
							menuPosition={isLastItem ? "top" : "bottom"}
							onChange={setHoursByDuration}
						/>
					</FloatItem>
				)}
				{(isCustomDuration || !isRateDaily || isOverTime) && (
					<FloatItem
						width={isCustomDuration ? "80%" : "100%"}
						menuPosition={isLastItem ? "top" : "bottom"}
					>
						<Field
							name="hours"
							component={FormField}
							defaultValue={
								isOverTime ? window.moment("1", "hh") : window.moment("8", "hh")
							}
							disabled={disabled}
							type="timepicker"
						/>
					</FloatItem>
				)}
				{isCustomDuration && isRateDaily && !isOverTime && (
					<FloatItem>
						<Menu
							openerNode={
								<div className="menuOpener">
									<ArrowDown />
								</div>
							}
						>
							{({ setOpen }) =>
								MENU_DATA.map(elem => (
									<MenuItem
										key={elem}
										onClick={() => {
											dispatch(change(FORM_NAME, "duration", elem));
											setHoursByDuration(elem);
											setOpen(false);
										}}
									>
										{elem.label}
									</MenuItem>
								))
							}
						</Menu>
					</FloatItem>
				)}
			</Td>
			<Td>
				<FloatItem width={"100%"}>
					<Field
						placeholder="Rate"
						name="rate"
						disabled={disabled}
						options={rateOptions()}
						position="bottom"
						component={StyledSelect}
						defaultValue={rateOptions(100)}
						tabIndex={0}
						menuPosition={isLastItem ? "top" : "bottom"}
					/>
				</FloatItem>
			</Td>
			<Td></Td>
			{!hideAmount && (
				<Td padding="16px 18px 9px 18px" verticalAlign="top">
					{amount}
				</Td>
			)}
			<Td />
			<Td verticalAlign="top" colSpan={2}>
				<Flex sx={{ gap: 2 }}>
					<Button
						disabled={!dirty}
						color="success"
						small
						onClick={props.handleSubmit(handleSubmit)}
						key="accept"
						style={{ width: "100%" }}
					>
						Apply
					</Button>
					<OutlineButton
						style={{ width: "100%" }}
						onClick={props.cancelEdit}
						small
					>
						cancel
					</OutlineButton>
				</Flex>
			</Td>
		</React.Fragment>
	);
};

const validate = formProps => {
	const errors = {};
	if (!formProps.date) {
		errors.date = "Field can't be empty";
	}
	if (!formProps.hours) {
		errors.hours = "Field can't be empty";
	}
	if (!formProps.duration) {
		errors.duration = "Field can't be empty";
	}
	if (!formProps.description || formProps.description.trim() === "") {
		errors.description = "Field can't be empty";
	}
	if (!formProps.rate) {
		errors.rate = "Field can't be empty";
	}
	return errors;
};

const mapStateToProps = state => {
	return {
		editItem: state.timesheet.editItem,
		reportingSettings: _get(state, "timesheet.data.reporting_settings", {}),
		featureTimesheetTags: featureTimesheetTagsSelector(state),
		tagOptions: timesheetTagsSelectSelector(state),
		projectName: timesheetTagsProjectNameSelector(state),
		formValues: state.form[FORM_NAME]?.values || {}
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: FORM_NAME,
		validate,
		touchOnBlur: false
	})
)(EditWorkTime);
