import React from "react";
import cx from "classnames";
import styles from "./open-virtual-room-button.module.scss";
import { Button } from "common/Button";
import { ReactComponent as VideoRecorder } from "static/icons/video-recorder-icon.svg";

const OpenVirtualRoomButton = ({ onClick, copyButton }) => (
	<div
		className={cx(styles.container, styles.grid3Columns, {
			[styles.grid4Columns]: !!copyButton
		})}
	>
		<VideoRecorder />
		<div className={styles.text}>Virtual meeting room</div>
		{copyButton && copyButton}
		<Button
			onClick={onClick}
			text="Join Meeting Room"
			className={styles.enterRoomButton}
		/>
	</div>
);

export default OpenVirtualRoomButton;
