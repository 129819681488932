import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
import {
	filterSelector,
	subSectorsSelector,
	functionsSelector
} from "../selectors";
import { userTypeSelector } from "modules/user/selectors/selectors";
import {
	onlineUserSectorSelector,
	onlineUserSectorsSelector
} from "modules/user/selectors/selectors";
import { languagesSelector } from "../../app/selectors";
import {
	onlineUserSelector,
	searchSelector
} from "../../user/selectors/selectors";
import CompanyDashboard from "../../../common/dashboard/Dashboard";
import * as Actions from "../actions";
import Sidebar from "common/search/Sidebar";
import Topbar from "common/search/Topbar";
import JobList from "./JobList";
import Pagination from "../../../common/Pagination";
import EmptySearch from "common/search/EmptySearch";
import { loadLanguages } from "../../app/actions/ListsActions";
import { Helmet } from "react-helmet";
import ConfirmationModal from "common/modal/ConfirmationModal";

import { PERMANENT } from "config";
import VacanciesSearch from "modules/candidate/components/search";
import { getSectors } from "modules/job/actions/vacancyFunctionsActions";

export const getJobSearchRoute = id => {
	return `/jobs/search${id ? `/${id}` : `(/:id)`}`;
};

export const getJobSearchPermission = () => {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "consultancy",
				roles: ["admin"]
			},
			{
				name: "permanent",
				roles: ["admin"]
			}
		]
	};
};

class JobSearch extends Component {
	constructor(props) {
		super(props);
		this.handlePageClick = this.handlePageClick.bind(this);
		this.handleSkillChange = this.handleSkillChange.bind(this);
	}

	state = {
		displayWarningModal: false
	};

	handlePageClick(selected) {
		const filter = { ...this.props.filter };
		const { search, isLoggedIn } = this.props;
		if (!isLoggedIn && selected > 6) {
			return this.props.toggleOfflinePopup();
		}
		if (filter.offset === search.size * selected) return;
		filter.offset = search.size * selected;
		this.props.updateFilter(filter, true, true);
	}

	handleSkillChange(skill) {
		const { addedSkills } = this.props;
		const filter = { ...this.props.filter };
		this.props.handleSkillChange(skill, addedSkills, filter, true);
	}

	handleDisplayWarningModal = sector => {
		this.setState({
			displayWarningModal: true,
			selectedSector: sector
		});
	};

	closeWarningModal = () => {
		this.setState({
			displayWarningModal: false
		});
	};
	clearSelectedSkills = () => {
		const { filter } = this.props;
		const { selectedSector } = this.state;
		this.props.setSubSectors(selectedSector.children);

		this.props.updateFilter({
			...filter,
			skills: [],
			category: { name: selectedSector.name, _id: selectedSector._id }
		});
		this.props.resetSkills([]);
		this.closeWarningModal();
	};

	render() {
		const {
			addedSkills,
			route: { title },
			params: { term, id },
			search: { data = [] },
			filter,
			sector,
			userType,
			sectors,
			subSectors,
			functions,
			addedLanguages,
			user,
			languages
		} = this.props;

		if (user.type === PERMANENT) {
			return (
				<VacanciesSearch
					profileCompleted={_get(user, "profile_completed")}
					preferencesCompleted={_get(user, "preferences_completed")}
					funcSkillLangCompleted={_get(
						user,
						"functions_skills_languages_completed"
					)}
					isOnline={true}
					user={user}
					vacancyId={id}
				/>
			);
		}
		return (
			<BodyClassName className="jobs gray-bg">
				<>
					<div id="job-search">
						{title && (
							<Helmet>
								<title>{title}</title>
							</Helmet>
						)}
						<CompanyDashboard />
						<Topbar
							filter={filter}
							sector={sector}
							userType={userType}
							sectors={sectors}
							subSectors={subSectors}
							functions={functions}
							getSectors={this.props.getSectors}
							updateFilter={this.props.updateFilter}
							setSubSectors={this.props.setSubSectors}
							getFunctions={this.props.getFunctions}
							handleDisplayWarningModal={this.handleDisplayWarningModal}
						/>
						<div className="container">
							<div className="total-jobs">
								<div className="count">
									{term && `${term} - `}
									{`${this.props.search.total || 0} found`}
								</div>
							</div>
							<div className="row">
								<div className="col-md-3">
									<Sidebar
										filter={filter}
										addedSkills={addedSkills}
										addedLanguages={addedLanguages}
										user={user}
										languages={languages}
										term={term}
										updateFilter={this.props.updateFilter}
										updateSkills={this.props.updateSkills}
										updateLanguages={this.props.updateLanguages}
										loadLanguages={this.props.loadLanguages}
										fetchJobs={this.props.fetchJobs}
										resetFilter={this.props.resetFilter}
										handleSkillChange={this.props.handleSkillChange}
									/>
								</div>
								<div className="col-md-9 search-result">
									{data.length > 0 && (
										<JobList
											jobs={data}
											onSkillClick={this.handleSkillChange}
											selectedSkills={addedSkills}
										/>
									)}
									{data.length === 0 && <EmptySearch />}
									{data.length > 0 && (
										<div className="ac">
											<Pagination
												total={this.props.search.total}
												size={this.props.search.size}
												handlePageClick={this.handlePageClick}
												initialSelected={
													this.props.filter.offset / this.props.search.size
												}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					{this.state.displayWarningModal && (
						<ConfirmationModal
							key="modal"
							title={`Are you sure you want to proceed?`}
							onClose={this.closeWarningModal}
							type="error"
							content={<>Doing this will clear all selected skills.</>}
							firstButton={{
								action: this.clearSelectedSkills,
								type: "danger",
								label: `YES, I'm sure`
							}}
						/>
					)}
				</>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		filter: filterSelector(state),
		sector: onlineUserSectorSelector(state),
		userType: userTypeSelector(state),
		sectors: state.vacancyFunctions.sectors,
		subSectors: subSectorsSelector(state),
		functions: functionsSelector(state),
		search: searchSelector(state).data,
		addedSkills: searchSelector(state).addedSkills,
		addedLanguages: searchSelector(state).addedLanguages,
		user: onlineUserSelector(state),
		languages: languagesSelector(state),
		userSectors: onlineUserSectorsSelector(state)
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{ ...Actions, loadLanguages, getSectors },
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSearch);
