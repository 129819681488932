import React, { useContext, useEffect } from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogTitle
} from "modules/consultancy/search/CreateNewSupplier/Dialog";
import _get from "lodash/get";
import s from "modules/consultancy/search/CreateNewSupplier/create-supplier.module.scss";
import ListContainer from "common/editablelist/Next/ListContainer";

import {
	ListContext,
	LIST_ACTION_TYPES
} from "common/editablelist/Next/listContext";

function SkillsDialog({
	isOpen,
	onClose,
	loadSkills,
	skills,
	initialSkills,
	sector,
	isRequest,
	onSave,
	categories,
	sectors,
	isSearch = false,
	canPropose = true,
	onPropse,
	onCancelButtonClick,
	onScoreChange,
	onDelete,
	onSkillSelect
}) {
	const { state, dispatch } = useContext(ListContext);

	let cats = [];

	if (isSearch && categories.length === 0) {
		cats = sectors;
	} else {
		cats = (sectors || []).reduce((acc, s) => {
			if (categories.find(c => c.sector.parent_sector._id === s._id)) {
				acc.push(s);
			}
			return acc;
		}, []);
	}

	useEffect(() => {
		loadSkills({
			sector: _get(state, "category")
				? _get(state, "category")
				: _get(sector, "sector.parent_sector._id"),
			term: _get(state, "filter")
		});
	}, [_get(state, "filter"), _get(state, "category"), _get(sector, "_id")]);

	const tooltipText = (
		<>
			Marking a skill as <strong>MUST HAVE</strong> will
			{isRequest
				? " prevent freelancers or consultants who don’t have that skill from applying on this request."
				: " exclude the candidates lacking that skill from the potential matches results."}
			You can mark up to 7 skills as <strong>MUST HAVE</strong>.{" "}
		</>
	);

	const setSkillsCategory = category => {
		if (category) {
			dispatch({
				type: LIST_ACTION_TYPES.SET_CATEGORY,
				category: category.value
			});
		}
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent size={"xxl"} onEscapeKeyDown={onClose}>
				<DialogTitle>Skills</DialogTitle>
				<ListContainer
					data={skills}
					cartTitle={"Skills"}
					selected={initialSkills}
					sector={sector}
					tooltipText={tooltipText}
					categories={cats}
					selectedSkillsCategory={_get(state, "category", [])}
					setSkillsCategory={setSkillsCategory}
					showCategories={!isRequest}
					onPropose={onPropse}
					canPropose={canPropose}
					onScoreChange={onScoreChange}
					onDelete={onDelete}
					onSkillSelect={onSkillSelect}
				/>
				<div className={s.dialogFooter}>
					<button
						onClick={() => {
							onClose();
							onCancelButtonClick();
						}}
						className={s.cancelButton}
					>
						Cancel
					</button>
					<button
						type={"submit"}
						className={s.mainButton}
						onClick={() => {
							onSave(state.selected);
						}}
					>
						Save
					</button>
				</div>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default SkillsDialog;
