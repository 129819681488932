import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_COMPANY_LOCATIONS_ENDPOINT } from "config/api-endpoints";

function updateCompanyLocations(body) {
	return client(UPDATE_COMPANY_LOCATIONS_ENDPOINT, { body });
}

function useUpdateCompanyLocations(config) {
	return useMutation(updateCompanyLocations, config);
}

export default useUpdateCompanyLocations;
