import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import styles from "./request-view-head.module.scss";
import { duplicateJob } from "modules/job/actions";
import GlobalTooltip from "common/GlobalTooltip";
import VacancyStatus from "modules/vacancy-view/components/vacancyStatus";
import { getStatusLabel } from "config/helpers";
import { get as _get } from "lodash";
import { formatDate } from "common/Functions";
import { DATE_FORMAT } from "config";
import _has from "lodash/has";
import RequestViewHeadPublishButton from "../RequestViewHeadPublishButton";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReactComponent as InfoOutline } from "static/icons/info-circle.svg";
import RequestDetailsPopup from "../RequestDetailsPopup";
import { TabList, Tab } from "@reach/tabs";
import { REQUEST_VIEW_TABS_IDS } from "modules/RequestView/utils/constants";
import useRequestViewCurrentTab from "modules/RequestView/hooks/useRequestViewCurrentTab";
import RequestViewKanbanSearch from "../RequestViewKanbanSearch";

const maxLength = 40;

const RequestViewHead = ({ job, connectedUser }) => {
	const [tabs] = useState(REQUEST_VIEW_TABS_IDS);
	const { indexTab } = useRequestViewCurrentTab();
	const triggerDetailsPopover = useRef(null);
	const [showRequestDetailsPopup, setShowRequestDetailsPopup] = useState(false);
	const truncate = value =>
		value?.length > maxLength ? `${value.substring(0, maxLength)}...` : value;

	const wasPublished = _has(job, "published_by");
	const published_by = _get(job, "published_by", {});
	const publishedByCreator =
		wasPublished && _get(job, "user_id") === published_by.user_id;

	return (
		<div id="head-request" className={styles.container}>
			{job.title && (
				<Helmet>
					<title>{job.title}</title>
				</Helmet>
			)}

			<div className={styles.header_kanban}>
				<div className="full-container">
					<div className={`${styles.flex} ${styles.spaceBetween}`}>
						<div style={{ position: "relative" }}>
							<div
								className={`${styles.flex} ${styles.alignCenter} ${styles.title_vacancy_container}`}
							>
								<GlobalTooltip
									placement="right"
									overlay={_get(job, "title")}
									active={_get(job, "title", "").length > maxLength}
								>
									<h1 className={styles.jobTtitle}>
										{truncate(_get(job, "title", "")) || "---"}
									</h1>
								</GlobalTooltip>
								<span
									className={styles.infoIcone}
									onClick={() => setShowRequestDetailsPopup(true)}
								>
									<InfoOutline ref={triggerDetailsPopover} />
								</span>
								<VacancyStatus
									status={_get(job, "status")}
									label={getStatusLabel(_get(job, "status"), "value", false)}
									className={styles.requestStatus}
								/>
							</div>
							<div
								className={`${styles.flex} ${styles.info_vacancy} ${styles.alignCenter}`}
							>
								<span
									className={`${styles.flex} ${styles.alignCenter} ${styles.refVacancy}`}
								>
									<span>#{_get(job, "job_ref")}</span>
								</span>{" "}
								<span className={styles.circleSeparator}></span>
								{wasPublished && publishedByCreator
									? "Created and Posted "
									: "Created "}
								{formatDate(_get(job, "created_at"), true, DATE_FORMAT)} by{" "}
								{_get(job, "user_first_name")} {_get(job, "user_last_name")}
							</div>
						</div>
						<RequestViewHeadPublishButton
							connectedUser={connectedUser}
							job={job}
						/>
						{showRequestDetailsPopup && (
							<RequestDetailsPopup
								style={{
									left:
										triggerDetailsPopover.current.getBoundingClientRect().left -
										60,
									position: "absolute",
									width: "640px",
									top: "13px"
								}}
								setVacancyInfoModal={setShowRequestDetailsPopup}
								job={job}
							/>
						)}
					</div>
				</div>
				<div className={styles.tabs_container}>
					<div className={styles.tabs_container_header}>
						<TabList>
							<Tab>Pipeline</Tab>
							<Tab>Performance</Tab>
							<Tab>Request details</Tab>
							<Tab disabled className={styles.disabledTab}>
								Withdrawn/Rejected
							</Tab>
							<Tab disabled>Notes</Tab>
							<Tab disabled>Activities</Tab>
						</TabList>
						{indexTab === tabs.indexOf("pipeline") && (
							<RequestViewKanbanSearch />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			duplicateJob
		},
		dispatch
	);
};
const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestViewHead);
