/* eslint-disable react/display-name*/
import React, { useState } from "react";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import { CreateVacanyAddButton } from "../CreateVacanyAddButton";
import { SkillsPickerModal } from "../SkillsPickerModal";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { useFormContext } from "react-hook-form";
import styles from "./profile-step-skills-field.module.scss";
import { CreateVacancyEditButton } from "../CreateVacancyEditButton";
import { groupSkillsByParent } from "../../utils/helper";

const ProfileStepSkillsField = ({ onSave: onSaveProp, choosenCategories }) => {
	const [displaySkillsModal, setDisplaySkillsModal] = useState(false);
	const { watch } = useFormContext();
	const skillsList = watch("skills");
	const choosenCategoriesList = watch("categories");

	const handleAddSkillsButtonClick = () => setDisplaySkillsModal(true);

	const handleSkillsModalCancelClick = () => setDisplaySkillsModal(false);

	const handleSkillsModalEscapeKeyDown = () => setDisplaySkillsModal(false);

	const onSave = list => {
		setDisplaySkillsModal(false);
		onSaveProp(list);
	};

	const chooseCategories = (choosenCategoriesList || []).map(
		({ category }) => category.value
	);

	let skills = [];

	groupSkillsByParent(skillsList)?.forEach((value, key) => {
		skills.push(
			<div key={key} className={styles.skillsContainer}>
				<div className={styles.categoryLabel}>{key}</div>
				<div className={styles.skillsList}>
					{value?.map(({ _id, name, proficiency, mustHave }) => (
						<div key={_id} className={styles.skillTag}>
							{name}
							{mustHave && "*"} ({proficiency}/5)
						</div>
					))}
				</div>
			</div>
		);
	});

	return (
		<CreateVacancyFieldContainer>
			<CreateVacancyLabelContainer>
				<CreateVacancyFieldLabel>Skills</CreateVacancyFieldLabel>
				<CreateVacancyInfoIcon>
					The skills are an essential part of the candidate/vacancy matching
					process. Be as precise as possible (without being too demanding) in
					the selection of the skills needed and the weight/importance they have
					for the role.
				</CreateVacancyInfoIcon>
			</CreateVacancyLabelContainer>
			{skillsList && <div className={styles.skillsRows}>{skills}</div>}

			{skillsList?.length > 0 ? (
				<CreateVacancyEditButton onClick={handleAddSkillsButtonClick} />
			) : (
				<CreateVacanyAddButton onClick={handleAddSkillsButtonClick} />
			)}
			{displaySkillsModal && (
				<SkillsPickerModal
					onCancel={handleSkillsModalCancelClick}
					onEscapeKeyDown={handleSkillsModalEscapeKeyDown}
					chooseCategories={chooseCategories}
					skillsList={skillsList}
					onSave={onSave}
					choosenCategories={choosenCategories}
				/>
			)}
		</CreateVacancyFieldContainer>
	);
};

export default ProfileStepSkillsField;
