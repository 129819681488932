import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GlobalTooltip from "common/GlobalTooltip";
import SettingsSwitcher from "modules/notifications/components/settings/SettingsSwitcher";
const ClearButtonContainer = styled.div`
	width: 23px;
	display: table-cell;
	vertical-align: middle;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(-50%, -50%);
	.icon-reset-3 {
		font-size: 25px;
		color: #a5b1bb;
		&:hover,
		&:focus {
			color: #929da6;
		}
	}
`;
const ClearButton = styled.button`
	color: white;
	width: 23px;
	border-radius: 50%;
	padding: 0;
	border: none;
	outline: none;
	display: flex;
	justify-content: center;
	background: #f4f5f8;

	&:hover {
		background: #f4f5f8;
	}
`;
const SearchBar = ({
	searchValue,
	onChange,
	clearable,
	controlClear,
	clearSearch,
	setMetadataItem,
	keywordSearch,
	keywordError,
	hasKeywordSearch = true,
	placeholder
}) => {
	const placeHolderText = keywordSearch
		? "Start typing... (Boolean strings OK)"
		: "Start typing...";
	return (
		<>
			<div className="form-group">
				<span className="icon-search"></span>
				<input
					value={searchValue}
					name="search.value"
					onChange={e => {
						onChange(e, null, false);
					}}
					type="text"
					className={`form-control ${keywordError && "input-error"}`}
					placeholder={placeholder || placeHolderText}
				/>
				{hasKeywordSearch && (
					<>
						{" "}
						<span className="help-block inline-error">
							{keywordError && keywordError}
						</span>
						<div className="checkbox checkbox-primary">
							<div
								className="input-box"
								style={{ width: "100%", display: "flex" }}
							>
								<label
									htmlFor={"keyword"}
									style={{
										padding: 0,
										fontFamily: "BasierCircle",
										fontSize: "13px",
										color: "rgb(15, 28, 70)",
										textTransform: "none",
										marginRight: "16px"
									}}
								>
									Search by Keywords{" "}
									<GlobalTooltip
										placement="top"
										overlay={"Search by keywords using Boolean strings"}
									/>
								</label>
								<SettingsSwitcher
									checked={keywordSearch}
									onChange={() => {
										setMetadataItem(
											"keywordSearch",
											keywordSearch ? undefined : true
										);
									}}
									name={"keywordSearch"}
									classes="toggle-keywordSearch"
								/>
							</div>
						</div>
					</>
				)}{" "}
				{clearable && (searchValue || controlClear) && (
					<ClearButtonContainer>
						<ClearButton
							type="button"
							onClick={() => {
								clearSearch
									? clearSearch()
									: controlClear
									? onChange("search.value", "", true)
									: onChange("search.value", "", false);
								hasKeywordSearch &&
									setMetadataItem("keywordSearch", keywordSearch);
							}}
						>
							<span className="icon-reset-3"></span>
						</ClearButton>
					</ClearButtonContainer>
				)}
			</div>
		</>
	);
};
SearchBar.propTypes = {
	searchValue: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	searchKeyword: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	showTitle: PropTypes.bool,
	clearable: PropTypes.bool,
	controlClear: PropTypes.bool,
	clearSearch: PropTypes.func
};
SearchBar.defaultProps = {
	searchValue: "",
	changeHandler: () => {},
	showTitle: true,
	clearable: false
};
export default memo(SearchBar);
