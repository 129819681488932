import React from "react";
import styles from "../../CommunityCriteria/DeleteModal/styles.module.scss";
import _get from "lodash/get";
import toaster from "common/Toaster";
import useHidePremanently from "../../../api/useHidePremanently";
import get from "lodash/get";
import CustomizableModel from "common/CustomizableModel/CustomizableModel";
import HideButton from "./HideButton";

const DeleteModal = ({ candidate, onClose, refetch }) => {
	const [mutate, { isLoading }] = useHidePremanently({
		onSuccess: () => {
			toaster.success("Profile had been hidden permanently!");
			onClose();
			refetch?.();
		},
		onError: error => {
			toaster.danger(
				_get(error, "detail.name") ? error.detail.name : error.detail
			);
		}
	});

	const hidePermanentlyHandler = id => {
		mutate({ profile_id: id });
	};

	return (
		<CustomizableModel
			title="Delete candidate"
			actionButton={
				<HideButton
					onClick={() => hidePermanentlyHandler(get(candidate, "id", ""))}
					isLoading={isLoading}
				/>
			}
			onClose={onClose}
		>
			<p className={styles.modal__text}>
				Are you sure you want to Hide :{" "}
				<span className={styles.modal__criteria}>
					{get(candidate, "name", "")}
				</span>
			</p>
		</CustomizableModel>
	);
};

export default DeleteModal;
