import React, { useState } from "react";
import { FILTER_SETTING_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useDebounce } from "react-use";
import styles from "common/CustomInput/custom-input.module.scss";
import { formatDate } from "common/Functions";
import { capitalize } from "config/helpers";

export const formatMissionsList = list => {
	return list.map(item => {
		return {
			id: item._id,
			Id: item._id,
			"Mission title": item.job.title,
			"Mission reference": item.contract_ref,
			Department: item.group.name,
			Consultant: `${item.vendor.first_name} ${item.vendor.last_name}`,
			"Profile type": item.vendor.type && capitalize(item.vendor.type),
			"Rate type":
				item.reporting_settings.rate_type &&
				capitalize(item.reporting_settings.rate_type),
			Rate: item.price,
			Cost: item.cost,
			Progress: item.progress,
			"Start date": formatDate(item.start_date),
			"End date": formatDate(item.end_date),
			"Timesheet frequency":
				item.reporting_settings.timesheet_frequency &&
				capitalize(item.reporting_settings.timesheet_frequency),
			Extensions: `${item.number_extension}`,
			Onboarded: item.onboarded ? "Yes" : "No",
			Status: item.status,
			"External Manager": item.invoice_details?.company_timesheet_manager_email,
			"Accounting Manager": item.invoice_details?.accounting_manager_email,
			"PO Number": item?.po_number,
			"Selling price": item.invoice_details?.selling_price,
			item: item
		};
	});
};
const fetchData = ({ tag, search = "", type = "missions" }) => {
	return client(FILTER_SETTING_ENDPOINT, {
		body: {
			list: type,
			tag,
			search
		}
	});
};

export const getDepartmentsList = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "missions_department", search })
			.then(res => {
				const itemsFormatted = res.missions_department.data.map(
					({ name, _id }) => ({
						label: name,
						id: _id
					})
				);

				resolve([{ id: 1, label: "Department", children: itemsFormatted }]);
			})
			.catch(e => reject(e));
	});
};
export const getStatus = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "mission_status", search })
			.then(res => {
				const itemsFormatted = res.mission_status.map(({ label, value }) => ({
					label: label,
					id: value
				}));

				resolve([{ id: 1, label: "Status", children: itemsFormatted }]);
			})
			.catch(e => reject(e));
	});
};

export const getProfileType = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "profile_type", search })
			.then(res => {
				const itemsFormatted = res.map(({ label, value }) => ({
					label: label,
					id: value
				}));

				resolve([{ id: 1, label: "Profile type", children: itemsFormatted }]);
			})
			.catch(e => reject(e));
	});
};

export const getTimesheet_frequency = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "timesheet_frequency", search })
			.then(res => {
				const itemsFormatted = res.timesheet_frequency.map(
					({ label, value }) => ({
						label: label,
						id: value
					})
				);

				resolve([
					{ id: 1, label: "Timesheet frequency", children: itemsFormatted }
				]);
			})
			.catch(e => reject(e));
	});
};

export const getOnboarded = () => {
	return [
		{
			id: 1,
			label: "Onboarded",
			children: [
				{
					label: "Yes",
					id: "true"
				},
				{
					label: "No",
					id: "false"
				}
			]
		}
	];
};

export const getRateType = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "rate_type", search })
			.then(res => {
				const itemsFormatted = res.rate_type.map(({ label, value }) => ({
					label: label,
					id: value
				}));

				resolve([{ id: 1, label: "Rate_type", children: itemsFormatted }]);
			})
			.catch(e => reject(e));
	});
};

export const NumberInputComponent = ({ data, min = 0, max }) => {
	const [number, setNumber] = useState(data?.value);

	useDebounce(
		() => {
			handleNumberChange(number);
		},
		1000,
		[number]
	);

	const handleNumberChange = value => {
		data?.onChange(value);
	};

	return (
		<input
			type="number"
			onChange={event => {
				const value = Number(event.target.value);
				if (max == 100 && value >= 100) return setNumber(100);
				return setNumber(value);
			}}
			value={number}
			className={styles.input}
			{...data?.propsRest}
			min={min}
			max={max}
		/>
	);
};

export const isMissionsListFiltersFull = filters => {
	let list = [];
	if (filters) {
		list = filters.filter(({ value }) => !isMissionsListFilterEmpty(value));
	}
	return list.length > 0;
};
export const isMissionsListFilterEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};
