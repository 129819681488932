import { browserHistory } from "react-router";
import * as types from "./actionsTypes";
import axios from "config/axios";
import {
	LIST_CATEGORIES,
	LIST_FUNCTIONS,
	CREATE_NEW_JOB,
	EDIT_JOB,
	GET_LAST_JOB,
	LIST_SKILLS,
	CREATE_NEW_JOB_PRICING,
	MY_JOBS_LIST,
	CLONE_JOB,
	DELETE_JOB,
	POST_JOB,
	UNPUBLISH_JOB,
	GROUP_LIST,
	FILL_JOB,
	GET_JOBS_BY_REQUEST,
	CLOSE_JOB,
	GET_CURRENCIES,
	GET_CHANNELS_PERFORMANCE
} from "config/api-endpoints";
import {
	HTTP_POST_METHOD,
	RESPONSE_OK,
	ARCHIVE_REQUEST,
	PAUSE_REQUEST,
	PUBLICATION_LENGTH,
	REQUEST,
	VACANCY,
	JOB,
	CLOSED_TO_NEW_APPLICANTS
} from "config";
import {
	getJobProfileRoute,
	getJobRequestRoute,
	getJobPreviewRoute,
	getVacancyRequestRoute,
	getVacancyPreviewRoute,
	getVacancyViewRoute
} from "../components";
import AxiosHelper from "config/axios-helper";
import { getJobListContainerRoute } from "../components/joblist/jobListContainer";
import { getNewVacancyProfileRoute } from "modules/vacancy/create-vacancy";
import { getCreateRequestRoute } from "modules/CreateRequestModule/CreateRequestModule";
import { historyPush } from "config/helpers";

export function toggleFetching() {
	return {
		type: types.TOGGLE_FETCHING
	};
}

export function setGroups(groups) {
	return {
		type: types.SET_GROUPS,
		groups
	};
}

export function setCategories(categories) {
	return {
		type: types.SET_CATEGORIES,
		categories
	};
}

export function setFunctions(functions) {
	return {
		type: types.SET_FUNCTIONS,
		functions
	};
}

export function setScore(item, score, field) {
	return {
		type: types.SET_SCORE,
		item,
		score,
		field
	};
}

export function setJobData(data) {
	return {
		type: types.SET_JOB_DATA,
		data
	};
}

export function resetJobData() {
	return {
		type: types.RESET_JOB_DATA
	};
}

export function setJobDataField(data, field) {
	return {
		type: types.SET_JOB_DATA_FIELD,
		data,
		field
	};
}

export function setJobsList(data) {
	return {
		type: types.SET_LIST_JOBS,
		data
	};
}

function setJobsListFilter(status) {
	return {
		type: types.FILTER_BY_STATUS,
		status
	};
}

export function filterJobsList(payload, status) {
	return dispatch => {
		dispatch(setJobsListFilter(status));
		dispatch(getJobsList(payload));
	};
}

export function publishRequest(id, step, type) {
	let link;
	link =
		step !== 3
			? type === VACANCY
				? getVacancyRequestRoute(id)
				: getJobRequestRoute(id)
			: `/${type || JOB}/${id}`;

	browserHistory.push(link);
}

export const publishRequestWarning = job => ({
	type: types.PUBLISH_JOB_WARNING,
	job
});

export function editJob(id) {
	historyPush(getCreateRequestRoute(id));
}

export function editVacancy(id) {
	browserHistory.push(getNewVacancyProfileRoute(id));
}

export function getCategories() {
	return AxiosHelper.__post({
		url: LIST_CATEGORIES,
		next: setCategories
	});
}

export function getGroups({ type = "jobs", ...rest } = {}) {
	return async dispatch => {
		try {
			dispatch(toggleFetching());
			const { status, data } = await axios({
				url: GROUP_LIST,
				method: HTTP_POST_METHOD,
				data: JSON.stringify({ type, ...rest })
			});
			if (status === RESPONSE_OK) {
				dispatch(setGroups(data));
				dispatch(toggleFetching());
			}
		} catch (error) {
			dispatch(toggleFetching());
		}
	};
}

export function getFunctions(payload) {
	return async dispatch => {
		try {
			dispatch(toggleFetching());
			const { status, data } = await axios({
				method: HTTP_POST_METHOD,
				url: LIST_FUNCTIONS,
				data: JSON.stringify(payload)
			});
			if (status === RESPONSE_OK) {
				dispatch(setFunctions(data));
				dispatch(toggleFetching());
			}
		} catch (error) {
			dispatch(toggleFetching());
		}
	};
}

export function createJob(job, edit, reduxFormName) {
	const source = job.job_type;
	const nextRoute =
		source === REQUEST ? getJobRequestRoute : getVacancyRequestRoute;
	const postObj = {
		url: edit ? EDIT_JOB : CREATE_NEW_JOB,
		toastMessage: edit
			? `Your ${source} has successfully been edited.`
			: `Your ${source} has successfully been saved as a draft.`,
		newRoute: nextRoute(job.id || ""),
		data: job,
		reduxFormName
	};
	if (!edit) postObj.next = () => setJobData(job);
	return AxiosHelper.__post(postObj);
}

export function createJobStep2(job, edit, reduxFormName) {
	const nextRoute =
		job.job_type === REQUEST ? getJobPreviewRoute : getVacancyPreviewRoute;
	return AxiosHelper.__post({
		url: edit ? EDIT_JOB : CREATE_NEW_JOB_PRICING,
		data: job,
		reduxFormName,
		next: () => dispatch => {
			dispatch(setJobData(job));
			browserHistory.push(nextRoute(job.id));
			return job;
		}
	});
}

export function getLastJob(payload = {}, changeRoute = false, type, viewRoute) {
	if (type) payload = { ...payload, job_type: type };
	return dispatch =>
		dispatch(
			AxiosHelper.__post({
				url: GET_LAST_JOB,
				data: payload,
				inAction: false,
				next: data => dispatch => {
					dispatch(setJobData(data));
					if (changeRoute && data && data.id) {
						browserHistory.push(
							type === VACANCY
								? viewRoute
									? getVacancyViewRoute(data.id)
									: getNewVacancyProfileRoute(data.id)
								: getJobProfileRoute(data.id)
						);
					}
					return data;
				}
			})
		).catch(({ status }) => {
			if (status === 403) {
				browserHistory.push(getJobListContainerRoute());
			}
		});
}

export function setSkills(skills) {
	return {
		type: types.SET_SKILLS,
		data: skills.data || []
	};
}

export function getSkills(payload = {}) {
	return AxiosHelper.__post({
		url: LIST_SKILLS,
		data: payload,
		next: setSkills,
		isModal: true
	});
}

export function getJobsList(payload) {
	return AxiosHelper.__post({
		data: { ...payload, job_type: payload.job_type || REQUEST },
		url: MY_JOBS_LIST,
		next: setJobsList
	});
}

export function duplicateJob(job_id, type) {
	return AxiosHelper.__post({
		url: CLONE_JOB,
		data: { job_id },
		toastMessage: `Your ${type} has successfully been duplicated.`,
		next: () => getLastJob({}, true, type)
	});
}

export function stopJobModal(data = {}) {
	return {
		type: types.UNPUBLISH_JOB,
		data
	};
}

export function stopJob(payload, mode, type) {
	const archive = mode === ARCHIVE_REQUEST;
	const unpublished = mode === PAUSE_REQUEST;
	const closeJob = mode === CLOSED_TO_NEW_APPLICANTS;
	return AxiosHelper.__post({
		data: payload,
		next: () => stopJobModal(),
		isModal: true,
		url: archive
			? DELETE_JOB
			: unpublished
			? UNPUBLISH_JOB
			: closeJob
			? CLOSE_JOB
			: FILL_JOB,
		toastMessage: `Your ${
			type ? type : "mission request"
		} has successfully been ${
			archive
				? "archived"
				: unpublished
				? "unpublished"
				: closeJob
				? "closed to new applications"
				: "marked as filled"
		}.`
	});
}

export function publishRequestAction(id, afterWarn = false, type, version) {
	let payload = {};
	if (type === VACANCY) {
		payload = {
			data: {
				id,
				status: "opened",
				publish_version: version
			}
		};
	} else {
		payload = {
			data: {
				id,
				publication_length: PUBLICATION_LENGTH
			},
			newRoute: getJobListContainerRoute()
		};
	}
	return AxiosHelper.__post({
		...payload,
		url: POST_JOB,
		isModal: true,
		toastMessage: `Your ${type ? type : "request"} has been published.`,
		next: () => afterWarn && publishRequestWarning(null),
		//TODO: use object deconstructing here.
		afterNext:
			type === VACANCY ? () => getLastJob({ id }, true, type, true) : null
	});
}

export function showModal() {
	return {
		type: "SHOW_FUNCTIONS_MODAL"
	};
}

export function getBidsList(job_id) {
	return AxiosHelper.__post({
		url: GET_JOBS_BY_REQUEST,
		data: { id: job_id },
		next: data => setBidsList(data.application_list, data.total_bids)
	});
}

export function setBidsList(list, total) {
	return {
		type: types.SET_BIDS_LIST,
		list,
		total
	};
}

export function getListCurrencies() {
	return AxiosHelper.__post({
		url: GET_CURRENCIES,
		data: {},
		next: data => setCurrenciesList(data)
	});
}

export function setCurrenciesList(data) {
	return {
		type: types.SET_LIST_CURRENCIES,
		data
	};
}

export function setCampaignsPerformance(data) {
	return {
		type: types.SET_CAMPAIGNS_PERFORMANCE,
		data
	};
}

export function getCampaignsPerformance(job_id) {
	return AxiosHelper.__post({
		url: GET_CHANNELS_PERFORMANCE,
		data: { id: job_id },
		next: data => setCampaignsPerformance(data)
	});
}
