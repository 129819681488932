import React, { useMemo } from "react";
import Modal from "common/modal";
import { Box, Text } from "rebass";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQuery, useMutation, queryCache } from "react-query";
//import { useDispatch } from "react-redux";
import _get from "lodash/get";
import Select from "common/react-hook-form-components/Select/index.js";
import s from "modules/vacancy-view/components/withdrawnRejected/withdrawn-rejected.module.scss";
import {
	KANBAN_VIEW_SETTING,
	REQUALIFY_APPLICATION
} from "config/api-endpoints";
import { client } from "lib/api-client";
//import { fetchPoke } from "modules/vacancy/actions";
import { getPokeDataKey } from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";
import { mockDataKanbanSettings } from "modules/user-onboarding/workflows/vacancy-pipeline/mockData";
import { TOUR_VACANCY_PIPELINE } from "config/onboarding";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getKanbanViewSetting = (key, { job_id }) =>
	client(KANBAN_VIEW_SETTING, {
		body: {
			job_id
		}
	});
const requalifyApplication = ({ application_ids, vacancy_id, step_id }) =>
	client(REQUALIFY_APPLICATION, {
		body: {
			application_ids,
			vacancy_id,
			step_id
		}
	});

const useTourData = () => {
	const { workflowId } = useOnboardingStore();
	const isActiveVacancyPipelineTour = useMemo(
		() => workflowId === TOUR_VACANCY_PIPELINE,
		[workflowId]
	);

	const getKanbanViewSettingMockData = () =>
		new Promise(function(resolve) {
			resolve([...mockDataKanbanSettings]);
		});

	return {
		getKanbanViewSettingMockData,
		isActiveVacancyPipelineTour
	};
};

const RequalifyRejected = ({
	show,
	setShow,
	job,
	application_id,
	first_name,
	onSuccess
}) => {
	const {
		getKanbanViewSettingMockData,
		isActiveVacancyPipelineTour
	} = useTourData();

	//const dispatch = useDispatch();
	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm({
		shouldUnregister: true,
		mode: "onBlur",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true
	});

	const handleClose = () => {
		setShow(false);
	};

	const { data = [], isLoading } = useQuery(
		["getKanbanViewSetting", { job_id: job._id }],
		isActiveVacancyPipelineTour
			? getKanbanViewSettingMockData
			: getKanbanViewSetting,
		{
			refetchOnWindowFocus: false,
			enabled: !!job._id
		}
	);

	const [requalify] = useMutation(requalifyApplication, {
		onSuccess: () => {
			if (onSuccess) {
				onSuccess();
			}
			if (window.location.href.includes("/vacancy/")) {
				queryCache.invalidateQueries("RejectedList");
				return queryCache.invalidateQueries("getDataPipeline");
			} else return queryCache.invalidateQueries(getPokeDataKey);
		}
	});
	const options = Boolean(data.length)
		? data
				.filter(
					stepName => stepName.type != "application" && stepName.type != "hired"
				)
				.map(opt => ({
					label: opt.name,
					value: opt.name,
					code: opt.name,
					id: opt._id
				}))
		: [];

	return (
		<Modal style={{ borderRadius: "8px" }} active={show} onClose={handleClose}>
			<Box
				sx={{
					display: "block",
					margin: "0 0 20px 0",
					width: "fit-content",
					fontFamily: "basiercircle",
					fontSize: "20px",
					fontWeight: "normal",
					color: "black"
				}}
			>
				Requalification
			</Box>
			<div
				style={{
					height: "2px",

					backgroundColor: "#e1e3e5"
				}}
			/>
			<Text
				mb="25px"
				mt="25px"
				sx={{
					fontSize: "14px",
					fontFamily: "basiercircle",
					fontWeight: "normal",
					lineHeight: "20px",
					color: "black"
				}}
			>
				Do you want to requalify {first_name} and send him/her back to the
				selected step!
			</Text>
			<Controller
				control={control}
				name="requalification"
				render={({ field: { onChange, value, name } }) => (
					<>
						<Select
							className={s.select}
							style={{
								width: "350px",
								marginBottom: "25px",
								color: "transparent"
							}}
							placeholder="Select a step..."
							options={options}
							onChange={onChange}
							name={name}
							isLoading={isLoading}
							value={value}
							error={_get(errors, "requalification")}
						/>
					</>
				)}
			/>
			<div
				style={{
					height: "2px",
					backgroundColor: "#e1e3e5",
					marginTop: "25px",
					marginBottom: "25px"
				}}
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end"
				}}
			>
				<button
					onClick={handleClose}
					style={{
						fontSize: "14px",
						color: "black",
						backgroundColor: "white",
						height: "36px",
						width: "77px",
						borderRadius: "4px",
						border: "1px solid lightgray",
						textAlign: "center",
						marginRight: "15px"
					}}
				>
					Cancel
				</button>
				<button
					style={{
						fontSize: "14px",
						color: "white",
						backgroundColor: "#f9463b",
						height: "36px",
						width: "94px",
						borderRadius: "4px",
						border: "1px solid #ff6849",
						textAlign: "center",
						fontWeight: "600"
					}}
					onClick={handleSubmit(({ requalification }) => {
						setShow(false),
							requalify({
								application_ids: [application_id],
								vacancy_id: job._id,
								step_id: requalification.id
							});
					})}
				>
					Requalify
				</button>
			</div>
		</Modal>
	);
};
export default RequalifyRejected;
const schema = yup.object().shape({
	requalification: yup.object().required("Please select a step")
});
