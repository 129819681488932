import React from "react";
import styles from "./custom-text-area.module.scss";
import cx from "classnames";

const CustomTextArea = ({ value, onChange, className, placeholder }) => {
	return (
		<textarea
			className={cx(styles.texarea, className)}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
		/>
	);
};

export default CustomTextArea;
