import React from "react";
import { ReactComponent as ChromeIcon } from "./icons/chrome.svg";
import styles from "./style.module.scss";
import cx from "classnames";

const ChromeBanner = ({ onClose }) => {
	return (
		<div className={cx(styles.banner, "chrome-banner")}>
			<ChromeIcon className={styles.icon} />
			<div className={styles.message}>
				For optimal experience on Wiggli, please make sure to use Chrome as a
				browser
			</div>
			<a
				href="https://www.google.com/chrome"
				target="_blank"
				rel="noopener noreferrer"
				className={styles.link}
			>
				Download google chrome
			</a>
			<button className={styles.close_btn} onClick={onClose}>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M18 6L6 18M6 6L18 18"
						stroke="#9CA3AF"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
		</div>
	);
};
export default ChromeBanner;
