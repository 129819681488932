import React from "react";
import { ReactComponent as CloseIcon } from "static/icons/close-icon-black.svg";
import styles from "./send-request-drawer-head.module.scss";

const FilterDrawerHead = ({ title, onClose }) => {
	return (
		<div className={styles.container}>
			<div className={styles.title}>{title}</div>
			<div className={styles.imgContainer}>
				<div className={styles.icon} onClick={onClose}>
					<CloseIcon width="15px" height="15px" />
				</div>
			</div>
		</div>
	);
};

export default FilterDrawerHead;
