import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { MULTIPLE_INVITATION_TO_SUPPLIERS_LIST } from "config/api-endpoints";

const multipleInvitations = data => {
	return client(MULTIPLE_INVITATION_TO_SUPPLIERS_LIST, {
		body: { ...data }
	});
};

const useInviteSuppliers = (options, queryOptions = {}) =>
	useMutation(multipleInvitations, {
		...queryOptions
	});
export default useInviteSuppliers;
