export const validFiltersCount = filters => {
	let valid_filters = [];
	if (filters) {
		valid_filters = filters.filter(filter => {
			return !isEmpty(filter.value);
		});
		return valid_filters.length;
	}
	return 0;
};

export const isEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};
