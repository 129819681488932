import React, { Component } from "react";
import { connect } from "react-redux";
import JobList from "./jobList";
import {
	getJobsList,
	filterJobsList,
	resetJobData,
	getBidsList
} from "../../actions";
import { totalBidsSelector, bidsListSelector } from "../../selectors";

export const getJobListContainerRoute = () => {
	return "/jobs/list";
};
export const getJobListContainerPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

class jobListContainer extends Component {
	render() {
		const {
			list_data,
			route,
			resetJobData,
			getJobsList,
			filterJobsList,
			stopJob,
			getBidsListByJob,
			bidsList,
			totalBids
		} = this.props;

		return (
			<JobList
				route={route}
				list_data={list_data}
				getJobsList={getJobsList}
				filterJobsList={filterJobsList}
				resetJobData={resetJobData}
				stopJob={stopJob}
				getBidsListByJob={getBidsListByJob}
				totalBids={totalBids}
				bidsList={bidsList}
			/>
		);
	}
}

const mapStateToProps = state => {
	//TODO add selector
	return {
		list_data: state.job.list_data,
		stopJob: state.job.stopJob,
		bidsList: bidsListSelector(state),
		totalBids: totalBidsSelector(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		resetJobData: () => dispatch(resetJobData()),
		getJobsList: payload => dispatch(getJobsList(payload)),
		filterJobsList: (payload, status) =>
			dispatch(filterJobsList(payload, status)),
		getBidsListByJob: id => dispatch(getBidsList(id))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(jobListContainer);
