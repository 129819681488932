import create from "zustand";
const initialState = {
	temporaryTab: "new_bid",
	startDate: "",
	endDate: "",
	isSettedDate: false,
	new_contract: [],
	new_bid: [],
	upcoming_starters: [],
	upcoming_finishers: [],
	request: []
};
const useTemporaryStore = create(set => ({
	...initialState,
	setItem: (key, value) => set({ [key]: value }),
	reset: () => set({ ...initialState })
}));

export default useTemporaryStore;
