import { useQuery } from "react-query";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getSitesList = () => {
	return client(NEW_FILTER_SETTINGS, {
		body: { list: "vacancies", tag: "site_list" }
	});
};

export const GET_SITES_LIST_QUERY_KEY = "GET_SITES_LIST_QUERY_KEY";

export const useGetSitesList = () => {
	const res = useQuery(GET_SITES_LIST_QUERY_KEY, getSitesList, {
		refetchOnWindowFocus: false,
		staleTime: Infinity
	});

	return res;
};
