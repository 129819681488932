import React, { useState, useEffect } from "react";
import Avatar from "common/Avatar/index";
import { get } from "lodash";
import { SkeletonTheme } from "react-loading-skeleton";
import toast from "react-hot-toast";
import { useBreadCrumbs } from "hooks/useBreadCrumb";
import styles from "./gdpr-permanent-view.module.scss";
import { BREADCRUMBS_ITEMS, CONVERSATIONS_TAB_ID } from "./utils/constants";
import { ReactComponent as PhoneIcon } from "static/icons/phone-grey.svg";
import { ReactComponent as LetterIcon } from "static/icons/letter-grey.svg";
import { ReactComponent as SuitCaseIcon } from "static/icons/suitcase-grey.svg";
import { ReactComponent as LinkedinIcon } from "static/icons/linkedin-icon.svg";
import { Button } from "common/Button";
import { addHashToUrl, getUrlHash, goBack, historyPush } from "config/helpers";
import { ReactComponent as ArrowLeftIcon } from "static/icons/arrow-left-icon.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-icon-grey.svg";
import { ReactComponent as TrashIconRed } from "static/icons/trashIcon.svg";
import cx from "classnames";
import useDeleteGdprProfiles from "./api/useDeleteGdprProfile";

GdprPermanentViewSkeletonCard;
import {
	TABS,
	PROFILE_TAB_ID,
	EVALUATION_TAB_ID,
	PROCESSES_TAB_ID,
	INTERACTIONS_TAB_ID,
	FILES_TAB_ID,
	NOTES_TAB_ID
} from "./utils/constants";
import loadable from "loadable-components";
import useGetGdprPermanentInformations from "./api/useGetGdprPermanentInformations";
import { getGdprListRoute } from "modules/GdprList/GdprList";
import { GdprPermanentViewSkeletonCard } from "./components/GdprPermanentViewSkeletonCard";

export const getGdprPermanentViewRoute = id =>
	`/gdpr/permanent/${id !== undefined ? `${id}` : `:id`}`;

export const getGdprPermanentViewPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const DeleteGdprCandidateModal = loadable(() =>
	import(
		/* webpackChunkName: "pagescms" */ "./components/DeleteGdprCandidateModal/DeleteGdprCandidateModal"
	)
);
const GdprPermanentProfileTab = loadable(() =>
	import(
		/* webpackChunkName: "pagescms" */ "./components/GdprPermanentProfileTab/GdprPermanentProfileTab"
	)
);
const GdprPermanentEvaluationsTab = loadable(() =>
	import(
		/* webpackChunkName: "pagescms" */ "./components/GdprPermanentEvaluationsTab/GdprPermanentEvaluationsTab"
	)
);
const GdprPermanentProcessesTab = loadable(() =>
	import(
		/* webpackChunkName: "pagescms" */ "./components/GdprPermanentProcessesTab/GdprPermanentProcessesTab"
	)
);
const GdprPermanentInteractionsTab = loadable(() =>
	import(
		/* webpackChunkName: "pagescms" */ "./components/GdprPermanentInteractionsTab/GdprPermanentInteractionsTab"
	)
);
const GdprPermanentNotesTab = loadable(() =>
	import(
		/* webpackChunkName: "pagescms" */ "./components/GdprPermanentNotesTab/GdprPermanentNotesTab"
	)
);
const GdprPermanentFilesTab = loadable(() =>
	import(
		/* webpackChunkName: "pagescms" */ "./components/GdprPermanentFilesTab/GdprPermanentFilesTab"
	)
);
const GdprPermanentConversationsTab = loadable(() =>
	import(
		/* webpackChunkName: "pagescms" */ "./components/GdprPermanentConversationsTab/GdprPermanentConversationsTab"
	)
);

const GdprPermanentView = ({ params: { id: permanentId } }) => {
	const {
		isLoading,
		data: permanentInformations
	} = useGetGdprPermanentInformations(permanentId);

	const [actifTab, setActifTab] = useState(TABS[0].id);
	useBreadCrumbs(BREADCRUMBS_ITEMS);

	const [deleteProfiles] = useDeleteGdprProfiles({
		onSuccess: () => {
			toast("Candidate deleted!", {
				duration: 2500,
				icon: <TrashIconRed />
			});
			historyPush(getGdprListRoute());
		},
		onError: () => {
			toast("Error", { isFailed: true });
		}
	});

	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	useEffect(() => {
		const id = getUrlHash();
		if (id) {
			setActifTab(id);
		}
	}, []);

	const onDelete = () => {
		setOpenConfirmationModal(true);
	};

	const onBack = () => goBack();

	const onTabClick = id => {
		setActifTab(id);
		addHashToUrl(id);
	};

	const onModalClose = () => {
		setOpenConfirmationModal(false);
	};

	const onDeleteConfirm = () => {
		const body = {
			include_ids: [],
			exclude_ids: [],
			selected_ids: [permanentId],
			filter_data: {}
		};

		deleteProfiles(body);
		setOpenConfirmationModal(false);
	};

	const getPhoneNumber = phone => (
		<>
			{"+"}
			{phone?.replace(/\D/g, "")}
		</>
	);

	const fullName =
		get(permanentInformations, "personal_profile.first_name") +
		" " +
		get(permanentInformations, "personal_profile.last_name");

	const getPhone = () => {
		const phonesArray = get(
			permanentInformations,
			"personal_profile.phones",
			[]
		);

		if (!phonesArray || phonesArray.length === 0) return "";
		else {
			const phonePrimary = phonesArray
				.filter(({ is_primary }) => is_primary)
				.map(({ phone }) => phone);

			return getPhoneNumber(phonePrimary[0]);
		}
	};

	const getMainFunction = () => {
		const list = get(permanentInformations, "functions", []);
		if (list.length === 0) {
			return "";
		} else {
			const mainFunction = get(permanentInformations, "functions", []).find(
				({ is_main }) => is_main
			);

			return `${mainFunction.name} - ${mainFunction.seniority}`;
		}
	};

	const getEmail = () => {
		const email = get(permanentInformations, "personal_profile.email", []);
		if (email) return email;

		const emailsArray = get(
			permanentInformations,
			"personal_profile.emails",
			[]
		);
		if (!emailsArray || emailsArray.length === 0) return "-";
		else {
			const emailPrimary = emailsArray
				.filter(profileMail => profileMail.is_primary)
				.map(item => {
					return item.email;
				});

			return emailPrimary;
		}
	};

	const getCv = () => {
		const cvDocs = get(permanentInformations, "personal_profile.cv_docs", []);
		const primaryCV = cvDocs?.filter(cd => cd.is_primary);
		const fileName = primaryCV?.[0]?.file_name;
		const filePath = primaryCV?.[0]?.path;

		return {
			cvName: fileName,
			cvPath: filePath
		};
	};

	const getLinkedinUrl = () => {
		return `https://www.linkedin.com/search/results/people/?firstName=${get(
			permanentInformations,
			"personal_profile.first_name"
		)}&lastName=${get(permanentInformations, "personal_profile.last_name")}`;
	};

	return (
		<SkeletonTheme
			style={{ borderRadius: "105", height: "10" }}
			color="#E5E7EB"
		>
			<div className={styles.container}>
				<div className={styles.buttonItem}>
					<Button
						text="Back"
						onClick={onBack}
						icon={<ArrowLeftIcon />}
						variant="text"
						className={styles.backButton}
						textClassName={styles.backButtonText}
					/>
				</div>
				<div className={styles.avatar}>
					{isLoading ? (
						<GdprPermanentViewSkeletonCard
							minWidth={56}
							maxWidth={56}
							height={56}
							style={{ borderRadius: 110 }}
						/>
					) : (
						<Avatar
							size={56}
							radius={4}
							src={permanentInformations?.personal_profile?.avatar}
							name={fullName}
							className={styles.avatar}
						/>
					)}
				</div>
				<div className={styles.profileContainer}>
					<div className={styles.nameContainer}>
						{isLoading ? (
							<GdprPermanentViewSkeletonCard />
						) : (
							<div className={styles.name}>{fullName}</div>
						)}

						<a
							className={styles.linkedinLink}
							target="_blank"
							rel="noopener noreferrer"
							href={getLinkedinUrl()}
						>
							<LinkedinIcon />
						</a>
					</div>
					<div className={styles.flex}>
						{getMainFunction() && (
							<div className={styles.functionContainer}>
								<SuitCaseIcon />
								{isLoading ? (
									<GdprPermanentViewSkeletonCard />
								) : (
									<div className={styles.function}>{getMainFunction()}</div>
								)}
							</div>
						)}
						{getPhone() && (
							<div className={styles.phoneContainer}>
								<PhoneIcon />
								{isLoading ? (
									<GdprPermanentViewSkeletonCard />
								) : (
									<div className={styles.phone}>{getPhone()}</div>
								)}
							</div>
						)}
						{getEmail() && (
							<div className={styles.mailContainer}>
								<LetterIcon />
								{isLoading ? (
									<GdprPermanentViewSkeletonCard />
								) : (
									<div className={styles.email}>{getEmail()}</div>
								)}
							</div>
						)}
					</div>
				</div>
				<div className={styles.deleteItem}>
					<Button
						className={cx(styles.deleteButton)}
						text="Delete candidate"
						icon={<TrashIcon className={cx(styles.trashIcon)} />}
						onClick={onDelete}
					/>
				</div>
				<div className={styles.tabsItem}>
					<nav>
						<div className={styles.tabs}>
							{TABS.map(({ id, label }) => (
								<button
									key={id}
									onClick={() => onTabClick(id)}
									className={cx(styles.tab, {
										[styles.active]: id === actifTab
									})}
								>
									{label}
								</button>
							))}
						</div>
						<div className={styles.presentationSlider}></div>
					</nav>
					<div className={styles.panel}>
						{actifTab === PROFILE_TAB_ID && (
							<GdprPermanentProfileTab
								email={getEmail()}
								phone={getPhone()}
								data={permanentInformations}
								isLoading={isLoading}
								cv={getCv()}
							/>
						)}
						{actifTab === EVALUATION_TAB_ID && (
							<GdprPermanentEvaluationsTab permanentId={permanentId} />
						)}
						{actifTab === PROCESSES_TAB_ID && (
							<GdprPermanentProcessesTab permanentId={permanentId} />
						)}
						{actifTab === INTERACTIONS_TAB_ID && (
							<GdprPermanentInteractionsTab permanentId={permanentId} />
						)}
						{actifTab === NOTES_TAB_ID && (
							<GdprPermanentNotesTab permanentId={permanentId} />
						)}
						{actifTab === FILES_TAB_ID && (
							<GdprPermanentFilesTab permanentId={permanentId} />
						)}
						{actifTab === CONVERSATIONS_TAB_ID && (
							<GdprPermanentConversationsTab permanentId={permanentId} />
						)}
					</div>
				</div>
			</div>
			{openConfirmationModal && (
				<DeleteGdprCandidateModal
					onDelete={onDeleteConfirm}
					onClose={onModalClose}
				/>
			)}
		</SkeletonTheme>
	);
};

export default GdprPermanentView;
