import React from "react";
import styles from "common/CustomInput/custom-input.module.scss";

export const NumberCustomInput = ({ onChange, value }) => {
	return (
		<input
			value={value}
			placeholder=""
			type={"number"}
			className={styles.input}
			onChange={event => {
				const value = Number(event.target.value);
				if (value < 1 || !value) onChange(1, "change");
				else if (value > 1000) onChange(1000, "change");
				else onChange(value, "change");
			}}
		/>
	);
};
