import get from "lodash/get";
import React, { useEffect, useState } from "react";
import Portal from "common/Portal";
import InterviewModal from "common/InterviewModal";
import { connect } from "react-redux";
import { change } from "redux-form";
import { sendPoke } from "modules/company/actions/usersListActions";
import {
	fetchCandidateProfile,
	getListAttendees
} from "modules/candidate/redux/actions";
import { ATTENDEES_LIST_TAG } from "config";
import { withRouter } from "react-router";
import { filterSettingsSelectors } from "modules/app/selectors";
import { authenticatedUserSelector } from "modules/bids/selectors";
import { getFilterSettings } from "modules/app/actions/ListsActions";
import {
	permanentSelector,
	attendeesListSelector
} from "modules/candidate/redux/selectors";

const PokeModal = ({
	user,
	attendeesList,
	router: {
		params: { id }
	},
	permanent,
	location: { query },
	settings: { vacancies },
	user: { company_name, first_name },
	toggleModal,
	nextAction,
	displayModal,
	...props
}) => {
	const [selectedVacancy, setSelectedVacancy] = useState(false);

	useEffect(() => {
		if (!displayModal) {
			props.fetchCandidateProfile({ id });
		}
	}, [displayModal]);

	const changeSelectedVacancy = selectedVacancy =>
		setSelectedVacancy(selectedVacancy);

	const sendPoke = values => {
		let payload;
		if (
			values &&
			values.interview &&
			get(values.interview, "interview_start_date")
		) {
			payload = { profile_id: id, ...values };
		} else {
			payload = {
				profile_id: id,
				additional_comment: get(values, "additional_comment"),
				...values.interview,
				vacancy_id: values.vacancy_id
			};
		}
		props
			.sendPoke({
				...payload
			})
			.then(() => {
				toggleModal();
				setSelectedVacancy("");
				nextAction();
			});
	};

	const fetchAttendees = selectedVacancy => {
		props.getListAttendees({
			tag: ATTENDEES_LIST_TAG,
			vacancy_id: get(selectedVacancy, "_id")
		});
	};
	const { is_feature_add_additional_comment } = permanent;

	const vacancyId = get(query, "vacancy", "");
	return (
		displayModal && (
			<Portal>
				<InterviewModal
					vacancies={vacancies}
					company_name={company_name}
					candidate_first_name={get(permanent, "first_name")}
					toggleModal={toggleModal}
					changeReduxForm={props.changeReduxForm}
					vacancyId={vacancyId}
					changeSelectedVacancy={changeSelectedVacancy}
					selectedVacancy={selectedVacancy}
					user={user}
					companyType={get(user, "company_type")}
					sendInterview={sendPoke}
					enableAdditionalComment={is_feature_add_additional_comment}
					attendeesList={attendeesList}
					fetchAttendees={fetchAttendees}
					first_name={first_name}
				/>
			</Portal>
		)
	);
};

const mapStateToProps = state => {
	return {
		settings: filterSettingsSelectors(state),
		user: authenticatedUserSelector(state),
		permanent: permanentSelector(state),
		attendeesList: attendeesListSelector(state)
	};
};
const mapDispatchToProps = dispatch => {
	return {
		getFilterSettings: payload => dispatch(getFilterSettings(payload)),
		getListAttendees: payload => dispatch(getListAttendees(payload)),
		sendPoke: payload => dispatch(sendPoke(payload)),
		fetchCandidateProfile: payload => dispatch(fetchCandidateProfile(payload)),
		changeReduxForm: (field, value) =>
			dispatch(change("send-poke", field, value))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PokeModal));
