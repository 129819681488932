import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { browserHistory } from "react-router";
import _isEqual from "lodash/isEqual";
import _get from "lodash/get";
import _omit from "lodash/omit";
import {
	getJobProfileRoute,
	getVacancyProfileRoute
} from "../jobprofile/JobProfileContainer";
import FormField from "common/FormField";
import GlobalTooltip from "common/GlobalTooltip";
import CurrenciesSymbolSelect from "common/CurrenciesSymbolSelect";
import {
	INPUT_MAX_LENGTH,
	DATE_FORMAT,
	DAYS,
	JOB_SELECT_DAYS,
	FULL_TIME_LABEL,
	PART_TIME_LABEL,
	EUR_PER_HOUR,
	EUR,
	STEP_2,
	DATE_SECOND_FORMAT,
	FULL_TIME,
	JOB_REQUEST_RICHEDITOR_BLOCKSTYLES,
	JOB_REQUEST_RICHEDITOR_INLINESTYLES,
	MAX_CURRENCY_VALUE,
	NEW_LINES_REGEX,
	SEARCH_ADDRESS,
	COMPANY_ADDRESS,
	REQUEST,
	VACANCY,
	MAX_SALARY_VALUE,
	ONE_MILLION,
	PREFERRED_COUNTRIES,
	JOB_DESCRIPTION_LENGTH,
	EDUCATION_LEVELS,
	PUBLISH_ERROR,
	CONTRACT_TYPE,
	EXPECTED_DURATION,
	EMPLOYMENT_TYPE,
	PERMANENT,
	REMOTE_LABEL,
	REMOTE
} from "config";
import DatePickerField from "common/DatePickerField";
import { getBusinessDays, removeMarkdown, utcTimeStamp } from "config/helpers";
import RichEditor from "common/RichEditor/RichEditor";
import CurrencyField from "common/NewCurrencyField";
import LeaveHook from "common/LeaveHook";
import { getCreateJobRoute, getCreateVacancyRoute } from "../CreateJob";
import { ButtonsContainer, Button, OutlineButton } from "common/styled/buttons";
import Icon from "common/styled/icons";
import { scrollToFirstError } from "common/Functions";
import AddressBox from "common/AddressBox/AddressChooser";
import { isAddressComplete, isEmpty } from "common/Functions";
import { getCompanyRoute } from "../../../company/components/MyCompany";
import { Link } from "react-router";
import CountryFlagSelect from "common/CountryFlagSelect";
import { handleAddressObject } from "config/helpers";
import Select from "common/StyledSelect";

const getAddressOnly = (data = {}) => {
	return {
		street: data.street || data.company_street,
		number: data.number || data.company_number,
		box: data.box || data.company_box,
		city: data.city || data.company_city,
		zip: data.zip || data.company_zip,
		country: data.country || data.company_country,
		longitude: data.longitude || data.company_longitude,
		latitude: data.latitude || data.company_latitude,
		iso_country: data.iso_country || data.company_iso_country
	};
};

const isEmptyAddress = address => {
	let isEmpty = true;
	Object.keys(address).forEach(key => {
		if (address[key] !== "" && isEmpty) isEmpty = false;
	});
	return isEmpty;
};
class JobRequest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			address: getAddressOnly(props.authenticatedUser),
			unroundedEstimation: 0,
			submitted: false,
			mounted: false,
			error: false,
			addressTypo: false,
			selectedCurrency: null,
			employmentType: null
		};
	}

	componentDidUpdate(prevProps) {
		const oldAddress = getAddressOnly(prevProps.jobInfo);
		const newAddress = getAddressOnly(this.props.jobInfo);
		if (
			!this.state.mounted &&
			this.props.formFields.location_type === SEARCH_ADDRESS
		) {
			if (!isEmptyAddress(oldAddress)) {
				this.setState({ address: oldAddress, mounted: true });
			} else if (
				!isEmptyAddress(newAddress) &&
				!_isEqual(oldAddress, newAddress)
			) {
				this.setState({ address: newAddress, mounted: true });
			}
		}
	}

	handleGoBack = e => {
		e.preventDefault();
		const editEducationOnly =
			_get(this.props, "query.source") === PUBLISH_ERROR;

		if (editEducationOnly) {
			return browserHistory.push(`/vacancy/${this.props.params.id}`);
		}
		if (this.props.jobInfo) {
			const profileRoute =
				this.props.job_type === REQUEST
					? getJobProfileRoute
					: getVacancyProfileRoute;
			browserHistory.push(profileRoute(this.props.jobInfo.id || ""));
		}
	};

	changeEstimation = (date, is_start = false) => {
		const {
			formFields: { start_date, end_date },
			changeReduxForm
		} = this.props;

		let newEstimation;

		if (is_start) {
			newEstimation = getBusinessDays(date, end_date);
		} else {
			newEstimation = getBusinessDays(start_date, date);
		}

		this.setState(
			{
				unroundedEstimation: newEstimation,
				changedType: {
					type: DAYS,
					value: newEstimation
				}
			},
			() => {
				changeReduxForm(
					"estimation",
					Math.round(this.state.unroundedEstimation)
				);
				changeReduxForm("estimation_type", JOB_SELECT_DAYS);
			}
		);
	};

	changeStartEstimation = value => {
		const {
			formFields: { end_date },
			clearReduxFormFields
		} = this.props;
		if (
			end_date &&
			window
				.moment(window.moment(value, DATE_FORMAT))
				.isAfter(window.moment(end_date, DATE_FORMAT), "day")
		) {
			clearReduxFormFields("end_date");
		}
		this.changeEstimation(value, true);
		return value;
	};

	changeEndEstimation = value => {
		this.changeEstimation(value, false);
		return value;
	};

	isValidAddress = () => {
		let valid = true;
		const requiredFields = ["street", "number", "city", "zip", "country"];
		requiredFields.forEach(field => {
			if (!this.state.address[field]) {
				valid = false;
			}
		});
		return valid;
	};

	onSubmitJobPricing = async formFields => {
		const { jobInfo, params, createJobStep2, job_type } = this.props;
		const { address } = this.state;
		if (!this.state.submitted) {
			this.setState({ submitted: true });
		}

		if (
			!this.isValidAddress(this.state.address) &&
			formFields.location_type === SEARCH_ADDRESS
		) {
			return this.setState({ error: true });
		}

		const finalAddress =
			formFields.location_type !== REMOTE &&
			(await handleAddressObject(address));

		if (finalAddress.addressTypo) {
			this.setState({
				error: true,
				addressTypo: true
			});
			return this.setState({
				error: false,
				addressTypo: false
			});
		}
		this.setState({
			...this.state,
			...finalAddress
		});

		const start_date = utcTimeStamp({
			date: formFields.start_date,
			format: DATE_SECOND_FORMAT
		});

		const end_date = utcTimeStamp({
			date: formFields.end_date,
			isStart: false,
			format: DATE_SECOND_FORMAT
		});
		let job = {
			step: STEP_2,
			...formFields,
			id: jobInfo ? jobInfo.id : "",
			...getAddressOnly(this.state.address),
			box: this.state.address.box || "",
			job_type,
			currency:
				typeof _get(formFields, "currency") === "string"
					? _get(formFields, "currency")
					: _get(formFields, "currency.value"),
			payment_time:
				typeof _get(formFields, "payment_time") === "string"
					? _get(formFields, "payment_time")
					: _get(formFields, "payment_time.value"),
			payment_type:
				typeof _get(formFields, "payment_type") === "string"
					? _get(formFields, "payment_type")
					: _get(formFields, "payment_type.value")
		};
		if (formFields.location_type === REMOTE) {
			job = _omit(job, [
				"street",
				"number",
				"box",
				"city",
				"zip",
				"country",
				"longitude",
				"formatted_address",
				"latitude",
				"iso_country"
			]);
		}

		if (job_type === REQUEST) {
			const contract_type = _get(formFields, "contract_type");
			job.start_date = start_date;
			job.end_date = end_date;
			job.full_time = formFields.time_type === FULL_TIME;
			job.part_time = formFields.time_type !== FULL_TIME;
			job.contract_type =
				typeof contract_type === "string" ? contract_type : contract_type.value;
		} else {
			const payroll_country = _get(formFields, "payroll_country");

			job.payroll_country =
				typeof payroll_country === "string"
					? payroll_country
					: payroll_country.value;
			job.education_level = _get(
				formFields,
				"education_level.value",
				_get(formFields, "education_level")
			);
			job.employment_type =
				typeof _get(formFields, "employment_type") === "string"
					? _get(formFields, "employment_type")
					: _get(formFields, "employment_type.value");

			job.expected_duration = _get(formFields, "expected_duration");
			job.expected_duration_period =
				typeof _get(formFields, "expected_duration_period") === "string"
					? _get(formFields, "expected_duration_period")
					: _get(formFields, "expected_duration_period.value");
		}
		const isPermanent =
			(_get(formFields, "employment_type.value") ||
				formFields.employment_type) === PERMANENT;
		if (isPermanent) {
			job = _omit(job, ["expected_duration", "expected_duration_period"]);
		}
		return createJobStep2(job, !!params.id, "JobFormSecondPart");
	};

	onChangeAddress = address => {
		if (
			address.country !== _get(this.state, "address.country") ||
			address.city !== _get(this.state, "address.city")
		) {
			if (
				address.longitude === _get(this.state, "address.city") ||
				address.latitude === _get(this.state, "address.latitude")
			) {
				address.latitude = 0;
				address.longitude = 0;
			}
		}
		this.setState({ address });
	};

	changeCompanyType = (event, value) => {
		const { authenticatedUser } = this.props;
		if (value === SEARCH_ADDRESS) {
			this.onChangeAddress({
				street: "",
				number: "",
				box: "",
				city: "",
				zip: "",
				country: "",
				longitude: "",
				formatted_address: "",
				latitude: "",
				iso_country: ""
			});
		}
		if (value === REMOTE) {
			this.onChangeAddress({
				street: "",
				number: "",
				box: "",
				city: "",
				zip: "",
				country: "",
				longitude: "",
				formatted_address: "",
				latitude: "",
				iso_country: ""
			});
		}
		if (value === COMPANY_ADDRESS) {
			this.onChangeAddress({
				street: authenticatedUser.company_street,
				number: authenticatedUser.company_number,
				box: authenticatedUser.company_box,
				city: authenticatedUser.company_city,
				zip: authenticatedUser.company_zip,
				country: authenticatedUser.company_country,
				longitude: authenticatedUser.company_longitude,
				latitude: authenticatedUser.company_latitude,
				iso_country: authenticatedUser.company_iso_country
			});
		}
	};
	changeEmploymentType = e => {
		this.setState({
			employmentType: e.value
		});
	};
	getAddress = () => {
		const { authenticatedUser } = this.props;
		return `${
			authenticatedUser.company_street ? authenticatedUser.company_street : ""
		} ${
			authenticatedUser.company_number ? authenticatedUser.company_number : ""
		} ${
			authenticatedUser.company_street || authenticatedUser.company_number
				? ","
				: ""
		} ${authenticatedUser.company_zip ? authenticatedUser.company_zip : ""} ${
			authenticatedUser.company_city ? authenticatedUser.company_city : ""
		} ${
			authenticatedUser.company_country ? authenticatedUser.company_country : ""
		}`;
	};

	getAddressObject = () => {
		const { authenticatedUser } = this.props;
		return {
			street: authenticatedUser.company_street,
			number: authenticatedUser.company_number,
			box: authenticatedUser.company_box,
			city: authenticatedUser.company_city,
			zip: authenticatedUser.company_zip,
			country: authenticatedUser.company_country,
			longitude: authenticatedUser.company_longitude,
			latitude: authenticatedUser.company_latitude,
			iso_country: authenticatedUser.company_iso_country
		};
	};

	changeCurrency = e => {
		this.setState({ selectedCurrency: e });
	};

	render() {
		const {
			handleSubmit,
			formFields,
			submitting,
			dirty,
			submitFailed,
			job_type,
			submitErrors,
			currencies,
			query
		} = this.props;

		const { selectedCurrency } = this.state;

		const paymentsData = [
			{ label: "Monthly", name: "Monthly", value: "monthly" },
			{ label: "Yearly", name: "Yearly", value: "yearly" },
			{ label: "Hourly", name: "Hourly", value: "hourly" }
		];
		const typeData = [
			{ label: "Gross", name: "Gross", value: "gross" },
			{ label: "Net", name: "Net", value: "net" }
		];

		const address = this.getAddress();
		const startDateMinDate = window.moment().toDate();

		const endDateMinDate = formFields.start_date
			? window
					.moment(formFields.start_date, DATE_FORMAT)
					.add(1, "day")
					.toDate()
			: startDateMinDate;
		const isVacancy = job_type === VACANCY;
		const creationRoute = !isVacancy
			? getCreateJobRoute
			: getCreateVacancyRoute;
		const jobTitleTooltip = isVacancy
			? "The title should be as explicit and objective as possible regarding the actual job title that the person selected will have within your company."
			: "The title should be as explicit and objective as possible regarding the actual job title that the person selected will have within your company. Indeed, this value is automatically inserted in the contract so avoid using titles like « Program manager needed for 6 months » or « Dynamic consultant for mission in Brussels ».";
		const descriptionTooltip = isVacancy
			? "A general description of the vacancy. Feel free to detail the function itself, the working environment, or even the soft skills needed."
			: "Describe the project on which the selected candidate will have to work as well as the tasks to perform in as much details as possible. As for the previous field, this data will automatically be inserted in the contract within the “tasks to perform” section so clear, straight-to-the-point information is essential.";
		const locationTooltip = isVacancy
			? "The location at which the employee will be based."
			: "We use your provided location to match you with relevant, nearby missions.";

		const editEducationOnly = _get(query, "source") === PUBLISH_ERROR;
		return (
			<LeaveHook dirty={dirty} route={creationRoute()}>
				<div className="row" style={{ padding: "15px" }}>
					<div className="create-job col-md-8 col-sm-10">
						<Field
							name="title"
							tabIndex={0}
							component={FormField}
							maxLength={INPUT_MAX_LENGTH}
							label={isVacancy ? "Job title" : "Request title"}
							placeholder="A descriptive title for your job"
							toolTipOverlay={jobTitleTooltip}
							disabled={editEducationOnly}
						/>

						<Field
							tabIndex={0}
							name="description"
							component={RichEditor}
							height="150px"
							maxLength={JOB_DESCRIPTION_LENGTH}
							label={isVacancy ? "Job description" : "Request description"}
							placeholder="Description of the project, tasks to perform etc..."
							blockStylesTypes={JOB_REQUEST_RICHEDITOR_BLOCKSTYLES}
							inlineStylesTypes={JOB_REQUEST_RICHEDITOR_INLINESTYLES}
							toolTipOverlay={descriptionTooltip}
							validationError={_get(submitErrors, "description[0]")}
							readOnly={editEducationOnly}
						/>

						{isVacancy && (
							<>
								<Field
									name="education_level"
									label="Education level"
									options={EDUCATION_LEVELS}
									position={false}
									searchable={true}
									clearable={true}
									component={Select}
								/>
							</>
						)}
						<div className="radio-group location-selector">
							<label htmlFor="full_adresse">
								Location
								<GlobalTooltip maxWidth="480px" overlay={locationTooltip} />
							</label>
							<Field
								tabIndex={0}
								name="location_type"
								id={COMPANY_ADDRESS}
								component={FormField}
								classes="radio first"
								type="radio"
								value={COMPANY_ADDRESS}
								label="Use company address"
								onChange={this.changeCompanyType}
								disabled={editEducationOnly}
							/>
							<Field
								tabIndex={0}
								name="location_type"
								id={SEARCH_ADDRESS}
								component={FormField}
								classes="radio"
								type="radio"
								value={SEARCH_ADDRESS}
								label="Use another address"
								onChange={this.changeCompanyType}
								disabled={editEducationOnly}
							/>
							<Field
								tabIndex={0}
								name="location_type"
								id={REMOTE}
								component={FormField}
								classes="radio"
								type="radio"
								value={REMOTE}
								label={REMOTE_LABEL}
								onChange={this.changeCompanyType}
								disabled={editEducationOnly}
							/>
						</div>
						{formFields && formFields.location_type === SEARCH_ADDRESS && (
							<AddressBox
								setAddress={this.onChangeAddress}
								isRequired={true}
								showAutoComplete
								name="location"
								address={this.state.address}
								addressTypo={this.state.addressTypo}
								error={
									this.state.error || (submitFailed && !this.isValidAddress())
								}
								disabled={editEducationOnly}
							/>
						)}

						{formFields.location_type === COMPANY_ADDRESS &&
							!isAddressComplete(this.getAddressObject()) && (
								<div key="incomplete" className="msg error-msg">
									<div className="msg-content">
										{isEmpty(this.getAddressObject())
											? "No company address has been provided for this account yet. "
											: "Your provided company address is not complete. "}
										To be able to use this option, please ask your account’s
										Super-Admin to fill the missing details in{" "}
										<Link to={getCompanyRoute()}>
											SETTINGS → COMPANY → EDIT ADDRESS{" "}
										</Link>
									</div>
								</div>
							)}
						{formFields.location_type === COMPANY_ADDRESS && (
							<div className="form-group inner-icon left-icon">
								<div className="input-box location-input-margin">
									<i className="fa fa-map-marker" />
									<input
										className="form-control"
										value={address}
										readOnly
										disabled={editEducationOnly}
									/>
								</div>
							</div>
						)}

						{!isVacancy ? (
							<>
								<div className="row">
									<div className="col-sm-6">
										<Field
											label="CONTRACT TYPE"
											name="contract_type"
											component={Select}
											options={CONTRACT_TYPE}
											placeholder="Select ..."
											position={false}
											searchable={true}
											clearable={false}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<Field
											tabIndex={0}
											classes="inner-icon right-icon"
											icon="fa fa-calendar"
											name="start_date"
											label="Expected start date"
											minDate={startDateMinDate}
											onChange={this.changeStartEstimation}
											component={DatePickerField}
										/>
									</div>
									<div className="col-sm-6">
										<Field
											tabIndex={0}
											classes="inner-icon right-icon"
											icon="fa fa-calendar"
											name="end_date"
											onChange={this.changeEndEstimation}
											minDate={endDateMinDate}
											label="Expected end date"
											component={DatePickerField}
										/>
									</div>
								</div>

								<div className="radio-group botPad">
									<label htmlFor="full_adresse"> Commitment </label>
									<Field
										tabIndex={0}
										name="time_type"
										id="full_time"
										component={FormField}
										classes="radio first"
										type="radio"
										value="full_time"
										label={FULL_TIME_LABEL}
									/>
									<Field
										tabIndex={0}
										name="time_type"
										id="part_time"
										component={FormField}
										classes="radio"
										type="radio"
										value="part_time"
										label={PART_TIME_LABEL}
									/>
								</div>

								<div className="row">
									<div className="col-sm-5 hourly-budget currency">
										<Field
											tabIndex={0}
											name="hourly_budget"
											label="Estimated hourly rate"
											suffix={EUR_PER_HOUR}
											component={CurrencyField}
											tooltipTitle="The hourly rate you think will be acceptable for
									the mission. This value won’t be communicated
									to applicants but will be used by our automatic
									matching tool to inform freelancers about your
									request, as long as the expected rate falls within
									their financial expectations."
										/>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="row">
									<div className="col-sm-3">
										<Field
											name="employment_type"
											label="EMPLOYMENT TYPE"
											component={Select}
											options={EMPLOYMENT_TYPE}
											position={false}
											placeholder="Select ..."
											searchable={true}
											clearable={false}
											onChange={e => this.changeEmploymentType(e)}
										/>
									</div>
									{(_get(formFields, "employment_type.value") ||
										formFields.employment_type) !== PERMANENT && (
										<div className="col-sm-6 expected-duration">
											<label> EXPECTED DURATION </label>
											<div className="row">
												<div className="col-sm-3">
													<Field
														name="expected_duration"
														component={FormField}
														placeholder="00"
														maxLength={2}
													/>
												</div>
												<div className="col-sm-6 expected-duration-select">
													<Field
														name="expected_duration_period"
														component={Select}
														options={EXPECTED_DURATION}
														placeholder="Select ..."
														position={false}
														searchable={true}
														clearable={false}
													/>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="row">
									<div className="col-md-12 form-group">
										<label>Projected salary range</label>
									</div>
									<div className="col-md-3 hourly-budget currency">
										<Field
											tabIndex={0}
											name="salary_range_start"
											label="BETWEEN"
											suffix={!selectedCurrency ? EUR : selectedCurrency.symbol}
											component={CurrencyField}
											disabled={editEducationOnly}
										/>
									</div>
									<div className="col-md-3 hourly-budget currency">
										<Field
											tabIndex={0}
											name="salary_range_end"
											label="AND"
											suffix={!selectedCurrency ? EUR : selectedCurrency.symbol}
											component={CurrencyField}
											disabled={editEducationOnly}
										/>
									</div>
									<div className="col-md-3">
										<Field
											name="currency"
											label="Currency"
											input_classes="currency"
											component={CurrenciesSymbolSelect}
											preferredCountries={PREFERRED_COUNTRIES}
											tabIndex={0}
											all={true}
											placeholder="Select a currency."
											//toolTipOverlay=""
											currenciesData={currencies}
											onChange={this.changeCurrency}
											disabled={editEducationOnly}
										/>
									</div>
									<div className="col-md-3">
										<Field
											name="payroll_country"
											label="Payroll country"
											input_classes="countries"
											component={CountryFlagSelect}
											preferredCountries={PREFERRED_COUNTRIES}
											tabIndex={0}
											all={true}
											placeholder="Select a country."
											toolTipOverlay="The country in which the labour taxes will be paid."
											disabled={editEducationOnly}
										/>
									</div>
									<div className="col-md-12" style={{ paddingLeft: "0px" }}>
										<div className="col-md-3">
											<Field
												name="payment_time"
												label="Payments time"
												options={paymentsData}
												position={false}
												searchable={true}
												clearable={false}
												component={Select}
												placeholder="Select a time."
												disabled={editEducationOnly}
											/>
										</div>
										<div className="col-md-3">
											<Field
												name="payment_type"
												label="Type"
												options={typeData}
												position={false}
												searchable={true}
												clearable={false}
												component={Select}
												placeholder="Select a type."
												disabled={editEducationOnly}
											/>
										</div>
									</div>
									<div className="col-md-12">
										<Field
											name="other_benefits"
											label="Other benefits"
											tabIndex={0}
											component={FormField}
											maxLength={500}
											placeholder="Company Car, Meal Vouchers, Insurance(s), Phone, Bonus, etc..."
											disabled={editEducationOnly}
										/>
									</div>
								</div>
							</>
						)}
					</div>

					<div className="col-xs-12">
						<div className="job-action">
							<ButtonsContainer className="pull-right">
								<OutlineButton has-icon large onClick={this.handleGoBack}>
									<Icon className="icon-arrow-left" />
									back
								</OutlineButton>
								<Button
									bordered
									large
									onClick={handleSubmit(this.onSubmitJobPricing)}
									disabled={submitting}
								>
									{isVacancy ? "Create vacancy" : "Next Step"}
								</Button>
							</ButtonsContainer>
						</div>
					</div>
				</div>
			</LeaveHook>
		);
	}
}

const validate = formprops => {
	const errors = {};
	let description = removeMarkdown(formprops.description || "");
	description = description ? description.replace(NEW_LINES_REGEX, "") : "";
	const cleanDescription = description.replace(/__(.*?)__/g, "");

	if (!formprops.title || formprops.title.trim() === "")
		errors.title = "Please write down the title.";

	if (!description || description.trim() === "")
		errors.description = "Please write down the description.";
	if (description && cleanDescription.length > JOB_DESCRIPTION_LENGTH) {
		errors.description = `This field should not exceed ${JOB_DESCRIPTION_LENGTH} characters`;
	}

	if (!formprops.start_date)
		errors.start_date = "Please specify your mission’s estimated start date.";

	if (!formprops.end_date)
		errors.end_date = "Please specify your mission’s estimated end date.";

	if (!formprops.hourly_budget)
		errors.hourly_budget =
			"Please specify your mission’s expected hourly rate.";

	if (
		formprops.hourly_budget &&
		(formprops.hourly_budget <= 0 ||
			formprops.hourly_budget >= MAX_CURRENCY_VALUE)
	) {
		errors.hourly_budget = "The hourly rate should be between 1€ and 1000€.";
	}

	if (!formprops.payroll_country) {
		errors.payroll_country = "Please select the payroll country.";
	}

	if (!formprops.accounting_period) {
		errors.accounting_period = "Please select the payment time.";
	}

	if (!formprops.social_contribution) {
		errors.social_contribution = "Please select the type.";
	}

	if (!formprops.currency) {
		errors.currency = "Please select the currency.";
	}

	if (!formprops.salary_range_start) {
		errors.salary_range_start =
			"Please provide a value for the start salary range.";
	}
	if (!formprops.salary_range_end) {
		errors.salary_range_end =
			"Please provide a value for the end salary range.";
	}
	if (
		formprops.salary_range_start &&
		(formprops.salary_range_start <= 0 ||
			formprops.salary_range_start >= MAX_SALARY_VALUE)
	) {
		errors.salary_range_start = `The salary should be between 1€ and ${ONE_MILLION}€.`;
	}
	if (
		formprops.salary_range_end &&
		(formprops.salary_range_end <= 0 ||
			formprops.salary_range_end >= MAX_SALARY_VALUE)
	) {
		errors.salary_range_end = `The salary should be between 1€ and ${ONE_MILLION}€.`;
	}
	if (
		formprops.salary_range_end &&
		formprops.salary_range_start &&
		formprops.salary_range_start > formprops.salary_range_end
	) {
		errors.salary_range_start =
			"The minimum salary range should be lower than or equal to the maximum salary range.";
	}
	if (!formprops.education_level) {
		errors.education_level = "Please select an education level.";
	}
	if (!formprops.employment_type) {
		errors.employment_type = "Please select an employment type.";
	}
	if (!formprops.expected_duration) {
		errors.expected_duration = "Required!";
	}
	if (formprops.expected_duration < 1) {
		errors.expected_duration = "From 1 to 99!";
	}
	if (!formprops.expected_duration_period) {
		errors.expected_duration_period = "Please select an expected duration.";
	}
	return errors;
};

export default reduxForm({
	form: "JobFormSecondPart",
	validate,
	touchOnBlur: false,
	onSubmitFail: errors => scrollToFirstError(errors)
})(JobRequest);
