import React, { Component } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import { Flex } from "rebass";
import Navigation from "./Navigation";

import {
	acceptAll,
	submitTimesheet,
	toggleEditTimesheet,
	sendDisputedList,
	toggleReSubmitTimesheet,
	toggleGeneralDispute
} from "../actions";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { formatDate } from "common/Functions";
import TimesheetStatus from "./TimesheetStatus";
import TimeSheetsDetails from "./TimeSheetsDetails";
import { formatTimesheetPeriod } from "config/helpers";

class Topbar extends Component {
	state = {
		showModal: false,
		showCancelModal: false,
		showSaveModal: false
	};

	acceptOrSubmit = () => {
		const { job, _id, isClient } = this.props;
		if (isClient) return this.props.acceptAll(_id, job._id);

		return this.props.submitTimesheet(_id, job._id);
	};

	toggleModal = () =>
		this.setState({
			showModal: !this.state.showModal
		});

	toggleCancelModal = () =>
		this.setState({
			showCancelModal: !this.state.showCancelModal
		});

	toggleSaveModal = () =>
		this.setState({
			showSaveModal: !this.state.showSaveModal
		});

	submitTimesheet = () => {
		this.acceptOrSubmit()
			.then(() => this.toggleModal())
			.catch(() => this.toggleModal());
	};

	cancelDisputedItems = () => {
		// load timesheet list
		this.props.loadTimeSheet().then(() => {
			// close modal
			this.toggleCancelModal();
			// toggle edit icon & buttons
			this.props.toggleEditTimesheet();
			// toggle dispute
			this.props.toggleGeneralDispute();
		});
	};

	onCancelAction = () => {
		if (this.props.disputed) {
			this.toggleCancelModal();
		} else {
			this.props.toggleEditTimesheet();
		}
	};

	saveChangedItems = () => {
		const {
			toggleEditTimesheet,
			updatedexpensesList,
			updatedworktimesList,
			toggleReSubmitTimesheet,
			sendDisputedList,
			timesheet_id,
			isClient,
			job
		} = this.props;
		sendDisputedList(
			{
				timesheet_id,
				expenses: {
					...updatedexpensesList
				},
				worktimes: {
					...updatedworktimesList
				}
			},
			job._id,
			isClient
		).then(() => {
			if (isClient) {
				this.toggleSaveModal();
			} else {
				toggleReSubmitTimesheet();
			}
			toggleEditTimesheet();
		});
	};

	render() {
		const {
			isClient,
			isConsultancy,
			status,
			job,
			startDate,
			endDate,
			onPrevious,
			onNext,
			previousTimesheet,
			nextTimesheet,
			totalWorktime,
			totalOvertime,
			totalWorktimeHours,
			totalOverTimeHours,
			totalExpense,
			lastUserAction,
			total,
			missionProgress,
			isConsultantAdmin,
			isNotProjectMananger,
			allItems,
			isOvertimeAllowed,
			reportingSettings,
			timesheet
		} = this.props;

		const timesheetPeriod = _get(timesheet, "period", null);
		const timesheetFrequency = _get(
			timesheet,
			"reporting_settings.timesheet_frequency",
			null
		);

		return (
			<div style={{ marginBottom: "10px" }} className="topbar">
				<div className="container">
					<Navigation
						goToPrevious={() => onPrevious(previousTimesheet)}
						goToNext={() => onNext(nextTimesheet)}
						hasPrevious={previousTimesheet}
						hasNext={nextTimesheet}
					/>
					<Flex alignItems="center" justifyContent="space-between">
						<TimesheetStatus
							isClient={isClient}
							status={status}
							submittedBy={
								_get(lastUserAction, "first_name", "") &&
								`${_get(lastUserAction, "first_name", "")} ${_get(
									lastUserAction,
									"last_name",
									""
								)}`
							}
							allItems={allItems}
						/>
						<TimeSheetsDetails
							startDate={formatDate(startDate)}
							endDate={formatDate(endDate)}
							companyName={_get(job, "company.name")}
							externalCompany={_get(
								timesheet,
								"contract.invoice_details.company_name",
								""
							)}
							externalManager={_get(
								timesheet,
								"contract.invoice_details.company_timesheet_manager_email",
								""
							)}
							jobTitle={job.title}
							missionProgress={missionProgress}
							details={[
								{
									label: "Period",
									detail: formatTimesheetPeriod(
										timesheetFrequency,
										timesheetPeriod
									),
									gridColumn: isOvertimeAllowed && "1 / 6"
								},
								{
									label: "Total expenses",
									detail: (
										<CurrencyFormatterRender
											isCent
											value={totalExpense}
											currency={_get(reportingSettings, "currency.code")}
										/>
									),
									gridColumn: isOvertimeAllowed && "6 / 9"
								},
								{
									label: "Total worktime",
									detail: !isConsultancy ? (
										<>
											<CurrencyFormatterRender
												isCent
												value={totalWorktime}
												currency={_get(reportingSettings, "currency.code")}
											/>{" "}
											({totalWorktimeHours})
										</>
									) : isConsultantAdmin ? (
										<>
											<CurrencyFormatterRender
												isCent
												value={totalWorktime}
												currency={_get(reportingSettings, "currency.code")}
											/>{" "}
											({totalWorktimeHours})
										</>
									) : (
										<>{totalWorktimeHours}</>
									),
									gridColumn: isOvertimeAllowed && "1 / 3"
								},
								isOvertimeAllowed && {
									label: "Total overtime",
									detail: !isConsultancy ? (
										<>
											<CurrencyFormatterRender
												isCent
												value={totalOvertime}
												currency={_get(reportingSettings, "currency.code")}
											/>{" "}
											({totalOverTimeHours})
										</>
									) : isConsultantAdmin ? (
										<>
											<CurrencyFormatterRender
												isCent
												value={totalOvertime}
												currency={_get(reportingSettings, "currency.code")}
											/>{" "}
											({totalOverTimeHours})
										</>
									) : (
										<>{totalOverTimeHours}</>
									),
									gridColumn: isOvertimeAllowed && "3 / 6"
								},
								isNotProjectMananger && {
									label: "Total",
									detail: (
										<React.Fragment>
											{" "}
											{isConsultancy && (
												<>
													{totalWorktimeHours}{" "}
													<span style={{ color: "#ff6849", fontSize: 15 }}>
														&amp;
													</span>{" "}
												</>
											)}
											<CurrencyFormatterRender
												isCent
												value={
													isConsultancy && !isNotProjectMananger
														? totalExpense
														: total
												}
												currency={_get(reportingSettings, "currency.code")}
											/>
										</React.Fragment>
									),
									gridColumn: isOvertimeAllowed && "6 / 9"
								}
							].filter(Boolean)}
						/>
					</Flex>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		warned: _get(state, "timesheet.warned"),
		view: _get(state, "timesheet.view"),
		submitDate: _get(state, "timesheet.data.submit_timesheet_date"),
		timesheetDate: _get(state, "timesheet.data.timesheet_month"),
		nextTimesheet: _get(state, "timesheet.data.next_timesheet"),
		previousTimesheet: _get(state, "timesheet.data.previous_timesheet"),
		showEditTimesheet: _get(state, "timesheet.showEditTimesheet"),
		disputed: _get(state, "timesheet.disputed"),
		updatedexpensesList: _get(state, "timesheet.updatedexpensesList"),
		updatedworktimesList: _get(state, "timesheet.updatedworktimesList"),
		showResubmitModal: _get(state, "timesheet.showResubmitModal"),
		timesheet: _get(state, "timesheet.data")
	};
};

const mapDispatchToProps = dispatch => ({
	submitTimesheet: (_id, user_id) => dispatch(submitTimesheet(_id, user_id)),
	acceptAll: (_id, job_id) => dispatch(acceptAll(_id, job_id)),
	toggleEditTimesheet: () => dispatch(toggleEditTimesheet()),
	sendDisputedList: (list, jobId, isClient) =>
		dispatch(sendDisputedList(list, jobId, isClient)),
	toggleReSubmitTimesheet: () => dispatch(toggleReSubmitTimesheet()),
	toggleGeneralDispute: () => dispatch(toggleGeneralDispute())
});

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
