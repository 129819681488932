import React, { Component } from "react";
import CVUploaderContainer from "../cvuploader/CVUploaderContainer";
import {
	Wrapper,
	Container,
	Divider,
	Text,
	Line,
	Or,
	Span
} from "./CVChooserStyled";
const cvupload = "cvupload";

export function getCVChooserRoute() {
	return "/company/cv";
}

export default class CVChooserContainer extends Component {
	state = {
		pick: null
	};

	uploadCv = () => {
		this.setState({ pick: cvupload });
	};

	goBack = () => {
		this.setState({ pick: null });
	};

	render() {
		const { pick } = this.state;

		if (pick === cvupload) {
			return <CVUploaderContainer goBack={this.goBack} />;
		}

		return (
			<Wrapper>
				<Container>
					<Span className="icon-fillcv">
						<span className="path1" />
						<span className="path2" />
						<span className="path3" />
						<span className="path4" />
						<span className="path5" />
						<span className="path6" />
						<span className="path7" />
						<span className="path8" />
						<span className="path9" />
						<span className="path10" />
					</Span>

					<button className="btn btn-primary-new" onClick={this.showCVLight}>
						Fill your profile
					</button>

					<Text>By detailing your skills and experiences.</Text>
				</Container>

				<Divider>
					<Line />
					<Or>Or</Or>
					<Line />
				</Divider>

				<Container>
					<Span className="icon-uploadcv">
						<span className="path1" />
						<span className="path2" />
						<span className="path3" />
						<span className="path4" />
						<span className="path5" />
						<span className="path6" />
						<span className="path7" />
						<span className="path8" />
						<span className="path9" />
						<span className="path10" />
					</Span>

					<button className="btn btn-primary-new" onClick={this.uploadCv}>
						Upload your CV
					</button>

					<Text>And we’ll set up your profile for you!</Text>
				</Container>
			</Wrapper>
		);
	}
}
