import React, { createContext, useContext } from "react";

const OverviewContext = createContext(undefined);

const OverviewProvider = ({ children, value }) => {
	return (
		<OverviewContext.Provider value={value}>
			{children}
		</OverviewContext.Provider>
	);
};

const useOverviewContext = () => {
	const context = useContext(OverviewContext);
	if (context === undefined) {
		throw new Error(
			"useOverviewContext must be used within a OverviewProvider"
		);
	}
	return context;
};

export { OverviewProvider, useOverviewContext };
