import React, { useState, useEffect } from "react";
import Skills from "modules/job/components/SkillsModal";
import AxiosHelper from "config/axios-helper";
import {
	LIST_SKILLS,
	LIST_SECTORS,
	GUEST_LIST_SKILLS
} from "config/api-endpoints";

const SkillsModal = ({
	skills,
	saveSkills,
	closeModal,
	selectedFunctions,
	cartTitle,
	showMustHave = true,
	saveActionName,
	isSearch,
	closeOnSave = true,
	modalHeadText,
	saveActionStyle,
	canPropose = true,
	isOnline = true,
	userId
}) => {
	useEffect(() => {
		AxiosHelper.post({
			url: LIST_SECTORS,
			isModal: true,
			isLogin: true
		}).then(resp => setCategories(resp));
	}, []);

	const loadSkills = ({ sector, term }) => {
		if (sector) {
			AxiosHelper.post({
				url: isOnline ? LIST_SKILLS : GUEST_LIST_SKILLS,
				isModal: true,
				data: {
					sector_id: sector,
					limit: 150,
					offset: 0,
					term
				},
				isLogin: true
			}).then(resp => setSkillsOptions(resp.data || []));
		}
	};

	const [skillsOptions, setSkillsOptions] = useState([]);
	const [categories, setCategories] = useState([]);
	return (
		<Skills
			loadSkills={loadSkills}
			skills={skillsOptions}
			initialSkills={skills}
			onSave={skills => {
				let s = skills.map(s => ({
					name: s.name,
					parent_sector: s.parent_sector,
					score: s.score,
					_id: s._id,
					must_have: s.must_have || false
				}));
				saveSkills(s, userId);
				closeOnSave && closeModal();
			}}
			contentHeight={84}
			closeModal={closeModal}
			isRequest={false}
			showMustHave={showMustHave}
			sectors={categories}
			categories={selectedFunctions}
			isSearch={isSearch}
			cartTitle={cartTitle}
			saveActionName={saveActionName}
			modalHeadText={modalHeadText}
			saveActionStyle={saveActionStyle}
			canPropose={canPropose}
		/>
	);
};

export default SkillsModal;
