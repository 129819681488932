import { LIST_FUNCTIONS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";

const getFunctions = body =>
	client(LIST_FUNCTIONS, {
		body
	});

export const useGetFunctionsList = (body, options) => {
	const res = useQuery(["functions", body], () => getFunctions(body), {
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		...options
	});

	return res;
};
