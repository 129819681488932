import { parseLanguageSchema } from "../utils/helper";
import { useAddVacancyProfileInformations } from "./useAddVacancyProfileInformations";

function formatLanguages(languagesSchema) {
	return languagesSchema.map(language => {
		if (language.language) {
			return {
				_id: language._id,
				name: language.language,
				score: language.proficiency
			};
		}
		return language;
	});
}

export const useCreateVacancyProfileInformations = isActive => {
	const [
		addVacancyProfileInformations,
		{ isLoading }
	] = useAddVacancyProfileInformations();

	const saveVacancyProfileInformations = (
		{
			site,
			department,
			skills,
			query_languages,
			categories: functions,
			priority
		} = {},
		options = {}
	) => {
		const body = {
			functions: formatFunctionsList(functions),
			query_languages: JSON.parse(
				JSON.stringify(parseLanguageSchema(formatLanguages(query_languages)))
			),
			skills: formatSkillsList(skills),
			step: 1,
			group_id: department?.value,
			job_type: "vacancy",
			priority: priority.label
		};

		let bodyUpdated;

		if (isActive) {
			bodyUpdated = {
				...body,
				site_id: site.value
			};
		} else {
			bodyUpdated = body;
		}

		addVacancyProfileInformations(bodyUpdated, options);
	};

	return [saveVacancyProfileInformations, isLoading];
};

const formatSkillsList = list => {
	return list.map(({ mustHave, parent_sector, name, _id, proficiency }) => {
		return {
			//TODO add primary UI
			must_have: mustHave,
			parent_sector: parent_sector,
			name: name,
			_id: _id,
			score: proficiency
		};
	});
};

const formatFunctionsList = list => {
	return list.map(({ isMain, seniority, _function, subCategory }) => {
		return {
			is_main: isMain,
			//function id & name
			_id: _function.value,
			name: _function.label,
			//subCategory
			sector_id: subCategory.value,
			seniority: seniority.value
		};
	});
};
