import { useMutation } from "react-query";
import { client } from "lib/api-client";
import _has from "lodash/has";
import {
	HTTP_UPDATE_COMPANY_ADDITIONAL_INFO,
	UPDATE_SITE
} from "config/api-endpoints";

const deleteTask = data => {
	const isMultisite = _has(data, "site_id");
	return client(
		isMultisite ? UPDATE_SITE : HTTP_UPDATE_COMPANY_ADDITIONAL_INFO,
		{
			body: { ...data }
		}
	);
};

const useUpdateCompanyInfo = (options, queryOptions = {}) =>
	useMutation(deleteTask, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useUpdateCompanyInfo;
