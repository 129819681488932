import * as React from "react";
import { ReactComponent as OnlyMeIcon } from "static/icons/lock-03.svg";
import { ReactComponent as CustomIcon } from "static/icons/settings-02.svg";
import { ReactComponent as GlobeIcon } from "static/icons/globe-04.svg";
import * as yup from "yup";
import { capitalize } from "config/helpers";
import { queryCache } from "react-query";
import { KEY_FETCH_CANDIDATES } from "modules/SearchCandidate/hooks/useFilterCandidates";
import {
	KEY_FETCH_NOTES,
	KEY_FETCH_VACANCY_NOTES
} from "modules/candidate/Profile/Pages/Notes/hooks/useFilterNotes";
import { GET_NOTES_QUERY_KEY } from "modules/notes/notes-list/hooks/useGetNotesList";
import { GET_NOTE_DETAILS } from "modules/notes/notes-list/api/useGetNoteDetails";
import { CUSTOM, PRIVATE } from "../utils";
import get from "lodash/get";
import _findIndex from "lodash/findIndex";

export const VisibilityIcon = {
	private: { icon: <OnlyMeIcon />, tooltip: "Only i can see" },
	public: {
		icon: <GlobeIcon />,
		tooltip: "Anyone in my company can see"
	},
	custom: {
		icon: <CustomIcon height="17" width="17" />,
		tooltip: "Custom"
	}
};

// validate Form
export const schema = yup.object().shape({
	profile_id: yup
		.object()
		.nullable()
		.required("This field is required."),
	vacancy_id: yup.object().nullable(),
	description: yup.string().required("This field is required."),

	note_type: yup
		.object()
		.nullable()
		.required("This field is required.")
});
export const maxLength = 10000;

export const formatDataSteps = steps => {
	if (steps && steps.length > 0)
		return steps.map(step => {
			let newObj = {};
			newObj["label"] = capitalize(step.name);
			newObj["value"] = step._id;
			return newObj;
		});
};

export const invalidateQueries = () => {
	queryCache.invalidateQueries(GET_NOTES_QUERY_KEY);
	queryCache.invalidateQueries("list_notes");
	queryCache.invalidateQueries("list_users");
	queryCache.invalidateQueries("@candidate/userNote");
	queryCache.invalidateQueries("getNotes");
	queryCache.invalidateQueries("getDataPipeline");
	queryCache.invalidateQueries(KEY_FETCH_NOTES);
	queryCache.invalidateQueries(KEY_FETCH_VACANCY_NOTES);
	queryCache.invalidateQueries(KEY_FETCH_CANDIDATES);
	queryCache.invalidateQueries("get-notes");
	queryCache.invalidateQueries("RejectedList");
	queryCache.invalidateQueries(GET_NOTE_DETAILS);
};

export const getNoteTypes = value => {
	if (value) {
		const itemsCounter = value.length;
		//type move the last one to the first case
		const type = value[itemsCounter - 1];

		return [type, ...value.slice(0, itemsCounter - 1)];
	}
	return [];
};

export const isNotVisible = (visibility, mentions, note) => {
	if (visibility === PRIVATE && mentions.includes("all")) return true;

	const usersAndGroupsIds = [
		...get(note, "payload.not_visible.users", []),
		...get(note, "payload.not_visible.groups", [])
	].map(id => id);

	const mentionsInNotVisible = mentions.some(id =>
		usersAndGroupsIds.includes(id)
	);

	if (visibility === CUSTOM && mentionsInNotVisible) return true;

	return false;
};

export const getNoteDetails = (id, notes) => {
	const noteIndex = _findIndex(notes, { id }); //update mentions
	const note = notes[noteIndex];
	const visibility = note?.payload?.visibility || "public";
	const mentions = notes[noteIndex]?.payload?.mentions || [];
	return { visibility, mentions, note, noteIndex };
};
