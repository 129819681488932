import React, { Fragment } from "react";
import _isFunction from "lodash/isFunction";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import {
	TimesheetTableDetails,
	Tr,
	Th,
	Td
} from "../../../timesheets/components/TimesheetStyled";
import { CenterTooltip } from "common/styled/CommonStyled";
import GlobalTooltip from "common/GlobalTooltip";

export default function ListRenderer({
	list = [],
	thList = [],
	tdList = [],
	actions = [],
	onClick,
	connectedUser
}) {
	return (
		<TimesheetTableDetails spacing="0 3px">
			<thead>
				<Tr>
					{thList.map((th, index) => (
						<Th key={index}>{th}</Th>
					))}
					{actions && <Th width="10%">Actions</Th>}
				</Tr>
			</thead>
			<tbody>
				{list.map((item, index) => {
					return (
						<Tr key={index}>
							{tdList.map((td, index) => {
								return (
									<Td padding={"12px 18px"} key={index}>
										{_isFunction(td) ? td(item) : item[td]}
									</Td>
								);
							})}
							{actions && (
								<Td padding={"12px 18px"}>
									{item._id === connectedUser?.id ? (
										<CenterTooltip>
											<GlobalTooltip
												placement="top"
												overlay="You can’t manage your own role."
											/>
										</CenterTooltip>
									) : (
										<RcDropDown
											placement={"bottomCenter"}
											trigger={["click"]}
											align={{
												points: ["tc", "bc"],
												offset: [0, 5]
											}}
											overlay={
												<Menu
													onClick={e => onClick(item, e)}
													selectable={false}
												>
													{actions.map(
														(action, index) =>
															(!action.isVisible ||
																action.isVisible(item, list)) && (
																<MenuItem
																	key={action.key || index}
																	disabled={
																		action.isDisabled
																			? action.isDisabled(item, list)
																			: false
																	}
																>
																	<Fragment>
																		<i className={action.icon} />
																		{action.name}
																	</Fragment>
																</MenuItem>
															)
													)}
												</Menu>
											}
										>
											<div className="dots">
												<div className="dot" />
												<div className="dot" />
												<div className="dot" />
											</div>
										</RcDropDown>
									)}
								</Td>
							)}
						</Tr>
					);
				})}
			</tbody>
		</TimesheetTableDetails>
	);
}
