import React from "react";
import EmptyState from "modules/home/components/EmptyState.jsx";
import tasksemptystate from "./images/tasksemptystate.png";
import { ReactComponent as PlusIcon } from "../../../svg/Plus.svg";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

const TasksEmptyState = ({ heading }) => {
	const { setIsOpen } = useAddNewTaskStore();
	const handleClickCreateNewTask = () => setIsOpen(true);
	return (
		<EmptyState
			illustration={<img src={tasksemptystate} />}
			heading={heading}
			firstAction={{
				label: "Add new task",
				onClick: handleClickCreateNewTask,
				icon: <PlusIcon />
			}}
		/>
	);
};

export default TasksEmptyState;
