import React from "react";
import { AdvancedTabsAdvancedCard } from "../AdvancedTabsAdvancedCard";
import { AdvancedTabsKeywordsCard } from "../AdvancedTabsKeywordsCard";
import styles from "./filter-drawer-advanced-tab.module.scss";
import { useFilters } from "../hooks/useFilter";

const FilterDrawerAdvancedTab = props => {
	const { hideTabSaved, placeHolder, onlyList } = props;
	const { getState, currentFilterId } = useFilters();
	const { displayKeywordCard } = getState(currentFilterId);
	return (
		<div className={styles.container}>
			{displayKeywordCard && !hideTabSaved && (
				<AdvancedTabsKeywordsCard placeHolder={placeHolder} />
			)}
			<AdvancedTabsAdvancedCard
				hideTabSaved={hideTabSaved}
				onlyList={onlyList}
				displayAdvancedSearchHead={props.displayAdvancedSearchHead}
			/>
		</div>
	);
};

export default FilterDrawerAdvancedTab;
