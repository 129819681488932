import { ButtonGroup } from "common/ButtonGroup";
import { DropDownInput } from "common/DropDownInput";
import React, { forwardRef, useEffect, memo } from "react";
import {
	OverlayContainer,
	useButton,
	useOverlayPosition,
	useOverlayTrigger
} from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import { ReactComponent as ResetIcon } from "static/icons/reset-icon.svg";
import SearchInput from "../SearchInput/SearchInput";
import styles from "./drop-down-with-search.module.scss";
import { Popover } from "./Popover";
import { ReactComponent as IconLeft } from "../../static/icons/IconLeft.svg";
import cx from "classnames";

const DropDownWithSearch = (
	{
		popoverContainer,
		content,
		placeHolder,
		value,
		placement = "bottom end",
		rootClassname,
		displayPaper,
		onInputClick,
		searchResultContent,
		onSearchChange,
		displayFooter,
		onResetClick,
		onConfirmClick,
		searchValue,
		paperClassName,
		onClose,
		dropDownInputClassName,
		hideResetButton = false,
		variant,
		showAddBtn = false,
		addButtonComponent,
		displaySelectedValues = true,
		displaySearchInput = true,
		chevron
	},
	ref
) => {
	let state = useOverlayTriggerState({});

	let triggerRef = React.useRef();
	let overlayRef = React.useRef();
	const searchRef = React.useRef();

	let { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		state,
		triggerRef
	);

	let { overlayProps: positionProps } = useOverlayPosition({
		targetRef: triggerRef,
		overlayRef,
		placement: placement,
		offset: 5,
		isOpen: displayPaper
	});

	let { buttonProps } = useButton(
		{
			onClick: () => onInputClick()
		},
		triggerRef
	);

	const buttons = [
		{
			text: "Confirm",
			variant: "contained",
			className: styles.button,
			onClick: onConfirmClick
		}
	];

	if (!hideResetButton) {
		buttons.unshift({
			text: "Reset",
			variant: "text",
			className: styles.button,
			icon: <ResetIcon />,
			onClick: onResetClick
		});
	}

	useEffect(() => {
		searchRef.current?.focus();
	}, [searchRef?.current]);

	return (
		<div className={cx(styles.root, rootClassname)} ref={ref}>
			<div className={styles.inputWrapper}>
				{displaySelectedValues && (
					<DropDownInput
						arrowDirection={displayPaper ? "down" : "top"}
						placeHolder={placeHolder}
						value={value}
						{...buttonProps}
						{...triggerProps}
						ref={triggerRef}
						onInputClick={onInputClick}
						className={dropDownInputClassName}
						showAddBtn={showAddBtn}
						displaySelectedValues={displaySelectedValues}
						chevron={chevron}
					/>
				)}

				{displaySelectedValues && showAddBtn && (
					<button className={styles.addBtn} {...buttonProps} {...triggerProps}>
						<IconLeft />
					</button>
				)}
				{addButtonComponent && (
					<div className={styles.flexContainer}>
						{addButtonComponent({
							...buttonProps,
							...triggerProps,
							ref: triggerRef
						})}
					</div>
				)}
			</div>
			{displayPaper && (
				<OverlayContainer portalContainer={popoverContainer}>
					<Popover
						{...overlayProps}
						{...positionProps}
						ref={overlayRef}
						isOpen={displayPaper}
						onClose={onClose}
						paperClassName={paperClassName}
						paperStyle={{
							width:
								variant === "parent-width"
									? `${triggerRef.current?.offsetWidth}px`
									: "380px",
							minWidth: `${triggerRef.current?.offsetWidth}px`
						}}
					>
						{displaySearchInput && (
							<div className={styles.searchWrapper}>
								<SearchInput
									className={styles.searchInput}
									value={searchValue}
									onChange={onSearchChange}
									ref={searchRef}
									variant="borderless"
								/>
								{searchResultContent}
							</div>
						)}
						<div className={styles.content}>{content}</div>
						{displayFooter && (
							<div className={styles.footer}>
								<ButtonGroup
									hideResetButton={hideResetButton}
									gap=".5rem"
									buttons={buttons}
								/>
							</div>
						)}
					</Popover>
				</OverlayContainer>
			)}
		</div>
	);
};

export default memo(forwardRef(DropDownWithSearch));
