import React from "react";
import { ReactComponent as RecordingIcon } from "static/icons/recording-icon.svg";
import { ReactComponent as GraduateHatIcon } from "static/icons/graduation-hat-icon.svg";
import { ReactComponent as TranslateIcon } from "static/icons/translate-icon.svg";
import { ReactComponent as ShortIcon } from "static/icons/short.svg";
import { ReactComponent as LongIcon } from "static/icons/long.svg";
import { createCommand } from "lexical";

export const aiToolsOptions = [
	{
		id: 1,
		label: "Simplify",
		action: "simplify",
		icon: <RecordingIcon />
	},
	{
		id: 2,
		label: "Grammar",
		action: "grammar",
		icon: <GraduateHatIcon />
	},

	{
		id: 3,
		label: "Make shorter",
		action: "make_shorter",
		icon: <ShortIcon />
	},

	{
		id: 4,
		label: "Make longer",
		action: "make_longer",
		icon: <LongIcon />
	},
	{
		id: 5,
		label: "Change Tone",
		action: "change_tone",
		icon: <RecordingIcon />,
		children: [
			{
				label: "Academic",
				value: "academic",
				action: "change_tone"
			},
			{
				label: "Business",
				value: "business",
				action: "change_tone"
			},
			{
				label: "Casual",
				value: "casual",
				action: "change_tone"
			},
			{
				label: "Conversational",
				value: "conversational",
				action: "change_tone"
			},
			{
				label: "Emotional",
				value: "emotional",
				action: "change_tone"
			},
			{
				label: "Humorous",
				value: "humorous",
				action: "change_tone"
			},
			{
				label: "Informative",
				value: "informative",
				action: "change_tone"
			}
		]
	},
	{
		id: 6,
		label: "Translate",
		icon: <TranslateIcon />,
		children: [
			{
				label: "Arabic",
				value: "arabic",
				action: "translate"
			},
			{
				label: "English",
				value: "english",
				action: "translate"
			},
			{
				label: "French",
				value: "french",
				action: "translate"
			},
			{
				label: "Spanish",
				value: "spanish",
				action: "translate"
			},
			{
				label: "Italian",
				value: "italian",
				action: "translate"
			},
			{
				label: "Dutch",
				value: "dutch",
				action: "translate"
			}
		]
	}
];

export const PLACEHOLDER_AI_TOOLS_COMMAND = createCommand(
	"PLACEHOLDER_AI_TOOLS_COMMAND"
);
