import create from "zustand";

export const viewTypes = {
	requests: "requests",
	candidates: "candidates",
	interviews: "interviews",
	contract: "contract"
};

const state = {
	activeView: viewTypes.requests
};

export const useRequestsModuleActiveTab = create(set => ({
	...state,
	setActiveView: value => {
		set(() => ({ activeView: value }));
	}
}));
