import React from "react";
import s from "modules/vacancy-view/components/jobDetails/job-details.module.scss";

export const SidebarDetails = ({ icon, title, value }) => {
	return value ? (
		<div className={s.jobContainer}>
			<div className={s.firstRow}>
				{icon}
				<div className={s.jobTitle}> {title} </div>
			</div>
			<div className={s.jobValue}>{value}</div>
		</div>
	) : null;
};

export default SidebarDetails;
