import React, { Component } from "react";

import BodyClassName from "react-body-classname";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import _get from "lodash/get";
import _find from "lodash/find";
import { LoaderFull } from "common/Loader";
import {
	DATE_FORMAT,
	EUR_PER_HOUR,
	DELETED,
	DRAFT,
	LIST_TITLE_MAX_REQUESTS,
	NO_DATA_PLACEHOLDER,
	JOBS_EMPTY_MESSAGE,
	REQUEST_STATUS,
	ALL_EXCEPT_ARCHIVED
} from "config";
import { isDateValid, excerpt } from "common/Functions";
import CompanyDashboard from "common/dashboard/Dashboard";
import EmptyJob from "common/EmptyComponent";
import Pagination from "common/Pagination";
import StopJob from "../modals/StopJob";
import features from "config/features/GetFeatureFromLocalStorage";
import CurrencyFormatter from "common/CurrencyFormater";
import Intro from "common/dashboard/Intro";
import { mergeFeatures } from "common/Functions";
import FilterByStatus from "../FilterByStatus";
import ListActions from "../ListActions";
import { getJobProfileRoute } from "../jobprofile/JobProfileContainer";
import { getJobRequestRoute } from "../index";
import GlobalTooltip from "common/GlobalTooltip";
import { Button } from "common/styled/buttons";
import Icon from "common/styled/icons";
import {
	TimesheetTableDetails,
	Th,
	Tr,
	Td
} from "modules/timesheets/components/TimesheetStyled";
import StatusLabel from "common/StatusLabel";
import { IconContainer, BidListContainer, ScrollableTable } from "./styled";
import BidsList from "./bidsList";
import NotificationJob from "../NotificationsJob";

class JobList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedJob: null
		};
	}

	componentDidMount() {
		this.props.filterJobsList({ offset: 0 }, ALL_EXCEPT_ARCHIVED);
	}

	jobHasStopped = () => {
		const {
			list_data: { offset, filterByStatus }
		} = this.props;
		const payload = {
			offset: offset
		};
		if (filterByStatus !== ALL_EXCEPT_ARCHIVED) payload.status = filterByStatus;
		this.getJobsList(payload);
	};

	filterList = status => {
		const payload = {
			offset: 0
		};
		if (status !== ALL_EXCEPT_ARCHIVED) payload.status = status;
		this.props.filterJobsList(payload, status);
	};

	getJobsList(payload) {
		this.props.getJobsList(payload);
	}

	viewJobDetails = (e, id, step, status) => {
		if (status === DELETED && step < 3) {
			return;
		}
		if (!e.target.classList.contains("ignore-click")) {
			const link = step !== 3 ? getJobRequestRoute(id) : `/job/${id}`;
			browserHistory.push(link);
		}
	};

	stopPropagation(e) {
		if (e) e.stopPropagation();
	}

	expandJobsList = id => {
		const { selectedJob } = this.state;
		const { getBidsListByJob } = this.props;
		if (id === selectedJob) {
			this.setState({
				selectedJob: null
			});
		} else {
			getBidsListByJob(id);
			this.setState({
				selectedJob: id
			});
		}
	};

	showListJobs = () => {
		const {
			list_data: { listJobs },
			bidsList,
			totalBids
		} = this.props;
		const { selectedJob } = this.state;
		let allFeatures = mergeFeatures(features);

		return listJobs.map((job, index) => {
			const rowDisabled = job.status === DELETED && job.step < 3;
			const iconDisabled = job.number_bids === 0;
			const isLastItem = index === listJobs.length - 1 && listJobs.length !== 1;
			const countOfBids = _get(job, "view_application_activity.bid", 0);
			const countOfContract = _get(
				job,
				"view_application_activity.contract_proposition",
				0
			);
			const countOfInterview = _get(
				job,
				"view_application_activity.interview",
				0
			);
			return (
				<GlobalTooltip
					withWrappingDiv={false}
					eventTrigger="click"
					placement="top"
					key={job.id}
					align="center"
					active={rowDisabled}
					overlay="This request can't be previewed as it was archived without a title or a description.
					You can edit it by using the 'Duplicate' button in the action column (right)."
				>
					<>
						<Tr
							key={job.id}
							onClick={e =>
								this.viewJobDetails(e, job.id, job.step, job.status)
							}
							className={rowDisabled ? "disabledTr" : ""}
							selectedBid={job.id === selectedJob}
							style={{ position: "relative" }}
						>
							<Td onClick={event => event.stopPropagation()}>
								<IconContainer
									onClick={() => !iconDisabled && this.expandJobsList(job.id)}
									rotate={job.id === selectedJob}
									cursor={iconDisabled && "not-allowed"}
									className={iconDisabled && "disabledColor"}
								>
									<i
										className={
											job.id === selectedJob
												? "icon-down-arrow rotate-icon"
												: "icon-down-arrow"
										}
									/>
								</IconContainer>
							</Td>
							<Td>{job.job_ref ? job.job_ref : NO_DATA_PLACEHOLDER}</Td>
							<Td>
								{job.status !== DRAFT &&
								job.status !== DELETED &&
								isDateValid(job.publish_date, true)
									? window.moment.unix(job.publish_date).format(DATE_FORMAT)
									: NO_DATA_PLACEHOLDER}
							</Td>
							<Td className="title">
								{job.title ? (
									<GlobalTooltip
										placement="top"
										overlay={job.title}
										withWrappingDiv={false}
										overlayClassName="g-tool-dark"
										active={job.title.length > LIST_TITLE_MAX_REQUESTS}
									>
										<div className="_input_color_">
											{excerpt(job.title, LIST_TITLE_MAX_REQUESTS)}
										</div>
									</GlobalTooltip>
								) : (
									NO_DATA_PLACEHOLDER
								)}
							</Td>
							<Td>
								{job.hourly_budget ? (
									<CurrencyFormatter
										cent={job.hourly_budget}
										symbol={EUR_PER_HOUR}
									/>
								) : (
									NO_DATA_PLACEHOLDER
								)}
							</Td>
							<Td>
								{`${
									isDateValid(job.start_date, true)
										? window.moment.unix(job.start_date).format(DATE_FORMAT)
										: "-"
								}  ${
									isDateValid(job.end_date, true)
										? window.moment.unix(job.end_date).format(DATE_FORMAT)
										: "-"
								}`}
							</Td>
							<Td className="bids">
								{!allFeatures.isFeatureEnabled("list_bids") ? (
									<span className="no-bide">{NO_DATA_PLACEHOLDER}</span>
								) : (
									<>
										{countOfBids > 0 && (
											<NotificationJob
												rightDisplay={"-16px"}
												count={countOfBids}
											/>
										)}
										<span>{job.number_bids}</span>
									</>
								)}
							</Td>
							<Td className="bids">
								{!allFeatures.isFeatureEnabled("list_bids") ? (
									<span className="no-bide">{NO_DATA_PLACEHOLDER}</span>
								) : (
									<>
										{countOfInterview > 0 && (
											<NotificationJob
												rightDisplay={"6px"}
												count={countOfInterview}
											/>
										)}
										<span>{job.count_interviews}</span>
									</>
								)}
							</Td>
							<Td className="bids">
								{!allFeatures.isFeatureEnabled("list_bids") ? (
									<span className="no-bide">{NO_DATA_PLACEHOLDER}</span>
								) : (
									<>
										{countOfContract > 0 && (
											<NotificationJob count={countOfContract} />
										)}
										<span>{job.count_contracts}</span>
									</>
								)}
							</Td>
							<Td align="center" onClick={this.stopPropagation}>
								<StatusLabel
									onClick={() => this.filterList(job.status)}
									status={job.status}
									label={_get(
										_find(
											REQUEST_STATUS,
											status => job.status === status.value
										),
										"name",
										""
									)}
								/>
							</Td>
							<ListActions job={job} reposition={true}>
								<Td onClick={this.stopPropagation}>
									<div className="dots ignore-click">
										<div className="dot ignore-click" />
										<div className="dot ignore-click" />
										<div className="dot ignore-click" />
									</div>
								</Td>
							</ListActions>
							<Td>
								{bidsList.length !== 0 && job.id === selectedJob && (
									<BidListContainer bottom={isLastItem}>
										<BidsList application_list={bidsList} total={totalBids} />
									</BidListContainer>
								)}
							</Td>
						</Tr>
					</>
				</GlobalTooltip>
			);
		});
	};

	publishNewRequest = () => {
		this.props.resetJobData();
		browserHistory.push(getJobProfileRoute(""));
	};

	handlePageClick = value => {
		const {
			list_data: { size, filterByStatus }
		} = this.props;
		const payload = {
			offset: size * value
		};
		if (filterByStatus !== ALL_EXCEPT_ARCHIVED) payload.status = filterByStatus;
		this.getJobsList(payload);
	};

	render() {
		const {
			list_data,
			route: { title: routeTitle },
			stopJob
		} = this.props;
		if (list_data.isFetching)
			return <LoaderFull boxClasses="no-text" classes="full-loader" />;
		const is_empty = list_data.listJobs.length <= 0;
		return (
			<BodyClassName className="my-job list-jobs gray-bg">
				<div className="jobs-list-preview">
					{routeTitle && (
						<Helmet>
							<title>{routeTitle}</title>
						</Helmet>
					)}
					<div className="dashboard-box">
						<CompanyDashboard subClass="requests-list dashboard" />
						<Intro>
							<p>
								The “Requests” section allows you to publish and manage mission
								requests.
							</p>
						</Intro>
					</div>

					<div className="container">
						<div className="top-actions">
							<Button
								size={14}
								style={{
									paddingTop: 12,
									paddingBottom: 12
								}}
								onClick={this.publishNewRequest}
							>
								<Icon className="fa fa-plus" />
								Create a new request
							</Button>

							<FilterByStatus filterList={this.filterList} />
						</div>

						{is_empty && (
							<EmptyJob
								title={
									JOBS_EMPTY_MESSAGE.find(
										message => message.value === list_data.filterByStatus
									).label
								}
							/>
						)}
						{!is_empty && (
							<div>
								{stopJob && stopJob.id && (
									<StopJob onSuccess={this.jobHasStopped} />
								)}
								<ScrollableTable>
									<TimesheetTableDetails
										className="request-list-expand"
										spacing="0 3px"
										width="100%"
									>
										<thead>
											<Tr>
												<Th width="5%" />
												<Th width="6%" className="hefhed">
													ref
												</Th>
												<Th width="11%">posted date</Th>
												<Th width="15%">title</Th>
												<Th width="8%">rate</Th>
												<Th width="10%">start / end date</Th>
												<Th align="center" width="5%" className="bids">
													bids
												</Th>
												<Th align="center" width="9%">
													Interviews
												</Th>
												<Th align="center" width="10%">
													Contract proposition
												</Th>
												<Th align="center" width="13%">
													status
												</Th>
												<Th width="8%">actions</Th>
											</Tr>
										</thead>
										<tbody>{this.showListJobs()}</tbody>
									</TimesheetTableDetails>
								</ScrollableTable>
								{list_data.isPagination && (
									<Pagination
										total={list_data.total}
										size={list_data.size}
										handlePageClick={this.handlePageClick}
										offset={list_data.offset}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</BodyClassName>
		);
	}
}

export default JobList;
