import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { VIEW_COMPANY_ENDPOINT } from "config/api-endpoints";
import { CONSULTANCY } from "config";

function viewSupplier(id) {
	return client(VIEW_COMPANY_ENDPOINT, {
		body: {
			type: CONSULTANCY,
			id
		}
	});
}

export const viewSupplierQueryKey = "@supplier/view-company";

function useViewSupplier({ id }) {
	return useQuery([viewSupplierQueryKey, id], () => viewSupplier(id), {
		refetchOnWindowFocus: false,
		retry: 0
	});
}

export default useViewSupplier;
