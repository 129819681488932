import { LIST_NOTIFICATIONS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useInfiniteQuery } from "react-query";
import get from "lodash/get";

const getSystemNootifications = (_, body, offset = 0) =>
	client(LIST_NOTIFICATIONS, {
		body: {
			...body,
			offset
		}
	});

export const GET_SYSTEM_NOTIFICATIONS_KEY = "GET_SYSTEM_NOTIFICATIONS_KEY";

const useGetSystemNotifications = (body, options = {}) => {
	const result = useInfiniteQuery(
		[GET_SYSTEM_NOTIFICATIONS_KEY, body],
		getSystemNootifications,
		{
			...options,
			retry: 0,
			refetchOnWindowFocus: false
		}
	);
	return {
		...result,
		total: get(result, "[0].pagination.total", 0)
	};
};

export default useGetSystemNotifications;
