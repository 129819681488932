import React from "react";
import { Box } from "rebass";

const BoxContainer = ({ children, isClient }) => {
	return (
		<Box
			sx={{
				...(isClient && {
					background: "#FFFFFF",

					border: "1px solid #D9DBE9",

					boxShadow: "0px 1px 1px #D9DBE9",
					borderRadius: 6,
					padding: "30px"
				})
			}}
		>
			{children}
		</Box>
	);
};

export default BoxContainer;
