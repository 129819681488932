import { createSelector } from "reselect";
import _get from "lodash/get";
import { ADMIN } from "config";

const getCompany = state => _get(state, "company");
const currentCompanySelector = state =>
	_get(state, "company.currentCompanySelector");
const getLightCVAddedSkills = state =>
	_get(state, "company.lightCVAddedSkills");
const getLightCVAddedLanguages = state =>
	_get(state, "company.lightCVAddedLanguages");
const getShownModal = state => _get(state, "company.showModal");
const getSavedEducation = state => _get(state, "company.lightCVAddedEducation");
const getSavedExperience = state =>
	_get(state, "company.lightCVAddedExperience");
const getSubmitted = state => _get(state, "company.submitted");
const getSubmitting = state => _get(state, "company.submitting");
const getCompanyInfo = state => _get(state, "company.currentCompany");
const getUploaderMethod = state => _get(state, "company.uploadMethod");
const getUserBUList = state => {
	return _get(state, "usersList.users.groups_list", []);
};
const getUserManagerBUList = state =>
	_get(state, "usersList.users.manager_groups_list", []);
const getUsersList = (state, role) => {
	return {
		list: _get(state, "usersList.users.users_list", []),
		role: role || ADMIN
	};
};

export const companyInfoSelector = createSelector(
	getCompanyInfo,
	currentCompany => currentCompany
);

export const uploaderMethodSelector = createSelector(
	getUploaderMethod,
	uploaderMethod => uploaderMethod
);

export const getCurrentCompany = createSelector(
	currentCompanySelector,
	currentCompany => currentCompany
);

export const lightCVAddedSkillsSelector = createSelector(
	getLightCVAddedSkills,
	skills => skills
);

export const lightCVAddedLanguagesSelector = createSelector(
	getLightCVAddedLanguages,
	languages => languages
);

export const showModalSelector = createSelector(
	getShownModal,
	modalName => modalName
);

export const savedEducationSelector = createSelector(
	getSavedEducation,
	education => education
);

export const savedExperienceSelector = createSelector(
	getSavedExperience,
	experience => experience
);

export const submittedSelector = createSelector(
	getSubmitted,
	submitted => submitted
);

export const submittingSelector = createSelector(
	getSubmitting,
	submitting => submitting
);

export const companySelector = createSelector(getCompany, company => company);

export const userBUListSelector = createSelector(getUserBUList, list => list);

export const userManagerBUListSelector = createSelector(
	getUserManagerBUList,
	manager_list => manager_list
);

// to use in both BUM list and Consultants list
export const BUMListSelector = createSelector(getUsersList, ({ list }) => {
	return list;
});
