import React from "react";
import styles from "../../saved-filters-tab.module.scss";

const EmptyData = ({ icon, title, description, children }) => {
	return (
		<div className={styles.noDataContainer}>
			{icon}
			<div className={styles.descriptionContainer}>
				<div className={styles.title}>{title}</div>
				<div className={styles.description}>{description}</div>
			</div>
			{children}
		</div>
	);
};

export default EmptyData;
