import React, { useEffect } from "react";
import _get from "lodash/get";
import { useQuery } from "react-query";

import Stepper from "./Stepper";
import { CreateRequestContainer } from "./Styled";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import JobProfile from "./JobProfile";
import { GET_LAST_JOB } from "config/api-endpoints";
import { client } from "lib/api-client";
import { PROFILE, REQUEST } from "config";
import JobDetails from "./JobDetails";

export const getCreateRequestRoute = () => "/jobs/post";

export const getCreateRequestPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export const getLastJob = (key, id, step) => {
	if (id || step === REQUEST) {
		let body = {
			job_type: REQUEST
		};
		if (id) {
			body.id = id;
		}
		return client(GET_LAST_JOB, {
			body
		});
	}
};

const CreateRequest = ({ location }) => {
	const pathnameSplit = _get(location, "pathname", "").split("/");

	const step = pathnameSplit[3];
	const id = pathnameSplit[4];

	const { data } = useQuery(["get-last-job", id, step], getLastJob);

	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([{ name: "Temporary" }, { name: "Requests", path: "/jobs/list" }]);
	}, []);

	return (
		<CreateRequestContainer>
			<Stepper step={step} />
			{step === PROFILE && (
				<JobProfile
					category={_get(data, "category")}
					department={_get(data, "group._id")}
					subCategory={_get(data, "subcategory")}
					langs={_get(data, "languages", [])}
					initialSkills={_get(data, "skills", [])}
					initialFunc={_get(data, "function")}
					_id={_get(data, "_id")}
					initialSeniority={_get(data, "seniority")}
				/>
			)}
			{step === REQUEST && (
				<JobDetails
					_id={_get(data, "_id")}
					jobId={id}
					jobTitle={_get(data, "title")}
					jobDescription={_get(data, "description")}
					location_type={_get(data, "location_type")}
					applicantsLocation={_get(data, "applicants_location")}
					addressObj={{
						box: _get(data, "box"),
						street: _get(data, "street"),
						number: _get(data, "number"),
						latitude: _get(data, "latitude"),
						longitude: _get(data, "longitude"),
						city: _get(data, "city"),
						country: _get(data, "country"),
						iso_country: _get(data, "iso_country")
					}}
					contract_type={_get(data, "contract_type")}
					full_time={_get(data, "full_time")}
					part_time={_get(data, "part_time")}
					hourly_budget={_get(data, "budget")}
					start_date={_get(data, "start_date")}
					end_date={_get(data, "end_date")}
					jobStep={_get(data, "step")}
					reporting_settings={_get(data, "reporting_settings")}
				/>
			)}
		</CreateRequestContainer>
	);
};

export default CreateRequest;
