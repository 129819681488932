import ProfileAvatar from "../Header/profileAvatar";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Flex, Box, Text } from "rebass";

const ProfileSkeleton = ({ children }) => {
	return (
		<React.Fragment>
			<Box
				sx={{
					borderRadius: 6,
					boxShadow: "0 0 6px 4px rgba(0, 0, 0, 0.04)",
					padding: "16px",
					backgroundColor: "#ffffff",
					marginBottom: 4
				}}
			>
				<Flex
					alignItems="center"
					alignContent="center"
					justifyContent="space-between"
				>
					<Flex width="46%">
						<Box mr={2} mb={2} alignSelf="flex-start">
							<ProfileAvatar
								backgroundColor="#818a92"
								size={80}
								isFetching={true}
								status="inactive"
							/>
						</Box>

						<Box alignSelf="center">
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={120} height={10} />
							</SkeletonTheme>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={120} height={10} />
							</SkeletonTheme>
							<Flex>
								<Box mr={2}>
									<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
										<Skeleton width={50} height={10} />
									</SkeletonTheme>
								</Box>

								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={80} height={10} />
								</SkeletonTheme>
							</Flex>
						</Box>
					</Flex>
					<Flex width="44%" alignItems="center">
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={80} height={20} />
						</SkeletonTheme>
						<Box alignSelf="center" mx={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={50} height={20} />
							</SkeletonTheme>
						</Box>
						<Box alignSelf="center" mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={50} height={20} />
							</SkeletonTheme>
						</Box>
						<Box alignSelf="center" mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={50} height={20} />
							</SkeletonTheme>
						</Box>

						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={50} height={20} />
						</SkeletonTheme>

						<Box
							marginLeft={3}
							marginRight={3}
							sx={{ borderRight: "2px solid #dfe3e8", height: "40px" }}
						></Box>
						<Box>
							<SkeletonTheme
								style={{ borderRadius: 10, marginRight: "8px" }}
								color="#dfe3e8"
							>
								<Skeleton width={60} height={14} />
							</SkeletonTheme>

							<Box mt={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={100} height={14} />
								</SkeletonTheme>
							</Box>
						</Box>
					</Flex>

					<Flex justifyContent="flex-end" width="10%">
						<Box mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={40} height={25} />
							</SkeletonTheme>
						</Box>

						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={40} height={25} />
						</SkeletonTheme>
					</Flex>
				</Flex>
			</Box>
			<Flex justifyContent="space-between">
				<Box minHeight="800px" alignItems="center" flex="1" pr={"20px"}>
					<Flex
						alignItems="center"
						sx={{
							height: "63px"
						}}
					>
						<Text
							sx={{
								fontSize: "16px",
								color: "#212b36",
								fontWeight: 600
							}}
						>
							Resume (CV)
						</Text>
					</Flex>
					<Box
						p={4}
						sx={{
							border: "14px solid #dfe3e8",
							borderRadius: "6px",
							backgroundColor: "#ffffff"
						}}
						minHeight="800px"
					>
						<Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={200} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={190} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={300} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={400} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={400} height={14} />
								</SkeletonTheme>
							</Box>
						</Box>
						<Box my={5}>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={200} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={300} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={400} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={300} height={14} />
								</SkeletonTheme>
							</Box>
						</Box>
						<Box mb={5}>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={200} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={300} height={14} />
								</SkeletonTheme>
							</Box>
							<Box mr={2} mb={2}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={400} height={14} />
								</SkeletonTheme>
							</Box>
						</Box>
					</Box>
				</Box>
				{children}
			</Flex>
		</React.Fragment>
	);
};

export default ProfileSkeleton;
