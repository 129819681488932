import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field, formValueSelector, initialize } from "redux-form";
import PropTypes from "prop-types";
import _get from "lodash/get";
import {
	DATE_FORMAT,
	CURRENCY_SUFFIX,
	MAX_CURRENCY_VALUE,
	TEXTAREA_MAX_LENGTH_500
} from "config";
import CurrencyField from "common/NewCurrencyField";
import DatePickerField from "common/DatePickerField";
import FormField from "common/FormField";
import Modal from "common/modal/Modal";
import { getHourlyCost } from "common/Functions";
import { utcTimeStamp } from "config/helpers";
import { onlineUserSelector } from "modules/user/selectors/selectors.js";
import { ExtendMissionContainer } from "./styles";
import { FEATURE_DISPLAY_COST } from "config";
class ExtendMissionModal extends Component {
	constructor() {
		super();
		this.state = {
			loading: false
		};
	}

	componentDidMount() {
		const { mission } = this.props;

		const price = _get(mission, "new_price");
		this.initializeReduxForm({
			price: price,
			start_date: window.moment.unix(_get(mission, "end_date")).add(1, "day"),
			hourly_cost: getHourlyCost(
				price,
				_get(mission, "vms_settings"),
				_get(mission, "reporting_settings")
			)
		});
	}

	initializeReduxForm = data => {
		this.props.initializeReduxForm({
			...this.props.formFields,
			...data
		});
	};

	hourlyRateChanged = (e, value) => {
		this.initializeReduxForm({
			hourly_cost: getHourlyCost(
				value,
				_get(this.props, "mission.vms_settings"),
				_get(this.props, "mission.reporting_settings")
			)
		});
	};

	formSubmitted = formProps => {
		const { extendMission, mission } = this.props;

		const start_date = utcTimeStamp({ date: formProps.start_date });
		const end_date = utcTimeStamp({ date: formProps.end_date, isStart: false });

		this.setState({
			loading: true
		});

		const payload = {
			price: formProps.price,
			start_date,
			end_date,
			description: formProps.description,
			id: mission._id
		};

		extendMission(payload).catch(() =>
			this.setState({
				loading: false
			})
		);
	};

	closeModal = () => {
		this.props.closeExtendModal();
	};

	startDateChanged = value => {
		const {
			formFields: { end_date }
		} = this.props;
		const moment = window.moment;
		const startDate = moment(value, DATE_FORMAT);
		const endDate =
			typeof end_date === "object"
				? end_date
				: moment.unix(end_date, DATE_FORMAT);

		if (endDate && moment(startDate).isAfter(endDate, "day")) {
			this.initializeReduxForm({ end_date: "" });
		}
	};

	render() {
		const {
			handleSubmit,
			mission: { end_date, reporting_settings },
			formFields: { start_date },
			user
		} = this.props;

		const minStartDate = window.moment.unix(end_date).add(1, "day");
		const minEndDate = start_date
			? window
					.moment(start_date, DATE_FORMAT)
					.add(1, "day")
					.toDate()
			: minStartDate;

		const displayCostEnabled = (user?.features || []).includes(
			FEATURE_DISPLAY_COST
		);

		return (
			<Modal
				loading={this.state.loading}
				title="Extension Proposition"
				onClose={this.closeModal}
				className="contract-modal"
				fixed={true}
				intro={"Please fill the details of your extension proposition."}
				confirmMessage={
					"Once the extension proposition is accepted by the freelance, wiggli will generate the contracts related to the extension of the mission."
				}
				firstButton={{
					label: "cancel",
					action: this.closeModal,
					type: "outlined"
				}}
				secondButton={{
					label: "extend",
					action: handleSubmit(this.formSubmitted),
					type: "primary",
					style: { width: "200px" }
				}}
			>
				<ExtendMissionContainer className="content">
					<div className="row ">
						<div className="col-md-5">
							<Field
								name="price"
								label={`${_get(reporting_settings, "rate_type")} Rate`}
								suffix={
									_get(reporting_settings, "currency.symbol") +
									CURRENCY_SUFFIX[_get(reporting_settings, "rate_type")]
								}
								component={CurrencyField}
								onChange={this.hourlyRateChanged}
							/>
						</div>

						{displayCostEnabled && (
							<div className="col-md-5">
								<Field
									name="hourly_cost"
									label={`${_get(reporting_settings, "rate_type")} Cost`}
									suffix={
										_get(reporting_settings, "currency.symbol") +
										CURRENCY_SUFFIX[_get(reporting_settings, "rate_type")]
									}
									component={CurrencyField}
									disabled={true}
								/>
							</div>
						)}
					</div>

					<div className="row">
						<div className="col-sm-5">
							<Field
								tabIndex={0}
								classes="inner-icon right-icon"
								icon="fa fa-calendar"
								name="start_date"
								label="Extension Start Date"
								minDate={minStartDate}
								component={DatePickerField}
								onChange={this.startDateChanged}
							/>
						</div>
						<div className="col-sm-5">
							<Field
								tabIndex={0}
								classes="inner-icon right-icon"
								icon="fa fa-calendar"
								name="end_date"
								minDate={minEndDate}
								label="Extension End date"
								component={DatePickerField}
							/>
						</div>
					</div>

					<div className="row">
						<div className="col-md-11">
							<Field
								name="description"
								label="ADDITIONAL COMMENTS"
								type="textarea"
								className="col-md-11"
								component={FormField}
								maxLength={TEXTAREA_MAX_LENGTH_500}
								placeholder="These comments will only be seen by wiggli’s support team"
							/>
						</div>
					</div>
				</ExtendMissionContainer>
			</Modal>
		);
	}
}

const validate = (formProps, props) => {
	const {
		mission: { reporting_settings }
	} = props;
	const errors = {};

	if (!formProps.price)
		errors.price = `Please define an ${_get(
			reporting_settings,
			"rate_type"
		)} rate.`;

	if (
		formProps.price &&
		(formProps.price <= 0 || formProps.price > MAX_CURRENCY_VALUE)
	) {
		errors.price = `The ${_get(
			reporting_settings,
			"rate_type"
		)} rate should be between 1€ and 1000€`;
	}

	if (!formProps.start_date)
		errors.start_date = "Please specify an extend start date.";

	if (!formProps.end_date)
		errors.end_date = "Please specify an extend end date.";

	if (
		formProps.description &&
		formProps.description.length > TEXTAREA_MAX_LENGTH_500
	) {
		errors.description = `This field should not exceed ${TEXTAREA_MAX_LENGTH_500} characters`;
	}

	return errors;
};

const selector = formValueSelector("extend-mission");

const mapStateToProps = state => {
	return {
		user: onlineUserSelector(state),
		formFields: selector(
			state,
			"price",
			"hourly_cost",
			"start_date",
			"end_date",
			"description"
		)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initializeReduxForm: data => dispatch(initialize("extend-mission", data))
	};
};

ExtendMissionModal.propTypes = {
	mission: PropTypes.object.isRequired
};

ExtendMissionModal.defaultProps = {
	active: false,
	mission: {}
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "extend-mission",
		validate,
		touchOnBlur: false
	})
)(ExtendMissionModal);
