import React, { useState, useEffect } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { Text } from "rebass";
import { bindActionCreators } from "redux";
import { useMutation } from "react-query";
import _get from "lodash/get";
import FormField from "common/FormField";
import Modal from "common/modal";
import PhoneNumberField from "common/PhoneNumberField";
import {
	UPDATE_PROFILE_ADDITIONAL_INFO,
	UPLOAD_CV
} from "config/api-endpoints";
import { client } from "lib/api-client";
import { getMyProfile } from "modules/user/actions/authActions";
import { LOADING, RESPONSE_CODE_422, PUBLIC, INACTIVE, PASSIVE } from "config";
import toaster from "common/Toaster";
import { FieldBlock } from "./styled";
import AxiosHelper from "config/axios-helper";
import CvUploader from "modules/candidate/components/CvContent/CvUploader";

const saveProfile = data =>
	client(UPDATE_PROFILE_ADDITIONAL_INFO, { body: data });

const CandidateProfileModal = ({
	closeModal,
	headline,
	handleSubmit,
	reloadProfile,
	user,
	getMyProfile
}) => {
	const [errorPhone, setErrorPhone] = useState("");
	const [currentSelect, setCurrentSelect] = useState(null);

	useEffect(() => {
		setCurrentSelect(user.candidate_status);
	}, []);

	const cvFileUpload = file => {
		AxiosHelper.post({
			data: {
				company_id: null,
				user_id: headline._id,
				type: "application/pdf",
				doc: file.url
			},
			url: UPLOAD_CV,
			toastMessage: "Cv content has successfully been updated."
		}).then(() => {
			reloadProfile();
		});
	};
	const [mutate, { status }] = useMutation(saveProfile, {
		onSuccess: () => {
			closeModal();
			toaster.success("Your profile has successfully been updated.");
			reloadProfile();
			getMyProfile();
		},
		onError: error => {
			if (_get(error, "status") === RESPONSE_CODE_422) {
				const message = _get(error, "detail.phone[0]");

				setErrorPhone(message);
			}
		}
	});

	const onChangePhone = e => {
		if (e.value === "") {
			setErrorPhone("");
		}
	};
	const submit = ({ phone, first_name, last_name }) => {
		mutate({
			phone: phone.formattedValue,
			first_name,
			last_name,
			candidate_status: currentSelect
		});
	};

	const onSwitch = e => {
		setCurrentSelect(e);
	};

	return (
		<Modal
			id="candidate-info"
			title="Personal details & Account Status"
			onClose={closeModal}
			firstButton={{
				label: "Save",
				type: "primary",
				style: { width: "200px" },
				action: handleSubmit(submit)
			}}
			loading={status === LOADING}
		>
			<div className="content">
				<form>
					<div className="row">
						<div className="col-md-12">
							<Field
								name="first_name"
								label="First name"
								component={FormField}
							/>
						</div>
						<div className="col-md-12">
							<Field name="last_name" label="Last name" component={FormField} />
						</div>
						<div className="col-md-12">
							<Field
								name="phone"
								label="Phone"
								component={PhoneNumberField}
								defaultValue={headline.phone}
								classes={errorPhone && "error_phone"}
								onChange={onChangePhone}
							/>
						</div>
						<div
							style={{
								color: "#f76d75",
								height: "15px",
								lineHeight: "16px",
								padding: "5px 0px 10px",
								textTransform: "none",
								whiteSpace: "normal",
								top: "-32px",
								left: "15px",
								position: "relative",
								clear: "both",
								fontWeight: "400"
							}}
						>
							{errorPhone}
						</div>
						<div className="col-md-12">
							<Field
								name="email"
								label="Email"
								component={FormField}
								defaultValue={headline.email}
								icon="fa icon-mail"
								classes="inner-icon left-icon"
								disabled={true}
								toolTipOverlay={"You can’t edit this field."}
							/>
						</div>
						<div className="col-md-12">
							<Text
								mb={"5px"}
								sx={{
									fontWeight: "500",
									color: "#58626b",
									textTransform: "uppercase"
								}}
							>
								CV DOCUMENT
							</Text>
							<CvUploader
								userId={headline._id}
								folder={"cv"}
								className="freelancer-cv"
								cvFileUpload={cvFileUpload}
								fileName={"simple.pdf"}
							/>
						</div>
					</div>
					<h3 className="title-FieldBlock">ACCOUNT STATUS</h3>
					<div className="row">
						<div className="col-md-6">
							<FieldBlock>
								<label
									className={
										currentSelect === PUBLIC
											? "container-label active"
											: "container-label"
									}
								>
									<input
										type="radio"
										name="accountStatus"
										className="styled"
										id={"public"}
										value={"public"}
										onClick={e => onSwitch(e.target.value)}
										checked={currentSelect === PUBLIC ? true : false}
									/>
									<span className="checkmark"></span>
									<span className="iconFieldBlock icon-Public">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
										<span className="path4"></span>
										<span className="path5"></span>
										<span className="path6"></span>
										<span className="path7"></span>
										<span className="path8"></span>
										<span className="path9"></span>
										<span className="path10"></span>
										<span className="path11"></span>
										<span className="path12"></span>
									</span>
									<h4>ACTIVE</h4>
									<p>
										You can be found and contacted by companies. You can apply
										for jobs.
									</p>
								</label>
							</FieldBlock>
						</div>
						<div className="col-md-6">
							<FieldBlock>
								<label
									className={
										currentSelect === PASSIVE
											? "container-label active"
											: "container-label"
									}
								>
									<input
										type="radio"
										name="accountStatus"
										className="styled"
										id={"passive"}
										value={"passive"}
										onClick={e => onSwitch(e.target.value)}
										checked={currentSelect === PASSIVE ? true : false}
									/>
									<span className="checkmark"></span>
									<span className="iconFieldBlock icon-Passive">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
										<span className="path4"></span>
										<span className="path5"></span>
									</span>
									<h4>Passive</h4>
									<p>
										You will not be found by new companies, but you can still
										apply for jobs.
									</p>
								</label>
							</FieldBlock>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<FieldBlock>
								<label
									className={
										currentSelect === INACTIVE
											? "container-label active"
											: "container-label"
									}
								>
									<input
										type="radio"
										name="accountStatus"
										className="styled"
										id={"inactive"}
										value={"inactive"}
										onClick={e => onSwitch(e.target.value)}
										checked={currentSelect === INACTIVE ? true : false}
									/>
									<span className="checkmark"></span>
									<span className="iconFieldBlock icon-Inactive">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
										<span className="path4"></span>
										<span className="path5"></span>
										<span className="path6"></span>
									</span>
									<h4>Inactive</h4>
									<p>
										You cannot be found or apply. All your current processes are
										put on hold.
									</p>
								</label>
							</FieldBlock>
						</div>
					</div>
				</form>
			</div>
		</Modal>
	);
};
const selector = formValueSelector("candidate-info");
const validate = props => {
	const errors = {};
	const fields = ["first_name", "last_name"];

	for (const field of fields) {
		if (!props[field] || props[field].trim() === "") {
			errors[field] = "This field is required.";
		}
	}
	// import FormField from "common/FormField";
	if (!props.phone || _get(props, "phone.value", {}) === "") {
		errors.phone = "This field is required.";
	}

	return errors;
};
const mapStateToProps = (state, props) => {
	const initialValues = { ...props.headline };

	return {
		initialValues,
		fields: {
			...selector(state, "first_name", "last_name", "phone")
		}
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			getMyProfile
		},
		dispatch
	);
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "candidate-info",
		validate,
		touchOnBlur: false
	})
)(CandidateProfileModal);
