import React from "react";
import { BannerHelpWrapper, BannerTitle, BannerBody } from "./Styled";
import { ReactComponent as IconHelp } from "./IconHelp.svg";

const BannerHelp = ({ onClose }) => {
	return (
		<BannerHelpWrapper>
			<div className="section-1">
				<IconHelp />
			</div>
			<div className="section-2">
				<BannerTitle>HELP</BannerTitle>
				<BannerBody>
					Start here your document request flow for each stage of the
					recruitment process. <br />
					Requirement can be set as optional or mandatory for your freelancers
					and your suppliers.
				</BannerBody>
			</div>
			<div className="section-3">
				<span className="icon-close2" onClick={onClose} />
			</div>
		</BannerHelpWrapper>
	);
};
export default BannerHelp;
