import EmptyCV from "modules/cv/components/Tabs/EmptyCV";
import React from "react";
import { CVContainer } from "../../ProfileStyle";
import { Box, Flex, Text } from "rebass";
import { PDF_VIEWER_URL } from "config";

const CvContent = ({ pdf_doc, isHeadline = true }) => {
	let pdfLink;

	if (pdf_doc) {
		pdfLink = PDF_VIEWER_URL + pdf_doc.replace(/^http:\/\//i, "https://");
	}

	return (
		<Box>
			{isHeadline && (
				<Flex
					alignItems="center"
					sx={{
						height: "63px"
					}}
				>
					<Text
						sx={{
							fontSize: "16px",
							color: "#212b36",
							fontWeight: 600
						}}
					>
						Resume (CV)
					</Text>
				</Flex>
			)}
			{pdf_doc ? (
				<CVContainer width="auto">
					<iframe
						style={{
							width: "100%",
							minHeight: "895px",
							overflow: "hidden",
							border: "none"
						}}
						src={pdfLink}
						width="100%"
					></iframe>
				</CVContainer>
			) : (
				<Flex minHeight="800px" alignItems="center" justifyContent="center">
					<EmptyCV />
				</Flex>
			)}
		</Box>
	);
};
export default CvContent;
