import React, { useEffect, useState } from "react";
import _get from "lodash/get";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { queryCache } from "react-query";
import Modal from "../../../../common/modal";
import { TEXTAREA_MAX_LENGTH, INTERNAL_USER_NOTE } from "config";
import { FILTER_SETTINGS_ENDPOINT, ADD_NOTE } from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";
import { AddNoteRendrer } from "common/AddNote";
import { userNoteKey } from "modules/candidate/Profile/api/useViewUserNotes";
import { KEY_FETCH_NOTES } from "../../Profile/Pages/Notes/hooks/useFilterNotes";

const getUsers = users => users.map(u => ({ id: u._id, display: u.user_name }));
const AddNoteModal = ({ onClose, profile_id, nextAction }) => {
	const [usersList, setUsersList] = useState([]);

	useEffect(() => {
		const data = {
			tag: INTERNAL_USER_NOTE
		};

		AxiosHelper.post({
			data,
			url: FILTER_SETTINGS_ENDPOINT,
			isModal: true
		}).then(resp => {
			setUsersList(_get(resp, "list_users", []));
			queryCache.invalidateQueries(userNoteKey);
		});
	}, []);

	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm({
		mode: "onBlur",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false
	});

	const submit = fields => {
		const payload = {
			profile_id,
			mentioned_users: _get(fields, "mention_note.mentions"),
			description: _get(fields, "mention_note.value"),
			note_type: _get(fields, "note_type.value"),
			application_id: _get(fields, "application.value")
		};
		AxiosHelper.post({
			url: ADD_NOTE,
			toastMessage: "The note has successfully added ",
			data: payload,
			isModal: true
		}).then(() => {
			nextAction && nextAction();
			queryCache.invalidateQueries(KEY_FETCH_NOTES);
			queryCache.invalidateQueries(userNoteKey);
			onClose();
		});
	};

	return (
		<Modal
			title="Add note to profile"
			onClose={onClose}
			showButtonOnRight
			firstButton={{
				label: "Cancel",
				type: "outlined",
				action: onClose
			}}
			secondButton={{
				label: "Add Note",
				type: "primary",
				action: handleSubmit(submit)
			}}
		>
			<AddNoteRendrer>
				<Controller
					control={control}
					name="note_type"
					render={({ field: { onChange, value, name } }) => (
						<AddNoteRendrer.Select
							label="Note type"
							onChange={onChange}
							value={value}
							name={name}
							error={_get(errors, "note_type")}
						/>
					)}
				/>

				<Controller
					control={control}
					name="application"
					render={({ field: { onChange, value, name } }) => (
						<AddNoteRendrer.ApplicationSelect
							label="Link to vacancy"
							onChange={onChange}
							value={value}
							name={name}
							error={_get(errors, "applications")}
							profile_id={profile_id}
						/>
					)}
				/>

				<Controller
					control={control}
					name="mention_note"
					render={({ field: { onChange, value, name } }) => (
						<AddNoteRendrer.MentionBox
							input={{
								value,
								onChange,
								name,
								placeholder: "Use @ to tag a team member..."
							}}
							data={[
								{ id: "all", display: "All (all department)" },
								...getUsers(usersList)
							]}
							meta={{
								touched: Boolean(
									_get(errors, "mention_note.value.message", "")
								),
								error: _get(errors, "mention_note.value.message")
							}}
							label="Content of note"
							maxLength={TEXTAREA_MAX_LENGTH}
						/>
					)}
				/>
			</AddNoteRendrer>
		</Modal>
	);
};
const schema = yup.object().shape({
	mention_note: yup.object().shape({
		value: yup.string().required("Please fill the note content")
	}),
	note_type: yup.object().required("Please choose a note type")
});
export default AddNoteModal;
