import * as Yup from "yup";

export const OPTION_MAX_LENGTH = 255;
export const CHARACTER_LIMIT_EXCEEDED = "CHARACTER_LIMIT_EXCEEDED";

export const schema = Yup.object().shape({
	options: Yup.array().of(
		Yup.object().shape({
			value: Yup.string()
				.required("Please fill the option above before save.")
				.test(CHARACTER_LIMIT_EXCEEDED, function(value) {
					if (value && value.length > OPTION_MAX_LENGTH) {
						return this.createError({
							message: `${value.length}/${OPTION_MAX_LENGTH}`
						});
					}
					return true;
				})
		})
	)
});

export const VACANCY_OPENING_PATH = "vacancy-opening";
export const VACANCY_CLOSING_PATH = "vacancy-closing";
export const VACANCY_PRIORITY_PATH = "vacancy-priority";
export const VACANCY_FILE_TYPE_PATH = "vacancy-file-type";
export const CANDIDATE_REJECTION_PATH = "candidate-rejection";

export const TABS = {
	VACANCY_CLOSING: "vacancy_closing",
	VACANCY_OPENING: "vacancy_opening",
	VACANCY_PRIORITY: "vacancy_priority",
	CANDIDATE_REJECTION: "candidate_rejection",
	VACANCY_FILE_TYPE: "vacancy_file_type"
};

export const CUSTOM_FIELDS_MANAGER_TAG = "custom_fields_manager";
