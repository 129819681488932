/* eslint-disable no-unused-vars */
import React from "react";
import { Label, Radio } from "@rebass/forms";
import { Flex } from "rebass";
import styles from "./questionnaire-form-yes-no-radio-button.module.scss";
import { QUESTIONNARE_YES_NO_OPTIONS } from "../utils/constants";
import { QuestionnaireFormLabel } from "../QuestionnaireFormLabel";
import { ReactComponent as RadioButtonIcon } from "static/icons/radio-button.svg";
import { ReactComponent as RadioButtonCheckIcon } from "static/icons/radio-button-checked.svg";

export default function QuestionnaireFormYesNoRadioButton({
	field,
	description,
	onChange,
	value: fieldValue,
	isReadOnly = false,
	is_required: isRequired,
	className
}) {
	const labelStyles = index => ({
		color: "#334155",
		fontVariantNumeric: "lining-nums tabular-nums",
		fontFamily: "Work Sans",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "150%",
		width: "15%",
		cursor: isReadOnly ? "default" : "pointer",
		display: "flex",
		alignItems: "center",
		gap: "8px",
		marginBottom: index === 0 ? "8px" : ""
	});

	const radioColor = isReadOnly ? "#cbd5e0" : "#00857a";
	return (
		<div className={styles.container}>
			<QuestionnaireFormLabel
				isRequired={isRequired}
				description={description}
				className={className}
			/>
			<Flex flexDirection={"column"}>
				{QUESTIONNARE_YES_NO_OPTIONS.map((option, index) => (
					<Label key={option.label} sx={labelStyles(index)}>
						{isReadOnly ? (
							option.value === fieldValue ? (
								<RadioButtonCheckIcon />
							) : (
								<RadioButtonIcon />
							)
						) : (
							<Radio
								className={styles.radio}
								sx={{
									color: option.value === fieldValue ? radioColor : "#cbd5e0",
									backgroundColor: "white",
									cursor: isReadOnly ? "default" : "pointer"
								}}
								type="radio"
								name={`${field}-${Math.floor(Math.random() * 1000000)}`}
								value={option.value}
								checked={option.value === fieldValue}
								onChange={isReadOnly ? null : () => onChange(option.value)}
								disabled={isReadOnly}
							/>
						)}
						{option.label}
					</Label>
				))}
			</Flex>
		</div>
	);
}
