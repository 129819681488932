import React from "react";
import { Flex } from "rebass";
import GlobalTooltip from "common/GlobalTooltip";
import { ReactComponent as Lock } from "static/icons/lock.svg";
import { ReactComponent as AnynomosProfile } from "static/icons/anynomos-profile.svg";

const AnoProfile = () => {
	return (
		<Flex sx={{ position: "relative" }}>
			<Flex
				bg="#b8bfc6"
				width="110px"
				height="110px"
				sx={{ borderRadius: "2px" }}
				justifyContent="center"
			>
				<AnynomosProfile />
			</Flex>
			<Flex
				alignItems="center"
				justifyContent="center"
				sx={{
					width: "32px",
					height: "32px",
					boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
					border: "solid 2.5px rgb(255, 255, 255)",
					backgroundColor: "rgb(126, 130, 142)",
					position: "absolute",
					top: "-20px",
					right: "-20px",
					borderRadius: "32px"
				}}
				className="avatar-lock"
			>
				<GlobalTooltip
					placement="top"
					overlay="Candidates’ profiles remain anonymous until they confirm their interest for a vacancy. To send them your vacancy, simply poke the candidate!"
				>
					<Lock />
				</GlobalTooltip>
			</Flex>
		</Flex>
	);
};

export default AnoProfile;
