import React, { forwardRef } from "react";
import { Button } from "common/Button";
import styles from "./modal-save-button.module.scss";

const ModalSaveButton = (
	{ onClick, text = "Save", icon, isLoading, ...propsRest },
	ref
) => {
	return (
		<Button
			className={styles.button}
			ref={ref}
			text={text}
			onClick={onClick}
			icon={icon}
			{...propsRest}
			disabled={isLoading}
		/>
	);
};

export default forwardRef(ModalSaveButton);
