import styled from "styled-components";

export const StatusContainer = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: ${({ isInterviewStatus }) =>
		isInterviewStatus ? "10px" : "4px"};
	padding: 1px 8px;
	background: ${({ background }) => background};
	margin-left: ${({ isInterviewStatus }) =>
		isInterviewStatus ? "0px" : "8px"};
	.status-label {
		color: ${({ color }) => color};
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 16px;
	}
`;
