import { useQuery } from "react-query";
import { LIST_SKILLS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getSkillsList = body => {
	return client(LIST_SKILLS, {
		body
	});
};

export const GET_SKILLS_QUERY_KEY = "GET_SKILLS_QUERY_KEY";

export const useGetSkillsList = (body, options) => {
	const res = useQuery(
		[GET_SKILLS_QUERY_KEY, body],
		() => getSkillsList(body),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...options
		}
	);

	return res;
};
