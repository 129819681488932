import React, { Component } from "react";
import { connect } from "react-redux";
import { FREELANCER } from "config";
import { changeTab } from "modules/cv/actions";
import FreelancerTabs from "./FreelancerTabs";
import ConsultantTabs from "./ConsultantTabs";

class Tabs extends Component {
	isActive = index => {
		const { activeTab } = this.props;
		return index === activeTab ? "active" : "";
	};

	onChange = index => {
		this.props.dispatch(changeTab(index));
	};

	render() {
		const {
			ownCV,
			experiences,
			profileCompanyType,
			modalName,
			activeTab,
			userType
		} = this.props;
		return (
			<div id="tabs" style={{ display: "flex", justifyContent: "center" }}>
				<ul className="tabs-list list-unstyled">
					{profileCompanyType === FREELANCER ? (
						<FreelancerTabs
							ownCV={ownCV}
							isActive={this.isActive}
							onChange={this.onChange}
							modalName={modalName}
							activeTab={activeTab}
							userType={userType}
						/>
					) : (
						<ConsultantTabs
							ownCV={ownCV}
							isActive={this.isActive}
							onChange={this.onChange}
							experiences={experiences}
							userType={userType}
						/>
					)}
				</ul>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		activeTab: state.cv.activeTab,
		experiences: state.cv.experiences,
		preference: state.cv.preference,
		userType: state.auth.user.company_type,
		skills: state.cv.skills,
		modalName: state.cv.modalName
	};
};

export default connect(mapStateToProps)(Tabs);
