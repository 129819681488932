import styled from "styled-components";

import { colors } from "config/styles";

export const SearchBoxContainer = styled.div`
	background-color: ${colors.white};
	padding: 15px 0;
	margin-top: 40px;
	margin-bottom: 15px;
	button {
		margin-left: -15px;
	}
	.reset {
		color: ${colors.white};
		width: 23px;
		height: 23px;
		border-radius: 50%;
		position: absolute;
		right: 25px;
	}
	.icon-reset-3 {
		color: rgb(193, 193, 193);
		font-size: 25px;
		&:hover,
		&:focus {
			color: rgb(168, 168, 168);
		}
	}
`;

export const AddressLabel = styled.label`
	font-weight: 500;
	color: #58626b;
	text-transform: uppercase;
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
`;
