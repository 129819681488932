import React from "react";
import { Box } from "rebass";
import Functions from "./renderFunctions";

const FunctionsContainer = ({ functions }) => {
	const otherFunctions = functions.filter(item => !item.is_main);
	const mainFunction = functions.filter(item => item.is_main);
	return (
		<Box>
			<Functions mainFunction={mainFunction} otherFunctions={otherFunctions} />
		</Box>
	);
};

export default FunctionsContainer;
