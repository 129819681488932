export const socialMediaImages = {
	linkedin:
		"https://mcusercontent.com/88c83061cea4f39823efacefc/images/ae64d26e-8aa2-cbfd-b0b0-f4e97ce63b55.png",
	twitter:
		"https://mcusercontent.com/88c83061cea4f39823efacefc/images/fdf7e089-65ac-9c5e-97c8-5f909f0bc0e6.png",
	instagram:
		"https://mcusercontent.com/88c83061cea4f39823efacefc/images/e24c66f5-bafe-5c35-ebfc-127ed21ebf26.png",
	facebook:
		"https://mcusercontent.com/88c83061cea4f39823efacefc/images/766f0dc5-b940-0aaf-6c47-8d462ea26e3e.png"
};
