import { CustomInput } from "common/CustomInput";
import { CustomMap } from "common/CustomMap";
import { OPERATORS } from "common/FilterDrawer/utils";
import React from "react";
import { FreelancersBooleanSelect } from "modules/freelancers/components/FreelancersBooleanSelect";
import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";

import { Picker } from "common/Picker";
import { NumberInputComponent } from "./missionsListHelper";

export const MISSIONS_LIST_PAGE_SIZES = [50, 24, 12];

export const MISSIONS_LIST_SIZES = 24;
export const API_DATA_TYPE = "search_missions_table";
export const MISSIONS_LIST_MAX_SELECT = 24;
export const MISSIONS_LIST_FILTER_ID = "MISSIONS_LIST_FILTER_ID";
export const MISSIONS_LIST_MODULE_ID = "MISSIONS_LIST_MODULE_ID";
export const DISPLAY_TYPE = {
	table: "table",
	card: "card"
};
export const TYPES = {
	text: "text",
	postcode: "postcode",
	timeCommitment: "timeCommitment",
	number: "number",
	array: "array",
	boolean: "boolean",
	date: "date",
	address: "address",
	singleOption: "singleOption",
	progress: "progress"
};

const PickerWithOneValue = props => <Picker {...props} limit={1} />;
PickerWithOneValue.displayName = "Picker";

const PostionsComponent = data => {
	return <NumberInputComponent data={data} />;
};

const ProgressComponent = data => {
	return <NumberInputComponent data={data} max={100} />;
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.postcode]: [
		OPERATORS.contains,
		OPERATORS.notContains,
		OPERATORS.startWith
	],
	[TYPES.timeCommitment]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.number]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.lessThanOrEqual,
		OPERATORS.greaterThanOrEqual
	],
	[TYPES.progress]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.lessThanOrEqual,
		OPERATORS.greaterThanOrEqual
	],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.singleOption]: [OPERATORS.is],
	[TYPES.boolean]: [OPERATORS.is, OPERATORS.isNot],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[TYPES.address]: [OPERATORS.is]
};
export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.address]: {
		default: CustomMap
	},
	[TYPES.postcode]: {
		default: CustomInput
	},
	[TYPES.timeCommitment]: {
		default: Picker
	},
	[TYPES.number]: {
		default: PostionsComponent,
		[OPERATORS.isEmpty.value]: FreelancersBooleanSelect
	},
	[TYPES.progress]: {
		default: ProgressComponent
	},
	[TYPES.array]: {
		default: Picker,
		[OPERATORS.is.value]: PickerWithOneValue
	},
	[TYPES.singleOption]: {
		default: Picker,
		[OPERATORS.is.value]: PickerWithOneValue
	},
	[TYPES.boolean]: {
		default: FreelancersBooleanSelect
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	}
};

export const columnSortMap = {
	_id: "_id",
	mission_reference: "mission_reference",
	mission_title: "mission_title",
	department: "department",
	consultant: "consultant",
	profile_type: "profile_type",
	rate_type: "rate_type",
	rate: "rate",
	cost: "cost",
	progress: "progress",
	start_date: "start_date",
	end_date: "end_date",
	timesheet_frequency: "timesheet_frequency",
	extensions: "extensions",
	onboarded: "onboarded",
	status: "status",
	external_manager: "external_manager",
	accounting_manager: "accounting_manager",
	po_number: "po_number",
	selling_price: "selling_price"
};

export const columnFiltersMap = {
	_id: "_id",
	mission_reference: "mission_reference",
	mission_title: "mission_title",
	department: "department",
	consultant: "consultant",
	profile_type: "profile_type",
	rate_type: "rate_type",
	rate: "rate",
	cost: "cost",
	progress: "progress",
	start_date: "start_date",
	end_date: "end_date",
	timesheet_frequency: "timesheet_frequency",
	extensions: "extensions",
	onboarded: "onboarded",
	status: "status",
	external_manager: "external_manager",
	accounting_manager: "accounting_manager",
	po_number: "po_number",
	selling_price: "selling_price"
};

export const CHECK_FILTER_COLUMNS = [
	"mission_title",
	"mission_reference",
	"consultant"
];
