import { useMutation } from "react-query";
import { CREATE_NEW_JOB_PRICING } from "config/api-endpoints";
import { client } from "lib/api-client";

const addVacany = body => {
	return client(CREATE_NEW_JOB_PRICING, {
		body
	});
};

export const useAddVacancyVacancyInformations = () => {
	const res = useMutation(addVacany);

	return res;
};
