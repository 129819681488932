import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { VIEW_USER_NOTES } from "config/api-endpoints";

const viewUserNotes = (_, { id, limit }) =>
	client(VIEW_USER_NOTES, {
		body: {
			id,
			limit: limit || 4,
			tag: "profiles_notes"
		}
	});

export const userNoteKey = "@candidate/userNote";

const useViewUserNotes = (options, queryOptions = {}) =>
	useQuery([userNoteKey, options], viewUserNotes, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default useViewUserNotes;
