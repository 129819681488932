// default min time
const DEFAULT_MIN_HOUR = 0;
const DEFAULT_MIN_MINUTE = 0;
const DEFAULT_MIN_SECOND = 0;
// default max time
const DEFAULT_MAX_HOUR = 23;
const DEFAULT_MAX_MINUTE = 59;
const DEFAULT_MAX_SECOND = 59;

const TimeUnitMetadata = {
	hour: {
		max: DEFAULT_MAX_HOUR,
		min: DEFAULT_MIN_HOUR
	},
	minute: {
		max: DEFAULT_MAX_MINUTE,
		min: DEFAULT_MIN_MINUTE
	}
};

export const areSameTime = (date1, date2) => {
	return (
		date1 != null &&
		date2 != null &&
		date1.hours() === date2.hours() &&
		date1.minutes() === date2.minutes() &&
		date1.seconds() === date2.seconds()
	);
};

export const getDateOnlyWithTime = date => {
	return window
		.moment()
		.hours(date.hours())
		.minutes(date.minutes())
		.seconds(date.seconds());
};

export const isTimeEqualOrGreaterThan = (time, timeToCompare) =>
	time.valueOf() >= timeToCompare.valueOf();
export const isTimeEqualOrSmallerThan = (time, timeToCompare) =>
	time.valueOf() <= timeToCompare.valueOf();

export const isTimeInRange = (date, minDate, maxDate) => {
	const time = getDateOnlyWithTime(date);
	const minTime = getDateOnlyWithTime(minDate);
	const maxTime = getDateOnlyWithTime(maxDate);

	const isTimeGreaterThanMinTime = isTimeEqualOrGreaterThan(time, minTime);
	const isTimeSmallerThanMaxTime = isTimeEqualOrSmallerThan(time, maxTime);

	if (isTimeEqualOrSmallerThan(maxTime, minTime)) {
		return isTimeGreaterThanMinTime || isTimeSmallerThanMaxTime;
	}

	return isTimeGreaterThanMinTime && isTimeSmallerThanMaxTime;
};

export function isTimeSameOrAfter(date, dateToCompare) {
	const time = getDateOnlyWithTime(date);
	const timeToCompare = getDateOnlyWithTime(dateToCompare);

	return isTimeEqualOrGreaterThan(time, timeToCompare);
}

export function getTimeInRange(time, minTime, maxTime) {
	if (areSameTime(minTime, maxTime)) {
		return maxTime;
	} else if (isTimeInRange(time, minTime, maxTime)) {
		return time;
	} else if (isTimeSameOrAfter(time, maxTime)) {
		return maxTime;
	}
	return minTime;
}

export function getDefaultMinTime() {
	return window
		.moment()
		.hours(DEFAULT_MIN_HOUR)
		.minutes(DEFAULT_MIN_MINUTE)
		.seconds(DEFAULT_MIN_SECOND);
}

export function getDefaultMaxTime() {
	return window
		.moment()
		.hours(DEFAULT_MAX_HOUR)
		.minutes(DEFAULT_MAX_MINUTE)
		.seconds(DEFAULT_MAX_SECOND);
}

export function getTimeUnitMax(unit) {
	return TimeUnitMetadata[unit].max;
}

export function getTimeUnitMin(unit) {
	return TimeUnitMetadata[unit].min;
}
