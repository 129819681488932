export const START_FETCHING = "START_FETCHING";
export const STOP_FETCHING = "STOP_FETCHING";
export const LIST_MANAGERS = "LIST_MANAGERS";
export const SWITCH_MANAGER = "SWITCH_MANAGER";
export const TOGGLE_PHONE_MODAL = "TOGGLE_PHONE_MODAL";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const TOGGLE_INTERRUPT_MODAL = "TOGGLE_INTERRUPT_MODAL";
export const TOGGLE_EXTEND_MODAL = "TOGGLE_EXTEND_MODAL";
export const UPDATE_FILTER = "CONTRACTS/UPDATE_FILTER";
export const RESET_FILTERS = "CONTRACT/RESET_FILTERS";
export const CLOSE_EXTEND_MODAL = "CONTRACT/CLOSE_EXTEND_MODAL";
