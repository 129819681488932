import React from "react";
import _get from "lodash/get";
import EditableSection from "../EditableSection";
import ToolTip from "common/GlobalTooltip";

import { ReactComponent as StarIcon } from "static/icons/star-outline.svg";
import { ReactComponent as SeparatorIcon } from "static/icons/separator-point.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle.svg";
import cardStyle from "../EditableSection/editable-section.module.scss";
import EmptyState from "modules/candidate/Profile/components/EmptyState";
import { VACANCY_LEVEL } from "config";
import styles from "modules/candidate/Profile/components/Card/card.module.scss";
import sortBy from "lodash/sortBy";

const Functions = ({ profileData }) => {
	const sectionFunctions = _get(profileData, "update_sections.functions");

	const getFunctionsData = data => {
		const functions = _get(data, "functions", []);
		const filteredFunction = functions.map(
			({ is_main, name, seniority, sector }) => {
				const parent_sector = _get(sector, "parent_sector.name", "");
				const sector_name = _get(sector, "name", "");

				const seniorityLabel = VACANCY_LEVEL.find(
					level => level.value === seniority
				)?.label;
				const tooltip = `
					${parent_sector} > ${sector_name} > ${name}
				`;
				return {
					is_main,
					name,
					tooltip,
					seniorityLabel
				};
			}
		);
		const sortedFunction = sortBy(filteredFunction, (x, y) =>
			x?.is_main ? -1 : y?.is_main ? 1 : 0
		);
		return sortedFunction;
	};
	const dataFunc = getFunctionsData(profileData);

	return (
		<React.Fragment>
			<EditableSection
				title="Functions"
				hasEdit={false}
				sectionUpdate={sectionFunctions}
			>
				{dataFunc.length > 0 ? (
					dataFunc.map(item => (
						<div key={item.name} className={cardStyle.section}>
							<div className={cardStyle.row}>
								<StarIcon fill={item.is_main ? "#F01C29" : "#BABEC3"} />
								<span className={styles.textNormal}>{item.name}</span>
								<SeparatorIcon />
								<span className={styles.textNormal}>{item.seniorityLabel}</span>
								<ToolTip
									placement={"right"}
									maxWidth={"30rem"}
									align={{
										offset: [15, 0]
									}}
									overlay={item.tooltip}
								>
									<InfoIcon className={cardStyle.iconFlex} />
								</ToolTip>
							</div>
						</div>
					))
				) : (
					<EmptyState />
				)}
			</EditableSection>
		</React.Fragment>
	);
};

export default Functions;
