import React from "react";
import cx from "classnames";
import Drawer from "rc-drawer";
import styles from "./mission-extension-details-drawer.module.scss";
import { ScoreBadge } from "../ScoreBadge";
import { formatDate } from "common/Functions";
import { CustomAvatar } from "common/CustomAvatar";
import Description from "common/Description";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import useGetMissionDetails from "modules/MissionsDetails/hooks/useGetMissionDetails";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { ReactComponent as UserIcon } from "static/icons/user-grey-icon.svg";
import { ReactComponent as BankIcon } from "static/icons/bank-note-icon.svg";
import { ReactComponent as CoinsIcon } from "static/icons/coins.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-3.svg";
import { ReactComponent as MessageIcon } from "static/icons/message-text-square.svg";
import { ReactComponent as TimeIcon } from "static/icons/clock-icon.svg";
import { ReactComponent as RightArrow } from "static/icons/arrowRight.svg";
import { ReactComponent as BuildingIcon } from "static/icons/building-2.svg";
import { ReactComponent as TicketIcon } from "static/icons/ticket.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle.svg";
import { CURRENCY_SUFFIX, HOURLY, SUPER_ADMIN, USER_HIREME_NAME } from "config";
import { useIsDisplayCostEnabled } from "hooks/useIsDisplayCostEnabled";
import moment from "moment";
import { NO_DATA_PLACEHOLDER } from "config";
import isEqual from "lodash/isEqual";
import Tooltip from "common/Tippy";
import { shouldDisplayFreelancerCost } from "config/helpers";
import { DAILY } from "config";

export default function MissionExtensionDetailsDrawer({
	isOpen,
	onClose,
	missionExtensionDetails,
	reporting_settings,
	drawerTitle,
	mission_id
}) {
	const { data: missionData } = useGetMissionDetails(mission_id);
	const reportingSettings =
		reporting_settings || missionData?.reporting_settings;

	const invoiceDetails = missionExtensionDetails?.invoice_details;
	const oldInvoiceDetails = missionExtensionDetails?.old_invoice_details;
	const supplierMode = invoiceDetails && invoiceDetails?.length !== 0;
	const isDisplayCostEnabled =
		useIsDisplayCostEnabled() && shouldDisplayFreelancerCost(missionData);
	const {
		user: { first_name, last_name, avatar, type },
		extended_at,
		status
	} = missionExtensionDetails;

	const name =
		type === SUPER_ADMIN ? USER_HIREME_NAME : first_name + " " + last_name;

	const getPriceValues = value => {
		if (!value)
			return <span className={styles.text}>{NO_DATA_PLACEHOLDER}</span>;
		return (
			<CurrencyFormatterRender
				className={styles.text}
				isCent
				value={value || "-"}
				currency={reportingSettings?.currency?.code}
				suffix={CURRENCY_SUFFIX[reportingSettings?.rate_type || HOURLY]}
			/>
		);
	};

	return (
		<Drawer
			open={isOpen}
			width="650"
			height="100%"
			level="root"
			placement="right"
			maskClosable={true}
			onClose={onClose}
			style={{ zIndex: 1100 }}
		>
			<div className={styles.drawerContent}>
				<div className={styles.header}>
					<div className={styles.flex}>
						<div className={styles.title}>{drawerTitle}</div>
						<button onClick={onClose} className={styles.closeBtn}>
							<CloseIcon />
						</button>
					</div>
					<div className={styles.dividerHeader}></div>
				</div>

				<div className={styles.body}>
					<div className={styles.headerCard}>
						<div className={styles.left}>
							<h5 className={styles.label}>Mission title</h5>
							<h3 className={styles.name}>{missionData?.job?.title}</h3>
						</div>
						<div className={styles.right}>
							<ScoreBadge value={missionData?.progress} />
						</div>
					</div>
					<div className={styles.divider}></div>
					<div className={styles.content}>
						<div className={styles.info}>
							<div className={styles.item}>
								<div className={styles.name}>
									<UserIcon />
									<span className={styles.textName}>
										{status === "edited"
											? "Edited"
											: status === "live"
											? "Created"
											: "Extended"}{" "}
										by
									</span>
								</div>
								<div className={styles.value}>
									<div className={styles.avatarWrapper}>
										{type !== SUPER_ADMIN && (
											<CustomAvatar
												src={avatar}
												fullName={first_name + " " + last_name}
												variant="small"
												rootClassName={cx(styles.collaboratorPicture)}
												imgClassName={cx(styles.picture)}
												avatarClassName={cx(
													styles.avatarWithBorder,
													styles.avatar
												)}
												avatarSize={30}
											/>
										)}
									</div>
									<span className={styles.text}>{name}</span>
								</div>
							</div>

							<div className={styles.item}>
								<div className={styles.name}>
									<TimeIcon />
									<span className={styles.textName}>
										{status === "edited"
											? "Edited"
											: status === "live"
											? "Created"
											: "Extended"}{" "}
										on
									</span>
								</div>
								<div className={styles.value}>
									<span className={styles.text}>
										{moment
											.unix(extended_at || missionExtensionDetails.date)
											.format("DD/MM/YYYY hh:mm")}
									</span>
								</div>
							</div>
							{missionExtensionDetails?.new_po_number && (
								<div className={cx(styles.item, styles.longItem)}>
									<div className={styles.name}>
										<TicketIcon />
										<span className={styles.textName}>PO number </span>
									</div>
									<div className={styles.values}>
										{status === "edited" &&
											!isEqual(
												missionExtensionDetails?.new_po_number,
												missionExtensionDetails?.old_po_number
											) &&
											missionExtensionDetails?.old_po_number && (
												<>
													<div className={styles.value}>
														<span className={styles.text}>
															{missionExtensionDetails?.old_po_number}
														</span>
													</div>
													{missionExtensionDetails?.old_po_number && (
														<RightArrow />
													)}
												</>
											)}
										<div className={styles.value}>
											<span className={styles.text}>
												{missionExtensionDetails?.new_po_number}
											</span>
										</div>
									</div>
								</div>
							)}
							<div className={styles.item}>
								<div className={styles.name}>
									<BankIcon />
									<span className={styles.textName}>
										{" "}
										{reportingSettings?.rate_type === DAILY
											? "Daily"
											: "Hourly"}{" "}
										rate
									</span>
								</div>
								{status === "edited" && (
									<>
										<div className={cx(styles.value, styles.fixedWidth)}>
											{getPriceValues(missionExtensionDetails.old_price)}
										</div>
										<RightArrow />
									</>
								)}
								<div className={cx(styles.value, styles.fixedWidth)}>
									{getPriceValues(missionExtensionDetails.new_price)}
								</div>
							</div>
							{isDisplayCostEnabled && (
								<div className={styles.item}>
									<div className={styles.name}>
										<CoinsIcon />
										<span className={styles.textName}>
											{reportingSettings?.rate_type === DAILY
												? "Daily"
												: "Hourly"}{" "}
											cost
										</span>
									</div>
									{status === "edited" && (
										<>
											<div className={cx(styles.value, styles.fixedWidth)}>
												{getPriceValues(missionExtensionDetails.old_cost)}
											</div>
											<RightArrow />
										</>
									)}
									<div className={cx(styles.value, styles.fixedWidth)}>
										{getPriceValues(missionExtensionDetails.new_cost)}
									</div>
								</div>
							)}
							<div className={styles.item}>
								<div className={styles.name}>
									<CalendarIcon />
									<span className={styles.textName}>Start date</span>
								</div>
								{status === "edited" && (
									<>
										<div className={cx(styles.value, styles.fixedWidth)}>
											<span className={styles.text}>
												{formatDate(
													missionExtensionDetails.old_mission_start_date || "-"
												)}
											</span>
										</div>
										<RightArrow />
									</>
								)}
								<div className={cx(styles.value, styles.fixedWidth)}>
									<span className={styles.text}>
										{formatDate(
											missionExtensionDetails.new_mission_start_date || "-"
										)}
									</span>
								</div>
							</div>
							<div className={styles.item}>
								<div className={styles.name}>
									<CalendarIcon />
									<span className={styles.textName}>End date</span>
								</div>
								{status === "edited" && (
									<>
										<div className={cx(styles.value, styles.fixedWidth)}>
											<span className={styles.text}>
												{formatDate(
													missionExtensionDetails.old_mission_end_date || "-"
												)}
											</span>
										</div>
										<RightArrow />
									</>
								)}
								<div className={cx(styles.value, styles.fixedWidth)}>
									<span className={styles.text}>
										{formatDate(
											missionExtensionDetails.new_mission_end_date || "-"
										)}
									</span>
								</div>
							</div>
							{!supplierMode && missionExtensionDetails?.new_selling_price && (
								<div className={styles.item}>
									<div className={styles.name}>
										<BankIcon />
										<span className={styles.textName}>Selling price</span>
									</div>
									{status === "edited" && (
										<>
											<div className={cx(styles.value, styles.fixedWidth)}>
												<span className={styles.text}>
													{getPriceValues(
														missionExtensionDetails.old_selling_price
													)}
												</span>
											</div>
											<RightArrow />
										</>
									)}
									<div className={cx(styles.value, styles.fixedWidth)}>
										<span className={styles.text}>
											{getPriceValues(
												missionExtensionDetails.new_selling_price
											)}
										</span>
									</div>
								</div>
							)}
						</div>
					</div>
					{supplierMode && (
						<>
							<div className={styles.divider}></div>

							<div className={styles.content}>
								<div className={styles.supplierMode}>
									<div className={styles.item}>
										<div className={styles.name}>
											<BuildingIcon />
											<span className={styles.textName}>Company Name</span>
										</div>
										{status === "edited" &&
											!isEqual(
												oldInvoiceDetails?.company_name,
												invoiceDetails?.company_name
											) &&
											oldInvoiceDetails.length !== 0 && (
												<>
													<div className={styles.value}>
														<span className={styles.text}>
															{oldInvoiceDetails?.company_name}
														</span>
													</div>
													<RightArrow />
												</>
											)}
										<div className={styles.value}>
											<span className={styles.text}>
												{invoiceDetails?.company_name}
											</span>
										</div>
									</div>

									<div className={cx(styles.item, styles.longItem)}>
										<div className={styles.name}>
											<BankIcon />
											<span className={styles.textName}>Selling price</span>
										</div>
										<div className={styles.values}>
											{status === "edited" &&
												!isEqual(
													oldInvoiceDetails?.selling_price,
													invoiceDetails?.selling_price
												) &&
												oldInvoiceDetails.length !== 0 && (
													<>
														<div className={styles.value}>
															<span className={styles.text}>
																{getPriceValues(
																	oldInvoiceDetails?.selling_price
																)}
															</span>
														</div>
														<RightArrow />
													</>
												)}
											<div className={styles.value}>
												<span className={styles.text}>
													{getPriceValues(invoiceDetails?.selling_price)}
												</span>
											</div>
										</div>
									</div>
									<div className={cx(styles.item, styles.longItem)}>
										<div className={cx(styles.name, styles.emailManager)}>
											<UserIcon />
											<span className={styles.textName}>Timesheet Manager</span>
										</div>
										<div className={styles.values}>
											<div className={styles.value}>
												<span className={styles.text}>
													{invoiceDetails?.company_timesheet_manager_email}
												</span>
												{status === "edited" &&
													!isEqual(
														oldInvoiceDetails?.company_timesheet_manager_email,
														invoiceDetails?.company_timesheet_manager_email
													) &&
													oldInvoiceDetails.length !== 0 && (
														<Tooltip
															theme="dark"
															content={
																<div className={styles.tooltip}>
																	<span>
																		Timesheet manager email has been updated.
																	</span>
																	<span>
																		Old:{" "}
																		{
																			oldInvoiceDetails?.company_timesheet_manager_email
																		}
																	</span>
																	<span>
																		New:{" "}
																		{
																			invoiceDetails?.company_timesheet_manager_email
																		}
																	</span>
																</div>
															}
														>
															<InfoIcon className={styles.icon} />
														</Tooltip>
													)}
											</div>
										</div>
									</div>
									<div className={cx(styles.item, styles.longItem)}>
										<div className={cx(styles.name, styles.emailManager)}>
											<UserIcon />

											<span className={styles.textName}>
												Accounting Manager
											</span>
										</div>
										<div className={styles.values}>
											<div className={styles.value}>
												<span className={styles.text}>
													{invoiceDetails?.accounting_manager_email}
												</span>
												{status === "edited" &&
													!isEqual(
														oldInvoiceDetails?.accounting_manager_email,
														invoiceDetails?.accounting_manager_email
													) &&
													oldInvoiceDetails.length !== 0 && (
														<Tooltip
															theme="dark"
															content={
																<div className={styles.tooltip}>
																	<span className={styles.text}>
																		Accounting manager email has been updated.
																	</span>
																	<span className={styles.text}>
																		Old:{" "}
																		{
																			oldInvoiceDetails?.accounting_manager_email
																		}
																	</span>
																	<span className={styles.text}>
																		New:{" "}
																		{invoiceDetails?.accounting_manager_email}
																	</span>
																</div>
															}
														>
															<InfoIcon className={styles.icon} />
														</Tooltip>
													)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}

					{missionExtensionDetails?.description && (
						<>
							<div className={styles.divider}></div>
							<div className={styles.title}>
								<MessageIcon />
								<span className={styles.titleText}>Additional comment</span>
							</div>
							<div className={styles.commentCard}>
								<Description
									description={missionExtensionDetails?.description}
									classNameContent={styles.description}
									showMore
									max={1000}
									showMoreText="View more"
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</Drawer>
	);
}
