import React from "react";
import { useMenuItem } from "@react-aria/menu";
import styles from "../MenuColumn/menu-column.module.scss";

function MenuItem({ item, state, onAction }) {
	// Get props for the menu item element
	let ref = React.useRef();
	let isDisabled = state.disabledKeys.has(item.key);
	let isFocused = state.selectionManager.focusedKey === item.key;
	let { menuItemProps } = useMenuItem(
		{
			key: item.key,
			isDisabled,
			onAction
		},
		state,
		ref
	);

	return (
		<li
			{...menuItemProps}
			ref={ref}
			style={{
				background: isFocused ? "#F5F5F5" : "transparent"
			}}
			className={styles.listMenu}
		>
			{item.rendered}
		</li>
	);
}
export default MenuItem;
