import React, { useMemo } from "react";
import get from "lodash/get";
import { queryCache } from "react-query";
import _map from "lodash/map";
import { listCountKey } from "modules/home/api/useListCount.js";
import HomeTable from "modules/home/components/Temporary/table";
import useContractList from "modules/home/api/useContractList.js";
import useTemporaryStore from "modules/home/zustand/temporary.store.js";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { CURRENCY_SUFFIX, WELCOME_PAGE } from "config";
import { historyPush } from "config/helpers";
import { formatDate } from "common/Functions";
import { RenderApplicantsName } from "modules/home/components/table/helpers.js";
import { NEW_CONTRACT } from "modules/home/config";
import { getClientBidViewRoute } from "modules/RequestView/components/BidDetailsDrawer/utils/constant";

const NewContractList = ({ handleViewAll }) => {
	const { startDate, endDate, temporaryTab, setItem } = useTemporaryStore();

	const { data, isLoading } = useContractList(
		{
			start_date:
				startDate && startDate.isValid() ? startDate.unix() : undefined,
			end_date: endDate && endDate.isValid() ? endDate.unix() : undefined
		},
		{
			enabled: temporaryTab === NEW_CONTRACT,
			onSuccess: res => {
				queryCache.invalidateQueries(listCountKey);
				setItem(
					NEW_CONTRACT,
					_map(res || [], item => item.bid_id)
				);
			}
		}
	);

	const renderCurrency = data => (
		<CurrencyFormatterRender
			isCent
			value={get(data, "cell.value")}
			suffix={
				CURRENCY_SUFFIX[
					get(data, "cell.row.original.reporting_settings.rate_type")
				]
			}
			currency={get(data, "cell.row.original.reporting_settings.currency.code")}
		/>
	);
	const renderApplicantsName = data => (
		<RenderApplicantsName
			name={get(data, "cell.row.original.vendor.user_name")}
			status={get(data, "cell.row.original.vendor.type")}
		/>
	);

	const columns = useMemo(
		() => [
			{
				Header: "expected / start date",
				accessor: "",
				Cell: props => (
					<div>
						{" "}
						{formatDate(get(props, "cell.row.original.start_date"))} -{" "}
						{formatDate(get(props, "cell.row.original.end_date"))}{" "}
					</div>
				)
			},
			{
				Header: "Applicant's name",
				accessor: "",
				Cell: renderApplicantsName
			},
			{
				Header: "Request",
				accessor: "job.title"
			},
			{
				Header: "Amount",
				accessor: "cost",
				isNumber: true,
				Cell: renderCurrency
			},
			{
				Header: "Proposition made by",
				accessor: "client.user_name"
			},
			{
				Header: "Departement",
				accessor: "group.name"
			}
		],
		[]
	);
	const buttonAction = () => historyPush("/jobs/list");
	const onRowClick = row =>
		historyPush(
			getClientBidViewRoute(
				row.bid_id,
				row.job._id,
				`&source=${WELCOME_PAGE}&list=contracts&listKey=${NEW_CONTRACT}`
			)
		);

	return (
		<HomeTable
			isLoading={isLoading}
			columns={columns}
			data={data}
			onRowClick={onRowClick}
			heading="You have no contracts for this date"
			buttonLabel="Create request"
			buttonAction={buttonAction}
			handleViewAll={handleViewAll}
		/>
	);
};

export default NewContractList;
