import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { API_DATA_TYPE } from "../components/_MissionsListUtils/missionsListUtils";

const getSettings = () => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: { tag: "search_missions_table" }
	});
};

export const GET_MISSIONS_LIST_TABLE_SETTINGS_QUERY_KEY = `@getColumnsSettings_${API_DATA_TYPE}`;

function useGetMissionsListTableSettings() {
	return useQuery(GET_MISSIONS_LIST_TABLE_SETTINGS_QUERY_KEY, getSettings, {
		refetchOnWindowFocus: false
	});
}

export default useGetMissionsListTableSettings;
