import React from "react";
import { get } from "lodash";
import OverView from "modules/home/components/overview";
import useJobsOverview from "modules/home/api/useJobsOverview.js";
import Card from "modules/home/components/overview/Card.jsx";
import { VACANCY } from "config";
import { ReactComponent as ApplicationsIcon } from "static/icons/application_home_icon.svg";
import { ReactComponent as ShortlistIcon } from "static/icons/shortlist_home_icon.svg";
import { ReactComponent as InterviewIcon } from "static/icons/interview_home_icon.svg";
import { ReactComponent as OfferIcon } from "static/icons/offer_home_icon.svg";
import { ReactComponent as MissionIcon } from "static/icons/mission_home_icon.svg";
import { ReactComponent as ContractIcon } from "static/icons/contract_home_icon.svg";
import { ReactComponent as BidIcon } from "static/icons/bid_home_icon.svg";
import { ReactComponent as RequestInterviewIcon } from "static/icons/request_interview_icon.svg";
import { HeaderStart } from "modules/home/components/headers.js";
import { HeaderEnd } from "modules/home/containers/interview/header.jsx";
import useOverviewStore from "modules/home/containers/jobsOverview/overview.store.js";

const JobsOverview = ({ type }) => {
	const {
		vacancy_start_date,
		vacancy_end_date,
		request_start_date,
		request_end_date,
		setItem,
		isSettedDate
	} = useOverviewStore();

	const { data, isLoading } = useJobsOverview({
		job_type: type,
		start_date:
			type === VACANCY
				? vacancy_start_date && vacancy_start_date.isValid()
					? vacancy_start_date.unix()
					: undefined
				: request_start_date && request_start_date.isValid()
				? request_start_date.unix()
				: undefined,
		end_date:
			type === VACANCY
				? vacancy_end_date && vacancy_end_date.isValid()
					? vacancy_end_date.unix()
					: undefined
				: request_end_date && request_end_date.isValid()
				? request_end_date.unix()
				: undefined
	});

	const LABEL = type === VACANCY ? "Vacancy overview" : "Request overview";

	const CARDS_DATA = [
		{
			icon: type === VACANCY ? <ApplicationsIcon /> : <BidIcon />,
			name: type === VACANCY ? "Applications" : "Bid",
			countAccessor: type === VACANCY ? "total_applications" : "total_bids",
			colorScheme: "green"
		},
		{
			icon: type === VACANCY ? <ShortlistIcon /> : <RequestInterviewIcon />,
			name: type === VACANCY ? "Shortlist" : "Interview",
			countAccessor: type === VACANCY ? "total_shortlists" : "total_interviews",
			colorScheme: "purple"
		},
		{
			icon: type === VACANCY ? <InterviewIcon /> : <ContractIcon />,
			name: type === VACANCY ? "Interviews" : "Contract proposition",
			countAccessor: type === VACANCY ? "total_interviews" : "total_contracts",
			colorScheme: "orange"
		},
		{
			icon: type === VACANCY ? <OfferIcon /> : <MissionIcon />,
			name: type === VACANCY ? "Offers" : "Mission",
			countAccessor: type === VACANCY ? "total_offers" : "total_missions",
			colorScheme: "yellow"
		}
	];

	return (
		<OverView
			headerStart={<HeaderStart label={LABEL} />}
			headerEnd={
				<HeaderEnd
					start_date={
						type === VACANCY ? vacancy_start_date : request_start_date
					}
					end_date={type === VACANCY ? vacancy_end_date : request_end_date}
					setItem={setItem}
					start_date_Key={
						type === VACANCY ? "vacancy_start_date" : "request_start_date"
					}
					end_date_key={
						type === VACANCY ? "vacancy_end_date" : "request_end_date"
					}
					isSettedDate={isSettedDate}
				/>
			}
			totalJobs={get(data, type === VACANCY ? "total_jobs" : "total_requests")}
			title={type}
			isLoading={isLoading}
		>
			{CARDS_DATA.map(elem => (
				<Card
					key={elem.countAccessor}
					{...elem}
					count={get(data, elem.countAccessor)}
				/>
			))}
		</OverView>
	);
};

export default JobsOverview;
