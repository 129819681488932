import styled from "styled-components";
import { colors } from "config/styles";

export const MatchesTopBar = styled.div`
	margin-top: 60px;
	width: 100%;
	.title {
		font-size: 14px;
		font-weight: 600;
		line-height: 1.97;
		color: ${colors.anchorsColor};
	}
	.description {
		margin-top: 5px;
		margin-bottom: 8px;
		background-color: ${colors.white};
		padding: 20px;
		line-height: 2;
		font-weight: normal;
		color: ${colors.darkGray};
		border-left: 3px solid ${colors.primaryColor};
		white-space: pre-line;
	}
`;

export const SearchCriterias = styled.div`
	margin-top: 5px;
	background-color: ${colors.white};
	width: 100%;
	border: 1px solid ${colors.grayBorder};
	border-radius: 1.5px;
	border-bottom: unset;
	font-size: 14px;
	font-weight: 400;
	color: ${colors.inputColor};
	text-align: left;
	.criteria {
		border-bottom: 1px solid ${colors.grayBorder};
		padding: 20px 15px;
		width: 100%;
		display: flex;
		align-items: center;
		.title {
			min-width: 12%;
			font-weight: 600;
			text-transform: uppercase;
			display: inline-block;
		}
		.content {
			display: inline-block;
		}
		.box {
			border: 1.5px solid ${colors.labelBorderColor};
			background-color: ${colors.white};
			border-radius: 3px;
			height: 34px;
			padding: 4px 13px;
			font-weight: 500;
			display: inline-block;
			float: left;
			margin-right: 5px;
			margin-bottom: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			.must_have_star {
				top: unset;
			}
		}
		.address {
			display: flex;
			flex-direction: column;

			.country {
				font-weight: 500;
				text-transform: uppercase;
			}
		}
	}
`;

export const ProfilesContainer = styled.div`
	margin-top: 10px;
	.potential-match {
		&:not(:nth-child(3n)) {
			margin-right: 10px;
		}
	}
`;
