import { IdCell } from "../components/IdCell";
import TableMenu from "common/AgTable/components/MenuColumn";
import { GdprListGenericCell } from "../components/GdprListGenericCell";

export const BREADCRUMBS_ITEMS = [{ name: "Settings" }, { name: "GDPR" }];
export const MAX_CANDIDATE = 500;

export const GDPR_FILTER_ID = "GDPR_FILTER_ID";
export const GDPR_LIST_FILTER = "GDPR_LIST_FILTER";
export const PAGE_SIZE_LIST = ["50", "24", "12"];
export const PAGE_SIZE = 24;
export const EMPTY_ARRAY = Array(12).fill({});

export const GDPR_TABLE_SORTABLE_COLUMNS = {
	reference: "reference",
	first_name: "first_name",
	last_name: "last_name",
	phone: "phone",
	email: "email"
};

export const GDPR_TABLE_COLUMNS = [
	{
		field: "reference",
		colId: "reference",
		hide: false,
		pinned: false,
		cellRenderer: IdCell,
		cellClass: "locked-visible",
		lockVisible: true,
		filter: TableMenu,
		hideFilter: true,
		hidePin: true,
		isSortable: true,
		columnSortMap: GDPR_TABLE_SORTABLE_COLUMNS,
		headerName: "Id",
		currentFilterId: GDPR_LIST_FILTER,
		suppressMovable: true,
		resizable: false
	},
	{
		flex: 1,
		field: "first_name",
		colId: "first_name",
		hide: false,
		pinned: false,
		cellRenderer: GdprListGenericCell,
		cellClass: "locked-visible",
		lockVisible: true,
		columnSortMap: GDPR_TABLE_SORTABLE_COLUMNS,
		headerName: "First name",
		filter: TableMenu,
		hideFilter: true,
		hidePin: true,
		isSortable: true,
		currentFilterId: GDPR_LIST_FILTER,
		suppressMovable: true,
		resizable: false
	},
	{
		flex: 1,
		field: "last_name",
		colId: "last_name",
		hide: false,
		pinned: false,
		cellRenderer: GdprListGenericCell,
		cellClass: "locked-visible",
		lockVisible: true,
		columnSortMap: GDPR_TABLE_SORTABLE_COLUMNS,
		headerName: "Last name",
		hideFilter: true,
		hidePin: true,
		isSortable: true,
		filter: TableMenu,
		currentFilterId: GDPR_LIST_FILTER,
		suppressMovable: true,
		resizable: false
	},
	{
		flex: 1,
		field: "email",
		colId: "email",
		hide: false,
		pinned: false,
		cellRenderer: GdprListGenericCell,
		cellClass: "locked-visible",
		lockVisible: true,
		columnSortMap: GDPR_TABLE_SORTABLE_COLUMNS,
		headerName: "Email",
		hideFilter: true,
		hidePin: true,
		isSortable: true,
		filter: TableMenu,
		currentFilterId: GDPR_LIST_FILTER,
		suppressMovable: true,
		resizable: false
	},
	{
		flex: 1,
		field: "phone",
		colId: "phone",
		hide: false,
		pinned: false,
		cellRenderer: GdprListGenericCell,
		cellClass: "locked-visible",
		lockVisible: true,
		columnSortMap: GDPR_TABLE_SORTABLE_COLUMNS,
		headerName: "Phone",
		hideFilter: true,
		hidePin: true,
		isSortable: true,
		filter: TableMenu,
		currentFilterId: GDPR_LIST_FILTER,
		suppressMovable: true,
		resizable: false
	}
];
