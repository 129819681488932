import React from "react";
import { FormProvider } from "react-hook-form";
import styles from "./questionnaire-form.module.scss";
import cx from "classnames";

function QuestionnaireForm({ children, className }) {
	return <div className={cx(styles.questionnaire, className)}>{children}</div>;
}

export function QuestionnaireFormBody({ children, onSubmit, methods }) {
	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className={styles.container}
			>
				{children}
			</form>
		</FormProvider>
	);
}

export default QuestionnaireForm;
