import React, { useState } from "react";
import { Flex } from "rebass";
import Avatar from "common/Avatar/index";

import {
	Title,
	UserSender,
	UserSenderName,
	AvatarHolder,
	AttendeePopover,
	ContactPersonContainer,
	MoreAvatar,
	MoreHolder,
	AttendeesContainer
} from "./styled";
import { ReactComponent as AttendeesIcon } from "static/icons/attendees_icon.svg";

const ContactPerson = ({ attendeesUsers, viewDrawer }) => {
	const [canDisplayMore, setCanDisplayMore] = useState(false);
	const max = attendeesUsers.length > 2 ? 1 : 2;
	const attendees = attendeesUsers.slice(0, max);
	const rest = attendeesUsers.slice(max);
	const hasRest = rest.length;

	const displayContactPeople = () => {
		return (
			<ContactPersonContainer>
				{viewDrawer ? (
					<h4>Attendee(s)</h4>
				) : (
					<Title className="box_title">
						<AttendeesIcon /> Attendee(s)
					</Title>
				)}
				<AttendeesContainer viewDrawer={viewDrawer}>
					{attendees.map(attendee => {
						return (
							<Flex key={attendee._id}>
								<AvatarHolder viewDrawer={viewDrawer}>
									<Avatar
										name={`${attendee.first_name} ${attendee.last_name}`}
										src={attendee.avatar}
										size={20}
									/>
								</AvatarHolder>
								<UserSenderName>{`${attendee.first_name} ${attendee.last_name}`}</UserSenderName>
							</Flex>
						);
					})}
					{rest.length > 0 && (
						<MoreHolder>
							<MoreAvatar>+{rest.length}</MoreAvatar>{" "}
						</MoreHolder>
					)}
				</AttendeesContainer>
			</ContactPersonContainer>
		);
	};

	return (
		<>
			<div
				onMouseEnter={hasRest ? () => setCanDisplayMore(true) : null}
				onMouseLeave={hasRest ? () => setCanDisplayMore(false) : null}
				className="contact-with-popover"
			>
				{displayContactPeople(attendeesUsers)}
			</div>
			{canDisplayMore && (
				<AttendeePopover>
					{rest.map(attendee => (
						<UserSender key={attendee._id}>
							<AvatarHolder>
								<Avatar
									name={`${attendee.first_name} ${attendee.last_name}`}
									src={attendee.avatar}
									size={30}
								/>
							</AvatarHolder>
							<UserSenderName className="sender-name">{`${attendee.first_name} ${attendee.last_name}`}</UserSenderName>
						</UserSender>
					))}
				</AttendeePopover>
			)}
		</>
	);
};

export default ContactPerson;
