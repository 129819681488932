import React, { useEffect, useState } from "react";
import { queryCache } from "react-query";
import { Scrollbars } from "react-custom-scrollbars";
import filter from "lodash/filter";
import style from "./missions-list-columns-list.module.scss";
import { ReactComponent as CheckIcon } from "static/icons/IconCheck.svg";
import { SearchInput } from "common/SearchInput";
import EmptyState from "common/SectionsMenu/components/MenuEmptyState/MenuEmptyState";
import useUpdateColumns from "common/AgTable/api/useUpdateColumns";

import { API_DATA_TYPE } from "../_MissionsListUtils/missionsListUtils";
import { GET_MISSIONS_LIST_TABLE_SETTINGS_QUERY_KEY } from "../../api/useGetMissionsListTableSettings";

const MissionsListColumnsList = ({ columns }) => {
	const [search, setSearch] = useState("");

	const [filteredColumns, setFilteredColumns] = useState([]);

	const [colVisibility, setColVisibility] = useState({});

	const [showColumns, setShowColumns] = useState({});

	const showStateCondition = showColumns !== columns?.length ? false : true;

	useEffect(() => {
		setFilteredColumns(
			filter(columns, col => {
				return col.headerName.toLowerCase().includes(search.toLowerCase());
			})
		);

		let colObject = {};
		columns?.forEach(col => {
			colObject[col.colId] = col.hide;
		});
		setColVisibility(colObject);
		setShowColumns(getHiddenColumnsCount(columns));
	}, [search, columns]);

	const searchHandler = value => {
		setFilteredColumns(
			filter(columns, col => {
				return col.headerName.toLowerCase().includes(value.toLowerCase());
			})
		);
	};

	const [mutate] = useUpdateColumns({
		onSuccess: () => {
			queryCache.invalidateQueries(GET_MISSIONS_LIST_TABLE_SETTINGS_QUERY_KEY);
		}
	});

	const getHiddenColumnsCount = tableCols => {
		let hidden = 0;
		tableCols?.forEach(col => {
			if (!col.hide) {
				hidden += 1;
			}
		});
		return hidden;
	};

	const handleVisibility = (colId, visibility) => {
		const show = visibility ? 0 : columns?.length;
		setColVisibility(() => ({ ...colVisibility, [colId]: visibility }));

		if (colId === "all") {
			let colObject = {};
			columns.forEach(col => {
				colObject[col.colId] = visibility;
			});
			setColVisibility(colObject);
			setShowColumns(show);
		}

		mutate({
			columns: [colId],
			type: API_DATA_TYPE,
			tag: "hide_columns",
			hide: visibility
		});
	};

	const renderColumn = (block, key) => {
		return (
			<li key={key} onClick={() => handleVisibility(block.colId, !block.hide)}>
				<div className={style.check}>
					{!colVisibility[block.colId] ? <CheckIcon /> : null}
				</div>
				<span> {block.headerName} </span>
			</li>
		);
	};

	return (
		<div className={style.menuContainer}>
			<div className={style.inputContainer}>
				<SearchInput
					value={search}
					onChange={e => {
						setSearch(e.target.value);
						searchHandler(e.target.value);
					}}
					variant="borderless"
				/>
			</div>

			{filteredColumns?.length > 0 ? (
				<Scrollbars
					autoHide
					autoHideTimeout={5000}
					autoHideDuration={200}
					autoHeight
					autoHeightMax={300}
				>
					{filteredColumns && renderColumn && (
						<ul>{filteredColumns?.map(renderColumn)}</ul>
					)}
				</Scrollbars>
			) : (
				<EmptyState />
			)}

			<div className={style.separator}></div>

			<button
				onClick={() => handleVisibility("all", showStateCondition)}
				className={style.btnShow}
			>
				{showColumns !== columns?.length ? "Show all" : "Hide all"}
			</button>
		</div>
	);
};

export default MissionsListColumnsList;
