import React, { useEffect, useRef } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { get } from "lodash";
import { queryCache } from "react-query";
import Box from "modules/home/components/Box";
import useSuppliersStore from "modules/home/zustand/SuppliersStore";
import style from "./styles.module.scss";
import { HeaderEnd } from "modules/home/containers/interview/header.jsx";
import { historyPush } from "config/helpers";
import {
	MySuppliers,
	RecentSuppliers,
	NewFreelancer
} from "modules/home/components";
import { RECENTS_SUPPLIERS, MY_SUPPLIERS, NEW_FREELANCER } from "config";
import TabCount from "modules/home/components/TabCount.jsx";
import {
	recentSuppliersListKey,
	mySuppliersListKey,
	newFreelancersListKey
} from "modules/home/api/queriesKeys.js";
import { getFreelancersListRoute } from "modules/freelancers";

const Suppliers = ({ listCount }) => {
	const suppliersTypeKey = "suppliersType";
	const urlToRedirect = useRef("/suppliers-management#history");
	const {
		suppliersType,
		setItemSuppliers,
		suppliersStartDate,
		suppliersEndDate
	} = useSuppliersStore();
	useEffect(() => {
		getUrlState(suppliersType);
	}, []);
	const handleTabsChange = index => {
		switch (index) {
			case 0:
				setItemSuppliers(suppliersTypeKey, "recentSuppliers");
				queryCache.invalidateQueries(recentSuppliersListKey);
				urlToRedirect.current = "/suppliers-management?tab=3";
				break;
			case 1:
				setItemSuppliers(suppliersTypeKey, "mySuppliers");
				queryCache.invalidateQueries(mySuppliersListKey);
				urlToRedirect.current = "/missions";
				break;
			case 2:
				setItemSuppliers(suppliersTypeKey, "newFreelancer");
				queryCache.invalidateQueries(newFreelancersListKey);
				urlToRedirect.current = getFreelancersListRoute();
				break;
		}
	};
	const getUrlState = type => {
		switch (type) {
			case RECENTS_SUPPLIERS:
				urlToRedirect.current = "/suppliers-management?tab=3";
				break;
			case MY_SUPPLIERS:
				urlToRedirect.current = "/missions";
				break;
			case NEW_FREELANCER:
				urlToRedirect.current = getFreelancersListRoute();
				break;
			default:
				return 0;
		}
	};
	const getTypeIndex = type => {
		switch (type) {
			case RECENTS_SUPPLIERS:
				return 0;
			case MY_SUPPLIERS:
				return 1;
			case NEW_FREELANCER:
				return 2;
			default:
				return 0;
		}
	};
	const handleViewAll = () => {
		return historyPush(urlToRedirect.current);
	};
	const TabsPermanent = () => {
		return (
			<TabList>
				<Tab>
					Recent suppliers{" "}
					<TabCount count={get(listCount, "recent_suppliers", 0)} />{" "}
				</Tab>
				<Tab>
					My suppliers <TabCount count={get(listCount, "my_suppliers", 0)} />{" "}
				</Tab>
				<Tab>
					New freelancer{" "}
					<TabCount count={get(listCount, "new_freelancer", 0)} />{" "}
				</Tab>
			</TabList>
		);
	};
	return (
		<Tabs
			defaultIndex={getTypeIndex(suppliersType)}
			onChange={handleTabsChange}
		>
			<Box
				headerEnd={
					<HeaderEnd
						start_date={suppliersStartDate}
						end_date={suppliersEndDate}
						setItem={setItemSuppliers}
						start_date_Key={"suppliersStartDate"}
						end_date_key={"suppliersEndDate"}
						handleViewAll={handleViewAll}
					/>
				}
				headerStart={<TabsPermanent />}
			>
				<div className={style.tabsContainer}>
					<TabPanels>
						<TabPanel>
							<RecentSuppliers handleViewAll={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<MySuppliers handleViewAll={handleViewAll} />
						</TabPanel>
						<TabPanel>
							<NewFreelancer handleViewAll={handleViewAll} />
						</TabPanel>
					</TabPanels>
				</div>
			</Box>
		</Tabs>
	);
};

export default Suppliers;
