import find from "lodash/find";
import toaster from "common/Toaster";
import useRequestViewKanbanStore from "../store/useRequestViewKanbanStore";
import { STAGE_TYPE_BID, STAGE_TYPE_MISSION, TRIGGERS } from "../constants";
import useNotesStore from "modules/notes/notes.store";
import { generateId } from "config/helpers";
import useSelectedKanbanBid from "modules/RequestView/hooks/useSelectedKanbanBid";
import { useTempDrawerHelpers } from "../../drawers/hooks/useTempDrawerHelpers";
import { DRAWER_PROPOSE_CONTRACT } from "../../drawers/constants";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import useTempKanbanCardRules, {
	CARD_ACTION_NOTE,
	CARD_ACTION_PROPOSE_CONTRACT,
	CARD_ACTION_PROSPOSE_INTERVIEW
} from "./useTempKanbanCardRules";
import useCurrentRequest from "modules/RequestView/hooks/useCurrentRequest";
import { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { ADMIN, ACCEPTED, SIGNED, SENT } from "config";

export function useReorderCards() {
	const {
		kanbanStages,
		setStageCards,
		getStageCards
	} = useRequestViewKanbanStore();

	const reorderCards = ({ oldStage, newStage, endIndex, startIndex }) => {
		if (oldStage === newStage) {
			const targetStage = find(kanbanStages, s => s._id === newStage);
			const stageCards = getStageCards(targetStage.name);
			if (!stageCards) return;

			const [removedCard] = stageCards.splice(startIndex, 1);
			stageCards.splice(endIndex, 0, removedCard);

			setStageCards({
				data: stageCards,
				stageName: targetStage.name
			});
		} else {
			const oldTargetStage = find(kanbanStages, s => s._id === oldStage);
			const newTargetStage = find(kanbanStages, s => s._id === newStage);
			const oldStageCards = getStageCards(oldTargetStage.name);
			const newStageCards = getStageCards(newTargetStage.name);
			if (!oldStageCards || !newStageCards) return;

			const [removed] = oldStageCards.splice(startIndex, 1);
			newStageCards.splice(endIndex, 0, removed);

			setStageCards({
				data: oldStageCards,
				stageName: oldTargetStage.name
			});
			setStageCards({
				data: newStageCards,
				stageName: newTargetStage.name
			});
		}
	};

	return {
		reorderCards
	};
}

export function useCanDragCard() {
	const { kanbanStages, getStageCards } = useRequestViewKanbanStore();

	const canDragCard = ({ oldStage, newStage, startIndex }) => {
		const newTargetStage = find(kanbanStages, s => s._id === newStage);
		const oldTargetStage = find(kanbanStages, s => s._id === oldStage);

		// move to bid stage
		if (oldStage !== newStage && newTargetStage.type === STAGE_TYPE_BID) {
			toaster.warning("Moving candidate back to the bid stage is not allowed.");
			return false;
		}

		// move to mission stage
		if (oldStage !== newStage && newTargetStage.type === STAGE_TYPE_MISSION) {
			const oldStageCards = getStageCards(oldTargetStage.name);
			const draggedCard = oldStageCards?.[startIndex];
			if (draggedCard?.contract?.status !== "accepted") {
				toaster.warning(
					"Moving a candidate to the mission stage is not allowed."
				);
				return false;
			}
		}

		return true;
	};

	const getStartMissionInfo = ({ oldStage, startIndex, newStage }) => {
		const oldTargetStage = find(kanbanStages, s => s._id === oldStage);
		const newTargetStage = find(kanbanStages, s => s._id === newStage);
		const oldStageCards = getStageCards(oldTargetStage.name);
		const draggedCard = oldStageCards?.[startIndex];

		return {
			contractId: draggedCard?.contract?._id,
			isAccepted: draggedCard?.contract?.status === "accepted",
			isSigned: draggedCard?.contract?.signed_status === "signed",
			nextStageIsMission: newTargetStage.type === STAGE_TYPE_MISSION
		};
	};

	return {
		canDragCard,
		getStartMissionInfo
	};
}

export const usePreparePayload = () => {
	const { kanbanStages, getStageCards } = useRequestViewKanbanStore();

	function preparePayload(args) {
		const { tag, jobId, endIndex, startIndex } = args;
		const oldStage = args?.oldStage;
		const newStage = args?.newStage;

		const paylaod = {
			tag,
			job_id: jobId,
			position: endIndex
		};

		if (tag === "card") {
			const oldTargetStage = find(kanbanStages, s => s._id === oldStage);
			const oldStageCards = getStageCards(oldTargetStage.name);
			const draggedCard = oldStageCards?.[startIndex];

			paylaod.card_id = draggedCard?._id;
			paylaod.step_id = newStage;
		}

		if (tag === "step") {
			const draggedStep = kanbanStages?.[startIndex];
			paylaod.step_id = draggedStep?._id;
		}

		return paylaod;
	}

	return {
		preparePayload
	};
};

export function useTriggers({ onInterviewDrop }) {
	const { kanbanStages, getStageCards } = useRequestViewKanbanStore();
	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));
	const { setBid } = useSelectedKanbanBid();
	const { getCardActions } = useTempKanbanCardRules();
	const { requestId } = useCurrentRequest();

	const {
		initializeAndOpenDrawer: openProposeContractDrawer
	} = useTempDrawerHelpers({
		drawerType: DRAWER_PROPOSE_CONTRACT
	});

	const handleTriggers = ({ newStage, endIndex, jobId }) => {
		const [NOTE, INTERVIEW, CONTRACT] = TRIGGERS;

		const newTargetStage = find(kanbanStages, s => s._id === newStage);
		const newStageCards = getStageCards(newTargetStage.name);
		const draggedCard = newStageCards?.[endIndex];

		const {
			cardActionsList: authorizedCardActions,
			contractTooltipText
		} = getCardActions({
			bidData: draggedCard
		});

		const onProposeContract = () => {
			const contract = !_isArray(_get(draggedCard, "contract"))
				? _get(draggedCard, "contract")
				: null;

			const payload = {
				contract,
				bidId: draggedCard.bid_id,
				vmsSetting: _get(draggedCard, "vms_settings"),
				candidateName: _get(draggedCard, "candidate_name"),
				proposedAmount: _get(draggedCard, "proposed_amount"),
				reportingSetting: _get(draggedCard, "reporting_settings"),
				_queryCacheProps: { jobId, stageId: newStage }
			};
			openProposeContractDrawer({ drawerProps: payload });
		};

		switch (newTargetStage.triggers) {
			case NOTE:
				if (authorizedCardActions.includes(CARD_ACTION_NOTE))
					addNote({
						type: "BID_NOTE",
						id: generateId(),
						payload: {
							candidate: {
								label: draggedCard.candidate_name,
								profile_id: draggedCard._id
							},
							requestId: requestId
						}
					});
				break;
			case INTERVIEW:
				if (authorizedCardActions.includes(CARD_ACTION_PROSPOSE_INTERVIEW)) {
					setBid(draggedCard);
					onInterviewDrop();
				}
				break;
			case CONTRACT:
				if (
					!contractTooltipText &&
					authorizedCardActions.includes(CARD_ACTION_PROPOSE_CONTRACT)
				)
					onProposeContract();
				break;
			default:
				break;
		}
	};

	return {
		handleTriggers
	};
}

export function useKanbanStyles({ showBanner }) {
	const [kanbanStageStyles, setKanbanStageStyles] = useState({
		minHeight: "calc(100vh - 390px)",
		maxHeight: "calc(100vh - 390px)"
	});

	useEffect(() => {
		let height = "calc(100vh - 330px)";
		if (showBanner) height = "calc(100vh - 390px)";

		setKanbanStageStyles({
			minHeight: height,
			maxHeight: height
		});
	}, [showBanner]);

	return {
		kanbanStageStyles
	};
}

export function useKanbanBannerContent() {
	const {
		kanbanStages,
		getStageCards,
		...kanbanStore
	} = useRequestViewKanbanStore();

	const job = useSelector(state => state.job.data);
	const user = useSelector(state => state.auth.user);
	const canProposeContract = useMemo(() => {
		const groups = _get(user, "groups", []);
		return (
			!user.is_member ||
			groups.some(
				group =>
					group.id === _get(job, "group._id") && group.role_name === ADMIN
			)
		);
	}, [job, user]);

	const bannerContent = useMemo(() => {
		const userRole = user.role_name;
		if (!canProposeContract || userRole !== ADMIN) {
			return "You can't propose contracts or reject candidates because you don't have permission for that.";
		}

		// todo not efficient need to be improved
		let contractedCard = null;
		for (let stage of kanbanStages) {
			const stageCards = getStageCards(stage.name) || [];
			contractedCard = stageCards.find(card => {
				const contract = !_isArray(_get(card, "contract"))
					? _get(card, "contract")
					: null;

				return !!contract && [SENT, ACCEPTED].includes(contract?.status);
			});
			if (contractedCard) break;
		}

		if (!contractedCard) return "";

		if (contractedCard.contract.signed_status === SIGNED) {
			return "Your request has been closed as the mission with the freelancer has started. Looking for more freelancers? Easily duplicate your request.";
		}
		if (contractedCard.contract.status === ACCEPTED) {
			return "Your request has been automatically closed upon the freelancer accepting the contract. Looking for more freelancers? Easily duplicate your request.";
		}
		if (contractedCard.contract.status === SENT) {
			return "You are currently unable to propose another contract to any freelancer as you have already submitted a contract proposal.";
		}

		return "";
	}, [kanbanStore, kanbanStages]);

	return { bannerContent };
}
