import React from "react";
import classnames from "./list-menu.module.scss";

const DropDown = ({ options, onSelect }) => {
	const clickHandler = option => onSelect(option);
	return (
		<div className={classnames.container}>
			<ul>
				{options.map(option => (
					<li onClick={() => clickHandler(option)} key={option.label}>
						<span className={classnames.label}>{option.label}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default DropDown;
