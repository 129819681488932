import { useState, useEffect } from "react";

export default function useGeoLocation() {
	const [state, setState] = useState({
		loading: true,
		accuracy: null,
		altitude: null,
		altitudeAccuracy: null,
		heading: null,
		latitude: null,
		longitude: null,
		speed: null,
		timestamp: Date.now()
	});

	let mounted = true;

	const onEvent = event => {
		if (mounted) {
			setState({
				loading: false,
				accuracy: event.coords.accuracy,
				altitude: event.coords.altitude,
				altitudeAccuracy: event.coords.altitudeAccuracy,
				heading: event.coords.heading,
				latitude: event.coords.latitude,
				longitude: event.coords.longitude,
				speed: event.coords.speed,
				timestamp: event.timestamp
			});
		}
	};

	const onEventError = error =>
		mounted && setState(oldState => ({ ...oldState, loading: false, error }));

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(onEvent, onEventError);
		return () => {
			mounted = false;
		};
	}, []);

	return state;
}
