import { useEditVacancyApi } from "../api/useEditVacancyApi";
import { buildEditVacancyInformationsPayload } from "../utils/helper";

export const useEditVacancyVacancyInformations = () => {
	const [mutate, { isLoading }] = useEditVacancyApi();
	const editVacancy = (values, vacancyId, options) => {
		const body = buildEditVacancyInformationsPayload(values, vacancyId);

		mutate(body, options);
	};

	return [editVacancy, isLoading];
};
