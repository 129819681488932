import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { MANAGE_EMAIL_TEMPLATE } from "config/api-endpoints";

const deleteTemplate = body => {
	return client(MANAGE_EMAIL_TEMPLATE, {
		body: {
			...body,
			action: "delete"
		}
	});
};

export default function useDeleteEmailTemplate() {
	return useMutation(deleteTemplate);
}
