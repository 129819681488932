import React, { useState } from "react";
import { browserHistory } from "react-router";
import _get from "lodash/get";
import { queryCache } from "react-query";
import { Flex } from "rebass/styled-components";
import CategoryTiering from "./CategoryTiering";
import SupplierKanbanSearch from "modules/company/components/VariableMargins/components/Search.jsx";
import Kanban from "modules/company/components/VariableMargins/components/Kanban.jsx";
import EmptyComponent from "common/EmptyComponent";
import useGetFeatureSettings from "modules/company/components/VariableMargins/api/useGetFeatureSettings.js"; // getFeatureSettingsQueryKey
import useUpdateFeatureSettings from "modules/company/components/VariableMargins/api/useUpdateFeatureSettings.js";
import toaster from "common/Toaster";
import s from "modules/company/components/VariableMargins/components/margin-form.module.scss";
import _map from "lodash/map";
import { CategoryTieringContainer } from "./styled";

export const categoryVariableMarginKey = "@suppliers/get-category-data";

const Tiering = ({
	isTiersManagement,
	register,
	search,
	categoryId,
	setSearch,
	handleSubmit,
	TabsHeader
}) => {
	const [payload, setPayload] = useState([]);

	const { status, data, isLoading: isFetching } = useGetFeatureSettings(
		categoryVariableMarginKey,
		{
			category_id: categoryId,
			feature_name: "feature_variable_margins",
			search
		},
		{
			onError: error => {
				if (error && error.status === 403) {
					browserHistory.push("/");
				}
			}
		}
	);

	const [updateSettings, { isLoading }] = useUpdateFeatureSettings({
		onSuccess() {
			queryCache.invalidateQueries(categoryVariableMarginKey);
			toaster.success("Update successful.");
		}
	});
	const saveChanges = fields => {
		updateSettings({
			category_id: categoryId,
			id: data?.feature_settings?._id,
			freelancer: {
				active: fields.freelancer.active,
				request_visibility_day: parseInt(
					fields.freelancer.request_visibility_day
				)
			},
			consultancy: {
				tiers_management: fields.consultancy.tiers_management === "active",
				request_visibility_day: parseInt(
					fields.consultancy.request_visibility_day || 0
				)
			},
			tiers: _map(fields.tiers, (tier, key) => ({
				_id: key,
				can_submit_till_day: parseInt(tier.can_submit_till_day),
				number_of_submission_per_request: parseInt(
					tier.number_of_submission_per_request
				),
				visibility_from_day: parseInt(tier.visibility_from_day)
			})),
			tier_moves: payload
		});
	};

	const tiers = _get(data, "feature_settings.tiers", []);

	return (
		<>
			{isTiersManagement && (
				<CategoryTieringContainer>
					<div className="categotyTiers">
						<label className="blockTitle">Category</label>
						{TabsHeader}
						<CategoryTiering
							data={_get(data, "feature_settings")}
							register={register}
						/>
					</div>

					<div className="box-tiers">
						<SupplierKanbanSearch search={search} onSearch={setSearch} />
						{!isFetching &&
						_get(data, "feature_settings.tiers", []).length > 0 ? (
							<Kanban
								tiers={tiers}
								status={status}
								payload={payload}
								setPayload={setPayload}
								isFetching={isFetching}
								featureID={_get(data, "feature_settings._id")}
								categoryId={categoryId}
								data={data}
							/>
						) : (
							<EmptyComponent
								className="no-border small-size inProcess empty-tier"
								title="Select a category from the select above to get started."
							/>
						)}
					</div>
					<Flex mt={4} justifyContent="flex-end">
						<button
							disabled={!data || isLoading}
							className={s.button}
							onClick={handleSubmit(saveChanges)}
						>
							{isLoading ? "Saving..." : "Save changes"}
						</button>
					</Flex>
				</CategoryTieringContainer>
			)}
		</>
	);
};

export default Tiering;
