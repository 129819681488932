import { createSelector } from "reselect";
import _get from "lodash/get";

const user = state => {
	return {
		id: _get(state, "auth.user.id"),
		first_name: _get(state, "auth.user.first_name"),
		last_name: _get(state, "auth.user.last_name"),
		email: _get(state, "auth.user.email"),
		type:
			_get(state, "auth.user.company_type") || _get(state, "auth.user.type"),
		show_notification_in_real_time: _get(
			state,
			"auth.user.show_notification_in_real_time"
		),
		receive_all_emails: _get(state, "auth.user.receive_all_emails"),
		phone: _get(state, "auth.user.phone"),
		user_function: _get(state, "auth.user.user_function"),
		avatar: _get(state, "auth.user.avatar"),
		role_name: _get(state, "auth.user.role_name"),
		active_role: _get(state, "auth.user.active_role"),
		groups: _get(state, "auth.user.groups", []),
		is_member: _get(state, "auth.user.is_member")
	};
};

export const userSelector = createSelector(user, user => user);
