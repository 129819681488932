import React, { useMemo } from "react";
import styles from "./request-pipeline-card-footer.module.scss";
import Tooltip from "common/Tippy";
import { IconButton } from "common/IconButton";
import { ReactComponent as DocIcon } from "static/icons/doc.svg";
import { ReactComponent as MessageIcon } from "static/icons/message.svg";
import { ReactComponent as Message } from "static/icons/message-3-dots.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete-circle.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-icon-dark.svg";
import useTempKanbanCardRules, {
	CARD_ACTION_NOTE,
	CARD_ACTION_PROPOSE_CONTRACT,
	CARD_ACTION_PROSPOSE_INTERVIEW,
	CARD_ACTION_REJECT_APPLICATION
} from "modules/RequestView/components/kanban/hooks/useTempKanbanCardRules";

const RequestPipelineCardFooter = ({
	bidData,
	onAddNote,
	notesCount,
	onProposeContract,
	onRejectApplication,
	onInterviewButtonClick
}) => {
	const { getCardActions } = useTempKanbanCardRules();
	const {
		cardActionsList: authorizedCardActions,
		contractTooltipText
	} = useMemo(() => getCardActions({ bidData }), [bidData]);

	const canProposeInterview = authorizedCardActions.includes(
		CARD_ACTION_PROSPOSE_INTERVIEW
	);

	const canProposeContract =
		!contractTooltipText &&
		authorizedCardActions.includes(CARD_ACTION_PROPOSE_CONTRACT);

	const canAddNote = authorizedCardActions.includes(CARD_ACTION_NOTE);

	const canRejectApplication = authorizedCardActions.includes(
		CARD_ACTION_REJECT_APPLICATION
	);

	const interviewTooltipContent =
		bidData?.interview?.length !== 0
			? "Propose another interview"
			: "Propose interview";

	const contract = bidData?.contract;
	const contractTooltipContent =
		canProposeContract && contract?.length !== 0
			? "Propose other terms"
			: canProposeContract && contract?.length === 0
			? "Propose contract"
			: contractTooltipText;

	return (
		<div className={styles.container}>
			<div className={styles.messageButton}>
				<Message />
				<div>{notesCount}</div>
			</div>

			<Tooltip
				theme="dark"
				content={interviewTooltipContent}
				overflow={canProposeInterview ? "visible" : ""}
			>
				<IconButton
					onClick={onInterviewButtonClick}
					isDisabled={!canProposeInterview}
				>
					<CalendarIcon />
				</IconButton>
			</Tooltip>

			<Tooltip
				theme="dark"
				content={contractTooltipContent}
				overflow={contractTooltipContent ? "visible" : ""}
			>
				<IconButton
					onClick={onProposeContract}
					isDisabled={!canProposeContract}
				>
					<DocIcon />
				</IconButton>
			</Tooltip>

			<Tooltip
				theme="dark"
				content="Add note"
				overflow={canAddNote ? "visible" : ""}
			>
				<IconButton isDisabled={!canAddNote} onClick={onAddNote}>
					<MessageIcon />
				</IconButton>
			</Tooltip>

			<Tooltip
				theme="dark"
				content="Reject application"
				overflow={canRejectApplication ? "visible" : ""}
			>
				<IconButton
					onClick={onRejectApplication}
					isDisabled={!canRejectApplication}
				>
					<DeleteIcon />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default RequestPipelineCardFooter;
