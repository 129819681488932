import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

export const listExtraBenifitsKey = "list_extra_benefits";

const getlistExtraBenefits = body => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body
	});
};

const useGetlistExtraBenefits = (
	key = listExtraBenifitsKey,
	options,
	queryOptions = {}
) =>
	useQuery([key, options], () => getlistExtraBenefits(options), {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
export default useGetlistExtraBenefits;
