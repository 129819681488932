import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import DrawerSection from "./DrawerSection/DrawerSection";
import DeleteModal from "./DeleteModal/DeleteModal";
import useGetCommunityCriteria from "modules/SearchCandidate/api/useGetCommunityCriteria";
import { useDebounce } from "react-use";
import { sectionsDisplayManager } from "modules/SearchCandidate/utils/accordion";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import get from "lodash/get";
import { SearchInput } from "common/SearchInput";
import EmptyState from "common/AgTable/components/EmptyState/EmptyState";
import { CANDIDATES_FILTER } from "../../utils/constants";
import { formatAllFilters } from "common/FilterDrawer/format_utils";

const CommunityCriteria = () => {
	const [search, setSearch] = useState("");
	const [sectionsState, setSectionsState] = useState({});
	const [deleteCriteria, setDeleteCriteria] = useState({});
	const [debouncedSearch, setDebouncedSearch] = useState("");
	const [showModal, setShowModal] = useState(false);
	const { setSelectedCriteria, getState, setSource, setFilters } = useFilters();
	const { columns } = getState(CANDIDATES_FILTER);

	useDebounce(
		() => {
			setDebouncedSearch(search);
		},
		500,
		[search]
	);

	const { data } = useGetCommunityCriteria({
		search: debouncedSearch,
		tag: "candidate_search"
	});

	useEffect(() => {
		let sectionsStateobj = {};
		if (data) {
			data.forEach(section => {
				sectionsStateobj[section.type] = false;
			});
		}
		setSectionsState({ ...sectionsStateobj });
	}, [data]);

	const rowSelectedHandler = async criteria => {
		const formatted_filters = await formatAllFilters(
			columns,
			get(criteria, "fields", [])
		);
		setFilters(formatted_filters);
		setSource(get(criteria, "data_source", "all"));
		setSelectedCriteria({
			_id: get(criteria, "_id", ""),
			name: get(criteria, "name", "")
		});
	};

	const deleteCriteraHandler = criteria => {
		setDeleteCriteria({
			_id: get(criteria, "_id", ""),
			name: get(criteria, "name", "")
		});
		setShowModal(true);
	};

	return (
		<>
			<div className={styles.input_container}>
				<SearchInput
					onChange={e => {
						setSearch(e.target.value);
					}}
					variant="fixed_width"
					style={{ width: "50%" }}
				/>
			</div>

			{data && data.length > 0 ? (
				<div>
					{data.map((section, index) => {
						return (
							<DrawerSection
								section={section}
								key={index}
								rowSelected={c => rowSelectedHandler(c)}
								deleteClicked={c => deleteCriteraHandler(c)}
								expanded={sectionsState[section.type]}
								setExpanded={section => {
									setSectionsState(
										sectionsDisplayManager(sectionsState, section)
									);
								}}
							/>
						);
					})}
				</div>
			) : (
				<EmptyState text="Search for something else" />
			)}

			{showModal && deleteCriteria && (
				<DeleteModal
					criteria={deleteCriteria}
					onClose={() => setShowModal(false)}
				/>
			)}
		</>
	);
};

export default CommunityCriteria;
