import React from "react";
import { StyledModalTitle } from "../styles/StyledInterview";

const ModalTitle = ({ currentStep }) => {
	const renderTitle = () => {
		switch (currentStep) {
			case 0:
				return "Poke Candidate";
			case 1:
				return "Interview Invitation";
			case 2:
				return "Preview";
		}
	};
	return <StyledModalTitle>{renderTitle()}</StyledModalTitle>;
};

export default ModalTitle;
