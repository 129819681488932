import create from "zustand";

export const viewTypes = {
	my_freelancers: "my_freelancers",
	pending_activation: "pending_activation"
};

const state = {
	activeView: viewTypes.my_freelancers
};

export const useFreelancerFilters = create(set => ({
	...state,
	setActiveView: value => {
		set(() => ({ activeView: value }));
	}
}));
