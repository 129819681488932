import React from "react";
import Modal from "common/modal";
import { browserHistory } from "react-router";

import style from "./shortListDrawer.module.scss";

const ShortListingDoneModal = ({
	setShortListDone,
	notify,
	isLoadingListCandidate,
	countShortList,
	VacancyId,
	jobId,
	reset
}) => {
	const redirectToVaccancy = () => {
		setShortListDone(false);
		reset();
		VacancyId
			? browserHistory.push("/vacancy/" + VacancyId)
			: browserHistory.push("/vacancy/" + jobId);
	};
	return (
		<>
			<Modal
				className={style.modalShortListDone}
				onClose={() => {
					reset();
					setShortListDone(false);
				}}
				loading={isLoadingListCandidate}
			>
				<h3>Adding done!</h3>
				<p>You have successfully added {countShortList} new candidates</p>
				<div className={style.footer_drawer}>
					<button
						className={style.buttonCancel}
						onClick={() => {
							reset();
							notify();
						}}
						disabled={isLoadingListCandidate}
					>
						Notify candidates
					</button>
					<button className={style.buttonSave} onClick={redirectToVaccancy}>
						{VacancyId ? "Back to vacancy" : "Go to vacancy"}
					</button>
				</div>
			</Modal>
		</>
	);
};

export default ShortListingDoneModal;
