import React from "react";
import StyledIcon, { StyledSplitIcon, StyledEditIcon } from "./style";

const Icon = props => <StyledIcon {...props} />;
Icon.defaultProps = {
	right: true,
	left: false
};
export const SplitIcon = props => <StyledSplitIcon className={props.icon} />;
export const EditIcon = props => <StyledEditIcon className={props.icon} />;
export default Icon;
