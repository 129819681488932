import React, { useRef } from "react";
import { ReactComponent as ColumnIcon } from "static/icons/Icon.svg";
import { ReactComponent as FilterIcon } from "static/icons/Iconfilter.svg";
import { ReactComponent as Arrow } from "static/icons/ArrowIcon.svg";

import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { SearchInput } from "common/SearchInput";
import cx from "classnames";

import style from "./missions-list-header.module.scss";
import { MISSIONS_LIST_FILTER_ID } from "../_MissionsListUtils/missionsListUtils";
import HeaderButton from "../MissionsListHeaderButton/MissionsListHeaderButton";
import MissionsListColumnsList from "../MissionsListColumnsList/MissionsListColumnsList";
import { validFiltersCount } from "common/FilterDrawer/render_utils";

const MissionsListHeader = ({
	filters,
	showRecap,
	columns,
	onFilterClick,
	onRecapClick
}) => {
	const { setQuery, getState } = useFilters();
	const { query, queryError } = getState(MISSIONS_LIST_FILTER_ID);
	const ref = useRef();
	const onSearch = e => {
		setQuery(e.target.value);
	};
	return (
		<div className={style.filtersContainer}>
			<div className={style.firstContainer}>
				<SearchInput
					className={cx(style.search, {
						[style.error]: queryError
					})}
					value={query}
					onChange={onSearch}
					placeholder={"Search on consultant, mission & content."}
					variant="fixed_width"
					ref={ref}
				/>
				<div className={style.filter_btns_container}>
					<HeaderButton
						icon={<FilterIcon />}
						text="Filter"
						className="btnAddFilter"
						onClick={onFilterClick}
					/>
					{validFiltersCount(filters) > 0 && (
						<button className={style.recap_btn} onClick={onRecapClick}>
							<span>{validFiltersCount(filters)}</span>
							<Arrow
								className={
									showRecap ? `${style.arrow} ${style.up}` : style.arrow
								}
							/>
						</button>
					)}
				</div>
			</div>
			<div className={style.secondContainer}>
				<HeaderButton
					icon={<ColumnIcon />}
					text="Columns"
					className="btnCriteria"
					secondIcon={<Arrow />}
				>
					<MissionsListColumnsList columns={columns} />
				</HeaderButton>
			</div>
		</div>
	);
};

export default MissionsListHeader;
