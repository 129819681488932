import { useQuery } from "react-query";
import { client } from "../../../lib/api-client";
import { VIEW_SUPPLIER_INVITATION_ENDPOINT } from "../../../config/api-endpoints";

function viewSupplierInvitation(id) {
	return client(VIEW_SUPPLIER_INVITATION_ENDPOINT, {
		body: {
			id
		}
	});
}

function useViewSupplierInvitation({ id }) {
	return useQuery(
		["@supplier/view-invitation", id],
		() => viewSupplierInvitation(id),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			staleTime: Infinity
		}
	);
}

export default useViewSupplierInvitation;
