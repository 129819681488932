import React, {
	useEffect,
	useState,
	forwardRef,
	useCallback,
	useRef,
	useMemo
} from "react";
import Tooltip from "common/Tippy";
import { useFormContext } from "react-hook-form";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import styles from "./add-vacancy-description-form.module.scss";
import { ReactComponent as DeleteIconRed } from "static/icons/delete-icon-red.svg";
import { ReactComponent as StarIconGreen } from "static/icons/small-star-icon.svg";
import { Button } from "common/Button";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";
import AddVacancyCustomInput from "../AddVacancyCustomInput/AddVacancyCustomInput";
import Editor from "../../../../../common/Editor/index";
import {
	JOB_DESCRIPTION_FIELDS,
	EDITOR_MAX_LENGTH
} from "../../utils/constants";
import { getHTMLTextLength, renderError } from "config/helpers";
import { isLanguageProtected } from "../../utils/helper";
import AIGeneratorBanner from "../AIGeneratorBanner/AIGeneratorBanner";
import EnhanceWithAIButton from "../EnhanceWithAIButton/EnhanceWithAIButton";
import { useGenerateVacancyDescription } from "../../hooks/useGenerateVacancyDescription";
import _get from "lodash/get";
import GenerateContentSkeleton from "../GenerateDescriptionSkeleton/GenerateDescriptionSkeleton";
import { AddVacancyEnhanceTextDialog } from "../AddVacancyEnhanceTextDialog";
import { useEnhanceText } from "../../api/useEnhanceText";
import useEnhanceSelectedText from "../../hooks/useEnhanceSelectedText";
import { $generateHtmlFromNodes } from "@lexical/html";

const AddVacancyDescriptionForm = (
	{
		onDelete,
		languageId,
		isPrimary,
		onSetPrimaryClick,
		fieldName,
		setValue,
		onLanguageChange,
		index,
		titleFocus,
		onJobTitleFocus,
		descriptionFocus = false,
		onDescriptionFocus,
		profileDescriptionFocus,
		onProfileDescriptionFocus,
		isFormSubmitted,
		triggerFormValidation,
		vacancy,
		vacancyId,
		language
	},
	ref
) => {
	const [showEnhanceTextDialog, setShowEnhanceTextDialog] = useState(false);
	const [isEnhancingTextLoading, setIsEnhancingTextLoading] = useState(false);
	const [formValues, setFormValues] = useState(null);
	const [field, setField] = useState("");
	const [aiAction, setAiAction] = useState(null);
	const [selectedHtml, setSelectedHtml] = useState("");

	const {
		watch,
		formState: { errors }
	} = useFormContext();

	const formErrors = errors.description?.[index] || {};
	const titleRef = useRef();

	const {
		jobTitle,
		jobDescription,
		profileDescription,
		isDescriptionGenerated
	} = watch("description")?.[index];

	const [isExtractingJobPostingData, setIsExtractingJobPostingData] = useState(
		!isDescriptionGenerated
	);

	useGenerateVacancyDescription(
		{ job_id: vacancyId, version: language },
		{
			enabled: !isDescriptionGenerated,
			onSuccess: data => {
				onTitleChange(_get(data, "job_title", ""));
				onDescriptionChange(_get(data, "job_description", ""));
				onProfileDescriptionChange(_get(data, "profile_description", ""));
				setValue(`${fieldName}.isDescriptionGenerated`, true);
				setIsExtractingJobPostingData(false);
			},
			onError: error => {
				setIsExtractingJobPostingData(false);
				renderError(error);
			}
		}
	);

	useEnhanceSelectedText(
		{
			job_id: vacancyId,
			key:
				field === JOB_DESCRIPTION_FIELDS.jobDescription
					? "job_description"
					: "profile_description",
			action: aiAction?.action,
			text:
				field === JOB_DESCRIPTION_FIELDS.jobDescription
					? jobDescription
					: profileDescription,
			selected_text: selectedHtml,
			value: aiAction?.value || ""
		},
		{
			enabled: !!selectedHtml,
			onSuccess: data => {
				setSelectedHtml("");
				if (field === JOB_DESCRIPTION_FIELDS.jobDescription) {
					onDescriptionChange(data?.enhanced_text);
				} else {
					onProfileDescriptionChange(data?.enhanced_text);
				}

				setIsEnhancingTextLoading(false);
			},
			onError: error => {
				setSelectedHtml("");
				setIsEnhancingTextLoading(false);
				renderError(error);
			}
		}
	);

	const [showToolbar, setShowToolbar] = useState(false);
	const [titleInput, descriptionInput, profileDescriptionInput] = ref;
	const { isLoading } = useEnhanceText(
		{
			job_id: vacancyId,
			key: field,
			version: language,
			prompt: formValues?.prompt,
			tone: formValues?.tone || ""
		},
		{
			enabled: !!formValues?.prompt,
			onSuccess: data => {
				switch (field) {
					case JOB_DESCRIPTION_FIELDS.jobDescription:
						onDescriptionChange(_get(data, "enhanced_text", ""));
						break;
					case JOB_DESCRIPTION_FIELDS.profileDescription:
						onProfileDescriptionChange(_get(data, "enhanced_text", ""));
						break;
					default:
						onTitleChange(_get(data, "enhanced_text", ""));
						break;
				}
				setFormValues(null);
				setIsEnhancingTextLoading(false);
			},
			onError: error => {
				setFormValues(null);
				setIsEnhancingTextLoading(false);
				renderError(error);
			}
		}
	);

	const editorRef = useRef();

	const [showBanner, setShowBanner] = useState(true);

	useEffect(() => {
		if (titleFocus) {
			titleRef.current.focus();
			onJobTitleFocus();
		} else if (descriptionFocus) {
			ref[1].current.focus();
			onDescriptionFocus();
		} else if (profileDescriptionFocus) {
			ref[2].current.focus();
			onProfileDescriptionFocus();
		}
	}, []);

	const onProfileDescriptionChange = useCallback(
		value => {
			setValue(`${fieldName}.profileDescription`, value);
			onLanguageChange();

			if (isFormSubmitted) {
				validateField(
					value,
					`${fieldName}.profileDescription`,
					formErrors.profileDescription
				);
			}
		},
		[
			onLanguageChange,
			isFormSubmitted,
			formErrors.profileDescription,
			validateField
		]
	);

	const onDescriptionChange = useCallback(
		value => {
			setValue(`${fieldName}.jobDescription`, value);
			onLanguageChange();

			if (isFormSubmitted) {
				validateField(
					value,
					`${fieldName}.jobDescription`,
					formErrors.jobDescription
				);
			}
		},
		[
			onLanguageChange,
			isFormSubmitted,
			validateField,
			formErrors.jobDescription
		]
	);

	const validateField = useCallback((value, name, error) => {
		const length = getHTMLTextLength(value);
		const isValid = length > 0 && length <= 10000;

		if (isValid && error) {
			triggerFormValidation(name);
		} else if (!isValid && !error) {
			triggerFormValidation(name);
		}
	}, []);

	const onTitleChange = useCallback(
		value => {
			setValue(`${fieldName}.jobTitle`, value);
			onLanguageChange();

			if (isFormSubmitted) {
				const value = value;
				const error = formErrors.jobTitle;

				if (value && error) triggerFormValidation(`${fieldName}.jobTitle`);
				else if (!value && !error)
					triggerFormValidation(`${fieldName}.jobTitle`);
			}
		},
		[onLanguageChange, formErrors.jobTitle, isFormSubmitted]
	);

	const descriptionLength = useMemo(() => getHTMLTextLength(jobDescription), [
		jobDescription
	]);

	const profileDescriptionLength = useMemo(
		() => getHTMLTextLength(profileDescription),
		[profileDescription]
	);

	const isProtected =
		vacancy?.versions && isLanguageProtected(languageId, vacancy);

	const deleteButton = (
		<Button
			variant="text"
			text="Delete"
			icon={
				<DeleteIconRed
					className={isProtected ? styles.deleteIconDisabled : ""}
					stroke="red"
				/>
			}
			className={styles.deleteButton}
			onClick={onDelete}
			disabled={isProtected}
		/>
	);

	const onEnhanceSelectedText = (action, editor, selection) => {
		setAiAction(action);
		setSelectedHtml($generateHtmlFromNodes(editor, selection));
		setIsEnhancingTextLoading(true);
	};

	return (
		<div className={styles.container}>
			{!isPrimary && (
				<div className={styles.buttonsContainer}>
					<Button
						variant="text"
						text="Set as original"
						icon={<StarIconGreen />}
						className={styles.setOriginalButton}
						onClick={onSetPrimaryClick}
					/>
					{isProtected ? (
						<Tooltip
							content="You cannot delete this language version because it has already been published to external channels"
							theme="dark"
						>
							{deleteButton}
						</Tooltip>
					) : (
						deleteButton
					)}
				</div>
			)}

			{showBanner && <AIGeneratorBanner onClose={() => setShowBanner(false)} />}

			<CreateVacancyFieldContainer>
				<CreateVacancyLabelContainer>
					<div className={styles.vacancyLabelContainer}>
						<div className={styles.fieldHeaderContainer}>
							<CreateVacancyFieldLabel>Job title</CreateVacancyFieldLabel>
							<CreateVacancyInfoIcon>
								The title should be as explicit and objective as possible
								regarding the actual job title that the person selected will
								have within your company.
							</CreateVacancyInfoIcon>
						</div>

						<EnhanceWithAIButton
							onClick={() => {
								setField(JOB_DESCRIPTION_FIELDS.title);
								setShowEnhanceTextDialog(true);
							}}
						/>
					</div>
				</CreateVacancyLabelContainer>
				{isExtractingJobPostingData ||
				(isEnhancingTextLoading && field === JOB_DESCRIPTION_FIELDS.title) ? (
					<GenerateContentSkeleton />
				) : (
					<AddVacancyCustomInput
						ref={ref => {
							titleRef.current = ref;
							titleInput.current = ref;
						}}
						placeHolder="A descriptive title of your job"
						onChange={e => onTitleChange(e.target.value)}
						value={jobTitle}
					/>
				)}

				<CreateVacancyFieldError error={formErrors.jobTitle} />
			</CreateVacancyFieldContainer>
			<CreateVacancyFieldContainer>
				<CreateVacancyLabelContainer>
					<div className={styles.vacancyLabelContainer}>
						<div className={styles.fieldHeaderContainer}>
							<CreateVacancyFieldLabel>Job description</CreateVacancyFieldLabel>
							<CreateVacancyInfoIcon>
								A general description of the vacancy. Feel free to detail the
								function itself, the working environment, or even the soft
								skills needed.
							</CreateVacancyInfoIcon>
						</div>
						<EnhanceWithAIButton
							onClick={() => {
								setField(JOB_DESCRIPTION_FIELDS.jobDescription);
								setShowEnhanceTextDialog(true);
							}}
						/>
					</div>
				</CreateVacancyLabelContainer>
				{isExtractingJobPostingData ||
				(isEnhancingTextLoading &&
					field === JOB_DESCRIPTION_FIELDS.jobDescription) ? (
					<GenerateContentSkeleton multiLines />
				) : (
					<Editor
						ref={descriptionInput}
						placeholder="Description of the role, tasks to perform, the profile sought, ..."
						scrollClassName={styles.editor}
						showToolbaar={showToolbar}
						setShowToolbar={setShowToolbar}
						useMentionPlugin={false}
						value={jobDescription}
						onChange={onDescriptionChange}
						focusClassName={styles.editorFocused}
						focus={descriptionFocus}
						namespace={JOB_DESCRIPTION_FIELDS.jobDescription}
						useAiTools
						onFocus={() => {
							setField(JOB_DESCRIPTION_FIELDS.jobDescription);
						}}
						onEnhanceSelectedText={onEnhanceSelectedText}
						editorRef={editorRef}
					/>
				)}
				<div className={styles.errorContainer}>
					<CreateVacancyFieldError error={formErrors.jobDescription} />
					{!isNaN(descriptionLength) && (
						<div className={styles.lengthContainer}>
							{descriptionLength.toLocaleString("fr")}/
							{EDITOR_MAX_LENGTH.toLocaleString("fr")}
						</div>
					)}
				</div>
			</CreateVacancyFieldContainer>
			<CreateVacancyFieldContainer>
				<CreateVacancyLabelContainer>
					<div className={styles.vacancyLabelContainer}>
						<CreateVacancyFieldLabel>
							Profile description
						</CreateVacancyFieldLabel>
						<EnhanceWithAIButton
							onClick={() => {
								setField(JOB_DESCRIPTION_FIELDS.profileDescription);
								setShowEnhanceTextDialog(true);
							}}
						/>
					</div>
				</CreateVacancyLabelContainer>
				{isExtractingJobPostingData ||
				(isEnhancingTextLoading &&
					field === JOB_DESCRIPTION_FIELDS.profileDescription) ? (
					<GenerateContentSkeleton multiLines />
				) : (
					<Editor
						ref={profileDescriptionInput}
						placeholder="Description of the profile you’re looking for..."
						scrollClassName={styles.editor}
						showToolbaar={showToolbar}
						setShowToolbar={setShowToolbar}
						useMentionPlugin={false}
						value={profileDescription}
						onChange={onProfileDescriptionChange}
						focusClassName={styles.editorFocused}
						focus={profileDescriptionFocus}
						namespace={JOB_DESCRIPTION_FIELDS.profileDescription}
						useAiTools
						onFocus={() => {
							setField(JOB_DESCRIPTION_FIELDS.profileDescription);
						}}
						onEnhanceSelectedText={onEnhanceSelectedText}
						editorRef={editorRef}
					/>
				)}

				<div className={styles.errorContainer}>
					<CreateVacancyFieldError error={formErrors.profileDescription} />
					{!isNaN(profileDescriptionLength) && (
						<div className={styles.lengthContainer}>
							{profileDescriptionLength.toLocaleString("fr")}/
							{EDITOR_MAX_LENGTH.toLocaleString("fr")}
						</div>
					)}
				</div>
			</CreateVacancyFieldContainer>
			{showEnhanceTextDialog && (
				<AddVacancyEnhanceTextDialog
					field={
						field === JOB_DESCRIPTION_FIELDS.title
							? "job title"
							: field === JOB_DESCRIPTION_FIELDS.jobDescription
							? "job description"
							: "profile description"
					}
					onGenerate={values => {
						setFormValues(values);
						setShowEnhanceTextDialog(false);
						setIsEnhancingTextLoading(true);
					}}
					onCancel={() => setShowEnhanceTextDialog(false)}
					isLoading={isLoading}
					placeholder={
						field === JOB_DESCRIPTION_FIELDS.title
							? "Please type your job title's prompt details here"
							: field === JOB_DESCRIPTION_FIELDS.jobDescription
							? "Please type your job description's prompt details here"
							: "Please type your profile description's prompt details here"
					}
				/>
			)}
		</div>
	);
};

export default forwardRef(AddVacancyDescriptionForm);
