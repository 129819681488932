/* eslint-disable react/display-name */

import cx from "classnames";
import React, { forwardRef } from "react";
import iconDisabled from "static/icons/arrow-down-grey.svg";
import icon from "static/icons/chevron-down-16px.svg";

import styles from "./drop-down-input.module.scss";

const DropDownInput = forwardRef(
	(
		{
			value,
			placeHolder = "Select value...",
			arrowDirection = "down",
			variant,
			onArrowClick,
			content,
			isDisabled,
			showAddBtn,
			className,
			chevron,
			onValueMouseOver = () => null,
			onValueMouseLeave = () => null,
			triggerIcon,
			inputIcon,
			...propsRest
		},
		ref
	) => {
		const _content = value || content || placeHolder;
		const iconClassName = styles.iconDown;

		const img = isDisabled ? iconDisabled : icon;

		return (
			<div
				className={cx(styles.root, className, {
					[styles.disabled]: isDisabled,
					[styles.inlineStyle]: showAddBtn,
					[styles.content]: !!value,
					[styles.open]: arrowDirection === "down",
					[styles.slim]: variant === "slim"
				})}
				{...propsRest}
				ref={ref}
				tabIndex="0"
			>
				{inputIcon}

				{showAddBtn ? (
					_content !== placeHolder && (
						<div
							onMouseOver={onValueMouseOver}
							onMouseLeave={onValueMouseLeave}
							className={styles.inlineStyle}
						>
							{_content}
						</div>
					)
				) : (
					<div
						onMouseOver={onValueMouseOver}
						onMouseLeave={onValueMouseLeave}
						className={styles.value}
					>
						{_content}
					</div>
				)}
				{!showAddBtn &&
					(chevron || (
						<div onClick={onArrowClick} className={styles.iconWrapper}>
							{triggerIcon || (
								<img src={img} alt={"arrow"} className={iconClassName} />
							)}
						</div>
					))}
			</div>
		);
	}
);

export default DropDownInput;
