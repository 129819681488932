import React from "react";
import { Flex } from "rebass";

const Toaster = ({
	icon,
	children,
	iconBoxBackground,
	textBoxBackground,
	toasterBackground,
	padding,
	border,
	height = "54px"
}) => {
	return (
		<Flex
			backgroundColor={toasterBackground}
			height={height}
			alignItems="center"
			sx={{
				borderRadius: 6,
				border
			}}
		>
			<Flex
				alignItems="center"
				sx={{
					background: iconBoxBackground || "transparent",
					padding: padding,
					height: "100%",
					borderRadius: "6px 0px 0px 6px"
				}}
			>
				{icon}
			</Flex>
			<Flex
				alignItems="center"
				sx={{
					background: textBoxBackground || "transparent",
					padding: padding,
					height: "100%",
					borderRadius: "0px 6px 6px 0px"
				}}
			>
				{children}
			</Flex>
		</Flex>
	);
};

export default Toaster;
