import React from "react";
import styles from "./open-virtual-room-copy-button.module.scss";
import { ReactComponent as CopyLink } from "static/icons/copy-link.svg";
import { Button } from "common/Button";
Button;

const OpenVirtualRoomCopyButton = ({ onCopy, ...rest }) => {
	return (
		<Button
			{...rest}
			className={styles.copyButton}
			variant="outlined"
			rightIcon={<CopyLink />}
			text="Copy link"
			onClick={onCopy}
		/>
	);
};

export default OpenVirtualRoomCopyButton;
