import React from "react";
import { Label, Radio } from "@rebass/forms";
import styles from "./questionnaire-form-radio-button-group.module.scss";
import { QuestionnaireFormLabel } from "../QuestionnaireFormLabel";
import { ReactComponent as RadioButtonIcon } from "static/icons/radio-button.svg";
import { ReactComponent as RadioButtonCheckIcon } from "static/icons/radio-button-checked.svg";

export default function QuestionnaireFormRadioButtonGroup({
	options,
	field,
	description,
	onChange,
	value: fieldValue,
	isReadOnly = false,
	is_required: isRequired,
	className
}) {
	const labelStyles = {
		color: "#334155",
		fontVariantNumeric: "lining-nums tabular-nums",
		fontFamily: "Work Sans",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "400",
		lineHeight: "150%",
		cursor: isReadOnly ? "default" : "pointer",
		display: "flex",
		alignItems: "center",
		gap: "8px"
	};
	return (
		<div className={styles.container}>
			<QuestionnaireFormLabel
				isRequired={isRequired}
				description={description}
				className={className}
			/>
			<div className={styles.radioContainer}>
				{options.map(option => {
					const isOptionValid = Array.isArray(fieldValue)
						? option === fieldValue?.[0]
						: option === fieldValue;
					const color = isOptionValid ? "#00857a" : "#cbd5e0";
					return (
						<Label key={`${field}_${option}_radio`} sx={labelStyles}>
							{isReadOnly ? (
								isOptionValid ? (
									<RadioButtonCheckIcon />
								) : (
									<RadioButtonIcon />
								)
							) : (
								<Radio
									sx={{
										color: color,
										backgroundColor: "white",
										cursor: isReadOnly ? "default" : "pointer"
									}}
									name={field}
									value={option}
									checked={
										Array.isArray(fieldValue)
											? option === fieldValue?.[0]
											: option === fieldValue
									}
									onChange={onChange}
									disabled={isReadOnly}
								/>
							)}
							{option}
						</Label>
					);
				})}
			</div>
		</div>
	);
}
