import React from "react";
import _get from "lodash/get";
import styles from "./job-sidebar.module.scss";
import {
	HYBRID_OPTION,
	REMOTE_ELIGIBITY_OPTIONS,
	PART_TIME_LABEL,
	FULL_TIME_LABEL,
	CONSULTANCY,
	CONTRACT_TYPE
} from "config";
import { formatDate } from "common/Functions";
import CompleteProfile from "../CompleteProfile/CompleteProfile";

const Sidebar = ({ job }) => {
	let remoteEligibilityValue;
	if (job?.remote_eligibility) {
		const {
			remote_eligibility: { office_days, type }
		} = job;

		if (office_days && type.includes(HYBRID_OPTION.value)) {
			remoteEligibilityValue = `Hybrid (${office_days} days in office/week)`;
		} else {
			const { label } = REMOTE_ELIGIBITY_OPTIONS.find(
				({ value }) => value === type
			);
			remoteEligibilityValue = label;
		}
	}

	const isConsultancy = job?.account_type === CONSULTANCY;
	const contractType = CONTRACT_TYPE.map(contract => {
		if (contract.value === job?.contract_type) {
			return contract.label;
		}
	});
	return (
		<div className={styles.sidebarDescription}>
			{!isConsultancy && !job?.profile_completed && <CompleteProfile />}
			{/* Company name  */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>company name</h6>
				<span>{_get(job, "site.name")}</span>
			</div>

			{/* Remote eligibility  */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>remote Eligibility</h6>
				<span>{remoteEligibilityValue}</span>
			</div>

			{/* contract type  */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>Contract Type</h6>
				<span>{contractType}</span>
			</div>

			{/* START - END DATE  */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>Start - End Date</h6>
				<span>
					{formatDate(_get(job, "start_date"))} -{" "}
					{formatDate(_get(job, "end_date"))}
				</span>
			</div>

			{/* TIME COMMITMENT  */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>Time Commitment</h6>
				<span className={styles.timeCommit}>
					{job?.full_time ? FULL_TIME_LABEL : PART_TIME_LABEL}
				</span>
			</div>
		</div>
	);
};

export default Sidebar;
