import React, {
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState
} from "react";
import Editor from "common/Editor";
import useGetUserEmail from "common/MessagingToolNewEmail/api/useGetUserEmail";
import { TO, BCC, CC } from "common/MessagingToolNewEmail/utils/constant";
import { filterAlreadySelectedParticipants } from "common/MessagingToolNewEmail/utils/helpers";
import MessagingToolSearchDropdown from "common/MessagingToolSearchDropdown/MessagingToolSearchDropdown";
import { bytesToSize } from "config/helpers";
import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as CheckIcon } from "static/icons/referral/check-circle.svg";
import { ReactComponent as FileIcon } from "static/icons/referral/file.svg";
import { ReactComponent as TrashIcon } from "static/icons/referral/trash.svg";
import { ReactComponent as RightIndentIcon } from "static/icons/right-indent-02.svg";
import { ReactComponent as DoubleChevronIcon } from "static/icons/email-template/double-chevron-right.svg";
import { ReactComponent as SaveIcon } from "static/icons/email-template/save.svg";
import NewEmailAutoCompleteInput from "../NewEmailAutoCompleteInput/NewEmailAutoCompleteInput";
import NewEmailUserTag from "../NewEmailUserTag/newEmailUserTag";
import styles from "./messaging-tool-new-email-body.module.scss";
import { createPortal } from "react-dom";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import cx from "classnames";
import { useGetPlaceholders } from "common/MessagingToolNewEmail/api/useGetPlaceholders";
import { INSERT_EMAIL_PLACEHOLDER_COMMAND } from "common/Editor/plugin/EmailPlaceholderPlugin";
import {
	EmailPlaceholderNode,
	MAIL_BOX_VIEW
} from "common/Editor/nodes/EmailPlaceholderNode";
import MessagingToolNewEmailPlaceholders from "common/Editor/plugin/EmailPlaceholderPlugin/components/MessagingToolNewEmailPlaceholders";
import loadable from "loadable-components";
import { CustomInput } from "common/CustomInput";
import { INSERT_EMAIL_SIGNATURE_COMMAND } from "common/Editor/plugin/EmailSignaturePlugin";
import { PlaceholderLinkNode } from "common/Editor/nodes/PlaceholderLinkNode";
import MessagingToolNewEmailSkeleton from "../MessagingToolNewEmailTemplateSkeleton/MessagingToolNewEmailSkeleton";
import { TOUR_MAILBOX } from "config/onboarding";

const MessagingToolSaveTemplateModal = loadable(() =>
	import("../MessagingToolSaveTemplateModal/MessagingToolSaveTemplateModal")
);

const MessagingToolNewEmailBody = ({
	setFiles,
	files = [],
	setSecondPortal,
	isActive,
	secondPortal,
	placeholdersRef,
	activeTemplate,
	isConversation = false,
	isExpanded,
	isEditorSmall = false,
	onFocusEditorBody,
	useEmailSignaturePlugin = false,
	emailSignature,
	path,
	totalItem,
	bodyClassName,
	readOnly = false
}) => {
	const {
		control,
		setValue,
		getValues,
		resetField,
		watch,
		formState: { dirtyFields }
	} = useFormContext();
	const canSaveTemplate =
		(dirtyFields.content || dirtyFields.subject) &&
		watch("content") &&
		watch("subject");
	const autocompleteInputRef = useRef({});
	const contentEditorRef = useRef();
	const editorRef = useRef();
	const [autocompleteKeyword, setAutocompleteKeyword] = useState("");
	const [activeInput, setActiveInput] = useState("");
	const [showToolbaar, setShowToolbar] = useState(false);
	const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
	const { data, isLoading } = useGetUserEmail({
		filter: "autocomplete_participants",
		limit: 5,
		keyword: autocompleteKeyword
	});
	const { data: placeholdersSuggestions } = useGetPlaceholders({
		filter: "placeholders_suggestions"
	});
	const {
		data: placeholders,
		isLoading: isPlaceholdersLoading
	} = useGetPlaceholders({ filter: "placeholders" });

	const filteredPlaceholders = useMemo(() => {
		if (placeholders?.placeholders) {
			const normalPlaceholders = {};
			const linkPlaceholders = {};
			Object.entries(placeholders?.placeholders).forEach(
				([entity, placeholders]) => {
					Object.entries(placeholders).forEach(([key, value]) => {
						if (key === "link") {
							linkPlaceholders[entity] ??= {};
							linkPlaceholders[entity][key] = value;
						} else {
							normalPlaceholders[entity] ??= {};
							normalPlaceholders[entity][key] = value;
						}
					});
				}
			);
			return {
				normalPlaceholders,
				linkPlaceholders
			};
		}
	}, [placeholders]);

	PlaceholderLinkNode.__placeholders = filteredPlaceholders?.linkPlaceholders;
	EmailPlaceholderNode.__placeholders =
		filteredPlaceholders?.normalPlaceholders;
	EmailPlaceholderNode.__view = MAIL_BOX_VIEW;

	const [isCcAdded, setIsCcAdded] = useState(false);
	const [isBccAdded, setIsBccAdded] = useState(false);

	const toField = watch(TO);

	const participants = useMemo(
		() => filterAlreadySelectedParticipants(data?.participants, toField),
		[data, toField]
	);

	useLayoutEffect(() => {
		if (files.length > 0) {
			const divElement = contentEditorRef.current;
			if (divElement) divElement.scrollTop = divElement.scrollHeight;
		}
	}, [files]);

	const onSelect = (name, value) => {
		const prevValues = getValues(name);
		let options = [];

		if (prevValues) {
			options = [...prevValues, value];
		} else options = [value];
		setValue(name, options, {
			shouldValidate: true
		});
		setAutocompleteKeyword("");

		autocompleteInputRef.current[name].focus();
		autocompleteInputRef.current[name].value = "";
	};

	const onDelete = (name, email) => {
		let filteredEmails = getValues(name).filter(item => item.email !== email);

		setValue(name, filteredEmails);
	};

	const onRemoveFile = id => {
		setFiles(files => files.filter(f => f.id !== id));
	};
	const renderPlaceholdersPortal = () => {
		if (isActive && secondPortal === "placeholders" && placeholdersRef?.current)
			return createPortal(
				<>
					<div className={styles.header}>
						<span className={styles.title}>{secondPortal}</span>
						<button
							className={styles.headerButton}
							type="button"
							onClick={() => setSecondPortal("")}
						>
							<CloseIcon className={styles.icon} />
						</button>
					</div>
					<MessagingToolNewEmailPlaceholders
						editor={editorRef?.current}
						placeholders={filteredPlaceholders?.normalPlaceholders}
						isLoading={isPlaceholdersLoading}
						containerClassName={isExpanded ? styles.placeholdersContainer : ""}
					/>
				</>,
				placeholdersRef.current
			);

		return null;
	};
	const renderEmailSignature = useCallback(
		({ editor }) => {
			if (emailSignature) {
				editor.dispatchCommand(INSERT_EMAIL_SIGNATURE_COMMAND, emailSignature);
				requestAnimationFrame(() => {
					resetField("content");
				});
			}
			return null;
		},
		[emailSignature]
	);

	const onEmailPlaceholdersChange = useCallback(list => {
		const placeholders = list.map(placeholderNode => {
			const placeholder = getValues("content_placeholders").find(
				placeholder => placeholder.entity === placeholderNode.entity
			);
			return {
				...placeholderNode,
				label: placeholder?.label,
				readOnly: placeholder?.readOnly,
				_id: placeholder?._id
			};
		});

		setValue("content_placeholders", placeholders);
	}, []);

	const onChangeAutocompleteKeyword = value => {
		setAutocompleteKeyword(value);
	};

	return (
		<div
			className={cx(styles.container, bodyClassName, {
				[styles.isExpanded]: isExpanded,
				[styles.small]: isEditorSmall
			})}
			onFocus={onFocusEditorBody}
		>
			<div className={cx(styles.field, styles.autoCompleteField)}>
				<label className={styles.fieldLabel}>
					To:
					<Controller
						control={control}
						name={TO}
						render={({ field: { value } }) => (
							<NewEmailAutoCompleteInput
								ref={ref => (autocompleteInputRef.current[TO] = ref)}
								setAutocompleteKeyword={onChangeAutocompleteKeyword}
								onFocus={() => setActiveInput(TO)}
								disableInput={readOnly}
							>
								{value?.map(item => (
									<NewEmailUserTag
										key={item.email}
										item={item.email}
										name={TO}
										onDelete={onDelete}
										readOnly={readOnly}
									/>
								))}
								{!!totalItem && (
									<NewEmailUserTag
										key={totalItem}
										item={`+ ${totalItem}`}
										name={TO}
										readOnly={readOnly}
									/>
								)}
							</NewEmailAutoCompleteInput>
						)}
					/>
					{!readOnly && !isCcAdded && !isBccAdded && (
						<div className={styles.ccButtons}>
							{!isCcAdded && (
								<button
									className={styles.cta}
									type="button"
									onClick={event => {
										event.preventDefault();
										setIsCcAdded(true);
									}}
								>
									Cc
								</button>
							)}
							{!isBccAdded && (
								<button
									className={styles.cta}
									type="button"
									onClick={event => {
										event.preventDefault();
										setIsBccAdded(true);
									}}
								>
									Bcc
								</button>
							)}
						</div>
					)}
					{activeInput === TO && autocompleteKeyword.length !== 0 && (
						<MessagingToolSearchDropdown
							setActiveInput={setActiveInput}
							data={participants}
							isLoading={isLoading}
							className={styles.searchDropdown}
							onSelect={user => onSelect(TO, user)}
						/>
					)}
				</label>
			</div>
			{isCcAdded && (
				<div className={cx(styles.field, styles.autoCompleteField)}>
					<label className={styles.fieldLabel}>
						Cc:
						<Controller
							control={control}
							name={CC}
							render={({ field: { value } }) => (
								<NewEmailAutoCompleteInput
									ref={ref => (autocompleteInputRef.current[CC] = ref)}
									setAutocompleteKeyword={onChangeAutocompleteKeyword}
									onFocus={() => setActiveInput(CC)}
								>
									{value?.map(item => (
										<NewEmailUserTag
											key={item.email}
											item={item.email}
											name={CC}
											onDelete={onDelete}
										/>
									))}
								</NewEmailAutoCompleteInput>
							)}
						/>
						<div className={styles.ccButtons}>
							{!isCcAdded && (
								<button
									className={styles.cta}
									type="button"
									onClick={event => {
										event.preventDefault();
										setIsCcAdded(true);
									}}
								>
									Cc
								</button>
							)}
							{!isBccAdded && (
								<button
									className={styles.cta}
									type="button"
									onClick={event => {
										event.preventDefault();
										setIsBccAdded(true);
									}}
								>
									Bcc
								</button>
							)}
						</div>
						{activeInput === CC && autocompleteKeyword.length !== 0 && (
							<MessagingToolSearchDropdown
								setActiveInput={setActiveInput}
								data={participants}
								isLoading={isLoading}
								className={styles.searchDropdown}
								onSelect={user => onSelect(CC, user)}
							/>
						)}
					</label>
				</div>
			)}
			{isBccAdded && (
				<div className={cx(styles.field, styles.autoCompleteField)}>
					<label className={styles.fieldLabel}>
						Bcc:
						<Controller
							control={control}
							name={BCC}
							render={({ field: { value } }) => (
								<NewEmailAutoCompleteInput
									ref={ref => (autocompleteInputRef.current[BCC] = ref)}
									setAutocompleteKeyword={onChangeAutocompleteKeyword}
									onFocus={() => setActiveInput(BCC)}
									disableInput={readOnly}
								>
									{value?.map(item => (
										<NewEmailUserTag
											key={item.email}
											item={item.email}
											name={BCC}
											onDelete={onDelete}
										/>
									))}
								</NewEmailAutoCompleteInput>
							)}
						/>
						<div className={styles.ccButtons}>
							{!isCcAdded && (
								<button
									className={styles.cta}
									type="button"
									onClick={event => {
										event.preventDefault();
										setIsCcAdded(true);
									}}
								>
									Cc
								</button>
							)}
							{!isBccAdded && (
								<button
									className={styles.cta}
									type="button"
									onClick={event => {
										event.preventDefault();
										setIsBccAdded(true);
									}}
								>
									Bcc
								</button>
							)}
						</div>
						{activeInput === BCC && autocompleteKeyword.length !== 0 && (
							<MessagingToolSearchDropdown
								setActiveInput={setActiveInput}
								data={participants}
								isLoading={isLoading}
								className={styles.searchDropdown}
								onSelect={user => onSelect(BCC, user)}
							/>
						)}
					</label>
				</div>
			)}

			{!isConversation && (
				<div className={styles.field}>
					<div className={styles.fieldLabel}>
						<span>Subject: </span>
						<Controller
							name={"subject"}
							control={control}
							render={({ field: { onChange, value } }) => {
								return (
									<CustomInput
										placeHolder="Enter subject..."
										onChange={onChange}
										value={value}
										className={styles.subjectInput}
									/>
								);
							}}
						/>
					</div>
				</div>
			)}
			<div className={styles.placeholders}>
				{!isConversation && canSaveTemplate && (
					<button
						className={styles.saveTemplate}
						onClick={() => setShowSaveTemplateModal(true)}
						type="button"
					>
						<SaveIcon className={styles.icon} />
						Save template
					</button>
				)}
				<div
					data-onboarding-step={`${TOUR_MAILBOX}-4`}
					className={cx(styles.templates, {
						[styles.active]: secondPortal === "templates"
					})}
					role="button"
					onClick={() => {
						onFocusEditorBody?.();
						setSecondPortal("templates");
					}}
				>
					<RightIndentIcon />
					Templates
					<DoubleChevronIcon
						className={cx({
							[styles.active]: secondPortal === "templates"
						})}
					/>
				</div>
				<div
					data-onboarding-step={`${TOUR_MAILBOX}-5`}
					className={cx(styles.templates, {
						[styles.active]: secondPortal === "placeholders"
					})}
					role="button"
					onClick={() => {
						onFocusEditorBody?.();
						setSecondPortal("placeholders");
					}}
				>
					<span className={styles.label}>Placeholders</span>
					<DoubleChevronIcon
						className={cx({
							[styles.active]: secondPortal === "placeholders"
						})}
					/>
				</div>
			</div>
			<div className={styles.editor} ref={contentEditorRef}>
				<Controller
					control={control}
					name="content"
					render={({ field: { value, onChange } }) => {
						return isPlaceholdersLoading ? (
							<MessagingToolNewEmailSkeleton />
						) : (
							<Editor
								editorRef={editorRef}
								key={activeTemplate?._id}
								value={value}
								onChange={onChange}
								useMentionPlugin={false}
								showToolbaar={showToolbaar}
								setShowToolbar={setShowToolbar}
								scrollClassName={styles.editorInput}
								placeholder="Start typing your message..."
								contentEditorClassName={styles.editorContent}
								editorContainerClassName={styles.editorContainerClassName}
								useEmailPlaceholderPlugin={true}
								useEmailSignaturePlugin={useEmailSignaturePlugin}
								renderEmailSignature={renderEmailSignature}
								editorId={"content_placeholders"}
								editorPath={path}
								onEmailPlaceholdersChange={onEmailPlaceholdersChange}
							/>
						);
					}}
				/>

				<div className={styles.attachments}>
					{files.map(({ id, file: { name, size }, progress }) => {
						return (
							<div key={id} className={styles.previewContainer}>
								<div className={styles.fileInfos}>
									<div className={styles.iconWrapper}>
										<FileIcon />
									</div>
									<div className={styles.name}>{name}</div>
									<div>{bytesToSize(size)}</div>
								</div>
								<div className={styles.fileStatus}>
									{progress < 100 ? (
										<div className={styles.progress}>
											<div className={styles.bar}>
												<div style={{ width: `${progress}%` }}></div>
											</div>
											<div className={styles.percentage}>{progress}%</div>
										</div>
									) : (
										<div className={styles.completed}>
											<CheckIcon />
											<span
												onClick={() => onRemoveFile(id)}
												role="button"
												className={styles.deleteIcon}
											>
												<TrashIcon />
											</span>
										</div>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{Object.keys(placeholdersSuggestions?.placeholders || {}).length !==
				0 && (
				<div className={styles.placeholdersSuggestions}>
					<span className={styles.label}>Suggestions: </span>
					<div className={styles.list}>
						{Object.entries(placeholdersSuggestions.placeholders || {}).map(
							([key, value]) => {
								return Object.entries(value).map(
									([attribute, value]) =>
										attribute !== "link" && (
											<button
												key={attribute}
												className={styles.placeholderSuggestion}
												type="button"
												onClick={() =>
													editorRef.current.dispatchCommand(
														INSERT_EMAIL_PLACEHOLDER_COMMAND,
														value
													)
												}
											>{`[${key}.${attribute}]`}</button>
										)
								);
							}
						)}
					</div>
				</div>
			)}
			{showSaveTemplateModal && (
				<MessagingToolSaveTemplateModal
					onClose={() => setShowSaveTemplateModal(false)}
				/>
			)}
			{renderPlaceholdersPortal()}
		</div>
	);
};

export default MessagingToolNewEmailBody;
