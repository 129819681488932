import React from "react";
import loadable from "loadable-components";
import styles from "./mission-list-table-cell.module.scss";
import EmptyStateCell from "common/AgTable/components/RenderCellsGlobal/EmptyStateCell/EmptyStateCell";
import { shouldDisplayFreelancerCost } from "config/helpers";

const CurrencyFormatterRender = loadable(() =>
	import("common/CurrencyFormatterRender")
);

const GenericCell = loadable(() =>
	import("common/AgTable/components/RenderCellsGlobal/GenericCell/GenericCell")
);

const MissionListStatusCell = loadable(() =>
	import("../MissionListStatusCell/MissionListStatusCell")
);

const MissionListProgressScore = loadable(() =>
	import("../MissionListProgressScore/MissionListProgressScore")
);

const MissionsListTableCell = props => {
	const isVmsFee = shouldDisplayFreelancerCost(props?.data?.item);
	const cells = {
		_id: <GenericCell data={props} isNumber />,
		status: <MissionListStatusCell data={props} />,
		progress: <MissionListProgressScore data={props} />,
		rate: (
			<CurrencyFormatterRender
				isCent
				value={props.data.item.price}
				currency={props.data.item?.reporting_settings?.currency?.code}
				className={styles.currency}
			/>
		),
		cost: isVmsFee ? (
			<CurrencyFormatterRender
				isCent
				value={props.data.item.cost}
				currency={props.data.item?.reporting_settings?.currency?.code}
				className={styles.currency}
			/>
		) : (
			"-"
		),
		selling_price: props.data.item?.invoice_details?.selling_price ? (
			<CurrencyFormatterRender
				isCent
				value={props.data.item?.invoice_details?.selling_price}
				currency={props.data.item?.reporting_settings?.currency?.code}
				className={styles.currency}
			/>
		) : (
			<EmptyStateCell />
		)
	};
	return cells[`${props.column.colId}`] ?? <GenericCell data={props} />;
};

export default MissionsListTableCell;
