import produce from "immer";
import { useReducer, useEffect, useCallback } from "react";
import { RESEND_ACTIVATION_EMAIL_LIMIT } from "config";

const initialState = {
	selectedRows: [],
	limit: RESEND_ACTIVATION_EMAIL_LIMIT, // -1 = no limit,
	totalItemsCount: -1,
	isSelectAll: false,
	method: "included",
	mode: ""
};

const TOGGLE_ALL = "TOGGLE_ALL";
const TOGGLE_PAGE = "TOGGLE_PAGE";
const TOGGLE_PACK = "TOGGLE_PACK";
const TOGGLE_ROW = "TOGGLE_ROW";
const SET_TOTAL_ITEMS_COUNT = "SET_TOTAL_ITEMS_COUNT";
const RESET_ALL = "RESET_ALL";

const selectionReducer = produce((draft, action) => {
	const isNoLimit = draft.limit === -1;
	const updateSelectAll = draft => {
		draft.isSelectAll = isNoLimit
			? draft.selectedRows.includes("all") ||
			  draft.totalItemsCount === draft.selectedRows.length
			: draft.totalItemsCount === draft.selectedRows.length;
	};
	switch (action.type) {
		case TOGGLE_PAGE: {
			draft.selectedRows = [
				...new Set([...draft.selectedRows, ...action.rows])
			];
			return;
		}
		case TOGGLE_ALL:
			if (draft.isSelectAll) {
				draft.selectedRows = [];
				draft.isSelectAll = false;
				draft.method = "included";
			} else {
				if (isNoLimit) {
					draft.selectedRows = ["all"];
					draft.method = "excluded";
				} else {
					draft.selectedRows = JSON.parse(JSON.stringify(action.rows)).slice(
						0,
						draft.limit
					);
					draft.mode = "select";
				}
				updateSelectAll(draft);
				draft.isSelectAll = true;
			}
			return;
		case TOGGLE_PACK: {
			draft.mode = "pack";
			draft.totalItemsCount = action.totalPack;
			return;
		}

		case TOGGLE_ROW: {
			const rowExists = draft.selectedRows.indexOf(action.row) !== -1;
			if (
				!rowExists &&
				!isNoLimit &&
				draft.selectedRows.length >= draft.limit
			) {
				return;
			}
			if (draft.isSelectAll) {
				draft.isSelectAll = false;
				if (isNoLimit) {
					draft.selectedRows = [];
				}
			}
			if (rowExists) {
				draft.selectedRows = draft.selectedRows.filter(r => r !== action.row);
				if (
					isNoLimit &&
					draft.method === "excluded" &&
					draft.selectedRows.length === 0
				) {
					draft.selectedRows = ["all"];
					draft.isSelectAll = true;
				}
			} else {
				draft.selectedRows.push(action.row);
			}
			if (draft.selectedRows.length >= draft.limit && draft.limit !== -1) {
				draft.isSelectAll = true;
				draft.method = "included";
			}
			return;
		}
		case SET_TOTAL_ITEMS_COUNT:
			draft.totalItemsCount = action.count;
			draft.limit = action.limit;
			updateSelectAll(draft);
			return;
		case RESET_ALL: {
			draft.selectedRows = [];
			draft.mode = "";
			draft.isSelectAll = false;
			return;
		}
		default:
			return draft;
	}
});

export default function useRowSelection(defaultProps = {}) {
	const [state, dispatch] = useReducer(selectionReducer, {
		...initialState,
		...defaultProps
	});
	useEffect(() => {
		dispatch({
			type: SET_TOTAL_ITEMS_COUNT,
			count: defaultProps.totalItemsCount
		});
	}, [defaultProps.totalItemsCount]);

	if (
		defaultProps.limit === -1 &&
		typeof defaultProps.totalItemsCount !== "number"
	) {
		throw new Error(
			"You've set the selection limit to 'no limit' but forgot to specify the 'totalItemsCount'"
		);
	}

	const toggleRowSelection = useCallback(row => {
		dispatch({ type: TOGGLE_ROW, row });
	}, []);

	const toggleAllSelection = useCallback(rows => {
		dispatch({ type: TOGGLE_ALL, rows });
	}, []);
	const togglePageSelection = rows => {
		dispatch({ type: TOGGLE_PAGE, rows });
	};
	const togglePack = useCallback(totalPack => {
		dispatch({ type: TOGGLE_PACK, totalPack });
	}, []);
	const reset = useCallback(() => {
		dispatch({ type: RESET_ALL });
	}, []);
	const checkRowSelection = useCallback(
		(_id, index, _, excludedIds, includedIds) => {
			const isInSelection = state.selectedRows.indexOf(_id) !== -1;
			if (state.limit === -1) {
				if (state.selectedRows.includes("all")) {
					return true;
				}
				switch (state.method) {
					case "excluded":
						return !isInSelection;
					default:
						return isInSelection;
				}
			} else if (state.mode === "pack") {
				if (index < state.totalItemsCount && !excludedIds?.includes(_id)) {
					return true;
				} else if (
					index >= state.totalItemsCount &&
					includedIds?.includes(_id) &&
					excludedIds.length >= includedIds.length
				)
					return true;
				return false;
			}
			return isInSelection;
		},
		[state]
	);

	const getSelectionCount = useCallback(() => {
		if (state.limit === -1) {
			if (state.method === "excluded") {
				return state.isSelectAll
					? state.totalItemsCount
					: state.totalItemsCount - state.selectedRows.length;
			}
			return state.isSelectAll
				? state.totalItemsCount
				: state.selectedRows.length;
		}
		return state.selectedRows.length;
	}, [state]);

	return [
		state,
		{
			toggleAllSelection,
			toggleRowSelection,
			togglePageSelection,
			checkRowSelection,
			getSelectionCount,
			togglePack,
			reset
		}
	];
}
