import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { ENHANCE_SELECTED_TEXT_ENDPOINT } from "config/api-endpoints";

const enhanceSelectedTextSettings = async body => {
	return client(ENHANCE_SELECTED_TEXT_ENDPOINT, { body });
};

export const ENHANCE_SELECTED_TEXT_QUERY_KEY =
	"ENHANCE_SELECTED_TEXT_QUERY_KEY";

const useEnhanceSelectedText = (body, options = {}) => {
	return useQuery(
		[ENHANCE_SELECTED_TEXT_QUERY_KEY, body],
		() => enhanceSelectedTextSettings(body),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...options
		}
	);
};

export default useEnhanceSelectedText;
