import React from "react";
import { queryCache, useMutation } from "react-query";
import _get from "lodash/get";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//import { useDispatch } from "react-redux";
import * as yup from "yup";
import { ReactComponent as RejectionIcon } from "static/icons/rejection-icon.svg";
import Header from "./DrawerHeader";
import { AddNoteRendrer } from "common/AddNote";
import { TEXTAREA_MAX_LENGTH } from "config";
import { AddNoteContainer, CancelButton, ActionButton } from "./styles";
import { CANCEL_APPLICATION_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore/index.js";
import Loader from "common/Loader";
import Select from "common/react-hook-form-components/Select";
//import { fetchPoke } from "modules/vacancy/actions";
import toaster from "common/Toaster";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import { getPokeDataKey } from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";
import useGetCandidateRejection from "hooks/useCandidateRejection";

const reject_application = data =>
	client(CANCEL_APPLICATION_ENDPOINT, {
		body: {
			...data
		}
	});

export default function Rejection({
	job,
	poke,
	clearSelection,
	onSuccess,
	isFromVacanciesTable
}) {
	const { setDrawerState, drawerState } = useVacancyStore();
	const { data: candidateRejectionList } = useGetCandidateRejection();
	//const dispatch = useDispatch();

	let rejectionList = candidateRejectionList?.options?.map(({ value }) => ({
		label: value,
		value
	}));

	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false
	});
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();
	const [mutate, { isLoading }] = useMutation(reject_application, {
		onSuccess: () => {
			setDrawerState({ open: false });
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(_get(drawerState, "application_id[0]"));
			clearSelection?.();
			toaster.success("Application successfully cancelled");
			queryCache.invalidateQueries("RejectedList");
			queryCache.invalidateQueries("getDataPipeline");
			queryCache.invalidateQueries("getAllProcesses");
			onSuccess();
			if (_get(poke, "_id"))
				//return dispatch(fetchPoke({ id: _get(poke, "_id") }));
				return queryCache.invalidateQueries(getPokeDataKey);
		},
		onError: error => {
			let errorMessage = _get(error, "detail");
			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					toaster.danger(
						`${name} : ${
							typeof _get(errorMessage, name) === "string"
								? _get(errorMessage, name)
								: _get(errorMessage, name)[0]
						}`
					);
				});
			}
		}
	});

	const handleCancel = () => {
		if (isFromCardActions) {
			setDrawerState({ open: false });
		} else {
			setDrawerState({ open: false });
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(_get(drawerState, "application_id[0]"));
		}
	};

	const handleReject = handleSubmit(values => {
		mutate({
			application_ids: _get(drawerState, "application_id"),
			step_id: _get(drawerState, "step_id"),
			cancel_comments: _get(values, "comment.value", ""),
			reject_reason: _get(values, "reason.value", []),
			vacancy_id:
				_get(job, "_id", "") === ""
					? _get(drawerState, "job_id")
					: _get(job, "_id"),
			stage: _get(drawerState, "stage", ""),
			internal_comments: _get(values, "internal_comments.value", "")
		});
	});

	return (
		<AddNoteContainer isLoading={isLoading}>
			{isLoading && (
				<div className="loader-container">
					<Loader />
				</div>
			)}
			<Header icon={<RejectionIcon />} title="Rejection" onClose={handleCancel}>
				<CancelButton
					className="btn-cancel"
					margin="0 8px 0 0"
					onClick={handleCancel}
				>
					Cancel
				</CancelButton>

				<ActionButton className="btn-action" onClick={handleReject}>
					Reject
				</ActionButton>
			</Header>
			<div className="box">
				<h4 className="box-title">Reject application</h4>
				<span className="box-sub-title">
					{_get(drawerState, "application_id.length", 0) === 1
						? `Watch out! This action will remove the applicant from your pipeline. Proceed anyway?`
						: "Watch out! This action will remove all selected applicants from your pipeline. Proceed anyway?"}
				</span>
				<div className="note">
					<AddNoteRendrer>
						<Controller
							control={control}
							name="reason"
							render={({ field: { onChange, value, name } }) => (
								<Select
									label="Reason"
									onChange={onChange}
									value={value}
									options={rejectionList && rejectionList}
									name={name}
									placeholder="Choose reason "
									error={_get(errors, "reason")}
								/>
							)}
						/>

						<Controller
							control={control}
							name="internal_comments"
							render={({ field: { onChange, value, name } }) => (
								<AddNoteRendrer.MentionBox
									input={{
										value,
										onChange,
										name,
										placeholder:
											"Leave an internal note on this profile without notifying the candidate"
									}}
									label="Internal note"
									meta={{
										touched: Boolean(_get(errors, "comment.value.message", "")),
										error: _get(errors, "comment.value.message")
									}}
									maxLength={TEXTAREA_MAX_LENGTH}
								/>
							)}
						/>
						<Controller
							control={control}
							name="comment"
							render={({ field: { onChange, value, name } }) => (
								<AddNoteRendrer.MentionBox
									input={{
										value,
										onChange,
										name,
										placeholder:
											"Your feedback that will be sent to the candidate..."
									}}
									label="External note"
									meta={{
										touched: Boolean(_get(errors, "comment.value.message", "")),
										error: _get(errors, "comment.value.message")
									}}
									maxLength={TEXTAREA_MAX_LENGTH}
								/>
							)}
						/>
					</AddNoteRendrer>
				</div>
				<div className="actions">
					<ActionButton margin="0 0 10px 0" onClick={handleReject}>
						Reject
					</ActionButton>
					<CancelButton backgroundColor="white" onClick={handleCancel}>
						Cancel
					</CancelButton>
				</div>
			</div>
		</AddNoteContainer>
	);
}
const schema = yup.object().shape({
	reason: yup.object().required("Please choose a reason")
});
