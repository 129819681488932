import React, { memo, useCallback } from "react";
import ColumnsSelect from "../ColumnsSelect/ColumnsSelect";
import { useFilters } from "../hooks/useFilter";

const FilterColumn = ({ filter }) => {
	const { replaceFilter, getState, currentFilterId } = useFilters();
	const { columns } = getState(currentFilterId);
	const handleChange = useCallback(
		value => {
			const columnsReduced = columns.reduce(
				(acc, item) => [...acc, ...item.children],
				[]
			);

			const column = columnsReduced.find(option => option.name === value.name);

			const newFilter = {
				...filter,
				name: column.name,
				type: column.type,
				operator: column.operator.value,
				label: column.label,
				payload: column.payload,
				value: ""
			};

			replaceFilter(newFilter);
		},
		[replaceFilter, columns]
	);

	return (
		<ColumnsSelect menu={columns} value={filter} onChange={handleChange} />
	);
};
export default memo(FilterColumn);
