import React, { useState, useRef, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import get from "lodash/get";
import Fuse from "fuse.js";
import useOnClickOutside from "hooks/useOnClickOutside";
import { ProfileMenu, Label } from "./styles";
import { ReactComponent as ClearIcon } from "static/icons/rounded-clear-icon.svg";
import { ReactComponent as NoDataIcon } from "static/icons/danger-icon.svg";
import Loader from "common/Loader";
import PanelItems from "./PanelItems";
import GlobalTooltip from "common/GlobalTooltip";
import Avatar from "common/Avatar";
import classnames from "modules/candidate/Profile/Pages/Files/files.module.scss";

function CustomSelect({
	data = [],
	onChange,
	idKey = "_id",
	selectTitle = "Invite team members",
	open,
	setOpen,
	includedItems,
	setIncludedItems,
	isLoading = false,
	inputPlaceholder = "Search member..."
}) {
	//const [selectData, setSelectData] = useState(data);
	const [searchKeyword, setSearch] = useState("");
	const menuRef = useRef(null);
	const firstMounted = useRef(false);

	useOnClickOutside(menuRef, () => {
		setOpen(false);
		setSearch("");
	});

	useEffect(() => {
		if (firstMounted.current) {
			onChange && onChange(includedItems);
		}
		firstMounted.current = true;
	}, [includedItems, onChange]);

	const selectData = Boolean(searchKeyword)
		? new Fuse(data, {
				keys: ["users.first_name", "users.last_name"],
				threshold: 0.3,
				includeScore: false
		  })
				.search(searchKeyword)
				.map(elem => elem.item)
		: data;

	const renderAvatar = user => {
		<GlobalTooltip
			overlay={get(user, "user_name")}
			placement="top"
			trigger="hover"
		>
			<Avatar
				className={classnames.avatar}
				name={get(user, "user_name")}
				avatar={get(user, "avatar")}
				size={32}
			/>
		</GlobalTooltip>;
	};

	const handleSelect = elem => {
		if (includedItems.findIndex(item => item[idKey] === elem[idKey]) === -1)
			setIncludedItems(old => [...old, elem]);
		else
			setIncludedItems(old =>
				old.filter(item => !(item[idKey] === elem[idKey]))
			);
	};

	const handleSearch = ({ target }) => setSearch(target["value"]);
	const clearSearch = () => setSearch("");

	return (
		<ProfileMenu
			ref={menuRef}
			showMenu={open}
			isLoading={isLoading}
			className="select-menu"
		>
			{isLoading && (
				<div className="loader">
					<Loader />
				</div>
			)}
			<h4 className="title">{selectTitle}</h4>
			<div className="text-field">
				<input
					placeholder={inputPlaceholder}
					value={searchKeyword}
					onChange={handleSearch}
				/>
				<ClearIcon onClick={clearSearch} />
			</div>
			{get(selectData, "length", -1) > 0 ? (
				<Scrollbars
					autoHide
					autoHideTimeout={5000}
					autoHideDuration={200}
					autoHeight
					autoHeightMax={270}
				>
					<ul className="menu">
						{selectData.map((elem, index) => (
							<PanelItems
								key={index}
								elem={elem}
								handleSelect={handleSelect}
								renderAvatar={renderAvatar}
								includedItems={includedItems}
								idKey={idKey}
								searchKeyword={searchKeyword}
								data={data}
							/>
						))}
					</ul>
				</Scrollbars>
			) : (
				<div className="empty-state">
					<NoDataIcon />
					<Label margin="0 0 0 10px">No results to show</Label>
				</div>
			)}
		</ProfileMenu>
	);
}

export default CustomSelect;
