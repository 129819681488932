import React from "react";
import styles from "./bid-match-score.module.scss";
import { ReactComponent as LoadingGreenIcon } from "static/icons/loading-green-icon.svg";
import { ReactComponent as LoadingYellowIcon } from "static/icons/loading-yellow-icon.svg";
import { ReactComponent as LoadingRedIcon } from "static/icons/loading-red-icon.svg";
import Tooltip from "common/Tippy";

const firstLevel = 34;
const secondLevel = 67;

export default function BidMatchScore({ value }) {
	const getMatchScoreIcon =
		value < firstLevel ? (
			<LoadingRedIcon />
		) : value < secondLevel ? (
			<LoadingYellowIcon />
		) : (
			<LoadingGreenIcon />
		);

	return value || value == 0 ? (
		<Tooltip content="Matching score" theme="dark">
			<div className={styles.scoreContainer}>
				<>
					{getMatchScoreIcon} <span>{value}%</span>
				</>
			</div>
		</Tooltip>
	) : (
		<></>
	);
}
