import React from "react";
import styles from "./timesheets-list-total-count.module.scss";

const TimesheetsListTotalCount = ({ isLoading, isFetching, total }) => {
	return (
		<p className={styles.results_count}>
			{isLoading || isFetching ? (
				"Loading..."
			) : (
				<div>
					{total === 0 ? (
						"No result found"
					) : (
						<div>
							<span className={styles.greenText}>
								{total?.toLocaleString?.()?.replace?.(",", " ")}
							</span>
							&nbsp;{total > 1 ? "results" : "result"} in total
						</div>
					)}
				</div>
			)}
		</p>
	);
};

export default TimesheetsListTotalCount;
