import React, { useState } from "react";
import Select from "react-select";
import _get from "lodash/get";

import RangeFilter from "./Rangefilter";
import { Th } from "./Styled";

const Thead = ({
	column,
	filter,
	setFilter,
	offlineFilter,
	setOfflineFilter,
	setPage
}) => {
	const [displayFilter, setDisplayFilter] = useState(true);
	const [clicked, setClicked] = useState(false);

	const isStatus = column.Header === "STATUS";

	return (
		<>
			<Th
				{...column.getHeaderProps()}
				selectedFilter={!!filter}
				clicked={clicked}
				onClick={() => setClicked(!clicked)}
				className={column.className}
				isRange={column.rangeFilter}
				isStatus={isStatus}
			>
				<div className="head">
					{column.render("Header")}
					{(column.hasFilter || column.rangeFilter) && (
						<span
							className={`icon-down-arrow ${
								displayFilter ? "" : "hidden-filter"
							}`}
							onClick={e => {
								e.stopPropagation();
								return setDisplayFilter(!displayFilter);
							}}
						/>
					)}
				</div>
				{column.hasFilter && displayFilter && (
					<Select
						value={filter}
						options={column.filterOptions}
						clearable={true}
						searchable={true}
						onChange={selected => {
							setFilter(_get(selected, "value"));
							setPage && setPage(0);
						}}
						onBlur={() => {}}
						className={column.disabledFilter ? "disabled" : ""}
						placeholder={""}
						disabled={column.disabledFilter}
					/>
				)}
				{column.rangeFilter && (
					<RangeFilter
						value={filter ? filter : offlineFilter}
						onChange={value => {
							filter ? setFilter(value) : setOfflineFilter(value);
						}}
					/>
				)}
				<div
					{...column.getResizerProps()}
					className={`resizer ${column.isResizing ? "isResizing" : ""}`}
				/>
			</Th>
		</>
	);
};

export default Thead;
