import styled from "styled-components";
import { TabList, Tab, TabPanels } from "@reach/tabs";

export const StyledTabList = styled(TabList)`
	background: #ffffff !important;
	padding: 0 40px;
	margin: 17px 0 5px 25px;
`;

export const StyledTabPanels = styled(TabPanels)`
	margin-bottom: ${props => (props.noMargin ? "0px" : "8px")};
	.history-container {
		overflow: hidden;
	}
`;

export const StyledTab = styled(Tab)`
	padding: 5px 12px !important;
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #303131 !important;

	&[data-selected] {
		color: #ff6849 !important;
		background: #ffe9e8;
		border: 1px solid #fff4f4;
		border-bottom: none !important;
		box-sizing: border-box;
		border-radius: 4px;
	}
	&:focus {
		outline: none !important;
	}
	&:active {
		background: #ffffff !important;
	}
`;

export const CVContainer = styled.div`
	padding: 20px 0px 0px 20px;
	background-color: white;
	display: flex;
	flex-direction: column;
	.rcs-custom-scroll {
		background-color: #ffffff;
	}

	.display-cv {
		max-height: ${props => props.maxHeight || "1000"}px;
		width: 100%;
		margin: 0 auto;
	}
	.download-pdf {
		padding: 7px 12px;
		color: #2294e3;
		border: solid 1px #2595e3;
		background-color: rgba(34, 148, 227, 0.1);
		font-weight: 600;
		border-radius: 4px;
		margin-bottom: 20px;
		margin-right: 30px;
		align-self: flex-end;
		text-decoration: unset;
	}
`;

export const PreferencesContainer = styled.div`
	background-color: #ffffff;
	padding: 20px;
	margin-top: 4px;
	border-radius: 4px;
	.section {
		border-radius: 4px;
		box-shadow: 0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11);
		background-color: #ffffff;
		padding: 0 !important;
		margin-bottom: 12px;
		.title {
			font-size: 13px;
			font-weight: 600;
			line-height: 1.63;
			letter-spacing: 0.08px;
			text-align: left;
			color: #273238;
			border-bottom: solid 0.5px #dde3e8;
			padding: 15px 20px;
			display: flex;
			align-items: center;
			.icon-local {
				color: #1b9f94;
				margin-right: 6px;
			}
		}
		.section-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 15px 20px;
		}
		.content {
			display: flex;
			align-items: center;
			width: 100%;
			&:not(:last-of-type) {
				margin-bottom: 12px;
			}
		}
		.sub-title {
			line-height: 1.95;
			letter-spacing: 0.99px;
			text-align: left;
			color: #a0a5b9;
			text-transform: uppercase;
			font-size: 11px;
			font-weight: 600;
			width: 190px;
			margin-right: 160px;
		}
		.preference-data {
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: left;
			color: #0f1c46;
			display: flex;
			align-items: center;
			.advantages {
				color: #0f1c46;
				font-size: 14px;
				font-weight: normal;
				.g-tool {
					margin-left: 0 !important;
					font-size: 11px !important;
					span {
						font-size: 11px !important;
						color: white !important;
					}
				}
			}
		}
	}
`;

export const FunctionsTabContainer = styled.div`
	width: 100%;
	margin: 0 auto;
`;

export const DetailsContainer = styled.div`
	border-radius: 4px;
	box-shadow: 0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11);
	background-color: #ffffff;
	width: 100%;
	margin-top: 10px;
	.title {
		padding: 15px 20px;
		line-height: 1.63;
		letter-spacing: 0.08px;
		text-align: left;
		color: #273238;
		font-size: 13px;
		font-weight: 600;
		display: flex;
		justify-content: space-between;
		border-bottom: solid 0.5px #dde3e8;
		.status {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.application-content {
		padding: 15px 20px;
		.content-title {
			line-height: 1.63;
			letter-spacing: 0.08px;
			text-align: left;
			color: #273238;
			font-size: 13px;
			font-weight: 600;
			margin-bottom: 14px;
		}
		.application {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			height: 75px;
			.application-date {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-right: solid 1px #b0b9c6;
				padding-right: 20px;
				min-height: 75px;
				.month {
					color: #ff6849;
					letter-spacing: 0.86px;
					font-size: 9px;
					font-weight: 600;
					margin-bottom: 8px;
					text-transform: uppercase;
				}
				.day {
					font-size: 34px;
					letter-spacing: 0.15px;
					text-align: left;
					color: #273238;
					line-height: 0.73;
					font-weight: 400;
				}
			}
			.application-time {
				display: flex;
				flex-direction: column;
				justify-content: center;
				border-right: solid 1px #b0b9c6;
				padding: 0 20px;
				min-height: 75px;
				.time-infos {
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.5;
					letter-spacing: normal;
					text-align: left;
					color: #242f43;
					font-size: 13px;
					min-width: 190px;
				}
			}
			.application-description {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 0 20px;
				.description-title {
					font-weight: 600;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.79;
					letter-spacing: 1.08px;
					text-align: left;
					color: #a0a5b9;
				}
				.description-content {
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.79;
					letter-spacing: normal;
					text-align: left;
					color: #0f1c46;
				}
			}
		}
	}
`;

export const ItemListContainer = styled.div`
	border-radius: 2px;
	border: solid 1px #ccd7e6;
	padding: 10px 20px;
	background-color: white;
	width: 100%;
	margin-bottom: 12px;
	min-height: 96px;
	.g-tool {
		font-size: 12px;
		height: 12px;
		position: relative;
		top: -5px;
	}
	.list-title {
		color: #a0a5b9;
		line-height: 2.15;
		letter-spacing: 0.9px;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 600;
	}
	.section-title {
		margin-top: 10px;
	}

	.items {
		margin-top: 12px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.item {
		color: #5197f1;
		padding: 4px 7px;
		border-radius: 14.5px;
		border: solid 1px #5197f1;
		font-weight: bold;
		font-size: 12px;
		text-transform: uppercase;
		margin-right: 6px;
		margin-bottom: 4px;
		&.placeholder {
			color: #dfe0e2;
			border-color: #dfe0e2;
		}
	}
	.icon-edit-2 {
		float: right;
		cursor: pointer;
		font-size: 12px;
	}
`;
