import useLayoutElements from "hooks/useLayoutElements";

export const useScrollTop = () => {
	const { scrollarea } = useLayoutElements();

	const scrollToTop = () => {
		scrollarea.scrollTo({ top: 0, behavior: "smooth" });
	};

	return scrollToTop;
};
