import React from "react";
import s from "./empty-state.module.scss";
import { ReactComponent as NoDataIcon } from "static/icons/danger-icon.svg";

const EmptyState = () => {
	return (
		<div className={s.root}>
			<NoDataIcon />
			<span>No Results to show</span>
		</div>
	);
};

export default EmptyState;
