import React from "react";
import styles from "./loading-view.module.scss";
import { LIMIT } from "../../util/constants";
import LoadingItem from "../LoadingItem/LoadingItem";

const LoadingView = () => {
	return (
		<div className={styles.container}>
			{Array.from(Array(LIMIT).keys()).map((_, i) => {
				return <LoadingItem key={i} />;
			})}
		</div>
	);
};

export default LoadingView;
