import * as types from "../actions/types";
const initialState = {
	isFetching: false,
	sectors: [],
	subSectors: [],
	functions: [],
	filter: {
		title: "",
		category: null,
		subcategory: null,
		function: null,
		skills: [],
		seniority: [],
		location: { distance: null, lat: 0, lon: 0 },
		languages: [],
		offset: 0
	},
	minBudget: 0,
	maxBudget: 1000000,
	addedSkills: [],
	addedLanguages: [],
	data: [],
	job_detail: {
		skills: [],
		languages: []
	},
	offlinePopup: false
};

export default function searchProfileReducer(state = initialState, action) {
	switch (action.type) {
		case types.FETCHED_JOBS_VACANCY:
			return Object.assign({}, state, { ...action.data });
		case types.UPDATE_FILTER_VACANCY:
			return Object.assign({}, state, { filter: action.filter });
		case types.UPDATE_SKILLS_VACANCY:
			return Object.assign({}, state, {
				addedSkills: [...action.skills]
			});
		case types.UPDATE_LANGUAGES_VACANCY:
			return Object.assign({}, state, {
				addedLanguages: [...action.languages]
			});
		case types.REST_LANGUAGES_VACANCY:
			return Object.assign({}, state, { addedLanguages: [] });
		case types.REST_SKILLS_VACANCY:
			return Object.assign({}, state, { addedSkills: [] });
		case types.SET_SEARCH_SECTORS_VACANCY:
			return Object.assign({}, state, { sectors: action.data });
		case types.SET_SEARCH_SUBSECTORS_VACANCY:
			return Object.assign({}, state, { subSectors: action.subSectors });
		case types.SET_SEARCH_FUNCTIONS_VACANCY:
			return Object.assign({}, state, { functions: action.data });
		case types.SET_SEARCH_RESET_VACANCY:
			return Object.assign({}, state, initialState);
		default:
			return state;
	}
}
