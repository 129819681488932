import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import StyledSelect from "common/StyledSelect";
import { REQUEST_STATUS, MY_JOBS_STATUS } from "config";

class FilterByStatus extends Component {
	render() {
		const options = this.props.myJobs ? MY_JOBS_STATUS : REQUEST_STATUS;
		return (
			<Field
				name="job_status"
				options={options}
				simpleValue={true}
				position={false}
				placeholder={"Filter By Status"}
				component={StyledSelect}
				valueToSet={this.props.job_status}
				onChange={(e, value) => {
					this.props.filterList(value);
				}}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		job_status: state.job.list_data.filterByStatus
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "filterJobs",
		touchOnBlur: false
	})
)(FilterByStatus);
