import React, { useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { v4 as uuid } from "uuid";
import ProactiveInterview from "common/InterviewModal/ProactiveInterview";
import CancelPokeModal from "modules/vacancy/components/pokeView/client/CancelPokeModal";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import AxiosHelper from "config/axios-helper";
import { ADD_TALENT_PIPELINE } from "config/api-endpoints";
import { ActionsContainer } from "./Styled";
import {
	SENT,
	CONFIRMED,
	CANCELLED,
	PERMANENT,
	REJECTED,
	APPLIED,
	OFFER,
	CANCEL_INTERVIEW,
	REJECT_APPLICATION,
	HIRED,
	ADD_INTERVIEW,
	SHORTLISTED,
	APPLICATION
} from "config";
import PokeActions from "./PokeActions";
import { client } from "lib/api-client";
import { CANCEL_OFFER } from "config/api-endpoints";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore/index.js";
import toaster from "common/Toaster";
import RequalifyModal from "modules/vacancy-view/components/withdrawnRejected/requalify_rejected.js";
import useNotesStore from "modules/notes/notes.store";

const cancelOffer = ({ offer_id }) => {
	return client(CANCEL_OFFER, {
		body: {
			offer_id
		}
	});
};

const PokeSideBar = ({
	offer,
	status,
	// toggleProposeInterviewModal,
	interview = {},
	acceptInterview,
	poke_id,
	company_name,
	fullName,
	user,
	companyType,
	changeReduxForm,
	attendeesList,
	fetchAttendees,
	selectedVacancy,
	jobDetails,
	profile,
	fetchPoke,
	stepperStatus
}) => {
	const pokeActionsVisibility = [
		SENT,
		CONFIRMED,
		REJECTED,
		APPLIED,
		CANCELLED,
		HIRED,
		SHORTLISTED
	];
	const [displayAddToTalent, setDisplayAddToTalent] = useState(false);
	const [displayProposeInterview, setDisplayProposeInterview] = useState(false);
	const [displayCancelPokeModal, setDisplayCancelPokeModal] = useState(false);
	const [selectedItemReject, setSelectedItemReject] = useState("");
	const [cancelOfferModal, setCancelOfferModal] = useState(false);
	const [requalifyModal, setRequalifyModal] = useState(false);
	const { setDrawerState } = useVacancyStore();
	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));

	const dispatch = useDispatch();

	const [cancel_offer, { isLoading: cancelLoading }] = useMutation(
		cancelOffer,
		{
			onSuccess: () => {
				setCancelOfferModal(false);
				toaster.success(
					"the offer proposition has successfully been cancelled!"
				);
				dispatch(fetchPoke({ id: poke_id }));
			},
			onError: err => {
				if (Boolean(err?.detail?.name))
					return toaster.danger(err?.detail?.name);
			}
		}
	);

	const AddToTalentPipeline = () => {
		return AxiosHelper.post({
			url: ADD_TALENT_PIPELINE,
			data: {
				id: poke_id,
				account_type: "client",
				company_id: jobDetails.company._id,
				user_id: jobDetails.user_id,
				vacancy_id: jobDetails._id,
				profile_id: profile._id
			},
			isModal: true
		}).then(() => {
			setDisplayProposeInterview(false);
			setDisplayAddToTalent(false);
			fetchPoke({ id: poke_id });
		});
	};

	const sendPoke = values => {
		let payload;
		if (
			values &&
			values.interview &&
			_get(values.interview, "interview_start_date")
		) {
			payload = { ...values };
		} else {
			payload = {
				...values.interview
			};
		}
		return AxiosHelper.post({
			url: ADD_TALENT_PIPELINE,
			data: {
				id: poke_id,
				account_type: "client",
				company_id: jobDetails.company._id,
				user_id: jobDetails.user_id,
				vacancy_id: jobDetails._id,
				profile_id: profile._id,
				...payload
			},
			isModal: true,
			toastMessage: "Interview proposed!"
		}).then(() => {
			setDisplayAddToTalent(false);
			setDisplayProposeInterview(false);
			fetchPoke({ id: poke_id });
		});
	};

	const toggleModal = () => {
		setDisplayProposeInterview(!displayProposeInterview);
	};
	const actions = {
		proposeInterview: () =>
			setDrawerState({
				open: true,
				component: ADD_INTERVIEW,
				candidateName: fullName,
				candidateEmail: _get(profile, "headline.email", ""),
				companyType: PERMANENT,
				profileId: _get(profile, "_id", ""),
				isCandidateHasMail: Boolean(_get(profile, "headline.email", "")),
				applicationId: poke_id,
				isApplicantHasAnOffer: Boolean(Object.keys(interview).length),
				interviewId: interview._id,
				interviewStatus: _get(interview, "status", "")
			}),
		addNote: () => {
			const { _id, description } = jobDetails;

			//TODO Move this contant to config
			addNote({
				type: "VACANCY_NOTE",
				id: uuid(),
				canSelectCandidate: true,
				payload: {
					vacancy: { _id: _id, name: description },
					candidate: {
						value: profile._id,
						label: fullName,
						profile_id: profile._id
					},
					onSuccess: () => {
						fetchPoke({ id: poke_id });
					}
				}
			});
		},
		cancelPoke: () => {
			setDrawerState({
				open: true,
				component: REJECT_APPLICATION,
				application_id: [poke_id],
				candidateName: fullName
			});
		},
		cancelInterview: () =>
			setDrawerState({
				open: true,
				component: CANCEL_INTERVIEW,
				application_id: poke_id,
				candidateName: fullName
			}),
		acceptInterview: acceptInterview,
		cancelOffer: () => setCancelOfferModal(true),
		proposeAnotherOffer: () =>
			setDrawerState({
				open: true,
				component: OFFER,
				application_id: poke_id,
				candidateName: fullName,
				candidateEmail: _get(profile, "headline.email"),
				offer,
				profile_id: profile._id
			}),
		addToTalentPipeline: () => {
			setDisplayAddToTalent(true);
		},
		rejectApplication: () => {
			setDisplayCancelPokeModal(true);
			setSelectedItemReject(poke_id);
		},
		requalify: () => setRequalifyModal(true)
	};

	return (
		<>
			{pokeActionsVisibility.includes(status) && (
				<ActionsContainer>
					<PokeActions
						pokeStage={stepperStatus}
						pokeStatus={status}
						interviewStatus={_get(interview, "status")}
						offerStatus={_get(offer, "status")}
						canAccept={
							_get(interview, "last_user_interview_action.type") === PERMANENT
						}
						actions={actions}
					/>
				</ActionsContainer>
			)}
			<ConfirmationModal
				active={displayAddToTalent}
				modalName="add_to_talent"
				onClose={() => {
					setDisplayAddToTalent(false);
				}}
				type={ConfirmationTypes.confirmation}
				title={"Are you sure you want to add applicant to the talent pipeline"}
				content={
					"If so, wiggli will shift the applicant to the TALENT PIPELINE section with status “Interest Confirmed”"
				}
				firstButton={{
					action: () => {
						AddToTalentPipeline();
					},
					label: "Yes, add",
					type: "success"
				}}
			/>
			{displayProposeInterview && (
				<ProactiveInterview
					company_name={company_name}
					user={user}
					companyType={companyType}
					changeReduxForm={changeReduxForm}
					attendeesList={attendeesList}
					fetchAttendees={fetchAttendees}
					selectedVacancy={selectedVacancy}
					toggleModal={toggleModal}
					sendInterview={sendPoke}
				/>
			)}

			{displayCancelPokeModal && (
				<CancelPokeModal
					closeModal={() => {
						setDisplayCancelPokeModal(false);
					}}
					id={selectedItemReject}
					type={"proActive"}
					nextAction={() => fetchPoke({ id: poke_id })}
				/>
			)}
			<ConfirmationModal
				active={cancelOfferModal}
				modalName="cancel_offer_modal"
				loading={cancelLoading}
				type={ConfirmationTypes.error}
				firstButton={{
					label: "Yes, cancel this offer",
					type: "warning",
					action: () =>
						cancel_offer({
							offer_id: offer._id
						})
				}}
				onClose={() => setCancelOfferModal(false)}
				title="Are you sure you want to cancel this offer?"
				content="This action is definitive and irreversible."
			/>
			<RequalifyModal
				show={requalifyModal}
				setShow={setRequalifyModal}
				application_id={poke_id}
				first_name={_get(profile, "headline.first_name")}
				job={jobDetails}
				view={APPLICATION}
			/>
		</>
	);
};

PokeSideBar.propTypes = {
	style: PropTypes.object,
	isSticky: PropTypes.bool,
	job: PropTypes.object.isRequired
};

export default PokeSideBar;
