/* eslint-disable react/display-name */

import React, { useEffect } from "react";
import { useTour } from "@reactour/tour";
import styles from "../../components/TourContentComponent/tour-content-component.module.scss";
import TourContentComponent from "../../components/TourContentComponent";
import useOnboardingStore from "../../hooks/useOnboardingStore";
import Portal from "common/Portal";
import { TOUR_EMAIL_TEMPLATES } from "../../../../config/onboarding";
import { SELECTORS_TOUR_INTERFACE_NAVIGATION } from "modules/user-onboarding/workflows/interface-navigation";
import { browserHistory } from "react-router";
import isElementLoaded from "../../helpers/isElementLoaded";
import { getEmailTemplateRoute } from "modules/EmailTemplate/EmailTemplate";

const selectorForStep = stepNumber =>
	`[data-onboarding-step=${TOUR_EMAIL_TEMPLATES}-${stepNumber}]`;

const steps = [
	{
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[8]}`,
		title: `Go to settings`,
		description: `Navigate to the settings to access the templates section.`,
		stepInteraction: false,
		position: p => [p.right + 0, p.top + 20],
		arrow: "left-top"
	},
	{
		selector: selectorForStep(1),
		title: `Select a template`,
		description: `Review existing templates and select one to edit or view details.`,
		stepInteraction: false,
		position: p => [p.right - 100, p.top + 30],
		arrow: "left-top"
	},
	{
		selector: selectorForStep(2),
		title: `Create new template`,
		description: `Start building a new email template from scratch.`,
		stepInteraction: false,
		position: p => [p.right + 16, p.top + 10],
		arrow: "left-top"
	},
	{
		selector: selectorForStep(3),
		title: `Add placeholders`,
		description: `Insert dynamic placeholders for personalized information in your template.`,
		stepInteraction: false,
		position: p => [p.left - 380, p.top + 50],
		arrow: "top-right"
	},
	{
		selector: selectorForStep(4),
		title: `Add new language`,
		description: `Choose and add multiple language versions to cater to different audiences.`,
		stepInteraction: false,
		position: p => [p.left - 385, p.top - 180],
		arrow: "bottom-right"
	},
	{
		selector: selectorForStep(5),
		title: `Save your template`,
		description: `After customization, save your template for future use.`,
		stepInteraction: false,
		position: p => [p.left - 360, p.top - 180],
		arrow: "bottom-right"
	}
];

export const useEmailTemplatesTour = (props = {}) => {
	const store = useOnboardingStore();
	const tour = useTour();

	useEffect(() => {
		if (store.workflowId === TOUR_EMAIL_TEMPLATES) {
			if (!tour.isOpen && store.currentStep === 0) {
				tour.setSteps(steps);
				tour.setCurrentStep(0);
				tour.setIsOpen(true);
			}

			if (store.currentStep === 1 && tour.currentStep === 0) {
				browserHistory.replace(getEmailTemplateRoute(""));
				isElementLoaded(selectorForStep(1)).then(() => {
					tour.setCurrentStep(1);
				});
			}

			if (store.currentStep === 2 && tour.currentStep === 1) {
				tour.setCurrentStep(2);
			}

			if (store.currentStep === 3 && tour.currentStep === 2) {
				document.querySelector(selectorForStep(2)).click();
				isElementLoaded(selectorForStep(3)).then(() => {
					props.setShowPlaceholdersUi?.(true);
					requestAnimationFrame(() => {
						tour.setCurrentStep(3);
					});
				});
			}

			if (store.currentStep === 4 && tour.currentStep === 3) {
				document.querySelector(selectorForStep(4)).click();
				tour.setCurrentStep(4);
			}

			if (store.currentStep === 5 && tour.currentStep === 4) {
				tour.setCurrentStep(5);
			}
		}
	}, [store.workflowId, store.currentStep, tour.currentStep, tour.isOpen]);

	return null;
};

export const providerConfig = {
	ContentComponent: TourContentComponent,
	onClickMask: () => null,
	className: styles.popover,
	maskClassName: styles.mask,
	padding: 0,
	scrollSmooth: true,
	disableKeyboardNavigation: true,
	Wrapper: ({ children }) => <Portal>{children}</Portal>
};
