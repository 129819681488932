import React from "react";
import TourPopover from "modules/user-onboarding/components/TourPopover";
import { ReactComponent as ChevronRightIcon } from "static/icons/chevron-right-icon.svg";
import {
	ONBOARDING_COMMON_STYLES,
	TOUR_USER_AND_DEPARTEMENT
} from "../../../../config/onboarding";
import { browserHistory } from "react-router";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";

// customize popover component
function StepPopover(props) {
	const {
		record: { title, stepsCount, description, arrowPosition },
		events: { onNext }
	} = props;

	return (
		<TourPopover position={arrowPosition}>
			<TourPopover.Header>{title}</TourPopover.Header>
			<TourPopover.Body>{description}</TourPopover.Body>
			<TourPopover.Footer>
				{!!props.record?.step && (
					<TourPopover.Step>
						{props.record.step}/{stepsCount}
					</TourPopover.Step>
				)}
				<TourPopover.ButtonsWrapper>
					{!!props.events?.onSkip && (
						<TourPopover.Button onClick={props.events.onSkip} variant="link">
							Skip
						</TourPopover.Button>
					)}
					<TourPopover.Button
						onClick={onNext}
						variant={props.record?.nextButtonVariant || "link"}
					>
						{props.record?.nextButtonText || (
							<>
								Next step
								<ChevronRightIcon />
							</>
						)}
					</TourPopover.Button>
				</TourPopover.ButtonsWrapper>
			</TourPopover.Footer>
		</TourPopover>
	);
}

export const SELECTORS_TOUR_USER_AND_DEPARTEMENT = [
	`${TOUR_USER_AND_DEPARTEMENT}__0`,
	`${TOUR_USER_AND_DEPARTEMENT}__1`
];

// steps
export const STEPS_TOUR_USER_AND_DEPARTEMENT = [
	{
		selector: `#${SELECTORS_TOUR_USER_AND_DEPARTEMENT[0]}`,
		position: p => [p.left - 275, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Create User",
					arrowPosition: "top-right",
					description: "Click 'Create User' to add new users and get started"
				}}
				events={{ onNext }}
			/>
		)
	},
	{
		selector: `#${SELECTORS_TOUR_USER_AND_DEPARTEMENT[1]}`,
		position: p => [p.left - 275, p.bottom + 30],
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					arrowPosition: "top-right",
					title: "Create department",
					description:
						"Click 'Create Department' to set up your departments and start organizing your workforce & Take control of your team structure."
				}}
				events={{ onNext }}
			/>
		)
	}
];

// handle rendering of each step
function ContentComponent(props) {
	const content = props.steps[props.currentStep].content;
	const isLastStep = props.currentStep === props.steps.length - 1;

	// onboarding store
	const {
		metaData,
		cleanStore,
		setMetaData,
		setIsLoading
	} = useOnboardingStore();

	// last step close tour
	const onTourClose = () => {
		// props.setSteps([]); // todo have to clean steps
		if (metaData?.showStartRecrutmentModal) {
			setMetaData({ ...metaData, showModal: true });
		} else {
			cleanStore(); // clean onboarding store
			browserHistory.push("/settings/setup-guide");
		}
	};

	// persist onboarding user settings
	const { persistData } = useOnboardingSaveData({ onTourClose });

	const onNext = () => {
		if (isLastStep) {
			props.setIsOpen(false); // close tour
			setIsLoading(true);
			persistData();
			return;
		}

		// next is third step
		if (props.currentStep === 0) {
			props.setIsOpen(false);
			browserHistory.push("/settings/users");
		}

		props.setCurrentStep(s => s + 1);
	};

	if (typeof content === "function") {
		return content({
			onNext,
			onSkip: onTourClose,
			step: props.currentStep + 1,
			stepsCount: props.steps.length
		});
	}

	return content;
}

// company tour config
export default function useTourUserDepartmentConfig() {
	return {
		ContentComponent,
		onClickMask: () => null,
		styles: ONBOARDING_COMMON_STYLES,
		disableKeyboardNavigation: true
	};
}
