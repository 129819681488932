import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_TASKS } from "config/api-endpoints";
import { tasksList } from "modules/home/api/queriesKeys.js";

const TAG = "home_task";

const listTasks = (key, { start_date, end_date, filter_tag }) =>
	client(LIST_TASKS, {
		body: {
			tag: TAG,
			filter_tag,
			start_date,
			end_date
		}
	});

const useTasksWp = (options, queryOptions = {}) =>
	useQuery([tasksList, options], listTasks, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useTasksWp;
