import { usePaginatedQuery } from "react-query";
import { get } from "lodash";
import { client } from "lib/api-client";
import { GET_LIST_CHANNELS } from "config/api-endpoints";
import useFiltersStore from "modules/publishJob/store/useFiltersStore";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import fakeChannels from "modules/user-onboarding/mockData/channels-premium.json";
import { HOW_TO_PUBLISH_VACANCY } from "config/onboarding";

const getChannels = (_key, body) =>
	client(GET_LIST_CHANNELS, {
		body
	});

const fakeData = () => new Promise(r => r(fakeChannels));

export const getChannelsQueryKey = "@VonqContract/getChannels";

const useGetChannels = () => {
	const {
		channelName,
		region,
		industry,
		jobTitle,
		jobFunction,
		display,
		sortBy,
		language,
		channelsOffset
	} = useFiltersStore();

	const options = {
		offset: channelsOffset,
		job_function_id: jobFunction?.id,
		job_title_id: jobTitle?.id,
		exact_location_id: get(region, "id", ""),
		industry_id: get(industry, "id", ""),
		name: channelName,
		display_language: language?.value ?? "",
		display,
		limit: 7,
		sort_by: sortBy
	};

	const { workflowId } = useOnboardingStore();
	const fetchData =
		workflowId === HOW_TO_PUBLISH_VACANCY ? fakeData : getChannels;

	return usePaginatedQuery(
		[getChannelsQueryKey, options, workflowId],
		fetchData,
		{
			refetchOnWindowFocus: false,
			retry: 0
		}
	);
};

export default useGetChannels;
