import create from "zustand";

const initialState = {};

const useEntries = create(set => ({
	entries: initialState,
	addEntries: (timesheetId, newEntries) => {
		const type = `${newEntries[0]?.type?.value}s`;
		set(({ entries }) => {
			const currentEntries = entries[timesheetId] || {
				worktimes: [],
				overtimes: [],
				files: [],
				expenses: []
			};

			return {
				entries: {
					...entries,
					[timesheetId]: {
						...currentEntries,
						...(type
							? { [type]: [...currentEntries[type], ...newEntries] }
							: {})
					}
				}
			};
		});
	},
	deleteEntry: (timesheetId, id, type) =>
		set(({ entries }) => {
			const currentEntries = entries[timesheetId] || {};

			return {
				entries: {
					...entries,
					[timesheetId]: {
						...currentEntries,
						[type]: currentEntries[type].filter(entry => entry._id !== id)
					}
				}
			};
		}),
	addFile: (timesheetId, file) => {
		set(({ entries }) => {
			const currentEntries = entries[timesheetId] || {
				worktimes: [],
				overtimes: [],
				files: [],
				expenses: []
			};

			return {
				entries: {
					...entries,
					[timesheetId]: {
						...currentEntries,
						files: (currentEntries?.length && [
							...currentEntries.files,
							file
						]) || [file]
					}
				}
			};
		});
	},
	removeFile: (timesheetId, file) => {
		set(({ entries }) => {
			const currentEntries = entries[timesheetId] || {};

			return {
				entries: {
					...entries,
					[timesheetId]: {
						...currentEntries,
						files:
							(currentEntries?.length &&
								currentEntries.files.filter(fl => fl.id !== file.id)) ||
							{}
					}
				}
			};
		});
	},
	clearEntries: timesheetId => {
		set(({ entries }) => {
			return {
				entries: {
					...entries,
					[timesheetId]: {
						worktimes: [],
						overtimes: [],
						files: [],
						expenses: []
					}
				}
			};
		});
	}
}));

export default useEntries;
