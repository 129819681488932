import React from "react";
import _get from "lodash/get";
import CurrencyFormatter from "common/CurrencyFormater";
import styles from "./permanent-job-sidebar.module.scss";
import {
	REMOTE,
	REMOTE_LABEL,
	HYBRID_OPTION,
	REMOTE_ELIGIBITY_OPTIONS
} from "config";
import { EMPLOYMENT_TYPE } from "config";

export default function Sidebar({ data }) {
	const locationTypeRemote = `${_get(data, "location_type")}` === REMOTE;
	const payment_time = _get(data, "payment_time", "yearly");
	const payment_type = _get(data, "payment_type", "gross");
	const symbol = _get(data, "symbol", "€");

	let remoteEligibilityValue;
	if (data) {
		const {
			remote_eligibility: { office_days, type }
		} = data;

		if (office_days && type.includes(HYBRID_OPTION.value)) {
			remoteEligibilityValue = `Hybrid (${office_days} days in office/week)`;
		} else {
			const { label } = REMOTE_ELIGIBITY_OPTIONS.find(
				({ value }) => value === type
			);
			remoteEligibilityValue = label;
		}
	}

	const mergeBenefits = (otherBenefits, extraBenefits) => {
		let extraBenefitsString = "";
		if (extraBenefits) {
			extraBenefitsString = extraBenefits.map(({ label }) => label).join(", ");
		}
		if (otherBenefits && !extraBenefitsString) return otherBenefits;
		else if (!otherBenefits && extraBenefitsString) return extraBenefitsString;
		else if (otherBenefits && extraBenefitsString)
			return `${otherBenefits}, ${extraBenefitsString}`;
		else return "";
	};

	const getEmploymentType = empType => {
		const employment = EMPLOYMENT_TYPE.find(type => type.value === empType);
		return _get(employment, "label");
	};

	return (
		<div className={styles.sidebarDescription}>
			{/* Company name */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>company name</h6>
				<span>{_get(data, "site.name")}</span>
			</div>

			{/* Remote eligibility  */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>remote eligibility</h6>
				<span>{remoteEligibilityValue}</span>
			</div>

			{_get(data, "employment_type") && (
				<div className={styles.sidebarDescriptionItem}>
					<h6>employment type</h6>
					<span>
						{getEmploymentType(_get(data, "employment_type"))}{" "}
						{_get(data, "expected_duration.duration")}{" "}
						{_get(data, "expected_duration.period")}
					</span>
				</div>
			)}

			{_get(data, "number_of_colleagues") && (
				<div className={styles.sidebarDescriptionItem}>
					<h6>number of colleagues </h6>
					<span>{_get(data, "number_of_colleagues")}</span>
				</div>
			)}

			{/* Salary  */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>
					{payment_time} {payment_type} salary
				</h6>
				{!_get(data, "salary_range_start") ? (
					"NA"
				) : (
					<span>
						Between{" "}
						<CurrencyFormatter
							cent={_get(data, "salary_range_start")}
							symbol={symbol}
						/>{" "}
						and{" "}
						<CurrencyFormatter
							cent={_get(data, "salary_range_end")}
							symbol={symbol}
						/>
					</span>
				)}
			</div>

			{/* Other benefits */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>other benefits</h6>
				<span>
					{data?.other_benefits || data?.extra_benefits?.length > 0
						? mergeBenefits(data.other_benefits, data.extra_benefits)
						: "None"}
				</span>
			</div>

			{/* Payroll country  */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>payroll country</h6>
				<span>{_get(data, "payroll_country")}</span>
			</div>

			{/* Location */}
			<div className={styles.sidebarDescriptionItem}>
				<h6>location</h6>
				{locationTypeRemote ? (
					<div>{REMOTE_LABEL}</div>
				) : (
					<span>
						{_get(data, "company.street")} {_get(data, "company.number")}{" "}
						{_get(data, "company.zip")} {_get(data, "company.city")}{" "}
						{_get(data, "company.country")}
					</span>
				)}
			</div>
		</div>
	);
}
