//TODO move the delete logic to parent
import React, { useEffect } from "react";
import { Overlay, useModalOverlay } from "react-aria";
import { Button } from "common/Button";
import { ReactComponent as TrashIcon } from "static/icons/trash-icon-grey.svg";
import { ReactComponent as CloseIcon } from "static/icons/cross-black.svg";
import { IconButton } from "common/IconButton";
import styles from "./delete-pipeline-step-modal.module.scss";
import { queryCache } from "react-query";
import useCurrentRequest from "modules/RequestView/hooks/useCurrentRequest";
import { useDeleteTempPiplineStep } from "modules/RequestView/api/useDeleteTempPiplineStep";
import { REQUEST_VIEW_KANBAN_SETTINGS_QUERY } from "../kanban/hooks/useTempKanbanSettingsApi";
import { ReactComponent as NotificationIcon } from "static/icons/warning-circle.svg";
import { BIDS_QUERY_KEY } from "modules/Requests/RequestsCandidatesList/hooks/useGetTempList";
import { KEY_FETCH_TEMP_INTERVIEWS } from "modules/Requests/RequestsInterviewsList/hooks/useFilterApplications";
import { GET_CONTRACTS_QUERY_KEY } from "modules/Requests/contracts/hooks/useGetContractsList";

const DeletePipelineStepModal = ({ onCancel, open, onDelete, stepId }) => {
	let ref = React.useRef();

	const [deleteStep] = useDeleteTempPiplineStep({
		onSuccess: () => {
			queryCache.invalidateQueries(REQUEST_VIEW_KANBAN_SETTINGS_QUERY);
			queryCache.invalidateQueries([BIDS_QUERY_KEY]);
			queryCache.invalidateQueries([KEY_FETCH_TEMP_INTERVIEWS]);
			queryCache.invalidateQueries([GET_CONTRACTS_QUERY_KEY]);

			onDelete();
		}
	});

	const { requestId } = useCurrentRequest();

	let { modalProps, underlayProps } = useModalOverlay(
		{ onClose: onCancel },
		{ isOpen: open },
		ref
	);

	useEffect(() => {
		const onKeydown = function(evt) {
			if (evt.key === "Escape") {
				onCancel();
			}
		};

		document.addEventListener("keydown", onKeydown);

		return () => {
			document.body.removeEventListener("keydown", onKeydown);
		};
	}, []);

	const onConfirm = () => {
		const body = {
			job_id: requestId,
			step_id: stepId,
			tag: "delete"
		};

		deleteStep(body);
	};

	return (
		<Overlay>
			<div className={styles.wrapper} ref={ref} {...underlayProps}>
				<div {...modalProps} className={styles.container}>
					<div className={styles.header}>
						<h2 className={styles.title}>Delete step</h2>
						<IconButton onClick={onCancel}>
							<CloseIcon />
						</IconButton>
					</div>
					<div className={styles.descriptionContainer}>
						<NotificationIcon className={styles.notificationIcon} />
						<div className={styles.description}>
							Are you sure you want to delete this step?
						</div>
					</div>
					<div className={styles.buttons}>
						<Button
							rootClassName={styles.cancel}
							text="Cancel"
							variant="outlined"
							onClick={onCancel}
						/>
						<Button
							className={styles.deleteButton}
							text="Delete"
							icon={<TrashIcon className={styles.trashIcon} />}
							onClick={onConfirm}
						/>
					</div>
				</div>
			</div>
		</Overlay>
	);
};

export default DeletePipelineStepModal;
