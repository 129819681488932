import React from "react";
import { ReactComponent as Illustration } from "./illustration.svg";
import s from "./empty-search.module.scss";
import Spinner from "common/Spinner";

function EmptySearch({ isLoading }) {
	return (
		<div className={s.root}>
			{!isLoading ? (
				<div className={s.bg}>
					<Illustration />
				</div>
			) : null}
			{isLoading ? (
				<Spinner
					sx={{ width: 64, height: 64, border: "4px solid" }}
					duration={0.5}
					color={"#ff6849"}
				/>
			) : null}
			<h4 className={s.heading}>
				{!isLoading ? "No results found" : "Loading, please wait..."}
			</h4>
			{!isLoading ? (
				<p className={s.p}>
					Try adjusting your search filters to find what you’re looking for.
				</p>
			) : null}
		</div>
	);
}

export default EmptySearch;
