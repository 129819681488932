import React from "react";
import styles from "./evaluation-card-score-status.module.scss";
import cx from "classnames";
import { ReactComponent as NoIcon } from "static/icons/thumbs-down.svg";
import { ReactComponent as AcceptableIcon } from "static/icons/thumbs-up2.svg";
import { ReactComponent as ExcellentIcon } from "static/icons/thumbs-up.svg";

const EvaluationCardScoreStatus = ({
	containerClassName,
	score,
	iconClassName = "",
	titleClassName = ""
}) => {
	const icons = {
		1: {
			text: "No",
			icon: <NoIcon />
		},
		2: {
			text: "Acceptable",
			icon: <AcceptableIcon />
		},
		3: {
			text: "Excellent",
			icon: <ExcellentIcon />
		}
	};

	return (
		<div className={cx(styles.container, containerClassName)}>
			<div className={cx(styles.icon, iconClassName)}>{icons[score].icon}</div>
			<div className={cx(styles.title, titleClassName)}>
				{icons[score].text}
			</div>
		</div>
	);
};
export default EvaluationCardScoreStatus;
