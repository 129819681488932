import React, { Component, createRef } from "react";
import { scroller } from "react-scroll";
import PropTypes from "prop-types";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import FocusLock from "react-focus-lock";
import { LoaderFull } from "common/Loader";
import GlobalTooltip from "../GlobalTooltip";
import { Button, OutlineButton } from "common/styled/buttons";
import {
	ModalElement,
	Backdrop,
	ModalTitle,
	ModalCloseBtn,
	ModalIntro,
	ModalConfirmMessage,
	ConfirmationModal,
	FloatItem,
	ActionsContainer,
	Clear
} from "./ModalStyled";
import { axiosPropsSelectors } from "modules/app/selectors";
import { toolTipMaxWidth } from "config";

const initialFocusRef = createRef();

class Modal extends Component {
	scrollToModal = () => {
		scroller.scrollTo(this.props.modalName || "modal", {
			offset: -50,
			smooth: false
		});
	};

	componentDidMount() {
		if (this.props.active) this.scrollToModal();
		document.addEventListener("keydown", this.handleKeyDown);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.handleKeyDown);
	}

	componentDidUpdate(previousProps) {
		if (!previousProps.active && this.props.active) {
			this.scrollToModal();
		}
	}

	onClose = () => {
		this.props.onClose();
	};

	handleKeyDown = e => {
		// closing modal by clicking on esc
		if (e.keyCode === 27 && this.props.active) this.onClose();
	};

	defaultSecond = {
		label: "back",
		action: this.onClose
	};

	renderAction(
		{ action, disabled, id, style, label, form, focused, ...button },
		color
	) {
		const ActionButton = color !== "outlined" ? Button : OutlineButton;
		return (
			button && (
				<ActionButton
					color={color}
					bordered
					block
					type="submit"
					form={form}
					onClick={action ? () => action() : null}
					disabled={disabled}
					id={id}
					style={style}
					ref={focused && initialFocusRef}
					{...button}
				>
					{label}
				</ActionButton>
			)
		);
	}

	handleButtonFocus = () => {
		if (initialFocusRef && initialFocusRef.current) {
			initialFocusRef.current.focus();
		}
	};

	render() {
		const {
			id,
			children,
			fixed,
			large,
			title,
			modalName = "modal",
			loading,
			active,
			overlay,
			className,
			firstButton,
			secondButton,
			actionsClassName,
			intro,
			isModal,
			inAction,
			confirmMessage,
			isConfirmationModal,
			actionsStyle,
			showActions,
			centerTitle,
			maxheight,
			showSecondButton,
			maxwidth,
			size,
			titleClassName,
			isOnProceed
		} = this.props;

		if (!active) return null;
		const StyledComponent = isConfirmationModal
			? ConfirmationModal
			: ModalElement;
		return (
			<BodyClassName className={"no-scroll"}>
				<Backdrop>
					<StyledComponent
						name={modalName}
						className={className}
						id={id}
						fixed={fixed ? "fixed" : ""}
						large={large ? "large" : ""}
						maxheight={maxheight}
						maxwidth={maxwidth}
						size={size}
					>
						<FocusLock onActivation={this.handleButtonFocus}>
							{title && (
								<ModalTitle centered={centerTitle} className={titleClassName}>
									{title}
									{overlay && (
										<GlobalTooltip
											maxWidth={toolTipMaxWidth}
											overlay={overlay}
										/>
									)}
								</ModalTitle>
							)}
							{!isOnProceed && (
								<ModalCloseBtn>
									<span onClick={this.onClose}>×</span>
								</ModalCloseBtn>
							)}

							<LoaderFull
								boxClasses="absolute"
								loading={loading || (isModal && inAction)}
							/>

							{intro && <ModalIntro>{intro}</ModalIntro>}

							{children}

							{confirmMessage && (
								<ModalConfirmMessage>{confirmMessage}</ModalConfirmMessage>
							)}
							{showActions && firstButton && secondButton !== null && (
								<ActionsContainer
									className={actionsClassName}
									style={actionsStyle || {}}
								>
									<FloatItem>
										{this.renderAction(
											firstButton,
											firstButton.type || "success"
										)}
									</FloatItem>
									{showSecondButton && (
										<FloatItem>
											{this.renderAction(
												secondButton ||
													(secondButton !== null && this.defaultSecond),
												(secondButton && secondButton.type) || "outlined"
											)}
										</FloatItem>
									)}
									<Clear />
								</ActionsContainer>
							)}
						</FocusLock>
					</StyledComponent>
				</Backdrop>
			</BodyClassName>
		);
	}
}

Modal.defaultProps = {
	active: true,
	loading: false,
	className: "",
	fixed: false,
	isConfirmationModal: false,
	actionsClassName: "stepsActions",
	modalName: "modal",
	showActions: true,
	showSecondButton: true,
	maxheight: "unset"
};

Modal.propTypes = {
	title: PropTypes.string,
	overlay: PropTypes.string,
	modalName: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	className: PropTypes.string,
	fixed: PropTypes.bool.isRequired,
	actionsClassName: PropTypes.string,
	firstButton: PropTypes.shape({
		label: PropTypes.string.isRequired,
		action: PropTypes.func.isRequired,
		className: PropTypes.string,
		disabled: PropTypes.bool
	}),
	secondButton: PropTypes.shape({
		label: PropTypes.string.isRequired,
		action: PropTypes.func.isRequired,
		className: PropTypes.string,
		disabled: PropTypes.bool
	}),
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
	showActions: PropTypes.bool,
	showSecondButton: PropTypes.bool,
	maxheight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const mapStateToProps = state => {
	return {
		inAction: axiosPropsSelectors(state).inAction,
		isModal: axiosPropsSelectors(state).isModal
	};
};

export default connect(mapStateToProps)(Modal);
