import React from "react";
import { ExpiredWrapper } from "./styled";
import { ReactComponent as VacancyNoLonger } from "./icons/VacancyNoLonger.svg";

const JobExpired = () => {
	return (
		<ExpiredWrapper>
			<div className="vacancy-not-found">
				<VacancyNoLonger />
				<h1>This vacancy is no longer open! </h1>
				<p>
					Check <a href="/vacancies">www.wiggli.io/vacancies</a> for all live
					opportunities !
				</p>
			</div>
		</ExpiredWrapper>
	);
};
export const getJobExpiredRoute = () => {
	return "/job-expired";
};
export default JobExpired;
