import { ReactComponent as CalendarIcon } from "static/icons/calendar-dark-grey.svg";
import { ReactComponent as FileIcon } from "static/icons/file1.svg";
import { ReactComponent as UserIcon } from "static/icons/user-x-red.svg";
import { ReactComponent as MessageIcon } from "static/icons/message-square.svg";
import _get from "lodash/get";
import {
	ACCEPT,
	ACCEPTED,
	ACCEPT_MISSION,
	ADMIN,
	CANCELLED,
	CANCEL_APPLICATION,
	CLIENT,
	DECLINED,
	DONE,
	EXPIRED,
	FREELANCER,
	LIVE,
	INVITE_INTERVIEW,
	PROPOSE_ANOTHER_DATE,
	PROPOSE_ANOTHER_TERM,
	PROPOSE_CONTRACT,
	REFUSED,
	REJECTED,
	REJECT_APPLICATION,
	SENT,
	SIGNED,
	SIGN_CONTRACT,
	SUPER_ADMIN
} from "config";

export class BidActionsManager {
	constructor({
		bidId,
		interview,
		contract,
		userType,
		bidStatus,
		companyId,
		userRole,
		onRejectApplication,
		onProposeContract,
		canMakeProposal,
		user,
		onAddNote,
		onProposeInterviewDrawer,
		onAcceptInterviewModal,
		onAcceptContractModal,
		onSignContractAction
	}) {
		Object.assign(this, {
			bidId,
			interview,
			contract,
			userType,
			bidStatus,
			companyId,
			userRole,
			onRejectApplication,
			onProposeContract,
			canMakeProposal,
			user,
			onAddNote,
			onProposeInterviewDrawer,
			onAcceptInterviewModal,
			onAcceptContractModal,
			onSignContractAction
		});
	}

	handleAction(action, disabled = false) {
		if (disabled) return;

		switch (action) {
			case ACCEPT:
				return this.onAcceptInterviewModal();
			case INVITE_INTERVIEW:
			case PROPOSE_ANOTHER_DATE:
				return this.onProposeInterviewDrawer();
			case REJECT_APPLICATION:
				return this.onRejectApplication();
			case SIGN_CONTRACT:
				return this.onSignContractAction();
			case ACCEPT_MISSION:
				return this.onAcceptContractModal();
			case PROPOSE_CONTRACT:
			case PROPOSE_ANOTHER_TERM:
				return this.onProposeContract();
			default:
				break;
		}
	}

	getButtonText({
		isClient,
		interview_id,
		contract_id,
		interview_status,
		contract_status,
		isContractLastId,
		isInterviewLastId
	}) {
		let text = "Reject application";
		if (!interview_id && !contract_id && !isClient) text = "Cancel Application";
		const isClientInInterview = isClient && interview_id;
		const isClientInContract = isClient && contract_id;
		const isFreelancerInInterview = !isClient && interview_id;
		const isFreelancerInContract = !isClient && contract_id;

		if (isClientInInterview) {
			switch (interview_status) {
				case SENT:
				case DECLINED:
				case ACCEPTED:
					text = isInterviewLastId ? "Cancel Interview" : "Refuse interview";
					break;
				default:
					text = "Reject Application";
					break;
			}
		}
		if (isClientInContract) {
			if (contract_status === SENT) text = "Cancel Proposition";
			else text = "Refuse Proposition";
		}

		if (isFreelancerInInterview && isInterviewLastId) {
			text = "Cancel Interview";
		} else if (isFreelancerInInterview && !isInterviewLastId) {
			text = "Refuse Interview";
		}

		if (isFreelancerInContract) {
			if (
				(contract_status === SENT || contract_status === DECLINED) &&
				!isContractLastId
			)
				text = "Refuse Contract Proposition";
			else text = "Cancel Contract Proposition";
		}
		return text;
	}

	extractBidActionVariables() {
		const {
			status: interview_status,
			_id: interview_id,
			last_action_company_id: interviewLastId,
			new_interview_end_date
		} = this.interview;

		const {
			status: contract_status,
			signed_status,
			_id: contract_id,
			last_action_company_id: contract_last_id
		} = this.contract;

		const isClient = this.userType === CLIENT;
		const isFreelancer = this.userType === FREELANCER;
		const isBidLive = this.bidStatus === LIVE;
		const isBidCancelled =
			this.bidStatus === CANCELLED || this.bidStatus === REJECTED;
		const contractDeclined = contract_status === DECLINED;
		const contractSent = contract_status === SENT;
		const contractAccepted = contract_status === ACCEPTED;
		const contractSigned = signed_status === SIGNED;
		const processEnded =
			contract_status === CANCELLED ||
			contract_status === REFUSED ||
			interview_status === REFUSED ||
			interview_status === CANCELLED ||
			isBidCancelled;
		const interviewSent = interview_status === SENT;
		const interviewDeclined = interview_status === DECLINED;
		const interviewExpired = interview_status === EXPIRED;
		const isInterviewLastId =
			interviewLastId && this.companyId === interviewLastId;
		const isContractLastId =
			contract_last_id && this.companyId === contract_last_id;
		const isInterviewDone = interview_status === DONE;
		const interviewCancelled = interview_status === CANCELLED;
		const hasInterviewExpired =
			new_interview_end_date &&
			window
				.moment()
				.isAfter(window.moment.unix(new_interview_end_date), "day");

		const interviewRefusedByFreelancer =
			isFreelancer && interview_status === REFUSED;

		const groups = _get(this.user, "groups", []);

		const canProposeContract =
			!this.user?.is_member ||
			(this.user?.is_member &&
				(this.user?.role_name === SUPER_ADMIN ||
					this.user?.role_name === ADMIN)) ||
			groups.some(
				group =>
					group.id === _get(this.bid, "job.group._id") &&
					group.role_name === ADMIN
			);

		return {
			interview_status,
			interview_id,
			interviewLastId,
			new_interview_end_date,
			contract_status,
			signed_status,
			contract_id,
			contract_last_id,
			isClient,
			isFreelancer,
			isBidLive,
			isBidCancelled,
			contractDeclined,
			contractSent,
			contractAccepted,
			contractSigned,
			processEnded,
			interviewSent,
			interviewDeclined,
			interviewExpired,
			isInterviewLastId,
			isContractLastId,
			isInterviewDone,
			interviewCancelled,
			hasInterviewExpired,
			interviewRefusedByFreelancer,
			canProposeContract
		};
	}

	getActions() {
		const {
			interview_status,
			interview_id,
			contract_status,
			contract_id,
			isClient,
			isBidLive,
			isBidCancelled,
			contractDeclined,
			contractAccepted,
			contractSigned,
			interviewSent,
			interviewDeclined,
			interviewExpired,
			isInterviewLastId,
			isContractLastId,
			isInterviewDone,
			interviewCancelled,
			hasInterviewExpired,
			interviewRefusedByFreelancer,
			canProposeContract,
			processEnded
		} = this.extractBidActionVariables();
		const _actions = [];

		if (processEnded) return [];

		if (!isBidCancelled) {
			if (
				isClient &&
				!contract_id &&
				(!interview_id || isInterviewDone || hasInterviewExpired)
			) {
				_actions.push({
					key: "invite-to-interview",
					label: "Propose interview",
					Icon: CalendarIcon,
					action: () => this.handleAction(INVITE_INTERVIEW)
				});
			}

			if (
				!contract_id &&
				interview_id &&
				(interviewDeclined || interviewSent) &&
				!isInterviewLastId &&
				!interviewExpired
			) {
				_actions.push({
					key: "accept-interview",
					label: "Accept interview",
					Icon: CalendarIcon,
					action: () => this.handleAction(ACCEPT)
				});
			}

			if (isClient && !contract_id) {
				let showTooltip =
					!canProposeContract ||
					(this.canMakeProposal !== undefined && !this.canMakeProposal) ||
					(this.userRole !== ADMIN && this.userRole !== SUPER_ADMIN);
				_actions.push({
					key: "propose-contract",
					label: "Propose Contract",
					Icon: FileIcon,
					action: () => this.handleAction(PROPOSE_CONTRACT),
					status: showTooltip && "disabled",
					tooltipText: !canProposeContract
						? "Sorry, your account status doesn’t allow you to make contract propositions."
						: "You can only make one contract proposition per request."
				});
			}

			if (
				!contract_id &&
				interview_id &&
				!hasInterviewExpired &&
				!isInterviewDone &&
				!interviewCancelled &&
				!interviewRefusedByFreelancer
			) {
				_actions.push({
					key: "propose-another-date",
					label: "Propose another interview",
					Icon: CalendarIcon,
					action: () =>
						this.handleAction(
							isClient ? INVITE_INTERVIEW : PROPOSE_ANOTHER_DATE
						),
					displayToolTip: true,
					tooltipText: "Propose another interview"
				});
			}

			if (!isContractLastId && contractDeclined) {
				_actions.push(
					{
						key: "accept-mission",
						label: "Accept Contract",
						Icon: FileIcon,
						action: () => this.handleAction(ACCEPT_MISSION)
					},
					{
						key: "propose-other-terms",
						label: "Propose other terms",
						Icon: CalendarIcon,
						action: () => this.handleAction(PROPOSE_ANOTHER_TERM)
					}
				);
			}

			if (!contractAccepted) {
				if (contract_id && isClient && this.userRole !== ADMIN) return _actions;
				_actions.push({
					key: "reject_application",
					label: this.getButtonText({
						isClient,
						interview_id,
						contract_id,
						interview_status,
						contract_status,
						isInterviewLastId,
						isContractLastId
					}),
					Icon: UserIcon,
					action: () =>
						this.handleAction(
							isClient ? REJECT_APPLICATION : CANCEL_APPLICATION,
							!isBidLive
						),
					status: "danger"
				});
			}

			if (contractAccepted && !contractSigned) {
				_actions.push({
					key: SIGN_CONTRACT,
					label: "Start mission",
					Icon: UserIcon,
					action: () => this.handleAction(SIGN_CONTRACT)
				});
			}

			if (contractSigned) {
				_actions.push({
					key: "view-missions",
					label: "View mission",
					Icon: FileIcon,
					to: "/missions/" + contract_id
				});
			}
		}

		if (isClient) {
			_actions.push({
				key: "add-note",
				label: "Add note",
				Icon: MessageIcon,
				action: () => this.onAddNote(),
				status: "info"
			});
		}

		return _actions;
	}

	getPrimaryAction() {
		const actions = [...this.getActions()];
		return actions[0];
	}

	getActionsExcludingPrimary() {
		const actions = [...this.getActions()];
		const primaryAction = this.getPrimaryAction();
		if (primaryAction) {
			const primaryActionKey = primaryAction.key;
			return actions.filter(action => action.key !== primaryActionKey);
		}

		return actions;
	}
}
