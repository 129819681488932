import React from "react";
import icon from "static/icons/drop-down-chevron.svg";
import styles from "./radius-field.module.scss";
import { CustomInput } from "common/CustomInput";

const RadiusField = ({ onChange, radius }) => {
	return (
		<div className={styles.radiusFieldWrapper}>
			<div className={styles.header}>
				<div>Radius</div>
				<div className={styles.line} />
			</div>
			<div className={styles.inputContainer}>
				<CustomInput
					onChange={onChange}
					className={styles.input}
					placeholder="Enter a number"
					type="number"
					value={radius}
				/>
				<div className={styles.unit}>
					<div className={styles.km}>Km</div>
					<div className={styles.iconWrapper}>
						<img src={icon} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default RadiusField;
