import React, { useState, useEffect, useMemo } from "react";
import DatePicker from "modules/candidate/components/profileClientView/profileModalTab/components/notes/datePicker.js";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-icon.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import styles from "modules/home/components/filters/filters.module.scss";
import { formatDate } from "common/Functions";
import cx from "classnames";
import { ReactComponent as Reset } from "modules/home/svg/clearFilters.svg";

const DataPicker = ({
	setStartDate,
	setEndDate,
	startDate,
	endDate,
	position,
	resetDatePicker,
	isOpen = false
}) => {
	const [displayPicker, setDisplayPicker] = useState(isOpen);

	const pickerRef = React.createRef(false);
	useOnClickOutside(pickerRef, () => {
		setDisplayPicker(false);
	});

	useEffect(() => {
		if (
			startDate &&
			startDate?._isValid &&
			!displayPicker &&
			endDate &&
			!endDate._isValid
		)
			return setEndDate(window.moment.unix(startDate.unix()).endOf("day"));
	}, [startDate, displayPicker]);

	const handleClick = () => setDisplayPicker(true);

	const isToday = useMemo(() => {
		const selectedStartDate = startDate && startDate?.format("YYYY-MM-DD");
		const selectedEndDate = endDate && endDate?.format("YYYY-MM-DD");

		const result =
			window.moment().isSame(selectedStartDate, "day") &&
			window.moment().isSame(selectedEndDate, "day");

		return result;
	}, [startDate, endDate]);

	return (
		<>
			<div className={styles.datePickerContainer}>
				<button className={styles.datePickerOpener} onClick={handleClick}>
					{(endDate?.isValid?.() || startDate?.isValid?.() || isToday) && (
						<span className={styles.date}>
							{isToday
								? "Today"
								: `${startDate.isValid() ? formatDate(startDate.unix()) : ""} ${
										endDate.isValid() ? `- ${formatDate(endDate.unix())}` : ""
								  } `}
						</span>
					)}
					<CalendarIcon />
				</button>

				<div
					className={cx(styles.boxDatePicker, {
						[styles.showDatePicker]: displayPicker
					})}
				>
					<DatePicker
						setStartDate={setStartDate}
						setEndDate={setEndDate}
						startDate={startDate}
						endDate={endDate}
						pickerRef={pickerRef}
						position={position}
						isOpen={displayPicker}
					/>
				</div>
			</div>
			{startDate && startDate.isValid() && (
				<button onClick={resetDatePicker} className={styles.resetBtn}>
					<Reset />
				</button>
			)}
		</>
	);
};

export default DataPicker;
