import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass";
import { ReactComponent as AddNote } from "static/icons/element-add-shortlist.svg";
import { ReactComponent as OptionsIcon } from "static/icons/icon-options.svg";
import { ReactComponent as AddShortlist } from "static/icons/element-add-note.svg";
import { ReactComponent as ArrowRight } from "static/icons/elements-icons-arrow-r-ight.svg";
import { ReactComponent as ArrowLeft } from "static/icons/elements-icons-arrow-left.svg";
import { ReactComponent as SendVacancy } from "static/icons/send-vacancy-icon.svg";
import { ReactComponent as AddTaskIcon } from "static/icons/btnAddTask.svg";
import ViewCandidateHelper from "modules/candidate/components/profileClientView/HOC/navigationHelper";

import DefaultButton from "common/Buttons";
import ProfileActions from "modules/candidate/components/profileClientView/Header/cta";
import Tooltip from "rc-tooltip";
import usePoolStore from "modules/vacancy/components/candidatePool/zustandPool";
import useSendVacancy from "modules/vacancy/components/candidatePool/SendVacancy/useSendVacancy";
import useShortList from "modules/vacancy/components/candidatePool/ShortListDrawer/useShortList";
import { CANDIDATE } from "config";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

const DrawerFooter = ({
	canPoke,
	canShortlist,
	addNoteHandler,
	previousProfile,
	nextProfile,
	isPreviousDisabled,
	isNextDisabled,
	canResendActivationEmail,
	canDelete,
	nextProfileID,
	refetchProfileData,
	goToProfile,
	profileId,
	headline,
	closeDrawer
}) => {
	const [displayCtas, setDisplayCtas] = useState(false);
	const {
		setIsOpen: openDrawerTask,
		setEntity,
		setSelectedEntityOption
	} = useAddNewTaskStore();
	const { setSelectData, setView, setCurrentId } = usePoolStore();
	const { setIsOpen } = useSendVacancy();
	const { setIsOpen: openDrawerShortList } = useShortList();
	const openTaskDrawer = () => {
		openDrawerTask(true);
		setEntity(CANDIDATE);
		setSelectedEntityOption({
			label: `${headline.first_name} ${headline.last_name}`,
			value: profileId
		});
		closeDrawer();
	};
	return (
		<React.Fragment>
			<Flex mt={2} justifyContent="space-between" alignItems="center">
				<Flex>
					<DefaultButton
						padding="0px"
						isDisabled={isPreviousDisabled}
						onClick={previousProfile}
						backgroundColor="white"
					>
						<Flex alignItems="center">
							<ArrowLeft />
							<Text
								ml={1}
								sx={{
									color: "#212b36",
									fontSize: "16px",
									fontWeight: "normal"
								}}
							>
								Previous
							</Text>
						</Flex>
					</DefaultButton>
					<DefaultButton
						padding="0px 16px"
						isDisabled={isNextDisabled}
						onClick={nextProfile}
						backgroundColor="white"
					>
						<Flex alignItems="center">
							<Text
								mr={1}
								sx={{
									color: "#212b36",
									fontSize: "16px",
									fontWeight: "normal"
								}}
							>
								Next
							</Text>
							<ArrowRight />
						</Flex>
					</DefaultButton>
				</Flex>
				<Flex flexDirection="row-reverse" alignItems="center">
					<Tooltip
						placement="bottom"
						overlay="Send vacancy"
						trigger="hover"
						overlayClassName={"function-tooltip"}
					>
						<Button
							mx={2}
							onClick={() => {
								setSelectData({
									mode: "select",
									selected_candidates: [profileId],
									preview_mode: {
										first_name: headline.first_name,
										last_name: headline.last_name
									},
									total_item: 1
								});
								setView("candidate");
								setCurrentId(profileId);
								setIsOpen(true);
							}}
							disabled={!canPoke}
							sx={{
								height: "40px",
								display: "flex",
								alignItems: "center",
								background: "#dfe3e8",
								justifyContent: "center",
								outline: "none",
								padding: 0,
								width: "40px",
								"&:disabled": {
									cursor: "not-allowed"
								}
							}}
						>
							<SendVacancy />
						</Button>
					</Tooltip>
					<Tooltip
						placement="top"
						overlay="Add to vacancy"
						trigger="hover"
						overlayClassName="function-tooltip"
					>
						<Button
							ml={2}
							onClick={() => {
								setSelectData({
									mode: "select",
									selected_candidates_for_ShortList: [profileId],
									total_item: 1
								});
								closeDrawer();
								openDrawerShortList(true);
							}}
							disabled={!canShortlist}
							sx={{
								background: "transparent",
								border: "none",
								height: "40px",
								padding: "0px",
								"&:disabled": {
									cursor: "not-allowed"
								}
							}}
						>
							<AddShortlist />
						</Button>
					</Tooltip>
					<Tooltip
						placement="top"
						overlay="Add task"
						trigger="hover"
						overlayClassName="function-tooltip"
					>
						<Button
							ml={2}
							onClick={openTaskDrawer}
							sx={{
								background: "transparent",
								border: "none",
								height: "40px",
								padding: "0px"
							}}
						>
							<AddTaskIcon />
						</Button>
					</Tooltip>
					<Tooltip
						placement="top"
						overlay="Add note"
						trigger="hover"
						overlayClassName="function-tooltip"
					>
						<Button
							ml={2}
							onClick={() => {
								addNoteHandler();
							}}
							sx={{
								background: "transparent",
								border: "none",
								height: "40px",
								padding: "0px"
							}}
						>
							<AddNote />
						</Button>
					</Tooltip>
					<Box sx={{ position: "relative" }} height="40px">
						<ProfileActions
							canResendActivationEmail={canResendActivationEmail}
							canDelete={canDelete}
							displayCtas={displayCtas}
							goToProfile={goToProfile}
							nextProfile={nextProfileID}
							closeCtas={() => setDisplayCtas(false)}
							refetchProfileData={refetchProfileData}
						/>
						<Button
							onClick={() => {
								setDisplayCtas(true);
							}}
							sx={{
								background: "transparent",
								border: "none",
								position: "relative",
								height: "40px",
								padding: "0px"
							}}
						>
							<OptionsIcon />
						</Button>
					</Box>
				</Flex>
			</Flex>
		</React.Fragment>
	);
};
// eslint-disable-next-line
export default props => (
	<ViewCandidateHelper
		render={({ ...otherProps }) => <DrawerFooter {...props} {...otherProps} />}
	/>
);
