import React from "react";
import { useButton } from "react-aria";
import { DropDownInput } from "common/DropDownInput";
import styles from "./category-select-button.module.scss";
import cx from "classnames";

const CategorySelectButton = ({ value, className, onInputClick, ...props }) => {
	let ref = props.buttonRef;
	let { buttonProps } = useButton(props, ref);

	return (
		<DropDownInput
			className={cx(styles.container, className)}
			value={value}
			{...buttonProps}
			ref={ref}
			onClick={onInputClick}
		/>
	);
};

export default CategorySelectButton;
