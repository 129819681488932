import React from "react";
import CurrencyInput from "common/NewCurrencyField/NewCurrencyField";

export const EvaluationsInput = ({ onChange, value }) => {
	return (
		<CurrencyInput
			input={{
				onChange: value => onChange(value, "change"),
				value
			}}
			useGreenTheme
		/>
	);
};
