import React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";
import AxiosHelper from "config/axios-helper";
import Modal from "common/modal";
import {
	CANCEL_POKE_ENDPOINT,
	CANCEL_APPLICATION_ENDPOINT
} from "config/api-endpoints";
import FormField from "common/FormField";

import { TEXTAREA_MAX_LENGTH } from "config";

const FormContainer = styled.div`
	.description {
		color: #193651;
		font-size: 14px;
		letter-spacing: normal;
		font-weight: normal;
		margin-bottom: 30px;
	}
	textarea {
		min-height: 150px !important;
	}
`;

const CancelPokeModal = ({
	closeModal,
	id,
	nextAction,
	handleSubmit,
	type
}) => {
	const cancelPoke = ({ reason }) => {
		return AxiosHelper.post({
			url:
				type === "proActive"
					? CANCEL_APPLICATION_ENDPOINT
					: CANCEL_POKE_ENDPOINT,
			data:
				type === "proActive"
					? {
							id,
							cancel_comments: reason
					  }
					: {
							id,
							reason
					  },
			toastMessage: "Application successfully rejected.",
			isModal: true
		}).then(() => {
			nextAction && nextAction();
			closeModal();
		});
	};

	return (
		<Modal
			modalName="hide-from-search"
			onClose={closeModal}
			firstButton={{
				action: handleSubmit(cancelPoke),
				label: "Reject Application",
				type: "primary"
			}}
			title="Reject Application"
		>
			<FormContainer>
				<div className="description">
					Please provide a feedback to the applicant, giving the reason(s) why
					you are rejecting the application.
				</div>
				<Field
					name="reason"
					type="textarea"
					component={FormField}
					placeholder="Your feedback will be sent to the candidate."
					maxLength={TEXTAREA_MAX_LENGTH}
				/>
			</FormContainer>
		</Modal>
	);
};

const validate = formProps => {
	let errors = {};

	if (!formProps.reason) {
		errors.reason = "Please provide your feedback.";
	}

	if (formProps.reason > TEXTAREA_MAX_LENGTH) {
		errors.reason = `The feedback can only be ${TEXTAREA_MAX_LENGTH} characters long.`;
	}

	return errors;
};

export default reduxForm({
	form: "cancel-poke",
	validate,
	touchOnBlur: false
})(CancelPokeModal);
