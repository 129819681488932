import React from "react";
import style from "./mission-details-empty-state.module.scss";
import { ReactComponent as EmptyIcon } from "static/icons/empty-state.svg";

const MissionDetailsEmptyState = ({ text, children }) => {
	return (
		<div className={style.emptyState}>
			<EmptyIcon />
			<p className={style.text}>{text}</p>
			{children}
		</div>
	);
};

export default MissionDetailsEmptyState;
