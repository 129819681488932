import React from "react";
import Modal from "common/modal";
import { NoteBox } from "modules/vacancy/styled";

function FeedbackModal({ text, active, onClose }) {
	return (
		<Modal
			active={active}
			onClose={onClose}
			title={"Feedback"}
			showSecondButton={false}
			fixed
			firstButton={{
				action: onClose,
				label: "Close",
				type: "outlined",
				large: true
			}}
		>
			<NoteBox className="grey-box">{text}</NoteBox>
		</Modal>
	);
}

export default FeedbackModal;
