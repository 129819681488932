import React, { useRef } from "react";
import { JobHeader } from "../JobHeader";
import SimilarJobs from "../SimilarJobs";
import { LoaderFull } from "common/Loader";
import { JobDescription } from "../JobDescription";
import { ApplyJobButton } from "../ApplyJobButton";
import { JobSideBar } from "../JobSideBar";
import { browserHistory } from "react-router";
import styles from "./temp-request-details.module.scss";
import _get from "lodash/get";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { ReactComponent as ChevronLeftIcon } from "static/icons/chevronLeftIcon.svg";

const TempRequestDetails = ({ data, isLoading, userAccountStatus }) => {
	const sidebarActionsRef = useRef(null);
	const { isVisible } = useIntersectionObserver(sidebarActionsRef, {
		root: null,
		threshold: 1,
		rootMargin: "-75px"
	});

	return (
		<div className="container">
			{isLoading ? (
				<LoaderFull loading={isLoading} />
			) : (
				<>
					<div className={styles.backButtonContainer}>
						<button
							onClick={() => browserHistory.push("/jobs/search")}
							className={`${styles.btn} ${styles.btnSecondary}`}
						>
							<ChevronLeftIcon /> Back
						</button>
					</div>
					<div className={styles.body}>
						<JobHeader data={data}>
							{!isVisible && (
								<div className={styles.headerActions}>
									<ApplyJobButton
										job={data}
										userAccountStatus={userAccountStatus}
									/>
								</div>
							)}
						</JobHeader>
						<div className={styles.columns}>
							<div className={styles.main}>
								<JobDescription data={data} />
							</div>
							<div className={styles.sidebar}>
								<div ref={sidebarActionsRef} className={styles.sidebarActions}>
									<ApplyJobButton
										job={data}
										userAccountStatus={userAccountStatus}
									/>
								</div>
								<JobSideBar job={data} />
							</div>
						</div>
						{!!_get(data, "similar_jobs.length") &&
							!!data.similar_jobs.filter(job => job?.description).length && (
								<SimilarJobs
									jobs={data.similar_jobs.filter(job => job.description)}
								/>
							)}
					</div>
				</>
			)}
		</div>
	);
};

export default TempRequestDetails;
