import { css } from "styled-components";
import darken from "polished/lib/color/darken";
import rgba from "polished/lib/color/rgba";
import { colors } from "config/styles";

export default function buttonVariant({
	background,
	color = colors.white,
	hoverColor = colors.white,
	activeColor = colors.white,
	hoverBackground = darken(0.075, background),
	activeBackground = darken(0.1, background),
	border = `2px solid ${background}`,
	hoverBorder = `2px solid ${hoverBackground}`,
	bordered,
	inactive,
	disabled,
	disabledStyles = "",
	disabledActiveStyle = false,
	disabledHover,
	paddingTop,
	paddingBottom,
	margin,
	float
}) {
	return css`
		padding-top: ${paddingTop};
		padding-bottom: ${paddingBottom};
		margin: ${margin};
		float: ${float};
		color: ${color};
		background-color: ${background};
		${bordered &&
			css`
				border: ${border};
			`};
		${!disabledStyles
			? css`
					&.disabled,
					&:disabled {
						background-color: ${background};
						opacity: 0.5;
						cursor: not-allowed;
					}
			  `
			: `&:disabled ${disabledStyles}`}
		${inactive &&
			css`
				background-color: ${background};
				opacity: 0.5;
				cursor: not-allowed;
			`};
		${!disabledHover &&
			css`
				&:hover {
					color: ${disabled || inactive ? "" : hoverColor} !important;
					background-color: ${disabled || inactive
						? ""
						: hoverBackground} !important;
					border: ${disabled || inactive
						? ""
						: bordered
						? hoverBorder
						: ""} !important;
				}
			`}
		${!disabledActiveStyle &&
			css`
				&:active,
				&.active {
					background-color: ${disabled || inactive ? "" : activeBackground};
					color: ${disabled || inactive ? "" : activeColor};
					background-image: none;
				}
			`};
		&:focus {
			${background !== "transparent" &&
				css`
					box-shadow: 0 0 0 0.2rem ${rgba(background, 0.6)};
				`};
		}
	`;
}
