import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

const suppliersDepartement = (key, { tag }) =>
	client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag
		}
	});

const useSuppliersDepartements = (key, options, queryOptions = {}) =>
	useQuery([key, options], suppliersDepartement, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useSuppliersDepartements;
