import { yupResolver } from "@hookform/resolvers/yup";
import { bytesToSize } from "config/helpers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { MAX_LENGTH_IN_BYTES } from "../utils/constant";

const schema = yup.object().shape({
	content: yup.string().test(
		"content-size",
		({ value }) => {
			return `Content is too long (${bytesToSize(
				new TextEncoder().encode(value).length
			)}), max is ${bytesToSize(MAX_LENGTH_IN_BYTES)}`;
		},
		value => {
			return new TextEncoder().encode(value).length < MAX_LENGTH_IN_BYTES;
		}
	),
	to: yup
		.array()
		.of(
			yup.object({
				_id: yup.string(),
				email: yup.string().email("Invalid email address format.")
			})
		)
		.min(1, "Please specify at least one recipient"),
	content_placeholders: yup.array().of(
		yup.object({
			_id: yup.string().required(),
			value: yup.string().required(),
			key: yup.string(),
			entity: yup.string()
		})
	)
});

const useNewEmailForm = values => {
	return useForm({
		resolver: yupResolver(schema),
		reValidateMode: "onSubmit",
		defaultValues: {
			to: values.to,
			cc: values.cc,
			bcc: values.bcc,
			subject: values.subject,
			content: values.content,
			content_placeholders: values.content_placeholders,
			attachments: values.attachments,
			scheduled_at: values.scheduled_at,
			signature_address: values.signature_address,
			signature_email: values.signature_email,
			signature_full_name: values.signature_full_name,
			signature_phone: values.signature_phone,
			signature_position: values.signature_position,
			signature_social_media: values.signature_social_media
		}
	});
};

export default useNewEmailForm;
