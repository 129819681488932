import React, { useEffect } from "react";
import { useMutation } from "react-query";
import _get from "lodash/get";
import Modal from "common/modal";
import { Container } from "./Styled";
import { ReactComponent } from "./unsubscribe.svg";
import { client } from "lib/api-client";
import { REMOVE_FROM_CANDIDATE_POOL_ENDPOINT } from "config/api-endpoints";
import toaster from "common/Toaster";
import { OutlineButton } from "common/styled/buttons";

const unsubscribe = company_id =>
	client(REMOVE_FROM_CANDIDATE_POOL_ENDPOINT, { body: { company_id } });

const UnsubscribeModal = ({ onClose, company_id }) => {
	const [mutate, { isLoading }] = useMutation(unsubscribe, {
		onError: e => {
			if (e && e.status === 422) {
				let errorMsg = _get(e, "detail.name");
				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			}
		}
	});

	useEffect(() => {
		mutate(company_id);
	}, []);

	const getDescription = () => `Your profile has successfully been removed from the
	client's talent pool`;
	return (
		<Modal
			fixed={true}
			onClose={onClose}
			modalName={"unsubscribe"}
			id="unsubscribe"
			loading={isLoading}
		>
			<Container>
				<ReactComponent />
				<div className="title">Profile successfully removed</div>
				<div className="description">{getDescription()}</div>
				<OutlineButton onClick={onClose}>Close</OutlineButton>
			</Container>
		</Modal>
	);
};

export default UnsubscribeModal;
