import React from "react";
const FilterGreen = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="20" height="20" rx="10" fill="#E6F3F2" />
			<path
				d="M5.125 6.71491C5.125 6.40741 5.125 6.25366 5.17813 6.13621C5.22487 6.0329 5.29945 5.94891 5.39118 5.89627C5.49546 5.83643 5.63197 5.83643 5.905 5.83643H14.095C14.368 5.83643 14.5045 5.83643 14.6088 5.89627C14.7005 5.94891 14.7751 6.0329 14.8219 6.13621C14.875 6.25366 14.875 6.40741 14.875 6.71491V7.08244C14.875 7.23002 14.875 7.30382 14.859 7.37243C14.8448 7.43324 14.8214 7.49085 14.7899 7.54275C14.7544 7.60131 14.7049 7.64964 14.6058 7.7463L11.4879 10.7897C11.3889 10.8864 11.3394 10.9347 11.3038 10.9932C11.2723 11.0451 11.249 11.1028 11.2348 11.1636C11.2187 11.2322 11.2187 11.306 11.2187 11.4536V14.3239C11.2187 14.4312 11.2187 14.4849 11.2034 14.5313C11.1898 14.5724 11.1677 14.6091 11.1389 14.6386C11.1064 14.6719 11.0621 14.6918 10.9736 14.7317L9.31609 15.4784C9.13691 15.5591 9.04733 15.5995 8.97541 15.5827C8.91252 15.5679 8.85733 15.5259 8.82184 15.4656C8.78125 15.3966 8.78125 15.2879 8.78125 15.0706V11.4536C8.78125 11.306 8.78125 11.2322 8.76524 11.1636C8.75105 11.1028 8.72769 11.0451 8.69619 10.9932C8.66063 10.9347 8.61112 10.8864 8.5121 10.7897L5.39415 7.7463C5.29513 7.64964 5.24562 7.60131 5.21006 7.54275C5.17856 7.49085 5.1552 7.43324 5.14101 7.37243C5.125 7.30382 5.125 7.23002 5.125 7.08244V6.71491Z"
				stroke="#00857A"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default FilterGreen;
