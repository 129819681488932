import AxiosHelper, { endpoints } from "config/axios-helper";
import { HIDE_TO_BE_REVIEWED_BANNER, HIDE_COOKIE_POLICY } from "./actionTypes";

export function updatePassword(data, reduxFormName) {
	return AxiosHelper.__post({
		url: endpoints.CHANGE_PASSWORD,
		toastMessage: "Your password has successfully been updated.",
		reduxFormName,
		data
	});
}

export function closeTobeReviewedBanner() {
	return {
		type: HIDE_TO_BE_REVIEWED_BANNER
	};
}

export function closeCookiePolicy() {
	return {
		type: HIDE_COOKIE_POLICY
	};
}
