import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_PROFILE_PERSONAL_INFO } from "config/api-endpoints";

const updateProfilePersonalInfo = data => {
	return client(UPDATE_PROFILE_PERSONAL_INFO, {
		body: {
			...data,
			tag: "disable_refresh_notif"
		}
	});
};

export const useUpdatePersonalInfo = options => {
	return useMutation(updateProfilePersonalInfo, options);
};
