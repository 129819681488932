import styled from "styled-components";
import { colors } from "config/styles";

export const TableWrapper = styled.div`
	border: 1px solid #f1f2f5;
	margin-bottom: ${props => (props.noScroll ? "0px" : "8px")};
	background-color: ${colors.white};
`;
export const SupplierList = styled.table`
	border-collapse: separate;
	margin: 0;
	width: 100%;
	font-weight: 500;
`;
export const SuppliersBody = styled.div`
	display: block;
	height: auto;
	max-height: ${props => (props.scroll ? "307px" : "auto")};
	overflow-x: ${props => (props.scroll ? "auto" : "hidden")};
`;
const TableItem = styled.th`
	border: 0;
`;

export const Th = styled(TableItem)`
	color: ${colors.bidList};
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 22px;
	text-transform: uppercase;
	border-bottom: 1px solid #f1f2f5;
	color: #a0a3bd;
	padding: 25px;
	text-align: ${props => (props.isNotNumber ? "left" : "right")};
	.icon-down-arrow {
		margin-left: 5px;
		font-size: 5px;
	}
`;

export const TdStyled = TableItem.withComponent("td");

export const Td = styled(TdStyled)`
	position: relative;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 24px;
	border: 0;
	border-bottom: 1px solid #f1f2f5 !important;
	word-break: break-word;
	color: #6e7191;
	vertical-align: middle;
	padding: 25px;
	text-align: ${props => (props.isNotNumber ? "left" : "right")};
`;

export const Thead = styled.thead`
	background-color: transparent;
`;

export const Trthead = styled.tr`
	background-color: transparent;
`;

export const Tr = styled.tr`
	cursor: pointer;
	height: ${props => props.height && props.height};
	&:last-child {
		${Td} {
			border-bottom: 0 !important;
		}
	}

	&:hover {
		${Td}:first-child {
			border-left: 3px solid #ff6849;
		}
		${Td} {
			background-color: ${props =>
				!props.inactive && "rgba(160, 163, 189, 0.1)"};
		}
	}

	&.inactive {
		background-color: ${colors.hoverBgColor};
		cursor: not-allowed;
		td {
			color: ${colors.timesheetTh} !important;
		}
	}
`;
