import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { capitalize, get } from "lodash";
import cx from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import { dateCalendar } from "common/Functions";
import useOnClickOutside from "hooks/useOnClickOutside";
import styles from "./profile-info.module.scss";
import Avatar from "common/Avatar/index";
import { ReactComponent as Close } from "static/icons/closeModalInfoProfile.svg";
import { ReactComponent as ArrowRight } from "static/icons/angle-right-b.svg";
import { formatDate } from "config/helpers";

const ProfileInfo = ({ setShowProfileInfo, data }) => {
	const modalRef = useRef(null);
	const [menuViewers, setMenuViewers] = useState(false);
	const user = useSelector(onlineUserSelector);
	const closeModal = () => {
		setShowProfileInfo(false);
	};
	useOnClickOutside(modalRef, () => {
		closeModal();
	});

	const userViewsData = get(data, "users_views_data");
	const userId = get(user, "id");

	const renderFullName = userViewData => {
		return userViewData.user_info._id === userId ? (
			get(data, "last_viewed_by.user_info._id", "") === userId ? (
				<h3>Last seen by you</h3>
			) : (
				<h3>You</h3>
			)
		) : (
			<h3>{userViewData.user_info.full_name}</h3>
		);
	};

	return (
		<div ref={modalRef} className={styles.root}>
			<div className={styles.headerModal}>
				<h2>Profile info</h2>
				<button onClick={closeModal}>
					<Close />
				</button>
			</div>
			<div className={styles.bodyModal}>
				<span className={styles.sourceLabel}>Source</span>
				<div className={styles.sourceValue}>
					{data?.source === "added" ? (
						<>
							Added by {get(data, "created_by.first_name")}{" "}
							{get(data, "created_by.last_name")}
						</>
					) : (
						data?.source && capitalize(data?.source)
					)}
				</div>
				<span className={styles.creationDateLabel}>Creation date</span>
				<div className={styles.creationDateValue}>
					{formatDate(data?.created_at, true)}
				</div>
				{Boolean(get(data, "total_views")) && (
					<h3 className={styles.viewersLabel}>
						Viewers ({get(data, "total_views")})
					</h3>
				)}
				{Boolean(Object.keys(get(data, "last_viewed_by")).length) && (
					<h5
						onClick={() => setMenuViewers(!menuViewers)}
						className={cx({ [styles.opened]: menuViewers })}
					>
						<ArrowRight /> {get(data, "last_viewed_by.user_info.full_name")}
						<span>{dateCalendar(get(data, "last_viewed_by.viewed_date"))}</span>
					</h5>
				)}
				{menuViewers && (
					<ul>
						<Scrollbars
							autoHideTimeout={5000}
							autoHideDuration={200}
							autoHeight
							autoHeightMax={200}
							style={{ zIndex: 1, paddingBottom: "16px" }}
							renderTrackHorizontal={props => (
								<div
									{...props}
									style={{ display: "none" }}
									className="track-horizontal"
								/>
							)}
							renderView={props => (
								<div
									{...props}
									style={{ ...props.style, overflowX: "hidden" }}
								/>
							)}
						>
							{userViewsData.map(user => {
								return (
									<li key={get(user, "_id")}>
										<div className={styles.leftSide}>
											<Avatar
												size={32}
												src={get(user, "user_info.avatar")}
												name={get(user, "user_info.full_name")}
											/>
											<div className={styles.contactInfo}>
												{renderFullName(user)}
												<h4>{get(user, "user_info.user_function")}</h4>
											</div>
										</div>
										<span>{dateCalendar(get(user, "viewed_date"))}</span>
									</li>
								);
							})}
						</Scrollbars>
					</ul>
				)}
				<span className={styles.followingLabel}>Following</span>
				{data?.following?.length > 0 && (
					<div className={styles.followingValue}>
						{data.following.join(" • ")}
					</div>
				)}
			</div>
		</div>
	);
};
export default ProfileInfo;
