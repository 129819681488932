/* eslint-disable react/display-name */
import React, { useMemo, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";
import { Flex, Text } from "rebass";
import _map from "lodash/map";
import { browserHistory } from "react-router";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";
import s from "./view-supplier.module.scss";
import Block from "./Block";
import InvitationForm from "./InvitationForm";
import useViewSupplier from "../api/useViewSupplier";
import InvitationHistory from "./InvitationHistory";
import Tag from "./Tag";
import Heading from "./Heading";
import { excerpt } from "common/Functions";
import { useToggle } from "react-use";
import useListPagination from "hooks/useListPagination.js";
import { searchParser } from "config/helpers";
import _get from "lodash/get";
import useSuppliersStore from "modules/home/zustand/SuppliersStore";
import { goBack } from "config/helpers";

export const getViewSupplierPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const DESCRIPTION_MAX_CHARS = 255;

function ViewSupplier({ params }) {
	const [showMore, toggleShowMore] = useToggle(false);
	const { data, isLoading } = useViewSupplier(params);
	const isLongDescription = data?.description?.length > DESCRIPTION_MAX_CHARS;
	// const hq = data?.locations?.offices?.[0] ?? {};
	const expertise = useMemo(() => {
		if (!data || isLoading) return [];
		return [
			..._map(data?.functions, f => f.parent_sector.name),
			..._map(data?.skills, s => s.name)
		];
	}, [data?.functions, data?.skills]);
	const searchQueries = searchParser();
	const welcomePageSupplierStore = useSuppliersStore();
	const { state, nextId, previousId } = useListPagination({
		data: _get(welcomePageSupplierStore, searchQueries.listKey, []),
		id: _get(params, "id")
	});
	useEffect(() => {
		browserHistory.replace(`/supplier/${state.id}${window.location.search}`);
	}, [state.id]);

	return (
		<div className={s.root}>
			<Flex
				mb={22}
				className="bottom-actions"
				justifyContent="space-between"
				alignItems="center"
			>
				<button onClick={goBack} className={s.backButton}>
					<ArrowNarrowLeftIcon width={16} height={16} /> Back
				</button>
				{Boolean(searchQueries.listKey) && (
					<Flex>
						<Text
							onClick={previousId}
							as={"button"}
							disabled={state.previousDisabled}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "11px",
								fontWeight: "600",
								letterSpacing: "0.99px",
								color: "rgb(97, 105, 118)",
								textTransform: "uppercase",
								border: "none",
								background: "none",
								outline: "none",
								"&[disabled]": {
									color: "rgb(160,165,185)",
									cursor: "not-allowed"
								}
							}}
							mr={"10px"}
						>
							&larr; Previous
						</Text>
						<Text
							onClick={nextId}
							as={"button"}
							disabled={state.nextDisabled}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "11px",
								fontWeight: "600",
								letterSpacing: "0.99px",
								color: "rgb(97, 105, 118)",
								textTransform: "uppercase",
								border: "none",
								background: "none",
								outline: "none",
								"&[disabled]": {
									color: "rgb(160,165,185)",
									cursor: "not-allowed"
								}
							}}
						>
							next &rarr;
						</Text>
					</Flex>
				)}
			</Flex>
			<Heading data={data} isLoading={isLoading} />

			<div className={s.content}>
				<div className={s.container}>
					<Block title={"Description"}>
						{isLoading ? (
							<Flex flexDirection={"column"} gap={2}>
								<Skeleton width={300} />
								<Skeleton width={250} />
								<Skeleton width={350} />
								<Skeleton width={100} />
							</Flex>
						) : (
							<span>
								{excerpt(
									data?.description,
									showMore ? data?.description.length : DESCRIPTION_MAX_CHARS
								) ?? "-"}
								{isLongDescription ? (
									<button
										className={s.linkButton}
										onClick={() => toggleShowMore(!showMore)}
									>
										{!showMore ? "Show more" : "Show less"}
									</button>
								) : null}
							</span>
						)}
					</Block>
					<Block>
						<div className={s.group}>
							<h4>Services proposed</h4>
							<div>
								{isLoading ? (
									<Flex sx={{ gap: 2 }}>
										<Skeleton width={100} height={25} />
										<Skeleton width={100} height={25} />
										<Skeleton width={100} height={25} />
										<Skeleton width={100} height={25} />
									</Flex>
								) : data?.services?.length > 0 ? (
									_map(data?.services, service => (
										<Tag key={service?.label ?? service}>
											{service?.label ?? service}
										</Tag>
									))
								) : (
									"-"
								)}
							</div>
						</div>
						<div className={s.group}>
							<h4>Expertise / Categories covered</h4>
							<div>
								{expertise.length > 0
									? expertise.map(e => <Tag key={e}>{e}</Tag>)
									: "-"}
								{isLoading ? (
									<Flex sx={{ gap: 2 }}>
										<Skeleton width={100} height={25} />
										<Skeleton width={100} height={25} />
										<Skeleton width={100} height={25} />
									</Flex>
								) : null}
							</div>
						</div>
						<div className={s.group}>
							<h4>Number of employees</h4>
							<div>
								{isLoading ? (
									<Skeleton width={150} />
								) : (
									<span>
										{data?.size?.from} - {data?.size?.to}
									</span>
								)}
							</div>
						</div>
						{/* <div className={s.group}>
							<h4>Headquarters location</h4>
							<div>
								{isLoading ? (
									<Skeleton width={150} height={30} />
								) : hq.city && hq.country ? (
									<Tag variant={"outlined"}>
										{hq?.city ? hq?.city + ", " : ""} {hq?.country}
									</Tag>
								) : (
									"-"
								)}
							</div>
						</div> */}
						<div className={s.group}>
							<h4>Geographic coverage</h4>
							<div>
								{isLoading ? (
									<Flex sx={{ gap: 2 }}>
										<Skeleton width={100} height={30} />
										<Skeleton width={100} height={30} />
									</Flex>
								) : data?.locations?.geo_coverage?.length > 0 ? (
									data?.locations?.geo_coverage.map(location => (
										<Tag key={location.latitude} variant={"outlined"}>
											{_get(location, "city", false)
												? location.city + ", "
												: ""}{" "}
											{location.country}
										</Tag>
									))
								) : (
									"-"
								)}
							</div>
						</div>
						<div className={s.group}>
							<h4>Offices</h4>
							<div>
								{isLoading ? (
									<Flex sx={{ gap: 2 }}>
										<Skeleton width={100} height={30} />
										<Skeleton width={100} height={30} />
									</Flex>
								) : data?.locations?.offices?.length > 0 ? (
									data?.locations?.offices.map(location => (
										<Tag key={location.latitude} variant={"outlined"}>
											{_get(location, "city", false)
												? location.city + ", "
												: ""}{" "}
											{location.country}
										</Tag>
									))
								) : (
									"-"
								)}
							</div>
						</div>
						<div className={s.group}>
							<h4>Industries</h4>
							<div>
								{isLoading ? (
									<Flex sx={{ gap: 2 }}>
										<Skeleton width={100} height={25} />
										<Skeleton width={100} height={25} />
										<Skeleton width={100} height={25} />
										<Skeleton width={100} height={25} />
									</Flex>
								) : data?.services?.length > 0 ? (
									_map(data?.industries, industry => (
										<Tag key={industry?.label ?? industry}>
											{industry?.name ?? industry}
										</Tag>
									))
								) : (
									"-"
								)}
							</div>
						</div>

						<div className={s.group}>
							<h4>Spoken languages</h4>
							<div>
								{isLoading ? (
									<Flex>
										<Skeleton width={100} height={30} />
										<Skeleton width={100} height={30} />
									</Flex>
								) : data?.languages?.length > 0 ? (
									data?.languages?.map(l => (
										<Tag key={l._id} variant={"outlined"}>
											{l.name}
										</Tag>
									))
								) : (
									"-"
								)}
							</div>
						</div>
					</Block>
					<Block title={"Billing details"}>
						<div className={s.billingGrid}>
							<div>
								<h4>VAT Number</h4>
								{isLoading ? (
									<Skeleton width={150} height={8} />
								) : (
									<span>{data?.vat}</span>
								)}
							</div>
							<div>
								<h4>BIC (Bank Identifier Code)</h4>
								{isLoading ? (
									<Skeleton width={150} height={8} />
								) : (
									<span>{data?.bic ?? "-"}</span>
								)}
							</div>
							<div>
								<h4>IBAN (International Bank Account Number)</h4>
								{isLoading ? (
									<Skeleton width={150} height={8} />
								) : (
									<span>{data?.iban ? data?.iban : "-"}</span>
								)}
							</div>
						</div>
					</Block>
				</div>
				<Block>
					<div className={cx(s.container, s.sidebar)}>
						<InvitationForm
							supplierId={data?._id}
							tiers={data?.supplier_tiers}
						/>
						<InvitationHistory supplierId={data?._id} />
					</div>
				</Block>
			</div>
		</div>
	);
}

export default ViewSupplier;
