import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { EDITION_HISTORY } from "config/api-endpoints";

const editHistory = (_key, body) =>
	client(EDITION_HISTORY, {
		body
	});
export const editHistoryKey = "@candidate/editHistory";

const useEditHistory = (options, queryOptions = {}) =>
	useQuery([editHistoryKey, options], editHistory, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default useEditHistory;
