import React, { useState, useRef } from "react";
import { Box, Flex } from "rebass";
import useOnClickOutside from "hooks/useOnClickOutside";
import { DocumentRequirement, DocumentMenu } from "../StyledDocManagement";
import { ReactComponent as DocErrorIcon } from "../icons/doc-error.svg";
import { ReactComponent as DocIcon } from "../icons/doc-normal.svg";

function DocumentRequirementItem({ _id, name, is_required, onEdit, onDelete }) {
	const [displayMenu, setDisplayMenu] = useState(false);
	const menuRef = useRef(null);
	useOnClickOutside(menuRef, () => setDisplayMenu(false));

	return (
		<DocumentRequirement>
			<Box px={2} py={2} mb={2} className="document-requirement">
				<Flex justifyContent="space-between">
					<Box width={1 / 2}>
						<Flex>
							{(is_required && <DocErrorIcon width={30} height={30} />) || (
								<DocIcon width={30} height={30} />
							)}
							<div className="content">
								<strong>{name}</strong>
								<br />
								<span className={is_required ? "required" : ""}>
									{is_required ? "Mandatory" : "Optional"}
								</span>
							</div>
						</Flex>
					</Box>
					<Box>
						<Flex alignItems="center" sx={{ position: "relative" }}>
							<div
								onClick={() => setDisplayMenu(true)}
								className="moriz dots ignore-click"
							>
								<div className="dot ignore-click" />
								<div className="dot ignore-click" />
								<div className="dot ignore-click" />
							</div>
							{displayMenu && (
								<DocumentMenu ref={menuRef}>
									<div>
										<button
											onClick={() => {
												setDisplayMenu(false);
												onEdit(_id);
											}}
										>
											Edit
										</button>
										<button
											onClick={() => {
												setDisplayMenu(false);
												onDelete(_id);
											}}
										>
											Delete
										</button>
									</div>
								</DocumentMenu>
							)}
						</Flex>
					</Box>
				</Flex>
			</Box>
		</DocumentRequirement>
	);
}

export default DocumentRequirementItem;
