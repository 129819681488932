/* eslint-disable react/display-name */
//TODO remove null from profile description and job description, we added null to avoid page crash
import React, { useCallback, useEffect, useState, useRef } from "react";
import { CreateRequestDescriptionCard } from "../CreateRequestDescriptionCard";
import { CreateRequestAdditionalInfoCard } from "../CreateRequestAdditionalInfoCard";
import styles from "./create-request-step-two.module.scss";
import { Button } from "common/Button";
import { ReactComponent as ArrowLeftIcon } from "static/icons/arrow-left-icon.svg";
import toaster from "common/Toaster";
import { useGetCountriesList } from "modules/vacancy/create-vacancy/api/useGetCountriesList";
import LeaveHook from "common/LeaveHook";
import { useGetApplicantLocations } from "../../hooks/useGetApplicantLocations";
import { VACANCY_STEP } from "../../utils/constants";
import { CreateRequestStepper } from "../CreateRequestStepper";
import { SaveButton } from "common/SaveButton";
import { CreateRequestStepTwoLoading } from "../CreateRequestStepTwoLoading";
import { CreateRequestStepTwoNavigation } from "../CreateRequestStepTwoNavigation";
import _get from "lodash/get";
import { getFormDefaultValues } from "../../utils/helper";
import { CreateRequestMissionDetailsCard } from "../CreateRequestMissionDetailsCard";
import { useRequestDetailsForm } from "modules/CreateRequestModule/hooks/useRequestDetailsForm";
import { useCreateRequest } from "modules/CreateRequestModule/api/useCreateRequest";
import { useEditRequestStepTwo } from "modules/CreateRequestModule/hooks/useEditRequestStepTwo";
import { browserHistory } from "react-router";
import { useGetTimesheetCurrencySetting } from "modules/CreateRequestModule/api/useGetTimesheetCurrencySetting";
import { useGetTimesheetFrequencySetting } from "modules/CreateRequestModule/api/useGetTimesheetFrequencySetting";
import { TIMESHEET_FREQUENCY_MONTHLY } from "config";

const ORDERED_FIELDS = [
	"versions",
	"education_level",
	"employment_type",
	"positions_number",
	"number_of_colleagues",
	"payroll_country",
	"salary_range_start",
	"salary_range_end",
	"currency",
	"payment_time",
	"payment_type",
	"extra_benefits",
	"other_benefits",
	"due_date",
	"type",
	"office_days",
	"street",
	"number",
	"street",
	"zip",
	"city",
	"country",
	"latitude",
	"longitude",
	"featured_video",
	"employment_type"
];

const ADDRESS_FIELDS = [
	"street",
	"number",
	"street",
	"zip",
	"city",
	"country",
	"latitude",
	"longitude"
];

const FIELDS_MAP = {
	versions: "description",
	education_level: "applicantEducationLevel",
	expected_duration: "duration",
	positions_number: "numberOfPositions",
	number_of_colleagues: "numberColleagues",
	expected_duration_period: "durationType",
	//salary data
	applicants_location: "applicantLocation",
	payroll_country: "payrollCountry",
	salary_range_start: "salaryMin",
	salary_range_end: "salaryMax",
	currency: "currency",
	payment_time: "paymentTime",
	payment_type: "type",
	extra_benefits: "extraBenefits",
	other_benefits: "otherBenefits",
	//additionInfo data
	featured_video: "featuredVideo",
	employment_type: "employmentType",
	due_date: "dueDate",
	featured_image: "featuredImage"
};

const CreateRequestStepTwo = ({
	onPreviousClick: onPreviousClickProp,
	vacancyToEdit,
	vacancyId,
	actif,
	isEdit
}) => {
	const [isFormIntialized, setIsFormIntialized] = useState(false);
	const [isUploadingImage, setIsUploadingImage] = useState(false);
	const [isFormSaving, setIsFormSaving] = useState(false);
	const isFormSubmitted = useRef(false);
	const methods = useRequestDetailsForm();
	const currencySetting = useGetTimesheetCurrencySetting();
	const frequencySetting = useGetTimesheetFrequencySetting();
	const [editVacancy, isEditVacancyLoading] = useEditRequestStepTwo();

	const { data: countriesList } = useGetCountriesList();
	const applicantLocations = useGetApplicantLocations();
	const {
		handleSubmit: handleSubmit,
		reset,
		formState: { isDirty, errors },
		watch,
		control,
		setValue,
		setError,
		getValues
	} = methods;

	const [isDescriptionDirty] = useState(false);

	const [createVacancy, isCreateVacancyLoading] = useCreateRequest();

	useEffect(() => {
		if (frequencySetting && currencySetting?.code && !vacancyToEdit?.title) {
			reset({
				...getValues(),
				currency: { value: currencySetting?.code },
				timesheetFrequency:
					frequencySetting.length > 0
						? frequencySetting.filter(
								el => el.value === TIMESHEET_FREQUENCY_MONTHLY
						  )[0].value
						: frequencySetting.value
			});
		}
	}, [currencySetting, frequencySetting, vacancyToEdit]);

	useEffect(() => {
		if (
			vacancyToEdit?.title &&
			countriesList?.length > 0 &&
			applicantLocations?.length > 0
		) {
			const data = getFormDefaultValues(vacancyToEdit, { applicantLocations });
			reset(data);
			setIsFormIntialized(true);
		} else if (vacancyToEdit && !vacancyToEdit?.versions) {
			setIsFormIntialized(true);
		}
	}, [vacancyToEdit, countriesList, applicantLocations]);

	const onPublish = values => {
		setIsFormSaving(true);

		if (vacancyToEdit) {
			if (isDirty || isDescriptionDirty) {
				editVacancy(values, vacancyToEdit.id, {
					onError: onPublishError
				});
			} else {
				browserHistory.push(`/job/${vacancyToEdit.id}`);
			}
		} else {
			createVacancy(values, vacancyId, {
				onError: onPublishError
			});
		}
	};

	const onPublishError = e => {
		setIsFormSaving(false);
		if (e && e.status === 422) {
			let errorMsg = _get(e, "detail");
			if (errorMsg) {
				const fieldToScroll = getFirstRegisteredInput(Object.keys(errorMsg));
				let isAddressErrorSetted = false;

				Object.keys(errorMsg).forEach(name => {
					const isErrorInAddress = ADDRESS_FIELDS.includes(name);

					if (isAddressErrorSetted && isErrorInAddress) return;
					let id = isErrorInAddress ? "vacancyAddress" : FIELDS_MAP[name];

					setError(id, {
						type: "manual",
						message: _get(errorMsg, name)[0],
						shouldFocus: fieldToScroll === name
					});

					if (isErrorInAddress) isAddressErrorSetted = true;
				});
			}
		} else {
			toaster.danger("An error has occurred");
		}
	};

	const getFirstRegisteredInput = fields => {
		return ORDERED_FIELDS.find(item => fields.includes(item));
	};

	const onPrevious = () => {
		onPreviousClickProp();
	};

	const onImageSubmitStart = useCallback(() => {
		setIsUploadingImage(true);
	}, []);

	const onImageSubmitFinish = useCallback(() => {
		setIsUploadingImage(false);
	}, []);

	if (!actif) return null;

	return isEdit && !isFormIntialized ? (
		<CreateRequestStepTwoLoading />
	) : (
		<>
			<CreateRequestStepTwoNavigation display={actif} />
			<CreateRequestStepper onBack={onPrevious} actifStep={VACANCY_STEP} />
			<div className={styles.cardsList}>
				<LeaveHook
					dirty={(isDirty || isDescriptionDirty) && !isFormSaving && actif}
				>
					<CreateRequestDescriptionCard
						titleError={errors.title}
						descriptionError={errors.description}
						control={control}
						description={watch("description")}
					/>
					<CreateRequestMissionDetailsCard
						control={control}
						setValue={setValue}
						vacancyAddress={watch("vacancyAddress")}
						vacancyAddressType={watch("vacancyAddressType")}
						errors={errors}
						remoteEligibility={watch("remoteEligibility")}
						onImageSubmitStart={onImageSubmitStart}
						onImageSubmitFinish={onImageSubmitFinish}
						applicantLocation={watch("applicantLocation")}
						currency={watch("currency")}
						rate={watch("rate")}
						watch={watch}
						currencySetting={currencySetting}
					/>
					<CreateRequestAdditionalInfoCard
						control={control}
						setValue={setValue}
						vacancyAddress={watch("vacancyAddress")}
						vacancyAddressType={watch("vacancyAddressType")}
						errors={errors}
						remoteEligibility={watch("remoteEligibility")}
						onImageSubmitStart={onImageSubmitStart}
						onImageSubmitFinish={onImageSubmitFinish}
					/>
					<div className={styles.buttonsContainer}>
						<Button
							text="Previous"
							onClick={onPrevious}
							icon={<ArrowLeftIcon />}
							variant="text"
							className={styles.previousButton}
						/>

						<SaveButton
							leftIcon={null}
							text="Save"
							onClick={() => {
								isFormSubmitted.current = true;
								handleSubmit(onPublish)();
							}}
							className={styles.publishButton}
							isLoading={isCreateVacancyLoading || isEditVacancyLoading}
							isDisabled={
								isCreateVacancyLoading ||
								isEditVacancyLoading ||
								isUploadingImage
							}
						/>
					</div>
				</LeaveHook>
			</div>
		</>
	);
};

export default CreateRequestStepTwo;
