import create from "zustand";

const initialState = {
	offset: 0,
	name: "",
	size: undefined,
	industry: [],
	services: [],
	languages: [],
	functions: [],
	skills: [],
	location: undefined,
	physical_presence: false,
	term: ""
};

const useMySuppliersStore = create(set => ({
	/* getters */
	...initialState,
	/* setters */
	setOffset: offset => set({ offset }),
	setName: name => set({ name }),
	setIndustry: industry => set({ industry }),
	setServices: services => set({ services }),
	setLanguages: languages => set({ languages }),
	setLocation: location => set({ location }),
	setPhysicalPresence: physical_presence => set({ physical_presence }),
	setFunctions: functions => set({ functions }),
	setSkills: skills => set({ skills }),
	setTerm: term => set({ term }),
	setSize: size => set({ size }),
	resetAllFilters: () => set({ ...initialState })
}));

export default useMySuppliersStore;
