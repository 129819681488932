import { useEffect, useRef, useState } from "react";
import { usePaginatedQuery } from "react-query";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { client } from "lib/api-client";
import { useDebounce } from "react-use";
import isEqual from "lodash/isEqual";
import { EVALUATIONS_DATA_ENDPOINT } from "config/api-endpoints";
import { EVALUATIONS_FILTER_ID } from "../components/utils";
import { getSearchEvaluationsPayload } from "../components/utils/getSearchEvaluationsPayload";
import { getValidFilters } from "common/FilterDrawer/utils";

export const filterData = body => {
	return client(EVALUATIONS_DATA_ENDPOINT, {
		body
	});
};

export const GET_EVALUATIONS_QUERY_KEY = "GET_EVALUATIONS_QUERY_KEY";

const useGetPermanentEvaluationsList = (id, isEnabled) => {
	const {
		setQueryError,
		setIsLoading,
		setIsFetching,
		getState,
		setData
	} = useFilters();

	const {
		query,
		logicalOperator,
		filters,
		sortBy,
		offset,
		filterChangeTrigger,
		limit
	} = getState(EVALUATIONS_FILTER_ID);

	const previousFilters = useRef();
	const [body, setBody] = useState(formatBody());

	const { refetch, isFetching, isLoading, isFetched, data } = usePaginatedQuery(
		[GET_EVALUATIONS_QUERY_KEY, body],
		() => filterData(body),
		{
			onError: e => {
				if (e?.detail?.keywords) {
					setQueryError(true);
				}
			},
			refetchOnWindowFocus: false,
			enabled: !!body.limit && isEnabled
		}
	);

	useEffect(() => {
		setIsLoading(isLoading);
		setIsFetching(isFetching && !isFetched);
	}, [isLoading, isFetching, refetch, isFetched]);

	useEffect(() => {
		if (data) {
			setData(data);
			setQueryError(false);
		}
	}, [data]);

	useEffect(() => {
		const validFilters = getValidFilters(filters);
		const globalFilter = {
			validFilters,
			sortBy,
			offset,
			logicalOperator,
			limit,
			id
		};

		const isFiltersChanged = !isEqual(previousFilters.current, globalFilter);

		if (isFiltersChanged) {
			previousFilters.current = globalFilter;
		}
		if (
			(filterChangeTrigger !== "change" &&
				filterChangeTrigger !== "newFilter" &&
				isFiltersChanged) ||
			filterChangeTrigger === "reset" ||
			filterChangeTrigger === "limitChange"
		) {
			setBody(formatBody());
		}
	}, [
		filters,
		sortBy,
		offset,
		filterChangeTrigger,
		logicalOperator,
		limit,
		id
	]);

	useDebounce(
		() => {
			if (filterChangeTrigger === "change") {
				setBody(formatBody());
			}
		},
		500,
		[filters, query]
	);

	function formatBody() {
		return getSearchEvaluationsPayload({
			id,
			filters,
			logicalOperator,
			offset,
			sortBy,
			limit,
			query
		});
	}

	return refetch;
};

export default useGetPermanentEvaluationsList;
