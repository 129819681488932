import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_GDPR_PERMANENT_INFORMATIONS } from "config/api-endpoints";

const getInformations = id =>
	client(GET_GDPR_PERMANENT_INFORMATIONS, {
		body: {
			id,
			tag: "profile"
		}
	});

export const GET_GDPR_PERMANENT_INFORMATIONS_QUERY_KEY =
	"GET_GDPR_PERMANENT_INFORMATIONS_QUERY_KEY";

const useGetGdprPermanentInformations = (id, options) =>
	useQuery(
		GET_GDPR_PERMANENT_INFORMATIONS_QUERY_KEY,
		() => getInformations(id),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...options
		}
	);
export default useGetGdprPermanentInformations;
