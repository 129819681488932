import base from "common/base";
import { Box } from "rebass";
import styled, { css } from "styled-components";
import { colors } from "config/styles";

export const Tag = styled.div`
	display: inline-flex;
	justify-content: space-between;
	padding: 5px 10px;
	color: ${colors.white};
	border-radius: 3px;
	text-transform: none;
	align-items: center;
	background: #ffffff;
	box-shadow: 0px 1px 1px #d9dbe9;
	border-radius: 3px;
	> form {
		> input {
			border: none;
			font-family: BasierCircle;
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 22px;
			color: ${({ isTagExist }) => (isTagExist ? "#F62F2F" : "#6e7191")};
		}
	}
	${props =>
		props.inactive &&
		css`
			opacity: 0.5;
		`};
	${props =>
		!props.inactive &&
		css`
			cursor: pointer;
		`};
	${props =>
		props.isTagExist &&
		css`
			border: 1px solid #f72f2f;
		`};
	${props =>
		props.isSelected &&
		css`
			border: 1px solid #fd6950;
			background: #fff8f6;
		`};

	span {
		display: inline-block;
		text-align: center;
		line-height: 18px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		margin-left: 10px;
		font-style: normal;
	}
	&.tag-type-archived {
		${props =>
			props.isSelected &&
			css`
				border: 1px solid #60cfac;
				background: #f2fff9;
			`};
	}
	${base};
`;

export const TagBox = styled(Box)`
	background-color: transparent;
	border-radius: 3px;
	${props =>
		props.error &&
		css`
			border-color: ${colors.errorBgcolor};
		`};
`;
