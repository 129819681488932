export const interviewsListKey = "@home/list_interviews";
export const pendingApplicationsListKey = "@home/pending-applications";
export const openJobsListKey = "@home/open_jobs";
export const overdueJobsListKey = "@home/overdue_jobs";
export const bidsListKey = "@home/list_bids";
export const contractListKey = "@home/list_contact";
export const requestKey = "@home/list_request";
export const timesheetToReviewKey = "@home/list_timesheet_to_review";
export const upcomingStartersKey = "@home/list_upcoming_starters";
export const upcomingFinishersKey = "@home/list_upcoming_finishers";
export const recentSuppliersListKey = "@home/recent_suppliers";
export const mySuppliersListKey = "@home/my_suppliers";
export const newFreelancersListKey = "@home/new_freelancers";
export const tasksList = "/@home/tasks_list";

export const activeProcessesKey = "@candidate/activeProcesses";
export const allProcessesKey = "@candidate/allProcesses";
export const getListCandidateFilesKey = "@Profile/getListCandidateFiles";
export const userHistoryKey = "@candidate/user_history";
export const viewCandidateKey = "@candidate/viewCandidate";
export const userNoteKey = "@candidate/userNote";
