/* eslint-disable react/jsx-key */
import React, { useMemo, useRef } from "react";
import { useTable } from "react-table";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import cx from "classnames";
import { Text, Flex } from "rebass";
import useListSupplierInvitations from "../api/useListSupplierInvitations";
import s from "./invitation-history.module.scss";
import Tooltip from "common/Tippy";
import { formatDate } from "../../../config/helpers";
import { Link } from "react-router";
import Spinner from "../../../common/Spinner";

const statusLabels = {
	draft: "Pending",
	sent: "Pending",
	accepted: "Accepted",
	refused: "Refused"
};

const Status = ({ status }) => (
	<Tooltip
		placement={"left"}
		content={statusLabels[status]}
		overflow={"visible"}
	>
		<div className={cx(s.status, s[status])} />
	</Tooltip>
);

function InvitationHistory({ supplierId }) {
	const { data, isLoading } = useListSupplierInvitations({
		supplier_id: supplierId
	});
	const categoryRef = useRef();
	const hasMore = data?.pagination.size < data?.pagination.total;

	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: row => formatDate(row.created_at)
			},
			{
				Header: "Inviter",
				accessor: row =>
					row.created_by.first_name + " " + row.created_by.last_name
			},
			{
				Header: "Categories",
				accessor: row => (
					<Tooltip content={row.category.name} overflow={"hidden"}>
						<div className={s.category} ref={categoryRef}>
							{row.category.name}
						</div>
					</Tooltip>
				)
			},
			{
				Header: "Tier",
				accessor: row => row.tier.name.replace("tier ", "#")
			},
			{
				Header: "Status",
				accessor: row => (
					<div className={s.statusContainer}>
						<Status status={row.status} />
					</div>
				)
			}
		],
		[data]
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		data: data?.data ?? [],
		columns
	});

	return (
		<div className={s.root}>
			<h4>
				<span>Supplier invitation history</span>
				{hasMore ? (
					<Link
						to={"/suppliers-management#history"}
						className={s.viewMoreButton}
					>
						View more <ArrowSmRightIcon width={16} height={16} />
					</Link>
				) : null}
			</h4>
			{isLoading ? (
				<Flex
					flexDirection={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					mt={4}
					sx={{ gap: 2 }}
				>
					<Spinner
						sx={{ width: 32, height: 32 }}
						color={"#fd5749"}
						duration={0.5}
					/>
					<Text>Loading, please wait...</Text>
				</Flex>
			) : data?.data.length > 0 ? (
				<table className={s.table} {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th {...column.getHeaderProps()}>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map(row => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map(cell => {
										return (
											<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			) : (
				<Flex mt={3}>
					<h5>
						No previous invitations found, use the form above to create a new
						one.
					</h5>
				</Flex>
			)}
		</div>
	);
}

export default InvitationHistory;
