import React, { useState, useEffect, useRef, useMemo } from "react";
import { queryCache } from "react-query";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import isEmpty from "lodash/isEmpty";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import cx from "classnames";
import _get from "lodash/get";
import _omit from "lodash/omit";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import style from "../../my-company.module.scss";
import ToggleInput from "../ToggleInput";
import ProfileImage from "modules/company/components/avatar/profileAvatar.js";
import { parsePhoneNumber } from "common/Functions";
import { ReactComponent as IconPen } from "static/icons/iconPen.svg";
import { COMPANY } from "config";
import { Picker } from "common/Picker";
import AddressBoxModal from "common/AddressBoxNext/AddressBoxModal";
import RenderInputAdress from "../RenderInputAdress";
import { SELECT_OPTIONS_NUMBER_OF_EMPLOYEES } from "config";
import { useDispatch, useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { ADMIN } from "config";
import { LIST_INDUSTRIES_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { PHONE_DEFAULT_COUNTRY } from "config";
import useLayoutElements from "hooks/useLayoutElements";
import { isElementInViewport } from "config/helpers";
import { SaveButton } from "common/SaveButton";
import { getMyProfile } from "modules/user/actions/authActions";
import { SELECTORS_TOUR_SETUP_COMPANY } from "modules/user-onboarding/workflows/setup-company";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useTour } from "@reactour/tour";
import _isEmpty from "lodash/isEmpty";
import {
	INTERACTIVE_CONTAINER_STYLES,
	TOUR_SETUP_COMPANY
} from "config/onboarding";
import CompanyImage from "static/images/Company.jpg";

const listIndustries = () => client(LIST_INDUSTRIES_ENDPOINT, { body: {} });

const formatDataIndustries = (data = []) => {
	const child = data.map(e => {
		return {
			id: e._id,
			label: e.name
		};
	});

	const parent = [{ id: 1, label: "Industries", children: child }];
	return parent;
};

const formatDataIndustriesToSend = (data = []) => {
	const dataIndustries = data.map(e => e.id);
	return dataIndustries;
};

const getlistIndustries = () => {
	return new Promise((resolve, reject) => {
		listIndustries()
			.then(res => {
				const formattedData = formatDataIndustries(res);
				resolve(formattedData);
			})
			.catch(e => reject(e));
	});
};

const schema = yup.object().shape({
	industries: yup.array().min(1, "Please select at least one industry."),
	commercial_name: yup
		.string()
		.nullable()
		.required("Commercial name is a required"),
	vat_number: yup
		.string()
		.nullable()
		.required("VAT number is a required")
});

const useTourData = ({ company }) => {
	const tourThirdStepRef = useRef(null);
	// to avoid re-run of effect ( when company data is updated )
	const [tourIsLoadedOnce, setTourIsLoadedOnce] = useState(false);

	const { workflowId, activeOverlay, setActiveOverlay } = useOnboardingStore();
	const { setIsOpen, setCurrentStep, currentStep, isOpen } = useTour();

	const isActiveSetupCompanyTour = useMemo(
		() => workflowId === TOUR_SETUP_COMPANY,
		[workflowId]
	);

	useEffect(() => {
		if (
			!tourIsLoadedOnce &&
			!_isEmpty(company) &&
			tourThirdStepRef.current &&
			isActiveSetupCompanyTour
		) {
			setCurrentStep(2);
			setIsOpen(true);
			setTourIsLoadedOnce(true);
		}
	}, [tourThirdStepRef, isActiveSetupCompanyTour, tourIsLoadedOnce, company]);

	const closeTour = () => {
		if (isActiveSetupCompanyTour) {
			setIsOpen(false);
			setActiveOverlay(true);
		}
	};

	const goNextTourStep = () => {
		if (isActiveSetupCompanyTour) {
			setActiveOverlay(false);
			setCurrentStep(3);
			setIsOpen(true);
		}
	};

	return {
		closeTour,
		goNextTourStep,
		tourThirdStepRef,
		toasterDuration: isActiveSetupCompanyTour ? 500 : false,
		selectors: {
			third: SELECTORS_TOUR_SETUP_COMPANY[2]
		},
		selectorsClassNames: {
			third:
				isActiveSetupCompanyTour && isOpen && currentStep === 2
					? " highlighted"
					: ""
		},
		interactiveContainerStyles:
			isActiveSetupCompanyTour && activeOverlay && currentStep === 2
				? INTERACTIVE_CONTAINER_STYLES
				: {}
	};
};

const GeneralInformations = ({
	hasMultiSite,
	company,
	currentSite,
	updateCompanyInfo,
	isLoadingUpdate,
	setActiveTab,
	IsAdmin
}) => {
	const {
		closeTour,
		selectors,
		goNextTourStep,
		toasterDuration,
		tourThirdStepRef,
		selectorsClassNames,
		interactiveContainerStyles
	} = useTourData({
		company
	});

	const user = useSelector(onlineUserSelector);
	const dispatch = useDispatch();

	const isOwner =
		_get(user, "active_role") === ADMIN && !_get(user, "is_member", false);

	const isSuperAdmin = _get(user, "active_role") === ADMIN;

	const [errorsApi, setErrorsApi] = useState({});

	const [modeEdit, setModeEdit] = useState(false);

	const [editAddress, setEditAddress] = useState(false);

	const { control, watch, handleSubmit, reset, formState } = useForm({
		resolver: yupResolver(schema),
		shouldUnregister: true
	});

	const { scrollarea } = useLayoutElements();

	const nameErrorElement = useRef();
	const vatErrorElement = useRef();
	const addressErrorElement = useRef();
	const companyWebsiteErrorElement = useRef();
	const industriesErrorElement = useRef();
	const phoneErrorElement = useRef();
	const employesCounterElement = useRef();

	const allField = watch();

	const getViewport = value => {
		if (value) {
			return {
				northeast: {
					lat: value.getNorthEast().lat(),
					lng: value.getNorthEast().lng()
				},
				southwest: {
					lat: value.getSouthWest().lat(),
					lng: value.getSouthWest().lng()
				}
			};
		} else {
			return null;
		}
	};
	const saveData = data => {
		const payload = {
			tag: "general_information",
			name: _get(data, "commercial_name"),
			website: _get(data, "company_website"),
			vat: _get(data, "vat_number"),
			industries: formatDataIndustriesToSend(
				_get(data, "industries[0].children", [])
			),
			main_location: {
				number: _get(data, "company_address.number"),
				street: _get(data, "company_address.street"),
				box: _get(data, "company_address.box"),
				latitude: _get(data, "company_address.latitude"),
				longitude: _get(data, "company_address.longitude"),
				city: _get(data, "company_address.city"),
				country: _get(data, "company_address.country"),
				zip: _get(data, "company_address.zip"),
				iso_country: _get(data, "company_address.iso_country"),
				viewport:
					_get(data, "company_address.viewport") ??
					getViewport(_get(data, "company_address.geometry.viewport"))
			},
			phone: _get(data, "phone"),
			total_employees: _get(data, "number_of_employees.value"),
			...(hasMultiSite && { site_id: _get(currentSite, "_id") })
		};

		updateCompanyInfo(hasMultiSite ? payload : _omit(payload, ["site_id"]), {
			onSuccess: () => {
				queryCache.invalidateQueries("myCurrentSite");
				queryCache.invalidateQueries("@getSites");
				setActiveTab(_get(company, "_id"));
				setModeEdit(!modeEdit);
				toast.success(`company details updated.`, {
					position: "bottom-right",
					duration: toasterDuration || 2500
				});
				setErrorsApi({});
				// update auth profile
				if (currentSite?.is_default) dispatch(getMyProfile());
				setTimeout(goNextTourStep, 550);
			},
			onError: error => {
				if (error.status === 422) {
					const message = error.detail.name;
					if (message) {
						toast(message || "Error while saving changes", {
							isFailed: true
						});
					} else {
						setErrorsApi(_get(error, "detail"));
					}
				} else {
					toast("Error while saving changes", {
						isFailed: true
					});
				}
				// setTimeout(goNextTourStep, 4050);
			}
		});
	};

	const handlePhoneChange = (country, formattedValue, onChange) => {
		const phoneValue = `${country?.countryCode?.toUpperCase()} ${formattedValue.replace(
			/\s/g,
			""
		)}`;
		onChange(phoneValue);
	};

	const onSubmit = data => {
		saveData(data);
	};

	const handleCancel = () => {
		reset();
		setModeEdit(!modeEdit);
		setErrorsApi({});
	};

	const handleFormSubmit = () => {
		handleSubmit(onSubmit)();
	};

	const scrollToFormHiddenArea = () => {
		scrollarea.scrollBy({ top: scrollarea.offsetHeight, behavior: "smooth" });
	};

	useEffect(() => {
		setModeEdit(false);
		reset({
			industries:
				_get(company, "industries", []).length > 0
					? formatDataIndustries(_get(company, "industries", []))
					: [],
			commercial_name: _get(company, "name"),
			company_website: _get(company, "website"),
			number_of_employees: {
				label: _get(company, "total_employees"),
				value: _get(company, "total_employees")
			},
			phone: _get(company, "phone", ""),
			company_address: _get(company, "main_location"),
			vat_number: _get(company, "vat")
		});
	}, [company]);

	useEffect(() => {
		if (
			Object.keys(errors).length !== 0 ||
			Object.keys(errorsApi).length !== 0
		) {
			if (
				nameErrorElement.current ||
				vatErrorElement.current ||
				addressErrorElement.current ||
				(companyWebsiteErrorElement.current &&
					isElementInViewport(companyWebsiteErrorElement.current)) ||
				(industriesErrorElement.current &&
					isElementInViewport(industriesErrorElement.current)) ||
				(phoneErrorElement.current &&
					isElementInViewport(phoneErrorElement.current)) ||
				(employesCounterElement.current &&
					isElementInViewport(employesCounterElement.current))
			) {
				return;
			}

			scrollToFormHiddenArea();
		}
	}, [errors, errorsApi]);

	const { dirtyFields, errors } = formState;

	return (
		<>
			<div className={style.headerBlock}>
				<h3>General informations</h3>
				{modeEdit ? (
					<div className={style.ctaModeEdit} style={interactiveContainerStyles}>
						<button
							type="button"
							className={style.cancelBtn}
							onClick={() => {
								handleCancel();
								goNextTourStep();
							}}
						>
							Cancel
						</button>
						<SaveButton
							onClick={handleFormSubmit}
							isLoading={isLoadingUpdate}
							isDisabled={isLoadingUpdate || isEmpty(dirtyFields)}
						/>
					</div>
				) : (
					IsAdmin && (
						<button
							type="button"
							id={selectors.third}
							ref={tourThirdStepRef}
							className={selectorsClassNames.third}
							onClick={() => {
								closeTour();
								setModeEdit(!modeEdit);
							}}
						>
							<IconPen /> Edit
						</button>
					)
				)}
			</div>
			<div className={style.card} style={interactiveContainerStyles}>
				<form className={style.formFields} onSubmit={handleSubmit(onSubmit)}>
					<div className={cx(style.field, style.avatar)}>
						<div className={style.label}>Company logo</div>
						<div className={style.infoLabel}>
							{IsAdmin ? (
								<ProfileImage
									user={{ ...company, id: _get(company, "company_id") }}
									type={COMPANY}
									onSuccess={() => {
										queryCache.invalidateQueries("myCurrentCompany");
									}}
									hasMultiSite={hasMultiSite}
									site_id={_get(currentSite, "_id")}
								/>
							) : (
								<img
									src={company.avatar || CompanyImage}
									alt="Company image"
									className={style.companyImage}
								/>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Commercial name</div>
						<div className={style.infoLabel}>
							<Controller
								name="commercial_name"
								control={control}
								render={({ field: { onChange, value } }) => (
									<ToggleInput
										modeEdit={modeEdit}
										value={value}
										onChange={onChange}
										isOwner={isOwner}
										isSuperAdmin={isSuperAdmin}
										initialValue={_get(company, "name")}
									/>
								)}
							/>
							{(_get(errors, "commercial_name") ||
								_get(errorsApi, "name", []).length > 0) && (
								<span ref={nameErrorElement} className={style.error}>
									{_get(
										errors,
										"commercial_name.message",
										typeof _get(errorsApi, "name[0]") === "string"
											? _get(errorsApi, "name")
											: _get(errorsApi, "name[0]")
									)}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>VAT number</div>
						<div className={style.infoLabel}>
							<Controller
								name="vat_number"
								control={control}
								render={({ field: { onChange, value } }) => (
									<ToggleInput
										modeEdit={modeEdit}
										value={value}
										onChange={onChange}
										isOwner={isOwner}
										isSuperAdmin={isSuperAdmin}
										initialValue={_get(company, "vat")}
									/>
								)}
							/>
							{(_get(errors, "vat_number") ||
								_get(errorsApi, "vat", []).length > 0) && (
								<span ref={vatErrorElement} className={style.error}>
									{_get(
										errors,
										"vat_number.message",
										_get(errorsApi, "vat[0].message", "")
									)}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Headquarters address</div>
						<div
							className={cx(style.infoLabel, { [style.editMode]: modeEdit })}
						>
							<RenderInputAdress
								value={_get(allField, "company_address")}
								modeEdit={modeEdit}
								setEditAddress={value => {
									setEditAddress(value);
								}}
							/>
							{_get(errorsApi, "main_location", []).length > 0 && (
								<span ref={addressErrorElement} className={style.error}>
									{_get(errorsApi, "main_location[0]", "")}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Company website</div>
						<div className={style.infoLabel}>
							<Controller
								name="company_website"
								control={control}
								render={({ field: { onChange, value } }) => (
									<ToggleInput
										modeEdit={modeEdit}
										value={value}
										onChange={onChange}
										isOwner={true}
									/>
								)}
							/>
							{_get(errorsApi, "website", []).length > 0 && (
								<span ref={companyWebsiteErrorElement} className={style.error}>
									{_get(errorsApi, "website[0]", "")}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Industries</div>
						<div className={style.infoLabel}>
							{modeEdit ? (
								<Controller
									name="industries"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Picker
											PrimaryHeaderContent={() => "industries"}
											SecondaryHeaderContent={() => "selected"}
											displayRating={false}
											isMultipleSelect={true}
											onConfirm={e => {
												onChange(e);
											}}
											onSkillDeleteFromInput={e => {
												onChange(e);
											}}
											value={value}
											queryFn={getlistIndustries}
											queryId={"functions"}
											key={"functions"}
											useServerFilter={false}
											inlineChips={true}
											inputClassName={style.industryInput}
										/>
									)}
								/>
							) : (
								<div className={style.chipsContainer}>
									{_get(company, "industries", []).map(c => (
										<span key={c._id} className={style.industriesChip}>
											{c.name}
										</span>
									))}
								</div>
							)}
							{(_get(errors, "industries") ||
								_get(errorsApi, "industries", []).length > 0) && (
								<span ref={industriesErrorElement} className={style.error}>
									{_get(
										errors,
										"industries.message",
										_get(errorsApi, "industries[0].message", "")
									)}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>General phone number</div>
						<div className={style.infoLabel}>
							{modeEdit ? (
								<Controller
									name="phone"
									control={control}
									render={({ field: { onChange: onFormChange, value } }) => (
										<PhoneInput
											country={PHONE_DEFAULT_COUNTRY}
											value={value}
											onChange={(_, country, e, formattedValue) =>
												handlePhoneChange(country, formattedValue, onFormChange)
											}
										/>
									)}
								/>
							) : (
								<span className={style.phoneNumber}>
									{parsePhoneNumber(_get(company, "phone"))}
								</span>
							)}
							{_get(errorsApi, "phone", []).length > 0 && (
								<span ref={phoneErrorElement} className={style.error}>
									{_get(errorsApi, "phone[0]", "")}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Number of employees</div>
						<div className={style.infoLabel}>
							{modeEdit ? (
								<Controller
									name="number_of_employees"
									control={control}
									render={({ field: { onChange, value } }) => {
										return (
											<Select
												value={value}
												options={SELECT_OPTIONS_NUMBER_OF_EMPLOYEES}
												clearable={false}
												onChange={s => onChange(s)}
											/>
										);
									}}
								/>
							) : (
								<span>{_get(company, "total_employees")}</span>
							)}
							{_get(errorsApi, "total_employees", []).length > 0 && (
								<span ref={employesCounterElement} className={style.error}>
									{_get(errorsApi, "total_employees[0]", "")}
								</span>
							)}
						</div>
					</div>
				</form>
			</div>
			<div style={interactiveContainerStyles}>
				<Controller
					name="company_address"
					control={control}
					render={({ field: { onChange, value } }) => {
						return (
							<AddressBoxModal
								active={editAddress}
								onClose={() => setEditAddress(false)}
								onSave={loc => {
									setEditAddress(false);
									onChange(loc);
								}}
								requiredFields={["street", "number", "city", "zip", "country"]}
								isRequired={true}
								title="Headquarters address"
								label="Company address"
								currentAddress={value}
							/>
						);
					}}
				/>
			</div>
		</>
	);
};

export default GeneralInformations;
