import { useEffect } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";

import {
	MAP_TYPE_COMPONENT,
	MAP_TYPE_OPERATORS,
	NOTES_FILTER_ID,
	NOTES_MODULE_ID,
	NOTES_PAGE_SIZE,
	TYPES
} from "../components/utils";
import { COLUMNS } from "../components/utils/notesFilterFields";

export const useInitializeFilterDrawer = () => {
	const { setFilterSetting, isHydrationCompleted } = useFilters();

	useEffect(() => {
		if (isHydrationCompleted) {
			setFilterSetting(
				TYPES,
				MAP_TYPE_OPERATORS,
				MAP_TYPE_COMPONENT,
				{},
				null,
				COLUMNS,
				NOTES_FILTER_ID,
				NOTES_MODULE_ID,
				true,
				{},
				NOTES_PAGE_SIZE
			);
		}
	}, [isHydrationCompleted]);
};
