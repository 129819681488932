import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./toggle-button.scss";

const ToggleButton = props => {
	const { name, style, checked, onChange, className, disabled } = props;
	return (
		<div className={className} style={style}>
			<Toggle
				id={name}
				checked={checked}
				icons={false}
				onChange={onChange}
				name={name}
				disabled={disabled}
				{...props}
			/>
		</div>
	);
};

ToggleButton.propTypes = {
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired
};

export default ToggleButton;
