import React, { useState, memo } from "react";
import styles from "./styles.module.scss";
import get from "lodash/get";
import { ReactComponent as BookmarkIcon } from "static/icons/Iconcriteria.svg";
import { ReactComponent as BinIcon } from "static/icons/bin.svg";
import { ReactComponent as ClearIcon } from "static/icons/clear_icon.svg";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { SOURCES } from "modules/SearchCandidate/utils/constants";
import {
	getSourceByKey,
	isEmpty
} from "common/SmartFilterDrawer/utils/render_utils";
import FilterPill from "common/FilterPill/FilterPill.js";
import loadable from "loadable-components";

const SaveModal = loadable(() =>
	import("modules/SearchCandidate/components/SaveModal/SaveModal")
);
const ClearModal = loadable(() =>
	import("modules/SearchCandidate/components/ClearModal/ClearModal")
);
const DeleteModal = loadable(() =>
	import(
		"modules/SearchCandidate/components/CommunityCriteria/DeleteModal/DeleteModal"
	)
);

const FiltersBar = ({
	filters,
	module_id,
	selectedCriteria,
	source,
	canSave = true,
	clearFilters
}) => {
	const [showSaveModal, setShowSaveModal] = useState(false);
	const [showClearModal, setShowClearModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const {
		handleFilterDelete,
		setSource,
		setShowDrawer,
		setSelectedFilter
	} = useFilters();

	const deleteFilterHandler = id => {
		if (id === "data_source") {
			setSource("all");
		} else handleFilterDelete(id);
	};

	const displayModalHandler = type => {
		switch (type) {
			case "save":
				setShowSaveModal(true);
				break;
			case "clear":
				setShowClearModal(true);
				break;
			case "delete":
				setShowDeleteModal(true);
				break;
		}
	};

	const onShowDrawer = () => {
		setShowDrawer(true);
	};

	const onClickFilterPill = filter => {
		setSelectedFilter(get(filter, "id", ""));
		setShowDrawer(true);
	};

	return (
		<>
			<div className={styles.filters_bar}>
				<div className={styles.scrollContainer} onClick={onShowDrawer}>
					<OverlayScrollbarsComponent style={{ maxWidth: "100%" }}>
						{source !== "all" && (
							<div className={styles.pill_container}>
								<FilterPill
									module_id={module_id}
									filter={{
										name: "data_source",
										label: "Search on",
										operator: "",
										value: get(getSourceByKey(SOURCES, source), "label", "")
									}}
									removable
									onRemove={() => deleteFilterHandler("data_source")}
								/>
							</div>
						)}
						{filters.map((filter, index) => {
							if (!isEmpty(filter.value)) {
								return (
									<div key={index} className={styles.pill_container}>
										<FilterPill
											module_id={module_id}
											filter={filter}
											removable
											onRemove={deleteFilterHandler}
											onClick={() => onClickFilterPill(filter)}
										/>
									</div>
								);
							}
						})}
					</OverlayScrollbarsComponent>
				</div>

				<div className={styles.btn_container}>
					<div className={styles.rightContainer}>
						<HeaderButton
							icon={<ClearIcon />}
							text={"Clear"}
							className="buttonClear"
							onClick={() => displayModalHandler("clear")}
						/>
						{canSave &&
							(get(selectedCriteria, "_id", "") === "" ? (
								<HeaderButton
									icon={<BookmarkIcon />}
									text={"Save community"}
									className="buttonSave"
									onClick={() => displayModalHandler("save")}
								/>
							) : (
								<HeaderButton
									icon={<BinIcon />}
									text={"Delete"}
									className="btnDelete"
									onClick={() => displayModalHandler("delete")}
								/>
							))}
					</div>
				</div>
			</div>

			{showSaveModal && (
				<SaveModal filters={filters} onClose={() => setShowSaveModal(false)} />
			)}

			{showClearModal && (
				<ClearModal
					onClose={() => setShowClearModal(false)}
					clearFunction={clearFilters}
				/>
			)}

			{showDeleteModal && (
				<DeleteModal
					criteria={selectedCriteria}
					onClose={() => setShowDeleteModal(false)}
				/>
			)}
		</>
	);
};

export default memo(FiltersBar);
