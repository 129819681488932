import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";
import fakeData from "modules/user-onboarding/mockData/email-autocomplete-participants.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getUserEmail = body => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body
	});
};

const USERS_EMAIL_KEY = "USERS_EMAIL_KEY";

const useGetUserEmail = (body, options) => {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery([USERS_EMAIL_KEY, body], () => {
			return Promise.resolve(fakeData);
		});
	}

	return useQuery([USERS_EMAIL_KEY, body], () => getUserEmail(body), {
		refetchOnWindowFocus: false,
		...options
	});
};

export default useGetUserEmail;
