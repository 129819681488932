import React from "react";
import icon from "static/icons/map-icon.svg";
import styles from "./empty-search-card.module.scss";

const EmptySearchCard = () => {
	return (
		<div className={styles.container}>
			<img src={icon} className={styles.icon} />
			<div className={styles.title}>Find a location</div>
			<div className={styles.body}>
				Start your search by entering a <span>location.</span>
			</div>
		</div>
	);
};

export default EmptySearchCard;
