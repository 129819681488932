import create from "zustand";
import produce from "immer";
import { operators } from "../utils/constants";
import { v4 as uuid } from "uuid";
import { schemValidator } from "../utils/helper";

const useLanguagesSchema = create(set => ({
	languagesSchema: [],
	activeIndex: null,
	setLanguagesSchema: languagesSchema => {
		set({
			languagesSchema
		});
	},
	setActiveIndex: activeIndex => {
		set({ activeIndex });
	},
	addItemToSchema: operator => {
		set(
			produce(draft => {
				if (draft.activeIndex !== null) {
					let temp = operator;
					if (temp.language) {
						temp["proficiency"] = temp.proficiency || 3;
					}
					draft.languagesSchema.splice(draft.activeIndex + 1, 0, temp);
				} else if (operator.language) {
					draft.languagesSchema.push({
						...operator,
						proficiency: operator.proficiency || 3
					});
				} else {
					draft.languagesSchema.push(operator);
				}

				draft.activeIndex = null;
			})
		);
	},
	setHasError: arrayOfIndex => {
		set(
			produce(draft => {
				draft.languagesSchema.forEach(e => {
					if (e.hasError) e["hasError"] = false;
				});
				arrayOfIndex.forEach(i => {
					draft.languagesSchema[i]["hasError"] = true;
				});
			})
		);
	},
	setOperator: (operatorId, index) => {
		set(
			produce(draft => {
				let selectedOperator = operators.find(e => e._id === operatorId);
				draft.languagesSchema[index] = { id: uuid(), ...selectedOperator };
				let newList = schemValidator(draft.languagesSchema);
				draft.languagesSchema = newList;
			})
		);
	},
	setLanguageRating: (languageId, rating) => {
		set(
			produce(draft => {
				const index = draft.languagesSchema.findIndex(
					({ id }) => languageId === id
				);
				draft.languagesSchema[index]["proficiency"] = rating;
			})
		);
	},
	deletItemSchema: id => {
		set(
			produce(draft => {
				const index = draft.languagesSchema.findIndex(e => e.id === id);
				draft.languagesSchema.splice(index, 1);
			})
		);
	},
	clearQueryLanguage: () => {
		set({
			languagesSchema: []
		});
	}
}));

export default useLanguagesSchema;
