import React, { memo, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogTitle
} from "../../CreateNewSupplier/Dialog";
import s from "../../CreateNewSupplier/create-supplier.module.scss";
import Select from "../../../shared/Select";
import styles from "./languages-filter-dialog.module.scss";
import useGetLanguages from "../../../api/filters/useGetLangauges";

function LanguagesFilterDialog({ isOpen, onClose, store }) {
	const { data } = useGetLanguages();
	const { setLanguages, languages } = store(state => ({
		setLanguages: state.setLanguages,
		languages: state.languages
	}));

	const { handleSubmit, control, reset } = useForm({
		shouldUnregister: true,
		defaultValues: {
			languages
		}
	});

	useEffect(() => {
		reset({ languages });
	}, [reset, languages]);

	const onSubmit = fields => {
		setLanguages(fields.languages);
		onClose();
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent onEscapeKeyDown={onClose}>
				<DialogTitle>Filter by Languages</DialogTitle>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name={"languages"}
						render={({ field: { onChange, value } }) => (
							<Select
								value={value}
								closeOnSelect={false}
								onChange={onChange}
								options={
									data
										? data.map(l => ({ label: l.language, value: l._id }))
										: []
								}
							/>
						)}
					/>
					<div className={s.dialogFooter}>
						<button onClick={onClose} className={s.cancelButton}>
							Cancel
						</button>
						<button type={"submit"} className={s.mainButton}>
							{!languages.length ? "Add to filters" : "Update filter"}
						</button>
					</div>
				</form>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default memo(LanguagesFilterDialog);
