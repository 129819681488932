import { usePaginatedQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_TASKS } from "config/api-endpoints";

export const myTaskListKey = "@tasks/my_tasks";

const getTasks = data => {
	return client(LIST_TASKS, {
		body: { ...data }
	});
};

const useGetTasks = (options, queryOptions = {}) =>
	usePaginatedQuery([myTaskListKey, options], () => getTasks(options), {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
export default useGetTasks;
