import React from "react";
import styles from "./profile-view-evaluations-summary-card.module.scss";
import ProfileViewEvaluationSummaryCardInfos from "../ProfileViewEvaluationSummaryCardInfos/ProfileViewEvaluationSummaryCardInfos";

const ProfileViewEvaluationSummaryCard = ({ vacancy, children, onClick }) => {
	return (
		<button className={styles.summaryCard} onClick={onClick}>
			<ProfileViewEvaluationSummaryCardInfos vacancy={vacancy} />
			{children}
		</button>
	);
};

export default ProfileViewEvaluationSummaryCard;
