import React from "react";
import { Item as TabItem } from "@react-spectrum/tabs";
import { CreateVacancySkeletonCard } from "../CreateVacancySkeletonCard";
import { CreateVacancyCard } from "../CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import styles from "./add-vacancy-description-card-loading.module.scss";
import { CreateVacancyCardTitle } from "../CreateVacancyCardTitle";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import { Tabs } from "common/Tabs";

const AddVacancyDescriptionCardLoading = () => {
	const tabsList = [
		{
			id: 1,
			header: <CreateVacancySkeletonCard height={24} width={83} />
		}
	];

	return (
		<CreateVacancyCard className={styles.card}>
			<CreateVacancyCardTitle>Description</CreateVacancyCardTitle>
			<CreateVacancyCardContent className={styles.cardContent}>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Version</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<CreateVacancySkeletonCard />
						<CreateVacancySkeletonCard width={77.47} />
					</div>
				</CreateVacancyFieldContainer>
				<Tabs
					rootClassName={styles.tabsRoot}
					headerClassName={styles.tabsHeader}
					items={tabsList}
					tabsPanelClassName={styles.tabPanels}
				>
					<TabItem key={1}>
						<div className={styles.descriptionForm}>
							<CreateVacancyFieldContainer>
								<CreateVacancyLabelContainer>
									<CreateVacancyFieldLabel>Job title</CreateVacancyFieldLabel>
									<CreateVacancyInfoIcon>
										The title should be as explicit and objective as possible
										regarding the actual job title that the person selected will
										have within your company.
									</CreateVacancyInfoIcon>
								</CreateVacancyLabelContainer>
								<CreateVacancySkeletonCard />
							</CreateVacancyFieldContainer>
							<CreateVacancyFieldContainer>
								<CreateVacancyLabelContainer>
									<CreateVacancyFieldLabel>
										Job description
									</CreateVacancyFieldLabel>
									<CreateVacancyInfoIcon>
										A general description of the vacancy. Feel free to detail
										the function itself, the working environment, or even the
										soft skills needed.
									</CreateVacancyInfoIcon>
								</CreateVacancyLabelContainer>
								<CreateVacancySkeletonCard height={215} />
							</CreateVacancyFieldContainer>
							<CreateVacancyFieldContainer>
								<CreateVacancyFieldContainer>
									<CreateVacancyLabelContainer>
										<CreateVacancyFieldLabel>
											Profile description
										</CreateVacancyFieldLabel>
									</CreateVacancyLabelContainer>
								</CreateVacancyFieldContainer>
								<CreateVacancySkeletonCard height={215} />
							</CreateVacancyFieldContainer>
						</div>
					</TabItem>
				</Tabs>
			</CreateVacancyCardContent>
		</CreateVacancyCard>
	);
};

export default AddVacancyDescriptionCardLoading;
