import React from "react";
import { Field } from "redux-form";
import FormField from "common/FormField";
import { INPUT_LENGTH } from "config";

export default function StepOne() {
	return (
		<React.Fragment>
			<Field
				name="name"
				label="Business unit name"
				autoComplete="off"
				placeholder="Business unit name"
				maxLength={INPUT_LENGTH}
				component={FormField}
			/>
		</React.Fragment>
	);
}
