import React from "react";

const OnlymeIcon = () => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="40" height="40" rx="20" fill="#F8FAFC" />
			<path
				d="M25 19V16C25 13.2386 22.7614 11 20 11C17.2386 11 15 13.2386 15 16V19M16.8 29H23.2C24.8802 29 25.7202 29 26.362 28.673C26.9265 28.3854 27.3854 27.9265 27.673 27.362C28 26.7202 28 25.8802 28 24.2V23.8C28 22.1198 28 21.2798 27.673 20.638C27.3854 20.0735 26.9265 19.6146 26.362 19.327C25.7202 19 24.8802 19 23.2 19H16.8C15.1198 19 14.2798 19 13.638 19.327C13.0735 19.6146 12.6146 20.0735 12.327 20.638C12 21.2798 12 22.1198 12 23.8V24.2C12 25.8802 12 26.7202 12.327 27.362C12.6146 27.9265 13.0735 28.3854 13.638 28.673C14.2798 29 15.1198 29 16.8 29Z"
				stroke="#334155"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default OnlymeIcon;
