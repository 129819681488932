import Avatar from "common/Avatar";
import React from "react";
import { Box, Flex, Text } from "rebass";
import CircleProgress from "./CircleProgress";
import { Divider } from "common/styled/CommonStyled";

const TimeSheetProgress = ({
	avatar,
	companyName,
	jobTitle,
	startDate,
	endDate,
	missionProgress,
	externalCompany,
	externalManager
}) => {
	return (
		<Flex
			alignItems="center"
			sx={{
				background: "#FFF8F7",
				border: "1px solid #F1F2F5",
				borderRadius: 5,
				padding: "20px",
				gap: "10px",
				width: "350px",
				display: "flex",
				justifyContent: "center"
			}}
		>
			<Box>
				<Flex>
					<Box height="50px" width="50px">
						<Avatar avatar={avatar} name={companyName} size={45} />
					</Box>

					<Box>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "12px",
								lineHeight: "22px",
								color: "#6E7191"
							}}
						>
							{companyName}
						</Text>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "12px",
								lineHeight: "22px",
								color: "#6E7191"
							}}
						>
							{jobTitle}
						</Text>
					</Box>
				</Flex>
				{externalCompany && externalManager && (
					<div style={{ width: "100%" }}>
						<Divider style={{ backgroundColor: "#E7DEDD", height: "1px" }} />
						<Flex>
							<Box height="50px" width="50px">
								<Avatar avatar={avatar} name={externalCompany} size={45} />
							</Box>

							<Box>
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "12px",
										lineHeight: "22px",
										color: "#6E7191"
									}}
								>
									{externalCompany}
								</Text>
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "12px",
										lineHeight: "22px",
										color: "#6E7191"
									}}
								>
									{externalManager}
								</Text>
							</Box>
						</Flex>
						<Divider style={{ backgroundColor: "#E7DEDD", height: "1px" }} />
					</div>
				)}

				<Box mt={21}>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: 600,
							fontSize: "11px",
							lineHeight: "22px",
							color: "#6E7191"
						}}
					>
						Start & End date
					</Text>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: 600,
							fontSize: "12px",
							lineHeight: "22px",
							color: "#FD6950"
						}}
					>
						{startDate} {">"} {endDate}
					</Text>
				</Box>
			</Box>
			<Box width="88px" height="88px">
				<CircleProgress value={missionProgress} />
			</Box>
		</Flex>
	);
};

export default TimeSheetProgress;
