import React from "react";
/* TODO: use in next sprint */
// import PlannedInterviewsUpcoming from "./PlannedInterviewsUpcoming/PlannedInterviewsUpcoming"
import PlannedInterviewsHistory from "./PlannedInterviewsHistory/PlannedInterviewsHistory";

export default function VacancyViewPlannedInterviews({ job }) {
	return (
		<div>
			{/* TODO: use in next sprint */}
			{/* <PlannedInterviewsUpcoming /> */}
			<PlannedInterviewsHistory job={job} />
		</div>
	);
}
