export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const LOAD_PROFILE = "LOAD_PROFILE";
export const LOADED_PROFILE = "LOADED_PROFILE";
export const CLEAR_PROFILE_DATA = "CLEAR_PROFILE_DATA";
export const CHANGE_TAB = "CHANGE_TAB";

export const RELOAD_SECTION = "RELOAD_SECTION";
export const RELOADED_SECTION = "RELOADED_SECTION";

export const CHANGE_AVATAR = "CHANGE_AVATAR";
export const WRITE_JOBS = "WRITE_JOBS";

export const UPDATE_SELECTED_LANGUAGES = "UPDATE_SELECTED_LANGUAGES";
export const UPDATE_SELECTED_SKILLS = "UPDATE_SELECTED_SKILLS";
export const PUT_SKILLS = "PUT_SKILLS";
export const PUT_SUGGESTED_SKILLS = "PUT_SUGGESTED_SKILLS";

export const PROCESSED_CV_FILE = "PROCESSED_CV_FILE";
export const UPDATE_IS_PROCESSING = "UPDATE_IS_PROCESSING";
