import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { SEARCH_PROFILE_ENDPOINT } from "config/api-endpoints";
import { SEARCH_POOL } from "config";

const getCandidate = keyword =>
	client(SEARCH_PROFILE_ENDPOINT, {
		body: {
			tag: SEARCH_POOL,
			type: "freelancer",
			keywords: keyword
		}
	});

export const GET_FREELANCERS_QUERY_KEY = "GET_FREELANCERS_QUERY_KEY";

export const useGetFreelancersList = value => {
	const res = useQuery(
		[GET_FREELANCERS_QUERY_KEY, value],
		() => getCandidate(value),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true
		}
	);

	return res;
};
