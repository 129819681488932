import React from "react";
import { useCookies } from "react-cookie";
import {
	YEAR_IN_SECONDS,
	DAY_IN_SECONDS,
	HOUR_IN_SECONDS,
	COOKIE_DOMAIN
} from "config";
import ChromeBanner from "./ChromeBanner";

const cookieName = "hireme-chrome-banner";

const CookieBanner = () => {
	const [cookies, setCookie] = useCookies([cookieName]);

	const onClose = () => {
		setCookie(cookieName, "seen", {
			expires: new Date(
				Date.now() +
					getSecondsUntilExpiration({
						hours: 0,
						days: 5
					}) *
						1000
			),
			domain: COOKIE_DOMAIN,
			path: "/"
		});
	};

	const getSecondsUntilExpiration = expiration => {
		const { hours, days, years } = expiration;
		return (
			years * YEAR_IN_SECONDS + days * DAY_IN_SECONDS + hours * HOUR_IN_SECONDS
		);
	};

	const hasClosed = () => {
		return cookieName in cookies && cookies[cookieName] === "seen";
	};

	const isNotChrome = () => {
		return (
			!/chrome/i.test(window.navigator.userAgent) ||
			/edg/i.test(window.navigator.userAgent)
		);
	};

	return !hasClosed() && isNotChrome() && <ChromeBanner onClose={onClose} />;
};

export default CookieBanner;
