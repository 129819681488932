import React from "react";
import styles from "./styles.module.scss";
import CustomizableModel from "common/CustomizableModel/CustomizableModel";
import ClearButton from "./ClearButton";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";

const ClearModal = ({ onClose }) => {
	const { resetFilters } = useFilters();
	const clearFiltersHandler = () => {
		resetFilters();
		onClose();
	};
	return (
		<CustomizableModel
			title="Clear filters"
			actionButton={<ClearButton onClick={clearFiltersHandler} />}
			onClose={onClose}
		>
			<p className={styles.modal__text}>
				Are you sure you want to clear these filters ?
			</p>
		</CustomizableModel>
	);
};

export default ClearModal;
