import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as AddIcon } from "static/icons/add-field.svg";
import styles from "../../profile.module.scss";

const AddField = ({ label, onClick, disabled }) => {
	return (
		<button onClick={onClick} className={styles.iconBtn} disabled={disabled}>
			<AddIcon />
			<span>{label}</span>
		</button>
	);
};
AddField.prototype = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};
AddField.defaultProps = {
	disabled: false
};
export default AddField;
