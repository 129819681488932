import create from "zustand";

const initialValues = {
	vacancy_start_date: "",
	vacancy_end_date: "",
	request_start_date: "",
	request_end_date: "",
	isSettedDate: false
};
const useOverviewStore = create(set => ({
	...initialValues,
	setItem: (key, value) => set({ [key]: value }),
	resetOverview: () => set({ ...initialValues })
}));

export default useOverviewStore;
