import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { VACANCY_RATIO } from "config/api-endpoints";

export const vacancyRatioKey = "@home/vacancy_ratio";
const vacancyRatio = (key, { start_date, end_date }) =>
	client(VACANCY_RATIO, {
		body: {
			start_date,
			end_date,
			job_type: "vacancy"
		}
	});

const useVacancyRatio = (options, queryOptions = {}) =>
	useQuery([vacancyRatioKey, options], vacancyRatio, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useVacancyRatio;
