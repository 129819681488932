import React from "react";
import "rc-cascader/assets/index.css";
import Cascader from "rc-cascader";
import "./cascaderMenu.css";

export default function CascaderMenu({
	data,
	onSelect,
	defaultText,
	expandTrigger = "hover"
}) {
	return (
		<Cascader options={data} onChange={onSelect} expandTrigger={expandTrigger}>
			<div className="menu-trigger">
				{defaultText} <i className="fa fa-chevron-down" />
			</div>
		</Cascader>
	);
}
