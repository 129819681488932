import React, { useCallback, useState } from "react";
import { Box, Text, Flex } from "rebass";

import { useMutation } from "react-query";
import toaster from "common/Toaster";
import { client } from "lib/api-client";

import Functions from "./functions";
import History from "./history";
import Notes from "./notes";
import PersonalInfo from "./personalInfo";
import Preferences from "./preferences";
import SkillsAndLanguages from "./skillsAndLanguages";
import DragAndDrop from "./DragDrop";
import { ReactComponent as CustomizeDragElements } from "static/icons/button-customize.svg";
import { ReactComponent as EnableDragElements } from "static/icons/enable-drag-drop.svg";
import {
	PERSONAL,
	FUNCTIONS_DROPPABLE,
	SKILLS_LANGUAGES,
	PREFERENCES,
	NOTES,
	HISTORY
} from "config";
import { UPDATE_USER_SETTING } from "config/api-endpoints";

const updateUserSetting = data => {
	return client(UPDATE_USER_SETTING, {
		body: {
			card_labels: data,
			type: "view_candidate_cards_order"
		}
	});
};

const CandidateDetails = ({
	functions,
	preference,
	history = [],
	languages = [],
	skills = [],
	headline,
	setIsOpenModalTab,
	setActiveTab,
	canEdit,
	cardLabels,
	notes = [],
	linkedInProfile,
	mainFunction,
	active_company,
	refetchProfileData,
	isFetching,
	isAddMailToPoke,
	getHistoryLoading,
	getNotesLoading
}) => {
	const [isDrag, setIsDrag] = useState(false);
	const [isSave, setIsSave] = useState(false);
	const [draggedElementOrder, setDraggedElementOrder] = useState(cardLabels);

	const [mutate] = useMutation(updateUserSetting, {
		onSuccess: () => {
			setIsDrag(false);
			setIsSave(false);
			toaster.success("Your order has been successfully saved !");
		},
		onError: error => toaster.danger(error.detail.type)
	});

	const onSave = useCallback(
		() => (isSave ? mutate(draggedElementOrder) : setIsDrag(false)),
		[draggedElementOrder, isSave]
	);
	const enabelDragElements = () => setIsDrag(true);

	return (
		<Box
			sx={{
				width: "390px",
				"@media screen and (min-width: 1600px)": {
					width: "492px"
				}
			}}
		>
			<Flex flexDirection="column" minHeight="800px">
				<Flex justifyContent="space-between" alignItems="center">
					<Text sx={{ fontSize: "16px", color: "#212b36", fontWeight: 600 }}>
						Candidate details
					</Text>
					{isFetching ? (
						<button
							style={{
								background: "transparent",
								border: "none",
								height: "60px",
								width: "60px"
							}}
						>
							<EnableDragElements />{" "}
						</button>
					) : (
						<button
							style={{
								background: "transparent",
								border: "none",
								height: "60px",
								width: "60px"
							}}
							onClick={isDrag ? onSave : enabelDragElements}
						>
							{isDrag ? <CustomizeDragElements /> : <EnableDragElements />}
						</button>
					)}
				</Flex>
				{draggedElementOrder.length > 0 && (
					<DragAndDrop
						setActiveTab={setActiveTab}
						setIsOpenModalTab={setIsOpenModalTab}
						isDrag={isDrag}
						setDraggedElementOrder={setDraggedElementOrder}
						isFetching={isFetching}
						isAddMailToPoke={isAddMailToPoke}
						setIsSave={setIsSave}
						history={history}
						notes={notes}
						getHistoryLoading={getHistoryLoading}
						getNotesLoading={getNotesLoading}
					>
						{draggedElementOrder.map(droppableElement => {
							switch (droppableElement) {
								case PERSONAL:
									return (
										<PersonalInfo
											canEdit={canEdit}
											active_company={active_company}
											headline={headline}
											linkedInProfile={linkedInProfile}
											refetchProfileData={refetchProfileData}
											name="personal"
										/>
									);

								case FUNCTIONS_DROPPABLE:
									return <Functions functions={functions} name="functions" />;

								case SKILLS_LANGUAGES:
									return (
										<SkillsAndLanguages
											skills={skills}
											languages={languages}
											mainFunction={mainFunction}
											name="skills_languages"
										/>
									);

								case PREFERENCES:
									return (
										<Preferences preference={preference} name="preferences" />
									);

								case NOTES:
									return <Notes name="notes" />;

								case HISTORY:
									return <History name="history" />;

								default:
									return <React.Fragment></React.Fragment>;
							}
						})}
					</DragAndDrop>
				)}
			</Flex>
		</Box>
	);
};

export default CandidateDetails;
