export const HISTORY_TYPE = {
	offer_accepted: "#10b373",
	offer_refused: "#b33a22",
	offer_cancelled: "#b33a22",
	offer_sent: "#ffbf64",
	poke: "#ff6849",
	poke_expired: "#212b36",
	poke_cancelled: "#b33a22",
	shortlist: "#fecb26",
	application: "#4836bf",
	Referred: "#6C17D8",
	application_rejected: "#b33a22",
	application_cancelled: "#b33a22",
	interest_declined: "#b33a22",
	interest_confirmed: "#018aee",
	interview_done: "#10b373",
	hired: "#ce31a4",
	vacancy_sent: "#2DA78A",
	internal_note: "#2DA78A",
	interview_sent: "#ffbf64",
	interview_expired: "#f75356",
	interview_planned: "#36ba90",
	interview_refused: "#d2d7db",
	interview_cancelled: "#d2d7db",
	interview_declined: "#ffbf64",
	interview_pending: "#2DA78A",
	application_requalified: "#2DA78A"
};
export const HISTORY_TYPE_LABEL = {
	offer_accepted: "Offer Accepted",
	offer_sent: "Offer Sent",
	offer_refused: "Offer Refused",
	offer_cancelled: "Offer Cancelled",
	poke: "Poke",
	poke_expired: "Poke Expired",
	poke_cancelled: "Poke Cancelled",
	shortlist: "Sourced",
	application: "Application",
	Referred: "Referred",
	application_rejected: "Application Rejected",
	application_cancelled: "Application Cancelled",
	interest_declined: "Interest Declined",
	interest_confirmed: "Interest Confirmed",
	interview_done: "Interview Done",
	hired: "Hired",
	vacancy_sent: "Vacancy Sent",
	internal_note: "Internal Note",
	interview_sent: "Interview Sent",
	interview_expired: "Interview Overdue",
	interview_planned: "Interview Planned",
	interview_refused: "Interview Refused",
	interview_cancelled: "Interview Cancelled",
	interview_declined: "Interview Declined",
	interview_pending: "Interview Pending",
	application_requalified: "Application Requalified"
};

export const hexToRgbA = (hex, opacity) => {
	let c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return (
			"rgba(" +
			[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
			`,${opacity})`
		);
	}
};
