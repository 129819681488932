import { FREELANCER, PERMANENT } from "config";

export const ACTIONS = {
	REPLY: "reply",
	REPLY_TO_ALL: "reply-to-all",
	FORWARD: "forward",
	MARK_AS_UNREAD: "mark-as-unread",
	ARCHIVE: "archive",
	DELETE: "delete"
};

export const FALLBACK_IMG =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/IlIezYAAAAASUVORK5CYII=";

export const CLICKABLE_PROFILE_TYPE = [FREELANCER, PERMANENT];
