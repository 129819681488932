import React from "react";
import { Picker } from "common/Picker";
import { CustomMap } from "common/CustomMap";
import { CustomInput } from "common/CustomInput";
import { OPERATORS } from "common/FilterDrawer/utils";

import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { NumberCustomInput } from "../NumberCustomInput/NumberCustomInput";
import { AmountCustomInput } from "../AmountCustomInput/AmountCustomInput";
import { PickerCustomDropDown } from "../PickerCustomDropDown/PickerCustomDropDown";
import { IsEmptyCustomDropDown } from "../IsEmptyCustomDropDown/IsEmptyCustomDropDown";
import { BooleanCustomDropDown } from "../BooleanCustomDropDown/BooleanCustomDropDown";
import { RemoteEligibilityCustomDropDown } from "../RemoteEligibilityCustomDropDown/RemoteEligibilityCustomDropDown";

import { ReactComponent as TaskIcon } from "static/icons/drawer-requests/task-icon.svg";
import { ReactComponent as EditIcon } from "static/icons/drawer-requests/edit-icon.svg";
import { ReactComponent as ArchiveIcon } from "static/icons/drawer-requests/archive-icon.svg";
import { ReactComponent as PublishIcon } from "static/icons/drawer-requests/publish-icon.svg";
import { ReactComponent as DuplicateIcon } from "static/icons/drawer-requests/duplicate-icon.svg";
import { ReactComponent as UnpublishIcon } from "static/icons/drawer-requests/unpublish-icon.svg";

import {
	UPDATE,
	ADD_TASK,
	PUBLISHED,
	DUPLICATED,
	PAUSE_REQUEST,
	ARCHIVE_REQUEST
} from "config";

export const REQUEST_PAGE_SIZE = 24;
export const REQUESTS_MAX_SELECT = 24;
export const REQUESTS_PAGE_SIZES = [50, 24, 12];
export const REQUESTS_FILTER_ID = "REQUESTS_FILTER_ID";
export const REQUESTS_MODULE_ID = "REQUESTS_MODULE_ID";
export const API_DATA_TYPE = "search_request_temporary_table";

export const DISPLAY_TYPE = { table: "table", card: "card" };
export const STATUS_PILL_CONFIG = {
	draft: {
		label: "Draft",
		color: "#D4D4D4"
	},
	published: {
		label: "Published",
		color: "#04AA46"
	},
	deleted: {
		label: "Archived",
		color: "#334155"
	},
	archived: {
		label: "Archived",
		color: "#334155"
	},
	unpublished: {
		label: "Unpublished",
		color: "#F59E0B"
	}
};

export const REQUESTS_ROWS_ACTIONS = [
	{
		name: "Publish",
		key: PUBLISHED,
		icon: <PublishIcon />
	},
	{
		name: "Unpublish",
		key: PAUSE_REQUEST,
		icon: <UnpublishIcon />
	},
	{
		name: "Edit",
		key: UPDATE,
		icon: <EditIcon />
	},
	{
		name: "Duplicate",
		key: DUPLICATED,
		icon: <DuplicateIcon />
	},
	{
		name: "Add task",
		key: ADD_TASK,
		icon: <TaskIcon />
	},
	{
		name: "Archive",
		key: ARCHIVE_REQUEST,
		icon: <ArchiveIcon />
	}
];

export const TYPES = {
	map: "map",
	text: "text",
	date: "date",
	amount: "amount",
	number: "number",
	boolean: "boolean",
	location_type: "location_type",
	multipleSelect: "multipleSelect",
	multipleSelectCustom: "multipleSelectCustom"
};

export const MAP_TYPES_OPERATORS = {
	[TYPES.map]: [OPERATORS.is],
	[TYPES.boolean]: [OPERATORS.is],
	[TYPES.location_type]: [OPERATORS.is],

	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],

	[TYPES.number]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[TYPES.amount]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],

	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],

	[TYPES.multipleSelect]: [OPERATORS.is, OPERATORS.isNot, OPERATORS.anyOf],
	[TYPES.multipleSelectCustom]: [OPERATORS.is, OPERATORS.isNot, OPERATORS.anyOf]
};

export const MAP_TYPES_COMPONENTS = {
	[TYPES.map]: { default: CustomMap },
	[TYPES.boolean]: { default: BooleanCustomDropDown },
	[TYPES.location_type]: { default: RemoteEligibilityCustomDropDown },

	[TYPES.text]: { default: CustomInput },

	[TYPES.number]: {
		default: NumberCustomInput,
		[OPERATORS.isEmpty.value]: IsEmptyCustomDropDown
	},
	[TYPES.amount]: {
		default: AmountCustomInput,
		[OPERATORS.isEmpty.value]: IsEmptyCustomDropDown
	},

	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	},

	[TYPES.multipleSelect]: { default: Picker },
	[TYPES.multipleSelectCustom]: {
		default: Picker,
		[OPERATORS.is.value]: PickerCustomDropDown
	}
};

export const columnSortMap = {
	_id: "_id",
	request_reference: "reference",
	request: "request_title",
	site_name: "site_id",
	department: "department",
	category: "category",
	sub_category: "subcategory",
	functions: "functions",
	seniority: "seniority",
	skills: "skills",
	languages: "languages",
	contract_type: "contract_type",
	time_commitment: "time_commitment",
	rate_type: "rate_type",
	expected_rate: "expected_rate",
	timesheet_frequency: "timesheet_frequency",
	overtime: "overtime",
	bid_count: "bids",
	interview_count: "interviews",
	contract_count: "contracts",
	mission_count: "missions",
	job_location: "job_location",
	applicant_location: "applicant_location",
	start_date: "start_date",
	end_date: "end_date",
	created_by: "created_by",
	created_on: "created_on",
	request_status: "status",
	steps: "steps",
	remote_eligibility: "remote_eligibility"
};
export const columnFiltersMap = {
	_id: "_id",
	request_reference: "reference",
	request: "request_title",
	site_name: "site_id",
	department: "department",
	category: "category",
	sub_category: "subcategory",
	functions: "functions",
	seniority: "seniority",
	skills: "skills",
	languages: "languages",
	contract_type: "contract_type",
	time_commitment: "time_commitment",
	rate_type: "rate_type",
	expected_rate: "expected_rate",
	timesheet_frequency: "timesheet_frequency",
	overtime: "overtime",
	bid_count: "bids",
	interview_count: "interviews",
	contract_count: "contracts",
	mission_count: "missions",
	job_location: "job_location",
	applicant_location: "applicant_location",
	start_date: "start_date",
	end_date: "end_date",
	created_by: "created_by",
	created_on: "created_on",
	request_status: "status",
	steps: "steps",
	remote_eligibility: "remote_eligibility"
};
export const CHECK_FILTER_COLUMNS = ["request"];
