import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EmptyStateNoteIcon } from "static/images/notes/empty-state-notes.svg";
import style from "./empty-note.module.scss";

const EmptyStateNote = ({ title, message }) => {
	return (
		<div className={style.container}>
			<EmptyStateNoteIcon />
			<p>{title}</p>
			<span>{message}</span>
		</div>
	);
};

EmptyStateNote.prototype = {
	title: PropTypes.string,
	message: PropTypes.string
};
EmptyStateNote.defaultProps = {
	title: "No notes",
	message: "Start by adding a note"
};
export default EmptyStateNote;
