import { CustomDropDown } from "common/CustomDropDown";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as PinIcon } from "static/icons/marker-pin-03.svg";
import { ReactComponent as BuildingIcon } from "static/icons/profile-menu/building.svg";
import { ReactComponent as VideoIcon } from "static/icons/video-icon.svg";
import { ReactComponent as EditIcon } from "static/icons/edit-icon-grey.svg";
import { COMPANY_ADDRESS, SEARCH_ADDRESS, REMOTE } from "config";
import styles from "./vacancy-view-send-offer-location-field.module.scss";
import VacancyViewSendOfferLocationForm from "./VacancyViewSendOfferLocationForm";
import { formatAddressesList } from "../../utils/helpers";
import { Button } from "common/Button";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import useGetCompanyOfficesList from "api/useGetCompanyOfficesList";
import FieldError from "common/MessagingToolNewEmail/components/FieldError";
import { addressObjectToString } from "config/helpers";

const OFFER_LOCATION_TYPES = [
	{
		label: (
			<span className={styles.optionLabel}>
				<BuildingIcon /> Company Address
			</span>
		),
		icon: <></>,
		value: COMPANY_ADDRESS
	},
	{
		label: (
			<span className={styles.optionLabel}>
				<PinIcon />
				Another location
			</span>
		),
		icon: <></>,
		value: SEARCH_ADDRESS
	},
	{
		label: (
			<span className={styles.optionLabel}>
				<VideoIcon className={styles.icon} /> Remote
			</span>
		),
		icon: <></>,
		value: REMOTE
	}
];

const VacancyViewSendOfferLocationField = ({ inputClassName }) => {
	const {
		control,
		watch,
		formState: { errors },
		clearErrors,
		setValue
	} = useFormContext();
	const locationType = watch("location_type");
	const customAddress = watch("custom_address") || {};
	const formattedCustomAddress = addressObjectToString(customAddress);
	const onAddressSave = (value, onChange) => {
		onChange(value);
		setOnEditAddress(false);
	};
	const [onEditAddress, setOnEditAddress] = useState(false);
	const { data: address } = useGetCompanyOfficesList();

	const addressesList = formatAddressesList(address?.offices);
	useEffect(() => {
		const address = addressesList.find(({ label }) => {
			return label === formattedCustomAddress;
		});
		if (address) {
			const value = {
				label: address?.label ?? "",
				value: address.value
			};
			requestAnimationFrame(() => setValue("company_address", value));
		}
	}, [address]);

	useEffect(() => {
		if (errors.custom_address) clearErrors("custom_address");
	}, [locationType]);

	const onAddressInputChange = (value, onChange) => {
		onChange({ label: value, value: null });
	};
	const onAddressSelectionChange = (key, onChange) => {
		const address = addressesList.find(({ value }) => {
			return value === key;
		});
		const value = {
			label: address?.label ?? "",
			value: key
		};

		onChange(value);
	};

	return (
		<div className={styles.container}>
			<Controller
				control={control}
				name="location_type"
				render={({ field: { onChange, value, name } }) => (
					<CustomDropDown
						name={name}
						value={value}
						onChange={value => {
							onChange(value);
							if (value === SEARCH_ADDRESS) setOnEditAddress(true);
							else setOnEditAddress(false);
						}}
						options={OFFER_LOCATION_TYPES}
						inputClassName={inputClassName}
						useSvgIcon
					/>
				)}
			/>
			<div className={styles.preview}>
				{locationType === SEARCH_ADDRESS &&
					Object.keys(customAddress).length > 0 &&
					!onEditAddress && (
						<>
							{formattedCustomAddress}
							<Button
								textClassName={styles.editText}
								className={styles.editButton}
								icon={<EditIcon className={styles.penIcon} />}
								variant="text"
								text="edit"
								onClick={() => setOnEditAddress(true)}
							/>
						</>
					)}
			</div>
			{locationType === COMPANY_ADDRESS && (
				<Controller
					control={control}
					name="company_address"
					render={({ field: { value, onChange } }) => (
						<ComboBox
							inputRootClassName={styles.comboxInput}
							inputValue={value?.label}
							selectedKey={value?.value}
							onInputChange={value => onAddressInputChange(value, onChange)}
							onSelectionChange={key => onAddressSelectionChange(key, onChange)}
						>
							{addressesList.map(({ value, label }) => (
								<Item key={value}>{label}</Item>
							))}
						</ComboBox>
					)}
				/>
			)}
			{locationType === SEARCH_ADDRESS && onEditAddress && (
				<div className={styles.customAddress}>
					<Controller
						control={control}
						name="custom_address"
						render={({ field: { value, onChange } }) => (
							<VacancyViewSendOfferLocationForm
								onSave={e => onAddressSave(e, onChange)}
								requiredFields={["street", "number", "city", "zip", "country"]}
								isRequired
								title="Edit Address"
								label="Address details"
								currentAddress={value}
								active={onEditAddress}
								onClose={() => setOnEditAddress(false)}
							/>
						)}
					/>
				</div>
			)}
			<FieldError error={errors.location_type} />
			<FieldError error={errors.custom_address} />
		</div>
	);
};

export default VacancyViewSendOfferLocationField;
