import React from "react";
import { render } from "react-dom";
import { Router, browserHistory } from "react-router";
import { Provider } from "react-redux";
import {
	Provider as SpectrumProvider,
	lightTheme
} from "@adobe/react-spectrum";
import moment from "moment-timezone";
import "moment/locale/en-gb";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import { ThemeProvider } from "styled-components";
import FeaturesLoader from "./config/features";
import theme from "./config/theme";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./styles/css/fonts.css";
import "./styles/css/style.css";
import "./styles/css/hireme/Overlayscrollbars.css";
import "./static/icomoon/style.css";
import "@fontsource/work-sans";
import "@fontsource/work-sans/500-italic.css";

import routes from "./routes";
import store from "./store";
import { TIMEZONE, LOCALSTORAGE_KEY } from "./config";
import { getTermsAndConditionsRoute } from "./common/termsAndConditions/components/TermsAndConditionsContainer";
import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS
} from "./modules/user/actions/actionTypes";
import { AVAILABLE_FEATURES } from "./config/features/actions";
import { getLocalUser } from "./config/helpers";
import { getSignInRoute } from "modules/user/components/Authentication/Login";

moment.tz.setDefault(TIMEZONE);

window.moment = moment; // add to window for global use

const features = JSON.parse(localStorage.getItem(AVAILABLE_FEATURES));
let duration = 2;

if (window.Cypress) {
	window.store = store;
}

if (features) {
	const now = window.moment();
	const timestamp = window.moment(features.timestamp);
	duration = window.moment.duration(now.diff(timestamp)).asHours();
}

browserHistory.listen(({ pathname }) => {
	if (pathname !== getTermsAndConditionsRoute()) window.scroll(0, 0);
});

window.addEventListener(
	"storage",
	async storageEvent => {
		if (storageEvent.key === LOCALSTORAGE_KEY) {
			const localStorageUser = getLocalUser();
			if (localStorageUser) {
				const value = storageEvent.newValue;
				const type = value ? LOGIN_SUCCESS : LOGOUT_SUCCESS;
				store.dispatch({
					type,
					data: JSON.parse(value)
				});
				if (!value) {
					window.location.href = getSignInRoute();
				}
			}
		}
	},
	false
);

render(
	<Provider store={store}>
		<SpectrumProvider theme={lightTheme} colorScheme="light">
			{(!features || duration > 12) && <FeaturesLoader />}
			{features && duration <= 12 && (
				<ThemeProvider theme={theme}>
					<Router history={browserHistory} routes={routes} />
				</ThemeProvider>
			)}
		</SpectrumProvider>
	</Provider>,
	document.getElementById("root")
);
