import React from "react";
import { browserHistory } from "react-router";
import {
	TableWrapper,
	Thead,
	Trthead,
	Th,
	SupplierList,
	SuppliersBody,
	Tr,
	Td
} from "./styled";
import { MAX_SUPPLIER_DISPLAY } from "config";
import EmptyComponent from "common/EmptyComponent";
import LineProgress from "common/progress/LineProgress";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const TableSupplier = ({
	supplier,
	setSupplierData,
	jobsPerformanceData,
	isFetching
}) => {
	const suppliersValue = ["all", "tier 1", "tier 2", "tier 3"];
	const hasSupplier = !(suppliersValue.indexOf(supplier?.value) !== -1);

	if (jobsPerformanceData.length === 0 && !isFetching)
		return <EmptyComponent title={`No data found`} />;

	return (
		<TableWrapper noScroll>
			<SupplierList>
				<Thead>
					<Trthead>
						<Th isNotNumber width="20%">
							{hasSupplier ? "REQUEST TITLE" : "SUPPLIER"}
						</Th>
						{!hasSupplier ? <Th width="15%">WORKABLE REQUEST</Th> : null}
						<Th width="7%">BIDS</Th>
						{!hasSupplier ? (
							<Th width="17%" isNotNumber>
								AVERAGE MATCHING SCORE
							</Th>
						) : null}
						<Th width="12%">INTERVIEWS</Th>
						<Th width="12%">CONTRACT PROP.</Th>
						<Th width="12%">REQUEST FILLED</Th>
					</Trthead>
				</Thead>
			</SupplierList>
			<SuppliersBody
				style={{
					overflow:
						jobsPerformanceData.length <= MAX_SUPPLIER_DISPLAY && "unset"
				}}
				scroll={jobsPerformanceData.length > MAX_SUPPLIER_DISPLAY}
			>
				<SupplierList>
					<tbody>
						{isFetching
							? new Array(8).fill(
									<Tr>
										<Td isNotNumber={true} width="20%">
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={110} height={16} />
											</SkeletonTheme>
										</Td>
										{!hasSupplier ? (
											<Td width="15%">
												{" "}
												<SkeletonTheme
													style={{ borderRadius: 10 }}
													color="#DFE3E8"
												>
													<Skeleton width={25} height={16} />
												</SkeletonTheme>
											</Td>
										) : null}
										<Td width="7%">
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={25} height={16} />
											</SkeletonTheme>
										</Td>
										{!hasSupplier ? (
											<Td width="17%">
												<SkeletonTheme
													style={{ borderRadius: 10, marginBottom: "7px" }}
													color="#DFE3E8"
												>
													<Skeleton width={25} height={16} />
												</SkeletonTheme>
												<SkeletonTheme
													style={{ borderRadius: 10 }}
													color="#DFE3E8"
												>
													<Skeleton width={110} height={7} />
												</SkeletonTheme>
											</Td>
										) : null}
										<Td width="12%">
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={25} height={16} />
											</SkeletonTheme>
										</Td>
										<Td width="12%">
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={25} height={16} />
											</SkeletonTheme>
										</Td>
										<Td width="12%">
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={25} height={16} />
											</SkeletonTheme>
										</Td>
									</Tr>
							  )
							: jobsPerformanceData.map(d => {
									return (
										<Tr key={d._id}>
											<Td
												isNotNumber={true}
												width="20%"
												onClick={
													hasSupplier
														? () =>
																browserHistory.push(
																	`/job/${d._id}?history=%2Fsuppliers-management&hash=suppliers-performance`
																)
														: () =>
																setSupplierData({ label: d.name, value: d._id })
												}
											>
												{d.name}
											</Td>
											{!hasSupplier ? (
												<Td width="15%">{d.num_workable_request}</Td>
											) : null}
											<Td width="7%">{d.num_bid}</Td>
											{!hasSupplier ? (
												<Td width="17%">
													<LineProgress
														strokeColor="#0fc855"
														strokeWidth={2}
														percent={d.average_matching_score}
													/>
												</Td>
											) : null}
											<Td width="12%">{d.num_interview}</Td>
											<Td width="12%">{d.num_contract_proposition}</Td>
											<Td width="12%">
												{!hasSupplier
													? d.num_request_filled
													: d.request_filled
													? "Yes"
													: "No"}
											</Td>
										</Tr>
									);
							  })}
					</tbody>
				</SupplierList>
			</SuppliersBody>
		</TableWrapper>
	);
};

export default TableSupplier;
