import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Link } from "react-router";
import _size from "lodash/size";
import { emailRegex } from "config";
import FormField from "common/FormField";
import {
	checkEmailInvitation,
	checkPhone
} from "../../consultancy/redux/actions";
import { ModalIntro } from "common/modal/ModalStyled";
import PhoneNumberField from "common/PhoneNumberField";
import { isValidPhoneNumber } from "config/validators";
import { getPolicyRoute } from "../../../common/privacyPolicy/components/PolicyContainer";

class InviteUser extends Component {
	verifyInput = ({ email, first_name, last_name, phone }) => {
		const promises = [this.verifyPhone(phone), this.verifyEmail(email)].map(p =>
			p.catch(e => e)
		);
		return Promise.all(promises)
			.then(response => {
				if (response) {
					const errors = response.reduce((acc, val) => {
						if (val && "errors" in val) {
							Object.keys(val.errors).forEach(
								key => (acc[key] = val.errors[key])
							);
						}
						return acc;
					}, {});
					if (_size(errors)) {
						throw errors;
					}
				}
				return this.props.onInvite({
					email,
					first_name,
					last_name,
					phone
				});
			})
			.catch(errors => {
				throw new SubmissionError(errors);
			});
	};

	verifyEmail(email) {
		const { email: propsEmail } = this.props;
		if (propsEmail && propsEmail === email) {
			return Promise.resolve();
		}
		return checkEmailInvitation({ email, reduxFormName: "invite-user" });
	}

	verifyPhone(phone) {
		const { phone: propsPhone, isEdit } = this.props;
		if (!isEdit || (propsPhone && propsPhone === phone)) {
			return Promise.resolve();
		}
		return checkPhone({
			phone: phone.formattedValue,
			reduxFormName: "invite-user"
		});
	}

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	render() {
		const { handleSubmit, isEdit, phone } = this.props;

		return (
			<form onSubmit={handleSubmit(this.verifyInput)} id="invite-form">
				<ModalIntro>
					Please provide your new user’s basic information to create their
					account.
				</ModalIntro>
				<div className="invite-form">
					<div className="invite-item item-20">
						<Field
							name="first_name"
							position="bottom"
							placeholder="User first name"
							component={FormField}
						/>
					</div>
					<div className="invite-item item-20">
						<Field
							name="last_name"
							position="bottom"
							placeholder="User last name"
							component={FormField}
						/>
					</div>
					<div className="invite-item item-45">
						<Field
							name="email"
							position="bottom"
							placeholder="User email"
							component={FormField}
							normalize={this.normalizeEmail}
						/>
					</div>
					{isEdit && (
						<div className="invite-item item-45">
							<Field
								name="phone"
								position="bottom"
								placeholder="User phone number"
								component={PhoneNumberField}
								validate={isValidPhoneNumber}
								defaultValue={phone}
							/>
						</div>
					)}
					<div className="invite-item item-45">
						<p style={{ fontSize: "14px", fontWeight: "400" }}>
							By submitting the name and email address, and any other personal
							information of your Business Unit Manager, you confirm you have
							obtained their consent to do so or are able to rely on another
							lawful basis to input their personal data. Their information will
							be collected, held, used and disclosed in accordance with our{" "}
							<Link target="_blank" className="link-url" to={getPolicyRoute()}>
								Privacy Policy.
							</Link>
						</p>
					</div>
				</div>
			</form>
		);
	}
}

InviteUser.propTypes = {
	onInvite: PropTypes.func.isRequired
};

const validate = formProps => {
	const errors = {};

	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Email is required";
	} else if (formProps.email && !emailRegex.test(formProps.email)) {
		errors.email = "Email is invalid";
	}

	if (!formProps.first_name || formProps.first_name.trim() === "") {
		errors.first_name = "First name is required";
	}

	if (!formProps.last_name || formProps.last_name.trim() === "") {
		errors.last_name = "Last name is required";
	}

	return errors;
};

export default reduxForm({
	form: "invite-user",
	validate,
	touchOnBlur: false
})(InviteUser);
