import styled from "styled-components";
import Icon from "common/styled/icons";
import { Button } from "common/styled/buttons";
import { colors } from "config/styles";

export const StepsContainer = styled.div`
	width: auto;
	margin-right: auto;
	margin-left: auto;

	.rc-steps {
		.rc-steps-item-error .rc-steps-item-tail:after {
			background-color: #36ba90;
		}
	}
`;

export const CTA = styled(Button)`
	padding: 10px 7px;
	padding-right: 35px;
	padding-left: 35px;
	letter-spacing: 2px;
`;

export const CTAIcon = styled(Icon)`
	position: absolute;
	right: -3px;
	top: 7px;
	font-size: 10px !important;
`;

export const BtnContainer = styled.div`
	float: right;
`;

export const StyledModalTitle = styled.h1`
	padding: 15px 0px 20px 0;
	color: #ff6849;
	font-weight: 300;
`;

export const FloatedBtn = styled(Button)`
	float: right;
	margin-left: 15px;
	width: 175px;
	&.btn-primary-outline {
		background-color: ${colors.white};
		color: ${colors.primaryColor};
		border: 1.5px solid ${colors.primaryColor};
		text-transform: capitalize;
		&:hover {
			color: ${colors.white};
			background-color: ${colors.primaryColor};
		}
	}
`;

export const Profile = styled.div`
	clear: both;
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 35px;
	position: relative;
	overflow: hidden;
	color: #707a82;
`;

export const RadioBtnResult = styled.div`
	margin-bottom: 30px;
`;

export const Message = styled.div`
	font-size: 16px;
	position: relative;
	.title {
		color: ${colors.anchorsColor};
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
	}
	.message-box {
		padding: 20px;
		background-color: ${colors.invitationDescription};
		color: ${colors.inputColor};
		border: 1px solid ${colors.loaderBaseColor};
		font-size: 14px;
		font-weight: 400;
	}
	a {
		color: ${colors.primaryColor};
		font-weight: 500;
	}
	.btn-additional-comment {
		text-align: center;
		margin-bottom: 10px;

		.arrow-down-icon {
			width: 15px;
			height: 15px;
			margin-left: 5px;
		}
		button {
			border-radius: 4px;
			box-shadow: 0 0 7.4px 0.1px rgba(218, 224, 242, 0.48);
			border: solid 1px #efefef;
			background-color: #ffffff;
			margin-top: 0;
			line-height: 36px;
			font-size: 13px;
			font-weight: bold;
			color: #293d58;
			-webkit-transition: all 0.3s ease-out;
			-moz-transition: all 0.3s ease-out;
			-ms-transition: all 0.3s ease-out;
			-o-transition: all 0.3s ease-out;
			transition: all 0.3s ease-out;
			&:hover {
				opacity: 0.7;
			}
			i {
				color: #ff6849;
				margin-left: 16px;
				font-size: 10px;
			}
		}
	}
	.additional-comment {
		position: relative;
		left: -21px;
		border-left: 3px solid ${colors.primaryColor};
		padding-left: 18px;
		width: 106%;
		margin-bottom: 15px;
		p {
			padding-right: 30px;
			color: ${colors.anchorsColor} !important;
			font-weight: 400 !important;
			font-size: 14px !important;
		}
	}
	.interview_accepted {
		border-radius: 4px;
		box-shadow: 0 0 7.4px 0.1px rgba(218, 224, 242, 0.48);
		border: solid 1px #efefef;
		background-color: #ffffff;
		padding: 15px;
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		p {
			color: ${colors.inputColor};
			font-size: 14px;
			font-weight: 400;
			margin: 0 !important;
		}
		.icon_container {
			border-radius: 50%;
			background-color: #deebf7;
			margin-right: 10px;
			height: 38.19px;
			width: 71.5px;

			.interview_icon {
				padding: 0 0 0 8px;
				height: 37px;
				width: 30px;
				path {
					fill: #44b5e7;
				}
			}
		}
	}
`;

export const PreviewActions = styled.div`
	margin-top: 40px;
	float: right;
`;

export const SelectTitle = styled.div`
	display: block;
	font-weight: 500;
	color: #58626b;
	text-transform: uppercase;
	margin-bottom: 5px;
`;

export const ErrorLabel = styled.div`
	color: #f76d75;
	height: auto;
	line-height: 16px;
	min-height: 31px;
	padding: 0px 0 10px 0;
	text-transform: none;
	white-space: normal;
	clear: both;
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
`;

export const InfosText = styled.div`
	color: #3d4b60;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 10px;
`;
