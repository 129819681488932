import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { MISSION_HISTORY } from "config/api-endpoints";

export const MISSION_HISTORY_QUERY_KEY = "MISSION_HISTORY_QUERY_KEY";

const getMissionHistory = id =>
	client(MISSION_HISTORY, {
		body: { id }
	});

const useGetMissionHistory = id => {
	return useQuery(
		[MISSION_HISTORY_QUERY_KEY, { id }],
		() => getMissionHistory(id),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};

export default useGetMissionHistory;
