import { useState, useEffect, useCallback } from "react";

const useWindowSize = () => {
	const windowMatch = media => {
		return window.matchMedia(media).matches;
	};
	const [isMobile, setIsMobile] = useState(windowMatch("(max-width: 600px)"));

	const setSize = useCallback(() => {
		setIsMobile(windowMatch("(max-width: 600px)"));
	}, []);

	useEffect(() => {
		window.addEventListener("resize", setSize);
		setSize();
		return () => window.removeEventListener("resize", setSize);
	}, [setSize]);

	return isMobile;
};

export default useWindowSize;
