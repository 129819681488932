import React from "react";
import styles from "./create-vacancy-card-title.module.scss";

const CreateVacancyCardTitle = ({ children, ...rest }) => {
	return (
		<h2
			data-onboarding-step={rest["data-onboarding-step"]}
			className={styles.container}
		>
			{children}
		</h2>
	);
};

export default CreateVacancyCardTitle;
