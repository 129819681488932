import React from "react";
import styles from "../components/CustomHeader/custom-header.module.scss";
const SixDots = ({ height, width }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={styles.sixDots}
		>
			<path
				d="M6 12.6666H6.0026M6 7.99992H6.0026M10 7.99992H10.0026M10 3.33325H10.0026M6 3.33325H6.0026M10 12.6666H10.0026"
				stroke="black"
				strokeWidth="3"
				strokeLinecap="round"
			/>
		</svg>
	);
};
export default SixDots;
