import React, { useState } from "react";
import { useDebounce } from "react-use";

function SupplierKanbanSearch({ onSearch, search }) {
	const [term, setTerm] = useState(search);
	useDebounce(() => onSearch(term), 500, [term]);
	return (
		<input
			type="text"
			style={{ width: "100%" }}
			onChange={e => setTerm(e.target.value)}
			value={term}
			placeholder={"Type here to filter suppliers..."}
		/>
	);
}

export default SupplierKanbanSearch;
