import React from "react";
import styles from "./request-pipeline-on-site-card.module.scss";
import { ReactComponent as BuildingIcon } from "static/icons/building-2.svg";
import cx from "classnames";

const RequestPipelineOnSiteCard = ({ className }) => {
	return (
		<div className={cx(styles.container, className)}>
			<BuildingIcon />
			<div className={styles.videoCallText}>On-site</div>
		</div>
	);
};

export default RequestPipelineOnSiteCard;
