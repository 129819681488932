import React from "react";
import VisibilityModal from "./ContentVisibilityModal";
import AlertModal from "./components/AlertModal";

export default function ContentVisibilityModal({
	setShowModal,
	setCustomPrivacy,
	setVisibility,
	visibilityData,
	title
}) {
	const [wasEdited, setWasEdited] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);
	return (
		<>
			<VisibilityModal
				title={title}
				onClose={() => {
					if (wasEdited) {
						setShowAlert(true);
						return;
					}
					setShowModal("");
				}}
				setWasEdited={setWasEdited}
				setCustomPrivacy={setCustomPrivacy}
				setVisibility={setVisibility}
				setShowModal={() => setShowModal("")}
				visibilityData={visibilityData}
			/>
			{showAlert && (
				<AlertModal
					onClose={() => setShowAlert(false)}
					onClick={() => {
						setShowModal("");
						setShowAlert(false);
						setWasEdited(false);
					}}
					title="Cancel visibility"
					displayDeleteIcon={false}
					confirmButtonText="Discard"
					textCancelButton="Back to visibility"
					body={
						<>
							<span style={{ fontWeight: "500", wordBreak: "break-word" }}>
								Are you sure you wish to cancel visibility modifications?
							</span>
							<br />
							<span style={{ wordBreak: "break-word" }}>
								By clicking &apos;Cancel or close&apos; you will undo all your
								modifications to the visibility customization.
							</span>
						</>
					}
				/>
			)}
		</>
	);
}
