import React from "react";
import Checkbox from "./../Checkbox/Checkbox";
import styles from "./actions-header.module.scss";

const ActionsHeader = ({ checked, onSelectChange }) => {
	return (
		<div className={styles.container}>
			<Checkbox
				id="select-all"
				checked={checked}
				onChange={() => onSelectChange(!checked)}
			/>
		</div>
	);
};
export default ActionsHeader;
