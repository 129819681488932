import { client } from "lib/api-client";
import {
	CHECK_ACTIVATE_ACCOUNT_ENDPOINT,
	VIEW_CANDIDATE_ENDPOINT,
	CHECK_PHONE,
	UPDATE_INTERNAL_PROFILE_DATA,
	SET_AS_PRIMARY
} from "config/api-endpoints";
import { useQuery } from "react-query";

export const checkActivateAccount = body =>
	client(CHECK_ACTIVATE_ACCOUNT_ENDPOINT, { body });

export const checkPhoneFormat = ({ phone }) =>
	client(CHECK_PHONE, {
		body: {
			phone
		}
	});

export const saveProfile = data =>
	client(UPDATE_INTERNAL_PROFILE_DATA, { body: data });

export const setAsPrimary = data => client(SET_AS_PRIMARY, { body: data });

const getPersonalProfileData = (_, { id, tag }) =>
	client(VIEW_CANDIDATE_ENDPOINT, {
		body: {
			id,
			tag
		}
	});
export const personalProfileKey = "@candidate/personalProfileKey";

const usePersonalProfile = (options, queryOptions = {}) =>
	useQuery([personalProfileKey, options], getPersonalProfileData, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default usePersonalProfile;
