import React, { useEffect } from "react";
import style from "./styles.module.scss";
import TaskItem from "./taskItem/TaskItem";
import Scrollbars from "react-custom-scrollbars";
import useTasksStore from "modules/home/zustand/TasksStore";
import useTasksWp from "modules/home/api/useTasksWp";
import get from "lodash/get";
import Loader from "common/Loader";
import TasksEmptyState from "../emptyState/TasksEmptyState";
import { utcTimeStamp } from "config/helpers";

const TasksItems = ({ tagType, type }) => {
	const {
		tasksStartDate,
		tasksEndDate,
		tasksType,
		setItemTasks
	} = useTasksStore();
	const TOTALTASKSKEY = "totalTasks";
	const { data, isFetching, refetch } = useTasksWp(
		{
			filter_tag: tagType,
			start_date:
				tasksStartDate && tasksStartDate.isValid()
					? utcTimeStamp({
							date: tasksStartDate,
							isStart: true
					  })
					: undefined,
			end_date:
				tasksEndDate && tasksEndDate.isValid()
					? utcTimeStamp({
							date: tasksEndDate,
							isStart: false
					  })
					: undefined
		},
		{
			enabled: tasksType === type,
			onSuccess: res => {
				setItemTasks(TOTALTASKSKEY, res.total);
			}
		}
	);

	useEffect(() => {
		if (!tasksEndDate && !tasksEndDate) {
			refetch();
		}
	}, [tasksStartDate, tasksEndDate]);
	if (isFetching)
		return (
			<div className={style["loader_container"]}>
				<Loader check={false} />{" "}
			</div>
		);
	if (!Boolean(get(data, "tasks.length")))
		return (
			<div className={style["loader_container"]}>
				{" "}
				<TasksEmptyState heading={"you have no tasks for today"} />
			</div>
		);

	return (
		<Scrollbars
			autoHide
			autoHideTimeout={5000}
			autoHideDuration={200}
			autoHeight
			autoHeightMax={380}
		>
			<ul className={style.tasksItems}>
				{get(data, "tasks", []).map(item => (
					<TaskItem key={item.id} task={item} refetch={refetch} />
				))}
			</ul>
		</Scrollbars>
	);
};

export default TasksItems;
