import React, { useMemo } from "react";
import { get } from "lodash";
import { queryCache } from "react-query";
import { listCountKey } from "modules/home/api/useListCount.js";
import HomeTable from "modules/home/components/Temporary/table";
import useTimesheetToReview from "modules/home/api/useTimesheetToReview.js";
import useTemporaryStore from "modules/home/zustand/temporary.store.js";
import { M_Y_FORMAT } from "config";
import { historyPush } from "config/helpers";
import { formatDate } from "common/Functions";
import { RenderDate } from "modules/home/ui";
import { RenderApplicantsName } from "modules/home/components/table/helpers.js";
import { TIMESHEET_TO_REVIEW } from "modules/home/config";

const TimesheetToReview = ({ handleViewAll }) => {
	const { startDate, endDate, temporaryTab } = useTemporaryStore();

	const { data, isLoading } = useTimesheetToReview(
		{
			start_date:
				startDate && startDate.isValid() ? startDate.unix() : undefined,
			end_date: endDate && endDate.isValid() ? endDate.unix() : undefined
		},
		{
			enabled: temporaryTab === TIMESHEET_TO_REVIEW,
			onSuccess: () => {
				queryCache.invalidateQueries(listCountKey);
			}
		}
	);
	const renderApplicantsName = data => (
		<RenderApplicantsName
			name={`${get(data, "cell.row.original.user.first_name")} ${get(
				data,
				"cell.row.original.user.last_name"
			)}`}
			status={get(data, "cell.row.original.user.type")}
		/>
	);

	const columns = useMemo(
		() => [
			{
				Header: "Submission date",
				accessor: "submitted_date",
				Cell: props => <RenderDate date={props.cell.value} />
			},
			{
				Header: "Consultant / Freelancer",
				accessor: "",
				Cell: renderApplicantsName
			},
			{
				Header: "Mission title",
				accessor: "job.title"
			},
			{
				Header: "Month",
				accessor: "month.start_date",
				Cell: props => (
					<div> {formatDate(get(props, "cell.value"), true, M_Y_FORMAT)}</div>
				)
			},
			{
				Header: "Reference",
				accessor: "job.job_ref",
				isNumber: true
			},
			{
				Header: "Departement",
				accessor: "group.name"
			}
		],
		[]
	);
	const buttonAction = () => historyPush("/jobs/list");
	const onRowClick = row =>
		historyPush(`/timesheets/${row.job._id}/${row._id}`);
	return (
		<HomeTable
			isLoading={isLoading}
			columns={columns}
			data={data}
			heading="You have no timesheets for this date"
			buttonLabel="Create request"
			buttonAction={buttonAction}
			onRowClick={onRowClick}
			handleViewAll={handleViewAll}
		/>
	);
};

export default TimesheetToReview;
