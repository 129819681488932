import React, { useState } from "react";
import { Box, Flex, Text } from "rebass";
import ProfileAvatar from "../../../Header/profileAvatar";
import get from "lodash/get";
import { ReactComponent as ClockIcon } from "static/icons/elements-icons-regular-clock.svg";
import EmptyState from "modules/candidate/Profile/components/EmptyState";
import styles from "modules/candidate/components/profileClientView/profileModalTab/components/notes/note.module.scss";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { ReactComponent as Back } from "static/images/notes/goBack.svg";
import { Scrollbars } from "react-custom-scrollbars";
import Note from "modules/candidate/Profile/components/NotesCard/Note";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import EmptyNote from "modules/candidate/Profile/components/NotesCard/components/EmptyNote";
import { HOW_TO_WORK_WITH_NOTES } from "config/onboarding";

const NotesList = ({
	isFetching,
	notes,
	isLoading,
	routeName,
	onSeeMoreClick,
	useScroll = true
}) => {
	const [noteToExpand, setNoteToExpand] = useState("");
	const skeltonData = new Array(3).fill(
		<Flex mb={3} alignItems="flex-start">
			<ProfileAvatar
				backgroundColor="#818a92"
				status={false}
				isFetching={isFetching}
			/>
			<Flex width="100%" justifyContent="space-between">
				<Box ml={2}>
					<Text
						sx={{
							fontSize: "16px",
							color: "#212b36",
							fontWeight: "normal"
						}}
						mb={2}
					>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={60} height={10} />
						</SkeletonTheme>
					</Text>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={100} height={10} />
					</SkeletonTheme>
				</Box>
				<Flex alignItems="center">
					<Box mr={1}>
						<ClockIcon />
					</Box>

					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={40} height={10} />
					</SkeletonTheme>
				</Flex>
			</Flex>
		</Flex>
	);

	const [activeNote, setActiveNote] = useState("");
	const [showMore, setShowMore] = useState(false);
	const renderNotes = activeNote
		? notes.filter(nt => nt._id == activeNote)
		: notes;

	const handleExpandClick = note => {
		setNoteToExpand(note);
	};

	const onBackClick = () => {
		setNoteToExpand("");
	};

	if (isFetching) return <Box overflow="auto">{skeltonData}</Box>;

	if (get(notes, "length", 0) === 0 && !isFetching)
		return (
			<div className={styles.drawer__empty__note}>
				<EmptyNote />
			</div>
		);

	let content = (
		<>
			{noteToExpand && (
				<>
					<HeaderButton
						icon={<Back />}
						text="Back"
						buttonClassName={styles.backButton}
						onClick={onBackClick}
					/>
					<Note showText note={noteToExpand} />
				</>
			)}

			{!isLoading ? (
				renderNotes?.length > 0 ? (
					!noteToExpand &&
					renderNotes.map(note => (
						<Note
							key={note?._id}
							note={note}
							setActiveNote={setActiveNote}
							activeNote={activeNote}
							showMore={showMore}
							setShowMore={setShowMore}
							routeName={routeName}
							onClick={() => handleExpandClick(note)}
						/>
					))
				) : (
					<EmptyState />
				)
			) : (
				<div className={styles.gap}>
					<SkeletonTheme
						style={{ borderRadius: 10, marginRight: 16 }}
						color="#dfe3e8"
					>
						<Skeleton width={200} height={16} />
					</SkeletonTheme>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={400} height={16} />
					</SkeletonTheme>
				</div>
			)}

			{renderNotes.length >= 2 && !noteToExpand && (
				<>
					<div className={styles.section_see_all}>
						<button
							className={styles.btn_see_all}
							onClick={() => {
								onSeeMoreClick();
							}}
							data-onboarding-step={`${HOW_TO_WORK_WITH_NOTES}-2`}
						>
							<svg
								width="1.125rem"
								height="1.125rem"
								viewBox="0 0 1.125rem 1.125rem"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.3332 9.33333V11.2C13.3332 11.9467 13.3332 12.3201 13.1879 12.6053C13.06 12.8562 12.8561 13.0602 12.6052 13.188C12.32 13.3333 11.9466 13.3333 11.1999 13.3333H9.3332M6.66653 2.66667H4.79987C4.05313 2.66667 3.67976 2.66667 3.39455 2.81199C3.14366 2.93982 2.93969 3.1438 2.81186 3.39468C2.66653 3.6799 2.66653 4.05326 2.66653 4.8V6.66667M9.99987 6L13.9999 2M13.9999 2H9.99987M13.9999 2V6M6.6665 13.5H4.79984C4.0531 13.5 3.67973 13.5 3.39452 13.3547C3.14363 13.2268 2.93966 13.0229 2.81183 12.772C2.6665 12.4868 2.6665 12.1134 2.6665 11.3667V10.4333V9.5"
									stroke="#525252"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<span className={styles.textBtn}>See all notes</span>
						</button>
					</div>
					<div className={styles.devider}></div>
				</>
			)}
		</>
	);

	return (
		<Box overflow="auto" id="section__scrollbar">
			{useScroll ? (
				<Scrollbars
					autoHideTimeout={5000}
					autoHideDuration={200}
					autoHeight
					autoHeightMax={730}
					style={{ zIndex: 1, paddingBottom: "1rem" }}
					renderTrackHorizontal={props => (
						<div
							{...props}
							style={{ display: "none" }}
							className="track-horizontal"
						/>
					)}
					renderView={props => (
						<div {...props} style={{ ...props.style, overflowX: "hidden" }} />
					)}
				>
					{content}
				</Scrollbars>
			) : (
				content
			)}
		</Box>
	);
};

export default NotesList;
