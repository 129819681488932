import React from "react";
import styles from "./create-vacancy-field-label.module.scss";

const CreateVacancyFieldLabel = ({ children, displayStar = true }) => {
	return (
		<div className={styles.container}>
			<label className={styles.label}>{children}</label>
			{displayStar && <span className={styles.asterisk}>*</span>}
		</div>
	);
};

export default CreateVacancyFieldLabel;
