import React, { useState } from "react";
import styles from "./email-template-languages-tab.module.scss";
import { ReactComponent as GlobIcon } from "static/icons/email-template/glob.svg";
import { ReactComponent as PlusIcon } from "static/icons/plus.svg";
import { ReactComponent as CloseIcon } from "static/icons/email-template/close.svg";
import {
	MenuTrigger,
	Popover,
	Menu,
	Button,
	MenuItem
} from "react-aria-components";
import Checkbox from "common/Ui/Checkbox";
import upperCase from "lodash/upperCase";
import cn from "classnames";
import loadable from "loadable-components";
import { Header, Description, Footer } from "common/ConfirmationModal";
import { TOUR_EMAIL_TEMPLATES } from "config/onboarding";
const ConfirmationModal = loadable(() => import("common/ConfirmationModal"));

export default function EmailTemplateLanguagesTab({
	versionsField,
	onChange,
	value,
	templateLanguages
}) {
	const [versionToDelete, setVersionToDelete] = useState(null);

	const toggleLanguage = version => {
		if (!versionsField.fields.find(i => i.version === version)) {
			versionsField.append({
				version,
				subject: "",
				content: "",
				placeholders: []
			});
			onChange(version);
		} else {
			setVersionToDelete(version);
		}
	};

	const removeLanguage = version => {
		const draft = [...versionsField.fields];
		const index = versionsField.fields.findIndex(i => i.version === version);
		versionsField.remove(index);
		draft.splice(index, 1);
		onChange(draft?.[0]?.version ?? "");
	};

	const confirmDelete = () => {
		removeLanguage(versionToDelete);
		setVersionToDelete(null);
	};

	return (
		<div className={styles.langTabs}>
			<div className={styles.langDropdownTrigger}>
				<GlobIcon />
				<span>Language</span>
				<MenuTrigger>
					<Button
						type="button"
						className={styles.plusIcon}
						data-onboarding-step={`${TOUR_EMAIL_TEMPLATES}-4`}
					>
						<PlusIcon />
					</Button>
					<Popover className={styles.popover}>
						<Menu onAction={toggleLanguage} className={styles.menu}>
							{Object.keys(templateLanguages).map(version => (
								<MenuItem
									id={version}
									key={version}
									className={styles.menuItem}
								>
									<Checkbox
										isSelected={versionsField.fields.find(
											i => i.version === version
										)}
									/>
									<span>{upperCase(version)}</span>
								</MenuItem>
							))}
						</Menu>
					</Popover>
				</MenuTrigger>
			</div>
			<div className={styles.tabs}>
				{versionsField.fields.map(item => {
					return (
						<div
							key={item._id}
							className={cn(
								styles.tab,
								value === item.version && styles.active
							)}
							onClick={() => onChange(item.version)}
						>
							<span>{upperCase(item.version)}</span>
							{value === item.version && (
								<button
									type="button"
									onClick={() => setVersionToDelete(item.version)}
									className={styles.closeBtn}
								>
									<CloseIcon />
								</button>
							)}
						</div>
					);
				})}
			</div>
			{!!versionToDelete && (
				<ConfirmationModal onClose={() => setVersionToDelete(null)}>
					<Header title="Delete language" />
					<Description>
						<div>
							Are you sure you want to delete&nbsp;
							<span className={styles.versionToDelete}>
								{`"${upperCase(versionToDelete)}"`}&nbsp;
							</span>
							all the content will be lost
						</div>
					</Description>
					<Footer
						onClickLabel="Delete"
						onClose={() => setVersionToDelete(null)}
						onClick={confirmDelete}
					/>
				</ConfirmationModal>
			)}
		</div>
	);
}
