import React from "react";
import "./styles.css";

export default function DoNotShowAgain({ checked, setChecked }) {
	return (
		<div className="wrapper">
			<div className="checkbox checkbox-primary">
				<input
					type="checkbox"
					value={checked}
					checked={checked}
					className="styled"
					name="do-not-show"
					id="do-not-show-again"
					onChange={() => setChecked(!checked)}
				/>
				<label htmlFor="do-not-show">Do not show this again</label>
			</div>
		</div>
	);
}
