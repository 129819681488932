import React from "react";
import _filter from "lodash/filter";
import Box from "modules/home/components/Box.jsx";
import { get } from "lodash";
import _map from "lodash/map";
import {
	HeaderEnd,
	HeaderStart
} from "modules/home/containers/interview/header.jsx";
import Interviews from "modules/home/containers/interview/Container.jsx";
import useInterviewStore from "modules/home/containers/interview/interviewStore.js";
import { historyPush } from "config/helpers";
import { TEMPORARY, PERMANENT } from "config";
import useInterviewsList from "modules/home/api/useInterviewsList.js";
import {
	PERMANENT_INTERVIEWS,
	TEMPORARY_INTERVIEWS
} from "modules/home/config";

const OPTIONS = [
	{ label: "All", value: "" },
	{ label: "Permanent", value: PERMANENT },
	{ label: "Temporary", value: TEMPORARY }
];
const Interview = () => {
	const {
		interview_start_date,
		interview_end_date,
		interview_recipient_type,
		setItemInterview,
		isSettedDate
	} = useInterviewStore();

	const { data, isLoading } = useInterviewsList(
		{
			start_date:
				interview_start_date && interview_start_date.isValid()
					? interview_start_date.unix()
					: undefined,
			end_date:
				interview_end_date && interview_end_date.isValid()
					? interview_end_date.unix()
					: undefined,
			recipient_type: interview_recipient_type.value
		},
		{
			onSuccess: res => {
				const permanentsInterviews = _map(
					_filter(res || [], item => Boolean(item?.poke_id)),
					item => item.poke_id
				);
				const temporaryInterviews = _map(
					_filter(res || [], item => Boolean(item?.bid_id)),
					item => item.bid_id
				);
				setItemInterview(PERMANENT_INTERVIEWS, permanentsInterviews);
				setItemInterview(TEMPORARY_INTERVIEWS, temporaryInterviews);
			}
		}
	);

	const handleViewAll = () => {
		if (get(interview_recipient_type, "value") === PERMANENT)
			return historyPush("/vacancies/list?view=interviews");
		return historyPush("/temp-interviews");
	};
	return (
		<Box
			headerEnd={
				<HeaderEnd
					start_date={interview_start_date}
					end_date={interview_end_date}
					recipient_type={interview_recipient_type}
					setItem={setItemInterview}
					start_date_Key={"interview_start_date"}
					end_date_key={"interview_end_date"}
					handleViewAll={handleViewAll}
					recipient_key="interview_recipient_type"
					options={OPTIONS}
					disabled={!Boolean(interview_recipient_type.value)}
					isSettedDate={isSettedDate}
				/>
			}
			headerStart={
				<HeaderStart title={"Interviews"} count={get(data, "length", 0)} />
			}
		>
			<Interviews data={data} isLoading={isLoading} />
		</Box>
	);
};

export default Interview;
