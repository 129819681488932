import { client } from "lib/api-client";
import { GET_CHANNELS_PERFORMANCE } from "config/api-endpoints";
import { useQuery } from "react-query";

const queryFn = body => client(GET_CHANNELS_PERFORMANCE, { body });

export const REQUEST_PERFORMANCE_DATA_QUERY = "REQUEST_PERFORMANCE_DATA_QUERY";

export const useGetRequestPerformance = (payload, options = {}) =>
	useQuery(
		[REQUEST_PERFORMANCE_DATA_QUERY, payload?.id],
		() => queryFn(payload),
		{
			retry: 1,
			refetchOnWindowFocus: false,
			enabled: !!payload?.id,
			...options
		}
	);
