import {
	TYPE_PDF,
	TYPE_DOC,
	TYPE_DOCX,
	TYPE_PNG,
	TYPE_JPEG,
	TYPE_PJPEG,
	TYPE_XLS,
	TYPE_XLSX,
	TYPE_PPT,
	TYPE_PPTX
} from "config";
import * as yup from "yup";

export const EMPTY_EMAIL = {
	to: [],
	cc: [],
	bcc: [],
	subject: "",
	content: "",
	content_placeholders: [],
	attachments: [],
	scheduled_at: ""
};
export const TO = "to";
export const CC = "cc";
export const BCC = "bcc";

export const ALLOWED_FILES_TYPES = [
	TYPE_PDF,
	TYPE_DOC,
	TYPE_DOCX,
	TYPE_PNG,
	TYPE_JPEG,
	TYPE_PJPEG,
	TYPE_XLS,
	TYPE_XLSX,
	TYPE_PPT,
	TYPE_PPTX
];

export const CONTENT_EDITOR = "content";
export const MIN_SCHEDULE_TIME = 30;
export const DEFAULT_TEMPLATE_VERSION = "en";
export const DEFAULT_TEMPLATE_CATEGORY = "general";
export const MAX_LENGTH_IN_BYTES = 15728640; // 15mb

export const templateSchema = yup.object().shape({
	template_name: yup.string().required("Template name is required!")
});

export const templateCategoryOptions = [{ label: "General", value: "general" }];
