import React, { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { AgTableActionsCell } from "common/AgTable/components/AgTableActionsCell";
import { useGetActions } from "modules/TasksList/hooks/useGetActions";
import { TASKS_LIST_ROWS_ACTIONS } from "modules/TasksList/utils/constant";
import styles from "./tasks-list-select.module.scss";
import { UPCOMING, REMINDER_AFTER, REMINDER_BEFORE } from "config";
import useUpdateReminder from "modules/TasksList/api/useUpdateReminder";
import toaster from "common/Toaster";
import { get } from "lodash";
import { queryCache } from "react-query";
import { GET_TASKS_LIST_QUERY_KEY } from "modules/TasksList/api/useGetTasksList";
import { TASK_REMINDER_OPTIONS_TYPES } from "config";

//Gap between menu and options is 10px
const GAP = 10;

export const TasksListSelectColumn = ({
	onItemClick: onItemClickProp,
	data: task
}) => {
	const root = useRef();
	const [showMenu, setShowMenu] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const { created_by, assigned_user, status, _id } = task;
	const actions = useGetActions({ created_by, assigned_user, status });
	const [updateReminder] = useUpdateReminder({
		onSuccess: () => {
			toaster.success("Reminder has been modified!");
			queryCache.invalidateQueries(GET_TASKS_LIST_QUERY_KEY);
		},
		onError: error => {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					toaster.danger(
						`${name} : ${
							typeof get(errorMessage, name) === "string"
								? get(errorMessage, name)
								: get(errorMessage, name)[0]
						}`
					);
				});
			} else {
				toaster.danger(errorMessage);
			}
		}
	});

	let options = isUpcomingTask() ? REMINDER_BEFORE : REMINDER_AFTER;

	function isUpcomingTask() {
		return status.toLowerCase() === UPCOMING;
	}

	const onItemClick = (...params) => {
		setShowOptions(params[0] === TASKS_LIST_ROWS_ACTIONS.reminder.key);
		setShowMenu(params[0] === TASKS_LIST_ROWS_ACTIONS.reminder.key);
		onItemClickProp(...params);
	};

	const onRemind = value => {
		setShowMenu(false);
		updateReminder({
			id: _id,
			time: value,
			remindingTime: isUpcomingTask()
				? TASK_REMINDER_OPTIONS_TYPES.before
				: TASK_REMINDER_OPTIONS_TYPES.after
		});
	};

	return actions.length > 0 ? (
		<>
			<AgTableActionsCell
				data={task}
				onItemClick={onItemClick}
				actions={actions}
				ref={root}
				onOpen={() => setShowMenu(true)}
				onClose={() => {
					setShowMenu(false);
					setShowOptions(false);
				}}
				show={showMenu}
				menuClassName={styles.root}
			/>
			{showOptions &&
				createPortal(
					<div
						className={styles.options}
						style={{
							left: `${root.current.offsetWidth + GAP}px`,
							top: "0px",
							position: "absolute"
						}}
					>
						{options.map(({ label, value }) => (
							<div
								key={value}
								onClick={() => onRemind(value)}
								className={styles.item}
							>
								{label}
							</div>
						))}
					</div>,
					root.current
				)}
		</>
	) : null;
};
