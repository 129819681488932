import React, { Component } from "react";
import loadable from "loadable-components";
import { browserHistory } from "react-router";
import LocationAutocomplete from "react-google-autocomplete";
import Rcslider from "rc-slider";
import { Scrollbars } from "react-custom-scrollbars";
import _get from "lodash/get";
import AutoComplete from "common/AutoComplete";
import {
	HTTP_POST_METHOD,
	FULL_TIME_LABEL,
	PART_TIME_LABEL,
	LEVEL,
	VACANCY_LEVEL,
	DEFAULT_DISTANCE,
	MINIMUM_DISTANCE
} from "config";
import { LIST_SKILLS } from "config/api-endpoints";
import "styles/css/hireme/rc-slider.css";
import styles from "./side-bar.module.scss";
import CustomHandle from "./CustomHandle";
import axiosInstance from "config/axios";
import Scrubber from "common/Scrubber";
import { notSelectedElements } from "modules/job/selectors";
import { SplitButton, ButtonGroup } from "common/styled/buttons";
import { SplitIcon } from "common/styled/icons";
import { REMOTE_ELIGIBITY_OPTIONS } from "config";
import { HYBRID_OPTION } from "config";
import cx from "classnames";

const Select = loadable(() =>
	import(/* webpackChunkName: "react-select" */ "react-select")
);

class Sidebar extends Component {
	constructor(props) {
		super(props);

		const userDistance = _get(this.props, "user.user_radius");

		this.state = {
			skillsData: [],
			isVisible: false,
			distance: userDistance || MINIMUM_DISTANCE,
			minDistance: MINIMUM_DISTANCE,
			currentDistance: userDistance || DEFAULT_DISTANCE
		};

		this.updateFilter = this.updateFilter.bind(this);
		this.handlePlaceChange = this.handlePlaceChange.bind(this);
		this.handleDistanceChange = this.handleDistanceChange.bind(this);
		this.handleSkillChange = this.handleSkillChange.bind(this);
		this.addLanguage = this.addLanguage.bind(this);
		this.hideMenu = this.hideMenu.bind(this);
		this.scrubberChange = this.scrubberChange.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
	}

	componentDidMount() {
		let {
			filter,
			user,
			term,
			loadLanguages,
			fetchJobs,
			languages
		} = this.props;
		let { currentDistance } = this.state;
		let _filter = { ...filter };
		const filterDistance = _get(filter, "location.distance");
		if (term) {
			_filter.title = term;
		}
		const _lat = _get(filter, "location.lat");
		const _lon = _get(filter, "location.lon");
		_filter.location = {
			distance: filterDistance || `${currentDistance}km`,
			lat: _lat || _get(user, "user_location.latitude", 0),
			lon: _lon || _get(user, "user_location.longitude", 0),
			iso_country: ""
		};

		fetchJobs(_filter);
		if (!languages || !languages.length) {
			loadLanguages();
		}
		document.addEventListener("click", this.hideMenu);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.hideMenu);
	}

	hideMenu(e) {
		if (
			!e.target.classList.contains("lang") &&
			!e.target.classList.contains("option")
		) {
			this.setState({ langFocus: false });
		}
	}

	updateFilter(e) {
		const filter = this.props.filter;
		const { name, value } = e.target;
		const idx = filter[name].indexOf(value);
		if (idx === -1) {
			filter[name].push(value);
		} else {
			filter[name].splice(idx, 1);
		}
		this.props.updateFilter(filter);
	}

	handleSkillsInput = term => {
		if (term === "") {
			this.setState({ skillsData: [] });
			return;
		}
		axiosInstance({
			method: HTTP_POST_METHOD,
			data: JSON.stringify({
				term,
				offset: 0,
				sector_id: _get(this.props, "filter.category._id"),
				is_job_search: true
			}),
			url: LIST_SKILLS
		}).then(result => this.setState({ skillsData: result.data }));
	};

	handlePlaceChange(place) {
		if (!place.geometry) return;
		const filter = { ...this.props.filter };
		let isSendViewport = false;
		const iso_country =
			place.address_components[
				place.address_components.map(item => item.types[0]).indexOf("country")
			].short_name;
		const typesSupportedAutoComplete = [
			"locality",
			"sublocality",
			"postal_code",
			"country",
			"administrative_area_level_1",
			"administrative_area_level_2",
			"administrative_area_level_3"
		];

		typesSupportedAutoComplete.forEach(type => {
			if (place.types.includes(type)) isSendViewport = true;
		});

		if (!filter.location.distance) {
			filter.location = {
				lat: place.geometry.location.lat(),
				lon: place.geometry.location.lng(),
				iso_country,
				viewport: isSendViewport
					? {
							northeast: {
								lat: place.geometry.viewport.getNorthEast().lat(),
								lng: place.geometry.viewport.getNorthEast().lng()
							},
							southwest: {
								lat: place.geometry.viewport.getSouthWest().lat(),
								lng: place.geometry.viewport.getSouthWest().lng()
							}
					  }
					: [],
				distance: `${MINIMUM_DISTANCE}km`
			};
		} else {
			filter.location.lat = place.geometry.location.lat();
			filter.location.lon = place.geometry.location.lng();
			filter.location.iso_country = iso_country;
			filter.location.viewport = isSendViewport
				? {
						northeast: {
							lat: place.geometry.viewport.getNorthEast().lat(),
							lng: place.geometry.viewport.getNorthEast().lng()
						},
						southwest: {
							lat: place.geometry.viewport.getSouthWest().lat(),
							lng: place.geometry.viewport.getSouthWest().lng()
						}
				  }
				: [];
		}

		this.props.updateFilter(filter);
	}

	handleDistanceChange(value) {
		const filter = { ...this.props.filter };
		if (filter.location.lat) {
			filter.location.distance = `${value}km`;
			this.props.updateFilter(filter);
		} else {
			filter.location = {
				distance: `${value}km`,
				lat: 0,
				lon: 0,
				iso_country: ""
			};
			this.props.updateFilter(filter, false);
		}
	}

	scrubberChange(value) {
		if (value === this.state.currentDistance) return;
		this.handleDistanceChange(value);
		this.setState({ currentDistance: value });
	}

	handleSkillChange(skill) {
		const { addedSkills } = this.props;
		const filter = { ...this.props.filter };
		this.props.handleSkillChange(skill, addedSkills, filter);
	}

	handleSkillActiveChange(skill) {
		const { filter, addedSkills } = this.props;
		for (const skl of addedSkills) {
			if (skl.name === skill.name) {
				if (skl.active) {
					filter.skills.splice(filter.skills.indexOf(skill.name), 1);
				} else {
					filter.skills.push(skill.name);
				}
				skl.active = !skl.active;
			}
		}
		this.props.updateSkills(addedSkills);
		this.props.updateFilter(filter);
	}

	handleLangActiveChange(lang) {
		const { filter, addedLanguages } = this.props;
		for (const lng of addedLanguages) {
			if (lang.name === lng.name) {
				if (lng.active) {
					filter.languages.splice(filter.languages.indexOf(lang.name), 1);
				} else {
					filter.languages.push(lang.name);
				}
				lng.active = !lng.active;
			}
		}
		this.props.updateLanguages(addedLanguages);
		this.props.updateFilter(filter);
	}

	addLanguage(lang) {
		const { addedLanguages } = this.props;
		const filter = { ...this.props.filter };
		let found = -1;
		for (const [index, lng] of addedLanguages.entries()) {
			if (lng.name === lang.label) {
				found = index;
				break;
			}
		}
		if (found === -1) {
			filter.languages.push(lang.label);
			addedLanguages.push({ name: lang.label, active: true });
			this.props.updateLanguages(addedLanguages);
			this.props.updateFilter(filter);
		}
		this.setState({ langFocus: false, langName: "" });
	}

	static filterOption(opt, term) {
		if (!term) return true;
		const regex = new RegExp(`\\b${term}`);
		return opt.label.toLowerCase().match(regex) !== null;
	}

	resetFilter() {
		const {
			term,
			resetFilter,
			user: { user_radius }
		} = this.props;
		this._autocomplete.value = "";
		if (term) {
			browserHistory.push("/jobs/search");
		}
		resetFilter("", user_radius || DEFAULT_DISTANCE);
	}

	componentDidUpdate(prevProps) {
		let { filter } = this.props;
		const distance = _get(filter, "location.distance");
		const prevDistance = _get(prevProps, "filter.location.distance");
		if (distance && prevDistance !== distance) {
			this.setState({
				currentDistance: parseFloat(distance.replace("km", ""))
			});
		}
	}

	generateButtons() {
		return [1, 2, 3, 4, 5].map(number => {
			const isSelected =
				this.props.filter.office_days.indexOf(number.toString()) !== -1;
			return (
				<button
					key={number}
					className={cx(styles.numberButton, isSelected && styles.selected)}
					onClick={this.updateFilter}
					name="office_days"
					value={number}
				>
					{number}
				</button>
			);
		});
	}

	render() {
		const {
			filter: { seniority, type, location, remote_eligibility },
			user: { user_location },
			addedSkills,
			addedLanguages,
			languages,
			vacancy
		} = this.props;

		const formattedLanguages = notSelectedElements(
			languages,
			addedLanguages,
			"name",
			"label"
		);

		const { currentDistance, minDistance } = this.state;
		const defaultAddress = location.inputValue
			? location.inputValue
			: user_location && user_location.city && user_location.country
			? `${user_location.city}, ${user_location.country}`
			: "";

		const distance =
			location.distance && location.distance !== "km"
				? parseFloat(location.distance.replace("km", ""))
				: minDistance;
		const filterLevel = vacancy ? VACANCY_LEVEL : LEVEL;
		return (
			<div id="sidebar" className="row">
				<div className="reset-filter pull-right" style={{ marginBottom: 30 }}>
					<ButtonGroup onClick={this.resetFilter}>
						<SplitButton color="primary">Clear filter</SplitButton>
						<SplitIcon icon="icon-retakepic" />
					</ButtonGroup>
				</div>
				<div className="clearfix" />
				<div className="filter">
					<div className="filter-name">Location</div>
					<div className="filter-body">
						<div className="location">
							<LocationAutocomplete
								placeholder="Enter a location"
								ref={ref => {
									if (ref) this._autocomplete = ref.refs.input;
								}}
								onPlaceSelected={this.handlePlaceChange}
								defaultValue={defaultAddress}
								types={["geocode"]}
							/>
						</div>
					</div>
				</div>
				<div className="filter">
					<div className="filter-name">Distance</div>
					<div className="npl col-md-8">
						<Rcslider
							min={minDistance}
							max={1000}
							defaultValue={distance}
							value={currentDistance || DEFAULT_DISTANCE}
							handle={props => (
								<CustomHandle {...props} tipFormatter={value => `${value}km`} />
							)}
							onChange={value => this.setState({ currentDistance: value })}
							onAfterChange={this.handleDistanceChange}
						/>
					</div>
					<div className="scrubber col-md-4">
						<Scrubber
							onAfterChange={this.scrubberChange}
							value={currentDistance || minDistance}
							min={minDistance}
							max={1000}
							debounce={true}
						/>
						km
					</div>
					<div className="clear" />
				</div>
				<div className="filter">
					<div className="filter-name">Skills</div>
					<div className="filter-body">
						<div className="menu-holder">
							<AutoComplete
								keys={{
									value: "_id",
									label: "name"
								}}
								emptyOnSelect={true}
								className="menu"
								showOnFocus={true}
								onChange={this.handleSkillsInput}
								data={this.state.skillsData}
								isVisible={true}
								minCharsToCallAPI="1"
								onSelect={skill => this.handleSkillChange(skill.label)}
							/>
						</div>
						<div className="active-skills">
							<Scrollbars autoHeight>
								<ul className="list-unstyled">
									{addedSkills.map((skill, i) => (
										<li key={i}>
											<div className="checkbox checkbox-primary">
												<input
													type="checkbox"
													className="styled"
													onChange={() => this.handleSkillActiveChange(skill)}
													checked={skill.active}
													id={`skill-${i}`}
												/>
												<label
													className={!skill.active ? "inactive" : ""}
													htmlFor={`skill-${i}`}
												>
													{skill.name}
												</label>
											</div>
										</li>
									))}
								</ul>
							</Scrollbars>
						</div>
					</div>
				</div>
				<div className="filter">
					<div className="filter-name">Seniority</div>
					<div className="filter-body">
						{filterLevel.map(level => (
							<div key={level.value} className="checkbox checkbox-primary">
								<input
									id={level.value}
									type="checkbox"
									onChange={this.updateFilter}
									checked={seniority.indexOf(level.value) !== -1}
									value={level.value}
									className="styled"
									name="seniority"
								/>
								<label htmlFor={level.value}>{level.label}</label>
							</div>
						))}
					</div>
				</div>
				<div className="filter">
					<div className="filter-name">Remote eligibility</div>
					<div className="filter-body">
						{REMOTE_ELIGIBITY_OPTIONS.map(level => (
							<div key={level.value} className="checkbox checkbox-primary">
								<input
									id={level.value}
									type="checkbox"
									onChange={this.updateFilter}
									checked={remote_eligibility.indexOf(level.value) !== -1}
									value={level.value}
									className="styled"
									name="remote_eligibility"
								/>
								<label htmlFor={level.value}>{level.label}</label>
							</div>
						))}
					</div>
				</div>
				{remote_eligibility.includes(HYBRID_OPTION.value) && (
					<div className="filter">
						<div className="filter-name">Days on office</div>
						<div className="filter-body">
							<div
								style={{
									display: "flex",
									justifyContent: "space-around"
								}}
							>
								{this.generateButtons()}
							</div>
						</div>
					</div>
				)}
				{!vacancy && (
					<div className="filter">
						<div className="filter-name">Time commitment</div>
						<div className="filter-body">
							<div className="checkbox checkbox-primary">
								<input
									id="part_time"
									type="checkbox"
									onChange={this.updateFilter}
									checked={type.indexOf("part_time") !== -1}
									value="part_time"
									className="styled"
									name="type"
								/>
								<label htmlFor="part_time">{PART_TIME_LABEL}</label>
							</div>
							<div className="checkbox checkbox-primary">
								<input
									id="full_time"
									type="checkbox"
									onChange={this.updateFilter}
									checked={type.indexOf("full_time") !== -1}
									value="full_time"
									className="styled"
									name="type"
								/>
								<label htmlFor="full_time">{FULL_TIME_LABEL}</label>
							</div>
						</div>
					</div>
				)}
				<div className="filter">
					<div className="filter-name">Languages</div>
					<div className="filter-body">
						<Select
							clearable={false}
							options={formattedLanguages}
							filterOption={Sidebar.filterOption}
							onChange={this.addLanguage}
							placeholder="Select languages"
							className="lang"
						/>
						<div className="active-languages">
							<Scrollbars autoHeight>
								<ul className="list-unstyled">
									{addedLanguages.map((lang, i) => (
										<li key={i}>
											<div className="checkbox checkbox-primary">
												<input
													type="checkbox"
													className="styled"
													onChange={() => this.handleLangActiveChange(lang)}
													checked={lang.active}
													id={`lang-${i}`}
												/>
												<label
													className={!lang.active ? "inactive" : ""}
													htmlFor={`lang-${i}`}
												>
													{lang.name}
												</label>
											</div>
										</li>
									))}
								</ul>
							</Scrollbars>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Sidebar;
