import React from "react";

const TimesheetIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<polygon
				className="notification-icon"
				points="327.1,380.5 173.4,380.5 175.6,305.8 249.6,250.2 320.4,300.7 325.4,310.8 	"
			/>
			<g>
				<path
					className="notification-icon-origin"
					d="M254.7,230c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1
			C252.4,224.9,254.7,227.2,254.7,230L254.7,230z M254.7,230"
				/>
				<path
					className="notification-icon-origin"
					d="M269.8,219.9c0,2.8-2.2,5.1-5.1,5.1c-2.8,0-5-2.3-5-5.1c0-2.8,2.2-5.1,5-5.1
			C267.6,214.8,269.8,217.1,269.8,219.9L269.8,219.9z M269.8,219.9"
				/>
				<path
					className="notification-icon-origin"
					d="M239.5,219.9c0,2.8-2.2,5.1-5,5.1c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.2-5.1,5.1-5.1
			C237.3,214.8,239.5,217.1,239.5,219.9L239.5,219.9z M239.5,219.9"
				/>
				<path
					className="notification-icon-origin"
					d="M224.4,209.8c0,2.8-2.3,5-5.1,5c-2.8,0-5.1-2.2-5.1-5c0-2.8,2.3-5.1,5.1-5.1
			C222.1,204.7,224.4,207,224.4,209.8L224.4,209.8z M224.4,209.8"
				/>
				<path
					className="notification-icon-origin"
					d="M285,209.8c0,2.8-2.3,5-5.1,5c-2.8,0-5.1-2.2-5.1-5c0-2.8,2.3-5.1,5.1-5.1C282.8,204.7,285,207,285,209.8
			L285,209.8z M285,209.8"
				/>
				<path
					className="notification-icon-origin"
					d="M300.2,199.7c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1s2.3-5.1,5.1-5.1
			C297.9,194.6,300.2,196.9,300.2,199.7L300.2,199.7z M300.2,199.7"
				/>
				<path
					className="notification-icon-origin"
					d="M209.2,199.7c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1s2.3-5.1,5.1-5.1
			C207,194.6,209.2,196.9,209.2,199.7L209.2,199.7z M209.2,199.7"
				/>
				<path
					className="notification-icon-origin"
					d="M254.7,209.8c0,2.8-2.3,5-5.1,5c-2.8,0-5.1-2.2-5.1-5c0-2.8,2.3-5.1,5.1-5.1
			C252.4,204.7,254.7,207,254.7,209.8L254.7,209.8z M254.7,209.8"
				/>
				<path
					className="notification-icon-origin"
					d="M254.7,270.4c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1
			C252.4,265.3,254.7,267.6,254.7,270.4L254.7,270.4z M254.7,270.4"
				/>
				<path
					className="notification-icon-origin"
					d="M254.7,285.6c0,2.8-2.3,5-5.1,5c-2.8,0-5.1-2.2-5.1-5c0-2.8,2.3-5.1,5.1-5.1
			C252.4,280.5,254.7,282.7,254.7,285.6L254.7,285.6z M254.7,285.6"
				/>
				<path
					className="notification-icon-origin"
					d="M254.7,300.7c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1
			C252.4,295.6,254.7,297.9,254.7,300.7L254.7,300.7z M254.7,300.7"
				/>
				<path
					className="notification-icon-origin"
					d="M254.7,315.8c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.2-5.1-5.1c0-2.8,2.3-5,5.1-5
			C252.4,310.8,254.7,313.1,254.7,315.8L254.7,315.8z M254.7,315.8"
				/>
				<path
					className="notification-icon-origin"
					d="M254.7,331c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1
			C252.4,326,254.7,328.2,254.7,331L254.7,331z M254.7,331"
				/>
				<path
					className="notification-icon-origin"
					d="M254.7,346.2c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1c0-2.8,2.3-5.1,5.1-5.1
			C252.4,341.1,254.7,343.4,254.7,346.2L254.7,346.2z M254.7,346.2"
				/>
				<path
					className="notification-icon-origin"
					d="M368.1,383.3H353v-11.9h-22.5v-51.6c0-13.6-6.8-26.3-18.1-33.9l-53.6-35.7l53.6-35.7
			c11.3-7.6,18.1-20.3,18.1-33.9v-51.6H353V117h15.1c2.8,0,5.1-2.3,5.1-5.1c0-2.8-2.3-5.1-5.1-5.1h-237c-2.8,0-5.1,2.3-5.1,5.1
			c0,2.8,2.3,5.1,5.1,5.1h15.1v11.9h22.5v51.6c0,13.6,6.8,26.3,18.1,33.9l53.6,35.7l-53.6,35.7c-11.3,7.6-18.1,20.3-18.1,33.9v51.6
			h-22.5v11.9h-15.1c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1h237c2.8,0,5.1-2.3,5.1-5.1C373.2,385.6,370.9,383.3,368.1,383.3
			L368.1,383.3z M192.5,206c-8.5-5.7-13.6-15.2-13.6-25.5v-51.6h141.5v51.6c0,10.3-5.1,19.8-13.6,25.5l-57.1,38.1L192.5,206z
			 M178.9,319.8c0-10.3,5.1-19.8,13.6-25.5l57.1-38.1l57.1,38.1c8.5,5.7,13.6,15.2,13.6,25.5v51.6H178.9V319.8z M178.9,319.8"
				/>
			</g>
		</g>
	</svg>
);
export default TimesheetIcon;
