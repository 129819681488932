import React from "react";
import styles from "modules/home/components/overview/card.module.scss";
import cx from "classnames";

const Card = ({ icon, count, name, colorScheme }) => (
	<div className={cx(styles.container, styles[colorScheme])}>
		<div className={styles.centerItems}>
			{icon}
			<div className={styles.flexColumn}>
				<h5 className={styles.count}>{count}</h5>
				<p className={cx(styles.cardName)}>{name}</p>
			</div>
		</div>
	</div>
);

export default Card;
