import React from "react";
import Portal from "common/Portal";
import { css } from "styled-components";

const HandleScroll = ({ id, children, nodeRect }) => {
	const top = nodeRect.top;
	const left = nodeRect.left;
	return id && top && left ? (
		<Portal
			elementStyle={css`
				position: absolute;
				top: ${top + 5}px;
				left: ${left}px;
				z-index: 9999999;
			`.join("")}
		>
			{children}
		</Portal>
	) : (
		children
	);
};

export default HandleScroll;
