import React from "react";
import _get from "lodash/get";
import { ReactComponent as ErrorIcon } from "static/icons/error-field.svg";
import styles from "./../../profile.module.scss";
import cardStyle from "../EditableSection/editable-section.module.scss";

const LastNameField = ({ register, isEditMode, errors }) => {
	return (
		<div className={styles.personalBox}>
			<div className={styles.personalLabel}>Last name</div>
			<div className={cardStyle.info}>
				<input
					{...register("last_name")}
					type="text"
					disabled={!isEditMode}
					placeholder="James"
				/>
				{_get(errors, "last_name.message") && (
					<div className={styles.error}>
						<ErrorIcon />
						{_get(errors, "last_name.message")}
					</div>
				)}{" "}
			</div>
		</div>
	);
};
export default LastNameField;
