import React from "react";
import styled from "styled-components";

const IconWrapper = styled.div`
	.st0 {
		fill: #dedede;
	}
	.st1 {
		opacity: 0.13;
		fill: #606060;
		enable-background: new;
	}
	.st2 {
		fill: #606060;
	}
	.st3 {
		fill: #f75356;
	}
	.st4 {
		fill: #ffffff;
	}
`;

const ProcessingError = () => (
	<IconWrapper>
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 215.7 170.9"
		>
			<g id="Layer_2"></g>
			<g id="Layer_1">
				<polygon className="st0" points="119.5,33.8 119.5,53.7 139.7,53.7 	" />
				<ellipse className="st1" cx="107.2" cy="147.4" rx="105.9" ry="22.5" />
				<g>
					<path
						className="st2"
						d="M59.8,34.6v103.6c0,2.1,1.7,3.8,3.8,3.8h74.8c2.1,0,3.8-1.7,3.8-3.8V53.5c0,0,0-0.1,0-0.1
			c0-0.2,0-0.4-0.1-0.5c0-0.1,0-0.1-0.1-0.2c-0.1-0.2-0.2-0.4-0.4-0.6L121,31.5c-0.2-0.2-0.4-0.3-0.6-0.4c-0.1,0-0.1,0-0.2-0.1
			c-0.2-0.1-0.4-0.1-0.5-0.1c0,0-0.1,0-0.1,0H63.6C61.5,30.9,59.8,32.5,59.8,34.6z M131,51.4h-7.7c-1,0-1.7-0.8-1.7-1.7V42
			c0-1.5,1.9-2.3,2.9-1.2l7.7,7.7C133.3,49.6,132.5,51.4,131,51.4z M65.6,35h50.1c1,0,1.7,0.8,1.7,1.7v15.1c0,2.1,1.7,3.8,3.8,3.8
			h15.1c1,0,1.7,0.8,1.7,1.7v78.9c0,1-0.8,1.7-1.7,1.7H65.6c-1,0-1.7-0.8-1.7-1.7V36.7C63.9,35.7,64.7,35,65.6,35z"
					/>
					<path
						className="st2"
						d="M146.3,127.3v0.7c0,1,0.8,1.7,1.7,1.7h2.7c2.1,0,3.8-1.7,3.8-3.8V22.3c0-2.1-1.7-3.8-3.8-3.8H75.9
			c-2.1,0-3.8,1.7-3.8,3.8V25c0,1,0.8,1.7,1.7,1.7h0.7c1,0,1.7-0.8,1.7-1.7v-0.7c0-1,0.8-1.7,1.7-1.7h70.7c1,0,1.7,0.8,1.7,1.7v99.5
			c0,1-0.8,1.7-1.7,1.7H148C147,125.5,146.3,126.3,146.3,127.3z"
					/>
					<path
						className="st2"
						d="M78,59.7h46c1,0,1.7,0.8,1.7,1.7v0.7c0,1-0.8,1.7-1.7,1.7H78c-1,0-1.7-0.8-1.7-1.7v-0.7
			C76.3,60.4,77.1,59.7,78,59.7z"
					/>
					<path
						className="st2"
						d="M78,47.3h13c1,0,1.7,0.8,1.7,1.7v0.7c0,1-0.8,1.7-1.7,1.7H78c-1,0-1.7-0.8-1.7-1.7V49
			C76.3,48.1,77.1,47.3,78,47.3z"
					/>
					<path
						className="st2"
						d="M78,72h29.5c1,0,1.7,0.8,1.7,1.7v0.7c0,1-0.8,1.7-1.7,1.7H78c-1,0-1.7-0.8-1.7-1.7v-0.7
			C76.3,72.8,77.1,72,78,72z"
					/>
					<path
						className="st2"
						d="M115.1,72h8.9c1,0,1.7,0.8,1.7,1.7v0.7c0,1-0.8,1.7-1.7,1.7h-8.9c-1,0-1.7-0.8-1.7-1.7v-0.7
			C113.3,72.8,114.1,72,115.1,72z"
					/>
				</g>
				<g>
					<g>
						<g>
							<path
								className="st2"
								d="M89.1,101.2c-0.6-0.6-1.5-0.6-2.1,0l-1.6,1.6C88,94.2,97.1,89.4,105.8,92c2.8,0.9,5.4,2.5,7.4,4.7
					c0.5,0.6,1.4,0.7,2,0.2l0,0c0.7-0.5,0.8-1.5,0.2-2.2c-6.6-7.4-17.9-8.6-26-2.5c-2.9,2.2-5.1,5.2-6.4,8.5c-0.4,1-1.7,1.2-2.4,0.5
					l0,0c-0.6-0.6-1.5-0.6-2.1,0l0,0c-0.6,0.6-0.6,1.5,0,2.1l4.3,4.3c0.6,0.6,1.5,0.6,2.1,0l4.3-4.3
					C89.7,102.8,89.7,101.8,89.1,101.2L89.1,101.2z"
							/>
							<path
								className="st2"
								d="M117.2,107.6l-4.3,4.3c-0.6,0.6-0.6,1.5,0,2.1l0,0c0.6,0.6,1.5,0.6,2.1,0l1.7-1.7
					c-2.6,8.6-11.7,13.5-20.4,10.9c-3-0.9-5.7-2.7-7.7-5c-0.5-0.6-1.4-0.7-2-0.2l0,0c-0.7,0.5-0.8,1.5-0.3,2.2
					c2.9,3.5,7,5.8,11.6,6.5c1,0.2,2.1,0.3,3.1,0.3c8.1,0,15.2-5,18-12.4c0.4-1,1.7-1.3,2.4-0.5l0,0c0.6,0.6,1.5,0.6,2.1,0l0,0
					c0.6-0.6,0.6-1.5,0-2.1l-4.3-4.3C118.7,107,117.7,107,117.2,107.6z M118.1,110.9L118.1,110.9l0.2,0L118.1,110.9z"
							/>
						</g>
					</g>
				</g>
				<circle className="st3" cx="152.4" cy="22" r="20.4" />
				<g>
					<g>
						<g>
							<g>
								<path
									className="st4"
									d="M159.4,27.2c0.3,0.3,0.3,0.7,0,1l-0.8,0.8c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2l-5.2-5.2
						l-5.2,5.2l0,0c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2l-0.8-0.8c-0.3-0.3-0.3-0.7,0-1l5.2-5.2l-5.2-5.2
						c-0.3-0.3-0.3-0.7,0-1l0.8-0.8c0.3-0.3,0.7-0.3,1,0l5.2,5.2l5.2-5.2l0,0c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2
						l0.8,0.8c0.3,0.3,0.3,0.7,0,1l-5.2,5.2L159.4,27.2z"
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</IconWrapper>
);

export default ProcessingError;
