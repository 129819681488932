import React from "react";
import styles from "./phone-input.module.scss";
import ReactPhoneInput from "react-phone-input-2";
import { PHONE_DEFAULT_CODE } from "config";
import { useGetCountryData } from "./useGetCountryData";
import cx from "classnames";
import { formatPhone } from "config/helpers";

const PhoneInput = ({
	value,
	onChange,
	phoneError,
	phoneContainerClassName
}) => {
	useGetCountryData({
		onSuccess: data => {
			if (value?.value) return;
			if (data.error) {
				// third parameter to indicate that the value is only the country code
				onChange(PHONE_DEFAULT_CODE, null, true);
				return;
			}
			onChange(data?.countryNumber, null, true);
		}
	});

	let formattedValue = value?.value
		? formatPhone(value.countryCode, value.value)
		: "";

	return (
		<ReactPhoneInput
			containerClass={cx(styles.phoneContainer, phoneContainerClassName)}
			inputClass={cx(styles.phoneInput, {
				[styles.error]: phoneError
			})}
			dropdownClass={styles.phoneDropDown}
			value={formattedValue}
			placeholder="Enter phone number"
			enableLongNumbers
			excludeCountries={["il"]}
			onChange={(value, countryData) =>
				onChange(value ? `+${value}` : "", countryData?.countryCode)
			}
		/>
	);
};

export default PhoneInput;
