import { useMutation } from "react-query";
import { MANAGE_STEP } from "config/api-endpoints";
import { client } from "lib/api-client";

const deleteStep = body => {
	return client(MANAGE_STEP, {
		body
	});
};

export const useDeleteTempPiplineStep = options => {
	return useMutation(deleteStep, options);
};
