import React from "react";
import _get from "lodash/get";
import { Box } from "rebass";
import { stringify } from "query-string";
import {
	PropositionBox,
	Time,
	Title,
	Content,
	CancelledBlock,
	Backdrop,
	CancelledTitle,
	CancelledHelp,
	IconHolder,
	Icon,
	UserSender,
	AvatarHolder,
	InterviewWrapper,
	UserSenderName,
	VideoBlock,
	FlexBoxWrapper,
	BoxTitle,
	BoxContent,
	BoxInterview,
	DetailInterview
} from "./Styled";
import { formatDate } from "common/Functions";
import {
	TIME_FORMAT_SYMBOL,
	DATA_MMMM_D_YYYY,
	CLIENT,
	CANCELLED,
	ACCEPTED,
	REFUSED,
	TWO_HUNDRED,
	DONE,
	VIDEO_CONFERENCE,
	CONFIRMED
} from "config";
import AddressComposer from "common/AddressComposer";
import Description from "common/Description";
import GlobalTooltip from "common/GlobalTooltip";
import Avatar from "common/Avatar/index";
import { OpenVirtualRoomButton } from "common/OpenVirtualRoomButton";
import videoIcon from "static/icons/videocall.svg";
import { useBounds } from "hooks";
import StatusLabel from "common/StatusLabel";
import ContactPerson from "common/contactPerson";
import { ReactComponent as CalendarIcon } from "static/icons/calendar_icon.svg";
import { ReactComponent as LocationIcon } from "static/icons/location-icon.svg";
import { ReactComponent as MessageIcon } from "static/icons/message_icon.svg";
import { historyPush } from "config/helpers";
import { getRoute as getVirtualRoomRoute } from "modules/WhereByVirtualRoom";

const InterviewDetails = ({
	data,
	user,
	userTarget,
	maxWidth,
	getInterviewStatusTooltip,
	visible_status,
	hasInterview
}) => {
	const hasOldDate = data.old_interview_start_date !== "" && undefined;
	const attendeesUsers = _get(data, "attendees_users");
	const islastUserSenderClient =
		data.last_user_interview_action.type === CLIENT;

	const isClient = user.company_type === CLIENT;

	const isInterview = hasInterview;

	const status = data.status;

	const isDone = status === DONE;

	const clientSender = `${data.user_sender.first_name} ${data.user_sender.last_name}`;

	const lastSender = `${data.last_user_interview_action.first_name} ${data.last_user_interview_action.last_name}`;

	const labeltwo = hasOldDate ? "new proposition" : "proposition";
	const clientInterviewSender =
		user.id === data.user_sender._id ? "Your" : `${clientSender}'s`;

	const label = islastUserSenderClient
		? // if last sender is the client
		  isClient
			? `${clientInterviewSender} ${labeltwo}`
			: `${lastSender}'s ${labeltwo}`
		: // if last sender is the freelancer
		isClient
		? `${lastSender}'s ${labeltwo}`
		: `Your ${labeltwo}`;

	const isInterviewConfirmed = [ACCEPTED, DONE, CONFIRMED].includes(status);
	const isInterviewPlanned = [ACCEPTED].includes(status);
	const isVideoConf = data.location_type === VIDEO_CONFERENCE;
	const isVideoConfOld =
		data.old_interview_address &&
		data.old_interview_address.location_type === VIDEO_CONFERENCE;

	const fullName = `${_get(data, "user_sender.first_name")} ${_get(
		data,
		"user_sender.last_name"
	)}`;

	const [oldInterviewRef, oldInterviewSize] = useBounds();
	const [newInterviewSize] = useBounds();

	const maxHeight = Math.max(
		...[oldInterviewSize?.height, newInterviewSize?.height]
	);

	const first_name = userTarget.first_name;
	const last_name = userTarget.last_name;
	const confirmed = first_name && last_name;
	const reference = userTarget.reference;

	const handleOpenVirtualRoomClick = () => {
		const stringified = stringify({
			roomUrl: data.room_url,
			displayName: `${user.first_name} ${user.last_name}`,
			vacancyName: this.props.vacancyName
		});

		historyPush(`${getVirtualRoomRoute()}?${stringified}`, true);
	};

	return (
		<React.Fragment>
			{isInterviewConfirmed && isVideoConf && data.room_url && (
				<Box
					mb={10}
					sx={{
						borderRadius: "4px",
						boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
						backgroundColor: "rgb(255, 255, 255)"
					}}
				>
					<VideoBlock>
						<OpenVirtualRoomButton onClick={handleOpenVirtualRoomClick} />
					</VideoBlock>
				</Box>
			)}
			<Box
				mb={10}
				sx={{
					borderRadius: "4px",
					boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
					backgroundColor: "rgb(255, 255, 255)",
					position: "relative"
				}}
				p={20}
				className="section"
			>
				{(status === CANCELLED || status === REFUSED) && (
					<CancelledBlock>
						<Backdrop />
						<IconHolder>
							<Icon className="icon-calendarerror" />
						</IconHolder>
						<CancelledTitle>Interview {status}</CancelledTitle>
						<CancelledHelp>
							Your interview with{" "}
							{confirmed ? (
								<>
									<b>
										{isClient
											? `${userTarget.first_name} ${userTarget.last_name}`
											: clientSender}
									</b>{" "}
									has been {status}.
								</>
							) : (
								<>
									<b>{isClient ? `Ref: ${reference}` : clientSender}</b> has
									been {status}.
								</>
							)}
						</CancelledHelp>
					</CancelledBlock>
				)}

				<div className="header">
					<div className="box-label">Interview</div>
					{isInterview && (
						<GlobalTooltip
							placement="top"
							eventTrigger="hover"
							noClassName={true}
							maxWidth={maxWidth}
							overlay={
								<span>
									{!isDone
										? getInterviewStatusTooltip(status, islastUserSenderClient)
										: visible_status}
								</span>
							}
							overlayClassName="g-tool-white"
						>
							<StatusLabel
								status={status}
								isInterview={true}
								label={visible_status}
								px={15}
							/>
						</GlobalTooltip>
					)}
				</div>
				<div className="section-proposition">
					<InterviewWrapper justify={isInterviewConfirmed}>
						{!isInterviewConfirmed && hasOldDate && (
							<PropositionBox maxwidth>
								<div className="title">Old proposition</div>
								<FlexBoxWrapper
									ref={oldInterviewRef}
									height={isNaN(maxHeight) ? "auto" : maxHeight}
								>
									<Box maxwidth left>
										<div className="icon-time-location">
											<i className="icon-inverted-watch" />
										</div>
										<BoxTitle>DATE & TIME</BoxTitle>
										<BoxContent>
											{formatDate(
												data.old_interview_start_date,
												true,
												DATA_MMMM_D_YYYY
											)}
										</BoxContent>
										<Time>
											{formatDate(
												data.old_interview_start_date,
												true,
												TIME_FORMAT_SYMBOL
											)}{" "}
											-{" "}
											{formatDate(
												data.old_interview_end_date,
												true,
												TIME_FORMAT_SYMBOL
											)}
										</Time>
									</Box>

									<Box maxwidth right>
										<div className="icon-time-location">
											<i className="icon-local" />
										</div>
										<BoxTitle>location</BoxTitle>
										{!isInterviewConfirmed && (
											<div>
												{isVideoConfOld ? (
													<div className="video-conf">
														<img
															src={videoIcon}
															alt="camera"
															className="box-camera"
														/>
														<div>
															<div className="text">Video Conference</div>
															<div className="box-text">
																Virtual Meeting Room
															</div>
														</div>
													</div>
												) : (
													<Content location>
														<AddressComposer
															addressData={data.old_interview_address}
															noClass={true}
														/>
													</Content>
												)}
											</div>
										)}
									</Box>
								</FlexBoxWrapper>
							</PropositionBox>
						)}
						<PropositionBox maxwidth fullWidth>
							<div className="title BoxWrapper">
								{isInterviewConfirmed ? " Confirmed date & time" : label}
							</div>
							<div className="BoxWrapper">
								<BoxInterview height="auto" border="none" boxShadow="none">
									<div className="contentBox">
										<h3 className="titleBox">
											<CalendarIcon /> Date & time
										</h3>
										<div className="detailsInterview">
											<span className="dateInterview">
												{formatDate(
													data.new_interview_start_date,
													true,
													DATA_MMMM_D_YYYY
												)}
											</span>
											<span className="timeInterview">
												{formatDate(
													data.new_interview_start_date,
													true,
													TIME_FORMAT_SYMBOL
												)}{" "}
												<i className="icon-arrow-right-new" />{" "}
												{formatDate(
													data.new_interview_end_date,
													true,
													TIME_FORMAT_SYMBOL
												)}
											</span>
										</div>
									</div>
								</BoxInterview>
								<BoxInterview height="auto" border="none" boxShadow="none">
									{!isInterviewConfirmed && (
										<>
											{isVideoConf ? (
												<div className="contentBox videoConference">
													<h3 className="titleBox">
														<LocationIcon />
														Location - video conference
													</h3>
													<span className="video_conf_details">
														Virtual Meeting Room
													</span>
												</div>
											) : (
												<div className="contentBox">
													<h3 className="titleBox">
														<LocationIcon />
														Location
													</h3>
													<Content>
														<AddressComposer
															addressData={data}
															noClass={true}
														/>
													</Content>
												</div>
											)}
										</>
									)}
									{isInterviewConfirmed && (
										<>
											{isVideoConf ? (
												<div className="contentBox videoConference">
													<h3 className="titleBox">
														<LocationIcon />
														Location - video conference
													</h3>
													<span className="video_conf_details">
														Virtual Meeting Room
													</span>
												</div>
											) : (
												<div className="contentBox">
													<h3 className="titleBox">
														<LocationIcon />
														Location
													</h3>
													<Content>
														<AddressComposer
															addressData={data}
															noClass={true}
														/>
													</Content>
												</div>
											)}
										</>
									)}
								</BoxInterview>
								{attendeesUsers && attendeesUsers.length > 0 && (
									<ContactPerson attendeesUsers={attendeesUsers} />
								)}
							</div>
						</PropositionBox>

						{(!attendeesUsers || attendeesUsers.length == 0) && (
							<UserSender>
								<Title>CONTACT PERSON</Title>
								<AvatarHolder>
									<Avatar
										src={_get(data, "user_sender.avatar")}
										name={fullName}
									/>
								</AvatarHolder>
								<UserSenderName>{fullName}</UserSenderName>
							</UserSender>
						)}
						{data.description && !isInterviewPlanned && (
							<div className="descriptionInterview">
								<Title className="box_title">
									<MessageIcon /> Message
								</Title>
								<Content>
									<Description
										parseMarkdown={false}
										description={data.description}
										max={TWO_HUNDRED}
									/>
								</Content>
							</div>
						)}
					</InterviewWrapper>
					{data.description && isInterviewPlanned && (
						<div className="row">
							<DetailInterview>
								<Title className="box_title">
									<MessageIcon /> Message
								</Title>
								<Content color="#202223">
									<Description
										parseMarkdown={false}
										description={data.description}
										max={TWO_HUNDRED}
									/>
								</Content>
							</DetailInterview>
						</div>
					)}
				</div>
			</Box>
		</React.Fragment>
	);
};

InterviewDetails.defaultProps = {
	maxWidth: "400px",
	margin_value: 0,
	userTarget: {}
};

export default InterviewDetails;
