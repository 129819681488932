import axios from "axios";
import { browserHistory } from "react-router";
import _get from "lodash/get";
import {
	API_URL,
	MIME_JSON,
	RESPONSE_CODE_422,
	RESPONSE_CODE_401,
	STATUS_ACTIVATED,
	RESPONSE_NOT_FOUND,
	CLIENT,
	IDENTITY,
	PERMANENT
} from "./index";
import store from "../store";
import toaster from "common/Toaster";
import { logout } from "../modules/user/actions/authActions";
import { getLocalUser } from "config/helpers";

const axiosInstance = axios.create({
	baseURL: API_URL,
	headers: { "content-type": MIME_JSON }
});

axiosInstance.interceptors.request.use(
	config => {
		const user = getLocalUser();
		if (user) {
			config.headers.common["Authorization"] = `Bearer ${user.access_token}`;
		}
		return config;
	},
	error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
	response => {
		const user = getLocalUser();
		const location = window.location.pathname;
		if (
			user &&
			user.company_status !== STATUS_ACTIVATED &&
			user.type !== PERMANENT &&
			location.indexOf("company") < 0 &&
			location.indexOf("policies") < 0
		) {
			const isClient = user.company_type === CLIENT;
			const step = _get(user.company_status.split("_"), "1", IDENTITY);
			if (!isClient) {
				browserHistory.push(`/company/${step}`);
				return Promise.reject("you don't have access to this page yet");
			}
		}
		return response;
	},
	error => {
		if (error.response && error.response.status === RESPONSE_CODE_422) {
			if (typeof error.response.data.detail === "string") {
				if (error.response.config.url.indexOf("vat-checker") === -1) {
					let message = "";
					try {
						message = JSON.parse(error.response.data.detail);
					} catch (e) {
						message = error.response.data.detail;
					}
					toaster.danger(message, { duration: 10 });
				}
				return Promise.reject(error);
			} else {
				return Promise.reject(error.response.data.detail);
			}
		} else if (error.response && error.response.status === RESPONSE_CODE_401) {
			store.dispatch(logout());
		} else if (error.response && error.response.status === RESPONSE_NOT_FOUND) {
			browserHistory.push("/404");
		} else {
			return Promise.reject(error);
		}
	}
);

export default axiosInstance;
