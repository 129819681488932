import create from "zustand";

const useShowAddNewPipelineBidStepDrawer = create(set => ({
	show: false,
	setShowAddNewStepDrawer: value => {
		set({
			show: value
		});
	}
}));

export default useShowAddNewPipelineBidStepDrawer;
