import React from "react";

import { AttendeesContainer } from "../styles/styled";
import Description from "common/Description";
import { TWO_HUNDRED } from "config";

const AttendeesList = ({ attendeesUsers, description }) => {
	let attendeesNames = [];
	attendeesUsers.map(attendee => {
		attendeesNames.push(`${attendee.first_name} ${attendee.last_name}`);
	});
	const attendeesString = attendeesNames.join(", ");
	return (
		<AttendeesContainer>
			<div className="section">
				<span className="infos-label">Attendee(s):</span>
				<p>{attendeesString}</p>
			</div>
			{description && description.length !== 0 && (
				<div className="section">
					<span className="infos-label">Description: </span>
					<Description
						description={description}
						parseMarkdown={true}
						showMore
						max={TWO_HUNDRED}
						showMoreText="View more"
					/>
				</div>
			)}
		</AttendeesContainer>
	);
};

export default AttendeesList;
