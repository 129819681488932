import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_SECTORS } from "config/api-endpoints";

function listSectors() {
	return client(LIST_SECTORS, {
		body: {}
	});
}

export const listSectorsKey = "@listSectors";

function useListSectors() {
	return useQuery([listSectorsKey], () => listSectors(), {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity
	});
}

export default useListSectors;
