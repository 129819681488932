import React, { useState } from "react";
import PlanInterview from "./PlanInterview";
import PlannedInterviews from "./PlannedInterviews";
import PlanPhoneScreen from "./PlanPhoneScreen";
import { ProposeInterviewContainer } from "./styles";
import { ReactComponent as BackButton } from "static/icons/back-button-icon.svg";
import { ReactComponent as CalendrierIcon } from "static/icons/interview-calendrier-icon.svg";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore";
import AddMailModal from "./AddMailModal";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";

const ProposeInterview = ({
	isProposeInterview,
	isPhoneScreen,
	companyType,
	candidateName,
	vacancyId,
	profileId,
	isCandidateHasMail,
	activeCompany,
	applicationId,
	isApplicantHasAnOffer,
	interviewId,
	phone,
	interviewStatus,
	isFromVacanciesTable,
	onSuccess
}) => {
	const [submitFromOutside, setSubmitFromOutside] = useState(false);
	const [isLogEvent, setIsLogEvent] = useState(false);
	const [showAddMailModal, setShowAddMailModal] = useState(false);

	const { setDrawerState } = useVacancyStore();
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();

	const closeDrawer = () => {
		if (isFromCardActions) {
			setDrawerState({
				open: false,
				component: ""
			});
		} else {
			setDrawerState({
				open: false,
				component: ""
			});
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(applicationId);
		}
	};

	const logInterviewHandler = () => {
		setIsLogEvent(true);
		setSubmitFromOutside(true);
	};
	const sendInterviewHandler = () => {
		setIsLogEvent(false);
		setSubmitFromOutside(true);
	};

	return (
		<ProposeInterviewContainer>
			<div className="drawer-header">
				<div className="left-side">
					<button onClick={closeDrawer} className="back-button">
						{" "}
						<BackButton />{" "}
					</button>
					<CalendrierIcon style={{ margin: "0px 8px" }} />
					<span className="header-title">
						{isPhoneScreen ? "Phone screen" : "Propose interview"}
					</span>
				</div>
				<div className="right-side">
					<button onClick={logInterviewHandler} className="outlined-btn">
						{isPhoneScreen ? "Log event" : "Save as logged"}
					</button>
					<button onClick={sendInterviewHandler} className="primary-btn">
						{isPhoneScreen ? "Send invitation" : "Propose Interview"}
					</button>
				</div>
			</div>
			<div className="drawer-body">
				{isProposeInterview && (
					<PlanInterview
						isLogEvent={isLogEvent}
						setIsLogEvent={setIsLogEvent}
						candidateName={candidateName}
						vacancyId={vacancyId}
						companyType={companyType}
						closeDrawer={closeDrawer}
						isCandidateHasMail={isCandidateHasMail}
						openAddMailModal={boolean => setShowAddMailModal(boolean)}
						applicationId={applicationId}
						showAddMailModal={showAddMailModal}
						isApplicantHasAnOffer={isApplicantHasAnOffer}
						interviewId={interviewId}
						interviewStatus={interviewStatus}
						submitFromOutside={submitFromOutside}
						setSubmitFromOutside={setSubmitFromOutside}
						isFromVacanciesTable={isFromVacanciesTable}
						onSuccess={onSuccess}
					/>
				)}
				{isPhoneScreen && (
					<PlanPhoneScreen
						isLogEvent={isLogEvent}
						setIsLogEvent={setIsLogEvent}
						candidateName={candidateName}
						vacancyId={vacancyId}
						companyType={companyType}
						closeDrawer={closeDrawer}
						isCandidateHasMail={isCandidateHasMail}
						openAddMailModal={boolean => setShowAddMailModal(boolean)}
						applicationId={applicationId}
						showAddMailModal={showAddMailModal}
						isApplicantHasAnOffer={isApplicantHasAnOffer}
						interviewId={interviewId}
						phone={phone}
						interviewStatus={interviewStatus}
						submitFromOutside={submitFromOutside}
						setSubmitFromOutside={setSubmitFromOutside}
					/>
				)}
				<PlannedInterviews
					profileId={profileId}
					vacancyId={vacancyId}
					candidateName={candidateName}
				/>
			</div>
			{showAddMailModal && (
				<AddMailModal
					profileId={profileId}
					closeModal={() => setShowAddMailModal(false)}
					logInterviewHandler={logInterviewHandler}
					sendInterviewHandler={sendInterviewHandler}
					activeCompany={activeCompany}
					setShowAddMailModal={setShowAddMailModal}
					vacancyId={vacancyId}
					closeDrawer={closeDrawer}
				/>
			)}
		</ProposeInterviewContainer>
	);
};

export default ProposeInterview;
