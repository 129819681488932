import create from "zustand";
import storeUtils from "common/FilterBox/utils";

const initialState = {
	offset: 0,
	op: "and",
	fields: [],
	search: "",
	isTitleSearch: true,
	isDescriptionSearch: true
};

const useFilesStore = create((set, get) => ({
	filters: initialState,
	rows: [],
	limit: 8,
	setLimit: limit => set({ limit }),
	...storeUtils(get, set, initialState)
}));

export default useFilesStore;
