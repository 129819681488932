import { useMutation } from "react-query";
import { CREATE_NEW_JOB } from "config/api-endpoints";
import { client } from "lib/api-client";

const addVacancy = body => {
	return client(CREATE_NEW_JOB, {
		body
	});
};

export const useAddVacancyProfileInformations = () => {
	const res = useMutation(addVacancy);

	return res;
};
