import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import _get from "lodash/get";
import { Field, reduxForm, initialize } from "redux-form";
import Select from "common/StyledSelect";
import {
	LOADING,
	PAYMENTS_TIME,
	PAYMENTS_TYPE,
	PREFERRED_COUNTRIES,
	MAX_SALARY_VALUE
} from "config";
import { client } from "lib/api-client";

import Modal from "common/modal";
import FormField from "common/FormField";
import CurrencyField from "common/NewCurrencyField";
import { SAVE_PREFERENCES_ENDPOINT, GET_COUNTRIES } from "config/api-endpoints";
import toaster from "common/Toaster";
import CountryFlagSelect from "common/CountryFlagSelect";
import AxiosHelper from "config/axios-helper";
import { GET_CURRENCIES } from "config/api-endpoints";
import CurrenciesSymbolSelect from "../../../../common/CurrenciesSymbolSelect";
import CheckBoxInput from "common/CheckBoxInput";
import ExtraBenefits from "./ExtraBenefitsPermanent";
import useReadProfile from "modules/candidate/Profile/api/useReadProfile.js";

const SavePreferences = data => {
	return client(SAVE_PREFERENCES_ENDPOINT, { body: data });
};

const getCountries = () =>
	client(GET_COUNTRIES, {
		body: {
			all: true
		}
	});

const PreferencesModal = ({
	onClose,
	reloadProfile,
	dispatch,
	preferences,
	handleSubmit,
	preferencesCompleted,
	isEmptyLangSkillsFnc,
	redirectToFunctions,
	employmentType,
	handleChangeEmployment,
	change
}) => {
	const employmentTypeData = employmentType.reduce((type, item) => {
		type[item] = item;
		return type;
	}, {});

	const { data } = useReadProfile({});

	const [extraBenifitsItems, setExtraBenifitsItems] = useState([]);

	useEffect(() => {
		setExtraBenifitsItems(
			_get(data, "front_sheet.preference.extra_benefits", [])
		);
	}, [data]);

	useEffect(() => {
		dispatch(
			initialize("preferences-modal", {
				...preferences,
				permanent: Boolean(employmentTypeData.permanent),
				fixed_term: Boolean(employmentTypeData.fixed_term),
				internship: Boolean(employmentTypeData.internship),
				currency_expected: preferences.currency_expected || "EUR",
				payroll_country_expected: preferences.payroll_country_expected
			})
		);
		getdataCurrencies();
	}, []);
	const [currencies, setCurrencies] = useState([]);

	const { data: countries = [], isLoading } = useQuery(
		["getCountries"],
		getCountries,
		{
			refetchOnWindowFocus: false
		}
	);

	const [mutate, { status }] = useMutation(SavePreferences, {
		onSuccess: () => {
			toaster.success("Successfully edited your preferences.");
			reloadProfile();
			onClose();
		},
		onError: e => {
			if (e && e.status === 422) {
				let errorObj = _get(e, "detail.preference[0]");
				if (errorObj) {
					let keys = Object.keys(errorObj);
					toaster.danger(errorObj[keys[0]]);
				}
			}
		}
	});
	const getdataCurrencies = () => {
		AxiosHelper.post({
			url: GET_CURRENCIES,
			data: {}
		}).then(resp => setCurrencies(resp));
	};
	const submit = formProps => {
		mutate({
			preference: {
				expected_salary: formProps.expected_salary || 0,
				expected_salary_advantage: formProps.expected_salary_advantage || "",
				notice_period: formProps.notice_period || "",
				currency_expected: checkTypeOfData(formProps.currency_expected),
				payment_time_expected: checkTypeOfData(formProps.payment_time_expected),
				payment_type_expected: checkTypeOfData(formProps.payment_type_expected),
				payroll_country_expected: checkTypeOfData(
					formProps.payroll_country_expected
				),
				employment_types: employmentType,
				extra_benefits: extraBenifitsItems.map(b => b.value)
			}
		});
		preferencesCompleted && isEmptyLangSkillsFnc && redirectToFunctions();
	};
	const checkTypeOfData = data => {
		if (typeof data === "string") {
			return data;
		} else {
			return data.value;
		}
	};

	const overlay = "Value from 0 to 1,000,000€";

	return (
		<Modal
			title="Preferences"
			onClose={onClose}
			firstButton={{
				label: "Save",
				type: "primary",
				action: handleSubmit(submit)
			}}
			loading={status === LOADING}
			className={"modalPreferences"}
		>
			<div className="row employmen__type">
				<div className="col-md-12">
					<label className="label__employment">EMPLOYMENT TYPE</label>
				</div>

				<div className="col-md-4">
					<Field
						name="permanent"
						formGroupClassName="checkbox-input"
						component={CheckBoxInput}
						onChange={e => handleChangeEmployment(e.target?.name)}
						id="permanent"
					>
						Permanent
					</Field>
				</div>
				<div className="col-md-4">
					<Field
						name="fixed_term"
						formGroupClassName="checkbox-input"
						component={CheckBoxInput}
						onChange={e => handleChangeEmployment(e.target?.name)}
						id="fixed_term"
					>
						Fixed term
					</Field>
				</div>
				<div className="col-md-4">
					<Field
						name="internship"
						formGroupClassName="checkbox-input"
						component={CheckBoxInput}
						onChange={e => handleChangeEmployment(e.target?.name)}
						id="internship"
					>
						Internship
					</Field>
				</div>
			</div>

			<div className="row">
				<div className="col-md-4">
					<Field
						name="expected_salary"
						label="Expected Salary"
						type="text"
						suffix={""}
						tooltipTitle={overlay}
						component={CurrencyField}
					/>
				</div>
				<div className="col-md-3">
					<Field
						name="currency_expected"
						label="Currency"
						currenciesData={currencies}
						position={false}
						searchable={true}
						component={CurrenciesSymbolSelect}
						classes="currency-preferences"
					/>
				</div>
				<div className="col-md-4">
					{!isLoading && (
						<Field
							name="payroll_country_expected"
							label="Payroll country"
							input_classes="countries"
							component={CountryFlagSelect}
							preferredCountries={PREFERRED_COUNTRIES}
							tabIndex={0}
							all={true}
							placeholder="Select a country."
							toolTipOverlay={"The country in which you pay your labor taxes."}
							classes="payroll-country-preferences"
							customList={countries}
							onChange={(_, newValue) => {
								let currency = _get(newValue, "currency", false);
								if (currency) {
									currency = {
										label: currency.code,
										value: currency.code,
										code: currency.code,
										symbol: currency.symbol
									};

									change("currency_expected", currency);
								}
							}}
						/>
					)}
				</div>
			</div>
			<div className="row" style={{ marginTop: "29px" }}>
				{/* payment_time */}
				<div className="col-md-4">
					<Field
						name="payment_time_expected"
						label="SALARY TYPE"
						options={PAYMENTS_TIME}
						position={false}
						searchable={true}
						component={Select}
					/>
				</div>

				<div className="col-md-3">
					<Field
						name="payment_type_expected"
						label="GROSS/NET"
						options={PAYMENTS_TYPE}
						position={false}
						searchable={true}
						component={Select}
					/>
				</div>
				<div className="col-md-4"></div>
			</div>
			<div className="row">
				<div className="col-md-11">
					<ExtraBenefits
						extraBenifitsItems={extraBenifitsItems}
						setExtraBenifits={setExtraBenifitsItems}
					/>
				</div>
			</div>
			<div className="row" style={{ marginTop: "15px" }}>
				<div className="col-md-11">
					<Field
						name="expected_salary_advantage"
						label="Other benefits"
						type="text"
						component={FormField}
						placeholder={
							"Company Car, Meal Vouchers, Insurance(s), Phone, Bonus, etc..."
						}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-11">
					<Field
						type="text"
						label="Notice Period"
						name="notice_period"
						placeholder={"1month..."}
						component={FormField}
					/>
				</div>
			</div>
		</Modal>
	);
};

const validate = formProps => {
	const errors = {};
	const fields = [
		"currency_expected",
		"payment_time_expected",
		"payment_type_expected",
		"payroll_country_expected",
		"notice_period"
	];
	if (!formProps.permanent && !formProps.fixed_term && !formProps.internship) {
		errors.permanent = "Please choose your employment type.";
	}
	if (
		formProps.expected_salary &&
		formProps.expected_salary > MAX_SALARY_VALUE
	) {
		errors.expected_salary = "Value should be between 0 and 1,000,000€";
	}
	for (const field of fields) {
		if (!formProps[field]) {
			errors[field] = "This field is required.";
		}
	}
	return errors;
};

export default reduxForm({
	form: "preferences-modal",
	validate,
	touchOnBlur: false
})(PreferencesModal);
