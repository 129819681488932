import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as IconChevronDown } from "static/icons/Chevron_Down.svg";
import "./style.scss";

export default function ButtonWithDropdown({
	label,
	options,
	classes: {
		btnClasses = "",
		btnOpenedClasses = "",
		menuClasses = "",
		menuItemClasses = "",
		menuItemDisabledClasses = ""
	}
}) {
	const menuRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const onOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

	useEffect(() => {
		let handler = e => {
			if (!menuRef.current.contains(e.target)) setIsOpen(false);
		};

		// fire event handler on mousedown
		document.addEventListener("mousedown", handler);

		// clean event handler on unmount
		return () => document.removeEventListener("mousedown", handler);
	}, []);

	return (
		<div ref={menuRef} className="menu__container">
			{/* Dropdown button */}
			<button
				onClick={onOpen}
				className={isOpen ? btnOpenedClasses : btnClasses}
			>
				{label}
				<IconChevronDown />
			</button>

			{/* Dropdown menu */}
			<div
				className={`${menuClasses} dropdown__menu ${
					isOpen ? "menu__active" : "menu__inactive"
				} `}
			>
				<ul>
					{options
						.filter(item => !!item.href) // filter empty links
						.map(item => {
							return (
								<li
									key={item.value}
									className={`${
										item.href ? menuItemClasses : menuItemDisabledClasses
									}`}
								>
									<a
										target="_blank"
										href={item.href}
										title={item.label}
										rel="noopener noreferrer"
										onClick={() => setIsOpen(false)}
									>
										{item.label}
									</a>
								</li>
							);
						})}
				</ul>
			</div>
		</div>
	);
}
