import React from "react";
import cx from "classnames";
import styles from "./propose-temp-interview-error-text.module.scss";
import { ReactComponent as InfoIcon } from "static/icons/info-icon-red.svg";

const ProposeTempInterviewErrorText = ({ error, className }) => {
	return error ? (
		<div className={cx(styles.container, className)}>
			<InfoIcon />
			<div>{error.message}</div>
		</div>
	) : null;
};

export default ProposeTempInterviewErrorText;
