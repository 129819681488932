import React from "react";
import styled from "styled-components";
import Modal from "common/modal";
import { Button } from "common/styled/buttons";
import { colors } from "config/styles";

const Heading = styled.h6`
	color: ${colors.primaryColor};
	font-size: 18px;
	font-weight: 300;
	margin-bottom: 30px;
`;

const Content = styled.p`
	color: ${colors.inputColor};
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 10px;
`;

const Actions = styled.div`
	float: left;
	clear: both;
	margin-top: 20px;
`;

export default function ResendInvite({ onResend, onClose, active, user }) {
	return (
		<Modal
			fixed={true}
			active={active}
			onClose={onClose}
			className="resend-invite-modal"
			title={"Resend Invitation Email to"}
			centerTitle={true}
		>
			{active && <Heading>{`${user.first_name} ${user.last_name}`}</Heading>}
			<div className="row">
				<div className="col-md-9">
					<Content>
						For security reasons, invitation links expire after 72 hours. <br />
						To re-send this invitation, please click on the “Resend” button
						below.
					</Content>
				</div>
			</div>
			<Actions>
				<Button large size={14} onClick={() => onResend(user)}>
					Resend
				</Button>
			</Actions>
		</Modal>
	);
}
