export default {
	a: { background: "#F0F9FF", text: "#0086C9" },
	b: { background: "#0086C9", text: "#F0F9FF" },
	c: { background: "#F8F9FC", text: "#3E4784" },
	d: { background: "#3E4784", text: "#F8F9FC" },
	e: { background: "#F9F5FF", text: "#7F56D9" },
	f: { background: "#7F56D9", text: "#F9F5FF" },
	g: { background: "#EEFFCA", text: "#70C800" },
	h: { background: "#70C800", text: "#EEFFCA" },
	i: { background: "#ECFDF3", text: "#039855" },
	j: { background: "#039855", text: "#ECFDF3" },
	k: { background: "#FEF3F2", text: "#D92D20" },
	l: { background: "#D92D20", text: "#FEF3F2" },
	m: { background: "#FFF6ED", text: "#EC4A0A" },
	n: { background: "#EC4A0A", text: "#FFF6ED" },
	o: { background: "#FFF1F3", text: "#E31B54" },
	p: { background: "#E31B54", text: "#FFF1F3" },
	q: { background: "#FDF2FA", text: "#DD2590" },
	r: { background: "#DD2590", text: "#FDF2FA" },
	s: { background: "#F0EEFF", text: "#444CE7" },
	t: { background: "#444CE7", text: "#F0EEFF" },
	u: { background: "#FFF1F3", text: "#E31B54" },
	v: { background: "#EFF8FF", text: "#1570EF" },
	w: { background: "#1570EF", text: "#EFF8FF" },
	x: { background: "#FFF2C5", text: "#F59E0B" },
	y: { background: "#F59E0B", text: "#FFF2C5" },
	z: { background: "#E0E5F2", text: "#2D3855" }
};
