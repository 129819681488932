import React, { Component } from "react";
import { Transition, animated } from "react-spring/renderprops";
import _get from "lodash/get";
import Menu from "./Menu";
import CascaderMenu from "./components/CascaderMenu";
import SearchBox from "./SearchBox";
import { excerpt } from "common/Functions";
import { CONSULTANCY } from "config";
import { TobBarContainer } from "common/styled/CommonStyled";
import { FREELANCER } from "config";

const MAX_MENU_CHARS = 12;
const renderCascaderMenuData = data =>
	data.reduce((acc, value) => {
		const isAllreadyAdded = acc.findIndex(e => e.label === value.group_name);
		if (!value.group_name) {
			const obj = {
				...value,
				label: value.name,
				value: value.name,
				subCategory: value.children,
				children: null
			};
			acc.push(obj);
			return acc;
		}
		if (value.group_name && isAllreadyAdded === -1) {
			const children = data.filter(
				item => item.group_name === value.group_name
			);
			const obj = {
				label: value.group_name,
				value: value.group_name,
				children: children.map(e => ({
					label: e.name,
					value: e.name,
					name: e.name,
					_id: e._id,
					subCategory: e.children
				}))
			};
			acc.push(obj);
		}
		return acc;
	}, []);

class Topbar extends Component {
	componentDidMount() {
		this.props.getSectors();
		document.addEventListener("click", this.hideMenus);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.hideMenus);
	}

	chooseFunction = fun => {
		const filter = { ...this.props.filter };
		filter.function = fun.name;
		this.props.updateFilter(filter);
	};

	handleKeywordChange = evt => {
		const { value } = evt.target;
		const filter = { ...this.props.filter };
		filter.title = value;
		this.props.updateFilter(filter, false);
	};

	handleSortChange = e => {
		const { name, value } = e.target;
		const filter = { ...this.props.filter };
		let found = -1;
		for (const [index, sort] of filter.sort_by.entries()) {
			if (sort.field === name) found = index;
		}
		if (found !== -1) {
			if (filter.sort_by[found].order === value)
				filter.sort_by.splice(found, 1);
			else filter.sort_by[found].order = value;
		} else {
			filter.sort_by.push({ field: name, order: value });
		}
		this.props.updateFilter(filter);
	};

	isSortedBy = (field, order) => {
		const sort_by = this.props.filter.sort_by;
		if (sort_by.length === 0) return false;
		for (const srt of sort_by) {
			if (srt.field === field && srt.order === order) return true;
		}
		return false;
	};

	handleFormSubmit = e => {
		e.preventDefault();
		e.stopPropagation();
		const filter = { ...this.props.filter };
		this.props.updateFilter(filter);
	};

	toggleMenuSubCat = () => {
		this.setState(prevState => {
			return { showMenuSubCat: !prevState.showMenuSubCat };
		});
	};

	toggleMenuFunc = () => {
		this.setState(prevState => {
			return { showMenuFunc: !prevState.showMenuFunc };
		});
	};

	toggleMenuSort = () => {
		this.setState(prevState => {
			return { showMenuSort: !prevState.showMenuSort };
		});
	};

	onSectorSelect = (value, selectedOptions) => {
		const sector = selectedOptions.pop();
		const { filter, userType } = this.props;

		filter.category = {
			name: sector.name,
			_id: sector._id
		};
		filter.subcategory = null;
		filter.function = null;

		if (userType === FREELANCER) {
			if (filter.skills.length > 0) {
				return this.props.handleDisplayWarningModal(sector);
			}
		}
		this.props.setSubSectors(sector.subCategory);
		this.props.updateFilter(filter);
	};

	onSubSectorSelect = sector => {
		const filter = { ...this.props.filter };
		filter.subcategory = sector.name;
		filter.function = null;
		this.props.getFunctions(sector);
		this.props.updateFilter(filter);
	};

	render() {
		let {
			filter: {
				subcategory: selectedSub,
				category: selectedCategory,
				function: selectedFunction
			},
			userType,
			vacancy
		} = this.props;

		const isConsultancy = userType === CONSULTANCY;
		const category = _get(selectedCategory, "name")
			? excerpt(_get(selectedCategory, "name"), MAX_MENU_CHARS)
			: "Category";
		const fnc = selectedFunction
			? excerpt(selectedFunction, MAX_MENU_CHARS)
			: "Function";
		const subcategory = selectedSub
			? excerpt(selectedSub, MAX_MENU_CHARS)
			: "Sub-Category";
		const hasCategory = !!selectedCategory;
		const hasSubCategory = !!selectedSub;

		return (
			<TobBarContainer
				vacancy={vacancy}
				className={vacancy && "top-bar-vacancy"}
				id="top-bar"
			>
				{!vacancy && (
					<SearchBox
						handleFormSubmit={this.handleFormSubmit}
						handleKeywordChange={this.handleKeywordChange}
						value={this.props.filter.title}
						vacancy={vacancy}
					/>
				)}
				{!vacancy && <div className="divider" />}
				<div className="container">
					<div className="row">
						<div className={`col-md-${vacancy ? "12" : "7"} top-filter`}>
							<div className="category item">
								<CascaderMenu
									defaultText={category}
									data={renderCascaderMenuData(this.props.sectors)}
									onSelect={this.onSectorSelect}
								/>
							</div>

							<div className="subcategory item">
								<Menu
									defaultText={subcategory}
									data={this.props.subSectors}
									onSelect={this.onSubSectorSelect}
									isDisabled={(isConsultancy || vacancy) && !hasCategory}
									overlayText="Please select a Category"
								/>
							</div>
							<div className="functions item">
								<Menu
									defaultText={fnc}
									isDisabled={!hasSubCategory}
									overlayText="Please select a Sub-Category"
									data={this.props.functions}
									onSelect={this.chooseFunction}
								/>
							</div>
							{!vacancy && (
								<div className="sort item">
									<Menu>
										{(isOpen, toggleMenu) => (
											<React.Fragment>
												<div className="menu-trigger" onClick={toggleMenu}>
													Sort by <i className="fa fa-chevron-down" />
												</div>
												<Transition
													items={isOpen}
													from={{ maxHeight: 0, opacity: 0 }}
													enter={{ maxHeight: 300, opacity: 1 }}
													leave={{ maxHeight: 0, opacity: 0 }}
													config={{ duration: 220 }}
												>
													{show =>
														show &&
														(props => (
															<animated.div
																style={props}
																className={"menu-holder"}
															>
																<div className="menu sort-menu">
																	<div className="heading">Date</div>
																	<ul className="list-unstyled">
																		<li className="sort-item">
																			<div className="checkbox checkbox-primary">
																				<input
																					onChange={this.handleSortChange}
																					name="publish_date"
																					value="desc"
																					type="checkbox"
																					id="newest"
																					checked={this.isSortedBy(
																						"publish_date",
																						"desc"
																					)}
																					className="styled"
																				/>
																				<label
																					htmlFor="newest"
																					className="sort"
																				>
																					Newest
																				</label>
																			</div>
																		</li>
																		<li className="sort-item">
																			<div className="checkbox checkbox-primary">
																				<input
																					onChange={this.handleSortChange}
																					name="publish_date"
																					value="asc"
																					type="checkbox"
																					id="oldest"
																					checked={this.isSortedBy(
																						"publish_date",
																						"asc"
																					)}
																					className="styled"
																				/>
																				<label
																					htmlFor="oldest"
																					className="sort"
																				>
																					Oldest
																				</label>
																			</div>
																		</li>
																	</ul>
																	<div className="divider" />
																	<div className="heading">Distance</div>
																	<ul className="list-unstyled">
																		<li className="sort-item">
																			<div className="checkbox checkbox-primary">
																				<input
																					onChange={this.handleSortChange}
																					type="checkbox"
																					id="nearest"
																					checked={this.isSortedBy(
																						"location",
																						"asc"
																					)}
																					name="location"
																					value="asc"
																					className="styled"
																				/>
																				<label
																					htmlFor="nearest"
																					className="sort"
																				>
																					Nearest
																				</label>
																			</div>
																		</li>
																		<li className="sort-item">
																			<div className="checkbox checkbox-primary">
																				<input
																					onChange={this.handleSortChange}
																					type="checkbox"
																					name="location"
																					checked={this.isSortedBy(
																						"location",
																						"desc"
																					)}
																					id="farthest"
																					value="desc"
																					className="styled"
																				/>
																				<label
																					htmlFor="farthest"
																					className="sort"
																				>
																					Farthest
																				</label>
																			</div>
																		</li>
																	</ul>
																</div>
															</animated.div>
														))
													}
												</Transition>
											</React.Fragment>
										)}
									</Menu>
								</div>
							)}
						</div>
					</div>
				</div>
			</TobBarContainer>
		);
	}
}

export default Topbar;
