import React, { useState, useEffect } from "react";
import { ModalContent } from "../CreateVacancyModal/components/ModalContent";
import { ModalFooter } from "../CreateVacancyModal/components/ModalFooter";
import { ModalSaveButton } from "../CreateVacancyModal/components/ModalSaveButton";
import { ModalCancelButton } from "../CreateVacancyModal/components/ModalCancelButton";
import { CreateVacancyModal } from "../CreateVacancyModal";
import styles from "./job-address-picker-modal.module.scss";
import { RadioGroup } from "common/RadioGroup";
import { ComboBox } from "common/ComboBox";
import useGetCompanyOfficesList from "modules/vacancy/create-vacancy/api/useGetCompanyOfficesList";
import { Item } from "react-stately";
import AddressChooser from "common/AddressBoxNext/AddressChooser";
import {
	addressObjectToString,
	handleAddressObject,
	isValidAddress
} from "config/helpers";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";

const OPTIONS = [
	{ label: "Company address", value: "companyAddress" },
	{ label: "Custom address", value: "customAddress" }
];

const REQUIRED_FIELDS = ["country"];

const JobAddressPickerModal = ({
	onCancel: onCancelProp,
	onSave: onSaveProp,
	value,
	addressType: addressTypeProp
}) => {
	const [addressType, setAddressType] = useState("companyAddress");
	const [companyAddress, setCompanyAddress] = useState();
	const [customAddress, setCustomAddress] = useState({ value: {}, label: "" });
	const [error, setError] = useState(false);
	const [addressTypo, setAddressTypo] = useState(false);
	const [companyAddressError, setCompanyAddressError] = useState("");
	const { data } = useGetCompanyOfficesList();

	const addressesList = data ? formatAddressesList(data.offices) : [];

	useEffect(() => {
		if (addressTypeProp === "company_address" && value?.value) {
			setCompanyAddress({
				label: value.label,
				value: generateAddressId(value.value)
			});
			setAddressType("companyAddress");
		} else if (value?.value) {
			setCustomAddress(value);
			setAddressType("customAddress");
		}
	}, [value, addressTypeProp]);

	function formatAddressesList(list) {
		const listFormatted = list.map(address => {
			const addressString = addressObjectToString(address);

			return {
				label: addressString,
				value: generateAddressId(address)
			};
		});

		return listFormatted;
	}

	function generateAddressId(address) {
		return `${address.street ? address.street : ""} ${
			address.number ? address.number : ""
		} ${address.street || address.number ? "," : ""} ${
			address.zip ? address.zip : ""
		} ${address.city ? address.city : ""} ${
			address.country ? address.country : ""
		}`;
	}

	const onCustomAddressChange = value => {
		setCustomAddress({ value, label: addressObjectToString(value) });
	};

	const onAddressTypeChange = e => {
		setAddressType(e.target.value);
	};

	const onSave = async () => {
		if (addressType === "companyAddress") {
			if (!companyAddress?.value) {
				setCompanyAddressError({ message: "Please select address" });
				return;
			}

			const addressObject = data.offices.find(
				address => generateAddressId(address) === companyAddress.value
			);

			const value = {
				label: companyAddress.label,
				value: addressObject
			};

			const payload = {
				value,
				type: "company_address"
			};

			onSaveProp(payload);
		} else {
			if (!isValidAddress(customAddress.value, REQUIRED_FIELDS)) {
				return setError(true);
			}
			const finalAddress = await handleAddressObject(customAddress.value, true);

			if (finalAddress?.addressTypo) {
				setAddressTypo(true);
				return setError(true);
			}
			setError(false);

			const payload = {
				value: { ...customAddress, value: finalAddress?.address },
				type: "search_address"
			};

			onSaveProp(payload);
		}
	};

	const getAddressById = id => {
		return addressesList.find(({ value }) => {
			return value === id;
		});
	};

	const onAddressSelectionChange = key => {
		const _object = getAddressById(key);

		const value = {
			label: _object?.label ?? "",
			value: key
		};

		if (key && companyAddressError) {
			setCompanyAddressError("");
		}

		setCompanyAddress(value);
	};

	const onAddressInputChange = value => {
		const valueObject = {
			label: value,
			value: value === "" ? null : companyAddress?.value
		};

		if (value && companyAddressError) {
			setCompanyAddressError("");
		}

		setCompanyAddress(valueObject);
	};

	const onCancel = () => {
		onCancelProp();
	};

	return (
		<CreateVacancyModal className={styles.modal} onClose={onCancel}>
			<h2 className={styles.modalTitle}>Site address</h2>
			<ModalContent className={styles.modalContent}>
				<RadioGroup
					className={styles.radioButtonsContainer}
					value={addressType}
					onChange={onAddressTypeChange}
					options={OPTIONS}
				/>

				{addressType === "companyAddress" ? (
					<div className={styles.companyAddressContainer}>
						<ComboBox
							inputValue={companyAddress?.label}
							selectedKey={companyAddress?.value}
							onInputChange={onAddressInputChange}
							onSelectionChange={onAddressSelectionChange}
						>
							{addressesList.map(({ value, label }) => (
								<Item key={value}>{label}</Item>
							))}
						</ComboBox>
						<CreateVacancyFieldError error={companyAddressError} />
					</div>
				) : (
					<AddressChooser
						setAddress={onCustomAddressChange}
						address={customAddress?.value || {}}
						error={error}
						addressTypo={addressTypo}
						requiredFields={REQUIRED_FIELDS}
						showAutoComplete={true}
					/>
				)}
			</ModalContent>
			<ModalFooter>
				<ModalCancelButton onClick={onCancel} />
				<ModalSaveButton onClick={onSave} />
			</ModalFooter>
		</CreateVacancyModal>
	);
};

export default JobAddressPickerModal;
