import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { CREATE_JOB_BOARD_CONTRACT_ENDPOINT } from "config/api-endpoints";

function addContract(body) {
	return client(CREATE_JOB_BOARD_CONTRACT_ENDPOINT, {
		body
	});
}

function useAddContract(queryOptions) {
	return useMutation(addContract, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
}

export default useAddContract;
