import React, { useRef, useState } from "react";
import { Element } from "react-scroll";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { Flex, Box, Text } from "rebass";
import FilesUploader from "modules/bids/components/createbid/FilesUploader";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import Toaster from "common/Toaster";
import FormField from "common/FormField";
import { getFileName, getFileExtension } from "common/Functions";
import { connect } from "react-redux";
import { client } from "lib/api-client";
import { REQUIRED_DOCUMENTS } from "config/api-endpoints";
// import { RemoveItem } from "./TimesheetStyled";
import { FILE_ICONS } from "./ListRendererFiles";
import { bindActionCreators } from "redux";
import { setFieldIndex } from "../actions";
import { Divider } from "common/styled/CommonStyled";
import { generateId } from "config/helpers";
import Tooltip from "common/Tippy";
import styled from "styled-components";
import { TIMESHEET_ATTACHMENTS } from "modules/timesheetsModule/utils/constants";

const deleteFile = (timesheet_id, document_id, type) =>
	client(REQUIRED_DOCUMENTS, {
		body: {
			action: "delete",
			timesheet_id,
			document_id,
			tag: type
		}
	});

const uploadFile = (timesheet_id, file, tag) =>
	client(REQUIRED_DOCUMENTS, {
		body: {
			action: "upload",
			timesheet_id,
			files: {
				...file
			},
			tag: tag
		}
	});

function AddFiles({
	timesheet_id,
	disabled,
	files,
	requiredFiles,
	attachments,
	files_requirements,
	initialize,
	onActionFinish,
	nameFormProp,
	setFieldIndex
}) {
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState("");
	const [selectedFileId, setSelectedFileId] = useState("");
	const [selectedFileType, setSelectedFileType] = useState("file");

	const ref = useRef();

	const onSubmit = (name, path, _id, tag) => {
		uploadFile(
			timesheet_id,
			{
				_id: _id,
				name: name,
				path: path
			},
			tag
		)
			.then(onSavingSuccess)
			.catch(onSavingError);
	};

	const onSavingSuccess = () => {
		Toaster.success(`Status: Your file item was saved successfully`);
		initialize({});
		onActionFinish();
	};

	const onSavingError = () => {
		Toaster.danger(`Status: an error occured while saving your file`);
	};

	const onDoneUploading = props => {
		const { name, path, _id, tag } = props;
		onSubmit(getFileName(nameFormProp || name), path, _id, tag);
	};

	const onRemoveFile = (name, id, type = "file") => {
		setSelectedFile(name);
		setSelectedFileId(id);
		setSelectedFileType(type);
		setDisplayDeleteModal(true);
	};

	const onCloseRemoveModal = () => {
		setSelectedFile("");
		setSelectedFileId("");
		setDisplayDeleteModal(false);
	};

	const handleDeleteFile = () => {
		deleteFile(timesheet_id, selectedFileId, selectedFileType)
			.then(onDeleteSuccess)
			.catch(() =>
				Toaster.danger(
					`Status: There was an error trying to delete your file item with the id: ${selectedFileId}`
				)
			)
			.finally(onCloseRemoveModal);
	};

	const onDeleteSuccess = () => {
		Toaster.success(`Status: your file item was deleted successfully`);
		setSelectedFileType("file");
		onActionFinish();
	};

	const files_has_requirement = name => {
		return files_requirements.includes(name);
	};

	const get_filename = (files, requiredFile) => {
		return files[requiredFile._id].name;
	};

	const get_filepath = (files, requiredFile) => {
		return files[requiredFile._id].path;
	};

	const renderFileIcon = path => {
		const extension = getFileExtension(path);
		return FILE_ICONS(18)[extension];
	};

	const onNameFieldFocus = index => setFieldIndex(index);

	return (
		<Element name="add-new-box">
			<div className="form-container">
				<Flex flexDirection="column">
					<Flex mb={1}>
						<Box mr={3} width={1 / 4}>
							<Text
								mt={2}
								sx={{
									fontFamily: "Roboto",
									fontSize: "14px",
									fontWeight: "bold",
									textTransform: "uppercase"
								}}
							>
								Document type
							</Text>
						</Box>
						<Box mr={4} width={1 / 4}>
							<Text
								mt={2}
								sx={{
									fontFamily: "Roboto",
									fontSize: "14px",
									fontWeight: "bold",
									textTransform: "uppercase"
								}}
							>
								Name your file
							</Text>
						</Box>
						<Box mr={3} width={1 / 4}>
							<Text
								mt={2}
								sx={{
									fontFamily: "Roboto",
									fontSize: "14px",
									fontWeight: "bold",
									textTransform: "uppercase"
								}}
							>
								Upload your file
							</Text>
						</Box>
					</Flex>

					<>
						<Divider style={{ margin: "20px 0 20px 0" }} />
						<Flex pt={3} alignItems="flex-start">
							<Box mr={3} width={1 / 4}>
								<Text
									sx={{
										fontFamily: "Roboto",
										fontSize: "16px",
										fontWeight: "500",
										textTransform: "Capitalize"
									}}
								>
									{TIMESHEET_ATTACHMENTS}
								</Text>
							</Box>
							{attachments && Object.keys(attachments).length > 0 ? (
								<Box width={3 / 4}>
									{Object.keys(attachments).length > 0 &&
										Object.keys(attachments).map((itm, index) => {
											const attch = attachments[itm];
											return (
												<Flex key={index} alignItems={"flex-start"}>
													<Box mr={3} width={1 / 4}>
														<Flex
															flexDirection={"column"}
															justifyContent={"flex-start"}
															gap={"10px"}
															width={"100%"}
															minWidth={"300px"}
															maxWidth={"300px"}
															pb={10}
														>
															<Flex
																alignItems="flex-start"
																justifyContent="space-between"
															>
																<Text mr={3}>
																	<Tooltip
																		theme="dark"
																		content={attch.name}
																		overflow="hidden"
																	>
																		<FileName ref={ref}>{attch.name}</FileName>
																	</Tooltip>
																</Text>
																<Flex alignItems="flex-start" pt={0} m={0}>
																	<Text mr={3}>
																		{renderFileIcon(attch.path)}
																	</Text>
																	<RemoveIcon
																		onClick={() =>
																			onRemoveFile(
																				attch.name,
																				attch._id,
																				"attachment"
																			)
																		}
																	>
																		<span
																			style={{
																				display: "flex",
																				alignItems: "flex-start"
																			}}
																			className="icon-reset-3"
																		/>
																	</RemoveIcon>
																</Flex>
															</Flex>
														</Flex>
													</Box>
												</Flex>
											);
										})}
								</Box>
							) : (
								<>
									<Box mr={3} width={1 / 4}>
										<Field
											disabled={disabled}
											type="text"
											name={`name_1111`}
											placeholder="Add a name for your file"
											component={FormField}
											onFocus={() => onNameFieldFocus(1111)}
										/>
									</Box>
									<Box mr={4} ml={3} width={1 / 3}>
										<FilesUploader
											_id={generateId()}
											hasNameInput={false}
											onDoneUploading={props => {
												onDoneUploading({ ...props, tag: "attachment" });
											}}
										/>
									</Box>
								</>
							)}
						</Flex>

						{attachments && Object.keys(attachments).length > 0 && (
							<Flex key={"attachment"} mt={4}>
								<Box mr={3} width={1 / 4}></Box>
								<Box mr={3} width={1 / 4}>
									<Field
										disabled={disabled}
										type="text"
										name={`name_1111`}
										placeholder="Add a name for your file"
										component={FormField}
										onFocus={() => onNameFieldFocus(1111)}
									/>
								</Box>
								<Box mr={4} ml={3} width={1 / 3}>
									<FilesUploader
										_id={generateId()}
										hasNameInput={false}
										onDoneUploading={props => {
											onDoneUploading({ ...props, tag: "attachment" });
										}}
									/>
								</Box>
							</Flex>
						)}
						<Divider style={{ margin: "20px 0 20px 0" }} />
					</>

					{requiredFiles.map((requiredFile, index) => {
						return (
							<Flex key={index} mt={1}>
								<Box mr={3} width={1 / 4}>
									<Text
										mt={2}
										sx={{
											fontFamily: "Roboto",
											fontSize: "16px",
											fontWeight: "500",
											textTransform: "Capitalize"
										}}
									>
										{requiredFile.name}
										{requiredFile.is_required && (
											<>
												<p
													style={{
														fontWeight: "bold",
														fontSize: "10px",
														color: "#fd6849",
														margin: 0
													}}
												>
													required
												</p>
											</>
										)}
									</Text>
								</Box>
								<Box mr={4} width={1 / 4}>
									{files_has_requirement(requiredFile.name) ? (
										<Flex
											pt={3}
											alignItems="center"
											justifyContent="space-between"
										>
											<Text>
												<Tooltip
													theme="dark"
													content={get_filename(files, requiredFile)}
													overflow="hidden"
												>
													<FileName ref={ref}>
														{get_filename(files, requiredFile)}
													</FileName>
												</Tooltip>
											</Text>
											{/* <Flex alignItems="center">
												<Text mr={3}>
													{renderFileIcon(get_filepath(files, requiredFile))}
												</Text>
												<RemoveItem
													onClick={() =>
														onRemoveFile(
															get_filename(files, requiredFile),
															requiredFile._id
														)
													}
												>
													<span className="icon-reset-3" />
												</RemoveItem>
											</Flex> */}
											<Flex alignItems="center" pt={0} m={0}>
												<Text mr={3}>
													{renderFileIcon(get_filepath(files, requiredFile))}
												</Text>
												<RemoveIcon
													onClick={() =>
														onRemoveFile(
															get_filename(files, requiredFile),
															requiredFile._id
														)
													}
												>
													<span
														style={{
															display: "flex",
															alignItems: "flex-start"
														}}
														className="icon-reset-3"
													/>
												</RemoveIcon>
											</Flex>
										</Flex>
									) : (
										<Field
											disabled={disabled}
											type="text"
											name={`name_${index}`}
											placeholder="Add a name for your file"
											component={FormField}
											onFocus={() => onNameFieldFocus(index)}
										/>
									)}
								</Box>
								<Box mr={3} width={1 / 3}>
									<FilesUploader
										_id={requiredFile._id}
										hasNameInput={false}
										onDoneUploading={props => {
											onDoneUploading({ ...props, tag: "file" });
										}}
									/>
								</Box>
							</Flex>
						);
					})}
				</Flex>
			</div>
			{displayDeleteModal && (
				<ConfirmationModal
					key="delete_department_modal"
					onClose={onCloseRemoveModal}
					active={true}
					modalName="delete_department_modal"
					type={ConfirmationTypes.error}
					title={`Are you sure you would like to delete the file with the name ${selectedFile}?`}
					firstButton={{
						action: handleDeleteFile,
						label: "YES, DELETE.",
						type: "warning"
					}}
					secondButton={{
						action: onCloseRemoveModal,
						label: "NO"
					}}
				/>
			)}
		</Element>
	);
}

const FileName = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	flex-grow: 1;
	max-width: 200px; /* You can adjust this value as needed */
`;

const RemoveIcon = styled.div`
	display: flex;
	align-items: flex-start;
	background: transparent;
	border: none;
	color: #c1c1c1;
	font-size: 25px;
	cursor: pointer;
	&:hover {
		color: #9fa7ae;
	}
`;

const validate = formProps => {
	const errors = {};

	if (!formProps.type) {
		errors.type = "File requirement is required.";
	}

	if (!formProps.name) {
		errors.name = "File name is required.";
	}

	return errors;
};

const mapStateToProps = state => {
	const {
		data: { required_files, files, attachments },
		fileFormFields,
		nameFieldCurrentIndex
	} = state.timesheet;

	const files_requirements = Object.keys(files || {})
		.map(fileId => files[fileId])
		.map(file => file.field_name);
	const selector = formValueSelector("add-files-form");
	return {
		requiredFiles: Object.keys(required_files || {}).map(
			requiredFile_id => required_files[requiredFile_id]
		),
		files,
		files_requirements,
		attachments,
		initialValues: Object.assign({}, fileFormFields),
		fileFormFields,
		nameFormProp: selector(state, `name_${nameFieldCurrentIndex}`)
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ setFieldIndex }, dispatch);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: "add-files-form",
		validate,
		touchOnBlur: false
	})(AddFiles)
);
