import React, { useState, useMemo, useRef, useEffect } from "react";
import _debounce from "lodash/debounce";
import { queryCache } from "react-query";
import Intro from "common/dashboard/Intro";
import { useSelector } from "react-redux";
import useTaskStore from "../Tasks/store/useTaskStore";
import useMarkAsCompleted from "modules/Tasks/api/useMarkAsCompleted";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { browserHistory } from "react-router";
import ConfirmationModalAction from "modules/Tasks/components/confirmationModal/ConfirmationModalAction";
import Table from "modules/Tasks/components/table";
import Date from "modules/Tasks/components/table/date";
import Priority from "modules/Tasks/components/table/priority";
import RenderUser from "modules/Tasks/components/table/renderUser";
import Status from "modules/Tasks/components/table/status";
import Actions from "modules/Tasks/components/table/action";
import FilterBox from "./components/FilterBox";
import classnames from "./tasks.module.scss";
import PaginationClient from "modules/Tasks/components/pagination";
import SkeletonComponent from "modules/Tasks/components/skeleton";
import useGetTasks from "modules/Tasks/api/useGetTasks";
import { myTaskListKey } from "modules/Tasks/api/useGetTasks";
import { getTaskDetailsKey } from "modules/Tasks/components/TaskDetails/api/useTaskDetails";
import get from "lodash/get";
import { REQUEST, UNPUBLISHED, CONSULTANCY, ONBOARDING } from "config";
import { dataSkeleton } from "modules/Tasks";
import TaskDetailsDrawer from "modules/Tasks/components/TaskDetails";
import EmptyStateLinkTo from "modules/Tasks/components/table/EmptyStateLinkTo";
import Tooltip from "rc-tooltip";

export const getTasksSupplierRoute = () => {
	return "/list-tasks";
};
export const getTasksIdRouteSupplier = () => {
	return `/list-tasks(/:id)`;
};
export const getLinksSupplier = (type, id) => {
	switch (type) {
		case REQUEST:
			return "/jobs/view/" + id;
		default:
			return "";
	}
};
export const getTasksSupplierPermission = () => {
	return {
		type: [
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
};

const TasksSupplier = ({ params }) => {
	const [taskID, setTaskID] = useState(null);
	const [isOpenDetailsDrawer, setIsOpenDetailsDrawer] = useState(false);
	const { id } = params;
	const [searchKeyword, setSearchKeyword] = useState("");
	const [showModalCompleted, setShowModalCompleted] = useState(false);
	const user = useSelector(onlineUserSelector);

	const idModal = useRef();
	useEffect(() => {
		setTaskID(id);
		setIsOpenDetailsDrawer(true);
	}, [id]);

	const {
		filters,
		updateOffset,
		updateSearch,
		updateIsTitleSeacrh,
		updateIsDescriptionSearch
	} = useTaskStore();
	const { offset, search, isTitleSearch, isDescriptionSearch } = filters;

	let { resolvedData, isFetching } = useGetTasks({
		offset,
		...((isTitleSearch || isDescriptionSearch) &&
			search && {
				search: {
					value: search,
					title: isTitleSearch,
					description: isDescriptionSearch
				}
			})
	});

	useEffect(() => {
		setSearchKeyword(search);
	}, []);

	const [markAsCompleted, { isLoading: isSaving }] = useMarkAsCompleted();

	const redirectToEntity = (e, entity) => {
		e.stopPropagation();
		const link = getLinksSupplier(
			get(entity, "linked_to.type", ""),
			get(entity, "linked_to._id", "")
		);
		link && browserHistory.push(link);
	};
	const markAsCompletedBtn = () => {
		markAsCompleted(
			{
				task_id: idModal.current
			},
			{
				onSuccess: () => {
					queryCache.invalidateQueries(getTaskDetailsKey);
					queryCache.invalidateQueries(myTaskListKey);
					setShowModalCompleted(false);
				}
			}
		);
	};

	const showModalClick = id => {
		idModal.current = id;
		setShowModalCompleted(true);
	};

	const closeModal = () => {
		setShowModalCompleted(false);
	};

	const data = useMemo(
		() =>
			isFetching
				? dataSkeleton
				: get(resolvedData, "tasks", []).map(item => {
						const fullName =
							get(item, "created_by.first_name", "") +
							" " +
							get(item, "created_by.last_name", "");
						const assignedFullName =
							get(item, "assigned_user.first_name", "") +
							" " +
							get(item, "assigned_user.last_name", "");
						const id = get(item, "_id");
						const status = get(item, "status", "");

						const title = get(item, "title", "");
						const renderedTitle =
							title.length > 46 ? (
								<Tooltip placement="top" trigger="hover" overlay={title}>
									<span className={classnames.title}>
										{title.slice(0, 43) + "..."}
									</span>
								</Tooltip>
							) : (
								<span className={classnames.title}>{title}</span>
							);

						const linkTo = get(item, "linked_to.entity", undefined);
						const isLinkHidden =
							get(item, "linked_to.status", "") === UNPUBLISHED ||
							get(item, "linked_to.status", "") === ONBOARDING;
						return {
							title: renderedTitle,
							link_to: linkTo ? (
								!isLinkHidden ? (
									<button
										className={classnames.linkToButton}
										onClick={e => redirectToEntity(e, item)}
									>
										<span className={classnames.linkTo}>{linkTo}</span>
									</button>
								) : (
									<span className={classnames.linkToText}>{linkTo}</span>
								)
							) : (
								<EmptyStateLinkTo />
							),
							status: <Status status={status} />,
							due_date: (
								<Date date={get(item, "due_date", "")} isHour isSupplier />
							),
							priority: (
								<Priority priority={get(item, "priority", undefined)} />
							),
							created_by: (
								<RenderUser
									name={fullName}
									avatar={get(item, "created_by.company_avatar")}
									jobTitle={get(item, "created_by.user_function")}
								/>
							),
							assigned_user: (
								<RenderUser
									name={assignedFullName}
									avatar={get(item, "assigned_user.company_avatar")}
									jobTitle={get(item, "assigned_user.user_function", "")}
									isExternal={get(item, "assigned_user.is_external", false)}
									isEmpty={
										!Boolean(Object.keys(get(item, "assigned_user", {})).length)
									}
								/>
							),
							creation_date: <Date date={get(item, "created_at", "")} />,
							action: (
								<Actions
									assignedUser={get(item, "assigned_user", {})}
									createdBy={get(item, "created_by", {})}
									userConnected={user}
									markAsComplete={() => showModalClick(id)}
									status={status}
									isSaving={isSaving}
									id={id}
									dataTask={item}
								/>
							)
						};
				  }),
		[isFetching]
	);
	const total = get(resolvedData, "pagination.total", "");
	const size = get(resolvedData, "pagination.size", "");

	const title = "Task list";
	const subTitle = (
		<>
			You will participate in all elements of consulting delivery, practice
			building, business development and professional and personal development;
			we will work with you to build on and use your strengths and help you
			broaden your skills, abilities and experience.
		</>
	);

	const handleRowClick = (row, index, fetchedData) => {
		browserHistory.push(`/list-tasks/${get(fetchedData[index], "_id")}`);
	};

	const debouncedChangeFilterAction = useRef(_debounce(updateSearch, 500));

	const handelSearchChange = value => {
		setSearchKeyword(value);
		debouncedChangeFilterAction.current(value);
	};

	return (
		<div className={classnames.container}>
			<div className={classnames.dashbordBox}>
				<Intro icon="fa fa-file-text-o" title={title} subTitle={subTitle} />
			</div>
			<div className="container">
				<div className={classnames.filterBox}>
					<FilterBox
						searchKeyword={searchKeyword}
						setSearchKeyword={handelSearchChange}
						isDescriptionSearch={isDescriptionSearch}
						setIsDescriptionSearch={updateIsDescriptionSearch}
						isTitleSearch={isTitleSearch}
						setIsTitleSearch={updateIsTitleSeacrh}
						store={useTaskStore}
					/>
				</div>
				<div className={classnames.listTasks}>
					<Table
						data={data}
						isFetching={isFetching}
						onRowClick={handleRowClick}
						fetchedData={get(resolvedData, "tasks", [])}
						isAddNewTask={false}
					/>
				</div>

				{get(resolvedData, "tasks.length", 0) > 0 && (
					<div className={classnames.paginationContainer}>
						<div className={classnames.paginationText}>
							Showing{" "}
							{isFetching ? (
								<SkeletonComponent width={15} height={12} borderRadius={10} />
							) : (
								offset + get(resolvedData, "tasks.length")
							)}{" "}
							of{" "}
							{isFetching ? (
								<SkeletonComponent width={15} height={12} borderRadius={10} />
							) : (
								total
							)}{" "}
							results found
						</div>

						<PaginationClient
							size={size}
							offset={offset}
							setOffset={updateOffset}
							total={total}
						/>
					</div>
				)}
			</div>
			{showModalCompleted && (
				<ConfirmationModalAction
					onClose={closeModal}
					onClick={markAsCompletedBtn}
					modalName={"mark-as-resolved-task"}
					title={`Are you sure you want to mark as completed`}
					type={"success"}
					textAction={"Yes, mark as completed"}
					loading={isSaving}
					typeAction={"success"}
				/>
			)}
			{taskID && (
				<TaskDetailsDrawer
					id={taskID}
					isOpenDetailsDrawer={isOpenDetailsDrawer}
					setIsOpenDetailsDrawer={setIsOpenDetailsDrawer}
					openModal={() => showModalClick(taskID)}
					isSaving={isSaving}
					backLink={getTasksSupplierRoute}
					userConnected={user}
					companyType={CONSULTANCY}
				/>
			)}
		</div>
	);
};

export default TasksSupplier;
