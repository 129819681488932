import AxiosHelper, { endpoints } from "config/axios-helper";
import { FETCH_USER_FILES } from "./actionTypes";

export function fetchUserFiles(payload) {
	return AxiosHelper.__post({
		url: endpoints.VIEW_USER_FILES,
		data: payload,
		loadingText: "Fetching files...",
		next: data => {
			return {
				type: FETCH_USER_FILES,
				data
			};
		}
	});
}
