import React, { Component } from "react";
import _get from "lodash/get";
import Pagination from "common/Pagination";
import EmptyJobs from "common/EmptyComponent";
import {
	DATE_FORMAT,
	VENDOR,
	CLIENT,
	ADMIN,
	FREELANCER,
	INTERRUPTED,
	CONSULTANT,
	LIST_TITLE_MAX,
	CURRENCY_SUFFIX
} from "config";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { isDateValid, excerpt } from "common/Functions";
import Actions from "./Actions";
import GlobalTooltip from "common/GlobalTooltip";
import MissionsFilter from "./MissionsFilter";
import {
	TimesheetTableDetails,
	Th,
	Tr,
	Td
} from "modules/timesheets/components/TimesheetStyled";
import StatusLabel from "common/StatusLabel";

class Contracts extends Component {
	renderJobList = () => {
		const { user, data, isConsultancy } = this.props;
		const isClient = user.type === CLIENT;
		const type = isClient ? VENDOR : CLIENT;
		const isSuperAdmin = user.active_role === ADMIN;
		const isCD = isConsultancy && !user.is_member;
		const progress = (start_date, end_date) =>
			Contracts.calculateProgress(start_date, end_date);

		if (data && data.length) {
			return data.map((item, i) => {
				const jobGroupId = _get(item, "group._id");
				const isAdminInMissionGroup = user.groups.some(
					group => group.id === jobGroupId && group.role_name === ADMIN
				);
				return (
					<Tr key={i}>
						<Td padding={"12px 18px"}>
							<div>
								<GlobalTooltip
									placement="top"
									overlay={item.job.title}
									active={item.job.title.length > LIST_TITLE_MAX}
									withWrappingDiv={false}
									overlayClassName="g-tool-dark"
								>
									<div className="_input_color_">
										{excerpt(item.job.title, LIST_TITLE_MAX)}
									</div>
								</GlobalTooltip>
							</div>
						</Td>
						{(isCD || !isConsultancy) && (
							<Td padding={"12px 18px"}>
								<span style={{ whiteSpace: "nowrap" }}>
									<CurrencyFormatterRender
										isCent
										value={isClient ? item.cost : item.price}
										suffix={
											CURRENCY_SUFFIX[
												_get(item, "reporting_settings.rate_type")
											]
										}
										currency={_get(item, "reporting_settings.currency.code")}
									/>
								</span>
							</Td>
						)}
						<Td padding={"12px 18px"}>
							{isDateValid(item.start_date, true)
								? window.moment.unix(item.start_date).format(DATE_FORMAT)
								: "-"}
						</Td>
						<Td padding={"12px 18px"}>
							{isDateValid(item.end_date, true)
								? window.moment.unix(item.end_date).format(DATE_FORMAT)
								: "-"}
						</Td>
						<Td padding={"12px 18px"}>
							{!isClient
								? item[type].company_name
								: `${item[type].first_name} ${item[type].last_name}`}
						</Td>
						{isConsultancy && (
							<Td padding={"12px 18px"}>
								{_get(item, "vendor.first_name")}{" "}
								{_get(item, "vendor.last_name")}
							</Td>
						)}
						{isConsultancy && (
							<Td padding={"12px 18px"}>
								{_get(item, "group_consultancy.name")}
							</Td>
						)}
						<Td align="center" padding={"12px 18px"}>
							<StatusLabel status={item.status} label={item.status} />
						</Td>
						<Td padding={"12px 18px"}>
							<div
								className={`progress-text ${
									item.status === INTERRUPTED ? "progress-danger" : ""
								}`}
							>
								{progress(item.start_date, item.end_date)}%
							</div>
							<div className="progress">
								<div
									className={`progress-bar ${
										item.status !== INTERRUPTED
											? "progress-bar-success"
											: "progress-bar-danger"
									}`}
									style={{
										width: `${progress(item.start_date, item.end_date)}%`
									}}
								/>
							</div>
						</Td>
						{isConsultancy ? null : isSuperAdmin || isAdminInMissionGroup ? (
							progress(item.start_date, item.end_date) === 100 ? (
								<Td padding={"12px 18px"} />
							) : (
								<Actions mission={item} isClient={isClient} reposition={true}>
									<Td padding={"12px 18px"}>
										<div className="dots">
											<div className="dot" />
											<div className="dot" />
											<div className="dot" />
										</div>
									</Td>
								</Actions>
							)
						) : (
							<Td padding={"12px 18px"}>
								<GlobalTooltip
									placement="top"
									overlay={
										"Your role doesn’t allow you to access these features."
									}
								/>
							</Td>
						)}
					</Tr>
				);
			});
		}
	};

	handlePageClick = value => {
		this.props.onPageClick(value);
	};

	static calculateProgress(start_date, end_date) {
		const now = window.moment();
		const start = window.moment.unix(start_date);
		const end = window.moment.unix(end_date);
		let total = Math.round(((now - start) / (end - start)) * 100);
		total = total < 0 ? 0 : total;
		return total > 100 ? 100 : total;
	}

	handleFilterChange = (e, fieldName) => {
		const { onFilterChange, filter } = this.props;
		if ("target" in e) {
			const { name, checked } = e.target;
			filter.status = checked ? name : "";
		} else {
			filter[fieldName] = e.value;
		}
		onFilterChange(filter);
	};

	render() {
		const {
			user,
			data,
			pagination = {},
			isConsultancy,
			filter,
			filterSettings
		} = this.props;

		const listJobsEmpty = !data || (data && data.length === 0);
		const isClient = user.type === CLIENT;
		const isFreelancer = user.type === FREELANCER;
		const isCD = isConsultancy && !user.is_member;

		return (
			<div>
				{(isClient || isFreelancer) && (
					<MissionsFilter
						isClient={isClient}
						filter={{ ...filter }}
						filterSettings={filterSettings}
						onFilterChange={this.handleFilterChange}
					/>
				)}
				{listJobsEmpty && (
					<EmptyJobs
						style={{ marginTop: 0 }}
						title="There is no ongoing mission at this stage"
					/>
				)}
				{!listJobsEmpty && (
					<div>
						<TimesheetTableDetails spacing="0 3px">
							<thead>
								<tr>
									<Th width="18%">Mission title</Th>
									{(isCD || !isConsultancy) && (
										<Th width="10%">
											<>
												{isClient ? "Cost " : "Rate "}
												{isClient && (
													<GlobalTooltip
														overlay={`This is the hourly cost of the freelancer.
                        It displays the last approved rate (in case the mission has been extended).`}
													/>
												)}
											</>
										</Th>
									)}
									<Th width="10%">start date</Th>
									<Th width="10%">end date</Th>
									<Th width="10%">{isClient ? CONSULTANT : CLIENT}</Th>
									{isConsultancy && <Th width="10%">Consultant</Th>}
									{isConsultancy && <Th width="12%">Business unit</Th>}
									<Th align="center" width="12%">
										Status
									</Th>
									<Th width="10%">progress</Th>
									{!isConsultancy && <Th width="10%">actions</Th>}
								</tr>
							</thead>
							<tbody>{this.renderJobList()}</tbody>
						</TimesheetTableDetails>
						<Pagination
							total={pagination.total}
							size={pagination.size}
							offset={pagination.offset}
							handlePageClick={this.handlePageClick}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default Contracts;
