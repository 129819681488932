import React, { useState, useEffect } from "react";
import Tooltip from "rc-tooltip";
import { connect } from "react-redux";
import _get from "lodash/get";
import { Box, Flex, Text, Button as ButtonRebass } from "rebass";
import BackButton from "common/Buttons/BackButton";
import Avatar from "common/Avatar/index";
import {
	cvHeadlineSelector,
	cvSelector,
	cvAddressSelector
} from "../../selectors/cvSelectors";
import { Button } from "common/styled/buttons";
import { ReactComponent as SendIcon } from "common/Icons/send-request.svg";
import { ReactComponent as AddTaskIcon } from "static/icons/IconAddTask.svg";
import ModalSendRequest from "modules/freelancers/components/FreelancersTabSendRequestDrawer/FreelancersTabSendRequestDrawer";
import Description from "./Description";
import { excerpt } from "common/Functions";
import useListPagination from "hooks/useListPagination.js";
import { searchParser } from "config/helpers";
import { browserHistory } from "react-router";
import useSuppliersStore from "modules/home/zustand/SuppliersStore";
import {
	IN_MISSION,
	AVAILABLE,
	NOT_AVAILABLE,
	DATE_FORMAT,
	EXCERPT_LENGTH
} from "config";
import { FREELANCER } from "config";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

function ProfileContainerClientView({ bid_id, ownCV, data, address }) {
	const [displaySendRequestModal, setdisplaySendRequestModal] = useState(false);
	const {
		setIsOpen: openDrawerTask,
		setEntity,
		setSelectedEntityOption
	} = useAddNewTaskStore();

	const country = address && address.country;
	const city = address && address.city;

	const countryCityExists = !!country && !!city;
	const cityCountryStr = countryCityExists ? `${country}, ${city}` : "";
	let showTooltip = false;
	if (countryCityExists && cityCountryStr.length > EXCERPT_LENGTH) {
		showTooltip = true;
	}
	const searchQueries = searchParser();
	const welcomePageSupplierStore = useSuppliersStore();

	const { state, nextId, previousId } = useListPagination({
		data: _get(welcomePageSupplierStore, searchQueries.listKey, []),
		id: bid_id
	});
	useEffect(() => {
		browserHistory.replace(
			`/consultant/user/profile/${state.id}${window.location.search}`
		);
	}, [state.id]);
	const openTaskDrawer = () => {
		openDrawerTask(true);
		setEntity(FREELANCER);
		setSelectedEntityOption({
			label: `${data.first_name} ${data.last_name}`,
			value: data._id
		});
	};
	return (
		<>
			<Box pt={34} pl={15} pr={15} id="headline-section">
				<Flex
					mb={22}
					className="bottom-actions"
					justifyContent="space-between"
					alignItems="center"
				>
					<BackButton />
					{Boolean(searchQueries.listKey) && (
						<Flex>
							<Text
								onClick={previousId}
								as={"button"}
								disabled={state.previousDisabled}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "11px",
									fontWeight: "600",
									letterSpacing: "0.99px",
									color: "rgb(97, 105, 118)",
									textTransform: "uppercase",
									border: "none",
									background: "none",
									outline: "none",
									"&[disabled]": {
										color: "rgb(160,165,185)",
										cursor: "not-allowed"
									}
								}}
								mr={"10px"}
							>
								&larr; Previous
							</Text>
							<Text
								onClick={nextId}
								as={"button"}
								disabled={state.nextDisabled}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "11px",
									fontWeight: "600",
									letterSpacing: "0.99px",
									color: "rgb(97, 105, 118)",
									textTransform: "uppercase",
									border: "none",
									background: "none",
									outline: "none",
									"&[disabled]": {
										color: "rgb(160,165,185)",
										cursor: "not-allowed"
									}
								}}
							>
								next &rarr;
							</Text>
						</Flex>
					)}
				</Flex>
				<Flex
					sx={{
						backgroundColor: "rgb(248, 249, 250)",
						padding: "16px 28px 10px 28px"
					}}
					justifyContent="space-between"
				>
					<Flex>
						<Box
							mr={30}
							sx={{
								width: "125px",
								height: "125px",
								padding: "6px",
								borderRadius: "2px",
								boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.11)",
								border: "solid 0.5px rgb(221, 227, 232)",
								backgroundColor: "rgb(255, 255, 255)",
								position: "relative",
								top: "30px"
							}}
						>
							<Avatar
								src={data.avatar}
								name={`${data.first_name} ${data.last_name}`}
								size={110}
								radius={0}
							/>
						</Box>
						<Box mt={22}>
							<Text
								mb={12}
								as={"h2"}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "18px",
									fontWeight: "500",
									color: "#0f1c46"
								}}
							>
								{data.first_name && (
									<Flex className="profile-name" alignItems="center">
										{data.first_name} {data.last_name}
										{data.availability_status && (
											<div
												className={`availability ${data.availability_status} pull-right`}
											>
												{data.availability_status === IN_MISSION &&
													`On a mission until ${window.moment
														.unix(data.availability_date)
														.format(DATE_FORMAT)}`}
												{data.availability_status === AVAILABLE && "Available"}
												{data.availability_status === NOT_AVAILABLE &&
													`Not Available until: ${window.moment
														.unix(data.availability_date)
														.format(DATE_FORMAT)}`}
											</div>
										)}
									</Flex>
								)}
							</Text>
							<Flex alignItems="center" mb={12}>
								<Text
									as={"h3"}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "15px",
										color: "#0f1c46"
									}}
								>
									<Flex className="profile-details" alignItems="center">
										<Text
											as={"span"}
											mr={10}
											sx={{
												fontSize: "15px",
												color: "#3d4b60"
											}}
										>
											{_get(data, "main_function.name", "Your function")}
										</Text>
										<Text
											mr={10}
											as={"span"}
											sx={{
												fontSize: "14px",
												color: "#727e96"
											}}
										>
											({_get(data, "main_function.category")})
										</Text>
										<Box
											sx={{
												textTransform: "capitalize",
												color: "#4eb2d7",
												fontWeight: "700",
												fontSize: "12px",
												padding: "3px 6px",
												border: "1px solid #4eb2d7",
												borderRadius: "2px"
											}}
										>
											{_get(data, "main_function.seniority")}
										</Box>
									</Flex>
								</Text>
							</Flex>
							{data.about_me ? (
								<Box width="384px" className="text-about-freelancer">
									<Description content={data.about_me} ownCV={ownCV} />
								</Box>
							) : null}
						</Box>
					</Flex>
					<Box mt={20}>
						<Flex mb={16} justifyContent="flex-end">
							<Button
								small
								mr={10}
								className="btn-send-request"
								disabledHover
								onClick={() => setdisplaySendRequestModal(true)}
							>
								<SendIcon width={12} height={12} />
								&nbsp;&nbsp;Send request
							</Button>

							<ButtonRebass
								sx={{
									background: "none",
									border: "1px solid #ff6849",
									color: "#ff6849",
									fontSize: "14px",
									fontFamily: "BasierCircle",
									fontWeight: 700,
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}}
								onClick={openTaskDrawer}
							>
								<AddTaskIcon />
								&nbsp;&nbsp;Add task
							</ButtonRebass>
						</Flex>
						<Flex>
							{data.phone ? (
								<Flex mr={40}>
									<Flex
										mr={20}
										alignItems="center"
										justifyContent="center"
										sx={{
											width: "32px",
											height: "32px",
											backgroundColor: "rgb(238, 239, 242)",
											borderRadius: "32px"
										}}
									>
										<span className="icon-phone-handle" />
									</Flex>
									<Box>
										<Text
											as={"h4"}
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "12px",
												fontWeight: "600",
												letterSpacing: "1.08px",
												color: "rgb(117, 123, 134)",
												textTransform: "uppercase"
											}}
										>
											Phone{" "}
										</Text>
										<Text
											as={"span"}
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "14px",
												color: "#0f1c46"
											}}
										>
											{data.phone}
										</Text>
									</Box>
								</Flex>
							) : null}
							{data.email ? (
								<Flex mr={40}>
									<Flex
										mr={20}
										alignItems="center"
										justifyContent="center"
										sx={{
											width: "32px",
											height: "32px",
											backgroundColor: "rgb(238, 239, 242)",
											borderRadius: "32px"
										}}
									>
										<span className="icon-mail" />
									</Flex>
									<Box>
										<Text
											as={"h4"}
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "12px",
												fontWeight: "600",
												letterSpacing: "1.08px",
												color: "rgb(117, 123, 134)",
												textTransform: "uppercase"
											}}
										>
											Email{" "}
										</Text>
										<Text
											as={"span"}
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "14px",
												color: "#0f1c46"
											}}
										>
											{data.email}
										</Text>
									</Box>
								</Flex>
							) : null}

							{countryCityExists ? (
								<Flex mr={0}>
									<Flex
										mr={20}
										alignItems="center"
										justifyContent="center"
										sx={{
											width: "32px",
											height: "32px",
											backgroundColor: "rgb(238, 239, 242)",
											borderRadius: "32px"
										}}
									>
										<span className="icon-local" />
									</Flex>
									<Box>
										<Text
											as={"h4"}
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "12px",
												fontWeight: "600",
												letterSpacing: "1.08px",
												color: "rgb(117, 123, 134)",
												textTransform: "uppercase"
											}}
										>
											LOCATION{" "}
										</Text>
										<Text
											as={"span"}
											sx={{
												fontFamily: "BasierCircle",
												fontSize: "14px",
												color: "#0f1c46"
											}}
										>
											{showTooltip ? (
												<Tooltip
													placement="top"
													mouseEnterDelay={0}
													mouseLeaveDelay={0}
													overlay={<div>{cityCountryStr}</div>}
												>
													<span className="name">
														{excerpt(`${cityCountryStr}`, 20)}
													</span>
												</Tooltip>
											) : (
												<span className="name">{cityCountryStr}</span>
											)}
										</Text>
									</Box>
								</Flex>
							) : null}
						</Flex>
					</Box>
				</Flex>
			</Box>
			{displaySendRequestModal && (
				<ModalSendRequest
					isFreelancerProfile={true}
					onClose={() => setdisplaySendRequestModal(false)}
					freelancers={[data._id]}
					freelancerName={data.first_name}
				/>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		data: cvHeadlineSelector(state),
		address: cvSelector(state).preference
			? cvAddressSelector(state)
			: { country: "", city: "" }
	};
};

export default connect(mapStateToProps, null)(ProfileContainerClientView);
