import React, { useEffect } from "react";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import SetupGuideCard from "./components/SetupGuideCard";
import styles from "./setup-guide.module.scss";

export const getSetupGuidePermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export const getSetupGuideRoute = () => {
	return "/settings/setup-guide";
};

export default function SetupGuide() {
	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([{ name: "Settings" }, { name: "Set-up guide" }]);
	}, []);

	return (
		<div className={styles.root}>
			<SetupGuideCard />
		</div>
	);
}
