import { OPERATORS } from "common/FilterDrawer/utils";
import moment from "moment";

export const getSearchTimesheetsPayload = ({
	filters,
	logicalOperator,
	offset,
	sortBy,
	limit,
	query
}) => {
	return {
		op: logicalOperator,
		fields: formatFilters(filters),
		keywords: query,
		offset,
		sort_by: sortBy,
		limit
	};
};

export const formatFilters = filters => {
	const definedFilters = filters.filter(({ value, name }) => {
		// period filter is handled separately
		if (name === "period") {
			return false;
		}
		//Be careful about false value, filtering a filter with false value is a bug
		if (
			[undefined, null, ""].includes(value) ||
			(Array.isArray(value) && value.length === 0) ||
			(value !== null &&
				typeof value === "object" &&
				Object.keys(value).length === 0)
		) {
			return false;
		} else {
			return true;
		}
	});

	const periodFilterFormatted = [];
	const periodFilter = filters.find(f => f.name === "period");
	if (periodFilter && Array.isArray(periodFilter.value)) {
		const months = periodFilter.value?.[0]?.children;
		const weeks = periodFilter.value?.[1]?.children;
		if (months) {
			periodFilterFormatted.push({
				value: months.map(item => item.value),
				field: "timesheet_month",
				op: periodFilter.operator
			});
		}
		if (weeks) {
			periodFilterFormatted.push({
				value: weeks.map(item => item.value),
				field: "timesheet_week",
				op: periodFilter.operator
			});
		}
	}

	const filtersFormatted = definedFilters.map(filter => {
		const { name, operator, value } = filter;

		switch (name) {
			case "mission_title":
			case "profile_type":
			case "timesheet_frequency":
			case "external_manager":
			case "accounting_manager":
			case "po_number":
			case "mission_reference": {
				return {
					value: value,
					field: name,
					op: operator
				};
			}
			case "consultant":
			case "timesheet_manager":
			case "currency":
			case "status": {
				return {
					value: (value?.[0]?.children ?? []).map(v => v.id),
					field: name,
					op: operator
				};
			}
			case "start_date":
			case "end_date":
			case "submission_date":
			case "approval_date": {
				let formattedValue;
				if ("start" && "end" in value) {
					formattedValue = [
						dateToTimestamp(
							`${value.start.day}-${value.start.month}-${value.start.year}`
						),
						dateToTimestamp(
							`${value.end.day}-${value.end.month}-${value.end.year}`
						)
					];
				} else {
					formattedValue = [
						dateToTimestamp(`${value.day}-${value.month}-${value.year}`)
					];
				}
				return {
					value: formattedValue,
					field: name,
					op: operator
				};
			}
			case "files": {
				return {
					value: value ? "yes" : "no",
					field: name,
					op: operator
				};
			}
			case "total_amount":
			case "external_total_amount":
			case "work_time":
			case "expenses":
			case "over_time": {
				if (OPERATORS.isEmpty.value === operator) {
					return {
						value: value ? "yes" : "no",
						field: name,
						op: operator
					};
				} else {
					return {
						value: Number(value) || 0,
						field: name,
						op: operator
					};
				}
			}
		}
	});

	return [...filtersFormatted, ...periodFilterFormatted];
};

const dateToTimestamp = d => {
	return moment(d, "DD-MM-YYYY").unix();
};
