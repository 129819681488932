import React, { Component } from "react";
import { clearFields, Field, reduxForm } from "redux-form";
import _ from "lodash";
import { browserHistory } from "react-router";
import S3Uploader from "common/S3Uploader";
import { TYPE_DOCX, TYPE_DOC, TYPE_PDF, LEVEL } from "config";
import StyledSelect from "common/StyledSelect";
import { getGlobalOptions } from "modules/job/selectors";
import { LoaderFull } from "common/Loader";
import { getAccountActivatedRoute } from "../AccountActivated";
import { FunctionBox } from "../Styled";

class CVUploader extends Component {
	state = {
		showErrors: {},
		saving: false
	};

	goNext = () => {
		this.setState({ saving: true });
		return this.props
			.UploadUserCV({
				doc: _.get(this.props, "formFields.file.url"),
				sector: _.get(this.props, "formFields.subcategory._id"),
				function: _.get(this.props, "formFields.function._id"),
				seniority: _.get(this.props, "formFields.seniority.value")
			})
			.then(res => {
				this.setState({ saving: false });
				if (res) {
					browserHistory.push(getAccountActivatedRoute());
				}
			})
			.catch(() => {
				this.setState({ saving: false });
			});
	};

	handleClickChange = ({ name, disabled }) => {
		if (disabled && name) {
			if (!_.has(this.state.showErrors, name)) {
				return this.setState({ showErrors: { [name]: true } });
			}
		} else {
			if (_.size(this.state.showErrors)) {
				return this.setState({ showErrors: {} });
			}
		}
	};

	onSubCatChanged = e => {
		const sector_id = _.get(e, "_id", "");
		if (sector_id) {
			this.props.getFunctions({
				sector_id,
				no_limit: true,
				offset: 0
			});
		}

		this.resetSeniorityFunction();
	};

	resetField = (value1, value2, value3) => {
		this.props.dispatch(
			clearFields("cv-uploader", false, false, value1, value2, value3)
		);
	};

	resetSeniorityFunctionSector = () => {
		this.resetField("sector", "seniority", "function");
	};

	resetSeniorityFunction = () => {
		this.resetField("seniority", "function");
	};

	resetSeniority = () => {
		this.resetField("seniority");
	};

	onUploadSuccess = file => {
		this.props.changeReduxForm("file", file);
	};

	resetFile = () => {
		this.onUploadSuccess(null);
	};

	onUploadError = (errorMessage, error) => {
		const status = _.get(error, "response.data.status");
		this.props.showError(
			status ? `Status: ${status} ${errorMessage}` : errorMessage
		);
	};

	componentDidUpdate({ formFields: { category } }) {
		if (
			_.get(category, "label") !==
			_.get(this.props, "formFields.category.label")
		) {
			this.props.changeReduxForm("subcategory", "");
		}
	}

	progressComponent(data) {
		if (data) {
			return (
				<div className="file-progress-box">
					<div className="size">{`${data.percentCompleted.toFixed(0)}%`}</div>
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${data.percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	}

	renderUploader = ({ meta: { error, touched } }) => {
		return (
			<div className="col-sm-6 col-sm-offset-3 withMargin">
				<S3Uploader
					accept={[TYPE_PDF, TYPE_DOC, TYPE_DOCX]}
					id="cv-uploader"
					multiple={false}
					folder="cv"
					autoSubmit={true}
					onSuccess={this.onUploadSuccess}
					resetOnSuccess={false}
					onError={this.onUploadError}
					progressComponent={this.progressComponent}
					onClose={this.resetFile}
					text={{
						choose: "Upload your CV",
						label: "CV document",
						placeholder: "Upload a file from your computer.",
						icon: { text: "CV", class: "fa fa-file-o" }
					}}
					error={
						<div className="help-block inline-error">{touched && error}</div>
					}
				/>
			</div>
		);
	};

	render() {
		const {
			categories,
			subcategories,
			formFields: { category, subcategory },
			goBack,
			handleSubmit,
			submitFailed,
			functions
		} = this.props;

		const { showErrors, saving } = this.state;

		return (
			<div className="container">
				{saving && (
					<LoaderFull text="Uploading your CV" classes="full-loader" />
				)}

				<h3 className="text-center bigBottomMargin">Upload your CV</h3>

				<Field name="file" component={this.renderUploader} />

				<div className="col-sm-6 col-sm-offset-3">
					<Field
						name="category"
						label="Category"
						options={getGlobalOptions(categories, "name")}
						onChange={this.resetSeniorityFunctionSector}
						onClick={this.handleClickChange}
						rawData={categories}
						grouped={true}
						position={false}
						component={StyledSelect}
						valueField="name"
						placeholder="Choose your job's category."
						toolTipOverlay="This is your area of expertise (IT, Finance, HR, etc).
            This is related to your function; not the sector in which you work (e.g. if you are an IT Manager in a bank;
            your category is IT, and not finance)."
					/>

					<Field
						disabled={!category}
						name="subcategory"
						label="Subcategory"
						options={getGlobalOptions(subcategories, "name")}
						position={false}
						component={StyledSelect}
						onClick={this.handleClickChange}
						onChange={this.onSubCatChanged}
						valueField="_id"
						placeholder="Choose your job's sub-category."
						toolTipOverlay="Groups of functions within the above-mentioned category."
						showErrors={showErrors.subcategory || submitFailed}
					/>
					<FunctionBox>
						<div className="row">
							<div className="col-md-8">
								<Field
									disabled={!subcategory}
									name="function"
									label="Main Function"
									options={getGlobalOptions(functions, "name")}
									onChange={this.resetSeniority}
									onClick={this.handleClickChange}
									placeholder="Choose a function"
									position={false}
									component={StyledSelect}
									toolTipOverlay="The job title that best describes your current role within the
												sub-category of functions chosen earlier. Note that you will be able to add more
												functions later in the profile completion process; should it be applicable based
												on your professional experience."
									showErrors={showErrors.function || submitFailed}
								/>
							</div>
							<div className="col-md-4">
								<Field
									disabled={!subcategory}
									onClick={this.handleClickChange}
									name="seniority"
									options={LEVEL}
									label="Seniority"
									placeholder="Choose Seniority"
									position="left"
									component={StyledSelect}
									toolTipOverlay="The amount of experience that you have in the function (previous field)"
									showErrors={showErrors.seniority || submitFailed}
								/>
							</div>
						</div>
					</FunctionBox>
					<button
						className="btn btn-outline-new huge pull-left"
						onClick={goBack}
					>
						<span className="icon-arrow-left-new back" />
						back
					</button>
					<button
						className="btn btn-primary-new huge pull-right"
						onClick={handleSubmit(this.goNext)}
					>
						Continue
					</button>
				</div>
			</div>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.file) {
		errors.file = "Please select a file.";
	}
	if (!formProps.category) {
		errors.category = "Please select a category.";
		errors.subcategory = "Please select a category first.";
	}

	if (!formProps.subcategory) {
		errors.subcategory = "Please select a subcategory.";
		errors.seniority = "Please begin by selecting a subcategory first.";
		errors.function = "Please begin by selecting a subcategory first.";
	} else {
		if (!formProps.seniority) {
			errors.seniority = "Please select a seniority.";
		}
		if (!formProps.function) {
			errors.function = "Please begin by selecting a function.";
		}
	}

	return errors;
};

export default reduxForm({
	form: "cv-uploader",
	validate,
	touchOnBlur: false
})(CVUploader);
