import React from "react";
import SkeletonComponent from "./components/Skeleton";

export const GROUPS_CONTRACTS_LIST_PAGE_SIZE = 8;

export const CONTRACTS_GROUPS_LIST_LOADING_TABLE_DFINITION = [
	{
		Header: "Name",
		accessor: "name"
	},
	{
		Header: "Number of channels",
		accessor: "channelsNumber"
	},
	{
		Header: "Action",
		accessor: "action"
	}
];

export const CONTRACTS_GROUPS_LIST_LOADING_TABLE_DATA = new Array(5).fill({
	name: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	channelsNumber: (
		<SkeletonComponent width={60} height={14} borderRadius={10} />
	),
	action: <SkeletonComponent width={120} height={14} borderRadius={10} />
});

export const MY_CONTRACTS_LIST_LOADING_TABLE_DATA = new Array(5).fill({
	channel_name: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	group: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	user: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	expiration_date: (
		<SkeletonComponent width={60} height={14} borderRadius={10} />
	)
});
