import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
	showModalAction,
	closeModalAction,
	initialFunctions
} from "modules/job/actions/vacancyFunctionsActions";
import Functions from "./Functions";
import FunctionsModal from "./FunctionsModal";
import { showVacancyFunctionsModalSelector } from "modules/job/selectors/vacancyFunctionsSelectors";

function FunctionsContainer({
	showModal,
	showModalAction,
	closeModalAction,
	initialFunctions,
	functions,
	sectors,
	onSave,
	skillsCategories,
	removeSkills,
	hasError
}) {
	useEffect(() => {
		return () => {
			initialFunctions();
		};
	}, []);
	return (
		<>
			<Functions
				sectors={sectors}
				functions={functions}
				showModal={showModalAction}
				hasError={hasError}
			/>
			{showModal && (
				<FunctionsModal
					functions={functions}
					onClose={closeModalAction}
					onSave={onSave}
					skillsCategories={skillsCategories}
					removeSkills={removeSkills}
				/>
			)}
		</>
	);
}

const mapStateToProps = state => {
	return {
		showModal: showVacancyFunctionsModalSelector(state)
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ showModalAction, closeModalAction, initialFunctions },
		dispatch
	);

FunctionsContainer.propTypes = {
	functions: PropTypes.array,
	showModalAction: PropTypes.func,
	closeModalAction: PropTypes.func,
	initialFunctions: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(FunctionsContainer);
