import create from "zustand";

const useOpenBidDetailsDrawer = create(set => ({
	open: false,
	bidId: null,
	setBidId: bidId => set({ bidId }),
	setOpen: open => set({ open })
}));

export default useOpenBidDetailsDrawer;
