import React, { useEffect } from "react";
import classnames from "./show-more-modal.module.scss";
import { ReactComponent as DeleteIcon } from "static/icons/close-icon-tasks.svg";
import avatar from "static/images/Avatar-default.png";
import get from "lodash/get";

const ShowMoreModal = ({
	data,
	title,
	closeModal,
	deleteFilterValue,
	isUser
}) => {
	const renderAvatar = user => {
		if (Boolean(get(user, "avatar"))) return get(user, "avatar", false);
		return avatar;
	};
	useEffect(() => {
		return () => {
			closeModal();
		};
	}, []);

	const label = item =>
		isUser
			? get(item, "first_name") + " " + get(item, "last_name")
			: get(item, "title");

	const handleCloseModal = e => {
		e.stopPropagation();
		closeModal();
	};

	const deleteFilterValueHandler = (e, index) => {
		e.stopPropagation();
		deleteFilterValue(index);
	};

	return (
		<div className={classnames.container}>
			<div className={classnames.modalInterviewHeader}>
				<span className={classnames.title}> {title} </span>
				<button onClick={handleCloseModal} className={classnames.closeModal}>
					<DeleteIcon />
				</button>
			</div>
			<div className={classnames.modalBody}>
				{data.map((item, index) => (
					<div key={get(item, "_id")} className={classnames.item}>
						<div className={classnames.leftSide}>
							{isUser && (
								<img className={classnames.avatar} src={renderAvatar(item)} />
							)}
							<div className={classnames.itemDetail}>
								<span>{label(item)}</span>
								{isUser && <span>{get(item, "user_function", "")}</span>}
							</div>
						</div>

						<button onClick={e => deleteFilterValueHandler(e, index)}>
							<DeleteIcon />
						</button>
					</div>
				))}
			</div>
		</div>
	);
};

export default ShowMoreModal;
