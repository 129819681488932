import { ButtonGroup as ButtonGroupReactSpectrum } from "@adobe/react-spectrum";
import React from "react";
import Button from "../Button/Button";

const ButtonGroup = ({ buttons, gap = "20px" }) => {
	return (
		<ButtonGroupReactSpectrum>
			{buttons.map(
				({ variant, text, className, icon, onClick, ...rest }, i) => {
					let styles = {};

					if (i !== 0) {
						styles = { marginLeft: gap };
					}

					return (
						<Button
							inlineStyle={styles}
							key={text}
							variant={variant}
							text={text}
							className={className}
							icon={icon}
							onClick={onClick}
							{...rest}
						/>
					);
				}
			)}
		</ButtonGroupReactSpectrum>
	);
};

export default ButtonGroup;
