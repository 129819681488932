import React, { useState } from "react";
import Modal from "common/modal/Modal";
import { Flex, Box, Text } from "rebass";
import { Input } from "@rebass/forms";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAddVatNumber from "modules/bids/components/modals/hooks/useAddVatNumber";
import toaster from "common/Toaster";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { getMyProfile } from "modules/user/actions/authActions";

const AddVatResubmitModal = ({
	onClose,
	handleOnReSubmitTimesheet,
	...props
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(
			yup.object().shape({
				vat: yup.string().required("VAT number is required")
			})
		)
	});
	const [addVatNumber, { isLoading }] = useAddVatNumber();
	const dispatch = useDispatch();
	const [vatError, setVatError] = useState("");

	const onSubmit = values => {
		addVatNumber(
			{ vat: values.vat },
			{
				onSuccess: () => {
					handleOnReSubmitTimesheet();
					dispatch(getMyProfile());

					onClose();
				},
				onError: error => {
					let errorMessage = get(error, "detail");

					if (error.status === 422) {
						if (typeof errorMessage === "object") {
							Object.keys(errorMessage).forEach(name => {
								get(errorMessage, name) &&
									setVatError(
										typeof get(errorMessage, name) === "string"
											? get(errorMessage, name)
											: get(errorMessage, name)[0]
									);
							});
						} else {
							toaster.danger(errorMessage);
						}
						return;
					} else {
						toaster.danger("An error has occurred");
					}
				}
			}
		);
	};
	return (
		<Modal onClose={onClose} isLoading={isLoading} {...props}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box mt={10} mb={75}>
					<Text
						mb={35}
						as={"h2"}
						sx={{
							fontSize: "37.5px",
							fontWeight: "300",
							letterSpacing: "-0.49px",
							color: "rgb(253, 104, 73)"
						}}
					>
						Add VAT
					</Text>
					<Text
						sx={{
							fontFamily: "Roboto",
							fontSize: "16px",
							letterSpacing: "-0.21px",
							color: "rgb(80, 92, 104)",
							width: "80%"
						}}
						mb={44}
					>
						You need to add your VAT number in order to proceed
					</Text>
					<Flex mb={15} flexDirection={"column"}>
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								fontWeight: "bold",
								letterSpacing: "-0.18px",
								color: "#58626b",
								marginBottom: "10px"
							}}
						>
							VAT number <span style={{ color: "red" }}>*</span>
						</Text>
						<Controller
							name={"vat"}
							control={control}
							render={({ field: { onChange, value } }) => (
								<>
									<Input
										onChange={onChange}
										type="text"
										placeholder="Type VAT number here"
										value={value}
										style={{
											border: (errors.vat || vatError) && "1px solid red"
										}}
									/>
									{errors.vat && (
										<Text sx={{ color: "red", marginTop: "5px" }}>
											{errors.vat.message}
										</Text>
									)}
									{vatError && (
										<Text sx={{ color: "red", marginTop: "5px" }}>
											{vatError}
										</Text>
									)}
								</>
							)}
						/>
					</Flex>
				</Box>

				<div className="actions">
					<div className="row">
						<div className="col-md-3">
							<button
								className="btn btn-outline-new btn-block"
								onClick={onClose}
							>
								Cancel
							</button>
						</div>
						<div className="col-md-4">
							<button
								type="submit"
								className="btn btn-primary-new btn-block"
								disabled={isLoading}
							>
								Save and submit
							</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default AddVatResubmitModal;
