import { queryCache } from "react-query";
import { API_URL } from "config";
import { GET_LAST_JOB } from "config/api-endpoints";
import { deleteCookie, historyPush } from "config/helpers";
import { getVacancyListRoute } from "modules/vacancy/containers/VacancyListContainer";
import { COOKIE_COMPANY, COOKIE_TOKEN } from "config";
const localStorageKey = "current_user";

function client(endpoint, { body, ...customConfig } = {}, options) {
	const user = window.localStorage.getItem(localStorageKey);
	const headers = { "content-type": "application/json" };
	const controller = new AbortController();

	if (user) {
		const token = JSON.parse(user).access_token;
		headers.Authorization = `Bearer ${token}`;
	}
	const config = {
		method: body ? "POST" : "GET",
		signal: controller.signal,
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers
		}
	};

	if (body) {
		config.body = JSON.stringify(body);
	}

	const promise = window
		.fetch(`${API_URL}${endpoint}`, config)
		.then(async r => {
			if (r.status === 401) {
				if (options?.on401) {
					options?.on401();
				} else {
					logout();
					window.location.href = "/sign-in";
					return;
				}
			}

			if (r.ok && customConfig.isBlob) {
				return Promise.resolve(r.blob());
			}

			if (r.status === 202) {
				return Promise.resolve();
			}

			if (r.status === 403 && endpoint === GET_LAST_JOB) {
				historyPush(getVacancyListRoute());
				return;
			}

			const data = await r.json();
			if (r.ok) {
				return data;
			} else {
				return Promise.reject(data);
			}
		});

	promise.cancel = () => {
		controller.abort();
	};

	return promise;
}
function logout() {
	deleteCookie(COOKIE_COMPANY);
	deleteCookie(COOKIE_TOKEN);
	queryCache.clear();
	window.localStorage.removeItem(localStorageKey);
}
export { client, localStorageKey, logout };
