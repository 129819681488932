import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { SEARCH_SUPPLIERS } from "config/api-endpoints";

const getSupplierList = keyword =>
	client(SEARCH_SUPPLIERS, {
		body: {
			tag: "search",
			company_name: keyword,
			create_mission: true
		}
	});

export const GET_SUPPLIERS_QUERY_KEY = "GET_SUPPLIERS_QUERY_KEY";

export const useGetSupplierList = (type, value) => {
	const res = useQuery(
		[GET_SUPPLIERS_QUERY_KEY, value],
		() => getSupplierList(value),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			enabled: type !== "freelancer",
			keepPreviousData: true
		}
	);

	return res;
};
