import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { EVALUATE_CANDIADTE_VIEW_DETAILS } from "config/api-endpoints";

export const queryFn = body =>
	client(EVALUATE_CANDIADTE_VIEW_DETAILS, { body });

export const EVALUATION_CANDIDATE_VIEW_DETAILS =
	"EVALUATION_CANDIDATE_VIEW_DETAILS";

export const useGetPermanentEvaluationDetails = (payload, options = {}) =>
	useQuery(
		[EVALUATION_CANDIDATE_VIEW_DETAILS, payload],
		() => queryFn(payload),
		{
			retry: 1,
			refetchOnWindowFocus: false,
			...options
		}
	);
