import React from "react";
const ResendActivation = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.8335 7.05339C1.8335 7.05339 3.17015 5.2322 4.25605 4.14555C5.34196 3.0589 6.84256 2.38672 8.50016 2.38672C11.8139 2.38672 14.5002 5.07301 14.5002 8.38672C14.5002 11.7004 11.8139 14.3867 8.50016 14.3867C5.76477 14.3867 3.4569 12.5562 2.73467 10.0534M1.8335 7.05339V3.05339M1.8335 7.05339H5.8335"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default ResendActivation;
