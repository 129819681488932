import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { LoadingStateContainer } from "../styles";

const LoadingState = () => {
	return (
		<LoadingStateContainer>
			<div className="header">
				<SkeletonTheme style={{ borderRadius: 4 }} color="#D2D5D8">
					<Skeleton width={150} height={10} />
				</SkeletonTheme>
			</div>

			<div className="body">
				<SkeletonTheme style={{ borderRadius: 4 }} color="#D2D5D8">
					<Skeleton width={100} height={10} />
				</SkeletonTheme>
				<SkeletonTheme style={{ borderRadius: 4 }} color="#D2D5D8">
					<Skeleton width={100} height={10} />
				</SkeletonTheme>
			</div>
		</LoadingStateContainer>
	);
};

export default LoadingState;
