import * as types from "../actions/actionTypes";
import { RESPONSE_ACCEPTED, HTTP_POST_METHOD } from "config";
import {
	HTTP_SWITCH_COMPANY,
	READ_MY_PROFILE,
	CHANGE_COMPANY_STATUS_ENDPOINT,
	HTTP_UPDATE_COMPANY_ADDITIONAL_INFO,
	UPLOAD_CV
} from "config/api-endpoints";
import { getMyProfile } from "../../user/actions/authActions";
import axiosInstance from "config/axios";
import AxiosHelper from "config/axios-helper";
import { UPDATE_PROFILE } from "config/api-endpoints";
import { UPDATE_COMPANY_ADDRESS } from "../../../config/api-endpoints";

export function switchCompany(company) {
	return {
		type: types.SWITCHED_COMPANY,
		isFetching: false,
		company
	};
}

export function startSwitch() {
	return {
		type: types.SWITCH_COMPANY,
		isFetching: true
	};
}

export function toggleSubmitting() {
	return {
		type: types.TOGGLE_SUBMITTING
	};
}

export function doSwitch(company) {
	return dispatch => {
		dispatch(startSwitch());
		axiosInstance({
			url: HTTP_SWITCH_COMPANY,
			method: HTTP_POST_METHOD,
			data: JSON.stringify({ active_company: company })
		}).then(response => {
			if (response && response.status === RESPONSE_ACCEPTED) {
				dispatch(getMyProfile());
				dispatch(switchCompany(company));
			} else {
				return Promise.reject("Couldn't switch company");
			}
		});
	};
}

export function companyChangeAvatar(company, avatar) {
	return {
		type: types.COMPANY_UPDATE_AVATAR,
		company,
		avatar
	};
}

export function UploadUserCV(data) {
	return dispatch => {
		return readMyProfile().then(() =>
			axiosInstance({
				method: HTTP_POST_METHOD,
				data: JSON.stringify(data),
				url: UPLOAD_CV
			}).then(response => {
				if (response && response.status === RESPONSE_ACCEPTED) {
					changeCompanyStatus().then(() => {
						dispatch(getMyProfile());
					});
					return response;
				}
			})
		);
	};
}

async function changeCompanyStatus() {
	await axiosInstance({
		url: CHANGE_COMPANY_STATUS_ENDPOINT,
		method: HTTP_POST_METHOD,
		data: JSON.stringify({ status: "activated" })
	});
}

function readMyProfile() {
	return AxiosHelper.post({
		url: READ_MY_PROFILE
	});
}

export function updateCompanyAdditionalInfo(data, reduxFormName) {
	return AxiosHelper.__post({
		url: HTTP_UPDATE_COMPANY_ADDITIONAL_INFO,
		toastMessage: "update done.",
		toasterID: "done",
		reduxFormName,
		data
	});
}

export function updateClientProfileInfo(
	data,
	reduxFormName,
	toasterDuration = 5 // 5 seconds
) {
	return AxiosHelper.__post({
		url: UPDATE_PROFILE,
		next: getMyProfile,
		toastMessage: "Profile update done.",
		toasterID: "done",
		toasterDuration,
		reduxFormName,
		data
	});
}

export function updateCompanyAddress(payload) {
	return AxiosHelper.__post({
		url: UPDATE_COMPANY_ADDRESS,
		isModal: true,
		data: {
			street: payload.street,
			country: payload.country,
			zip: payload.zip,
			city: payload.city,
			number: payload.number,
			box: payload.box,
			latitude: payload.latitude,
			longitude: payload.longitude,
			iso_country: payload.iso_country,
			viewport: payload.viewport
		},
		toastMessage: "Company address has successfully been updated."
	});
}
