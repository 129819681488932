import React, { useEffect } from "react";
import { Flex } from "rebass";

const Tab = ({ id, title, setTabs, activeTab, children, px, py }) => {
	useEffect(() => {
		setTabs(tabs => [...tabs, { id, title }]);
	}, []);
	useEffect(() => {
		setTabs(tabs =>
			tabs.map(tab => {
				if (tab.id === "history" && id === tab.id) {
					return {
						id,
						title
					};
				}
				return tab;
			})
		);
	}, [activeTab]);
	return (
		id === activeTab && (
			<Flex
				flexDirection={"column"}
				flex={1}
				px={px || 14}
				py={py || 14}
				sx={{
					borderBottom: "1px solid #dfe3e8",
					height: "602px",
					position: "relative"
				}}
			>
				{children}
			</Flex>
		)
	);
};

export default Tab;
