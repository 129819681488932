import React, { useCallback, useEffect, useState } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { CustomDropDown } from "common/CustomDropDown";

const FieldDropDown = ({ filter }) => {
	const { getState, currentFilterId, replaceFilter } = useFilters();
	const { columns } = getState(currentFilterId);
	const [options, setOptions] = useState([]);

	const handleChange = useCallback(
		value => {
			const column = columns.find(column => column.name === value);

			const newFilter = {
				...filter,
				name: column.name,
				type: column.type,
				operator: column.operator.value,
				label: column.label,
				payload: column.payload,
				value: ""
			};

			replaceFilter(newFilter);
		},
		[replaceFilter, columns]
	);

	useEffect(() => {
		const options = columns.map(({ label, name, icon }) => ({
			label,
			value: name,
			icon
		}));

		setOptions(options);
	}, [columns]);

	return (
		<CustomDropDown
			options={options}
			value={filter.name}
			onChange={handleChange}
		/>
	);
};

export default FieldDropDown;
