import React from "react";
import useConfirmationStore from "modules/publishJob/store/useConfirmationStore";
import usePostingRequirementStore from "modules/publishJob/store/usePostingRequirementStore";
import CardSkeleton from "../channelCard/CardSkeleton";
import img from "static/images/wiggli-logo-blue.png";
import ChannelCard from "../channelCard";

const FreeChannelsList = ({
	loading,
	channels,
	isContractSelected,
	freeChannelSelectionBucket,
	showMyCandidatesChannel,
	showTalentPoolChannel
}) => {
	const { setvalue, freeChannels } = usePostingRequirementStore();

	const { addDynamicFreeChannel } = useConfirmationStore();

	const handlePinnedChannelChange = (e, id) => {
		e.stopPropagation();
		freeChannelSelectionBucket.actions.row(id, isContractSelected);
		setvalue("freeChannels", {
			...freeChannels,
			[id]: !freeChannels[id]
		});
	};

	const handleFreeChannelChange = (e, item) => {
		e.stopPropagation();
		addDynamicFreeChannel(item);
		freeChannelSelectionBucket.actions.row(item.product_id, isContractSelected);
	};

	return loading ? (
		Array.from(Array(8).keys()).map((_, index) => <CardSkeleton key={index} />)
	) : (
		<>
			{showMyCandidatesChannel && (
				<ChannelCard
					key="my_candidates"
					id="my_candidates"
					title="Wiggli"
					description="My candidates - company’s private candidate pool"
					checked={freeChannelSelectionBucket.helpers.isFreeChannelSelected(
						"my_candidates"
					)}
					handleCheck={e => {
						handlePinnedChannelChange(e, "my_candidates");
					}}
					src={img}
				/>
			)}
			{showTalentPoolChannel && (
				<ChannelCard
					key="talent_pool"
					id="talent_pool"
					title="Wiggli"
					description="Wiggli talent pool - All candidates with a wiggli account"
					checked={freeChannelSelectionBucket.helpers.isFreeChannelSelected(
						"talent_pool"
					)}
					handleCheck={e => {
						handlePinnedChannelChange(e, "talent_pool");
					}}
					src={img}
				/>
			)}
			{channels.map(function Card(item) {
				return (
					<ChannelCard
						key={item.product_id}
						id={item.product_id}
						title={item.channel.name}
						description={item.description}
						checked={freeChannelSelectionBucket.helpers.isFreeChannelSelected(
							item.product_id
						)}
						handleCheck={e => handleFreeChannelChange(e, item)}
						src={item.logo_url[0].url || img}
					/>
				);
			})}
		</>
	);
};

export default FreeChannelsList;
