import React, { useRef, useEffect, useState, useMemo } from "react";
import { SearchInput } from "common/SearchInput";
import debounce from "lodash/debounce";
import styles from "./messaging-tool-filter-dropdown.module.scss";
import MessaginToolSearchDropdown from "../MessaginToolSearchDropdown/MessaginToolSearchDropdown";
import {
	FROM,
	FROM_INPUT_LABEL,
	HAS_ATTACHMENT,
	WORDS,
	PARTICIPANTS_LIMIT,
	SUBJECT,
	TO,
	TO_INPUT_LABEL,
	USERS_AUTOCOMPLETE
} from "modules/MessagingTool/utils/constant";
import { Controller, useForm } from "react-hook-form";
import useOnClickOutside from "hooks/useOnClickOutside";
import { ReactComponent as AttachementIcon } from "static/icons/attachment-02.svg";
import MessagingToolAutoCompleteInput from "../MessagingToolAutoCompleteInput/MessagingToolAutoCompleteInput";
import { Checkbox } from "common/AgTable/components/Checkbox";
import { Button } from "common/Button";
import { addFiltersToUrl } from "modules/MessagingTool/utils/helpers";
import { historyReplace } from "config/helpers";
import MessagingToolFilterItemTag from "../MessagingToolFilterItemTag/MessagingToolFilterItemTag";
import useGetUserEmail from "common/MessagingToolNewEmail/api/useGetUserEmail";
import { filterAlreadySelectedParticipants } from "common/MessagingToolNewEmail/utils/helpers";
import useAdvancedFilter from "common/MessagingToolEmailsList/hooks/useAdvancedFilter";
import { getMessagingToolRoute } from "modules/MessagingTool/MessagingTool";

const MessagingToolFilterDropdown = ({
	onClose,
	hash,
	onClearFilter,
	onSubmit: onSubmitProp
}) => {
	const autocompleteInputRef = useRef({});
	const autocompleteDropdownRef = useRef();
	const [autocompleteKeyword, setQuery] = useState("");
	const [activeInput, setActiveInput] = useState("");
	const { data, isLoading } = useGetUserEmail({
		filter: USERS_AUTOCOMPLETE,
		limit: PARTICIPANTS_LIMIT,
		keyword: autocompleteKeyword
	});
	const { control, handleSubmit, setValue, getValues, watch, reset } = useForm({
		defaultValues: {
			from: [],
			to: []
		}
	});
	const { setFilters, filters } = useAdvancedFilter();

	const participantsAlreadySelected = [...watch(TO), ...watch(FROM)];

	const participants = useMemo(
		() =>
			filterAlreadySelectedParticipants(
				data?.participants,
				participantsAlreadySelected
			),
		[data, watch(TO), watch(FROM)]
	);

	useOnClickOutside(autocompleteDropdownRef, () => setActiveInput(""));

	const debouncedOnSearch = debounce(value => {
		setQuery(value);
	}, 500);

	useEffect(() => {
		if (filters) {
			reset(filters);
		}
	}, []);

	const onSearch = e => {
		debouncedOnSearch(e.target.value);
	};

	const onSelect = (name, email) => {
		const prevValues = getValues(name);
		let options = [];

		if (prevValues) {
			options = [...prevValues, email];
		} else options = [email];
		setValue(name, options);
		setQuery("");

		autocompleteInputRef.current[name].focus();
		autocompleteInputRef.current[name].value = "";
	};

	const onDelete = (name, email) => {
		let filteredEmails = getValues(name).filter(item => item !== email);
		setValue(name, filteredEmails);
	};

	const onSubmit = data => {
		const queryString = addFiltersToUrl(data);
		setFilters(data);
		historyReplace(getMessagingToolRoute(`${hash}?${queryString}`));
		onClose();
		onSubmitProp(data);
	};

	return (
		<form className={styles.dropdown} onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.field}>
				<label className={styles.fieldLabel}>
					From:
					<Controller
						control={control}
						name={FROM}
						render={({ field: { value } }) => (
							<MessagingToolAutoCompleteInput
								onChange={event => {
									if (!autocompleteKeyword) {
										setActiveInput(FROM_INPUT_LABEL);
									}
									onSearch(event);
								}}
								ref={ref => (autocompleteInputRef.current[FROM] = ref)}
							>
								{value?.map(email => (
									<MessagingToolFilterItemTag
										key={email}
										item={email}
										name={FROM}
										onDelete={onDelete}
									/>
								))}
							</MessagingToolAutoCompleteInput>
						)}
					/>
					{activeInput === FROM_INPUT_LABEL &&
						autocompleteKeyword.length !== 0 && (
							<MessaginToolSearchDropdown
								participants={participants}
								isLoading={isLoading}
								className={styles.searchDropdown}
								onSelect={email => onSelect(FROM, email)}
								ref={autocompleteDropdownRef}
							/>
						)}
				</label>
			</div>
			<div className={styles.field}>
				<label className={styles.fieldLabel}>
					To:
					<Controller
						control={control}
						name={TO}
						render={({ field: { value } }) => (
							<MessagingToolAutoCompleteInput
								onChange={event => {
									if (!autocompleteKeyword) {
										setActiveInput(TO_INPUT_LABEL);
									}
									onSearch(event);
								}}
								ref={ref => (autocompleteInputRef.current[TO] = ref)}
							>
								{value?.map(email => (
									<MessagingToolFilterItemTag
										key={email}
										item={email}
										name={TO}
										onDelete={onDelete}
									/>
								))}
							</MessagingToolAutoCompleteInput>
						)}
					/>
					{activeInput === TO_INPUT_LABEL &&
						autocompleteKeyword.length !== 0 && (
							<MessaginToolSearchDropdown
								participants={participants}
								isLoading={isLoading}
								className={styles.searchDropdown}
								onSelect={email => onSelect(TO, email)}
								ref={autocompleteDropdownRef}
							/>
						)}
				</label>
			</div>
			<div className={styles.field}>
				<label className={styles.fieldLabel}>
					Subject:
					<Controller
						name={SUBJECT}
						control={control}
						render={({ field: { value, onChange } }) => (
							<SearchInput
								inputClassName={styles.inputClassName}
								variant="borderless"
								onChange={onChange}
								value={value}
								icon={<></>}
								placeholder=""
							/>
						)}
					/>
				</label>
			</div>
			<div className={styles.field}>
				<label className={styles.fieldLabel}>
					Has words:
					<Controller
						name={WORDS}
						control={control}
						render={({ field: { value, onChange } }) => (
							<SearchInput
								inputClassName={styles.inputClassName}
								variant="borderless"
								onChange={onChange}
								value={value}
								icon={<></>}
								placeholder=""
							/>
						)}
					/>
				</label>
			</div>
			<Controller
				name={HAS_ATTACHMENT}
				control={control}
				render={({ field: { onChange, value } }) => (
					<div className={styles.checkboxWrapper}>
						<Checkbox
							id={HAS_ATTACHMENT}
							checked={value}
							onChange={() => onChange(!value)}
							className={styles.checkbox}
						/>
						<AttachementIcon />
						<label className={styles.label}>Has attachment</label>
					</div>
				)}
			/>
			<div className={styles.footer}>
				<Button
					text="Clear"
					variant="outlined"
					type="button"
					onClick={() => {
						onClearFilter();
						onClose();
						historyReplace(getMessagingToolRoute(hash));
					}}
					className={styles.clearButton}
				/>
				<Button text="Search" type="submit" />
			</div>
		</form>
	);
};

export default MessagingToolFilterDropdown;
