import React from "react";
import CurrencyInput from "common/NewCurrencyField/NewCurrencyField";

const AmountInput = ({ onChange, value }) => (
	<CurrencyInput
		input={{
			onChange: value => onChange(value, "change"),
			value
		}}
		useGreenTheme
	/>
);

export default AmountInput;
