import React from "react";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import styles from "./rating.module.scss";

const Rating = ({
	interactive = true,
	value,
	onChange,
	displayText = true
}) => {
	return (
		<div className={styles.root} onClick={e => e.stopPropagation()}>
			<Rater
				rating={value}
				interactive={interactive}
				onRate={e => onChange(e.rating)}
			/>
			{displayText && <span className={styles.text}>{value}/5</span>}
		</div>
	);
};

export default Rating;
