import React from "react";
import styles from "./request-pipeline-money-card.module.scss";
import cx from "classnames";
import { ReactComponent as MoneyIcon } from "static/icons/coins-2.svg";
import Tooltip from "common/Tippy";

const RequestPipelineMoneyCard = ({ className, amount, tooltipContent }) => {
	return (
		<div className={cx(styles.container, className)}>
			<MoneyIcon height={20} width={20} />
			<Tooltip content={tooltipContent} theme="dark">
				<div className={styles.dayRate}>{amount}</div>
			</Tooltip>
		</div>
	);
};

export default RequestPipelineMoneyCard;
