/* eslint-disable react/jsx-key */
import React, { useMemo } from "react";
import get from "lodash/get";
import s from "modules/candidate/Profile/Pages/Profile/profile.module.scss";
import SkeletonComponent from "modules/candidate/Profile/components/Skeleton";
import { formatDate } from "config/helpers";
import { ReactComponent as TypeIcon } from "static/icons/status-icon.svg";
import { ReactComponent as UserIcon } from "static/icons/user-icon.svg";
import { ReactComponent as CreationDateIcon } from "static/icons/creation-date-icon.svg";
import { ReactComponent as BotIcon } from "static/icons/bot.svg";

import {
	NEW_VALUE,
	CREATION_DATE,
	EDITED_BY,
	EDITED_FIELD,
	PREVIOUS_VALUE,
	SKILLS,
	FUNCTIONS,
	LANGUAGES,
	PREFERRED_LOCATION,
	VACANCY_LEVEL,
	UPLOAD_CV,
	PROFILE_TAGS,
	EDITED_VALUE,
	FIRST_NAME,
	LAST_NAME,
	PHONES,
	EMAILS,
	PRIMARY,
	LINKS,
	EMPLOYMENT_TYPES,
	SALARY,
	PAYMENT_TYPE,
	CURRENCY,
	PAYROLL_COUNTRY,
	PAYMENT_TIME,
	NOTICE,
	EXTRA_BENEFITS,
	HISTORY_FIELDS,
	UPDATED_BY,
	PROFILE_CREATION
} from "config";
import GlobalTooltip from "common/GlobalTooltip";
import _get from "lodash/get";
import BasicPopover from "./components/Popover";
import { ReactComponent as SeparatorIcon } from "static/icons/separator-point.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle.svg";
import CurrencyFormatter from "common/CurrencyFormater";
import { excerpt } from "common/Functions";

export const sortBylist = [
	{
		field: CREATION_DATE,
		icon: <CreationDateIcon />,
		label: "Edited on",
		options: []
	},
	{
		field: UPDATED_BY,
		icon: <UserIcon />,
		label: "Edited by",
		options: []
	},
	{
		field: EDITED_FIELD,
		icon: <TypeIcon />,
		label: "Edited field",
		options: []
	},
	{
		field: PREVIOUS_VALUE,
		icon: <TypeIcon />,
		label: "Previous value",
		options: []
	},
	{
		field: NEW_VALUE,
		icon: <TypeIcon />,
		label: "New value",
		options: []
	}
];

const getPhoneNumber = phone => {
	return (
		<>
			{"+"}
			{phone.replace(/\D/g, "")}
		</>
	);
};
export const columns = sortedField => [
	{
		Header: "Edited on",
		accessor: CREATION_DATE,
		isSorted: sortedField === CREATION_DATE
	},
	{
		Header: "Edited by",
		accessor: EDITED_BY,
		isSorted: sortedField === EDITED_BY
	},
	{
		Header: "Edited field",
		accessor: EDITED_FIELD,
		isSorted: sortedField === EDITED_FIELD
	},
	{
		Header: "Previous value",
		accessor: PREVIOUS_VALUE,
		isSorted: sortedField === PREVIOUS_VALUE
	},
	{
		Header: "New value",
		accessor: EDITED_VALUE,
		isSorted: sortedField === EDITED_VALUE
	}
];

export const dataSkeleton = new Array(9).fill({
	created_at: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	description: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	note_type: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	linked_to_notes: (
		<SkeletonComponent width={60} height={14} borderRadius={10} />
	),
	created_by: <SkeletonComponent width={60} height={14} borderRadius={10} />
});
const MAX_CHAR = 30;
const renderWithTooltip = value => {
	return <BasicPopover value={value} />;
};

const renderFirstName = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			<span>{data}</span>
		</div>
	);
};
const renderLastName = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			<span>{data}</span>
		</div>
	);
};

const renderPhones = data => {
	if (data.length === 0) {
		return "---";
	}
	const phones = data.map(
		(item, index) =>
			index < 2 && (
				<div>
					<span>{getPhoneNumber(item.phone)}</span>
					{index !== data.length - 1 && <span> &nbsp;-&nbsp; </span>}
				</div>
			)
	);
	const slicedPhones = data.map(
		(item, index) =>
			index >= 2 && (
				<div>
					<span>{getPhoneNumber(item.phone)}</span>
				</div>
			)
	);
	return (
		<div className={s.editContainer}>
			{phones}
			{phones.length > 2 && <span>{renderWithTooltip(slicedPhones)}</span>}
		</div>
	);
};
const renderEmails = data => {
	if (data.length === 0) {
		return "---";
	}
	const emails = data.map(
		(item, index) =>
			index < 2 && (
				<div className={s.textSeparator}>
					<span>{item.email}</span>
					{index !== data.length - 1 && <span> &nbsp;-&nbsp; </span>}
				</div>
			)
	);

	const slicedEmails = data.map(
		(item, index) =>
			index >= 2 && (
				<div>
					<span>{item.email}</span>
				</div>
			)
	);
	return (
		<div className={s.editContainer}>
			{emails}
			{emails.length > 2 && <span>{renderWithTooltip(slicedEmails)}</span>}
		</div>
	);
};
const renderLinks = data => {
	if (data.length === 0) {
		return "---";
	}
	const links = data.map(
		(item, index) =>
			index < 2 && (
				<div>
					<span>{item.link}</span>
					{index !== data.length - 1 && <span> &nbsp;-&nbsp; </span>}
				</div>
			)
	);
	const slicedLinks = data.map(
		(item, index) =>
			index >= 2 && (
				<div>
					<span>{item.link}</span>
				</div>
			)
	);
	return (
		<div className={s.editContainer}>
			{links}
			{links.length > 2 && <span>{renderWithTooltip(slicedLinks)}</span>}
		</div>
	);
};
const renderFile = data => {
	if (data.length === 0) {
		return "---";
	}
	const fileName = get(data, "file_name", "");
	const filePath = get(data, "path", "");
	return (
		<GlobalTooltip
			placement="top"
			trigger="hover"
			overlay={
				fileName ? fileName : filePath.substring(filePath.indexOf("_") + 1)
			}
		>
			<a
				href={filePath ? filePath : data}
				className={s.path}
				target="_blank"
				rel="noopener noreferrer"
			>
				{fileName
					? excerpt(fileName, MAX_CHAR)
					: excerpt(filePath.substring(filePath.indexOf("_") + 1), MAX_CHAR)}
			</a>
		</GlobalTooltip>
	);
};
const setPrimary = data => {
	if (data.length === 0) {
		return "---";
	}
	const fileName = get(data, "file.file_name", "");
	const filePath = get(data, "file.path", "");
	return (
		<div className={s.editContainer}>
			{data.phone && (
				<div key={data._id}>
					<span>{getPhoneNumber(data.phone)}</span>
				</div>
			)}
			{data.email && (
				<div key={data._id}>
					<span>{data.email}</span>
				</div>
			)}
			{data.link && (
				<div key={data._id}>
					<span>{data.link}</span>
				</div>
			)}
			{get(data, "file") && (
				<div key={data._id}>
					<GlobalTooltip placement="top" trigger="hover" overlay={fileName}>
						<a
							href={filePath}
							className={s.path}
							target="_blank"
							rel="noopener noreferrer"
						>
							{excerpt(fileName, MAX_CHAR)}
						</a>
					</GlobalTooltip>
				</div>
			)}
		</div>
	);
};

const renderSkills = data => {
	if (data.length === 0) {
		return "---";
	}
	const skills = data.map(
		(skill, index) =>
			index < 2 && (
				<>
					<span key={skill._id}>{`${skill.name} ( ${skill.score} / 5 )`}</span>
					{skill.is_bot && <BotIcon className={s.botIcon} />}
					{index !== data.length - 1 && <span> -&nbsp; </span>}
				</>
			)
	);

	const slicedSkills = data.map(
		(skill, index) =>
			index >= 2 && (
				<div className={s.withIcon}>
					<span key={skill._id}>{`${skill.name} ( ${skill.score} / 5 )`}</span>
					{skill.is_bot && <BotIcon className={s.botIcon} />}
				</div>
			)
	);
	return (
		<div className={s.editContainer}>
			{skills}
			{slicedSkills.length > 2 && (
				<span>{renderWithTooltip(slicedSkills)}</span>
			)}
		</div>
	);
};

const renderFuncs = data => {
	if (data.length === 0) {
		return "---";
	}
	const filteredFunction = data.map(({ name, seniority, sector, is_bot }) => {
		const parent_sector = _get(sector, "parent_sector.name", "");
		const sector_name = _get(sector, "name", "");

		const seniorityLabel = VACANCY_LEVEL.find(
			level => level.value === seniority
		).label;
		const tooltip = `
					${parent_sector} > ${sector_name} > ${name}
				`;
		return {
			name,
			tooltip,
			seniorityLabel,
			is_bot
		};
	});
	const renderFunctions = filteredFunction.map(
		(item, index) =>
			index < 1 && (
				<div className={s.func}>
					<span>{item.name}</span>
					<SeparatorIcon />
					<span>{item.seniorityLabel}</span>
					<GlobalTooltip
						placement={"bottom"}
						maxWidth={"30rem"}
						align={{
							offset: [15, 0]
						}}
						overlay={item.tooltip}
					>
						<InfoIcon />
					</GlobalTooltip>
					{item.is_bot && <BotIcon className={s.botIcon} />}
				</div>
			)
	);
	const slicedFunctions = filteredFunction.map(
		(item, index) =>
			index >= 1 && (
				<div className={s.func}>
					<span>{item.name}</span>
					<SeparatorIcon />
					<span>{item.seniorityLabel}</span>
					<GlobalTooltip
						placement={"bottom"}
						maxWidth={"30rem"}
						align={{
							offset: [15, 0]
						}}
						overlay={item.tooltip}
					>
						<InfoIcon />
					</GlobalTooltip>
					{item.is_bot && <BotIcon className={s.botIcon} />}
				</div>
			)
	);
	return (
		<div className={s.editContainer}>
			{renderFunctions}
			{slicedFunctions.length > 1 && (
				<span>{renderWithTooltip(slicedFunctions)}</span>
			)}
		</div>
	);
};
const renderProfileCreation = data => {
	if (data.length === 0) {
		return "---";
	}
	const functions = get(data, "functions", []);
	return (
		<div className={s.profileCreation}>
			<span>
				{`-First name: `} {get(data, "first_name")}
			</span>
			<span>
				{`-Last name: `} {get(data, "last_name")}
			</span>
			<span
				style={{
					display: "flex",
					alignItems: "end",
					gap: "5px"
				}}
			>
				{`-Resume: `}
				<GlobalTooltip
					placement="top"
					trigger="hover"
					overlay={get(data, "cv_doc.file_name")}
				>
					<a
						href={get(data, "cv_doc.cv_doc")}
						className={s.path}
						target="_blank"
						rel="noopener noreferrer"
					>
						{excerpt(get(data, "cv_doc.file_name"), MAX_CHAR)}
					</a>
				</GlobalTooltip>
			</span>
			{functions.length > 0 && (
				<span
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "5px"
					}}
				>
					{`-Functions: `} {renderFuncs(functions)}
				</span>
			)}
			{data.email && (
				<span>
					{`-Email: `} {get(data, "email")}
				</span>
			)}
			{data.phone && (
				<span>
					{`-Phone number: `} {getPhoneNumber(get(data, "phone"))}
				</span>
			)}
		</div>
	);
};
const renderTags = data => {
	if (data.length === 0) {
		return "---";
	}
	const tags = data.map(
		(tag, index) =>
			index < 2 && (
				<span key={tag._id}>
					{`${tag.value} ( ${tag.type} )`}
					{index !== data.length - 1 && <span> &nbsp;-&nbsp; </span>}
				</span>
			)
	);

	const slicedTags = data.map(
		(tag, index) =>
			index >= 2 && (
				<span key={tag._id}>{`${tag.value} ( ${tag.type} / 5 )`}</span>
			)
	);
	return (
		<div className={s.editContainer}>
			{tags}
			{slicedTags.length > 2 && <span>{renderWithTooltip(slicedTags)}</span>}
		</div>
	);
};

const renderLanguages = data => {
	let langs = [];
	let slicedLangs = [];
	if (data.length === 0) {
		return "---";
	}
	data.map((lang, index) => {
		langs.push(
			index < 2 && (
				<>
					<span key={lang.name}>{`${lang.name} ( ${lang.score} / 5 )`}</span>
					{lang.is_bot && <BotIcon className={s.botIcon} />}
					{index !== data.length - 1 && <span> &nbsp;-&nbsp; </span>}
				</>
			)
		);
		slicedLangs.push(
			index >= 2 && (
				<div className={s.withIcon}>
					<span key={lang.name}>{`${lang.name} ( ${lang.score} / 5 )`}</span>
					{lang.is_bot && <BotIcon className={s.botIcon} />}
				</div>
			)
		);
	});
	return (
		<div className={s.editContainer}>
			{" "}
			{langs}
			{slicedLangs.length > 2 && <span>{renderWithTooltip(slicedLangs)}</span>}
		</div>
	);
};

const formatAddress = addressObj =>
	`${_get(addressObj, "street", "")} ${_get(addressObj, "number", "")}${
		addressObj.number && addressObj.box ? " / " : " "
	}${_get(addressObj, "box", "")} ${_get(addressObj, "zip", "")}
	${addressObj.city && `${addressObj.city},`} ${_get(addressObj, "country", "")}`
		.replace(/\s\s+/g, " ")
		.replace(/\s,+/g, ", ");

const renderLocations = data => {
	if (data.length === 0) {
		return "---";
	}
	const location = data.map(
		(location, index) =>
			index < 2 && (
				<div key={location.latitude}>
					{formatAddress(location)}
					{index !== data.length - 1 && <span> &nbsp;-&nbsp; </span>}
				</div>
			)
	);
	const slicedLocation = data.map(
		(location, index) =>
			index >= 1 && <div key={location.latitude}>{formatAddress(location)}</div>
	);

	return (
		<div className={s.editContainer}>
			{location}
			{location.length > 2 && (
				<span>{renderWithTooltip(slicedLocation.slice(1))}</span>
			)}
		</div>
	);
};

const renderSalary = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			<CurrencyFormatter cent={data} noSymbol={true} />
		</div>
	);
};
const renderPaymentType = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			<span className={s.extra}>{data}</span>
		</div>
	);
};
const renderCurrency = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			<span>{data}</span>
		</div>
	);
};
const renderPayrollCountry = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			<span>{data}</span>
		</div>
	);
};
const renderPaymentTime = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			<span className={s.extra}>{data}</span>
		</div>
	);
};
const renderNotice = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			<span className={s.notice}>{data}</span>
		</div>
	);
};
const renderExtraBenefits = data => {
	if (data.length === 0) {
		return "---";
	}
	const extraB = data.map(
		(item, index) =>
			index < 2 && (
				<div className={s.extra}>
					<span>{item.replace(/_/g, " ")}</span>
					{index !== data.length - 1 && <span> &nbsp;-&nbsp; </span>}
				</div>
			)
	);
	const slicedExtra = data.map(
		(item, index) =>
			index >= 2 && (
				<div className={s.extra}>
					<span>{item.replace(/_/g, " ")}</span>
				</div>
			)
	);
	return (
		<div className={s.editContainer}>
			{extraB}
			{slicedExtra.length > 2 && <span>{renderWithTooltip(slicedExtra)}</span>}
		</div>
	);
};
const renderEmploymentTypes = data => {
	if (data.length === 0) {
		return "---";
	}
	return (
		<div className={s.editContainer}>
			{data.map((item, index) => (
				<div key={item._id} className={s.extra}>
					<span>{item.replace(/_/g, " ")}</span>
					{index !== data.length - 1 && <span> &nbsp;-&nbsp; </span>}
				</div>
			))}
		</div>
	);
};

const renderElements = (element, key) => {
	let data = [];
	var newElement = element.edited_field;
	switch (newElement) {
		case FIRST_NAME:
			data = renderFirstName(element[key]);
			break;
		case LAST_NAME:
			data = renderLastName(element[key]);
			break;
		case PHONES:
			data = renderPhones(element[key]);
			break;
		case EMAILS:
			data = renderEmails(element[key]);
			break;
		case LINKS:
			data = renderLinks(element[key]);
			break;
		case PRIMARY:
			data = setPrimary(element[key]);
			break;
		case UPLOAD_CV:
			data = renderFile(element[key]);
			break;
		case PAYMENT_TYPE:
			data = renderPaymentType(element[key]);
			break;
		case PAYROLL_COUNTRY:
			data = renderPayrollCountry(element[key]);
			break;
		case NOTICE:
			data = renderNotice(element[key]);
			break;
		case EXTRA_BENEFITS:
			data = renderExtraBenefits(element[key]);
			break;
		case PREFERRED_LOCATION:
			data = renderLocations(element[key]);
			break;
		case SALARY:
			data = renderSalary(element[key]);
			break;
		case CURRENCY:
			data = renderCurrency(element[key]);
			break;
		case PAYMENT_TIME:
			data = renderPaymentTime(element[key]);
			break;
		case EMPLOYMENT_TYPES:
			data = renderEmploymentTypes(element[key]);
			break;
		case SKILLS:
			data = renderSkills(element[key]);
			break;
		case PROFILE_TAGS:
			data = renderTags(element[key]);
			break;
		case FUNCTIONS:
			data = renderFuncs(element[key]);
			break;
		case LANGUAGES:
			data = renderLanguages(element[key]);
			break;
		case PROFILE_CREATION:
			data = renderProfileCreation(element[key]);
			break;
	}
	return data;
};

export const getData = (isFetching, resolvedData) =>
	useMemo(
		() =>
			isFetching
				? dataSkeleton
				: get(resolvedData, "data", []).map(item => {
						const created_at = get(item, "created_at", "");
						const first_name = get(item, "updated_by.first_name");
						const last_name = get(item, "updated_by.last_name");
						const updated_by = (
							<span className={s.editContainer}>
								{first_name + " " + last_name}
							</span>
						);
						const edited_field = (
							<span className={s.field}>
								{get(item, "track_data.edited_field", "") !== PRIMARY
									? HISTORY_FIELDS.find(
											s => s.value === get(item, "track_data.edited_field", "")
									  ).label
									: HISTORY_FIELDS.find(
											s => s.value === get(item, "track_data.edited_field", "")
									  ).label +
									  ` - ${
											Object.keys(get(item, "track_data.previous_value", {}))[0]
									  }`}
							</span>
						);
						const previous_value = renderElements(
							item.track_data,
							PREVIOUS_VALUE
						);

						const edited_value = renderElements(item.track_data, EDITED_VALUE);

						return {
							id: get(item, "_id"),
							created_at: (
								<span className={s.editContainer}>
									{formatDate(created_at, true)}
								</span>
							),
							updated_by,
							edited_field,
							previous_value,
							edited_value
						};
				  }),
		[isFetching]
	);
