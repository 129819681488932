import { useEffect } from "react";
const { CANDIDATES_FILTER } = require("../utils/constants");
import useGetFiles from "common/AddCandidateDrawer/hooks/useGetFiles";
import { searchParser } from "config/helpers";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import { LOGICAL_OPERATORS } from "common/SmartFilterDrawer/utils/constant";
import { findColumn, reduceColumns } from "../utils/helpers";

function getValuesParcel(candidateReferences) {
	const columnsReduced = reduceColumns();
	const column = findColumn(columnsReduced, "id");

	const filter = candidateReferences.map(reference => {
		return {
			name: column.name,
			type: column.type,
			operator: column.operator.value,
			label: column.label,
			value: reference
		};
	});

	return filter;
}

const useSetParcelCandidates = () => {
	const { resetFilters } = useFilters();

	const { candidateReferences } = useGetFiles();

	const { parcelId } = searchParser();
	useEffect(() => {
		if (parcelId && candidateReferences.length > 0) {
			resetFilters(CANDIDATES_FILTER, {
				filters: getValuesParcel(candidateReferences),
				isLoading: true,
				isFetching: true,
				logicalOperator: LOGICAL_OPERATORS.or.value
			});
		}
	}, [candidateReferences, parcelId]);
};
export default useSetParcelCandidates;
