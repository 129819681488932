import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_CURRENCIES } from "config/api-endpoints";

const fetchCurrencies = () => {
	return client(GET_CURRENCIES, {
		body: {}
	});
};

export const getCurrenciesKey = "@timesheet-settings/currencies";

const useFetchCurrencies = (options, queryOptions = {}) => {
	return useQuery([getCurrenciesKey, options], fetchCurrencies, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
};

export default useFetchCurrencies;
