import styled from "styled-components";
import { colors } from "config/styles";

export const FieldDateSelect = styled.div`
	border: 1px solid ${colors.sectionBorderColor};
	border-radius: 3px;
	height: 50px;
	background-color: ${colors.white};
	cursor: pointer;
	.date-select {
		padding: 5px;
		width: 100%;
		display: flex;
		justify-content: space-around;
		.icon-arrow-right {
			font-size: 9px;
			color: ${colors.primaryColor};
			margin-top: 15px;
		}
		.icon-down-arrow {
			font-size: 5px;
			margin-top: 15px;
			color: ${colors.anchorsColor};
		}
	}
`;
export const StartDate = styled.div`
	font-size: 12px;
	color: #6e7191;
	font-style: normal;
	font-weight: normal;
	line-height: 18px;
	transform: translate(5px, 3px);
	.title {
		font-weight: 500;
		font-size: 12px;
	}
`;
export const EndDate = styled.div`
	font-size: 12px;
	color: #6e7191;
	font-style: normal;
	font-weight: normal;
	line-height: 18px;
	transform: translate(5px, 3px);
	.title {
		font-weight: 500;
		font-size: 12px;
	}
`;
export const ChooseDate = styled.form`
	background-color: #fff;
	padding: 30px;
	width: 100%;
	.form-group label {
		text-transform: capitalize;
	}
	.error-greater {
		font-size: 11px;
		color: ${colors.primaryColor};
		display: flex;
		margin-top: -30px;
		height: 30px;
	}
	.form-group {
		&.input-end-date {
			margin-left: 7px;
		}
		input.form-control {
			min-height: 40px;
			padding: 12px;
		}
		.help-block {
			font-size: 12px;
		}
	}
	.apply-button {
		display: inline-block;
		height: 38px;
		border-radius: 3px;
		font-weight: 500;
		white-space: nowrap;
		word-break: keep-all;
		cursor: pointer;
		line-height: 1.5;
		position: relative;
		font-size: 13px;
		padding: 8px 20px;
		outline: none;
		text-transform: capitalize;
		border: none;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
		color: #ffffff;
		background-color: #ff6849;
		display: inline-block;
	}
	.cancel-button {
		margin-left: 5px;
		display: inline-block;
		border-radius: 3px;
		height: 38px;
		font-weight: 500;
		white-space: nowrap;
		word-break: keep-all;
		cursor: pointer;
		line-height: 1.5;
		position: relative;
		font-size: 13px;
		padding: 8px 20px;
		outline: none;
		text-transform: capitalize;
		border: none;
		-webkit-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
		color: #58626b;
		background-color: transparent;
		-webkit-text-decoration: none;
		text-decoration: none;
		border: 2px solid #fff;
		border: 2px solid transparent;
		&:hover {
			border: 2px solid #b9bdc0;
			border: 2px solid transparent;
			color: #fff;
			background-color: #58626b;
		}
	}
	.Select-control {
		width: 100% !important;
		height: 42px !important;
		.Select-placeholder {
			line-height: 40px !important;
		}
		.Select-value {
			line-height: 40px !important;
		}
	}
`;
export const HandleDatePicker = styled.div`
	display: flex;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
	border-radius: 3px;
	width: 900px;
	position: absolute;
	right: -400px;
	z-index: 9;
	top: 52px;
	.DayPicker__withBorder {
		box-shadow: unset;
		border-radius: unset;
		border-right: 1px solid ${colors.grayBorder};
		width: unset !important;
		> div {
			display: flex;
		}
		outline: 0;
	}
	.DayPickerKeyboardShortcuts_buttonReset {
		display: none;
	}
	.CalendarDay__selected_span {
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		height: 5px;
		&:last-child {
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
		}
	}

	.CalendarDay__selected_span,
	.CalendarDay__selected_start {
		~ .CalendarDay__selected_span {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.CalendarDay__selected,
	.CalendarDay__selected:active {
		background: ${colors.primaryColor} !important;
		border: unset;
		color: ${colors.white} !important;
		border-radius: 50px;
	}

	.CalendarDay__default {
		border: unset;
		outline: 0;
	}
	.CalendarDay__default:hover {
		background: ${colors.primaryColor} !important;
		color: ${colors.white} !important;
	}
	.CalendarDay__selected_span {
		background: #ffece6 !important;
		border: unset !important;
		color: #000 !important;
	}
	.CalendarDay__selected_span:hover {
		border-radius: 50px !important;
	}
	.CalendarDay__default:hover {
		background: #ffece6;
		color: inherit;
		border-radius: 50px;
		border: unset;
	}
	.CalendarDay__hovered_span {
		background: none;
	}
	.CalendarDay__hovered_span:hover {
		background: none;
	}
`;
export const NavButtonLeft = styled.div`
	.icon-down-arrow {
		transform: rotate(88deg) !important;
		-webkit-transform: rotate(88deg) !important;
		left: 40px;
		margin-top: 3px;
		top: 16px;
		color: ${colors.draftPickerColor};
		font-size: 7px;
		background: #f4f4f4;
		padding: 12px 9px 12px 9px;
		border-radius: 50px;
		position: absolute;
	}
`;
export const NavButtonRight = styled.div`
	.icon-down-arrow {
		transform: rotate(-88deg) !important;
		-webkit-transform: rotate(-88deg) !important;
		right: 40px;
		margin-top: 3px;

		top: 16px;
		color: ${colors.draftPickerColor};
		font-size: 7px;
		background: #f4f4f4;
		padding: 12px 9px 12px 9px;
		border-radius: 50px;
		position: absolute;
	}
`;
