/* eslint-disable */
import _get from "lodash/get";
import React, { useState } from "react";
import styles from "modules/candidate/Profile/components/SkillsCard/skills-card.module.scss";
import EditableSection from "../EditableSection";
import Tag from "modules/candidate/Profile/components/Tag";
import { ReactComponent as AddIcon } from "static/icons/VectoraddIcon.svg";
import s from "./languages.module.scss";
import { queryCache } from "react-query";
import toaster from "common/Toaster";
import useUpdateProfileLangugaes from "modules/candidate/Profile/api/useUpdateLanguages";
import { useToggle } from "react-use";
import Portal from "common/Portal";
import LanguagesModal from "modules/candidate/Profile/Pages/Profile/components/Languages/InternalLanguagesModal";
import { profileOverviewKey } from "modules/candidate/Profile/api/useViewCandidateInternal";
import { editHistoryKey } from "modules/candidate/Profile/api/useEditHistory";
import { LoaderFull } from "common/Loader";
import cx from "classnames";
import { ReactComponent as BotIcon } from "static/icons/bot.svg";
import LeaveHook from "common/LeaveHook";
import useUnsavedChangesDialog from "modules/candidate/Profile/Store/useUnsavedChangesDialog";
import { viewOverviewKey } from "modules/candidate/Profile/api/useOverview";

const Languages = ({ languages, data }) => {
	const [on, toggle] = useToggle(false);
	const [isDirty, setIsDirty] = useState(false);
	const [
		isUnsavedChangesDialogOpen,
		toggleUnsavedChangesDialogOpen
	] = useUnsavedChangesDialog();

	const [mutate, { isLoading }] = useUpdateProfileLangugaes({
		onSuccess: () => {
			queryCache.invalidateQueries(profileOverviewKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(editHistoryKey);
			toggle(false);
			resetDirty();
		},
		onError: () => {
			toaster.danger("Error while saving changes.");
		}
	});

	const saveLanguages = languages => {
		mutate({
			cv_id: data.cv_id,
			fields_data: {
				tag: "languages",
				value: languages.map(function(item) {
					delete item.is_bot;
					return item;
				})
			}
		});
	};

	const handleLanguageRemove = () => {
		markDirty();
	};

	const handleScoreChange = () => {
		markDirty();
	};

	const handleLanguageSelect = () => {
		markDirty();
	};

	const handleCancelButtonClick = () => {
		resetDirty();
	};

	const markDirty = () => {
		setIsDirty(true);
	};

	const resetDirty = () => {
		setIsDirty(false);
	};

	const handleCloseButtonClick = () => {
		toggle(false);
		resetDirty();
	};

	return (
		<LeaveHook
			dirty={isDirty && !isUnsavedChangesDialogOpen}
			onOpen={toggleUnsavedChangesDialogOpen}
			onStay={toggleUnsavedChangesDialogOpen}
			onLeaveClick={toggleUnsavedChangesDialogOpen}
		>
			<EditableSection
				title="Languages"
				isEdit={false}
				onEdit={toggle}
				onCancel={() => resetDirty()}
				isLoading={false}
			>
				<div className={cx(styles.section, styles.langs)}>
					<div className={styles.row}>
						{languages.length > 0
							? languages.map(language => (
									<>
										<Tag
											key={_get(language, "_id")}
											text={`${_get(language, "name")} (${_get(
												language,
												"score"
											)}/5)`}
											icon={
												_get(language, "is_bot") ? (
													<BotIcon className={s.botIcon} />
												) : null
											}
										/>
									</>
							  ))
							: null}
					</div>
					{/* <button className={s.btnAdd} onClick={toggle}>
						<AddIcon />
						<span>Add language</span>
					</button> */}
				</div>
				<Portal>
					{isLoading && <LoaderFull />}
					<LanguagesModal
						isOpen={on}
						onClose={handleCloseButtonClick}
						selectedLanguages={_get(data, "languages", [])}
						closeOnSave={false}
						saveLanguages={saveLanguages}
						isLoading={isLoading}
						onLanguageRemove={handleLanguageRemove}
						onScoreChange={handleScoreChange}
						onLanguageSelect={handleLanguageSelect}
						onCancelButtonClick={handleCancelButtonClick}
					/>
				</Portal>
			</EditableSection>
		</LeaveHook>
	);
};

export default Languages;
