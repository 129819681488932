import {
	USER_ACTIVE_PROCESSES,
	USER_DELETE_ACCOUNT
} from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation, useQuery } from "react-query";

const REACT_QUERY_RETRY = 1;
const REACT_QUERY_STALE_TIME = 0;
const KEEP_PREVIOUS_DATA = false;
const REFECH_ON_WINDOW_FOCUS = false;

const queryFn = body => client(USER_ACTIVE_PROCESSES, { body });

export const USER_ACTIVE_PROCESSES_QUERY = "USER_ACTIVE_PROCESSES_QUERY";
export const useActiveProcessesCount = (payload, options = {}) =>
	useQuery([USER_ACTIVE_PROCESSES_QUERY, payload], () => queryFn(payload), {
		retry: REACT_QUERY_RETRY,
		keepPreviousData: KEEP_PREVIOUS_DATA,
		refetchOnWindowFocus: REFECH_ON_WINDOW_FOCUS,
		staleTime: REACT_QUERY_STALE_TIME,
		...options
	});

export function useDeleteAccount() {
	return useMutation(body => client(USER_DELETE_ACCOUNT, { body }));
}
