import React from "react";
import emptystateweather from "./images/emptystateweather.png";
import style from "./styles.module.scss";
const EmptyState = ({ date, username }) => {
	return (
		<div>
			<span className={style.date}>{date}</span>
			<h1 className={style.username}>Hello {username}!</h1>
			<div className={style.weatherEmptyState}>
				<img src={emptystateweather} />
				<p>You currently have no weather information</p>
			</div>
		</div>
	);
};

export default EmptyState;
