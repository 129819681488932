import React from "react";
import Steps, { Step } from "rc-steps";
import PropTypes from "prop-types";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";

const StepsComponent = ({
	activeStep,
	steps,
	hasError,
	disabledSteps,
	noStyles,
	errorSteps,
	active,
	...rest
}) => {
	if (!active) return false;

	const Icon = ({ type }) => <span className={`rc-steps-icon icon-${type}`} />;

	const restProps = hasError ? { status: "error" } : {};

	return (
		<div className={noStyles ? "" : "steps-container"}>
			<div className={noStyles ? "" : "steps"}>
				<div className={noStyles ? "" : "container"}>
					<Steps
						labelPlacement="vertical"
						current={activeStep}
						{...restProps}
						{...rest}
					>
						{steps.map((step, index) => {
							return (
								(typeof step === "string" || step.label) && (
									<Step
										key={index}
										title={step.label ? step.label : step}
										status={
											errorSteps.length > 0
												? errorSteps.includes(index)
													? "error"
													: undefined
												: disabledSteps.includes(step)
												? "process"
												: undefined
										}
										icon={
											disabledSteps.includes(step) ? (
												<span className="rc-steps-icon-override">
													{index + 1}
												</span>
											) : activeStep > index && !errorSteps.includes(index) ? (
												<Icon type={"check2"} />
											) : errorSteps.includes(index) ? (
												<Icon type={"close2"} />
											) : (
												""
											)
										}
									/>
								)
							);
						})}
					</Steps>
				</div>
			</div>
		</div>
	);
};

StepsComponent.propTypes = {
	activeStep: PropTypes.number,
	steps: PropTypes.array.isRequired,
	errorSteps: PropTypes.array
};

StepsComponent.defaultProps = {
	activeStep: 0,
	disabledSteps: [],
	errorSteps: [],
	noStyles: false,
	active: true
};

export default StepsComponent;
