import * as React from "react";
import { GlobeIcon, OnlymeIcon, CustomIcon } from "../../icons";
import { CUSTOM, PRIVATE, PUBLIC } from "../../utils";
import styles from "./visibility-options.module.scss";

const options = [
	{
		label: "Public",
		value: PUBLIC,
		description: "Anyone inside my company can see",
		icon: <GlobeIcon />
	},
	{
		label: "Only me",
		value: PRIVATE,
		description: "Only I can see",
		icon: <OnlymeIcon />
	},
	{
		label: "Custom",
		value: CUSTOM,
		description: "Include and exclude who can see",
		icon: <CustomIcon />
	}
];

const NoteVisibilityOptionsList = ({
	valueProp,
	handleSelectOption,
	children
}) => {
	return (
		<div className={styles.optionsGroup}>
			{options.map(e => {
				return (
					<div
						key={e.label}
						id={e.value}
						onClick={handleSelectOption}
						className={styles.optionWrapper}
					>
						<div className={styles.option}>
							{e.icon}
							<div className={styles.optionTitle}>
								<label>{e.label}</label>
								<span>{e.description}</span>
							</div>

							<input
								type="radio"
								id={e.value}
								name="visibility"
								value={e.value}
								checked={valueProp === e.value}
								// onChange={handleSelectOption}
							/>
							<span className={styles.radioStyle}></span>
						</div>
						{valueProp === CUSTOM && valueProp === e.value ? (
							<div
								style={{ marginTop: "12px", borderTop: "1px solid #E2E8F0" }}
							>
								{children}
							</div>
						) : null}
					</div>
				);
			})}
		</div>
	);
};

export default NoteVisibilityOptionsList;
