import React from "react";

export default function map({ fill = "#ffffff" }) {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.06657 1.075L9.33324 12.925M0.774902 10.2917L6.6999 7M3.9349 12.925H9.4649C10.571 12.925 11.1241 12.925 11.5465 12.7097C11.9182 12.5204 12.2203 12.2182 12.4096 11.8466C12.6249 11.4242 12.6249 10.8711 12.6249 9.765V4.235C12.6249 3.12889 12.6249 2.57584 12.4096 2.15337C12.2203 1.78175 11.9182 1.47961 11.5465 1.29026C11.1241 1.075 10.571 1.075 9.4649 1.075H3.9349C2.8288 1.075 2.27575 1.075 1.85327 1.29026C1.48165 1.47961 1.17951 1.78175 0.990164 2.15337C0.774902 2.57584 0.774902 3.12889 0.774902 4.235V9.765C0.774902 10.8711 0.774902 11.4242 0.990164 11.8466C1.17951 12.2182 1.48165 12.5204 1.85327 12.7097C2.27575 12.925 2.8288 12.925 3.9349 12.925Z"
				stroke={fill}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
