import React, { forwardRef } from "react";
import cx from "classnames";
import { CustomInput } from "common/CustomInput";
import styles from "./create-request-custom-input.module.scss";

const CreateRequestCustomInput = ({ className, ...propsRest }, ref) => {
	return (
		<CustomInput
			{...propsRest}
			ref={ref}
			className={cx(styles.input, className)}
		/>
	);
};

export default forwardRef(CreateRequestCustomInput);
