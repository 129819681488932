import React from "react";
import styles from "./create-request-field-label.module.scss";

const CreateRequestFieldLabel = ({ children, displayStar = true }) => {
	return (
		<div className={styles.container}>
			<label className={styles.label}>{children}</label>
			{displayStar && <span className={styles.asterisk}>&nbsp;*</span>}
		</div>
	);
};

export default CreateRequestFieldLabel;
