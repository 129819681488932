import React from "react";
import { InformationCircleIcon, PencilIcon } from "@heroicons/react/outline";
import s from "./editable-box.module.scss";

const Actions = ({ isEdit, onEdit, onCancel, isLoading }) => {
	return (
		<div className={s.actions}>
			{isEdit ? (
				<>
					<button
						disabled={isLoading}
						className={s.cancel}
						type={"button"}
						onClick={onCancel}
					>
						Cancel
					</button>
					<button disabled={isLoading} className={s.save} type={"submit"}>
						{isLoading ? "Saving..." : "Save"}
					</button>
				</>
			) : (
				<button type={"button"} onClick={onEdit}>
					<PencilIcon width={16} height={16} />
				</button>
			)}
		</div>
	);
};

function EditableBox({
	children,
	name,
	displayValue,
	emptyMessage,
	onSave,
	isLoading,
	isFetching,
	isEdit,
	toggle
}) {
	return (
		<div className={s.body}>
			<form onSubmit={onSave}>
				<Actions
					onEdit={() => toggle(true)}
					onCancel={() => toggle(false)}
					isEdit={isEdit}
					isLoading={isLoading}
					isFetching={isFetching}
				/>
				<h4>{name}</h4>
				{isEdit ? (
					children
				) : (
					<div>
						{displayValue ?? (
							<span className={s.info}>
								<InformationCircleIcon width={24} height={24} />
								{emptyMessage ??
									`Click the button on top right corner to add a ${name}.`}
							</span>
						)}
					</div>
				)}
			</form>
		</div>
	);
}

export default EditableBox;
