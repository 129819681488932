import { client } from "lib/api-client";
import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import { useQuery } from "react-query";
import fakeData from "modules/user-onboarding/mockData/email-placeholders.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getPlaceholders = (_, body) => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body
	});
};

export const GET_EMAIL_PLACEHOLDERS = "GET_EMAIL_PLACEHOLDERS";

export function useGetPlaceholders(body, options) {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery([GET_EMAIL_PLACEHOLDERS, body], () => {
			return Promise.resolve(fakeData);
		});
	}

	return useQuery([GET_EMAIL_PLACEHOLDERS, body], getPlaceholders, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
}
