import React from "react";
import { Text, Box, Flex } from "rebass";
import BoxContainer from "../boxContainer";

import { ReactComponent as LanguagesIcon } from "static/icons/elements-icons-globe.svg";
import { ReactComponent as SkillsIcon } from "static/icons/elements-icons-suitcase.svg";
import get from "lodash/get";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { getSkillsTooltip } from "config/helpers";
import Tooltip from "rc-tooltip";

export const SkillsByCategory = skills => {
	const categories = {};
	skills.map(skill => {
		return categories[get(skill, "parent_sector.name")]
			? categories[get(skill, "parent_sector.name")].push(skill)
			: (categories[get(skill, "parent_sector.name")] = [skill]);
	});
	return categories;
};

const SkillsAndLanguages = ({
	languages,
	skills,
	isDrag,
	setIsOpenModalTab,
	setActiveTab,
	mainFunction,
	isFetching
}) => {
	const mainCategory = get(mainFunction, "category", "");
	const skillsBycategory = SkillsByCategory(skills);
	const keys = Object.keys(skillsBycategory);
	const mainSkills =
		get(skillsBycategory, mainCategory) ??
		get(skillsBycategory, get(keys, 0, ""), []);

	const mainCategorySkills =
		skills.length > 0 && mainSkills ? mainSkills.slice(0, 2) : [];
	const numbOfRestSkills =
		get(skills, "length", 0) - get(mainCategorySkills, "length", 0);
	const renderItems = (name, score) =>
		score ? (
			<Tooltip
				placement="top"
				overlay={<div>{getSkillsTooltip(score)}</div>}
				trigger="hover"
				overlayClassName="function-tooltip"
			>
				<Box
					p={2}
					sx={{
						borderRadius: 4,
						backgroundColor: "#f2f2f2",
						marginRight: "8px",
						marginBottom: "8px"
					}}
					mb={2}
				>
					<Text
						sx={{
							fontSize: "16px",
							color: "#2b3946"
						}}
					>
						{name ? `${name} (${score}/5)` : "N/A"}
					</Text>
				</Box>
			</Tooltip>
		) : (
			<Box
				p={2}
				sx={{
					borderRadius: 4,
					backgroundColor: "#f2f2f2",
					marginRight: "8px",
					marginBottom: "8px"
				}}
				mb={2}
			>
				<Text
					sx={{
						fontSize: "16px",
						color: "#2b3946"
					}}
				>
					N/A
				</Text>
			</Box>
		);

	return (
		<BoxContainer
			setActiveTab={setActiveTab}
			setIsOpenModalTab={setIsOpenModalTab}
			isDrag={isDrag}
			title="Skills & Languages"
			isFetching={isFetching}
		>
			{isFetching ? (
				<React.Fragment>
					<Flex mb={3} alignItems="center">
						<SkillsIcon style={{ marginRight: "8px" }} />
						<Box>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={270} height={14} />
							</SkeletonTheme>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={170} height={14} />
							</SkeletonTheme>
						</Box>
					</Flex>
					<Flex alignItems="center">
						<LanguagesIcon style={{ marginRight: "8px" }} />
						<Box>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={270} height={14} />
							</SkeletonTheme>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={170} height={14} />
							</SkeletonTheme>
						</Box>
					</Flex>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Flex mb={3} alignItems="flex-start">
						<Box>
							<SkillsIcon />
						</Box>

						{skills.length > 0 && mainCategory ? (
							<Box ml={2}>
								<Text
									mb={2}
									sx={{
										fontSize: "16px",
										color: "#818a92",
										fontWeight: "normal"
									}}
								>
									{mainCategory}{" "}
									{numbOfRestSkills ? `(+${numbOfRestSkills} Skills)` : null}
								</Text>
								<Flex flexWrap="wrap">
									{mainCategorySkills
										.slice(0, 2)
										.map(({ name, score }) => renderItems(name, score))}
								</Flex>
							</Box>
						) : (
							<Box sx={{ marginLeft: "8px" }}>
								<Text
									mb={2}
									sx={{
										fontSize: "16px",
										color: "#818a92",
										fontWeight: "normal"
									}}
								>
									Skills
								</Text>

								<Flex flexWrap="wrap">{renderItems()}</Flex>
							</Box>
						)}
					</Flex>
					<Flex alignItems="flex-start">
						<LanguagesIcon />

						<Box ml={2}>
							<Text
								mb={2}
								sx={{
									fontSize: "16px",
									color: "#818a92",
									fontWeight: "normal"
								}}
							>
								Languages
							</Text>

							<Flex flexWrap="wrap">
								{languages.length > 0
									? languages.map(({ name, score }) => renderItems(name, score))
									: renderItems()}
							</Flex>
						</Box>
					</Flex>
				</React.Fragment>
			)}
		</BoxContainer>
	);
};

export default SkillsAndLanguages;
