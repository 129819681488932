import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import { CLIENT, CONSULTANCY } from "config";
import CompanyDashboard from "./CompanyDashboard";
import { fetchCurrentCompany } from "../actions/identityActions";
import { companyInfoSelector } from "../selectors/createCompanySelectors";
import { onlineUserSelector } from "../../user/selectors/selectors";

export const companySteps = [
	"create",
	"payment",
	"identity",
	"cv",
	"congratulation"
];

class Company extends Component {
	componentDidMount() {
		const { fetchCurrentCompany } = this.props;
		fetchCurrentCompany();
	}

	componentDidUpdate(prevProps) {
		const { status } = this.props.companyInfo;
		const step = this.props.location.state;
		if (status && step !== prevProps.location.state) {
			this.props.fetchCurrentCompany();
		}
	}

	getActiveNumber = () => {
		const { type } = this.props.companyInfo;
		const step = this.props.location.pathname;
		let active = 0;
		if (
			(step.includes(companySteps[2]) && type === CLIENT) ||
			step.includes(companySteps[3])
		) {
			active = 1;
		}
		if (step.includes(companySteps[4])) {
			active = 2;
		}
		return active;
	};

	render() {
		const {
			children,
			companyInfo: { type },
			user
		} = this.props;
		return (
			<BodyClassName className="company">
				<div style={{ marginTop: type === CONSULTANCY ? 150 : 0 }}>
					{type !== CONSULTANCY && !user.is_member && (
						<CompanyDashboard
							type={type}
							skippedIdentity={user.identity_step === "skipped"}
							active={this.getActiveNumber()}
						/>
					)}
					{children}
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		companyInfo: companyInfoSelector(state),
		user: onlineUserSelector(state)
	};
};

const mapDispatchToProps = dispatch => ({
	fetchCurrentCompany: () => dispatch(fetchCurrentCompany())
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);
