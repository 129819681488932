import Immutable from "seamless-immutable";
import * as Types from "../actions/actionsTypes";

const initialState = Immutable({
	formFields: {
		document: "",
		stage: {
			value: "",
			label: ""
		},
		is_required: false
	},
	selectedDocument: null
});

export default function DocumentReducer(state = initialState, action) {
	switch (action.type) {
		case Types.INIT_ADD_MODAL:
			return Immutable.merge(state, { formFields: action.payload });
		case Types.RESET_ADD_MODAL:
			return Immutable.merge(state, { formFields: initialState.formFields });
		case Types.SET_SELECTED:
			return Immutable.merge(state, { selectedDocument: action.id });
		case Types.SET_IS_MANDATORY:
			return Immutable.merge(state, {
				formFields: {
					...state.formFields,
					is_required: action.is
				}
			});
		default:
			return state;
	}
}
