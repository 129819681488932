import styled, { css } from "styled-components";
import { colors } from "config/styles";
import {
	SUBMITTED,
	REFUSED,
	ACCEPTED,
	IN_MEDIATION,
	DISPUTED,
	APPROVED,
	IN_REVIEW
} from "config";

export const TimesheetTableDetails = styled.table`
	width: ${props => props.width || " 100%"};
	border: 0;
	table-layout: fixed;
	margin: ${props => props.margin || 0};
	border-spacing: ${props => props.spacing || 0};
	border-collapse: ${props => (props.collapse ? "collapse" : "initial")};
`;

export const TdDetails = styled.td`
	line-height: 1.5;
	word-wrap: break-word;
	color: ${colors.inputColor};
	border: 1px solid ${colors.timesheetBorder};
	font-size: ${props => (props.caption ? "12.5px" : "13px")};
	font-weight: ${props => (props.caption ? 700 : 300)};
	text-transform: ${props =>
		props.transform
			? props.transform
			: props.caption
			? "uppercase"
			: "lowercase"};
	background: ${props =>
		props.background ? colors.invitationDescription : "none"};
	width: ${props => (props.caption && !props.space ? "19%" : "31%")};
	padding-left: ${props => (props.space ? "30px" : "15px")};
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 15px;
`;

export const StatusContainer = styled.div`
	font-size: 12.5px;
	font-weight: 600;
	text-transform: uppercase;
	color: ${colors.inputColor};
	float: left;
	line-height: 14px;
`;

export const Caption = styled.div`
	font-size: 13.5px;
	font-weight: 600;
	text-transform: uppercase;
	line-height: 1.5;
	color: ${colors.inputColor};
	margin: 40px 0 5px;
`;

export const Th = styled.th`
	border-left-width: 3px;
	border-radius: 1.5px;
	font-family: BasierCircle;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 34px;
	background-color: ${colors.white};
	padding: ${props => (props.padding ? props.padding : "18px 5px")};
	text-transform: uppercase;
	letter-spacing: 0.5px;
	color: #6e7191;
	border: 0;
	text-align: ${props => props.align || "left"};
	width: ${props => props.width || "auto"};
	&:first-child {
		padding-left: 22px;
	}
	.g-tool {
		display: inline;
	}
`;

export const Td = styled.td`
	border-radius: 1.5px;
	padding: ${props => props.padding || "9px 2px"};
	word-break: break-word;
	${({ verticalAlign }) =>
		verticalAlign &&
		css`
			vertical-align: ${verticalAlign};
		`}

	font-family: BasierCircle;
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	height: 44px;
	font-weight: ${props => props.fontWeight || "400"};
	text-transform: none;
	color: ${props => props.color || "#3E4C5F"};
	text-align: ${props => props.align || "left"};
	position: ${props => props.position};
	border: 0;
	&.generateFiles {
		.g-tool {
			vertical-align: initial;
			margin-left: 0;
			width: 50%;
			text-align: center;
		}
	}
	cursor: ${props => (props.onClick ? "pointer" : "default")};
	.disabledColor {
		color: #a5b1bb;
	}
	&:first-child {
		border-left: ${props => !props.subTotal && "3px solid transparent"};
		padding-left: ${props => !props.subTotal && "22px"};
	}
	&:last-child {
		border-right: ${props =>
			props.subTotal && `3px solid ` + colors.primaryColor};
		padding-right: ${props => props.subTotal && "15px"};
	}
	width: ${props => props.width || "auto"};
	._input_color_ {
		color: ${colors.inputColor};
	}
	.file-icon {
		font-size: 35px;
		margin-top: 6px;
	}
	.download-file {
		text-decoration: unset;
		font-size: 20px;
		color: ${colors.bullColor};
		&:hover {
			color: ${colors.inputColor};
		}
	}
	.file-time {
		color: ${colors.disabledGray};
		margin-top: 3px;
	}
	${({ isApproveButton, showTooltip }) =>
		isApproveButton &&
		css`
			.g-tool {
				margin: 0 !important;
			}
			button {
				cursor: ${showTooltip ? "not-allowed" : "auto"};
				opacity: ${showTooltip ? 0.6 : 1};
			}
		`}
`;

export const Delete = styled.button`
	border-radius: 50%;
	padding: 5px 6px 3px 5px;
	font-size: 9px;
	display: inline-flex;
	color: ${colors.deleteButton};
	cursor: pointer;
	width: 20px !important;
	height: 20px !important;
	border: none;
	background-color: transparent;
	justify-content: center;
	align-items: center;
	&:hover {
		background-color: ${colors.deleteButton};
		color: ${colors.white};
	}
`;

export const FileIcon = styled.span`
	font-size: 22px;
	width: 50%;
	display: ${props => (props.block ? "block" : "inline-block")};
	color: ${colors.icon};
	text-align: center;
	cursor: pointer;
	&.downloadIcones {
		font-size: 32px;
		&.icon-xls-download,
		&.icon-pdf-download {
			font-size: 34px;
			position: relative;
			top: 3px;
		}
	}
`;

export const Tr = styled.tr`
	height: ${props => (props.height ? props.height : "74px")};
	background: ${props => (props.background ? props.background : "white")};
	cursor: ${props => (props.onClick ? "pointer" : "default")};
	${Td}:first-child {
		border-left: 3px solid transparent;
	}
	&:hover {
		${Td}:first-child {
			${({ isExpanded }) =>
				!isExpanded &&
				css`
					border-left-color: ${colors.primaryColor};
					border-left-width: 3px;
				`};
		}
		${FileIcon} {
			color: ${colors.primaryColor};
		}
	}
	float: ${props => props.float || "none"};
	${props =>
		props.highlighted &&
		css`
			background: #fff8f7;

			${Td}:first-child {
				border-left-color: ${colors.primaryColor};
				border-left-width: 3px;
			}
			${Td} {
				cursor: pointer;
			}
		`};
`;

export const FloatItem = styled.div`
	float: ${props => props.float || "left"};
	width: ${props => props.width || "auto"};
	padding: ${props => props.padding || "0 2% 0 0"};
	display: flex;
	.form-group {
		width: 100% !important;
	}
	label {
		margin-bottom: ${props => props.labelMarginBottom || ""};
	}
	&.upload-holder {
		display: flex;
		justify-content: center;
	}
	.select-style.label-no {
		margin: 0 0 !important;
	}
	#menuContainer {
		bottom: 0 !important;
		left: 25px !important;
	}
`;

const variant = status => {
	switch (status) {
		case SUBMITTED:
			return css`
				background-color: ${colors.submittedColor};
			`;
		case REFUSED:
			return css`
				background-color: ${colors.errorColor};
			`;
		case ACCEPTED:
		case APPROVED:
			return css`
				background-color: ${colors.successColor};
			`;
		case DISPUTED:
			return css`
				background-color: ${colors.warningColor};
			`;
		case IN_MEDIATION:
			return css`
				background-color: ${colors.paused};
			`;
		case IN_REVIEW:
			return css`
				background-color: ${colors.ultraBlue};
			`;
		default:
			return css`
				background-color: ${colors.disabledGray};
			`;
	}
};

export const StatusLabel = styled.div`
	line-height: 12px;
	border-radius: 2px;
	border: none;
	right: 30px;
	padding: ${props => props.padding || "8px 10px"};
	color: ${colors.white};
	font-size: 14px;
	text-transform: capitalize;
	font-weight: 400;
	display: ${props => (props.block ? "block" : "inline-block")};
	margin: ${props => props.margin || 0};
	text-align: center;
	${props => variant(props.status)};
`;

export const DoneBy = styled.div`
	font-weight: 400;
	color: #3d4b60;
	float: left;
	line-height: 26px;
	margin-left: 5px;
`;

export const RemoveItem = styled.button`
	background: transparent;
	border: none;
	color: ${colors.skillsItemBg};
	font-size: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	&:hover {
		color: ${colors.deleteButton};
	}
`;

export const DuplicateItem = styled.button`
	color: ${colors.skillsItemBg};
	font-size: 30px;
	cursor: pointer;
	background: transparent;
	border: none;
	&:hover {
		color: ${colors.deleteButton};
	}
	&:disabled {
		cursor: not-allowed;
	}
`;
export const MenuItemWrapper = styled.div`
	border-radius: 2px;
	box-shadow: 0 0 7.4px 0.1px rgba(218, 224, 242, 0.48);
	border: solid 1px rgb(215, 217, 224);
	background-color: rgb(255, 255, 255);
	padding: 10px;
	> ul {
		list-style: none;
		border: none;
		box-shadow: unset;
		padding: 0;
		outline: 0;
		> li {
			margin-bottom: 22px;
			outline: 0;
		}
	}
	.textGenerate {
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		color: rgb(61, 75, 96);
		> span {
			display: block;
			font-size: 10px;
		}
	}
	.rowMenu {
		display: flex;
		justify-content: center;
		align-items: center;
		.iconesWrapper {
			position: relative;
			top: 4px;
		}
	}
	.downloadIcones {
		font-size: 30px;
		margin: 0px;
		vertical-align: initial;
	}
	.btnGenerateFile {
		> span {
			font-size: 20px;
			cursor: pointer;
			position: relative;
			top: -3px;
		}
	}
	.linkDocument {
		text-decoration: none;
	}
	.wrapperLoaderDocument {
		min-height: 36px;
		text-align: center;
		.downloadIcones {
			cursor: pointer;
		}
	}
`;

export const ButtonTimesheetActionFiles = styled.button`
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	color: rgb(61, 75, 96);
	background-color: #fff;
	border-radius: 3px;
	border: 1px solid #e2e2e2;
	padding: 10px 20px;
	outline-color: #73b7f8;
	&.active {
		border: 1px solid #73b7f8;
	}
	> i {
		font-size: 10px;
		margin-left: 30px;
	}
`;
