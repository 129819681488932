import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_USER_SETTING } from "config/api-endpoints";

function updateUserSettings(data) {
	return client(UPDATE_USER_SETTING, {
		body: data
	});
}

function useUpdateUserSettings(options) {
	return useMutation(updateUserSettings, options);
}

export default useUpdateUserSettings;
