/* eslint-disable react/display-name */

import React, { useEffect } from "react";
import { useTour } from "@reactour/tour";
import { HOW_TO_CREATE_VACANCY } from "../../../../config/onboarding";
import styles from "../../components/TourContentComponent/tour-content-component.module.scss";
import TourContentComponent from "../../components/TourContentComponent";
import useOnboardingStore from "../../hooks/useOnboardingStore";
import Portal from "common/Portal";
import isElementLoaded from "../../helpers/isElementLoaded";

const selectorForStep = stepNumber =>
	`[data-onboarding-step=${HOW_TO_CREATE_VACANCY}-${stepNumber}]`;

const steps = [
	{
		selector: selectorForStep(0),
		title: "Quick add",
		description:
			"Create vacancies effortlessly from anywhere on the platform. Click the “Quick Add” button",
		stepInteraction: false,
		position: p => [p.left - 300, p.bottom + 15],
		arrow: "top-right"
	},
	{
		selector: selectorForStep(1),
		title: "Click to create a vacancy",
		description:
			"Create a new vacancy with ease by simply clicking on the 'Create Vacancy' button, to get started with just a few clicks.",
		stepInteraction: false,
		position: p => [p.left - 415, p.bottom - 15],
		arrow: "right-top",
		before() {}
	},
	{
		selector: selectorForStep(2),
		title: "Profile criteria",
		description:
			"Easily fill profile details based on the candidates you're targeting for your job openings. profile criteria feature guarantees a smooth and precise match-making process, streamlining your hiring journey.",
		stepInteraction: false,
		position: p => [p.left + 415, p.bottom - 300],
		arrow: "left-top"
	},
	{
		selector: selectorForStep(3),
		title: "Vacancy details / description",
		description:
			"Elevate your job posting and make it shine with powerful descriptions, ensuring clarity and impact that attract the right candidates.",
		stepInteraction: false,
		position: p => [p.left + 700, p.bottom],
		arrow: "left-top"
	}
];

export const useCreateVacancyTour = (props = {}) => {
	const store = useOnboardingStore();
	const tour = useTour();

	useEffect(() => {
		if (store.workflowId === HOW_TO_CREATE_VACANCY) {
			if (store.currentStep === 0) {
				tour.setSteps(steps);
				tour.setCurrentStep(0);
				tour.setIsOpen(true);
			}

			if (
				store.currentStep === 1 &&
				tour.currentStep === 0 &&
				props.showQuickAddMenu &&
				props.createVacancyActionRef.current
			) {
				props.showQuickAddMenu();
				setTimeout(() => {
					props.createVacancyActionRef.current.classList.add(
						styles.highlighted
					);
					tour.setCurrentStep(1);
				}, 200);
			}

			if (
				store.currentStep === 2 &&
				tour.currentStep === 1 &&
				props.goToCreateVacancy
			) {
				props.goToCreateVacancy();
			}

			if (store.currentStep === 2 && tour.currentStep === 1) {
				isElementLoaded(selectorForStep(2)).then(() => {
					tour.setCurrentStep(2);
				});
			}

			if (
				store.currentStep === 3 &&
				tour.currentStep === 2 &&
				props.handleFirstStepSubmit
			) {
				props.handleFirstStepSubmit();
			}

			if (store.currentStep === 3 && tour.currentStep === 2) {
				isElementLoaded(selectorForStep(3)).then(() => {
					tour.setCurrentStep(3);
				});
			}
		} else {
			if (props.createVacancyActionRef) {
				props.createVacancyActionRef.current.classList.remove(
					styles.highlighted
				);
			}
		}
	}, [store.workflowId, store.currentStep, tour.currentStep]);

	return null;
};

export const providerConfig = {
	ContentComponent: TourContentComponent,
	onClickMask: () => null,
	className: styles.popover,
	maskClassName: styles.mask,
	padding: 0,
	scrollSmooth: true,
	disableKeyboardNavigation: true,
	Wrapper: ({ children }) => <Portal>{children}</Portal>
};
