import React, { useState, useEffect } from "react";
import { useQuery, usePaginatedQuery } from "react-query";
import _get from "lodash/get";
import { DESC, FREELANCER, CURRENCY_SUFFIX } from "config";
import {
	FILTER_SETTINGS_ENDPOINT,
	LIST_BIDS_ENDPOINT
} from "config/api-endpoints";
import { client } from "lib/api-client";
import Table from "common/Table";
import { ViewContainer } from "./Styled";
import Filters from "./Filters";
import { formatDate } from "common/Functions";
import Status from "common/Table/Status";
import Pagination from "common/PaginationClient";
import GlobalTooltip from "common/GlobalTooltip";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { historyPush, searchParser } from "config/helpers";
import BackButton from "common/Buttons/BackButton";
import { getClientBidViewRoute } from "modules/RequestView/components/BidDetailsDrawer/utils/constant";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";

const getBids = (_key, { sort, filters, search }) => {
	return client(LIST_BIDS_ENDPOINT, {
		body: {
			sort,
			search,
			...filters
		}
	});
};

const getSettings = () => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "temporary_bids"
		}
	});
};

const renderStatus = data => (
	<>
		<Status status={_get(data, "value")} />
		{_get(data, "value")}
	</>
);

const renderApplicantsName = data => {
	const isFreelancer =
		_get(data, "cell.row.original.company_type") === FREELANCER;

	return (
		<div className="appl-name">
			{isFreelancer ? (
				<>
					{data.value}
					<div
						className={`appl-type ${_get(
							data,
							"cell.row.original.company_type"
						)}`}
					>
						{_get(data, "cell.row.original.company_type")}
					</div>
				</>
			) : (
				<GlobalTooltip
					placement={"top"}
					overlay={
						"This is a consultant, employed by a consultancy that charges an hourly rate for his/her performance."
					}
				>
					<span>
						<span id="consultant">{data.value}</span>
						<div
							className={`appl-type ${_get(
								data,
								"cell.row.original.company_type"
							)}`}
						>
							{_get(data, "cell.row.original.company_type")}
						</div>
					</span>
				</GlobalTooltip>
			)}
		</div>
	);
};

const renderCurrency = data => {
	const value = data?.row?.original?.proposed_amount;
	const rate_type = data?.row?.original?.reporting_settings?.rate_type;
	const code = data?.row?.original?.reporting_settings?.currency?.code;

	return value ? (
		<CurrencyFormatterRender
			isCent
			value={value}
			currency={code}
			suffix={CURRENCY_SUFFIX[rate_type]}
		/>
	) : (
		""
	);
};

const getTableColumns = settings => [
	{
		Header: `Applicant's name`,
		accessor: "user_name",
		hasFilter: true,
		filter: "applicant_id",
		filterOptions: formatSettings(_get(settings, "applicants", [])),
		Cell: renderApplicantsName
	},
	{
		Header: "Department",
		accessor: "group.name",
		hasFilter: true,
		filter: "group_id",
		filterOptions: formatSettings(_get(settings, "groups", [])),
		width: 120
	},
	{
		Header: "Request",
		accessor: "job.title",
		hasFilter: true,
		filter: "job_id",
		filterOptions: formatSettings(_get(settings, "jobs", [])),
		width: 240
	},
	{
		Header: "Date",
		accessor: "created_at",
		Cell: props => formatDate(_get(props, "value")),
		hasFilter: true,
		disabledFilter: true,
		width: 90
	},
	{
		Header: "Amount",
		hasFilter: true,
		disabledFilter: true,
		Cell: renderCurrency,
		width: 80
	},
	{
		Header: "Status",
		accessor: "visible_status",
		hasFilter: true,
		Cell: renderStatus,
		filter: "status",
		filterOptions: formatSettings(_get(settings, "status", []))
	}
];

const formatSettings = settings => {
	return settings.map(setting => {
		if (typeof setting === "string") {
			return {
				label: setting,
				value: setting
			};
		}
		return {
			label: _get(setting, "title", _get(setting, "name")),
			value: _get(setting, "_id")
		};
	});
};

const ClientBidList = () => {
	const [search, setSearch] = useState("");
	const [sort, setSort] = useState(DESC);
	const [filters, setFilters] = useState({});

	const { resolvedData, isFetching } = usePaginatedQuery(
		["loadBids", { search, sort, filters }],
		getBids
	);

	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([{ name: "Temporary" }, { name: "Bids", path: "/bids" }]);
	}, []);

	const settingsData = useQuery(["loadSettings"], getSettings);
	const searchQueries = searchParser();

	const handlePageClick = value => {
		setFilters({
			...filters,
			offset: _get(resolvedData, "pagination.size") * value
		});
	};

	const handleRowClick = row =>
		historyPush(
			getClientBidViewRoute(
				row.original._id,
				row.original.job._id,
				"&source=list&list=bids"
			)
		);

	return (
		<ViewContainer>
			{Boolean(_get(searchQueries, "from", "") === "home") && (
				<BackButton className="backButton" />
			)}
			<Filters
				submitSearch={value => {
					setSearch(value);
				}}
				setSort={setSort}
				sort={sort}
			/>
			<Table
				filters={filters}
				setFilters={setFilters}
				columns={getTableColumns(_get(settingsData, "data", {}))}
				data={_get(resolvedData, "bids")}
				isLoading={isFetching}
				emptyTitle={"No bids"}
				emptyText={"You haven’t received any bid yet."}
				onRowClick={handleRowClick}
			/>
			{_get(resolvedData, "pagination.total") > 0 && (
				<Pagination
					total={_get(resolvedData, "pagination.total")}
					size={_get(resolvedData, "pagination.size")}
					offset={_get(resolvedData, "pagination.offset")}
					handlePageClick={handlePageClick}
				/>
			)}
		</ViewContainer>
	);
};

export const getClientBidsListRoute = () => `/bids`;

export const getClientBidsListPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default ClientBidList;
