import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass";
import _get from "lodash/get";
import Filter from "./Filter";
import List from "./List";
import Cart from "./Cart";
import { WithCategoryFilter, Title, CartHead, ClearAll } from "./style";
import { ListContext, LIST_ACTION_TYPES } from "./listContext";
import Star from "../../Icons/Star";
import GlobalTooltip from "../../GlobalTooltip";

function ListContainer({
	data,
	categories,
	selected,
	setSkillsCategory,
	showCategories,
	title,
	cartTitle,
	canPropose,
	onPropose,
	tooltipText,
	showMustHave,
	onScoreChange,
	onDelete,
	onSkillSelect
}) {
	const { state, dispatch } = useContext(ListContext);
	const [showSuccess] = useState(false);

	useEffect(() => {
		if (data) {
			dispatch({ type: LIST_ACTION_TYPES.SET_DATA, data });
		}
	}, [data]);

	useEffect(() => {
		return () => {
			if (showCategories) {
				setSkillsCategory(state.category || undefined);
			}
			dispatch({ type: LIST_ACTION_TYPES.SET_DATA, data: [] });
			dispatch({ type: LIST_ACTION_TYPES.CLEAR_DATA });
		};
	}, []);

	useEffect(() => {
		if (selected) {
			dispatch({
				type: LIST_ACTION_TYPES.UPDATE_SELECTED,
				selected: JSON.parse(JSON.stringify(selected))
			});
		}
	}, []);

	const onSelect = item => {
		onSkillSelect && onSkillSelect();
		if (item.proposing) {
			delete item.proposing;

			let selectedCategory = categories.find(c => c._id === state.category);

			item.parent_sector = {
				_id: _get(state, "category"),
				name: _get(selectedCategory, "name"),
				group_name: _get(selectedCategory, "group_name", "")
			};

			return onPropose(item).then(() => {
				dispatch({ type: LIST_ACTION_TYPES.UPDATE_FILTER, filter: "" });
				dispatch({
					type: LIST_ACTION_TYPES.ADD_ITEM,
					selected: item
				});
			});
		}
		return dispatch({
			type: LIST_ACTION_TYPES.ADD_ITEM,
			selected: item
		});
	};

	const clearDataHandler = () => {
		return dispatch({ type: LIST_ACTION_TYPES.CLEAR_SELECTED });
	};

	return (
		<Flex flexDirection="column">
			<Box m={"20px 0"}>
				{showCategories ? (
					<WithCategoryFilter>
						<Filter
							categories={categories}
							selectedSkillsCategory={_get(state, "category")}
							setSkillsCategory={setSkillsCategory}
							showCategories
						/>
					</WithCategoryFilter>
				) : (
					<Filter
						categories={categories}
						selectedSkillsCategory={state.category}
						setSkillsCategory={setSkillsCategory}
					/>
				)}
			</Box>
			<Flex>
				<Box width={2 / 3}>
					<List
						title={title}
						onSelect={onSelect}
						hasCategory={!!_get(state, "category")}
						canPropose={canPropose}
						showSuccess={showSuccess}
						showCategories={showCategories}
					/>
				</Box>
				<Box width={1 / 3}>
					<Cart
						grouped={showCategories}
						title={
							<Flex justifyContent="space-between" alignItems="center">
								<CartHead>
									{cartTitle}
									{showMustHave && (
										<Title>
											<Star className="star" /> = <span>Must have</span>
											<GlobalTooltip overlay={tooltipText} placement="top" />
										</Title>
									)}
								</CartHead>
								<ClearAll onClick={clearDataHandler}>Clear All</ClearAll>
							</Flex>
						}
						onSelect={onSelect}
						showMustHave={showMustHave}
						onScoreChange={() => onScoreChange?.()}
						onDelete={() => onDelete?.()}
					/>
				</Box>
			</Flex>
		</Flex>
	);
}

ListContainer.propTypes = {
	children: PropTypes.node,
	formError: PropTypes.bool,
	cartTitle: PropTypes.string,
	title: PropTypes.string,
	categories: PropTypes.array,
	data: PropTypes.array,
	selected: PropTypes.array,
	maxSkills: PropTypes.number,
	canPropose: PropTypes.bool
};
export default ListContainer;
