import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Flex, Box } from "rebass";
const DrawerSkeleton = () => {
	return (
		<React.Fragment>
			<Box p={3} mt={1} height={"15%"}>
				{" "}
				<Flex>
					<Box mr={2} mb={2} alignSelf="flex-start">
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton circle={true} width={70} height={70} />
						</SkeletonTheme>
					</Box>

					<Box alignSelf="center">
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={200} height={12} />
						</SkeletonTheme>

						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={150} height={12} />
						</SkeletonTheme>
						<Flex>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={50} height={12} />
							</SkeletonTheme>
							<Box ml={1}>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
									<Skeleton width={100} height={12} />
								</SkeletonTheme>
							</Box>
						</Flex>
					</Box>
				</Flex>
			</Box>
			<Box p={3} height={"75%"}>
				<Flex>
					<Box mr={4} mb={4}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={90} height={16} />
						</SkeletonTheme>
					</Box>
					<Box mr={4} mb={4}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={90} height={16} />
						</SkeletonTheme>
					</Box>
					<Box mr={4} mb={4}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={90} height={16} />
						</SkeletonTheme>
					</Box>
					<Box mr={4} mb={4}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={90} height={16} />
						</SkeletonTheme>
					</Box>
					<Box mr={4} mb={4}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={90} height={16} />
						</SkeletonTheme>
					</Box>
					<Box mr={4} mb={4}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={90} height={16} />
						</SkeletonTheme>
					</Box>
				</Flex>
				<Box
					sx={{
						border: "14px solid #dfe3e8",
						borderBottom: "none",
						borderRadius: "6px",
						height: "560px"
					}}
					p={4}
				>
					<Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={130} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={120} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={230} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={330} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={230} height={14} />
							</SkeletonTheme>
						</Box>
					</Box>
					<Box my={4}>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={130} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={230} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={330} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={230} height={14} />
							</SkeletonTheme>
						</Box>
					</Box>
					<Box mb={4}>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={130} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={230} height={14} />
							</SkeletonTheme>
						</Box>
						<Box mr={2} mb={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={330} height={14} />
							</SkeletonTheme>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box p={3} height={"8%"}>
				<Flex mt={2} justifyContent="space-between" alignItems="center">
					<Flex>
						<Box mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={60} height={15} />
							</SkeletonTheme>
						</Box>
						<Box mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={60} height={15} />
							</SkeletonTheme>
						</Box>
					</Flex>
					<Flex flexDirection="row-reverse" alignItems="center">
						<Box mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={80} height={30} />
							</SkeletonTheme>
						</Box>
						<Box mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={40} height={30} />
							</SkeletonTheme>
						</Box>
						<Box mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={40} height={30} />
							</SkeletonTheme>
						</Box>
						<Box mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={40} height={30} />
							</SkeletonTheme>
						</Box>
						<Box mr={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={40} height={30} />
							</SkeletonTheme>
						</Box>
					</Flex>
				</Flex>
			</Box>
		</React.Fragment>
	);
};

export default DrawerSkeleton;
