import React from "react";
import { useDropzone } from "react-dropzone";
import { SUPPORTED_SIZE } from "config";
import cx from "classnames";
import { ReactComponent as PaperclipIcon } from "static/icons/referral/paperclip.svg";
import { bytesToSize } from "config/helpers";
import styles from "./input.module.scss";

export const SUPPORTED_FILES = ".doc, .docx, .pdf";
function Input({
	children,
	onChange,
	onDrop,
	multiple,
	onDropRejected,
	className,
	maxFiles,
	maxSize = SUPPORTED_SIZE,
	isReadOnly,
	filesTypesDescription,
	accept = SUPPORTED_FILES,
	noClick = true,
	disabled = false
}) {
	const { open, getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		noClick,
		multiple,
		maxSize,
		maxFiles,
		accept,
		onDropRejected
	});
	if (children) {
		return (
			<div
				className={cx("inputContainer", className)}
				{...getRootProps()}
				onClick={open}
			>
				<input {...getInputProps({ onChange })} disabled={disabled} />
				{children({ isDragActive })}
			</div>
		);
	}

	return (
		<Render
			getRootProps={getRootProps}
			getInputProps={getInputProps}
			isReadOnly={isReadOnly}
			maxSize={maxSize}
			filesTypesDescription={filesTypesDescription}
		/>
	);
}

const Render = ({
	getRootProps,
	getInputProps,
	isReadOnly,
	maxSize = SUPPORTED_SIZE,
	filesTypesDescription = ""
}) => (
	<div
		role="button"
		tabIndex={0}
		className={cx(styles.uploaderContainer, {
			[styles.isReadOnly]: isReadOnly
		})}
		{...getRootProps()}
	>
		<div className={styles.iconWrapper}>
			<PaperclipIcon />
		</div>
		<input {...getInputProps()} />
		<div>
			<div>
				<span className={styles.highlighted}>Click to upload</span> or drag and
				drop
			</div>
			<div>
				{filesTypesDescription} (max. {bytesToSize(maxSize)})
			</div>
		</div>
	</div>
);

export default Input;
