import React from "react";
import Table from "modules/candidate/Profile/components/Table/Table.js";
import { ContractsGroupActionsCell } from "../../components/ContractsGroupActionsCell";

const ContractsGroupsList = ({ onDeleteClick, onModifyClick, list }) => {
	const ActionCell = args => {
		const contractsGroup = args.row.original;

		return (
			<ContractsGroupActionsCell
				isDeleteButtonDisabled={contractsGroup.total > 0}
				onModifyClick={() => onModifyClick(contractsGroup)}
				onDeleteClick={() => onDeleteClick(contractsGroup)}
			/>
		);
	};

	const columns = [
		{
			Header: "Name",
			accessor: "name"
		},
		{
			Header: "Number of channels",
			accessor: "total"
		},
		{
			Header: "Action",
			accessor: "action",
			Cell: ActionCell
		}
	];

	return <Table data={list} columns={columns} />;
};

export default ContractsGroupsList;
