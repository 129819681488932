import { CustomDropDown } from "common/CustomDropDown";
import { DropDownInput } from "common/DropDownInput";
import React from "react";
import { useFilters } from "../hooks/useFilter";
import { getComponentByOperator } from "../utils";

const OperatorsField = ({ filter }) => {
	const { replaceFilter, getState, currentFilterId } = useFilters();
	const { operatorsByType, componentsByOperator } = getState(currentFilterId);
	const type = filter?.type;

	if (!filter.name) return <DropDownInput isDisabled placeHolder="Operator" />;

	const onChange = newOperator => {
		const { type, operator } = filter;

		const component = getComponentByOperator(
			type,
			operator,
			componentsByOperator
		);

		const newComponent = getComponentByOperator(
			type,
			newOperator,
			componentsByOperator
		);

		const value = {
			...filter,
			operator: newOperator,
			value: newComponent !== component ? "" : filter.value
		};

		replaceFilter(value, "operatorChange");
	};

	return (
		<CustomDropDown
			value={filter.operator}
			onChange={onChange}
			options={operatorsByType?.[type]}
		/>
	);
};

export default OperatorsField;
