import { client } from "lib/api-client";
import { MESSAGING_TOOL_FILTER_OPTIONS } from "config/api-endpoints";
import { useQuery } from "react-query";
import fakeData from "modules/user-onboarding/mockData/email-template-langs.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getLanguages = (_, body) => {
	return client(MESSAGING_TOOL_FILTER_OPTIONS, {
		body
	});
};

export const GET_EMAIL_TEMPLATE_LANGUAGES = "GET_EMAIL_TEMPLATE_LANGUAGES";

export function useGetEmailTemplateLanguages(
	body = { filter: "version_languages" },
	options
) {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery([GET_EMAIL_TEMPLATE_LANGUAGES, body], () => {
			return Promise.resolve(fakeData);
		});
	}

	return useQuery([GET_EMAIL_TEMPLATE_LANGUAGES, body], getLanguages, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
}
