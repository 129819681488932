import { SEARCH_ADDRESS, COMPANY_ADDRESS } from "config";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import flatten from "lodash/flatten";
import _get from "lodash/get";
import { PHONE_SCREEN } from "config";

export const getAttendees = (connectedUser, vacancyId) => {
	return new Promise((resolve, reject) => {
		client(FILTER_SETTINGS_ENDPOINT, {
			body: {
				tag: "temporary_user_attendees",
				job_id: vacancyId
			}
		})
			.then(res => {
				const rest = res.list_users.map(({ _id, user_name, avatar }) => ({
					id: _id,
					label: user_name,
					avatar
				}));

				const user = {
					id: connectedUser.id,
					label: `${connectedUser.first_name} ${connectedUser.last_name}`,
					avatar: connectedUser.avatar
				};

				resolve([{ id: 1, label: "Collaborators", children: [user, ...rest] }]);
			})
			.catch(e => reject(e));
	});
};

export const composeAddress = addressObj => {
	return `${_get(addressObj, "street", "")} ${_get(addressObj, "number", "")}${
		addressObj.number && addressObj.box ? " / " : " "
	}${_get(addressObj, "box", "")} ${_get(addressObj, "zip", "")} ${
		_get(addressObj, "city") ? `${_get(addressObj, "city")},` : ""
	} ${_get(addressObj, "country", "")}`
		.replace(/\s\s+/g, " ")
		.replace(/\s,+/g, ", ");
};

export const isTimeValid = (startTime, endTime) => {
	if (startTime && endTime) {
		return endTime.hour === startTime.hour
			? endTime.minute > startTime.minute
			: endTime.hour > startTime.hour;
	}
	return false;
};

export const prepareProposeInterviewPayload = ({
	formData,
	logEvent,
	companyType,
	applicationId,
	interviewId,
	isDeclineInterview
}) => {
	const availabilitySlots = formData.times.map(time => {
		const formattedDate = window
			.moment(time.date.toDate())
			.format("YYYY-MM-DD");
		const formattedStartTime = window
			.moment(time.startTime.toString(), "HH:mm:ss")
			.format("HH:mm");
		const formattedEndTime = window
			.moment(time.endTime.toString(), "HH:mm:ss")
			.format("HH:mm");
		return {
			start_date: window
				.moment(`${formattedDate} ${formattedStartTime}`)
				.utc()
				.unix(),
			end_date: window
				.moment(`${formattedDate} ${formattedEndTime}`)
				.utc()
				.unix()
		};
	});

	const attendeesIds = flatten(
		formData.attendees.map(({ children }) => children.map(({ id }) => id))
	);
	const locationPayload = [SEARCH_ADDRESS, COMPANY_ADDRESS].includes(
		formData.locationType
	)
		? {
				...JSON.parse(formData.locationValue)
		  }
		: {};

	const payload = {
		id: formData.id,
		description: formData.note,
		attendees_users: attendeesIds,
		location_type: formData.locationType,
		...locationPayload,
		application_id: applicationId,
		recipient_type: companyType,
		log_event: logEvent,
		availability_slots: availabilitySlots,
		subject: formData.subject,
		content: formData.content,
		content_placeholders: formData.content_placeholders.map(placeholder => ({
			key: placeholder.value,
			value:
				placeholder.value === "INTERVIEW_LINK" && !placeholder._id
					? isDeclineInterview
						? interviewId
						: formData.id
					: placeholder._id || ""
		})),
		attachments: [],
		...(interviewId && {
			interview_id: interviewId
		})
	};
	return payload;
};

export const preparePhoneScreenPayload = ({
	formData,
	logEvent,
	companyType,
	applicationId,
	interviewId,
	isDeclineInterview
}) => {
	const availabilitySlots = formData.times.map(time => {
		const formattedDate = window
			.moment(time.date.toDate())
			.format("YYYY-MM-DD");
		const formattedStartTime = window
			.moment(time.startTime.toString(), "HH:mm:ss")
			.format("HH:mm");
		const formattedEndTime = window
			.moment(time.endTime.toString(), "HH:mm:ss")
			.format("HH:mm");
		return {
			start_date: window
				.moment(`${formattedDate} ${formattedStartTime}`)
				.utc()
				.unix(),
			end_date: window
				.moment(`${formattedDate} ${formattedEndTime}`)
				.utc()
				.unix()
		};
	});
	const attendeesIds = flatten(
		formData.attendees.map(({ children }) => children.map(({ id }) => id))
	);

	const payload = {
		id: formData.id,
		description: formData.note,
		attendees_users: attendeesIds,
		location_type: PHONE_SCREEN,
		application_id: applicationId,
		recipient_type: companyType,
		phone: `${formData.phone.countryCode.toUpperCase()} ${
			formData.phone.value
		}`,
		log_event: logEvent,
		availability_slots: availabilitySlots,
		subject: formData.subject,
		content: formData.content,
		content_placeholders: formData.content_placeholders.map(placeholder => ({
			key: placeholder.value,
			value:
				placeholder.value === "INTERVIEW_LINK" && !placeholder._id
					? isDeclineInterview
						? interviewId
						: formData.id
					: placeholder._id || ""
		})),
		attachments: [],
		...(interviewId && {
			interview_id: interviewId
		})
	};
	return payload;
};
