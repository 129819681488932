import React from "react";
import StatusVacancy from "common/StatusVacancy";

const VacancyStatus = ({ status, label, className }) => {
	return (
		<>
			<StatusVacancy className={className} status={status} label={label} />
		</>
	);
};

export default VacancyStatus;
