import create from "zustand";
import moment from "moment-timezone";
import { TIMEZONE } from "config";

moment.tz.setDefault(TIMEZONE);

const initialValues = {
	interview_start_date: moment().startOf("day"),
	interview_end_date: moment().endOf("day"),
	interview_recipient_type: { label: "All", value: "" },
	interviews: [],
	isSettedDate: true
};
const useInterviewStore = create(set => ({
	...initialValues,
	setItemInterview: (key, value) => set({ [key]: value }),
	resetInterviews: () => set({ ...initialValues })
}));

export default useInterviewStore;
