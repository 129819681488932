import React from "react";

export default function ManagerIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 286 286"
			xmlSpace="preserve"
			style={{
				width: 15,
				marginRight: 10
			}}
		>
			<g>
				<g>
					<g>
						<g>
							<path
								fill="#505c68"
								d="M189.9,174.2c25.6-15.6,42.4-43.5,42.4-75.8c0-49-40.1-89.2-89.2-89.2S53.9,49.4,53.9,98.4c0,32.3,16.7,60.2,42.4,75.8
					c-44.6,16.7-78,55.8-87,102.6H21v0.1h243.8v-0.1H277C267.9,228.8,234.5,189.9,189.9,174.2z M76.1,98.4
					c0-36.8,30.1-66.9,66.9-66.9s66.9,30.1,66.9,66.9s-30.1,66.9-66.9,66.9S76.1,135.2,76.1,98.4z M39.2,254.2
					c17.9-39.4,57.7-66.5,104-66.5s86.1,27.1,104,66.5H39.2z"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
