import create from "zustand";

const store = create(set => ({
	isOpen: false,
	toggle: () => set(({ isOpen }) => ({ isOpen: !isOpen }))
}));

export const useUnsavedChangesDialog = () => {
	const { isOpen, toggle } = store(state => state);

	return [isOpen, toggle];
};

export default useUnsavedChangesDialog;
