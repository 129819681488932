import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { array, object, string } from "yup";
import { TIME_FORMAT_24, DATETIME_FORMAT, TIMEZONE } from "config";
import {
	ADD_INTERVIEW_NOTE_MAX_LENGTH,
	TEMP_INTERVIEW_LOCATION_TYPES
} from "../utils/constants";
import { useGetUser } from "hooks/useGetUser";

export const useInviteFreelancerInterviewForm = () => {
	const connectedUser = useGetUser();
	let schemaTemplate = {
		day: object().test("Required", "Please select a day", function(value) {
			return !!value;
		}),
		startTime: object().test("Required", "Time is invalid", function(value) {
			return !!value;
		}),
		endTime: object().test("Required", "Time is invalid", function(value) {
			const { startTime } = this.parent;
			if (!value) {
				return false;
			} else if (startTime && value) {
				const startHour = startTime.format(TIME_FORMAT_24).split(":")[0];
				const endHour = value.format(TIME_FORMAT_24).split(":")[0];
				const startMinute = startTime.format(TIME_FORMAT_24).split(":")[1];
				const endMinute = value.format(TIME_FORMAT_24).split(":")[1];
				return parseInt(endHour) === parseInt(startHour)
					? parseInt(endMinute) > parseInt(startMinute)
					: parseInt(endHour) > parseInt(startHour);
			}
		}),
		attendees: array().test("Required", "Please select attendees", function(
			value
		) {
			return value?.[0]?.children.length > 0;
		}),
		customAddress: object().test(
			"Required",
			"Please select custom address",
			function(value) {
				const { locationType } = this.parent;
				if (locationType === "search_address") {
					return !!value?.zip;
				} else {
					return true;
				}
			}
		),
		note: string().test(
			"Required",
			`The input is more than ${ADD_INTERVIEW_NOTE_MAX_LENGTH} characters long`,
			function(value) {
				if (!value) return true;
				else return value?.length <= ADD_INTERVIEW_NOTE_MAX_LENGTH;
			}
		)
	};
	const schema = object().shape(schemaTemplate);
	const defaultStartDate = window.moment().add(5, "minute");
	const defaultEndDate = window.moment().add(65, "minute");
	const attendees = [
		{
			id: 1,
			label: "Collaborators",
			children: [
				{
					id: connectedUser.id,
					label: `${connectedUser.first_name} ${connectedUser.last_name}`
				}
			]
		}
	];
	const res = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			locationType: TEMP_INTERVIEW_LOCATION_TYPES.companyAddress,
			startTime: window.moment.tz(
				`${defaultStartDate.format(DATETIME_FORMAT)}`,
				DATETIME_FORMAT,
				TIMEZONE
			),
			endTime: window.moment.tz(
				`${defaultEndDate.format(DATETIME_FORMAT)}`,
				DATETIME_FORMAT,
				TIMEZONE
			),
			attendees
		},
		shouldUnregister: true
	});
	return res;
};
