import React, { useEffect, useRef, useState } from "react";
import Table from "common/AgTable/AgTable";
import { generateEmptyArray } from "config/helpers";
import { formatTasksList } from "modules/TasksList/utils/helpers";
import { useGetColumns } from "modules/TasksList/hooks/useGetColumns";
import {
	API_DATA_TYPE,
	TASKS_LIST_FILTER_ID,
	TASKS_LIST_PAGE_SIZE,
	TASKS_TABLE_COLUMNS_IDS
} from "modules/TasksList/utils/constant";
import useOpenTaskDetailsDrawer from "../TaskDetailsDrawer/hooks/useOpenTaskDetailsDrawer";
import { getTaskDetailsRoute } from "modules/TasksList/TasksList";
import { browserHistory } from "react-router";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { validate } from "uuid";
import EmptyState from "../EmptyState/EmptyState";

export default function TasksListTable({
	columns: propsColumns,
	onActionsCellClick
}) {
	const [List, setList] = useState();
	const ref = useRef(null);
	const { getState } = useFilters();
	const { data: propsData, isFetching, isLoading } = getState(
		TASKS_LIST_FILTER_ID
	);

	useEffect(() => {
		if (propsData) {
			const list = formatTasksList(propsData.tasks);
			setList(list);
		}
	}, [propsData]);

	const columns = useGetColumns({
		columns: propsColumns,
		isFetching,
		isLoading,
		tableRef: ref,
		onActionsCellClick: onActionsCellClick
	});

	const { setIsOpen } = useOpenTaskDetailsDrawer();
	const pathname = window.location.pathname.split("/");
	const taskId = pathname[pathname.length - 1];

	useEffect(() => setIsOpen(validate(taskId)), [taskId]);

	const onRowClick = ({ column, data }) => {
		const colId = column.colId;
		if (colId === "_selects" || colId === TASKS_TABLE_COLUMNS_IDS.attachment)
			return;

		browserHistory.replace(getTaskDetailsRoute(data?._id));
	};

	return (
		<>
			<Table
				ref={ref}
				columnsSettings={columns}
				rows={
					isLoading || isFetching
						? generateEmptyArray(TASKS_LIST_PAGE_SIZE)
						: List
				}
				typeTag={API_DATA_TYPE}
				onCellClicked={onRowClick}
				noRowsOverlayComponent={() => (
					<EmptyState
						title="No tasks Available"
						description="You currently have no tasks recorded in your list."
					/>
				)}
			/>
		</>
	);
}
