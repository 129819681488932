import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { initialize } from "redux-form";
import CompanyDashboard from "common/dashboard/Dashboard";
import { SwitcherContainer, Switcher } from "common/styled/CommonStyled";
import {
	BUSINESS_UNITS,
	BUSINESS_UNIT_MANAGERS,
	CONSULTANTS,
	ADMIN,
	PROJECT_MANAGER
} from "config";
import Intro from "common/dashboard/Intro";
import BusinessUnit from "./BusinessUnit";
import BusinessUnitManager from "./bum/BusinessUnitManager";
import {
	getBUList,
	editBUModal,
	resendInvite,
	resendBusinessUnitManagerInvite,
	getBusinessUnitManagersList
} from "../redux/actions";
import {
	getConsultants,
	consultantAccessChanged,
	businessUnitManagerAccessChanged
} from "../../company/actions/usersListActions";
import {
	BUListSelector,
	currentBUSelector,
	activeModalSelector,
	BusinessUnitManagersListSelector
} from "../redux/selectors";
import {
	BUMListSelector,
	userManagerBUListSelector
} from "../../company/selectors/createCompanySelectors";
import { selectAuthenticatedUser } from "../../job/selectors";
import Consultants from "./consultant/Consultants";

export const getMyConsultantsRoute = () => {
	return "/my-consultants";
};

export const getMyConsultantsPermission = () => {
	return {
		type: [
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
};

class MyConsultants extends Component {
	switchView = type => {
		window.location.hash = type;
	};

	accessChanged = (user, options) => {
		this.props.accessChanged(user, false, options);
	};

	initialize = (form, values) => {
		this.props.initialize(form, values);
	};

	componentDidMount() {
		this.props.getBUList({ search: "", offset: 0 });
	}

	render() {
		const {
			route: { title },
			location: { hash },
			BUList,
			ConsultantsList,
			AuthenticatedUser,
			currentBU,
			activeModal,
			editBUModal,
			getUsers,
			businessUnitManagersList,
			UserBUList
		} = this.props;

		const selectedTab = hash.substring("1");

		const isConnectedUserSuperAdmin = !AuthenticatedUser.is_member;

		const isManager =
			AuthenticatedUser.role_name === ADMIN && !isConnectedUserSuperAdmin;

		return (
			<BodyClassName className="gray-bg">
				<div className="company-table">
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<div className="dashboard-box">
						<CompanyDashboard />
						<Intro icon="fa fa-clipboard" title="Consultants">
							{isConnectedUserSuperAdmin ? (
								<p>
									As the account “superuser”, you are the only one able to
									create Business Units and assign them to your company’s
									Managers - if applicable (You can manage the Business Unit(s)
									and its/their consultants yourself). This section is also
									meant to create your Consultants’ profile.This can be done by
									you; or by a Business Unit Manager (but only for his/her
									Business Unit).
									<br />
									You are solely responsible for ensuring that you and/or your
									Business Unit Manager have a lawful basis to be able to submit
									to, and keep, consultant information on our platform.
								</p>
							) : (
								<p>
									This section allows you to register and edit the profiles of
									the consultants who are part of your Business Unit.
								</p>
							)}
						</Intro>
					</div>
					<div className="container">
						{isConnectedUserSuperAdmin && (
							<SwitcherContainer bordred paddingTop="0px" id="consultant-tabs">
								<Switcher
									checked={!selectedTab || selectedTab.includes(BUSINESS_UNITS)}
									onClick={() => this.switchView(BUSINESS_UNITS)}
								>
									Business Units
								</Switcher>

								<Switcher
									checked={selectedTab === BUSINESS_UNIT_MANAGERS}
									onClick={() => this.switchView(BUSINESS_UNIT_MANAGERS)}
								>
									Business Unit Managers
								</Switcher>

								<Switcher
									checked={selectedTab === CONSULTANTS}
									onClick={() => this.switchView(CONSULTANTS)}
								>
									Consultants
								</Switcher>
							</SwitcherContainer>
						)}

						{isConnectedUserSuperAdmin &&
							(!selectedTab || selectedTab.includes(BUSINESS_UNITS)) && (
								<BusinessUnit
									size={BUList?.size}
									total={BUList?.total}
									list={BUList?.data}
									editBUModal={editBUModal}
									currentBU={currentBU}
									activeModal={activeModal}
									getUsers={getUsers}
									fetchList={this.props.getBUList}
									isLoading={this.props.isBusinessUnitLoading}
								/>
							)}

						{isConnectedUserSuperAdmin &&
							selectedTab === BUSINESS_UNIT_MANAGERS && (
								<BusinessUnitManager
									isEmpty={
										businessUnitManagersList?.users_list?.[0].data.length === 0
									}
									AuthenticatedUser={AuthenticatedUser}
									userBUList={BUList?.data}
									BUList={BUList?.data}
									ManagerBUList={businessUnitManagersList?.manager_groups_list}
									list={businessUnitManagersList?.users_list?.[0].data}
									size={businessUnitManagersList?.users_list?.[0]?.size}
									total={businessUnitManagersList?.users_list?.[0]?.total}
									initialize={this.initialize}
									fetchList={this.props.getBusinessUnitManagersList}
									resend={this.props.resendBusinessUnitManagerInvite}
									isLoading={this.props.isBusinessUnitManagersLoading}
									accessChanged={this.props.businessUnitManagerAccessChanged}
								/>
							)}
						{(selectedTab === CONSULTANTS || isManager) && (
							<Consultants
								isEmpty={ConsultantsList?.[0]?.data?.length === 0}
								AuthenticatedUser={AuthenticatedUser}
								accessChanged={this.accessChanged}
								isManager={isManager}
								is_member={AuthenticatedUser.is_member}
								list={ConsultantsList?.[0]?.data}
								userBUList={UserBUList}
								BUList={BUList?.data}
								total={ConsultantsList?.[0]?.total}
								size={ConsultantsList?.[0]?.size}
								offset={ConsultantsList?.[0]?.offset}
								fetchList={this.props.getUsers}
								resend={this.props.resendInvite}
								isLoading={this.props.isConsultantsLoading}
							/>
						)}
					</div>
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		UserBUList: state.usersList?.users?.groups_list,
		isConsultantsLoading: state.usersList.loading,
		businessUnitManagersList: BusinessUnitManagersListSelector(state),
		BUList: BUListSelector(state),
		ManagerBUList: userManagerBUListSelector(state),
		ConsultantsList: BUMListSelector(state, PROJECT_MANAGER),
		activeModal: activeModalSelector(state),
		currentBU: currentBUSelector(state),
		AuthenticatedUser: selectAuthenticatedUser(state),
		isBusinessUnitManagersLoading:
			state.consultancy.isBusinessUnitManagersLoading,
		isBusinessUnitLoading: state.consultancy.isBusinessUnitLoading
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getBUList,
			editBUModal,
			getUsers: getConsultants,
			accessChanged: consultantAccessChanged,
			businessUnitManagerAccessChanged,
			initialize,
			resendInvite,
			getBusinessUnitManagersList,
			resendBusinessUnitManagerInvite
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(MyConsultants);
