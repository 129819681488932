import React, { Component } from "react";
import Datetime from "react-datetime";
import { Element } from "react-scroll";
import { DATE_FORMAT, TIMEZONE } from "config";
import { getTrueType } from "config/helpers";
import { ReactComponent as CloseIcon } from "static/icons/close.svg";
import "./closeIcon.css";
import { ReactComponent as ErrorIcon } from "common/react-hook-form-components/icons/elements-icons-error.svg";
import GlobalTooltip from "common/GlobalTooltip";
import styles from "./date-picker-field.module.scss";

class DatePickerField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCalendarChanged: false,
			clearCalendar: false
		};
	}

	onClearCalendar = () => {
		const { handleCalendarChanged } = this.props;
		this.setState({
			clearCalendar: true,
			isCalendarChanged: false
		});
		handleCalendarChanged(false);
	};
	renderInput = ({ className, onClick, placeholder, value, tabIndex }) => {
		const {
			inputClassName,
			readOnly,
			disabled,
			displayResetIcon = false
		} = this.props;
		const { isCalendarChanged } = this.state;
		return (
			<div className="close-icon-container">
				<input
					value={value}
					onClick={onClick}
					placeholder={placeholder}
					disabled={disabled}
					className={`${className} datePickerReadOnlyInput ${inputClassName}`}
					tabIndex={tabIndex}
					readOnly={readOnly}
				/>
				{displayResetIcon && (
					<CloseIcon
						className={
							isCalendarChanged || value !== ""
								? "set-display close-icon"
								: "close-icon"
						}
						onClick={this.onClearCalendar}
					/>
				)}
			</div>
		);
	};

	isDateValid = date => {
		const { minDate, maxDate } = this.props;
		let valid = true;

		if (minDate && window.moment(minDate).isAfter(date, "day")) {
			valid = false;
		}
		if (maxDate && window.moment(maxDate).isBefore(date, "day")) {
			valid = false;
		}
		return valid;
	};

	changeInput = currentDate => {
		this.setState({
			isCalendarChanged: true,
			clearCalendar: false
		});
		const onChange = this.props.input && this.props.input.onChange;
		if (currentDate && window.moment.isMoment(currentDate) && onChange) {
			return onChange(window.moment(currentDate.clone()).tz(TIMEZONE));
		}
	};

	renderDay = ({ className, style, onClick, key }, currentDate) => {
		const _onClick = onClick ? () => this.changeInput(currentDate) : undefined;
		return (
			<td key={key} className={className} style={style} onClick={_onClick}>
				{currentDate.date()}
			</td>
		);
	};

	render() {
		const {
			label,
			placeholder,
			icon,
			input,
			classes,
			disabled,
			dateFormat,
			viewMode,
			minDate,
			isMakeABid,
			tooltip,
			meta: { touched, error },
			tabIndex,
			required,
			labelClassName
		} = this.props;
		const { clearCalendar } = this.state;
		const has_error = touched && error ? "has-error has-feedback" : "";
		const name =
			"input" in this.props ? this.props.input.name : this.props.name;
		let value = clearCalendar ? "" : input.value;
		if (value && !window.moment.isMoment(value)) {
			value =
				getTrueType(value) === "number"
					? window.moment.unix(value)
					: window.moment(value, dateFormat);
		}

		return (
			<Element
				className={`form-group ${classes} ${has_error}`}
				name={name}
				scrollto="scroll-to-error"
			>
				{label && (
					<label className={labelClassName} htmlFor={name}>
						{label}
						{required && <span className={styles.asterisk}>*</span>}
						{tooltip && (
							<GlobalTooltip
								withWrappingDiv={false}
								placement="top"
								overlay={tooltip}
								maxWidth={"180px"}
							>
								<span className="icon-info"></span>
							</GlobalTooltip>
						)}{" "}
					</label>
				)}
				<div className="input-box">
					{icon && <i className={icon} />}
					<Datetime
						input={true}
						id={name}
						name={name}
						value={
							value || (isMakeABid && window.moment().format("DD/MM/YYYY"))
						}
						viewMode={viewMode}
						viewDate={minDate}
						closeOnSelect={true}
						inputProps={{
							placeholder,
							disabled,
							tabIndex
						}}
						renderInput={this.renderInput}
						renderDay={this.renderDay}
						isValidDate={this.isDateValid}
						dateFormat={dateFormat}
						timeFormat={false}
						closeOnTab={true}
					/>
				</div>
				{touched && error && (
					<div className="help-block inline-error">
						{" "}
						<ErrorIcon /> {touched && error}
					</div>
				)}
			</Element>
		);
	}
}

DatePickerField.defaultProps = {
	placeholder: DATE_FORMAT,
	dateFormat: DATE_FORMAT,
	inputClassName: "",
	readOnly: true,
	viewMode: "days",
	meta: {}
};

export default DatePickerField;
