import React from "react";
import styles from "./messaging-tool.module.scss";
import {
	MessagingToolContent,
	MessagingToolHeader,
	MessagingToolSidebar
} from "./components";
import { useBreadCrumbs } from "hooks/useBreadCrumbs";
import { historyReplace } from "config/helpers";
import { MESSAGING_TOOL_LINKS } from "./utils/constant";
import { withRouter } from "react-router";

export const getMessagingToolRoute = hash => {
	return hash ? `/mail/${hash}` : "/mail/:hash";
};

export const getMessagingToolPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const MessagingTool = ({ params }) => {
	useBreadCrumbs([]);

	React.useEffect(() => {
		if (!params.hash) {
			historyReplace(getMessagingToolRoute(MESSAGING_TOOL_LINKS.inbox.slug));
		}
	}, []);

	return (
		<div className={styles.container}>
			<MessagingToolHeader hash={params.hash} />
			<div className={styles.contentLayout}>
				<MessagingToolSidebar hash={params.hash} />
				<MessagingToolContent hash={params.hash} />
			</div>
		</div>
	);
};

export default withRouter(MessagingTool);
