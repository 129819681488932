/* eslint-disable react/jsx-key, react/display-name */
import React, { useMemo, useState } from "react";
import BodyClassName from "react-body-classname";
import { useTable } from "react-table";
import { Flex, Text } from "rebass";
import cx from "classnames";
import { useToggle } from "react-use";
import create from "zustand";
import Dashboard from "common/dashboard/Dashboard";
import Intro from "common/dashboard/Intro";
import useListSupplierInvitations from "../api/useListSupplierInvitations";
import { formatDate } from "../../../config/helpers";
import s from "../view-supplier/invitation-history.module.scss";
import styles from "./invitations.module.scss";
import ContractDialog from "./ContractDialog";
import Pagination from "common/Pagination";
import Spinner from "../../../common/Spinner";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";

export function getSupplierInvitationsRoute() {
	return "/settings/invitations";
}

export function getSupplierInvitationsPermission() {
	return {
		type: [
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
}

const statusLabels = {
	sent: "Waiting for approval",
	accepted: "Accepted",
	refused: "Refused",
	draft: "Waiting for approval"
};

function Actions({ id, status, file }) {
	const [action, setAction] = useState(undefined);
	const [on, toggle] = useToggle(false);
	const Accept = (
		<button
			key={"resend"}
			className={cx(s.linkButton, styles.acceptCta)}
			onClick={() => {
				toggle(true);
				setAction("accept");
			}}
		>
			Accept
		</button>
	);

	const Refuse = (
		<button
			key={"refuse"}
			className={cx(s.linkButton, styles.refuseCta)}
			onClick={() => {
				toggle(true);
				setAction("refuse");
			}}
		>
			Refuse
		</button>
	);

	const Dialog = (
		<ContractDialog
			key={"contract-dialog"}
			id={id}
			action={action}
			isOpen={on}
			onClose={() => {
				toggle(false);
				setAction(undefined);
			}}
			file={file}
		/>
	);

	const actions = {
		sent: [Refuse, Accept, Dialog],
		draft: [Refuse, Accept, Dialog]
	};

	return actions[status] ?? "N/A";
}

const useOffset = create(set => ({
	offset: 0,
	setOffset: offset => set({ offset })
}));

function ClientInvitations() {
	const { offset, setOffset } = useOffset(state => ({
		offset: state.offset,
		setOffset: state.setOffset
	}));
	const { data, isLoading } = useListSupplierInvitations({
		offset: offset,
		limit: 24
	});
	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: row => formatDate(row.created_at)
			},
			{
				Header: "Client",
				accessor: row =>
					row.created_by.first_name + " " + row.created_by.last_name
			},
			{
				Header: "Category",
				accessor: "category.name"
			},
			{
				Header: "Contract file",
				accessor: row =>
					_isEmpty(row.tier?.tier_link) ? (
						"N/A"
					) : (
						<a
							className={styles.fileLink}
							target={"_blank"}
							rel="noopener noreferrer"
							href={_get(row, "tier.tier_link.link")}
						>
							{_get(row, "tier.tier_link.file_name")}
						</a>
					)
			},
			{
				Header: "Status",
				accessor: row => (
					<div className={cx(s.tableStatus, s[row.status])}>
						{statusLabels[row.status] ?? row.status}
					</div>
				)
			},
			{
				/*FIXME: link object*/
				Header: "Actions",
				Cell: ({ row: { original } }) => (
					<Actions
						status={original.status}
						file={original.tier?.tier_link}
						id={original._id}
					/>
				)
			}
		],
		[data]
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		data: data?.data ?? [],
		columns
	});

	return (
		<BodyClassName className="my-company my-profile">
			<>
				<div className="dashboard-box">
					<Dashboard />
					<div className={"container"} style={{ padding: "0 15px" }}>
						<Intro icon="fa fa-file-text-o" title={"Client Invitations"} />
					</div>
				</div>
				<div className={"container"}>
					{isLoading ? (
						<Flex
							flexDirection={"column"}
							sx={{ gap: 3 }}
							justifyContent={"center"}
							alignItems={"center"}
							height={"40rem"}
						>
							<Spinner
								width={32}
								height={32}
								color={"#ff6849"}
								duration={0.5}
							/>
							<Text>Loading, please wait...</Text>
						</Flex>
					) : data?.data?.length > 0 ? (
						<table
							className={cx(s.table, styles.supplierTable)}
							{...getTableProps()}
						>
							<thead>
								{headerGroups.map(headerGroup => (
									<tr {...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(column => (
											<th {...column.getHeaderProps()}>
												{column.render("Header")}
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody {...getTableBodyProps()}>
								{rows.map(row => {
									prepareRow(row);
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map(cell => {
												return (
													<td {...cell.getCellProps()}>
														{cell.render("Cell")}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					) : (
						<Flex
							height={"40rem"}
							alignItems={"center"}
							justifyContent={"center"}
						>
							<Text as={"h4"}>No invitations yet.</Text>
						</Flex>
					)}
					{data?.pagination.total > data?.pagination.size ? (
						<Pagination
							total={data?.pagination?.total}
							size={data?.pagination?.size}
							handlePageClick={page => setOffset(page * 24)}
						/>
					) : null}
				</div>
			</>
		</BodyClassName>
	);
}

export default ClientInvitations;
