import React from "react";
import _get from "lodash/get";
import _capitalize from "lodash/capitalize";
import GlobalTooltip from "common/GlobalTooltip";
import { Function, ShowMore } from "../Styled";

const Functions = ({ funcs, deleteFilter, openModal }) => {
	const sectors = {};

	funcs.forEach(func => {
		if (sectors[_get(func, "sector.parent_sector.name")]) {
			sectors[_get(func, "sector.parent_sector.name")].push(func);
		} else {
			sectors[_get(func, "sector.parent_sector.name")] = [func];
		}
	});
	const getGroupedFunctions = () => {
		let groupedFunctions = [];
		let displayedCount = 0;
		for (let sector in sectors) {
			if (displayedCount < 3) {
				groupedFunctions.push(
					<>
						{sectors[sector].map(func => {
							displayedCount += 1;
							return (
								<div
									key={func._id}
									style={{ marginBottom: "0px", marginTop: "10px" }}
									className="sidebar-functions"
								>
									<div className="row">
										<div className="col-md-10 functionsContainer">
											<GlobalTooltip
												active={
													_get(func, "sector.name") === null &&
													func.seniority === null
														? false
														: true
												}
												overlayClassName="g-tool-dark"
												placement="top"
												mouseEnterDelay={0}
												mouseLeaveDelay={0}
												overlay={
													<div>
														<div>
															Category:{" "}
															{_get(func, "sector.parent_sector.name")}
														</div>
														{func.sector.name && (
															<div>Sub-Category: {func.sector.name}</div>
														)}
														{func.name && <div>Function: {func.name}</div>}
														{_capitalize(func.seniority) && (
															<div>
																Seniority: {_capitalize(func.seniority)}
															</div>
														)}
													</div>
												}
											>
												<Function>
													<>
														{func.sector.parent_sector.name && (
															<li className="parent-sector-search">
																{func.sector.name === null &&
																func.seniority === null
																	? func.sector.parent_sector.name
																	: func.sector.parent_sector.name.length > 25
																	? `${func.sector.parent_sector.name.substr(
																			0,
																			25
																	  )}...`
																	: func.sector.parent_sector.name}
															</li>
														)}
														{func.sector.name && (
															<li className="sector-search">
																{func.sector.name.length > 25
																	? `${func.sector.name.substr(0, 25)}...`
																	: func.sector.name}
															</li>
														)}
														{func.name && (
															<li className="function-search">
																{func.name.length > 25
																	? `${func.name.substr(0, 25)}...`
																	: func.name}
															</li>
														)}
														{func.seniority && <li>{func.seniority}</li>}
													</>
												</Function>
											</GlobalTooltip>
										</div>

										<div className="col-md-2">
											<span
												className="icon-close"
												onClick={() => {
													deleteFilter({
														id: func._id,
														sector_id: func.sector._id,
														parent_sector_id: func.sector.parent_sector._id,
														parent_sector_name: func.sector.parent_sector.name,
														name: func.name,
														seniority: func.seniority
													});
												}}
											></span>
										</div>
									</div>
								</div>
							);
						})}
					</>
				);
			}
		}
		return (
			<>
				{groupedFunctions}

				{displayedCount < funcs.length && (
					<ShowMore onClick={openModal}>
						<span> + </span>
						<span>({funcs.length - displayedCount}) Show more</span>
					</ShowMore>
				)}
			</>
		);
	};

	return <>{getGroupedFunctions()}</>;
};

export default Functions;
