import React, { useEffect, useState } from "react";
import styles from "./note-drawer.module.scss";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import NoteDrawerHeader from "./NoteDrawerHeader";
import DrawerBody from "./NoteDrawerBody";
import { ReactComponent as PreviousIcon } from "static/icons/drawer-notes/previousIcon.svg";
import { ReactComponent as NextIcon } from "static/icons/drawer-notes/nextIcon.svg";

const NoteDrawerDetails = ({
	currentNoteDetails: note,
	closeDrawer,
	notesList,
	total,
	offset,
	size,
	setOffset
}) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setIsOpen(true);
		});
	}, []);

	const afterVisibleChange = visibility => {
		if (!visibility) {
			closeDrawer();
		}
	};
	const page = offset / size;

	const notesIds = notesList.map(p => p._id);
	const [noteIndex, setNoteIndex] = useState(
		notesIds.indexOf(note.id) < 0 ? 0 : notesIds.indexOf(note.id)
	);

	const isLastNote =
		total - (page + 1) * size < 0 && notesList.length - 1 === noteIndex;
	const isFirstNote = noteIndex === 0 && offset === 0;

	const handleNextClick = async () => {
		if (isLastNote) return;
		let index;
		if (!notesList[noteIndex + 1]) {
			await setOffset((page + 1) * size);
			index = 0;
		} else {
			index = noteIndex + 1;
		}
		setNoteIndex(index);
	};
	const handlePrevClick = async () => {
		if (isFirstNote) return;
		let index;
		if (!notesList[noteIndex - 1]) {
			await setOffset((page - 1) * size);
			index = size - 1;
		} else {
			index = noteIndex - 1;
		}
		setNoteIndex(index);
	};

	return (
		<div className={styles.container}>
			<Drawer
				open={isOpen}
				width="700px"
				height="100%"
				placement="right"
				style={{ zIndex: 1000, backgroundColor: "transparent" }}
				level={"root"}
				maskClosable={true}
				onClose={() => setIsOpen(false)}
				afterVisibleChange={afterVisibleChange}
			>
				<div className={styles.container}>
					<div className={styles.root}>
						<NoteDrawerHeader
							onClose={() => setIsOpen(false)}
							title={"Candidate note"}
						/>
						{notesList[noteIndex] && <DrawerBody note={notesList[noteIndex]} />}

						<div className={styles.drawerFooter}>
							<button
								type="button"
								disabled={isFirstNote}
								onClick={handlePrevClick}
							>
								<PreviousIcon /> Previous
							</button>
							<button
								type="button"
								onClick={handleNextClick}
								disabled={isLastNote}
							>
								Next
								<NextIcon />
							</button>
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	);
};
export default NoteDrawerDetails;
