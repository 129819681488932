import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_UPCOMING_MISSIONS } from "config/api-endpoints";
import { DESC } from "config";

const listUpcomingMissions = (key, { start_date, end_date, tag }) =>
	client(LIST_UPCOMING_MISSIONS, {
		body: {
			start_date,
			end_date,
			tag,
			sort: DESC
		}
	});

const useUpcomingMissionsList = (key, options, queryOptions = {}) =>
	useQuery([key, options], listUpcomingMissions, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useUpcomingMissionsList;
