import React from "react";
import { Flex, Box } from "rebass";
import range from "lodash/range";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const MyCandidatesSkeletonCards = () => {
	return (
		<Box
			sx={{
				marginTop: "16px",
				paddingRight: "20px",
				display: "grid",
				gridGap: 3,
				gridTemplateColumns: "repeat(auto-fill, minmax(310px, 1fr))"
			}}
		>
			{range(24).map(i => (
				<Box
					key={i}
					sx={{
						position: "relative",
						boxShadow: "0 0 6px 4px rgba(0, 0, 0, 0.04)",
						backgroundColor: "rgb(255, 255, 255)",
						borderRadius: "6px",
						padding: "24px 8px 14px 8px",
						transition: "box-shadow 0.3s ease-in-out",
						"&:hover h5 span": {
							color: "#1686f7"
						},
						"&:hover": {
							boxShadow: "0 0 6px 4px rgba(0, 0, 0, 0.1)"
						}
					}}
				>
					<Flex alignItems="center" mb={12} sx={{ minHeight: "50px" }}>
						<SkeletonTheme color="#818a92">
							<Skeleton
								style={{ border: "4px solid #dfe3e8" }}
								circle={true}
								height={41}
								width={41}
							/>
						</SkeletonTheme>

						<Box ml={"10px"}>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton width={160} height={16} />
							</SkeletonTheme>
						</Box>
					</Flex>
					<Flex
						mb={16}
						sx={{
							padding: "4px 8px",
							borderRadius: "6px",
							backgroundColor: "#f6f7fb",
							minHeight: "41px"
						}}
						alignItems="center"
					>
						<Box mr={"6px"}>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton style={{ borderRadius: 10 }} height={16} width={24} />
							</SkeletonTheme>
						</Box>
						<Box>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton
									style={{ borderRadius: 10 }}
									height={16}
									width={125}
								/>
							</SkeletonTheme>
						</Box>
					</Flex>
					<Flex justifyContent="flex-end">
						<Box mr={"10px"}>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton style={{ borderRadius: 10 }} height={16} width={60} />
							</SkeletonTheme>
						</Box>
						<Box>
							<SkeletonTheme color="#dfe3e8">
								<Skeleton style={{ borderRadius: 10 }} height={16} width={60} />
							</SkeletonTheme>
						</Box>
					</Flex>
				</Box>
			))}
		</Box>
	);
};

export default MyCandidatesSkeletonCards;
