import React from "react";
import cx from "classnames";
import { useTable } from "react-table";
import Scrollbars from "react-custom-scrollbars";
import EmptyState from "../EmptyState";
import classnames from "./table.module.scss";

const Table = ({ data, fetchedData, onRowClick, columns, className }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data
	});

	return (
		<React.Fragment>
			<Scrollbars
				autoHide
				autoHideTimeout={5000}
				autoHideDuration={200}
				autoHeight
				autoHeightMax={600}
				renderTrackHorizontal={props => (
					<div
						{...props}
						style={{ display: "none" }}
						className="track-horizontal"
					/>
				)}
			>
				<table
					className={cx(classnames[className], classnames.table)}
					{...getTableProps()}
				>
					<thead className={classnames.tableHead}>
						{headerGroups.map(headerGroup => (
							<tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th
										key={index}
										{...column.getHeaderProps()}
										className={classnames[className]}
									>
										<span
											className={cx(classnames.columnsHeadline, {
												[classnames.sortedField]: column?.isSorted
											})}
										>
											{column.render("Header")}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					{data.length > 0 ? (
						<tbody className={classnames.tableBody} {...getTableBodyProps()}>
							{rows.map((row, index) => {
								prepareRow(row);
								return (
									<tr
										onClick={
											onRowClick
												? () => onRowClick(data, index, fetchedData)
												: null
										}
										key={index}
										{...row.getRowProps()}
									>
										{row.cells.map((cell, index) => {
											return (
												<td
													onClick={cell.column.onClick}
													key={index}
													{...cell.getCellProps()}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					) : null}
				</table>
				{data.length === 0 && (
					<div className={classnames.emtyStateWrraper}>
						<EmptyState />
					</div>
				)}
			</Scrollbars>
		</React.Fragment>
	);
};

export default Table;
