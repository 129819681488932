import React, { useState } from "react";
import { Section } from "@react-stately/collections";
import { Item } from "@react-stately/collections";
import Menu from "./components/MenuElements/Menu";
import get from "lodash/get";
import styles from "./styles.module.scss";
import { useDebounce } from "react-use";
import { SearchInput } from "common/SearchInput";
import MenuEmptyState from "./components/MenuEmptyState/MenuEmptyState";

/*===============================================================

   For this component the format of the data should be :
    data = [
       section ==> {
            type : String (ex: Communities)
            data : Object (ex: (name: "", _id:""))
            count: Number
        }
        ...
    ]
    
    Props: 
    selectedElement: Object
    setSelectedElement: returns the id and section key
    onSearch: function called when changing search input
    ...
 *===============================================================*/

const SectionsMenu = ({
	selectedElement,
	setSelectedElement,
	onSearch,
	data,
	isLoading,
	width,
	renderViewAllButton = () => {}
}) => {
	const [search, setSearch] = useState("");
	const [activeSection, setActiveSection] = useState("");

	useDebounce(
		() => {
			onSearch(search);
		},
		500,
		[search]
	);

	return (
		<>
			<div className={styles.menu} style={{ width: width ? width : "25rem" }}>
				<SearchInput
					onChange={e => {
						setSearch(e.target.value);
					}}
					variant="borderless"
				/>

				{isLoading ? (
					<Menu sections={["1", "2", "3", "4"]} isLoading={true}>
						<Section title={"1"} key={"1"}></Section>
						<Section title={"2"} key={"2"}></Section>
					</Menu>
				) : (
					<>
						{data && data.length > 0 ? (
							<>
								<Menu
									onAction={setSelectedElement}
									aria-label="Actions"
									shouldFocusWrap={false}
									sections={data.map(section => {
										return {
											type: get(section, "type", ""),
											count: get(section, "count", "")
										};
									})}
									selectedKey={get(selectedElement, "_id", "")}
									activeSection={activeSection}
									setActiveSection={setActiveSection}
								>
									{data.map(section => {
										return (
											<Section
												title={get(section, "type", "")}
												key={get(section, "type", "")}
											>
												{get(section, "data", []).map(sec => {
													return (
														<Item key={get(sec, "_id", "")}>
															{get(sec, "name", "")}
														</Item>
													);
												})}
											</Section>
										);
									})}
								</Menu>
								{renderViewAllButton()}
							</>
						) : (
							<MenuEmptyState />
						)}
					</>
				)}
			</div>
		</>
	);
};

export default SectionsMenu;
