import React, { useRef } from "react";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useLocale, useRangeCalendar } from "react-aria";
import { createCalendar } from "@internationalized/date";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import CalendarGrid from "../CalendarGrid/CalendarGrid";
import styles from "./range-calendar.module.scss";
import { RangeDatePickerFooter } from "../RangeDatePickerFooter";

function RangeCalendar(props) {
	const { onConfirm, onReset } = props;
	const { locale } = useLocale();

	let state = useRangeCalendarState({
		...props,
		visibleDuration: { months: 2 },
		locale,
		createCalendar
	});

	let ref = useRef();
	let { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
		props,
		state,
		ref
	);

	return (
		<div {...calendarProps} ref={ref}>
			<div className={styles.calendars}>
				<CalendarGrid
					buttonProps={prevButtonProps}
					state={state}
					Icon={ChevronLeftIcon}
					buttonPosition="left"
				/>
				<CalendarGrid
					state={state}
					Icon={ChevronRightIcon}
					offset={{ months: 1 }}
					buttonProps={nextButtonProps}
					buttonPosition="right"
				/>
			</div>
			<RangeDatePickerFooter onReset={onReset} onConfirm={onConfirm} />
		</div>
	);
}

export default RangeCalendar;
