import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { READ_MY_PROFILE } from "config/api-endpoints";

const readProfile = (_, body) =>
	client(READ_MY_PROFILE, {
		body
	});
export const readProfileKey = "@candidate/readProfile";

const useReadProfile = (options, queryOptions = {}) =>
	useQuery([readProfileKey, options], readProfile, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default useReadProfile;
