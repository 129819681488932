import { dateCalendar } from "common/Functions";
import Tooltip from "rc-tooltip";
import React from "react";
import { Box, Flex, Text } from "rebass";
import {
	HISTORY_TYPE,
	HISTORY_TYPE_LABEL,
	hexToRgbA
} from "../../profileModalTab/components/interactions/constants";

const ListHistory = ({
	jobTitle,
	fullName,
	historyType,
	createdAt,
	isLast,
	isFirst
}) => {
	const renderHistoryType = historyType => (
		<Box
			sx={{
				backgroundColor: hexToRgbA(HISTORY_TYPE[historyType], 0.14),
				padding: "4px 6px",
				borderRadius: "4px"
			}}
		>
			<Text
				sx={{
					color: HISTORY_TYPE[historyType],
					fontSize: "14px",
					fontWeight: "normal"
				}}
			>
				{HISTORY_TYPE_LABEL[historyType]}
			</Text>
		</Box>
	);
	return (
		<Flex
			sx={{
				borderBottom: !isLast ? "1px solid #f6f7fb" : "none",
				borderRadius: "10px"
			}}
			pt={!isFirst ? 2 : 0}
			pb={!isLast ? 2 : 0}
			alignItems="center"
			justifyContent="space-between"
		>
			<Box width="65%">
				<Flex mb="4px" alignItems="center">
					{fullName.length > 7 ? (
						<Tooltip
							placement="bottom"
							overlay={fullName}
							trigger="hover"
							overlayClassName="function-tooltip"
						>
							<Text
								sx={{
									fontSize: "16px",
									color: "#818a92",
									fontWeight: "normal"
								}}
							>
								{fullName.slice(0, 7) + "..."}
							</Text>
						</Tooltip>
					) : (
						<Text
							sx={{
								fontSize: "16px",
								color: "#818a92",
								fontWeight: "normal"
							}}
						>
							{fullName}
						</Text>
					)}

					<Box
						mx={1}
						width={4}
						height={4}
						sx={{ backgroundColor: "#2b3946", borderRadius: "50%" }}
					></Box>
					<Text
						sx={{
							fontSize: "12px",
							color: "#818a92",
							fontWeight: "normal"
						}}
						as="span"
					>
						{dateCalendar(createdAt)}
					</Text>
				</Flex>
				<Text
					sx={{
						fontSize: "16px",
						color: "#212b36",
						fontWeight: "normal"
					}}
				>
					{jobTitle}
				</Text>
			</Box>
			{renderHistoryType(historyType)}
		</Flex>
	);
};

export default ListHistory;
