import * as types from "../actions/actionTypes";
import Immutable from "seamless-immutable";

const initialState = {
	loading: false,
	loaded: false,
	data: null,
	error: null,
	rejectModal: false,
	interview_modal: {
		show: false,
		type: null
	},
	contract_modal: {
		show: false,
		type: null
	}
};

export default function(
	state = { ...initialState, showVideoConf: false },
	action
) {
	switch (action.type) {
		case types.FETCH_BID_SUCCEEDED:
			return Immutable.merge(state, {
				...initialState,
				data: action.data,
				contract_modal: state.contract_modal,
				loaded: true
			});
		case types.TOGGLE_INTERVIEW_MODAL:
			return Immutable.merge(state, {
				interview_modal: {
					show: !state.interview_modal.show,
					type: action.modal
				}
			});
		case types.TOGGLE_CONTRACT_MODAL:
			return Immutable.merge(state, {
				contract_modal: {
					show: action.show,
					type: action.modal
				}
			});
		case types.TOGGLE_REJECT_MODAL:
			return Immutable.merge(state, { rejectModal: !state.rejectModal });
		case types.SHOW_VIDEO_CONF:
			return Immutable.merge(state, { showVideoConf: true });
		case types.HIDE_VIDEO_CONF:
			return Immutable.merge(state, { showVideoConf: false });
		default:
			return state;
	}
}
