import React from "react";
import { Flex } from "rebass";
import { useDropzone } from "react-dropzone";
import _isEmpty from "lodash/isEmpty";
import { useMutation, useQueryCache } from "react-query";
import { CloudUploadIcon, PaperClipIcon } from "@heroicons/react/outline";
import _get from "lodash/get";
import Tooltip from "common/Tippy";
import { SectionWork } from "./Styled";
import s from "./droppable-header.module.scss";
import { client } from "lib/api-client";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import { uploadToS3 } from "config/helpers";
import Spinner from "common/Spinner";
import useUpdateFeatureSettings from "../api/useUpdateFeatureSettings";
import toaster from "common/Toaster";
import { categoryVariableMarginKey } from "./categoryTiering";

const preSignUrl = file =>
	client(PRE_SIGNED_URL, {
		body: {
			name: file.name,
			folder: "supplier",
			size: file.size,
			type: file.type
		}
	});

const HeaderKanban = ({
	name,
	title,
	link,
	featureId,
	tierId,
	categoryId,
	featureData
}) => {
	const queryCache = useQueryCache();
	const [updateFeature, { isLoading: isSaving }] = useUpdateFeatureSettings({
		onSuccess() {
			toaster.success("Tier contract file has been uploaded.");
			queryCache.invalidateQueries(categoryVariableMarginKey);
		},
		onError(err) {
			toaster.danger(_get(err, "detail.name", "Somewhere went wrong"));
		}
	});

	const [upload, { isLoading }] = useMutation(preSignUrl, {
		async onSuccess(result, vars) {
			const uploadResults = await uploadToS3({
				response: result,
				file: vars,
				file_name: vars.name
			});
			updateFeature({
				id: featureId,
				category_id: categoryId,
				freelancer: featureData?.feature_settings.freelancer,
				consultancy: {
					...featureData?.feature_settings.consultancy,
					tiers_management:
						featureData?.feature_settings.consultancy.tiers_management
				},
				tiers: [
					{
						_id: tierId,
						tier_link: {
							file_name: vars.name,
							link: uploadResults?.url
						}
					}
				]
			});
		}
	});
	const { getInputProps, open } = useDropzone({
		accept: ["application/pdf", ".docx", ".doc", ".xlsx"],
		onDrop(files) {
			upload(files?.[0]);
		},
		onDropRejected() {
			toaster.danger("File type not supported, only pdf.");
		}
	});

	return (
		<>
			<SectionWork>
				<ul>
					<li className="title">
						<Flex justifyContent={"space-between"}>
							<span>{title}</span>
							{name !== "blocked" ? (
								<Flex alignItems={"center"}>
									<input {...getInputProps()} />
									<Tooltip
										content={"Upload a contract file"}
										overflow={"visible"}
									>
										{isLoading || isSaving ? (
											<Spinner
												sx={{ height: 20, width: 20 }}
												color={"#ff6849"}
												duration={0.5}
											/>
										) : (
											<button
												className={s.button}
												onClick={e => {
													e.preventDefault();
													open();
												}}
											>
												<CloudUploadIcon width={20} height={20} />
											</button>
										)}
									</Tooltip>
									{!_isEmpty(link) && (
										<Tooltip
											overflow={"visible"}
											content={"Download the previously uploaded file."}
										>
											<Flex
												as={"a"}
												href={link.link}
												target={"_blank"}
												alignItems={"center"}
												justifyContent={"center"}
											>
												<PaperClipIcon
													color={"#FD5749"}
													width={20}
													height={20}
												/>
											</Flex>
										</Tooltip>
									)}
								</Flex>
							) : null}
						</Flex>
					</li>
					{name ? <li className="name">{name}</li> : null}
				</ul>
			</SectionWork>
		</>
	);
};
export default HeaderKanban;
