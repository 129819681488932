import { useMutation } from "react-query";
import { client } from "../../../lib/api-client";
import toaster from "../../../common/Toaster";

const createNewSupplier = body =>
	client("/api/companies/invite-supplier", { body });

const useCreateNewSupplier = ({ onClose }) => {
	return useMutation(createNewSupplier, {
		onSuccess() {
			toaster.success("Supplier created!");
			onClose();
		}
	});
};

export default useCreateNewSupplier;
