import React from "react";
import styles from "./profile-view-evaluations-summary-card-infos.module.scss";

const ProfileViewEvaluationSummaryCardInfos = ({ vacancy }) => {
	return (
		<div className={styles.summaryCardInfos}>
			<div className={styles.infos}>
				<span className={styles.vacancy}>{vacancy}</span>
			</div>
		</div>
	);
};
export default ProfileViewEvaluationSummaryCardInfos;
