//TODO Fix tooltip overflow
/* eslint-disable react/display-name */
import React, { useState } from "react";
import { FunctionsPickerModal } from "../FunctionsPickerModal";
import styles from "./profile-step-functions-field.module.scss";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import { CreateVacanyAddButton } from "../CreateVacanyAddButton";
import { BreadcrumbList } from "common/BreadcrumbList";
import { useFormContext } from "react-hook-form";
import { CreateVacancyEditButton } from "../CreateVacancyEditButton";
import { ReactComponent as Star } from "static/icons/contained-green-star.svg";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";

const Breadcrumb = ({
	item: { category, subCategory, seniority, _function, isMain }
}) => {
	const list = [
		category.label,
		subCategory.label,
		_function.label,
		seniority.label
	];

	return (
		<div
			className={isMain ? styles.breadcrumbWithMain : styles.breadcrumbNotMain}
		>
			<BreadcrumbList list={list} />
			{isMain && <Star />}
		</div>
	);
};

const ProfileStepFunctionsField = ({ onSave: onSaveProp }) => {
	const [displayFunctionsModal, setDisplayFunctionsModal] = useState(false);
	const { watch } = useFormContext();

	const handleAddFunctionsButtonClick = () => setDisplayFunctionsModal(true);

	const handleFunctionsModalCancelClick = () => setDisplayFunctionsModal(false);

	const handleFunctionsModalEscapeKeyDown = () =>
		setDisplayFunctionsModal(false);

	const onSave = list => {
		setDisplayFunctionsModal(false);
		onSaveProp(list);
	};

	const categoriesList = watch("categories");

	let breacrumbList = categoriesList?.map(item => (
		<Breadcrumb key={`${item.id}${item.isMain}`} item={item} />
	));

	return (
		<>
			<div className={styles.labelContainer}>
				<CreateVacancyFieldLabel>Functions</CreateVacancyFieldLabel>
				<CreateVacancyInfoIcon>
					Select the function(s) that best describes your need and set the
					seniority that you want your candidates to have in that function (this
					is not a global seniority value). Note that you can select up to 5
					functions to broaden the scope of your potential matches.
				</CreateVacancyInfoIcon>
			</div>
			{categoriesList?.length > 0 && (
				<div className={styles.breacrumbsContainer}>{breacrumbList}</div>
			)}
			{categoriesList?.length > 0 ? (
				<CreateVacancyEditButton onClick={handleAddFunctionsButtonClick} />
			) : (
				<CreateVacanyAddButton onClick={handleAddFunctionsButtonClick} />
			)}
			{displayFunctionsModal && (
				<FunctionsPickerModal
					onCancel={handleFunctionsModalCancelClick}
					onEscapeKeyDown={handleFunctionsModalEscapeKeyDown}
					onSave={onSave}
					list={watch("categories")}
				/>
			)}
		</>
	);
};

export default ProfileStepFunctionsField;
