import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { VIEW_TASK } from "config/api-endpoints";
export const getTaskDetailsKey = "@tasks/get_task_details";
function getTaskDetails(id) {
	return client(VIEW_TASK, {
		body: {
			id
		}
	});
}

function useTaskDetails({ id }) {
	return useQuery([getTaskDetailsKey, id], () => getTaskDetails(id), {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity
	});
}

export default useTaskDetails;
