import React, { useState } from "react";
import { get } from "lodash";
import styles from "./header-quick-view.module.scss";
import Avatar from "common/Avatar/index";
import {
	InfoOutline,
	Dot,
	Phone,
	Envelope,
	LinkedinIcon,
	ArrowDownIcon
} from "./icons";
import ProfileInfo from "./ProfileInfo";
import ContactInfoModal from "./ContactInfosQuickView";
import ViewCandidateHelper from "modules/candidate/components/profileClientView/HOC/navigationHelper";

import HeaderSkeleton from "./HeaderSkeleton";
import { ReactComponent as ArrowIcon } from "static/icons/arrow-to-right.svg";

const Header = ({ data, isLoading, setOpenDrawer, onProfileNameClick }) => {
	const [showProfileInfo, setShowProfileInfo] = useState(false);
	const [phonesModal, setPhoneModal] = useState(false);
	const [emailsModal, setEmailsModal] = useState(false);
	const [linksModal, setLinksModal] = useState(false);

	const showModalInfoProfile = () => {
		setShowProfileInfo(true);
	};

	const getMainLocation = location => {
		const mainLocation = location.filter(loc => loc.is_main);
		return (
			<>
				{get(mainLocation, "[0].city")
					? get(mainLocation, "[0].city") + ","
					: null}{" "}
				{get(mainLocation, "[0].country")}
			</>
		);
	};

	const getPhoneNumber = phone => {
		return (
			<>
				{"+"}
				{phone.replace(/\D/g, "")}
			</>
		);
	};

	const formatPhoneData = phone => {
		return phone.map(p => "+" + p.replace(/\D/g, ""));
	};

	const personal_profile = get(data, "personal_profile");
	const emailsArray = get(personal_profile, "emails", []);
	const emailPrimary = emailsArray
		.filter(profileMail => profileMail.is_primary)
		.map(item => {
			return item.email;
		});
	const otherEmails = emailsArray
		.filter(profileMail => !profileMail.is_primary)
		.map(item => {
			return item.email;
		});

	const phonesArray = get(personal_profile, "phones", []);
	const phonePrimary = phonesArray
		.filter(profilePhone => profilePhone.is_primary)
		.map(item => {
			return item.phone;
		});
	const otherPhones = phonesArray
		.filter(profilePhone => !profilePhone.is_primary)
		.map(item => {
			return item.phone;
		});

	const linkedin = get(personal_profile, "external_links", []);
	const primaryLink = linkedin
		.filter(profileLink => profileLink.is_primary)
		.map(item => {
			return item.link;
		});
	const otherLink = linkedin
		.filter(profileLink => !profileLink.is_primary)
		.map(item => {
			return item.link;
		});

	const fullName =
		get(data, "personal_profile.first_name") +
		" " +
		get(data, "personal_profile.last_name");

	return (
		<>
			<div className={styles.headerBackground}>
				<div className={styles.headerContainer}>
					<div className={styles.quickContainer}>
						<div className={styles.quickText}>Quick View </div>
						<button
							style={{ background: "transparent" }}
							onClick={() => setOpenDrawer(false)}
						>
							<ArrowIcon />
						</button>
					</div>
				</div>
				{isLoading ? (
					<HeaderSkeleton />
				) : (
					<div className={styles.infoProfile}>
						<div className={styles.headline}>
							<Avatar
								size={60}
								radius={4}
								src={get(data, "personal_profile.avatar")}
								name={fullName}
							/>
							<div className={styles.rightSide}>
								<h1>
									<div
										onClick={onProfileNameClick}
										className={styles.profileNameContainer}
									>
										{get(data, "personal_profile.first_name")}{" "}
										{get(data, "personal_profile.last_name")}
									</div>

									<span>
										<InfoOutline onClick={showModalInfoProfile} />
										{showProfileInfo && (
											<ProfileInfo
												setShowProfileInfo={setShowProfileInfo}
												data={data}
											/>
										)}
									</span>
								</h1>
								<h2 className={styles.secondaryTitle}>
									{get(data, "main_function.subcategory")}{" "}
									{get(data, "main_function.subcategory") ? <Dot /> : null}{" "}
									{get(data, "main_function.seniority")}
								</h2>
								<h2 className={styles.secondaryTitle}>
									{getMainLocation(
										get(
											data,
											"candidate_preferences.front_sheet.preference.preferred_location",
											[]
										)
									)}
								</h2>
							</div>
						</div>
						<div className={styles.contactInfos}>
							<ul className={styles.listInfo}>
								{get(data, "personal_profile.phones", [])?.length > 0 && (
									<li>
										<Phone />

										<span>{getPhoneNumber(phonePrimary[0])}</span>
										<div className={styles.containerModal}>
											{otherPhones.length > 0 && (
												<ArrowDownIcon onClick={() => setPhoneModal(true)} />
											)}{" "}
											{phonesModal && (
												<ContactInfoModal
													label="All phone numbers"
													data={formatPhoneData(otherPhones)}
													onClose={() => setPhoneModal(false)}
												/>
											)}
										</div>
									</li>
								)}
								{get(data, "personal_profile.emails", [])?.length > 0 && (
									<li>
										<Envelope />
										<span>{emailPrimary}</span>
										<div className={styles.containerModal}>
											{otherEmails.length > 0 && (
												<ArrowDownIcon onClick={() => setEmailsModal(true)} />
											)}{" "}
											{emailsModal && (
												<ContactInfoModal
													label="All emails"
													data={otherEmails}
													onClose={() => setEmailsModal(false)}
												/>
											)}
										</div>
									</li>
								)}
								{get(data, "personal_profile.external_links", [])?.length >
								0 ? (
									<li>
										<LinkedinIcon />
										<span>{primaryLink}</span>
										<div className={styles.containerModal}>
											{otherLink.length > 0 && (
												<ArrowDownIcon onClick={() => setLinksModal(true)} />
											)}
											{linksModal && (
												<ContactInfoModal
													label="All links"
													data={otherLink}
													onClose={() => setLinksModal(false)}
												/>
											)}
										</div>
									</li>
								) : null}
							</ul>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
// eslint-disable-next-line
export default props => (
	<ViewCandidateHelper
		render={({ ...otherProps }) => <Header {...props} {...otherProps} />}
	/>
);
