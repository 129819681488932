export const TOGGLE_PROPOSE = "TOGGLE_PROPOSE";
export const TOGGLE_GET_HELP = "TOGGLE_GET_HELP";
export const TOGGLE_HELP_MODAL = "TOGGLE_HELP_MODAL";
export const FILL_HELP_FORM = "FILL_HELP_FORM";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_SUBCATEGORIES = "SET_SUBCATEGORIES";
export const SET_FUNCTIONS = "SET_FUNCTIONS";
export const SET_SKILLS = "SET_SKILLS";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_FILTER_SETTINGS = "SET_FILTER_SETTINGS";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const AXIOS_PRE_REQUEST = "AXIOS_PRE_REQUEST";
export const AXIOS_REQUEST_SUCCESS = "AXIOS_REQUEST_SUCCESS";
export const AXIOS_REQUEST_FAIL = "AXIOS_REQUEST_FAIL";
export const ACTION_CREATOR = "ACTION_CREATOR";
