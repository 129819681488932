import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FEATURE_SETTINGS } from "config/api-endpoints";

const getFeatureSettings = () =>
	client(FEATURE_SETTINGS, {
		body: { feature_name: "feature_proactive_applications" }
	});

export const GET_FEATURE_SETTINGS_QUERY_KEY = "GET_FEATURE_SETTINGS_QUERY_KEY";

const useGetFeatureSettings = (options, queryOptions = {}) =>
	useQuery([GET_FEATURE_SETTINGS_QUERY_KEY, options], getFeatureSettings, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions,
		...options
	});

export default useGetFeatureSettings;
