import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { EDIT_MISSION_EXTENSION } from "config/api-endpoints";

const editMissionExtensionApi = data =>
	client(EDIT_MISSION_EXTENSION, { body: data });

function useEditMissionExtension(options) {
	return useMutation(editMissionExtensionApi, options);
}

export default useEditMissionExtension;
