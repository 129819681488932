import React from "react";
import { Text } from "rebass";

export const ListTabs = ({ isActiveTab, title, clickHandler }) => {
	return (
		<button
			onClick={clickHandler}
			style={{
				padding: "16px 12px",
				borderRadius: "5px 5px 0px 0px",
				backgroundColor: isActiveTab ? "#FFFFFF" : "transparent",
				border: "none",
				height: "54px",
				minWidth: "84px",
				fontFamily: "BasierCircle"
			}}
		>
			<Text
				sx={{
					fontSize: "12px",
					color: isActiveTab ? "#6E7191" : "#A0A3BD",
					fontWeight: "600",
					lineHeight: "22px",
					letterSpacing: "0.25px"
				}}
			>
				{title}
			</Text>
		</button>
	);
};
