import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPLOAD_CANDIDATE_FILES_ENDPOINT } from "config/api-endpoints";

function uploadCandidateFiles(body) {
	return client(UPLOAD_CANDIDATE_FILES_ENDPOINT, {
		body
	});
}

function useUploadCandidateFiles(queryOptions) {
	return useMutation(uploadCandidateFiles, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
}

export default useUploadCandidateFiles;
