import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";
import Portal from "./Portal";

const CustomOption = props => {
	const [showPortal, setShowPortal] = useState(false);
	const { option } = props;
	const focusedNode = document.getElementById(option.id);
	let nodeRect = { x: 0, y: 0 };
	if (focusedNode) {
		nodeRect = focusedNode.getBoundingClientRect();
	}

	const hasSubMenu = option.children && option.children.length > 0;

	return (
		<div onMouseLeave={() => setShowPortal(false)}>
			<div
				aria-hidden="true"
				role="button"
				className="Select-sub-menu"
				id={option.id}
				onMouseEnter={() => {
					setShowPortal(true);
				}}
				onClick={() => {
					if (!hasSubMenu) {
						props.onSelect(option);
					}
				}}
			>
				{option.name} {hasSubMenu && <i className="fa fa-angle-right"></i>}
			</div>
			{showPortal && hasSubMenu && (
				<Portal
					elementClassName="nested-menu"
					onMouseLeave={() => setShowPortal(false)}
					elementStyle={css`
						position: absolute;
						top: ${nodeRect.y + window.scrollY + 1}px;
						left: ${nodeRect.x + nodeRect.width - 1}px;
						z-index: 9999;
						border: 1px solid #ccc;
						box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
					`.join("")}
				>
					{option.children.map(item => (
						<div
							aria-hidden="true"
							role="button"
							key={item.id}
							className={`item`}
							onClick={() => props.onSelect(item)}
						>
							{item.name}
						</div>
					))}
				</Portal>
			)}
		</div>
	);
};

CustomOption.propTypes = {
	isFocused: PropTypes.bool,
	setValue: PropTypes.func,
	option: PropTypes.object
};

export default CustomOption;
