import create from "zustand";
const initialState = {
	homeType: "permanent"
};
const useHomeStore = create(set => ({
	...initialState,
	switchTab: value => set(() => ({ homeType: value })),
	resetHomeStore: () => set({ ...initialState })
}));

export default useHomeStore;
