import React from "react";
import { get } from "lodash";
import Checkbox from "common/Checkbox";
import Pagination from "common/Pagination";
import FreeChannelsList from "../FreeChannelsList/FreeChannelsList";
import s from "./publish-vacancy-free-channels-view.module.scss";
import { HOW_TO_PUBLISH_VACANCY } from "config/onboarding";

const PublishVacancyFreeChannelView = ({
	isAllFreeChannelsCheckboxChecked,
	handleAllFreeChannelsCheckboxChange,
	selectedFreeChannelsCounter,
	isFreeChannelsOnLoading,
	freeChannelsList,
	isContractSelected,
	freeChannelSelectionBucket,
	isMyCandidatesActive,
	isTalentPoolActive,
	freeChannelsOffset,
	handleFreeChannelPageClick
}) => {
	return (
		<div className={s.block}>
			<div className={s.selectAll}>
				<Checkbox
					id="free_channels"
					checked={isAllFreeChannelsCheckboxChecked}
					onChange={handleAllFreeChannelsCheckboxChange}
					label="All free channels&nbsp;"
					labelClassName={s.label}
				/>
				<span>
					{selectedFreeChannelsCounter !== 0 &&
						`(${selectedFreeChannelsCounter})`}
				</span>
			</div>
			<div
				className={s.gridLayout}
				data-onboarding-step={`${HOW_TO_PUBLISH_VACANCY}-3`}
			>
				<FreeChannelsList
					loading={isFreeChannelsOnLoading}
					channels={freeChannelsList?.data}
					isContractSelected={isContractSelected}
					freeChannelSelectionBucket={freeChannelSelectionBucket}
					showMyCandidatesChannel={isMyCandidatesActive}
					showTalentPoolChannel={isTalentPoolActive}
				/>
			</div>
			{!isFreeChannelsOnLoading &&
				get(freeChannelsList, "pagination.total") >
					get(freeChannelsList, "pagination.limit") && (
					<Pagination
						total={get(freeChannelsList, "pagination.total")}
						size={get(freeChannelsList, "pagination.limit")}
						offset={freeChannelsOffset}
						handlePageClick={handleFreeChannelPageClick}
					/>
				)}
		</div>
	);
};

export default PublishVacancyFreeChannelView;
