import React, { useMemo } from "react";
import styles from "./form-builder-add-element-dropdown.module.scss";
import {
	MenuTrigger,
	Popover,
	Menu,
	Button,
	MenuItem
} from "react-aria-components";
import { ReactComponent as AddIcon } from "static/icons/plus.svg";
import { ReactComponent as SectionIcon } from "static/icons/section.svg";
import { ReactComponent as HelpCircleIcon } from "static/icons/help-circle.svg";
import { ReactComponent as CheckDoneIcon } from "static/icons/check-done.svg";
import { ReactComponent as ThumbUpIcon } from "static/icons/thumb-up.svg";
import {
	ELEMENT_TYPES,
	ELEMENT_LABELS,
	ELEMENT_SCHEMAS
} from "../../utils/constants";
import find from "lodash/find";
import get from "lodash/get";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

const BUILDER_ITEMS = [
	{
		type: ELEMENT_TYPES.section,
		label: ELEMENT_LABELS.section,
		icon: <SectionIcon />,
		schema: ELEMENT_SCHEMAS.section
	},
	{
		type: ELEMENT_TYPES.question,
		label: ELEMENT_LABELS.question,
		icon: <HelpCircleIcon />,
		schema: ELEMENT_SCHEMAS.question
	},
	{
		type: ELEMENT_TYPES.score_card,
		label: ELEMENT_LABELS.score_card,
		icon: <CheckDoneIcon />,
		schema: ELEMENT_SCHEMAS.score_card
	},
	{
		type: ELEMENT_TYPES.yes_no,
		label: ELEMENT_LABELS.yes_no,
		icon: <ThumbUpIcon />,
		schema: ELEMENT_SCHEMAS.yes_no
	}
];

export default function FormBuilderAddElementDropdown({
	fieldArrayName,
	isSection = false
}) {
	const { setValue, getValues, clearErrors, setFocus } = useFormContext();

	const onAddItem = type => {
		const element = find(BUILDER_ITEMS, ["type", type]);
		const draft = structuredClone(getValues());
		const list = get(draft, fieldArrayName);
		list.push({ type, ...structuredClone(element.schema), _id: uuid() });
		setValue("elements", draft.elements, { shouldDirty: true });
		clearErrors(fieldArrayName);
		requestAnimationFrame(() => {
			setFocus(`${fieldArrayName}.${list.length - 1}.title`);
		});
	};

	const filteredItems = useMemo(() => {
		return BUILDER_ITEMS.filter(element =>
			isSection ? element.type !== ELEMENT_TYPES.section : true
		);
	}, [isSection]);

	return (
		<MenuTrigger>
			<Button type="button" className={styles.button}>
				<AddIcon />
				<span>Add element</span>
			</Button>
			<Popover className={styles.popover}>
				<div className={styles.popoverTitle}>Add elements</div>
				<div className={styles.divider} />
				<Menu onAction={onAddItem} className={styles.menu}>
					{filteredItems.map(item => (
						<MenuItem
							id={item.type}
							key={item.type}
							className={styles.menuItem}
						>
							{item.icon}
							<span>{item.label}</span>
						</MenuItem>
					))}
				</Menu>
			</Popover>
		</MenuTrigger>
	);
}
