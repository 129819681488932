import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _get from "lodash/get";
import { FIRST_LOGIN } from "config";

const HighlightableSpan = styled.span`
	background-color: ${props => (props.highlighted ? "#f7dad4" : "unset")};
	border-radius: 3px !important;
	padding: 5px;
`;

const highlightableTab = {
	functions: true,
	expectations: true
};

const FreelancerTabs = ({
	isActive,
	onChange,
	ownCV,
	modalName,
	activeTab,
	userType
}) => {
	const [highlightedTabs, setHighlightedTabs] = useState(undefined);

	const changeTab = n => {
		onChange(n);
	};

	if (!highlightedTabs && modalName === FIRST_LOGIN) {
		setHighlightedTabs(highlightableTab);
	}

	useEffect(() => {
		if (
			!_get(highlightedTabs, "functions") &&
			!_get(highlightedTabs, "expectations") &&
			!_get(highlightedTabs, "history")
		) {
			return;
		}
		const highlightables = Object.keys(highlightableTab);
		setHighlightedTabs({
			...highlightedTabs,
			[highlightables[activeTab - 1]]: false // -1 = - CV tab
		});
	}, [activeTab]);

	return (
		<>
			<li className={isActive(0)} onClick={() => onChange(0)}>
				Cv
			</li>
			<li className={isActive(1)} onClick={() => changeTab(1)}>
				<HighlightableSpan
					highlighted={_get(highlightedTabs, "functions", false)}
				>
					Functions, skills and languages
				</HighlightableSpan>
			</li>
			{ownCV && (
				<li className={isActive(2)} onClick={() => changeTab(2)}>
					<HighlightableSpan
						highlighted={_get(highlightedTabs, "expectations", false)}
					>
						Expectations
					</HighlightableSpan>
				</li>
			)}
			{userType === "client" ? (
				<li className={isActive(3)} onClick={() => changeTab(3)}>
					<HighlightableSpan
						highlighted={_get(highlightedTabs, "history", false)}
					>
						History
					</HighlightableSpan>
				</li>
			) : (
				<></>
			)}
		</>
	);
};

export default FreelancerTabs;
