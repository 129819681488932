import React from "react";
import icon from "../../../../static/icons/bell-notifs.png";

const EmptyNotifications = () => {
	return (
		<div className="empty-notifications">
			<img src={icon} width="48" alt="No new notifications" />
			<h3>No new notification</h3>
		</div>
	);
};

export default EmptyNotifications;
