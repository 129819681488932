import React from "react";
import styles from "./header.module.scss";
import { ConfirmationModal } from "common/modal";
import cx from "classnames";

const Header = ({ title, className }) => (
	<div className={cx(styles.header, className)}>
		<h2 className={styles.title}>{title}</h2>
	</div>
);

export default Header;

ConfirmationModal.Header = Header;
