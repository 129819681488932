import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import TableSupplier from "./components/Table";
import { Box, Flex } from "rebass";
import StatisticPerformance from "../StatisticPerformance";
import CsvButton from "../CsvButton";
import TopSection from "../TopSections";
import { useQuery, useMutation } from "react-query";
import { client } from "lib/api-client";
import {
	GET_LIST_COMPANY,
	JOBS_PERFORMANCE,
	EXPORT_DATA
} from "config/api-endpoints";
import InfoCompanySection from "../InfoCompanySection";
import { utcTimeStamp } from "config/helpers";
import useOnClickOutside from "hooks/useOnClickOutside";
import toaster from "common/Toaster";
import PusherManager from "config/pusher";
import {
	EVENT_JOBS_PERFORMANCE_EXCEL_GENERATED_TYPE,
	EVENT_REFRESH_DATA
} from "config";
import useGetCategories from "../../../consultancy/api/useGetCategories";
import useSuppliersDepartements from "modules/consultancy/api/useSuppliersDepartement.js";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

const getListSuppliers = () => {
	return client(GET_LIST_COMPANY, {
		body: {
			type: "consultancy"
		}
	});
};

const exportData = ({ vendor, startDate, endDate, group_id }) => {
	const notSupplier =
		vendor !== "all" &&
		vendor !== "tier 1" &&
		vendor !== "tier 2" &&
		vendor !== "tier 3";
	const isTiers =
		vendor === "tier 1" || vendor === "tier 2" || vendor === "tier 3";
	return client(EXPORT_DATA, {
		body: {
			tag: "jobs_performance",
			filter_data: {
				vendor_company_id: notSupplier ? vendor : null,
				start_date: startDate ? startDate.unix() : null,
				end_date: endDate ? endDate.unix() : null,
				tier_name: isTiers ? vendor : null,
				group_id
			}
		}
	});
};

const getJobsPerformance = (
	_,
	supplierId,
	startDate,
	endDate,
	category,
	group_id
) => {
	const id = typeof supplierId === "string" ? supplierId : supplierId.value;
	const notSupplier =
		id !== "all" && id !== "tier 1" && id !== "tier 2" && id !== "tier 3";
	const isTiers = id === "tier 1" || id === "tier 2" || id === "tier 3";
	return client(JOBS_PERFORMANCE, {
		body: {
			vendor_company_id: notSupplier ? id : null,
			start_date: startDate,
			end_date: endDate,
			tier_name: isTiers ? id : null,
			category_id: category,
			group_id
		}
	});
};

const pickerRef = React.createRef();

const SuppliersPerformance = () => {
	const { data } = useGetCategories();
	const { data: suppliersDepartement } = useSuppliersDepartements(
		"list_supplier_performance",
		{
			tag: "list_supplier_performance"
		}
	);
	const user = useSelector(state => state.auth.user);
	const [supplier, setSupplier] = useState({
		label: "All suppliers",
		value: "all"
	});
	const [category, setCategory] = useState();
	const [departement, setDepartement] = useState();
	const [displayPicker, setDisplayPicker] = useState(false);

	const [startDate, setStartDate] = useState(window.moment().startOf("month"));
	const [endDate, setEndDate] = useState(window.moment().endOf("month"));

	const start_date = utcTimeStamp({
		date: startDate
	});
	const end_date = utcTimeStamp({
		date: endDate,
		isStart: false
	});
	const { data: jobsPerformanceData, isFetching } = useQuery(
		[
			"jobs-performance",
			supplier?.value,
			start_date,
			end_date,
			category?.value,
			_get(departement, "value", "")
		],
		getJobsPerformance,
		{
			refetchOnWindowFocus: false
		}
	);

	const [fileExcel, setFileExcel] = useState(
		_get(jobsPerformanceData, "excel_link", "")
	);

	useEffect(() => {
		setFileExcel(_get(jobsPerformanceData, "excel_link"));
	}, [jobsPerformanceData]);

	const pusherEvent = [
		{
			name: [EVENT_REFRESH_DATA],
			cb: data => {
				if (
					data.message.context.event_type ===
					EVENT_JOBS_PERFORMANCE_EXCEL_GENERATED_TYPE
				) {
					setFileExcel(_get(data, "message.refresh_context.excel_link"));
					window.open(_get(data, "message.refresh_context.excel_link"));
				}
			}
		}
	];

	useEffect(() => {
		// setItems([{ name: "Temporary" }, { name: "Suppliers Performance" }]);
		if (user && "id" in user) {
			subscribeToPusher(user);
		}
	}, []);

	const subscribeToPusher = user => {
		PusherManager.subscribe(user.id, user.access_token, pusherEvent);
	};
	const { data: suppliersData } = useQuery(
		["list-suppliers"],
		getListSuppliers,
		{
			refetchOnWindowFocus: false
		}
	);

	const [
		onGenerate,
		{ isFetching: isGenerating, isLoading: isLoadingFile }
	] = useMutation(exportData, {
		onSuccess: () => {
			toaster.success("Your data has successfully been exported.");
		}
	});

	const supplierDetails = _get(
		jobsPerformanceData,
		"supplier_detail",
		undefined
	);
	const togglePicker = () => {
		setDisplayPicker(!displayPicker);
	};

	useOnClickOutside(pickerRef, () => {
		setDisplayPicker(false);
	});

	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([
			{ name: "Suppliers" },
			{ name: "Suppliers Management" },
			{ name: "Suppliers performance" }
		]);
	}, []);

	return (
		<BodyClassName className="gray-bg">
			<Box className="Vacancies-list-preview">
				<div className="full-container">
					<Flex justifyContent="space-between" alignItems="center">
						<TopSection
							supplier={supplier}
							setSupplier={setSupplier}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							listSupliers={_get(suppliersData, "consultancies", [])}
							pickerRef={pickerRef}
							displayPicker={displayPicker}
							setDisplayPicker={setDisplayPicker}
							togglePicker={togglePicker}
							category={category}
							setCategory={setCategory}
							categoriesData={data}
							departement={departement}
							setDepartement={setDepartement}
							suppliersDepartement={suppliersDepartement}
						/>

						<CsvButton
							onGenerate={() =>
								onGenerate({
									vendor: supplier?.value,
									startDate,
									endDate,
									group_id: departement?.value
								})
							}
							isGenerating={isGenerating}
							isLoadingFile={isLoadingFile}
							excelLink={fileExcel}
							isFetching={isFetching}
						/>
					</Flex>

					{supplierDetails && (
						<InfoCompanySection supplierDetails={supplierDetails} />
					)}

					<StatisticPerformance
						totalData={_get(jobsPerformanceData, "totals", [])}
					/>

					<TableSupplier
						supplier={supplier}
						setSupplierData={setSupplier}
						jobsPerformanceData={_get(jobsPerformanceData, "details", [])}
						isFetching={isFetching}
					/>
				</div>
			</Box>
		</BodyClassName>
	);
};
export default SuppliersPerformance;
