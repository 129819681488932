import React, { useState, useEffect } from "react";
import { useButton } from "react-aria";
import { DropDownInput } from "common/DropDownInput";
import styles from "./category-select-button.module.scss";
import cx from "classnames";
import { isOverflow } from "config/helpers";
import Tooltip from "common/Tippy";

const CategorySelectButton = ({ value, className, onInputClick, ...props }) => {
	let ref = props.buttonRef;
	let { buttonProps } = useButton(props, ref);
	const [useTooltip, setUseTooltip] = useState(false);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const wrapper = ref.current.children[0];
		setUseTooltip(isOverflow(wrapper));
	}, [value]);

	const onMouseOver = () => setVisible(true);

	const onMouseLeave = () => setVisible(false);

	return (
		<Tooltip
			visible={visible}
			addTooltip={useTooltip}
			theme="dark"
			content={value}
		>
			<div>
				<DropDownInput
					className={cx(styles.container, className)}
					value={value}
					{...buttonProps}
					ref={ref}
					onClick={onInputClick}
					onValueMouseOver={onMouseOver}
					onValueMouseLeave={onMouseLeave}
				/>
			</div>
		</Tooltip>
	);
};

export default CategorySelectButton;
