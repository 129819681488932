import { useQuery } from "react-query";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getCurrency = () => {
	return client(NEW_FILTER_SETTINGS, {
		body: {
			list: "requests",
			tag: "preferred_currency"
		}
	});
};

export const QUERY_KEY = "@request-form/preferred-currency";

export const useGetTimesheetCurrencySetting = () => {
	const res = useQuery(QUERY_KEY, getCurrency, {
		refetchOnWindowFocus: false
	});

	return res?.data?.preferred_currency;
};
