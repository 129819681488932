import React, { useEffect, useRef, useState } from "react";
import styles from "./messaging-tool-email-details-new-email.module.scss";
import useSendEmail from "common/MessagingToolNewEmail/api/useSendEmail";
import useNewEmailForm from "common/MessagingToolNewEmail/hooks/useNewEmailForm";
import toaster from "common/Toaster";
import debounce from "lodash/debounce";
import {
	formatData,
	isPlaceholdersValid
} from "common/MessagingToolNewEmail/utils/helpers";
import { FormProvider } from "react-hook-form";
import MessagingToolNewEmailFooter from "common/MessagingToolNewEmail/components/MessagingToolNewEmailFooter/MessagingToolNewEmailFooter";
import MesssagingToolNewEmailWarnings from "common/MessagingToolNewEmail/components/MesssagingToolNewEmailWarnings/MesssagingToolNewEmailWarnings";
import MessagingToolNewEmailBody from "common/MessagingToolNewEmail/components/MessagingToolNewEmailBody/MessagingToolNewEmailBody";
import MessagingToolNewEmailTemplates from "common/MessagingToolNewEmail/components/MessagingToolNewEmailTemplates/MessagingToolNewEmailTemplates";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import cx from "classnames";
import { getDefaultValues } from "modules/MessagingToolEmailDetails/utils/helpers";
import { queryCache } from "react-query";
import { EMAIL_DETAILS_KEY } from "modules/MessagingToolEmailDetails/api/useGetEmailDetails";
import { ACTIONS } from "common/MessagingToolEmailView/utils/constants";
import { useGetUser } from "hooks/useGetUser";
import { MIN_SCHEDULE_TIME } from "common/MessagingToolNewEmail/utils/constant";

const MessagingToolEmailDetailsNewEmail = ({
	emailId,
	email,
	onClose,
	draftEmail,
	replyOrForward,
	isEditorSmall,
	onFocusEditorBody,
	isDraft,
	hash
}) => {
	const user = useGetUser();
	const [showSubjectWarning, setShowSubjectWarning] = useState(false);
	const [secondPortal, setSecondPortal] = useState(null);
	const placeholdersRef = useRef();
	const [files, setFiles] = useState([]);
	const [activeTemplate, setActiveTemplate] = useState(null);

	const methods = useNewEmailForm(
		getDefaultValues(email, user.email, replyOrForward, isDraft, hash)
	);

	const { watch, formState } = methods;

	const [sendEmail, { isLoading }] = useSendEmail({
		onSuccess: () => {
			toaster.success("Email sent successfully");
			onClose();
			if (showSubjectWarning) setShowSubjectWarning(false);
			queryCache.invalidateQueries(EMAIL_DETAILS_KEY);
		}
	});

	useEffect(() => {
		methods.setValue("attachments", files);
	}, [files]);

	useEffect(() => {
		const debouncedCb = debounce(async formValues => {
			const newData = await formatData(formValues);
			if (!isPlaceholdersValid(newData.content_placeholders)) return;
			draftEmail({
				id: emailId,
				...newData,
				root_message_id:
					replyOrForward.action === ACTIONS.FORWARD
						? replyOrForward.emailId
						: undefined,
				reply_to_message_id: [ACTIONS.REPLY, ACTIONS.REPLY_TO_ALL].includes(
					replyOrForward.action
				)
					? replyOrForward.emailId
					: undefined,
				scheduled_at: ""
			});
		}, 1000);

		const subscription = watch(debouncedCb);
		return () => subscription.unsubscribe();
	}, [watch]);
	const onSchedule = async ({ scheduled_at }) => {
		const currentTime = window.moment.utc().unix();
		const timeDifferenceInMinutes = Math.abs(scheduled_at - currentTime) / 60;

		if (timeDifferenceInMinutes < MIN_SCHEDULE_TIME) {
			methods.setError(
				"scheduled_at",
				"The scheduled time must be at least 30 minutes from now."
			);
			return;
		}
		const newData = await formatData({
			...watch(),
			attachments: files
		});

		const valid = await methods.trigger();
		if (!valid) return;
		draftEmail(
			{
				id: emailId,
				...newData,
				root_message_id:
					replyOrForward.action === ACTIONS.FORWARD
						? replyOrForward.emailId
						: undefined,
				reply_to_message_id:
					replyOrForward.action === ACTIONS.REPLY
						? replyOrForward.emailId
						: undefined,
				scheduled_at
			},
			{
				onSuccess: () => {
					toaster.success("Email scheduled successfully");
					onClose();
				}
			}
		);
	};

	const onSend = async data => {
		if (!data.content) {
			setShowSubjectWarning(true);
			return;
		}
		sendEmail({ id: emailId });
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSend)} className={styles.form}>
				<div className={styles.newMailContainer}>
					<div className={styles.body}>
						<MessagingToolNewEmailBody
							files={files}
							setFiles={setFiles}
							setSecondPortal={setSecondPortal}
							placeholdersRef={placeholdersRef}
							isActive={true}
							secondPortal={secondPortal}
							activeTemplate={activeTemplate}
							isConversation
							isEditorSmall={isEditorSmall}
							onFocusEditorBody={onFocusEditorBody}
							path="email_view_details"
						/>
						<div className={styles.footer}>
							<MessagingToolNewEmailFooter
								emailId={emailId}
								isLoading={isLoading}
								onClose={onClose}
								onSchedule={onSchedule}
								setFiles={setFiles}
								isDirty={formState.isDirty}
								isDraft={isDraft}
								errors={methods.formState.errors}
							/>
						</div>
					</div>
					<MesssagingToolNewEmailWarnings
						showSubjectWarning={showSubjectWarning}
						setShowSubjectWarning={setShowSubjectWarning}
						isLoading={isLoading}
						sendEmail={() => sendEmail({ id: email.id })}
						emailId={emailId}
					/>
				</div>
			</form>
			<div
				ref={placeholdersRef}
				className={cx(styles.placeholdersAndTemplates, {
					[styles.hide]: secondPortal !== "placeholders"
				})}
			/>
			{secondPortal === "templates" && (
				<div className={styles.placeholdersAndTemplates}>
					<div className={styles.header}>
						<span className={styles.title}>{secondPortal}</span>
						<button
							className={styles.headerButton}
							type="button"
							onClick={() => setSecondPortal("")}
						>
							<CloseIcon className={styles.icon} />
						</button>
					</div>
					<MessagingToolNewEmailTemplates
						setActiveTemplate={setActiveTemplate}
					/>
				</div>
			)}
		</FormProvider>
	);
};

export default MessagingToolEmailDetailsNewEmail;
