import { useMutation } from "react-query";
import { DECLINE_INTERVIEW } from "config/api-endpoints";
import { client } from "lib/api-client";

const decline = body => {
	return client(DECLINE_INTERVIEW, {
		body
	});
};

export const useDeclineTempInterview = options => {
	const res = useMutation(decline, options);

	return res;
};
