import { IconButton } from "common/IconButton";
import React, { useState } from "react";
import {
	OverlayContainer,
	useButton,
	useOverlayPosition,
	useOverlayTrigger
} from "react-aria";
import { OverlayProvider } from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import { ReactComponent as GreenIcon } from "static/icons/green-eye.svg";
import { JobOfferFilesList } from "../JobOfferFilesList";
import { Popover } from "../Popover";
import styles from "./job-offers-files-menu.module.scss";

const JobOffersFilesMenu = ({ files }) => {
	let state = useOverlayTriggerState({});
	const [displayList, setDisplayList] = useState(false);

	let triggerRef = React.useRef();
	let overlayRef = React.useRef();

	let { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		state,
		triggerRef
	);

	let { overlayProps: positionProps } = useOverlayPosition({
		targetRef: triggerRef,
		overlayRef,
		placement: "bottom end",
		offset: 5,
		isOpen: displayList
	});

	let { buttonProps } = useButton(
		{
			onClick: () => onTriggerClick()
		},
		triggerRef
	);

	const onClose = () => {
		setDisplayList(false);
	};

	const onTriggerClick = () => {
		setDisplayList(state => !state);
	};

	return (
		<OverlayProvider>
			<div className={styles.triggerContainer}>
				<div>{files.length}</div>
				<IconButton>
					<GreenIcon {...buttonProps} {...triggerProps} ref={triggerRef} />
				</IconButton>
			</div>

			{displayList && (
				<OverlayContainer>
					<Popover
						{...overlayProps}
						{...positionProps}
						ref={overlayRef}
						isOpen={true}
						onClose={onClose}
						paperStyle={{
							width: "fit-content"
						}}
					>
						<JobOfferFilesList files={files} />
					</Popover>
				</OverlayContainer>
			)}
		</OverlayProvider>
	);
};

export default JobOffersFilesMenu;
