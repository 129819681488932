import React, { Component } from "react";
import MyBids from "./MyBids";
import OtherBids from "./OtherBids";
import { connect } from "react-redux";

class SideBar extends Component {
	render() {
		const { bids_list = [], isConsultancy, ownCV } = this.props;
		return (
			<div>
				<div id="cv-sidebar">
					{ownCV ? (
						<div>
							<h5>{isConsultancy ? "consultant's bids" : "my bids"}</h5>
							<MyBids bids={bids_list} isConsultancy={isConsultancy} />
						</div>
					) : (
						<div>
							<h5>Other bids</h5>
							<OtherBids bidId={this.props.bidId} list={bids_list} />
						</div>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		bids_list: state.cv.bids_list
	};
};

export default connect(mapStateToProps)(SideBar);
