import React from "react";
import { OpenerContainer } from "./styles";

function Opener({ children, setOpen, className }) {
	return (
		<OpenerContainer
			className={`opener ${className}`}
			onClick={() => setOpen(true)}
		>
			{children}
		</OpenerContainer>
	);
}

export default Opener;
