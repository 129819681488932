import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_USER_SETTING } from "config/api-endpoints";

function updateColumns(body) {
	return client(UPDATE_USER_SETTING, {
		body
	});
}
function useUpdateColumns(options) {
	return useMutation(updateColumns, options);
}

export default useUpdateColumns;
