import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { MANAGE_EMAIL_TEMPLATE } from "config/api-endpoints";

const saveTemplate = ({ body, isEditMode }) => {
	return client(MANAGE_EMAIL_TEMPLATE, {
		body: {
			...body,
			action: isEditMode ? "edit" : "add"
		}
	});
};

export default function useSaveEmailTemplate() {
	return useMutation(saveTemplate);
}
