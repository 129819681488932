import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import BodyClassName from "react-body-classname";
import _has from "lodash/has";
import _get from "lodash/get";
import _filter from "lodash/filter";
import { Decimal } from "decimal.js";
import {
	readTimeSheet,
	toggleDisputeModal,
	toggleDeleteModal,
	deleteItemHandler,
	toggleDisputedModal,
	switchView,
	editItem,
	acceptItem,
	refuseItem,
	initializeTimesheet,
	toggleEditTimesheet,
	toggleReSubmitTimesheet,
	toggleGeneralDispute,
	sendDisputedList,
	submitTimesheet
} from "../actions";
import {
	CLIENT,
	DISPUTED,
	ACCEPTED,
	DRAFT,
	WORKTIME,
	IN_MEDIATION,
	CONSULTANCY,
	APPROVED,
	PROJECT_MANAGER,
	EXPENSE,
	OVERTIME,
	HOURLY,
	DAILY
} from "config";
import CompanyDashboard from "common/dashboard/Dashboard";
import Intro from "common/dashboard/Intro";
import TopBar from "./TopBar";
import ViewSwitcher from "./ViewSwitcher";
import AddNewBox from "./AddNewBox";
import ReasonModal from "./ReasonModal";
import { ConfirmationTypes, ConfirmationModal } from "common/modal";
import ListRenderer from "./ListRenderer";
import DisputedTimesheetModal from "./DisputedTimesheetModal";
import { getHours } from "config/helpers";
import DuplicateItemModal from "./DuplicateItemModal";
import { getMyProfile } from "modules/user/actions/authActions";
import TimesheetHelpModal from "./TimesheetHelpModal";
import { FILES, ADMIN, FREELANCER } from "config";
import { Box } from "rebass";
import BackButton from "common/Buttons/BackButton";
import { AddTimesheetEntriesDrawer } from "./AddTimesheetEntriesDrawer";
export class TimesheetContainer extends PureComponent {
	state = {
		value: "all",
		displayDuplicateModal: false,
		itemToDuplicate: {},
		displayHelpModal: true,
		displayHelpModalAfterWatch: false,
		disputedWorkTime: [],
		disputedExpense: [],
		addedWorkTimes: [],
		addedOverTimes: [],
		addedExpenses: [],
		isIntro: true,
		showAddEntriesDrawer: false,
		attachments: []
	};

	componentWillMount() {
		if (window.location && window.location.hash)
			this._switchView(window.location.hash.slice(1));
	}

	componentDidMount() {
		this.loadTimeSheet();
	}

	componentDidUpdate(prevProps) {
		const {
			params: { timesheetId }
		} = this.props;
		const {
			params: { timesheetId: prevTimesheetId }
		} = prevProps;
		if (prevTimesheetId && prevTimesheetId !== timesheetId) {
			this.loadTimeSheet();
		}
	}

	componentWillUnmount() {
		this.props.initializeTimesheet();
	}

	loadTimeSheet = () => {
		const {
			params: { jobId, timesheetId },
			readTimeSheet
		} = this.props;
		return readTimeSheet(jobId, timesheetId);
	};

	openTimesheet = timesheet_id => {
		const { jobId } = this.props.params;
		browserHistory.push(`/timesheet/${jobId}/${timesheet_id}`);
	};

	renderDataKey = (view = WORKTIME) => {
		const keys = {
			[WORKTIME]: "worktimes",
			[EXPENSE]: "expenses",
			[OVERTIME]: "overtimes"
		};
		return keys[view];
	};

	getListByViewAndStatus = (view = WORKTIME, status) => {
		const { value } = this.state;
		const viewList = _get(
			this.props,
			`timesheet.data.month.${this.renderDataKey(view)}`,
			[]
		);

		let list = _filter(viewList, {
			status: status === APPROVED ? ACCEPTED : status
		});
		let list2 = _filter(list, item =>
			_has(item, "dispute")
				? _get(item, "dispute.tag", "") === value
				: item.tag === value
		);

		return {
			byView: viewList,
			byStatus: value !== "all" ? list2 : list
		};
	};

	getFilesList = () => {
		const {
			timesheet: {
				data: { files }
			}
		} = this.props;

		return Object.keys(files || {}).map(fileId => files[fileId]);
	};

	getAttachmentsList = () => {
		const {
			timesheet: {
				data: { attachments }
			}
		} = this.props;

		return Object.keys(attachments || {}).map(fileId => attachments[fileId]);
	};

	getLengthOfSubmittedFiles = () => {
		return this.getFilesList().length;
	};

	getLengthOfSubmittedAttachments = () => {
		return this.getAttachmentsList().length + this.state.attachments.length;
	};

	getLengthOfMandatoryRequiredFiles = () => {
		const {
			timesheet: {
				data: { required_files }
			}
		} = this.props;

		let counter = 0;
		const mandatory_required_files = Object.keys(required_files || {})
			.map(fileId => ({
				name: required_files[fileId].name,
				is_required: required_files[fileId].is_required
			}))
			.filter(item => item.is_required);

		const added_files = [
			...new Set(this.getFilesList().map(addedFile => addedFile.field_name))
		];
		for (let required_file of mandatory_required_files) {
			if (!added_files.includes(required_file.name)) counter++;
		}
		return counter;
	};

	requiredFilesIsEmpty = () => {
		const {
			timesheet: {
				data: { required_files }
			}
		} = this.props;

		if (!required_files) return true;
		return !Boolean(Object.keys(required_files).length);
	};

	getAttachmentsListLength = () => {
		const {
			timesheet: {
				data: { attachments = [] }
			}
		} = this.props;
		return Object.keys(attachments).map(attchId => attachments[attchId]).length;
	};

	canSubmit = () => {
		const worktimes = _get(this.props, `timesheet.data.month.worktimes`, []);
		const overtimes = _get(this.props, `timesheet.data.month.overtimes`, []);
		const expenses = _get(this.props, `timesheet.data.month.expenses`, []);
		const list = [...worktimes, ...expenses, ...overtimes];
		return _filter(list, { status: DISPUTED }).length === 0;
	};

	hasApprovedAll = () => {
		const worktimes = _get(this.props, `timesheet.updatedworktimesList`, []);
		const expenses = _get(this.props, `timesheet.updatedexpensesList`, []);

		return (
			_get(worktimes, "refused.length") === 0 &&
			_get(expenses, "refused.length") === 0
		);
	};

	_switchView = view => {
		window.history.replaceState({}, null, `#${view}`);
		this.props.switchView(view);
	};

	filterList = e => {
		this.setState({ value: e.value });
	};
	worktimeFormat = (totalHours, hoursPerDay) => {
		const days = Math.floor(totalHours / hoursPerDay);
		const hours = Math.floor(totalHours - days * hoursPerDay);
		const minutes = (totalHours - (days * hoursPerDay + hours)) * 60;
		if (!Boolean(days))
			return `${Boolean(hours) ? `${hours}h` : "00 h"} ${
				Boolean(minutes) ? `${Math.round(minutes)}m` : " 00"
			}`;

		return `${days}d ${Boolean(hours) ? `${hours}h` : ""} ${
			Boolean(minutes) ? `${Math.round(minutes)}m` : ""
		}`;
	};
	totalWorkTimeHours = listKey => {
		const list = JSON.parse(
			JSON.stringify(_get(this.props, `timesheet.data.month.${listKey}`, []))
		);
		const reportingSettings = _get(
			this.props,
			`timesheet.data.reporting_settings`,
			{}
		);
		const isRateDaily = _get(reportingSettings, "rate_type", HOURLY) === DAILY;

		const totalHours = list.reduce((acc, val) => {
			let hours = _get(val, "hours", 0);
			if (val.disputed) {
				hours = _get(val, "hours", 0) / 60;
			} else if (val.status === DISPUTED) {
				hours = _get(val, "dispute.hours", hours);
			}
			return new Decimal(acc.plus(hours));
		}, new Decimal(0));
		if (isRateDaily && listKey === "worktimes") {
			return this.worktimeFormat(
				totalHours.toNumber(),
				reportingSettings.hours
			);
		}
		return getHours(totalHours.toNumber());
	};

	toggleDuplicateModal = data => {
		this.setState({ itemToDuplicate: data });
		this.setState({ displayDuplicateModal: !this.state.displayDuplicateModal });
	};

	onAddEntries = () => {
		this.setState({
			showAddEntriesDrawer: true
		});
	};

	closeHelpTimesheetModal = () => {
		this.setState({ displayHelpModal: !this.state.displayHelpModal });
		this.setState({ displayHelpModalAfterWatch: false });
	};
	calculateProgress = (start_date, end_date) => {
		const now = window.moment();
		const start = window.moment.unix(start_date);
		const end = window.moment.unix(end_date);
		let total = Math.round(((now - start) / (end - start)) * 100);
		total = total < 0 ? 0 : total;
		return total > 100 ? 100 : total;
	};

	closeDrawer = () => {
		this.setState({
			showAddEntriesDrawer: false
		});
	};

	setTotal = () => {
		const calculateTotal = (items, key) => {
			return items?.reduce(
				(total, item) => total.plus(item[key] || 0),
				new Decimal(0)
			);
		};

		const draftExpenses = calculateTotal(this.state.addedExpenses, "price");
		const draftOvertime = calculateTotal(
			this.state.addedOverTimes,
			"freelancer_amount"
		);
		const draftWorktime = calculateTotal(
			this.state.addedWorkTimes,
			"freelancer_amount"
		);

		return {
			draftExpenses: draftExpenses.toNumber(),
			draftOvertime: draftOvertime.toNumber(),
			draftWorktime: draftWorktime.toNumber(),
			draftTotal: draftExpenses
				.plus(draftOvertime)
				.plus(draftWorktime)
				.toNumber()
		};
	};

	render() {
		const {
			timesheet: {
				data: {
					job,
					contract,
					start_date,
					end_date,
					total_amount = 0,
					total_expenses = 0,
					total_worktimes = 0,
					total_overtimes = 0,
					status,
					is_timesheet_processed_by_client,
					last_user_timesheet_action,
					month,
					_id,
					feature_timesheet_tags,
					timesheet_tags,
					timesheet_tags_name,
					is_edited,
					last_action
				},
				view,
				showReasonModal,
				currentDocument,
				currentDocumentEditMode,
				currentTime
			},
			user,
			disputeModal,
			params: { jobId, timesheetId },
			route: { title },
			toggleDisputedModal,
			toggleDeleteModal,
			deleteItemHandler,
			disputedModal,
			deleteModal,
			deleteItem,
			editItem,
			showEditTimesheet,
			acceptItem,
			refuseItem,
			timesheet,
			getMyProfile,
			disputed,
			showResubmitModal,
			toggleReSubmitTimesheet,
			toggleGeneralDispute,
			sendDisputedList,
			updatedworktimesList,
			updatedexpensesList,
			submitTimesheet
		} = this.props;
		const isClient = user.company_type === CLIENT;
		const isConsultancy = user.company_type === CONSULTANCY;
		const isFreelancer = user.company_type === FREELANCER;
		const isWatchedHelp = user.watched_clone_help;

		const isNotProjectMananger = user.role_name !== PROJECT_MANAGER;

		const isConsultantAdmin = isConsultancy && user.role_name === ADMIN;
		const showVideoHelpButton =
			!isClient && (isFreelancer || (isConsultancy && !isConsultantAdmin));
		const addedItems =
			view === WORKTIME
				? this.state.addedWorkTimes
				: view === OVERTIME
				? this.state.addedOverTimes
				: this.state.addedExpenses;

		const viewList = [
			...addedItems,
			..._get(this.props, `timesheet.data.month.${view}s`, [])
		];
		const emptyTimesheet = viewList.length < 1;

		const emptyTimesheetText =
			isClient &&
			emptyTimesheet &&
			this.getListByViewAndStatus(view, status).byView.length > 0;

		let subTitle = `In here, you’ll find the time and expense sheets that you need to fill in order to be able to invoice your work at the end of the month. Each time and expense sheet is available for edition & submission during the whole month.`;
		if (isClient) subTitle = "";

		const showAmountInDrawer =
			(isConsultancy && isNotProjectMananger) ||
			(!isConsultancy && isNotProjectMananger);

		const getLengthOfDisputedFor = view => {
			return _get(this.props, `timesheet.data.month.${view}s`, []).filter(
				item => {
					const lastUserAction =
						_get(item, "last_user_action.active_company", false) ===
						this.props.user.active_company;
					return item.status === DISPUTED && !lastUserAction;
				}
			).length;
		};

		const isDisputed = status === DISPUTED;
		const isInMediation = status === IN_MEDIATION;

		const tagOverlayText = `Each timesheet entry should be related to a ${(
			timesheet_tags_name || "tag"
		).toUpperCase()}.`;

		const totalWorkTimeHours = this.totalWorkTimeHours("worktimes");
		const totalOverTimeHours = this.totalWorkTimeHours("overtimes");

		const openModalHelp = () => {
			this.setState({ displayHelpModalAfterWatch: true });
		};
		const allItems = [
			..._get(this.props, "timesheet.data.month.worktimes", []),
			..._get(this.props, "timesheet.data.month.expenses", []),
			..._get(this.props, "timesheet.data.month.overtimes", [])
		];
		const reportingSettings = _get(
			this.props,
			`timesheet.data.reporting_settings`,
			[]
		);

		return (
			<BodyClassName className="company gray-bg">
				<div id="timesheets">
					{this.state.showAddEntriesDrawer && (
						<AddTimesheetEntriesDrawer
							disputedWorkTime={this.state.disputedWorkTime}
							month={month}
							onClose={this.closeDrawer}
							addedWorkTimes={this.state.addedWorkTimes}
							setAddedWorkTime={data => this.setState({ addedWorkTimes: data })}
							addedOverTimes={this.state.addedOverTimes}
							setAddedExpense={data => this.setState({ addedExpenses: data })}
							setAddedOverTime={data => this.setState({ addedOverTimes: data })}
							addedExpenses={this.state.addedExpenses}
							setAttachments={data => this.setState({ attachments: data })}
							attachments={this.state.attachments}
							type={user.company_type}
							showAmountInDrawer={showAmountInDrawer}
							timesheet={_get(this.props, `timesheet.data`, [])}
						/>
					)}
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					<div className="dashboard-box">
						<CompanyDashboard />
						<Intro
							title={
								<BackButton
									onClick={() => {
										browserHistory.push("/reporting/timesheets");
									}}
									style={{ display: "block" }}
								/>
							}
							subTitle={this.state.isIntro ? subTitle : ""}
							buttonWatch={showVideoHelpButton}
							openModalHelp={openModalHelp}
							isClosable
							onClose={() => this.setState({ isIntro: false })}
							onAddEntries={this.onAddEntries}
							showAddEntriesButton={
								_get(this.props, `timesheet.data.status`, []) !== APPROVED
							}
						/>
					</div>
					{job && (
						<div className="content">
							<div className="view-job-details">
								<TopBar
									timesheet={timesheet.data}
									_id={_id}
									job={job}
									contract={contract}
									totalExpense={
										parseFloat(total_expenses) + this.setTotal().draftExpenses
									}
									totalWorktime={
										parseFloat(total_worktimes) + this.setTotal().draftWorktime
									}
									totalOvertime={
										parseFloat(total_overtimes) + this.setTotal().draftOvertime
									}
									totalWorktimeHours={totalWorkTimeHours}
									totalOverTimeHours={totalOverTimeHours}
									total={parseFloat(total_amount) + this.setTotal().draftTotal}
									timesheetMonth={start_date}
									isClient={isClient}
									isConsultancy={isConsultancy}
									status={status}
									processedByClient={is_timesheet_processed_by_client === "1"}
									onNext={this.openTimesheet}
									onPrevious={this.openTimesheet}
									loadTimeSheet={this.loadTimeSheet}
									lastUserAction={last_user_timesheet_action}
									disableReSubmit={status === DISPUTED && !this.canSubmit()}
									timesheet_id={_id}
									allApproved={this.hasApprovedAll()}
									isConsultantAdmin={isConsultantAdmin}
									isNotProjectMananger={isNotProjectMananger}
									disableSubmit={this.getLengthOfMandatoryRequiredFiles() > 0}
									missionProgress={this.calculateProgress(start_date, end_date)}
									startDate={start_date}
									endDate={end_date}
									allItems={allItems}
									isOvertimeAllowed={_get(
										reportingSettings,
										"allow_overtime",
										false
									)}
									reportingSettings={reportingSettings}
								/>
							</div>

							<div className="timesheet-body">
								<div className="controllers">
									<ViewSwitcher
										timesheet={timesheet.data}
										switchView={this._switchView}
										disputedExpensesLength={getLengthOfDisputedFor("expense")}
										disputedWorktimeLength={getLengthOfDisputedFor("worktime")}
										disputedOvertimeLength={getLengthOfDisputedFor("overtimes")}
										requiredFilesLength={this.getLengthOfMandatoryRequiredFiles()}
										requiredFilesEmpty={this.requiredFilesIsEmpty()}
										submittedFilesLength={this.getLengthOfSubmittedFiles()}
										submittedAttachmentsLength={this.getLengthOfSubmittedAttachments()}
										status={status}
										view={view}
										filterList={this.filterList}
										value={this.state.value}
										isClient={isClient}
										isConsultantAdmin={isConsultantAdmin}
										tags={timesheet_tags || []}
										timesheetTagName={timesheet_tags_name || "TAG"}
										loadTimeSheet={this.loadTimeSheet}
										toggleEditTimesheet={toggleEditTimesheet}
										showEditTimesheet={showEditTimesheet}
										disputed={disputed}
										showResubmitModal={showResubmitModal}
										toggleReSubmitTimesheet={toggleReSubmitTimesheet}
										toggleGeneralDispute={toggleGeneralDispute}
										updatedexpensesList={updatedexpensesList}
										updatedworktimesList={updatedworktimesList}
										sendDisputedList={sendDisputedList}
										timesheetId={timesheetId}
										job={job}
										submitTimesheet={submitTimesheet}
										reportingSetting={reportingSettings}
									/>
								</div>
								<div>
									{view === FILES &&
									status === DRAFT &&
									(!this.requiredFilesIsEmpty() ||
										this.getAttachmentsListLength() > 0) &&
									!isConsultantAdmin ? (
										<Box my={20}>
											<AddNewBox
												view={view}
												switchView={this._switchView}
												status={status}
												month={month}
												jobId={jobId}
												timesheetId={_id}
												currentTime={currentTime}
												currentDocument={currentDocument}
												tagOverlayText={tagOverlayText}
												requiredFilesIsEmpty={this.requiredFilesIsEmpty()}
												attachmentsLength={this.getAttachmentsListLength()}
												onFilesActionFinish={this.loadTimeSheet}
												addedWorkTimes={this.state.addedWorkTimes}
												addedExpenses={this.state.addedExpenses}
												addedOverTimes={this.state.addedOverTimes}
												reportingSettings={reportingSettings}
												setAddedWorkTime={data =>
													this.setState({ addedWorkTimes: data })
												}
												setAddedExpense={data =>
													this.setState({ addedExpenses: data })
												}
												setAddedOverTime={data =>
													this.setState({ addedOverTimes: data })
												}
											/>{" "}
										</Box>
									) : (
										<>
											<ListRenderer
												attachments={this.state.attachments}
												list={viewList}
												view={view}
												month={month}
												jobId={jobId}
												status={status}
												timesheetId={timesheetId}
												toggleDeleteModal={toggleDeleteModal}
												showEditTimesheet={showEditTimesheet}
												toggleDisputedModal={toggleDisputedModal}
												isInMediation={isInMediation}
												isClient={isClient}
												isConsultancy={isConsultancy}
												type={user.company_type}
												companyId={user.active_company}
												featureTimesheetTags={feature_timesheet_tags}
												timesheetTagName={timesheet_tags_name || "TAG"}
												tagOptions={timesheet_tags}
												tagOverlayText={tagOverlayText}
												isNotProjectMananger={isNotProjectMananger}
												currentDocument={currentDocument}
												toggleDuplicateModal={this.toggleDuplicateModal}
												disputedWorkTime={this.state.disputedWorkTime}
												disputedExpense={this.state.disputedExpense}
												setDisputedWorkTime={data =>
													this.setState({ disputedWorkTime: data })
												}
												setDisputedExpense={data =>
													this.setState({ disputedExpense: data })
												}
												loadTimeSheet={this.loadTimeSheet}
												is_edited={is_edited}
												addedWorkTimes={this.state.addedWorkTimes}
												addedOverTimes={this.state.addedOverTimes}
												addedExpenses={this.state.addedExpenses}
												setAddedWorkTime={data =>
													this.setState({ addedWorkTimes: data })
												}
												setAddedExpense={data =>
													this.setState({ addedExpenses: data })
												}
												setAddedOverTime={data =>
													this.setState({ addedOverTimes: data })
												}
												currentDocumentEditMode={currentDocumentEditMode}
												clearResubmittedTimesheet={() =>
													this.setState({
														addedWorkTimes: [],
														addedExpenses: [],
														disputedWorkTime: [],
														disputedExpense: [],
														addedOverTimes: [],
														attachments: []
													})
												}
												last_action={last_action}
												isConsultantAdmin={isConsultantAdmin}
												allItems={timesheet.data.month}
												emptyTimesheet={emptyTimesheet}
												emptyTimesheetText={emptyTimesheetText}
												reportingSettings={reportingSettings}
												requiredFilesIsEmpty={this.requiredFilesIsEmpty}
											/>
										</>
									)}

									{(isClient || status === DISPUTED) && showReasonModal && (
										<div>
											<div className="backdrop" />
											<ReasonModal userType={user.company_type} />
										</div>
									)}

									<DisputedTimesheetModal
										view={view}
										editItem={editItem}
										disputedModal={disputedModal}
										toggleDisputedModal={toggleDisputedModal}
										isFreelancer={!isClient}
										isInMediation={isInMediation}
										isDisputed={isDisputed}
										type={user.company_type}
										acceptItem={acceptItem}
										refuseItem={refuseItem}
										data={{
											start_date: window.moment.unix(month.start_date).toDate(),
											end_date: window.moment.unix(month.end_date).toDate()
										}}
										reportingSettings={reportingSettings}
									/>

									<ConfirmationModal
										active={deleteModal}
										modalName="delete-timesheet"
										onClose={toggleDeleteModal}
										firstButton={{
											style: { width: "140px" },
											action: () => deleteItemHandler(deleteItem),
											label: "YES",
											type: "warning",
											focus: true
										}}
										title="Are you sure you want to delete this input ?"
										type={ConfirmationTypes.error}
									/>

									<ConfirmationModal
										active={disputeModal}
										modalName="dispute-timesheet"
										onClose={this.props.closeDisputeModal}
										firstButton={{
											action: this.props.closeDisputeModal,
											label: "Got it!",
											type: "warning"
										}}
										secondButton={{
											label: "Cancel"
										}}
										title="Please fix the errors first"
										content={
											isClient
												? "Please review your disputed elements with red tooltip"
												: "Please respond to all disputed entries before you submit"
										}
										type={ConfirmationTypes.error}
									/>
									{this.state.displayDuplicateModal && (
										<DuplicateItemModal
											month={month}
											view={view}
											status={status}
											addedWorkTimes={this.state.addedWorkTimes}
											addedExpenses={this.state.addedExpenses}
											addedOverTimes={this.state.addedOverTimes}
											setAddedWorkTime={data =>
												this.setState({ addedWorkTimes: data })
											}
											setAddedExpense={data =>
												this.setState({ addedExpenses: data })
											}
											setAddedOverTime={data =>
												this.setState({ addedOverTimes: data })
											}
											worktime={this.state.itemToDuplicate}
											closeModal={() => this.toggleDuplicateModal({})}
											reloadTimesheet={this.loadTimeSheet}
											timesheetId={timesheetId}
											type={user.company_type}
											disputedWorkTime={this.state.disputedWorkTime}
										/>
									)}
									{this.state.displayHelpModal &&
										!isClient &&
										!isWatchedHelp && (
											<TimesheetHelpModal
												onClose={() => this.closeHelpTimesheetModal()}
												getMyProfile={getMyProfile}
											/>
										)}
									{this.state.displayHelpModalAfterWatch &&
										showVideoHelpButton && (
											<TimesheetHelpModal
												onClose={() => this.closeHelpTimesheetModal()}
												getMyProfile={getMyProfile}
											/>
										)}
								</div>
							</div>
						</div>
					)}
				</div>
			</BodyClassName>
		);
	}
}

TimesheetContainer.propTypes = {
	timesheet: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	timesheet: { ...state.timesheet },
	user: state.auth.user,
	disputeModal: state.timesheet.disputeModal,
	deleteModal: state.timesheet.deleteModal,
	deleteItem: state.timesheet.deleteItem,
	disputedModal: state.timesheet.disputedModal,
	showEditTimesheet: state.timesheet.showEditTimesheet,
	requiredFiles: state.timesheet.data.requiredFiles,
	showResubmitModal: _get(state, "timesheet.showResubmitModal"),
	disputed: _get(state, "timesheet.disputed"),
	updatedexpensesList: _get(state, "timesheet.updatedexpensesList"),
	updatedworktimesList: _get(state, "timesheet.updatedworktimesList")
});

const mapDispatchToProps = dispatch => ({
	readTimeSheet: (job_id, timesheet_id) =>
		dispatch(readTimeSheet(job_id, timesheet_id)),
	closeDisputeModal: () => dispatch(toggleDisputeModal()),
	switchView: view => dispatch(switchView(view)),
	toggleDeleteModal: data => dispatch(toggleDeleteModal(data)),
	deleteItemHandler: data => dispatch(deleteItemHandler(data)),
	toggleDisputedModal: data => dispatch(toggleDisputedModal(data)),
	editItem: item => dispatch(editItem(item)),
	acceptItem: (id, list) => dispatch(acceptItem(id, list)),
	refuseItem: (id, list) => dispatch(refuseItem(id, list)),
	initializeTimesheet: () => dispatch(initializeTimesheet()),

	getMyProfile: () => dispatch(getMyProfile()),
	toggleReSubmitTimesheet: () => dispatch(toggleReSubmitTimesheet()),
	toggleGeneralDispute: () => dispatch(toggleGeneralDispute()),
	sendDisputedList: (list, jobId, isClient) =>
		dispatch(sendDisputedList(list, jobId, isClient)),
	submitTimesheet: (_id, user_id) => dispatch(submitTimesheet(_id, user_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetContainer);
