import { usePaginatedQuery } from "react-query";
import { client } from "lib/api-client";
import { ALL_PROCESSES } from "config/api-endpoints";

const getAllProcesses = (_key, body) =>
	client(ALL_PROCESSES, {
		body
	});

export const allProcessesKey = "@candidate/allProcesses";

const useAllProcesses = (options, queryOptions = {}) =>
	usePaginatedQuery(["getAllProcesses", options], getAllProcesses, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default useAllProcesses;
