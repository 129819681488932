import { utcTimeStamp } from "config/helpers";

export const getSearchFreelancersPayload = ({
	filters,
	logicalOperator,
	offset,
	sortBy,
	limit,
	query
}) => {
	return {
		data_source: "all",
		op: logicalOperator,
		fields: formatFilters(filters),
		keywords: query,
		offset,
		//We should change the line to */ sort_by:sortBy */ after 30 days from 12 oct 2023. We added this because some users have in the cache (react-query) availability sort and the api doesn't support sorty by availability
		sort_by: sortBy.field === "availability" ? {} : sortBy,
		limit,
		type: "freelancer"
	};
};

export const formatFilters = filters => {
	const definedFilters = filters.filter(({ value }) => {
		//Be careful about false value, filtering a filter with false value is a bug
		if (
			[undefined, null, "", 0].includes(value) ||
			(Array.isArray(value) && value.length === 0) ||
			(value !== null &&
				typeof value === "object" &&
				Object.keys(value).length === 0)
		) {
			return false;
		} else {
			return true;
		}
	});

	const filtersFormatted = definedFilters.map(filter => {
		const { name, operator } = filter;
		let filterFormatted;

		if (name === "skills") {
			filterFormatted = formatTypeSkillFunctionLanguageFilter(filter);
		} else if (name === "functions") {
			filterFormatted = formatTypeSkillFunctionLanguageFilter(filter, true);
		} else if (name === "languages") {
			filterFormatted = formatTypeSkillFunctionLanguageFilter(
				filter,
				false,
				true
			);
		} else if (name === "category" || name === "sub_category") {
			filterFormatted = formatTypeCategorySubCategory(filter);
		} else if (name === "created_on") {
			filterFormatted = formatFilterTypeDate(filter, "created_at");
		} else if (name === "postcode") {
			filterFormatted = {
				value: filter.value,
				field: "zip_code",
				op: operator
			};
		} else if (name === "minimum_hourly_rate") {
			filterFormatted = formatHourlyRate(filter);
		} else if (
			name === "website" ||
			name === "vat_number" ||
			name === "last_name" ||
			name === "first_name" ||
			name === "company" ||
			name === "phone" ||
			name === "email"
		) {
			let field = name;
			if (name === "company") {
				field = "company_name";
			} else if (name === "vat_number") {
				field = "vat";
			}
			filterFormatted = {
				value: filter.value,
				field,
				op: operator
			};
		} else if (name === "preferred_location" || name === "company_address") {
			filterFormatted = formatTypeLocation(filter);
		} else if (
			["interviewed", "bid_received", "been_on_mission"].includes(name)
		) {
			filterFormatted = formatBooleanFilter(filter);
		} else {
			if (name) {
				filterFormatted = formatFilter(filter);
			}
		}

		return filterFormatted;
	});

	return filtersFormatted;
};

const formatHourlyRate = ({ value, operator }) => {
	let newValue = value;

	if (operator === "is_empty") {
		newValue = value ? "yes" : "no";
	}

	return {
		value: newValue,
		field: "min_hourly_rate",
		op: operator
	};
};

const formatTypeSkillFunctionLanguageFilter = (
	filter,
	deleteRating,
	deleteCategory
) => {
	const { name, operator, value } = filter;

	const items = [];
	(value || []).forEach(parent => {
		parent.children.forEach(item => {
			const filterFormatted = {
				name: item.label,
				score: item.rating,
				category: parent?.label
			};

			if (deleteRating) {
				delete filterFormatted.score;
			}
			if (deleteCategory) {
				delete filterFormatted.category;
			}

			items.push(filterFormatted);
		});
	});

	return {
		field: name,
		op: operator,
		value: items
	};
};

const formatTypeCategorySubCategory = filter => {
	const value = reduceItems(filter.value || []).map(({ label }) => label);

	return {
		value,
		field: filter.name === "sub_category" ? "subcategory" : filter.name,
		op: filter.operator
	};
};

const formatFilterTypeDate = (filter, field) => {
	const { value, operator, name } = filter;

	if (!value) {
		return {
			field: field || name,
			value: null,
			op: operator
		};
	}
	let valueFormatted;

	if (!value.start) {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value),
			isStart: false
		});
		valueFormatted = [startDate, endDate];
	} else {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.start)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.end),
			isStart: false
		});

		valueFormatted = [startDate, endDate];
	}

	return {
		field: field || name,
		value: valueFormatted,
		op: operator
	};
};

const formatTypeLocation = (filter, forSearch = false) => {
	const { value, operator, name } = filter;
	const newValue = value || [];

	const valuesFormatted = newValue.map(({ place, radius }) => {
		const address = {};

		place.address_components.map(elem => {
			if (elem.types[0] === "country") {
				address[elem.types[0]] = elem.long_name;
				address.iso_country = elem.short_name;
				return;
			}
			return (address[elem.types[0]] = elem.long_name);
		});

		address.latitude = place.geometry.location.lat();
		address.longitude = place.geometry.location.lng();
		address.street =
			address.route ||
			address.neighborhood ||
			address.premise ||
			address.sublocality_level_1 ||
			address.sublocality_level_2 ||
			address.sublocality_level_3 ||
			address.sublocality_level_4 ||
			address.sublocality_level_5 ||
			address.subpremise ||
			address.sublocality ||
			address.jpns ||
			"";
		address.country = address.country || "";
		address.is_main = false;
		address.zip = address.postal_code || "";
		address.latitude = address.latitude || "";
		address.longitude = address.longitude || "";
		address.city =
			address.locality ||
			address.administrative_area_level_1 ||
			address.administrative_area_level_2 ||
			address.administrative_area_level_3 ||
			address.administrative_area_level_4 ||
			address.administrative_area_level_5 ||
			"";
		address.number = address.street_number || "";
		address.box = address.box || "";
		address.iso_country = address.iso_country || "";

		const values = place?.geometry?.viewport;
		let viewport = null;

		if (values) {
			viewport = {
				northeast: {
					lat: values.getNorthEast().lat(),
					lng: values.getNorthEast().lng()
				},
				southwest: {
					lat: values.getSouthWest().lat(),
					lng: values.getSouthWest().lng()
				}
			};
		}

		const typesSupportedAutoComplete = [
			"locality",
			"sublocality",
			"postal_code",
			"country",
			"administrative_area_level_1",
			"administrative_area_level_2",
			"administrative_area_level_3",
			"locality",
			"political"
		];

		let isSendViewport = false;

		typesSupportedAutoComplete.forEach(type => {
			if (place.types.includes(type)) isSendViewport = true;
		});

		const res = {
			zip: address.zip,
			country: address.country,
			number: address.number,
			iso_country: address.iso_country,
			city: address.city,
			street: address.street,
			latitude: address.latitude,
			is_main: address.is_main,
			box: address.box,
			longitude: address.longitude,
			distance_unit: "km",
			distance: parseInt(radius),
			viewport: isSendViewport ? viewport : [],
			place_id: forSearch ? place.place_id : undefined
		};

		return res;
	});

	return {
		value: valuesFormatted.length ? valuesFormatted[0] : null,
		field: name === "company_address" ? "company_location" : name,
		op: operator
	};
};

const formatFilter = filter => {
	const { name, value, operator } = filter;
	const ids = reduceItems(value || []).map(({ id }) => id);

	return {
		field: name === "time_commitment" ? "time_commitments" : name,
		value: ids,
		op: operator
	};
};

export const formatBooleanFilter = ({ name, operator, value }) => {
	return {
		field: name === "been_on_mission" ? "on_mission" : name,
		op: operator,
		value: value
	};
};

function reduceItems(items) {
	return items.reduce((acc, item) => [...acc, ...item.children], []);
}

const convertCalendarDateToMS = date => {
	const { year, month, day } = date;
	var dateObject = new Date(`${year}/${month}/${day}`);

	return dateObject;
};
