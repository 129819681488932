import React, { useEffect, useState } from "react";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { useQuery, useMutation } from "react-query";
import { connect } from "react-redux";
import MultiSelectTextInput from "./MultiSelectTextInput";
import { fetchCurrentCompany } from "modules/company/actions/identityActions";
import { Box, Text, Flex } from "rebass";
import { FEATURE_SETTINGS, MANAGE_MISSION_TAGS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { FEATURE_TIMESHEET_TAGS } from "config";
import AvailbleTags from "./AvailableTags";
import ArchivedTags from "./ArchivedTags";
import { ReactComponent as TagName } from "static/icons/tag-name-icon.svg";
import _get from "lodash/get";
import { v4 as uuid } from "uuid";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import { browserHistory } from "react-router";
import BackButton from "common/Buttons/BackButton";
import { searchParser } from "config/helpers";
import TimesheetReporting from "modules/TimesheetTags/components/timesheetReporter/TimesheetReporting";
import { useSelector } from "react-redux";
import useUpdateTimesheets from "modules/TimesheetTags/api/useUpdateTimesheets";
import ToolTip from "common/GlobalTooltip";
import useFetchCurrencies from "./api/useFetchCurrencies";
import useReportingSettings from "./api/useReportingSettings";

const getFeatureSettings = (_, company_id) =>
	client(FEATURE_SETTINGS, {
		body: {
			feature_name: FEATURE_TIMESHEET_TAGS,
			company_id
		}
	});

const manageMissionTags = data =>
	client(MANAGE_MISSION_TAGS, {
		body: {
			...data
		}
	});

export const getTimesheetTagsRoute = () => {
	return "/settings/timesheet-tags";
};
export const getTimesheetTagsPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["super_admin"]
			}
		]
	};
};
const DAILY = "daily";
const TimesheetTags = ({ fetchCurrentCompany }) => {
	const [tags, setTags] = useState([]);
	const [multiSelectTagsInput, setMultiSelectTagsInput] = useState([]);
	const [loadingCompanyData, setLoadingCompanyData] = useState(true);
	const [companyData, setCompanyData] = useState(null);
	const searchQueries = searchParser();
	const { features } = useSelector(state => state.auth.user);
	const isTimesheetTag = features.includes("feature_timesheet_tags");
	const {
		data: reportingSettings,
		refetch: refetchReportingSettings
	} = useReportingSettings();
	const { data: currencies } = useFetchCurrencies();

	const [saveSettings, { isLoading: isSaving }] = useUpdateTimesheets();
	const { refetch, isLoading } = useQuery(
		["feature_settings", _get(companyData, "_id", "")],
		getFeatureSettings,
		{
			onSuccess: data => {
				if (data && data.feature_settings) {
					setTags(data.feature_settings || []);
				}
			},
			onError: error => {
				if (error && error.status === 403) {
					browserHistory.push("/");
				}
			},
			enabled: isTimesheetTag,
			refetchOnWindowFocus: false
		}
	);

	const [mutate, { isLoading: mutationLoading }] = useMutation(
		manageMissionTags,
		{
			onSuccess: () => {
				toaster.success("Timesheet tags has successfully been added.");
				setMultiSelectTagsInput([]);
				if (isTimesheetTag) refetch();
			},
			onError: error => {
				toaster.danger(_get(error, "detail.name", _get(error, "title")));
			}
		}
	);

	const onAddTags = tags =>
		mutate({
			action: "add",
			company_id: _get(companyData, "_id", ""),
			tags
		});

	useEffect(() => {
		const fetchCompanyInfo = async () => {
			const data = await fetchCurrentCompany();
			setCompanyData(data);
			setLoadingCompanyData(false);
			if (isTimesheetTag) refetch();
		};
		fetchCompanyInfo();
	}, []);

	const handleTagAdd = e => {
		e.preventDefault();
		onAddTags(
			multiSelectTagsInput.map(({ value }) => ({ name: value, _id: uuid() }))
		);
	};

	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Settings" },
			{ name: "Timesheet Settings ", path: "/settings/timesheet-tags" }
		]);
	}, []);

	const savingTimesheetsSetting = fields => {
		saveSettings(
			{
				reporting_settings_id: _get(
					reportingSettings,
					"reporting_settings._id"
				),
				rate: _get(fields, "rate.value"),
				hours:
					_get(fields, "rate.value") === DAILY
						? _get(fields, "hours") &&
						  window.moment(_get(fields, "hours")).hours() * 60 +
								window.moment(_get(fields, "hours")).minutes()
						: undefined,
				allow_overtime:
					_get(fields, "rate.value") === DAILY
						? _get(fields, "submission")
						: undefined,
				timesheet_frequency: _get(fields, "timesheet_frequency.value"),
				currency_id: _get(fields, "currency.value")
			},
			{
				onSuccess: () => {
					refetchReportingSettings();
					toaster.success("Timesheet settings has successfully been added.");
				},
				onError: error => {
					toaster.danger(_get(error, "detail.name", _get(error, "title")));
				}
			}
		);
	};

	const allTags = [
		...multiSelectTagsInput,
		..._get(tags, "active", []),
		..._get(tags, "inactive", [])
	];

	return (
		<Box marginTop={10} p={20}>
			{_get(searchQueries, "history") && (
				<Box mb={18}>
					<BackButton label="Back"></BackButton>
				</Box>
			)}
			{reportingSettings && (
				<TimesheetReporting
					saveHandler={savingTimesheetsSetting}
					isSaving={isSaving}
					data={_get(reportingSettings, "reporting_settings")}
					isLoadingContainer={isLoading}
					currencies={currencies}
				/>
			)}
			{isTimesheetTag && (
				<>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: 600,
							fontSize: "16px",
							lineHeight: "22px",
							textTransform: "uppercase",
							color: "#6e7191",
							marginBottom: "10px"
						}}
					>
						Timesheet tags
						<ToolTip
							placement={"right"}
							maxWidth={"330px"}
							align={{
								offset: [15, 0]
							}}
							overlay={
								<div style={{ textAlign: "center" }}>
									This feature allows you to split the timesheet reporting per
									projects, office location, etc.
									<br /> Create, edit or delete your tags using the tool below.{" "}
									<br />
									{
										"Allocate those tags to each mission on its dedicated page (Main Menu > Temporary > Missions)"
									}
								</div>
							}
						/>
					</Text>
					<Flex mb="12px" width="100%" alignItems="flex-end">
						<TagName />
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "14px",
								lineHeight: "22px",
								color: "#6E7191",
								marginLeft: "8px"
							}}
						>
							TAG Name
						</Text>
					</Flex>
					<Flex alignItems="flex-start">
						<Box width="95%" mr={"1rem"}>
							<MultiSelectTextInput
								setTags={setMultiSelectTagsInput}
								tags={multiSelectTagsInput}
								label={"Tag name"}
								allTags={allTags}
							/>
						</Box>
						<button
							className={"btn btn-primary"}
							disabled={
								mutationLoading || _get(multiSelectTagsInput, "length", 0) === 0
							}
							onClick={handleTagAdd}
						>
							<i className="icon-add BoxText-icon" /> Add
						</button>
					</Flex>
				</>
			)}

			<LoaderFull loading={mutationLoading} />

			{loadingCompanyData || isLoading
				? null
				: isTimesheetTag && (
						<React.Fragment>
							<Box my="50px">
								<AvailbleTags
									fetchFeatureSetting={refetch}
									tags={_get(tags, "active", [])}
									companyID={_get(companyData, "_id", "")}
									allTags={allTags}
								/>
							</Box>
							<Box>
								<ArchivedTags
									fetchFeatureSetting={refetch}
									tags={_get(tags, "inactive", [])}
									companyID={_get(companyData, "_id", "")}
									allTags={allTags}
								/>
							</Box>
						</React.Fragment>
				  )}
		</Box>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		fetchCurrentCompany: () => dispatch(fetchCurrentCompany(false))
	};
};
export default connect(null, mapDispatchToProps)(TimesheetTags);
