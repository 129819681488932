import React, { Component } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import LoginForm from "./Form";
import AccountBlocked from "modules/company/components/AnswerInvitation/AccountBlocked";
import { fillHelpForm } from "modules/app/actions/ListsActions";
import {
	userBlockedSelector,
	emailSelector,
	isAccountSelector
} from "../../../selectors/selectors";
import { Helmet } from "react-helmet";
import AuthenticationLayout from "../Layout";
import { browserHistory } from "react-router";

export const getSignInRoute = job_id => `/sign-in${job_id ? `/${job_id}` : ""}`;

class SignIn extends Component {
	componentDidMount() {
		const { jobId } = this.props.params;
		if (jobId) localStorage.setItem("offline-job", jobId);
	}

	handleGetHelp = e => {
		e.preventDefault();
		const fields = {
			email: this.props.email
		};
		this.props.dispatch(fillHelpForm(fields));
		browserHistory.push("/ask-question");
	};

	render() {
		const { userBlocked, is_account, location } = this.props;
		return (
			<AuthenticationLayout>
				<Helmet>
					<title>Sign in</title>
				</Helmet>

				{!userBlocked && (
					<LoginForm redirectTo={_get(location, "state.redirectTo")} />
				)}

				{userBlocked && (
					<AccountBlocked
						onGetHelp={this.handleGetHelp}
						is_account={is_account}
					/>
				)}
			</AuthenticationLayout>
		);
	}
}

const mapStateToProps = state => {
	return {
		userBlocked: userBlockedSelector(state),
		email: emailSelector(state),
		is_account: isAccountSelector(state)
	};
};

export default connect(mapStateToProps)(SignIn);
