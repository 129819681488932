import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { CREATE_CAMPAIGN } from "config/api-endpoints";

const createCampaign = data =>
	client(CREATE_CAMPAIGN, {
		body: { ...data }
	});

const useCreateCampaign = (queryOptions = {}) =>
	useMutation(createCampaign, queryOptions);
export default useCreateCampaign;
