import React from "react";
import { browserHistory } from "react-router";

import Modal from "common/modal";
import { DescriptionText } from "./styled";
import { ModalContent } from "modules/candidate/components/search/Styled";

const IncompleteProfileModal = ({
	vacancyTitle,
	onClose,
	companyName,
	isWelcome
}) => {
	const redirectToProfile = tab => {
		browserHistory.push(`/my-cv#${tab}`);
	};
	return (
		<Modal fixed={true} onClose={onClose} modalName={"signin-apply"}>
			<ModalContent>
				<div className="title primary">
					{isWelcome ? "Welcome to wiggli!" : "Welcome back!"}{" "}
					{isWelcome && (
						<div className="title-track">{`${companyName}'s Applicant Tracking System`}</div>
					)}
				</div>

				<DescriptionText>
					Your application for{" "}
					<span className="infos">{`"${vacancyTitle}"`}</span> has successfully
					been processed !
					<div className="bold">
						Increase your chances to be considered for the role :
					</div>
				</DescriptionText>
				<div className="actions">
					<button
						className="btn btn-primary"
						onClick={() => redirectToProfile(2)}
					>
						Edit functions, skills &amp; languages
					</button>
					<button
						className="btn btn-primary-outline"
						onClick={() => redirectToProfile(1)}
					>
						Edit preferences
					</button>
				</div>
			</ModalContent>
		</Modal>
	);
};

export default IncompleteProfileModal;
