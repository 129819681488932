export const sectionsDisplayManager = (sections, clickedSection) => {
	let newSections = {};
	Object.keys(sections).forEach(section => {
		if (section !== clickedSection) {
			newSections[section] = false;
		} else {
			const old_value = sections[section];
			newSections[section] = !old_value;
		}
	});
	newSections["activeSection"] =
		sections["activeSection"] === clickedSection ? "" : clickedSection;
	return newSections;
};
