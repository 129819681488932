import React from "react";
import classnames from "./filter-modal.module.scss";
import { ReactComponent as EmptyStateIcon } from "static/icons/empty-state-list-filter-icon.svg";

const EmptyState = () => (
	<div className={classnames.emptyStateContainer}>
		<EmptyStateIcon />
		<span>No filters applied, click “Add filter” to get started.</span>
	</div>
);
export default EmptyState;
