import React, { useState } from "react";
import { Flex, Text, Box } from "rebass";
import BoxContainer from "../boxContainer";

import { ReactComponent as MainTagFunction } from "static/icons/meta-tag-main-category.svg";
import { ReactComponent as TagFunction } from "static/icons/meta-tag-category.svg";
import { ReactComponent as FunctionArrow } from "static/icons/function-arrow.svg";
import { VACANCY_LEVEL } from "config";
import Tooltip from "rc-tooltip";
import { get } from "lodash";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Functions = ({
	functions = [],
	isDrag,
	setIsOpenModalTab,
	setActiveTab,
	isFetching
}) => {
	const [tooltipIdVisible, setTooltipIdVisible] = useState("");
	let renderMainFunctionWithTooltip = (fun, index) => (
		<Flex
			onMouseEnter={() => setTooltipIdVisible(fun._id)}
			onMouseLeave={() => setTooltipIdVisible("")}
			mb={functions.length - 1 === index ? 0 : 3}
			alignItems="center"
		>
			<MainTagFunction />
			<Text
				sx={{
					fontSize: "16px",
					color: "#ff6649",
					fontWeight: 600,
					marginLeft: "8px",
					maxWidth: "40%",
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "nowrap"
				}}
			>
				{get(fun, "name")}
			</Text>
			<Tooltip
				placement="bottom"
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				overlayClassName="function-tooltip"
				visible={fun._id === tooltipIdVisible}
				overlay={
					<Text
						sx={{
							fontSize: "14px",
							color: "#dfe3e8",
							fontWeight: "normal",
							display: "flex",
							alignItems: "center"
						}}
					>
						{get(fun, "sector.parent_sector.name", "")} <FunctionArrow />
						{get(fun, "sector.name", "")} <FunctionArrow />
						{get(fun, "name", "")} <FunctionArrow />
						{fun.seniority &&
							VACANCY_LEVEL.find(level => level.value === fun.seniority).label}
					</Text>
				}
			>
				<Box
					mx={1}
					width={4}
					height={4}
					sx={{ backgroundColor: "#2b3946", borderRadius: "50%" }}
				></Box>
			</Tooltip>
			<Text
				sx={{
					fontSize: "16px",
					color: "#212b36",
					fontWeight: "normal"
				}}
			>
				{fun.seniority
					? VACANCY_LEVEL.find(level => level.value === fun.seniority).label
					: "N/A"}
			</Text>
		</Flex>
	);
	let renderOtherFunctionsWithTooltip = (fun, index) => (
		<Flex
			onMouseEnter={() => setTooltipIdVisible(fun._id)}
			onMouseLeave={() => setTooltipIdVisible("")}
			mb={functions.length - 1 === index ? 0 : 3}
			alignItems="center"
		>
			<TagFunction />
			<Text
				sx={{
					fontSize: "16px",
					color: "#212b36",
					fontWeight: 600,
					marginLeft: "8px",
					maxWidth: "40%",
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "nowrap"
				}}
			>
				{get(fun, "name")}
			</Text>
			<Tooltip
				placement="bottom"
				mouseEnterDelay={0}
				mouseLeaveDelay={0}
				overlayClassName="function-tooltip"
				visible={fun._id === tooltipIdVisible}
				overlay={
					<Text
						sx={{
							fontSize: "14px",
							color: "#dfe3e8",
							fontWeight: "normal"
						}}
					>
						{get(fun, "sector.parent_sector.name", "")} {"> "}
						{get(fun, "sector.name", "")} {"> "}
						{get(fun, "name", "")} {"> "}
						{fun.seniority &&
							VACANCY_LEVEL.find(level => level.value === fun.seniority).label}
					</Text>
				}
			>
				<Box
					mx={1}
					width={4}
					height={4}
					sx={{ backgroundColor: "#2b3946", borderRadius: "50%" }}
				></Box>
			</Tooltip>
			<Text
				sx={{
					fontSize: "16px",
					color: "#212b36",
					fontWeight: "normal"
				}}
			>
				{fun.seniority
					? VACANCY_LEVEL.find(level => level.value === fun.seniority).label
					: "N/A"}
			</Text>
		</Flex>
	);
	const renderMainFunction = () => (
		<Flex mb={3} alignItems="center">
			<MainTagFunction />
			<Text
				sx={{
					fontSize: "16px",
					color: "#ff6649",
					fontWeight: 600,
					marginLeft: "8px"
				}}
			>
				N/A
			</Text>
			<Box
				mx={1}
				width={4}
				height={4}
				sx={{ backgroundColor: "#2b3946", borderRadius: "50%" }}
			></Box>
			<Text
				sx={{
					fontSize: "16px",
					color: "#212b36",
					fontWeight: "normal"
				}}
			>
				N/A
			</Text>
		</Flex>
	);

	const renderOtherFunctions = () => (
		<Flex alignItems="center">
			<TagFunction />
			<Text
				sx={{
					fontSize: "16px",
					color: "#212b36",
					fontWeight: 600,
					marginLeft: "8px"
				}}
			>
				N/A
			</Text>
			<Box
				mx={1}
				width={4}
				height={4}
				sx={{ backgroundColor: "#2b3946", borderRadius: "50%" }}
			></Box>
			<Text
				sx={{
					fontSize: "16px",
					color: "#212b36",
					fontWeight: "normal"
				}}
			>
				N/A
			</Text>
		</Flex>
	);
	return (
		<BoxContainer
			isDrag={isDrag}
			setIsOpenModalTab={setIsOpenModalTab}
			setActiveTab={setActiveTab}
			title="Functions"
			isFetching={isFetching}
		>
			{isFetching ? (
				<React.Fragment>
					{" "}
					<Flex mb={3} alignItems="center">
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={20} height={14} />
						</SkeletonTheme>
						<Box ml={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={270} height={14} />
							</SkeletonTheme>
						</Box>
					</Flex>
					<Flex alignItems="center">
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={20} height={14} />
						</SkeletonTheme>
						<Box ml={2}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={270} height={14} />
							</SkeletonTheme>
						</Box>
					</Flex>
				</React.Fragment>
			) : (
				<React.Fragment>
					{" "}
					{functions.length > 0 ? (
						functions.map((fun, index) =>
							fun.is_main
								? renderMainFunctionWithTooltip(fun, index)
								: renderOtherFunctionsWithTooltip(fun, index)
						)
					) : (
						<React.Fragment>
							{renderMainFunction({})}
							{renderOtherFunctions({})}
						</React.Fragment>
					)}
				</React.Fragment>
			)}
		</BoxContainer>
	);
};

export default Functions;
