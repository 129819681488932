import React, { useState, useRef, useEffect, useMemo } from "react";
import styles from "./permanent-view-evaluations-tab.module.scss";
import ProfileViewEvaluationSummary from "./components/ProfileViewEvaluationSummary/ProfileViewEvaluationSummary";
import EvaluationCard from "common/EvaluationCard/EvaluationCard";
import EvaluationCardHeader from "common/EvaluationCard/EvaluationCardHeader/EvaluationCardHeader";
import EvaluationCardProfileOverview from "common/EvaluationCard/EvaluationCardProfileOverview/EvaluationCardProfileOverview";
import EvaluationCardActions from "common/EvaluationCard/EvaluationCardActions/EvaluationCardActions";
import QuickEvaluationCardBody from "common/EvaluationCard/QuickEvaluationCardBody/QuickEvaluationCardBody";
import EvaluationCardCandidateLabel from "common/EvaluationCard/EvaluationCardCandidateLabel/EvaluationCardCandidateLabel";
import EvaluationCardEditButton from "common/EvaluationCard/EvaluationCardEditButton/EvaluationCardEditButton";
import EvaluationCardDeleteButton from "common/EvaluationCard/EvaluationCardDeleteButton/EvaluationCardDeleteButton";
import EvaluationCardScoreStatus from "common/EvaluationCard/EvaluationCardScoreStatus/EvaluationCardScoreStatus";
import { ReactComponent as DividerIcon } from "static/icons/divider.svg";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import Pagination from "common/NewPagination";
import { SearchInput } from "common/SearchInput";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { ReactComponent as FilterIcon } from "static/icons/Iconfilter.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-2.svg";
import { useInitializeFilterDrawer } from "./hooks/useInitializeFilterDrawer";
import { EVALUATIONS_FILTER_ID } from "./components/utils";
import cx from "classnames";
import loadable from "loadable-components";
import { isFilterEmpty } from "common/FilterDrawer/utils";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import useGetPermanentEvaluationsList from "./hooks/useGetPermanentEvaluationsList";
import useGetProfileSummaryEvaluationsList from "./hooks/useGetProfileSummaryEvaluationsList";
import { browserHistory } from "react-router";

import _get from "lodash/get";
import usePermanentToEvaluate from "./components/ProfileViewEvaluationTemplateFormDrawer/hooks/usePermanentToEvaluate";
import useOpenEditQuickEvaluation from "./components/ProfileEditQuickEvaluationDrawer/hooks/useOpenEditQuickEvaluation";
import useEditQuickEvaluationData from "./components/ProfileEditQuickEvaluationDrawer/hooks/useEditQuickEvaluationData";
import useStartEvaluationDrawerData from "./components/ProfileViewStartEvaluationDrawer/hooks/useStartEvaluationDrawerData";
import useOpenStartEvaluationDrawer from "./components/ProfileViewStartEvaluationDrawer/hooks/useOpenStartEvaluationDrawer";
import useAssignEvaluationTaskStore from "./components/ProfileViewAssignEvaluationTaskDrawer/hooks/useAssignEvaluationTaskStore";
import useOpenTemplateEvaluationDrawer from "./components/ProfileViewEvaluationTemplateFormDrawer/hooks/useOpenTemplateEvaluationDrawer";
import useFormStoreEvaluationQuick from "common/StartEvaluationDrawer/components/EvaluationQuickAddForm/hooks/useFormStoreEvaluationQuick";
import useFormStoreCandidateEvaluation from "common/StartEvaluationDrawer/components/EvaluateCandidateForm/hooks/useFormStoreCandidateEvaluation";
import useFormStoreAssignEvaluation from "common/StartEvaluationDrawer/components/AssignEvaluationToMemberForm/hooks/useFormStoreAssignEvaluation";
import { PermanentViewEvaluationDetailsDrawer } from "modules/candidate/components/PermanentViewEvaluationDetailsDrawer";
import EvaluationDetailsCardBody from "common/EvaluationCard/EvaluationDetailsCardBody/EvaluationDetailsCardBody";
import { PermanentEvaluationsListLoading } from "./components/PermanentEvaluationsListLoading";
import { PermanentEvaluationsListEmptyState } from "./components/PermanentEvaluationsListEmptyState";
import { PermanentViewEvaluationSummaryLoading } from "./components/PermanentViewEvaluationSummaryLoading";
import { PermanentViewEvaluationSummaryEmptyState } from "./components/PermanentViewEvaluationSummaryEmptyState";
import { useGetEvaluationFilterSettings } from "common/StartEvaluationDrawer/hooks/useGetEvaluationFilterSettings";
import { historyPush } from "config/helpers";
import Tooltip from "common/Tippy";
import { isEmpty } from "lodash";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const ProfileEditQuickEvaluationDrawer = loadable(() =>
	import("./components/ProfileEditQuickEvaluationDrawer")
);

const ProfileViewEvaluationTemplateFormDrawer = loadable(() =>
	import("./components/ProfileViewEvaluationTemplateFormDrawer")
);

const ProfileViewAssignEvaluationTaskDrawer = loadable(() =>
	import("./components/ProfileViewAssignEvaluationTaskDrawer")
);

const ProfileViewStartEvaluationDrawer = loadable(() =>
	import("./components/ProfileViewStartEvaluationDrawer")
);

const DeleteEvaluationModal = loadable(() =>
	import("./components/DeleteEvaluationModal/DeleteEvaluationModal")
);
const FilterDrawer = loadable(() => import("common/FilterDrawer/FilterDrawer"));

const ClearModal = loadable(() =>
	import("modules/freelancers/components/ClearModal/ClearModal")
);

const PermanentViewEvaluationsTab = ({ id, candidateData, isTabActif }) => {
	const user = useSelector(onlineUserSelector);
	const [showFilterDrawer, setShowFilterDrawer] = useState(false);
	const [showClearModal, setShowClearModal] = useState(false);
	const { workflowId } = useOnboardingStore();

	const { getState, setOffset, setQuery } = useFilters();
	const {
		filters,
		offset,
		data: evaluationsList,
		isLoading,
		isFetching
	} = getState(EVALUATIONS_FILTER_ID);
	const total = evaluationsList?.pagination.total;
	const size = evaluationsList?.pagination.size;
	const filtersCount = filters.filter(({ value }) => !isFilterEmpty(value))
		.length;

	const [currentEvaluation, setCurrentEvaluation] = useState();
	const [showDeleteEvaluationpModal, setShowDeleteEvaluationpModal] = useState(
		false
	);

	const {
		data: evaluationsSummaryList,
		isLoading: isSummaryLoading
	} = useGetProfileSummaryEvaluationsList(
		{
			profile_id: id
		},
		{ enabled: isTabActif && !workflowId }
	);
	const onPageClick = async page => {
		await setOffset(page * evaluationsList?.pagination.size);
	};

	const ref = useRef();
	const onSearch = e => {
		setQuery(e.target.value);
	};

	useGetPermanentEvaluationsList(id, isTabActif && !workflowId);
	useInitializeFilterDrawer(id);

	const {
		isOpen: isOpenStartEvaluationDrawer,
		setIsOpen: setIsOpenStartEvaluationDrawer
	} = useOpenStartEvaluationDrawer();
	const { setData: setEvaluationDrawerData } = useStartEvaluationDrawerData();

	const onStartEvaluation = () => {
		setEvaluationDrawerData({
			avatar: _get(candidateData, "personal_profile.avatar"),
			candidate_name:
				_get(candidateData, "personal_profile.first_name") +
				" " +
				_get(candidateData, "personal_profile.last_name"),
			candidate_id: _get(candidateData, "personal_profile._id"),
			function: { main_function: _get(candidateData, "main_function.name") }
		});
		setIsOpenStartEvaluationDrawer(true);
	};

	const {
		resetFormValues: resetQuickEvaluationForm
	} = useFormStoreEvaluationQuick();
	const {
		resetFormValues: resetCandidateEvaluationForm
	} = useFormStoreCandidateEvaluation();
	const {
		resetFormValues: resetAssignEvaluationForm
	} = useFormStoreAssignEvaluation();
	useEffect(() => {
		if (!isOpenStartEvaluationDrawer) {
			resetQuickEvaluationForm();
			resetAssignEvaluationForm();
			resetCandidateEvaluationForm();
		}
	}, [isOpenStartEvaluationDrawer]);

	const { data: vacanciesPayload } = useGetEvaluationFilterSettings(
		{
			search: "",
			tag: "job",
			list: "evaluations",
			profile_id: _get(candidateData, "personal_profile._id")
		},
		{ enabled: !workflowId }
	);
	const canStartEvaluation = useMemo(() => {
		return vacanciesPayload?.count > 0;
	}, [vacanciesPayload]);

	const {
		isOpen: isOpenEditQuickEvaluation,
		setIsOpen: setIsOpenQuickEvaluation
	} = useOpenEditQuickEvaluation();
	const { setData: setQuickEvaluationData } = useEditQuickEvaluationData();

	const {
		isOpen: isOpenTemplateEvaluationDrawer,
		setIsOpen: setIsOpenEvaluationTemplate
	} = useOpenTemplateEvaluationDrawer();
	const { setData: setEvaluationTemplateData } = usePermanentToEvaluate();

	const onEditEvaluation = evaluation => {
		const template = {
			...evaluation,
			elements: evaluation?.evaluation_form || []
		};

		const candidateFunction = {
			main_function: evaluation?.profile?.main_function?.function
		};
		const drawerData = {
			template,
			is_edit_mode: true,
			function: candidateFunction,
			vacancy_id: evaluation?.job?._id,
			avatar: evaluation?.profile?.avatar,
			vacancy_name: evaluation?.job?.title,
			candidate_id: evaluation?.profile?._id,
			candidate_name: evaluation?.profile?.candidate_name
		};

		if (!!template.elements.length) {
			setEvaluationTemplateData(drawerData);
			setIsOpenEvaluationTemplate(true);
		} else {
			setQuickEvaluationData(drawerData);
			setIsOpenQuickEvaluation(true);
		}
	};

	const {
		isOpen: isOpenAssignEvaluationDrawer
	} = useAssignEvaluationTaskStore();

	const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
	const hasEvaluationQueryParams = new URLSearchParams(
		window.location.search
	).has("view_evaluation_id");

	useEffect(() => setShowDetailsDrawer(hasEvaluationQueryParams), [
		hasEvaluationQueryParams
	]);

	const redirectToEvaluationDetails = evaluationId => {
		const urlParams = new URLSearchParams(window.location.search);
		urlParams.set("view_evaluation_id", evaluationId);
		browserHistory.push(`${window.location.pathname}?${urlParams.toString()}`);
	};
	const redirectToEvaluationList = () => {
		const urlParams = new URLSearchParams(window.location.search);
		urlParams.delete("view_evaluation_id");
		browserHistory.push(`${window.location.pathname}?${urlParams.toString()}`);
	};

	const getFormFirstQuestion = form => {
		const question = form[0];

		if (question.type !== "section") {
			return [question];
		} else {
			return [
				{
					...question,
					items: question.items.slice(0, 1)
				}
			];
		}
	};

	return (
		<div className={styles.evaluationWrapper}>
			<div className={styles.leftSection}>
				<div className={styles.filtersContainer}>
					<div className={styles.firstContainer}>
						<SearchInput
							className={styles.search}
							placeholder={"Search in vacancy title & created by"}
							onChange={onSearch}
							ref={ref}
						/>
						<div className={styles.filter_btns_container}>
							<HeaderButton
								icon={<FilterIcon />}
								text="Filter"
								count={filtersCount}
								countClassName={cx({
									[styles.countClassName]: filtersCount
								})}
								className="btnAddFilter"
								onClick={() => setShowFilterDrawer(true)}
							/>
						</div>
						<div
							className={cx(styles.filter_btns_container, {
								[styles.filter_btns_container_hidden]: filtersCount === 0
							})}
						>
							<HeaderButton
								icon={<TrashIcon />}
								text="Clear"
								className="btnAddFilter"
								onClick={() => setShowClearModal(true)}
							/>
						</div>
					</div>
					<div className={styles.secondContainer}>
						<div className={styles.filterBtnsContainer}>
							<Tooltip
								theme="dark"
								addTooltip={!canStartEvaluation}
								content="You cannot add evaluation because there is currently no candidate assigned to this vacancy. Please ensure that the vacancy contains at least one candidate."
							>
								<button
									className={styles.addBtn}
									onClick={onStartEvaluation}
									disabled={!canStartEvaluation}
								>
									<p className={styles.addText}>Add Evaluation</p>
								</button>
							</Tooltip>
						</div>
					</div>
				</div>
				<hr />
				<p className={styles.results_count}>
					<div>
						<>
							<span className={styles.greenText}>{total ? total : 0}</span>
							&nbsp;Evaluations in total&nbsp;
						</>
					</div>
				</p>
				{isLoading || isFetching ? (
					<PermanentEvaluationsListLoading />
				) : evaluationsList?.evaluations?.length === 0 ? (
					<PermanentEvaluationsListEmptyState />
				) : (
					<div className={styles.list}>
						{evaluationsList?.evaluations.map(evaluation => (
							<EvaluationCard key={evaluation._id}>
								<EvaluationCardHeader>
									<EvaluationCardProfileOverview
										name={evaluation.created_by.client_name}
										date={evaluation.created_by.date}
										edited={evaluation.edited}
										avatar={evaluation.created_by.avatar}
									>
										<EvaluationCardCandidateLabel
											containerClassName={styles.evaluationCandidate}
											title={evaluation.job.title}
											onClick={() =>
												historyPush(`/vacancy/${evaluation.job._id}`)
											}
										/>
									</EvaluationCardProfileOverview>
									<EvaluationCardActions>
										{user.id === evaluation.created_by._id && (
											<>
												<EvaluationCardEditButton
													onClick={() => onEditEvaluation(evaluation)}
												/>

												<EvaluationCardDeleteButton
													onClick={() => {
														setCurrentEvaluation(evaluation);
														setShowDeleteEvaluationpModal(true);
													}}
												/>

												<DividerIcon />
											</>
										)}
										<EvaluationCardScoreStatus
											score={evaluation.evaluation_score}
										/>
									</EvaluationCardActions>
								</EvaluationCardHeader>
								<hr className={styles.hr} />
								{evaluation.evaluation_form[0] ? (
									<>
										<EvaluationDetailsCardBody
											form={getFormFirstQuestion(evaluation.evaluation_form)}
											contentClassName={styles.evaluationOverview}
										/>
										<hr className={styles.hr} />
										<div className={styles.btnContainer}>
											<button
												className={styles.btn}
												onClick={() =>
													redirectToEvaluationDetails(evaluation._id)
												}
											>
												<span className={styles.btnTitle}>Show details</span>
											</button>
										</div>
									</>
								) : (
									<QuickEvaluationCardBody note={evaluation.evaluation_note} />
								)}
							</EvaluationCard>
						))}
					</div>
				)}
				{total > size && (
					<Pagination
						total={total}
						size={size}
						offset={offset}
						handlePageClick={onPageClick}
					/>
				)}
			</div>
			<div className={styles.rightSection}>
				{isSummaryLoading ? (
					<PermanentViewEvaluationSummaryLoading />
				) : isEmpty(evaluationsSummaryList?.data) ? (
					<PermanentViewEvaluationSummaryEmptyState />
				) : (
					<ProfileViewEvaluationSummary
						percents={evaluationsSummaryList.percents}
						data={evaluationsSummaryList.data}
					/>
				)}
			</div>
			{showFilterDrawer && (
				<FilterDrawer
					onClose={() => setShowFilterDrawer(false)}
					displayDrawer={showFilterDrawer}
					hideTabSaved
					onlyList
					displayAdvancedSearchHead={false}
				/>
			)}
			{showClearModal && (
				<ClearModal onClose={() => setShowClearModal(false)} />
			)}
			{showDeleteEvaluationpModal && (
				<DeleteEvaluationModal
					name={currentEvaluation.created_by.client_name}
					payload={{ id: currentEvaluation?._id }}
					onClose={() => setShowDeleteEvaluationpModal(false)}
				/>
			)}
			{isOpenStartEvaluationDrawer && <ProfileViewStartEvaluationDrawer />}
			{isOpenTemplateEvaluationDrawer && (
				<ProfileViewEvaluationTemplateFormDrawer />
			)}
			{isOpenAssignEvaluationDrawer && (
				<ProfileViewAssignEvaluationTaskDrawer />
			)}
			{isOpenEditQuickEvaluation && <ProfileEditQuickEvaluationDrawer />}
			{showDetailsDrawer && (
				<PermanentViewEvaluationDetailsDrawer
					onClose={() => redirectToEvaluationList()}
					onDeleteSuccess={() => redirectToEvaluationList()}
				/>
			)}
		</div>
	);
};
export default PermanentViewEvaluationsTab;
