import React from "react";
import _get from "lodash/get";
import { Flex, Box, Text } from "rebass";
import { Title, Content, CancelledTitle, CancelledHelp } from "./Styled";
import StatusLabel from "common/StatusLabel";
import {
	ONE_HUNDRED_AND_TWENTY_FIVE,
	CLIENT,
	ACCEPTED,
	SENT,
	DECLINED,
	CANCELLED,
	REFUSED,
	SIGNED,
	CONTRACT,
	ON_MISSION,
	CURRENCY_SUFFIX
} from "config";
import Description from "common/Description";
import { formatDate } from "common/Functions";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import GlobalTooltip from "common/GlobalTooltip";
import AttachedFiles from "common/AttachedFiles";
import { ReactComponent as ContratRefusedIcon } from "static/icons/icon-contart-refused.svg";

const ContractDetails = ({
	userType,
	contract = {},
	userTarget = {},
	getContractStatusTooltip,
	visible_status,
	stepperStatus,
	user,
	reportingSetting
}) => {
	const isClient = userType === CLIENT;

	const getBox = ({ proposition, title, desc }) => {
		if (!proposition) return;

		const { created_at, rate, cost, start_date, end_date } = proposition;

		return (
			<>
				<Flex mb={40}>
					<Box sx={{ minWidth: "max-content" }}>
						<Flex flexDirection="column">
							<Text
								mb={28}
								sx={{
									fontFamily: "BasierCircle",
									fontSize: "15px",
									fontWeight: "600",
									letterSpacing: "0.08px",
									color: "rgb(39, 50, 56)"
								}}
							>
								{title}
							</Text>
							<Flex alignItems="center">
								<Flex
									flexDirection="column"
									alignItems="center"
									sx={{ borderRight: "1px solid #f1f2f5" }}
									pr={30}
									mr={30}
								>
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "10px",
											fontWeight: "600",
											letterSpacing: "0.86px",
											color: "#ff6849"
										}}
									>
										{formatDate(created_at, true, "MMMM")}
									</Text>
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "30px",
											letterSpacing: "0.15px",
											color: "rgb(39, 50, 56)",
											borderBottom: "solid 2px rgb(112, 112, 112)"
										}}
									>
										{formatDate(created_at, true, "D")}
									</Text>
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "12px",
											letterSpacing: "0.1px",
											color: "rgb(15, 28, 70)"
										}}
									>
										{formatDate(created_at, true, "YYYY")}
									</Text>
								</Flex>
								<Box>
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "12px",
											fontWeight: "600",
											letterSpacing: "1.08px",
											color: "rgb(160, 165, 185)"
										}}
									>
										MISSION START &amp; END DATE
									</Text>
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "14px",
											color: "rgb(15, 28, 70)"
										}}
									>
										{`${formatDate(start_date, true)} - ${formatDate(
											end_date,
											true
										)}`}
									</Text>
									<Flex mt={20} justifyContent="space-between">
										<Box mr={26}>
											<Text
												sx={{
													fontFamily: "BasierCircle",
													fontSize: "12px",
													fontWeight: "600",
													letterSpacing: "1.08px",
													color: "rgb(160, 165, 185)",
													textTransform: "uppercase"
												}}
											>
												{`${_get(reportingSetting, "rate_type")} RATE`}
											</Text>
											<Text
												sx={{
													fontFamily: "BasierCircle",
													fontSize: "14px",
													color: "rgb(15, 28, 70)"
												}}
											>
												<CurrencyFormatterRender
													isCent
													value={rate}
													suffix={
														CURRENCY_SUFFIX[_get(reportingSetting, "rate_type")]
													}
													currency={_get(reportingSetting, "currency.code")}
												/>
											</Text>
										</Box>
										{isClient && (
											<Box>
												<Text
													sx={{
														fontFamily: "BasierCircle",
														fontSize: "12px",
														fontWeight: "600",
														letterSpacing: "1.08px",
														color: "rgb(160, 165, 185)",
														textTransform: "uppercase"
													}}
												>
													{`${_get(reportingSetting, "rate_type")} cost`}
												</Text>
												<Text
													sx={{
														fontFamily: "BasierCircle",
														fontSize: "14px",
														color: "rgb(15, 28, 70)"
													}}
												>
													<CurrencyFormatterRender
														isCent
														value={cost}
														suffix={
															CURRENCY_SUFFIX[
																_get(reportingSetting, "rate_type")
															]
														}
														currency={_get(reportingSetting, "currency.code")}
													/>
												</Text>
											</Box>
										)}
									</Flex>
								</Box>
							</Flex>
						</Flex>
					</Box>

					<Box ml={20} mt={52}>
						{!isContractSigned && desc && (
							<div>
								<Title>Additional comments</Title>
								<Content>
									<Description
										parseMarkdown={false}
										description={description_other_terms || description || ""}
										max={ONE_HUNDRED_AND_TWENTY_FIVE}
									/>
								</Content>
							</div>
						)}
					</Box>
				</Flex>
			</>
		);
	};

	const {
		new_proposition = {},
		old_proposition = {},
		status,
		signed_status,
		description,
		description_other_terms,
		last_user_contract_action
	} = contract;

	const isContractSent = status === SENT;

	const isContractDeclined = status === DECLINED;

	const isContractAccepted = status === ACCEPTED;

	const isContractSigned = signed_status === SIGNED;

	const isContract = stepperStatus === CONTRACT || stepperStatus === ON_MISSION;

	const clientFullName = `${_get(contract, "manager_ts.first_name")} ${_get(
		contract,
		"manager_ts.last_name"
	)}`;

	const freelancerFullName = `${userTarget.first_name} ${userTarget.last_name}`;

	const lastSender = `${_get(
		last_user_contract_action,
		"first_name",
		""
	)} ${_get(last_user_contract_action, "last_name", "")}`;

	const islastUserSenderClient =
		_get(last_user_contract_action, "type") === CLIENT;

	const clientName = `${_get(contract, "client.first_name")} ${_get(
		contract,
		"client.last_name"
	)}`;

	const files = _get(contract, "files", []);

	const labelTwo =
		_get(contract, "client._id") === user.id ? "Your" : `${clientName}'s`;

	const defaultTitle = isContractAccepted
		? // when contracts are signed, we look for the other user, because the user sender is now the one who accepted not the one who sent the proposition
		  islastUserSenderClient
			? isClient
				? `${freelancerFullName}'s Proposition`
				: "Your proposition"
			: isClient
			? `${labelTwo} proposition`
			: `${clientFullName}'s Proposition`
		: // else we show lastSender's name and if the sender is the connected user, we show 'Your'
		islastUserSenderClient && isClient
		? `${labelTwo} proposition`
		: !islastUserSenderClient && !isClient
		? `Your proposition`
		: `${lastSender}'s Proposition`;

	return (
		<div className="section" style={{ position: "relative" }}>
			<Flex
				justifyContent="space-between"
				mb={48}
				alignItems="center"
				sx={{
					backgroundColor: () =>
						contract.status === CANCELLED || contract.status === REFUSED
							? "#F9E6F1"
							: "white",
					borderRadius: "4px",
					padding: "10px 12px"
				}}
			>
				{contract.status === CANCELLED || contract.status === REFUSED ? (
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "15px",
							fontWeight: "600",
							letterSpacing: "0.08px",
							color: "rgb(39, 50, 56)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<ContratRefusedIcon />
						<Box sx={{ marginLeft: "15px" }}>
							<CancelledTitle>Contract {contract.status}</CancelledTitle>
							<CancelledHelp>
								{isClient ? "Your " : "The "}contract proposition with{" "}
								<b>
									{isClient
										? `${userTarget.first_name} ${userTarget.last_name}`
										: clientFullName}
								</b>{" "}
								has been {contract.status}.
							</CancelledHelp>
						</Box>
					</Text>
				) : (
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "15px",
							fontWeight: "600",
							letterSpacing: "0.08px",
							color: "rgb(39, 50, 56)"
						}}
					>
						Contract
					</Text>
				)}
				<Box sx={{ opacity: 0.7 }}>
					{isContract && (
						<GlobalTooltip
							placement="top"
							eventTrigger="hover"
							noClassName={true}
							overlay={
								<span>
									{getContractStatusTooltip(
										isContractSigned ? SIGNED : status,
										islastUserSenderClient
									)}
								</span>
							}
						>
							<StatusLabel
								right={0}
								status={status === DECLINED ? SENT : status}
								label={visible_status}
								px={15}
							/>
						</GlobalTooltip>
					)}
				</Box>
			</Flex>

			<Flex justifyContent="space-between">
				{(isContractAccepted || isContractSigned) && (
					<Box width={1 / 3} mr={20}>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								fontWeight: "500",
								letterSpacing: "0.08px",
								color: "#ff6849",
								marginBottom: "8px"
							}}
						>
							Congratulations!
						</Text>
						<Text
							mb={"16px"}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "14px",
								color: "rgb(15, 28, 70)"
							}}
						>
							All contracts are signed.
						</Text>
						<Flex
							alignItems="center"
							sx={{
								padding: "10px",
								borderRadius: "4px",
								backgroundColor: "rgba(232, 244, 252, 0.74)",
								fontFamily: "BasierCircle",
								fontSize: "12px",
								letterSpacing: "0.1px",
								color: "rgb(61, 75, 96)",
								lineHeight: "20px"
							}}
						>
							<Text
								mr={"14px"}
								sx={{ color: "#2294e3" }}
								className="icon-info"
							></Text>
							{isContractSigned
								? "All contracts are signed and this is now an actual mission."
								: "You reached an agreement on the terms."}
						</Flex>
					</Box>
				)}
				<Box width={isContractAccepted || isContractSigned ? 2 / 3 : "100%"}>
					{getBox({
						proposition: new_proposition,
						orange: true,
						title: defaultTitle,
						desc: true
					})}
					{isContractDeclined &&
						getBox({
							proposition: old_proposition,
							orange: isContractSent,
							title: isContractSent ? defaultTitle : "Previous proposition"
						})}
				</Box>
			</Flex>
			{files.length > 0 ? <AttachedFiles files={files} /> : <></>}
		</div>
	);
};

export default ContractDetails;
