import cx from "classnames";
import React from "react";
import styles from "./advanced-tab-card-header.module.scss";
import { ReactComponent as ArrowRight } from "static/icons/arrow-right-new.svg";

const AdvancedTabCardHeader = ({ title, iconDirection, count, onClick }) => {
	return (
		<div className={styles.container} onClick={onClick}>
			<ArrowRight
				className={cx(styles.arrow, {
					[styles.iconBottom]: iconDirection === "bottom"
				})}
			/>
			<div className={styles.text}>
				{title}{" "}
				{title === "Advanced" && (
					<span className={styles.count}>({count})</span>
				)}
			</div>
		</div>
	);
};

export default AdvancedTabCardHeader;
