import { VALIDATE_PUBLISH_VACANCY } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

const getPublishVacancyValidation = body =>
	client(VALIDATE_PUBLISH_VACANCY, { body });

const usePublishVacancyValidation = () => {
	return useMutation(getPublishVacancyValidation);
};

export default usePublishVacancyValidation;
