import React from "react";
import { ReactComponent as UnblockIcon } from "static/icons/Unblock.svg";
import { ReactComponent as BlockIcon } from "static/icons/Block.svg";
import HeaderButton from "modules/freelancers/components/HeaderButton/HeaderButton";

const BlockUserButton = ({ onClick, isLoading, isUnblock }) => {
	return (
		<HeaderButton
			icon={isUnblock ? <UnblockIcon /> : <BlockIcon />}
			text={isUnblock ? "Unblock" : "Block"}
			className="btnDelete"
			variant="big"
			onClick={onClick}
			isLoading={isLoading}
		/>
	);
};

BlockUserButton.displayName = "BlockUserButton";

export default BlockUserButton;
