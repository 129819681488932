import { useQuery } from "react-query";
import { PAGE_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getPagesSettings = () =>
	client(PAGE_SETTINGS, {
		body: {}
	});

export const FETCH_PAGE_SETTINGS_KEY = "FETCH_PAGE_SETTINGS_KEY";

export const useGetPagesSettings = () => {
	return useQuery(FETCH_PAGE_SETTINGS_KEY, getPagesSettings, {
		refetchOnWindowFocus: true,
		retry: 0
	});
};
