import React, { useState } from "react";
import styles from "./styles.module.scss";
import Modal from "common/modal";
import Avatar from "common/Avatar/index";
import { ReactComponent as SwitchIcon } from "static/icons/switch-icon.svg";
import FormField from "common/FormField";
import { Button } from "common/styled/buttons";
import { useDispatch, useSelector } from "react-redux";
import { setModalErrorMsg } from "modules/company/actions/usersListActions";
import { ReactComponent as InfoIcon } from "static/icons/info2-icon.svg";
import { ReactComponent as YellowBadgeIcon } from "static/icons/yellow-badge-icon.svg";

const TransfertOwnership = ({
	user,
	connectedUser,
	onClose,
	onTranfertOwnership
}) => {
	const [password, setPassword] = useState("");
	const isLoading = useSelector(state => state.usersList.modalLoading);
	const errorMsg = useSelector(state => state.usersList.modalErrorMsg);
	const dispatch = useDispatch();

	return (
		<Modal
			size={"37rem"}
			padding={"1.5rem"}
			borderRadius={"8px"}
			backgroundColor={"rgba(0,0,0,0.25)"}
			boxShadow={
				"0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
			}
			border={"1px solid #e5e5e5"}
			loading={isLoading}
			onClose={onClose}
		>
			<div className={styles.title}>
				<h3>Transfer Ownership</h3>
				<p>
					Transfer the Ownership of Company
					<strong>{` ${connectedUser.company_name} `}</strong>
					from <br />
					<strong>{` "${connectedUser.first_name} ${connectedUser.last_name}" `}</strong>
					to
					<strong>{` "${user.first_name} ${user.last_name}" `}</strong>
				</p>
			</div>
			<div className={styles.users}>
				<div className={styles.users__avatar}>
					<Avatar
						size={60}
						src={connectedUser.avatar}
						name={connectedUser.first_name + " " + connectedUser.last_name}
						style={{
							boxShadow:
								"0px 10px 15px -6px rgba(0, 0, 0, 0.4), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
						}}
					/>
				</div>
				<SwitchIcon />
				<div className={styles.users__avatar}>
					<Avatar
						size={60}
						src={user.avatar}
						name={user.first_name + " " + user.last_name}
						style={{
							boxShadow:
								"0px 10px 15px -6px rgba(0, 0, 0, 0.4), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
						}}
					/>
					<YellowBadgeIcon />
				</div>
			</div>
			<div className={styles.form}>
				<form>
					<FormField
						type="password"
						label="Enter your password to confirm the transfer *"
						hasError={!!errorMsg}
						displayHelpBlock={false}
						placeholder={"Enter password"}
						input={{
							name: "password",
							value: password,
							onBlur: event => event.preventDefault(),
							onChange: event => setPassword(event.target.value),
							onFocus: () => dispatch(setModalErrorMsg())
						}}
						meta={{
							touched: false,
							error: false
						}}
						classes={
							errorMsg
								? `${styles.form__password} ${styles.form__password_error}`
								: styles.form__password
						}
						errorComponent={
							<span className={styles.form__password_error}>{errorMsg}</span>
						}
					/>
				</form>

				<div className={styles.form__info}>
					<InfoIcon />
					<span>The transfer is immediate and cannot be undone</span>
				</div>

				{/* actions buttons */}
				<div className={styles.form__buttons}>
					<Button
						size={14}
						onClick={onClose}
						id="cancel-transfert"
						color="secondary"
						disabledHover
						style={{
							border: "1px solid #D0D5DD"
						}}
					>
						Cancel
					</Button>
					<Button
						size={14}
						color="greenButton"
						id="submit-transfert"
						disabled={!password}
						onClick={() => onTranfertOwnership(user, password)}
						disabledStyles="{ background-color:#FAFAFA; color: #858c98; }"
					>
						Confirm
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default TransfertOwnership;
