import React, { Component } from "react";
import { connect } from "react-redux";
import { getLastJob } from "../actions";
import { jobDataSelector } from "../selectors";
import { REQUEST, VACANCY, JOBS } from "config";
import _get from "lodash/get";
import RequestView from "modules/RequestView/RequestView";

export const getJobPreviewRoute = id => {
	return `/jobs/post/preview${id !== undefined ? `/${id}` : `(/:id)`}`;
};
export const getVacancyPreviewRoute = id => {
	return `/vacancy/post/preview${id !== undefined ? `/${id}` : `(/:id)`}`;
};

class JobPreview extends Component {
	componentDidMount() {
		const pathname = _get(window, "location.pathname");
		let source;
		if (pathname) {
			source = _get(pathname.split("/"), "1");
		}
		const job_type = source === JOBS ? REQUEST : VACANCY;
		this.props.getLastJob({ id: this.props.params.id, job_type });
	}
	componentDidUpdate(prevProps) {
		if (prevProps.params.id !== this.props.params.id) {
			const pathname = _get(window, "location.pathname");
			let source;
			if (pathname) {
				source = _get(pathname.split("/"), "1");
			}
			const job_type = source === JOBS ? REQUEST : VACANCY;
			this.props.getLastJob({ id: this.props.params.id, job_type });
		}
	}

	render() {
		const { job, company } = this.props;
		return (
			<RequestView
				params={this.props.params}
				preview={true}
				job={job}
				company={company}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		job: jobDataSelector(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLastJob: payload => dispatch(getLastJob(payload))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobPreview);
