import React, { useState, useEffect, useRef } from "react";
import styles from "modules/candidate/Profile/Pages/Notes/notes.module.scss";
import FilterBox from "common/FilterBox";
import Table from "modules/candidate/Profile/components/Table";
import DisplayResults from "modules/Tasks/components/pagination/DisplayResults";
import PaginationClient from "modules/Tasks/components/pagination";
import useEditHistoryStore from "modules/candidate/Profile/Store/useEditHistoryStore";
import _debounce from "lodash/debounce";
import get from "lodash/get";
import {
	sortBylist,
	columns,
	getData
} from "modules/candidate/Profile/Pages/Profile/historyHelper";
import useEditHistory from "modules/candidate/Profile/api/useEditHistory";
import cx from "classnames";
import { searchParser } from "config/helpers";

const EditsHistory = ({ id }) => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const { tab } = searchParser();
	const enabled = tab === "profile";

	const {
		filters,
		updateOffset,
		updateSearch,
		setLimit,
		updateFilters,
		limit
	} = useEditHistoryStore();

	const {
		offset,
		// fields,
		field,
		search,
		isEditedBy,
		isEditedField,
		isEditedValue,
		isPreviousValue
	} = filters;

	const { data: resolvedData, isFetching } = useEditHistory(
		{
			...filters,
			search: {
				value: search,
				edited_by: isEditedBy,
				edited_field: isEditedField,
				previous_value: isPreviousValue,
				new_value: isEditedValue
			},
			profile_id: id,
			limit
		},
		{ enabled }
	);

	useEffect(() => {
		setSearchKeyword(search);
	}, []);

	const data = getData(isFetching, resolvedData);

	const debouncedChangeFilterAction = useRef(_debounce(updateSearch, 500));

	const handelSearchChange = value => {
		setSearchKeyword(value);
		debouncedChangeFilterAction.current(value);
		updateOffset(0);
	};

	const total = get(resolvedData, "pagination.total", 0);
	const size = get(resolvedData, "pagination.size", 1);

	return (
		<div className={cx(styles.noteContainer, styles.historyContainer)}>
			<div className={styles.container}>
				<div className={styles.filterBox}>
					<FilterBox
						searchKeyword={searchKeyword}
						setSearchKeyword={handelSearchChange}
						sortBylist={sortBylist}
						store={useEditHistoryStore}
						isEditedBy={isEditedBy}
						isEditedField={isEditedField}
						isEditedValue={isEditedValue}
						isPreviousValue={isPreviousValue}
						updateFilters={updateFilters}
						historyEdit={true}
					/>
				</div>
				<div className={styles.notesTable}>
					<Table
						data={data}
						isFetching={isFetching}
						sortedField={field}
						fetchedData={get(resolvedData, "data", [])}
						columns={columns(field)}
					/>
				</div>
				{get(resolvedData, "data.length", 0) > 0 && (
					<div className={styles.paginationContainer}>
						<DisplayResults
							resolvedData={resolvedData}
							isFetching={isFetching}
							setLimit={setLimit}
							updateOffset={updateOffset}
							offset={offset}
							limit={limit}
						/>

						<PaginationClient
							size={size}
							offset={offset}
							setOffset={updateOffset}
							total={total}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default EditsHistory;
