import React from "react";
import styles from "./quick-evaluation-card-body.module.scss";
import LexialContentView from "common/LexicalContentView/LexicalContentView";

const QuickEvaluationCardBody = ({ note }) => {
	return (
		<div className={styles.evaluationCardBody}>
			<div className={styles.note}>
				<LexialContentView description={note} />
			</div>
		</div>
	);
};
export default QuickEvaluationCardBody;
