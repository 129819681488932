import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getVisibleSelectionRect } from "draft-js";
import styled from "styled-components";
import { colors } from "config/styles";

function LinkInput({ value, placeholder, onKeyDown, onChange }) {
	const containerRef = useRef();
	const inputRef = useRef();
	const [position, setPosition] = useState({});
	const selectionRect = getVisibleSelectionRect(window);
	const editor = document.querySelector(".RichEditor"); // get the ref better
	const editorRect = editor.getBoundingClientRect();

	const offsetTop = -8;

	useEffect(() => {
		if (!containerRef.current) {
			return;
		}

		inputRef.current.focus();

		setPosition({
			top:
				editor.offsetTop -
				containerRef.current.offsetHeight +
				(selectionRect.top - editorRect.top) +
				offsetTop,
			left:
				editor.offsetLeft +
				(selectionRect.left - editorRect.left) +
				selectionRect.width / 2 -
				containerRef.current.offsetWidth / 2
		});
	}, []);

	return (
		<Container
			ref={containerRef}
			style={{ position: "absolute", ...position, zIndex: 99999 }}
		>
			<Background>
				<Input
					type="input"
					ref={inputRef}
					value={value}
					placeholder={placeholder}
					onKeyDown={onKeyDown}
					onChange={onChange}
					spellCheck={false}
				/>
			</Background>
		</Container>
	);
}

LinkInput.propTypes = {
	position: PropTypes.shape({
		top: PropTypes.number,
		left: PropTypes.number,
		height: PropTypes.number
	}),
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onKeyDown: PropTypes.func,
	onChange: PropTypes.func,
	onClickRemove: PropTypes.func,
	enableRemove: PropTypes.bool
};
LinkInput.defaultProps = {
	position: {
		top: 0,
		left: 0,
		height: 0
	},
	value: "",
	placeholder: "",
	onKeyDown: () => {},
	onChange: () => {},
	onClickRemove: () => {},
	enabledRemove: false
};
const Container = styled.div`
	display: flex;
	flex-direction: row;
	border-radius: 5px;
	position: absolute;
	z-index: 99;
`;

const Background = styled.div`
	position: relative;
	background-color: white;
	padding: 7px 10px;
	border: 1px solid #d7d9e0;
	border-radius: 1.5px;
	box-shadow: 0 0 7.4px 0.1px rgba(218, 224, 242, 0.48);

	&::after {
		content: "";
		position: absolute;
		left: calc(50% - 5px);
		bottom: -6px;
		background: white;
		width: 10px !important;
		height: 10px !important;
		border: 1px solid #d7d9e0 !important;
		transform: rotate(136deg);
		border-left: 0 !important;
		border-bottom: 0 !important;
	}
`;

const Input = styled.input`
	width: 250px;
	background: transparent;
	font-size: 14px;
	color: ${colors.anchorsColor};
	border: 0;
	outline: none;
`;

export default LinkInput;
