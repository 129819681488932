import React, { memo } from "react";
import styles from "./create-request-step-two-loading.module.scss";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestSkeletonCard } from "../CreateRequestSkeletonCard";
import { CreateRequestModal as CreateRequestCard } from "../CreateRequestCard";
import { CreateRequestCardTitle } from "../CreateRequestCardTitle";

const CreateRequestStepTwoLoading = () => {
	return (
		<div className={styles.cardsList}>
			<div className={styles.stepper} />
			<CreateRequestCard className={styles.card}>
				<CreateRequestCardTitle>Description</CreateRequestCardTitle>
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>
							Request description
						</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<CreateRequestSkeletonCard />
				</CreateRequestFieldContainer>
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>Job description</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<CreateRequestSkeletonCard height={215} />
				</CreateRequestFieldContainer>
			</CreateRequestCard>
		</div>
	);
};

export default memo(CreateRequestStepTwoLoading);
