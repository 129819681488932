import React, { memo, useState } from "react";
import { connect } from "react-redux";
import Modal, { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { reduxForm, formValueSelector, Field } from "redux-form";
import { compose } from "redux";
import _get from "lodash/get";
import { CONTRACT, INTERVIEW } from "config";
import StyledSelect from "common/StyledSelect";
import FormField from "common/FormField";
import { SubHeading } from "../../../vacancy/components/list/style";

const formName = "reject-application";
const other = "other";
const commentsMaxLength = 500;

function RejectApplicationModal({
	toggleRejectApplicationModal,
	onReject,
	handleSubmit,
	stepperStatus,
	bidId,
	interviewId,
	contractId,
	selectedOption
}) {
	const [showWarning, setShowWarning] = useState(false);

	const modalTitle = `Reject Application`;
	const modalContent = `Please provide a feedback to the applicant, 
      giving the reason(s) why you are rejecting the application. 
      Either pick a reason in the drop down below, or provide your own 
      personalised feedback by selecting “Other” and completing the 
      free text box below. 
  `;

	const closeModal = e => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		toggleRejectApplicationModal();
	};

	const save = async fields => {
		if (!showWarning) {
			return setShowWarning(true);
		}
		const _id =
			stepperStatus === CONTRACT
				? contractId
				: stepperStatus === INTERVIEW
				? interviewId
				: bidId;
		const payload = {
			bid_id: bidId, // need it for reloading data
			stepper_status: stepperStatus,
			id: _id,
			reject_reason: fields.reject_reason.value
		};
		if (fields.reject_reason.value === "other") {
			payload.comments = fields.comments;
		}
		await onReject(payload);
		closeModal();
	};
	const options = [
		{ value: "not_enough_experience", label: "Not enough experience" },
		{
			value: "not_skilled_enough_in_key_areas",
			label: "Not skilled enough in key areas"
		},
		{ value: "too_expensive", label: "Too expensive" },
		{
			value: "not_available_quickly_enough",
			label: "Not available quickly enough"
		},
		{
			value: "no_more_budget_for_the_mission",
			label: "No more budget for the mission"
		},
		{
			value: "mission_filled_by_another",
			label: "Mission filled by someone else"
		},
		{ value: other, label: "Other (detail reason below)" }
	];
	return (
		<>
			<Modal
				onClose={closeModal}
				title={modalTitle}
				firstButton={{
					label: "Confirm",
					type: "primary",
					action: handleSubmit(save)
				}}
				fixed
				active={!showWarning}
			>
				<SubHeading>{modalContent}</SubHeading>
				<Field
					name="reject_reason"
					options={options}
					placeholder="Reason for rejection"
					position={false}
					component={StyledSelect}
					autoFocus={true}
				/>
				<Field
					name="comments"
					label={"Additional comments"}
					placeholder="Feel free to add any information that you'd like to share with the applicant."
					type="textarea"
					maxLength={commentsMaxLength}
					component={FormField}
					className="textarea-resize-vertical"
					disabled={_get(selectedOption, "value", "") !== other}
				/>
			</Modal>
			{showWarning && (
				<ConfirmationModal
					modalName="reject-application"
					onClose={() => setShowWarning(false)}
					type={ConfirmationTypes.error}
					title={"Are you sure you want to reject this application?"}
					content={`This action is definitive and irreversible, and you will no longer be
            able to interact with this candidate once the operation has been
            performed.`}
					firstButton={{
						action: handleSubmit(save),
						label: "Yes, reject this application",
						type: "warning"
					}}
				/>
			)}
		</>
	);
}

const validate = formProps => {
	const errors = {};
	if (!formProps.reject_reason) {
		errors.reject_reason = "Please select a reject reason.";
	}
	if (formProps.comments && formProps.comments.length > commentsMaxLength) {
		errors.comments = `Comments should not exceed ${commentsMaxLength} characters.`;
	}
	if (
		formProps.reject_reason &&
		formProps.reject_reason.value === other &&
		(!formProps.comments || formProps.comments.trim() === "")
	) {
		errors.comments = "Comments can't be empty.";
	}
	return errors;
};

const selector = formValueSelector(formName);

const mapStateToProps = state => {
	return {
		selectedOption: selector(state, "reject_reason")
	};
};

export default compose(
	memo,
	reduxForm({ form: formName, touchOnBlur: false, validate }),
	connect(mapStateToProps)
)(RejectApplicationModal);
