import { SEND_OFFER } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

const sendOffer = payload =>
	client(SEND_OFFER, {
		body: payload
	});

export const useSendOffer = options => {
	return useMutation(sendOffer, {
		...options
	});
};
