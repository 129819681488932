import React, { useEffect } from "react";
import _get from "lodash/get";
import Notes from "./Notes";
import Tab from "modules/candidate/components/profileClientView/profileModalTab/components/tab/tab";
import Tabs from "modules/candidate/components/profileClientView/profileModalTab/components/tab/tabs";
import { PDF_VIEWER_URL } from "config";
import { Flex } from "rebass";

const DrawerTabs = ({
	profileData,
	activeTab,
	setActiveTab,
	onSeeAllNotesClick
}) => {
	useEffect(() => {
		const hash = window.location.hash;
		const tab = hash?.split("#")[1];
		hash && setActiveTab(tab);
	}, [window.location.hash, setActiveTab]);
	const cvDocs = _get(profileData, "personal_profile.cv_docs", []).filter(
		d => d.is_primary
	)[0];

	return (
		<Tabs activeTab={activeTab} setActiveTab={setActiveTab}>
			<Tab id="cv" title="Resume (CV)">
				<Flex
					flexDirection={"column"}
					flex={1}
					margin="0"
					padding="0"
					overflow="hidden"
				>
					<iframe
						style={{
							width: "100%",
							overflow: "hidden",
							border: "none",
							height: "100%",
							flex: 1
						}}
						src={
							PDF_VIEWER_URL +
							_get(cvDocs, "path", "").replace(/^http:\/\//i, "https://")
						}
						width="100%"
						height="100%"
					></iframe>
				</Flex>
			</Tab>

			<Tab id="notes" title="Notes" px=".5rem" py="0">
				<Notes
					onSeeAllNotesClick={onSeeAllNotesClick}
					profileData={profileData}
					useScroll={false}
				/>
			</Tab>
		</Tabs>
	);
};

export default DrawerTabs;
