import React from "react";
import { useMenuItem } from "@react-aria/menu";
import { useKeyboard } from "react-aria";
import ArrowComponent from "../ArrowComponent/ArrowComponent";
import MenuItem from "./MenuItem";
import styles from "./menu.module.scss";
import Scrollbars from "react-custom-scrollbars";
import { sectionsDisplayManager } from "modules/SearchCandidate/utils/accordion";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

const findFollowing = (arr, el, direction) => {
	const idx = arr.indexOf(el);

	if (direction === "next") {
		return arr[idx + 1 >= arr.length ? idx : idx + 1];
	} else if (direction === "previous") {
		return arr[idx - 1 < 0 ? idx : idx - 1];
	}
	return el;
};

const MenuSection = ({
	section,
	state,
	onAction,
	sectionsState,
	sectionsCount,
	setSectionsState,
	isLoading,
	selectedKey,
	setActiveSection
}) => {
	let ref = React.useRef();

	let { menuItemProps } = useMenuItem(
		{
			key: section.key,
			onAction: () => {
				return;
			}
		},
		state,
		ref
	);
	let { keyboardProps } = useKeyboard({
		onKeyDown: e => expand(e)
	});

	const expand = e => {
		const selected = state.collection.keyMap.get(
			state.selectionManager.focusedKey
		);

		if (e.key === "ArrowLeft") {
			setActiveSection(section.key);
			setSectionsState(sectionsDisplayManager(sectionsState, section.key));
		} else if (e.key === "ArrowRight") {
			setActiveSection(section.key);
			setSectionsState(sectionsDisplayManager(sectionsState, section.key));
		}

		if (selected && selected.key === section.key) {
			if (e.key === "ArrowUp") {
				const previousSection = findFollowing(
					Object.keys(sectionsState),
					selected.key,
					"previous"
				);
				if (!sectionsState[previousSection]) {
					state.selectionManager.setFocusedKey(previousSection);
				} else {
					if (selected.prevKey) {
						state.selectionManager.setFocusedKey(selected.prevKey);
					}
				}
			} else if (e.key === "ArrowDown") {
				if (sectionsState.activeSection === section.key) {
					if (selected.nextKey) {
						state.selectionManager.setFocusedKey(selected.nextKey);
					}
				} else {
					const nextSection = findFollowing(
						Object.keys(sectionsState),
						selected.key,
						"next"
					);
					state.selectionManager.setFocusedKey(nextSection);
				}
			}
		}
		return;
	};

	let isFocused = state.selectionManager.focusedKey === section.key;

	return (
		<>
			<li
				className={classNames([styles.section], {
					[styles.focused]: isFocused,
					[styles.loading]: isLoading
				})}
			>
				{isLoading ? (
					<>
						<Skeleton className={styles.sectionSkeleton} />
					</>
				) : (
					<>
						{section.rendered && (
							<button
								ref={ref}
								className={styles.btn}
								{...menuItemProps}
								{...keyboardProps}
								onClick={() => {
									setActiveSection(section.key);
									setSectionsState(
										sectionsDisplayManager(sectionsState, section.key)
									);
								}}
								type="button"
							>
								<ArrowComponent
									width={"1.125rem"}
									height={"1.125rem"}
									stroke={2}
									color="#262626"
									className={
										sectionsState.activeSection === section.key
											? [styles.arrow, styles.expanded].join(" ")
											: styles.arrow
									}
								/>
								{section.rendered}
								&nbsp;
								<span className={styles.green_span}>
									({sectionsCount[section.key]})
								</span>
							</button>
						)}
						<ul>
							<Scrollbars autoHeight autoHeightMax={208.25}>
								{sectionsState.activeSection === section.key &&
									[...section.childNodes].map(node => (
										<MenuItem
											key={node.key}
											item={node}
											state={state}
											onAction={id => onAction(id, section.key)}
											selectedKey={selectedKey}
										/>
									))}
							</Scrollbars>
						</ul>
					</>
				)}
			</li>
		</>
	);
};

export default MenuSection;
