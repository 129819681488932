import styled, { css } from "styled-components";
import { colors } from "config/styles";

export const SearchResultsContainer = styled.div`
	width: 100%;
	margin-top: ${props =>
		props.marginTop
			? props.marginTop + "px"
			: props.haveSearchCriteria
			? "50px"
			: "78px"};
	.results-count {
		color: ${colors.inputColor};
		font-size: 15px;
		font-weight: 400;
		line-height: normal;
		text-align: right;
		margin-right: 35px;
	}
	.results-container {
		min-height: 595px;

		.results {
			display: flex;
			align-items: center;
			margin-top: 10px;
			flex-wrap: wrap;
		}
	}
	.count-checkbox {
		display: flex;
		justify-content: center;
		font-family: "basiercircle";
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #6e7191;
	}
	.send-Vacancy-btn {
		width: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fd6950;
		border-radius: 6px;
		outline: none;
		border: none;
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 22px;
		color: #ffffff;
		place-content: space-evenly;
		opacity: ${({ sendVacancy }) => (sendVacancy ? 1 : 0.4)};
	}
`;

export const ProfileItem = styled.div`
	background-color: ${colors.white};
	border-radius: 4px;
	box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
	border: solid 0.5px ${colors.grayBorder};
	width: 32%;
	height: 310px;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: 0.3s;
	cursor: pointer;
	&:hover {
		box-shadow: 0px 5px 5px 0.1px rgba(197, 199, 206, 0.4);
		.first-section {
			.function {
				.name {
					color: ${colors.primaryColor};
				}
			}
		}
		.third-section {
			.icon-container {
				background-color: ${colors.primaryColor};
				border: 1px solid ${colors.primaryColor};
				span {
					color: ${colors.white};
				}
			}
		}
	}
	.first-section {
		padding: 25px 20px;
		border-bottom: 1px solid #ebeef0;
		position: relative;
		min-height: 98px;
		.profile-actions{
			display: block;
			position: absolute;
			right: 10px;
			top: 7px;
			.dots{
				display: inline;
				position: relative;
				top: -2px;
			}
		}
	}

		.function {
			cursor: pointer;
			.name {

  font-family: "BasierCircle";
  font-size: 16px;
			font-weight: 500;

  letter-spacing: 0.08px;

  color: rgb(51, 51, 51);
				transition: 0.3s;
				span {
					font-weight: normal;
				}
			}
		}
		.location {
			color: ${colors.inputColor};
			font-size: 14px;
			.main-location{

				font-family: "BasierCircle";
				font-size: 14px;
				color: rgb(152, 153, 156);
			}

			.g-tool {
				color: ${colors.inputColor} !important;
				font-size: 14px !important;
				margin-left: unset !important;
			}
			.icon-local {
				color: #3185cf;
				margin-right: 7px;
				position: relative;
				top: 1px;
				left: 3px;
			}
		}
	}
	.second-section {

		font-size: 14px;
padding: 23px;
padding-top: 21px;

		.salary {
			padding: 20px 0;
			border-right: 1px solid #ebeef0;
			width: 50%;
		}
		.advantages {
			padding: 20px 0 20px 10px;
			width: 50%;

			.content {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.center {

			}
		}
		.title {

  font-family: "BasierCircle";
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.99px;
  color: rgb(160, 165, 185);
			margin-bottom: 5px;
			text-transform: uppercase;
		}
		.content {

  font-family:"BasierCircle" ;
  font-size: 14px;

	color: rgb(15, 28, 70);
	margin-bottom: 10px;

		}
	}
	.third-section {
		padding: 12px 20px;
		font-size: 14px;
		display: flex !important;
		align-items: center !important ;
		justify-content: space-between !important;

		.circle {
			height: 10px;
			width: 10px;
			display: inline-block;
			border-radius: 50%;
			margin-right: 7px;
			vertical-align: middle;
		}

		.fullName, .ref  {
			border-radius: 17.5px;
			background-color: rgb(248, 249, 250);
			padding: 5px 10px;

  font-family: "BasierCircle";
  font-size: 12px;
  font-weight: 500;


  color: rgb(36, 47, 67);
			.circle {
				background-color: ${colors.greenTextColor};
			}
		}
		.icon-container {
			width: 30px;
			height: 30px;
			position: relative;
			border: 1px solid #f0f2f4;
			border-radius: 50%;
			transition: 0.3s;
			cursor: pointer;
			&:hover {
				background-color: ${colors.primaryColor};
				border: 1px solid ${colors.primaryColor};
				span {
					color: ${colors.white};
				}
			}
			.icon-arrow-right1 {
				color: ${colors.primaryColor};
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 12px;
			}
		}
	}
`;

export const EmptySearchContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	${props =>
		props.padding
			? css`
					padding-top: ${props.padding};
			  `
			: css`
					padding-top: 150px;
			  `}

	img {
		width: 114px;
		height: 71px;
		margin-bottom: 15px;
	}
	.title {
		font-size: 16px !important;
		font-weight: bold;
		color: #242f43;
		text-align: center;
	}
	.paragraph {
		color: ${colors.inputColor};
		font-size: 14px;
		font-weight: normal;
	}
`;

export const SearchLimitMessage = styled.span`
	font-size: 14px;
	font-weight: normal;
	line-height: normal;
	color: ${colors.grayText};
	letter-spacing: normal;
	text-align: center;
	display: block;
	margin-top: 20px;
`;
