import React from "react";
import styles from "./profile-view-evaluations-summary.module.scss";
import ProfileViewEvaluationSummaryCards from "../ProfileViewEvaluationSummaryCards/ProfileViewEvaluationSummaryCards";
import { EvaluationsStatisticsSegments } from "common/EvaluationsStatisticsSegments";
import { ReactComponent as NoIcon } from "static/icons/thumbs-down.svg";
import { ReactComponent as AcceptableIcon } from "static/icons/thumbs-up2.svg";
import { ReactComponent as ExcellentIcon } from "static/icons/thumbs-up.svg";

const ProfileViewEvaluationSummary = ({ percents, data }) => {
	const statistics = [
		{
			label: "No",
			percent: percents.bad,
			icon: <NoIcon />,
			backgroundColor: "#d92d20"
		},
		{
			label: "Acceptable",
			percent: percents.acceptable,
			icon: <AcceptableIcon />,
			backgroundColor: "#f59e0b"
		},
		{
			label: "Excellent",
			percent: percents.excellent,
			icon: <ExcellentIcon />,
			backgroundColor: "#039855"
		}
	];
	return (
		<>
			<div className={styles.summaryTitle}>Candidate Scores</div>
			<hr />
			<div>
				<EvaluationsStatisticsSegments
					total={1}
					stats={statistics}
					suffix="%"
				/>
			</div>
			<hr />
			<ProfileViewEvaluationSummaryCards data={data} />
		</>
	);
};
export default ProfileViewEvaluationSummary;
