import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import styled from "styled-components";
import loadable from "loadable-components";
import { Element } from "react-scroll";
import GlobalTooltip from "./GlobalTooltip";
import NestedOption from "./NestedOption";
const Container = styled.div`
	.Select-menu-outer {
		top: ${props => props.menuPosition === "top" && "auto"};
		bottom: ${props => props.menuPosition === "top" && "100%"};
		border-top: ${props => props.menuPosition === "top" && "2px solid gray"};
	}
`;
const Select = loadable(() =>
	import(/* webpackChunkName: "react-select" */ "react-select")
);

class StyledSelect extends PureComponent {
	changeReduxForm = val => {
		const { input, defaultValue } = this.props;
		val = val || defaultValue;
		if (input && !input.value && val) {
			input.onChange(val);
		}
	};

	componentDidMount() {
		this.changeReduxForm();
	}

	componentDidUpdate({ defaultValue }) {
		if (defaultValue !== this.props.defaultValue) {
			this.changeReduxForm(this.props.defaultValue);
		}
	}

	// TODO: get formatted list from props (selector)
	handleGroupedData = (rawData = null) => {
		const { grouped, valueField } = this.props;
		if (!rawData) rawData = this.props.rawData;
		const options = [];
		if (grouped) {
			rawData.forEach(opt => {
				if (opt.group_name !== "") {
					const groupIdx = options.findIndex(
						grp => grp.label === opt.group_name && !opt.hidden
					);
					if (groupIdx === -1) {
						options.push({
							label: opt.group_name,
							value: opt.group_name,
							children: opt.children,
							_id: opt._id,
							subs: [
								{
									label: opt.name,
									children: opt.children,
									value: opt[valueField]
								}
							]
						});
					} else {
						const child = {
							_id: opt._id,
							label: opt.name,
							children: opt.children,
							value: opt[valueField]
						};
						_get(options[groupIdx], "subs", []).push(child);
					}
					options.push({
						value: opt[valueField],
						label: opt.name,
						_id: opt._id,
						children: opt.children,
						hidden: true
					});
				} else {
					options.push({
						label: opt.name,
						value: opt[valueField],
						children: opt.children,
						_id: opt._id,
						subs: []
					});
				}
			});
			return options;
		} else {
			return this.props.options;
		}
	};

	handleChange = value => {
		const { input, onChange } = this.props;
		if (input && input.onChange) {
			return input.onChange(value);
		}
		onChange && onChange(value);
	};

	render() {
		const {
			label,
			input,
			meta: { touched, error },
			grouped,
			placeholder,
			searchable,
			disabled,
			clearable,
			simpleValue,
			classes,
			toolTipOverlay,
			showErrors,
			toolTipMaxWidth,
			isLoading = false,
			backspaceRemoves,
			onSelectResetsInput,
			onBlurResetsInput,
			onInputChange,
			onValueClick,
			onClick,
			labelKey,
			valueKey,
			multi,
			valueToSet,
			noResultsText,
			searchPromptText,
			filterOptions,
			className,
			options,
			style,
			onOpen,
			selectOptionRenderer,
			removeSelected,
			valueComponent,
			defaultValue,
			tabIndex,
			autoFocus,
			showErrorContainer = true,
			isClient,
			menuPosition
		} = this.props;

		const _showError = showErrors === undefined ? touched : showErrors;

		let classesToAdd = _showError && error ? "has-error has-feedback" : "";
		if (label) {
			classesToAdd += " has-label";
		} else classesToAdd += " label-no";

		if (grouped) classesToAdd += " Select-parent";

		const _options = grouped ? this.handleGroupedData() : options;

		const _value =
			valueToSet ||
			(!!input.value && !onSelectResetsInput ? input.value : defaultValue);

		const _onClick = event =>
			onClick && onClick({ event, name: input.name, value: _value, disabled });

		return (
			<Element
				id={input.name}
				className={`form-group select-style ${classesToAdd} ${
					className ? className : null
				}`}
				name={input.name}
				scrollto="scroll-to-error"
				style={style}
			>
				{label && (
					<label htmlFor={input.name}>
						{label} {"  "}
						{toolTipOverlay && (
							<GlobalTooltip
								maxWidth={toolTipMaxWidth}
								overlay={toolTipOverlay}
							/>
						)}
					</label>
				)}
				<Container menuPosition={menuPosition} onClick={_onClick}>
					<Select
						name={input.name}
						value={_value}
						options={_options}
						clearable={clearable}
						placeholder={placeholder}
						simpleValue={simpleValue}
						searchable={searchable}
						onChange={this.handleChange}
						onBlur={() => (input.onBlur ? input.onBlur(input.value) : null)}
						disabled={disabled}
						className={classes}
						optionComponent={props => (
							<NestedOption {...props} isClient={isClient} />
						)}
						noResultsText={noResultsText}
						isLoading={isLoading}
						backspaceRemoves={backspaceRemoves}
						onBlurResetsInput={onBlurResetsInput}
						onInputChange={onInputChange}
						labelKey={labelKey}
						valueKey={valueKey}
						onValueClick={onValueClick}
						onSelectResetsInput={onSelectResetsInput}
						searchPromptText={searchPromptText}
						filterOptions={filterOptions}
						removeSelected={removeSelected}
						multi={multi}
						onOpen={onOpen}
						optionRenderer={selectOptionRenderer}
						valueComponent={valueComponent}
						tabIndex={tabIndex}
						autoFocus={autoFocus}
					/>
					{showErrorContainer && (
						<div className="help-block inline-error">{_showError && error}</div>
					)}
				</Container>
			</Element>
		);
	}
}

StyledSelect.propTypes = {
	valueField: PropTypes.string.isRequired,
	grouped: PropTypes.bool,
	rawData: PropTypes.array
};

StyledSelect.defaultProps = {
	searchable: false,
	disabled: false,
	clearable: false,
	simpleValue: false,
	valueField: "name",
	toolTipMaxWidth: "400px",
	grouped: false,
	rawData: [],
	noResultsText: "No results found",
	options: [],
	input: {},
	meta: {}
};

export default StyledSelect;
