import React, { useCallback, useState } from "react";
import {
	MessagingToolEmailItem,
	MessagingToolEmailListEmptyState
} from "common/MessagingToolEmailsList";
import sharedStyles from "common/MessagingToolEmailsList/messaging-tool-email-list.module.scss";
import styles from "./conversations-tab-list.module.scss";
import { ReactComponent as EditIcon } from "static/icons/edit-04.svg";
import MessagingToolEmailListLoading from "common/MessagingToolEmailsList/components/MessagingToolEmailListLoading/MessagingToolEmailListLoading";
import cx from "classnames";
import { formatDateAndTime } from "common/MessagingToolEmailsList/utils/helpers";
import useGetEmailsList, {
	MESSAGING_TOOL_EMAILS_LIST_KEY
} from "common/MessagingToolEmailsList/hooks/useGetEmailsList";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";
import { formatData } from "modules/MessagingTool/utils/helpers";
import debounce from "lodash/debounce";
import { SearchInput } from "common/SearchInput";
import MessagingToolEmailDeleteButton from "common/MessagingToolEmailsList/components/MessagingToolEmailDeleteButton";
import MessagingToolEmailArchiveButton from "common/MessagingToolEmailsList/components/MessagingToolEmailArchiveButton";
import { MessagingToolEmailsListHeader } from "common/MessagingToolEmailsList";
import {
	ACTIONS_BY_VIEW,
	ACTION_SUCCESS_MESSAGE,
	EMAILS_LIST_LIMIT,
	MARK_AS_ARCHIVED_ACTION,
	SOFT_DELETE_ACTION
} from "common/MessagingToolEmailsList/utils/constant";
import useEmailAction from "common/MessagingToolEmailsList/hooks/useEmailAction";
import { queryCache } from "react-query";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import { addQueryParamToPath, generateId, renderError } from "config/helpers";
import { browserHistory } from "react-router";
import useNotesStore from "modules/notes/notes.store";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import { NEW_MAIL } from "modules/notes/constants";
import { ReactComponent as AttachmentsIcon } from "static/icons/attachment-02.svg";

export default function ConversationsTabList({ candidateData }) {
	const [search, setSearch] = useState("");
	const [currentPage, setCurrentPage] = useState(0);
	const { data, isLoading } = useGetEmailsList({
		folder: MESSAGING_TOOL_LINKS.sent.slug.toUpperCase(),
		search: search,
		profile_id: candidateData.user_id,
		tag: "conversations",
		offset: currentPage * EMAILS_LIST_LIMIT
	});

	const total = data?.total || 0;
	const numberOfPages = Math.ceil(total / EMAILS_LIST_LIMIT);

	const emailsListView = formatData(data?.data, MESSAGING_TOOL_LINKS.sent.slug);

	const onSearchChange = useCallback(
		debounce(value => {
			setSearch(value);
		}, 500),
		[]
	);

	const [mutation, { isLoading: actionIsLoading }] = useEmailAction({
		onSuccess: (_, { action }) => {
			queryCache.invalidateQueries(MESSAGING_TOOL_EMAILS_LIST_KEY);
			toaster.success(ACTION_SUCCESS_MESSAGE[action].single);
		},
		onError: renderError
	});

	const onAction = ({ id, action }) => {
		mutation({
			action,
			ids: [id]
		});
	};

	const onRedirectToEmailView = id => {
		browserHistory.replace({
			pathname: window.location.pathname,
			search: addQueryParamToPath(window.location.search, "conversationId", id)
		});
	};

	const { setActiveNote, addNote: addEmail } = useNotesStore();

	const onAddNewEmail = () => {
		const id = generateId();
		const email = candidateData?.personal_profile?.email;
		setActiveNote(id);
		addEmail({
			id,
			type: NEW_MAIL,
			...EMPTY_EMAIL,
			to: email ? [{ email }] : []
		});
	};

	const actionsByView = ACTIONS_BY_VIEW[MESSAGING_TOOL_LINKS.sent.slug];

	const displayActions = item => (
		<>
			<MessagingToolEmailArchiveButton
				tooltipText={actionsByView?.[MARK_AS_ARCHIVED_ACTION]}
				onClick={() =>
					onAction({
						id: item._id,
						action: MARK_AS_ARCHIVED_ACTION
					})
				}
			/>
			<MessagingToolEmailDeleteButton
				tooltipText={actionsByView?.[SOFT_DELETE_ACTION]}
				onClick={() =>
					onAction({
						id: item._id,
						action: SOFT_DELETE_ACTION
					})
				}
			/>
		</>
	);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<SearchInput
					onChange={({ target }) => onSearchChange(target.value)}
					placeholder="Search in mail"
				/>
				<button className={styles.composeButton} onClick={onAddNewEmail}>
					<EditIcon className={styles.icon} />
					New Mail
				</button>
			</div>

			{isLoading ? (
				<MessagingToolEmailListLoading />
			) : (
				<div>
					{emailsListView.length ? (
						<>
							<MessagingToolEmailsListHeader
								hasActions={false}
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								numberOfPages={numberOfPages}
								total={total}
							/>
							{emailsListView.map(item => (
								<MessagingToolEmailItem
									key={item._id}
									emailId={item._id}
									email={item}
									onRedirectToEmailView={() =>
										onRedirectToEmailView(item.parent_id || item._id)
									}
									className={cx(sharedStyles.emailItem, {
										[sharedStyles.unread]: item.unread
									})}
								>
									<MessagingToolEmailItem.UserInfo
										username={item.user.name}
										proficiency={item.user.user_function}
										hash={MESSAGING_TOOL_LINKS.sent.slug}
									/>
									<MessagingToolEmailItem.EmailDetails
										subject={item.subject}
										message={item.body}
									/>
									<div className={sharedStyles.actions}>
										<div
											className={cx(
												sharedStyles.ctaList,
												sharedStyles.hoverable
											)}
										>
											{displayActions(item)}
										</div>
										{item.attachments.length !== 0 && (
											<AttachmentsIcon className={sharedStyles.attachments} />
										)}
										<span className={sharedStyles.time}>
											{formatDateAndTime(item.date, "DD/MM/YYYY, hh:mm A")}
										</span>
									</div>
								</MessagingToolEmailItem>
							))}
						</>
					) : (
						<div className={styles.emptyStateContainer}>
							<MessagingToolEmailListEmptyState />
						</div>
					)}
				</div>
			)}
			{actionIsLoading && <LoaderFull />}
		</div>
	);
}
