import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styles from "./loading-item.module.scss";

const LoadingItem = () => {
	return (
		<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
			<div className={styles.container}>
				<div className={styles.picture}>
					<Skeleton width={"100%"} height={"100%"} />
				</div>
				<div className={styles.infoWrapper}>
					<div className={styles.author}>
						<Skeleton width={"100%"} height={"100%"} />
					</div>
					<div className={styles.date}>
						<Skeleton width={"100%"} height={"100%"} />
					</div>
				</div>
				<div className={styles.body}>
					<Skeleton
						className={styles.bodySkeleton}
						width={"100%"}
						height={"100%"}
					/>
				</div>
			</div>
		</SkeletonTheme>
	);
};

export default LoadingItem;
