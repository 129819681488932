import React, { useRef } from "react";
import { useButton } from "react-aria";

import {
	ContainedButtonStyle,
	PrimaryButtonStyle,
	OutlinedButtonStyle,
	IconButtonStyle,
	StyledButton
} from "./styles";
import Loader from "common/Loader";

const DefaultButton = props => {
	let ref = useRef();
	let { children, isLoading, padding, backgroundColor, className } = props;
	let { buttonProps } = useButton(
		{ ...props, ...(isLoading && { isDisabled: isLoading }) },
		ref
	);

	return (
		<StyledButton
			{...buttonProps}
			padding={padding}
			backgroundColor={backgroundColor}
			className={className}
			ref={ref}
		>
			{isLoading ? <Loader loading={true} /> : children}
		</StyledButton>
	);
};

export default DefaultButton;

export const PrimaryButton = props => {
	let ref = useRef();
	let { children, isLoading } = props;
	let { buttonProps } = useButton(
		{ ...props, ...(isLoading && { isDisabled: isLoading }) },
		ref
	);

	return (
		<PrimaryButtonStyle {...props} {...buttonProps} ref={ref}>
			{isLoading ? <Loader loading={true} /> : children}
		</PrimaryButtonStyle>
	);
};

export const ContainedButton = props => {
	let ref = useRef();
	let { children, isLoading } = props;
	let { buttonProps } = useButton({ ...props, isDisabled: isLoading }, ref);

	return (
		<ContainedButtonStyle {...props} {...buttonProps} ref={ref}>
			{isLoading ? <Loader loading={true} /> : children}
		</ContainedButtonStyle>
	);
};

export const OutlinedButton = props => {
	let ref = useRef();
	let { children, isLoading } = props;
	let { buttonProps } = useButton({ ...props, isDisabled: isLoading }, ref);

	return (
		<OutlinedButtonStyle {...props} {...buttonProps} ref={ref}>
			{isLoading ? <Loader loading={true} /> : children}
		</OutlinedButtonStyle>
	);
};

export const IconButton = props => {
	let ref = useRef();
	let { children, isLoading, isCreateDeparetment = false } = props;
	let { buttonProps } = useButton({ ...props }, ref);

	if (isCreateDeparetment) {
		return (
			<IconButtonStyle {...props}>
				{isLoading ? <Loader loading={true} /> : children}
			</IconButtonStyle>
		);
	}

	return (
		<IconButtonStyle {...props} {...buttonProps} ref={ref}>
			{isLoading ? <Loader loading={true} /> : children}
		</IconButtonStyle>
	);
};
