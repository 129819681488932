import * as types from "../actions/actionTypes";

const initialState = {
	isFetching: false,
	isProposing: false,
	can_adjust: false,
	job_id: null,
	bid_id: null,
	memberList: [],
	currentFreelancer: null
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.MAKE_PROPOSAL:
			return Object.assign({}, state, {
				isProposing: true,
				job_id: action.job_id,
				bid_id: action.bid_id,
				can_adjust: action.can_adjust
			});
		case types.CLOSE_PROPOSAL:
			return Object.assign({}, state, {
				isProposing: false,
				currentFreelancer: null
			});
		case types.SUBMIT_PROPOSAL:
			return Object.assign({}, state, { isFetching: true });
		case types.DONE_FETCHING:
			return Object.assign({}, state, {
				isFetching: false,
				isProposing: action.isProposing
			});
		case types.GET_MEMBERS:
			return Object.assign({}, state, {
				memberList: action.list,
				isFetching: false
			});
		case types.SWITCH_FREELANCER:
			return Object.assign({}, state, {
				currentFreelancer: action.freelancer
			});
		default:
			return state;
	}
}
