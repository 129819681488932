import React from "react";
import styles from "./styles.module.scss";
import CustomizableModel from "../../../../common/CustomizableModel/CustomizableModel";
import ClearButton from "./ClearButton";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import { browserHistory } from "react-router";
import get from "lodash/get";
import { historyPush } from "config/helpers";
import { getProfileSearchRoute } from "modules/SearchCandidate/ViewSwitcher";

const ClearModal = ({ onClose }) => {
	const { resetFilters } = useFilters();
	const clearFiltersHandler = () => {
		resetFilters();
		onClose();
		const job_id = get(
			browserHistory.getCurrentLocation(),
			"query.vacancy-id",
			""
		);
		if (job_id) historyPush(getProfileSearchRoute());
	};
	return (
		<CustomizableModel
			title="Clear filters"
			actionButton={<ClearButton onClick={clearFiltersHandler} />}
			onClose={onClose}
		>
			<p className={styles.modal__text}>
				Are you sure you want to clear these filters ?
			</p>
		</CustomizableModel>
	);
};

export default ClearModal;
