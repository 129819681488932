import React, { useState } from "react";
import { useMutation } from "react-query";
import { Flex } from "rebass";
import get from "lodash/get";
import { Button } from "common/styled/buttons";
import { SEND_COMMENT } from "config/api-endpoints";
import toaster from "common/Toaster";
import { ReactComponent as SendComment } from "static/icons/send-comments.svg";
import { client } from "lib/api-client";
import { WORKTIME, OVERTIME, EXPENSE } from "config";
const addComment = data => client(SEND_COMMENT, { body: data });

const AddComment = ({ view, timesheetId, id, loadTimeSheet, maxLength }) => {
	const [comment, setComment] = useState("");
	const [mutate, { isLoading }] = useMutation(addComment, {
		onSuccess: () => {
			setComment("");
			loadTimeSheet();
		},
		onError: error => {
			let errorMsg = get(error, "detail");
			const keys = Object.keys(errorMsg);
			if (keys.length > 0) {
				keys.forEach(name => {
					toaster.danger(errorMsg[name]);
				});
			}

			toaster.danger(get(error, "detail", get(error, "title")));
		}
	});

	const sendComment = e => {
		e.preventDefault();
		e.stopPropagation();
		if (!comment.trim().length) {
			return;
		}
		const type = {
			[WORKTIME]: WORKTIME,
			[EXPENSE]: EXPENSE,
			[OVERTIME]: WORKTIME
		};
		return mutate({
			timesheet_id: timesheetId,
			type_item: type[view], // 'worktime' or 'expense'
			item_id: id,
			comment
		});
	};

	const getTextLength = () => {
		return comment.length;
	};

	return (
		<form onSubmit={sendComment}>
			<Flex
				onClick={e => {
					e.stopPropagation();
				}}
				sx={{ gap: 2 }}
				justifyContent="space-between"
				alignItems="flex-start"
			>
				<Flex
					sx={{
						outline: "none",
						position: "relative",
						flex: 1,
						"> input": {
							paddingLeft: "16px",
							background: "#F7F8FA",
							borderRadius: "5px",
							border: "1px solid #f1f2f5",
							boxShadow: "0 1px 0 #fafafb",
							width: "100%",
							height: "46px"
						},
						"> p": {
							margin: 0,
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: "normal",
							fontSize: "12px",
							lineHeight: "22px",
							color: "#6E7191"
						}
					}}
					flexDirection="column"
				>
					<input
						placeholder="Type your comment"
						value={comment}
						onChange={e => {
							e.stopPropagation();
							if (maxLength && e.target.value.length <= maxLength) {
								setComment(e.target.value);
							}
							if (!maxLength) setComment(e.target.value);
						}}
					/>
					{maxLength && (
						<span
							style={{
								alignSelf: "flex-end",
								fontSize: 13,
								fontWeight: 500
							}}
						>
							{getTextLength()}/{maxLength}
						</span>
					)}
				</Flex>

				<Button type="submit" disabled={!comment || isLoading} size={14}>
					<SendComment style={{ marginRight: "6px" }} />
					Send
				</Button>
			</Flex>
		</form>
	);
};

export default AddComment;
