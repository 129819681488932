import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { HTTP_GET_CURRENT_COMPANY } from "config/api-endpoints";

function getCurrentCompany() {
	return client(HTTP_GET_CURRENT_COMPANY);
}

export const getCurrentCompanyQueryKey = "get-current-company";

function useGetCurrentCompany(queryConfig = {}) {
	return useQuery(getCurrentCompanyQueryKey, getCurrentCompany, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryConfig
	});
}

export default useGetCurrentCompany;
