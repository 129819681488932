import React from "react";
import styles from "./parcel-loading-state.module.scss";
import Skeleton from "react-loading-skeleton";

export function ParcelFilesLoadingState() {
	return (
		<div className={styles.list}>
			{Array(10)
				.fill(0)
				.map((item, index) => (
					<div className={styles.progressContainer} key={index}>
						<div className={styles.description}>
							<Skeleton className={styles.fileIcon} />
							<Skeleton className={styles.name} />
						</div>
						<Skeleton className={styles.actionIcon} />
					</div>
				))}
		</div>
	);
}

function ParcelLoadingState() {
	return (
		<>
			<div className={styles.header}>
				<div className={styles.subHeader}>
					<div className={styles.description}>
						<Skeleton className={styles.title} />
						<Skeleton className={styles.status} />
					</div>
					<Skeleton className={styles.divider} />
				</div>

				<div className={styles.tabs}>
					<Skeleton className={styles.tab} />
					<Skeleton className={styles.tab} />
				</div>
			</div>
			<ParcelFilesLoadingState />
		</>
	);
}

export default ParcelLoadingState;
