/** this is our base Component every component must extend it */
import {
	space,
	borders,
	borderColor,
	width,
	minWidth,
	maxWidth,
	height,
	minHeight,
	maxHeight,
	display,
	fontSize,
	flex,
	order,
	alignSelf,
	color,
	textAlign,
	compose,
	position
} from "styled-system";

export const themed = key => props => props.theme[key];

const base = compose(
	() => ({ boxSizing: "border-box" }),
	space,
	width,
	minWidth,
	maxWidth,
	height,
	minHeight,
	maxHeight,
	fontSize,
	color,
	flex,
	order,
	alignSelf,
	borders,
	borderColor,
	display,
	textAlign,
	position
);

base.propTypes = {
	...display.propTypes,
	...space.propTypes,
	...borders.propTypes,
	...borderColor.propTypes,
	...width.propTypes,
	...height.propTypes,
	...fontSize.propTypes,
	...color.propTypes,
	...textAlign.propTypes,
	...flex.propTypes,
	...order.propTypes,
	...alignSelf.propTypes,
	...position.propTypes
};

export default base;
