import React, { Component } from "react";
import cx from "classnames";
import { ReactComponent as IfoCloseIcon } from "static/icons/info-close-icon.svg";
import { ReactComponent as AddIcon } from "static/icons/add-icon-grey.svg";
import styles from "./intro.module.scss";
import { Button } from "common/Button";

export default class Intro extends Component {
	render() {
		const isClient = this.props.isClient;
		const onClose = this.props.onClose;
		return (
			<div className={cx("steps-container", this.props.className)}>
				<div
					style={{
						padding: isClient ? "0 15px" : "0"
					}}
					className={isClient ? "full-container intro" : "container intro"}
				>
					<div className={"row align-items-center"}>
						<div className="col-md-4">
							<h3 className={styles.title}>{this.props.title}</h3>
						</div>
						{this.props.buttonWatch && (
							<div className={cx("col-md-8", styles.buttons)}>
								{this.props?.showAddEntriesButton && (
									<Button
										className={styles.addEntryButton}
										text="Add entries"
										onClick={this.props.onAddEntries}
										icon={
											<AddIcon
												height={24}
												width={24}
												className={styles.plusIcon}
											/>
										}
									/>
								)}

								<div className="button-watch-guide">
									<button onClick={() => this.props.openModalHelp()}>
										<span className="icon-play-btn"></span> Watch Timesheet
										clone Guide
									</button>
								</div>
							</div>
						)}
					</div>

					{this.props.subTitle && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center"
							}}
							className="description"
						>
							<p>
								{this.props.subTitle}
								{this.props.secondSubTitle && (
									<span>
										<br />
										{this.props.secondSubTitle}
									</span>
								)}
							</p>
							{this.props.isClosable && (
								<button
									onClick={onClose}
									style={{
										background: "transparent",
										border: "none"
									}}
								>
									<IfoCloseIcon />
								</button>
							)}
						</div>
					)}

					{this.props.children && (
						<div className="description">{this.props.children}</div>
					)}
				</div>
			</div>
		);
	}
}
