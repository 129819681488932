import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { browserHistory } from "react-router";
import { readNotifications } from "../actions";
import NotificationRow from "./common/NotificationRowClient";
import Pagination from "../../../common/PaginationClient";
import { Scrollbars } from "react-custom-scrollbars";
import styles from "./style.module.scss";
import styled from "styled-components";
import { ReactComponent as ChevronLeft } from "static/icons/pagination-chevron-left.svg";
import { ReactComponent as ChevronRight } from "static/icons/pagination-chevron-right.svg";
import NotificationsCenterEmptyBlock from "common/Layouts/NotificationsCenterEmptyBlock";
import { Box } from "rebass";
import useGetMissionNotifications from "../hooks/useGetMissionNotifications";
import { LATEST, MISSION_NOTIFICATIONS } from "config";

const PaginationNotificationsWrapper = styled.div`
	.pagination-client {
		all: revert;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 32px;
		margin-bottom: 32px;
		li {
			list-style: none;
			[role="button"] {
				all: revert;
				font-family: "Work Sans";
				font-size: 14px;
				font-weight: 600;
				text-align: center;
				color: #525252;
				cursor: pointer;
				&:hover {
					background: transparent !important;
					color: #262626 !important;
				}
			}
			&.active > [role="button"] {
				background-color: white !important;
				color: #262626 !important;
				position: relative;
				&::after {
					content: "";
					position: absolute;
					inset: auto 0 0 0;
					background-color: #00857a;
					height: 2px;
					border-radius: 99px 99px 0 0;
				}
			}
		}
		li:not(:is(.previous, .next)) {
			[role="button"] {
				height: 32px;
				padding: 0 12px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		li:is(.previous, .next) {
			.disabled {
				color: #d4d4d4;
			}
			[role="button"] {
				height: 32px;
				width: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			&::after,
			&::before {
				content: "";
			}
		}
	}
`;

const MissionNotifications = ({ view }) => {
	const [offset, setOffset] = useState(0);
	const { data, refetch } = useGetMissionNotifications({
		offset,
		tag: MISSION_NOTIFICATIONS,
		field: view
	});
	const dispatch = useDispatch();
	let missionNotificationCount;
	if (view === LATEST)
		missionNotificationCount = data?.counts?.mission?.total_latest;
	else missionNotificationCount = data?.counts?.mission?.total_unread;
	const readNotification = notification => {
		notification = JSON.parse(JSON.stringify(notification));
		browserHistory.push(notification.message.url);
		dispatch(readNotifications(notification._id));
	};
	useEffect(() => {
		refetch();
	}, [offset]);

	const renderMission = mission => {
		if (!mission) return;
		return (
			<div key={mission._id}>
				<div className={styles.mission_title}>
					<div className={styles.mission_title_divider} />
					<span className={styles.mission_title_text}>{mission.title}</span>
					<div className={styles.mission_title_divider} />
				</div>
				<div>
					<Scrollbars
						autoHide
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={240}
					>
						{renderNotifications(mission.notifications)}
					</Scrollbars>
				</div>
			</div>
		);
	};

	const renderNotifications = notifications => {
		return notifications.map((notification, index) => {
			return (
				<React.Fragment key={notification._id}>
					{!!index && <div className={styles.notif_item_divider} />}
					<NotificationRow
						onRead={notif => readNotification(notif)}
						notification={notification}
					/>
				</React.Fragment>
			);
		});
	};

	const handlePageClick = selected => {
		const newOffset = data.pagination.size * selected;
		setOffset(newOffset);
	};

	return (
		<div>
			<div className={styles.collapsible}>
				<div className={styles.collapsible_header}>
					<div className={styles.title}>
						<span>Missions notifications</span>
						{!!data?.data?.length && (
							<div className={styles.badge}>{missionNotificationCount}</div>
						)}
					</div>
				</div>
				<div className={styles.collapsible_divider} />
				<div className={styles.collapsible_content}>
					{data?.data &&
						data?.data?.length !== 0 &&
						data?.data?.map(mission => renderMission(mission))}
					{data?.data && data?.data?.length === 0 && (
						<Box my={24}>
							<NotificationsCenterEmptyBlock height="auto" />
						</Box>
					)}
				</div>
			</div>

			{data?.data && data?.pagination?.total !== 0 && (
				<PaginationNotificationsWrapper>
					<Pagination
						total={data?.pagination?.total}
						size={data?.pagination?.size}
						handlePageClick={handlePageClick}
						initialSelected={data?.pagination?.offset / data?.pagination?.size}
						previousLabel={<ChevronLeft />}
						nextLabel={<ChevronRight />}
					/>
				</PaginationNotificationsWrapper>
			)}
		</div>
	);
};

export default MissionNotifications;
