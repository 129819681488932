import React, { useMemo } from "react";
import { queryCache } from "react-query";
import { listCountKey } from "modules/home/api/useListCount.js";
import useOverdueJobsList from "../../../api/UseOverdueJobs";
import usePermanentStore from "../../../zustand/PermanentStore";
import { RenderDate } from "../../../ui";
import RenderStatus from "modules/home/ui/renderStatus/RenderStatus";
import { JOBS_OVERDUE } from "modules/home/config";
import { WELCOME_PAGE } from "config";
import { historyPush } from "config/helpers";
import PermanentTable from "modules/home/components/permanent/PermanentTable/PermanentTable";
const OverdueJobs = ({ handleViewAll }) => {
	const {
		permanentStartDate,
		permanentEndDate,
		permanentType,
		setItemPermanent
	} = usePermanentStore();
	const { data, isLoading } = useOverdueJobsList(
		{
			start_date:
				permanentStartDate && permanentStartDate.isValid()
					? permanentStartDate.unix()
					: undefined,
			end_date:
				permanentEndDate && permanentEndDate.isValid()
					? permanentEndDate.unix()
					: undefined
		},
		{
			enabled: permanentType === JOBS_OVERDUE,
			onSuccess: res => {
				queryCache.invalidateQueries(listCountKey);
				setItemPermanent(
					JOBS_OVERDUE,
					res.data.map(item => item._id)
				);
			}
		}
	);
	const overdueJobsColumns = useMemo(
		() => [
			{
				Header: "Due date",
				accessor: "due_date",
				Cell: props => {
					return <RenderDate date={props.cell.value} />;
				}
			},
			{
				Header: "Vacancy name",
				accessor: "title"
			},
			{
				Header: "Applications",
				accessor: "count_applications",
				isNumber: true
			},
			{
				Header: "Shortlist",
				accessor: "count_shortlists",
				isNumber: true
			},
			{
				Header: "Interview",
				accessor: "count_interviews",
				isNumber: true
			},
			{
				Header: "Offer",
				accessor: "count_offers",
				isNumber: true
			},
			{
				Header: "Hired",
				accessor: "count_hired",
				isNumber: true
			},
			{
				Header: "Status ",
				accessor: "status",
				Cell: props => {
					return <RenderStatus data={props.cell.value} />;
				}
			},
			{
				Header: "Ref",
				accessor: "job_ref",
				isNumber: true
			}
		],
		[]
	);
	const onRowClick = row =>
		historyPush(
			`/vacancy/${row._id}?source=${WELCOME_PAGE}&listKey=${JOBS_OVERDUE}`
		);

	return (
		<PermanentTable
			data={data?.data}
			columns={overdueJobsColumns}
			isLoading={isLoading}
			onRowClick={onRowClick}
			titleEmptyState={"You have no overdue job for today!"}
			handleViewAll={handleViewAll}
		/>
	);
};

export default OverdueJobs;
