import React, { useEffect } from "react";
import styles from "./add-timesheet-entries-time-input.module.scss";
import TimePicker from "common/TimePicker";
import { OVERTIME_DURATION } from "config";
import { Item } from "react-stately";
import { ComboBox } from "common/ComboBox";
import { Controller } from "react-hook-form";
import cx from "classnames";
import { HOURLY } from "config";

const AddTimesheetEntriesTimeInput = ({
	control,
	watch,
	timesheet,
	setValue
}) => {
	useEffect(() => {
		if (isHourly && isWorkTime)
			setValue(
				"overtimeDuration",
				OVERTIME_DURATION.find(({ value }) => "" + value === "custom")
			);
	}, [timesheet]);

	const onSelectionChange = (key, onChange) => {
		const value = {
			label: getValue(key)?.label ?? "",
			value: key
		};

		onChange(value);

		const splitedDayHours = timesheet?.data?.reporting_settings?.hours_representation.split(
			":"
		);
		const isHoursOdd = Number(splitedDayHours[0]) % 2 === 1;

		const addedMinutes = isHoursOdd && value.value == 0.5 ? 30 : 0;

		var amountOfTime = window.moment().utcOffset(0);
		amountOfTime.set({
			hour: value.value * Number(splitedDayHours[0]),
			minute: value.value * Number(splitedDayHours[1]) + addedMinutes,
			second: 0,
			millisecond: 0
		});
		amountOfTime.toISOString();
		amountOfTime.format();
		if (value.value !== "custom") setValue("time", amountOfTime);
		else setValue("time", window.moment("00", "hh"));
	};

	const getValue = chosenId => {
		return OVERTIME_DURATION.find(({ value }) => "" + value === chosenId);
	};

	const onInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("overtimeDuration")?.value
		};

		onChange(valueObject);
	};

	const isWorkTime = watch("type")?.value === "worktime";
	const isHourly = timesheet?.data?.reporting_settings?.rate_type === HOURLY;

	return (
		<div
			className={cx(
				{ [styles.container]: isWorkTime && !isHourly },
				{ [styles.container2]: !isWorkTime }
			)}
		>
			{isWorkTime && !isHourly && (
				<>
					<Controller
						name="overtimeDuration"
						control={control}
						render={({ field: { onChange, value } }) => {
							return (
								<ComboBox
									inputRootClassName={styles.comboxBoxInput}
									inputValue={value?.label}
									selectedKey={value?.value}
									onSelectionChange={key => onSelectionChange(key, onChange)}
									onInputChange={value => onInputChange(value, onChange)}
								>
									{OVERTIME_DURATION.map(item => {
										return <Item key={item.value}>{item.label}</Item>;
									})}
								</ComboBox>
							);
						}}
					/>
					<div className={styles.separator} />
				</>
			)}
			<Controller
				name="time"
				control={control}
				render={({ field: { onChange, value } }) => (
					<TimePicker
						name="hours"
						onChange={onChange}
						value={value}
						showSeparator={false}
						rootClassName={styles.timePicker}
						isReadonly={
							watch("overtimeDuration")?.value !== "custom" && isWorkTime
						}
					/>
				)}
			/>
		</div>
	);
};

export default AddTimesheetEntriesTimeInput;
