import React, { memo, useState } from "react";
import { Paper } from "common/Paper";
import { CategorySelectPopoverTrigger } from "./CategorySelectPopoverTrigger";
import Menu from "./Menu/menu";
import SubMenu from "./SubMenu/SubMenu";
import styles from "./category-select.module.scss";

const CategorySelect = ({ value, menu, onChange }) => {
	const [displayPopover, setDisplayPopover] = useState(false);
	const [displaySubMenu, setDisplaySubMenu] = useState(false);
	const [children, setChildren] = useState([]);
	const [parent, setParent] = useState(null);

	const onClose = () => {
		if (displaySubMenu) {
			setDisplaySubMenu(false);
		}
		if (displayPopover) {
			setDisplayPopover(false);
		}
	};

	const handleHover = id => {
		const item = menu.find(item => item.id === id);
		if (item?.children?.length) {
			setDisplaySubMenu(true);
			setChildren(item.children);
			setParent(item);
		} else {
			setDisplaySubMenu(false);
		}
	};

	const handleMenuItemClick = item => {
		onChange({ ...item, parent });
		setDisplaySubMenu(false);
		setDisplayPopover(false);
		setParent(null);
	};

	const onCategoryClick = category => {
		onChange(category);
		setDisplayPopover(false);
	};

	const onInputClick = () => {
		setDisplayPopover(true);
	};

	return (
		<CategorySelectPopoverTrigger
			isOpen={displayPopover}
			value={value}
			label="Open Popover"
			onInputClick={onInputClick}
			onClose={onClose}
			inputClassName={displayPopover ? styles.input : ""}
		>
			<Paper>
				<Menu
					menu={menu}
					onMenuItemHover={handleHover}
					onCategoryClick={onCategoryClick}
				/>
				{displaySubMenu && (
					<Paper className={styles.paper}>
						<SubMenu onItemClick={handleMenuItemClick} items={children} />
					</Paper>
				)}
			</Paper>
		</CategorySelectPopoverTrigger>
	);
};

export default memo(CategorySelect);
