import React from "react";
import styled from "styled-components";
import { OptionButton } from "./ButtonAnswer";
import { colors } from "config/styles";

const AnswersContainer = styled.span`
	position: relative;
	.wrapper {
		padding: 10px;
		position: absolute;
		min-width: 360px;
		background-color: ${colors.white};
		margin-bottom: 50px;
		border: solid 1px ${colors.depSelect};
		border-radius: 4px;
		box-shadow: 0 0 7.4px 0.1px rgba(218, 224, 242, 0.48);
		z-index: 99;
	}
`;

const answersRef = React.createRef();

const DropdownAnswer = ({
	group,
	disabled,
	answers,
	selected,
	handleChange,
	isOpen,
	setSelected
}) => {
	const [computedStyle, setComputedStyle] = React.useState({
		top: 50,
		left: 5
	});

	React.useEffect(() => {
		const container = document.querySelector(".chatbot__container");
		if (!container || !answersRef.current) return;
		setTimeout(() => {
			const containerBounds = container.getBoundingClientRect();
			const dropdownBounds = answersRef.current.getBoundingClientRect();
			if (containerBounds.width < dropdownBounds.x + dropdownBounds.width) {
				setComputedStyle({ top: 50, right: 5 });
			}
		}, 0);
	}, [isOpen]);

	return (
		<AnswersContainer>
			<OptionButton
				onClick={() => (!isOpen ? setSelected(group) : setSelected(""))}
				className="group-container"
				active={isOpen}
				disabled={disabled}
			>
				{group}
				{isOpen ? (
					<i className="fa fa-angle-up" />
				) : (
					<i className="fa fa-angle-down" />
				)}
			</OptionButton>
			{isOpen && (
				<div ref={answersRef} className="wrapper" style={{ ...computedStyle }}>
					{answers.map(opt => (
						<OptionButton
							key={opt._id || opt.value}
							onClick={
								disabled
									? null
									: () =>
											handleChange({
												value: opt._id || opt.value,
												name: opt.name || opt.label
											})
							}
							disabled={disabled}
							active={
								selected &&
								(opt.name || opt.label) === (selected.name || selected.label)
							}
						>
							{opt.name || opt.label}
						</OptionButton>
					))}
				</div>
			)}
		</AnswersContainer>
	);
};

export default DropdownAnswer;
