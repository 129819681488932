import create from "zustand";
import { SINGLE_CANDIDATE_TAB_ID, UPLOAD_ID } from "../utils/constants";

const useGetFiles = create(set => ({
	activeTab: SINGLE_CANDIDATE_TAB_ID,
	setActiveTab: tab => set({ activeTab: tab }),
	uploadedFile: {},
	addUrlToUploadedFile: (_url, file, id) =>
		set(() => {
			const newFile = { file, url: _url, id, _isUploaded: true };
			return { uploadedFile: newFile };
		}),
	clearSingleCandidate: () => set({ uploadedFile: {}, formValues: {} }),
	removeUploadedFile: () => set({ uploadedFile: {} }),
	formValues: {},
	setFormValues: formValues => set({ formValues }),
	files: [],
	clearFiles: () => set({ files: [] }),
	addFiles: newFiles =>
		set(state => {
			return { files: [...state.files, ...newFiles] };
		}),
	removeFile: id =>
		set(state => {
			const newFiles = state.files.filter(file => file._id !== id);
			return { files: newFiles };
		}),
	addUrlToFile: (id, _url) =>
		set(state => {
			const newFiles = state.files.map(file =>
				file._id === id ? { ...file, _url } : file
			);
			return { files: newFiles };
		}),

	drawerActiveBody: UPLOAD_ID,
	setDrawerActiveBody: body => set({ drawerActiveBody: body }),

	displayCreateCandidateDrawer: false,
	setDisplayCreateCandidateDrawer: displayCreateCandidateDrawer =>
		set({ displayCreateCandidateDrawer: displayCreateCandidateDrawer }),

	parcels: [],
	setParcels: parcels => set({ parcels: parcels }),

	currentParcel: {},
	setCurrentParcel: parcel => set({ currentParcel: parcel }),

	currentParcelId: null,
	setCurrentParcelId: id => set({ currentParcelId: id }),

	candidateReferences: [],
	setCandidateReferences: candidateReferences => set({ candidateReferences }),

	displayCreateCandidate: false,
	setDisplayCreateCandidate: displayCreateCandidate =>
		set({ displayCreateCandidate: displayCreateCandidate })
}));

export default useGetFiles;
