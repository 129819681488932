import { useEditVacancyApi } from "./useEditVacancyApi";

export const useEditRequestProfileInformations = isActive => {
	const [mutate, { isLoading }] = useEditVacancyApi();

	const editRequest = (
		{
			site,
			department,
			skills,
			languages,
			category,
			subCategory,
			_function,
			seniority
		} = {},
		vacancyId,
		options
	) => {
		const body = {
			category: category.label,
			subcategory: subCategory.label,
			function: _function.label,
			seniority: seniority.value,
			languages: formatLanguagesList(languages),
			skills: formatSkillsList(skills),
			step: 1,
			group_id: department?.value,
			job_type: "request",
			id: vacancyId
		};

		let bodyUpdated;

		if (isActive) {
			bodyUpdated = {
				...body,
				site_id: site.value
			};
		} else {
			bodyUpdated = body;
		}

		mutate(bodyUpdated, options);
	};

	return [editRequest, isLoading];
};

const formatSkillsList = list => {
	return list.map(({ mustHave, name, _id, proficiency }) => {
		return {
			must_have: mustHave,
			name: name,
			_id: _id,
			score: proficiency
		};
	});
};

const formatLanguagesList = list => {
	return list.map(item => {
		return {
			_id: item.language.value,
			score: item.proficiency,
			name: item.language.label
		};
	});
};
