import React from "react";
import PropTypes from "prop-types";
import { TYPE_PDF } from "config";
import { Button } from "../../../common/styled/buttons";

const isPDF = filetype => {
	return filetype === TYPE_PDF;
};

const WebcamSuccess = ({ files, onProceed }) => {
	return (
		<div className="webcam-success">
			<ul>
				{files.map(file => (
					<li key={file.name}>
						<label>{file.name}</label>
						{isPDF(file.filetype) ? (
							<div className="pdf-style">
								<div className="pdf-icon">
									<span className="icon-pdf">
										<span className="path1" />
										<span className="path2" />
										<span className="path3" />
										<span className="path4" />
										<span className="path5" />
										<span className="path6" />
										<span className="path7" />
										<span className="path8" />
									</span>
								</div>
								<div className="pdf-name">{file.filename}</div>
							</div>
						) : (
							<img src={file.file} alt={file.filename} />
						)}
					</li>
				))}
			</ul>
			<div className="actions">
				<Button medium small onClick={onProceed}>
					Complete account creation
				</Button>
			</div>
		</div>
	);
};

WebcamSuccess.propTypes = {
	files: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			file: PropTypes.string.isRequired
		})
	).isRequired,
	onProceed: PropTypes.func.isRequired
};

WebcamSuccess.defaultProps = {
	files: [],
	onProceed: () => {}
};

export default WebcamSuccess;
