import React, { useCallback, useRef, memo } from "react";
import styles from "./custom-header.module.scss";
import ArrowDown from "../../icons/ArrowDown";
import SortUpGreen from "../../icons/SortUpGreen";
import SortDownGreen from "../../icons/SortDownGreen";
import FilterGreen from "../../icons/FilterGreen";
import SixDots from "../../icons/SixDots";
import { useFilters } from "../../../FilterDrawer/hooks/useFilter";
import find from "lodash/find";
import findKey from "lodash/findKey";
import { isEmpty } from "common/FilterDrawer/render_utils";
import { useFilters as useSmartFilter } from "common/SmartFilterDrawer/hooks/useFilter";
import cx from "classnames";

const CustomHeader = props => {
	const { displayName, column } = props;
	const isPageSearchCandidate =
		window.location.pathname === "/candidates/search";
	const oldFilter = useFilters();
	const smartFilter = useSmartFilter();

	const { filters, sortBy, smartQuerySchema } = isPageSearchCandidate
		? smartFilter.getState(column.colDef.currentFilterId)
		: oldFilter.getState(column.colDef.currentFilterId);

	const refButton = useRef(null);

	const filteredValue = () => {
		let newfilters =
			isPageSearchCandidate && smartQuerySchema.length > 0
				? smartQuerySchema
				: filters;

		return (
			find(newfilters, item => {
				return (
					findKey(column.colDef.columnFiltersMap, el => el === item.name) ===
					column.colId
				);
			}) ?? null
		);
	};

	const sortedValue =
		findKey(column.colDef.columnSortMap, el => el === sortBy?.field) ===
		column.colId
			? sortBy.order
			: "";

	const onMenuClicked = useCallback(() => {
		props.showColumnMenu(refButton.current);
	}, [props.showColumnMenu, refButton.current]);

	const isMovable = !column.colDef.suppressMovable;
	const { noActions } = column.colDef;

	return noActions ? (
		<span className={cx(styles.title, styles.marginLeft)}>{displayName}</span>
	) : (
		<>
			{displayName && (
				<div className={styles.headerColumnContainer}>
					<div className={styles.dropDownDisplay} ref={refButton} />
					<div className={styles.headerContainer}>
						{isMovable && (
							<SixDots width="1rem" height="1rem" color={"#525252"} />
						)}
						<span className={isMovable ? "" : styles.marginLeft}>
							{displayName}
						</span>
					</div>
					<div className={styles.iconsActionsContainer}>
						{sortedValue === "asc" && <SortUpGreen width={16} height={16} />}
						{sortedValue === "desc" && <SortDownGreen width={16} height={16} />}
						{filteredValue() && !isEmpty(filteredValue().value) && (
							<FilterGreen width={16} height={16} />
						)}

						<div onClick={onMenuClicked}>
							<ArrowDown width={16} height={16} stroke="1.5" color="#525252" />
						</div>
					</div>
				</div>
			)}{" "}
		</>
	);
};

export default memo(CustomHeader);
