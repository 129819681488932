import React, { useEffect, useState } from "react";
import get from "lodash/get";
import find from "lodash/find";
import useGetCommunityCriteria from "modules/SearchCandidate/api/useGetCommunityCriteria";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import SectionsMenu from "common/SectionsMenu/SectionsMenu";
import { CANDIDATES_FILTER } from "modules/SearchCandidate/utils/constants";
import { formatAllFilters } from "common/SmartFilterDrawer/utils/format_utils";
import { reverseSmartQueryParser } from "common/SmartFilterDrawer/utils/helpers";
import { ReactComponent as ArrowUp } from "static/icons/arrow-up-right.svg";
import useAdvancedFilterDrawerTabs from "common/SmartFilterDrawer/hooks/useAdvancedFilterDrawerTabs";
import { TABS_IDS } from "common/SmartFilterDrawer/utils/constant";
import styles from "./styles.module.scss";

const CommunityCriteria = () => {
	const [search, setSearch] = useState("");
	const [newData, setNewData] = useState([]);
	const {
		setSelectedCriteria,
		setFilters,
		setSource,
		getState,
		setSmartQuery
	} = useFilters();

	const { columns, selectedCriteria } = getState(CANDIDATES_FILTER);
	const { data, isLoading } = useGetCommunityCriteria({
		search: search,
		tag: "candidate_search"
	});
	const { setShowDrawer } = useFilters();
	const { setActiveTab } = useAdvancedFilterDrawerTabs();
	const handleNewFilterClick = () => {
		setActiveTab(TABS_IDS.SAVED_FILTERS);
		setShowDrawer(true);
	};

	const selectCriteriaHandler = async (id, section) => {
		const section_arr = find(data, el => get(el, "type") === section, []);
		const criteriaObject = find(section_arr?.data, el => get(el, "_id") === id);
		const smartQuery = get(criteriaObject, "smart_query_filter", null);
		if (smartQuery) {
			let smartQueryFilter = reverseSmartQueryParser(smartQuery);
			const formatted_filters = await formatAllFilters(
				columns,
				smartQueryFilter
			);
			setSmartQuery(formatted_filters, id);
		} else if (get(criteriaObject, "fields", [])) {
			const formatted_filters = await formatAllFilters(
				columns,
				get(criteriaObject, "fields", [])
			);
			setFilters(formatted_filters);
		}
		const source = criteriaObject?.source || "all";
		setSource(source);
		setSelectedCriteria({
			_id: get(criteriaObject, "_id"),
			name: get(criteriaObject, "name")
		});
	};

	useEffect(() => {
		if (data && selectedCriteria) {
			const index = data.findIndex(item => item.type === "vacancies");
			if (index !== -1) {
				if (get(selectedCriteria, "type") === "vacancy") {
					if (!data[index].data.find(el => el._id === selectedCriteria._id)) {
						data[index].data.unshift({
							_id: selectedCriteria._id,
							name: selectedCriteria.name,
							fields: selectedCriteria.fields
						});
					}
				}
			}
		}
		setNewData(data);
	}, [data, selectedCriteria]);

	return (
		<>
			<SectionsMenu
				selectedElement={selectedCriteria}
				setSelectedElement={(id, section) => selectCriteriaHandler(id, section)}
				onSearch={setSearch}
				data={newData}
				isLoading={isLoading}
				renderViewAllButton={() => (
					<button
						onClick={handleNewFilterClick}
						className={styles.viewAllContainer}
					>
						<div>View All</div>
						<ArrowUp />
					</button>
				)}
			/>
		</>
	);
};

export default CommunityCriteria;
