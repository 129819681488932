export const DETAILS_TAB_ID = "0";
export const RESUME_TAB_ID = "1";
export const NOTES_TAB_ID = "2";

export const TABS = [
	{
		id: DETAILS_TAB_ID,
		label: "Bid details"
	},
	{
		id: RESUME_TAB_ID,
		label: "Resume (CV)"
	},
	{
		id: NOTES_TAB_ID,
		label: "Notes"
	}
];

export const getClientBidViewRoute = (bidId, requestId, params = "") => {
	return `/job/${requestId}?bid_id=${bidId}${params}`;
};
