import React from "react";
import Modal from "common/modal";
import styles from "./styles.module.scss";
import { Controller } from "react-hook-form";
import { CustomInput } from "common/CustomInput";
import generalSettingsStyles from "../../styles.module.scss";
import { useChangePasswordForm } from "../../hooks/useChangePasswordForm";
import { useUpdatePassword } from "modules/user/api/useUpdatePassword";
import { ReactComponent as IconClose } from "static/icons/IconClose.svg";
import _get from "lodash/get";

const ChangePassword = ({ onClose }) => {
	const {
		control,
		handleSubmit,
		isSubmitting,
		formState: { errors }
	} = useChangePasswordForm();

	const [updatePassword, { isLoading }] = useUpdatePassword(onClose);
	const onSubmit = data => updatePassword(data);

	const getInputClassName = name =>
		`${styles.form__field} ${errors[name] ? styles.form__field_error : ""}`;

	return (
		<Modal
			size={"500px"}
			padding={"24px"}
			onClose={onClose}
			loading={isLoading}
			borderRadius={"12px"}
			border={"1px solid #CBD5E1"}
			backgroundColor={"rgba(0,0,0,0.25)"}
			loaderStyle={{ borderTopColor: "#00857a" }}
			boxShadow={
				"0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
			}
			customCloseBtnRender={onClose => (
				<div className={styles.modal__close} onClick={onClose}>
					<IconClose />
				</div>
			)}
		>
			<div className={styles.title}>Change password</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.form__fieldsContainer}>
					<div className={getInputClassName("old_password")}>
						<label>Current password</label>
						<Controller
							control={control}
							name="old_password"
							render={({ field }) => <CustomInput {...field} type="password" />}
						/>
						{_get(errors, "old_password") && (
							<span>{_get(errors, "old_password.message")}</span>
						)}
					</div>
					<div className={getInputClassName("password")}>
						<label>New Password</label>
						<Controller
							name="password"
							control={control}
							render={({ field }) => <CustomInput {...field} type="password" />}
						/>
						{_get(errors, "password") && (
							<span>{_get(errors, "password.message")}</span>
						)}
					</div>
					<div className={getInputClassName("confirm_password")}>
						<label>Confirm Password</label>
						<Controller
							control={control}
							name="confirm_password"
							render={({ field }) => <CustomInput {...field} type="password" />}
						/>
						{_get(errors, "confirm_password") && (
							<span>{_get(errors, "confirm_password.message")}</span>
						)}
					</div>
				</div>
				<div className={styles.form__buttons}>
					<button
						type="button"
						onClick={onClose}
						className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__link}`}
					>
						Cancel
					</button>
					<button
						type="submit"
						disabled={isSubmitting || isLoading}
						className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__green}`}
					>
						Confirm
					</button>
				</div>
			</form>
		</Modal>
	);
};
export default ChangePassword;
