import React from "react";
import styles from "./parcel-list-loading-state.module.scss";
import Skeleton from "react-loading-skeleton";

function ParcelListLoadingState() {
	return (
		<div className={styles.loadingList}>
			{Array(5)
				.fill(0)
				.map((item, index) => (
					<div className={styles.progressContainer} key={index}>
						<Skeleton className={styles.description} />
						<Skeleton className={styles.icon} />
					</div>
				))}
		</div>
	);
}

export default ParcelListLoadingState;
