import React, { Component } from "react";
import Tooltip from "rc-tooltip";
import "../../styles/css/hireme/customHandleStyles.css";

const handleStyle = {};

class CustomHandle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isTooltipVisible: false
		};
	}

	showTooltip() {
		this.setState({
			isTooltipVisible: true
		});
	}

	hideTooltip() {
		this.setState({
			isTooltipVisible: false
		});
	}

	clickFocus = () => {};

	render() {
		const style = Object.assign({ left: `${this.props.offset}%` }, handleStyle);
		const {
			prefixCls = "rc-slider",
			tooltipPrefixCls,
			tipTransitionName,
			tipFormatter,
			value,
			dragging,
			noTip
		} = this.props;

		const handle = (
			<div
				style={style}
				className="handle"
				onMouseUp={this.showTooltip.bind(this)}
				onMouseEnter={this.showTooltip.bind(this)}
				onMouseLeave={this.hideTooltip.bind(this)}
			>
				<div className="handle-middle" />
			</div>
		);
		if (noTip) {
			return handle;
		}
		const isTooltipVisible = dragging || this.state.isTooltipVisible;
		return (
			<Tooltip
				prefixCls={tooltipPrefixCls || `${prefixCls}-tooltip`}
				placement="top"
				visible={isTooltipVisible}
				overlay={<span>{tipFormatter(value)}</span>}
				delay={0}
				transitionName={tipTransitionName}
			>
				{handle}
			</Tooltip>
		);
	}
}

export default CustomHandle;
