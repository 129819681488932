import React from "react";
import styles from "./posting-requirements-cascader.module.scss";
import _find from "lodash/find";
import Cascader from "rc-cascader";
import cx from "classnames";
import { formatHieOptions } from "modules/publishJob/helper/helper";
import useGetAutoCompleteFieldData from "modules/publishJob/api/useGetAutocompleteFieldData";

const PostingRequirementsCascader = ({
	errorMessage,
	options,
	label,
	onFieldChange,
	value,
	onChange,
	required,
	channel,
	name,
	fieldId
}) => {
	const { data } = useGetAutoCompleteFieldData(
		{
			term: typeof fieldId === "string" ? fieldId : "",
			product_id: channel.contract_id || channel.channel_id,
			posting_name: name,
			credentials: {}
		},
		{
			enabled: options.length === 0
		}
	);

	let formattedValue = "Select...";
	if (value) {
		formattedValue =
			options.length > 0
				? _find(options, ["key", value?.at(-1)]).label
				: _find(data, ["key", value?.at(-1)]).label;
	}

	return (
		<div className={cx("category item", styles.root)}>
			<label className={styles.label}>
				{label} {required && <span className={styles.red}>*</span>}
			</label>
			<Cascader
				className={errorMessage && styles.redBorder}
				options={
					options.length > 0
						? formatHieOptions(options)
						: formatHieOptions(data || [])
				}
				onChange={selectedValue => {
					onChange(selectedValue);
					onFieldChange(name);
				}}
				value={value}
				expandTrigger="hover"
			>
				<div className={styles.cascaderValue}>
					<span>{formattedValue}</span>
				</div>
			</Cascader>
			<div className={styles.errorMessage}>{errorMessage}</div>
		</div>
	);
};

export default PostingRequirementsCascader;
