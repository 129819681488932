import React, { useEffect, useState } from "react";
import cx from "classnames";
import { ReactComponent as GreenCircle } from "static/icons/green-circle.svg";
import { ReactComponent as RedCircle } from "static/icons/red-circle.svg";
import { ReactComponent as ExternalLink } from "static/icons/external-link.svg";
import { ReactComponent as ExternalLinkDisabled } from "static/icons/external-link-disabled.svg";
import { DropDownWithIcon } from "common/DropDownWithIcon";
import styles from "./page-card.module.scss";
import { Button } from "common/Button";
import { ReactComponent as CopyIcon } from "static/icons/copy-icon.svg";
import { ReactComponent as EditIcon } from "static/icons/edit-icon.svg";
import { formatDate, getFullName } from "config/helpers";
import { CustomAvatar } from "common/CustomAvatar";
import CopyTooltip from "rc-tooltip";
import Tooltip from "common/Tippy";
import { CustomInput } from "common/CustomInput";
import { Picker } from "common/Picker";
import get from "lodash/get";
import { isEmpty } from "common/FilterDrawer/render_utils";
import useUpdateSite from "../api/useUpdateSite";
import toast from "react-hot-toast";
import * as copyToClipboard from "copy-to-clipboard";
import { queryCache } from "react-query";
import { FETCH_PAGE_SETTINGS_KEY } from "../api/useGetPagesSettings";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { ADMIN } from "config";

const ONLINE = "online";
const OFFLINE = "offline";
const options = [
	{ label: "Online", value: ONLINE, icon: <GreenCircle /> },
	{ label: "Offline", value: OFFLINE, icon: <RedCircle /> }
];

const formatCollaborators = collaborators => {
	if (collaborators.length > 0) {
		return collaborators.map(user => {
			return {
				fullName: `${get(user, "first_name")} ${get(user, "last_name")}`,
				label: `${get(user, "first_name")} ${get(user, "last_name")}`,
				avatar: get(user, "avatar"),
				_id: get(user, "_id"),
				id: get(user, "_id")
			};
		});
	}
	return [];
};

const PageCard = ({
	className,
	title = "Career page",
	data,
	url,
	originalUrl,
	feature_name,
	site_id,
	hasMultiSite,
	tourData = null // onboarding data
}) => {
	const [copyText, setCopyText] = useState("Copy link");
	const [isLinkRead, setIsLinkRead] = useState(true);
	const [subdomain, setSubdomain] = useState();
	const [collaborators, setCollaborators] = useState([]);
	const [allCollaborators, setAllCollaborators] = useState([]);
	const [subdomainSuffix, setSubdomainSuffix] = useState();
	const admin = get(data, "company_owner");
	const status = get(data, "status", "offline");

	const updatedBy = !isEmpty(get(data, "updated_by"))
		? get(data, "updated_by")
		: admin;
	const updateDate =
		get(data, "updated_at", "") !== ""
			? formatDate(get(data, "updated_at"), true)
			: formatDate(get(data, "created_at", ""), true);

	const handleOpenEditorClick = () => {
		if (status === ONLINE) {
			window.open(url, "_blank").focus();
		}
	};

	const user = useSelector(onlineUserSelector);

	const collaboratorId = get(data, "collaborators", []).filter(
		opt => opt?._id === user.id
	);

	useEffect(() => {
		let urlObj = {};
		if (originalUrl) {
			urlObj = new URL(originalUrl);
		}
		const suffix =
			get(urlObj, "hostname", "").replace(get(data, "subdomain", ""), "") +
			get(urlObj, "pathname", "");
		setSubdomain(get(data, "subdomain", ""));
		setSubdomainSuffix(suffix);

		const temp_collaborators = formatCollaborators(
			get(data, "collaborators", [])
		);
		const temp_remaining = formatCollaborators(get(data, "remaining", []));

		setCollaborators(temp_collaborators);
		setAllCollaborators([...temp_collaborators, ...temp_remaining]);
	}, [data]);

	const [mutate] = useUpdateSite({
		onSuccess: (_, body) => {
			toast("Settings updated successfully", {
				duration: tourData?.toasterDuration || 3000
			});
			if (!hasMultiSite) {
				queryCache.invalidateQueries(FETCH_PAGE_SETTINGS_KEY);
			} else {
				queryCache.invalidateQueries("myCurrentSite");
			}

			if ("subdomain" in body && tourData)
				setTimeout(tourData.goLastTourStep, 500);
		},
		onError: (error, body) => {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					toast(
						`${name} : ${
							typeof get(errorMessage, name) === "string"
								? get(errorMessage, name)
								: get(errorMessage, "name[0]")
						}`,
						{ isFailed: true, duration: tourData?.toasterDuration || 3000 }
					);
				});
			} else {
				toast(errorMessage, {
					isFailed: true,
					duration: tourData?.toasterDuration || 3000
				});
			}
			if ("subdomain" in body && tourData)
				setTimeout(tourData.goLastTourStep, 500);
		}
	});

	const handleSiteStatusChange = selectedStatus => {
		const payload = {
			feature_name,
			status: selectedStatus
		};
		if (hasMultiSite) {
			payload.site_id = site_id;
		}

		mutate(payload);
	};

	const handleLinkEditClick = () => {
		setIsLinkRead(false);
	};

	const handleCancelClick = () => {
		setSubdomain(get(data, "subdomain", ""));
		setIsLinkRead(true);
	};

	const handleCopy = () => {
		copyToClipboard(url);
		setCopyText("Copied!");
		setTimeout(() => {
			setCopyText("Copy link");
		}, 1500);
	};

	const handleSaveClick = () => {
		if (subdomain.trim() && subdomain.trim() !== get(data, "subdomain", "")) {
			const payload = {
				feature_name,
				subdomain: subdomain.trim()
			};
			if (hasMultiSite) {
				payload.site_id = site_id;
			}
			mutate(payload);
		} else if (tourData) {
			tourData.goLastTourStep(); // subdomain unchanged
		}
		setIsLinkRead(true);
	};

	const updateSiteHandler = selectedCollaborators => {
		const collaborators = get(
			selectedCollaborators,
			"[0].children",
			[]
		).map(v => get(v, "_id"));
		const payload = {
			feature_name,
			collaborators
		};
		if (hasMultiSite) {
			payload.site_id = site_id;
		}
		mutate(payload);
	};

	const valueObject = options.find(item => item.value === status);

	// tour onboarding steps props
	const tourStepsProps = (tourData && {
		second: {
			id: tourData.selectors.second
			// className: `${user.active_role !== ADMIN ? styles.statusLabel : ""} ${
			// 	tourData.selectorsClassNames.second
			// }`
		},
		third: {
			id: tourData.selectors.third,
			className: `${status ? styles.button : styles.buttonDisabled} ${
				tourData.selectorsClassNames.third
			}`
		},
		four: {
			id: tourData.selectors.four,
			style: { display: "flex", alignItems: "center" },
			className: `${tourData.selectorsClassNames.four}`
		}
	}) || { second: {}, third: {}, four: {} };

	return (
		<div className={cx(styles.container, className)}>
			<div className={styles.header}>
				<div className={styles.title}>{title}</div>
				{user.active_role === ADMIN ? (
					<DropDownWithIcon
						onChange={handleSiteStatusChange}
						options={options}
						value={status}
						disabled={user.active_role !== ADMIN}
						{...tourStepsProps.second}
					/>
				) : (
					<div className={styles.statusLabel} {...tourStepsProps.second}>
						<div className={styles.iconWrapper}>{valueObject.icon}</div>
						<div className={styles.label}>{valueObject.label}</div>
					</div>
				)}
			</div>
			<div className={styles.card}>
				{status === ONLINE ? (
					<div className={styles.buttonWrapper}>
						<Button
							className={status ? styles.button : styles.buttonDisabled}
							text="Open editor"
							icon={
								status === ONLINE ? <ExternalLink /> : <ExternalLinkDisabled />
							}
							variant="outlined"
							onClick={() => {
								if (tourData) tourData.goToFourStep();
								handleOpenEditorClick();
							}}
							disabled={
								status !== ONLINE &&
								(user.active_role !== ADMIN || !collaboratorId)
							}
							{...tourStepsProps.third}
						/>
					</div>
				) : (
					""
				)}
				<div className={styles.row}>
					<div className={cx(styles.label, styles.admin)}>Admin</div>
					<div className={styles.avatarWrapper}>
						<Tooltip content={getFullName(admin)} theme="dark">
							<div className={styles.avatarWrapper}>
								<CustomAvatar
									src={get(admin, "avatar", "")}
									fullName={getFullName(admin)}
									imgClassName={styles.picture}
									avatarClassName={styles.avatar}
									avatarSize={24}
									variant="small"
								/>
							</div>
						</Tooltip>
					</div>
				</div>
				<div className={styles.row}>
					<div className={cx(styles.label, styles.lastEdited)}>Last edited</div>
					<div className={styles.lastEditedContainer}>
						<Tooltip content={getFullName(updatedBy)} theme="dark">
							<div className={styles.avatarWrapper}>
								<CustomAvatar
									src={get(updatedBy, "avatar")}
									fullName={getFullName(updatedBy)}
									imgClassName={styles.picture}
									avatarClassName={styles.avatar}
									avatarSize={24}
									variant="small"
								/>
							</div>
						</Tooltip>
						<div className={styles.lastEditDate}>{updateDate}</div>
					</div>
				</div>

				<div className={styles.row}>
					<div className={cx(styles.label, styles.collaborators)}>
						Collaborators
					</div>
					<div className={styles.collaboratorsContainer}>
						<div className={styles.images}>
							{collaborators.map(({ avatar, _id, fullName }, i) => {
								return (
									<Tooltip key={_id} content={fullName} theme="dark">
										<div className={styles.avatarWrapper}>
											<CustomAvatar
												src={avatar}
												variant="small"
												fullName={fullName}
												rootClassName={cx(styles.collaboratorPicture, {
													[styles.firstCollaboratorPicture]: i === 0
												})}
												imgClassName={cx(styles.picture)}
												avatarClassName={cx(
													styles.avatarWithBorder,
													styles.avatar,
													{
														[styles.firstCollaboratorPicture]: i === 0
													}
												)}
												avatarSize={30}
												rootStyle={{
													zIndex: i
												}}
												avatarStyle={{
													zIndex: i
												}}
											/>
										</div>
									</Tooltip>
								);
							})}
						</div>

						{allCollaborators.length > 0 && user.active_role === ADMIN && (
							<Picker
								PrimaryHeaderContent={() => "collaborators remaining"}
								SecondaryHeaderContent={() => "collaborators selected"}
								displayRating={false}
								displaySelectedValues={false}
								addButtonComponent={props => (
									<EditIcon
										{...props}
										className={styles.editIcon}
										disabled={user.active_role !== ADMIN}
									/>
								)}
								placement={"top"}
								isMultipleSelect={true}
								onConfirm={e => {
									updateSiteHandler(e);
								}}
								queryFn={() => [
									{
										id: 1,
										label: "Collaborators",
										children: allCollaborators
									}
								]}
								value={[
									{
										id: 1,
										label: "Collaborators",
										children: collaborators
									}
								]}
								key={"collaborators"}
								useServerFilter={false}
								inlineChips={true}
							/>
						)}
					</div>
				</div>

				{status === ONLINE && (
					<div className={styles.row}>
						<div className={cx(styles.label, styles.linklabel)}>Link</div>
						{isLinkRead ? (
							<div className={styles.urlRead}>
								<div>
									<a
										className={styles.link}
										href={url}
										target="_blank"
										rel="noopener noreferrer"
									>
										{originalUrl}
									</a>
								</div>

								{user.active_role === ADMIN && (
									<div {...tourStepsProps.four}>
										<EditIcon
											onClick={() => {
												if (tourData) tourData.closeTour();
												handleLinkEditClick();
											}}
											className={styles.editIcon}
											disabled={user.active_role !== ADMIN}
										/>
									</div>
								)}

								<CopyTooltip
									placement="top"
									theme="dark"
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									overlayClassName={"employer_branding_tooltip"}
									overlay={<div>{copyText}</div>}
								>
									<CopyIcon className={styles.copyIcon} onClick={handleCopy} />
								</CopyTooltip>
							</div>
						) : (
							<div
								className={styles.urlEdit}
								style={tourData?.interactiveContainerStyles || {}}
							>
								<div className={styles.domainWrapper}>
									<CustomInput
										value={subdomain}
										onChange={e => setSubdomain(e.target.value)}
										className={styles.inputSubDomainInput}
									/>
									<div className={styles.rootDomain}>{subdomainSuffix}</div>
								</div>

								<div className={styles.buttonsWrapper}>
									<Button
										className={styles.linkButtons}
										variant="outlined"
										text="Save"
										onClick={handleSaveClick}
									/>
									<Button
										className={styles.linkButtons}
										variant="text"
										text="Cancel"
										onClick={() => {
											handleCancelClick();
											if (tourData) tourData.goLastTourStep();
										}}
									/>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default PageCard;
