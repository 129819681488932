import { client } from "lib/api-client";
import { GET_EMAIL_TEMPLATE } from "config/api-endpoints";
import { useQuery } from "react-query";

const getTemplate = (_, body) => {
	return client(GET_EMAIL_TEMPLATE, {
		body: {
			...body
		}
	});
};

export const GET_EMAIL_TEMPLATE_QUERY_KEY = "GET_EMAIL_TEMPLATE_QUERY_KEY";

export function useGetEmailTemplate(body, options) {
	return useQuery([GET_EMAIL_TEMPLATE_QUERY_KEY, body], getTemplate, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
}
