import React from "react";
import { Button } from "common/Button";
import s from "./modal-cancel-button.module.scss";

const ModalCancelButton = ({ onClick, text = "Cancel" }) => {
	return (
		<Button
			text={text}
			variant="outlined"
			onClick={onClick}
			className={s.cancelButton}
			textClassName={s.cancelButtonText}
		/>
	);
};

export default ModalCancelButton;
