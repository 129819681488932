import React, { useState, useRef, useEffect } from "react";
import styles from "./create-request-module.module.scss";
import { CreateRequestStepOne } from "./components/CreateRequestStepOne";
import { CreateRequestStepTwo } from "./components/createRequestStepTwo";
import {
	useGetLastCreatedRequest,
	GET_LAST_CREATED_REQUEST_QUERY_KEY
} from "./api/useGetLastCreatedRequest";
import { PROFILE_STEP, VACANCY_STEP } from "./utils/constants";
import { useGetVacancy } from "modules/vacancy/create-vacancy/api/useGetVacancy";
import { queryCache } from "react-query";
import { GET_VACANCY_QUERY_KEY } from "./api/useGetVacancy";
import { useSetCreateRequestBreadcrumb } from "./hooks/useSetCreateRequestBreadcrumb";
import useLayoutElements from "hooks/useLayoutElements";
import { isMultisiteActive } from "hooks/useIsMultisiteActive";
import { SkeletonTheme } from "react-loading-skeleton";
import { CreateRequestHead } from "./components/CreateRequestHead";
import { CreateRequestStepOneLoading } from "./components/CreateRequestStepOneLoading";

export const getCreateRequestRoute = id => {
	return `/jobs/post/profile${
		id !== undefined ? (id.length ? "/" + id : "") : "(/:id)"
	}`;
};

export const getCreateRequestStepTwoRoute = id => {
	return `/jobs/post/request${
		id !== undefined ? (id.length ? "/" + id : "") : "(/:id)"
	}`;
};

export const getCreateRequestPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export const getCreateVacancyPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const CreateRequestModule = ({
	params: { id: vacancyId },
	location: { pathname }
}) => {
	const fetchLastVacancy = useRef(false);
	const [actifStep, setActifStep] = useState(PROFILE_STEP);

	useSetCreateRequestBreadcrumb();
	const { scrollarea } = useLayoutElements();
	const hasMultiSites = isMultisiteActive();
	const { data: vacancyToEdit, isFetching } = useGetVacancy(
		{
			id: vacancyId,
			job_type: "request"
		},
		{ enabled: !!vacancyId }
	);

	const { data: lastVacancy } = useGetLastCreatedRequest({
		enabled: fetchLastVacancy.current,
		job_type: "request"
	});

	useEffect(() => {
		if (pathname.startsWith("/jobs/post/request")) {
			if (vacancyId) {
				setActifStep(VACANCY_STEP);
			} else {
				history.pushState(null, null, "/jobs/post/profile");
			}
		}
	}, []);

	useEffect(() => {
		return () => {
			queryCache.invalidateQueries(GET_LAST_CREATED_REQUEST_QUERY_KEY);
		};
	}, []);

	useEffect(() => {
		scrollarea?.scrollTo({ top: 0, behavior: "smooth" });
	}, [actifStep]);

	const onPreviousClick = () => {
		if (vacancyId) {
			history.pushState(null, null, getCreateRequestRoute(vacancyId));
		} else {
			history.pushState(null, null, "/jobs/post/profile");
		}
		setActifStep(PROFILE_STEP);
	};

	const onProfileSaveSuccess = () => {
		if (vacancyToEdit) {
			queryCache.invalidateQueries(GET_VACANCY_QUERY_KEY);
			history.pushState(null, null, `/jobs/post/request/${vacancyId}`);
		} else {
			history.pushState(null, null, "/jobs/post/request");
			if (fetchLastVacancy.current) {
				queryCache.invalidateQueries(GET_LAST_CREATED_REQUEST_QUERY_KEY);
			} else {
				fetchLastVacancy.current = true;
			}
		}

		setActifStep(VACANCY_STEP);
	};

	const onNext = () => {
		if (vacancyId) {
			history.pushState(null, null, `/jobs/post/request/${vacancyId}`);
		} else {
			history.pushState(null, null, "/jobs/post/request");
		}
		setActifStep(VACANCY_STEP);
	};

	return (
		<SkeletonTheme style={{ borderRadius: "8" }} color="#E5E5E5">
			<CreateRequestHead />
			<div className={styles.container}>
				{actifStep === PROFILE_STEP &&
					(isFetching ? (
						<CreateRequestStepOneLoading hasMultiSites={hasMultiSites} />
					) : (
						<CreateRequestStepOne
							vacancyToEdit={vacancyToEdit || lastVacancy}
							vacancyId={
								lastVacancy?._id || "ea527b7d-ec01-4033-847d-2f1c9fe88091"
							}
							onSuccess={onProfileSaveSuccess}
							onNext={onNext}
							hasMultiSites={hasMultiSites}
						/>
					))}

				<>
					<CreateRequestStepTwo
						actif={actifStep === VACANCY_STEP}
						onPreviousClick={onPreviousClick}
						vacancyId={lastVacancy?._id}
						vacancyToEdit={vacancyToEdit}
						isEdit={!!vacancyId}
					/>
				</>
			</div>
		</SkeletonTheme>
	);
};

export default CreateRequestModule;
