import React from "react";
import styles from "./questionnaire-form-label.module.scss";

const QuestionnaireFormLabel = ({
	isRequired,
	description,
	className = styles.label
}) => {
	return (
		<div className={className}>
			<span
				dangerouslySetInnerHTML={{
					__html:
						description +
						(isRequired ? `<span class=${styles.required}>*</span>` : "")
				}}
			/>
		</div>
	);
};

export default QuestionnaireFormLabel;
