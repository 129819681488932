export const getCustomFieldsManagerRoute = activeTabMenu => {
	return `/settings/custom-fields-manager${
		activeTabMenu !== undefined
			? activeTabMenu
				? `/${activeTabMenu}`
				: ""
			: "(/:activeTabMenu)"
	}`;
};

export const getCustomFieldsManagerRoutePermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
