import cx from "classnames";
import { DropDownInput } from "common/DropDownInput";
import React, { memo, useRef, useState } from "react";
import {
	OverlayContainer,
	useButton,
	useOverlayPosition,
	useOverlayTrigger
} from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import checkIcon from "static/icons/check-icon.svg";
import styles from "./drop-down-with-icon.module.scss";
import Popover from "./Popover/Popover";

const CustomDropDownWithIcon = ({
	value,
	options,
	onChange,
	className,
	id = null,
	disabled = false,
	placeHolder
}) => {
	const [displayPaper, setDisplayPaper] = useState(false);
	const rootElement = useRef();
	let state = useOverlayTriggerState({});

	let triggerRef = React.useRef();
	let overlayRef = React.useRef();

	let { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		state,
		triggerRef
	);

	let { overlayProps: positionProps } = useOverlayPosition({
		targetRef: triggerRef,
		overlayRef,
		placement: "bottom start",
		offset: 5,
		isOpen: displayPaper
	});

	let { buttonProps } = useButton(
		{
			onPress: () => handleInputClick()
		},
		triggerRef
	);

	function handleInputClick() {
		setDisplayPaper(displayPaper => !displayPaper);
	}

	const handleOptionClick = value => {
		onChange(value);
		setDisplayPaper(false);
	};

	const handleClose = () => {
		setDisplayPaper(false);
	};

	const valueObject = options.find(item => item.value === value);

	return (
		<div id={id} ref={rootElement} className={cx(styles.container, className)}>
			<DropDownInput
				content={
					<div className={styles.valueWrapper}>
						{valueObject.icon} <div>{valueObject.label}</div>
					</div>
				}
				arrowDirection={displayPaper ? "down" : "top"}
				className={styles.input}
				isDisabled={disabled}
				{...buttonProps}
				{...triggerProps}
				ref={triggerRef}
				placeHolder={placeHolder}
				variant={"slim"}
			/>

			{displayPaper && !disabled && (
				<OverlayContainer>
					<Popover
						{...overlayProps}
						{...positionProps}
						ref={overlayRef}
						isOpen={displayPaper}
						onClose={handleClose}
						paperStyle={{ minWidth: `${triggerRef.current.offsetWidth}px` }}
					>
						<div className={styles.optionsWrapper}>
							{options.map(({ value: itemValue, label, icon }) => {
								return (
									<div
										key={itemValue}
										onClick={() => handleOptionClick(itemValue)}
										className={styles.option}
									>
										<div className={styles.optionWrapper}>
											<div className={styles.iconWrapper}>{icon}</div>
											<div className={styles.label}>{label}</div>
										</div>

										<div className={styles.iconWrapper}>
											<img
												className={styles.icon}
												src={value === itemValue && checkIcon}
											/>
										</div>
									</div>
								);
							})}
						</div>
					</Popover>
				</OverlayContainer>
			)}
		</div>
	);
};
CustomDropDownWithIcon.displayName = "CustomDropDownWithIcon";
export default memo(CustomDropDownWithIcon);
