import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";

export const getAttendees = (connectedUser, requestId) => {
	return new Promise((resolve, reject) => {
		client(FILTER_SETTINGS_ENDPOINT, {
			body: {
				tag: "temporary_user_attendees",
				job_id: requestId
			}
		})
			.then(res => {
				const rest = res.list_users.map(({ _id, user_name, avatar }) => ({
					id: _id,
					label: user_name,
					avatar
				}));

				const user = {
					id: connectedUser.id,
					label: `${connectedUser.first_name} ${connectedUser.last_name}`,
					avatar: connectedUser.avatar
				};

				resolve([{ id: 1, label: "Collaborators", children: [user, ...rest] }]);
			})
			.catch(e => reject(e));
	});
};
