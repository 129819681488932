import classNames from "classnames";
import Tooltip from "common/Tippy";
import get from "lodash/get";
import React, { useRef } from "react";
import FiltersRenderer from "../../FiltersRenderer/FiltersRenderer";
import style from "./styles.module.scss";

const SectionRow = ({
	data,
	rowSelected,
	deleteClicked,
	selectedcriteriaId
}) => {
	const rowRef = useRef();
	const colRef = useRef();
	return (
		<div
			className={classNames(style.row, {
				[style.selected]: selectedcriteriaId === get(data, "_id")
			})}
			onClick={() => rowSelected(data)}
			ref={rowRef}
		>
			<div className={style.col}>
				<Tooltip content={data.name} theme="dark" overflow="hidden">
					<p ref={colRef} className={style.bold}>
						{data.name}
					</p>
				</Tooltip>
			</div>
			<div className={style.col}>
				<FiltersRenderer
					canDelete={!get(data, "is_default")}
					title={get(data, "name", "")}
					filters={get(data, "fields", [])}
					data_source={get(data, "data_source", "all")}
					created_at={get(data, "created_at", "")}
					deleteClicked={deleteClicked}
					referenceElement={rowRef}
					parent_styles={style}
				/>
			</div>
		</div>
	);
};

export default SectionRow;
