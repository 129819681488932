import React, { useState, useEffect } from "react";
import TimeChooser from "./TimeChooser";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { PlanInterviewContainer, Title } from "../styles";
import Attendees from "./Attendees";
import AddInterviewNote from "./AddNoteToInterview";
import InterviewLocation from "./InterviewLocation";
import { client } from "lib/api-client";
import { useQuery, useMutation, queryCache } from "react-query";
import {
	DECLINE_INTERVIEW,
	FILTER_SETTINGS_ENDPOINT,
	INVITE_USER_TO_INTERVIEW
} from "config/api-endpoints";
import {
	ATTENDEES_LIST_TAG,
	SEARCH_ADDRESS,
	COMPANY_ADDRESS,
	VIDEO_CONFERENCE,
	TIMEZONE,
	IN_DEF_START_TIME,
	IN_DEF_END_TIME,
	DONE,
	CANCELLED
} from "config";
import get from "lodash/get";
import toaster from "common/Toaster";
import { handleAddressObject } from "config/helpers";
import { LoaderFull } from "common/Loader";
import { fetchPoke } from "modules/vacancy/actions";
import { EXPIRED } from "config";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";

const getAttendesList = (_, id) =>
	client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: ATTENDEES_LIST_TAG,
			vacancy_id: id
		}
	});

const inviteUserToInterview = ({ isDeclineInterview, ...data }) => {
	return client(
		isDeclineInterview ? DECLINE_INTERVIEW : INVITE_USER_TO_INTERVIEW,
		{
			body: {
				...data
			}
		}
	);
};

export const getAddressOnly = (data = {}) => {
	return {
		street: data.street || data.company_street,
		number: data.number || data.company_number,
		box: data.box || data.company_box,
		city: data.city || data.company_city,
		zip: data.zip || data.company_zip,
		country: data.country || data.company_country,
		longitude: data.longitude || data.company_longitude,
		latitude: data.latitude || data.company_latitude,
		iso_country: data.iso_country || data.company_iso_country
	};
};

const getIsoCountry = async address => {
	const { address: newAddress } = await handleAddressObject(address, true);
	return newAddress;
};

const PlanInterview = ({
	candidateName = "CANDIDATE_NAME",
	vacancyId,
	companyType,
	authenticatedUser,
	isLogEvent,
	setIsLogEvent,
	closeDrawer,
	isCandidateHasMail,
	openAddMailModal,
	applicationId,
	showAddMailModal,
	isApplicantHasAnOffer,
	interviewId,
	interviewStatus,
	submitFromOutside,
	setSubmitFromOutside,
	isFromVacanciesTable,
	onSuccess
}) => {
	const [date, setDate] = useState();
	const [address, setAddress] = useState(getAddressOnly(authenticatedUser));
	const [addressTypo, setAddressTypo] = useState(false);
	const [addressError, setAddressError] = useState(false);
	const dispatch = useDispatch();
	const { data, isLoading: isLoadingAttendees } = useQuery(
		["attendees-list", vacancyId],
		getAttendesList,
		{
			enabled: Boolean(vacancyId)
		}
	);

	const schema = yup.object().shape({
		time_chooser: yup.object().test("len", "Time period can't be empty", () => {
			if (!date) {
				return false;
			}
			return true;
		}),
		location_type: yup.string().required("Interview location is a required"),
		attendees_users: yup
			.array()
			.test("len", "Attendees can't be empty", val => val.length)
	});

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema),
		defaultValues: {
			time_chooser: {
				startTime: IN_DEF_START_TIME,
				endTime: IN_DEF_END_TIME
			},
			location_type: VIDEO_CONFERENCE,
			description: "",
			attendees_users: [get(authenticatedUser, "id")]
		}
	});
	const { setIsOpen, setApplicationId } = useProfileView();
	const [mutate, { isLoading }] = useMutation(inviteUserToInterview, {
		onSuccess: () => {
			toaster.success("Interview was successfully sent");
			queryCache.invalidateQueries("getDataPipeline");
			openAddMailModal(false);
			dispatch(fetchPoke({ id: applicationId }));
			closeDrawer();
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(applicationId);
			onSuccess?.();
		},
		onError: error => {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					toaster.danger(
						`${name} : ${
							typeof get(errorMessage, name) === "string"
								? get(errorMessage, name)
								: get(errorMessage, name)[0]
						}`
					);
				});
			} else {
				toaster.danger(errorMessage);
			}
		}
	});

	const onChangeAddress = newAddress => {
		if (
			newAddress.country !== get(address, "country") ||
			newAddress.city !== get(address, "city")
		) {
			if (
				newAddress.longitude === get(address, "city") ||
				newAddress.latitude === get(address, "latitude")
			) {
				newAddress.latitude = 0;
				newAddress.longitude = 0;
			}
		}
		setAddress(newAddress);
	};

	const changeLocationType = value => {
		if (value === SEARCH_ADDRESS) {
			onChangeAddress({
				street: "",
				number: "",
				box: "",
				city: "",
				zip: "",
				country: "",
				longitude: "",
				formatted_address: "",
				latitude: "",
				iso_country: ""
			});
		}
		if (value === COMPANY_ADDRESS || value === VIDEO_CONFERENCE) {
			onChangeAddress({
				street: authenticatedUser.company_street,
				number: authenticatedUser.company_number,
				box: authenticatedUser.company_box,
				city: authenticatedUser.company_city,
				zip: authenticatedUser.company_zip,
				country: authenticatedUser.company_country,
				longitude: authenticatedUser.company_longitude,
				latitude: authenticatedUser.company_latitude,
				iso_country: authenticatedUser.company_iso_country
			});
		}
	};
	const getAddress = () => {
		return `${
			authenticatedUser.company_street ? authenticatedUser.company_street : ""
		} ${
			authenticatedUser.company_number ? authenticatedUser.company_number : ""
		} ${
			authenticatedUser.company_street || authenticatedUser.company_number
				? ","
				: ""
		} ${authenticatedUser.company_zip ? authenticatedUser.company_zip : ""} ${
			authenticatedUser.company_city ? authenticatedUser.company_city : ""
		} ${
			authenticatedUser.company_country ? authenticatedUser.company_country : ""
		}`;
	};
	let attendessList = get(data, "list_users", []);

	if (attendessList) {
		attendessList = [
			...attendessList.map(({ _id, avatar, user_name }) => ({
				_id,
				first_name: user_name,
				avatar
			})),
			{
				_id: authenticatedUser.id,
				first_name: authenticatedUser.first_name,
				last_name: authenticatedUser.last_name,
				avatar: authenticatedUser.avatar
			}
		];
	}

	const onSubmit = async data => {
		if (submitFromOutside) {
			setSubmitFromOutside(false);
		}
		if (!showAddMailModal && !isLogEvent && !isCandidateHasMail)
			return openAddMailModal(true);

		const isDeclineInterview =
			interviewStatus !== CANCELLED &&
			interviewStatus !== DONE &&
			interviewStatus !== EXPIRED &&
			isApplicantHasAnOffer;

		const {
			description,
			attendees_users,
			time_chooser: { startTime, endTime },
			location_type
		} = data;
		let payload = {
			description,
			attendees_users,
			location_type
		};

		const startDate = window.moment(date.clone()).tz(TIMEZONE);
		const endDate = window.moment(date.clone()).tz(TIMEZONE);
		const [startTimeHour, startTimeMinute] = startTime.split(":");
		const [endTimeHour, endTimeMinute] = endTime.split(":");
		if (isDeclineInterview) {
			payload.new_interview_start_date = startDate
				.set({
					hour: startTimeHour,
					minute: startTimeMinute
				})
				.utc()
				.unix();

			payload.new_interview_end_date = endDate
				.set({
					hour: endTimeHour,
					minute: endTimeMinute
				})
				.utc()
				.unix();
		} else {
			payload.interview_start_date = startDate
				.set({
					hour: startTimeHour,
					minute: startTimeMinute
				})
				.utc()
				.unix();

			payload.interview_end_date = endDate
				.set({
					hour: endTimeHour,
					minute: endTimeMinute
				})
				.utc()
				.unix();
		}

		const finalAddress =
			location_type === SEARCH_ADDRESS && (await handleAddressObject(address));

		if (finalAddress.addressTypo) {
			setAddressTypo(true);
			return setAddressError(true);
		}

		finalAddress && setAddress(get(finalAddress, "address"));

		let payloadAddress = finalAddress
			? getAddressOnly(get(finalAddress, "address"))
			: getAddressOnly(address);

		if (!payloadAddress.iso_country) {
			payloadAddress = await getIsoCountry(payloadAddress);
		}

		payload = {
			...payload,
			...payloadAddress,
			application_id: applicationId,
			recipient_type: companyType,
			log_event: isLogEvent,
			isDeclineInterview,
			...(interviewId && {
				interview_id: interviewId
			})
		};

		mutate(payload);
	};
	useEffect(() => {
		if (submitFromOutside) {
			handleSubmit(onSubmit)();
			setSubmitFromOutside(false);
		}
	}, [submitFromOutside]);

	return (
		<PlanInterviewContainer>
			<Title margin="0px 0px 40px 0px">
				Plan and notify {candidateName} with the interview details
			</Title>
			<form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="time_chooser"
					control={control}
					defaultValue={false}
					render={({ field: { onChange } }) => {
						return (
							<TimeChooser
								handleTime={value => onChange(value)}
								label="Interview Date"
								dateClass="col-md-5"
								timeStartClass="col-md-3"
								timeEndClass="col-md-3"
								setDate={setDate}
								date={date}
								error={get(errors, "time_chooser.message")}
							/>
						);
					}}
				/>
				<Controller
					name="attendees_users"
					control={control}
					defaultValue={false}
					render={({ field: { onChange, value } }) => (
						<Attendees
							data={attendessList}
							value={value}
							onChange={onChange}
							error={get(errors, "attendees_users.message")}
							isLoadingAttendees={isLoadingAttendees}
						/>
					)}
				/>
				<Controller
					name="description"
					control={control}
					defaultValue={false}
					render={({ field: { onChange, value } }) => (
						<AddInterviewNote onChange={onChange} value={value} />
					)}
				/>

				<Controller
					name="location_type"
					control={control}
					defaultValue={false}
					render={({ field: { onChange, value } }) => (
						<InterviewLocation
							onChange={onChange}
							changeLocationType={changeLocationType}
							value={value}
							setAddress={setAddress}
							address={address}
							companyAddress={getAddress()}
							setAddressError={setAddressError}
							addressError={addressError}
							addressTypo={addressTypo}
						/>
					)}
				/>

				<div className="button-wrapper">
					<button
						onClick={() => setIsLogEvent(false)}
						type="submit"
						className="primary-btn"
					>
						Propose Interview
					</button>
					<button
						onClick={() => setIsLogEvent(true)}
						type="submit"
						className="outlined-btn"
					>
						Save as logged
					</button>
				</div>
			</form>
			<LoaderFull loading={isLoading} />
		</PlanInterviewContainer>
	);
};

const mapsToProps = state => {
	return {
		authenticatedUser: state.auth.user
	};
};

export default connect(mapsToProps)(PlanInterview);
