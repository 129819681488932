import React from "react";
import { useFieldArray, Controller } from "react-hook-form";
import _map from "lodash/map";
import { TrashIcon } from "@heroicons/react/outline";
import Select from "react-select";
import { client } from "lib/api-client";
import { LIST_FUNCTIONS } from "config/api-endpoints";
import CategoriesSelect from "./CategoriesSelect";
import s from "./functions-tab.module.scss";

function Functions({ control, getValues, setValue, errors }) {
	const { fields, append, remove } = useFieldArray({
		control,
		name: "row"
	});

	const loadFunctions = async (subCategory, cb) => {
		if (!subCategory) {
			return cb(null, { options: [], complete: false });
		}
		const results = await client(LIST_FUNCTIONS, {
			body: {
				no_limit: true,
				sector_id: subCategory
			}
		});
		cb(null, {
			options: _map(results, r => ({ label: r.name, value: r._id })),
			complete: false
		});
	};

	const categories = getValues("row") || [];

	return (
		<div>
			{fields.map((field, index) => {
				const subCategoryOptions = _map(
					getValues(`row[${index}].category`)?.children,
					c => ({ label: c.name, value: c._id })
				);
				const subCategory = getValues(`row[${index}].subCategory`)?.value;

				return (
					<div key={index} className={s.row}>
						<div>
							<Controller
								control={control}
								name={`row[${index}].category`}
								render={({ field: { onChange, value } }) => (
									<CategoriesSelect
										onSelect={v => {
											setValue(`row[${index}].subCategory`, null);
											setValue(`row[${index}].function`, null);
											onChange(v);
										}}
										value={value}
									/>
								)}
							/>
							<span className={s.errorField}>
								{errors?.row?.[index]?.category?.message}
							</span>
						</div>
						<div>
							<Controller
								name={`row[${index}].subCategory`}
								control={control}
								render={({ field: { onChange, value } }) => (
									<Select
										value={value}
										options={subCategoryOptions}
										onChange={v => {
											setValue(`row[${index}].function`, null);
											onChange(v);
										}}
									/>
								)}
							/>
							<span className={s.errorField}>
								{errors?.row?.[index]?.subCategory?.message}
							</span>
						</div>
						<Controller
							name={`row[${index}].function`}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select.Async
									key={subCategory}
									value={value}
									onChange={onChange}
									loadOptions={(_, cb) => loadFunctions(subCategory, cb)}
									initialValue={null}
									cache={false}
								/>
							)}
						/>
						<button
							type={"button"}
							className={s.removeButton}
							onClick={() => remove(index)}
						>
							<TrashIcon
								height={20}
								width={20}
								color={"hsl(358 75.0% 59.0%)"}
							/>
						</button>
					</div>
				);
			})}

			{categories.length < 5 ? (
				<button
					className={s.addButton}
					type={"button"}
					onClick={() =>
						append({ category: "", subCategory: "", function: "" })
					}
				>
					Add a category
				</button>
			) : null}
		</div>
	);
}

export default Functions;
