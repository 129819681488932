import { client } from "lib/api-client";
import { GET_PROFILE_OVERVIEW } from "config/api-endpoints";
import { useQuery } from "react-query";

const viewProfile = (_key, id) => {
	return client(GET_PROFILE_OVERVIEW, {
		body: {
			id
		}
	});
};

export const useFetchProfile = (profileId, options = {}) => {
	return useQuery(["view-profile", profileId], viewProfile, {
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		...options
	});
};
