import React, { forwardRef } from "react";
import styles from "./custom-input.module.scss";
import cx from "classnames";

const CustomInput = (
	{
		placeHolder,
		value,
		onChange,
		className,
		type = "text",
		maxlength,
		max,
		...propsRest
	},
	ref
) => {
	return (
		<input
			ref={ref}
			className={cx(styles.input, className)}
			value={value}
			onChange={onChange}
			type={type}
			maxLength={maxlength}
			max={max}
			placeholder={placeHolder}
			{...propsRest}
			autoComplete="off"
		/>
	);
};

export default forwardRef(CustomInput);
