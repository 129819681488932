import React, { useRef } from "react";
import FilterCard from "../../../shared/FilterCard";
import { useToggle } from "react-use";
import { ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import cx from "classnames";
import s from "../ServicesFilter/service-filter.module.scss";
import FunctionsDialog from "./FunctionsDialog";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuArrow
} from "./Dropdown";
import SkillsDialog from "./SkillsDialog";
import styles from "../IndustryFilter/industry-filter.module.scss";
import { PencilAltIcon } from "@heroicons/react/outline";
import Tooltip from "../../../../../common/Tippy";
import Tag from "../../../view-supplier/Tag";
import _filter from "lodash/filter";
import Portal from "../../../../../common/Portal";

const ButtonComponent = ({ onCategoriesClick, onSkillsClick }) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<button style={{ display: "flex", alignItems: "center" }}>
					Add expertise <ChevronDownIcon height={16} width={16} />
				</button>
			</DropdownMenuTrigger>
			<DropdownMenuContent sideOffset={3}>
				<DropdownMenuItem onClick={onCategoriesClick}>
					Functions
				</DropdownMenuItem>
				<DropdownMenuItem onClick={onSkillsClick}>Skills</DropdownMenuItem>
				<DropdownMenuArrow />
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

const EditButtonComponent = ({ onCategoriesClick, onSkillsClick }) => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<button className={styles.actionButton}>
					<PencilAltIcon width={24} height={24} />
				</button>
			</DropdownMenuTrigger>
			<DropdownMenuContent sideOffset={3}>
				<DropdownMenuItem onClick={onCategoriesClick}>
					Functions
				</DropdownMenuItem>
				<DropdownMenuItem onClick={onSkillsClick}>Skills</DropdownMenuItem>
				<DropdownMenuArrow />
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

const FunctionRow = ({ fun, onRemove }) => {
	const categoryRef = useRef();
	const subCategoryRef = useRef();
	const functionRef = useRef();

	return (
		<li className={s.listItem}>
			<Tooltip overflow={"hidden"} content={fun.category?.label}>
				<span ref={categoryRef}>{fun.category?.label}</span>
			</Tooltip>

			<Tooltip overflow={"hidden"} content={fun.subCategory?.label}>
				{fun.subCategory ? (
					<span ref={subCategoryRef}>{fun.subCategory.label}</span>
				) : (
					<span ref={subCategoryRef}>N/A</span>
				)}
			</Tooltip>
			<Tooltip overflow={"hidden"} content={fun.function?.label ?? "N/A"}>
				{fun.function ? (
					<span ref={functionRef}>{fun.function.label}</span>
				) : (
					<span ref={functionRef}>N/A</span>
				)}
			</Tooltip>
			<button onClick={() => onRemove(fun)}>
				<XIcon width={16} height={16} />
			</button>
		</li>
	);
};

function ExpertiseFilter({ store }) {
	const [showFunctionsDialog, toggleFunctionsDialog] = useToggle(false);
	const [showSkillsDialog, toggleSkillsDialog] = useToggle(false);
	const { skills, functions, setSkills, setFunctions } = store(state => ({
		functions: state.functions,
		skills: state.skills,
		setFunctions: state.setFunctions,
		setSkills: state.setSkills
	}));

	const handleRemove = () => {
		setFunctions([]);
		setSkills([]);
	};

	const handleSkillRemove = skill => {
		setSkills(_filter(skills, sk => sk._id !== skill._id));
	};

	const handleFunctionRemove = fn => {
		setFunctions(
			_filter(
				functions,
				fun =>
					fun.category?.value !== fn.category?.value &&
					fun.subCategory?.value !== fn.subCategory?.value &&
					fun.function?.value !== fn.function?.value
			)
		);
	};
	return (
		<>
			<FilterCard
				name={"Expertise"}
				onEdit={toggleFunctionsDialog}
				onRemove={handleRemove}
				value={[...skills, ...(functions || [])]}
				buttonComponent={() => (
					<ButtonComponent
						onCategoriesClick={() => toggleFunctionsDialog(true)}
						onSkillsClick={() => toggleSkillsDialog(true)}
					/>
				)}
				editButtonComponent={() => (
					<EditButtonComponent
						onCategoriesClick={() => toggleFunctionsDialog(true)}
						onSkillsClick={() => toggleSkillsDialog(true)}
					/>
				)}
			>
				{functions?.length > 0 ? (
					<>
						<h6>Functions</h6>
						<ul className={s.list}>
							{functions.map(fun => (
								<FunctionRow
									key={fun._id}
									fun={fun}
									onRemove={handleFunctionRemove}
								/>
							))}
						</ul>
					</>
				) : null}
				{skills?.length > 0 ? (
					<>
						<h6>Skills</h6>
						<ul className={cx(s.list, s.listTags)}>
							{skills.map(skill => (
								<Tag className={s.tag} key={skill._id}>
									{skill.name}
									<button onClick={() => handleSkillRemove(skill)}>
										<XIcon width={16} height={16} />
									</button>
								</Tag>
							))}
						</ul>
					</>
				) : null}
			</FilterCard>
			<Portal>
				<FunctionsDialog
					isOpen={showFunctionsDialog}
					onClose={() => toggleFunctionsDialog(false)}
					store={store}
				/>
				<SkillsDialog
					onClose={() => toggleSkillsDialog(false)}
					isOpen={showSkillsDialog}
					store={store}
				/>
			</Portal>
		</>
	);
}

export default ExpertiseFilter;
