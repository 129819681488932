import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import Avatar from "common/Avatar/index";
import { UNREAD, DATA_DDDD_D_MMM_H_M, DEFAULT_USER_NAME } from "config";

const renderCreatedAt = time => {
	return window.moment.unix(time).format(DATA_DDDD_D_MMM_H_M);
};

const createMarkUp = content => {
	return { __html: content };
};

const NotificationRow = ({ notification, onRead }) => {
	const firstName = _get(notification, "user_sender.first_name");
	const lastName = _get(notification, "user_sender.last_name");
	const fullname = firstName ? `${firstName} ${lastName}` : undefined;

	return (
		<div className="notification-row">
			<div className="col-md-1">
				<div
					className={`dot ${notification.status === UNREAD ? UNREAD : ""}`}
				/>
			</div>
			<div className="col-md-11">
				<div className="notification-content">
					{notification.user_sender && (
						<div style={{ float: "left", marginRight: 10, display: "flex" }}>
							<Avatar
								src={notification.user_sender.avatar}
								fallbackText={DEFAULT_USER_NAME}
								name={fullname}
							/>
						</div>
					)}
					<div className={notification.user_sender && "message"}>
						<div className="info muted">
							{renderCreatedAt(notification.created_at)}
						</div>
						<p
							dangerouslySetInnerHTML={createMarkUp(
								notification.message.content
							)}
						/>
					</div>
				</div>
				{notification.message.url && (
					<button
						onClick={() => onRead(notification)}
						className="btn btn-white btn-sm read-more"
					>
						Read more
					</button>
				)}
			</div>
		</div>
	);
};

NotificationRow.propTypes = {
	notification: PropTypes.object.isRequired
};

export default NotificationRow;
