export const LANGUAGE_LEVELS_LABELS = [
	"Novice",
	"Advanced beginner",
	"Competent",
	"Proficient",
	"Expert"
];

export const DAILY_HOURLY = [
	{ value: "daily", label: "Daily" },
	{ value: "hourly", label: "Hourly" }
];

export const PART_TIME = {
	label: "Part-time",
	value: "Part-time"
};

export const FULL_TIME = {
	label: "Full-time",
	value: "full-time"
};

export const SKILLS_LEVELS_LABELS = [
	"Novice",
	"Advanced beginner",
	"Competent",
	"Proficient",
	"Expert"
];

export const VACANCY_STEP_CARDS_IDS = {
	description: "descriptionCard",
	missionDetails: "missionDetailsCard",
	additionalInfo: "additionalInfoCard"
};

export const PROFILE_STEP = "PROFILE_STEP";
export const VACANCY_STEP = "VACANCY_STEP";

export const EDITOR_MAX_LENGTH = 10000;

export const NUMBER_KEYS_CODES = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
