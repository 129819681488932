import React from "react";
import style from "./styles.module.scss";
import avatar from "../../../images/avatar.png";

const TaskOwner = ({ assignedBy }) => {
	return (
		<div className={style.assignedByContainer}>
			<div className={style.title}>Assigned by</div>
			<span className={style.separator}></span>
			<div className={style.userContainer}>
				<div className={style.avatar}>
					<img src={assignedBy.avatar ?? avatar} />
				</div>
				<div className={style.user}>
					<span
						className={style.title}
					>{`${assignedBy.first_name} ${assignedBy.last_name}`}</span>
					<span>{assignedBy.user_function}</span>
				</div>
			</div>
		</div>
	);
};

export default TaskOwner;
