import React, { useEffect } from "react";
import { ReactComponent as ChevronIcon } from "static/icons/chevron-down.svg";
import styles from "./vacancy-view-location-picker.module.scss";
import {
	MenuTrigger,
	Popover,
	Menu,
	Button as MenuButton,
	MenuItem
} from "react-aria-components";
import cx from "classnames";
import { LOCATION_TYPES } from "../helper/constants";
import { Controller, useFormContext } from "react-hook-form";
import values from "lodash/values";
import { CustomDropDown } from "common/CustomDropDown";
import AddressChooser from "common/AddressBoxNext/AddressChooser";
import { useGetOffices } from "../hooks/useGetOffices";
import { composeAddress } from "../helper/utils";

const VacancyViewLocationPicker = () => {
	const { control, watch, setValue, trigger } = useFormContext();
	const { data: officesData } = useGetOffices();

	const locationType = watch("locationType");
	const selectedLocationType = values(LOCATION_TYPES).find(
		l => l.value === locationType
	);

	useEffect(() => {
		setValue("locationValue", "");
	}, [locationType]);

	const setAddress = onChange => address => {
		const addressObject = {
			number: address?.number,
			box: address?.box,
			street: address?.street,
			zip: address?.zip,
			city: address?.city,
			country: address?.country,
			iso_country: address?.iso_country,
			latitude: address?.latitude,
			longitude: address?.longitude
		};
		if (address?.viewport?.northeast?.lat) {
			addressObject.viewport = address.viewport;
		} else {
			addressObject.viewport = {
				northeast: {
					lat: address?.geometry?.viewport?.getNorthEast?.()?.lat?.(),
					lng: address?.geometry?.viewport?.getNorthEast?.()?.lng?.()
				},
				southwest: {
					lat: address?.geometry?.viewport?.getSouthWest?.()?.lat?.(),
					lng: address?.geometry?.viewport?.getSouthWest?.()?.lng?.()
				}
			};
		}
		onChange(JSON.stringify(addressObject));
	};

	return (
		<div>
			<MenuTrigger>
				<MenuButton type="button" className={styles.locationTypeTrigger}>
					{selectedLocationType.icon}
					<span className={styles.locationTypeLabel}>
						{selectedLocationType.label}
					</span>
					<ChevronIcon />
				</MenuButton>

				<Controller
					control={control}
					name="locationType"
					render={({ field: { onChange, value } }) => (
						<Popover className={styles.locationTypePopover}>
							<Menu
								onAction={value => {
									onChange(value);
									trigger("locationValue");
								}}
								className={styles.locationTypeMenu}
							>
								{values(LOCATION_TYPES).map(item => (
									<MenuItem
										id={item.value}
										key={item.value}
										className={cx(styles.locationTypeItem, {
											[styles.locationTypeSelected]: value === item.value
										})}
									>
										{item.icon}
										<span>{item.label}</span>
									</MenuItem>
								))}
							</Menu>
						</Popover>
					)}
				/>
			</MenuTrigger>
			{locationType === LOCATION_TYPES.online.value ? null : locationType ===
			  LOCATION_TYPES.companyAddress.value ? (
				<Controller
					name="locationValue"
					control={control}
					render={({ field: { value, onChange } }) => {
						return (
							<CustomDropDown
								inputClassName={styles.select}
								onChange={onChange}
								value={value}
								placeHolder="Select an office"
								optionClassName={styles.option}
								labelClassName={styles.optionLabel}
								options={
									officesData?.offices
										? officesData.offices.map(addressObject => ({
												value: JSON.stringify(addressObject),
												label: composeAddress(addressObject)
										  }))
										: []
								}
							/>
						);
					}}
				/>
			) : (
				<div className={styles.addressPickerContainer}>
					<Controller
						control={control}
						name="locationValue"
						render={({ field: { onChange, value } }) => {
							return (
								<AddressChooser
									address={value ? JSON.parse(value) : {}}
									setAddress={setAddress(onChange)}
									error={false}
									addressTypo={false}
									showAutoComplete={true}
									viewSwitcherClassName={styles.customLocationViewSwitcher}
								/>
							);
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default VacancyViewLocationPicker;
