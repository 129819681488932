import React, { useMemo } from "react";
import { queryCache } from "react-query";
import { listCountKey } from "modules/home/api/useListCount.js";
import useOpenJobsList from "../../../api/UseOpenJobs";
import usePermanentStore from "../../../zustand/PermanentStore";
import { RenderDate } from "../../../ui";
import { OPEN_JOBS } from "modules/home/config";
import { WELCOME_PAGE } from "config";
import { historyPush } from "config/helpers";
import PermanentTable from "../PermanentTable/PermanentTable";
import RenderStatus from "modules/home/ui/renderStatus/RenderStatus";
const OpenJobs = ({ handleViewAll }) => {
	const {
		permanentStartDate,
		permanentEndDate,
		setItemPermanent,
		permanentType
	} = usePermanentStore();
	const { data, isLoading } = useOpenJobsList(
		{
			start_date:
				permanentStartDate && permanentStartDate.isValid()
					? permanentStartDate.unix()
					: undefined,
			end_date:
				permanentEndDate && permanentEndDate.isValid()
					? permanentEndDate.unix()
					: undefined
		},
		{
			enabled: permanentType === OPEN_JOBS,
			onSuccess: res => {
				queryCache.invalidateQueries(listCountKey);
				setItemPermanent(
					OPEN_JOBS,
					res.data.map(item => item._id)
				);
			}
		}
	);
	const openJobsColumns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: "created_at",
				Cell: props => {
					return <RenderDate date={props.cell.value} />;
				}
			},
			{
				Header: "Vacancy name",
				accessor: "title"
			},
			{
				Header: "Applications",
				accessor: "count_applications",
				isNumber: true
			},
			{
				Header: "Shortlist",
				accessor: "count_shortlists",
				isNumber: true
			},
			{
				Header: "Interview",
				accessor: "count_interviews",
				isNumber: true
			},
			{
				Header: "Offer",
				accessor: "count_offers",
				isNumber: true
			},
			{
				Header: "Hired",
				accessor: "count_hired",
				isNumber: true
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => {
					return <RenderStatus data={props.cell.value} />;
				}
			},
			{
				Header: "Ref",
				accessor: "job_ref",
				isNumber: true
			}
		],
		[]
	);

	const onRowClick = row =>
		historyPush(
			`/vacancy/${row._id}?source=${WELCOME_PAGE}&listKey=${OPEN_JOBS}`
		);

	return (
		<PermanentTable
			data={data?.data}
			columns={openJobsColumns}
			onRowClick={onRowClick}
			isLoading={isLoading}
			titleEmptyState={"You have no open jobs for today!"}
			handleViewAll={handleViewAll}
		/>
	);
};

export default OpenJobs;
