import React from "react";
import { StatusContainer } from "./styles";

const statusBackground = {
	opened: "#3c78d8",
	draft: "#ffbf64",
	published: "#5bc4a3",
	filled: "#4D13D1",
	closed: "#f9a938",
	hired: "#92E6B5",
	unpublished: "#ffbf64",
	deleted: "#f1f2f5",
	sent: "#ffbf64",
	declined: "#ffbf64",
	planned: "#36ba90",
	done: "#1a91ce",
	refused: "#d2d7db",
	cancelled: "#d2d7db",
	overdue: "#d1d8dc",
	new: "#ffbf64",
	accepted: "#36ba90"
};
const statusColor = {
	opened: "white",
	draft: "#202223",
	published: "white",
	filled: "white",
	closed: "#202223",
	hired: "#202223",
	unpublished: "#202223",
	deleted: "#58626b",
	sent: "#fffff",
	declined: "#ffffff",
	planned: "#ffffff",
	done: "#ffffff",
	refused: "#58626b",
	cancelled: "#58626b",
	overdue: "#58626b",
	new: "#fffff",
	accepted: "#ffffff"
};
export const statusLabel = {
	opened: "Open for internal use",
	draft: "Draft",
	published: "Published",
	filled: "Filled",
	closed: "Closed",
	hired: "Hired",
	onboarding: "Onboarding",
	unpublished: "Unpublished",
	deleted: "Deleted",
	sent: "Sent",
	declined: "Declined",
	planned: "Planned",
	done: "Done",
	refused: "Refused",
	cancelled: "Cancelled",
	overdue: "Overdue",
	new: "New",
	accepted: "Accepted"
};

const StatusLabel = ({ status, isInterviewStatus }) => {
	return (
		<StatusContainer
			color={statusColor[status]}
			background={statusBackground[status]}
			isInterviewStatus={isInterviewStatus}
		>
			<span className="status-label">{statusLabel[status]}</span>
		</StatusContainer>
	);
};

export default StatusLabel;
