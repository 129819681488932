import React from "react";

const Navigations = props => {
	return (
		<div>
			<div className="navigations">
				<div className="btn-group" role="group" aria-label="Basic example">
					{props.hasPrevious && (
						<button
							type="button"
							className="btn btn-secondary"
							onClick={props.goToPrevious}
						>
							<span className="icon-arrow-left" />
							Previous timesheet
						</button>
					)}
					{props.hasNext && (
						<button
							type="button"
							className="btn btn-secondary"
							onClick={props.goToNext}
						>
							Next timesheet
							<span className="icon-arrow-right" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Navigations;
