import React from "react";
import { searchParser } from "config/helpers";
import "./whereby-permanent-virtual-room.module.scss";
import { VideoConference } from "common/VideoConference";

export const getRoute = () => {
	return `/permanent/virtual-room`;
};

export const getPermissions = () => {
	return {
		type: [
			{
				name: "permanent",
				roles: ["admin"]
			}
		]
	};
};

const WherebyPermanentVirtualRoom = () => {
	const { roomUrl, displayName } = searchParser();

	return <VideoConference roomUrl={roomUrl} displayName={displayName} />;
};

export default WherebyPermanentVirtualRoom;
