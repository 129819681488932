import React from "react";
import _get from "lodash/get";
import { formatDate } from "config/helpers";
import styles from "./job-header.module.scss";
import { ReactComponent } from "../icons/empty-logo-company.svg";

export default function JobHeader({ data, children }) {
	return (
		<div className={styles.jobHeader}>
			{_get(data, "company.avatar") ? (
				<img className={styles.logo} src={_get(data, "company.avatar")} />
			) : (
				<div className={styles.logoPlaceholder}>
					<ReactComponent />
				</div>
			)}
			<div className={styles.jobTitle}>
				<h1>{_get(data, "title")}</h1>
				{_get(data, "publish_date") && (
					<span>{formatDate(_get(data, "publish_date"))}</span>
				)}
			</div>
			{children}
		</div>
	);
}
