import React from "react";
import Tooltip from "common/Tippy";
import { today, getLocalTimeZone, Time } from "@internationalized/date";
import styles from "./propose-interview-to-freelancer-drawer-body.module.scss";
import { DatePicker } from "common/DatePicker";
import { RadioGroup } from "common/RadioGroup";
import { get as _get } from "lodash";
import { Button } from "common/Button";
import { ReactComponent as EditIcon } from "static/icons/edit-icon-grey.svg";
import { MissionInterviewAttendeesField } from "../MissionInterviewAttendeesField";
import { Controller } from "react-hook-form";
import { ProposeTempInterviewErrorText } from "../ProposeTempInterviewErrorText";
import NewTimePicker from "common/NewTimePicker";
import moment from "moment";

const ProposeInterviewToFreelancerDrawerBody = ({
	onEditAddress,
	customAddres,
	companyAddress,
	control,
	selectedAttendees,
	errors,
	setValue,
	candidateName
}) => {
	const CustomComponent = ({ onEditAddress }) => {
		const value = formatAddress(customAddres);

		return (
			<>
				{customAddres && (
					<Tooltip theme="dark" content={value}>
						<div content={value} className={styles.address}>
							{value}
						</div>
					</Tooltip>
				)}
				<Button
					textClassName={styles.editText}
					className={styles.editButton}
					icon={<EditIcon className={styles.penIcon} />}
					variant="text"
					text="edit"
					onClick={onEditAddress}
				/>
			</>
		);
	};

	const CompanyAddress = () => {
		return (
			<Tooltip theme="dark" content={companyAddress}>
				<div className={styles.address}>{companyAddress}</div>
			</Tooltip>
		);
	};

	const OPTIONS = [
		{
			label: "Company address",
			value: "company_address",
			customComponent: <CompanyAddress />,
			className: styles.companyAddressLabel
		},
		{
			label: "Custom address",
			value: "search_address",
			customComponent: (
				<CustomComponent
					customAddres={customAddres}
					onEditAddress={onEditAddress}
				/>
			),
			className: styles.customAddressLabel
		},
		{
			label: "Online",
			value: "video_conference",
			className: styles.onlineLabel
		}
	];
	const formatAddress = value => {
		return `${_get(value, "street", "") && _get(value, "street") + " "}${_get(
			value,
			"number",
			""
		) && _get(value, "number", "") + " "}${_get(value, "box", "") &&
			_get(value, "box", "") + " "}${_get(value, "zip", "") &&
			_get(value, "zip", "") + " "}${_get(value, "city", "") &&
			_get(value, "city", "") + " "}${_get(value, "country", "")}`;
	};

	const onStartTimeChange = onChange => value => {
		onTimeChange(onChange)(value);
		const endTime = window.moment(value).add(1, "hours");
		setValue("endTime", endTime);
	};

	const onTimeChange = onChange => ({ minute, hour }) => {
		const instance = moment();
		instance.hours(hour);
		instance.minutes(minute);
		onChange(instance);
	};

	return (
		<div className={styles.container}>
			<p className={styles.description}>Plan and notify </p>
			<b className={styles.name}>{candidateName}</b>
			<p className={styles.description}> with the interview details</p>
			<div className={styles.grid}>
				<div className={styles.label}>Day</div>
				<div className={styles.timeLabel}>Time</div>
				<Controller
					control={control}
					name="day"
					render={({ field: { value, onChange } }) => (
						<div>
							<DatePicker
								value={value}
								onChange={onChange}
								rootClassName={styles.datePicker}
								minValue={today(getLocalTimeZone())}
							/>
							<ProposeTempInterviewErrorText error={errors.day} />
						</div>
					)}
				/>
				<div className={styles.startTime}>
					<Controller
						control={control}
						name="startTime"
						render={({ field: { onChange, value } }) => {
							return (
								<NewTimePicker
									inputClassName={styles.timePicker}
									onChange={onStartTimeChange(onChange)}
									value={new Time(value.hours(), value.minutes())}
									hourCycle={24}
								/>
							);
						}}
					/>
					<ProposeTempInterviewErrorText error={errors.startTime} />
				</div>
				<div className={styles.toLabel}>TO</div>
				<div className={styles.endTime}>
					<Controller
						control={control}
						name="endTime"
						render={({ field: { onChange, value } }) => {
							return (
								<NewTimePicker
									inputClassName={styles.timePicker}
									onChange={onTimeChange(onChange)}
									value={new Time(value.hours(), value.minutes())}
									hourCycle={24}
								/>
							);
						}}
					/>
					<ProposeTempInterviewErrorText error={errors.endTime} />
				</div>
			</div>
			<div className={styles.attendeesContainer}>
				<div className={styles.label}>Attendees</div>
				<div className={styles.avatars}>
					<MissionInterviewAttendeesField
						control={control}
						selectedAttendees={selectedAttendees}
					/>
				</div>
				<ProposeTempInterviewErrorText error={errors.attendees} />
			</div>
			<div className={styles.label}>Note</div>
			<div>
				<Controller
					name="note"
					control={control}
					render={({ field: { value, onChange } }) => (
						<textarea
							placeholder="Start typing..."
							className={styles.textarea}
							value={value}
							onChange={e => onChange(e.target.value)}
						/>
					)}
				/>
				<ProposeTempInterviewErrorText
					error={errors.note || errors.description}
				/>
			</div>
			<div className={styles.locationContainer}>
				<div className={styles.label}>Location</div>
				<Controller
					name="locationType"
					control={control}
					render={({ field: { value, onChange } }) => (
						<RadioGroup
							options={OPTIONS}
							value={value}
							onChange={onChange}
							direction="column"
							className={styles.radioGroup}
						/>
					)}
				/>
				<ProposeTempInterviewErrorText error={errors.customAddress} />
			</div>
		</div>
	);
};

export default ProposeInterviewToFreelancerDrawerBody;
