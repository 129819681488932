import create from "zustand";

const useVacancyListStore = create(set => ({
	page: 0,
	status: "live_vacancies",
	setPage: page => set({ page }),
	setStatus: status => set({ status })
}));

export default useVacancyListStore;
