import Portal from "common/ListFilter/components/Portal";
import { DATE_FORMAT } from "config";
import useOnClickOutside from "hooks/useOnClickOutside";
import React, { useRef, useEffect, useState } from "react";
import Datetime from "react-datetime";
import style from "./timechooser.module.scss";

const TimeChooser = ({ onChange, closeMenu, value, id }) => {
	const modalTimeChooserRef = useRef(null);
	const [focusedNode, setfocusedNode] = useState(false);

	useEffect(() => {
		id && setfocusedNode(document.getElementById(id));
	}, [id]);

	let nodeRect = { x: 0, y: 0 };
	if (focusedNode) {
		nodeRect = focusedNode.getBoundingClientRect();
	}

	const updateDate = date => {
		date = window.moment(date);
		onChange([
			window
				.moment(date)
				.startOf("day")
				.unix(),
			window
				.moment(date)
				.endOf("day")
				.unix()
		]);
		closeMenu();
	};
	useOnClickOutside(modalTimeChooserRef, closeMenu);

	return (
		<Portal nodeRect={nodeRect} id={id}>
			<div className={style.timeChooser} ref={modalTimeChooserRef}>
				<div className={style.dateTime}>
					<Datetime
						value={window.moment.unix(
							value ? value[0] : window.moment().format(DATE_FORMAT)
						)}
						input={false}
						timeFormat={DATE_FORMAT}
						onChange={updateDate}
					/>
				</div>
			</div>
		</Portal>
	);
};
export default TimeChooser;
