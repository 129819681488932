import React, { useState } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import cx from "classnames";
import { get } from "lodash";
import ListMenu from "../ListMenu";
import MenuLabel from "../ListMenu/MenuLabel";
import classnames from "./filter-modal.module.scss";
import { ReactComponent as DeleteRow } from "static/icons/close-icon-tasks.svg";
import { ReactComponent as ArrowRight } from "static/icons/arrow-right-list-filter-icon.svg";
import { GLOBAL_OPERATORS, ASSIGNED_TO, CREATED_BY } from "config";
import { useSelectors } from "common/ListFilter/FilterStore/selectors";
import RenderFilterOptions from "../RenderFilterOptions";

const SingleRow = ({
	row,
	index,
	filterList,
	updateRows,
	rows,
	filters,
	setGlobalOperator,
	updateFields,
	deleteField,
	listFilterRef,
	onClose,
	store
}) => {
	const [openGlobalOperator, setOpenGlobalOperator] = useState(false);
	const [openOperator, setOpenOperator] = useState(false);
	const [openFilterType, setOpenFilterType] = useState(false);
	const [openFilterOptions, setOpenFilterOptions] = useState(false);

	const { setFilter, setFilterValue, deleteFilterValue } = useSelectors(store);

	const updateFilter = row => {
		updateRows([
			...rows.slice(0, index),
			{ ...row, filterOptions: row.filterOptions },
			...rows.slice(index + 1)
		]);
		setFilter(row, index);
		setOpenFilterType(false);
	};

	const updateFilterValue = value => {
		setFilterValue(row, value, index);
	};
	const deleteFilterValueHandler = id => {
		deleteFilterValue(row, id, index);
	};

	const updateOperator = option => {
		updateFields(
			{
				...get(filters, "fields", [])[index],
				op: get(option, "value")
			},
			index
		);

		setOpenOperator(false);
	};

	useOnClickOutside(listFilterRef, event => {
		const classList = event.target.classList.value;
		const parentClassname = event.target.parentElement.className;
		const notClosed = [
			"list-menu",
			"",
			"rdt",
			"dow",
			"CalendarDay",
			"DayPicker",
			"menu",
			"select-menu"
		].some(
			elem =>
				elem === classList ||
				(elem !== "" && classList.search?.(elem) > -1) ||
				(elem !== "" && parentClassname.search?.(elem) > -1)
		);

		if (!notClosed) {
			onClose();
		}
	});

	const deleteRow = () => {
		updateRows([...rows.slice(0, index), ...rows.slice(index + 1)]);
		deleteField(index);
	};

	const getOperatorValue = get(row, "oprators", []).filter(
		i => i.value === get(get(filters, "fields", [])[index], "op", "is")
	)[0].label;

	const Label = (
		<div className={classnames.filterTypeMenuLabel}>
			{" "}
			{get(row, "label")}{" "}
			{get(row, "selectedOption") ? (
				<span
					className={cx({
						[classnames.externalLabel]:
							get(row, "selectedOption.value") === "external"
					})}
				>
					({get(row, "selectedOption.label")})
				</span>
			) : null}
		</div>
	);
	const AnyLabel = () => {
		if (
			(get(row, "field") === CREATED_BY || get(row, "field") === ASSIGNED_TO) &&
			get(get(filters, "fields", [])[index], "value.length", []) > 1
		) {
			return <div className={classnames.anyLabel}> (any)</div>;
		} else if (
			(get(row, "electedOption.label", "") === "candidate" ||
				get(row, "electedOption.label", "") === "freelancer" ||
				get(row, "electedOption.label", "") === "supplier") &&
			get(get(filters, "fields", [])[index], "value._id.length", []) > 1
		) {
			return <div className={classnames.anyLabel}>(any)</div>;
		}
	};

	const showGlobalOperatorMenu = () => setOpenGlobalOperator(true);
	const closeGlobalOperatorMenu = () => setOpenGlobalOperator(false);
	const handleChangeGlobalOperator = value => {
		setGlobalOperator(get(value, "value"));
		setOpenGlobalOperator(false);
	};

	const showFilterTypeMenu = () => setOpenFilterType(true);
	const closeFilterTypeMenu = () => setOpenFilterType(false);

	const showOperatorMenu = () => setOpenOperator(true);
	const closeOperatorMenu = () => setOpenOperator(false);

	const showFilterOptionsMenu = () => setOpenFilterOptions(true);
	const closeFilterOptionsMenu = () => setOpenFilterOptions(false);

	const globalOperatorId = get(row, "id") + index + "goi";
	const filterTypeId = get(row, "id") + index + "fti";
	const opratorId = get(row, "id") + index + "oi";
	const filterOptionsId = get(row, "id") + index + "foi";

	return (
		<div className={classnames.singleRowContainer}>
			{index === 0 ? (
				<div className={classnames.whereLabel}>
					{" "}
					<span>Where</span>
				</div>
			) : (
				<div className={classnames.globalOpertatorWrraper}>
					{index === 1 && (
						<MenuLabel
							clickHandler={showGlobalOperatorMenu}
							value={get(filters, "op")}
						/>
					)}
					{openGlobalOperator && (
						<div id={globalOperatorId} className={classnames.listMenuWrraper}>
							<ListMenu
								closeMenu={closeGlobalOperatorMenu}
								options={GLOBAL_OPERATORS}
								onSelect={handleChangeGlobalOperator}
								id={globalOperatorId}
							/>
						</div>
					)}

					{index !== 1 && (
						<div className={classnames.globalOperator}>
							{" "}
							<span>{get(filters, "op")}</span>
							<ArrowRight className={classnames.icon} />
						</div>
					)}
				</div>
			)}
			<div className={classnames.filterTypeWrraper}>
				<MenuLabel clickHandler={showFilterTypeMenu} value={Label} />
				{openFilterType && (
					<div id={filterTypeId} className={classnames.listMenuWrraper}>
						<ListMenu
							closeMenu={closeFilterTypeMenu}
							options={filterList}
							onSelect={updateFilter}
							id={filterTypeId}
						/>
					</div>
				)}
			</div>
			<div className={classnames.opratorWrraper}>
				<MenuLabel
					clickHandler={showOperatorMenu}
					value={
						<>
							{" "}
							{getOperatorValue} {AnyLabel()}
						</>
					}
				/>
				{openOperator && (
					<div id={opratorId} className={classnames.listMenuWrraper}>
						<ListMenu
							closeMenu={closeOperatorMenu}
							options={get(row, "oprators", [])}
							onSelect={updateOperator}
							id={opratorId}
						/>
					</div>
				)}
			</div>
			<div className={classnames.filterOptionsWrraper}>
				<RenderFilterOptions
					clickHandler={showFilterOptionsMenu}
					field={get(row, "field")}
					operator={get(get(filters, "fields", [])[index], "op", "Is")}
					deleteFilterValue={deleteFilterValueHandler}
					showMoreTitle={Label}
					closeMenu={closeFilterOptionsMenu}
					optionsFunction={get(row, "filterOptions", null)}
					onSelect={updateFilterValue}
					op={get(get(filters, "fields", [])[index], "op", "Is")}
					value={get(get(filters, "fields", [])[index], "value", [])}
					openFilterOptions={openFilterOptions}
					selectedOption={get(row, "selectedOption")}
					id={filterOptionsId}
				/>
			</div>
			<div className={classnames.deleteButtonWrraper}>
				<button onClick={deleteRow}>
					<DeleteRow />
				</button>
			</div>
		</div>
	);
};

export default SingleRow;
