import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_USER_SETTING } from "config/api-endpoints";

function updateUsersettings(body) {
	return client(UPDATE_USER_SETTING, {
		body
	});
}
export const updateUserSettingsKey = "@profile/update-user-settings";
function useUpdateUserSettings(options) {
	return useMutation(updateUsersettings, options);
}

export default useUpdateUserSettings;
