import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
import { pdfjs } from "react-pdf";
import Modal from "common/modal";
import S3Uploader from "common/S3Uploader";
import {
	TYPE_PDF,
	TYPE_DOC,
	TYPE_DOCX,
	CV_SIZE_LIMIT,
	DOC,
	PDF,
	PDF_VIEWER_URL
} from "config";
import { PRE_SIGNED_URL, UPLOAD_CV } from "config/api-endpoints";

import toaster from "common/Toaster";
import AxiosHelper from "config/axios-helper";
import EmptyCV from "./EmptyCV";
import { processingFileSelector } from "modules/cv/selectors/cvSelectors";
import { setFileProcessingStatus } from "modules/cv/actions";
import GlobalTooltip from "common/GlobalTooltip";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TYPE_MAP = {
	docx: DOC,
	doc: DOC,
	pdf: PDF
};

const getFileExtension = (file = "") => {
	return TYPE_MAP[
		file
			.split(".")
			.pop()
			.toLowerCase()
	];
};

const FreelancerCV = ({
	cv,
	id,
	canUpdateCV,
	cvDoc = "",
	isProcessingFile,
	setFileProcessingStatus
}) => {
	const timeOutRef = useRef();

	const [displayUploadCvModal, setDisplayUploadCvModal] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);

	const [fileUrl, setFileUrl] = useState(cvDoc);
	const fileExtension = getFileExtension(cvDoc || PDF);
	let cvDocName = `CV file.${fileExtension}`;
	const [fileName, setFileName] = useState(undefined);
	const [error, setError] = useState(false);
	const [processingError, setProcessingError] = useState(false);

	if (!fileName && cvDoc) {
		setFileName(_get(cvDoc.split(/_(.*)/g), "1", cvDocName));
	}

	useEffect(() => {
		if (cv && imageLoaded) {
			setImageLoaded(false);
		}
	}, [cv]);

	const defaultFileName = fileName || cvDocName;

	const progressComponent = ({ percentCompleted }) => {
		if (percentCompleted) {
			return (
				<div className="file-progress-box">
					<div className="size">{`${percentCompleted.toFixed(0)}%`}</div>
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	};

	const renderUploader = () => {
		return (
			<>
				<S3Uploader
					accept={[TYPE_PDF, TYPE_DOC, TYPE_DOCX]}
					id="cv-upload"
					multiple={false}
					folder={"cv"}
					autoSubmit={true}
					onSuccess={onUploadSuccess}
					onClose={resetFile}
					resetOnSuccess={false}
					isLogin={true}
					onError={error =>
						toaster.danger(error, { id: "uploader-error", duration: 7 })
					}
					progressComponent={progressComponent}
					text={{
						choose: "Upload your CV",
						placeholder: "Upload a file from your computer.",
						icon: { text: "CV", class: "fa fa-file-o" }
					}}
					preSignedUrl={PRE_SIGNED_URL}
					preSignedData={{ id }}
					sizeLimit={CV_SIZE_LIMIT}
				/>
				<div className="help-block inline-error">
					{error && <>Please upload a file before submitting</>}
				</div>
			</>
		);
	};
	const onUploadSuccess = file => {
		setFileUrl(file.url);
		setFileName(file.name);
	};
	const resetFile = () => setFileUrl(null);

	const submit = async () => {
		if (!fileUrl) {
			return setError(true);
		}
		try {
			setFileProcessingStatus(true);
			await AxiosHelper.post({
				url: UPLOAD_CV,
				data: { doc: fileUrl, user_id: id },
				toastMessage: "Successfully uploaded your cv!",
				isModal: true
			});
			resetFile();
			if (timeOutRef.current) {
				clearTimeout(timeOutRef.current);
				timeOutRef.current = null;
			}
			setDisplayUploadCvModal(false);
			setProcessingError(false);
			timeOutRef.current = setTimeout(() => {
				setProcessingError(true);
			}, 30000);
		} catch (e) {
			setFileProcessingStatus(false);
		}
	};

	const displayProcessingError = () => {
		return (
			<>
				<EmptyCV
					loading
					error
					title="We Couldn't convert the file"
					text="You can wait or you can skip this and check back later."
				/>
			</>
		);
	};

	const displayProcessingFile = (text = "Processing the file...") => (
		<EmptyCV loading title={text} />
	);

	const displayFileIcon = () => (
		<div className="file-icon-container">
			<div className="file-icon">
				<span className={`icon-${getFileExtension(defaultFileName)}`}>
					<span className="path1" />
					<span className="path2" />
					<span className="path3" />
					<span className="path4" />
					<span className="path5" />
					<span className="path6" />
					<span className="path7" />
					<span className="path8" />
				</span>
			</div>

			{defaultFileName.length > 20 ? (
				<GlobalTooltip
					overlayClassName="g-tool-dark"
					placement={"top"}
					overlay={defaultFileName}
				>
					<a className="file-name" href={fileUrl}>
						{defaultFileName.substr(0, 20)}...
					</a>
				</GlobalTooltip>
			) : (
				<a className="file-name" href={fileUrl}>
					{defaultFileName}
				</a>
			)}
		</div>
	);

	return (
		<>
			<div
				className="cv-container"
				style={{ margin: "0 auto", width: "100%", padding: "0" }}
			>
				{canUpdateCV && (cv || isProcessingFile) && (
					<div className="section-edit pull-right">
						<button
							className="btn btn-sm"
							onClick={() => setDisplayUploadCvModal(true)}
						>
							<span className="icon-edit-2" />
						</button>
					</div>
				)}
				{isProcessingFile ? (
					<>
						{displayFileIcon()}
						{processingError
							? displayProcessingError()
							: displayProcessingFile()}
					</>
				) : cv ? (
					<>
						{canUpdateCV && displayFileIcon()}

						<iframe
							style={{
								width: "100%",
								minHeight: "895px",
								overflow: "hidden",
								border: "none"
							}}
							src={PDF_VIEWER_URL + cv.replace(/^http:\/\//i, "https://")}
							width="100%"
						></iframe>
					</>
				) : canUpdateCV ? (
					cvDoc ? (
						<>
							<div className="section-edit pull-right">
								<button
									className="btn btn-sm"
									onClick={() => setDisplayUploadCvModal(true)}
								>
									<span className="icon-edit-2" />
								</button>
							</div>
							{displayFileIcon()}
							{displayProcessingError()}
						</>
					) : (
						<>
							<div className="empty-section">
								<div
									className="add btn-center-block"
									onClick={() => setDisplayUploadCvModal(true)}
								>
									<i className="fa fa-plus" /> Upload your cv
								</div>
							</div>
						</>
					)
				) : (
					<>
						<EmptyCV
							title={"No CV found"}
							text={"This freelancer has not uploaded his Cv yet."}
						/>
					</>
				)}
			</div>

			{
				<Modal
					active={displayUploadCvModal}
					title={"CV upload"}
					firstButton={{
						label: "Save to my profile",
						type: "primary",
						action: submit,
						disabled: !fileUrl
					}}
					onClose={() => setDisplayUploadCvModal(false)}
					titleClassName="cv-modal-title"
				>
					<>{renderUploader()}</>
				</Modal>
			}
		</>
	);
};

const mapStateToProps = state => {
	return {
		isProcessingFile: processingFileSelector(state)
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({ setFileProcessingStatus }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FreelancerCV);
