//TODO use the api from root
import { useQuery } from "react-query";
import { client } from "lib/api-client";
import {
	HTTP_GET_COMPANY_SITE,
	HTTP_GET_CURRENT_COMPANY
} from "config/api-endpoints";

const myCurrentCompany = () => client(HTTP_GET_CURRENT_COMPANY);

export const useMyCurrentCompany = (queryOptions = {}) => {
	return useQuery(["myCurrentCompany"], myCurrentCompany, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
};

const myCurrentSite = body => {
	return client(HTTP_GET_COMPANY_SITE, {
		body
	});
};

export const useMyCurrentSite = (options, queryOptions = {}) =>
	useQuery(["myCurrentSite", options], () => myCurrentSite(options), {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
