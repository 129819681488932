import React from "react";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import Portal from "common/Portal";
const ConfirmationModalAction = ({
	modalName,
	title,
	type,
	onClose,
	onClick,
	textAction,
	loading
}) => {
	const getType = type => {
		switch (type) {
			case "success":
				return ConfirmationTypes.success;
			case "warning":
				return ConfirmationTypes.warning;
			default:
				return ConfirmationTypes.success;
		}
	};
	return (
		<Portal>
			<ConfirmationModal
				modalName={modalName}
				onClose={onClose}
				loading={loading}
				title={title}
				type={getType(type)}
				firstButton={{
					label: textAction,
					type: type,
					action: () => onClick()
				}}
			/>
		</Portal>
	);
};

export default ConfirmationModalAction;
