import React from "react";
import styles from "modules/home/components/styles.module.scss";
import cx from "classnames";
import { get } from "lodash";

const redirect = url => () => {
	window.location.assign(url);
};

const EmptyState = ({
	children,
	illustration,
	heading,
	description,
	firstAction,
	secondAction
}) => (
	<div className={styles.emptyStateContainer}>
		<div className={styles.flexColumn}>
			{illustration && <div>{illustration}</div>}
			{heading && <h5 className={styles["gray_text"]}>{heading}</h5>}
			{description && (
				<h5 className={cx(styles["gray_text"], styles["dark_gray_text"])}>
					{description}
				</h5>
			)}
			<div className={styles.flexCenter}>
				{firstAction && (
					<button
						className={styles["emptyStateButton"]}
						onClick={
							get(firstAction, "onClick") || redirect(get(firstAction, "url"))
						}
					>
						<span>{get(firstAction, "label", "")} </span>
						{get(firstAction, "icon", "")}
					</button>
				)}
				{secondAction && (
					<button
						className={styles["emptyStateButton"]}
						onClick={get(secondAction, "onClick")}
					>
						<span>{get(secondAction, "label", "")} </span>
						{get(secondAction, "icon", "")}
					</button>
				)}
			</div>

			{children}
		</div>
	</div>
);

export default EmptyState;
