/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { compose } from "redux";
import {
	change,
	Field,
	formValueSelector,
	getFormSubmitErrors,
	initialize,
	reduxForm,
	SubmissionError
} from "redux-form";
import { connect } from "react-redux";
import get from "lodash/get";
import has from "lodash/has";
import { Link } from "react-router";

import VatDescription from "./VatDescription";
import FormField from "common/FormField";
import { capitalize, getAddressOnly, isEmptyAddress } from "config/helpers";
import { scrollToFirstError } from "common/Functions";
import { getSignInRoute } from "../Login";
import AddressBox from "common/AddressBox/AddressChooser";
import { handleAddressObject } from "config/helpers";
import { AddressLabel } from "../../Styled";

import AxiosHelper from "config/axios-helper";
import { LIST_INDUSTRIES_ENDPOINT } from "config/api-endpoints";
import Select from "common/StyledSelect";
import { CLIENT } from "config";
import toaster from "common/Toaster";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import { FREELANCER } from "config";
import WindowSizeProvider from "common/WindowSizeProvider";
import cx from "classnames";

const addressFields = isMobile => [
	{
		label: "street",
		required: true,
		group: "1"
	},
	{
		label: "number",
		required: true,
		group: "2"
	},
	{
		label: "box",
		required: false,
		group: "3"
	},
	{
		label: "country",
		required: true,
		group: "4"
	},
	{
		label: "city",
		required: true,
		group: isMobile ? "5" : "4"
	},
	{
		label: "zip",
		required: true,
		group: isMobile ? "6" : "4"
	}
];

class SignUpSecondStep extends Component {
	state = {
		acceptTerms: false,
		address: {},
		error: false,
		addressTypo: false,
		industries: []
	};

	isAddressInvalid = () => {
		const { address } = this.state;
		return (
			!get(address, "street") ||
			!get(address, "number") ||
			!get(address, "city") ||
			!get(address, "country") ||
			!get(address, "zip")
		);
	};

	componentDidMount() {
		this.getIndutriesList();
	}

	initializeReduxForm(data) {
		const { dispatch, company_name, vat, accept_terms } = this.props;
		dispatch(
			initialize("signup-second-step", {
				...data,
				company_name,
				vat,
				accept_terms
			})
		);
	}

	setAddress = address => {
		const newAddress = {
			street: address.street || "",
			country: address.country || "",
			zip: address.zip || "",
			city: address.city || "",
			number: address.number || "",
			box: address.box || "",
			latitude: address.latitude || "",
			longitude: address.longitude || "",
			formatted_address: address.formatted_address || "",
			iso_country: address.iso_country || "",
			geometry: address.geometry || ""
		};

		if (
			newAddress.country !== get(this.state, "address.country") ||
			newAddress.city !== get(this.state, "address.city")
		) {
			if (
				newAddress.longitude === get(this.state, "address.city") ||
				newAddress.latitude === get(this.state, "address.latitude")
			) {
				newAddress.latitude = 0;
				newAddress.longitude = 0;
			}
		}
		this.setState({
			address: newAddress,
			error: this.state.error && !this.isAddressInvalid()
		});
	};

	acceptTermsHandler = () => {
		this.props.dispatch(change("signup-second-step", "accept_terms", true));
	};

	termsCloseHandler = () => {
		this.setState({ acceptTerms: false });
	};

	getViewport = value => {
		if (value) {
			return {
				northeast: {
					lat: value.getNorthEast().lat(),
					lng: value.getNorthEast().lng()
				},
				southwest: {
					lat: value.getSouthWest().lat(),
					lng: value.getSouthWest().lng()
				}
			};
		} else {
			return null;
		}
	};

	handleSignUp = async props => {
		const { address } = this.state;
		const cleanAddress = getAddressOnly(address);
		if (isEmptyAddress(cleanAddress) && this.isAddressInvalid())
			return this.setState({ error: true });

		const finalAddress = await handleAddressObject(cleanAddress);

		if (finalAddress.addressTypo) {
			this.setState({
				error: true,
				addressTypo: true
			});
			return this.setState({
				error: false,
				addressTypo: false
			});
		}

		this.setState({
			...this.state,
			...finalAddress
		});
		cleanAddress.latitude = finalAddress.address.latitude;
		cleanAddress.longitude = finalAddress.address.longitude;
		cleanAddress.iso_country = finalAddress.address.iso_country;
		cleanAddress.viewport = this.getViewport(address?.geometry?.viewport);

		const { geometry, ...restCleanAddress } = cleanAddress;
		return this.props
			.onSignUp({
				...props,
				...restCleanAddress,
				step: 2,
				industry: get(props, "industry._id")
			})
			.catch(error => {
				if (
					has(error, "vat") &&
					get(error, "vat.0.message") === "vat_existing"
				) {
					delete error.vat;
					error.vat_existing = true;
					toaster.danger(
						"An account for this vat/registration number already exists."
					);
				} else {
					toaster.danger(
						get(error, "name") ||
							"Something wrong with data sent. Please try again!"
					);
				}
				return Promise.reject(new SubmissionError(error));
			});
	};

	hasAcceptedTermsAndPolicy = () => {
		const { acceptedTerms } = this.props;
		const { company_name, vat } = this.props;
		if (!acceptedTerms) {
			const nextState = {};
			if (company_name && vat) nextState.acceptTerms = true;
			this.setState(nextState);
		}
	};

	handleClaimAccount = () => {
		const { onClaimAccount, vat } = this.props;
		onClaimAccount({
			firstname: get(this.props, "firstname", ""),
			lastname: get(this.props, "lastname", ""),
			email: get(this.props, "email", ""),
			vat
		});
	};

	getIndutriesList = () => {
		AxiosHelper.post({
			url: LIST_INDUSTRIES_ENDPOINT,
			isLogin: true
		}).then(resp => this.setState({ ...this.state, industries: resp }));
	};

	formatIndustries = industries => {
		return industries.map(i => ({
			...i,
			label: i.name,
			value: i._id
		}));
	};

	signupForm = () => {
		const { submitErrors, submitFailed } = this.props;
		const { address } = this.state;

		return (
			<WindowSizeProvider>
				{isMobile => (
					<>
						<div>
							<div
								className={cx("form-field", {
									["mobileFormField"]: isMobile
								})}
							>
								<Field
									type="text"
									name="company_name"
									label="Company name"
									labelWithAsterisk
									component={FormField}
								/>
							</div>
							<div
								className={cx("form-field", {
									["mobileFormField"]: isMobile
								})}
							>
								<Field
									type="text"
									name="vat"
									label="Vat/Registration number"
									labelWithAsterisk={this.props.type !== FREELANCER}
									component={FormField}
									maxLength={20}
									showMaxCharacters={!isMobile}
									hasError={has(submitErrors, "vat_existing") && submitFailed}
									errorComponent={
										<div className="help-block vat-exists">
											An account for this vat/registration number already
											exists. <Link to={getSignInRoute()}>Log in</Link> or{" "}
											<span
												role="button"
												className="claim-btn"
												onClick={this.handleClaimAccount}
											>
												claim this account.
											</span>
										</div>
									}
								/>
							</div>
							{this.props.type === CLIENT && (
								<div
									className={cx("form-field", {
										["mobileFormField"]: isMobile
									})}
								>
									<Field
										name="industry"
										label="industry"
										options={this.formatIndustries(this.state.industries)}
										position={false}
										searchable={true}
										clearable={true}
										component={Select}
									/>
								</div>
							)}
							<div
								className={cx("form-field address-box", {
									["mobileFormField"]: isMobile
								})}
							>
								<AddressLabel>Address</AddressLabel>

								<AddressBox
									editAddressFields={addressFields(isMobile)}
									setAddress={this.setAddress}
									isRequired={true}
									showAutoComplete
									name="location"
									address={address}
									showToast={this.state.error}
									addressTypo={this.state.addressTypo}
									error={
										this.state.error ||
										(submitFailed &&
											!isEmptyAddress(address) &&
											this.isAddressInvalid())
									}
								/>
							</div>
						</div>
					</>
				)}
			</WindowSizeProvider>
		);
	};

	render() {
		const { type, handleSubmit, isLoading } = this.props;
		return (
			<WindowSizeProvider>
				{isMobile => (
					<div>
						<h3
							className={cx("heading", {
								["mobileHeading"]: isMobile
							})}
						>
							Register as a {capitalize(type)}
						</h3>
						<VatDescription type={type} />
						<form className="form" onSubmit={handleSubmit(this.handleSignUp)}>
							{this.signupForm()}
							<div style={{ height: 20 }}></div>
							<button
								onClick={this.hasAcceptedTermsAndPolicy}
								disabled={isLoading}
								className="form-btn"
							>
								{isLoading ? <AnimatedLoaderIcon width={24} /> : "Next"}
							</button>
							<div
								className={cx("description sign-up-cta", {
									["mobileDescription"]: isMobile
								})}
							>
								{`Already have an account?`}{" "}
								<Link
									className={cx("link", {
										["mobileDescription"]: isMobile
									})}
									to="/sign-in"
								>
									Log in
								</Link>
							</div>
						</form>
					</div>
				)}
			</WindowSizeProvider>
		);
	}
}

const validate = (formProps, props) => {
	const errors = {};
	if (!formProps.accept_terms) {
		errors.accept_terms = "You must accept the Terms and Conditions.";
	}
	if (!formProps.company_name || formProps.company_name.trim() === "") {
		errors.company_name = "Company name is required.";
	}
	if (props.type !== FREELANCER && !formProps.vat) {
		errors.vat = "VAT/registration number is required.";
	}
	return errors;
};

const selector = formValueSelector("signup-second-step");
const mapStateToProps = state => {
	return {
		acceptedTerms: selector(state, "accept_terms"),
		accept_terms: selector(state, "accept_terms"),
		company_name: selector(state, "company_name"),
		vat: selector(state, "vat"),
		address: state.signup.address,
		submitErrors: getFormSubmitErrors("signup-second-step")(state)
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "signup-second-step",
		validate,
		touchOnBlur: false,
		onSubmitFail: errors => scrollToFirstError(errors)
	})
)(SignUpSecondStep);
