import React, { Component } from "react";
import PropTypes from "prop-types";
import Desc from "common/Description";

class Description extends Component {
	render() {
		return (
			<div className="description-profile">
				<h5>About me</h5>
				{this.props.ownCV
					? this.props.content ||
					  "Adding a biography is a quick and easy way to highlight your experience and interests."
					: (
							<Desc
								description={this.props.content}
								parseMarkdown={true}
								showMore
								max={46}
								showMoreText="View more"
							/>
					  ) || (
							<div style={{ marginLeft: 0, color: "#727e96" }}>
								There is no additional information available.
							</div>
					  )}
			</div>
		);
	}
}

Description.propTypes = {
	content: PropTypes.string,
	ownCV: PropTypes.bool
};

export default Description;
