import { usePaginatedQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_LIST_CHANNELS } from "config/api-endpoints";
import { searchParser } from "config/helpers";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import fakeChannels from "modules/user-onboarding/mockData/channels-free.json";
import { HOW_TO_PUBLISH_VACANCY } from "config/onboarding";

const getChannels = (_key, body) =>
	client(GET_LIST_CHANNELS, {
		body
	});

const fakeData = () => new Promise(r => r(fakeChannels));

export const getFreeChannelsKey = "@VonqContract/getChannels";

const useGetFreeChannels = freeChannelsOffset => {
	const { siteId: vacancySiteId } = searchParser();

	const options = {
		tag: "my_free_channels",
		limit: 6,
		offset: freeChannelsOffset,
		sort_by: "asc",
		field: "created_at",
		name: "",
		site_id: vacancySiteId
	};

	const { workflowId } = useOnboardingStore();
	const fetchData =
		workflowId === HOW_TO_PUBLISH_VACANCY ? fakeData : getChannels;

	return usePaginatedQuery(
		[getFreeChannelsKey, options, workflowId],
		fetchData,
		{
			refetchOnWindowFocus: false,
			retry: 0
		}
	);
};

export default useGetFreeChannels;
