/* eslint-disable react/display-name */
import React from "react";
import cx from "classnames";
import { Link } from "react-router";
import { INVITE_PREVIEW, STEPS } from "config/index";
import { getPolicyRoute } from "../../../../common/privacyPolicy/components/PolicyContainer";
import { getTermsAndConditionsRoute } from "../../../../common/termsAndConditions/components/TermsAndConditionsContainer";
import styles from "./create-user.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/referral/x.svg";
import Steps from "common/steps";
import { ASSIGN_ROLE } from "config";

export default ({
	handleStep,
	user,
	adminName = "",
	onClose,
	currentStep,
	showStepper,
	isModeTour,
	moveToStep
}) => {
	return (
		<div className={styles.userFormContainer}>
			<header className={styles.header}>
				<h3 className={styles.title}>Create user</h3>
				<div
					role="button"
					tabIndex="0"
					onClick={onClose}
					className={styles.button}
				>
					<CloseIcon />
				</div>
			</header>
			<div className={styles.containerAssignRole}>
				<div className={styles.steps}>
					<Steps
						labelPlacement="vertical"
						steps={STEPS}
						activeStep={currentStep}
						active={showStepper}
						noStyles={true}
					/>
				</div>
				<p className={styles.textInfo}>
					The following email will be sent to your invited user
				</p>
				<div className={cx(styles.grayBlock, styles.previewText)}>
					<p>Hello {user.first_name},</p>
					<p>
						Welcome to wiggli! Please activate your account & access the
						platform by clicking on the “JOIN” button below.
					</p>
					<p>
						By clicking “join”, your personal information, such as your name,
						email address and job title will be shared with wiggli, you consent
						to such information being collected, held, used and disclosed in
						accordance with Wiggli’s{" "}
						<Link className="link-url" to={getPolicyRoute()}>
							Privacy Policy.
						</Link>{" "}
						<br />
						When using the Wiggli platform you must adhere to their{" "}
						<Link className="link-url" to={getTermsAndConditionsRoute()}>
							Website T&Cs.
						</Link>
					</p>
					<p>
						Please note that you have 72 hours to join the platform using the
						provided invitation before it expires.
					</p>
					<p>
						Do not hesitate to contact me in case you need any help or have any
						question.
					</p>
					<p>
						Best regards, <br />
						{adminName}.
					</p>
				</div>
			</div>
			<div className={styles.footer}>
				<button
					className={styles.submitBtn}
					onClick={() => handleStep(INVITE_PREVIEW, isModeTour)}
				>
					Send
				</button>
				<button
					type="button"
					className={styles.cancelBtn}
					onClick={() => moveToStep(ASSIGN_ROLE, user)}
				>
					Back
				</button>
			</div>
		</div>
	);
};
