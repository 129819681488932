import { HIDE_MENU, SHOW_MENU } from "./actions";
export default function menu(state = { isVisible: false }, action) {
	switch (action.type) {
		case SHOW_MENU:
			return Object.assign({}, state, {
				isVisible: true
			});
		case HIDE_MENU:
			return Object.assign({}, state, {
				isVisible: false
			});
		default:
			return state;
	}
}
