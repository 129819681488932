/* eslint-disable no-console */
import React, { useState, useRef } from "react";
import _debounce from "lodash/debounce";
//import { queryCache } from "react-query";
import get from "lodash/get";
import cx from "classnames";
import FilterBox from "common/FilterBox";
import Table from "modules/candidate/Profile/components/Table/Table.js";
import useAllProcesses from "../../api/useAllProcesses";
import { sortBylist, columns, getData } from "../../utils/allProcesses";
import usePrecessesStore from "../../Store/usePrecessesStore";
import DisplayResults from "modules/Tasks/components/pagination/DisplayResults";
import PaginationClient from "modules/Tasks/components/pagination";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";

import classNames from "modules/Tasks/tasks.module.scss";
import styles from "./processes.module.scss";
import useProfileStore from "modules/home/zustand/candidate.store.js";
import {
	// OVERVIEW,
	// PROFILE,
	PROCESSES
	// INTERACTIONS
	// NOTES,
	// FILES
} from "modules/home/config";
const AllProcesses = ({ candidateId }) => {
	const { candidateTab } = useProfileStore();

	const [searchKeyword, setSearchKeyword] = useState("");

	const {
		filters,
		updateOffset,
		updateSearch,
		limit,
		setLimit
	} = usePrecessesStore();

	const { offset, field = "created_at", sort = "desc", search } = filters;
	const { data: resolvedData, isFetching } = useAllProcesses(
		{
			user_id: candidateId,
			field,
			search,
			sort,
			offset,
			limit
		},
		{
			enabled: candidateTab === PROCESSES
		}
	);

	const debouncedChangeFilterAction = useRef(_debounce(updateSearch, 500));

	const handelSearchChange = value => {
		setSearchKeyword(value);
		debouncedChangeFilterAction.current(value);
		updateOffset(0);
	};

	const data = getData(isFetching, resolvedData);
	const total = get(resolvedData, "pagination.total", []);
	const size = get(resolvedData, "pagination.size", []);
	const { setIsOpen, setApplicationId, setIsFromProcesses } = useProfileView();
	const handleRowClick = (row, index) => {
		setIsOpen(true);
		setApplicationId(row[index].id);
		setIsFromProcesses(true);
	};

	return (
		<div className={styles.processesContainer}>
			<div className={styles.filterBox}>
				<FilterBox
					searchKeyword={searchKeyword}
					setSearchKeyword={handelSearchChange}
					sortBylist={sortBylist}
					hideParams={true}
					store={usePrecessesStore}
				/>
			</div>
			<div className={styles.listProcesses}>
				<Table
					data={data}
					isFetching={isFetching}
					onRowClick={handleRowClick}
					fetchedData={get(resolvedData, "pokes", [])}
					columns={columns(field)}
					className={"notes"}
				/>
			</div>{" "}
			{get(resolvedData, "pokes.length", 0) > 0 && (
				<div
					className={cx(
						classNames.paginationContainer,
						styles.paginationContainer
					)}
				>
					<DisplayResults
						resolvedData={resolvedData}
						isFetching={isFetching}
						updateOffset={updateOffset}
						offset={offset}
						limit={limit}
						setLimit={setLimit}
					/>

					<PaginationClient
						size={size}
						offset={offset}
						setOffset={updateOffset}
						total={total}
					/>
				</div>
			)}
		</div>
	);
};

export default AllProcesses;
