/*eslint-disable */
import React from "react";
import Modal from "common/modal";
import styles from "./resend-invite.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/closeModal.svg";

export default function ResendInvite({ onResend, user, onClose }) {
	return (
		<Modal
			size={"620px"}
			padding={"0px"}
			borderRadius={"12px"}
			buttonCloseClassName={"hide"}
			style={{
				background: "#FFF",
				boxShadow:
					"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
				overflow: "hidden"
			}}
		>
			<div className={styles.modalContainer}>
				<div className={styles.headerModal}>
					<h3>Re-send invitation</h3>
					<button onClick={() => onClose()}>
						<CloseIcon />
					</button>
				</div>
				<div className={styles.contenuModal}>
					<p>
						For security reasons, invitation links expire after 72 hours. <br />
						To re-send this invitation link to{" "}
						<strong>{`${user.first_name} ${user.last_name}`}</strong>, please
						click on the resend button bellow
					</p>
				</div>
				<div className={styles.footer}>
					<button className={styles.cancelBtn} onClick={() => onClose()}>
						Cancel
					</button>
					<button className={styles.submitBtn} onClick={() => onResend(user)}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<path
								d="M8.74976 11.2501L17.4998 2.50014M8.85608 11.5235L11.0462 17.1552C11.2391 17.6513 11.3356 17.8994 11.4746 17.9718C11.5951 18.0346 11.7386 18.0347 11.8592 17.972C11.9983 17.8998 12.095 17.6518 12.2886 17.1559L17.7805 3.08281C17.9552 2.63516 18.0426 2.41133 17.9948 2.26831C17.9533 2.1441 17.8558 2.04663 17.7316 2.00514C17.5886 1.95736 17.3647 2.0447 16.9171 2.21939L2.84398 7.71134C2.34808 7.90486 2.10013 8.00163 2.02788 8.14071C1.96524 8.26129 1.96532 8.40483 2.0281 8.52533C2.10052 8.66433 2.34859 8.7608 2.84471 8.95373L8.47638 11.1438C8.57708 11.183 8.62744 11.2026 8.66984 11.2328C8.70742 11.2596 8.74028 11.2925 8.76709 11.3301C8.79734 11.3725 8.81692 11.4228 8.85608 11.5235Z"
								stroke="white"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<span>Resend</span>
					</button>
				</div>
			</div>
		</Modal>
	);
}
