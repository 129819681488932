import { ExclamationIcon } from "@heroicons/react/outline";
import {
	OverlayContainer,
	OverlayProvider,
	useDateRangePicker
} from "react-aria";
import { useDateRangePickerState } from "@react-stately/datepicker";
import { DropDownInput } from "common/DropDownInput";
import React, { useRef, useState } from "react";
import { useButton, useOverlayPosition, useOverlayTrigger } from "react-aria";
import { useOverlayTriggerState } from "react-stately";
import DateField from "./DateField/DateField";
import Popover from "./Popover/Popover";
import styles from "./range-date-picker.module.scss";
import RangeCalendar from "./RangeCalendar/RangeCalendar";

// const convertToCalendarObject = date => {
// 	if (!date) return date;
// 	else if (typeof date === "number") {
// 		const dateObj = moment.unix(date);

// 		return new CalendarDate(
// 			dateObj.year(),
// 			dateObj.month() + 1,
// 			dateObj.date()
// 		);
// 	} else if (date.getFullYear)
// 		return new CalendarDate(
// 			date.getFullYear(),
// 			date.getMonth() + 1,
// 			date.getDate()
// 		);
// 	else if (date)
// 		return new CalendarDate(date.year(), date.month() + 1, date.date());
// };

// const convertRangeToCalendarObject = (start, end) => {
// 	if (!start || !end || !end._isValid || !start._isValid) {
// 		return {
// 			start: null,
// 			end: null
// 		};
// 	}
// 	const value = {
// 		start: convertToCalendarObject(start),
// 		end: convertToCalendarObject(end)
// 	};

// 	return value;
// };

// const formatDateToMoment = date => {
// 	if (!date) return date;

// 	const dateString = date.toString();
// 	const momentObject = moment(dateString, "YYYY-MM-DD");

// 	return momentObject;
// };

const RangeDatePicker = ({
	displayField = true,
	onReset,
	onChange,
	value,
	className,
	...propsRest
}) => {
	const [displayPaper, setDisplayPaper] = useState(false);
	let propsUpdated = {
		...propsRest,
		onChange: onChange,
		value: value
	};

	let state = useDateRangePickerState(propsUpdated);
	let ref = useRef();
	let { startFieldProps, endFieldProps, calendarProps } = useDateRangePicker(
		propsUpdated,
		state,
		ref
	);

	let triggerState = useOverlayTriggerState({});

	let triggerRef = React.useRef();
	let overlayRef = React.useRef();

	// Get props for the trigger and overlay. This also handles
	// hiding the overlay when a parent element of the trigger scrolls
	// (which invalidates the popover positioning).
	let { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		triggerState,
		triggerRef
	);

	// Get popover positioning props relative to the trigger
	let { overlayProps: positionProps } = useOverlayPosition({
		targetRef: triggerRef,
		overlayRef,
		placement: "bottom right",
		offset: 5,
		isOpen: displayPaper
	});

	// useButton ensures that focus management is handled correctly,
	// across all browsers. Focus is restored to the button once the
	// popover closes.
	let { buttonProps } = useButton(
		{
			onPress: () => handleInputClick()
		},
		triggerRef
	);

	// function handleChange(value) {
	// 	setStartDate(formatDateToMoment(value.start));
	// 	setEndDate(formatDateToMoment(value.end));
	// }

	const handleConfirmClick = () => {
		setDisplayPaper(false);
	};

	const handleInputClick = () => {
		setDisplayPaper(displayPaper => !displayPaper);
	};

	return (
		<div className={styles.root}>
			{displayField && (
				<DropDownInput
					{...buttonProps}
					{...triggerProps}
					ref={triggerRef}
					arrowDirection={displayPaper ? "down" : "top"}
					className={className}
					value={
						<div className={styles.fieldWrapper}>
							<DateField {...startFieldProps} />
							<span aria-hidden="true" className={styles.separator}>
								to
							</span>
							<DateField {...endFieldProps} />
							{state.validationState === "invalid" && (
								<ExclamationIcon className={styles.errorIconWrapper} />
							)}
						</div>
					}
				/>
			)}
			{displayPaper && (
				<OverlayProvider>
					<OverlayContainer>
						<Popover
							onClose={() => setDisplayPaper(false)}
							{...overlayProps}
							{...positionProps}
							ref={overlayRef}
							isOpen={displayPaper}
						>
							<RangeCalendar
								onReset={onReset}
								onConfirm={handleConfirmClick}
								{...calendarProps}
							/>
						</Popover>
					</OverlayContainer>
				</OverlayProvider>
			)}
		</div>
	);
};

RangeDatePicker.displayName = "RangeDatePicker";
export default RangeDatePicker;
