import React from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import { Button } from "common/styled/buttons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createToken } from "../actions/authActions";

export const getSwitchUserRoute = () => "/switch-user";

const SwitchUser = ({ createToken }) => {
	const schema = yup.object().shape({
		email: yup
			.string()
			.required("Please enter your email.")
			.email("Please enter a valid email."),
		password: yup.string().required("Please enter your password."),
		switched_user_id: yup.string().required("Please enter the User ID.")
	});

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema),
		shouldUnregister: false
	});
	const onSubmit = data => {
		createToken(data, true, "", null, null, true);
	};

	return (
		<>
			<BodyClassName className="sign-in fluid-container">
				<div>
					<Helmet>
						<meta
							name="viewport"
							content="width=device-width, initial-scale=1"
						/>
						<title>Switch User</title>
					</Helmet>

					<div className="center-box body-full" id="sign-in">
						<div className="card-holder">
							<div className="forms-box">
								<h2>
									<span className="underlined">Log</span>in
								</h2>
								<div>
									<form
										className="login-form"
										onSubmit={handleSubmit(onSubmit)}
									>
										<div className="fields-box">
											<div className="form-group">
												<label htmlFor="email">email</label>
												<div className="position-input-box">
													<div className="input-box">
														<input
															{...register("email")}
															id="email"
															className="form-control"
														/>
														<div className="help-block inline-error">
															{errors.email?.message}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="fields-box">
											<div className="form-group">
												<label htmlFor="password">password</label>
												<div className="position-input-box">
													<div className="input-box">
														<input
															{...register("password")}
															id="password"
															className="form-control"
															type="password"
														/>
														<div className="help-block inline-error">
															{errors.password?.message}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="fields-box">
											<div className="form-group">
												<label htmlFor="switched_user_id">User ID</label>
												<div className="position-input-box">
													<div className="input-box">
														<input
															{...register("switched_user_id", {
																required: true
															})}
															className="form-control"
															id="switched_user_id"
														/>
														<div className="help-block inline-error">
															{errors.switched_user_id?.message}
														</div>
													</div>
												</div>
											</div>
										</div>

										<Button color={"primary"} block>
											Login
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</BodyClassName>
		</>
	);
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createToken
		},
		dispatch
	);

export default connect(null, mapDispatchToProps)(SwitchUser);
