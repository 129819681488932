import React from "react";
import Drawer from "rc-drawer";
import TabContainer from "./profileModalTab";
import DrawerFooter from "./profileModalTab/components/DrawerFooter";
import { Flex, Box } from "rebass";
import DrawerHeader from "./profileModalTab/components/DrawerHeader";

import { ReactComponent as CloseDrawerIcon } from "static/icons/close-drawer.svg";
import DefaultButton from "common/Buttons";
import DrawerSkeleton from "./Skeleton/DrawerSkeleton";

import { withRouter } from "react-router";

const ModalTab = ({
	openDrawer,
	closeDrawer,
	activeTab,
	setActiveTab,
	functions,
	preference,
	history,
	languages,
	skills,
	headline,
	canEdit,
	canPoke,
	canShortlist,
	togglePokeModal,
	addNoteHandler,
	shortlistHandler,
	mainFunction,
	isAnonymous,
	reference,
	linkedInProfile,
	active_company,
	isFetching,
	refetchProfileData,
	userId,
	canResendActivationEmail,
	canDelete,
	totalHistory,
	refetchNotes,
	isFetchingHistory,
	profileId
}) => {
	return (
		<Drawer
			width={`800px`}
			open={openDrawer}
			height={"100%"}
			placement={"right"}
			style={{ zIndex: 9 }}
			level={"root"}
			maskClosable={true}
			wrapperClassName="view-candidate"
			onClose={closeDrawer}
		>
			<Flex height="100%" flexDirection="column">
				<Flex pt={24} px={24} justifyContent="flex-end">
					<DefaultButton
						padding="0px 8px"
						onClick={closeDrawer}
						backgroundColor="white"
					>
						<CloseDrawerIcon />
					</DefaultButton>
				</Flex>
				{isFetching || isFetchingHistory ? (
					<DrawerSkeleton />
				) : (
					<React.Fragment>
						<Box px={24} mb={2} pb={43}>
							{" "}
							<DrawerHeader
								isAnonymous={isAnonymous}
								reference={reference}
								headline={headline}
								preference={preference}
							/>
						</Box>
						<Box height={"75%"}>
							<TabContainer
								functions={functions}
								preference={preference}
								history={history}
								languages={languages}
								skills={skills}
								headline={headline}
								activeTab={activeTab}
								setActiveTab={setActiveTab}
								canEdit={canEdit}
								mainFunction={mainFunction}
								linkedInProfile={linkedInProfile}
								active_company={active_company}
								refetchProfileData={refetchProfileData}
								userId={userId}
								openDrawer={openDrawer}
								totalHistory={totalHistory}
								refetchNotes={refetchNotes}
								isFetchingHistory={isFetchingHistory}
							/>
						</Box>
						<Box pb={24} px={24}>
							<DrawerFooter
								canPoke={canPoke}
								canShortlist={canShortlist}
								addNoteHandler={addNoteHandler}
								shortlistHandler={shortlistHandler}
								togglePokeModal={togglePokeModal}
								closeDrawer={closeDrawer}
								canResendActivationEmail={canResendActivationEmail}
								canDelete={canDelete}
								refetchProfileData={refetchProfileData}
								profileId={profileId}
								headline={headline}
							/>
						</Box>
					</React.Fragment>
				)}
			</Flex>
		</Drawer>
	);
};

export default withRouter(ModalTab);
