import { browserHistory } from "react-router";
import axiosInstance from "../../../config/axios";
import {
	HTTP_POST_METHOD,
	FREELANCER,
	CONSULTANCY,
	HTTP_FORBIDDEN
} from "config";
import { PREVIEW_JOB, TRACK_VIEWS } from "../../../config/api-endpoints";
import * as types from "./actionTypes";
import AxiosHelper from "config/axios-helper";
import toaster from "common/Toaster";

export function toggleIdentityModal() {
	return {
		type: types.TOGGLE_IDENTITY_MODAL
	};
}

export default function fetchJob(id, companyType, version) {
	let payload = {
		type: "request",
		id
	};
	return dispatch => {
		dispatch({
			type: types.FETCH_JOB
		});
		return axiosInstance({
			method: HTTP_POST_METHOD,
			data: JSON.stringify({ id, version }),
			url: PREVIEW_JOB
		})
			.then(response => {
				dispatch({
					type: types.FETCH_JOB_SUCCEEDED,
					data: response.data
				});
				dispatch(trackViews(payload));
			})
			.catch(error => {
				if (
					error?.response?.status === HTTP_FORBIDDEN &&
					(companyType === FREELANCER || companyType === CONSULTANCY)
				) {
					toaster.danger(
						"You don't have enough permissions to access this page."
					);
					browserHistory.push("/jobs/search");
				}
				dispatch({
					type: types.FETCH_JOB_FAILED,
					error
				});
			});
	};
}

export function trackViews(data) {
	return AxiosHelper.__post({
		data,
		url: TRACK_VIEWS
	});
}
