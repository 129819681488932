import React from "react";
import SkeletonComponent from "../skeleton";
import classnames from "modules/Tasks/tasks.module.scss";
import get from "lodash/get";
import DropDown from "./dropdown";

const DisplayResults = ({ resolvedData, isFetching, setLimit, limit }) => {
	const total = get(resolvedData, "pagination.total", 0);

	return (
		<div className={classnames.paginationText}>
			Showing
			<span>
				{isFetching ? (
					<SkeletonComponent width={15} height={12} borderRadius={10} />
				) : (
					<DropDown setLimit={setLimit} limit={limit} />
				)}
			</span>
			<span>
				{"of "}
				{isFetching ? (
					<SkeletonComponent width={15} height={12} borderRadius={10} />
				) : (
					total
				)}
				{" results found"}
			</span>
		</div>
	);
};

export default DisplayResults;
