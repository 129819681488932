import React from "react";
import styles from "./timesheet-entry-row.module.scss";
import { ReactComponent as ClockIcon } from "static/icons/clock.svg";
import { ReactComponent as ArrowIcon } from "static/icons/arrow-1.svg";
import { ReactComponent as CoinsIcon } from "static/icons/coins-2.svg";
import { ReactComponent as TagIcon } from "static/icons/tag.svg";
import { ReactComponent as NotificationIcon } from "static/icons/notification2.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-icon.svg";
import { ReactComponent as CommentIcon } from "static/icons/comment-2.svg";
import { IconButton } from "common/IconButton";
import Tooltip from "common/Tippy";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { connect } from "react-redux";
import { get } from "lodash";
import { useDateFormatter } from "react-aria";
import { getLocalTimeZone } from "@internationalized/date";
import { getFileExtension } from "common/Functions";

import { ReactComponent as ImageIcon } from "static/icons/image-icon.svg";
import { ReactComponent as PdfIcon } from "static/icons/download-pdf.svg";
import { EmptyStateCell } from "modules/Vacancies/common/PillRenderer/PillRenderer";

const TimesheetEntryRow = ({
	date,
	time,
	rate,
	type,
	tag,
	onDelete,
	recipet,
	comment,
	timesheet,
	calculatedAmount,
	amount,
	showAmountInDrawer = true,
	error
}) => {
	const reportingSettings = get(timesheet, `data.reporting_settings`, {});
	const showTagName = get(timesheet, `data.timesheet_tags`, [])?.length > 0;

	let formatter = useDateFormatter({
		dateStyle: "medium"
	});
	const [day, month] = formatter
		.format(date.toDate(getLocalTimeZone()))
		.split(" ");

	const displayedError = content => {
		if (Array.isArray(content) && content?.length > 1) {
			return (
				<ul className={styles.list}>
					{content.map((err, index) => (
						<li className={styles.option} key={index}>
							{err}
						</li>
					))}
				</ul>
			);
		}
		return content;
	};

	return type === "worktime" || type === "overtime" ? (
		<div className={styles.container}>
			<div className={styles.monthContainer}>
				<div className={styles.month}>{month}</div>
				<div className={styles.day}>{day}</div>
			</div>
			<div className={styles.timeContainer}>
				<ClockIcon className={styles.timeIcon} height={20} width={20} />
				<div className={styles.label}>{time}</div>
			</div>
			<div className={styles.tagContainer}>
				<ArrowIcon className={styles.arrowIcon} />
				<div className={styles.label}>{rate}%</div>
			</div>
			<div className={styles.moneyContainer}>
				{showAmountInDrawer && (
					<>
						<CoinsIcon className={styles.coinIcon} />
						<div className={styles.label}>
							<CurrencyFormatterRender
								isCent
								value={calculatedAmount}
								currency={get(reportingSettings, "currency.code")}
							/>
						</div>
					</>
				)}
			</div>
			{showTagName && (
				<div className={styles.tags}>
					<TagIcon className={styles.arrowIcon} />
					<div className={styles.label}>{tag}</div>
				</div>
			)}

			<div className={styles.closeButtoncontainer}>
				<div className={styles?.icons}>
					{error && (
						<Tooltip
							theme="dark"
							content={displayedError(error)}
							maxWidth={340}
							className={styles.errors}
						>
							<IconButton className={styles.warning}>
								<NotificationIcon />
							</IconButton>
						</Tooltip>
					)}

					<IconButton onClick={onDelete} className={styles.closeButton}>
						<CloseIcon className={styles.closeIcon} width={12} height={12} />
					</IconButton>
				</div>
			</div>
		</div>
	) : (
		<div className={styles.container}>
			<div className={styles.monthContainer}>
				<div className={styles.month}>{month}</div>
				<div className={styles.day}>{day}</div>
			</div>
			<div className={styles.moneyContainer2}>
				<CoinsIcon className={styles.coinIcon} />
				<div className={styles.label}>
					<CurrencyFormatterRender
						isCent
						value={amount}
						currency={get(reportingSettings, "currency.code")}
					/>
				</div>
			</div>
			<div className={styles.docContainer}>
				{recipet ? (
					<div className={styles.doc}>
						{getFileExtension(recipet) === "pdf" ? (
							<PdfIcon height={22} width={20} />
						) : (
							<ImageIcon height={22} width={20} />
						)}
					</div>
				) : (
					<div className={styles.doc}>
						<div
							style={{
								width: "20px",
								height: "22px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							<EmptyStateCell />
						</div>
					</div>
				)}
			</div>
			{showTagName && (
				<div className={styles.tags}>
					<TagIcon className={styles.arrowIcon} />
					<div className={styles.label}>{tag}</div>
				</div>
			)}
			<div className={styles.commentContainer}>
				<CommentIcon className={styles.commentIcon} height={20} width={20} />
				<div className={styles.comment}>
					<Tooltip theme="dark" content={comment}>
						<span>{comment}</span>
					</Tooltip>
				</div>
			</div>

			<div className={styles.closeButtoncontainer}>
				<div className={styles.icons}>
					{error && (
						<Tooltip
							theme="dark"
							content={displayedError(error)}
							className={styles.errors}
							enableAnimation
						>
							<IconButton className={styles.warning}>
								<NotificationIcon />
							</IconButton>
						</Tooltip>
					)}
					<IconButton onClick={onDelete} className={styles.closeButton}>
						<CloseIcon className={styles.closeIcon} width={12} height={12} />
					</IconButton>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	timesheet: state.timesheet
});

export default connect(mapStateToProps)(TimesheetEntryRow);
