import React from "react";
import { PDF_VIEWER_URL } from "config";
import styles from "./evaluation-template-resume.module.scss";
import { ReactComponent as Icon } from "static/icons/note-grey.svg";

const EvaluationTemplateCandidateResume = ({ path }) => {
	if (!path) {
		return (
			<div className={styles.emptyStateContainer}>
				<Icon />
				<div className={styles.title}>No resume (CV) available.</div>
				<div className={styles.description}>
					this profile Has not uploaded his cv yet
				</div>
			</div>
		);
	}

	return (
		<div className={styles.pdfViewerContainer}>
			<iframe
				width="100%"
				title="PDF Viewer"
				src={PDF_VIEWER_URL + path?.replace(/^http:\/\//i, "https://")}
			/>
		</div>
	);
};

export default EvaluationTemplateCandidateResume;
