import React from "react";
import TourPopover from "modules/user-onboarding/components/TourPopover";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import {
	ONBOARDING_COMMON_STYLES,
	TOUR_INTERFACE_NAVIGATION
} from "../../../../config/onboarding";
import { browserHistory } from "react-router";
import { useOnboardingSaveData } from "modules/user-onboarding/hooks/useOnboardingSaveData";

// customize popover component
function StepPopover(props) {
	const {
		record: { title, stepsCount, description, arrowPosition },
		events: { onNext }
	} = props;

	return (
		<TourPopover position={arrowPosition}>
			<TourPopover.Header>{title}</TourPopover.Header>
			<TourPopover.Body>{description}</TourPopover.Body>
			<TourPopover.Footer>
				{!!props.record?.step && (
					<TourPopover.Step>
						{props.record.step}/{stepsCount}
					</TourPopover.Step>
				)}
				<TourPopover.ButtonsWrapper>
					{!!props.events?.onSkip && (
						<TourPopover.Button
							variant="link"
							onClick={props.events.onSkip}
							style={{ padding: "4px 16px" }}
						>
							Skip
						</TourPopover.Button>
					)}
					<TourPopover.Button onClick={onNext}>
						{props.record?.nextButtonText || "Next"}
					</TourPopover.Button>
				</TourPopover.ButtonsWrapper>
			</TourPopover.Footer>
		</TourPopover>
	);
}

export const SELECTORS_TOUR_INTERFACE_NAVIGATION = [
	`${TOUR_INTERFACE_NAVIGATION}__0`,
	`${TOUR_INTERFACE_NAVIGATION}__1`,
	`${TOUR_INTERFACE_NAVIGATION}__2`,
	`${TOUR_INTERFACE_NAVIGATION}__3`,
	`${TOUR_INTERFACE_NAVIGATION}__4`,
	`${TOUR_INTERFACE_NAVIGATION}__5`,
	`${TOUR_INTERFACE_NAVIGATION}__6`,
	`${TOUR_INTERFACE_NAVIGATION}__7`,
	`${TOUR_INTERFACE_NAVIGATION}__8`
];

// steps
export const STEPS_TOUR_INTERFACE_NAVIGATION = [
	{
		position: p => [p.left - 375, p.bottom + 30],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[0]}`,
		content: ({ step, onNext, stepsCount, onSkip }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Profile & notifications",
					arrowPosition: "top-right",
					description:
						"Here, you can see and change the details of your company and profile as well as your notification preferences."
				}}
				events={{ onNext, onSkip }}
			/>
		)
	},
	{
		position: p => [p.left - 375, p.bottom + 30],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[1]}`,
		content: ({ step, onNext, stepsCount, onSkip }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Help center",
					arrowPosition: "top-right",
					description:
						"Whether you're looking for tips, tricks, or specific answers, our Help Center is your go-to resource for unlocking the full potential of Wiggli and finding exactly what you need."
				}}
				events={{ onNext, onSkip }}
			/>
		)
	},
	{
		position: p => [p.left - 450, p.bottom - 260],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[2]}`,
		content: ({ step, onNext, stepsCount, onSkip }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Quick add",
					arrowPosition: "right-top",
					description:
						"Experience the convenience of quick add, which offers you immediate access to all the essential actions you may require"
				}}
				events={{ onNext, onSkip }}
			/>
		)
	},
	{
		position: p => [p.left + 320, p.bottom - 820],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[3]}`,
		content: ({ step, onNext, stepsCount, onSkip }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Navigation menu",
					arrowPosition: "left-top",
					description:
						"A valuable feature that enhances your browsing journey and provides you with effortless access to all sections of the platform."
				}}
				events={{ onNext, onSkip }}
			/>
		)
	},
	{
		position: p => [p.left + 320, p.bottom - 25],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[4]}`,
		content: ({ step, onNext, stepsCount, onSkip }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Tasks",
					arrowPosition: "left-top",
					description:
						"helps you manage your time, stay organized, and efficiently complete tasks, and enabling you to maximize your productivity and achieve success."
				}}
				events={{ onNext, onSkip }}
			/>
		)
	},
	{
		position: p => [p.left + 320, p.bottom - 100],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[5]}`,
		content: ({ step, onNext, stepsCount, onSkip }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Permanent",
					arrowPosition: "left-top",
					description:
						"is where you can manage your permanent hiring process from start to finish. With our advanced features, you can easily find and hire the right candidates for your open positions."
				}}
				events={{ onNext, onSkip }}
			/>
		)
	},
	{
		position: p => [p.left + 320, p.bottom - 300],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[6]}`,
		content: ({ step, onNext, stepsCount, onSkip }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Temporary",
					arrowPosition: "left-top",
					description:
						"Manage your temporary hiring requests, freelancer, bids, interviews, and missions easily in our Temporary Hiring section. Keep track of progress, and manage contact propositions and timesheets all in one place."
				}}
				events={{ onNext, onSkip }}
			/>
		)
	},
	{
		position: p => [p.left + 320, p.bottom - 140],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[7]}`,
		content: ({ step, onNext, stepsCount, onSkip }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Suppliers",
					arrowPosition: "left-top",
					description:
						"Suppliers section is where you can create, search, and manage all your suppliers in one place. easily find new suppliers, add them to your list, and keep track of their performance."
				}}
				events={{ onNext, onSkip }}
			/>
		)
	},
	{
		position: p => [p.left + 320, p.bottom - 335],
		selector: `#${SELECTORS_TOUR_INTERFACE_NAVIGATION[8]}`,
		content: ({ step, onNext, stepsCount }) => (
			<StepPopover
				record={{
					step,
					stepsCount,
					title: "Settings",
					nextButtonText: "Got it!",
					arrowPosition: "left-top",
					description:
						"here you have complete control over your preferences and configuration..."
				}}
				events={{ onNext }}
			/>
		)
	}
];

// handle rendering of each step
function ContentComponent(props) {
	const content = props.steps[props.currentStep].content;
	const isLastStep = props.currentStep === props.steps.length - 1;

	// onboarding store
	const {
		metaData,
		cleanStore,
		setMetaData,
		setIsLoading
	} = useOnboardingStore();

	const unCollapseAllMenuItems = () => {
		// redirect to home and collapse menu items
		const homeLinkSelector = `${TOUR_INTERFACE_NAVIGATION}__home`;
		window.document.getElementById(homeLinkSelector).firstChild.click();
	};

	// last step close tour
	const onTourClose = (fromSkip = true) => {
		if (fromSkip) {
			props.setIsOpen(false); // close tour
			unCollapseAllMenuItems();
			cleanStore(); // clean onboarding store
			browserHistory.push("/settings/setup-guide");
		} else {
			// props.setSteps([]); // todo have to clean steps
			if (metaData?.showStartRecrutmentModal) {
				setMetaData({ ...metaData, showModal: true });
			} else {
				cleanStore(); // clean onboarding store
				browserHistory.push("/settings/setup-guide");
			}
		}
	};

	// persist onboarding user settings
	const { persistData } = useOnboardingSaveData({
		onTourClose: () => onTourClose(false)
	});

	const onNext = () => {
		if (isLastStep) {
			props.setIsOpen(false); // close tour
			unCollapseAllMenuItems();
			setIsLoading(true);
			persistData();
			return;
		}

		// fifth step ( tasks link )
		if (props.currentStep === 3) unCollapseAllMenuItems();

		// sixth step ( permanent group menu ) and all steps after
		if (props.currentStep >= 4) {
			// expand group menu items
			window.document
				.getElementById(
					SELECTORS_TOUR_INTERFACE_NAVIGATION[props.currentStep + 1]
				)
				.firstChild.firstChild.click();
		}

		props.setCurrentStep(s => s + 1);
	};

	if (typeof content === "function") {
		return content({
			onNext,
			step: props.currentStep + 1,
			stepsCount: props.steps.length,
			onSkip: () => onTourClose()
		});
	}

	return content;
}

// navigation interface config
export default function useTourInterfaceNavigationConfig() {
	return {
		ContentComponent,
		onClickMask: () => null,
		disableInteraction: true,
		styles: ONBOARDING_COMMON_STYLES,
		disableKeyboardNavigation: true
	};
}
