import * as React from "react";
import styles from "./note-drawer.module.scss";
import { ReactComponent as PhoneIcon } from "static/icons/drawer-notes/phoneIcon.svg";
import { ReactComponent as MailIcon } from "static/icons/drawer-notes/mailIcon.svg";
import { ReactComponent as LinkedInIcon } from "static/icons/drawer-notes/linkedInIcon.svg";
import { ReactComponent as GitIcon } from "static/icons/drawer-notes/gitIcon.svg";
import { ReactComponent as FacebookIcon } from "static/icons/facebookIcon.svg";
import { ReactComponent as InstagramIcon } from "static/icons/instagramIcon.svg";
import { ReactComponent as TwitterIcon } from "static/icons/twitterIcon.svg";
import { ReactComponent as YoutubeIcon } from "static/icons/youtubeIcon.svg";
import { ReactComponent as ExternalIcon } from "static/icons/external-link-svgrepo-com.svg";

import Avatar from "common/Avatar/index";
import { DEFAULT_USER_NAME } from "config";
import get from "lodash/get";
import Note from "modules/candidate/Profile/components/NotesCard/Note";
import useGetNoteDetails from "../../api/useGetNoteDetails";
import useNotesStore from "modules/notes/notes.store";
import { v4 as uuid } from "uuid";
import { historyPush } from "config/helpers";
import { head, pickBy, values } from "lodash";

const NoteDrawerBody = ({ note }) => {
	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));
	const { data } = useGetNoteDetails({
		note_id: note._id
	});
	const user = get(data, "user", {});
	const handleAddNote = () => {
		addNote({
			type: "CANDIDATE_NOTE",
			id: uuid(),
			canSelectCandidate: true,
			payload: {
				candidate: {
					value: user._id,
					label: `${user.first_name} ${user.last_name}`,
					profile_id: user._id
				}
			}
		});
	};
	const iconLink = link => {
		const url_icons = {
			linkedin: <LinkedInIcon />,
			facebook: <FacebookIcon />,
			instagram: <InstagramIcon />,
			twitter: <TwitterIcon />,
			youtube: <YoutubeIcon />,
			github: <GitIcon />
		};
		const match = pickBy(url_icons, (_, key) => link?.includes(key));
		return head(values(match)) ? head(values(match)) : <ExternalIcon />;
	};

	if (!data) return <></>;

	return (
		<>
			<div className={styles.candidateInfos}>
				<div className={styles.firstSection}>
					<div className={styles.infos}>
						<Avatar
							src={data.user.avatar}
							fallbackText={DEFAULT_USER_NAME}
							name={`${data.user.first_name} ${data.user.last_name}`}
							size={40}
							radius={8}
							style={{ marginRight: "8px" }}
						/>
						<div className={styles.candidateName}>
							<h1
								onClick={() => historyPush(`/permanent/view/${data.user._id}`)}
							>
								{data.user.first_name} {data.user.last_name}
							</h1>
							<p className={styles.userFunction}>
								{data?.user?.main_function?.name &&
								data?.user?.main_function?.seniority
									? `${get(data.user, "main_function.name")}, ${get(
											data.user,
											"main_function.seniority"
									  )}`
									: "-"}
							</p>
						</div>
					</div>
					<button className={styles.button} onClick={handleAddNote}>
						Add note
					</button>
				</div>
				<div className={styles.secondSection}>
					<div className={styles.phone}>
						<PhoneIcon />

						{`${data.user.phone?.split(" ")[1]}`}
					</div>
					<div className={styles.email}>
						<MailIcon />
						{data.user.email}
					</div>

					<div className={styles.linkedIn}>
						<a
							className={styles.btnGoToLinkedin}
							target="_blank"
							rel="noopener noreferrer"
							href={`https:www.linkedin.com/search/results/people/?firstName=${data.user.first_name}&lastName=${data.user.last_name}`}
						>
							<LinkedInIcon />
						</a>
					</div>

					{user?.link && (
						<div className={styles.github}>
							{" "}
							<a
								className={styles.btnGoToLinkedin}
								target="_blank"
								rel="noopener noreferrer"
								href={user.link}
							>
								{iconLink(user.link)}
							</a>
						</div>
					)}
				</div>
			</div>
			<div className={styles.noteContainer}>
				<Note
					key={note._id}
					note={data}
					showText
					noteContainerMaxHeight={420}
					noteDrawer
					avatarSize={56}
				/>
			</div>
		</>
	);
};

export default NoteDrawerBody;
