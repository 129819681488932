import React from "react";
import styles from "./unsaved-changes-modal.module.scss";
import ConfirmationModal, {
	Header,
	Description,
	Footer
} from "common/ConfirmationModal";

const UnsavedChangesModal = ({ onClose, onDiscard }) => {
	return (
		<ConfirmationModal onClose={onClose} zIndex={10000}>
			<Header title="Unsaved Changes" />
			<Description>
				<div className={styles.description}>
					You have unsaved changes. Are you sure you want to leave this page
					without saving?
				</div>
			</Description>
			<Footer>
				<button type="button" className={styles.cancel} onClick={onClose}>
					Keep editing
				</button>
				<button type="button" className={styles.btnDiscard} onClick={onDiscard}>
					Leave
				</button>
			</Footer>
		</ConfirmationModal>
	);
};

export default UnsavedChangesModal;
