import React, { useState, useEffect } from "react";
import Modal from "common/modal/Modal";
import EditableList from "common/editablelist";
import AxiosHelper from "config/axios-helper";
import { getLanguageTooltip } from "config/helpers";
import { GET_LANGUAGES } from "config/api-endpoints";
import { notSelectedElements } from "modules/job/selectors";

const LanguagesModal = ({
	closeModal,
	selectedLanguages = [],
	saveLanguages,
	closeOnSave = true
}) => {
	useEffect(() => {
		AxiosHelper.get({
			url: GET_LANGUAGES,
			isModal: true,
			isLogin: true
		}).then(resp => setLanguagesOptions(resp));
	}, []);

	const [error, setError] = useState(false);
	const [languages, setLanguages] = useState(selectedLanguages);
	const [languagesOptions, setLanguagesOptions] = useState([]);

	const selectLanguage = element => {
		if (element) {
			const selectedLangs = [
				...languages,
				{
					_id: element.value._id,
					name: element.label,
					score: 3
				}
			];
			setLanguages(selectedLangs);
		}
	};

	const removeLanguage = element => {
		let selectedLangs = languages.filter(l => l._id !== element.value._id);
		setLanguages(selectedLangs);
	};

	const editLanguageScore = (element, score) => {
		let selectedLangs = languages.map(l => {
			return l._id === element.value._id
				? {
						...l,
						score
				  }
				: l;
		});
		setLanguages(selectedLangs);
	};

	const save = () => {
		if ((!languages || !languages.length) && !error) {
			return setError(true);
		}
		saveLanguages(languages);
		if (closeOnSave) {
			closeModal();
		}
	};

	const formatLanguages = langs =>
		langs.map(l => ({
			value: { score: 3, ...l },
			label: l.language
		}));
	return (
		<Modal
			fixed={false}
			onClose={closeModal}
			title="Languages"
			firstButton={{
				action: save,
				type: "primary",
				label: "Save"
			}}
		>
			{error && (
				<div className="form-error">
					<span className="icon-error" />
					You are required to add at least one language in order to proceed.
				</div>
			)}
			<div className="content">
				<EditableList
					minHeight={true}
					label="Languages"
					options={formatLanguages(
						notSelectedElements(languagesOptions, languages, "name", "language")
					)}
					simpleValue={false}
					itemsLabel="LANGUAGE"
					scoreLabel="PROFICIENCY"
					scorePath="value.score"
					idPath="value._id"
					labelName="value.name"
					onRowChange={selectLanguage}
					removeSelected={removeLanguage}
					tooltipFormater={getLanguageTooltip}
					handleScoreChange={editLanguageScore}
					selectedOptions={formatLanguages(languages)}
					placeholder="Type your desired language, then select the closest match."
				/>
			</div>
		</Modal>
	);
};

export default LanguagesModal;
