import React from "react";
import {
	MenuTrigger,
	Popover,
	Menu,
	Button,
	MenuItem
} from "react-aria-components";
import { ReactComponent as ThreeDots } from "static/icons/3-dots.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete-icon.svg";
import { ReactComponent as PenIcon } from "static/icons/pen.svg";
import styles from "./request-pipeline-stage-actions-menu.module.scss";
import cx from "classnames";

const RequestPipelineStageActionsMenu = ({ onItemClick, canRename }) => {
	if (!canRename) return <></>;

	return (
		<MenuTrigger>
			<Button className={styles.triggerButton} aria-label="Menu">
				<ThreeDots height={40} width={40} />
			</Button>

			<Popover>
				<Menu className={styles.menu} onAction={onItemClick}>
					{canRename && (
						<MenuItem className={styles.menuItem} id="renameStep">
							<PenIcon />
							<div className={cx(styles.menuItemText, styles.editText)}>
								Rename step
							</div>
						</MenuItem>
					)}
					<MenuItem className={styles.menuItem} id="deleteStep">
						<DeleteIcon className={styles.deleteIcon} />
						<div className={cx(styles.menuItemText, styles.deleteText)}>
							Delete step
						</div>
					</MenuItem>
				</Menu>
			</Popover>
		</MenuTrigger>
	);
};

export default RequestPipelineStageActionsMenu;
