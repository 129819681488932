import create from "zustand";

const useElements = create(set => ({
	header: null,
	scrollarea: null,
	mainElement: null,
	setHeaderBar: elt => set({ header: elt }),
	setScrollarea: elt => set({ scrollarea: elt }),
	setMainArea: elt => set({ mainElement: elt })
}));

export default useElements;
