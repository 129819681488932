import React, { useState, useEffect } from "react";
import Modal from "../modal";
import AddressChooser from "./AddressChooser";
import { isValidAddress, handleAddressObject } from "config/helpers";
import { ReactComponent as IconSave } from "static/icons/iconSave.svg";
import style from "./adress-box.module.scss";

const initialState = {
	address: {},
	error: false,
	submitted: false
};

const getAddressOnly = (data = {}) => {
	return {
		street: data.street,
		country: data.country,
		zip: data.zip,
		city: data.city,
		number: data.number,
		box: data.box,
		latitude: data.latitude,
		longitude: data.longitude,
		iso_country: data.iso_country
	};
};

function AddressBoxModal(props) {
	const [address, setAddress] = useState(
		props.currentAddress || initialState.address
	);
	const [error, setError] = useState(false);
	const [addressTypo, setAddressTypo] = useState(false);

	useEffect(() => {
		if (!props.active) {
			setAddress(initialState.address);
			setError(initialState.error);
		} else {
			setAddress(getAddressOnly(props.currentAddress));
		}
	}, [props.active]);

	const onSave = async e => {
		e.stopPropagation();
		if (error) {
			setError(false);
		}
		if (!isValidAddress(address, props.requiredFields)) {
			return setError(true);
		}
		const finalAddress = await handleAddressObject(address, true);

		if (finalAddress?.addressTypo) {
			setAddressTypo(true);
			return setError(true);
		}
		setError(false);
		props.onSave(finalAddress?.address);
	};

	const { active, onClose, children, title, warning, label } = props;

	return (
		<Modal
			active={active}
			onClose={onClose}
			title={title}
			className={"adressBoxNext"}
			buttonCloseClassName={"closeModal"}
			titleClassName={"adressBoxNextTitle"}
		>
			<div className="help-block mt--15">{warning}</div>
			<AddressChooser
				setAddress={address => setAddress(address)}
				address={address}
				error={error}
				addressTypo={addressTypo}
				label={label}
				requiredFields={props.requiredFields}
				showAutoComplete={true}
			/>
			{children}
			<div className={style.blockCta}>
				<button type="button" onClick={onClose} className={style.cancelBtn}>
					Cancel
				</button>
				<button type="button" onClick={onSave} className={style.savelBtn}>
					<IconSave /> Save
				</button>
			</div>
		</Modal>
	);
}

export default AddressBoxModal;
