import React from "react";
import { useToggle } from "react-use";
import { XIcon } from "@heroicons/react/solid";
import IndustryFilterDialog from "./IndustryFilterDialog";
import Portal from "common/Portal";
import FilterCard from "../../../shared/FilterCard";
import s from "../ServicesFilter/service-filter.module.scss";

function IndustryFilter({ store }) {
	const [on, toggle] = useToggle(false);
	const { industry, setIndustry } = store(state => ({
		industry: state.industry,
		setIndustry: state.setIndustry
	}));

	const removeIndustry = ind => {
		setIndustry(industry.filter(i => i.value !== ind.value));
	};

	return (
		<>
			<FilterCard
				name={"Industry"}
				onEdit={toggle}
				onRemove={() => setIndustry([])}
				value={industry}
			>
				<ul className={s.list}>
					{industry?.map(ind => (
						<li className={s.listItem} key={ind.value}>
							<span>{ind.label}</span>
							<button onClick={() => removeIndustry(ind)}>
								<XIcon width={16} height={16} />
							</button>
						</li>
					))}
				</ul>
			</FilterCard>
			<Portal>
				<IndustryFilterDialog
					store={store}
					isOpen={on}
					onClose={() => toggle(false)}
				/>
			</Portal>
		</>
	);
}

export default IndustryFilter;
