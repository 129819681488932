import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import FormField from "common/FormField";
import { cancelInterview } from "../../redux/actions";
import Modal, { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { WrapperModalCancelInterview } from "../styled";

function CancelInterview({
	onClose,
	handleSubmit,
	cancelInterviewAction,
	poke_id,
	token_invitation_id,
	interview_id
}) {
	const [showWarning, setShowWarning] = useState(false);
	const stopped = () => {
		if (!showWarning) {
			return setShowWarning(true);
		}
	};

	const dataCancelInterview = fields => {
		const payload = {
			poke_id,
			interview_id,
			token_invitation_id,
			cancel_reason: fields.feedback
		};
		cancelInterviewAction(payload).then(() => {
			onClose();
		});
	};

	return (
		<>
			<Modal
				onClose={onClose}
				title={"Cancel Interview"}
				firstButton={{
					action: onClose,
					label: "Cancel",
					type: "outlined",
					large: true
				}}
				secondButton={{
					action: handleSubmit(stopped),
					label: "Cancel Interview",
					type: "primary",
					large: true
				}}
				active={!showWarning}
			>
				<div className="content">
					<WrapperModalCancelInterview>
						<div className="row">
							<div className="col-md-10">
								<p className="txt-modal">
									Use the box below to let the candidate know why you have
									decided to cancel the interview.
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<Field
									name="feedback"
									type="textarea"
									className="col-md-11"
									component={FormField}
									maxLength="500"
									placeholder={
										"Your feedback, which will be sent to the vacancy."
									}
								/>
							</div>
						</div>
					</WrapperModalCancelInterview>
				</div>
			</Modal>

			{showWarning && (
				<ConfirmationModal
					modalName="stop_job"
					onClose={() => setShowWarning(false)}
					type={ConfirmationTypes.error}
					title={"Are you sure you want to cancel this interview?"}
					firstButton={{
						action: handleSubmit(dataCancelInterview),
						label: "Yes, cancel interview",
						type: "warning"
					}}
				/>
			)}
		</>
	);
}

const mapDispatchToProps = dispatch => ({
	cancelInterviewAction: payload => dispatch(cancelInterview(payload))
});

const validate = formProps => {
	const errors = {};
	if (formProps.feedback && formProps.feedback.length > 500) {
		errors.feedback = "This field should not exceed 500 characters";
	}

	if (!formProps.feedback || formProps.feedback.trim() === "") {
		errors.feedback = "Please provide your feedback.";
	}
	return errors;
};

CancelInterview.propTypes = {
	cancelInterviewAction: PropTypes.func,
	onClose: PropTypes.func
};

export default compose(
	connect(null, mapDispatchToProps),
	reduxForm({
		form: "cancel-interview",
		validate,
		touchOnBlur: false
	})
)(CancelInterview);
