import get from "lodash/get";
import React from "react";
import { Text, Box, Flex } from "rebass";
import BoxContainer from "../boxContainer";
import ListHistory from "./listHistory";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const History = ({
	isDrag,
	setIsOpenModalTab,
	setActiveTab,
	history,
	getHistoryLoading
}) => {
	if (getHistoryLoading)
		return (
			<BoxContainer
				setIsOpenModalTab={setIsOpenModalTab}
				setActiveTab={setActiveTab}
				isDrag={isDrag}
				title="Interactions"
				isFetching={getHistoryLoading}
			>
				<Flex mb={3} alignItems="center" justifyContent="space-between">
					<Box mb={2}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={150} height={14} />
						</SkeletonTheme>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={200} height={14} />
						</SkeletonTheme>
					</Box>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={50} height={14} />
					</SkeletonTheme>
				</Flex>
				<Flex alignItems="center" justifyContent="space-between">
					<Box mb={2}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={150} height={14} />
						</SkeletonTheme>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={200} height={14} />
						</SkeletonTheme>
					</Box>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={50} height={14} />
					</SkeletonTheme>
				</Flex>
			</BoxContainer>
		);

	return (
		<BoxContainer
			setIsOpenModalTab={setIsOpenModalTab}
			setActiveTab={setActiveTab}
			isDrag={isDrag}
			title="Interactions"
			isFetching={getHistoryLoading}
		>
			<React.Fragment>
				{history.length > 0 ? (
					history.map((singleHistory, index) => {
						const fullName =
							get(singleHistory, "user_action.first_name", "") +
							" " +
							get(singleHistory, "user_action.last_name", "");
						return (
							<ListHistory
								historyType={get(singleHistory, "history_type")}
								fullName={
									fullName != " "
										? fullName
										: get(singleHistory, "user_action", "")
								}
								jobTitle={get(singleHistory, "job.title", "")}
								createdAt={get(singleHistory, "created_at")}
								isLast={index === history.length - 1}
								isFirst={index === 0}
								key={get(singleHistory, "user_action.first_name")}
							/>
						);
					})
				) : (
					<Text
						sx={{ fontSize: "16px", color: "#818a92", fontWeight: "normal" }}
					>
						No interactions to show
					</Text>
				)}
			</React.Fragment>
		</BoxContainer>
	);
};

export default History;
