import React from "react";
import DeleteButton from "modules/freelancers/components/DeleteFreelancersFilterModal/DeleteButton";
import { ReactComponent as AlertIcon } from "static/icons/alertIcon2.svg";
import Modal from "common/modal";
import { ReactComponent as CloseIcon } from "static/icons/closeModal.svg";
import styles from "./delete-invitation.module.scss";

const DeleteInvitation = ({ user, onDeleteInvitation, onClose }) => {
	return (
		<Modal
			size={"620px"}
			padding={"0px"}
			borderRadius={"12px"}
			buttonCloseClassName={"hide"}
			style={{
				background: "#FFF",
				boxShadow:
					"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
				overflow: "hidden"
			}}
		>
			<div className={styles.modalContainer}>
				<div className={styles.headerModal}>
					<h3>Delete invitation</h3>
					<button onClick={() => onClose()}>
						<CloseIcon />
					</button>
				</div>
				<div className={styles.contenuModal}>
					<div className={styles.modal__alert}>
						<AlertIcon />
					</div>
					<p className={styles.modal__text}>
						Are you sure you want to delete invitation ?
					</p>
				</div>
				<div className={styles.footer}>
					<button className={styles.cancelBtn} onClick={() => onClose()}>
						Cancel
					</button>
					<DeleteButton onClick={() => onDeleteInvitation(user)} />
				</div>
			</div>
		</Modal>
	);
};

export default DeleteInvitation;
