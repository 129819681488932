import React, { useEffect, useState } from "react";
import { browserHistory } from "react-router";
import usePoolStore from "modules/SearchCandidate/hooks/zustandPool.js";
import { useMutation, queryCache } from "react-query";
import Drawer from "rc-drawer";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { change } from "redux-form";
import { ReactComponent as ArrowLeft } from "static/icons/VectorarrowL.svg";
import { ReactComponent as ArrowRight } from "static/icons/VectorarrowR.svg";
import { ReactComponent as AddToListIcon } from "static/icons/VectoraddToVac.svg";
import { ReactComponent as AddNoteIcon } from "static/icons/VectoraddNote.svg";
import { ReactComponent as MoreOptionsIcon } from "static/icons/Vectoractions.svg";
import { ReactComponent as AddTaskIcon } from "static/icons/VectoraddTask.svg";
import { ReactComponent as SendVacancy } from "static/icons/Vectorsend.svg";
import DrawerSkeleton from "../../candidatePool/DrawerSkeleton";
import InterviewModal from "common/InterviewModal";
import AddNoteModal from "modules/candidate/components/Modals/AddNoteModal";
import ProfileActions from "common/ProfileActions";
import { Box, Flex, Button as ButtonRebass } from "rebass";

import _get from "lodash/get";
import toaster from "common/Toaster";
import HidePermanentlyModal from "common/ProfileActions/HidePermanentlyModal";
import DeleteModal from "../../candidatePool/ModalDeleteProfil";
import MailPhoneSave from "modules/candidate/components/profileClientView/HOC/mailPhoneSave";
import { getFilterSettings } from "modules/app/actions/ListsActions";
import { filterSettingsSelectors } from "modules/app/selectors";
import { authenticatedUserSelector } from "modules/bids/selectors";
import {
	permanentSelector,
	attendeesListSelector
} from "modules/candidate/redux/selectors";
import {
	fetchCandidateProfile,
	resetCandidateProfile,
	getListAttendees
} from "modules/candidate/redux/actions";
import { sendPoke } from "modules/company/actions/usersListActions";
import {
	USER_RESEND_ACTIVATION_EMAIL,
	DELETE_PROFILE
} from "config/api-endpoints";
import { client } from "lib/api-client";
import { ATTENDEES_LIST_TAG, VISIT_PROFILE } from "config";
import GlobalTooltip from "common/GlobalTooltip";
import useSendVacancy from "modules/SearchCandidate/components/SendVacancy/hooks/useSendVacancy";
import useShortList from "modules/SearchCandidate/components/ShortListDrawer/useShortList";
import DrawerTabs from "./DrawerTabs";
import { CANDIDATE } from "config";
import Header from "modules/candidate/Profile/components/Header/HeaderQuickView";
import useViewOverview from "modules/candidate/Profile/api/useOverview";
import styles from "./profile-card-drawer.module.scss";
import { v4 as uuid } from "uuid";
import useNotesStore from "modules/notes/notes.store";
import { HOW_TO_WORK_WITH_NOTES } from "config/onboarding";
import { useWorkWithNotesTour } from "modules/user-onboarding/workflows/work-with-notes";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";
const resendInvitation = data =>
	client(USER_RESEND_ACTIVATION_EMAIL, { body: data });
const deletProfile = data => client(DELETE_PROFILE, { body: data });

// TODO: check here achraf for send vaccancy button

export const InputField = ({ input: { value, onChange }, hasError }) => {
	return (
		<Box
			sx={{
				border: hasError ? "1px solid #f54953" : "none",
				borderRadius: "3px",
				width: "100%"
			}}
		>
			<input
				value={value}
				onChange={onChange}
				style={{
					border: "none",
					fontSize: "16px",
					color: "#212b36",
					marginLeft: "6px",
					background: "transparent",
					fontWeight: "normal",
					width: "100%"
				}}
			/>
		</Box>
	);
};

const ProfileCardDrawer = ({
	profileList,
	offset,
	total,
	handleNext,
	handlePrev,
	changeReduxForm,
	attendeesList,
	user,
	settings: { vacancies },
	user: { company_name, first_name: name, active_company, id },
	location: { query },
	permanent,
	getListAttendees,
	fetchCandidateProfile,
	sendPoke: sendPokeAction,
	phoneNumber,
	mail,
	setPhoneNumber,
	setMail,
	headline,
	userID,
	setUserID,
	openDrawer,
	setOpenDrawer,
	goToProfile,
	profileData,
	loading,
	cancelEdit,
	size,
	refetchProfile,
	onProfileNameClick,
	onSeeAllNotesClick
}) => {
	const { currentID, setCurrentId } = usePoolStore(state => ({
		currentID: state.currentID,
		setCurrentId: state.setCurrentId
	}));

	const { data } = useViewOverview({
		id: currentID
	});
	const { is_feature_add_additional_comment } = permanent;
	const {
		setIsOpen: openDrawerTask,
		setEntity,
		setSelectedEntityOption
	} = useAddNewTaskStore();
	const [toggleModal, setToggleModal] = useState(false);
	const [activeTab, setActiveTab] = useState("cv");
	const [selectedVacancy, setSelectedVacancy] = useState("");
	const [displayAddNoteModal, setDisplayAddNoteModal] = useState(false);
	const [
		displayHidePermanentlyModal,
		setDisplayHidePermanentlyModal
	] = useState(false);

	const [deleteProfileModal, setDeleteProfileModal] = useState(null);

	const { setIsOpen } = useSendVacancy();
	const { setIsOpen: openDrawerShortList } = useShortList();

	const {
		setIsNext,
		setIsPrevious,
		setSelectData,
		setView,
		setProfileId
	} = usePoolStore(state => ({
		setIsNext: state.setIsNext,
		setIsPrevious: state.setIsPrevious,
		isNext: state.isNext,
		isPrevious: state.isPrevious,
		setSelectData: state.setSelectData,
		setView: state.setView,
		setProfileId: state.setProfileId
	}));

	const profileIds = profileList.map(p => p.id);

	const [profileIndex, setProfileIndex] = useState(
		profileIds.indexOf(userID) < 0 ? 0 : profileIds.indexOf(userID)
	);

	useEffect(() => {
		if (userID) {
			!openDrawer && setOpenDrawer(true);
			setProfileIndex(profileIds.indexOf(userID));
			cancelEdit();
		} else {
			openDrawer && setOpenDrawer(false);
			cancelEdit();
		}
	}, [userID]);

	const isLastProfile =
		profileIndex === (total % 24) - 1 && total - offset < 24;

	const isFirstProfile = profileIndex === 0 && offset === 0;

	const fetchNewProfile = userId => {
		const userIdIndex = profileIds.indexOf(userId);
		if (userIdIndex === profileIds.length - 1) {
			setIsNext(true);
			handleNext(offset);
			return;
		}
		if (userIdIndex === 0) {
			setIsPrevious(true);
			handlePrev(offset);
			return;
		}
	};
	const handlePreviousClick = userId => {
		const userIdIndex = profileIds.indexOf(userId);
		setProfileIndex(userIdIndex - 1);

		if (userIdIndex === 0 && offset === 0) {
			return;
		}

		if (userIdIndex === 0) {
			fetchNewProfile(userId);
			return;
		}

		setUserID(profileIds[userIdIndex - 1]);
	};

	const handleNextClick = userId => {
		if (isLastProfile) {
			return;
		}
		const userIdIndex = profileIds.indexOf(userId);
		if (userIdIndex === size - 1) {
			fetchNewProfile(userId);
			return;
		}

		setProfileIndex(userIdIndex + 1);
		setUserID(profileIds[userIdIndex + 1]);
	};

	const sendPoke = values => {
		let payload;
		if (
			values &&
			values.interview &&
			_get(values.interview, "interview_start_date")
		) {
			payload = { profile_id: userID, ...values };
		} else {
			payload = {
				profile_id: userID,
				additional_comment: _get(values, "additional_comment"),
				...values.interview,
				vacancy_id: values.vacancy_id
			};
		}
		sendPokeAction({
			...payload
		}).then(() => {
			setToggleModal(!toggleModal);
			fetchCandidateProfile({ id: userID });
			setSelectedVacancy("");
		});
	};

	const fetchAttendees = selectedVacancy => {
		getListAttendees({
			tag: ATTENDEES_LIST_TAG,
			vacancy_id: _get(selectedVacancy, "_id")
		});
	};

	const changeSelectedVacancy = selectedVacancy => {
		setSelectedVacancy(selectedVacancy);
	};

	const [mutate] = useMutation(resendInvitation, {
		onSuccess: () => {
			toaster.success("The activation email has successfully been resent.");
			queryCache.invalidateQueries("search-profiles-perm");
		},
		onError: error => {
			toaster.danger(_get(error, "detail.name"));
		}
	});

	const [mutateProfil] = useMutation(deletProfile, {
		onSuccess: () => {
			toaster.success("profile Deleted");
			setUserID(null);
			queryCache.invalidateQueries("search-profiles-perm");
		},
		onError: error => {
			toaster.danger(error.detail);
		}
	});

	const sendInvitation = profilID => {
		mutate({ profile_ids: [profilID] });
	};

	const deletPendingProfile = profilID => {
		mutateProfil({ profile_ids: [profilID] });
	};

	const getActions = (id, canDelet, canResendEmail) => {
		let actions = [
			{
				key: "Hide permanently",
				label: "Hide permanently",
				action: () => {
					setDisplayHidePermanentlyModal(true);
				}
			},

			{
				key: VISIT_PROFILE,
				label: "View full profile",
				action: () => {
					goToProfile(id);
					setUserID(null);
				}
			}
		];

		if (canDelet) {
			actions.push({
				key: "Delete profile",
				label: "Delete profile",
				action: () => {
					setDeleteProfileModal(true);
				}
			});
		}
		if (canResendEmail) {
			actions.push({
				key: "RESEND EMAIL INVITATION",
				label: "Resend email invitation",
				action: () => sendInvitation(id)
			});
		}

		return actions;
	};

	const headlineEmail = _get(headline, "email");
	useEffect(() => {
		headlineEmail && !mail && setMail(headlineEmail);
	}, [headlineEmail]);

	const headlinePhone = _get(headline, "phone");
	useEffect(() => {
		headlinePhone && !phoneNumber && setPhoneNumber(headlinePhone);
	}, [headlinePhone]);

	const getFullName = () => {
		return (
			_get(profileData, "personal_profile.first_name") +
			" " +
			_get(profileData, "personal_profile.last_name")
		);
	};
	const openTaskDrawer = () => {
		const fullName = getFullName();

		openDrawerTask(true);
		setEntity(CANDIDATE);
		setSelectedEntityOption({
			label: fullName,
			value: userID
		});
		setUserID(null);
		setCurrentId(userID);
	};

	const fullName = getFullName();

	const closeDrawer = () => {
		const pathname = _get(window, "location.pathname");
		setUserID(null);
		setActiveTab("cv");

		browserHistory.push({
			pathname,
			hash: ""
		});

		queryCache.invalidateQueries("search-profiles-perm");
	};

	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));
	const showPopup = () => {
		closeDrawer();
		addNote({
			id: uuid(),
			canSelectCandidate: true,
			type: "QUICK_NOTE",
			payload: {
				candidate: {
					value: _get(profileData, "user_id", ""),
					label: fullName,
					profile_id: userID
				}
			}
		});
	};

	useWorkWithNotesTour({
		switchToNotesTab: () => setActiveTab("notes")
	});

	return (
		<Drawer
			open={openDrawer}
			width={`733px`}
			height={"100%"}
			placement={"right"}
			style={{ zIndex: 1 }}
			level={"root"}
			maskClosable={true}
			onClose={() => closeDrawer()}
			className={"profile-drawer"}
		>
			{loading ? (
				<DrawerSkeleton />
			) : (
				<>
					<Box
						data-onboarding-step={`${HOW_TO_WORK_WITH_NOTES}-1`}
						sx={{
							height: "100%"
						}}
					>
						{/* component Profile */}
						<Header
							data={data}
							displayQuickView={false}
							setOpenDrawer={setOpenDrawer}
							onProfileNameClick={onProfileNameClick}
						/>
						<Flex
							sx={{
								minHeight: "calc(100% - 22%) ",
								flexDirection: "column"
							}}
						>
							<Flex
								flexDirection="column"
								mt={16}
								sx={{ flexGrow: "1" }}
								position="relative"
							>
								<DrawerTabs
									active_company={active_company}
									userId={id}
									refetchProfileData={refetchProfile}
									profileData={profileData}
									headline={headline}
									activeTab={activeTab}
									setActiveTab={setActiveTab}
									onSeeAllNotesClick={onSeeAllNotesClick}
								/>
							</Flex>
						</Flex>
						<Box sx={{ height: "72px", padding: "0px 24px" }}>
							<Flex
								pt={16}
								pb={16}
								justifyContent="space-between"
								alignItems="center"
							>
								<Flex>
									<ButtonRebass
										mr={32}
										onClick={() =>
											handlePreviousClick(_get(profileData, "user_id"))
										}
										sx={{
											border: "1px solid #A7ACB1",
											padding: "8px 12px 8px 12px",
											display: "flex",
											alignItems: "center",
											fontFamily: "BasierCircle",
											fontSize: "16px",
											color: "#212b36",
											background: "none",
											outline: "none",
											"&[disabled]": {
												cursor: "not-allowed",
												opacity: "0.3"
											}
										}}
										disabled={isFirstProfile}
									>
										<ArrowLeft />
									</ButtonRebass>
									<ButtonRebass
										onClick={() =>
											handleNextClick(_get(profileData, "user_id"))
										}
										sx={{
											marginLeft: "-20px",
											border: "1px solid #A7ACB1",
											padding: "8px 12px 8px 12px",
											display: "flex",
											alignItems: "center",
											fontFamily: "BasierCircle",
											fontSize: "16px",
											color: "#212b36",
											background: "none",
											outline: "none",
											"&[disabled]": {
												cursor: "not-allowed",
												opacity: "0.3"
											}
										}}
										disabled={isLastProfile}
									>
										<ArrowRight />
									</ButtonRebass>
								</Flex>
								<Box>
									<Flex>
										<GlobalTooltip
											overlay={
												!_get(profileData, "can_poke")
													? "This action requires the email of candidate"
													: "Send vacancy"
											}
											eventTrigger="hover"
											placement={"top"}
											overlayClassName={"g-tool-dark"}
										>
											<ButtonRebass
												className={
													!_get(profileData, "can_poke") ? "disabled" : null
												}
												mr={16}
												p={0}
												sx={{
													border: "1px solid #A7ACB1",
													background: "white",
													height: "32px",
													width: "30px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													outline: "none",
													"&.disabled": {
														cursor: "not-allowed"
													}
												}}
												onClick={
													_get(profileData, "can_poke")
														? () => {
																setSelectData({
																	mode: "select",
																	selected_candidates: [
																		_get(profileData, "user_id")
																	],

																	preview_mode: {
																		first_name: _get(profileData, "first_name"),
																		last_name: _get(profileData, "last_name")
																	},
																	total_item: 1
																});

																setView("candidate");
																setProfileId(_get(profileData, "user_id"));
																setIsOpen(true);
														  }
														: () => {}
												}
											>
												<SendVacancy />
											</ButtonRebass>
										</GlobalTooltip>
										<GlobalTooltip
											overlay={"Add to vacancy"}
											eventTrigger="hover"
											placement={"top"}
											overlayClassName={"g-tool-dark"}
										>
											<ButtonRebass
												mr={16}
												p={0}
												sx={{
													border: "1px solid #A7ACB1",
													background: "white",
													height: "32px",
													width: "30px",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													outline: "none",
													["&.disabled"]: {
														cursor: "not-allowed",
														opacity: "0.5"
													}
												}}
												// disabled={!_get(profileData, "can_shortlist")}
												onClick={() => {
													setSelectData({
														mode: "select",
														selected_candidates_for_ShortList: [
															_get(profileData, "user_id")
														],

														total_item: 1
													});
													setProfileId(_get(profileData, "user_id"));
													setUserID(null);
													openDrawerShortList(true);
												}}
											>
												<AddToListIcon />
											</ButtonRebass>
										</GlobalTooltip>
										<GlobalTooltip
											overlay={"Add task"}
											eventTrigger="hover"
											placement={"top"}
											overlayClassName={"g-tool-dark"}
										>
											<ButtonRebass
												mr={16}
												p={0}
												sx={{
													height: "32px",
													width: "30px",
													display: "flex",
													alignItems: "center",
													border: "1px solid #A7ACB1",
													background: "white",
													justifyContent: "center",
													outline: "none"
												}}
												onClick={openTaskDrawer}
											>
												<AddTaskIcon />
											</ButtonRebass>
										</GlobalTooltip>
										<GlobalTooltip
											overlay={"Add note"}
											eventTrigger="hover"
											placement={"top"}
											overlayClassName={"g-tool-dark"}
										>
											<ButtonRebass
												mr={16}
												p={0}
												sx={{
													height: "32px",
													width: "30px",
													display: "flex",
													alignItems: "center",
													border: "1px solid #A7ACB1",
													background: "white",
													justifyContent: "center",
													outline: "none"
												}}
												onClick={showPopup}
											>
												<AddNoteIcon />
											</ButtonRebass>
										</GlobalTooltip>
										<ButtonRebass
											p={0}
											sx={{
												height: "32px",
												width: "30px",
												display: "flex",
												alignItems: "center",
												border: "1px solid #A7ACB1",
												background: "white",
												justifyContent: "center",
												outline: "none",
												"& > svg": {
													width: "100%"
												}
											}}
										>
											<ProfileActions
												options={getActions(
													_get(profileData, "user_id"),
													_get(profileData, "can_delete"),
													_get(profileData, "can_resend_activation_email")
												)}
											>
												<div className={styles.moreOptionsContainer}>
													<MoreOptionsIcon />
												</div>
											</ProfileActions>
										</ButtonRebass>
									</Flex>
								</Box>
							</Flex>
						</Box>

						{/* End component Profile */}
					</Box>
				</>
			)}

			{toggleModal && (
				<InterviewModal
					vacancies={vacancies}
					company_name={company_name}
					first_name={name}
					candidate_first_name={_get(profileData, "first_name")}
					toggleModal={() => setToggleModal(!toggleModal)}
					changeReduxForm={changeReduxForm}
					vacancyId={_get(query, "vacancy", "")}
					changeSelectedVacancy={changeSelectedVacancy}
					selectedVacancy={selectedVacancy}
					user={user}
					companyType={_get(user, "company_type")}
					sendInterview={sendPoke}
					enableAdditionalComment={is_feature_add_additional_comment}
					attendeesList={attendeesList}
					fetchAttendees={fetchAttendees}
				/>
			)}

			{displayAddNoteModal && (
				<AddNoteModal
					profile_id={userID}
					nextAction={() => fetchCandidateProfile({ id: userID })}
					onClose={() => setDisplayAddNoteModal(false)}
				/>
			)}
			<HidePermanentlyModal
				active={displayHidePermanentlyModal}
				closeModal={() => setDisplayHidePermanentlyModal(false)}
				profile_id={userID}
				nextAction={() => {
					queryCache.invalidateQueries("search-profiles-perm");
					setUserID(null);
				}}
			/>
			{deleteProfileModal && (
				<DeleteModal
					closeModal={() => setDeleteProfileModal(false)}
					deletPendingProfile={deletPendingProfile}
					profileId={userID}
				/>
			)}
		</Drawer>
	);
};

const mapStateToProps = state => {
	return {
		settings: filterSettingsSelectors(state),
		user: authenticatedUserSelector(state),
		permanent: permanentSelector(state),
		attendeesList: attendeesListSelector(state)
	};
};
const mapDispatchToProps = dispatch => {
	return {
		getFilterSettings: payload => dispatch(getFilterSettings(payload)),
		getListAttendees: payload => dispatch(getListAttendees(payload)),
		sendPoke: payload => dispatch(sendPoke(payload)),
		fetchCandidateProfile: payload => dispatch(fetchCandidateProfile(payload)),
		resetCandidateProfile: () => dispatch(resetCandidateProfile()),
		changeReduxForm: (field, value) =>
			dispatch(change("send-poke", field, value))
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	withRouter(({ ...props }) => (
		<MailPhoneSave
			refetchProfileData={props.refetchProfile}
			headline={props.headline}
			id={props.userID}
			render={otherProps => <ProfileCardDrawer {...props} {...otherProps} />}
		/>
	))
);
