import React from "react";
import ConversationsTabList from "./components/ConversationsTabList";
import ConversationsTabViewEmail from "./components/ConversationsTabViewEmail";

export default function PermanentViewConversationsTab({ candidateData }) {
	const searchParams = new URLSearchParams(window.location.search);
	const conversationId = searchParams.get("conversationId");

	if (!candidateData) {
		return null;
	}

	return conversationId ? (
		<ConversationsTabViewEmail />
	) : (
		<ConversationsTabList candidateData={candidateData} />
	);
}
