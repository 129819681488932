import { useInfiniteQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_EMAIL_TEMPLATES } from "config/api-endpoints";
import fakeData from "modules/user-onboarding/mockData/email-templates-all.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

export const EMAIL_TEMPLATES_LIST_LIMIT = 7;

function getList(_, body, offset = 0) {
	return client(LIST_EMAIL_TEMPLATES, {
		body: {
			...body,
			offset,
			limit: EMAIL_TEMPLATES_LIST_LIMIT
		}
	});
}

export const GET_EMAIL_TEMPLATES_QUERY_KEY = "GET_EMAIL_TEMPLATES_QUERY_KEY";

export function useGetEmailTemplates(body, options = {}) {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useInfiniteQuery([GET_EMAIL_TEMPLATES_QUERY_KEY, body], () => {
			return Promise.resolve(fakeData);
		});
	}

	return useInfiniteQuery([GET_EMAIL_TEMPLATES_QUERY_KEY, body], getList, {
		...options,
		retry: 0,
		refetchOnWindowFocus: false
	});
}
