import React, { Fragment } from "react";
import Select from "react-select";
import _get from "lodash/get";
import styled from "styled-components";
import { Element } from "react-scroll";
import GlobalTooltip from "./GlobalTooltip";
import { colors } from "config/styles";

const CTAHolder = styled.div`
	background-color: ${colors.white};
	padding: 0 21px 21px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const MenuHolder = styled.div`
	max-height: 200px;
	overflow: auto;
`;

const OrContainer = styled.div`
	display: flex;
	padding: 15px 0;
	align-items: center;
	background-color: ${colors.white};
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${colors.borderColor};
`;

const Text = styled.div`
	color: ${colors.arrow};
	font-size: 13px;
	margin: 0 10px;
	font-weight: 500;
`;

const DisabledOption = styled.div`
	text-align: center;
	color: ${colors.disabledGray};
	display: block;
	padding: 15px;
	pointer-events: none;
	margin: 10px 0;
`;

const MenuWithCTA = ({
	focusedOption,
	focusOption,
	options,
	selectValue,
	cta,
	empty
}) => {
	return (
		<Fragment>
			<MenuHolder role="listbox" id="list-box">
				{options.map(option => {
					return option.disabled ? (
						<DisabledOption key={_get(option, "_id", option.value)}>
							{option.emptyText}
						</DisabledOption>
					) : (
						<>
							<div
								key={_get(option, "_id", option.value)}
								onClick={() => selectValue(option)}
								onMouseOver={() => focusOption(option)}
								className={`Select-option ${
									focusedOption && focusedOption.value === option.value
										? "is-focused"
										: ""
								}`}
							>
								{option.label}
							</div>
						</>
					);
				})}
			</MenuHolder>
			{cta && (
				<Fragment>
					{!empty && (
						<OrContainer id="or-container">
							<Divider />
							<Text>OR</Text>
							<Divider />
						</OrContainer>
					)}
					<CTAHolder id="cta-holder">{cta()}</CTAHolder>
				</Fragment>
			)}
		</Fragment>
	);
};

const CTASelect = ({
	label,
	input = {},
	meta: { touched, error } = {},
	placeholder,
	searchable,
	disabled,
	clearable,
	simpleValue,
	classes,
	toolTipOverlay,
	showErrors,
	defaultValue,
	toolTipMaxWidth,
	isLoading,
	backspaceRemoves,
	onSelectResetsInput,
	onBlurResetsInput,
	onInputChange,
	onValueClick,
	onClick,
	labelKey,
	valueKey,
	multi,
	valueToSet,
	noResultsText,
	searchPromptText,
	filterOptions,
	className,
	options,
	style,
	onOpen,
	removeSelected,
	emptyText = "You have no departments",
	cta
}) => {
	const _showError = showErrors === undefined ? touched : showErrors;
	options = JSON.parse(JSON.stringify(options || [])); // for immutable options
	let classesToAdd = _showError && error ? "has-error has-feedback" : "";
	if (label) {
		classesToAdd += " has-label";
	} else classesToAdd += " label-no";
	const _value = !!valueToSet
		? valueToSet
		: !!input.value && !onSelectResetsInput
		? input.value
		: defaultValue;

	const _onClick = event =>
		onClick && onClick({ event, name: input.name, value: _value, disabled });
	let empty = false;
	if (options && !options.length) {
		options.push({
			value: "",
			label: placeholder,
			emptyText,
			disabled: true
		});
		empty = true;
	}
	return (
		<Element
			id={input.name}
			className={`form-group select-style  ${classesToAdd} ${
				className ? className : null
			}`}
			name={input.name}
			scrollto="scroll-to-error"
			style={style}
		>
			{label && (
				<label htmlFor={input.name}>
					{label}
					{toolTipOverlay && (
						<GlobalTooltip
							maxWidth={toolTipMaxWidth}
							overlay={toolTipOverlay}
						/>
					)}
				</label>
			)}
			<div onClick={_onClick}>
				<Select
					name={input.name}
					value={_value}
					clearable={clearable}
					placeholder={placeholder}
					simpleValue={simpleValue}
					searchable={searchable}
					onChange={value => input && input.onChange && input.onChange(value)}
					onBlur={() => (input.onBlur ? input.onBlur(input.value) : null)}
					disabled={disabled}
					className={classes}
					noResultsText={noResultsText}
					isLoading={isLoading}
					backspaceRemoves={backspaceRemoves}
					onBlurResetsInput={onBlurResetsInput}
					onInputChange={onInputChange}
					labelKey={labelKey}
					valueKey={valueKey}
					onValueClick={onValueClick}
					onSelectResetsInput={onSelectResetsInput}
					searchPromptText={searchPromptText}
					filterOptions={filterOptions}
					removeSelected={removeSelected}
					multi={multi}
					onOpen={onOpen}
					menuRenderer={props => (
						<MenuWithCTA {...props} empty={empty} cta={cta} />
					)}
					options={options}
					menuContainerStyle={{
						border: "none"
					}}
					menuStyle={{
						maxHeight: "none",
						border: "1px solid #ccc",
						borderBottomLeftRadius: 3,
						borderBottomRightRadius: 3
					}}
				/>
				<div className="help-block inline-error">{_showError && error}</div>
			</div>
		</Element>
	);
};

export default CTASelect;
