import React from "react";
import _get from "lodash/get";
import EditableSection from "../EditableSection";
import cardStyle from "../EditableSection/editable-section.module.scss";
import CopyToClipboard from "modules/candidate/Profile/utils/copyValue";

const EmptyState = ({ message }) => {
	return (
		<div className={cardStyle.emptyContainer}>
			<p>{message}</p>
		</div>
	);
};

const PersonalProfile = ({ profileData }) => {
	const sectionProfile = _get(profileData, "update_sections.headline");

	const getPhoneNumber = phone => {
		return (
			<>
				{"+"}
				{phone.replace(/\D/g, "")}
			</>
		);
	};

	return (
		<React.Fragment>
			<EditableSection
				title="Personal profile"
				hasEdit={false}
				sectionUpdate={sectionProfile}
			>
				<div className={cardStyle.personalContainer}>
					<div className={cardStyle.personalRow}>
						<div className={cardStyle.profileBox}>
							<div className={cardStyle.profileLabel}>First name</div>
							{_get(profileData, "personal_profile.first_name", "") ? (
								<div className={cardStyle.profileInfo}>
									<CopyToClipboard
										text={_get(profileData, "personal_profile.first_name", "")}
									>
										{_get(profileData, "personal_profile.first_name", "")}
									</CopyToClipboard>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
						<div className={cardStyle.profileBox}>
							<div className={cardStyle.profileLabel}>Email</div>
							{_get(profileData, "personal_profile.email", "") ? (
								<div className={cardStyle.profileInfo}>
									<CopyToClipboard
										text={_get(profileData, "personal_profile.email", "")}
									>
										{_get(profileData, "personal_profile.email", "")}
									</CopyToClipboard>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
					<div className={cardStyle.personalRow}>
						<div className={cardStyle.profileBox}>
							<div className={cardStyle.profileLabel}>Last name</div>
							{_get(profileData, "personal_profile.last_name", "") ? (
								<div className={cardStyle.profileInfo}>
									<CopyToClipboard
										text={_get(profileData, "personal_profile.last_name", "")}
									>
										{_get(profileData, "personal_profile.last_name", "")}
									</CopyToClipboard>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
						<div className={cardStyle.profileBox}>
							<div className={cardStyle.profileLabel}>Phone number</div>
							{_get(profileData, "personal_profile.phone", "") ? (
								<div className={cardStyle.profileInfo}>
									<CopyToClipboard
										text={_get(
											profileData,
											"personal_profile.phone",
											""
										).replace(/\D/g, "")}
									>
										{getPhoneNumber(
											_get(profileData, "personal_profile.phone", "")
										)}
									</CopyToClipboard>
								</div>
							) : (
								<EmptyState />
							)}
						</div>
					</div>
				</div>
			</EditableSection>
		</React.Fragment>
	);
};
EmptyState.defaultProps = {
	message: "N/A"
};
export default PersonalProfile;
