import { client } from "lib/api-client";
import { UPDATE_INTERNAL_PROFILE_DATA } from "config/api-endpoints";
import { useMutation } from "react-query";

const updateInternalData = body =>
	client(UPDATE_INTERNAL_PROFILE_DATA, {
		body
	});

function useUpdateInternalData(options) {
	return useMutation(updateInternalData, options);
}

export default useUpdateInternalData;
