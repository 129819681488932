import React from "react";
import ChannelsFilters from "./filters";
import ChannelsResult from "./result";
import s from "./add-contract.module.scss";

const AddContract = () => {
	return (
		<div className={s.root}>
			<ChannelsFilters />
			<ChannelsResult />
		</div>
	);
};

export default AddContract;
