// TODO: split file into container and view, move actions to redux
import React, { Component } from "react";
import {
	reduxForm,
	Field,
	formValueSelector,
	change,
	clearFields
} from "redux-form";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import styled from "styled-components";
import _get from "lodash/get";
import FormField from "common/FormField";
import { closeModal, reloadSection, loadProfile } from "../../actions";
import StyledSelect from "common/StyledSelect";
import DatePickerField from "common/DatePickerField";
import {
	AVAILABILITY_STATUS,
	IN_MISSION,
	NOT_AVAILABLE,
	DATE_FORMAT,
	HEADLINE,
	TEXTAREA_MAX_CHARS,
	CONSULTANCY,
	PROJECT_MANAGER
} from "config";
import { UPDATE_PROFILE_ADDITIONAL_INFO } from "config/api-endpoints";
import { formatDate } from "common/Functions";
import { getMyProfile } from "../../../user/actions/authActions";
import {
	cvHeadlineSelector,
	cvAddressSelector
} from "../../selectors/cvSelectors";
import PhoneNumberField from "common/PhoneNumberField";
import Modal from "common/modal";
import { utcTimeStamp } from "config/helpers";
import AxiosHelper from "config/axios-helper";
import { GetBUSelect } from "../../../consultancy/components/bum/BUMSecondStep";
import { authenticatedUserSelector } from "../../../bids/selectors";
import { prepareForSelect } from "common/Functions";
import {
	getFunctionList,
	getSectorList
} from "../../../consultancy/redux/actions";
import { userBUListSelector } from "../../../company/selectors/createCompanySelectors";
import { getUsers } from "../../../company/actions/usersListActions";
import { FREELANCER, ASK_QUESTION, ACCEPTED } from "../../../../config";
import { toggleHelpModal } from "../../../app/actions/ListsActions";

class PersonalInfoModal extends Component {
	state = {
		isVisible: false,
		functions: [],
		sectors: [],
		rawSectors: [],
		subSectors: []
	};

	componentDidMount() {
		this.getSectors();
		const company_type = _get(this, "props.user.company_type");
		// load only for consultants
		if (!this.props.userBUList.length && company_type === CONSULTANCY) {
			this.props.dispatch(getUsers());
		}
	}

	componentDidUpdate({ fields: { category, sector } }) {
		const currentSector = _get(this.props.fields, "sector");
		const currentCategory = _get(this.props.fields, "category");
		if (
			!this.state.subSectors.length &&
			category &&
			this.props.category !== category
		) {
			for (const cat of this.state.sectors) {
				if (cat.value === _get(this.props, "category.value")) {
					const subSectors = this.prepareForSelect(cat.children);
					this.setState({ subSectors });
					break;
				}
			}
		}
		if (
			category &&
			currentCategory &&
			(category._id !== currentCategory._id || category !== currentCategory)
		) {
			const {
				headline: { sector }
			} = this.props;
			getSectorList().then(({ sectors, rawSectors }) => {
				let subSectors = [];
				rawSectors.map(raw => {
					if (raw._id === currentCategory._id) {
						subSectors = this.prepareForSelect(raw.children);
						this.changeField("sector", sector._id);
						this.changeField("function", null);
					}
				});

				this.setState({
					sectors,
					rawSectors,
					subSectors
				});
			});
		}
		if (
			sector &&
			currentSector &&
			(sector._id !== currentSector._id || sector !== currentSector)
		) {
			const validSector = _get(currentSector, "_id") || currentSector;
			this.getFunctions(validSector);
		}
	}

	getFunctions = id => {
		if (!id) return;
		getFunctionList(id, true).then(data =>
			this.setState({
				functions: data
			})
		);
	};

	changeField = (field, value) => {
		this.props.dispatch(change("personal-info", field, value));
	};

	getSectors = () => {
		const {
			headline: { sector }
		} = this.props;
		getSectorList().then(({ sectors, rawSectors }) => {
			let subSectors = [];
			this.changeField("category", sector ? sector.parent_sector._id : "");
			for (const category of rawSectors) {
				if (sector && category._id === sector.parent_sector._id) {
					subSectors = this.prepareForSelect(category.children);
					this.changeField("sector", sector._id);
					break;
				}
			}
			this.setState({
				sectors,
				rawSectors,
				subSectors
			});
		});
	};

	prepareForSelect(sectors) {
		const newSectors = [];
		for (const sector of sectors) {
			newSectors.push({
				value: sector._id,
				label: sector.name,
				children: sector.children || null
			});
		}
		return newSectors;
	}

	closeModal = e => {
		if (e) e.preventDefault();
		this.props.dispatch(closeModal(!!e));
	};

	updateInfo = formProps => {
		const data = { ...formProps };

		if (
			data.availability_status &&
			typeof data.availability_status !== "string"
		) {
			data.availability_status = data.availability_status.value;
		}
		if (data.availability_date) {
			data.availability_date = utcTimeStamp({
				date: data.availability_date,
				format: DATE_FORMAT
			});
		}
		if (data.phone) {
			data.phone = data.phone.formattedValue;
		}

		if (this.props.userId) {
			data.profile_id = this.props.userId;
		}

		if (data.groups && data.groups.value) {
			data.group_id = data.groups.value;
			data.role_name = PROJECT_MANAGER;
		}

		if (this.props.userStatus) {
			data.status = this.props.userStatus;
		}

		const isConsultancy = this.props.user.company_type === CONSULTANCY;

		AxiosHelper.post({
			data,
			url: UPDATE_PROFILE_ADDITIONAL_INFO,
			toastMessage: "Info updated!",
			reduxFormName: "personal-info",
			next: isConsultancy ? () => loadProfile(this.props.userId) : getMyProfile,
			isModal: true
		}).then(() => {
			this.props.dispatch(reloadSection(HEADLINE, this.props.userId));
			this.closeModal();
		});
	};

	renderAvailabilityDate = () => {
		const { availability_status } = this.props.fields;
		let inMission = false;
		if (availability_status && typeof availability_status === "object") {
			inMission =
				availability_status.value === IN_MISSION ||
				availability_status.value === NOT_AVAILABLE;
		} else if (availability_status && typeof availability_status === "string") {
			inMission =
				availability_status === IN_MISSION ||
				availability_status === NOT_AVAILABLE;
		}

		return (
			inMission && (
				<div className="col-md-6">
					<Field
						classes="inner-icon left-icon"
						icon="fa fa-calendar"
						name="availability_date"
						minDate={window.moment()}
						label="Availability Date"
						component={DatePickerField}
					/>
				</div>
			)
		);
	};

	resetField = (value1, value2, value3) => {
		this.props.dispatch(
			clearFields("personal-info", false, false, value1, value2, value3)
		);
	};

	resetSeniorityFunctionSector = () => {
		this.resetField("sector", "seniority", "function");
	};

	resetSeniorityFunction = () => {
		this.resetField("seniority", "function");
		this.changeField("function", null);
	};

	resetSeniority = () => {
		this.resetField("seniority");
	};

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	render() {
		const {
			handleSubmit,
			user: { role_name, company_type, is_member } = {},
			address,
			address: { street, city, country, number, box, zip },
			userBUList,
			toggleHelpModal,
			userStatus
		} = this.props;
		const isNotInvited = userStatus === ACCEPTED;
		const ContactUs = styled.span`
			color: #ff6849;
			background: none;
			border: none;
			padding: 0 !important;
			font: inherit;
			cursor: pointer;
			outline: none;
		`;
		const isConsultancy = company_type === CONSULTANCY;
		const isFreelancer = company_type === FREELANCER;
		const _address =
			Object.keys(address).length !== 0
				? `${street && `${street},`} ${number && `${number}`}${box &&
						`/${box},`} ${zip && ` ${zip} `} ${city && `${city},`} ${country &&
						country}`
				: "...";
		return (
			<Modal
				id="personal-info"
				title="Basic Information"
				onClose={this.closeModal}
				firstButton={{
					label: "Save to my profile",
					type: "primary",
					action: handleSubmit(this.updateInfo)
				}}
			>
				<div className="content">
					<form onSubmit={handleSubmit(this.updateInfo)}>
						<div className="row">
							<div className="col-md-11">
								{isFreelancer && (
									<div className="row">
										<div className="col-md-12">
											<p
												style={{
													fontSize: "14px",
													fontWeight: "400",
													marginBottom: "30px"
												}}
											>
												You can modify, change or delete your personal
												information at any time by contacting us or by modifying
												your information directly on this page. Any questions
												about your privacy ?{" "}
												<ContactUs
													onClick={() => toggleHelpModal(ASK_QUESTION)}
												>
													Contact us.
												</ContactUs>
											</p>
										</div>
									</div>
								)}
								{isConsultancy && (
									<div className="row">
										<div className="col-md-6 form-group">
											<label>Business unit</label>
											<GetBUSelect
												options={prepareForSelect(userBUList)}
												onClose={this.closeModal}
												name="groups"
												role_name={role_name}
												is_member={is_member}
												label={true}
											/>
										</div>
									</div>
								)}
								<div className="row">
									<div className="col-md-6">
										<Field
											name="first_name"
											label="First name"
											component={FormField}
										/>
									</div>
									<div className="col-md-6">
										<Field
											name="last_name"
											label="Last name"
											component={FormField}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<Field
											name="email"
											normalize={this.normalizeEmail}
											label="Email"
											disabled={isNotInvited ? true : false}
											toolTipOverlay={
												isNotInvited ? "This value can't be edited." : null
											}
											component={FormField}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<Field
											name="phone"
											component={PhoneNumberField}
											placeholder=" "
											label="Phone"
										/>
									</div>
									<div className="col-md-6">
										<Field
											name="address_readonly"
											label="Location"
											disabled={true}
											component={FormField}
											defaultValue={_address}
											inputClasses="withEllipsis"
											toolTipOverlay={
												isConsultancy
													? "To edit this part go to the “WHAT I WANT” section of your profile."
													: "You can edit the location by modifying it in the expectations tab"
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<Field
											name="availability_status"
											label="My Status"
											options={AVAILABILITY_STATUS}
											placeholder="Choose a Status"
											position={false}
											component={StyledSelect}
										/>
									</div>
									{this.renderAvailabilityDate()}
								</div>

								<div className="row">
									<div className="col-md-12">
										<Field
											name="about_me"
											label="About me"
											type="textarea"
											component={FormField}
											maxLength={TEXTAREA_MAX_CHARS}
										/>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		);
	}
}

const validate = props => {
	const errors = {};
	const fields = ["first_name", "last_name"];

	for (const field of fields) {
		if (!props[field] || props[field].trim() === "") {
			errors[field] = "This field is required.";
		}
	}
	if (!props.email) {
		errors.email = "This field is required.";
	}
	if (!props.phone || _get(props, "phone.value.length", 0) === 0) {
		errors.phone = "This field is required.";
	}
	if (!props.availability_date) {
		errors.availability_date = "This field is required.";
	}
	if (!props.availability_status) {
		errors.availability_status = "Please select a status.";
	}
	if (props.about_me && props.about_me.length > TEXTAREA_MAX_CHARS) {
		errors.about_me = `This field should not exceed ${TEXTAREA_MAX_CHARS} characters`;
	}
	return errors;
};

const selector = formValueSelector("personal-info");

const mapStateToProps = (state, props) => {
	const headline = cvHeadlineSelector(state);

	const _function = _get(headline, "function._id");

	const initialValues = {
		...headline,
		groups: props.group,
		function: _function,
		availability_date: headline.availability_date
			? formatDate(headline.availability_date, true)
			: window.moment()
	};

	return {
		user: authenticatedUserSelector(state),
		selectedFunctions: state.cv.functions,
		headline: {
			...headline,
			function: _function
		},
		userBUList: userBUListSelector(state),
		address: cvAddressSelector(state),
		initialValues,
		fields: {
			...selector(
				state,
				"first_name",
				"last_name",
				"category",
				"sector",
				"email",
				"phone",
				"about_me",
				"availability_status"
			)
		}
	};
};
const mapDispatchToProps = dispatch =>
	bindActionCreators({ toggleHelpModal }, dispatch);

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "personal-info",
		validate,
		touchOnBlur: false
	})
)(PersonalInfoModal);
