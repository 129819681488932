import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_CANDIDATE_FILE_ENDPOINT } from "config/api-endpoints";

function deleteCandidateFiles(body) {
	return client(DELETE_CANDIDATE_FILE_ENDPOINT, {
		body
	});
}

function useDeleteCandidateFile(queryOptions) {
	return useMutation(deleteCandidateFiles, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
}

export default useDeleteCandidateFile;
