import React, { useContext, useState, useEffect, createRef } from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { BotContext } from "./botContext";
import { Button } from "common/styled/buttons";
import { EUR } from "config";
import toaster from "common/Toaster";

const inputRef = createRef();

const InputContainer = styled.form`
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
`;

const SaveButton = styled(Button)`
	width: 40px;
	height: 40px;
	border-radius: 9999px;
	color: white !important;
	position: absolute;
	right: 10px;
	> span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

function InputAnswer({
	triggerNextStep,
	emulateSubmitUserMessage,
	trigger,
	placeholder,
	actionType,
	value: propsValue,
	numbersOnly = false,
	min,
	max,
	maxLength,
	errorMessage
}) {
	const { state, dispatch } = useContext(BotContext);
	const [value, setValue] = useState();
	const [error, setError] = useState(undefined);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (!value) {
			return setError("Please specify an answer!");
		} else {
			if (numbersOnly) {
				if (value < min || value > max) {
					return setError(errorMessage);
				} else {
					return setError(undefined);
				}
			} else {
				if (value.length > maxLength) {
					return setError(errorMessage);
				} else {
					return setError(undefined);
				}
			}
		}
	}, [value, min, max, errorMessage, numbersOnly]);

	const handleSubmit = e => {
		e.preventDefault();
		if (error) {
			return toaster.danger(error, { id: "error" });
		}
		emulateSubmitUserMessage(numbersOnly ? `${value} ${EUR}` : value);
		dispatch({
			type: actionType,
			value: numbersOnly ? parseFloat(value) : value
		});
		triggerNextStep({ value, trigger });
	};

	const disabled = !!state[propsValue];

	return (
		<InputContainer onSubmit={disabled ? null : handleSubmit}>
			<div className={`form-group`} style={{ width: "50%" }}>
				<div className="input-box">
					{numbersOnly ? (
						<NumberFormat
							className={`form-control`}
							value={value}
							suffix={EUR}
							fixedDecimalScale={true}
							allowNegative={false}
							onValueChange={e => setValue(e.value)}
							autoComplete="off"
							disabled={disabled}
							placeholder={placeholder}
							getInputRef={ref => (inputRef.current = ref)}
						/>
					) : (
						<input
							className={`form-control`}
							placeholder={placeholder}
							type={"text"}
							onChange={e => setValue(e.target.value)}
							value={value}
							disabled={disabled}
							ref={inputRef}
						/>
					)}
				</div>
			</div>
			<SaveButton small type="submit" disabled={disabled || !value}>
				<span className="icon icon-arrow-right1" />
			</SaveButton>
		</InputContainer>
	);
}

export default InputAnswer;
