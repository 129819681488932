import BackButton from "common/Buttons/BackButton";
import React, { useState } from "react";
import { Flex, Box } from "rebass";
import CandidateDetails from "./candidateDetails";
import CvContent from "./CvViewer";
import Header from "./Header";
import ModalTab from "./modalTab";
import ProfileSkeleton from "./Skeleton/ProfileSkeleton";
import NoteModal from "./NoteModal";
import PokeModal from "./PokeModal";
import { useQuery } from "react-query";
import ShortlistModal from "./ShortlistModal";
import { client } from "lib/api-client";
import { VIEW_USER_NOTES, VIEW_USER_HISTORY } from "config/api-endpoints";
import get from "lodash/get";
import { searchParser } from "config/helpers";
import { CANDIDATE_POOL } from "config";
import ShortListDrawer from "modules/vacancy/components/candidatePool/ShortListDrawer";

const getHistory = (_, { profileId }) =>
	client(VIEW_USER_HISTORY, {
		body: {
			id: profileId,
			limit: 3
		}
	});
const getNotes = (_key, { profileId }) => {
	return client(VIEW_USER_NOTES, {
		body: {
			id: profileId,
			limit: 3
		}
	});
};

const ProfileClientView = ({
	functions,
	preference,
	languages,
	skills,
	headline,
	isAnonymous,
	reference,
	pdf_doc,
	canEdit,
	canPoke,
	canShortlist,
	profileActions,
	isFetching,
	cardLabels,
	mainFunction,
	totalViews,
	lastViewedBy,
	usersViewsData,
	linkedInProfile,
	active_company,
	userId,
	refetchProfileData,
	createdBy,
	canResendActivationEmail,
	canDelete,
	profileId
}) => {
	const [activeTab, setActiveTab] = useState("personal");
	const [isOpenModalTab, setIsOpenModalTab] = useState(false);
	const [isAddMailToPoke, setIsAddMailToPoke] = useState(false);
	const [displayAddNoteModal, setDisplayAddNoteModal] = useState(false);
	const [displayShortlistModal, setDisplayShortlistModal] = useState(false);
	const [displayModal, setDisplayModal] = useState(false);
	const togglePokeModal = () => {
		setDisplayModal(!displayModal);
	};

	const {
		data,
		refetch: refetchInteraction,
		status: fetchingHistoryStatus,
		isFetching: isFetchingHistory
	} = useQuery(
		["interactions", { profileId }],
		getHistory,

		{
			refetchOnWindowFocus: false
		}
	);

	const {
		data: resolvedData,
		refetch: refetchNotes,
		status: fetchingNotesStatus
	} = useQuery(
		[
			"get-notes",
			{
				profileId
			}
		],
		getNotes,
		{
			refetchOnWindowFocus: false
		}
	);
	let history = get(data, "data", []);
	let notes = get(resolvedData, "data", []);
	const search = searchParser();
	const LABEL =
		get(search, "list") === CANDIDATE_POOL ? "Back to search" : "Back";

	return (
		<div className="view-candidate full-container">
			<Box px={4}>
				<BackButton
					label={LABEL}
					style={{
						fontSize: "14px",
						fontWeight: "normal",
						color: "#212b36",
						marginBottom: "10px",
						width: "max-content"
					}}
				/>
				{isFetching ? (
					<React.Fragment>
						<ProfileSkeleton>
							<CandidateDetails
								isFetching={isFetching}
								cardLabels={[
									"personal",
									"preferences",
									"functions",
									"skills_languages",
									"history",
									"notes"
								]}
							/>
						</ProfileSkeleton>
					</React.Fragment>
				) : (
					<React.Fragment>
						<Header
							headline={headline}
							isAnonymous={isAnonymous}
							reference={reference}
							canPoke={canPoke}
							canShortlist={canShortlist}
							togglePokeModal={togglePokeModal}
							profileActions={profileActions}
							addNoteHandler={() => setDisplayAddNoteModal(true)}
							shortlistHandler={() => setDisplayShortlistModal(true)}
							preference={preference}
							totalViews={totalViews}
							lastViewedBy={lastViewedBy}
							usersViewsData={usersViewsData}
							canEdit={canEdit}
							setIsAddMailToPoke={setIsAddMailToPoke}
							isAddMailToPoke={isAddMailToPoke}
							userId={userId}
							createdBy={createdBy}
							refetchProfileData={refetchProfileData}
							canResendActivationEmail={canResendActivationEmail}
							canDelete={canDelete}
							refetchInteraction={refetchInteraction}
							profileId={profileId}
						/>
						<Flex justifyContent="space-between">
							<Box
								flex="1"
								sx={{
									paddingRight: "27px",
									"@media screen and (min-width: 1600px)": {
										paddingRight: "32px"
									}
								}}
							>
								<CvContent pdf_doc={pdf_doc} />
							</Box>

							<CandidateDetails
								isFetching={isFetching}
								functions={functions}
								preference={preference}
								history={history}
								languages={languages}
								skills={skills}
								headline={headline}
								setIsOpenModalTab={() => setIsOpenModalTab(true)}
								setActiveTab={setActiveTab}
								canEdit={canEdit}
								cardLabels={cardLabels}
								notes={notes}
								linkedInProfile={linkedInProfile}
								mainFunction={mainFunction}
								active_company={active_company}
								refetchProfileData={refetchProfileData}
								isAddMailToPoke={isAddMailToPoke}
								getHistoryLoading={fetchingHistoryStatus === "loading"}
								getNotesLoading={fetchingNotesStatus === "loading"}
							/>
						</Flex>
					</React.Fragment>
				)}

				<ModalTab
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					openDrawer={isOpenModalTab}
					closeDrawer={() => {
						setIsOpenModalTab(false);
						setActiveTab("");
					}}
					functions={functions}
					preference={preference}
					history={history}
					languages={languages}
					skills={skills}
					headline={headline}
					canEdit={canEdit}
					canPoke={canPoke}
					canShortlist={canShortlist}
					addNoteHandler={() => setDisplayAddNoteModal(true)}
					shortlistHandler={() => setDisplayShortlistModal(true)}
					togglePokeModal={togglePokeModal}
					mainFunction={mainFunction}
					isAnonymous={isAnonymous}
					reference={reference}
					linkedInProfile={linkedInProfile}
					active_company={active_company}
					refetchProfileData={refetchProfileData}
					isFetching={isFetching}
					userId={userId}
					canResendActivationEmail={canResendActivationEmail}
					canDelete={canDelete}
					totalHistory={get(data, "pagination.total", 0)}
					refetchNotes={refetchNotes}
					isFetchingHistory={
						fetchingHistoryStatus === "loading" || isFetchingHistory
					}
					profileId={profileId}
				/>
			</Box>

			<NoteModal
				profileId={profileId}
				nextAction={refetchNotes}
				displayAddNoteModal={displayAddNoteModal}
				onClose={() => setDisplayAddNoteModal(false)}
			/>
			<ShortlistModal
				profileID={profileId}
				nextAction={refetchInteraction}
				onClose={() => setDisplayShortlistModal(false)}
				displayShortlistModal={displayShortlistModal}
			/>
			<PokeModal
				nextAction={refetchInteraction}
				displayModal={displayModal}
				toggleModal={togglePokeModal}
			/>
			<ShortListDrawer />
		</div>
	);
};

export default ProfileClientView;
