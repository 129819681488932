import { reset } from "redux-form";
import { connect } from "react-redux";
import ChangePassword from "./ChangePassword";
import { updatePassword } from "../actions/profileActions";

export const getChangePasswordRoute = () => {
	return "/settings/change-password";
};

const mapDispatchToProps = dispatch => ({
	updatePassword: params => dispatch(updatePassword(params)),
	resetFields: () => dispatch(reset("change-password"))
});

export default connect(null, mapDispatchToProps)(ChangePassword);
