import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { getDefaultValues } from "../utils/helpers";
import { SEARCH_ADDRESS } from "config";

const useProposeOfferForm = values => {
	const schema = yup.object().shape({
		contract_type: yup
			.string()
			.nullable()
			.required("please choose a contract type"),
		currency: yup
			.object({
				value: yup
					.string()
					.nullable()
					.required("Please choose a currency"),
				symbol: yup.string().nullable()
			})
			.required("please choose a currency"),
		amount: yup
			.string()
			.nullable()
			.required("please type an amount"),
		salary_type: yup.string().required("please choose a salary type"),
		payment_periode: yup.string().required("please choose a payment type"),
		start_date: yup.object().required("please choose a start date"),
		location_type: yup.string().required("please choose a location"),
		custom_address: yup
			.object()
			.test("Valid", "Please enter a valid address", async function(address) {
				if (this.parent.location_type === SEARCH_ADDRESS) {
					if (!address.street)
						return this.createError({ message: "Street is required" });
					if (!address.number)
						return this.createError({ message: "Number is required" });
					if (!address.city)
						return this.createError({ message: "City is required" });
					if (!address.zip)
						return this.createError({ message: "Zip is required" });
					if (!address.country)
						return this.createError({ message: "Country is required" });
				}
				return true;
			})
	});
	return useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false,
		defaultValues: getDefaultValues(values)
	});
};
export default useProposeOfferForm;
