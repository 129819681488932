import { useMemo } from "react";
import { capitalize } from "lodash";
import loadable from "loadable-components";
import {
	API_DATA_TYPE,
	columnFiltersMap,
	columnSortMap,
	TIMESHEETS_FILTER_ID,
	CHECK_FILTER_COLUMNS
} from "../utils/constants";

const LoadingStateCell = loadable(() =>
	import(
		"common/AgTable/components/RenderCellsGlobal/LoadingStateCell/LoadingStateCell"
	)
);

const TableMenu = loadable(() =>
	import("common/AgTable/components/MenuColumn")
);

const TimesheetsTableCell = loadable(() =>
	import("../components/TimesheetsTableCell/TimesheetsTableCell")
);

export const useTimesheetsColumns = ({
	isLoading,
	isFetching,
	tableRef,
	columns
}) => {
	const columnsList = useMemo(() => {
		return [
			{
				colId: "_selects",
				field: "isSelected",
				resizable: false,
				hide: true,
				filter: false,
				pinned: "left",
				lockVisible: true,
				lockPinned: true,
				cellRenderer: null
			},
			...columns?.map(column => {
				return {
					field: column.colId,
					width: column.width,
					minWidth: `${column.colId === "last_note" ? 160 : 80}`,
					colId: column.colId,
					hide: column.hide,
					pinned: column.pinned ?? null,
					filter: TableMenu,
					cellRenderer:
						isLoading || isFetching ? LoadingStateCell : TimesheetsTableCell,
					cellClass: "locked-visible",
					lockVisible: true,
					refGrid: tableRef,
					typeTag: API_DATA_TYPE,
					columnSortMap: columnSortMap,
					columnFiltersMap: columnFiltersMap,
					currentFilterId: TIMESHEETS_FILTER_ID,
					checkFilterColumns: CHECK_FILTER_COLUMNS,
					isSortable: column.sortable,
					headerName: capitalize(column.headerName)
				};
			})
		];
	}, [columns, isFetching, isLoading]);

	return columnsList;
};
