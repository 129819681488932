import React from "react";
import AxiosHelper from "config/axios-helper";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { HIDE_JOB } from "config/api-endpoints";

const HideJobModal = ({ closeModal, jobId, nextAction }) => {
	const hideProfile = () => {
		return AxiosHelper.post({
			url: HIDE_JOB,
			data: {
				id: jobId
			},
			toastMessage: "Job hidden permanently",
			isModal: true
		}).then(() => {
			nextAction && nextAction();
			closeModal();
		});
	};

	return (
		<ConfirmationModal
			modalName="hide-job"
			onClose={closeModal}
			firstButton={{
				action: hideProfile,
				label: "YES, HIDE JOB.",
				type: "danger"
			}}
			title="Are you sure you would like to hide
            this job ?"
			content={
				<>This job will no longer appear in any of your future searches.</>
			}
			type={ConfirmationTypes.error}
		/>
	);
};

export default HideJobModal;
