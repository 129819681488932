import React from "react";
import styles from "./banner.module.scss";
import { ReactComponent as NotificationIcon } from "static/icons/notification-icon-yellow.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-icon-grey.svg";

const Banner = ({ children, onClose }) => {
	return (
		<div className={styles.notificationItem}>
			<NotificationIcon />
			<div className={styles.notificationDescription}>{children}</div>
			<button onClick={onClose} className={styles.closeButtonItem}>
				<CloseIcon />
			</button>
		</div>
	);
};

export default Banner;
