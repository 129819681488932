import React from "react";
import styles from "./form-builder-action-duplicate.module.scss";
import { ReactComponent as DuplicateIcon } from "static/icons/duplicate-2.svg";
import Tooltip from "common/Tippy";

export default function FormBuilderActionDuplicate({ onClick }) {
	return (
		<Tooltip content="Duplicate" theme="dark">
			<button onClick={onClick} type="button" className={styles.actionButton}>
				<DuplicateIcon />
			</button>
		</Tooltip>
	);
}
