import { useSelector } from "react-redux";
import { FEATURE_DISPLAY_COST } from "config";

export const useIsDisplayCostEnabled = () => {
	const user = useSelector(state => state.auth.user);
	const displayCostEnabled = (user?.features || []).includes(
		FEATURE_DISPLAY_COST
	);
	return displayCostEnabled;
};
