import React, { useRef } from "react";
import { Box, Button, Flex, Text } from "rebass";
import { ReactComponent as DeleteTag } from "static/icons/delete-tag-multi-select-input.svg";

const useFocus = () => {
	const htmlElRef = useRef(null);
	const setFocus = () => {
		htmlElRef.current && htmlElRef.current.focus();
	};

	return [htmlElRef, setFocus];
};

const CreatableSelect = ({
	value,
	onInputChange,
	inputValue,
	onKeyDown,
	setValue,
	isTagExist
}) => {
	const [inputRef, setInputFocus] = useFocus();

	return (
		<Flex
			alignItems="center"
			sx={{
				background: "#FFFFFF",
				border: "1.5px solid #D3D9DE",
				borderRadius: "3px",
				padding: "8px 10px",
				height: "50px",
				position: "relative"
			}}
			onKeyDown={onKeyDown}
			onMouseUp={setInputFocus}
		>
			{value.length > 0 &&
				value.map(e => (
					<Flex
						sx={{
							background: "#EFF0F7",
							boxShadow: "0px 1px 1px #D9DBE9",
							borderRadius: 3,
							padding: "6px",
							justifyContent: "space-between",
							alignItems: "center",
							marginRight: "5px"
						}}
						key={e}
					>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "12px",
								lineHeight: "22px",
								color: "#6E7191",
								marginRight: "22px"
							}}
						>
							{e.value}
						</Text>
						<Button
							sx={{
								border: "none",
								background: "transparent",
								padding: 0,
								height: "14px"
							}}
							onClick={() =>
								setValue(list =>
									list.filter(item => item.value.localeCompare(e.value) !== 0)
								)
							}
						>
							<DeleteTag />
						</Button>
					</Flex>
				))}
			{value.length <= 0 && inputValue.length <= 0 && (
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: "normal",
						fontSize: "13px",
						lineHeight: "24px",
						color: "#A0A3BD"
					}}
				>
					add a tag
				</Text>
			)}
			<Box
				sx={{
					margin: 0,
					visibility: "visible",
					color: "hsl(0, 0%, 20%)",
					padding: 0,
					position: value.length > 0 ? "static" : "absolute"
				}}
			>
				<input
					style={{
						background: "rgba(0, 0, 0, 0) none repeat scroll 0px center",
						border: "0px none",
						opacity: 1,
						outline: "currentcolor none 0px",
						padding: "0px",
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: "normal",
						fontSize: "12px",
						lineHeight: "22px",
						color: isTagExist ? "#F62F2F" : "#6E7191"
					}}
					value={inputValue}
					onChange={e => {
						onInputChange(e.target.value);
					}}
					ref={inputRef}
				/>
			</Box>
		</Flex>
	);
};

export default CreatableSelect;
