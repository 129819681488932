import React, { useEffect, useState } from "react";
import styles from "./messaging-tool-new-email-template.module.scss";
import { SearchInput } from "common/SearchInput";
import useGetTemplates from "common/MessagingToolNewEmail/api/useGetTemplates";
import debounce from "lodash/debounce";
import { useFormContext } from "react-hook-form";
import { useGetEmailTemplate } from "common/MessagingToolNewEmail/api/useGetEmailTemplate";
import Skeleton from "react-loading-skeleton";
import MessagingToolNewEmailTemplatesEmptyState from "../MessagingToolNewEmailTemplatesEmptyState/MessagingToolNewEmailTemplatesEmptyState";

const MessagingToolNewEmailTemplates = ({
	setActiveTemplate: setActiveTemplateProps,
	onClose
}) => {
	const [activeTemplate, setActiveTemplate] = useState(null);
	const [query, setQuery] = useState("");
	const { setValue } = useFormContext();
	const { data: templateDetails } = useGetEmailTemplate(
		{ id: activeTemplate?.template_id },
		{
			enabled: !!activeTemplate
		}
	);
	const { data, isLoading } = useGetTemplates({
		tag: "by_version",
		category: "",
		search: query,
		offset: 0
	});

	useEffect(() => {
		if (templateDetails) {
			const template = templateDetails.versions[activeTemplate.version];
			setActiveTemplateProps(template);
			setValue("subject", template.subject);
			setValue("content", template.content);
			setValue(
				"content_placeholders",
				template.placeholders.map(placeholder => ({ value: placeholder }))
			);
		}
	}, [templateDetails, activeTemplate]);

	const debouncedOnSearch = debounce(value => {
		setQuery(value);
	}, 500);

	const onSearch = e => {
		debouncedOnSearch(e.target.value);
	};

	return (
		<div className={styles.container}>
			<div className={styles.searchInputContainer}>
				<SearchInput placeholder="Search" onChange={onSearch} />
			</div>
			{isLoading ? (
				<LoadingComponent />
			) : data?.data.length === 0 ? (
				<div className={styles.container}>
					<MessagingToolNewEmailTemplatesEmptyState onClose={onClose} />
				</div>
			) : (
				<div className={styles.listTemplates}>
					{data.data.map((template, index) => (
						<div
							key={`${template.template_id}-${index}`}
							className={styles.templateItem}
							onClick={() => setActiveTemplate(template)}
						>
							<span className={styles.name}>{template.template_name}</span>
							<span className={styles.version}>{template.version}</span>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default MessagingToolNewEmailTemplates;

const LoadingComponent = () => {
	return (
		<div>
			{Array(10)
				.fill(0)
				.map(e => (
					<div key={e} className={styles.skeleton}>
						<Skeleton width={129} height={10} />
					</div>
				))}
		</div>
	);
};
