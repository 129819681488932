//TODO We shouldn't clear the field function when we change subcateogry (when the value isn't new)
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuid } from "uuid";
import { ModalContent } from "../CreateVacancyModal/components/ModalContent";
import { ModalFooter } from "../CreateVacancyModal/components/ModalFooter";
import { ModalSaveButton } from "../CreateVacancyModal/components/ModalSaveButton";
import { ModalCancelButton } from "../CreateVacancyModal/components/ModalCancelButton";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import { Button } from "common/Button";
import styles from "./functions-picker-modal.module.scss";
import { useGetCategoriesList } from "modules/vacancy/create-vacancy/api/useGetCategoriesList";
import { formatCategoriesList } from "modules/vacancy/create-vacancy/utils/helper";
import { CreateVacancyModal } from "../CreateVacancyModal";
import produce from "immer";
import { FunctionsPickerModalFunctionRow } from "./FunctionsPickerModalFunctionRow";
import CreateVacancyFieldError from "../CreateVacancyFieldError/CreateVacancyFieldError";
import { VACANCY_LEVEL } from "config";
import { ReactComponent as InfoIconGrey } from "static/icons/info-grey.svg";

const DEFAULT_LIST = [{ id: uuid(), isMain: true }];

const FunctionsPickerModal = ({
	onEscapeKeyDown,
	onSave: onSaveProp,
	onCancel,
	list: listProp
}) => {
	const [list, setList] = useState(DEFAULT_LIST);
	const { data: categoriesList } = useGetCategoriesList();
	const [error, setError] = useState(false);
	const isSaveClicked = useRef(false);

	useEffect(() => {
		if (listProp && categoriesList) {
			const newList = listProp.map(({ category, ...rest }) => {
				const { children } = categoriesList.find(
					({ _id }) => _id === category.value
				);

				return {
					...rest,
					category: {
						...category,
						children
					}
				};
			});

			setList(newList);
		}
	}, [listProp, categoriesList]);

	const handleAddFunctionClick = () => {
		const newItem = { id: uuid(), isMain: false };

		setList(list => {
			const newList = [...list, newItem];

			if (isSaveClicked.current) {
				validate(newList);
			}

			return newList;
		});
	};

	const handleDeleteFunctionClick = (itemToDeleteId, isMain) => {
		setList(list => {
			const newList = list.filter(({ id }) => id !== itemToDeleteId);

			if (isSaveClicked.current) {
				validate(newList);
			}

			if (isMain && newList.length !== 0) {
				const listChanged = produce(newList, draft => {
					draft[0].isMain = true;
				});
				return listChanged;
			} else {
				return newList;
			}
		});
	};

	const handleStarToggleClick = clickedToggleId => {
		setList(list => {
			const newList = list.map(({ id, ...itemRest }) => {
				return {
					...itemRest,
					id,
					isMain: id === clickedToggleId ? true : false
				};
			});

			return newList;
		});
	};

	const onCategoryChange = (id, value, oldValue) => {
		if (oldValue?.value !== value.value) {
			changeListItem(id, "category", value);
			changeListItem(id, "subCategory", { label: "", value: null });
			changeListItem(id, "_function", { label: "", value: null });
		}
	};

	const onFunctionChange = (id, value) => {
		changeListItem(id, "_function", value);
	};

	const onSeniorityChange = (id, value) => {
		changeListItem(id, "seniority", value);
	};

	const changeListItem = (itemId, attribute, value) => {
		setList(list => {
			const newList = produce(list, draft => {
				const index = draft.findIndex(({ id }) => itemId === id);
				draft[index][attribute] = value;
			});

			if (isSaveClicked.current) {
				validate(newList);
			}

			return newList;
		});
	};

	const onSubCategoryChange = (id, value, hasChanged) => {
		changeListItem(id, "subCategory", value);

		if (hasChanged) {
			changeListItem(id, "_function", { label: "", value: null });
		}
	};

	const onSave = () => {
		isSaveClicked.current = true;

		const error = validate(list);

		if (!error) onSaveProp(list);
	};

	const someFieldsEmpty = list => {
		const someEmpty = list.some(
			({ subCategory, category, _function, seniority }) => {
				return (
					!subCategory?.value ||
					!category?.value ||
					!_function?.value ||
					!seniority?.value
				);
			}
		);

		return someEmpty;
	};

	const getUnusedSeniorities = (seniorityId, functionId) => {
		const ids = list
			.filter(
				({ seniority, _function }) =>
					seniority?.value && _function?.value === functionId
			)
			.map(({ seniority: { value } }) => value);

		return VACANCY_LEVEL.filter(
			({ value }) => !ids.includes(value) || value === seniorityId
		);
	};

	const validate = list => {
		let error;

		if (list.length === 0) {
			error = "Functions list can not be empty";
		} else if (someFieldsEmpty(list)) {
			error = "Some fields are incomplete!";
		}

		setError(error);

		return error;
	};

	const categoriesListFormatted = categoriesList
		? formatCategoriesList(categoriesList)
		: [];

	return (
		<CreateVacancyModal
			className={styles.dialogContent}
			onClose={onEscapeKeyDown}
		>
			<h2 className={styles.modalTitle}>Functions</h2>
			<ModalContent className={styles.contentContainer}>
				<div />
				<div className={styles.label}>Category</div>
				<div className={styles.label}>Sub-category</div>
				<div className={styles.label}>Function</div>
				<div className={styles.label}>Seniority</div>
				<div />
				{list.map(_function => {
					return (
						<FunctionsPickerModalFunctionRow
							key={_function.id}
							functionItem={_function}
							handleStarToggleClick={handleStarToggleClick}
							categoriesListFormatted={categoriesListFormatted}
							onCategoryChange={value =>
								onCategoryChange(_function.id, value, _function.category)
							}
							onSubCategoryChange={(value, hasChanged) =>
								onSubCategoryChange(_function.id, value, hasChanged)
							}
							onFunctionChange={value => onFunctionChange(_function.id, value)}
							onSeniorityChange={value =>
								onSeniorityChange(_function.id, value)
							}
							handleDeleteFunctionClick={id =>
								handleDeleteFunctionClick(id, _function.isMain)
							}
							senioritiesList={getUnusedSeniorities(
								_function?.seniority?.value,
								_function?._function?.value
							)}
							displayDelete={list.length > 1}
						/>
					);
				})}
				{list.length <= 4 ? (
					<Button
						text="Add function"
						variant="text"
						textClassName={styles.addFunctionButton}
						icon={<AddIcon />}
						rootClassName={styles.addFunctionButtonContainer}
						onClick={handleAddFunctionClick}
						className={styles.addButton}
					/>
				) : (
					!error && (
						<div className={styles.infoMessageContainer}>
							<InfoIconGrey />
							<div className={styles.infoMessage}>
								You already added 5 functions
							</div>
						</div>
					)
				)}
			</ModalContent>
			<ModalFooter className={styles.modalFooter}>
				{error ? (
					<CreateVacancyFieldError error={{ message: error }} />
				) : (
					<div />
				)}
				<ModalCancelButton onClick={onCancel} />
				<ModalSaveButton onClick={onSave} />
			</ModalFooter>
		</CreateVacancyModal>
	);
};

export default FunctionsPickerModal;
