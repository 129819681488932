import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { EMAIL_ACTION } from "config/api-endpoints";

const emailAction = body => {
	return client(EMAIL_ACTION, { body });
};

function useEmailAction(options) {
	return useMutation(emailAction, options);
}

export default useEmailAction;
