import React, { useState, useEffect } from "react";
import { MentionsInput as MI, Mention } from "react-mentions";
import _filter from "lodash/filter";
import _get from "lodash/get";
import { Element } from "react-scroll";
import { getMaxIndicatorClassName } from "config";
import styles from "./mentions.module.css";
import { ReactComponent as ErrorIcon } from "common/react-hook-form-components/icons/elements-icons-error.svg";

const mentionRegex = /\?##([\w\s|(\W*)]+),([\w\S]+)##\?/i;
const inputRef = React.createRef();

const stripMentions = (value = "") => value.replace(mentionRegex, "");

const getData = ({ data, mentions }) => {
	if (mentions.includes("all")) {
		return [];
	} else {
		if (mentions.length) {
			data = _filter(data, d => d.id !== "all");
		}
	}
	return _filter(data, d => mentions.indexOf(d.id) === -1);
};

export default function MentionsInput({
	data,
	maxLength,
	label,
	input: {
		name,
		value: propsValue,
		onChange,
		placeholder = "Add your note, type '@' to tag your team members",
		onFocus = () => {},
		onBlur = () => {}
	},
	meta: { touched, error } = {},
	readOnly,
	disabled
}) {
	const [value, setValue] = useState(_get(propsValue, "value", ""));
	const [mentions, setMentions] = useState([]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.setAttribute("id", "mention-box");
			if (readOnly) {
				inputRef.current.setAttribute("readOnly", true);
			}
		}
	}, [readOnly]);

	useEffect(() => {
		setValue(_get(propsValue, "value", ""));
	}, [propsValue]);

	data = getData({ data, mentions });

	const hasReachedMax = (value = "") => stripMentions(value).length > maxLength;

	const handleChange = (e, newVal, _, mentions) => {
		mentions = [...new Set(mentions.flatMap(m => m.id))];
		if (hasReachedMax(newVal)) {
			newVal = value;
		}
		setValue(newVal);
		setMentions(mentions);
		onChange({
			value: newVal,
			mentions
		});
	};
	const strippedLength = stripMentions(value).length;
	const _hasError = touched && error;
	const has_error = _hasError ? "has-error has-feedback" : "";

	let style = {
		input: {
			overflow: "auto",
			height: 150
		},
		highlighter: {
			boxSizing: "border-box",
			overflow: "hidden",
			height: 150
		},
		suggestions: {
			list: {
				maxHeight: 300,
				overflow: "auto"
			}
		}
	};
	const { mentions__mention } = styles;
	return (
		<Element
			className={`form-group ${has_error}`}
			name={name}
			scrollto="scroll-to-error"
		>
			{!readOnly && label && (
				<label
					className={`${styles.mention_label} mention-label`}
					htmlFor={name}
				>
					{label}
				</label>
			)}

			<div
				className={disabled ? "disabled-mentions-input" : "mention-container"}
			>
				<MI
					value={value}
					onChange={readOnly ? null : handleChange}
					classNames={styles}
					inputRef={inputRef}
					placeholder={placeholder}
					disabled={disabled}
					onFocus={() => {
						onFocus();
					}}
					onBlur={() => {
						onBlur();
					}}
					style={style}
					className="mentions"
				>
					<Mention
						trigger="@"
						data={data}
						displayTransform={(_, d) => `@${d}`}
						className={mentions__mention}
						markup={"?##__display__,__id__##?"}
						id={"mention-trigger"}
						appendSpaceOnAdd
					/>
				</MI>
			</div>
			{!readOnly && maxLength && (
				<p
					className={`input-maxlength ${getMaxIndicatorClassName(
						strippedLength,
						maxLength
					)} ${styles["input-maxlength"]}`}
				>
					{strippedLength}/{maxLength}
				</p>
			)}
			{!readOnly && touched && (
				<div className={styles.error_block}>
					<ErrorIcon />
					<span className={styles.text}>{error}</span>
				</div>
			)}
		</Element>
	);
}
