import React from "react";
import { Filter } from "common/newSearch/Styled";

function SearchEmployment({
	filterByPermanent,
	filterByFixedTerm,
	filterByInternShip,
	employment_type
}) {
	const employmentValue = type => {
		employment_type.map(employment => {
			if (employment === type) {
				return true;
			}
		});
	};
	const employmentTypeData = employment_type.reduce((type, item) => {
		type[item] = item;
		return type;
	}, {});

	return (
		<Filter className="checkbox-filter" isSource={true}>
			<div className="title">Employment type</div>
			<div className="checkbox-container">
				<div className="checkbox checkbox-primary">
					<div
						className="input-box"
						style={{ width: "100%", display: "block", marginBottom: "15px" }}
					>
						<input
							type="checkbox"
							className="styled"
							id={"permanent"}
							name={"permanent"}
							checked={
								(employment_type.length && employmentValue("permanent")) ||
								employmentTypeData.permanent === "permanent"
							}
							onChange={() => filterByPermanent("permanent")}
						/>
						<label htmlFor={"permanent"}>Permanent</label>
					</div>
				</div>
				<div className="checkbox checkbox-primary">
					<div
						className="input-box"
						style={{ width: "100%", display: "block", marginBottom: "15px" }}
					>
						<input
							type="checkbox"
							className="styled"
							id={"fixed_term"}
							name={"fixed_term"}
							checked={
								(employment_type.length && employmentValue("fixed_term")) ||
								employmentTypeData.fixed_term === "fixed_term"
							}
							onChange={() => filterByFixedTerm("fixed_term")}
						/>
						<label htmlFor={"fixed_term"}>Fixed term</label>
					</div>
				</div>
				<div className="checkbox checkbox-primary">
					<div
						className="input-box"
						style={{ width: "100%", display: "block" }}
					>
						<input
							type="checkbox"
							className="styled"
							id={"internship"}
							name={"internship"}
							checked={
								(employment_type.length && employmentValue("internship")) ||
								employmentTypeData.internship === "internship"
							}
							onChange={() => filterByInternShip("internship")}
						/>
						<label htmlFor={"internship"}>Internship</label>
					</div>
				</div>
			</div>
		</Filter>
	);
}
export default SearchEmployment;
