import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { VIEW_CANDIDATE_ENDPOINT } from "config/api-endpoints";

const viewCandidate = (_, { id, is_internal, email }) =>
	client(VIEW_CANDIDATE_ENDPOINT, {
		body: {
			id,
			is_internal,
			limit: 4,
			email: email ?? undefined
		}
	});
export const viewCandidateKey = "@candidate/viewCandidate";

const useViewCandidate = (options, queryOptions = {}) =>
	useQuery([viewCandidateKey, options], viewCandidate, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default useViewCandidate;
