import TimesheetContainer from "./TimesheetContainer";

export const getTimesheetRoute = () => {
	return "/timesheet/:jobId(/:timesheetId)";
};
export const getTimesheetPermission = () => {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "consultancy",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default TimesheetContainer;
