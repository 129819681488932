import React, { Fragment } from "react";
import { Link } from "react-router";
import { ADMIN, CLIENT, FREELANCER, PROJECT_MANAGER } from "config";
import { getCreateJobRoute } from "../../job/components/CreateJob";
import { getJobListContainerRoute } from "../../job/components/joblist/jobListContainer";
import { getMissionsContainerRoute } from "../../contract/components/MissionsContainer";
import { getMyConsultantsRoute } from "../../consultancy/components/MyConsultants";

const renderCTAForType = (type, role, isMember) => {
	const isConsultant = role === PROJECT_MANAGER;

	switch (type) {
		case CLIENT:
			return (
				<Fragment>
					{role === ADMIN && !isMember ? (
						<Link to="/settings/users" className="btn btn-primary btn-bold">
							create department
						</Link>
					) : (
						<>
							<Link
								to={getCreateJobRoute()}
								className="btn btn-primary btn-bold"
							>
								create request
							</Link>
							<Link
								to={getJobListContainerRoute()}
								className="btn discover btn-bold"
							>
								discover the platform
							</Link>
						</>
					)}
				</Fragment>
			);
		case FREELANCER:
			return (
				<Link to="/jobs/search" className="btn btn-primary huge btn-bold">
					Discover your next mission!
				</Link>
			);
		default:
			if (isMember) {
				return (
					<Link
						to={
							isConsultant
								? getMissionsContainerRoute()
								: getMyConsultantsRoute()
						}
						className="btn btn-primary huge btn-bold"
					>
						{isConsultant ? "My missions" : "My consultants"}
					</Link>
				);
			}
			return (
				<Link
					to={getMyConsultantsRoute()}
					className="btn btn-primary huge btn-bold"
				>
					CREATE BUSINESS UNIT
				</Link>
			);
	}
};

const renderHelpTextForType = (type, isMember) => {
	switch (type) {
		case CLIENT:
			return (
				<p className="congratulations">
					Your account has successfully been created. Welcome to wiggli!
				</p>
			);
		case FREELANCER:
			return (
				<p className="congratulations">
					<strong>You are now registered on wiggli.</strong>
					<br /> Once your provided ID document is approved by our support team,
					you’ll be able to start bidding on missions!
				</p>
			);
		default:
			if (isMember) {
				return (
					<p className="congratulations">
						Your account has been successfully created, welcome to wiggli!
					</p>
				);
			}
			return (
				<p className="congratulations">
					<strong>Your consultancy is now registered on wiggli.</strong>
					<p className="congratulation-text">
						Once your provided ID document is approved by our support team,
						you’ll be able to place bids on missions for your consultants. In
						the meantime, start registering your consultants’ profiles by
						creating your first Business Unit.
					</p>
				</p>
			);
	}
};

const CompanyActivatedSuccessfully = ({ type, role, isMember }) => {
	return (
		<div
			className={`${type === FREELANCER ? null : "large"} invitation-accepted`}
		>
			<h3>Congratulations!</h3>
			{renderHelpTextForType(type, isMember)}
			<div className="actions">{renderCTAForType(type, role, isMember)}</div>
		</div>
	);
};

export default CompanyActivatedSuccessfully;
