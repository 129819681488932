//TODO There is a lot of components tootlip, we should leave just one, this reduce producitivity
import React, { Component } from "react";

class Tooltip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			position: "right"
		};

		this.calculateTip = this.calculateTip.bind(this);
	}
	render() {
		const { type, text, description, position, visible } = this.props;
		if (!visible) return null;
		// TODO  add styles for other position
		const _position = position || this.state.position;
		return (
			<div
				ref={ref => (this._tooltip = ref)}
				className={`help-block ${type} ${_position} ${type}`}
			>
				{text}
				{description}
			</div>
		);
	}

	calculateTip() {
		if (this.props.position) return;
		const window_width = window.innerWidth;
		const tip = this._tooltip;
		const parent = tip.parentNode;
		const parentRect = parent.getBoundingClientRect();
		const tiptRect = tip.getBoundingClientRect();

		const outOfRightView =
			parentRect.left + parentRect.width + tiptRect.width > window_width;
		const outOfLeftView = parentRect.left - tiptRect.width < 0;

		if (outOfRightView && outOfLeftView) {
			this.setState({ position: "bottom" });
		} else if (outOfRightView && !outOfLeftView) {
			this.setState({ position: "left" });
		} else {
			this.setState({ position: "right" });
		}
	}

	componentDidMount() {
		this.calculateTip();
		window.addEventListener("resize", this.calculateTip);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.calculateTip);
	}
}

Tooltip.defaultProps = {
	visible: true,
	type: "error"
};

export default Tooltip;
