import React, { useEffect, useState } from "react";
import LocationModal from "./LocationModal";
import {
	CheckLocationBoxesWrapper,
	SubTitle,
	LocationDescription
} from "../../styles";
import { SEARCH_ADDRESS } from "config";

const CheckLocationBoxes = ({
	onChange,
	addressType,
	addressTypeIcon,
	type,
	locationType,
	content,
	setAddress,
	address,
	error,
	addressTypo,
	label,
	addressError,
	setAddressError,
	closeLocationModal
}) => {
	const [showLocationModal, setShowLocationModal] = useState(false);

	useEffect(() => {
		if (addressType === SEARCH_ADDRESS && locationType === SEARCH_ADDRESS) {
			return setShowLocationModal(true);
		}
		setShowLocationModal(false);
	}, [addressType, locationType]);

	return (
		<CheckLocationBoxesWrapper
			isSelected={locationType === addressType}
			onClick={onChange}
		>
			<div className="radio-container">
				<input
					type="radio"
					name={locationType}
					checked={locationType === addressType}
					onChange={onChange}
				/>
				<span className="label"></span>
			</div>
			<div className="icon-wrapper">{addressTypeIcon}</div>

			<SubTitle>{type}</SubTitle>
			<LocationDescription>{content}</LocationDescription>
			{showLocationModal && (
				<LocationModal
					setAddress={address => setAddress(address)}
					address={address}
					error={error}
					addressTypo={addressTypo}
					label={label}
					requiredFields={true}
					showAutoComplete={true}
					showLocationModal={showLocationModal}
					setShowLocationModal={setShowLocationModal}
					setAddressError={setAddressError}
					addressError={addressError}
					closeLocationModal={closeLocationModal}
				/>
			)}
		</CheckLocationBoxesWrapper>
	);
};

export default CheckLocationBoxes;
