import React from "react";
import get from "lodash/get";
import s from "./channel-card.module.scss";
import useAddContractStore from "../../store/useAddContractStore";

const ChannelCard = ({ channel }) => {
	const { setvalue } = useAddContractStore();

	const handleClick = () => {
		setvalue("open", true);
		setvalue("channel", channel.id);
	};

	const src = get(channel, "logo_url[0].url");

	return (
		<div className={s.root}>
			<div className={s.channelInfo}>
				{src ? <img src={src} /> : <div className={s.hiddenBox} />}
				<p>{get(channel, "name", "")}</p>
			</div>
			<button className={s.addContractButton} onClick={handleClick}>
				Add to my contracts
			</button>
		</div>
	);
};

export default ChannelCard;
