import React, { memo, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import _find from "lodash/find";
import { Label, Checkbox } from "@rebass/forms/styled-components";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogTitle
} from "../../CreateNewSupplier/Dialog";
import s from "../../CreateNewSupplier/create-supplier.module.scss";
import Select from "../../../shared/Select";
import styles from "./geographical-coverage-dialog.module.scss";

function GeographicalCoverageFilterDialog({ isOpen, onClose, store }) {
	const autoComplete = useRef(
		new window.google.maps.places.AutocompleteService()
	);
	const geocoder = useRef(new window.google.maps.Geocoder());

	const {
		setLocation,
		location,
		physical_presence,
		setPhysicalPresence
	} = store(state => ({
		setLocation: state.setLocation,
		setPhysicalPresence: state.setPhysicalPresence,
		physical_presence: state.physical_presence,
		location: state.location
	}));

	const { handleSubmit, control, reset } = useForm({
		shouldUnregister: true,
		defaultValues: {
			geo_coverage: location,
			physical_presence
		}
	});

	useEffect(() => {
		if (!location)
			return reset({ geo_coverage: null, physical_presence: false });
		reset({
			geo_coverage: {
				label: `${location.city}, ${location.country}`,
				value: location.placeId
			},
			physical_presence
		});
	}, [reset, location, physical_presence]);

	const onSubmit = async fields => {
		if (!fields.geo_coverage) return;
		const { results } = await geocoder.current.geocode({
			placeId: fields.geo_coverage.value
		});

		if (!results) return;

		const city = _find(results?.[0].address_components, {
			types: ["locality"]
		});
		const country = _find(results?.[0].address_components, {
			types: ["country"]
		});

		const bounds = results?.[0].geometry.bounds.getCenter().toJSON();
		const viewport = {
			northeast: results?.[0].geometry.viewport.getNorthEast().toJSON(),
			southwest: results?.[0].geometry.viewport.getSouthWest().toJSON()
		};

		setLocation({
			placeId: fields.geo_coverage.value,
			city: city?.long_name ?? "",
			country: country.long_name,
			iso_country: country.short_name,
			viewport,
			latitude: bounds.lat,
			longitude: bounds.lng
		});
		setPhysicalPresence(fields.physical_presence);
		onClose();
	};

	const handleLoadOptions = (input, cb) => {
		if (!input) {
			return Promise.resolve({ options: [] });
		}

		autoComplete.current.getPlacePredictions(
			{
				input,
				types: ["(regions)"]
			},
			function(data) {
				cb(null, {
					options: data
						? data.map(d => ({ label: d.description, value: d.place_id }))
						: [],
					complete: false
				});
			}
		);
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent onEscapeKeyDown={onClose}>
				<DialogTitle>Filter by Geographical Coverage</DialogTitle>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name={"geo_coverage"}
						render={({ field: { onChange, value } }) => (
							<Select
								async={true}
								multi={false}
								value={value}
								loadOptions={handleLoadOptions}
								onChange={onChange}
								cache={false}
								initialValue={null}
							/>
						)}
					/>
					<Controller
						control={control}
						name={"physical_presence"}
						render={({ field: { onChange, value } }) => (
							<Label className={styles.checkbox}>
								<Checkbox
									checked={value}
									onChange={e => onChange(e.target.checked)}
								/>
								Physical presence only
							</Label>
						)}
					/>
					<div className={s.dialogFooter}>
						<button onClick={onClose} className={s.cancelButton}>
							Cancel
						</button>
						<button type={"submit"} className={s.mainButton}>
							{!location ? "Add to filters" : "Update filter"}
						</button>
					</div>
				</form>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default memo(GeographicalCoverageFilterDialog);
