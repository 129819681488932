import React from "react";
import { OPERATORS } from "common/FilterDrawer/utils";
import { Picker } from "common/Picker";
import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { ReactComponent as EditIcon } from "static/icons/drawer-requests/edit-icon.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-03.svg";
import { ReactComponent as AlarmIcon } from "static/icons/alarm-clock.svg";
import { ReactComponent as CheckIcon } from "static/icons/check-circle-broken.svg";
import { CustomDropDown } from "common/CustomDropDown";
import { CustomInput } from "../components/CustomInput";

export const API_DATA_TYPE = "search_tasks_table";
export const TASKS_LIST_FILTER_ID = "TASKS_LIST_FILTER_ID";
export const TASKS_LIST_MODULE_ID = "TASKS_LIST_MODULE_ID";
export const TASKS_LIST_PAGE_SIZE = 24;

export const TASKS_PAGE_SIZES = [50, 24, 12];

export const TYPES = {
	text: "text",
	array: "array",
	date: "date",
	select: "select"
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.array]: [OPERATORS.is, OPERATORS.isNot],
	[TYPES.date]: [OPERATORS.is, OPERATORS.between],
	[TYPES.select]: [OPERATORS.is, OPERATORS.isNot]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.array]: {
		default: Picker
	},
	[TYPES.select]: {
		default: CustomDropDown
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	}
};

//Here we define the column that shows input when we click on filter from dropdown
export const CHECK_FILTER_COLUMNS = ["title"];

//Here we define filtered columns
export const columnFiltersMap = {
	status: "status",
	due_date: "due_date",
	created_at: "created_at",
	created_by: "created_by",
	priority: "priority",
	title: "title"
};

export const columnSortMap = {
	created_at: "created_at",
	due_date: "due_date",
	priority: "priority",
	status: "status",
	created_by: "created_by",
	title: "title",
	assigned_user: "assigned_user",
	linked_to: "linked_to"
};

export const TASKS_LIST_ROWS_ACTIONS = {
	edit: {
		name: "Edit",
		key: "edit",
		icon: <EditIcon />
	},
	markAsComplete: {
		name: "Mark as complete",
		key: "publish",
		icon: <CheckIcon />
	},
	delete: {
		name: "Delete",
		key: "delete",
		icon: <TrashIcon />
	},
	reminder: {
		name: "Reminder",
		key: "reminder",
		icon: <AlarmIcon />
	}
};

export const TASKS_TABLE_COLUMNS_IDS = {
	status: "status",
	due_date: "due_date",
	created_at: "created_at",
	linked_to: "linked_to",
	created_by: "created_by",
	assigned_user: "assigned_user",
	evaluation_task: "evaluation_task",
	attachment: "attachment",
	title: "title",
	reminder: "reminder",
	priority: "priority"
};

export const TASKS_LIST_UNFILTERED_COLUMNS = [
	TASKS_TABLE_COLUMNS_IDS.attachment,
	TASKS_TABLE_COLUMNS_IDS.linked_to,
	TASKS_TABLE_COLUMNS_IDS.assigned_user
];
