import useOnClickOutside from "hooks/useOnClickOutside";
import React, { useState, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import ArrowDown from "static/icons/arrow-down-drop-down-icon.svg";
import classnames from "modules/Tasks/components/pagination/drop-down.module.scss";

const DropDown = ({ setLimit, limit }) => {
	const [open, setOpen] = useState(false);
	const openMenu = () => setOpen(true);
	const closeMenu = () => setOpen(false);

	const ref = useRef();
	useOnClickOutside(ref, closeMenu);

	const handleChooseItem = item => {
		setLimit(item);
		closeMenu();
	};

	const staticOpts = [8, 16, 24, 32];

	return (
		<div className={classnames.container}>
			<button onClick={openMenu} className={classnames.label}>
				<span className={classnames.selectedValue}> {limit}</span>

				<img src={ArrowDown} width="9" height="9" alt="arrow down" />
			</button>
			{open && (
				<div ref={ref} className={classnames.menuItems}>
					<Scrollbars
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={250}
						style={{ zIndex: 1, paddingBottom: "16px" }}
						renderTrackHorizontal={props => (
							<div
								{...props}
								style={{ display: "none" }}
								className="track-horizontal"
							/>
						)}
						renderView={props => (
							<div
								{...props}
								style={{
									...props.style,
									overflowX: "hidden",
									maxHeight: "250px"
								}}
							/>
						)}
					>
						{staticOpts.map(item => (
							<button
								key={item}
								onClick={() => handleChooseItem(item)}
								className={classnames.menuItem}
							>
								{item}
							</button>
						))}
					</Scrollbars>
				</div>
			)}
		</div>
	);
};

export default DropDown;
