import React from "react";
import PropTypes from "prop-types";
import { REMOTE, REMOTE_LABEL } from "config";
import { addressObjectToString } from "config/helpers";

const BidDetailsAddressComposer = ({
	addressData,
	addressData: { street, number, box, zip, city, country },
	className
}) => {
	return (
		<span className={className}>
			{addressData &&
				addressData.location_type !== REMOTE &&
				(city || country ? (
					<span>
						{addressObjectToString({
							street,
							number,
							box,
							zip,
							city,
							country
						})}
					</span>
				) : (
					"-"
				))}
			{addressData.location_type === REMOTE && <span>{REMOTE_LABEL}</span>}
		</span>
	);
};

BidDetailsAddressComposer.propTypes = {
	addressData: PropTypes.shape({
		street: PropTypes.string,
		number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		box: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		zip: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string
	})
};

BidDetailsAddressComposer.defaultProps = {
	addressData: {}
};

export default BidDetailsAddressComposer;
