import React from "react";
import { CreateVacancySkeletonCard } from "../CreateVacancySkeletonCard";
import { CreateVacancyCard } from "../CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import styles from "./add-vacancy-applicants-card-loading.module.scss";
import { CreateVacancyCardTitle } from "../CreateVacancyCardTitle";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";

const AddVacancyApplicantsCardLoading = () => {
	return (
		<CreateVacancyCard className={styles.card}>
			<CreateVacancyCardTitle>Applicants</CreateVacancyCardTitle>
			<CreateVacancyCardContent>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Education level</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>
							Applicants must be based in
						</CreateVacancyFieldLabel>
						<CreateVacancyInfoIcon>
							Applicants based elsewhere than in the selected location(s) will
							be rejected automatically
						</CreateVacancyInfoIcon>
					</CreateVacancyLabelContainer>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Employment type</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Number of positions
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
			</CreateVacancyCardContent>
		</CreateVacancyCard>
	);
};

export default AddVacancyApplicantsCardLoading;
