export const columns = () => [
	{
		Header: "Channel name",
		accessor: "channel_name"
	},
	{
		Header: "Group",
		accessor: "group"
	},
	{
		Header: "User",
		accessor: "user"
	},
	{
		Header: "Expiration date",
		accessor: "expiration_date"
	},
	{
		Header: "Action",
		accessor: "action",
		onClick: e => e.stopPropagation()
	}
];
