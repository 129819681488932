import React from "react";
import s from "./contacts-data.module.scss";

function ContactsData({ data }) {
	return (
		<div className={s.root}>
			<div>
				<h4>Administrative contact person</h4>
				<ul>
					<li>
						<label>First name</label>
						<span>{data?.msp_contact_person?.first_name ?? "-"}</span>
					</li>
					<li>
						<label>Last name</label>
						<span>{data?.msp_contact_person?.last_name ?? "-"}</span>
					</li>
					<li>
						<label>Phone number</label>
						<span style={{ textTransform: "uppercase" }}>
							{data?.msp_contact_person?.phone ?? "-"}
						</span>
					</li>
					<li>
						<label>Email</label>
						<span>{data?.msp_contact_person?.email ?? "-"}</span>
					</li>
				</ul>
			</div>
			<div>
				<h4>Commercial contact person</h4>
				<ul>
					<li>
						<label>First name</label>
						<span>{data?.commercial_contact_person?.first_name ?? "-"}</span>
					</li>
					<li>
						<label>Last name</label>
						<span>{data?.commercial_contact_person?.last_name ?? "-"}</span>
					</li>
					<li>
						<label>Phone number</label>
						<span style={{ textTransform: "uppercase" }}>
							{data?.commercial_contact_person?.phone ?? "-"}
						</span>
					</li>
					<li>
						<label>Email</label>
						<span>{data?.commercial_contact_person?.email ?? "-"}</span>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default ContactsData;
