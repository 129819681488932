import styled, { css } from "styled-components";
import { colors } from "config/styles";

export const Title = styled.h3`
	font-size: 32px;
	margin-bottom: 60px;
	font-weight: 300;
	text-transform: capitalize;
	font-family: BasierCircle, sans-serif;
`;

export const DroppableContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	top: 13px;
	width: 55%;
	border: 1px solid ${colors.sectionBorderColor};
	border-radius: 8px;
	overflow: hidden;
	background-color: white;
	box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
`;

export const StyledDroppable = styled.div`
	padding: 10px;
	height: 350px;
	position: relative;
	background-color: #fff;
	overflow: auto;
	${props =>
		props.isDraggingOver &&
		css`
			background-color: ${colors.droppableHover};
		`};
	${props =>
		props.draggingFromThisWith &&
		css`
			background-color: ${colors.droppableFrom};
		`};
	${props =>
		props.isSelecting &&
		css`
			padding-bottom: 55px;
		`};
	${props =>
		props.isMarketplace &&
		css`
			background-color: #202223;
		`}
`;

export const DroppableHeader = styled.div`
	display: flex;
	position: relative;
	justify-content: space-between;
	width: 100%;
	font-family: BasierCircle, sans-serif;
	padding: 10px;
	top: 0;
	line-height: 2;
	h3 {
		font-size: 11px;
		color: #a0a5b9;
		font-weight: 600;
		text-transform: uppercase;
	}
	> div {
		width: 26px !important;
	}
	${props =>
		props.isMarketplace &&
		css`
			background-color: #202223;
		`}
`;

export const StyledDraggable = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	background-color: white;
	margin-bottom: 5px;
	color: #2d3540;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
	font-family: BasierCircle, sans-serif;
	font-size: 14px;
	overflow: hidden;
	${props =>
		props.isDragging &&
		css`
			border-color: ${colors.primaryColor};
		`};
	label {
		margin: 0;
		font-weight: 500 !important;
	}
	img {
		border-radius: 50%;
		margin-right: 18px;
	}
	strong {
		color: #768089;
		font-weight: 500 !important;
	}
`;

export const SectionWork = styled.span`
	display: flex;
	justify-content: space-between;
	flex: 1;
	ul {
		list-style: none;
		padding: 0;
		width: 100%;
		li {
			width: 100%;
		}
		li.name {
			font-size: 11px;
			font-weight: 600;
			text-transform: uppercase;
			color: #a0a5b9;
		}
		li.title {
			font-size: 15px;
			font-weight: 600;
			text-align: left;
			color: ${colors.primaryColor};
		}
		li.subTitle {
			font-size: 12px;
			font-weight: normal;
			color: #3d4b60;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		input[type="text"] {
			width: 50px;
			min-height: 26px !important;
			height: 20px;
			text-align: center;
		}
	}
`;

export const SearchContainer = styled.div`
	margin-top: 20px;
	.form-group {
		position: relative;
		margin-bottom: 24px;
		.form-control {
			background: transparent;
			border-bottom: 1px solid #d6d7dd;
			padding: 0 30px;
			min-height: 45px !important;
		}
		input.form-control {
			padding-left: 30px;
		}
		.icon-search {
			position: absolute;
			top: 17px;
			left: 8px;
			color: #a5b1bb;
		}
		.input-error {
			border: 1px solid #ff6849 !important;
		}
		.icon-reset-3 {
			position: relative;
			top: 50%;
		}
	}
`;
export const SettingRow = styled.div`
	display: flex;
	align-items: flex-start;
	color: ${colors.anchorsColor};
	.label-freelancer {
		margin-left: 15px;
		margin-bottom: 10px;
	}
	.form-group:not(.styled) {
		margin: 0 15px !important;
	}
	input {
		width: 50px !important;
		min-height: 20px !important;
		height: 25px;
		text-align: center;
	}
	input.freelance-visibility-day {
		position: relative;
		bottom: 5px;
		padding: 15px 5px;
		appearance: textfield !important;
	}
	.styled.inactive {
		label {
			color: ${colors.draftColor} !important;
		}
	}
	.help-block {
		display: none;
	}
	.radio {
		padding-left: 0px !important;
		label {
			text-transform: unset !important;
			font-weight: 400 !important;
			position: relative;
			&:before {
				top: 50% !important;
				transform: translate(0, -50%);
			}
			&:after {
				top: calc(50% - 4px) !important;
			}
		}
	}
	${props =>
		!props.active &&
		css`
			color: ${colors.draftColor};
		`};
`;

export const TiersDetails = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	padding: 20px 10px;
	h3 {
		font-size: 12px !important;
		font-weight: 600 !important;
		text-transform: uppercase !important;
		color: #fd5749 !important;
		margin-bottom: 20px !important;
	}

	ul {
		list-style: none;
		padding: 0;
		width: 30%;
		li {
			color: ${colors.inputColor};
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			span {
				font-size: 13px;
				font-weight: normal;

				line-height: 1.5;
				letter-spacing: 0.1px;
				text-align: left;
				color: #3d4b60;
			}
			input {
				width: 50px;
				min-height: 20px;
				height: 25px;
				text-align: center;
				padding: 10px 0;
				appearance: textfield;
			}
		}
	}
	.title {
		box-shadow: inset 0px -1px 0px #e1e3e5;
		border-radius: 0px;
	}
`;

export const Divider = styled.div`
	display: flex;
	height: 50%;
	width: 1px;
	background-color: #d8dbdd;
`;

export const HorizontalDivider = styled.div`
	display: flex;
	width: calc(100% + 60px);
	height: 1px;
	background-color: ${colors.sectionBorderColor};
	margin: 30px -30px;
`;

export const TabsContainer = styled.div`
	[data-reach-tab] {
		background: #edeeef;
		border-radius: 4px;
		color: #8c9196;
		display: flex;
		padding: 3px 10px;
		cursor: pointer;
		font-family: "BasierCircle";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		border: 0;
		margin-bottom: 0;
	}
	[data-reach-tab][data-selected] {
		background: #ffe9e8;
		color: #fd5749;
		border: 0;
	}
	[data-reach-tab-list] {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background: transparent;
		gap: 6px;
		margin-top: 10px;
	}
	.menuOpener {
		display: flex;
		align-items: center;
		height: 25px;
	}
	.hidenTabsCount {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #ffffff;
		background: #fd5749;
		border-radius: 2px;
		padding: 2px 10px;
	}
	.tabsController {
		display: flex;
		gap: 10px;
		align-items: center;
		background: #fff;
		color: #fd5749;
		.icon {
			transition: "transform 0.4s ease";
			&.active {
				transform: rotate(180deg);
			}
		}
	}
	.emptyStateContainer {
		background: #ffffff;
		box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
		border-radius: 5px;
		margin-top: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		.box {
			padding: 40px;
			margin: 20px;
			/* border: 1px solid; */
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 10px;
			border: 0.5px solid #e1e3e5;
			box-sizing: border-box;
			box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
			border-radius: 4px;
			.illustration {
				width: 150px;
			}
			.headding {
				font-family: "basiercircle";
				font-weight: 600;
				font-size: 16px;
				color: #babec3;
			}
			.menuOpener {
				border-radius: 4px;
				color: #fd5749;
				border: 1px solid #fd5749;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				padding: 5px 12px;
				> svg {
					> path {
						fill: #fd5749;
					}
				}
			}
			.menu {
				bottom: 25px;
			}
		}
	}
`;
