import { dateCalendar } from "common/Functions";
import React from "react";
import { Box, Flex, Text } from "rebass";
import { parseMentions } from "config/helpers";

const ListNotes = ({ description, fullName, createdAt, isLast, isFirst }) => {
	const parsedDescription = parseMentions(description);
	return (
		<Box
			sx={{
				borderBottom: !isLast ? "1px solid #f6f7fb" : "none",
				borderRadius: "10px"
			}}
			pt={!isFirst ? 2 : 0}
			pb={!isLast ? 2 : 0}
			pr={5}
		>
			<Flex mb={1} alignItems="center">
				<Text
					sx={{
						fontSize: "16px",
						color: "#818a92",
						fontWeight: "normal"
					}}
				>
					{fullName}
				</Text>{" "}
				<Box
					mx={1}
					width={4}
					height={4}
					sx={{ backgroundColor: "#2b3946", borderRadius: "50%" }}
				></Box>
				<Text
					sx={{
						fontSize: "12px",
						color: "#818a92",
						fontWeight: "normal"
					}}
					as="span"
				>
					{dateCalendar(createdAt)}
				</Text>
			</Flex>
			<Text
				sx={{
					fontSize: "16px",
					color: "#212b36",
					textOverflow: "ellipsis",
					fontWeight: "normal",
					overflow: "hidden",
					whiteSpace: "nowrap"
				}}
			>
				{parsedDescription.length > 150
					? parsedDescription.slice(0, 150)
					: parsedDescription}
			</Text>
		</Box>
	);
};

export default ListNotes;
