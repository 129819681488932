import { useState, useCallback } from "react";
import _get from "lodash/get";
import { MAX_CANDIDATE } from "../utils/constants";
import { QUERY } from "config";

export const useSelectGdprProfile = data => {
	const [include, setInclude] = useState([]);
	const [exclude, setExclude] = useState([]);
	const [mode, setMode] = useState("");
	const [selectedProfiles, setSelectedProfiles] = useState([]);

	const limit =
		_get(data, "total") < MAX_CANDIDATE ? _get(data, "total") : MAX_CANDIDATE;

	const isSelectAll =
		(mode === QUERY && exclude.length === include.length) ||
		(selectedProfiles.length === _get(data, "total", -1) &&
			selectedProfiles.length > 0);

	const toggleCard = useCallback(
		(e, id, index) => {
			let checked = e.target.checked;
			e?.stopPropagation?.();

			if (mode === QUERY) {
				if (!checked) {
					setInclude(old => old.filter(item => !(item === id)));
					if (index + _get(data, "offset") <= limit)
						return setExclude(old => [...new Set([...old, id])]);
				} else if (checked && !isSelectAll) {
					setExclude(old => old.filter(item => !(item === id)));
					if (index + _get(data, "offset") >= limit)
						return setInclude(old => [...new Set([...old, id])]);
				}
			} else {
				toggleProfile(id);
			}
			if (checked && !mode) {
				setMode("select");
			}
		},
		[mode, include, exclude]
	);

	const checkCandidateSelection = useCallback(
		(index, item) => {
			if (mode === QUERY) {
				return !exclude.includes(item._id);
			} else if (mode !== QUERY && selectedProfiles.includes(item._id)) {
				return true;
			} else {
				return false;
			}
		},
		[selectedProfiles, exclude, mode]
	);

	const toggleAll = useCallback(
		checked => {
			setInclude([]);
			setExclude([]);
			setSelectedProfiles([]);

			if (checked) {
				setMode(QUERY);
			} else {
				setMode(null);
			}
		},
		[data]
	);

	const toggleProfile = id => {
		setSelectedProfiles(selectedProfiles => {
			const rowExists = selectedProfiles.indexOf(id) !== -1;
			let profiles;

			if (rowExists) {
				profiles = selectedProfiles.filter(r => r !== id);
			} else {
				profiles = [...selectedProfiles, id];
			}

			return profiles;
		});
	};

	const reset = useCallback(() => {
		setMode("");
		setInclude([]);
		setExclude([]);
		setSelectedProfiles([]);
	}, []);

	return {
		selectedProfilesCounter:
			mode == QUERY
				? limit - exclude.length + include.length
				: selectedProfiles.length,
		isSelectAll,
		toggleAll,
		toggleCard,
		checkCandidateSelection,
		include,
		exclude,
		selectedProfiles,
		mode,
		reset
	};
};
