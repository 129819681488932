import React, { Component } from "react";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import BodyClassName from "react-body-classname";

import CompanyActivatedSuccessfully from "./CompanyActivatedSuccessfully";
import { setLocalUser } from "config/helpers";
import { STATUS_ACTIVATED, CLIENT } from "config";

export function getAccountActivatedRoute() {
	return "/company/congratulation";
}

class AccountActivated extends Component {
	componentDidMount() {
		const { user } = this.props;
		user.company_status = STATUS_ACTIVATED;
		setLocalUser(user);
		if (user.company_type === CLIENT) {
			browserHistory.push("/");
		}
	}

	render() {
		const { user } = this.props;

		return (
			<BodyClassName className="company-activated">
				<div className="account-activated">
					<div className="container">
						<div
							className={`text-center ${user.is_member ? "centred-box" : ""}`}
						>
							<CompanyActivatedSuccessfully
								type={user.company_type}
								role={user.role_name}
								isMember={user.is_member}
							/>
						</div>
					</div>
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.auth.user
	};
};
export default connect(mapStateToProps)(AccountActivated);
