import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
import Description from "./Description";
import BasicInfo from "./BasicInfo";
import { showModal } from "../../actions";
import {
	HEADLINE,
	IN_MISSION,
	AVAILABLE,
	NOT_AVAILABLE,
	DATE_FORMAT
} from "config";
import { viewContactInfo } from "../../../bids/actions/bidActions";
import Icon from "common/styled/icons";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import GlobalTooltip from "../../../../common/GlobalTooltip";
import {
	cvHeadlineSelector,
	cvSelector,
	cvAddressSelector,
	consultancyFunctionSelector
} from "../../selectors/cvSelectors";
import { ReactComponent as SendIcon } from "common/Icons/send-request.svg";
import { Button } from "common/styled/buttons";
import ModalSendRequest from "modules/freelancers/components/FreelancersTabSendRequestDrawer/FreelancersTabSendRequestDrawer";
import ProfileImage from "modules/company/components/avatar/profileAvatar.js";
import { PROFILE } from "config";
import { CV } from "config";

class TopSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displaySendRequestModal: false
		};
	}
	render() {
		const {
			data,
			ownCV,
			address = {},
			actions,
			bid_id,
			user,
			isConsultancy,
			viewProfile,
			companyName,
			userConsultancy,
			consultancyFunction,
			isFreelancer
		} = this.props;
		const { displaySendRequestModal } = this.state;
		return (
			<>
				<div id="top-section">
					<div
						className={`${
							isFreelancer && !ownCV ? "client-layout-padding" : "container"
						}`}
					>
						<div
							className="row"
							style={{ paddingBottom: isConsultancy ? 30 : 0 }}
						>
							<div className="col-md-12">
								<div className="col-sm-3 update">
									<ProfileImage
										user={
											isFreelancer
												? { ...data, ...user, id: data._id, bid_id }
												: { ...data, id: data._id, bid_id }
										}
										type={isFreelancer ? PROFILE : CV}
									/>
								</div>
								<div className="col-sm-6 margin-top-20">
									{data.first_name && (
										<div className="profile-name">
											{data.first_name} {data.last_name}
											{data.availability_status && (
												<div
													className={`availability ${data.availability_status} pull-right`}
												>
													{data.availability_status === IN_MISSION &&
														`On a mission until ${window.moment
															.unix(data.availability_date)
															.format(DATE_FORMAT)}`}
													{data.availability_status === AVAILABLE &&
														"Available"}
													{data.availability_status === NOT_AVAILABLE &&
														`Not Available until: ${window.moment
															.unix(data.availability_date)
															.format(DATE_FORMAT)}`}
												</div>
											)}
										</div>
									)}
									{isConsultancy && !ownCV && (
										<div className="col-md-7 pull-right">
											<h5>PROFILE TYPE</h5>
											<span className="bid_details">
												Consultancy{" "}
												<GlobalTooltip
													maxWidth="440px"
													overlay={`The applicant is not freelance but employed by a
																	consultancy. The contact information are the ones of
																	the consultant’s business unit manager who is in
																	charge of arranging interviews and potential contract
																	negotiation.`}
												/>
											</span>
										</div>
									)}
									<div className="title">
										{isConsultancy ? (
											<>
												<div className="function bid_details">
													{_get(consultancyFunction, "name", "Your function")}
												</div>
												<div className="tag hollow blue">
													{_get(
														consultancyFunction,
														"seniority",
														"Your seniority"
													)}
												</div>
											</>
										) : (
											<div className="profile-details">
												<span className="function">
													{_get(data, "main_function.name", "Your function")}
												</span>
												<span className="sector">
													(
													{_get(
														data,
														"main_function.category",
														"Your category"
													)}{" "}
													&amp;
													{_get(
														data,
														"main_function.subcategory",
														"Your sub-category"
													)}
													)
												</span>
												<div className="profile-seniority">
													{_get(
														data,
														"main_function.seniority",
														"Your seniority"
													)}
												</div>
											</div>
										)}
									</div>
									{isConsultancy && (
										<div className="category bid_details">
											<div className="circle" />
											{_get(
												consultancyFunction,
												"sector.parent_sector.name",
												"Your category"
											)}
											/
											{_get(
												consultancyFunction,
												"sector.name",
												"Your sub-category"
											)}
										</div>
									)}
									<Description content={data.about_me} ownCV={ownCV} />
								</div>
								<Flex flexDirection="column" alignItems="flex-end">
									{ownCV && (
										<div>
											<button
												className="btn-edit-profile"
												onClick={() => actions.showModal(HEADLINE)}
											>
												<Icon className="icon-edit-2" /> Edit profile
											</button>
										</div>
									)}
									{isFreelancer && user.company_type === "client" && (
										<div>
											<Button
												mt={10}
												className="btn-send-request"
												disabledHover
												onClick={() =>
													this.setState({ displaySendRequestModal: true })
												}
											>
												<SendIcon width={20} height={20} color={"#ff6849"} />
												&nbsp;&nbsp;Send request{" "}
											</Button>
										</div>
									)}
								</Flex>
								<div className="col-md-12 update">
									<BasicInfo
										country={address && address.country}
										city={address && address.city}
										mobile={data.phone}
										email={data.email}
										ownCV={ownCV}
										viewContactInfo={actions.viewContactInfo}
										bid_id={bid_id}
										viewProfile={viewProfile}
										isConsultancy={isConsultancy}
										ConsultancyName={companyName}
										userConsultancy={userConsultancy}
										isFreelancer={isFreelancer}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{displaySendRequestModal && (
					<ModalSendRequest
						isFreelancerProfile={true}
						onClose={() => this.setState({ displaySendRequestModal: false })}
						freelancers={[data._id]}
						freelancerName={data.first_name}
					/>
				)}
			</>
		);
	}
}

TopSection.propTypes = {
	data: PropTypes.object.isRequired,
	bid_id: PropTypes.string,
	user: PropTypes.object
};

const mapStateToProps = state => {
	return {
		//TODO: use selectors
		data: cvHeadlineSelector(state),
		consultancyFunction: consultancyFunctionSelector(state),
		userConsultancy: _get(cvSelector(state), "user_consultancy"),
		companyName: _get(cvSelector(state), "company_consultancy.name"),
		user: onlineUserSelector(state),
		address: cvSelector(state).preference
			? cvAddressSelector(state)
			: { country: "", city: "" }
	};
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ viewContactInfo, showModal }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TopSection);
