import React, { Component } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import StyledSelect from "../StyledSelect";
import { MAX_LANGUAGE_WEIGHT, getMaxIndicatorClassName } from "config";
import Scorer from "../scorer";
import { ScorerWrapper } from "../scorer/ScorerStyled";
import { Counter } from "../styled/CommonStyled";
require("./outer.css");

class EditableList extends Component {
	onRowChange = elemnt => {
		const { input, onRowChange } = this.props;
		onRowChange(elemnt);
		if (input && input.onChange) {
			input.onChange(elemnt);
		}
	};

	render() {
		const {
			input,
			name,
			label,
			position,
			simpleValue,
			defaultValue,
			searchable,
			placeholder,
			itemsLabel,
			options,
			selectedOptions,
			toolTipOverlay,
			onRowClick,
			onRowBlur,
			removeSelected,
			handleScoreChange,
			scoreFormater,
			tooltipFormater,
			scorePath,
			labelName,
			idPath,
			minHeight,
			onInputChange,
			scoreLabel,
			counter,
			maxCount,
			isTag,
			meta,
			...props
		} = this.props;
		const hasLength = selectedOptions && selectedOptions.length > 0;

		const counterLength = _get(selectedOptions, "length", 0);

		return (
			<div
				id="editableList"
				className={`selectable-control ${minHeight ? "dropdownProfile" : ""}`}
			>
				<StyledSelect
					label={label}
					options={options}
					position={position}
					simpleValue={simpleValue}
					onClick={onRowClick}
					onRowBlur={onRowBlur}
					searchable={searchable}
					placeholder={placeholder}
					toolTipOverlay={toolTipOverlay}
					onInputChange={onInputChange}
					meta={{ touched: false, error: "", ...meta }}
					input={{
						value: input.value || defaultValue,
						name: name,
						...input,
						onChange: this.onRowChange
					}}
					{...props}
					className={hasLength ? "noErrorMinHeight" : ""}
					showErrorContainer={true}
				/>

				<ScorerWrapper>
					{hasLength && (
						<Scorer
							scoreLabel={scoreLabel}
							onRemove={removeSelected}
							onScoreChange={handleScoreChange}
							maxScore={MAX_LANGUAGE_WEIGHT}
							items={selectedOptions}
							labelName={labelName}
							itemsLabel={itemsLabel}
							scoreFormater={scoreFormater}
							tooltipFormater={tooltipFormater}
							scorePath={scorePath}
							idPath={idPath}
							isTag={isTag}
						/>
					)}
					<Counter color={getMaxIndicatorClassName(counterLength, maxCount)}>
						{counter && `${counterLength}/${maxCount}`}
					</Counter>
				</ScorerWrapper>
			</div>
		);
	}
}

EditableList.defaultProps = {
	position: false,
	simpleValue: true,
	searchable: true,
	meta: {},
	input: {},
	defaultValue: "",
	onRowClick: null,
	minHeight: false,
	counter: false,
	maxCount: 20,
	onInputChange: () => {}
};

EditableList.propTypes = {
	removeSelected: PropTypes.func.isRequired,
	handleScoreChange: PropTypes.func.isRequired,
	onRowChange: PropTypes.func.isRequired,
	onRowClick: PropTypes.func,
	onInputChange: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([
				PropTypes.string.isRequired,
				PropTypes.object.isRequired
			]),
			label: PropTypes.string.isRequired
		})
	),
	name: PropTypes.string,
	label: PropTypes.string,
	position: PropTypes.bool.isRequired,
	simpleValue: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	searchable: PropTypes.bool.isRequired,
	placeholder: PropTypes.string,
	toolTipOverlay: PropTypes.string,
	itemsLabel: PropTypes.string.isRequired,
	minHeight: PropTypes.bool
};

export default EditableList;
