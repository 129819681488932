import React, { Component } from "react";
import { browserHistory, withRouter } from "react-router";
import { ConfirmationModal, ConfirmationTypes } from "./modal";
import UnsavedChangesModal from "./UnsavedChangesModal/UnsavedChangesModal";

class LeaveHook extends Component {
	state = {
		warned: false
	};

	handleBeforeUnload = e => {
		const { onLeave } = this.props;

		if (typeof onLeave === "function") {
			onLeave();
		} else if (this.props.dirty) {
			e.returnValue = "sure?";
			e.preventDefault();
		}
	};

	stay = () => {
		const { onStay } = this.props;

		this.setState({ warned: false });
		onStay && onStay();
		return false;
	};

	leave = () => {
		const { onLeave, onLeaveClick, enforceWarning } = this.props;
		onLeave?.();
		onLeaveClick?.();
		if (!enforceWarning) {
			this.setState({ warned: false });
			browserHistory.push(this.state.pathname);
		}
	};

	getCurrentPath = () => {
		const { pathname, search } = window.location;

		return `${pathname}${search}`;
	};

	handleRouteLeave = nextPath => {
		const { onOpen } = this.props;

		if (
			this.props.dirty &&
			!this.state.warned &&
			(this.props.location?.pathname !== nextPath.pathname ||
				this.props.location?.search !== nextPath.search)
		) {
			this.setState({ warned: true, pathname: nextPath });
			onOpen?.();
			return false;
		}
	};

	componentDidMount() {
		window.addEventListener("beforeunload", this.handleBeforeUnload);
		this.removeListener = browserHistory.listenBefore(this.handleRouteLeave);
	}

	componentWillUnmount() {
		window.removeEventListener("beforeunload", this.handleBeforeUnload);
		this.removeListener();
	}

	render() {
		if (this.props.confirmationModal) {
			const {
				onDiscard,
				disabled,
				isLoading,
				onClose
			} = this.props.confirmationModal;
			return this.state.warned || this.props.enforceWarning ? (
				<UnsavedChangesModal
					onClose={() => {
						onClose();
						this.stay();
					}}
					onDiscard={() => {
						onDiscard();
						this.leave();
					}}
					disabled={disabled}
					isLoading={isLoading}
				/>
			) : (
				<div />
			);
		} else {
			return [
				this.props.children,
				<ConfirmationModal
					key="leave_modal"
					onClose={this.stay}
					active={this.state.warned}
					modalName="leave_modal"
					type={ConfirmationTypes.error}
					title="Your changes have not been saved. Are you sure you'd like to leave this page without saving?"
					firstButton={{
						action: this.leave,
						label: "Leave",
						type: "warning"
					}}
					secondButton={{
						action: this.stay,
						label: "Stay"
					}}
				/>
			];
		}
	}
}

export default withRouter(LeaveHook);
