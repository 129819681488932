import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { ReactComponent as ListFilterIcon } from "static/icons/list-filter-icon.svg";
import { ReactComponent as SortByIcon } from "static/icons/sort-by-icon.svg";
import FilterModal from "./components/FilterModal";
import ListMenu from "./components/ListMenu";
import classnames from "./list-filter.module.scss";
import { ASC, DESC } from "config";
import { ReactComponent as SortAscIcon } from "static/icons/sort-ascending-icon.svg";
import { ReactComponent as SortDescIcon } from "static/icons/sort-descending_icon.svg";
import { ReactComponent as DeleteSortedField } from "static/icons/delete-sorted-field-icon.svg";

const ListFilter = ({ filterList, sortBylist, store, ...rest }) => {
	const [openFilterModal, setOpenFilterModal] = useState(false);
	const [openListMenu, setOpenListMenu] = useState(false);
	const [rows, setRows] = useState([]);
	const [selectedOptionsFilter, setOptionsFilter] = useState([]);

	const { hideParams } = rest;

	const { setSortByField, filters, saveRows, rows: savedRows } = store();

	const showFilterModal = () => setOpenFilterModal(true);
	const closeFilterModal = () => setOpenFilterModal(false);

	const showListMenu = () => setOpenListMenu(true);
	const closeListMenu = () => setOpenListMenu(false);

	const handleSelectField = field => {
		const fiedlName = get(field, "field", "");
		setSortByField(fiedlName, "asc");
		closeListMenu();
	};
	useEffect(() => {
		saveRows(rows);
	}, [get(rows, "length")]);

	useEffect(() => {
		setRows(savedRows);
	}, []);

	const numOfRows = get(filters, "fields", []).filter(item =>
		Boolean(
			get(
				item,
				"value._id.length",
				get(item, "value.time", get(item, "value.length", 0))
			)
		)
	).length;
	const changeSort = () =>
		setSortByField(
			get(filters, "field"),
			get(filters, "sort", ASC) !== ASC ? ASC : DESC
		);

	const sortedField = get(filters, "field");
	const deleteSortedField = () => setSortByField();
	return (
		<div className={classnames.listFilterContainer}>
			{sortedField && (
				<div className={classnames.sortedField}>
					<div className={classnames.field}>
						<span>
							{
								sortBylist.filter(field => field.field === sortedField)[0]
									?.label
							}
						</span>{" "}
						<button onClick={() => deleteSortedField()}>
							{" "}
							<DeleteSortedField />{" "}
						</button>{" "}
					</div>
					<button className={classnames.changeSortButton} onClick={changeSort}>
						{get(filters, "sort", ASC) === ASC ? (
							<SortAscIcon />
						) : (
							<SortDescIcon />
						)}
					</button>
				</div>
			)}
			<div>
				{Boolean(sortBylist) && (
					<button className={classnames.sortByButton} onClick={showListMenu}>
						<span>Sort by</span>
						<SortByIcon />
					</button>
				)}

				{openListMenu && (
					<React.Fragment>
						<div className={classnames.backdrop}></div>
						<div className={classnames.positionRelative}>
							<ListMenu
								closeMenu={closeListMenu}
								options={sortBylist}
								onSelect={handleSelectField}
							/>
						</div>
					</React.Fragment>
				)}
			</div>
			<div>
				{Boolean(filterList) && hideParams && (
					<button
						className={classnames.filterListButton}
						onClick={showFilterModal}
					>
						<span>Filter</span> {numOfRows > 0 && <span> {numOfRows} </span>}
						<ListFilterIcon />
					</button>
				)}

				{openFilterModal && (
					<div className={classnames.positionRelative}>
						<FilterModal
							filterList={filterList}
							setRows={setRows}
							rows={rows}
							selectedOptionsFilter={selectedOptionsFilter}
							setOptionsFilter={setOptionsFilter}
							onClose={closeFilterModal}
							store={store}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
export default ListFilter;
