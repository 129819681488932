import React from "react";
import { Label } from "@rebass/forms";
import { Controller } from "react-hook-form";
import s from "./contacts-form.module.scss";

const PhoneInput = React.lazy(() =>
	import("common/react-hook-form-components/PhoneInput")
);

function ContactsForm({ register, control, errors }) {
	return (
		<div className={s.root}>
			<div>
				<h4 style={{ marginBottom: 2 }}>Administrative contact person</h4>
				<div className={s.fieldsContainer}>
					<div>
						<Label htmlFor={"msp_contact_person.first_name"}>First name</Label>
						<input {...register("msp_contact_person.first_name")} />
						<div className={s.error}>
							{errors?.msp_contact_person?.first_name?.message}
						</div>
					</div>
					<div>
						<Label htmlFor={"msp_contact_person.last_name"}>Last name</Label>
						<input {...register("msp_contact_person.last_name")} />
						<div className={s.error}>
							{errors?.msp_contact_person?.last_name?.message}
						</div>
					</div>
					<div>
						<Label htmlFor={"msp_contact_person.phone"}>Phone number</Label>
						<React.Suspense fallback={"Loading..."}>
							<Controller
								name={"msp_contact_person.phone"}
								control={control}
								render={({ field: { onChange, value, name, ref } }) => (
									<PhoneInput
										ref={ref}
										preferredCountries={["be"]}
										fieldId="input"
										format={false}
										name={name}
										value={value?.displayValue}
										formatOnInit={false}
										formatOnChange={false}
										className={"contact-phone"}
										onPhoneNumberChange={(isValid, value, countryData) => {
											onChange({
												displayValue: value,
												countryData
											});
										}}
										error={errors?.msp_contact_person?.phone?.message}
									/>
								)}
							/>
							<div className={s.error}>
								{errors?.msp_contact_person?.phone?.displayValue?.message}
							</div>
						</React.Suspense>
					</div>
					<div>
						<Label htmlFor={"msp_contact_person.email"}>Email</Label>
						<input
							type={"email"}
							{...register("msp_contact_person.email")}
						/>{" "}
						<div className={s.error}>
							{errors?.msp_contact_person?.email?.message}
						</div>
					</div>
				</div>
			</div>
			<div>
				<h4 style={{ marginBottom: 2 }}>Commercial contact person</h4>
				<div className={s.fieldsContainer}>
					<div>
						<Label htmlFor={"commercial_contact_person.first_name"}>
							First name
						</Label>
						<input {...register("commercial_contact_person.first_name")} />
						<div className={s.error}>
							{errors?.commercial_contact_person?.first_name?.message}
						</div>
					</div>
					<div>
						<Label htmlFor={"commercial_contact_person.last_name"}>
							Last name
						</Label>
						<input {...register("commercial_contact_person.last_name")} />
						<div className={s.error}>
							{errors?.commercial_contact_person?.last_name?.message}
						</div>
					</div>
					<div>
						<Label htmlFor={"commercial_contact_person.phone"}>
							Phone number
						</Label>
						<React.Suspense fallback={"Loading..."}>
							<Controller
								name={"commercial_contact_person.phone"}
								control={control}
								render={({ field: { onChange, value, name, ref } }) => (
									<PhoneInput
										ref={ref}
										preferredCountries={["be"]}
										fieldId="commercial_contact_person.phone"
										format={false}
										name={name}
										value={value?.displayValue}
										formatOnInit={false}
										formatOnChange={false}
										onPhoneNumberChange={(isValid, value, countryData) => {
											onChange({
												displayValue: value,
												countryData
											});
										}}
										error={errors?.commercial_contact_person?.phone?.message}
									/>
								)}
							/>
							<div className={s.error}>
								{
									errors?.commercial_contact_person?.phone?.displayValue
										?.message
								}
							</div>
						</React.Suspense>
					</div>
					<div>
						<Label htmlFor={"commercial_contact_person.email"}>Email</Label>
						<input
							type={"email"}
							{...register("commercial_contact_person.email")}
						/>
						<div className={s.error}>
							{errors?.commercial_contact_person?.email?.message}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactsForm;
