import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { PROPOSE_SKILL } from "config/api-endpoints";

function getProposedSkill(data) {
	return client(PROPOSE_SKILL, { body: data });
}

function useProposeSkill(options) {
	return useMutation(getProposedSkill, options);
}

export default useProposeSkill;
