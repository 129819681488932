import React from "react";
import styles from "../SetupGuideCardStepRow/step.module.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function SkeletonCardStep() {
	return (
		<SkeletonTheme color="#E2E8F0">
			<div className={`${styles.step} ${styles.gradientBox}`}>
				<div className={styles.stepContent}>
					<Skeleton style={{ borderRadius: "64px" }} width={60} height={60} />

					<div className={styles.stepDescription}>
						<h5>
							<Skeleton width={120} height={18} />
						</h5>
						<p style={{ marginTop: "6px" }}>
							<Skeleton width={350} height={12} />
						</p>
					</div>
				</div>
				<div className={styles.stepActions}>
					<Skeleton width={100} height={20} />
				</div>
			</div>
		</SkeletonTheme>
	);
}

export function SkeletonLoader({ items = 4 }) {
	return (
		<>
			{Array.from(Array(items).keys()).map(item => {
				return <SkeletonCardStep key={item} />;
			})}
		</>
	);
}
