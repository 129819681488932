import React from "react";
import _get from "lodash/get";
import _set from "lodash/set";
import ControlledBooleanField from "./components/ControlledBooleanField";
import ControlledTextareaField from "./components/ControlledTextareaField";
import ControlledScoreCardField from "./components/ControlledScoreCardField";
import { ELEMENT_TYPES } from "modules/EvaluationFormsSettings/utils/constants";
import { useFormContext } from "react-hook-form";

export const RenderFieldElementByType = ({
	index,
	element,
	sectionIndex = null
}) => {
	const { type } = element;
	const name = sectionIndex
		? `${sectionIndex}-${ELEMENT_TYPES.section}.${index}-${type}`
		: `${index}-${type}`;

	const {
		control,
		formState: { errors }
	} = useFormContext();

	switch (type) {
		case ELEMENT_TYPES.question:
			return (
				<ControlledTextareaField
					rows={4}
					name={name}
					element={element}
					control={control}
					error={_get(errors, name)}
					placeholder="Type answer here..."
				/>
			);
		case ELEMENT_TYPES.score_card:
			return (
				<ControlledScoreCardField
					name={name}
					errors={errors}
					element={element}
					control={control}
				/>
			);
		case ELEMENT_TYPES.yes_no:
			return (
				<ControlledBooleanField
					name={name}
					element={element}
					control={control}
					error={_get(errors, name)}
				/>
			);

		default:
			break;
	}
};

export const prepareFormTemplatePayload = (templateElements, formValues) => {
	const templatePayload = [];
	templateElements.map((element, idx) => {
		if (element.type === ELEMENT_TYPES.section) {
			const sectionItems = element.items.map((subElement, subIdx) => {
				const sectionKey = `${idx}-${ELEMENT_TYPES.section}`;
				const subItemKey = `${subIdx}-${subElement.type}`;
				const key = `${sectionKey}.${subItemKey}`;
				return setElementValueByType({ formValues, element: subElement, key });
			});
			templatePayload.push({ ...element, items: sectionItems });
		} else {
			const key = `${idx}-${element.type}`;
			templatePayload.push(setElementValueByType({ formValues, element, key }));
		}
	});
	return templatePayload;
};
const setElementValueByType = ({ formValues, element, key }) => {
	if (element.type === ELEMENT_TYPES.score_card) {
		const scoreCardItems = element.items.map((scoreCard, index) => {
			return { ...scoreCard, value: _get(formValues, `${key}.${index}`, "") };
		});
		return { ...element, items: scoreCardItems };
	} else {
		return { ...element, value: _get(formValues, key, "") };
	}
};

export const getFormTemplateInitialValues = templateElements => {
	const initialValues = {};
	templateElements.map((element, idx) => {
		if (element.type === ELEMENT_TYPES.section) {
			element.items.map((subElement, subIdx) => {
				const sectionKey = `${idx}-${ELEMENT_TYPES.section}`;
				const subItemKey = `${subIdx}-${subElement.type}`;
				const value = getInitialValueByElement(subElement);
				_set(initialValues, `${sectionKey}.${subItemKey}`, value);
			});
		} else {
			const value = getInitialValueByElement(element);
			_set(initialValues, `${idx}-${element.type}`, value);
		}
	});
	return initialValues;
};
const getInitialValueByElement = element => {
	if (element.type === ELEMENT_TYPES.score_card) {
		return element.items.map(item => item?.value || "");
	} else {
		return element?.value || "";
	}
};
