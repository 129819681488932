import { queryCache, useQuery } from "react-query";
import { client } from "lib/api-client";
import { VIEW_CANDIDATE_ENDPOINT } from "config/api-endpoints";

const profileOverview = (_, { id }) =>
	client(VIEW_CANDIDATE_ENDPOINT, {
		body: {
			id,
			is_internal: true
		}
	});
export const profileOverviewKey = "@candidate/view-Candidate-internal";

const invalidate = queryCache.invalidateQueries("viewCandidate");
const useViewCandidateInternal = (options, queryOptions = {}) => {
	const query = useQuery([profileOverviewKey, options], profileOverview, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
	return {
		...query,
		invalidate
	};
};

export default useViewCandidateInternal;
