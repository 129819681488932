import React from "react";
import styles from "./step.module.scss";
import cardStyles from "../SetupGuideCard/setup-guide-card.module.scss";
import { ReactComponent as CheckIcon } from "static/icons/checkIcon2.svg";
import { ReactComponent as RefreshIcon } from "static/icons/refreshIcon.svg";
import ModalVideo from "../ModalVideo";

export default function SetupGuideCardStepRow({
	record,
	counter,
	onStepClick,
	isModalOpen,
	onCloseModalVideo
}) {
	const counterClassName = record?.is_done
		? `${styles.stepCounter} ${styles.stepCounter__checked}`
		: styles.stepCounter;

	const descriptionClassName = record?.is_done
		? `${styles.stepDescription} ${styles.stepDescription__checked}`
		: styles.stepDescription;

	return (
		<>
			<div className={`${styles.step} ${styles.gradientBox}`}>
				<div className={styles.stepContent}>
					<div className={counterClassName}>{record.order || counter}</div>
					<div className={descriptionClassName}>
						<h5>{record.title}</h5>
						<p>{record.description}</p>
					</div>
				</div>

				<div className={styles.stepActions}>
					{record?.is_done ? (
						<>
							{record.can_restart && (
								<button
									onClick={onStepClick}
									className={`${styles.roundedBox} ${styles.restartBtn}`}
								>
									<RefreshIcon />
								</button>
							)}
							<div className={`${styles.roundedBox} ${styles.checkIcon}`}>
								<CheckIcon />
							</div>
						</>
					) : (
						<button
							onClick={onStepClick}
							className={`${cardStyles.btn} ${cardStyles.btnSuccess}`}
						>
							{record.button_text}
						</button>
					)}
				</div>
			</div>
			{isModalOpen && record.type === "video" && (
				<ModalVideo
					onClose={() => onCloseModalVideo(record.key)}
					videoLink={record.link}
				/>
			)}
		</>
	);
}
