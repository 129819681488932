import { useQuery } from "react-query";
import { client } from "../../../../lib/api-client";
import { LIST_INDUSTRIES_ENDPOINT } from "../../../../config/api-endpoints";

const getIndustries = () =>
	client(LIST_INDUSTRIES_ENDPOINT, { method: "post" });

function useGetIndustries() {
	return useQuery("@suppliers/get-industries", getIndustries, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity
	});
}

export default useGetIndustries;
