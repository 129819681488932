import React from "react";
import { ReactComponent as ClearIcon } from "static/icons/clear_icon.svg";
import HeaderButton from "../HeaderButton/HeaderButton";

const ClearButton = ({ onClick }) => {
	return (
		<HeaderButton
			icon={<ClearIcon />}
			text="Clear"
			className="btnDelete"
			variant="big"
			onClick={onClick}
		/>
	);
};

ClearButton.displayName = "ClearButton";

export default ClearButton;
