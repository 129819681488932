import React from "react";
import { ReactComponent as WarningIcon } from "static/icons/IconWarning.svg";
import style from "modules/MyCompany/components/DeleteModal/delete-modal.module.scss";

const SecondStepComponent = () => {
	return (
		<div className={style.deleteContainer}>
			<WarningIcon />{" "}
			<p className={style.text}>
				You’re about to delete this site permanently. This action can not be
				undone
			</p>
		</div>
	);
};
export default SecondStepComponent;
