import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";

const queryFn = body => client(NEW_FILTER_SETTINGS, { body });
export const EVALUATION_FILTERS_SETTINGS_QUERY =
	"EVALUATION_FILTERS_SETTINGS_QUERY";

export const useGetEvaluationFilterSettings = (payload, options = {}) =>
	useQuery(
		[EVALUATION_FILTERS_SETTINGS_QUERY, payload],
		() => queryFn(payload),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			...options
		}
	);
