export const NEW_BID = "new_bid";
export const NEW_CONTRACT = "new_contract";
export const TIMESHEET_TO_REVIEW = "timesheet_to_review";
export const UPCOMING_STARTERS = "upcoming_starters";
export const UPCOMING_FINISHERS = "upcoming_finishers";
export const REQUEST = "request";
export const PENDING_APPLICATIONS = "pendingApplications";
export const OPEN_JOBS = "openJobs";
export const JOBS_OVERDUE = "jobsOverdue";
export const PROACTIVE_APPLICATIONS = "proactiveApplications";
export const RECENT_SUPPLIERS = "recentSuppliers";
export const MY_SUPPLIERS = "mySuppliers";
export const NEW_FREELANCER = "newFreelancer";
export const PERMANENT_INTERVIEWS = "permanent_interviews";
export const TEMPORARY_INTERVIEWS = "temporary_interviews";
export const COUNT = "count";
export const MYTASKS = "MyTasks";
export const ASSIGNEDTOME = "assignedToMe";
export const VACANCY_CANDIDATES_LIST = "vacancyCandidatesList";

//TODO Remove these constants and use array CANDIDATE_TABS
export const OVERVIEW = "overview";
export const PROFILE = "profile";
export const PROCESSES = "processes";
export const INTERACTIONS = "interactions";
export const NOTES = "notes";
export const FILES = "files";

export const CANDIDATE_TABS = {
	OVERVIEW: "overview",
	PROFILE: "profile",
	EVALUATIONS: "evaluations",
	PROCESSES: "processes",
	INTERACTIONS: "interactions",
	NOTES: "notes",
	FILES: "files",
	CONVERSATIONS: "conversations"
};

export const PROFILE_TABS = {
	INTERNAL_DATA: "internal-data",
	CANDIDATE_PROFILE: "candidate-profile",
	EDITION_HISTORY: "edition-history"
};

export const VACANCY_VIEW = "vacancy-view";

export const CANDIDATE_ACTIONS = {
	MERGE: "merge",
	ADD_TO_CANDIDATES: "add_to_candidates",
	CANDIDATE_ALREADY_POOL: "candidate_already_pool"
};
