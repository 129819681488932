import React from "react";
import { ReactComponent as ArrowBack } from "../../icons/arrow-left.svg";
import styles from "./header.module.scss";
import { browserHistory } from "react-router";
import usePublishRequestStore from "modules/publishRequest/hooks/usePublishRequestStore";
import { PUBLISHED } from "config";

export default function Header() {
	const {
		setIsConfirmationDrawerOpen,
		selectedChannels,
		request
	} = usePublishRequestStore();

	const onClickBack = () => {
		browserHistory.goBack();
	};

	const onClickPublish = () => {
		setIsConfirmationDrawerOpen(true);
	};

	return (
		<div className={styles.header}>
			<button className={styles.backButton} onClick={onClickBack}>
				<ArrowBack />
				<span>Back</span>
			</button>
			<button
				disabled={!selectedChannels.length}
				className={styles.publishButton}
				onClick={onClickPublish}
			>
				{request?.status === PUBLISHED ? "Republish" : "Publish"}
			</button>
		</div>
	);
}
