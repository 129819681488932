import React from "react";
import styles from "../../messaging-tool-email-list.module.scss";
import { ReactComponent as EmailIcon } from "static/icons/email-2.svg";
import { ReactComponent as EmailOpenIcon } from "static/icons/email-open.svg";
import Tooltip from "common/Tippy";

export default function MessagingToolEmailUnreadButton({
	onClick,
	tooltipText,
	isUnread = true
}) {
	return (
		<Tooltip content={tooltipText} theme="dark">
			<button
				className={styles.cta}
				onClick={e => {
					e.stopPropagation();
					onClick(e);
				}}
			>
				{isUnread ? (
					<EmailOpenIcon className={styles.icon} width={22} height={22} />
				) : (
					<EmailIcon className={styles.icon} width={22} height={22} />
				)}
			</button>
		</Tooltip>
	);
}
