import React, { memo } from "react";
import { Link } from "react-router";
import _get from "lodash/get";
import Avatar from "common/Avatar/index";
import {
	LocationBoxContainer,
	LocationBox,
	Profile,
	ProfileRef,
	InfoContainer,
	Tag,
	Details,
	AvatarContainer,
	Function,
	EmptyLocation,
	FlexContainer,
	PreInformation
} from "../../styled";
import GlobalTooltip from "common/GlobalTooltip";
import profileIcon from "static/icons/anonymous-avatar.svg";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import CurrencyFormatter from "common/CurrencyFormater";

function ProfileItem({
	profile: {
		id,
		reference,
		functions,
		preferred_location,
		expected_salary_advantage,
		expected_salary,
		first_name,
		avatar,
		last_name,
		email,
		main_function,
		main_location
	},
	vacancyId,
	className
}) {
	const location = `${_get(main_location, "city")}, ${_get(
		main_location,
		"country"
	)} `;
	return (
		<Profile className={`job ${className}`}>
			<Link
				to={getPermanentProfileRoute(
					vacancyId ? `${id}?vacancy=${vacancyId}` : id
				)}
			>
				<InfoContainer>
					<FlexContainer>
						<AvatarContainer>
							{email && first_name && last_name ? (
								<div className="show-avatar">
									<Avatar
										src={avatar}
										size={64}
										name={`${first_name} ${last_name}`}
									/>
									<div className="search-profile-name">{`${first_name} ${last_name}`}</div>
								</div>
							) : (
								<div className="anonymous-avatar">
									<img alt="anonymous" height="70px" src={profileIcon} />
									<GlobalTooltip
										placement="top"
										withWrappingDiv={false}
										overlay="Candidates’ profiles remain anonymous until they confirm their interest for a vacancy. To send them your vacancy, simply poke the candidate!"
									>
										<i className="icon-lockfiled" />
									</GlobalTooltip>
									<ProfileRef id="profile-ref">REF: {reference}</ProfileRef>
								</div>
							)}
						</AvatarContainer>
						<PreInformation>
							<Tag>
								<div>{main_function && main_function.seniority}</div>
							</Tag>
							<div className="functions-cont">
								<GlobalTooltip
									placement="top"
									overlay="This candidate has other functions that he/she can endorse. Click on his profile to see them. "
									overlayClassName="g-tool-dark"
									withWrappingDiv={false}
									ellipsisMode={true}
								>
									<Function>{main_function && main_function.name}</Function>
								</GlobalTooltip>
								{functions && functions.length > 1 && (
									<GlobalTooltip
										placement="top"
										overlay="This candidate has other functions that he/she can endorse. Click on his profile to see them. "
										overlayClassName="g-tool-white"
										withWrappingDiv={false}
									>
										<div className="functions-counter">{`(+${functions &&
											functions.length - 1})`}</div>
									</GlobalTooltip>
								)}
							</div>

							{preferred_location &&
							preferred_location.length > 0 &&
							main_location ? (
								<LocationBoxContainer>
									<GlobalTooltip
										placement="top"
										overlay={location}
										overlayClassName="g-tool-dark"
										ellipsisMode={true}
									>
										<LocationBox name="location_box">{location}</LocationBox>
									</GlobalTooltip>
									{preferred_location && preferred_location.length > 1 && (
										<GlobalTooltip
											placement="top"
											overlay="This candidate has additional preferred locations. They are listed in the “Preferences” section of his/her profile"
											overlayClassName="g-tool-white"
											withWrappingDiv={false}
										>
											<div className="location-counter">{`(+${preferred_location &&
												preferred_location.length - 1})`}</div>
										</GlobalTooltip>
									)}
								</LocationBoxContainer>
							) : (
								<EmptyLocation />
							)}
						</PreInformation>
					</FlexContainer>
					<Details>
						<div className="salary">
							<div className="title">expected salary</div>
							<div className="salary-content">
								<div>
									<CurrencyFormatter cent={expected_salary} />
								</div>{" "}
								Gross / year
							</div>
						</div>
						<div className="advantages">
							<div className="title">advantages</div>
							<GlobalTooltip
								ellipsisMode={true}
								placement="top"
								overlayClassName="g-tool-dark"
								overlay={expected_salary_advantage}
							>
								<div className="advantages-description">
									{expected_salary_advantage ? (
										expected_salary_advantage
									) : (
										<div className="no-advantage">Not applicable</div>
									)}
								</div>
							</GlobalTooltip>
						</div>
					</Details>
				</InfoContainer>
			</Link>
		</Profile>
	);
}

export default memo(ProfileItem);
