import React from "react";
import { QuestionnaireFormLabel } from "../QuestionnaireFormLabel";

export default function QuestionnaireFormSimpleText({
	description,
	className
}) {
	return (
		<QuestionnaireFormLabel description={description} className={className} />
	);
}
