import { useMutation } from "react-query";
import { client } from "../../../lib/api-client";
import {
	MOVE_ELEMENT,
	SEARCH_PROFILE_ENDPOINT,
	UPDATE_USER_SETTING
} from "../../../config/api-endpoints";

function updateColumnSettings(body) {
	return client(UPDATE_USER_SETTING, {
		body
	});
}

function moveColumn(body) {
	return client(MOVE_ELEMENT, {
		body
	});
}

function sortColumn(body) {
	return client(SEARCH_PROFILE_ENDPOINT, {
		body
	});
}

export function useMoveColumnSettings(options) {
	return useMutation(moveColumn, options);
}

export function useUpdateColumnSettings(options) {
	return useMutation(updateColumnSettings, options);
}

export function useSortData(options) {
	return useMutation(sortColumn, options);
}
