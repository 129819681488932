import React from "react";
import _get from "lodash/get";
import styles from "./delete-invitation.module.scss";
import BlockUserButton from "./BlockUserButton";
import { ReactComponent as AlertIcon } from "static/icons/alertIcon2.svg";
import Modal from "common/modal";
import { ReactComponent as CloseIcon } from "static/icons/closeModal.svg";

const ChangeUserAccess = ({ user, onAccessChanged, onClose, isUnblock }) => {
	const fullName = `${_get(user, "first_name", "")} ${_get(
		user,
		"last_name",
		""
	)}`;
	return (
		<Modal
			size={"620px"}
			padding={"0px"}
			borderRadius={"12px"}
			buttonCloseClassName={"hide"}
			style={{
				background: "#FFF",
				boxShadow:
					"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
				overflow: "hidden"
			}}
		>
			<div className={styles.modalContainer}>
				<div className={styles.headerModal}>
					<h3>{`${isUnblock ? "Unblock" : "Block"} user`}</h3>
					<button onClick={() => onClose()}>
						<CloseIcon />
					</button>
				</div>
				<div className={styles.contenuModal}>
					<div className={styles.modal__alert}>
						<AlertIcon />
					</div>
					<div>
						<p className={styles.modal__textBloskUser}>
							{`Are you sure you want to ${isUnblock ? "unblock" : "block"}`}{" "}
							<strong>{fullName}</strong>?
						</p>
						<p>{`Doing this will ${
							isUnblock ? "enable" : "disable"
						} the user’s access to their account. You will be able to ${
							isUnblock ? "block" : "unblock"
						} them later.`}</p>
					</div>
				</div>
				<div className={styles.footer}>
					<button className={styles.cancelBtn} onClick={() => onClose()}>
						Cancel
					</button>
					<BlockUserButton
						isUnblock={isUnblock}
						onClick={() => onAccessChanged(user)}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ChangeUserAccess;
