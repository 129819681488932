import create from "zustand";

const initialialState = {
	channelName: "",
	region: "",
	regionInput: "",
	industry: "",
	jobTitle: "",
	jobFunction: "",
	sortBy: "relevant",
	display: "all_channels",
	cururency: "",
	language: "",
	channelsOffset: 0,
	contractOffset: 0
};

const useFiltersStore = create(set => ({
	...initialialState,
	setvalue: (key, value) =>
		set({ [key]: value, channelsOffset: 0, contractOffset: 0 }),
	setChannelsOffset: value => set({ channelsOffset: value }),
	setContractOffset: value => set({ contractOffset: value }),
	reset: () => set(initialialState)
}));

export default useFiltersStore;
