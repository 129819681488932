import React from "react";
import CurrencyInput from "common/NewCurrencyField/NewCurrencyField";

export const AmountCustomInput = ({ onChange, value }) => (
	<CurrencyInput
		useGreenTheme
		input={{
			value,
			onChange: value => onChange(value, "change")
		}}
	/>
);
