import React, {
	useContext,
	memo,
	useState,
	useEffect,
	useRef,
	useMemo
} from "react";
import _groupBy from "lodash/groupBy";
import _get from "lodash/get";
import _map from "lodash/map";
import _reduce from "lodash/reduce";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { ListContext, LIST_ACTION_TYPES } from "./listContext";
import {
	ListItemContainer,
	ListItem,
	SkillLabel,
	ListButton,
	CategoryName,
	MustHaveInput,
	MustHaveLabel,
	AddedMessage,
	MustHaveCounter
} from "./style";
import {
	getMaxIndicatorClassName,
	MAX_SKILL_LENGTH,
	MAX_SKILL_NAME_LENGTH
} from "config";
import { getSkillsTooltip } from "config/helpers";
import { excerpt } from "common/Functions";
import GlobalTooltip from "common/GlobalTooltip";
import { Counter } from "../../styled/CommonStyled";
import SimpleScorer from "../../scorer/Simple";
import { CartContainer } from "./style";
import Star from "../../Icons/Star";

const MAX_MUST_HAVE_COUNT = 7;

function Cart({
	grouped = false,
	groupField = "parent_sector.name",
	title,
	showMustHave,
	onScoreChange,
	onDelete
}) {
	const { state, dispatch } = useContext(ListContext);
	const [highlight, setHighlight] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const data = grouped
		? _groupBy(_get(state, "selected"), s => _get(s, groupField))
		: state.selected;

	const counterLength = grouped
		? _reduce(
				data,
				(acc, v) => {
					acc += v.length;
					return acc;
				},
				0
		  )
		: data.length;

	const timer = useRef();
	const lastSkill = useRef();

	const remove = () => {
		timer.current = window.setTimeout(() => {
			setHighlight(false);
			window.clearInterval(timer.current);
		}, 1500);
	};

	useEffect(() => lastSkill.current.scrollIntoView({ behavior: "smooth" }), [
		_get(state, "lastAdded")
	]);

	const highlightLast = () => {
		if (timer.current !== null) {
			setShowMessage(false);
			window.clearInterval(timer.current);
		}
		setHighlight(true);
		remove();
	};

	useEffect(() => {
		highlightLast();
		return () => {
			if (timer.current !== null) {
				window.clearInterval(timer.current);
			}
		};
	}, [_get(state, "lastAdded")]);

	useEffect(() => {
		if (_get(state, "lastMustHave")) {
			if (timer.current !== null) {
				setHighlight(false);
				window.clearInterval(timer.current);
			}
			timer.current = window.setTimeout(() => {
				setShowMessage(false);
				window.clearInterval(timer.current);
			}, 1500);
			setShowMessage(true);
		}
	}, [_get(state, "lastMustHave")]);

	const mustHaveCount = useMemo(
		() =>
			_reduce(
				_get(state, "selected"),
				(acc, item) => {
					acc += Number(item.must_have || false);
					return acc;
				},
				0
			),
		[_get(state, "selected")]
	);

	const renderItem = item => {
		return (
			<div key={item._id}>
				<ListItemContainer cart>
					<ListItem cart highlight={highlight && state.lastAdded === item._id}>
						<GlobalTooltip
							placement="top"
							overlay={item.name}
							withWrappingDiv={false}
							active={item.name.length > MAX_SKILL_NAME_LENGTH}
						>
							<SkillLabel>
								{excerpt(item.name, MAX_SKILL_NAME_LENGTH)}
							</SkillLabel>
						</GlobalTooltip>
						<MustHaveInput
							name={"must_have"}
							type="checkbox"
							checked={!!item.must_have}
							disabled={!item.must_have && mustHaveCount >= MAX_MUST_HAVE_COUNT}
							onChange={() =>
								dispatch({
									type: LIST_ACTION_TYPES.UPDATE_MUST_HAVE,
									item: item._id
								})
							}
						/>
						{showMustHave && (
							<MustHaveLabel htmlFor="must_have">
								<AddedMessage
									showMessage={showMessage && state.lastMustHave === item._id}
								>
									Added to must have
								</AddedMessage>
								<Star className={`star ${!item.must_have && "gray"}`} />
							</MustHaveLabel>
						)}
						<SimpleScorer
							tooltipFormater={getSkillsTooltip}
							maxScore={5}
							item={item}
							onScoreChange={item => {
								dispatch({ type: LIST_ACTION_TYPES.ON_SCORE_CHANGE, item });
								onScoreChange();
							}}
						/>
						<ListButton
							marginLeft="5px"
							onClick={() => {
								dispatch({ type: LIST_ACTION_TYPES.REMOVE_ITEM, item });
								onDelete();
							}}
						>
							<span className="icon-close2" />
						</ListButton>
					</ListItem>
				</ListItemContainer>
			</div>
		);
	};

	const scrollBarsRenderView = ({ style, ...props }) => {
		const viewStyle = {
			padding: 15
		};
		return <div style={{ ...style, ...viewStyle }} {...props} />;
	};

	return (
		<div style={{ margin: "0 15px" }}>
			<label className="upperCase" style={{ width: "100%" }}>
				{title}
			</label>
			<CartContainer>
				<Scrollbars
					style={{ height: "100%" }}
					renderView={scrollBarsRenderView}
				>
					<>
						{grouped
							? _map(Object.keys(data), key => {
									return (
										<React.Fragment key={key}>
											<CategoryName>{key}</CategoryName>
											{_map(data[key], item => renderItem(item))}
										</React.Fragment>
									);
							  })
							: _map(data, item => renderItem(item))}
						<div ref={lastSkill}></div>
					</>
				</Scrollbars>
			</CartContainer>
			{showMustHave && (
				<MustHaveCounter
					color={getMaxIndicatorClassName(mustHaveCount, MAX_MUST_HAVE_COUNT)}
				>
					Marked as must have: {mustHaveCount} / {MAX_MUST_HAVE_COUNT}
				</MustHaveCounter>
			)}
			<Counter
				className="counter-no-padding"
				style={{ top: 0, paddingTop: 0 }}
				color={getMaxIndicatorClassName(counterLength, MAX_SKILL_LENGTH)}
			>
				{`${counterLength}/${MAX_SKILL_LENGTH}`}
			</Counter>
		</div>
	);
}

Cart.propTypes = {
	grouped: PropTypes.bool,
	groupField: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	counterLength: PropTypes.number,
	maxCount: PropTypes.number
};

export default memo(Cart);
