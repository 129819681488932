import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { OutlineButton, Button, ButtonsContainer } from "common/styled/buttons";
import { CLIENT } from "config";
import Icon from "common/styled/icons";

const freelancerText = (
	<Fragment>
		In order to offer a secure environment to our users, our support team needs
		to be able to confirm the identity of all registering users.
		<br />
		By skipping this step, you will have access to limited functionalities. You
		will see the job opportunities but the companies’ names will be hidden and
		you won’t be allowed to bid on missions until your identity has been
		confirmed.
	</Fragment>
);

const clientText = (
	<Fragment>
		In order to offer a secure environment to our users, our support team needs
		to be able to confirm the identity of all registering users. <br />
		By skipping this step, you will have access to limited functionalities. You
		will be able to prepare your requests for temporary staff but won’t be
		allowed to post them until your identity has been confirmed.
	</Fragment>
);

const Skipper = ({ onConfirm, goBack, userType }) => {
	return (
		<div className="identity">
			<div className="container">
				<h3 className="text-center h3-title">Personal Identity</h3>
			</div>
			<div className="skip-description">
				<h3 className="skip-title">Are you sure you want to skip this step?</h3>
				{userType === CLIENT ? clientText : freelancerText}
			</div>
			<ButtonsContainer className="center-actions">
				<OutlineButton small large has-icon onClick={goBack}>
					<Icon size={14} className="icon-arrow-left-new" />
					BACK
				</OutlineButton>
				<Button small medium bordered onClick={onConfirm}>
					SKIP ANYWAY
				</Button>
			</ButtonsContainer>
		</div>
	);
};

Skipper.propTypes = {
	goBack: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired
};

export default Skipper;
