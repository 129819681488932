import React, { memo } from "react";
import PropTypes from "prop-types";
import _groupBy from "lodash/groupBy";
import Tooltip from "rc-tooltip";
import styled from "styled-components";
import Skills from "common/Skills";
import { getSkillsTooltip } from "config/helpers";
import { colors } from "config/styles";

import Star from "common/Icons/Star";

const CategoryContainer = styled.div`
	margin-top: -10px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	.category-name {
		color: ${colors.anchorColor};
		font-weight: bold;
		line-height: 2.23;
		text-align: left;
	}
`;

function SkillsSection({ data }) {
	const renderSkillsByCategory = () => {
		const categories = _groupBy(data, "parent_sector.name");
		let skills = [];
		for (let category in categories) {
			skills.push(
				<CategoryContainer key={category}>
					<div className="category-name">{category}</div>
					{renderSkills(categories[category])}
				</CategoryContainer>
			);
		}
		return skills;
	};

	const renderSkills = skills => {
		return (
			skills &&
			skills.length !== 0 && (
				<Skills
					skills={skills}
					grouped={"sector.parent_sector.name"}
					max={12}
					showMoreText={"•••"}
					showLessText={"↑"}
					readLessLinkClassName={"tag link"}
					readMoreLinkClassName={"tag link"}
					renderOption={skill =>
						skill.must_have ? (
							<Tooltip
								placement="top"
								overlay={
									<div>
										<div>
											This skill was marked as a <strong>MUST HAVE</strong>.
										</div>
										Candidates lacking this skill will be excluded from the
										potential matches list.
									</div>
								}
								trigger="hover"
								destroyTooltipOnHide={true}
								mouseLeaveDelay={0}
								overlayClassName="g-tool-white"
								key={skill._id}
							>
								<div className="tag">
									<Star className="must_have_star" />
									{`${skill.name} ( ${skill.score} / 5 )`}
								</div>
							</Tooltip>
						) : (
							<Tooltip
								placement="top"
								overlay={<div>{getSkillsTooltip(skill.score)}</div>}
								trigger="hover"
								destroyTooltipOnHide={true}
								mouseLeaveDelay={0}
								overlayClassName="g-tool-white"
								key={skill._id}
							>
								<div className="tag">
									{`${skill.name} ( ${skill.score} / 5 )`}
								</div>
							</Tooltip>
						)
					}
				/>
			)
		);
	};

	return renderSkillsByCategory();
}

SkillsSection.propTypes = {
	data: PropTypes.array.isRequired
};

SkillsSection.defaultProps = {
	data: []
};

export default memo(SkillsSection);
