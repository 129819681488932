import styled from "styled-components";

export default styled("div")`
	width: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	height: 10px;
	margin-right: 4px;
	background: ${props =>
		props.backgroundColor ? props.backgroundColor : null};
`;

export const FlexBox = styled.div`
	display: inline-flex;
	align-items: center;
	border-radius: 10px;
	padding: 2px 8px 2px 5px;
	color: ${props => (props.color ? props.color : null)};
	background: ${props => (props.borderStatus ? props.borderStatus : null)};
`;
