import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_OPEN_JOBS_ENDPOINT } from "config/api-endpoints";
import { WELCOME_PAGE_OPEN_JOBS } from "config";
import { openJobsListKey } from "modules/home/api/queriesKeys.js";

const listOpenJobs = (key, { start_date, end_date }) =>
	client(LIST_OPEN_JOBS_ENDPOINT, {
		body: {
			start_date,
			end_date,
			job_type: "vacancy",
			tag: WELCOME_PAGE_OPEN_JOBS
		}
	});

const useOpenJobsList = (options, queryOptions = {}) =>
	useQuery([openJobsListKey, options], listOpenJobs, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useOpenJobsList;
