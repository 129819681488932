import React from "react";
import GroupIcon from "./GroupIcon";
import InterviewIcon from "./InterviewIcon";
import InvoiceIcon from "./InvoiceIcon";
import ProfileIcon from "./ProfileIcon";
import RatingIcon from "./RatingIcon";
import SystemIcon from "./SystemIcon";
import TimesheetIcon from "./TimesheetIcon";
import CompanyIcon from "./CompanyIcon";
import ContractIcon from "./ContractIcon";
import SkillsIcon from "./SkillsIcon";

const IconsChecker = ({ type }) => {
	const iconsArray = [
		{
			name: "group",
			type: ["add_group_notification", "update_group_notification"]
		},
		{
			name: "interview",
			type: ["reminder_interview_notification", "reminder_done_interview_email"]
		},
		{
			name: "company",
			type: [
				"accept_invitation_notification",
				"change_company_identity_status_notification",
				"change_company_information_status_notification"
			]
		},
		{
			name: "invoice",
			type: [
				"generate_invoice_notification",
				"reminder_client_to_pay_invoice_notification",
				"unpaid_invoices_notification",
				"wire_transfer_notification"
			]
		},
		{
			name: "skill",
			type: [
				"job_matching_notification",
				"skill_banned_notification",
				"skill_name_edited_notification"
			]
		},
		{
			name: "timesheet",
			type: [
				"reminder_client_to_validate_time_sheet_notification",
				"reminder_fill_timesheet_notification"
			]
		},
		{
			name: "profile",
			type: ["edit_user_role_notification"]
		},
		{
			name: "contract",
			type: ["reminder_contract_notification"]
		},
		{
			name: "rating",
			type: ["rate_profile_notification"]
		},
		{
			name: "system",
			type: ["client_expired_job_notification", "welcome_notification"]
		}
	];
	let result = iconsArray.find(obj => obj.type.includes(type));
	let name = result ? result.name : "system";
	switch (name) {
		case "interview":
			return <InterviewIcon />;
		case "group":
			return <GroupIcon />;
		case "company":
			return <CompanyIcon />;
		case "rating":
			return <RatingIcon />;
		case "invoice":
			return <InvoiceIcon />;
		case "profile":
			return <ProfileIcon />;
		case "timesheet":
			return <TimesheetIcon />;
		case "contract":
			return <ContractIcon />;
		case "skill":
			return <SkillsIcon />;
		case "system":
			return <SystemIcon />;
		default:
			return <SystemIcon />;
	}
};
export default IconsChecker;
