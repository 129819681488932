import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import _get from "lodash/get";

import { onlineUserSelector } from "modules/user/selectors/selectors";
import JobSearch from "./index";
import { PERMANENT, APPLICATION } from "config";
import toaster from "common/Toaster";

export const getOfflineSearchRoute = id =>
	`/vacancies${id ? `/${id}` : `(/:id)`}`;
export const getOfflineApplyRoute = id =>
	`vacancies/${id ? id : `:id`}/application`;

const OfflineSearch = ({ user, params, location }) => {
	const [isOnline, setIsOnline] = useState(false);

	useEffect(() => {
		if (_get(user, "access_token")) {
			setIsOnline(true);
		}
	}, [user]);

	useEffect(() => {
		if (_get(user, "access_token") && _get(user, "type") === PERMANENT) {
			browserHistory.push("/jobs/search");
		} else if (_get(user, "access_token")) {
			toaster.danger("You don't have enough permissions to access this page");
			browserHistory.push({
				pathname: "/"
			});
		}
	}, []);

	const pathnameSplit = location.pathname.split("/");
	let isDirectApply = false;
	if (pathnameSplit[3] && pathnameSplit[3] === APPLICATION) {
		isDirectApply = true;
	}
	return (
		<JobSearch
			isOnline={isOnline}
			vacancyId={_get(params, "id")}
			isDirectApply={isDirectApply}
		/>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps)(OfflineSearch);
