import React from "react";
import { Button } from "common/Button";
import styles from "./delete-evaluation-modal.module.scss";
import useDeleteEvaluation from "../../hooks/useDeleteEvaluation";
import { ReactComponent as CloseIcon } from "static/icons/cross-black.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-icon-grey.svg";
import { ReactComponent as NotificationIcon } from "static/icons/warning-circle.svg";

import get from "lodash/get";
import Modal from "common/modal";
import Portal from "common/Portal";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { GET_EVALUATIONS_QUERY_KEY } from "../../hooks/useGetPermanentEvaluationsList";
import { GET_PERMANENT_EVALUATIONS_LIST_QUERY_KEY } from "modules/candidate/Profile/api/useGetPermanentEvaluations";
import { GET_EVALUATIONS_SUMMARY_QUERY_KEY } from "../../hooks/useGetProfileSummaryEvaluationsList";

const DeleteEvaluationModal = ({ onClose, payload, onSuccess, name }) => {
	const [mutate, { isLoading }] = useDeleteEvaluation({
		onSuccess: () => {
			toaster.success("Evaluation deleted !");
			queryCache.invalidateQueries(GET_EVALUATIONS_QUERY_KEY);
			queryCache.invalidateQueries(GET_PERMANENT_EVALUATIONS_LIST_QUERY_KEY);
			queryCache.invalidateQueries(GET_EVALUATIONS_SUMMARY_QUERY_KEY);
			onClose();
			onSuccess?.();
		},
		onError: error => {
			let errorMessage = get(error, "detail");
			if (error.status === 422) {
				if (typeof errorMessage === "object") {
					Object.keys(errorMessage).forEach(name => {
						get(errorMessage, name) &&
							toaster.error(
								`${name} : ${
									typeof get(errorMessage, name) === "string"
										? get(errorMessage, name)
										: get(errorMessage, name)[0]
								}`
							);
					});
				} else {
					toaster.error(errorMessage);
				}
				return;
			}
		}
	});

	const deleteEvaluation = payload => {
		mutate(payload);
	};

	return (
		<Portal>
			<Modal
				size={"500px"}
				padding={"0px"}
				onClose={onClose}
				loading={isLoading}
				borderRadius={"12px"}
				border={"1px solid #CBD5E1"}
				backgroundColor={"rgba(0,0,0,0.25)"}
				boxShadow={
					"0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
				}
				customCloseBtnRender={onClose => (
					<div className={styles.closeButton} onClick={onClose}>
						<CloseIcon />
					</div>
				)}
			>
				<div className={styles.container}>
					<div className={styles.header}>
						<h2 className={styles.title}>Delete evaluation</h2>
					</div>
					<div className={styles.descriptionContainer}>
						<NotificationIcon className={styles.notificationIcon} />
						<div className={styles.description}>
							Are you sure you want to delete {name} Evaluation ?
						</div>
					</div>
					<div className={styles.buttons}>
						<Button
							text="Cancel"
							onClick={onClose}
							variant="outlined"
							rootClassName={styles.cancel}
						/>
						<Button
							text="Delete"
							className={styles.deleteButton}
							onClick={() => deleteEvaluation(payload)}
							icon={<TrashIcon className={styles.trashIcon} />}
						/>
					</div>
				</div>
			</Modal>
		</Portal>
	);
};

export default DeleteEvaluationModal;
