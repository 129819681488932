import React from "react";
import { Box } from "rebass";
import _get from "lodash/get";
import { FREELANCER, FUNCTIONS, SKILLS } from "config";
import ProfileContainerClientView from "./TopSection/ProfileContainerClientView";
import Tabs from "./Tabs/Tabs";
import Preferences from "./Tabs/Preferences";
import Languages from "./Tabs/Languages";
import Functions from "modules/job/components/jobprofile/Functions/Functions";
import FreelancerModals from "./modals/FreelancerModals";
import Skills from "./Tabs/FreelancerSkills";
import FreelancerCV from "./Tabs/FreelancerCV";
import HistoryBid from "modules/bids/components/bidview/History";
const FreelancerProfileClientView = ({
	bid_id,
	_ownCV,
	headline,
	functions,
	activeTab,
	isClient,
	showBack,
	viewProfile,
	cv_docs,
	showModal,
	status,
	initialSkills,
	id,
	history
}) => {
	const cv = _get(cv_docs, "original_cv");
	const cvDoc = _get(cv_docs, "cv_doc");
	return (
		<>
			<>
				<ProfileContainerClientView
					showBack={showBack}
					bid_id={bid_id}
					ownCV={_ownCV}
					viewProfile={viewProfile}
					userId={headline && headline._id}
					isFreelancer={true}
				/>
				<Box
					mr={15}
					ml={15}
					pt={"6px"}
					sx={{ backgroundColor: "#fff", overflow: "hidden" }}
				>
					<div className="col-md-12 tabs-container">
						<Tabs
							functions={functions}
							ownCV={_ownCV}
							profileCompanyType={FREELANCER}
						/>
					</div>
				</Box>
				<div
					className={` ${
						_ownCV ? "container" : "client-layout-padding container"
					}`}
				>
					<div className="row">
						<div className="col-md-12 update">
							{activeTab === 0 && (
								<div
									className="tab-content"
									style={{
										backgroundColor: "#fff",
										border: "1px solid #e1e4e7",
										borderRadius: "4px",
										boxShadow: "0px 2px 1px 0 rgba(197,199,206,0.25)"
									}}
								>
									<FreelancerCV
										cv={cv}
										id={id}
										canUpdateCV={_ownCV}
										cvDoc={cvDoc}
									/>
								</div>
							)}
							{activeTab === 1 && (
								<div className="tab-content">
									<Functions
										functions={functions}
										showModal={() => showModal(FUNCTIONS)}
										canUpdateFunctions={_ownCV}
										isFreelancer
									/>
									<Skills
										skills={initialSkills}
										functionsLength={_get(functions, "length", 0)}
										showModal={() => showModal(SKILLS)}
										ownCV={_ownCV}
									/>
									<Languages ownCV={_ownCV} isClient={isClient} isFreelancer />
								</div>
							)}
							{activeTab === 2 && (
								<div className="tab-content">
									<Preferences ownCV={_ownCV} />
								</div>
							)}
							{activeTab === 3 && (
								<div className="tab-content">
									<HistoryBid history={history} />
								</div>
							)}
						</div>
					</div>
				</div>
				<FreelancerModals
					bid_id={bid_id}
					newUserId={headline && headline._id}
					userStatus={status}
					functions={functions}
					profileSector={_get(headline, "sector.parent_sector")}
				/>
			</>
		</>
	);
};

export default FreelancerProfileClientView;
