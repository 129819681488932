import ViewSwitcher from "./ViewSwitcher";
import React from "react";
import BodyClassName from "react-body-classname";
// import { Redirect } from "react-router";

export const getSearchCandidatePool = () => {
	return `/vacancies/search`;
};

export const getSearchCandidatePoolPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const SearchCandidatePool = () => {
	return (
		<BodyClassName className="jobs gray-bg my-candidate-page">
			<ViewSwitcher />
			{/* <Redirect to={"/candidates/search"} /> */}
		</BodyClassName>
	);
};

export default SearchCandidatePool;
