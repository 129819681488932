//todo
import React, { useEffect, useState } from "react";
import styles from "./my-company.module.scss";
import Breadcumb from "modules/company/components/BreadcumbSettingPages";
import GeneralInformations from "./components/GeneralInformations";
import InvoicingDetails from "./components/InvoicingDetails";
import Offices from "./components/Offices";
import { useMyCurrentSite } from "./api/useMyCurrentCompany";
import useUpdateCompanyInfo from "./api/useUpdateCompanyInfo";
import DeleteModal from "modules/MyCompany/components/DeleteModal/DeleteModal";
import MultiSitesHeader from "./MultiSitesHeader";
import HeaderButton from "../SearchCandidate/HeaderButton";
import { ReactComponent as BinIcon } from "../../static/icons/bin.svg";
import { useToggle } from "react-use";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "../user/selectors/selectors";
import AddSiteDrawer from "./components/AddSiteDrawer/AddSiteDrawer";
import GeneralInformationsEmptyState from "./components/EmptyStates/GeneralInformationsEmptyState";
import InvoicingDetailsEmptyState from "./components/EmptyStates/InvoicingDetailsEmptyState";
import OfficesEmptyState from "./components/EmptyStates/OfficesEmptyState";
import useGetSites from "modules/MyCompany/api/useGetSites";
import MultiSitesHeaderEmptyState from "./components/EmptyStates/MultiSitesHeaderEmptyState";
import { getUrlHash, searchParser } from "config/helpers";
import { ADMIN } from "config";

export function getMyCompanyRoute() {
	return "/settings/my-company";
}

export function getMyCompanyPermission() {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
}
const MyCompany = () => {
	const [deleteSite, setDeleteSite] = useToggle(false);
	const [displayDrawer, setDisplayDrawer] = useToggle(false);
	const [currentSite, setCurrentSite] = useState({});
	const [activeData, setActiveData] = useState({});
	const user = useSelector(onlineUserSelector);
	const features = get(user, "features", []);
	const enabled = !!currentSite?._id;
	const hasMultiSite = features.includes("feature_multisite");
	const showDelete = currentSite?.is_default === false;
	const [activeTab, setActiveTab] = useState(null);
	const [defaultSite, setDefaultSite] = useState(null);
	const [
		updateCompanyInfo,
		{ isLoading: updateCompanyLoading }
	] = useUpdateCompanyInfo();
	const { data, isLoading: loadingSites } = useGetSites({
		enabled: !enabled
	});

	const { data: dataSite, isLoading } = useMyCurrentSite(
		{
			tag: "app_source",
			site_id: get(currentSite, "_id")
		},
		{ enabled: enabled }
	);

	useEffect(() => {
		let actifSite = getUrlHash();
		const { siteId, isDefault } = searchParser();

		if (actifSite) {
			if (data) {
				const { is_default } = data.site_list.find(
					({ _id }) => _id === actifSite
				);

				setCurrentSite({
					_id: actifSite,
					is_default
				});
			}
		} else if (siteId) {
			setCurrentSite({
				_id: siteId,
				is_default: isDefault
			});
			setDefaultSite(siteId);
		} else {
			const defaultSite = get(data, "site_list", []).find(item => {
				return item.is_default === true;
			});

			setCurrentSite({
				_id: activeTab ? activeTab : get(defaultSite, "_id"),
				is_default: true
			});
		}
	}, [data, activeTab]);

	useEffect(() => {
		if (enabled && dataSite) {
			setActiveData(dataSite);
		}
	}, [dataSite, currentSite, setCurrentSite]);
	const onDeleteSiteSuccess = () => {
		history.pushState(null, null, `#${data.site_list[0]._id}`);
	};
	const handleCurrentSiteChange = ({ _id, is_default }) => {
		setCurrentSite({
			_id,
			is_default
		});
		history.pushState(null, null, `#${_id}`);
	};

	const IsAdmin = get(user, "active_role") === ADMIN;
	return (
		<div className={styles.root}>
			<Breadcumb
				parentItem="Settings"
				currentItem="My Company"
				pathCurrentItem="/settings/company"
			/>
			<h1 className={styles.titlePage}>My company</h1>
			{loadingSites && hasMultiSite ? (
				<MultiSitesHeaderEmptyState />
			) : (
				get(data, "site_list", []).length > 0 &&
				hasMultiSite && (
					<MultiSitesHeader
						addSite={displayDrawer}
						HandleShowAddSite={setDisplayDrawer}
						handleCurrentSiteChange={handleCurrentSiteChange}
						tabs={get(data, "site_list")}
						defaultSite={defaultSite}
						IsAdmin={IsAdmin}
						currentSite={currentSite?._id}
					/>
				)
			)}
			<div className={styles.containerMyCompany}>
				<div>
					{isLoading || loadingSites ? (
						<GeneralInformationsEmptyState />
					) : (
						<GeneralInformations
							IsAdmin={IsAdmin}
							setActiveTab={setActiveTab}
							currentSite={currentSite}
							hasMultiSite={hasMultiSite}
							company={activeData}
							updateCompanyInfo={updateCompanyInfo}
							isLoadingUpdate={updateCompanyLoading}
						/>
					)}
				</div>
				<div>
					{isLoading || loadingSites ? (
						<InvoicingDetailsEmptyState />
					) : (
						<InvoicingDetails
							IsAdmin={IsAdmin}
							setActiveTab={setActiveTab}
							currentSite={currentSite}
							hasMultiSite={hasMultiSite}
							company={activeData}
							updateCompanyInfo={updateCompanyInfo}
							isLoadingUpdate={updateCompanyLoading}
						/>
					)}
					{isLoading || loadingSites ? (
						<OfficesEmptyState />
					) : (
						<Offices
							IsAdmin={IsAdmin}
							setActiveTab={setActiveTab}
							currentSite={currentSite}
							hasMultiSite={hasMultiSite}
							company={activeData}
							updateCompanyInfo={updateCompanyInfo}
							isLoadingUpdate={updateCompanyLoading}
						/>
					)}
				</div>
			</div>{" "}
			{showDelete && IsAdmin && (
				<div className={styles.deleteBtnContainer}>
					<HeaderButton
						icon={<BinIcon />}
						text="Delete site"
						className="btnDelete"
						variant="big"
						onClick={() => setDeleteSite()}
					/>
				</div>
			)}
			{displayDrawer && (
				<AddSiteDrawer
					title={"Add site"}
					onClose={() => setDisplayDrawer(false)}
					onSuccess={() => setCurrentSite({})}
					setActiveTab={setActiveTab}
				/>
			)}
			{deleteSite && (
				<DeleteModal
					setOpenModal={setDeleteSite}
					setDisplayDrawer={() => setDisplayDrawer(true)}
					id={get(currentSite, "_id")}
					dataSite={dataSite}
					onDeleteSiteSuccess={onDeleteSiteSuccess}
				/>
			)}
		</div>
	);
};

export default MyCompany;
