import React, { useState, memo } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import { Element } from "react-scroll";
import { DATE_FORMAT, TIMEZONE } from "config";
import { getTrueType } from "config/helpers";

import "./theme.css";

function DatePickerField({
	label,
	placeholder,
	icon,
	input,
	classes,
	disabled,
	dateFormat,
	minDate,
	maxDate,
	meta: { touched, error },
	initialVisibleMonth,
	name: propsName
}) {
	const [focused, setFocused] = useState(false);

	const isOutsideRange = day => {
		return (
			(minDate && day.isBefore(minDate)) || (maxDate && day.isAfter(maxDate))
		);
	};

	const changeInput = currentDate => {
		var onChange = input && input.onChange;
		if (currentDate && window.moment.isMoment(currentDate) && onChange) {
			return onChange(window.moment(currentDate.clone()).tz(TIMEZONE));
		}
	};

	const has_error = touched && error ? "has-error has-feedback" : "";
	const name = input ? input.name : propsName;
	let value = input.value;
	if (value && !window.moment.isMoment(value)) {
		value =
			getTrueType(value) === "number"
				? window.moment.unix(value)
				: window.moment(value, dateFormat);
	}

	return (
		<Element
			className={`form-group ${classes} ${has_error}`}
			name={name}
			scrollto="scroll-to-error"
		>
			{label && <label htmlFor={name}>{label}</label>}
			<div className="input-box">
				{icon && <i className={icon} />}
				<SingleDatePicker
					id={name}
					name={name}
					date={value}
					displayFormat={dateFormat}
					focused={focused}
					disabled={disabled}
					numberOfMonths={1}
					onFocusChange={({ focused }) => setFocused(focused)}
					isOutsideRange={isOutsideRange}
					onDateChange={changeInput}
					placeholder={placeholder}
					initialVisibleMonth={initialVisibleMonth}
				/>
			</div>
			<div className="help-block inline-error">{touched && error}</div>
		</Element>
	);
}

DatePickerField.defaultProps = {
	placeholder: DATE_FORMAT,
	dateFormat: DATE_FORMAT,
	inputClassName: "",
	readOnly: true,
	viewMode: "days",
	meta: {}
};

export default memo(DatePickerField);
