import { goBack } from "config/helpers";
import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 12px 14px;
	background-color: white;
	color: #2a3644;
	line-height: 1;
	border-radius: 8px;
	border: 1px solid #eceff3;
	font-size: 14px;
	font-weight: 500;
	font-family: "Roboto";
	&:hover {
		background: #f5f5f5;
	}
`;

export default function BackButton({ label = "Back", onClick }) {
	const onClickBtn = () => {
		if (onClick) {
			onClick?.();
		} else {
			goBack();
		}
	};

	return (
		<StyledButton onClick={onClickBtn} type="button">
			<svg
				width="16"
				height="12"
				viewBox="0 0 16 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14.6666 6L1.33329 6M1.33329 6L6.33329 11M1.33329 6L6.33329 1"
					stroke="currentColor"
					strokeWidth="1.7"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
			<span>{label}</span>
		</StyledButton>
	);
}
