import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { onlineUserSelector } from "modules/user/selectors/selectors";
import { fetchMyJobList } from "../../redux/actions";
import { jobListSelector } from "../../redux/selectors";
import JobList from "./JobList";
import { toggleHelpModal } from "modules/app/actions/ListsActions";

export const getCandidateJobListRoute = () => {
	return "/my-jobs";
};

export const getCandidateJobListPermission = () => {
	return {
		type: [
			{
				name: "permanent",
				roles: ["admin"]
			}
		]
	};
};

function CandidateJobList({
	route,
	fetchMyJobList,
	jobList,
	toggleHelpModal,
	user: { account_status }
}) {
	return (
		<JobList
			getJobList={fetchMyJobList}
			route={route}
			data={jobList}
			accountStatus={account_status}
			toggleHelpModal={toggleHelpModal}
		/>
	);
}

const mapStateToProps = state => ({
	jobList: jobListSelector(state),
	user: onlineUserSelector(state)
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ fetchMyJobList, toggleHelpModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CandidateJobList);
