import { GET_AUTHENTICATION_DETAILS } from "config/api-endpoints";
import { renderError } from "config/helpers";
import { client } from "lib/api-client";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import fakeData from "modules/user-onboarding/mockData/email-sync-details.json";

const { useQuery } = require("react-query");

function getAuthenticationDetails() {
	return client(GET_AUTHENTICATION_DETAILS, {
		body: {
			filter: "authentication_details"
		}
	});
}

export const MESSAGING_TOOL_AUTHENTICATION_DETAILS =
	"MESSAGING_TOOL_AUTHENTICATION_DETAILS";

function useGetAuthenticationDetails() {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery(MESSAGING_TOOL_AUTHENTICATION_DETAILS, () => {
			return Promise.resolve(fakeData);
		});
	}

	return useQuery(
		MESSAGING_TOOL_AUTHENTICATION_DETAILS,
		getAuthenticationDetails,
		{
			onError: renderError,
			refetchOnWindowFocus: false,
			retry: 1
		}
	);
}

export default useGetAuthenticationDetails;
