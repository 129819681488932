import React from "react";
import SearchModal from "common/CustomizableModel/CustomizableModel";
import styles from "./styles.module.scss";
import { ReactComponent as AlertTriangle } from "static/icons/alert-triangle.svg";
import { ReactComponent as AlertTriangleYellow } from "static/icons/alert-triangle-yellow.svg";

import cx from "classnames";
const AlertModal = ({
	onClose,
	onClick,
	title,
	confirmButtonText,
	textCancelButton,
	body,
	dontShare
}) => {
	return (
		<SearchModal
			title={title}
			actionButton={
				<>
					<button className={styles["cancel-btn"]} onClick={onClose}>
						{textCancelButton}
					</button>
					<button
						onClick={onClick}
						className={cx(styles.confirmButton, {
							[styles.dontShare]: dontShare
						})}
					>
						{confirmButtonText}
					</button>
				</>
			}
			hasCloseIcon
			displayCloseButton={false}
			onClose={onClose}
			textCancelButton={textCancelButton}
			classNameFooter={styles.footer}
			classNameHeader={styles.header}
			maxwidth="43.5rem"
			padding="0rem"
			boxShadow="0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)"
			borderRadius="12px"
		>
			<div className={styles.content}>
				<span
					className={cx(styles.alertTriangle, {
						[styles.dontShare]: dontShare
					})}
				>
					{dontShare ? <AlertTriangleYellow /> : <AlertTriangle />}
				</span>
				<p className={styles.contentText}>{body}</p>
			</div>
		</SearchModal>
	);
};

export default AlertModal;
