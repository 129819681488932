import React from "react";
import _get from "lodash/get";
import RcDropDown from "rc-dropdown";
import Menu, { Item } from "rc-menu";
import { OutlineButton, Button } from "common/styled/buttons";
import {
	CANCELLED,
	REJECTED,
	HIRED,
	SHORTLISTED,
	DECLINED,
	SENT,
	ACCEPTED
} from "config";

const PokeActions = ({
	pokeStage = "shortlist",
	pokeStatus = "pending",
	interviewStatus,
	offerStatus,
	canAccept,
	actions
}) => {
	const cancelOffer = {
		name: "Cancel offer",
		action: actions.cancelOffer
	};

	const proposeOffer = {
		name: "Propose offer",
		action: actions.proposeAnotherOffer
	};

	const proposeAnotherOffer = {
		name: "Propose another offer",
		action: actions.proposeAnotherOffer
	};

	const addNoteOption = {
		name: "Add note",
		action: actions.addNote,
		icon: "icon-add-note"
	};

	const proposeInterviewOption = {
		name: "Propose interview",
		action: actions.proposeInterview
	};

	const cancelPokeOption = {
		name:
			pokeStatus === SHORTLISTED ? "Disqualify candidate" : "Reject candidate",
		action: actions.cancelPoke
	};
	const rejectApplicationOption = {
		name: "Reject application",
		action: actions.cancelPoke
	};

	const proposeAnotherInterviewAction = {
		name: "Propose another interview",
		action: actions.proposeInterview
	};

	const cancelInterviewAction = {
		name: "Cancel interview",
		action: actions.cancelInterview
	};
	const acceptInterview = {
		name: "Accept interview",
		action: actions.acceptInterview
	};
	const requalifyAction = {
		name: "Requalify candidate",
		action: actions.requalify
	};

	const interviewActionsOnOffer = [
		!Boolean(interviewStatus) && proposeInterviewOption,
		Boolean(interviewStatus) &&
			interviewStatus !== REJECTED &&
			pokeStatus !== CANCELLED &&
			proposeAnotherInterviewAction,
		(interviewStatus === DECLINED ||
			interviewStatus === SENT ||
			interviewStatus === ACCEPTED) &&
			cancelInterviewAction,
		interviewStatus === DECLINED && canAccept && acceptInterview
	];

	const offerCommonActions = [
		proposeAnotherOffer,
		cancelOffer,
		addNoteOption,
		rejectApplicationOption,
		...interviewActionsOnOffer
	];

	const shortlistCommonActions = [
		proposeInterviewOption,
		proposeOffer,
		addNoteOption,
		cancelPokeOption
	];

	const interviewCommonActions = [
		proposeAnotherInterviewAction,
		proposeOffer,
		addNoteOption,
		rejectApplicationOption
	];

	const possibleActions = {
		offer: {
			sent: offerCommonActions,
			draft: offerCommonActions,
			refused: [
				proposeAnotherOffer,
				addNoteOption,
				rejectApplicationOption,
				...interviewActionsOnOffer
			],
			accepted: offerCommonActions,
			cancelled: [
				proposeAnotherOffer,
				addNoteOption,
				rejectApplicationOption,
				...interviewActionsOnOffer
			]
		},
		apply: {
			applied: [
				proposeInterviewOption,
				proposeOffer,
				addNoteOption,
				{
					name: "Reject application",
					action: actions.rejectApplication
				}
			]
		},
		shortlist: {
			applied: shortlistCommonActions,
			shortlisted: shortlistCommonActions,
			sent: shortlistCommonActions,
			cancelled: [addNoteOption, requalifyAction]
		},
		interview: {
			sent: [
				proposeAnotherInterviewAction,
				proposeOffer,
				addNoteOption,
				cancelInterviewAction,
				rejectApplicationOption
			],
			accepted: [
				proposeAnotherInterviewAction,
				proposeOffer,
				addNoteOption,
				cancelInterviewAction,
				rejectApplicationOption
			],
			declined: [
				canAccept && acceptInterview,
				proposeAnotherInterviewAction,
				proposeOffer,
				addNoteOption,
				cancelInterviewAction,
				rejectApplicationOption
			],
			expired: interviewCommonActions,
			done: interviewCommonActions,
			rejected: [addNoteOption],
			cancelled: [
				pokeStatus !== CANCELLED && proposeAnotherInterviewAction,
				proposeOffer,
				addNoteOption,
				pokeStatus !== CANCELLED && cancelPokeOption
			]
		}
	};

	let stageActions =
		pokeStatus === REJECTED || pokeStatus === CANCELLED
			? [addNoteOption, requalifyAction]
			: pokeStatus === HIRED
			? [addNoteOption, rejectApplicationOption]
			: _get(
					possibleActions,
					`${pokeStage}.${offerStatus ?? interviewStatus ?? pokeStatus}`,
					[]
			  ).filter(Boolean);

	if (stageActions.length === 0) {
		stageActions.push(addNoteOption);
	}

	const showMore = stageActions.length > 3;
	const visibleActions = showMore ? stageActions.slice(0, 1) : stageActions;
	const moreActions = stageActions.slice(1, stageActions.length);

	return (
		<>
			{visibleActions.map((action, index) => {
				const ActionButton = !Boolean(index) ? Button : OutlineButton;
				return (
					<ActionButton
						key={action.name}
						small
						mb={2}
						onClick={() => action.action()}
					>
						{action.icon && (
							<i className={action.icon} style={{ marginRight: 5 }} />
						)}
						{action.name}
					</ActionButton>
				);
			})}
			{showMore && (
				<RcDropDown
					trigger={["click"]}
					overlay={
						<Menu className="sidebar-actions">
							{moreActions.map(action => (
								<Item key={action.name} onClick={() => action.action()}>
									<span>{action.name}</span>
								</Item>
							))}
						</Menu>
					}
					overlayClassName="ignore-click sidebar-actions-container"
				>
					<OutlineButton
						small
						block
						style={{ marginBottom: "10px" }}
						className="poke-more-options"
					>
						More options
					</OutlineButton>
				</RcDropDown>
			)}
		</>
	);
};

export default PokeActions;
