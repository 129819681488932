import React, { useState, memo } from "react";
import FilterPill from "common/FilterPill/FilterPill.js";
import styles from "./styles.module.scss";
import get from "lodash/get";
import { ReactComponent as ClearIcon } from "static/icons/clear_icon.svg";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { isEmpty } from "common/FilterDrawer/render_utils";
import HeaderButton from "../HeaderButton/HeaderButton";
import ClearModal from "../ClearModal/ClearModal";
import { TYPES } from "modules/timesheetsModule/utils/constants";
import moment from "moment";
import { OPERATORS } from "common/FilterDrawer/utils";
import capitalize from "lodash/capitalize";

const TimesheetsFiltersRecap = ({ filters, module_id }) => {
	const [showClearModal, setShowClearModal] = useState(false);
	const { handleFilterDelete, setShowDrawer, setSelectedFilter } = useFilters();
	const onFilterDelete = id => {
		handleFilterDelete(id);
	};

	const formatFilterByType = filter => {
		const value = (() => {
			if (filter.operator === OPERATORS.isEmpty.value) {
				return filter.value ? "Yes" : "No";
			}

			switch (filter.type) {
				case TYPES.multipleSelect:
					return filter.value
						.map(group => {
							return group.children
								.map(item =>
									filter.name === "currency" ? `(${item.label})` : item.label
								)
								.join(" - ");
						})
						.join(" - ");
				case TYPES.amount:
					return Number(filter.value) === 0 ? "0" : Number(filter.value) / 100;
				case TYPES.date:
					if (filter.value.start) {
						return `${moment(filter.value.start, "YYYY-MM-DD").format(
							"DD/MM/YYYY"
						)} and ${moment(filter.value.end, "YYYY-MM-DD").format(
							"DD/MM/YYYY"
						)}`;
					}
					return moment(filter.value, "YYYY-MM-DD").format("DD/MM/YYYY");
				default:
					return capitalize(filter.value);
			}
		})();
		return { ...filter, value };
	};

	return (
		<>
			<div className={styles.filters_bar}>
				<div
					className={styles.scrollContainer}
					onClick={() => setShowDrawer(true)}
				>
					<OverlayScrollbarsComponent style={{ maxWidth: "100%" }}>
						{filters?.map(filter => {
							if (!isEmpty(filter.value)) {
								return (
									<div key={filter.id} className={styles.pill_container}>
										<FilterPill
											module_id={module_id}
											filter={formatFilterByType(filter)}
											removable
											onRemove={onFilterDelete}
											onClick={() => {
												setSelectedFilter(get(filter, "id", ""));
												setShowDrawer(true);
											}}
											isValueFormatted
										/>
									</div>
								);
							}
						})}
					</OverlayScrollbarsComponent>
				</div>

				<div className={styles.btn_container}>
					<div className={styles.rightContainer}>
						<HeaderButton
							icon={<ClearIcon />}
							text={"Clear"}
							className="buttonClear"
							onClick={() => setShowClearModal(true)}
						/>
					</div>
				</div>
			</div>

			{showClearModal && (
				<ClearModal onClose={() => setShowClearModal(false)} />
			)}
		</>
	);
};

export default memo(TimesheetsFiltersRecap);
