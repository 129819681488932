import React from "react";
const PinRightIcon = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.7405 10.911L14.5117 14.6822M8.52869 4.92796L9.56928 5.96855C9.65417 6.05343 9.69661 6.09587 9.74496 6.1296C9.78788 6.15953 9.83418 6.18431 9.88289 6.20341C9.93777 6.22494 9.99663 6.23671 10.1143 6.26025L12.5573 6.74885C13.1922 6.87582 13.5096 6.93931 13.6581 7.10668C13.7875 7.25249 13.8466 7.44761 13.8198 7.6407C13.7891 7.86233 13.5602 8.09124 13.1024 8.54905L8.37855 13.2729C7.92074 13.7307 7.69183 13.9596 7.47019 13.9903C7.27711 14.0171 7.08198 13.958 6.93618 13.8286C6.76881 13.6801 6.70532 13.3627 6.57835 12.7278L6.08975 10.2848C6.06621 10.1671 6.05444 10.1083 6.03291 10.0534C6.0138 10.0047 5.98903 9.95838 5.95909 9.91546C5.92537 9.86711 5.88293 9.82467 5.79805 9.73979L4.75746 8.69919C4.70319 8.64492 4.67605 8.61779 4.64622 8.5941C4.61972 8.57305 4.59166 8.55405 4.56228 8.53727C4.52921 8.51837 4.49393 8.50325 4.42339 8.47302L2.76048 7.76035C2.27535 7.55243 2.03278 7.44848 1.9226 7.28049C1.82624 7.13358 1.79177 6.95458 1.82668 6.7824C1.86659 6.5855 2.0532 6.39889 2.42642 6.02568L5.85518 2.59692C6.22839 2.2237 6.415 2.03709 6.6119 1.99718C6.78408 1.96227 6.96308 1.99675 7.10998 2.0931C7.27797 2.20329 7.38193 2.44585 7.58984 2.93099L8.30252 4.59389C8.33275 4.66444 8.34787 4.69971 8.36676 4.73278C8.38355 4.76217 8.40255 4.79023 8.42359 4.81672C8.44729 4.84655 8.47442 4.87369 8.52869 4.92796Z"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default PinRightIcon;
