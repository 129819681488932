import React from "react";
import _get from "lodash/get";
import ListItem from "common/ListItem";
import { isHTML } from "config/helpers";
import Description from "common/Description";
import styles from "./job-description.module.scss";
import JobFunctions from "common/JobFunctions";
import VideoCard from "modules/candidate/components/jobviewOnline/VideoCard";
import {
	SKILLS,
	VACANCY,
	LANGUAGES,
	MAX_SKILL_WEIGHT,
	MAX_LANGUAGE_WEIGHT
} from "config";
import LexicalContentView from "common/LexicalContentView";

export default function JobDescription({ data }) {
	const jobDescription = data?.description;
	const profileDescription = data?.profile_description;

	return (
		<>
			{/* featured video */}
			{data?.featured_video && (
				<VideoCard
					url={data?.featured_video}
					className={styles.featuredVideo}
				/>
			)}

			{/* job description */}
			<div className={styles.jobDescriptionTitle}>About the Request</div>
			<div className={styles.jobDescription}>
				{isHTML(jobDescription) ? (
					<LexicalContentView description={jobDescription} />
				) : (
					<Description
						description={jobDescription}
						parseMarkdown={true}
						max={500}
					/>
				)}
			</div>

			{/* profile description */}
			{profileDescription && (
				<>
					<div className={styles.profileDescriptionTitle}>
						About the Request
					</div>
					<div className={styles.profileDescription}>
						<LexicalContentView description={profileDescription} />
					</div>
				</>
			)}

			{/* featured image */}
			{data?.featured_image && (
				<div className={styles.featuredImage}>
					<img src={data.featured_image} alt="Featured image" />
				</div>
			)}

			{/* job more Info */}
			<div className={styles.jobMoreInfo}>
				<JobFunctions
					className="jobFunctions"
					functions={[
						{
							_id: "1",
							name: _get(data, "function"),
							seniority: _get(data, "seniority"),
							is_main: true,
							sector: {
								name: _get(data, "subcategory"),
								parent_sector: {
									name: _get(data, "category")
								}
							}
						}
					]}
				/>
				<ListItem
					hideTooltip
					type={SKILLS}
					isVacancy={true}
					title={"Skills"}
					jobType={VACANCY}
					className="listItem"
					scoreMax={MAX_SKILL_WEIGHT}
					list={_get(data, "skills", [])}
					displayCategory={_get(data, "category")}
				/>
				<ListItem
					hideTooltip
					type={LANGUAGES}
					title={"Languages"}
					className="listItem"
					scoreMax={MAX_LANGUAGE_WEIGHT}
					list={_get(data, "languages", [])}
				/>
			</div>
		</>
	);
}
