import React from "react";
import BodyClassName from "react-body-classname";
import cx from "classnames";
import useGetCurrentCompany, {
	getCurrentCompanyQueryKey
} from "../../api/useGetCompany";
import Dashboard from "../../../../common/dashboard/Dashboard";
import Intro from "../../../../common/dashboard/Intro";
import s from "./company.module.scss";
import { COMPANY } from "../../../../config";
import ProfileImage from "../../../company/components/avatar/profileAvatar";
import { formatDate } from "../../../../config/helpers";
import Description from "./Description";
import Contacts from "./Contacts";
import RestFields from "./RestFields";
import { useQueryCache } from "react-query";
import BillingDetails from "./BillingDetails";

export function getSupplierCompanyRoute() {
	return "/settings/legal-entity";
}

export function getSupplierCompanyPermission() {
	return {
		type: [
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
}

function SupplierCompany() {
	const { data = {}, isLoading, isFetching } = useGetCurrentCompany();
	const queryCache = useQueryCache();

	const invalidateCompany = () =>
		queryCache.invalidateQueries(getCurrentCompanyQueryKey);

	return (
		<BodyClassName className="my-company my-profile">
			<>
				<div className="dashboard-box">
					<Dashboard />
					<Intro
						icon="fa fa-file-text-o"
						title={"Company/Legal Entity Details"}
					>
						<p>
							These details are needed to generate the contracts for missions
							arranged via wiggli as well as pay the invoices related to the
							work of your consultants. Should the VAT number, or official name
							of the company change, please{" "}
							<a href={"mailto:support@wiggli.io"}>contact our support team.</a>
						</p>
					</Intro>
				</div>
				<div className={cx("container", s.container)}>
					<div className={s.companyInfo}>
						<div className={s.avatar}>
							<ProfileImage
								user={{ ...data, id: data._id }}
								type={COMPANY}
								onSuccess={invalidateCompany}
							/>
						</div>
						<div className={cx(s.box, s.accountInfoContainer)}>
							<div className={s.companyName}>
								<h4>Official name & legal form</h4>
								<strong>{data?.name}</strong>
							</div>
							<div className={s.accountInfo}>
								<ul>
									<li>
										<h4>Account type</h4>
										<span className={s.accountType}>{data?.type}</span>
									</li>
									<li>
										<h4>Account created on</h4>
										<span>{formatDate(data?.registration_date ?? 0)}</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className={s.box}>
						<Description
							isFetching={isFetching}
							isLoading={isLoading}
							data={data}
						/>
					</div>
					<div className={s.box}>
						<Contacts data={data} />
					</div>
					<div className={s.box}>
						<RestFields data={data} />
					</div>
					<div className={s.box}>
						<BillingDetails data={data} />
					</div>
				</div>
			</>
		</BodyClassName>
	);
}

export default SupplierCompany;
