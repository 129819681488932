import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { DEPARTMENT } from "config";

const getFilterSettings = mentionedUsers => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "internal_user_note",
			excluded_user_ids: mentionedUsers
		}
	});
};

const useGetMentions = (mentionedUsers, options = {}) =>
	useQuery(
		["getMentions", mentionedUsers],
		async () => {
			const data = await getFilterSettings(mentionedUsers);

			const { list_users } = data;
			let users = [DEPARTMENT, ...list_users];

			return {
				...data,
				list_users: users
			};
		},
		{
			refetchOnWindowFocus: false,
			...options
		}
	);

export default useGetMentions;
