import React from "react";
import styles from "./create-request-head.module.scss";
import { IconButton } from "common/IconButton";
import { ReactComponent as Icon } from "static/icons/back-button-icon.svg";
import { goBack } from "config/helpers";

const CreateRequestHead = () => {
	const onClick = () => goBack();

	return (
		<>
			<div className={styles.container}>
				<IconButton onClick={onClick}>
					<Icon className={styles.icon} height={24} width={24} />
				</IconButton>
				<div className={styles.text}>Create request</div>
			</div>
			<div className={styles.lineContainer}>
				<div className={styles.line} />
			</div>
		</>
	);
};

export default CreateRequestHead;
