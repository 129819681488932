import styled, { css } from "styled-components";

export const BoxWrapper = styled.div`
	padding: 25px;
	background: #f9fafb;
	border: 1px solid #e5e5e5;
	border-radius: 0px 5px 5px 5px;
	margin-bottom: 20px;
`;

export const BoxItem = styled.div`
	margin-top: 10px;
	min-height: 50px !important;
	font-size: 14px;
`;

export const Title = styled.div`
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #737373;
	margin-bottom: 4px;
	&.panel-title {
		font-size: 14px;
		line-height: 16px;
		color: #737373;
		margin-bottom: 0.25rem;
	}
`;

export const Desc = styled.div`
	height: 30px;
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 3px;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 30px;
	color: #262626;
`;

export const EditInput = styled.input`
	background: #ffffff;
	border: 1px solid #e5e5e5;
	border-radius: 8px;
	width: 100%;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #262626;
	padding: 13px;
	line-height: 16px;
	height: auto;

	&:focus {
	}
	${props =>
		props.hasError &&
		css`
			border-color: #f76d75;
			::placeholder {
				color: #f76d75;
			}
		`};
`;
