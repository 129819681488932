import React from "react";
import { VIDEO_CONFERENCE, SEARCH_ADDRESS, COMPANY_ADDRESS } from "config";
import { ReactComponent as CompanyAddressIcon } from "static/icons/company-address-icon.svg";
import { ReactComponent as RemoteAddressIcon } from "static/icons/remote-address-icon.svg";
import { ReactComponent as SearchAddressIcon } from "static/icons/search-address-icon.svg";
import CheckLocationBoxes from "./CheckLocationBoxes";
import { Headline, InterviewLocationContainer } from "../../styles";
import { REMOTE } from "config";

const InterviewLocation = ({
	companyAddress = "685 E Middlefield Rd, Mountain View, CA 94043, United States",
	changeLocationType,
	value,
	onChange,
	setAddress,
	address,
	addressError,
	setAddressError,
	addressTypo,
	label = "Interview location",
	offer
}) => {
	const interviewLocationTypes = [
		{
			type: "Company Address",
			content: companyAddress,
			icon: <CompanyAddressIcon />,
			locationType: COMPANY_ADDRESS
		},
		{
			type: "Another Address",
			content: "Add a different address for this interview",
			icon: <SearchAddressIcon />,
			locationType: SEARCH_ADDRESS
		},
		{
			type: "Online",
			content: "Video Conference",
			icon: <RemoteAddressIcon />,
			locationType: VIDEO_CONFERENCE
		}
	];
	const offerLocationTypes = [
		{
			type: "Company Address",
			content: companyAddress,
			icon: <CompanyAddressIcon />,
			locationType: COMPANY_ADDRESS
		},
		{
			type: "Another Address",
			content: "Add a different address for this job",
			icon: <SearchAddressIcon />,
			locationType: SEARCH_ADDRESS
		},
		{
			type: "Remote",
			content: "Offer the candidate to work from his current location",
			icon: <RemoteAddressIcon />,
			locationType: REMOTE
		}
	];
	const isAddress = () => {
		const requiredFields = ["street", "number", "city", "zip", "country"];
		return requiredFields.every(field => address[field]);
	};
	const data = Boolean(offer) ? offerLocationTypes : interviewLocationTypes;
	return (
		<InterviewLocationContainer>
			<Headline>{label}</Headline>
			<div className="check-location-boxes wrapper">
				{data.map(({ type, locationType, content, icon }) => (
					<CheckLocationBoxes
						type={type}
						content={content}
						key={locationType}
						onChange={() => {
							onChange(locationType);
							changeLocationType(locationType);
						}}
						addressType={value}
						locationType={locationType}
						setAddress={setAddress}
						address={address}
						addressTypeIcon={icon}
						setAddressError={setAddressError}
						addressError={addressError}
						addressTypo={addressTypo}
						closeLocationModal={() => {
							onChange(VIDEO_CONFERENCE);
							if (!isAddress()) {
								onChange(VIDEO_CONFERENCE);
								changeLocationType(VIDEO_CONFERENCE);
							}
						}}
					/>
				))}
			</div>
		</InterviewLocationContainer>
	);
};

export default InterviewLocation;
