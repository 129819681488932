import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";

function getListColleagues(key, { tag }) {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag
		}
	});
}

function useListColleagues(options, queryOptions = {}) {
	return useQuery(
		["@permanentProfile/listColleagues", options],
		getListColleagues,
		{
			refetchOnWindowFocus: false,
			retry: 0,
			staleTime: Infinity,
			...queryOptions
		}
	);
}

export default useListColleagues;
