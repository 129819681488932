import React, { Component } from "react";
import { OutlineButton } from "common/styled/buttons";
import Icon from "common/styled/icons";
import IdentityUploaderContainer from "./IdentityUploaderContainer";
import { uploaderMethodSelector } from "../selectors/createCompanySelectors";
import { connect } from "react-redux";
import WebCamModal from "./WebCamModal";
import { USE_WEBCAM } from "config";
import { onlineUserSelector } from "../../user/selectors/selectors";
import { switchMethodDispatch } from "../actions/identityActions";
import { CONSULTANCY } from "../../../config";

const helpText = type => {
	return type === CONSULTANCY ? (
		<div className="step-description">
			Please upload a front & back copy of your ID card or a copy of your
			passport.
			<br />
			This step is necessary to prevent identity theft and (if applicable) to
			make sure that you are legally authorized to represent the consultancy
			that you are registering.
		</div>
	) : (
		<div className="step-description">
			Please upload a front & back copy of your ID card or a copy of passport.
			<br />
			You can also use your webcam to instantaneously capture/upload photos of
			your ID card or passport. This step is necessary to prevent identity theft
			and (if applicable) to make sure that you are legally authorized to
			represent the legal entity identified through the VAT number initially
			provided.
		</div>
	);
};

class Identity extends Component {
	goBack = () => {
		this.props.dispatch(switchMethodDispatch()); // reset redux method;
		this.props.goBack();
	};

	render() {
		const {
			uploadMethod: { success, method, files },
			user_id,
			goBack,
			userType
		} = this.props;
		return (
			<div className="identity">
				<div className="container">
					<h3 className="text-center h3-title">
						{success ? "Thank you for uploading your ID" : "Personal Identity"}
					</h3>
					<div>
						{!success && helpText(userType)}

						<IdentityUploaderContainer />

						{method === USE_WEBCAM && (
							<WebCamModal userId={user_id} files={files} storeFiles={true} />
						)}

						{goBack && (
							<div className="skip-action">
								<OutlineButton large small has-icon onClick={this.goBack}>
									<Icon size={14} className="icon-arrow-left-new" />
									BACK
								</OutlineButton>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user_id: onlineUserSelector(state).id,
		uploadMethod: uploaderMethodSelector(state)
	};
};

export default connect(mapStateToProps)(Identity);
