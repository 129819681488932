//The hook role is to save details of step two in create request
import { useMutation } from "react-query";
import { CREATE_NEW_JOB_PRICING } from "config/api-endpoints";
import { client } from "lib/api-client";

const add = body => {
	return client(CREATE_NEW_JOB_PRICING, {
		body
	});
};

export const useAddRequestDetails = () => {
	const res = useMutation(add);

	return res;
};
