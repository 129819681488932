export const RESET_FILTER = "RESET_FILTER";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const FETCHED_JOBS = "FETCHED_JOBS";
export const UPDATE_SKILLS = "UPDATE_SKILLS";
export const UPDATE_LANGUAGES = "UPDATE_LANGUAGES";
export const REST_LANGUAGES = "REST_LANGUAGES";
export const REST_SKILLS = "REST_SKILLS";
export const TOGGLE_OFFLINE_POPUP = "TOGGLE_OFFLINE_POPUP";
// sectors
export const SET_SEARCH_SECTORS = "GET_SEARCH_SECTORS";
export const SET_SEARCH_SUBSECTORS = "SET_SEARCH_SUBSECTORS";
export const SET_SEARCH_FUNCTIONS = "SET_SEARCH_FUNCTIONS";
export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";
