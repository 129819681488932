import React from "react";
import styles from "./add-timesheet-entries-drawer-footer.module.scss";
import { ButtonGroup } from "common/ButtonGroup";

const AddTimesheetEntriesDrawerFooter = ({ onSave, onClose }) => {
	const buttons = [
		{
			variant: "contained",
			text: "Save",
			onClick: onSave,
			className: styles.saveButton
		},
		{
			variant: "outlined",
			text: "Cancel",
			onClick: onClose,
			className: styles.cancelButton
		}
	];

	return (
		<div className={styles.container}>
			<ButtonGroup gap="12px" buttons={buttons} />
		</div>
	);
};

export default AddTimesheetEntriesDrawerFooter;
