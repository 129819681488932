import React, { useRef } from "react";
import HeaderButton from "../HeaderButton/HeaderButton";

import { ReactComponent as ColumnIcon } from "static/icons/Icon.svg";
import { ReactComponent as FilterIcon } from "static/icons/Iconfilter.svg";

import { ReactComponent as Arrow } from "static/icons/ArrowIcon.svg";

import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { SearchInput } from "common/SearchInput";
import cx from "classnames";
import { validFiltersCount } from "common/FilterDrawer/render_utils";
import Tooltip from "common/Tippy";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { connect } from "react-redux";
import { useUserHasSearchByKeyword } from "hooks/useUserHasSearchByKeyword";
import { NOTES_FILTER_ID } from "../utils";
import style from "../../../../freelancers/components/FreelancersSecondaryHeader/freelancers-secondary-header.module.scss";
import NotesColumnsList from "../NotesColumnsList/NotesColumnsList";

const NotesSecondaryHeader = ({
	columns,
	onRecapClick,
	showRecap,
	displayType,
	onFilterClick,
	user
}) => {
	const { setQuery, getState } = useFilters();
	const { query, queryError } = getState(NOTES_FILTER_ID);

	const userHasSearchByKeyword = useUserHasSearchByKeyword({ user });

	const onSearch = e => {
		setQuery(e.target.value);
	};

	const ref = useRef();

	const { filters } = getState(NOTES_FILTER_ID);

	let searchInput = (
		<SearchInput
			disabled={!userHasSearchByKeyword}
			className={cx(style.search, {
				[style.error]: queryError
			})}
			value={query}
			onChange={onSearch}
			placeholder={"Search on candidate, vacancy & content."}
			variant="fixed_width"
			ref={ref}
		/>
	);
	return (
		<div className={style.filtersContainer}>
			<div className={style.firstContainer}>
				{userHasSearchByKeyword ? (
					searchInput
				) : (
					<Tooltip
						content="To enable this feature please contact support"
						theme="dark"
					>
						<div>{searchInput}</div>
					</Tooltip>
				)}
				<div className={style.filter_btns_container}>
					<HeaderButton
						icon={<FilterIcon />}
						text="Filter"
						className="btnAddFilter"
						onClick={onFilterClick}
					/>
					{validFiltersCount(filters) > 0 && (
						<button className={style.recap_btn} onClick={onRecapClick}>
							<span>{validFiltersCount(filters)}</span>
							<Arrow
								className={
									showRecap ? `${style.arrow} ${style.up}` : style.arrow
								}
							/>
						</button>
					)}
				</div>
			</div>
			<div className={style.secondContainer}>
				<HeaderButton
					icon={<ColumnIcon />}
					text="Columns"
					className="btnCriteria"
					secondIcon={<Arrow />}
					disabled={displayType === "cards"}
				>
					<NotesColumnsList columns={columns} />
				</HeaderButton>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps)(NotesSecondaryHeader);
