/* eslint-disable react/display-name */

import React, { useEffect } from "react";
import { useTour } from "@reactour/tour";
import styles from "../../components/TourContentComponent/tour-content-component.module.scss";
import TourContentComponent from "../../components/TourContentComponent";
import useOnboardingStore from "../../hooks/useOnboardingStore";
import Portal from "common/Portal";
import { HOW_TO_WORK_WITH_NOTES } from "../../../../config/onboarding";
import { browserHistory } from "react-router";
import isElementLoaded from "../../helpers/isElementLoaded";
import useNotesStore from "modules/notes/notes.store";

const selectorForStep = stepNumber =>
	`[data-onboarding-step=${HOW_TO_WORK_WITH_NOTES}-${stepNumber}]`;

const steps = [
	{
		selector: selectorForStep(0),
		title: "Profile quick view / notes",
		description: `From Quick View feature, you can easily preview a candidate's resume and access all notes related to them.`,
		stepInteraction: false,
		position: p => [p.right + 16, p.top],
		arrow: "left-top",
		action: element => {
			element.classList.add(styles.highlighted);
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	},
	{
		selector: selectorForStep(1),
		title: "Notes",
		description: `quickly preview a candidate's resume, view and edit your own notes, It's an efficient way to stay on top of all candidate information and collaborate with your team.`,
		stepInteraction: false,
		position: p => [p.left - 416, p.top + 300],
		arrow: "right-top"
	},
	{
		selector: selectorForStep(2),
		title: "See all notes",
		description: `access to all feedback and insights shared by your team about a particular candidate. `,
		stepInteraction: false,
		position: p => [p.left - 340, p.bottom + 16],
		arrow: "top-right",
		action: element => {
			element.classList.add(styles.highlighted);
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	},
	{
		selector: selectorForStep(3),
		title: "Advanced search",
		description: `search bar and advanced filter options make it a breeze to discover practical notes related to users or vacancies.`,
		stepInteraction: false,
		position: p => [p.left + 200, p.bottom + 16],
		arrow: "top-left",
		action: element => {
			element.classList.add(styles.highlighted);
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	},
	{
		selector: selectorForStep(4),
		title: "Add note",
		description: `Create and share notes in just one click, ensuring seamless communication of your decisions with your team mates.`,
		stepInteraction: false,
		position: p => [p.left - 340, p.bottom + 16],
		arrow: "top-right",
		action: element => {
			element.classList.add(styles.highlighted);
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	},
	{
		selector: selectorForStep(5),
		title: "Add note",
		description: `Create and share notes in an effective and efficient manner, giving you more control over your thoughts and opinions.`,
		stepInteraction: false,
		position: p => [p.left - 300, p.top - p.height - 34],
		arrow: "bottom-right"
	}
];

export const useWorkWithNotesTour = (props = {}) => {
	const store = useOnboardingStore();
	const tour = useTour();
	const { clearStore } = useNotesStore(({ clearStore }) => ({ clearStore }));

	useEffect(() => {
		if (store.workflowId === HOW_TO_WORK_WITH_NOTES) {
			if (!tour.isOpen && store.currentStep === 0) {
				tour.setSteps(steps);
				browserHistory.push("/candidates/search");
			}

			if (!tour.isOpen && store.currentStep === 0) {
				isElementLoaded(selectorForStep(0)).then(() => {
					tour.setCurrentStep(0);
					tour.setIsOpen(true);
				});
			}

			if (
				store.currentStep === 1 &&
				tour.currentStep === 0 &&
				props.openQuickViewDrawer
			) {
				props.openQuickViewDrawer();
			}

			if (
				store.currentStep === 1 &&
				tour.currentStep === 0 &&
				props.switchToNotesTab
			) {
				props.switchToNotesTab();
				setTimeout(() => {
					tour.setCurrentStep(1);
				}, 400);
			}

			if (store.currentStep === 2 && tour.currentStep === 1) {
				tour.setCurrentStep(2);
			}

			if (store.currentStep === 3 && tour.currentStep === 2) {
				browserHistory.push("/permanent/view/fake-id?tab=notes");
				isElementLoaded(selectorForStep(3)).then(() => {
					tour.setCurrentStep(3);
				});
			}

			if (store.currentStep === 4 && tour.currentStep === 3) {
				tour.setCurrentStep(4);
			}

			if (
				store.currentStep === 5 &&
				tour.currentStep === 4 &&
				props.openAddNoteDialog
			) {
				props.openAddNoteDialog();
				isElementLoaded(selectorForStep(5)).then(() => {
					tour.setCurrentStep(5);
				});
			}

			return () => {
				if (store.currentStep === 5 && tour.currentStep === 5 && tour.isOpen)
					clearStore();
			};
		}
	}, [store.workflowId, store.currentStep, tour.currentStep, tour.isOpen]);
};

export const providerConfig = {
	ContentComponent: TourContentComponent,
	onClickMask: () => null,
	className: styles.popover,
	maskClassName: styles.mask,
	padding: 0,
	scrollSmooth: true,
	disableKeyboardNavigation: true,
	Wrapper: ({ children }) => <Portal>{children}</Portal>
};
