import React from "react";
import { queryCache } from "react-query";
import { ProactiveApplicationsTable } from "../ProactiveApplicationsTable";
import useGetProactiveApplicationsList from "../../../api/useGetProactiveApplicationsList";
import styles from "./proactive-applications-tab.module.scss";
import Loader from "common/Loader";
import PermanentEmptyState from "../emptyState/PermanentEmptyState";
import { PROACTIVE_APPLICATIONS } from "modules/home/config";
import usePermanentStore from "modules/home/zustand/PermanentStore";
import { listCountKey } from "modules/home/api/useListCount";

const ProactiveApplicationsTab = ({ onViewAllClick }) => {
	const { permanentType } = usePermanentStore();
	const { data, isLoading } = useGetProactiveApplicationsList({
		onSuccess: () => {
			queryCache.invalidateQueries(listCountKey);
		}
	});
	const isTabActive = permanentType === PROACTIVE_APPLICATIONS;

	if (!isTabActive) return null;

	return (
		<div>
			{isLoading ? (
				<div className={styles.loaderContainer}>
					<Loader check={false} />
				</div>
			) : data.total === 0 ? (
				<div className={styles.emptyStateContainer}>
					<PermanentEmptyState
						showAction={false}
						heading="You have no proactive applications for today!"
					/>
				</div>
			) : (
				<ProactiveApplicationsTable
					applicationsList={data.data}
					onViewAllClick={onViewAllClick}
				/>
			)}
		</div>
	);
};

export default ProactiveApplicationsTab;
