import React, { useState } from "react";
import { Flex, Box, Text, Link } from "rebass";
import { Scrollbars } from "react-custom-scrollbars";
import { ReactComponent as DownloadCloud } from "static/icons/download-cloud.svg";
import { getFileExtension } from "common/Functions";
import { JPG, JPEG, PNG, DOCX, DOC, PDF } from "config";

const AttachedFiles = ({ files, preview, header }) => {
	const [height, setHeight] = useState(150);

	const getIconType = file => {
		const fileType = getFileExtension(file);
		switch (fileType) {
			case JPG:
			case JPEG:
				return "jpg";
			case PNG:
				return "png";
			case DOCX:
			case DOC:
				return "doc";
			case PDF:
				return "pdf";
			case "xls":
			case "xlsx":
				return "xls";
			case "ppt":
			case "pptx":
				return "ppt";
			default:
				return "doc";
		}
	};

	return (
		<Box
			sx={{
				marginTop: "20px"
			}}
		>
			{header ? (
				header
			) : (
				<Text
					mb={22}
					sx={{
						fontFamily: "BasierCircle",
						fontSize: "15px",
						fontWeight: "600",
						letterSpacing: "0.08px",
						color: "rgb(39, 50, 56)"
					}}
				>
					Attached files
				</Text>
			)}
			<Scrollbars
				autoHideTimeout={5000}
				autoHideDuration={200}
				autoHeight
				autoHeightMax={height}
				style={{ zIndex: 1, paddingBottom: "16px" }}
				renderTrackHorizontal={props => (
					<div
						{...props}
						style={{ display: "none" }}
						className="track-horizontal"
					/>
				)}
				renderView={props => (
					<div {...props} style={{ ...props.style, overflowX: "hidden" }} />
				)}
			>
				<Flex sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
					{files.map(file => {
						return (
							<Box
								key={file}
								mb={16}
								mr={15}
								sx={{
									minHeight: "36px",
									// flex: "0 46%",
									paddingBottom: "16px",
									borderBottom: "solid 1px rgb(221, 227, 232)"
								}}
							>
								<Flex alignItems="center">
									<Box mr={16}>
										<Text
											as={"span"}
											sx={{ fontSize: "40px" }}
											className={`icon-${getIconType(file.path)}`}
										>
											<Text as={"span"} className="path1"></Text>
											<Text as={"span"} className="path2"></Text>
											<Text as={"span"} className="path3"></Text>
											<Text as={"span"} className="path4"></Text>
											<Text as={"span"} className="path5"></Text>
											<Text as={"span"} className="path6"></Text>
											<Text as={"span"} className="path7"></Text>
											<Text as={"span"} className="path8"></Text>
										</Text>
									</Box>
									<Flex sx={{ width: "100%" }} justifyContent="space-between">
										<Box>
											<Text
												sx={{
													fontFamily: "BasierCircle",
													fontSize: "14px",
													fontWeight: "500",
													color: "rgb(36, 47, 67)"
												}}
											>
												{preview ? file.name : file.field_name}
											</Text>
											{/* <Text
												sx={{
													fontFamily: "BasierCircle",
													fontSize: "14px",
													color: "rgb(152, 153, 156)"
												}}
											>
												06 sep, 2020 at 11:05 . 4.8mb
											</Text> */}
										</Box>
										<Link href={file.path}>
											<DownloadCloud
												className="downloadCloud-icon"
												style={{ width: "30px" }}
											/>
										</Link>
									</Flex>
								</Flex>
							</Box>
						);
					})}
				</Flex>
			</Scrollbars>
			{files.length > 4 ? (
				<Flex justifyContent="center" mt={28}>
					<Text
						as={"span"}
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "14px",
							fontWeight: "500",
							color: "rgb(80, 95, 117)",
							display: "inline-block",
							padding: "8px 10px",
							cursor: "pointer",
							"&:hover": {
								borderRadius: "4px",
								backgroundColor: "rgba(240, 243, 245, 0.61)"
							}
						}}
						onClick={() => (height <= 150 ? setHeight(400) : setHeight(150))}
					>
						{height <= 150
							? `Show more (+${files.length - 4})`
							: `Show less (-${files.length - 4})`}
						<Text
							className={
								height <= 150 ? "icon-down-arrow" : "icon-down-arrow up"
							}
							ml={"10px"}
							sx={{
								color: "rgb(80, 95, 117)",
								fontSize: "6px",
								display: "inline-block",
								"&.up": {
									transform: "rotate(-180deg)"
								}
							}}
							as={"span"}
						></Text>
					</Text>
				</Flex>
			) : (
				<></>
			)}
		</Box>
	);
};

export default AttachedFiles;
