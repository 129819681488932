import { createCalendar } from "@internationalized/date";
import { useDateField, useLocale } from "react-aria";
import { useDateFieldState } from "@react-stately/datepicker";
import cx from "classnames";
import React from "react";
import { DateSegment } from "../DateSegment";
import styles from "./date-field.module.scss";

const DateField = ({
	dateFieldClassName,
	isMultiple,
	list,
	onPress,
	...propsRest
}) => {
	let { locale } = useLocale();
	let state = useDateFieldState({
		...propsRest,
		locale,
		createCalendar
	});

	let ref = React.useRef();
	let { fieldProps } = useDateField(propsRest, state, ref);

	return (
		<div className={cx(dateFieldClassName)}>
			<div {...fieldProps} ref={ref} className={styles.field}>
				{isMultiple && list.length === 0 ? (
					<button
						{...(onPress ? { onClick: onPress } : {})}
						className={styles.placeholder}
					>
						Select date
					</button>
				) : (
					state.segments.map((segment, i) => {
						let newSegment;
						if (i === 0 && isMultiple) {
							newSegment = {
								...segment,
								text: list
									.sort((a, b) => a.day - b.day)
									.map(date => date.day)
									.join("-")
							};
						} else {
							newSegment = segment;
						}

						return <DateSegment key={i} segment={newSegment} state={state} />;
					})
				)}
			</div>
		</div>
	);
};

DateField.displayName = "DateField";

export default DateField;
