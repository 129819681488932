import React from "react";
import _get from "lodash/get";
import TopSection from "./TopSection";
import Tabs from "./Tabs/Tabs";
import { CONSULTANCY, FUNCTIONS, SKILLS } from "../../../config";

import Preferences from "./Tabs/Preferences";

import SideBar from "./sidebar/SideBar";

import HistoryBid from "modules/bids/components/bidview/History";
import Functions from "modules/job/components/jobprofile/Functions/Functions";
import FreelancerModals from "./modals/FreelancerModals";
import FreelancerCV from "./Tabs/FreelancerCV";
import Languages from "./Tabs/Languages";
import Skills from "./Tabs/FreelancerSkills";

const ConsultantProfile = ({
	bid_id,
	_ownCV,
	headline,
	functions,
	activeTab,
	isClient,
	group,
	status,
	showBack,
	viewProfile,
	history,
	showModal,
	id,
	cv_docs,
	initialSkills
}) => {
	const cv = _get(cv_docs, "original_cv");
	const cvDoc = _get(cv_docs, "cv_doc");
	return (
		<>
			<TopSection
				showBack={showBack}
				bid_id={bid_id}
				ownCV={_ownCV}
				viewProfile={viewProfile}
				userId={headline && headline._id}
				isConsultancy={true}
			/>
			<div className={` ${_ownCV ? "container" : "client-layout-padding"}`}>
				<div className="col-md-9">
					<Tabs
						functions={functions}
						ownCV={_ownCV}
						profileCompanyType={CONSULTANCY}
					/>
				</div>
				<div className="row">
					<div className="col-md-9 update">
						{activeTab === 0 && (
							<div
								className="tab-content"
								style={{
									backgroundColor: "#fff",
									border: "1px solid #e1e4e7",
									borderRadius: "4px",
									boxShadow: "0px 2px 1px 0 rgba(197,199,206,0.25)"
								}}
							>
								<FreelancerCV
									cv={cv}
									id={headline && headline._id}
									canUpdateCV={_ownCV}
									cvDoc={cvDoc}
								/>
							</div>
						)}
						{activeTab === 1 && (
							<div className="tab-content">
								<Functions
									functions={functions}
									showModal={() => showModal(FUNCTIONS)}
									canUpdateFunctions={_ownCV}
									isFreelancer
									userid={id}
								/>

								<Skills
									skills={initialSkills}
									functionsLength={_get(functions, "length", 0)}
									showModal={() => showModal(SKILLS)}
									ownCV={_ownCV}
								/>
								<Languages ownCV={_ownCV} isClient={isClient} isFreelancer />
							</div>
						)}
						{activeTab === 2 && (
							<div className="tab-content">
								<Preferences ownCV={_ownCV} />
							</div>
						)}

						{activeTab === 3 && (
							<div className="tab-content">
								<HistoryBid history={history} />
							</div>
						)}
					</div>
					<div className="col-md-3 update profile-sidebar">
						<SideBar bidId={bid_id} ownCV={_ownCV} isConsultancy />
					</div>
				</div>

				<FreelancerModals
					bid_id={bid_id}
					newUserId={headline && headline._id}
					userStatus={status}
					functions={functions}
					profileSector={_get(headline, "sector.parent_sector")}
					isConsultancy={true}
					group={{ label: group.name, value: group._id }}
				/>
			</div>
		</>
	);
};

export default ConsultantProfile;
