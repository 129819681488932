import React from "react";
import EmptyState from "modules/home/components/EmptyState.jsx";
import interviewEmptyState from "static/images/interview_empty.png";
import { ReactComponent as SearchIcon } from "static/icons/search-list-icon.svg";
import { historyPush } from "config/helpers";
import useInterviewStore from "modules/home/containers/interview/interviewStore.js";
import { PERMANENT, TEMPORARY } from "config";

const InterviewEmptyState = () => {
	const { interview_recipient_type } = useInterviewStore();
	const handleClickMyVavancies = () => historyPush("/vacancies/list");
	const handleClickMyRequest = () => historyPush("/jobs/list");
	return (
		<EmptyState
			illustration={<img src={interviewEmptyState} />}
			heading="No interviews planned on the selected dates."
			description="Organise interviews through :"
			firstAction={
				!Boolean(interview_recipient_type.value) ||
				interview_recipient_type.value === PERMANENT
					? {
							label: "My vacancies",
							onClick: handleClickMyVavancies,
							icon: <SearchIcon />
					  }
					: undefined
			}
			secondAction={
				!Boolean(interview_recipient_type.value) ||
				interview_recipient_type.value === TEMPORARY
					? {
							label: "My request",
							onClick: handleClickMyRequest,
							icon: <SearchIcon />
					  }
					: undefined
			}
		/>
	);
};

export default InterviewEmptyState;
