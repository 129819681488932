import React from "react";
import styles from "../styles.module.scss";
import useDeleteAccountStore from "../store";
import { ReactComponent as ChevronLeftIcon } from "static/icons/chevronLeftIcon.svg";
import { ReactComponent as ChevronRightIcon } from "static/icons/chevronRightIcon.svg";
import { ReactComponent as RedMarkIcon } from "static/icons/redMarkIcon.svg";
import generalSettingsStyles from "../../../styles.module.scss";

const data = [
	"Log you out on all devices",
	"Delete all of your account information",
	"You will stop receiving job alerts"
];
export default function SecondStep() {
	const {
		incrementStep,
		decrementStep,
		hasNext,
		hasPrev
	} = useDeleteAccountStore();

	return (
		<div>
			<div className={styles.flex_col} style={{ margin: "25px 0" }}>
				<h5 className={styles.subtitle}>
					Deleting your account will do the following:
				</h5>
				{data.map((item, index) => {
					return (
						<div key={index} className={styles.list_item}>
							<RedMarkIcon />
							<p>{item}</p>
						</div>
					);
				})}
			</div>
			<div
				className={styles.buttons}
				style={{ justifyContent: "space-between" }}
			>
				<button
					disabled={!hasPrev}
					onClick={decrementStep}
					className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__secondary}`}
				>
					<ChevronLeftIcon />
					Back
				</button>
				<button
					disabled={!hasNext}
					onClick={incrementStep}
					className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__secondary}`}
				>
					Next
					<ChevronRightIcon />
				</button>
			</div>
		</div>
	);
}
