import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import styles from "./accordion.module.scss";

const Loading = () => {
	return new Array(3).fill(0).map((_, i) => {
		return (
			<div className={styles.accordion} key={i}>
				<div className={styles.accordionHeader}>
					<div className={styles.header}>
						<div className={styles.title}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={600} height={16} />
							</SkeletonTheme>
						</div>
						<div className={styles.description}>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={150} height={16} />
							</SkeletonTheme>{" "}
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={100} height={16} />
							</SkeletonTheme>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={150} height={16} />
							</SkeletonTheme>
						</div>
					</div>
					<div className={styles.action}>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={50} height={16} />
						</SkeletonTheme>
					</div>
				</div>
			</div>
		);
	});
};

export default Loading;
