import React from "react";

import { CustomDropDown } from "common/CustomDropDown";
import { CustomInput } from "common/CustomInput";
import { CustomMap } from "common/CustomMap";
import { DatePicker } from "common/DatePicker";
import { OPERATORS } from "common/SmartFilterDrawer/utils/constant";
import { Picker } from "common/Picker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { CANDIDATE_DATA_BY_COLUMN_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import coupleIcon from "static/icons/person-icon.svg";
import preferenceIcon from "static/icons/preferences-icon.svg";
import starIcon from "static/icons/star-icone.svg";
import timeIcon from "static/icons/timer-icon.svg";
import CurrencyInput from "../../../common/NewCurrencyField/NewCurrencyField";

export const CANDIDATES_FILTER = "CANDIDATES_FILTER";
export const CANDIDATES_MODULE_ID = "candidates";
export const rowsPages = [50, 24, 12];
export const SAVE_MODAL = "SAVE_MODAL";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const DELETE_MODAL = "DELETE_MODAL";
const IsEmptyComponent = props => (
	<CustomDropDown
		options={[
			{
				label: "Yes",
				value: "yes"
			},
			{
				label: "No",
				value: "no"
			}
		]}
		{...props}
	/>
);
const PickerCustomDropDown = props => <Picker {...props} limit={1} />;
PickerCustomDropDown.displayName = "Picker";

const BooleanComponent = props => (
	<CustomDropDown withBooleanOptions {...props} />
);

const ExpectedSalaryComponent = ({ onChange, value, rootClassName }) => (
	<CurrencyInput
		input={{
			onChange: value => onChange(value, "change"),
			value
		}}
		useGreenTheme
		rootClassName={rootClassName}
	/>
);

export const OPTION_TYPES = {
	date: "date",
	multipleSelect: "multipleSelect",
	multipleSelect2: "multipleSelect2",
	text: "text",
	text2: "text2",
	text3: "text3",
	zipCode: "zipCode",
	expectedSalary: "expectedSalary",
	noticePeriode: "noticePeriode",
	extraBenefits: "extraBenefits",
	profileCreatedBy: "profileCreatedBy",
	lastNote: "lastNote",
	map: "map",
	interviewed: "interviewed",
	boolean: "boolean",
	keyword: "keyword"
};

export const SOURCES = {
	allData: { value: "all", label: "All" },
	myData: { value: "internal_data", label: "My data" },
	candidatesData: { value: "candidate_profile", label: "Candidates data" }
};

const toSingular = label => {
	return label
		.split(" ")
		.map((word, index) => {
			return index === 0 ? word.slice(0, -1) : word;
		})
		.join(" ");
};

export const OPERATORS_BY_TYPE = {
	[OPTION_TYPES.map]: [OPERATORS.is],
	[OPTION_TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[OPTION_TYPES.lastNote]: [
		OPERATORS.on,
		OPERATORS.notOn,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between,
		OPERATORS.contains
	],
	[OPTION_TYPES.number]: [
		OPERATORS.is,
		OPERATORS.lt,
		OPERATORS.between,
		OPERATORS.gt
	],
	[OPTION_TYPES.list]: [OPERATORS.is, OPERATORS.neq, OPERATORS.isAnyOf],
	[OPTION_TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[OPTION_TYPES.keyword]: [OPERATORS.keyword],
	[OPTION_TYPES.text2]: [
		OPERATORS.contains,
		OPERATORS.notContains,
		OPERATORS.isEmpty
	],
	[OPTION_TYPES.expectedSalary]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[OPTION_TYPES.noticePeriode]: [
		OPERATORS.is,
		OPERATORS.isNot,
		OPERATORS.anyOf,
		OPERATORS.isEmpty
	],
	[OPTION_TYPES.extraBenefits]: [
		OPERATORS.is,
		OPERATORS.isNot,
		OPERATORS.anyOf
	],
	[OPTION_TYPES.profileCreatedBy]: [
		OPERATORS.contains,
		OPERATORS.notContains,
		OPERATORS.starstWith,
		OPERATORS.endsWith,
		OPERATORS.anyOf
	],
	[OPTION_TYPES.multipleSelect]: [
		OPERATORS.is,
		OPERATORS.isNot,
		OPERATORS.anyOf
	],
	[OPTION_TYPES.multipleSelect2]: [OPERATORS.is],
	[OPTION_TYPES.interviewed]: [OPERATORS.is, OPERATORS.isNot],
	[OPTION_TYPES.boolean]: [OPERATORS.is],
	[OPTION_TYPES.zipCode]: [
		OPERATORS.contains,
		OPERATORS.notContains,
		OPERATORS.startWith
	]
};

export const COMPONENT_BY_OPERATOR = {
	[OPTION_TYPES.text]: {
		default: CustomInput
	},
	[OPTION_TYPES.keyword]: {
		default: CustomInput
	},
	[OPTION_TYPES.text2]: {
		default: CustomInput,
		[OPERATORS.isEmpty.value]: IsEmptyComponent
	},
	[OPTION_TYPES.expectedSalary]: {
		default: ExpectedSalaryComponent,
		[OPERATORS.isEmpty.value]: IsEmptyComponent
	},
	[OPTION_TYPES.noticePeriode]: {
		default: CustomInput
	},
	[OPTION_TYPES.profileCreatedBy]: {
		default: CustomInput
	},
	[OPTION_TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	},
	[OPTION_TYPES.lastNote]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	},
	[OPTION_TYPES.multipleSelect]: {
		default: Picker,
		[OPERATORS.is.value]: PickerCustomDropDown
	},
	[OPTION_TYPES.multipleSelect2]: {
		default: Picker
	},
	[OPTION_TYPES.multipleSelect3]: {
		default: Picker
	},
	[OPTION_TYPES.map]: {
		default: CustomMap
	},
	[OPTION_TYPES.interviewed]: {
		default: CustomDropDown
	},
	[OPTION_TYPES.boolean]: {
		default: BooleanComponent
	},
	[OPTION_TYPES.zipCode]: {
		default: CustomInput
	}
};

export const COLUMNS = [
	{
		id: 2,
		label: "Candidate details",
		icon: coupleIcon,
		children: [
			{
				label: "Id",
				name: "id",
				type: OPTION_TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "First name",
				name: "first_name",
				type: OPTION_TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Last name",
				name: "last_name",
				type: OPTION_TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Email address",
				name: "email",
				type: OPTION_TYPES.text2,
				operator: OPERATORS.contains
			},
			{
				label: "Phone number",
				name: "phone",
				type: OPTION_TYPES.text2,
				operator: OPERATORS.contains
			},
			{
				label: "External profile",
				name: "external_link",
				type: OPTION_TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Residence",
				name: "residence",
				type: OPTION_TYPES.map,
				operator: OPERATORS.is
			},
			{
				label: "Postcode",
				name: "zip_code",
				type: OPTION_TYPES.zipCode,
				operator: OPERATORS.contains
			},
			{
				label: "Follow",
				name: "followed",
				type: OPTION_TYPES.boolean,
				operator: OPERATORS.is
			},
			{
				label: "Cv content",
				name: "cv_content",
				type: OPTION_TYPES.keyword,
				operator: OPERATORS.keyword
			}
		]
	},
	{
		id: 3,
		label: "Preferences",
		icon: preferenceIcon,
		children: [
			{
				label: "Preferred location",
				name: "preferred_location",
				type: OPTION_TYPES.map,
				operator: OPERATORS.is
			},
			{
				label: "Expected salary",
				name: "expected_salary",
				type: OPTION_TYPES.expectedSalary,
				operator: OPERATORS.eq
			},
			{
				label: "Currency",
				name: "currency",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "currencies" })
								.then(res => {
									const formattedData = formatCurrencies(res, "Currencies");
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Currencies remained",
					secondaryHeaderContent: () => "Currencies selected",
					useServerFilter: false
				}
			},
			{
				label: "Country",
				name: "payroll_country",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "countries" })
								.then(res => {
									const formattedData = formatCountries(res);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Countries remained",
					secondaryHeaderContent: () => "Countries selected"
				}
			},
			{
				label: "Salary type",
				name: "salary_type",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "payment_time" })
								.then(res => {
									const formattedData = formatDataTypeOptions(res, "");
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Salary types remained",
					secondaryHeaderContent: () => "Salary types selected"
				}
			},
			{
				label: "Gross/net",
				name: "payment_type",
				type: OPTION_TYPES.multipleSelect2,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "payment_type" })
								.then(res => {
									const formattedData = formatDataTypeOptions(
										res,
										"Payment types"
									);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Payment types remained",
					secondaryHeaderContent: () => "Payment types selected"
				}
			},
			{
				label: "Notice period",
				name: "notice_period",
				type: OPTION_TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Extra benefits",
				name: "extra_benefits",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "extra_benefits" })
								.then(res => {
									const formattedData = formatDataTypeOptions(
										res,
										"Extra benefits"
									);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Extra benefits remained",
					secondaryHeaderContent: () => "Extra benefits selected",
					useServerFilter: false
				}
			},

			{
				label: "Employment type",
				name: "employment_type",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "employment_type" })
								.then(res => {
									const formattedData = formatDataTypeOptions(
										res,
										"Employment types"
									);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Employment types remained",
					secondaryHeaderContent: () => "Employment types selected",
					useServerFilter: false
				}
			}
		]
	},
	{
		id: 4,
		label: "Functions & Skills",
		icon: starIcon,
		children: [
			{
				label: "Category",
				name: "category",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: (_, { search }) => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "category", search })
								.then(res => {
									const formattedData = formatItemsWithoutParent(
										res,
										"Categories"
									);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Categories remained",
					secondaryHeaderContent: () => "Categories selected",
					useServerFilter: true
				}
			},
			{
				label: "Sub category",
				name: "subcategory",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: (_, { search }) => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "sub_category", search })
								.then(res => {
									const formattedData = formatItemsWithoutParent(
										res,
										"Sub Categories"
									);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: ({ parent, singular }) =>
						singular
							? toSingular(`Functions remain in ${parent}`)
							: `Functions remain in ${parent}`,
					secondaryHeaderContent: ({ parent, singular }) =>
						singular
							? toSingular(`Functions selected in ${parent}`)
							: `Functions selected in ${parent}`,
					useServerFilter: true
				}
			},
			{
				label: "Functions",
				name: "functions",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: (_, { search }) => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "functions", search })
								.then(res => {
									const formattedData = formatFunctions(res);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: ({ parent, singular }) =>
						singular
							? toSingular(`Functions remain in ${parent}`)
							: `Functions remain in ${parent}`,
					secondaryHeaderContent: ({ parent, singular }) =>
						singular
							? toSingular(`Functions selected in ${parent}`)
							: `Functions selected in ${parent}`,
					useServerFilter: true
				}
			},
			{
				label: "Seniority",
				name: "seniority",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "seniority" })
								.then(res => {
									const formattedData = fomatSeniorities(res);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Seniorities remained",
					secondaryHeaderContent: () => "Seniorities selected"
				}
			},
			{
				label: "Skills",
				name: "skills",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: (_, { search }) => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "skills", search })
								.then(res => {
									const formattedData = formatSkills(res);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: true,
					primaryHeaderContent: ({ parent, singular }) =>
						singular
							? toSingular(`Skills remain in ${parent}`)
							: `Skills remain in ${parent}`,
					secondaryHeaderContent: ({ parent, singular }) =>
						singular
							? toSingular(`Skills selected in ${parent}`)
							: `Skills selected in ${parent}`,
					useServerFilter: true
				}
			},
			{
				label: "Languages",
				name: "languages",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: (_, { search }) => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "languages", search })
								.then(res => {
									const formattedData = formatItemsWithoutParent(
										res,
										"Languages"
									);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: true,
					primaryHeaderContent: ({ singular }) =>
						singular ? `Language remain` : `Languages remain`,
					secondaryHeaderContent: ({ singular }) =>
						singular ? `Language selected` : `Languages selected`,
					useServerFilter: true
				}
			},
			{
				label: "Profile tags",
				name: "profile_tags",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "profile_tags" })
								.then(res => {
									const formattedData = fomatProfileTags(res);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? `Profile tag remain` : `Profile tags remain`,
					secondaryHeaderContent: ({ singular }) =>
						singular ? `Profile tag selected` : `Profile tags selected`
				}
			}
		]
	},
	{
		id: 5,
		label: "History",
		icon: timeIcon,
		children: [
			{
				label: "Profile created by",
				name: "created_by",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "created_by" })
								.then(res => {
									const formattedData = formatCreatedByArray(res);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Created by remained",
					secondaryHeaderContent: () => "Created by selected"
				}
			},
			{
				label: "Profile created on",
				name: "created_at",
				type: OPTION_TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "Source",
				name: "source",
				type: OPTION_TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "source" })
								.then(res => {
									const formattedData = formatCandidatesSource(res.source);
									resolve(formattedData);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Sources remained",
					secondaryHeaderContent: () => "Sources tags selected"
				}
			},
			{
				label: "Notes",
				name: "notes",
				type: OPTION_TYPES.boolean,
				operator: OPERATORS.is
			},
			{
				label: "Interviewed",
				name: "interviewed",
				type: OPTION_TYPES.interviewed,
				operator: OPERATORS.is,
				payload: {
					options: [
						{
							label: "Yes",
							value: true
						},
						{
							label: "No",
							value: false
						}
					]
				}
			},
			{
				label: "Viewed",
				name: "is_viewed",
				type: OPTION_TYPES.boolean,
				operator: OPERATORS.is
			},
			{
				label: "Activated",
				name: "is_activated",
				type: OPTION_TYPES.boolean,
				operator: OPERATORS.is
			},
			{
				label: "Referred",
				name: "is_referred",
				type: OPTION_TYPES.boolean,
				operator: OPERATORS.is
			}
		]
	}
];

//TODO Refactor this
function formatCandidatesSource(data) {
	const sources = data.map(item => ({
		value: item,
		label: item
	}));

	const dataFormatted = sources.map(item => {
		const { label, value } = item;

		return {
			label,
			id: value
		};
	});

	const parent = [{ id: 1, label: "sources", children: dataFormatted }];

	return parent;
}

function formatSkills(parents) {
	return parents.map(parent => {
		const { skills, id, name } = parent;

		const children = skills.map(({ id, name }) => {
			return { label: name, id };
		});

		const newParent = {
			id,
			label: name,
			children
		};

		return newParent;
	});
}

const getFieldValues = ({ tag, search = "" }) => {
	return client(CANDIDATE_DATA_BY_COLUMN_ENDPOINT, {
		body: {
			list: "candidates",
			tag,
			search
		}
	});
};

function formatDataTypeOptions(data, label) {
	const dataFormatted = data.map(item => {
		const { label, value } = item;

		return {
			label,
			id: value
		};
	});

	const parent = [{ id: 1, label, children: dataFormatted }];

	return parent;
}

function formatCurrencies(items, label) {
	const itemsFormatted = items.map(item => {
		const { name, symbol } = item;

		return {
			label: name,
			id: symbol
		};
	});

	const parent = [{ id: 1, label, children: itemsFormatted }];

	return parent;
}

function formatCountries(data, label) {
	const dataFormatted = data.map(item => {
		const { country } = item;

		return {
			label: country,
			id: country
		};
	});

	const parent = [{ id: 1, label, children: dataFormatted }];

	return parent;
}

function formatItemsWithoutParent(items, label) {
	const itemsFormatted = items.map(item => {
		const { name, _id } = item;

		return {
			label: name,
			id: _id
		};
	});

	const parent = [{ id: 1, label, children: itemsFormatted }];

	return parent;
}

function fomatSeniorities(seniorities) {
	const seniorityFormatted = seniorities.map(seniority => {
		const { label, value } = seniority;

		return {
			label,
			id: value
		};
	});

	const parent = [
		{ id: 1, label: "Seniorities", children: seniorityFormatted }
	];

	return parent;
}

function fomatProfileTags(profileTags) {
	const profileTagsFormatted = profileTags.map(profileTag => {
		const { value } = profileTag;

		return {
			label: value,
			id: value
		};
	});

	const parent = [
		{ id: 1, label: "Profile tags", children: profileTagsFormatted }
	];

	return parent;
}

function formatFunctions(parents) {
	return parents.map(parent => {
		const { functions, id, name } = parent;

		const children = functions.map(({ id, name }) => {
			return { label: name, id };
		});

		const newParent = {
			id,
			label: name,
			children
		};

		return newParent;
	});
}

function formatCreatedByArray(createdByArray) {
	const createdByFormatted = createdByArray.map(createdByItem => {
		const { id, first_name, last_name } = createdByItem;

		return {
			label: `${first_name} ${last_name}`,
			id
		};
	});

	const parent = [{ id: 1, label: "Created by", children: createdByFormatted }];

	return parent;
}
