import React, { useEffect } from "react";
import styles from "./video-conference.module.scss";
import { ReactComponent as WiggliLogoSVG } from "static/icons/wiggli-logo-grey.svg";
import { WherebyEmbed } from "common/WherebyEmbed";
import url from "static/icons/video-conference-background.svg";

const VideoConference = ({ roomUrl, displayName }) => {
	useEffect(() => {
		const elm = document.querySelector("whereby-embed");
		elm.addEventListener("leave", () => window.close());
	}, []);

	const style = {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		paddingTop: "48px",
		paddingBottom: "48px",
		height: "100vh",
		"background-color": "#122f23",
		"background-image": `url(${url})`,
		gap: "10px"
	};

	return (
		<div style={style}>
			<WiggliLogoSVG className={styles.logo} />
			<div className={styles.videoConferenceContainer}>
				<WherebyEmbed roomUrl={roomUrl} displayName={displayName} />
			</div>
		</div>
	);
};

export default VideoConference;
