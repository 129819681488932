import React, { useState, useRef } from "react";
import MenuLabel from "./MenuLabel";
import classnames from "./filter-options-values.module.scss";
import { get, filter } from "lodash";
import {
	REMINDER_BEFORE,
	REMINDER_AFTER,
	DUE_DATE,
	REMINDER,
	PRIORITY,
	LINK_TO,
	CREATED_BY,
	ASSIGNED_TO,
	CREATION_DATE,
	STATUS,
	CREATED_AT,
	LINK_TO_NOTES,
	NOTE_TYPE,
	USER_ID,
	ALLOWED_USERS,
	FILE_TYPE
} from "config";
import RenderSelectedUsers from "./RenderSelectedUsers";
import RenderSelectedVacancy from "./RenderSelectedVacancy";
import StatusLabel from "modules/Tasks/components/table/status";
import StatusLabels from "modules/candidate/Profile/Pages/ProfileInteractions/status";
import Priority from "modules/Tasks/components/table/priority";
import { INTERACTIONS } from "config";
import useOnClickOutside from "hooks/useOnClickOutside";
import FileType from "modules/candidate/Profile/Pages/Files/components/fileType";

const RenderFilterOptionsValues = ({
	field,
	clickHandler,
	value,
	operator,
	deleteFilterValue,
	showMoreTitle,
	isAddIcon,
	options,
	onSelect
}) => {
	const ref = useRef();
	const [openMenu, setOpenMenu] = useState(false);
	useOnClickOutside(ref, () => {
		setOpenMenu(false);
	});

	let formattedValue = "";
	switch (field) {
		case NOTE_TYPE:
			const notesValues =
				value &&
				value.map(item => (
					<StatusLabel
						key={item}
						isDelete
						isDefault
						deleteHandler={e => {
							e.stopPropagation();
							onSelect(value.filter(option => item !== option));
						}}
						status={get(filter(options, { value: item }, "")[0], "label", "")}
					/>
				));
			return (
				<MenuLabel
					isAddIcon={isAddIcon}
					clickHandler={isAddIcon ? clickHandler : () => {}}
					value={
						<div onClick={() => {}} className={classnames.statusWrapper}>
							{notesValues}
						</div>
					}
				/>
			);

		case STATUS:
			const mappedValues =
				value &&
				value.map(item => (
					<StatusLabel
						key={item}
						isDelete
						deleteHandler={e => {
							e.stopPropagation();
							onSelect(value.filter(option => item !== option));
						}}
						status={item}
					/>
				));
			return (
				<MenuLabel
					isAddIcon={isAddIcon}
					clickHandler={isAddIcon ? clickHandler : () => {}}
					value={
						<div onClick={() => {}} className={classnames.statusWrapper}>
							{mappedValues}
						</div>
					}
				/>
			);
		case FILE_TYPE:
			const fileType =
				value &&
				value.map(item => (
					<FileType
						key={item}
						isDelete
						deleteHandler={e => {
							e.stopPropagation();
							onSelect(value.filter(option => item !== option));
						}}
						fileType={item}
					/>
				));
			return (
				<MenuLabel
					isAddIcon={isAddIcon}
					clickHandler={isAddIcon ? clickHandler : () => {}}
					value={
						<div onClick={() => {}} className={classnames.statusWrapper}>
							{fileType}
						</div>
					}
				/>
			);
		case INTERACTIONS:
			const countValues =
				value &&
				value.length > 1 &&
				value.slice(1).map(item => (
					<StatusLabels
						key={item}
						isDelete
						deleteHandler={e => {
							e.stopPropagation();
							onSelect(value.filter(option => item !== option));
						}}
						status={item}
					/>
				));
			const filterValues =
				value && value.length > 1
					? value.slice(0, 1).map(item => (
							<StatusLabels
								key={item}
								isDelete
								deleteHandler={e => {
									e.stopPropagation();
									onSelect(value.filter(option => item !== option));
								}}
								status={item}
							/>
					  ))
					: value.map(item => (
							<StatusLabels
								key={item}
								isDelete
								deleteHandler={e => {
									e.stopPropagation();
									onSelect(value.filter(option => item !== option));
								}}
								status={item}
							/>
					  ));
			return (
				<MenuLabel
					isAddIcon={isAddIcon}
					clickHandler={isAddIcon ? clickHandler : () => {}}
					value={
						<div onClick={() => {}} className={classnames.statusWrapper}>
							{filterValues}
							{value.length > 1 && (
								<div>
									<span
										onClick={e => {
											e.stopPropagation();
											setOpenMenu(true);
										}}
										style={{
											height: "25px",
											width: "25px",
											backgroundColor: "#babfc3",
											borderRadius: "50%",
											display: "inline-block",
											marginRight: "5px"
										}}
									>
										+{value.length - 1}{" "}
									</span>

									{openMenu && (
										<div
											ref={ref}
											style={{
												position: "absolute",
												zIndex: "99",
												backgroundColor: "white",
												display: "flex",
												flexDirection: "column",
												alignItems: "start",
												padding: "10px",
												gap: "10px",
												borderRadius: "7px",
												border: "1px solid #d2d5d8",
												marginTop: "5px"
											}}
										>
											{countValues}
										</div>
									)}
								</div>
							)}
						</div>
					}
				/>
			);
		case PRIORITY:
			const mappedPriorityValues =
				value &&
				value.map(item => (
					<Priority
						key={item}
						isDelete
						deleteHandler={e => {
							e.stopPropagation();
							onSelect(value.filter(option => item !== option));
						}}
						priority={item}
					/>
				));
			return (
				<MenuLabel
					isAddIcon={isAddIcon}
					clickHandler={isAddIcon ? clickHandler : () => {}}
					value={
						<div className={classnames.priorityWrapper}>
							{mappedPriorityValues}
						</div>
					}
				/>
			);
		case ALLOWED_USERS:
		case CREATED_BY:
		case ASSIGNED_TO:
		case USER_ID:
			let users = options.filter(item => value.includes(get(item, "_id")));

			return (
				<RenderSelectedUsers
					clickHandler={clickHandler}
					users={users}
					deleteFilterValue={deleteFilterValue}
					showMoreTitle={showMoreTitle}
				/>
			);
		case CREATED_AT:
		case CREATION_DATE:
		case DUE_DATE:
			const startDate = window.moment.unix(get(value, "0"));
			const endDate = window.moment.unix(get(value, "1"));
			if (get(value, "length", 0) > 0) {
				if (
					operator === "is" ||
					window.moment(startDate).isSame(endDate, "day")
				) {
					formattedValue = window.moment
						.unix(get(value, "0"))
						.format("DD/MM/YYYY");
				} else {
					formattedValue =
						startDate.format("DD/MM/YYYY") +
						" to " +
						endDate.format("DD/MM/YYYY");
				}
			}
			return (
				<MenuLabel
					clickHandler={clickHandler}
					value={formattedValue}
					isAddIcon={!Boolean(get(formattedValue, "length", 0))}
				/>
			);
		case REMINDER:
			if (get(value, "reminding_time") === "before") {
				formattedValue = REMINDER_BEFORE.filter(
					item => item.value === get(value, "time")
				)[0].label;
			} else if (get(value, "reminding_time") === "after") {
				formattedValue = REMINDER_AFTER.filter(
					item => item.value === get(value, "time")
				)[0].label;
			}

			return (
				<MenuLabel
					clickHandler={clickHandler}
					value={formattedValue}
					isAddIcon={!Boolean(get(formattedValue, "length", 0))}
				/>
			);

		case LINK_TO:
		case LINK_TO_NOTES:
			if (
				get(value, "entity", "") === "candidate" ||
				get(value, "entity", "") === "freelancer" ||
				get(value, "entity", "") === "profile" ||
				get(value, "entity", "") === "supplier"
			) {
				return (
					<RenderSelectedUsers
						isAddIcon={isAddIcon}
						clickHandler={clickHandler}
						users={options.filter(item =>
							get(value, "_id", []).includes(get(item, "_id"))
						)}
						deleteFilterValue={deleteFilterValue}
						showMoreTitle={showMoreTitle}
					/>
				);
			}
			return (
				<RenderSelectedVacancy
					isAddIcon={isAddIcon}
					clickHandler={clickHandler}
					value={
						value
							? options.filter(item =>
									get(value, "_id", []).includes(get(item, "_id"))
							  )
							: []
					}
					deleteFilterValue={deleteFilterValue}
					showMoreTitle={showMoreTitle}
				/>
			);
		default:
			return <MenuLabel clickHandler={clickHandler} value={value} />;
	}
};

export default RenderFilterOptionsValues;
