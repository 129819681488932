import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { ADD_ALL_TO_INTERNAL } from "config/api-endpoints";

function addToInternal(body) {
	return client(ADD_ALL_TO_INTERNAL, {
		body
	});
}
export const updateAddInternal = "@profile/add-to-internal";
function useAddToInternal(options) {
	return useMutation(addToInternal, options);
}

export default useAddToInternal;
