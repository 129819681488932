import React from "react";

const checkBox = ({
	children,
	formGroupClassName = "",
	input,
	meta: { touched, error },
	onChange,
	className = "styled",
	...props
}) => {
	const classes = touched && error ? " has-error" : "";
	const has_error = touched && error ? " has-error has-feedback" : "";
	return (
		<div className={`form-group ${formGroupClassName} ${classes} ${has_error}`}>
			<div className="checkbox checkbox-primary">
				<div className="input-box" style={{ width: "100%", display: "flex" }}>
					<input
						{...input}
						type="checkbox"
						className={className}
						id={input.name}
						checked={Boolean(input.value)}
						onChange={onChange || input.onChange}
						{...props}
					/>
					<label htmlFor={input.name} className={classes}>
						{children}
					</label>
				</div>
				<div className="help-block inline-error">{touched && error}</div>
			</div>
		</div>
	);
};

export default checkBox;
