import React, { useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import _get from "lodash/get";
import { LoaderFull } from "common/Loader";
import {
	DATE_FORMAT,
	NO_DATA_PLACEHOLDER,
	SENT,
	CONFIRM_INTEREST,
	CONFIRM_INTEREST_LABEL,
	MY_POKES_STATUS,
	INACTIVE,
	ASK_QUESTION,
	CANCEL_APPLICATION_LABEL,
	CANCEL_APPLICATION,
	APPLIED,
	NEW_POKE,
	NEW_INTERVIEW,
	INTERVIEW_PLANNED,
	CONFIRMED,
	ACTIVE_JOBS,
	ALL,
	REMOTE_LABEL
} from "config";
import { isDateValid } from "common/Functions";
import CompanyDashboard from "common/dashboard/Dashboard";
import EmptyJob from "common/EmptyComponent";
import Pagination from "common/Pagination";
import Intro from "common/dashboard/Intro";
import {
	TimesheetTableDetails,
	Th,
	Tr,
	Td
} from "modules/timesheets/components/TimesheetStyled";
import Actions from "common/ActionsMenu";
import { getPokeViewContainerRoute } from "../poke/PokeViewContainer";
import GlobalTooltip from "common/GlobalTooltip";
import { LinkButton } from "../poke/styled";
import StatusLabel from "common/StatusLabel";
import { ReactComponent as AppliedIcon } from "./icons/AppliedIcon.svg";
import { ReactComponent as PokedIcon } from "./icons/PokedIcon.svg";
import FilterJob from "./FilterJob";

function MyJobList({
	data: { pokes, pagination: { offset, size, isFetching, total } = {} },
	getJobList,
	route: { title: routeTitle },
	accountStatus,
	toggleHelpModal
} = {}) {
	const [activeFilter, setActiveFilter] = useState(ACTIVE_JOBS);
	useEffect(() => {
		getJobList({ offset: 0, status: ACTIVE_JOBS });
	}, []);

	const isInactive = accountStatus === INACTIVE;

	const filterList = value => {
		setActiveFilter(value);
		const payload = {
			offset: 0
		};
		if (value !== ALL) {
			payload.status = value;
		}
		getList(payload);
	};

	const getList = payload => {
		getJobList(payload);
	};

	const viewJobDetails = (e, id, step) => {
		if (!e.target.classList.contains("ignore-click")) {
			step !== 3 && browserHistory.push(getPokeViewContainerRoute(id));
		}
	};

	const getActions = status => [
		{
			label: CONFIRM_INTEREST_LABEL,
			key: CONFIRM_INTEREST,
			icon: "",
			visible: status === SENT
		},
		{
			label: CANCEL_APPLICATION_LABEL,
			key: CANCEL_APPLICATION,
			icon: "",
			visible: status === APPLIED
		}
	];

	const handleMenuClick = (e, id) => {
		if (e.key === CONFIRM_INTEREST) {
			browserHistory.push(getPokeViewContainerRoute(id));
		}
		if (e.key === CANCEL_APPLICATION) {
			browserHistory.push(getPokeViewContainerRoute(id));
		}
	};

	const stopPropagation = e => e.stopPropagation();

	const handleContactSupport = e => {
		e.stopPropagation();
		toggleHelpModal(ASK_QUESTION);
	};

	const getActionMenu = poke => {
		const dots = (
			<div className="dots ignore-click">
				<div className="dot ignore-click" />
				<div className="dot ignore-click" />
				<div className="dot ignore-click" />
			</div>
		);
		const overlay = (
			<span>
				Your profile status is set as INACTIVE; which means that you can’t
				interact with vacancies and client pokes. <br />
				To (re-)activate your profile, just{" "}
				<LinkButton onClick={handleContactSupport}>
					contact our support team
				</LinkButton>
				.
			</span>
		);
		return isInactive ? (
			<Td>
				<GlobalTooltip
					placement={"top"}
					noClassName={true}
					overlayClassName="g-tool-white top center-text"
					overlay={overlay}
					align={["tc", "bc"]}
				>
					{dots}
				</GlobalTooltip>
			</Td>
		) : (
			<Actions
				actions={getActions(poke.status)}
				onClick={isInactive ? null : e => handleMenuClick(e, poke._id)}
				id={poke._id}
				reposition={true}
			>
				<Td onClick={stopPropagation}>{dots}</Td>
			</Actions>
		);
	};
	const showList = () =>
		pokes.map(poke => {
			const jobRef = _get(poke, "job.job_ref");
			const canDisplayNotif =
				(poke.visible_status === NEW_POKE && poke.status === SENT) ||
				(poke.visible_status === (NEW_INTERVIEW || INTERVIEW_PLANNED) &&
					poke.status === CONFIRMED);
			const isRemote = !_get(poke, "job.country");

			return (
				<Tr
					key={jobRef}
					onClick={e => viewJobDetails(e, poke._id, poke.step, poke.status)}
				>
					<Td>{!poke.is_applied ? <PokedIcon /> : <AppliedIcon />}</Td>
					<Td>{jobRef}</Td>
					<Td>
						{isDateValid(poke.created_at, true)
							? window.moment.unix(poke.created_at).format(DATE_FORMAT)
							: NO_DATA_PLACEHOLDER}
					</Td>
					<Td className="title">{_get(poke, "job.company.name", "")}</Td>
					<Td>{_get(poke, "job.title")}</Td>
					<Td>
						{isRemote ? (
							<>{REMOTE_LABEL}</>
						) : (
							<>
								{_get(poke, "job.country")}{" "}
								{_get(poke, "job.city") ? `, ${_get(poke, "job.city")}` : ""}{" "}
							</>
						)}
					</Td>
					<Td>
						<StatusLabel
							status={poke.last_status}
							label={poke.visible_status}
							isInterview={typeof _get(poke, "job._id") === "string"}
							is_applied={poke.is_applied}
							canDisplayNotif={canDisplayNotif}
						/>
					</Td>
					{poke.status !== APPLIED && poke.status !== SENT ? (
						<Td onClick={stopPropagation} />
					) : (
						getActionMenu(poke)
					)}
				</Tr>
			);
		});

	const handlePageClick = value => {
		const payload = {
			offset: size * value
		};
		if (value !== ALL) {
			payload.status = activeFilter;
		}
		getList(payload);
	};

	if (isFetching)
		return <LoaderFull boxClasses="no-text" classes="full-loader" />;
	const isEmpty = !pokes.length;
	return (
		<BodyClassName className="my-job list-job gray-bg">
			<div className="job-list-preview">
				{routeTitle && (
					<Helmet>
						<title>{routeTitle}</title>
					</Helmet>
				)}
				<div className="dashboard-box">
					<CompanyDashboard isPermanentView={true} />
					<Intro>
						<p>
							This pages lists all the vacancies with which you have interacted.
						</p>
					</Intro>
				</div>

				<div className="container">
					<div className="top-actions">
						<FilterJob
							options={MY_POKES_STATUS}
							value={activeFilter}
							onFilterChange={filterList}
						/>
					</div>

					{isEmpty ? (
						<EmptyJob
							title={"There is no process at this stage"}
							actionText="START APPLYING"
							actionLink="/jobs/search"
							className="empty-job-permanent"
						/>
					) : (
						<>
							<TimesheetTableDetails className="job-list" spacing="0 3px">
								<thead>
									<Tr>
										<Th width="6%" className="hefhed" />
										<Th width="6%" className="hefhed">
											ref
										</Th>
										<Th width="10%">date</Th>
										<Th width="10%">company name</Th>
										<Th width="20%">job title</Th>
										<Th width="15%">location</Th>
										<Th align="center" width="15%">
											status
										</Th>
										<Th width="8%">actions</Th>
									</Tr>
								</thead>
								<tbody>{showList()}</tbody>
							</TimesheetTableDetails>

							<Pagination
								total={total}
								size={size}
								handlePageClick={handlePageClick}
								offset={offset}
							/>
						</>
					)}
				</div>
			</div>
		</BodyClassName>
	);
}

export default MyJobList;
