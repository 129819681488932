import React from "react";
import CompanyDashboard from "common/dashboard/Dashboard";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import NotificationsSettings from "./NotificationSettings";
import StatusSettings from "./StatusSettings";
import PrivacySettings from "./PrivacySettings";

export const getGeneralSettingsRoute = () => {
	return "/settings/general";
};

export default function GeneralSettings({ route }) {
	return (
		<BodyClassName className="gray-bg">
			<div>
				{route.title && (
					<Helmet>
						<title>{route.title}</title>
					</Helmet>
				)}
				<div
					className="dashboard-box"
					style={{ paddingBottom: "0px", marginBottom: "0px" }}
				>
					<CompanyDashboard />
				</div>
				<div className="container">
					<NotificationsSettings />
					<StatusSettings />
					<PrivacySettings />
				</div>
			</div>
		</BodyClassName>
	);
}
