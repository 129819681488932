import React, { useState } from "react";
import styles from "./add-timesheet-entries-drawer-body.module.scss";
import { Button } from "common/Button";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import { AddTimesheetEntryForm } from "../AddTimesheetEntryForm";
import { AddTimesheetEntriesExplanatoryCard } from "../AddTimesheetEntriesExplanatoryCard";
import { AddEntriesDrawerRowsCard } from "../AddTimesheetEntriesDrawer/AddEntriesDrawerRowsCard";
import { AddTimesheetEntriesUploadCard } from "../AddTimesheetEntriesUploadCard";
import useEntries from "modules/timesheets/hooks/useEntries";
import { ReactComponent as DocIcon } from "static/icons/doc.svg";
import { ReactComponent as ClockPlusIcon } from "static/icons/clock-plus.svg";
import { ReactComponent as ClockIcon } from "static/icons/clock.svg";
import { AddTimesheetEntriesBanner } from "../AddTimesheetEntriesBanner";
import { generateId } from "config/helpers";
import useExtractTimesheets from "../../api/useExtractTimesheets";
import { LoaderFull } from "common/Loader";
import {
	convertParse,
	validateExtraction
} from "modules/timesheets/utils/helper";

const AddTimesheetEntriesDrawerBody = ({
	disputedWorkTime,
	month,
	addedWorkTimes,
	showAmountInDrawer,
	timesheet,
	errorBanner,
	setErrorBanner,
	entriesErrors,
	showForm,
	setShowForm
}) => {
	const { entries, addFile, addEntries } = useEntries();
	const [showExplainCard, setShowExplainCard] = useState(true);
	const [extractedUrl, setExtractedUrl] = useState("");

	const onUpload = files => {
		setErrorBanner(null);
		setExtractedUrl(files[0].path);
		addFile(timesheet?.data?._id, { ...files[0], id: generateId() });
	};

	const { isLoading: isExtracting } = useExtractTimesheets(
		{
			timesheet_id: timesheet?.data?._id,
			timesheet_attachment: extractedUrl
		},
		{
			enabled: !!extractedUrl,
			onSuccess: res => {
				setExtractedUrl("");
				if (validateExtraction(res, setErrorBanner)) {
					const { worktimes = {}, expenses = {} } = res.data;
					if (res.data?.message) {
						setErrorBanner({
							type: "info",
							message: res.data.message
						});
					} else {
						setErrorBanner({
							type: "success",
							message: `Success! The list of entries has been updated. ${worktimes.length +
								expenses.length} new entries have been added.`
						});
					}

					if (expenses.length)
						addEntries(timesheet?.data?._id, convertParse(expenses, "expense"));

					if (worktimes.filter(el => !el.is_overtime).length)
						addEntries(
							timesheet?.data?._id,
							convertParse(
								worktimes.filter(el => !el.is_overtime),
								"worktime"
							)
						);

					if (worktimes.filter(el => el.is_overtime).length)
						addEntries(
							timesheet?.data?._id,
							convertParse(
								worktimes.filter(el => el.is_overtime),
								"overtime"
							)
						);
				}
			},
			onError: () => {
				setErrorBanner({
					type: "danger",
					message: `Something went wrong, please try again.`
				});
			}
		}
	);

	return (
		<div className={styles.container}>
			<LoaderFull loading={isExtracting} text={"File Extracting"} />

			{showExplainCard && (
				<AddTimesheetEntriesExplanatoryCard
					onClose={() => setShowExplainCard(false)}
				/>
			)}
			<AddTimesheetEntriesUploadCard
				isExtracting={isExtracting}
				onUpload={onUpload}
				files={entries[timesheet?.data?._id]?.files || []}
				timesheetId={timesheet?.data?._id}
			/>
			{errorBanner && (
				<AddTimesheetEntriesBanner
					type={errorBanner?.type}
					message={errorBanner?.message}
					onClose={() => setErrorBanner(null)}
				/>
			)}
			{entries[timesheet?.data?._id]?.worktimes.length > 0 && (
				<AddEntriesDrawerRowsCard
					icon={
						<ClockIcon className={styles.clockIcon} height={24} width={24} />
					}
					title="Worktimes"
					list={entries[timesheet?.data?._id].worktimes}
					showAmountInDrawer={showAmountInDrawer}
					timesheet={timesheet}
					entriesErrors={
						entriesErrors?.worktimes ? entriesErrors?.worktimes : {}
					}
				/>
			)}
			{entries[timesheet?.data?._id]?.overtimes.length > 0 && (
				<AddEntriesDrawerRowsCard
					icon={<ClockPlusIcon height={22} width={22} />}
					title="Overtimes"
					list={entries[timesheet?.data?._id].overtimes}
					showAmountInDrawer={showAmountInDrawer}
					timesheet={timesheet}
					entriesErrors={
						entriesErrors?.overtimes ? entriesErrors?.overtimes : {}
					}
				/>
			)}
			{entries[timesheet?.data?._id]?.expenses.length > 0 && (
				<AddEntriesDrawerRowsCard
					icon={<DocIcon className={styles.docIcon} height={22} width={22} />}
					title="Expenses"
					timesheet={timesheet}
					list={entries[timesheet?.data?._id].expenses}
					entriesErrors={entriesErrors?.expenses ? entriesErrors?.expenses : {}}
				/>
			)}
			{showForm && (
				<AddTimesheetEntryForm
					disputedWorkTime={disputedWorkTime}
					onSave={() => setShowForm(false)}
					onCancel={() => setShowForm(false)}
					month={month}
					addedWorkTimes={addedWorkTimes}
				/>
			)}
			{!showForm && (
				<Button
					text="Add entry"
					icon={<AddIcon height={24} width={24} className={styles.addIcon} />}
					variant="outlined"
					className={styles.addButton}
					onClick={() => setShowForm(true)}
				/>
			)}
		</div>
	);
};

export default AddTimesheetEntriesDrawerBody;
