import React from "react";

export default function ClearIcon({ fill = "#C1C1C1", width, height }) {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 272.4 270.6"
			width={width}
			height={height}
			style={{ background: "white" }}
		>
			<g>
				<path
					fill={fill}
					d="M136.2,2.8C63,2.8,3.7,62.1,3.7,135.3S63,267.8,136.2,267.8s132.5-59.3,132.5-132.5S209.4,2.8,136.2,2.8z
			 M185.7,172.2c3.5,3.5,3.4,9,0,12.5c-3.4,3.4-9,3.5-12.5,0L136.5,148l-36.7,36.7c-3.4,3.4-9,3.4-12.5,0c-3.4-3.4-3.4-9,0-12.5
			l36.7-36.7L87.3,98.8c-3.4-3.4-3.4-9,0-12.5c3.4-3.4,9-3.4,12.5,0l36.7,36.7l36.7-36.7c3.5-3.5,9-3.4,12.5,0c3.4,3.4,3.5,9,0,12.5
			L149,135.5L185.7,172.2z"
				/>
			</g>
		</svg>
	);
}
