import { useState, useEffect } from "react";

const useSessionCardDisplay = () => {
	const [settings, setSettings] = useState({
		timeSheets: false,
		extensions: true,
		permissions: true,
		history: true
	});

	useEffect(() => {
		const savedSettings = JSON.parse(
			sessionStorage.getItem("mission_details_setup")
		);
		if (savedSettings) setSettings(savedSettings);
	}, []);

	const toggle = key => {
		setSettings(prevSettings => {
			const newState = { ...prevSettings, [key]: !prevSettings[key] };
			sessionStorage.setItem("mission_details_setup", JSON.stringify(newState));
			return newState;
		});
	};

	return [settings, toggle];
};

export default useSessionCardDisplay;
