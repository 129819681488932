import React from "react";
import { get } from "lodash";
import Table from "modules/candidate/Profile/components/Table/Table.js";
import Actions from "../../components/actions";
import { formatDate } from "common/Functions";
import GlobalTooltip from "common/GlobalTooltip";
import s from "./my-contracts-list.module.scss";

const RenderText = ({ text, maxSize }) =>
	get(text, "length", 0) > maxSize ? (
		<GlobalTooltip
			overlayClassName={s.tooltip}
			placement="top"
			trigger="hover"
			overlay={text}
		>
			<span className={s.title}>{text.slice(0, maxSize) + "..."}</span>
		</GlobalTooltip>
	) : (
		<span className={s.title}>{text}</span>
	);

const MyContractsList = ({ list, columns, onDelete }) => {
	const dataFormatted = list.map(item => {
		return {
			channel_name: <RenderText text={item.product.title} maxSize={60} />,
			group: <RenderText text={item.group?.name || "Default"} maxSize={60} />,
			user: (
				<RenderText
					text={`${item.created_by.first_name} ${item.created_by.last_name}`}
					maxSize={30}
				/>
			),
			expiration_date: formatDate(item?.expiry_date || "---"),
			action: <Actions handleDelete={onDelete} contractId={item.contract_id} />
		};
	});

	return <Table data={dataFormatted} columns={columns} />;
};

export default MyContractsList;
