import React, { useState, useEffect } from "react";
import Select from "react-select";
import _get from "lodash/get";
import AxiosHelper from "config/axios-helper";
import { SelectWrapper, SelectOption } from "./Styled";
import { REMOVE_SEARCH_TEMPLATE_ENDPOINT } from "config/api-endpoints";
import DeleteTemplateModal from "./Modals/DeleteTemplateModal";
import { usePrevious } from "hooks";
import { searchParser, searchStringify } from "config/helpers";

const TemplateSelect = ({
	selectedTemplate,
	templates,
	selectTemplate,
	fetchTemplates,
	clearFilters,
	isClient = true
}) => {
	const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);
	const [templateToDelete, setTemplateToDelete] = useState({});
	const previousSelectedTemplate = usePrevious(selectedTemplate);

	useEffect(() => {
		if (
			!templates.length ||
			(previousSelectedTemplate &&
				previousSelectedTemplate === selectedTemplate)
		) {
			return;
		}

		const queryTemplate = searchParser(window.location.search.slice(1));
		let template = selectedTemplate;

		if ("template" in queryTemplate) {
			if (previousSelectedTemplate === undefined) {
				selectTemplate(templates.find(t => t._id === queryTemplate.template));
			} else if (template === undefined) {
				template = "";
			}
		}

		if (template !== undefined) {
			const templateId = _get(selectedTemplate, "_id", "");
			const search = searchStringify({ template: templateId });
			let newUrl = window.location.pathname;
			if (templateId) {
				newUrl += `?${search}`;
			}
			window.history.pushState(
				{
					path: newUrl
				},
				"",
				newUrl
			);
		}
	}, [selectedTemplate, templates]);

	const deleteTemplate = () => {
		const { data, isSelected } = templateToDelete;
		AxiosHelper.post({
			url: REMOVE_SEARCH_TEMPLATE_ENDPOINT,
			toastMessage: "Community  deleted!",
			isModal: true,
			data
		}).then(() => {
			fetchTemplates();
			isSelected && selectTemplate("");
			setDeleteTemplateModal(false);
		});
	};

	const onChange = (value = {}) => {
		selectTemplate(value);
		if (value === null) {
			clearFilters();
		}
	};

	let placeholder = isClient
		? "Select a vacancy or a community"
		: "Select a search template";
	let title = isClient ? "Community" : "Search template";

	return (
		<>
			<SelectWrapper>
				<div className="template-label" htmlFor="template">
					{title}
				</div>
				<Select
					name="template"
					value={selectedTemplate}
					options={templates}
					clearable={true}
					onChange={onChange}
					searchable={true}
					className="select-template-search"
					placeholder={placeholder}
					optionComponent={({ option, onSelect }) => {
						return (
							<SelectOption key={option._id}>
								<div
									className="Select-option"
									onClick={() => {
										onSelect(option);
									}}
								>
									{option.label}
									{!option.is_default && (
										<span
											className="icon-close"
											onClick={e => {
												e.stopPropagation();
												setTemplateToDelete({
													data: { id: _get(option, "_id", "") },
													isSelected:
														_get(selectedTemplate, "_id") ===
														_get(option, "_id")
												});
												setDeleteTemplateModal(true);
											}}
										/>
									)}
								</div>
							</SelectOption>
						);
					}}
				/>
			</SelectWrapper>
			{deleteTemplateModal && (
				<DeleteTemplateModal
					closeModal={() => setDeleteTemplateModal(false)}
					deleteTemplate={deleteTemplate}
					isClient={isClient}
				/>
			)}
		</>
	);
};

export default TemplateSelect;
