import React from "react";
import { browserHistory } from "react-router";
import {
	TableWrapper,
	Thead,
	Trthead,
	Th,
	SupplierList,
	SuppliersBody,
	Tr,
	Td
} from "./styles";
import CurrencyFormatter from "common/CurrencyFormater";
import EmptyComponent from "common/EmptyComponent";
import { MAX_SUPPLIER_DISPLAY } from "config";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { formatDate } from "common/Functions";
import { Box, Flex } from "rebass";

const TableSpend = ({
	supplier,
	setSupplierData,
	jobsPerformanceData,
	isFetching
}) => {
	const suppliersValue = ["all", "tier 1", "tier 2", "tier 3"];
	const hasSupplier = !(suppliersValue.indexOf(supplier?.value) !== -1);

	if (jobsPerformanceData.length === 0 && !isFetching)
		return <EmptyComponent title={`No data found`} />;

	return (
		<TableWrapper noScroll>
			<SupplierList>
				<Thead>
					<Trthead>
						<Th isNotNumber width={hasSupplier ? "13%" : "20%"}>
							{hasSupplier ? "MISSION TITLE" : "SUPPLIER"}
						</Th>
						{hasSupplier ? (
							<React.Fragment>
								<Th isNotNumber width="13%">
									CONSULTANT
								</Th>{" "}
								<Th isNotNumber width="13%">
									TIMESHEET MANAGER
								</Th>{" "}
								<Th isNotNumber width="13%">
									DEPARTMENT
								</Th>{" "}
								<Th width="10%">COST</Th>{" "}
								<Th isNotNumber width="13%">
									START & END DATE
								</Th>
								<Th isNotNumber width="7%">
									ONBOARDED
								</Th>
							</React.Fragment>
						) : (
							<React.Fragment>
								{" "}
								<Th width="20%">REQUESTS FILLED</Th>
								<Th width="20%">ONBOARDED MISSIONS</Th>
								<Th width="20%">TOTAL OF MISSIONS</Th>
							</React.Fragment>
						)}

						<Th width="18%">TOTAL SPENT</Th>
					</Trthead>
				</Thead>
			</SupplierList>
			<SuppliersBody
				style={{
					overflow:
						jobsPerformanceData.length <= MAX_SUPPLIER_DISPLAY && "unset"
				}}
				scroll={jobsPerformanceData.length > MAX_SUPPLIER_DISPLAY}
			>
				<SupplierList>
					<tbody>
						{isFetching
							? new Array(8).fill(
									<Tr>
										<Td isNotNumber={true} width={hasSupplier ? "15%" : "20%"}>
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={110} height={16} />
											</SkeletonTheme>
										</Td>

										<Td
											isNotNumber={hasSupplier}
											width={hasSupplier ? "13%" : "20%"}
										>
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={25} height={16} />
											</SkeletonTheme>
										</Td>
										{hasSupplier && (
											<React.Fragment>
												<Td isNotNumber width="13%">
													{" "}
													<SkeletonTheme
														style={{ borderRadius: 10 }}
														color="#DFE3E8"
													>
														<Skeleton width={25} height={16} />
													</SkeletonTheme>
												</Td>
												<Td isNotNumber width="13%">
													{" "}
													<SkeletonTheme
														style={{ borderRadius: 10 }}
														color="#DFE3E8"
													>
														<Skeleton width={25} height={16} />
													</SkeletonTheme>
												</Td>
												<Td isNotNumber width="7%">
													{" "}
													<SkeletonTheme
														style={{ borderRadius: 10 }}
														color="#DFE3E8"
													>
														<Skeleton width={25} height={16} />
													</SkeletonTheme>
												</Td>
											</React.Fragment>
										)}

										<Td width={hasSupplier ? "10%" : "20%"}>
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={25} height={16} />
											</SkeletonTheme>
										</Td>
										<Td
											isNotNumber={hasSupplier}
											width={hasSupplier ? "15%" : "20%"}
										>
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={!hasSupplier ? 25 : 80} height={16} />
											</SkeletonTheme>
										</Td>
										<Td width="18%">
											<SkeletonTheme
												style={{ borderRadius: 10 }}
												color="#DFE3E8"
											>
												<Skeleton width={25} height={16} />
											</SkeletonTheme>
										</Td>
									</Tr>
							  )
							: jobsPerformanceData.map(d => {
									return (
										<Tr key={d._id}>
											<Td
												isNotNumber={true}
												onClick={
													hasSupplier
														? () =>
																browserHistory.push(
																	`/missions/${d._id}?history=%2Fsuppliers-management&hash=spend`
																)
														: () =>
																setSupplierData({ label: d.name, value: d._id })
												}
												width={hasSupplier ? "13%" : "20%"}
											>
												{d.name}
											</Td>
											{hasSupplier ? (
												<React.Fragment>
													<Td
														onClick={() =>
															browserHistory.push(
																"/user/profile/" +
																	d.bid_id +
																	"?from=%2Fsuppliers-management#spend"
															)
														}
														isNotNumber
														width="13%"
													>
														{" "}
														{d.consultant?.full_name}{" "}
													</Td>{" "}
													<Td isNotNumber width="13%">
														{" "}
														{d.timesheet_manager}{" "}
													</Td>{" "}
													<Td isNotNumber width="13%">
														{d.group}
													</Td>{" "}
													<Td width="10%">
														<CurrencyFormatter cent={d.cost} />
													</Td>{" "}
													<Td isNotNumber width="13%">
														{" "}
														{formatDate(d.start_date)} -{" "}
														{formatDate(d.end_date)}{" "}
													</Td>{" "}
													<Td isNotNumber width="7%">
														<Flex
															alignItems="center"
															justifyContent="space-between"
														>
															{d.onboarded ? "YES" : "NO"}

															<Box
																sx={{
																	width: "10px",
																	height: "10px",
																	borderRadius: "50%",
																	background: d.onboarded
																		? "#60CFAC"
																		: "#F62F2F"
																}}
															></Box>
														</Flex>
													</Td>{" "}
												</React.Fragment>
											) : (
												<React.Fragment>
													<Td width="20%">{d.num_request_filled}</Td>
													<Td width="20%">{d.num_onboarded_missions}</Td>
													<Td width="20%">{d.num_missions}</Td>
												</React.Fragment>
											)}

											<Td width="18%">
												<CurrencyFormatter cent={d.total_spent_to_date} />
											</Td>
										</Tr>
									);
							  })}
					</tbody>
				</SupplierList>
			</SuppliersBody>
		</TableWrapper>
	);
};

export default TableSpend;
