import { renderError } from "config/helpers";

import { DRAFT_EMAIL } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

function deleteDraft(body) {
	return client(DRAFT_EMAIL, { body, method: "delete" });
}

const useDeleteDraft = options => {
	return useMutation(deleteDraft, {
		...options,
		onError: renderError
	});
};

export default useDeleteDraft;
