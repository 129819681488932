import React from "react";
import Status from "common/Table/Status";
import { VACANCY_STATUS } from "config";
import _get from "lodash/get";
import _find from "lodash/find";
import { Flex } from "rebass";

const RenderStatus = data => {
	return (
		<Flex
			sx={{
				cursor: "pointer",
				width: "100%",
				height: "100%",
				alignItems: "center"
			}}
		>
			<Status status={_get(data, "data")} />
			{_get(
				_find(VACANCY_STATUS, status => data.data === status.value),
				"label",
				""
			)}
		</Flex>
	);
};

export default RenderStatus;
