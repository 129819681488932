import React, { PureComponent } from "react";
import { Field, reduxForm, change } from "redux-form";
import { browserHistory } from "react-router";
import _get from "lodash/get";
import CurrencyField from "common/NewCurrencyField";
import DatePickerField from "common/DatePickerField";
import RichEditor from "common/RichEditor/RichEditor";
import {
	MOTIVATION_MAX_LENGTH,
	JOB_REQUEST_RICHEDITOR_BLOCKSTYLES,
	JOB_REQUEST_RICHEDITOR_INLINESTYLES,
	MAX_CURRENCY_VALUE,
	NEW_LINES_REGEX,
	DAILY,
	CURRENCY_SUFFIX
} from "config";
import { removeMarkdown } from "config/helpers";
import MenuWithCTA from "common/MenuWithCTA";
import { CTA, CTAIcon } from "modules/job/components/jobprofile/RequestProfile";
import FormField from "common/FormField";
import { ReactComponent as InfoIcon } from "static/icons/info_icon_red.svg";
import { Label } from "./styles";

class FirstStep extends PureComponent {
	componentDidMount() {
		const {
			bid,
			isConsultancyDirector,
			businessManagerUnit = {},
			initializeReduxForm,
			isConsultancy
		} = this.props;
		let data = {};
		if (bid) {
			data = { ...bid };
		}
		if (isConsultancy && !isConsultancyDirector) {
			data.business_unit = businessManagerUnit.name;
		}
		if (!data.available_date) data.available_date = window.moment();

		initializeReduxForm("propose-bid", data);
	}

	componentDidUpdate() {
		const {
			isConsultancyDirector,
			businessManagerUnit,
			isConsultancy
		} = this.props;
		if (isConsultancy && !isConsultancyDirector) {
			this.props.getConsultants(businessManagerUnit.id);
		}
	}

	componentWillUnmount() {
		this.props.setConsultants([]);
	}

	closeAndRedirect = redirectURL => {
		this.props.closeProposal();
		browserHistory.push(redirectURL);
	};

	getCTA = ({ isConsultants = false } = {}) => {
		const redirectURL = isConsultants
			? "/my-consultants#consultants"
			: "/my-consultants";
		return (
			<CTA
				size={11}
				small
				large
				onClick={() => this.closeAndRedirect(redirectURL)}
			>
				{isConsultants ? "Create a consultant" : "Create a business unit"}
				<CTAIcon className="icon-newtab-arrow" />
			</CTA>
		);
	};

	handleBusinessUnitChange = unit => {
		this.props.dispatch(change("propose-bid", "consultant", ""));
		this.props.getConsultants(unit.value);
	};

	render() {
		const {
			closeProposal,
			goToSecondStep,
			handleSubmit,
			isConsultancy,
			isConsultancyDirector,
			businessUnits,
			consultants,
			reportingSetting
		} = this.props;

		const RateLabel = (
			<Label>
				<span>{`${_get(reportingSetting, "rate_type")} rate`}</span>
				{_get(reportingSetting, "rate_type") === DAILY && (
					<span className="info">
						<InfoIcon />{" "}
						{`${_get(reportingSetting, "hours_representation").replace(
							":",
							"h"
						)} of work time per day`}
					</span>
				)}
			</Label>
		);
		return (
			<div>
				<div className="body">
					{isConsultancy && (
						<div className="row">
							<div className="col-md-5">
								<Field
									clearable={false}
									component={isConsultancyDirector ? MenuWithCTA : FormField}
									disabled={!isConsultancyDirector}
									name="business_unit"
									label={"Business unit"}
									placeholder="Select a business unit"
									emptyText="You have no business units yet"
									options={businessUnits}
									onChange={this.handleBusinessUnitChange}
									position={false}
									searchable={false}
									cta={this.getCTA}
								/>
							</div>
							<div className="col-md-5 col-md-offset-1">
								<Field
									clearable={false}
									component={MenuWithCTA}
									name="consultant"
									label={"Consultant"}
									placeholder="Select a consultant"
									emptyText="You have no consultant yet"
									options={consultants}
									position={false}
									searchable={false}
									cta={() => this.getCTA({ isConsultants: true })}
								/>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-md-5">
							<Field
								name="proposed_amount"
								label={RateLabel}
								suffix={
									_get(reportingSetting, "currency.symbol") +
									CURRENCY_SUFFIX[_get(reportingSetting, "rate_type")]
								}
								component={CurrencyField}
							/>
						</div>
						<div className="col-md-5 col-md-offset-1">
							<Field
								classes="inner-icon left-icon"
								icon="fa fa-calendar"
								name="available_date"
								label="Available from"
								value={window.moment().format("DD/MM/YYYY")}
								isMakeABid={true}
								minDate={window.moment().toDate()}
								component={DatePickerField}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-11 form-group textarea">
							<Field
								name="description"
								id="description"
								label="Motivation"
								type="textarea"
								className="col-md-11"
								component={RichEditor}
								maxLength={MOTIVATION_MAX_LENGTH}
								placeholder={
									isConsultancy
										? "Briefly explain why your consultant is the best candidate for this mission."
										: "Briefly explain why you are the best candidate for this job."
								}
								blockStylesTypes={JOB_REQUEST_RICHEDITOR_BLOCKSTYLES}
								inlineStylesTypes={JOB_REQUEST_RICHEDITOR_INLINESTYLES}
							/>
						</div>
					</div>
				</div>
				<div className="actions">
					<div className="row">
						<div className="col-md-3">
							<button
								className="btn btn-outline-new btn-block"
								onClick={closeProposal}
							>
								Cancel
							</button>
						</div>
						<div className="col-md-4">
							<button
								className="btn btn-primary-new btn-block"
								onClick={handleSubmit(goToSecondStep)}
							>
								Next
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const validate = formProps => {
	let description = removeMarkdown(formProps.description || "");
	description = description.replace(NEW_LINES_REGEX, "");
	const errors = {};
	if (!formProps.business_unit) {
		errors.business_unit = "Business unit is required.";
	}
	if (!formProps.consultant) {
		errors.consultant = "Consultant is required.";
	}
	if (!formProps.proposed_amount || formProps.proposed_amount === "") {
		errors.proposed_amount = "Please specify your hourly rate.";
	}
	if (!formProps.available_date) {
		errors.available_date = "Please specify your availability.";
	}
	if (!description || description.trim() === "") {
		errors.description = "Please provide a brief motivational statement.";
	}
	if (description && description.length > MOTIVATION_MAX_LENGTH) {
		errors.description = `This field should not exceed ${MOTIVATION_MAX_LENGTH} characters`;
	}
	if (
		formProps.proposed_amount &&
		(formProps.proposed_amount <= 0 ||
			formProps.proposed_amount > MAX_CURRENCY_VALUE)
	) {
		errors.proposed_amount = "The hourly rate should be between 1€ and 1000€";
	}
	return errors;
};

export default reduxForm({
	form: "propose-bid",
	validate,
	touchOnBlur: false
})(FirstStep);
