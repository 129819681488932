import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Flex, Box, Text } from "rebass";
import _get from "lodash/get";
import { useMutation } from "react-query";
import { v4 as uuid4 } from "uuid";
import { browserHistory } from "react-router";

import Modal from "common/modal";
import { LoginFormContainer } from "./Styled";
import LoginForm from "modules/user/components/LoginForm";
import { authenticatedUserSelector } from "modules/bids/selectors";
import { client } from "lib/api-client";
import { APPLY_TO_VACANCY_ENDPOINT } from "config/api-endpoints";
import { LOADING, OFFLINE_JOB_BOARD, REPORT_ERROR } from "config";
import { ReactComponent } from "../icons/empty-logo-company.svg";
import { JobHeader } from "./Styled";
import ModalUI from "modules/help/components/ModalUI";
import {
	showGetHelpSelector,
	showHelpModalSelector,
	helpModalFieldsSelector
} from "modules/app/selectors";

const applyToJob = data => client(APPLY_TO_VACANCY_ENDPOINT, { body: data });
const id = uuid4();

const SignInModal = ({
	onClose,
	user,
	vacancy_id,
	templateId,
	newEmail,
	vonqData,
	data,
	errorForm,
	fields
}) => {
	const redirectToApplication = id => {
		return browserHistory.push(`${getJobUrl(id)}`);
	};

	const [showReportModal, setShowReportModal] = useState(false);
	const [mutate, { status }] = useMutation(applyToJob, {
		onSuccess: () => redirectToApplication(id),
		onError: error => {
			if (_get(error, "detail.name")) {
				const applicationId = error.detail.name.split("_")[1];
				if (applicationId) {
					redirectToApplication(applicationId);
				} else {
					onClose();
				}
			}
		}
	});
	useEffect(() => {
		if (_get(user, "access_token")) {
			handleApplyToJob();
		}
	}, [user]);

	const getJobUrl = poke_id => {
		let link = `/pokes/${poke_id}`;
		if (templateId) {
			link += `?template=${templateId}`;
		}
		link += `?search=welcome-back`;

		return link;
	};

	const handleApplyToJob = async () => {
		await mutate({
			vacancy_id,
			id,
			tag: OFFLINE_JOB_BOARD,
			source_id: _get(vonqData, "source_id"),
			campaign_id: _get(vonqData, "campaign_id")
		});
	};

	return (
		<>
			<Modal
				fixed={true}
				onClose={onClose}
				modalName={"signin-apply"}
				loading={status === LOADING}
				style={{ padding: "20px 0" }}
			>
				<Text
					pl={30}
					mb={18}
					sx={{
						fontFamily: "BasierCircle",
						fontSize: "18px",
						fontWeight: "600",
						letterSpacing: "0.09px",
						color: "rgb(39, 50, 56)"
					}}
				>
					Job Application
				</Text>
				{errorForm ? (
					<Flex
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						mb={33}
						mt={33}
					>
						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "21px",
								fontWeight: "600",
								letterSpacing: "0.11px",
								color: "rgb(193, 69, 69)"
							}}
						>
							Email Already used!
						</Text>

						<Text
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								fontWeight: "normal",
								letterSpacing: "0.08px",
								color: "rgb(193, 69, 69)"
							}}
						>
							An account for this email already exists. Either{" "}
							<Text as={"strong"}>Log in</Text> below or{" "}
							<Text
								as={"span"}
								sx={{
									fontWeight: "bold",
									cursor: "pointer",
									textDecoration: "underline"
								}}
								onClick={() => setShowReportModal(true)}
							>
								claim this email.
							</Text>
							<br />
							You can also close this pop-up and apply with a different email
							address.
						</Text>
					</Flex>
				) : null}
				<Box
					mb={30}
					sx={{
						padding: "30px",
						backgroundColor: "rgba(229, 232, 236, 0.4)"
					}}
				>
					<Flex
						sx={{
							padding: "20px",
							borderRadius: "4px",
							boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
							backgroundColor: "rgb(255, 255, 255)"
						}}
					>
						<Box>
							<JobHeader>
								{_get(data, "company.avatar") ? (
									<>
										<div className="logo">
											<img src={_get(data, "company.avatar")} />
										</div>
									</>
								) : (
									<div className="logo-placeholder">
										<ReactComponent />
									</div>
								)}
							</JobHeader>
						</Box>
						<Box>
							<Flex flexDirection="column">
								<Text
									as={"h1"}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "18px",
										fontWeight: "600",
										lineHeight: "1.28",
										letterSpacing: "0.15px",
										color: "rgb(37, 55, 88)"
									}}
								>
									{_get(data, "title")}
								</Text>
								<Text as={"h2"} sx={{ fontSize: "14px" }} mt={10}>
									<Text
										as={"span"}
										mr={1}
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "16px",
											lineHeight: "1.06",
											color: "#1b9f94"
										}}
									>
										{_get(data, "group.name")}
									</Text>
									<Text
										sx={{
											fontFamily: "BasierCircle",
											fontSize: "16px",
											lineHeight: "1.06",
											color: "#a0a5b9"
										}}
										as={"span"}
									>
										<Text
											as={"span"}
											sx={{
												textTransform: "capitalize",
												display: "inline-block"
											}}
										>
											{_get(data, "employment_type")}
										</Text>{" "}
										· {_get(data, "city")}
									</Text>
								</Text>
							</Flex>
						</Box>
					</Flex>
				</Box>
				{/* <DescriptionText>
				For the position of <span className="infos">{`"${vacancyTitle}"`}</span>{" "}
				at <span className="infos">{`"${companyName}"`} </span>in{" "}
				<span className="infos"> {`"${city}"`}</span>. The email address you
				submitted is already linked to a wiggli account.
				<div className="bold">Log in now to complete your application!</div>
			</DescriptionText> */}
				<Flex
					flexDirection="column"
					sx={{
						width: "545px",
						margin: "0 auto"
					}}
				>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontSize: "18px",
							fontWeight: "600",
							letterSpacing: "0.09px",
							color: "rgb(97, 105, 118)",
							textAlign: "center"
						}}
					>
						Log in now to complete your application!
					</Text>
					<LoginFormContainer>
						<LoginForm
							shouldRedirect={false}
							newEmail={newEmail}
							styleGreen={true}
						/>
					</LoginFormContainer>
				</Flex>
			</Modal>
			{showReportModal ? (
				<ModalUI
					fields={fields}
					user={user}
					// type={showHelpModal}
					type={REPORT_ERROR}
					onClose={() => setShowReportModal(false)}
				/>
			) : null}
		</>
	);
};

const mapStateToProps = state => ({
	user: authenticatedUserSelector(state),
	showGetHelp: showGetHelpSelector(state),
	showHelpModal: showHelpModalSelector(state),
	fields: helpModalFieldsSelector(state)
});

export default connect(mapStateToProps)(SignInModal);
