import React, { useEffect } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/authActions";
import { useResetHomePageStores } from "modules/home/config/helpers.js";
import useNotesStore from "modules/notes/notes.store";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { queryCache } from "react-query";
import { removeTemplateFromLocalStorage as removeEvaluationTemplateFromLocalStorage } from "modules/EvaluationFormsSettings/utils/helpers";
import useGetFiles from "common/AddCandidateDrawer/hooks/useGetFiles";
import { SINGLE_CANDIDATE_TAB_ID } from "common/AddCandidateDrawer/utils/constants";
import { deleteCookie } from "config/helpers";
import { COOKIE_TOKEN, COOKIE_COMPANY } from "config";
import { useVacancyFilesStore } from "modules/vacancy-view/components/Files/hooks/useVacancyFilesStore";

export function getLogoutRoute() {
	return "/logout";
}
const Logout = props => {
	const [resetHomeStores] = useResetHomePageStores();
	const { clearStore: clearNotesStore } = useNotesStore(({ clearStore }) => ({
		clearStore
	}));
	const { resetFilters, currentFilterId } = useFilters();
	const { clearSingleCandidate, clearFiles, setActiveTab } = useGetFiles();

	deleteCookie(COOKIE_COMPANY);
	deleteCookie(COOKIE_TOKEN);

	const { clearVacancyFilesStore } = useVacancyFilesStore();
	//TODO: clear all filters
	props.logout(true);
	useEffect(() => {
		clearVacancyFilesStore();
		resetHomeStores();
		clearNotesStore();
		removeEvaluationTemplateFromLocalStorage();
		clearSingleCandidate();
		clearFiles();
		setActiveTab(SINGLE_CANDIDATE_TAB_ID);
		if (currentFilterId !== -1) {
			resetFilters();
		}
		queryCache.clear();
	}, []);
	return <div />;
};

const mapDispatchToProps = dispatch => ({
	logout: useApi => dispatch(logout(useApi))
});
// this allows to check if user agreed to logout
export default connect(null, mapDispatchToProps)(Logout);
