import { usePaginatedQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_LIST_CHANNELS } from "config/api-endpoints";

const getChannels = (_key, body) =>
	client(GET_LIST_CHANNELS, {
		body
	});

const useGetChannels = (options, queryOptions = {}) =>
	usePaginatedQuery(["@VonqContract/getChannels", options], getChannels, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default useGetChannels;
