import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_PROFILE } from "config/api-endpoints";

function deletProfile(body) {
	return client(DELETE_PROFILE, {
		body
	});
}

function useDeleteProfile(options) {
	return useMutation(deletProfile, options);
}

export default useDeleteProfile;
