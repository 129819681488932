import React, { Children, cloneElement, useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { ReactComponent as CloseIcon } from "static/icons/elements-icons-close.svg";

import { Box, Flex, Text } from "rebass";
import DefaultButton from "common/Buttons";

const MenuList = ({ setOpenMenu, openMenu, onClose, children, onChange }) => {
	const containerRef = useRef(null);

	useOnClickOutside(containerRef, () => {
		setOpenMenu(false);
	});

	return (
		openMenu && (
			<Box
				p={2}
				sx={{
					borderRadius: "4px",

					boxShadow: "0 6px 8px 8px rgba(0, 0, 0, 0.04)",

					backgroundColor: "#ffffff",
					position: "absolute",
					zIndex: 9
				}}
				minWidth="300px"
				maxHeight="500px"
				ref={containerRef}
			>
				<Flex py={2} justifyContent="space-between">
					<Text sx={{ fontSize: "14px", fontWeight: 600, color: "#212b36" }}>
						Profile viewers
					</Text>
					<DefaultButton
						onClick={() => setOpenMenu(false)}
						padding="0px"
						backgroundColor="white"
					>
						<CloseIcon />
					</DefaultButton>
				</Flex>

				{Children.map(children, element =>
					cloneElement(element, { onClose, onChange })
				)}
			</Box>
		)
	);
};

export default MenuList;
