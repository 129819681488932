import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { FieldDateSelect, StartDate, EndDate } from "./Styled";
import Picker from "./Picker";
import { DATE_FORMAT } from "config/index";

function DatePicker({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	pickerRef,
	displayPicker,
	setDisplayPicker,
	togglePicker
}) {
	const dateSelectRef = useRef();
	const [activePreset, activePresetSet] = useState("current_month");

	const presets = [
		{
			label: "All Times",
			value: "all_times",
			run: () => {
				setStartDate(null);
				setEndDate(null);
			}
		},
		{
			label: "Current year",
			value: "current_year",
			run: () => {
				setStartDate(window.moment().startOf("year"));

				setEndDate(window.moment().endOf("year"));
			}
		},

		{
			label: "Last Year",
			value: "last_year",
			run: () => {
				setStartDate(
					window
						.moment()
						.subtract(1, "year")
						.startOf("year")
						.clone()
				);
				setEndDate(
					window
						.moment()
						.subtract(1, "year")
						.endOf("year")
						.set({
							hour: 23,
							minute: 59
						})
				);
			}
		},

		{
			label: "Current Quarter",
			value: "current_quarter",
			run: () => {
				setStartDate(window.moment().startOf("quarter"));
				setEndDate(
					window
						.moment()
						.endOf("quarter")
						.set({
							hour: 23,
							minute: 59
						})
				);
			}
		},
		{
			label: "Last Quarter",
			value: "last_quarter",
			run: () => {
				setStartDate(
					window
						.moment()
						.subtract(1, "quarter")
						.startOf("quarter")
						.clone()
				);
				setEndDate(
					window
						.moment()
						.subtract(1, "quarter")
						.endOf("quarter")
						.set({
							hour: 23,
							minute: 59
						})
				);
			}
		},
		{
			label: "Current Month",
			value: "current_month",
			run: () => {
				setStartDate(window.moment().startOf("month"));
				setEndDate(
					window
						.moment()
						.endOf("month")
						.set({
							hour: 23,
							minute: 59
						})
				);
			}
		},
		{
			label: "Last Month",
			value: "last_month",
			run: () => {
				setStartDate(
					window
						.moment()
						.subtract(1, "month")
						.startOf("month")
						.clone()
				);
				setEndDate(
					window
						.moment()
						.subtract(1, "month")
						.endOf("month")
						.set({
							hour: 23,
							minute: 59
						})
				);
			}
		}
	];

	return (
		<div className="date-picker-acl">
			<FieldDateSelect ref={dateSelectRef} onClick={togglePicker}>
				<div className="date-select">
					<StartDate>
						<div className="title">Start date</div>
						{activePreset === "all_times"
							? "All Times"
							: startDate.format(DATE_FORMAT)}
					</StartDate>
					<span className="icon-arrow-right" />
					<EndDate>
						<div className="title">End date</div>
						{activePreset === "all_times"
							? "All Times"
							: endDate.format(DATE_FORMAT)}
					</EndDate>
					<span className="icon-down-arrow" />
				</div>
			</FieldDateSelect>
			{displayPicker && (
				<Picker
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					onClose={() => setDisplayPicker(false)}
					presets={presets}
					activePreset={activePreset}
					setActivePreset={activePresetSet}
					togglePicker={togglePicker}
					pickerRef={pickerRef}
				/>
			)}
		</div>
	);
}

DatePicker.propTypes = {
	userId: PropTypes.string,
	fetchShortlistedCandidate: PropTypes.func
};

export default DatePicker;
