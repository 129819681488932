import React from "react";
import ButtonGroup from "common/ButtonGroup/ButtonGroup";
import styles from "./send-request-drawer-footer.module.scss";
import { ReactComponent as SendIcon } from "static/icons/send-icon-white.svg";

const SendRequestDrawerFooter = ({ onClose, onSend, sendDisabled }) => {
	const buttons = [
		{
			variant: "contained",
			text: "Send",
			onClick: onSend,
			icon: <SendIcon height="1.125rem" width="1.125rem" />,
			className: sendDisabled ? styles.sendButton : ""
		},
		{
			variant: "outlined",
			text: "Cancel",
			onClick: onClose,
			className: styles.cancelButton,
			textClassName: styles.cancelButtonText
		}
	];

	return (
		<div className={styles.container}>
			<ButtonGroup gap=".5rem" buttons={buttons} />
		</div>
	);
};

export default SendRequestDrawerFooter;
