import _get from "lodash/get";
import { emailRegex } from ".";

export const isRequired = value => {
	if (!value || value.trim() === "") {
		return "This field is required.";
	}
};

export const isEmail = value => {
	if (emailRegex && !emailRegex.test(value)) {
		return "Email is invalid.";
	}
};

export const isValidPhoneNumber = phone => {
	if (!phone || !_get(phone, "value.length")) {
		return "Please enter your phone number.";
	}
};
