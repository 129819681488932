import React from "react";
import styles from "./questionnare-form-multiple-choice.module.scss";
import { QuestionnaireFormLabel } from "../QuestionnaireFormLabel";
import cx from "classnames";

export default function QuestionnaireFormMultipleChoice({
	description,
	field,
	options,
	onChange,
	isReadOnly = false,
	value = [],
	is_required: isRequired,
	className
}) {
	const handleChange = optionValue => {
		if (!onChange) return () => {};
		return () => {
			const newValue = value.includes(optionValue)
				? value.filter(v => v !== optionValue)
				: [...value, optionValue];

			onChange(newValue);
		};
	};
	return (
		<div className={styles.container}>
			<QuestionnaireFormLabel
				isRequired={isRequired}
				description={description}
				className={className}
			/>
			<div className={styles.questionnaireContainer}>
				{options.map(option => (
					<label
						className={cx(styles.checkboxContainer, {
							[styles.isReadOnly]: isReadOnly
						})}
						key={`${field}_${option}_checkbox`}
					>
						<input
							type="checkbox"
							checked={value.includes(option)}
							onChange={handleChange(option)}
							disabled={isReadOnly}
						/>
						<span className={styles.checkmark}></span>
						<span htmlFor={field} className={styles.label}>
							{option}
						</span>
					</label>
				))}
			</div>
		</div>
	);
}
