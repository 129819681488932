import { OPERATORS } from "common/FilterDrawer/utils";
import { CustomInput } from "common/CustomInput";
import { Picker } from "common/Picker";
import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { EvaluationsBooleanSelect } from "../EvaluationsBooleanSelect/EvaluationsBooleanSelect";
import { EvaluationsInput } from "../EvaluationsInput/EvaluationsInput";
import { PickerWithOneValue } from "../PickerWithOneValue/PickerWithOneValue";

export const EVALUATIONS_MAX_SELECT = 24;

export const EVALUATIONS_PAGE_SIZES = [50, 24, 12];

export const EVALUATIONS_FILTER_ID = "PROFILE_EVALUATIONS_FILTER_ID";

export const EVALUATIONS_MODULE_ID = "PROFILE_EVALUATIONS_MODULE_ID";

export const API_CONTRACT_DATA_TYPE = "";

export const EVALUATIONS_PAGE_SIZE = 5;

export const TYPES = {
	text: "text",
	number: "number",
	array: "array",
	date: "date",
	customMultiSelect: "customMultiSelect",
	booleanCustomDropDown: "booleanCustomDropDown"
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.number]: [OPERATORS.eq, OPERATORS.lessThan, OPERATORS.greaterThan],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[TYPES.customMultiSelect]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.booleanCustomDropDown]: [OPERATORS.is, OPERATORS.isNot]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.number]: {
		default: EvaluationsInput,
		[OPERATORS.isEmpty.value]: EvaluationsBooleanSelect
	},
	[TYPES.array]: {
		default: Picker
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	},
	[TYPES.customMultiSelect]: {
		default: Picker,
		[OPERATORS.is.value]: PickerWithOneValue
	},
	[TYPES.booleanCustomDropDown]: {
		default: EvaluationsBooleanSelect
	}
};
