import { createSelector } from "reselect";
import _get from "lodash/get";
const getIdentityStatus = state =>
	_get(state, "auth.user.company_identity_status");

export const identityStatusSelector = createSelector(
	getIdentityStatus,
	status => status
);

const getCompanyInformationStatus = state =>
	_get(state, "auth.user.company_information_status", "");

export const companyInformationStatusSelector = createSelector(
	getCompanyInformationStatus,
	status => status
);

const getIdentityStep = state => _get(state, "auth.user.identity_step", "");

export const identityStepSelector = createSelector(
	getIdentityStep,
	step => step
);
const getIdentityModal = state => state.jobView.identityModal;

export const identityModalSelector = createSelector(
	getIdentityModal,
	modal => modal
);

const getJobView = state => _get(state, "jobView");

export const jobViewSelector = createSelector(getJobView, jobView => jobView);
