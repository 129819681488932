import React from "react";
import classnames from "./list-menu.module.scss";
import { ReactComponent as ArrowRight } from "static/icons/arrow-right-list-filter-icon.svg";

const MenuLabel = ({ value, clickHandler }) => {
	return (
		<button onClick={clickHandler} className={classnames.menuLabelContainer}>
			<div className={classnames.menuLabel}>{value} </div>
			<ArrowRight className={classnames.icon} />
		</button>
	);
};

export default MenuLabel;
