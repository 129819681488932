import React from "react";
import { StatusCircle } from "./Styled";
import { colors } from "config/styles";

import {
	APPLIED_LABEL,
	POKE_EXPIRED_LABEL,
	INTEREST_CONFIRMED_LABEL,
	APPLICATION_REJECTED_LABEL,
	POKE_CANCELLED_LABEL,
	APPLICATION_CANCELLED_LABEL,
	INTERVIEW_DONE_LABEL,
	INTERVIEW_PLANNED_LABEL,
	HIRED_LABEL,
	SHORTLISTED_LABEL,
	REMOVED_FROM_SHORTLIST_LABEL,
	INTEREST_DECLINED_LABEL,
	PENDING_FEEDBACK_LABEL,
	INTERVIEW_DECLINED_LABEL,
	CONFIRMED,
	HIRED,
	ACCEPTED,
	LIVE,
	PUBLISHED,
	EXPIRED,
	DECLINED,
	FILLED,
	SENT,
	DRAFT,
	SIGNED,
	CANCELLED,
	REFUSED,
	REJECTED,
	DISPUTED,
	IN_MEDIATION,
	DONE,
	SUBMITTED,
	ONBOARDED,
	UNPUBLISHED,
	DELETED,
	EXTENDED,
	INTERRUPTED,
	POKE_DECLINED,
	SHORTLISTED,
	INTERVIEW_DECLINED,
	FINISHED,
	APPROVED,
	OPEN_FOR_INTERNAL_USE,
	CLOSED_TO_NEW_APPLICANTS,
	APPLIED,
	APPLY_CANCELLED,
	PROPOSITION_ACCEPTED_LABEL,
	PROPOSITION_CANCELED_LABEL,
	NEW_BID_LABEL,
	PROPOSITION_SENT_LABEL,
	NEW_TERMS_PROPOSED_LABEL,
	INTERVIEW_OVERDUE_LABEL,
	INTERVIEW_SENT_LABEL,
	ONLINE,
	IN_PROGRESS,
	OFFLINE
} from "config";
import { IN_REVIEW } from "config";

const statusColors = {
	[APPLIED_LABEL]: "#95cce8",
	[POKE_EXPIRED_LABEL]: "#d1d8dc",
	[HIRED_LABEL]: "#36ba90",
	[INTEREST_CONFIRMED_LABEL]: "#1a91ce",
	[APPLICATION_REJECTED_LABEL]: "#d2d7db",
	[INTEREST_DECLINED_LABEL]: "#ffbf64",
	[POKE_CANCELLED_LABEL]: "#ffbf64",
	[APPLICATION_CANCELLED_LABEL]: "#ffbf64",
	[INTERVIEW_DONE_LABEL]: "#00ba7d",
	[INTERVIEW_PLANNED_LABEL]: "#36ba90",
	[INTERVIEW_SENT_LABEL]: "#ffbd69",
	[SHORTLISTED_LABEL]: "#00ba7d",
	[REMOVED_FROM_SHORTLIST_LABEL]: "#f75c5f",
	[PENDING_FEEDBACK_LABEL]: "#ffbf64",
	[INTERVIEW_DECLINED_LABEL]: "#f75c5f",

	[CONFIRMED]: colors.interestConfirmed,
	[ONBOARDED]: colors.interestConfirmed,
	[DONE]: colors.interestConfirmed,

	[OPEN_FOR_INTERNAL_USE]: colors.internalColor,

	[FILLED]: colors.primaryColor,
	[HIRED]: colors.stepperIconColor,
	[ACCEPTED]: colors.stepperIconColor,
	[PROPOSITION_ACCEPTED_LABEL]: colors.stepperIconColor,
	[LIVE]: colors.stepperIconColor,
	[SIGNED]: colors.stepperIconColor,
	[APPROVED]: colors.stepperIconColor,
	[PUBLISHED]: colors.greenModalBgColor,
	[EXPIRED]: colors.lighterGray,
	[SENT]: colors.stoppedColor,
	[UNPUBLISHED]: colors.stoppedColor,
	[INTERVIEW_DECLINED]: colors.stoppedColor,
	[CANCELLED]: colors.btnDisabled,
	[REFUSED]: colors.btnDisabled,
	[REJECTED]: colors.btnDisabled,

	[EXPIRED]: colors.paused,
	[DISPUTED]: colors.warningColor,
	[IN_MEDIATION]: colors.paused,
	[DECLINED]: colors.paused,
	[SUBMITTED]: colors.submittedColor,

	[DRAFT]: colors.disabledGray,

	[DELETED]: colors.bodyBg,
	[EXTENDED]: colors.extendedColor,
	[INTERRUPTED]: colors.errorColor,
	[POKE_DECLINED]: colors.errorColor,
	[SHORTLISTED]: colors.stepperIconColor,
	[FINISHED]: colors.primaryColor,

	[CLOSED_TO_NEW_APPLICANTS]: colors.closeColor,
	[APPLIED]: colors.appliedColor,
	[APPLY_CANCELLED]: colors.btnDisabled,
	[PROPOSITION_CANCELED_LABEL]: colors.btnDisabled,
	[PROPOSITION_SENT_LABEL]: "#ffbf64",
	[NEW_BID_LABEL]: "#ffbf64",
	[NEW_TERMS_PROPOSED_LABEL]: "#ffbf64",
	[INTERVIEW_OVERDUE_LABEL]: colors.errorColor,

	[ONLINE]: "#00ba7d",
	[IN_PROGRESS]: "#ffbd69",
	[OFFLINE]: "#d0dfeb",

	[IN_REVIEW]: colors.ultraBlue
};

const Status = ({ status, isInterview }) => {
	if (isInterview && status === DECLINED) {
		const finalStatus = INTERVIEW_DECLINED;
		return <StatusCircle color={statusColors[finalStatus]} />;
	} else {
		return <StatusCircle color={statusColors[status]} />;
	}
};

export default Status;
