import React, { useState, useRef, useEffect } from "react";
import Fuse from "fuse.js";
import s from "modules/candidate/Profile/Pages/Profile/components/CandidatePreferences/candidate-preferences.module.scss";
import _get from "lodash/get";
import { ReactComponent as AddIcon } from "static/icons/focus-add.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import useGetlistExtraBenefits from "modules/candidate/Profile/Pages/Profile/components/CandidatePreferences/api/useExtraBenifits.js";
import { ReactComponent as CloseBanner } from "static/icons/cancelSmall.svg";
import { usePopper } from "react-popper";
import Portal from "common/Portal";

const ExtraBenifits = ({ extraBenifitsItems, setExtraBenifits }) => {
	const [openSelectBenifits, setOpenSelectBenifits] = useState(false);
	const [items, setItems] = useState([]);
	const [arrowRef, setArrowRef] = useState(null);
	const referenceElement = useRef();
	const popperElement = useRef();

	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "right",
			modifiers: [
				{
					name: "arrow",
					options: {
						element: arrowRef
					}
				},
				{
					name: "offset",
					options: {
						offset: [0, 20]
					}
				}
			]
		}
	);

	useOnClickOutside(
		popperElement,
		() => {
			if (openSelectBenifits) {
				setOpenSelectBenifits(false);
				handleAddExtraBenifits();
			}
		},
		[]
	);

	const { data } = useGetlistExtraBenefits(null, {
		tag: "list_extra_benefits"
	});

	useEffect(() => {
		setItems(
			_get(data, "extra_benefits", []).map(element => ({
				...element,
				checked: extraBenifitsItems
					.map(b => b.value)
					.includes(_get(element, "value"))
			}))
		);
	}, [data, extraBenifitsItems, setItems]);

	const [search, setSearch] = useState("");
	const filtredBenefits = !search
		? items.map(item => ({ item }))
		: new Fuse(items, {
				keys: ["label"],
				threshold: 0.3
		  }).search(search);

	const handleCheckBox = item => {
		const indexOfElm = items.findIndex(
			obj => _get(obj, "value") === _get(item, "item.value")
		);
		setItems(
			Object.values({
				...items,
				[indexOfElm]: {
					...items[indexOfElm],
					checked: !_get(item, "item.checked")
				}
			})
		);
	};

	const handleAddExtraBenifits = () => {
		const filtered = filtredBenefits
			.filter(elm => elm.item.checked)
			.map(elm => ({ value: elm.item.value, label: elm.item.label }));
		setExtraBenifits(filtered);
	};

	const removeExtraBenifits = item => {
		const indexOfElmExtra = extraBenifitsItems.findIndex(
			obj => _get(obj, "value") === _get(item, "value")
		);
		const indexOfElm = items.findIndex(
			obj => _get(obj, "value") === _get(item, "value")
		);
		setItems(
			Object.values({
				...items,
				[indexOfElm]: {
					...items[indexOfElm],
					checked: false
				}
			})
		);
		const arr = extraBenifitsItems;
		extraBenifitsItems.splice(indexOfElmExtra, 1);
		setExtraBenifits(arr);
	};

	const handleExtra = () => {
		setOpenSelectBenifits(true);
	};

	return (
		<div className={s.extraBenifits}>
			<div className={s.titleExtra}>EXTRA BENEFITS</div>
			<div className={s.extraBenifitItems}>
				{extraBenifitsItems.map((b, index) => (
					<span key={index} className={s.item}>
						{_get(b, "label")}
						<button onClick={() => removeExtraBenifits(b)}>
							<CloseBanner />
						</button>
					</span>
				))}
			</div>

			<Portal>
				{openSelectBenifits ? (
					<div
						className={s.listExtraBenifits}
						ref={popperElement}
						style={{
							...styles.popper,
							visibility: openSelectBenifits ? "visible" : "hidden"
						}}
						{...attributes.popper}
					>
						<div ref={setArrowRef} className={s.arrow} id="arrow" />
						<div className={s.searchBox}>
							<input
								type="text"
								value={search}
								onChange={e => {
									setSearch(e.target.value);
								}}
							/>
						</div>
						<ul>
							{filtredBenefits.map((item, index) => {
								return (
									<li key={index} onClick={() => handleCheckBox(item)}>
										<input
											type="checkbox"
											checked={_get(item, "item.checked")}
										/>
										<label>{_get(item, "item.label")}</label>
									</li>
								);
							})}
						</ul>
					</div>
				) : null}
			</Portal>

			<div className={s.btnContainer}>
				<button
					className={s.btnAdd}
					onClick={() => handleExtra()}
					ref={referenceElement}
				>
					<AddIcon />
					<span>Add extra</span>
				</button>
			</div>
		</div>
	);
};
export default ExtraBenifits;
