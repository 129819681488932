import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { HashtagNode } from "@lexical/hashtag";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkNode } from "@lexical/mark";
import { OverflowNode } from "@lexical/overflow";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import MentionNode from "./MentionNode";
import { EmailPlaceholderNode } from "./EmailPlaceholderNode";
import { EmailSignatureNode } from "./EmailSignatureNode";
import { PlaceholderLinkNode } from "./PlaceholderLinkNode";

const EditorNodes = [
	CodeHighlightNode,
	CodeNode,
	HashtagNode,
	AutoLinkNode,
	LinkNode,
	ListItemNode,
	ListNode,
	MarkNode,
	OverflowNode,
	HorizontalRuleNode,
	HeadingNode,
	QuoteNode,
	TableCellNode,
	TableNode,
	TableRowNode,
	MentionNode,
	EmailPlaceholderNode,
	EmailSignatureNode,
	PlaceholderLinkNode
];
export default EditorNodes;
