import React from "react";
import IconsChecker from "./Icons/IconsChecker";
import NewAvatar from "./Avatar/index";

const Avatar = ({
	notifIcon,
	notifType,
	avatar,
	name,
	className,
	classNameOnly,
	size = 40,
	radius
}) => {
	return (
		<div className={classNameOnly ? className : `avatar ${className}`}>
			{notifType === "system" && notifIcon ? (
				<IconsChecker type={notifIcon} />
			) : (
				<NewAvatar
					src={avatar}
					name={name}
					alt={name}
					size={size}
					radius={radius}
				/>
			)}
		</div>
	);
};

Avatar.defaultProps = {
	className: ""
};

export default Avatar;
