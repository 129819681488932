import React from "react";
import cx from "classnames";
import { ReactComponent as EmptyStarGreen } from "static/icons/empty-star-green.svg";
import styles from "./star-toggle.module.scss";

const StarToggle = ({ name, id, value, checked, onClick }) => {
	return (
		<>
			<label onClick={onClick} className={styles.label} htmlFor={id}>
				<EmptyStarGreen
					className={cx(styles.icon, { [styles.containedIcon]: checked })}
				/>
				<span className={styles.span}>Primary</span>
			</label>
			<input
				value={value}
				name={name}
				id={id}
				type="radio"
				checked={checked}
				className={styles.radioButton}
			/>
		</>
	);
};

export default StarToggle;
