import styled from "styled-components";
import { colors } from "config/styles";

export const ListBidsBody = styled.div`
	display: block;
	max-height: 185px;
	height: auto;
	overflow-x: hidden;
`;

export const TableExpandWrapper = styled.div`
	border: 1px solid ${colors.borderColor};
	background-color: ${colors.white};
	border-radius: 3px;
	margin-bottom: 8px;
`;

export const JobBidsList = styled.table`
	border-collapse: separate;
	margin: 0;
	width: 100%;
	margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 0)};
	border-spacing: ${props => (props.borderSpacing ? "0 4px" : "unset")};
`;

const TableItem = styled.th`
	border: 0;
`;

export const Th = styled(TableItem)`
	color: ${colors.bidList};
	font-weight: 500;
	text-transform: uppercase;
	border-bottom: 1px solid ${colors.borderColor};
`;

export const TdStyled = TableItem.withComponent("td");

export const Td = styled(TdStyled)`
	position: relative;
	border: 0;
	border-bottom: 1px solid ${colors.borderColor}!important;
	word-break: break-word;
	color: ${colors.inputColor};
	font-size: 13px;
	vertical-align: middle;

	.poke-icon {
		width: 30px;
		margin-top: 5px;
	}
	.shortlisted-icon {
		width: 20px;
		margin-top: 5px;
	}
	.application-icon {
		width: 30px;
		margin-top: 5px;
	}
	.inactive-icon {
		width: 20px;

		fill: ${colors.timesheetTh};
	}
	.icon-verified {
		font-size: 16px;
		padding: 5px;
	}
`;

export const Thead = styled.thead`
	background-color: transparent;
`;

export const Trthead = styled.tr`
	background-color: ${props => (props.bg ? props.bg : "transparent")};
`;

export const Tr = styled.tr`
	cursor: pointer;
	height: ${props => props.height && props.height};
	&:last-child {
		${Td} {
			border-bottom: 0 !important;
		}
	}

	&:hover {
		${Td} {
			background-color: ${props => !props.inactive && colors.lineHover};
		}
	}

	&.inactive {
		background-color: ${colors.hoverBgColor};
		cursor: not-allowed;
		td {
			color: ${colors.timesheetTh} !important;
		}
	}
`;
