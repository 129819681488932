import styled from "styled-components";
import { colors, utilities } from "config/styles";

export const ScorerWrapper = styled.div`
	float: right;
`;

export const ScorerThead = styled.thead`
	display: block;
	border-bottom: solid 0.5px ${colors.notificationBorder};
`;

export const Th = styled.th`
	padding: 20px;
	color: ${colors.anchorsColor};
	border-bottom-width: 1px;
	text-transform: uppercase;
	&:first-child {
		width: 150px;
		padding-right: 0;
	}
`;

export const ScorerTbody = styled.tbody`
	display: block;
	max-height: 300px;
	overflow-y: auto;
`;

export const Tr = styled.tr`
	border-top: solid 0.5px ${colors.notificationBorder};
	&:hover {
		background-color: ${colors.hoverBgColor};
	}
	&:first-child {
		border-top-width: 0;
	}
`;

export const Td = styled.td`
	padding: 20px;
	color: ${props =>
		props.current ? colors.primaryColor : "inherit"}!important;
	font-weight: ${props => (props.current ? 500 : 300)}!important;
	letter-spacing: ${props => (props.current ? "3px" : 0)}!important;
	width: ${props => (props.current ? "30px" : "auto")}!important;

	&:first-child {
		width: 150px !important;
		padding-right: 0;
		word-break: break-all;
		font-size: 14px;
		font-weight: 400 !important;
	}
`;

export const ScorerTable = styled.table`
	box-shadow: 0 0 7.4px 0.1px rgba(218, 224, 242, 0.48);
	border: solid 0.5px ${colors.notificationBorder};
	border-radius: ${utilities.borderRadius};
	margin-bottom: 30px;
	width: 100%;

	a {
		text-decoration: none;
	}

	.reactRater {
		> div {
			display: inline-block;
			line-height: 1;
		}
	}
`;

export const ScorerScrub = styled.div`
	width: 20px;
	height: 20px;
	border: 2px solid
		${props => (props.active ? colors.primaryColor : colors.scrubColor)};
	color: ${props => (props.active ? colors.primaryColor : colors.scrubColor)};
	display: inline-block;
	border-radius: 50%;
	position: relative;
	margin-right: 1px;
	cursor: pointer;
	&:after {
		display: ${props => (props.active ? "block" : "none")};
		content: "";
		width: 10px;
		height: 10px;
		background-color: ${colors.primaryColor};
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
	}
`;
