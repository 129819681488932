import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { API_DATA_TYPE } from "../utils/constants";

const getSettings = () => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "search_timesheets_table"
		}
	});
};

export const QUERY_KEY = `@getColumnsSettings_${API_DATA_TYPE}`;

export default function useFetchTimesheetsTableSettings() {
	return useQuery([QUERY_KEY], getSettings);
}
