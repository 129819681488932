import React from "react";
import DefaultButton from "common/Buttons";
import { Box, Text } from "rebass";
import styled from "styled-components";

const StyledDefaultButton = styled(DefaultButton)`
	&& {
		display: flex;
		margin-bottom: ${({ mb }) => mb + "px"};
		.icon {
			fill: #dfe3e8;
		}
		&:hover {
			> div {
				color: #ff6849;
			}
			.icon {
				fill: #ff6849;
			}
		}
	}
`;

const Menu = ({ actions }) => {
	return (
		<Box
			sx={{
				borderRadius: "4px",
				position: "absolute",
				boxShadow: "0 6px 8px 8px rgba(0, 0, 0, 0.04)",
				display: "flex",
				flexDirection: "column",
				backgroundColor: "#ffffff",
				padding: "16px 8px"
			}}
		>
			{actions.map(({ label, icon, action, mb }) => (
				<StyledDefaultButton
					padding="0px 8px"
					onClick={action}
					backgroundColor="white"
					mb={mb}
					key={label}
				>
					{icon}
					<Text
						ml={2}
						sx={{
							fontSize: "14px",
							color: "#212b36",
							fontWeight: "normal"
						}}
					>
						{label}
					</Text>
				</StyledDefaultButton>
			))}
		</Box>
	);
};

export default Menu;
