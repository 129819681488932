import create from "zustand";
import { devtools } from "zustand/middleware";

const initialState = {
	searchQuery: "",
	kanbanStages: []
};

const RequestViewKanbanStore = (set, get) => ({
	...initialState,
	setSearchQuery: searchQuery => set(state => ({ ...state, searchQuery })),
	setKanbanStages: kanbanStages => set(state => ({ ...state, kanbanStages })),
	setStageCards: ({ stageName, data }) =>
		set(state => ({ ...state, [stageName]: data })),
	getStageCards: stageName => {
		const state = get();
		if (stageName in state) return [...state[stageName]];
		return null;
	},
	renameStageCards: ({ oldStageName, stageName }) => {
		set(state => {
			state[stageName] = state[oldStageName];
			delete state[oldStageName];
			return state;
		});
	},
	// clean store
	cleanKanbanStore: () =>
		set(state => {
			state.kanbanStages.map(stage => delete state[stage.name]);
			state.kanbanStages = [];
			state.searchQuery = "";
			return state;
		})
});

const useRequestViewKanbanStore = create(devtools(RequestViewKanbanStore));
export default useRequestViewKanbanStore;
