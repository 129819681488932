import React, { useEffect, useState } from "react";
import TimePicker from "rc-time-picker";
import DatePickerField from "common/DatePickerField";
import "rc-time-picker/assets/index.css";
import {
	DATETIME_FORMAT,
	TIME_FORMAT_24,
	IN_DEF_START_TIME,
	IN_DEF_END_TIME,
	TIMEZONE
} from "config";
import moment from "moment";
const MINUTES = [0, 15, 30, 45];
const HOURS = [
	0,
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
	21,
	22,
	23
];
const TimeChooser = ({
	previousStartTime,
	previousEndTime,
	handleCalendarChanged = () => {},
	dateClass,
	timeStartClass,
	timeEndClass,
	displayResetIcon,
	handleTime,
	setDate,
	date,
	error
}) => {
	const [startTime, setStartTime] = useState(
		window.moment.tz(
			`01/01/1970 ${IN_DEF_START_TIME}`,
			DATETIME_FORMAT,
			TIMEZONE
		)
	);
	const [endTime, setEndTime] = useState(
		window.moment.tz(`01/01/1970 ${IN_DEF_END_TIME}`, DATETIME_FORMAT, TIMEZONE)
	);
	const [valid, setValid] = useState(true);

	useEffect(() => {
		previousStartTime && setStartTime(moment(previousStartTime, "HH:mm"));
		previousEndTime && setEndTime(moment(previousEndTime, "HH:mm"));
	}, []);

	useEffect(() => {
		const checkTime = async () => {
			await validate();
			handleTime({
				startTime: startTime.format(TIME_FORMAT_24),
				endTime: endTime.format(TIME_FORMAT_24)
			});
		};

		checkTime();
	}, [startTime, endTime]);

	const handlerStartTime = value => {
		handleCalendarChanged(true);
		const startTime = value;
		const endTime = window.moment(value).add(1, "hours");
		setStartTime(startTime);
		setEndTime(endTime);
	};

	const handlerEndTime = value => {
		const endTime = value;
		handleCalendarChanged(true);
		setEndTime(endTime);
	};

	const validate = () => {
		return new Promise(resolve => {
			setValid(startTime < endTime);
			return resolve();
		});
	};

	const updateDate = date => {
		handleCalendarChanged(true);
		setDate(date);
		date = window.moment(date);

		setStartTime(startTime =>
			startTime.set({
				year: date.year(),
				month: date.month(),
				date: date.date()
			})
		);
		setEndTime(endTime =>
			endTime.set({
				year: date.year(),
				month: date.month(),
				date: date.date()
			})
		);
	};

	const errorClass = !valid ? "has-error has-feedback" : "";
	const disabledHours = () => {
		const startTimeHour = window.moment(startTime).get("hours");
		return HOURS.slice(0, startTimeHour);
	};
	const disabledMinutes = endTimeHour => {
		const startTimeMinute = window.moment(startTime).get("minute");
		const startTimeHour = window.moment(startTime).get("hours");
		const minuteIndex = MINUTES.indexOf(startTimeMinute);
		return endTimeHour === startTimeHour
			? MINUTES.slice(0, minuteIndex + 1)
			: null;
	};
	return (
		<div className="other-date row">
			<div className={dateClass}>
				<DatePickerField
					classes=""
					name="start_date"
					label="Interview date"
					onChange={updateDate}
					minDate={window.moment().toDate()}
					handleCalendarChanged={handleCalendarChanged}
					displayResetIcon={displayResetIcon}
					input={{
						value: date,
						onChange: updateDate
					}}
					meta={{ error, touched: error }}
				/>
			</div>
			<div className={timeStartClass}>
				<div className={`form-group ${errorClass}`}>
					<label>Start at</label>
					<div>
						<TimePicker
							name="time_start"
							format={TIME_FORMAT_24}
							showSecond={false}
							value={startTime}
							className="time-picker"
							onChange={handlerStartTime}
							inputReadOnly={true}
							allowEmpty={false}
							defaultOpenValue={window.moment()}
							minuteStep={15}
						/>
					</div>
				</div>
			</div>
			<div className={timeEndClass}>
				<div className={`form-group ${errorClass}`}>
					<label>Ends at</label>
					<div>
						<TimePicker
							name="time_end"
							format={TIME_FORMAT_24}
							showSecond={false}
							value={endTime}
							className="time-picker"
							onChange={handlerEndTime}
							inputReadOnly={true}
							allowEmpty={false}
							defaultOpenValue={window.moment()}
							minuteStep={15}
							disabledHours={disabledHours}
							disabledMinutes={disabledMinutes}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TimeChooser;
