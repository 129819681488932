import { dateCalendar } from "common/Functions";
import React from "react";
import { Box, Flex, Text } from "rebass";
import ProfileAvatar from "../profileAvatar";

const MenuItem = ({ option }) => {
	const { fullName, jobTitle, viewedAt, avatar, isAvatar } = option;

	return (
		<Flex
			sx={{ borderTop: "1px solid #f2f2f2" }}
			justifyContent="space-between"
			alignItems="center"
			py={2}
		>
			<Flex alignItems="center">
				{isAvatar && (
					<ProfileAvatar
						isProfileBorder={false}
						src={avatar}
						name={fullName}
						size={50}
					/>
				)}
				<Box>
					<Text
						sx={{
							fontSize: "14px",
							color: "#212b36",
							marginLeft: isAvatar ? "8px" : "0px",
							fontWeight: "normal"
						}}
					>
						{fullName}
					</Text>
					{isAvatar && jobTitle && (
						<Text
							sx={{
								fontSize: "12px",
								color: "#818a92",
								marginLeft: "8px",
								fontWeight: "normal"
							}}
						>
							{jobTitle}
						</Text>
					)}
				</Box>
			</Flex>
			<Text sx={{ fontSize: "10px", color: "#818a92", fontWeight: "normal" }}>
				{dateCalendar(viewedAt)}
			</Text>
		</Flex>
	);
};

export default MenuItem;
