import React from "react";
import Modal from "common/modal";
import { DescriptionText } from "./Styled";

const EmailAlreadyUsedModal = ({
	onClose,
	companyName,
	vacancyTitle,
	city,
	accountType,
	backAction
}) => {
	return (
		<Modal
			fixed={true}
			onClose={onClose}
			modalName={"offline-application-confirmation"}
			title={"Job application"}
			id="application-email-used"
		>
			<DescriptionText>
				<div className="headline">
					For the position of{" "}
					<span className="infos">{`"${vacancyTitle}"`}</span> at{" "}
					<span className="infos">{`"${companyName}"`} </span>in{" "}
					<span className="infos"> {`"${city}"`}</span>.
				</div>
				<div>
					You can’t apply on wiggli with this email address as a {accountType}{" "}
					account is already linked to it.
					<br />
					If you wish to apply anyway, just use the button below to submit your
					application using another email address
				</div>
			</DescriptionText>
			<button className="btn btn-primary-outline" onClick={backAction}>
				Back
			</button>
		</Modal>
	);
};

export default EmailAlreadyUsedModal;
