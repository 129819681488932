import React from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import get from "lodash/get";

import Card from "../Card";
import Tag from "../Tag";
import EmptyState from "../EmptyState";

import styles from "../Card/card.module.scss";

const LanguagesCard = ({
	title,
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	isLoading,
	languages
}) => {
	return (
		<Card title={title} {...{ onDragEnd, onViewMore, onToggleVisibility }}>
			<div className={styles.section}>
				<div className={styles.row}>
					{!isLoading ? (
						languages.length > 0 ? (
							languages.map(language => (
								<Tag
									key={get(language, "_id")}
									text={`${get(language, "name")} (${get(
										language,
										"score"
									)}/5)`}
								/>
							))
						) : (
							<EmptyState />
						)
					) : (
						<div className={styles.gap}>
							<SkeletonTheme
								style={{ borderRadius: 10, marginRight: 16 }}
								color="#dfe3e8"
							>
								<Skeleton width={80} height={28} />
							</SkeletonTheme>
							<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
								<Skeleton width={80} height={28} />
							</SkeletonTheme>
						</div>
					)}
				</div>
			</div>
		</Card>
	);
};

LanguagesCard.displayName = "LanguagesCard";
LanguagesCard.prototype = {
	...Card.prototype,
	isLoading: PropTypes.bool.isRequired,
	languages: PropTypes.shape({
		name: PropTypes.string.isRequired,
		_id: PropTypes.string.isRequired,
		score: PropTypes.number.isRequired
	})
};
export default LanguagesCard;
