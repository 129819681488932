import styled from "styled-components";
import { colors } from "config/styles";

export const ChartTitle = styled.div`
	letter-spacing: 0.05px;
	line-height: 2.15;
	font-weight: 500;
	font-size: 16px;
	float: left;
	color: #0f1c46;
`;

export const DetailContainer = styled.div`
	margin-left: ${props => (props.permanent ? "15px" : null)};
	width: 50%;
	margin: auto;
	.legend-container {
		display: flex;
		padding: 10px;
		.g-tool {
			display: flex;
			color: #8a95a1;
			font-size: 19px;
			vertical-align: middle;
			margin-left: 0px;
		}
	}
`;

export const DetailColor = styled.div`
	margin-right: 8px;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	flex-shrink: 0;
	background-color: ${props =>
		props.color ? props.color : colors.primaryColor};
`;

export const DetailLabel = styled.span`
	line-height: 1.4;
	font-size: 12px;
	font-weight: 400;
	margin-right: 12px;
	color: #333;
	margin-left: 0px;
	text-transform: lowercase;
	&::first-letter {
		text-transform: uppercase;
	}
`;

export const BlocHolder = styled.div`
	.hourly-cost {
		position: absolute;
		top: ${props => (props.permanent ? "162px" : "154px")};
		left: 0;
		font-size: ${props => (props.permanent ? "25px" : "20px")};
		display: grid;
		justify-items: center;
		line-height: 1.3;
		font-weight: 400;
		width: 100%;
		text-align: center;
		z-index: 5;
	}
	.title-container {
		justify-content: space-between;
		padding: 20px 20px 0 20px;
		display: flex;
	}
	.chart-title {
		color: #0f1c46;
		letter-spacing: 0.05px;
		line-height: 2.15;
		font-weight: 500;
		font-size: 16px;
		padding: 20px 20px 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		span {
			width: 24px;
			height: 24px;
		}
		.icon-check {
			display: none;
		}
	}
`;
export const CenterBloc = styled.div`
	display: flex;
	position: relative;
	.VictoryContainer {
		display: flex;
		justify-content: center;
	}
	.VictoryContainer svg:first-child {
		width: 350px !important;
	}
`;
