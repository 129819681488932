import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_SUPPLIERS_ENDPOINT } from "config/api-endpoints";
import { WELCOME_PAGE_RECENT_SUPPLIERS } from "config";
import { recentSuppliersListKey } from "modules/home/api/queriesKeys.js";

const listRecentSuppliers = (key, { start_date, end_date }) =>
	client(LIST_SUPPLIERS_ENDPOINT, {
		body: {
			start_date,
			end_date,
			tag: WELCOME_PAGE_RECENT_SUPPLIERS
		}
	});

const useRecentSuppliers = (options, queryOptions = {}) =>
	useQuery([recentSuppliersListKey, options], listRecentSuppliers, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useRecentSuppliers;
