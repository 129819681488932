import { useEffect, useRef, useState } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { REQUESTS_INTERVIEWS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { usePaginatedQuery } from "react-query";
import { useDebounce } from "react-use";
import isEqual from "lodash/isEqual";
import { API_DATA_TYPE, REQUEST_INTERVIEWS_FILTERS_ID } from "../utils";
import { formatFilters } from "../../utils/helpers";

export const filterData = body => {
	return client(REQUESTS_INTERVIEWS, {
		body
	});
};

export const KEY_FETCH_TEMP_INTERVIEWS = "KEY_FETCH_INTERVIEWS";

const useFilterData = () => {
	const {
		setQueryError,
		setIsLoading,
		setIsFetching,
		getState,
		setData
	} = useFilters();

	const {
		query,
		logicalOperator,
		filters,
		sortBy,
		offset,
		filterChangeTrigger,
		limit
	} = getState(REQUEST_INTERVIEWS_FILTERS_ID);

	const previousFilters = useRef();
	const [body, setBody] = useState(formatBody());

	const {
		resolvedData: data,
		isLoading,
		isFetching,
		refetch,
		isFetched
	} = usePaginatedQuery(
		[KEY_FETCH_TEMP_INTERVIEWS, body],
		() => filterData(body),
		{
			onError: e => {
				if (e?.detail?.keywords) {
					setQueryError(true);
				}
			},
			refetchOnWindowFocus: false
		}
	);

	useEffect(() => {
		setIsLoading(isLoading);
		setIsFetching(isFetching && !isFetched);
	}, [isLoading, isFetching, refetch, isFetched]);

	useEffect(() => {
		setData(data);
		setQueryError(false);
	}, [data]);

	useEffect(() => {
		const validFilters = getValidFilters(filters);
		const globalFilter = {
			validFilters,
			sortBy,
			offset,
			logicalOperator
		};

		const isFiltersChanged = !isEqual(previousFilters.current, globalFilter);

		if (isFiltersChanged) {
			previousFilters.current = globalFilter;
		}
		if (
			(filterChangeTrigger !== "change" &&
				filterChangeTrigger !== "newFilter" &&
				isFiltersChanged) ||
			filterChangeTrigger === "reset" ||
			filterChangeTrigger === "limitChange"
		) {
			setBody(formatBody());
		}
	}, [filters, sortBy, offset, filterChangeTrigger, logicalOperator, limit]);

	useDebounce(
		() => {
			if (filterChangeTrigger === "change") {
				setBody(formatBody());
			}
		},
		500,
		[filters, query]
	);

	function formatBody() {
		const andRegex = /\band\b|\bAnd\b|\bAND\b/gi;
		const orRegex = /\bor\b|\bOr\b|\bOR\b/gi;
		const notRegex = /\bnot\b|\bNot\b|\bNOT\b/gi;

		const queryUpdated = query
			.replaceAll(andRegex, " AND ")
			.replaceAll(orRegex, " OR ")
			.replaceAll(notRegex, " NOT ");

		return {
			op: logicalOperator,
			fields: formatFilters(filters),
			search: queryUpdated,
			offset,
			sort_by: sortBy,
			limit,
			tag: API_DATA_TYPE
		};
	}

	function getValidFilters(filters) {
		//Be careful about false value, filtering a filter with false value is a bug
		//In case of expected salary, entering 0 will be filtered, the ideal should be including the salary to the filter!
		return filters.filter(({ value }) => {
			if (
				[undefined, null, "", 0].includes(value) ||
				(Array.isArray(value) && value.length === 0) ||
				(value !== null &&
					typeof value === "object" &&
					Object.keys(value).length === 0)
			) {
				return false;
			} else {
				return true;
			}
		});
	}

	return refetch;
};

export default useFilterData;
