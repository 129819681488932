import { Box, Flex } from "rebass";
import styled from "styled-components";

export const AddNotesInputContainer = styled(Box)`
	&& {
		.mention-container {
			#mention-box {
				height: ${({ noteDescription }) =>
					!noteDescription ? "52px !important" : "100px !important"};
			}
			.mentions_mentions__highlighter__2Wunj {
				height: ${({ noteDescription }) =>
					!noteDescription ? "52px !important" : "100px !important"};
			}
		}
	}
	.note_type {
		margin-bottom: 10px;
		.Select-control {
			height: 30px;
			.Select-placeholder {
				display: flex;
				align-items: center;
			}
			.Select-value-label {
				display: flex !important;
				align-items: center;
				height: 100%;
			}
		}
	}
`;

export const SearchInputContainer = styled(Flex)`
	border-radius: 4px;

	background-color: #f2f2f2;
	> input {
		border: none;
		background-color: #f2f2f2;
		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: #818a92;
			opacity: 1; /* Firefox */
			font-size: 16px;
			font-weight: normal;
		}

		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #818a92;
			opacity: 1;
			font-size: 16px;
			font-weight: normal;
		}

		::-ms-input-placeholder {
			/* Microsoft Edge */
			color: #818a92;
			opacity: 1;
			font-size: 16px;
			font-weight: normal;
		}
		:focus-visible {
			outline: none;
		}
	}
`;

export const AddNoteButton = styled.button`
	border-radius: 6px;
	border: none;
	background-color: #2b3946;
	padding: 6px 18px;
`;

export const PaginationContainer = styled.div`
	.pagination {
		margin: 20px auto;
	}
`;
