import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import _get from "lodash/get";
import {
	EUR_PER_HOUR,
	VACANCY,
	PUBLISHED,
	OPEN_FOR_INTERNAL_USE,
	APPROVED,
	OFFERED,
	FEATURE_PRO_ACTIVE_APPLICATIONS
} from "config";
import CurrencyFormatter from "common/CurrencyFormater";
import Avatar from "common/Avatar/index";
import GlobalTooltip from "common/GlobalTooltip";
import { Link } from "react-router";
import { getProfileSearchRoute } from "modules/vacancy/components";
import { SearchContext, ACTION_TYPES } from "common/newSearch/context";
import { OutlineButton } from "common/styled/buttons";
import ChannelsModal from "modules/vacancy/components/modals/ChannelsModal";
import { FEATURE_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getFeatureSettings = (_, company_id, feature_name) => {
	client(FEATURE_SETTINGS, {
		body: {
			company_id,
			feature_name
		}
	});
};
const JobTopbar = props => {
	const {
		title,
		type,
		category,
		subcategory,
		_function,
		seniority,
		hourly_budget,
		bids_average,
		total_bids,
		avatar_list,
		isConsultancy = false,
		consutlantName,
		consutlantAvatar,
		status,
		template,
		job,
		connectedUser,
		vacancy_performance,
		reload
	} = props;
	const companyId = _get(job, "company._id", "");
	const isVacancy = type === VACANCY;
	const { data } = useQuery(
		["feature_settings", companyId, FEATURE_PRO_ACTIVE_APPLICATIONS],
		getFeatureSettings,
		{
			enabled: isVacancy
		}
	);
	const [showModalVonq, setShowModalVonq] = useState(false);
	const logoCompany = _get(job, "company.avatar", "");
	const nameCompany = _get(job, "company.name", "");
	const firstAvatar = _get(avatar_list, "0.avatar", "");
	const secondAvatar = _get(avatar_list, "1.avatar", "");

	const overlay =
		"This will launch a quick search among wiggli’s database and provide you with a list of potential matches, based on the profile, skills, languages and location of your vacancy. You can then poke any interesting candidate in that list, to attract his/her attention towards your vacancy.";

	const { dispatch } = useContext(SearchContext);

	const idStatus = _get(connectedUser, "company_identity_status");
	const paymentStatus = _get(connectedUser, "company_payment_status");
	const companyInformationStatus = _get(
		connectedUser,
		"company_information_status"
	);
	const externalCandidates = _get(
		data,
		"feature_settings.external_candidates",
		{}
	);

	const disableViewPotentialMatches =
		idStatus !== APPROVED ||
		(paymentStatus !== APPROVED && paymentStatus !== OFFERED) ||
		companyInformationStatus !== APPROVED;
	const isNewCampaing =
		_get(vacancy_performance, "campaigns.length", 0) > 0 &&
		status === PUBLISHED;
	return (
		<>
			<div className={`topbar ${isNewCampaing && "topbar_newcampaign"}`}>
				<div className="title">
					<div className="row">
						{logoCompany ? (
							<div className="col-md-1">
								<div className="logoCompany">
									<img src={logoCompany} alt={nameCompany} />
								</div>
							</div>
						) : null}

						<div className="col-md-8">
							<div>
								<span className="section-title">
									{isVacancy ? "JOB TITLE" : "REQUEST TITLE"}
								</span>
								<span className="request-title">{title}</span>
							</div>
							<div>
								{type !== VACANCY && (
									<div className="section-profile-request">
										<span className="section-title">PROFILE</span>
										<span className="profile-title">
											{category} - {subcategory} - {_function}
											<span className="seniority">{seniority}</span>
										</span>
									</div>
								)}
							</div>
						</div>
						<div className={logoCompany ? "col-md-3" : "col-md-4"}>
							{isVacancy && isNewCampaing && (
								<OutlineButton
									onClick={() => setShowModalVonq(true)}
									className="create__new__campaign"
								>
									<i className="fa fa-plus" /> CREATE NEW CAMPAIGN
								</OutlineButton>
							)}
						</div>
						<div className={`pull-right ${isConsultancy ? "consultant" : ""}`}>
							{!disableViewPotentialMatches &&
							isVacancy &&
							(status === PUBLISHED || status === OPEN_FOR_INTERNAL_USE) ? (
								<div
									className="potential-container"
									onClick={() =>
										dispatch({
											type: ACTION_TYPES.SET_ACTIVE_TEMPLATE,
											template
										})
									}
								>
									<GlobalTooltip overlay={overlay}>
										<Link
											className="btn btn-primary"
											to={getProfileSearchRoute(template)}
											disabled={disableViewPotentialMatches}
										>
											<i className="icon-matches" />
											VIEW POTENTIAL MATCHES
										</Link>
									</GlobalTooltip>
								</div>
							) : null}
							{hourly_budget ? (
								<div className="item">
									<h5>EXPECTED RATE</h5>
									<span className="value">
										<CurrencyFormatter
											cent={hourly_budget}
											symbol={EUR_PER_HOUR}
										/>
									</span>
								</div>
							) : (
								""
							)}
							{isConsultancy && (
								<div className="item">
									<h5>Consultant</h5>
									<span className="value">
										<div style={{ float: "left", marginRight: 10 }}>
											<Avatar src={consutlantAvatar} name={consutlantName} />
										</div>{" "}
										{consutlantName}
									</span>
								</div>
							)}
							{bids_average && bids_average > 0 ? (
								<div className="item">
									<h5>AVERAGE BID</h5>
									<span className="value">
										<CurrencyFormatter cent={bids_average} />
									</span>
								</div>
							) : (
								""
							)}
							{total_bids && total_bids > 0 ? (
								<div className="item">
									<h5 className="total-bids">
										<span>{total_bids}</span> BIDS
									</h5>
									<span className="avatars">
										{total_bids >= 1 && (
											<Avatar
												src={firstAvatar}
												size={24}
												className="bid-avatar"
											/>
										)}
										{total_bids >= 2 && (
											<Avatar
												size={24}
												src={secondAvatar}
												className="bid-avatar back"
											/>
										)}
										{total_bids > 2 && (
											<span className="more-avatars">...</span>
										)}
									</span>
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</div>
			{showModalVonq && isVacancy && (
				<ChannelsModal
					isActive={showModalVonq}
					onClose={() => {
						setShowModalVonq(false);
					}}
					jobId={_get(job, "id")}
					channels={_get(externalCandidates, "channels", [])}
					externalCandidates={externalCandidates}
					title={"Republish"}
					isNewCampaing={isNewCampaing}
					previousChannels={_get(vacancy_performance, "previously_used")}
					onAfterSave={reload}
				/>
			)}
		</>
	);
};

JobTopbar.propTypes = {
	_function: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	subcategory: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	category: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string.isRequired,
	hourly_budget: PropTypes.number,
	bids_average: PropTypes.number,
	total_bids: PropTypes.number,
	avatar_list: PropTypes.array
};

export default JobTopbar;
