import React from "react";
import _get from "lodash/get";

import { FiltersContainer } from "common/newSearch/Styled";
import FilterItem from "common/newSearch/Filters/FilterItem";
import {
	FUNCTIONS,
	SKILLS,
	LANGUAGES,
	LOCATION,
	DESC,
	ASC,
	HYBRID_OPTION,
	REMOTE_ELIGIBITY_OPTIONS
} from "config";
import KeywordsSearch from "common/newSearch/Filters/KeywordsSearch";
import { Filter } from "common/newSearch/Styled";
import SearchEmployment from "common/newSearch/Filters/SearchEmployment";
import styles from "./search-filters.module.scss";
import { ButtonGroup } from "common/ButtonGroup";
import cx from "classnames";

const SearchFilters = ({
	functions,
	skills,
	languages,
	location,
	distance,
	updateDistance,
	setDisplayFunctionsModal,
	setDisplaySkillsModal,
	setDisplayLanguagesModal,
	setDisplayAddressModal,
	setDisplayTemplateModal,
	handleDeleteFunction,
	deleteFilter,
	clearFilters,
	keywords = "",
	updateKeywordsValue,
	sortBy,
	updateSortBy,
	except_applied,
	except_poked,
	updateIsApplied,
	updateIsPoked,
	keywordError,
	isOnline,
	filterByPermanent,
	filterByFixedTerm,
	filterByInternShip,
	employment_type,
	remoteEligilibityOptions,
	onRemoteOptionChange,
	onDaysOfficeChange,
	daysInOffice
}) => {
	const canCreateTemplate =
		remoteEligilibityOptions.length > 0 ||
		_get(functions, "length", []) > 0 ||
		_get(skills, "length", []) > 0 ||
		_get(languages, "length", []) > 0 ||
		_get(keywords, "length", "") > 0 ||
		except_poked ||
		except_applied ||
		_get(sortBy, "publish_date.order") ||
		_get(employment_type, "length", []) > 0;
	_get(location, "city");

	const handleSortChange = () => {
		let updatedSort = DESC;
		if (_get(sortBy, "publish_date.order") === DESC) {
			updatedSort = ASC;
		}
		updateSortBy({
			publish_date: {
				order: updatedSort
			}
		});
	};

	const generateButtons = () => {
		return [1, 2, 3, 4, 5].map(number => {
			const isSelected = daysInOffice.includes(number);

			return {
				variant: isSelected ? "contained" : "outlined",
				text: number,
				onClick: () => onDaysOfficeChange(number),
				className: cx(styles.dayButton, {
					[styles.dayButtonSelected]: isSelected,
					[styles.marginLeft10]: number != 1
				})
			};
		});
	};

	return (
		<FiltersContainer className={styles.container}>
			<div className="head">
				<span className="title">Search Criteria</span>
				<span className="clearFilters" onClick={clearFilters}>
					<span className="icon-clear-filter"></span>
					<span>Clear Filter</span>
				</span>
			</div>
			<Filter className="checkbox-filter">
				<div className="title">Sort by</div>
				<div className="checkbox checkbox-primary">
					<div className="input-box" style={{ width: "100%", display: "flex" }}>
						<input
							type="checkbox"
							className="styled"
							id={"sort"}
							checked={_get(sortBy, "publish_date.order") === DESC}
							onChange={handleSortChange}
						/>
						<label htmlFor={"sort"}>Most recent on top</label>
					</div>
				</div>
			</Filter>
			{isOnline && (
				<Filter className="checkbox-filter">
					<div className="title">Show all except</div>
					<div className="checkbox-container">
						<div className="checkbox checkbox-primary">
							<div
								style={{ width: "100%", display: "flex", marginRight: "30px" }}
							>
								<input
									type="checkbox"
									className="styled"
									id={"applied"}
									checked={except_applied}
									onChange={() =>
										updateIsApplied(except_applied ? undefined : true)
									}
								/>
								<label htmlFor={"applied"}>Already applied</label>
							</div>
						</div>
						<div className="checkbox checkbox-primary">
							<div style={{ width: "100%", display: "flex" }}>
								<input
									type="checkbox"
									className="styled"
									id={"poked"}
									checked={except_poked}
									onChange={() =>
										updateIsPoked(except_poked ? undefined : true)
									}
								/>
								<label htmlFor={"poked"}>Been sourced</label>
							</div>
						</div>
					</div>
				</Filter>
			)}
			<Filter className="checkbox-filter">
				<div className="title">Remote eligibility</div>
				{REMOTE_ELIGIBITY_OPTIONS.map(({ label, value }, index) => (
					<div
						key={label}
						style={{
							marginBottom:
								index === REMOTE_ELIGIBITY_OPTIONS.length - 1 ? "8px" : "15px"
						}}
						className="checkbox checkbox-primary"
					>
						<div
							className="input-box"
							style={{ width: "100%", display: "flex" }}
						>
							<input
								type="checkbox"
								className="styled"
								id={label}
								checked={remoteEligilibityOptions.includes(value)}
								onChange={e => onRemoteOptionChange(e, value)}
							/>
							<label htmlFor={label}>{label}</label>
						</div>
					</div>
				))}
			</Filter>
			{remoteEligilibityOptions.includes(HYBRID_OPTION.value) && (
				<Filter className={styles.daysInOfficeCard}>
					<div className="title">Days in the office</div>
					<ButtonGroup buttons={generateButtons()} />
				</Filter>
			)}
			<KeywordsSearch
				keywords={keywords}
				updateKeywordsValue={updateKeywordsValue}
				hasError={keywordError}
			/>
			<FilterItem
				title={"Functions"}
				filterType={FUNCTIONS}
				items={functions}
				deleteFilter={handleDeleteFunction}
				openModal={() => setDisplayFunctionsModal(true)}
			/>
			<FilterItem
				title={"Skills"}
				filterType={SKILLS}
				items={skills}
				deleteFilter={deleteFilter}
				openModal={() => setDisplaySkillsModal(true)}
			/>
			<FilterItem
				title={"Languages"}
				filterType={LANGUAGES}
				items={languages}
				deleteFilter={deleteFilter}
				openModal={() => setDisplayLanguagesModal(true)}
			/>
			<FilterItem
				title={"Location"}
				filterType={LOCATION}
				items={location}
				deleteFilter={deleteFilter}
				openModal={() => setDisplayAddressModal(true)}
				distance={distance}
				updateDistance={updateDistance}
			/>
			<SearchEmployment
				filterByPermanent={filterByPermanent}
				filterByFixedTerm={filterByFixedTerm}
				filterByInternShip={filterByInternShip}
				employment_type={employment_type}
			/>

			{isOnline && (
				<button
					className="btn btn-primary"
					onClick={() => setDisplayTemplateModal(true)}
					disabled={!canCreateTemplate}
				>
					<span className="icon-save-template"></span>
					<span>Save as search template</span>
				</button>
			)}
		</FiltersContainer>
	);
};

export default SearchFilters;
