import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import get from "lodash/get";
import { SENT } from "config";
import { ACTIONS } from "common/MessagingToolEmailView/utils/constants";
import template from "lodash/template";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";

const concatenateMessages = (email, subject, emailId) => {
	let content = `<p class="PlaygroundEditorTheme__paragraph"><br></p>`;
	const compiled = template(`<p class="PlaygroundEditorTheme__paragraph"><br></p>
							<p class="PlaygroundEditorTheme__paragraph" dir="ltr">
								<span style="white-space: pre-wrap;">------------------------ Forwarded message ------------------------</span>
							</p>
							<p class="PlaygroundEditorTheme__paragraph" dir="ltr">
								<span style="white-space: pre-wrap;">From: <%= from %></span><br>
								<span style="white-space: pre-wrap;">Date: <%= created_at %></span><br>
								<span style="white-space: pre-wrap;">Subject: <%= subject %></span><br>
								<span style="white-space: pre-wrap;">To: <%= to %></span>
							</p>
							<p class="PlaygroundEditorTheme__paragraph"><br></p>
							<p class="PlaygroundEditorTheme__paragraph" dir="ltr">
								<span style="white-space: pre-wrap;"><%= snippet %></span>
							</p>
							<p class="PlaygroundEditorTheme__paragraph"><br></p>
							`);
	if (emailId) {
		const reply =
			email._id === emailId
				? email
				: email.replies.find(reply => reply._id === emailId);
		content =
			content +
			compiled({
				from: reply.from.map(user => user.email).join(","),
				to: reply.to.map(user => user.email).join(","),
				created_at: window.moment
					.unix(reply.sent_at)
					.utc()
					.format("ddd, MMM D, YYYY [at] h:mm A"),
				subject,
				snippet: reply.snippet
			});

		return content;
	}
	[email, ...email.replies].forEach(item => {
		if (item.status === SENT) {
			content =
				content +
				compiled({
					from: item.from.map(user => user.email).join(","),
					to: item.to.map(user => user.email).join(","),
					created_at: window.moment
						.unix(item.sent_at)
						.utc()
						.format("ddd, MMM D, YYYY [at] h:mm A"),
					subject,
					snippet: item.snippet
				});
		}
	});

	return content;
};

const getEmail = (data, emailId, action, currentUserEmail, isDraft, hash) => {
	if (action === ACTIONS.REPLY) {
		if (isDraft || hash === MESSAGING_TOOL_LINKS.sent.slug) return data.to;
		else return data.from;
	}
	if (action === ACTIONS.REPLY_TO_ALL) {
		const emailSet = new Set();
		if (data.from[0].email !== currentUserEmail) emailSet.add(data.from[0]);
		data.replies.forEach(item => {
			item.from.forEach(fromItem => {
				if (fromItem.email !== currentUserEmail) emailSet.add(fromItem);
			});
		});

		return Array.from(emailSet);
	}
	if (data._id === emailId) return data.from;
	else if (data.replies) {
		const reply = data.replies.find(reply => reply._id === emailId);
		return reply.from;
	}
	return [];
};
export const getDefaultValues = (
	email,
	currentUserEmail,
	replyOrForward,
	isDraft,
	hash
) => {
	return {
		...EMPTY_EMAIL,
		subject: get(email, "subject", ""),
		content:
			replyOrForward.action === ACTIONS.FORWARD
				? concatenateMessages(email, email.subject, replyOrForward.emailId)
				: isDraft
				? email.content
				: "",
		to:
			replyOrForward.action === ACTIONS.REPLY ||
			replyOrForward.action === ACTIONS.REPLY_TO_ALL
				? getEmail(
						email,
						replyOrForward.emailId,
						replyOrForward.action,
						currentUserEmail,
						isDraft,
						hash
				  )
				: []
	};
};
