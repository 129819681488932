import React, { useState, useRef } from "react";
import { useMutation } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import cx from "classnames";
import _get from "lodash/get";
import { ReactComponent as ExclamationCircle } from "static/icons/exclamation-circle.svg";
import {
	UPDATE_PROFILE_PERSONAL_INFO,
	CHECK_ACTIVATE_ACCOUNT
} from "config/api-endpoints";
import { client } from "lib/api-client";
import toaster from "common/Toaster";
import useOnClickOutside from "hooks/useOnClickOutside";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import Loader from "common/Loader";

import style from "./shortListDrawer.module.scss";

const updateProfilePersonalInfo = data => {
	return client(UPDATE_PROFILE_PERSONAL_INFO, {
		body: {
			...data
		}
	});
};

const EmailHandler = ({
	activeCompany,
	profileId,
	notify,
	setDisableNotifyBtn
}) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [errorEmailExists, setErrorEmailExists] = useState(false);
	const [errorPermanentEmail, setErrorPermanentEmail] = useState(false);
	const [inputLoader, setInputLoader] = useState(false);
	const [userIdAfterMerge, setUserIdAfterMerge] = useState("");

	const schema = yup.object().shape({
		email: yup.string().email("Must be a valid email")
	});
	const {
		handleSubmit,
		formState: { errors },
		watch,
		control
	} = useForm({
		resolver: yupResolver(schema),
		shouldUnregister: false,
		defaultValues: {}
	});

	const tooltipRef = useRef(null);
	useOnClickOutside(tooltipRef, () => {
		setShowTooltip(false);
	});
	const [mutateEmail, { isLoading }] = useMutation(updateProfilePersonalInfo, {
		onSuccess: () => {
			toaster.success("Email address successfully added!");
			notify(userIdAfterMerge);
			closeModal();
			setDisableNotifyBtn(false);
			setInputLoader(false);
		},

		onError: error => {
			toaster.danger(_get(error, "detail.name"));
			setInputLoader(false);
		}
	});
	const closeModal = () => setOpenModal(false);
	const formValues = watch();

	const save = async () => {
		try {
			const { exists, type, can_merge, user_id } = await client(
				CHECK_ACTIVATE_ACCOUNT,
				{
					body: {
						email: _get(formValues, "email"),
						tag: "email",
						company_id: activeCompany,
						id: profileId
					}
				}
			);
			setInputLoader(true);
			if (exists) {
				if (
					type === "consultancy" ||
					type === "client" ||
					type === "freelancer"
				) {
					toaster.danger("This email already exists!");
					setErrorEmailExists(true);
					setErrorPermanentEmail(false);
					setDisableNotifyBtn(true);
					setInputLoader(false);
				}
				if (type === "permanent") {
					if (can_merge) {
						setErrorPermanentEmail(true);
						setErrorEmailExists(false);
						setDisableNotifyBtn(true);
						setInputLoader(false);
						setUserIdAfterMerge(user_id);
						return;
					}
					setErrorEmailExists(true);
					toaster.danger("This email already exists!");
					setInputLoader(false);
				}
				return;
			}
			mutateEmail({ id: profileId, email: _get(formValues, "email") });
		} catch (error) {
			toaster.danger(_get(_get(error, "detail"), "email"));
			setInputLoader(false);
		}
	};

	const onSubmit = () => {
		if (_get(formValues, "email")) {
			setDisableNotifyBtn(false);
			save();
		}
	};

	const onError = () => setDisableNotifyBtn(true);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit, onError)}>
				<Controller
					name="email"
					control={control}
					render={({ field: { onChange, value } }) => {
						return (
							<div className={style.inputEmailContainer}>
								<input
									placeholder="name@example.com"
									className={cx({
										[style.emailExistsInput]:
											errorEmailExists || _get(errors, "email.message")
									})}
									onBlur={handleSubmit(onSubmit, onError)}
									value={value}
									onChange={onChange}
								/>
								{inputLoader && (
									<div className={style.loader}>
										<Loader classes={"small"}></Loader>
									</div>
								)}
							</div>
						);
					}}
				/>

				<div className={style.inputEmailState}>
					{errorPermanentEmail && (
						<>
							<span ref={tooltipRef}>
								This email already exists &nbsp;
								<ExclamationCircle
									onClick={() => setShowTooltip(true)}
									style={{ cursor: "pointer" }}
								/>
								{showTooltip && (
									<div className={style.tooltip}>
										This email address is used by another candidate profile
										which you can merge in one profile!
									</div>
								)}
							</span>
							<button onClick={() => setOpenModal(true)}>Merge</button>
						</>
					)}
				</div>
				{errorEmailExists && (
					<div className={style.emailExists}>
						<span>This email already exists</span>
					</div>
				)}
				{_get(errors, "email.message") && (
					<div className={style.emailExists}>
						<span>Must be a valid email</span>
					</div>
				)}
			</form>

			<ConfirmationModal
				onClose={closeModal}
				active={openModal}
				loading={isLoading}
				modalName="existing_candidate"
				title="Existing Candidate"
				type={ConfirmationTypes.warning}
				className="confirmation_merge"
				warningText="This email address is used by another candidate profile!
	Do you want to merge both profiles?"
				firstButton={{
					action: () => {
						mutateEmail({
							id: profileId,
							email: _get(formValues, "email"),
							tag: "disable_refresh_notif"
						});
					},
					label: "Merge profiles",
					type: "warning"
				}}
				secondButton={{
					action: closeModal,
					label: "Cancel"
				}}
			/>
		</>
	);
};

export default EmailHandler;
