import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as ArrowRight } from "static/icons/arrow-right-new.svg";
import classNames from "classnames";
import Scrollbars from "react-custom-scrollbars";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import get from "lodash/get";
import SectionRow from "./SectionRow/SectionRow";
import { CANDIDATES_FILTER } from "../../../utils/constants";

const DrawerSection = ({
	section,
	rowSelected,
	deleteClicked,
	expanded,
	setExpanded
}) => {
	const { getState } = useFilters();
	const { selectedCriteria } = getState(CANDIDATES_FILTER);
	const [sectionData, setSectionData] = useState([]);

	useEffect(() => {
		if (section.type === "vacancies" && selectedCriteria) {
			if (get(selectedCriteria, "type") === "vacancy") {
				if (!section.data.find(el => el._id === selectedCriteria._id)) {
					section.data.unshift({
						_id: selectedCriteria._id,
						name: selectedCriteria.name,
						fields: selectedCriteria.fields
					});
				}
			}
		}
		setSectionData(section.data);
	}, [section, selectedCriteria]);

	return (
		<div className={styles.section} key={section.type}>
			<button
				className={styles.section__btn}
				onClick={() => setExpanded(section.type)}
			>
				<ArrowRight
					className={classNames(styles.arrow, { [styles.right]: expanded })}
				/>
				<p>
					{section.type}&nbsp;
					<span className={styles.green_span}>({section.count})</span>
				</p>
			</button>
			{expanded && (
				<div className={styles.section__content}>
					<Scrollbars autoHeight autoHeightMax={266} scrollbarWidth={3}>
						{sectionData.map(sec => {
							return (
								<SectionRow
									key={get(sec, "_id")}
									data={sec}
									rowSelected={rowSelected}
									deleteClicked={() => deleteClicked(sec)}
									selectedcriteriaId={get(selectedCriteria, "_id", "")}
								/>
							);
						})}
					</Scrollbars>
				</div>
			)}
		</div>
	);
};

export default DrawerSection;
