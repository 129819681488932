import React from "react";
import TableContainer from "../TableContainer";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import styles from "../TableContainer/table-container.module.scss";
import get from "lodash/get";
import { CANDIDATES_FILTER } from "../../utils/constants";
const TableView = ({
	tableSettings,
	resolvedData,
	checkCandidateSelection,
	onCheckboxChange,
	isSelectAll,
	onSelectAllChange,
	selectionState,
	selectedCandidatesCounter,
	offset,
	tableTag,
	refetch
}) => {
	const { getState } = useFilters();
	const { isLoading, isFetching } = getState(CANDIDATES_FILTER);
	return (
		<div>
			<p className={styles.results_count}>
				<span className={styles.results_count}>
					{selectedCandidatesCounter > 0 && (
						<span className={styles.grayText}>
							<span className={styles.greenText}>
								{selectedCandidatesCounter.toLocaleString().replace(",", " ")}
							</span>
							&nbsp;selected of&nbsp;
						</span>
					)}
				</span>
				<span className={styles.results_count}>
					{isLoading || isFetching ? (
						"Loading..."
					) : (
						<span className={styles.results_count}>
							{get(resolvedData, "total") === 0 ? (
								"No result found"
							) : (
								<span className={styles.grayText}>
									<span className={styles.greenText}>
										{get(resolvedData, "total")
											?.toLocaleString()
											?.replace(",", " ")}
									</span>
									&nbsp;
									{get(resolvedData, "total") > 1 ? "results" : "result"} in
									total
								</span>
							)}
						</span>
					)}
				</span>
			</p>
			{tableSettings?.columns?.length > 0 && (
				<TableContainer
					data={resolvedData}
					columns={tableSettings?.columns}
					checkCandidateSelection={checkCandidateSelection}
					onCheckboxChange={onCheckboxChange}
					isSelectAll={isSelectAll}
					onSelectAllChange={onSelectAllChange}
					selectionState={selectionState}
					selectedCandidatesCounter={selectedCandidatesCounter}
					offset={offset}
					tableTag={tableTag}
					refetch={refetch}
				/>
			)}
		</div>
	);
};
export default TableView;
