import React, { memo, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogTitle
} from "../../CreateNewSupplier/Dialog";
import s from "../../CreateNewSupplier/create-supplier.module.scss";
import Select from "../../../shared/Select";
import styles from "./services-filter-dialog.module.scss";

const options = [
	{
		label: "Contingent workers",
		value: "Contingent workers"
	},
	{ label: "Employed contractors", value: "Employed contractors" },
	{
		label: "Independent contractors (Freelancers)",
		value: "Independent contractors (Freelancers)"
	},
	{
		label: "Temp agency workers (interim)",
		value: "Temp agency workers (interim)"
	},
	{ label: "Permanent recruitment", value: "Permanent recruitment" }
];

function ServicesFilterDialog({ isOpen, onClose, store }) {
	const { setServices, services } = store(state => ({
		setServices: state.setServices,
		services: state.services
	}));

	const { handleSubmit, control, reset } = useForm({
		shouldUnregister: true,
		defaultValues: {
			services
		}
	});

	useEffect(() => {
		reset({ services });
	}, [reset, services]);

	const onSubmit = fields => {
		setServices(fields.services);
		onClose();
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent size={"lg"} onEscapeKeyDown={onClose}>
				<DialogTitle>Filter by Services</DialogTitle>
				<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						name={"services"}
						render={({ field: { onChange, value } }) => (
							<Select
								searchable={false}
								value={value}
								onChange={onChange}
								options={options}
							/>
						)}
					/>
					<div className={s.dialogFooter}>
						<button onClick={onClose} className={s.cancelButton}>
							Cancel
						</button>
						<button type={"submit"} className={s.mainButton}>
							{!services.length ? "Add to filters" : "Update filter"}
						</button>
					</div>
				</form>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default memo(ServicesFilterDialog);
