/* eslint-disable react/display-name */
import React, { memo, useMemo, useState } from "react";
import Card from "../CreateRequestCard/CreateRequestCard";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import styles from "./create-request-description-card.module.scss";
import {
	EDITOR_MAX_LENGTH,
	VACANCY_STEP_CARDS_IDS
} from "modules/CreateRequestModule/utils/constants";
import Editor from "common/Editor";
import { Controller } from "react-hook-form";
import { CreateRequestCustomInput } from "../CreateRequestCustomInput";
import { CreateRequestCardTitle } from "../CreateRequestCardTitle";
import { CreateRequestCardContent } from "../CreateRequestCardContent";
import { getHTMLTextLength } from "config/helpers";

const CreateRequestDescriptionCard = ({
	descriptionError,
	titleError,
	control,
	description
}) => {
	const [showToolbar, setShowToolbar] = useState(false);

	const descriptionLength = useMemo(() => getHTMLTextLength(description), [
		description
	]);

	return (
		<Card id={VACANCY_STEP_CARDS_IDS.description}>
			<CreateRequestCardTitle>Request description</CreateRequestCardTitle>
			<CreateRequestCardContent className={styles.cardContent}>
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>Request title</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<Controller
						name="title"
						control={control}
						render={({ field: { onChange, value, ref } }) => {
							return (
								<CreateRequestCustomInput
									placeHolder="A descriptive title of your job"
									onChange={onChange}
									value={value}
									ref={ref}
								/>
							);
						}}
					/>

					<CreateRequestFieldError error={titleError} />
				</CreateRequestFieldContainer>
				<CreateRequestFieldContainer>
					<CreateRequestLabelContainer>
						<CreateRequestFieldLabel>
							Request description
						</CreateRequestFieldLabel>
					</CreateRequestLabelContainer>
					<Controller
						name="description"
						control={control}
						render={({ field: { onChange, value, ref } }) => {
							return (
								<Editor
									ref={ref}
									focus={false}
									placeholder="Description of the role, tasks to perform, the profile sought, ..."
									scrollClassName={styles.editor}
									showToolbaar={showToolbar}
									setShowToolbar={setShowToolbar}
									useMentionPlugin={false}
									value={value}
									onChange={onChange}
									focusClassName={styles.editorFocused}
								/>
							);
						}}
					/>

					<div className={styles.errorContainer}>
						<CreateRequestFieldError error={descriptionError} />
						{!isNaN(descriptionLength) && (
							<div className={styles.lengthContainer}>
								{descriptionLength.toLocaleString("fr")}/
								{EDITOR_MAX_LENGTH.toLocaleString("fr")}
							</div>
						)}
					</div>
				</CreateRequestFieldContainer>
			</CreateRequestCardContent>
		</Card>
	);
};
export default memo(CreateRequestDescriptionCard);
