import React from "react";
import styles from "./header.module.scss";
import Skeleton from "../Skeleton";

const HeaderSkeleton = () => {
	return (
		<div className={styles.infoProfile}>
			<div className={styles.headline}>
				<Skeleton width="60px" height="60px" borderRadius="50%" />
				<div className={styles.rightSide}>
					<h1>
						<Skeleton width="150px" height="20px" borderRadius="5px" />
					</h1>
					<h2 className={styles.secondaryTitle}>
						<Skeleton width="180px" height="20px" borderRadius="5px" />
					</h2>
					<h2 className={styles.secondaryTitle}>
						<Skeleton width="80px" height="18px" borderRadius="5px" />
					</h2>
				</div>
			</div>
			<div className={styles.contactInfos}>
				<ul className={styles.listInfo}>
					<li>
						<Skeleton width="140px" height="18px" borderRadius="5px" />
					</li>
					<li>
						<Skeleton width="140px" height="18px" borderRadius="5px" />
					</li>
					<li>
						<Skeleton width="140px" height="18px" borderRadius="5px" />
					</li>
				</ul>
			</div>
			<div className={styles.infoProfile}>
				<Skeleton width="140px" height="18px" borderRadius="5px" />

				<Skeleton width="140px" height="18px" borderRadius="5px" />
			</div>
		</div>
	);
};

export default HeaderSkeleton;
