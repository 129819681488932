import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { PERMANENT } from "config";
import {
	showGetHelpSelector,
	showHelpModalSelector,
	helpModalFieldsSelector
} from "modules/app/selectors";
import { authenticatedUserSelector } from "modules/bids/selectors";
import { getSignUpFirstStepRoute } from "../SignUpFirstStepContainer";
import SubContainer from "../../SubContainer";
import cx from "classnames";
import useWindowSize from "hooks/useWindowSize";

function SuccessCreate({ email, handleResendEmail }) {
	const isMobile = useWindowSize();

	return (
		<SubContainer width={isMobile ? "auto" : 460}>
			<div className="success-content">
				<h3
					className={cx("heading", {
						["mobileHeading"]: isMobile
					})}
				>
					Your account has successfully been created!
				</h3>
				<p
					className={cx("description", {
						["mobileDescription"]: isMobile
					})}
				>
					We’ve sent you a link to confirm your email address. Please check your
					inbox. It could take up to 10 minutes to show up in your inbox.
				</p>
				<div
					className={cx("divider", {
						["mobileDivider"]: isMobile
					})}
				></div>
				<div className="instructions">
					<h4
						className={cx("heading", {
							["mobileDescription"]: isMobile
						})}
					>
						Didn’t receive the email?
					</h4>
					<ol>
						<li
							className={cx("item", {
								["mobileDescription"]: isMobile
							})}
						>
							<div>
								Is <b>{email}</b> your correct email without typos?
							</div>
							<div>
								If not,&nbsp;
								<Link
									className={cx("inline-link", {
										["mobileDescription"]: isMobile
									})}
									to={getSignUpFirstStepRoute(PERMANENT)}
								>
									you can restart the sign up process
								</Link>
							</div>
						</li>
						<li
							className={cx("item", {
								["mobileDescription"]: isMobile
							})}
						>
							Check your spam folder
						</li>
						<li
							className={cx("item", {
								["mobileDescription"]: isMobile
							})}
						>
							Add <u>noreply@wiggli.io</u> to your contacts
						</li>
						<li
							className={cx("item", {
								["mobileDescription"]: isMobile
							})}
						>
							Click{" "}
							<span
								className={cx("inline-link", {
									["mobileDescription"]: isMobile
								})}
								onClick={handleResendEmail}
							>
								here
							</span>{" "}
							to resend the email
						</li>
					</ol>
					<div
						className={cx("item", {
							["mobileDescription"]: isMobile
						})}
					>
						Still having trouble?&nbsp;
						<Link
							className={cx("inline-link", {
								["mobileDescription"]: isMobile
							})}
							to="/ask-question"
						>
							Contact us
						</Link>
					</div>
				</div>
			</div>
		</SubContainer>
	);
}

const mapStateToProps = state => ({
	user: authenticatedUserSelector(state),
	showGetHelp: showGetHelpSelector(state),
	showHelpModal: showHelpModalSelector(state),
	fields: helpModalFieldsSelector(state)
});

export default connect(mapStateToProps)(SuccessCreate);
