import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function CardSkeleton() {
	return (
		<SkeletonTheme color={"#dfe3e8"}>
			<Skeleton height={219} style={{ borderRadius: 12 }} />
		</SkeletonTheme>
	);
}
