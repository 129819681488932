import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import Select from "react-select";

import Card from "../Card";
import EmptyState from "../EmptyState";
import styles from "./cv-card.module.scss";
import { PDF_VIEWER_URL } from "config";

const CvCard = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	isLoading,
	pdf_doc
}) => {
	const [currentDoc, setCurrentDoc] = useState({ label: "", value: "" });

	useEffect(() => {
		const primary = find(pdf_doc, "is_primary") || pdf_doc[0];
		const label = get(primary, "file_name", "");
		const value = get(primary, "path", "");

		setCurrentDoc({ label, value });
	}, [isLoading, pdf_doc]);

	const renderOption = option => ({
		label: option.file_name,
		value: option.path
	});
	const options = map(pdf_doc, renderOption);
	const extraOptions = pdf_doc.length > 1 && (
		<div className={styles.selectContainer}>
			<Select
				clearable={false}
				searchable={false}
				options={options}
				onChange={selected => setCurrentDoc(selected)}
				value={currentDoc}
				placeholder={"More files"}
				dafaultValue={"More files"}
			/>
		</div>
	);

	let cvLink;
	const docValue = currentDoc.value;

	if (docValue) {
		const param = currentDoc.value.replace(/^http:\/\//i, "https://");
		cvLink = `${PDF_VIEWER_URL}${param}`;
	}

	return (
		<Card
			title={
				currentDoc.label ? (
					<div className={styles.title}>{currentDoc.label}</div>
				) : (
					<h2>CV</h2>
				)
			}
			{...{ onDragEnd, onViewMore, onToggleVisibility, extraOptions }}
		>
			<div className={styles.cvContainer}>
				{!isLoading ? (
					docValue ? (
						<iframe
							style={{
								width: "100%",
								minHeight: "832px",
								overflow: "hidden",
								border: "none"
							}}
							src={cvLink}
							key={cvLink}
							width="100%"
						></iframe>
					) : (
						<EmptyState />
					)
				) : (
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={"100%"} height={900} />
					</SkeletonTheme>
				)}
			</div>
		</Card>
	);
};
CvCard.prototype = {
	...Card.prototype,
	isLoading: PropTypes.bool.isRequired,
	cv: PropTypes.string.isRequired
};
export default CvCard;
