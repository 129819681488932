import React, { Component } from "react";
import TimePicker from "rc-time-picker";
import DatePickerField from "../DatePickerField";
import "rc-time-picker/assets/index.css";
import { Field } from "redux-form";
import {
	DATETIME_FORMAT,
	TIME_FORMAT_24,
	IN_DEF_START_TIME,
	IN_DEF_END_TIME,
	TIMEZONE
} from "../../config";
import moment from "moment";

class TimeChooser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startTime: props.previousStartTime
				? moment(props.previousStartTime, "HH:mm")
				: window.moment.tz(
						`01/01/1970 ${IN_DEF_START_TIME}`,
						DATETIME_FORMAT,
						TIMEZONE
				  ),
			endTime: props.previousEndTime
				? moment(props.previousEndTime, "HH:mm")
				: window.moment.tz(
						`01/01/1970 ${IN_DEF_END_TIME}`,
						DATETIME_FORMAT,
						TIMEZONE
				  ),
			valid: true
		};

		this.handlerStartTime = this.handlerStartTime.bind(this);
		this.handlerEndTime = this.handlerEndTime.bind(this);
		this.validate = this.validate.bind(this);
	}

	handlerStartTime(value) {
		const { handleCalendarChanged, isProposeModal } = this.props;
		isProposeModal && handleCalendarChanged(true);
		const startTime = value;
		const endTime = window.moment(value).add(1, "hours");

		this.setState({ startTime, endTime }, () => {
			this.validate().then(() => {
				this.props.handlerStartTime(
					startTime.format(TIME_FORMAT_24),
					this.state.valid
				);
				this.props.handlerEndTime(
					endTime.format(TIME_FORMAT_24),
					this.state.valid
				);
			});
		});
	}

	handlerEndTime(value) {
		const endTime = value;
		const { handleCalendarChanged, isProposeModal } = this.props;
		isProposeModal && handleCalendarChanged(true);
		this.setState({ endTime }, () => {
			this.validate().then(() => {
				this.props.handlerEndTime(
					endTime.format(TIME_FORMAT_24),
					this.state.valid
				);
			});
		});
	}

	validate() {
		const { startTime, endTime } = this.state;
		return new Promise(resolve => {
			this.setState({ valid: startTime < endTime });
			return resolve();
		});
	}

	updateDate = date => {
		const { handleCalendarChanged } = this.props;
		handleCalendarChanged(true);
		date = window.moment(date);
		this.setState(prevState => {
			const startTime = prevState.startTime.set({
				year: date.year(),
				month: date.month(),
				date: date.date()
			});
			const endTime = prevState.endTime.set({
				year: date.year(),
				month: date.month(),
				date: date.date()
			});
			return {
				startTime,
				endTime
			};
		});
	};

	render() {
		const {
			dateClass,
			timeStartClass,
			timeEndClass,
			label,
			handleCalendarChanged,
			displayResetIcon
		} = this.props;
		const errorClass = !this.state.valid ? "has-error has-feedback" : "";
		return (
			<div className="other-date row">
				<div className={dateClass}>
					<Field
						classes="inner-icon left-icon"
						icon="fa fa-calendar"
						name="start_date"
						label={label}
						component={DatePickerField}
						onChange={this.updateDate}
						minDate={window.moment().toDate()}
						handleCalendarChanged={handleCalendarChanged}
						displayResetIcon={displayResetIcon}
					/>
				</div>
				<div className={timeStartClass}>
					<div className={`form-group ${errorClass}`}>
						<label>start at</label>
						<div className="input-box withIcon">
							<i className="icon-watch2" />
							<TimePicker
								name="time_start"
								format={TIME_FORMAT_24}
								showSecond={false}
								value={this.state.startTime}
								className="time-picker"
								onChange={this.handlerStartTime}
								inputReadOnly={true}
								allowEmpty={false}
								defaultOpenValue={window.moment()}
							/>
						</div>
					</div>
				</div>
				<div className={timeEndClass}>
					<div className={`form-group ${errorClass}`}>
						<label>end at</label>
						<div className="input-box withIcon">
							<i className="icon-watch2" />
							<TimePicker
								name="time_end"
								format={TIME_FORMAT_24}
								showSecond={false}
								value={this.state.endTime}
								className="time-picker"
								onChange={this.handlerEndTime}
								inputReadOnly={true}
								allowEmpty={false}
								defaultOpenValue={window.moment()}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

TimeChooser.defaultProps = {
	label: "Time period",
	handleCalendarChanged: () => {}
};

export default TimeChooser;

// TODO move to redux-form
