import { useQuery } from "react-query";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getPrioritiesList = () => {
	return client(NEW_FILTER_SETTINGS, {
		body: { list: "custom_fields_manager", tag: "vacancy_priority" }
	});
};

export const GET_PRIORITIES_LIST_QUERY_KEY = "GET_PRIORITIES_LIST_QUERY_KEY";

export const useGetPrioritiesList = (options = {}) => {
	const res = useQuery(GET_PRIORITIES_LIST_QUERY_KEY, getPrioritiesList, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});

	return res;
};
