import React from "react";

import SupplierSearch from "../search";

export const getMySuppliersPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

function MySuppliers() {
	return <SupplierSearch tag={"company_pool"} />;
}

export default MySuppliers;
