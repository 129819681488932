import React from "react";
import _get from "lodash/get";
import cx from "classnames";
import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";
import { ReactComponent as ErrorIcon } from "static/icons/error-field.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete.svg";
import { PHONES, PHONE_DEFAULT_COUNTRY } from "config";
import styles from "./../../profile.module.scss";
import personalProfileStyles from "./personal-profile.module.scss";
import AddField from "../AddField";
import cardStyle from "../EditableSection/editable-section.module.scss";

const PhoneFields = ({
	personalProfile,
	isEditMode,
	errors,
	control,
	phones,
	phoneFields,
	onPhoneDelete,
	isNewPhoneExists,
	onBlur,
	onKeyDown,
	onPrimaryPhoneClick,
	onPhoneAdd,
	onChange
}) => {
	const isNewPhone = phone => {
		const phones = _get(personalProfile, PHONES, []);
		const index = phones.findIndex(phoneObject => phoneObject.phone === phone);

		return index === -1;
	};

	return (
		<div className={cx(cardStyle.grid, styles.MarginBottom1)}>
			{phoneFields.map((item, index) => {
				const error = _get(errors, `phones[${index}].phone.message`);
				const phoneValue = item.phone;
				const disabled = !isEditMode && !isNewPhone(phoneValue);

				return (
					<div key={item.id} className={styles.phoneBox}>
						{index === 0 && (
							<div className={styles.personalLabel}>Phone number</div>
						)}
						<Controller
							name={`phones[${index}].phone`}
							control={control}
							render={({ field: { onChange: onFormChange, value } }) => {
								return (
									<PhoneInput
										value={value}
										onBlur={onBlur}
										onKeyDown={onKeyDown}
										disabled={disabled}
										country={PHONE_DEFAULT_COUNTRY}
										onChange={(_, country, e, formattedValue) =>
											onChange(country, formattedValue, index, onFormChange)
										}
										containerClass={personalProfileStyles.phoneContainer}
										inputClass={personalProfileStyles.phoneInput}
										dropdownClass={personalProfileStyles.phoneDropDown}
										enableLongNumbers
									/>
								);
							}}
						/>
						{isEditMode && !error ? (
							<button
								className={styles.deleteItem}
								disabled={item.is_primary}
								onClick={e => onPhoneDelete(e, index)}
							>
								<DeleteIcon />
								Delete item
							</button>
						) : error && (isNewPhoneExists || isEditMode) ? (
							<div className={styles.error}>
								<ErrorIcon />
								{error}
							</div>
						) : (
							!isNewPhone(item.phone) &&
							!isEditMode && (
								<span className={styles.radio_input}>
									<Controller
										name={`phones[${index}].is_primary`}
										control={control}
										render={({ field: { value } }) => {
											return (
												<input
													type="radio"
													className="styled"
													name={`phone.is_primary`}
													checked={value}
													onChange={() => onPrimaryPhoneClick(index)}
													id={item.id}
												/>
											);
										}}
									/>
									<span className={styles.radio_control}></span>
									<label
										className={personalProfileStyles.radioButtonLabel}
										htmlFor={item.id}
									>
										Set as primary
									</label>
								</span>
							)
						)}{" "}
					</div>
				);
			})}
			<div className={cx(styles.addBox)}>
				{phoneFields.length === 0 && (
					<div className={cardStyle.label}>Phone number</div>
				)}
				{!(isEditMode || isNewPhoneExists) && (
					<AddField
						onClick={onPhoneAdd}
						label="Add phone"
						disabled={
							isEditMode ??
							_get(
								errors,
								`phones[${phoneFields.length - 1}].phone.message`,
								""
							).length > 5 ??
							(phones.length !== 0 &&
								!Boolean(phones[phones.length - 1]?.phone))
						}
					/>
				)}
			</div>
		</div>
	);
};
export default PhoneFields;
