import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import Drawer from "rc-drawer";
import styled from "styled-components";
import Select from "react-select";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import FormField from "common/FormField";
import { Tag } from "common/styled/CommonStyled";
import {
	INPUT_LENGTH,
	ADMIN,
	PROJECT_MANAGER,
	STEP_CREATE_DEPARTMENT,
	SUPER_ADMIN
} from "config";
import DepartmentSuccess from "./DepartmentSuccess";
import MultiValue from "common/MultiValue";
import styles from "./department-drawer.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/referral/x.svg";
import SettingsSwitcher from "modules/notifications/components/settings/SettingsSwitcher";
import FooterDrawer from "./FooterDrawer";

const SCSelect = styled(Select)`
	&.Select--multi {
		.Select-multi-value-wrapper {
			display: flex;
			height: 100%;
			align-items: center;
			flex-wrap: wrap;
		}
		.Select-input {
			height: auto;
			width: auto;
			margin: 18px 0 0 0;
		}
		.Select-aria-only {
			display: none;
		}
	}
`;

class DepartmentModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showExisiting: !!props.data._id,
			admins: [],
			project_managers: [],
			isClosing: false,
			isOpenDrawer: false,
			isTourMode: false,
			isSaving: false
		};
	}
	roles = [
		{
			name: ADMIN,
			label: "Admin"
		},
		{
			name: PROJECT_MANAGER,
			label: "Project Manager"
		}
	];

	submitForm = formProps => {
		this.setState({ isSaving: true });
		const { data, search, offset } = this.props;
		let { admins, project_managers, isTourMode } = this.state;
		const isEdit = !!data._id;
		admins = admins.reduce((acc, user) => {
			acc.push({ _id: user.value, role_name: ADMIN });
			return acc;
		}, []);
		project_managers = project_managers.reduce((acc, user) => {
			acc.push({ _id: user.value, role_name: PROJECT_MANAGER });
			return acc;
		}, []);

		let users = [];
		if (admins.length) users = users.concat(admins);
		if (project_managers.length) users = users.concat(project_managers);

		const payload = {
			name: formProps.name,
			users
		};

		if (isEdit) {
			payload.id = data._id;
		}
		this.props
			.createDepartment(payload, isTourMode, this.props.vacancyId, isEdit, {
				search,
				offset
			})
			.then(() => this.setState({ isSaving: true }));
	};

	componentDidMount() {
		const { data, initializeForm } = this.props;
		requestAnimationFrame(() => {
			this.setState({ isOpenDrawer: true });
		});
		let initialValue = {};
		if (data._id) {
			initialValue = {
				showExisiting: "checked",
				name: data.name
			};
			this.setState({
				admins: this.getInitialListFor(ADMIN),
				project_managers: this.getInitialListFor(PROJECT_MANAGER)
			});
		}
		initializeForm("department-modal", initialValue);
	}

	getInitialListFor = type => {
		const { connectedUser, data, usersList } = this.props;
		return data.user.reduce((acc, user) => {
			if (user._id !== connectedUser.id && user.role_name === type) {
				let groupsLength = _find(usersList, { _id: user._id }).groups.length;
				acc.push({
					value: user._id,
					label: `${user.first_name} ${user.last_name}`,
					clearableValue: groupsLength > 1
				});
			}
			return acc;
		}, []);
	};

	validateDeptName = value => {
		let error;
		if (!value) {
			error = "Department name is required.";
		} else {
			// no checks for edit mode
			if (!this.props.data._id) {
				this.props.deptList.map(dept => {
					if (dept.name === value) {
						error = "Department already exists.";
					}
					return "";
				});
			}
		}

		return error;
	};

	getUsersList = name => {
		const { usersList, connectedUser } = this.props;
		const { admins, project_managers } = this.state;
		const list = name === ADMIN ? project_managers : admins;
		return usersList.reduce((acc, user) => {
			const isInList = _findIndex(list, { value: user._id }) !== -1;
			if (
				!isInList &&
				connectedUser.id !== user._id &&
				user.role_name !== SUPER_ADMIN &&
				user.is_member
			)
				acc.push({
					value: user._id,
					label: `${user.first_name} ${user.last_name}`
				});
			return acc;
		}, []);
	};

	handleAddExitingChange = e =>
		this.setState({ showExisiting: e.target.checked });

	handleRolesChange = (values, name) => {
		this.setState(() => {
			if (name === ADMIN) return { admins: values };
			return { project_managers: values };
		});
	};

	closeDrawer = () => {
		this.setState({ isClosing: true });
		this.props.onClose();
	};

	handlTourMode = state => {
		this.setState({
			isTourMode: state
		});
	};

	render() {
		const {
			onClose,
			onBack,
			connectedUser,
			handleSubmit,
			data,
			usersList,
			toggleDepartmentModalSuccess
		} = this.props;
		const { showExisiting } = this.state;
		const isFirstStep = data.step === STEP_CREATE_DEPARTMENT;
		const isEdit = !!data._id;

		return (
			<>
				{!isFirstStep ? (
					<DepartmentSuccess
						vacancyId={this.props.vacancyId}
						isEdit={isEdit}
						onClose={onClose}
						onBack={onBack}
						createdDepartment={this.props.createdDepartment}
					/>
				) : (
					<Drawer
						open={this.state.isOpenDrawer}
						width="546px"
						height="100%"
						placement="right"
						style={{ zIndex: 1000 }}
						level={"root"}
						maskClosable={true}
						onClose={this.closeDrawer}
						className={this.state.isClosing ? "fade-out" : ""}
					>
						<div className={styles.drawerDepartmentContainer}>
							<header className={styles.header}>
								<h3 className={styles.title}>
									{isFirstStep
										? isEdit
											? "Edit Department"
											: "Add Department"
										: ""}
								</h3>
								<div
									role="button"
									tabIndex="0"
									onClick={onClose}
									className={styles.button}
								>
									<CloseIcon />
								</div>
							</header>
							<div className="department-content">
								<div className={styles.inputField}>
									<Field
										name="name"
										label="Department name"
										autoComplete="off"
										placeholder="Department Name"
										maxLength={INPUT_LENGTH}
										component={FormField}
										validate={this.validateDeptName}
									/>
								</div>

								<div className="form-group checkboxStyle">
									<SettingsSwitcher
										label={"Add existing users to this new department"}
										checked={showExisiting}
										onChange={this.handleAddExitingChange}
										name={"showExisiting"}
										classes="notifications-toggle"
									/>
								</div>
								{showExisiting && (
									<div className="department-box">
										<div className="dep-owner">
											<Tag background>
												{`${connectedUser.first_name} ${connectedUser.last_name}`}{" "}
												<span>Super Admin</span>
											</Tag>
											{usersList
												.filter(
													user =>
														user.role_name === SUPER_ADMIN &&
														connectedUser.id !== user._id
												)
												.map((tag, index) => (
													<Tag background key={index}>
														{`${tag.first_name} ${tag.last_name}`} -{" "}
														<span>Super Admin</span>
													</Tag>
												))}
										</div>
										{this.roles.map(role => (
											<div className="select-wrapper" key={role.name}>
												<div className="select-label">{role.label}</div>
												<div className="department-select">
													<SCSelect
														name={`${role.name}s`}
														onChange={selectedArray =>
															this.handleRolesChange(selectedArray, role.name)
														}
														isLoading={false}
														multi
														clearable={false}
														searchable={true}
														value={
															role.name === ADMIN
																? this.state.admins
																: this.state.project_managers
														}
														placeholder="Choose users"
														options={this.getUsersList(role.name)}
														valueComponent={MultiValue}
													/>
												</div>
											</div>
										))}
									</div>
								)}
							</div>

							<FooterDrawer
								submitForm={handleSubmit(this.submitForm)}
								onClose={this.closeDrawer}
								setIsTourMode={this.handlTourMode}
								isTourMode={this.state.isTourMode}
								toggleDepartmentModalSuccess={toggleDepartmentModalSuccess}
								isSaving={this.state.isSaving}
							/>
						</div>
					</Drawer>
				)}
			</>
		);
	}
}

export default reduxForm({
	form: "department-modal"
})(DepartmentModal);
