import React, { useState } from "react";
import Drawer from "rc-drawer";
import style from "./taskDetails.module.scss";
import StatusLabel from "common/StatusLabel";
import _get from "lodash/get";
import { browserHistory } from "react-router";
import { ReactComponent as CloseIcon } from "static/icons/delete-tag-multi-select-input.svg";
import { ReactComponent as ReminderIcon } from "static/icons/reminder.svg";
import { ReactComponent as TimeIcon } from "static/icons/time.svg";
import { ReactComponent as AgendaIcon } from "static/icons/agenda.svg";
import { ReactComponent as MarkAsCompletedIcon } from "static/icons/tick_minor.svg";
import { ReactComponent as ReminderIconButton } from "static/icons/reset_minor.svg";
import Avatar from "common/Avatar/index";
import Description from "common/Description";
import RenderLinkedTo from "./LinkedTo";
import useTaskDetails from "./api/useTaskDetails";
import { Loader } from "common/Loader";
import Reminder from "../table/action/reminder";
import { UPCOMING, COMPLETED, DATE_FORMAT, TIME_FORMAT_24 } from "config";
import { getLinks } from "modules/Tasks";
import { getLinksSupplier } from "modules/TasksSupplier";
import Tooltip from "rc-tooltip";
import { CONSULTANCY, UNPUBLISHED, ONBOARDING } from "config";
import useAddNewTaskStore from "modules/TasksList/AddNewTaskDrawer/hooks/useAddNewTaskStore";

const TaskDetailsDrawer = ({
	id,
	isOpenDetailsDrawer,
	setIsOpenDetailsDrawer,
	openModal,
	backLink = null,
	userConnected,
	companyType
}) => {
	const maxLength = 420;
	const [isReadMore, setIsReadMore] = useState(true);
	const [openReminderMenu, setIsOpenReminderMenu] = useState(false);
	const [reminderTargetRef, setReminderTargetRef] = useState(false);
	const closeDrawer = () => {
		setIsOpenDetailsDrawer(false);
		backLink && browserHistory.push(backLink());
	};
	const { setHighlitedRow } = useAddNewTaskStore();
	const { data, isLoading, isFetching } = useTaskDetails({ id });
	const timeConvert = n => {
		if (n < 60) {
			return n + " minutes";
		} else if (n < 1440) {
			return n / 60 + n / 60 > 1 ? " hours" : " hour";
		} else {
			return n / 60 / 24 + " day";
		}
	};
	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};
	const canAddReminder =
		_get(userConnected, "id") === _get(data, "assigned_user._id") &&
		_get(data, "status") !== COMPLETED;
	const canMarkedAsComplted =
		_get(userConnected, "id") === _get(data, "assigned_user._id") &&
		_get(data, "status") !== COMPLETED;

	const isBeforeOptions = _get(data, "status") === UPCOMING;

	const redirectToEntity = (e, entity) => {
		e.stopPropagation();
		const link =
			companyType === CONSULTANCY
				? getLinksSupplier(
						_get(entity, "linked_to.type", ""),
						_get(entity, "linked_to._id", "")
				  )
				: getLinks(
						_get(entity, "linked_to.type", ""),
						_get(entity, "linked_to._id", "")
				  );
		link && browserHistory.push(link);
	};

	const createdByName = `${_get(data, "created_by.first_name")} ${_get(
		data,
		"created_by.last_name"
	)}`;

	const assignedUserName = `${_get(data, "assigned_user.first_name")} ${_get(
		data,
		"assigned_user.last_name"
	)}`;
	const linkedToStatus = _get(data, "linked_to.status", "");
	const isLinkHidden =
		companyType === CONSULTANCY &&
		(linkedToStatus === UNPUBLISHED || linkedToStatus === ONBOARDING);

	return (
		<Drawer
			open={isOpenDetailsDrawer}
			width={`40vw`}
			height={"100%"}
			placement={"right"}
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={closeDrawer}
			className="task_details_drawer"
		>
			<div className={style.root}>
				<div className={style.header_drawer}>
					<h2 className={style.title_drawer}>
						Task details
						<div className={style.loaderContainer}>
							<Loader check={false} loading={isLoading} />
						</div>
					</h2>
					<button onClick={closeDrawer}>
						<CloseIcon />
					</button>
				</div>
				{!isLoading && (
					<div>
						<div className={style.assigned_block}>
							<div className={style.col_2}>
								<h3>Assign to</h3>

								<Tooltip
									overlay={assignedUserName}
									placement="top"
									trigger="hover"
								>
									<Avatar
										src={_get(data, "assigned_user.avatar")}
										size={30}
										name={assignedUserName}
										radius={30}
									/>
								</Tooltip>
							</div>
							<div className={style.col_2}>
								<h3>Assign by</h3>
								<Tooltip
									overlay={createdByName}
									placement="top"
									trigger="hover"
								>
									<Avatar
										src={_get(data, "created_by.avatar")}
										size={30}
										name={createdByName}
										radius={30}
									/>
								</Tooltip>
							</div>
						</div>
						<div className={style.block_status}>
							<div className={style.checkbox_completed_task}>
								{_get(data, "inform_task_completion") && (
									<>
										<input type="checkbox" id="completed_task" checked={true} />
										<label htmlFor="completed_task">
											Inform me when task is completed
										</label>
									</>
								)}
							</div>
							<StatusLabel
								status={_get(data, "status")}
								label={_get(data, "status")}
							/>
						</div>
						{_get(data, "title") && (
							<div>
								<h3>Task title</h3>
								<p>{_get(data, "title")}</p>
							</div>
						)}
						{_get(data, "description") && (
							<div>
								<h3>Description</h3>
								<p>
									{_get(data, "description", "").length > maxLength &&
									isReadMore ? (
										<Description
											description={`${_get(data, "description", "").slice(
												0,
												maxLength
											)}...`}
											parseMarkdown={true}
										/>
									) : (
										<Description
											description={_get(data, "description", "")}
											parseMarkdown={true}
										/>
									)}
									{maxLength < _get(data, "description", "").length && (
										<button onClick={toggleReadMore} className={style.readMore}>
											{isReadMore ? " Read more" : " Show less"}
										</button>
									)}
								</p>
							</div>
						)}
						<div className={style.date_priority}>
							<div className={style.date_reminder}>
								{_get(data, "due_date") && (
									<div className={style.col_2}>
										<h3>Due date</h3>
										<div className={style.date_block}>
											<span>
												<AgendaIcon />
												{window.moment
													.unix(_get(data, "due_date"))
													.format(DATE_FORMAT)}
											</span>
											<span>
												<TimeIcon />
												{window.moment
													.unix(_get(data, "due_date"))
													.format(TIME_FORMAT_24)}
											</span>
										</div>
									</div>
								)}
								{_get(data, "reminder.time") && (
									<div className={style.col_2}>
										<h3>Reminder</h3>
										<div className={style.date_block}>
											<span>
												{" "}
												<ReminderIcon />
												{timeConvert(_get(data, "reminder.time"))}{" "}
												{_get(data, "reminder.reminding_time")}
											</span>
										</div>
									</div>
								)}
							</div>
							<div className={style.date_reminder}>
								{_get(data, "priority") && (
									<div className={style.col_2}>
										<h3>Priority</h3>
										<div className={style.priority_status}>
											<StatusLabel
												status={_get(data, "priority")}
												label={_get(data, "priority")}
											/>
										</div>
									</div>
								)}
								{Boolean(Object.keys(_get(data, "linked_to", {})).length) && (
									<div className={style.col_2}>
										<h3>Link to</h3>
										{isLinkHidden ? (
											<span className={style.linkToText}>
												{" "}
												{_get(data, "linked_to.entity", "")} -{" "}
												{_get(data, "linked_to.title", "")}
											</span>
										) : (
											<button
												className={style.linkToButton}
												onClick={e => redirectToEntity(e, data)}
											>
												<span className={style.linkTo}>
													{" "}
													{_get(data, "linked_to.entity", "")} -{" "}
													{_get(data, "linked_to.title", "")}
												</span>
											</button>
										)}
									</div>
								)}
							</div>
						</div>

						<RenderLinkedTo
							linkedTo={_get(data, "linked_to")}
							closeDrawer={closeDrawer}
						/>
					</div>
				)}
			</div>
			<div className={style.footer_drawer}>
				<button className={style.buttonFooterDrawer} onClick={closeDrawer}>
					Cancel
				</button>
				{canAddReminder && (
					<div className={style.reminderButton}>
						<button
							onClick={() => setIsOpenReminderMenu(true)}
							className={style.buttonFooterDrawer}
							ref={setReminderTargetRef}
						>
							<ReminderIconButton />{" "}
							{isBeforeOptions ? "Reminder" : "Remind me"}
						</button>
						{openReminderMenu && (
							<div className={style.reminderContainer}>
								<Reminder
									id={id}
									onClose={() => setIsOpenReminderMenu(false)}
									isBeforeOption={isBeforeOptions}
									reminderTargetRef={reminderTargetRef}
									dueDate={_get(data, "due_date")}
									position="top"
									offset={[0, 5]}
									setHighlitedRow={setHighlitedRow}
								/>
							</div>
						)}
					</div>
				)}

				{canMarkedAsComplted ? (
					<button
						disabled={isFetching}
						className={style.buttonFooterDrawer}
						onClick={openModal}
					>
						<MarkAsCompletedIcon /> Mark as completed
					</button>
				) : null}
			</div>
		</Drawer>
	);
};
TaskDetailsDrawer.defaultProps = {
	companyType: ""
};

export default TaskDetailsDrawer;
