import useUpdateSettings from "modules/user-onboarding/hooks/useUpdateSettings";
import { ONBOARDING_STEPS_QUERY } from "modules/setupGuide/api/useOnboardingSteps";
import { queryCache } from "react-query";
import {
	UPDATE_SETTINGS_ONBOARDING_STEPS,
	UPDATE_SETTINGS_TYPE
} from "../../../config/onboarding";
import useOnboardingStore from "./useOnboardingStore";

// persist onboarding user settings
export const useOnboardingSaveData = ({ onTourClose }) => {
	const { metaData, setIsLoading } = useOnboardingStore();
	const [mutate] = useUpdateSettings();

	const updateUserSettings = () => {
		const payload = {
			type: UPDATE_SETTINGS_TYPE,
			onboarding_options: {
				value: metaData?.stepKey,
				onboarding_type: UPDATE_SETTINGS_ONBOARDING_STEPS
			}
		};

		// when step is already saved
		if (metaData?.stepIsDone) {
			setIsLoading(false);
			onTourClose();
			return;
		}

		return mutate(payload, {
			onSuccess: () => {
				queryCache.invalidateQueries(ONBOARDING_STEPS_QUERY);
				onTourClose();
			},
			onError: () => onTourClose(),
			onSettled: () => setIsLoading(false)
		});
	};

	return {
		persistData: updateUserSettings
	};
};
