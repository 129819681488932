//TODO We have 2 components common/Avatar and common/CustomAvatar, we should have only one
import React from "react";
import Avatar from "common/Avatar";
import { ReactComponent as PhoneIcon } from "static/icons/phone-grey.svg";
import { ReactComponent as LetterIcon } from "static/icons/letter-grey.svg";
import { ReactComponent as UserIcon } from "static/icons/user-icon-grey.svg";
import { ReactComponent as LinkedinIcon } from "static/icons/linkedin-icon.svg";

import { BidDetailsSkeletonCard } from "../BidDetailsSkeletonCard";
import { capitalize } from "common/Functions";
import _get from "lodash/get";
import styles from "./bid-details-header.module.scss";
import cx from "classnames";
import { CONSULTANCY, FREELANCER } from "config";
import { ConsultancyTooltip } from "../BidDetailsTooltips";
import ProfileInfoTooltip from "../BidDetailsTooltips/ProfileInfoTooltip";
import Tooltip from "common/Tippy";
import { BidActions } from "../BidActions";
import { BidMatchScore } from "../BidMatchScore";
import * as copyToClipboard from "copy-to-clipboard";

const ProfileHeader = ({
	data,
	isLoading,
	profileData,

	unlockedAndGoToProfile,
	onProposeInterviewDrawer,
	onAcceptInterviewModal,
	onAcceptContractModal,
	onSignContractAction
}) => {
	const userType = _get(data, "company_type", "");
	const isFREELANCER = userType === FREELANCER;
	const isCONSULTANCY = userType === CONSULTANCY;
	const typeData = isFREELANCER ? "profile_detail" : "user_consultancy";
	const firstName = _get(data, "profile_detail.first_name");
	const lastName = _get(data, "profile_detail.last_name");
	const fullName = firstName + " " + lastName;
	const email = _get(data, `${typeData}.email`);
	const phone = _get(data, `${typeData}.phone`);

	const matchScore =
		_get(data, "matching_rate") === 0 ? "0" : _get(data, "matching_rate");

	const linkedinUrl = `https://www.linkedin.com/search/results/people/?firstName=${firstName}&lastName=${lastName}`;

	const onCopy = text => {
		copyToClipboard(text);
	};

	return (
		<div className={styles.container}>
			<div className={styles.top}>
				<div className={styles.info}>
					<div className={styles.avatar} onClick={unlockedAndGoToProfile}>
						{isLoading ? (
							<BidDetailsSkeletonCard
								minWidth={56}
								maxWidth={56}
								height={56}
								style={{ borderRadius: 110 }}
							/>
						) : (
							<Avatar
								size={56}
								classNameOnly={true}
								className={styles.avatar}
								radius={12}
								avatar={_get(data, "profile_detail.avatar")}
								name={fullName}
							/>
						)}
					</div>
					<div className={styles.infoData}>
						<div className={styles.flex}>
							<span className={styles.name} onClick={unlockedAndGoToProfile}>
								{isLoading ? <BidDetailsSkeletonCard /> : fullName}
							</span>
							<ProfileInfoTooltip functions={_get(profileData, "functions")} />
						</div>
						<div className={styles.flex}>
							{isLoading ? (
								<BidDetailsSkeletonCard />
							) : (
								userType && (
									<div className={styles.type}>
										{capitalize(userType)}
										{isCONSULTANCY && <ConsultancyTooltip />}
									</div>
								)
							)}
							<BidMatchScore value={matchScore} />
						</div>
					</div>
				</div>
				{data && (
					<BidActions
						bid={data}
						onProposeInterviewDrawer={onProposeInterviewDrawer}
						onAcceptInterviewModal={onAcceptInterviewModal}
						onAcceptContractModal={onAcceptContractModal}
						onSignContractAction={onSignContractAction}
					/>
				)}
			</div>
			{_get(data, "show_profile") && (
				<>
					<div className={styles.divider}></div>
					{isCONSULTANCY && (
						<span className={styles.contact}>{"Manager's contact"}</span>
					)}
					<div className={styles.bottom}>
						{isCONSULTANCY && (
							<>
								{isLoading ? (
									<BidDetailsSkeletonCard />
								) : (
									<Tooltip
										className={styles.tooltip}
										content={
											_get(data, `${typeData}.first_name`) +
											" " +
											_get(data, `${typeData}.last_name`)
										}
										theme="dark"
										placement="top"
									>
										<div className={cx(styles.item, styles.item_name)}>
											<UserIcon />
											<span>
												{_get(data, `${typeData}.first_name`) +
													" " +
													_get(data, `${typeData}.last_name`)}
											</span>
										</div>
									</Tooltip>
								)}
							</>
						)}
						{isLoading ? (
							<BidDetailsSkeletonCard />
						) : (
							phone && (
								<Tooltip
									className={styles.tooltip}
									content={phone}
									theme="dark"
									placement="top"
								>
									<div className={styles.item} onClick={() => onCopy(phone)}>
										<PhoneIcon />
										<span className={styles.phone}>{phone}</span>
									</div>
								</Tooltip>
							)
						)}
						{isLoading ? (
							<BidDetailsSkeletonCard />
						) : (
							email?.length > 0 && (
								<Tooltip
									className={styles.tooltip}
									content={email}
									theme="dark"
									placement="top"
								>
									<div className={styles.item} onClick={() => onCopy(email)}>
										<LetterIcon />
										<span>{email}</span>
									</div>
								</Tooltip>
							)
						)}
						{isFREELANCER && (
							<div className={cx(styles.item, styles.item_a)}>
								<a target="_blank" rel="noopener noreferrer" href={linkedinUrl}>
									<LinkedinIcon size={20} />
								</a>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default ProfileHeader;
