import React from "react";
import VerticalSeparator from "../../verticalSeparator/VerticalSeparator";
import style from "./styles.module.scss";
import { ReactComponent as Calendar } from "../../../svg/Calendar.svg";
import { ReactComponent as Clock } from "../../../svg/Clock.svg";
import { formatDate } from "common/Functions";
import { TIME_FORMAT_SYMBOL } from "config";
const DateTime = ({ date }) => {
	return (
		<div className={style.containerDate}>
			<div className={style.date}>
				<VerticalSeparator />
				<Calendar />
				<span>{formatDate(date)}</span>
				<Clock />
				<span>{`${formatDate(date, true, TIME_FORMAT_SYMBOL)}`}</span>
			</div>
		</div>
	);
};

export default DateTime;
