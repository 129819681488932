import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { CHANGE_FILE_VISIBILITY_ENDPOINT } from "config/api-endpoints";

function updateVisibilty(body) {
	return client(CHANGE_FILE_VISIBILITY_ENDPOINT, {
		body
	});
}

function useUpdateVisibility(queryOptions) {
	return useMutation(updateVisibilty, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
}

export default useUpdateVisibility;
