import styled from "styled-components";

export const BannerHelpWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	position: relative;
	width: 100%;
	height: 100px;
	padding: 18.8px 32px 14.6px 29px;
	border-radius: 4px;
	box-shadow: 0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11);
	background-color: #ffffff;
	margin-top: 3rem;
	.section-1 {
		border-right: solid 1px #f1f2f5;
		align-items: center;
		display: flex;
		svg {
			width: 22px;
			height: 22px;
			margin-right: 26px;
		}
	}
	.section-2 {
		width: 80%;
	}
	.section-3 {
		.icon-close2 {
			position: absolute;
			right: 30px;
			top: 45px;
			color: #a1a5aa;
			cursor: pointer;
		}
	}
`;
export const BannerTitle = styled.h5`
	width: 31px;
	height: 11px;
	margin: 2.8px 1197.5px 3px 22.3px;

	font-size: 11px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.95;
	letter-spacing: 0.99px;
	text-align: left;
	color: #a0a5b9;
`;
export const BannerBody = styled.p`
	width: 1196px;
	height: 36px;
	margin: 9.9px 32.5px 0 22.3px;

	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: 0.1px;
	text-align: left;
	color: #3d4b60;
`;
export const IconHelp = styled.span`
	width: 22.5px;
	height: 22.5px;
	margin: 3px 22.3px 20.4px 0;
	padding: 5px 10px 4.5px;
	background-color: #55a3ff;
`;
