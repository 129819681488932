export const JOB_DATA = "JOB_DATA";
export const SET_STEP = "SET_STEP";
export const LIST_LANGUAGES = "LIST_LANGUAGES";
export const LIST_CATEGORIES = "LIST_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_SUBCATEGORIES = "SET_SUBCATEGORIES";
export const SET_FUNCTIONS = "SET_FUNCTIONS";
export const TOGGLE_FETCHING = "TOGGLE_FETCHING";
export const PUSH_TO_FIELD = "PUSH_TO_FIELD";
export const SET_SCORE = "SET_SCORE";
export const SET_JOB_DATA = "SET_JOB_DATA";
export const SET_SKILLS = "GET_SKILLS";
export const SET_JOB_DATA_FIELD = "SET_JOB_DATA_FIELD";
export const RESET_JOB_DATA = "RESET_JOB_DATA";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const FETCH_LIST_JOBS = "FETCH_LIST_JOBS";
export const SET_LIST_JOBS = "SET_LIST_JOBS";
export const FILTER_BY_STATUS = "FILTER_BY_STATUS";
export const UNPUBLISH_JOB = "UNPUBLISH_JOB";
export const PUBLISH_JOB_WARNING = "PUBLISH_JOB_WARNING";
export const SET_GROUPS = "SET_GROUPS";
export const SET_BIDS_LIST = "SET_BIDS_LIST";
export const GET_LIST_CURRENCIES = "GET_LIST_CURRENCIES";
export const SET_LIST_CURRENCIES = "SET_LIST_CURRENCIES";
export const SET_CAMPAIGNS_PERFORMANCE = "SET_CAMPAIGNS_PERFORMANCE";
