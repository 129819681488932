import React from "react";
import styles from "./messaging-tool-email-list-loading.module.scss";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";

const MessagingToolEmailListLoading = () => {
	return (
		<div className={styles.list}>
			{Array(9)
				.fill(0)
				.map((_, index) => (
					<div className={styles.listItem} key={index}>
						<Skeleton circle width={32} height={32} />
						<div className={cx(styles.column, styles.userInfo)}>
							<Skeleton width={129} height={10} />
							<Skeleton width={129} height={10} />
						</div>
						<div className={cx(styles.column, styles.subject)}>
							<Skeleton width={200} height={10} />
							<Skeleton width={482} height={10} />
						</div>
						<div className={styles.actions}>
							<Skeleton width={63} height={10} />
						</div>
					</div>
				))}
		</div>
	);
};

export default MessagingToolEmailListLoading;
