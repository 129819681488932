import React from "react";
import styled, { css } from "styled-components";
import { colors } from "config/styles";
import ProcessingFile from "common/Icons/ProcessingFile";
import EmptyFile from "common/Icons/EmptyCv";
import ProcessingError from "common/Icons/ProcessingError";

const Container = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
	margin:${props => props.isClientView && "50px 0px"};
	${props =>
		props.hasText &&
		props.error &&
		css`
			padding-bottom: 105px !important;
		`}
	${props =>
		props.hasText &&
		!props.error &&
		css`
			padding: 80px;
		`}

    .title {
        color: ${colors.blackText};
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0 7px 0;
    }
    .paragraph {
        color: ${colors.inputColor}
        font-size: 13px;
		font-weight: normal;
		${props =>
			props.error &&
			css`
				color: ${colors.clearFilterColor};
			`}
    }
    svg{
        width: 120px;
    }
`;

const EmptyCV = ({ text, loading, title, error, isClientView }) => (
	<Container error={error} hasText={text} isClientView={isClientView}>
		{loading ? error ? <ProcessingError /> : <ProcessingFile /> : <EmptyFile />}

		{title && <div className="title">{title}</div>}
		{text && <div className="paragraph">{text}</div>}
	</Container>
);

export default EmptyCV;
