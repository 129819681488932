import create from "zustand";

const initialState = {
	formValues: {
		evaluation_note: "",
		evaluation_score: "",
		vacancy: { value: "" }
	}
};

const store = set => ({
	...initialState,
	setFormValues: formValues => set(state => ({ ...state, formValues })),
	resetFormValues: () => set(() => initialState)
});

const useFormStoreEvaluationQuick = create(store);
export default useFormStoreEvaluationQuick;
