import React from "react";
import styles from "./note-custom-visibility-list.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/close-icon.svg";
import NoteCustomVisibilityListTags from "./NoteCustomVisibilityTags";

const NoteCustomVisibilityList = ({ visible, not_visible, onClose }) => {
	return (
		<div className={styles.container}>
			<button className={styles.close} onClick={onClose}>
				<CloseIcon />
			</button>
			{[...visible.users, ...visible.groups].length ? (
				<div className={styles.visibleSection}>
					<label>Visible to:</label>
					<div className={styles.listTags}>
						<NoteCustomVisibilityListTags
							users={visible.users}
							groups={visible.groups}
						/>
					</div>
				</div>
			) : null}
			{[...not_visible.users, ...not_visible.groups].length ? (
				<div className={styles.notVisibleSection}>
					<label>Not visible to:</label>
					<div className={styles.listTags}>
						<NoteCustomVisibilityListTags
							users={not_visible.users}
							groups={not_visible.groups}
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default NoteCustomVisibilityList;
