import React, { useMemo, memo } from "react";
import find from "lodash/find";
import {
	CONFIRMED,
	HIRED,
	ACCEPTED,
	LIVE,
	PUBLISHED,
	EXPIRED,
	DECLINED,
	FILLED,
	SENT,
	DRAFT,
	SIGNED,
	CANCELLED,
	REFUSED,
	REJECTED,
	DISPUTED,
	IN_MEDIATION,
	DONE,
	SUBMITTED,
	ONBOARDED,
	UNPUBLISHED,
	DELETED,
	EXTENDED,
	INTERRUPTED,
	POKE_DECLINED,
	SHORTLISTED,
	INTERVIEW_DECLINED,
	FINISHED,
	APPROVED,
	OPEN_FOR_INTERNAL_USE,
	CLOSED_TO_NEW_APPLICANTS,
	APPLIED,
	APPLY_CANCELLED,
	REQUEST_INTERRUPTION,
	OVERDUE,
	COMPLETED,
	UPCOMING,
	HIGH,
	MEDIUM,
	LOW
} from "config";
import { colors } from "config/styles";
import StyledLabel from "./style";
import Notification from "modules/vacancy/components/Notification";

const COLORS_BY_STATUS = [
	{
		status: [CONFIRMED, ONBOARDED, DONE],
		color: "white",
		backgroundColor: colors.interestConfirmed
	},
	{
		status: [OPEN_FOR_INTERNAL_USE],
		color: "white",
		backgroundColor: colors.internalColor
	},
	{
		status: [FILLED],
		color: colors.primaryColor,
		borderColor: colors.primaryColor,
		backgroundColor: colors.white,
		borderWidth: "0.5px",
		borderStyle: "solid"
	},
	{
		status: [HIRED, ACCEPTED, LIVE, SIGNED, APPROVED],
		color: "white",
		backgroundColor: colors.stepperIconColor
	},
	{
		status: [PUBLISHED],
		color: "white",
		backgroundColor: colors.greenModalBgColor
	},
	{
		status: [EXPIRED],
		color: colors.anchorsColor,
		backgroundColor: colors.lighterGray
	},
	{
		status: [SENT, UNPUBLISHED, INTERVIEW_DECLINED],
		color: "white",
		backgroundColor: colors.stoppedColor
	},
	{
		status: [CANCELLED, REFUSED, REJECTED],
		color: colors.anchorsColor,
		backgroundColor: colors.btnDisabled
	},
	{
		status: [EXPIRED, DISPUTED, IN_MEDIATION, DECLINED],
		color: "white",
		backgroundColor: colors.paused
	},
	{
		status: [SUBMITTED],
		color: "white",
		backgroundColor: colors.submittedColor
	},
	{
		status: [DRAFT],
		color: "white",
		backgroundColor: colors.disabledGray
	},
	{
		status: [DELETED],
		color: colors.anchorsColor,
		backgroundColor: colors.bodyBg
	},
	{
		status: [EXTENDED],
		color: "white",
		backgroundColor: colors.extendedColor
	},
	{
		status: [INTERRUPTED, POKE_DECLINED],
		color: "white",
		backgroundColor: colors.errorColor
	},
	{
		status: [SHORTLISTED],
		color: colors.stepperIconColor,
		borderColor: colors.stepperIconColor,
		borderWidth: "1px",
		borderStyle: "solid",
		backgroundColor: "white"
	},
	{
		status: [FINISHED],
		color: "white",
		backgroundColor: colors.primaryColor
	},
	{
		status: [CLOSED_TO_NEW_APPLICANTS],
		color: "white",
		backgroundColor: colors.closeColor
	},
	{
		status: [APPLIED],
		color: "white",
		backgroundColor: colors.appliedColor
	},
	{
		status: [APPLY_CANCELLED],
		color: colors.anchorsColor,
		backgroundColor: colors.btnDisabled,
		className: "applyCancelled"
	},
	{
		status: [REQUEST_INTERRUPTION],
		color: "white",
		backgroundColor: "#C44569"
	},
	{
		status: [OVERDUE],
		color: "white",
		backgroundColor: "#D82C0D"
	},
	{
		status: [COMPLETED],
		color: "white",
		backgroundColor: "#00A47C"
	},
	{
		status: [UPCOMING],
		color: "white",
		backgroundColor: "#FBAB21"
	},
	{
		status: [HIGH],
		color: "#D82C0D",
		borderColor: "#D82C0D",
		borderWidth: "1px",
		borderStyle: "solid",
		backgroundColor: "#FFF5EA"
	},
	{
		status: [MEDIUM],
		color: "#F0940E",
		borderColor: "#F0940E",
		borderWidth: "1px",
		borderStyle: "solid",
		backgroundColor: "#FFF4F4"
	},
	{
		status: [LOW],
		color: "#00A47C",
		borderColor: "#00A47C",
		borderWidth: "1px",
		borderStyle: "solid",
		backgroundColor: "#E2F1EA"
	}
];

function StatusLabel({
	status,
	label,
	isPoke,
	isInterview,
	is_applied,
	canDisplayNotif,
	fromDetails,
	isVacancies,
	...rest
}) {
	const getColors = status => {
		let finalStatus = status;

		if (isPoke && status === DECLINED) {
			finalStatus = POKE_DECLINED;
		}
		if (isInterview && status === DECLINED) {
			finalStatus = INTERVIEW_DECLINED;
		}
		if (status === CANCELLED && is_applied) {
			finalStatus = APPLY_CANCELLED;
		}

		if (status === REJECTED && is_applied) {
			finalStatus = APPLY_CANCELLED;
		}
		return (
			find(COLORS_BY_STATUS, obj => {
				return obj.status.includes(finalStatus);
			}) || {
				color: "red",
				backgroundColor: "white"
			}
		);
	};

	const statusColor = useMemo(() => getColors(status), [status]);

	return (
		<StyledLabel
			backgroundColor={statusColor.backgroundColor}
			color={statusColor.color}
			borderColor={statusColor.borderColor || ""}
			borderWidth={statusColor.borderWidth}
			borderStyle={statusColor.borderStyle}
			filled={status === FILLED}
			className={`status`}
			{...rest}
			isVacancies={isVacancies}
		>
			{status === FILLED && <i className="icon-check" />}
			{label}
			{canDisplayNotif && (
				<Notification
					className="notification-list"
					count="1"
					border="1px solid"
					leftDisplay={fromDetails ? "25px" : "55px"}
					topDisplay="-20px"
				/>
			)}
		</StyledLabel>
	);
}

export default memo(StatusLabel);
