import { createSelector } from "reselect";
import _get from "lodash/get";
import { notSelectedElements } from "../../job/selectors";
import { lightCVAddedLanguagesSelector } from "../../company/selectors/createCompanySelectors";

const getShowGetHelp = state => _get(state, "app.showGetHelp");
const getshowHelpModal = state => _get(state, "app.showHelpModal");
const getHelpModalFields = state => _get(state, "app.fields");
const getCategories = state => _get(state, "app.categories");
const getSubCategories = state => _get(state, "app.subcategories");
const getFunctions = state => _get(state, "app.functions");
const getSkills = state => _get(state, "app.skills");
const getLoading = state => _get(state, "app.loading");
const getProposeSkill = state => _get(state, "app.proposeSkill");
const getCountries = (state, all) =>
	_get(state, all ? "app.allCountries" : "app.euCountries");
const getFilterSettings = state => _get(state, "app.filterSettings");

const prepareForSelect = (data, label = "name", value = "_id") =>
	data.map(item => ({
		label: item[label],
		value: item[value]
	}));

export const getLanguages = state => _get(state, "app.languages");

export const showGetHelpSelector = createSelector(
	getShowGetHelp,
	showGetHelp => showGetHelp
);

export const showHelpModalSelector = createSelector(
	getshowHelpModal,
	showHelpModal => showHelpModal
);

export const helpModalFieldsSelector = createSelector(
	getHelpModalFields,
	fields => fields
);

export const categoriesSelector = createSelector(
	getCategories,
	categories => categories
);

export const subcategoriesSelector = createSelector(
	getSubCategories,
	subcategories => prepareForSelect(subcategories)
);

export const functionsSelector = createSelector(getFunctions, functions =>
	prepareForSelect(functions)
);

export const skillsSelector = createSelector(getSkills, skills =>
	skills.map(skill => ({ name: skill.name, _id: skill._id, score: 0 }))
);

export const languagesSelector = createSelector(getLanguages, languages =>
	prepareForSelect(languages, "language")
);

export const languagesListSelector = createSelector(
	languagesSelector,
	lightCVAddedLanguagesSelector,
	(languages, selected) =>
		notSelectedElements(languages, selected, "name", "label")
);

export const appLoadingSelector = createSelector(
	getLoading,
	loading => loading
);

export const proposeSkillSelector = createSelector(
	getProposeSkill,
	proposing => proposing
);

export const countriesSelector = createSelector(
	getCountries,
	countries => countries
);

const selectAxiosProps = state => _get(state, "app.axiosHelper", {});

export const axiosPropsSelectors = createSelector(
	selectAxiosProps,
	props => props
);

export const filterSettingsSelectors = createSelector(
	getFilterSettings,
	filter => filter
);
