import React, { Component, Fragment } from "react";
import BodyClassName from "react-body-classname";
import {
	BID,
	INTERVIEW,
	CONTRACT,
	ON_MISSION,
	SHORTLIST,
	OFFER,
	RECRUITMENT
} from "config";
import { connect } from "react-redux";
import _get from "lodash/get";
import { Helmet } from "react-helmet";
import { capitalize } from "common/Functions";
import { VACANCY } from "config";
import { getLastJob, getCampaignsPerformance } from "modules/job/actions";
import { jobDataSelector } from "modules/job/selectors";
import { campaignsPerformanceSelector } from "modules/job/selectors";
import { fetchCurrentCompany } from "modules/company/actions/identityActions";

export const getVacancyViewRoute = id => {
	return `/vacancy/${id !== undefined ? `${id}` : `:id`}`;
};

export const getJobViewPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

class JobView extends Component {
	getIndexOfStepper = () => {
		const activeStepper = _get(this.props, "job.stepper_status");
		switch (activeStepper) {
			case BID:
				return 0;
			case INTERVIEW:
				return 1;
			case CONTRACT:
				return 2;
			case ON_MISSION:
				return 3;
			default:
				return -1;
		}
	};

	getSteps = () => {
		const stepsStatus = _get(this.props, "job.visible_status");
		const activeStep = _get(this.props, "job.stepper_status");
		let stepOrder = [SHORTLIST, INTERVIEW, OFFER, RECRUITMENT];
		let steps = [];
		stepsStatus &&
			Object.keys(stepsStatus).forEach((key, index) => {
				steps = [
					...steps,
					{
						label: capitalize(key.replace("_", " ")), // fix for ON_MISSION
						status:
							stepOrder.indexOf(key) <= stepOrder.indexOf(activeStep)
								? !stepsStatus[key]
									? "process"
									: "finish"
								: "wait",
						boxText: `0${index + 1}`,
						step: index + 1,
						stepName: capitalize(key.replace("_", " ")),
						active: activeStep === key,
						done: activeStep && stepsStatus[key]
					}
				];
			});
		return steps;
	};

	componentDidMount() {
		this.props.getLastJob({ id: this.props.params.id, job_type: VACANCY });
		this.props.getCampaignsPerformance(this.props.params.id);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.params.id !== this.props.params.id) {
			this.props.getLastJob({ id: this.props.params.id, job_type: VACANCY });
			this.props.getCampaignsPerformance(this.props.params.id);
		}
	}

	render() {
		const {
			route: { title }
		} = this.props;

		return (
			<BodyClassName className="my-job job-view">
				<Fragment>
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
				</Fragment>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		job: jobDataSelector(state),
		campaignsPerformance: campaignsPerformanceSelector(state),
		connectedUser: {
			company_identity_status: _get(
				state,
				"auth.user.company_identity_status",
				""
			),
			company_payment_status: _get(
				state,
				"auth.user.company_payment_status",
				""
			),
			company_information_status: _get(
				state,
				"auth.user.company_information_status",
				""
			)
		}
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getLastJob: payload => dispatch(getLastJob(payload)),
		fetchCurrentCompany: () => dispatch(fetchCurrentCompany(false)),
		getCampaignsPerformance: payload =>
			dispatch(getCampaignsPerformance(payload))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobView);
