import React, { useState, useEffect } from "react";
import { Filter } from "common/newSearch/Styled";

const KeywordsSearch = ({ keywords, updateKeywordsValue, hasError }) => {
	const [search, setSearchValue] = useState(keywords);

	useEffect(() => {
		setSearchValue(keywords);
	}, [keywords]);

	const onSubmit = () => {
		updateKeywordsValue(search);
	};

	const handleKeyPress = e => {
		if (e.key === "Enter") {
			e.preventDefault();
			onSubmit();
		}
	};

	return (
		<Filter className={`filter-keywords`}>
			<div className="title">Keywords</div>

			<textarea
				id="keywords"
				placeholder="Start typing..."
				className="form-control textarea-no-resize"
				value={search}
				onChange={e => setSearchValue(e.target.value)}
				onKeyDown={handleKeyPress}
			/>
			<span className="help-block inline-error">{hasError && hasError}</span>
			<button className="btn btn-primary btn-search-search" onClick={onSubmit}>
				Search
			</button>
		</Filter>
	);
};

export default KeywordsSearch;
