import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import style from "../../my-company.module.scss";

const MultiSitesHeaderEmptyState = () => {
	return (
		<div className={style.multiSitesContainer}>
			<div className={style.tabs}>
				<SkeletonTheme style={{ borderRadius: "8" }} color="#E5E5E5">
					<Skeleton width={132} height={21} />
				</SkeletonTheme>
				<SkeletonTheme style={{ borderRadius: "8" }} color="#E5E5E5">
					<Skeleton width={132} height={21} />
				</SkeletonTheme>
				<SkeletonTheme style={{ borderRadius: "8" }} color="#E5E5E5">
					<Skeleton width={132} height={21} />
				</SkeletonTheme>
			</div>
			<SkeletonTheme style={{ borderRadius: "8" }} color="#E5E5E5">
				<Skeleton width={106} height={37} />
			</SkeletonTheme>
		</div>
	);
};

export default MultiSitesHeaderEmptyState;
