import styled, { css } from "styled-components";

import { Tr } from "../TimesheetStyled";

export const FormContainer = styled(Tr)`
	#date {
		height: 50px;
	}
	#description {
		height: 50px;
	}
	#tag {
		width: 100%;
		&.select-style.label-no {
			margin: 0;
		}
	}
	#rate {
		width: 100%;
		&.select-style.label-no {
			margin: 0;
		}
	}

	.DateInput.DateInput_1 {
		width: 100%;
	}
	${({ isLastItem }) =>
		isLastItem &&
		css`
			.SingleDatePicker_picker {
				top: unset !important;
				bottom: 66px !important;
			}
		`}
`;

export const FormContainerMultipleEdit = styled.div`
	#date {
		height: 50px;
	}
	#description {
		height: 50px;
	}
	#tag {
		width: 100%;
		&.select-style.label-no {
			margin: 0;
		}
	}
	#rate {
		width: 100%;
		&.select-style.label-no {
			margin: 0;
		}
	}

	.DateInput.DateInput_1 {
		width: 100%;
	}
	.SingleDatePicker_picker {
		top: unset !important;
		bottom: 66px !important;
	}
	grid-area: form;
	width: 100%;
	padding: 20px;
`;
