import { client } from "lib/api-client";
import { useMutation } from "react-query";
import { VALIDATE_LOCATION_ENDPOINT } from "config/api-endpoints";

const validateLocation = body => {
	return client(VALIDATE_LOCATION_ENDPOINT, {
		body
	});
};

function useValidateLocation() {
	return useMutation(validateLocation);
}

export default useValidateLocation;
