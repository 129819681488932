export const getDynamicContentRoute = () => {
	return "/settings/dynamic-content";
};

export const getDynamicContentPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
