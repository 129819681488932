import React from "react";
import styles from "./evaluation-template-render-drawer.module.scss";
import { ReactComponent as ThumbUpIcon } from "static/icons/thumb-up-2-icon.svg";
import { ReactComponent as ThumbsUpIcon } from "static/icons/thumbs-up-icon.svg";
import { ReactComponent as ThumbDownIcon } from "static/icons/thumb-down-icon.svg";
import { SCORE_QUESTION_ANSWERS_OPTIONS } from "config";

export const COMMENT_MAX_LENGTH = 1000;
export const TEXTAREA_MAX_LENGTH = 1000;

export const EVALUATION_SCORES_CARD_ITEMS = [
	{
		value: SCORE_QUESTION_ANSWERS_OPTIONS.bad,
		label: "Bad",
		icon: <ThumbDownIcon />,
		active_classname: styles.evaluationScoreBtn__red
	},
	{
		value: SCORE_QUESTION_ANSWERS_OPTIONS.acceptable,
		label: "Acceptable",
		icon: <ThumbUpIcon />,
		active_classname: styles.evaluationScoreBtn__yellow
	},
	{
		value: SCORE_QUESTION_ANSWERS_OPTIONS.excellent,
		label: "Excellent",
		icon: <ThumbsUpIcon />,
		active_classname: styles.evaluationScoreBtn__green
	}
];

export const EVALUATION_SCORES_ITEMS = [
	{
		value: 1,
		label: "No",
		icon: <ThumbDownIcon />,
		active_classname: styles.evaluationScoreBtn__red
	},
	{
		value: 2,
		label: "Acceptable",
		icon: <ThumbUpIcon />,
		active_classname: styles.evaluationScoreBtn__yellow
	},
	{
		value: 3,
		label: "Excellent",
		icon: <ThumbsUpIcon />,
		active_classname: styles.evaluationScoreBtn__green
	}
];

export const YES_NO_ITEMS = [
	{
		value: "true",
		label: "Yes",
		active_classname: styles.evaluationScoreBtn__green
	},
	{
		value: "false",
		label: "No",
		active_classname: styles.evaluationScoreBtn__red
	}
];
