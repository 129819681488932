import get from "lodash/get";
import * as yup from "yup";
import {
	INTERVIEW,
	PHONE_SCREEN,
	OFFER,
	APPLICATION,
	SHORTLIST,
	VIDEO_CONFERENCE,
	processStatus,
	PHONE_SCREENING,
	HIRED,
	VACANCY_LEVEL,
	CANCELLED,
	REJECTED
} from "config";

const sliceData = (data, size = 4) =>
	data.length > size ? data.slice(0, size) : data;

export const getPreferencesData = data => {
	const locations = get(
		data,
		"candidate_preference.front_sheet.preference.preferred_location",
		[]
	);
	const filteredLocations = locations.reduce((acc, curr) => {
		if (!curr.is_main) {
			acc.push(curr?.city ? `${curr.city}, ${curr.country}` : curr.city);
		}
		return acc;
	}, []);
	const expectedSalary = get(
		data,
		"candidate_preference.front_sheet.preference.expected_salary"
	);
	const currencyExpected = get(
		data,
		"candidate_preference.front_sheet.preference.currency_expected"
	);
	const paymentTimeExpected = get(
		data,
		"candidate_preference.front_sheet.preference.payment_time_expected"
	);
	const paymentTypeExpected = get(
		data,
		"candidate_preference.front_sheet.preference.payment_type_expected"
	);
	const extraBenefits = get(
		data,
		"candidate_preference.front_sheet.preference.extra_benefits"
	);

	return {
		filteredLocations,
		expectedSalary,
		currencyExpected,
		extraBenefits,
		paymentTimeExpected,
		paymentTypeExpected
	};
};

const getStatus = status => {
	return { visibleStatus: processStatus[status] || status };
};

const getType = data => {
	switch (true) {
		case get(data, "status") === HIRED:
			return HIRED;
		case get(data, "status") === REJECTED:
			return REJECTED;
		case get(data, "status") === CANCELLED:
			return CANCELLED;
		case get(data, "last_step.data.is_offer"):
			return OFFER;
		case get(data, "last_step.data.is_interview"):
			if (get(data, "last_step.data.location_type") === PHONE_SCREEN)
				return PHONE_SCREENING;
			return INTERVIEW;

		default:
			if (get(data, "poke_type") === APPLICATION) return APPLICATION;
			return SHORTLIST;
	}
};

export const getActivePrecesses = data => {
	const header = get(data, "vacancy");
	const status = get(data, "vacancy.status");

	const { visibleStatus } = getStatus(status);

	const type = getType(data);
	const applicationStage = get(data, "last_step.name");
	const applicationStatus = get(data, "status");
	const interviewStatus = get(data, "last_step.data.status");
	const applicationVisibleStatus = get(data, "visible_status");
	const lastStatusChangedOn = get(data, "status_changed_on");

	let body;

	switch (type) {
		case HIRED:
			body = {
				message: "This candidate was hired on",
				lastStatusChangedOn
			};
			break;
		case REJECTED:
			body = {
				date: get(data, "status_changed_on"),
				reasonOfReject: get(data, "rejection_reason")
			};
			break;
		case CANCELLED:
			body = {
				date: get(data, "status_changed_on"),
				message: `Application cancelled by `,
				user: get(data, "last_user_action.user_name")
			};
			break;
		case OFFER:
			body = {
				symbol: get(data, "last_step.data.symbol"),
				amount: get(data, "last_step.data.amount"),
				paymentType: get(data, "last_step.data.payment_type"),
				paymentTime: get(data, "last_step.data.payment_time"),
				startDate: get(data, "last_step.data.start_date"),
				otherBenefits: get(data, "last_step.data.other_benefits")
			};
			break;
		case INTERVIEW: {
			const description =
				get(data, "last_step.data.description", "").length > 150
					? `${get(data, "last_step.data.description", "").slice(0, 150)}...`
					: get(data, "last_step.data.description", "");
			const locationLabel =
				get(data, "last_step.data.location_type") === VIDEO_CONFERENCE
					? "Location - video conference"
					: "Location - Company Address";
			const city = get(data, "last_step.data.city");
			const country = get(data, "last_step.data.country");
			const street = get(data, "last_step.data.street");
			const zip = get(data, "last_step.data.zip");
			const location =
				get(data, "last_step.data.location_type") === VIDEO_CONFERENCE
					? "Virtual Meeting Room"
					: `${street}, ${zip} ${city},
             ${country}`;

			body = {
				startDate: get(data, "last_step.data.new_interview_start_date"),
				endDate: get(data, "last_step.data.new_interview_end_date"),
				locationType: get(data, "last_step.data.location_type"),
				attendeesUsers: get(data, "last_step.data.attendees_users", []),
				locationLabel,
				location,
				description
			};
			break;
		}
		case PHONE_SCREENING:
		case PHONE_SCREEN:
			body = {
				startDate: get(data, "last_step.data.new_interview_start_date"),
				endDate: get(data, "last_step.data.new_interview_end_date"),
				description: get(data, "last_step.data.description")
			};
			break;

		case SHORTLIST:
			body = {
				message: "Added to sourced",
				lastUserAction: get(data, "last_user_action.user_name"),
				statusChangedOn: get(data, "status_changed_on")
			};

			break;
		case APPLICATION:
			body = {
				message: "Proactively applied",
				sourceProfile: get(data, "source_profile"),
				createdAt: get(data, "created_at"),
				source: get(data, "source"),
				referredBy: get(data, "referred_by")
			};
			break;
		default:
			body = {
				message: "Added to sourced",
				lastUserAction: get(data, "last_user_action.user_name"),
				statusChangedOn: get(data, "status_changed_on")
			};
			break;
	}
	return {
		header: { visibleStatus, ...header },
		body: {
			type,
			applicationStatus,
			applicationVisibleStatus,
			lastStatusChangedOn,
			applicationStage,
			interviewStatus,
			...body
		}
	};
};

export const getFunctionsData = data => {
	const functions = get(data, "functions", []);
	const filteredFunction = functions.map(
		({ is_main, name, seniority, sector, is_bot }) => {
			const parent_sector = get(sector, "parent_sector.name", "");
			const sector_name = get(sector, "name", "");

			const seniorityLabel = VACANCY_LEVEL.find(
				level => level.value === seniority
			)?.label;
			const tooltip = `
					${parent_sector} > ${sector_name} > ${name}
				`;
			return {
				is_main,
				name,
				tooltip,
				seniorityLabel,
				is_bot
			};
		}
	);
	const sortedFunction = filteredFunction.sort((x, y) =>
		x.is_main ? -1 : y.is_main ? 1 : 0
	);
	return sliceData(sortedFunction);
};
export const getNotesData = (data = [], userId) => {
	const mappedNotes = data.map(note => {
		const fullName = `${get(note, "created_by.first_name", "")} ${get(
			note,
			"created_by.last_name",
			""
		)}`;

		return {
			fullName: userId === get(note, "created_by._id") ? "You" : fullName,
			linkedTo: get(note, "job.title", false) || "Profile",
			created_at: get(note, "created_at"),
			created_by: get(note, "created_by"),
			description: get(note, "description"),
			job: get(note, "job", {}),
			job_id: get(note, "job_id", "")
		};
	});

	return sliceData(mappedNotes);
};

export const getUserInteractions = data => {
	const mappedInteractions = data.map(interaction => {
		const fullName = `${get(interaction, "user_action.first_name", "")} ${get(
			interaction,
			"user_action.last_name",
			""
		)}`;
		return {
			fullName: fullName.length > 2 ? fullName : "N/A",
			jobTitle: get(interaction, "job.title", ""),
			createdAt: get(interaction, "created_at"),
			historyType: get(interaction, "history_type")
		};
	});

	return sliceData(mappedInteractions);
};

export const handleCheckEmail = (
	{
		exists,
		type,
		can_merge,
		profile_id,
		removed_profile_id,
		blocked,
		can_add_to_pool,
		is_subscribed,
		user_id
	},
	index,
	setError,
	canMerge,
	setCanMerge,
	clearErrors,
	canAddToPool,
	setCanAddToPool,
	isProfileAreadyInPool,
	setIsProfileAreadyInPool,
	profileId
) => {
	if (exists) {
		if (type !== "permanent") {
			setError(`emails[${index}].email`, {
				type: "manual",
				message: "Email already exists!"
			});
		} else {
			if (can_merge) {
				setError(`emails[${index}].email`, {
					type: "manual",
					message: "Email is used for another profile!"
				});
				if (profile_id) {
					const canMergeUpdated = canMerge.map((mergeItem, i) => {
						if (index !== i) return mergeItem;
						else return { index, profile_id, removed_profile_id };
					});
					setCanMerge(canMergeUpdated);
				}
			} else if (can_add_to_pool) {
				const canAddToPoolUpdated = canAddToPool.map((item, i) => {
					if (index !== i) return item;
					else return { index, profile_id: user_id };
				});

				setCanAddToPool(canAddToPoolUpdated);
			} else if (
				!blocked &&
				is_subscribed &&
				!can_merge &&
				user_id !== profileId
			) {
				const alreadyInPoolUpdated = isProfileAreadyInPool.map((item, i) => {
					if (index !== i) return item;
					else return { index, profile_id: user_id };
				});

				setIsProfileAreadyInPool(alreadyInPoolUpdated);
			}
			setError(`emails[${index}].email`, {
				type: "manual",
				message: "Email already exists!"
			});
		}
	} else {
		clearErrors(`emails[${index}].email`);
	}
};

export const mapFields = (fields, type, baseField) =>
	fields.map((item, index) => ({
		[type]: item[type],
		is_primary: baseField[index]?.is_primary || false
	}));

yup.addMethod(yup.string, "duplicatePhone", function() {
	return this.test({
		name: "duplicatePhone",
		message: "Phone already exists!",
		test: (phoneValue, { options }) => {
			const { index: phoneIndex, from } = options;
			const { phones } = from[1].value;

			const index = phones.findIndex(
				(phoneItem, i) => phoneItem.phone === phoneValue && i !== phoneIndex
			);

			return index === -1;
		}
	});
});

yup.addMethod(yup.string, "duplicateLink", function() {
	return this.test({
		name: "duplicateLink",
		message: "External profile already exists!",
		test: (linkValue, { options }) => {
			const { index: linkIndex, from } = options;
			const { external_links } = from[1].value;

			const index = external_links.findIndex(
				(item, i) => item.link === linkValue && i !== linkIndex
			);

			return index === -1;
		}
	});
});

const externalLinkRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/;

export const personalProfileSchema = yup.object().shape({
	first_name: yup.string().required("Please fill the first name"),
	last_name: yup.string().required("Please fill the last name"),
	emails: yup.array().of(
		yup.object().shape({
			email: yup
				.string()
				.required("Please fill the email")
				.email("Invalid email address."),
			is_primary: yup.boolean()
		})
	),
	phones: yup.array().of(
		yup.object().shape({
			phone: yup
				.string()
				.min(14, "The input does not match a phone number format..")
				.max(20, "The input does not match a phone number format...")
				.duplicatePhone(),

			is_primary: yup.boolean()
		})
	),
	external_links: yup.array().of(
		yup.object().shape({
			link: yup
				.string()
				.required("Please fill the external profile")
				.matches(externalLinkRegex, "Enter correct url!")
				.duplicateLink(),
			is_primary: yup.boolean()
		})
	)
});
