import styled from "styled-components";
import { colors } from "config/styles";

export const Container = styled.div`
	display: flex;

	.notifications {
		width: 50%;
		.notification-select {
			.duration-filter {
				width: 180px;
				margin: 0;
				float: right;
			}
		}
	}
	.pokes {
		width: 45%;
		margin-left: 20px;
		label {
			min-width: 48% !important;
		}
		.setting-description {
			margin-top: 5px;
			font-size: 12.5px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.94;
			letter-spacing: normal;
			text-align: left;
			color: ${colors.darkGray};
		}
	}
	.poke-icon {
		width: 20px;
		margin-right: 8px;
		vertical-align: sub;
	}
`;
export const ToggleTooltip = styled.span`
	margin-left: ${props => props.isPermanentView && "114px"};
	.icon-check {
		font-size: 14px;
	}
`;

export const SwitcherWrapper = styled.div`
	display: flex;
	align-items: center;
	label {
		flex-grow: 1;
		font-family: "Work Sans";
		font-size: 16px;
		font-weight: 500;
		letter-spacing: -0.5px;
		color: #1e293b;
		margin-bottom: 0;
	}
	padding: 16px 12px 16px 16px;
	border: 1px solid #f3f4f6;
	border-radius: 8px;
	&:not(:last-child) {
		margin-bottom: 12px;
	}
	.react-toggle {
		display: block;
		.react-toggle-track {
			background-color: #e5e7eb;
		}
		&--checked {
			.react-toggle-track {
				background-color: #339d95;
			}
		}
		.react-toggle-thumb {
			transform: scale(0.8);
		}
	}
`;
