import React from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import { TextHeadline } from "../personalTab";

const Styles = styled.div`
	table {
		border-spacing: 0;
		width: 100%;
		tr {
			:last-child {
				td {
					border-bottom: 0;
					width: 25%;
				}
			}
		}

		th,
		td {
			margin: 0;
			padding: 1rem 4rem 1rem 0rem;
			border-bottom: 1px solid #f6f7fb;
			border-radius: 10px;
			width: 25%;
			:last-child {
				border-right: 0;
			}
		}
	}
`;
const columns = [
	{
		Header: <TextHeadline mb={0}>User</TextHeadline>,
		accessor: "user_name"
	},
	{
		Header: <TextHeadline mb={0}> Date</TextHeadline>,
		accessor: "created_at"
	},
	{
		Header: <TextHeadline mb={0}>Vacancy</TextHeadline>,
		accessor: "job_title"
	},
	{
		Header: <TextHeadline mb={0}>Interaction</TextHeadline>,
		accessor: "history_type"
	}
];
const Table = ({ data }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data
	});

	return (
		<Styles>
			<table {...getTableProps()}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, index) => (
								<th key={index} {...column.getHeaderProps()}>
									{column.render("Header")}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, index) => {
						prepareRow(row);
						return (
							<tr key={index} {...row.getRowProps()}>
								{row.cells.map((cell, index) => {
									return (
										<td key={index} {...cell.getCellProps()}>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</Styles>
	);
};

export default Table;
