import styled from "styled-components";
import Modal from "common/modal/Modal";
import { colors } from "config/styles";

import { Box } from "rebass";

export const SelectTitle = styled.div`
	display: block;
	font-weight: 500;
	color: #58626b;
	text-transform: uppercase;
	margin-bottom: 5px;
`;

export const ErrorLabel = styled.div`
	color: #f76d75;
	height: auto;
	line-height: 16px;
	min-height: 31px;
	padding: 0px 0 10px 15px;
	text-transform: none;
	white-space: normal;
	clear: both;
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
`;

export const StyledModal = styled(Modal)`
	.modal-actions {
		float: right;
		margin-top: 30px;
	}
	.modal-actions div:first-child {
		float: right;
		margin-left: 15px;
	}
`;

export const ModalWrapper = styled.div`
	padding: 0 16px;
	margin-bottom: 5px;
	.cards_container {
		display: grid;
		grid-template-columns: 50% 50%;
		gap: 20px;
		margin: 0 20px 20px 0;
		.col-md-6 {
			padding: 0;
			width: 100%;
			&.active {
				label {
					border: solid 1px #ff3649;
					border-radius: 4px;
					box-shadow: 0px 0px 4px #ff3649;
				}
			}
		}
	}
	.g-tool {
		width: 100% !important;
		margin-left: unset !important;
		margin-right: unset !important;
		.col-md-6 {
			padding: unset !important;
		}
	}
	h3 {
		font-family: "basiercircle";
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		color: #6e7191;
		margin-bottom: 35px;
	}
`;

export const FieldBlock = styled.div`
	height: 190px;
	border-radius: 4px;
	background-color: #ffffff;
	position: relative;
	label {
		display: block;
		padding: 18px 18px 25px 30px;
		height: 100%;
		margin-bottom: 0;
		cursor: pointer;
		border: solid 1px rgb(229, 235, 241);
		border-radius: 4px;
		&.disabled {
			cursor: not-allowed;
		}
	}
	.checkmark {
		position: absolute;
		top: 12px;
		left: 12px;
		height: 25px;
		width: 25px;
		border: solid 2px rgb(213, 220, 230);
		background-color: #ffffff;
		border-radius: 50%;
		&:after {
			content: "";
			position: absolute;
			display: none;
		}
	}
	.container-label input:checked ~ .checkmark {
		background-color: red;
		border: solid 1px red;
	}
	.container-label input:checked ~ .checkmark:after {
		display: block;
	}
	.container-label .checkmark:after {
		left: 9px;
		top: 4px;
		width: 6px;
		height: 13px;
		border: solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	.icon {
		font-size: 52px;
		margin-bottom: 20px;
		display: block;
		text-align: center;
		margin-top: 10px;
	}
	input:checked ~ .radio-btn {
		color: #2570b7;
	}
	.radio-btn {
		color: #d5dce6;
		width: 30px;
		height: 30px;
	}
	> div {
		position: absolute;
		top: 18px;
		right: 18px;
	}
	.text-label {
		display: block;
		font-weight: normal;
		font-family: "basiercircle";
		font-size: 14px;
		line-height: 20px;
		color: #6e7191;
		text-align: center;
		strong {
			display: block;
			font-family: "basiercircle";
			font-weight: 600;
			font-size: 14px;
			line-height: 22px;
			color: #4e4b66;
			text-align: center;
		}
	}
`;

export const HeaderModal = styled.div`
	background: #ffffff;
	font-size: 19px;
	font-weight: 600;
	color: #273238;
	margin-bottom: 16px;
`;

export const Channels = styled.div`
	min-height: 360px;
	background-color: #f1f2f5;
`;
export const PaginationChannels = styled.div`
	text-align: center;
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	.pagination {
		float: none;
		display: inline-block;
		margin: 0;
		margin-top: 20px;
		li.previous {
			margin-right: 5px;
		}
		li.next {
			margin-left: 5px;
		}
		li.previous,
		li.next {
			> a {
				visibility: hidden;
				font-size: 3px;
				line-height: 18px;
			}
		}
		li.next {
			margin-right: 0;
		}
		li.previous:before {
			left: 0;
		}
		li.next:after {
			right: 0;
		}
	}
`;
export const Label = styled.span`
	color: #58626b;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
`;

export const ClearFilterButton = styled.button`
	display: flex;
	justify-content: center;
	background-color: #fff;
	width: 23px;
	height: 23px;
	border-radius: 50%;
	border: none;
	color: #ffffff;
	position: absolute;
	right: 10px;
	line-height: 21px;
	padding: 0;
	outline: none;
	top: 12px;
	right: 26px;
	.icon-reset-3 {
		color: #c1c1c1;
		font-size: 25px;
		&:hover {
			color: #e1e1e1;
		}
	}
`;

export const FilterBlock = styled.div`
	margin-bottom: 10px;
	.sortBy {
		strong {
			color: #58626b;
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
			float: left;
			margin-right: 18px;
			margin-top: 17px;
		}
		.Select {
			width: 210px;
			float: left;
		}
	}
	.selectAllChannels {
		margin-top: 14px;
		overflow: hidden;
		> div {
			float: right;
		}
		strong {
			float: left;
			margin-right: 18px;
			color: #58626b;
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
			margin-top: 4px;
		}
		label {
			float: left;
		}
	}
`;

export const Channel = styled.div`
	overflow: hidden;
	height: 100%;
	border-radius: 3px;
	box-shadow: 0px 1px 1px 0 rgba(197, 199, 206, 0.25);
	border: solid 0.5px rgb(228, 228, 228);
	background-color: rgb(255, 255, 255);
	margin-bottom: ${({ isActiveChannel }) => (isActiveChannel ? "10px" : "0px")};
	width: 95%;
	padding: 12px;
	.desc {
		font-size: 13px;
		color: rgb(36, 47, 67);
	}
	.icon-save-template {
		color: #8a95a1;
		font-size: 17px;
	}
	.icon-save-template:hover {
		color: #106dd5;
	}
	.remove-channel {
		font-size: 17px;
	}
	.actionChannel {
		span {
			cursor: pointer;
		}
		.trash-img {
			cursor: pointer;
			width: 14px;
			height: 14px;
			color: #8a95a1;
		}
		.trash-img:hover {
			fill: #ff3649;
		}
	}
	.channel-value {
		font-size: 15px;
		color: #273238;
		font-weight: 500;
		margin: 7px 0px;
	}
`;

export const EmptySearchContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 150px;

	img {
		width: 114px;
		height: 71px;
		margin-bottom: 15px;
	}
	.title {
		font-size: 16px !important;
		font-weight: bold;
		color: #242f43;
		text-align: center;
	}
`;

export const DisabledContainer = styled.div`
	cursor: not-allowed;
	opacity: 0.45;
`;

export const TagsChannel = styled.div`
	font-size: 15px;
	font-weight: normal;
	color: #7e828e;
	margin-right: 16px;
	align-items: center;
	display: flex;
`;
export const FormContainer = styled.div`
	.form-group {
		&:after {
			display: none;
		}
		margin-bottom: 31px;
	}

	.btn {
		float: right;
		margin-top: 30px;
		padding: 14px 24px !important;
		width: 100px;
		margin-left: 14px;
		&.btn-outline {
			background-color: white;
			color: ${colors.anchorsColor};
			border: 1.5px solid #b9bdc0;
			&:hover {
				background-color: ${colors.anchorsColor};
				color: ${colors.white};
				border-color: ${colors.anchorsColor};
			}
		}
	}
	.inline-error {
		&.phone {
			margin-top: -31px;
		}
	}
	#accept_policies {
		width: 50px !important;
		height: 60px !important;
		top: -5px !important;
		left: 20px !important;
		z-index: 100;
		a {
			cursor: pointer;
		}
	}
`;

export const SearchWrapper = styled.div`
	flex-grow : 2;
	margin-right : 16px;
	.searchBlock {
			position: relative;
			.icon-search {
				position: absolute;
				top: 18px;
				left: 25px;
				font-size: 14px;
				color: #bdc7d1;
	}
			}
		}
		.searchChannels {
			width: 100%;
			padding-left: 8%;
			color: #0f1c46;
font-size: 15px;
			&:focus {
			border-color: #106dd5 !important;
			box-shadow: unset !important;

			}
		}
`;

export const FilterContainer = styled.div`
	.has-value.Select--single > .Select-control .Select-value .Select-value-label,
	.has-value.is-pseudo-focused.Select--single
		> .Select-control
		.Select-value
		.Select-value-label {
		color: #0f1c46;
		font-size: 15px;
	}
	.Select-sub-menu:hover,
	.Select-option.is-selected {
		border-left: none;
		background-color: #fafafb;
		color: #626b74;
	}
	.Select-option,
	.Select-option.is-focused {
		border-left: none;
		color: #0f1c46;
		font-size: 15px;
	}

	.Select-sub-menu {
		border-radius: 0 3px 3px 3px;
		background-color: #fff;
		border: 1px solid #ccc;
		border-top-color: #e6e6e6;
		box-sizing: border-box;
		margin-top: -1px;
		max-height: 305px;
		min-width: 200px;
		border: unset;
		bottom: 0;
		left: 0;
		line-height: 48px;
		padding-left: 19px;
		color: #0f1c46;
		display: flex;
		justify-content: space-between;
		font-size: 15px;
	}

	.Select-sub-menu .fa {
		padding: 10px;
		padding-top: 15px;
		font-size: 19px;
	}
	.Select.is-focused .Select-control {
		border-color: #106dd5;
	}

	.Select.is-focused .Select-control .Select-arrow,
	.Select-arrow-zone:hover > .Select-arrow {
		border-top-color: #106dd5;
	}
	.btn.btn-get-recommendations {
		padding: 10px 18px;
		border-radius: 4px;
		background-color: #106dd5;
		font-size: 14px;
		font-weight: 600;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&:hover {
			background-color: #1579e8;
		}
		.get-recommendation-icon {
			fill: #fff;
			margin-right: 6px;
		}
	}
	.btn-reset-filter {
		background: transparent;
		border: none;
		font-size: 14px;
		font-weight: 500;
		color: #333333;
		padding: 14px 0;
		&:hover {
			color: #106dd5;
			.icon-refresh {
				fill: #106dd5;
			}
		}
		.icon-refresh {
			width: 18px;
			height: 18px;
			fill: #333333;
			margin-right: 8px;
		}
	}
`;

export const ALLChannel = styled(Box)`
	background-color: #f1f2f5;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	border-radius: 1px;
	padding: 20px 14px;
	max-height: 535px;
	height: 535px;
`;

export const ActiveChannel = styled(ALLChannel)`
	.campaign-total {
		font-size: 15px;
		font-weight: 500;
		color: #757b86;
		text-transform: capitalize;
		display: flex;
		justify-content: space-between;
		.currency-formatter {
			font-weight: bold;
			color: #106dd5;
		}
	}
	.divider {
		border: 1px dashed #a0a5b9;
	}
`;

export const PaginationWrapper = styled(Box)`
	.pagination {
		margin: 0px;
		display: flex;
		justify-content: center;
		> li {
			border-radius: 50%;
		}
	}
	.pagination li.previous::before,
	li.next::after {
		display: none;
	}
	.pagination li.next {
		margin-right: 0px;
		margin-left: 0px;
		width: 35px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		> a {
			width: 35px;
		}
	}
	.pagination li.previous {
		width: 35px;
		margin-right: 0px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		> a {
			width: 35px;
		}
	}
	.pagination li.previous:hover,
	.pagination li.next:hover {
		background: #ff6849;
		color: #ffffff;
		> a {
			color: #ffffff;
		}
	}
	.pagination li.previous > a:hover,
	.pagination li.next > a:hover {
		color: #fff !important;
		background: transparent !important;
	}
	.pagination li.previous > a:focus,
	.pagination li.next > a:focus {
		color: inherit !important;
		background: transparent !important;
	}
	.pagination li > a {
		background-color: transparent !important;
		color: #273238;
	}
	.pagination li.active a,
	.pagination li.active a:hover,
	.pagination li.active a:focus,
	.pagination li > a:focus,
	.pagination li > a:hover {
		color: #ff6849 !important;
		background: transparent !important;
	}
`;
export const ConfirmChannelsStyles = styled.div`
	.title {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 34px;
		letter-spacing: 0.75px;
		color: #6e7191;
	}
	.sub_title {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 13px;
		line-height: 24px;
		color: #6e7191;
		display: block;
		margin-top: 5px;
	}
	.total {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 3% 3% 0%;
		> span {
			font-family: "basiercircle";
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 34px;
			text-align: right;
			letter-spacing: 0.75px;
			color: #6e7191;
		}
	}
`;

export const Table = styled.div`
	width: 100%;
	margin: 3% 0;
`;
export const Row = styled.div`
	display: grid;
	grid-template-columns: 64% 18% 18%;
	background-color: #ffffff;
	padding: 15px 0;
	font-family: "basiercircle";
	font-style: normal;
	font-weight: 600;
	font-size: ${props => (props.header ? "12px" : "13px")};
	line-height: ${props => (props.header ? "22px" : "24px")};
	color: ${props => (props.header ? "#ff6849" : "#6e7191")};
	border-bottom: 1px solid lightgray;
	.price-formatted {
		text-align: end;
		margin-right: 20%;
	}
`;
