import React from "react";
import moment from "moment";
import classnames from "./date.module.scss";
import { DATE_FORMAT } from "config";

const Date = ({ date }) => {
	return (
		<div className={classnames.dateWrraper}>
			<div className={classnames.calendarDate}>
				<span>{moment.unix(date).format(DATE_FORMAT)}</span>
			</div>
		</div>
	);
};

export default Date;
