import React from "react";
import BodyClassName from "react-body-classname";
import { useQueryCache } from "react-query";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { browserHistory } from "react-router";
import useChangeSupplierInvitationStatus from "../api/useChangeSupplierInvitationStatus";
import toaster from "../../../common/Toaster";
import { supplierHistoryQueryKey } from "../api/useListSupplierInvitations";
import { InvitationStatus } from "../../company/components/VariableMargins/components/Kanban";
import useViewSupplierInvitation from "../api/useViewInvitation";
import Intro from "../../../common/dashboard/Intro";
import s from "./view-invitation.module.scss";
import { PDF_VIEWER_URL } from "../../../config";

export function getSupplierInvitationRoute() {
	return "/client/invitation/:id";
}

export function getSupplierInvitationPermission() {
	return {
		type: [
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
}

function ViewInvitation({ params }) {
	const { id } = params;
	const queryCache = useQueryCache();
	const [
		updateStatus,
		{ isLoading: isSaving }
	] = useChangeSupplierInvitationStatus({
		onSuccess(_, vars) {
			toaster.success(`Invitation ${vars.status}.`);
			queryCache.invalidateQueries(supplierHistoryQueryKey);
			browserHistory.push("/settings/invitations");
		}
	});

	const handleChangeStatus = action => {
		const status = {
			accept: "accepted",
			refuse: "refused"
		};
		updateStatus({
			status: status[action],
			invitation_ids: [id]
		});
	};

	const statusMap = {
		draft: "Pending",
		sent: "Pending",
		accepted: "Accepted",
		refused: "Refused",
		expired: "Expired"
	};

	// FIXME: backend data
	const { data } = useViewSupplierInvitation({ id });
	const file = _get(data, "tier.tier_link", {});
	return (
		<BodyClassName className="my-company my-profile">
			<>
				<div className="dashboard-box">
					<div className={"container"} style={{ padding: "30px 15px 0" }}>
						<Intro icon="fa fa-file-text-o" title={"Client Invitation"} />
					</div>
				</div>

				<div className={"container"}>
					<div className={s.root}>
						<div className={s.infoContainer}>
							<div>
								<h4>Client</h4>
								<span>{data?.company?.company_name}</span>
							</div>
							<div>
								<h4>Category</h4>
								<span>{data?.category?.name}</span>
							</div>
							<div>
								<h4>Status</h4>
								<InvitationStatus status={data?.status}>
									{statusMap[data?.status] ?? ""}
								</InvitationStatus>
							</div>
							<div className={s.actionsContainer}>
								{data?.status === "sent" ? (
									<>
										<button
											disabled={isSaving}
											onClick={() => handleChangeStatus("refuse")}
											className={s.linkButton}
										>
											Refuse
										</button>
										<button
											disabled={isSaving}
											onClick={() => handleChangeStatus("accept")}
											className={s.button}
										>
											Accept
										</button>
									</>
								) : null}
							</div>
						</div>
						{!_isEmpty(file) ? (
							<div className={s.pdfContainer}>
								<div className={s.iframeContainer}>
									<iframe
										style={{
											width: "100%",
											height: "100%",
											overflow: "hidden",
											border: "none"
										}}
										src={
											PDF_VIEWER_URL +
											file?.link?.replace(/^http:\/\//i, "https://")
										}
										width="100%"
									/>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</>
		</BodyClassName>
	);
}

export default ViewInvitation;
