import Immutable from "seamless-immutable";
import {
	FETCH_POKE_DATA,
	DISPLAY_INTERVIEW_INVITATION,
	DISPLAY_PROPOSE_MODAL,
	SET_INTERVIEW_TIME,
	ERROR_INTERVIEW_TIME
} from "./types";
import { CLEAN_STATE } from "../../vacancy/actions/types";
const initialState = {
	data: {},
	canDisplayInvitation: false,
	canDisplayPropose: false
};

export default function(state = initialState, action) {
	switch (action.type) {
		case FETCH_POKE_DATA:
			return Immutable.merge(state, action.data);
		case SET_INTERVIEW_TIME:
			return {
				...state,
				data: {
					...state.data,
					interview_time: { ...action.interviewTime, error: null }
				}
			};
		case ERROR_INTERVIEW_TIME:
			return {
				...state,
				data: {
					...state.data,
					interview_time: { ...state.data.interview_time, error: action.error }
				}
			};
		case DISPLAY_INTERVIEW_INVITATION:
			return { ...state, canDisplayInvitation: action.canDisplayInvitation };
		case DISPLAY_PROPOSE_MODAL:
			return { ...state, canDisplayPropose: action.canDisplayPropose };
		case CLEAN_STATE:
			return initialState;
		default:
			return state;
	}
}
