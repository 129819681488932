import styled, { css } from "styled-components";
import { colors, utilities } from "config/styles";
import { YELLOW_INDICATOR, RED_INDICATOR } from "config";

export const SmallCheckerStyled = styled.div`
	width: 120px;
	height: 34px;
	border-radius: 3px;
	border: solid 1.5px;
	line-height: 30px;
	text-align: center;
	display: inline-block;
	margin-right: 20px;
	color: ${props =>
		props.check ? colors.primaryColor : colors.checkboxBorderColor};
	border-color: ${props =>
		props.check ? colors.primaryColor : colors.checkboxBorderColor};
`;

export const SmallCheckerIconStyled = styled.i`
	font-size: 18px;
	margin: 0 4px;
	font-weight: 600;
	position: relative;
	top: 3px;
	left: -4px;
`;

export const Counter = styled.div`
	float: right;
	padding-top: 10px;
	top: -30px;
	position: relative;
	font-weight: 400;
	text-align-last: end;
	font-size: 14px;
	margin: 5px 0 0;
	color: ${props =>
		(props.color === YELLOW_INDICATOR.className && colors.warningColor) ||
		(props.color === RED_INDICATOR.className && colors.dropdownPauseColor) ||
		colors.pColor};

	& + .help-block.inline-error {
		clear: left;
	}
`;

export const Divider = styled.div`
	background-color: ${colors.bodyBg};
	height: 1px;
	margin: 15px 0;
`;

export const SmallDivider = styled.div`
	background-color: ${colors.bodyBg};
	height: 3.5px;
	width: 50px;
	margin: 20px 0;
`;

export const PlaceHolder = styled.div`
	width: 30%;
	margin-left: 10px;
	display: inline-block;
	position: relative;
	top: 3px;
	&:before {
		content: "";
		height: 6px;
		width: 94%;
		background-color: ${colors.bodyBg};
		border-radius: 10px;
		display: block;
	}
	&:after {
		content: "";
		height: 6px;
		width: 54%;
		background-color: ${colors.bodyBg};
		border-radius: 10px;
		display: block;
		margin-top: 5px;
	}
`;

export const Description = styled.div`
	background-color: ${colors.invitationDescription};
	border: solid 1px ${colors.loaderBaseColor};
	border-left: 4px solid ${colors.invitationDescriptionBorder};
	padding: 18px 30px;
	margin: 0 auto 35px;
	text-align: center;
	width: ${props => props.width || "100%"};
	font-weight: 400;
	line-height: 1.7;
`;

export const InfoBox = styled.div`
	/* border: 1px solid ${colors.skillsIntroBorder}; */
	background-color: ${colors.skillsIntroBg};
	color: ${colors.skillsIntroColor};
	padding: ${props => props.padding || "15px 20px"};
	margin: ${props => props.margin || "30px auto 0"};
	border-radius: ${utilities.borderRadius};
	font-weight: 400;
	font-size: ${props => (props.size ? `${props.size}px` : `14px`)};
	max-width: 800px;
	line-height: 1.5;
	display: ${props => (props.noDisplay ? "none" : "flex")};
	flex-direction: ${props => (props.column ? "column" : "row")};
	align-items: center;
	width: fit-content;
	clear: both;

	p,
	h3,
	button {
		color: ${colors.skillsIntroColor} !important;
	}
	p {
		font-weight: 400 !important;
	}
	h3 {
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 500 !important;
		margin-bottom: 5px;
	}
	button {
		border: none;
		background-color: transparent;
		font-weight: 500 !important;
		&:hover {
			text-decoration: underline;
		}
	}
`;

export const InfoBoxIcon = styled.i`
	font-size: 20px;
	margin-right: 15px;
`;

export const Switcher = styled.div`
	float: left;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
	color: ${props => (props.checked ? "#334155" : "#64748B")};
	padding: 0 19px 8px;
	cursor: pointer;
	border-bottom: 3px solid
		${props => (props.checked ? "#1A9187" : "transparent")};
	position: relative;
	bottom: -1px;
	letter-spacing: 0.5px;
	font-family: "Work Sans";
	&.Switcher {
		margin-right: 30px;
		padding: 0 0 8px;

		font-family: "Work Sans";
		font-size: 14px;
		font-weight: 500;
		color: ${props => (props.checked ? "#242f43" : "rgb(149, 161, 179)")};

		border-bottom: 2px solid
			${props => (props.checked ? "#ff6849" : "transparent")};
	}
`;

export const SwitcherContainer = styled.div`
	border-bottom: 1px solid
		${props => (props.bordred ? colors.switcher : "transparent")};
	float: left;
	width: 100%;
	padding-top: ${props => props.paddingTop || "40px"};
	&:after {
		content: "";
		display: block;
		clear: both;
	}
	&.SwitcherContainer {
		padding: 0 15px;
	}
`;

export const Tag = styled.span`
	display: inline-block;
	line-height: 100%;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	margin: ${props => props.margin || "0 5px 5px 0"};
	padding: 8px 12px;
	border-radius: 3px;
	&.showMoreBtn{
		cursor: pointer;
		color: #FF8477;
			border-color: #FF8477;
			border-width:1px;
			margin-left: 4px;
		&:hover{
			color: ${colors.tag};
			border-color: ${colors.labelBorderColor};
		}
	}
	cursor: ${props => props.cursor || "default"};
	background-color: ${props =>
		props.background
			? colors.hoverBgColor
			: props.warning
			? colors.skillsIntroBg
			: colors.white};
	color: ${props => (props.warning ? colors.skillsIntroColor : colors.tag)}
	font-weight: 400;
	border: 2px solid
		${props => (props.warning ? colors.skillsIntroBg : colors.labelBorderColor)};
	font-size: 12px;
	text-transform: ${props => (props.capitalize ? "capitalize" : "none")};
	span {
		color: ${colors.primaryColor};
	}
	strong {
		font-weight: 500;
	}

	 ${props =>
			props.animated &&
			css`
				&:hover {
					border-color: ${colors.inputColor};
					transition: 0.3s ease-out;
				}
			`}
`;

export const TopContainer = styled.div`
	padding: 0px 6px 0 15px !important;
`;

export const CenterTooltip = styled.div`
	text-align: center;
	margin-right: 5px;
`;

export const SearchContainer = styled.div`
	${props =>
		props.vacancy &&
		css`
			width: 100%;
			background: white;
			padding: 15px;
			margin-bottom: 5px;
			border: solid 1px ${colors.grayBorder};
		`};
`;

export const TobBarContainer = styled.div`
	${props =>
		props.vacancy &&
		css`
			margin-top: 0 !important;
			margin-bottom: 10px !important;
			background-color: unset !important;
			border: none !important;
			.container {
				border-radius: 3px;
				width: 100% !important;
				background-color: #fff;
				padding: 20px 20px !important;
				border: solid 1px ${colors.grayBorder};
			}
		`};
`;

export const ClearBoth = styled.div`
	clear: both;
`;

export const ComingSoonLabel = styled.label`
	position: absolute;
	color: ${colors.primaryColor};
	text-transform: uppercase;
	font-weight: 400;
	font-size: 10px;
	letter-spacing: 1.02px;
	top: 80%;
	left: 66%;
	transform: translate(-50%);
	min-width: 113px;
	letter-spacing: 1.02px;
`;

export const StylelessContainer = styled.div`
	font-weight: normal !important;
	font-size: 16px !important;

	h4 {
		font-size: 15px !important;
		margin-bottom: 10px !important;
		color: #333 !important;
	}
	h5 {
		font-size: 14px !important;
		color: #333 !important;
	}
	h6 {
		font-size: 12px !important;
		color: #333 !important;
	}
	p {
		color: #333 !important;
		font-weight: normal !important;
	}
	ol,
	ul {
		margin: 16px 0 !important;
		padding: 0 !important;
		color: #333 !important;
		li {
			margin-left: 1.5em !important;
		}
	}

	blockquote {
		padding: 10px 20px !important;
		margin: 0 0 20px !important;
		font-size: 17.5px !important;
		border-left: 5px solid #eee !important;
		color: #333 !important;
	}
`;
export const FiltersContainer = styled.div`
	.form-group {
		position: relative;
		margin-bottom: 24px;
		.form-control {
			border: unset;
			background: transparent;
			border-bottom: 1px solid #d6d7dd;
			padding: 0px 30px;
			min-height: 45px !important;
		}
		input.form-control {
			padding-left: 30px;
		}
		.icon-search {
			position: absolute;
			top: 17px;
			left: 8px;
			color: #a5b1bb;
		}
		.input-error {
			border: 1px solid #ff6849 !important;
		}
		.icon-reset-3 {
			position: relative;
			top: 50%;
		}
	}
	.select-label {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: 0.08px;
		color: #273238;
		line-height: 1.43;
	}
	.Select-control {
		width: 190px;
		margin-top: 6px;
		height: 40px;
		border: 2px solid #0676ed;
		border-radius: 2px;
		.Select-value {
			display: flex;
			align-items: center;
		}
	}
	.Select-menu-outer {
		width: 190px;
	}
`;

export const Table = styled.table`
	border-collapse: separate;
	margin: 0;
	width: ${props => (props.width ? props.width : "100%")};
	padding-bottom: ${props => (props.noPadding ? "0" : "30px")};
	thead {
		tr {
			cursor: auto;
		}
	}
	td {
		border: 0;
		border-top: 1px solid ${colors.borderColor};
	}
	${props =>
		props.candidate &&
		css`
			background-color: #ffffff;
			border: solid 1px #e4e7ee;
			padding-bottom: 0 !important;
		`};
`;

export const Tr = styled.tr`
	height: 74px;
	&:hover {
		background-color: ${colors.lineHover};
		td {
			background-color: ${colors.lineHover};
		}
	}
	td {
		cursor: ${props => (props.canViewItem ? "pointer" : "default")};
	}
`;

export const Th = styled.th`
	color: ${colors.anchorsColor};
	font-weight: 500;
	text-transform: uppercase;
	padding: 25px 8px 20px;
	font-size: 13px;
	border: 0;
	width: ${props => props.width || "auto"};
	text-align: ${props => (props.center ? "center" : "left")};
	text-align: ${props => (props.textAlign ? props.textAlign : "none")};
	&:first-child {
		padding-left: 20px;
	}
	&:last-child {
		padding-right: 20px;
	}
	${props =>
		props.minWidth &&
		css`
			min-width: ${props.minWidth};
		`};
`;

export const Td = styled.td`
	padding: 20px 8px 15px;
	position: relative;
	border-bottom: 1px solid ${colors.borderColor};
	word-break: break-word;
	color: ${colors.inputColor};
	font-size: 13px;
	text-align: ${props => (props.center ? "center" : "left")};
	vertical-align: middle;
	width: ${props => props.width && props.width};
	text-align: ${props => (props.textAlign ? props.textAlign : "none")};
	&:first-child {
		padding-left: 20px;
	}
	&:last-child {
		padding-right: 20px;
	}
	&.files-menu-container {
		display: flex;
	}
`;
