import create from "zustand";
import { HIREME_CHANNEL_ID } from "config";

export const WIIGLI_CHANNEL = {
	channel: { name: "Wiggli - jobBoard" },
	vonq_price: [{ amount: 0, currency: "EUR" }],
	product_id: HIREME_CHANNEL_ID
};

const initialialState = {
	open: false,
	selectedChannels: [],
	dynamicFreeChannels: []
};

const useConfirmationStore = create((set, get) => ({
	...initialialState,
	setvalue: (key, value) => set({ [key]: value }),
	add: (item, isContractSelected = false) => {
		const { selectedChannels } = get();

		const rowExist =
			selectedChannels.findIndex(
				elem => elem.product_id === item.product_id
			) !== -1;
		if (rowExist) {
			if (item.product_id === HIREME_CHANNEL_ID) {
				if (selectedChannels.length === 1 && !isContractSelected) {
					return set({ selectedChannels: [] });
				}
				return;
			}
			if (selectedChannels.length == 2) {
				const newData = selectedChannels.filter(
					elem => elem.product_id !== item.product_id
				);
				return isContractSelected
					? set({ selectedChannels: [WIIGLI_CHANNEL] })
					: set({ selectedChannels: newData });
			}
			if (selectedChannels.length > 2) {
				const newData = selectedChannels.filter(
					elem => elem.product_id !== item.product_id
				);
				return set({ selectedChannels: newData });
			}
		} else {
			if (
				!Boolean(selectedChannels.length) &&
				item.product_id !== HIREME_CHANNEL_ID
			)
				return set({
					selectedChannels: [WIIGLI_CHANNEL, item]
				});
			return set({ selectedChannels: [...selectedChannels, item] });
		}
	},
	addPage: page => {
		const { selectedChannels } = get();
		const newPure_elements = page.filter(
			item =>
				selectedChannels.findIndex(
					elem => elem.product_id === item.product_id
				) === -1
		);
		set({ selectedChannels: [...selectedChannels, ...newPure_elements] });
	},
	removePage: (page, isContractSelected = false) => {
		const { selectedChannels } = get();
		const newSelection = selectedChannels.filter(
			item => page.findIndex(elem => elem.product_id === item.product_id) === -1
		);
		if (isContractSelected)
			return set({ selectedChannels: [WIIGLI_CHANNEL, ...newSelection] });
		if (page.length === selectedChannels.length)
			return set({ selectedChannels: newSelection });
		return set({ selectedChannels: [WIIGLI_CHANNEL, ...newSelection] });
	},
	reset: () => {
		set({ open: false, selectedChannels: [] });
	},
	resetDynamicFreeChannels: () => {
		set({ dynamicFreeChannels: [] });
	},
	addDynamicFreeChannel: item => {
		const { dynamicFreeChannels } = get();
		const rowExist =
			dynamicFreeChannels.findIndex(
				elem => elem.product_id === item.product_id
			) !== -1;

		if (!rowExist) {
			set({ dynamicFreeChannels: [...dynamicFreeChannels, item] });
		} else {
			const data = dynamicFreeChannels.filter(
				elem => elem.product_id !== item.product_id
			);

			set({ dynamicFreeChannels: data });
		}
	},
	addDynamicFreeChannels: channels => {
		const { dynamicFreeChannels } = get();

		const newElements = channels.filter(
			item =>
				dynamicFreeChannels.findIndex(
					elem => elem.product_id === item.product_id
				) === -1
		);

		set({ dynamicFreeChannels: [...dynamicFreeChannels, ...newElements] });
	},
	removeDynamicFreeChannels: () => {
		return set({ dynamicFreeChannels: [] });
	},
	removeChannel: id => {
		const { selectedChannels } = get();
		const newArray = selectedChannels.filter(
			({ product_id }) => product_id !== id
		);
		set({ selectedChannels: newArray });
	}
}));

export default useConfirmationStore;
