import { browserHistory } from "react-router";
import _ from "lodash";
import axiosInstance from "config/axios";
import {
	HTTP_POST_METHOD,
	RESPONSE_ACCEPTED,
	RESPONSE_OK,
	PERMANENT
} from "config";
import {
	SIGN_UP,
	VAT_CHECKER_API,
	RESEND_ACTIVATE_EMAIL
} from "config/api-endpoints";
import {
	LOADING,
	LOADED,
	FAILED_VAT,
	SIGN_UP_SUCCESS,
	STORE_USER_ID,
	SET_SIGNUP_ADDRESS
} from "./actionTypes";
import {
	getSignUpFirstStepRoute,
	getSignUpSecondStepRoute
} from "../components";
import { getSignUpSuccessRoute } from "../components";
import AxiosHelper from "config/axios-helper";

const isProd = window.location.hostname === "app.wiggli.io";

const loading = () => ({ type: LOADING });
const loaded = (item = null, data = null) => ({ type: LOADED, item, data });
export const storeUserId = id => ({ type: STORE_USER_ID, id });

export function signup(payload) {
	return async dispatch => {
		dispatch(loading());
		try {
			const response = await axiosInstance({
				method: HTTP_POST_METHOD,
				data: JSON.stringify(payload),
				url: SIGN_UP
			});
			if (response && response.status === RESPONSE_ACCEPTED) {
				dispatch(loaded());
				dispatch(storeUserId(payload.step === 1 ? payload.id : null));
				if (payload.type === PERMANENT || payload.step === 1) {
					dispatch({
						type: SIGN_UP_SUCCESS,
						name: `${payload.first_name} ${payload.last_name}`,
						email: payload.email
					});
					if (isProd) {
						window.lintrk?.("track", { conversion_id: 10560241 });
					}
				}
				if (payload.step === 2 || payload.type === PERMANENT) {
					return browserHistory.push(getSignUpSuccessRoute(payload.type));
				}
				browserHistory.push(getSignUpSecondStepRoute(payload.type));
			}
		} catch (error) {
			dispatch(loaded());
			throw error;
		}
	};
}

export function resendActivateEmail(email) {
	return AxiosHelper.__post({
		toastMessage: "We sent you a link please check your mail",
		data: { email },
		url: RESEND_ACTIVATE_EMAIL,
		isLogin: true
	});
}

export function checkVat({ vat, type }) {
	return dispatch => {
		dispatch(loading());
		return axiosInstance({
			method: HTTP_POST_METHOD,
			data: JSON.stringify({ vat }),
			url: VAT_CHECKER_API
		})
			.then(response => {
				if (response && response.status === RESPONSE_OK) {
					dispatch(loaded("vat", response.data));
					_.delay(
						() => browserHistory.push(getSignUpFirstStepRoute(type)),
						300
					);
				}
			})
			.catch(error => {
				if (error.vat) {
					dispatch({ type: FAILED_VAT, error: _.get(error, "vat.0"), vat });
				}
			});
	};
}

export const setSignUpAddress = address => ({
	type: SET_SIGNUP_ADDRESS,
	address
});
