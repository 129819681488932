import React from "react";
import styles from "./evaluation-card-profile-overview.module.scss";
import Avatar from "common/Avatar";
import moment from "moment";
import { DATE_FORMAT } from "config";

const EvaluationCardProfileOverview = ({
	name,
	date,
	children,
	edited,
	avatar
}) => {
	const formatDate = () => {
		return moment(date * 1000).format(DATE_FORMAT);
	};
	return (
		<div className={styles.evaluationCardInfos}>
			<div className={styles.evaluationCardAvatar}>
				<Avatar
					name={name}
					size={50}
					radius={8}
					className={styles.avatar}
					avatar={avatar}
				/>
			</div>
			<div className={styles.infos}>
				<span className={styles.name}>{name}</span>
				<div className={styles.evaluationCardDate}>
					<span className={styles.date}>
						{formatDate()}
						{edited ? " Edited" : ""}
					</span>
					{children}
				</div>
			</div>
		</div>
	);
};
export default EvaluationCardProfileOverview;
