import React, { Component } from "react";
import { Element } from "react-scroll";
import {
	Editor,
	EditorState,
	RichUtils,
	convertToRaw,
	convertFromRaw,
	Modifier
} from "draft-js";
import _get from "lodash/get";
import "draft-js/dist/Draft.css";
import InlineStyleControls from "./InlineStyleControls";
import BlockStyleControls from "./BlockStyleControls";
import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";
import "../../styles/css/hireme/richEditor.css";
import {
	YELLOW_INDICATOR,
	RED_INDICATOR,
	NEW_LINES_REGEX,
	LINK
} from "../../config";
import GlobalTooltip from "../GlobalTooltip";

import Input from "./Link";
import { getPosition, getSelected } from "./Link/helpers";
import decorator from "./Link/decorator";
import InsertNameTags from "./InsertNameTags";

class RichEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: this.setEditorValue(),
			validationError: "",
			showLinkInput: false
		};
	}

	setEditorValue = (props = this.props) => {
		const defaultValue = props.defaultValue || _get(props, "input.value", "");

		let newEditorState = EditorState.createEmpty();

		if (defaultValue) {
			const rawData = mdToDraftjs(defaultValue);
			const contentState = convertFromRaw(rawData);
			newEditorState = EditorState.createWithContent(contentState);
		}
		return newEditorState;
	};

	componentDidUpdate(prevProps) {
		const prevValue = _get(prevProps, "input.value");
		const value = _get(this.props, "input.value");
		const clearData = _get(this.props, "clearData");

		if (clearData) {
			if (value !== prevValue) {
				this.setState({
					editorState: this.setEditorValue()
				});
			}
		} else {
			if (value && this.getTextLength() === 0 && value !== prevValue) {
				this.setState({
					editorState: this.setEditorValue()
				});
			}
		}

		if (
			this.props.validationError &&
			this.props.validationError !== this.state.backendError
		) {
			this.setState({
				backendError: this.props.validationError
			});
		}
	}

	onChange = editorState => {
		let { onChange, input } = this.props;
		if (!onChange) onChange = input.onChange;
		this.setState({ editorState }, () => {
			const content = editorState.getCurrentContent();
			onChange(draftjsToMd(convertToRaw(content)));
		});
	};

	onLinkClick = () => {
		this.setState({
			showLinkInput: true
		});
	};
	onLinkInputClose = () => {
		this.setState({
			showLinkInput: false
		});
		this.onChange(RichUtils.toggleBlockType(this.state.editorState, LINK));
	};
	_onMouseOrKeyUp = () => {
		const selected = getSelected();
		if (selected) {
			this.setState({
				position: getPosition(selected)
			});
		}
	};
	insertText = (text, editorState) => {
		const currentContent = editorState.getCurrentContent(),
			currentSelection = editorState.getSelection();

		const newContent = Modifier.replaceText(
			currentContent,
			currentSelection,
			text
		);

		const newEditorState = EditorState.push(
			editorState,
			newContent,
			"insert-characters"
		);
		return EditorState.forceSelection(
			newEditorState,
			newContent.getSelectionAfter()
		);
	};
	focusEditor = () => {
		if (this.editor) {
			this.editor.focusEditor();
		}
	};

	handleInputLinkChange = editorState => {
		this.setState({ editorState });
	};

	toggleInlineStyle = inlineStyle => {
		const { editorState } = this.state;
		if (inlineStyle === LINK) {
			const selection = getSelected();
			if (selection.type === "Range") {
				this.onLinkClick();
			} else {
				return;
			}
		}
		this.onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
	};

	toggleBlockType = blockType => {
		const { editorState } = this.state;
		this.onChange(RichUtils.toggleBlockType(editorState, blockType));
	};

	onInsert = tagType => {
		this.setState({
			editorState: this.insertText(tagType, this.state.editorState)
		});
		this.focusEditor();
	};

	toggleAlignType = alignType => {
		const { editorState } = this.state;
		this.onChange(RichUtils.toggleBlockType(editorState, alignType));
	};

	handleKeyCommand = command => {
		const { editorState } = this.state;
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			this.onChange(newState);
			return true;
		}
		return false;
	};

	maxReached = () => {
		const currentContent = this.state.editorState.getCurrentContent();
		const plainText = currentContent.getPlainText("");
		const cleanString = plainText.replace(NEW_LINES_REGEX, "").trim();
		if (cleanString.length > this.props.maxLength - 1) {
			return true;
		}
		return false;
	};

	handleBeforeInput = () => {
		if (!this.props.maxLength === undefined) return;
		return this.maxReached();
	};

	handlePastedText = pastedText => {
		const { maxLength } = this.props;
		if (maxLength === undefined) return;
		const { editorState } = this.state;
		const selection = editorState.getSelection();
		const contentState = editorState.getCurrentContent();
		const currentContentLength = this.getTextLength();
		let nextEditorState = EditorState.createEmpty();

		if (currentContentLength + pastedText.length > maxLength) {
			const leftChars = maxLength - this.getTextLength();
			const txt = pastedText.substring(0, leftChars);
			if (selection.isCollapsed()) {
				const nextContentState = Modifier.insertText(
					contentState,
					selection,
					txt
				);
				nextEditorState = EditorState.push(
					editorState,
					nextContentState,
					"insert-characters"
				);
			} else {
				const nextContentState = Modifier.replaceText(
					contentState,
					selection,
					txt
				);
				nextEditorState = EditorState.push(
					editorState,
					nextContentState,
					"insert-characters"
				);
			}
			this.onChange(nextEditorState);
			return true;
		}
	};

	componentDidMount() {
		this.handleInputLinkChange(
			EditorState.set(this.state.editorState, {
				decorator: decorator({
					clickLink: this.onLinkClick
				})
			})
		);
	}

	getTextLength = () => {
		const currentContent = this.state.editorState.getCurrentContent();
		const plainText = currentContent.getPlainText("");
		const cleanString = plainText.replace(NEW_LINES_REGEX, "").trim();
		return cleanString.length;
	};

	getMaxIndicatorClassName = () => {
		const { maxLength } = this.props;
		if (maxLength) {
			const leftChars = maxLength - this.getTextLength();
			if (leftChars <= RED_INDICATOR.trigger) return RED_INDICATOR.className;
			if (maxLength / YELLOW_INDICATOR.trigger >= leftChars)
				return YELLOW_INDICATOR.className;
		}
		return "";
	};

	render() {
		const {
			maxLength,
			inlineStylesTypes,
			blockStylesTypes,
			tagTypes,
			meta,
			label,
			input: { name },
			toolTipOverlay,
			handleFocus,
			handleBlur,
			overlayClassName
		} = this.props;
		const { backendError } = this.state;
		const has_error = backendError
			? "has-error has-feedback"
			: meta && meta.touched && meta.error
			? "has-error has-feedback"
			: "";

		return (
			<Element
				className={`form-group RichEditorMargin ${has_error}`}
				name={name}
				scrollto="scroll-to-error"
			>
				{label && (
					<label htmlFor={name}>
						{label}
						{toolTipOverlay && (
							<GlobalTooltip
								maxWidth="475px"
								overlayClassName={overlayClassName}
								overlay={toolTipOverlay}
							/>
						)}
					</label>
				)}
				<div className={`RichEditor ${has_error}`}>
					{tagTypes && (
						<InsertNameTags
							editorState={this.state.editorState}
							types={tagTypes}
							onInsert={this.onInsert}
						/>
					)}
					<div
						className={
							tagTypes
								? "RichEditor-controls-group RichEditor-insert-tags"
								: "RichEditor-controls-group"
						}
					>
						<InlineStyleControls
							editorState={this.state.editorState}
							types={inlineStylesTypes}
							onToggle={this.toggleInlineStyle}
						/>
						<BlockStyleControls
							editorState={this.state.editorState}
							types={blockStylesTypes}
							onToggle={this.toggleBlockType}
						/>
					</div>
					<Editor
						editorState={this.state.editorState}
						handleBeforeInput={this.handleBeforeInput}
						handlePastedText={this.handlePastedText}
						placeholder={this.props.placeholder || ""}
						onChange={this.onChange}
						handleKeyCommand={this.handleKeyCommand}
						stripPastedStyles={true}
						readOnly={this.props.readOnly}
						onFocus={handleFocus}
						onBlur={handleBlur}
					/>
					{maxLength && (
						<p className={`input-maxlength ${this.getMaxIndicatorClassName()}`}>
							{this.getTextLength()}/{maxLength}
						</p>
					)}
					<div className="help-block inline-error">
						{backendError
							? backendError
							: meta && meta.touched && meta.error && meta.error}
					</div>
					{this.state.showLinkInput && (
						<Input
							entityType="LINK"
							editorState={this.state.editorState}
							onChange={this.handleInputLinkChange}
							onClose={this.onLinkInputClose}
						/>
					)}
				</div>
			</Element>
		);
	}
}

RichEditor.defaultProps = {
	meta: {},
	handleFocus: () => {},
	handleBlur: () => {},
	setForcedSetValue: () => {},
	forcedSetValue: false
};

export default RichEditor;
