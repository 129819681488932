import React from "react";
import _get from "lodash/get";
import cx from "classnames";
import { createMarkup, shouldDisplayFreelancerCost } from "config/helpers";
import styles from "./bid-details-processes-card.module.scss";
import { ReactComponent as CoinsIcon } from "static/icons/coins.svg";
import { ReactComponent as MessageTextSquareIcon } from "static/icons/message-text-square.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-3.svg";
import { ReactComponent as FileCheckIcon } from "static/icons/file-check.svg";
import { ReactComponent as DownloadIcon } from "static/icons/Icondownload.svg";
import { ReactComponent as FileIcon } from "static/icons/file-icon1.svg";
import { ReactComponent as FilePdfIcon } from "static/icons/file-pdf-icon.svg";
import { ReactComponent as FileXlsIcon } from "static/icons/file-xls-icon.svg";
import { ReactComponent as FileJpgIcon } from "static/icons/file-jpg-icon.svg";
import { ReactComponent as InfoIcon } from "static/icons/info-circle-dark-grey.svg";
import { BidDetailsProcessesCard } from ".";
import { formatDate } from "common/Functions";
import { DAILY, HOURLY, CURRENCY_SUFFIX } from "config";
import { BidDetailsSkeletonCard } from "../BidDetailsSkeletonCard";
import Tooltip from "common/Tippy";
import FeeTooltip from "../BidDetailsTooltips/FeeTooltip";
import { ClampContent } from "common/ClampContent";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { useIsDisplayCostEnabled } from "hooks/useIsDisplayCostEnabled";

const CustomFileIcon = ({ path }) => {
	const ext = path?.split(".").at(-1);
	if (ext === "pdf") return <FilePdfIcon size={20} />;
	if (ext === "xls") return <FileXlsIcon size={20} />;
	return <FileJpgIcon size={20} />;
};

export default function BidDetailsProcessesBidCard({
	data,
	isLoading,
	displayStatus,
	statusTooltip
}) {
	const description = _get(data, "description", "");
	const displayCostEnabled =
		useIsDisplayCostEnabled() && shouldDisplayFreelancerCost(data);

	return (
		<BidDetailsProcessesCard
			status={displayStatus && _get(data, "visible_status")}
			statusTooltip={statusTooltip}
		>
			<div className={styles.content}>
				<div className={styles.bidInfo}>
					<div className={styles.bidInfo_item}>
						<div className={styles.bidInfo_item_labelHead}>
							<FileCheckIcon size={20} />
							<div className={styles.bidInfo_item_labelHead_label}>
								Bid date
							</div>
						</div>
						<div className={styles.bidInfo_item_value}>
							{isLoading ? (
								<BidDetailsSkeletonCard maxWidth={100} minWidth={100} />
							) : (
								formatDate(_get(data, "created_at"))
							)}
						</div>
					</div>
					<div className={styles.bidInfo_item}>
						<div className={styles.bidInfo_item_labelHead}>
							<CalendarIcon size={20} />
							<div className={styles.bidInfo_item_labelHead_label}>
								Available from
							</div>
						</div>
						<div className={styles.bidInfo_item_value}>
							{isLoading ? (
								<BidDetailsSkeletonCard maxWidth={100} minWidth={100} />
							) : (
								formatDate(_get(data, "available_date"))
							)}
						</div>
					</div>
					<div className={styles.bidInfo_item}>
						<div className={styles.bidInfo_item_labelHead}>
							<CoinsIcon size={20} />
							<div className={styles.bidInfo_item_labelHead_label}>
								{isLoading ? (
									<BidDetailsSkeletonCard maxWidth={70} minWidth={70} />
								) : _get(data, "reporting_settings.rate_type") === DAILY ? (
									"Daily rate"
								) : (
									"Hourly rate"
								)}
							</div>
							{_get(data, "reporting_settings.rate_type", HOURLY) === DAILY && (
								<Tooltip
									theme="dark"
									placement={"top"}
									content={`${_get(
										data,
										"reporting_settings.hours_representation"
									).replace(":", "h")} of work time per day`}
								>
									<InfoIcon />
								</Tooltip>
							)}
						</div>
						<div className={styles.bidInfo_item_value}>
							{isLoading ? (
								<BidDetailsSkeletonCard maxWidth={100} minWidth={100} />
							) : (
								<div className={styles.flex}>
									<CurrencyFormatterRender
										className={styles.textNoWrap}
										isCent
										value={_get(data, "proposed_amount", 0)}
										currency={_get(data, "reporting_settings.currency.code")}
										suffix={
											CURRENCY_SUFFIX[
												_get(data, "reporting_settings.rate_type", HOURLY)
											]
										}
									/>
									{displayCostEnabled && (
										<div className={styles.fee}>
											<span>+</span>
											<CurrencyFormatterRender
												className={styles.textNoWrap}
												isCent
												value={_get(data, "margin_value", 0)}
												currency={_get(
													data,
													"reporting_settings.currency.code"
												)}
												suffix={
													CURRENCY_SUFFIX[
														_get(data, "reporting_settings.rate_type", HOURLY)
													]
												}
											/>
											<FeeTooltip />
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
				{_get(data, "files")?.length > 0 && (
					<div className={cx(styles.item, styles.item__message)}>
						<div className={styles.bidInfo_item_labelHead}>
							<FileIcon size={20} />
							<div className={styles.bidInfo_item_labelHead_label}>Files</div>
						</div>
						<div className={styles.files}>
							{_get(data, "files").map(file => {
								return (
									<Tooltip
										content={file.field_name}
										theme="dark"
										placement="top"
										key={file._id}
									>
										<div
											key={file._id}
											className={styles.file}
											onClick={() => {
												window.open(file.path, "_blank");
											}}
										>
											<CustomFileIcon path={file.path} />
											<span className={styles.label}>{file.field_name}</span>
											<DownloadIcon size={20} />
										</div>
									</Tooltip>
								);
							})}
						</div>
					</div>
				)}
				{(_get(data, "description")?.length > 0 || isLoading) && (
					<div className={cx(styles.item, styles.item__message)}>
						<div className={styles.bidInfo_item_labelHead}>
							<MessageTextSquareIcon size={20} />
							<div className={styles.bidInfo_item_labelHead_label}>Message</div>
						</div>
						<div>
							{isLoading ? (
								<BidDetailsSkeletonCard
									maxWidth={500}
									minWidth={500}
									height={50}
								/>
							) : (
								<ClampContent html={createMarkup(description)} />
							)}
						</div>
					</div>
				)}
			</div>
		</BidDetailsProcessesCard>
	);
}
