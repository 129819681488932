import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import get from "lodash/get";
import Card from "../Card";
import Note from "./Note";
import EmptyNote from "./components/EmptyNote";
import styles from "./notes-card.module.scss";
import NoteDrawer from "../../Pages/Notes/NoteDrawer";
import useNotesStore from "../../Store/useNotesStore";
const NotesCard = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	title,
	data,
	isLoading,
	onNoteAdd,
	candidateData
}) => {
	const {
		isOtherDrawerOpen,
		setActiveNoteIdx,
		activeNoteIdx,
		toggleOtherDrawer
	} = useNotesStore();
	const notes = get(data, "data", []);
	const total = get(data, "data", []).length;
	const size = get(data, "data", []).length + 1;

	const [hasNext, setHasNext] = useState(activeNoteIdx + 1 !== total);
	const [hasPrevious, setHasPrevious] = useState(
		!!activeNoteIdx && activeNoteIdx !== 0
	);

	useEffect(() => {
		setActiveNoteIdx(0);
		setHasPrevious(false);
		setHasNext(true);
	}, [data]);

	const nextNote = index => {
		if (index + 1 === size) {
			setActiveNoteIdx(0);
		} else {
			setActiveNoteIdx(index + 1);
		}
		if (index + 2 === total) {
			setHasNext(false);
		}
		if (index >= 0) {
			setHasPrevious(true);
		}
	};

	const previousNote = index => {
		if (index === 1) {
			setActiveNoteIdx(0);
			setHasPrevious(false);
			setHasNext(true);
		} else {
			setActiveNoteIdx(index - 1);
		}
	};
	const handleNoteClick = index => {
		toggleOtherDrawer();
		setActiveNoteIdx(index);
		setHasPrevious(index !== 0);
		setHasNext(index + 1 !== total);
	};

	return (
		<>
			<Card
				title={title}
				typeBtn="condidate__note"
				{...{ onDragEnd, onViewMore, onToggleVisibility, notes }}
				candidateData={candidateData}
			>
				{!isLoading ? (
					notes?.length > 0 ? (
						notes.map((note, index) => {
							return (
								<Note
									key={note.createdAt}
									note={note}
									onClick={() => handleNoteClick(index)}
								/>
							);
						})
					) : (
						<>
							<EmptyNote />
						</>
					)
				) : (
					<div className={styles.gap}>
						<SkeletonTheme
							style={{ borderRadius: 10, marginRight: 16 }}
							color="#dfe3e8"
						>
							<Skeleton width={200} height={16} />
						</SkeletonTheme>
						<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
							<Skeleton width={400} height={16} />
						</SkeletonTheme>
					</div>
				)}
			</Card>

			{isOtherDrawerOpen && (
				<NoteDrawer
					isDrawerOpen={isOtherDrawerOpen}
					toggleDrawer={toggleOtherDrawer}
					nextNote={nextNote}
					previousNote={previousNote}
					hasNext={hasNext}
					hasPrevious={hasPrevious}
					activeNoteIdx={activeNoteIdx}
					activeNote={notes[activeNoteIdx]}
					total={total}
					handleAddNote={onNoteAdd}
					onViewMore={onViewMore}
					candidateData={candidateData}
				/>
			)}
		</>
	);
};
NotesCard.prototype = {
	...Card.prototype,
	id: PropTypes.string.isRequired
};
export default NotesCard;
