import React from "react";
import { Item } from "react-stately";
import Description from "common/Description";
import styles from "./request-details-card.module.scss";
import { ReactComponent as BuildingIcon } from "static/icons/building-icon.svg";
import { ReactComponent as AddressIcon } from "static/icons/address-icon.svg";
import { ReactComponent as ClockIcon } from "static/icons/clock-icon.svg";
import { Button } from "common/Button";
import { ComboBox } from "common/ComboBox";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import { RequestDetailsCardEmptyState } from "../RequestDetailsCardEmptyState";
import { historyPush, isHTML } from "config/helpers";
import { COMPANY_DEFAULT_AVATAR } from "modules/freelancers/utils/constants";
import { REMOTE } from "config";
import { REMOTE_LABEL } from "config";
import { get } from "lodash";
import LexicalContentView from "common/LexicalContentView";

const RequestDetailsCard = ({
	requestsList,
	onRequestInputChange,
	onRequestSelectionChange,
	request,
	displayButtonNewRequest,
	freelancerFirstName
}) => {
	const onNewRequest = () => {
		historyPush("/jobs/post/profile");
	};

	return (
		<div className={styles.root}>
			<div className={styles.requestContainer}>
				<div className={styles.requestLabel}>Request</div>
				<ComboBox
					displayButton={displayButtonNewRequest}
					button={
						<Button
							text="Create new request"
							variant="text"
							textClassName={styles.addRequestButtonText}
							className={styles.addRequestButton}
							icon={<AddIcon />}
							onClick={onNewRequest}
						/>
					}
					selectedKey={request?.value}
					inputValue={request?.label}
					onInputChange={value => onRequestInputChange(value)}
					onSelectionChange={key => onRequestSelectionChange(key)}
					inputRootClassName={styles.inputRequest}
				>
					{requestsList.map(({ _id, title }) => (
						<Item key={_id}>{title}</Item>
					))}
				</ComboBox>
			</div>
			{request ? (
				<div className={styles.greyCard}>
					<p className={styles.message}>
						Dear {freelancerFirstName},
						<br />
						We would like to share with you the following request:
					</p>
					<div className={styles.container}>
						<div className={styles.header}>
							<div className={styles.requestName}>{request.title}</div>
							<img
								src={request.site?.avatar || COMPANY_DEFAULT_AVATAR}
								alt="company-logo"
								className={styles.logo}
							/>
						</div>
						<div className={styles.grid}>
							<BuildingIcon
								height={24}
								width={24}
								className={styles.BuildingIcon}
							/>
							<AddressIcon
								height={24}
								width={24}
								className={styles.addressIcon}
							/>
							<ClockIcon height={24} width={24} className={styles.clockIcon} />
							<div className={styles.companyName}>{request.site?.name}</div>
							<div className={styles.country}>
								{get(request, "location_type", "") === REMOTE
									? REMOTE_LABEL
									: `${request.city}, ${request.country}`}
							</div>
							<div className={styles.availability}>
								{request.part_time ? "Part-time" : "Full-time"}
							</div>
						</div>

						<div className={"section description"}>
							{isHTML(request.description) ? (
								<LexicalContentView description={request.description} />
							) : (
								<Description
									description={request.description}
									parseMarkdown
									showMore
									max={500}
									showMoreText={""}
									className="requestDescription"
								/>
							)}
						</div>

						<Button
							rootClassName={styles.sendButton}
							className={styles.button}
							text="Open job"
						/>
					</div>

					<p className={styles.message}>
						Kind regards,
						<br />
						Team {request.site?.name}
					</p>
				</div>
			) : (
				<RequestDetailsCardEmptyState />
			)}
		</div>
	);
};

export default RequestDetailsCard;
