import React from "react";
import { Link } from "react-router";

const CompleteProfile = () => {
	return (
		<div className="complete-profile">
			<div className="title">
				<h5>Complete your profile</h5>
			</div>
			<div className="content">
				You cannot submit a proposal for this job because you haven&#39;t
				finished your profile, please <Link to="/cv">click here</Link> to do so.
			</div>
		</div>
	);
};

export default CompleteProfile;
