import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { MOVE_TIER } from "config/api-endpoints";

function inviteToSupplierList(body) {
	return client(MOVE_TIER, {
		body
	});
}

function useInviteToSupplierList(options) {
	return useMutation(inviteToSupplierList, options);
}

export default useInviteToSupplierList;
