import React, { useMemo } from "react";
import { useQuery } from "react-query";
import _get from "lodash/get";
import { ResponsiveBar } from "@nivo/bar";
import { VACANCY_PERFORMANCE, LOADING, SUCCESS, ERROR } from "config";
import { client } from "lib/api-client";
import { DASHBOARD_DATA_ENDPOINT } from "config/api-endpoints";
import ChartLoading from "../components/ChartLoading";
import ChartError from "../components/ChartError";
import ChartEmpty from "../components/ChartEmpty";
import { SectionInfo } from "../styleCurrentSearch";
import Loader from "common/Loader";
import { excerpt } from "common/Functions";

const fetchVacancyPerformanceData = () =>
	client(DASHBOARD_DATA_ENDPOINT, {
		body: { tag: VACANCY_PERFORMANCE }
	});

const VacancyPerformanceChart = () => {
	const { status, data, isFetching, refetch } = useQuery(
		[VACANCY_PERFORMANCE],
		fetchVacancyPerformanceData
	);
	const chartData = useMemo(() =>
		_get(data, "vacancy_performance.data", []).slice(0, 8)
	);
	const isEmpty = chartData.length === 0;
	const renderChart = status => {
		switch (status) {
			case ERROR:
				return <ChartError onReload={refetch} />;
			case LOADING:
				return <ChartLoading />;
			case SUCCESS:
				if (isEmpty) {
					return <ChartEmpty title="Vacancy performance" />;
				}
				return (
					<>
						<div className="chart-title">
							Vacancy performance {isFetching && <Loader />}{" "}
						</div>

						<ResponsiveBar
							data={chartData}
							keys={[
								"total_shortlists",
								"total_offers",
								"total_interviews",
								"total_applications"
							]}
							margin={{ right: 30, top: 30, bottom: 130, left: 180 }}
							padding={0.3}
							layout="horizontal"
							indexBy={"title"}
							colors={["#0099fa", "#f7b422", "#56bd5b", "#3c78d8"]}
							borderColor={{ from: "color", modifiers: [["brighter", "1.6"]] }}
							axisTop={null}
							axisRight={null}
							axisBottom={null}
							axisLeft={{
								tickSize: 0,
								tickPadding: 7,
								tickRotation: 0,
								renderTick: ({ value, x, y }) => {
									return (
										<g transform={`translate(${x},${y})`}>
											<text
												textAnchor={500}
												transform={`translate(-148,3)`}
												style={{
													fontWeight: 500,
													fontSize: "12px",
													fill: "#091842"
												}}
											>
												<tspan>{excerpt(value, 15)}</tspan>
											</text>
										</g>
									);
								}
							}}
							enableGridX={true}
							enableGridY={false}
							theme={{
								tooltip: {
									container: {
										background: "#394047",
										color: "#fff",
										fontSize: "12px",
										opacity: 1
									}
								},
								axis: {
									fontSize: "14px",
									tickColor: "#eee",
									ticks: {
										text: {
											fill: "#091842"
										}
									}
								}
							}}
							labelFormat={d => (
								<tspan style={{ fontWeight: "bold" }}>{d}</tspan>
							)}
							tooltip={({ data }) => (
								<span>
									<p
										style={{
											color: "#fff",
											fontSize: "12px",
											marginBottom: "5px"
										}}
									>
										{data.title}
									</p>
									{`# Of sourced: ${_get(data, "total_shortlists", 0)} `}
									<br />
									{`# Of Offers: ${_get(data, "total_offers", 0)}`}
									<br />
									{`# Of Interviews: ${_get(data, "total_interviews", 0)} `}
									<br />
									{`# Of Applications: ${_get(data, "total_applications", 0)} `}
									<br />
								</span>
							)}
							labelTextColor="#fff"
							animate={true}
							motionStiffness={90}
							motionDamping={15}
						/>
						<SectionInfo isvacancyPer={true}>
							<div className="ova">
								<div className="section-ova">
									<span className="Ova-1" /> # Of Shortlists
								</div>
								<div className="section-ova">
									<span className="Ova-2" /> # Of Offers
								</div>
								<div className="section-ova">
									<span className="Ova-3" /> # Of Interviews
								</div>
								<div className="section-ova">
									<span className="Ova-4" /> # Of Applications
								</div>
							</div>
						</SectionInfo>
					</>
				);
			default:
				return <></>;
		}
	};

	return <>{renderChart(status)}</>;
};

export default VacancyPerformanceChart;
