import React from "react";
const ResendActivation = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.11342 8.86185C2.02262 8.71809 1.97723 8.64621 1.95182 8.53534C1.93273 8.45206 1.93273 8.32073 1.95182 8.23745C1.97723 8.12658 2.02262 8.0547 2.11341 7.91094C2.86369 6.72295 5.09693 3.71973 8.50027 3.71973C11.9036 3.71973 14.1369 6.72295 14.8871 7.91094C14.9779 8.0547 15.0233 8.12658 15.0487 8.23745C15.0678 8.32073 15.0678 8.45206 15.0487 8.53534C15.0233 8.64621 14.9779 8.71809 14.8871 8.86185C14.1369 10.0498 11.9036 13.0531 8.50027 13.0531C5.09693 13.0531 2.86369 10.0498 2.11342 8.86185Z"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.50027 10.3864C9.60484 10.3864 10.5003 9.49096 10.5003 8.38639C10.5003 7.28182 9.60484 6.38639 8.50027 6.38639C7.3957 6.38639 6.50027 7.28182 6.50027 8.38639C6.50027 9.49096 7.3957 10.3864 8.50027 10.3864Z"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default ResendActivation;
