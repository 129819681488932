import create from "zustand";

export const shortListStore = create(set => ({
	isOpen: false,
	setIsOpen: value => set({ isOpen: value })
}));

const useShortList = () => {
	const { isOpen, setIsOpen } = shortListStore(state => ({ ...state }));
	return {
		isOpen,
		setIsOpen
	};
};

export default useShortList;
