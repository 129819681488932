import React, {
	useState,
	useRef,
	useMemo,
	useEffect,
	useCallback
} from "react";
import style from "./multiSites_header.module.scss";
import cx from "classnames";
import HeaderButton from "../../SearchCandidate/HeaderButton";
import { ReactComponent as AddIcon } from "../../../static/icons/PlusAdd.svg";
import { ReactComponent as DropDownArrow } from "static/icons/ArrowIcon.svg";
import SiteRender from "./SiteRender";
import { getVisibleAndHiddenTabIndices } from "../utils/headerHelper";
import get from "lodash/get";
import { useToggle } from "react-use";
import { useOverlayTriggerState } from "react-stately";
import {
	OverlayContainer,
	OverlayProvider,
	useButton,
	useOverlayPosition,
	useOverlayTrigger
} from "react-aria";
import { Popover } from "common/DropDownWithSearch/Popover";
import { TabMeasurer } from "./TabMeasurer/TabMeasurer";
import Tooltip from "common/Tippy";
import { ReactComponent as TooltipIcon } from "static/icons/tooltipIcon.svg";

const PILL_MARGIN = 7;

const MultiSitesHeader = ({
	tabs = [],
	HandleShowAddSite,
	handleCurrentSiteChange,
	displayAddSiteBtn = true,
	offset = 0,
	// defaultSite,
	IsAdmin,
	currentSite
}) => {
	let state = useOverlayTriggerState({});
	const tabState = useMemo(() => {
		return tabs.sort((a, b) => Number(b.is_default) - Number(a.is_default));
	}, [tabs]);
	const [selectedTab, setSelectedTab] = useState(0);
	const [visibleTabs, setVisibleTabs] = useState([]);
	const [hiddenTabs, setHiddenTabs] = useState([]);
	const [open, setOpen] = useToggle(false);
	const triggerRef = useRef();
	const overlayRef = useRef();
	const defaultPillRef = useRef();
	const navRef = useRef();
	const pillWidth = get(defaultPillRef, "current.offsetWidth", 0) + PILL_MARGIN;

	let { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		state,
		triggerRef
	);

	let { overlayProps: positionProps } = useOverlayPosition({
		targetRef: triggerRef,
		overlayRef,
		placement: "bottom end",
		offset: 10,
		isOpen: open
	});

	let { buttonProps } = useButton(
		{
			onClick: setOpen
		},
		triggerRef
	);

	useEffect(() => {
		if (currentSite && tabState) {
			const index = tabState.findIndex(({ _id }) => _id === currentSite);

			setSelectedTab(index);
		}
	}, [currentSite, tabState]);

	const handleViewChange = list => {
		handleCurrentSiteChange(list);
		const selectedTabIndex = tabState.findIndex(
			t => get(t, "_id") === get(list, "_id")
		);
		setSelectedTab(selectedTabIndex);
	};

	const handleMeasurement = useCallback(
		measurements => {
			const {
				hiddenTabWidths: tabWidths,
				containerWidth,
				disclosureWidth
			} = measurements;

			const { visibleTabs, hiddenTabs } = getVisibleAndHiddenTabIndices(
				tabState,
				selectedTab,
				disclosureWidth,
				tabWidths,
				containerWidth
			);

			setVisibleTabs(
				tabState.filter((el, idx) => {
					if (visibleTabs.includes(idx)) {
						return tabState[idx];
					}
				})
			);
			setHiddenTabs(
				tabState.filter((el, idx) => {
					if (hiddenTabs.includes(idx)) {
						return tabState[idx];
					}
				})
			);
		},
		[tabState]
	);

	const activator = (
		<button
			className={cx(style.seeMore)}
			{...buttonProps}
			{...triggerProps}
			ref={triggerRef}
		>
			+{hiddenTabs.length}
			<DropDownArrow className={style.arrow} />
		</button>
	);

	const handleSetActive = id => {
		if (currentSite) {
			return currentSite === id;
		} else {
			return tabState[selectedTab]?._id === id;
		}
	};

	return (
		<>
			<TabMeasurer
				tabs={tabState}
				handleMeasurement={handleMeasurement}
				offset={offset + pillWidth}
				activator={activator}
			/>
			<div className={style.panel}>
				{visibleTabs && (
					<nav className={style.tabsNav} ref={navRef}>
						<div className={style.flexContainer}>
							<div className={style.tabs}>
								{visibleTabs.map(item => {
									return (
										<button
											key={item?._id}
											onClick={() => {
												handleViewChange(item);
											}}
											className={cx(style.tab, {
												[style.active]: handleSetActive(item?._id)
											})}
										>
											{item?.name}
											{item.is_default && (
												<Tooltip content={`Main company`} theme="dark">
													<TooltipIcon />
												</Tooltip>
											)}
										</button>
									);
								})}
							</div>
							<OverlayProvider>
								{hiddenTabs.length > 0 && (
									<>
										<button
											className={cx(style.seeMore)}
											{...buttonProps}
											{...triggerProps}
											ref={triggerRef}
										>
											+{hiddenTabs.length}
											<DropDownArrow className={style.arrow} />
										</button>

										{open && (
											<OverlayContainer>
												<Popover
													{...overlayProps}
													{...positionProps}
													multiSite
													ref={overlayRef}
													isOpen={open}
													onClose={setOpen}
												>
													<ul className={style.dropDownUl}>
														{hiddenTabs.map(item => {
															return (
																<SiteRender
																	key={item._id}
																	list={item}
																	handleClick={handleViewChange}
																/>
															);
														})}
													</ul>
												</Popover>
											</OverlayContainer>
										)}
									</>
								)}
							</OverlayProvider>
						</div>
						<div className={style.presentationSlider}></div>
					</nav>
				)}
				{displayAddSiteBtn && IsAdmin && (
					<div className={style.btnContainer}>
						<HeaderButton
							onClick={HandleShowAddSite}
							text="Add Site"
							className="addSiteButton"
							icon={<AddIcon />}
						/>
					</div>
				)}
			</div>
		</>
	);
};

export default MultiSitesHeader;
