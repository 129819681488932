import React from "react";
import { useToggle } from "react-use";
import { XIcon } from "@heroicons/react/solid";
import ServicesFilterDialog from "./ServicesFilterDialog";
import FilterCard from "../../../shared/FilterCard";
import s from "./service-filter.module.scss";
import Portal from "../../../../../common/Portal";

function ServicesFilter({ store }) {
	const [on, toggle] = useToggle(false);
	const { services, setServices } = store(state => ({
		services: state.services,
		setServices: state.setServices
	}));

	const removeService = service => {
		setServices(services.filter(s => s.value !== service.value));
	};

	return (
		<>
			<FilterCard
				name={"Services"}
				onEdit={() => toggle(true)}
				onRemove={() => setServices([])}
				value={services}
			>
				<ul className={s.list}>
					{services.map(service => (
						<li className={s.listItem} key={service.value}>
							<span>{service.label}</span>
							<button onClick={() => removeService(service)}>
								<XIcon width={16} height={16} />
							</button>
						</li>
					))}
				</ul>
			</FilterCard>
			<Portal>
				<ServicesFilterDialog
					store={store}
					isOpen={on}
					onClose={() => toggle(false)}
				/>
			</Portal>
		</>
	);
}

export default ServicesFilter;
