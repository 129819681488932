import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";

import {
	ARCHIVE_REQUEST,
	PAUSE_REQUEST,
	CLOSED_TO_NEW_APPLICANTS,
	VACANCY
} from "config";
import { stopJobModal, stopJob } from "modules/job/actions";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";

function StopVacancy({
	job: { id, mode, title, close_reason },
	onSuccess,
	stopJobModal,
	stopJob
}) {
	const closeModal = e => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		stopJobModal();
	};

	const stopped = () => {
		stopJob({ id, close_reason }, mode, VACANCY).then(response => {
			if (response) {
				onSuccess(response);
			}
		});
	};

	let modalTitle = "";
	let modalContent = "";
	let labelText = "";

	switch (mode) {
		case ARCHIVE_REQUEST:
			modalTitle = `Are you sure you would like to archive this vacancy?`;
			labelText = "YES, ARCHIVE THIS VACANCY.";
			break;
		case PAUSE_REQUEST:
			modalTitle = `Are you sure you would like to unpublish the vacancy “${title}” ?`;
			modalContent = ``;
			labelText = "YES, UNPUBLISH THIS VACANCY.";
			break;
		case CLOSED_TO_NEW_APPLICANTS:
			modalTitle = `Are you sure you would like to close the vacancy “${title}” ?`;
			modalContent = ``;
			labelText = "YES, CLOSE THIS VACANCY.";
			break;
		default:
			break;
	}

	return (
		<ConfirmationModal
			modalName="stop_job"
			onClose={closeModal}
			type={ConfirmationTypes.error}
			title={modalTitle}
			content={modalContent}
			firstButton={{
				action: stopped,
				label: labelText,
				type: "warning"
			}}
		/>
	);
}

const mapStateToProps = state => {
	return {
		job: state.job.stopJob
	};
};

StopVacancy.propTypes = {
	onSuccess: PropTypes.func
};

StopVacancy.defaultProps = {
	onSuccess: () => {}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			stopJobModal,
			stopJob
		},
		dispatch
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(StopVacancy);
