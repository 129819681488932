import React from "react";
import { ReactComponent as DotsGridIcon } from "static/icons/dots-grid.svg";

export default function FormBuilderDragButton({ className, dragHandleProps }) {
	return (
		<div
			className={className}
			ref={dragHandleProps?.setActivatorNodeRef}
			{...(dragHandleProps?.listeners ?? {})}
		>
			<DotsGridIcon />
		</div>
	);
}
