import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_CONTRACTS_GROUP } from "config/api-endpoints";

function updateGroup(body) {
	return client(UPDATE_CONTRACTS_GROUP, {
		body
	});
}

function useUpdateContractsGroup(queryOptions) {
	return useMutation(updateGroup, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
}

export default useUpdateContractsGroup;
