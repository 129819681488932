import React, { useEffect } from "react";
import path_logo from "static/images/logo-white.svg";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import slideImage1 from "static/images/auth-slider/slide1.png";
import slideImage2 from "static/images/auth-slider/slide2.png";
import slideImage3 from "static/images/auth-slider/slide3.png";
import activateAccountSlideImage1 from "static/images/auth-slider/activate-account/slide1.png";
import activateAccountSlideImage2 from "static/images/auth-slider/activate-account/slide2.png";
import activateAccountSlideImage3 from "static/images/auth-slider/activate-account/slide3.png";
import activateAccountSlideImage4 from "static/images/auth-slider/activate-account/slide4.png";
import Container from "./Container";
import BodyClassName from "react-body-classname";
import { Link } from "react-router";
import { Box, Flex } from "rebass";
import { Helmet } from "react-helmet";
import ChromeBanner from "common/ChromeBanner";
import { useLocale } from "./store";
import translations from "./translations.json";
import useWindowSize from "hooks/useWindowSize";
import cx from "classnames";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const mainSliderItems = [
	{
		id: "1",
		title: "Transform the way you manage your vacancies.",
		description:
			"Helps you find top-notch freelancers for your projects with advanced search tools, and manage your projects with ease. Stay up to date with progress and collaborate effortlessly to ensure project success.",
		image: slideImage1
	},
	{
		id: "2",
		title: "A wide pool of potential candidates ready to hire",
		description:
			"Designed to enhance your recruitment process by leveraging advanced search tools and providing you with all the necessary information to locate top-notch candidates for your vacancies.",
		image: slideImage2
	},
	{
		id: "3",
		title: "Find the Perfect Temporary Staffing Solution.",
		description:
			"Designed to simplify and streamline your recruitment process. With a range of powerful features and tools to manage your vacancies in a more effective way.",
		image: slideImage3
	}
];

const activateAccountSliderItems = [
	{
		id: "1",
		title: "Take control of your job application process.",
		description: `Take control of your job application process with ease using our platform's advanced features. Our user-friendly design makes managing your job opportunities a breeze, allowing you to focus on finding the right fit.`,
		image: activateAccountSlideImage1
	},
	{
		id: "2",
		title: "keep your profile up-to-date and shining bright. ",
		description: `Our platform makes it easy to keep your profile up-to-date and shining bright. With just a few clicks, you can effortlessly add or switch functions, skills, and personal details, ensuring that you're always attracting the right opportunities.`,
		image: activateAccountSlideImage2
	},
	{
		id: "3",
		title: "Find and apply to jobs that match your skills.",
		description: ` helps you find top-notch freelancers for your projects with advanced search tools, and manage your projects with ease. Stay up to date with progress and collaborate effortlessly to ensure project success.`,
		image: activateAccountSlideImage3
	},
	{
		id: "4",
		title: "Never miss out on the perfect job opportunity.",
		description: `Our platform keeps you updated with all the job offers related to your favorite companies, so you never miss out on the perfect opportunity. With just a few clicks, you can stay on top of the latest job openings and take the next step.`,
		image: activateAccountSlideImage4
	}
];

export default function AuthenticationLayout({
	children,
	useActivateAccountSlider = false
}) {
	const { locale, setLocale } = useLocale(state => state);
	const dictionnary = translations[locale];
	const sliderItems = useActivateAccountSlider
		? activateAccountSliderItems
		: mainSliderItems;

	useEffect(() => {
		return () => {
			setLocale("en");
		};
	}, []);

	const isMobile = useWindowSize();

	return (
		<BodyClassName
			className={cx("authentication", { mobileBody: isMobile })}
			flexGrow={1}
		>
			<Flex
				flexDirection={"column"}
				className={cx("authentication-container", {
					mobileAuthenticationContainer: isMobile
				})}
				height={"100%"}
			>
				<Helmet>
					<meta name="viewport" content="width=device-width, initial-scale=1" />
				</Helmet>
				{!isMobile && <ChromeBanner />}
				<Flex flexGrow={1}>
					{!isMobile && (
						<Box
							height={"100vh"}
							width={"50%"}
							className="slider"
							style={{ position: "sticky", top: 0 }}
						>
							<div className="gradient-1"></div>
							<div className="gradient-2"></div>
							<Container style={{ height: "100%" }}>
								<Flex flexDirection={"column"} height={"100%"}>
									<Link to="/" className="logo">
										<img src={path_logo} alt="Wiggli" height="36" />
									</Link>
									<Box flexGrow={1}>
										<Flex
											justifyContent={"center"}
											alignItems={"center"}
											height={"100%"}
										>
											<div className="slides">
												<div className="images">
													<div className="top-caption-container"></div>
													<AutoplaySlider
														organicArrows={false}
														interval={3000}
														play={true}
													>
														{sliderItems.map(item => (
															<div key={item.id} className="slide">
																<img className="image" src={item.image} />
																<div className="top-caption caption-animated">
																	{item.title}
																</div>
																<div className="bottom-caption caption-animated">
																	{item.description}
																</div>
															</div>
														))}
													</AutoplaySlider>
												</div>
											</div>
										</Flex>
									</Box>
								</Flex>
							</Container>
						</Box>
					)}
					<Flex
						flexDirection={"column"}
						width={isMobile ? "100%" : "50%"}
						className="content"
					>
						<Flex flexDirection={"column"} flexGrow={1}>
							{children}
						</Flex>
						<Box
							className={cx("content__footer", {
								mobileFooter: isMobile
							})}
						>
							<Container style={{ width: isMobile ? "91%" : "" }}>
								<Flex justifyContent={"space-between"}>
									<Box display={"flex"} style={{ gap: 10 }}>
										<a href="https://www.wiggli.io/privacy-policy">
											{dictionnary["footer.privacy"]}
										</a>
										<span>&</span>
										<a href="https://www.wiggli.io/terms-of-use">
											{dictionnary["footer.terms"]}
										</a>
									</Box>
									<Box>
										<Link to="/ask-question">{dictionnary["footer.help"]}</Link>
									</Box>
								</Flex>
							</Container>
						</Box>
					</Flex>
				</Flex>
			</Flex>
		</BodyClassName>
	);
}
