import { capitalize } from "lodash";
import { client } from "lib/api-client";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import {
	getFullName,
	convertCalendarDateToMS,
	utcTimeStamp,
	convertTStoString,
	getHourFromTS
} from "config/helpers";
import {
	REMINDER_BEFORE,
	REMINDER_AFTER,
	REQUEST,
	PRIORITY,
	FREELANCER,
	CREATED_BY,
	STATUS
} from "config";
import { getValidFilters } from "common/FilterDrawer/utils";
import { TASKS_TABLE_COLUMNS_IDS } from "./constant";

export const formatTasksList = list => {
	return list.map(item => {
		const newItem = { ...item };

		for (var key in item) {
			const value = item[key];
			if (key === TASKS_TABLE_COLUMNS_IDS.status) {
				newItem[key] = capitalize(value);
			} else if (key === TASKS_TABLE_COLUMNS_IDS.due_date) {
				newItem[key] = `${convertTStoString(value)} ${getHourFromTS(value)}`;
			} else if (key === TASKS_TABLE_COLUMNS_IDS.created_at) {
				newItem[key] = convertTStoString(value);
			} else if (key === TASKS_TABLE_COLUMNS_IDS.linked_to) {
				newItem[key] = value.entity;
			} else if (
				key === TASKS_TABLE_COLUMNS_IDS.created_by ||
				key === TASKS_TABLE_COLUMNS_IDS.assigned_user
			) {
				newItem[key] = {
					first_name: value.first_name,
					last_name: value.last_name,
					avatar: value.avatar,
					_id: value._id
				};
			} else if (key === TASKS_TABLE_COLUMNS_IDS.evaluation_task) {
				newItem.attachments = { ...value, linked_to: item.linked_to };
			}
		}
		newItem.initial_record = item;
		return newItem;
	});
};

const fetchData = ({ tag, search = undefined }) => {
	return client(NEW_FILTER_SETTINGS, {
		body: {
			list: "tasks",
			tag,
			search
		}
	});
};

export const getInternalList = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "assigned_user_internal", search })
			.then(res => {
				const list = res.map(({ _id, ...rest }) => ({
					label: getFullName(rest),
					id: _id
				}));
				resolve([{ id: 1, label: "Internal", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getExternalList = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "assigned_user_external", search })
			.then(res => {
				const list = res.map(({ _id, ...rest }) => ({
					label: getFullName(rest),
					id: _id
				}));
				resolve([{ id: 1, label: "External", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getUsersList = () => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: CREATED_BY })
			.then(res => {
				const list = res.map(item => ({
					label: getFullName(item),
					id: item._id
				}));
				resolve([{ id: 1, label: "Created by", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getTaskStatusList = () => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: STATUS })
			.then(res => {
				const list = res.map(({ label, value }) => ({
					label,
					id: value
				}));
				resolve([{ id: 1, label: "Status", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getTaskPriorityList = () => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: PRIORITY })
			.then(res => {
				const list = res.map(({ label, value }) => ({
					label,
					id: value
				}));
				resolve([{ id: 1, label: "Priority", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getCandidatesList = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "candidate", search })
			.then(res => {
				const list = res.map(({ _id, ...rest }) => ({
					label: getFullName(rest),
					id: _id
				}));
				resolve([{ id: 1, label: "Candidate", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getFreelancerList = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: FREELANCER, search })
			.then(res => {
				const list = res.map(({ _id, ...rest }) => ({
					label: getFullName(rest),
					id: _id
				}));
				resolve([{ id: 1, label: "Freelancer", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getSupplierList = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "supplier", search })
			.then(res => {
				const list = res.map(({ name, _id }) => ({
					label: name,
					id: _id
				}));
				resolve([{ id: 1, label: "Supplier", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getVacancyList = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: "vacancy", search })
			.then(res => {
				const list = res.map(({ title, _id }) => ({
					label: title,
					id: _id
				}));
				resolve([{ id: 1, label: "Vacancy", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getRequestList = (_, { search }) => {
	return new Promise((resolve, reject) => {
		fetchData({ tag: REQUEST, search })
			.then(res => {
				const list = res.map(({ title, _id }) => ({
					label: title,
					id: _id
				}));
				resolve([{ id: 1, label: "Request", children: list }]);
			})
			.catch(e => reject(e));
	});
};

export const getTasksReminderOptions = () => {
	const list1 = REMINDER_BEFORE.map(({ label, value }) => ({
		value: {
			time: value,
			reminding_time: "before"
		},
		label
	}));

	const list2 = REMINDER_AFTER.map(({ label, value }) => ({
		value: {
			time: value,
			reminding_time: "after"
		},
		label
	}));

	return [...list1, ...list2];
};

export const getSearchTasksPayload = ({
	filters,
	logicalOperator,
	offset,
	limit,
	query,
	sortBy
}) => {
	return {
		op: logicalOperator,
		fields: formatFilters(filters),
		search: query,
		offset,
		sort_by: sortBy,
		limit,
		isDescriptionSearch: true,
		isTitleSearch: true
	};
};

const formatFilters = list => {
	const newList = getValidFilters(list);

	return newList.map(({ name, operator, value }) => {
		let newValue;

		if (isDate(value)) {
			newValue = formatFilterTypDate(value);
		} else if (Array.isArray(value)) {
			newValue = formatFilterTypePicker(value);
		} else {
			newValue = value;
		}

		if (isLinkedTo(name)) {
			return {
				field: TASKS_TABLE_COLUMNS_IDS.linked_to,
				op: operator,
				value: {
					entity: name,
					_id: newValue
				}
			};
		} else {
			return {
				field: ["internal", "external"].includes(name)
					? TASKS_TABLE_COLUMNS_IDS.assigned_user
					: name,
				value: newValue,
				op: operator
			};
		}
	});
};

const isLinkedTo = value => {
	return ["freelancer", "candidate", "supplier", "vacancy", "request"].includes(
		value
	);
};

function formatFilterTypePicker(items) {
	const values = items.reduce((acc, item) => [...acc, ...item.children], []);
	return values.map(({ id }) => id);
}

function formatFilterTypDate(value) {
	let start = value.start || value;
	let end = value.end || value;

	return [
		utcTimeStamp({
			date: convertCalendarDateToMS(start)
		}),
		utcTimeStamp({
			date: convertCalendarDateToMS(end),
			isStart: false
		})
	];
}

export function isDate(value) {
	return (value.day && value.month && value.year) || (value.start && value.end);
}
