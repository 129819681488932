import { get } from "lodash";
import {
	LINK_TO,
	REMINDER,
	CREATED_BY,
	ASSIGNED_TO,
	LINK_TO_NOTES,
	USER_ID,
	ALLOWED_USERS
} from "config";

///setters

export const useSelectors = store => {
	const { filters: state, setFields, updateFields, updateOffset } = store();

	const addFilter = field => {
		setFields({
			field: get(field, "field"),
			op: "is",
			value:
				get(field, "field") === LINK_TO || get(field, "field") === LINK_TO_NOTES
					? {
							entity: get(field, "selectedOption.value"),
							_id: []
					  }
					: get(field, "field") === REMINDER
					? {
							time: "",
							reminding_time: ""
					  }
					: []
		});
	};
	const setFilter = (field, index) => {
		let value = [];
		switch (get(field, "field")) {
			case LINK_TO_NOTES:
			case LINK_TO:
				value = {
					entity: get(field, "selectedOption.value"),
					_id: []
				};
				break;
			case REMINDER:
				value = {
					time: "",
					reminding_time: ""
				};
				break;
			default:
				value = "";
				break;
		}
		updateFields(
			{
				field: get(field, "field"),
				op: "is",
				value
			},
			index
		);
	};

	const setFilterValue = (field, value, index) => {
		const oldValue = get(get(state, "fields", [])[index], "value", []);
		updateOffset(0);
		let newValue = {};
		switch (get(field, "field")) {
			case LINK_TO_NOTES:
			case LINK_TO:
				if (
					get(field, "selectedOption.value", "") === "candidate" ||
					get(field, "selectedOption.value", "") === "freelancer" ||
					get(field, "selectedOption.value", "") === "profile" ||
					get(field, "selectedOption.value", "") === "supplier"
				) {
					newValue = {
						...oldValue,
						_id: [...value.map(item => get(item, "value", get(item, "_id")))]
					};
				} else {
					newValue = {
						...oldValue,
						_id: [
							...get(oldValue, "_id", []),
							get(value, "value", get(value, "_id"))
						]
					};
				}

				break;
			case CREATED_BY:
			case USER_ID:
			case ALLOWED_USERS:
			case ASSIGNED_TO:
				newValue = [...value.map(item => get(item, "value", get(item, "_id")))];
				break;
			case REMINDER:
				newValue = {
					time: get(value, "value"),
					reminding_time: get(value, "label", "").split(" ")[2]
				};
				break;
			default:
				newValue = get(field, "isMultipleSelect", false) ? [...value] : value;
				break;
		}
		updateFields(
			{
				...get(state, "fields", [])[index],
				value: newValue
			},
			index
		);
	};

	const deleteFilterValue = (field, value, index) => {
		let newValue = null;
		const oldValue = get(get(state, "fields", [])[index], "value", []);
		switch (get(field, "field")) {
			case LINK_TO:
			case LINK_TO_NOTES:
				const filteredValues = get(oldValue, "_id", []).filter(
					(_, index) => index !== value
				);
				newValue = {
					...oldValue,
					_id: filteredValues
				};
				break;
			default:
				newValue = oldValue.filter((_, index) => index !== value);
				break;
		}

		updateFields(
			{
				...get(state, "fields", [])[index],
				value: newValue
			},
			index
		);
	};

	return {
		addFilter,
		setFilter,
		setFilterValue,
		deleteFilterValue
	};
};
