import { OPERATORS } from "common/FilterDrawer/utils";
import { TYPES } from ".";
import { getCreatedByList, getScoresList, getVacancyList } from "./_helper";
import createdByIcon from "static/icons/user-edit.svg";
import VacancyIcon from "static/icons/briefcase-01.svg";
import ScoreIcon from "static/icons/star-01.svg";
import dateIcon from "static/icons/calendar-04.svg";
import editedIcon from "static/icons/edit-04.svg";

export const getColumns = profileId => {
	return [
		{
			id: 1,
			label: "Created by",
			name: "created_by",
			type: TYPES.customMultiSelect,
			operator: OPERATORS.is,
			icon: createdByIcon,
			payload: {
				displayRating: false,
				primaryHeaderContent: () => "Created by remained",
				secondaryHeaderContent: () => "Created by selected",
				useServerFilter: false,
				queryFn: getCreatedByList
			}
		},
		{
			id: 2,
			label: "Vacancy",
			name: "job",
			type: TYPES.customMultiSelect,
			operator: OPERATORS.is,
			icon: VacancyIcon,
			payload: {
				displayRating: false,
				primaryHeaderContent: () => "Vacancy remained",
				secondaryHeaderContent: () => "Vacancy selected",
				useServerFilter: false,
				queryFn: () => getVacancyList(profileId)
			}
		},
		{
			id: 3,
			name: "score",
			label: "Score",
			icon: ScoreIcon,
			operator: OPERATORS.is,
			type: TYPES.customMultiSelect,
			payload: {
				displayRating: false,
				useServerFilter: false,
				queryFn: getScoresList,
				primaryHeaderContent: () => "Score remained",
				secondaryHeaderContent: () => "Score selected"
			}
		},
		{
			id: 4,
			label: "Date",
			name: "date",
			type: TYPES.date,
			icon: dateIcon,
			operator: OPERATORS.is
		},
		{
			id: 5,
			label: "Edited",
			name: "edited",
			type: TYPES.booleanCustomDropDown,
			icon: editedIcon,
			operator: OPERATORS.is
		}
	];
};
