import React from "react";
import { BotProvider } from "./botContext";
import ChatBot from "./ChatBot";

function SuccessBot({ email, type }) {
	return (
		<BotProvider type={type}>
			<ChatBot email={email} type={type} />
		</BotProvider>
	);
}

export default SuccessBot;
