import React, { Component } from "react";
import _get from "lodash/get";
import BodyClassName from "react-body-classname";
//components
import { Helmet } from "react-helmet";

export const getCreateJobRoute = () => {
	return "/jobs/post";
};
export const getCreateVacancyRoute = () => {
	return "/vacancy/post";
};

export const getCreateJobPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
export const getCreateVacancyPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

class CreateJob extends Component {
	getActiveRoute = () => {
		return _get(this.props, "children.props.route.active", 0);
	};

	render() {
		const { route, children } = this.props;
		return (
			<BodyClassName className="my-job">
				<div className="create-job">
					{route.title && (
						<Helmet>
							<title>{route.title}</title>
						</Helmet>
					)}
					<div>{children}</div>
				</div>
			</BodyClassName>
		);
	}
}

export default CreateJob;
