import React from "react";
import { SwitchContainer } from "./Styled";

function Switch({ checked, onChange }) {
	return (
		<SwitchContainer>
			<label className="switch">
				<input
					type="checkbox"
					checked={checked}
					onChange={() => onChange(!checked)}
				/>
				<span
					className="slider round"
					style={{ border: !checked ? "1px solid #ccc" : "none" }}
				></span>
			</label>
		</SwitchContainer>
	);
}

export default Switch;
