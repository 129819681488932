import React from "react";
import AxiosHelper from "config/axios-helper";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { REMOVE_SHORTLIST_ENDPOINT } from "config/api-endpoints";

const RemoveShortlistModal = ({ active, closeModal, id, nextAction }) => {
	const removeShortlist = () => {
		return AxiosHelper.post({
			url: REMOVE_SHORTLIST_ENDPOINT,
			data: {
				id
			},
			toastMessage: "Sourced successfully removed.",
			isModal: true
		}).then(() => {
			nextAction && nextAction();
			closeModal();
		});
	};

	return (
		<ConfirmationModal
			active={active}
			modalName="hide-from-search"
			onClose={closeModal}
			firstButton={{
				action: removeShortlist,
				label: "YES, REMOVE SHORTLIST",
				type: "danger"
			}}
			title="Are you sure you would like to remove this shortlist?"
			type={ConfirmationTypes.error}
		/>
	);
};

export default RemoveShortlistModal;
