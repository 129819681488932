import React from "react";
import cx from "classnames";
import styles from "./evaluation-score-button.module.scss";

export default function EvaluationScoreButton({
	onClick,
	children,
	className = "",
	disabled = false
}) {
	return (
		<button
			type="button"
			onClick={onClick}
			disabled={disabled}
			className={cx(styles.evaluationScoreBtn, className)}
		>
			{children}
		</button>
	);
}
