import { useQuery } from "react-query";
import { TIMESHEET_REPORTING_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";

const getReportingSettings = () => {
	return client(TIMESHEET_REPORTING_SETTINGS, {
		body: {
			tag: "create_mission"
		}
	});
};

export const REPORTING_SETTINGS_KEY = "REPORTING_SETTINGS_KEY";

export const useGetReportingSettingsList = () => {
	return useQuery(REPORTING_SETTINGS_KEY, getReportingSettings, {
		refetchOnWindowFocus: false
	});
};
