import React from "react";
import styles from "./styles.module.scss";

const Box = ({ children, headerStart, headerEnd }) => {
	return (
		<div className={styles.box_container}>
			<div className={styles.box_header}>
				<div>{headerStart}</div>
				<div>{headerEnd}</div>
			</div>
			<div>{children}</div>
		</div>
	);
};

export default Box;
