import React from "react";
import SearchParams from "../SearchParams";
import classnames from "./filterBox.module.scss";
import ListFilter from "common/ListFilter";

const FilterBox = ({
	searchKeyword,
	setSearchKeyword,
	sortBylist,
	filterList,
	store,
	historyEdit,
	...rest
}) => {
	return (
		<div className={classnames.filterBoxContainer}>
			<div className={classnames.leftSide}>
				<SearchParams
					searchKeyword={searchKeyword}
					setSearchKeyword={setSearchKeyword}
					hideParams={false}
					historyEdit={historyEdit}
					{...{ ...rest }}
				/>
			</div>
			<ListFilter
				filterList={filterList}
				sortBylist={sortBylist}
				store={store}
				hideParams={true}
				historyEdit={historyEdit}
				{...{ ...rest }}
			/>
		</div>
	);
};

export default FilterBox;
