import React, { useEffect } from "react";
import { useScript } from "../hooks";
import { RECAPTCHA_SITE_KEY } from "config";

function ReCaptcha({ verifyCallback, apiResponse }) {
	const testMode = window.Cypress;
	const testKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

	const key = testMode ? testKey : RECAPTCHA_SITE_KEY;

	const [loaded, error] = useScript(
		`https://www.google.com/recaptcha/api.js?render=explicit`
	);

	useEffect(() => {
		if (loaded && !error) {
			if (window.grecaptcha) {
				const grecaptcha = window.grecaptcha;
				grecaptcha.ready(() => {
					grecaptcha.render("recaptcha", {
						sitekey: key,
						callback: verifyCallback
					});
				});
			}
		}
	}, [loaded, error]);

	useEffect(() => {
		if (apiResponse && !apiResponse.success) {
			window.grecaptcha.reset();
		}
	}, [apiResponse]);

	return <div id={"recaptcha"} data-sitekey={key} />;
}

export default ReCaptcha;
