import React from "react";
import styles from "./styles.module.scss";
import CustomizableModel from "common/CustomizableModel/CustomizableModel";
import DeleteButton from "./DeleteButton";
import get from "lodash/get";
import useDeleteCommunityCriteria from "modules/SearchCandidate/api/useDeleteCommunityCriteria";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";

const DeleteModal = ({ criteria, onClose }) => {
	const { clearFilters, clearSmartQuery } = useFilters();
	const [mutate, { isLoading }] = useDeleteCommunityCriteria({
		onSuccess: () => {
			toaster.success("Community criteria Deleted");
			queryCache.invalidateQueries("getCommunityCriteria");
			onClose();
		},
		onError: error => {
			toaster.danger(
				get(error, "detail.name") ? error.detail.name : error.detail
			);
		}
	});

	const deleteCriteraHandler = id => {
		mutate({ id });
		clearFilters();
		clearSmartQuery();
	};

	return (
		<CustomizableModel
			title="Delete filters"
			actionButton={
				<DeleteButton
					onClick={() => deleteCriteraHandler(get(criteria, "_id", ""))}
					isLoading={isLoading}
				/>
			}
			onClose={onClose}
		>
			<p className={styles.modal__text}>
				Are you sure you want to delete :{" "}
				<span className={styles.modal__criteria}>
					{get(criteria, "name", "")}
				</span>
			</p>
		</CustomizableModel>
	);
};

export default DeleteModal;
