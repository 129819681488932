import create from "zustand";

const initialState = {
	request: null,
	selectedChannels: [],
	isConfirmationDrawerOpen: false
};

const usePublishRequestStore = create(set => ({
	...initialState,
	setRequest: request => set({ request }),
	toggleSelectChannel: channel =>
		set(({ selectedChannels }) => {
			const result = [...selectedChannels];
			const index = selectedChannels.findIndex(
				c => c.product_id === channel.product_id
			);
			const isChannelAlreadySelected = index > -1;
			if (isChannelAlreadySelected) {
				result.splice(index, 1);
			} else {
				result.push(channel);
			}
			return { selectedChannels: result };
		}),
	setIsConfirmationDrawerOpen: isConfirmationDrawerOpen =>
		set({ isConfirmationDrawerOpen }),
	cleanStore: () => set(() => initialState)
}));

export default usePublishRequestStore;
