import * as React from "react";
import styles from "./requests.module.scss";
import RequestsHeader from "./components/RequestsHeader/RequestsHeader";
import {
	useRequestsModuleActiveTab,
	viewTypes
} from "./store/useRequestsModuleActiveTab";
import { browserHistory } from "react-router";
import get from "lodash/get";
import useGetRequestsStatistics from "./api/useGetRequestsStatistics";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { isEmpty } from "./utils/filters_utils";
import { formatFilters } from "./utils/helpers";
import { REQUESTS_FILTERS } from "./utils/constants";
import loadable from "loadable-components";

const TempInterviewsList = loadable(() =>
	import(
		/* webpackChunkName: "interviews" */ "./RequestsInterviewsList/TempInterviewsList"
	)
);
export const RequestsTab = loadable(() =>
	import(/* webpackChunkName: "requests" */ "./components/RequestsTab")
);
export const RequestsCandidatesList = loadable(() =>
	import(
		/* webpackChunkName: "candidates" */ "./RequestsCandidatesList/RequestsCandidatesList"
	)
);

export const ContractsTab = loadable(() =>
	import(/* webpackChunkName: "contracts" */ "./contracts")
);

export const getRequestsRoute = () => "/jobs/list";
export const getRequestsPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const Requests = () => {
	const { activeView, setActiveView } = useRequestsModuleActiveTab();
	const { getState } = useFilters();
	const { query, filters, logicalOperator } = getState(REQUESTS_FILTERS);
	const emptyFieldFilters = isEmpty(formatFilters(filters)?.[0])
		? true
		: !isEmpty(formatFilters(filters)?.[0]?.["field"]);

	const { data: overviewCount } = useGetRequestsStatistics(
		{
			op: logicalOperator,
			fields: formatFilters(filters),
			search: query
		},
		{
			enabled: emptyFieldFilters
		}
	);

	React.useEffect(() => {
		setActiveView(
			get(browserHistory.getCurrentLocation(), "query.view", viewTypes.requests)
		);
	}, []);

	const onViewChange = view => {
		setActiveView(view);
		browserHistory.replace(`${getRequestsRoute()}?view=${view}`);
	};

	return (
		<div className={styles.root}>
			<RequestsHeader
				overviewCount={overviewCount}
				activeView={activeView}
				onViewChange={onViewChange}
			/>
			<div className={styles.container}>
				{activeView === viewTypes.requests && <RequestsTab />}
				{activeView === viewTypes.interviews && <TempInterviewsList />}
				{activeView === viewTypes.candidates && <RequestsCandidatesList />}
				{activeView === viewTypes.contract && <ContractsTab />}
			</div>
		</div>
	);
};

export default Requests;
