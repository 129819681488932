import React from "react";
import styled from "styled-components";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import S3Uploader from "common/S3Uploader";
import { TYPE_PDF, TYPE_DOC, TYPE_DOCX, CV_SIZE_LIMIT } from "config";
import toaster from "common/Toaster";
import { colors } from "config/styles";

export const CvUploaderWrapper = styled.div`
	&.freelancer-cv {
		.droppable-holder {
			label {
				margin-bottom: 40px;
			}
		}
	}

	#cv_uploader {
		.droppable-holder {
			label {
				font-size: 14px;
				text-transform: uppercase;
				color: ${colors.anchorsColor};
				font-weight: 500;
			}
			.file-container {
				position: relative;
				cursor: pointer;
				overflow: hidden;
				height: 50px;
				border-radius: 1.5px;
				border: 1px dotted ${colors.inputColor};
				.fake_file {
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					cursor: pointer;
					padding: 8px 15px;
					&.disabled {
						cursor: not-allowed;
						.file-field {
							cursor: not-allowed;
						}
					}
					.fa-file-o {
						position: relative;
						float: left;
						font-size: 28px;
						color: ${colors.darkerLightGray};
						margin-right: 10px;
						top: 2px;
						span {
							font-size: 8px;
							position: absolute;
							left: 0;
							top: 13px;
							font-weight: 900;
							width: 100%;
							text-align: center;
							font-family: arial;
						}
					}
					.placeholder-btn {
						font-size: 14px;
						color: ${colors.darkerLightGray};
						float: left;
						max-width: 80%;
						overflow: hidden;
						margin: unset;
						line-height: 28px;
						position: relative;
						top: 2px;
					}
					.cancel {
						background-color: ${colors.white};
						display: flex;
						align-items: center;
						justify-content: center;
						.icon-reset1 {
							font-size: 25px;
							color: ${colors.lightGray2};
							&:hover {
								color: ${colors.clearSearchGray};
							}
						}
					}
					.fake-button {
						float: right;
						text-align: center;
						font-size: 12px;
						color: ${colors.white};
						border-radius: 3px;
						background-color: ${colors.darkerLightGray2};
						padding: 8px 15px;
						position: absolute;
						width: unset;
						left: unset;
						right: 8px;
						text-transform: unset;
						top: 8px;
						&.disabled {
							background-color: ${colors.errorColor};
							opacity: 0.65;
							cursor: not-allowed;
						}
					}
					input[type="file"] {
						position: absolute;
						top: 0;
						height: 100%;
						width: 100%;
						left: 0;
						cursor: pointer;
						opacity: 0;
						z-index: 2;
					}
				}
			}
		}
		.file-progress-box {
			margin-top: 11px;
			bottom: 12px;
			.file-progress-bar {
				height: 8px;
				border-radius: 4px;
				background-color: ${colors.bodyBg};
				overflow: hidden;
			}
			.size {
				float: right;
				font-size: 12px;
				font-weight: 500;
				text-align: left;
				color: ${colors.linksColor};
				margin-left: 15px;
				margin-top: -2px;
				line-height: 14px;
			}
			.file-sr-only {
				border-radius: 4px;
				height: 100%;
				width: 1%;
				background-color: ${colors.primaryColor};
			}
		}
	}
`;
class CvUploader extends React.Component {
	onUploadSuccess = file => {
		if (file) {
			this.props.changeReduxForm("cv_doc", file.url);
		}
	};
	resetFile = () => {
		this.onUploadSuccess(null);
	};
	renderUploader = ({ meta: { touched, error } }) => {
		const { cvFileUpload, fileName } = this.props;
		return (
			<>
				<S3Uploader
					accept={[TYPE_PDF, TYPE_DOC, TYPE_DOCX]}
					id="cv-upload"
					multiple={false}
					folder={this.props.folder || "candidate"}
					autoSubmit={true}
					userId={this.props.userId}
					companyId={this.props.companyId}
					onSuccess={cvFileUpload || this.onUploadSuccess}
					onClose={this.resetFile}
					disabled={this.props.disabled}
					resetOnSuccess={false}
					isLogin={true}
					fileName={fileName}
					onError={error =>
						toaster.danger(error, { id: "uploader-error", duration: 7 })
					}
					data={{
						chooseText: "Upload CV",
						label: "CV Document",
						placeholder: "Upload a file from your computer."
					}}
					icon={{ label: "CV", className: "fa fa-file-o" }}
					progressComponent={this.progressComponent}
					sizeLimit={CV_SIZE_LIMIT}
				/>
				<div className="help-block inline-error">{touched && error}</div>
			</>
		);
	};
	progressComponent({ percentCompleted }) {
		if (percentCompleted) {
			return (
				<div className="file-progress-box">
					<div className="size">{`${percentCompleted.toFixed(0)}%`}</div>
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	}
	render() {
		const { className } = this.props;
		return (
			<CvUploaderWrapper className={className}>
				<div id="cv_uploader">
					<Field name="cv_doc" component={this.renderUploader} />
				</div>
			</CvUploaderWrapper>
		);
	}
}

CvUploader.propTypes = {
	userId: PropTypes.string,
	companyId: PropTypes.string,
	folder: PropTypes.string,
	changeReduxForm: PropTypes.func,
	disabled: PropTypes.bool
};
export default CvUploader;
