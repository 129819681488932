import React, { useState, useEffect, useRef } from "react";
import * as moment from "moment";
import { usePrevious } from "hooks";
import differenceBy from "lodash/differenceBy";
import findIndex from "lodash/findIndex";
import Toaster from "common/Toaster";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { TimesheetTableDetails, Th, Tr, Td } from "./TimesheetStyled";
import { connect } from "react-redux";
import { getFileExtension } from "common/Functions";
import { client } from "lib/api-client";
import { REQUIRED_DOCUMENTS } from "config/api-endpoints";
import {
	DocIcon,
	DocxIcon,
	PdfIcon,
	JpgIcon,
	XlsIcon,
	XlsxIcon,
	PngIcon
} from "modules/bids/components/createbid/icons";
import { Flex } from "rebass";
import Tooltip from "common/Tippy";
import styled from "styled-components";
import { EmptyStateCell } from "modules/Requests/contracts/components/AvailabilityPillRenderer/AvailabilityPillRenderer";
import {
	ALL_FILES,
	REQUIRED_FILES,
	TIMESHEET_ATTACHMENTS,
	getTimesheetFilesTabs
} from "modules/timesheetsModule/utils/constants";

export const FILE_ICONS = size => ({
	pdf: <PdfIcon width={size} height={size} />,
	doc: <DocIcon width={size} height={size} />,
	docx: <DocxIcon width={size} height={size} />,
	jpg: <JpgIcon width={size} height={size} />,
	jpeg: <JpgIcon width={size} height={size} />,
	xls: <XlsIcon width={size} height={size} />,
	xlsx: <XlsxIcon width={size} height={size} />,
	png: <PngIcon width={size} height={size} />
});

const deleteFile = (timesheet_id, document_id) =>
	client(REQUIRED_DOCUMENTS, {
		body: {
			action: "delete",
			timesheet_id,
			document_id
		}
	});

function ListRendererFiles({
	list,
	isClient,
	timesheetId,
	onActionFinish,
	attachments_list,
	requiredFilesIsEmpty
}) {
	const requiredFilesEmpty = requiredFilesIsEmpty();
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const [selectedFile, setSelectedFile] = useState("");
	const [selectedFileId, setSelectedFileId] = useState("");
	const [highlighted, setHighlighted] = useState(-1);
	const [currentView, setView] = useState(
		!requiredFilesEmpty ? ALL_FILES : TIMESHEET_ATTACHMENTS
	);
	const filtredList =
		currentView === ALL_FILES
			? [...list, ...attachments_list]
			: currentView === REQUIRED_FILES
			? list
			: attachments_list;

	const prevList = usePrevious(filtredList);
	const ref = useRef();

	useEffect(() => {
		let highlightTimer;
		if (!prevList || !prevList.length) return;
		if (prevList.length < filtredList.length) {
			const diffItem = differenceBy(filtredList, prevList, item => item?._id);
			const index = findIndex(list, { _id: diffItem[0]?._id });
			setHighlighted(index);
			highlightTimer = setTimeout(() => {
				highlighted && setHighlighted(-1);
			}, 2000);
		}
		return () => highlightTimer && clearTimeout(highlightTimer);
	}, [filtredList]);

	const onCloseRemoveModal = () => {
		setSelectedFile("");
		setSelectedFileId("");
		setDisplayDeleteModal(false);
	};

	const handleDeleteFile = () => {
		deleteFile(timesheetId, selectedFileId)
			.then(onDeleteSuccess)
			.catch(() =>
				Toaster.danger(
					`Status: There was an error trying to delete your file item with the id: ${selectedFileId}`
				)
			)
			.finally(() => onCloseRemoveModal());
	};

	const onDeleteSuccess = () => {
		Toaster.success(`Status: your file item was deleted successfully`);
		onActionFinish();
	};

	const renderFileIcon = path => {
		const extension = getFileExtension(path);
		return FILE_ICONS(28)[extension];
	};

	const handleDownload = path => {
		if (path) window.open(path);
	};

	const props = {
		containerStyle: { padding: "0 15px" },
		thStyle: { backgroundColor: "#e5e8ec", color: "#6b7075" }
	};
	const tabs = getTimesheetFilesTabs(requiredFilesEmpty);

	return (
		<div
			className={!isClient ? "container" : ""}
			{...(isClient && { style: props.containerStyle })}
		>
			<Flex flexDirection={"column"}>
				<Flex
					sx={{
						backgroundColor: "#F1F5F9",
						width: !requiredFilesEmpty ? "700px" : "250px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						borderRadius: "8px",
						gap: "16px",
						marginTop: "12px"
					}}
					mb={2}
				>
					{tabs.map((view, index) => (
						<button
							key={index}
							style={{
								fontFamily: "Work sans",
								fontSize: "16px",
								fontWeight: 500,
								color: "#334155",
								backgroundColor: currentView === view ? "white" : "transparent",
								lineHeight: "24px",
								flex: 1,
								mx: "5px",
								borderRadius: "4px",
								padding: "10px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								boxShadow:
									currentView === view ? "0 0 5px rgba(0, 0, 0, 0.1)" : "none",
								cursor: "pointer",
								transition: "all 0.3s ease"
							}}
							onClick={() => setView(view)}
						>
							{view}
						</button>
					))}
				</Flex>
			</Flex>

			<TimesheetTableDetails spacing="0 3px" key="timesheet-list">
				<thead>
					<tr>
						{currentView !== TIMESHEET_ATTACHMENTS && (
							<Th width={"15%"} {...(isClient && { style: props.thStyle })}>
								Client request
							</Th>
						)}

						<Th width={"25%"} {...(isClient && { style: props.thStyle })}>
							Name of your document
						</Th>
						<Th width={"40%"} {...(isClient && { style: props.thStyle })}>
							Date uploaded
						</Th>
						<Th width={"10%"} {...(isClient && { style: props.thStyle })}>
							File
						</Th>
						<Th width={"10%"} {...(isClient && { style: props.thStyle })}>
							Actions
						</Th>
					</tr>
				</thead>
				<tbody>
					{filtredList.map((file, index) => {
						return (
							<Tr key={index} highlighted={highlighted === index}>
								{currentView !== TIMESHEET_ATTACHMENTS &&
									(file.field_name ? (
										<Td>{file.field_name}</Td>
									) : (
										<Td>
											<EmptyStateCell />
										</Td>
									))}

								<Td>
									<Tooltip
										theme="dark"
										content={file.name || file.file_name}
										overflow="hidden"
									>
										<FileName ref={ref}>{file.name || file.file_name}</FileName>
									</Tooltip>
								</Td>
								<Td>{moment.unix(file.created_at).format("DD/MM/YYYY")}</Td>
								<Td>{renderFileIcon(file.path)}</Td>
								<Td align="center">
									<button
										style={{ border: "none", background: "none" }}
										onClick={() => handleDownload(file.path)}
									>
										<span className="icon-download"></span>
									</button>
								</Td>
							</Tr>
						);
					})}
				</tbody>
			</TimesheetTableDetails>
			{displayDeleteModal && (
				<ConfirmationModal
					key="delete_department_modal"
					onClose={onCloseRemoveModal}
					active={true}
					modalName="delete_department_modal"
					type={ConfirmationTypes.error}
					title={`Are you sure you would like to delete the file with the name ${selectedFile}?`}
					firstButton={{
						action: handleDeleteFile,
						label: "YES, DELETE.",
						type: "warning"
					}}
					secondButton={{
						action: onCloseRemoveModal,
						label: "NO"
					}}
				/>
			)}
		</div>
	);
}

const FileName = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	flex-grow: 1;
	max-width: 200px; /* You can adjust this value as needed */
`;

const mapStateToProps = (state, props) => {
	const {
		timesheet: {
			data: { files, attachments }
		}
	} = state;
	const propsAttachments = Object.keys(props?.attachments_list || {}).map(
		fileId => props?.attachments_list[fileId]
	);

	return {
		list: Object.keys(files || {}).map(fileId => files[fileId]),
		attachments_list: [
			...Object.keys(attachments || {}).map(
				attachmentId => attachments[attachmentId]
			),
			...propsAttachments
		]
	};
};

export default connect(mapStateToProps, null)(ListRendererFiles);
