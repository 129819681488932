import React from "react";
import CustomDropDown from "../../CustomDropDown/CustomDropDown";
import { useFilters } from "../hooks/useFilter";
import { LOGICAL_OPERATORS } from "../utils";

const LogicalOperatorField = ({ disabled, className }) => {
	const { getState, setLogicalOperator, currentFilterId } = useFilters();
	const { logicalOperator } = getState(currentFilterId);

	const handleChange = value => {
		setLogicalOperator(value);
	};

	const options = [];

	for (let key in LOGICAL_OPERATORS) {
		options.push(LOGICAL_OPERATORS[key]);
	}

	return (
		<CustomDropDown
			className={className}
			options={options}
			onChange={handleChange}
			disabled={disabled}
			value={logicalOperator}
		/>
	);
};
export default LogicalOperatorField;
