import React, { useState, useLayoutEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import styles from "./bid-details-notes.module.scss";
import { BidDetailsSkeletonCard } from "../BidDetailsSkeletonCard";
import Avatar from "common/Avatar";
import _get from "lodash/get";
import { formatNote } from "config/helpers";
import { formatDate } from "common/Functions";
import Tooltip from "common/Tippy";
import { VisibilityIcon } from "modules/notes/AddNote/helpers";
import LexicalContentView from "common/LexicalContentView";

export default function BidDetailsNote({ note, isLoading, onClick, showText }) {
	const noteContentContainer = useRef();
	const [displaySeeMore, setDisplaySeeMore] = useState(false);
	const noteFormatted = formatNote(note?.description);
	const fullName = `${note.created_by.first_name} ${note.created_by.last_name}`;
	const visibility = _get(note, "visibility");

	useLayoutEffect(() => {
		if (noteContentContainer.current) {
			setDisplaySeeMore(
				noteContentContainer.current.offsetHeight <
					noteContentContainer.current.scrollHeight
			);
		}
	}, [noteFormatted]);

	return (
		<div className={styles.note}>
			<div className={styles.noteHeader}>
				<div className={styles.avatar}>
					{isLoading ? (
						<BidDetailsSkeletonCard
							minWidth={33.5}
							maxWidth={33.5}
							height={33.5}
						/>
					) : (
						<Avatar
							classNameOnly={true}
							className={styles.avatar}
							size={33.5}
							radius={3}
							src={_get(note, "created_by.avatar", "")}
							name={fullName}
						/>
					)}
				</div>
				<div className={styles.info}>
					<span className={styles.name}>{fullName}</span>
					<div className={styles.status}>
						{_get(note, "note_type") && (
							<>
								<span>{_get(note, "note_type")}</span>
								<span className={styles.dot}>•</span>
							</>
						)}
						<span>{formatDate(_get(note, "created_at"))}</span>
						<span className={styles.dot}>•</span>
						<Tooltip
							placement="top"
							content={<span>{VisibilityIcon[visibility]?.tooltip}</span>}
							theme="dark"
							className={styles.tooltip}
						>
							<span>{VisibilityIcon[visibility]?.icon}</span>
						</Tooltip>
					</div>
				</div>
			</div>
			<div className={styles.content}>
				{showText ? (
					<Scrollbars
						autoHide
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={420}
						autoHeightMin={0}
					>
						<LexicalContentView description={noteFormatted} />
					</Scrollbars>
				) : (
					<>
						<div className={styles.noteContent}>
							<LexicalContentView
								ref={noteContentContainer}
								description={noteFormatted}
							/>
						</div>
						{displaySeeMore && (
							<button
								className={styles.view__note__btn}
								onClick={() => onClick(note?._id)}
							>
								See more
							</button>
						)}
					</>
				)}
			</div>
		</div>
	);
}
