import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./tag.module.scss";

const Tag = ({ outline, text, icon }) => {
	return (
		<div className={cx(styles.tag, { [styles["outline"]]: outline })}>
			<p>{text}</p>
			{icon}
		</div>
	);
};
Tag.displayName = "Tag";
Tag.propTypes = {
	text: PropTypes.string.isRequired,
	outline: PropTypes.bool
};

Tag.defaultProps = {
	outline: false
};

export default Tag;
