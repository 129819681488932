/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Button } from "rebass";
import { Tag } from "./timesheet-tags.style";
import toaster from "common/Toaster";
import { ReactComponent as ArchiveTag } from "static/icons/archive-v2-tag-icon.svg";
import { ReactComponent as EditTag } from "static/icons/edit-tag-icon.svg";
import { ReactComponent as SaveIcon } from "static/icons/save-tag-icon.svg";
import { ReactComponent as RestoreTag } from "static/icons/restore-v2-tag-icon.svg";
import get from "lodash/get";

const TimesheetTag = ({
	index,
	tag,
	isArchived,
	onArchiveTags,
	onRestoreTags,
	onUpdateTags,
	setSelectedTags,
	selectedTags,
	allTags,
	setIsEdit,
	isEdit,
	mutationLoading
}) => {
	const [value, setValue] = useState(get(tag, "name", ""));

	useEffect(() => {
		setValue(get(tag, "name", ""));
	}, [tag]);

	const onEditTag = () => {
		if (value.trim().length === 0) {
			toaster.danger(`The tag name can not be empty.`);
			return;
		}
		if (get(tag, "name", "") === value) {
			return setIsEdit(false);
		}
		if (
			!allTags.some(
				tag =>
					get(tag, "label", get(tag, "name"))
						.toLowerCase()
						.localeCompare(value.toLowerCase()) === 0
			)
		) {
			onUpdateTags({
				_id: get(tag, "_id", ""),
				name: value
			});
			setIsEdit(false);
		} else {
			toaster.danger(
				`The tag ${value} already exists, please choose another tag name.`
			);
			setValue(get(tag, "name", ""));
		}
	};

	if (isArchived) {
		return (
			<Tag
				onClick={() =>
					setSelectedTags(selectedTags =>
						selectedTags.indexOf(get(tag, "_id", "")) < 0
							? [...selectedTags, get(tag, "_id", "")]
							: selectedTags.filter(tags => tags !== get(tag, "_id", ""))
					)
				}
				isSelected={selectedTags.some(id => id === get(tag, "_id", ""))}
				key={`${tag}-${index}`}
				tone="white"
				mr={"1rem"}
				mb={"1rem"}
				inactive={get(tag, "_id", "") === isEdit}
				className="tag-type-archived"
			>
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "12px",
						lineHeight: "22px",
						color: "#6E7191"
					}}
				>
					{get(tag, "name", "")}{" "}
				</Text>

				<Box ml={16}>
					{" "}
					<Button
						sx={{
							width: "34px",
							height: "34px",
							background: "#E6F0ED",
							borderRadius: "15px",
							marginLeft: "8px",
							padding: 0,
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}
						onClick={e => {
							e.stopPropagation();
							onRestoreTags([get(tag, "_id", "")]);
						}}
						disabled={mutationLoading}
					>
						<RestoreTag />
					</Button>
				</Box>
			</Tag>
		);
	}
	const isTagExist =
		get(tag, "name", "") !== value &&
		allTags.some(
			tag =>
				get(tag, "label", get(tag, "name"))
					.toLowerCase()
					.localeCompare(value.toLowerCase()) === 0
		);
	if (get(tag, "_id", "") === isEdit) {
		return (
			<Tag isTagExist={isTagExist} tone="white" mr={"1rem"} mb={"1rem"}>
				<form
					onSubmit={e => {
						e.stopPropagation();
						onEditTag();
					}}
				>
					<input
						value={value}
						onChange={({ target }) => {
							if (target.value.length <= 20) {
								setValue(target.value);
							}
						}}
					/>
					<Button
						sx={{
							border: "none",
							background: "transparent",
							padding: 0
						}}
						type="submit"
						disabled={mutationLoading}
					>
						<SaveIcon />
					</Button>
				</form>
			</Tag>
		);
	}

	return (
		<Tag
			onClick={() =>
				!isEdit &&
				setSelectedTags(selectedTags =>
					selectedTags.indexOf(get(tag, "_id", "")) < 0
						? [...selectedTags, get(tag, "_id", "")]
						: selectedTags.filter(tags => tags !== get(tag, "_id", ""))
				)
			}
			key={`${tag}-${index}`}
			isSelected={selectedTags.some(id => id === get(tag, "_id", ""))}
			tone="white"
			mr={"1rem"}
			mb={"1rem"}
			inactive={isEdit && get(tag, "_id", "") !== isEdit}
		>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "12px",
					lineHeight: "22px",
					color: "#6E7191"
				}}
			>
				{get(tag, "name", "")}{" "}
			</Text>

			<Flex alignItems="center" ml={16}>
				{" "}
				<Button
					sx={{
						width: "34px",
						height: "34px",
						background: "#EFF0F7",
						borderRadius: "15px",
						marginLeft: "8px",
						padding: 0,
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
					onClick={e => {
						e.stopPropagation();
						setValue(get(tag, "name", ""));
						setIsEdit(get(tag, "_id", ""));
					}}
					disabled={mutationLoading}
				>
					<EditTag />
				</Button>{" "}
				<Button
					sx={{
						width: "34px",
						height: "34px",
						background: "#FFEEEE",
						borderRadius: "15px",
						marginLeft: "8px",
						padding: 0,
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
					onClick={e => {
						onArchiveTags([get(tag, "_id", "")]);
						e.stopPropagation();
					}}
					disabled={mutationLoading}
				>
					<ArchiveTag />
				</Button>{" "}
			</Flex>
		</Tag>
	);
};

export default TimesheetTag;
