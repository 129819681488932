import produce from "immer";
import { useReducer } from "react";
import { HIREME_CHANNEL_ID } from "config";

const initialState = {
	selectedRows: [],
	isSelectAll: false
};

const TOGGLE_ADD_PAGE = "TOGGLE_ADD_PAGE";
const TOGGLE_REMOVE_PAGE = "TOGGLE_REMOVE_PAGE";
const TOGGLE_ADD_CHANNEL = "TOGGLE_ADD_CHANNEL";

const selectionReducer = produce((draft, action) => {
	switch (action.type) {
		case TOGGLE_ADD_PAGE: {
			draft.selectedRows = [
				...new Set([HIREME_CHANNEL_ID, ...draft.selectedRows, ...action.rows])
			];
			return;
		}
		case TOGGLE_REMOVE_PAGE: {
			const newSelectedRows = draft.selectedRows.filter(
				item => action.rows.indexOf(item) === -1
			);
			if (action.rows.length === draft.selectedRows.length) {
				draft.selectedRows = [...new Set([...newSelectedRows])];
				return;
			} else {
				draft.selectedRows = [
					...new Set([HIREME_CHANNEL_ID, ...newSelectedRows])
				];
				return;
			}
		}
		case TOGGLE_ADD_CHANNEL: {
			const channel_exist = draft.selectedRows.indexOf(action.row) !== -1;
			if (channel_exist) {
				if (action.row === HIREME_CHANNEL_ID) {
					if (draft.selectedRows.length === 1 && !action.isContractSelected) {
						draft.selectedRows = [];
						return;
					}
					return;
				}
				if (draft.selectedRows.length == 2) {
					draft.selectedRows = action.isContractSelected
						? [HIREME_CHANNEL_ID]
						: draft.selectedRows.filter(item => item !== action.row);
				}
				if (draft.selectedRows.length > 2) {
					draft.selectedRows = draft.selectedRows.filter(
						item => item !== action.row
					);
				}
			} else {
				draft.selectedRows = [
					...new Set([HIREME_CHANNEL_ID, ...draft.selectedRows, action.row])
				];
			}
			return;
		}
		default:
			return draft;
	}
});

export default function useSelectChannels(defaultProps = {}) {
	const [state, dispatch] = useReducer(selectionReducer, {
		...initialState,
		...defaultProps
	});

	if (
		defaultProps.limit === -1 &&
		typeof defaultProps.totalItemsCount !== "number"
	) {
		throw new Error(
			"You've set the selection limit to 'no limit' but forgot to specify the 'totalItemsCount'"
		);
	}

	const toggleAddPage = rows => {
		dispatch({ type: TOGGLE_ADD_PAGE, rows });
	};
	const toggleRemovePage = rows => {
		dispatch({ type: TOGGLE_REMOVE_PAGE, rows });
	};
	const toggleAddChannel = (row, isContractSelected = false) => {
		dispatch({ type: TOGGLE_ADD_CHANNEL, row, isContractSelected });
	};
	const isChannelSelected = id => {
		return state.selectedRows.includes(id);
	};
	return [
		state,
		{
			toggleAddPage,
			toggleRemovePage,
			toggleAddChannel,
			isChannelSelected
		}
	];
}
