import MessagingToolEmailsList from "common/MessagingToolEmailsList";
import React from "react";
import styles from "./messaging-tool-content.module.scss";
import MessagingToolEmailDetails from "modules/MessagingToolEmailDetails/MessagingToolEmailDetails";
import { withRouter } from "react-router";
import useMessagingToolUnreadCounter from "modules/MessagingTool/hooks/useMessagingToolUnreadCounter";

const MessagingToolContent = ({ params, hash }) => {
	const { emailId } = params;
	const { setUnreadCounter } = useMessagingToolUnreadCounter();
	return (
		<div className={styles.container}>
			{Boolean(emailId) ? (
				<MessagingToolEmailDetails emailDetailId={emailId} hash={hash} />
			) : (
				<MessagingToolEmailsList
					hash={hash}
					setUnreadCounter={setUnreadCounter}
				/>
			)}
		</div>
	);
};

export default withRouter(MessagingToolContent);
