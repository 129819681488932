import * as types from "./actionTypes";
import axiosInstance from "../../../config/axios";

import {
	HTTP_POST_METHOD,
	RESPONSE_ACCEPTED,
	HTTP_FORBIDDEN
} from "../../../config";
import {
	PROPOSE_BID,
	LIST_MEMBERS,
	ADJUST_BID
} from "../../../config/api-endpoints";
import AxiosHelper from "../../../config/axios-helper";
import toaster from "common/Toaster";

export function makeProposal(job_id, bid_id = null, can_adjust = false) {
	return {
		type: types.MAKE_PROPOSAL,
		job_id,
		bid_id,
		can_adjust
	};
}

export function closeProposal() {
	return { type: types.CLOSE_PROPOSAL };
}

function fetching() {
	return { type: types.SUBMIT_PROPOSAL };
}

function doneFetching(isProposing = false) {
	return { type: types.DONE_FETCHING, isProposing };
}

function setMembersList(list) {
	return { type: types.GET_MEMBERS, list };
}

export function switchFreelancer(freelancer) {
	return { type: types.SWITCH_FREELANCER, freelancer };
}

export function listMembers() {
	return AxiosHelper.__post({
		url: LIST_MEMBERS,
		next: setMembersList
	});
}

export function proposeBid(payload, can_adjust = false) {
	return dispatch => {
		dispatch(fetching());
		const url = can_adjust ? ADJUST_BID : PROPOSE_BID;
		return axiosInstance({
			url: url,
			method: HTTP_POST_METHOD,
			data: JSON.stringify(payload)
		})
			.then(response => {
				if (response && response.status === RESPONSE_ACCEPTED) {
					dispatch(doneFetching(true));
				}
			})
			.catch(error => {
				if (error.response?.status === HTTP_FORBIDDEN) {
					toaster.danger("You do not have enough permissions.");
				} else if (error.response?.name) toaster.danger(error.name);
				else if (error.files[0]) toaster.danger(error.files[0]);
				dispatch(doneFetching(true));
				throw error;
			});
	};
}
