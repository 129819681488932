import styled from "styled-components";
import { colors } from "config/styles";

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: ${props => (props.nomargin ? "0px" : "50px 0 100px 0")};
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 300px;
	height: ${props => props.height || "200px"};
`;

export const Text = styled.div`
	color: ${colors.inputColor};
	line-height: 1.3;
	height: 13px;
`;

export const Span = styled.span`
	fill: ${colors.inputColor};
	-webkit-text-fill-color: ${colors.inputColor};
	font-size: 47px;
`;

export const Divider = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: ${props => props.height || "200px"};
`;
export const Or = styled.div`
	color: ${colors.inputColor};
	font-size: 12.3px;
	text-transform: uppercase;
	height: 30px;
	background: ${colors.white};
	display: flex;
	align-items: center;
	font-weight: bold;
`;

export const Line = styled.div`
	width: 1.5px;
	height: 70px;
	background: ${colors.bodyBg};
`;
