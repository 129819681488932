import { useQuery } from "react-query";
import { GET_COUNTRIES } from "config/api-endpoints";
import { client } from "lib/api-client";

const getCountries = () => {
	return client(GET_COUNTRIES, {
		body: {
			all: true
		}
	});
};

export const GET_COUNTRIES_LIST_QUERY_KEY = "GET_COUNTRIES_LIST_QUERY_KEY";

export const useGetCountriesList = (options = {}) => {
	const res = useQuery(GET_COUNTRIES_LIST_QUERY_KEY, getCountries, {
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		...options
	});

	return res;
};
