import {
	convertCalendarDateToMS,
	dateToTimestamp,
	utcTimeStamp
} from "config/helpers";
import { DAILY } from "config";
import { isSupplierMode } from "modules/MissionsDetails/utils/_helper";

export const getSuffix = reportingSettings => {
	if (!reportingSettings) return;
	const {
		currency: { symbol },
		rate_type
	} = reportingSettings;
	const rate = rate_type === DAILY ? "day" : "h";
	return `${symbol}/${rate}`;
};

export const getDates = values => {
	if (!values) return;
	const startDate = values.start_date || values.new_mission_start_date;
	const endDate = values.end_date || values.new_mission_end_date;
	return { startDate, endDate };
};

export const mappingValues = (
	values,
	missionId,
	extensionSelected,
	missionDetails
) => {
	const endDate = convertCalendarDateToMS(values.end_date);
	const payload = {
		contract_id: missionId,
		extension_id: extensionSelected._id,
		end_date: utcTimeStamp({ date: endDate, isStart: false }),
		start_date: dateToTimestamp(
			`${values["start_date"].day}-${values["start_date"].month}-${values["start_date"].year}`
		),

		price: values.rate,
		description: values.description || "",
		// check if not impact others
		...(isSupplierMode(missionDetails?.invoice_details) && {
			selling_price: values.selling_price
		})
	};

	return payload;
};
