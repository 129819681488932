import React from "react";
import { Message } from "../styles/StyledInterview";
import { Scrollbars } from "react-custom-scrollbars";
import { createMarkup } from "config/helpers";
import { FloatedBtn, PreviewActions } from "../styles/StyledInterview";
import { OutlineButton } from "common/styled/buttons";
import { ReactComponent as InterviewLogo } from "static/icons/interview-propose.svg";
import { browserHistory } from "react-router";
import get from "lodash/get";
import { Flex } from "rebass";
import DefaultButton from "common/Buttons";

const PreviewStep = ({
	company_name,
	selectedVacancy,
	additionalComment,
	user_name,
	handleBackStep,
	handleSubmitModal,
	interviewSkipped,
	candidate_first_name
}) => {
	return (
		<Message>
			<p className="title">Message</p>
			<div className="message-box">
				Dear {candidate_first_name}, <br /> <br />I have come across your
				profile and think you can be a good match for the role of{" "}
				<strong>
					{get(selectedVacancy, "label")
						? get(selectedVacancy, "label")
						: "Please select a vacancy"}
				</strong>
				<br />
				<br />
				Please review the job description and confirm/decline your interest,
				using the link below.
				<br />
				<br />
				{get(selectedVacancy, "_id") && (
					<Flex alignItems="center" justifyContent="center">
						<DefaultButton
							onPress={() =>
								browserHistory.push("/vacancy/" + get(selectedVacancy, "_id"))
							}
							backgroundColor="white"
						>
							View vacancy
						</DefaultButton>
					</Flex>
				)}
				<br />
				<br />
				{!interviewSkipped && (
					<div className="interview_accepted">
						<div className="icon_container">
							<InterviewLogo className="interview_icon" />
						</div>
						<p>
							An interview proposition is attached to this poke, you’ll be able
							to accept or propose another time/location for the interview once
							you have confirmed your interest for the vacancy.
						</p>
					</div>
				)}
				{additionalComment && (
					<div className="additional-comment">
						<div className="title">additional comments</div>
						<Scrollbars
							autoHide
							autoHideTimeout={5000}
							autoHideDuration={200}
							autoHeight
							autoHeightMax={120}
						>
							<div dangerouslySetInnerHTML={createMarkup(additionalComment)} />
						</Scrollbars>
					</div>
				)}
				Kind regards,
				<br />
				<br />
				{user_name}
				<br />
				<br />
				{company_name}
			</div>
			<PreviewActions>
				<FloatedBtn type="primary" bordered onClick={handleSubmitModal}>
					Poke
				</FloatedBtn>
				<OutlineButton onClick={() => handleBackStep(false)}>
					Back
				</OutlineButton>
			</PreviewActions>
		</Message>
	);
};
export default PreviewStep;
