import draftToHtml from "draftjs-to-html";
import { mdToDraftjs } from "draftjs-md-converter";
import { FILTER_SETTINGS_ENDPOINT, GET_COUNTRIES } from "config/api-endpoints";
import _get from "lodash/get";
import { client } from "lib/api-client";
import moment from "moment";
import {
	EDUCATION_LEVELS,
	HYBRID_OPTION,
	VACANCY_LEVEL,
	CONTINENTS,
	ANYWHERE
} from "config";
import {
	isHTML,
	formatDateToCalendarObject,
	generateId,
	addressObjectToString
} from "config/helpers";
import { operators } from "./constants";
import { v4 as uuid } from "uuid";

export function schemValidator(languagesSchema) {
	let stack = [...languagesSchema];

	for (let index = 0; index < languagesSchema.length; index++) {
		const element = languagesSchema[index];
		if (
			element?.language &&
			["and", "or"].includes(languagesSchema[index + 3]?.value) &&
			languagesSchema[index + 1]?.value !== languagesSchema[index + 3]?.value
		) {
			// insert "("
			stack.splice(index, 0, {
				id: generateId(),
				...operators[0] /* ( */
			});

			// insert ")"
			stack.splice(index + 4, 0, {
				id: generateId(),
				...operators[1] /* ) */
			});
		}
	}

	return stack;
}
export function insertOperators(languagesSchema) {
	let newList = [...languagesSchema];
	if (languagesSchema.length === 2) {
		if (languagesSchema[0]?.language && languagesSchema[1]?.language)
			newList.splice(1, 0, {
				id: generateId(),
				...operators[2] /* AND */
			});
		return newList;
	}

	for (let index = 0; index < languagesSchema.length; index++) {
		const element = languagesSchema[index];
		const itemPlusOne = languagesSchema[index + 1];
		const itemPlusThree = languagesSchema[index + 3];
		if (
			(element?.language && itemPlusOne?.language) ||
			(element?.language &&
				["and", "or"].includes(itemPlusThree?.value) &&
				["and", "or"].includes(itemPlusOne?.value) &&
				itemPlusOne?.value !== itemPlusThree?.value)
		) {
			// insert "("
			newList.splice(index, 0, {
				id: generateId(),
				...operators[0] /* ( */
			});
			// insert "AND"
			if (newList[index + 2]?.value !== "and")
				newList.splice(index + 2, 0, {
					id: generateId(),
					...operators[2] /* AND */
				});
			// insert ")"
			newList.splice(index + 4, 0, {
				id: generateId(),
				...operators[1] /* ) */
			});
		}
	}

	return newList;
}

const languageSelectedLength = languagesSchema => {
	for (let i = 0; i < languagesSchema.length; i++) {
		if (languagesSchema[i].language) return true;
	}
	return false;
};

export function checkIfSchemaIsCorrect(languagesSchema) {
	let error = null;

	if (!languagesSchema.length || !languageSelectedLength(languagesSchema))
		error = "Query languages can not be empty";
	let stack = [];
	let operationsStack = [];
	let unmatchedParentheses = [];

	const parentheses = [")", "("];
	languagesSchema.forEach((element, index) => {
		// Check parentheses
		if (element.value === "(") {
			stack.push(index);
		} else if (element.value === ")") {
			if (!stack.length) unmatchedParentheses.push(index);
			stack.pop();
		}

		// Check operators
		if (["and", "or"].includes(element.value)) {
			if (
				(languagesSchema[index - 1]?.value !== ")" &&
					!languagesSchema[index - 1]?.language) ||
				(languagesSchema[index + 1]?.value !== "(" &&
					!languagesSchema[index + 1]?.language)
			) {
				operationsStack.push(index);
			}
		}

		if (parentheses.includes(element.value)) {
			if (element.value === ")" && languagesSchema[index + 1]?.value === "(") {
				error = "Between two groups must be an AND or OR";
				stack.push(...[index, index + 1]);
			}
		}

		if (element.language && languagesSchema[index + 1]?.language) {
			stack.push(index);
			error = `Insert AND, OR between two languages`;
		}

		if (
			element.language &&
			(languagesSchema[index - 1]?.value === ")" ||
				languagesSchema[index + 1]?.value === "(")
		) {
			stack.push(index);
			error = `Insert AND, OR between language and ( , )`;
		}
	});

	unmatchedParentheses = unmatchedParentheses.concat([
		...stack,
		...operationsStack
	]);

	return {
		correct: unmatchedParentheses.length === 0,
		unmatchedParentheses,
		error
	};
}

function formatLanguage(language) {
	return {
		field: "languages",
		op: "is",
		value: [language]
	};
}

export function parseLanguageSchema(languagesSchema) {
	let index = 0;
	let lastOperator = ""; // if there is a sequence of the same operator just push them to the same array.

	function parseGroup() {
		const item = languagesSchema[index];
		index++; // skip if item is "("
		if (item?.value === "(") {
			const group = parseTerm();
			index++; // skip ")"
			return group;
		} else {
			return item;
		}
	}

	function parseTerm() {
		let left = parseGroup();
		while (
			index < languagesSchema.length &&
			(languagesSchema[index].value === "or" ||
				languagesSchema[index].value === "and")
		) {
			const operator = languagesSchema[index].value;
			index++; // skip operator
			let right = parseGroup();
			if (lastOperator === operator && left[operator]) {
				left[operator].push(formatLanguage(right));
			} else {
				if (left.name) {
					left = formatLanguage(left);
				}
				if (right.name) {
					right = formatLanguage(right);
				}
				left = {
					[operator]: [left, right]
				};
			}
			lastOperator = operator;
		}
		if (index) {
			if (left.name) left = { ["and"]: [formatLanguage(left)] };
			return left;
		}
	}

	return parseTerm();
}

export const formatValues = values => {
	return values.map(item => {
		const fieldType = item.field;
		return {
			title: item.title,
			description: item.description,
			field: item.field,
			options: fieldType === "multiple_choice" ? item.options : undefined,
			one_answer_allowed:
				fieldType === "multiple_choice"
					? Boolean(item.one_answer_allowed)
					: undefined,
			is_required: item.is_required
		};
	});
};

export const isConditionSpecificToVacancy = (conditions, id) => {
	const condition = conditions.find(condition => condition._id === id);
	return !!condition;
};

export const formatCategoriesList = list => {
	const valueField = "_id";
	const options = [];

	list.forEach(opt => {
		if (opt.group_name !== "") {
			const groupIdx = options.findIndex(
				grp => grp.label === opt.group_name && !opt.hidden
			);

			if (groupIdx === -1) {
				options.push({
					label: opt.group_name,
					value: opt.group_name,
					id: opt._id,
					children: [
						{
							label: opt.name,
							children: opt.children,
							value: opt[valueField]
						}
					]
				});
			} else {
				const child = {
					id: opt._id,
					label: opt.name,
					value: opt[valueField],
					children: opt.children
				};

				_get(options[groupIdx], "children", []).push(child);
			}
		} else {
			options.push({
				label: opt.name,
				value: opt[valueField],
				id: opt._id,
				subs: opt.children
			});
		}
	});

	return options;
};

export const getExtraBenefitsList = () => {
	return new Promise((resolve, reject) => {
		client(FILTER_SETTINGS_ENDPOINT, {
			body: {
				tag: "list_extra_benefits"
			}
		})
			.then(res => {
				const formattedData = formatExtraBenefitsList(
					res.extra_benefits,
					"Extra benefits"
				);
				resolve(formattedData);
			})
			.catch(e => reject(e));
	});
};

function formatExtraBenefitsList(data, label) {
	const dataFormatted = data.map(item => {
		const { label, value } = item;

		return {
			label,
			id: value
		};
	});

	const parent = [{ id: 1, label, children: dataFormatted }];

	return parent;
}

export const buildEditVacancyInformationsPayload = (values, vacancyId) => {
	const {
		//description data
		description,
		//applicant data
		applicantEducationLevel,
		duration,
		numberOfPositions,
		durationType,
		//salary data
		applicantLocation,
		payrollCountry,
		salaryMin,
		salaryMax,
		currency,
		paymentTime,
		type,
		extraBenefits,
		otherBenefits,
		//additionInfo data
		featuredVideo,
		employmentType,
		dueDate,
		vacancyAddress,
		featuredImage,
		remoteEligibility,
		daysPerWeek,
		vacancyAddressType,
		numberColleagues,
		openingReason
	} = values;

	let versions = {};

	description.forEach(
		({
			generatedId,
			primary,
			jobTitle,
			jobDescription,
			profileDescription,
			languageObject: { code, language }
		}) => {
			versions[code] = {
				original: primary,
				title: jobTitle,
				description: jobDescription,
				language: language,
				version: code,
				_id: generatedId,
				profile_description: profileDescription
			};
		}
	);

	const body = {
		// description payload
		id: vacancyId,
		step: 2,
		job_type: "vacancy",
		versions,
		//applicants payload
		//TODO fix this
		expected_duration: Number(duration) === 0 ? 1 : Number(duration),
		education_level: applicantEducationLevel.value,
		positions_number: Number(numberOfPositions),
		expected_duration_period: durationType,
		//We should get object from applicantLocation field, we are getting only code
		applicants_location: formatApplicantLocations(
			applicantLocation[0].children
		),
		//salary payload
		payroll_country: payrollCountry.label,
		currency: currency.label,
		payment_time: paymentTime,
		payment_type: type,
		extra_benefits: extraBenefits[0]
			? extraBenefits[0].children.map(({ id }) => id)
			: [],
		other_benefits: otherBenefits,
		//additionalInfo payload
		featured_video: featuredVideo,
		due_date: dateToTimestamp(
			`${dueDate.day}-${dueDate.month}-${dueDate.year}`
		),
		employment_type: employmentType,
		featured_image: featuredImage,
		remote_eligibility: remoteEligibility,
		office_days:
			remoteEligibility === HYBRID_OPTION.value ? daysPerWeek : undefined,
		location_type: vacancyAddressType,
		number_of_colleagues: numberColleagues ? numberColleagues : undefined,
		opening_reason: openingReason.label,
		...vacancyAddress.value
	};

	let bodyUpdated = body;

	if (salaryMin) {
		bodyUpdated = {
			...bodyUpdated,
			salary_range_start: salaryMin,
			salary_range_end: salaryMax
		};
	}

	return bodyUpdated;
};

const formatApplicantLocations = list => {
	return list.map(({ id, label, type }) => {
		return {
			type,
			code: id,
			name: label
		};
	});
};

const dateToTimestamp = d => {
	return moment(d, "DD-MM-YYYY").unix();
};

export const groupSkillsByParent = skills => {
	const map = new Map();
	skills?.forEach(item => {
		if (map.has(item.parent_sector.name)) {
			const list = map.get(item.parent_sector.name);
			map.set(item.parent_sector.name, [...list, item]);
		} else {
			map.set(item.parent_sector.name, [item]);
		}
	});

	return map;
};

export const initializeVacancyForm = (vacancy, reset, data) => {
	const {
		versions: descriptionVersions,
		positions_number,
		education_level,
		applicants_location,
		other_benefits,
		salary_range_start,
		salary_range_end,
		payment_time,
		payment_type,
		extra_benefits,
		payroll_country,
		currency,
		employment_type,
		expected_duration,
		number_of_colleagues,
		//Additional informations fields
		due_date,
		featured_video,
		featured_image,
		location_type,
		remote_eligibility,
		opening_reason
	} = vacancy;

	const { countriesList } = data;

	const countryObject = countriesList.find(
		({ country }) => country === payroll_country
	);

	const locationsList = applicants_location.map(({ code, name, type }) => ({
		id: code,
		//In the case of anywhere we dont get label, that's we  treat this case
		label: name || ANYWHERE.label,
		type
	}));

	const vacancyAddress = formatVacancyAddress(getVacancyAddress(vacancy));

	reset({
		//description card fields
		description: formatDescriptions(descriptionVersions),
		//applicant card fields
		numberOfPositions: positions_number,
		applicantEducationLevel: EDUCATION_LEVELS.find(
			({ value }) => value === education_level
		),
		applicantLocation: [{ id: 1, label: "Locations", children: locationsList }],
		duration: expected_duration?.duration,
		durationType: expected_duration?.period,
		//salary card fields
		payrollCountry: {
			label: countryObject?.country,
			value: countryObject?.code
		},
		currency: {
			label: currency || "",
			value: currency || ""
		},
		salaryMin: salary_range_start,
		salaryMax: salary_range_end,
		paymentTime: payment_time,
		type: payment_type,
		extraBenefits: formatExtraBenefits(extra_benefits),
		otherBenefits: other_benefits,
		numberColleagues: number_of_colleagues,
		//additional info card fields
		dueDate: due_date ? formatDateToCalendarObject(due_date) : null,
		featuredVideo: featured_video,
		employmentType: employment_type,
		featuredImage: featured_image,
		remoteEligibility: remote_eligibility?.type,
		daysPerWeek: remote_eligibility?.office_days,
		vacancyAddress: {
			value: vacancyAddress,
			label: addressObjectToString(vacancyAddress)
		},
		vacancyAddressType: location_type,
		openingReason: opening_reason
			? {
					_id: uuid(),
					label: opening_reason,
					value: opening_reason
			  }
			: null
	});
};

const formatDescriptions = descriptions => {
	const descriptionArray = [];

	for (const key in descriptions) {
		//add profile description
		const {
			title,
			description,
			original,
			language,
			version,
			profile_description,
			_id
		} = descriptions[key];
		let descriptionInHTML;

		if (isHTML(description)) {
			descriptionInHTML = description;
		} else {
			const rawData = mdToDraftjs(description);
			descriptionInHTML = draftToHtml(rawData);
		}

		const item = {
			generatedId: _id,
			jobTitle: title,
			jobDescription: descriptionInHTML,
			profileDescription: profile_description,
			primary: original,
			languageObject: {
				code: version,
				language
			},
			isDescriptionGenerated: true
		};

		descriptionArray.push(item);
	}

	return descriptionArray;
};

const formatVacancyAddress = ({
	street,
	number,
	box,
	city,
	zip,
	country,
	latitude,
	iso_country,
	company_street,
	company_number,
	company_box,
	company_city,
	company_zip,
	company_country,
	company_longitude,
	company_latitude,
	company_iso_country,
	longitude
}) => {
	return {
		street: street || company_street,
		number: number || company_number,
		box: box || company_box,
		city: city || company_city,
		zip: zip || company_zip,
		country: country || company_country,
		longitude: longitude || company_longitude,
		latitude: latitude || company_latitude,
		iso_country: iso_country || company_iso_country
	};
};

const getVacancyAddress = vacancy => {
	const {
		street,
		number,
		box,
		city,
		zip,
		country,
		latitude,
		iso_country,
		company_street,
		company_number,
		company_box,
		company_city,
		company_zip,
		company_country,
		company_longitude,
		company_iso_country,
		longitude,
		company_latitude
	} = vacancy;

	return {
		street,
		number,
		box,
		city,
		zip,
		country,
		latitude,
		iso_country,
		company_street,
		company_number,
		company_box,
		company_city,
		company_zip,
		company_country,
		company_longitude,
		company_iso_country,
		longitude,
		company_latitude
	};
};

const formatExtraBenefits = extra_benefits => {
	return [
		{
			id: 1,
			label: "Extra benefits",
			children: (extra_benefits || []).map(({ label, value }) => {
				return {
					id: value,
					label
				};
			})
		}
	];
};

export const initializeProfileForm = (
	reset,
	{ group, site, skills, functions, query_languages, priority }
) => {
	reset({
		department: {
			label: group.name,
			value: group._id
		},
		site: {
			label: site.name,
			value: site._id
		},
		query_languages: reverseSmartQueryParser(query_languages),
		skills: formatSkills(skills),
		categories: formatFunctions(functions),
		priority: priority
			? {
					_id: uuid(),
					label: priority,
					value: priority
			  }
			: null
	});
};

const formatSkills = skills => {
	return skills.map(({ must_have, parent_sector, name, _id, score }) => {
		return {
			_id,
			name,
			parent_sector,
			proficiency: score,
			mustHave: must_have,
			categoryId: parent_sector._id
		};
	});
};

const formatFunctions = functions => {
	return functions.map(({ _id, name, seniority, is_main, sector }) => {
		const seniorityObject = VACANCY_LEVEL.find(
			({ value }) => value === seniority
		);

		return {
			id: generateId(),
			isMain: is_main,
			category: {
				label: sector.parent_sector.name,
				value: sector.parent_sector._id,
				children: []
			},
			subCategory: {
				label: sector.name,
				value: sector._id
			},
			_function: {
				label: name,
				value: _id
			},
			seniority: {
				label: seniorityObject.label,
				value: seniorityObject.value
			}
		};
	});
};

export const getLocations = () => {
	return new Promise((resolve, reject) => {
		client(GET_COUNTRIES, {
			body: {
				all: true
			}
		})
			.then(res => {
				const countries = res.map(({ code, country }) => ({
					id: code,
					label: country,
					type: "country"
				}));

				const continentsFormatted = CONTINENTS.map(({ label, value }) => ({
					id: value.code,
					label,
					type: "continent"
				}));

				const result = [
					{
						id: 1,
						label: "Locations",
						children: [...continentsFormatted, ...countries]
					}
				];

				resolve(result);
			})
			.catch(e => reject(e));
	});
};

export function reverseSmartQueryParser(query) {
	const result = [];
	function processCondition(condition) {
		if (condition?.and || condition?.or) {
			result.push({
				id: generateId(),
				value: "(",
				label: "(",
				isParentheses: true
			});
			const subConditions = condition.and || condition.or;
			subConditions.forEach((subCondition, index) => {
				processCondition(subCondition);
				if (index < subConditions.length - 1) {
					result.push({
						id: generateId(),
						value: condition.and ? "and" : "or",
						label: condition.and ? "AND" : "OR",
						isOperator: true
					});
				}
			});
			result.push({
				id: generateId(),
				value: ")",
				label: ")",
				isParentheses: true
			});
		} else {
			result.push({
				id: generateId(),
				_id: condition?.value?.[0]?._id,
				language: condition?.value?.[0]?.name,
				proficiency: condition?.value?.[0]?.score
			});
		}
	}
	processCondition(query);
	result.pop();
	result.shift();
	return result;
}

export const isLanguageProtected = (id, vacancy) => {
	const version = Object.keys(vacancy.versions).find(
		key => vacancy.versions[key]._id === id
	);
	return _get(vacancy, `versions[${version}].published`, false);
};
