import React from "react";
import styles from "./form-builder-action-toggle-required.module.scss";
import { ReactComponent as AsteriskIcon } from "static/icons/asterisk.svg";
import Tooltip from "common/Tippy";
import cx from "classnames";

export default function FormBuilderActionToggleRequired({ value, onClick }) {
	return (
		<Tooltip content="Mark as required" theme="dark">
			<button
				onClick={onClick}
				type="button"
				className={cx(styles.actionButton, styles.asterisk, {
					[styles.required]: value
				})}
			>
				<AsteriskIcon />
			</button>
		</Tooltip>
	);
}
