import React from "react";
import CurrencyInput from "common/NewCurrencyField/NewCurrencyField";

const FreelancersCurrencyInput = ({ onChange, value }) => {
	return (
		<CurrencyInput
			input={{
				onChange: value => onChange(value, "change"),
				value
			}}
			useGreenTheme
		/>
	);
};

export default FreelancersCurrencyInput;
