import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import { Button } from "rebass";
import { usePaginatedQuery, useQuery } from "react-query";
import { browserHistory } from "react-router";
import { client } from "lib/api-client";
import {
	LIST_APPLICATIONS_ENDPOINT,
	FILTER_SETTINGS_ENDPOINT
} from "config/api-endpoints";
import Filters from "./Filters";
import { ViewContainer } from "./Styled";
import { formatDate } from "common/Functions";
import Pagination from "common/PaginationClient";
import Status from "common/Table/Status";
import Table from "common/Table";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { INACTIVE } from "config";
import { historyPush } from "config/helpers";
import BackButton from "common/Buttons/BackButton";
import { searchParser } from "config/helpers";

const getApplications = (_key, { sort, filters, search }) => {
	return client(LIST_APPLICATIONS_ENDPOINT, {
		body: {
			tag: "application",
			sort,
			search,
			...filters
		}
	});
};

const getSettings = () => {
	return client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: "filter_application_list_tag"
		}
	});
};

const renderProfileName = data => (
	<>
		{_get(data, "value.first_name")} {_get(data, "value.last_name")}
	</>
);

const renderStatus = data => (
	<>
		<Status status={_get(data, "value")} />
		{_get(data, "value")}
	</>
);

const renderSource = data => (
	<>
		{_get(data, "value") === "hireme database"
			? "wiggli database"
			: _get(data, "value")}
	</>
);
const getTableColumns = settings => [
	{
		Header: `Applicant's name`,
		accessor: "profile",
		hasFilter: true,
		filter: "profile_id",
		filterOptions: formatSettings(_get(settings, "poked_profile", [])),
		Cell: renderProfileName
	},
	{
		Header: "Department",
		accessor: "group.name",
		hasFilter: true,
		filter: "group_id",
		filterOptions: formatSettings(_get(settings, "list_groups", [])),
		width: 120
	},
	{
		Header: "Vacancy",
		accessor: "job.title",
		hasFilter: true,
		filter: "vacancy_id",
		filterOptions: formatSettings(_get(settings, "jobs_title", [])),
		width: 240
	},
	{
		Header: "Date",
		accessor: "created_at",
		hasFilter: true,
		filter: "dates",
		filterOptions: formatSettings(_get(settings, "dates", []), true),
		Cell: props => formatDate(_get(props, "value")),
		disabledFilter: true
	},
	{
		Header: "Source",
		accessor: "source_profile",
		hasFilter: true,
		filter: "source",
		filterOptions: formatSettings(_get(settings, "source", [])),
		Cell: renderSource
	},
	{
		Header: "Status",
		accessor: "visible_status",
		hasFilter: true,
		Cell: renderStatus,
		filter: "status",
		filterOptions: formatSettings(_get(settings, "status", []))
	}
];

const formatSettings = (settings, isDate) => {
	return settings.map(setting => {
		if (typeof setting === "string") {
			return {
				label: setting,
				value: setting
			};
		}
		return {
			label: isDate
				? formatDate(_get(setting, "name"))
				: _get(
						setting,
						"title",
						_get(setting, "name", _get(setting, "full_name"))
				  ),
			value: _get(setting, "_id")
		};
	});
};

const ApplicationsList = () => {
	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState({});
	const [sort, setSort] = useState("desc");

	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Permanent" },
			{ name: "Applications", path: "/applications" }
		]);
	}, []);

	const { resolvedData, isFetching } = usePaginatedQuery(
		["loadApplications", { search, sort, filters }],
		getApplications
	);

	const settingsData = useQuery(["loadSettings"], getSettings);
	const searchQueries = searchParser();
	const handlePageClick = value => {
		setFilters({ ...filters, offset: _get(resolvedData, "size") * value });
	};

	const handleRowClick = row => {
		let isActive = _get(row, "original.profile.candidate_status") !== INACTIVE;
		if (isActive) {
			historyPush(
				`/vacancies/client/poke/${row.original._id}?source=list&list=applications&tab=1`
			);
		} else {
			historyPush(`/permanent/view/${row.original.profile._id}`);
		}
	};

	const handleProfilClick = row => {
		browserHistory.push(`/permanent/view/${row.original.profile._id}`);
	};

	const shouldDisableRow = row => {
		let isActive = _get(row, "original.profile.candidate_status") !== INACTIVE;
		return !isActive;
	};

	const inActiveMessageTooltip = row => (
		<>
			Since your contact, the candidate has changed his/her status to inactive
			as (s)he is no longer open for opportunities.
			<br />
			<Button
				sx={{
					background: "none",
					color: "#333",
					padding: 0,
					textDecoration: "underline",
					outline: "none"
				}}
				onClick={() => handleProfilClick(row)}
			>
				Click here
			</Button>{" "}
			to view his/her profile
		</>
	);
	return (
		<ViewContainer>
			{Boolean(_get(searchQueries, "from", "") === "home") && (
				<BackButton className="backButton" />
			)}
			<Filters
				submitSearch={value => {
					setSearch(value);
				}}
				setSort={setSort}
				sort={sort}
			/>
			<Table
				filters={filters}
				setFilters={setFilters}
				columns={getTableColumns(_get(settingsData, "data", {}))}
				data={_get(resolvedData, "data")}
				isLoading={isFetching}
				emptyTitle={"No application"}
				emptyText={"You haven’t received any applications yet."}
				onRowClick={handleRowClick}
				shouldDisableRow={shouldDisableRow}
				disabledRowOverlay={inActiveMessageTooltip}
			/>
			<Pagination
				total={_get(resolvedData, "total")}
				size={_get(resolvedData, "size")}
				offset={_get(resolvedData, "offset")}
				handlePageClick={handlePageClick}
			/>
		</ViewContainer>
	);
};

export const getApplicationsListRoute = () => `/applications`;

export const getApplicationsListPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default ApplicationsList;
