import React, { useEffect, useMemo } from "react";
import cx from "classnames";
import { Helmet } from "react-helmet";
import { get } from "lodash";
import { Permanent, Tasks, Temporary } from "./components";
import useHomeStore from "./zustand/HomeStore";
import style from "./home.module.scss";
import JobsOverview from "modules/home/containers/jobsOverview/index.js";
import Interview from "modules/home/containers/interview";
import VacancyRatio from "modules/home/containers/vacancyRatio/index.jsx";
import Weather from "modules/home/containers/weather/Weather";
import { VACANCY, REQUEST } from "config";
import Suppliers from "modules/home/containers/suppliers/Suppliers";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import useListCount from "modules/home/api/useListCount.js";
import { TOUR_INTERFACE_NAVIGATION } from "config/onboarding";
import { STEPS_TOUR_INTERFACE_NAVIGATION } from "modules/user-onboarding/workflows/interface-navigation";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useTour } from "@reactour/tour";

export const getHomeRoute = () => {
	return "/home";
};

export const getHomePermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const useTourData = () => {
	const { workflowId } = useOnboardingStore();
	const { setSteps, setIsOpen, setCurrentStep } = useTour();

	const isActiveNavigationTour = useMemo(
		() => workflowId === TOUR_INTERFACE_NAVIGATION,
		[workflowId]
	);

	useEffect(() => {
		if (isActiveNavigationTour) {
			setSteps(STEPS_TOUR_INTERFACE_NAVIGATION); // init steps
			setCurrentStep(0);
			setIsOpen(true);
		}
	}, [isActiveNavigationTour]);
};

function Home({ route }) {
	const permanent = "permanent";
	const temporary = "temporary";
	const [homeType, setHomeType] = useHomeStore(state => [
		state.homeType,
		state.switchTab
	]);
	const { data: listCount } = useListCount();
	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([{ name: "Home" }]);
	}, []);

	useTourData(); // init tour
	return (
		<>
			<Helmet>
				<title>{route.title} </title>
			</Helmet>
			<div className={cx(style.wrapper, style.customTab)}>
				<div className={style.weather}>
					<Weather />
				</div>
				<div className={style.tasks}>
					<Tasks />
				</div>
				<div className={style.interviews}>
					<Interview />{" "}
				</div>

				<ul className={style.tabs}>
					<li
						onClick={() => setHomeType(permanent)}
						className={cx({
							[style.active]: homeType === permanent
						})}
					>
						Permanent
					</li>
					<li
						onClick={() => setHomeType(temporary)}
						className={cx({
							[style.active]: homeType === temporary
						})}
					>
						Temporary
					</li>
				</ul>
				<div className={style.contentTab}>
					{homeType === "permanent" ? (
						<div className={cx(style.permanent)}>
							<Permanent
								listCount={get(listCount, "list_count_welcome_page", {})}
							/>
						</div>
					) : (
						<div className="temporary">
							<Temporary
								listCount={get(listCount, "list_count_welcome_page", {})}
							/>
						</div>
					)}
				</div>
				<div className={style.middleBox}>
					{homeType === "permanent" ? (
						<JobsOverview type={VACANCY} />
					) : (
						<JobsOverview type={REQUEST} />
					)}
				</div>
				<div className={cx(style.middleBox, style.last)}>
					{homeType === "permanent" ? (
						<div className="permanent">
							<VacancyRatio />
						</div>
					) : (
						<div className="temporary">
							<Suppliers
								listCount={get(listCount, "list_count_welcome_page", {})}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default Home;
