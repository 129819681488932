import React, { useRef } from "react";
import style from "./request-details-popup.module.scss";
import { ReactComponent as Close } from "static/icons/closeInfoVac.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import {
	CONTRACT_TYPE,
	FULL_TIME_LABEL,
	HYBRID_OPTION,
	PART_TIME_LABEL,
	REMOTE_ELIGIBITY_OPTIONS
} from "config";
import { formatDate } from "common/Functions";

const RequestDetailsPopup = ({
	setVacancyInfoModal,
	job,
	style: styleObject
}) => {
	const containerRef = useRef(null);
	useOnClickOutside(containerRef, () => {
		setVacancyInfoModal(false);
	});

	const getRemoteEligbility = () => {
		if (!job.remote_eligibility) {
			return "---";
		}

		let value;

		const {
			remote_eligibility: { type, office_days }
		} = job;

		if (office_days && type.includes(HYBRID_OPTION.value)) {
			value = `Hybrid (${office_days} days in office/week)`;
		} else {
			const res = REMOTE_ELIGIBITY_OPTIONS.find(({ value }) => value === type);

			value = res?.label || "---";
		}

		return value;
	};

	const contractType = CONTRACT_TYPE.find(
		contract => contract.value === job.contract_type
	);

	return (
		<div ref={containerRef} className={style.root} style={styleObject}>
			<div className={style.headerModal}>
				<h1>Request information</h1>
				<button
					onClick={() => {
						setVacancyInfoModal(false);
					}}
				>
					<Close />
				</button>
			</div>

			<div className={style.vacancyInfo}>
				<div className={style.leftSide}>
					<div className={style.blockInfo}>
						<h3>Remote eligibility</h3>
						<h4>{getRemoteEligbility()}</h4>
					</div>
					<div className={style.blockInfo}>
						<h3>Contract type</h3>
						<h4 className={style.contractTypeValue}>{contractType?.label}</h4>
					</div>

					<div className={style.blockInfo}>
						<h3>Publish date</h3>
						<h4> {formatDate(job.publish_date)}</h4>
					</div>
					<div className={style.blockInfo}>
						<h3>START-END DATE</h3>
						<h4>
							{formatDate(job.start_date)} - {formatDate(job.end_date)}
						</h4>
					</div>
				</div>
				<div className={style.rightSide}>
					<div className={style.blockInfo}>
						<h3>Time commitment</h3>
						<h4> {job.full_time ? FULL_TIME_LABEL : PART_TIME_LABEL}</h4>
					</div>
					<div className={style.blockInfo}>
						<h3>Applicant’s location</h3>
						<h4>
							{job?.applicants_location?.[0].type === "any_where"
								? "Anywhere"
								: job.applicants_location?.[0]?.name}
						</h4>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RequestDetailsPopup;
