import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { FEATURE_SETTINGS } from "config/api-endpoints";

const getFeatureSettings = body =>
	client(FEATURE_SETTINGS, {
		body
	});

export const getFeatureSettingsQueryKey = "@supplier/get-feature-settings";

const useGetFeatureSettings = (
	key = getFeatureSettingsQueryKey,
	options,
	queryOptions = {}
) => {
	return useQuery([key, options], () => getFeatureSettings(options), {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
};

export default useGetFeatureSettings;
