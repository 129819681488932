import React from "react";
import {
	QuestionnaireFormCheckBox,
	QuestionnaireFormMultipleChoice,
	QuestionnaireFormRadioButtonGroup,
	QuestionnaireFormFileUploader,
	QuestionnaireFormYesNoRadioButton,
	QuestionnaireFormSimpleText,
	QuestionnaireFormFileUploaded
} from "common/QuestionnaireForm";

import { QUESTIONNARE_TYPE_OF_FIELDS } from "common/QuestionnaireForm/utils/constants";

const {
	YES_NO,
	MULTIPLE_CHOICE,
	FILE_UPLOAD,
	CHECKBOX,
	SIMPLE_TEXT
} = QUESTIONNARE_TYPE_OF_FIELDS;
export default function QuestionnaireFormBodyQuestionItem({
	field,
	one_answer_allowed,
	isReadOnly,
	value,
	isQuestionnaireResult,
	...props
}) {
	const componentProps = {
		field,
		isReadOnly,
		value,
		...props
	};

	const getComponent = () => {
		switch (field) {
			case CHECKBOX:
				return QuestionnaireFormCheckBox;
			case FILE_UPLOAD:
				return isReadOnly && isQuestionnaireResult
					? QuestionnaireFormFileUploaded
					: QuestionnaireFormFileUploader;
			case MULTIPLE_CHOICE:
				return one_answer_allowed
					? QuestionnaireFormRadioButtonGroup
					: QuestionnaireFormMultipleChoice;
			case YES_NO:
				return QuestionnaireFormYesNoRadioButton;
			case SIMPLE_TEXT:
				return QuestionnaireFormSimpleText;
		}
	};

	const Component = getComponent();
	return Component ? <Component {...componentProps} /> : null;
}
