import React, {
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState
} from "react";
import styles from "./conversations-tab-view-email.module.scss";
import MessagingToolEmailView from "common/MessagingToolEmailView/MessagingToolEmailView";
import { Button } from "common/Button";
import { ReactComponent as CornerIcon } from "static/icons/corner-down-left.svg";
import useGetEmailDetails from "modules/MessagingToolEmailDetails/api/useGetEmailDetails";
import get from "lodash/get";
import { useGetUser } from "hooks/useGetUser";
import { browserHistory } from "react-router";
import { generateId, removeURLParameter } from "config/helpers";
import useReplyToEmail from "modules/MessagingToolEmailDetails/api/useRelpyToEmail";
import useForwardEmail from "modules/MessagingToolEmailDetails/api/useForwardEmail";
import MessagingToolEmailDetailsNewEmail from "modules/MessagingToolEmailDetails/components/MessagingToolEmailDetailsNewEmail/MessagingToolEmailDetailsNewEmail";
import { ACTIONS } from "common/MessagingToolEmailView/utils/constants";
import { DRAFT, SENT } from "config";
import { formatDateAndTime } from "common/MessagingToolEmailsList/utils/helpers";
import { getDate } from "../../utils/helpers";

const ConversationsTabViewEmail = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const conversationId = searchParams.get("conversationId");
	const conversationRef = useRef();
	const [replyOrForward, setReplyOrForward] = useState("");
	const [replyToEmail] = useReplyToEmail();
	const [forwardEmail] = useForwardEmail();
	const [draftEmail, setDraftEmail] = useState(null);

	const user = useGetUser();
	const { data, isLoading } = useGetEmailDetails({
		id: conversationId
	});
	const onBack = () => {
		browserHistory.replace(
			removeURLParameter(window.location.href, ["conversationId"])
		);
	};
	const emailId = useMemo(() => (draftEmail ? draftEmail?._id : generateId()), [
		draftEmail
	]);
	useLayoutEffect(() => {
		if (replyOrForward) {
			const divElement = conversationRef?.current;

			if (divElement) divElement.scrollTop = divElement.scrollHeight;
		}
	}, [replyOrForward]);

	useEffect(() => {
		if (data) {
			const draft = data.replies.find(item => item.status === DRAFT);
			if (draft) {
				setReplyOrForward({ action: ACTIONS.REPLY, emailId });
				setDraftEmail(draft);
			}
		}
	}, [data, emailId]);

	return (
		<MessagingToolEmailView>
			<MessagingToolEmailView.Header
				emailData={data}
				isLoading={isLoading}
				subject={data?.email_subject || data?.subject}
				onBack={onBack}
				onClickReply={({ action, emailId }) =>
					setReplyOrForward({ action: action || ACTIONS.REPLY, emailId })
				}
				onClickForward={() =>
					setReplyOrForward({ action: ACTIONS.FORWARD, emailId: data?._id })
				}
			/>
			<div className={styles.conversation} ref={conversationRef}>
				<MessagingToolEmailView.Message
					emailData={data}
					onClickForward={({ emailId }) =>
						setReplyOrForward({ action: ACTIONS.FORWARD, emailId })
					}
					onClickReply={({ emailId }) =>
						setReplyOrForward({ action: ACTIONS.REPLY, emailId })
					}
					isLoading={isLoading}
					fromName={
						get(data, "from[0].email") === user.email
							? "Me"
							: get(data, "from[0].name")
					}
					toName={get(data, "to", [])
						.map(item => (item.email === user.email ? "Me" : item.name))
						.join(", ")}
					content={data?.email_content || data?.content || ""}
					createdAt={formatDateAndTime(getDate(data), "DD/MM/YYYY, HH:mm")}
					to={get(data, "to", [])
						.map(item => item.email)
						.join(", ")}
					from={get(data, "from", [])
						.map(item => item.email)
						.join(", ")}
					bcc={get(data, "bcc", [])
						.map(item => item.email)
						.join(", ")}
					cc={get(data, "cc", [])
						.map(item => item.email)
						.join(", ")}
					attachments={get(data, "attachments", [])}
				/>
				{get(data, "replies", []).map(
					reply =>
						reply.status === SENT && (
							<MessagingToolEmailView.Message
								emailData={reply}
								messageId={data?.message_id}
								onClickForward={({ emailId }) =>
									setReplyOrForward({ action: ACTIONS.FORWARD, emailId })
								}
								onClickReply={({ emailId }) =>
									setReplyOrForward({ action: ACTIONS.REPLY, emailId })
								}
								key={reply._id}
								isLoading={isLoading}
								fromName={
									get(reply, "from[0].email") === user.email
										? "Me"
										: get(data, "from[0].name")
								}
								toName={get(reply, "to", [])
									.map(item => (item.email === user.email ? "Me" : item.name))
									.join(", ")}
								content={get(reply, "content", "")}
								createdAt={formatDateAndTime(
									getDate(reply),
									"DD/MM/YYYY, HH:mm"
								)}
								to={get(reply, "to", [])
									.map(item => item.email)
									.join(", ")}
								from={get(data, "from", [])
									.map(item => item.email)
									.join(", ")}
								bcc={get(reply, "bcc", [])
									.map(item => item.email)
									.join(", ")}
								cc={get(reply, "cc", [])
									.map(item => item.email)
									.join(", ")}
								attachments={get(reply, "attachments", [])}
							/>
						)
				)}
				{replyOrForward ? (
					<div className={styles.message}>
						<MessagingToolEmailDetailsNewEmail
							emailId={emailId}
							email={draftEmail ? draftEmail : data}
							isDraft={!!draftEmail}
							replyOrForward={replyOrForward}
							onClose={() => {
								setReplyOrForward(null);
							}}
							draftEmail={
								replyOrForward.action === ACTIONS.REPLY
									? replyToEmail
									: forwardEmail
							}
						/>
					</div>
				) : (
					<div className={styles.footer}>
						<Button
							className={styles.cta}
							variant="outlined"
							text="Reply"
							icon={<CornerIcon className={styles.replyIcon} />}
							onClick={() => {
								setDraftEmail(null);
								setReplyOrForward({
									action: ACTIONS.REPLY,
									emailId: data?.message_id
								});
							}}
						/>
						<Button
							className={styles.cta}
							variant="outlined"
							text="Forward"
							icon={<CornerIcon className={styles.forwardIcon} />}
							onClick={() => {
								setDraftEmail(null);
								setReplyOrForward({
									action: ACTIONS.FORWARD,
									emailId: data?._id
								});
							}}
						/>
					</div>
				)}
			</div>
		</MessagingToolEmailView>
	);
};

export default ConversationsTabViewEmail;
