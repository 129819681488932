import React from "react";
import { Helmet } from "react-helmet";
import JobDetails from "./components/JobDetails";
import BodyClassName from "react-body-classname";
import CompanyDashboard from "common/dashboard/Dashboard";
import "./styles.css";

export const getPermanentJobDetailsRoute = id => {
	return id === false ? "jobs/permanent" : `jobs/permanent/${id || ":id"}`;
};

export const getPermissions = () => {
	return {
		type: [{ name: "permanent", roles: ["admin"] }]
	};
};

export default function PermanentJobDetails({ route }) {
	return (
		<BodyClassName className="gray-bg">
			<div>
				{route.title && (
					<Helmet>
						<title>{route.title}</title>
					</Helmet>
				)}
				<div
					className="dashboard-box job-details"
					style={{ paddingBottom: "0px", marginBottom: "0px" }}
				>
					<CompanyDashboard />
				</div>
				<JobDetails />
			</div>
		</BodyClassName>
	);
}
