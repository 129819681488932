import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GROUP_LIST } from "config/api-endpoints";

const getDepartments = () => {
	return client(GROUP_LIST, {
		body: {
			type: "jobs"
		}
	});
};

export const GET_DEPARTMENTS_QUERY_KEY = "GET_DEPARTMENTS_QUERY_KEY";

export const useGetDepartmentsList = (options = {}) => {
	const res = useQuery(GET_DEPARTMENTS_QUERY_KEY, getDepartments, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});

	return res;
};
