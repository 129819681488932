import React from "react";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import { Controller } from "react-hook-form";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CategorySelect } from "../CategorySelect";

const CreateRequestCategoryField = ({ error, control, list, setValue }) => {
	const onCategoryChange = (value, onChange) => {
		onChange(value);
		setValue("subCategory", { label: "", value: null });
		setValue("_function", { label: "", value: null });
		setValue("skills", []);
	};

	return (
		<CreateRequestFieldContainer>
			<CreateRequestLabelContainer>
				<CreateRequestFieldLabel displayStar>Category</CreateRequestFieldLabel>
			</CreateRequestLabelContainer>
			<Controller
				name="category"
				control={control}
				render={({ field: { value, onChange } }) => {
					return (
						<CategorySelect
							onChange={value => onCategoryChange(value, onChange)}
							menu={list}
							value={value?.label}
						/>
					);
				}}
			/>

			<CreateRequestFieldError error={error} />
		</CreateRequestFieldContainer>
	);
};

export default CreateRequestCategoryField;
