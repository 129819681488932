export const SHOW_MENU = "SHOW_MENU";
export const HIDE_MENU = "HIDE_MENU";

function hideMenuRight() {
	return {
		type: HIDE_MENU
	};
}

function showMenuRight() {
	return {
		type: SHOW_MENU
	};
}

export function toggleMenu(show = false) {
	return dispatch => {
		if (show) {
			dispatch(showMenuRight());
		} else {
			dispatch(hideMenuRight());
		}
	};
}
