import { useEffect } from "react";
import usePostingRequirementStore from "../store/usePostingRequirementStore";

export const useToggleFreeChannels = (
	isMyCandidatesActive,
	isTalentPoolActive,
	freeChannelSelectionBucket
) => {
	const { setvalue } = usePostingRequirementStore();

	useEffect(() => {
		const {
			actions: { row },
			helpers: { isFreeChannelSelected }
		} = freeChannelSelectionBucket;

		const toggleChannel = row;
		const isMyCandidatesSelected = isFreeChannelSelected("my_candidates");
		const isTalentPoolSelected = isFreeChannelSelected("talent_pool");

		if (isMyCandidatesActive && !isMyCandidatesSelected) {
			toggleChannel("my_candidates");
		} else if (!isMyCandidatesActive && isMyCandidatesSelected) {
			toggleChannel("my_candidates");
		}
		if (isTalentPoolActive && !isTalentPoolSelected) {
			toggleChannel("talent_pool");
		} else if (!isTalentPoolActive && isTalentPoolSelected) {
			toggleChannel("talent_pool");
		}

		setvalue("freeChannels", {
			my_candidates: isMyCandidatesActive,
			talent_pool: isTalentPoolActive
		});
	}, [isMyCandidatesActive, isTalentPoolActive]);
};
