import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _get from "lodash/get";
import { Label, Radio } from "@rebass/forms/styled-components";
import { VACANCY_LEVEL, LEVEL } from "config";
import { LIST_FUNCTIONS, GUEST_LIST_FUNCTIONS } from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";
import StyledSelect from "common/StyledSelect";
import Tooltip from "rc-tooltip";
import { StyledTd } from "./styled";

function Row({
	sectors,
	rawSectors,
	subSectors,
	onMainChange,
	fnc,
	isMainRequired,
	isFreelancer,
	isOnline = true,
	...props
}) {
	const [functions, setFunctions] = useState(null);
	const [loading, setLoading] = useState(false);
	const [nextSubCatLabel, setNextSubCatLabel] = useState(null);
	const [SubCatTooltip, displaySubCatTooltip] = useState(false);
	const [nextFuncLabel, setNextFuncLabel] = useState(null);
	const [funcTooltip, displayFuncTooltip] = useState(false);
	const optionsFormatter = options => {
		const newOptions = [];
		for (const option of options) {
			newOptions.push({
				value: option._id,
				label: option.name
			});
		}
		return newOptions;
	};

	const onRemove = fun => props.onRemove(fun);

	const getFunctions = async sector_id => {
		try {
			setLoading(true);
			const data = await AxiosHelper.post({
				url: isOnline ? LIST_FUNCTIONS : GUEST_LIST_FUNCTIONS,
				data: { sector_id, no_limit: true },
				isLogin: true,
				manageByDefault: false
			});
			setLoading(false);
			setFunctions(data);

			const func = data.find(f => f._id === fnc._id);

			if (func) {
				props.onUpdate({
					...fnc,
					_id: func._id,
					name: func.name,
					seniority: fnc.seniority,
					sector: {
						...fnc.sector,
						...func.sector
					}
				});
			}
		} catch {
			setLoading(false);
		}
	};

	useEffect(() => {
		const subCategory = fnc.sector._id;
		if (subCategory) {
			getFunctions(subCategory);
		} else if (!subCategory && functions) {
			setFunctions(null);
		}
	}, [fnc.sector._id]);

	const handleSubCategoryChange = nextSubCategory => {
		props.onUpdate({
			...fnc,
			_id: null,
			name: null,
			seniority: null,
			sector: {
				...fnc.sector,
				_id: nextSubCategory.value,
				name: nextSubCategory.label
			}
		});
		if (nextSubCategory.label.length > 32) {
			setNextSubCatLabel(nextSubCategory.label);
		} else {
			setNextSubCatLabel(null);
		}
	};

	const handleFunctionChange = nextFunction => {
		props.onUpdate({
			...fnc,
			_id: nextFunction.value,
			name: nextFunction.label,
			seniority: null
		});
		if (nextFunction.label.length > 32) {
			setNextFuncLabel(nextFunction.label);
		} else {
			setNextFuncLabel(null);
		}
	};

	const handleSeniorityChange = nextSeniority => {
		props.onUpdate({
			...fnc,
			seniority: nextSeniority.value
		});
	};

	const handleCategoryChange = nextCategory => {
		props.onUpdate({
			...fnc,
			_id: null,
			name: null,
			seniority: null,
			sector: {
				_id: null,
				name: null,
				parent_sector: {
					...fnc.sector.parent_sector,
					_id: nextCategory.value,
					name: nextCategory.label
				}
			}
		});
	};

	const getSeniorities = () => {
		let seniorities = isFreelancer ? LEVEL : VACANCY_LEVEL;
		props.usedFunctions.forEach(used => {
			if (used.function === _get(fnc, "_id")) {
				if (fnc.seniority) {
					seniorities = seniorities.filter(
						s => s.value !== used.seniority || s.value === fnc.seniority
					);
				} else {
					seniorities = seniorities.filter(s => s.value !== used.seniority);
				}
			}
		});

		return seniorities;
	};

	const category = _get(fnc, "sector.parent_sector._id");
	const subCategory = _get(fnc, "sector._id");
	const fun = _get(fnc, "_id");
	const seniority = _get(fnc, "seniority");

	const onVisibleChangeSubCat = visible => {
		displaySubCatTooltip(visible);
	};

	const onVisibleChangeFunc = visible => {
		displayFuncTooltip(visible);
	};

	return (
		<tr className="category">
			{isMainRequired && (
				<td style={{ paddingLeft: 24 }}>
					<div
						style={{
							display: "flex",
							height: 50,
							alignItems: "center"
						}}
					>
						<Label
							style={{
								cursor: "pointer",
								display: "flex",
								justifyContent: "flex-start"
							}}
						>
							<Radio
								id="main"
								name="main"
								checked={fnc.is_main || false}
								onChange={() => onMainChange(fnc.id)}
							/>
						</Label>
					</div>
				</td>
			)}
			<td>
				<div
					className={`select-wrapper ${
						props.hasError && !category ? "error" : ""
					}`}
					style={{ width: "100%" }}
				>
					<StyledSelect
						name="category"
						options={sectors}
						placeholder="Choose a Category"
						onChange={handleCategoryChange}
						valueToSet={category}
						position={false}
						grouped={true}
						rawData={rawSectors}
						valueField="_id"
						showErrorContainer={false}
						clearable={false}
						searchable={false}
						style={{ width: "100%", marginTop: "unset" }}
					/>
				</div>
			</td>
			<StyledTd>
				<Tooltip
					placement="top"
					overlay={<div>{nextSubCatLabel}</div>}
					trigger="hover"
					destroyTooltipOnHide={true}
					mouseLeaveDelay={0}
					overlayClassName="g-tool-dark"
					visible={nextSubCatLabel ? SubCatTooltip : false}
					onVisibleChange={onVisibleChangeSubCat}
				>
					<div
						className={`select-wrapper ${
							props.hasError && !subCategory ? "error" : ""
						}`}
					>
						<Select
							name="subcategory"
							value={subCategory}
							options={subSectors}
							clearable={false}
							onChange={handleSubCategoryChange}
							searchable={true}
							placeholder="Choose a Sub-Category"
							className={nextSubCatLabel && "select-tooltip"}
						/>
					</div>
				</Tooltip>
			</StyledTd>
			<StyledTd>
				<Tooltip
					placement="top"
					overlay={<div>{nextFuncLabel}</div>}
					trigger="hover"
					destroyTooltipOnHide={true}
					mouseLeaveDelay={0}
					overlayClassName="g-tool-dark"
					visible={nextFuncLabel ? funcTooltip : false}
					onVisibleChange={onVisibleChangeFunc}
				>
					<div
						className={`select-wrapper ${
							props.hasError && !fun ? "error" : ""
						}`}
					>
						<Select
							name="function"
							value={fun}
							loading={loading}
							options={optionsFormatter(functions || [])}
							clearable={false}
							searchable={true}
							onChange={handleFunctionChange}
							placeholder="Choose a Function"
							className={nextFuncLabel && "select-tooltip"}
						/>
					</div>
				</Tooltip>
			</StyledTd>
			<td>
				<div
					className={`select-wrapper ${
						props.hasError && !seniority ? "error" : ""
					}`}
				>
					<Select
						name="seniority"
						value={seniority}
						disabled={loading}
						options={getSeniorities()}
						clearable={false}
						searchable={true}
						onChange={handleSeniorityChange}
						placeholder="Choose a Seniority"
					/>
				</div>
			</td>
			<td>
				<button
					style={{
						backgroundColor: "transparent",
						border: "none"
					}}
					className="remove"
					onClick={() => onRemove(fnc)}
				>
					×
				</button>
			</td>
		</tr>
	);
}

Row.propTypes = {
	onRemove: PropTypes.func,
	onUpdate: PropTypes.func,
	onMainChange: PropTypes.func,
	index: PropTypes.number,
	fnc: PropTypes.object,
	subSectors: PropTypes.array,
	sectors: PropTypes.array,
	rawSectors: PropTypes.array,
	selectedFunctions: PropTypes.array,
	hasError: PropTypes.bool,
	headLineFunction: PropTypes.object,
	usedFunctions: PropTypes.array
};

export default memo(Row);
