import React, { useCallback, useMemo, useState, useEffect } from "react";
import CreateMissionFieldLabel from "../CreateMissionFieldLabel/CreateMissionFieldLabel";
import Checkbox from "common/AgTable/components/Checkbox/Checkbox";
import { ReactComponent as CheckIcon } from "static/icons/checkIcon2.svg";
import { ReactComponent as ArrowLeftIcon } from "static/icons/arrow-left-icon.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-icon-grey.svg";
import { SaveButton } from "common/SaveButton";
import { Button } from "common/Button";
import { useForm, Controller } from "react-hook-form";
import { RadioGroup } from "common/RadioGroup";
import {
	FREELANCER_TYPES,
	RATE_TYPE,
	TIMESHEET_FREQUENCY_TYPES,
	getSchema
} from "../_CreateMissionUtils/constant";
import get from "lodash/get";

import { DatePicker } from "common/DatePicker";

import CurrencyField from "common/NewCurrencyField";
import { ComboBox } from "common/ComboBox";
import { Item } from "react-stately";
import { CustomInput } from "common/CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { goBack, shouldDisplayFee } from "config/helpers";
import { v4 as uuid } from "uuid";
import {
	useGetCurrenciesList,
	useGetDepartmentsList,
	useGetSitesList,
	useGetFreelancersList,
	useGetConsultantList,
	useGetSupplierList,
	useGetReportingSettingsList
} from "../../api";
import TimePicker from "common/TimePicker";
import {
	_mappingValues,
	clearMultipleFields
} from "../_CreateMissionUtils/helper";
import useReportingSettings from "../../hooks/useReportingSettings";
import styles from "./create-mission-form.module.scss";
import useSaveMission from "../../api/useSaveMission";
import useElements from "hooks/useLayoutElements";
import { getLocalTimeZone, today } from "@internationalized/date";
import useDebouncedValue from "hooks/useDebouncedValue";
import { useSelector } from "react-redux";
import { FEATURE_MULTISITE } from "config";
import { useIsDisplayCostEnabled } from "hooks/useIsDisplayCostEnabled";
import { calculateCost } from "modules/CreateMission/utils/helpers";
import MissionSupplierModeCard, {
	MissionSupplierModeCardHeader,
	MissionSupplierModeCardBody
} from "common/MissionSupplierModeCard";
import { ReactComponent as TicketIcon } from "static/icons/ticket.svg";

import { FieldError } from "common/FieldError";
import { DAILY } from "config";
import moment from "moment";
import _get from "lodash";

function CreateMissionForm() {
	const { mainElement } = useElements();
	const currentDay = today(getLocalTimeZone());
	const [minEndDate, setMinEndDate] = useState(currentDay);

	const features = useSelector(state => state.auth?.user?.features);
	const sitePermission = features.includes(FEATURE_MULTISITE);

	const [rateType, setRateType] = useState("hourly");
	const [showSupplierMode, setShowSupplierMode] = useState(false);

	const [symbol, setSymbol] = useState("");
	const [consultant, setConsultant] = useState(null);
	const [type, setType] = useState("freelancer");

	const { data: departmentsList } = useGetDepartmentsList();
	const { data: sitesData } = useGetSitesList();
	const { data: settings } = useGetReportingSettingsList();
	const { data: currenciesList } = useGetCurrenciesList();

	const [showCostByFee, setShowCostByFee] = useState(false);
	const displayCostEnabled = useIsDisplayCostEnabled();

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
		setValue,
		register
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(getSchema(sitePermission)),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: true
	});

	const freelancerValue = watch("freelancer");
	const debouncedFreelancerSearch = useDebouncedValue(freelancerValue?.label);

	const supplierValue = watch("supplier");
	const debouncedSupplierSearch = useDebouncedValue(supplierValue?.label);

	const consultantValue = watch("consultant");
	const debouncedConsultantSearch = useDebouncedValue(consultantValue?.label);

	const startDateValue = watch("start_date");
	useEffect(() => {
		setMinEndDate(startDateValue || currentDay);
		if (startDateValue) {
			setValue("end_date", startDateValue.add({ days: 1 }));
		}
	}, [startDateValue]);

	const [createMission] = useSaveMission();

	useReportingSettings(
		settings?.reporting_settings,
		currenciesList,
		setValue,
		setRateType
	);

	const { data: freelancersList } = useGetFreelancersList(
		debouncedFreelancerSearch
	);

	const { data: supplierList } = useGetSupplierList(
		type,
		debouncedSupplierSearch
	);

	const { data: consultantList } = useGetConsultantList(
		consultant,
		debouncedConsultantSearch
	);

	const sitesList = sitesData ? sitesData.site_list : [];

	const currencyCode = watch("currency");
	const { rate, rate_type } = watch();

	useEffect(() => {
		if (settings && displayCostEnabled && rate) {
			const value = calculateCost({ settings, type, rate_type, rate });
			setValue("hourly_cost", value);
		} else setValue("hourly_cost", 0);
	}, [rate, type, rate_type, settings]);

	useMemo(() => {
		if (rate_type === DAILY) {
			setValue("hours", moment("00:00", "HH:mm"));
		}
	}, [rate_type]);

	useMemo(() => {
		if (!currenciesList) return;
		const selectedCurrency = currenciesList?.find(
			({ code }) => code == currencyCode?.value
		);
		if (!selectedCurrency) return;
		setSymbol(selectedCurrency?.symbol);
	}, [currencyCode?.value, currenciesList]);

	const onSelectionChange = useCallback((key, onChange, data) => {
		const value = {
			label: data?.label,
			value: key
		};

		onChange(value);
	}, []);

	const onInputChange = (key, onChange) => value => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch(key)?.value
		};
		onChange(valueObject);
	};

	const handleType = (value, onChange) => {
		onChange(value);
		setType(value);
		clearMultipleFields(["freelancer", "supplier", "consultant"], setValue);
	};

	const submit = data => {
		const contract_id = uuid();
		const payload = _mappingValues(
			data,
			freelancersList,
			consultantList,
			currenciesList,
			showSupplierMode
		);
		createMission({ contract_id, ...payload });
	};

	useEffect(() => {
		if (settings) {
			const _property = type === "freelancer" ? type : "consultancy";

			const { fee_type, margin, daily_amount, hourly_amount } = _get(
				settings,
				`vms_settings.${_property}`,
				{}
			);

			const result = shouldDisplayFee({
				feeType: fee_type,
				margin,
				dailyAmount: daily_amount,
				hourlyAmount: hourly_amount,
				rateType: rate_type
			});

			setShowCostByFee(result);
		}
	}, [type, settings, rate_type, rate]);

	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				<div className={styles.field}>
					<CreateMissionFieldLabel label="Mission title" />
					<Controller
						name="mission_title"
						control={control}
						render={({ field }) => (
							<CustomInput
								{...field}
								className={styles.input}
								placeholder="Describe your mission title..."
							/>
						)}
					/>
					{errors?.mission_title && (
						<div className={styles.fieldError}>
							{errors?.mission_title?.message}
						</div>
					)}
				</div>
				<div className={styles.semiFields}>
					<div className={styles.field}>
						<CreateMissionFieldLabel label="Departement" />
						<Controller
							name="departement"
							control={control}
							render={({ field: { value, onChange } }) => {
								return (
									<ComboBox
										popoverContainer={mainElement}
										inputClassName={styles.comboxInput}
										inputValue={value?.label}
										selectedKey={value?.value?.id}
										placeholder={"Select a department"}
										onChange={onChange}
										onInputChange={value =>
											onInputChange("departement", onChange)(value)
										}
										onSelectionChange={key =>
											onSelectionChange(key, onChange, value)
										}
									>
										{(departmentsList || []).map(value => {
											return <Item key={value._id}>{value.name}</Item>;
										})}
									</ComboBox>
								);
							}}
						/>
						{errors?.departement && (
							<div className={styles.fieldError}>
								{errors?.departement?.message}
							</div>
						)}
					</div>
					{sitePermission && (
						<div className={styles.field}>
							<CreateMissionFieldLabel label="Site" />
							<Controller
								name="site"
								control={control}
								render={({ field: { value, onChange } }) => {
									return (
										<ComboBox
											popoverContainer={mainElement}
											inputClassName={styles.comboxInput}
											inputValue={value?.label}
											selectedKey={value?.value?.id}
											placeholder={"Select a site"}
											onChange={onChange}
											onInputChange={value =>
												onInputChange("site", onChange)(value)
											}
											onSelectionChange={key =>
												onSelectionChange(key, onChange, value)
											}
										>
											{sitesList.map(({ _id, name }) => {
												return <Item key={_id}>{name}</Item>;
											})}
										</ComboBox>
									);
								}}
							/>
							{errors?.site && (
								<div className={styles.fieldError}>{errors?.site?.message}</div>
							)}
						</div>
					)}
				</div>
				<div className={styles.field}>
					<CreateMissionFieldLabel label="Type" />
					<Controller
						name="type"
						control={control}
						defaultValue={"freelancer"}
						render={({ field: { onChange, value } }) => (
							<RadioGroup
								className={styles.radioButtonContainer}
								options={FREELANCER_TYPES}
								value={value}
								onChange={e => handleType(e.target.value, onChange)}
							/>
						)}
					/>
				</div>
				<div className={styles.semiFields}>
					{type == "freelancer" ? (
						<div className={styles.field}>
							<CreateMissionFieldLabel label="Freelancer" />
							<Controller
								name="freelancer"
								control={control}
								render={({ field: { value, onChange } }) => {
									return (
										<ComboBox
											popoverContainer={mainElement}
											inputClassName={styles.comboxInput}
											inputValue={value?.label}
											selectedKey={value?.value?.id}
											placeholder={"Select a freelancer"}
											onChange={onChange}
											onInputChange={value => {
												onInputChange("freelancer", onChange)(value);
											}}
											onSelectionChange={key =>
												onSelectionChange(key, onChange, value)
											}
										>
											{freelancersList?.data?.map(
												({ id, first_name, last_name }) => (
													<Item key={id}>{`${first_name} ${last_name}`}</Item>
												)
											)}
										</ComboBox>
									);
								}}
							/>
							{errors?.freelancer && (
								<div className={styles.fieldError}>
									{errors?.freelancer?.message}
								</div>
							)}
						</div>
					) : (
						<>
							<div className={styles.field}>
								<CreateMissionFieldLabel label="Supplier" />
								<Controller
									name="supplier"
									control={control}
									render={({ field: { value, onChange } }) => {
										return (
											<ComboBox
												popoverContainer={mainElement}
												inputClassName={styles.comboxInput}
												inputValue={value?.label}
												selectedKey={value?.value?.id}
												onChange={onChange}
												onInputChange={value => {
													onInputChange("supplier", onChange)(value);
												}}
												onSelectionChange={key => {
													setConsultant(key);
													onSelectionChange(key, onChange, value);
													setValue("consultant", {});
												}}
											>
												{supplierList?.data?.map(
													({ id, name, consultants_count }) => (
														<Item key={id} textValue={name}>
															{`${name} (${consultants_count})`}
														</Item>
													)
												)}
											</ComboBox>
										);
									}}
								/>
								{errors?.supplier && (
									<div className={styles.fieldError}>
										{errors?.supplier?.message}
									</div>
								)}
							</div>
							<div className={styles.field}>
								<CreateMissionFieldLabel label="Consultant" />
								<Controller
									name="consultant"
									control={control}
									render={({ field: { value, onChange } }) => {
										return (
											<ComboBox
												popoverContainer={mainElement}
												inputClassName={styles.comboxInput}
												inputValue={value?.label}
												selectedKey={value?.value?.id}
												onChange={onChange}
												onInputChange={value => {
													onInputChange("consultant", onChange)(value);
												}}
												onSelectionChange={key =>
													onSelectionChange(key, onChange, value)
												}
											>
												{consultantList?.consultants?.map(
													({ _id, consultant_name }) => {
														return <Item key={_id}>{consultant_name}</Item>;
													}
												)}
											</ComboBox>
										);
									}}
								/>
								{errors?.consultant && (
									<div className={styles.fieldError}>
										{errors?.consultant?.message}
									</div>
								)}
							</div>
						</>
					)}
				</div>
				<div className={styles.semiFields}>
					<div className={styles.field}>
						<CreateMissionFieldLabel label="Start date" />
						<Controller
							control={control}
							name="start_date"
							render={({ field }) => (
								<DatePicker
									portalContainer={mainElement}
									rootClassName={styles.datePickerRoot}
									inputClassName={styles.datePickerInput}
									triggerIcon={<CalendarIcon />}
									{...field}
								/>
							)}
						/>
						{errors?.start_date && (
							<div className={styles.fieldError}>
								{errors?.start_date?.message}
							</div>
						)}
					</div>
					<div className={styles.field}>
						<CreateMissionFieldLabel label="End date" />
						<Controller
							control={control}
							name="end_date"
							render={({ field }) => {
								return (
									<DatePicker
										portalContainer={mainElement}
										rootClassName={styles.datePickerRoot}
										inputClassName={styles.datePickerInput}
										triggerIcon={<CalendarIcon />}
										minValue={minEndDate.add({ days: 1 })}
										{...field}
										value={field.value}
									/>
								);
							}}
						/>
						{errors?.end_date && (
							<div className={styles.fieldError}>
								{errors?.end_date?.message}
							</div>
						)}
					</div>
				</div>
				<div className={styles.field}>
					<CreateMissionFieldLabel label="Timesheet frequency" />
					<Controller
						name="timesheet_frequency"
						control={control}
						render={({ field: { onChange, value } }) => (
							<RadioGroup
								className={styles.radioButtonContainer}
								options={TIMESHEET_FREQUENCY_TYPES}
								value={value}
								onChange={onChange}
							/>
						)}
					/>
				</div>
				<div className={styles.field}>
					<CreateMissionFieldLabel label="Rate" />
					<Controller
						name="rate_type"
						control={control}
						render={({ field: { onChange, value } }) => (
							<RadioGroup
								className={styles.radioButtonContainer}
								options={RATE_TYPE}
								value={value}
								onChange={e => {
									setRateType(e.target.value);
									onChange(e.target.value);
								}}
							/>
						)}
					/>
				</div>
				{rateType === "daily" ? (
					<>
						<div className={styles.field}>
							<Controller
								name="hours"
								control={control}
								render={({ field: { onChange, value } }) => (
									<TimePicker
										onChange={onChange}
										value={value}
										name="hoursPerDay"
										className={styles.timePicker}
									/>
								)}
							/>
						</div>
						<div className={styles.allowSubmissionContainer}>
							<Controller
								name="allowSubmission"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Checkbox
										onChange={() => onChange(!value)}
										checked={value}
										id="allowSubmission"
										className={styles.allowSubmissionCheckbox}
									/>
								)}
							/>
							<div className={styles.allowSubmissionText}>
								Allow submission of overtime (hourly input), on top of the
								amount of hours per day.
							</div>
						</div>
					</>
				) : null}
				<div className={styles.semiFields}>
					<div className={styles.field}>
						<CreateMissionFieldLabel label="Currency" />
						<Controller
							name="currency"
							control={control}
							render={({ field: { value, onChange } }) => {
								return (
									<ComboBox
										popoverContainer={mainElement}
										inputClassName={styles.comboxInput}
										inputValue={value?.label}
										selectedKey={value?.value?.id}
										placeholder={"Select a currency"}
										onChange={onChange}
										onInputChange={value =>
											onInputChange("currency", onChange)(value)
										}
										onSelectionChange={key => {
											onSelectionChange(key, onChange, value);
										}}
									>
										{(currenciesList || []).map(({ code, symbol, name }) => (
											<Item key={code}>{`${symbol} - ${name}`}</Item>
										))}
									</ComboBox>
								);
							}}
						/>
						{errors?.currency && (
							<div className={styles.fieldError}>
								{errors?.currency?.message}
							</div>
						)}
					</div>
					<div className={`${showCostByFee ? styles.rateInput : styles.field}`}>
						<CreateMissionFieldLabel
							label={`${rateType === "daily" ? "Daily" : "Hourly"} rate`}
						/>
						<Controller
							name="rate"
							control={control}
							render={({ field: { value = "", onChange } }) => (
								<div
									className={`${
										showCostByFee
											? styles.hourlyRateContainer
											: styles.hourlyRateContainerFullWidth
									}`}
								>
									<CurrencyField
										input={{
											value,
											onChange
										}}
										className={styles.daysPerWeekInput}
										rootClassName={styles.currency}
										useGreenTheme
									/>
									<div className={styles.daysInOfficeLabel}>
										{symbol}/{rateType === "daily" ? "day" : "h"}
									</div>
								</div>
							)}
						/>
						{errors?.rate && (
							<div className={styles.fieldError}>{errors?.rate?.message}</div>
						)}
					</div>
					{displayCostEnabled && showCostByFee && (
						<div
							className={`${showCostByFee ? styles.rateInput : styles.field}`}
						>
							<CreateMissionFieldLabel
								displayStar={false}
								label={`${rateType === "daily" ? "Daily" : "Hourly"} cost`}
							/>
							<Controller
								name="hourly_cost"
								control={control}
								render={({ field: { value = "", onChange } }) => (
									<div className={styles.hourlyRateContainer}>
										<CurrencyField
											disabled
											input={{
												value,
												onChange
											}}
											className={styles.daysPerWeekInput}
											rootClassName={styles.currency}
											useGreenTheme
										/>
										<div className={styles.daysInOfficeLabel}>
											{symbol}/{rateType === "daily" ? "day" : "h"}
										</div>
									</div>
								)}
							/>
						</div>
					)}
				</div>
				<div className={styles.field}>
					<label className={styles.po_number}>
						<TicketIcon className={styles.icon} />
						PO number
					</label>
					<Controller
						name="po_number"
						control={control}
						render={({ field: { value = "", onChange } }) => (
							<>
								<CustomInput
									onChange={onChange}
									className={`${styles.po_placeHolder} ${styles.input}`}
									placeholder="Enter number"
									value={value}
								/>

								{errors?.po_number && (
									<FieldError
										className={styles.marginBottom}
										error={get(errors, "po_number")}
									/>
								)}
							</>
						)}
					/>
				</div>
				<MissionSupplierModeCard>
					<MissionSupplierModeCardHeader
						control={control}
						checked={showSupplierMode}
						onToggle={() => setShowSupplierMode(prev => !prev)}
					/>
					{showSupplierMode && (
						<MissionSupplierModeCardBody
							register={register}
							errors={errors}
							symbol={`${symbol}/${rateType === "daily" ? "day" : "h"}`}
							control={control}
							rateType={rateType}
						/>
					)}
				</MissionSupplierModeCard>
			</div>
			<div className={styles.buttonContainer}>
				<Button
					text="Cancel"
					onClick={goBack}
					icon={<ArrowLeftIcon />}
					variant="text"
					className={styles.backButton}
					textClassName={styles.backButtonText}
				/>

				<SaveButton
					leftIcon={<CheckIcon />}
					text="Create mission"
					onClick={handleSubmit(submit)}
					className={styles.nextButton}
				/>
			</div>
		</div>
	);
}

export default CreateMissionForm;
