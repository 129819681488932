import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Label = styled.label`
	background-color: #f7f8fa;
	padding: 10px 16px;
	font-size: 14px;
	text-transform: inherit !important;
	border: 1px solid #b9bdc0;
	cursor: pointer;
	margin-bottom: 0;
	color: ${props =>
		props.isActive ? "#879098 !important" : "#3d4b60 !important"};
`;

const InserNameTags = ({ types, onInsert }) => {
	return (
		<>
			<div className="insert-name">
				{types.map((tag, i) => {
					return (
						<Label key={i} onClick={() => onInsert(tag.tag)}>
							{tag.label}
						</Label>
					);
				})}
			</div>
		</>
	);
};
InserNameTags.propTypes = {
	insertFirstName: PropTypes.func,
	insertLastName: PropTypes.func
};
export default InserNameTags;
