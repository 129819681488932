//Migrate to class component
import React, { Component } from "react";
import _isObject from "lodash/isObject";
import _get from "lodash/get";
import NoListMessage from "./NoListMessage";
import { Button } from "common/styled/buttons";
import Icon from "common/styled/icons";
import ListRenderer from "./ListRenderer";
import {
	SENT,
	CREATED,
	PENDING,
	ACCEPTED,
	BLOCKED,
	UNBLOCK_USER,
	BLOCK_USER,
	EDIT_PROFILE,
	ADMIN,
	CONSULTANCY,
	RESEND_INVITATON,
	EXPIRED
} from "config";
import Avatar from "common/Avatar/index";

import AddUserModal from "../AddUserModal";
import ResendInvite from "../modals/ResendInvite";
import { ReactComponent as PrevIcon } from "static/icons/chevron-left.svg";
import { SearchInput } from "common/SearchInput";
import Pagination from "common/NewPagination";
import styles from "./business-unit.module.scss";
import _debounce from "lodash/debounce";
import { ConsultantsLoading } from "../ConsultantsLoading";

export const getUBMName = user => {
	let groupNames = user.group_roles_name;
	return groupNames.map(group => (_isObject(group) ? `${group.name} ` : group));
};

export const getManager = ({
	invitation_status,
	blocked,
	first_name,
	last_name,
	avatar
}) => {
	const invitationStatus =
		invitation_status === SENT || invitation_status === CREATED
			? PENDING
			: invitation_status === ACCEPTED
			? null
			: invitation_status;
	return (
		<div className="consultant_name">
			<div style={{ marginRight: 10 }}>
				<Avatar src={avatar} name={`${first_name} ${last_name}`} />
			</div>
			<div className="avatar-text">
				{first_name} {last_name}
				{(invitationStatus || blocked) && (
					<span
						className={`invitation-status ${
							blocked ? "blocked" : invitationStatus
						}`}
					>
						{" "}
						{blocked ? "User blocked" : `Invitation ${invitationStatus}`}
					</span>
				)}
			</div>
		</div>
	);
};
export const isUserExpired = user => user.invitation_status === EXPIRED;
export const isUserBlocked = user =>
	user.blocked || user.invitation_status === BLOCKED;

export const allowBlock = user =>
	[CREATED, SENT, ACCEPTED].includes(user.invitation_status);

export const shouldUnblock = (user, list) => {
	const _list = list.filter(
		el =>
			_get(el, "group_roles_name.0._id") ===
				_get(user, "group_roles_name.0._id") &&
			el.email !== user.email &&
			el.invitation_status !== BLOCKED &&
			!el.blocked
	);
	// do not unblock a user if there are other pending users with the same group name
	return !!_list.length;
};

export const isInvitation = user => !user._id;

class BusinessUnitManager extends Component {
	state = {
		activeModal: false,
		isEdit: false,
		user: {},
		isResend: false,
		offset: 0,
		search: "",
		debounceValue: ""
	};

	debouncedSubmit = _debounce(
		val => this.setState({ debounceValue: val }),
		500
	);

	componentDidMount() {
		const { search, offset } = this.state;

		this.props.fetchList({ search, offset, tag: "bu_managers" });
	}

	componentDidUpdate(_, prevState) {
		const { search, offset } = this.state;

		if (
			prevState.debounceValue !== this.state.debounceValue ||
			prevState.offset !== this.state.offset
		) {
			this.props.fetchList({ search, offset, tag: "bu_managers" });
		}
	}

	toggleModal = (user, isResend = false) => {
		const { activeModal } = this.state;
		if (user) {
			this.__email = user.email;
		}
		this.setState({
			activeModal: !activeModal,
			step: 0,
			user,
			isResend,
			isEdit: !!user
		});
	};

	getBUMName = user => {
		let groupNames = user.group_roles_name;
		return groupNames.map(group =>
			_isObject(group) ? `${group.name} ` : group
		);
	};

	sendInvitation = business_unit => {
		const nextState = {
			invitedUser: { ...this.state.invitedUser, business_unit }
		};
		const skipStep =
			this.state.isEdit && this.__email === this.state.invitedUser.email;
		this.setState(
			() => {
				if (!skipStep) nextState.step = 3;
				return { ...nextState };
			},
			() => {
				if (skipStep) {
					// skip invitation step
					return this.goToThirdStep();
				}
			}
		);
	};

	doAction = (user, event) => {
		if (event.key === RESEND_INVITATON) {
			this.toggleModal(user, true);
		} else if (event.key === EDIT_PROFILE) {
			this.toggleModal(user, false);
		} else {
			const { search, offset } = this.state;

			this.props.accessChanged(
				{
					...user,
					isConsultant:
						this.props.AuthenticatedUser.company_type === CONSULTANCY
				},
				undefined,
				{ search, offset, tag: "bu_managers" }
			);
		}
	};

	isConnectedUserSuperAdmin = !this.props.AuthenticatedUser.is_member;

	listActions = [
		{
			icon: "icon-pen",
			name: "Edit profile",
			key: EDIT_PROFILE,
			isVisible: user => !isUserExpired(user) && !isUserBlocked(user)
		},
		{
			icon: "icon-block",
			name: "Block user",
			key: BLOCK_USER,
			isVisible: user =>
				this.isConnectedUserSuperAdmin &&
				allowBlock(user) &&
				!isUserBlocked(user)
		},
		{
			icon: "icon-unblock",
			name: "Unblock user",
			key: UNBLOCK_USER,
			isDisabled: shouldUnblock,
			isVisible: user => this.isConnectedUserSuperAdmin && isUserBlocked(user),
			overlay: "There's already an unblocked user with the same business unit"
		},
		{
			name: "Resend invitation",
			icon: "icon-send",
			key: RESEND_INVITATON,
			isVisible: user => isInvitation(user),
			isDisabled: shouldUnblock,
			overlay: "There's already an active user with the same business unit"
		}
	];

	onResend = () => {
		const { user, search, offset } = this.state;

		this.props
			.resend(
				{
					id: user.invitation_id,
					group_id: _get(user, "group_roles_name.0._id")
				},
				{ search, offset, tag: "bu_managers" }
			)
			.then(() => this.toggleModal({}, false));
	};

	render() {
		const {
			isEmpty,
			list,
			AuthenticatedUser = {},
			userBUList,
			BUList,
			ManagerBUList,
			total,
			size
		} = this.props;

		const { activeModal, user, isEdit, isResend, search } = this.state;
		return (
			<div>
				{isEmpty && !search ? (
					<NoListMessage
						message={
							<>
								There is no Business Unit <br /> Manager at this stage.
							</>
						}
						onClick={() => this.toggleModal()}
						buttonText="Add business unit manager"
					/>
				) : this.props.isLoading ? (
					<ConsultantsLoading />
				) : (
					[
						<div
							style={{ margin: "50px 0px 30px" }}
							key="search"
							className={styles.searchContainer}
						>
							<SearchInput
								inputClassName={styles.searchInput}
								placeholder="Find a business unit manager"
								onChange={e => {
									this.setState({ search: e.target.value, offset: 0 });
									this.debouncedSubmit(e.target.value);
								}}
								value={this.state.search}
							/>
							<Button
								onClick={() => this.toggleModal()}
								key="create-bum"
								size={14}
								id="create-business-unit"
								paddingTop={12}
								paddingBottom={12}
								float="left"
							>
								<Icon className="fa fa-plus" />
								Add business unit manager
							</Button>
						</div>,
						<ListRenderer
							key="table"
							list={list}
							thList={["Business unit manager", "Email", "Business unit name"]}
							tdList={[getManager, "email", this.getBUMName]}
							onClick={this.doAction}
							actions={this.listActions}
							connectedUser={this.props.AuthenticatedUser}
						/>
					]
				)}
				<AddUserModal
					active={!isResend && activeModal}
					userBUList={userBUList?.data}
					toggleModal={this.toggleModal}
					AuthenticatedUser={AuthenticatedUser}
					type={ADMIN}
					isEdit={isEdit}
					user={user}
					email={this.__email}
					initialize={this.props.initialize}
					BUList={BUList}
					ManagerBUList={ManagerBUList}
					tag="bu_managers"
					search={this.state.search}
					offset={this.state.offset}
				/>
				<ResendInvite
					active={isResend && activeModal}
					user={user}
					onResend={this.onResend}
					onClose={this.toggleModal}
				/>
				{total > 0 && (
					<div style={{ position: "relative" }}>
						<Pagination
							previousLabel={<PrevIcon />}
							nextLabel={<PrevIcon style={{ transform: "rotate(180deg)" }} />}
							total={total}
							size={size}
							handlePageClick={page => {
								this.setState({ offset: page * size });
							}}
							offset={this.state.offset}
							forcePage={Math.ceil(this.state.offset / size)}
							pageRangeDisplayed={total > 1000 ? 6 : undefined}
							marginPagesDisplayed={total > 1000 ? 0 : undefined}
							className={styles.pagination}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default BusinessUnitManager;
