import React from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import get from "lodash/get";
import Card from "../Card";
import Tag from "../Tag";
import CurrencyFormatter from "common/CurrencyFormater";
import EmptyState from "modules/candidate/Profile/components/EmptyState";

import styles from "../Card/card.module.scss";

const PreferencesCard = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	isLoading,
	title,
	provided,
	preferenceData
}) => {
	const locations = get(
		preferenceData,
		"candidate_preferences.front_sheet.preference.preferred_location",
		[]
	);

	const filteredLocations = locations.reduce((acc, curr) => {
		if (!curr.is_main) {
			acc.push(curr?.city ? `${curr.city}, ${curr.country}` : curr.city);
		}
		return acc;
	}, []);

	const preferences = get(preferenceData, "candidate_preferences.front_sheet");
	const expectedSalary = get(
		preferenceData,
		"candidate_preferences.front_sheet.preference.expected_salary"
	);
	const currencyExpected = get(
		preferenceData,
		"candidate_preferences.front_sheet.preference.currency_expected"
	);
	const paymentTimeExpected = get(
		preferenceData,
		"candidate_preferences.front_sheet.preference.payment_time_expected"
	);
	const paymentTypeExpected = get(
		preferenceData,
		"candidate_preferences.front_sheet.preference.payment_type_expected"
	);
	const extraBenefits = get(
		preferenceData,
		"candidate_preferences.front_sheet.preference.extra_benefits",
		[]
	);

	const LoadingSkeleton = (
		<>
			<div className={styles.section}>
				<div className={styles.title}>Locations</div>
				<div className={styles.row}>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={200} height={16} />
					</SkeletonTheme>
				</div>
			</div>
			<div className={styles.section}>
				<div className={styles.title}>Salary</div>
				<div className={styles.row}>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={200} height={16} />
					</SkeletonTheme>
				</div>
			</div>
			<div className={styles.section}>
				<div className={styles.title}>Extra benefits</div>
				<div className={styles.row}>
					<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
						<Skeleton width={150} height={16} />
					</SkeletonTheme>
				</div>
			</div>
		</>
	);
	return (
		<Card
			provided={provided}
			title={title}
			{...{ onDragEnd, onViewMore, onToggleVisibility }}
		>
			{!isLoading ? (
				preferences ? (
					<>
						{filteredLocations.length > 0 && (
							<div className={styles.section}>
								<div className={styles.title}>Locations</div>
								<div className={styles.row}>
									{filteredLocations.map(location => (
										<div key={location} className={styles.textGras}>
											<p>{location}</p>
										</div>
									))}
								</div>
							</div>
						)}
						{currencyExpected && expectedSalary && (
							<div className={styles.section}>
								<div className={styles.title}>Salary</div>
								<div className={styles.row}>
									<div className={styles.textGras}>
										<CurrencyFormatter
											cent={expectedSalary}
											symbol={` ${currencyExpected}`}
										/>
									</div>
									<div className={styles.textNormal}>
										<p>{paymentTypeExpected}</p>
									</div>
									<div className={styles.textNormal}>
										<p>{paymentTimeExpected}</p>
									</div>
								</div>
							</div>
						)}
						{extraBenefits && (
							<div className={styles.section}>
								<div className={styles.title}>Extra benefits</div>
								<div className={styles.row}>
									{extraBenefits.map(extraBenefit => (
										<Tag key={extraBenefit} text={extraBenefit.label} outline />
									))}
								</div>
							</div>
						)}
					</>
				) : (
					<EmptyState />
				)
			) : (
				LoadingSkeleton
			)}
		</Card>
	);
};
PreferencesCard.prototype = {
	...Card.prototype,
	isLoading: PropTypes.bool.isRequired,
	preferenceData: PropTypes.shape({
		filteredLocations: PropTypes.array.isRequired,
		expectedSalary: PropTypes.number.isRequired,
		currencyExpected: PropTypes.string.isRequired,
		extraBenefits: PropTypes.array.isRequired
	})
};
export default PreferencesCard;
