import usePermanentStore from "modules/home/zustand/PermanentStore.js";
import useSuppliersStore from "modules/home/zustand/SuppliersStore.js";
import useTasksStore from "modules/home/zustand/TasksStore.js";
import useTemporaryStore from "modules/home/zustand/temporary.store.js";
import useInterviewStore from "modules/home/containers/interview/interviewStore.js";
import useOverviewStore from "modules/home/containers/jobsOverview/overview.store.js";
import useRatioStore from "modules/home/containers/vacancyRatio/ratio.store.js";
import useHomeStore from "modules/home/zustand/HomeStore.js";

export const useResetHomePageStores = () => {
	const { reset } = usePermanentStore();
	const { reset: resetSuppliersStore } = useSuppliersStore();
	const { reset: resetTasksStore } = useTasksStore();
	const { reset: resetTemporaryStore } = useTemporaryStore();
	const { resetInterviews } = useInterviewStore();
	const { resetOverview } = useOverviewStore();
	const { resetRatio } = useRatioStore();
	const { resetHomeStore } = useHomeStore();
	const resetAllHomeStores = () => {
		reset();
		resetSuppliersStore();
		resetTasksStore();
		resetTemporaryStore();
		resetInterviews();
		resetOverview();
		resetRatio();
		resetHomeStore();
	};
	return [resetAllHomeStores];
};
