import React from "react";
import { ReactComponent as EmptyStateIcon } from "static/icons/empty-state-interviews.svg";

const EmptyState = () => (
	<div className="empty-state-interviews">
		<EmptyStateIcon />
		<span className="empty-state-text">No results to show</span>
	</div>
);

export default EmptyState;
