import produce from "immer";
import { useReducer, useEffect } from "react";

const initialState = {
	data: [],
	id: "",
	index: null,
	nextDisabled: false,
	previousDisabled: false
};

const SET_DEFAULT_STATE = "SET_DEFAULT_STATE";
const NEXT_ID = "NEXT_ID";
const PEREVIOUS_ID = "PEREVIOUS_ID";

const paginationReducer = produce((draft, action) => {
	const LAST_INDEX = draft.data.length - 1;
	const FIRST_INDEX = 0;
	switch (action.type) {
		case SET_DEFAULT_STATE:
			draft.data = action.data;
			draft.id = action.id;
			draft.index = action.index;
			draft.nextDisabled = action.index === LAST_INDEX;
			draft.previousDisabled = action.index === FIRST_INDEX;
			return;

		case NEXT_ID: {
			if (draft.index < LAST_INDEX) {
				draft.id = draft.data[draft.index + 1];
				draft.index += 1;
				draft.previousDisabled = false;
			}
			if (draft.index === LAST_INDEX) {
				draft.nextDisabled = true;
			}
			return;
		}
		case PEREVIOUS_ID:
			if (draft.index > FIRST_INDEX) {
				draft.id = draft.data[draft.index - 1];
				draft.index -= 1;
				draft.nextDisabled = false;
			}
			if (draft.index === FIRST_INDEX) {
				draft.previousDisabled = true;
			}
			return;
		default:
			return draft;
	}
});

export default function useListPagination(defaultProps = {}) {
	const [state, dispatch] = useReducer(paginationReducer, {
		...initialState,
		...defaultProps
	});
	useEffect(() => {
		dispatch({
			type: SET_DEFAULT_STATE,
			data: defaultProps.data,
			id: defaultProps.id,
			index:
				defaultProps.data.indexOf(defaultProps.id) !== -1
					? defaultProps.data.indexOf(defaultProps.id)
					: 0
		});
	}, [defaultProps.id]);

	const nextId = () => dispatch({ type: NEXT_ID });

	const previousId = () => dispatch({ type: PEREVIOUS_ID });

	return {
		state: {
			id: state.id,
			data: state.data,
			previousDisabled: state.previousDisabled,
			nextDisabled: state.nextDisabled
		},
		nextId,
		previousId
	};
}
