import React from "react";
import Avatar from "common/Avatar/index";
import styles from "modules/home/components/styles.module.scss";

const Attendees = ({ attendees, max = 3 }) => {
	return (
		<div className={styles.attendees}>
			{attendees.slice(0, max).map(ele => (
				<Avatar
					key={ele._id}
					size={18}
					src={ele.avatar}
					name={`${ele.first_name} ${ele.last_name}`}
				/>
			))}
			{Boolean(attendees.length - max > 0) && (
				<div>+{attendees.length - max}</div>
			)}
		</div>
	);
};

export default Attendees;
