import React, { useEffect, useState } from "react";
import { Headline } from "../styles";
import { ReactComponent as ButtonCircleIcon } from "static/icons/Button-Circle-icon.svg";
import RedAvatarIcon from "static/icons/red-avatar-icon.svg";
import GreenAvatarIcon from "static/icons/green-avatar-icon.svg";
import YellowAvatarIcon from "static/icons/yellow-avatar-icon.svg";
import avatar from "static/images/Avatar-default.png";
import { CoumpoundSelect } from "common/CustomSelect";
import get from "lodash/get";
import Loader from "common/Loader";

const Attendees = ({ onChange, data, error, isLoadingAttendees }) => {
	const [open, setOpen] = useState(false);
	const [includedItems, setIncludedItems] = useState([]);
	const [showMoreUsers, setShowMoreUser] = useState(3);
	useEffect(() => {
		const attendeesUsers = includedItems.map(({ _id }) => _id);
		onChange(attendeesUsers);
	}, [includedItems]);
	useState(() => {
		if (data instanceof Array) {
			setIncludedItems(array => [...array, data[get(data, "length", 0) - 1]]);
		}
	}, [data]);

	const renderSelectedUsers = () => {
		let privateRandomAvatar = 0;
		const renderAvatar = user => {
			if (Boolean(get(user, "avatar"))) return get(user, "avatar", false);
			const avatarArray = ["yellow", "green", "red", "gray"];

			switch (avatarArray[privateRandomAvatar]) {
				case "yellow":
					privateRandomAvatar++;
					return YellowAvatarIcon;
				case "green":
					privateRandomAvatar++;
					return GreenAvatarIcon;
				case "red":
					privateRandomAvatar++;
					return RedAvatarIcon;
				case "gray":
					privateRandomAvatar = 0;
					return avatar;

				default:
					return avatar;
			}
		};

		return includedItems
			.slice(0, showMoreUsers)
			.map(ele => (
				<img
					style={{ marginRight: "2px", borderRadius: "50%" }}
					key={ele._id}
					src={renderAvatar(ele)}
				/>
			));
	};
	return (
		<div className="attendes-container">
			<Headline>Invite other Attendees</Headline>

			<div className="invite-attendes">
				{isLoadingAttendees ? (
					<div className="loading-attendees">
						<Loader />
						<span>Loading attendees...</span>
					</div>
				) : (
					<React.Fragment>
						{renderSelectedUsers()}
						{Boolean(get(includedItems, "length", 0) - showMoreUsers > 0) && (
							<button
								onClick={e => {
									e.stopPropagation();
									setShowMoreUser(showMoreUsers + 3);
								}}
								className="other-invited-users"
							>
								+{includedItems.length - showMoreUsers}
							</button>
						)}
						<CoumpoundSelect.Opener setOpen={setOpen}>
							<ButtonCircleIcon />
							{get(includedItems, "length", 0) < 4 && (
								<span className="invite-users">Select members</span>
							)}
						</CoumpoundSelect.Opener>
					</React.Fragment>
				)}
			</div>
			{error && <div className="error-message"> {error} </div>}

			{open && (
				<CoumpoundSelect.Select
					isLoading={false}
					includedItems={includedItems}
					setIncludedItems={setIncludedItems}
					data={data}
					setOpen={setOpen}
					open={open}
				>
					{" "}
				</CoumpoundSelect.Select>
			)}
		</div>
	);
};

export default Attendees;
