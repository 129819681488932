import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_CONTRACT_PROPOSITIONS_ENDPOINT } from "config/api-endpoints";
import { contractListKey } from "modules/home/api/queriesKeys.js";

const WELCOME_PAGE_CONTRACT = "welcome_page_contracts";

const listContract = (key, { start_date, end_date }) =>
	client(LIST_CONTRACT_PROPOSITIONS_ENDPOINT, {
		body: {
			start_date,
			end_date,
			tag: WELCOME_PAGE_CONTRACT
		}
	});

const useContractList = (options, queryOptions = {}) =>
	useQuery([contractListKey, options], listContract, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useContractList;
