import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import get from "lodash/get";
import Interview from "modules/home/containers/interview/Interview.jsx";
import style from "modules/home/containers/interview/interview.module.scss";
import Loader from "common/Loader";
import InterviewEmptyState from "modules/home/containers/interview/EmptyState.jsx";

const Interviews = ({ data, isLoading }) => {
	if (isLoading)
		return (
			<div className={style["loader_container"]}>
				<Loader />{" "}
			</div>
		);

	if (!Boolean(get(data, "length")))
		return (
			<div className={style["loader_container"]}>
				<InterviewEmptyState />
			</div>
		);

	return (
		<Scrollbars
			autoHide
			autoHideTimeout={5000}
			autoHideDuration={200}
			autoHeight
			autoHeightMax={430}
		>
			<div className={style["interviews"]}>
				{data.map((interview, index) => (
					<Interview
						key={index}
						interview={interview}
						has_bg={index % 2 === 0}
					/>
				))}
			</div>
		</Scrollbars>
	);
};

export default Interviews;
