import React from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogTitle
} from "../search/CreateNewSupplier/Dialog";
import s from "../search/CreateNewSupplier/create-supplier.module.scss";
import { useQueryCache } from "react-query";
import useChangeSupplierInvitationStatus from "../api/useChangeSupplierInvitationStatus";
import toaster from "../../../common/Toaster";
import { supplierHistoryQueryKey } from "../api/useListSupplierInvitations";
import { PDF_VIEWER_URL } from "../../../config";
import _isEmpty from "lodash/isEmpty";

function ContractDialog({ isOpen, action, id, onClose, file }) {
	const queryCache = useQueryCache();
	const [updateStatus, { isLoading }] = useChangeSupplierInvitationStatus({
		onSuccess() {
			toaster.success(`Invitation ${action}ed.`);
			queryCache.invalidateQueries([supplierHistoryQueryKey]);
			onClose();
		}
	});

	const handleChangeStatus = () => {
		const status = {
			accept: "accepted",
			refuse: "refused"
		};
		updateStatus({
			status: status[action],
			invitation_ids: [id]
		});
	};

	const loadingMessage = {
		accept: "Accepting",
		refuse: "Refusing"
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent size={"xl"} onEscapeKeyDown={onClose}>
				<DialogTitle>{action} Client Invitation</DialogTitle>
				{!_isEmpty(file) && (
					<iframe
						style={{
							width: "100%",
							height: "628px",
							overflow: "hidden",
							border: "none"
						}}
						src={
							PDF_VIEWER_URL + file?.link?.replace(/^http:\/\//i, "https://")
						}
						width="100%"
					/>
				)}
				<div className={s.dialogFooter}>
					<button
						onClick={onClose}
						disabled={isLoading}
						className={s.cancelButton}
					>
						Cancel
					</button>
					<button
						type={"button"}
						disabled={isLoading}
						onClick={handleChangeStatus}
						className={s.mainButton}
					>
						{isLoading ? loadingMessage[action] : action}
					</button>
				</div>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default ContractDialog;
