import React from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import get from "lodash/get";
import cx from "classnames";

import Card from "../Card";
import Status from "../Status";
import EmptyState from "../EmptyState";
import { getUserInteractions } from "../../utils/helper";
import { dateCalendar } from "common/Functions";

import styles from "../Card/card.module.scss";

const InteractionsCard = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	title,
	data,
	isLoading
}) => {
	const interactionData = getUserInteractions(get(data, "data", []));

	return (
		<Card title={title} {...{ onDragEnd, onViewMore, onToggleVisibility }}>
			{!isLoading ? (
				interactionData.length > 0 ? (
					interactionData.map(interactionData => (
						<div key={interactionData.createdAt} className={styles.section}>
							<div className={styles.row}>
								<div className={styles.textNormal}>
									<p>{interactionData.fullName}</p>
								</div>
								<div className={styles.textNormal}>
									<p>{dateCalendar(interactionData.createdAt)}</p>
								</div>
							</div>
							<div className={cx(styles.row, styles.justifyBetween)}>
								<div className={styles.textGras}>
									<p>{interactionData.jobTitle}</p>
								</div>
								<Status type={interactionData.historyType} />
							</div>
						</div>
					))
				) : (
					<EmptyState />
				)
			) : (
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={200} height={16} />
				</SkeletonTheme>
			)}
		</Card>
	);
};
InteractionsCard.prototype = {
	...Card.prototype,
	id: PropTypes.string.isRequired
};
export default InteractionsCard;
