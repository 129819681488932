import styled, { css } from "styled-components";
import { colors } from "config/styles";

export const EmptyLocation = styled.div`
	width: 100%;
	height: 31.5px;
	margin-top: 6px;
`;

export const LocationBoxContainer = styled.div`
	flex-wrap: wrap;
	display: flex;
	margin-top: 6px;
`;

export const LocationBox = styled.div`
	max-width: 140px;
	height: 31.5px;
	border-radius: 3px;
	box-shadow: 0 0 19.8px 0.2px rgba(214, 214, 214, 0.24);
	border: solid 0.5px ${colors.locationBox};
	background-color: ${colors.white};
	text-align: center;
	vertical-align: middle;
	line-height: 31.5px;
	font-size: 12px;
	font-weight: 500;
	color: ${colors.anchorsColor};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	${props =>
		props.flex &&
		css`
			display: flex;
		`};
	text-transform: capitalize;
	padding: 0 10px 0 10px;
	i {
		font-size: 12px;
	}
`;

export const Profile = styled.div`
	padding: 0 !important;
	width: 49.53%;
	a {
		margin-left: 0 !important;
	}
	&.potential-match {
		width: 32.74% !important;
		display: inline-block;
	}
`;

export const ProfilesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	.profile-separator {
		width: 8px;
	}
`;
export const AvatarContainer = styled.div`
	width: 50%;
	.show-avatar {
		margin-top: 3px;
	}
	.anonymous-avatar {
		width: fit-content;
		margin: 0 auto;
		position: relative;
		i {
			border: 2px solid ${colors.white};
			border-radius: 50%;
			background-color: ${colors.lockIconGray};
			color: ${colors.white};
			padding: 7px;
			position: absolute;
			right: 16px;
			top: 4px;
			width: 34px;
			height: 34px;
			font-size: 15px;
			&:hover {
				background-color: ${colors.anchorsColor};
			}
		}
	}
	.search-profile-name {
		margin-top: 7px;
		color: ${colors.darkGray};
		text-transform: capitalize;
	}
`;

export const ProfileRef = styled.div`
	border-radius: 11px;
	border: 1px solid ${colors.primaryColor};
	color: ${colors.primaryColor};
	padding: 0px 10px;
	margin-top: 13px !important;
	margin: 0 auto;
	width: fit-content;
`;

export const InfoContainer = styled.div`
	text-align: center;
`;
export const Function = styled.div`
	text-align: left;
	font-size: 13px;
	font-weight: 500;
	line-height: 2.69;
	color: ${colors.anchorsColor};
	text-transform: capitalize;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
	max-width: 140px;
`;
export const Tag = styled.div`
	flex-wrap: wrap;
	display: flex;
	div {
		width: fit-content;
		height: 30px;
		background-color: ${colors.white};
		line-height: 25px;
		padding: 0 14px;
		float: left;
		font-size: 13.3px;
		font-weight: 500;
		border: 2px solid ${colors.senior};
		border-color: ${colors.senior};
		color: ${colors.senior};
		border-radius: 3px;
		text-transform: capitalize;
	}
`;

export const Details = styled.div`
	border-top: 1px solid ${colors.grayBorder};
	display: flex;
	flex-wrap: wrap;
	.title {
		font-size: 13px;
		font-weight: bold;
		line-height: 2.41;
		text-align: center;
		color: ${colors.grayText};
		text-transform: uppercase;
	}
	.advantages-description {
		font-size: 13px;
		font-weight: 500;
		text-align: center;
		color: ${colors.inputColor};
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 0 auto;
		max-width: 140px;
		.no-advantage {
			color: ${colors.noAdvantagePlaceholder};
		}
	}
	.salary-content {
		font-size: 13px;
		text-align: left;
		color: ${colors.inputColor};
		font-weight: normal;
		display: inline-flex;
		div {
			font-weight: 500;
			padding-right: 5px;
		}
	}
	.salary {
		width: 50%;
		padding: 10px;
	}
	.advantages {
		width: 50%;
		padding: 10px;
		.g-tool {
			width: 100%;
		}
	}
`;

export const NoteCreated = styled.div`
	color: ${colors.anchorsColor};
	font-weight: normal;
	margin-bottom: 30px;
	span {
		font-weight: 500;
		margin-right: 10px;
	}
`;

export const NoteBox = styled.div`
	margin-bottom: 30px;
	line-height: 2;
	#mention-box {
		font-size: 13px !important;
		border-color: transparent;
		outline: none;
		padding: 0 !important;
		&:focus {
			border-color: transparent !important;
		}
	}
	div {
		padding: 0 !important;
	}
`;

export const WarningTextNote = styled.div`
	font-weight: normal;
	color: ${colors.anchorsColor};
	margin-bottom: 10px;
	span {
		font-weight: bold;
	}
`;
export const FlexContainer = styled.div`
	display: flex;
	padding: 20px 0;
`;
export const PreInformation = styled.div`
	width: 50%;
	padding-top: 3px;
	.functions-cont {
		display: flex;
	}
	.location-counter {
		padding: 5.3px 0 0 0;
		color: ${colors.anchorsColor};
		font-weight: 500;
		margin-left: 10px;
		height: 26px;
		&:hover {
			color: ${colors.primaryColor};
			border-bottom: 1px solid ${colors.primaryColor};
		}
	}
	.functions-counter {
		color: ${colors.anchorsColor};
		font-weight: 500;
		padding: 7px 0px 0px 0px;
		height: 26px;
		&:hover {
			color: ${colors.primaryColor};
			border-bottom: 1px solid ${colors.primaryColor};
		}
	}
`;

export const SearchLimitMessage = styled.span`
	font-size: 14px;
	font-weight: normal;
	line-height: 1.79;
	color: ${colors.grayText};
	letter-spacing: normal;
	text-align: center;
	position: absolute;
	top: 19%;
`;

export const WrapperModalCancelInterview = styled.div`
	.txt-modal {
		font-size: 14px;
		color: #193651;
		margin-bottom: 45px;
	}
`;

export const NoteDescriptionWrapper = styled.div`
	background-color: #f8f8f9;
	border: 1px solid #eef0f1;
	padding: 25px;
	margin-bottom: 30px;
`;

export const NoteDescription = styled.p`
	height: 150px;
	font-size: 13px;
	color: #3d4b60;
	font-weight: 400;
`;
