import styled from "styled-components";
export const PerformanceListContainer = styled.div`
	box-shadow: 0px 2px 21.5px 0 rgba(197, 199, 206, 0.43);
	position: absolute;
	z-index: 10;
	right: 0px;
	width: 895px;
	top: ${props => (props.bottom || props.toped ? "unset" : "60px")};
	bottom: ${props => (props.bottom || props.toped ? "60px" : "unset")};
	max-height: 210px;
	overflow-y: ${props => (props.scroll ? "scroll" : "hidden")};
	scrollbar-width: thin;
`;
export const Th = styled.th`
	border: 0;
	color: #6b7075;
	font-weight: 500;
	border-bottom: ${props => (props.border ? "none" : "1px solid #e2e2e2")};
	padding: 15px;
	width: ${props =>
		(props.name && "20%") ||
		(props.count && "15%") ||
		(props.status && "15%") ||
		(props.link && "10%") ||
		(props.applicant && "20%")};
	text-align: ${props =>
		(props.name && "left") ||
		(props.count && "center") ||
		(props.status && "left") ||
		(props.link && "center") ||
		(props.applicant && "center")};
	cursor: normal;
	letter-spacing: normal;
`;
export const Td = styled.td`
	position: relative;
	border: 0;
	border-bottom: ${props => (props.border ? "none" : "1px solid #e2e2e2")};
	word-break: break-word;
	font-size: 13px;
	vertical-align: middle;
	padding: 15px;
	.g-tool {
		margin: 0;
	}
	width: ${props =>
		(props.name && "20%") ||
		(props.count && "15%") ||
		(props.status && "15%") ||
		(props.link && "10%") ||
		(props.applicant && "20%")};
	text-align: ${props =>
		(props.name && "left") ||
		(props.count && "center") ||
		(props.status && "left") ||
		(props.link && "center") ||
		(props.applicant && "center")};
	cursor: normal;
	font-weight: ${props => (props.name ? 500 : 400)};
	color: #3d4b60;
	.channel-name {
		display: flex;
		align-items: center;
	}

	.link-icon {
		width: 18px;
		height: 18px;
		cursor: ${({ channelStatus, isLink = true }) =>
			channelStatus === "offline" || !isLink ? "not-allowed" : "pointer"};
		fill: ${({ channelStatus, isLink = true }) =>
			channelStatus === "offline" || !isLink ? "#e2e4e8" : "#7c7d7e"};
	}
`;

export const Tr = styled.tr`
	height: ${props => props.height && props.height};
	border-bottom: 1px solid #e2e2e2;
	&:last-child {
		${Td} {
			border-bottom: 0 !important;
		}
	}
`;
export const StatusON = styled.div`
	width: 11px;
	height: 11px;
	border: 4px solid #5bc4a3;
	background-color: #ffffff;
	border-radius: 50%;
	display: inline-block;
	top: 1px;
	position: relative;
	margin-right: 5px;
`;
export const StatusOff = styled.div`
	width: 11px;
	height: 11px;
	border: 4px solid #f75c5f;
	background-color: #ffffff;
	border-radius: 50%;
	display: inline-block;
	top: 1px;
	position: relative;
	margin-right: 5px;
`;
export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	.box {
		display: flex;
		flex-direction: column;
		margin: 10px 20px;
		.avatar-content {
			display: flex;
			align-items: center;
			.text {
				margin-left: 10px;
			}
		}
	}
`;
export const Title = styled.span`
	font-family: BasierCircle;
	font-size: 14px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.79;
	letter-spacing: 1.08px;
	text-align: left;
	color: #a0a5b9;
	align-content: center;
	align-items: center;
	text-transform: uppercase;
`;
export const Content = styled.span`
	font-family: BasierCircle;
	font-size: 15px;
	font-weight: ${props => (props.number ? 600 : "normal")};
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: left;
	color: #614e6e;
	svg {
		width: 25px;
		height: 25px;
		fill: darkgray;
	}
`;
