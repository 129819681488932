import React, { memo } from "react";
import _get from "lodash/get";
import { Scrollbars } from "react-custom-scrollbars";
import { formatDate } from "common/Functions";
import { PokeNotesTableDetails, Th, Tr, Td, Section } from "./styled";
import Flag from "static/icons/flag.svg";
import GlobalTooltip from "common/GlobalTooltip";
import { displayAttendeesList } from "common/displayAttendees";
import {
	VIDEO_CONFERENCE,
	TIME_FORMAT_SYMBOL,
	COMPANY_ADDRESS,
	SEARCH_ADDRESS,
	DONE,
	CANCELLED
} from "config";

const INTERVIEW_LABELS = {
	[VIDEO_CONFERENCE]: "Video interview",
	[COMPANY_ADDRESS]: "Company Address",
	[SEARCH_ADDRESS]: "Another Address"
};

const InterviewHistory = ({
	interviews = [],
	flag,
	displayInterviewDetailsModal,
	status,
	selectInterview
}) => {
	const showNotes = () => {
		return interviews.map(item => {
			const {
				isValidArray,
				isValidRestArray,
				restString,
				first,
				restAttendees
			} = displayAttendeesList(
				_get(item, "attendees_users"),
				_get(item, "user_sender")
			);

			let interviewStartTime = formatDate(
				item.new_interview_start_date,
				true,
				TIME_FORMAT_SYMBOL
			);
			let interviewEndTime = formatDate(
				item.new_interview_end_date,
				true,
				TIME_FORMAT_SYMBOL
			);
			let interviewDay = formatDate(item.new_interview_start_date);
			return (
				<Tr key={item._id}>
					<Td>
						<span>
							{item.status === DONE ? (
								<GlobalTooltip
									placement="top"
									overlay={`Interview ${item.status}`}
								>
									<div className="done">
										<span className="wrapper-icon">
											<span className="icon-check2"></span>
										</span>
									</div>
								</GlobalTooltip>
							) : item.status === CANCELLED ? (
								<GlobalTooltip
									placement="top"
									overlay={`Interview ${item.status}`}
								>
									<span className="icon-reset-3 cancelled"></span>
								</GlobalTooltip>
							) : null}
						</span>
					</Td>
					<Td>{formatDate(item.created_at, true)}</Td>
					<Td>
						{`${first.first_name} ${first.last_name}`}

						{isValidArray && isValidRestArray && (
							<GlobalTooltip
								placement="top"
								overlay={`${restString}`}
								overlayClassName="g-tool-dark"
							>
								<div className="rest-attendees">(+{restAttendees.length})</div>
							</GlobalTooltip>
						)}
					</Td>
					<Td>{INTERVIEW_LABELS[item.location_type]}</Td>
					<Td className="detailsInterview">
						<span className="dateInterview">{interviewDay}</span>
						<span className="timeInterview">
							{interviewStartTime}
							<i className="icon-arrow-right-new" />
							{interviewEndTime}{" "}
						</span>
					</Td>
					<Td>
						<div
							className="view-more"
							onClick={() => {
								displayInterviewDetailsModal();
								return selectInterview(item);
							}}
						>
							View more
						</div>
					</Td>
				</Tr>
			);
		});
	};

	return (
		<Section className="section interviews-history">
			{status === CANCELLED || status === DONE ? (
				<div className="flagwrapper">
					<div className={`section-title flag interview-history`}>
						<img src={Flag} alt="Flag" className="img" />
						<div className="text">{flag}</div>
					</div>
				</div>
			) : (
				<span className="past-interviews">PAST INTERVIEW(S)</span>
			)}

			<Scrollbars
				autoHide
				autoHideTimeout={5000}
				autoHideDuration={200}
				autoHeight
				autoHeightMax={324}
			>
				<PokeNotesTableDetails className="request-list">
					<thead>
						<Tr>
							<th width="6%"></th>
							<Th width="17%">Created date</Th>
							<Th width="22%">Attendees</Th>
							<Th width="18%">Location</Th>
							<Th width="25%">Date &amp; time</Th>
							<Th width="12%" />
						</Tr>
					</thead>
					<tbody>{showNotes()}</tbody>
				</PokeNotesTableDetails>
			</Scrollbars>
		</Section>
	);
};

export default memo(InterviewHistory);
