import create from "zustand";

const useNewFilterDrawerCard = create(set => ({
	activeCard: null,
	isKeywordsActive: false,
	setIsKeywordActive: isKeywordsActive => set(() => ({ isKeywordsActive })),
	isAdvancedFilterActive: true,
	setIsAdvancedFilterActive: isAdvancedFilterActive =>
		set(() => ({ isAdvancedFilterActive })),
	setActiveCard: activeCard => set(() => ({ activeCard }))
}));

export default useNewFilterDrawerCard;
