import React from "react";
import { v4 as uuid } from "uuid";

const Mark = () => {
	const id = uuid();
	return (
		<svg
			width="10"
			height="8"
			viewBox="0 0 10 8"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.31508 7.85899L0.133076 4.44199C0.0474843 4.34865 0 4.22662 0 4.09999C0 3.97335 0.0474843 3.85132 0.133076 3.75799L0.776076 3.07499C0.816994 3.03069 0.866637 2.99534 0.921881 2.97117C0.977125 2.94699 1.03677 2.93451 1.09708 2.93451C1.15738 2.93451 1.21703 2.94699 1.27227 2.97117C1.32752 2.99534 1.37716 3.03069 1.41808 3.07499L3.63808 5.46799L8.58008 0.140985C8.621 0.0965295 8.67071 0.0610451 8.72604 0.0367732C8.78138 0.0125013 8.84115 -3.05176e-05 8.90158 -3.05176e-05C8.962 -3.05176e-05 9.02177 0.0125013 9.07711 0.0367732C9.13245 0.0610451 9.18215 0.0965295 9.22308 0.140985L9.86608 0.824985C9.95181 0.918045 9.99941 1.03995 9.99941 1.16649C9.99941 1.29302 9.95181 1.41493 9.86608 1.50799L3.95608 7.85799C3.91516 7.90228 3.86552 7.93763 3.81027 7.9618C3.75503 7.98598 3.69538 7.99846 3.63508 7.99846C3.57477 7.99846 3.51512 7.98598 3.45988 7.9618C3.40464 7.93763 3.35499 7.90228 3.31408 7.85799"
				fill={`url(#${id})`}
			/>
			<defs>
				<linearGradient
					id={id}
					x1="4.9997"
					y1="-3.05176e-05"
					x2="4.9997"
					y2="7.99846"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F01C29" />
					<stop offset="1" stopColor="#FF6849" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default Mark;
