import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { REMOVE_SEARCH_TEMPLATE_ENDPOINT } from "config/api-endpoints";

function deleteCommunityCriteria(body) {
	return client(REMOVE_SEARCH_TEMPLATE_ENDPOINT, {
		body
	});
}

function useDeleteCommunityCriteria(options) {
	return useMutation(deleteCommunityCriteria, options);
}

export default useDeleteCommunityCriteria;
