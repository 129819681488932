import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { initialize } from "redux-form";

import { CONSULTANCY } from "config";
import { proposeBid, closeProposal } from "../../actions/proposalActions";
import CreateBidPopup from "./CreateBidPopup";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { getGroups, setGroups } from "modules/job/actions";
import {
	businessUnitsSelector,
	consultantsGroupsSelector,
	consultantsSelector
} from "../../selectors";
import { getConsultants, setConsultants } from "../../actions";

export class CreateBidPopupContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: props.files.length > 0 ? props.files : undefined
		};
	}

	componentDidMount() {
		const {
			user: { company_type },
			proposal: { job_id },
			setGroups,
			getGroups
		} = this.props;
		const isConsultancy = company_type === CONSULTANCY;
		setGroups([]);
		if (isConsultancy) {
			getGroups({ type: "bids", job_id });
		}
	}

	render() {
		const {
			user,
			proposal,
			proposeBid,
			isFetching,
			closeProposal,
			initializeReduxForm,
			businessUnits,
			consultants,
			getConsultants,
			setConsultants,
			reportingSetting
		} = this.props;

		return (
			<CreateBidPopup
				initializeReduxForm={initializeReduxForm}
				closeProposal={closeProposal}
				proposeBid={proposeBid}
				isFetching={isFetching}
				user={user}
				businessUnits={businessUnits}
				consultants={consultants}
				getConsultants={getConsultants}
				setConsultants={setConsultants}
				files={this.state.files}
				setFiles={files => this.setState({ files })}
				reportingSetting={reportingSetting}
				{...proposal}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: onlineUserSelector(state),
		businessUnits: businessUnitsSelector(state),
		consultantsGroups: consultantsGroupsSelector(state),
		consultants: consultantsSelector(state),
		proposal: state.proposal,
		files: state.jobView.data.required_files,
		reportingSetting: state.jobView.data.reporting_settings || {}
	};
};

const mapDispatchToProps = dispatch => {
	return {
		...bindActionCreators(
			{
				proposeBid,
				closeProposal,
				initializeReduxForm: initialize,
				getGroups,
				setGroups,
				getConsultants,
				setConsultants
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateBidPopupContainer);
