import React from "react";
import styles from "./messaging-tool-list-empty-state.module.scss";
import { ReactComponent as EmptyStateIcon } from "static/icons/empty-state.svg";
import { EMPTY_STATE_DESCRIPTION } from "common/MessagingToolEmailsList/utils/constant";

const MessagingToolEmailListEmptyState = ({ hash }) => {
	return (
		<div className={styles.container}>
			<EmptyStateIcon />
			<h3 className={styles.title}>No Messages! </h3>
			<span className={styles.description}>
				{EMPTY_STATE_DESCRIPTION[hash]}
			</span>
		</div>
	);
};

export default MessagingToolEmailListEmptyState;
