import { OPERATORS } from "common/FilterDrawer/utils";
import { CustomInput } from "common/CustomInput";
import { Picker } from "common/Picker";
import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { BooleanSelect } from "../components/BooleanSelect";
import { AmountInput } from "../components/AmountInput";
import { NumberInput } from "../components/NumberInput";
import { FrequencySelect } from "../components/FrequencySelect";
import { ProfileTypeSelect } from "../components/ProfileTypeSelect";

export const API_DATA_TYPE = "search_timesheets_table";
export const TIMESHEETS_FILTER_ID = "TIMESHEETS_FILTER_ID";
export const TIMESHEETS_MODULE_ID = "TIMESHEETS_MODULE_ID";
export const TIMESHEETS_PAGE_SIZE = 24;
export const TIMESHEETS_PAGE_SIZES = [50, 24, 12];

export const TYPES = {
	text: "text",
	date: "date",
	multipleSelect: "multipleSelect",
	frequencySelect: "frequencySelect",
	profileTypeSelect: "profileTypeSelect",
	number: "number",
	amount: "amount",
	boolean: "boolean"
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[TYPES.multipleSelect]: [OPERATORS.is, OPERATORS.isNot, OPERATORS.anyOf],
	[TYPES.frequencySelect]: [OPERATORS.is],
	[TYPES.profileTypeSelect]: [OPERATORS.is],
	[TYPES.number]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[TYPES.amount]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[TYPES.boolean]: [OPERATORS.isEmpty]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	},
	[TYPES.multipleSelect]: {
		default: Picker
	},
	[TYPES.frequencySelect]: {
		default: FrequencySelect
	},
	[TYPES.profileTypeSelect]: {
		default: ProfileTypeSelect
	},
	[TYPES.number]: {
		default: NumberInput,
		[OPERATORS.isEmpty.value]: BooleanSelect
	},
	[TYPES.amount]: {
		default: AmountInput,
		[OPERATORS.isEmpty.value]: BooleanSelect
	},
	[TYPES.boolean]: {
		default: BooleanSelect
	}
};

export const columnFiltersMap = {
	title: "mission_title",
	user_id: "consultant",
	manager_ts: "timesheet_manager",
	profile_type: "profile_type",
	period: "period",
	submitted_at: "submission_date",
	approved_at: "approval_date",
	status: "status",
	total_amount: "total_amount",
	files: "files",
	start_date: "start_date",
	end_date: "end_date",
	timesheet_frequency: "timesheet_frequency",
	external_manager: "external_manager",
	accounting_manager: "accounting_manager",
	po_number: "po_number",
	external_total_amount: "external_total_amount"
};

export const columnSortMap = {
	title: "mission_title",
	user_id: "consultant",
	manager_ts: "timesheet_manager",
	profile_type: "profile_type",
	period: "period",
	submitted_at: "submission_date",
	approved_at: "approval_date",
	status: "status",
	total_amount: "total_amount",
	files: "files",
	start_date: "start_date",
	end_date: "end_date",
	timesheet_frequency: "timesheet_frequency",
	external_manager: "external_manager",
	accounting_manager: "accounting_manager",
	po_number: "po_number",
	external_total_amount: "external_total_amount"
};

export const CHECK_FILTER_COLUMNS = ["title"];

export const ALL_FILES = "All files";
export const REQUIRED_FILES = "Required files";
export const TIMESHEET_ATTACHMENTS = "Timesheet attachments";

export const getTimesheetFilesTabs = requiredFilesEmpty =>
	requiredFilesEmpty
		? [TIMESHEET_ATTACHMENTS]
		: [ALL_FILES, REQUIRED_FILES, TIMESHEET_ATTACHMENTS];
