import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { UPDATE_REMINDER } from "config/api-endpoints";

const updateReminder = ({ id, time, remindingTime }) => {
	return client(UPDATE_REMINDER, {
		body: {
			id,
			reminder: {
				time,
				reminding_time: remindingTime
			}
		}
	});
};

const useUpdateReminder = (queryOptions = {}) => {
	return useMutation(updateReminder, {
		retry: 0,
		...queryOptions
	});
};
export default useUpdateReminder;
