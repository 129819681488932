import React, { useMemo, useState } from "react";
import { Flex, Box, Text } from "rebass";
import { Scrollbars } from "react-custom-scrollbars";
import FilesUploader from "./FilesUploader";

const FilesStep = ({ closeProposal, goToLastStep, files, setFiles }) => {
	const [hasRequired, setHasRequired] = useState(false);
	const hasEmpty = useMemo(() => files.some(f => f.is_required && !f.path), [
		files
	]);

	const onDoneUploading = file => {
		const fs = [...files];
		const index = fs.findIndex(f => f._id === file._id);
		if (index !== -1) {
			fs[index] = { ...fs[index], ...file };
			setFiles(fs);
		}
	};

	const handleFileNameChange = (_id, value) => {
		const fs = [...files];
		const index = files.findIndex(f => f._id === _id);
		if (index !== -1) {
			fs[index].name = value;
			setFiles(fs);
		}
	};

	const handleNext = () => {
		if (hasEmpty) {
			return setHasRequired(true);
		}
		goToLastStep();
	};

	return (
		<>
			<Box mt={44} mb={75}>
				<Text
					sx={{
						fontFamily: "Roboto",
						fontSize: "16px",
						letterSpacing: "-0.21px",
						color: "rgb(80, 92, 104)",
						width: "80%"
					}}
					mb={44}
				>
					The company your applying is requiring that you provide the following
					documents to move to the next stage:
				</Text>
				<Flex mb={15}>
					<Box width={1 / 3} mr={16}>
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								fontWeight: "bold",
								letterSpacing: "-0.18px",
								color: "#58626b"
							}}
						>
							DOCUMENT TYPE
						</Text>
					</Box>
					<Box width={1 / 3} mr={16}>
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								fontWeight: "bold",
								letterSpacing: "-0.18px",
								color: "#58626b"
							}}
						>
							NAME YOUR FILE
						</Text>
					</Box>
					<Box width={1 / 3}>
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "14px",
								fontWeight: "bold",
								letterSpacing: "-0.18px",
								color: "#58626b"
							}}
						>
							UPLOAD YOUR FILE
						</Text>
					</Box>
				</Flex>
				<Scrollbars
					autoHide
					autoHideTimeout={5000}
					autoHideDuration={200}
					autoHeight
					autoHeightMax={260}
					style={{ zIndex: 1, paddingBottom: "16px" }}
					renderTrackHorizontal={props => (
						<div
							{...props}
							style={{ display: "none" }}
							className="track-horizontal"
						/>
					)}
					renderView={props => (
						<div {...props} style={{ ...props.style, overflowX: "hidden" }} />
					)}
				>
					{files.map(file => (
						<FilesUploader
							key={file._id}
							_id={file._id}
							name={file.name}
							path={file.path || undefined}
							isRequired={file.is_required}
							onDoneUploading={onDoneUploading}
							onNameChange={handleFileNameChange}
						/>
					))}
				</Scrollbars>
				{hasRequired && hasEmpty ? (
					<Box
						sx={{
							color: "#242f43",
							fontWeight: 400,
							backgroundColor: "#fff6f9",
							border: "1px solid #f75356",
							borderRadius: 4,
							padding: 3,
							marginTop: 3
						}}
					>
						<strong>Missing some mandatory documents.</strong> Please upload all
						the required elements.
					</Box>
				) : null}
			</Box>
			<div className="actions">
				<div className="row">
					<div className="col-md-3">
						<button
							className="btn btn-outline-new btn-block"
							onClick={closeProposal}
						>
							Cancel
						</button>
					</div>
					<div className="col-md-4">
						<button
							className="btn btn-primary-new btn-block"
							onClick={handleNext}
						>
							Next
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default FilesStep;
