import React, { useEffect, useState } from "react";
import styles from "./employer-branding-body.module.scss";
import GeneralCard from "../GeneralCard/GeneralCard";
import { Pages } from "../Pages";
import { isEmpty } from "common/FilterDrawer/render_utils";
import { useMyCurrentSite } from "modules/MyCompany/api/useMyCurrentCompany";
import get from "lodash/get";

const EmployerBrandingBody = ({
	site_id,
	hasMultiSite,
	hasCareerPage,
	hasJobPage,
	hasMissionPage,
	isLoadingProp,
	data
}) => {
	const [pageData, setPageData] = useState();

	const { data: siteData, isLoading } = useMyCurrentSite(
		{
			tag: "employer_branding_source",
			site_id
		},
		{ enabled: !isEmpty(site_id) }
	);

	useEffect(() => {
		if (hasMultiSite) {
			setPageData(siteData);
		} else {
			setPageData(data);
		}
	}, [data, siteData]);

	const socialMediaPicture =
		get(pageData, "social_media_picture") !== ""
			? get(pageData, "social_media_picture")
			: "https://hireme-develop-api.s3-eu-west-1.amazonaws.com/avatar/09b67591-66ea-4e87-b4c2-808d2980ebc9/avatar-c74ed44e-378b-46ac-a482-6f75ca6aa570.png";

	return (
		<div className={styles.container}>
			<GeneralCard
				socialMediaPicture={socialMediaPicture}
				companyPage={get(pageData, "feature_employer_branding.post_url", "")}
				isLoading={isLoadingProp || isLoading}
				site_id={site_id}
				hasMultiSite={hasMultiSite}
			/>
			<Pages
				careerPageData={get(pageData, "feature_cms_career_page", {})}
				jobPageData={get(pageData, "feature_cms_job_page", {})}
				missionPageData={get(pageData, "feature_cms_mission_page", {})}
				isLoading={isLoadingProp || isLoading}
				site_id={site_id}
				hasMultiSite={hasMultiSite}
				hasCareerPage={hasCareerPage}
				hasJobPage={hasJobPage}
				hasMissionPage={hasMissionPage}
			/>
		</div>
	);
};

export default EmployerBrandingBody;
