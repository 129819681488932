import React, { memo, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { Label, Checkbox } from "@rebass/forms/styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import { SettingRow } from "./Styled";
import s from "modules/company/components/VariableMargins/components/margin-form.module.scss";

function MarginForm({
	data,
	tiersManagement,
	control,
	register,
	watch,
	setValue,
	onSave,
	isLoading
}) {
	const freelancerActive = watch("freelancer.active");

	const filled = useRef(false);
	useEffect(() => {
		if (data) {
			const fields = {
				...data,
				tiers: []
			};
			fields.consultancy.tiers_management = filled.current
				? tiersManagement
					? "active"
					: "inactive"
				: data.consultancy.tiers_management
				? data.consultancy.tiers_management === "inactive"
					? "inactive"
					: "active"
				: "inactive";

			setValue("consultancy", fields.consultancy);

			filled.current = true;
		}
	}, [data]);
	return (
		<div className="white-box" style={{ marginTop: "20px" }}>
			<Flex mb={4} sx={{ gap: 3 }}>
				<SettingRow active={freelancerActive}>
					<Flex flexDirection="column">
						<Label sx={{ marginBottom: 3, paddingBottom: 2 }}>Freelancer</Label>
						<Flex alignItems={"center"}>
							<Box>
								<Controller
									control={control}
									name={"freelancer.active"}
									render={({ field: { onChange, value, name } }) => (
										<Label sx={{ display: "flex", alignItems: "center" }}>
											<Checkbox
												name={name}
												value={value}
												defaultChecked={data?.freelancer?.active}
												onChange={e => onChange(e.target.checked)}
											/>
											<Text
												sx={{
													fontSize: "14px",
													fontWeight: "500",
													textWrap: "nowrap"
												}}
												as={"span"}
											>
												Allow freelancers to apply from day
											</Text>
										</Label>
									)}
								/>
							</Box>
							<input
								className="freelance-visibility-day form-control"
								type={"number"}
								min={0}
								{...register("freelancer.request_visibility_day")}
								style={{ marginLeft: 10, marginTop: 5 }}
							/>
						</Flex>
					</Flex>
				</SettingRow>
				<Flex ml={10}>
					<SettingRow>
						<Flex flexDirection="column">
							<Label
								sx={{
									display: "block",
									borderBottom: "1px solid #E1E3E5",
									marginBottom: 3,
									paddingBottom: 2
								}}
							>
								Suppliers
							</Label>
							<Flex>
								<div
									className={`form-group radio styled ${
										tiersManagement ? "inactive" : ""
									}`}
								>
									<div className="position-input-box">
										<div className="input-box">
											<input
												type="radio"
												className="radio styled"
												id="margin"
												defaultChecked={!tiersManagement}
												value={"inactive"}
												{...register("consultancy.tiers_management")}
											/>
											<label htmlFor="margin">
												<Flex alignItems={"center"}>
													<Text
														sx={{ fontSize: "14px", fontWeight: "500" }}
														as={"span"}
													>
														Consultancy Requests visibility from day
													</Text>
													<Box width={120}>
														<input
															type="number"
															{...register(
																"consultancy.request_visibility_day"
															)}
															style={{
																padding: "15px 5px",
																marginLeft: 10
															}}
															min={0}
														/>
													</Box>
												</Flex>
											</label>
										</div>
									</div>
								</div>
								<div
									className={`form-group radio styled ${
										!tiersManagement ? "inactive" : ""
									}`}
									style={{ display: "flex", alignItems: "center" }}
								>
									<div className="position-input-box">
										<div className="input-box">
											<input
												type="radio"
												className="radio styled"
												id="tiers"
												defaultChecked={tiersManagement}
												value={"active"}
												{...register("consultancy.tiers_management")}
											/>
											<label htmlFor="tiers">
												<Text
													sx={{ fontSize: "14px", fontWeight: "500" }}
													as={"span"}
												>
													Tiers management (only suppliers)
												</Text>
											</label>
										</div>
									</div>
								</div>
							</Flex>
						</Flex>
					</SettingRow>
				</Flex>
			</Flex>
			{!tiersManagement && (
				<Flex mt={4} justifyContent="flex-end">
					<button disabled={!data} className={s.button} onClick={onSave}>
						{isLoading ? "Saving..." : "Save changes"}
					</button>
				</Flex>
			)}
		</div>
	);
}

export default memo(MarginForm);
