import { SubmissionError } from "redux-form";
import _get from "lodash/get";
import AxiosHelper from "config/axios-helper";
import {
	CREATE_GROUP,
	UPDATE_GROUP,
	GROUP_LIST,
	INVITE_USER,
	CHECK_EMAIL_INVITATION,
	LIST_FUNCTIONS,
	LIST_SECTORS,
	EDIT_USER_ROLE,
	CHECK_PHONE,
	RESEND_INVITATION,
	GET_USER_LIST_URL
} from "config/api-endpoints";
import * as types from "./actionTypes";
import { prepareForSelect } from "common/Functions";
import { getConsultants } from "../../company/actions/usersListActions";
import { CREATED } from "config";

export function createBU(name, reduxFormName) {
	return AxiosHelper.__post({
		url: CREATE_GROUP,
		data: { name },
		isModal: true,
		reduxFormName,
		next: getBUList,
		toastMessage: "Business unit was successfully created."
	});
}

export function editBU({ name, id }, reduxFormName) {
	return AxiosHelper.__post({
		url: UPDATE_GROUP,
		data: { name, id },
		isModal: true,
		reduxFormName,
		next: getBUList,
		toastMessage: "Business unit was successfully edited."
	});
}

export function getBUList({ search, offset }) {
	return AxiosHelper.__post({
		url: GROUP_LIST,
		REQUEST_SUCCESS: types.FETCH_BU_LIST,
		data: { type: "users", limit: 6, search, offset },
		inAction: false
	});
}

export function getBusinessUnitManagersList({ search, offset, tag }) {
	return AxiosHelper.__post({
		url: GET_USER_LIST_URL,
		REQUEST_SUCCESS: types.FETCH_BUSINESS_UNIT_MANAGERS_LIST,
		data: { limit: 6, search, offset, tag },
		inAction: false
	});
}

export function checkPhone({ reduxFormName, phone }) {
	return AxiosHelper.post({
		url: CHECK_PHONE,
		data: { phone },
		reduxFormName,
		isModal: true
	}).catch(error => {
		throw new SubmissionError({
			phone: _get(error, "data.detail.phone.0")
		});
	});
}

export function checkEmailInvitation(
	{ email, first_name, last_name, phone },
	reduxFormName
) {
	return AxiosHelper.post({
		url: CHECK_EMAIL_INVITATION,
		data: { email, first_name, last_name, phone },
		reduxFormName,
		isModal: true
	}).then(response => {
		if (response.exists === true) {
			throw new SubmissionError({
				email: "This email has already been used."
			});
		} else {
			return response;
		}
	});
}

export function inviteUser(data, isEdit = false, options) {
	const payload = {
		name: data.name,
		email: data.email,
		first_name: data.first_name,
		last_name: data.last_name,
		role_name: data.role_name,
		group_id: data.group_id,
		sector: data.sector,
		function: data.function,
		seniority: data.seniority,
		phone: data.phone ? data.phone.formattedValue : undefined,
		doc: data.doc
	};

	if (isEdit) {
		payload.status = data._id ? data.invitation_status : CREATED;
		payload.id = data._id ? data._id : data.invitation_id;
		payload.name = data.name;
	}

	return AxiosHelper.post({
		url: isEdit ? EDIT_USER_ROLE : INVITE_USER,
		data: payload,
		isModal: true,
		next: () => getConsultants(options)
	});
}

export function inviteBusinessUnitManager(data, isEdit = false, options) {
	const payload = {
		name: data.name,
		email: data.email,
		first_name: data.first_name,
		last_name: data.last_name,
		role_name: data.role_name,
		group_id: data.group_id,
		sector: data.sector,
		function: data.function,
		seniority: data.seniority,
		phone: data.phone ? data.phone.formattedValue : undefined,
		doc: data.doc
	};

	if (isEdit) {
		payload.status = data._id ? data.invitation_status : CREATED;
		payload.id = data._id ? data._id : data.invitation_id;
		payload.name = data.name;
	}

	return AxiosHelper.post({
		url: isEdit ? EDIT_USER_ROLE : INVITE_USER,
		data: payload,
		isModal: true,
		next: () => getBusinessUnitManagersList(options)
	});
}

export function getFunctionList(id, isModal) {
	return AxiosHelper.post({
		url: LIST_FUNCTIONS,
		data: { sector_id: id, offset: 0, no_limit: true },
		formatter: prepareForSelect,
		isModal
	});
}

export function getSectorList() {
	return AxiosHelper.post({
		url: LIST_SECTORS,
		isModal: true,
		formatter: data => ({
			sectors: prepareForSelect(data),
			rawSectors: data
		})
	});
}

const setBUID = buid => ({
	type: types.SET_BUID,
	buid
});

const setActiveModal = modal => ({
	type: types.SET_ACTIVE_MODAL,
	modal
});

export const editBUModal = ({ show, buid }) => {
	return dispatch => {
		dispatch(setBUID(buid));
		dispatch(setActiveModal(show));
	};
};

export const resendInvite = (payload, options) =>
	AxiosHelper.__post({
		url: RESEND_INVITATION,
		data: payload,
		isModal: true,
		next: () => getConsultants(options),
		toastMessage: "The invitation has been resent."
	});

export const resendBusinessUnitManagerInvite = (payload, options) =>
	AxiosHelper.__post({
		url: RESEND_INVITATION,
		data: payload,
		isModal: true,
		next: () => getBusinessUnitManagersList(options),
		toastMessage: "The invitation has been resent."
	});
