import React from "react";
import { v4 as uuid } from "uuid";

const Mark = () => {
	const id = uuid();
	return (
		<svg
			width="16"
			height="4"
			viewBox="0 0 16 4"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 2C4 3.104 3.104 4 2 4C0.896 4 0 3.104 0 2C0 0.896 0.896 0 2 0C3.104 0 4 0.896 4 2ZM10 2C10 3.104 9.104 4 8 4C6.896 4 6 3.104 6 2C6 0.896 6.896 0 8 0C9.104 0 10 0.896 10 2ZM16 2C16 3.104 15.104 4 14 4C12.896 4 12 3.104 12 2C12 0.896 12.896 0 14 0C15.104 0 16 0.896 16 2Z"
				fill={`url(#${id})`}
			/>
			<defs>
				<linearGradient
					id={id}
					x1="8"
					y1="0"
					x2="8"
					y2="4"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F01C29" />
					<stop offset="1" stopColor="#FF6849" />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default Mark;
