import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { queryCache } from "react-query";
import get from "lodash/get";
import _get from "lodash/get";
import s from "./overview.module.scss";
import { reorder, getItemStyle, move, getListStyle } from "./dnd-helpers";
import useFilterSettings from "../../api/useFilterSetting";
import useUpdateUserSettings from "../../api/useUpdateUserSetting";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import useOverviewStore from "../../Store/useOverviewStore";
import PreferencesCard from "../../components/PreferencesCard";
import LanguagesCard from "../../components/LanguagesCard";
import { AllProcessesCard } from "../../components/Processes";
import { getFunctionsData } from "../../utils/helper";
import FunctionsCard from "../../components/FunctionsCard";
import NotesCard from "../../components/NotesCard";
import CvCard from "../../components/CvCard";
import InteractionsCard from "../../components/InteractionsCard";
import { ActiveProcesses } from "../../components/ActiveProcesses";
import { SkillsCard } from "../../components/SkillsCard";
import { OverviewProvider } from "./useOverviewContext";
import ProfileTagsCard from "modules/candidate/Profile/components/ProfileTags";
import useGetProcesses from "./../../api/useActiveProcesses";
import useViewUserNotes from "../../api/useViewUserNotes";
import useUserHistory from "../../api/useUserHistory";
import usePokeView from "../../Store/usePokeView";
import { PermanentEvaluationsList } from "../PermanentViewEvaluationsTab/components/PermanentEvaluationsList";
import { CANDIDATE_TABS } from "modules/home/config";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

function Overview({
	candidateData,
	preferenceData,
	isLoading: laodingCandidateData,
	candidateId,
	handleTabsChange,
	onNoteAdd,
	onEvaluationsViewMore
}) {
	const { indexTab } = usePokeView();
	const enabled = indexTab === 0; // FIXME: temp fix
	const { cards, setValue } = useOverviewStore();
	const { workflowId } = useOnboardingStore();

	const { data: dataProcess, isLoading: isLoadingProcesses } = useGetProcesses(
		{
			id: candidateId,
			limit: 4
		},
		{ enabled: enabled && !workflowId }
	);

	const { data: notes, isLoading: isNotesLoading } = useViewUserNotes(
		{ id: candidateId },
		{ enabled: enabled && !workflowId }
	);

	const {
		data: interactions,
		isLoading: isInteractionsLoading
	} = useUserHistory(
		{
			id: candidateId,
			limit: 4
		},
		{ enabled: enabled && !workflowId }
	);

	const { isLoading } = useFilterSettings(
		"@permanent-profile/cards",
		{ tag: "view_candidate_cards" },
		{
			onSuccess: res => {
				setValue("cards", res.cards);
			},
			enabled: enabled && !workflowId
		}
	);

	const [mutate] = useUpdateUserSettings({
		onSuccess: () => {
			queryCache.invalidateQueries("@permanent-profile/cards");
		},
		onError: () => {
			toaster.danger("Error while saving changes.");
			queryCache.invalidateQueries("@permanent-profile/cards");
		}
	});

	function onDragEnd(result) {
		const { source, destination } = result;

		if (!destination) {
			return;
		}
		const sInd = +source.droppableId;
		const dInd = +destination.droppableId;

		if (sInd === dInd) {
			const items = reorder(cards[sInd], source.index, destination.index);
			const newState = [...cards];
			newState[sInd] = items;
			setValue("cards", newState);
			mutate({ cards: newState, type: "view_candidate_cards" });
		} else {
			const result = move(cards[sInd], cards[dInd], source, destination);
			const newState = [...cards];
			newState[sInd] = result[sInd];
			newState[dInd] = result[dInd];
			setValue("cards", newState);
			mutate({ cards: newState, type: "view_candidate_cards" });
		}
	}

	const onHide = (block, columnID) => {
		const newState = [...cards];
		newState[columnID] = cards[columnID].map(elem => {
			if (elem.name === block) {
				elem.is_hidden = true;
			}
			return elem;
		});
		setValue("cards", newState);
		mutate({ cards: newState, type: "view_candidate_cards" });
	};

	const renderBlocks = (block, columnID) => {
		switch (block) {
			case "preferences":
				return (
					<PreferencesCard
						title={<h2>Preferences</h2>}
						onToggleVisibility={() => onHide(block, columnID)}
						preferenceData={preferenceData}
						isLoading={laodingCandidateData}
						onViewMore={() =>
							handleTabsChange(CANDIDATE_TABS.PROFILE, "candidatePreferences")
						}
					/>
				);
			case "cv":
				return (
					<CvCard
						pdf_doc={_get(candidateData, "personal_profile.cv_docs", [])}
						isLoading={laodingCandidateData}
						onToggleVisibility={() => onHide(block, columnID)}
						onViewMore={() => handleTabsChange(CANDIDATE_TABS.PROFILE, "cv")}
					/>
				);
			case "skills":
				return (
					<SkillsCard
						title={<h2>Skills</h2>}
						onToggleVisibility={() => onHide(block, columnID)}
						skills={_get(candidateData, "skills", [])}
						isLoading={laodingCandidateData}
						onViewMore={() =>
							handleTabsChange(CANDIDATE_TABS.PROFILE, "skills")
						}
					/>
				);
			case "evaluations":
				return (
					<PermanentEvaluationsList
						onToggleVisibility={() => onHide(block, columnID)}
						onViewMore={onEvaluationsViewMore}
					/>
				);
			case "functions":
				return (
					<FunctionsCard
						title={<h2>Functions</h2>}
						functions={getFunctionsData(candidateData)}
						isLoading={laodingCandidateData}
						onToggleVisibility={() => onHide(block, columnID)}
						onViewMore={() =>
							handleTabsChange(CANDIDATE_TABS.PROFILE, "functions")
						}
					/>
				);
			case "languages":
				return (
					<LanguagesCard
						title={<h2>Languages</h2>}
						languages={get(candidateData, "languages", [])}
						isLoading={laodingCandidateData}
						onToggleVisibility={() => onHide(block, columnID)}
						onViewMore={() =>
							handleTabsChange(CANDIDATE_TABS.PROFILE, "languages")
						}
					/>
				);
			case "profile_tags":
				return (
					<ProfileTagsCard
						title={<h2>Profile Tags</h2>}
						tags={get(candidateData, "profile_tags", [])}
						isLoading={laodingCandidateData}
						onToggleVisibility={() => onHide(block, columnID)}
						onViewMore={() =>
							handleTabsChange(CANDIDATE_TABS.PROFILE, "profileTags")
						}
					/>
				);

			case "notes":
				//This condition is added to show button see more, because switching from tab notes to overview don't display button.
				return indexTab === 0 ? (
					<NotesCard
						title={<h2>Notes</h2>}
						id={candidateId}
						onToggleVisibility={() => onHide(block, columnID)}
						onViewMore={() => handleTabsChange(CANDIDATE_TABS.NOTES)}
						data={notes}
						isLoading={isNotesLoading}
						onNoteAdd={onNoteAdd}
						candidateData={candidateData}
					/>
				) : null;
			case "interactions":
				return (
					<InteractionsCard
						title={<h2>Interactions</h2>}
						id={candidateId}
						onToggleVisibility={() => onHide(block, columnID)}
						onViewMore={() => handleTabsChange(CANDIDATE_TABS.INTERACTIONS)}
						data={interactions}
						isLoading={isInteractionsLoading}
					/>
				);
			case "all_processes":
				return (
					<AllProcessesCard
						title={<h2>All Processes</h2>}
						onToggleVisibility={() => onHide(block, columnID)}
						processes={dataProcess}
						isLoading={isLoadingProcesses}
						onViewMore={() => handleTabsChange(CANDIDATE_TABS.PROCESSES)}
					/>
				);
			case "active_process":
				return (
					<ActiveProcesses
						title={<h2>Active Processes</h2>}
						onToggleVisibility={() => onHide(block, columnID)}
						processes={dataProcess}
						isLoading={isLoadingProcesses}
						onViewMore={() => handleTabsChange(CANDIDATE_TABS.PROCESSES)}
					/>
				);
		}
	};

	return (
		<div className={s.overviewContainer}>
			<LoaderFull loading={isLoading} />
			<DragDropContext onDragEnd={onDragEnd}>
				{cards.map((el, ind) => (
					<Droppable key={`${ind}`} droppableId={`${ind}`}>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
								{...provided.droppableProps}
							>
								{el
									.filter(es => !es.is_hidden)
									.map((item, index) => (
										<Draggable
											key={`${index}_${item.name}`}
											draggableId={`${index}_${item.name}`}
											index={index}
										>
											{(provided, snapshot) => (
												<OverviewProvider key={item.name} value={{ provided }}>
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														style={getItemStyle(
															snapshot.isDragging,
															provided.draggableProps.style
														)}
													>
														{renderBlocks(item.name, ind)}
													</div>
												</OverviewProvider>
											)}
										</Draggable>
									))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				))}
			</DragDropContext>
		</div>
	);
}
export default Overview;
