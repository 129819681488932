import React, { useState, useEffect } from "react";
import get from "lodash/get";
import _remove from "lodash/remove";
import { ReactComponent as DeleteIcon } from "static/icons/delete-icon.svg";
import { ReactComponent as UploadFailedIcon } from "static/icons/upload-error.svg";
import { Uploader } from "common/react-hook-form-components/uploader/coumpound_uploader";
import styles from "./vacancy-view-send-offer-file-uploader.module.scss";
import { bytesToSize } from "config/helpers";
import { ReactComponent as FileIcon } from "static/icons/referral/file.svg";
import { ReactComponent as TrashIcon } from "static/icons/referral/trash.svg";
import { ReactComponent as CheckIcon } from "static/icons/referral/check-circle.svg";
import { SUPPORTED_SIZE } from "config";
import { ReactComponent as PaperclipIcon } from "static/icons/referral/paperclip.svg";
import { SUPPORTED_FILES } from "common/react-hook-form-components/uploader/coumpound_uploader/Input";

function VacancyViewSendOfferFileUploader({ setValue, setFiles, files }) {
	const [acceptedFiles, setAcceptedFiles] = useState(files || []);
	const [rejectedFiles, setRejectedFiles] = useState([]);

	const onDrop = (accepted, rejected) => {
		const filterAcceptedFiles = accepted.filter(
			item => acceptedFiles.findIndex(elem => elem.path === item.path) === -1
		);
		setAcceptedFiles(old => [...old, ...filterAcceptedFiles]);
		setValue("files", filterAcceptedFiles);
		setRejectedFiles(rejected);
	};

	const handleDeleteAcceptedFiles = (e, file) => {
		e.preventDefault();
		const newData = _remove(acceptedFiles, n => n.path !== file.path);
		const deletedFiles = _remove(files, n => n.name !== file.path);
		setFiles(deletedFiles);
		setAcceptedFiles(newData);
		setValue("files", newData);
	};

	const handleDeleteRejectedFiles = (e, file) => {
		e.preventDefault();
		const newData = _remove(rejectedFiles, n => n.file.path !== file.path);
		setRejectedFiles(newData);
	};

	const addFileUrl = url => {
		if (files.findIndex(item => item.name === url.name) === -1)
			return setFiles(old => [...old, url]);
	};
	return (
		<div className={styles.container}>
			<Uploader>
				{acceptedFiles.map(file => (
					<Uploader.Progress
						key={`${file.size}_${file.name}`}
						file={file}
						shouldUpload={!!file.size}
					>
						{({ progress, fileUrl, isLoading }) => {
							useEffect(() => {
								if (Boolean(fileUrl))
									return addFileUrl({ name: file.name, path: fileUrl });
							}, [fileUrl]);

							return (
								<div key={fileUrl} className={styles.previewContainer}>
									<div className={styles.fileInfos}>
										<div className={styles.iconWrapper}>
											<FileIcon />
										</div>
										<div className={styles.name}>{file.name}</div>
										{file.size && <div>{bytesToSize(file.size)}</div>}
									</div>
									<div className={styles.fileStatus}>
										{progress < 100 && file.size ? (
											<div className={styles.progress}>
												<div className={styles.bar}>
													<div style={{ width: `${progress}%` }}></div>
												</div>
												<div className={styles.percentage}>{progress}%</div>
											</div>
										) : (
											<div className={styles.completed}>
												{file.size && (
													<>
														<span className={styles.label}>Completed</span>
														<CheckIcon />
													</>
												)}
												<button
													onClick={e => handleDeleteAcceptedFiles(e, file)}
													className={styles.deleteIcon}
													disabled={isLoading}
												>
													<TrashIcon />
												</button>
											</div>
										)}
									</div>
								</div>
							);
						}}
					</Uploader.Progress>
				))}
				{rejectedFiles.map((item, index) => (
					<Uploader.Progress key={index} file={item.file}>
						{() => {
							return (
								<div className={styles.progress_container}>
									<UploadFailedIcon />
									<div className={styles.progress_bar}>
										<span className={styles.file_name}> {item.file.name} </span>
										<span className={styles.error_text}>
											{get(item, "errors[0].message", "")}
										</span>
									</div>
									<div className={styles.actions}>
										<button
											onClick={e => handleDeleteRejectedFiles(e, item.file)}
										>
											<DeleteIcon />
										</button>
									</div>
								</div>
							);
						}}
					</Uploader.Progress>
				))}
				<Uploader.Input
					onDrop={onDrop}
					multiple={true}
					className={styles.inputClassName}
				>
					{() => (
						<div
							role="button"
							tabIndex={0}
							className={styles.uploaderContainer}
						>
							<div className={styles.iconWrapper}>
								<PaperclipIcon />
							</div>
							<div>
								<div>
									<span className={styles.highlighted}>Click to upload</span> or
									drag and drop
								</div>
								<div>
									<span className={styles.supportedFiles}>
										{SUPPORTED_FILES.replaceAll(".", "")}
									</span>{" "}
									(max. {bytesToSize(SUPPORTED_SIZE)})
								</div>
							</div>
						</div>
					)}
				</Uploader.Input>
			</Uploader>
		</div>
	);
}

export default VacancyViewSendOfferFileUploader;
