import React, { Component } from "react";
import _get from "lodash/get";
import NoListMessage from "../bum/NoListMessage";
import AddUserModal from "../AddUserModal";
import {
	PROJECT_MANAGER,
	VIEW_PROFILE,
	UNBLOCK_USER,
	BLOCK_USER,
	CONSULTANCY,
	RESEND_INVITATON
} from "config";
import {
	getManager,
	getUBMName,
	isUserBlocked,
	allowBlock,
	isUserExpired,
	isInvitation
} from "../bum/BusinessUnitManager";
import { Button } from "common/styled/buttons";
import Icon from "common/styled/icons";
import ListRenderer from "../bum/ListRenderer";
import ResendInvite from "../modals/ResendInvite";
import { historyPush } from "config/helpers";
import styles from "./consultants.module.scss";
import { ReactComponent as PrevIcon } from "static/icons/chevron-left.svg";
import { SearchInput } from "common/SearchInput";
import Pagination from "common/NewPagination";
import _debounce from "lodash/debounce";
import { ConsultantsLoading } from "../ConsultantsLoading";

const goToFreelancerProfile = (id, hash = "") => {
	return `/consultant/user/profile/${id}?source=list&list=bid&from=${window.location.pathname}${hash}`;
};

export default class Consultants extends Component {
	state = {
		activeModal: false,
		user: undefined,
		BU_id: null,
		offset: 0,
		search: "",
		debounceValue: ""
	};

	debouncedSubmit = _debounce(
		val => this.setState({ debounceValue: val }),
		500
	);

	componentDidMount() {
		const { search, offset } = this.state;
		const { fetchList } = this.props;

		fetchList({
			search,
			offset,
			tag: this.props.isManager ? undefined : "consultants"
		});
	}

	componentDidUpdate(_, prevState) {
		const { search, offset } = this.state;
		const { fetchList } = this.props;

		if (
			prevState.debounceValue !== this.state.debounceValue ||
			prevState.offset !== this.state.offset
		) {
			fetchList({
				search,
				offset,
				tag: this.props.isManager ? undefined : "consultants"
			});
		}
	}

	toggleModal = () => {
		this.setState({ activeModal: !this.state.activeModal });
	};

	isConnectedUserSuperAdmin = !this.props.AuthenticatedUser.is_member;

	getCategory = user => {
		return _get(user, "category", "---");
	};

	doAction = (user, event) => {
		if (event.key === RESEND_INVITATON) {
			this.setState({ activeModal: true, user });
		} else if (event.key === VIEW_PROFILE) {
			historyPush(goToFreelancerProfile(user.cv_id, "#consultants"));
		} else {
			const { search, offset } = this.state;

			this.props.accessChanged(
				{
					...user,
					isConsultant:
						this.props.AuthenticatedUser.company_type === CONSULTANCY
				},
				{
					search,
					offset,
					tag: this.props.isManager ? undefined : "consultants"
				}
			);
		}
	};

	listActions = [
		{
			// find view icon
			icon: "icon-pen",
			name: "View profile",
			key: VIEW_PROFILE,
			isVisible: user =>
				!isUserExpired(user) && !isUserBlocked(user) && user.cv_id
		},
		{
			icon: "icon-block",
			name: "Block user",
			key: BLOCK_USER,
			isVisible: user => allowBlock(user) && !isUserBlocked(user)
		},
		{
			icon: "icon-unblock",
			name: "Unblock user",
			key: UNBLOCK_USER,
			isVisible: user => isUserBlocked(user)
		},
		{
			name: "Resend invitation",
			icon: "icon-send",
			key: RESEND_INVITATON,
			isVisible: user => isInvitation(user)
		}
	];

	onClose = () => {
		this.setState({ activeModal: false, user: undefined });
	};

	onResend = () => {
		const { user, search, offset } = this.state;
		this.props
			.resend(
				{
					id: user.invitation_id,
					group_id: _get(user, "group_roles_name.0._id")
				},
				{
					search,
					offset,
					tag: this.props.isManager ? undefined : "consultants"
				}
			)
			.then(() => this.onClose());
	};

	render() {
		const {
			AuthenticatedUser,
			list,
			isManager,
			is_member,
			userBUList,
			BUList,
			size,
			total,
			isLoading
		} = this.props;
		const { user, activeModal } = this.state;

		const { search } = this.state;

		return (
			<div>
				{total === 0 && !search ? (
					<NoListMessage
						message="There is no registered consultant at this stage."
						onClick={this.toggleModal}
						buttonText="Add a consultant"
					/>
				) : isLoading ? (
					<ConsultantsLoading />
				) : (
					[
						<div
							style={{ margin: isManager ? "20px 0px 30px" : "50px 0px 30px" }}
							key="search"
							className={styles.searchContainer}
						>
							<SearchInput
								inputClassName={styles.searchInput}
								placeholder="Find a consultant"
								onChange={e => {
									this.setState({ search: e.target.value, offset: 0 });
									this.debouncedSubmit(e.target.value);
								}}
								value={this.state.search}
							/>
							<Button
								onClick={() => this.toggleModal()}
								key="create-bum"
								size={14}
								id="create-business-unit"
								paddingTop={12}
								paddingBottom={12}
								float="left"
							>
								<Icon className="fa fa-plus" />
								Add a consultant
							</Button>
						</div>,
						<ListRenderer
							key="table"
							list={list}
							thList={["Name", "Email", "Business unit name", "Category"]}
							tdList={[getManager, "email", getUBMName, this.getCategory]}
							onClick={this.doAction}
							actions={this.listActions}
							connectedUser={this.props.AuthenticatedUser}
						/>
					]
				)}

				<AddUserModal
					active={!user && activeModal}
					toggleModal={this.toggleModal}
					AuthenticatedUser={AuthenticatedUser}
					isManager={isManager}
					is_member={is_member}
					type={PROJECT_MANAGER}
					userBUList={userBUList}
					BUList={BUList}
					search={this.state.search}
					offset={this.state.offset}
					tag={this.props.isManager ? undefined : "consultants"}
				/>

				<ResendInvite
					active={!!user && activeModal}
					user={user}
					onResend={this.onResend}
					onClose={this.onClose}
				/>
				{total > 0 && (
					<div style={{ position: "relative" }}>
						<Pagination
							previousLabel={<PrevIcon />}
							nextLabel={<PrevIcon style={{ transform: "rotate(180deg)" }} />}
							total={total}
							size={size}
							handlePageClick={page => {
								this.setState({ offset: page * size });
							}}
							offset={this.props.offset}
							forcePage={Math.ceil(this.props.offset / size)}
							pageRangeDisplayed={total > 1000 ? 6 : undefined}
							marginPagesDisplayed={total > 1000 ? 0 : undefined}
							className={styles.pagination}
						/>
					</div>
				)}
			</div>
		);
	}
}
