import { get } from "lodash";
import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import useOnClickOutside from "hooks/useOnClickOutside";
import SubMenu from "./SubMenu";
import classnames from "./list-menu.module.scss";
import { ReactComponent as ArrowRight } from "static/icons/arrow-right-list-filter-icon.svg";
import { ReactComponent as SearchIcon } from "static/icons/elements-icons-search.svg";
import { ReactComponent as NoDataIcon } from "static/icons/danger-icon.svg";
import Portal from "../Portal";

const ListMenu = ({
	options = [],
	onSelect,
	closeMenu,
	isSearchable,
	searchKeyword,
	setSearchKeyword,
	fullWidth = false,
	emptyStateIcon = <NoDataIcon />,
	emptyStateText = "No results to show",
	id
}) => {
	const [openSubMenu, setOpenSubMenu] = useState(false);
	const [focusedNode, setfocusedNode] = useState(false);

	const listMenuRef = useRef();
	useOnClickOutside(listMenuRef, () => closeMenu());
	useEffect(() => {
		id && setfocusedNode(document.getElementById(id));
	}, [id]);

	let nodeRect = { x: 0, y: 0 };
	if (focusedNode) {
		nodeRect = focusedNode.getBoundingClientRect();
	}

	const handleSelectOption = option => {
		if (get(option, "options.length", 0) === 0) {
			onSelect(option);
		}
	};
	const handleSelectSubOption = (option, selectedOption) => {
		const fieldObject = {
			...option,
			selectedOption: {
				label: get(selectedOption, "label"),
				value: get(selectedOption, "value")
			},
			filterOptions: get(selectedOption, "filterOptions"),
			...(get(selectedOption, "type") && {
				type: selectedOption.type
			})
		};
		onSelect(fieldObject);
	};

	const handleMouseEnter = option => {
		if (Boolean(get(option, "options.length", 0))) {
			setOpenSubMenu(get(option, "field"));
		}
	};

	const handleMouseLeave = option => {
		if (Boolean(get(option, "options.length", 0))) {
			setOpenSubMenu(false);
		}
	};

	const handleSearch = event => {
		const {
			target: { value }
		} = event;
		setSearchKeyword(value);
	};
	const top = nodeRect.top;
	const left = nodeRect.left;

	return (
		<Portal id={id} nodeRect={nodeRect}>
			<div
				ref={listMenuRef}
				className={cx(classnames.container, {
					[classnames.scroll]:
						options.filter(item => get(item, "options.length", 0)).length === 0,
					[classnames.fullWidth]: fullWidth,
					[classnames.containerScroll]: Boolean(id && top && left)
				})}
			>
				{isSearchable && (
					<div className={classnames.searchBox}>
						<SearchIcon />
						<input
							value={searchKeyword}
							onChange={handleSearch}
							placeholder="Search"
						/>
					</div>
				)}
				{get(options, "length", 0) ? (
					<ul>
						{options.map(option => (
							<li
								onClick={() => handleSelectOption(option)}
								key={option.label}
								onMouseEnter={() => handleMouseEnter(option)}
								onMouseLeave={() => handleMouseLeave(option)}
							>
								<div className={classnames.content}>
									{option.icon && (
										<div className={classnames.icon}>{option.icon}</div>
									)}
									<span className={classnames.label}>{option.label}</span>
								</div>

								{get(option, "options.length", 0) > 0 && (
									<div className={classnames.subMenuWrraper}>
										<ArrowRight style={{ fill: "#575959" }} />
										{openSubMenu === get(option, "field") && (
											<SubMenu
												options={get(option, "options", [])}
												onSelect={selectedOption =>
													handleSelectSubOption(option, selectedOption)
												}
											/>
										)}
									</div>
								)}
							</li>
						))}
					</ul>
				) : (
					<div className={classnames.emptyState}>
						{emptyStateIcon}
						<span>{emptyStateText}</span>
					</div>
				)}
			</div>
		</Portal>
	);
};

export default ListMenu;
