import React from "react";
import Select from "react-select";
import styled from "styled-components";

const SelectContainer = styled.div`
	.Select-control {
		width: 120px;
		height: 32px;
		border: 1px solid #f1f2f3;
		.Select-placeholder {
			display: flex;
			align-items: center;
		}
		.Select-value {
			display: flex;
			align-items: center;
		}
	}
`;
const TypeSelect = ({
	onChange,
	placeholder,
	value,
	options,
	clearable = false,
	searchable = false,
	dafaultValue
}) => {
	return (
		<SelectContainer>
			<Select
				clearable={clearable}
				searchable={searchable}
				options={options}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				dafaultValue={dafaultValue}
			/>
		</SelectContainer>
	);
};

export default TypeSelect;
