/*eslint-disable*/
import React, { Component } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router";
import FormField from "common/FormField";
import {
	emailRegex,
	CREATE_USER,
	ASSIGN_ROLE,
	ASK_QUESTION,
	INVITATION_USER_ALRAEDY_EXIST,
	STEPS
} from "config";
import { toggleHelpModal } from "../../../app/actions/ListsActions";
import { getPolicyRoute } from "../../../../common/privacyPolicy/components/PolicyContainer";
import styles from "./create-user.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/referral/x.svg";
import Steps from "common/steps";

class CreateUser extends Component {
	onSubmit = formProps => {
		return this.props.handleStep(
			CREATE_USER,
			this.props.isModeTour,
			ASSIGN_ROLE,
			formProps
		);
	};

	handleCta = e => {
		e.preventDefault();
		this.props.onClose();
		const fields = {
			title: "Unable to Add Existing User",
			help: "",
			subject: `Unable to Add Existing User - [${this.props.email}]`,
			message: `Hello,\n\nUpon attempting to add a new user, I was told that their email address already corresponds to an existing wiggli account.\n\nThe email in question is: ${this.props.email}.\n\nPlease get back to me as soon as you can.\n\nThank you,\n\n${this.props.adminName}
			`,
			payload: {
				invited_user_email: this.props.email
			},
			type: INVITATION_USER_ALRAEDY_EXIST,
			action: "send"
		};

		this.props.dispatch(toggleHelpModal(ASK_QUESTION, fields));
	};

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	render() {
		const {
			handleSubmit,
			emailExists,
			onClose,
			currentStep,
			showStepper
		} = this.props;
		return [
			<form id="userform" key="form" className={styles.userFormContainer}>
				<header className={styles.header}>
					<h3 className={styles.title}>Create user</h3>
					<div
						role="button"
						tabIndex="0"
						onClick={onClose}
						className={styles.button}
					>
						<CloseIcon />
					</div>
				</header>

				<div className={styles.formContainer}>
					<div className={styles.steps}>
						<Steps
							labelPlacement="vertical"
							steps={STEPS}
							activeStep={currentStep}
							active={showStepper}
							noStyles={true}
						/>
					</div>
					<div className={styles.formFields}>
						<div className="">
							<div className="">
								<Field
									name="first_name"
									label="First name"
									placeholder="First name"
									component={FormField}
								/>
							</div>
						</div>
						<div className="">
							<div className="">
								<Field
									name="last_name"
									label="Last name"
									placeholder="Last name"
									component={FormField}
								/>
							</div>
						</div>
						<div className="">
							<div className="">
								<Field
									name="email"
									label="Email"
									placeholder="Email"
									type="email"
									component={FormField}
									hasError={emailExists}
									normalize={this.normalizeEmail}
								/>
							</div>
						</div>
					</div>
					{emailExists && (
						<div>
							<div className={styles.errorMsg}>
								<div className="alert alert-error">
									<span className="icon-holder">
										<i className="icon-error" />
									</span>
									<p>
										The provided email address already corresponds to an
										existing wiggli account. If the user whose account you are
										trying to create isn’t present in your wiggli contact list,
										please add the user using an alternative email address or{" "}
										<button className="btn-link" onClick={this.handleCta}>
											contact our support team
										</button>
										.
									</p>
								</div>
							</div>
						</div>
					)}
					<div className={styles.policyTextContainer} key="policy-text">
						<div className={styles.policyText}>
							<div>
								By submitting the name and email address, and any other personal
								information of your colleague or third-party user, you confirm
								you have obtained their consent to do so or are able to rely on
								another lawful basis for their personal information to be
								uploaded. Their information will be collected, held, used and
								disclosed in accordance with our{" "}
								<Link
									target="_blank"
									className="link-url"
									to={getPolicyRoute()}
								>
									Privacy Policy.
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.footer}>
					<button
						className={styles.submitBtn}
						onClick={handleSubmit(this.onSubmit)}
					>
						Next
					</button>
					<button type="button" className={styles.cancelBtn} onClick={onClose}>
						Cancel
					</button>
				</div>
			</form>
		];
	}
}

const validate = formProps => {
	const errors = {};
	const fields = ["first_name", "last_name"];
	for (const field of fields) {
		if (!formProps[field] || formProps[field].trim() === "") {
			errors[field] = "This field is required";
		}
	}
	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Email is required";
	} else if (formProps.email && !emailRegex.test(formProps.email)) {
		errors.email = "Email is invalid";
	}
	return errors;
};

const selector = formValueSelector("create-user");

const mapStateToProps = state => {
	return {
		email: selector(state, "email"),
		initialValues: {
			first_name: state.usersList.user?.first_name,
			last_name: state.usersList.user?.last_name,
			email: state.usersList.user?.email
		}
	};
};

export default connect(mapStateToProps)(
	reduxForm({
		form: "create-user",
		touchOnBlur: false,
		validate
	})(CreateUser)
);
