import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Element } from "react-scroll";
import { scroller } from "react-scroll";
import MapContainer from "./MapView/MapContainer";
import GoogleAutocomplete from "../GoogleAutocomplete";
import ViewSwitcher from "./ViewSwitcher";
import AddressEdit from "./AddressEdit";
import toaster from "common/Toaster";
import { ADDRESS_VIEW_TYPES } from "config";

const ViewContainer = styled.div`
	position: relative;
`;

export default function AddressChooser(props) {
	const [view, setView] = useState(ADDRESS_VIEW_TYPES.inputs);
	useEffect(() => {
		if (props.error) {
			setView(ADDRESS_VIEW_TYPES.inputs);
			props.scrollOnError &&
				scroller.scrollTo(props.name, { offset: -50, smooth: true });

			props.showToast &&
				toaster.warning(
					props.addressTypo
						? `Please make sure to double check the spelling of both the city and the country.`
						: `We couldn't get all the required fields from the address you've selected,
					please fill the remaining fields to continue.`,
					{ duration: 15, id: "incomplete-address" }
				);
		}
	}, [props.error]);

	return (
		<Element name={props.name} scrollto="scroll-to-error">
			<>
				{props.showAutoComplete && (
					<div className="address-input-container">
						<div className={"address-input"}>
							<GoogleAutocomplete
								input={{ onChange: props.setAddress }}
								label={props.label}
								name={"address"}
								placeholder="Please type your full address then select from the drop down menu"
								address={props.address}
								overlay={props.overlay}
							/>
						</div>
						{props.addButton && (
							<button
								onClick={props.addButton}
								className="btn btn-primary pull-right btn-location"
								disabled={props.addDisabled}
							>
								+ ADD
							</button>
						)}
					</div>
				)}
				<ViewContainer className="address-container">
					<ViewSwitcher selected={view} onSwitch={type => setView(type)} />
					{view === ADDRESS_VIEW_TYPES.inputs && (
						<AddressEdit
							fields={props.editAddressFields}
							address={props.address}
							error={props.error}
							label={props.label}
							onAddressChange={props.setAddress}
							requiredFields={props.requiredFields}
						/>
					)}
					{view === ADDRESS_VIEW_TYPES.map && (
						<div
							style={{
								position: "relative",
								width: "100%",
								height: 368,
								marginBottom: 31
							}}
							className="map-container"
						>
							<MapContainer
								address={props.address}
								onAddressChange={props.setAddress}
							/>
						</div>
					)}
				</ViewContainer>
			</>
		</Element>
	);
}

AddressChooser.defaultProps = {
	scrollOnError: true,
	showToast: true
};
