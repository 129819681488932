import { useEffect } from "react";
import PusherManager from "config/pusher";
import { getLocalUser } from "config/helpers";

export const useSubscribeToPusher = (events, cb) => {
	const { id, access_token } = getLocalUser();

	useEffect(() => {
		PusherManager.subscribe(id, access_token, [
			{
				name: events,
				cb
			}
		]);

		return () => {
			PusherManager.unsubscribe();
		};
	}, []);
};
