import React from "react";
import styles from "./filter-drawer-header.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";

const FilterDrawerHeader = ({ onClose, title }) => {
	return (
		<div className={styles.container}>
			<div className={styles.title}>{title}</div>
			<div className={styles.imgContainer}>
				<button onClick={onClose} className={styles.closeBtn}>
					<CloseIcon />
				</button>
			</div>
		</div>
	);
};

export default FilterDrawerHeader;
