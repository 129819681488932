import React, { forwardRef } from "react";
import { Button } from "common/Button";
import styles from "./modal-save-button.module.scss";

const ModalSaveButton = ({ onClick, text = "Save", ...propsRest }, ref) => {
	return (
		<Button
			className={styles.button}
			ref={ref}
			text={text}
			onClick={onClick}
			{...propsRest}
		/>
	);
};

export default forwardRef(ModalSaveButton);
