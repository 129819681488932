import React from "react";
import styles from "./evaluation-card-delete-button.module.scss";
import { ReactComponent as DeleteIcon } from "static/icons/trash-04.svg";

const EvaluationCardDeleteButton = ({ onClick }) => {
	return (
		<button className={styles.button} onClick={onClick}>
			<div className={styles.icon}>
				<DeleteIcon />
			</div>
		</button>
	);
};
export default EvaluationCardDeleteButton;
