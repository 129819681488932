import React, { useEffect, useState, useRef } from "react";
import _debounce from "lodash/debounce";
import useInteractionsStore from "../../Store/useInteractionsStore";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import FilterBox from "common/FilterBox";
import Table from "../../components/Table";
import classnames from "./interactions.module.scss";
import get from "lodash/get";
import useUserHistory from "modules/candidate/Profile/api/useUserHistory";
import DisplayResults from "modules/Tasks/components/pagination/DisplayResults";
import PaginationClient from "modules/Tasks/components/pagination";
import {
	sortBylist,
	columns,
	getData,
	filterList
} from "modules/candidate/Profile/utils/interactionsHelper";
import { searchParser } from "config/helpers";
import { INTERACTIONS } from "modules/home/config";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const ProfileInteractions = ({ id }) => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const user = useSelector(onlineUserSelector);
	const {
		filters,
		limit,
		setLimit,
		updateOffset,
		updateSearch,
		updateIsTitleSeacrh,
		updateIsDescriptionSearch
	} = useInteractionsStore();
	const {
		offset,
		// fields,
		field,
		search,
		isTitleSearch,
		isDescriptionSearch
	} = filters;
	useEffect(() => {
		setSearchKeyword(search);
	}, []);

	const { tab } = searchParser();
	const enabled = tab === INTERACTIONS;

	const { workflowId } = useOnboardingStore();
	let { data: resolvedData, isFetching } = useUserHistory(
		{
			...filters,
			limit,
			id
		},
		{
			enabled: enabled && !workflowId
		}
	);

	const data = getData(isFetching, resolvedData, user);
	const total = get(resolvedData, "pagination.total", "");
	const size = get(resolvedData, "pagination.size", "");

	const debouncedChangeFilterAction = useRef(_debounce(updateSearch, 500));

	const handelSearchChange = value => {
		setSearchKeyword(value);
		debouncedChangeFilterAction.current(value);
		updateOffset(0);
	};

	return (
		<div className={classnames.container}>
			<div className={classnames.interactionsTitle}>Interactions</div>
			<div className={classnames.filterBox}>
				<FilterBox
					searchKeyword={searchKeyword}
					setSearchKeyword={handelSearchChange}
					isDescriptionSearch={isDescriptionSearch}
					setIsDescriptionSearch={updateIsDescriptionSearch}
					isTitleSearch={isTitleSearch}
					setIsTitleSearch={updateIsTitleSeacrh}
					store={useInteractionsStore}
					sortBylist={sortBylist}
					filterList={filterList(id)}
					hideParams={true}
				/>
			</div>
			<div className={classnames.listTasks}>
				<Table
					data={data}
					isFetching={isFetching}
					fetchedData={get(resolvedData, "data", [])}
					sortedField={field}
					columns={columns(field)}
					className={"notes"}
				/>
			</div>
			{get(resolvedData, "data.length", 0) > 0 && (
				<div className={classnames.paginationContainer}>
					<DisplayResults
						resolvedData={resolvedData}
						isFetching={isFetching}
						updateOffset={updateOffset}
						offset={offset}
						setLimit={setLimit}
						limit={limit}
					/>

					<PaginationClient
						size={size}
						offset={offset}
						setOffset={updateOffset}
						total={total}
					/>
				</div>
			)}
		</div>
	);
};

export default ProfileInteractions;
