import { useQuery } from "react-query";
import { renderError } from "config/helpers";
import { client } from "lib/api-client";
import { LIST_EMAILS } from "config/api-endpoints";
import { EMAILS_LIST_LIMIT } from "../utils/constant";
import fakeData from "modules/user-onboarding/mockData/inbox-emails-list.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getEmailsList = body => {
	return client(LIST_EMAILS, {
		body: {
			...body,
			limit: EMAILS_LIST_LIMIT
		}
	});
};

export const MESSAGING_TOOL_EMAILS_LIST_KEY = "MESSAGING_TOOL_EMAILS_LIST_KEY";

function useGetEmailsList(body, options) {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery([MESSAGING_TOOL_EMAILS_LIST_KEY, body], () => {
			return Promise.resolve(fakeData);
		});
	}

	return useQuery(
		[MESSAGING_TOOL_EMAILS_LIST_KEY, body],
		() => getEmailsList(body),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			onError: renderError,
			...options
		}
	);
}

export default useGetEmailsList;
