import { getLocalTimeZone, today } from "@internationalized/date";
import { formatDate } from "common/Functions";
import { timestampToDateCalendar } from "config/helpers";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";
import moment from "moment";

export function formatTime(timestamp, format) {
	const currentDay = today(getLocalTimeZone());
	const calendarDate = timestampToDateCalendar(timestamp);

	const isSameDay =
		calendarDate.day === currentDay.day &&
		calendarDate.month === currentDay.month &&
		calendarDate.year === currentDay.year;

	if (isSameDay) {
		// If the email was received today, return the time in HH:MM AM/PM format
		return window.moment
			.unix(timestamp)
			.utc()
			.format("LT");
	} else {
		// If the email was received on a different day, return the date in DD/MM/YYYY format
		return formatDate(timestamp, true, format, true);
	}
}

export function formatDateAndTime(timestamp, format) {
	const currentDay = today(getLocalTimeZone());
	const calendarDate = timestampToDateCalendar(timestamp);

	const isSameDay =
		calendarDate.day === currentDay.day &&
		calendarDate.month === currentDay.month &&
		calendarDate.year === currentDay.year;

	if (isSameDay) {
		// If the email was received today, return the time in HH:MM AM/PM format
		return moment.unix(timestamp).format("LT");
	} else {
		// If the email was received on a different day, return the date in DD/MM/YYYY format
		return moment.unix(timestamp).format(format);
	}
}

export function getFieldsFromSearchInput(email, view) {
	const path = [
		MESSAGING_TOOL_LINKS.sent.slug,
		MESSAGING_TOOL_LINKS.schedule.slug
	].includes(view)
		? "to"
		: "from";

	return {
		[path]: email
	};
}
