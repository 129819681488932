import React from "react";
import styles from "./add-task-drawer-compound.module.scss";
import { ReactComponent as IconPriority1 } from "static/icons/priority-01-icon.svg";
import { ReactComponent as IconPriority2 } from "static/icons/priority-02-icon.svg";
import { ReactComponent as IconPriority3 } from "static/icons/priority-03-icon.svg";

export const SOURCES = {
	allData: { value: "all", label: "All" },
	myData: { value: "internal_data", label: "My data" },
	candidatesData: { value: "candidate_profile", label: "Candidates data" }
};

export const PRIORITY_OPTIONS = [
	{
		value: "high",
		label: "High",
		icon: <IconPriority1 />,
		active_classname: styles.priorityBtn__red
	},
	{
		value: "medium",
		label: "Medium",
		icon: <IconPriority2 />,
		active_classname: styles.priorityBtn__yellow
	},
	{
		value: "low",
		label: "Low",
		icon: <IconPriority3 />,
		active_classname: styles.priorityBtn__green
	}
];
