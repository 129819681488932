import React from "react";
import { ReactComponent as DownloadIcon } from "static/icons/download-icon.svg";
import styles from "./job-offer-files-list.module.scss";

const JobOfferFilesList = ({ files }) => {
	return files.map(({ name, path }) => (
		<div key={path} className={styles.container}>
			<div className={styles.text}>{name}</div>
			<a className={styles.link} href={path}>
				<DownloadIcon />
			</a>
		</div>
	));
};

export default JobOfferFilesList;
