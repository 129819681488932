import React from "react";
import DatePicker from "modules/home/components/filters/DataPicker.jsx";
import RedirectButton from "modules/home/components/filters/Button.jsx";
import TypeSelect from "modules/home/components/filters/Select.jsx";

const Filters = ({ children }) => {
	return <React.Fragment>{children}</React.Fragment>;
};

Filters.DatePicker = DatePicker;
Filters.RedirectButton = RedirectButton;
Filters.TypeSelect = TypeSelect;

export { Filters };
