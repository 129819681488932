import React from "react";
import Skeleton from "react-loading-skeleton";
import { getRandomNumber } from "config/helpers";

const CustomSkeletonCard = ({
	maxWidth,
	minWidth,
	height,
	style,
	className
}) => {
	let width = getRandomNumber(minWidth, maxWidth);

	return (
		<Skeleton
			className={className}
			width={width}
			height={height}
			style={style}
		/>
	);
};

export default CustomSkeletonCard;
