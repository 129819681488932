import React from "react";
import {
	SmallCheckerStyled,
	SmallCheckerIconStyled
} from "./styled/CommonStyled";

const SmallChecker = ({ text, checked }) => {
	return (
		<SmallCheckerStyled check={checked}>
			<SmallCheckerIconStyled
				className={`${checked ? `icon-check` : `icon-close`}`}
			/>
			<span>{text}</span>
		</SmallCheckerStyled>
	);
};

SmallChecker.defaultProps = {
	checked: true,
	text: "confirmed"
};

export default SmallChecker;
