import React from "react";
import PropTypes from "prop-types";
import StyleButton from "./StyleButton";

const BlockStyleControls = ({ editorState, types, onToggle }) => {
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	return (
		<div className="RichEditor-controls">
			{types.map(type => (
				<StyleButton
					key={type.label}
					active={type.style === blockType}
					icon={type.icon}
					label={type.label}
					onToggle={onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};

BlockStyleControls.propTypes = {
	types: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			style: PropTypes.string.isRequired,
			icon: PropTypes.string
		})
	).isRequired
};

BlockStyleControls.defaultProps = {
	types: [
		{ label: "H2", style: "header-two" },
		{ label: "H4", style: "header-four" },
		{ label: "H5", style: "header-five" },
		{ label: "UL", style: "unordered-list-item", icon: "list-ul" },
		{ label: "OL", style: "ordered-list-item", icon: "list-ol" }
	]
};

export default BlockStyleControls;
