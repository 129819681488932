import { useEffect } from "react";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import {
	MAP_TYPE_COMPONENT,
	MAP_TYPE_OPERATORS,
	TIMESHEETS_FILTER_ID,
	TIMESHEETS_MODULE_ID,
	TIMESHEETS_PAGE_SIZE,
	TYPES
} from "../utils/constants";
import { COLUMNS } from "../utils/timesheetsFilterFields";

export const useInitializeFilterDrawer = () => {
	const { setFilterSetting } = useFilters();

	useEffect(() => {
		setFilterSetting(
			TYPES,
			MAP_TYPE_OPERATORS,
			MAP_TYPE_COMPONENT,
			{},
			null,
			COLUMNS,
			TIMESHEETS_FILTER_ID,
			TIMESHEETS_MODULE_ID,
			true,
			{},
			TIMESHEETS_PAGE_SIZE
		);
	}, []);
};
