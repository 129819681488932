import React from "react";
import styles from "./bid-details-pagination.module.scss";
import { ReactComponent as ArrowLeftIcon } from "static/icons/arrow-left-dark-grey.svg";
import useOpenBidDetailsDrawer from "../../hooks/useOpenBidDetailsDrawer";
import { addQueryParamToPath } from "config/helpers";
import { browserHistory } from "react-router";

export default function BidDetailsPagination({ idNextBid, idPrevBid }) {
	const { setBidId } = useOpenBidDetailsDrawer();

	const handlePrevious = () => {
		setBidId(idPrevBid);

		browserHistory.replace({
			pathname: window.location.pathname,
			search: addQueryParamToPath(window.location.search, "bid_id", idPrevBid)
		});
	};
	const handleNext = () => {
		setBidId(idNextBid);
		browserHistory.replace({
			pathname: window.location.pathname,
			search: addQueryParamToPath(window.location.search, "bid_id", idNextBid)
		});
	};

	return (
		<div className={styles.pagination}>
			<button onClick={handleNext} disabled={!idNextBid}>
				<ArrowLeftIcon />
			</button>
			<button onClick={handlePrevious} disabled={!idPrevBid}>
				<ArrowLeftIcon className={styles.rotate} />
			</button>
		</div>
	);
}
