import { useQuery } from "react-query";
import { GET_LAST_JOB } from "config/api-endpoints";
import { client } from "lib/api-client";

const getRequest = id => {
	return client(GET_LAST_JOB, {
		body: {
			id,
			job_type: "request"
		}
	});
};

export const GET_REQUEST_TO_PUBLISH_KEY = "GET_REQUEST_TO_PUBLISH_KEY";

export const useGetRequest = id => {
	const res = useQuery(GET_REQUEST_TO_PUBLISH_KEY, () => getRequest(id));

	return res;
};
