import React, { useMemo } from "react";
import get from "lodash/get";

import SkeletonComponent from "../components/Skeleton";
import Status from "../components/Status";
import { formatDate } from "common/Functions";
import { CREATION_DATE, VACANCY_NAME, REJECTED, CANCELLED } from "config";
import { ReactComponent as DueDateIcon } from "static/icons/due-date-icon.svg";
import { ReactComponent as StatusIcon } from "static/icons/status-icon.svg";

export const sortBylist = [
	{
		field: CREATION_DATE,
		icon: <DueDateIcon />,
		label: "Date created",
		options: []
	},
	{
		field: VACANCY_NAME,
		icon: <StatusIcon />,
		label: "Vacancy name",
		options: []
	}
];

export const columns = sortedField => [
	{
		Header: "Date created",
		accessor: "created_at",
		isSorted: sortedField === CREATION_DATE
	},
	{
		Header: "Vacancy name",
		accessor: "title",
		isSorted: sortedField === VACANCY_NAME
	},
	{
		Header: "Last stage",
		accessor: "stepper_status"
	},
	{
		Header: "Date reached",
		accessor: "date_reached"
	}
];

export const dataSkeleton = new Array(9).fill({
	created_at: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	title: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	stepper_status: (
		<SkeletonComponent width={60} height={14} borderRadius={10} />
	),
	date_reached: <SkeletonComponent width={60} height={14} borderRadius={10} />
});

export const getData = (isFetching, resolvedData) =>
	useMemo(
		() =>
			isFetching
				? dataSkeleton
				: get(resolvedData, "pokes", []).map(item => {
						const applicationStatus = get(item, "status");
						const status =
							applicationStatus === REJECTED || applicationStatus === CANCELLED
								? "Withdrawn/Rejected"
								: get(item, "last_step.name", "");
						const date_reached = get(item, "last_step.date_reached", 0);
						return {
							created_at: formatDate(item.created_at),
							title: get(item, "vacancy.title"),
							id: get(item, "_id"),
							stepper_status: (
								<Status
									type={status === null ? "N/A" : status.replace("_", " ")}
									isStatic={true}
									staticColor="#347C84"
									staticBackground="#EBF9FC"
								/>
							),
							date_reached: formatDate(date_reached)
						};
				  }),
		[isFetching]
	);
