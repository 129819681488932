import React, { useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import { useQuery } from "react-query";
import _get from "lodash/get";
import { Button } from "common/styled/buttons";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import DocumentRequirementItem from "./components/DocumentRequirementItem";
import EmptySearch from "./components/EmptySearch";
import HelpBanner from "./HelpBanner";
import { Flex, Text, Box } from "rebass";
import Switch from "common/Switch";
import Toaster from "common/Toaster";
import Loader from "common/Loader";
import AddDocumentRequirementModal from "./Modals/AddDocumentModal";
import { RequiredDocumentStageItem } from "./StyledDocManagement";
import { Scrollbars } from "react-custom-scrollbars";

import { client } from "lib/api-client";
import {
	GET_DOCUMENTS,
	MANAGE_DOCUMENTS,
	UPDATE_DOCUMENT_SETTINGS
} from "config/api-endpoints";
import {
	REQUIREMENT_BID,
	REQUIREMENT_CONTRACT,
	REQUIREMENT_TIMESHEET_SUBMISSION,
	CRUD_ACTIONS
} from "config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { initDocumentModal, setSelected } from "./actions/document.actions";

export const getDocumentsManagement = () => {
	return "/settings/document-management";
};
export const getDocumentsManagementPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const getDocuments = () => client(GET_DOCUMENTS, { body: {} });

const manageDocument = props =>
	client(MANAGE_DOCUMENTS, {
		body: props
	});

const updateStageStatus = props =>
	client(UPDATE_DOCUMENT_SETTINGS, {
		body: props
	});

function DocumentManagement({
	initDocumentModal,
	setSelected,
	selectedDocument,
	is_mandatory_doc
}) {
	const [displayBanner, setDisplayBanner] = useState(true);
	const [modalHasStage, setModalHasStage] = useState(false);
	const [stage, setStage] = useState(null);
	const [currentAction, setCurrentAction] = useState(CRUD_ACTIONS.NOACTION);
	const [
		displayAddDocRequirementModal,
		setDisplayAddDocRequirementModal
	] = useState(false);
	const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
		false
	);
	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([{ name: "Settings" }, { name: "Document Request Management" }]);
	}, []);

	const { data, isLoading, isFetching, refetch } = useQuery(
		["getDocuments"],
		getDocuments
	);

	const handleAddDocumentClick = stage => {
		handleOpenModal(false);
		setStage(stage);
		setCurrentAction(CRUD_ACTIONS.ADD);
	};

	const handleAddDocumentRequirementClick = () => {
		handleOpenModal(true);
		setCurrentAction(CRUD_ACTIONS.ADD);
	};

	const handleOpenModal = hasStage => {
		setDisplayAddDocRequirementModal(true);
		setModalHasStage(hasStage);
	};

	const onSubmitDocument = values => {
		values = {
			...values,
			is_required: is_mandatory_doc
		};

		manageDocument({
			...(isUpdateOrDelete() && { id: selectedDocument }),
			tag: (stage && stage.value) || values.stage.value,
			name: values.document,
			is_required: values.is_required,
			action: currentAction
		})
			.then(onManageDocSuccess)
			.catch(onManageDocError)
			.finally(initModalParameters);
	};

	const onManageDocSuccess = () => {
		const actionMessage =
			currentAction === CRUD_ACTIONS.ADD
				? "added"
				: currentAction === CRUD_ACTIONS.UPDATE
				? "updated"
				: "deleted";
		Toaster.success(
			`Status: your document requirement was ${actionMessage} successfully`
		);

		refetch();
	};

	const onManageDocError = () => {
		Toaster.danger(`Status: there was an error trying to alter your document`);
	};

	const isUpdateOrDelete = () => {
		return (
			currentAction === CRUD_ACTIONS.UPDATE ||
			currentAction === CRUD_ACTIONS.DELETE
		);
	};

	const initModalParameters = () => {
		setDisplayAddDocRequirementModal(false);
		if (stage) setStage(null);
		setCurrentAction(CRUD_ACTIONS.NOACTION);
		setSelected(null);
		setDisplayConfirmationModal(false);
	};

	const renderStageDocs = stage => {
		const handleEdit = _id => {
			const { name, is_required } = _get(docs, _id, {});
			initDocumentModal({
				document: name,
				stage,
				is_required
			});
			setSelected(_id);
			setCurrentAction(CRUD_ACTIONS.UPDATE);
			handleOpenModal(false);
		};

		const handleDelete = _id => {
			setSelected(_id);
			setStage(stage);
			setCurrentAction(CRUD_ACTIONS.DELETE);
			setDisplayConfirmationModal(true);
		};

		const docs = data?.temporary?.[stage.value].files_requirement ?? {};
		return !isLoading && Array.isArray(docs) && !docs.length ? (
			<EmptySearch />
		) : (
			Object.keys(docs).map(key => {
				return (
					<DocumentRequirementItem
						key={key}
						{...docs[key]}
						onEdit={handleEdit}
						onDelete={handleDelete}
					/>
				);
			})
		);
	};

	const isActiveStage = stage => {
		return data?.temporary?.[stage].is_active;
	};

	const renderStageCard = (key, stage, renderStageDocs, isActiveStage) => {
		const onActiveStatusChange = val => {
			const errorMessage = `Status: there was an error trying to change the status of the stage`;
			if (!data._id) return Toaster.danger(errorMessage);
			setCurrentAction(CRUD_ACTIONS.CHANGE_STATUS);
			updateStageStatus({ active: val, tag: stage.value, id: data._id })
				.then(refetch)
				.catch(() => {
					Toaster.danger(errorMessage);
				})
				.finally(() => setCurrentAction(CRUD_ACTIONS.NOACTION));
		};
		return (
			<RequiredDocumentStageItem>
				<div>
					<Flex
						p={3}
						className="top-section"
						justifyContent="space-between"
						alignItems="center"
					>
						<Flex flexDirection="column">
							<div className="title">Stage {key}</div>
							<div className="content">{stage.label}</div>
						</Flex>
						<Switch
							checked={isActiveStage(stage.value)}
							onChange={onActiveStatusChange}
						/>
					</Flex>
					<Scrollbars
						autoHide
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={220}
						style={{ zIndex: 1, paddingBottom: "20px" }}
						renderTrackHorizontal={props => (
							<div
								{...props}
								style={{ display: "none" }}
								className="track-horizontal"
							/>
						)}
						renderView={props => (
							<div {...props} style={{ ...props.style, overflowX: "hidden" }} />
						)}
					>
						<Flex p={2} flexDirection="column" className="content-section">
							{renderStageDocs(stage)}
						</Flex>
					</Scrollbars>
					<Flex className="bottom-section">
						<Box sx={{ textAlign: "center" }} width={1} mt={3} pb={2}>
							<button onClick={() => handleAddDocumentClick(stage)}>
								<span>+</span> Add a document
							</button>
						</Box>
					</Flex>
				</div>
			</RequiredDocumentStageItem>
		);
	};

	return (
		<>
			<BodyClassName className="gray-bg container-tiers">
				<div style={{ padding: "3rem" }}>
					{displayBanner && (
						<HelpBanner onClose={() => setDisplayBanner(false)} />
					)}
					<Flex flexDirection="column">
						<Flex mt={4} justifyContent="flex-end">
							{(currentAction === CRUD_ACTIONS.CHANGE_STATUS ||
								isLoading ||
								isFetching) && <Loader />}
							<Button
								mr={0}
								ml={3}
								onClick={() => {
									setCurrentAction(CRUD_ACTIONS.ADD);
									handleAddDocumentRequirementClick();
								}}
								disabledHover
							>
								<i className="icon-save-template" />
								<Text ml={2} display="inline">
									Add a new document requirement
								</Text>
							</Button>
						</Flex>
						<Flex mt={4}>
							<Box width={1 / 3} mr={2}>
								{renderStageCard(
									1,
									REQUIREMENT_BID,
									renderStageDocs,
									isActiveStage
								)}
							</Box>
							<Box width={1 / 3} mx={2}>
								{renderStageCard(
									2,
									REQUIREMENT_CONTRACT,
									renderStageDocs,
									isActiveStage
								)}
							</Box>
							<Box width={1 / 3} ml={2}>
								{renderStageCard(
									3,
									REQUIREMENT_TIMESHEET_SUBMISSION,
									renderStageDocs,
									isActiveStage
								)}
							</Box>
						</Flex>
					</Flex>
					{displayAddDocRequirementModal && (
						<AddDocumentRequirementModal
							hasStage={modalHasStage}
							stageLabel={stage && stage.label}
							onSubmit={onSubmitDocument}
							action={currentAction}
							onClose={initModalParameters}
						/>
					)}
					{displayConfirmationModal && (
						<ConfirmationModal
							key="delete_department_modal"
							onClose={() => setDisplayConfirmationModal(false)}
							active={true}
							modalName="delete_department_modal"
							type={ConfirmationTypes.error}
							title={`Are you sure you would like to delete this document requirement ?`}
							firstButton={{
								action: () => onSubmitDocument({}),
								label: "YES, DELETE.",
								type: "warning"
							}}
							secondButton={{
								action: () => setDisplayConfirmationModal(false),
								label: "NO"
							}}
						/>
					)}
				</div>
			</BodyClassName>
		</>
	);
}

const mapStateToProps = state => ({
	selectedDocument: state.document.selectedDocument,
	is_mandatory_doc: state.document.formFields.is_required
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ initDocumentModal, setSelected }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentManagement);
