import React, { useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQueryCache } from "react-query";
import _orderBy from "lodash/orderBy";
import Select from "../shared/Select";
import s from "./invitation-form.module.scss";
import useInviteToSupplierList from "../api/useInviteToSupplierList";
import toaster from "../../../common/Toaster";
import { supplierHistoryQueryKey } from "../api/useListSupplierInvitations";
import useGetFeatureSettings from "../../company/components/VariableMargins/api/useGetFeatureSettings";
import { viewSupplierQueryKey } from "../api/useViewSupplier";

function InvitationForm({ supplierId, tiers }) {
	const queryCache = useQueryCache();
	const { handleSubmit, control, watch, setValue } = useForm();

	const { data } = useGetFeatureSettings(null, {
		feature_name: "feature_variable_margins"
	});

	const [invite, { isLoading }] = useInviteToSupplierList({
		onSuccess() {
			setValue("tier", undefined);
			setValue("categories", undefined);
			queryCache.invalidateQueries(supplierHistoryQueryKey);
			queryCache.invalidateQueries(viewSupplierQueryKey);
			toaster.success("Invitation sent to supplier.");
		}
	});

	const onSubmit = fields => {
		invite({
			id: data?.feature_settings._id,
			tier_destination_id: fields.tier?.value,
			category_ids: fields.categories.map(c => c.value),
			consultancies: [supplierId]
		});
	};

	const orderedTiers = useMemo(
		() =>
			_orderBy(
				tiers?.map(t => ({
					label: t.name,
					value: t._id,
					categories: t.categories
				})),
				["label"],
				["asc"]
			),
		[tiers]
	);
	const tier = watch("tier");

	useEffect(() => {
		setValue("categories", []);
	}, [tier]);

	const categories = tier?.categories?.map(c => ({
		label: c.name,
		value: c._id
	}));

	return (
		<div>
			<h4>Invite to my suppliers</h4>
			<form className={s.form} onSubmit={handleSubmit(onSubmit)}>
				<div>
					<label htmlFor="tier">Tier</label>
					<Controller
						name={"tier"}
						id={"tier"}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								value={value}
								options={orderedTiers}
								onChange={onChange}
								multi={false}
							/>
						)}
					/>
				</div>
				<div>
					<label htmlFor="categories">Categories</label>
					<Controller
						name={"categories"}
						id={"categories"}
						control={control}
						render={({ field: { onChange, value } }) => (
							<Select
								value={value}
								onChange={onChange}
								options={categories}
								multi={true}
								closeOnSelect={false}
							/>
						)}
					/>
				</div>
				<button disabled={isLoading} className={s.submitButton} type={"submit"}>
					{isLoading ? "Inviting..." : "Invite to my suppliers"}
				</button>
			</form>
		</div>
	);
}

export default InvitationForm;
