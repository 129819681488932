import React, { useMemo } from "react";
import Select from "react-select";
import _groupBy from "lodash/groupBy";
import _reduce from "lodash/reduce";
import useGetCategories from "../../../api/useGetCategories";
import "./select.css";

function CategoriesSelect({ onSelect, value }) {
	const { data } = useGetCategories();

	const groupedData = useMemo(
		() =>
			_reduce(
				_groupBy(data, "group_name"),
				(acc, value) => {
					acc.push(
						...value.map(v => ({
							label: v.name,
							value: v._id,
							children: v.children
						}))
					);
					return acc;
				},
				[]
			),
		[data]
	);

	return (
		<Select
			name="category"
			options={groupedData}
			placeholder="Choose a Category"
			onChange={onSelect}
			value={value}
			clearable={false}
		/>
	);
}

export default CategoriesSelect;
