import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { browserHistory } from "react-router";
import MenuWithCTA from "common/MenuWithCTA";
import { getVacancyProfileRoute } from "modules/job/components";
import FormField from "common/FormField";
import {
	CTA,
	CTAIcon,
	FloatedBtn,
	BtnContainer,
	InfosText
} from "../styles/StyledInterview";
import { TEXTAREA_MAX_LENGTH_500 } from "config";
import { OutlineButton } from "common/styled/buttons";

const PokeStep = props => {
	const {
		handleSubmit,
		vacancies = [],
		vacancyId,
		changeReduxForm,
		changeSelectedVacancy,
		onSubmit,
		handleBackStep,
		selectedVacancy,
		enableAdditionalComment,
		setAdditionalComment,
		additionalComment
	} = props;

	const vacanciesWithLabel = vacancies.map(vacancy => {
		return {
			value: vacancy._id,
			label: vacancy.title,
			_id: vacancy._id
		};
	});

	const defaultVacancy = vacanciesWithLabel.find(vacancy => {
		return vacancy.value === vacancyId;
	});

	useEffect(() => {
		if (defaultVacancy) {
			changeReduxForm("vacancy", defaultVacancy);
			changeSelectedVacancy(defaultVacancy);
		}
	}, [vacancyId]);
	const selectVacancy = vacancy => {
		changeSelectedVacancy(vacancy);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InfosText>
				An email (with a link to the selected vacancy) will be sent to the poked
				candidate.
				{enableAdditionalComment &&
					` Feel free to add any relevant info, using the “Additional
				Comment” box below.`}{" "}
				You will be able to preview the email content before finalising the
				poke.
			</InfosText>
			<Field
				label="Vacancy"
				name="vacancy"
				type="select"
				options={vacanciesWithLabel}
				value={selectedVacancy}
				defaultValue={defaultVacancy || ""}
				onChange={selectVacancy}
				placeholder={"Please select a vacancy"}
				component={MenuWithCTA}
				position={false}
				searchable={true}
				clearable={false}
				emptyText="You have no vacancies"
				cta={() => (
					<CTA
						size={14}
						small
						large
						onClick={() => browserHistory.push(getVacancyProfileRoute(""))}
					>
						Create a new vacancy
						<CTAIcon className="icon-newtab-arrow" />
					</CTA>
				)}
			/>
			{enableAdditionalComment && (
				<FormField
					label="Additional comment"
					type="textarea"
					placeholder={"Message"}
					maxLength={TEXTAREA_MAX_LENGTH_500}
					input={{
						name: "comment",
						value: additionalComment,
						onChange: e => setAdditionalComment(e.target.value),
						onBlur: event => {
							event.preventDefault();
						}
					}}
					meta={{
						touched: false,
						error: false
					}}
					className="textarea-no-resize"
				/>
			)}
			<BtnContainer>
				<FloatedBtn type="primary" bordered>
					Next
				</FloatedBtn>
				<OutlineButton onClick={() => handleBackStep(false)} type="button">
					Cancel
				</OutlineButton>
			</BtnContainer>
		</form>
	);
};

const validate = formProps => {
	const errors = {};

	if (!formProps.vacancy) {
		errors.vacancy = "Please select a vacancy.";
	}
	return errors;
};

export default reduxForm({
	form: "send-poke",
	validate,
	touchOnBlur: false
})(PokeStep);
