import React from "react";

const GroupIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" y="0px" viewBox="0 0 500 500">
		<g>
			<g>
				<circle className="notification-icon" cx="249" cy="218.5" r="27" />
				<path
					className="notification-icon"
					d="M231.8,266h34.6c20.3,0,37,16.7,37,37v23.3H194.8V303C194.8,282.7,211.5,266,231.8,266z"
				/>
			</g>
			<g>
				<g>
					<path
						className="notification-icon-origin"
						d="M112.4,315H187v13c0,2.6,2.1,4.7,4.7,4.7h114.7c2.6,0,4.7-2.1,4.7-4.7v-13h74.6c2.6,0,4.7-2.1,4.7-4.7v-24.7
				c-0.1-24.1-19.6-43.7-43.7-43.8h-36.4c-7.5,0-14.9,1.9-21.4,5.6c-5.6,3.2-10.4,7.5-14.1,12.7c-2.5-0.4-5-0.7-7.5-0.6h-36.4
				c-2.5,0-5,0.2-7.5,0.6c-3.7-5.2-8.5-9.5-14.1-12.7c-6.5-3.7-13.9-5.7-21.4-5.6h-36.4c-24.2,0.1-43.7,19.6-43.8,43.8v24.7
				C107.7,313,109.8,315,112.4,315z M293.6,255.7c5.1-2.9,10.9-4.4,16.8-4.4h36.4c19,0.1,34.3,15.4,34.4,34.4v20h-70.1v-2.8l0,0
				c0-0.4,0-0.7,0-1.1l0,0c0-0.4,0-0.7,0-1.1l0,0c0-0.5,0-1.1,0-1.6l0,0v-0.5l0,0V298l0,0v-1v-0.2v-0.4l0,0v-0.4v-0.2v-0.4l0,0V295
				v-0.2v-0.3l0,0v-0.3l0,0v-0.3v-0.7v-0.3l0,0v-0.4l0,0V292l-0.2-0.8l0,0v-0.3l0,0v-0.3v-0.2v-0.3v-0.4l-0.5-1.3l0,0
				c-4.2-11.2-12.8-20.3-23.9-25C288.5,260.5,290.8,257.9,293.6,255.7z M230.9,269h36.4c17.3,0.1,31.8,12.9,34.1,30l0,0
				c0,0.7,0,1.4,0.2,2.1l0,0c0,0.7,0,1.5,0,2.2v20H196.5V303c0-0.4,0-0.7,0-1.1l0,0c0-0.6,0-1.1,0-1.7v-0.8c0-0.3,0-0.6,0-1l0,0v-1
				l0,0v-0.3l0,0v-0.4l0,0V296l0,0l0.2-0.8l0,0l0.3-1.2l0,0l0.2-0.8l0,0c4.7-14.1,17.8-23.7,32.7-23.7L230.9,269z M117.1,285.7
				c0.1-19,15.4-34.3,34.4-34.4h36.4c5.9,0,11.7,1.5,16.8,4.4c3.4,1.9,6.5,4.5,9.1,7.4c-14.6,6.4-24.7,20.1-26.3,36v1
				c0,0.5,0,0.9,0,1.3l0,0v1.9l0,0v2.3h-70.4V285.7z"
					/>
					<path
						className="notification-icon-origin"
						d="M306.4,333.6H191.7c-3.1,0-5.6-2.5-5.6-5.6V316h-73.7c-3.1,0-5.6-2.5-5.6-5.6v-24.7
				c0.1-24.6,20.1-44.7,44.7-44.7h36.4c0,0,0,0,0,0c7.6,0,15.2,2,21.9,5.8c5.5,3.1,10.3,7.4,14.1,12.5c2.3-0.4,4.7-0.6,7.1-0.6
				c0,0,0,0,0,0h36.4c0,0,0,0,0,0c2.4,0,4.7,0.2,7.1,0.6c3.7-5.1,8.6-9.4,14.1-12.5c6.7-3.8,14.2-5.8,21.9-5.8c0,0,0,0,0,0h36.4
				c24.5,0.1,44.5,20.2,44.6,44.7v24.7c0,3.1-2.5,5.6-5.6,5.6H312V328C312,331.1,309.5,333.6,306.4,333.6z M151.5,242.8
				c-23.6,0.1-42.8,19.3-42.9,42.9v24.7c0,2.1,1.7,3.8,3.8,3.8h75.5V328c0,2.1,1.7,3.8,3.8,3.8h114.7c2.1,0,3.8-1.7,3.8-3.8v-13.9
				h75.5c2.1,0,3.8-1.7,3.8-3.8v-24.7c-0.1-23.5-19.2-42.8-42.8-42.9h-36.4c0,0,0,0,0,0c-7.3,0-14.6,1.9-21,5.5
				c-5.4,3.1-10.2,7.4-13.8,12.4l-0.3,0.5l-0.6-0.1c-2.4-0.4-4.9-0.7-7.4-0.6h-36.4c0,0,0,0,0,0c-2.5,0-4.9,0.2-7.3,0.6l-0.6,0.1
				l-0.3-0.5c-3.6-5.1-8.4-9.4-13.8-12.4c-6.4-3.6-13.6-5.5-21-5.5c0,0,0,0,0,0H151.5z M302.5,324.3H195.6l0-28.5l0.8-2.8
				c4.8-14.5,18.2-24.2,33.3-24.4l0.9-0.5h36.6c17.7,0.1,32.7,13.3,35,30.8c0,0.8,0,1.4,0.2,2l0,0.2V324.3z M197.4,322.4h103.3
				v-21.2c-0.2-0.8-0.2-1.5-0.2-2.3c-2.2-16.5-16.4-29-33.2-29.1h-36.2l-0.9,0.5l-0.2,0c-14.5,0.1-27.3,9.3-31.8,23.1l-0.7,2.6
				V322.4z M382.1,306.6h-71.9v-14.4l-0.6-3.5c-4.1-10.9-12.6-19.9-23.4-24.5l-1-0.4l0.6-0.9c2-3,4.4-5.6,7.2-7.9l0.1-0.1
				c5.2-3,11.2-4.5,17.2-4.5c0,0,0,0,0,0h36.4c19.4,0.1,35.3,15.9,35.3,35.3V306.6z M312,304.8h68.2v-19.1
				c-0.1-18.4-15.1-33.4-33.5-33.5h-36.4c0,0,0,0,0,0c-5.7,0-11.3,1.5-16.3,4.3c-2.3,1.9-4.4,4.1-6.2,6.5
				c10.7,4.9,19.2,14,23.3,25.1l0.5,1.6v1.5l0.2,0.9V304.8z M188.4,306.6h-72.2v-20.9c0.1-19.4,15.9-35.2,35.3-35.3h36.4
				c0,0,0,0,0,0c6,0,12,1.6,17.2,4.5c3.5,2,6.7,4.6,9.4,7.6l0.8,0.9l-1.1,0.5c-14.3,6.2-24.2,19.7-25.7,35.3L188.4,306.6z
				 M118,304.7h68.6v-5.6c1.6-15.9,11.4-29.6,25.7-36.3c-2.3-2.5-5-4.6-8-6.3c-5-2.8-10.6-4.3-16.3-4.3c0,0,0,0,0,0h-36.4
				c-18.4,0.1-33.4,15.1-33.5,33.5V304.7z"
					/>
				</g>
				<g>
					<path
						className="notification-icon-origin"
						d="M169.6,167.7c-18.3,0-33.1,14.8-33.2,33.1s14.8,33.1,33.1,33.2c18.3,0,33.1-14.8,33.2-33.1
				c0-8.8-3.5-17.2-9.7-23.4C186.9,171.2,178.4,167.7,169.6,167.7z M186.4,217.6c-9.3,9.3-24.3,9.3-33.5,0c-9.3-9.3-9.3-24.3,0-33.5
				c9.3-9.3,24.3-9.3,33.5,0c4.4,4.4,6.9,10.5,6.9,16.8C193.4,207.1,190.9,213.1,186.4,217.6z"
					/>
					<path
						className="notification-icon-origin"
						d="M169.6,234.9C169.6,234.9,169.6,234.9,169.6,234.9c-9.1,0-17.7-3.6-24.1-10c-6.4-6.4-10-15-9.9-24.1
				c0-18.8,15.3-34,34-34c0,0,0,0,0.1,0c9,0,17.7,3.6,24,10c6.4,6.4,9.9,15,9.9,24.1c0,9.1-3.6,17.6-10,24.1
				C187.2,231.3,178.7,234.9,169.6,234.9z M169.6,168.6c-17.7,0-32.2,14.4-32.2,32.2c0,8.6,3.3,16.7,9.4,22.8
				c6.1,6.1,14.2,9.4,22.8,9.5c0,0,0,0,0,0c8.6,0,16.7-3.3,22.8-9.4c6.1-6.1,9.4-14.2,9.5-22.8c0-8.6-3.3-16.7-9.4-22.8
				C186.4,172.1,178.1,168.6,169.6,168.6C169.6,168.6,169.6,168.6,169.6,168.6L169.6,168.6C169.6,168.6,169.6,168.6,169.6,168.6z
				 M169.6,225.4c-6.6,0-12.8-2.6-17.4-7.2c-4.7-4.7-7.2-10.8-7.2-17.4c0-6.6,2.6-12.8,7.2-17.4c4.7-4.7,10.8-7.2,17.4-7.2
				c0,0,0,0,0,0c6.6,0,12.8,2.6,17.4,7.2c4.7,4.7,7.2,10.8,7.2,17.4c0,6.5-2.6,12.8-7.2,17.4l0,0
				C182.4,222.9,176.2,225.4,169.6,225.4C169.6,225.4,169.6,225.4,169.6,225.4z M169.6,178C169.6,178,169.6,178,169.6,178
				c-6.1,0-11.8,2.4-16.1,6.7c-4.3,4.3-6.7,10-6.7,16.1c0,6.1,2.4,11.8,6.7,16.1c4.3,4.3,10,6.7,16.1,6.7c0,0,0,0,0,0
				c6.1,0,11.8-2.4,16.1-6.7l0,0c4.2-4.2,6.7-10.1,6.7-16.1c0-6.1-2.4-11.8-6.7-16.1C181.4,180.4,175.7,178,169.6,178z"
					/>
				</g>
				<g>
					<path
						className="notification-icon-origin"
						d="M328.6,233.9c18.3,0,33.1-14.8,33.2-33.1c0-18.3-14.8-33.1-33.1-33.2s-33.1,14.8-33.2,33.1
				c0,8.8,3.5,17.2,9.7,23.4C311.3,230.4,319.8,233.9,328.6,233.9z M311.8,184c9.3-9.3,24.3-9.3,33.5,0c9.3,9.3,9.3,24.3,0,33.5
				c-9.3,9.3-24.3,9.3-33.5,0c-4.4-4.4-6.9-10.5-6.9-16.8C304.9,194.5,307.4,188.5,311.8,184z"
					/>
					<path
						className="notification-icon-origin"
						d="M328.6,234.8C328.6,234.8,328.6,234.8,328.6,234.8c-9,0-17.8-3.6-24.1-10c-6.4-6.4-9.9-15-9.9-24.1
				c0-18.8,15.3-34,34-34c0,0,0,0,0,0c18.8,0,34,15.3,34,34.1C362.6,219.6,347.4,234.8,328.6,234.8z M328.6,233L328.6,233
				C328.6,233,328.6,233,328.6,233c17.8,0,32.2-14.4,32.2-32.2c0-17.8-14.4-32.2-32.2-32.2c0,0,0,0,0,0c-17.7,0-32.2,14.4-32.2,32.2
				c0,8.6,3.3,16.7,9.4,22.8C311.8,229.5,320.1,233,328.6,233C328.6,233,328.6,233,328.6,233z M328.6,225.4
				c-6.6,0-12.8-2.6-17.4-7.2c-4.7-4.7-7.2-10.8-7.2-17.4c0-6.5,2.6-12.8,7.2-17.4c4.7-4.7,10.8-7.2,17.4-7.2c0,0,0,0,0,0
				c6.6,0,12.8,2.6,17.4,7.2c4.7,4.7,7.2,10.8,7.2,17.4c0,6.6-2.6,12.8-7.2,17.4C341.4,222.8,335.2,225.4,328.6,225.4
				C328.6,225.4,328.6,225.4,328.6,225.4z M328.6,178C328.6,178,328.6,178,328.6,178c-6.1,0-11.8,2.4-16.1,6.7l0,0
				c-4.2,4.2-6.7,10.1-6.7,16.1c0,6.1,2.4,11.8,6.7,16.1c4.3,4.3,10,6.7,16.1,6.7c0,0,0,0,0,0c6.1,0,11.8-2.4,16.1-6.7
				c4.3-4.3,6.7-10,6.7-16.1c0-6.1-2.4-11.8-6.7-16.1C340.4,180.3,334.7,178,328.6,178z"
					/>
				</g>
				<g>
					<path
						className="notification-icon-origin"
						d="M225.7,195.1c-12.9,12.9-12.9,33.9,0.1,46.8c12.9,12.9,33.9,12.9,46.8-0.1s12.9-33.9-0.1-46.8
				c-6.2-6.2-14.6-9.7-23.4-9.7C240.3,185.3,231.9,188.8,225.7,195.1z M272.8,218.5c0,13.1-10.6,23.7-23.7,23.7
				c-13.1,0-23.7-10.6-23.7-23.7c0-13.1,10.6-23.7,23.7-23.7c6.3,0,12.3,2.5,16.8,6.9C270.3,206.2,272.8,212.2,272.8,218.5z"
					/>
					<path
						className="notification-icon-origin"
						d="M249.1,252.4c-9.1,0-17.6-3.5-24-9.9c-6.4-6.4-10-14.9-10-24c0-9.1,3.5-17.6,9.9-24.1l0,0
				c6.3-6.4,15.1-10,24.1-10c0,0,0,0,0,0c9.1,0,17.6,3.5,24,9.9c6.4,6.4,10,14.9,10,24c0,9.1-3.5,17.6-9.9,24.1
				C266.8,248.9,258.3,252.4,249.1,252.4C249.2,252.4,249.1,252.4,249.1,252.4z M249.1,186.3c-8.5,0-16.8,3.4-22.8,9.5l0,0
				c-6.1,6.1-9.4,14.2-9.4,22.8c0,8.6,3.4,16.7,9.5,22.7c6.1,6.1,14.1,9.4,22.7,9.4c0,0,0,0,0.1,0c8.6,0,16.7-3.4,22.7-9.5
				c6.1-6.1,9.4-14.2,9.4-22.8c0-8.6-3.4-16.7-9.5-22.7C265.8,189.6,257.7,186.3,249.1,186.3C249.1,186.2,249.1,186.2,249.1,186.3z
				 M249.1,243.1C249.1,243.1,249.1,243.1,249.1,243.1c-13.6,0-24.6-11.1-24.6-24.6c0-6.6,2.6-12.8,7.2-17.4
				c4.7-4.6,10.8-7.2,17.4-7.2c0,0,0,0,0,0c6.6,0,12.8,2.6,17.4,7.2c4.6,4.6,7.2,10.9,7.2,17.4C273.7,232.1,262.7,243.1,249.1,243.1
				z M249.1,195.7c-12.6,0-22.8,10.2-22.8,22.8c0,12.6,10.2,22.8,22.8,22.8c0,0,0,0,0,0c12.6,0,22.8-10.2,22.8-22.8v0
				c0-6-2.4-11.9-6.7-16.1C260.9,198.1,255.2,195.7,249.1,195.7C249.1,195.7,249.1,195.7,249.1,195.7z"
					/>
				</g>
			</g>
		</g>
	</svg>
);
export default GroupIcon;
