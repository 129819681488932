import styled from "styled-components";
import { colors } from "config/styles";
export const Notice = styled.div`
	cursor: pointer;
	margin-right: 5px;
	float: right;
	user-select: none;
	position: relative;
	.notif-bubble {
		background-color: ${colors.primaryColor};
		color: ${colors.white};
		font-size: 11px;
		font-weight: 400;
		width: 18px;
		height: 18px;
		padding: 3px 0px;
		border-radius: 50%;
		display: block;
		position: absolute;
		text-align: center;
		top: ${props => (props.top ? props.top : "-12px")};
		right: ${props => (props.right ? props.right : "12px")};
		left: ${props => props.left && props.left};
		border: ${props => props.border && props.border};
		line-height: initial;
	}
`;
