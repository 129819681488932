import React from "react";
import { Box, Flex, Text } from "rebass";
import { useMutation } from "react-query";
import { ReactComponent as GlobalActionIcon } from "static/icons/arrow-right-icon.svg";

import EditMultipleWorkTime from "../EditBox/EditMultipleWorkTime";
import EditMultipleExpenses from "../EditBox/EditMultipleExpense";
import { connect } from "react-redux";
import {
	documentUploaded,
	updateAmounts,
	resetCurrentDocumentMultiEditMode
} from "../../actions";
import toaster from "common/Toaster";
import { client } from "lib/api-client";
import { COMPUTE_TIMESHEET } from "config/api-endpoints";
import { get } from "lodash";

const computeTimesheet = ({ data }) =>
	client(COMPUTE_TIMESHEET, { body: data });

const MultiAction = ({
	itemSelected,
	disabled,
	item,
	month,
	list,
	amount,
	timesheetId,
	isClient,
	hasTag,
	getInitialVisibleMonth,
	cancelEdit,
	currentDocument,
	isExpense,
	isWorkTime,
	isOverTime,
	disputedWorkTime,
	disputedExpense,
	setDisputedWorkTime,
	setDisputedExpense,
	isAllElementHadChecked,
	dispatch,
	type,
	canApproveTags,
	reportingSettings
}) => {
	const [onComputeTimesheet] = useMutation(computeTimesheet, {
		onSuccess: (data, { payload }) => {
			const mappedIDS = data.dispute_items.expenses.edited.map(
				({ _id }) => _id
			);

			const mappedDiputedExpenseIds = disputedExpense.map(
				({ expense_id }) => expense_id
			);
			const disputedItem = [...new Set(isAllElementHadChecked)].map(id => {
				const index = mappedDiputedExpenseIds.indexOf(id);
				const listIndex = list.map(({ _id }) => _id).indexOf(id);
				const amount =
					data.dispute_items.expenses.edited[mappedIDS.indexOf(id)].amount;
				if (index >= 0) {
					return {
						...disputedExpense[index],
						...payload,
						price: amount,
						status: "edited"
					};
				}
				const { date, description, document, price, tag } = list[listIndex];

				return {
					expense_id: id,
					...{ date, description, document, price, tag },
					...payload,
					price: amount,
					status: "edited"
				};
			});

			const removeDuplicateItem = disputedExpense.filter(
				({ expense_id }) => isAllElementHadChecked.indexOf(expense_id) < 0
			);
			const result = [...disputedItem, ...removeDuplicateItem];

			setDisputedExpense(result);
			dispatch(resetCurrentDocumentMultiEditMode());

			dispatch(updateAmounts(data));
			cancelEdit();
		},
		onError: error => {
			toaster.danger(get(error, "detail.name", get(error, "title")));
		}
	});

	return (
		<Box className="multi-action">
			<Flex
				sx={{
					gridArea: "label"
				}}
				alignItems="center"
			>
				<GlobalActionIcon />
				<Text
					ml="12px"
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "14px",
						lineHeight: "17px"
					}}
				>
					Dispute the{" "}
					<Text
						sx={{
							color: "#FD6950",
							display: "inline-block"
						}}
					>
						{itemSelected}
					</Text>{" "}
					selected entries
				</Text>
			</Flex>
			{(isWorkTime || isOverTime) && (
				<EditMultipleWorkTime
					disabled={disabled}
					item={item}
					month={month}
					amount={amount}
					timesheetId={timesheetId}
					isClient={isClient}
					hasTag={hasTag}
					getInitialVisibleMonth={getInitialVisibleMonth}
					cancelEdit={cancelEdit}
					currentDocument={currentDocument}
					isExpense={isExpense}
					isWorkTime={isWorkTime}
					isOverTime={isOverTime}
					isAllElementHadChecked={isAllElementHadChecked}
					disputedWorkTime={disputedWorkTime}
					setDisputedWorkTime={setDisputedWorkTime}
					list={list}
					type={type}
					itemSelected={itemSelected}
					canApproveTags={canApproveTags}
				/>
			)}
			{isExpense && (
				<EditMultipleExpenses
					disabled={disabled}
					item={item}
					month={month}
					amount={amount}
					timesheetId={timesheetId}
					isClient={isClient}
					hasTag={hasTag}
					onUploadDone={data => dispatch(documentUploaded(data, false, true))}
					getInitialVisibleMonth={getInitialVisibleMonth}
					cancelEdit={cancelEdit}
					currentDocument={currentDocument}
					isExpense={isExpense}
					isWorkTime={isWorkTime}
					isAllElementHadChecked={isAllElementHadChecked}
					disputedExpense={disputedExpense}
					setDisputedExpense={setDisputedExpense}
					list={list}
					onComputeTimesheet={onComputeTimesheet}
					canApproveTags={canApproveTags}
					reportingSettings={reportingSettings}
				/>
			)}
		</Box>
	);
};

export default React.memo(connect()(MultiAction));
