/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Text, Flex, Button } from "rebass";
import { client } from "lib/api-client";
import { LoaderFull } from "common/Loader";
import { useMutation } from "react-query";
import toaster from "common/Toaster";
import { MANAGE_MISSION_TAGS } from "config/api-endpoints";
import { TagBox } from "./timesheet-tags.style";
import TimesheetTag from "./TimesheetTag";
import { ReactComponent as EmptyAvailableTags } from "static/icons/empty-available-tags-icon.svg";
import { ReactComponent as ArchiveAll } from "static/icons/archive-all-icon.svg";
import { ReactComponent as ClearAll } from "static/icons/clear-icon.svg";
import get from "lodash/get";

const manageMissionTags = data =>
	client(MANAGE_MISSION_TAGS, {
		body: {
			...data
		}
	});

const AvailableTags = ({ tags, companyID, fetchFeatureSetting, allTags }) => {
	const [selectedTags, setSelectedTags] = useState([]);
	const [isEdit, setIsEdit] = useState(false);

	const [mutate, { isLoading: mutationLoading }] = useMutation(
		manageMissionTags,
		{
			onSuccess: (_, { action }) => {
				toaster.success(`Timesheet tags has successfully been ${action}d.`);
				fetchFeatureSetting();
			},
			onError: error => {
				toaster.danger(get(error, "detail.name", get(error, "title")));
			}
		}
	);
	const onArchiveTags = (tags, isResetSelectedTags = false) => {
		mutate({
			action: "archive",
			company_id: companyID,
			tags
		});
		isResetSelectedTags && setSelectedTags([]);
	};
	const onUpdateTags = tags =>
		mutate({
			action: "update",
			company_id: companyID,
			tags
		});

	return (
		<React.Fragment>
			<LoaderFull loading={mutationLoading} />

			<Flex my={24} alignItems="center" justifyContent="space-between">
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "16px",
						lineHeight: "34px",
						color: "#6E7191",
						textTransform: "uppercase"
					}}
				>
					Available tags
				</Text>
				{selectedTags.length > 0 && (
					<Flex alignItems="center">
						<Flex>
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: "14px",
									color: "#A0A3BD"
								}}
							>
								{selectedTags.length} selected
							</Text>
							<Button
								sx={{
									border: "none",
									background: "transparent",
									padding: 0,
									display: "flex",
									alignItems: "center"
								}}
								onClick={() => setSelectedTags([])}
								disabled={mutationLoading}
								mx={22}
							>
								<ClearAll />
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "14px",
										color: "#FD6950",
										marginLeft: "10px"
									}}
								>
									clear
								</Text>
							</Button>
						</Flex>

						<button
							onClick={() => onArchiveTags(selectedTags, true)}
							style={{
								alignItems: "center",
								display: "flex",
								background: "#E83131",
								border: "none",
								borderRadius: "4px",
								padding: "10px 15px"
							}}
							disabled={mutationLoading}
						>
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: "13px",
									color: "#FFFFFF",
									marginLeft: "10px"
								}}
							>
								Archive
							</Text>

							<ArchiveAll style={{ marginLeft: "5px" }} />
						</button>
					</Flex>
				)}
			</Flex>

			<TagBox minHeight={100}>
				{tags.length === 0 ? (
					<Flex
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
					>
						<Text
							mb={16}
							sx={{
								fontFamily: "BasierCircle",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "16px",
								lineHeight: "34px",
								color: "#A0A3BD"
							}}
						>
							No available tags
						</Text>
						<EmptyAvailableTags />
					</Flex>
				) : (
					tags.map((tag, index) => (
						<TimesheetTag
							onArchiveTags={onArchiveTags}
							onUpdateTags={onUpdateTags}
							key={tag}
							tag={tag}
							index={index}
							setSelectedTags={setSelectedTags}
							selectedTags={selectedTags}
							allTags={allTags}
							isEdit={isEdit}
							setIsEdit={setIsEdit}
							mutationLoading={mutationLoading}
						/>
					))
				)}
			</TagBox>
		</React.Fragment>
	);
};

export default AvailableTags;
