import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import _ from "lodash";
import CVUploader from "./CVUploader";
import { getCategories, getFunctions } from "modules/job/actions";
import { selectCategories, selectFunctions } from "modules/job/selectors";
import toaster from "common/Toaster";
import { UploadUserCV } from "../../actions";

export class CVUploaderContainer extends Component {
	componentDidMount() {
		const { categories, getCategories } = this.props;
		if (!categories || !categories.length) {
			getCategories();
		}
	}

	render() {
		const {
			categories,
			formFields,
			changeReduxForm,
			showError,
			goBack,
			UploadUserCV,
			getFunctions,
			functions
		} = this.props;

		const subcategories = _.get(
			_.find(categories, { name: _.get(formFields, "category.label") }),
			"children",
			[]
		);

		return (
			<CVUploader
				goBack={goBack}
				categories={categories}
				functions={functions}
				subcategories={subcategories}
				formFields={formFields}
				showError={showError}
				changeReduxForm={changeReduxForm}
				getFunctions={getFunctions}
				UploadUserCV={UploadUserCV}
			/>
		);
	}
}

const selector = formValueSelector("cv-uploader");

const mapStateToProps = state => ({
	formFields: selector(
		state,
		"category",
		"subcategory",
		"file",
		"function",
		"seniority"
	),
	categories: selectCategories(state),
	functions: selectFunctions(state)
});

const mapDispatchToProps = dispatch => ({
	getCategories: () => dispatch(getCategories()),
	getFunctions: payload => dispatch(getFunctions(payload)),
	UploadUserCV: data => dispatch(UploadUserCV(data)),
	showError: error => toaster.danger(error),
	changeReduxForm: (fieldName, value) =>
		dispatch(change("cv-uploader", fieldName, value))
});

CVUploaderContainer.propTypes = {
	formFields: PropTypes.object.isRequired,
	categories: PropTypes.array.isRequired,
	functions: PropTypes.array.isRequired,
	getCategories: PropTypes.func.isRequired,
	UploadUserCV: PropTypes.func.isRequired,
	showError: PropTypes.func.isRequired,
	changeReduxForm: PropTypes.func.isRequired
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CVUploaderContainer);
