import { LIST_EMAIL_TEMPLATES } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";
import fakeData from "modules/user-onboarding/mockData/email-templates-by-version.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getTemplateList = body => {
	return client(LIST_EMAIL_TEMPLATES, {
		body
	});
};

const MESSAGING_TOOL_TEMPLATES_LIST = "MESSAGING_TOOL_TEMPLATES_LIST";

const useGetTemplates = (body, options) => {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery([MESSAGING_TOOL_TEMPLATES_LIST, body], () => {
			return Promise.resolve(fakeData);
		});
	}

	return useQuery(
		[MESSAGING_TOOL_TEMPLATES_LIST, body],
		() => getTemplateList(body),
		{
			refetchOnWindowFocus: false,
			...options
		}
	);
};

export default useGetTemplates;
