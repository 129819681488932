import { useMutation } from "react-query";
import { INVITE_USER_TO_INTERVIEW } from "config/api-endpoints";
import { client } from "lib/api-client";

const inviteFreelancer = body => {
	return client(INVITE_USER_TO_INTERVIEW, {
		body
	});
};

export const useProposeFreelancerInterview = options => {
	const res = useMutation(inviteFreelancer, options);

	return res;
};
