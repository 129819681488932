import create from "zustand";

const initialState = {
	formValues: {
		template: { value: "" },
		vacancy: { value: "" }
	}
};

const store = set => ({
	...initialState,
	setFormValues: formValues => set(state => ({ ...state, formValues })),
	resetFormValues: () => set(() => initialState)
});

const useFormStoreCandidateEvaluation = create(store);
export default useFormStoreCandidateEvaluation;
