export const getBidIdFromUrl = () => {
	const currentUrl = window.location.href;
	const urlParams = new URLSearchParams(currentUrl);
	return urlParams.get("bid_id");
};

export const getClientResumeRoute = () => {
	return "/user/profile/:id";
};

export const getClientResumePermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			},
			{
				name: "consultancy",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export const getResumePermission = () => {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "consultancy",
				roles: ["admin", "project_manager"]
			},
			{
				name: "permanent",
				roles: ["admin"]
			}
		]
	};
};

export const PROFILE_TAB_ID = "0";
export const PROFILE_TAB_ID_FILTER = "PROFILE_TAB_ID_FILTER";
export const USER_PROFILE = "user_profile";
export const RESUME_TAB_ID = "1";
export const HISTORY_TAB_ID = "2";
export const NOT_FOUND = "N/A";
export const CONSULTANT = "consultancy";
export const TYPE_TAG = "PROFILE_HISTORY_ID";
export const PAGE_SIZE = 8;
export const GET_PROFILE_HISTORY_QUERY_KEY = "GET_PROFILE_HISTORY_QUERY_KEY";
export const PAGE_SIZE_LIST = [32, 24, 16, 8];

export const TABS = [
	{
		id: PROFILE_TAB_ID,
		label: "Profile"
	},
	{
		id: RESUME_TAB_ID,
		label: "Resume (CV)"
	},
	{
		id: HISTORY_TAB_ID,
		label: "History"
	}
];

export const COLUMNS = [
	{
		colId: "date",
		headerName: "Date"
	},
	{
		colId: "user",
		headerName: "User"
	},
	{
		colId: "type",
		headerName: "Type"
	},
	{
		colId: "request",
		headerName: "Request"
	}
];
