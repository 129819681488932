import React, { Component } from "react";
import _get from "lodash/get";
import Modal from "common/modal";
import { Link } from "react-router";
import InviteUser from "../../company/components/InviteUser";
import BUMSecondStep from "./bum/BUMSecondStep";
import Steps from "common/steps";
import { BUSINESS_MANAGER_STEPS, CONSULTANT_STEPS, ADMIN } from "config";
import { EmailTitle, EmailContent } from "./bum/NoListMessage";
import ConsultantStep from "./consultant/ConsultantStep";
import { inviteBusinessUnitManager as inviteUser } from "../redux/actions";
import { getPolicyRoute } from "../../../common/privacyPolicy/components/PolicyContainer";
import { getTermsAndConditionsRoute } from "../../../common/termsAndConditions/components/TermsAndConditionsContainer";

export default class AddUserModal extends Component {
	state = {
		step: 0,
		invitedUser: {}
	};

	componentDidUpdate({ active }) {
		if (!active && this.props.active) {
			const hasUser = !!this.props.user;
			if (hasUser) {
				this.props.initialize("invite-user", this.props.user);
			}
			this.setState({
				step: 0,
				invitedUser: hasUser ? this.props.user : {}
			});
		}
	}

	goToSecondStep = invitedUser => {
		this.setState({
			step: 1,
			invitedUser: { ...this.state.invitedUser, ...invitedUser }
		});
	};

	sendInvitation = ({ value, label } = {}) => {
		const nextState = {
			invitedUser: { ...this.state.invitedUser, group_id: value, name: label }
		};
		const skipStep =
			this.props.isEdit && this.props.email === this.state.invitedUser.email;
		this.setState(
			() => {
				if (!skipStep) nextState.step = 3;
				return { ...nextState };
			},
			() => {
				if (skipStep) {
					// skip invitation step
					return this.goToThirdStep();
				}
			}
		);
	};

	goToDirectlyThirdStep = user => {
		this.setState({
			step: 3,
			invitedUser: { ...this.state.invitedUser, ...user }
		});
	};

	goToThirdStep = e => {
		e && e.preventDefault();
		const { invitedUser } = this.state;
		const { search, offset } = this.props;

		inviteUser(
			{
				...invitedUser,
				role_name: this.props.type
			},
			this.props.isEdit,
			{ search, offset, tag: this.props.tag }
		).then(() =>
			this.setState({
				step: 4
			})
		);
	};

	render() {
		const {
			active,
			toggleModal,
			AuthenticatedUser,
			isManager,
			is_member,
			type,
			isEdit,
			email,
			userBUList,
			BUList,
			ManagerBUList
		} = this.props;
		const { step, invitedUser } = this.state;
		const isAdmin = type === ADMIN;
		const noEmailChange = invitedUser && email === invitedUser.email;
		return (
			<Modal
				modalName="business-unit-manager-modal"
				title={
					step !== 4
						? isAdmin
							? `${isEdit ? "Edit" : "Add"} Business Unit Manager`
							: "Add a Consultant"
						: undefined
				}
				centerTitle={true}
				active={active}
				onClose={toggleModal}
				maxwidth="650px"
				firstButton={
					step !== 4
						? {
								label: "cancel",
								action: toggleModal,
								type: "outlined",
								size: "12"
						  }
						: undefined
				}
				secondButton={
					step !== 4
						? {
								label: step === 3 && !isAdmin ? "add consultant" : "continue",
								form: "invite-form",
								action: () => {},
								type: "primary",
								size: "12"
						  }
						: undefined
				}
				fixed={false}
			>
				<div className="propose-modal-bid">
					{step !== 4 && (
						<Steps
							steps={isAdmin ? BUSINESS_MANAGER_STEPS : CONSULTANT_STEPS}
							activeStep={step}
						/>
					)}
				</div>

				{isAdmin
					? [
							step === 0 && (
								<InviteUser
									isEdit={isEdit}
									email={email}
									phone={this.props.user && this.props.user.phone}
									onInvite={this.goToSecondStep}
									key="email"
								/>
							),
							step === 1 && (
								<BUMSecondStep
									isEdit={isEdit}
									initialize={this.props.initialize}
									key="invite"
									ManagerBUList={ManagerBUList}
									role_name={AuthenticatedUser.role_name}
									invitedUser={invitedUser}
									type={type}
									sendInvitation={this.sendInvitation}
								/>
							)
					  ]
					: step === 0 && (
							<ConsultantStep
								userBUList={userBUList}
								BUList={BUList}
								role_name={AuthenticatedUser.role_name}
								sendInvitation={this.goToDirectlyThirdStep}
								group={AuthenticatedUser.groups[0]}
								is_member={is_member}
								isManager={isManager}
							/>
					  )}

				{step === 3 && (
					<SentEmail
						first_name={invitedUser.first_name}
						user_first_name={AuthenticatedUser.first_name}
						user_last_name={AuthenticatedUser.last_name}
						onClick={this.goToThirdStep}
						isAdmin={isAdmin}
					/>
				)}

				{step === 4 && (
					<div className="invite-success">
						<div className="icon">
							<i className="icon icon-check" />
						</div>

						<h3 className="title">
							{isEdit && noEmailChange
								? `The following Business Unit Manager has successfully been updated:`
								: `${
										isAdmin ? "A Business Unit Manager " : "A consultant "
								  }invitation email has successfully been sent to:`}
						</h3>
						<div className="bid-message">
							<EmailTitle>
								{_get(invitedUser, "first_name", "---")}{" "}
								{_get(invitedUser, "last_name", "---")}
							</EmailTitle>
							<div>
								{(!isEdit || !noEmailChange) &&
									`for security reasons, invitation links expire after 72 hours.`}
							</div>
						</div>
						<button className="btn btn-faded bid-button" onClick={toggleModal}>
							Close window
						</button>
					</div>
				)}
			</Modal>
		);
	}
}

const SentEmail = ({
	first_name,
	user_first_name,
	user_last_name,
	onClick,
	isAdmin
}) => {
	return (
		<form
			className="grey-box"
			id="invite-form"
			onSubmit={onClick}
			style={{ marginBottom: "40px" }}
		>
			<EmailTitle>
				The following e-mail will be sent to your invited{" "}
				{isAdmin ? "Business Unit Manager:" : "consultant:"}
			</EmailTitle>
			<EmailContent>Hello {first_name},</EmailContent>
			<EmailContent>
				{isAdmin
					? "Our company uses wiggli to find missions for our consultants. Just activate your account by clicking on the “JOIN” button below; then start adding your consultants to the platform."
					: "Our company uses wiggli as a source to find new clients and missions. I have created your account on the platform so you can use it to manage your timesheets while on a mission through hireme. Just activate your account by clicking on the “JOIN” button below."}
			</EmailContent>
			(JOIN BUTTON)
			<EmailContent>
				By clicking “join”, your personal information, such as your name, email
				address and job title will be shared with wiggli, you consent to such
				information being collected, held, used and disclosed in accordance with
				Wiggli’s{" "}
				<Link target="_blank" to={getPolicyRoute()} className="link-url">
					Privacy Policy.
				</Link>{" "}
				<br />
				When using the Wiggli platform you must adhere to their{" "}
				<Link
					target="_blank"
					to={getTermsAndConditionsRoute()}
					className="link-url"
				>
					Website T&Cs.
				</Link>
			</EmailContent>
			<EmailContent>
				Please note that you have 72 hours to join the platform using the
				provided invitation before it expires.
			</EmailContent>
			<EmailContent>
				Do not hesitate to contact me in case you need any help or have any
				question.
			</EmailContent>
			<EmailContent>Best regards, </EmailContent>
			<div>
				{user_first_name} {user_last_name}
			</div>
		</form>
	);
};
