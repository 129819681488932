import React from "react";
import styles from "./head.module.scss";
import { IconButton } from "common/IconButton";
import { ReactComponent as CloseIcon } from "static/icons/IconClose-1.svg";

const Head = ({ onClose, title }) => {
	return (
		<div className={styles.modalTitle}>
			<div>{title}</div>
			<IconButton onClick={onClose}>
				<CloseIcon className={styles.closeIcon} />
			</IconButton>
		</div>
	);
};

export default Head;
