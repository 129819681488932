import React from "react";
import PropTypes from "prop-types";

import Actions from "./Actions";

import styles from "./editable-section.module.scss";
import cx from "classnames";

const EditableSection = ({
	children,
	title,
	isEdit,
	onEdit,
	onCancel,
	isLoading,
	hasEdit = true,
	onSave,
	sectionUpdate,
	disable
}) => {
	return (
		<div
			className={cx(styles.container, {
				[styles.sectionUpdated]: sectionUpdate
			})}
		>
			<div
				className={cx(styles.header, {
					[styles.sticky]: isEdit
				})}
			>
				<h3>{title}</h3>
				{hasEdit && (
					<Actions
						isEdit={isEdit}
						onEdit={onEdit}
						onCancel={onCancel}
						isLoading={isLoading}
						onSave={onSave}
						disable={disable}
					/>
				)}
			</div>
			<div className={styles.content}>{children}</div>
		</div>
	);
};
EditableSection.prototype = {
	title: PropTypes.string.isRequired,
	actions: PropTypes.arrayOf(PropTypes.node),
	children: PropTypes.node.isRequired
};

EditableSection.defaultProps = {
	actions: undefined
};
export default EditableSection;
