import React from "react";
import styles from "./bid-actions-select.module.scss";
import { BidActionsSelect, BidPrimaryAction } from ".";
import { CLIENT } from "config";
import { BidActionsManager } from "./utils";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import { useTempDrawerHelpers } from "modules/RequestView/components/drawers/hooks/useTempDrawerHelpers";
import {
	DRAWER_PROPOSE_CONTRACT,
	DRAWER_REJECT_APPLICATION
} from "modules/RequestView/components/drawers/constants";
import useNotesStore from "modules/notes/notes.store";
import { generateId } from "config/helpers";

const BidActions = ({
	bid,
	onProposeInterviewDrawer,
	onAcceptInterviewModal,
	onAcceptContractModal,
	onSignContractAction
}) => {
	const user = useSelector(onlineUserSelector);
	const firstName = _get(bid, "profile_detail.first_name");
	const lastName = _get(bid, "profile_detail.last_name");
	const candidate_name = firstName + " " + lastName;

	const {
		initializeAndOpenDrawer: openRejectApplicationDrawer
	} = useTempDrawerHelpers({
		drawerType: DRAWER_REJECT_APPLICATION
	});

	const {
		initializeAndOpenDrawer: openProposeContractDrawer
	} = useTempDrawerHelpers({
		drawerType: DRAWER_PROPOSE_CONTRACT
	});

	// drawers actions
	const onRejectApplication = () => {
		const contract = !_isArray(_get(bid, "contract"))
			? _get(bid, "contract")
			: null;

		const interview = !_isArray(_get(bid, "interview"))
			? _get(bid, "interview")
			: null;

		const payload = {
			bidId: bid._id,
			contractId: contract?._id,
			interviewId: interview?._id,
			candidate_name,
			_queryCacheProps: { jobId: bid.job._id }
		};
		openRejectApplicationDrawer({ drawerProps: payload });
	};

	const onProposeContract = () => {
		const contract = !_isArray(_get(bid, "contract"))
			? _get(bid, "contract")
			: null;

		const payload = {
			contract,
			bidId: bid._id,
			vmsSetting: _get(bid, "vms_settings"),
			candidateName: candidate_name,
			proposedAmount: _get(bid, "proposed_amount"),
			reportingSetting: _get(bid, "reporting_settings"),
			_queryCacheProps: { jobId: _get(bid, "job._id") }
		};
		openProposeContractDrawer({ drawerProps: payload });
	};

	const { addNote } = useNotesStore(({ addNote }) => ({ addNote }));

	const onAddNote = () => {
		addNote({
			type: "BID_NOTE",
			id: generateId(),
			payload: {
				candidate: {
					label:
						_get(bid, "profile_detail.first_name") +
						" " +
						_get(bid, "profile_detail.last_name"),
					profile_id: bid.user_id
				},
				requestId: _get(bid, "job._id")
			}
		});
	};

	const bidActionsManager = new BidActionsManager({
		bidId: bid?._id,
		interview: _get(bid, "interview", {}),
		contract: _get(bid, "contract", {}),
		canMakeProposal: _get(bid, "can_make_proposal"),
		userType: CLIENT,
		bidStatus: bid?.status,
		companyId: user.active_company || "",
		userRole: user.role_name,
		user,
		onRejectApplication,
		onProposeContract,
		onAddNote,
		onProposeInterviewDrawer,
		onAcceptInterviewModal,
		onAcceptContractModal,
		onSignContractAction
	});

	return (
		<div className={styles.actions}>
			<BidPrimaryAction bidActionsManager={bidActionsManager} />
			<BidActionsSelect bidActionsManager={bidActionsManager} />
		</div>
	);
};

export default BidActions;
