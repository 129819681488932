export const LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS";
export const WRITE_NOTIFICATIONS = "WRITE_NOTIFICATIONS";
export const WRITE_LATEST_NOTIFICATIONS = "WRITE_LATEST_NOTIFICATIONS";
export const WRITE_NOTIFICATION = "WRITE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const TOGGLE_NOTIFICATIONS_DROPDOWN = "TOGGLE_NOTIFICATIONS_DROPDOWN";
export const SWITCH_VIEW = "SWITCH_VIEW";
export const WINDOW_FOCUSED = "WINDOW_FOCUSED";
export const WINDOW_BLURRED = "WINDOW_BLURRED";
export const WRITE_NOTIFICATION_COUNT = "WRITE_NOTIFICATION_COUNT";
export const NOTIFICATIONS_START_FETCHING = "NOTIFICATIONS_START_FETCHING";
export const NOTIFICATIONS_STOP_FETCHING = "NOTIFICATIONS_STOP_FETCHING";
export const WRITE_NOTIFICATION_SETTINGS = "WRITE_NOTIFICATION_SETTINGS";
export const TOGGLE_IN_ACTION = "TOGGLE_IN_ACTION";
export const CHANGE_POKABILITY_ENDPOINT = "CHANGE_POKABILITY_ENDPOINT";
