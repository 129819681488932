import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { HTTP_UPDATE_COMPANY_ADRESSE } from "config/api-endpoints";

function updateCompanyAddress(body) {
	return client(HTTP_UPDATE_COMPANY_ADRESSE, { body });
}

function useUpdateCompanyAddress(config) {
	return useMutation(updateCompanyAddress, config);
}

export default useUpdateCompanyAddress;
