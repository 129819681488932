import React from "react";
import { stringify } from "query-string";
import Table from "modules/home/components/table/Table";
import { PROACTIVE_APPLICATIONS_TABLE_DEFINITION } from "modules/home/config/constants";
import { historyPush } from "config/helpers";
import { CANDIDATE_TABS, PROFILE_TABS } from "modules/home/config";

const ProactiveApplicationsTable = ({ onViewAllClick, applicationsList }) => {
	const handleRowClick = row => {
		const params = {
			tab: CANDIDATE_TABS.PROFILE,
			profileTab: PROFILE_TABS.CANDIDATE_PROFILE
		};

		const stringified = stringify(params);

		historyPush(`/permanent/view/${row.id}?${stringified}`);
	};

	return (
		<Table
			data={applicationsList}
			columns={PROACTIVE_APPLICATIONS_TABLE_DEFINITION}
			onRowClick={handleRowClick}
			handleViewAll={onViewAllClick}
		/>
	);
};

export default ProactiveApplicationsTable;
