import React from "react";
import styled from "styled-components";
import cx from "classnames";
import styles from "./checkbox.module.scss";

const Checkbox = ({
	id,
	checked,
	onChange,
	disabled,
	label,
	labelClassName
}) => (
	<CheckboxContainer disabled={disabled}>
		<div
			className="checkbox checkbox-primary"
			onClick={e => e.stopPropagation()}
		>
			<div
				className="input-box"
				style={{
					width: "100%",
					display: "block"
				}}
			>
				<input
					type="checkbox"
					className={cx("styled", { [styles.checkboxDisabled]: disabled })}
					id={id}
					name={id}
					checked={checked}
					onChange={onChange}
					disabled={disabled}
				/>
				<label className={cx(labelClassName, styles.label)} htmlFor={id}>
					{label}
				</label>
			</div>
		</div>
	</CheckboxContainer>
);

export default Checkbox;

const CheckboxContainer = styled.div`
	input[type="checkbox"].styled + label {
		cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	}
	input[type="checkbox"].styled:not(:checked) + label:before {
		background-color: #d9dbe9;
		border: 2px solid #d9dbe9;
		border-radius: 3px;
	}
	input[type="checkbox"].styled {
		margin: 0 !important;
	}
`;
