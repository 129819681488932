import React from "react";
import { HeaderContainer } from "./styles";
import { ReactComponent as LeftArrow } from "static/icons/arrow left.svg";

const Header = ({ children, icon, title, onClose }) => {
	return (
		<HeaderContainer>
			<div className="info">
				<LeftArrow className="left-arrow" onClick={onClose} />
				{icon}
				<h5 className="title">{title} </h5>
			</div>
			<div className="actions">{children}</div>
		</HeaderContainer>
	);
};
export default Header;
