import React, { forwardRef } from "react";
import cx from "classnames";
import { ReactComponent as IconSave } from "static/icons/iconSave.svg";
import styles from "./save-button.module.scss";
import LoadingSpinner from "common/LoadingSpinner/LoadingSpinner";

const SaveButton = (
	{
		onClick,
		isLoading,
		isDisabled,
		leftIcon = <IconSave className={styles.iconSave} />,
		rightIcon,
		className,
		text = "save",
		id
	},
	ref
) => {
	return (
		<button
			id={id}
			ref={ref}
			type="submit"
			onClick={onClick}
			className={cx(styles.saveButton, className, {
				[styles.isLoading]: isLoading
			})}
			disabled={isDisabled}
		>
			{isLoading ? (
				<LoadingSpinner />
			) : (
				<>
					{leftIcon ?? leftIcon}
					<span>{text}</span>
					{rightIcon ?? rightIcon}
				</>
			)}
		</button>
	);
};
export default forwardRef(SaveButton);
