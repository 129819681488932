import React from "react";
import _get from "lodash/get";
import FieldError from "./FieldError";
import { Controller } from "react-hook-form";
import { EVALUATION_SCORES_CARD_ITEMS } from "../constants";
import EvaluationScoreButton from "./EvaluationScoreButton";
import styles from "../evaluation-template-render-drawer.module.scss";

export default function ControlledScoreCardField({
	name,
	errors,
	element,
	control
}) {
	const { items, is_required } = element;

	return (
		<div className={styles.scoreCardFieldContainer}>
			<div className={styles.scoreCardContainer}>
				{items.map((sub_element, index) => {
					return (
						<div key={index} className={styles.scoreCardItem}>
							<div>
								<p className={styles.scoreCardItemTitle}>
									{sub_element.title}
									{is_required && <span className={styles.required}>*</span>}
								</p>
								<FieldError error={_get(errors, `${name}.${index}`)} />
							</div>
							<Controller
								control={control}
								name={`${name}.${index}`}
								defaultValue={sub_element?.value || ""}
								rules={is_required ? { required: "Please add score" } : {}}
								render={({ field: { onChange, value } }) => {
									return (
										<div className={styles.scoreRatingContainer}>
											{EVALUATION_SCORES_CARD_ITEMS.map(item => {
												const isActive = item.value === value;
												const onClick = () => {
													onChange(!isActive ? item.value : "");
												};
												return (
													<EvaluationScoreButton
														key={item.value}
														onClick={onClick}
														className={{
															[styles.scoreRatingBtn]: true,
															[item.active_classname]: isActive
														}}
													>
														{item.icon}
													</EvaluationScoreButton>
												);
											})}
										</div>
									);
								}}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}
