import SearchParams from "common/SearchParams";
import React from "react";
import classnames from "./filter-box.module.scss";

const FilterBox = ({
	searchKeyword,
	setSearchKeyword,
	isDescriptionSearch,
	setIsDescriptionSearch,
	isTitleSearch,
	setIsTitleSearch
}) => {
	return (
		<div className={classnames.filterBoxContainer}>
			<SearchParams
				searchKeyword={searchKeyword}
				setSearchKeyword={setSearchKeyword}
				isDescriptionSearch={isDescriptionSearch}
				setIsDescriptionSearch={setIsDescriptionSearch}
				isTitleSearch={isTitleSearch}
				setIsTitleSearch={setIsTitleSearch}
			/>
		</div>
	);
};

export default FilterBox;
