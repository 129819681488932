import React, { useCallback, useEffect } from "react";
import Pagination from "../../../common/Pagination";

import s from "./search-suppliers.module.scss";
import SupplierCard from "../shared/SupplierCard";
import useSearchSuppliers from "../api/useSearchSuppliers";
import { useToggle } from "react-use";
import CreateNewSupplier from "./CreateNewSupplier/CreateNewSupplier";
import SearchSuppliersFilter from "./filters";
import useFiltersStore from "./filters/searchStore";
import EmptySearch from "./EmptySearch";
import { RefreshIcon } from "@heroicons/react/outline";
import useMySuppliersStore from "./filters/mySuppliersStore";
import useRowSelection from "hooks/Selection";
import _get from "lodash/get";
import Checkbox from "common/Checkbox";
import useOpenDrawerSupplier from "modules/consultancy/search/zustand/useOpenDrawerSupplier";
import InvitationDrawer from "modules/consultancy/search/invitationDrawer/InvitationDrawer";
import useInviteSuppliers from "modules/consultancy/search/api/useInviteSuppliers";
import toaster from "common/Toaster";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";

export const getSearchSupplierPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
const LIMIT = 24;
function SupplierSearch({ tag = "search" }) {
	const isSearch = tag === "search";
	const store = isSearch ? useFiltersStore : useMySuppliersStore;
	const { data, isLoading, isFetching } = useSearchSuppliers({ tag, store });
	const { setOffset, offset, reset } = store(state => ({
		offset: state.offset,
		setOffset: state.setOffset,
		reset: state.resetAllFilters
	}));
	const { toggleDrawer } = useOpenDrawerSupplier();
	const [on, toggle] = useToggle(false);
	const suppliers = data?.data ?? [];
	const [mutate, { isLoading: isInviting }] = useInviteSuppliers();
	const [
		selectionState,
		{
			toggleRowSelection,
			toggleAllSelection,
			checkRowSelection,
			getSelectionCount,
			togglePack,
			reset: resetState
		}
	] = useRowSelection({
		limit: LIMIT,
		totalItemsCount: _get(data, "total", 0)
	});

	const selectionBucket = {
		state: selectionState,
		actions: {
			row: toggleRowSelection,
			all: toggleAllSelection,
			pack: togglePack
		},
		helpers: {
			checkRowSelection,
			getSelectionCount,
			resetState
		}
	};

	const isSelectAll = _get(selectionBucket, "state.isSelectAll");

	const atLeastOneIsSelected =
		_get(selectionBucket, "state.selectedRows.length") > 0;
	const totalSelected = _get(selectionBucket, "state.selectedRows.length");

	const toggleAll = ({ target: { checked } }) => {
		if (checked) {
			const suppliers = data.data.map(item => {
				return item.id;
			});
			return selectionBucket.actions.all(suppliers);
		} else {
			return selectionBucket.helpers.resetState();
		}
	};
	const toggleCard = useCallback(
		(e, id) => {
			e.stopPropagation();
			selectionBucket.actions.row(id);
		},
		[selectionBucket.state]
	);
	const handlePageClick = page => {
		return setOffset(page * data?.size ?? 24);
	};
	const submitInvitation = fields => {
		mutate(
			{
				is_search: true,
				tier_destination_name: fields.tier.value,
				category_ids: fields.categories.map(el => el.value),
				consultancies: selectionBucket.state.selectedRows
			},
			{
				onSuccess: () => {
					selectionBucket.helpers.resetState();
					toggleDrawer(false);
					toaster.success("Invitation sent to suppliers");
				},
				onError: () => {
					// toaster.success(err.detail);
					// console.log({err, variables, context})
				}
			}
		);
	};
	const unselect = () => {
		return selectionBucket.helpers.resetState();
	};

	const setItems = useBreadCrumbs(state => state.setItems);

	useEffect(() => {
		setItems([{ name: "Suppliers" }]);
	}, []);

	return (
		<div className={s.root}>
			<div className={s.ctaContainer}>
				<div className={s.heading}>
					<h4>Search criteria</h4>
					<div>
						<button onClick={reset}>
							<RefreshIcon width={16} height={16} /> Reset filters
						</button>
					</div>
					<div className={s.counCheckbox}>
						<Checkbox
							id="selection"
							checked={isSelectAll}
							onChange={toggleAll}
						/>
						<span>
							{atLeastOneIsSelected
								? `${totalSelected} of ${LIMIT} selected`
								: "Select all"}
						</span>
					</div>

					{totalSelected > 0 && !isSelectAll && (
						<div className={s.counCheckbox}>
							<button onClick={unselect}>Unselect</button>
						</div>
					)}
				</div>
				<div className={s.toRight}>
					<button
						className={s.buttonTransparent}
						onClick={() => toggleDrawer(true)}
						disabled={!atLeastOneIsSelected}
					>
						Invite to my suppliers
					</button>

					{isSearch && (
						<button className={s.button} onClick={() => toggle(true)}>
							Create a new supplier
						</button>
					)}
				</div>
			</div>
			<SearchSuppliersFilter isSearch={isSearch} />

			<div className={s.cardsContainer}>
				{!!suppliers.length &&
					suppliers.map((supplier, index) => (
						<SupplierCard
							key={supplier.id}
							data={supplier}
							isLoading={isLoading}
							selectionBucket={selectionBucket}
							index={index}
							toggleCard={toggleCard}
							offset={_get(data, "offset")}
						/>
					))}
			</div>
			<div className={s.cardsContainer}>
				{!suppliers.length && (
					<EmptySearch isLoading={isLoading || isFetching} />
				)}
			</div>
			{suppliers.length ? (
				<div className={s.paginationContainer}>
					<Pagination
						handlePageClick={handlePageClick}
						size={data?.size}
						total={data?.total}
						offset={offset}
					/>
				</div>
			) : null}
			<CreateNewSupplier isOpen={on} onClose={() => toggle(false)} />

			<InvitationDrawer
				submitInvitation={submitInvitation}
				isInviting={isInviting}
			/>
		</div>
	);
}

export default SupplierSearch;
