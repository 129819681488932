import create from "zustand";
const initialState = {
	tasksType: "myTasks",
	tasksStartDate: "",
	tasksEndDate: "",
	totalTasks: "",
	isSettedDate: false,
	tasksRecepientType: { label: "All", value: "" }
};
const useTasksStore = create(set => ({
	...initialState,
	setItemTasks: (key, value) => set({ [key]: value }),
	reset: () => set({ ...initialState })
}));

export default useTasksStore;
