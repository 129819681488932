import React from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import get from "lodash/get";
import { SelectContainer } from "./style";
import { client } from "lib/api-client";
import { FILTER_SETTINGS_ENDPOINT } from "config/api-endpoints";
import { LIST_NOTES_TYPES } from "config";
import { ReactComponent as ErrorIcon } from "common/react-hook-form-components/icons/elements-icons-error.svg";

const getNotesType = () =>
	client(FILTER_SETTINGS_ENDPOINT, {
		body: {
			tag: LIST_NOTES_TYPES
		}
	});

export default function NoteType({
	label,
	onChange,
	placeholder = "Choose note type",
	value,
	name,
	error,
	clearable = false
}) {
	const { data } = useQuery(["notes-type"], getNotesType);

	return (
		<SelectContainer className="note_type" error={Boolean(error)}>
			{label && <label className="select-label">{label}</label>}
			<Select
				clearable={clearable}
				options={get(data, "notes_type", [])}
				onChange={onChange}
				value={value}
				name={name}
				placeholder={placeholder}
			/>
			{error && (
				<span className="error">
					<ErrorIcon />
					<span className="text">{error.message}</span>
				</span>
			)}
		</SelectContainer>
	);
}
