import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { VIEW_PROFILE } from "config/api-endpoints";
import { USER_PROFILE } from "../utils/constants";

const getTempProfile = (id, fromBid) =>
	client(VIEW_PROFILE, {
		body: { id, tag: fromBid ? undefined : USER_PROFILE }
	});

const useGetTempProfile = (id, fromBid) => {
	return useQuery([id, fromBid], getTempProfile, {
		refetchOnWindowFocus: false,
		enabled: !!id,
		retry: true
	});
};

export default useGetTempProfile;
