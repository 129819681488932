import React, { useState, useRef, useEffect } from "react";
import styles from "./create-vacancy-module.module.scss";
import { CreateVacancyProfileStep } from "./components/CreateVacancyProfileStep";
import { CreateVacancyVacancyStep } from "./components/CreateVacancyVacancyStep";
import { useGetLastCreatedVacancy } from "./api/useGetLastCreatedVacancy";
import {
	PROFILE_STEP,
	QUESTIONNAIRE_STEP,
	VACANCY_STEP
} from "./utils/constants";
import { useGetVacancy } from "modules/vacancy/create-vacancy/api/useGetVacancy";
import { queryCache } from "react-query";
import { GET_VACANCY_QUERY_KEY } from "./api/useGetVacancy";
import { GET_LAST_CREATED_VACANCY_QUERY_KEY } from "./api/useGetLastCreatedVacancy";
import { useSetCreateVacancyBreadcrumb } from "./hooks/useSetCreateVacancyBreadcrumb";
import useLayoutElements from "hooks/useLayoutElements";
import { isMultisiteActive } from "hooks/useIsMultisiteActive";
import { CreateVacancyProfileStepLoading } from "./components/CreateVacancyProfileStepLoading";
import { SkeletonTheme } from "react-loading-skeleton";
import useLanguagesSchema from "./hooks/useLanguagesSchema";
import loadable from "loadable-components";
import useCreateVacancyConditions from "./hooks/useCreateVacancyConditions";
import { v4 as uuid } from "uuid";

const CreateVacancyQuestionnairePreview = loadable(() =>
	import(
		"./components/CreateVacancyQuestionnairePreview/CreateVacancyQuestionnairePreview"
	)
);

const CreateVacancyQuestionnaireStep = loadable(() =>
	import(
		"./components/CreateVacancyQuestionnaireStep/CreateVacancyQuestionnaireStep"
	)
);

export const getNewCreateVacancyRoute = () => "/vacancy/create";

export const getNewVacancyProfileRoute = id => {
	let path = id ? `/${id}` : id === "" ? "" : "(/:id)";
	return `/vacancy/create/profile${path}`;
};

export const getNewVacancyRequestRoute = id => {
	let path = id ? `/${id}` : id === "" ? "" : "(/:id)";
	return `/vacancy/create/request${path}`;
};

export const getNewVacancyQuestionnaireRoute = id => {
	let path = id ? `/${id}` : id === "" ? "" : "(/:id)";
	return `/vacancy/create/questionnaire${path}`;
};

export const getNewVacancyPreviewRoute = id => {
	return `/vacancy/create/preview${id !== undefined ? `/${id}` : `(/:id)`}`;
};

export const getNewCreateVacancyPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export const STEPS = {
	PROFILE_STEP: {
		next: VACANCY_STEP,
		getRoute: getNewVacancyRequestRoute
	},
	VACANCY_STEP: {
		next: QUESTIONNAIRE_STEP,
		prev: PROFILE_STEP,
		getRoute: getNewVacancyQuestionnaireRoute,
		getPrevRoute: getNewVacancyProfileRoute
	},
	QUESTIONNAIRE_STEP: {
		next: "",
		prev: VACANCY_STEP,
		getPrevRoute: getNewVacancyRequestRoute
	}
};

export const getCreateVacancyPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const CreateVacancyModule = ({
	params: { id: vacancyId },
	location: { pathname }
}) => {
	const fetchLastVacancy = useRef(false);
	const [actifStep, setActifStep] = useState(PROFILE_STEP);
	const [questionnaireToPreview, setQuestionnaireToPreview] = useState(null);
	useSetCreateVacancyBreadcrumb();
	const { scrollarea } = useLayoutElements();
	const hasMultiSites = isMultisiteActive();
	const [isConditionsInitialized, setIsConditionsInitialized] = useState(false);

	const { data: vacancyToEdit, isFetching } = useGetVacancy(
		{
			id: vacancyId,
			job_type: "vacancy",
			channels_info: true
		},
		{ enabled: !!vacancyId }
	);

	const { data: lastVacancy } = useGetLastCreatedVacancy({
		enabled: fetchLastVacancy.current
	});

	const { clearQueryLanguage } = useLanguagesSchema();
	const {
		conditions,
		setConditions,
		clearConditions
	} = useCreateVacancyConditions();

	React.useEffect(() => {
		clearConditions();
		if (vacancyToEdit) {
			const oldConditions = vacancyToEdit.questionnaire?.map(item => ({
				id: uuid(),
				...item
			}));

			if (oldConditions) {
				setConditions(oldConditions);
				setIsConditionsInitialized(true);
			} else {
				setIsConditionsInitialized(true);
			}
		}
	}, [vacancyToEdit]);

	useEffect(() => {
		if (pathname.startsWith("/vacancy/create/request")) {
			setActifStep(VACANCY_STEP);
		} else if (pathname.startsWith("/vacancy/create/questionnaire")) {
			setActifStep(QUESTIONNAIRE_STEP);
		}
	}, [pathname]);

	useEffect(() => {
		return () => {
			queryCache.invalidateQueries(GET_LAST_CREATED_VACANCY_QUERY_KEY);
		};
	}, []);

	useEffect(() => {
		scrollarea?.scrollTo({ top: 0, behavior: "smooth" });
	}, [actifStep]);

	const onPreviousClick = () => {
		if (vacancyId) {
			history.pushState(null, null, STEPS[actifStep].getPrevRoute(vacancyId));
		} else {
			history.pushState(null, null, STEPS[actifStep].getPrevRoute(""));
		}
		setActifStep(STEPS[actifStep].prev);
	};

	const onProfileSaveSuccess = () => {
		if (vacancyToEdit) {
			queryCache.invalidateQueries(GET_VACANCY_QUERY_KEY);
			history.pushState(null, null, getNewVacancyRequestRoute(vacancyId));
			clearQueryLanguage();
		} else {
			history.pushState(null, null, "/vacancy/create/request");
			if (fetchLastVacancy.current) {
				queryCache.invalidateQueries(GET_LAST_CREATED_VACANCY_QUERY_KEY);
			} else {
				fetchLastVacancy.current = true;
			}
		}

		setActifStep(VACANCY_STEP);
	};

	const onVacancySaveSuccess = () => {
		if (vacancyToEdit) {
			queryCache.invalidateQueries(GET_VACANCY_QUERY_KEY);
			history.pushState(null, null, getNewVacancyQuestionnaireRoute(vacancyId));
		} else {
			queryCache.invalidateQueries(GET_LAST_CREATED_VACANCY_QUERY_KEY);
			history.pushState(null, null, "/vacancy/create/questionnaire");
		}

		setActifStep(QUESTIONNAIRE_STEP);
	};

	const onNext = () => {
		if (vacancyId) {
			history.pushState(null, null, STEPS[actifStep].getRoute(vacancyId));
		} else {
			history.pushState(null, null, STEPS[actifStep].getRoute(""));
		}
		setActifStep(STEPS[actifStep].next);
	};

	return questionnaireToPreview ? (
		<CreateVacancyQuestionnairePreview
			onClosePreview={() => setQuestionnaireToPreview(null)}
			questionnaire={questionnaireToPreview}
		/>
	) : (
		<SkeletonTheme style={{ borderRadius: "8" }} color="#E5E5E5">
			<div className={styles.container}>
				{actifStep === PROFILE_STEP &&
					(isFetching ? (
						<CreateVacancyProfileStepLoading hasMultiSites={hasMultiSites} />
					) : (
						<CreateVacancyProfileStep
							vacancyToEdit={vacancyToEdit || lastVacancy}
							vacancyId={lastVacancy?._id}
							onSuccess={onProfileSaveSuccess}
							onNext={onNext}
							hasMultiSites={hasMultiSites}
						/>
					))}

				<CreateVacancyVacancyStep
					actif={actifStep === VACANCY_STEP}
					onPreviousClick={onPreviousClick}
					vacancyId={lastVacancy?._id}
					vacancyToEdit={vacancyToEdit}
					onNext={onNext}
					onSuccess={onVacancySaveSuccess}
					idFromUrl={vacancyId}
				/>
				{actifStep === QUESTIONNAIRE_STEP && (
					<CreateVacancyQuestionnaireStep
						onPreviousClick={onPreviousClick}
						vacancyId={lastVacancy?._id}
						vacancyToEdit={vacancyToEdit}
						isEdit={!!vacancyId}
						onPreviewAll={value => setQuestionnaireToPreview(value)}
						conditions={conditions}
						onNext={onNext}
						isVacancyLoading={vacancyId && !isConditionsInitialized}
					/>
				)}
			</div>
		</SkeletonTheme>
	);
};

export default CreateVacancyModule;
