/* eslint-disable react/display-name */
import React, { useState } from "react";
import s from "./profile-tags.module.scss";
import useOnClickOutside from "hooks/useOnClickOutside";
import useListProfileTags from "./api/useListProfileTags";
import { useDebounce } from "react-use";
import { Scrollbars } from "react-custom-scrollbars";
import { notSelectedElements } from "modules/job/selectors";
import { v4 as uuid } from "uuid";

const SelectTags = React.forwardRef(
	(
		{ closeSelect, cv_id, selectedTags, setSelectedTags, style, show },
		popperRef
	) => {
		const [search, setSearch] = useState("");
		const [debouncedValue, setDebouncedValue] = useState("");

		useOnClickOutside(popperRef, () => {
			closeSelect(false);
		});
		const { data, isLoading } = useListProfileTags(null, {
			cv_id: cv_id,
			search: debouncedValue
		});
		useDebounce(
			() => {
				setDebouncedValue(search);
			},
			500,
			[search]
		);

		const addNewTag = val => {
			const id = uuid();
			const newItem = {
				type: "text",
				value: val,
				_id: id
			};
			setSelectedTags([...selectedTags, newItem]);
			closeSelect(false);
		};

		const addToSelectedTags = val => {
			setSelectedTags([...selectedTags, val]);
		};

		return (
			<>
				{data && (
					<div
						className={s.containerSelectTags}
						ref={popperRef}
						style={{
							...style,
							visibility: show ? "visible" : "hidden",
							PointerEvents: show ? "auto" : "none"
						}}
					>
						<div className={s.searchBox}>
							<input
								type="text"
								value={search}
								onChange={e => {
									setSearch(e.target.value);
								}}
							/>
							<button
								onClick={() => addNewTag(search)}
								disabled={search.length === 0}
							>
								{" "}
								Add as new tag
							</button>
						</div>
						{isLoading ? (
							"Loading..."
						) : data.length > 0 ? (
							<ul className={s.listItems}>
								<Scrollbars
									autoHideTimeout={5000}
									autoHideDuration={200}
									autoHeight
									autoHeightMax={200}
									style={{ zIndex: 1, paddingBottom: "20px" }}
									renderTrackHorizontal={props => (
										<div
											{...props}
											style={{ display: "none" }}
											className="track-horizontal"
										/>
									)}
									renderView={props => (
										<div
											{...props}
											style={{ ...props.style, overflowX: "hidden" }}
										/>
									)}
								>
									{notSelectedElements(data, selectedTags, "_id", "_id").map(
										item => {
											return (
												<li
													key={item._id}
													onClick={() => addToSelectedTags(item)}
												>
													<h3>
														{item.value} - <span>({item.type})</span>
													</h3>
													{/* <span>{item.type}</span> */}
												</li>
											);
										}
									)}
								</Scrollbars>
							</ul>
						) : (
							<div>no results found.</div>
						)}
					</div>
				)}
			</>
		);
	}
);

export default SelectTags;
