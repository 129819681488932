import Portal from "common/Portal";
import useOnClickOutside from "hooks/useOnClickOutside";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useToggle } from "react-use";
import { ReactComponent as ChevronDown } from "static/icons/Chevron_Down.svg";
import classes from "./styles.module.scss";
import { ReactComponent as ArrowDown } from "static/icons/ArrowIcon.svg";
import DeleteButton from "../DrawerSection/DeleteButton/DeleteButton";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import get from "lodash/get";
import FilterPills from "../common/FilterPills/FilterPills";
import { CANDIDATES_FILTER } from "../../../utils/constants";
import { formatFilterStore2 } from "common/FilterDrawer/format_utils";
import { renderFilterValues } from "common/FilterDrawer/render_utils";

const getRemainingFilters = (columns, filters, maxLength) => {
	let count = 1;
	let chars = 0;
	let filterArr = [];

	filterArr = filters.map(filter => {
		return formatFilterStore2(columns, filter);
	});

	const filterVals = filterArr.map(filter => {
		const filter_str =
			get(filter, "label", "") +
			" " +
			get(filter, "operator", "") +
			" " +
			renderFilterValues(get(filter, "field", ""), get(filter, "value", ""));
		return filter_str;
	});
	for (let i = 0; i < filterVals.length; i++) {
		chars += filterVals[i].length;
		if (chars < maxLength) {
			count += 1;
		} else {
			break;
		}
	}

	return filterVals.length - count;
};

const FiltersRenderer = ({
	filters,
	id,
	title,
	created_at,
	referenceElement,
	deleteClicked,
	parent_styles,
	data_source,
	canDelete
}) => {
	const DATE_LANG = "en-BE";
	const popperElement = useRef(null);
	const [popperWidth, setPopperWidth] = useState();
	const { getState } = useFilters();
	const { columns } = getState(CANDIDATES_FILTER);
	const [remainingFilters, setRemainingFilters] = useState(0);

	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "bottom-end",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [0, -46]
					}
				}
			]
		}
	);

	useEffect(() => {
		setRemainingFilters(getRemainingFilters(columns, filters, 36));
	}, []);

	useEffect(() => {
		if (
			referenceElement?.current &&
			referenceElement?.current?.clientWidth !== popperWidth
		) {
			setPopperWidth(referenceElement?.current?.clientWidth);
		}
	}, [referenceElement?.current?.clientWidth, referenceElement?.current]);

	const [expanded, setExpanded] = useToggle();
	useOnClickOutside(popperElement, () => {
		setExpanded(false);
	});
	return (
		<div className={styles.root}>
			<div className={classes.filters_container} ref={referenceElement}>
				<FilterPills
					filters={filters}
					data_source={data_source}
					classes={classes}
					parent_styles={parent_styles}
				/>

				<div className={classes.btns_container}>
					{remainingFilters > 0 && (
						<button
							className={classes.pill_btn}
							onClick={e => {
								setExpanded();
								e.stopPropagation();
							}}
						>
							<span>+{remainingFilters}</span>

							<ArrowDown />
						</button>
					)}
					{canDelete && (
						<DeleteButton
							className={parent_styles.delete_btn_container}
							deleteClicked={() => {
								deleteClicked(id);
							}}
						/>
					)}
				</div>
			</div>

			<Portal>
				<div
					className={
						expanded ? `${classes.popper} ${classes.expanded}` : classes.popper
					}
					ref={popperElement}
					style={{
						...styles.popper,
						width: popperWidth + 2,
						zIndex: "9998"
					}}
					{...attributes.popper}
					onClick={e => {
						e.stopPropagation();
					}}
				>
					<div className={classes.title_container}>
						<h5>
							{title}
							{" - "}
							<span className={classes.date}>
								{new Intl.DateTimeFormat(DATE_LANG, {
									day: "numeric"
								}).format(window.moment.unix(created_at)) +
									", " +
									new Intl.DateTimeFormat(DATE_LANG, {
										month: "short",
										year: "numeric"
									}).format(window.moment.unix(created_at))}
							</span>
						</h5>
						<div className={classes.title_btns}>
							<button
								className={classes.close_btn}
								onClick={e => {
									e.stopPropagation();
									setExpanded(false);
								}}
							>
								<ChevronDown />
							</button>
							{canDelete && (
								<DeleteButton
									className={classes.delete_btn_container}
									deleteClicked={c => {
										deleteClicked(c);
										setExpanded(false);
									}}
								/>
							)}
						</div>
					</div>
					<FilterPills
						filters={filters}
						data_source={data_source}
						small
						classes={classes}
						parent_styles={parent_styles}
					/>
				</div>
			</Portal>
		</div>
	);
};

export default FiltersRenderer;
