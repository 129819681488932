import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import s from "./styles.module.scss";
import Select from "modules/consultancy/shared/Select.jsx";
import { getSectorList } from "modules/consultancy/redux/actions";
import useOpenDrawerSupplier from "modules/consultancy/search/zustand/useOpenDrawerSupplier";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _get from "lodash/get";
const options = [
	{
		value: "tier 1",
		label: "Tier 1"
	},
	{
		value: "tier 2",
		label: "Tier 2"
	},
	{
		value: "tier 3",
		label: "Tier 3"
	}
];
const InvitationForm = ({ submitInvitation, isInviting }) => {
	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		mode: "onBlur",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false
	});

	const [categrories, setCategories] = useState([]);
	const { toggleDrawer } = useOpenDrawerSupplier();

	useEffect(() => {
		getSectorList().then(({ sectors }) => {
			setCategories(sectors);
		});
	}, []);

	return (
		<div className={s.containerForm}>
			<h4>Invite to my suppliers</h4>
			<form className={s.form} onSubmit={handleSubmit(submitInvitation)}>
				<div className={s.inputContainer}>
					<div>
						<label htmlFor="tier">Tier</label>
						<Controller
							name={"tier"}
							id={"tier"}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									value={value}
									options={options}
									onChange={onChange}
									multi={false}
								/>
							)}
						/>
						<span className={s.error}>
							{_get(errors, "tier.message") && "Please choose a tier"}
						</span>
					</div>
					<div>
						<label htmlFor="categories">Categories</label>
						<Controller
							name={"categories"}
							id={"categories"}
							control={control}
							render={({ field: { onChange, value } }) => (
								<Select
									value={value}
									onChange={onChange}
									options={categrories}
									multi={true}
									closeOnSelect={false}
								/>
							)}
						/>
						<span className={s.error}>
							{_get(errors, "categories.message") &&
								"Please choose a Categorie"}
						</span>
					</div>
				</div>
				<div className={s.containerButton}>
					<button className={s.btnClose} onClick={() => toggleDrawer(false)}>
						Close
					</button>
					<button
						disabled={isInviting}
						className={s.submitButton}
						type={"submit"}
					>
						{isInviting ? "Inviting..." : "Invite to my suppliers"}
					</button>
				</div>
			</form>
		</div>
	);
};
const schema = yup.object().shape({
	tier: yup.object().required("Please choose a tier"),
	categories: yup
		.array()
		.min(1, "Please choose a categorie")
		.required("Please choose a categorie")
});
export default InvitationForm;
