import React, { useEffect } from "react";
import style from "./create-mission.module.scss";
import { goBack } from "config/helpers";
import useBreadCrumbs from "common/Layouts/breadcrumbs.store";
import { ReactComponent as BackArrow } from "static/icons/IconBack.svg";
import CreateMissionForm from "./components/CreateMissionForm/CreateMissionForm";

const CreateMission = () => {
	const setItems = useBreadCrumbs(state => state.setItems);
	useEffect(() => {
		setItems([
			{ name: "Temporary" },
			{ name: "Missions", path: "/missions" },
			{ name: "Create mission" }
		]);
	}, []);
	return (
		<div className={style.createMissionContainer}>
			<div className={style.headerCreateMission}>
				<div className={style.first}>
					<button onClick={goBack}>
						<BackArrow />
					</button>
					<h3>Create Mission</h3>
				</div>
			</div>
			<div className={style.createMissionForm}>
				<CreateMissionForm />
			</div>
		</div>
	);
};

export const getCreateMissionsRoute = () => {
	return "/missions/create-mission";
};
export const getCreateMissionsRoutePermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export default CreateMission;
