import React from "react";
import EmptyState from "modules/home/components/EmptyState.jsx";
import pendingapplicationsemptystate from "../images/pendingapplicationsemptystate.png";
import { ReactComponent as PlusIcon } from "../../../svg/Plus.svg";
import { historyPush } from "config/helpers";

const PermanentEmptyState = ({ heading, showAction = true }) => {
	const handleClickCreateNewVacancy = () => historyPush("/vacancies/list");
	return (
		<EmptyState
			illustration={<img src={pendingapplicationsemptystate} />}
			heading={heading}
			firstAction={
				showAction && {
					label: "Create a vacancy",
					onClick: handleClickCreateNewVacancy,
					icon: <PlusIcon />
				}
			}
		/>
	);
};

export default PermanentEmptyState;
