import React, { useEffect, useMemo, useState } from "react";
import useDeleteAccountStore from "../PrivacySettings/DeleteAccount/store";
import { logout } from "lib/api-client";
import toaster from "common/Toaster";
import {
	useActiveProcessesCount,
	useDeleteAccount
} from "./useDeleteAccountApi";
import { v4 as uuid } from "uuid";
import { browserHistory } from "react-router";
import globalStyles from "../PrivacySettings/DeleteAccount/styles.module.scss";

const MAX_LENGTH = 100;
export const OTHER = "other";
export const feedbackOptions = [
	{ key: uuid(), label: "I don’t need it anymore" },
	{ key: uuid(), label: "I don’t know what you’re doing with my data" },
	{ key: uuid(), label: "I’m switching to something else" },
	{ key: OTHER, label: "Other" }
];
export function useFeedbackStep() {
	const {
		incrementStep,
		decrementStep,
		setFeedback,
		hasNext,
		feedBack
	} = useDeleteAccountStore();
	const [feedbackTextarea, setFeedbackTextarea] = useState(""); // textarea state

	// sync textarea input with store on component mount
	useEffect(() => {
		if (feedBack && feedBack.key === OTHER) setFeedbackTextarea(feedBack.value);
	}, []);

	// form validation
	const formIsInvalid = useMemo(() => {
		return (
			!hasNext ||
			!feedBack ||
			(feedBack &&
				feedBack.key === OTHER &&
				(!feedbackTextarea || feedbackTextarea.length > MAX_LENGTH))
		);
	}, [hasNext, feedBack, feedbackTextarea]);

	// textarea validation
	const maxLengthExceeded = useMemo(
		() => feedbackTextarea.length > MAX_LENGTH,
		[feedbackTextarea]
	);

	// next / prev buttons action
	const onStepChange = step => {
		// update textarea value into store
		if (feedBack && feedBack.key === OTHER)
			setFeedback({ ...feedBack, value: feedbackTextarea });

		if (step === "next") incrementStep();
		if (step === "prev") decrementStep();
	};

	// radio button on change event
	const onChangeRadioBtn = option => {
		setFeedback({
			...option,
			value: option.key === OTHER ? "" : option.label
		});
		setFeedbackTextarea(""); // clear textarea input
	};

	return {
		formIsInvalid,
		maxLengthExceeded,
		events: { onStepChange, onChangeRadioBtn },
		textarea: { feedbackTextarea, setFeedbackTextarea }
	};
}

export function useDeleteAccountStep(onClose) {
	const [password, setPassword] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [confirmDelete, setConfirmDelete] = useState(false);

	// store and query
	const { feedBack, setModalLoading } = useDeleteAccountStore();
	const { isLoading, isError, data: payload } = useActiveProcessesCount({});

	// ongoing process query has error
	useEffect(() => {
		if (isError) {
			toaster.danger("Something went wrong. Please try again!");
			onClose();
		}
	}, [isError]);

	// display ongoing proccess
	const hasProcessOngoing = useMemo(() => {
		return (
			isLoading ||
			(!isLoading &&
				(payload?.offer_accepted ||
					payload?.offer_sent ||
					payload?.interview_scheduled ||
					payload?.interview_done ||
					payload?.talent_pool))
		);
	}, [payload, isLoading]);

	// form inputs events
	const onFocusPswdInput = () => setErrorMsg("");
	const onChangePswdInput = event => setPassword(event.target.value);
	const onClickCheckboxInput = () => {
		setConfirmDelete(!confirmDelete);
		setPassword("");
		setErrorMsg("");
	};

	// form validation
	const formIsInvalid = useMemo(() => {
		return !confirmDelete || !password || errorMsg;
	}, [errorMsg, password, confirmDelete]);

	// delete account api
	const [mutate] = useDeleteAccount();
	const deleteUserAccount = () => {
		const payload = { password, deletion_raison: feedBack.value };
		setModalLoading(true);
		return mutate(payload, {
			onSuccess: () => {
				onClose();
				logout();
				browserHistory.push("/sign-in");
				toaster.success(
					<ToasterMessage
						heading="Your account has been deleted"
						body="Thank you for using Wiggli"
					/>
				);
			},
			onError: () => {
				setErrorMsg("The password you entered is incorrect!");
			},
			onSettled: () => setModalLoading(false)
		});
	};

	return {
		form: { formIsInvalid, deleteUserAccount },
		state: { errorMsg, password, confirmDelete },
		ongoingProcess: { isLoading, payload, hasProcessOngoing },
		events: { onFocusPswdInput, onClickCheckboxInput, onChangePswdInput }
	};
}

function ToasterMessage({ heading, body }) {
	return (
		<div>
			<span className={globalStyles.toaster__heading}>{heading}</span>
			<span className={globalStyles.toaster__body}>{body}</span>
		</div>
	);
}
