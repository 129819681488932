import React from "react";
import cx from "classnames";
import styles from "./field-error.module.scss";
import { ReactComponent as InfoIcon } from "static/icons/info-icon-red.svg";

const FieldError = ({ error, className }) => {
	return error?.message ? (
		<div className={cx(styles.container, className)}>
			<InfoIcon />
			<div>{error.message}</div>
		</div>
	) : null;
};

export default FieldError;
