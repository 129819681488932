import React from "react";
import { Title, TextTitle } from "../styles/styled";
const InvitationTitle = ({ firstName, lastName }) => {
	return (
		<div>
			<TextTitle>
				{
					"A first interview invitation is attached to this poke. Please reply, using the buttons below."
				}
			</TextTitle>
			<Title>{`Interview Invitation from ${firstName} ${lastName}`}</Title>
		</div>
	);
};

export default InvitationTitle;
