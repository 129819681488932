import React, { useEffect, useMemo } from "react";
import { browserHistory } from "react-router";
import { getPermanentJobDetailsRoute } from "../../index";
import styles from "./permanent-job-navigation.module.scss";
import useJobsStore from "modules/candidate/components/search/store";
import rootStyles from "../JobDetails/permanent-job-details.module.scss";
import { ReactComponent as ChevronLeftIcon } from "static/icons/chevronLeftIcon.svg";
import { ReactComponent as ChevronRightIcon } from "static/icons/chevronRightIcon.svg";

import get from "lodash/get";
import GlobalTooltip from "common/GlobalTooltip";
import "../../../companies/CompanyCard/g-tooltip.scss";
import { usePaginatedQuery } from "react-query";
import { getJobs } from "modules/candidate/components/search/onlineMode";

const TOOLTIP_DELAY = 0.5; // 1/2 second
export default function Navigation({ jobId, jobLang }) {
	const { jobs } = useJobsStore(); // store

	const jobsData = useMemo(() => get(jobs, "data", []), [jobs]);
	const jobIndex = useMemo(
		() =>
			jobsData.findIndex(
				job => job.job_id === jobId && job.version === jobLang
			),
		[jobsData, jobId, jobLang]
	);
	const isFirstJob = useMemo(() => jobIndex === 0, [jobIndex]);
	const isLastJob = useMemo(() => jobIndex === jobsData.length - 1, [
		jobIndex,
		jobsData
	]);

	// ** hooks
	useTriggerSearchJobs({ jobIndex }); // trigger fetch jobs
	const { isFetching } = useSearchJobsQuery(); // fetch jobs

	const handleNavigation = step => {
		if (jobIndex >= 0) {
			const newIndex = step === "prev" ? jobIndex - 1 : jobIndex + 1;
			const newVersion = jobsData[newIndex].version;
			const newJobId = jobsData[newIndex].job_id;
			// redirect
			browserHistory.push(
				"/" + getPermanentJobDetailsRoute(newJobId) + "?job_lang=" + newVersion
			);
		}
	};

	return (
		<div className={styles.container}>
			<button
				onClick={() => browserHistory.push("/jobs/search")}
				className={`${rootStyles.btn} ${rootStyles.btnSecondary}`}
			>
				<ChevronLeftIcon /> Back
			</button>
			<div className={styles.navigation}>
				<GlobalTooltip
					noClassName
					placement="top"
					overlay={"Previous"}
					withWrappingDiv={false}
					mouseEnterDelay={TOOLTIP_DELAY}
					overlayClassName={"g-tool-dark"}
				>
					<button
						onClick={() => handleNavigation("prev")}
						disabled={jobIndex < 0 || isFirstJob || isFetching}
						className={`${rootStyles.btn} ${rootStyles.btnSecondary}`}
					>
						<ChevronLeftIcon />
					</button>
				</GlobalTooltip>
				<GlobalTooltip
					noClassName
					placement="top"
					overlay={"Next"}
					withWrappingDiv={false}
					mouseEnterDelay={TOOLTIP_DELAY}
					overlayClassName={"g-tool-dark"}
				>
					<button
						onClick={() => handleNavigation("next")}
						disabled={jobIndex < 0 || isLastJob || isFetching}
						className={`${rootStyles.btn} ${rootStyles.btnSecondary}`}
					>
						<ChevronRightIcon />
					</button>
				</GlobalTooltip>
			</div>
		</div>
	);
}

function useTriggerSearchJobs({ jobIndex }) {
	const { jobs, filters, offsets, setFilters } = useJobsStore(); // store

	useEffect(() => {
		const lastIndex = jobs && jobs.data.length - 1;
		const totalPages = jobs && Math.ceil(jobs.total / jobs.size) - 1;

		const initialPage = jobs && Math.ceil(jobs.offset / jobs.size);
		const isFirstPage = initialPage === 0;
		const isLastPage = initialPage === totalPages;
		// console.log(jobIndex + " / " + lastIndex, "jobIndex");

		// jobIndex is valid
		if (jobIndex >= 0) {
			const prevOffset = Math.min(...offsets) - jobs.size;
			const nextOffset = Math.max(...offsets) + jobs.size;

			// ** check previous offset is valid and not already added in offsets array
			const hasPrevPage = prevOffset >= 0; //&& !offsets.includes(prevOffset);
			if (!isFirstPage && jobIndex <= 1 && hasPrevPage) {
				// ** trigger search jobs query
				setFilters({ ...filters, offset: prevOffset });
			}

			// ** check next offset is valid and not already added in offsets array
			const hasNextPage = nextOffset <= jobs.total; //&& !offsets.includes(nextOffset);
			if (!isLastPage && jobIndex >= lastIndex - 1 && hasNextPage) {
				// ** trigger search jobs query
				setFilters({ ...filters, offset: nextOffset });
			}
		}
	}, []);
}

function useSearchJobsQuery() {
	const { jobs, filters, offsets, setJobs, setOffsets } = useJobsStore(); // store

	return usePaginatedQuery(["search-jobs", filters], getJobs, {
		enabled: filters?.offset && !offsets.includes(filters.offset),
		refetchOnWindowFocus: false,
		onSuccess: res => {
			if (res.offset >= 0 && !offsets.includes(res.offset)) {
				if (res.offset < Math.min(...offsets)) {
					//console.log("refetch previous page");
					setOffsets([res.offset, ...offsets]);
					setJobs({ ...jobs, data: [...res.data, ...jobs.data] });
				}
				if (res.offset > Math.max(...offsets)) {
					//console.log("refetch next page");
					setOffsets([...offsets, res.offset]);
					setJobs({ ...jobs, data: [...jobs.data, ...res.data] });
				}
			}
		}
	});
}
