import React, { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { browserHistory } from "react-router";
import _get from "lodash/get";
import Tiering from "modules/company/components/VariableMargins/components/categoryTiering";
import useGetFeatureSettings from "modules/company/components/VariableMargins/api/useGetFeatureSettings.js";
import { TabsContainer } from "./Styled";
import useTieringStore from "../store/tiering.store";
import { ReactComponent as AddIcon } from "static/icons/dashed_add_icon.svg";
import Menu, { MenuItem } from "./customMenu";
import useGetCategories from "../../../../consultancy/api/useGetCategories";
import { ReactComponent as ArrowDownIcon } from "static/icons/arrow_down_icon.svg";
import emptyCategory from "static/images/tiering_category_empty.png";

const _displyedTabs = 12;
const CategoriesTabs = ({
	category,
	isTiersManagement,
	register,
	search,
	setSearch,
	handleSubmit,
	setCategory
}) => {
	const [indexTab, setIndexTab] = useState(0);
	const [showedTabs, setShowedTabs] = useState({
		state: "show_less",
		count: _displyedTabs
	});
	const { setItem, visibleCategories } = useTieringStore();

	const { data } = useGetFeatureSettings(
		"@suppliers/get_visible_categories",
		{
			feature_name: "feature_variable_margins",
			search
		},
		{
			onSuccess: res => {
				setItem("visibleCategories", res.feature_settings.visible_categories);
			},
			onError: error => {
				if (error && error.status === 403) {
					browserHistory.push("/");
				}
			}
		}
	);
	useEffect(() => {
		if (category) {
			const categoryIndex = visibleCategories.findIndex(
				item => item._id === category._id
			);
			if (categoryIndex !== -1) {
				setIndexTab(categoryIndex);
				setShowedTabs({
					state: "show_more",
					count: visibleCategories.length
				});
			}
		}
	}, [visibleCategories]);
	const { data: categories = [] } = useGetCategories();

	const excludedCategories = categories?.filter(
		item => visibleCategories.findIndex(elem => elem._id === item._id) === -1
	);

	const handleTabsChange = index => {
		setIndexTab(index);
	};

	const TabsHeader = () => (
		<TabList>
			{_get(data, "feature_settings.visible_categories", [])
				.slice(0, showedTabs.count)
				.map(elem => (
					<Tab key={elem._id}>{elem.name}</Tab>
				))}
			{_get(data, "feature_settings.visible_categories.length", 0) >
				_displyedTabs &&
				showedTabs.state === "show_less" && (
					<span className="hidenTabsCount">
						+
						{_get(data, "feature_settings.visible_categories.length", 0) -
							_displyedTabs}{" "}
					</span>
				)}
			<Menu
				openerNode={
					<div className="menuOpener">
						{" "}
						<AddIcon />{" "}
					</div>
				}
			>
				{({ setOpen }) =>
					excludedCategories.map(elem => (
						<MenuItem
							key={elem}
							onClick={() => {
								setCategory(elem);
								setOpen(false);
							}}
						>
							{" "}
							{elem.name}{" "}
						</MenuItem>
					))
				}
			</Menu>
			{_get(data, "feature_settings.visible_categories.length", 0) >
				_displyedTabs && (
				<button
					className="tabsController"
					onClick={() =>
						setShowedTabs(old => ({
							count:
								old.state === "show_more"
									? _displyedTabs
									: _get(data, "feature_settings.visible_categories.length", 0),
							state: old.state === "show_more" ? "show_less" : "show_more"
						}))
					}
				>
					{showedTabs.state === "show_more" ? "Show less" : "Show more"}
					<ArrowDownIcon
						className={
							showedTabs.state === "show_more" ? "icon active" : "icon"
						}
					/>
				</button>
			)}
		</TabList>
	);
	return (
		<TabsContainer>
			{Boolean(_get(data, "feature_settings.visible_categories.length", 0)) ? (
				<Tabs index={indexTab} onChange={handleTabsChange}>
					<TabPanels>
						{_get(data, "feature_settings.visible_categories", []).map(
							(elem, index) => (
								<TabPanel key={index}>
									{indexTab === index ? (
										<Tiering
											category={category}
											isTiersManagement={isTiersManagement}
											register={register}
											search={search}
											setSearch={setSearch}
											categoryId={elem._id}
											handleSubmit={handleSubmit}
											TabsHeader={<TabsHeader />}
										/>
									) : null}
								</TabPanel>
							)
						)}
					</TabPanels>
				</Tabs>
			) : (
				<div className="emptyStateContainer">
					<div className="box">
						<img className="illustartion" src={emptyCategory} />
						<h4 className="headding">
							Add a category from the select below to get started{" "}
						</h4>
						<Menu
							openerNode={
								<div className="menuOpener">
									Add a category
									<AddIcon />{" "}
								</div>
							}
						>
							{({ setOpen }) =>
								categories.map(elem => (
									<MenuItem
										key={elem}
										onClick={() => {
											setCategory(elem);
											setOpen(false);
										}}
									>
										{" "}
										{elem.name}{" "}
									</MenuItem>
								))
							}
						</Menu>
					</div>
				</div>
			)}
		</TabsContainer>
	);
};

export default CategoriesTabs;
