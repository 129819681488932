import { EditorState, Modifier, AtomicBlockUtils } from "draft-js";

export function createEntity(editorState, entityType, data = {}) {
	const { contentState, selectionState } = getEditorData(editorState);
	const contentStateWithEntity = contentState.createEntity(
		entityType,
		"MUTABLE",
		data
	);
	const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
	const newEditorState = EditorState.set(editorState, {
		currentContent: Modifier.applyEntity(
			contentStateWithEntity,
			selectionState,
			entityKey
		)
	});
	return newEditorState;
}

export function removeEntity(editorState, entityType) {
	const { contentState, selectionState } = getEditorData(editorState);
	const blocks = getSelectedBlocksByType(editorState, entityType);
	if (blocks.length !== 0) {
		let anchorKey;
		let focusKey;
		let anchorOffset;
		let focusOffset;
		blocks.forEach(({ block, start, end }, key) => {
			const blockKey = block.getKey();
			if (key === 0) {
				anchorKey = blockKey;
				anchorOffset = start;
			}
			if (key === blocks.length - 1) {
				focusKey = blockKey;
				focusOffset = end;
			}
		});
		const newContentState = Modifier.applyEntity(
			contentState,
			selectionState.merge({
				anchorKey,
				focusKey,
				anchorOffset,
				focusOffset,
				isBackward: false
			}),
			null
		);
		return EditorState.set(editorState, {
			currentContent: newContentState
		});
	}
}

export function findEntityInSelection(editorState, entityType) {
	const { startKey, startOffset, endOffset } = getEditorData(editorState);
	const entities = getEntitiesByBlockKey(editorState, entityType, startKey);
	if (entities.length === 0) {
		return null;
	}

	let selectedEntity = null;
	entities.forEach(entity => {
		const { blockKey, start, end } = entity;
		if (
			blockKey === startKey &&
			((startOffset > start && startOffset < end) ||
				(endOffset > start && endOffset < end) ||
				(startOffset === start && endOffset === end))
		) {
			selectedEntity = entity;
		}
	});
	return selectedEntity;
}

export function findEntities(entityType, contentBlock, callback, contentState) {
	return contentBlock.findEntityRanges(
		character => entityFilter(character, entityType, contentState),
		callback
	);
}

export function entityFilter(character, entityType, contentState) {
	const entityKey = getEntity(character);
	return (
		entityKey !== null &&
		contentState.getEntity(entityKey).getType() === entityType
	);
}

export function getEntity(character) {
	return character.getEntity();
}

export function getEntities(
	editorState,
	entityType = null,
	selectedEntityKey = null
) {
	const { contentState } = getEditorData(editorState);
	const entities = [];
	contentState.getBlocksAsArray().forEach(block => {
		let selectedEntity = null;
		block.findEntityRanges(
			character => {
				const entityKey = character.getEntity();
				if (entityKey !== null) {
					const entity = contentState.getEntity(entityKey);
					if (!entityType || (entityType && entity.getType() === entityType)) {
						if (
							selectedEntityKey === null ||
							(selectedEntityKey !== null && entityKey === selectedEntityKey)
						) {
							selectedEntity = {
								entityKey,
								blockKey: block.getKey(),
								entity: contentState.getEntity(entityKey)
							};
							return true;
						} else {
							return false;
						}
					}
				}
				return false;
			},
			(start, end) => {
				entities.push({ ...selectedEntity, start, end });
			}
		);
	});
	return entities;
}

export function getEntitiesByBlockKey(
	editorState,
	entityType = null,
	blockKey = null
) {
	return getEntities(editorState, entityType).filter(
		entity => entity.blockKey === blockKey
	);
}

export function getEditorData(editorState) {
	return {
		contentState: editorState.getCurrentContent(),
		inlineStyle: editorState.getCurrentInlineStyle(),
		selectionState: editorState.getSelection(),
		hasFocus: editorState.getSelection().getHasFocus(),
		isCollapsed: editorState.getSelection().isCollapsed(),
		startKey: editorState.getSelection().getStartKey(),
		startOffset: editorState.getSelection().getStartOffset(),
		endKey: editorState.getSelection().getEndKey(),
		endOffset: editorState.getSelection().getEndOffset()
	};
}

export function getSelectedBlocks(editorState) {
	const { contentState, startKey, endKey } = getEditorData(editorState);
	const blocks = [];
	let block = contentState.getBlockForKey(startKey);
	let key = block.getKey();
	while (key !== endKey) {
		blocks.push(block);
		let blockKey = block.getKey();
		if (blockKey === endKey) {
			break;
		} else {
			block = contentState.getBlockAfter(blockKey);
		}
	}
	return blocks;
}

export function getSelectedBlocksByType(editorState, blockType) {
	const {
		contentState,
		startKey,
		endKey,
		startOffset,
		endOffset
	} = getEditorData(editorState);
	const blocks = [];
	getSelectedBlocks(editorState).forEach(block => {
		const blockKey = block.getKey();
		const blockStartOffset = blockKey === startKey ? startOffset : 0;
		const blockEndOffset = blockKey === endKey ? endOffset : block.getLength();
		findEntities(
			blockType,
			block,
			(start, end) => {
				if (
					Math.max(start, blockStartOffset) <= Math.min(end, blockEndOffset)
				) {
					const entityKey = block.getEntityAt(start);
					const text = block.getText().slice(start, end);
					const url = contentState.getEntity(entityKey).getData().url;
					blocks.push({ text, url, block, start, end });
				}
			},
			contentState
		);
	});
	return blocks;
}

export function isSelectedOneBlock(editorState) {
	const { startKey, endKey } = getEditorData(editorState);
	return startKey === endKey;
}

export function getStartBlock(editorState) {
	const { contentState, startKey } = getEditorData(editorState);
	return contentState.getBlockForKey(startKey);
}

export function getStartBlockSelectedText(editorState) {
	const { startOffset, endOffset } = getEditorData(editorState);
	const text = getStartBlock(editorState).getText();
	return isSelectedOneBlock(editorState)
		? text.slice(startOffset, endOffset)
		: text.slice(startOffset);
}

export function extendSelectionByData(editorState, data) {
	const {
		selectionState,
		startKey,
		startOffset,
		endKey,
		endOffset
	} = getEditorData(editorState);
	let anchorKey = startKey;
	let focusKey = endKey;
	let anchorOffset = startOffset;
	let focusOffset = endOffset;
	data.forEach(({ blockKey, start, end }, key) => {
		if (key === 0) {
			anchorKey = blockKey;
			anchorOffset = start;
		}
		if (key === data.length - 1) {
			focusKey = blockKey;
			focusOffset = end;
		}
	});
	const state = Object.assign({}, anchorKey ? { anchorKey } : {}, {
		focusKey,
		anchorOffset,
		focusOffset,
		isBackward: false
	});
	const newSelectionState = selectionState.merge(state);
	return EditorState.acceptSelection(editorState, newSelectionState);
}

export function insertImage(editorState, data = {}) {
	const { contentState } = getEditorData(editorState);
	const newContentState = contentState.createEntity("IMAGE", "IMMUTABLE", data);
	const entityKey = newContentState.getLastCreatedEntityKey();
	const newEditorState = EditorState.set(editorState, {
		currentContent: newContentState
	});
	return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
}

export function getPosition(selected) {
	if (!selected.anchorNode) {
		return;
	}
	const textPosition = selected.anchorNode.parentNode.getBoundingClientRect();
	const containerPosition = document
		.querySelector(".RichEditor")
		.getBoundingClientRect();

	const left = selected.getRangeAt(0).getBoundingClientRect().left;

	return {
		top: textPosition.top - containerPosition.top + textPosition.height + 7,
		left: left - containerPosition.left
	};
}

export function getSelected() {
	var t = "";
	if (window.getSelection) {
		t = window.getSelection();
	} else if (document.getSelection) {
		t = document.getSelection();
	} else if (document.selection) {
		t = document.selection.createRange().text;
	}
	return t;
}
