import { useEffect, useMemo, useState } from "react";
import { useCompaniesList } from "./useCompaniesApi";
import useCompaniesStore from "../store/useCompaniesStore";
import { getFollowedCompaniesRoute } from "../index";
import { browserHistory } from "react-router";
import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import { useRef } from "react";

const LIMIT = 9;
const DEBOUNCE_DELAY = 1000;
export default function useCompaniesQuery() {
	const {
		page,
		setPage,
		offset,
		setOffset,
		searchValue,
		setSearchValue,
		searchQuery,
		setSearchQuery
	} = useCompaniesFilter();

	// companies query
	const { isLoading, isFetching, data: payload, isError } = useCompaniesList({
		offset,
		limit: LIMIT,
		search: searchQuery
	});

	// pagination
	const onPaginate = page => {
		const size = payload?.pagination?.size || 0;
		setOffset(page * size);
	};
	useEffect(() => {
		if (!isLoading && payload?.pagination && payload.pagination.size !== 0) {
			// when page is empty reset pagination
			if (!payload.data.length) setOffset(0);
			else setPage(payload.pagination.offset / payload.pagination.size);
		}
	}, [isLoading, payload]);

	// search input
	const debouncedSearch = useMemo(
		() =>
			_debounce(value => {
				setSearchQuery(value);
				setOffset(0);
			}, DEBOUNCE_DELAY),
		[setSearchQuery]
	);
	const onSearchChange = e => {
		const keywords = e.target.value.trimStart();
		setSearchValue(keywords);
		debouncedSearch(keywords);
	};
	const onSearchClear = () => {
		setSearchValue("");
		setSearchQuery("");
		setOffset(0);
	};

	// display starter page
	const displayEmptyState = useMemo(
		() =>
			!isLoading &&
			payload?.pagination &&
			payload.pagination.total === 0 &&
			!searchQuery
				? true
				: false,
		[isLoading, payload, searchQuery]
	);

	return {
		isError,
		payload,
		isLoading,
		isFetching,
		displayEmptyState,
		search: { searchValue, onSearchChange, onSearchClear },
		pagination: { currentPage: page, onPaginate }
	};
}

// custom hooks
function useFirstRender() {
	const ref = useRef(true);
	const isFirstRender = ref.current;
	ref.current = false;
	return isFirstRender;
}
function useCompaniesFilter() {
	const isFirstRender = useFirstRender();
	const { page, setPage, searchQuery, setSearchQuery } = useCompaniesStore();

	// initial values
	const initialSearchQuery = getQueryParamsValue("search") || searchQuery;
	const initialOffset =
		parseInt(getQueryParamsValue("page")) >= 1
			? (parseInt(getQueryParamsValue("page")) - 1) * LIMIT
			: page * LIMIT;

	const [offset, setOffset] = useState(initialOffset);
	const [searchValue, setSearchValue] = useState(initialSearchQuery); // search input value

	// init zustand state from query params
	useEffect(() => {
		const queryParams = getCompaniesQueryParams();
		// query params is empty
		if (!_isEmpty(queryParams)) {
			const page = queryParams?.page;
			const search = queryParams?.search;
			if (search) setSearchQuery(search);
			if (page && _isNumber(page) && parseInt(page) >= 1) setPage(page - 1);
			else syncQueryParamsWithStore({ page: 1, search }); // page value is invalid
			// console.log("init store ....");
		}
	}, []);

	// sync query params route with zustand state
	useEffect(() => {
		const queryParams = getCompaniesQueryParams();
		// in not first render or query params is empty
		if (!isFirstRender || _isEmpty(queryParams)) {
			syncQueryParamsWithStore({ page: page + 1, search: searchQuery });
			// console.log("sync query params ....");
		}
	}, [page, searchQuery]);

	return {
		page,
		setPage,
		offset,
		setOffset,
		searchValue,
		setSearchValue,
		searchQuery,
		setSearchQuery
	};
}

// helpers
function getQueryParamsValue(key) {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(key);
}
function getCompaniesQueryParams() {
	const page = getQueryParamsValue("page");
	const search = getQueryParamsValue("search");
	if (search && page) return { search, page };
	if (page && !search) return { search: "", page };
	if (search && !page) return { search };
	return null;
}
function syncQueryParamsWithStore({ page, search }) {
	browserHistory.replace({
		pathname: getFollowedCompaniesRoute(),
		query: search ? { page, search } : { page }
	});
}
