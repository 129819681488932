import Immutable from "seamless-immutable";
import * as types from "../actions/actionTypes";

const initialState = Immutable({
	isFetching: false,
	expired: false,
	account_blocked: false,
	accept_success: false,
	invitationInfo: null,
	already_accepted: false
});

export default function(state = initialState, action) {
	switch (action.type) {
		case types.TOGGLE_FETCHING:
			return Immutable.merge(state, { isFetching: !state.isFetching });
		case types.INVITATION_FOUND:
			return Immutable.merge(state, {
				invitationInfo: action.data
			});
		case types.INVITATION_ACCEPTED:
			return Immutable.merge(state, {
				accept_success: true
			});
		case types.ACCOUNT_BLOCKED:
			return Immutable.merge(state, {
				account_blocked: true
			});
		case types.SET_EXPIRED:
			return Immutable.merge(state, { expired: true });
		case types.ALREADY_ACCEPTED:
			return Immutable.merge(state, { already_accepted: true });
		default:
			return state;
	}
}
