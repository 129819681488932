import { client } from "lib/api-client";
import { useMutation } from "react-query";
import { UPDATE_USER_SETTING } from "config/api-endpoints";

const closeSyncEmailBanner = body => {
	return client(UPDATE_USER_SETTING, {
		body
	});
};

function useCloseSyncEmailBanner() {
	return useMutation(closeSyncEmailBanner, {
		refetchOnWindowFocus: false,
		retry: 0
	});
}

export default useCloseSyncEmailBanner;
