import React from "react";
import Drawer from "rc-drawer";
import useOpenDrawerSupplier from "modules/consultancy/search/zustand/useOpenDrawerSupplier.js";
import InvitationForm from "modules/consultancy/search/invitationForm/InvitationForm.jsx";

const InvitationDrawer = ({ submitInvitation, isInviting }) => {
	const { drawerIsOpen, toggleDrawer } = useOpenDrawerSupplier();
	const closeDrawer = () => {
		toggleDrawer(false);
	};
	return (
		<Drawer
			open={drawerIsOpen}
			width={`380px`}
			height={"100%"}
			placement={"right"}
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={closeDrawer}
			className="task_details_drawer"
		>
			{drawerIsOpen && (
				<InvitationForm
					submitInvitation={submitInvitation}
					isInviting={isInviting}
				/>
			)}
		</Drawer>
	);
};

export default InvitationDrawer;
