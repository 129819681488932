import create from "zustand";
// import { REQUEST_DETAILS_TAB } from "../utils/constants";

const initialState = {
	indexTab: 0
};

const useRequestViewCurrentTab = create(set => ({
	...initialState,
	setIndexTab: value => {
		set({
			indexTab: value
		});
	}
}));

export default useRequestViewCurrentTab;
