import React from "react";
import Card from "../Card";
import _groupBy from "lodash/groupBy";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ReactComponent as EmptyState } from "static/icons/exclamation-triangledanger.svg";
import styles from "./skills-card.module.scss";
import GlobalTooltip from "common/GlobalTooltip";

export const SkillsByCategory = skills => {
	const categories = _groupBy(skills, "parent_sector.name");
	return categories;
};

const SkillsCard = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	skills,
	isLoading,
	title
}) => {
	const skillsBycategory = SkillsByCategory(skills);
	const keys = Object.keys(skillsBycategory);

	const renderItems = (name, score) =>
		score && (
			<>
				<div className={styles.tag}>
					<p>{name ? `${name} (${score}/5)` : "N/A"}</p>
				</div>
			</>
		);

	const countValue =
		skills?.length > 0 &&
		keys.map(item => (
			<div key={item} className={styles.section}>
				<div className={styles.row}>
					{skillsBycategory[item]?.length > 4 &&
						skillsBycategory[item]
							.slice(4)
							.map(({ name, score }) => renderItems(name, score, item))}
				</div>
			</div>
		));

	return (
		<Card title={title} {...{ onDragEnd, onViewMore, onToggleVisibility }}>
			{!isLoading ? (
				skills?.length > 0 ? (
					keys.map(item => (
						<div key={item} className={styles.section}>
							<div className={styles.title}>{item}</div>
							<div className={styles.row}>
								{skillsBycategory[item].length > 4
									? skillsBycategory[item]
											.slice(0, 4)
											.map(({ name, score }) => renderItems(name, score, item))
									: skillsBycategory[item].map(({ name, score }) =>
											renderItems(name, score, item)
									  )}
								<div>
									{skillsBycategory[item].length > 4 && (
										<GlobalTooltip
											placement="bottom"
											trigger="hover"
											overlay={countValue}
										>
											<span
												style={{
													height: "25px",
													width: "25px",
													backgroundColor: "#e4e5e7",
													borderRadius: "50%",
													display: "inline-block",
													marginRight: "5px",
													fontSize: "13px",
													color: "#3c3636",
													textAlign: "center",
													padding: "3px"
												}}
											>
												+{skillsBycategory[item].length - 4}
											</span>
										</GlobalTooltip>
									)}
								</div>
							</div>
						</div>
					))
				) : (
					<div className={styles.emptyState}>
						<EmptyState />
						<p>No results to show</p>
					</div>
				)
			) : (
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={200} height={16} />
				</SkeletonTheme>
			)}
		</Card>
	);
};

export default SkillsCard;
