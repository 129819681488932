import React, { useState } from "react";
import { Box, Text } from "rebass";
import { TitleHeadline, SubTextHeadline } from "../personalTab";
import Table from "./table";
import get from "lodash/get";
import { HISTORY_TYPE, HISTORY_TYPE_LABEL, hexToRgbA } from "./constants";
import Pagination from "common/Pagination";
import { dateCalendar } from "common/Functions";
import { PaginationContainer } from "../notes/styles";
import Tooltip from "rc-tooltip";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DefaultButton from "common/Buttons";
import { VIEW_USER_HISTORY } from "config/api-endpoints";
import { client } from "lib/api-client";
import { usePaginatedQuery } from "react-query";
import { withRouter } from "react-router";

import styled from "styled-components";
import { historyPush } from "config/helpers";

const AlignedButton = styled(DefaultButton)`
	text-align: left;
`;

const getHistory = (_, data) =>
	client(VIEW_USER_HISTORY, {
		body: { ...data }
	});

const Interactions = ({ userId, router }) => {
	const [offset, setOffset] = useState(0);

	const {
		params: { id }
	} = router;
	const { resolvedData, isLoading } = usePaginatedQuery(
		["users", { id, offset }],
		getHistory,
		{
			refetchOnWindowFocus: false
		}
	);

	const history = get(resolvedData, "data", []);

	const dataSkeleton = new Array(7).fill({
		user_name: (
			<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
				<Skeleton width={60} height={14} />
			</SkeletonTheme>
		),
		created_at: (
			<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
				<Skeleton width={60} height={14} />
			</SkeletonTheme>
		),
		job_title: (
			<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
				<Skeleton width={60} height={14} />
			</SkeletonTheme>
		),
		history_type: (
			<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
				<Skeleton width={60} height={14} />
			</SkeletonTheme>
		)
	});

	const renderHistoryType = historyType => (
		<Box
			sx={{
				backgroundColor: hexToRgbA(HISTORY_TYPE[historyType], 0.14),
				padding: "4px 6px",
				borderRadius: "4px",
				width: "max-content"
			}}
		>
			<Text
				as={"h6"}
				sx={{
					color: HISTORY_TYPE[historyType],
					fontSize: "14px",
					fontWeight: "normal"
				}}
			>
				{HISTORY_TYPE_LABEL[historyType]}
			</Text>
		</Box>
	);
	const size = get(resolvedData, "pagination.size", 0);
	const total = get(resolvedData, "pagination.total", 0);

	const data =
		get(history, "length", 0) > 0
			? history.map(singleHistory => {
					const fullName =
						get(singleHistory, "user_action.first_name", "") +
						" " +
						get(singleHistory, "user_action.last_name", "");

					return {
						user_name:
							get(singleHistory, "user_action._id", "") === userId ? (
								<SubTextHeadline> You </SubTextHeadline>
							) : fullName !== " " ? (
								<Tooltip
									placement="bottom"
									overlay={fullName}
									trigger="hover"
									overlayClassName="function-tooltip"
								>
									<span
										style={{
											fontSize: "16px",
											color: "#212b36",
											fontWeight: "normal",
											overflow: "hidden",
											whiteSpace: "nowrap",
											textOverflow: "ellipsis"
										}}
									>
										{fullName}
									</span>
								</Tooltip>
							) : (
								<SubTextHeadline>
									{" "}
									{get(singleHistory, "user_action", "")}{" "}
								</SubTextHeadline>
							),
						created_at: (
							<SubTextHeadline>
								{dateCalendar(get(singleHistory, "created_at"))}
							</SubTextHeadline>
						),
						job_title:
							get(singleHistory, "job.title", "").length > 29 ? (
								<Tooltip
									placement="bottom"
									overlay={get(singleHistory, "job.title", "")}
									trigger="hover"
									overlayClassName="function-tooltip"
								>
									<span
										onClick={() =>
											historyPush(
												"/vacancy/" + get(singleHistory, "job._id", "")
											)
										}
										style={{
											fontSize: "16px",
											color: "#212b36",
											fontWeight: "normal",
											cursor: "pointer"
										}}
									>
										{get(singleHistory, "job.title", "").slice(0, 30) + "..."}
									</span>
								</Tooltip>
							) : (
								<AlignedButton
									padding="0px"
									onPress={() =>
										historyPush("/vacancy/" + get(singleHistory, "job._id", ""))
									}
									backgroundColor="white"
								>
									<SubTextHeadline>
										{get(singleHistory, "job.title", "")}
									</SubTextHeadline>
								</AlignedButton>
							),
						history_type: renderHistoryType(get(singleHistory, "history_type"))
					};
			  })
			: [
					{
						user_name: <SubTextHeadline>N/A</SubTextHeadline>,
						created_at: <SubTextHeadline>N/A</SubTextHeadline>,
						job_title: <SubTextHeadline>N/A</SubTextHeadline>,
						history_type: <SubTextHeadline>N/A</SubTextHeadline>
					}
			  ];

	return (
		<Box height="100%">
			<TitleHeadline>INTERACTIONS</TitleHeadline>
			<Box overflow="auto" height="466px">
				<Table data={isLoading ? dataSkeleton : data} />
			</Box>

			{total > size && (
				<PaginationContainer>
					<Pagination
						total={total}
						size={size}
						handlePageClick={page => {
							setOffset(page * size);
						}}
						offset={offset}
						forcePage={Math.ceil(offset / size)}
					/>
				</PaginationContainer>
			)}
		</Box>
	);
};

export default withRouter(Interactions);
