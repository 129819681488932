import React from "react";
import styled, { keyframes } from "styled-components";

const openAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 97.3%;
  }
`;

const Wrapper = styled.div`
	left: 5px;
	bottom: 0;
	height: 4px;
	position: absolute;
	border-radius: 12px;
	background-color: #d1d5db;
	transition: width ${props => props.duration}s;
	animation: ${openAnimation} ${props => props.duration}s linear;
`;

export default function ProgressBar(props) {
	return <Wrapper active={props.active} duration={props.duration} />;
}
