import React, { createContext, useReducer } from "react";
import { VACANCY_LEVEL, LEVEL, FREELANCER } from "config";

const initialState = {
	sectors: [],
	subSectors: [],
	functions: [],
	seniority: VACANCY_LEVEL,
	selectedSector: undefined,
	selectedSubSector: undefined,
	selectedFunction: undefined,
	selectedSeniority: undefined,
	selectedLocation: undefined,
	selectedExpectation: undefined,
	selectedNoticePeriod: undefined
};

export const BOT_ACTION_TYPES = {
	SET_SECTORS: "SET_SECTORS",
	SET_SUB_SECTORS: "SET_SUB_SECTORS",
	SET_FUNCTIONS: "SET_FUNCTIONS",
	SET_SELECTED_SECTOR: "SET_SELECTED_SECTOR",
	SET_SELECTED_SUB_SECTOR: "SET_SELECTED_SUB_SECTOR",
	SET_SELECTED_FUNCTION: "SET_SELECTED_FUNCTION",
	SET_SELECTED_SENIORITY: "SET_SELECTED_SENIORITY",
	SET_SELECTED_LOCATION: "SET_SELECTED_LOCATION",
	SET_SELECTED_EXPECTATION: "SET_SELECTED_EXPECTATION",
	SET_SELECTED_NOTICE_PERIOD: "SET_SELECTED_NOTICE_PERIOD"
};

function reducer(state, action) {
	switch (action.type) {
		case BOT_ACTION_TYPES.SET_SECTORS: {
			return Object.assign({}, state, {
				sectors: action.sectors
			});
		}
		case BOT_ACTION_TYPES.SET_SUB_SECTORS: {
			return Object.assign({}, state, {
				subSectors: action.subSectors
			});
		}
		case BOT_ACTION_TYPES.SET_FUNCTIONS: {
			return Object.assign({}, state, {
				functions: action.functions
			});
		}
		case BOT_ACTION_TYPES.SET_SELECTED_SECTOR:
			return Object.assign({}, state, {
				selectedSector: action.sector
			});
		case BOT_ACTION_TYPES.SET_SELECTED_SUB_SECTOR:
			return Object.assign({}, state, {
				selectedSubSector: action.subSector
			});
		case BOT_ACTION_TYPES.SET_SELECTED_FUNCTION:
			return Object.assign({}, state, {
				selectedFunction: action.func
			});
		case BOT_ACTION_TYPES.SET_SELECTED_SENIORITY:
			return Object.assign({}, state, {
				selectedSeniority: action.seniority
			});
		case BOT_ACTION_TYPES.SET_SELECTED_LOCATION:
			return Object.assign({}, state, {
				selectedLocation: action.location
			});
		case BOT_ACTION_TYPES.SET_SELECTED_EXPECTATION:
			return Object.assign({}, state, {
				selectedExpectation: action.value
			});
		case BOT_ACTION_TYPES.SET_SELECTED_NOTICE_PERIOD:
			return Object.assign({}, state, {
				selectedNoticePeriod: action.value
			});
		default:
			return state;
	}
}

export const BotContext = createContext({});

export const BotProvider = ({ children, type }) => {
	if (type === FREELANCER) {
		initialState.seniority = LEVEL;
	}

	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<BotContext.Provider value={{ state, dispatch }}>
			{children}
		</BotContext.Provider>
	);
};
