import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { GET_BID } from "config/api-endpoints";
import { useIsDisplayCostEnabled } from "hooks/useIsDisplayCostEnabled";
import { useEffect } from "react";

export const BID_DETAILS_QUERY_ID = "BID_DETAILS_QUERY";

const getBidDetails = bid_id =>
	client(GET_BID, {
		body: { bid_id }
	});

const useGetBidDetails = bid_id => {
	const displayCostEnabled = useIsDisplayCostEnabled();

	const res = useQuery(
		[BID_DETAILS_QUERY_ID, { bid_id }],
		() => getBidDetails(bid_id),
		{
			refetchOnWindowFocus: false,
			enabled: !!bid_id,
			retry: 5
		}
	);

	useEffect(() => {
		if (!res.isFetching) {
			res.refetch();
		}
	}, [displayCostEnabled]);

	return res;
};

export default useGetBidDetails;
