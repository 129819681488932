import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { formatDate } from "common/Functions";
import PokeNoteModal from "modules/vacancy/components/modals/PokeNoteModal";
import { PokeNotesTableDetails, Th, Tr, Td, Section } from "./Styled";
import { convertNoteDescriptionToText } from "config/helpers";

const NotesDetails = ({ notes, flag, toggleNotesModal, isBid }) => {
	const [showModal, setShowModal] = useState(false);
	const [data, setdata] = useState();
	const note = {
		data: data
	};
	const showNotes = () => {
		return notes.map(item => {
			return (
				<Tr key={item._id}>
					<Td>{formatDate(item.created_at, true)}</Td>
					<Td>{`${item.user.first_name} ${item.user.last_name}`}</Td>
					<Td ellipsis>{convertNoteDescriptionToText(item.description)}</Td>
					{/* <Td ellipsis>{parseMentions(item.note_type)}</Td> */}
					<Td>
						<div
							className="view-more"
							onClick={() =>
								isBid
									? (setShowModal(true), setdata(item))
									: toggleNotesModal(item)
							}
						>
							View more
						</div>
					</Td>
				</Tr>
			);
		});
	};

	return (
		<Section className="section">
			<div className="flagwrapper_notes">{flag}</div>
			<Scrollbars
				autoHide
				autoHideTimeout={5000}
				autoHideDuration={200}
				autoHeight
				autoHeightMax={324}
			>
				<PokeNotesTableDetails className="request-list">
					<thead>
						<Tr>
							<Th width="15%">date</Th>
							<Th width="25%">created by</Th>
							<Th width="25%">details</Th>
							<Th width="10%" />
						</Tr>
					</thead>
					<tbody>{showNotes()}</tbody>
				</PokeNotesTableDetails>
			</Scrollbars>
			{showModal && (
				<PokeNoteModal note={note} onClose={setShowModal} usersList={[]} />
			)}
		</Section>
	);
};

NotesDetails.propTypes = {
	flag: PropTypes.string.isRequired
};

export default memo(NotesDetails);
