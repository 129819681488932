import mockDataJobDetails from "./jobDetails.json";
import mockDataKanbanPayload from "./kanbanPayload.json";
import mockDataKanbanSettings from "./kanbanSettings.json";

import mockDataNotes from "./notesTabData.json";
import mockDataRejected from "./rejectedTabData.json";
import mockDataActivities from "./activitiesTabData.json";
import mockDataPerformance from "./performanceTabData.json";

export {
	mockDataNotes,
	mockDataRejected,
	mockDataActivities,
	mockDataJobDetails,
	mockDataPerformance,
	mockDataKanbanPayload,
	mockDataKanbanSettings
};
