import React from "react";
import styles from "./search-result.module.scss";

const SearchResult = ({ items, onClick }) => {
	return (
		<div className={styles.searchResult}>
			{items.map(item => {
				return (
					<>
						<div className={styles.header}>
							<div>{item.label}</div>
							<div className={styles.line} />
						</div>
						<div key={item} className={styles.parent}>
							{item.children.map(child => (
								<div
									key={child.id}
									onClick={() => onClick(child)}
									className={styles.item}
								>
									{child.label}
								</div>
							))}
						</div>
					</>
				);
			})}
		</div>
	);
};

export default SearchResult;
