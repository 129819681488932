import React from "react";
import PropTypes from "prop-types";

const Link = ({ contentState, entityKey, clickLink, children, target }) => {
	const { value } = contentState.getEntity(entityKey).getData();
	return (
		<a
			href={value}
			onClick={clickLink}
			target={target}
			rel="noopener noreferrer"
		>
			{children}
		</a>
	);
};

Link.propTypes = {
	contentState: PropTypes.object,
	entityKey: PropTypes.string,
	target: PropTypes.string,
	clickLink: PropTypes.func,
	children: PropTypes.object
};

export default Link;
