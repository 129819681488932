import React, { useState, useEffect } from "react";
import { ReactComponent as AddUsers } from "static/icons/add-users-icon.svg";
import avatar from "static/images/Avatar-default.png";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import classnames from "./render-selected-users.module.scss";
import { ReactComponent as ArrowRight } from "static/icons/arrow-right-list-filter-icon.svg";
import ShowMoreModal from "../ShowMoreModal";
import Portal from "common/ListFilter/components/Portal";

const RenderSelectedUsers = ({
	users,
	clickHandler,
	deleteFilterValue,
	showMoreTitle
}) => {
	const [showMoreUsers, setShowMoreUser] = useState(false);
	const [focusedNode, setfocusedNode] = useState(false);
	const id = uuid();
	useEffect(() => {
		id && setfocusedNode(document.getElementById(id));
	}, [id]);

	let nodeRect = { x: 0, y: 0 };
	if (focusedNode) {
		nodeRect = focusedNode.getBoundingClientRect();
	}

	const renderUsers = () => {
		const renderAvatar = user => {
			if (Boolean(get(user, "avatar"))) return get(user, "avatar", false);
			return avatar;
		};

		return users
			.slice(0, 8)
			.map(ele => (
				<img
					className={classnames.avatar}
					key={ele._id}
					src={renderAvatar(ele)}
				/>
			));
	};

	const openShowMoreModal = e => {
		e.stopPropagation();
		setShowMoreUser(true);
	};
	const closeShowMoreModal = () => setShowMoreUser(false);
	return (
		<button onClick={clickHandler} className={classnames.usersContainer}>
			<div className={classnames.menuLabel}>
				<React.Fragment>
					{renderUsers()}
					<React.Fragment>
						{Boolean(get(users, "length", 0) - 8 > 0) && (
							<button
								onClick={openShowMoreModal}
								className={classnames.otherUsers}
							>
								+{users.length - 8}
							</button>
						)}
						<div id={id} className={classnames.positionRelative}></div>
					</React.Fragment>
					<div className={classnames.addUsers}>
						<AddUsers />
					</div>
				</React.Fragment>
			</div>
			<ArrowRight className={classnames.icon} />
			{showMoreUsers && get(users, "length", 0) && (
				<Portal id={id} nodeRect={nodeRect}>
					<ShowMoreModal
						title={showMoreTitle}
						data={users}
						closeModal={closeShowMoreModal}
						deleteFilterValue={deleteFilterValue}
						isUser
					/>
				</Portal>
			)}
		</button>
	);
};

export default RenderSelectedUsers;
