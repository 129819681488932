import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { Flex, Box, Text } from "rebass";
import _get from "lodash/get";
import Avatar from "common/Avatar/index";
import { NO_DATA_PLACEHOLDER, EUR, DRAFT, SENT } from "config";
import { parsePhoneNumber } from "common/Functions";
import StatusLabel from "common/StatusLabel";
import {
	InfoContainer,
	PhoneContainer,
	ProfileContainer,
	EmailContainer,
	PhonePlaceholder,
	CandidateInfo,
	EmailPlaceholder,
	Placeholder
} from "../Styled";
import CurrencyFormatter from "common/CurrencyFormater";
import profileIcon from "static/icons/anonymous-avatar.svg";
import GlobalTooltip from "common/GlobalTooltip";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import oneLine from "modules/candidate/components/FabricEditor/icons/1line.svg";
import { historyPush } from "config/helpers";

function PokeHeadLine({ profile, pokeId, finalStatus }) {
	const candidateProfile = id => {
		return historyPush(
			getPermanentProfileRoute(`${id}?poke=${pokeId}`) +
				"&source=list&list=poke"
		);
	};

	const first_name = _get(profile, "headline.first_name", null);
	const last_name = _get(profile, "headline.last_name", null);
	const confirmed = first_name && last_name;
	const avatar = _get(profile, "headline.avatar", null);
	const _functions = _get(profile, "functions[0]", {});
	const email = _get(profile, "headline.email", null);
	const phone = _get(profile, "headline.phone", null);
	const expected_salary = _get(
		profile,
		"front_sheet.preference.expected_salary",
		null
	);
	const expected_salary_advantage = _get(
		profile,
		"front_sheet.preference.expected_salary_advantage",
		null
	);
	const reference = _get(profile, "headline.reference", null);
	const preferred_location = _get(
		profile,
		"front_sheet.preference.preferred_location[0]",
		null
	);

	const formatedPhone =
		phone && phone !== NO_DATA_PLACEHOLDER && parsePhoneNumber(phone);

	return (
		<div
			onMouseUp={() => candidateProfile(profile._id, confirmed)}
			className="bid_headline"
		>
			<Flex
				alignItems="center"
				justifyContent="space-between"
				sx={{
					backgroundColor: "#fff",
					borderBottom: "1px solid #F0F3F5",
					padding: "0 60px"
				}}
				pb={0}
				pt={0}
			>
				<Flex alignItems="center">
					<Flex
						justifyContent=" center"
						width="115px"
						height="115px"
						mr={20}
						sx={{
							position: "relative",
							top: "24px"
						}}
					>
						{confirmed ? (
							<Avatar
								src={avatar}
								size={105}
								name={`${first_name} ${last_name}`}
								radius={4}
							/>
						) : (
							<img
								alt="anonymous"
								style={{ marginTop: -5 }}
								height="114px"
								src={profileIcon}
							/>
						)}
					</Flex>
					<Box>
						<Text
							as={"h3"}
							sx={{
								fontFamily: "BasierCircle",
								fontSize: "16px",
								fontWeight: "600",
								color: "#202223",
								marginBottom: "6px"
							}}
						>
							<Link
								className="clickable"
								style={{ textDecoration: "none", color: "rgb(15,28,70)" }}
							>
								{confirmed ? `${first_name} ${last_name}` : `Ref: ${reference}`}
							</Link>
						</Text>
						<CandidateInfo className="user-sector">
							{_functions.seniority && (
								<Text
									as="span"
									className="seniority"
									sx={{
										background: "#E4E5E7",
										borderRadius: "10px",
										fontSize: "13px",
										lineHeight: "16px",
										textAlign: "center",
										color: "#202223",
										fontFamily: "basiercircle",
										fontStyle: "normal",
										borderColor: "#E4E5E7",
										textTransform: "capitalize",
										fontWeight: "normal"
									}}
								>
									{_functions.seniority}
								</Text>
							)}

							<Text
								as="span"
								ml={"10px"}
								className="function-content"
								sx={{
									fontFamily: "basiercircle",
									fontStyle: "normal",
									fontWeight: "normal",
									fontSize: "12px",
									lineHeight: "16px",
									color: "#202223"
								}}
							>
								{_functions.name}
							</Text>
						</CandidateInfo>
					</Box>
				</Flex>

				<Flex>
					<InfoContainer isPokeHead>
						<PhoneContainer>
							<i className="icon-phone-handle" />
							{phone === NO_DATA_PLACEHOLDER || !phone ? (
								<EmailPlaceholder className="placeholder" />
							) : (
								<span className="phone_number"> {formatedPhone} </span>
							)}
						</PhoneContainer>

						<EmailContainer>
							<i className="icon-mail" style={{ marginTop: "0" }} />
							{email === NO_DATA_PLACEHOLDER || !email ? (
								<PhonePlaceholder className="placeholder" />
							) : (
								<span>{email}</span>
							)}
						</EmailContainer>
					</InfoContainer>
				</Flex>
				<Flex>
					<ProfileContainer margin="0 0 0 10px">
						<div className="ProfileContainer-salary">
							<span className="ProfileContainer-label">expected salary</span>
							{expected_salary ? (
								<>
									<span className="profile-expected_salary">
										<CurrencyFormatter
											cent={expected_salary}
											symbol={` ${_get(
												profile,
												"front_sheet.preference.symbol_expected",
												EUR
											)}`}
										/>
									</span>
									<Text
										as={"span"}
										sx={{
											textTransform: "capitalize",
											display: "inline-block",
											color: "#202223"
										}}
									>
										{_get(
											profile,
											"front_sheet.preference.payment_type_expected",
											"gross"
										)}
									</Text>
									<Text
										as={"span"}
										sx={{
											textTransform: "capitalize",
											display: "inline-block",
											color: "#202223"
										}}
									>
										/
										{_get(
											profile,
											"front_sheet.preference.payment_time_expected",
											"yearly"
										)}
									</Text>
								</>
							) : (
								<Placeholder>
									<img src={oneLine} width={100} alt="expected salary" />
								</Placeholder>
							)}
							{expected_salary_advantage && (
								<GlobalTooltip
									placement="top"
									overlay={expected_salary_advantage}
									overlayClassName="g-tool-dark"
								>
									<div className="ProfileContainer-advantages">
										Advantages...
									</div>
								</GlobalTooltip>
							)}
						</div>
						<div className="ProfileContainer-location">
							<span className="ProfileContainer-label">Place of residence</span>
							{preferred_location ? (
								<GlobalTooltip
									placement="top"
									overlay={`${preferred_location.city}, ${preferred_location.country} `}
									overlayClassName="g-tool-dark"
									ellipsisMode={true}
								>
									<div className="location_box" name="location_box">{`${
										preferred_location.city ? `${preferred_location.city},` : ""
									} ${preferred_location.country} `}</div>
								</GlobalTooltip>
							) : (
								<Placeholder center>
									<img src={oneLine} width={200} alt="Place of residence" />
								</Placeholder>
							)}
						</div>
					</ProfileContainer>
				</Flex>
				<Flex>
					<Box>
						<StatusLabel
							right={0}
							status={
								_get(finalStatus, "last_status") === DRAFT
									? SENT
									: _get(finalStatus, "last_status")
							}
							isInterview={true}
							label={_get(finalStatus, "visible_status")}
							px={15}
						/>
					</Box>
				</Flex>
			</Flex>
		</div>
	);
}

PokeHeadLine.propTypes = {
	profile: PropTypes.object.isRequired
};

export default PokeHeadLine;
