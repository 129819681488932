import { queryCache, useMutation } from "react-query";
import _get from "lodash/get";
import toaster from "common/Toaster";
import {
	personalProfileKey,
	setAsPrimary as setAsPrimaryApi
} from "modules/candidate/Profile/api/usePersonalProfile";
import { viewOverviewKey } from "modules/candidate/Profile/api/useOverview";
import { profileOverviewKey } from "modules/candidate/Profile/api/useViewCandidateInternal";
import { editHistoryKey } from "modules/candidate/Profile/api/useEditHistory";

export const usePrimary = (onSuccess, onError) => {
	const [setAsPrimary] = useMutation(setAsPrimaryApi, {
		onSuccess: () => {
			toaster.success("Personal profile has been updated ");
			queryCache.invalidateQueries(personalProfileKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(profileOverviewKey);
			queryCache.invalidateQueries(editHistoryKey);
			onSuccess();
		},
		onError: e => {
			if (e && e.status === 422) {
				let errorMsg = _get(e, "detail.name");
				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			}
			queryCache.invalidateQueries(personalProfileKey);
			onError();
		}
	});
	return setAsPrimary;
};
