import React from "react";

import FieldError from "./FieldError";
import { YES_NO_ITEMS } from "../constants";
import { Controller } from "react-hook-form";
import EvaluationScoreButton from "./EvaluationScoreButton";
import styles from "../evaluation-template-render-drawer.module.scss";

export default function ControlledBooleanField({
	name,
	error,
	element,
	control
}) {
	const { title, is_required } = element;

	return (
		<div className={styles.fieldContainer}>
			<label className={styles.fieldLabel}>
				{title} {is_required && <span className={styles.required}>*</span>}
			</label>
			<Controller
				name={name}
				control={control}
				defaultValue={element?.value || ""}
				rules={is_required ? { required: "Please answer this question" } : {}}
				render={({ field: { onChange, value } }) => {
					return (
						<div className={styles.scoreRatingContainer}>
							{YES_NO_ITEMS.map(item => {
								const isActive = item.value === value;
								return (
									<EvaluationScoreButton
										key={item.value}
										className={{ [item.active_classname]: isActive }}
										onClick={() => onChange(!isActive ? item.value : "")}
									>
										<span>{item.label}</span>
									</EvaluationScoreButton>
								);
							})}
						</div>
					);
				}}
			/>
			<FieldError error={error} />
		</div>
	);
}
