import create from "zustand";
import storeUtils from "common/FilterBox/utils";

const initialState = {
	offset: 0,
	op: "and",
	fields: [],
	search: ""
};

const useNotesStore = create((set, get) => ({
	filters: initialState,
	rows: [],
	isDrawerOpen: false,
	isOtherDrawerOpen: false,
	activeNoteIdx: null,
	limit: 8,
	setLimit: limit => set({ limit }),
	setActiveNoteIdx: activeNoteIdx => set({ activeNoteIdx }),
	setAllNotes: allNotes => set({ allNotes }),
	toggleDrawer: () =>
		set(({ isDrawerOpen }) => ({ isDrawerOpen: !isDrawerOpen })),
	toggleOtherDrawer: () =>
		set(({ isOtherDrawerOpen }) => ({ isOtherDrawerOpen: !isOtherDrawerOpen })),
	...storeUtils(get, set, initialState)
}));

export default useNotesStore;
