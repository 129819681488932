import React from "react";
// import styles from "./create-request-seniority-field.module.scss";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { ComboBox } from "common/ComboBox";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import { Controller } from "react-hook-form";
import { Item } from "react-stately";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { VACANCY_LEVEL } from "config";
import useElements from "hooks/useLayoutElements";

const CreateRequestSeniorityField = ({ control, error, seniority }) => {
	const { mainElement } = useElements();
	const getSeniorityById = id => {
		return VACANCY_LEVEL.find(({ value }) => value === id);
	};

	const onSenioritySelectionChange = (key, onChange) => {
		const seniorityObject = getSeniorityById(key);

		const value = {
			label: seniorityObject?.label,
			value: key
		};

		onChange(value);
	};

	const onSeniorityInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : seniority?.value
		};

		onChange(valueObject);
	};

	const getUnusedSeniorities = () => {
		return VACANCY_LEVEL.slice(1);
	};

	return (
		<CreateRequestFieldContainer>
			<CreateRequestLabelContainer>
				<CreateRequestFieldLabel displayStar>Seniority</CreateRequestFieldLabel>
			</CreateRequestLabelContainer>
			<Controller
				name="seniority"
				control={control}
				render={({ field: { value, onChange } }) => {
					return (
						<ComboBox
							popoverContainer={mainElement}
							displayButton={false}
							selectedKey={value?.value}
							inputValue={value?.label}
							onInputChange={value => onSeniorityInputChange(value, onChange)}
							onSelectionChange={key =>
								onSenioritySelectionChange(key, onChange)
							}
						>
							{getUnusedSeniorities().map(({ value, label }) => (
								<Item key={value}>{label}</Item>
							))}
						</ComboBox>
					);
				}}
			/>

			<CreateRequestFieldError error={error} />
		</CreateRequestFieldContainer>
	);
};

export default CreateRequestSeniorityField;
