import React, { useEffect, useRef } from "react";
import { StyledCheckbox } from "./styled";

const CHECKBOX_STATES = {
	Checked: "Checked",
	Indeterminate: "Indeterminate",
	Empty: "Empty"
};

const ControlledCheckbox = ({ value, onChange, disabled }) => {
	const checkboxRef = useRef();

	useEffect(() => {
		if (value === CHECKBOX_STATES.Checked) {
			checkboxRef.current.checked = true;
			checkboxRef.current.indeterminate = false;
		} else if (value === CHECKBOX_STATES.Empty) {
			checkboxRef.current.checked = false;
			checkboxRef.current.indeterminate = false;
		} else if (value === CHECKBOX_STATES.Indeterminate) {
			checkboxRef.current.checked = false;
			checkboxRef.current.indeterminate = true;
		}
	}, [value]);

	return (
		<StyledCheckbox>
			<div className="checkbox checkbox-primary">
				<div
					className="input-box"
					style={{ width: "100%", display: "block", marginTop: "11px" }}
				>
					<input
						className="styled"
						ref={checkboxRef}
						type="checkbox"
						onChange={onChange}
						id="check-all"
						name="check-all"
						style={{ height: 0, width: 0, margin: 0 }}
						disabled={disabled}
					/>
					<label
						style={{
							width: "20px",
							padding: 0,
							opacity: disabled ? 0.25 : 1,
							cursor: disabled ? "not-allowed" : "pointer"
						}}
						htmlFor="check-all"
					></label>
				</div>
			</div>
		</StyledCheckbox>
	);
};

export default ControlledCheckbox;
