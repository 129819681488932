import {
	DISABLE_ACCOUNT,
	HIDE_TO_BE_REVIEWED_BANNER,
	HIDE_COOKIE_POLICY
} from "../actions/actionTypes";

export default function(
	state = {
		isFecthing: false,
		displayBannerToBeReviewed: true,
		displayCookiePolicy: true
	},
	action
) {
	const { isFetching } = action;
	switch (action.type) {
		case HIDE_TO_BE_REVIEWED_BANNER:
			return {
				...state,
				displayBannerToBeReviewed: false
			};
		case DISABLE_ACCOUNT:
			return Object.assign({}, state, { isFetching });
		case HIDE_COOKIE_POLICY:
			return {
				...state,
				displayCookiePolicy: false
			};
		default:
			return state;
	}
}
