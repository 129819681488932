import create from "zustand";

const useEmailsSelection = create(set => ({
	selection: [],
	toggleItemSelection: id => {
		set(state => {
			const isSelected = state.selection.includes(id);
			return {
				selection: isSelected
					? state.selection.filter(item => item !== id)
					: [...state.selection, id]
			};
		});
	},
	selectMany: ids => {
		set({ selection: ids });
	}
}));

export default useEmailsSelection;
