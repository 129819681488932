import React from "react";
import { ReactComponent as InfoIcon } from "static/icons/info-circle-filter.svg";
import Tooltip from "common/Tippy";
import styles from "./mission-supplier-mode-card-header.module.scss";
import ToggleButton from "common/ToggleButton";
import { Controller } from "react-hook-form";

const MissionSupplierModeCardHeader = ({ checked, onToggle, control }) => {
	return (
		<header className={styles.header}>
			<div className={styles.headerLeft}>
				<h3 className={styles.headerTitle}>Supplier mode</h3>
				<Tooltip
					theme="dark"
					className={styles.tooltip}
					content={
						"Supplier Mode serves as a communication hub between companies and freelancers, making it easier for companies to manage timesheets and invoices. By allowing companies to review timesheets independently and receive receipts once they're approved, Supplier Mode simplifies project management and ensures transparency for everyone involved."
					}
				>
					<InfoIcon className={styles.infoCircle} />
				</Tooltip>
			</div>
			<div>
				<Controller
					name="showSupplierMode"
					defaultValue={checked}
					control={control}
					render={({ field: { onChange } }) => (
						<ToggleButton
							checked={checked}
							onChange={e => {
								onToggle();
								onChange(e.target.checked);
							}}
							name={"supplier-mode"}
							className={styles.toggle}
						/>
					)}
				/>
			</div>
		</header>
	);
};

export default MissionSupplierModeCardHeader;
