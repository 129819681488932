import React from "react";
import classnames from "./filter-options-values.module.scss";
import { ReactComponent as ArrowRight } from "static/icons/arrow-right-list-filter-icon.svg";
import { ReactComponent as AddIcon } from "static/icons/add-icon.svg";

const MenuLabel = ({ value, clickHandler, isAddIcon = true }) => {
	return (
		<button onClick={clickHandler} className={classnames.menuLabelContainer}>
			<div className={classnames.menuLabel}>
				{value} {isAddIcon && <AddIcon />}
			</div>
			<ArrowRight className={classnames.icon} />
		</button>
	);
};

export default MenuLabel;
