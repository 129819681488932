import React, { useMemo } from "react";
import Scrollbars from "react-custom-scrollbars";
import cx from "classnames";
import { useTable } from "react-table";
import EmptyState from "../EmptyState";
import classnames from "./table.module.scss";
import {
	DUE_DATE,
	PRIORITY,
	LINK_TO,
	CREATED_BY,
	ASSIGNED_TO,
	CREATION_DATE,
	STATUS
} from "config";
import { get } from "lodash";

const Table = ({
	data,
	isFetching,
	sortedField,
	fetchedData,
	onRowClick,
	isAddNewTask,
	highlitedRow
}) => {
	const columns = useMemo(
		() => [
			{
				Header: "Title",
				accessor: "title"
			},
			{
				Header: "Link to",
				accessor: "link_to",
				isSorted: sortedField === LINK_TO
			},
			{
				Header: "Status",
				accessor: "status",
				isSorted: sortedField === STATUS
			},
			{
				Header: "Due date",
				accessor: "due_date",
				isSorted: sortedField === DUE_DATE
			},
			{
				Header: "Priority",
				accessor: "priority",
				isSorted: sortedField === PRIORITY
			},
			{
				Header: "Created by",
				accessor: "created_by",
				isSorted: sortedField === CREATED_BY
			},
			{
				Header: "Assigned to",
				accessor: "assigned_user",
				isSorted: sortedField === ASSIGNED_TO
			},
			{
				Header: "Creation date",
				accessor: "creation_date",
				isSorted: sortedField === CREATION_DATE
			},
			{
				Header: "Action",
				accessor: "action",
				onClick: e => e.stopPropagation()
			}
		],
		[sortedField]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data
	});

	return (
		<React.Fragment>
			<Scrollbars
				autoHide
				autoHideTimeout={5000}
				autoHideDuration={200}
				autoHeight
				autoHeightMax={600}
				autoHeightMin={600}
			>
				<table
					className={cx(classnames.table, {
						[classnames.minHeight]: isFetching
					})}
					{...getTableProps()}
				>
					<thead className={classnames.tableHead}>
						{headerGroups.map(headerGroup => (
							<tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th key={index} {...column.getHeaderProps()}>
										<span
											className={cx(classnames.columnsHeadline, {
												[classnames.sortedField]: column?.isSorted
											})}
										>
											{column.render("Header")}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					{data.length > 0 ? (
						<tbody className={classnames.tableBody} {...getTableBodyProps()}>
							{rows.map((row, index) => {
								prepareRow(row);
								return (
									<tr
										onClick={
											onRowClick
												? () => onRowClick(data, index, fetchedData)
												: null
										}
										key={index}
										{...row.getRowProps()}
										className={cx({
											[classnames.highlitedRow]:
												highlitedRow === get(fetchedData[index], "_id")
										})}
									>
										{row.cells.map((cell, index) => {
											return (
												<td
													onClick={cell.column.onClick}
													key={index}
													{...cell.getCellProps()}
												>
													{cell.render("Cell")}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					) : null}
				</table>
				{data.length === 0 && (
					<div className={classnames.emtyStateWrraper}>
						<EmptyState isAddNewTask={isAddNewTask} />
					</div>
				)}
			</Scrollbars>
		</React.Fragment>
	);
};

export default Table;
