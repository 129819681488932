import React, { forwardRef } from "react";
import styles from "./messagin-tool-search-dropdown.module.scss";
import Avatar from "common/Avatar";
import { ReactComponent as EmailIcon } from "static/icons/email.svg";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";
import get from "lodash/get";
import { getMessagingToolRoute } from "modules/MessagingTool/MessagingTool";
import { historyReplace } from "config/helpers";

const MessaginToolSearchDropdown = (
	{ participants, emails, isLoading, className, onSelect, hash, onClose },
	ref
) => {
	return (
		<div ref={ref} className={cx(styles.dropdown, className)}>
			{isLoading ? (
				<MessaginToolSearchDropdownLoading />
			) : (
				<>
					{get(participants, "length", 0) !== 0 && (
						<div className={styles.participants}>
							{participants?.map(user => (
								<button
									className={styles.listItem}
									key={user._id}
									onClick={e => {
										e.preventDefault();
										onSelect(user.email);
									}}
								>
									<Avatar
										size={32}
										name={`${user.first_name} ${user.last_name}`}
									/>
									<div className={styles.userInfo}>
										<span
											className={styles.username}
										>{`${user.first_name} ${user.last_name}`}</span>
										<span className={styles.email}>{user.email}</span>
									</div>
								</button>
							))}
						</div>
					)}
					{emails && emails.length !== 0 && (
						<div className={styles.emails}>
							{emails.map(email => (
								<button
									className={styles.listItem}
									key={email._id}
									onClick={() => {
										onClose();
										historyReplace(
											getMessagingToolRoute(`${hash}/${email._id}`)
										);
									}}
								>
									<EmailIcon />
									<div className={styles.userInfo}>
										<span className={styles.username}>{email.subject}</span>
										<span className={styles.email}>
											<div className={styles.body}>{email.snippet}</div>
										</span>
									</div>
								</button>
							))}
						</div>
					)}
				</>
			)}
		</div>
	);
};

const MessaginToolSearchDropdownLoading = () => {
	return Array(5)
		.fill(0)
		.map((_, index) => (
			<div className={styles.listItem} key={index}>
				<Skeleton circle width={38} height={38} />
				<div className={styles.userInfo}>
					<Skeleton width={82} height={11} />
					<Skeleton width={113} height={11} />
				</div>
			</div>
		));
};
MessaginToolSearchDropdown.displayName = "MessaginToolSearchDropdown";

export default forwardRef(MessaginToolSearchDropdown);
