//TODO Change component name
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Item } from "@react-stately/collections";
import { queryCache } from "react-query";
import findKey from "lodash/findKey";
import flatMapDeep from "lodash/flatMapDeep";
import FilterIcons from "../../icons/FilterIcons";
import SortDownIcon from "../../icons/SortDown";
import SortUpIcon from "../../icons/SortUp";
import PinRightIcon from "../../icons/PinRight";
import PinLeftIcon from "../../icons/PinLeft";
import UnpinIcon from "../../icons/Unpin";
import UnSortUp from "../../icons/UnSortUp";
import { useUpdateColumnSettings } from "../../api/useUpdateColumnSettings";
import toaster from "common/Toaster";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import styles from "./menu-column.module.scss";
import useOnClickOutside from "hooks/useOnClickOutside";
import Menu from "../Menu/Menu";

import { ReactComponent as Icon } from "static/icons/search-icon-input.svg";
import cx from "classnames";
import find from "lodash/find";
import _get from "lodash/get";
import UnSortDown from "../../icons/UnSortDown";
import { useFilters as useSmartFilter } from "common/SmartFilterDrawer/hooks/useFilter";

const MenuColumn = props => {
	const { column } = props;
	const formRef = useRef();
	const [searchValue, setSearchValue] = useState("");
	const isPageSearchCandidate =
		window.location.pathname === "/candidates/search";

	const oldFilter = useFilters();

	const smartFilter = useSmartFilter();

	const {
		setSortBy,
		setFilterValue,
		setFilter,
		setFilterColumnValue,
		setShowDrawer,
		setSelectedFilter,
		handleFilterAdd,
		addSmartQuery,
		clearFilters,
		setSmartQueryFilterValue
	} = isPageSearchCandidate ? smartFilter : oldFilter;

	const { filters, sortBy, columns, smartQuerySchema } = isPageSearchCandidate
		? smartFilter.getState(column.colDef.currentFilterId)
		: oldFilter.getState(column.colDef.currentFilterId);

	const [showFilter, setShowFilter] = useState(false);

	const [mutateUpdateColumn] = useUpdateColumnSettings({
		onSuccess: () => {
			queryCache.invalidateQueries(
				`@getColumnsSettings_${column.colDef.typeTag}`
			);
		},
		onError: () => {
			toaster.danger("Error while saving changes.");
		}
	});

	useEffect(() => {
		formRef.current?.focus();
	}, [showFilter]);

	useEffect(() => {
		const filterObject = find(filters, filter => {
			return filter.name === column.colId;
		});
		setSearchValue(filterObject?.value);
	}, [column.colId, filters]);

	const handlePinCLick = useCallback(type => {
		// pin left :
		if (type === "PIN_LEFT") {
			mutateUpdateColumn({
				columns: [column?.colId],
				type: column.colDef.typeTag,
				pinned: "left",
				tag: "pin_column_left"
			});
			column?.userProvidedColDef?.refGrid?.current?.columnApi?.applyColumnState(
				{
					state: [{ colId: column?.colId, pinned: "left" }],
					defaultState: { pinned: true }
				}
			);
		}
		// pin right :
		if (type === "PIN_RIGHT") {
			mutateUpdateColumn({
				columns: [column?.colId],
				type: column.colDef.typeTag,
				pinned: "right",
				tag: "pin_column_right"
			});
			column?.userProvidedColDef?.refGrid.current?.columnApi?.applyColumnState({
				state: [{ colId: column?.colId, pinned: "right" }],
				defaultState: { pinned: true }
			});
		}
		//Unpin :
		if (type === "UNPIN") {
			mutateUpdateColumn({
				columns: [column?.colId],
				type: column.colDef.typeTag,
				tag: "unpin_columns"
			});
			column?.userProvidedColDef?.refGrid?.current?.columnApi?.applyColumnState(
				{
					state: [{ colId: column?.colId, pinned: null }],
					defaultState: { pinned: true }
				}
			);
		}
	}, []);
	const handleSortActions = type => {
		const field = column.colDef.columnSortMap[column?.colId];
		setSortBy({
			field,
			order: type
		});
		if (type === "unsort") {
			setSortBy({});
		}
	};
	useOnClickOutside(formRef, () => {
		setShowFilter(false);
	});
	const handleFilterChange = event => {
		const value = event.target.value;
		setSearchValue(value);
		const key = column.colDef.columnFiltersMap[column.colId];
		const filterObject = find(filters, filter => {
			return filter.name === key;
		});
		if (isPageSearchCandidate && smartQuerySchema.length > 0) {
			const smartQueryObject = find(smartQuerySchema, filter => {
				return filter.name === key;
			});
			if (smartQueryObject) {
				setSmartQueryFilterValue(
					_get(smartQueryObject, "id", ""),
					value,
					"change"
				);
			} else {
				const new_columns = flatMapDeep(columns, el => {
					return el.children ? el.children : el;
				});
				const filter_data = new_columns.find(
					el => el.name === column.colDef.columnFiltersMap[column.colId]
				);

				addSmartQuery({ ...filter_data, value });
			}
		} else {
			if (filterObject) {
				setFilterValue(_get(filterObject, "id", ""), value, "change");
			} else {
				const new_columns = flatMapDeep(columns, el => {
					return el.children ? el.children : el;
				});
				const filter_data = new_columns.find(
					el => el.name === column.colDef.columnFiltersMap[column.colId]
				);

				if (filters.length === 1 && filters[0].name === "") {
					setFilterColumnValue({
						...filter_data,
						value: value,
						id: _get(filters[0], "id", "")
					});
				} else {
					setFilter({ ...filter_data, value });
				}
			}
		}
	};

	const handleFilterClick = () => {
		if (column.colDef.checkFilterColumns.includes(column.colId)) {
			setShowFilter(true);
		} else if (isPageSearchCandidate && smartQuerySchema.length > 0) {
			if (
				!smartQuerySchema.find(
					el => el.name === column.colDef.columnFiltersMap[column.colId]
				)
			) {
				if (filters.length === 1 && filters[0].name === "") {
					clearFilters();
				}
				const new_columns = flatMapDeep(columns, el => {
					return el.children ? el.children : el;
				});
				const filter_data = new_columns.find(
					el => el.name === column.colDef.columnFiltersMap[column.colId]
				);
				addSmartQuery(filter_data);
			}
			setShowDrawer(true);
			setSelectedFilter(column.colDef.columnFiltersMap[column.colId]);
		} else {
			if (
				!filters.find(
					el => el.name === column.colDef.columnFiltersMap[column.colId]
				)
			) {
				if (filters.length === 1 && filters[0].name === "") {
					clearFilters();
				}
				const new_columns = flatMapDeep(columns, el => {
					return el.children ? el.children : el;
				});
				const filter_data = new_columns.find(
					el => el.name === column.colDef.columnFiltersMap[column.colId]
				);
				handleFilterAdd(filter_data);
			}
			setShowDrawer(true);
			setSelectedFilter(column.colDef.columnFiltersMap[column.colId]);
		}
	};
	const isSorted =
		column.colId ===
		findKey(column.colDef.columnSortMap, el => {
			return el === sortBy?.field;
		});
	const typeSort = sortBy?.order;

	const renderSortASC = () => {
		if (isSorted && typeSort === "asc") {
			return (
				<Item key="Unsort">
					<UnSortUp
						width="1.125rem"
						height="1.125rem"
						color="#A3A3A3"
						stroke="1.5"
					/>
					<div className={styles.headerItem}>Unsort</div>
				</Item>
			);
		} else {
			return (
				<Item key="Sort by ASC">
					<SortUpIcon
						width="1.125rem"
						height="1.125rem"
						color="#A3A3A3"
						stroke="1.5"
					/>
					<div className={styles.headerItem}>Sort by ASC</div>
				</Item>
			);
		}
	};
	const renderSortDESC = () => {
		if (isSorted && typeSort === "desc") {
			return (
				<Item key="Unsort">
					<UnSortDown
						width="1.125rem"
						height="1.125rem"
						color="#A3A3A3"
						stroke="1.5"
					/>
					<div className={styles.headerItem}>Unsort</div>
				</Item>
			);
		} else {
			return (
				<Item key="Sort by DESC">
					<SortDownIcon
						width="1.125rem"
						height="1.125rem"
						color="#A3A3A3"
						stroke="1.5"
					/>
					<div className={styles.headerItem}>Sort by DESC</div>
				</Item>
			);
		}
	};
	const handleActionsDropDown = key => {
		if (key === "Filter") {
			handleFilterClick();
		} else if (key === "Sort by ASC") {
			handleSortActions("asc");
		} else if (key === "Sort by DESC") {
			handleSortActions("desc");
		} else if (key === "Pin to left") {
			handlePinCLick("PIN_LEFT");
		} else if (key === "Pin to right") {
			handlePinCLick("PIN_RIGHT");
		} else if (key === "Unpin") {
			handlePinCLick("UNPIN");
		} else {
			handleSortActions("unsort");
		}
	};
	return (
		<>
			{!showFilter ? (
				<Menu aria-label="Actions" onAction={handleActionsDropDown}>
					{!column.colDef.hideFilter &&
						column.colId !== "last_note" &&
						column?.colId !== "steps" && (
							<Item key="Filter">
								<FilterIcons
									width="1.125rem"
									height="1.125rem"
									color="#A3A3A3"
									stroke="1.5"
								/>
								<div className={styles.headerItem}>Filter</div>
							</Item>
						)}

					{column.colDef.isSortable && renderSortASC()}
					{column.colDef.isSortable && renderSortDESC()}

					{column.pinned === null && !column.colDef.hidePin && (
						<Item key="Pin to left">
							<PinLeftIcon
								width="1.125rem"
								height="1.125rem"
								color="#A3A3A3"
								stroke="1.5"
							/>
							<div className={styles.headerItem}>Pin to left</div>
						</Item>
					)}
					{column.pinned === null && !column.colDef.hidePin && (
						<Item key="Pin to right">
							<PinRightIcon
								width="1.125rem"
								height="1.125rem"
								color="#A3A3A3"
								stroke="1.5"
							/>
							<div className={styles.headerItem}>Pin to right</div>
						</Item>
					)}

					{column.pinned !== null && !column.colDef.hidePin && (
						<Item key="Unpin">
							<UnpinIcon width={16} height={16} color="#A3A3A3" stroke="2" />
							<div className={styles.headerItem}>Unpin</div>
						</Item>
					)}
				</Menu>
			) : (
				<div className={cx(styles.root, styles.inputStyleColumn)}>
					<input
						onChange={event => handleFilterChange(event)}
						className={styles.input}
						placeholder={"Filter"}
						ref={formRef}
						value={searchValue}
					/>
					<Icon className={styles.icon} />
				</div>
			)}
		</>
	);
};

export default MenuColumn;
