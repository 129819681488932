import React from "react";
import { useTable, useResizeColumns, useFlexLayout } from "react-table";
import _get from "lodash/get";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Text, Box, Flex } from "rebass";
import { StyledTable, Td } from "./Styled";
import Thead from "./Thead";
import EmptyTable from "./EmptyTable";
import GlobalTooltip from "common/GlobalTooltip";

const Table = ({
	filters,
	setFilters,
	setPage,
	columns,
	data = [],
	isLoading,
	isFetching,
	emptyTitle,
	emptyText,
	offlineFilters, //Filters that should impact the view but without fetching new data from backend, example: Progress filter in missions
	setOfflineFilters,
	onRowClick,
	clearAll,
	shouldDisableRow,
	disabledRowOverlay
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable(
		{
			columns,
			data
		},
		useResizeColumns,
		useFlexLayout
	);

	return (
		<Flex flexDirection="column" sx={{ position: "relative" }}>
			<Box
				onClick={() => setFilters({})}
				sx={{
					fontFamily: "BasierCircle",
					fontSize: "14px",
					fontWeight: "600",
					color: "rgb(107, 112, 117)",
					cursor: "pointer",
					position: "absolute",
					right: "0",
					top: "-20px"
				}}
			>
				{clearAll ? (
					<>
						<Text as={"span"} className="icon-reset-3"></Text> Clear all
					</>
				) : null}
			</Box>
			<StyledTable {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup, index) => (
						<tr key={index} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, index) => (
								<Thead
									key={index}
									column={column}
									filter={filters[column.filter]}
									setPage={setPage}
									setFilter={value =>
										setFilters({
											...filters,
											[column.filter]: value,
											offset: 0
										})
									}
									offlineFilter={_get(
										offlineFilters,
										`${column.offlineFilter}`
									)}
									setOfflineFilter={value =>
										setOfflineFilters({
											...setOfflineFilters,
											[column.offlineFilter]: value
										})
									}
								/>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{data.length === 0 && !isLoading ? (
						<EmptyTable title={emptyTitle} text={emptyText} />
					) : (
						rows.map((row, index) => {
							prepareRow(row);
							let disabled = shouldDisableRow && shouldDisableRow(row);
							return (
								<GlobalTooltip
									overlay={disabledRowOverlay && disabledRowOverlay(row)}
									key={index}
									withWrappingDiv={false}
									placement={"top"}
									active={!!disabled}
								>
									<tr
										onClick={onRowClick ? e => onRowClick(row, e) : null}
										{...row.getRowProps()}
									>
										{row.cells.map((cell, index) => {
											return (
												<Td
													key={index}
													{...cell.getCellProps([
														{
															className: cell.column.className
														}
													])}
													isFirstElement={index === 0}
													disabled={disabled}
													padding={_get(cell, "column.padding", "")}
													onClick={e => cell.column.onClick?.(e, row)}
												>
													{isLoading || isFetching ? (
														<div className="skeleton-container">
															<SkeletonTheme color="#dadee4">
																<Skeleton width={"100%"} />
															</SkeletonTheme>
														</div>
													) : disabled ? (
														<div className="g-tool">
															<span>{cell.render("Cell")}</span>
														</div>
													) : (
														<span>{cell.render("Cell")}</span>
													)}
												</Td>
											);
										})}
									</tr>
								</GlobalTooltip>
							);
						})
					)}
				</tbody>
			</StyledTable>
		</Flex>
	);
};

export default Table;
