import React, { useState, useRef, useEffect } from "react";
import { utcTimeStamp } from "config/helpers";
import { DayPickerRangeController } from "react-dates";
import styled from "styled-components";
import { colors } from "config/styles";
import useOnClickOutside from "hooks/useOnClickOutside";
import get from "lodash/get";
import Portal from "common/ListFilter/components/Portal";

export const HandleDatePicker = styled.div`
	display: flex;
	flex-direction: column;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
	border-radius: 3px;
	position: absolute;
	z-index: 9;
	top: 5px;
	right: -312px;
	background: white;
	.DayPicker__withBorder {
		box-shadow: unset;
		border-radius: unset;
		border-right: 1px solid ${colors.grayBorder};
		width: unset !important;
		> div {
			display: flex;
		}
		outline: 0;
	}
	.DayPickerKeyboardShortcuts_buttonReset {
		display: none;
	}
	.CalendarDay__selected_span {
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		height: 5px;
		&:last-child {
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
		}
	}

	.CalendarDay__selected_span,
	.CalendarDay__selected_start {
		~ .CalendarDay__selected_span {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	.CalendarDay__selected,
	.CalendarDay__selected:active {
		background: ${colors.primaryColor} !important;
		border: unset;
		color: ${colors.white} !important;
		border-radius: 50px;
	}

	.CalendarDay__default {
		border: unset;
		outline: 0;
	}
	.CalendarDay__default:hover {
		background: ${colors.primaryColor} !important;
		color: ${colors.white} !important;
	}
	.CalendarDay__selected_span {
		background: #ffece6 !important;
		border: unset !important;
		color: #000 !important;
	}
	.CalendarDay__selected_span:hover {
		border-radius: 50px !important;
	}
	.CalendarDay__default:hover {
		background: #ffece6;
		color: inherit;
		border-radius: 50px;
		border: unset;
	}
	.CalendarDay__hovered_span {
		background: none;
	}
	.CalendarDay__hovered_span:hover {
		background: none;
	}
	.date-range-action {
		display: flex;
		justify-content: flex-end;
		padding: 16px;
		.cancel-button {
			background: #ffffff;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
			border-radius: 4px;
			border: 1px solid #babfc3;
			span {
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;

				color: #202223;
			}
		}
		.apply-button {
			background: #fd5749;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
				inset 0px -1px 0px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			margin-left: 24px;
			span {
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 20px;

				color: #ffffff;
			}
		}
	}
`;

const DateRange = ({ onChange, closeMenu, value, id }) => {
	const monthController = useRef();
	const [startDate, setStartDate] = useState(window.moment().startOf("month"));
	const [endDate, setEndDate] = useState(window.moment().endOf("month"));
	const [focusedNode, setfocusedNode] = useState(false);

	const pickerRef = useRef();

	useOnClickOutside(pickerRef, () => {
		closeMenu();
	});

	const [focusedInput, setFocusedInput] = useState("startDate");

	useEffect(() => {
		if (focusedInput === "startDate") {
			monthController.current.onMonthSelect(
				monthController.current.month,
				startDate.format("M")
			);
		}
	}, [startDate]);
	useEffect(() => {
		if (get(value, "length")) {
			setStartDate(window.moment.unix(value[0]));
			setEndDate(window.moment.unix(value[1]));
		}
	}, [value]);

	useEffect(() => {
		id && setfocusedNode(document.getElementById(id));
	}, [id]);

	let nodeRect = { x: 0, y: 0 };
	if (focusedNode) {
		nodeRect = focusedNode.getBoundingClientRect();
	}

	const onDatesChange = ({ startDate, endDate }) => {
		if (focusedInput === "startDate" || endDate === null) {
			return setStartDate(startDate);
		}

		setEndDate(endDate);
	};
	const onApply = () => {
		onChange([
			utcTimeStamp({ date: startDate, isStart: true }),
			utcTimeStamp({ date: endDate, isStart: false })
		]);
		closeMenu();
	};

	return (
		<Portal nodeRect={nodeRect} id={id}>
			<HandleDatePicker className="date_container" ref={pickerRef}>
				<DayPickerRangeController
					onDatesChange={onDatesChange}
					focusedInput={focusedInput}
					numberOfMonths={2}
					startDate={startDate}
					endDate={focusedInput === "endDate" ? null : endDate}
					renderMonthElement={(...args) => {
						monthController.current = {
							month: args[0].month,
							onMonthSelect: args[0].onMonthSelect
						};
						return args[0].month.format("MMMM YYYY");
					}}
					onFocusChange={focusedInput =>
						setFocusedInput(!focusedInput ? "startDate" : focusedInput)
					}
					initialVisibleMonth={() => window.moment().subtract(2, "month")}
				/>
				<div className="date-range-action">
					<button onClick={closeMenu} className="cancel-button">
						<span>Cancel</span>
					</button>
					<button onClick={onApply} className="apply-button">
						<span>Apply</span>
					</button>
				</div>
			</HandleDatePicker>
		</Portal>
	);
};

export default DateRange;
