import useWindowSize from "hooks/useWindowSize";
import React from "react";
import { Link } from "react-router";
import cx from "classnames";

const RecoverSuccess = ({ isFromVacancy }) => {
	const isMobile = useWindowSize();

	return (
		<p
			className={cx("description", {
				mobileDescription: isMobile
			})}
		>
			{`We've sent you an e-mail with a link and instructions to
			update your password${
				isFromVacancy
					? ", note that we'll take over your application by following this process."
					: "."
			}`}
			If needed, don&#39;t hesitate to{" "}
			<Link
				className={cx("link contact-team-link", {
					mobileDescription: isMobile
				})}
				to="/ask-question"
			>
				contact our support team!
			</Link>
		</p>
	);
};

export default RecoverSuccess;
