import React from "react";
import PropTypes from "prop-types";
import Modal from "./index";

const SuccessModal = ({
	onClose,
	title,
	description,
	modalName,
	className,
	buttonCloseClassName
}) => {
	return (
		<Modal
			fixed={true}
			onClose={onClose}
			modalName={modalName}
			className={className}
			buttonCloseClassName={buttonCloseClassName}
		>
			<div className="invite-success">
				<div className="icon">
					<i className="icon icon-check" />
				</div>

				<h3 className="title">{title}</h3>

				<div className="bid-message">{description}</div>

				<button className="btn btn-faded bid-button" onClick={onClose}>
					Close window
				</button>
			</div>
		</Modal>
	);
};

SuccessModal.propTypes = {
	title: PropTypes.string,
	modalName: PropTypes.string,
	onClose: PropTypes.func
};

export default SuccessModal;
