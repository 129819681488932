import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { TIMESHEET_REPORTING_SETTINGS } from "config/api-endpoints";
export const queryKey = "@timesheetSettings/reportingSettings";

const getReportingSettingsSetting = () =>
	client(TIMESHEET_REPORTING_SETTINGS, {
		body: {}
	});

const useReportingSettings = (options, queryOptions = {}) =>
	useQuery([queryKey, options], getReportingSettingsSetting, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useReportingSettings;
