import React, { memo, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogTitle
} from "../../CreateNewSupplier/Dialog";
import s from "../../CreateNewSupplier/create-supplier.module.scss";
import style from "./size-filter-dialog.module.scss";
import { Select } from "@rebass/forms";

function SizeFilterDialog({ isOpen, onClose, store }) {
	const { size, setSize } = store(state => ({
		size: state.size,
		setSize: state.setSize
	}));

	const { handleSubmit, register, reset } = useForm({
		shouldUnregister: true,
		defaultValues: {
			size
		}
	});

	useEffect(() => {
		if (!size) return;
		reset({ size: `${size.from}-${size.to}` });
	}, [reset, size]);

	const onSubmit = fields => {
		const size = fields.size?.split("-");
		setSize({
			from: +size?.[0],
			to: +size?.[1]
		});
		onClose();
	};

	return (
		<Dialog open={isOpen}>
			<DialogContent onEscapeKeyDown={onClose}>
				<DialogTitle>Filter by company size</DialogTitle>
				<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<Select className={style.select} {...register("size")}>
						<option value={"0-50"}>0 - 50</option>
						<option value={"51-100"}>51 - 100</option>
						<option value={"101-200"}>101 - 200</option>
						<option value={"201-300"}>201 - 300+</option>
					</Select>
					<div className={s.dialogFooter}>
						<button onClick={onClose} className={s.cancelButton}>
							Cancel
						</button>
						<button type={"submit"} className={s.mainButton}>
							Add to filters
						</button>
					</div>
				</form>
				<DialogClose className={s.dialogClose} onClick={onClose}>
					<span>&times;</span>
				</DialogClose>
			</DialogContent>
		</Dialog>
	);
}

export default memo(SizeFilterDialog);
