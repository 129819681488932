// import React from "react";
import { OPERATORS } from "common/FilterDrawer/utils";
import {
	getCreatedBy,
	getCandidate,
	getNoteType,
	getVisibility
} from "./_helper";

import { TYPES } from ".";
import creationDateIcon from "static/icons/drawer-notes/creationDateIcon.svg";
import createdByIcon from "static/icons/drawer-notes/createdByIcon.svg";
import visibilityIcon from "static/icons/drawer-notes/visibilityIcon.svg";
import vacancyIcon from "static/icons/drawer-notes/vacancyIcon.svg";
import contentIcon from "static/icons/drawer-notes/contentIcon.svg";
import noteTypeIcon from "static/icons/drawer-notes/noteTypeIcon.svg";
import coupleIcon from "static/icons/person-icon.svg";
export const COLUMNS = [
	{
		id: 2,
		label: "Creation date",
		name: "created_at",
		type: TYPES.date,
		operator: OPERATORS.is,
		icon: creationDateIcon
	},
	{
		id: 5,
		label: "Candidate",
		name: "user_id",
		icon: coupleIcon,
		type: TYPES.array,
		operator: OPERATORS.is,
		payload: {
			displayRating: false,
			primaryHeaderContent: () => "Candidate remained",
			secondaryHeaderContent: () => "Candidate selected",
			useServerFilter: false,
			queryFn: getCandidate
		}
	},
	{
		id: 1,
		label: "Created by",
		name: "created_by",
		icon: createdByIcon,
		type: TYPES.array,
		operator: OPERATORS.is,
		payload: {
			displayRating: false,
			primaryHeaderContent: () => "Created by remained",
			secondaryHeaderContent: () => "Created by selected",
			queryFn: getCreatedBy
		}
	},

	{
		id: 3,
		label: "Note Type",
		name: "note_type",
		type: TYPES.array,
		operator: OPERATORS.is,
		payload: {
			displayRating: false,
			primaryHeaderContent: () => "Types remained",
			secondaryHeaderContent: () => "Types selected",
			useServerFilter: false,
			queryFn: getNoteType
		},
		icon: noteTypeIcon
	},
	{
		id: 4,
		label: "Vacancy title",
		name: "job_title",
		type: TYPES.text,
		operator: OPERATORS.contains,
		icon: vacancyIcon
	},
	{
		id: 5,
		label: "Visibility",
		name: "visibility",
		icon: visibilityIcon,
		type: TYPES.array,
		operator: OPERATORS.is,
		payload: {
			displayRating: false,
			primaryHeaderContent: () => "Visibility remained",
			secondaryHeaderContent: () => "Visibility selected",
			useServerFilter: false,
			queryFn: getVisibility
		}
	},
	{
		id: 5,
		label: "Content",
		name: "description",
		type: TYPES.text,
		operator: OPERATORS.contains,
		icon: contentIcon
	}
];
