import React from "react";
import { ReactComponent as InfoIcon } from "static/icons/info-circle-dark-grey.svg";
import Tooltip from "common/Tippy";
import styles from "./bid-details-tooltips.module.scss";

const ConsultancyContactInfoTooltip = () => {
	return (
		<Tooltip
			className={styles.tooltip}
			content={
				<div className={styles.container}>
					The applicant is employed by a consultancy. The contact information
					belongs to the business unit manager responsible for arranging
					interviews and contract negotiations.
				</div>
			}
			theme="dark"
			placement="bottom"
		>
			<InfoIcon />
		</Tooltip>
	);
};

export default ConsultancyContactInfoTooltip;
