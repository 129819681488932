import * as types from "../actions/actionTypes";
import { getLocalUser } from "config/helpers";

const initialState = {
	isFetching: false,
	userBlocked: false,
	isLoggedIn: !!getLocalUser(),
	user: getLocalUser() || {},
	showWelcome: false,
	onboardingAlreadyStarted: false
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case types.LOGIN_SUCCESS:
			return Object.assign({}, state, {
				isLoggedIn: true,
				user: { ...action.data }
			});
		case types.USER_BLOCKED:
			return Object.assign({}, state, {
				userBlocked: action.error.blocked_account,
				is_account: action.error.blocked_account,
				email: action.data.email
			});
		case types.LOGOUT_SUCCESS:
			return Object.assign({}, state, {
				isLoggedIn: false,
				user: {}
			});
		case types.TOGGLE_WELCOME:
			return Object.assign({}, state, {
				showWelcome: !state.showWelcome
			});
		case types.TOGGLE_GET_STARTED_DIALOG:
			return Object.assign({}, state, {
				onboardingAlreadyStarted: !state.onboardingAlreadyStarted
			});
		default:
			return state;
	}
}
