import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _has from "lodash/has";
import _get from "lodash/get";

import ClientBidView from "./ClientBidView";
import {
	fetchBid,
	viewContactInfo,
	toggleInterviewModal,
	interviewDispatch,
	acceptInterview,
	cancelInterview,
	refuseInterview,
	toggleContractModal,
	ProposeContract,
	rejectApplication,
	toggleRejectApplicationModal,
	acceptContract,
	showVideoConf,
	hideVideoConf,
	loadProfile
} from "../../../actions/bidActions";

import { toggleNotesModal } from "modules/vacancy/actions";

import { toggleHelpModal } from "modules/app/actions/ListsActions";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { bidSelector, showVideoConfSelector } from "../../../selectors";
import { cvSelector } from "modules/cv/selectors/cvSelectors";
import {
	BID,
	INTERVIEW,
	CONTRACT,
	ON_MISSION_LABEL,
	SIGNED,
	ACCEPTED,
	CANCELLED,
	REJECTED,
	REFUSED,
	EXPIRED,
	DECLINED,
	SENT,
	DONE,
	CLIENT,
	CONSULTANCY
} from "config";
import { capitalize } from "common/Functions";
import { historyPush } from "config/helpers";
import { stringify } from "query-string";
import { getJobViewRoute } from "modules/RequestView/RequestView";

export const getClientBidViewRoute = (id, search) => {
	return `/jobs/client/bids/${id || `:id`}${search ? `?${search}` : ""}`;
};

export const getClientBidViewPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

export const getSteps = data => {
	const hasInterview = _has(data, "interview._id");
	const interviewStatus = _get(data, "interview.status");
	const hasContract = _has(data, "contract._id");
	const contractStatus = _get(data, "contract.status");
	const contractSigned = _get(data, "contract.signed_status") === SIGNED;
	const hasMission = _get(data, "contract.status") === ACCEPTED;
	const applicationStatus = _get(data, "status");
	const errorStatus = [CANCELLED, REJECTED, REFUSED];
	const pendingStatus = [SENT, DECLINED, EXPIRED, ACCEPTED];

	const defaultStatus = "wait";

	const steps = [
		{
			label: capitalize(BID),
			status: errorStatus.includes(applicationStatus)
				? "error"
				: hasInterview || hasContract
				? "finish"
				: "process"
		},
		{
			label: capitalize(INTERVIEW),
			status: errorStatus.includes(interviewStatus)
				? "error"
				: interviewStatus === DONE
				? "finish"
				: pendingStatus.includes(interviewStatus) || hasContract
				? "process"
				: defaultStatus
		},
		{
			label: capitalize(CONTRACT),
			status: errorStatus.includes(contractStatus)
				? "error"
				: contractStatus === ACCEPTED
				? "finish"
				: pendingStatus.includes(contractStatus)
				? "process"
				: defaultStatus
		},
		{
			label: capitalize(ON_MISSION_LABEL),
			status: contractSigned ? "finish" : hasMission ? "process" : defaultStatus
		}
	];

	return steps;
};

export class ClientBidViewContainer extends Component {
	componentDidMount() {
		const { params } = this.props;
		if (params && params.id) {
			this.props.actions.fetchBid(params.id);
			this.props.actions.loadProfile(params.id);
		}
		const hasVideoCall = _get(this.props, "location.query.videocall", false);
		if (Boolean(hasVideoCall)) {
			this.props.actions.showVideoConf();
		}
	}

	componentDidUpdate({ params }) {
		const {
			params: { id },
			actions
		} = this.props;
		if (id && params.id !== id) {
			actions.fetchBid(id);
			actions.loadProfile(params.id);
		}

		if (this.props.bid?.data?.job) {
			const paramsPath = stringify({
				bid_id: params.id
			});
			historyPush(
				`${getJobViewRoute(this.props.bid?.data?.job?._id)}?${paramsPath}`
			);
		}
	}

	render() {
		const {
			route,
			params,
			bid,
			user,
			actions: {
				viewContactInfo,
				fetchBid,
				toggleInterviewModal,
				interviewDispatch,
				acceptInterview,
				cancelInterview,
				toggleRejectApplicationModal,
				rejectApplication,
				toggleContractModal,
				ProposeContract,
				refuseInterview,
				acceptContract,
				toggleHelpModal,
				showVideoConf: showVideoConfAction,
				hideVideoConf,
				toggleNotesModal
			},
			showVideoConf,

			headline,
			functions = [],
			user: { company_type },

			cv_doc,

			status,
			params: { bid_id },
			ownCV,
			activeTab,
			cv_docs,
			initialSkills,
			_id,
			history
		} = this.props;

		let isClient = company_type === CLIENT;

		let _ownCV = ownCV || company_type === CONSULTANCY;

		return (
			<ClientBidView
				route={route}
				params={params}
				{...bid}
				viewContactInfo={viewContactInfo}
				fetchBid={fetchBid}
				toggleInterviewModal={toggleInterviewModal}
				interviewDispatch={interviewDispatch}
				acceptInterview={acceptInterview}
				user={user}
				onCancel={cancelInterview}
				toggleRejectApplicationModal={toggleRejectApplicationModal}
				onRefuse={refuseInterview}
				rejectApplication={rejectApplication}
				rejectModal={bid.rejectModal}
				toggleContractModal={toggleContractModal}
				ProposeContract={ProposeContract}
				acceptContract={acceptContract}
				toggleHelpModal={toggleHelpModal}
				getSteps={getSteps}
				showVideoConf={showVideoConf}
				onCallEnd={hideVideoConf}
				onCallStart={showVideoConfAction}
				bid_id={bid_id}
				_ownCV={_ownCV}
				headline={headline}
				functions={functions}
				activeTab={activeTab}
				isClient={isClient}
				showBack={this.showBack}
				viewProfile={this.viewProfile}
				cv_doc={cv_doc}
				cv_docs={cv_docs}
				showModal={this.props.showModal}
				status={status}
				initialSkills={initialSkills}
				id={_id}
				toggleNotesModal={toggleNotesModal}
				history={history}
			/>
		);
	}
}

ClientBidViewContainer.propTypes = {
	actions: PropTypes.object.isRequired,
	params: PropTypes.shape({
		id: PropTypes.string.isRequired
	}).isRequired,
	bid: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	...cvSelector(state),
	initialSkills: state.cv.skills,
	bid: bidSelector(state),
	user: onlineUserSelector(state),
	showVideoConf: showVideoConfSelector(state)
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(
		{
			fetchBid,
			viewContactInfo,
			toggleInterviewModal,
			interviewDispatch,
			acceptInterview,
			cancelInterview,
			toggleRejectApplicationModal,
			toggleContractModal,
			ProposeContract,
			rejectApplication,
			refuseInterview,
			acceptContract,
			toggleHelpModal,
			showVideoConf,
			hideVideoConf,
			loadProfile,
			toggleNotesModal
		},
		dispatch
	)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClientBidViewContainer);
