import toaster from "common/Toaster";
import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { browserHistory } from "react-router";
import {
	PREVIEW_JOB,
	UPDATE_USER_SETTING,
	APPLY_TO_VACANCY_ENDPOINT
} from "config/api-endpoints";
import { USER_SETTINGS_OPTIONS, APPLY_TO_VACCANCY_MODAL } from "config";

const getJobData = body => client(PREVIEW_JOB, { body });
const onError = () => {
	toaster.danger("404: not found.");
	browserHistory.push("/404");
};

const JOB_DETAILS_QUERY = "JOB_DETAILS_QUERY";
export const useJobDetails = (payload, options = {}) =>
	useQuery([JOB_DETAILS_QUERY, payload], () => getJobData(payload), {
		onError,
		retry: 1,
		refetchOnWindowFocus: false,
		...options
	});

export const applyToJob = data =>
	client(APPLY_TO_VACANCY_ENDPOINT, { body: data });

export const trackDoNotShowAgain = () =>
	client(UPDATE_USER_SETTING, {
		body: {
			type: USER_SETTINGS_OPTIONS,
			user_options: { [APPLY_TO_VACCANCY_MODAL]: true }
		}
	});
