import * as types from "../actions/types";
import produce from "immer";

const initialState = {
	isFetching: false,
	sectors: [],
	subSectors: [],
	functions: [],
	filter: {
		title: "",
		category: null,
		subcategory: null,
		function: null,
		estimation: {
			gte: 0
		},
		estimation_type: null,
		project_estimation: null,
		skills: [],
		seniority: [],
		remote_eligibility: [],
		office_days: [],
		location: { distance: null, lat: 0, lon: 0, viewport: [], iso_country: "" },
		type: [],
		duration: "",
		languages: [],
		offset: 0,
		sort_by: [{ field: "publish_date", order: "desc" }]
	},
	minBudget: 0,
	maxBudget: 1000000,
	addedSkills: [],
	addedLanguages: [],
	data: [],
	offlinePopup: false
};

export default produce((draft, action) => {
	switch (action.type) {
		case types.FETCHED_JOBS:
			draft.data = action.data;
			draft.offset = action.data.offset;
			draft.size = action.data.size;
			draft.total = action.data.total;
			break;
		case types.UPDATE_FILTER:
			draft.filter = action.filter;
			break;
		case types.UPDATE_SKILLS:
			draft.addedSkills = action.skills;
			break;
		case types.UPDATE_LANGUAGES:
			draft.addedLanguages = action.languages;
			break;

		case types.REST_LANGUAGES:
			draft.addedLanguages = [];
			break;
		case types.REST_SKILLS:
			draft.addedSkills = [];
			break;
		case types.TOGGLE_OFFLINE_POPUP:
			draft.offlinePopup = !draft.offlinePopup;
			break;
		case types.SET_SEARCH_SECTORS:
			draft.sectors = action.data;
			break;
		case types.SET_SEARCH_SUBSECTORS:
			draft.subSectors = action.subSectors;
			break;
		case types.SET_SEARCH_FUNCTIONS:
			draft.functions = action.data;
			break;
		case types.CLEAR_ALL_FILTERS:
			draft = initialState;
			break;
		default:
			return draft;
	}
}, initialState);
