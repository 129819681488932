import { queryCache, useMutation } from "react-query";
import toaster from "common/Toaster";
import _get from "lodash/get";
import { viewOverviewKey } from "modules/candidate/Profile/api/useOverview";
import {
	personalProfileKey,
	saveProfile
} from "modules/candidate/Profile/api/usePersonalProfile";
import { profileOverviewKey } from "modules/candidate/Profile/api/useViewCandidateInternal";
import { editHistoryKey } from "modules/candidate/Profile/api/useEditHistory";

export const useSaveProfile = onSuccess => {
	const res = useMutation(saveProfile, {
		onSuccess: () => {
			toaster.success("Personal profile has been updated ");
			queryCache.invalidateQueries(personalProfileKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(profileOverviewKey);
			queryCache.invalidateQueries(editHistoryKey);
			onSuccess();
		},
		onError: e => {
			if (e && e.status === 422) {
				const errorMsg =
					_get(e, "detail.name") || _get(e, "detail.fields_data[0]");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			}
		}
	});

	return res;
};
