import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_EVALUATION } from "config/api-endpoints";

function deleteEvaluation(body) {
	return client(DELETE_EVALUATION, {
		body
	});
}

function useDeleteEvaluation(options) {
	return useMutation(deleteEvaluation, options);
}

export default useDeleteEvaluation;
