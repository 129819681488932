import React from "react";
import _get from "lodash/get";
import AxiosHelper from "config/axios-helper";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { HIDE_PROFILE_ENDPOINT } from "config/api-endpoints";

const HideProfileFromSearch = ({
	active,
	closeModal,
	profile_id,
	selectedTemplate,
	nextAction
}) => {
	const hideProfile = () => {
		return AxiosHelper.post({
			url: HIDE_PROFILE_ENDPOINT,
			data: {
				profile_id,
				template_id: _get(selectedTemplate, "_id")
			},
			toastMessage: "Profile hidden from search results.",
			isModal: true
		}).then(() => {
			nextAction && nextAction();
			closeModal();
		});
	};

	return (
		<ConfirmationModal
			active={active}
			modalName="hide-from-search"
			onClose={closeModal}
			firstButton={{
				action: hideProfile,
				label: "YES, HIDE FROM RESULTS",
				type: "danger"
			}}
			title="Are you sure you would like to hide
        this profile from the search results?"
			content={
				<>
					This candidate will no longer appear in the search results for this
					template: <br />
					{_get(selectedTemplate, "name")}
				</>
			}
			type={ConfirmationTypes.error}
		/>
	);
};

export default HideProfileFromSearch;
