import React from "react";
import JobItem from "./JobItem";

const JobList = ({ jobs, onSkillClick, selectedSkills }) => {
	return (
		<div>
			{jobs.map((job, i) => (
				<JobItem
					selectedSkills={selectedSkills}
					onSkillClick={onSkillClick}
					job={job}
					key={i}
				/>
			))}
		</div>
	);
};

export default JobList;
