import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	publishRequestWarning,
	publishRequestAction,
	getCampaignsPerformance
} from "../../actions";
import { ConfirmationModal, ConfirmationTypes } from "../../../../common/modal";
import { VACANCY } from "../../../../config";

class PublishJob extends Component {
	closeModal = e => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.props.onClose?.();
		this.props.dispatch(publishRequestWarning(null));
	};

	publish = () => {
		const {
			job: { id, type, versions },
			dispatch
		} = this.props;
		let versionId;
		for (const key in versions) {
			if (versions[key].original) {
				versionId = versions[key].version;
				break;
			}
		}
		dispatch(publishRequestAction(id, true, type, versionId))
			.then(() => {
				dispatch(getCampaignsPerformance(id));
				this.props.onSuccess();
			})
			.catch(err => err);
	};

	renderTextModal = type => {
		let title = "";
		let content = "";
		let button = "";

		if (type === VACANCY) {
			title = `Are you sure you would like to open this ${type} for internal use now?`;
			content = `Once your ${type} has been opened, you will no longer be able to edit it. 
			You will only be able to either close or duplicate it.`;
			button = `Yes, open this ${type}.`;
		} else {
			title = `Are you sure you would like to publish this ${type} now?`;
			content = `Once your ${type} has been published, you will no longer be able to edit it. 
			You will only be able to either unpublish or duplicate it.`;
			button = `Yes, publish this ${type}.`;
		}
		return { title, content, button };
	};

	render() {
		const {
			job: { type }
		} = this.props;

		return (
			<ConfirmationModal
				modalName="publish_job"
				type={ConfirmationTypes.confirmation}
				onClose={this.closeModal}
				title={this.renderTextModal(type).title}
				content={this.renderTextModal(type).content}
				firstButton={{
					label: this.renderTextModal(type).button,
					action: this.publish
				}}
			/>
		);
	}
}

PublishJob.propTypes = {
	onSuccess: PropTypes.func
};

PublishJob.defaultProps = {
	onSuccess: () => {}
};

export default connect()(PublishJob);
