import React from "react";
import { ReactComponent as ErrorIcon } from "common/react-hook-form-components/icons/elements-icons-error.svg";
import styles from "common/react-hook-form-components/uploader/coumpound_uploader/uploader.module.scss";
function Error({ error }) {
	return (
		<React.Fragment>
			{error && (
				<span className={styles.error}>
					<ErrorIcon />
					<span className={styles.text}>{error}</span>
				</span>
			)}
		</React.Fragment>
	);
}

export default Error;
