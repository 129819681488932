import React from "react";
import { ReactComponent as Vector } from "static/vectors/languages-emty-state.svg";
import styles from "./email-template-lang-empty-block.module.scss";

export default function EmailTemplateLangEmptyBlock() {
	return (
		<div className={styles.container}>
			<div className={styles.emptyBlock}>
				<Vector />
				<h3 className={styles.title}>Select a Language to Get Started!</h3>
				<p className={styles.description}>
					To start creating your email template, please select at least one
					language from the button (+) above.
				</p>
			</div>
		</div>
	);
}
