export const REQUEST_VIEW_TABS_IDS = [
	"pipeline",
	"notes",
	"performance",
	"withdrawn",
	"request_details",
	"activities"
];

export const REQUEST_DETAILS_TAB = "request_details";

export const TEMP_INTERVIEW_LOCATION_TYPES = {
	online: "video_conference",
	searchAddress: "search_address",
	companyAddress: "company_address"
};

export const PIPELINE_TEMP_STEPS_OPTIONS = [
	{ value: "internal_review", label: "Internal review" },
	{ value: "interview", label: "Interview" },
	{ value: "contract", label: "Contract" },
	{ value: "mission", label: "Mission" },
	{ value: "custom", label: "Custom" }
];

export const ADD_INTERVIEW_NOTE_MAX_LENGTH = 2000;
