import create from "zustand";

const store = create(set => ({
	entity: null,
	taskData: {},
	isOpen: false,
	highlitedRow: null,
	selectedEntityOption: null,
	setIsOpen: value => set({ isOpen: value }),
	setEntity: value => set({ entity: value }),
	setTaskData: task => set({ taskData: task }),
	setHighlitedRow: id => set({ highlitedRow: id }),
	setSelectedEntityOption: task => set({ selectedEntityOption: task })
}));

const useAddNewTaskStore = () => {
	const {
		isOpen,
		entity,
		taskData,
		setEntity,
		setIsOpen,
		setTaskData,
		highlitedRow,
		setHighlitedRow,
		selectedEntityOption,
		setSelectedEntityOption
	} = store(state => ({ ...state }));
	return {
		isOpen,
		entity,
		taskData,
		setIsOpen,
		setEntity,
		setTaskData,
		highlitedRow,
		setHighlitedRow,
		selectedEntityOption,
		setSelectedEntityOption
	};
};

export default useAddNewTaskStore;
