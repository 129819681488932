import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import { Flex, Box, Text } from "rebass";
import { Helmet } from "react-helmet";
import { browserHistory } from "react-router";
import _has from "lodash/has";
import _get from "lodash/get";
import { Tabs, TabPanel } from "@reach/tabs";
import Steps from "../../../components/shared/stepper";
import BidDetails from "../BidDetailsClient";
import BidHeadLine from "./BidHeadLine";
import ClientInterviewModal from "../../modals/ClientInterview";
import { StyledTabList, StyledTab, StyledTabPanels } from "../Styled";
import {
	CANCELLED,
	SENT,
	DECLINED,
	REFUSED,
	PROPOSE_CONTRACT,
	PROPOSE_SUCCESS,
	CLIENT,
	INVITE_INTERVIEW,
	ACCEPTED,
	SIGNED,
	EXPIRED,
	DONE,
	ADMIN,
	CONSULTANCY,
	FUNCTIONS,
	SKILLS,
	WELCOME_PAGE
} from "config";
import { NEW_BID } from "modules/home/config";
import InterviewDetails from "../InterviewDetails";
import ContractDetails from "../ContractDetails";
import RejectApplicationModal from "../../modals/RejectApplicationModal";
import ProposeContractModal from "../../modals/ProposeContractModal";
import {
	ConfirmationModal,
	ConfirmationTypes,
	SuccessModal
} from "common/modal";
import CancelBidModal from "../../modals/CancelBidModal";
import BidSideBar from "../BidSideBarClient";
import { getIndexOfStepper } from "../freelancer/FreelancerBidView";
import AcceptInterviewModal from "common/AcceptInterviewModal";
import Languages from "modules/cv/components/Tabs/Languages";
import Functions from "modules/job/components/jobprofile/Functions/Functions";
import FreelancerCV from "modules/cv/components/Tabs/FreelancerCV";
import Skills from "modules/cv/components/Tabs/FreelancerSkills";
import HistoryBid from "../History";
import NotesDetails from "modules/vacancy/components/pokeView/NotesDetails";
import { getClientBidViewRoute } from "modules/bids/components/bidview/client/ClientBidViewContainer";
import BackButton from "common/Buttons/BackButton";
import useListPagination from "hooks/useListPagination.js";
import useTemporaryStore from "modules/home/zustand/temporary.store.js";
import { searchParser } from "config/helpers";
import useInterviewStore from "modules/home/containers/interview/interviewStore.js";

const ClientBidView = props => {
	const [showCancelContractModal, setShowCancelContractModal] = useState(false);
	const [showBidModal, setShowBidModal] = useState(false);
	const [
		displayAcceptInterviewConfirmation,
		setDisplayAcceptInterviewConfirmation
	] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);

	const handleTabsChange = index => {
		window.history.replaceState({}, "", `#${index}`);
		setTabIndex(index);
	};
	const searchQueries = searchParser();
	const welcomePageTemporaryStore = useTemporaryStore();
	const temporaryInterviews = useInterviewStore();
	const listNavigationData =
		searchQueries.listKey === NEW_BID
			? _get(welcomePageTemporaryStore, searchQueries.listKey, [])
			: _get(temporaryInterviews, searchQueries.listKey, []);

	const { state, nextId, previousId } = useListPagination({
		data: listNavigationData,
		id: props.params.id
	});

	useEffect(() => {
		browserHistory.replace(
			`${getClientBidViewRoute(state.id)}${window.location.search}`
		);
	}, [state.id]);

	const getInterviewStatusTooltip = (status, islastUserSenderClient) => {
		switch (status) {
			case SENT:
			case DECLINED:
				switch (islastUserSenderClient) {
					case true:
						return "Expecting applicant’s feedback.";
					case false:
						return "Please reply to the applicant's alternate interview proposition.";
					default:
						return "";
				}
			case REFUSED:
			case CANCELLED:
				switch (islastUserSenderClient) {
					case true:
						return "You have rejected this application.";
					case false:
						return "The freelance has canceled his/her application.";
					default:
						return "";
				}

			case EXPIRED:
				return "The interview proposition is overdue, please propose another date.";
			case ACCEPTED:
				return "The interview date, time and place are confirmed.";
			case DONE:
				return "As a next step, you can either, propose a contract, organise another interview, or reject the freelancer's application";
			default:
				return "";
		}
	};

	const getContractStatusTooltip = (status, islastUserSenderClient) => {
		switch (status) {
			case SENT:
			case DECLINED:
				switch (islastUserSenderClient) {
					case true:
						return "Expecting applicant’s feedback.";
					case false:
						return "Please reply to the freelancer's proposition.";
					default:
						return "";
				}
			case CANCELLED:
			case REFUSED:
				switch (islastUserSenderClient) {
					case true:
						return "You have rejected this application.";
					case false:
						return "The freelance has canceled his/her application.";
					default:
						return "";
				}
			case ACCEPTED:
				return (
					<span>
						{" "}
						Congratulations for coming to an agreement! <br />
						wiggli is currently generating the related documents.
					</span>
				);
			case SIGNED:
				return "All contracts are signed and this is now an actual mission.";
			default:
				return "";
		}
	};

	const acceptContract = () => {
		props
			.acceptContract({
				contract_id: _get(props, "data.contract._id"),
				bid_id: _get(props, "params.id")
			})
			.then(() => toggleContractConfirmation())
			.catch(() => toggleContractConfirmation());
	};

	const closeContractModal = () => {
		props.toggleContractModal(false);
	};

	const renderModals = () => {
		const {
			interview_modal,
			interviewDispatch,
			data,
			params,
			user,
			toggleInterviewModal,
			contract_modal,
			ProposeContract
		} = props;
		const isDeclined = _get(data, "contract.status") === DECLINED;
		if (interview_modal.show) {
			switch (interview_modal.type) {
				case INVITE_INTERVIEW:
					return (
						<ClientInterviewModal
							interviewDispatch={interviewDispatch}
							toggleInterviewModal={toggleInterviewModal}
							interview={_get(data, "interview", {})}
							job_title={_get(data, "job.title")}
							is_edit={
								_get(data, "interview.status") === SENT ||
								_get(data, "interview.status") === ACCEPTED
							}
							is_decline={_get(data, "interview.status") === DECLINED}
							bid_id={params.id}
							user={user}
							profile={`${_get(data, "profile_detail.first_name")} ${_get(
								data,
								"profile_detail.last_name"
							)}`}
							listUsers={_get(data, "list_users")}
							attendees_users={_get(data, "interview.attendees_users")}
							companyType={_get(data, "company_type")}
						/>
					);
				default:
					break;
			}
		}

		if (contract_modal.show) {
			switch (contract_modal.type) {
				case PROPOSE_CONTRACT:
					return (
						<ProposeContractModal //! decrepated
							toggleContractModal={closeContractModal}
							bid_id={params.id}
							contract={data.contract}
							proposed_amount={data.proposed_amount}
							ProposeContract={ProposeContract}
							reportingSetting={data.reporting_settings}
							vmsSetting={data.vms_settings}
							margin={data.margin_rate}
						/>
					);
				case PROPOSE_SUCCESS:
					return (
						<SuccessModal
							className="contract-modal"
							onClose={closeContractModal}
							buttonCloseClassName={"props-close-btn"}
							title={
								isDeclined
									? "Other Terms Proposed"
									: "Contract Proposition Sent"
							}
							description={
								<span>
									Your {`${isDeclined ? "adapted" : "contract"}`} proposition
									has successfully been {`${isDeclined ? "sent" : "made"}`}!
									<br /> You’ll receive a feedback as soon as one is provided.
								</span>
							}
						/>
					);
				default:
					break;
			}
		}

		return null;
	};

	const toggleContractConfirmation = () => {
		setShowCancelContractModal(old => !old);
	};

	const cancelBid = () => {
		setShowBidModal(old => !old);
	};

	const acceptInterviewModalAction = () => {
		const { acceptInterview, data, params } = props;
		acceptInterview(
			{
				interview_id: _get(data, "interview._id", {}),
				token_invitation_id: _get(data, "interview.token_invitation_id", {}),
				bid_id: params.id
			},
			() => closeAcceptInterviewModal()
		);
	};
	const displayAcceptInterviewModal = () => {
		setDisplayAcceptInterviewConfirmation(true);
	};
	const closeAcceptInterviewModal = () => {
		setDisplayAcceptInterviewConfirmation(false);
	};
	const handlePreviousNextClick = bidId => {
		browserHistory.replace(
			`${getClientBidViewRoute(bidId)}${window.location.search}`
		);
	};

	const {
		params,
		route,
		data,
		viewContactInfo,
		fetchBid,
		toggleInterviewModal,
		toggleRejectApplicationModal,
		rejectApplication,
		user,
		rejectModal,
		toggleContractModal,
		toggleHelpModal,
		getSteps,
		onCallEnd,
		onCallStart,
		showVideoConf,
		headline,
		_ownCV,
		functions,
		isClient,
		cv_docs,
		showModal,
		initialSkills,
		id,
		history
	} = props;
	const idNextBid = _get(data, "next");
	const idPrevBid = _get(data, "previous");
	const previousDisabled =
		searchQueries.source === WELCOME_PAGE ? state.previousDisabled : !idPrevBid;
	const nextDisabled =
		searchQueries.source === WELCOME_PAGE ? state.nextDisabled : !idNextBid;
	const typeProfile = _get(headline, "type");

	const hasInterview = _has(data, "interview._id");

	const hasContract = _get(data, "contract._id");

	const groups = _get(user, "groups", []);

	const canProposeContract =
		!user.is_member ||
		groups.some(
			group =>
				group.id === _get(data, "job.group._id") && group.role_name === ADMIN
		);

	const stepperStatus = _get(data, "stepper_status");

	const cv = _get(cv_docs, "original_cv");
	const cvDoc = _get(cv_docs, "cv_doc");

	const handlePrevious = () => {
		searchQueries.source === WELCOME_PAGE
			? previousId()
			: handlePreviousNextClick(idPrevBid);
	};
	const handleNext = () => {
		searchQueries.source === WELCOME_PAGE
			? nextId()
			: handlePreviousNextClick(idNextBid);
	};

	return (
		<BodyClassName className="bid gray-bg">
			<div className="view-job-details">
				{route.title && (
					<Helmet>
						<title>{route.title}</title>
					</Helmet>
				)}

				{data && data.job && (
					<div className="full-container">
						<Steps steps={getSteps(data)} />
						<Flex
							pr={15}
							pl={15}
							justifyContent="space-between"
							alignItems="center"
						>
							<Box className="top-actions" sx={{ width: "fit-content" }}>
								<BackButton />
							</Box>
							<Flex>
								<Text
									onClick={handlePrevious}
									as={"button"}
									disabled={previousDisabled}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "11px",
										fontWeight: "600",
										letterSpacing: "0.99px",
										color: "rgb(97, 105, 118)",
										textTransform: "uppercase",
										border: "none",
										background: "none",
										outline: "none",
										"&[disabled]": {
											color: "rgb(160,165,185)",
											cursor: "not-allowed"
										}
									}}
									mr={"10px"}
								>
									&larr; Previous
								</Text>
								<Text
									onClick={handleNext}
									as={"button"}
									disabled={nextDisabled}
									sx={{
										fontFamily: "BasierCircle",
										fontSize: "11px",
										fontWeight: "600",
										letterSpacing: "0.99px",
										color: "rgb(97, 105, 118)",
										textTransform: "uppercase",
										border: "none",
										background: "none",
										outline: "none",
										"&[disabled]": {
											color: "rgb(160,165,185)",
											cursor: "not-allowed"
										}
									}}
								>
									next &rarr;
								</Text>
							</Flex>
						</Flex>
						<Box pr={15} pl={15}>
							<Tabs index={tabIndex} onChange={handleTabsChange}>
								<Box
									mb={12}
									sx={{
										borderRadius: "4px",
										boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
										backgroundColor: "rgb(255, 255, 255)",
										position: "relative"
									}}
								>
									<BidHeadLine
										profile={data.profile_detail}
										accountType={data.company_type}
										userConsultancy={data.user_consultancy}
										viewContactInfo={viewContactInfo}
										fetchBid={fetchBid}
										bid_id={params.id}
										interview={data.interview}
										contractStatus={_get(data, "contract.status")}
										matchingScore={_get(data, "matching_rate")}
										companyName={_get(data, "company_consultancy.name")}
										showProfile={data.show_profile}
									/>
									{typeProfile === CONSULTANCY ? (
										<Flex justifyContent="center">
											<StyledTabList>
												<StyledTab>Process details</StyledTab>
												<StyledTab>CV Content</StyledTab>
												<StyledTab>Functions & Skills</StyledTab>
												<StyledTab>History</StyledTab>
											</StyledTabList>
										</Flex>
									) : (
										<Flex justifyContent="center">
											<StyledTabList>
												<StyledTab>Process details</StyledTab>
												<StyledTab>CV Content</StyledTab>
												<StyledTab>History</StyledTab>
												<StyledTab>Functions & Skills</StyledTab>
											</StyledTabList>
										</Flex>
									)}
								</Box>
								<Flex>
									<Box mr={10} width={9 / 12}>
										{typeProfile === CONSULTANCY ? (
											<StyledTabPanels>
												<TabPanel>
													{data.client_notes && data.client_notes.length > 0 && (
														<Box
															mb={10}
															sx={{
																borderRadius: "4px",
																boxShadow:
																	"0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
																backgroundColor: "rgb(255, 255, 255)"
															}}
														>
															<NotesDetails
																flag="NOTES"
																notes={data.client_notes}
																isBid={true}
															/>
														</Box>
													)}
													{hasContract && (
														<Box
															mb={10}
															p={20}
															sx={{
																borderRadius: "4px",
																boxShadow:
																	"0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
																backgroundColor: "rgb(255, 255, 255)"
															}}
														>
															<ContractDetails
																userType={CLIENT}
																user={user}
																userTarget={data.profile_detail}
																contract={data.contract}
																stepperStatus={stepperStatus}
																visible_status={data.visible_status}
																getContractStatusTooltip={
																	getContractStatusTooltip
																}
																reportingSetting={data.reporting_settings}
															/>
														</Box>
													)}
													{hasInterview && (
														<Box>
															<InterviewDetails
																data={data.interview}
																user={user}
																userTarget={data.profile_detail}
																stepperStatus={stepperStatus}
																visible_status={data.visible_status}
																getInterviewStatusTooltip={
																	getInterviewStatusTooltip
																}
																showVideoConf={showVideoConf}
																onCallStart={onCallStart}
																onCallEnd={onCallEnd}
															/>
														</Box>
													)}
													<Box
														mb={10}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<BidDetails
															status={data.status}
															created_at={data.created_at}
															margin_value={data.margin_value}
															proposed_amount={data.proposed_amount}
															available_date={data.available_date}
															description={data.description}
															visible_status={data.visible_status}
															flag="BID"
															type={user.company_type || ""}
															stepperStatus={stepperStatus}
															user={user}
															bid_details={data}
														/>
													</Box>
												</TabPanel>
												<TabPanel>
													<Box
														mb={10}
														sx={{
															borderRadius: "4px",
															boxShadow: "none",
															backgroundColor: "rgb(255, 255, 255)",
															padding: 0
														}}
														id="resume"
													>
														<FreelancerCV
															cv={cv}
															id={id}
															canUpdateCV={_ownCV}
															cvDoc={cvDoc}
														/>
													</Box>
												</TabPanel>
												<TabPanel>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)",
															["&#resume"]: {
																background: "transparent",
																padding: "0"
															}
														}}
														id="resume"
													>
														<Box id="can-do">
															<Functions
																functions={functions}
																showModal={() => showModal(FUNCTIONS)}
																canUpdateFunctions={_ownCV}
																isFreelancer
															/>
														</Box>
													</Box>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<Skills
															skills={initialSkills}
															functionsLength={_get(functions, "length", 0)}
															showModal={() => showModal(SKILLS)}
															ownCV={_ownCV}
															isClient={true}
														/>
													</Box>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<Languages
															ownCV={_ownCV}
															isClient={isClient}
															isFreelancer
														/>
													</Box>
												</TabPanel>
												<TabPanel>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<HistoryBid history={history} />
													</Box>
												</TabPanel>
											</StyledTabPanels>
										) : (
											<StyledTabPanels>
												<TabPanel>
													{data.client_notes && data.client_notes.length > 0 && (
														<Box
															mb={10}
															sx={{
																borderRadius: "4px",
																boxShadow:
																	"0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
																backgroundColor: "rgb(255, 255, 255)"
															}}
														>
															<NotesDetails
																flag="NOTES"
																notes={data.client_notes}
																isBid={true}
															/>
														</Box>
													)}
													{hasContract && (
														<Box
															mb={10}
															p={20}
															sx={{
																borderRadius: "4px",
																boxShadow:
																	"0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
																backgroundColor: "rgb(255, 255, 255)"
															}}
														>
															<ContractDetails
																userType={CLIENT}
																user={user}
																userTarget={data.profile_detail}
																contract={data.contract}
																stepperStatus={stepperStatus}
																visible_status={data.visible_status}
																getContractStatusTooltip={
																	getContractStatusTooltip
																}
																reportingSetting={data.reporting_settings}
															/>
														</Box>
													)}
													{hasInterview && (
														<Box
															mb={10}
															p={20}
															sx={{
																borderRadius: "4px",
																boxShadow:
																	"0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
																backgroundColor: "rgb(255, 255, 255)"
															}}
														>
															<InterviewDetails
																data={data.interview}
																user={user}
																userTarget={data.profile_detail}
																stepperStatus={stepperStatus}
																visible_status={data.visible_status}
																getInterviewStatusTooltip={
																	getInterviewStatusTooltip
																}
																showVideoConf={showVideoConf}
																onCallStart={onCallStart}
																onCallEnd={onCallEnd}
															/>
														</Box>
													)}
													<Box
														mb={10}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<BidDetails
															status={data.status}
															created_at={data.created_at}
															margin_value={data.margin_value}
															proposed_amount={data.proposed_amount}
															available_date={data.available_date}
															description={data.description}
															visible_status={data.visible_status}
															flag="BID"
															type={user.company_type || ""}
															stepperStatus={stepperStatus}
															user={user}
															bid_details={data}
														/>
													</Box>
												</TabPanel>
												<TabPanel>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<FreelancerCV
															cv={cv}
															id={id}
															canUpdateCV={_ownCV}
															cvDoc={cvDoc}
														/>
													</Box>
												</TabPanel>
												<TabPanel>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<HistoryBid history={history} />
													</Box>
												</TabPanel>
												<TabPanel>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)",
															["&#resume"]: {
																background: "transparent",
																padding: "0"
															}
														}}
														id="resume"
													>
														<Box id="can-do">
															<Functions
																functions={functions}
																showModal={() => showModal(FUNCTIONS)}
																canUpdateFunctions={_ownCV}
																isFreelancer
															/>
														</Box>
													</Box>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<Skills
															skills={initialSkills}
															functionsLength={_get(functions, "length", 0)}
															showModal={() => showModal(SKILLS)}
															ownCV={_ownCV}
															isClient={true}
														/>
													</Box>
													<Box
														mb={10}
														p={20}
														sx={{
															borderRadius: "4px",
															boxShadow: "0 0.5px 3.5px 0 rgba(0, 0, 0, 0.11)",
															backgroundColor: "rgb(255, 255, 255)"
														}}
													>
														<Languages
															ownCV={_ownCV}
															isClient={isClient}
															isFreelancer
														/>
													</Box>
												</TabPanel>
											</StyledTabPanels>
										)}
									</Box>
									<Box width={3 / 12}>
										<BidSideBar
											userType={CLIENT}
											userRole={user.role_name}
											fetchBid={fetchBid}
											canProposeContract={canProposeContract}
											job={data.job}
											toggleInterviewModal={toggleInterviewModal}
											toggleContractModal={toggleContractModal}
											stepperStatus={getIndexOfStepper(data)}
											interview={_get(data, "interview", {})}
											contract={_get(data, "contract", {})}
											bidStatus={data.status}
											companyId={user.active_company || ""}
											canMakeProposal={data.can_make_proposal}
											acceptInterview={displayAcceptInterviewModal}
											toggleContractConfirmation={toggleContractConfirmation}
											bidId={params.id}
											toggleRejectApplicationModal={
												toggleRejectApplicationModal
											}
											cancelBid={cancelBid}
											toggleHelpModal={toggleHelpModal}
											reportingSetting={data.reporting_settings}
										/>
									</Box>
								</Flex>
							</Tabs>
						</Box>
						{renderModals()}
						{rejectModal && (
							<RejectApplicationModal //! decrepated
								bidId={params.id}
								interviewId={_get(data, "interview._id", "")}
								contractId={_get(data, "contract._id", "")}
								stepperStatus={_get(data, "stepper_status")}
								onReject={rejectApplication}
								toggleRejectApplicationModal={toggleRejectApplicationModal}
							/>
						)}
						<CancelBidModal active={showBidModal} onClose={cancelBid} />

						<ConfirmationModal
							active={showCancelContractModal}
							modalName="accept-client-modal"
							onClose={toggleContractConfirmation}
							firstButton={{
								action: acceptContract,
								label: "YES, ACCEPT TERMS"
							}}
							title="Are you sure you want to accept these terms ?"
							content="Once accepted, the terms of the proposition will be used
									by wiggli to generate the contracts covering the mission."
							type={ConfirmationTypes.confirmation}
							loading={false}
						/>
						<AcceptInterviewModal
							acceptInterview={acceptInterviewModalAction}
							closeModal={closeAcceptInterviewModal}
							displayModal={displayAcceptInterviewConfirmation}
						/>
					</div>
				)}
			</div>
		</BodyClassName>
	);
};
export default ClientBidView;
