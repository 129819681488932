import React from "react";
import styles from "./confirmation-modal.module.scss";
import Modal from "common/modal";
import Portal from "common/Portal";
import { ReactComponent as CloseIcon } from "static/icons/cross-black.svg";

const ConfirmationModal = ({
	children,
	onClose,
	isLoading,
	width = "500px",
	zIndex = 999999,
	...props
}) => {
	return (
		<Portal>
			<Modal
				size={width}
				padding={"0px"}
				onClose={onClose}
				loading={isLoading}
				borderRadius={"12px"}
				border={"1px solid #CBD5E1"}
				backgroundColor={"rgba(0,0,0,0.25)"}
				boxShadow={
					"0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
				}
				customCloseBtnRender={onClose => (
					<div className={styles.closeButton} onClick={onClose}>
						<CloseIcon />
					</div>
				)}
				{...props}
				zIndexOverlay={zIndex}
			>
				<div className={styles.container}>{children}</div>
			</Modal>
		</Portal>
	);
};

export default ConfirmationModal;
