import * as React from "react";
import { createMarkupHtml, parseNewMentions } from "config/helpers";
import styles from "./lexical-content-view.module.scss";
import cx from "classnames";

const LexicalContentView = React.forwardRef(
	({ className, description }, ref) => {
		//This useEffect fixes nested list bug in Lexical library
		React.useEffect(() => {
			const selector1 = `.${styles.description} ul li ul`;
			const selector2 = `.${styles.description} ol li ol`;
			const selector3 = `.${styles.description} ol li ul`;
			const selector4 = `.${styles.description} ul li ol`;

			const elements = document.querySelectorAll(
				`${selector1} , ${selector2} , ${selector3}, ${selector4}`
			);

			elements.forEach(element => {
				element.parentElement.style.listStyleType = "none";
			});
		}, [description]);

		const content = parseNewMentions(description);

		return (
			<div
				ref={ref}
				className={cx(styles.root, className)}
				dangerouslySetInnerHTML={createMarkupHtml(content)}
			/>
		);
	}
);

LexicalContentView.displayName = "LexialContentView";

export default React.memo(LexicalContentView);
