import FormBuilderElementSection from "../components/FormBuilderElementSection";
import FormBuilderElementQuestion from "../components/FormBuilderElementQuestion";
import FormBuilderElementScoreCard from "../components/FormBuilderElementScoreCard";
import FormBuilderElementYesNo from "../components/FormBuilderElementYesNo";
import {
	ELEMENT_TYPES,
	LOCAL_STORAGE_KEY
} from "modules/EvaluationFormsSettings/utils/constants";
import get from "lodash/get";
import { v4 as uuid } from "uuid";

export function deleteField({ remove, index }) {
	remove(index);
}

export function duplicateField({ index, formContext, fieldArrayName }) {
	const list = structuredClone(formContext.getValues(fieldArrayName));
	const newElement = structuredClone(list[index]);
	newElement._id = uuid();
	if (newElement.items) {
		newElement.items = insertIdToEachElement(newElement.items);
	}
	list.splice(index + 1, 0, newElement);
	formContext.setValue(fieldArrayName, list, { shouldDirty: true });
	requestAnimationFrame(() => {
		formContext.setFocus(`${fieldArrayName}.${index + 1}.title`);
	});
}

export function toggleRequired({
	index,
	formContext,
	fieldArrayName,
	replace
}) {
	const element = get(formContext.getValues(), `${fieldArrayName}.${index}`);
	formContext.setValue(`${fieldArrayName}.${index}`, {
		...element,
		is_required: !element.is_required
	});
	replace(get(formContext.getValues(), fieldArrayName));
}

export function getComponentByType(type) {
	switch (type) {
		case ELEMENT_TYPES.section:
			return FormBuilderElementSection;

		case ELEMENT_TYPES.question:
			return FormBuilderElementQuestion;

		case ELEMENT_TYPES.score_card:
			return FormBuilderElementScoreCard;

		case ELEMENT_TYPES.yes_no:
			return FormBuilderElementYesNo;

		default:
			return () => null;
	}
}

export function removeIdFromEachElement(elements = []) {
	return structuredClone(elements).map(element => {
		const result = { ...element };
		delete result._id;
		if (result.items) {
			result.items = removeIdFromEachElement(result.items);
		}
		return result;
	});
}

export function insertIdToEachElement(elements = []) {
	return elements.map(element => {
		const result = { ...element };
		result._id = uuid();
		if (result.items) {
			result.items = insertIdToEachElement(result.items);
		}
		return result;
	});
}

export function getTemplateFromLocalStorage() {
	return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
}

export function removeTemplateFromLocalStorage() {
	return localStorage.removeItem(LOCAL_STORAGE_KEY);
}
