import { useMutation } from "react-query";
import { CREATE_MISSION } from "config/api-endpoints";
import { client } from "lib/api-client";
import toaster from "common/Toaster";
import { browserHistory } from "react-router";
import { getMissionDetailsContainerRoute } from "modules/MissionsDetails";
import { renderError } from "config/helpers";

const createMission = body => {
	return client(CREATE_MISSION, {
		body: body
	});
};

const useSaveMission = () => {
	return useMutation(createMission, {
		refetchOnWindowFocus: false,
		retry: 0,
		onSuccess: (_, variables) => {
			browserHistory.push(
				getMissionDetailsContainerRoute(variables.contract_id)
			);
			toaster.success("The mission has been created.");
		},
		onError: renderError
	});
};

export default useSaveMission;
