/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Text, Flex, Button } from "rebass";
import { client } from "lib/api-client";
import { LoaderFull } from "common/Loader";
import { useMutation } from "react-query";
import toaster from "common/Toaster";
import { MANAGE_MISSION_TAGS } from "config/api-endpoints";
import { TagBox } from "./timesheet-tags.style";
import TimesheetTag from "./TimesheetTag";
import { ReactComponent as EmptyArchivedTags } from "static/icons/empty-archived-tags-icon.svg";
import { ReactComponent as RestoreAll } from "static/icons/restore-all-icon.svg";
import { ReactComponent as ClearAll } from "static/icons/clear-icon.svg";
import get from "lodash/get";

const manageMissionTags = data =>
	client(MANAGE_MISSION_TAGS, {
		body: {
			...data
		}
	});

const AvailableTags = ({ tags, companyID, fetchFeatureSetting, allTags }) => {
	const [selectedTags, setSelectedTags] = useState([]);
	const [mutate, { isLoading: mutationLoading }] = useMutation(
		manageMissionTags,
		{
			onSuccess: () => {
				fetchFeatureSetting();
				toaster.success("Timesheet tags has successfully been restored.");
			},
			onError: error => {
				toaster.danger(get(error, "detail.name", get(error, "title")));
			}
		}
	);

	const onRestoreTags = (tags, isResetSelectedTags = false) => {
		mutate({
			action: "restore",
			company_id: companyID,
			tags
		});
		isResetSelectedTags && setSelectedTags([]);
	};

	return (
		<React.Fragment>
			<LoaderFull loading={mutationLoading} />

			<Flex my={24} alignItems="center" justifyContent="space-between">
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "16px",
						lineHeight: "34px",
						color: "#6E7191",
						textTransform: "uppercase"
					}}
				>
					Archived tags
				</Text>
				{selectedTags.length > 0 && (
					<Flex alignItems="center">
						<Flex>
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: "14px",
									color: "#A0A3BD"
								}}
							>
								{selectedTags.length} selected
							</Text>
							<Button
								sx={{
									border: "none",
									background: "transparent",
									padding: 0,
									display: "flex",
									alignItems: "center"
								}}
								onClick={() => setSelectedTags([])}
								disabled={mutationLoading}
								mx={22}
							>
								<ClearAll />
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontStyle: "normal",
										fontWeight: 600,
										fontSize: "14px",
										color: "#FD6950",
										marginLeft: "10px"
									}}
								>
									clear
								</Text>
							</Button>
						</Flex>
						<button
							onClick={() => onRestoreTags(selectedTags, true)}
							style={{
								alignItems: "center",
								display: "flex",
								background: "#60CFAC",
								border: "none",
								borderRadius: "4px",
								padding: "10px 15px"
							}}
							disabled={mutationLoading}
						>
							<Text
								sx={{
									fontFamily: "BasierCircle",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: "13px",
									color: "#FFFFFF",
									marginLeft: "10px"
								}}
							>
								Restore
							</Text>

							<RestoreAll style={{ marginLeft: "5px" }} />
						</button>
					</Flex>
				)}
			</Flex>

			<TagBox minHeight={100}>
				{tags.length === 0 ? (
					<Flex
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
					>
						<Text
							mb={16}
							sx={{
								fontFamily: "BasierCircle",
								fontStyle: "normal",
								fontWeight: 600,
								fontSize: "16px",
								lineHeight: "34px",
								color: "#A0A3BD"
							}}
						>
							No archived tags
						</Text>
						<EmptyArchivedTags />
					</Flex>
				) : (
					tags.map((tag, index) => (
						<TimesheetTag
							onRestoreTags={onRestoreTags}
							isArchived
							key={tag._id}
							tag={tag}
							index={index}
							setSelectedTags={setSelectedTags}
							selectedTags={selectedTags}
							allTags={allTags}
							mutationLoading={mutationLoading}
						/>
					))
				)}
			</TagBox>
		</React.Fragment>
	);
};

export default AvailableTags;
