import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import _get from "lodash/get";
import Table from "common/AgTable/AgTable";
import { ReactComponent as TrashIcon } from "static/icons/trash-icon-grey.svg";
import { useBreadCrumbs } from "hooks/useBreadCrumbs";
import { SearchInput } from "common/SearchInput";
import { Button } from "common/Button";
import {
	EMPTY_ARRAY,
	GDPR_LIST_FILTER,
	PAGE_SIZE_LIST
} from "./utils/constants";
import styles from "./gdpr-list.module.scss";
import { useInitializeFilterDrawer } from "./hooks/useInitializeFilterDrawer";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import useFetchGdprList from "./hooks/useFilterGdprList";
import Pagination from "common/NewPagination";
import { ReactComponent as PrevIcon } from "static/icons/chevron-left.svg";
import { QUERY } from "config";
import useDeleteGdprProfiles from "./api/useDeleteGdprProfile";
import { BREADCRUMBS_ITEMS } from "./utils/constants";
import { useSelectGdprProfile } from "./hooks/useSelectGdprProfile";
import { DeleteGdprCandidateModal } from "./components/DeleteGdprCandidateModal";
import { toast } from "react-hot-toast";
import { ReactComponent as TrashIconRed } from "static/icons/trashIcon.svg";
import { useGetGdprListColumns } from "./hooks/useGetGdprListColumns";
import { GdprListEmptyState } from "./components/GdprListEmptyState";
import { historyPush } from "config/helpers";
import { getGdprPermanentViewRoute } from "modules/GdprPermanentView/GdprPermanentView";
import { Banner } from "common/Banner";
import RowsPages from "common/PageSizeSelect/PageSizeSelect";

export const getGdprListRoute = () => {
	return "/settings/gdpr-list";
};

export const getPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const GdprList = () => {
	useInitializeFilterDrawer();
	const { refetch } = useFetchGdprList();
	const [deleteProfiles] = useDeleteGdprProfiles({
		onSuccess: () => {
			toast("Candidate deleted!", {
				duration: 2500,
				icon: <TrashIconRed />
			});
			reset();
			refetch();
		},
		onError: () => {
			toast("Error", { isFailed: true });
		}
	});
	const tableRef = useRef();
	const [list, setList] = useState([]);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const { setQuery, getState, setOffset, setLimit } = useFilters();
	const {
		query,
		isLoading,
		isFetching,
		data,
		offset,
		limit: pageSize,
		sortBy
	} = getState(GDPR_LIST_FILTER);
	useBreadCrumbs(BREADCRUMBS_ITEMS);

	const [displayNotification, setDisplayNotification] = useState(true);
	const {
		isSelectAll,
		selectedProfilesCounter,
		toggleAll,
		toggleCard,
		checkCandidateSelection,
		include,
		exclude,
		reset,
		selectedProfiles,
		mode
	} = useSelectGdprProfile(data);

	const columnsList = useGetGdprListColumns(
		toggleCard,
		checkCandidateSelection,
		isLoading,
		isFetching,
		isSelectAll,
		toggleAll,
		tableRef
	);

	useEffect(() => {
		if (selectedProfilesCounter !== 0) {
			reset();
		}
	}, [sortBy, query]);

	const onRowClick = ({ column, data }) => {
		const colId = column.colId;

		if (colId === "_selects") return;
		else {
			historyPush(getGdprPermanentViewRoute(data._id));
		}
	};

	const filterData = data => {
		let newData = [];
		data?.map((item, i) => {
			const checked = checkCandidateSelection(i, item);
			const rowIndex = i + offset;
			const obj = {
				...item,
				isSelected: checked,
				onCheckboxChange: e => toggleCard(e, item._id, rowIndex, checked)
			};
			return newData.push(obj);
		});
		setList(newData);
	};

	useEffect(() => {
		if (data) {
			filterData(data.data);
		}
	}, [data, checkCandidateSelection, offset]);

	const onClose = () => {
		setDisplayNotification(false);
	};

	const onSearchInputChange = e => {
		setQuery(e.target.value);
	};

	const onDelete = () => {
		setOpenConfirmationModal(true);
	};

	const onModalClose = () => {
		setOpenConfirmationModal(false);
	};

	const onDeleteConfirm = () => {
		const subBody = {
			include_ids: mode === QUERY ? include : [],
			exclude_ids: mode === QUERY ? exclude : [],
			selected_ids: mode !== QUERY ? selectedProfiles : []
		};

		let body;

		if (mode === QUERY) {
			body = {
				...subBody,
				filter_data: {
					offset: offset,
					search: query,
					sort_by: sortBy
				}
			};
		} else {
			body = {
				...subBody,
				filter_data: {}
			};
		}

		deleteProfiles(body);
		setOpenConfirmationModal(false);
	};

	const isDeleteDisabled = selectedProfilesCounter <= 0;

	const isInitialDataEmpty = () => {
		return data?.data.length === 0 && query === "";
	};

	const getDeleteButtonText = () => {
		if (selectedProfilesCounter !== 0)
			return `Delete candidates (${selectedProfilesCounter})`;
		else return "Delete candidates";
	};

	return (
		<div className={styles.container}>
			{displayNotification && (
				<Banner onClose={onClose}>
					{isInitialDataEmpty()
						? `Candidates who have deleted their profiles will be listed on this page. Please remember to remove their information from your database to remain compliant with GDPR regulations.`
						: `The candidates listed on this page have opted to remove their profile and stop all ongoing processes. Thus, you will no longer be able to reach out to them through the platform. You can still access your past records and interactions with them. To adhere to the GDPR, it is advisable to consider deleting their data from your database.`}
				</Banner>
			)}
			<div className={styles.searchInputItem}>
				<SearchInput
					onChange={onSearchInputChange}
					value={query}
					className={styles.searchInput}
				/>
			</div>
			<div className={styles.deleteButtonItem}>
				<Button
					className={cx(styles.deleteButton, {
						[styles.deleteButtonDisabled]: isDeleteDisabled
					})}
					disabled={isDeleteDisabled}
					text={getDeleteButtonText()}
					icon={
						<TrashIcon
							className={cx(styles.trashIcon, {
								[styles.trashIconDisabled]: isDeleteDisabled
							})}
						/>
					}
					onClick={onDelete}
				/>
			</div>

			<div className={styles.tableItem}>
				<p className={styles.results_count}>
					<span className={styles.results_count}>
						{selectedProfilesCounter > 0 && (
							<span className={styles.grayText}>
								<span className={styles.greenText}>
									{selectedProfilesCounter.toLocaleString().replace(",", " ")}
								</span>
								&nbsp;selected of&nbsp;
							</span>
						)}
					</span>
					<span className={styles.results_count}>
						{isLoading || isFetching ? (
							"Loading..."
						) : (
							<span className={styles.results_count}>
								{_get(data, "total") === 0 ? (
									"No result found"
								) : (
									<span className={styles.grayText}>
										<span className={styles.greenText}>
											{_get(data, "total")
												?.toLocaleString()
												?.replace(",", " ")}
										</span>
										&nbsp;
										{_get(data, "total") > 1 ? "results" : "result"} in total
									</span>
								)}
							</span>
						)}
					</span>
				</p>
				<Table
					rows={isLoading || isFetching ? EMPTY_ARRAY : list}
					columnsSettings={columnsList}
					ref={tableRef}
					onCellClicked={onRowClick}
					noRowsOverlayComponent={GdprListEmptyState}
				/>
				{_get(data, "total", 0) > pageSize && (
					<div className={styles.containerFooter}>
						<RowsPages
							limit={pageSize}
							setLimit={setLimit}
							rowsPages={PAGE_SIZE_LIST}
							refetch={refetch}
						/>
						<Pagination
							previousLabel={<PrevIcon />}
							nextLabel={<PrevIcon style={{ transform: "rotate(180deg)" }} />}
							total={data?.total}
							size={data?.size}
							handlePageClick={page => {
								setOffset(page * data?.size);
							}}
							offset={offset}
							forcePage={Math.ceil(data?.offset / data?.size)}
							pageRangeDisplayed={data.total > 1000 ? 6 : undefined}
							marginPagesDisplayed={data.total > 1000 ? 0 : undefined}
						/>
					</div>
				)}
			</div>
			{openConfirmationModal && (
				<DeleteGdprCandidateModal
					onDelete={onDeleteConfirm}
					onClose={onModalClose}
					deletedCandidatesCounter={selectedProfilesCounter}
				/>
			)}
		</div>
	);
};

export default GdprList;
