import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styles from "./template-names-list-skeleton.module.scss";

export default function TemplatesNamesListSekeleton() {
	return (
		<div className={styles.container}>
			<SkeletonTheme color="#F1F1F1">
				<div>
					<Skeleton
						width={"30%"}
						height={24}
						className={styles.skeletonTitle}
					/>
				</div>
				<div>
					<Skeleton width={"90%"} height={36} className={styles.skeletonItem} />
				</div>
				<div>
					<Skeleton width={"60%"} height={36} className={styles.skeletonItem} />
				</div>
				<div>
					<Skeleton width={"95%"} height={36} className={styles.skeletonItem} />
				</div>
				<div>
					<Skeleton width={"80%"} height={36} className={styles.skeletonItem} />
				</div>
				<div>
					<Skeleton width={"95%"} height={36} className={styles.skeletonItem} />
				</div>
				<div>
					<Skeleton width={"75%"} height={36} className={styles.skeletonItem} />
				</div>
				<div>
					<Skeleton
						width={"30%"}
						height={24}
						className={styles.skeletonTitle}
					/>
				</div>
				<div>
					<Skeleton width={"80%"} height={36} className={styles.skeletonItem} />
				</div>
				<div>
					<Skeleton width={"90%"} height={36} className={styles.skeletonItem} />
				</div>
				<div>
					<Skeleton width={"60%"} height={36} className={styles.skeletonItem} />
				</div>
			</SkeletonTheme>
		</div>
	);
}
