import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, array } from "yup";
import useOnboardingStore from "../../../user-onboarding/hooks/useOnboardingStore";

import { HOW_TO_CREATE_VACANCY } from "config/onboarding";

export const useProfileForm = ({ hasMultiSites }) => {
	let schemaTemplate = {
		// department: object().test(
		// 	"Required",
		// 	"Please select a department",
		// 	function(value) {
		// 		return !!value?.value;
		// 	}
		// ),
		categories: array().required("Value is required and can't be empty"),
		skills: array().required("Value is required and can't be empty"),
		query_languages: array().required("Please select at least one language."),
		priority: object()
			.test("Required", "Please select priority", function(value) {
				return !!value?.value;
			})
			.nullable()
	};

	if (hasMultiSites) {
		schemaTemplate.site = object().test(
			"Required",
			"Please select a site",
			function(value) {
				return !!value?.value;
			}
		);
	}

	const { workflowId } = useOnboardingStore();
	const isTourOngoing = workflowId === HOW_TO_CREATE_VACANCY;
	const schema = object().shape(isTourOngoing ? {} : schemaTemplate);

	const res = useForm({
		resolver: yupResolver(schema),
		shouldUnregister: true
	});

	return res;
};
