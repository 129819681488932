import React from "react";
import s from "./banner.module.scss";
import cx from "classnames";
import { ReactComponent as CloseBanner } from "static/icons/cancelSmall.svg";
import { ReactComponent as DefaultIconBanner } from "static/icons/IconDefaultBanner.svg";
import { INFORMATIONAL, WARNING, DEFAULT } from "config";

const Banner = ({ type = DEFAULT, children, onClose, icon, title }) => {
	const classNameBanner = {
		informational: INFORMATIONAL,
		warning: WARNING,
		default: DEFAULT
	};

	return (
		<div className={cx(s.containerBanner, s[classNameBanner[type]])}>
			<div className={s.iconBanner}>{icon ? icon : <DefaultIconBanner />}</div>
			<div className={s.contentBanner}>
				{title && <h3>{title}</h3>}
				{children}
			</div>
			<button className={s.closeBannerButton} onClick={onClose}>
				<CloseBanner />
			</button>
		</div>
	);
};

export default Banner;
