import get from "lodash/get";
import React, { Children, useState, cloneElement } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DragAndDrop = ({
	children,
	isDrag,
	setIsOpenModalTab,
	setActiveTab,
	setDraggedElementOrder,
	isFetching,
	setIsSave,
	isAddMailToPoke,
	history,
	notes,
	getHistoryLoading,
	getNotesLoading
}) => {
	const [draggedChildren, setDraggedChildren] = useState(children);

	const [destination, setDestination] = useState(null);
	const [originalDestination, setOriginalDestination] = useState(null);

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}

		const items = reorder(
			draggedChildren,
			result.source.index,
			result.destination.index
		);

		const cardLabels = items.map(card => card.props.name);

		setDraggedChildren(items);
		setDestination(null);

		setDraggedElementOrder(cardLabels);
	};

	const grid = 2;
	const getItemStyle = (isDragging, draggableStyle, index) => {
		return {
			userSelect: "none",

			margin: `0 0 12px 0`,
			borderBottom:
				index === destination && originalDestination != 0
					? "3px solid #FF4346"
					: "none",

			borderTop:
				destination === index && originalDestination == 0
					? "3px solid #FF4346"
					: "none",

			boxShadow: isDragging
				? "0px 0px 4px 4px rgba(255,104,73,0.14)"
				: destination === index && originalDestination == 0
				? "0px 0px 4px 0px rgba(255,104,73,0.14)"
				: destination === index && originalDestination != 0
				? "0px 4px 0px 0px rgba(255,104,73,0.14)"
				: "0 0 6px 4px rgba(0, 0, 0, 0.04)",
			cursor: isDrag ? "pointer" : "auto",

			...draggableStyle
		};
	};

	const getListStyle = () => ({
		padding: grid,
		background: "transparent"
	});

	const handleDragUpdate = result => {
		const destinationIndex = get(result, "destination.index");

		const sourceIndex = get(result, "source.index");

		if (destinationIndex === sourceIndex) {
			if (sourceIndex === 0) {
				setDestination(1);
			} else {
				setDestination(sourceIndex - 1);
			}

			setOriginalDestination(sourceIndex);
			return;
		}
		if (destinationIndex === 0) {
			setDestination(0);
			setOriginalDestination(0);
			return;
		}
		if (destinationIndex < sourceIndex) {
			setDestination(destinationIndex - 1);
			setOriginalDestination(destinationIndex);
			return;
		}
		setOriginalDestination(null);
		setDestination(destinationIndex);
	};

	return (
		<DragDropContext
			onDragStart={() => setIsSave(true)}
			onDragUpdate={handleDragUpdate}
			onDragEnd={onDragEnd}
		>
			<Droppable
				isDropDisabled={!isDrag}
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					justifyContent: "space-between"
				}}
				type="column"
				direction="vertical"
				droppableId="droppable"
			>
				{(provided, snapshot) => {
					return (
						<div
							style={getListStyle(snapshot.isDraggingOver)}
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{Children.map(draggedChildren, (element, index) => {
								return (
									<Draggable
										isDragDisabled={!isDrag}
										key={element.props.name}
										draggableId={element.props.name}
										index={index}
									>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style,
													index,
													element.props.name
												)}
											>
												{cloneElement(element, {
													isDrag,
													setIsOpenModalTab,
													setActiveTab: () => setActiveTab(element.props.name),
													isFetching,
													isAddMailToPoke,
													history,
													notes,
													getHistoryLoading,
													getNotesLoading
												})}
											</div>
										)}
									</Draggable>
								);
							})}

							{provided.placeholder}
						</div>
					);
				}}
			</Droppable>
		</DragDropContext>
	);
};

export default DragAndDrop;
