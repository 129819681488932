import React from "react";
import { DataRenderer } from "modules/vacancy/components/pokeView/client/OfferDetails.jsx";
import { PhoneScreenContainer } from "modules/vacancy/components/pokeView/client/styles.js";
import { ReactComponent as MessageIcon } from "static/icons/message_icon.svg";
import { ReactComponent as CalendarIcon } from "static/icons/calendar_icon.svg";
import StatusLabel from "common/StatusLabel";
import { formatDate } from "common/Functions";
import _get from "lodash/get";
import { DATA_MMMM_D_YYYY, TIME_FORMAT_SYMBOL } from "config";

function PhoneScreen({ poke }) {
	return (
		<PhoneScreenContainer>
			<div className="header">
				<label className="box_label">Phone screen</label>
				<StatusLabel
					status={_get(poke, "interview.status")}
					label={_get(poke, "interview.visible_status")}
				/>
			</div>
			<div className="info_section">
				<DataRenderer
					title="Date and Time"
					value={`${formatDate(
						_get(poke, "interview.new_interview_start_date", ""),
						true,
						DATA_MMMM_D_YYYY
					)} | ${formatDate(
						_get(poke, "interview.new_interview_start_date", ""),
						true,
						TIME_FORMAT_SYMBOL
					)} to ${formatDate(
						_get(poke, "interview.new_interview_end_date", ""),
						true,
						TIME_FORMAT_SYMBOL
					)}`}
					icon={<CalendarIcon />}
				/>
				<DataRenderer
					title="Phone number"
					value={_get(poke, "interview.phone", "N/A")}
					icon={<MessageIcon />}
				/>
				<DataRenderer
					title="Message"
					value={
						Boolean(_get(poke, "interview.description"))
							? _get(poke, "interview.description")
							: "N/A"
					}
					icon={<MessageIcon />}
				/>
			</div>
		</PhoneScreenContainer>
	);
}

export default PhoneScreen;
