import styled from "styled-components";

export const CategoryTieringContainer = styled.div`
	background-color: white;
	padding: 20px;
	box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
	border-radius: 5px;
	margin-top: 20px;
	.categotyTiers {
		padding: 10px;
		border: 0.5px solid #e1e3e5;
		box-sizing: border-box;
		box-shadow: 0px 2px 1px rgb(0 0 0 / 5%), 0px 0px 1px rgb(0 0 0 / 25%);
		border-radius: 4px;
		.blockTitle {
			font-family: "basiercircle";
			font-weight: 600;
			font-size: 12px;
			text-transform: uppercase;
			color: #6d7175;
		}
	}
`;
