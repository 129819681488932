import React from "react";
import _get from "lodash/get";
import CurrencyFormatter from "common/CurrencyFormater";
import GlobalTooltip from "common/GlobalTooltip";
import { EUR, EMPLOYMENT_TYPE } from "config";
import {
	Section,
	FrontSheet,
	LocationBox,
	LocationTextBox,
	EmploymentBox
} from "./ProfileStyle";
const renderLocation = (location, i) => {
	return (
		<GlobalTooltip
			placement="top"
			overlay={`${_get(location, "city")}, ${_get(location, "country")} `}
			overlayClassName="g-tool-dark"
			key={i}
			ellipsisMode={true}
		>
			<LocationBox>
				<LocationTextBox name="location_box">
					{`${_get(location, "city") ? `${location.city},` : ""} ${_get(
						location,
						"country"
					)}`}
				</LocationTextBox>
			</LocationBox>
		</GlobalTooltip>
	);
};

const renderPreferredLocations = (locations = []) =>
	locations.map((location, i) => {
		if (!location.is_main) {
			return renderLocation(location, i);
		}
	});

const RenderMainLocation = (locations = []) => {
	const residency = locations.find(l => l.is_main);

	if (residency) {
		return renderLocation(residency, 0);
	}
};
const employmentType = employment_type => {
	const typeOfEmployment = EMPLOYMENT_TYPE.find(
		type => type.value === employment_type
	);
	return <EmploymentBox>{typeOfEmployment.label}</EmploymentBox>;
};

const FrontSheetComponent = ({
	preference = {},
	isOwnProfile,
	displayPreferredLocationModal,
	displayPreferencesModal
}) => (
	<Section padding={"0 0 30px 0"}>
		<FrontSheet>
			<tbody>
				<tr>
					<td>Place of residence</td>
					<td className="location-container">
						{RenderMainLocation(preference.preferred_location)}
					</td>
					{isOwnProfile && (
						<th rowSpan="2" scope="rowgroup">
							<button
								className="btn btn-sm front-sheet"
								onClick={displayPreferredLocationModal}
							>
								<i className="fa fa-pencil" />
							</button>
						</th>
					)}
				</tr>
				<tr>
					<td>Preferred location(s)</td>
					<td className="location-container">
						{renderPreferredLocations(preference.preferred_location)}
					</td>
				</tr>
			</tbody>
			<tbody>
				<tr>
					<td>employment type</td>
					<td className="employment-container">
						{_get(preference, "employment_types", []).map(type =>
							employmentType(type)
						)}
					</td>
					{isOwnProfile && (
						<th rowSpan="5" scope="rowgroup">
							<button
								className="btn btn-sm front-sheet"
								onClick={displayPreferencesModal}
							>
								<i className="fa fa-pencil" />
							</button>
						</th>
					)}
				</tr>

				<tr>
					<td>Expected salary</td>
					<td className="salary-td">
						<div className="salary">
							<span className="amount">
								<CurrencyFormatter
									cent={preference.expected_salary}
									symbol={` ${_get(preference, "symbol_expected", EUR)}`}
								/>
							</span>
							<span
								style={{ textTransform: "capitalize", fontWeight: "normal" }}
							>
								{_get(preference, "payment_type_expected", "gross")}
							</span>
							/{_get(preference, "payment_time_expected", "year")}
						</div>
					</td>
				</tr>

				<tr>
					<td>Extra Benefits</td>
					<td>
						{preference?.extra_benefits && (
							<div className="benefitsLabel">
								{preference?.extra_benefits.map(ex => (
									<div className="extraContainer" key={ex.value}>
										{ex.label}
									</div>
								))}
							</div>
						)}
					</td>
				</tr>
				{preference.expected_salary_advantage && (
					<tr>
						<td>Other Benefits</td>
						<td>{preference.expected_salary_advantage}</td>
					</tr>
				)}
				<tr>
					<td>Notice period</td>
					<td>{preference.notice_period}</td>
				</tr>
			</tbody>
		</FrontSheet>
	</Section>
);

export default FrontSheetComponent;
