import React, { memo, useEffect, useState } from "react";
import s from "../search-suppliers.module.scss";
import { useDebounce } from "react-use";

function SupplierNameFilter({ store }) {
	const [value, setValue] = useState(undefined);
	const { setName, name } = store(state => ({
		setName: state.setName,
		name: state.name
	}));

	useDebounce(() => setName(value), 500, [value]);

	useEffect(() => {
		setValue(name);
	}, [name, setValue]);

	return (
		<div className={s.card}>
			<h5 className={s.heading}>Supplier name</h5>
			<input
				type="text"
				onChange={e => setValue(e.target.value)}
				name={"supplier.name"}
				className={s.input}
				value={value}
			/>
		</div>
	);
}

export default memo(SupplierNameFilter);
