import React, { useState } from "react";
import _get from "lodash/get";
import { v4 as uuid } from "uuid";

import { INPUT_MAX_LENGTH } from "config";
import Modal from "common/modal/Modal";
import FormField from "common/FormField";
import AxiosHelper from "config/axios-helper";
import { CREATE_SEARCH_TEMPLATE_ENDPOINT } from "config/api-endpoints";
import { HYBRID_OPTION } from "config";

const CreateTemplateModal = ({
	closeModal,
	filters,
	fetchTemplates,
	selectTemplate,
	tagSearch,
	typeSearch,
	isClient = true
}) => {
	const [name, setName] = useState("");
	const [error, setError] = useState(false);
	const [touched, setTouched] = useState(true);

	const {
		functions,
		skills,
		languages,
		location,
		distance,
		except_applied,
		except_poked,
		sort_by,
		keywords,
		residence_only,
		source: { applied, poked, added, bid },
		employment_types,
		interviewed,
		not_viewed_by_me,
		not_viewed_by_my_company,
		on_mission,
		on_interview,
		no_vacancy_sent,
		profile_tags,
		data_source,
		remoteEligilibityOptions,
		daysInOffice
	} = filters;

	const handleChange = e => {
		if (_get(e, "target")) {
			setName(e.target.value);
		}
		if (error) {
			setTouched(false);
		}
	};

	const createTemplate = () => {
		let templateName = `${
			isClient ? "Community" : "Search template"
		} - ${name}`;
		let id = uuid();
		AxiosHelper.post({
			url: CREATE_SEARCH_TEMPLATE_ENDPOINT,
			toastMessage: `${isClient ? "Community" : "Search template"} saved!`,
			data: {
				name: templateName,
				id,
				functions,
				skills,
				profile_tags,
				data_source,
				languages,
				location,
				distance,
				except_applied,
				except_poked,
				sort_by,
				keywords,
				residence_only,
				tag: tagSearch,
				...(typeSearch && { type: typeSearch }),
				source: {
					applied,
					poked,
					added,
					bid
				},
				employment_types,
				interviewed,
				not_viewed_by_my_company,
				not_viewed_by_me,
				on_mission,
				on_interview,
				no_vacancy_sent,
				remote_eligibility: remoteEligilibityOptions,
				office_days:
					remoteEligilibityOptions.includes(HYBRID_OPTION.value) && daysInOffice
						? daysInOffice
						: undefined
			}
		}).then(() => {
			fetchTemplates();
			selectTemplate({
				name: templateName,
				_id: id
			});
		});
	};

	return (
		<Modal
			fixed={false}
			onClose={closeModal}
			title={isClient ? "Community" : "Search template"}
			id="createTemplateModal"
			firstButton={{
				action: () => {
					if (name.trim().length < 1) {
						setError(true);
						setTouched(true);
					} else {
						createTemplate();
						closeModal();
					}
				},
				type: "primary",
				label: "Save template",
				disabled: name.trim().length < 3
			}}
			titleClassName={"search-template-title"}
			className="close-template-icon"
		>
			<div className="template-form">
				<FormField
					label={"Template name"}
					input={{ name: "name", value: name, onChange: handleChange }}
					placeholder="Start typing..."
					type="text"
					maxLength={INPUT_MAX_LENGTH}
					meta={{
						touched: touched,
						error:
							error &&
							`Please enter a name for the ${
								isClient ? "community" : "search template"
							}.`
					}}
					clearable
					onClear={() => setName("")}
				/>
			</div>
		</Modal>
	);
};

export default CreateTemplateModal;
