import React from "react";
import s from "./description.module.scss";

function DescriptionForm({ register, defaultValue, error }) {
	return (
		<>
			<textarea
				{...register("description")}
				placeholder={"Type your description..."}
				defaultValue={defaultValue}
			/>
			<div className={s.errorField}>{error?.message}</div>
		</>
	);
}

export default DescriptionForm;
