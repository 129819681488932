import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DELETE_CONTRACT } from "config/api-endpoints";

function deleteContract(body) {
	return client(DELETE_CONTRACT, {
		body
	});
}

function useDeleteContract(queryOptions) {
	return useMutation(deleteContract, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
}

export default useDeleteContract;
