import styled from "styled-components";
import Select from "react-select";

export const StyledSelect = styled(Select)`
	margin-bottom: ${props => (props.hasError ? "5px" : "30px")};

	.Select-value {
		padding: 5px;
	}
	.Select-value-label {
		display: inline-flex !important;
	}
`;
