import { OPERATORS } from "common/FilterDrawer/utils";
import { CustomInput } from "common/CustomInput";
import { Picker } from "common/Picker";
import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { BooleanSelect } from "../BooleanSelect/BooleanSelect";
import { PickerWithOneValue } from "../PickerWithOneValue/PickerWithOneValue";

export const FILES_PAGE_SIZES = [50, 24, 12];

export const VACANCY_FILES_FILTER_ID = "VACANCY_FILES_FILTER_ID";

export const VACANCY_FILES_MODULE_ID = "VACANCY_FILES_MODULE_ID";

export const FILES_PAGE_SIZE = 12;

export const API_VACANCY_FILES_DATA_TYPE =
	"search_vacancy_files_temporary_table";

export const TYPES = {
	text: "text",
	array: "array",
	customMultiSelect: "customMultiSelect",
	date: "date",
	booleanCustomDropDown: "booleanCustomDropDown"
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.customMultiSelect]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[TYPES.booleanCustomDropDown]: [OPERATORS.is, OPERATORS.isNot]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.array]: {
		default: Picker
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	},
	[TYPES.customMultiSelect]: {
		default: Picker,
		[OPERATORS.is.value]: PickerWithOneValue
	},
	[TYPES.booleanCustomDropDown]: {
		default: BooleanSelect
	}
};

export const columnSortMap = {
	_id: "_id",
	file_name: "file_name",
	file_type: "file_type",
	created_at: "created_at",
	created_by: "created_by"
};

export const columnFiltersMap = {
	_id: "_id",
	file_name: "file_name",
	file_type: "file_type",
	created_at: "created_at",
	created_by: "created_by",
	allowed_users: "allowed_users"
};

export const CHECK_FILTER_COLUMNS = ["file_name"];
