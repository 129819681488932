import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_PROFILE_TAGS } from "config/api-endpoints";

export const listProfileTagsKey = "@list_profile_tags";

const getlistProfileTags = body => {
	return client(LIST_PROFILE_TAGS, {
		body
	});
};

const useListProfileTags = (
	key = listProfileTagsKey,
	options,
	queryOptions = {}
) =>
	useQuery([key, options], () => getlistProfileTags(options), {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});
export default useListProfileTags;
