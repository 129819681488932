import flatMapDeep from "lodash/flatMapDeep";
import find from "lodash/find";
import get from "lodash/get";
import moment from "moment";
import { CalendarDate } from "@internationalized/date";
import { isEmpty, renderLocationValues } from "./filters_utils";
import { utcTimeStamp } from "config/helpers";
import isNumber from "lodash/isNumber";

const formatTypeScore = filter => {
	const { value, name, operator } = filter;
	return {
		field: name,
		op: operator,
		value: isNumber(value) ? value / 100 : value
	};
};

export const formatFilters = filters => {
	const definedFilters = filters.filter(({ value }) => {
		return !isEmpty(value);
	});
	const filtersFormatted = definedFilters.map(filter => {
		const { name, operator } = filter;
		let filterFormatted;

		if (name === "skills") {
			filterFormatted = formatTypeSkillFunctionLanguageFilter(filter);
		} else if (name === "languages") {
			filterFormatted = formatTypeSkillFunctionLanguageFilter(
				filter,
				false,
				true
			);
		} else if (name === "sub_category") {
			filterFormatted = formatTypeCategorySubCategory(filter);
		} else if (name === "interview_date") {
			filterFormatted = formatFilterTypeDate(filter);
		} else if (
			name === "last_name" ||
			name === "first_name" ||
			name === "phone_number" ||
			name === "email" ||
			name === "id" ||
			name === "candidate_reference" ||
			name === "candidate_name" ||
			name === "request_title" ||
			name === "vacancy_title" ||
			name === "vacancy_reference"
		) {
			filterFormatted = {
				value: filter.value,
				field: name,
				op: operator
			};
		} else if (name === "matching_rate") {
			filterFormatted = formatTypeScore(filter);
		} else if (name === "interviewer_location") {
			filterFormatted = formatTypeLocation(filter);
		} else if (
			["is_viewed", "is_activated", "interviewed", "notes"].includes(name)
		) {
			filterFormatted = formatFilterSelect(filter);
		} else {
			if (name) {
				filterFormatted = formatFilter(filter);
			}
		}

		return filterFormatted;
	});

	return filtersFormatted;
};

const formatTypeSkillFunctionLanguageFilter = (
	filter,
	deleteRating,
	deleteCategory
) => {
	const { name, operator, value } = filter;

	const items = [];
	(value || []).forEach(parent => {
		parent.children.forEach(item => {
			const filterFormatted = {
				name: item.label,
				score: item.rating,
				category: parent?.label
			};

			if (deleteRating) {
				delete filterFormatted.score;
			}
			if (deleteCategory) {
				delete filterFormatted.category;
			}

			items.push(filterFormatted);
		});
	});

	return {
		field: name,
		op: operator,
		value: items
	};
};

const formatTypeCategorySubCategory = filter => {
	const value = reduceItems(filter.value || []).map(({ label }) => label);

	return { value, field: filter.name, op: filter.operator };
};

function reduceItems(items) {
	return items.reduce((acc, item) => [...acc, ...item.children], []);
}

const formatFilter = filter => {
	const { name, value, operator } = filter;
	const ids = reduceItems(value || []).map(({ id }) => id);

	return {
		field: name,
		value: ids,
		op: operator
	};
};

const formatFilterTypeDate = filter => {
	const { value, operator, name } = filter;

	if (!value) {
		return {
			field: name,
			value: null,
			op: operator
		};
	}
	let valueFormatted;

	if (!value.start) {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value),
			isStart: false
		});
		valueFormatted = [startDate, endDate];
	} else {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.start)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.end),
			isStart: false
		});

		valueFormatted = [startDate, endDate];
	}

	return {
		field: name,
		value: valueFormatted,
		op: operator
	};
};

const convertCalendarDateToMS = date => {
	const { year, month, day } = date;
	return new Date(`${year}/${month}/${day}`);
};

const formatTypeLocation = (filter, forSearch = false) => {
	const { value, operator, name } = filter;
	const newValue = value || [];

	const valuesFormatted = newValue.map(({ place, radius }) => {
		const address = {};

		place.address_components.map(elem => {
			if (elem.types[0] === "country") {
				address[elem.types[0]] = elem.long_name;
				address.iso_country = elem.short_name;
				return;
			}
			return (address[elem.types[0]] = elem.long_name);
		});

		address.latitude = place.geometry.location.lat();
		address.longitude = place.geometry.location.lng();
		address.street =
			address.route ||
			address.neighborhood ||
			address.premise ||
			address.sublocality_level_1 ||
			address.sublocality_level_2 ||
			address.sublocality_level_3 ||
			address.sublocality_level_4 ||
			address.sublocality_level_5 ||
			address.subpremise ||
			address.sublocality ||
			address.jpns ||
			"";
		address.country = address.country || "";
		address.is_main = false;
		address.zip = address.postal_code || "";
		address.latitude = address.latitude || "";
		address.longitude = address.longitude || "";
		address.city =
			address.locality ||
			address.administrative_area_level_1 ||
			address.administrative_area_level_2 ||
			address.administrative_area_level_3 ||
			address.administrative_area_level_4 ||
			address.administrative_area_level_5 ||
			"";
		address.number = address.street_number || "";
		address.box = address.box || "";
		address.iso_country = address.iso_country || "";

		const values = place?.geometry?.viewport;
		let viewport = null;

		if (values) {
			viewport = {
				northeast: {
					lat: values.getNorthEast().lat(),
					lng: values.getNorthEast().lng()
				},
				southwest: {
					lat: values.getSouthWest().lat(),
					lng: values.getSouthWest().lng()
				}
			};
		}

		const typesSupportedAutoComplete = [
			"locality",
			"sublocality",
			"postal_code",
			"country",
			"administrative_area_level_1",
			"administrative_area_level_2",
			"administrative_area_level_3",
			"locality",
			"political"
		];

		let isSendViewport = false;

		typesSupportedAutoComplete.forEach(type => {
			if (place.types.includes(type)) isSendViewport = true;
		});

		return {
			zip: address.zip,
			country: address.country,
			number: address.number,
			iso_country: address.iso_country,
			city: address.city,
			street: address.street,
			latitude: address.latitude,
			is_main: address.is_main,
			box: address.box,
			longitude: address.longitude,
			distance_unit: "km",
			distance: parseInt(radius),
			viewport: isSendViewport ? viewport : [],
			place_id: forSearch ? place.place_id : undefined
		};
	});

	return {
		value: valuesFormatted.length ? valuesFormatted[0] : null,
		field: name,
		op: operator
	};
};

const dateToTimestamp = d => {
	return moment(d, "DD-MM-YYYY").unix();
};

const timestampToDateCalendar = timestamp => {
	const date = moment.unix(timestamp);
	return new CalendarDate(
		date.get("year"),
		date.get("month") + 1,
		date.get("date")
	);
};

const formatPickerValue = (value, withRating = false) => {
	return get(value, "[0].children", []).map(v => {
		return {
			name: v.label,
			id: v.id,
			score: withRating ? v.rating : undefined
		};
	});
};

const formatIdsValue = value => {
	let formatted_value = [];
	formatted_value = get(value, "[0].children", []).map(v => {
		return v.id;
	});

	return formatted_value;
};

export const formatFilterSelect = filter => {
	const { name, value, operator } = filter;
	let formatted_value = value;
	switch (name) {
		case "source":
		case "extra_benefits":
		case "salary_type":
		case "payroll_country":
		case "currency":
		case "payment_type":
		case "category":
		case "subcategory":
		case "seniority":
		case "profile_tags":
		case "statues":
			formatted_value = formatPickerValue(value);
			break;
		case "employment_type":
		case "created_by":
			formatted_value = formatIdsValue(value);
			break;
		case "residence":
		case "preferred_location":
			formatted_value = get(formatTypeLocation(filter, true), "value", "");
			break;
		case "skills":
			formatted_value = formatSkillValue(value);
			break;
		case "functions":
			formatted_value = formatFunctionValue(value);
			break;
		case "languages":
			formatted_value = formatPickerValue(value, true);
			break;
		case "created_on":
		case "due_date":
		case "posted_on":
			if ("start" && "end" in value) {
				formatted_value = [
					dateToTimestamp(
						`${value.start.day}-${value.start.month}-${value.start.year}`
					),
					dateToTimestamp(
						`${value.end.day}-${value.end.month}-${value.end.year}`
					)
				];
			} else {
				formatted_value = [
					dateToTimestamp(`${value.day}-${value.month}-${value.year}`)
				];
			}
			break;
		default:
			return value;
	}

	return {
		field: name,
		op: operator,
		value: formatted_value
	};
};

export const formatFilterStore = async (columns, filter) => {
	const formatted_payload = flatMapDeep(columns, el => {
		return el.children;
	});

	const filterData =
		find(formatted_payload, el => {
			return el.name === filter.field;
		}) || {};

	let operator = get(filter, "op", "");
	if (
		get(filter, "field", "") === "created_at" &&
		get(filter, "value.length", 0) === 2
	) {
		operator = "range";
	}

	return {
		name: get(filter, "field", ""),
		operator: operator,
		type: get(filterData, "type", ""),
		label: get(filterData, "label", ""),
		value: await formatFilterValue(filter),
		payload: get(filterData, "payload", {})
	};
};

export const formatSkillValue = value => {
	let formatted_value = [];
	value.forEach(v => {
		const children = get(v, "children", []);
		children.forEach(c => {
			formatted_value.push({
				id: c.id,
				name: c.label,
				score: c.rating,
				category: {
					id: v.id,
					name: v.label
				}
			});
		});
	});
	return formatted_value;
};

export const formatFunctionValue = value => {
	let formatted_value = [];

	value.forEach(v => {
		const children = get(v, "children", []);
		children.forEach(c => {
			formatted_value.push({
				id: c.id,
				name: c.label,
				category: {
					id: v.id,
					name: v.label
				}
			});
		});
	});
	return formatted_value;
};

const formatStoreSkill = value => {
	let categories = {};
	value.forEach(v => {
		if (!(v.category.id in categories)) {
			categories[v.category.id] = {
				id: v.category.id,
				label: v.category.name,
				children: []
			};
		}
	});

	value.forEach(v => {
		categories[v.category.id].children.push({
			label: v.name,
			id: v.id,
			rating: v.score
		});
	});

	return Object.values(categories);
};

const formatFieldToStore = (value, label, withRating = false) => {
	const result = {
		id: 1,
		label: label,
		children: []
	};

	(value || []).forEach(v => {
		result.children.push({
			id: v.id,
			label: v.name,
			rating: withRating ? v.score : undefined
		});
	});

	return [result];
};

const formatCreatedByToStore = (value, label) => {
	const result = {
		id: 1,
		label: label,
		children: []
	};

	(value || []).forEach(v => {
		result.children.push({
			id: v.id,
			label: v.first_name + " " + v.last_name,
			rating: v.score
		});
	});

	return [result];
};

const formatFieldEmploy = (value, label) => {
	const result = {
		id: 1,
		label: label,
		children: []
	};

	(value || []).forEach(v => {
		result.children.push({
			id: v.value,
			label: v.label
		});
	});

	return [result];
};

const getLocation = searchObj => {
	const geocoder = new window.google.maps.Geocoder();
	return new Promise(resolve => {
		geocoder.geocode(searchObj).then(response => {
			resolve(get(response, "results[0]"));
		});
	});
};

const formatFilterValue = async filter => {
	const { field, value } = filter;

	if (field && !isEmpty(value)) {
		switch (field) {
			case "source":
				return formatFieldToStore(value, "sources");
			case "employment_type":
				return formatFieldEmploy(value, "Employment types");
			case "extra_benefits":
				return formatFieldToStore(value, "Extra benefits");
			case "salary_type":
				return formatFieldToStore(value, "Salary type");
			case "payroll_country":
				return formatFieldToStore(value, "Payroll country");
			case "currency":
				return formatFieldToStore(value, "Currencies");
			case "payment_type":
				return formatFieldToStore(value, "Payment type");
			case "residence":
			case "preferred_location":
				let searchObj = {};
				if ("place_id" in value) {
					searchObj = { placeId: value.place_id };
				} else {
					searchObj = {
						location: { lat: value.latitude, lng: value.longitude }
					};
				}
				const location = await getLocation(searchObj);

				return [
					{
						place: {
							...location,
							description: renderLocationValues(value)
						},
						radius: get(value, "distance", 0)
					}
				];
			case "category":
				return formatFieldToStore(value, "Categories");
			case "subcategory":
				return formatFieldToStore(value, "Sub Categories");
			case "functions":
				return formatStoreSkill(value);
			case "seniority":
			case "statues":
				return formatFieldToStore(value, "statues");
			case "skills":
				return formatStoreSkill(value);
			case "languages":
				return formatFieldToStore(value, "Languages", true);
			case "profile_tags":
				return formatFieldToStore(value, "Profile Tags");
			case "created_by":
				return formatCreatedByToStore(value, "Created by");
			case "created_at":
				if (value.length === 1) {
					return timestampToDateCalendar(value[0]);
				}
				if (value.length === 2) {
					return {
						start: timestampToDateCalendar(value[0]),
						end: timestampToDateCalendar(value[1])
					};
				}
				return [];
			default:
				return value;
		}
	}
	return "";
};
