import React from "react";
import { ReactComponent as BinIcon } from "static/icons/bin.svg";
import HeaderButton from "../HeaderButton/HeaderButton";

const DeleteButton = ({ onClick, isLoading }) => {
	return (
		<HeaderButton
			icon={<BinIcon />}
			text="Delete"
			className="btnDelete"
			variant="big"
			onClick={onClick}
			isLoading={isLoading}
		/>
	);
};

DeleteButton.displayName = "DeleteButton";

export default DeleteButton;
