import React, { memo } from "react";
import { CreateVacancySkeletonCard } from "../CreateVacancySkeletonCard";
import { CreateVacancyCard } from "../CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import styles from "./create-vacancy-profile-step-loading.module.scss";
import cx from "classnames";
import { ReactComponent as EmptyStarGreen } from "static/icons/empty-star-green.svg";

const CreateVacancyProfileStepLoading = ({ hasMultiSites }) => {
	return (
		<>
			<div className={styles.stepper} />
			<div className={styles.cardsList}>
				<CreateVacancyCard className={styles.card}>
					<CreateVacancyCardContent>
						<CreateVacancyFieldContainer>
							<CreateVacancyLabelContainer>
								<CreateVacancyFieldLabel>Department</CreateVacancyFieldLabel>
								<CreateVacancyInfoIcon>
									Please choose the department for which you are hiring. The
									users of the selected department will be able to see and
									interact with candidates for this vacancy (poke candidates,
									add notes on applications, etc..).
								</CreateVacancyInfoIcon>
							</CreateVacancyLabelContainer>
							<CreateVacancySkeletonCard />
						</CreateVacancyFieldContainer>
						{hasMultiSites && (
							<CreateVacancyFieldContainer>
								<CreateVacancyFieldLabel>Site</CreateVacancyFieldLabel>
								<CreateVacancySkeletonCard />
							</CreateVacancyFieldContainer>
						)}
						<CreateVacancyFieldContainer>
							<CreateVacancyLabelContainer>
								<CreateVacancyFieldLabel>Functions</CreateVacancyFieldLabel>
								<CreateVacancyInfoIcon>
									Select the function(s) that best describes your need and set
									the seniority that you want your candidates to have in that
									function (this is not a global seniority value). Note that you
									can select up to 5 functions to broaden the scope of your
									potential matches.
								</CreateVacancyInfoIcon>
							</CreateVacancyLabelContainer>
							<div className={styles.breadcrumbs}>
								<div className={styles.breadcrumb}>
									<div
										className={cx(
											styles.firstBreadcrumbItem,
											styles.breadcrumbItem
										)}
									>
										<CreateVacancySkeletonCard
											width={157}
											height={22}
											className={styles.top}
											style={{ borderRadius: "8px 0px 0px 0px" }}
										/>
										<CreateVacancySkeletonCard
											width={157}
											height={22}
											className={styles.bottom}
											style={{ borderRadius: "0px 0px 0px 8px" }}
										/>
									</div>
									<div
										className={cx(
											styles.thirdBreadcrumbItem,
											styles.breadcrumbItem
										)}
									>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px" }}
											width={157}
											height={22}
											className={styles.top}
										/>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px" }}
											width={157}
											height={22}
											className={styles.bottom}
										/>
									</div>
									<div
										className={cx(
											styles.fourthBreadcrumbItem,
											styles.breadcrumbItem
										)}
									>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px" }}
											width={157}
											height={22}
											className={styles.top}
										/>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px" }}
											width={157}
											height={22}
											className={styles.bottom}
										/>
									</div>
									<div
										className={cx(
											styles.lastBreadcrumbItem,
											styles.breadcrumbItem
										)}
									>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px 8px 0px 0px" }}
											width={157}
											height={22}
											className={styles.top}
										/>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px 0px 8px 0px" }}
											width={157}
											height={22}
											className={styles.bottom}
										/>
									</div>
									<EmptyStarGreen className={styles.containedIcon} />
								</div>
								<div className={styles.breadcrumb}>
									<div
										className={cx(
											styles.firstBreadcrumbItem,
											styles.breadcrumbItem
										)}
									>
										<CreateVacancySkeletonCard
											width={157}
											height={22}
											className={styles.top}
											style={{ borderRadius: "8px 0px 0px 0px" }}
										/>
										<CreateVacancySkeletonCard
											width={157}
											height={22}
											className={styles.bottom}
											style={{ borderRadius: "0px 0px 0px 8px" }}
										/>
									</div>
									<div
										className={cx(
											styles.thirdBreadcrumbItem,
											styles.breadcrumbItem
										)}
									>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px" }}
											width={157}
											height={22}
											className={styles.top}
										/>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px" }}
											width={157}
											height={22}
											className={styles.bottom}
										/>
									</div>
									<div
										className={cx(
											styles.fourthBreadcrumbItem,
											styles.breadcrumbItem
										)}
									>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px" }}
											width={157}
											height={22}
											className={styles.top}
										/>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px" }}
											width={157}
											height={22}
											className={styles.bottom}
										/>
									</div>
									<div
										className={cx(
											styles.lastBreadcrumbItem,
											styles.breadcrumbItem
										)}
									>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px 8px 0px 0px" }}
											width={157}
											height={22}
											className={styles.top}
										/>
										<CreateVacancySkeletonCard
											style={{ borderRadius: "0px 0px 8px 0px" }}
											width={157}
											height={22}
											className={styles.bottom}
										/>
									</div>
								</div>
							</div>
						</CreateVacancyFieldContainer>
						<CreateVacancyFieldContainer>
							<CreateVacancyLabelContainer>
								<CreateVacancyFieldLabel>Skills</CreateVacancyFieldLabel>
								<CreateVacancyInfoIcon>
									The skills are an essential part of the candidate/vacancy
									matching process. Be as precise as possible (without being too
									demanding) in the selection of the skills needed and the
									weight/importance they have for the role.
								</CreateVacancyInfoIcon>
							</CreateVacancyLabelContainer>
							<div className={styles.skillsSkeletonContainer}>
								<CreateVacancySkeletonCard useRandomWidth />
								<CreateVacancySkeletonCard useRandomWidth />
								<CreateVacancySkeletonCard useRandomWidth />
							</div>
						</CreateVacancyFieldContainer>
						<CreateVacancyFieldContainer>
							<CreateVacancyLabelContainer>
								<CreateVacancyFieldLabel>Languages</CreateVacancyFieldLabel>
								<CreateVacancyInfoIcon>
									Languages are another fundamental part of the matching
									process. Although they are weighted less than skills in the
									matching score calculation, it is still important to properly
									outline your linguistic skills.
								</CreateVacancyInfoIcon>
							</CreateVacancyLabelContainer>
							<div className={styles.languagesSkeletonContainer}>
								<CreateVacancySkeletonCard useRandomWidth />
								<CreateVacancySkeletonCard useRandomWidth />
							</div>
						</CreateVacancyFieldContainer>
					</CreateVacancyCardContent>
				</CreateVacancyCard>
				<div className={styles.nextButtonContainer}>
					<CreateVacancySkeletonCard width="78px" />
				</div>
			</div>
		</>
	);
};

export default memo(CreateVacancyProfileStepLoading);
