import React from "react";
import useDeleteAccountStore from "../store";
import styles from "../styles.module.scss";
import generalSettingsStyles from "../../../styles.module.scss";
import { ReactComponent as GreenCheckIcon } from "static/icons/greenCheckIcon.svg";
import { useUpdateUserStatus } from "modules/user/api/useUpdateUserStatus";
import { useSelector } from "react-redux";
import toaster from "common/Toaster";
import { INACTIVE } from "config";

const data = [
	{
		default: "All your data will be invisible",
		inactive: "All your data will be invisible"
	},
	{
		default: "All your current processes are put on hold",
		inactive: "All your current processes are put on hold"
	},
	{
		default: "You will stop receiving job alerts",
		inactive: "You are not receiving any job alerts"
	}
];

export default function FirstStep({ onClose }) {
	const { incrementStep } = useDeleteAccountStore();
	const userStatus = useSelector(state => state.auth.user.candidate_status);

	// update user status
	const [updateStatus, { isLoading }] = useUpdateUserStatus();
	const onSwitch = () =>
		updateStatus(
			{ status: INACTIVE },
			{
				onSuccess: () => {
					onClose();
					toaster.success("You account is now on inactive mode!");
				}
			}
		);

	return (
		<div>
			<div className={styles.flex_col} style={{ margin: "25px 0" }}>
				<h5 className={styles.subtitle}>
					{userStatus === INACTIVE
						? "Your account is currently in inactive mode, which means that:"
						: "Instead of deleting your account, you can switch to inactive mode"}
				</h5>
				{data.map((item, index) => {
					return (
						<div key={index} className={styles.list_item}>
							<GreenCheckIcon />
							<p>{userStatus === INACTIVE ? item.inactive : item.default}</p>
						</div>
					);
				})}
			</div>
			<div className={styles.buttons} style={{ justifyContent: "end" }}>
				<button
					disabled={isLoading}
					onClick={incrementStep}
					className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__danger}`}
				>
					Delete account
				</button>
				{userStatus !== INACTIVE && (
					<button
						onClick={onSwitch}
						disabled={isLoading}
						className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__green}`}
					>
						Switch to inactive
					</button>
				)}
			</div>
		</div>
	);
}
