import { renderError } from "config/helpers";

import { SEND_EMAIL } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";

function sendEmail(body) {
	return client(SEND_EMAIL, { body });
}

const useSendEmail = options => {
	return useMutation(sendEmail, {
		...options,
		onError: renderError
	});
};

export default useSendEmail;
