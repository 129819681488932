import React from "react";
import Tooltip from "common/Tippy";
import styles from "./bid-details-tooltips.module.scss";

const BidDetailsStatusTooltip = ({ content, children }) => {
	return content?.length ? (
		<Tooltip
			className={styles.tooltip}
			content={content}
			theme="dark"
			placement="bottom"
		>
			{children}
		</Tooltip>
	) : (
		<>{children}</>
	);
};

export default BidDetailsStatusTooltip;
