import React from "react";
import Container from "./Container";
import useWindowSize from "hooks/useWindowSize";

export default function Header({ children }) {
	const isMobile = useWindowSize();
	return (
		<div className="content__header">
			<Container
				style={{ height: "100%", width: isMobile ? "92%" : undefined }}
			>
				{children}
			</Container>
		</div>
	);
}
