/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useRef, useState } from "react";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import { CANDIDATE_FILTER_DATA } from "config/api-endpoints";
import { client } from "lib/api-client";
import { usePaginatedQuery } from "react-query";
import { useDebounce } from "react-use";
import isEqual from "lodash/isEqual";
import { CANDIDATES_FILTER } from "../utils/constants";
import { formatFilters } from "common/SmartFilterDrawer/utils/format_utils";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import {
	checkIfSchemaIsCorrect,
	isSmartQueryValid,
	parseSmartQuery
} from "common/SmartFilterDrawer/utils/helpers";

export const filterData = (body, customConfig) => {
	return client(CANDIDATE_FILTER_DATA, {
		body,
		...customConfig
	});
};

export const KEY_FETCH_CANDIDATES = "KEY_FETCH_CANDIDATES";

const useFilterData = (job_id, { enabled = true }) => {
	const {
		setQueryError,
		setIsLoading,
		setIsFetching,
		getState,
		setData,
		currentFilterId,
		setSmartQueryError,
		setInvalidElementsIndex
	} = useFilters();
	const {
		source,
		query,
		logicalOperator,
		filters,
		sortBy,
		offset,
		filterChangeTrigger,
		limit,
		smartQuerySchema,
		smartQueryError,
		invalidElementsIndex
	} = getState(CANDIDATES_FILTER);

	const previousFilters = useRef();
	const [body, setBody] = useState(formatBody());

	const { workflowId } = useOnboardingStore();
	const abortController = new AbortController();
	const signal = abortController.signal;
	const {
		resolvedData: data,
		isLoading,
		isFetching,
		refetch,
		isFetched
	} = usePaginatedQuery(
		[KEY_FETCH_CANDIDATES, body, workflowId],
		() => filterData(body, { signal }),
		{
			onError: e => {
				if (e?.detail?.keywords) {
					setQueryError(true);
				}
			},
			refetchOnWindowFocus: false,
			enabled: !!body?.data_source && !!body?.limit && enabled,
			retry: 1
		}
	);

	useEffect(() => {
		if (enabled) {
			setIsLoading(isLoading);
			setIsFetching(isFetching && !isFetched);
		}
	}, [isLoading, isFetching, refetch, isFetched]);

	useEffect(() => {
		if (currentFilterId) {
			setData(data);
			setQueryError(false);
		}
	}, [data, currentFilterId]);

	const memoizedCheckIfSchemaIsCorrect = useMemo(
		() => checkIfSchemaIsCorrect(smartQuerySchema),
		[smartQuerySchema]
	);

	useEffect(() => {
		const validFilters = getValidFilters(filters);
		const validSmartQuery = getValidFilters(smartQuerySchema);
		const globalFilter = {
			validFilters,
			validSmartQuery,
			source,
			sortBy,
			offset,
			logicalOperator
		};

		/** CHECK IF SMART QUERY VALID ***/

		if (smartQuerySchema.length) {
			if (smartQueryError) setSmartQueryError(null);
			if (!isSmartQueryValid(smartQuerySchema)) {
				setSmartQueryError("Invalid query");
				return;
			}

			const {
				correct,
				unmatchedParentheses,
				error
			} = memoizedCheckIfSchemaIsCorrect;

			setInvalidElementsIndex(unmatchedParentheses);
			if (error) {
				setSmartQueryError(error);
				return;
			}

			if (!correct) {
				return;
			}
		}

		const isFiltersChanged = !isEqual(previousFilters.current, globalFilter);

		if (isFiltersChanged) {
			previousFilters.current = globalFilter;
		}
		if (
			(filterChangeTrigger !== "change" &&
				filterChangeTrigger !== "newFilter" &&
				filterChangeTrigger !== "addColumn" &&
				isFiltersChanged &&
				!!source) ||
			filterChangeTrigger === "reset" ||
			filterChangeTrigger === "limitChange" ||
			filterChangeTrigger === "newOperator"
		) {
			if (enabled) {
				setBody(formatBody());
			}
		}
	}, [
		job_id,
		smartQuerySchema,
		filters,
		source,
		sortBy,
		offset,
		filterChangeTrigger,
		logicalOperator,
		limit
	]);

	useDebounce(
		() => {
			if (
				filterChangeTrigger === "change" &&
				!!source &&
				enabled &&
				!smartQueryError &&
				invalidElementsIndex.length === 0
			) {
				setBody(formatBody());
			}
		},
		500,
		[filters, query, smartQuerySchema]
	);

	const memoizedParseSmartQuery = useMemo(
		() => parseSmartQuery(formatFilters(smartQuerySchema)),
		[smartQuerySchema]
	);

	const memoizedFormatFilters = useMemo(() => formatFilters(filters), [
		filters
	]);

	function formatBody() {
		const andRegex = /\band\b/gi;
		const orRegex = /\bor\b/gi;
		const notRegex = /\bnot\b/gi;

		const queryUpdated = query
			.replaceAll(andRegex, " AND ")
			.replaceAll(orRegex, " OR ")
			.replaceAll(notRegex, " NOT ");
		let payload = {
			data_source: source,
			keywords: queryUpdated,
			offset,
			sort_by: sortBy,
			limit
		};
		if (smartQuerySchema.length) {
			const smart_query = memoizedParseSmartQuery;
			payload = {
				...payload,
				smart_query_filter: smart_query
					? JSON.parse(JSON.stringify(smart_query))
					: {}
			};
			if (job_id) payload = { ...payload, template_id: job_id }; // potential matches
		} else {
			payload = {
				...payload,
				fields: memoizedFormatFilters,
				op: logicalOperator
			};
		}
		return payload;
	}

	function getValidFilters(filters) {
		//Be careful about false value, filtering a filter with false value is a bug
		//In case of expected salary, entering 0 will be filtered, the ideal should be including the salary to the filter!
		return filters.filter(({ value }) => {
			if (
				[undefined, null, "", 0].includes(value) ||
				(Array.isArray(value) && value.length === 0) ||
				(value !== null &&
					typeof value === "object" &&
					Object.keys(value).length === 0)
			) {
				return false;
			} else {
				return true;
			}
		});
	}

	return refetch;
};

export default useFilterData;
