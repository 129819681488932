import React from "react";
import { CustomAvatar } from "common/CustomAvatar";
import cx from "classnames";
import styles from "./new-multiple-select.module.scss";

const NewMultipleSelectItems = ({ selectedItems, children }) => {
	return (
		<div className={styles.addButtonComponent}>
			{selectedItems.map((e, i) => (
				<div className={styles.avatarWrapper} key={e.id}>
					<CustomAvatar
						src={e.avatar}
						variant="small"
						fullName={`${e.label}`}
						rootClassName={cx(styles.collaboratorPicture, {
							[styles.firstCollaboratorPicture]: i === 0
						})}
						imgClassName={cx(styles.picture)}
						avatarClassName={cx(styles.avatarWithBorder, styles.avatar, {
							[styles.firstCollaboratorPicture]: i === 0
						})}
						avatarSize={30}
						rootStyle={{
							zIndex: i
						}}
						avatarStyle={{
							zIndex: i
						}}
					/>
				</div>
			))}
			{children}
		</div>
	);
};

export default NewMultipleSelectItems;
