export const EVALUATION_TAB_ID = "EVALUATION_TAB_ID";
export const EVALUATION_CV_ID = "EVALUATION_CV_ID";

export const TABS = [
	{
		id: EVALUATION_TAB_ID,
		component: "Evaluation form"
	},
	{
		id: EVALUATION_CV_ID,
		component: "Resume (CV)"
	}
];
