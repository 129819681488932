import * as types from "./actionTypes";
import { CREATED_AT, DESC } from "config";
import {
	INTERRUPT_MISSION,
	LIST_CONTRACTS,
	EXTEND_MISSION
} from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";

export function switchManager(manager) {
	return { type: types.SWITCH_MANAGER, manager };
}

export function togglePhoneModal() {
	return { type: types.TOGGLE_PHONE_MODAL };
}

export function updateFilter(filter) {
	return dispatch => {
		dispatch({ type: types.UPDATE_FILTER, filter });
		return dispatch(
			AxiosHelper.__post({
				url: LIST_CONTRACTS,
				data: filter,
				REQUEST_SUCCESS: types.FETCH_SUCCESS
			})
		);
	};
}
export function resetFilters() {
	return {
		type: types.RESET_FILTERS
	};
}

export function fetchMissionsList(
	payload = { field: CREATED_AT, sort: DESC, offset: 0 }
) {
	return dispatch => {
		return dispatch(
			AxiosHelper.__post({
				url: LIST_CONTRACTS,
				data: payload,
				REQUEST_SUCCESS: types.FETCH_SUCCESS
			})
		);
	};
}

export function toggleInterruptModal(id = null) {
	return {
		type: types.TOGGLE_INTERRUPT_MODAL,
		id
	};
}
export function toggleExtendModal(mission = {}) {
	return {
		type: types.TOGGLE_EXTEND_MODAL,
		mission
	};
}

export function closeExtendModal() {
	return {
		type: types.CLOSE_EXTEND_MODAL
	};
}

export function interruptMission(id) {
	return AxiosHelper.__post({
		url: INTERRUPT_MISSION,
		data: { id },
		isModal: true,
		REQUEST_SUCCESS: types.TOGGLE_INTERRUPT_MODAL,
		toastMessage:
			"The interruption request has successfully been submitted. Our support team will get in touch with you shortly with the necessary documents.",
		next: () => dispatch => {
			dispatch(fetchMissionsList());
			return dispatch(toggleInterruptModal());
		}
	});
}

export function extendMission(payload) {
	return AxiosHelper.__post({
		url: EXTEND_MISSION,
		data: payload,
		REQUEST_SUCCESS: types.TOGGLE_EXTEND_MODAL,
		toastMessage:
			"The extension proposition has successfully been made. Our support team will get in touch with you shortly with the necessary documents.",
		next: () => fetchMissionsList()
	});
}
