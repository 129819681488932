import React from "react";
import { BannerHelpWrapper, BannerTitle, BannerBody } from "./Styled";
import { ReactComponent as IconHelp } from "../IconHelp.svg";

const BannerHelp = ({ onClose }) => {
	return (
		<BannerHelpWrapper>
			<div className="section-1">
				<IconHelp />
			</div>
			<div className="section-2">
				<BannerTitle>HELP</BannerTitle>
				<BannerBody>
					These settings allow you to integrate your usual suppliers as
					preferred contacts and browse through a list of verified suppliers.
					<br /> It also allows you to define a workflow over time to bring
					other service providers into play and to block some with whom you no
					longer wish to work.
				</BannerBody>
			</div>
			<div className="section-3">
				<span className="icon-close2" onClick={onClose} />
			</div>
		</BannerHelpWrapper>
	);
};
export default BannerHelp;
