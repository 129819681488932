import React, { useEffect } from "react";
import styles from "./form-builder-element-score-card.module.scss";
import { ReactComponent as AddIcon } from "static/icons/plus.svg";
import { useFieldArray, useFormContext } from "react-hook-form";
import FormBuilderElementCard from "../FormBuilderElementCard/FormBuilderElementCard";
import get from "lodash/get";
import FieldError from "../FieldError";
import { ELEMENT_LABELS, SCORE_CARD_ITEM_SCHEMA } from "../../utils/constants";
import FormBuilderDragButton from "../FormBuilderDragButton";
import FormBuilderActionToggleRequired from "../FormBuilderActionToggleRequired";
import FormBuilderActionDuplicate from "../FormBuilderActionDuplicate";
import FormBuilderActionDelete from "../FormBuilderActionDelete";
import cx from "classnames";

export default function FormBuilderElementScoreCard({
	element,
	path,
	onToggleRequired,
	onDelete,
	onDuplicate,
	dragHandleProps,
	isPresentational
}) {
	const {
		formState: { errors }
	} = useFormContext();
	const fieldArrayName = `${path}.items`;
	const fieldArray = useFieldArray({
		name: fieldArrayName
	});

	useEffect(() => {
		if (!fieldArray.fields.length) {
			onAddCard();
		}
	}, []);

	const onAddCard = () => {
		fieldArray.append(structuredClone(SCORE_CARD_ITEM_SCHEMA));
	};

	return (
		<div
			className={cx(styles.container, { [styles.shadow]: isPresentational })}
		>
			<FormBuilderDragButton
				className={styles.dragHandler}
				dragHandleProps={dragHandleProps}
			/>
			<div className={styles.subContainer}>
				<div className={styles.header}>
					<div className={styles.title}>{ELEMENT_LABELS.score_card}</div>
					<FormBuilderActionToggleRequired
						onClick={onToggleRequired}
						value={element.is_required}
					/>
					<FormBuilderActionDuplicate onClick={onDuplicate} />
					<FormBuilderActionDelete onClick={onDelete} />
				</div>
				<div className={styles.content}>
					<div className={styles.cards}>
						<FieldError
							error={get(errors, path)?.items}
							className={styles.contentError}
						/>
						{(isPresentational ? element.items : fieldArray.fields).map(
							(element, index) => (
								<FormBuilderElementCard
									isPresentational={isPresentational}
									element={element}
									isDeletable={!!index}
									key={element.id}
									path={`${fieldArrayName}.${index}`}
									onDeleteCard={() => {
										fieldArray.remove(index);
									}}
								/>
							)
						)}
					</div>
					<button
						onClick={onAddCard}
						type="button"
						className={styles.addButton}
					>
						<AddIcon />
						<span>Add card</span>
					</button>
				</div>
			</div>
		</div>
	);
}
