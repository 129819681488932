import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import _differenceBy from "lodash/differenceBy";
import _filter from "lodash/filter";
import styled from "styled-components";
import _find from "lodash/find";
import _get from "lodash/get";
import { browserHistory } from "react-router";
import GlobalTooltip from "common/GlobalTooltip";
import {
	ASSIGN_ROLE,
	INVITE_PREVIEW,
	PROJECT_MANAGER,
	ACCEPTED,
	ADMIN,
	SUPER_ADMIN,
	STEPS
} from "config";

import { getGlobalOptions } from "modules/job/selectors";
import StyledSelect from "common/StyledSelect";
import MultiValue from "common/MultiValue";
import MenuWithCTA from "common/MenuWithCTA";

import styles from "./create-user.module.scss";
import { ReactComponent as CloseIcon } from "static/icons/referral/x.svg";
import { ReactComponent as ArrowUp } from "static/icons/arrow-up-right.svg";
import Steps from "common/steps";
import { CREATE_USER } from "config";

const SCSelect = styled(StyledSelect)`
	.Select--multi {
		.Select-multi-value-wrapper {
			display: flex;
			height: 100%;
			align-items: center;
			flex-wrap: wrap;
		}
		.Select-input {
			height: 0;
			width: 0;
		}
		.Select-aria-only {
			display: none;
		}
	}
`;

export const UserModalContainer = styled.div`
	p,
	h4 {
		margin-bottom: 12px !important;
	}
	p:last-of-type {
		margin-bottom: 17px !important;
	}
	.Select-option {
		padding: 12px 15px !important;
	}
	#or-container {
		padding: 7px 0 !important;
	}
	#cta-holder {
		padding: 0 21px 10px !important;
	}
	#list-box {
		max-height: 200px !important;
	}
`;

class AssignRole extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role_name: "",
			submitted: false,
			selectedDep: "",
			isSaving: false
		};
	}

	manager_list = [];
	admin_list = [];

	componentDidMount() {
		const {
			departmentList,
			user: { group_roles_name, invitation_status: status, user_admin },
			assignRoleDataForm,
			assignDepartment
		} = this.props;
		if (departmentList) {
			// initializing the selected departments
			this.manager_list = this.getSelectedDepartments(PROJECT_MANAGER);
			this.admin_list = this.getSelectedDepartments(ADMIN);
			this.setState({
				departmentList: this.getDeptInitialValue()
			});
		}

		this.setState({
			role_name:
				_get(group_roles_name, "0.role_name", "") ||
				assignRoleDataForm?.role_name,
			selectedDep: {
				label:
					_get(group_roles_name, "0.name", "") ||
					assignDepartment?.selectedDep?.label,
				value:
					_get(group_roles_name, "0._id", "") ||
					assignDepartment?.selectedDep?.value
			}
		});

		if (status === "accepted" && user_admin) {
			this.setState({
				role_name: SUPER_ADMIN
			});
		}
	}

	getSelectedDepartments = type => {
		const {
			connectedUser: { active_role },
			user: { group_roles_name },
			connectedUserRoles
		} = this.props;

		if (type) {
			let list = getGlobalOptions(
				_filter(group_roles_name, {
					role_name: type
				}),
				"_id"
			);

			const isConnectedUserSuperAdmin = active_role === "admin";
			const isAdmin = !isConnectedUserSuperAdmin ? type === ADMIN : false;

			return list.map(element => {
				const userRole = _find(connectedUserRoles, { _id: element._id });
				const hasDeptAndIsAdmin = userRole
					? userRole.role_name === ADMIN
					: false;

				return {
					...element,
					clearableValue:
						isConnectedUserSuperAdmin || (!isAdmin && hasDeptAndIsAdmin)
				};
			});
		}
		return [];
	};

	getDeptInitialValue = () => {
		const {
			connectedUser: { active_role, role_name },
			connectedUserRoles,
			departmentList
		} = this.props;

		let list = [];
		// if the connected user is admin and the user has already accepted the invitation
		if (active_role === "member" && role_name === ADMIN) {
			list = _filter(connectedUserRoles, { role_name: ADMIN });
		} else {
			list = departmentList;
		}
		if (list.length) {
			// remove selected departments from options
			list = _differenceBy(
				list,
				this.manager_list.concat(this.admin_list),
				"_id"
			);
		}

		return getGlobalOptions(list, "_id");
	};

	handleNext = () => {
		this.setState({ isSaving: true });
		const { role_name, selectedDep } = this.state;
		const { isEdit, user, isModeTour } = this.props;
		const selectedRoles = this.getFinalList();
		const userAccepted = user.invitation_status === ACCEPTED;
		if (role_name !== SUPER_ADMIN) {
			if (
				(!userAccepted && (!role_name || !selectedDep)) ||
				(userAccepted && selectedRoles.list.length <= 0)
			) {
				return this.setState({ submitted: true });
			}
		}

		const payload = {
			role_name: role_name || ADMIN,
			first_name: user.first_name,
			last_name: user.last_name
		};

		if (isEdit) {
			payload.id = user._id ? user._id : user.invitation_id;
			payload.status = user.invitation_status;
		}

		if (!userAccepted) payload.group_id = selectedDep.value;

		if (userAccepted) payload.groups = selectedRoles.payload;
		if (role_name === SUPER_ADMIN) {
			delete payload.groups;
		}
		this.props
			.handleStep(ASSIGN_ROLE, isModeTour, INVITE_PREVIEW, payload, isEdit)
			.then(() => this.setState({ isSaving: false }));
	};

	getFinalList = () => {
		const {
			user: { group_roles_name, invitation_status }
		} = this.props;
		const userAccepted = invitation_status === ACCEPTED;
		if (!userAccepted) return;
		let formattedList = [];
		let finalList = {
			add: [],
			remove: []
		};

		this.manager_list.map(element => {
			return formattedList.push({
				group_id: element._id,
				role_name: PROJECT_MANAGER
			});
		});

		this.admin_list.map(element => {
			return formattedList.push({ group_id: element._id, role_name: ADMIN });
		});

		// get added or changed departments

		for (let group of formattedList) {
			let found = group_roles_name.some(el => {
				return el._id === group.group_id;
			});

			if (!found) {
				finalList.add.push(group);
			}

			if (found) {
				let oldRole = group_roles_name.find(
					role => role._id === group.group_id
				);

				if (group.role_name !== oldRole.role_name) {
					finalList.add.push(group);
					if (oldRole._id && oldRole.role_name) {
						finalList.remove.push({
							group_id: oldRole._id,
							role_name: oldRole.role_name
						});
					} else {
						finalList.remove = [];
					}
				}
			}
		}

		// get removed departments
		for (let el of group_roles_name) {
			let found = formattedList.some(group => {
				return el._id === group.group_id;
			});

			if (!found) {
				if (el._id && el.role_name) {
					finalList.remove.push({
						group_id: el._id,
						role_name: el.role_name
					});
				} else {
					finalList.remove = [];
				}
			}
		}

		return {
			payload: finalList,
			list: this.manager_list.concat(this.admin_list)
		};
	};

	saveDepartment = selectedDep => {
		this.setState({ selectedDep });
		this.props.setAssignDepartment({ selectedDep });
	};

	handleSelectChange = (list, name) => {
		// updating the selected lists
		this[`${name}_list`] = list;
		// updating the options list
		this.setState({
			submitted: false,
			departmentList: this.getDeptInitialValue()
		});
	};

	goToDept = () => {
		this.props.onClose();
		this.props.toggleDepartmentModal();
		browserHistory.push("/settings/users");
	};

	render() {
		const { role_name, submitted, selectedDep, departmentList } = this.state;
		const {
			isEdit,
			user,
			onClose,
			moveToStep,
			currentStep,
			showStepper,
			connectedUser: { active_role, is_member },
			setAssignRoleDataForm
		} = this.props;
		const userAccepted = user.invitation_status === ACCEPTED;

		return (
			<div className={styles.userFormContainer}>
				<header className={styles.header}>
					<h3 className={styles.title}>
						{isEdit ? "Edit user" : "Create user"}
					</h3>
					<div
						role="button"
						tabIndex="0"
						onClick={onClose}
						className={styles.button}
					>
						<CloseIcon />
					</div>
				</header>

				<UserModalContainer className={styles.containerAssignRole}>
					{!isEdit && (
						<div className={styles.steps}>
							<Steps
								labelPlacement="vertical"
								steps={STEPS}
								activeStep={currentStep}
								active={showStepper}
								noStyles={true}
							/>
						</div>
					)}
					<div className={styles.grayBlock}>
						<h4>
							{isEdit
								? `For : ${user.first_name} ${user.last_name}`
								: "Please select one of the following two roles for the relevant department: "}
						</h4>
						<p>
							Users with an <strong>Super Admin</strong> role have access to all
							available functionalities for the whole account . These include
							modifying company information, managing users, signing contracts,
							and posting vacancies on (payable) job boards, among many other
							permissions.
						</p>
						<p>
							Users with an <strong>Admin</strong> role have access to all
							available functionalities within their department(s). These
							include modifying company information, managing users, signing
							contracts, and posting vacancies on (payable) job boards, among
							many other permissions.
						</p>
						<p>
							Users with a <strong>Project Manager</strong> role only have
							access to operational functions within their department(s). This
							means that they are unable to modify company information, manage
							users, sign contracts, or post vacancies on (payable) job boards
							without an Admin’s approval.
						</p>
					</div>
					<div className={styles.userRoles}>
						<div className={userAccepted ? "users-box" : "roles-box"}>
							{!userAccepted
								? [
										<div
											className="form-group select-label form-container"
											key="label"
										>
											<label className="labelText">Choose role</label>
										</div>,
										<div className="rolesContainer" key="radio">
											{!is_member && (
												<div className="form-group radio first">
													<div className="input-box">
														<input
															className="form-control"
															checked={role_name === SUPER_ADMIN}
															type="radio"
															id="super_admin"
															name="role"
															onChange={() =>
																this.setState({ role_name: SUPER_ADMIN })
															}
														/>
														<label htmlFor="super_admin">Super admin</label>
													</div>
												</div>
											)}
											<div className="form-group radio">
												<div className="input-box">
													<input
														className="form-control"
														checked={role_name === ADMIN}
														type="radio"
														id="admin_role"
														name="role"
														onChange={() => {
															this.setState({ role_name: ADMIN });
															setAssignRoleDataForm({ role_name: ADMIN });
														}}
													/>
													<label htmlFor="admin_role">Admin </label>
												</div>
											</div>
											<div className="form-group radio">
												<div className="input-box">
													<input
														className="form-control"
														checked={role_name === PROJECT_MANAGER}
														type="radio"
														id="project_manager_role"
														name="role"
														onChange={() => {
															this.setState({ role_name: PROJECT_MANAGER });
															setAssignRoleDataForm({
																role_name: PROJECT_MANAGER
															});
														}}
													/>
													<label htmlFor="project_manager_role">
														Project Manager
													</label>
												</div>
											</div>
											<div className="help-block inline-error">
												{submitted && !role_name && "Please choose a role"}
											</div>
											<div className="clear" />
										</div>,
										role_name !== SUPER_ADMIN && (
											<Field
												name="department"
												toolTipOverlay="This allows you to group users by departments, business entity, etc.
										Note that the user will only be able to interact with the requests, applications, timesheets,
										etc of the selected departement. You can only invite a user to one departement at this stage;
										but you will be able to link her/him to more departements once the invitation has been accepted."
												clearable={false}
												component={MenuWithCTA}
												className="select-restyle"
												key="select"
												label="Assign a department"
												isLoading={false}
												placeholder="Select a department..."
												defaultValue={selectedDep}
												onChange={this.saveDepartment}
												options={departmentList}
												position={false}
												searchable={true}
												meta={{
													touched: submitted,
													error:
														!selectedDep &&
														"Please choose at least one department"
												}}
												cta={
													active_role !== ADMIN
														? null
														: () => (
																<button
																	className={styles.btnCreateNewDepartment}
																	onClick={this.goToDept}
																>
																	<span>Create a new department</span>
																	<ArrowUp />
																</button>
														  )
												}
											/>
										)
								  ]
								: [
										<div className="select-label" key={"edit-title"}>
											<label>
												ASSIGN ROLES AND DEPARTMENTS {"  "}
												<GlobalTooltip overlay="This allows you to group users by departments, business entity, etc. Note that the user will only be able to interact with the requests, applications, timesheets, etc of the selected departement. You can only invite a user to one departement at this stage; but you will be able to link her/him to more departements once the invitation has been accepted." />
											</label>
										</div>,
										<div key={"list-department"}>
											{!is_member && (
												<div className="select-wrapper">
													<div className="select-label">
														Super Admin{" "}
														<input
															checked={role_name === SUPER_ADMIN}
															type="checkbox"
															id="super_admin"
															onChange={() =>
																role_name === SUPER_ADMIN
																	? this.setState({ role_name: "" })
																	: this.setState({ role_name: SUPER_ADMIN })
															}
															className={styles.selectRole}
														/>
													</div>
												</div>
											)}

											<div className="select-wrapper">
												<div className="select-label">Admin</div>
												<div className="department-select">
													<Field
														multi={true}
														isLoading={false}
														component={SCSelect}
														name="user-select-admin"
														placeholder="Choose a department"
														valueToSet={this.admin_list}
														valueComponent={MultiValue}
														searchable={true}
														onChange={(o, l) =>
															this.handleSelectChange(l || o, "admin")
														}
														props={{
															disabled: role_name === SUPER_ADMIN
														}}
														options={departmentList}
														className="select-restyle"
													/>
												</div>
											</div>
											<div className="select-wrapper">
												<div className="select-label">Project Manager</div>
												<div className="department-select">
													<Field
														multi={true}
														isLoading={false}
														component={SCSelect}
														name="user-select-manager"
														placeholder="Choose a department"
														valueToSet={this.manager_list}
														valueComponent={MultiValue}
														searchable={true}
														onChange={(o, l) =>
															this.handleSelectChange(l || o, "manager")
														}
														props={{
															disabled: role_name === SUPER_ADMIN
														}}
														options={departmentList}
														className="select-restyle"
													/>
												</div>
											</div>
										</div>,
										<div
											key="error"
											className={`help-block inline-error ${
												userAccepted ? "" : "marginLeft"
											}`}
										>
											{submitted && "Please choose at least one department"}
										</div>
								  ]}
						</div>
					</div>
				</UserModalContainer>

				<div className={styles.footer}>
					<button
						className={styles.submitBtn}
						onClick={this.handleNext}
						disabled={this.state.isSaving}
					>
						{isEdit ? "Update role" : "Next"}
					</button>
					<button
						type="button"
						className={styles.cancelBtn}
						onClick={() => moveToStep(CREATE_USER, user)}
					>
						Back
					</button>
				</div>
			</div>
		);
	}
}

export default reduxForm({
	form: "assign-role-modal"
})(AssignRole);
