import React, { PureComponent } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, initialize, destroy, formValueSelector } from "redux-form";
import _get from "lodash/get";
import Modal from "common/modal";
import StepOne from "./StepOne";
import { createBU, editBU } from "../../redux/actions";

const Wrapper = styled.div`
	margin-top: 50px;
	max-width: ${props => props.width || 90}%;
`;

class BusinessUnitModal extends PureComponent {
	componentDidUpdate(prevProps) {
		const prevBUID = _get(prevProps, "currentBU._id", undefined);
		const nextBUID = _get(this.props, "currentBU._id", undefined);
		if (nextBUID && prevBUID !== nextBUID) {
			this.setState({ step: 1 });
			const bu = this.props.currentBU;
			let initialValues = {
				name: bu.name
			};
			this.props.dispatch(initialize("business-unit", initialValues));
		}
		if (!this.props.activeModal) this.props.dispatch(destroy("business-unit"));
	}

	submitForm = ({ name }) => {
		const { currentBU, dispatch } = this.props;
		if (currentBU) {
			dispatch(
				editBU(
					{
						id: currentBU._id,
						name
					},
					"business-unit"
				)
			).then(this.onClose);
		} else {
			dispatch(createBU(name, "business-unit")).then(this.onClose);
		}
	};

	onClose = () => {
		const { toggleModal } = this.props;
		//load users list after creating/editing a Business Unit to get the refreshed BU list in the select
		toggleModal({ show: false, buid: undefined });
	};

	render() {
		const { currentBU, activeModal, handleSubmit } = this.props;

		return (
			<Modal
				title={`${currentBU ? "Edit" : "Create"} Business Unit`}
				modalName="business-unit-modal"
				active={activeModal}
				onClose={this.onClose}
				fixed={true}
				maxwidth={"700px"}
				firstButton={{
					label: "cancel",
					action: this.onClose,
					type: "outlined",
					size: "12"
				}}
				secondButton={{
					label: "save & continue",
					action: handleSubmit(this.submitForm),
					type: "primary",
					size: "12"
				}}
			>
				<Wrapper>
					<StepOne currentBU={currentBU} />
				</Wrapper>
			</Modal>
		);
	}
}

const selector = formValueSelector("business-unit");

const mapStateToProps = state => ({
	fields: selector(state, "first_name", "last_name", "email", "phone")
});

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "business-unit",
		touchOnBlur: false
	})
)(BusinessUnitModal);
