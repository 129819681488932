import React, { useState, useEffect, useMemo } from "react";
import { queryCache } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import isEmpty from "lodash/isEmpty";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import _get from "lodash/get";
import _omit from "lodash/omit";
import ToggleInput from "../ToggleInput";
import style from "../../my-company.module.scss";
import { ReactComponent as IconPen } from "static/icons/iconPen.svg";
import { ReactComponent as IconLeft } from "static/icons/IconLeft.svg";
import { SaveButton } from "common/SaveButton";
import { useSelector } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { ADMIN } from "config";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { useTour } from "@reactour/tour";
import { SELECTORS_TOUR_SETUP_COMPANY } from "modules/user-onboarding/workflows/setup-company";
import {
	INTERACTIVE_CONTAINER_STYLES,
	TOUR_SETUP_COMPANY
} from "config/onboarding";

const schema = yup.object().shape({
	items: yup.array().of(
		yup.object().shape({
			name: yup
				.string()
				.nullable()
				.email("This is not a valid email")
		})
	),
	credit_collect_email: yup
		.string()
		.nullable()
		.email("This is not a valid email")
});

const useTourData = () => {
	const { workflowId, activeOverlay, setActiveOverlay } = useOnboardingStore();
	const { setIsOpen, setCurrentStep, currentStep, isOpen } = useTour();

	const isActiveSetupCompanyTour = useMemo(
		() => workflowId === TOUR_SETUP_COMPANY,
		[workflowId]
	);

	const closeTour = () => {
		if (isActiveSetupCompanyTour) {
			setIsOpen(false);
			setActiveOverlay(true);
		}
	};

	const goNextTourStep = () => {
		if (isActiveSetupCompanyTour) {
			setActiveOverlay(false);
			setCurrentStep(4);
			setIsOpen(true);
		}
	};

	return {
		closeTour,
		goNextTourStep,
		toasterDuration: isActiveSetupCompanyTour ? 500 : false,
		selectors: {
			four: SELECTORS_TOUR_SETUP_COMPANY[3]
		},
		selectorsClassNames: {
			four:
				isActiveSetupCompanyTour && isOpen && currentStep === 3
					? " highlighted"
					: ""
		},
		interactiveContainerStyles:
			isActiveSetupCompanyTour && activeOverlay && currentStep === 3
				? INTERACTIVE_CONTAINER_STYLES
				: {}
	};
};

const InvoicingDetails = ({
	isLoadingUpdate,
	company,
	updateCompanyInfo,
	hasMultiSite,
	currentSite,
	setActiveTab,
	IsAdmin
}) => {
	const {
		selectors,
		closeTour,
		goNextTourStep,
		toasterDuration,
		selectorsClassNames,
		interactiveContainerStyles
	} = useTourData();

	const user = useSelector(onlineUserSelector);
	const isOwner =
		_get(user, "active_role") === ADMIN && !_get(user, "is_member", false);

	const [modeEdit, setModeEdit] = useState(false);
	const [errorsApi, setErrorsApi] = useState({});
	const { control, handleSubmit, reset, formState, watch } = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema)
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: "items"
	});

	const allField = watch();
	const isEmptyField = _get(allField, "items", []).some(item => !item.name);

	const saveData = data => {
		const payload = {
			tag: "invoicing_details",
			company_name: _get(data, "company_name", ""),
			legal_form: _get(data, "legal_form", ""),
			contact_name: _get(data, "contact_person", ""),
			emails: _get(data, "items", []).map(e => e.name),
			...(hasMultiSite && { site_id: _get(currentSite, "_id") })
		};
		updateCompanyInfo(hasMultiSite ? payload : _omit(payload, ["site_id"]), {
			onSuccess: () => {
				queryCache.invalidateQueries("myCurrentSite");
				queryCache.invalidateQueries("@getSites");
				setActiveTab(_get(company, "_id"));
				setModeEdit(!modeEdit);
				toast.success(`company details updated.`, {
					position: "bottom-right",
					duration: toasterDuration || 2500
				});
				setTimeout(goNextTourStep, 550);
			},
			onError: error => {
				toast(`Error while saving changes`, { isFailed: true });
				setErrorsApi(_get(error, "detail"));
				// setTimeout(goNextTourStep, 4050);
			}
		});
	};

	const onSubmit = data => {
		saveData(data);
	};
	const handleCancel = () => {
		reset({
			items: _get(company, "invoicing_details.emails", []).map(e => ({
				name: e
			})),
			company_name: _get(company, "invoicing_details.company_name", ""),
			legal_form: _get(company, "invoicing_details.legal_form", ""),
			contact_person: _get(company, "invoicing_details.contact_name", "")
		});
		setModeEdit(!modeEdit);
		setErrorsApi({});
	};
	useEffect(() => {
		setModeEdit(false);
		reset({
			items: _get(company, "invoicing_details.emails", []).map(e => ({
				name: e
			})),
			company_name: _get(company, "invoicing_details.company_name", ""),
			legal_form: _get(company, "invoicing_details.legal_form", ""),
			contact_person: _get(company, "invoicing_details.contact_name", "")
		});
	}, [company]);
	const { dirtyFields, errors } = formState;
	return (
		<>
			<div className={style.headerBlock}>
				<h3>Invoicing details</h3>
				{modeEdit ? (
					<div className={style.ctaModeEdit} style={interactiveContainerStyles}>
						<button
							className={style.cancelBtn}
							type="button"
							onClick={() => {
								handleCancel();
								goNextTourStep();
							}}
						>
							Cancel
						</button>
						<SaveButton
							onClick={handleSubmit(onSubmit)}
							isLoading={isLoadingUpdate}
							isDisabled={
								isLoadingUpdate || isEmpty(dirtyFields) || isEmptyField
							}
						/>
					</div>
				) : (
					IsAdmin && (
						<button
							type="button"
							id={selectors.four}
							onClick={() => {
								closeTour();
								setModeEdit(!modeEdit);
							}}
							disabled={isLoadingUpdate}
							className={selectorsClassNames.four}
						>
							<IconPen /> Edit
						</button>
					)
				)}
			</div>
			<div className={style.card} style={interactiveContainerStyles}>
				<form className={style.formFields} onSubmit={handleSubmit(onSubmit)}>
					<div className={style.field}>
						<div className={style.label}>Company legal name</div>
						<div className={style.infoLabel}>
							<Controller
								name="company_name"
								control={control}
								render={({ field: { onChange, value } }) => (
									<ToggleInput
										isOwner={isOwner}
										modeEdit={modeEdit}
										value={value}
										onChange={onChange}
									/>
								)}
							/>
							{(_get(errors, "company_name") ||
								_get(errorsApi, "company_name", []).length > 0) && (
								<span className={style.error}>
									{_get(
										errors,
										"company_name.message",
										_get(errorsApi, "company_name[0]")
									)}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Legal form</div>
						<div className={style.infoLabel}>
							<Controller
								name="legal_form"
								control={control}
								render={({ field: { onChange, value } }) => (
									<ToggleInput
										isOwner={isOwner}
										modeEdit={modeEdit}
										value={value}
										onChange={onChange}
									/>
								)}
							/>
							{_get(errors, "legal_form") && (
								<span className={style.error}>
									{_get(errors, "legal_form.message")}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Contact person</div>
						<div className={style.infoLabel}>
							<Controller
								name="contact_person"
								control={control}
								render={({ field: { onChange, value } }) => (
									<ToggleInput
										isOwner={isOwner}
										modeEdit={modeEdit}
										value={value}
										onChange={onChange}
									/>
								)}
							/>
							{_get(errors, "contact_person") && (
								<span className={style.error}>
									{_get(errors, "contact_person.message")}
								</span>
							)}
						</div>
					</div>
					<div className={style.field}>
						<div className={style.label}>Invoicing email</div>
						<div className={style.infoLabel}>
							<div className={style.inputsContainer}>
								{fields.map((item, index) => (
									<div key={item.id}>
										<Controller
											name={`items[${index}].name`}
											control={control}
											render={({ field: { onChange, value } }) => (
												<ToggleInput
													isOwner={isOwner}
													modeEdit={modeEdit}
													value={value}
													onChange={onChange}
													removeInput={() => {
														remove(index);
													}}
												/>
											)}
										/>
										{_get(errors, `items[${index}].name.message`) && (
											<span className={style.error}>
												{_get(errors, `items[${index}].name.message`)}
											</span>
										)}
									</div>
								))}
							</div>

							{modeEdit && fields.length < 2 && (
								<button
									className={style.addBtn}
									type="button"
									onClick={() => append({ name: "" })}
								>
									<IconLeft />
								</button>
							)}
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default InvoicingDetails;
