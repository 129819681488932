import React, { useState } from "react";
import { Text } from "rebass";
import Select from "react-select";
import _get from "lodash/get";
import { usePaginatedQuery } from "react-query";
import FileIcon from "static/icons/File.js";
import { dateCalendar } from "common/Functions";
import Pagination from "common/PaginationClient";
import Loader from "common/Loader";
import { VIEW_USER_FILES } from "config/api-endpoints";
import { client } from "lib/api-client";
import LapTopIcon from "static/icons/Laptop.js";
import emptyImg from "static/images/empty-image-state.png";
import emptyRecentFile from "static/images/NoRecentFiles.png";
import {
	ModalContainer,
	Card,
	Button,
	FromComputer,
	Fromfiles,
	EmptyImageContainer
} from "./style";

const SELECT_OPTIONS = [
	{
		label: "Oldest to newest",
		value: "asc"
	},
	{
		label: "Newest to oldest",
		value: "desc"
	}
];

const getMyFiles = (_key, { sort, search, offset }) => {
	return client(VIEW_USER_FILES, {
		body: {
			sort,
			search,
			offset,
			tag: "list_images"
		}
	});
};

const getRecentFiles = () => {
	return client(VIEW_USER_FILES, {
		body: {
			sort: "desc",
			offset: 0,
			tag: "list_images"
		}
	});
};

const renderFileName = (item, num) => {
	if (_get(item, "file_name.length") > num)
		return `${_get(item, "file_name").substr(0, num)}...`;
	return _get(item, "file_name");
};

export default function Tabs({
	activeTab,
	setActiveTab,
	setImageSrc,
	setFileName,
	getRootProps,
	getInputProps,
	imageSrc,
	isDragActive
}) {
	const [search, setSearch] = useState("");
	const [sort, setSort] = useState("desc");
	const [offset, setOffset] = useState(0);

	const { resolvedData, isLoading } = usePaginatedQuery(
		["user-files", { search, sort, offset }],
		getMyFiles
	);
	const { resolvedData: recent_files } = usePaginatedQuery(
		["recent-files"],
		getRecentFiles
	);

	const recentFile = recent_files ? recent_files["data"].slice(0, 4) : [];
	const MENU_DATA = [
		{
			icon: (
				<LapTopIcon
					className="icon"
					stroke={activeTab === 0 ? "white" : "#6E7191"}
				/>
			),
			label: "From computer",
			tab: 0
		},
		{
			icon: (
				<FileIcon
					className="icon"
					stroke={activeTab === 1 ? "white" : "#6E7191"}
				/>
			),
			label: "From wiggli files",
			tab: 1
		}
	];

	const handleTabClick = item => {
		setActiveTab(_get(item, "tab"));
	};

	const onCardClick = item => {
		setImageSrc(_get(item, "path"));
		setFileName(_get(item, "file_name"));
	};

	const handlePageClick = value =>
		setOffset(_get(resolvedData, "size") * value);

	const handleChange = ({ target }) => {
		setSearch(target.value);
		setOffset(0);
	};

	return (
		<ModalContainer>
			<div className="menu">
				{MENU_DATA.map((item, index) => (
					<Button
						key={index}
						active={item.tab === activeTab}
						onClick={() => handleTabClick(item)}
						className={`avatar-uploader__menu-tab${
							item.tab === activeTab ? "--active" : ""
						}`}
					>
						{_get(item, "icon")}
						<span className="button-content">{_get(item, "label")}</span>
					</Button>
				))}
				<FromComputer>
					<div className="divider"></div>
					<span className="title">Recent files</span>

					{recentFile.length > 0 ? (
						<div className="cards-container">
							{recentFile.map((item, index) => (
								<Card key={index} onClick={() => onCardClick(item)} recentFile>
									<img src={_get(item, "path")} />
									<div className="card-content">
										<span className="name">{renderFileName(item, 23)}</span>
										<span className="date">
											{dateCalendar(_get(item, "created_at"))}
										</span>
									</div>
								</Card>
							))}
						</div>
					) : (
						<img className="empty-recent-files" src={emptyRecentFile} />
					)}
				</FromComputer>
			</div>
			<div className="content">
				{activeTab === 0 && (
					<FromComputer>
						<EmptyImageContainer
							FromComputer
							{...getRootProps()}
							isDragActive={isDragActive}
						>
							<img className="empty-image" src={emptyImg} />
							<h5 className="drag-title">Drag a profile photo here</h5>
							{!isDragActive && (
								<button className="uploader-button">
									Select a photo from my computer
								</button>
							)}
							<input
								{...getInputProps()}
								style={
									imageSrc
										? {
												display: "none"
										  }
										: {
												width: "100%",
												height: "60%",
												position: "absolute",
												opacity: 0,
												top: 0
										  }
								}
							/>
						</EmptyImageContainer>
					</FromComputer>
				)}
				{activeTab === 1 && (
					<Fromfiles>
						<span className="title">Document library</span>
						<div className="search-box">
							<div className="input">
								<Text
									sx={{
										position: "absolute",
										color: "gray",
										marginRight: "13px"
									}}
									as={"span"}
									className={search ? "icon-close" : "icon-search"}
									onClick={() => (search ? setSearch("") : null)}
								></Text>
								<input
									placeholder="Search by document name…"
									value={search}
									onChange={handleChange}
								/>
							</div>
							<div className="select">
								<Select
									value={sort}
									options={SELECT_OPTIONS}
									clearable={false}
									onChange={selected => setSort(selected.value)}
								/>
							</div>
						</div>
						{isLoading ? (
							<Loader />
						) : _get(resolvedData, "total", 0) === 0 ? (
							<EmptyImageContainer Fromfiles>
								<img className="empty-image" src={emptyImg} />
							</EmptyImageContainer>
						) : (
							<>
								<div className="filesList">
									{_get(resolvedData, "data", []).map((item, index) => (
										<Card key={index} onClick={() => onCardClick(item)}>
											<img src={_get(item, "path")} />
											<div className="card-content">
												<span className="name">{renderFileName(item, 28)}</span>
												<span className="date">
													{dateCalendar(_get(item, "created_at"))}
												</span>
											</div>
										</Card>
									))}
								</div>
								{_get(resolvedData, "total", 0) > 10 && (
									<div className="pagination">
										<span className="text">
											showing {_get(resolvedData, "offset") + 1}-
											{_get(resolvedData, "offset") +
												_get(resolvedData, "data.length")}{" "}
											of {_get(resolvedData, "total")} item
										</span>
										<Pagination
											total={resolvedData.total}
											size={resolvedData.size}
											offset={resolvedData.offset}
											handlePageClick={handlePageClick}
											pageRangeDisplayed={1}
											marginPagesDisplayed={0}
										/>
									</div>
								)}
							</>
						)}
					</Fromfiles>
				)}
			</div>
		</ModalContainer>
	);
}
