/* TODO Move onPress to CustomInput */
/* eslint-disable react/display-name */
import React, { useState, memo, useMemo, forwardRef } from "react";
import CreateVacancyCard from "../CreateVacancyCard/CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyCardTitle } from "../CreateVacancyCardTitle";
import { CreateVacancyInfoIcon } from "../CreateVacancyInfoIcon";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import { DatePicker } from "common/DatePicker";
import { AddVacancyCustomInput } from "../AddVacancyCustomInput";
import styles from "./add-vacancy-additional-info-card.module.scss";
import { REMOTE_ELIGIBITY_OPTIONS, HYBRID_OPTION } from "config";
import { VACANCY_STEP_CARDS_IDS } from "modules/vacancy/create-vacancy/utils/constants";
import { Controller } from "react-hook-form";
import { CreateVacancyFieldError } from "../CreateVacancyFieldError";
import { JobAddressInput } from "../JobAddressInput";
import { JobAddressPickerModal } from "../JobAddressPickerModal";
import { VacancyAdditionalInfoCardUploadImage } from "../VacancyAdditionalInfoCardUploadImage";
import { today, getLocalTimeZone } from "@internationalized/date";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-icon-grey.svg";
import { RadioGroup } from "common/RadioGroup";
import { NUMBER_KEYS_CODES } from "../../utils/constants";
import useElements from "hooks/useLayoutElements";
import { v4 as uuid } from "uuid";
import { ComboBox } from "common/ComboBox";
import { Button } from "common/Button";
import { Item } from "react-stately";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import { VACANCY_OPENING_PATH } from "modules/CustomFieldsManager/utils/constants";
import { browserHistory } from "react-router";
import { getCustomFieldsManagerRoute } from "modules/CustomFieldsManager/getRouteConfig";

const AddVacancyAdditionalInfoCard = ({
	control,
	setValue,
	vacancyAddress,
	vacancyAddressType,
	errors,
	remoteEligibility,
	onImageSubmitStart,
	onImageSubmitFinish,
	openingReasonsData,
	vacancyToEdit,
	openingReason
}) => {
	const [displayAddressPicker, setDisplayAddressPicker] = useState(false);
	const { mainElement } = useElements();
	const onAddressEdit = () => setDisplayAddressPicker(state => !state);
	const [displayOpeningReasonButton, setDisplayOpeningReasonButton] = useState(
		true
	);
	const onJobAddressChange = ({ value, type }, onChange) => {
		onChange(value);
		setValue("vacancyAddressType", type);
		setDisplayAddressPicker(false);
	};

	const onAddressDialogCancel = () => {
		setDisplayAddressPicker(false);
	};

	const onKeyPress = e => {
		const pressedKey = e.which;

		if (!NUMBER_KEYS_CODES.slice(1, 6).includes(pressedKey)) {
			e.preventDefault();
		}
	};

	const onDaysPerWeekChange = (e, onChange) => {
		if ((e.target.value >= 1 && e.target.value <= 5) || e.target.value === "")
			onChange(e);
	};

	const formattedReasonsOptions = useMemo(() => {
		const optionExists = !!openingReasonsData?.options.find(option => {
			return option.value === vacancyToEdit?.opening_reason;
		});

		const list =
			optionExists || !vacancyToEdit?.opening_reason
				? openingReasonsData?.options
				: [
						...openingReasonsData?.options,
						{
							value: vacancyToEdit?.opening_reason,
							label: vacancyToEdit?.opening_reason,
							_id: uuid()
						}
				  ];

		return list;
	}, [openingReasonsData, vacancyToEdit?.opening_reason]);

	const onOpeningReasonInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : openingReason?.value
		};

		setDisplayOpeningReasonButton(true);

		onChange(valueObject);
	};
	const onOpeningReasonSelectionChange = (key, onChange) => {
		const value = {
			label: getOpeningReasonById(key)?.value ?? key,
			value: key
		};

		if (key) {
			setDisplayOpeningReasonButton(false);
		}

		onChange(value);
	};
	const getOpeningReasonById = id => {
		return formattedReasonsOptions.find(({ _id }) => _id === id);
	};

	return (
		<CreateVacancyCard id={VACANCY_STEP_CARDS_IDS.additionalInfo}>
			<CreateVacancyCardTitle>Additional info</CreateVacancyCardTitle>
			<CreateVacancyCardContent className={styles.cardContent}>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Due date</CreateVacancyFieldLabel>
						<CreateVacancyInfoIcon>
							The date by which you need this vacancy to be filled (not visible
							to applicants).
						</CreateVacancyInfoIcon>
					</CreateVacancyLabelContainer>
					<Controller
						name="dueDate"
						control={control}
						render={({ field }) => (
							<DateController portalContainer={mainElement} {...field} />
						)}
					/>
					<CreateVacancyFieldError error={errors.dueDate} />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>
							Remote eligibility
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<Controller
						name="remoteEligibility"
						control={control}
						render={({ field: { onChange, value } }) => (
							<RadioGroup
								className={styles.radioButtonContainer}
								options={REMOTE_ELIGIBITY_OPTIONS}
								value={value}
								onChange={e => {
									onChange(e.target.value);
								}}
							/>
						)}
					/>
					<CreateVacancyFieldError error={errors.remoteEligibility} />
				</CreateVacancyFieldContainer>
				{remoteEligibility === HYBRID_OPTION.value && (
					<CreateVacancyFieldContainer>
						<CreateVacancyLabelContainer>
							<CreateVacancyFieldLabel displayStar={false}>
								Days in the office
							</CreateVacancyFieldLabel>
						</CreateVacancyLabelContainer>
						<Controller
							name="daysPerWeek"
							control={control}
							render={({ field: { value, onChange } }) => (
								<div className={styles.daysPerWeekContainer}>
									<AddVacancyCustomInput
										className={styles.daysPerWeekInput}
										onKeyPress={onKeyPress}
										value={value}
										onChange={e => onDaysPerWeekChange(e, onChange)}
									/>
									<div className={styles.daysInOfficeLabel}>
										days in office/week
									</div>
								</div>
							)}
						/>
						<CreateVacancyFieldError error={errors.daysPerWeek} />
					</CreateVacancyFieldContainer>
				)}
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Location</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<Controller
						name="vacancyAddress"
						control={control}
						render={({ field: { ref, onChange } }) => (
							<>
								<JobAddressInput
									ref={ref}
									value={vacancyAddress?.label}
									onEdit={onAddressEdit}
									className={
										displayAddressPicker ? styles.jobAddressInputFocused : ""
									}
								/>
								{displayAddressPicker && (
									<JobAddressPickerModal
										onSave={value => onJobAddressChange(value, onChange)}
										value={vacancyAddress}
										addressType={vacancyAddressType}
										onCancel={onAddressDialogCancel}
									/>
								)}
							</>
						)}
					/>
					<CreateVacancyFieldError error={errors.vacancyAddress} />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Featured image
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<Controller
						name="featuredImage"
						control={control}
						render={({ field: { value, onChange } }) => (
							<VacancyAdditionalInfoCardUploadImage
								setFeaturedImageUrl={onChange}
								picture={value}
								onImageSubmitStart={onImageSubmitStart}
								onImageSubmitFinish={onImageSubmitFinish}
							/>
						)}
					/>
					<CreateVacancyFieldError error={errors.featuredImage} />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Featured video
						</CreateVacancyFieldLabel>
						<CreateVacancyInfoIcon>
							The featured video will appear in the job description. It helps
							the candidates to get a close vision of the job.
							<br />
							(ex. : Youtube, Vimeo, ...)
						</CreateVacancyInfoIcon>
					</CreateVacancyLabelContainer>
					<div className={styles.inputContainer}>
						<Controller
							name="featuredVideo"
							control={control}
							render={({ field }) => (
								<AddVacancyCustomInput placeholder="Video URL" {...field} />
							)}
						/>
					</div>
					<CreateVacancyFieldError error={errors.featuredVideo} />
				</CreateVacancyFieldContainer>

				<CreateVacancyFieldContainer>
					<CreateVacancyFieldLabel>Opening reason</CreateVacancyFieldLabel>
					<Controller
						name="openingReason"
						control={control}
						render={({ field: { value, onChange } }) => {
							return (
								<ComboBox
									inputValue={value?.label || ""}
									selectedKey={value?.value}
									displayButton={displayOpeningReasonButton}
									onInputChange={value => {
										onOpeningReasonInputChange(value, onChange);
									}}
									onSelectionChange={key => {
										onOpeningReasonSelectionChange(key, onChange);
									}}
									button={
										<Button
											text="Add new opening reason"
											variant="text"
											textClassName={styles.addNewDepartmentButtonText}
											className={styles.addDepartmentButton}
											icon={<AddIcon />}
											onClick={() =>
												browserHistory.replace(
													getCustomFieldsManagerRoute(VACANCY_OPENING_PATH)
												)
											}
										/>
									}
								>
									{formattedReasonsOptions?.map(({ _id, value }) => {
										return <Item key={_id}>{value}</Item>;
									})}
								</ComboBox>
							);
						}}
					/>
					<CreateVacancyFieldError error={errors.openingReason} />
				</CreateVacancyFieldContainer>
			</CreateVacancyCardContent>
		</CreateVacancyCard>
	);
};

export default AddVacancyAdditionalInfoCard;

const DateController = memo(
	forwardRef((props, ref) => {
		return (
			<DatePicker
				rootClassName={styles.datePickerRoot}
				minValue={today(getLocalTimeZone())}
				inputClassName={styles.datePickerInput}
				ref={ref}
				{...props}
				triggerIcon={<CalendarIcon />}
			/>
		);
	})
);
