import React, { useState } from "react";
import cx from "classnames";
import { ReactComponent as SearchParamIcon } from "static/icons/search-param-icon.svg";
import { ReactComponent as SearchIcon } from "static/icons/icon_search.svg";
import classnames from "./searchParams.module.scss";
import Params from "common/SearchParams/params";
import useInteractionsStore from "modules/candidate/Profile/Store/useInteractionsStore";

const SearchParams = ({
	searchKeyword,
	setSearchKeyword,
	historyEdit,
	...rest
}) => {
	const [openSearchParm, setOpenSearchParm] = useState(false);
	const [focus, setFocus] = useState(false);
	const { hideParams } = rest;

	const onClose = () => setOpenSearchParm(false);
	const { updateOffset } = useInteractionsStore();
	return (
		<div className={classnames.searchBox}>
			<div
				onBlur={() => setFocus(false)}
				onFocus={() => setFocus(true)}
				className={cx(classnames.searchInput, {
					[classnames.focus]: focus
				})}
			>
				<SearchIcon className={classnames.icon} />
				<input
					value={searchKeyword}
					onChange={({ target }) => {
						setSearchKeyword(target.value), updateOffset(0);
					}}
					placeholder="Search"
				/>
				{searchKeyword && (
					<button
						style={{ backgroundColor: "white", padding: "0px" }}
						className="icon-reset-3"
						onClick={() => setSearchKeyword("")}
					></button>
				)}
			</div>
			<div className={classnames.paramsWrapper}>
				{!hideParams && (
					<button
						className={classnames.searchParamsButton}
						onClick={e => {
							e.stopPropagation();
							setOpenSearchParm(true);
						}}
					>
						<SearchParamIcon />
					</button>
				)}
				{openSearchParm && !hideParams && (
					<Params
						{...{ ...rest }}
						onClose={onClose}
						historyEdit={historyEdit}
					/>
				)}
			</div>
		</div>
	);
};
export default SearchParams;
