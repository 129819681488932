import React from "react";
import CustomScroll from "react-custom-scroll";
import EmptyCV from "modules/cv/components/Tabs/EmptyCV";
import CvContent from "modules/candidate/components/profileClientView/CvViewer";

const ResumeTab = ({ pdf_doc }) => {
	return (
		<>
			<CustomScroll allowOuterScroll={true}>
				<div className="display-cv">
					{!pdf_doc ? (
						<EmptyCV loading isClientView title={"Loading CV ..."} />
					) : (
						<CvContent isHeadline={false} pdf_doc={pdf_doc} />
					)}
				</div>
			</CustomScroll>
		</>
	);
};

export default ResumeTab;
