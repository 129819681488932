import React from "react";
import styles from "./request-pipeline-card-head.module.scss";
import { CustomAvatar } from "common/CustomAvatar";
import { BidMatchScore } from "modules/RequestView/components/BidDetailsDrawer/components/BidMatchScore";
import { CONSULTANCY } from "config";

const RequestPipelineCardHead = ({ name, avatar, matchingScore, type }) => {
	return (
		<div className={styles.container}>
			<div className={styles.avatar}>
				{avatar ? (
					<img src={avatar} alt={name} />
				) : (
					<CustomAvatar avatarClassName={styles.customAvatar} fullName={name} />
				)}
			</div>
			<div className={styles.nameContainer}>
				<div className={styles.fullName}>{name}</div>
			</div>

			<div className={styles.flex}>
				{type === CONSULTANCY ? (
					<div className={styles.role}>Consultancy</div>
				) : (
					<div className={styles.role}>Freelancer</div>
				)}
				<BidMatchScore value={matchingScore} />
			</div>
		</div>
	);
};

export default RequestPipelineCardHead;
