import React, { useState, useEffect } from "react";
import BodyClassName from "react-body-classname";
import s from "./publish-vacancy.module.scss";
import ChannelsFilterCard from "./components/ChannelsFilterCard";
import ChannelsList from "./components/ChannelsList";
import PublishVacancyConfirmDrawer from "./components/PublishVacancyConfirmDrawer";
import usePostingRequirementStore from "./store/usePostingRequirementStore";
import useRowSelection from "hooks/Selection";
import useConfirmationStore from "./store/useConfirmationStore";
import { goBack } from "config/helpers";
import { searchParser } from "config/helpers";
import useSelectChannels from "./hooks/useSelectChannels.js";
import useFiltersStore from "./store/useFiltersStore";
import useSelectedFreeChannels from "./hooks/useSelectedFreeChannels";
import useSetPublishVacancyBreadCrumbs from "./hooks/useSetPublishVacancyBreadCrumbs";
import { usePublishVacancyTour } from "modules/user-onboarding/workflows/publish-vacancy";
import { HOW_TO_PUBLISH_VACANCY } from "config/onboarding";
import { ReactComponent as AlertTriangle } from "static/icons/alert-triangle-sm.svg";
import { useGetVacancy } from "modules/vacancy/create-vacancy/api/useGetVacancy";
import useEditJobLocation from "modules/publishJob/api/useEditJobLocation";
import { queryCache } from "react-query";
import { GET_VACANCY_QUERY_KEY } from "modules/CreateRequestModule/api/useGetVacancy";
import loadable from "loadable-components";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const PublishVacancyAlertModal = loadable(() =>
	import("./components/PublishVacancyAlertModal/PublishVacancyAlertModal")
);

const PublishJobAddressPickerModal = loadable(() =>
	import(
		"./components/PublishJobAddressPickerModal/PublishJobAddressPickerModal"
	)
);

const PublishVacancy = ({ params: { id } }) => {
	const [address, setAddress] = useState({});
	const { workflowId } = useOnboardingStore();
	const { data: vacancyToEdit } = useGetVacancy(
		{
			id,
			job_type: "vacancy",
			channels_info: true
		},
		{
			enabled: !!id && !workflowId,
			onSuccess: value => {
				const vacancyAddress = getVacancyAddress(value);
				setAddress(vacancyAddress);
			}
		}
	);

	const getVacancyAddress = vacancyToEdit => {
		const {
			street,
			number,
			box,
			city,
			zip,
			country,
			latitude,
			iso_country,
			longitude
		} = vacancyToEdit;

		return {
			label: "",
			value: {
				street,
				number,
				box,
				city,
				zip,
				country,
				latitude,
				iso_country,
				longitude
			}
		};
	};

	const [displayAddressPicker, setDisplayAddressPicker] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [contractLmit, selContractLimit] = useState(6);
	const [isRecommendation, setIsRecommendation] = useState(false);
	useSetPublishVacancyBreadCrumbs(id);

	const { published } = searchParser();

	const {
		setvalue,
		reset: clearPremiumChannelsList,
		selectedChannels
	} = useConfirmationStore();

	const { setvalue: setFilters } = useFiltersStore();
	const {
		freeChannels,
		clearSelectedContractsList
	} = usePostingRequirementStore();

	const [
		state,
		{ toggleAddPage, toggleRemovePage, toggleAddChannel, isChannelSelected }
	] = useSelectChannels();

	const [
		selectedFreeChannelsState,
		{
			toggleAddPage: toggleAddFreePage,
			toggleRemovePage: toggleRemoveFreePage,
			toggleAddChannel: toggleAddFreeChannel,
			isChannelSelected: isFreeChannelSelected
		}
	] = useSelectedFreeChannels();

	const [
		contractSelectionState,
		{
			toggleRowSelection: selectContract,
			toggleAllSelection: selectPage,
			checkRowSelection: isContractSelected
		}
	] = useRowSelection({
		totalItemsCount: -1
	});

	useEffect(() => {
		return () => {
			clearPremiumChannelsList();
			clearSelectedContractsList();
		};
	}, []);

	const contractSelectionBucket = {
		state: contractSelectionState,
		actions: {
			row: selectContract,
			all: selectPage
		},
		helpers: {
			isContractSelected
		}
	};

	const channelSelectionBucket = {
		state,
		actions: {
			row: toggleAddChannel,
			all: toggleAddPage,
			remove: toggleRemovePage
		},
		helpers: {
			isChannelSelected
		}
	};

	const freeChannelSelectionBucket = {
		state: selectedFreeChannelsState,
		actions: {
			row: toggleAddFreeChannel,
			all: toggleAddFreePage,
			remove: toggleRemoveFreePage
		},
		helpers: {
			isFreeChannelSelected
		}
	};
	const publishDisabled =
		!Boolean(contractSelectionBucket.state.selectedRows.length) &&
		!Boolean(channelSelectionBucket.state.selectedRows.length) &&
		!Boolean(freeChannelSelectionBucket.state.selectedRows.length) &&
		Object.values(freeChannels).every(item => !Boolean(item));

	usePublishVacancyTour({
		openPublishModal: () => setvalue("open", true)
	});

	const isInfoMissed = !(
		vacancyToEdit?.city &&
		vacancyToEdit?.street &&
		vacancyToEdit?.zip &&
		vacancyToEdit?.country
	);

	const showAlertButton =
		(selectedChannels.length > 1 ||
			contractSelectionState.selectedRows.length > 0) &&
		isInfoMissed;

	const onAddressDialogCancel = () => {
		setDisplayAddressPicker(false);
		setOpenModal(false);
	};

	const [editJobLocation] = useEditJobLocation({
		onSuccess: () => {
			queryCache.invalidateQueries(GET_VACANCY_QUERY_KEY);
			setDisplayAddressPicker(false);
		}
	});

	const onJobAddressChange = ({ value }) => {
		let payload = value.value;
		payload.id = vacancyToEdit?.id;
		payload.location_type = vacancyToEdit?.location_type;

		editJobLocation(payload);
	};

	return (
		<BodyClassName className="publish-job">
			<div>
				<div className={s.actionsContainer}>
					<div>
						<button className={s.backButton} onClick={goBack}>
							Back
						</button>
					</div>
					<div className={s.actionsWrapper}>
						<button
							className={s.backButton}
							onClick={() => {
								setFilters("display", "recommendations_only");
								setIsRecommendation(true);
							}}
						>
							Recommended
						</button>
						{publishDisabled && (
							<button
								className={s.publishDisableButton}
								disabled={true}
								data-onboarding-step={`${HOW_TO_PUBLISH_VACANCY}-6`}
							>
								{published === "true" ? "Republish" : "Publish"}
							</button>
						)}
						{!publishDisabled && !showAlertButton && (
							<button
								className={s.publishButton}
								onClick={() => setvalue("open", true)}
								data-onboarding-step={`${HOW_TO_PUBLISH_VACANCY}-6`}
							>
								{published === "true" ? "Republish" : "Publish"}
							</button>
						)}
						{!publishDisabled && showAlertButton && (
							<button
								className={s.publishEnableButton}
								onClick={() => setOpenModal(true)}
								data-onboarding-step={`${HOW_TO_PUBLISH_VACANCY}-6`}
							>
								<AlertTriangle />
								<span className={s.buttonText}>
									{published === "true" ? "Republish" : "Publish"}
								</span>
							</button>
						)}
					</div>
				</div>
				<div className={s.gridLayout}>
					<div className={s.filters}>
						<ChannelsFilterCard
							setIsRecommendation={setIsRecommendation}
							isRecommendation={isRecommendation}
							jobId={id}
						/>
					</div>
					<div className={s.results}>
						<ChannelsList
							isInfoMissed={isInfoMissed}
							channelSelectionBucket={channelSelectionBucket}
							contractSelectionBucket={contractSelectionBucket}
							contractLmit={contractLmit}
							selContractLimit={selContractLimit}
							freeChannelSelectionBucket={freeChannelSelectionBucket}
						/>
					</div>
				</div>
				<PublishVacancyConfirmDrawer
					channelSelectionBucket={channelSelectionBucket}
					contractSelectionBucket={contractSelectionBucket}
					id={id}
					isPublished={published === "true"}
					freeChannelSelectionBucket={freeChannelSelectionBucket}
				/>
				{openModal && (
					<PublishVacancyAlertModal
						onClose={() => setOpenModal(false)}
						onClick={() => {
							setDisplayAddressPicker(true);
							setOpenModal(false);
						}}
						title="Vacancy Location"
						body={
							"To publish a vacancy on paid channels, please make sure you provide complete location information for the vacancy  (Street, Zip, City, Country)."
						}
						confirmButtonText="Edit Location"
						textCancelButton="Cancel"
					/>
				)}
				{displayAddressPicker && (
					<PublishJobAddressPickerModal
						onSave={value => onJobAddressChange(value)}
						value={address}
						addressType={vacancyToEdit?.location_type}
						onCancel={onAddressDialogCancel}
					/>
				)}
			</div>
		</BodyClassName>
	);
};

export default PublishVacancy;

export const getPublishJobRoute = () => `publish-vacancy/:id`;

export const getPublishJobPermission = () => ({
	type: [
		{
			name: "client",
			roles: ["admin", "project_manager"]
		}
	]
});
