import styled from "styled-components";
//import { colors } from "config/styles";

export const CategoryContainer = styled.div`
	padding: 5px 0px;
	margin-top: -10px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	.category-name {
		font-size: 14px;
		color: #b8babc;
		font-weight: 500;
		line-height: 2.23;
		text-align: left;
		//text-transform: uppercase;
	}
`;
