import "rc-progress/assets/index.css";
import React, { Component } from "react";
import { Line } from "rc-progress";
import PropTypes from "prop-types";

class LineProgress extends Component {
	constructor(props) {
		super(props);
		this.state = {
			percent: 0
		};
	}

	componentDidMount() {
		this.increase();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.percent !== this.props.percent) {
			this.setState({ percent: 0 });
			this.increase();
		}
	}

	increase = () => {
		const percent = this.state.percent + 1;
		if (percent > this.props.percent) {
			clearTimeout(this.tm);
			return;
		}
		this.setState({ percent });
		this.tm = setTimeout(this.increase, 10);
	};

	render() {
		const { percent } = this.state;
		const {
			width,
			strokeWidth,
			trailWidth,
			trailColor,
			strokeColor,
			percentStyle = false
		} = this.props;
		return (
			<div>
				<span
					className={percentStyle ? "bid_details" : undefined}
				>{`${percent}%`}</span>
				<div style={{ width }}>
					<Line
						trailWidth={trailWidth}
						strokeWidth={strokeWidth}
						percent={percent}
						strokeColor={strokeColor}
						trailColor={trailColor}
					/>
				</div>
			</div>
		);
	}
}

LineProgress.propTypes = {
	percent: PropTypes.number.isRequired,
	width: PropTypes.string,
	displayPercent: PropTypes.bool,
	strokeWidth: PropTypes.number,
	trailWidth: PropTypes.number,
	strokeColor: PropTypes.string,
	trailColor: PropTypes.string
};

LineProgress.defaultProps = {
	width: "100%",
	displayPercent: true,
	strokeWidth: 4,
	trailWidth: 4,
	strokeColor: "#ff6849",
	trailColor: "#eff4f6",
	percent: 0
};

export default LineProgress;
