import React, { Component } from "react";
import PropTypes from "prop-types";
import { Sticky } from "react-sticky";

class FloatingSideBar extends Component {
	render() {
		const topOffset = 80;
		const { children, bottomOffset } = this.props;
		return (
			<Sticky topOffset={-topOffset} bottomOffset={bottomOffset}>
				{({ isSticky, style }) => {
					return (
						<div className="sidebar" style={style}>
							{isSticky && <div style={{ height: topOffset }} />}
							{children}
						</div>
					);
				}}
			</Sticky>
		);
	}
}

FloatingSideBar.propTypes = {
	style: PropTypes.object,
	isSticky: PropTypes.bool,
	bottomOffset: PropTypes.number
};

FloatingSideBar.defaultProps = {
	bottomOffset: 222
};

export default FloatingSideBar;
