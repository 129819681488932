import React, { useEffect } from "react";
import { connect } from "react-redux";
import _get from "lodash/get";
import { browserHistory } from "react-router";
import BodyClassName from "react-body-classname";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import LoginForm from "modules/user/components/LoginForm";
import { PERMANENT } from "config";
import toaster from "common/Toaster";

export const getPermanentUnsubscribeRoute = id =>
	`/unsubscribe/${id ? id : `:id`}`;

const Unsubscribe = ({ params, user }) => {
	useEffect(() => {
		if (_get(user, "access_token") && _get(user, "type") !== PERMANENT) {
			toaster.danger("You don't have enough permissions to access this page");
			browserHistory.push({
				pathname: "/"
			});
		} else if (_get(user, "access_token")) {
			browserHistory.push(`/my-cv?unsubscribe=${params.id}`);
		}
	}, [user]);

	const isOnline = _get(user, "access_token");

	return (
		<>
			{!isOnline && (
				<BodyClassName className="sign-in fluid-container">
					<div className="center-box body-full" id="sign-in">
						<div className="card-holder">
							<div className="forms-box">
								<h2>
									<span className="underlined">Log</span>in
								</h2>
								<div>
									<LoginForm shouldRedirect={false} />
								</div>
							</div>
						</div>
					</div>
				</BodyClassName>
			)}
		</>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps)(Unsubscribe);
