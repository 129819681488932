import styled from "styled-components";

export const ExpiredWrapper = styled.div`
	margin-top: 80px;
	display: flex;
	display: flex;
	justify-content: center;
	text-align: center;
	line-height: 4;
	.vacancy-not-found {
		transform: inherit;
		h1 {
			color: #51585e;
		}
		p {
			color: #51585e;
		}
		a {
			color: #ff6849;
			font-weight: 400;
		}
		svg {
			width: 150px;
			height: 150px;
		}
	}
`;
