import React from "react";
import styles from "./missions-list-total-count.module.scss";

const MissionListTotalCount = ({
	selectCount,
	isLoading,
	isFetching,
	total
}) => {
	return (
		<p className={styles.results_count}>
			{selectCount > 0 && (
				<>
					<span className={styles.greenText}>
						{selectCount.toLocaleString().replace(",", " ")}
					</span>
					&nbsp;selected of&nbsp;
				</>
			)}
			{isLoading || isFetching ? (
				"Loading..."
			) : (
				<>
					{total === 0 ? (
						"No result found"
					) : (
						<>
							<span className={styles.greenText}>
								{total?.toLocaleString()?.replace(",", " ")}
							</span>
							&nbsp;
							{total > 1 ? "results" : "result"} in total
						</>
					)}
				</>
			)}
		</p>
	);
};

export default MissionListTotalCount;
