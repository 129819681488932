import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_LANGUAGES } from "config/api-endpoints";

const getLanguages = () => client(GET_LANGUAGES, { method: "post" });

const useGetLanguages = (options, queryOptions = {}) =>
	useQuery(["@profile/getLanguages", options], getLanguages, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useGetLanguages;
