import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { useEffect } from "react";
import {
	NOTES_FILTER_ID,
	COMPONENT_BY_OPERATOR,
	OPERATORS_BY_TYPE,
	SOURCES,
	OPTION_TYPES,
	LIMIT
} from "../util/constants";
import { OPERATORS } from "common/FilterDrawer/utils";
import { FILTER_SETTING_ENDPOINT } from "../../../../../../config/api-endpoints";
import { client } from "lib/api-client";
import { produce } from "immer";
import useGetLastViewedCandidate from "./useGetLastViewedCandidate";

const getFieldValues = ({ tag, id, search }) => {
	return client(FILTER_SETTING_ENDPOINT, {
		body: {
			list: "notes",
			tag,
			id,
			search
		}
	});
};

export const useInitializeFilterDrawer = profileId => {
	const {
		setFilterSetting,
		setFilterColumns,
		getState,
		setFilters
	} = useFilters();
	const { filters } = getState(NOTES_FILTER_ID);
	const {
		candidateId: lastViewCandidate,
		setCandidateId
	} = useGetLastViewedCandidate();

	useEffect(
		() =>
			setFilterSetting(
				OPTION_TYPES,
				OPERATORS_BY_TYPE,
				COMPONENT_BY_OPERATOR,
				SOURCES,
				null,
				[],
				NOTES_FILTER_ID,
				LIMIT
			),
		[setFilterSetting]
	);

	useEffect(() => {
		const columns = [
			{
				label: "Created on",
				name: "created_at",
				type: OPTION_TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "Type",
				name: "note_type",
				type: OPTION_TYPES.multipleSelect3,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "note_type", id: profileId })
								.then(res => {
									const dataFormatted = res.map(({ value, label }) => {
										return {
											label,
											id: value
										};
									});

									const data = [
										{ id: 1, label: "Type", children: dataFormatted }
									];

									resolve(data);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Types remained",
					secondaryHeaderContent: () => "Types selected",
					useServerFilter: false
				}
			},
			{
				label: "Owner",
				name: "created_by",
				type: OPTION_TYPES.multipleSelect3,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "created_by", id: profileId })
								.then(res => {
									const dataFormatted = res.map(
										({ first_name, last_name, _id }) => {
											return {
												label: `${first_name} ${last_name}`,
												id: _id
											};
										}
									);

									const data = [
										{ id: 1, label: "Owner", children: dataFormatted }
									];

									resolve(data);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Owners remained",
					secondaryHeaderContent: () => "Owners selected",
					useServerFilter: false
				}
			},
			{
				label: "Vacancy",
				name: "vacancy",
				type: OPTION_TYPES.multipleSelect2,
				operator: OPERATORS.is,
				payload: {
					queryFn: (_, { search }) => {
						return new Promise((resolve, reject) => {
							getFieldValues({ tag: "vacancy", search, id: profileId })
								.then(res => {
									const dataFormatted = res.map(({ title, _id }) => {
										return {
											label: title,
											id: _id
										};
									});

									const data = [
										{ id: 1, label: "Vacancies", children: dataFormatted }
									];

									resolve(data);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Vacancies remained",
					secondaryHeaderContent: () => "Vacancies selected",
					useServerFilter: true
				}
			}
		];

		updateQueryFn(columns);
		setFilterColumns(columns);
		setCandidateId(profileId);
	}, [profileId]);

	function updateQueryFn(columns) {
		const newFilters = filters.map(filter => {
			const filterName = filter.name;

			if (!filterName) return filter;

			const index = columns.findIndex(({ name }) => name === filterName);

			const newFilter = produce(filter, draft => {
				if (!!columns[index].payload) {
					draft.payload.queryFn = columns[index].payload.queryFn;
				}
				if (lastViewCandidate !== profileId) {
					draft.value = "";
				}
			});

			return newFilter;
		});

		setFilters(newFilters);
	}
};
