import React from "react";
import BodyClassName from "react-body-classname";
import _get from "lodash/get";

import { Helmet } from "react-helmet";
import Select from "react-select";
import { Box } from "rebass/styled-components";
import { useDispatch } from "react-redux";
import SettingsSwitcher from "./SettingsSwitcher";
import CompanyDashboard from "common/dashboard/Dashboard";
import { PERMANENT, EMAILS_PERIOD_FREE, EMAILS_PERIOD_PERM } from "config";
import { getMyProfile } from "modules/user/actions/authActions";
import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { NOTIFICATIONS_SETTINGS } from "config/api-endpoints";
import Breadcumb from "modules/company/components/BreadcumbSettingPages";
import styles from "./style.module.scss";
import { LoaderFull } from "common/Loader";
import cx from "classnames";

const NotificationsSettings = ({ user, route }) => {
	const dispatch = useDispatch();

	const allEmails = _get(user, "receive_all_emails", false);
	const realTimeNotifications = _get(
		user,
		"show_notification_in_real_time",
		false
	);
	const matchingJobsAlerts = _get(user, "receive_matching_jobs_emails", false);
	const alertsPeriod = _get(
		user,
		"receive_matching_jobs_emails_period",
		undefined
	);
	const updateNotificationsSettings = ({ name, value }) =>
		client(NOTIFICATIONS_SETTINGS, {
			body: {
				receive_all_emails: allEmails,
				show_notification_in_real_time: realTimeNotifications,
				receive_matching_jobs_emails: matchingJobsAlerts,
				receive_matching_jobs_emails_period: alertsPeriod,
				[name]: value
			}
		});
	const [updateNotifications, { isLoading }] = useMutation(
		updateNotificationsSettings,
		{
			onSuccess: () => dispatch(getMyProfile())
		}
	);

	const getOptionDuration = () => {
		const isPermanent = user.type === PERMANENT;
		return isPermanent ? EMAILS_PERIOD_PERM : EMAILS_PERIOD_FREE;
	};

	const isPermanent = user.type === PERMANENT;
	const isClient = user.company_type === "client";
	const labelMatching = isPermanent
		? "Receive matching vacancies via email"
		: "Receive matching requests via email";

	const onSwitch = e => {
		updateNotifications({
			name: e.target.name,
			value: e.target.checked
		});
	};

	return (
		<>
			<Helmet>
				<title>{route.title}</title>
			</Helmet>
			<BodyClassName>
				<>
					{!isClient && (
						<>
							<CompanyDashboard />
							<Breadcumb
								parentItem="Settings"
								currentItem="Notifications"
								pathCurrentItem="/settings/notifications"
							/>
						</>
					)}
					<Box p={20}>
						<div
							className={cx(
								styles.settings_container,
								!isClient && "container",
								!isClient && styles.not_client_container
							)}
						>
							{isLoading && <LoaderFull check={false} />}
							<div className={styles.header}>
								<div className={styles.text}>Notifications Settings</div>
							</div>
							<Box p={20}>
								<div className={styles.intro}>
									Any notifications you choose to receive by email relate to
									wiggli’s services and activity on our platform. You can change
									your notification preferences at any time by using the
									notification settings below.
								</div>
								<div>
									<SettingsSwitcher
										label={"Receive all notifications via e-mail"}
										checked={_get(user, "receive_all_emails", false)}
										onChange={onSwitch}
										name={"receive_all_emails"}
									/>
									<SettingsSwitcher
										label={"Show notifications in real time"}
										checked={_get(
											user,
											"show_notification_in_real_time",
											false
										)}
										onChange={onSwitch}
										name={"show_notification_in_real_time"}
									/>
									{isClient && (
										<SettingsSwitcher
											label="Show proactive applications notifications"
											checked={_get(
												user,
												"show_proactive_applications_notifications"
											)}
											onChange={onSwitch}
											name="show_proactive_applications_notifications"
										/>
									)}
								</div>
								{!isClient && (
									<div>
										<div className={styles.title}>Job Alert</div>
										<div className="notification-center">
											<div className="notification-center-type--matching-email">
												<SettingsSwitcher
													label={labelMatching}
													checked={_get(
														user,
														"receive_matching_jobs_emails",
														false
													)}
													onChange={onSwitch}
													name={"receive_matching_jobs_emails"}
												/>
												<div className="notification-select">
													<Select
														placeholder="Choose duration"
														position={false}
														searchable={false}
														clearable={false}
														grouped={true}
														className="duration-filter"
														options={getOptionDuration()}
														onChange={e =>
															updateNotifications({
																name: "receive_matching_jobs_emails_period",
																value: e.value
															})
														}
														value={_get(
															user,
															"receive_matching_jobs_emails_period",
															undefined
														)}
														disabled={!matchingJobsAlerts}
													/>
												</div>
											</div>
										</div>
									</div>
								)}
							</Box>
						</div>
					</Box>
				</>
			</BodyClassName>
		</>
	);
};

export default NotificationsSettings;
