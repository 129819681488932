import _get from "lodash/get";

import { GET_ALL_FEATURES_URL } from "../api-endpoints";
import AxiosHelper from "../axios-helper";
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS";
export const GET_FEATURES_ERROR = "GET_FEATURES_ERROR";
export const AVAILABLE_FEATURES = "available_features";

const createFeatures = result => {
	const features = {};
	_get(result, "connected", []).map(feature => {
		features[feature.feature.slice(8, feature.feature.lentgh)] = {
			active: feature.status
		};
		return features[feature.feature.slice(8, feature.feature.lentgh)];
	});
	_get(result, "guest", []).map(feature => {
		features[feature.feature.slice(8, feature.feature.lentgh)] = {
			active: feature.status
		};
		return features[feature.feature.slice(8, feature.feature.lentgh)];
	});

	return features;
};

export const getFeatures = () =>
	AxiosHelper.__get({
		url: GET_ALL_FEATURES_URL,
		REQUEST_SUCCESS: GET_FEATURES_SUCCESS,
		REQUEST_FAIL: GET_FEATURES_ERROR,
		manageByDefault: false,
		isLogin: true,
		next: res => {
			localStorage.setItem(
				AVAILABLE_FEATURES,
				JSON.stringify({
					...createFeatures(res),
					timestamp: window.moment()
				})
			);
			document.location.reload(true);
			return Promise.resolve();
		}
	});
