import { VictoryPie, VictoryLabel, VictoryTooltip } from "victory";
import React, { useMemo, useEffect, useState } from "react";
import _get from "lodash/get";
import _reduce from "lodash/reduce";
import _findIndex from "lodash/findIndex";
import { BlocHolder, CenterBloc } from "./styled";
import ChartDetails from "./components/chartDetails";
import Loader from "common/Loader";
import { toEuro } from "common/Functions";

class CustomLabel extends React.Component {
	static defaultEvents = VictoryTooltip.defaultEvents;
	render() {
		const { permanent, datum } = this.props;
		const { status, count } = datum;

		return (
			<g>
				<VictoryLabel
					{...this.props}
					style={{ fontSize: 16, fill: "white", fontWeight: 600 }}
				/>
				<VictoryTooltip
					constrainToVisibleArea
					pointerLength={0}
					{...this.props}
					dx={permanent ? 80 : 60}
					dy={permanent ? 0 : 30}
					text={
						permanent ? `${status} - ${count}` : `${status} - ${toEuro(count)}€`
					}
					style={[
						{
							padding: 15,
							fontSize: 16,
							fill: "#fff"
						},
						{
							fontSize: 12,
							fill: "#fe6455"
						}
					]}
					flyoutStyle={{
						fill: "#394047",
						strokeWidth: 0
					}}
				/>
			</g>
		);
	}
}

const fixedColors = [
	"#f7b422",
	"#dd6b20",
	"#0099fa",
	"#56bd5b",
	"#fe6455",
	"#7C8EA4",
	"#5a67d8"
];

const App = props => {
	const data = _get(props, "chartData.data", []);
	const chartTitle = _get(props, "chartTitle");
	const chartType = _get(props, "chartType");
	const isFetching = _get(props, "isFetching");
	const totalCost = _get(props, "chartData.total_cost", null);
	const total = _get(props, "chartData.total", null);
	const [angle, setAngle] = useState(0);

	useEffect(() => {
		setAngle(0);
		const chartAnimation = setTimeout(() => {
			setAngle(360);
		}, 500);
		return () => clearTimeout(chartAnimation);
	}, []);
	let chartData = useMemo(() => {
		return _reduce(
			data,
			(acc, elm) => {
				const index = _findIndex(acc, i => i.status === elm.name);
				if (index !== -1) {
					acc[index].y += elm.percentage;
					acc[index].x = `${acc[index].y.toFixed(1)}%`;
					acc[index].count += elm.count;
				} else {
					acc.push({
						x: `${elm.percentage.toFixed(1)}%`,
						y: elm.percentage,
						status: elm.name || elm.status,
						count: elm.count
					});
				}
				return acc;
			},
			[]
		);
	}, [data]);

	const chartColors = {
		Shortlisted: "#f7b422",
		Hired: "#0099fa",
		Applied: "#3c78d8",
		"Application Cancelled": "#d2d7db",
		"Application Rejected": "#f75c5f"
	};

	let mappedColors = data.map(elm => {
		return chartColors[elm.name];
	});

	// remove dups
	mappedColors = [...new Set(mappedColors)];

	const isPermanent = chartType === "permanent";
	return (
		<BlocHolder permanent={isPermanent}>
			<div className="chart-title">
				{chartTitle} {isFetching && <Loader />}{" "}
			</div>
			<CenterBloc permanent={isPermanent}>
				<div style={{ position: "relative" }}>
					{!isFetching && (
						<div className="hourly-cost">
							{isPermanent ? (
								<span>{total}</span>
							) : (
								<>
									<span>{toEuro(totalCost) || null}</span>
									<span>€/Hr</span>
								</>
							)}
						</div>
					)}
					<VictoryPie
						colorScale={isPermanent ? mappedColors : fixedColors}
						innerRadius={75}
						labelRadius={100}
						labels={({ datum }) => datum.x}
						labelComponent={<CustomLabel permanent={isPermanent} />}
						data={chartData}
						labelPosition="centroid"
						animate={{ duration: 250 }}
						endAngle={angle}
					/>
				</div>

				<ChartDetails
					colors={isPermanent ? mappedColors : fixedColors}
					data={chartData}
					permanent={isPermanent}
				/>
			</CenterBloc>
		</BlocHolder>
	);
};
export default App;
