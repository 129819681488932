import React from "react";
import styles from "./add-timesheet-info-icon.scss";
import Tooltip from "common/Tippy";
import { ReactComponent as InfoIcon } from "static/icons/info-icon-black-light.svg";

const AddTimesheetInfoIcon = ({ children }) => {
	const content = (
		<div className={styles.notificationContainer}>{children}</div>
	);

	return (
		<Tooltip content={content} theme="dark">
			<InfoIcon className={styles.infoIcon} />
		</Tooltip>
	);
};

export default AddTimesheetInfoIcon;
