/* eslint-disable react/display-name */
import React from "react";
import { StarToggle } from "common/StarToggle";
import { Item } from "react-stately";
import styles from "./functions-picker-modal-function-row.module.scss";
import { ReactComponent as DeleteIconGrey } from "static/icons/delete-button-black.svg";
import { ComboBox } from "common/ComboBox";
import { useGetFunctionsList } from "api/useGetFunctionsList";
import { CategorySelect } from "../../CategorySelect";

const FunctionsPickerModalFunctionRow = ({
	handleStarToggleClick,
	categoriesListFormatted,
	onFunctionChange,
	handleDeleteFunctionClick,
	onSeniorityChange,
	onSubCategoryChange,
	onCategoryChange: onCategoryChangeProp,
	functionItem: { id, isMain, category, subCategory, _function, seniority },
	senioritiesList,
	displayDelete
}) => {
	const { data: functionsList } = useGetFunctionsList(
		{ no_limit: true, sector_id: subCategory?.value },
		{
			enabled: !!subCategory?.value
		}
	);

	const getSeniorityById = id => {
		return senioritiesList.find(({ value }) => value === id);
	};

	const onCategoryChange = value => {
		onCategoryChangeProp(value);
	};

	const onSenioritySelectionChange = key => {
		const seniorityObject = getSeniorityById(key);

		const value = {
			label: seniorityObject?.label,
			value: key
		};

		onSeniorityChange(value);
	};

	const onSeniorityInputChange = value => {
		const valueObject = {
			label: value,
			value: value === "" ? null : seniority?.value
		};

		onSeniorityChange(valueObject);
	};

	const getFunctionById = id => {
		return functionsList?.find(({ _id }) => _id === id);
	};

	const onFunctionSelectionChange = key => {
		const functionObject = getFunctionById(key);

		const value = {
			label: functionObject?.name,
			value: key
		};

		onFunctionChange(value);
	};

	const onFunctionInputChange = value => {
		const valueObject = {
			label: value,
			value: value === "" ? null : _function.value
		};

		onFunctionChange(valueObject);
	};

	const getSubCategoryById = key => {
		return (category?.children || category?.subs)?.find(
			({ _id }) => _id === key
		);
	};

	const onSubCategorySelectionChange = key => {
		const _object = getSubCategoryById(key);
		const value = {
			label: _object?.name,
			value: key
		};
		const hasChanged = subCategory.value !== key;

		onSubCategoryChange(value, hasChanged);
	};

	const onSubCategoryInputChange = value => {
		const _object = {
			label: value,
			value: value === "" ? null : subCategory.value
		};

		const hasChanged = value === "";

		onSubCategoryChange(_object, hasChanged);
	};

	return (
		<>
			<StarToggle
				name="main_function"
				value={id}
				checked={isMain}
				onClick={() => handleStarToggleClick(id)}
			/>
			<div className={styles.categorySelectWrapper}>
				<CategorySelect
					onChange={onCategoryChange}
					menu={categoriesListFormatted}
					value={category?.label}
					inputClassName={styles.categoryInput}
				/>
			</div>
			<div>
				<ComboBox
					inputValue={subCategory?.label}
					selectedKey={subCategory?.value}
					onSelectionChange={onSubCategorySelectionChange}
					onInputChange={onSubCategoryInputChange}
				>
					{(category?.children || category?.subs)?.map(({ _id, name }) => (
						<Item key={_id}>{name}</Item>
					))}
				</ComboBox>
			</div>
			<div>
				<ComboBox
					selectedKey={_function?.value}
					inputValue={_function?.label}
					onSelectionChange={onFunctionSelectionChange}
					onInputChange={onFunctionInputChange}
					disabledInput={functionsList?.length === 0 || !functionsList}
				>
					{functionsList?.map(({ _id, name }) => (
						<Item key={_id}>{name}</Item>
					))}
				</ComboBox>
			</div>
			<div>
				<ComboBox
					selectedKey={seniority?.value}
					inputValue={seniority?.label}
					onSelectionChange={onSenioritySelectionChange}
					onInputChange={onSeniorityInputChange}
				>
					{senioritiesList.map(({ label, value }) => (
						<Item key={value}>{label}</Item>
					))}
				</ComboBox>
			</div>
			{displayDelete && (
				<button
					className={styles.deleteButton}
					aria-labelledby="delete-function-button-label"
					onClick={() => handleDeleteFunctionClick(id)}
				>
					<span id="delete-function-button-label" hidden>
						Delete function
					</span>
					<DeleteIconGrey />
				</button>
			)}
		</>
	);
};

export default FunctionsPickerModalFunctionRow;
