import React, { useEffect } from "react";
import useFetchTimesheetsList from "modules/timesheetsModule/api/useFetchTimesheetsList";
import useFetchTimesheetsTableSettings from "modules/timesheetsModule/api/useFetchTimesheetsTableSettings";
import { COLUMNS } from "modules/timesheetsModule/utils/timesheetsFilterFields";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import {
	API_DATA_TYPE,
	TIMESHEETS_FILTER_ID,
	TIMESHEETS_PAGE_SIZES
} from "modules/timesheetsModule/utils/constants";
import { useInitializeFilterDrawer } from "modules/timesheetsModule/hooks/useInitializeFilterDrawer";
import { TimesheetsTable } from "../TimesheetsTable";
import styles from "./timesheets-list.module.scss";
import PageSizeSelect from "common/PageSizeSelect/PageSizeSelect";
import { TimesheetsListPagination } from "../TimesheetsListPagination";
import { TimesheetsListTotalCount } from "../TimesheetsListTotalCount";
import { TimesheetsSecondaryHeader } from "../TimesheetsSecondaryHeader";
import { validFiltersCount } from "common/FilterDrawer/render_utils";
import loadable from "loadable-components";
import { useDisplayRecap } from "modules/timesheetsModule/hooks/useDisplayTimesheetsFilterRecap";
import { TimesheetsFilterRecap } from "../TimesheetsFilterRecap";
import { isTimesheetsFiltersFull } from "modules/timesheetsModule/utils/helpers";
import { goBack, searchParser } from "config/helpers";
import { ReactComponent as BackArrow } from "static/icons/IconBack.svg";
import useBreadcrumbs from "common/Layouts/breadcrumbs.store";
import useGetTimeSheetStatus from "modules/timesheetsModule/api/useGetTimeSheetStatus";
import { findColumn } from "common/FilterDrawer/utils";

const FilterDrawer = loadable(() => import("common/FilterDrawer/FilterDrawer"));

export default function TimesheetsList() {
	const refetch = useFetchTimesheetsList();
	const { missionTitle, missionStatus } = searchParser();
	const { data: statusList } = useGetTimeSheetStatus({
		enabled: !!missionStatus
	});

	useInitializeFilterDrawer();
	const {
		getState,
		setLimit,
		setOffset,
		setShowDrawer,
		setFilters
	} = useFilters();
	const {
		data: timesheetsList,
		isLoading,
		isFetching,
		limit,
		showDrawer,
		filters
	} = getState(TIMESHEETS_FILTER_ID);

	const { data: tableSettings } = useFetchTimesheetsTableSettings();
	const {
		displayRecap: showFiltersRecap,
		toggleDisplayRecap
	} = useDisplayRecap();
	const setBreadcrumbs = useBreadcrumbs(state => state.setItems);

	useEffect(() => {
		setBreadcrumbs([{ name: "Temporary" }, { name: "Timesheets" }]);
	}, []);

	useEffect(() => {
		if (
			(missionTitle && !missionStatus) ||
			(missionTitle && missionStatus && statusList)
		) {
			const value = [];

			if (missionTitle) {
				const filter_data = findColumn("mission_title", COLUMNS);
				value.push({
					...filter_data,
					value: missionTitle,
					operator: filter_data.operator.value
				});
			}
			if (missionStatus) {
				const filter_data = findColumn("status", COLUMNS);

				value.push({
					...filter_data,
					value: [
						{
							id: 1,
							label: "status",
							children: statusList.status
								.map(item => ({
									label: item.label,
									id: item.value
								}))
								.filter(({ id }) => id === missionStatus)
						}
					],
					operator: filter_data.operator.value
				});
			}
			setFilters(value);
		}
	}, [statusList]);

	useEffect(() => {
		if (tableSettings?.table_settings?.size) {
			setLimit(tableSettings?.table_settings?.size);
		}
	}, [tableSettings]);

	const onPageClick = page => {
		setOffset(page * timesheetsList.size);
	};

	if (!tableSettings) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<button onClick={goBack}>
					<BackArrow />
				</button>
				<h3>Timesheets</h3>
			</div>
			<div className={styles.headerSeparator} />
			<TimesheetsSecondaryHeader
				onFilterClick={() => setShowDrawer(true)}
				onRecapClick={toggleDisplayRecap}
				columns={tableSettings.ordered_columns}
			/>
			{showFiltersRecap && isTimesheetsFiltersFull(filters) && (
				<TimesheetsFilterRecap
					module_id={TIMESHEETS_FILTER_ID}
					filters={filters}
				/>
			)}
			<TimesheetsListTotalCount
				isLoading={isLoading}
				isFetching={isFetching}
				total={timesheetsList?.total}
			/>
			<TimesheetsTable
				data={timesheetsList?.data}
				columns={tableSettings.columns}
				isLoading={isLoading}
				isFetching={isFetching}
			/>
			<div className={styles.containerFooter}>
				<PageSizeSelect
					limit={limit}
					setLimit={setLimit}
					refetch={refetch}
					rowsPages={TIMESHEETS_PAGE_SIZES}
					tableTag={API_DATA_TYPE}
				/>
				{timesheetsList && timesheetsList.total > timesheetsList.size && (
					<TimesheetsListPagination
						total={timesheetsList.total}
						size={timesheetsList.size}
						offset={timesheetsList.offset}
						onPageClick={onPageClick}
					/>
				)}
			</div>
			{showDrawer && (
				<FilterDrawer
					onClose={() => setShowDrawer(false)}
					displayDrawer={showDrawer}
					hideTabSaved
					onlyList
					displayAdvancedSearchHead={false}
					title={
						validFiltersCount(filters) === 0
							? "Filter"
							: `Filter (${validFiltersCount(filters)})`
					}
				/>
			)}
		</div>
	);
}

export const getTimesheetListRoute = () => "/temporary/timesheets";

export const getTimesheetClientRoute = () => {
	return "/timesheets/:jobId(/:timesheetId)";
};

export const getTimesheetListPermissions = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
