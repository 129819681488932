import React from "react";
import cx from "classnames";
import { ReactComponent as CalendrierIcon } from "static/icons/calendrier-icon.svg";
import { ReactComponent as TimeIcon } from "static/icons/time-icon.svg";
import moment from "moment";
import classnames from "./date.module.scss";
import { DATE_FORMAT, TIME_FORMAT_24 } from "config";

const Date = ({ date, isHour, isSupplier }) => {
	return (
		<div
			className={cx(classnames.dateWrraper, {
				[classnames.supplierDate]: isSupplier
			})}
		>
			<div className={classnames.calendarDate}>
				<CalendrierIcon className={classnames.calendrierIcon} />
				<span>{moment.unix(date).format(DATE_FORMAT)}</span>
			</div>
			{isHour && (
				<div className={classnames.calendarHour}>
					<TimeIcon />
					<span>{moment.unix(date).format(TIME_FORMAT_24)}</span>
				</div>
			)}
		</div>
	);
};

export default Date;
