import React, { Component } from "react";
import { connect } from "react-redux";
import { Flex, Text, Button } from "rebass/styled-components";
import BodyClassName from "react-body-classname";
import { reduxForm, formValueSelector, Field } from "redux-form";
import { bindActionCreators, compose } from "redux";
import { Helmet } from "react-helmet";
import { browserHistory } from "react-router";

import {
	CLIENT,
	CONSULTANCY,
	MEMBER,
	DATE_FORMAT,
	SP_NUM_CH,
	ASK_QUESTION,
	COMPANY,
	FREELANCER
} from "config";
import { objectCleaner } from "common/Functions";
import Dashboard from "common/dashboard/Dashboard";
import Intro from "common/dashboard/Intro";
import AddressComposer from "common/AddressComposer";
import GlobalTooltip from "common/GlobalTooltip";
import FormField from "common/FormField";
import { fetchCurrentCompany } from "../actions/identityActions";
import { updateCompanyAdditionalInfo, updateCompanyAddress } from "../actions";
import _get from "lodash/get";
import { getMyProfile } from "../../user/actions/authActions";
import AddressBoxModal from "../../../common/AddressBox/AddressBoxModal";
import { EditIcon } from "../../../common/styled/icons";
import { toggleHelpModal } from "modules/app/actions/ListsActions";

import AxiosHelper from "config/axios-helper";
import {
	LIST_INDUSTRIES_ENDPOINT,
	UPDATE_BILLING_DETAILS
} from "config/api-endpoints";
import Select from "common/StyledSelect";
import Breadcumb from "modules/company/components/BreadcumbSettingPages";
import ProfileImage from "modules/company/components/avatar/profileAvatar.js";

export function getCompanyRoute() {
	return "/settings/company";
}

export function getCompanyPermission() {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			}
		]
	};
}

class MyCompany extends Component {
	state = {
		company: this.props.company || {},
		editMode: false,
		editAddress: false,
		industries: [],
		vat: "",
		companyName: "",
		editCompanyName: false,
		editCompanyVat: false
	};

	componentDidMount() {
		this.getCurrentCompany();
		this.getIndutriesList();
	}

	toggleAddress = () => {
		this.setState({ editAddress: !this.state.editAddress });
	};

	toggleEditCompanyName = () => {
		this.setState({ editCompanyName: !this.state.editCompanyName });
	};
	toggleEditCompanyVat = () => {
		this.setState({ editCompanyVat: !this.state.editCompanyVat });
	};

	updateCompanyState = avatar => {
		this.setState({ company: { ...this.state.company, avatar } });
	};

	editSwitcher = formProps => {
		const editMode = this.state.editMode;
		if (editMode) {
			return this.updateCompanyAdditionalInfo(formProps);
		} else this.setState({ editMode: true });
	};

	getCurrentCompany = () => {
		this.props.fetchCurrentCompany(false).then(data => {
			this.setState({ company: data || {} });
			this.props.initialize({
				...data,
				industry: {
					...data.industry,
					label: _get(data, "industry.name"),
					value: _get(data, "industry._id")
				}
			});
		});
	};
	onAddCompanyInfo = () => {
		let { companyName, company, vat } = this.state;
		AxiosHelper.post({
			url: UPDATE_BILLING_DETAILS,
			isLogin: true,
			data: {
				company_name: companyName ? companyName : _get(company, "name"),
				vat: vat ? vat : _get(company, "vat")
			}
		}).then(() => {
			this.getCurrentCompany();
			this.props.getMyProfile();
			this.setState({ editCompanyName: false });
			this.setState({ editCompanyVat: false });
		});
	};
	onCancelCompanyInfo = () => {
		this.setState({ editCompanyName: false });
		this.setState({ editCompanyVat: false });
	};

	updateItems = (event, stateItem, toUpper = false) => {
		let { company } = this.state;
		company[stateItem] = toUpper
			? event.target.value.toUpperCase()
			: event.target.value.toLowerCase();
		this.setState({ company: { ...company } });
	};

	updateCompanyAdditionalInfo = ({ industry }) => {
		const company = objectCleaner(this.state.company);
		company.type = CLIENT;
		company.industry = _get(industry, "_id");
		this.props.updateCompanyAdditionalInfo(company, "my-company").then(() => {
			this.setState({
				editMode: false
			});
			this.getCurrentCompany();
			this.props.getMyProfile();
		});
	};

	saveAddress = company => {
		let _company = { ...this.state.company, ...company };
		this.props.updateCompanyAddress(_company).then(() => {
			this.setState({ company: _company });
			this.toggleAddress();
			this.props.getMyProfile();
		});
	};

	getIndutriesList = () => {
		AxiosHelper.post({
			url: LIST_INDUSTRIES_ENDPOINT,
			isLogin: true,
			data: {}
		}).then(resp => this.setState({ ...this.state, industries: resp }));
	};

	formatIndustries = industries => {
		return industries.map(i => ({
			...i,
			label: i.name,
			value: i._id
		}));
	};

	render() {
		const {
			editMode,
			company,
			editCompanyName,
			editCompanyVat,
			vat,
			companyName
		} = this.state;
		const {
			toggleHelpModal,
			handleSubmit,
			route: { title },
			user: { company_type, isMember, active_role }
		} = this.props;
		const isClient = company_type === CLIENT;
		const isConsultant = company_type === CONSULTANCY;
		const isFeelancer = company_type === FREELANCER;
		const isTooltip =
			(company_type === CLIENT && isMember) ||
			(company_type === CONSULTANCY && active_role === MEMBER);

		const action = (
			<button
				onClick={isTooltip ? null : handleSubmit(this.editSwitcher)}
				className={`btn ${
					editMode ? "btn-primary-new" : "btn-normal"
				} pull-right-btn`}
			>
				{editMode ? (
					"Save changes"
				) : (
					<div>
						<EditIcon icon={"icon-edit"} /> Edit profile
					</div>
				)}
			</button>
		);

		const vacancyId = _get(this.props, "location.query.source");
		return (
			<BodyClassName className="my-company my-profile">
				<div>
					{title && (
						<Helmet>
							<title>{title}</title>
						</Helmet>
					)}
					{!isClient ? (
						<div className="dashboard-box">
							<Dashboard />
							{vacancyId && (
								<div
									className="back-to-list"
									onClick={() => {
										browserHistory.push(`/vacancy/${vacancyId}`);
									}}
								>
									<div className="icon-container">
										<span className="icon-arrow-right1" />
									</div>
									Back to job
								</div>
							)}
							<Intro
								icon="fa fa-file-text-o"
								title={
									!isClient ? "Company/Legal Entity Details" : "Company Details"
								}
							>
								<p>
									{isClient
										? `These details are needed for wiggli to generate the contracts as
									well as the invoices related to the missions performed via the platform.
									Should the address of the company change, or if you would like to
									use wiggli via another VAT/registration number, please`
										: isConsultant
										? `These details are needed to generate the contracts for missions arranged via wiggli as well as pay the invoices related to the work of your consultants. Should the VAT number, or official name of the  company change, please  `
										: `These details are needed for wiggli to be able to prepare your
									invoices and pay them as well as generate the contracts for your missions.
									Should the address of your legal entity change, or if you would like to
									work via another VAT/registration number, please `}{" "}
									<button
										className="button-link"
										onClick={() =>
											toggleHelpModal(ASK_QUESTION, {
												subject: "Change of legal entity"
											})
										}
									>
										contact our support team.
									</button>
								</p>
							</Intro>
						</div>
					) : null}
					{isClient ? (
						<Breadcumb
							parentItem="Settings"
							currentItem="My Company"
							pathCurrentItem="/settings/company"
						/>
					) : (
						<></>
					)}
					<div
						style={{
							marginTop: isClient ? "20px" : "0",
							padding: isClient ? "0 15px" : "0"
						}}
						className={isClient ? "full-container" : "container"}
					>
						<div className="left-side">
							<div className="avatar-side bg-white">
								<ProfileImage
									user={{ ...company, id: company._id }}
									type={COMPANY}
									onSuccess={() => {
										this.getCurrentCompany();
										this.props.getMyProfile();
									}}
								/>
							</div>
							<div className="company-type">
								<div className="bg-white">
									<div>
										<span className="title-label">Account type</span>
										<span className="type">{company.type}</span>
									</div>
									<div>
										<span className="title-label">Account created on</span>
										<span>
											{window.moment
												.unix(company.registration_date)
												.format(DATE_FORMAT)}
										</span>
									</div>
								</div>
							</div>

							<div className="additional-info bg-white">
								<GlobalTooltip
									active={isTooltip}
									placement="top"
									eventTrigger="click"
									overlay="As a user, you can’t edit Company Details, please contact your account’s Super Admin"
								>
									{action}
								</GlobalTooltip>

								{!isFeelancer && (
									<div className={editMode ? "editable-item" : "item"}>
										<span className="title-label">Industry</span>
										{editMode ? (
											<Field
												name="industry"
												options={this.formatIndustries(this.state.industries)}
												position={false}
												searchable={true}
												clearable={true}
												component={Select}
											/>
										) : (
											<p>{_get(company, "industry.name")}</p>
										)}
									</div>
								)}
								<div className={editMode ? "editable-item" : "item"}>
									<span className="title-label">Website</span>
									{editMode ? (
										<Field
											autoFocus
											name="website"
											component={FormField}
											onChange={e => {
												this.updateItems(e, "website");
											}}
										/>
									) : (
										<p>{_get(company, "website")}</p>
									)}
								</div>
								<div className={editMode ? "editable-item" : "item"}>
									<span className="title-label">
										BIC (Bank Identifier Code)
									</span>
									{editMode ? (
										<Field
											name="bic"
											className="upperCase"
											component={FormField}
											onChange={e => this.updateItems(e, "bic", true)}
										/>
									) : (
										<p>{company.bic}</p>
									)}
								</div>
								<div className={editMode ? "editable-item" : "item"}>
									<span className="title-label">
										IBAN (International Bank Account Number)&nbsp;
									</span>
									{editMode ? (
										<Field
											name="iban"
											className="upperCase"
											component={FormField}
											onChange={e => this.updateItems(e, "iban", true)}
										/>
									) : (
										<p>{company.iban}</p>
									)}
								</div>
							</div>
						</div>
						<div className="right-side">
							<div className="bg-white">
								<div className="item">
									<h2>Billing details</h2>

									<span className="title-label">VAT number</span>

									{editCompanyVat ? (
										<>
											<input
												className="form-control  upperCase"
												value={vat}
												onChange={({ target }) =>
													this.setState({ vat: target.value })
												}
											/>
										</>
									) : (
										<Flex justifyContent="space-between" alignItems="center">
											<p>{_get(company, "vat")}</p>{" "}
											{!_get(company, "vat") && (
												<Text
													as={"i"}
													sx={{ fontSize: "19px", cursor: "pointer" }}
													className="icon-edit"
													onClick={this.toggleEditCompanyVat}
												/>
											)}
										</Flex>
									)}
								</div>
								<div className="item">
									<span className="title-label">
										Official name &amp; legal form
									</span>

									{editCompanyName ? (
										<input
											value={companyName}
											className="form-control  upperCase"
											onChange={({ target }) =>
												this.setState({ companyName: target.value })
											}
										/>
									) : (
										<Flex justifyContent="space-between" alignItems="center">
											<p>{_get(company, "name")}</p>{" "}
											{!_get(company, "name") && (
												<Text
													as={"i"}
													sx={{ fontSize: "19px", cursor: "pointer" }}
													className="icon-edit"
													onClick={this.toggleEditCompanyName}
												/>
											)}
										</Flex>
									)}
								</div>

								{_get(company, "vat") && _get(company, "name") ? null : (
									<div className="item">
										<Flex justifyContent="flex-end">
											<Button
												onClick={this.onCancelCompanyInfo}
												mr={"10px"}
												sx={{
													background: "#FFFFFF",
													border: "1px solid #A0A3BD",
													fontSize: "14px",
													color: "#A0A3BD",
													fontWeight: "bold"
												}}
											>
												Cancel
											</Button>
											<Button
												onClick={this.onAddCompanyInfo}
												className="btn btn-primary-new"
												disabled={
													(companyName || _get(company, "name")) &&
													(vat || _get(company, "vat"))
														? false
														: true
												}
											>
												save
											</Button>
										</Flex>
									</div>
								)}

								<div className="item">
									<span className="title-label">Address</span>
									<GlobalTooltip
										active={isTooltip}
										placement="top"
										eventTrigger="click"
										overlay="As a user, you can’t edit Company Details, please contact your account’s Super Admin"
									>
										<div
											className="btn btn-normal pull-right-30top"
											onClick={isTooltip ? null : this.toggleAddress}
										>
											<i className="icon-edit" /> Edit Address
										</div>
									</GlobalTooltip>

									<AddressComposer addressData={company} />
								</div>
								<AddressBoxModal
									active={this.state.editAddress}
									onClose={this.toggleAddress}
									onSave={this.saveAddress}
									requiredFields={[
										"street",
										"number",
										"city",
										"zip",
										"country"
									]}
									isRequired={true}
									title="Edit Address"
									label="Company address"
									currentAddress={company}
								/>
							</div>
						</div>
					</div>
				</div>
			</BodyClassName>
		);
	}
}

function validator({ bic, iban }) {
	const errors = {};
	if (bic && !SP_NUM_CH.test(bic)) {
		errors.bid = "only letters and numbers";
	}
	if (iban && !SP_NUM_CH.test(iban)) {
		errors.iban = "only letters and numbers";
	}
	return errors;
}

const selector = formValueSelector("my-company");

const mapStateToProps = state => {
	const {
		phone_country,
		phone_number,
		website,
		bic,
		iban,
		industry
	} = selector(
		state,
		"phone_country",
		"phone_number",
		"website",
		"bic",
		"iban",
		"industry"
	);
	return {
		user: {
			first_name: state.auth.user.first_name,
			last_name: state.auth.user.last_name,
			email: state.auth.user.email,
			birthday: state.auth.user.birthday,
			user_function: state.auth.user.user_function,
			avatar: state.auth.user.avatar,
			company_type: state.auth.user.company_type,
			role_name: state.auth.user.role_name,
			active_role: state.auth.user.active_role,
			isMember: _get(state, "auth.user.is_member")
		},
		company: state.company,
		formFields: {
			phone_country,
			phone_number,
			website,
			bic,
			iban,
			industry
		}
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchCurrentCompany,
			updateCompanyAdditionalInfo,
			updateCompanyAddress,
			getMyProfile,
			toggleHelpModal
		},
		dispatch
	);
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "my-company",
		touchOnBlur: false,
		validator
	})
)(MyCompany);
