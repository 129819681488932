import { useMutation } from "react-query";
import { client } from "../../../lib/api-client";
import { CHANGE_SUPPLIER_INVITATION_STATUS_ENDPOINT } from "../../../config/api-endpoints";

function changeSupplierInvitationStatus(body) {
	return client(CHANGE_SUPPLIER_INVITATION_STATUS_ENDPOINT, {
		body
	});
}

function useChangeSupplierInvitationStatus(options) {
	return useMutation(changeSupplierInvitationStatus, options);
}

export default useChangeSupplierInvitationStatus;
