import Immutable from "seamless-immutable";
import {
	LOADING,
	LOADED,
	SIGN_UP_SUCCESS,
	STORE_USER_ID,
	SET_SIGNUP_ADDRESS
} from "../actions/actionTypes";

const InitialState = Immutable({
	loading: false,
	loaded: false,
	error: null,
	userId: null,
	address: null,
	data: {
		name: null,
		email: null
	}
});

export default function signupReducer(state = InitialState, action) {
	switch (action.type) {
		case LOADING:
			return Immutable.merge(state, {
				loading: true,
				loaded: false
			});
		case LOADED:
			return Immutable.merge(state, {
				loaded: true,
				loading: false,
				data: {
					...state.data
				}
			});
		case SIGN_UP_SUCCESS:
			return Immutable.merge(state, {
				data: {
					name: action.name,
					email: action.email
				}
			});
		case STORE_USER_ID:
			return Immutable.merge(state, { userId: action.id });
		case SET_SIGNUP_ADDRESS:
			return Immutable.merge(state, { address: action.address });
		default:
			return state;
	}
}
