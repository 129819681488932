import styled from "styled-components";
import { colors } from "config/styles";

export const InterestTitle = styled.div`
	text-align: left;
	display: inline-block;
	width: min-content;
`;

export const Heading = styled.div`
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 16px;
	color: ${colors.anchorsColor};
`;

export const SubHeading = styled.div`
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 10px;
	color: ${colors.anchorsColor};
`;

export const PokeListContainer = styled.div`
	box-shadow: 0px 2px 21.5px 0 rgba(197, 199, 206, 0.43);
	position: absolute;
	z-index: 10;
	right: 0;
	width: 920px;
	top: ${props => (props.bottom ? "unset" : "75px")};
	bottom: ${props => (props.bottom ? "75px" : "unset")};
	max-height: 210px;
	overflow: ${props => (props.scroll ? "auto" : "hidden")};
`;

export const IconContainer = styled.div`
	text-align: center;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${props => (props.rotate ? "#ff6849" : "#c9cacb")};
	height: 30px;
	width: 30px;
	font-size: 6px;
	line-height: ${props => (props.rotate ? "30px" : "35px")};
	cursor: ${props => (props.cursor ? props.cursor : "pointer")};
	color: ${props => (props.rotate ? "#ffffff" : "#fff")};
	.rotate-icon {
		transform: rotate(-90deg);
		display: inline-block;
		-webkit-transform: rotate(-90deg);
		-moz-transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		-o-transform: rotate(-90deg);
	}
`;

export const FiltersContainer = styled.div`
	.form-group {
		position: relative;
		margin-bottom: 24px;
		.form-control {
			border: unset;
			background: transparent;
			border-bottom: 1px solid #d6d7dd;
			padding: 0px 30px;
			min-height: 45px !important;
		}
		input.form-control {
			padding-left: 30px;
		}
		.icon-search {
			position: absolute;
			top: 17px;
			left: 8px;
			color: #a5b1bb;
		}
		.input-error {
			border: 1px solid #ff6849 !important;
		}
		.icon-reset-3 {
			position: relative;
			top: 50%;
		}
	}
	.select-label {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: 0.08px;
		color: #273238;
		line-height: 1.43;
	}
	.Select-control {
		width: 190px;
		margin-top: 6px;
		height: 40px;
		border: 2px solid #0676ed;
		border-radius: 2px;
		.Select-value {
			display: flex;
			align-items: center;
		}
	}
	.Select-menu-outer {
		width: 190px;
	}
`;
