import Immutable from "seamless-immutable";
import _get from "lodash/get";
import * as types from "./actionTypes";

const initialState = {
	BUList: [],
	buid: undefined,
	activeModal: false,
	businessUnitManagersList: [],
	isBusinessUnitManagersLoading: true,
	isBusinessUnitLoading: true
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.FETCH_BUSINESS_UNIT_MANAGERS_LIST:
			return Immutable.merge(state, {
				businessUnitManagersList: _get(action, "data", []),
				isBusinessUnitManagersLoading: false
			});
		case types.FETCH_BU_LIST:
			return Immutable.merge(state, {
				BUList: _get(action, "data.groups_list", []),
				isBusinessUnitLoading: false
			});
		case types.SET_ACTIVE_MODAL:
			return Immutable.merge(state, {
				activeModal: action.modal
			});
		case types.SET_BUID:
			return Immutable.merge(state, {
				buid: action.buid
			});
		default:
			return state;
	}
}
