import React from "react";
import styles from "../companies.module.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ReactComponent as IconUsers } from "static/icons/IconUsers.svg";
import { ReactComponent as IconJobs } from "static/icons/Iconjobs.svg";
import { ReactComponent as IconTalendPool } from "static/icons/IconTalendPool.svg";
import { ReactComponent as IconIndustries } from "static/icons/IconIndustries.svg";
import { ReactComponent as IconLocation } from "static/icons/IconLocation.svg";
import { ReactComponent as IconGreenNotification } from "static/icons/IconGreenNotification.svg";

export default function SkeletonCard() {
	return (
		<SkeletonTheme style={{ borderRadius: "64px" }} color="#E2E8F0">
			<div className={styles.card__container}>
				<div className={styles.card__header}>
					<div className={styles.card__title}>
						<Skeleton width={56} height={56} />
						<div
							style={{
								gap: "6px",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center"
							}}
						>
							<Skeleton width={110} height={15} />
							<Skeleton width={150} height={12} />
						</div>
					</div>
					<div className={styles.card__icons}>
						<IconTalendPool />
						<IconGreenNotification />
					</div>
				</div>
				<div className={styles.card__body}>
					{[
						<IconIndustries key="IconIndustries" />,
						<IconLocation key="IconLocation" />,
						<IconUsers key="IconUsers" />,
						<IconJobs key="IconJobs" />
					].map((icon, index) => {
						return (
							<div
								key={index}
								style={{
									gap: "14px",
									display: "flex"
								}}
							>
								{icon}
								<Skeleton width={225} height={10} />
							</div>
						);
					})}
				</div>
			</div>
		</SkeletonTheme>
	);
}

export function SkeletonList({ items = 9 }) {
	return (
		<div className={styles.companies__container}>
			{Array.from(Array(items).keys()).map(item => {
				return <SkeletonCard key={item} />;
			})}
		</div>
	);
}
