import React from "react";
import styles from "./messaging-tool-new-email-template-empty-state.module.scss";
import { Button } from "common/Button";
import { historyReplace } from "config/helpers";
import { ReactComponent as EditIcon } from "static/icons/email-template/edit.svg";
import { NEW_TEMPLATE_SEGMENT_ID } from "modules/EmailTemplate/utils/constants";
import { getEmailTemplateRoute } from "modules/EmailTemplate";

const MessagingToolNewEmailTemplatesEmptyState = ({ onClose }) => {
	return (
		<div className={styles.container}>
			<h3 className={styles.title}>No Templates</h3>
			<span className={styles.description}>
				No email templates yet. Create reusable templates for common messages.
			</span>
			<Button
				className={styles.button}
				icon={<EditIcon />}
				text="New Template"
				type="button"
				onClick={() => {
					onClose();
					historyReplace(getEmailTemplateRoute(NEW_TEMPLATE_SEGMENT_ID));
				}}
			/>
		</div>
	);
};

export default MessagingToolNewEmailTemplatesEmptyState;
