import React from "react";
import styles from "./temp-pipeline-card-loading.module.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const TempPipelineCardLoading = () => {
	return (
		<SkeletonTheme color="#F1F5F9">
			<div className={styles.container}>
				<div className={styles.head}>
					<div className={styles.avatar}>
						<Skeleton style={{ borderRadius: "50%" }} width={38} height={38} />
					</div>
					<Skeleton style={{ borderRadius: "6px" }} width={114} height={21} />
					<Skeleton
						style={{ borderRadius: "6px" }}
						width={"100%"}
						height={21}
					/>
				</div>
				<div className={styles.body}>
					<Skeleton
						style={{ borderRadius: "8px" }}
						width={"100%"}
						height={"38px"}
					/>
					<Skeleton
						style={{ borderRadius: "8px" }}
						width={"100%"}
						height={"38px"}
					/>
				</div>
				<div className={styles.footer}>
					<Skeleton
						style={{ borderRadius: "66px", marginRight: "18px" }}
						width={"42px"}
						height={"26px"}
					/>
					<Skeleton
						style={{ borderRadius: "66px", marginRight: "18px" }}
						width={"42px"}
						height={"26px"}
					/>
					<div className={styles.emptyButton} />
					<div className={styles.buttonContainer}>
						<Skeleton
							style={{ borderRadius: "50%" }}
							width={"16px"}
							height={"16px"}
						/>
					</div>
					<div className={styles.buttonContainer}>
						<Skeleton
							width={"16px"}
							height={"16px"}
							style={{ borderRadius: "50%" }}
						/>
					</div>
					<div className={styles.buttonContainer}>
						<Skeleton
							style={{ borderRadius: "50%" }}
							width={"16px"}
							height={"16px"}
						/>
					</div>
				</div>
			</div>
		</SkeletonTheme>
	);
};

export default TempPipelineCardLoading;
