import React from "react";
import cx from "classnames";
import _get from "lodash/get";
import DateTimeDisplayer from "modules/home/components/DateTimeDisplayer.jsx";
import VerticalDivider from "modules/home/components/VerticalDivider";
import style from "modules/home/containers/interview/interview.module.scss";
import Attendees from "modules/home/components/Attendees.jsx";
import GlobalTooltip from "common/GlobalTooltip";
import { INTERVIEW_LABELS, VACANCY, WELCOME_PAGE } from "config";
import { historyPush } from "config/helpers";
import { TEMPORARY_INTERVIEWS } from "modules/home/config";
import { getClientBidViewRoute } from "modules/RequestView/components/BidDetailsDrawer/utils/constant";

const MAX_TITLE_LENGTH = 25;

const ItemRenderer = ({ children, title }) => (
	<div>
		<label className={style["label"]}> {title} </label>
		<div className={style["value"]}>
			<VerticalDivider />
			{children}
		</div>
	</div>
);

const Interview = ({ interview, has_bg }) => {
	const handleClick = () => {
		const redirectTo =
			_get(interview, "job_type") === VACANCY
				? `/permanent/view/${interview.user_recipient._id}?application_id=${interview.poke_id}`
				: getClientBidViewRoute(
						interview.bid_id,
						interview.job._id,
						`&source=${WELCOME_PAGE}&list=bids&listKey=${TEMPORARY_INTERVIEWS}`
				  );
		historyPush(redirectTo);
	};
	return (
		<div
			className={cx(style["interview"], {
				[style["has_bg"]]: has_bg
			})}
			onClick={handleClick}
		>
			<DateTimeDisplayer
				createdAt={_get(interview, "new_interview_start_date")}
				interviewStartDate={_get(interview, "new_interview_start_date")}
				interviewEndDate={_get(interview, "new_interview_end_date")}
			/>
			<div className={style["itemsContainer"]}>
				<ItemRenderer
					title={
						_get(interview, "job_type") === VACANCY ? "Vacancy" : "Request"
					}
				>
					<GlobalTooltip
						placement="top"
						overlay={_get(interview, "job.title")}
						withWrappingDiv={false}
						active={_get(interview, "job.title.length", 0) > MAX_TITLE_LENGTH}
					>
						<div>
							{_get(interview, "job.title.length", 0) > MAX_TITLE_LENGTH
								? `${_get(interview, "job.title").substring(
										0,
										MAX_TITLE_LENGTH
								  )}...`
								: _get(interview, "job.title")}
						</div>
					</GlobalTooltip>
				</ItemRenderer>
				<ItemRenderer title="Applicant's name">
					<div>
						{`${_get(interview, "user_recipient.first_name")} ${_get(
							interview,
							"user_recipient.last_name"
						)}`}{" "}
					</div>
				</ItemRenderer>
				<ItemRenderer title="Location">
					<div>{INTERVIEW_LABELS[_get(interview, "location_type")]}</div>
				</ItemRenderer>
				<ItemRenderer title="Attendees">
					<Attendees attendees={_get(interview, "attendees_users")} />{" "}
				</ItemRenderer>
			</div>
		</div>
	);
};

export default Interview;
