import styled from "styled-components";

export const Label = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-family: "basiercircle";
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: #6d7175;
	margin-bottom: 5px;
	> svg {
		min-width: 20px;
		margin-right: 10px;
	}
`;
export const OfferDetailsContainer = styled.div`
	display: grid;
	grid-template-columns: 6fr 1fr;
	background: white;
	padding: 16px 16px 16px 32px;
	box-shadow: 0px 0px 2px 2px rgb(24 24 25 / 4%);
	border-radius: 4px;
	margin-bottom: 8px;
	.box-label {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #202223;
	}
	.first_row {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		margin: 20px 0;
	}
	.second_row {
		display: grid;
		grid-template-columns: 1fr 4fr;
		margin: 20px 0;
	}
	.value {
		font-family: "basiercircle";
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		color: #202223;
		margin-left: 30px;
		word-break: break-all;
		display: block;
	}
	.file {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #181819;
		font-family: "basiercircle";
		margin-bottom: 10px;
		.text {
			margin-right: 10px;
			text-decoration: none;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.other_files {
		font-family: "basiercircle";
		font-size: 12px;
		line-height: 16px;
		color: #a8aaac;
		.view_all {
			color: #4d13d1;
			cursor: pointer;
			margin-left: 10px;
		}
	}
	.status {
		justify-self: flex-end;
	}
`;
export const PhoneScreenContainer = styled.div`
	box-sizing: border-box;
	margin: 0;
	min-width: 0;
	border-radius: 4px;
	box-shadow: 0 0.5px 3.5px 0 rgb(0 0 0 / 11%);
	background-color: rgb(255, 255, 255);
	position: relative;
	margin-bottom: 10px;
	padding: 20px 20px 20px 35px;
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 0 20px 0;
	}
	.box_label {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #202223;
		font-family: "basiercircle";
	}
	.info_section {
		display: grid;
		grid-template-columns: 10fr 6fr;
		grid-gap: 20px;
		.item {
			width: 100%;
			.value {
				display: block;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				color: #202223;
				font-family: "basiercircle";
				margin-left: 30px;
				word-break: break-word;
			}
		}
	}
`;
