import React from "react";
import { ReactComponent as GroupPeople } from "static/icons/group-people.svg";
import styles from "./gdpr-list-empty-state.module.scss";

const GdprListEmptyState = () => {
	return (
		<div className={styles.container}>
			<GroupPeople />
			<div className={styles.text}>No candidates available</div>
		</div>
	);
};

export default GdprListEmptyState;
