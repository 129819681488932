import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _get from "lodash/get";
import BidSideBar from "modules/bids/components/bidview/BidSideBar";
import {
	CLIENT,
	ADMIN,
	DECLINED,
	INVITE_INTERVIEW,
	SENT,
	ACCEPTED,
	PROPOSE_CONTRACT,
	PROPOSE_SUCCESS,
	REJECTED,
	CANCELLED,
	REFUSED
} from "config";
import {
	fetchBid,
	toggleInterviewModal,
	acceptInterview,
	toggleContractModal,
	toggleRejectApplicationModal,
	interviewDispatch,
	ProposeContract,
	rejectApplication,
	acceptContract
} from "modules/bids/actions/bidActions";
import CancelBidModal from "modules/bids/components/modals/CancelBidModal";
import { toggleHelpModal } from "modules/app/actions/ListsActions";
import { bidSelector } from "modules/bids/selectors";
import { Container } from "./Styled";
import ClientInterviewModal from "modules/bids/components/modals/ClientInterview";
import ProposeContractModal from "modules/bids/components/modals/ProposeContractModal";
import {
	ConfirmationModal,
	ConfirmationTypes,
	SuccessModal
} from "common/modal";
import RejectApplicationModal from "modules/bids/components/modals/RejectApplicationModal";
import AcceptInterviewModal from "common/AcceptInterviewModal";

const ProfileBidActions = ({
	user,
	bid,
	bidId,
	fetchBid,
	toggleInterviewModal,
	acceptInterview,
	toggleContractModal,
	toggleRejectApplicationModal,
	toggleHelpModal,
	interviewDispatch,
	ProposeContract,
	rejectApplication,
	acceptContract
}) => {
	useEffect(() => {
		fetchBid(bidId);
	}, []);

	const [showBidModal, setShowBidModal] = useState(false);
	const [showCancelContractModal, setShowContractModal] = useState(false);
	const [
		displayAcceptInterviewConfirmation,
		setDisplayAcceptInterviewConfirmation
	] = useState(false);

	const data = _get(bid, "data");
	const groups = _get(user, "groups", []);
	const canProposeContract =
		!user.is_member ||
		groups.some(
			group =>
				group.id === _get(data, "job.group._id") && group.role_name === ADMIN
		);

	const acceptInterviewModalAction = () => {
		acceptInterview(
			{
				interview_id: _get(data, "interview._id", {}),
				token_invitation_id: _get(data, "interview.token_invitation_id", {}),
				bid_id: bidId
			},
			() => setDisplayAcceptInterviewConfirmation(false)
		);
	};

	const renderModals = () => {
		const isDeclined = _get(data, "contract.status") === DECLINED;
		if (_get(bid, "interview_modal.show")) {
			switch (_get(bid, "interview_modal.type")) {
				case INVITE_INTERVIEW:
					return (
						<ClientInterviewModal
							interviewDispatch={interviewDispatch}
							toggleInterviewModal={toggleInterviewModal}
							interview={_get(data, "interview", {})}
							job_title={_get(data, "job.title")}
							is_edit={
								_get(data, "interview.status") === SENT ||
								_get(data, "interview.status") === ACCEPTED
							}
							is_decline={_get(data, "interview.status") === DECLINED}
							bid_id={bidId}
							user={user}
							profile={`${_get(data, "profile_detail.first_name")} ${_get(
								data,
								"profile_detail.last_name"
							)}`}
						/>
					);
				default:
					break;
			}
		}

		if (_get(bid, "contract_modal.show")) {
			switch (_get(bid, "contract_modal.type")) {
				case PROPOSE_CONTRACT:
					return (
						<ProposeContractModal
							toggleContractModal={() => toggleContractModal(false)}
							bid_id={bidId}
							contract={data.contract}
							proposed_amount={data.proposed_amount}
							ProposeContract={ProposeContract}
							margin={data.margin_rate}
							reportingSetting={data.reporting_settings}
						/>
					);
				case PROPOSE_SUCCESS:
					return (
						<SuccessModal
							className="contract-modal"
							onClose={() => toggleContractModal(false)}
							title={
								isDeclined
									? "Other Terms Proposed"
									: "Contract Proposition Sent"
							}
							description={
								<span>
									Your {`${isDeclined ? "adapted" : "contract"}`} proposition
									has successfully been {`${isDeclined ? "sent" : "made"}`}!
									<br /> You’ll receive a feedback as soon as one is provided.
								</span>
							}
						/>
					);
				default:
					break;
			}
		}

		return null;
	};

	const toggleContractConfirmation = () => {
		setShowContractModal(showCancelContractModal);
	};
	if (
		_get(bid, "data.status") !== REJECTED &&
		_get(bid, "data.status") !== CANCELLED &&
		_get(bid, "data.contract.status") !== CANCELLED &&
		_get(bid, "data.contract.status") !== REFUSED
	) {
		return (
			<Container>
				<BidSideBar
					userType={CLIENT}
					userRole={user.role_name}
					canProposeContract={canProposeContract}
					job={_get(data, "job", {
						full_time: "",
						hourly_budget: "",
						publish_date: " ",
						start_date: "",
						end_date: "",
						company: ""
					})}
					toggleInterviewModal={toggleInterviewModal}
					toggleContractModal={toggleContractModal}
					interview={_get(data, "interview", {})}
					contract={_get(data, "contract", {})}
					bidStatus={_get(data, "status")}
					companyId={user.active_company || ""}
					canMakeProposal={_get(data, "can_make_proposal")}
					acceptInterview={() => setDisplayAcceptInterviewConfirmation(true)}
					toggleContractConfirmation={toggleContractConfirmation}
					bidId={bidId}
					toggleRejectApplicationModal={toggleRejectApplicationModal}
					cancelBid={() => setShowBidModal(true)}
					toggleHelpModal={toggleHelpModal}
					displayOnlyActions
				/>
				<CancelBidModal
					active={showBidModal}
					onClose={() => setShowBidModal(false)}
				/>
				{renderModals()}

				{_get(bid, "rejectModal") && (
					<RejectApplicationModal
						bidId={bidId}
						interviewId={_get(data, "interview._id", "")}
						contractId={_get(data, "contract._id", "")}
						stepperStatus={_get(data, "stepper_status")}
						onReject={rejectApplication}
						toggleRejectApplicationModal={toggleRejectApplicationModal}
					/>
				)}
				<ConfirmationModal
					active={showCancelContractModal}
					modalName="accept-client-modal"
					onClose={toggleContractConfirmation}
					firstButton={{
						action: () =>
							acceptContract({
								contract_id: _get(bid, "data.contract._id"),
								bid_id: _get(bid, "params.id")
							})
								.then(() => toggleContractConfirmation())
								.catch(() => toggleContractConfirmation()),
						label: "YES, ACCEPT TERMS"
					}}
					title="Are you sure you want to accept these terms ?"
					content="Once accepted, the terms of the proposition will be used
					by wiggli to generate the contracts covering the mission."
					type={ConfirmationTypes.confirmation}
					loading={false}
				/>
				<AcceptInterviewModal
					acceptInterview={acceptInterviewModalAction}
					closeModal={() => setDisplayAcceptInterviewConfirmation(false)}
					displayModal={displayAcceptInterviewConfirmation}
				/>
			</Container>
		);
	}
	return <></>;
};

const mapStateToProps = state => ({
	bid: bidSelector(state)
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			toggleInterviewModal,
			acceptInterview,
			toggleContractModal,
			toggleRejectApplicationModal,
			toggleHelpModal,
			fetchBid,
			interviewDispatch,
			ProposeContract,
			rejectApplication,
			acceptContract
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(ProfileBidActions);
