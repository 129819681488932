import React, { useState, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import { bindActionCreators } from "redux";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import _get from "lodash/get";

import Modal from "common/modal";
import MenuWithCTA from "common/MenuWithCTA";
import MentionsInput from "common/MentionsInput";
import { getVacancyProfileRoute } from "modules/job/components";

import { TEXTAREA_MAX_LENGTH, VACANCY_SHORTLIST } from "config";
import { CTA, CTAIcon } from "./PokeModal";
import {
	ADD_TO_SHORTLIST_ENDPOINT,
	FILTER_SETTINGS_ENDPOINT
} from "config/api-endpoints";
import AxiosHelper from "config/axios-helper";

const formatVacancies = vacancies => {
	return vacancies.map(v => ({ label: v.title, value: v._id }));
};

const getUsers = users => users.map(u => ({ id: u._id, display: u.user_name }));

const getDefaultVacancy = (vacancies, vacancyId) => {
	let vac = vacancies.find(v => v._id === vacancyId);
	if (vac) {
		return { label: _get(vac, "title"), value: _get(vac, "_id") };
	}
};

const AddShortListModal = ({
	onClose,
	handleSubmit,
	vacancyId,
	changeReduxForm,
	profile_id,
	nextAction
}) => {
	const [selectedVacancy, setSelectedVacancy] = useState("");
	const [vacanciesList, setVacanciesList] = useState([]);
	const [usersList, setUsersList] = useState([]);

	useEffect(() => {
		let defaultVacancy = getDefaultVacancy(vacanciesList, vacancyId);
		if (defaultVacancy && !selectedVacancy) {
			changeReduxForm("vacancy", defaultVacancy);
			setSelectedVacancy(defaultVacancy);
		}
	}, [vacanciesList]);

	useEffect(() => {
		loadSettings();
	}, [selectedVacancy]);

	const loadSettings = () => {
		const data = {
			tag: VACANCY_SHORTLIST,
			profile_id,
			vacancy_id: _get(selectedVacancy, "value")
		};

		AxiosHelper.post({
			data,
			url: FILTER_SETTINGS_ENDPOINT,
			isModal: true
		}).then(resp => {
			setVacanciesList(_get(resp, "vacancies", []));
			setUsersList(_get(resp, "list_users", []));
		});
	};

	const submit = fields => {
		const payload = {
			vacancy_id: _get(fields, "vacancy.value"),
			shortlist_note: _get(fields, "mention.value"),
			mentioned_users: _get(fields, "mention.mentions"),
			profile_id
		};
		AxiosHelper.post({
			url: ADD_TO_SHORTLIST_ENDPOINT,
			toastMessage: "Successfully added to shortlist",
			data: payload,
			isModal: true
		}).then(() => {
			nextAction && nextAction();
			return onClose();
		});
	};

	return (
		<Modal
			title={"Add to sourced"}
			onClose={onClose}
			firstButton={{
				label: "Add to sourced",
				type: "primary",
				action: handleSubmit(submit)
			}}
		>
			<Field
				label="Vacancy"
				name="vacancy"
				type="select"
				options={formatVacancies(vacanciesList)}
				value={selectedVacancy}
				onChange={data => setSelectedVacancy(data)}
				placeholder={"Please select a vacancy"}
				// defaultValue={getDefaultVacancy(vacancies, vacancyId) || ""}
				component={MenuWithCTA}
				position={false}
				searchable={true}
				clearable={false}
				emptyText="You have no vacancies"
				cta={() => (
					<CTA
						size={14}
						small
						large
						onClick={() => browserHistory.push(getVacancyProfileRoute(""))}
					>
						Create a new vacancy
						<CTAIcon className="icon-newtab-arrow" />
					</CTA>
				)}
			/>
			<Field
				name="mention"
				label="Internal note (optional)"
				component={MentionsInput}
				maxLength={TEXTAREA_MAX_LENGTH}
				data={[
					{ id: "all", display: "All (all department)" },
					...getUsers(usersList)
				]}
				disabled={!_get(selectedVacancy, "value")}
			/>
		</Modal>
	);
};

const validate = formProps => {
	const errors = {};
	if (!formProps.vacancy) {
		errors.vacancy = "Please fill out this field.";
	}
	return errors;
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			changeReduxForm: (field, value) =>
				dispatch(change("add-to-shortlist", field, value))
		},
		dispatch
	);

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: "add-to-shortlist",
		validate,
		touchOnBlur: false
	})(AddShortListModal)
);
