import { OverlayContainer, useDatePicker } from "react-aria";
import { useDatePickerState } from "@react-stately/datepicker";
import cx from "classnames";
import { DropDownInput } from "common/DropDownInput";
import GlobalTooltip from "common/GlobalTooltip";
import React, { useState, forwardRef } from "react";
import {
	useButton,
	useOverlayPosition,
	useOverlayTrigger,
	OverlayProvider
} from "react-aria";
import Calendar from "./Calendar/Calendar";
import styles from "./date-picker.module.scss";
import DateField from "./DateField/DateField";
import Popover from "./Popover/Popover";
import { Button } from "common/Button";

const DatePicker = (
	{
		rootClassName,
		labelClassName,
		tooltip,
		value,
		onChange,
		minValue,
		maxValue,
		hideLabel = true,
		inputClassName,
		triggerIcon,
		acceptEmptyValue = false,
		portalContainer,
		isDisabled = false,
		list,
		isMultiple,
		onConfirm,
		onClear,
		isExpense,
		onToggle,
		isSelectedAll,
		children,
		...propsRest
	},
	inputRefProp
) => {
	const [displayPaper, setDisplayPaper] = useState(false);

	let propsUpdated = {
		minValue,
		maxValue,
		onChange: value => onChange(value, "dateChanged"),
		isDisabled
	};

	if (!isMultiple) {
		if (value && !value.start) {
			propsUpdated.value = value;
		}
		if (acceptEmptyValue && !value) {
			propsUpdated.value = null;
		}
	} else {
		propsUpdated.value = null;
	}

	let state = useDatePickerState(propsUpdated);
	let ref = React.useRef();

	let { fieldProps, dialogProps, calendarProps, labelProps } = useDatePicker(
		propsUpdated,
		state,
		ref
	);
	let triggerRef = React.useRef();
	let overlayRef = React.useRef();

	// Get props for the trigger and overlay. This also handles
	// hiding the overlay when a parent element of the trigger scrolls
	// (which invalidates the popover positioning).
	let { triggerProps, overlayProps } = useOverlayTrigger(
		{ type: "dialog" },
		state,
		triggerRef
	);

	// Get popover positioning props relative to the trigger
	let { overlayProps: positionProps } = useOverlayPosition({
		targetRef: triggerRef,
		overlayRef,
		placement: "bottom",
		offset: 5,
		isOpen: displayPaper
	});

	let { buttonProps } = useButton(
		{
			onPress: () => setDisplayPaper(displayPaper => !displayPaper)
		},
		triggerRef
	);

	return (
		<OverlayProvider>
			<div className={cx(styles.datePicker, rootClassName)}>
				{!hideLabel && (
					<label {...labelProps} className={cx(styles.label, labelClassName)}>
						{propsRest.label}
						{tooltip && (
							<GlobalTooltip
								withWrappingDiv={false}
								placement="top"
								overlay={tooltip}
								maxWidth={"180px"}
							>
								<span className="icon-info"></span>
							</GlobalTooltip>
						)}
					</label>
				)}
				{isMultiple && displayPaper ? null : (
					<DropDownInput
						className={inputClassName}
						ref={elt => {
							triggerRef.current = elt;
							if (inputRefProp) {
								inputRefProp.current = elt;
							}
						}}
						{...buttonProps}
						{...triggerProps}
						value={
							<DateField
								isMultiple={isMultiple}
								list={list}
								dateFieldClassName={styles.field}
								{...fieldProps}
								{...(isMultiple && { value: value })}
								onPress={() => setDisplayPaper(displayPaper => !displayPaper)}
							/>
						}
						arrowDirection={displayPaper ? "down" : "top"}
						triggerIcon={triggerIcon}
					/>
				)}

				{displayPaper && !isDisabled && !isMultiple && (
					<OverlayContainer portalContainer={portalContainer}>
						<Popover
							{...dialogProps}
							isOpen={displayPaper}
							onClose={() => setDisplayPaper(false)}
							{...overlayProps}
							{...positionProps}
							ref={overlayRef}
						>
							<div className={styles.calendarContainer}>
								<Calendar
									isMultiple={isMultiple}
									isExpense={isExpense}
									onConfirm={onConfirm}
									list={list}
									{...calendarProps}
								/>
							</div>
						</Popover>
					</OverlayContainer>
				)}

				{isMultiple && displayPaper && (
					<div className={styles.stickyCalendarContainer}>
						<Calendar
							isMultiple={isMultiple}
							isExpense={isExpense}
							onConfirm={onConfirm}
							list={list}
							onToggle={onToggle}
							{...calendarProps}
							isSelectedAll={isSelectedAll}
							className={styles.greyCard}
						>
							{children}
						</Calendar>
						<div className={styles.footer}>
							<Button
								onClick={() => {
									setDisplayPaper();
									onConfirm();
								}}
								className={styles.saveButton}
								text="Confirm date"
							/>
							<Button
								className={styles.cancelButton}
								variant="outlined"
								text="Clear"
								onClick={onClear}
							/>
						</div>
					</div>
				)}
			</div>
		</OverlayProvider>
	);
};

DatePicker.displayName = "DatePicker";
export default forwardRef(DatePicker);
