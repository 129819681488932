import React, { useEffect } from "react";
import { Box, Flex, Text } from "rebass";
import get from "lodash/get";
import { ReactComponent as PersonIcon } from "static/icons/elements-icons-person.svg";
import { ReactComponent as MailIcon } from "static/icons/icon-email.svg";
import { ReactComponent as PhoneIcon } from "static/icons/icon-phone.svg";
import { ReactComponent as EditIcon } from "static/icons/elements-icons-regular-edit-pen.svg";
import { ReactComponent as CheckIcon } from "static/icons/elements-icons-check-mark.svg";
import { ReactComponent as LinkedinIcon } from "static/icons/icons-social-linkedin.svg";
import { ReactComponent as CopyIcon } from "static/icons/elements-icons-copy-file.svg";
import { ReactComponent as ErrorIcon } from "static/icons/elements-icons-error.svg";
import { ReactComponent as CancelIcon } from "static/icons/icons-close.svg";
import PhoneNumberField from "common/PhoneNumberField";
import { InputField } from "../../../candidateDetails/personalInfo";
import MailPhoneSave, {
	SAVE_MAIL,
	SAVE_PHONE_NUMBER,
	SAVE_LINKEDIN_PROFILE
} from "../../../HOC/mailPhoneSave";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "rc-tooltip";
import CopyValue from "./copyValue";

export const TextHeadline = ({ children, mb = 3 }) => (
	<Text
		mb={mb}
		sx={{
			fontSize: "16px",
			color: "#818a92",
			fontWeight: "normal"
		}}
	>
		{children}
	</Text>
);
export const SubTextHeadline = ({ children }) => (
	<Text
		sx={{
			fontSize: "16px",
			color: "#212b36",
			fontWeight: "normal"
		}}
	>
		{children}
	</Text>
);
export const TitleHeadline = ({ children, mb = 3 }) => (
	<Text
		as={"h4"}
		mb={mb}
		sx={{
			fontSize: "16px",
			color: "#212b36",
			fontWeight: 600
		}}
	>
		{children}
	</Text>
);

const PersonalTab = ({
	headline,
	canEdit,
	save,
	mail,
	setPhoneNumber,
	setMail,
	phoneNumber,
	linkedInProfile,
	setLinkedInUrl,
	linkedInUrl,
	isEditMail,
	setIsEditMail,
	isEditPhone,
	setIsEditPhone,
	isEditLinkedIn,
	setIsEditLinkedIn,
	isSaveMailError,
	isSavePhoneError,
	setIsSavePhoneError,
	setIsSaveMailError,
	cancelEdit
}) => {
	const headlineEmail = get(headline, "email");
	useEffect(() => {
		setMail(headlineEmail);
	}, [headlineEmail]);

	const headlinePhone = get(headline, "phone");
	useEffect(() => {
		setPhoneNumber(headlinePhone);
	}, [headlinePhone]);
	useEffect(() => {
		setLinkedInUrl(linkedInProfile);
	}, [linkedInProfile]);

	useEffect(() => {
		return () => {
			cancelEdit();
		};
	}, []);

	return (
		<Box>
			<TitleHeadline>Contact</TitleHeadline>
			<Flex>
				<Box width={1 / 2}>
					<TextHeadline>First name</TextHeadline>
					<Flex
						alignItems="center"
						px={2}
						py={1}
						sx={{
							borderRadius: "4px",
							backgroundColor: "#f2f2f2"
						}}
						width={"90%"}
						minHeight="45px"
					>
						<PersonIcon />
						<Text
							sx={{
								fontSize: "16px",
								color: "#212b36",
								marginLeft: "10px",
								fontWeight: "normal"
							}}
						>
							{headline.first_name}
						</Text>
					</Flex>
				</Box>
				<Box width={1 / 2}>
					<TextHeadline>Last name</TextHeadline>
					<Flex
						alignItems="center"
						px={2}
						py={1}
						sx={{
							borderRadius: "4px",
							backgroundColor: "#f2f2f2"
						}}
						width={"90%"}
						minHeight="45px"
					>
						<PersonIcon />

						<Text
							sx={{
								fontSize: "16px",
								color: "#212b36",
								marginLeft: "10px",
								fontWeight: "normal"
							}}
						>
							{headline.last_name}
						</Text>
					</Flex>
				</Box>
			</Flex>
			<Flex mt={16}>
				<Box width={1 / 2}>
					<TextHeadline>Phone number</TextHeadline>
					<Flex
						alignItems="center"
						justifyContent="space-between"
						px={2}
						py={1}
						sx={{
							borderRadius: "4px",
							backgroundColor: "#f2f2f2"
						}}
						width={"90%"}
						minHeight="45px"
					>
						{isEditPhone ? (
							<React.Fragment>
								<Flex alignItems="center">
									<Box mr={2}>
										<PhoneIcon />
									</Box>
									<PhoneNumberField
										input={{
											onChange: ({ formattedValue }) =>
												setPhoneNumber(formattedValue)
										}}
										classes="view-candidate"
										defaultValue={phoneNumber}
										isErrorBlock={false}
										allowCopy={true}
									/>
								</Flex>
								{!isSavePhoneError ? (
									<button
										onClick={() => {
											headlinePhone === phoneNumber
												? setIsEditPhone(false)
												: save(SAVE_PHONE_NUMBER);
										}}
										style={{
											background: "transparent",
											border: "none"
										}}
									>
										<CheckIcon />
									</button>
								) : (
									<button
										onClick={() => {
											setPhoneNumber(headlinePhone);
											setIsSavePhoneError(false);
											setIsEditPhone(false);
										}}
										style={{
											background: "transparent",
											border: "none"
										}}
									>
										<CancelIcon />
									</button>
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								<Flex alignItems="center">
									<PhoneIcon />
									<Text
										sx={{
											fontSize: "16px",
											color: "#212b36",
											marginLeft: "10px",
											fontWeight: "normal"
										}}
									>
										{phoneNumber ? (
											<CopyValue showCopyButton={"hidden"} text={"0696"}>
												{phoneNumber}
											</CopyValue>
										) : (
											"N/A"
										)}
									</Text>
								</Flex>
								{canEdit && (
									<button
										onClick={() => setIsEditPhone(true)}
										style={{
											background: "transparent",
											border: "none"
										}}
									>
										<EditIcon />
									</button>
								)}
							</React.Fragment>
						)}
					</Flex>
					{isEditPhone && isSavePhoneError && (
						<Flex alignItems="center">
							<ErrorIcon />{" "}
							<Text
								ml={1}
								sx={{
									fontSize: "14px",
									color: "#ff6849",
									fontWeight: "normal"
								}}
							>
								{isSavePhoneError}
							</Text>
						</Flex>
					)}
				</Box>
				<Box width={1 / 2}>
					<TextHeadline>Email address</TextHeadline>

					<Flex
						alignItems="center"
						justifyContent="space-between"
						px={2}
						py={1}
						sx={{
							borderRadius: "4px",
							backgroundColor: "#f2f2f2",
							border: isSaveMailError ? "1px solid #f6511d" : "none"
						}}
						width={"90%"}
						minHeight="45px"
					>
						{isEditMail ? (
							<React.Fragment>
								<Flex width={"85%"} alignItems="center">
									<Box sx={{ marginRight: "2.5px", height: "18px" }}>
										<MailIcon />
									</Box>
									<InputField
										input={{
											value: mail,
											onChange: ({ target: { value } }) => setMail(value)
										}}
									/>
								</Flex>
								{!isSaveMailError ? (
									<button
										onClick={() => {
											headlineEmail === mail
												? setIsEditMail(false)
												: save(SAVE_MAIL);
										}}
										style={{
											background: "transparent",
											border: "none",
											"&[disabled]": {
												cursor: "not-allowed"
											}
										}}
										disabled={mail === ""}
									>
										<CheckIcon />
									</button>
								) : (
									<button
										onClick={() => {
											setMail(headlineEmail);
											setIsSaveMailError(false);
											setIsEditMail(false);
										}}
										style={{
											background: "transparent",
											border: "none"
										}}
									>
										<CancelIcon />
									</button>
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								<Flex width={"80%"} alignItems="center">
									<MailIcon />
									{mail ? (
										<Tooltip
											placement="top"
											overlay={mail}
											trigger="hover"
											overlayClassName="function-tooltip"
										>
											<Text
												sx={{
													fontSize: "16px",
													color: "#212b36",
													marginLeft: "10px",
													padding: "0px",
													fontWeight: "normal",
													height: "26px",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
													width: "90%"
												}}
											>
												{mail && (
													<CopyValue showCopyButton={"hidden"} text={mail}>
														{mail}
													</CopyValue>
												)}
											</Text>
										</Tooltip>
									) : (
										<Text
											sx={{
												fontSize: "16px",
												color: "#212b36",
												marginLeft: "10px",
												padding: "0px",
												fontWeight: "normal",
												height: "26px",
												overflow: "hidden",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis",
												width: "80%"
											}}
										>
											N/A
										</Text>
									)}
								</Flex>
								{canEdit && (
									<button
										onClick={() => setIsEditMail(true)}
										style={{
											background: "transparent",
											border: "none"
										}}
									>
										<EditIcon />
									</button>
								)}
							</React.Fragment>
						)}
					</Flex>
					{isEditMail && isSaveMailError && (
						<Flex alignItems="center">
							<ErrorIcon />{" "}
							<Text
								ml={1}
								sx={{
									fontSize: "14px",
									color: "#ff6849",
									fontWeight: "normal"
								}}
							>
								{isSaveMailError}
							</Text>
						</Flex>
					)}
				</Box>
			</Flex>
			<Box mt={24}>
				<TitleHeadline>LINKS</TitleHeadline>
			</Box>

			<Box width={1 / 2}>
				<TextHeadline>Linkedin profile</TextHeadline>
				<Flex alignItems="center">
					<Flex
						alignItems="center"
						justifyContent="space-between"
						px={2}
						py={1}
						sx={{
							borderRadius: "4px",
							backgroundColor: "#f2f2f2"
						}}
						width={"90%"}
						minHeight="45px"
					>
						{isEditLinkedIn ? (
							<React.Fragment>
								<Flex width={"85%"} alignItems="center">
									<LinkedinIcon />
									<InputField
										input={{
											value: linkedInUrl,
											onChange: ({ target: { value } }) => setLinkedInUrl(value)
										}}
									/>
								</Flex>

								<button
									onClick={() => {
										linkedInProfile === linkedInUrl
											? setIsEditLinkedIn(false)
											: save(SAVE_LINKEDIN_PROFILE);
									}}
									style={{
										background: "transparent",
										border: "none"
									}}
								>
									<CheckIcon />
								</button>
							</React.Fragment>
						) : (
							<React.Fragment>
								<Flex width={"80%"} alignItems="center">
									<LinkedinIcon />
									{linkedInUrl ? (
										<Tooltip
											placement="top"
											overlay={linkedInUrl}
											trigger="hover"
											overlayClassName="function-tooltip"
										>
											<Text
												sx={{
													fontSize: "16px",
													color: "#212b36",
													marginLeft: "10px",
													padding: "0px",
													fontWeight: "normal",
													height: "26px",
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
													width: "80%"
												}}
											>
												{linkedInUrl}
											</Text>
										</Tooltip>
									) : (
										<Text
											sx={{
												fontSize: "16px",
												color: "#212b36",
												marginLeft: "10px",
												padding: "0px",
												fontWeight: "normal",
												height: "26px",
												overflow: "hidden",
												whiteSpace: "nowrap",
												textOverflow: "ellipsis"
											}}
										>
											N/A
										</Text>
									)}
								</Flex>
								{canEdit && (
									<button
										onClick={() => setIsEditLinkedIn(true)}
										style={{
											background: "transparent",
											border: "none"
										}}
									>
										<EditIcon />
									</button>
								)}
							</React.Fragment>
						)}
					</Flex>
					<CopyToClipboard text={linkedInUrl}>
						<button
							style={{
								background: "transparent",
								border: "none",
								marginLeft: "6px"
							}}
						>
							<CopyIcon />
						</button>
					</CopyToClipboard>
				</Flex>
			</Box>
		</Box>
	);
};

// eslint-disable-next-line
export default props => (
	<MailPhoneSave
		active_company={props.active_company}
		refetchProfileData={props.refetchProfileData}
		linkedInProfile={props.linkedInProfile}
		headline={props.headline}
		id={props.profileId}
		render={otherProps => <PersonalTab {...otherProps} {...props} />}
	/>
);
