import React from "react";

import Skills from "modules/cv/components/Tabs/Skills";
import Languages from "modules/cv/components/Tabs/Languages";
import Functions from "modules/job/components/jobprofile/Functions/Functions";
import SkillsFreelancer from "modules/cv/components/Tabs/FreelancerSkills";

import { FunctionsTabContainer, ItemListContainer } from "./Styled";

const FunctionsSkillsTab = ({ functions, skills, languages }) => {
	return (
		<FunctionsTabContainer>
			<Functions
				functions={functions}
				ownCV={false}
				isPermanent={true}
				canUpdateFunctions={false}
				isClient={true}
			/>
			{skills.length === 0 ? (
				<Skills isPermanent={true} ownCV={false} isClient={true} />
			) : (
				<SkillsFreelancer skills={skills} ownCV={false} isClient={true} />
			)}

			{languages.length === 0 ? (
				<Languages isPermanent={true} ownCV={false} isClient={true} />
			) : (
				<ItemListContainer>
					<div className="section-title">Languages</div>
					<div className="items">
						{languages.map(l => (
							<div
								className="item"
								key={l.name}
							>{`${l.name} ( ${l.score} / 5)`}</div>
						))}
					</div>
				</ItemListContainer>
			)}
		</FunctionsTabContainer>
	);
};

export default FunctionsSkillsTab;
