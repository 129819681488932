import React, { useContext } from "react";
import { Link } from "react-router";
import _get from "lodash/get";
import { JobItem } from "./Styled";
import { ReactComponent as CompanyLogo } from "./Icons/EmptyCompany.svg";
import { ReactComponent as PokedIcon } from "./Icons/PokedIcon.svg";
import { ReactComponent as AppliedIcon } from "./Icons/AppliedIcon.svg";
import { ReactComponent as CompanyIcon } from "./Icons/CompanyIcon.svg";
import GlobalTooltip from "common/GlobalTooltip";
import LoadingJob from "./LoadingJob";
import ProfileActions from "common/ProfileActions";
import { ACTION_TYPES, SearchContext } from "common/newSearch/context";
import { REMOTE, REMOTE_LABEL } from "config";
import { convertHTMLtoText, isHTML, removeMarkdown } from "config/helpers";
import { browserHistory } from "react-router";

const JOB_CARD_DESCRIPTION_LENGTH = 40;
const JOB_CARD_MAX_SKILLS = 4;
const MAX_TITLE_LENGTH = 25;

const JobCard = ({
	job,
	index,
	isLoading,
	setSelectedJob,
	setDisplayJobView,
	templateId,
	dispalyHideJob,
	isOnline
}) => {
	const address = `${_get(job, "main_location.country")} ${_get(
		job,
		"main_location.city"
	)}`;
	const locationTypeRemote = `${_get(job, "main_location.type")}` === REMOTE;
	const { dispatch } = useContext(SearchContext);

	const handleJobClick = () => {
		if (isLoading) {
			return;
		}
		setSelectedJob({ ...job, index });
		setDisplayJobView(true);
		browserHistory.push(`/vacancies/${job.id}`);
	};

	const getJobUrl = poke_id => {
		let link = `/pokes/${poke_id}`;
		if (templateId) {
			link += `?template=${templateId}`;
		}
		link += `?search`;

		return link;
	};

	const getActions = id => {
		let actions = [
			{
				key: "hide",
				label: "Hide from this list",
				action: () => {
					dispatch({
						type: ACTION_TYPES.SELECT_JOB_TO_HIDE,
						value: id
					});
					dispalyHideJob();
				},
				icon: <i className="icon-close-vacancy" />
			}
		];

		return actions;
	};
	return (
		<JobItem onClick={handleJobClick}>
			{isLoading ? (
				<LoadingJob />
			) : (
				<>
					<div className="content">
						<div className="head">
							{job.company_avatar ? (
								<>
									<div className="logo">
										<img src={job.company_avatar} />
									</div>
								</>
							) : (
								<div className="empty-logo">
									<CompanyLogo />
								</div>
							)}
							<div
								style={{
									lineHeight: "1.8",
									marginLeft: "10px"
								}}
							>
								<div className="title">
									{job.title.length > MAX_TITLE_LENGTH
										? `${job.title.substr(0, MAX_TITLE_LENGTH)}...`
										: job.title}
								</div>
								<div className="location-date-container">
									<div className="location">
										<span className="icon-local" />
										{locationTypeRemote ? REMOTE_LABEL : address}
									</div>
								</div>
								<div className="company-container">
									<div className="company">
										<CompanyIcon />
										{job.company_name}
									</div>
								</div>
							</div>
							{isOnline && (
								<div
									onClick={e => {
										e.stopPropagation();
									}}
								>
									<ProfileActions options={getActions(job.id)}>
										<div className="dots">
											<div className="dot" />
											<div className="dot" />
											<div className="dot" />
										</div>
									</ProfileActions>
								</div>
							)}
						</div>
						{isHTML(job.description) ? (
							<div className="description">
								{`${convertHTMLtoText(job.description).substr(
									0,
									JOB_CARD_DESCRIPTION_LENGTH
								)}...`}
							</div>
						) : (
							<div className="description">
								{job.description.length > JOB_CARD_DESCRIPTION_LENGTH
									? removeMarkdown(
											`${job.description.substr(
												0,
												JOB_CARD_DESCRIPTION_LENGTH
											)}...`
									  )
									: removeMarkdown(job.description)}
							</div>
						)}

						<div>
							<div className="skills">
								{job.skills.map((skill, index) => {
									return index < JOB_CARD_MAX_SKILLS ? (
										<span key={skill.name} className="skill">
											{skill.name}
										</span>
									) : (
										<></>
									);
								})}

								{job.skills.length > JOB_CARD_MAX_SKILLS ? (
									<span className="more-skills">
										{job.skills.length - JOB_CARD_MAX_SKILLS} more
									</span>
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
					<div className="footer">
						{/* Posted on {formatDate(job.publish_date)} */}
						{job.is_poked && (
							<span className="icons">
								<GlobalTooltip
									placement="top"
									overlayClassName="g-tool-white job-link"
									overlay={
										<>
											You have already been poked for this job.{" "}
											<Link to={getJobUrl(job.poke_id)}>View</Link>{" "}
										</>
									}
								>
									<PokedIcon />
								</GlobalTooltip>
							</span>
						)}
						{job.is_applied && !job.is_poked && (
							<span className="icons">
								<GlobalTooltip
									placement="top"
									overlayClassName="g-tool-white job-link"
									overlay={
										<>
											You have already applied for this job.
											<Link to={getJobUrl(job.poke_id)}>View</Link>{" "}
										</>
									}
								>
									<AppliedIcon />
								</GlobalTooltip>
							</span>
						)}
					</div>
				</>
			)}
		</JobItem>
	);
};

export default JobCard;
