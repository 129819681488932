import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { EXTRACT_TIMESHEET } from "config/api-endpoints";

const extract = body => client(EXTRACT_TIMESHEET, { body });

export const EXTRACT_TIMESHEETS_QUERY_KEY = "EXTRACT_TIMESHEETS_QUERY_KEY";

const useExtractTimesheets = (body, options = {}) => {
	return useQuery([EXTRACT_TIMESHEETS_QUERY_KEY, body], () => extract(body), {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
};

export default useExtractTimesheets;
