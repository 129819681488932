import useViewUserNotes from "modules/candidate/Profile/api/useViewUserNotes";

export default id => {
	return useViewUserNotes(
		{
			id,
			limit: 5
		},
		{ refetchOnWindowFocus: false, enabled: !!id, retry: true }
	);
};
