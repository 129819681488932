import React from "react";
import { useToggle } from "react-use";
import { useQueryCache } from "react-query";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import DescriptionForm from "./DescriptionForm";
import useUpdateCompanyAdditionalInfo from "../../../api/useUpdateCompanyAdditionalInfo";
import toaster from "common/Toaster";
import { getCurrentCompanyQueryKey } from "../../../api/useGetCompany";
import EditableBox from "../EditableBox";
import { excerpt } from "common/Functions";
import s from "./description.module.scss";

const DESCRIPTION_MAX_CHARS = 255;

function Description({ data, isLoading, isFetching }) {
	const [showMore, toggleShowMore] = useToggle(false);
	const isLongDescription = data?.description?.length > DESCRIPTION_MAX_CHARS;
	const queryCache = useQueryCache();
	const [isEdit, toggle] = useToggle(false);
	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(
			object().shape({
				description: string().max(
					2000,
					"Description must be at most 2000 characters"
				)
			})
		)
	});

	const [update, { isLoading: isSaving }] = useUpdateCompanyAdditionalInfo({
		onSuccess() {
			toaster.success("Description updated.");
			queryCache.invalidateQueries([getCurrentCompanyQueryKey]);
			toggle(false);
		}
	});

	return (
		<EditableBox
			name={"description"}
			displayValue={
				isLoading ? (
					<Skeleton count={5} />
				) : (
					<p style={{ whiteSpace: "pre-wrap", fontSize: 13 }}>
						{excerpt(
							data?.description,
							showMore ? data?.description?.length : DESCRIPTION_MAX_CHARS
						) ?? "-"}
						{isLongDescription ? (
							<button
								className={s.showMoreButton}
								onClick={() => toggleShowMore(!showMore)}
								type={"button"}
							>
								{!showMore ? "Show more" : "Show less"}
							</button>
						) : null}
					</p>
				)
			}
			onSave={handleSubmit(update)}
			isLoading={isSaving}
			isFetching={isFetching}
			isEdit={isEdit}
			toggle={toggle}
		>
			<DescriptionForm
				error={errors?.description}
				register={register}
				defaultValue={data?.description}
			/>
		</EditableBox>
	);
}

export default Description;
