import React from "react";
import styles from "./score-badge.module.scss";
import { ReactComponent as LoadingGreenIcon } from "static/icons/loading-green-icon.svg";
import { ReactComponent as LoadingYellowIcon } from "static/icons/loading-yellow-icon.svg";
import { ReactComponent as LoadingRedIcon } from "static/icons/loading-red-icon.svg";

const firstLevel = 34;
const secondLevel = 67;

export default function ScoreBadge({ value }) {
	const getMatchScoreIcon =
		value < firstLevel ? (
			<LoadingRedIcon />
		) : value < secondLevel ? (
			<LoadingYellowIcon />
		) : (
			<LoadingGreenIcon />
		);

	return (
		<div className={styles.countainer}>
			{getMatchScoreIcon} <span>{value || 0}%</span>
		</div>
	);
}
