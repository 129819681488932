import { useQuery } from "react-query";
import { WEATHER_API_KEY } from "config";
import axios from "axios";
export const weatherListKey = "@home/weather";
const listWeather = async (key, { days, ip }) => {
	return axios(
		`https://api.weatherapi.com/v1/forecast.json?key=${WEATHER_API_KEY}&q=${ip}&days=${days}`
	);
};

const useWeather = (options, queryOptions = {}) =>
	useQuery([weatherListKey, options], listWeather, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useWeather;
