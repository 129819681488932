import React from "react";
import Select from "react-select";
import { SelectContainer } from "./style";
import { ReactComponent as ErrorIcon } from "common/react-hook-form-components/icons/elements-icons-error.svg";

export default function CandidateSelect({
	label,
	onChange,
	placeholder,
	value,
	name,
	error,
	clearable = false,
	data
}) {
	return (
		<SelectContainer error={Boolean(error)}>
			{label && <label className="select-label">{label}</label>}
			<Select
				clearable={clearable}
				options={data}
				onChange={onChange}
				value={value}
				name={name}
				placeholder={placeholder}
			/>
			{error && (
				<span className="error">
					<ErrorIcon />
					<span className="text">{error.message}</span>
				</span>
			)}
		</SelectContainer>
	);
}
