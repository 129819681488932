import Styled, { css } from "styled-components";
import { colors } from "config/styles";

export const RequiredDocumentStageItem = Styled.div`
   position: relative;
   background-color: ${colors.white};
   border-radius: 4px;
   box-shadow: 0px 2px 1px 0 rgba(197, 199, 206, 0.25);
   border: solid 0.5px ${colors.grayBorder};
   font-family: "BasierCircle";
   height: 360px;

   .top-section {
      background-color: ${colors.openGrey};

      .title {
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.99px;
        color: rgb(160, 165, 185);
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      .content {
        font-size: 16px;
        color: rgb(15, 28, 70);
      }
   }

   .bottom-section {
     position: absolute;
     bottom: 5px;
     width: 100%;
     button {
       color: #616976;
       border-radius: 4px;
       border: solid 1px #616976;
       background: none;
       padding: 5px 10px;
       font-size: 13px;
       font-weight: 600;
       border-radius: 2px;
       margin: auto;

      span {
        margin-right: 5px
      }
     }
   }
`;

export const DocumentRequirement = Styled.div`
    .document-requirement {
      border: 1px solid ${colors.powderBlue};
      border-radius: 3px;


      .content {
        color: #333;
        font-size: 12.5px;
        text-transform: Capitalize;
        margin-left: 10px;
        span {
          font-size: 10px;
          color: #a0a5b9;
          &.required {
            color: #fd6455
          }
        }
      }

      .menu-toggle {
        background: none;
        border: none;
        color: ${colors.powderBlue};
        font-size: 10px;
        margin: 0;
        padding:0;
        height: 20px;
      }

      .moriz.dots {
        .dot {
          width: 4px;
          height: 4px;
          margin-right: 2px;
        }
      }
    }
`;

export const ProfileTag = Styled.div`
  padding: 2px 5px;
  font-size: 7px;
  font-family: "BasierCircle";
  margin-bottom: 2px;
  ${props =>
		css`
			background-color: ${props.lightColor};
			color: ${props.color};
		`}
`;

export const DocumentMenu = Styled.div`
  position: absolute;
  right: 0;
  top: 110%;
  z-index: 99;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  background: white;
  padding: 7px;
  color: rgb(160, 165, 185);
  font-size: 12px;
  width: 100px;
  min-height: 70px;
  &:after, &:before {
    bottom: 100%;
    left: 80%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-bottom-color: white;
    border-width: 5px;
    margin-left: -5px;
  }
  &:before {
    border-bottom-color: rgba(0, 0, 0, 0.11);
    border-width: 6px;
    margin-left: -6px;
  }

  button {
    border: none;
    background: none;
    display: block;
    width: 100%;
    text-align: left;
    &:hover {
      background-color: #e8f4fc;
    }
  }
`;
