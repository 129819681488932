import React, { Component } from "react";
import { TempRequestDetails } from "./components/TempRequestDetails";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fetchJob from "./actions/JobViewActions";
import { jobViewSelector } from "./selectors/jobSelectors";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { PUBLISHED } from "config";
import ExpiredView from "common/ExpiredView";
import invitExpire from "static/icons/non-available-folder.svg";
import { Link } from "react-router";

export const getJobViewContainerRoute = id => {
	return `/jobs/view/${id || `:id`}`;
};

export const getJobViewContainerPermission = () => {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "consultancy",
				roles: ["admin"]
			}
		]
	};
};

export class TempRequestDetailsModule extends Component {
	componentDidMount() {
		const { params, user } = this.props;
		if (params?.id) {
			this.props.fetchJob(params.id, user.company_type, "en");
		}
	}

	render() {
		const { job, user } = this.props;
		if (!job?.loading && job.data?.status !== PUBLISHED)
			return (
				<ExpiredView icon={invitExpire}>
					<div className="expired-title">{`“ ${job.data?.title} ”`}</div>
					This job offer is no longer available
					<br />
					<Link className="btn btn-primary expired-button" to={"/jobs/search"}>
						Discover other jobs
					</Link>
				</ExpiredView>
			);
		if (job?.data?.status === PUBLISHED)
			return (
				<TempRequestDetails
					data={job.data}
					isLoading={job.isLoading}
					userAccountStatus={user.company_account_status}
				/>
			);
		return <></>;
	}
}

TempRequestDetailsModule.PropTypes = {
	job: PropTypes.object.isRequired,
	fetchJob: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	job: jobViewSelector(state),
	user: onlineUserSelector(state)
});

const mapDispatchToProps = dispatch => ({
	fetchJob: bindActionCreators(fetchJob, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TempRequestDetailsModule);
