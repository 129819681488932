import React from "react";
import s from "./billing-details-form.module.scss";

const BillingDetailsForm = ({ errors, register }) => {
	return (
		<div className={s.root}>
			<h5>Billing Details</h5>
			<div className={s.fieldsContainer}>
				<div>
					<h4>Vat number</h4>
					<input {...register("vat_number")} disabled={true} />
					<div className={s.error}>
						{errors?.msp_contact_person?.first_name?.message}
					</div>
				</div>
				<div>
					<h4>Bic (bank identifier code)</h4>
					<div className={s.input}>
						<input {...register("bic")} />
					</div>
					<div className={s.error}>
						{errors?.msp_contact_person?.first_name?.message}
					</div>
				</div>
				<div>
					<h4>iban (bank identifier code)</h4>
					<div className={s.input}>
						<input {...register("iban")} />
					</div>
					<div className={s.error}>
						{errors?.msp_contact_person?.first_name?.message}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BillingDetailsForm;
