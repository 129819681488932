import { COLUMNS } from "../utils/constants";

export function reduceColumns() {
	return COLUMNS.reduce((acc, { children }) => {
		acc.push(...children);
		return acc;
	}, []);
}
export function findColumn(columns, id) {
	return columns.find(column => column.name === id);
}
