import React, { useState } from "react";

import EditableSection from "modules/candidate/Profile/Pages/Profile/components/EditableSection";
import CvSection from "../PersonalProfile/CvSection";

const PrimaryCV = ({ id, viewFilesMenuItemClicked, personalProfile }) => {
	const [menuAction, setMenuAction] = useState(false);
	const cvDocs = personalProfile?.cv_docs;
	let primaryCv;

	if (cvDocs) {
		primaryCv = cvDocs.find(cd => cd.is_primary);
	}
	return (
		<EditableSection title="Primary CV" hasEdit={false}>
			<CvSection
				setMenuAction={setMenuAction}
				menuAction={menuAction}
				id={id}
				viewFilesClicked={viewFilesMenuItemClicked}
				primaryCv={primaryCv}
			/>
		</EditableSection>
	);
};

export default PrimaryCV;
