import React, { useEffect, useRef, useState } from "react";
import Table from "common/AgTable/AgTable";
import { generateEmptyArray, historyPush } from "config/helpers";
import {
	API_DATA_TYPE,
	TIMESHEETS_PAGE_SIZE
} from "modules/timesheetsModule/utils/constants";
import { useTimesheetsColumns } from "modules/timesheetsModule/hooks/useTimesheetsColumns";
import { formatTimesheetsList } from "modules/timesheetsModule/utils/helpers";
import TimesheetsTableEmptyState from "../TimesheetsTableEmptyState/TimesheetsTableEmptyState";

export default function TimesheetsTable({
	columns: propsColumns,
	data: propsData,
	isLoading,
	isFetching
}) {
	const [timesheetsList, setTimesheetsList] = useState();
	const ref = useRef(null);

	useEffect(() => {
		if (propsData) {
			const list = formatTimesheetsList(propsData);
			setTimesheetsList(list);
		}
	}, [propsData]);

	const columns = useTimesheetsColumns({
		columns: propsColumns,
		isFetching,
		isLoading,
		tableRef: ref
	});

	const onRowClick = ({ column, data }) => {
		const colId = column.colId;
		if (colId === "files") return;
		handleRowClick(data);
	};

	const handleRowClick = data => {
		historyPush(`/timesheets/${data.job_id}/${data.id}`);
	};

	return (
		<Table
			ref={ref}
			columnsSettings={columns}
			rows={
				isLoading || isFetching
					? generateEmptyArray(TIMESHEETS_PAGE_SIZE)
					: timesheetsList
			}
			typeTag={API_DATA_TYPE}
			onCellClicked={onRowClick}
			noRowsOverlayComponent={TimesheetsTableEmptyState}
		/>
	);
}
