import React from "react";
import styled from "styled-components";

const IconWrapper = styled.div`
	.st0 {
		opacity: 0.13;
		fill: #606060;
	}
	.st1 {
		fill: #ffffff;
	}
	.st2 {
		fill: #dedede;
	}
	.st3 {
		fill: #606060;
	}
`;

const EmptyCv = () => (
	<IconWrapper>
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 250.3 163.4"
		>
			<ellipse className="st0" cx="125.6" cy="134.5" rx="122.5" ry="26" />
			<g>
				<g id="XMLID_1588_">
					<g id="XMLID_915_">
						<path
							id="XMLID_1495_"
							className="st1"
							d="M69.7,127.5h87.1c2.7,0,4.9-2.2,4.9-4.9V10.7c0-2.7-2.2-4.9-4.9-4.9H87.6
    c-8.9,8.9-13.9,13.9-22.8,22.8v94C64.8,125.3,67,127.5,69.7,127.5z"
						/>
					</g>
					<g id="XMLID_1723_">
						<path
							id="XMLID_3284_"
							className="st2"
							d="M153.1,96.4l22.3,22.3c2.5,2.5,6.6,2.5,9.2,0l0,0c2.5-2.5,2.5-6.6,0-9.2l-22.3-22.3
    L153.1,96.4z"
						/>

						<rect
							id="XMLID_2069_"
							x="154.5"
							y="90.4"
							transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.0594 141.6293)"
							className="st1"
							width="13"
							height="9.2"
						/>
						<circle
							id="XMLID_3273_"
							className="st2"
							cx="135.3"
							cy="67.5"
							r="32.5"
						/>
					</g>
				</g>
				<g id="XMLID_490_">
					<path
						id="XMLID_1103_"
						className="st3"
						d="M186.3,107.8l-21.5-21.5c3.5-5.4,5.5-11.9,5.5-18.8c0-7.3-2.2-14-6-19.6V10.7
c0-4.1-3.3-7.4-7.4-7.4h-33.6c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5h33.6c1.4,0,2.5,1.1,2.5,2.5V42c-6.3-5.9-14.7-9.6-24-9.6
c-13.3,0-24.8,7.4-30.8,18.3H85c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5h17.3c-1,2.8-1.7,5.8-1.9,8.9H85c-1.4,0-2.5,1.1-2.5,2.5
c0,1.4,1.1,2.5,2.5,2.5h15.3c0.2,3.1,0.8,6.1,1.7,8.9H85c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5h19.1
c1.7,3.3,3.9,6.3,6.5,8.9H85c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5h31.9c5.3,3.3,11.6,5.2,18.4,5.2c5.9,0,11.5-1.5,16.4-4.1
l7.6,7.6v16.5c0,1.4-1.1,2.5-2.5,2.5H69.7c-1.4,0-2.5-1.1-2.5-2.5V30.8h15.1c4.1,0,7.4-3.3,7.4-7.4V8.3h13.6
c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5H87.6c-0.7,0-1.3,0.3-1.7,0.7L63.1,26.8c-0.5,0.5-0.7,1.1-0.7,1.7v94
c0,4.1,3.3,7.4,7.4,7.4h87.1c4.1,0,7.4-3.3,7.4-7.4V111l9.5,9.5c1.7,1.7,4,2.6,6.3,2.6c2.3,0,4.6-0.9,6.3-2.6
c1.7-1.7,2.6-3.9,2.6-6.3C189,111.7,188,109.5,186.3,107.8z M82.4,25.8H71L84.8,12v11.4C84.8,24.7,83.7,25.8,82.4,25.8z
 M105.2,67.5c0-16.6,13.5-30.1,30.1-30.1s30.1,13.5,30.1,30.1c0,16.6-13.5,30.1-30.1,30.1S105.2,84.1,105.2,67.5z M161.8,90.3
l3.6,3.6l-5.7,5.7l-3.9-3.9C158.1,94.1,160.1,92.3,161.8,90.3z M182.8,116.9c-1.6,1.6-4.1,1.6-5.7,0l-13.9-13.9l5.7-5.7l13.9,13.9
c0.8,0.8,1.2,1.8,1.2,2.8C184,115.2,183.6,116.2,182.8,116.9z"
					/>
					<path
						id="XMLID_1107_"
						className="st3"
						d="M129.7,110.4c0.5-0.5,0.7-1.1,0.7-1.7c0-0.7-0.3-1.3-0.7-1.7c-0.5-0.5-1.1-0.7-1.7-0.7
c-0.7,0-1.3,0.3-1.8,0.7c-0.5,0.5-0.7,1.1-0.7,1.7c0,0.7,0.3,1.3,0.7,1.7c0.5,0.5,1.1,0.7,1.8,0.7
C128.6,111.1,129.2,110.9,129.7,110.4z"
					/>
					<path
						id="XMLID_1108_"
						className="st3"
						d="M118.1,111.1c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5H85c-1.4,0-2.5,1.1-2.5,2.5
s1.1,2.5,2.5,2.5H118.1z"
					/>
					<path
						id="XMLID_1109_"
						className="st3"
						d="M113.3,8.3c0.7,0,1.3-0.3,1.7-0.7s0.7-1.1,0.7-1.7S115.5,4.5,115,4s-1.1-0.7-1.7-0.7
S112,3.6,111.5,4c-0.5,0.5-0.7,1.1-0.7,1.7s0.3,1.3,0.7,1.7C112,8,112.6,8.3,113.3,8.3z"
					/>
				</g>
			</g>
		</svg>
	</IconWrapper>
);

export default EmptyCv;
