import React, { useEffect, useState } from "react";
import styles from "./messaging-tool-email-list-header.module.scss";
import { Checkbox } from "common/AgTable/components/Checkbox";
import { ReactComponent as RefreshIcon } from "static/icons/refresh-2.svg";
import { ReactComponent as ChecvronIcon } from "static/icons/chevron-left.svg";
import useEmailsSelection from "../../hooks/useEmailsSelection";
import { queryCache } from "react-query";
import { MESSAGING_TOOL_EMAILS_LIST_KEY } from "common/MessagingToolEmailsList/hooks/useGetEmailsList";
import MessagingToolEmailArchiveButton from "../MessagingToolEmailArchiveButton";
import MessagingToolEmailDeleteButton from "../MessagingToolEmailDeleteButton";
import MessagingToolEmailUnreadButton from "../MessagingToolEmailUnreadButton";
import {
	ACTIONS_BY_VIEW,
	ACTION_SUCCESS_MESSAGE,
	MARK_AS_ARCHIVED_ACTION,
	MARK_AS_READ_ACTION,
	MARK_AS_UNREAD_ACTION,
	PERMANENTLY_DELETE_ACTION,
	SOFT_DELETE_ACTION
} from "common/MessagingToolEmailsList/utils/constant";
import toaster from "common/Toaster";
import useEmailAction from "common/MessagingToolEmailsList/hooks/useEmailAction";
import { LoaderFull } from "common/Loader";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";
import loadable from "loadable-components";
import { Header, Description, Footer } from "common/ConfirmationModal";
import { renderError } from "config/helpers";
const ConfirmationModal = loadable(() => import("common/ConfirmationModal"));
import cx from "classnames";

const MessagingToolEmailsListHeader = ({
	setCurrentPage,
	currentPage,
	numberOfPages,
	total,
	hasActions = true,
	currentPageSelected = false,
	onSelectAll,
	emailsListView = [],
	view = null,
	isRefreshing = false
}) => {
	const { selection, selectMany } = useEmailsSelection();
	const [confirmation, setConfirmation] = useState(null);
	const [isAnimationEnded, setIsAnimationEnded] = useState(true);

	const [mutation, { isLoading: actionIsLoading }] = useEmailAction({
		onSuccess: (_, { action, ids }) => {
			toaster.success(
				ACTION_SUCCESS_MESSAGE[action][ids.length > 1 ? "multiple" : "single"]
			);
			queryCache.invalidateQueries(MESSAGING_TOOL_EMAILS_LIST_KEY);
			selectMany([]);
		},
		onError: renderError
	});

	const onRefresh = () => {
		queryCache.invalidateQueries(MESSAGING_TOOL_EMAILS_LIST_KEY);
	};

	const onAction = ({ action }) => {
		setConfirmation(null);
		mutation({
			ids: selection,
			action
		});
	};

	const actionsByView = ACTIONS_BY_VIEW[view];
	const archiveLabel = actionsByView?.[MARK_AS_ARCHIVED_ACTION];
	const deleteLabel =
		actionsByView?.[SOFT_DELETE_ACTION] ??
		actionsByView?.[PERMANENTLY_DELETE_ACTION];
	const markAsReadLabel = actionsByView?.[MARK_AS_READ_ACTION];
	const markAsUnreadLabel = actionsByView?.[MARK_AS_UNREAD_ACTION];

	const onClickArchiveAction = () => {
		const message =
			selection.length === 1
				? {
						title: "Archive conversation",
						description:
							"Are you sure you want to archive selected conversation?"
				  }
				: {
						title: "Archive all",
						description:
							"Are you sure you want to archive all selected conversations?"
				  };
		setConfirmation({
			...message,
			action: () => onAction({ action: MARK_AS_ARCHIVED_ACTION })
		});
	};

	const onClickDeleteAction = () => {
		const message =
			selection.length === 1
				? {
						title: "Delete conversation",
						description:
							view === MESSAGING_TOOL_LINKS.trash.slug
								? "Are you sure you want to permanently delete selected conversation?"
								: "Are you sure you want to delete selected conversation?"
				  }
				: {
						title: "Delete selected conversations",
						description:
							view === MESSAGING_TOOL_LINKS.trash.slug
								? "Are you sure you want to permanently delete all selected conversations?"
								: "Are you sure you want to delete all selected conversations?"
				  };
		setConfirmation({
			...message,
			action: () =>
				onAction({
					action:
						view === MESSAGING_TOOL_LINKS.trash.slug
							? PERMANENTLY_DELETE_ACTION
							: SOFT_DELETE_ACTION
				})
		});
	};

	const displayActions = () => {
		const selectionHasUnread = selection.some(
			id => emailsListView.find(email => email._id === id)?.unread
		);
		return (
			<>
				{!!archiveLabel && (
					<MessagingToolEmailArchiveButton
						tooltipText={archiveLabel}
						onClick={onClickArchiveAction}
					/>
				)}
				{!!deleteLabel && (
					<MessagingToolEmailDeleteButton
						tooltipText={deleteLabel}
						onClick={onClickDeleteAction}
					/>
				)}
				{!!markAsReadLabel && !!markAsUnreadLabel && (
					<MessagingToolEmailUnreadButton
						tooltipText={
							selectionHasUnread ? markAsReadLabel : markAsUnreadLabel
						}
						isUnread={selectionHasUnread}
						onClick={() =>
							onAction({
								action: selectionHasUnread
									? MARK_AS_READ_ACTION
									: MARK_AS_UNREAD_ACTION
							})
						}
					/>
				)}
			</>
		);
	};

	useEffect(() => {
		if (isRefreshing) {
			setIsAnimationEnded(false);
			setTimeout(() => {
				setIsAnimationEnded(true);
			}, 1000);
		}
	}, [isRefreshing]);

	return (
		<div className={styles.header}>
			<div className={styles.left}>
				{hasActions && (
					<>
						<Checkbox
							checked={currentPageSelected}
							onChange={onSelectAll}
							width={22}
							height={22}
						/>
						<button
							className={cx(styles.refresh, {
								[styles.animated]: isRefreshing || !isAnimationEnded
							})}
							onClick={onRefresh}
						>
							<RefreshIcon width={22} height={22} />
						</button>
						{!!selection.length && (
							<div className={styles.actionsContainer}>{displayActions()}</div>
						)}
						{!!selection.length && (
							<div className={styles.selectionMessage}>
								{selection.length}{" "}
								{selection.length === 1
									? "conversation is"
									: "conversations are"}{" "}
								selected.
							</div>
						)}
					</>
				)}
			</div>
			<div className={styles.right}>
				<span className={styles.paginationDetails}>
					{currentPage + 1}
					{numberOfPages > 1 && `-${numberOfPages}`} of {total}
				</span>
				<button
					className={styles.prev}
					onClick={() => setCurrentPage(prev => (prev - 1 >= 0 ? prev - 1 : 0))}
					disabled={currentPage === 0}
				>
					<ChecvronIcon className={styles.icon} />
				</button>
				<button
					className={styles.next}
					onClick={() => setCurrentPage(prev => prev + 1)}
					disabled={currentPage + 1 === numberOfPages} // currentPage + 1 because currentPage initial state is 0
				>
					<ChecvronIcon className={styles.icon} />
				</button>
			</div>
			{!!confirmation && (
				<ConfirmationModal onClose={() => setConfirmation(null)}>
					<Header title={confirmation.title} />
					<Description>{confirmation.description}</Description>
					<Footer
						onClickLabel={confirmation.title}
						onClose={() => setConfirmation(null)}
						onClick={() => confirmation.action()}
						showDeleteIcon={false}
					/>
				</ConfirmationModal>
			)}
			{actionIsLoading && <LoaderFull />}
		</div>
	);
};

export default MessagingToolEmailsListHeader;
