import { client } from "lib/api-client";
import { CANDIDATE_DATA_BY_COLUMN_ENDPOINT } from "config/api-endpoints";
import { utcTimeStamp } from "config/helpers";
import get from "lodash/get";
import isNumber from "lodash/isNumber";

export const isFiltersFull = filters => {
	let list = [];
	if (filters) {
		list = filters.filter(({ value }) => !isFilterEmpty(value));
	}
	return list.length > 0;
};

export const isFilterEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};

export const getFieldValues = ({ tag, search = "" }) => {
	return client(CANDIDATE_DATA_BY_COLUMN_ENDPOINT, {
		body: {
			list: "bids",
			tag,
			search
		}
	});
};

export function formatStatusOptions(data, label = "status") {
	const dataFormatted = data.map(item => {
		const { value, label } = item;
		return { label, id: value };
	});
	return [{ id: 1, label, children: dataFormatted }];
}
export function formatItemsWithoutParent(items, label) {
	const itemsFormatted = items.map(item => {
		const { name, _id } = item;

		return {
			label: name,
			id: _id
		};
	});

	const parent = [{ id: 1, label, children: itemsFormatted }];

	return parent;
}

export function fomatSeniorities(seniorities) {
	const seniorityFormatted = seniorities.map(seniority => {
		const { label, value } = seniority;

		return {
			label,
			id: value
		};
	});

	const parent = [
		{ id: 1, label: "Seniorities", children: seniorityFormatted }
	];

	return parent;
}

export function formatFunctions(parents) {
	return parents.map(parent => {
		const { functions, id, name } = parent;

		const children = functions.map(({ id, name }) => {
			return { label: name, id };
		});

		const newParent = {
			id,
			label: name,
			children
		};

		return newParent;
	});
}

export function formatDataTypeOptions(data, label) {
	const dataFormatted = data.map(item => {
		const { label, value } = item;

		return {
			label,
			id: value
		};
	});

	const parent = [{ id: 1, label, children: dataFormatted }];

	return parent;
}

export function formatDataTypeInterview(data, label) {
	const dataFormatted = data.map(item => {
		const { label, value } = item;

		return {
			label,
			id: value
		};
	});

	const parent = [{ id: 1, label, children: dataFormatted }];

	return parent;
}

export function formatCreatedByArray(createdByArray) {
	const createdByFormatted = createdByArray.map(createdByItem => {
		const { _id, full_name } = createdByItem;

		return {
			label: `${full_name}`,
			id: _id
		};
	});

	const parent = [{ id: 1, label: "Created by", children: createdByFormatted }];

	return parent;
}

function reduceItems(items) {
	return items.reduce((acc, item) => [...acc, ...item.children], []);
}

const formatFilter = filter => {
	const { name, value, operator } = filter;
	const ids = reduceItems(value || []).map(({ id }) => id);

	return {
		field: name,
		value: ids,
		op: operator
	};
};

const convertCalendarDateToMS = date => {
	const { year, month, day } = date;
	return new Date(`${year}/${month}/${day}`);
};

const formatFilterTypeDate = filter => {
	const { value, operator, name } = filter;

	if (!value) {
		return {
			field: name,
			value: null,
			op: operator
		};
	}
	let valueFormatted;

	if (!value.start) {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value),
			isStart: false
		});
		valueFormatted = [startDate, endDate];
	} else {
		const startDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.start)
		});

		const endDate = utcTimeStamp({
			date: convertCalendarDateToMS(value.end),
			isStart: false
		});

		valueFormatted = [startDate, endDate];
	}

	return {
		field: name,
		value: valueFormatted,
		op: operator
	};
};

export const isEmpty = value => {
	return (
		value == null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
	);
};

const formatTypeLocation = (filter, forSearch = false) => {
	const { value, operator, name } = filter;
	const newValue = value || [];

	const valuesFormatted = newValue.map(({ place, radius }) => {
		const address = {};

		place.address_components.map(elem => {
			if (elem.types[0] === "country") {
				address[elem.types[0]] = elem.long_name;
				address.iso_country = elem.short_name;
				return;
			}
			return (address[elem.types[0]] = elem.long_name);
		});

		address.latitude = place.geometry.location.lat();
		address.longitude = place.geometry.location.lng();
		address.street =
			address.route ||
			address.neighborhood ||
			address.premise ||
			address.sublocality_level_1 ||
			address.sublocality_level_2 ||
			address.sublocality_level_3 ||
			address.sublocality_level_4 ||
			address.sublocality_level_5 ||
			address.subpremise ||
			address.sublocality ||
			address.jpns ||
			"";
		address.country = address.country || "";
		address.is_main = false;
		address.zip = address.postal_code || "";
		address.latitude = address.latitude || "";
		address.longitude = address.longitude || "";
		address.city =
			address.locality ||
			address.administrative_area_level_1 ||
			address.administrative_area_level_2 ||
			address.administrative_area_level_3 ||
			address.administrative_area_level_4 ||
			address.administrative_area_level_5 ||
			"";
		address.number = address.street_number || "";
		address.box = address.box || "";
		address.iso_country = address.iso_country || "";

		const values = place?.geometry?.viewport;
		let viewport = null;

		if (values) {
			viewport = {
				northeast: {
					lat: values.getNorthEast().lat(),
					lng: values.getNorthEast().lng()
				},
				southwest: {
					lat: values.getSouthWest().lat(),
					lng: values.getSouthWest().lng()
				}
			};
		}

		const typesSupportedAutoComplete = [
			"locality",
			"sublocality",
			"postal_code",
			"country",
			"administrative_area_level_1",
			"administrative_area_level_2",
			"administrative_area_level_3",
			"locality",
			"political"
		];

		let isSendViewport = false;

		typesSupportedAutoComplete.forEach(type => {
			if (place.types.includes(type)) isSendViewport = true;
		});

		return {
			zip: address.zip,
			country: address.country,
			number: address.number,
			iso_country: address.iso_country,
			city: address.city,
			street: address.street,
			latitude: address.latitude,
			is_main: address.is_main,
			box: address.box,
			longitude: address.longitude,
			distance_unit: "km",
			distance: parseInt(radius),
			viewport: isSendViewport ? viewport : [],
			place_id: forSearch ? place.place_id : undefined
		};
	});

	return {
		value: valuesFormatted.length ? valuesFormatted[0] : null,
		field: name,
		op: operator
	};
};

const formatFieldProfileType = filter => {
	const { value, name, operator } = filter;
	return {
		field: name,
		op: operator,
		value: get(value, "[0].children").map(v => v.id)
	};
};

const formatTypeScore = filter => {
	const { value, name, operator } = filter;

	return {
		field: name,
		op: operator,
		value: isNumber(value) ? value / 100 : value
	};
};
const formatTypeAmount = filter => {
	const { value, name, operator } = filter;

	return {
		field: name,
		op: operator,
		value: value
	};
};

export const formatFilters = filters => {
	const definedFilters = filters.filter(({ value }) => {
		return !isEmpty(value);
	});
	const filtersFormatted = definedFilters.map(filter => {
		const { name, operator } = filter;
		let filterFormatted;
		if (name === "matching_score") {
			filterFormatted = formatTypeScore(filter);
		} else if (name === "amount") {
			filterFormatted = formatTypeAmount(filter);
		} else if (name === "application_date") {
			filterFormatted = formatFilterTypeDate(filter);
		} else if (
			name === "profile_type" ||
			name === "availability" ||
			name === "time_commitment" ||
			name === "rate_type"
		) {
			filterFormatted = formatFieldProfileType(filter);
		} else if (
			name === "status" ||
			name === "subcategory" ||
			name === "function" ||
			name === "seniority" ||
			name === "category"
		) {
			filterFormatted = formatFilter(filter);
		} else if (name === "company_address") {
			filterFormatted = formatTypeLocation(filter);
		} else {
			filterFormatted = {
				value: filter.value,
				field: name,
				op: operator
			};
		}

		return filterFormatted;
	});

	return filtersFormatted;
};
