import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { HIDE_PROFILE_ENDPOINT } from "config/api-endpoints";

function hidePremanently(body) {
	return client(HIDE_PROFILE_ENDPOINT, {
		body
	});
}

function useHidePremanently(options) {
	return useMutation(hidePremanently, options);
}

export default useHidePremanently;
