import { SENT, CANCELLED, ACCEPTED, DONE } from "config";
import get from "lodash/get";
import moment from "moment";
import React from "react";
import EmptyState from "./EmptyState";
import InterviewStatus from "./InterviewStatus";
import LoadingState from "./LoadingState";

const UpcomingInterviews = ({ data, isLoading }) => {
	const renderDetail = (status, item) => {
		switch (status) {
			case SENT:
				return "Awaiting response";

			case CANCELLED:
				break;
			case ACCEPTED:
				return `${moment
					.unix(item.new_interview_start_date)
					.format("HH:mm")} - ${moment
					.unix(item.new_interview_end_date)
					.format("HH:mm")}`;
			case DONE:
				break;
			default:
				break;
		}
	};
	return (
		<div>
			<div className="upcoming-interview-title">
				<span className="headline">Upcoming</span>
				<span className="subtitle">All your planned interviews</span>
			</div>
			{isLoading ? (
				new Array(3).fill(<LoadingState />)
			) : get(data, "length", 0) > 0 ? (
				data.map(item => (
					<InterviewStatus
						key={item._id}
						label={item.full_name}
						date={moment
							.unix(item.new_interview_start_date)
							.format("DD/MM/YYYY")}
						detail={renderDetail(item.status, item)}
						color="#00BA88"
						status={item.status}
					/>
				))
			) : (
				<EmptyState />
			)}
		</div>
	);
};

export default UpcomingInterviews;
