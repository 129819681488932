import React, { useMemo, useState } from "react";

import _get from "lodash/get";
import Editor from "common/Editor";
import FieldError from "./components/FieldError";
import { RenderFieldElementByType } from "./helpers";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./evaluation-template-render-drawer.module.scss";
import EvaluationScoreButton from "./components/EvaluationScoreButton";
import { COMMENT_MAX_LENGTH, EVALUATION_SCORES_ITEMS } from "./constants";
import { ELEMENT_TYPES } from "modules/EvaluationFormsSettings/utils/constants";
import { getHTMLTextLength } from "config/helpers";

function EvaluationTemplateRender({ children }) {
	return <div className={styles.templateContainer}>{children}</div>;
}

function Header({ templateTitle, children, label = "Description" }) {
	return (
		<div className={styles.templateHeader}>
			<h3 className={styles.templateTitle}>{templateTitle}</h3>
			{children && (
				<div className={styles.templateDescriptionContainer}>
					<p className={styles.templateDescriptionLabel}>{label}</p>
					<div className={styles.templateDescriptionCard}>{children}</div>
				</div>
			)}
		</div>
	);
}
function Body({ children }) {
	return <div className={styles.formBodyContainer}>{children}</div>;
}
function Footer({ children }) {
	return <div className={styles.formFooterContainer}>{children}</div>;
}
function FieldsList({ elements }) {
	return (
		<>
			{elements.map((element, idx) => {
				if (element.type === ELEMENT_TYPES.section) {
					return (
						<div key={idx} className={styles.sectionContainer}>
							<h3 className={styles.sectionTitle}>{element.title}</h3>
							{element.items.map((sub_element, sub_element_idx) =>
								RenderFieldElementByType({
									element: sub_element,
									sectionIndex: idx.toString(),
									index: sub_element_idx.toString()
								})
							)}
						</div>
					);
				}
				return RenderFieldElementByType({
					element,
					index: idx.toString()
				});
			})}
		</>
	);
}
function ScoreField({ name, label, ...restProps }) {
	const {
		control,
		formState: { errors }
	} = useFormContext();

	return (
		<div className={styles.fieldContainer}>
			<label className={styles.fieldLabel}>
				{label}
				<span className={styles.required}>*</span>
			</label>
			<Controller
				{...restProps}
				name={name}
				control={control}
				render={({ field: { onChange, value } }) => {
					return (
						<div className={styles.scoreRatingContainer}>
							{EVALUATION_SCORES_ITEMS.map(item => {
								const isActive = item.value === value;
								return (
									<EvaluationScoreButton
										key={item.value}
										className={{ [item.active_classname]: isActive }}
										onClick={() => onChange(!isActive ? item.value : "")}
									>
										{item.icon} {item.label}
									</EvaluationScoreButton>
								);
							})}
						</div>
					);
				}}
			/>
			<FieldError error={_get(errors, name)} />
		</div>
	);
}
function NoteField({ name, label, placeholder, ...restProps }) {
	const {
		watch,
		control,
		formState: { errors }
	} = useFormContext();

	const [showToolbaar, setShowToolbar] = useState(false);
	const editorTextLength = useMemo(
		() => (watch(name) ? getHTMLTextLength(watch(name)) : 0),
		[watch(name)]
	);

	return (
		<div className={styles.fieldContainer}>
			<label className={styles.fieldLabel}>{label}</label>
			<Controller
				{...restProps}
				name={name}
				control={control}
				render={({ field: { onChange, value } }) => (
					<Editor
						value={value}
						onChange={onChange}
						useMentionPlugin={false}
						placeholder={placeholder}
						showToolbaar={showToolbaar}
						setShowToolbar={setShowToolbar}
						scrollClassName={styles.editorInput}
						focusClassName={styles.editorInputFocused}
						contentEditorClassName={styles.editorContent}
						editorMaxLength={COMMENT_MAX_LENGTH}
						textLengthClassName={
							editorTextLength > COMMENT_MAX_LENGTH && styles.editorTextError
						}
						editorTextLength={!isNaN(editorTextLength) && editorTextLength}
					/>
				)}
			/>
			<FieldError error={_get(errors, name)} />
		</div>
	);
}
function Button({
	children,
	isDisabled = false,
	isDirtyEnabled = false,
	...restProps
}) {
	const {
		formState: { isDirty }
	} = useFormContext();

	return (
		<button disabled={isDirtyEnabled ? !isDirty : isDisabled} {...restProps}>
			{children}
		</button>
	);
}

EvaluationTemplateRender.Header = Header;
EvaluationTemplateRender.FieldsList = FieldsList;
EvaluationTemplateRender.ScoreField = ScoreField;
EvaluationTemplateRender.NoteField = NoteField;
EvaluationTemplateRender.Footer = Footer;
EvaluationTemplateRender.Body = Body;
EvaluationTemplateRender.Button = Button;

export default EvaluationTemplateRender;
