import { DEFAULT_USER_NAME } from "config";

export function getInitials(name, fallback = DEFAULT_USER_NAME) {
	if (!name || typeof name !== "string") name = fallback;
	return name
		.replace(/\s+/, " ")
		.split(" ")
		.slice(0, 2)
		.map(v => v && v[0].toUpperCase())
		.join("");
}

export function hashCode(s) {
	const str = String(s);
	let hash = 0;
	let char;
	if (str.trim().length === 0) return hash;
	for (let i = 0; i < str.length; i++) {
		char = str.charCodeAt(i);
		hash = (hash << 5) - hash + char;
		// Convert to 32bit integer
		hash &= hash;
	}
	return Math.abs(hash);
}
