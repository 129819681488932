import React from "react";
import { ReactComponent as DotsIcon } from "static/images/notes/dots-vertical.svg";
import { ReactComponent as ArchiveIcon } from "static/icons/archive.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-2.svg";
import { ReactComponent as EmailOpenIcon } from "static/icons/email-open.svg";
import { ReactComponent as EmailIcon } from "static/icons/email.svg";
import { ReactComponent as CornerUpLeftIcon } from "static/icons/corner-up-left.svg";
import { ReactComponent as CornerUpRightIcon } from "static/icons/corner-up-right.svg";
import styles from "./messaging-tool-email-view-conversation-actions.module.scss";
import cx from "classnames";
import {
	MenuTrigger,
	Popover,
	Menu,
	Button,
	MenuItem
} from "react-aria-components";
import useEmailAction from "common/MessagingToolEmailsList/hooks/useEmailAction";
import {
	MARK_AS_ARCHIVED_ACTION,
	MARK_AS_UNREAD_ACTION,
	MARK_AS_READ_ACTION,
	SOFT_DELETE_ACTION,
	REPLY_ACTION,
	FORWARD_ACTION,
	PERMANENTLY_DELETE_ACTION
} from "common/MessagingToolEmailsList/utils/constant";
import { ACTION_SUCCESS_MESSAGE } from "common/MessagingToolEmailsList/utils/constant";
import { renderError } from "config/helpers";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import { ACTIONS } from "common/MessagingToolEmailView/utils/constants";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";

export default function MessagingToolEmailViewConversationActions({
	onBack,
	emailData,
	onClickReply,
	onClickForward,
	hash
}) {
	const [mutation, { isLoading: actionIsLoading }] = useEmailAction({
		onSuccess: (_, { action }) => {
			toaster.success(ACTION_SUCCESS_MESSAGE[action].single);
			onBack();
		},
		onError: renderError
	});

	const onAction = action => {
		switch (action) {
			case REPLY_ACTION:
				onClickReply?.({
					action: ACTIONS.REPLY_TO_ALL,
					emailId: emailData.message_id
				});
				break;
			case FORWARD_ACTION:
				onClickForward?.({ emailId: emailData._id });
				break;
			case MARK_AS_READ_ACTION:
				mutation({ action, ids: [emailData._id] });
				break;
			case MARK_AS_UNREAD_ACTION:
				mutation({ action, ids: [emailData._id] });
				break;
			case MARK_AS_ARCHIVED_ACTION:
				mutation({ action, ids: [emailData._id] });
				break;
			case SOFT_DELETE_ACTION:
			case PERMANENTLY_DELETE_ACTION:
				mutation({ action, ids: [emailData.parent_id || emailData._id] });
				break;
		}
	};

	return (
		<MenuTrigger>
			<Button className={cx(styles.cta, styles.actions)}>
				<DotsIcon width={20} height={20} />
			</Button>
			<Popover className={styles.popover}>
				<Menu onAction={onAction} className={styles.menu}>
					<MenuItem id={REPLY_ACTION} className={styles.menuItem}>
						<CornerUpLeftIcon width={16} height={16} />
						<span>Reply to all</span>
					</MenuItem>
					<MenuItem id={FORWARD_ACTION} className={styles.menuItem}>
						<CornerUpRightIcon width={16} height={16} />
						<span>Forward</span>
					</MenuItem>
					{emailData?.unread ? (
						<MenuItem id={MARK_AS_READ_ACTION} className={styles.menuItem}>
							<EmailOpenIcon width={16} height={16} />
							<span>Mark as read</span>
						</MenuItem>
					) : (
						<MenuItem id={MARK_AS_UNREAD_ACTION} className={styles.menuItem}>
							<EmailIcon width={16} height={16} />
							<span>Mark as unread</span>
						</MenuItem>
					)}
					<MenuItem id={MARK_AS_ARCHIVED_ACTION} className={styles.menuItem}>
						<ArchiveIcon width={16} height={16} />
						<span>Archive</span>
					</MenuItem>
					<MenuItem
						id={
							hash === MESSAGING_TOOL_LINKS.trash.slug
								? PERMANENTLY_DELETE_ACTION
								: SOFT_DELETE_ACTION
						}
						className={styles.menuItem}
					>
						<TrashIcon width={16} height={16} />
						<span>Delete</span>
					</MenuItem>
				</Menu>
			</Popover>
			{actionIsLoading && <LoaderFull />}
		</MenuTrigger>
	);
}
