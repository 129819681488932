import React, { useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import FeedbackModal from "./FeedbackModal";
//components
import GlobalTooltip from "common/GlobalTooltip";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { formatDate } from "common/Functions";

//config
import {
	DATE_FORMAT_LLLL,
	TWO_HUNDRED,
	CLIENT,
	BID,
	REJECTED,
	DAILY,
	HOURLY,
	CURRENCY_SUFFIX
} from "config";
import Flag from "static/icons/flag.svg";
import Description from "common/Description";
import Fee from "./client/Fee";
import StatusLabel from "common/StatusLabel";
import AttachedFiles from "common/AttachedFiles";
import ToolTip from "common/GlobalTooltip";

const BidDetails = ({
	visible_status,
	status,
	created_at,
	proposed_amount,
	margin_value,
	available_date,
	description,
	get_status_tooltip,
	flag,
	maxWidth,
	type: userType,
	stepperStatus,
	bid_details
}) => {
	const isClient = userType === CLIENT;
	const isBid = stepperStatus === BID;
	const files = _get(bid_details, "files", []);

	const [showMore, setShowMore] = useState(false);

	return (
		<div className="section">
			<div className="flagwrapper">
				<div className={`section-title ${isBid ? "flag" : "no-flag"}`}>
					{isBid && <img src={Flag} alt="Flag" className="img" />}
					<div className="text">{flag}</div>
				</div>

				{isBid && (
					<GlobalTooltip
						placement="top"
						eventTrigger="hover"
						maxWidth={maxWidth}
						overlay={<span>{get_status_tooltip}</span>}
					>
						<StatusLabel
							position="absolute"
							right={0}
							status={status}
							label={visible_status}
							px={15}
						/>
					</GlobalTooltip>
				)}
			</div>

			<div className="subtitle">creation date</div>
			<div className="row-content">
				{formatDate(created_at, true, DATE_FORMAT_LLLL)}
			</div>

			<div className="wrapper flexColumn">
				<div className="gridColumn">
					<div className="subtitle">
						{`${_get(
							bid_details,
							"reporting_settings.rate_type",
							HOURLY
						)} rate`}
						{_get(bid_details, "reporting_settings.rate_type", HOURLY) ===
							DAILY && (
							<ToolTip
								placement={"right"}
								maxWidth={"250px"}
								align={{
									offset: [15, 0]
								}}
								overlay={`${_get(
									bid_details,
									"reporting_settings.hours_representation"
								).replace(":", "h")} of work time per day`}
							/>
						)}
					</div>
					<div className="row-content">
						<CurrencyFormatterRender
							isCent
							value={proposed_amount}
							suffix={
								CURRENCY_SUFFIX[
									_get(bid_details, "reporting_settings.rate_type", HOURLY)
								]
							}
							currency={_get(bid_details, "reporting_settings.currency.code")}
						/>{" "}
						{isClient && (
							<Fee
								marginValue={margin_value}
								reportingSettings={_get(bid_details, "reporting_settings")}
							/>
						)}
					</div>
				</div>
				<div className="gridColumn">
					<div className="subtitle">available from</div>
					<div className="row-content">{formatDate(available_date)}</div>
				</div>
			</div>

			<div className="subtitle">motivation</div>
			<div className="content-description">
				<Description
					description={description}
					max={TWO_HUNDRED}
					parseMarkdown={true}
				/>
			</div>
			{files.length > 0 ? <AttachedFiles files={files} /> : <></>}

			{status === REJECTED && (
				<FeedbackModal
					active={showMore}
					onClose={() => setShowMore(false)}
					text={get_status_tooltip}
				/>
			)}
		</div>
	);
};

BidDetails.propTypes = {
	status: PropTypes.string.isRequired,
	created_at: PropTypes.number.isRequired,
	proposed_amount: PropTypes.number.isRequired,
	margin_value: PropTypes.number.isRequired,
	available_date: PropTypes.number.isRequired,
	description: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
		.isRequired,
	get_status_tooltip: PropTypes.string.isRequired,
	flag: PropTypes.string.isRequired,
	maxWidth: PropTypes.string,
	type: PropTypes.string.isRequired
};

BidDetails.defaultProps = {
	maxWidth: "400px",
	margin_value: 0
};

export default BidDetails;
