import React from "react";
import styles from "./smart-query-filter-recap.module.scss";
import { getOperatorLabel } from "common/SmartFilterDrawer/utils/format_utils";
import { OPERATORS } from "common/SmartFilterDrawer/utils/constant";
import { renderFilterValues } from "common/SmartFilterDrawer/utils/helpers";

const SmartQueryFilterRecap = ({ filter }) => {
	return (
		<div className={styles.filterRecapItem}>
			{(filter.isOperator || filter.isParentheses) && (
				<span className={styles.label}>{filter.label}</span>
			)}
			{!!filter.name && (
				<>
					<span className={styles.label}>{filter.label}</span>
					<span className={styles.operator}>
						{getOperatorLabel(OPERATORS, filter.operator)}
					</span>
					<div className={styles.value}>
						{renderFilterValues(filter.name, filter.value)}
					</div>
				</>
			)}
		</div>
	);
};

export default SmartQueryFilterRecap;
