import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Loading, TitleSkeleton } from "../styled";

function ChartLoading({ className }) {
	return (
		<>
			<Loading className={className}>
				<TitleSkeleton>
					<SkeletonTheme color="#d8dce2">
						<Skeleton width={120} height={8} />
					</SkeletonTheme>
				</TitleSkeleton>

				<div className="row">
					<div className="col-md-5 SkeletonBlocksLeft">
						<SkeletonTheme color="#d8dce2">
							<Skeleton width={77} height={8} />

							<Skeleton width={59} height={8} />

							<Skeleton width={92} height={8} />

							<Skeleton width={67} height={8} />

							<Skeleton width={77} height={8} />
						</SkeletonTheme>
					</div>
					<div className="col-md-7 SkeletonBlocks SkeletonBlocks SkeletonBlocksRight">
						<SkeletonTheme color="#d8dce2">
							<Skeleton width={130} height={20} />

							<Skeleton width={164} height={20} />

							<Skeleton width={110} height={20} />

							<Skeleton width={127} height={20} />

							<Skeleton width={150} height={20} />
						</SkeletonTheme>
					</div>
				</div>
			</Loading>
		</>
	);
}

export default ChartLoading;
