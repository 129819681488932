import { useQuery } from "react-query";
import { ENHANCE_JOB_POSTING_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";

const enhanceText = body =>
	client(ENHANCE_JOB_POSTING_ENDPOINT, {
		body
	});

export const ENHANCE_TEXT_QUERY_KEY = "ENHANCE_TEXT_QUERY_KEY";

export const useEnhanceText = (body, options = {}) => {
	const res = useQuery(
		[ENHANCE_TEXT_QUERY_KEY, body],
		() => enhanceText(body),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...options
		}
	);

	return res;
};
