import Immutable from "seamless-immutable";
import * as types from "../actions/actionTypes";
import {
	CREATE_USER,
	USERS_TAB,
	STEP_CREATE_DEPARTMENT,
	STEP_DEPARTMENT_SUCCESS,
	SUCCESS_USER_CREATED
} from "config";

const initialState = Immutable({
	users: {
		groups_list: [],
		users_list: []
	},
	departments: {},
	showModal: false,
	step: CREATE_USER,
	user: null,
	loading: true,
	modalLoading: false,
	modalErrorMsg: "",
	exists: false,
	modalSuccess: false,
	isEdit: false,
	view: USERS_TAB,
	departmentModal: {
		show: false,
		step: STEP_CREATE_DEPARTMENT
	},
	showSuccessModalDepartment: false
});

export default function(state = initialState, action) {
	switch (action.type) {
		case types.TOGGLE_FETCHING:
			return Immutable.merge(state, { loading: !state.loading });

		case types.TOGGLE_MODAL_FETCHING:
			return Immutable.merge(state, { modalLoading: !state.modalLoading });

		case types.SET_MODAL_ERROR:
			return Immutable.merge(state, { modalErrorMsg: action.errorMsg });

		case types.PUT_USERS:
			return Immutable.merge(state, { users: action.users, loading: false });

		case types.PUT_DEPARTMENTS:
			return Immutable.merge(state, {
				departments: action.departments,
				loading: false
			});

		case types.MOVE_TO_STEP:
			return Immutable.merge(state, {
				step: action.step,
				user: { ...state.user, ...action.user }
			});

		case types.SET_EMAIL_EXISTS:
			return Immutable.merge(state, { exists: true });

		case types.SHOW_MODAL:
			return Immutable.merge(state, {
				showModal: true,
				step: action.step,
				user: action.user,
				isEdit: action.isEdit
			});

		case types.CLOSE_MODAL:
			return Immutable.merge(state, {
				showModal: false,
				exists: false,
				user: null,
				modalErrorMsg: "",
				modalSuccess: false
			});

		case types.SET_SUCCESS:
			return Immutable.merge(state, {
				modalSuccess: true,
				step: SUCCESS_USER_CREATED
			});
		case types.TOGGLE_DEPARTMENT_MODAL_SUCCESS:
			return Immutable.merge(state, {
				showSuccessModalDepartment: !state.showSuccessModalDepartment
			});
		case types.SWITCH_USER_VIEW:
			return Immutable.merge(state, { view: action.view });

		case types.TOGGLE_DEPARTMENT_MODAL:
			let newDepartmentModal = {
				step: STEP_CREATE_DEPARTMENT,
				show: !state.departmentModal.show
			};

			if (action.department._id)
				newDepartmentModal = {
					...newDepartmentModal,
					...action.department
				};

			return Immutable.merge(state, {
				departmentModal: newDepartmentModal
			});

		case types.CHANGE_DEPARTMENT_MODAL_STEP:
			return Immutable.merge(state, {
				departmentModal: {
					...state.departmentModal,
					step: STEP_DEPARTMENT_SUCCESS
				}
			});

		default:
			return state;
	}
}
