import React from "react";
import { Box, Flex, Text } from "rebass";

const statusBackground = {
	disputed: "#F9A937",
	draft: "#A0A3BD",
	submitted: "#1F5DC6",
	in_mediation: "#f75356",
	approved: "#36ba90",
	accepted: "#36ba90",
	edited: "#FD6950"
};
export const statusLabel = {
	disputed: "disputed",
	draft: "draft",
	submitted: "submitted",
	in_mediation: "In mediation",
	approved: "approved",
	accepted: "approved",
	edited: "edited"
};

const WorktimeStatus = ({ status }) => {
	return (
		<Flex alignItems="center">
			<Box
				sx={{
					background: statusBackground[status],
					height: "11px",
					width: "11px",
					borderRadius: "50%",
					marginRight: "5px"
				}}
			/>
			<Text
				sx={{
					fontFamily: "BasierCircle",
					fontStyle: "normal",
					fontWeight: 600,
					fontSize: "12px",
					lineHeight: "22px",
					color: statusBackground[status],
					textTransform: "capitalize"
				}}
			>
				{statusLabel[status]}
			</Text>
		</Flex>
	);
};

export default WorktimeStatus;
