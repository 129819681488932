import React from "react";
import { CreateVacancySkeletonCard } from "../CreateVacancySkeletonCard";
import { CreateVacancyCard } from "../CreateVacancyCard";
import { CreateVacancyCardContent } from "../CreateVacancyCardContent";
import { CreateVacancyFieldContainer } from "../CreateVacancyFieldContainer";
import { CreateVacancyFieldLabel } from "../CreateVacancyFieldLabel";
import { CreateVacancyLabelContainer } from "../CreateVacancyLabelContainer";
import { CreateVacancyCardTitle } from "../CreateVacancyCardTitle";
import styles from "./add-vacancy-additional-info-card-loading.module.scss";

const AddVacancyAdditionalInfoCardLoading = () => {
	return (
		<CreateVacancyCard>
			<CreateVacancyCardTitle>Additional info</CreateVacancyCardTitle>
			<CreateVacancyCardContent>
				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel>Due date</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<CreateVacancySkeletonCard width="30%" />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyFieldLabel displayStar={false}>
						Job address
					</CreateVacancyFieldLabel>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
				<CreateVacancyFieldContainer>
					<CreateVacancyFieldLabel displayStar={false}>
						Featured image
					</CreateVacancyFieldLabel>
					<div className={styles.grid2Columns}>
						<CreateVacancySkeletonCard
							style={{ borderRadius: 12 }}
							width={100}
							height={67}
						/>
						<CreateVacancySkeletonCard width={100} />
					</div>
				</CreateVacancyFieldContainer>

				<CreateVacancyFieldContainer>
					<CreateVacancyLabelContainer>
						<CreateVacancyFieldLabel displayStar={false}>
							Featured video
						</CreateVacancyFieldLabel>
					</CreateVacancyLabelContainer>
					<CreateVacancySkeletonCard />
				</CreateVacancyFieldContainer>
			</CreateVacancyCardContent>
		</CreateVacancyCard>
	);
};

export default AddVacancyAdditionalInfoCardLoading;
