import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { array, object, string } from "yup";
import {
	ADD_INTERVIEW_NOTE_MAX_LENGTH,
	LOCATION_TYPES,
	MAX_LENGTH_IN_BYTES
} from "../helper/constants";
import { useGetUser } from "hooks/useGetUser";
import { isTimeValid } from "../helper/utils";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import { bytesToSize } from "config/helpers";
import { v4 as uuid } from "uuid";
import { CalendarDateTime, now } from "@internationalized/date";

export const useProposePermanentInterviewForm = ({ candidate }) => {
	const connectedUser = useGetUser();
	const schema = object().shape({
		dates: array().test("Required", "Please select a day", function(value) {
			return value.length > 0;
		}),
		times: array()
			.of(
				object().test("Unique time", "Please choose unique times", function(
					value
				) {
					const timesList = [];
					const getPath = time => {
						const { year, month, day } = time.date;
						const { hour, minute } = time.startTime;
						const { hour: endHour, minute: endMinute } = time.endTime;
						return `${year}-${month}-${day}-${hour}-${minute}-${endHour}-${endMinute}`;
					};
					for (const time of this.parent) {
						timesList.push(getPath(time));
					}
					return timesList.filter(p => getPath(value) === p).length > 1
						? false
						: true;
				})
			)
			.test("Required", "Time is invalid", function(value) {
				const invalidTimes = value.filter(
					item => !isTimeValid(item.startTime, item.endTime)
				);

				return invalidTimes.length === 0;
			})
			.test("Invalid", "Availability slots should be in the future", function(
				value
			) {
				return value.every(item => {
					const startDateTime = new CalendarDateTime(
						item.date.year,
						item.date.month,
						item.date.day,
						item.startTime.hour,
						item.startTime.minute
					);
					const comparison = startDateTime.compare(now());
					return comparison > 0;
				});
			}),
		attendees: array().test("Required", "Please select attendees", function(
			value
		) {
			return value?.[0]?.children.length > 0;
		}),
		locationValue: string()
			.notRequired()
			.test("Missing fields", "Please enter a valid address", function(value) {
				if (this.parent.locationType === LOCATION_TYPES.online.value)
					return true;
				else if (
					this.parent.locationType === LOCATION_TYPES.companyAddress.value
				) {
					return !!value;
				}
				if (value) {
					const address = JSON.parse(value);
					if (!address.street)
						return this.createError({ message: "Street is required" });
					if (!address.number)
						return this.createError({ message: "Number is required" });
					if (!address.city)
						return this.createError({ message: "City is required" });
					if (!address.zip)
						return this.createError({ message: "Zip is required" });
					if (!address.country)
						return this.createError({ message: "Country is required" });
				}
				return true;
			}),
		note: string().test(
			`The input is more than ${ADD_INTERVIEW_NOTE_MAX_LENGTH} characters long`,
			function(value) {
				if (!value) return true;
				else return value?.length <= ADD_INTERVIEW_NOTE_MAX_LENGTH;
			}
		),
		content: string().test(
			"content-size",
			({ value }) => {
				return `Content is too long (${bytesToSize(
					new TextEncoder().encode(value).length
				)}), max is ${bytesToSize(MAX_LENGTH_IN_BYTES)}`;
			},
			value => {
				return new TextEncoder().encode(value).length < MAX_LENGTH_IN_BYTES;
			}
		)
	});

	const formContext = useForm({
		resolver: yupResolver(schema),
		mode: "onChange",
		defaultValues: {
			id: uuid(),
			locationType: LOCATION_TYPES.companyAddress.value,
			locationValue: "",
			times: [],
			dates: [],
			attendees: [
				{
					id: 1,
					label: "Collaborators",
					children: [
						{
							id: connectedUser.id,
							label: `${connectedUser.first_name} ${connectedUser.last_name}`
						}
					]
				}
			],
			...EMPTY_EMAIL,
			to: candidate ? [candidate] : []
		},
		shouldUnregister: false
	});

	return formContext;
};
