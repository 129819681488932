import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import WebCam from "react-webcam";
import delay from "lodash/delay";
// import S3Uploader from "common/S3Uploader";
import { CARD_ID_FOLDER, TYPE_JPEG, JPG } from "config";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import {
	setUploadedFiles,
	switchMethodDispatch
} from "../actions/identityActions";
import { connect } from "react-redux";
import { toggleIdentityModal } from "../../TempRequestDetailsModule/actions/JobViewActions";
import BodyClassName from "react-body-classname";
import { ButtonConfirmPicture, ButtonRetakePicture } from "./Styled";
import { uploadToS3 } from "config/helpers";
import { get } from "lodash";
import { client } from "lib/api-client";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import toaster from "common/Toaster";

const initialState = {
	captured: false,
	shot: null,
	done: false,
	uploading: false,
	previewing: false,
	warningModal: false,
	successMessage: ""
};

class WebCamModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			...initialState,
			hasUserMedia: false,
			files: props.files,
			progress: null,
			selectedFile: props.files.length > 0 ? 0 : null
		};
	}

	capture = e => {
		e.preventDefault();
		const shot = this.cam.getScreenshot();
		this.setState({
			shot,
			captured: true,
			uploading: false
		});
	};

	cyclePending = () => {
		const { files } = this.state;
		const firstPending = files.findIndex(file => file.file === "");
		if (firstPending !== -1) {
			this.setState(
				{
					selectedFile: firstPending,
					successMessage: `Please use your webcam to capture the ${files[firstPending].name} side of your ID.`
				},
				() => delay(() => this.setState({ ...initialState }), 3000)
			);
		} else this.props.setUploadedFiles(files);
	};

	accept = async () => {
		try {
			const { shot, files, selectedFile } = this.state;

			this.setState({ uploading: true });
			const name = `webcam-id-${files[selectedFile].name}-${this.props.userId}.${JPG}`;
			const file = files[selectedFile];
			const results = await client(PRE_SIGNED_URL, {
				body: {
					name: get(file, "name"),
					folder: CARD_ID_FOLDER,
					size: 4 * Math.ceil(shot.length / 3),
					type: TYPE_JPEG
				}
			});
			const result = await uploadToS3({
				response: results,
				file: shot,
				onProgress: progress => {
					this.setState({
						progress
					});
				}
			});
			if (this.props.storeFiles) {
				localStorage.setItem(name, result.url);
			}
			this.setState(
				prevState => {
					const newState = [...prevState.files];
					if (this.props.files.length > 0) {
						newState[selectedFile] = {
							...newState[selectedFile],
							file: result.url,
							done: true
						};
					}
					return {
						done: true,
						files: newState
					};
				},
				() =>
					this.props.files.length > 0
						? this.cyclePending()
						: delay(() => {
								this.setState({ ...initialState });
						  }, 2000)
			);
			this.setState({
				progress: null
			});
		} catch (error) {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					get(errorMessage, name) &&
						toaster.danger(
							`${name} : ${
								typeof get(errorMessage, name) === "string"
									? get(errorMessage, name)
									: get(errorMessage, name)[0]
							}`
						);
				});
			} else {
				toaster.danger(errorMessage);
			}
		}
	};

	progressComponent = data => {
		if (data) {
			return (
				<div className="file-progress-box">
					<div className="size">
						<div className="size">{`${data.toFixed(0)}%`}</div>
					</div>
					<div className="file-progress-bar">
						<div className="file-sr-only" style={{ width: `${data}%` }} />
					</div>
				</div>
			);
		}
	};

	setCamRef = ref => {
		this.cam = ref;
	};

	handleUserMedia = () => {
		this.setState({ hasUserMedia: true });
	};

	switchSelectedFile = async index => {
		const { captured, selectedFile, files } = this.state;
		const currentFile = files[selectedFile];
		if (currentFile.name === files[index].name) return;

		if (!captured)
			return this.setState({
				...initialState,
				selectedFile: index,
				previewing: !!files[index].file
			});

		if (captured && !currentFile.file)
			this.setState({ warningModal: true, fileIndex: index });
	};

	closeWarningModal = () => this.setState({ warningModal: false });

	confirmWarningModal = () => {
		const { files, fileIndex } = this.state;
		this.setState({
			...initialState,
			selectedFile: fileIndex,
			previewing: !!files[fileIndex].file
		});
	};

	handleRetake = e => {
		e.preventDefault();
		this.setState({ ...initialState });
	};

	render() {
		const {
			captured,
			shot,
			uploading,
			done,
			hasUserMedia,
			files,
			selectedFile,
			successMessage,
			progress
		} = this.state;
		const previewing =
			this.state.previewing || (files.length > 0 && files[selectedFile].done);

		return (
			<BodyClassName className="no-scroll">
				<div>
					<div className="backdrop" />
					<div className="modal-section webcam">
						<button onClick={this.props.switchMethodDispatch} className="close">
							&times;
						</button>
						<div className="content">
							{files.length > 0 && (
								<div className="files">
									<ul>
										{files.map((file, index) => (
											<li
												className={
													files[selectedFile].name === file.name
														? "active"
														: null
												}
												key={file.name}
												onClick={() => this.switchSelectedFile(index)}
											>
												{!file.file ? (
													<Fragment>
														<span className="round-icon">+</span>
														{file.name}
													</Fragment>
												) : (
													<Fragment>
														<img src={file.file} alt={file.name} />
														<i className="icon-check" />
													</Fragment>
												)}
											</li>
										))}
									</ul>
								</div>
							)}
							<div className="webcam-holder">
								{!captured && !previewing ? (
									<WebCam
										ref={this.setCamRef}
										onUserMedia={this.handleUserMedia}
										audio={false}
										height="100%"
										screenshotFormat="image/jpeg"
										width="100%"
									/>
								) : (
									<img
										src={previewing ? files[selectedFile].file : shot}
										height="100%"
										width="100%"
										alt="presentation"
									/>
								)}
							</div>
							{((!done && hasUserMedia) || previewing) && (
								<div className="actions">
									{!captured && !previewing && (
										<button
											className="btn-capture btn-ring"
											onClick={this.capture}
										>
											<i className="icon-capture" />
										</button>
									)}
									{(captured || previewing) && (
										<div
											className={`post-capture${previewing ? " preview" : ""}`}
										>
											<div className="btn-retake">
												<ButtonRetakePicture onClick={this.handleRetake}>
													Try again
												</ButtonRetakePicture>
											</div>
											{!previewing && (
												<Fragment>
													<div className="pusher" />
													<div className="btn-accept">
														<ButtonConfirmPicture onClick={this.accept}>
															<i className="icon-check" />
															<span className="text">Use this picture</span>
														</ButtonConfirmPicture>
													</div>
												</Fragment>
											)}
										</div>
									)}
								</div>
							)}
							{!done && progress && (
								<div id="webcam-uploader">
									<div className="backdrop"></div>
									<div
										className={`upload-progress${
											uploading ? " in-progress" : ""
										}`}
									>
										{uploading && <div className="backdrop" />}
										<div className={`s3-uploader  ${uploading ? "busy" : ""}`}>
											<div className="queue">
												{progress ? this.progressComponent(progress) : null}
											</div>
										</div>
									</div>
								</div>
							)}
							{(done || hasUserMedia === false) && !previewing && (
								<div className="message">
									<div className="round-check">
										<div className="backdrop" />
										<div className="content">
											<div
												className={`ico-holder${!hasUserMedia ? " error" : ""}`}
											>
												<i
													className={`${done ? "icon-check" : "icon-webcam"}`}
												/>
											</div>
											<div className="text">
												{done
													? !!files.length
														? successMessage
														: "Your ID has been successfully uploaded"
													: "wiggli requires access to your webcam to be able to capture your ID. Please grant your browser access to your webcam."}
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<ConfirmationModal
						active={this.state.warningModal}
						onClose={this.closeWarningModal}
						type={ConfirmationTypes.error}
						firstButton={{
							label: "Resume upload",
							action: this.closeWarningModal,
							type: "warning"
						}}
						secondButton={{
							label: "Quit anyway",
							action: this.confirmWarningModal
						}}
						title="Warning!"
						content="Your file hasn't completed uploading yet."
					/>{" "}
				</div>
			</BodyClassName>
		);
	}
}

WebCamModal.propTypes = {
	files: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			file: PropTypes.string.isRequired
		})
	).isRequired,
	storeFiles: PropTypes.bool.isRequired
};

WebCamModal.defaultProps = {
	files: [],
	storeFiles: false
};

const mapDispatchToProps = dispatch => {
	return {
		setUploadedFiles: files => dispatch(setUploadedFiles(files, true, true)),
		switchMethodDispatch: () => dispatch(switchMethodDispatch()),
		toggleIdentityModal: () => dispatch(toggleIdentityModal())
	};
};

export default connect(null, mapDispatchToProps)(WebCamModal);
