import React from "react";

export default function File({ stroke = "#6E7191" }) {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.5 3.83332C3.5 2.72874 4.39543 1.83331 5.5 1.83331H7.18421C8.84106 1.83331 10.1842 3.17646 10.1842 4.83331V5.99998C10.1842 7.38069 11.3035 8.49998 12.6842 8.49998H13.5C15.1569 8.49998 16.5 9.84312 16.5 11.5V16.1666C16.5 17.2712 15.6046 18.1666 14.5 18.1666H5.5C4.39543 18.1666 3.5 17.2712 3.5 16.1666V3.83332Z"
				stroke={stroke}
				strokeWidth="2"
			/>
			<path
				d="M3.5 3.83332C3.5 2.72874 4.39543 1.83331 5.5 1.83331H6.99708C9.3569 1.83331 11.5963 2.87518 13.1164 4.68023L14.6193 6.46494C15.8339 7.9073 16.5 9.73236 16.5 11.618V16.1666C16.5 17.2712 15.6046 18.1666 14.5 18.1666H5.5C4.39543 18.1666 3.5 17.2712 3.5 16.1666V3.83332Z"
				stroke={stroke}
				strokeWidth="2"
			/>
		</svg>
	);
}
