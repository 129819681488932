import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_INTERVIEW } from "config/api-endpoints";
import { WELCOME_PAGE_INTERVIEWS } from "config";
import { interviewsListKey } from "modules/home/api/queriesKeys.js";

const listInterviews = (key, { start_date, end_date, recipient_type }) =>
	client(LIST_INTERVIEW, {
		body: {
			start_date,
			end_date,
			recipient_type,
			tag: WELCOME_PAGE_INTERVIEWS
		}
	});

const useInterviewsList = (options, queryOptions = {}) =>
	useQuery([interviewsListKey, options], listInterviews, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useInterviewsList;
