import React, { useState } from "react";
import { browserHistory } from "react-router";
import CompanyDashboard from "common/dashboard/Dashboard";
import { Helmet } from "react-helmet";
import { ReactComponent as IconInfo } from "static/icons/IconInfo.svg";
import { ReactComponent as IconClose } from "static/icons/IconClose.svg";
import BodyClassName from "react-body-classname";
import CompaniesList from "./CompaniesList";
import styles from "./companies.module.scss";
import CompaniesEmptyState from "./CompaniesEmptyState";
import useCompaniesQuery from "./hooks/useCompaniesQuery";

export const getFollowedCompaniesRoute = () => {
	return "/companies";
};

export default function FollowedCompanies({ route }) {
	const [alertInfoDisplayed, setAlertInfoDisplayed] = useState(true);

	const {
		isError,
		payload,
		isLoading,
		isFetching,
		displayEmptyState,
		search: { searchValue, onSearchChange, onSearchClear },
		pagination: { currentPage, onPaginate }
	} = useCompaniesQuery();

	// redirect to 404 page
	if (isError) {
		browserHistory.push("/404");
		return <></>;
	}

	return (
		<BodyClassName className="gray-bg">
			<div>
				{route.title && (
					<Helmet>
						<title>{route.title}</title>
					</Helmet>
				)}
				<div
					className="dashboard-box"
					style={{ paddingBottom: "0px", marginBottom: "0px" }}
				>
					<CompanyDashboard />
				</div>

				<div className="container">
					{displayEmptyState ? (
						<CompaniesEmptyState />
					) : (
						<>
							{alertInfoDisplayed && (
								<div className={`${styles.alert__info}`}>
									<p>
										<IconInfo />
										All the companies you are following or added you to their
										talent pool can be found here.
									</p>
									<IconClose onClick={() => setAlertInfoDisplayed(false)} />
								</div>
							)}
							<div className={`${styles.flex_col} ${styles.gap_16}`}>
								<CompaniesList
									payload={payload}
									isLoading={isLoading}
									isFetching={isFetching}
									searchValue={searchValue}
									onSearchClear={onSearchClear}
									onSearchChange={onSearchChange}
									currentPage={currentPage}
									onPaginate={onPaginate}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</BodyClassName>
	);
}
