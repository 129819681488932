export default {
	colors: {
		primary: "#ff6849"
	},
	forms: {
		radio: {
			color: "primary",
			border: "white",
			"&:focus": {
				outline: "none !important"
			}
		},
		checkbox: {
			color: "primary",
			border: "white",
			"&:focus": {
				outline: "none !important"
			}
		}
	}
};
