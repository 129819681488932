import React, { useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { get } from "lodash";
import ListMenu from "../ListMenu";
import classnames from "./filter-modal.module.scss";
import SingleRow from "./SingleRow";
import { ReactComponent as AddFilterTypeIcon } from "static/icons/add-filter-type-icon.svg";
import EmptyState from "./EmptyState";
import { useSelectors } from "common/ListFilter/FilterStore/selectors";
import useOnClickOutside from "hooks/useOnClickOutside";

const FilterModal = ({ filterList, rows, setRows, onClose, store }) => {
	const [openListMenu, setOpenListMenu] = useState(false);

	const ref = useRef();

	const {
		filters,
		setGlobalOperator,
		setFields,
		updateFields,
		deleteField,
		clearFilters
	} = store();

	const { addFilter } = useSelectors(store);

	const showList = () => setOpenListMenu(true);
	const closeList = () => setOpenListMenu(false);

	const onSelect = field => {
		setRows(rows => [
			...rows,
			{
				...field,
				...(get(field, "filterOptions") && {
					filterOptions: field.filterOptions
				})
			}
		]);
		addFilter(field);
		setOpenListMenu(false);
	};

	const clearAllHandler = () => {
		clearFilters();
		setRows([]);
	};

	useOnClickOutside(
		ref,
		() => {
			if (!Boolean(rows.length)) onClose();
		},
		[rows]
	);

	return (
		<React.Fragment>
			<div className={classnames.backdrop}></div>
			<div ref={ref} className={classnames.container}>
				<div className={classnames.modalBody}>
					<Scrollbars
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={245}
						autoHeightMin={245}
						style={{ zIndex: 1, paddingBottom: "20px" }}
						renderTrackHorizontal={props => (
							<div
								{...props}
								style={{ display: "none" }}
								className="track-horizontal"
							/>
						)}
						renderView={props => (
							<div
								{...props}
								style={{
									...props.style,
									overflowX: "hidden",
									marginBottom: 0,
									maxHeight: "225px",
									minHeight: "225px"
								}}
							/>
						)}
					>
						{get(rows, "length", 0) > 0 ? (
							rows.map((row, index) => (
								<SingleRow
									key={row.field + index}
									filterList={filterList}
									row={row}
									index={index}
									updateRows={setRows}
									rows={rows}
									filters={filters}
									setGlobalOperator={setGlobalOperator}
									setFields={setFields}
									updateFields={updateFields}
									clearFilters={clearFilters}
									deleteField={deleteField}
									listFilterRef={ref}
									onClose={onClose}
									openListMenu={openListMenu}
									store={store}
								/>
							))
						) : (
							<EmptyState />
						)}
					</Scrollbars>
				</div>
				<div className={classnames.modalFooter}>
					{" "}
					<button className={classnames.addFilterButton} onClick={showList}>
						{" "}
						<AddFilterTypeIcon /> <span>Add filter</span>
					</button>{" "}
					{Boolean(get(rows, "length", 0)) && (
						<button
							className={classnames.clearAllButton}
							onClick={clearAllHandler}
						>
							Clear all
						</button>
					)}
				</div>
				{openListMenu && (
					<div className={classnames.filterModalContainer}>
						{" "}
						<ListMenu
							closeMenu={closeList}
							options={filterList}
							onSelect={onSelect}
						/>{" "}
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default FilterModal;
