import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { readNotifications } from "../actions";
import NotificationRow from "./common/NotificationRow";
import icon from "../../../static/icons/system_notifs.svg";
import { Scrollbars } from "react-custom-scrollbars";
import { IS_URL_REGEX } from "../../../config";
import EmptyNotifications from "./common/EmptyNotifications";
import {
	notificationsViewSelector,
	systemNotificationsSelector
} from "../selectors/NotificationsSelectors";

class SystemNotifications extends Component {
	constructor(props) {
		super(props);
		this.readNotification = this.readNotification.bind(this);
	}

	readNotification(notification) {
		notification = JSON.parse(JSON.stringify(notification));
		this.props.dispatch(readNotifications(notification._id));

		if (IS_URL_REGEX.test(notification.message.url)) {
			window.open(notification.message.url);
		} else browserHistory.push(notification.message.url);
	}

	renderData(notifications) {
		if (!notifications) return;

		if (notifications.length) {
			return notifications.map(notification => {
				return (
					<NotificationRow
						onRead={notif => this.readNotification(notif)}
						key={notification._id}
						notification={notification}
					/>
				);
			});
		}
	}

	render() {
		const { data } = this.props;
		const addClass = data && data.length === 0 ? "empty" : "";
		return (
			<div className={`system-notifications section ${addClass}`}>
				<div className="col-md-4">
					<img src={icon} className="sys-icon" alt="System notifications" />
					<h4>System notifications</h4>
				</div>
				<div className="col-md-8">
					{data && data.length !== 0 && (
						<Scrollbars
							autoHide
							autoHideTimeout={5000}
							autoHideDuration={200}
							autoHeight
							autoHeightMax={580}
						>
							{this.renderData(data)}
						</Scrollbars>
					)}
					{data && data.length === 0 && <EmptyNotifications />}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		view: notificationsViewSelector(state),
		data: systemNotificationsSelector(state)
	};
};

export default connect(mapStateToProps)(SystemNotifications);
