import create from "zustand";

const initialState = { isOpen: false };

const store = set => ({
	...initialState,
	setIsOpen: isOpen => set(state => ({ ...state, isOpen }))
});

const useOpenStartEvaluationDrawer = create(store);
export default useOpenStartEvaluationDrawer;
