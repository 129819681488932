import React, { useState, useEffect } from "react";
import { Box, Flex, Text } from "rebass";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import {
	DuplicateItem,
	FileIcon,
	RemoveItem,
	Td,
	Tr
} from "../TimesheetStyled";
import { Button } from "common/styled/buttons";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import get from "lodash/get";
import { ReactComponent as EditIcon } from "static/icons/clarity_edit-line.svg";
import { ReactComponent as DuplicateIcon } from "static/icons/ion_duplicate-outline.svg";
import { ReactComponent as CommentIcon } from "static/icons/bi_chat-left-text.svg";
import { ReactComponent as DeleteIcon } from "static/icons/carbon_row-delete.svg";
import { ReactComponent as CommentIconNotif } from "static/icons/comments-icons-.svg";
import { ReactComponent as ExpandedIcon } from "static/icons/expand-icon.svg";
import { ReactComponent as UnExpandedIcon } from "static/icons/unexpanded-icon.svg";
import { ReactComponent as LockedEdit } from "static/icons/locked-edit.svg";
import { ReactComponent as LockedComment } from "static/icons/locked-comment.svg";
import { ACCEPT_WORKTIMES, ACCEPT_EXPENSES } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useMutation } from "react-query";
import CommentsBox from "../CommentsBox";
import EditBox from "../EditBox";
import SampleCheckbox from "../MultiAction/SimpleCheckbox";
import WorktimeStatus from "../TimesheetStatus/WorktimeStatus";
import {
	CLIENT,
	DATE_FORMAT,
	DISPUTED,
	DRAFT,
	FREELANCER,
	IN_MEDIATION,
	SUBMITTED,
	ACCEPTED,
	EXPENSE,
	WORKTIME
} from "config";
import { getIconType } from "../ListRenderer";
import toaster from "common/Toaster";
import ExpandedItem from "./ExpandedItem";
import GlobalTooltip from "common/GlobalTooltip";
import { APPROVED } from "config";
import Tooltip from "rc-tooltip";

const checkEditedElements = (item, editedItem) => {
	let elements = [];
	Object.keys(item).forEach(element => {
		if (typeof item[element] === "string") {
			if (item[element].localeCompare(editedItem[element]) !== 0) {
				elements = [...elements, element];
			}
		}
		if (typeof item[element] === "number") {
			if (item[element] !== editedItem[element]) {
				elements = [...elements, element];
			}
		}
	});
	return elements;
};

const CHECKBOX_STATES = {
	Checked: "Checked",
	Indeterminate: "Indeterminate",
	Empty: "Empty"
};
const COEF_TO_DAY = {
	1: "1 day",
	0.5: "1/2 day"
};
const RendrerItem = ({
	_item,
	checked,
	index,
	isLastItem,
	companyId,
	hasTag,
	isExpense,
	type,
	disputedWorkTime,
	hideAmount,
	disputedExpense,
	isMultiSelect,
	editItemId,
	setEditItemId,
	isExpanded,
	setIsExpanded,
	highlighted,
	setChecked,
	checkAll,
	canApprove,
	setIsAllElementHasChecked,
	status,
	jobId,
	timesheetId,
	isTalent,
	isClient,
	isLocked,
	isWorkTime,
	isOverTime,
	addedExpenses = [],
	toggleDeleteModal,
	toggleDuplicateModal,
	addedWorkTimes = [],
	setAddedExpense,
	setAddedWorkTime,
	setAddedOverTime,
	addedOverTimes,
	view,
	setCheckAll,
	month,
	canApproveTags,
	loadTimeSheet,
	setDisputedExpense,
	setDisputedWorkTime,
	getInitialVisibleMonth,
	currentDocumentEditMode,
	timesheetTagName,
	showCheckboxes,
	isRateDaily,
	reportingSettings
}) => {
	let item = _item;
	const [showAcceptModal, setShowAcceptModal] = useState(false);
	const [dispalyCommentBox, setDispalyCommentBox] = useState("");

	const toggleAcceptModal = () => setShowAcceptModal(!showAcceptModal);

	const acceptTimesheet = data =>
		client(!isExpense ? ACCEPT_WORKTIMES : ACCEPT_EXPENSES, { body: data });

	const [onAcceptTimesheet, { isLoading }] = useMutation(acceptTimesheet, {
		onSuccess: () => {
			toggleAcceptModal();
			loadTimeSheet();
		},
		onError: error => {
			if (error && error.status === 422) {
				let errorMsg = get(error, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			} else {
				toaster.danger(get(error, "detail", get(error, "title")));
			}
		}
	});

	const handleOnAcceptTimesheet = () => {
		const data = {
			timesheet_id: timesheetId,
			...(isExpense ? { expenses: [item._id] } : { worktimes: [item._id] })
		};
		onAcceptTimesheet(data);
	};

	const comments = get(_item, "comments", []);
	const edited = get(_item, "edited", {});
	const mergeEdited =
		Object.keys(edited).length > 0 ? { ..._item, ...edited } : {};
	const hasEdits = Object.keys(mergeEdited).length > 0;
	const isAdded = get(_item, "flag", "") === "added";

	const editedElements = checkEditedElements(item, mergeEdited);

	const lastUserAction =
		get(_item, "last_user_action.active_company", false) === companyId;

	const isEditedToDispute = isExpense
		? disputedExpense
				.map(({ expense_id }) => expense_id)
				.includes(get(_item, "_id"))
		: disputedWorkTime
				.map(({ worktime_id }) => worktime_id)
				.includes(get(_item, "_id"));

	const rendrerItemStatus = get(_item, "status", "");

	useEffect(() => {
		if (rendrerItemStatus === ACCEPTED) {
			setEditItemId("");
		}
	}, [rendrerItemStatus]);

	const amountOfTime = isRateDaily
		? COEF_TO_DAY[item.daily_time] || item.hours_representation
		: item.hours_representation;
	return (
		<React.Fragment>
			<Tr
				onClick={() => {
					if (
						rendrerItemStatus !== ACCEPTED &&
						Object.keys(mergeEdited).length > 0
					) {
						setEditItemId("");
						setIsExpanded(isExpanded === item._id ? "" : item._id);
					}
				}}
				style={
					isExpanded || editItemId
						? isExpanded === item._id || editItemId === item._id
							? hasEdits
								? { boxShadow: "0 -8px 16px #ccc" }
								: { boxShadow: "0 0 16px #ccc" }
							: { opacity: 0.5 }
						: {}
				}
				id={
					(isAdded || Object.keys(mergeEdited).length > 0) &&
					"first-item-highlighted"
				}
				highlighted={
					rendrerItemStatus !== ACCEPTED &&
					(highlighted === index ||
						item._id === editItemId ||
						Object.keys(mergeEdited).length > 0 ||
						isAdded ||
						!item._id)
				}
			>
				<Td>
					<Flex sx={{ gap: 1 }} alignItems="center">
						{isClient &&
							showCheckboxes &&
							(status === DISPUTED || status === SUBMITTED) &&
							isMultiSelect && (
								<SampleCheckbox
									setChecked={setChecked}
									checkAll={checkAll}
									setIsAllElementHasChecked={setIsAllElementHasChecked}
									id={item._id}
									disabled={
										!canApprove(item.tag) ||
										rendrerItemStatus === ACCEPTED ||
										rendrerItemStatus === DRAFT ||
										(!isClient && rendrerItemStatus === SUBMITTED) ||
										isLocked
									}
								/>
							)}
						<span style={{ minWidth: "11px" }}>
							{rendrerItemStatus !== ACCEPTED &&
								Object.keys(mergeEdited).length > 0 &&
								(isExpanded === item._id ? (
									<UnExpandedIcon
										onClick={() => {
											setEditItemId("");
											setIsExpanded(item._id);
										}}
									/>
								) : (
									<ExpandedIcon
										onClick={() => {
											setIsExpanded("");
										}}
									/>
								))}
						</span>
						<span>{window.moment.unix(item.date).format(DATE_FORMAT)}</span>
						{rendrerItemStatus !== ACCEPTED &&
							Object.keys(mergeEdited).length > 0 && (
								<Box
									ml={10}
									sx={{
										width: "6px",
										height: "6px",
										background: "#FD6950",
										borderRadius: "50%"
									}}
								/>
							)}
					</Flex>
				</Td>
				<Td padding="2px 5px">{item.description}</Td>
				{hasTag && (
					<Td padding="2px 5px">
						{item.tag && (
							<span className="job-status tag-status">{item.tag}</span>
						)}
					</Td>
				)}
				{isExpense && (
					<Td>
						{item.document ? (
							<a
								href={item.document}
								className="file-icon"
								rel="noopener noreferrer"
								target="_blank"
							>
								<FileIcon className={getIconType(item.document)} />
							</a>
						) : (
							"N/A"
						)}
					</Td>
				)}
				{!isExpense && <Td align="center">{amountOfTime}</Td>}
				{!isExpense && <Td>{`${item.rate}%`}</Td>}
				<Td>
					{" "}
					<WorktimeStatus status={rendrerItemStatus} />{" "}
				</Td>
				{!hideAmount && (
					<Td>
						<CurrencyFormatterRender
							isCent
							value={
								item[`${type === CLIENT ? CLIENT : FREELANCER}_amount`] ||
								item.price ||
								0
							}
							currency={get(reportingSettings, "currency.code")}
						/>
					</Td>
				)}
				{Object.keys(mergeEdited).length > 0 &&
				isExpanded === item._id &&
				rendrerItemStatus !== ACCEPTED ? (
					<Td>
						<Box
							sx={{
								background: "#FFFFFF",
								opacity: "0.5",
								border: "1.3px solid #3E4C5F",
								borderRadius: "100px",
								display: "flex",
								justifyContent: "center"
							}}
						>
							<Text
								sx={{
									color: "#3E4C5F",
									fontFamily: "BasierCircle",
									fontStyle: "normal",
									fontWeight: 600,
									fontSize: "12px",
									lineHeight: "22px"
								}}
							>
								Edition
							</Text>
						</Box>
					</Td>
				) : status !== DRAFT && rendrerItemStatus !== ACCEPTED ? (
					<Td />
				) : null}
				<Td isApproveButton showTooltip={!canApprove(item.tag)}>
					{(isClient && rendrerItemStatus === SUBMITTED) ||
					(!lastUserAction &&
						status !== DRAFT &&
						rendrerItemStatus !== ACCEPTED &&
						item._id &&
						!isEditedToDispute &&
						item._id !== editItemId) ? (
						<GlobalTooltip
							placement="top"
							active={!canApprove(item.tag)}
							overlay={
								<Text
									sx={{
										fontFamily: "BasierCircle",
										fontStyle: "normal",
										fontWeight: 400,
										fontSize: "12px",
										lineHeight: "18px",
										color: "#fff",
										whiteSpace: "break-spaces",
										display: "inline-block"
									}}
								>
									You can only view the entries related to this{" "}
									{timesheetTagName}
								</Text>
							}
							trigger="hover"
							overlayClassName={`timesheet-comment-tooltip`}
						>
							<Button
								disabled={isLocked}
								color="success"
								size={14}
								onClick={e => {
									if (canApprove(item.tag)) {
										toggleAcceptModal();
										e.stopPropagation();
									}
								}}
								key="accept"
								style={{
									padding: "10px 10px",
									borderRadius: "3px"
								}}
							>
								Approve
							</Button>
						</GlobalTooltip>
					) : null}
				</Td>
				<Td
					colSpan={status === DRAFT || rendrerItemStatus === ACCEPTED ? 2 : 1}
					align="right"
				>
					<Flex justifyContent="flex-end">
						{!item._id &&
						status !== IN_MEDIATION &&
						rendrerItemStatus !== ACCEPTED
							? null
							: (isTalent || isClient) &&
							  status !== IN_MEDIATION &&
							  rendrerItemStatus !== ACCEPTED && (
									<Tooltip placement="top" overlay="Edit">
										<DuplicateItem
											onClick={e => {
												e.stopPropagation();
												setEditItemId(item._id);
												setIsExpanded("");
											}}
											disabled={isLocked || !canApprove(item.tag)}
										>
											{isLocked || !canApprove(item.tag) ? (
												<GlobalTooltip
													placement="top"
													active={!canApprove(item.tag)}
													overlay={
														<Text
															sx={{
																fontFamily: "BasierCircle",
																fontStyle: "normal",
																fontWeight: 400,
																fontSize: "12px",
																lineHeight: "18px",
																color: "#fff",
																whiteSpace: "break-spaces",
																display: "inline-block"
															}}
														>
															You can only view the entries related to this{" "}
															{timesheetTagName}
														</Text>
													}
													trigger="hover"
													overlayClassName={`timesheet-edit-tooltip`}
												>
													<LockedEdit />
												</GlobalTooltip>
											) : (
												<EditIcon />
											)}
										</DuplicateItem>
									</Tooltip>
							  )}

						{!item._id &&
						(isWorkTime || isOverTime) &&
						status !== IN_MEDIATION &&
						rendrerItemStatus !== ACCEPTED
							? null
							: isTalent &&
							  status !== DISPUTED &&
							  (isWorkTime || isOverTime) &&
							  status !== IN_MEDIATION &&
							  rendrerItemStatus !== ACCEPTED && (
									<Tooltip placement="top" overlay="Duplicate">
										<DuplicateItem
											onClick={e => {
												e.stopPropagation();
												toggleDuplicateModal({
													[`${view}_id`]: item._id,
													type: view,
													item: isEditedToDispute
														? { ...item, hours: item.hours / 60 }
														: item,
													timesheet_id: timesheetId,
													jobId
												});
											}}
										>
											<DuplicateIcon />
										</DuplicateItem>
									</Tooltip>
							  )}
						{(isTalent || isClient) && item._id && (
							<>
								{status === APPROVED && get(comments, "length", 0) === 0 ? (
									<></>
								) : (
									<Tooltip placement="top" overlay="Comment">
										<DuplicateItem
											onClick={e => {
												e.stopPropagation();
												setDispalyCommentBox(item._id);
												setEditItemId("");
												setIsExpanded(item._id);
											}}
											disabled={isLocked}
										>
											{isLocked ? (
												<LockedComment />
											) : get(comments, "length", 0) > 0 ? (
												<CommentIconNotif />
											) : (
												<CommentIcon />
											)}
										</DuplicateItem>
									</Tooltip>
								)}

								<CommentsBox
									dispalyCommentBox={dispalyCommentBox === item._id}
									comments={comments}
									id={item._id}
									timesheetId={timesheetId}
									view={view}
									closeCommentsBox={() => {
										setDispalyCommentBox("");
										setIsExpanded(false);
									}}
									loadTimeSheet={loadTimeSheet}
									status={rendrerItemStatus}
									isClient={isClient}
								/>
							</>
						)}
						{isTalent && rendrerItemStatus === DRAFT && (
							<Tooltip placement="top" overlay="Delete">
								<RemoveItem
									onClick={e => {
										e.stopPropagation();
										if (item._id) {
											toggleDeleteModal({
												[`${view === EXPENSE ? view : WORKTIME}_id`]: item._id,
												type: view,
												timesheet_id: timesheetId,
												jobId
											});
										} else {
											const newArray = isExpense
												? addedExpenses
												: isOverTime
												? addedOverTimes
												: addedWorkTimes;
											const filtredArray = newArray.filter(
												({ generatedId }) => generatedId !== item.generatedId
											);
											return isExpense
												? setAddedExpense(filtredArray)
												: isOverTime
												? setAddedOverTime(filtredArray)
												: setAddedWorkTime(filtredArray);
										}
									}}
								>
									<DeleteIcon />
								</RemoveItem>
							</Tooltip>
						)}
					</Flex>
				</Td>
			</Tr>
			<ExpandedItem
				isExpanded={isExpanded}
				id={item._id}
				hasEdits={hasEdits}
				editedElements={editedElements}
				mergeEdited={mergeEdited}
				hasTag={hasTag}
				isExpense={isExpense}
				type={type}
				hideAmount={hideAmount}
				reportingSettings={reportingSettings}
			/>
			{item._id === editItemId && (
				<EditBox
					disabled={false}
					isLastItem={isLastItem}
					item={item}
					month={month}
					amount={
						<CurrencyFormatterRender
							isCent
							value={
								item[`${type === CLIENT ? CLIENT : FREELANCER}_amount`] ||
								item.price ||
								0
							}
							currency={get(reportingSettings, "currency.code")}
						/>
					}
					timesheetId={timesheetId}
					isClient={isClient}
					hasTag={hasTag}
					id={item._id}
					getInitialVisibleMonth={getInitialVisibleMonth}
					cancelEdit={() => setEditItemId(false)}
					currentDocument={currentDocumentEditMode}
					isExpense={isExpense}
					isWorkTime={isWorkTime}
					isOverTime={isOverTime}
					status={status}
					disputedWorkTime={disputedWorkTime}
					disputedExpense={disputedExpense}
					setDisputedWorkTime={setDisputedWorkTime}
					setDisputedExpense={setDisputedExpense}
					loadTimeSheet={loadTimeSheet}
					hideAmount={hideAmount}
					isMultiSelect={isMultiSelect}
					isExpanded={Object.keys(mergeEdited).length > 0}
					type={type}
					addedWorkTimes={addedWorkTimes}
					canApproveTags={canApproveTags.map(item => ({
						label: item,
						value: item
					}))}
					rendrerItemStatus={rendrerItemStatus}
					reportingSettings={reportingSettings}
				/>
			)}
			<ConfirmationModal
				active={showAcceptModal}
				modalName="accept-worktimes"
				onClose={() => {
					toggleAcceptModal();
					if (checked) {
						setIsAllElementHasChecked([]);
						setCheckAll(checkAll === false ? undefined : false);
						setChecked(CHECKBOX_STATES.Empty);
					}
				}}
				firstButton={{
					action: () => {
						handleOnAcceptTimesheet();
						if (checked) {
							setIsAllElementHasChecked([]);
							setCheckAll(checkAll === false ? undefined : false);
							setChecked(CHECKBOX_STATES.Empty);
						}
					},
					label: `YES, APPROVE THIS ${isExpense ? "EXPENSE" : "WORKTIME"}`
				}}
				title={`Are you sure you want to approve this ${
					isExpense ? "expense" : "worktime"
				}?`}
				type={ConfirmationTypes.confirmation}
				loading={isLoading}
			/>
		</React.Fragment>
	);
};

export default RendrerItem;
