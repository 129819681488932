import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { MISSION_DETAILS } from "config/api-endpoints";

export const GET_MISSION_DETAILS_QUERY_KEY = "GET_MISSION_DETAILS_QUERY_KEY";

const getMissionDetails = id =>
	client(MISSION_DETAILS, {
		body: { id }
	});

const useGetMissionDetails = id => {
	return useQuery(
		[GET_MISSION_DETAILS_QUERY_KEY, { id }],
		() => getMissionDetails(id),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};

export default useGetMissionDetails;
