import React, { useState } from "react";
import { get } from "lodash";
import cx from "classnames";
import styles from "./header.module.scss";
import Avatar from "common/Avatar/index";
import {
	ArrowLeft,
	ArrowRight,
	ArrowLeftLight,
	InfoOutline,
	Dot,
	Phone,
	Envelope,
	LinkedinIcon,
	ArrowDownIcon,
	FacebookIcon,
	InstagramIcon,
	TwitterIcon,
	YoutubeIcon,
	GithubIcon,
	ExternalIcon
} from "./icons";

import ProfileInfo from "./ProfileInfo";
import ContactInfoModal from "./ContactInfoModal";
import ProfileActions from "./ProfileActions";
import ViewCandidateHelper from "modules/candidate/components/profileClientView/HOC/navigationHelper";
import { goBack } from "config/helpers";
import HeaderSkeleton from "./HeaderSkeleton";
import pickBy from "lodash/pickBy";
import values from "lodash/values";
import head from "lodash/head";
import VacancyNavigationHOC from "modules/candidate/components/profileClientView/HOC/VacancyNavigationHOC";
import { browserHistory } from "react-router";
import { ReactComponent as IconLinkedin } from "static/icons/icons-linkedin.svg";
import prependHttp from "prepend-http";
import SearchCandidateNavigationHOC from "modules/candidate/components/profileClientView/HOC/SearchCandidateNavigationHOC";

const Header = ({
	data,
	setDisplayAddNoteModal,
	previousProfile,
	nextProfile,
	isNextDisabled: isNextDisabledProp,
	isPreviousDisabled: isPreviousDisabledProp,
	nextProfileID,
	goToProfile,
	isLoading,
	mergeProfiles,
	mergeProfileButtonClicked,
	showAddProfileToPoolButton,
	addProfileToPoolButtonClicked,
	setAddToPool,
	disableNavigation
}) => {
	const [showProfileInfo, setShowProfileInfo] = useState(false);
	const [phonesModal, setPhoneModal] = useState(false);
	const [emailsModal, setEmailsModal] = useState(false);
	const [linksModal, setLinksModal] = useState(false);

	const isNextDisabledInternal = isNextDisabledProp || disableNavigation;
	const isPreviousDisabledInternal =
		isPreviousDisabledProp || disableNavigation;

	const addNoteHandler = () => setDisplayAddNoteModal(true);
	const showModalInfoProfile = () => setShowProfileInfo(true);

	const getMainLocation = location => {
		const mainLocation = location.filter(loc => loc.is_main);
		return (
			<>
				{get(mainLocation, "[0].city")
					? get(mainLocation, "[0].city") + ","
					: null}{" "}
				{get(mainLocation, "[0].country")}
			</>
		);
	};
	const getPhoneNumber = phone => (
		<>
			{"+"}
			{phone?.replace(/\D/g, "")}
		</>
	);
	const personal_profile = get(data, "personal_profile");
	const emailsArray = get(personal_profile, "emails", []);
	const emailPrimary = emailsArray
		.filter(profileMail => profileMail.is_primary)
		.map(item => {
			return item.email;
		});
	const otherEmails = emailsArray
		.filter(profileMail => !profileMail.is_primary)
		.map(item => {
			return item.email;
		});

	const linkedin = get(personal_profile, "external_links", []);
	const primaryLink = linkedin
		.filter(profileLink => profileLink.is_primary)
		.map(item => {
			return item.link;
		});

	const otherLink = linkedin
		.filter(profileLink => !profileLink.is_primary)
		.map(item => {
			return item.link;
		});

	const phonesArray = get(personal_profile, "phones", []);
	const phonePrimary = phonesArray
		.filter(profilePhone => profilePhone.is_primary)
		.map(item => {
			return item.phone;
		});
	const otherPhones = phonesArray
		.filter(profilePhone => !profilePhone.is_primary)
		.map(item => {
			return item.phone;
		});

	const formatPhoneData = phone => phone.map(p => "+" + p.replace(/\D/g, ""));

	const fullName =
		get(data, "personal_profile.first_name") +
		" " +
		get(data, "personal_profile.last_name");

	const iconLink = link => {
		const url_icons = {
			linkedin: <LinkedinIcon />,
			facebook: <FacebookIcon />,
			instagram: <InstagramIcon />,
			twitter: <TwitterIcon />,
			youtube: <YoutubeIcon />,
			github: <GithubIcon />
		};
		const match = pickBy(url_icons, (_, key) => link.includes(key));
		return head(values(match)) ? head(values(match)) : <ExternalIcon />;
	};

	const handleBackButtonClick = () => {
		goBack();
	};
	const handleUrl = () => {
		setAddToPool(false);
		browserHistory.push(
			window.location.pathname +
				window.location.search.replace("addToTalentPool=true", "")
		);
	};

	const goNext = () => {
		nextProfile();
		handleUrl();
	};

	const goPrev = () => {
		previousProfile();
		handleUrl();
	};

	const handleNextClick = () => {
		if (isNextDisabledInternal) return;
		goNext();
	};

	const handlePreviousClick = () => {
		if (isPreviousDisabledInternal) return;
		goPrev();
	};

	return (
		<>
			<div className={styles.headerBackground}>
				<div className={styles.headerContainer}>
					<div className={styles.topHeaderButtons}>
						<button onClick={handleBackButtonClick}>
							<ArrowLeftLight /> Back
						</button>
						<div className={styles.pagination}>
							<button
								className={cx({
									[styles.disabledButton]: isPreviousDisabledInternal
								})}
								disabled={isPreviousDisabledInternal}
								onClick={handlePreviousClick}
							>
								<ArrowLeft />
							</button>
							<button
								className={cx({
									[styles.disabledButton]: isNextDisabledInternal
								})}
								disabled={isNextDisabledInternal}
								onClick={handleNextClick}
							>
								<ArrowRight />
							</button>
						</div>
					</div>
					{isLoading ? (
						<HeaderSkeleton />
					) : (
						<div className={styles.infoProfile}>
							<div className={styles.headline}>
								<Avatar
									size={60}
									radius={4}
									src={get(data, "personal_profile.avatar")}
									name={fullName}
								/>
								<div className={styles.rightSide}>
									<h1>
										{get(data, "personal_profile.first_name")}{" "}
										{get(data, "personal_profile.last_name")}
										<span>
											<InfoOutline onClick={showModalInfoProfile} />
											{showProfileInfo && (
												<ProfileInfo
													setShowProfileInfo={setShowProfileInfo}
													data={data}
												/>
											)}
										</span>
										<a
											className={styles.btnGoToLinkedin}
											target="_blank"
											rel="noopener noreferrer"
											href={`https://www.linkedin.com/search/results/people/?firstName=${get(
												data,
												"personal_profile.first_name"
											)}&lastName=${get(data, "personal_profile.last_name")}`}
										>
											<IconLinkedin />
										</a>
									</h1>
									<h2 className={styles.secondaryTitle}>
										{get(data, "main_function.name")}{" "}
										{get(data, "main_function.name") ? <Dot /> : null}{" "}
										<span>{get(data, "main_function.seniority")}</span>
									</h2>
									<h2 className={styles.secondaryTitle}>
										{getMainLocation(
											get(
												data,
												"candidate_preferences.front_sheet.preference.preferred_location",
												[]
											)
										)}
									</h2>
								</div>
							</div>
							<div className={styles.contactInfos}>
								<ul className={styles.listInfo}>
									{get(data, "personal_profile.phones", [])?.length > 0 ? (
										<li>
											<Phone />
											<span>{getPhoneNumber(phonePrimary[0])}</span>
											<div className={styles.containerModal}>
												{otherPhones.length > 0 && (
													<ArrowDownIcon onClick={() => setPhoneModal(true)} />
												)}
												{phonesModal && (
													<ContactInfoModal
														label="All phone numbers"
														data={formatPhoneData(otherPhones)}
														onClose={() => setPhoneModal(false)}
													/>
												)}
											</div>
										</li>
									) : null}
									{get(data, "personal_profile.emails", [])?.length > 0 ? (
										<li>
											<Envelope />
											<span>{emailPrimary}</span>
											<div className={styles.containerModal}>
												{otherEmails.length > 0 && (
													<ArrowDownIcon onClick={() => setEmailsModal(true)} />
												)}
												{emailsModal && (
													<ContactInfoModal
														label="All emails"
														data={otherEmails}
														onClose={() => setEmailsModal(false)}
													/>
												)}
											</div>
										</li>
									) : null}
									{get(data, "personal_profile.external_links", [])?.length >
									0 ? (
										<li>
											{Boolean(primaryLink.length) &&
												iconLink(get(primaryLink, 0))}

											{Boolean(primaryLink.length) && (
												<a
													href={prependHttp(get(primaryLink, 0))}
													target="_blank"
													rel="noreferrer noopener"
													className={styles.path}
												>
													<span> {primaryLink}</span>
												</a>
											)}
											<div className={styles.containerModal}>
												{otherLink.length > 0 && (
													<ArrowDownIcon onClick={() => setLinksModal(true)} />
												)}
												{linksModal && (
													<ContactInfoModal
														label="All links"
														data={otherLink}
														onClose={() => setLinksModal(false)}
														headerLinks={true}
													/>
												)}
											</div>
										</li>
									) : null}
								</ul>
							</div>
							<ProfileActions
								profileId={get(data, "user_id")}
								fullName={fullName}
								candidate={{
									value: get(data, "personal_profile._id", ""),
									label: `${get(data, "personal_profile.first_name", "")} ${get(
										data,
										"personal_profile.last_name",
										""
									)}`,
									profile_id: get(data, "user_id")
								}}
								firstName={get(data, "first_name")}
								lastName={get(data, "last_name")}
								emailProfile={get(data, "personal_profile.email", "")}
								addNoteHandler={addNoteHandler}
								canDelete={get(data, "can_delete")}
								canResendActivationEmail={get(
									data,
									"can_resend_activation_email"
								)}
								canPoke={get(data, "can_poke")}
								nextProfile={nextProfileID}
								goToProfile={goToProfile}
								mergeProfiles={mergeProfiles}
								mergeProfileButtonClicked={mergeProfileButtonClicked}
								showAddProfileToPoolButton={showAddProfileToPoolButton}
								addProfileToPoolButtonClicked={addProfileToPoolButtonClicked}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export { Header };

export const HeaderWithSearchCandidateNavigation = props => (
	<SearchCandidateNavigationHOC
		onPreviousClick={props.onPreviousClick}
		onNextClick={props.onNextClick}
		render={({ ...otherProps }) => <Header {...props} {...otherProps} />}
	/>
);

export const HeaderWithVancacyNavigation = props => (
	<VacancyNavigationHOC
		onPreviousClick={props.onPreviousClick}
		onNextClick={props.onNextClick}
		render={({ ...otherProps }) => <Header {...props} {...otherProps} />}
	/>
);

// eslint-disable-next-line
export default props => (
	<ViewCandidateHelper
		onPreviousClick={props.onPreviousClick}
		onNextClick={props.onNextClick}
		render={({ ...otherProps }) => <Header {...props} {...otherProps} />}
	/>
);
