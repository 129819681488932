import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { DOWNLOAD_ATTACHMENT } from "config/api-endpoints";

function downloadAttachment({ body, isBlob }) {
	return client(DOWNLOAD_ATTACHMENT, {
		body,
		isBlob
	});
}

function useDownloadAttachment(options) {
	return useMutation(downloadAttachment, {
		...options
	});
}

export default useDownloadAttachment;
