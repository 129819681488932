import React from "react";
import Table from "modules/home/components/table/Table";
import PermanentEmptyState from "../emptyState/PermanentEmptyState";
import get from "lodash/get";
import Loader from "common/Loader";
import style from "./styles.module.scss";
const PermanentTable = ({
	data,
	isLoading,
	columns,
	titleEmptyState,
	onRowClick,
	handleViewAll
}) => {
	if (isLoading)
		return (
			<div className={style["loader_container"]}>
				<Loader check={false} />{" "}
			</div>
		);
	if (!Boolean(get(data, "length")))
		return (
			<div className={style["loader_container"]}>
				{" "}
				<PermanentEmptyState heading={titleEmptyState} />
			</div>
		);

	return (
		<Table
			data={data}
			columns={columns}
			onRowClick={onRowClick}
			handleViewAll={handleViewAll}
		/>
	);
};

export default PermanentTable;
