import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";

const getFilters = () => {
	return client(NEW_FILTER_SETTINGS, {
		body: { tag: "status", list: "timesheets" }
	});
};

export const GET_TIMESHEET_STATUS_QUERY_KEY = "GET_TIMESHEET_STATUS_QUERY_KEY";

function useGetTimeSheetStatus(options) {
	return useQuery(GET_TIMESHEET_STATUS_QUERY_KEY, getFilters, {
		refetchOnWindowFocus: false,
		...options
	});
}

export default useGetTimeSheetStatus;
