import * as types from "./actionTypes";
import axiosInstance from "config/axios";
import AxiosHelper from "config/axios-helper";
import _get from "lodash/get";
import {
	HTTP_POST_METHOD,
	RESPONSE_OK,
	HTTP_GET_METHOD,
	LATEST,
	EVENT_REFRESH_DATA
} from "config";
import {
	LIST_NOTIFICATIONS,
	READ_NOTIFICATIONS,
	TOTAL_NOTIFICATIONS,
	LATEST_NOTIFICATIONS,
	CHANGE_POKABILITY_ENDPOINT
} from "config/api-endpoints";
import { getMyProfile } from "../../user/actions/authActions";

function writeNotifications(data) {
	return {
		type: types.WRITE_NOTIFICATIONS,
		data
	};
}

function startFetching() {
	return {
		type: types.NOTIFICATIONS_START_FETCHING
	};
}

function stopFetching() {
	return {
		type: types.NOTIFICATIONS_STOP_FETCHING
	};
}

function writeNotification(notification) {
	return {
		type: types.WRITE_NOTIFICATION,
		notification
	};
}

function writeLatestNotifications(notifications) {
	return {
		type: types.WRITE_LATEST_NOTIFICATIONS,
		notifications
	};
}

function writeNotificationsCount(count) {
	return {
		type: types.WRITE_NOTIFICATION_COUNT,
		count
	};
}

// exported functions
export function hideNotification(notification) {
	return dispatch => {
		dispatch(readNotifications(notification._id));
		dispatch(_hideNotification(notification));
	};
}

export function _hideNotification(notification) {
	return {
		type: types.REMOVE_NOTIFICATION,
		notification
	};
}

export function clearNotifications() {
	return {
		type: types.CLEAR_NOTIFICATIONS
	};
}

export function toggleDropDown() {
	return {
		type: types.TOGGLE_NOTIFICATIONS_DROPDOWN
	};
}

export function switchView(view) {
	return dispatch => {
		dispatch(listNotifications(0, view));
		dispatch({ type: types.SWITCH_VIEW, view });
	};
}

export function listNotifications(offset = 0, field = LATEST) {
	return dispatch => {
		dispatch(startFetching());
		axiosInstance({
			url: LIST_NOTIFICATIONS,
			method: HTTP_POST_METHOD,
			data: JSON.stringify({ offset, field })
		}).then(response => {
			if (response && response.status === RESPONSE_OK) {
				dispatch(stopFetching());
				dispatch(writeNotifications(response.data));
			}
		});
	};
}

export function getLatestNotifications() {
	return dispatch => {
		axiosInstance({
			url: LATEST_NOTIFICATIONS,
			method: HTTP_GET_METHOD
		}).then(response => {
			if (response && response.status === RESPONSE_OK) {
				dispatch(writeLatestNotifications(response.data));
			}
		});
	};
}

export function getNotificationsCount() {
	return dispatch => {
		axiosInstance({
			url: TOTAL_NOTIFICATIONS,
			method: HTTP_POST_METHOD
		}).then(response => {
			if (response && response.status === RESPONSE_OK) {
				dispatch(writeNotificationsCount(response.data.total));
			}
		});
	};
}

export function showNotification(notification) {
	return dispatch => {
		if (_get(notification, "message.type") !== EVENT_REFRESH_DATA) {
			const notificationObj = {
				message: {
					content: notification.message.content,
					type: notification.message.type,
					url: notification.message.url
				},
				...notification.message
			};

			delete notificationObj.content;
			delete notificationObj.type;
			delete notificationObj.url;

			dispatch(writeNotification(notificationObj));
			dispatch(getNotificationsCount());
			dispatch(getLatestNotifications());
		}
	};
}

export function readNotifications(payload, offset, field) {
	return (dispatch, getState) => {
		const data = {};
		if (typeof payload === "boolean") data.all = payload;
		if (typeof payload === "string") data.notification_id = payload;
		axiosInstance({
			url: READ_NOTIFICATIONS,
			method: HTTP_POST_METHOD,
			data: JSON.stringify(data)
		}).then(() => {
			dispatch(stopFetching());
			dispatch(
				listNotifications(offset, getState()?.notifications?.view ?? field)
			);
			dispatch(getNotificationsCount());
			dispatch(getLatestNotifications());
		});
	};
}

export function changePokabilityStatus(data, isModal) {
	return AxiosHelper.__post({
		method: HTTP_POST_METHOD,
		url: CHANGE_POKABILITY_ENDPOINT,
		data,
		loadingText: "Saving changes...",
		isModal,
		next: () => dispatch => dispatch(getMyProfile())
	});
}
