import React from "react";
import Pagination from "common/NewPagination";
import { ReactComponent as PrevIcon } from "static/icons/chevron-left.svg";
import styles from "./tasks-list-pagination.module.scss";

const FreelancersTabPagination = ({ size, total, offset, onPageClick }) => {
	return (
		<Pagination
			previousLabel={<PrevIcon />}
			nextLabel={<PrevIcon className={styles.nextIcon} />}
			total={total}
			size={size}
			handlePageClick={onPageClick}
			offset={offset}
			forcePage={Math.ceil(offset / size)}
			pageRangeDisplayed={total > 1000 ? 6 : undefined}
			marginPagesDisplayed={total > 1000 ? 0 : undefined}
		/>
	);
};

export default FreelancersTabPagination;
