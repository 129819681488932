import React from "react";
import { ReactComponent as BriefcaseIcon } from "static/icons/Briefcase-grey-icon.svg";
import Tooltip from "common/Tippy";
import styles from "./bid-details-tooltips.module.scss";

const ProfileInfoTooltip = ({ functions = [] }) => {
	return functions?.length > 0 ? (
		<Tooltip
			className={styles.tooltip}
			content={
				<div className={styles.content}>
					<div className={styles.functions}>
						<h5>Function{functions?.length > 1 && "s"}:</h5>
						<ul>
							{functions.map(({ name, _id }) => (
								<li key={_id}>{name}</li>
							))}
						</ul>
					</div>
				</div>
			}
			theme="dark"
			placement="bottom"
		>
			<BriefcaseIcon />
		</Tooltip>
	) : (
		<></>
	);
};

export default ProfileInfoTooltip;
