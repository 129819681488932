import {
	MY_SKILLS,
	MY_EDUCATIONS,
	MY_EXPERIENCES,
	MY_LANGUAGES,
	MY_ADDITIONALINFO,
	MY_FUNCTIONS,
	MY_PREFERENCE
} from "../../../config/api-endpoints";

export const SECTION = {
	skills: MY_SKILLS,
	languages: MY_LANGUAGES,
	experiences: MY_EXPERIENCES,
	educations: MY_EDUCATIONS,
	headline: MY_ADDITIONALINFO,
	preference: MY_PREFERENCE,
	functions: MY_FUNCTIONS
};
