import { client } from "lib/api-client";
import { EVALUATIONS_SUMMARY_DATA_ENDPOINT } from "config/api-endpoints";
import { useQuery } from "react-query";

export const filterData = body => {
	return new Promise(resolve => {
		client(EVALUATIONS_SUMMARY_DATA_ENDPOINT, {
			body
		}).then(res => {
			setTimeout(() => resolve(res), [2000]);
		});
	});
};

export const GET_EVALUATIONS_SUMMARY_QUERY_KEY = "GET_EVALUATIONS_QUERY_KEY";

const useGetProfileSummaryEvaluationsList = (body, options = {}) => {
	return useQuery(
		[GET_EVALUATIONS_SUMMARY_QUERY_KEY, body],
		() => filterData(body),
		{
			refetchOnWindowFocus: false,
			...options
		}
	);
};

export default useGetProfileSummaryEvaluationsList;
