import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { PUBLISHED_JOB } from "config/api-endpoints";

const publishJob = data => {
	return client(PUBLISHED_JOB, {
		body: { ...data }
	});
};

const usePublishJob = (queryOptions = {}) =>
	useMutation(publishJob, queryOptions);
export default usePublishJob;
