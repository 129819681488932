import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_USERS_AND_GROUPS } from "config/api-endpoints";

const getUsersAndGroups = body => {
	return client(GET_USERS_AND_GROUPS, {
		body
	});
};

const useGetUsersAndGroups = options =>
	useQuery(["getUsersAndGroups", options], () => getUsersAndGroups(options), {
		refetchOnWindowFocus: false,
		retry: 0
	});

export default useGetUsersAndGroups;
