import { client } from "lib/api-client";
import { GET_EMAIL_SIGNATURE } from "config/api-endpoints";
import { useQuery } from "react-query";
import fakeData from "modules/user-onboarding/mockData/email-signature.json";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";

const getSignature = () => {
	return client(`${GET_EMAIL_SIGNATURE}?render=true`);
};

export const GET_EMAIL_SIGNATURE_QUERY_KEY = "GET_EMAIL_SIGNATURE_QUERY_KEY";

export function useGetEmailSignature(options) {
	const { workflowId } = useOnboardingStore();

	if (workflowId) {
		return useQuery(GET_EMAIL_SIGNATURE_QUERY_KEY, () => {
			return Promise.resolve(fakeData);
		});
	}

	return useQuery(GET_EMAIL_SIGNATURE_QUERY_KEY, getSignature, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
}
