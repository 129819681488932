import { ANYWHERE, CONTINENTS } from "config";
import { useEffect, useState } from "react";
import { useGetCountriesList } from "../api/useGetCountriesList";

export const useGetApplicantLocations = options => {
	const [locations, setLocations] = useState([]);
	const { data: countriesList } = useGetCountriesList(options);

	useEffect(() => {
		if (!countriesList) return;

		const countriesFormatted = countriesList.map(item => ({
			label: item.country,
			value: {
				type: "country",
				code: item.code,
				name: item.country
			}
		}));

		const ApplicantData = [ANYWHERE, ...CONTINENTS, ...countriesFormatted];

		//Code property is producing an issue in ComboBox, when we filter by morocco, filter doesn't work as expected
		const list = ApplicantData.map((item, i) => {
			return {
				...item,
				value: {
					...item.value,
					id: String(i)
				}
			};
		});

		setLocations(list);
	}, [countriesList]);

	return locations;
};
