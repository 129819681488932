import styled from "styled-components";

export const Label = styled.label`
	font-weight: 500;
	color: #0f1c46;
	margin-top: 41px;
	position: relative;
	font-size: 16px;
	left: -60px;
`;
export const ChartCurrentSearch = styled.div`
	width: 70%;
	top: -15px;
	position: relative;
	left: 80px;
`;
export const SectionInfo = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	font-weight: normal;
	color: #273238;
	top: ${props =>
		props.isvacancyPer || props.isrequestPer ? "-100px" : "-45px"};
	left: 20px;
	&.is-request-perf {
		position: relative;
		left: 20px !important;
	}
	.ova {
		display: inline-flex;
		font-size: 12px;
		.section-ova {
			display: inline-flex;
			margin-left: ${props =>
				props.isvacancyPer ? "20px" : props.isrequestPer ? "30px" : "8px"};
			span {
				position: relative;
				top: 0px !important;
				margin-right: 3px;
			}
		}
	}
	.Ova-1 {
		background-color: #0099fa;
		height: 15px;
		width: 15px;
		top: 0;
		border-radius: 50px;
		display: flex;
	}
	.Ova-2 {
		background-color: #f7b422;
		height: 15px;
		width: 15px;
		top: 0;
		border-radius: 50px;
		display: flex;
	}
	.Ova-3 {
		background-color: #56bd5b;
		height: 15px;
		width: 15px;
		top: 0;
		border-radius: 50px;
		display: flex;
	}
	.Ova-4 {
		background-color: #3c78d8;
		height: 15px;
		width: 15px;
		top: 0;
		border-radius: 50px;
		display: flex;
	}
`;
export const LoaderPlace = styled.div`
	position: relative;
	float: right;
	margin-top: 35px;
	left: 67px;
	height: 15px;
	.loader-dashboard span {
		width: 24px;
		height: 24px;
	}
`;
export const WrappeVictory = styled.div`
	position: relative;
	top: -40px;
	width: 500px;
	left: -40px;
`;
