import _get from "lodash/get";
import * as types from "./actionTypes";
import axiosInstance from "config/axios";
import AxiosHelper from "config/axios-helper";
import {
	LIST_CATEGORIES,
	LIST_FUNCTIONS,
	LIST_SKILLS,
	GET_LANGUAGES,
	GET_COUNTRIES,
	FILTER_SETTINGS_ENDPOINT
} from "config/api-endpoints";
import { HTTP_POST_METHOD, RESPONSE_OK } from "config";

export function togglePropose(sector, skill, subcategory = null) {
	return {
		type: types.TOGGLE_PROPOSE,
		sector,
		skill,
		subcategory
	};
}

export function toggleGetHelp() {
	return {
		type: types.TOGGLE_GET_HELP
	};
}

export function toggleLoading() {
	return {
		type: types.TOGGLE_LOADING
	};
}

export function toggleHelpModal(modal = null, fields = null) {
	return {
		type: types.TOGGLE_HELP_MODAL,
		modal,
		fields
	};
}

export function fillHelpForm(fields = null) {
	return {
		type: types.FILL_HELP_FORM,
		fields
	};
}

const setCategories = data => ({ type: types.SET_CATEGORIES, data });
const setFunctions = data => ({ type: types.SET_FUNCTIONS, data });
export const setSkills = data => ({ type: types.SET_SKILLS, data });
const setCountries = (data, all) => ({ type: types.SET_COUNTRIES, data, all });

export const setSubCategories = data => ({
	type: types.SET_SUBCATEGORIES,
	data
});

export function getCategories() {
	return async dispatch => {
		try {
			dispatch(toggleLoading());
			const { status, data } = await axiosInstance({
				url: LIST_CATEGORIES,
				method: HTTP_POST_METHOD
			});
			if (status === RESPONSE_OK) {
				dispatch(setCategories(data));
				dispatch(toggleLoading());
			}
		} catch (error) {
			dispatch(toggleLoading());
		}
	};
}

export function getFunctions(payload) {
	return async dispatch => {
		try {
			dispatch(toggleLoading());
			const { status, data } = await axiosInstance({
				url: LIST_FUNCTIONS,
				method: HTTP_POST_METHOD,
				data: JSON.stringify(payload)
			});
			if (status === RESPONSE_OK) {
				dispatch(setFunctions(data));
				dispatch(toggleLoading());
			}
		} catch (error) {
			dispatch(toggleLoading());
		}
	};
}

export function loadSkills(value, sector) {
	return (dispatch, getState) => {
		const category = _get(
			getState(),
			"form.cv-light-headline.values.category",
			null
		);
		if (!sector && !category) return;
		const data = {
			sector_id: sector ? sector._id : category.value,
			no_limit: true,
			offset: 0
		};
		if (value) data.term = value;
		return dispatch(
			AxiosHelper.__post({
				url: LIST_SKILLS,
				REQUEST_SUCCESS: types.SET_SKILLS,
				data
			})
		);
	};
}

export function loadLanguages() {
	return AxiosHelper.__get({
		url: GET_LANGUAGES,
		REQUEST_SUCCESS: types.SET_LANGUAGES,
		isModal: true
	});
}

export function loadCountries(all = true) {
	return (dispatch, getState) => {
		const key = all ? "allCountries" : "euCountries";
		let countries = _get(getState(), `app.${key}`, []);
		if (countries.length) return countries;
		return dispatch(
			AxiosHelper.__post({
				url: GET_COUNTRIES,
				data: { all },
				formatter: prepareForSelect
			})
		).then(data => dispatch(setCountries(data, all)));
	};
}

function prepareForSelect(countries) {
	const newCountries = [];
	for (const country of countries) {
		newCountries.push({
			value: country.country,
			label: country.country
		});
	}
	return newCountries.sort((a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	});
}

export function getFilterSettings(payload = { tag: "missions" }) {
	return AxiosHelper.__post({
		url: FILTER_SETTINGS_ENDPOINT,
		data: payload,
		REQUEST_SUCCESS: types.SET_FILTER_SETTINGS
	});
}
