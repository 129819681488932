import { createSelector } from "reselect";
import _get from "lodash/get";
import { TIMESHEET_TAGS_STATUS } from "config";

const optionsFormatter = options => {
	return (options || []).map(item => ({
		value: item,
		label: item
	}));
};

const getTimesheetTagsSelect = state =>
	optionsFormatter(_get(state, "timesheet.data.timesheet_tags", []));

export const timesheetTagsSelectSelector = createSelector(
	getTimesheetTagsSelect,
	status => status
);

const getTimesheetTagsProjectName = state =>
	_get(state, "timesheet.data.timesheet_tags_name", "");

export const timesheetTagsProjectNameSelector = createSelector(
	getTimesheetTagsProjectName,
	status => status
);

const getFeatureTimesheetTags = state =>
	_get(
		state,
		"timesheet.data.feature_timesheet_tags",
		TIMESHEET_TAGS_STATUS.inactive
	);

export const featureTimesheetTagsSelector = createSelector(
	getFeatureTimesheetTags,
	status => status
);
