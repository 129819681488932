import React, { useMemo } from "react";
import styles from "./channel-card.module.scss";
import img from "static/images/wiggli-logo-blue.png";
import Checkbox from "common/Ui/Checkbox";
import usePublishRequestStore from "modules/publishRequest/hooks/usePublishRequestStore";
import GlobalTooltip from "common/GlobalTooltip";
import cx from "classnames";

export default function ChannelCard({ channel }) {
	const { toggleSelectChannel, selectedChannels } = usePublishRequestStore();

	const onChange = () => {
		toggleSelectChannel(channel);
	};

	const isChannelSelected = useMemo(() => {
		const index = selectedChannels.findIndex(
			c => c.product_id === channel.product_id
		);
		return index > -1;
	}, [selectedChannels]);

	return (
		<label className={styles.card}>
			<div className={styles.checkboxContainer}>
				<Checkbox onChange={onChange} isSelected={isChannelSelected} />
			</div>
			<img src={channel?.logo_url?.[0]?.url || img} className={styles.logo} />
			<div className={styles.title}>{channel.channel.name}</div>
			{!!channel.description && (
				<GlobalTooltip
					placement="top"
					trigger="hover"
					overlay={channel.description}
					overlayClassName={cx("g-tool-dark", styles.tooltip)}
					noClassName
				>
					<div className={styles.description}>{channel.description}</div>
				</GlobalTooltip>
			)}
		</label>
	);
}
