//TODO when we create site the button delete doesn't exist
import React from "react";
import style from "modules/MyCompany/components/DeleteModal/delete-modal.module.scss";
import ConfirmationBox from "./ConfirmationBox";
import { ReactComponent as BriefCaseIcon } from "static/icons/Briefcase.svg";
import { ReactComponent as GlobeIcon } from "static/icons/GlobeAlt.svg";
import SitesDropDown from "modules/MyCompany/components/DeleteModal/SitesDropDown";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import { ReactComponent as Arrow } from "static/icons/ArrowIcon.svg";
import cx from "classnames";

const FirstStepCommponent = ({
	handleCheck,
	check,
	vacanciesCount,
	requestCount,
	isJobPage,
	setDisplayDrawer,
	setOpenModal,
	isCareerPage,
	onChange,
	siteId,
	selectedOption,
	setSelectedOption,
	requestSite,
	setRequestSite
}) => {
	const changeHandler = option => {
		setSelectedOption(option);
		onChange(option);
	};

	const isBigHeight = () => {
		if (vacanciesCount > 0 && requestCount > 0) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			<p className={style.modalDesc}>
				You have some entities linked to this site :{" "}
			</p>
			<div className={style.modalRow}>
				{(vacanciesCount > 0 || requestCount > 0) && (
					<div className={style.vacanciesDisplay}>
						<div className={style.flex}>
							<BriefCaseIcon className={style.briefCaseIcon} />
							<div className={style.links}>{vacanciesCount} open vacancies</div>
							<div>&nbsp; & &nbsp;</div>
							<div className={style.links}>{requestCount} requests</div>
						</div>
						{vacanciesCount !== 0 && (
							<div className={style.select}>
								<p className={style.text}>Assign all vacancies to</p>
								<HeaderButton
									text={selectedOption ? selectedOption.name : "Select.."}
									className="sitesSelect"
									secondIcon={<Arrow />}
								>
									<SitesDropDown
										onChange={changeHandler}
										setDisplayDrawer={setDisplayDrawer}
										setOpenModal={setOpenModal}
										siteId={siteId}
									/>
								</HeaderButton>
							</div>
						)}
						{requestCount !== 0 && (
							<div className={style.requestSelect}>
								<p className={style.text}>Assign all requests to</p>
								<HeaderButton
									text={requestSite ? requestSite.name : "Select.."}
									className="sitesSelect"
									secondIcon={<Arrow />}
								>
									<SitesDropDown
										onChange={option => {
											setRequestSite(option);
										}}
										setDisplayDrawer={setDisplayDrawer}
										setOpenModal={setOpenModal}
										siteId={siteId}
									/>
								</HeaderButton>
							</div>
						)}
					</div>
				)}
				{(vacanciesCount > 0 || requestCount > 0) &&
					(isJobPage || isCareerPage) && (
						<div
							className={cx(style.separator, {
								[style.smallHeight]: !isBigHeight(),
								[style.bigHeight]: isBigHeight()
							})}
						></div>
					)}
				{(isJobPage || isCareerPage) && (
					<div className={style.pageDisplay}>
						<div className={style.links}>
							<GlobeIcon /> company page & job page
						</div>
						<div className={style.check} onClick={handleCheck}>
							<ConfirmationBox checked={check} />
							<p className={style.text}>
								Delete the company and job page of the site
							</p>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default FirstStepCommponent;
