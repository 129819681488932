import create from "zustand";

const initialState = {
	workflowId: "",
	metaData: null,
	currentStep: 0,
	isLoading: false,
	activeOverlay: false
};

const onboardingStore = set => ({
	...initialState,
	setMetaData: metaData => set(state => ({ ...state, metaData })),
	setIsLoading: isLoading => set(state => ({ ...state, isLoading })),
	setWorkflowId: workflowId => set(state => ({ ...state, workflowId })),
	setCurrentStep: currentStep => set(state => ({ ...state, currentStep })),
	setActiveOverlay: activeOverlay =>
		set(state => ({ ...state, activeOverlay })),
	cleanStore: () => set(() => initialState)
});

const useOnboardingStore = create(onboardingStore);
export default useOnboardingStore;
