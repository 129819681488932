import { GET_FEATURES_SUCCESS, GET_FEATURES_ERROR } from "./actions";
import { isFeatures } from "./GetFeatureFromLocalStorage";
const initialState = {
	isFeatures,
	success: isFeatures
};
export default function features(state = initialState, action) {
	switch (action.type) {
		case GET_FEATURES_SUCCESS:
			return Object.assign({}, state, {
				isFeatures: true,
				success: true
			});
		case GET_FEATURES_ERROR:
			return Object.assign({}, state, {
				isFeatures: true,
				success: false
			});
		default:
			return state;
	}
}
