import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { change } from "redux-form";

import VacancyList from "../components/list/VacancyList";
import { getPokesListExp } from "../actions";
import { getJobsList, filterJobsList, resetJobData } from "modules/job/actions";
import { VACANCY, ATTENDEES_LIST_TAG } from "config";
import { pokesListSelector } from "../selectors";
import { dataListJobSelector, stopJobSelector } from "../../job/selectors";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { attendeesListSelector } from "modules/candidate/redux/selectors";
import { getListAttendees } from "modules/candidate/redux/actions";

export const getVacancyListRoute = () => "/vacancies/list";

export const getVacancyListPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

function VacancyListContainer({
	route,
	getJobsList,
	resetJobData,
	data,
	stopJob,
	pokesList,
	getPokesListExp,
	user,
	getListAttendees,
	attendeesList,
	change
}) {
	const fetchAttendees = id => {
		getListAttendees({
			tag: ATTENDEES_LIST_TAG,
			vacancy_id: id
		});
	};

	const getVacanyList = payload => {
		getJobsList({ ...payload, job_type: VACANCY });
	};

	const getPokesListByVacancy = job_id => {
		getPokesListExp(job_id);
	};

	const changeReduxForm = (field, value) => {
		change("send-interview", field, value);
	};

	return (
		<VacancyList
			getVacanciesList={getVacanyList}
			route={route}
			data={data}
			stopJob={stopJob}
			resetJobData={resetJobData}
			getPokesListByVacancy={getPokesListByVacancy}
			pokesList={pokesList}
			user={user}
			fetchAttendees={fetchAttendees}
			attendeesList={attendeesList}
			changeReduxForm={changeReduxForm}
		/>
	);
}

const mapStateToProps = state => {
	return {
		data: dataListJobSelector(state),
		stopJob: stopJobSelector(state),
		pokesList: pokesListSelector(state),
		user: onlineUserSelector(state),
		attendeesList: attendeesListSelector(state)
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			resetJobData,
			getJobsList,
			filterJobsList,
			getPokesListExp,
			getListAttendees,
			change
		},

		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(VacancyListContainer);
