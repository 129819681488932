import React, { Component } from "react";
import Tooltip from "rc-tooltip";
import PropTypes from "prop-types";
import "../styles/css/hireme/customHandleStyles.css";

const handleStyle = {};

class CustomHandle extends Component {
	render() {
		const style = Object.assign({ left: `${this.props.offset}%` }, handleStyle);
		const {
			prefixCls = "rc-slider",
			tooltipPrefixCls,
			tipTransitionName,
			tipFormatter,
			value,
			showTip,
			tipClass,
			trigger = [],
			hideTooltips
		} = this.props;
		const handle = (
			<div style={style} className="handle">
				<div className="handle-middle" />
			</div>
		);

		if (!showTip) return handle;
		let displayProps = {};

		if (trigger.length === 0) {
			if (hideTooltips) {
				displayProps.visible = false;
			} else {
				displayProps.visible = true;
			}
			displayProps.defaultVisible = true;
		} else {
			if (hideTooltips) {
				displayProps.visible = false;
			}
			displayProps.trigger = trigger;
		}

		return (
			<Tooltip
				prefixCls={tooltipPrefixCls || `${prefixCls}-tooltip`}
				placement="top"
				overlay={<span>{tipFormatter(value)}</span>}
				delay={200}
				overlayClassName={tipClass || ""}
				transitionName={tipTransitionName}
				{...displayProps}
			>
				{handle}
			</Tooltip>
		);
	}
}

CustomHandle.propTypes = {
	showTip: PropTypes.bool
};

CustomHandle.defaultProps = {
	showTip: true
};

export default CustomHandle;
