import React, { forwardRef } from "react";
import styles from "./popover-content.module.scss";
import { ReactComponent as ChevronUpIcon } from "static/icons/chevron-up.svg";
import { ReactComponent as ChevronDownIcon } from "static/icons/chevron-down.svg";

const PopoverContent = forwardRef(({ overlayProps, state }, ref) => {
	return (
		<div {...overlayProps} className={styles.popover} tabIndex="0" ref={ref}>
			<div className={styles.controls}>
				<button type="button" onClick={() => state.increment("hour")}>
					<ChevronUpIcon />
				</button>
				<button type="button" onClick={() => state.incrementPage("minute")}>
					<ChevronUpIcon />
				</button>
			</div>
			<div className={styles.preview}>
				{state.segments.map((segment, i) => (
					<div key={i}>{segment.text}</div>
				))}
			</div>
			<div className={styles.controls}>
				<button type="button" onClick={() => state.decrement("hour")}>
					<ChevronDownIcon />
				</button>
				<button type="button" onClick={() => state.incrementPage("minute")}>
					<ChevronDownIcon />
				</button>
			</div>
		</div>
	);
});

PopoverContent.displayName = "PopoverContent";
export default PopoverContent;
