import React from "react";

const ProfileIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<g>
				<path
					className="notification-icon-origin"
					d="M285.9,250h-71.7c-50,0-90.6,40.6-90.6,90.6v60.9h252.9v-60.9C376.4,290.6,335.8,250,285.9,250z M356.7,381.8
			H143.3v-41.2c0-39.1,31.8-70.8,70.8-70.8h71.7c39.1,0,70.8,31.8,70.8,70.8V381.8z"
				/>
				<path
					className="notification-icon"
					d="M356.7,340.6v41.2H143.3v-41.2c0-39.1,31.8-70.8,70.8-70.8h71.7C324.9,269.8,356.7,301.5,356.7,340.6z"
				/>
			</g>
			<g>
				<path
					className="notification-icon-origin"
					d="M250,98.5c-38.2,0-69.3,31.1-69.3,69.3c0,38.2,31.1,69.3,69.3,69.3s69.3-31.1,69.3-69.3
			C319.3,129.6,288.2,98.5,250,98.5z M250,214.6c-25.8,0-46.8-21-46.8-46.8c0-25.8,21-46.8,46.8-46.8s46.8,21,46.8,46.8
			C296.8,193.6,275.8,214.6,250,214.6z"
				/>
			</g>
		</g>
	</svg>
);

export default ProfileIcon;
