import create from "zustand";

const initialState = {
	data: {
		avatar: null,
		function: null,
		vacancy_id: null,
		vacancy_name: null,
		candidate_id: null,
		candidate_name: null
	}
};

const store = set => ({
	...initialState,
	setData: data => set(state => ({ ...state, data }))
});

const useEditQuickEvaluationData = create(store);
export default useEditQuickEvaluationData;
