//Nice to have add loading
import React, { useState, useEffect } from "react";
import styles from "./questionnare-form-file-uploader.module.scss";
import toaster from "common/Toaster";
import { ReactComponent as PaperclipIcon } from "static/icons/referral/paperclip.svg";
import { ReactComponent as FileIcon } from "static/icons/referral/file.svg";
import { ReactComponent as TrashIcon } from "static/icons/referral/trash.svg";
import { ReactComponent as CheckIcon } from "static/icons/referral/check-circle.svg";
import cx from "classnames";
import { PRE_SIGNED_URL } from "config/api-endpoints";
import { client } from "lib/api-client";
import { QuestionnaireFormLabel } from "../QuestionnaireFormLabel";
import { ALLOWED_FILES_TYPES } from "../utils/constants";
import { useDropzone } from "react-dropzone";
import { bytesToSize, uploadToS3 } from "config/helpers";
import { SUPPORTED_SIZE, FILE_SIZE_LIMIT_MB } from "config";

const REJECTIONS = {
	"file-invalid-type": "Invalid file type.",
	"file-too-large": `The file size should not exceed ${FILE_SIZE_LIMIT_MB}MB.`
};

export default function QuestionnaireFormFileUploader({
	onChange: onUrlChange,
	description,
	is_required: isRequired,
	isReadOnly,
	className
}) {
	const [files, setFiles] = useState([]);
	const [progress, setProgress] = useState(0);
	const { getRootProps, getInputProps, fileRejections } = useDropzone({
		accept: ALLOWED_FILES_TYPES.join(","),
		maxFiles: 1,
		maxSize: SUPPORTED_SIZE,
		onDrop: acceptedFiles => {
			if (acceptedFiles.length > 0) {
				setProgress(0);
				setFiles(acceptedFiles);
				upload(acceptedFiles);
			}
		}
	});

	useEffect(() => {
		if (fileRejections.length) {
			fileRejections.forEach(({ errors }) => {
				errors.forEach(error =>
					toaster.danger(REJECTIONS[error.code], {
						duration: 8,
						id: error.code
					})
				);
			});
		}
	}, [fileRejections]);

	const preSignS3 = file => {
		return client(PRE_SIGNED_URL, {
			body: {
				name: file.name,
				folder: "files",
				size: file.size,
				type: file.type
			}
		});
	};

	const upload = async files => {
		const response = await preSignS3(files[0]);
		const result = await uploadToS3({
			response,
			file: files[0],
			onProgress: value => setProgress(Math.floor(value))
		});

		onUrlChange([{ path: result.url, file_name: files[0].path }]);
	};

	return (
		<div className={styles.container}>
			<QuestionnaireFormLabel
				isRequired={isRequired}
				description={description}
				className={className}
			/>
			{files.map(({ path, size }, index) => {
				return (
					<div key={index} className={styles.previewContainer}>
						<div className={styles.fileInfos}>
							<div className={styles.iconWrapper}>
								<FileIcon />
							</div>
							<div className={styles.name}>{path}</div>
							<div>{bytesToSize(size)}</div>
						</div>
						<div className={styles.fileStatus}>
							{progress < 100 ? (
								<div className={styles.progress}>
									<div className={styles.bar}>
										<div style={{ width: `${progress}%` }}></div>
									</div>
									<div className={styles.percentage}>{progress}%</div>
								</div>
							) : (
								<div className={styles.completed}>
									<span className={styles.label}>Completed</span>
									<CheckIcon />
									<span
										onClick={() => {
											setFiles([]);
											onUrlChange([]);
										}}
										role="button"
										className={styles.deleteIcon}
									>
										<TrashIcon />
									</span>
								</div>
							)}
						</div>
					</div>
				);
			})}

			{files?.length === 0 && (
				<div
					role="button"
					tabIndex={0}
					className={cx(styles.uploaderContainer, {
						[styles.isReadOnly]: isReadOnly
					})}
					{...getRootProps()}
				>
					<div className={styles.iconWrapper}>
						<PaperclipIcon />
					</div>
					<input {...getInputProps()} />
					<div>
						<div>
							<span className={styles.highlighted}>Click to upload</span> or
							drag and drop
						</div>
						<div>PDF, PNG, JPEG, DOC, XLS, PPT.</div>
					</div>
				</div>
			)}
		</div>
	);
}
