import React from "react";
import styles from "./temp-pipeline-edit-step-card.module.scss";
import { IconButton } from "common/IconButton";
import { ReactComponent as IconCheck } from "static/icons/IconCheck.svg";
import { ReactComponent as Close } from "static/icons/cross-red.svg";

const TempPipelineEditStepCard = ({
	value,
	isDirty,
	onCancel,
	onSubmit,
	onInputChange
}) => {
	return (
		<div className={styles.container}>
			<input
				value={value}
				onChange={onInputChange}
				className={styles.input}
				type="text"
			/>
			<IconButton
				onClick={onSubmit}
				className={styles.button}
				disabled={!value || !isDirty}
			>
				<IconCheck className={styles.check} height={20} width={20} />
			</IconButton>
			<IconButton onClick={onCancel} className={styles.button}>
				<Close className={styles.closeIcon} height={20} width={20} />
			</IconButton>
		</div>
	);
};

export default TempPipelineEditStepCard;
