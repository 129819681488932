import { client } from "lib/api-client";
import { GET_EMAIL_SIGNATURE } from "config/api-endpoints";
import { useQuery } from "react-query";

const getSignature = (_, body) => {
	return client(GET_EMAIL_SIGNATURE, {
		body
	});
};

export const GET_EMAIL_SIGNATURE_QUERY_KEY = "GET_EMAIL_SIGNATURE_QUERY_KEY";

export function useGetEmailSignature(options) {
	return useQuery(GET_EMAIL_SIGNATURE_QUERY_KEY, getSignature, {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
}
