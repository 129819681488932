import { useMutation } from "react-query";
import { GET_SINGLE_CANDIDATE_DATA } from "config/api-endpoints";
import { client } from "lib/api-client";

const getSingleCandidate = body =>
	client(GET_SINGLE_CANDIDATE_DATA, {
		body
	});

const useGetSingleCandidate = options => {
	return useMutation(getSingleCandidate, options);
};

export default useGetSingleCandidate;
