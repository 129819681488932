//We use compound component pattern
import React, { useState, useEffect } from "react";
import Drawer from "rc-drawer";
import styles from "./propose-interview-to-freelancer-drawer.module.scss";
import AddressBoxModal from "common/AddressBoxNext/AddressBoxModal";
import { useInviteFreelancerInterviewForm } from "../../hooks/useInviteFreelancerInterviewForm";
import { Controller } from "react-hook-form";
import {
	getDeclineTempInterviewPayload,
	getProposeTempInterviewFormDefaultValues,
	getProposeTempInterviewPayload
} from "./utils/helper";
import Portal from "common/Portal";
import { useProposeFreelancerInterview } from "modules/RequestView/api/useProposeFreelancerInterview";
import { useUpdateTempInterview } from "modules/RequestView/api/useUpdateTempInterview";
import { FilterDrawerHeader } from "../FilterDrawerHeader";
import { ProposeInterviewToFreelancerDrawerBody } from "../ProposeInterviewToFreelancerDrawerBody";
import { ProposeInterviewToFreelancerDrawerFooter } from "../ProposeInterviewToFreelancerDrawerFooter";
import useSelectedKanbanBid from "modules/RequestView/hooks/useSelectedKanbanBid";
import { queryCache } from "react-query";
import { REQUEST_VIEW_KANBAN_DATA_QUERY } from "../kanban/hooks/useTempPipelineDataApi";
import { useMyCurrentCompany } from "api/useMyCurrentCompany";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import { SENT, ACCEPTED, DECLINED } from "config";
import { useDeclineTempInterview } from "modules/RequestView/api/useDeclineTempInterview";
import { useGetUser } from "hooks/useGetUser";
import { useToggle } from "react-use";
import { addressObjectToString } from "config/helpers";
import { BIDS_QUERY_KEY } from "modules/Requests/RequestsCandidatesList/hooks/useGetTempList";
import { KEY_FETCH_TEMP_INTERVIEWS } from "modules/Requests/RequestsInterviewsList/hooks/useFilterApplications";
import { GET_CONTRACTS_QUERY_KEY } from "modules/Requests/contracts/hooks/useGetContractsList";

const ProposeInterviewToFreelancerDrawer = ({
	onClose,
	onSuccess: onSuccessProp
}) => {
	const [isFormInitialized, setIsFormInitialized] = useState(false);
	const { bid: selectedBid } = useSelectedKanbanBid();
	const [showAddressModal, setShowAddressModal] = useState(false);
	const { data } = useMyCurrentCompany();
	const companyAddress = data ? addressObjectToString(data) : "";
	const isEdit = selectedBid?.interview?.length !== 0;
	const [displayDrawer, setDisplayDrawer] = useToggle(false);

	const {
		control,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
		setValue,
		setError
	} = useInviteFreelancerInterviewForm();
	const connectedUser = useGetUser();
	const [
		proposeInterview,
		{ isLoading: isAddInterviewLoading }
	] = useProposeFreelancerInterview({
		onSuccess: () => {
			queryCache.invalidateQueries(REQUEST_VIEW_KANBAN_DATA_QUERY);
			queryCache.invalidateQueries([BIDS_QUERY_KEY]);
			queryCache.invalidateQueries([KEY_FETCH_TEMP_INTERVIEWS]);
			queryCache.invalidateQueries([GET_CONTRACTS_QUERY_KEY]);

			toaster.success("Interview has been proposed successfully!");
			onSuccessProp?.();
		},
		onError: e => {
			if (e?.status === 422) {
				let errorMsg = e?.detail;
				if (errorMsg) {
					Object.keys(errorMsg).forEach(name => {
						setError(name, {
							type: "manual",
							message: errorMsg[name][0]
						});
					});
				}
			} else {
				toaster.danger("An error has occurred");
			}
		}
	});

	const [
		updateInterview,
		{ isLoading: updateInterviewIsLoading }
	] = useUpdateTempInterview({
		onSuccess: () => {
			queryCache.invalidateQueries(REQUEST_VIEW_KANBAN_DATA_QUERY);
			toaster.success("Interview has been updated successfully!");
			onSuccessProp?.();
		},
		onError: e => {
			if (e?.status === 422) {
				let errorMsg = e?.detail;
				if (errorMsg) {
					Object.keys(errorMsg).forEach(name => {
						setError(name, {
							type: "manual",
							message: errorMsg[name][0]
						});
					});
				}
			} else {
				toaster.danger("An error has occurred");
			}
		}
	});

	const [
		declineInterview,
		{ isLoading: declineInterviewIsLoading }
	] = useDeclineTempInterview({
		onSuccess: () => {
			queryCache.invalidateQueries(REQUEST_VIEW_KANBAN_DATA_QUERY);
			toaster.success("Interview has been updated successfully!");
			onSuccessProp?.();
		},
		onError: e => {
			if (e?.status === 422) {
				let errorMsg = e?.detail;
				if (errorMsg) {
					Object.keys(errorMsg).forEach(name => {
						setError(name, {
							type: "manual",
							message: errorMsg[name][0]
						});
					});
				}
			} else {
				toaster.danger("An error has occurred");
			}
		}
	});

	useEffect(() => {
		if (isEdit) {
			reset(getProposeTempInterviewFormDefaultValues(selectedBid?.interview));
			setIsFormInitialized(true);
		}
	}, [selectedBid, isEdit, connectedUser]);

	const onAddressSave = (value, onChange) => {
		onChange(value);
		setShowAddressModal(false);
	};

	const onSubmit = async values => {
		const body = await getProposeTempInterviewPayload(values, {
			companyAddress: data,
			bidId: selectedBid.bid_id,
			interviewId: selectedBid?.interview?._id
		});

		const status = selectedBid.interview?.status;

		if (status === SENT || status === ACCEPTED) {
			updateInterview(body);
		} else if (status === DECLINED) {
			const body = await getDeclineTempInterviewPayload(values, {
				companyAddress: data,
				bidId: selectedBid.bid_id,
				interviewId: selectedBid?.interview?._id
			});
			declineInterview(body);
		} else {
			proposeInterview(body);
		}
	};

	useEffect(() => {
		requestAnimationFrame(() => {
			setDisplayDrawer(true);
		});
	}, []);

	const onCloseDrawer = () => {
		setDisplayDrawer(false);
	};

	const afterVisibleChange = visibility => {
		if (!visibility) onClose();
	};

	return (
		<div>
			<Drawer
				open={displayDrawer}
				width="700"
				height="100%"
				placement="right"
				style={{ zIndex: 1000, backgroundColor: "transparent" }}
				level={"root"}
				maskClosable
				onClose={onCloseDrawer}
				afterVisibleChange={afterVisibleChange}
			>
				<div className={styles.container}>
					<div className={styles.root}>
						<LoaderFull
							loading={
								isAddInterviewLoading ||
								updateInterviewIsLoading ||
								declineInterviewIsLoading
							}
						/>
						<FilterDrawerHeader
							title={isEdit ? "Propose another interview" : "Propose interview"}
							onClose={onCloseDrawer}
						/>

						{/*We use conditional rendering to fix an issue in attendees picker, the component doesn't update internal state when prop value changes*/}
						{((isEdit && isFormInitialized) || !isEdit) && (
							<ProposeInterviewToFreelancerDrawerBody
								onEditAddress={() => setShowAddressModal(true)}
								customAddres={watch("customAddress")}
								companyAddress={companyAddress}
								control={control}
								selectedAttendees={watch("attendees")}
								errors={errors}
								setValue={setValue}
								candidateName={selectedBid.candidate_name}
							/>
						)}
						<ProposeInterviewToFreelancerDrawerFooter
							onSubmit={handleSubmit(onSubmit)}
							onClose={onCloseDrawer}
						/>
					</div>
				</div>
			</Drawer>

			<Portal>
				<Controller
					control={control}
					name="customAddress"
					render={({ field: { value, onChange } }) => {
						return (
							showAddressModal && (
								<AddressBoxModal
									active={showAddressModal}
									onClose={() => setShowAddressModal(false)}
									onSave={e => onAddressSave(e, onChange)}
									requiredFields={[
										"street",
										"number",
										"city",
										"zip",
										"country"
									]}
									isRequired
									title="Edit Address"
									label="Custom address"
									currentAddress={value}
								/>
							)
						);
					}}
				/>
			</Portal>
		</div>
	);
};

export default ProposeInterviewToFreelancerDrawer;
