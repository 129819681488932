import React from "react";
import styles from "./form-builder-render-elements.module.scss";
import { ELEMENT_TYPES } from "modules/EvaluationFormsSettings/utils/constants";
import {
	deleteField,
	duplicateField,
	getComponentByType,
	toggleRequired
} from "modules/EvaluationFormsSettings/utils/helpers";
import { useDroppable } from "@dnd-kit/core";
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useFormContext } from "react-hook-form";
import cx from "classnames";

export default function FormBuilderRenderElements({
	id,
	fieldArray: { fields, remove, replace },
	fieldArrayName,
	disableDrop = false,
	isPresentational = false,
	presentationalItems = []
}) {
	const context = useFormContext();
	const { setNodeRef, active } = useDroppable({
		id,
		disabled: disableDrop,
		data: {
			droppablePath: fieldArrayName
		}
	});
	const activeElementType = active?.data?.current?.type;
	const listOfElements = isPresentational ? presentationalItems : fields;

	return (
		<SortableContext
			id={id}
			items={fields.map(i => i._id)}
			disabled={disableDrop || isPresentational}
			strategy={verticalListSortingStrategy}
		>
			<div
				ref={setNodeRef}
				className={cx(styles.droppableContainer, {
					[styles.droppableContainerNotEmpty]:
						!!fields.length && fieldArrayName === "elements"
				})}
			>
				{listOfElements.length === 0 ? (
					id !== "root" ? (
						<div className={styles.droppableContainerEmptyBlock}>
							Drag or add elements here
						</div>
					) : null
				) : (
					listOfElements.map((item, index) => {
						const key = item._id + item.items?.map?.(i => i._id).join();
						const Component = getComponentByType(item.type);
						const path = `${fieldArrayName}.${index}`;

						const onToggleRequired = () => {
							toggleRequired({
								index,
								fieldArrayName,
								formContext: context,
								replace
							});
						};

						return (
							<div key={key}>
								<SortableItem
									id={item._id}
									type={item.type}
									itemPath={fieldArrayName}
									renderItem={({ dragHandleProps }) => {
										return (
											<Component
												path={path}
												element={item}
												dragHandleProps={dragHandleProps}
												isPresentational={isPresentational}
												disableDrop={
													item.type === ELEMENT_TYPES.section
														? activeElementType === ELEMENT_TYPES.section
														: undefined
												}
												onDelete={() => deleteField({ remove, index })}
												onDuplicate={() =>
													duplicateField({
														index,
														fieldArrayName,
														formContext: context
													})
												}
												onToggleRequired={
													item.type === ELEMENT_TYPES.section
														? undefined
														: onToggleRequired
												}
											/>
										);
									}}
								/>
							</div>
						);
					})
				)}
			</div>
		</SortableContext>
	);
}

function SortableItem({ id, type, renderItem, itemPath }) {
	const {
		transform,
		transition,
		listeners,
		setActivatorNodeRef,
		setNodeRef,
		isDragging
	} = useSortable({
		id,
		data: { type, itemPath }
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			className={cx({ [styles.ghost]: isDragging })}
		>
			{renderItem({ dragHandleProps: { setActivatorNodeRef, listeners } })}
		</div>
	);
}
