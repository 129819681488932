import React, { useState } from "react";
import { useSelector } from "react-redux";
import _isArray from "lodash/isArray";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import styles from "./permanent-view-evaluation-details-drawer.module.scss";

import get from "lodash/get";
import EvaluationCard from "common/EvaluationCard/EvaluationCard";
import { ReactComponent as DividerIcon } from "static/icons/divider.svg";
import EvaluationTemplateFormDrawer from "common/EvaluationTemplateFormDrawer";
import EvaluationCardEditButton from "common/EvaluationCard/EvaluationCardEditButton/EvaluationCardEditButton";
import EvaluationDetailsCardBody from "common/EvaluationCard/EvaluationDetailsCardBody/EvaluationDetailsCardBody";
import EvaluationCardDeleteButton from "common/EvaluationCard/EvaluationCardDeleteButton/EvaluationCardDeleteButton";

import EvaluationCardHeader from "common/EvaluationCard/EvaluationCardHeader/EvaluationCardHeader";
import EvaluationCardActions from "common/EvaluationCard/EvaluationCardActions/EvaluationCardActions";
import EvaluationTemplateRender from "common/EvaluationTemplateFormDrawer/components/EvaluationTemplateRender";
import EvaluationCardScoreStatus from "common/EvaluationCard/EvaluationCardScoreStatus/EvaluationCardScoreStatus";
import EvaluationCardCandidateLabel from "common/EvaluationCard/EvaluationCardCandidateLabel/EvaluationCardCandidateLabel";
import EvaluationCardProfileOverview from "common/EvaluationCard/EvaluationCardProfileOverview/EvaluationCardProfileOverview";
import { EvaluationTemplateCandidateResume } from "common/EvaluationTemplateFormDrawer/components/EvaluationTemplateCandidateResume";
import usePermanentToEvaluate from "../../Profile/Pages/PermanentViewEvaluationsTab/components/ProfileViewEvaluationTemplateFormDrawer/hooks/usePermanentToEvaluate";
import useOpenTemplateEvaluationDrawer from "../../Profile/Pages/PermanentViewEvaluationsTab/components/ProfileViewEvaluationTemplateFormDrawer/hooks/useOpenTemplateEvaluationDrawer";

import { LoaderFull } from "common/Loader";
import { EVALUATION_CV_ID, EVALUATION_TAB_ID, TABS } from "./constants";
import { useGetPermanentEvaluationDetails } from "./api/useGetPermanentEvaluationDetails";
import DeleteEvaluationModal from "../../Profile/Pages/PermanentViewEvaluationsTab/components/DeleteEvaluationModal/DeleteEvaluationModal";
import { historyPush } from "config/helpers";

const PermanentViewEvaluationDetailsDrawer = ({ onClose, onDeleteSuccess }) => {
	const user = useSelector(onlineUserSelector);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [activeTab, setActiveTab] = useState(EVALUATION_TAB_ID);

	const {
		setIsOpen: setIsOpenEvaluationTemplate
	} = useOpenTemplateEvaluationDrawer();
	const { setData: setEvaluationTemplateData } = usePermanentToEvaluate();

	const evaluationId = new URLSearchParams(window.location.search).get(
		"view_evaluation_id"
	);

	const { data: evaluation, isLoading } = useGetPermanentEvaluationDetails(
		{ id: evaluationId },
		{ enabled: !!evaluationId }
	);

	const candidateName = `${get(evaluation, "profile.first_name", "")} ${get(
		evaluation,
		"profile.last_name",
		""
	)}`;
	const candidateFunction = {
		main_function: get(evaluation, "main_function.function")
	};
	const createdByName = `${get(evaluation, "created_by.first_name")} ${get(
		evaluation,
		"created_by.last_name"
	)}`;

	const onEdit = () => {
		const template = {
			...evaluation,
			elements: evaluation?.evaluation_form || []
		};

		const drawerData = {
			template,
			is_edit_mode: true,
			has_previous: true,
			function: candidateFunction,
			candidate_name: candidateName,
			vacancy_id: evaluation?.job?._id,
			avatar: evaluation?.profile?.avatar,
			vacancy_name: evaluation?.job?.title,
			candidate_id: evaluation?.profile?._id
		};
		setEvaluationTemplateData(drawerData);
		setIsOpenEvaluationTemplate(true);
	};

	return (
		<EvaluationTemplateFormDrawer isOpen onClose={onClose}>
			<LoaderFull loading={isLoading} />
			<EvaluationTemplateFormDrawer.Header>
				<EvaluationTemplateFormDrawer.Title
					onClose={onClose}
					title="Candidate Evaluation"
				/>
				<EvaluationTemplateFormDrawer.CandidateInfo
					candidate_name={candidateName}
					candidate_function={candidateFunction}
					avatar={get(evaluation, "profile.avatar")}
				/>
				<EvaluationTemplateFormDrawer.Tabs
					tabs={TABS}
					activeTab={activeTab}
					onChange={tabId => setActiveTab(tabId)}
				/>
				<EvaluationTemplateFormDrawer.Divider />
			</EvaluationTemplateFormDrawer.Header>
			<EvaluationTemplateFormDrawer.TabPanel
				className={activeTab !== EVALUATION_TAB_ID && styles.displayNone}
			>
				{evaluation && !_isArray(evaluation) && (
					<EvaluationTemplateRender>
						<EvaluationCard className={styles.marginTop}>
							<EvaluationCardHeader>
								<EvaluationCardProfileOverview
									name={createdByName}
									edited={evaluation.edited}
									date={evaluation.created_at}
								>
									<EvaluationCardCandidateLabel
										isCandidate
										title={candidateName}
										containerClassName={styles.evaluationCandidate}
										onClick={() =>
											historyPush(`/vacancy/${evaluation.job._id}`)
										}
									/>
								</EvaluationCardProfileOverview>
								<EvaluationCardActions>
									{user.id === evaluation.created_by._id && (
										<>
											<EvaluationCardEditButton onClick={onEdit} />
											<EvaluationCardDeleteButton
												onClick={() => setShowDeleteModal(true)}
											/>
											<DividerIcon />
										</>
									)}
									<EvaluationCardScoreStatus
										score={evaluation.evaluation_score}
									/>
								</EvaluationCardActions>
							</EvaluationCardHeader>
							<hr className={styles.hr} />
							<EvaluationDetailsCardBody
								form={evaluation.evaluation_form}
								note={evaluation.evaluation_note}
							/>
						</EvaluationCard>
					</EvaluationTemplateRender>
				)}
			</EvaluationTemplateFormDrawer.TabPanel>
			<EvaluationTemplateFormDrawer.TabPanel
				className={activeTab !== EVALUATION_CV_ID && styles.displayNone}
			>
				<EvaluationTemplateCandidateResume
					path={get(evaluation, "cv_docs.0.path")}
				/>
			</EvaluationTemplateFormDrawer.TabPanel>
			{showDeleteModal && (
				<DeleteEvaluationModal
					onSuccess={onDeleteSuccess}
					payload={{ id: evaluation?._id }}
					onClose={() => setShowDeleteModal(false)}
				/>
			)}
		</EvaluationTemplateFormDrawer>
	);
};

export default PermanentViewEvaluationDetailsDrawer;
