/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import styles from "./request-view-stage.module.scss";
import { Draggable, Droppable } from "react-beautiful-dnd";
import useRequestViewKanbanStore from "../../store/useRequestViewKanbanStore";

import _get from "lodash/get";
import Scrollbars from "react-custom-scrollbars";
import { DROPPABLE_TYPE_CARD, STAGE_TYPE_BID } from "../../constants";
import KanbanCardWrapper from "../KanbanCardWrapper";
import { ReactComponent as EmptyStageIcon } from "static/icons/EmptyStageIcon.svg";
import { ReactComponent as DotsGridIcon } from "static/icons/dots-grid.svg";
import { useTempPipelineDataApi } from "../../hooks/useTempPipelineDataApi";
import { RequestPipelineStageActionsMenu } from "../RequestPipelineStageActionsMenu";
import { DeletePipelineStepModal } from "modules/RequestView/components/DeletePipelineStepModal";
import { TempPipelineEditStepCard } from "modules/RequestView/components/TempPipelineEditStepCard";
import { useEditTempPipelineStep } from "modules/RequestView/api/useEditTempPipelineStep";
import useCurrentRequest from "modules/RequestView/hooks/useCurrentRequest";
import { TempPipelineCardLoading } from "modules/RequestView/components/TempPipelineCardLoading";
import { CantDeletePipelineStepModal } from "modules/RequestView/components/CantDeletePipelineStepModal";
import { queryCache } from "react-query";
import { REQUEST_VIEW_KANBAN_SETTINGS_QUERY } from "../../hooks/useTempKanbanSettingsApi";
import toaster from "common/Toaster";
import { isEmpty } from "lodash";
import cx from "classnames";

export default function RequestViewStage({
	index,
	jobId,
	stageConfig,
	styles: cardContainerStyles,
	placeholderProps
}) {
	const { requestId } = useCurrentRequest();
	const [isEditStep, setIsEditStep] = useState(false);
	const [stepName, setStepName] = useState(stageConfig.name);
	const [showCantDeleteStepModal, setShowCantDeleteStepModal] = useState(false);
	const [showDeleteStepModal, setShowDeleteStepModal] = useState(false);

	const {
		searchQuery,
		setStageCards,
		getStageCards,
		renameStageCards,
		...store
	} = useRequestViewKanbanStore();

	const [editStep] = useEditTempPipelineStep({
		onSuccess: (_, body) => {
			renameStageCards({
				oldStageName: body.old_step_name,
				stageName: body.step_name
			});
			queryCache.invalidateQueries([
				REQUEST_VIEW_KANBAN_SETTINGS_QUERY,
				{ job_id: jobId }
			]);
			setIsEditStep(false);
		},
		onError: () => {
			toaster.danger("Step name already taken!");
		}
	});

	const queryPayload = useMemo(() => {
		return {
			job_id: jobId,
			search: searchQuery,
			steps: [stageConfig.name]
		};
	}, [searchQuery]);

	const { isLoading, data } = useTempPipelineDataApi(queryPayload);
	useEffect(() => {
		if (data) {
			const stageName = stageConfig.name;
			const payload = data?.[stageName]?.data || [];
			setStageCards({ stageName, data: payload });
		}
	}, [data]);

	const stageCards = useMemo(() => {
		return getStageCards(stageConfig.name) || [];
	}, [store]);

	const stageCardsCount = useMemo(() => {
		return (stageCards || []).length;
	}, [stageCards]);

	const getStageStyles = (isDragging, draggableStyle) => {
		return {
			border: isDragging && "1px dashed rgba(80, 92, 115, 0.35)",
			boxShadow: isDragging && "0px 12px 56px -2px rgba(80, 92, 115, 0.16)",
			...draggableStyle
		};
	};

	const onMenItemClick = value => {
		if (value === "renameStep") {
			setIsEditStep(true);
		} else {
			onDeleteStep();
		}
	};

	const onSaveStepName = () => {
		editStep({
			job_id: requestId,
			step_id: stageConfig._id,
			step_name: stepName,
			tag: "update",
			// ** used to update store
			old_step_name: stageConfig.name
		});
	};

	const onDeleteStep = () => {
		if (stageCards.length > 0) {
			setShowCantDeleteStepModal(true);
		} else if (_get(stageConfig, "step_settings.can_delete")) {
			setShowDeleteStepModal(true);
		} else {
			setShowCantDeleteStepModal(true);
		}
	};

	const renderCardPlaceholder = snapshot => {
		return (
			snapshot.isDraggingOver && (
				<div
					className={styles.cardDotted}
					style={{
						top: placeholderProps.clientY,
						left: placeholderProps.clientX,
						width: placeholderProps.clientWidth,
						height: placeholderProps.clientHeight
					}}
				></div>
			)
		);
	};

	return (
		<>
			<Draggable
				index={index}
				draggableId={stageConfig._id}
				isDragDisabled={!_get(stageConfig, "step_settings.can_move")}
			>
				{(draggableProvided, draggableSnapshot) => {
					return (
						<Droppable
							direction={"vertical"}
							type={DROPPABLE_TYPE_CARD}
							droppableId={stageConfig._id}
						>
							{(droppableProvided, snapshot) => {
								return (
									<div
										className={styles.container}
										ref={draggableProvided.innerRef}
										{...draggableProvided.draggableProps}
										style={getStageStyles(
											draggableSnapshot.isDragging,
											draggableProvided.draggableProps.style
										)}
									>
										{/* Stage header */}
										<div
											{...draggableProvided.dragHandleProps}
											className={styles.stageHeaderContainer}
										>
											{isEditStep ? (
												<TempPipelineEditStepCard
													value={stepName}
													onSubmit={onSaveStepName}
													onCancel={() => setIsEditStep(false)}
													isDirty={stageConfig.name !== stepName}
													onInputChange={e => setStepName(e.target.value)}
												/>
											) : (
												<>
													<p
														className={cx(styles.stageTitle, {
															[styles.stageTitleBid]:
																stageConfig.name === "bids"
														})}
													>
														{_get(stageConfig, "step_settings.can_move") && (
															<DotsGridIcon
																className={
																	draggableSnapshot.isDragging
																		? styles.dragDotsActive
																		: styles.dragDots
																}
															/>
														)}
														{stageConfig.name}
														{!!stageCardsCount && (
															<span className={styles.stageCardCount}>
																{stageCardsCount}
															</span>
														)}
													</p>

													<RequestPipelineStageActionsMenu
														onItemClick={onMenItemClick}
														canRename={_get(
															stageConfig,
															"step_settings.can_rename"
														)}
													/>
												</>
											)}
										</div>
										{/* Stage cards container */}
										<div
											style={{ ...cardContainerStyles, position: "relative" }}
											ref={droppableProvided.innerRef}
											className={styles.cardsContainer}
											{...droppableProvided.dropppableProps}
										>
											<Scrollbars
												autoHeightMax={250}
												autoHideTimeout={5000}
												autoHideDuration={200}
												renderTrackHorizontal={props => (
													<div {...props} style={{ display: "none" }} />
												)}
												renderView={props => (
													<div
														{...props}
														className={styles.scrollbarContainer}
														style={{
															...props.style,
															marginBlock: "-6px",
															overflowX: "hidden"
														}}
														id={stageConfig._id}
													/>
												)}
											>
												{isLoading ? (
													Array.from(Array(5).keys()).map(key => {
														return <TempPipelineCardLoading key={key} />;
													})
												) : (
													<>
														{stageCardsCount ? (
															<>
																{stageCards.map((item, index) => (
																	<KanbanCardWrapper
																		record={item}
																		index={index}
																		jobId={jobId}
																		key={item._id}
																		stage={stageConfig}
																	/>
																))}
																{droppableProvided.placeholder}
																{renderCardPlaceholder(snapshot)}
															</>
														) : stageConfig.type === STAGE_TYPE_BID ? (
															<div className={styles.emptyStageContainer}>
																<EmptyStageIcon />
																<p>New bids will be dropped here</p>
															</div>
														) : (
															renderCardPlaceholder(snapshot)
														)}
													</>
												)}
											</Scrollbars>
										</div>
									</div>
								);
							}}
						</Droppable>
					);
				}}
			</Draggable>
			{showDeleteStepModal && (
				<DeletePipelineStepModal
					stepId={stageConfig._id}
					onCancel={() => setShowDeleteStepModal(false)}
					onDelete={() => setShowDeleteStepModal(false)}
				/>
			)}
			{showCantDeleteStepModal && (
				<CantDeletePipelineStepModal
					onClose={() => setShowCantDeleteStepModal(false)}
				/>
			)}
		</>
	);
}
