import React, { useRef, useState } from "react";
import styles from "./email-template-list.module.scss";
import EmailTemplateListItem from "../EmailTemplateListItem";
import useTemplateSearchKeyword from "modules/EmailTemplate/hooks/useTemplateSearchKeyword";
import {
	EMAIL_TEMPLATES_LIST_LIMIT,
	useGetEmailTemplates
} from "modules/EmailTemplate/api/useGetEmailTemplates";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { v4 as uuid } from "uuid";
import { GET_EMAIL_TEMPLATES_QUERY_KEY } from "../../api/useGetEmailTemplates";
import { queryCache } from "react-query";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import useDuplicateEmailTemplate from "../../api/useDuplicateEmailTemplate";
import useDeleteEmailTemplate from "../../api/useDeleteEmailTemplate";
import { getEmailTemplateRoute } from "../../EmailTemplate";
import loadable from "loadable-components";
import { withRouter, browserHistory } from "react-router";
import { Header, Description, Footer } from "common/ConfirmationModal";
import TemplatesNamesListSekeleton from "../TemplatesNamesListSekeleton/TemplatesNamesListSekeleton";
import { TOUR_EMAIL_TEMPLATES } from "config/onboarding";
const ConfirmationModal = loadable(() => import("common/ConfirmationModal"));

const EmailTemplateList = withRouter(({ params: { templateId } }) => {
	const observerTarget = useRef(null);
	const { keywords } = useTemplateSearchKeyword();
	const reduceData = data =>
		data.reduce((acc, val) => [...acc, ...val.data], []);
	const {
		data,
		isFetchingMore,
		isLoading,
		fetchMore,
		canFetchMore
	} = useGetEmailTemplates(
		{
			search: keywords.trim(),
			tag: "all"
		},
		{
			getFetchMore: (lastGroup, allData) => {
				if (reduceData(allData).length === lastGroup.total) {
					return false;
				}
				return lastGroup.offset + EMAIL_TEMPLATES_LIST_LIMIT;
			}
		}
	);
	useInfiniteScroll({
		observerTarget,
		callback: () => {
			if (!isFetchingMore) {
				fetchMore();
			}
		}
	});

	const [
		dublicateTemplate,
		{ isLoading: isDuplicateLoading }
	] = useDuplicateEmailTemplate();
	const onItemDuplicate = item => {
		const newId = uuid();
		dublicateTemplate(
			{
				id: item._id,
				new_email_template_id: newId
			},
			{
				onSuccess() {
					toaster.success("Email template successfully duplicated.");
					queryCache.invalidateQueries(GET_EMAIL_TEMPLATES_QUERY_KEY);
					browserHistory.replace(getEmailTemplateRoute(newId)); // TODO: navigate to template form
				}
			}
		);
	};

	const [itemToDelete, setItemToDelete] = useState(null);
	const [
		deleteTemplate,
		{ isLoading: isDeleteLoading }
	] = useDeleteEmailTemplate();
	const onItemDelete = item => {
		setItemToDelete(item);
	};
	const onConfirmDelete = () => {
		deleteTemplate(
			{
				id: itemToDelete._id
			},
			{
				onSuccess() {
					toaster.success("Email template successfully deleted.");
					queryCache.invalidateQueries(GET_EMAIL_TEMPLATES_QUERY_KEY);
					if (templateId === itemToDelete._id) {
						browserHistory.replace({
							pathname: getEmailTemplateRoute(""),
							state: { forceNavigation: true }
						});
					}
					setItemToDelete(null);
				}
			}
		);
	};

	if (isLoading) {
		return <TemplatesNamesListSekeleton />;
	}

	if (reduceData(data).length === 0) {
		return (
			<div className={styles.emptyStateMessage}>
				{keywords.trim()
					? "No results found"
					: "No email templates. Click the “New” button above to create your first template."}
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<div>
				{reduceData(data).map((item, index) => {
					return (
						<div
							key={item._id}
							data-onboarding-step={
								!index ? `${TOUR_EMAIL_TEMPLATES}-1` : undefined
							}
						>
							<EmailTemplateListItem
								isActive={templateId === item._id}
								item={item}
								onClickDuplicate={() => onItemDuplicate(item)}
								onClickDelete={() => onItemDelete(item)}
							/>
						</div>
					);
				})}
				{canFetchMore && (
					<div ref={observerTarget} className={styles.infiniteLoadingIndicator}>
						{isFetchingMore && <AnimatedLoaderIcon width={20} />}
					</div>
				)}
			</div>

			{!!itemToDelete && (
				<ConfirmationModal
					onClose={() => setItemToDelete(null)}
					isLoading={isDeleteLoading}
				>
					<Header title="Delete email template" />
					<Description>
						<div>
							Are you sure you want to delete&nbsp;
							<span className={styles.templateName}>
								{itemToDelete.template_name}
							</span>
						</div>
					</Description>
					<Footer
						onClickLabel="Delete"
						onClose={() => setItemToDelete(null)}
						onClick={onConfirmDelete}
					/>
				</ConfirmationModal>
			)}

			{isDuplicateLoading && <LoaderFull />}
		</div>
	);
});

export default EmailTemplateList;
