import React, { memo } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import styled from "styled-components";
import { Field, reduxForm } from "redux-form";
import StyledSelect from "common/StyledSelect";
import { prepareForSelect } from "config/helpers";
import CheckBoxInput from "common/CheckBoxInput";

const FlexContainer = styled.div`
	display: flex;
	height: 50px;
	.form-group {
		.checkbox {
			display: flex;
			align-items: center;
			label {
				padding-left: 30px !important;
			}
			&:first-child {
				margin-right: 15px;
			}
			&:last-child {
				margin-top: 0;
			}
		}
	}
`;

const MissionsFilter = ({
	filter,
	filterSettings,
	onFilterChange,
	isClient
}) => {
	const missionTitles = prepareForSelect(
		_get(filterSettings, "mission_titles", []),
		"mission_title"
	);
	const vendorNames = prepareForSelect(
		_get(filterSettings, "vendor_names", []),
		"name"
	);
	const ClientNames = prepareForSelect(
		_get(filterSettings, "company_names", []),
		"company_name"
	);

	return (
		<div className="row">
			{!isClient && (
				<div className="col-md-3 col-md-offset-6">
					<Field
						name="client_id"
						options={ClientNames}
						searchable={true}
						clearable={true}
						placeholder={"Client"}
						label={"Client"}
						component={StyledSelect}
						onChange={e => onFilterChange(e, "client_id")}
						value={filter.company_name}
					/>
				</div>
			)}
			{isClient && (
				<>
					<div className="col-md-3 col-md-offset-3">
						<Field
							name="job_id"
							options={missionTitles}
							searchable={true}
							clearable={true}
							placeholder={"Mission title"}
							label={"Mission title"}
							component={StyledSelect}
							onChange={e => onFilterChange(e, "job_id")}
							value={filter.mission_title}
						/>
					</div>
					<div className="col-md-3">
						<Field
							name="vendor_id"
							options={vendorNames}
							searchable={true}
							clearable={true}
							placeholder={"Consultant"}
							label={"Consultant"}
							component={StyledSelect}
							onChange={e => onFilterChange(e, "vendor_id")}
							value={filter.consultant}
						/>
					</div>
				</>
			)}
			<div className="col-md-3">
				<div className="form-group has-label">
					<label>Status</label>
					<FlexContainer>
						<Field
							component={CheckBoxInput}
							name="ongoing"
							checked={filter.status === "ongoing"}
							onChange={onFilterChange}
							formGroupClassName="checkbox-input"
						>
							Ongoing
						</Field>
						<Field
							component={CheckBoxInput}
							name="finished"
							checked={filter.status === "finished"}
							onChange={onFilterChange}
							formGroupClassName="checkbox-input"
						>
							Finished
						</Field>
					</FlexContainer>
				</div>
			</div>
		</div>
	);
};

MissionsFilter.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChange: PropTypes.func.isRequired
};

MissionsFilter.defaultProps = {
	onFilterChange: () => {}
};

export default memo(
	reduxForm({
		form: "mission-filter"
	})(MissionsFilter)
);
