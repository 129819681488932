export const columnSortMap = {
	source: "source",
	interviewed: "interviewed",
	_id: "reference",
	first_name: "first_name",
	last_name: "last_name",
	email_address: "email",
	phone_number: "phone",
	external_profile: "external_link",
	zip_code: "zip_code",
	payroll_country: "payroll_country",
	notice_period: "notice_period",
	extra_benefits: "extra_benefits",
	category: "category",
	subcategory: "subcategory",
	functions: "functions",
	seniority: "seniority",
	skills: "skills",
	languages: "languages",
	profile_tags: "profile_tags",
	last_note: "last_note",
	notes: "notes",
	is_viewed: "is_viewed",
	is_referred: "is_referred",
	is_active: "is_active",
	gross_net: "payment_type_expected",
	salary_type: "payment_time_expected",
	other_extra_benefits: "expected_salary_advantage",
	employment_type: "employment_types",
	preferred_locations: "preferred_location",
	profile_created_on: "registration_date",
	currency_salary: "symbol_expected",
	profile_created_by: "created_by",
	is_activated: "is_active",
	residence: "main_location",
	expected_salary: "expected_salary"
};

export const columnFiltersMap = {
	source: "source",
	interviewed: "interviewed",
	_id: "id",
	first_name: "first_name",
	last_name: "last_name",
	email_address: "email",
	phone_number: "phone",
	external_profile: "external_link",
	residence: "residence",
	preferred_locations: "preferred_location",
	zip_code: "zip_code",
	employment_type: "employment_type",
	currency_salary: "currency",
	payroll_country: "payroll_country",
	salary_type: "salary_type",
	gross_net: "payment_type",
	notice_period: "notice_period",
	extra_benefits: "extra_benefits",
	category: "category",
	sub_category: "subcategory",
	functions: "functions",
	seniority: "seniority",
	skills: "skills",
	languages: "languages",
	profile_tags: "profile_tags",
	last_note: "last_note",
	notes: "notes",
	is_viewed: "is_viewed",
	is_referred: "is_referred",
	is_active: "is_active",
	profile_created_on: "created_at",
	profile_created_by: "created_by",
	other_extra_benefits: "expected_salary_advantage",
	is_activated: "is_active"
};
