import * as types from "../actions/actionTypes";
import immutable from "seamless-immutable";
import _get from "lodash/get";
import _findIndex from "lodash/findIndex";
import _remove from "lodash/remove";
import {
	HOURS_INCORRECT,
	PRICE_INCORRECT,
	DATE_INCORRECT,
	CLIENT,
	WORKTIME,
	EXPENSE,
	ACCEPTED,
	REFUSED,
	DISPUTED,
	REFUSE,
	ACCEPT
} from "config";

const getDefaultUsersView = () => {
	let hash = window.location.hash.substring(1);
	if (hash && (hash === WORKTIME || hash === EXPENSE)) {
		return hash;
	}
	return WORKTIME;
};

const initialState = immutable({
	data: {},
	isFetching: false,
	isFetchingWeek: false,
	error: null,
	view: getDefaultUsersView(),
	statusView: ACCEPTED,
	currentDocument: null,
	currentDocumentEditMode: null,
	currentDocumentMultiEditMode: null,
	activeAction: null,
	currentTime: null,
	editItem: null,
	showReasonModal: false,
	warned: false,
	disputeModal: false,
	deleteModal: false,
	deleteItem: {},
	disputedModal: false,
	showEditTimesheet: false,
	showResubmitModal: false,
	disputed: false,
	updatedexpensesList: {
		accepted: [],
		disputed: [],
		refused: []
	},
	updatedworktimesList: {
		accepted: [],
		disputed: [],
		refused: []
	},
	timesheetList: [],
	newXls: {},
	newPdf: {},
	fileFormFields: {
		name: "",
		path: ""
	},
	nameFieldCurrentIndex: -1
});

export default function(state = initialState, action) {
	let data = {};
	let week = {};
	switch (action.type) {
		case types.SAVE_TIMESHEET_LIST:
			return immutable.merge(state, { timesheetList: action.data });
		case types.START_FETCHING:
			return immutable.merge(state, { isFetching: true });
		case types.INITIALIZE_TIMESHEET:
			return immutable.merge(state, initialState);
		case types.TOGGLE_RESUBMIT_TIMESHEET:
			return immutable.merge(state, {
				showResubmitModal: !state.showResubmitModal
			});
		case types.SWITCH_VIEW:
			return immutable.merge(state, {
				view: action.view,
				editItem: null
			});
		case types.CATCH_NOTIFICATIONS_TIMESHEETS_XLS:
			return immutable.merge(state, {
				timesheetList: state.timesheetList.map(t => {
					if (t._id === action.data._id) {
						return {
							...t,
							excel_link_download: action.data.excel_link_download
						};
					}
					return t;
				}),
				data: {
					...state.data,
					excel_link_download: action.data.excel_link_download
				},
				newXls: {
					_id: action.data._id
				}
			});
		case types.CATCH_NOTIFICATIONS_TIMESHEETS_PDF:
			return immutable.merge(state, {
				timesheetList: state.timesheetList.map(t => {
					if (t._id === action.data._id) {
						return {
							...t,
							pdf_link_download: action.data.pdf_link_download
						};
					}
					return t;
				}),
				data: {
					...state.data,
					pdf_link_download: action.data.pdf_link_download
				},
				newPdf: {
					_id: action.data._id
				}
			});
		case types.WRITE_TIMESHEET:
			return immutable.merge(state, {
				data: action.data,
				// do not change this value because it's used to compare initial data with current data in timesheet modals
				initialData: [
					...action.data.month.expenses,
					...action.data.month.worktimes
				]
			});

		case types.TOGGLE_GENERAL_DISPUTE:
			return immutable.merge(state, {
				disputed: false
			});

		case types.HANDLE_DOCUMENT_UPLOAD:
			if (action.editMode) {
				return immutable.merge(state, {
					currentDocumentEditMode: action.data.url
				});
			}
			if (action.multiEdit) {
				return immutable.merge(state, {
					currentDocumentMultiEditMode: action.data.url
				});
			}
			return immutable.merge(state, { currentDocument: action.data.url });
		case types.SET_TIME:
			return immutable.merge(state, { currentTime: action.current_time });
		// ======> refuse item
		case types.REFUSE_DISPUTED_ITEM:
			let __newList = state[`updated${action.list}List`];
			let __modifiedList = Object.assign(
				[],
				_get(state, `data.month.${action.list}`, [])
			);
			const __index = _findIndex(__modifiedList, { _id: action._id });
			if (__index !== -1) {
				__modifiedList[__index] = {
					...__modifiedList[__index],
					status: REFUSED,
					disputed: true
				};

				__newList = {
					...__newList,
					refused: [...__newList.refused, action._id]
				};
			}

			return immutable.merge(state, {
				data: {
					...state.data,
					month: { ...state.data.month, [action.list]: __modifiedList }
				},
				[`updated${action.list}List`]: __newList,
				disputedModal: false
			});

		// ======> approve item
		case types.APPROVE_DISPUTED_ITEM:
			let _modifiedList = Object.assign(
				[],
				_get(state, `data.month.${action.list}`, [])
			);
			let _newList = state[`updated${action.list}List`];
			const _index = _findIndex(_modifiedList, {
				_id: action._id
			});

			const _refused = [..._newList.refused];
			_remove(_refused, item => item === action._id);

			if (_index !== -1) {
				_modifiedList[_index] = {
					..._modifiedList[_index],
					hours: _get(_modifiedList, `[${_index}].dispute.hours`),
					status: ACCEPTED,
					disputed: false
				};
				_newList = {
					..._newList,
					accepted: [..._newList.accepted, action._id],
					refused: _refused
				};
			}

			return immutable.merge(state, {
				data: {
					...state.data,
					month: { ...state.data.month, [action.list]: _modifiedList }
				},
				[`updated${action.list}List`]: _newList,
				disputedModal: false
			});

		// ======> edit item
		case types.EDIT_ITEM:
			let modifiedList = Object.assign(
				[],
				_get(state, `data.month.${action.list}`, [])
			);
			const index = _findIndex(modifiedList, { _id: action._id });
			modifiedList[index] = { ...action.item, _id: action._id, disputed: true };
			const list = state[`updated${action.list}List`];
			const newList = {
				...list,
				disputed: [...list.disputed]
			};
			_remove(newList.disputed, elmt => elmt[action.view_id] === action._id);
			newList.disputed.push({
				[action.view_id]: action._id,
				comments: action.item.comments,
				description: action.item.description,
				date: action.item.date,
				hours: action.item.back_hours,
				rate: action.item.rate,
				price: action.item.price,
				tag: action.item.tag
			});
			return immutable.merge(state, {
				data: {
					...state.data,
					month: { ...state.data.month, [action.list]: modifiedList }
				},
				[`updated${action.list}List`]: newList,
				disputedModal: false,
				disputed: true
			});
		case types.UPDATE_COMPUTED:
			data = JSON.parse(JSON.stringify(state.data));

			return immutable.merge(state, {
				data: {
					...state.data,
					total_worktimes: action.total_worktimes,
					total_expenses: action.total_expenses,
					total_amount: action.total_amount
				}
			});
		case types.TOGGLE_ACTION:
			const newData = JSON.parse(JSON.stringify(state.data));
			const key =
				action.userType === CLIENT ? "client_action" : "vendor_action";
			for (const week of newData.weeks) {
				for (const item of week[`${state.view}s`]) {
					if (item._id === action.item_id) {
						if (action.action === ACCEPT) {
							item[key] = ACCEPTED;
						}
						if (action.action === REFUSE || action.action === DISPUTED) {
							item[key] = action.userType === CLIENT ? DISPUTED : REFUSED;
						}
						break;
					}
				}
			}
			return immutable.merge(state, { data: newData });
		case types.ACCEPT_ALL:
			let currentWeek = immutable.asMutable(state.week);
			for (week of action.weeks) {
				if (week._id === currentWeek) currentWeek = week;
			}
			return immutable.merge(state, {
				week: currentWeek,
				data: { ...state.data, weeks: action.weeks }
			});
		case types.SHOW_REASON_MODAL:
			return immutable.merge(state, {
				showReasonModal: true,
				editItem: action.item
			});
		case types.TOGGLE_DELETE_MODAL:
			return immutable.merge(state, {
				deleteModal: !state.deleteModal,
				deleteItem: action.item || {}
			});
		case types.TOGGLE_DISPUTE_MODAL:
			return immutable.merge(state, {
				disputeModal: !state.disputeModal
			});
		case types.CLOSE_REASON_MODAL:
			return immutable.merge(state, {
				showReasonModal: false,
				editItem: null
			});
		case types.DISPUTE_ENTRY:
			data = JSON.parse(JSON.stringify(state.data));
			currentWeek = data.weeks.find(week => week._id === state.week);
			for (const item of currentWeek[`${state.view}s`]) {
				if (item._id === state.editItem._id) {
					item.reason = action.payload.reason;
					item.dispute_description = action.payload.dispute_description;
					switch (item.reason) {
						case DATE_INCORRECT:
							item.dispute_date = action.payload.dispute_date;
							break;
						case HOURS_INCORRECT:
							item.dispute_hours = action.payload.dispute_hours;
							break;
						case PRICE_INCORRECT:
							item.dispute_price = action.payload.dispute_price;
							break;
						default:
							return item;
					}
					break;
				}
			}
			return immutable.merge(state, {
				data,
				showReasonModal: false,
				editItem: null
			});
		case types.SWITCH_STATUS_VIEW:
			return immutable.merge(state, { statusView: action.status });

		case types.EMPTY_CURRENT_DOCUMENT:
			return immutable.merge(state, { currentDocument: null });
		case types.EMPTY_CURRENT_DOCUMENT_EDIT_MODE:
			return immutable.merge(state, { currentDocumentEditMode: null });
		case types.EMPTY_CURRENT_DOCUMENT_MULTI_EDIT_MODE:
			return immutable.merge(state, { currentDocumentMultiEditMode: null });
		case types.TOGGLE_DISPUTE_WARNING:
			return immutable.merge(state, { warned: !state.warned });

		case types.TOGGLE_DISPUTED_MODAL:
			return immutable.merge(state, {
				disputedModal: !state.disputedModal,
				disputedTimesheet: action.item
			});

		case types.TOGGLE_DISPUTE_TIMESHEET:
			return immutable.merge(state, {
				showEditTimesheet:
					action.data !== undefined ? action.data : !state.showEditTimesheet
			});
		case types.SET_FILE_FORM:
			return immutable.merge(state, {
				fileFormFields: {
					...state.fileFormFields,
					...action.fields
				}
			});
		case types.SET_FIELD_INDEX:
			return immutable.merge(state, {
				nameFieldCurrentIndex: action.index
			});
		case types.CATCH_NOTIFICATIONS_EVENT_TIMESHEET_RESUBMITTED:
			return immutable.merge(state, {
				data: {
					...state.data,
					is_edited: action.data
				}
			});
		case types.CATCH_NOTIFICATIONS_EVENT_TIMESHEET_DISPUTED:
			return immutable.merge(state, {
				data: {
					...state.data,
					is_edited: action.data
				}
			});
		default:
			return state;
	}
}

export const findByStatus = (state, status = "accepted") => {
	if (!state.data.weeks) return;
	const weeks = JSON.parse(JSON.stringify(state.data.weeks));
	const wk = weeks.find(w => w._id === state.week);
	if (wk) {
		return wk[`${state.view}s`].filter(item => item.status === status);
	}
	return false;
};
