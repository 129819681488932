const checkPasswordRules = password => {
	const error = { txt: "" };

	if (!password || password.trim() === "") {
		error.txt = "Password is required";
	} else if (!/[a-z]+/i.test(password)) {
		error.txt = "At least one letter";
	} else if (!/[A-Z]+/.test(password)) {
		error.txt = "At least one uppercase letter";
	} else if (!/[\d]+/.test(password)) {
		error.txt = "At least one number";
	} else if (!/[!@$%^?*)(+=._;,#/\\&-]/g.test(password)) {
		error.txt = "At least one special character";
	} else if (password && password.length < 8) {
		error.txt = "At least 8 characters long";
	}

	return error.txt;
};

export default checkPasswordRules;
