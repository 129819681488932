import React from "react";
import styles from "./email-signature-decorator.module.scss";
import { ReactComponent as LinkedinIcon } from "static/icons/social/linkedin.svg";
import { ReactComponent as FacebookIcon } from "static/icons/social/facebook.svg";
import { ReactComponent as XIcon } from "static/icons/social/x.svg";
import { ReactComponent as InstagramIcon } from "static/icons/social/instagram.svg";
import { ReactComponent as PhoneIcon } from "static/icons/email-signature/phone.svg";
import { ReactComponent as EmailIcon } from "static/icons/email-signature/email.svg";
import { ReactComponent as HomeIcon } from "static/icons/email-signature/home.svg";
import { CustomInput } from "common/CustomInput";
import cx from "classnames";
import { $isEmailSignatureNode } from "common/Editor/nodes/EmailSignatureNode";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNodeByKey } from "lexical";

const icons = {
	linkedin: <LinkedinIcon width={24} height={24} />,
	facebook: <FacebookIcon width={24} height={24} />,
	twitter: <XIcon width={24} height={24} />,
	instagram: <InstagramIcon width={24} height={24} />
};

const EmailSignatureDecorator = ({ nodeKey, signature = {} }) => {
	const [editor] = useLexicalComposerContext();

	if (!signature) return null;
	const {
		signature_full_name,
		signature_address,
		signature_avatar,
		signature_email,
		signature_position,
		signature_social_media,
		signature_phone
	} = signature;

	const onChangeSignature = (value, name) => {
		editor.update(() => {
			const node = $getNodeByKey(nodeKey);
			if ($isEmailSignatureNode(node)) {
				node.setSignature({
					signature_full_name,
					signature_phone,
					signature_avatar,
					signature_social_media,
					signature_position,
					signature_email,
					signature_address,
					[name]: value
				});
			}
		});
	};
	return (
		<div className={styles.content}>
			<div className={styles.contentContainer}>
				{!!signature_avatar && (
					<div className={styles.avatar}>
						<img src={signature_avatar} />
					</div>
				)}
				<div className={styles.form}>
					{!!signature_full_name && (
						<CustomInput
							value={signature_full_name}
							onChange={event =>
								onChangeSignature(event.target.value, "signature_full_name")
							}
							className={cx(styles.input, styles.fullName)}
						/>
					)}
					{!!signature_position && (
						<CustomInput
							value={signature_position}
							onChange={event =>
								onChangeSignature(event.target.value, "signature_position")
							}
							className={styles.input}
						/>
					)}
					{!!signature_phone && (
						<div className={styles.info}>
							<div className={styles.icon}>
								<PhoneIcon />
							</div>
							<CustomInput
								value={signature_phone}
								onChange={event =>
									onChangeSignature(event.target.value, "signature_phone")
								}
								className={styles.input}
							/>
						</div>
					)}
					{!!signature_email && (
						<div className={styles.info}>
							<div className={styles.icon}>
								<EmailIcon />
							</div>
							<CustomInput
								value={signature_email}
								onChange={event =>
									onChangeSignature(event.target.value, "signature_email")
								}
								className={styles.input}
							/>
						</div>
					)}
					{!!signature_address && (
						<div className={styles.info}>
							<div className={styles.icon}>
								<HomeIcon />
							</div>
							<CustomInput
								value={signature_address}
								onChange={event =>
									onChangeSignature(event.target.value, "signature_address")
								}
								className={styles.input}
							/>
						</div>
					)}
					<div className={styles.socialIcons}>
						{signature_social_media?.map(({ url, platform }) => {
							if (!url) return null;
							else
								return (
									<a key={platform} href={url} target="_blank" rel="noreferrer">
										{icons[platform]}
									</a>
								);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmailSignatureDecorator;
