import React, { useState } from "react";
import styles from "./add-timesheet-entries-upload-card.module.scss";
import { AddTimesheetEntriesCard } from "modules/timesheets/components/AddTimesheetEntriesDrawerBody/AddTimesheetEntriesCard";
import { ReactComponent as DocIcon } from "static/icons/doc.svg";
import { ReactComponent as ArrowDown } from "static/icons/chevron-down.svg";
import { ReactComponent as ArrowUp } from "static/icons/chevron-up.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete-circle.svg";

import { TYPE_PDF, TYPE_DOC, TYPE_DOCX } from "config";
import { TIMESHEET_ATTACHMENTS } from "modules/timesheetsModule/utils/constants";
import { Uploader } from "common/react-hook-form-components/uploader/coumpound_uploader";
import { bytesToSize } from "config/helpers";
import { SUPPORTED_SIZE } from "config";
import toaster from "common/Toaster";
import AccordionRx from "common/AccordionRx";
import Tooltip from "common/Tippy";
import useEntries from "modules/timesheets/hooks/useEntries";
import { TIMESHEET_ATTACHMENT_S3_FOLDER } from "config";

const AddTimesheetEntriesUploadCard = ({
	onUpload,
	isExtracting,
	files,
	timesheetId
}) => {
	const [file, setFile] = useState([]);
	const [open, setOpen] = useState("");
	const { removeFile } = useEntries();

	const onDropRejected = fileRejections => {
		const REJECTIONS = {
			"file-invalid-type": "Invalid file type.",
			"file-too-large": `The file size should not exceed ${bytesToSize(
				SUPPORTED_SIZE
			)}.`
		};
		if (fileRejections.length) {
			fileRejections.forEach(({ errors }) => {
				errors.forEach(error =>
					toaster.danger(REJECTIONS[error.code], {
						duration: 8,
						id: error.code
					})
				);
			});
		}
	};
	const onDrop = acceptedFiles => {
		if (acceptedFiles.length > 0) {
			setFile(acceptedFiles);
		}
	};
	return (
		<AddTimesheetEntriesCard>
			<div className={styles.label}>
				<DocIcon />
				<div>{TIMESHEET_ATTACHMENTS}</div>
			</div>
			<Uploader>
				{file.length === 0 && (
					<Uploader.Input
						onDrop={onDrop}
						multiple={false}
						onDropRejected={onDropRejected}
						maxFiles={1}
						isReadOnly={false}
						filesTypesDescription={"PDF, DOC, DOCX"}
						accept={[TYPE_PDF, TYPE_DOC, TYPE_DOCX]}
						noClick={false}
					/>
				)}
				{file.map((acceptedFile, index) => (
					<Uploader.Progress
						key={index}
						index={index}
						file={acceptedFile}
						isExtracting={isExtracting}
						setFiles={setFile}
						onUrlChange={val => {
							onUpload(val);
							setFile([]);
						}}
						s3Folder={TIMESHEET_ATTACHMENT_S3_FOLDER}
					/>
				))}
			</Uploader>
			{files && files.length > 0 && (
				<AccordionRx
					collapsible
					type="single"
					value={open}
					onValueChange={setOpen}
					className={styles.accordion}
				>
					<AccordionRx.Item value={"files"}>
						<AccordionRx.Trigger className={styles.triggerContainer}>
							<div className={styles.headerText}>
								<DocIcon />{" "}
								<span>
									{" "}
									{files?.length} file{files?.length > 1 ? "s" : ""} uploaded
								</span>
							</div>
							<div className={styles.toggle}>
								{open === "files" ? <ArrowUp /> : <ArrowDown />}
							</div>
						</AccordionRx.Trigger>
						<AccordionRx.Content className={styles.accordionContent}>
							{files.map((file, index) => {
								return (
									<div className={styles.fileInfo} key={index}>
										<Tooltip
											theme="dark"
											content={file?.file_name}
											overflow="hidden"
										>
											<div className={styles.name}>{file.file_name}</div>
										</Tooltip>

										<div className={styles.size}>
											{bytesToSize(file.size)} |
											<button
												className={styles.deleteButton}
												onClick={() => removeFile(timesheetId, file)}
											>
												<DeleteIcon />
											</button>
										</div>
									</div>
								);
							})}
						</AccordionRx.Content>
					</AccordionRx.Item>
				</AccordionRx>
			)}
		</AddTimesheetEntriesCard>
	);
};

export default AddTimesheetEntriesUploadCard;
