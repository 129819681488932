import React, { useContext, useState, useEffect, createRef } from "react";
import Autocomplete from "react-google-autocomplete";
import styled from "styled-components";
import _get from "lodash/get";
import { BotContext, BOT_ACTION_TYPES } from "./botContext";
import { Button } from "common/styled/buttons";

const LocationContainer = styled.form`
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
`;

const SaveButton = styled(Button)`
	width: 40px;
	height: 40px;
	border-radius: 9999px;
	color: white !important;
	position: absolute;
	right: 10px;
	> span {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const noop = () => {};

const inputRef = createRef();

function LocationAnswer({ triggerNextStep, emulateSubmitUserMessage }) {
	const { state, dispatch } = useContext(BotContext);
	const [value, setValue] = useState();
	const [canSubmit, setCanSubmit] = useState(false);
	const [location, setLocation] = useState();

	const disabled = !!state.selectedLocation;

	useEffect(() => {
		if (inputRef.current && inputRef.current.refs.input) {
			if (disabled) {
				inputRef.current.refs.input.setAttribute("disabled", true);
			} else {
				setTimeout(() => {
					inputRef.current.refs.input.focus();
				}, 0);
			}
		}
	}, [disabled, inputRef.current]);

	const handlePlaceSelected = p => {
		setLocation(p);
		setCanSubmit(true);
		const fields = {
			locality: "city",
			country: "country"
		};
		const address = p.address_components.reduce((acc, val) => {
			Object.keys(fields).forEach(key => {
				if (val.types.indexOf(key) !== -1) {
					acc[fields[key]] = val.long_name;
				}
			});
			return acc;
		}, {});
		setValue(`${address.city}, ${address.country}`);
	};

	const handleSubmit = e => {
		e.preventDefault();
		emulateSubmitUserMessage(value);
		dispatch({
			type: BOT_ACTION_TYPES.SET_SELECTED_LOCATION,
			location: {
				box: "",
				city: _get(location, "address_components.0.long_name", ""),
				country: _get(location, "address_components.2.long_name"),
				latitude: _get(location, "geometry.location.lat", noop)(),
				longitude: _get(location, "geometry.location.lng", noop)(),
				number: "",
				street: "",
				zip: "8000",
				is_main: true
			}
		});
		triggerNextStep({ value, trigger: "pre-salary" });
	};

	return (
		<LocationContainer onSubmit={disabled || !canSubmit ? null : handleSubmit}>
			<Autocomplete
				className="form-control address-control"
				onPlaceSelected={handlePlaceSelected}
				placeholder={"Start typing to choose a location..."}
				types={["geocode"]}
				value={value}
				onChange={e => setValue(e.target.value)}
				style={{
					width: "50%"
				}}
				ref={inputRef}
			/>
			<SaveButton small disabled={disabled || !canSubmit} type="submit">
				<span className="icon icon-arrow-right1" />
			</SaveButton>
		</LocationContainer>
	);
}

export default LocationAnswer;
