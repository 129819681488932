import React from "react";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";

const DeleteTemplateModal = ({ closeModal, deleteTemplate, isClient }) => (
	<ConfirmationModal
		title={`Are you sure you would like to delete this ${
			isClient ? "community" : "template"
		}`}
		onClose={closeModal}
		type={ConfirmationTypes.error}
		firstButton={{
			action: deleteTemplate,
			type: "warning",
			label: "YES, DELETE TEMPLATE"
		}}
	/>
);

export default DeleteTemplateModal;
