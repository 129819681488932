import React from "react";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import styles from "./messaging-tool-filter-item-tag.module.scss";

const MessagingToolFilterItemTag = ({ onDelete, name, item }) => {
	return (
		<div className={styles.tag} key={item}>
			<span className={styles.tagLabel}>{item}</span>
			<button
				className={styles.tagIcon}
				onClick={e => {
					e.preventDefault();
					onDelete(name, item);
				}}
			>
				<CloseIcon className={styles.closeIcon} />
			</button>
		</div>
	);
};

export default MessagingToolFilterItemTag;
