import React from "react";
import {
	DECIMAL_SEPARATOR,
	THOUSAND_SEPARATOR,
	EUR,
	DECIMAL_PRECISION
} from "../config";
import { toEuro } from "../common/Functions";
import NumberFormat from "react-number-format";

function CurrencyFormatter({
	className = "",
	noSymbol,
	cent,
	symbol = EUR,
	euro
}) {
	let value = euro >= 0 ? euro : toEuro(cent);
	return (
		<NumberFormat
			displayType="text"
			className={`price-formatted ${className}`}
			value={value}
			decimalSeparator={DECIMAL_SEPARATOR}
			thousandSeparator={THOUSAND_SEPARATOR}
			decimalScale={DECIMAL_PRECISION}
			suffix={noSymbol ? null : symbol}
		/>
	);
}

export default CurrencyFormatter;
