import React from "react";
import icon from "../static/icons/site-down.png";
import { HOME_URL } from "../config";

const WebSiteDown = () => {
	return (
		<div id="service-down">
			<div className="text-center">
				<img src={icon} alt="Web site is down" />
				<h4>
					<span className="bold">Whoops</span> Web site is down
				</h4>
				<p>
					We&#39;re experiencing a problem here, our developers are on the case.
					Please try it later. Please come back later or contact us via{" "}
					<a
						className="bold"
						href={`${HOME_URL}/contact-us?your-subject=${encodeURIComponent(
							"Website is down"
						)}`}
					>
						support@hire-me.io
					</a>
				</p>
				<button
					onClick={() => window.location.reload()}
					className="btn btn-primary"
				>
					Refresh
				</button>
			</div>
		</div>
	);
};

export default WebSiteDown;
