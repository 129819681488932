import React from "react";
import Loader from "common/Loader";
import styles from "./styles.module.scss";
import { Box } from "rebass/styled-components";
import globalStyles from "../styles.module.scss";
import SettingsRadioBtn from "./SettingsRadioBtn";
import { PUBLIC, INACTIVE, PASSIVE } from "config";
import { useSelector } from "react-redux";
import { ReactComponent as StatusActiveIcon } from "static/icons/statusActiveIcon.svg";
import { ReactComponent as StatusPassiveIcon } from "static/icons/statusPassiveIcon.svg";
import { ReactComponent as StatusInactiveIcon } from "static/icons/StatusInactiveIcon.svg";
import { useUpdateUserStatus } from "modules/user/api/useUpdateUserStatus";

const StatusSettings = () => {
	const status = useSelector(state => state.auth.user.candidate_status);
	const [updateStatus, { isLoading }] = useUpdateUserStatus();
	const onSwitch = status => updateStatus({ status });

	return (
		<div
			className={globalStyles.white_box}
			style={{ marginBottom: "32px", position: "relative" }}
		>
			{isLoading && (
				<Box sx={{ position: "absolute", top: "30px", right: "30px" }}>
					<Loader check={false} classes={styles.status__loader} />
				</Box>
			)}
			<div className={globalStyles.white_box__title}>Status</div>
			<div className={styles.status__settings}>
				<div className={styles.status__leftColumn}>
					<SettingsRadioBtn
						name="active"
						label={"Active"}
						onChange={() => onSwitch(PUBLIC)}
						checked={status === PUBLIC}
						icon={<StatusActiveIcon />}
						description={
							"You can be found and contacted by companies. You can apply for jobs."
						}
					/>
					<SettingsRadioBtn
						name="passive"
						label={"Passive"}
						onChange={() => onSwitch(PASSIVE)}
						checked={status === PASSIVE}
						icon={<StatusPassiveIcon />}
						description={
							"You will not be found by new companies, but you can still apply for jobs."
						}
					/>
					<SettingsRadioBtn
						name="inactive"
						label={"Inactive"}
						onChange={() => onSwitch(INACTIVE)}
						checked={status === INACTIVE}
						icon={<StatusInactiveIcon />}
						description={
							"You cannot be found or apply. All your current processes are put on hold."
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default StatusSettings;
