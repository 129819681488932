import React, { Component } from "react";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import _get from "lodash/get";
import CompanyDashboard from "common/dashboard/Dashboard";
import {
	loadProfile,
	showModal,
	clearProfileData,
	loadProfileUser
} from "../actions";
import { cvSelector } from "../selectors/cvSelectors";
import { SKILLS, CONSULTANCY, PERMANENT, CLIENT, FREELANCER } from "config";
import { authenticatedUserSelector } from "../../bids/selectors";
import PermanentProfile from "modules/candidate/components/ProfileContainer";
import { viewContactInfo } from "modules/bids/actions/bidActions";
import ConsultantProfile from "./ConsultantProfile";
import FreelancerProfile from "./FreelancerProfile";
import { axiosPropsSelectors } from "modules/app/selectors";
import { bannerToBeReviewedSelector } from "modules/user/selectors/selectors";

export const consultantGetClientResumeRoute = () => {
	return "/consultant/user/profile/:bid_id";
};

export const getResumeRoute = () => {
	return "/my-cv";
};
export const getClientResumePermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			},
			{
				name: "consultancy",
				roles: ["admin", "project_manager"]
			}
		]
	};
};
export const getResumePermission = () => {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			},
			{
				name: "consultancy",
				roles: ["admin", "project_manager"]
			},
			{
				name: "permanent",
				roles: ["admin"]
			}
		]
	};
};

class ResumeContainer extends Component {
	componentDidMount() {
		let search = window.location.search;
		let pathnameSplit = window.location.pathname.split("/");

		if (
			search &&
			search.split("/")[2] &&
			pathnameSplit[1] === "user" &&
			pathnameSplit[2] === "profile"
		) {
			return this.props
				.viewContactInfo({
					id: pathnameSplit[3],
					type: "contact",
					activity_type: "bid"
				})
				.then(() => {
					this.getBackUrl();
					this.viewProfile();
				});
		}
		this.viewProfile().then(() => {
			// the link "my-cv?skills" should open skills modal by default
			if (search && search === `?${SKILLS}`) {
				this.props.showModal(SKILLS);
			}
		});
		this.getBackUrl();
	}

	viewProfile = newBidId => {
		const searchQuery = _get(this.props, "location.query");
		const {
			params: { bid_id = false },
			loadProfile,
			loadProfileUser
		} = this.props;
		if (
			searchQuery.list !== "bid" &&
			(searchQuery?.source === "search" ||
				searchQuery?.source === "list" ||
				searchQuery?.source === "tasks")
		) {
			return loadProfileUser({ id: newBidId || bid_id, tag: "user_profile" });
		} else {
			return loadProfile(newBidId || bid_id);
		}
	};

	getBackUrl = () => {
		const search = _get(window, "location.search");
		const hash = _get(window, "location.hash", "");
		const urlSplit = search && search.split("=");
		this.showBack = urlSplit.length && urlSplit[1] ? urlSplit[1] + hash : null;
	};

	componentDidUpdate({ params: { bid_id } }) {
		const newBidId = _get(this.props, "params.bid_id");
		if (bid_id && bid_id !== newBidId) {
			this.viewProfile(newBidId);
		}
	}

	componentWillUnmount() {
		return this.props.clearProfileData();
	}

	displayProfile = () => {
		const {
			headline,
			functions = [],
			user,
			user: { company_type },
			modalName,
			front_sheet: { preference } = {},
			cv_doc,
			group = {},
			status,
			params: { bid_id },
			ownCV,
			activeTab,
			cv_docs,
			initialSkills,
			_id,
			languages,
			skills,
			inAction,
			isFetching,
			history
		} = this.props;

		let isClient = company_type === CLIENT;

		let _ownCV = ownCV || company_type === CONSULTANCY;

		const searchQuery = _get(this.props, "location.query.source");

		switch (_get(headline, "type")) {
			case FREELANCER:
				return (
					<FreelancerProfile
						bid_id={bid_id}
						_ownCV={_ownCV}
						headline={headline}
						functions={functions}
						activeTab={activeTab}
						isClient={isClient}
						showBack={this.showBack}
						viewProfile={this.viewProfile}
						cv_doc={cv_doc}
						cv_docs={cv_docs}
						showModal={this.props.showModal}
						user={user}
						status={status}
						initialSkills={initialSkills}
						id={_id}
						history={history}
						searchQuery={searchQuery}
					/>
				);

			case PERMANENT:
				return (
					<PermanentProfile
						user={user}
						headline={headline}
						functions={functions}
						preference={preference}
						languages={languages}
						cv_doc={cv_doc}
						viewProfile={this.viewProfile}
						skills={skills}
						modalName={modalName}
						cv_docs={cv_docs}
						inAction={inAction}
						isFetching={isFetching}
						displayBannerToBeReviewed={this.props.displayBannerToBeReviewed}
					/>
				);

			case CONSULTANCY:
				return (
					<ConsultantProfile
						bid_id={bid_id}
						_ownCV={_ownCV}
						headline={headline}
						functions={functions}
						activeTab={activeTab}
						isClient={isClient}
						group={group}
						status={status}
						showBack={this.showBack}
						viewProfile={this.viewProfile}
						history={history}
						showModal={this.props.showModal}
						initialSkills={initialSkills}
						cv_docs={cv_docs}
					/>
				);

			default:
				return <></>;
		}
	};

	render() {
		const {
			route: { title },
			headline,
			user: { company_type }
		} = this.props;
		let isClient = company_type === CLIENT;
		const isPermanent = _get(headline, "type") === PERMANENT;

		return (
			<BodyClassName className="company gray-bg">
				<div>
					{title && (
						<Helmet>
							<title>{title} </title>
						</Helmet>
					)}
					<div id="resume">
						{!isClient && !isPermanent && (
							<CompanyDashboard isPermanent={isPermanent} />
						)}
						{this.displayProfile()}
					</div>
				</div>
			</BodyClassName>
		);
	}
}

const mapStateToProps = state => {
	return {
		...cvSelector(state),
		user: authenticatedUserSelector(state),
		initialSkills: state.cv.skills,
		modalName: state.cv.modalName,
		inAction: axiosPropsSelectors(state).inAction,
		displayBannerToBeReviewed: bannerToBeReviewedSelector(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loadProfile: payload => dispatch(loadProfile(payload)),
		loadProfileUser: payload => dispatch(loadProfileUser(payload)),
		showModal: type => dispatch(showModal(type)),
		viewContactInfo: payload => dispatch(viewContactInfo(payload)),
		clearProfileData: () => dispatch(clearProfileData())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeContainer);
