import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../rootReducer";

const createWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);

const store = createWithMiddleware(
	rootReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

if (window.Cypress) {
	window.store = store;
}

export default store;
