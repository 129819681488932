import React from "react";
import styles from "./tasks-list-tasks-counter.module.scss";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { TASKS_LIST_FILTER_ID } from "modules/TasksList/utils/constant";

const TasksListTasksCounter = () => {
	const { getState } = useFilters();
	const { data: tasksList, isFetching, isLoading } = getState(
		TASKS_LIST_FILTER_ID
	);

	const total = tasksList?.pagination?.total;

	return (
		<p className={styles.results_count}>
			<span className={styles.results_count}>
				{isLoading || isFetching ? (
					"Loading..."
				) : (
					<span className={styles.grayText}>
						{total === 0 ? (
							"No result found"
						) : (
							<span className={styles.grayText}>
								<span className={styles.greenText}>
									{total?.toLocaleString()?.replace(",", " ")}
								</span>
								&nbsp;
								{total > 1 ? "results" : "result"} in total
							</span>
						)}
					</span>
				)}
			</span>
		</p>
	);
};

export default TasksListTasksCounter;
