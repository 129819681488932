import React from "react";
import { createCalendar } from "@internationalized/date";
import { useDateField, useDateSegment } from "react-aria";
import { useLocale } from "react-aria";
import { useDateFieldState } from "@react-stately/datepicker";
import cx from "classnames";
import styles from "./date-field.module.scss";

const DateField = props => {
	let { locale } = useLocale();
	let state = useDateFieldState({
		...props,
		locale,
		createCalendar
	});

	let ref = React.useRef();
	let { fieldProps } = useDateField(props, state, ref);

	return (
		<div className={styles.wrapper}>
			<div {...fieldProps} ref={ref} className={styles.field}>
				{state.segments.map((segment, i) => (
					<DateSegment key={i} segment={segment} state={state} />
				))}
			</div>
		</div>
	);
};

const DateSegment = ({ segment, state }) => {
	let ref = React.useRef();
	let { segmentProps } = useDateSegment(segment, state, ref);

	return (
		<div
			{...segmentProps}
			ref={ref}
			className={segment.text !== "/" ? styles.segment : ""}
		>
			<span
				className={cx(styles.text, {
					[styles.separator]: segment.text === "/",
					[styles.label]: segment.text !== "/"
				})}
			>
				{!isNaN(segment.text) ? segment.text.padStart(2, "0") : segment.text}
			</span>
		</div>
	);
};

export default DateField;
