import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import get from "lodash/get";
import cx from "classnames";

import Card from "../../Card";
import { dateCalendar } from "common/Functions";
import { ReactComponent as EmptyState } from "static/icons/exclamation-triangledanger.svg";
import styles from "../active-processes-card.module.scss";

const ActiveProcesses = ({
	onDragEnd,
	onViewMore,
	onToggleVisibility,
	title,
	isLoading,
	processes
}) => {
	const pokes = get(processes, "pokes");
	return (
		<Card title={title} {...{ onDragEnd, onViewMore, onToggleVisibility }}>
			{!isLoading ? (
				pokes?.length > 0 ? (
					pokes.map(poke => (
						<div key={get(poke, "vacancy.title")} className={styles.section}>
							<div className={cx(styles.row, styles.justifyBetween)}>
								<div className={styles.textGras}>
									<p>{get(poke, "vacancy.title")}</p>
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.textNormal}>
									<p className={styles.text}>Last update: </p>
									<div style={{ width: "8px" }}></div>
									<p> {dateCalendar(get(poke, "created_at"))}</p>
								</div>
								<div className={styles.textNormal}>
									<p className={styles.text}>
										At stage:
										<span className={styles.textSpan}>
											{get(poke, "status")}
										</span>{" "}
									</p>
								</div>
							</div>
						</div>
					))
				) : (
					<div className={styles.emptyState}>
						<EmptyState />
						<p>No results to show</p>
					</div>
				)
			) : (
				<SkeletonTheme style={{ borderRadius: 10 }} color="#dfe3e8">
					<Skeleton width={200} height={16} />
				</SkeletonTheme>
			)}
		</Card>
	);
};
export default ActiveProcesses;
