import create from "zustand";

export const sendVacancyStore = create(set => ({
	isOpen: false,
	recipients: [],
	setIsOpen: value => set({ isOpen: value }),
	setRecipients: value => set({ recipients: value })
}));

const useSendVacancy = () => {
	const {
		isOpen,
		recipients,
		setIsOpen,
		setRecipients
	} = sendVacancyStore(state => ({ ...state }));
	return {
		isOpen,
		recipients,
		setIsOpen,
		setRecipients
	};
};

export default useSendVacancy;
