import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ReactComponent as Avatar } from "static/icons/Avatar Default.svg";
import range from "lodash/range";

import style from "./shortListDrawer.module.scss";

const AddEmailSkeleton = () => {
	return (
		<>
			<table className={style.tableCandidate}>
				<thead>
					<tr>
						<th width="40%">
							<SkeletonTheme color="#D2D5D8">
								<Skeleton width={80} height={8} />
							</SkeletonTheme>
						</th>
						<th width="30%">
							<SkeletonTheme color="#D2D5D8">
								<Skeleton width={80} height={8} />
							</SkeletonTheme>
						</th>
						<th width="30%">
							<SkeletonTheme color="#D2D5D8">
								<Skeleton width={80} height={8} />
							</SkeletonTheme>
						</th>
					</tr>
				</thead>
				<tbody>
					{range(5).map((_n, i) => (
						<tr key={i}>
							<td>
								<div className={style.candidateName}>
									<Avatar />
									<span>
										<SkeletonTheme color="#D2D5D8">
											<Skeleton width={112} height={8} />
										</SkeletonTheme>
									</span>
								</div>
							</td>
							<td>
								{" "}
								<SkeletonTheme color="#D2D5D8">
									<Skeleton width={112} height={8} />
								</SkeletonTheme>
							</td>
							<td>
								<SkeletonTheme color="#D2D5D8">
									<Skeleton width={112} height={8} />
								</SkeletonTheme>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

export default AddEmailSkeleton;
