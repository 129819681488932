import { getLocalTimeZone, today } from "@internationalized/date";
import { useCalendarCell } from "react-aria";
import { useFocusRing } from "@react-aria/focus";
import cx from "classnames";
import React from "react";
import { mergeProps } from "react-aria";
import styles from "./calendar-cell.module.scss";

const CalendarCell = ({
	state,
	date,
	isWeekend,
	isMultiple,
	list,
	cellClassName
}) => {
	let ref = React.useRef();

	let {
		cellProps,
		buttonProps,
		isSelected,
		isOutsideVisibleRange,
		formattedDate,
		isUnavailable,
		isDisabled
	} = useCalendarCell({ date }, state, ref);

	let daySelected = isSelected;
	if (isMultiple) {
		const dateMoment = window.moment([date.year, date.month - 1, date.day]);
		for (var item of list) {
			const itemMoment = window.moment([item.year, item.month - 1, item.day]);
			daySelected = dateMoment.isSame(itemMoment, "day");
			if (daySelected) break;
		}
	}

	const { focusProps, isFocusVisible } = useFocusRing();

	const todayDate = today(getLocalTimeZone());
	const isCurrentDay = window
		.moment([date.year, date.month - 1, date.day])
		.isSame(
			window.moment([todayDate.year, todayDate.month - 1, todayDate.day]),
			"day"
		);

	return (
		<td
			{...cellProps}
			className={cx(styles.td, {
				[styles.weekendTd]: isWeekend,
				[styles.disabledCell]: isDisabled || isUnavailable
			})}
		>
			<div
				{...mergeProps(buttonProps, focusProps)}
				ref={ref}
				hidden={isOutsideVisibleRange}
				className={cx(styles.cell, cellClassName, {
					[styles.selected]: daySelected,
					[styles.bigCell]: !cellClassName && isMultiple,
					[styles.whiteCell]: !isDisabled && isMultiple
				})}
			>
				<div
					className={cx(styles.cellContent, {
						[styles.cellContentSelected]: daySelected,
						[styles.workingDay]: !isWeekend,
						[styles.disabledCellContent]: isDisabled || isUnavailable,
						[styles.cellContentFocused]: isFocusVisible,
						[styles.currentDay]: isCurrentDay
					})}
				>
					{formattedDate}
				</div>
			</div>
		</td>
	);
};

export default CalendarCell;
