import React from "react";
import PropTypes from "prop-types";
import Tooltip from "rc-tooltip";
import _get from "lodash/get";
import "rc-tooltip/assets/bootstrap.css";
import { SENIOR } from "config";
import Star from "./Icons/Star";
import cx from "classnames";

function Functions({ functions = [], className = "" }) {
	const renderCrumb = ({ value, max = 18, isMain = false }) => {
		if (value.length > max) {
			return (
				<Tooltip
					placement="top"
					mouseEnterDelay={0}
					mouseLeaveDelay={0}
					overlay={<div>{value}</div>}
				>
					<li>
						{isMain && <Star color="white" />}
						{`${value.substring(0, max)}...`}
					</li>
				</Tooltip>
			);
		}
		return (
			<li>
				{isMain && <Star color="white" />}
				{value}
			</li>
		);
	};

	const renderCrumbs = () => {
		return functions.map(fnc => (
			<ul key={fnc._id} className={`crumbs ${fnc.is_main ? "main" : ""}`}>
				{renderCrumb({
					value: _get(fnc, "sector.parent_sector.name", ""),
					isMain: fnc.is_main
				})}
				{renderCrumb({ value: _get(fnc, "sector.name", "") })}
				{renderCrumb({ value: _get(fnc, "name", "") })}
				{renderCrumb({ value: _get(fnc, "seniority", SENIOR) })}
			</ul>
		));
	};

	return (
		<div id="can-do" className={cx("section action-hover", className)}>
			<div className="section-title">Main Function</div>
			<div className="section-content">{renderCrumbs(true)}</div>
		</div>
	);
}

Functions.propTypes = {
	functions: PropTypes.array.isRequired
};

export default Functions;
