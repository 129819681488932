import React from "react";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-icon-grey.svg";
import styles from "./request-pipeline-date-card.module.scss";
import cx from "classnames";
import Tooltip from "common/Tippy";

const RequestPipelineDateCard = ({
	className,
	children,
	tooltipContent = "Application date"
}) => {
	return (
		<Tooltip content={tooltipContent} theme="dark">
			<div className={cx(styles.container, className)}>
				<CalendarIcon />
				<div className={styles.date}>{children}</div>
			</div>
		</Tooltip>
	);
};

export default RequestPipelineDateCard;
