import styled, { css } from "styled-components";
import { Link } from "react-router";
import { margin } from "styled-system";
import { utilities } from "config/styles";
import theme from "../theme";
import buttonVariant from "./variant";

const baseButton = css`
	display: inline-block;
	border-radius: ${utilities.borderRadius};
	font-weight: bold;
	white-space: nowrap;
	word-break: keep-all;
	cursor: pointer;
	line-height: 1.5;
	position: relative;
	font-size: ${props =>
		props.small ? "14px" : props.size ? `${props.size}px` : "16px"};
	${props =>
		props.block &&
		css`
			width: 100%;
		`};
	padding: ${props => (props.small ? "10px 9px" : "14px 32px")};
	outline: none;
	:first-letter {
		text-transform: capitalize;
	}
	border: none;
	transition: all 0.2s ease-out;
	&:hover {
		transition: all 0.2s ease-in;
	}
	${props =>
		props["has-icon"] &&
		css`
			i {
				position: absolute;
				${props["has-icon"] === "right" ? "right" : "left"}: 20px;
				font-size: 20px;
				font-weight: 600;
				top: 50%;
				transform: translate(-50%, -50%);
			}
			${props.small &&
				css`
					padding-left: 50px;
					padding-right: 35px;
				`};
		`};
	${props =>
		props.large &&
		css`
			padding-right: 80px;
			padding-left: 80px;
		`};
	${props =>
		props.medium &&
		css`
			padding-right: 50px;
			padding-left: 50px;
		`};
	${props =>
		props.round &&
		css`
			border-radius: 50px;
		`};
	${props =>
		props.px &&
		css`
			padding-left: ${props.px}px;
			padding-right: ${props.px}px;
		`};
	${props =>
		props.py &&
		css`
			padding-top: ${props.py}px;
			padding-bottom: ${props.py}px;
		`};
	div + i,
	i + i {
		margin: 0 8px;
	}
	${margin}
`;

export const Label = styled.span`
	flex: 0 0 auto;
	line-height: inherit;
	color: inherit;
	${props => (props.loading ? "opacity: 0;" : "opacity: 1;")};
	align-self: center;
	margin: auto;
	font-weight: bold;
`;

export const StyledButtonsContainer = styled.div`
	button + button,
	a + a,
	button + a,
	a + button {
		margin-left: 15px;
	}
	text-align: ${props => (props.center ? "center" : "left")};
	margin: ${props => props.margin || 0};
	${props =>
		props.display &&
		css`
			display: ${props.display};
		`}
`;

export const StyledSolidButton = styled.button`
	${baseButton};
	${({
		color = "primary",
		bordered,
		disabled,
		disabledStyles = "",
		disabledActiveStyle = false,
		...rest
	}) =>
		buttonVariant({
			...rest,
			background: theme[color],
			color: theme[`${color}Color`],
			border: `1px solid ${theme[`${color}Border`]}`,
			hoverColor: theme[`${color}HoverColor`],
			hoverBackground: theme[`${color}Hover`],
			hoverBorder: `1px solid ${theme[`${color}HoverBorderColor`]}`,
			bordered,
			disabled,
			disabledStyles,
			disabledActiveStyle
		})};
	display: ${props => (props.block ? "block" : "inline-block")};
`;

export const StyledOutlineButton = styled.button`
	${baseButton} ${props =>
		buttonVariant({
			background: theme.secondary,
			color: theme.secondaryColor,
			hoverColor: theme.secondaryHoverColor,
			hoverBackground: theme.secondaryHover,
			hoverBorder: `2px solid ${theme.secondaryHoverBorderColor}`,
			activeBackground: theme.secondaryActive,
			activeColor: theme.secondaryActiveColor,
			bordered: true,
			disabled: props.disabled,
			inactive: props.inactive,
			disabledHover: props.disabledHover
		})};
	border: 2px solid ${theme.secondaryBorder};
	text-decoration: none;
	&:focus {
		border-color: ${theme.secondaryHover};
	}
`;
export const StyledOutlineEditButton = styled(StyledOutlineButton)`
	padding-left: 15px;
	padding-right: 20px;
	z-index: 1;
`;

export const StyledButtonGroup = styled.div`
	position: relative;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	&:hover {
		button {
			background-color: ${theme.primaryHover};
		}
		i {
			background-color: ${theme.splitIconHover};
		}
	}
`;

export const StyledSplitButton = styled.button`
	${baseButton};
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	${props =>
		buttonVariant({
			background: theme.split,
			color: theme.primaryColor,
			hoverColor: theme.primaryColor,
			hoverBackground: theme.splitHover,
			bordered: false,
			disabled: props.disabled
		})};
	padding: 6px 15px;
	font-size: 13px;
	font-weight: bold;
	line-height: inherit;
	transition: none !important;
`;

export const StyledIconButton = styled.button`
	${baseButton};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;
	> i {
		margin: 0;
	}
	height: 32px;
	width: 32px;
	${({ color = "white", bordered, disabled }) =>
		buttonVariant({
			background: theme[color],
			color: theme[`${color}Color`],
			border: `2px solid ${theme[`${color}Border`]}`,
			hoverColor: theme[`${color}HoverColor`],
			hoverBackground: theme[`${color}Hover`],
			hoverBorder: `2px solid ${theme[`${color}HoverBorderColor`]}`,
			bordered,
			disabled
		})};
`;

export const StyledOutlinedLinkButton = StyledOutlineButton.withComponent(Link);

export const StyledLinkButton = styled(Link)`
	${baseButton};
	${({ color = "primary", bordered, disabled }) =>
		buttonVariant({
			background: theme[color],
			hoverBackground: theme[`${color}Hover`],
			hoverBorder: `2px solid ${theme[`${color}HoverBorderColor`]}`,
			bordered,
			disabled
		})};
	display: ${props => (props.block ? "block" : "inline-block")};
	text-decoration: none;
`;
