import React, { forwardRef } from "react";
import CardSkeleton from "../channelCard/CardSkeleton";
import { PublishVacancyContractsList } from "../PublishVacancyContractsList";
import s from "./publish-vacancy-contracts-view.module.scss";
import EmptyState from "../emptyState";
import Pagination from "common/Pagination";
import { createArrayWithIncrementalValues } from "config/helpers";
import { HOW_TO_PUBLISH_VACANCY } from "config/onboarding";

const PublishVacancyContractsView = (
	{
		selectedContractsCounter,
		isLoadingContract,
		handlePageClick,
		setContractOffset,
		contractSelectionBucket,
		data,
		handleContractCheckboxChange,
		isContractCanBeUsedForPublish
	},
	ref
) => {
	let total;
	let limit;
	let offset;
	let displayPagination;
	let contractsList;
	let pagination;

	if (data) {
		({ data: contractsList, pagination } = data);
		({ total, limit, offset } = pagination);
		displayPagination = total > limit;
	}

	return (
		<div className={s.block} ref={ref}>
			<div className={s.selectAll}>
				<span>
					My Contracts{" "}
					{selectedContractsCounter !== 0 && `(${selectedContractsCounter})`}
				</span>
			</div>
			<div
				className={s.gridLayout}
				data-onboarding-step={`${HOW_TO_PUBLISH_VACANCY}-4`}
			>
				{isLoadingContract ? (
					createArrayWithIncrementalValues(8).map((_, index) => (
						<CardSkeleton key={index} />
					))
				) : contractsList.length > 0 ? (
					<PublishVacancyContractsList
						isContractSelected={
							contractSelectionBucket.helpers.isContractSelected
						}
						isContractCanBeUsedForPublish={isContractCanBeUsedForPublish}
						list={contractsList}
						onContractCheckboxChange={handleContractCheckboxChange}
					/>
				) : (
					<EmptyState />
				)}
			</div>
			{displayPagination && (
				<div>
					<Pagination
						total={total}
						size={limit}
						offset={offset}
						handlePageClick={handlePageClick(setContractOffset, data)}
					/>
				</div>
			)}
		</div>
	);
};

export default forwardRef(PublishVacancyContractsView);
