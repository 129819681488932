import { usePaginatedQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_JOB_BOARD_CONTRACT_ENDPOINT } from "config/api-endpoints";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import fakeContracts from "modules/user-onboarding/mockData/contracts.json";
import { HOW_TO_PUBLISH_VACANCY } from "config/onboarding";

const getListContract = (_key, body) => {
	return client(LIST_JOB_BOARD_CONTRACT_ENDPOINT, {
		body
	});
};

const fakeData = () => new Promise(r => r(fakeContracts));

export const getListContractQueryKey = "@VonqContract/getListContract";

const useGetListContract = (body, queryOptions = {}) => {
	const { workflowId } = useOnboardingStore();
	const fetchData =
		workflowId === HOW_TO_PUBLISH_VACANCY ? fakeData : getListContract;

	return usePaginatedQuery(
		[getListContractQueryKey, body, workflowId],
		fetchData,
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...queryOptions
		}
	);
};

export default useGetListContract;
