import React from "react";

export default function Star({ color }) {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 317 321"
			fill={color}
		>
			<g>
				<path
					d="M298.6,113.9l-84.6-8c-5.3-0.5-9.9-3.9-12.1-9l-30.3-73.5c-4.8-12.1-22.1-12.1-26.9,0L114.6,97c-1.9,5.1-6.8,8.5-12.1,9
		l-84.6,8c-12.6,1.2-17.7,17-8.2,25.5l63.8,56c4.1,3.6,5.8,9,4.6,14.3l-19.2,78.6c-2.9,12.4,10.4,22.5,21.6,16l70.6-41.5
		c4.6-2.7,10.2-2.7,14.8,0l70.6,41.5c11.2,6.5,24.5-3.4,21.6-16L239,209.7c-1.2-5.3,0.5-10.7,4.6-14.3l63.8-56
		C316.6,130.9,311.2,115.1,298.6,113.9z"
				/>
			</g>
		</svg>
	);
}
