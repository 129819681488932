import React from "react";
import { useTable } from "react-table";
import cx from "classnames";
import style from "./styles.module.scss";
import Scrollbars from "react-custom-scrollbars";
const Table = ({ columns, data, onRowClick, handleViewAll }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data
	});

	return (
		<div className={style.table}>
			<Scrollbars
				autoHide
				autoHideTimeout={5000}
				autoHideDuration={200}
				autoHeight
				autoHeightMax={255}
			>
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup, i) => (
							<tr key={i} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th
										className={cx({
											[style.textAlignRight]: Boolean(column?.isNumber)
										})}
										key={column.name}
										{...column.getHeaderProps()}
									>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map(row => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									key={row.original._id}
									onClick={() => onRowClick(row.original)}
								>
									{row.cells.map((cell, i) => {
										return (
											<td
												className={cx({
													[style.textAlignRight]: Boolean(
														cell?.column?.isNumber
													)
												})}
												key={i}
												{...cell.getCellProps()}
											>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				<span className={style.viewAll} onClick={handleViewAll}>
					View all
				</span>
			</Scrollbars>
		</div>
	);
};

export default Table;
