import "./index.css";
import cx from "classnames";
import {
	$createLinkNode,
	$isAutoLinkNode,
	$isLinkNode,
	TOGGLE_LINK_COMMAND
} from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $findMatchingParent, mergeRegister } from "@lexical/utils";
import {
	$getSelection,
	$isLineBreakNode,
	$isRangeSelection,
	CLICK_COMMAND,
	COMMAND_PRIORITY_CRITICAL,
	COMMAND_PRIORITY_HIGH,
	COMMAND_PRIORITY_LOW,
	KEY_ESCAPE_COMMAND,
	SELECTION_CHANGE_COMMAND
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import * as React from "react";
import { createPortal } from "react-dom";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { sanitizeUrl } from "../../utils/url";
import { setFloatingElemPositionForLinkEditor } from "../../utils/packages/lexical-playground/src/utils/setFloatingElemPositionForLinkEditor";
import { ReactComponent as CheckIcon } from "static/icons/check.svg";
import { ReactComponent as CloseIcon } from "static/icons/CloseX.svg";
import { ReactComponent as PencilIcon } from "static/icons/pen.svg";
import { ReactComponent as DeleteIcon } from "static/icons/delete.svg";
import { IconButton } from "common/IconButton";

function FloatingLinkEditor({
	editor,
	isLink,
	setIsLink,
	anchorElem,
	isLinkEditMode,
	setIsLinkEditMode
}) {
	const editorRef = useRef(null);
	const inputRef = useRef(null);
	const [linkUrl, setLinkUrl] = useState("");
	const [editedLinkUrl, setEditedLinkUrl] = useState("https://");
	const [lastSelection, setLastSelection] = useState(null);

	const updateLinkEditor = useCallback(() => {
		const selection = $getSelection();
		if ($isRangeSelection(selection)) {
			const node = getSelectedNode(selection);
			const linkParent = $findMatchingParent(node, $isLinkNode);

			if (linkParent) {
				setLinkUrl(linkParent.getURL());
			} else if ($isLinkNode(node)) {
				setLinkUrl(node.getURL());
			} else {
				setLinkUrl("");
			}
			if (isLinkEditMode) {
				setEditedLinkUrl(linkUrl);
			}
		}
		const editorElem = editorRef.current;
		const nativeSelection = window.getSelection();
		const activeElement = document.activeElement;

		if (editorElem === null) {
			return;
		}

		const rootElement = editor.getRootElement();

		if (
			selection !== null &&
			nativeSelection !== null &&
			rootElement !== null &&
			rootElement.contains(nativeSelection.anchorNode) &&
			editor.isEditable()
		) {
			const domRect = nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
			if (domRect) {
				domRect.y += 40;
				setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem);
			}
			setLastSelection(selection);
		} else if (!activeElement || activeElement.className !== "link-input") {
			if (rootElement !== null) {
				setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem);
			}
			setLastSelection(null);
			setIsLinkEditMode(false);
			setLinkUrl("");
		}

		return true;
	}, [anchorElem, editor, setIsLinkEditMode, isLinkEditMode, linkUrl]);

	useEffect(() => {
		const scrollerElem = anchorElem.parentElement;

		const update = () => {
			editor.getEditorState().read(() => {
				updateLinkEditor();
			});
		};

		window.addEventListener("resize", update);

		if (scrollerElem) {
			scrollerElem.addEventListener("scroll", update);
		}

		return () => {
			window.removeEventListener("resize", update);

			if (scrollerElem) {
				scrollerElem.removeEventListener("scroll", update);
			}
		};
	}, [anchorElem.parentElement, editor, updateLinkEditor]);

	useEffect(() => {
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateLinkEditor();
				});
			}),

			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				() => {
					updateLinkEditor();
					return true;
				},
				COMMAND_PRIORITY_LOW
			),
			editor.registerCommand(
				KEY_ESCAPE_COMMAND,
				() => {
					if (isLink) {
						setIsLink(false);
						return true;
					}
					return false;
				},
				COMMAND_PRIORITY_HIGH
			)
		);
	}, [editor, updateLinkEditor, setIsLink, isLink]);

	useEffect(() => {
		editor.getEditorState().read(() => {
			updateLinkEditor();
		});
	}, [editor, updateLinkEditor]);

	useEffect(() => {
		if (isLinkEditMode && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isLinkEditMode, isLink]);

	const monitorInputInteraction = event => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleLinkSubmission();
		} else if (event.key === "Escape") {
			event.preventDefault();
			setIsLinkEditMode(false);
		}
	};

	const handleLinkSubmission = () => {
		if (lastSelection !== null) {
			if (linkUrl !== "") {
				editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
					url: sanitizeUrl(editedLinkUrl),
					target: "_blank"
				});
				editor.update(() => {
					const selection = $getSelection();
					if ($isRangeSelection(selection)) {
						const parent = getSelectedNode(selection).getParent();
						if ($isAutoLinkNode(parent)) {
							const linkNode = $createLinkNode(parent.getURL(), {
								rel: parent.__rel,
								target: parent.__target,
								title: parent.__title
							});
							parent.replace(linkNode, true);
						}
					}
				});
			}
			setEditedLinkUrl("https://");
			setIsLinkEditMode(false);
		}
	};

	return (
		<div ref={editorRef} className={cx("link-editor", { padding6: isLink })}>
			{!isLink ? null : isLinkEditMode ? (
				<>
					<input
						ref={inputRef}
						className="link-input"
						value={editedLinkUrl}
						onChange={event => {
							setEditedLinkUrl(event.target.value);
						}}
						onKeyDown={event => {
							monitorInputInteraction(event);
						}}
					/>
					<IconButton
						className="link-cancel"
						type="button"
						onClick={() => {
							setIsLinkEditMode(false);
						}}
					>
						<CloseIcon height={20} width={20} />
					</IconButton>

					<IconButton
						type="button"
						className="link-confirm"
						onClick={handleLinkSubmission}
					>
						<CheckIcon height={20} width={20} />
					</IconButton>
				</>
			) : (
				<>
					<a
						href={sanitizeUrl(linkUrl)}
						target="_blank"
						rel="noopener noreferrer"
					>
						{linkUrl}
					</a>
					<IconButton
						className="link-edit"
						type="button"
						onClick={() => {
							setEditedLinkUrl(linkUrl);
							setIsLinkEditMode(true);
						}}
					>
						<PencilIcon />
					</IconButton>
					<IconButton
						type="button"
						className="link-trash"
						onClick={() => {
							editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
						}}
					>
						<DeleteIcon />
					</IconButton>
				</>
			)}
		</div>
	);
}

function useFloatingLinkEditorToolbar(
	editor,
	anchorElem,
	isLinkEditMode,
	setIsLinkEditMode
) {
	const [activeEditor, setActiveEditor] = useState(editor);
	const [isLink, setIsLink] = useState(false);

	useEffect(() => {
		function updateToolbar() {
			const selection = $getSelection();
			if ($isRangeSelection(selection)) {
				const focusNode = getSelectedNode(selection);
				const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode);
				const focusAutoLinkNode = $findMatchingParent(
					focusNode,
					$isAutoLinkNode
				);
				if (!(focusLinkNode || focusAutoLinkNode)) {
					setIsLink(false);
					return;
				}
				const badNode = selection.getNodes().find(node => {
					const linkNode = $findMatchingParent(node, $isLinkNode);
					const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode);
					if (
						!linkNode?.is(focusLinkNode) &&
						!autoLinkNode?.is(focusAutoLinkNode) &&
						!linkNode &&
						!autoLinkNode &&
						!$isLineBreakNode(node)
					) {
						return node;
					}
				});
				if (!badNode) {
					setIsLink(true);
				} else {
					setIsLink(false);
				}
			}
		}
		return mergeRegister(
			editor.registerUpdateListener(({ editorState }) => {
				editorState.read(() => {
					updateToolbar();
				});
			}),
			editor.registerCommand(
				SELECTION_CHANGE_COMMAND,
				(_payload, newEditor) => {
					updateToolbar();
					setActiveEditor(newEditor);
					return false;
				},
				COMMAND_PRIORITY_CRITICAL
			),
			editor.registerCommand(
				CLICK_COMMAND,
				payload => {
					const selection = $getSelection();
					if ($isRangeSelection(selection)) {
						const node = getSelectedNode(selection);
						const linkNode = $findMatchingParent(node, $isLinkNode);
						if ($isLinkNode(linkNode) && (payload.metaKey || payload.ctrlKey)) {
							window.open(linkNode.getURL(), "_blank");
							return true;
						}
					}
					return false;
				},
				COMMAND_PRIORITY_LOW
			)
		);
	}, [editor]);

	return createPortal(
		<FloatingLinkEditor
			editor={activeEditor}
			isLink={isLink}
			anchorElem={anchorElem}
			setIsLink={setIsLink}
			isLinkEditMode={isLinkEditMode}
			setIsLinkEditMode={setIsLinkEditMode}
		/>,
		anchorElem
	);
}

export default function FloatingLinkEditorPlugin({
	anchorElem = document.body,
	isLinkEditMode,
	setIsLinkEditMode
}) {
	const [editor] = useLexicalComposerContext();
	return useFloatingLinkEditorToolbar(
		editor,
		anchorElem,
		isLinkEditMode,
		setIsLinkEditMode
	);
}
