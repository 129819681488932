import React, { useMemo } from "react";
import { ReactComponent as Status } from "static/icons/status-icon.svg";
import { ReactComponent as DueDateIcon } from "static/icons/due-date-icon.svg";
import { ReactComponent as LinkToIcon } from "static/icons/link_to_icon.svg";
import { ReactComponent as PriorityIcon } from "static/icons/priority-icon.svg";
import { ReactComponent as UserIcon } from "static/icons/user-icon.svg";
import { ReactComponent as ReminderIcon } from "static/icons/status-icon.svg";
import {
	VALUE_OPERATORS,
	DATE_OPERATORS,
	FILE_NAME,
	FILE_TYPE,
	CREATED_AT,
	ALLOWED_USERS,
	CREATED_BY
} from "config";
import { client } from "lib/api-client";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { v4 as uuid } from "uuid";

const getFilterSettings = (tag, id) =>
	client(NEW_FILTER_SETTINGS, {
		body: {
			list: "files",
			tag,
			id
		}
	});

export const sortBylist = [
	{
		field: FILE_NAME,
		icon: <Status />,
		label: "File name",
		options: []
	},
	{
		field: FILE_TYPE,
		icon: <DueDateIcon />,
		label: "Document type",
		options: []
	},
	{
		field: CREATED_AT,
		icon: <PriorityIcon />,
		label: "Added",
		options: []
	},
	{
		field: CREATED_BY,
		icon: <LinkToIcon />,
		label: "Created by"
	}
];

export const filterList = id => [
	{
		field: FILE_TYPE,
		icon: <ReminderIcon />,
		label: "Type",
		options: [],
		filterOptions: () => getFilterSettings(FILE_TYPE, id),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: CREATED_AT,
		icon: <DueDateIcon />,
		label: "Added",
		options: [],
		selectedOption: "",
		oprators: DATE_OPERATORS,
		isMultipleSelect: false,
		id: uuid()
	},
	{
		field: CREATED_BY,
		icon: <UserIcon />,
		label: "Created by",
		options: [],
		filterOptions: () => getFilterSettings(CREATED_BY, id),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: ALLOWED_USERS,
		icon: <UserIcon />,
		label: "Visibility",
		options: [],
		filterOptions: () => getFilterSettings(ALLOWED_USERS, id),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	}
];

export const columns = (sortedField, hasInternal = true) =>
	useMemo(
		() =>
			[
				{
					Header: "File name",
					accessor: "file_name",
					isSorted: sortedField === FILE_NAME
				},
				{
					Header: "Type",
					accessor: "file_type",
					isSorted: sortedField === FILE_TYPE
				},
				{
					Header: "Added",
					accessor: "created_at",
					isSorted: sortedField === CREATED_AT
				},
				{
					Header: "Creator",
					accessor: "created_by",
					isSorted: sortedField === CREATED_BY
				},
				{
					Header: "Visibility",
					accessor: "allowed_users"
				},
				hasInternal && {
					Header: "Primary",
					accessor: "is_primary"
				},
				{
					Header: "Action",
					accessor: "action",
					onClick: e => e.stopPropagation()
				}
			].filter(Boolean),
		[sortedField, hasInternal]
	);
