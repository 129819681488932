import React from "react";
import GlobalTooltip from "common/GlobalTooltip";
import _get from "lodash/get";
import { LANGUAGES } from "config";
import { ItemsWrapper, Item, ShowMore } from "../Styled";

const Languages = ({ languages, deleteFilter, openModal }) => {
	let displayedCount = 0;

	return (
		<>
			<ItemsWrapper>
				{languages.map(language => {
					if (displayedCount < 4) {
						displayedCount += 1;
						return _get(language, "name", []).length > 12 ? (
							<GlobalTooltip
								key={language._id}
								placement="top"
								mouseEnterDelay={0}
								mouseLeaveDelay={0}
								overlay={language.name}
								overlayClassName="g-tool-dark"
							>
								<Item hasTooltip key={language._id} className="language-item">
									{`${_get(language, "name", "").substr(0, 12)}... (${_get(
										language,
										"score"
									)}/5)`}
									<span
										className="icon-close"
										onClick={() => deleteFilter(LANGUAGES, language._id)}
									></span>
								</Item>
							</GlobalTooltip>
						) : (
							<Item key={language._id} className="language-item">
								{`${language.name} (${language.score}/5)`}
								<span
									className="icon-close"
									onClick={() => deleteFilter(LANGUAGES, language._id)}
								></span>
							</Item>
						);
					}
				})}
			</ItemsWrapper>
			{displayedCount < languages.length && (
				<ShowMore onClick={openModal}>
					<span> + </span>
					<span>({languages.length - displayedCount}) Show more</span>
				</ShowMore>
			)}
		</>
	);
};

export default Languages;
