import React from "react";
import { Flex, Box } from "rebass/styled-components";

import CurrencyFormatter from "common/CurrencyFormater";
import { StatisticWrapper } from "./Styled";
import GlobalTooltip from "common/GlobalTooltip";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const cardsConfig = {
	total_workable_requests: {
		label: "Workable requests"
	},
	total_bid_to_requests: {
		label: "BIDS TO REQUEST",
		upKey: { key: "num_requests", label: "Number of requests" },
		downKey: { key: "num_bids", label: "Number of bids" }
	},
	total_bid_to_interviews: {
		label: "BIDS TO INTERVIEW",
		upKey: { key: "num_bids", label: "Number of bids" },
		downKey: { key: "num_interviews", label: "Number of interview" }
	},
	total_bid_to_contract_propositions: {
		label: "BIDS TO CONTRACT PROPOSITION",
		upKey: { key: "num_bids", label: "Number of bids" },
		downKey: {
			key: "num_contract_propositions",
			label: "Number of contract propositions"
		}
	},
	total_bid_to_missions: {
		label: "BIDS TO MISSION",
		upKey: { key: "num_bids", label: "Number of bids" },
		downKey: { key: "num_missions", label: "Number of missions" }
	},
	total_interview_to_contract_propositions: {
		label: "INTERVIEWS TO CONTRACT PROPOSITION",
		upKey: { key: "num_interviews", label: "Number of interview" },
		downKey: {
			key: "num_contract_propositions",
			label: "Number of contract propositions"
		}
	},
	total_contract_proposition_to_missions: {
		label: "CONTRACT PROPOSITIONS TO MISSION",
		upKey: {
			key: "num_contract_propositions",
			label: "Number of contract propositions"
		},
		downKey: { key: "num_missions", label: "Number of missions" }
	},
	total_interview_to_missions: {
		label: "INTERVIEWS TO MISSION",
		upKey: { key: "num_interviews", label: "Number of interview" },
		downKey: { key: "num_missions", label: "Number of missions" }
	},
	total_amount: { label: "TOTAL SPENT TO DATE (ALL)" },
	total_filled: { label: "REQUESTS FILLED" },
	total_missions: { label: "TOTAL OF MISSION" },
	total_onboarded_missions: { label: "ONBOARDED MISSIONS" },
	total_supplier: { label: "SUPPLIER" },
	total_average_cost: { label: "AVERAGE COST" },
	total_cost: { label: "TOTAL COST" },
	num_missions: { label: "NUMBER OF MISSIONS" }
};

const PerformanceCard = ({ name, value }) => {
	const cardConfig = cardsConfig[name];
	const showTooltip = "upKey" in cardConfig;
	return (
		<GlobalTooltip
			active={showTooltip}
			placement={"top"}
			noClassName={true}
			overlayClassName={"g-tool-black top center-text"}
			overlay={
				<Flex flexDirection="column" minWidth={300}>
					<Box
						sx={{
							fontSize: 13,
							fontWeight: 600,
							textTransform: "uppercase",
							fontFamily: "BasierCircle",
							color: "#d6d9e5"
						}}
						mb={2}
					>
						Ratio details
					</Box>
					<Flex>
						<Box
							sx={{
								fontFamily: "BasierCircle",
								display: "grid",
								gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
								fontSize: 14,
								color: "white",
								fontWeight: 400,
								width: "100%"
							}}
						>
							<Box sx={{ gridColumn: "span 5 / span 5" }}>
								{cardConfig.upKey?.label}
							</Box>
							<Box sx={{ textAlign: "right" }}>
								{value[cardConfig.upKey?.key]}
							</Box>
							<Box sx={{ gridColumn: "span 5 / span 5" }}>
								{cardConfig.downKey?.label}
							</Box>
							<Box sx={{ textAlign: "right" }}>
								{value[cardConfig.downKey?.key]}
							</Box>
						</Box>
					</Flex>
				</Flex>
			}
		>
			<Flex
				flexDirection="column"
				fontFamily="BasierCircle"
				justifyContent="center"
				alignItems="center"
				sx={{
					height: "100%",
					backgroundColor: "white",
					borderRadius: 5,
					padding: "10px",
					boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.04)",
					border: "1px solid transparent",
					transitionProperty: "background-color, border-color",
					transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
					transitionDuration: "150ms",
					"&:hover": {
						borderColor: "#2595e3",
						backgroundColor: "rgba(34, 148, 227, 0.1)"
					}
				}}
			>
				<Box
					fontFamily="BasierCircle"
					sx={{
						color: "#A0A3BD",
						fontWeight: 600,
						fontSize: "12px",
						lineHeight: "22px",
						textAlign: "center",
						textTransform: "uppercase",
						wordWrap: "break-word",
						height: "50%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					{cardsConfig[name].label}
				</Box>
				<Box
					fontFamily="BasierCircle"
					sx={{
						fontSize: 23,
						fontWeight: 600,
						height: "50%",
						color: "#6E7191",
						lineHeight: "40px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					{typeof value === "number" ? (
						name === "total_amount" ||
						name === "total_cost" ||
						name === "total_average_cost" ? (
							<CurrencyFormatter cent={value} />
						) : (
							value
						)
					) : (
						`${value.average} : ${value.quantity}`
					)}
				</Box>
			</Flex>
		</GlobalTooltip>
	);
};

const StatisticPerformance = ({ totalData, isFetching }) => {
	return (
		<StatisticWrapper>
			{isFetching
				? new Array(5).fill(
						<Flex
							flexDirection="column"
							fontFamily="BasierCircle"
							justifyContent="center"
							alignItems="center"
							sx={{
								height: "106px",
								backgroundColor: "white",
								borderRadius: 5,
								padding: "10px",
								boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.04)",
								border: "1px solid transparent",
								transitionProperty: "background-color, border-color",
								transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
								transitionDuration: "150ms",
								"&:hover": {
									borderColor: "#2595e3",
									backgroundColor: "rgba(34, 148, 227, 0.1)"
								}
							}}
						>
							<Box
								sx={{
									height: "50%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#DFE3E8">
									<Skeleton width={110} height={16} />
								</SkeletonTheme>
							</Box>
							<Box
								sx={{
									height: "50%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<SkeletonTheme style={{ borderRadius: 10 }} color="#DFE3E8">
									<Skeleton width={90} height={16} />
								</SkeletonTheme>
							</Box>
						</Flex>
				  )
				: Object.keys(totalData).map(key => (
						<PerformanceCard
							name={key}
							key={key}
							value={totalData[key]}
						></PerformanceCard>
				  ))}
		</StatisticWrapper>
	);
};
export default StatisticPerformance;
