import React, { useState, memo, useEffect, useRef } from "react";
import HeaderButton from "../HeaderButton";
import CommunityCriteria from "modules/SearchCandidate/components/CommunityCriteria/MenuCommunityCriteria.js";
import style from "modules/SearchCandidate/pageHeader/page-header.module.scss";
import { ReactComponent as ColumnIcon } from "static/icons/Icon.svg";
import { ReactComponent as FilterIcon } from "static/icons/Iconfilter.svg";
import { ReactComponent as GridIcon } from "static/icons/IconGrid.svg";
import { ReactComponent as RowIcon } from "static/icons/IconRows.svg";
import { ReactComponent as RemoveIcon } from "static/icons/removeIcon.svg";
import { ReactComponent as BookmarkIcon } from "static/icons/Iconcriteria.svg";
import { ReactComponent as FilledBookmarkIcon } from "static/icons/filledBookmark.svg";
import { ReactComponent as Arrow } from "static/icons/ArrowIcon.svg";

import ColumnsMenu from "./ColumnsMenu";
import get from "lodash/get";
import { useFilters } from "common/SmartFilterDrawer/hooks/useFilter";
import { SearchInput } from "common/SearchInput";
import cx from "classnames";
import { CANDIDATES_FILTER } from "../utils/constants";
import { validFiltersCount } from "common/SmartFilterDrawer/utils/render_utils";
import { browserHistory } from "react-router";
import { useUserHasSearchByKeyword } from "hooks/useUserHasSearchByKeyword";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { connect } from "react-redux";
import Tooltip from "common/Tippy";
import { HOW_TO_SOURCE_AND_INVITE_CANDIDATE } from "config/onboarding";

const HeaderFilters = ({
	tableSettings,
	toggleRecap,
	showRecap,
	onAddFilterClick,
	onViewChange,
	setActiveView,
	activeView,
	tableTag,
	user
}) => {
	const {
		setQuery,
		getState,
		resetFilters,
		clearFilters,
		setSelectedCriteria
	} = useFilters();
	const {
		query,
		queryError,
		showDrawer,
		selectedCriteria,
		filters,
		smartQuerySchema
	} = getState(CANDIDATES_FILTER);
	const [localQuery, setLocalQuery] = useState(query);
	const history = browserHistory;
	const userHasSearchByKeyword = useUserHasSearchByKeyword({ user });

	const handleChange = e => {
		setLocalQuery(e.target.value);
		setQuery(e.target.value);
	};

	const ref = useRef();

	const filtersList = smartQuerySchema.length ? smartQuerySchema : filters;
	const selectCriteriaHandler = criteria => {
		setSelectedCriteria(criteria);
		clearFilters();
	};

	const clearselectedCriteriaHandler = () => {
		resetFilters();
		const currentQuery = get(history.getCurrentLocation(), "query");
		delete currentQuery.vacancy_id;
		const location = {
			pathname: "/candidates/search",
			query: currentQuery
		};
		history.replace(location);
	};

	useEffect(() => {
		if (!showDrawer && localQuery != query) {
			setLocalQuery(query);
		}
	}, [query, showDrawer]);

	const searchInput = (
		<SearchInput
			disabled={!userHasSearchByKeyword}
			className={cx(style.search, {
				[style.error]: queryError
			})}
			value={localQuery}
			onChange={handleChange}
			placeholder={
				"Search in name, phone, email & CV content (Boolean strings OK)"
			}
			variant="fixed_width"
			ref={ref}
			data-onboarding-step={`${HOW_TO_SOURCE_AND_INVITE_CANDIDATE}-1`}
		/>
	);

	return (
		<div className={style.filtersContainer}>
			<div className={style.firstContainer}>
				{userHasSearchByKeyword ? (
					searchInput
				) : (
					<Tooltip
						content="To enable this feature please contact support"
						theme="dark"
					>
						<div>{searchInput}</div>
					</Tooltip>
				)}
				<div className={style.filter_btns_container}>
					<HeaderButton
						icon={<FilterIcon />}
						text="Filter"
						className="btnAddFilter"
						onClick={onAddFilterClick}
					/>
					{validFiltersCount(filtersList) > 0 && (
						<button className={style.recap_btn} onClick={toggleRecap}>
							<span>{validFiltersCount(filtersList)}</span>
							<Arrow
								className={
									showRecap ? `${style.arrow} ${style.up}` : style.arrow
								}
							/>
						</button>
					)}
				</div>

				<HeaderButton
					icon={
						get(selectedCriteria, "name", "") ? (
							<FilledBookmarkIcon />
						) : (
							<BookmarkIcon />
						)
					}
					text={
						get(selectedCriteria, "name", "") === ""
							? "Saved community criteria"
							: get(selectedCriteria, "name")
					}
					className="btnCriteria"
					isCriteriaButton
					isDropDown
					secondIcon={
						get(selectedCriteria, "name", "") ? (
							<RemoveIcon
								onClick={e => {
									e.stopPropagation();
									clearselectedCriteriaHandler();
								}}
							/>
						) : (
							<Arrow />
						)
					}
					side="left"
					variant="fixed-width"
					onClick={() => selectCriteriaHandler({})}
				>
					<CommunityCriteria />
				</HeaderButton>
			</div>
			<div className={style.secondContainer}>
				<div className={style.switchBtns}>
					<HeaderButton
						onClick={() => {
							// setActive("table");
							onViewChange(0);
							setActiveView("table");
						}}
						icon={<RowIcon />}
						className="btnLeft"
						active={activeView === "table"}
					/>
					<HeaderButton
						onClick={() => {
							// setActive("cards");
							onViewChange(1);
							setActiveView("cards");
						}}
						icon={<GridIcon />}
						className="btnRight"
						active={activeView === "cards"}
					/>
				</div>
				<HeaderButton
					icon={<ColumnIcon />}
					text="Columns"
					className="btnCriteria"
					secondIcon={<Arrow />}
					disabled={activeView === "cards"}
				>
					<ColumnsMenu tableSettings={tableSettings} tableTag={tableTag} />
				</HeaderButton>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps)(memo(HeaderFilters));
