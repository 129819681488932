import React, { useEffect, useRef } from "react";
import { useToggle } from "react-use";
import { useForm } from "react-hook-form";
import _pick from "lodash/pick";
import _find from "lodash/find";
import _get from "lodash/get";
import { useQueryCache } from "react-query";
import EditableBox from "../EditableBox";
import RestFieldsForm from "./RestFieldsForm";
import RestFieldsData from "./RestFieldsData";
import useUpdateCompanyExpertise from "../../../api/useUpdateCompanyExpertise";
import useUpdateCompanyLocations from "../../../api/useUpdateCompanyLocations";
import useUpdateCompanyAdditionalInfo from "../../../api/useUpdateCompanyAdditionalInfo";
import useUpdateCompanyAddress from "../../../api/useUpdateCompanyAddress";
import { getCurrentCompanyQueryKey } from "../../../api/useGetCompany";
import toaster from "../../../../../common/Toaster";

function RestFields({ data }) {
	const queryCache = useQueryCache();
	const geocoder = useRef(new window.google.maps.Geocoder());
	const [
		updateExpertise,
		{ isLoading: isSavingExpertise }
	] = useUpdateCompanyExpertise();
	const [
		updateLocations,
		{ isLoading: isSavingLocations }
	] = useUpdateCompanyLocations();
	const [
		updateAdditionalInfo,
		{ isLoading: isSavingAdditionalInfo }
	] = useUpdateCompanyAdditionalInfo({
		onSuccess() {
			queryCache.invalidateQueries(getCurrentCompanyQueryKey);
			toaster.success("Changes saved successfully.");
			toggle(false);
		}
	});
	const [
		updateCompanyAddress,
		{ isLoading: isSavingCompanyAddress }
	] = useUpdateCompanyAddress();
	const [on, toggle] = useToggle(false);
	const address = _pick(data, [
		"box",
		"city",
		"country",
		"number",
		"street",
		"zip",
		"latitude",
		"longitude"
	]);
	const {
		register,
		control,
		handleSubmit,
		watch,
		formState: { dirtyFields, errors },
		reset
	} = useForm();
	useEffect(() => {
		reset({
			address,
			industry: data?.industries?.map(industry => ({
				label: industry.name,
				value: industry._id
			})),
			languages: data?.languages?.map(l => ({ label: l.name, value: l._id })),
			offices: data?.locations?.offices?.map(o => ({
				label: `${o.city ? o.city + ", " : ""} ${o.country}`,
				value: o
			})),
			geo_coverage: data?.locations?.geo_coverage?.map(o => ({
				label: `${o.city ? o.city + ", " : ""} ${o.country}`,
				value: o
			})),
			functions: data?.functions?.map(f => ({
				category: { label: f.parent_sector.name, value: f.parent_sector._id },
				subCategory: {
					label: f.sector.name,
					value: f.sector._id
				},
				function: { label: f.name, value: f._id }
			})),
			skills: data?.skills,
			services: data?.services,
			size: `${data?.size?.from}-${data?.size?.to}`
		});
	}, [data]);

	async function geocode(fields) {
		return await Promise.all(
			fields?.map(async office => {
				const isPlaceId = typeof office.value === "string";
				if (isPlaceId) {
					let { results } = await geocoder.current.geocode({
						placeId: office.value
					});
					if (!results) return office.value;
					results = _get(results, "0", {});
					const city = _find(results?.address_components, {
						types: ["locality"]
					});
					const country = _find(results?.address_components, {
						types: ["country"]
					});
					const bounds = results?.geometry?.bounds.getCenter().toJSON();
					const viewport = {
						northeast: results?.geometry?.viewport.getNorthEast().toJSON(),
						southwest: results?.geometry?.viewport.getSouthWest().toJSON()
					};
					return {
						city: city?.long_name ?? "",
						street: "",
						zip: "",
						number: "",
						box: "",
						country: country.long_name,
						iso_country: country.short_name,
						viewport,
						latitude: bounds.lat,
						longitude: bounds.lng
					};
				}
				return office.value;
			})
		);
	}

	const onSubmit = async fields => {
		const offices = await geocode(_get(fields, "offices", []));
		const geo_coverage = await geocode(_get(fields, "geo_coverage", []));
		if (dirtyFields?.offices) {
			await updateLocations({
				tag: "offices",
				locations: offices ?? []
			});
		}
		if (dirtyFields?.geo_coverage) {
			await updateLocations({
				tag: "geo_coverage",
				locations: geo_coverage ?? []
			});
		}

		if (dirtyFields.address) {
			await updateCompanyAddress({
				...fields.address
			});
		}

		if (dirtyFields.functions || dirtyFields.skills || dirtyFields.languages) {
			await updateExpertise({
				functions: fields.functions
					? fields.functions?.map(f => ({
							_id: f.function?.value ?? "",
							sector_id: f.subCategory?.value ?? "",
							parent_sector_id: f.category?.value
					  }))
					: [],
				skills: fields.skills
					? fields.skills.map(s => ({
							_id: s._id,
							name: s.name,
							parent_sector: s.parent_sector?._id
					  }))
					: [],
				languages: fields.languages
					? fields.languages.map(l => ({ _id: l.value, name: l.label }))
					: []
			});
		}

		const size = fields.size.split("-");
		await updateAdditionalInfo({
			size: {
				from: size?.[0] || "0",
				to: size?.[1] || "50"
			},
			services: fields.services.map(service => service.label ?? service),
			industries: fields.industry?.map(industry => industry.value),
			description: data?.description
		});
	};

	const functions = watch("functions");
	const addressField = watch("address");
	const skills = watch("skills");

	const isLoading =
		isSavingExpertise ||
		isSavingLocations ||
		isSavingAdditionalInfo ||
		isSavingCompanyAddress;

	return (
		<EditableBox
			onSave={handleSubmit(onSubmit)}
			isLoading={isLoading}
			isEdit={on}
			toggle={toggle}
			displayValue={<RestFieldsData functions={functions} data={data} />}
		>
			<RestFieldsForm
				register={register}
				control={control}
				functions={functions}
				skills={skills}
				address={addressField}
				data={data}
				errors={errors}
			/>
		</EditableBox>
	);
}

export default RestFields;
