import React from "react";
import { v4 as uuid } from "uuid";
const Close = () => {
	const id = uuid();
	return (
		<svg
			width="12"
			height="16"
			viewBox="0 0 12 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 15.0002V3.41425L10.293 6.70725C10.684 7.09825 11.316 7.09825 11.707 6.70725C12.098 6.31625 12.098 5.68425 11.707 5.29325L6.707 0.29325C6.316 -0.09775 5.684 -0.09775 5.293 0.29325L0.293 5.29325C0.098 5.48825 0 5.74425 0 6.00025C0 6.25625 0.098 6.51225 0.293 6.70725C0.684 7.09825 1.316 7.09825 1.707 6.70725L5 3.41425V15.0002C5 15.5532 5.447 16.0002 6 16.0002C6.553 16.0002 7 15.5532 7 15.0002"
				fill={`url(#${id})`}
			/>
			<defs>
				<linearGradient
					id={id}
					x1="6.00012"
					y1="0"
					x2="6.00012"
					y2="16.0002"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F01C29" />
					<stop offset="1" stopColor="#FF6849" />
				</linearGradient>
			</defs>
		</svg>
	);
};
export default Close;
