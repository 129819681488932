import React, { useState, useEffect } from "react";
import Drawer from "rc-drawer";
import BodyClassName from "react-body-classname";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { useDispatch } from "react-redux";
import _get from "lodash/get";
import { useQuery, useMutation } from "react-query";
import Description from "common/Description";
import JobFunctions from "common/JobFunctions";
import ListItem from "common/ListItem";
import {
	MAX_LANGUAGE_WEIGHT,
	MAX_SKILL_WEIGHT,
	SKILLS,
	LANGUAGES,
	VACANCY,
	LOADING,
	EDUCATION_LEVELS,
	PUBLISHED,
	USER_SETTINGS_OPTIONS,
	JOB_SEARCH_SIZE,
	APPLY_TO_VACCANCY_MODAL
} from "config";
import cx from "classnames";
import styles from "./job-view-online.module.scss";
import { client } from "lib/api-client";
import {
	PREVIEW_JOB,
	APPLY_TO_VACANCY_ENDPOINT,
	UPDATE_USER_SETTING,
	GUEST_PREVIEW_JOB
} from "config/api-endpoints";
import Sidebar from "./Sidebar";
import {
	JobHeader,
	FunctionsContainer,
	Navigation,
	Container,
	JobImageContainer,
	JobImage
} from "./Styled";
import "rc-drawer/assets/index.css";
import toaster from "common/Toaster";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import { LoaderFull } from "common/Loader";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { ReactComponent } from "../icons/empty-logo-company.svg";
import Loader from "common/Loader";
import { formatDate } from "common/Functions";
import { isHTML, searchParser } from "config/helpers";
import { getJobExpiredRoute } from "common/JobExpired";
import { getMyProfile } from "modules/user/actions/authActions.js";
import VideoCard from "./VideoCard";
import loadable from "loadable-components";
import LexicalContentView from "common/LexicalContentView";

const OfflineApplicationModal = loadable(() =>
	import(/* webpackChunkName: "job-application" */ "./OfflineApplicationModal")
);

const ConfirmOfflineApplicationModal = loadable(() =>
	import(
		/* webpackChunkName: "job-application" */ "./ConfirmOfflineApplicationModal"
	)
);

const EmailAlreadyUsedModal = loadable(() =>
	import(/* webpackChunkName: "job-application" */ "./EmailAlreadyUsedModal")
);

const SignInModal = loadable(() =>
	import(/* webpackChunkName: "job-application" */ "./SignInModal")
);

const getJobData = (key, id, isOnline, version) =>
	client(isOnline ? PREVIEW_JOB : GUEST_PREVIEW_JOB, {
		body: { id, version }
	});

const applyToJob = data => client(APPLY_TO_VACANCY_ENDPOINT, { body: data });

const trackDoNotShowAgain = () =>
	client(UPDATE_USER_SETTING, {
		body: {
			type: USER_SETTINGS_OPTIONS,
			user_options: { [APPLY_TO_VACCANCY_MODAL]: true }
		}
	});

const openJobDescription = (id, version) =>
	browserHistory.push(`/jobs/search/${id}?job_lang=${version}`);
const JobViewOnline = ({
	user,
	job,
	jobsData,
	closeDrawer,
	offset,
	updateOffset,
	setSelectedJob,
	templateId,
	isOnline,
	isDirectAccess,
	vacancyId,
	isDirectApply,
	openDrawer,
	jobIndex,
	setJobIndex
}) => {
	const [checked, setChecked] = useState(false);
	const [displayConfirmationModal, setDisplayConfirmationModal] = useState(
		false
	);
	const [displayWarningModal, setDisplayWarningModal] = useState(false);
	const [
		displayOfflineApplicationModal,
		setDisplayOfflineApplicationModal
	] = useState(false);
	const [
		displayConfirmApplicationModal,
		setDisplayConfirmApplicationModal
	] = useState(false);
	const [newAccountEmail, setNewAccountEmail] = useState("");
	const [newAccountType, setNewAccountType] = useState("");
	const [displaySignInModal, setDisplaySignInModal] = useState(false);
	const [displayEmailUsedModal, setDisplayEmailUsedModal] = useState(false);
	const [vonqData, setVonqData] = useState({});
	const dispatch = useDispatch();

	const jobIds = _get(jobsData, "data", []).map(j => j.id);
	const search = searchParser();

	useEffect(() => {
		if (isDirectApply) {
			setDisplayOfflineApplicationModal(true);
		}
		if (isDirectAccess) {
			if (_get(search, "vq_source") && _get(search, "vq_campaign")) {
				setVonqData({
					source_id: search.vq_source,
					campaign_id: search.vq_campaign
				});
			}
		}
	}, []);
	useEffect(() => {
		jobIds[jobIndex] && loadNewJob();
	}, [jobsData, jobIndex]);

	useEffect(() => {
		setJobIndex(job.index);
	}, [job]);

	let vacId = jobIds[jobIndex];

	if (isDirectAccess) {
		vacId = vacancyId;
	}

	const query = useQuery(
		[
			"vacancy",
			vacId,
			isOnline,
			_get(search, "job_lang", false)
				? _get(search, "job_lang")
				: _get(job, "version")
		],
		getJobData,
		{
			enabled: vacId,
			refetchOnWindowFocus: false,
			onError: () => {
				if (vacId) {
					toaster.danger("404: not found.");
					browserHistory.push("/404");
				}
			}
		}
	);

	const data = query.data;
	const jobDescription = data?.description;
	const profileDescription = data?.profile_description;
	const isLoadingJob = query.status === LOADING;
	const isFetching = query.isFetching;

	const [mutate, { status: applyStatus }] = useMutation(applyToJob, {
		onSuccess: () => {
			toaster.success("Your application has successfully been sent!");
			setDisplayConfirmationModal(false);
			query.refetch();
			dispatch(getMyProfile());
		},
		onError: e => {
			if (e && e.status === 422) {
				let errorMsg = _get(e, "detail.name");
				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			}
			query.refetch();
		}
	});

	const [doNotShowAgain] = useMutation(trackDoNotShowAgain);

	const applyWithoutModal = user.apply_to_vacancy_modal;

	const sendJobApplication = () => {
		mutate({
			vacancy_id: vacId,
			version: _get(job, "version") || _get(search, "job_lang"),
			source_id: _get(vonqData, "source_id"),
			campaign_id: _get(vonqData, "campaign_id")
		});
		if (checked) {
			doNotShowAgain();
		}
	};

	const handleApplyToJob = () => {
		if (!isOnline) {
			return setDisplayOfflineApplicationModal(true);
		}
		if (applyWithoutModal) {
			return sendJobApplication();
		}
		return setDisplayConfirmationModal(true);
	};

	const isFirstJob = jobIndex === 0 && offset === 0;
	const isLastJob = jobIndex + 1 === _get(jobsData, "total", 0) - offset;

	const loadNewJob = () => {
		const newJob = _get(jobsData, "data", []).find((j, i) => i === jobIndex);
		if (newJob) {
			setSelectedJob({ ...newJob, index: jobIndex });
		}
		query.refetch();
	};

	const fetchNewJobs = () => {
		let newOffset = offset;
		if (jobIndex === JOB_SEARCH_SIZE - 1) {
			setJobIndex(0);
			newOffset += JOB_SEARCH_SIZE;
		} else if (jobIndex === 0) {
			setJobIndex(JOB_SEARCH_SIZE - 1);
			newOffset -= JOB_SEARCH_SIZE;
		}
		updateOffset(newOffset);
	};

	const handlePreviousClick = () => {
		if (isFirstJob) {
			return;
		}
		if (jobIndex === 0) {
			return setDisplayWarningModal(true);
		}
		openJobDescription(
			jobIds[jobIndex - 1],
			_get(jobsData, `data[${jobIndex - 1}].version`)
		);
		return setJobIndex(jobIndex - 1);
	};

	const handleNextClick = () => {
		if (isLastJob) {
			return;
		}
		if (jobIndex === JOB_SEARCH_SIZE - 1) {
			return setDisplayWarningModal(true);
		}
		openJobDescription(
			jobIds[jobIndex + 1],
			_get(jobsData, `data[${jobIndex + 1}].version`)
		);
		return setJobIndex(jobIndex + 1);
	};

	useEffect(() => {
		if (
			_get(query, "data.status") &&
			_get(query, "data.status") !== PUBLISHED
		) {
			return browserHistory.push(getJobExpiredRoute());
		}
	}, [_get(query, "data")]);

	return (
		<>
			<Drawer
				open={openDrawer}
				width={`${JOB_SEARCH_SIZE}5%`}
				height={"100%"}
				placement={"right"}
				style={{ zIndex: 1000 }}
				level={"root"}
				maskClosable={true}
				onClose={closeDrawer}
			>
				<LoaderFull loading={isLoadingJob} />
				<BodyClassName className="job-view gray-bg">
					<div className="view-job-details">
						<div className="container">
							<div className="row">
								<Navigation>
									<div className="back-to-list" onClick={closeDrawer}>
										<div className="icon-container">
											<span className="icon-arrow-right1" />
										</div>
										Back to list
									</div>
									{!isLoadingJob && isFetching && <Loader />}
									{!isDirectAccess && (
										<div className="actions-container">
											<button
												className={`action ${isFirstJob ? "disabled" : ""}`}
												onClick={handlePreviousClick}
											>
												<span className="arrow">&larr;</span> Previous
											</button>
											<button
												className={`action ${isLastJob ? "disabled" : ""}`}
												onClick={handleNextClick}
											>
												Next job<span className="arrow"> &rarr;</span>
											</button>
										</div>
									)}
								</Navigation>

								<div className="col-md-8 content-inner">
									<JobHeader>
										{_get(data, "company.avatar") ? (
											<>
												<div className="logo">
													<img src={_get(data, "company.avatar")} />
												</div>
											</>
										) : (
											<div className="logo-placeholder">
												<ReactComponent />
											</div>
										)}
										<div className="title">
											{_get(data, "title")}
											<div className="time">
												Published on {formatDate(_get(data, "publish_date"))}
											</div>
										</div>
									</JobHeader>
									{data?.featured_video && (
										<VideoCard url={data?.featured_video} />
									)}
									<div className={cx("section description", styles.flex)}>
										{isHTML(jobDescription) ? (
											<LexicalContentView description={jobDescription} />
										) : (
											<Description
												description={jobDescription}
												parseMarkdown={true}
												showMore
												max={500}
												showMoreText="Read more"
											/>
										)}
										{profileDescription && (
											<LexicalContentView description={profileDescription} />
										)}
									</div>
									{data?.featured_image && (
										<JobImageContainer>
											<JobImage
												src={data.featured_image}
												alt="Featured image"
											/>
										</JobImageContainer>
									)}
									<FunctionsContainer>
										<JobFunctions functions={_get(data, "functions", [])} />
									</FunctionsContainer>
									<ListItem
										title={"Skills"}
										list={_get(data, "skills", [])}
										scoreMax={MAX_SKILL_WEIGHT}
										type={SKILLS}
										jobType={VACANCY}
										isVacancy={true}
									/>
									<ListItem
										title={"Languages"}
										list={_get(data, "languages", [])}
										scoreMax={MAX_LANGUAGE_WEIGHT}
										type={LANGUAGES}
									/>
									<div className="section">
										<div className="section-title"> level</div>
										<div
											style={{
												border: "1px solid #e4e5e7",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												borderRadius: "3px",
												width: "max-content",
												padding: "0 14px",
												margin: " 0 10px 10px 0",
												height: "30px",
												backgroundColor: "#e4e5e7",
												fontWeight: "500",
												fontSize: "13px",
												color: "#58626b"
											}}
										>
											{EDUCATION_LEVELS.map(level => {
												if (level.value === _get(data, "education_level")) {
													return <>{level.label}</>;
												}
												return <></>;
											})}
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<Sidebar
										job={data}
										displayApplyModal={handleApplyToJob}
										canApplyToJob={_get(data, "can_apply")}
										isLoading={applyStatus === LOADING}
										templateId={templateId}
										isOnline={isOnline}
										candidateStatus={user.candidate_status}
									/>
								</div>
							</div>
						</div>
					</div>
				</BodyClassName>
				<ConfirmationModal
					active={displayConfirmationModal}
					modalName="apply-to-vacancy"
					onClose={() => setDisplayConfirmationModal(false)}
					firstButton={{
						action: sendJobApplication,
						label: "Yes"
					}}
					title="Are you sure you would like to apply to
									this position?"
					content="Once your application is confirmed, your profile will
									no longer be anonymous to this company."
					type={ConfirmationTypes.confirmation}
					loading={applyStatus === LOADING}
					doNotShowAgain={true}
					doNotShowComponent={() => (
						<DoNotShowAgain checked={checked} setChecked={setChecked} />
					)}
				/>
				<ConfirmationModal
					active={displayWarningModal}
					modalName="fetch-jobs"
					onClose={() => setDisplayWarningModal(false)}
					firstButton={{
						action: () => {
							fetchNewJobs();
							setDisplayWarningModal(false);
						},
						label: "Yes, load new jobs."
					}}
					title="Are you sure you would like load new jobs."
					content="Doing so will update the list of jobs displayed when you go back to the search page."
					type={ConfirmationTypes.confirmation}
					loading={isLoadingJob}
				/>
				{displayOfflineApplicationModal && (
					<OfflineApplicationModal
						onClose={() => setDisplayOfflineApplicationModal(false)}
						vacancy_id={vacId}
						companyName={_get(data, "company.name")}
						vacancyTitle={_get(data, "title")}
						city={_get(data, "company.city")}
						setNewAccountEmail={setNewAccountEmail}
						displayConfirmApplicationModal={() =>
							setDisplayConfirmApplicationModal(true)
						}
						displaySignInModal={() => setDisplaySignInModal(true)}
						setNewAccountType={setNewAccountType}
						displayEmailUsedModal={() => setDisplayEmailUsedModal(true)}
						loginAndApply={() => {
							setNewAccountEmail("");
							setDisplayOfflineApplicationModal(false);
							setDisplaySignInModal(true);
						}}
						vonqData={vonqData}
					/>
				)}
				{displayConfirmApplicationModal && (
					<ConfirmOfflineApplicationModal
						companyName={_get(data, "company.name")}
						newAccountEmail={newAccountEmail}
						onClose={() => setDisplayConfirmApplicationModal(false)}
						displayOfflineApplicationModal={() =>
							setDisplayOfflineApplicationModal(true)
						}
					/>
				)}
				{displaySignInModal && (
					<SignInModal
						companyName={_get(data, "company.name")}
						vacancyTitle={_get(data, "title")}
						city={_get(data, "company.city")}
						vacancy_id={vacId}
						onClose={() => setDisplaySignInModal(false)}
						templateId={templateId}
						newEmail={newAccountEmail}
						vonqData={vonqData}
					/>
				)}
				{displayEmailUsedModal && (
					<EmailAlreadyUsedModal
						companyName={_get(data, "company.name")}
						vacancyTitle={_get(data, "title")}
						city={_get(data, "company.city")}
						accountType={newAccountType}
						onClose={() => {
							setDisplayEmailUsedModal(false);
						}}
						backAction={() => {
							setDisplayEmailUsedModal(false);
							setDisplayOfflineApplicationModal(true);
						}}
					/>
				)}
			</Drawer>
		</>
	);
};

const DoNotShowAgain = ({ checked, setChecked }) => {
	return (
		<Container>
			<div className="checkbox checkbox-primary">
				<input
					id={"do-not-show-again"}
					type="checkbox"
					onChange={() => setChecked(!checked)}
					checked={checked}
					value={checked}
					className="styled"
					name="do-not-show"
				/>
				<label htmlFor={"do-not-show"}>Do not show this again</label>
			</div>
		</Container>
	);
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default connect(mapStateToProps)(JobViewOnline);
