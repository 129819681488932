import React from "react";
import { useRef } from "react";
import { VisuallyHidden, useCheckbox, useFocusRing } from "react-aria";
import { useToggleState } from "react-stately";
import styles from "./checkbox.module.scss";
import cx from "classnames";

export default function Checkbox(props) {
	const ref = useRef();
	const state = useToggleState(props);
	const { inputProps } = useCheckbox(props, state, ref);
	const { focusProps, isFocused } = useFocusRing();

	return (
		<label className={styles.label}>
			<VisuallyHidden>
				<input {...inputProps} {...focusProps} ref={ref} />
			</VisuallyHidden>
			<div
				className={cx(
					props.className,
					styles.checkmark,
					isFocused && styles.focused,
					state.isSelected && styles.selected
				)}
			>
				{state.isSelected && (
					<svg width="12" height="13" viewBox="0 0 12 13" fill="none">
						<path
							d="M10 3.25977L4.5 8.75977L2 6.25977"
							stroke="white"
							strokeWidth="1.6666"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				)}
			</div>
			{props.children}
		</label>
	);
}
