import React, { useMemo } from "react";
import get from "lodash/get";
import { browserHistory } from "react-router";

import SkeletonComponent from "../components/Skeleton";
import GlobalTooltip from "common/GlobalTooltip";
import { formatDate } from "common/Functions";
import Avatar from "common/Avatar";
import { ReactComponent as MoreIcon } from "static/icons/more-points.svg";
import { ReactComponent as TypeIcon } from "static/icons/status-icon.svg";
import { ReactComponent as LinkToIcon } from "static/icons/link_to_icon.svg";
import { ReactComponent as UserIcon } from "static/icons/user-icon.svg";
import { ReactComponent as CreationDateIcon } from "static/icons/creation-date-icon.svg";
import {
	VALUE_OPERATORS,
	DATE_OPERATORS,
	LINK_TO_NOTES,
	CREATED_AT,
	NOTE_TYPE,
	CREATED_BY,
	VACANCY,
	NOTE_PROFILE
} from "config";
import { client } from "lib/api-client";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { parseMentions } from "config/helpers";
import { v4 as uuid } from "uuid";
import styles from "../Pages/Notes/notes.module.scss";

const getFilterSettings = (tag, id, search) =>
	client(NEW_FILTER_SETTINGS, {
		body: {
			id,
			list: "notes",
			tag,
			search
		}
	});

export const sortBylist = [
	{
		field: CREATED_AT,
		icon: <CreationDateIcon />,
		label: "Date",
		options: []
	},
	{
		field: NOTE_TYPE,
		icon: <TypeIcon />,
		label: "Type",
		options: []
	},
	{
		field: CREATED_BY,
		icon: <UserIcon />,
		label: "Owner",
		options: []
	}
];

export const filterList = id => [
	{
		field: CREATED_AT,
		icon: <CreationDateIcon />,
		label: "Date",
		options: [],
		selectedOption: "",
		oprators: DATE_OPERATORS,
		isMultipleSelect: false,
		id: uuid()
	},
	{
		field: NOTE_TYPE,
		icon: <TypeIcon />,
		label: "Type",
		options: [],
		filterOptions: () => getFilterSettings(NOTE_TYPE, id),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},

	{
		field: LINK_TO_NOTES,
		icon: <LinkToIcon />,
		label: "Linked to ",
		options: [
			{
				value: "vacancy",
				label: "Vacancy",
				filterOptions: (_, search) => getFilterSettings(VACANCY, id, search)
			},
			{
				value: "profile",
				label: "Profile",
				filterOptions: (_, search) => getFilterSettings("profile", id, search)
			}
		],
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	},
	{
		field: CREATED_BY,
		icon: <UserIcon />,
		label: "Owner",
		options: [],
		filterOptions: () => getFilterSettings(CREATED_BY, id),
		selectedOption: "",
		oprators: VALUE_OPERATORS,
		isMultipleSelect: true,
		id: uuid()
	}
];

export const columns = sortedField => [
	{
		Header: "Date",
		accessor: CREATED_AT,
		isSorted: sortedField === CREATED_AT
	},
	{
		Header: "Note",
		accessor: "description"
	},
	{
		Header: "Type",
		accessor: NOTE_TYPE,
		isSorted: sortedField === NOTE_TYPE
	},
	{
		Header: "Linked to",
		accessor: LINK_TO_NOTES,
		isSorted: sortedField === LINK_TO_NOTES
	},
	{
		Header: "Owner",
		accessor: CREATED_BY,
		isSorted: sortedField === CREATED_BY
	}
];

export const dataSkeleton = new Array(9).fill({
	created_at: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	description: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	note_type: <SkeletonComponent width={60} height={14} borderRadius={10} />,
	linked_to_notes: (
		<SkeletonComponent width={60} height={14} borderRadius={10} />
	),
	created_by: <SkeletonComponent width={60} height={14} borderRadius={10} />
});

const redirectToEntity = (e, id) => {
	e.stopPropagation();
	const link = `vacancy/${id}`;
	link && browserHistory.push(link);
};
export const getData = (isFetching, resolvedData) =>
	useMemo(
		() =>
			isFetching
				? dataSkeleton
				: get(resolvedData, "data", []).map(item => {
						const description = parseMentions(get(item, "description", ""));
						const avatar = get(item, "created_by.avatar");
						const first_name = get(item, "created_by.first_name");
						const last_name = get(item, "created_by.last_name");
						const renderDescription =
							description.length > 40 ? (
								<GlobalTooltip
									placement={"top"}
									trigger="hover"
									overlay={description}
									overlayClassName="g-tool-text"
								>
									<div className={styles.overlay}>
										{description.slice(0, 40)}{" "}
										<span>
											<MoreIcon />
										</span>
									</div>
								</GlobalTooltip>
							) : (
								description
							);

						const renderAvatar = (
							<GlobalTooltip
								placement={"top"}
								trigger="hover"
								overlay={`${first_name} ${last_name}`}
							>
								<div className={styles.avatar}>
									<Avatar
										name={`${first_name} ${last_name}`}
										avatar={avatar}
										size={30}
									/>
								</div>
							</GlobalTooltip>
						);
						const linkToText = get(item, "job.title", NOTE_PROFILE);
						const linkTo =
							linkToText.length > 44 ? (
								<>
									{linkToText.slice(0, 45)}{" "}
									<span>
										<MoreIcon />
									</span>
								</>
							) : (
								linkToText
							);

						const renderLinkTo =
							linkToText !== NOTE_PROFILE ? (
								<button
									className={styles.linkToButton}
									onClick={e => redirectToEntity(e, get(item, "job_id"))}
								>
									<span className={styles.linkTo}>{linkTo}</span>
								</button>
							) : (
								NOTE_PROFILE
							);
						return {
							id: get(item, "_id"),
							job_id: get(item, "job_id"),
							created_at: formatDate(get(item, "created_at")),
							description: renderDescription,
							note_type: get(item, "note_type", "N/A"),
							linked_to_notes: renderLinkTo,
							created_by: renderAvatar
						};
				  }),
		[isFetching]
	);
