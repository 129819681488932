import React from "react";
import GlobalTooltip from "common/GlobalTooltip";
import styles from "./contracts-group-actions-cell.module.scss";

const ContractsGroupsActionsCell = ({
	onDeleteClick,
	onModifyClick,
	isDeleteButtonDisabled
}) => {
	return (
		<div className={styles.container}>
			<button onClick={onModifyClick}>Modify</button>
			{isDeleteButtonDisabled ? (
				<GlobalTooltip
					placement="bottom"
					overlay="The group has contracts associated with it"
					align={{}}
					withWrappingDiv={false}
				>
					<div className={styles.disabledDeleteButtonContainer}>
						<button
							className={styles.disabledDeleteButton}
							disabled
							onClick={onDeleteClick}
						>
							Delete
						</button>
					</div>
				</GlobalTooltip>
			) : (
				<button onClick={onDeleteClick}>Delete</button>
			)}
		</div>
	);
};

export default ContractsGroupsActionsCell;
