import React from "react";
import { DecoratorNode } from "lexical";
import styles from "../plugin/PlaceholderLinkPlugin/placeholder-link-plugin.module.scss";
import { ReactComponent as PlaceholderLinkIcon } from "static/icons/placeholder-link-icon.svg";
import Tooltip from "common/Tippy";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { PLACEHOLDER_LINK_COMMAND } from "../plugin/PlaceholderLinkPlugin/PlaceholderLinkPlugin";
import cx from "classnames";
import { useFormContext } from "react-hook-form";
import { useMemo } from "react";

export class PlaceholderLinkNode extends DecoratorNode {
	__text;
	__placeholder;
	static __placeholders;

	constructor({ text, placeholder }, key) {
		super(key);
		this.__text = text;
		this.__placeholder = placeholder;
	}

	static getType() {
		return "link-placeholder";
	}

	static clone(node) {
		return new PlaceholderLinkNode(
			{
				text: node.__text,
				placeholder: node.__placeholder
			},
			node.key
		);
	}

	createDOM() {
		const element = document.createElement("a");
		element.setAttribute(
			"data-lexical-email-link-placeholder",
			this.__placeholder
		);
		element.style.display = "inline-block";
		element.classList.add(styles.decorator);
		return element;
	}

	updateDOM() {
		return true;
	}

	exportDOM() {
		const element = document.createElement("a");
		element.setAttribute(
			"data-lexical-email-link-placeholder",
			this.__placeholder
		);
		element.setAttribute("href", `*|${this.__placeholder}|*`);
		element.setAttribute("target", "_blank");
		element.innerText = this.__text;
		return { element };
	}

	setPlaceholder(placeholder) {
		const self = this.getWritable();
		self.__placeholder = placeholder;
	}

	static importDOM() {
		return {
			a: domNode => {
				if (!domNode.hasAttribute("data-lexical-email-link-placeholder")) {
					return null;
				}
				return {
					conversion: domNode => {
						return {
							node: $createPlaceholderLinkNode({
								text: domNode.innerText,
								placeholder: domNode.getAttribute(
									"data-lexical-email-link-placeholder"
								)
							})
						};
					},
					priority: 4
				};
			}
		};
	}

	static importJSON({ text, placeholder }) {
		return $createPlaceholderLinkNode({ text, placeholder });
	}

	exportJSON() {
		return {
			placeholder: this.__placeholder,
			text: this.__text,
			type: PlaceholderLinkNode.getType(),
			version: 1
		};
	}

	getTextContent() {
		return this.__text;
	}

	isInline() {
		return true;
	}

	isKeyboardSelectable() {
		return true;
	}

	static getEntity(placeholderValue) {
		let result = {};
		Object.entries(PlaceholderLinkNode.__placeholders).forEach(
			([entity, object]) => {
				Object.entries(object).forEach(([key, value]) => {
					if (value === placeholderValue) {
						result = { key, entity };
					}
				});
			}
		);
		return result;
	}

	decorate() {
		const { entity, key } = PlaceholderLinkNode.getEntity(this.__placeholder);
		return (
			<PlaceholderLinkNodeDecorator
				entity={entity}
				entityKey={key}
				placeholder={this.__placeholder}
				text={this.__text}
				node={this}
			/>
		);
	}
}

export function $createPlaceholderLinkNode({ text, placeholder }) {
	return new PlaceholderLinkNode({ text, placeholder });
}

export function $isPlaceholderLinkNode(node) {
	return node instanceof PlaceholderLinkNode;
}

function PlaceholderLinkNodeDecorator({
	entity,
	entityKey,
	placeholder,
	text,
	node
}) {
	const [editor] = useLexicalComposerContext();
	const { watch } = useFormContext();

	const onClick = () => {
		editor.dispatchCommand(PLACEHOLDER_LINK_COMMAND, node);
	};

	const contentPlaceholders = watch("content_placeholders");

	const linkEntityObject = useMemo(() => {
		return contentPlaceholders?.find?.(
			entityObject => entityObject?.value === placeholder
		);
	}, [contentPlaceholders]);

	return (
		<Tooltip content={`[${entity}.${entityKey}]`} theme="dark">
			<div
				onClick={onClick}
				className={cx(styles.decoratorText, {
					[styles.empty]: !contentPlaceholders ? false : !linkEntityObject?._id
				})}
			>
				<PlaceholderLinkIcon /> {text}
			</div>
		</Tooltip>
	);
}
