import { OPERATORS } from "common/FilterDrawer/utils";
import { TASKS_TABLE_COLUMNS_IDS, TYPES } from "./constant";
import coupleIcon from "static/icons/person-icon.svg";
import preferenceIcon from "static/icons/preferences-icon.svg";
import starIcon from "static/icons/star-icone.svg";
import {
	getUsersList,
	getSupplierList,
	getTaskPriorityList,
	getTaskStatusList,
	getRequestList,
	getVacancyList,
	getInternalList,
	getFreelancerList,
	getExternalList,
	getCandidatesList,
	getTasksReminderOptions
} from "../utils/helpers";

getRequestList;

export const COLUMNS = [
	{
		id: 1,
		label: "Task details",
		icon: coupleIcon,
		children: [
			{
				label: "Title",
				name: TASKS_TABLE_COLUMNS_IDS.title,
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Status",
				name: TASKS_TABLE_COLUMNS_IDS.status,
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: () => "Status remained",
					secondaryHeaderContent: () => "Status selected",
					useServerFilter: false,
					queryFn: getTaskStatusList
				}
			},
			{
				label: "Due date",
				name: TASKS_TABLE_COLUMNS_IDS.due_date,
				type: TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "Reminder",
				name: TASKS_TABLE_COLUMNS_IDS.reminder,
				type: TYPES.select,
				operator: OPERATORS.is,
				payload: {
					options: getTasksReminderOptions()
				}
			},
			{
				label: "Created by",
				name: TASKS_TABLE_COLUMNS_IDS.created_by,
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: () => "Created by remained",
					secondaryHeaderContent: () => "Created by selected",
					useServerFilter: true,
					queryFn: getUsersList
				}
			},
			{
				label: "Priority",
				name: TASKS_TABLE_COLUMNS_IDS.priority,
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? "Priority remained" : "Priorities remained",
					secondaryHeaderContent: ({ singular }) =>
						singular ? "Priority selected" : "Priorities selected",
					useServerFilter: false,
					queryFn: getTaskPriorityList
				}
			},
			{
				label: "Creation date",
				name: TASKS_TABLE_COLUMNS_IDS.created_at,
				type: TYPES.date,
				operator: OPERATORS.is
			}
		]
	},
	{
		id: 2,
		label: "Link to",
		icon: preferenceIcon,
		children: [
			{
				label: "Candidate",
				name: "candidate",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? "Candidate remained" : "Candidates remained",
					secondaryHeaderContent: ({ singular }) =>
						singular ? "Candidate selected" : "Candidates selected",
					useServerFilter: true,
					queryFn: getCandidatesList
				}
			},
			{
				label: "Freelancer",
				name: "freelancer",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? "Freelancer remained" : "Freelancers remained",
					secondaryHeaderContent: ({ singular }) =>
						singular ? "Freelancer selected" : "Freelancers selected",
					useServerFilter: true,
					queryFn: getFreelancerList
				}
			},
			{
				label: "Supplier",
				name: "supplier",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? "Supplier remained" : "Suppliers remained",
					secondaryHeaderContent: ({ singular }) =>
						singular ? "Supplier selected" : "Suppliers selected",
					useServerFilter: true,
					queryFn: getSupplierList
				}
			},
			{
				label: "Vacancy",
				name: "vacancy",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? "Vacancy remained" : "Vacancies remained",
					secondaryHeaderContent: ({ singular }) =>
						singular ? "Vacancy selected" : "Vacancies selected",
					useServerFilter: true,
					queryFn: getVacancyList
				}
			},
			{
				label: "Request",
				name: "request",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? "Request remained" : "Requests remained",
					secondaryHeaderContent: ({ singular }) =>
						singular ? "Request selected" : "Requests selected",
					useServerFilter: true,
					queryFn: getRequestList
				}
			}
		]
	},
	{
		id: 3,
		label: "Assign to",
		icon: starIcon,
		children: [
			{
				label: "Internal",
				name: "internal",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? `Internal remained` : `Internals remained`,
					secondaryHeaderContent: ({ singular }) =>
						singular ? `Internal selected` : `Internals selected`,
					useServerFilter: true,
					queryFn: getInternalList
				}
			},
			{
				label: "External",
				name: "external",
				type: TYPES.array,
				operator: OPERATORS.is,
				payload: {
					displayRating: false,
					primaryHeaderContent: ({ singular }) =>
						singular ? `External remained` : `Externals remained`,
					secondaryHeaderContent: ({ singular }) =>
						singular ? `External selected` : `Externals selected`,
					queryFn: getExternalList,
					useServerFilter: true
				}
			}
		]
	}
];
