import styled, { css } from "styled-components";
import { colors } from "config/styles";
import theme from "../theme";

export default styled.i`
	box-sizing: border-box;
	display: inline-block;
	font-size: ${props => (props.size ? `${props.size}px` : `14px`)} !important;
	font-style: normal;
	height: 1em;
	top: -1px;
	position: relative;
	vertical-align: middle;
	${props =>
		props.right
			? css`
					margin-right: 10px;
			  `
			: css`
					margin-left: 10px;
			  `};
	width: 1em;
	&::before,
	&::after {
		display: block;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	${props =>
		props.moveOnHover &&
		css`
			left: 0;
		`};
`;

export const StyledSplitIcon = styled.i`
	background-color: ${theme.splitIcon};
	color: ${colors.white};
	padding: 6px 9px;
	cursor: pointer;
	transition: none;
	font-weight: 400;
	border-radius: 3px;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	display: table-cell;
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	transition: none !important;
`;

export const StyledEditIcon = styled.i`
	color: ${colors.bullColor};
`;
