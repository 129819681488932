import React, { forwardRef, memo, useState } from "react";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import MarkdownShortcutPlugins from "./plugin/MarkdownShortcutPlugin";
import FloatingTextFormatToolbarPlugin, {
	AIToolsPlugin
} from "./plugin/FloatingTextFormatToolbarPlugin";
import MyCustomAutoFocusPlugin from "./plugin/MyCustomAutoFocusPlugin";
import MentionsPlugin from "./plugin/MentionsPlugin";
import RichTextContentEditable from "./ui/ContentEditable";
import Theme from "./themes/Theme";
import EditorNodes from "./nodes/EditorNodes";
import { $generateHtmlFromNodes } from "@lexical/html";
import { $generateNodesFromDOM } from "@lexical/html";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { $getRoot } from "lexical";
import "./editor.css";
import cx from "classnames";
import { URL_MATCHERS_LIST, validateUrl } from "./utils/helpers";
import { removeTags } from "config/helpers";
import FloatingLinkEditorPlugin from "./plugin/FloatingLinkEditorPlugin";
import EmailPlaceholderPlugin from "./plugin/EmailPlaceholderPlugin";
import { MaxLengthPlugin } from "./plugin/MaxLengthPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import PreventLineBreakPlugin from "./plugin/PreventLineBreakPlugin/PreventLineBreakPlugin";
import EmailSignaturePlugin from "./plugin/EmailSignaturePlugin";
import PlaceholderLinkPlugin from "./plugin/PlaceholderLinkPlugin/PlaceholderLinkPlugin";

const LexicalErrorBoundary = ({ children }) => {
	return <div>{children}</div>; //TODO
};

const Placeholder = ({ placeholder, className }) => {
	return (
		<div className={cx("editor-placeholder", className)}>{placeholder}</div>
	);
};

const Editor = (
	{
		placeholder,
		placeholderClassName,
		editorContainerClassName,
		idVacancy,
		onChange: onChangeProp,
		showToolbaar,
		setShowToolbar,
		setMentions,
		id,
		steps,
		mentions = [],
		addMention,
		oldUsers,
		scrollClassName,
		contentEditorClassName,
		useMentionPlugin = true,
		useEmailPlaceholderPlugin = false,
		useEmailSignaturePlugin = false,
		renderEmailSignature,
		editorId = "",
		editorPath = "",
		onEmailPlaceholdersChange,
		value: propValue,
		focusClassName,
		focus = true,
		editorTextLength,
		editorMaxLength = 1000,
		textLengthClassName = "",
		editorStateHandler,
		limitText,
		isRichText = true,
		editorWrapperClassName,
		editorRef,
		namespace,
		onFocus,
		useAiTools = false,
		onEnhanceSelectedText
	},
	ref
) => {
	const [isLinkEditMode, setIsLinkEditMode] = useState(false);
	const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

	const onRef = _floatingAnchorElem => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	const initialConfig = {
		namespace: namespace || `${editorPath}.${editorId}`,
		nodes: [...EditorNodes],
		onError: error => {
			throw error;
		},
		theme: Theme,
		// display comment from editor
		editorState: editor => {
			if (typeof editorStateHandler === "function") {
				editorStateHandler(editor);
			} else {
				const parser = new DOMParser();
				if (propValue) {
					const dom = parser.parseFromString(propValue, "text/html");
					if (dom !== null) {
						const nodes = $generateNodesFromDOM(editor, dom);
						const rootNode = $getRoot();
						rootNode.append(...nodes);
					}
				}
			}
		}
	};

	const onChange = (_, editor) => {
		editor.update(() => {
			const html = $generateHtmlFromNodes(editor, null);
			const comment = removeTags(html).trim().length > 0 ? html : "";

			if (propValue !== comment) {
				onChangeProp(comment);
			}
		});
	};

	return (
		<LexicalComposer initialConfig={initialConfig}>
			<EditorRefPlugin editorRef={editorRef} />
			<div
				className={cx("editor-container", editorContainerClassName)}
				onFocus={onFocus}
			>
				<div className={cx("editor-inner", editorWrapperClassName)} ref={onRef}>
					{isRichText ? (
						<>
							<RichTextPlugin
								contentEditable={
									<RichTextContentEditable
										steps={steps}
										scrollClassName={scrollClassName}
										className={contentEditorClassName}
										focusClassName={focusClassName}
										ref={ref}
									/>
								}
								placeholder={
									<Placeholder
										placeholder={placeholder}
										className={placeholderClassName}
									/>
								}
								ErrorBoundary={LexicalErrorBoundary}
							/>

							{!!editorTextLength && (
								<div className={cx("editor-text-length", textLengthClassName)}>
									{`${editorTextLength} / ${editorMaxLength}`}
								</div>
							)}
							{useMentionPlugin && (
								<MentionsPlugin
									setMentions={setMentions}
									mentions={mentions}
									idVacancy={idVacancy}
									id={id}
									addMention={addMention}
									oldUsers={oldUsers}
									content={propValue}
								/>
							)}
							<ListPlugin />
							{limitText && <MaxLengthPlugin maxLength={editorMaxLength} />}
							<AutoLinkPlugin matchers={URL_MATCHERS_LIST} />
							<LinkPlugin validateUrl={validateUrl} />
							{useAiTools && (
								<AIToolsPlugin onEnhanceSelectedText={onEnhanceSelectedText} />
							)}
							{floatingAnchorElem && (
								<>
									<FloatingLinkEditorPlugin
										anchorElem={floatingAnchorElem}
										isLinkEditMode={isLinkEditMode}
										setIsLinkEditMode={setIsLinkEditMode}
									/>
									<FloatingTextFormatToolbarPlugin
										showToolbaar={showToolbaar}
										setShowToolbar={setShowToolbar}
										setIsLinkEditMode={setIsLinkEditMode}
										useEmailPlaceholderPlugin={useEmailPlaceholderPlugin}
										useAiTools={useAiTools}
									/>
								</>
							)}
							<MyCustomAutoFocusPlugin focus={focus} />
							<MarkdownShortcutPlugins />
							<TabIndentationPlugin />
						</>
					) : (
						<>
							<PlainTextPlugin
								contentEditable={
									<ContentEditable className={contentEditorClassName} />
								}
								placeholder={
									<Placeholder
										placeholder={placeholder}
										className={placeholderClassName}
									/>
								}
								ErrorBoundary={LexicalErrorBoundary}
							/>
							<FloatingTextFormatToolbarPlugin
								anchorElem={floatingAnchorElem}
								showToolbaar={showToolbaar}
								setShowToolbar={setShowToolbar}
								setIsLinkEditMode={setIsLinkEditMode}
							/>
							<PreventLineBreakPlugin />
						</>
					)}
					<OnChangePlugin onChange={onChange} ignoreSelectionChange />
					<HistoryPlugin />
					{useEmailPlaceholderPlugin && (
						<>
							<EmailPlaceholderPlugin
								onEmailPlaceholdersChange={onEmailPlaceholdersChange}
							/>
							<PlaceholderLinkPlugin />
						</>
					)}
					{useEmailSignaturePlugin && (
						<EmailSignaturePlugin renderEmailSignature={renderEmailSignature} />
					)}
				</div>
			</div>
		</LexicalComposer>
	);
};

export default memo(forwardRef(Editor));
