import React from "react";
import { DecoratorNode } from "lexical";
import styles from "../plugin/EmailPlaceholderPlugin/styles.module.scss";
import MessagingToolNewEmailPlaceholdersDecoratorComponent from "common/Editor/plugin/EmailPlaceholderPlugin/components/MessagingToolNewEmailPlaceholdersDecoratorComponent/MessagingToolNewEmailPlaceholdersDecoratorComponent";

export const EMAIL_TEMPLATE_VIEW = "EMAIL_TEMPLATE_VIEW";
export const MAIL_BOX_VIEW = "MAIL_BOX_VIEW";

export class EmailPlaceholderNode extends DecoratorNode {
	__placeholder = {};
	static __placeholders = {};
	static __view = "";

	constructor(placeholder, key) {
		super(key);
		this.__placeholder = placeholder;
	}

	static getType() {
		return "email-placeholder-decorator";
	}

	static clone(node) {
		return new EmailPlaceholderNode(node.__placeholder, node.key);
	}

	createDOM() {
		const element = document.createElement("span");
		element.setAttribute(
			"data-lexical-email-placeholder",
			this.__placeholder.value
		);
		element.style.display = "inline-block";
		return element;
	}

	exportDOM() {
		const element = document.createElement("span");
		element.setAttribute(
			"data-lexical-email-placeholder",
			this.__placeholder.value
		);
		element.innerText = `*|${this.__placeholder.value}|*`;
		return { element };
	}

	static importDOM() {
		return {
			span: domNode => {
				if (!domNode.hasAttribute("data-lexical-email-placeholder")) {
					return null;
				}
				return {
					conversion: domNode => {
						const placeholderValue = domNode.getAttribute(
							"data-lexical-email-placeholder"
						);
						const { key, entity } = EmailPlaceholderNode.getEntity(
							placeholderValue
						);
						const placeholder = {
							value: placeholderValue,
							key,
							entity
						};
						return {
							node: $createEmailPlaceholderNode(placeholder)
						};
					},
					priority: 1
				};
			}
		};
	}

	static importJSON(serializedNode) {
		const node = $createEmailPlaceholderNode(serializedNode.placeholder);
		return node;
	}

	exportJSON() {
		return {
			placeholder: this.__placeholder,
			type: "email-placeholder-decorator",
			version: 1
		};
	}

	updateDOM() {
		return false;
	}

	isInline() {
		return true;
	}

	static getEntity(placeholderValue) {
		let result = {};
		Object.entries(EmailPlaceholderNode.__placeholders).forEach(
			([entity, object]) => {
				Object.entries(object).forEach(([key, value]) => {
					if (value === placeholderValue) {
						result = { key, entity };
					}
				});
			}
		);
		return result;
	}
	decorate() {
		if (EmailPlaceholderNode.__view === MAIL_BOX_VIEW)
			return (
				<MessagingToolNewEmailPlaceholdersDecoratorComponent
					placeholder={this.__placeholder}
				/>
			);
		return (
			<span className={styles.placeholder}>
				{`[${this.__placeholder.entity}.${this.__placeholder.key}]`}
			</span>
		);
	}
}

export function $createEmailPlaceholderNode(placeholder) {
	return new EmailPlaceholderNode(placeholder);
}

export function $isEmailPlaceholderNode(node) {
	return node instanceof EmailPlaceholderNode;
}
