import React from "react";
import styles from "./bid-details-unlock-profile.module.scss";
import { ReactComponent as LockIcon } from "static/icons/lock-white.svg";
import Tooltip from "common/Tippy";

const BidDetailsUnlockProfile = ({ handleUnlockProfile }) => {
	return (
		<Tooltip
			content={
				<div className={styles.container}>
					Unlock Profile and Access candidate&apos;s full information, including
					email, phone number, and more.
				</div>
			}
			theme="dark"
			placement="bottom"
			className={styles.tooltip}
		>
			<button className={styles.lock} onClick={handleUnlockProfile}>
				<LockIcon />
				<span>Unlock profile</span>
			</button>
		</Tooltip>
	);
};

export default BidDetailsUnlockProfile;
