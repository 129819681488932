import { useMutation } from "react-query";
import { PROPOSE_SKILL } from "config/api-endpoints";
import { client } from "lib/api-client";

const suggestSkill = body => {
	return client(PROPOSE_SKILL, {
		body
	});
};

export const useSuggestSkill = () => {
	const res = useMutation(suggestSkill);

	return res;
};
