import create from "zustand";

const initialState = {
	indexTab: 0
};

const usePokeView = create(set => ({
	...initialState,
	setIndexTab: value => {
		set({
			indexTab: value
		});
	}
}));

export default usePokeView;
