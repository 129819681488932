import React, { useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import {
	useUpdateColumnSettings,
	useMoveColumnSettings
} from "./api/useUpdateColumnSettings";
import toaster from "../Toaster";
import { useDebounce } from "react-use";

import "./agTable.scss";
import CustomHeader from "./components/CustomHeader/CustomHeader";
import { queryCache } from "react-query";
ModuleRegistry.registerModules([ClientSideRowModelModule]);
const rowStyle = {
	color: "#262626",
	height: "44px !important"
};
const defaultColDef = {
	height: "auto",
	resizable: true
};
const getRowHeight = () => 48;

const getRowStyle = params => {
	const isSelected =
		typeof params.node.data.isSelected === "object"
			? params.node.data.isSelected.selected
			: params.node.data.isSelected;

	if (isSelected) {
		if (params.node.rowIndex % 2 === 0) {
			return { background: "#ddefee" };
		} else {
			return { background: "#e6f8f7" };
		}
	} else {
		if (params.node.rowIndex % 2 === 0) {
			return { background: "#F5F5F5" };
		} else {
			return { background: "#FFFFFF" };
		}
	}
};
const headerComponent = {
	agColumnHeader: CustomHeader
};

// eslint-disable-next-line react/display-name
const Table = React.forwardRef((props, ref) => {
	const {
		columnsSettings,
		rows = null,
		noRowsOverlayComponent,
		disabledClickCell = [],
		typeTag,
		onCellClicked,
		onFirstDataRendered
	} = props;

	const containerStyle = {
		width: "100%",
		height: "635px"
	};
	const [moveAction, setMoveAction] = useState(null);
	const [updateActions, setUpdateActions] = useState(null);
	const [mutateUpdateColumn] = useUpdateColumnSettings({
		onSuccess: () => {
			queryCache.invalidateQueries(`@getColumnsSettings_${typeTag}`);
		},
		onError: () => {
			toaster.danger("Error while saving changes.");
		}
	});
	const [mutateMoveColumn] = useMoveColumnSettings({
		onSuccess: () => {
			queryCache.invalidateQueries(`@getColumnsSettings_${typeTag}`);
		},
		onError: () => {
			toaster.danger("Error while saving changes.");
		}
	});
	useDebounce(
		() => handleUpdateColumns(updateActions?.type, updateActions?.event),
		500,
		[updateActions]
	);
	useDebounce(() => handleMoveColumn(moveAction), 500, [moveAction]);
	//  this two functions  get the information from localstorage and set it in the columns :
	// handle the actions resize hide ordering :
	const handleUpdateColumns = (type, event) => {
		if (event?.source === "gridOptionsChanged") {
			return;
		}
		if (type === "RESIZE") {
			if (event?.column) {
				mutateUpdateColumn({
					columns: [event?.column?.userProvidedColDef?.colId],
					type: typeTag,
					width: event?.column?.actualWidth,
					tag: "resize_column"
				});
			}
		}
		if (type === "HIDE") {
			if (event?.column) {
				mutateUpdateColumn({
					columns: [event?.column?.userProvidedColDef.colId],
					type: typeTag,
					hide: !event?.column?.userProvidedColDef.hide,
					tag: "hide_columns"
				});
			}
		}
	};
	const handleMoveColumn = event => {
		if (event?.type === "columnMoved") {
			if (event?.toIndex && event?.column) {
				mutateMoveColumn({
					position: event?.toIndex - 1,
					column_name: event?.column?.userProvidedColDef?.colId,
					tag: "column",
					type: typeTag
				});
			}
		} else if (
			event?.type === "columnPinned" &&
			event.source !== "gridOptionsChanged" &&
			event.source !== "api"
		) {
			const allCols = event.columnApi.getAllGridColumns();
			const column = event.column;
			const allFixedCols = allCols.filter(col => col.getColDef().lockPosition);
			const allNonFixedCols = allCols.filter(
				col => !col.getColDef().lockPosition
			);
			const pinnedCount = allNonFixedCols.filter(
				col => col.getPinned() === "left"
			).length;
			const pinFixed = pinnedCount > 0;
			const columnStates = [];
			allFixedCols.forEach(col => {
				if (pinFixed !== col.isPinned()) {
					columnStates.push({
						colId: col.getId(),
						pinned: pinFixed ? "left" : null
					});
				}
			});

			if (columnStates.length > 0) {
				event.columnApi.applyColumnState({ state: columnStates });
			}

			if (event.pinned === "left") {
				mutateUpdateColumn({
					columns: [column?.colId],
					type: typeTag,
					pinned: "left",
					tag: "pin_column_left"
				});
			}
			// pin right :
			if (event.pinned === "right") {
				mutateUpdateColumn({
					columns: [column?.colId],
					type: typeTag,
					pinned: "right",
					tag: "pin_column_right"
				});
			}

			if (event.pinned === null) {
				mutateUpdateColumn({
					columns: [column?.colId],
					type: typeTag,
					tag: "unpin_columns"
				});
				if (columnStates.length > 0) {
					event.columnApi.applyColumnState({ state: columnStates });
				}
			}
		}
	};
	const cellClickHandler = params => {
		if (
			!disabledClickCell.includes(params.colDef.colId) &&
			disabledClickCell !== "LOADING"
		) {
			onCellClicked(params);
		}
	};

	return (
		<>
			<div style={containerStyle} className="ag-theme-alpine">
				<AgGridReact
					// we can use the ref form useRef to get all information about the AgGrid and api that we can use inside or outSide the Table
					ref={ref}
					rowStyle={rowStyle}
					getRowStyle={getRowStyle}
					getRowHeight={getRowHeight}
					//for the smooth animation in Rows set i to true
					animateRows="true"
					components={headerComponent}
					columnDefs={columnsSettings}
					rowData={rows}
					defaultColDef={defaultColDef}
					suppressDragLeaveHidesColumns={true}
					// suppressColumnVirtualisation={true}
					// enableRangeSelection={true}
					suppressRowClickSelection={true}
					rowSelection={"multiple"}
					// enableCellTextSelection={true}
					// this props give us the abilities to work this all events , for better performance we use _debounce
					onColumnResized={event => {
						setUpdateActions({ type: "RESIZE", event });
					}}
					onColumnVisible={event => {
						setUpdateActions({ type: "HIDE", event });
					}}
					onColumnMoved={event => {
						setMoveAction(event);
					}}
					onCellClicked={cellClickHandler}
					onColumnPinned={handleMoveColumn}
					noRowsOverlayComponent={noRowsOverlayComponent}
					onFirstDataRendered={onFirstDataRendered}
				/>
			</div>{" "}
		</>
	);
});

export default Table;
