import React from "react";
import styles from "common/AgTable/components/Checkbox/Checkbox.module.scss";
import { ReactComponent as CheckedIcon } from "static/icons/Checkbox.svg";
import { ReactComponent as CheckEmpty } from "static/icons/DeleteCheck.svg";

const ConfirmationBox = ({ checked, onChange }) => {
	return (
		<div>
			<label htmlFor="cbx" className={styles.labelCbx}>
				<input type="checkbox" checked={checked} className={styles.invisible} />
				<div onClick={onChange}>
					{checked ? (
						<CheckedIcon className={styles.emptySvg} />
					) : (
						<CheckEmpty className={styles.emptySvg} />
					)}
				</div>
			</label>
		</div>
	);
};
export default ConfirmationBox;
