import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { MY_JOBS_LIST } from "config/api-endpoints";
import { REQUEST } from "config";
import { requestKey } from "modules/home/api/queriesKeys.js";

const TAG = "welcome_page_requests";

const listRequests = (key, { start_date, end_date }) =>
	client(MY_JOBS_LIST, {
		body: {
			start_date,
			end_date,
			tag: TAG,
			job_type: REQUEST
		}
	});

const useRequestList = (options, queryOptions = {}) =>
	useQuery([requestKey, options], listRequests, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});

export default useRequestList;
