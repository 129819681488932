import React from "react";
import styles from "../styles.module.scss";
import generalSettingsStyles from "../../../styles.module.scss";
import SettingsRadioBtn from "../../../StatusSettings/SettingsRadioBtn";
import { ReactComponent as ChevronLeftIcon } from "static/icons/chevronLeftIcon.svg";
import { ReactComponent as ChevronRightIcon } from "static/icons/chevronRightIcon.svg";
import {
	OTHER,
	feedbackOptions,
	useFeedbackStep
} from "../../../hooks/useDeleteAccountSteps";
import useDeleteAccountStore from "../store";
import FormField from "common/FormField";

export default function FeedBackStep() {
	const { hasPrev, feedBack } = useDeleteAccountStore();

	const {
		formIsInvalid,
		maxLengthExceeded,
		events: { onStepChange, onChangeRadioBtn },
		textarea: { feedbackTextarea, setFeedbackTextarea }
	} = useFeedbackStep();

	return (
		<div>
			<div className={styles.flex_col} style={{ margin: "25px 0" }}>
				<h5 className={styles.subtitle} style={{ marginBottom: "10px" }}>
					Why are you deleting your account?
				</h5>
				{feedbackOptions.map(option => {
					return (
						<SettingsRadioBtn
							key={option.key}
							name={option.key}
							label={option.label}
							checked={feedBack && feedBack.key === option.key}
							onChange={() => onChangeRadioBtn(option)}
						/>
					);
				})}
				{feedBack && feedBack.key === OTHER && (
					<FormField
						type="textarea"
						displayHelpBlock={false}
						hasError={maxLengthExceeded}
						placeholder={"Tell us about it..."}
						meta={{ error: false, touched: false }}
						input={{
							name: "other",
							value: feedbackTextarea,
							onBlur: event => event.preventDefault(),
							onFocus: event => event.preventDefault(),
							onChange: event => setFeedbackTextarea(event.target.value)
						}}
						classes={
							maxLengthExceeded
								? `${styles.form__textarea} ${styles.form__textarea_error}`
								: styles.form__textarea
						}
						errorComponent={
							<span className={styles.form__textarea_error}>
								Maximum number of characters exceeded
							</span>
						}
					/>
				)}
			</div>
			<div
				className={styles.buttons}
				style={{ justifyContent: "space-between" }}
			>
				<button
					disabled={!hasPrev}
					onClick={() => onStepChange("prev")}
					className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__secondary}`}
				>
					<ChevronLeftIcon />
					Back
				</button>
				<button
					disabled={formIsInvalid}
					onClick={() => onStepChange("next")}
					className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__secondary}`}
				>
					Next
					<ChevronRightIcon />
				</button>
			</div>
		</div>
	);
}
