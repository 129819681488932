import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function TemplatesNamesListSekeleton() {
	return (
		<div>
			<SkeletonTheme color="#F1F1F1">
				{Array.from(Array(8)).map((_, index) => (
					<div
						key={index}
						style={{ padding: "14px 20px", borderBottom: "1px solid #E2E8F0" }}
					>
						<Skeleton width={129} height={10} style={{ borderRadius: 99 }} />
						<div>
							<Skeleton width={34} height={10} style={{ borderRadius: 99 }} />
							<Skeleton
								width={34}
								height={10}
								style={{ borderRadius: 99, marginLeft: 10 }}
							/>
						</div>
					</div>
				))}
			</SkeletonTheme>
		</div>
	);
}
