import React from "react";
import Steps from "common/steps";
import { CLIENT, CREATE_COMPANY_FREELANCER } from "config";
import { LoaderFull } from "common/Loader";

const CompanyDashboard = ({ type, active, skippedIdentity }) => {
	let steps = CREATE_COMPANY_FREELANCER;

	const errorSteps = [];
	const identityIndex = Number(type === CLIENT);

	if (skippedIdentity && active > identityIndex) errorSteps.push(identityIndex);
	return type === CLIENT ? (
		<LoaderFull text="Logging in to your account..." classes="full-loader" />
	) : (
		<div className="company-dashboard">
			<Steps errorSteps={errorSteps} activeStep={active} steps={steps} />
		</div>
	);
};

export default CompanyDashboard;
