import React from "react";
import folder from "../static/images/folder.jpg";
import { LinkButton } from "common/styled/buttons";

function EmptyComponent({
	title,
	paragraph,
	actionLink,
	actionText,
	style = {},
	className
}) {
	return (
		<div className={`empty-job ${className}`} style={style}>
			<img alt={title} src={folder} />
			<h3>{title}</h3>
			{paragraph && <p>{paragraph}</p>}
			{actionLink && <LinkButton to={actionLink}>{actionText}</LinkButton>}
		</div>
	);
}

export default EmptyComponent;
