//TODO reduce params
import { useEffect } from "react";
import { useState } from "react";
import LoadingStateCell from "common/AgTable/components/RenderCellsGlobal/LoadingStateCell/LoadingStateCell";
import { ActionsHeader } from "common/AgTable/components/ActionsHeader";
import SelectColumn from "common/AgTable/components/SelectColumn";
import { GDPR_TABLE_COLUMNS } from "../utils/constants";

export const useGetGdprListColumns = (
	toggleCard,
	checkCandidateSelection,
	isLoading,
	isFetching,
	isSelectAll,
	toggleAll,
	tableRef
) => {
	const [columnsList, setColumnsList] = useState([]);

	useEffect(() => {
		const columns = GDPR_TABLE_COLUMNS.map(item => {
			return {
				...item,
				refGrid: tableRef,
				cellRenderer:
					isLoading || isFetching ? LoadingStateCell : item.cellRenderer
			};
		});

		const firstColumn = {
			colId: "_selects",
			field: "",
			width: 45,
			maxWidth: 45,
			resizable: false,
			hide: false,
			filter: false,
			cellClass: "locked-visible",
			headerComponent: ActionsHeader,
			lockVisible: true,
			cellRenderer: isLoading || isFetching ? LoadingStateCell : SelectColumn,
			headerComponentParams: {
				checked: isSelectAll,
				onSelectChange: toggleAll
			},
			cellRendererParams: {
				handleSetSelectedCandidate: toggleCard,
				hideExpand: true,
				hideDots: true
			},
			suppressMovable: true
		};

		setColumnsList([firstColumn, ...columns]);
	}, [
		toggleCard,
		checkCandidateSelection,
		isLoading,
		isFetching,
		isSelectAll,
		toggleAll
	]);

	return columnsList;
};
