import React from "react";
import { Button } from "common/styled/buttons";
import { SearchContainer } from "common/styled/CommonStyled";

const SearchBox = props => {
	return (
		<SearchContainer
			vacancy={props.vacancy}
			className={!props.vacancy && "container"}
		>
			<div className="row text-search">
				<div className="col-md-12">
					<form onSubmit={props.handleFormSubmit}>
						<div className="col-md-10 search-container">
							<span id="job__search-search__icon" className="icon-search" />
							<input
								type="text"
								id="job-input"
								onChange={props.handleKeywordChange}
								value={props.value || ""}
								placeholder={
									props.placeholder
										? props.placeholder
										: "Search by keyword (PHP, .NET, design, etc)"
								}
							/>
							{props.hasReset && (
								<div className="reset" onClick={props.resetSearch}>
									<span className="icon-reset-3" />{" "}
								</div>
							)}
						</div>
						<div className="col-md-2 div_button-search">
							<Button className="button-search" block>
								Search
							</Button>
						</div>
					</form>
				</div>
			</div>
		</SearchContainer>
	);
};

export default SearchBox;
