import React from "react";
const PinLeftIcon = ({ color, stroke, width, height }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.58471 10.411L1.81348 14.1822M7.7965 4.42796L6.75591 5.46855C6.67103 5.55343 6.62859 5.59587 6.58023 5.6296C6.53731 5.65953 6.49102 5.68431 6.44231 5.70341C6.38742 5.72494 6.32857 5.73671 6.21086 5.76025L3.76788 6.24885C3.13301 6.37582 2.81558 6.43931 2.66707 6.60668C2.53769 6.75249 2.47861 6.94761 2.50538 7.1407C2.53611 7.36233 2.76501 7.59124 3.22282 8.04905L7.94665 12.7729C8.40446 13.2307 8.63336 13.4596 8.855 13.4903C9.04809 13.5171 9.24321 13.458 9.38902 13.3286C9.55639 13.1801 9.61987 12.8627 9.74685 12.2278L10.2354 9.78484C10.259 9.66713 10.2708 9.60828 10.2923 9.55339C10.3114 9.50468 10.3362 9.45838 10.3661 9.41546C10.3998 9.36711 10.4423 9.32467 10.5271 9.23979L11.5677 8.19919C11.622 8.14492 11.6491 8.11779 11.679 8.0941C11.7055 8.07305 11.7335 8.05405 11.7629 8.03727C11.796 8.01837 11.8313 8.00325 11.9018 7.97302L13.5647 7.26035C14.0498 7.05243 14.2924 6.94848 14.4026 6.78049C14.499 6.63358 14.5334 6.45458 14.4985 6.2824C14.4586 6.0855 14.272 5.89889 13.8988 5.52568L10.47 2.09692C10.0968 1.7237 9.91019 1.53709 9.7133 1.49718C9.54112 1.46227 9.36212 1.49675 9.21521 1.5931C9.04722 1.70329 8.94327 1.94585 8.73535 2.43099L8.02268 4.09389C7.99245 4.16444 7.97733 4.19971 7.95843 4.23278C7.94165 4.26217 7.92265 4.29023 7.9016 4.31672C7.87791 4.34655 7.85077 4.37369 7.7965 4.42796Z"
				stroke={color}
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default PinLeftIcon;
