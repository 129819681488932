import React, { PureComponent } from "react";
import RcDropDown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import PropTypes from "prop-types";

const initialState = {
	dropdownPosition: "bottomCenter",
	align: {
		points: ["tc", "bc"],
		offset: [0, -5]
	}
};

class Actions extends PureComponent {
	state = initialState;

	onVisibleChange = visible => {
		const { id, reposition, onVisibleChange } = this.props;
		const windowHeight = window.innerHeight;
		setTimeout(() => {
			const element = document.getElementsByClassName(`menu-${id}`)[0];
			if (!element) return;
			if (reposition && visible) {
				const boundingBox = element.getBoundingClientRect();
				if (boundingBox.y + boundingBox.height > windowHeight) {
					this.setState({
						dropdownPosition: "topCenter",
						align: {
							points: ["bc", "tc"],
							offset: [0, 15]
						}
					});
				}
			} else if (reposition) {
				this.setState(initialState);
			}
			element.classList.add("rc-shown");
		}, 0);

		onVisibleChange && onVisibleChange();
	};

	listMenu = () => {
		const { actions, onClick } = this.props;
		return (
			<Menu onClick={onClick} selectable={false}>
				{actions.map(
					action =>
						action.visible && (
							<MenuItem
								key={action.key}
								className={`ignore-click ${action.key}`}
							>
								<span className="ignore-click">
									<i className={`icon ${action.icon} ignore-click`} />
									{action.label}
								</span>
							</MenuItem>
						)
				)}
			</Menu>
		);
	};

	render() {
		const { children, id, reposition } = this.props;
		return (
			<RcDropDown
				trigger={["click"]}
				placement={this.state.dropdownPosition}
				overlay={this.listMenu()}
				overlayClassName={`ignore-click menu-${id} rc-hidden`}
				onVisibleChange={this.onVisibleChange}
				align={reposition && this.state.align}
				onOverlayClick={this.onVisibleChange}
				animation="slide-up"
			>
				{children}
			</RcDropDown>
		);
	}
}

Actions.propTypes = {
	onVisibleChange: PropTypes.func
};

Actions.defaultProps = {
	onVisibleChange: () => {}
};

export default Actions;
