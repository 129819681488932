import { useMutation } from "react-query";
const { SUBMIT_TIMESHEET } = require("config/api-endpoints");
const { client } = require("lib/api-client");
import toaster from "common/Toaster";
import { get } from "lodash";

const submitTimesheet = data => client(SUBMIT_TIMESHEET, { body: data });

const useSubmitTimesheet = () => {
	return useMutation(submitTimesheet, {
		onError: error => {
			if (error && error.status === 422) {
				let errorMsg = get(error, "detail.name");

				if (errorMsg) {
					toaster.danger(errorMsg);
				}
			} else {
				toaster.danger(get(error, "detail", get(error, "title")));
			}
		}
	});
};

export default useSubmitTimesheet;
