import React from "react";
import Drawer from "rc-drawer";
import { Box, Flex, Text, Button } from "rebass";
import AddComment from "./AddComment";
import AddCommentClient from "./AddCommentClient";
import SingleComment from "./SingleComment";
import EmptyStateComment from "static/images/Emptystate-comment.png";
import { ReactComponent as CloseIcon } from "static/icons/close-comments-box.svg";
import { get } from "lodash";
import { ACCEPTED } from "config";

const CommentsDrawer = ({
	closeDrawer,
	openCommentsDrawer,
	comments = [],
	view,
	timesheetId,
	id,
	loadTimeSheet,
	status,
	isClient
}) => {
	return (
		<Drawer
			open={openCommentsDrawer}
			height={"100%"}
			width={"400px"}
			placement={"right"}
			style={{ zIndex: 9 }}
			level={"root"}
			maskClosable={true}
			onClose={e => {
				e.stopPropagation();
				closeDrawer();
			}}
		>
			<Flex
				flexDirection="column"
				sx={{
					background: "#FFFFFF",
					boxShadow: "0px 12px 16px rgba(0, 0, 0, 0.04)",
					padding: 20,
					height: "100%"
				}}
				onClick={e => e.stopPropagation()}
			>
				<Flex justifyContent="space-between" alignItems="center">
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: 600,
							fontSize: "16px",
							lineHeight: "34px",
							color: "#6E7191"
						}}
					>
						Show comment
					</Text>
					<Button
						onClick={e => {
							e.stopPropagation();
							closeDrawer();
						}}
						sx={{
							padding: 0,
							border: "none",
							background: "transparent"
						}}
					>
						<CloseIcon />
					</Button>{" "}
				</Flex>

				<Box
					sx={{
						height: "90%"
					}}
					overflowY="auto"
				>
					{get(comments, "length", 0) > 0 ? (
						(comments || []).map((item, index) => (
							<SingleComment key={index} item={item} />
						))
					) : (
						<Flex height="90%" justifyContent="center" alignItems="center">
							<img
								style={{ height: "150px", width: "150px" }}
								src={EmptyStateComment}
							/>
						</Flex>
					)}
				</Box>
				{status !== ACCEPTED && (
					<Box
						sx={{
							borderTop: "1px solid #EFF0F7",
							padding: "23px 0px"
						}}
					>
						{isClient ? (
							<AddCommentClient
								loadTimeSheet={loadTimeSheet}
								id={id}
								timesheetId={timesheetId}
								view={view}
								maxLength={100}
							/>
						) : (
							<AddComment
								loadTimeSheet={loadTimeSheet}
								id={id}
								timesheetId={timesheetId}
								view={view}
								maxLength={100}
							/>
						)}
					</Box>
				)}
			</Flex>
		</Drawer>
	);
};

export default CommentsDrawer;
