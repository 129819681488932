import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { ReactComponent as CheckIcon } from "static/icons/TitleNewIcon.svg";
import { ReactComponent as AddIcon } from "static/icons/PlusAdd.svg";
import FeatureAlert from "../FeatureAlert/FeatureAlert";
import HeaderButton from "../HeaderButton";
import style from "./page-header.module.scss";
import { useCookies } from "react-cookie";
import { ReactComponent as BackArrow } from "static/icons/IconBack.svg";
import { goBack } from "config/helpers";
import GuideModal from "../HeaderFilters/GuideModal/GuideModal";
import { HOW_TO_SOURCE_AND_INVITE_CANDIDATE } from "config/onboarding";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import ProcessMenu from "./ProcessMenu";
import { ReactComponent as DownArrow } from "static/icons/IcondownArrow.svg";

const PageHeader = ({
	setDisplayCreateCandidateModal,
	selectionBucket,
	limit,
	MAX_CANDIDATE,
	mode,
	selectedCandidatesCounter
}) => {
	const [openAlert, setOpenAlert] = useState(true);
	const [cookies, setCookie] = useCookies(["firstTime"]);
	const [openModal, setOpenModal] = useState(false);
	const { workflowId } = useOnboardingStore();

	const handleCloseButtonClick = () => {
		setOpenAlert(openAlert => !openAlert);
		setCookie("firstTime", false);
	};

	const openModalGuide = () => {
		setOpenModal(true);
	};

	const closeModalGuide = () => {
		setOpenModal(false);
	};

	const selectedCount =
		selectedCandidatesCounter > 0 ? "(" + selectedCandidatesCounter + ")" : "";

	return (
		<div className={style.headerContainer}>
			<AnimatePresence>
				{!workflowId && !cookies.firstTime && openAlert && (
					<FeatureAlert onClose={handleCloseButtonClick} icon={<CheckIcon />}>
						<div className={style.headerAlert}>
							<div className={style.contentText}>
								<p className={style.textGras}>Good news, everyone</p>
								<p className={style.textNormal}>
									We’ve made a few improvements and added new features to help
									you accomplish your tasks more easily and effectively.
								</p>
								<button
									onClick={handleCloseButtonClick}
									className={style.okButton}
								>
									Got it
								</button>
							</div>
						</div>
					</FeatureAlert>
				)}
			</AnimatePresence>
			<div className={style.headerCandidate}>
				<div className={style.first}>
					<button onClick={goBack}>
						<BackArrow />
					</button>
					<h3>Search candidates</h3>
					<a
						onClick={() => {
							openModalGuide();
						}}
						className={style.btnPlay}
					>
						How does this work?
					</a>
				</div>

				<div className={style.btnContainer}>
					<HeaderButton
						text={`Process candidates ${selectedCount}`}
						secondIcon={<DownArrow />}
						variant="wide"
						className="buttonProcess"
						disabled={selectedCandidatesCounter <= 0}
						data-onboarding-step={`${HOW_TO_SOURCE_AND_INVITE_CANDIDATE}-3`}
					>
						<ProcessMenu
							selectionBucket={selectionBucket}
							limit={limit}
							MAX_CANDIDATE={MAX_CANDIDATE}
							mode={mode}
						/>
					</HeaderButton>
					<HeaderButton
						onClick={() => setDisplayCreateCandidateModal(true)}
						text="Add candidates"
						className="buttonAdd"
						icon={<AddIcon />}
						data-onboarding-step={`${HOW_TO_SOURCE_AND_INVITE_CANDIDATE}-0`}
					/>
				</div>
			</div>
			<div className={style.separator}></div>
			{openModal && <GuideModal onClose={closeModalGuide} />}
		</div>
	);
};

export default PageHeader;
