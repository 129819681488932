import React from "react";
import styles from "./resume.module.scss";
import BidDetailsEmptyResume from "./BidDetailsEmptyResume";
import { PDF_VIEWER_URL } from "config";

const BidDetailsResumeTab = ({ path }) => (
	<div className={styles.container}>
		{path ? (
			<>
				<iframe
					title="PDF Viewer"
					style={{
						width: "100%",
						minHeight: "895px",
						overflow: "hidden",
						border: "none"
					}}
					src={PDF_VIEWER_URL + path.replace(/^http:\/\//i, "https://")}
					width="100%"
				></iframe>
			</>
		) : (
			<BidDetailsEmptyResume />
		)}
	</div>
);

export default BidDetailsResumeTab;
