import React, { useMemo } from "react";
import FormField from "common/FormField";
import styles from "../styles.module.scss";
import useDeleteAccountStore from "../store";
import generalSettingsStyles from "../../../styles.module.scss";
import { ReactComponent as CheckIcon } from "static/icons/checkIcon.svg";
import { ReactComponent as FireIcon } from "static/icons/fireIcon.svg";
import { ReactComponent as PhoneIcon } from "static/icons/phoneIcon.svg";
import { ReactComponent as TodoIcon } from "static/icons/todoIcon.svg";
import { ReactComponent as ChevronLeftIcon } from "static/icons/chevronLeftIcon.svg";
import { ReactComponent as TalendPoolIcon } from "static/icons/talendPoolIcon.svg";
import { useDeleteAccountStep } from "../../../hooks/useDeleteAccountSteps";
import Skeleton from "react-loading-skeleton";

const checkboxPlaceholder =
	"I’m sure that I want to cancel all my processes, and leave all talent pools";

export default function DeleteAccountStep({ onClose }) {
	const {
		form: { formIsInvalid, deleteUserAccount },
		state: { errorMsg, password, confirmDelete },
		ongoingProcess: { isLoading, payload, hasProcessOngoing },
		events: { onFocusPswdInput, onClickCheckboxInput, onChangePswdInput }
	} = useDeleteAccountStep(onClose);

	const { hasPrev, decrementStep } = useDeleteAccountStore();
	return (
		<div>
			{/* ongoing process */}
			{!!hasProcessOngoing && (
				<OngoingProcesses payload={payload} isLoading={isLoading} />
			)}

			{/* password form */}
			<div className={styles.form}>
				<FormField
					type="checkbox"
					id="confirm-delete"
					checked={confirmDelete}
					classes={styles.form__checkbox}
					placeholder={checkboxPlaceholder}
					meta={{ touched: false, error: false }}
					onClick={onClickCheckboxInput}
					input={{
						name: "confirm-delete",
						onBlur: event => event.preventDefault(),
						onFocus: event => event.preventDefault()
					}}
					inputContainerClasses={styles.form__container_checkbox}
				/>
				{confirmDelete && (
					<FormField
						type="password"
						label="Password"
						hasError={!!errorMsg}
						displayHelpBlock={false}
						placeholder={"Enter password"}
						meta={{ error: false, touched: false }}
						input={{
							name: "password",
							value: password,
							onFocus: onFocusPswdInput,
							onChange: onChangePswdInput,
							onBlur: event => event.preventDefault()
						}}
						classes={
							errorMsg
								? `${styles.form__password} ${styles.form__password_error}`
								: styles.form__password
						}
						errorComponent={
							<span className={styles.form__password_error}>{errorMsg}</span>
						}
					/>
				)}
			</div>

			{/* buttons actions */}
			<div
				className={styles.buttons}
				style={{ justifyContent: "space-between" }}
			>
				<button
					disabled={!hasPrev}
					onClick={decrementStep}
					className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__secondary}`}
				>
					<ChevronLeftIcon />
					Back
				</button>

				<button
					disabled={formIsInvalid}
					onClick={deleteUserAccount}
					className={`${generalSettingsStyles.btn} ${generalSettingsStyles.btn__red}`}
				>
					Delete account
				</button>
			</div>
		</div>
	);
}

function OngoingProcesses({ payload, isLoading }) {
	const isHidden = useMemo(() => {
		return {
			offer_accepted: !isLoading && !payload?.offer_accepted,
			offer_sent: !isLoading && !payload?.offer_sent,
			interview_scheduled: !isLoading && !payload?.interview_scheduled,
			interview_done: !isLoading && !payload?.interview_done,
			talent_pool: !isLoading && !payload?.talent_pool
		};
	}, [payload, isLoading]);

	return (
		<div className={styles.flex_col} style={{ marginTop: "24px" }}>
			<h5 className={styles.subtitle} style={{ marginBottom: "5px" }}>
				You have some ongoing processes
			</h5>
			<div
				className={styles.list_item}
				style={isHidden.offer_accepted ? { display: "none" } : {}}
			>
				<TodoIcon />
				{!isLoading ? (
					<p>{`${payload.offer_accepted} offers accepted`}</p>
				) : (
					<Loader width="120px" />
				)}
			</div>
			<div
				className={styles.list_item}
				style={isHidden.offer_sent ? { display: "none" } : {}}
			>
				<FireIcon />
				{!isLoading ? (
					<p>{`${payload.offer_sent} new offers`}</p>
				) : (
					<Loader width="80px" />
				)}
			</div>
			<div
				className={styles.list_item}
				style={isHidden.interview_scheduled ? { display: "none" } : {}}
			>
				<PhoneIcon />
				{!isLoading ? (
					<p>{`${payload.interview_scheduled} interviews scheduled`}</p>
				) : (
					<Loader width="140px" />
				)}
			</div>
			<div
				className={styles.list_item}
				style={isHidden.interview_done ? { display: "none" } : {}}
			>
				<CheckIcon />
				{!isLoading ? (
					<p>{`${payload.interview_done} interviews done`}</p>
				) : (
					<Loader width="100px" />
				)}
			</div>
			<div
				className={styles.list_item}
				style={isHidden.talent_pool ? { display: "none" } : {}}
			>
				<TalendPoolIcon />
				{!isLoading ? (
					<p>{`belongs to ${payload.talent_pool} talent pools`}</p>
				) : (
					<Loader />
				)}
			</div>
		</div>
	);
}

function Loader({ width = 180, height = 12 }) {
	return (
		<Skeleton
			width={width}
			height={height}
			color="#E2E8F0"
			style={{ marginTop: "6px" }}
		/>
	);
}
