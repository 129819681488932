import React from "react";
import cx from "classnames";
import { viewTypes } from "./freelancersStore";
import styles from "./freelancersHeader.module.scss";
import { PENDING_FREELANCER_FILTER_ID } from "modules/freelancers/pendingActivation/utils/constants";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";

const FreelancerHeader = ({ activeView, handleViewChange }) => {
	const { getState } = useFilters();
	const { data } = getState(PENDING_FREELANCER_FILTER_ID);

	return (
		<div className={styles.freelancersContainer}>
			<div className={styles.panel}>
				<nav>
					<div className={styles.tabs}>
						<button
							onClick={() => {
								handleViewChange(viewTypes.my_freelancers);
							}}
							className={cx(styles.tab, {
								[styles.active]: activeView === viewTypes.my_freelancers
							})}
						>
							Freelancers
						</button>

						<button
							onClick={() => {
								handleViewChange(viewTypes.pending_activation);
							}}
							className={cx(styles.tab, {
								[styles.active]: activeView === viewTypes.pending_activation
							})}
						>
							Pending activation {data && `(${data.total})`}
						</button>
					</div>
					<div className={styles.presentationSlider}></div>
				</nav>
			</div>
		</div>
	);
};

export default FreelancerHeader;
