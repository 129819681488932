import React, { forwardRef } from "react";
import cx from "classnames";
import Loader from "../../modules/SearchCandidate/components/Loader/Loader";
import styles from "./button.module.scss";

const Button = (
	{
		text,
		icon: leftIcon,
		variant = "contained",
		disabled,
		className,
		inlineStyle,
		onClick,
		rootClassName,
		textClassName,
		isLoading,
		rightIcon,
		id = "",
		...rest
	},
	ref
) => {
	return (
		<div ref={ref} className={cx(styles.root, rootClassName)}>
			<button
				disabled={disabled}
				id={id}
				onClick={onClick}
				style={inlineStyle}
				className={cx(styles.wrapper, className, {
					[styles.contained]: variant === "contained",
					[styles.containedText]: variant === "contained-text",
					[styles.text]: variant === "text",
					[styles.outlined]: variant === "outlined",
					[styles.disabled]: disabled
				})}
				{...rest}
			>
				{!isLoading ? (
					<>
						{leftIcon}
						<div className={cx(styles.globalText, textClassName)}>{text}</div>
						{rightIcon}
					</>
				) : (
					<Loader />
				)}
			</button>
		</div>
	);
};

export default forwardRef(Button);
