import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
	flexWrap,
	flexDirection,
	alignItems,
	justifyContent
} from "styled-system";
import base, { themed } from "../base";

const BoxWrapper = styled("div")(
	base,
	themed("Box"),
	props =>
		props.flexBox &&
		css(
			{ display: "flex" },
			flexWrap,
			flexDirection,
			alignItems,
			justifyContent,
			themed("FlexBox")
		)
);

const Box = ({ children, ...props }) => (
	<BoxWrapper {...props}>{children}</BoxWrapper>
);

export default Box;

Box.propTypes = {
	children: PropTypes.any.isRequired,
	flexBox: PropTypes.bool,
	as: PropTypes.oneOf([
		"div",
		"article",
		"section",
		"address",
		"header",
		"footer",
		"nav",
		"main",
		"label"
	]),
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	fontSize: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	color: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	flex: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	order: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	alignSelf: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	display: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	border: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	borderTop: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	borderRight: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	borderBottom: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	borderLeft: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	]),
	borderColor: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
	])
};

Box.defaultProps = {
	as: "div"
};
