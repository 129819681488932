import React, { useEffect, useMemo, useState } from "react";
import {
	MessagingToolEmailsListHeader,
	MessagingToolEmailItem,
	MessagingToolEmailListEmptyState
} from ".";
import styles from "./messaging-tool-email-list.module.scss";
import MessagingToolEmailListLoading from "./components/MessagingToolEmailListLoading/MessagingToolEmailListLoading";
import cx from "classnames";
import { formatDateAndTime } from "./utils/helpers";
import useGetEmailsList, {
	MESSAGING_TOOL_EMAILS_LIST_KEY
} from "./hooks/useGetEmailsList";
import get from "lodash/get";
import { formatData, formatFilters } from "modules/MessagingTool/utils/helpers";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";
import {
	ACTIONS_BY_VIEW,
	ACTION_SUCCESS_MESSAGE,
	EMAILS_LIST_LIMIT,
	EMPTY_TRASH,
	MARK_AS_ARCHIVED_ACTION,
	MARK_AS_READ_ACTION,
	MARK_AS_UNREAD_ACTION,
	PERMANENTLY_DELETE_ACTION,
	SOFT_DELETE_ACTION
} from "./utils/constant";
import useEmailsSelection from "./hooks/useEmailsSelection";
import useEmailAction from "./hooks/useEmailAction";
import { LoaderFull } from "common/Loader";
import MessagingToolEmailArchiveButton from "./components/MessagingToolEmailArchiveButton";
import MessagingToolEmailDeleteButton from "./components/MessagingToolEmailDeleteButton";
import MessagingToolEmailUnreadButton from "./components/MessagingToolEmailUnreadButton";
import toaster from "common/Toaster";
import loadable from "loadable-components";
import { queryCache } from "react-query";
import { renderError, searchParser } from "config/helpers";
import useAdvancedFilter from "./hooks/useAdvancedFilter";
import { Header, Description, Footer } from "common/ConfirmationModal";
import { useGetUser } from "hooks/useGetUser";
import { ReactComponent as AttachmentsIcon } from "static/icons/attachment-02.svg";
const ConfirmationModal = loadable(() => import("common/ConfirmationModal"));

const MessagingToolEmailsList = ({ hash, setUnreadCounter }) => {
	const currentUser = useGetUser();
	const [currentPage, setCurrentPage] = useState(0);
	const [ongoingAction, setOngoingAction] = useState("");
	const { filters, setFilters } = useAdvancedFilter();
	const [confirmation, setConfirmation] = useState(null);
	const { selection, toggleItemSelection, selectMany } = useEmailsSelection();

	const payload = {
		folder: hash.toUpperCase(),
		search: "",
		fields: formatFilters(filters),
		offset: currentPage * EMAILS_LIST_LIMIT
	};
	const { data, isLoading, isFetching } = useGetEmailsList(payload, {
		onSuccess: data => {
			setUnreadCounter?.(data.unread || {});
		}
	});

	const [emailAction, { isLoading: actionIsLoading }] = useEmailAction({
		onSuccess: (_, { action }) => {
			if (action !== MARK_AS_READ_ACTION && action !== MARK_AS_UNREAD_ACTION) {
				queryCache.invalidateQueries([MESSAGING_TOOL_EMAILS_LIST_KEY, payload]);
				toaster.success(ACTION_SUCCESS_MESSAGE[action].single);
			}
			setConfirmation(null);
			setOngoingAction("");
		},
		onError: renderError,
		onMutate: ({ action, ids }) => {
			if (action === MARK_AS_READ_ACTION || action === MARK_AS_UNREAD_ACTION) {
				queryCache.setQueryData(
					[MESSAGING_TOOL_EMAILS_LIST_KEY, payload],
					oldList => {
						const updated = oldList.data.map(email => {
							if (ids.includes(email._id)) {
								email.unread = !email.unread;
							}
							return email;
						});
						return { ...oldList, data: updated };
					}
				);
			}
		}
	});

	const total = get(data, "total", 0);
	const numberOfPages = Math.ceil(total / EMAILS_LIST_LIMIT);
	const isScheduled = hash === MESSAGING_TOOL_LINKS.schedule.slug;
	const isTrash = hash === MESSAGING_TOOL_LINKS.trash.slug;
	const emailsListView = formatData(data?.data, hash, currentUser.email);

	const onAction = ({ id, action }) => {
		setOngoingAction(action);
		emailAction(
			action === EMPTY_TRASH
				? { action }
				: {
						action,
						ids: [id]
				  }
		);
	};

	const currentPageSelection = useMemo(() => {
		return emailsListView.filter(item => selection.includes(item._id));
	}, [selection, emailsListView]);

	const currentPageSelected = useMemo(() => {
		return currentPageSelection.length === emailsListView.length;
	}, [currentPage, selection, emailsListView]);

	useEffect(() => {
		const { from, to, subject, words, has_attachment } = searchParser();
		if (from || to || subject || words || has_attachment) {
			setFilters({
				from: from?.split(",") || [],
				to: to?.split(",") || [],
				subject: subject,
				words,
				has_attachment: Boolean(has_attachment)
			});
		}
	}, [window.location.search]);

	const onSelectAll = () => {
		if (currentPageSelection.length !== emailsListView.length) {
			emailsListView.forEach(item => {
				if (!selection.includes(item._id)) {
					toggleItemSelection(item._id);
				}
			});
		} else {
			currentPageSelection.forEach(item => {
				toggleItemSelection(item._id);
			});
		}
	};

	useEffect(() => {
		if (selection.length) {
			selectMany([]);
		}
	}, [hash]);

	const actionsByView = ACTIONS_BY_VIEW[hash];
	const archiveLabel = actionsByView?.[MARK_AS_ARCHIVED_ACTION];
	const deleteLabel =
		actionsByView?.[SOFT_DELETE_ACTION] ??
		actionsByView?.[PERMANENTLY_DELETE_ACTION];
	const markAsReadLabel = actionsByView?.[MARK_AS_READ_ACTION];
	const markAsUnreadLabel = actionsByView?.[MARK_AS_UNREAD_ACTION];

	const displayActions = item => (
		<>
			{!!archiveLabel && (
				<MessagingToolEmailArchiveButton
					tooltipText={archiveLabel}
					onClick={() =>
						onAction({
							id: item._id,
							action: MARK_AS_ARCHIVED_ACTION
						})
					}
				/>
			)}
			{!!deleteLabel && (
				<MessagingToolEmailDeleteButton
					tooltipText={deleteLabel}
					onClick={() =>
						hash === MESSAGING_TOOL_LINKS.draft.slug
							? setConfirmation({
									title: "Delete Draft",
									description: "Are you sure you want to delete this draft?",
									action: () =>
										onAction({
											id: item._id,
											action: SOFT_DELETE_ACTION
										})
							  })
							: onAction({
									id: item._id,
									action:
										hash === MESSAGING_TOOL_LINKS.trash.slug
											? PERMANENTLY_DELETE_ACTION
											: SOFT_DELETE_ACTION
							  })
					}
				/>
			)}
			{!!markAsReadLabel && !!markAsUnreadLabel && (
				<MessagingToolEmailUnreadButton
					tooltipText={item.unread ? markAsReadLabel : markAsUnreadLabel}
					isUnread={item.unread}
					onClick={() =>
						onAction({
							id: item._id,
							action: item.unread ? MARK_AS_READ_ACTION : MARK_AS_UNREAD_ACTION
						})
					}
				/>
			)}
		</>
	);

	if (isLoading) return <MessagingToolEmailListLoading />;
	if (emailsListView?.length === 0) {
		return (
			<div className={styles.emptyState}>
				<MessagingToolEmailListEmptyState hash={hash} />
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<MessagingToolEmailsListHeader
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				numberOfPages={numberOfPages}
				currentPageSelected={currentPageSelected}
				total={total}
				onSelectAll={onSelectAll}
				emailsListView={emailsListView}
				view={hash}
				isRefreshing={isFetching}
			/>
			<div className={styles.list}>
				{isScheduled && (
					<div className={styles.banner}>
						Messages in Scheduled will be sent at their scheduled time.
					</div>
				)}
				{isTrash && (
					<div className={styles.banner}>
						Messages that have been in Trash more than 30 days will be
						automatically deleted.
						<button
							className={styles.cta}
							onClick={() =>
								setConfirmation({
									title: "Empty Trash",
									description:
										"Are you sure you want to permanently delete all conversations?",
									action: () => onAction({ action: EMPTY_TRASH })
								})
							}
						>
							Empty Trash now
						</button>
					</div>
				)}
				{emailsListView?.map(item => (
					<MessagingToolEmailItem
						key={item._id}
						emailId={item._id}
						email={item}
						selected={selection.includes(item._id)}
						unread={item.unread}
						className={styles.emailItem}
						hash={hash}
					>
						<MessagingToolEmailItem.Checkbox
							checked={selection.includes(item._id)}
							onChange={e => {
								e.stopPropagation();
								toggleItemSelection(item._id);
							}}
						/>
						<MessagingToolEmailItem.UserInfo
							status={item.send_failed ? "failed" : "draft"}
							username={
								item.replies_participants.length > 2
									? item.replies_participants.map(user => user.name).join(",")
									: item.user.email === currentUser.email
									? "Me"
									: item.user.name
							}
							avatar={
								item.replies_participants.length > 2
									? item.replies_participants[0]?.avatar
									: item.user.avatar
							}
							proficiency={item.user.user_function}
							hash={hash}
						/>
						<MessagingToolEmailItem.EmailDetails
							subject={item.email_subject || item.subject || "(no subject)"}
							message={item.body}
							countReplies={item.count_replies}
						/>
						<div className={styles.actions}>
							<div className={cx(styles.ctaList, styles.hoverable)}>
								{displayActions(item)}
							</div>
							{item.attachments.length !== 0 && (
								<AttachmentsIcon className={styles.attachments} />
							)}
							<span
								className={cx(styles.time, {
									[styles.red]: isScheduled
								})}
							>
								{formatDateAndTime(item.date, "DD/MM/YYYY, HH:mm")}
							</span>
						</div>
					</MessagingToolEmailItem>
				))}
			</div>
			{!!confirmation && (
				<ConfirmationModal onClose={() => setConfirmation(null)}>
					<Header title={confirmation.title} />
					<Description>{confirmation.description}</Description>
					<Footer
						onClickLabel={confirmation.title}
						onClose={() => setConfirmation(null)}
						onClick={() => confirmation.action()}
						showDeleteIcon={false}
					/>
				</ConfirmationModal>
			)}
			{actionIsLoading &&
				ongoingAction !== MARK_AS_READ_ACTION &&
				ongoingAction !== MARK_AS_UNREAD_ACTION && <LoaderFull />}
		</div>
	);
};

export default MessagingToolEmailsList;
