import React from "react";
import { SectionBanner } from "./styled";
import { ReactComponent } from "./icons/functions.svg";
function FSLBanner({ onClose, displayFSLBanner, tabIndex, isClient }) {
	const tabs = tabIndex === 1 || tabIndex === 2;

	return (
		<>
			{displayFSLBanner && tabs && !isClient && (
				<SectionBanner>
					<ReactComponent />
					<div className="content-banner">
						When editing this section, please keep in mind that the data below
						is used for matching you with the most suitable vacancies. Input
						only functions and skills that you can and <u>want to use</u> in
						your future professional challenge.
					</div>
					<span className="icon-close2" onClick={onClose} />
				</SectionBanner>
			)}
		</>
	);
}

export default FSLBanner;
