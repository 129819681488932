import React from "react";
import styles from "./request-pipeline-signed-badge.module.scss";
import { ReactComponent as CheckVerifiedIcon } from "static/icons/check-verified-02.svg";

export default function RequestPipelineSignedBadge() {
	return (
		<div className={styles.container}>
			<CheckVerifiedIcon />
			<p className={styles.content}>This is now an actual mission.</p>
		</div>
	);
}
