import React from "react";
import styles from "./form-builder-action-delete.module.scss";
import { ReactComponent as TrashIcon } from "static/icons/trash-3.svg";
import Tooltip from "common/Tippy";

export default function FormBuilderActionDelete({ onClick }) {
	return (
		<Tooltip content="Delete" theme="dark">
			<button onClick={onClick} type="button" className={styles.actionButton}>
				<TrashIcon />
			</button>
		</Tooltip>
	);
}
