import React from "react";
import { WORKTIME, FILES } from "../../../config";
// static files
import worktimeIcon from "static/icons/worktime-icon.png";
import expenseIcon from "static/icons/expenses-icon.png";
import folderIcon from "static/images/folder.jpg";

const EmptyTimesheet = ({ view, content, selectedStatus }) => {
	const icon =
		view === FILES
			? folderIcon
			: view === WORKTIME
			? worktimeIcon
			: expenseIcon;
	return (
		<div className="no-data">
			<img src={icon} alt={`No ${view} yet`} />
			<p>
				{selectedStatus === "pending_my_review" ? (
					content
				) : (
					<>
						You don&#39;t have any <strong>{view}</strong> yet
					</>
				)}
			</p>
		</div>
	);
};

export default EmptyTimesheet;
