import React, { PureComponent } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import FormField from "common/FormField";
import DatePickerField from "common/DatePickerField.new";
import S3Uploader from "common/S3Uploader";
import Icon from "common/styled/icons";
import { ReactComponent as CancelIcon } from "static/icons/cancel-icon.svg";
import {
	DATE_FORMAT,
	TYPE_PDF,
	EUR,
	TYPE_JPEG,
	TYPE_PNG,
	TYPE_PJPEG,
	TIMESHEET_TAGS_STATUS
} from "config";
import toaster from "common/Toaster";
import CurrencyField from "common/NewCurrencyField";
import { Button } from "common/styled/buttons";
import { utcTimeStamp } from "config/helpers";
import StyledSelect from "common/StyledSelect";
import {
	timesheetTagsSelectSelector,
	timesheetTagsProjectNameSelector,
	featureTimesheetTagsSelector
} from "../../timesheetSelector";
import { Flex, Box } from "rebass";

import { FormContainerMultipleEdit } from "./styled";
import { resetCurrentDocumentMultiEditMode } from "../../actions";
import { get } from "lodash";

class EditMultipleExpenses extends PureComponent {
	state = {
		uploading: false
	};

	handleSubmit = formProps => {
		const {
			currentDocument,
			tagOptions,
			featureTimesheetTags,
			isAllElementHadChecked,
			disputedExpense,
			setDisputedExpense,
			cancelEdit,
			dispatch,
			onComputeTimesheet,
			timesheetId,
			list
		} = this.props;
		const payload = {
			...Object.fromEntries(
				Object.entries(formProps).filter(
					([e, v]) => (e === "price" && v !== "" && v >= 0) || !!v
				)
			)
		};

		if (payload.tag) {
			featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
				get(tagOptions, "length", 0) > 0 &&
				(payload.tag = get(payload, "tag.value", ""));
		}
		if (payload.date) {
			const current = window.moment();

			payload.date.set({
				hour: current.get("hour"),
				minute: current.get("minute"),
				second: current.get("second")
			});

			payload.date = utcTimeStamp({
				date: payload.date,
				format: DATE_FORMAT,
				manageTime: false
			});
		}

		if (currentDocument) {
			payload.document = currentDocument;
		}

		if (payload.price >= 0) {
			const mappedDiputedExpenseIds = disputedExpense.map(
				({ expense_id }) => expense_id
			);
			const disputedItem = [...new Set(isAllElementHadChecked)].map(id => {
				const index = mappedDiputedExpenseIds.indexOf(id);
				const listIndex = list.map(({ _id }) => _id).indexOf(id);

				const amount =
					get(payload, "price", -1) >= 0
						? get(payload, "price", null)
						: list[listIndex].price;
				if (index >= 0) {
					return {
						_id: id,
						amount
					};
				}

				return {
					_id: id,
					amount
				};
			});

			onComputeTimesheet({
				data: {
					timesheet_id: timesheetId,
					dispute_items: {
						worktimes: { edited: [], added: [] },
						expenses: {
							edited: disputedItem,
							added: []
						}
					}
				},
				payload
			});
			return;
		}

		const mappedDiputedExpenseIds = disputedExpense.map(
			({ expense_id }) => expense_id
		);
		const disputedItem = [...new Set(isAllElementHadChecked)].map(id => {
			const index = mappedDiputedExpenseIds.indexOf(id);
			const listIndex = list.map(({ _id }) => _id).indexOf(id);
			if (index >= 0) {
				return {
					...disputedExpense[index],
					...payload,
					status: "edited"
				};
			}
			const { date, description, document, price, tag } = list[listIndex];

			return {
				expense_id: id,
				...{
					date,
					description,
					document,
					price,
					tag
				},
				...payload,
				status: "edited"
			};
		});

		const removeDuplicateItem = disputedExpense.filter(
			({ expense_id }) => isAllElementHadChecked.indexOf(expense_id) < 0
		);
		const result = [...disputedItem, ...removeDuplicateItem];

		setDisputedExpense(result);
		cancelEdit();
		dispatch(resetCurrentDocumentMultiEditMode());
	};

	progressComponent = data => {
		if (data) {
			return (
				<div className="file-progress-box">
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${data.percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	};

	uploadDone = data => {
		this.setState({ uploading: false });
		this.props.onUploadDone(data);
	};

	uploadError = error => {
		toaster.danger(error);
	};

	render() {
		const {
			month,
			disabled,
			handleSubmit,
			currentDocument,
			projectName,
			tagOptions,
			featureTimesheetTags,
			tagOverlayText,
			getInitialVisibleMonth,
			dirty,
			cancelEdit,
			dispatch,
			canApproveTags,
			isClient,
			reportingSettings
		} = this.props;

		const filled = currentDocument;
		const hasTag =
			this.props.hasTag &&
			featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
			get(tagOptions, "length", 0) > 0;

		const isApply = [currentDocument, dirty].some(i => i);

		return (
			<React.Fragment>
				<Flex
					sx={{
						gridArea: "cta"
					}}
					justifyContent="flex-end"
					alignItems="center"
				>
					<button
						className="cancel-button"
						onClick={() => {
							cancelEdit();
							dispatch(resetCurrentDocumentMultiEditMode());
						}}
					>
						<CancelIcon style={{ marginRight: "8px" }} /> Cancel
					</button>
					<Button
						style={{
							height: "45px"
						}}
						disabled={!isApply}
						color="success"
						small
						onClick={handleSubmit(this.handleSubmit)}
						key="accept"
					>
						<Icon className="fa icon-check" style={{ marginRight: "8px" }} />{" "}
						Apply
					</Button>
				</Flex>
				<FormContainerMultipleEdit>
					<Flex alignItems="center" width="100%">
						<Box>
							<Field
								classes="inner-icon left-icon"
								disabled={disabled}
								icon="icon-calendar"
								name="date"
								label="Date"
								placeholder="Date"
								component={DatePickerField}
								minDate={window.moment.unix(month.start_date).toDate()}
								maxDate={window.moment.unix(month.end_date).toDate()}
								initialVisibleMonth={getInitialVisibleMonth}
							/>
						</Box>

						<Box
							sx={{
								flex: "1"
							}}
							mx={30}
						>
							<Field
								disabled={disabled}
								type="text"
								placeholder="Description"
								label="Description"
								name="description"
								position="bottom"
								component={FormField}
								autoComplete="off"
							/>
						</Box>

						{hasTag && (
							<Box minWidth="130px">
								<Field
									placeholder={projectName || "TAG"}
									label={projectName || "TAG"}
									name="tag"
									options={
										isClient
											? canApproveTags.length > 0
												? canApproveTags
												: tagOptions
											: tagOptions
									}
									disabled={disabled}
									menuPosition="top"
									toolTipOverlay={tagOverlayText}
									component={StyledSelect}
								/>
							</Box>
						)}

						<Box height="105px" minWidth="45px" mx={30}>
							<Box
								style={{
									padding: "0"
								}}
								className="add-new-box"
							>
								<div className="upload-holder">
									<div
										style={{
											width: "100%",
											height: "24px",
											position: "relative"
										}}
										className="form-goup"
									>
										<label>RECEIPT</label>
										<S3Uploader
											id="document-uploader"
											onStart={() => this.setState({ uploading: true })}
											className={`${filled ? "filled" : ""}`}
											onSuccess={this.uploadDone}
											onError={this.uploadError}
											folder="timesheet"
											chooseButton={false}
											cancelButton={false}
											text={{
												placeholder: "",
												icon: { class: "icon-upload" }
											}}
											multiple={false}
											progressComponent={this.progressComponent}
											autoSubmit={true}
											accept={[TYPE_PDF, TYPE_JPEG, TYPE_PNG, TYPE_PJPEG]}
											disabled={disabled}
										/>
										{filled && <i className="fa fa-check" />}
									</div>
								</div>
							</Box>
						</Box>

						<Flex
							justifyContent="center"
							alignItems="center"
							minHeight="45px"
							mx={30}
						>
							<Field
								disabled={disabled}
								type="text"
								placeholder="Amount"
								label="Amount"
								name="price"
								position="bottom"
								suffix={reportingSettings?.currency?.symbol ?? EUR}
								component={CurrencyField}
								editExpense
							/>
						</Flex>
					</Flex>
				</FormContainerMultipleEdit>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		editItem: state.timesheet.editItem,
		featureTimesheetTags: featureTimesheetTagsSelector(state),
		tagOptions: timesheetTagsSelectSelector(state),
		projectName: timesheetTagsProjectNameSelector(state),
		initialValues: {
			date: state.timesheet.editItem
				? window.moment.unix(state.timesheet.editItem.date).format(DATE_FORMAT)
				: "",
			description: state.timesheet.editItem
				? state.timesheet.editItem.description
				: "",
			price: state.timesheet.editItem ? state.timesheet.editItem.price : "",
			document: state.timesheet.editItem
				? state.timesheet.editItem.document
				: ""
		}
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "multi-edit-expense",
		touchOnBlur: false
	})
)(EditMultipleExpenses);
