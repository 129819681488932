import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import Select from "react-select";
import _get from "lodash/get";
import { browserHistory } from "react-router";

import {
	CreateJobContainer,
	SelectContainer,
	CtaContainer,
	ItemListContainer,
	ActionsContainer
} from "./Styled";
import { client } from "lib/api-client";
import {
	GROUP_LIST,
	LIST_SECTORS,
	LIST_FUNCTIONS,
	LIST_SKILLS,
	CREATE_NEW_JOB,
	EDIT_JOB
} from "config/api-endpoints";
import GlobalTooltip from "common/GlobalTooltip";
import { LEVEL, REQUEST } from "config";
import SkillsModal from "modules/job/components/SkillsModal";
import { ListProvider } from "common/editablelist/Next/listContext";
import AxiosHelper from "config/axios-helper";
import LanguagesModal from "common/newSearch/Modals/LanguagesModal";
import toaster from "common/Toaster";
import { LoaderFull } from "common/Loader";
import StyledSelect from "common/StyledSelect";
import Star from "common/Icons/Star";

const getDepartments = () =>
	client(GROUP_LIST, {
		body: {
			type: "jobs"
		}
	});
const getCategories = () =>
	client(LIST_SECTORS, {
		body: {}
	});
const getFunctions = (key, sector_id) =>
	client(LIST_FUNCTIONS, {
		body: {
			no_limit: true,
			offset: 0,
			sector_id
		}
	});

const createRequest = data =>
	client(CREATE_NEW_JOB, {
		body: {
			...data
		}
	});
const editRequest = data =>
	client(EDIT_JOB, {
		body: {
			...data
		}
	});

const formatOptions = options =>
	options.map(option => ({ ...option, label: option.name, value: option._id }));

const JobProfile = ({
	category,
	department,
	subCategory,
	initialSkills,
	langs,
	initialFunc,
	_id,
	initialSeniority
}) => {
	const [group, setGroup] = useState(department);
	const [sector, setSector] = useState(category);
	const [subSectors, setSubSectors] = useState(_get(category, "children", []));
	const [subSector, setSubSector] = useState("");

	const [seniority, setSeniority] = useState("");
	const [skills, setSkills] = useState(initialSkills);
	const [languages, setLanguages] = useState(langs);

	const [displaySkillsModal, setDisplaySkillsModal] = useState(false);
	const [displayLanguagesModal, setDisplayLanguagesModal] = useState(false);
	const [skillsOptions, setSkillsOptions] = useState([]);
	const [error, setError] = useState(false);
	const groupsData = useQuery("groups", getDepartments);
	const sectorsData = useQuery("sectors", getCategories);
	const functionsData = useQuery(
		["functions", _get(subSector, "value")],
		getFunctions
	);

	const [func, setFunc] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const [mutate] = useMutation(_id ? editRequest : createRequest, {
		onSuccess: () => {
			toaster.success(`Request successfully ${_id ? "edited" : "created"}`);
			browserHistory.push(`/jobs/post/request${_id ? `/${_id}` : ""}`);
			setIsLoading(false);
		},
		onError: e => {
			setIsLoading(false);
			if (e && e.status === 422) {
				let errorMsg = _get(e, "detail");
				if (errorMsg) {
					toaster.danger(
						`${Object.keys(errorMsg)[0]} : ${Object.values(errorMsg)[0]}`
					);
				}
			} else {
				toaster.danger("An error has occurred");
			}
		}
	});

	const submit = () => {
		if (
			!sector ||
			!func ||
			!group ||
			languages.length === 0 ||
			skills.length === 0 ||
			!subSector
		) {
			return setError(true);
		}

		let jobObj = {
			category: _get(sector, "label"),
			function: _get(func, "name"),
			group_id: group,
			job_type: REQUEST,
			languages,
			seniority,
			skills: skills.map(s => ({
				must_have: !!s.must_have,
				name: s.name,
				_id: s._id,
				score: s.score
			})),
			step: 1,
			subcategory: _get(subSector, "name")
		};

		if (_id) {
			jobObj.id = _id;
		}
		setIsLoading(true);
		mutate(jobObj);
	};

	useEffect(() => {
		let sec = _get(sectorsData, "data", []).find(
			s => s._id === _get(category, "_id")
		);

		if (!sector && sec) {
			setSector({ ...sec, label: sec.name, value: sec._id });
			setSubSectors(sec.children);

			setSubSector({
				...subCategory,
				value: _get(subCategory, "_id"),
				label: _get(subCategory, "name")
			});
		}
	}, [sectorsData]);

	useEffect(() => {
		if (!func) {
			let fnc = _get(functionsData, "data", []).find(
				f => f.name === initialFunc
			);
			if (fnc) {
				setFunc({
					...fnc,
					label: fnc.name,
					value: fnc._id
				});
			}
		}
	}, [functionsData]);

	useEffect(() => {
		let sec = _get(sectorsData, "data", []).find(
			s => s._id === _get(category, "_id")
		);
		if (sec) {
			setSector({ ...sec, label: sec.name, value: sec._id });
			setSubSectors(sec.children);
			setSubSector({
				...subCategory,
				value: _get(subCategory, "_id"),
				label: _get(subCategory, "name")
			});
		} else {
			sectorsData.refetch();
		}
		let fnc = _get(functionsData, "data", []).find(f => f.name === initialFunc);
		if (fnc) {
			setFunc({
				...fnc,
				label: fnc.name,
				value: fnc._id
			});
		} else {
			functionsData.refetch();
		}
		let sen = LEVEL.find(l => l.value === initialSeniority);
		setSeniority(_get(sen, "value"));
		setGroup(department);
		setSkills(initialSkills);
		setLanguages(langs);
	}, [_id]);

	const loadSkills = ({ term }) => {
		AxiosHelper.post({
			url: LIST_SKILLS,
			isModal: true,
			data: {
				sector_id: _get(sector, "_id"),
				limit: 150,
				offset: 0,
				term
			},
			isLogin: true
		}).then(resp => setSkillsOptions(resp.data || []));
	};

	return (
		<>
			{isLoading && <LoaderFull />}
			<CreateJobContainer>
				<div className="page">Page 1 of 2 </div>
				<div className="title">Describe the profile</div>
				<div className="description">
					Use the following forms to describe the profile you need, make sure
					you selected the right information for better matching chances.
				</div>
				<div className="job">
					<div className="first-section">
						<SelectContainer>
							<div className="select-label">
								Department{" "}
								<GlobalTooltip
									overlay={
										"By selecting a department, you will allow this request and the following processes to appear only for the users in this department. Learn more about departments and roles in our FAQ."
									}
								/>
							</div>
							<Select
								label=""
								options={formatOptions(_get(groupsData, "data", []))}
								name={"group"}
								value={group}
								clearable={false}
								placeholder={""}
								searchable={true}
								onChange={selected => {
									setGroup(_get(selected, "value"));
								}}
								onBlur={() => {}}
								className={"dpt-select"}
								menuRenderer={props => (
									<>
										{props.options.map(option => {
											return (
												<div
													key={_get(option, "_id", option.value)}
													onClick={() => props.selectValue(option)}
													onMouseOver={() => props.focusOption(option)}
													className={`Select-option ${
														props.focusedOption &&
														props.focusedOption.value === option.value
															? "is-focused"
															: ""
													}`}
												>
													{option.label}
												</div>
											);
										})}
										<CtaContainer>
											<div className="divider"></div>
											<button
												onClick={() => browserHistory.push("/settings/users")}
											>
												Create a new department
											</button>
										</CtaContainer>
									</>
								)}
							/>
						</SelectContainer>
						{error && !group && (
							<span className="help-block inline-error">
								Please fill in this field
							</span>
						)}
						<SelectContainer>
							<div className="select-label">Category </div>
							<StyledSelect
								label=""
								options={formatOptions(_get(sectorsData, "data", []))}
								name={"sector"}
								valueToSet={sector}
								clearable={false}
								placeholder={""}
								onChange={selected => {
									if (_get(selected, "_id") !== _get(sector, "_id")) {
										setSkills([]);
										setSubSector("");
									}

									setSector(selected);
									setSubSectors(_get(selected, "children"));
								}}
								searchable={true}
								onBlur={() => {}}
								rawData={formatOptions(_get(sectorsData, "data", []))}
								grouped={true}
								isClient={true}
								input={{
									value: sector
								}}
							/>
						</SelectContainer>
						{error && !sector && (
							<span className="help-block inline-error">
								Please fill in this field
							</span>
						)}
						<SelectContainer>
							<div className="select-label">Subcategory </div>
							<Select
								label=""
								options={formatOptions(subSectors ? subSectors : [])}
								name={"subsector"}
								value={_get(subSector, "value")}
								clearable={false}
								placeholder={""}
								searchable={true}
								onChange={selected => {
									setSubSector(selected);
								}}
								onBlur={() => {}}
							/>
						</SelectContainer>
						{error && !subSector && (
							<span className="help-block inline-error">
								Please fill in this field
							</span>
						)}
						<div className="functions-container">
							<SelectContainer width={"49%"}>
								<div className="select-label">Functions </div>
								<Select
									label=""
									options={formatOptions(_get(functionsData, "data", []))}
									name={"functions"}
									value={_get(func, "value")}
									clearable={false}
									placeholder={""}
									searchable={true}
									onChange={selected => {
										setFunc(selected);
									}}
									onBlur={() => {}}
								/>
								{error && !func && (
									<span className="help-block inline-error">
										Please fill in this field
									</span>
								)}
							</SelectContainer>

							<SelectContainer width={"49%"}>
								<div className="select-label">Seniority </div>
								<Select
									label=""
									options={LEVEL}
									name={"seniority"}
									value={seniority}
									clearable={false}
									placeholder={""}
									searchable={true}
									onChange={selected => {
										setSeniority(_get(selected, "value"));
									}}
									onBlur={() => {}}
								/>
								{error && !seniority && (
									<span className="help-block inline-error">
										Please fill in this field
									</span>
								)}
							</SelectContainer>
						</div>
					</div>
					<div className="second-section">
						<ItemListContainer>
							<div className="list-title">
								Skills
								<GlobalTooltip
									overlay={
										"The skills are an essential part of the candidate/request matching process and matching score calculation. Be as precise as possible in the selection of the skills needed and the weight/importance they have. A good skills selection will maximise your chances to find a good match faster."
									}
								/>
								<span
									className="icon-edit-2"
									onClick={() => setDisplaySkillsModal(true)}
									disabled={!sector}
								/>
							</div>
							<div className="items">
								{skills.map(s => (
									<div className="item" key={s.name}>
										{s.must_have && <Star className="client_layout" />}
										{`${s.name} ( ${s.score} / 5)`}
									</div>
								))}
								{skills.length === 0 && (
									<div className="item placeholder"> Example ( 1 / 5)</div>
								)}
							</div>
						</ItemListContainer>
						{error && skills.length === 0 && (
							<span className="help-block inline-error">
								Please fill in this field
							</span>
						)}

						<ItemListContainer>
							<div className="list-title">
								Languages
								<GlobalTooltip
									overlay={
										"Languages are another fundamental part of the matching process. Although they are weighted less than skills in the matching score calculation, it is still important to properly outline your linguistic skills."
									}
								/>
								<span
									className="icon-edit-2"
									onClick={() => setDisplayLanguagesModal(true)}
								/>
							</div>
							<div className="items">
								{languages.map(l => (
									<div
										className="item"
										key={l.name}
									>{`${l.name} ( ${l.score} / 5)`}</div>
								))}
								{languages.length === 0 && (
									<div className="item placeholder"> Example ( 2 / 5)</div>
								)}
							</div>
						</ItemListContainer>
						{error && languages.length === 0 && (
							<span className="help-block inline-error">
								Please fill in this field
							</span>
						)}
					</div>
				</div>
				<ActionsContainer>
					<button
						className="back-to-list-btn"
						onClick={() => {
							browserHistory.push(`jobs/list`);
						}}
					>
						Back to list
					</button>
					<button className="create-request" onClick={submit}>
						Requests details
					</button>
				</ActionsContainer>
			</CreateJobContainer>
			{displaySkillsModal && (
				<ListProvider>
					<SkillsModal
						loadSkills={loadSkills}
						skills={skillsOptions}
						initialSkills={skills}
						sector={sector}
						onSave={skills => {
							setSkills(skills);
							return setDisplaySkillsModal(false);
						}}
						contentHeight={84}
						closeModal={() => setDisplaySkillsModal(false)}
						isRequest={true}
						showMustHave={true}
					/>
				</ListProvider>
			)}
			{displayLanguagesModal && (
				<LanguagesModal
					closeModal={() => setDisplayLanguagesModal(false)}
					saveLanguages={languages => setLanguages(languages)}
					selectedLanguages={languages}
				/>
			)}
		</>
	);
};

export default JobProfile;
