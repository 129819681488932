import { useMutation } from "react-query";
import { client } from "lib/api-client";
import { EDIT_INTERVIEW } from "config/api-endpoints";

const inviteFreelancer = body => {
	return client(EDIT_INTERVIEW, {
		body
	});
};

export const useUpdateTempInterview = options => {
	const res = useMutation(inviteFreelancer, options);

	return res;
};
