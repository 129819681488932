import React from "react";
import ProfileItem from "./ProfileItem";
import { ProfilesContainer } from "../../styled";

const ProfileList = ({ jobs, onSkillClick, selectedSkills, vacancyId }) => {
	return (
		<ProfilesContainer>
			{jobs.map((profile, i) => (
				<React.Fragment key={profile.id}>
					<ProfileItem
						selectedSkills={selectedSkills}
						onSkillClick={onSkillClick}
						profile={profile}
						vacancyId={vacancyId}
					/>
					{i % 2 === 0 && <div className="profile-separator" />}
				</React.Fragment>
			))}
		</ProfilesContainer>
	);
};

export default ProfileList;
