//TODO Remove state remainItems, we can use just selectedItems but it needs some modifications
//TODO Dont reduce data onConfirm and delete!
import React, { useEffect, useState, forwardRef } from "react";
import { useQuery } from "react-query";
import { Chip } from "common/Chip";
import { DropDownWithSearch } from "common/DropDownWithSearch";
import produce from "immer";
import styles from "./picker.module.scss";
import { PickerBody } from "./PickerBody";
import useDebouncedValue from "../../hooks/useDebouncedValue";
import Loader from "../../modules/SearchCandidate/components/Loader/Loader";
import cx from "classnames";

const Picker = forwardRef(
	(
		{
			popoverContainer,
			onConfirm,
			onSkillDeleteFromInput,
			placement,
			//Use PrimaryHeaderContent not primaryHeaderContent
			PrimaryHeaderContent,
			//Use SecondaryHeaderContent not secondaryHeaderContent
			SecondaryHeaderContent,
			displayRating = true,
			value = [],
			queryFn,
			queryId,
			useServerFilter,
			limit = -1,
			inlineChips = false,
			addButtonComponent = undefined,
			displaySelectedValues = true,
			inputClassName
		},
		ref
	) => {
		const [selectedItems, setSelectedItems] = useState([]);
		const [searchValue, setSearchValue] = useState("");
		const [displayPaper, setDisplayPaper] = useState(false);
		//TODO I think we need to remove state isDataExtracted, to check!
		const [isDataExtracted, setIsDataExtracted] = useState(false);
		const debouncedSearch = useDebouncedValue(searchValue);

		let fnParams = {};
		let options = {
			refetchOnWindowFocus: false
		};

		if (useServerFilter) {
			fnParams.search = debouncedSearch;
		} else {
			options = {
				retry: 0
			};
		}
		const { data, isLoading } = useQuery([queryId, fnParams], queryFn, {
			...options
		});

		useEffect(() => {
			if (!data || isDataExtracted) return;
			if (Array.isArray(value)) {
				setSelectedItems(value);
				setIsDataExtracted(true);
			}
		}, [data]);

		const handleItemsAddClick = (item, parent) => {
			if (limit === 1) {
				const newCategory = {
					...parent,
					children: [{ ...item, rating: 1 }]
				};
				setSelectedItems([newCategory]);
				return;
			}

			const index = selectedItems.findIndex(
				element => element.id === parent.id
			);
			let selectedItemsUpdated = [];

			if (index === -1) {
				const newCategory = {
					...parent,
					children: [{ ...item, rating: 1 }]
				};

				selectedItemsUpdated = [...selectedItems, newCategory];
			} else {
				selectedItemsUpdated = produce(selectedItems, draft => {
					const index = draft.findIndex(({ id }) => id === parent.id);

					const { children } = draft[index];

					draft[index].children = [...children, { ...item, rating: 1 }];
				});
			}

			setSelectedItems(selectedItemsUpdated);
		};

		const handleDeleteItemClick = (item, parent) => {
			//create function to delete item from structure to reuse it
			let selectedItemsUpdated = produce(selectedItems, draft => {
				const index = draft.findIndex(category => category.id === parent);
				const category = selectedItems[index];

				if (category.children.length === 1) {
					draft.splice(index, 1);
				} else {
					const childIndex = draft[index].children.findIndex(
						({ id }) => id === item.id
					);
					draft[index].children.splice(childIndex, 1);
				}
			});

			setSelectedItems(selectedItemsUpdated);
		};

		const handleRatingClick = (childId, parent, value) => {
			let selectedItemsUpdated = produce(selectedItems, draft => {
				const index = draft.findIndex(category => category.id === parent);

				const childIndex = draft[index].children.findIndex(
					children => children.id === childId
				);

				draft[index].children[childIndex].rating = value;
			});

			setSelectedItems(selectedItemsUpdated);
		};

		const handleSearchChange = e => {
			setSearchValue(e.target.value);
		};

		const handleResetClick = () => {
			setSearchValue("");
			setSelectedItems([]);
		};

		const handleConfirmClick = () => {
			setDisplayPaper(false);
			onConfirm?.(selectedItems);
		};
		// in case of remote search we need to display a loading and jump this code!

		let itemsSelectedFiltered = [];
		let itemsRemainFiltered = [];

		const selectedItemsReduced = selectedItems.reduce(
			(acc, item) => [...acc, ...item.children],
			[]
		);

		const selectedIds = selectedItemsReduced.map(({ id }) => id);

		if (useServerFilter) {
			const remains = [];
			(data || []).forEach(parent => {
				const children = [];
				parent.children.forEach(child => {
					if (!selectedIds.includes(child.id)) {
						children.push(child);
					}
				});

				remains.push({
					...parent,
					children
				});
			});

			itemsRemainFiltered = remains;

			let selected = [];

			if (searchValue) {
				(data || []).forEach(parent => {
					const children = [];
					parent.children.forEach(child => {
						if (selectedIds.includes(child.id)) {
							const item = selectedItemsReduced.find(
								item => item.id === child.id
							);
							children.push(item);
						}
					});

					if (children.length > 0) {
						selected.push({
							...parent,
							children
						});
					}
				});
			} else {
				selected = selectedItems;
			}

			itemsSelectedFiltered = selected;
		} else {
			selectedItems.forEach(category => {
				const children = [];

				category.children.forEach(child => {
					if (
						child.label.toLowerCase().includes(searchValue.toLocaleLowerCase())
					) {
						children.push(child);
					}
				});

				if (children.length > 0) {
					itemsSelectedFiltered.push({
						...category,
						children
					});
				}
			});
			(data || []).forEach(category => {
				const children = [];
				category.children.forEach(child => {
					const { label } = child;
					if (
						!selectedIds.includes(child.id) &&
						label?.toLowerCase()?.includes(searchValue.toLocaleLowerCase())
					) {
						children.push(child);
					}
				});

				if (children.length > 0) {
					itemsRemainFiltered.push({
						...category,
						children
					});
				}
			});
		}

		const handleInputClick = () => {
			setDisplayPaper(displayPaper => !displayPaper);
		};

		const handleItemDeleteFromInputClick = (child, e) => {
			e.stopPropagation();
			//create function to delete item from structure to reuse it
			let selectedItemsUpdated = produce(selectedItems, draft => {
				const index = draft.findIndex(({ children }) => {
					const childIndex = children.findIndex(({ id }) => id === child.id);
					return childIndex !== -1;
				});

				if (index !== -1) {
					const category = selectedItems[index];

					if (category.children.length === 1) {
						draft.splice(index, 1);
					} else {
						const childIndex = draft[index].children.findIndex(
							children => children.id === child.id
						);
						draft[index].children.splice(childIndex, 1);
					}
				}
			});

			onSkillDeleteFromInput?.(selectedItemsUpdated);
			setSelectedItems(selectedItemsUpdated);
		};
		const handleClose = () => {
			setDisplayPaper(false);
		};

		const reduceItems = items => {
			return items.reduce((acc, item) => [...acc, ...item.children], []);
		};

		const values = reduceItems(value || []).map(child => {
			const { rating, label } = child;
			let text;

			if (displayRating) {
				text = `${label} ${rating}/5`;
			} else {
				text = label;
			}

			return (
				<Chip
					key={text}
					onClick={displayPaper}
					onDelete={e => {
						handleItemDeleteFromInputClick(child, e);
					}}
					text={text}
					isDelete
					className={cx({ ["modeInline"]: inlineChips })}
				/>
			);
		});

		const chips = (
			<div
				className={cx(styles.valueWrapper, {
					[styles.modeInline]: inlineChips
				})}
			>
				{values}
			</div>
		);

		return (
			<DropDownWithSearch
				popoverContainer={popoverContainer}
				ref={ref}
				showAddBtn={inlineChips}
				addButtonComponent={addButtonComponent}
				displaySelectedValues={displaySelectedValues}
				placement={placement}
				onClose={handleClose}
				displayPaper={displayPaper}
				displayFooter
				onSearchChange={handleSearchChange}
				onResetClick={handleResetClick}
				searchValue={searchValue}
				onConfirmClick={handleConfirmClick}
				onInputClick={handleInputClick}
				value={values.length > 0 ? chips : null}
				dropDownInputClassName={cx(inputClassName, {
					[styles.dropDownInput]: values.length
				})}
				content={
					isLoading ? (
						<div className={styles.loaderContainer}>
							<Loader className={styles.loader} />
						</div>
					) : (
						<PickerBody
							onItemClick={handleItemsAddClick}
							remainItems={itemsRemainFiltered}
							selectedItems={itemsSelectedFiltered}
							onItemDeleteClick={handleDeleteItemClick}
							onRating={handleRatingClick}
							PrimaryHeaderContent={PrimaryHeaderContent}
							SecondaryHeaderContent={SecondaryHeaderContent}
							displayRating={displayRating}
						/>
					)
				}
			/>
		);
	}
);

Picker.displayName = "Picker";
export default Picker;
