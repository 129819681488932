import React from "react";
import { CustomDropDown } from "common/CustomDropDown";

export const IsEmptyCustomDropDown = props => (
	<CustomDropDown
		options={[
			{ label: "Yes", value: "yes" },
			{ label: "No", value: "no" }
		]}
		{...props}
	/>
);
