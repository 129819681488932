import React from "react";
import styles from "./form-builder-element-card.module.scss";
import { ReactComponent as TrashIcon } from "static/icons/trash-3.svg";
import { useFormContext } from "react-hook-form";
import cx from "classnames";
import get from "lodash/get";
import FieldError from "../FieldError";

export default function FormBuilderElementCard({
	path,
	isDeletable,
	onDeleteCard,
	isPresentational,
	element
}) {
	const {
		register,
		formState: { errors }
	} = useFormContext();

	return (
		<div className={styles.card}>
			<div className={styles.inputContainer}>
				<input
					{...(isPresentational
						? { value: element.title }
						: register(`${path}.title`))}
					className={styles.input}
					placeholder="Enter card title"
				/>
				<FieldError
					error={get(errors, path)?.title}
					className={styles.titleError}
				/>
			</div>
			<button
				onClick={onDeleteCard}
				type="button"
				disabled={!isDeletable}
				className={cx(styles.actionButton, {
					[styles.hidden]: !isDeletable
				})}
			>
				<TrashIcon />
			</button>
		</div>
	);
}
