import * as React from "react";
import SearchModal from "common/CustomizableModel/CustomizableModel";
import HeaderButton from "modules/SearchCandidate/HeaderButton";
import styles from "./content-visibility-modal.module.scss";
import VisibilityOptions from "./components/VisibilityOptionsList";
import get from "lodash/get";
import find from "lodash/find";
import { SHARE } from "./utils";
import { CUSTOM } from "config";
import useGetUsersAndGroups from "./api/useGetUsersAndGroups";

const CustomVisibility = React.lazy(() =>
	import("./components/CustomVisibility")
);

const VisibilityModal = ({
	onClose,
	setShowModal,
	setWasEdited,
	setCustomPrivacy,
	setVisibility,
	visibilityData,
	title = "Who can see this content?"
}) => {
	const [search, setSearch] = React.useState("");
	const [visibility, setSelectedOption] = React.useState(
		visibilityData?.visibility || "public"
	);
	const [shareWith, setShareWith] = React.useState([]);
	const [dontShare, setDontShareWith] = React.useState([]);
	const [people, setPeople] = React.useState([]);
	const { data, isLoading } = useGetUsersAndGroups({
		visible: get(visibilityData, "visible", {}),
		not_visible: get(visibilityData, "not_visible", {}),
		search: search,
		tag: "list_visibility_user"
	});

	const selectCriteriaHandler = (id, section, shareStatus) => {
		const section_arr = find(people, el => get(el, "type") === section, []);
		const obj = find(section_arr?.data, el => get(el, "_id") === id);

		if (shareStatus === SHARE) {
			setShareWith(prev => [...prev, { ...obj, section }]);
		} else {
			setDontShareWith(prev => [...prev, { ...obj, section }]);
		}

		const updatedData = people.map(item => ({
			...item,
			data: item.data.filter(obj => obj._id !== id)
		}));

		setWasEdited(true);
		setPeople(updatedData);
	};

	const formatUsersGroupsData = newData => {
		const visible = visibilityData?.visible;
		const not_visible = visibilityData?.not_visible;
		if (visible) {
			visible.users?.forEach(id => {
				const user = get(data, "users.data", []).find(e => e._id === id);
				const alreadyExist = shareWith.find(e => e._id === id);
				if (user && !alreadyExist)
					setShareWith(prev => [
						...prev,
						{
							...user,
							name: `${user.first_name} ${user.last_name}`,
							section: "Colleagues"
						}
					]);
			});
			visible.groups?.forEach(id => {
				const user = get(data, "groups.data", []).find(e => e._id === id);
				const alreadyExist = shareWith.find(e => e._id === id);
				if (user && !alreadyExist)
					setShareWith(prev => [...prev, { ...user, section: "Departements" }]);
			});
		}
		if (not_visible) {
			not_visible.users?.forEach(id => {
				const user = get(data, "users.data", []).find(e => e._id === id);
				const alreadyExist = dontShare.find(e => e._id === id);
				if (user && !alreadyExist)
					setDontShareWith(prev => [
						...prev,
						{
							...user,
							name: `${user.first_name} ${user.last_name}`,
							section: "Colleagues"
						}
					]);
			});
			not_visible.groups?.forEach(id => {
				const user = get(data, "groups.data", []).find(e => e._id === id);
				const alreadyExist = dontShare.find(e => e._id === id);
				if (user && !alreadyExist)
					setDontShareWith(prev => [
						...prev,
						{ ...user, section: "Departements" }
					]);
			});
		}

		if (visible?.users && not_visible?.users) {
			const idsToRemove = [
				...visible.users,
				...visible.groups,
				...not_visible.users,
				...not_visible.groups
			];

			const filteredData = newData.map(group => ({
				...group,
				data: group.data.filter(item => !idsToRemove.includes(item._id))
			}));

			return filteredData;
		}

		return newData;
	};

	React.useEffect(() => {
		let newData = [];
		if (data && search) {
			newData = [
				{
					type: "Departements",
					data: get(data, "groups.data", []),
					count: get(data, "groups.total", 0)
				},
				{
					type: "Colleagues",
					data: get(data, "users.data", []).map(e => ({
						...e,
						name: `${e.first_name} ${e.last_name}`
					})),
					count: get(data, "users.total", 0)
				}
			];

			const idsToRemove = [
				...get(visibilityData, "visible.users", []),
				...get(visibilityData, "visible.groups", []),
				...get(visibilityData, "not_visible.users", []),
				...get(visibilityData, "not_visible.groups", []),
				...shareWith.map(e => e._id),
				...dontShare.map(e => e._id)
			];
			newData = newData.map(group => ({
				...group,
				data: group.data.filter(item => !idsToRemove.includes(item._id))
			}));

			setPeople(newData);
		}
	}, [data, search]);

	React.useEffect(() => {
		let newData = [];
		if (data && !search) {
			newData = [
				{
					type: "Departements",
					data: get(data, "groups.data", []),
					count: get(data, "groups.total", 0)
				},
				{
					type: "Colleagues",
					data: get(data, "users.data", []).map(e => ({
						...e,
						name: `${e.first_name} ${e.last_name}`
					})),
					count: get(data, "users.total", 0)
				}
			];

			newData = formatUsersGroupsData(newData);
			setPeople(newData);
		}
	}, [data]);

	const onDeleteTag = (id, shareStatus) => {
		if (shareStatus === SHARE)
			setShareWith(prev => prev.filter(e => e._id !== id));
		else setDontShareWith(prev => prev.filter(e => e._id !== id));
	};

	const onDone = () => {
		if (visibility !== CUSTOM) {
			const data = {
				visible: { users: [], groups: [] },
				not_visible: { users: [], groups: [] }
			};
			setCustomPrivacy(data);
		} else {
			const { usersVisible, groupsVisible } = shareWith.reduce(
				(acc, element) => {
					if (element.section === "Colleagues")
						acc.usersVisible.push(element._id);
					else acc.groupsVisible.push(element._id);
					return acc;
				},
				{ usersVisible: [], groupsVisible: [] }
			);
			const { usersNotVisible, groupsNotVisible } = dontShare.reduce(
				(acc, element) => {
					if (element.section === "Colleagues")
						acc.usersNotVisible.push(element._id);
					else acc.groupsNotVisible.push(element._id);
					return acc;
				},
				{ usersNotVisible: [], groupsNotVisible: [] }
			);

			const data = {
				visible: { users: usersVisible, groups: groupsVisible },
				not_visible: { users: usersNotVisible, groups: groupsNotVisible }
			};
			setCustomPrivacy(data);
		}

		setVisibility(visibility);
		setShowModal();
	};

	return (
		<SearchModal
			title={visibility === CUSTOM ? "Custom privacy" : title}
			actionButton={
				<>
					<button
						className={styles["modal-visibility--footer-btn"]}
						onClick={onClose}
					>
						Cancel
					</button>
					<HeaderButton
						text={"Save"}
						onClick={onDone}
						className="buttonSaveNote"
						visibility
						disabled={
							visibility === CUSTOM && !shareWith.length && !dontShare.length
						}
					/>
				</>
			}
			onClose={onClose}
			hasCloseIcon
			displayCloseButton={false}
			classNameHeader={styles["modal-visibility--header"]}
			classNameFooter={styles["modal-visibility--footer"]}
			classNameCloseIcon={styles.closeIcon}
			maxwidth="44.214rem"
			padding="0rem"
			boxShadow="0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)"
			borderRadius="12px"
		>
			<div style={{ padding: "0px 20px" }}>
				<VisibilityOptions
					valueProp={visibility}
					handleSelectOption={e => {
						setSelectedOption(e.currentTarget.id);
					}}
				>
					{visibility === CUSTOM && (
						<React.Suspense fallback={<div>loading...</div>}>
							<CustomVisibility
								shareWith={shareWith}
								dontShareWith={dontShare}
								people={people}
								setSearch={setSearch}
								selectCriteriaHandler={selectCriteriaHandler}
								isLoading={isLoading}
								onDeleteTag={onDeleteTag}
							/>
						</React.Suspense>
					)}
				</VisibilityOptions>
			</div>
		</SearchModal>
	);
};

export default VisibilityModal;
