import React from "react";
import { ReactComponent as Icon } from "static/icons/empty-white-folder.svg";
import styles from "./empty-list.module.scss";

const EmptyList = ({ title, description, AddButton }) => {
	return (
		<div className={styles.container}>
			<Icon />
			<div className={styles.title}>{title}</div>
			<div className={styles.description}>{description}</div>
			<AddButton />
		</div>
	);
};

export default EmptyList;
