import React, { forwardRef, useRef } from "react";
import Portal from "common/Portal";
import Menu from "common/AgTable/components/Menu/Menu";
import useOnClickOutside from "hooks/useOnClickOutside";
import DotHorzIcons from "common/AgTable/icons/DotsHor";
import { usePopper } from "react-popper";
import { Item } from "@react-stately/collections";
import style from "./ag-table-actions-cell.module.scss";
import cx from "classnames";

const AgTableActionsCell = (
	{ actions, onItemClick, data, show, onOpen, onClose, menuClassName },
	menuRef
) => {
	const popperElement = useRef();
	const referenceElement = useRef();

	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "right-start",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [-9, 10]
					}
				},
				{
					name: "flip",
					options: {
						fallbackPlacements: ["top", "right"]
					}
				}
			]
		}
	);

	useOnClickOutside(popperElement, () => {
		onClose();
	});

	return (
		<div className={`${style.selectIconsContainer}`}>
			<button
				className={style.dotsHorizontal}
				ref={referenceElement}
				onClick={onOpen}
			>
				<DotHorzIcons
					width="1.125rem"
					height="1.125rem"
					color="#A3A3A3"
					stroke="1.5"
				/>
			</button>
			<Portal>
				<div
					style={{ ...styles.popper, display: show ? "block" : "none" }}
					{...attributes.popper}
					ref={popperElement}
					className={cx(style.popperContainer, menuClassName)}
				>
					<Menu
						ref={menuRef}
						aria-label="Actions"
						onAction={id => {
							onItemClick(id, data);
						}}
					>
						{actions.map(({ key, icon, name }) => {
							return (
								<Item key={key}>
									{icon}
									<div className={style.headerItem}>{name}</div>
								</Item>
							);
						})}
					</Menu>
				</div>
			</Portal>
		</div>
	);
};

export default forwardRef(AgTableActionsCell);
