//TODO Fix debounce
import React, { Component } from "react";
import _filter from "lodash/filter";
import { ReactComponent as PrevIcon } from "static/icons/chevron-left.svg";
import { Button } from "common/styled/buttons";
import Icon from "common/styled/icons";
import { Tag } from "common/styled/CommonStyled";
import BusinessUnitModal from "./modals/BusinessUnitModal";
import NoListMessage from "./bum/NoListMessage";
import ListRenderer from "./bum/ListRenderer";
import { ADMIN, PROJECT_MANAGER } from "config";
import Pagination from "common/NewPagination";
import styles from "./business-unit.module.scss";
import { SearchInput } from "common/SearchInput";
import _debounce from "lodash/debounce";
import { ConsultantsLoading } from "./ConsultantsLoading";

class BusinessUnit extends Component {
	state = {
		offset: 0,
		search: "",
		debounceValue: ""
	};

	debouncedSubmit = _debounce(
		val => this.setState({ debounceValue: val }),
		500
	);

	toggleModal = params => {
		this.props.editBUModal(params);
	};

	componentDidMount() {
		if (window.location.hash === "#create_business_units") {
			this.toggleModal({ show: true });
		}
	}

	componentDidUpdate(_, prevState) {
		const { search, offset } = this.state;
		const { fetchList } = this.props;

		if (
			prevState.debounceValue !== this.state.debounceValue ||
			prevState.offset !== this.state.offset
		) {
			fetchList({ search, offset });
		}
	}

	tdList = [
		"name",
		item =>
			_filter(item.user, { role_name: ADMIN }).map((user, i) => (
				<Tag key={i}>
					{user.first_name} {user.last_name}
				</Tag>
			)),
		item => _filter(item.user, { role_name: PROJECT_MANAGER }).length
	];

	thList = ["Name", "Business unit manager", "Amount of consultants"];

	actions = [{ icon: "icon-pen", name: "Edit Business Unit" }];

	render() {
		const {
			list,
			activeModal,
			currentBU,
			editBU,
			getUsers,
			size,
			total
		} = this.props;

		const { search } = this.state;

		return (
			<React.Fragment>
				{total === 0 && !search ? (
					<NoListMessage
						message="There is no Business Unit, nor registered consultant at this stage."
						onClick={() => this.toggleModal({ show: true })}
						buttonText="Create business unit"
					/>
				) : this.props.isLoading ? (
					<ConsultantsLoading />
				) : (
					[
						<div key="search" className={styles.searchContainer}>
							<SearchInput
								inputClassName={styles.searchInput}
								placeholder="Find a business unit"
								onChange={e => {
									this.setState({ search: e.target.value, offset: 0 });
									this.debouncedSubmit(e.target.value);
								}}
								value={this.state.search}
							/>
							<Button
								onClick={() => this.toggleModal({ show: true })}
								key="toggle"
								size={14}
								id="create-business-unit"
								paddingTop={12}
								paddingBottom={12}
								float="left"
							>
								<Icon className="fa fa-plus" />
								Create business unit
							</Button>
						</div>,

						<ListRenderer
							key="table"
							list={list}
							thList={this.thList}
							tdList={this.tdList}
							actions={this.actions}
							onClick={item => this.toggleModal({ buid: item._id, show: true })}
						/>
					]
				)}
				<BusinessUnitModal
					activeModal={activeModal}
					toggleModal={this.toggleModal}
					currentBU={currentBU}
					getUsers={getUsers}
					editBU={editBU}
				/>
				{total > 0 && (
					<div style={{ position: "relative" }}>
						<Pagination
							previousLabel={<PrevIcon />}
							nextLabel={<PrevIcon style={{ transform: "rotate(180deg)" }} />}
							total={total}
							size={size}
							handlePageClick={page => {
								this.setState({ offset: page * size });
							}}
							offset={this.state.offset}
							forcePage={Math.ceil(this.state.offset / size)}
							pageRangeDisplayed={total > 1000 ? 6 : undefined}
							marginPagesDisplayed={total > 1000 ? 0 : undefined}
							className={styles.pagination}
						/>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default BusinessUnit;
