import styled, { css } from "styled-components";
import { colors, utilities } from "config/styles";
import { TabList, Tab, TabPanels } from "@reach/tabs";

const ProfileMatching = styled.div`
	float: left;
	margin-left: 45px;
	width: ${props => (props.width ? props.width : "110px")};
	line-height: 14px;
`;

const ProfileMatchingTitle = styled.div`
	text-transform: uppercase;
	font-size: 12.5px;
	color: ${colors.anchorsColor};
	font-weight: bold;
	padding: 26px 0 10px;
	line-height: 14px;
`;

const PropositionBox = styled.div`
	width: 48%;
	display: inline-block;
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};
	margin: ${props => (props.maxwidth ? "0 30px 0 0" : "30px 15px 15px 0")};
	font-weight: 400;
	.title {
		max-width: ${props => (props.maxwidth ? "100%" : "none")};
		color: ${props =>
			props.orange ? colors.primaryColor : colors.anchorsColor};
		text-align: center;
		font-weight: 500;
		font-size: 13px;
		text-transform: uppercase;
		line-height: 14px;
		margin-bottom: 18px;
		div {
			font-weight: normal;
			font-size: 12px;
			margin-top: 3px;
		}
	}
	.date {
		text-align: center;
		font-weight: 300;
		line-height: 20px;
	}
`;

const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	max-width: ${props => (props.maxwidth ? "230px" : "none")};
	padding: 10px 10px;
	border-radius: ${utilities.borderRadius};
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};
	border: 1px dashed
		${props =>
			props.orange ? colors.primaryColor : colors.stepperDividerColor};
	${props =>
		props.right
			? `border-left:none;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;`
			: `border-right: 1px dashed ${props =>
					props.orange ? colors.primaryColor : colors.stepperDividerColor};
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;`};
`;

const FlexBoxWrapper = styled.div`
	width: ${props => (props.width ? props.width : "auto")};
	display: flex;
	height: ${({ height }) => (height !== "auto" ? `${height}px` : height)};
	> div {
		flex-basis: 50%;
	}
`;

const FlexBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	> div {
		flex-direction: column;
		display: flex;
		flex-basis: 50%;
		height: fit-content;
		align-items: flex-start;
		> span {
			text-transform: uppercase;
			font-weight: 500;
		}
	}
`;

const Time = styled.div`
	font-size: 15px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 25px;
	text-align: center;
`;

const BoxTitle = styled.div`
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};
	text-align: center;
`;

const BoxContent = styled.div`
	text-align: center;
	font-weight: normal;
	line-height: 20px;
	font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
`;

const Title = styled.div`
	color: ${colors.anchorsColor};
	font-weight: 500;
	font-size: 12.5px;
	text-transform: uppercase;
	line-height: 14px;
	margin-bottom: 6px;
`;

const Content = styled.div`
	line-height: 1.6;
	color: ${props => (props.orange ? colors.primaryColor : colors.anchorsColor)};

	font-weight: normal;
	word-break: break-word;
	${props => props.location && `text-align: center; font-size:12px`};
`;

const Backdrop = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	opacity: 0.8;
	z-index: -1;
	background-color: ${colors.white};
`;

const CancelledBlock = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;

const CancelledTitle = styled.h5`
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 13px;
	line-height: 24px;
	color: #e40173;
`;

const CancelledHelp = styled.div`
	font-family: "basiercircle";
	font-weight: 600;
	font-size: 13px;
	line-height: 22px;
	color: #98014c;
`;

const IconHolder = styled.div`
	margin-bottom: 10px;
`;

const Icon = styled.i`
	font-size: 40px;
	color: ${colors.paused};
`;

const ContractAccepted = styled.div`
	display: inline-block;
	overflow: hidden;
	margin: 86px 30px 0 0;
	.title {
		color: ${colors.primaryColor};
		text-transform: capitalize;
		font-size: 32px;
		font-weight: 500;
	}
	p {
		color: ${colors.inputColor}!important;
		line-height: 20px;
		font-weight: 400 !important;
	}
`;

const UserSender = styled.div`
	line-height: 14px;
	padding: 31px;
	width: 50%;
`;

const InterviewWrapper = styled.div`
	display: flex;
	padding: 35px 0px;
	${props =>
		props.justify &&
		css`
			justify-content: space-between;

			> div {
				flex-grow: 1;
			}
		`};
`;

const AvatarHolder = styled.div`
	display: inline-block;
	width: 45px;
	vertical-align: middle;
	margin-right: 14px;
	> .avatar {
		border-radius: 0;
	}
`;

const UserSenderName = styled.div`
	display: inline-block;
	vertical-align: middle;
	font-size: 13.5px;
	font-weight: 400;
	text-transform: capitalize;
	color: ${colors.darkGray};
`;

const VideoBlock = styled.div`
	background-color: ${colors.white};
	min-height: 50px;
	color: ${colors.linksColor};
	margin-bottom: 5px;
	display: flex;
	font-weight: 500;
	img {
		width: 20px;
	}
`;

const Placeholder = styled.div`
	color: ${colors.linksColor};
	font-weight: 500;
	position: absolute;
	z-index: 1;
`;

export const ManagerBlock = styled.div`
	border: none !important;
	h4 {
		color: ${colors.anchorsColor};
		font-size: 12.5px;
		text-transform: uppercase;
	}
`;

const ProfileType = styled.div`
	font-size: 13px;
	font-weight: 400;
	line-height: 26px;
	display: flex;
	line-height: 1.3;
`;

const ConsultancyBlock = styled.div`
	width: 165px;
`;

const BoxInfoContainer = styled.div`
	width: 50%;
	margin: 0 auto;
`;
const FreelancerBidInfos = styled.div`
	height: 104px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	float: left;
	margin-left: 47px;
	div {
		min-width: ${props => (props.width ? props.width : "auto")};
		&:first-child {
			padding-right: 15px;
			border-right: 2px solid rgba(185, 189, 192, 0.19);
			margin-right: 15px;
		}
		span[class^="icon"] {
			margin-right: 10px;
			position: relative;
			top: 2px;
		}
		.placeholder {
			width: 130px;
		}
	}
`;

const ViewContactInformations = styled.div`
	width: 36px;
	height: 36px;
	border-radius: 50%;
	float: right;
	position: relative;
	cursor: pointer;
	position: absolute;
	right: 20px;
	bottom: 8px;
	background-color: ${props =>
		props.locked ? colors.primaryColor : colors.greenTextColor};

	&:hover {
		background-color: ${props =>
			props.locked ? colors.primaryHover : colors.greenTextColor};
	}
	span[class^="icon"] {
		font-size: 18px;
		top: 21%;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		color: ${colors.white};
	}
	.test {
		width: 36px;
		height: 36px;
		border-radius: 50%;
		display: block;
	}
`;
const ConsultancyContactInfos = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	float: left;
	flex-wrap: wrap;
	div {
		width: 150px;
		margin-bottom: 10px;
		span[class^="icon"] {
			margin-right: 10px;
			position: relative;
			top: 2px;
		}
		.placeholder {
			width: 80%;
		}
	}
`;

export const StyledTabList = styled(TabList)`
	background: #ffffff !important;
	padding: 0 40px;
`;

export const StyledTabPanels = styled(TabPanels)`
	margin-bottom: ${props => (props.noMargin ? "0px" : "8px")};
`;

export const StyledTab = styled(Tab)`
	padding: 15px !important;
	margin-right: 20px !important;
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #95a1b3 !important;
	font-family: "BasierCircle";

	&[data-selected] {
		border-bottom: solid 2px #ff6849 !important;
		color: #242f43 !important;
	}
	&:focus {
		outline: none !important;
	}
	&:active {
		background: #ffffff !important;
	}
`;

export const HistoryContainer = styled.div`
	.has-been-poked {
		color: ${colors.darkGray};
		background-color: ${colors.white};
		padding: 20px;
		margin-bottom: 20px;
		font-weight: 400;
		border-left: 3px solid ${colors.primaryColor};
		> span {
			color: ${colors.primaryColor};
		}
	}
`;
export const HistoryItem = styled.div`
	background-color: ${colors.white};
	padding: 20px;
	margin-bottom: 3px;
	div {
		width: 100%;
	}
	span:not(:last-child) {
		margin-right: 30%;
	}
`;
export const Table = styled.table`
	width: 100%;
	thead {
		background-color: ${colors.white};
		padding: 20px;
		color: ${colors.timesheetTh};
		font-weight: 500;
		border-bottom: 7px solid ${colors.grayBodyBg};
	}
	tbody {
		font-weight: 400;
		background-color: ${colors.white};
		tr {
			border-bottom: 3px solid ${colors.grayBodyBg};
		}
	}
	td {
		background-color: ${colors.white};
		padding: 20px;
	}
`;

export const EmptyHistory = styled.div`
	background-color: ${colors.white};
	padding: 60px;
	text-align: center;
	margin-bottom: 30px;
	svg {
		width: 80px;
		margin-bottom: 20px;
	}
	p {
		font-weight: 400;
		font-size: 16px;
	}
`;

export const LinkButton = styled.button`
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	color: ${colors.disabledGray};
	font-size: 13px;
	font-weight: 500;
	&:hover {
		color: ${colors.primaryColor};
		text-decoration: underline;
	}
`;
export const RedirectButton = styled.button`
	background-color: transparent;
	border: none;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	&:hover {
		color: ${colors.primaryColor};
		text-decoration: underline;
	}
`;

export {
	UserSenderName,
	AvatarHolder,
	UserSender,
	PropositionBox,
	Box,
	Time,
	Title,
	Content,
	CancelledBlock,
	Backdrop,
	CancelledTitle,
	CancelledHelp,
	IconHolder,
	Icon,
	FlexBox,
	FlexBoxWrapper,
	ContractAccepted,
	ProfileMatching,
	ProfileMatchingTitle,
	InterviewWrapper,
	VideoBlock,
	Placeholder,
	ProfileType,
	BoxTitle,
	BoxContent,
	ConsultancyBlock,
	BoxInfoContainer,
	FreelancerBidInfos,
	ViewContactInformations,
	ConsultancyContactInfos
};
