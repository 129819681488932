import React, { useEffect } from "react";
import cx from "classnames";
import { Overlay, useModalOverlay } from "react-aria";
import styles from "./create-vacancy-modal.module.scss";

const CreateVacancyModal = ({ className, children, onClose }) => {
	let ref = React.useRef();
	let { modalProps, underlayProps } = useModalOverlay(
		{ onClose },
		{ isOpen: true },
		ref
	);

	useEffect(() => {
		document.addEventListener("keydown", function(evt) {
			if (evt.key === "Escape") {
				onClose();
			}
		});
	}, []);

	return (
		<Overlay>
			<div
				style={{
					position: "fixed",
					zIndex: 100,
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					background: "rgba(0, 0, 0, 0.5)",
					display: "flex",
					alignItems: "center",
					justifyContent: "center"
				}}
				{...underlayProps}
			>
				<div
					{...modalProps}
					ref={ref}
					className={cx(styles.dialogContent, className)}
				>
					{children}
				</div>
			</div>
		</Overlay>
	);
};

export default CreateVacancyModal;
