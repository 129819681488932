import React, { useState } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import s from "./profile.module.scss";
import CandidateProfile from "./components/CandidateProfile";
import InternalData from "./components/InternalData";
import EditsHistory from "./components/EditsHistory";
import Banner from "common/BannerBox";
import { ReactComponent as InformationalIcon } from "static/icons/Icon-informational.svg";
import { ReactComponent as WarningIcon } from "static/icons/warning-icon.svg";
import useAddToInternal from "../../api/useAddToInternalData";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { ConfirmationModal, ConfirmationTypes } from "common/modal";
import _get from "lodash/get";
import { viewOverviewKey } from "modules/candidate/Profile/api/useOverview";
import { profileOverviewKey } from "modules/candidate/Profile/api/useViewCandidateInternal";
import { viewCandidateKey } from "modules/candidate/Profile/api/useViewCandidate";
import isEmpty from "lodash/isEmpty";
import { searchParser } from "config/helpers";

const Profile = ({
	id,
	data,
	viewFilesMenuItemClicked,
	mergeClicked,
	overviewData,
	setIndexTab,
	indexTab,
	isLoadingInternal,
	addProfileToPoolButtonClicked,
	isLoadingAddProfile
}) => {
	const [bannerActive, setBannerActive] = useState(true);
	const [openModal, setOpenModal] = useState(false);
	const [ignoreModal, setIgnoreModal] = useState(false);
	const [openBlueBanner, setOpenBlueBanner] = useState(true);
	const updateAlert = _get(data, "can_update_alert");
	const { tab } = searchParser();
	const isProfileCurrentTab = tab === "profile";

	const [mutate, { isLoading }] = useAddToInternal({
		onSuccess: (_, req) => {
			queryCache.invalidateQueries(profileOverviewKey);
			queryCache.invalidateQueries(viewOverviewKey);
			queryCache.invalidateQueries(viewCandidateKey);
			!req?.ignore_update && toaster.success("Internal data updated.");
			closeModal();
		},
		onError: () => {
			toaster.danger("Error while saving changes.");
		}
	});

	const handleCurrentTabsChange = index => {
		setIndexTab(index);
	};

	const handleViewProfileButtonClick = profileIdToDelete => {
		setIndexTab(1);
		mergeClicked(profileIdToDelete);
	};

	function closeModal() {
		setOpenModal(false);
		setIgnoreModal(false);
	}

	const handleBlueBannerCloseButtonClick = () => {
		setOpenBlueBanner(openBlueBanner => !openBlueBanner);
	};
	const updatedSections = _get(data, "update_sections");

	return (
		<>
			<div className={s.container}>
				<div className={s.tabsContainer}>
					<Tabs onChange={handleCurrentTabsChange} index={indexTab}>
						<TabList>
							<Tab>Internal data</Tab>
							<Tab>Candidate profile</Tab>
							<Tab>Edition history</Tab>
						</TabList>
						{bannerActive && updateAlert === true && indexTab === 0 && (
							<Banner
								type="warning"
								icon={<WarningIcon />}
								title={"Edits have been made"}
								onClose={() => setBannerActive(false)}
							>
								<div className={s.contentBanner}>
									The candidate has edited his/her profile. To view those
									editions (and potentially add them to your internal data),{" "}
									<button
										className={s.secondaryButton}
										onClick={() => {
											handleCurrentTabsChange(1);
										}}
									>
										go to the candidate profile
									</button>
								</div>
							</Banner>
						)}
						{bannerActive && !isEmpty(updatedSections) && indexTab === 1 && (
							<Banner
								type="warning"
								icon={<WarningIcon />}
								title={"Edits have been made"}
								onClose={() => setBannerActive(false)}
							>
								<div className={s.contentBanner}>
									The candidate has edited his/her profile. The edited sections
									are highlited (in red) below. Add all editions to your
									internal data;{" "}
									<button
										className={s.primaryButton}
										onClick={() => setOpenModal(true)}
									>
										by clicking here
									</button>{" "}
									or{" "}
									<button
										className={s.secondaryButton}
										onClick={() => setIgnoreModal(true)}
									>
										ignore
									</button>
								</div>
							</Banner>
						)}
						{openBlueBanner && indexTab === 0 && (
							<Banner
								type="informational"
								icon={<InformationalIcon />}
								onClose={handleBlueBannerCloseButtonClick}
							>
								<div className={s.alertInfo}>
									{`This section of the profile is only visible to you and the other members of your company account.
									Use it to add all the info you wish to link to this candidate (additional contact details, alternative CV,
									salary preferences,etc.) and decide which will be displayed in the profile overview ( > Set as primary).
									You can also tag the profile (to help find it in future searches for example) using your very own structure/
									values via the "Profile tags" section (at the bottom of this page).`}
								</div>
							</Banner>
						)}
						<TabPanels>
							<TabPanel>
								{/* we added this condition to remount component */}
								{isProfileCurrentTab && indexTab === 0 && (
									<InternalData
										id={id}
										isLoading={isLoadingInternal}
										viewFilesMenuItemClicked={viewFilesMenuItemClicked}
										viewProfileButtonClicked={handleViewProfileButtonClick}
										addProfileToPoolButtonClicked={
											addProfileToPoolButtonClicked
										}
										isLoadingAddProfile={isLoadingAddProfile}
									/>
								)}
							</TabPanel>
							<TabPanel>
								{/* we added this condition to remount component */}
								{isProfileCurrentTab && indexTab === 1 && (
									<CandidateProfile
										bannerActive={bannerActive}
										updateAlert={updateAlert}
										setBannerActive={setBannerActive}
										setOpenModal={setOpenModal}
										closeModal={closeModal}
										openModal={openModal}
										isLoading={isLoading}
										id={id}
									/>
								)}
							</TabPanel>
							{/* we added this condition to remount component */}
							<TabPanel>
								{isProfileCurrentTab && indexTab === 2 && (
									<EditsHistory id={id} />
								)}
							</TabPanel>
						</TabPanels>
					</Tabs>
				</div>
			</div>

			<ConfirmationModal
				onClose={() => {
					setIgnoreModal(false);
				}}
				active={ignoreModal}
				loading={isLoading}
				modalName="ignore_update"
				title="Are you sure you would like to ignore these changes?"
				type={ConfirmationTypes.warning}
				firstButton={{
					action: () => {
						mutate({
							profile_id: _get(overviewData, "personal_profile._id"),
							ignore_update: true
						});
						setBannerActive(false);
					},
					label: "Ignore these changes",
					type: "warning"
				}}
				secondButton={{
					action: closeModal,
					label: "Cancel"
				}}
			/>
			<ConfirmationModal
				onClose={closeModal}
				active={openModal}
				loading={isLoading}
				modalName="merge_data"
				title="Are you sure you would like to add all to internal data?"
				type={ConfirmationTypes.warning}
				className="confirmation_merge"
				warningText="This action will overwrite what you've saved before"
				firstButton={{
					action: () => {
						mutate({
							profile_id: _get(overviewData, "personal_profile._id")
						});
						setBannerActive(false);
					},
					label: "Add candidate data",
					type: "warning"
				}}
				secondButton={{
					action: closeModal,
					label: "Cancel"
				}}
			/>
		</>
	);
};

export default Profile;
