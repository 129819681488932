import React from "react";
const ContractIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<path
				className="notification-icon"
				d="M298,365.7l0,24.6L283.9,379l-14.1,11.3v-24.6v-9.9c4,2.7,8.9,4.3,14.1,4.3c5.2,0,10.1-1.6,14.1-4.3V365.7z"
			/>
			<path
				className="notification-icon"
				d="M283.9,309.3c14,0,25.4,11.4,25.4,25.4c0,8.8-4.5,16.6-11.3,21.1c-4,2.7-8.9,4.3-14.1,4.3
		c-5.2,0-10.1-1.6-14.1-4.3c-6.8-4.6-11.3-12.3-11.3-21.1C258.5,320.6,269.8,309.3,283.9,309.3z"
			/>
			<path
				className="notification-icon"
				d="M283.9,326.2c4.7,0,8.5,3.8,8.5,8.5c0,4.7-3.8,8.5-8.5,8.5c-4.7,0-8.5-3.8-8.5-8.5
		C275.4,330,279.2,326.2,283.9,326.2z"
			/>
			<polygon
				style={{ fill: "#F5F7FA" }}
				points="343.1,143.6 309.3,143.6 309.3,109.7 	"
			/>
			<path
				className="notification-icon-origin"
				d="M347.3,143.2c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.4c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3
		c0,0,0,0,0,0c0-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.2-0.3
		c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3l-33.9-33.9c-0.1-0.1-0.2-0.2-0.3-0.3c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.2
		c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.2c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.1
		c0,0,0,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0-0.4,0H156.9c-2.3,0-4.2,1.9-4.2,4.2v256
		c0,2.3,1.9,4.2,4.2,4.2h96c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2h-91.7V114H305v29.6c0,2.3,1.9,4.2,4.2,4.2h29.6v213.6h-24
		c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2h28.2c2.3,0,4.2-1.9,4.2-4.2V143.6C347.4,143.5,347.4,143.3,347.3,143.2z
		 M313.5,119.9l19.4,19.4h-19.4V119.9z"
			/>
			<path
				className="notification-icon-origin"
				d="M190.7,234.5h118.5c2.3,0,4.2-1.9,4.2-4.2s-1.9-4.2-4.2-4.2H190.7c-2.3,0-4.2,1.9-4.2,4.2
		S188.4,234.5,190.7,234.5z"
			/>
			<path
				className="notification-icon-origin"
				d="M190.7,257.1h118.5c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2H190.7c-2.3,0-4.2,1.9-4.2,4.2
		C186.5,255.2,188.4,257.1,190.7,257.1z"
			/>
			<path
				className="notification-icon-origin"
				d="M313.5,275.4c0-2.3-1.9-4.2-4.2-4.2H190.7c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2h118.5
		C311.6,279.6,313.5,277.7,313.5,275.4z"
			/>
			<path
				className="notification-icon-origin"
				d="M190.7,293.7c-2.3,0-4.2,1.9-4.2,4.2c0,2.3,1.9,4.2,4.2,4.2h50.8c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2
		H190.7z"
			/>
			<path
				className="notification-icon-origin"
				d="M313.5,334.7c0-16.3-13.3-29.6-29.6-29.6s-29.6,13.3-29.6,29.6c0,9.4,4.4,17.8,11.3,23.2v32.4c0,0,0,0.1,0,0.1
		c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2
		c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
		c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2
		c0,0,0.1,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
		c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0
		c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0c0,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.6-0.1c0,0,0.1,0,0.1,0
		c0.2-0.1,0.4-0.2,0.6-0.3c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.6-0.4c0,0,0,0,0,0l11.5-9.2l11.5,9.2c0,0,0,0,0,0c0.2,0.1,0.4,0.3,0.6,0.4
		c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0.1,0,0.1,0c0.2,0.1,0.4,0.1,0.6,0.1c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1-0.1
		c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1
		c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1
		c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
		c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2
		c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1l0-32.4C309.1,352.5,313.5,344.1,313.5,334.7z M262.7,334.7c0-11.7,9.5-21.2,21.2-21.2
		S305,323,305,334.7s-9.5,21.2-21.2,21.2S262.7,346.3,262.7,334.7z M293.8,381.5l-7.2-5.7l0,0l-0.1,0c0,0-0.1,0-0.1-0.1
		c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1
		c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0
		c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
		c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0-0.1,0-0.1,0.1l-0.1,0
		c0,0,0,0,0,0l-7.2,5.7v-18.9c3.1,1.1,6.4,1.7,9.9,1.7c3.5,0,6.8-0.6,9.9-1.7L293.8,381.5z"
			/>
			<path
				className="notification-icon-origin"
				d="M283.9,347.4c7,0,12.7-5.7,12.7-12.7c0-7-5.7-12.7-12.7-12.7s-12.7,5.7-12.7,12.7
		C271.2,341.7,276.9,347.4,283.9,347.4z M283.9,330.4c2.3,0,4.2,1.9,4.2,4.2c0,2.3-1.9,4.2-4.2,4.2c-2.3,0-4.2-1.9-4.2-4.2
		C279.6,332.3,281.5,330.4,283.9,330.4z"
			/>
			<path
				className="notification-icon-origin"
				d="M190.7,202.9c-2.3,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2h50.8c2.3,0,4.2-1.9,4.2-4.2s-1.9-4.2-4.2-4.2H190.7z"
			/>
		</g>
	</svg>
);
export default ContractIcon;
