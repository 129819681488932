import React, { useState, useEffect } from "react";
import TimeChooser from "../PlanInterview/TimeChooser";
import AddInterviewNote from "../PlanInterview/AddNoteToInterview";
import { PlanInterviewContainer, Title } from "../styles";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { client } from "lib/api-client";
import { useMutation, queryCache } from "react-query";
import {
	DECLINE_INTERVIEW,
	INVITE_USER_TO_INTERVIEW,
	CHECK_PHONE
} from "config/api-endpoints";
import get from "lodash/get";
import toaster from "common/Toaster";
import {
	IN_DEF_START_TIME,
	IN_DEF_END_TIME,
	DONE,
	CANCELLED,
	EXPIRED,
	TIMEZONE
} from "config";
import PhoneNumber from "./PhoneNumber";
import { LoaderFull } from "common/Loader";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";

const inviteUserToInterview = ({ isDeclineInterview, ...data }) =>
	client(isDeclineInterview ? DECLINE_INTERVIEW : INVITE_USER_TO_INTERVIEW, {
		body: {
			...data
		}
	});

const validatePhone = phone =>
	client(CHECK_PHONE, {
		body: {
			phone
		}
	});

const PlanPhoneScreen = ({
	candidateName = "CANDIDATE_NAME",
	companyType,
	isLogEvent,
	setIsLogEvent,
	closeDrawer,
	isCandidateHasMail,
	openAddMailModal,
	applicationId,
	showAddMailModal,
	isApplicantHasAnOffer,
	interviewId,
	phone,
	interviewStatus,
	submitFromOutside,
	setSubmitFromOutside
}) => {
	const [date, setDate] = useState();

	const schema = yup.object().shape({
		time_chooser: yup.object().test("len", "time period can't be empty", () => {
			if (!date) {
				return false;
			}
			return true;
		}),
		phone: yup.string().required("Phone number is a required")
	});
	const {
		handleSubmit,
		control,
		formState: { errors },
		setError
	} = useForm({
		shouldUnregister: true,
		resolver: yupResolver(schema),
		defaultValues: {
			time_chooser: {
				startTime: IN_DEF_START_TIME,
				endTime: IN_DEF_END_TIME
			},
			description: "",
			phone
		}
	});
	const { setIsOpen, setApplicationId } = useProfileView();
	const [mutate, { isLoading }] = useMutation(inviteUserToInterview, {
		onSuccess: () => {
			toaster.success("Interview was successfully sent");
			queryCache.invalidateQueries("getDataPipeline");
			openAddMailModal(false);
			closeDrawer();
			setIsOpen(true);
			setApplicationId(applicationId);
		},
		onError: error => {
			let errorMessage = get(error, "detail");

			if (typeof errorMessage === "object") {
				Object.keys(errorMessage).forEach(name => {
					setError(name, {
						type: "manual",
						message:
							typeof get(errorMessage, name) === "string"
								? get(errorMessage, name)
								: get(errorMessage, name)[0]
					});
					toaster.danger(
						`${name} : ${
							typeof get(errorMessage, name) === "string"
								? get(errorMessage, name)
								: get(errorMessage, name)[0]
						}`
					);
				});
			} else {
				toaster.danger(errorMessage);
			}
		}
	});

	const onSubmit = async data => {
		if (submitFromOutside) {
			setSubmitFromOutside(false);
		}
		const {
			description,
			phone,
			time_chooser: { startTime, endTime }
		} = data;
		let payload = {
			description,
			phone
		};
		try {
			await validatePhone(phone);
			if (!showAddMailModal && !isLogEvent && !isCandidateHasMail)
				return openAddMailModal(true);
			const isDeclineInterview =
				interviewStatus !== CANCELLED &&
				interviewStatus !== DONE &&
				interviewStatus !== EXPIRED &&
				isApplicantHasAnOffer;

			const startDate = window.moment(date.clone()).tz(TIMEZONE);
			const endDate = window.moment(date.clone()).tz(TIMEZONE);
			const [startTimeHour, startTimeMinute] = startTime.split(":");
			const [endTimeHour, endTimeMinute] = endTime.split(":");
			if (isDeclineInterview) {
				payload.new_interview_start_date = startDate
					.set({
						hour: startTimeHour,
						minute: startTimeMinute
					})
					.utc()
					.unix();

				payload.new_interview_end_date = endDate
					.set({
						hour: endTimeHour,
						minute: endTimeMinute
					})
					.utc()
					.unix();
			} else {
				payload.interview_start_date = startDate
					.set({
						hour: startTimeHour,
						minute: startTimeMinute
					})
					.utc()
					.unix();

				payload.interview_end_date = endDate
					.set({
						hour: endTimeHour,
						minute: endTimeMinute
					})
					.utc()
					.unix();
			}

			payload = {
				...payload,
				application_id: applicationId,
				recipient_type: companyType,
				log_event: isLogEvent,
				location_type: "phone_screen",
				isDeclineInterview,
				...(interviewId && {
					interview_id: interviewId
				})
			};
			mutate(payload);
		} catch (error) {
			setError("phone", {
				type: "manual",
				message: get(get(error, "detail"), "phone")
			});
		}
	};
	useEffect(() => {
		if (submitFromOutside) {
			handleSubmit(onSubmit)();
			setSubmitFromOutside(false);
		}
	}, [submitFromOutside]);

	return (
		<PlanInterviewContainer>
			<Title margin="0px 0px 40px 0px">
				Plan a phone screen call with {candidateName}
			</Title>
			<form id="hook-form" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="time_chooser"
					control={control}
					defaultValue={false}
					render={({ field: { onChange } }) => {
						return (
							<TimeChooser
								handleTime={value => onChange(value)}
								label="Interview Date"
								dateClass="col-md-5"
								timeStartClass="col-md-3"
								timeEndClass="col-md-3"
								setDate={setDate}
								date={date}
								error={get(errors, "time_chooser.message")}
							/>
						);
					}}
				/>
				<Controller
					name="phone"
					control={control}
					render={({ field: { onChange, value } }) => (
						<PhoneNumber
							error={get(errors, "phone.message")}
							onChange={onChange}
							value={value}
						/>
					)}
				/>
				<Controller
					name="description"
					control={control}
					render={({ field: { onChange, value } }) => (
						<AddInterviewNote
							placeholder="Type your note here..."
							onChange={onChange}
							value={value}
						/>
					)}
				/>

				<div className="button-wrapper">
					<button
						onClick={() => setIsLogEvent(false)}
						type="submit"
						className="primary-btn"
					>
						Send invitation
					</button>
					<button
						onClick={() => setIsLogEvent(true)}
						type="submit"
						className="outlined-btn"
					>
						Log event
					</button>
				</div>
			</form>
			<LoaderFull loading={isLoading} />
		</PlanInterviewContainer>
	);
};

export default PlanPhoneScreen;
