export const NEW_TEMPLATE_SEGMENT_ID = "new-template";
export const LOCAL_STORAGE_KEY = "evaluation-template";

export const ELEMENT_TYPES = {
	section: "section",
	question: "question",
	score_card: "score_card",
	yes_no: "yes_no"
};

export const ELEMENT_LABELS = {
	section: "Section",
	question: "Question",
	score_card: "Scorecard",
	yes_no: "Yes / No"
};

export const SCORE_CARD_ITEM_SCHEMA = {
	title: "",
	options: ["bad", "acceptable", "excellent"]
};

export const ELEMENT_SCHEMAS = {
	section: {
		title: "",
		items: []
	},
	question: {
		title: "",
		is_required: false
	},
	score_card: {
		items: [],
		is_required: false
	},
	yes_no: {
		title: "",
		is_required: false
	}
};
