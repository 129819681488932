import React, { useCallback } from "react";
import styles from "./evaluations-list-header.module.scss";
import useTemplateSearchKeyword from "modules/EvaluationFormsSettings/hooks/useTemplateSearchKeyword";
import debounce from "lodash/debounce";

export default function EvaluationsListHeader() {
	const { setKeywords } = useTemplateSearchKeyword();

	const onInputChange = useCallback(debounce(setKeywords, 700), []);

	return (
		<div className={styles.container}>
			<div className={styles.title}>Search</div>
			<input
				className={styles.input}
				placeholder="Search for form"
				onChange={({ target }) => onInputChange(target.value)}
			/>
		</div>
	);
}
