/*TODO use components  CreateRequestFieldContainer; CreateRequestLabelContainer; CreateRequestFieldLabel; CreateRequestInfoIcon*/
import React, { useEffect, useState } from "react";
import toaster from "common/Toaster";
import { Item } from "react-stately";
import { Controller, FormProvider } from "react-hook-form";
import styles from "./create-request-step-one.module.scss";
import Card from "../CreateRequestCard/CreateRequestCard";
import { SaveButton } from "common/SaveButton";
import { Button } from "common/Button";
import { CreateRequestInfoIcon } from "../CreateRequestInfoIcon";
import { ReactComponent as AddIcon } from "static/icons/add-icon-green.svg";
import { ProfileStepSkillsField } from "../ProfileStepSkillsField";
import { ProfileStepLanguagesField } from "../ProfileStepLanguagesField";
import { ComboBox } from "common/ComboBox";
import { historyPush } from "config/helpers";
import { useGetSitesList } from "modules/vacancy/create-vacancy/api/useGetSitesList";
import { useGetDepartmentsList } from "modules/vacancy/create-vacancy/api/useGetDepartmentsList";
import { CreateRequestCardContent as CardContent } from "../CreateRequestCardContent";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { ReactComponent as RightArrowIcon } from "static/icons/next-icon-white.svg";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import LeaveHook from "common/LeaveHook";
import { CreateRequestStepper } from "../CreateRequestStepper";
import { PROFILE_STEP } from "../../utils/constants";
import { useFilter } from "react-aria";
import { stringify } from "query-string";
import { ReactComponent as ArrowLeftIcon } from "static/icons/arrow-left-icon.svg";
import { goBack } from "config/helpers";
import {
	formatCategoriesList,
	initializeProfileForm
} from "../../utils/helper";
import { searchParser } from "config/helpers";
import { CreateRequestCategoryField } from "../CreateRequestCategoryField";
import { CreateRequestFunctionField } from "../CreateRequestFunctionField";
import { CreateRequestSeniorityField } from "../CreateRequestSeniorityField";
import { CreateRequestSubcategoryField } from "../CreateRequestSubcategoryField";
import { useGetCategoriesList } from "modules/CreateRequestModule/api/useGetCategoriesList";
import { useCreateVacancyProfileInformations } from "modules/CreateRequestModule/api/useCreateVacancyProfileInformations";
import { useProfileForm } from "modules/CreateRequestModule/hooks/useProfileForm";
import { useEditRequestProfileInformations } from "modules/CreateRequestModule/api/useEditRequestProfileInformations";
import useElements from "hooks/useLayoutElements";

const CreateRequestStepOne = ({
	onSuccess,
	vacancyToEdit,
	onNext: onNextProp,
	hasMultiSites
}) => {
	const { mainElement } = useElements();
	const { data: sitesData } = useGetSitesList();
	const { data: departmentsList } = useGetDepartmentsList();
	const methods = useProfileForm(hasMultiSites);
	const [displayAddDpartmentButton, setDisplayAddDpartmentButton] = useState(
		true
	);

	const { data: categoriesList } = useGetCategoriesList();

	let { contains } = useFilter({
		sensitivity: "base"
	});
	const {
		control,
		watch,
		handleSubmit,
		reset,
		formState: { isDirty, errors },
		setValue
	} = methods;
	const sitesList = sitesData ? sitesData.site_list : [];
	const [
		createVacancyProfileInformations,
		isCreateVacancyLoading
	] = useCreateVacancyProfileInformations(hasMultiSites);
	const [editVacancy, isEditVacancyLoading] = useEditRequestProfileInformations(
		hasMultiSites
	);

	const categoriesListFormatted = categoriesList
		? formatCategoriesList(categoriesList)
		: [];

	useEffect(() => {
		if (vacancyToEdit && categoriesList) {
			initializeProfileForm(reset, vacancyToEdit, categoriesList);
		}
	}, [vacancyToEdit, categoriesList]);

	useEffect(() => {
		const { departmentName, departmentId } = searchParser();

		if (departmentName) {
			setValue("department", { label: departmentName, value: departmentId });
		}
	}, []);

	const onNewDepartmentClick = () => {
		if (vacancyToEdit) {
			const params = {
				openDepartmentModal: true,
				vacancyId: vacancyToEdit._id
			};

			const stringified = stringify(params);

			historyPush(`settings/users?${stringified}`);
		} else {
			historyPush("settings/users?openDepartmentModal=true");
		}
	};

	const onNext = values => {
		if (!isDirty) onNextProp();
		else if (vacancyToEdit) {
			editVacancy(values, vacancyToEdit._id, {
				onSuccess,
				onError: onSubmitError
			});
		} else {
			createVacancyProfileInformations(values, {
				onSuccess,
				onError: onSubmitError
			});
		}
	};

	const getDartmentById = id => {
		const department = departmentsList.find(({ _id }) => _id === id);
		return department;
	};

	let onDepartmentSelectionChange = (key, onChange) => {
		const value = {
			label: getDartmentById(key)?.name ?? "",
			value: key
		};

		if (key) {
			setDisplayAddDpartmentButton(false);
		}

		onChange(value);
	};

	let onDepartmentInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("department")?.value
		};

		const list = departmentsList.map(({ name }) => name);
		let filteredList = list.filter(composer => contains(composer, value));

		if (filteredList.length === 0) {
			setDisplayAddDpartmentButton(true);
		} else {
			setDisplayAddDpartmentButton(false);
		}

		onChange(valueObject);
	};

	const getSiteById = id => {
		return sitesList.find(({ _id }) => _id === id);
	};

	const onSiteSelectionChange = (key, onChange) => {
		const value = {
			label: getSiteById(key)?.name ?? "",
			value: key
		};

		onChange(value);
	};

	const onSiteInputChange = (value, onChange) => {
		const valueObject = {
			label: value,
			value: value === "" ? null : watch("site")?.selectedKey
		};

		onChange(valueObject);
	};

	const onBack = () => goBack();

	const onSubmitError = e => {
		if (e && e.status === 422) {
			toaster.danger(e.detail);
		} else {
			toaster.danger("An error has occurred");
		}
	};

	return (
		<>
			<CreateRequestStepper
				onVacancyClick={handleSubmit(onNext)}
				actifStep={PROFILE_STEP}
			/>
			<div className={styles.cardsList}>
				<LeaveHook
					onOpen={() => setDisplayAddDpartmentButton(false)}
					onStay={() => setDisplayAddDpartmentButton(true)}
					dirty={isDirty}
				>
					<FormProvider {...methods}>
						<Card className={styles.card}>
							<CardContent>
								<CreateRequestFieldContainer>
									<CreateRequestLabelContainer>
										<CreateRequestFieldLabel>
											Department
										</CreateRequestFieldLabel>
										<CreateRequestInfoIcon>
											Please choose the department for which you are hiring. The
											users of the selected department will be able to see and
											interact with candidates for this vacancy (poke
											candidates, add notes on applications, etc..).
										</CreateRequestInfoIcon>
									</CreateRequestLabelContainer>
									<Controller
										name="department"
										control={control}
										render={({ field: { value, onChange } }) => {
											return (
												<ComboBox
													popoverContainer={mainElement}
													displayButton={displayAddDpartmentButton}
													button={
														<Button
															text="Create new department"
															variant="text"
															textClassName={styles.addNewDepartmentButtonText}
															className={styles.addDepartmentButton}
															icon={<AddIcon />}
															onClick={onNewDepartmentClick}
														/>
													}
													selectedKey={value?.value}
													inputValue={value?.label}
													onInputChange={value =>
														onDepartmentInputChange(value, onChange)
													}
													onSelectionChange={key =>
														onDepartmentSelectionChange(key, onChange)
													}
												>
													{(departmentsList || []).map(({ _id, name }) => (
														<Item key={_id}>{name}</Item>
													))}
												</ComboBox>
											);
										}}
									/>

									<CreateRequestFieldError error={errors.department} />
								</CreateRequestFieldContainer>
								{hasMultiSites && (
									<div className={styles.fieldContainer}>
										<CreateRequestFieldLabel>Site</CreateRequestFieldLabel>
										<Controller
											name="site"
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<ComboBox
														popoverContainer={mainElement}
														inputValue={value?.label}
														selectedKey={value?.value}
														onInputChange={value =>
															onSiteInputChange(value, onChange)
														}
														onSelectionChange={key =>
															onSiteSelectionChange(key, onChange)
														}
													>
														{sitesList.map(({ _id, name }) => {
															return <Item key={_id}>{name}</Item>;
														})}
													</ComboBox>
												);
											}}
										/>
										<CreateRequestFieldError error={errors.site} />
									</div>
								)}
								<div className={styles.row}>
									<CreateRequestCategoryField
										list={categoriesListFormatted}
										error={errors.category}
										setValue={setValue}
									/>
									<CreateRequestSubcategoryField
										subCategory={watch("subCategory")}
										error={errors.subCategory}
										setValue={setValue}
										list={
											watch("category")?.children || watch("category")?.subs
										}
									/>
								</div>
								<div className={styles.row}>
									<CreateRequestFunctionField
										subCategory={watch("subCategory")?.value}
										_function={watch("_function")}
										error={errors._function}
										setValue={setValue}
									/>
									<CreateRequestSeniorityField
										error={errors.seniority}
										seniority={watch("seniority")}
									/>
								</div>
								<div className={styles.fieldContainer}>
									<Controller
										name="skills"
										control={control}
										render={({ field: { onChange } }) => {
											return (
												<ProfileStepSkillsField
													category={watch("category")}
													onSave={onChange}
												/>
											);
										}}
									/>
									<CreateRequestFieldError error={errors.skills} />
								</div>
								<div className={styles.fieldContainer}>
									<Controller
										name="languages"
										control={control}
										render={({ field: { onChange } }) => {
											return <ProfileStepLanguagesField onSave={onChange} />;
										}}
									/>
									<CreateRequestFieldError error={errors.languages} />
								</div>
							</CardContent>
						</Card>
						<div className={styles.buttonsContainer}>
							<Button
								text="Back"
								onClick={onBack}
								icon={<ArrowLeftIcon />}
								variant="text"
								className={styles.backButton}
								textClassName={styles.backButtonText}
							/>

							<SaveButton
								rightIcon={<RightArrowIcon />}
								leftIcon={null}
								text="Next"
								onClick={handleSubmit(onNext)}
								className={styles.nextButton}
								isLoading={isCreateVacancyLoading || isEditVacancyLoading}
								isDisabled={isCreateVacancyLoading || isEditVacancyLoading}
							/>
						</div>
					</FormProvider>
				</LeaveHook>
			</div>
		</>
	);
};

export default CreateRequestStepOne;
