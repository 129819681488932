import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Controller } from "react-hook-form";
import { Uploader } from "common/react-hook-form-components/uploader/coumpound_uploader";
import { ReactComponent as UploadFileIcon } from "static/icons/upload-file-icon-grey.svg";
import s from "./upload-box.module.scss";
import _remove from "lodash/remove";
import FileCard from "./FileCard";
import { ActionButton } from "modules/vacancy/components/vacancyView/CandidateAction/components/styles.js";
import toaster from "common/Toaster";

const UploadBox = ({
	control,
	fields,
	remove,
	onSubmit,
	errors,
	cvDefaultFormat,
	uploadOneFile = true,
	isFiletypesInputReadonly,
	getValues,
	setValue
}) => {
	const [acceptedFiles, setAcceptedFiles] = useState([]);
	const onDrop = accepted => {
		const filterAcceptedFiles = accepted.filter(
			item => acceptedFiles.findIndex(elem => elem.path === item.path) === -1
		);
		if (cvDefaultFormat) {
			filterAcceptedFiles[0].document_type = cvDefaultFormat;
		}
		setValue("items", [
			...getValues("items"),
			...JSON.parse(JSON.stringify(filterAcceptedFiles))
		]);
		setAcceptedFiles(old => [...old, ...filterAcceptedFiles]);
	};

	const handleDeleteAcceptedFiles = (file, index) => {
		const newData = _remove(acceptedFiles, n => n.path !== file.path);
		remove(index);
		setAcceptedFiles(newData);
	};

	let primaryTitle = "Profiles file";
	let secondaryTitle =
		"Upload and keep all documents related to this profile in one place for quick and easy access";
	let dragDropText = "Drag & Drop your files here";
	let supportedFileText = "Files supported: Pdf, Docx, Xls, Txt";
	let rejectUploadText = "The Maximum size is 5MB & 5 files maximum";

	if (uploadOneFile) {
		primaryTitle = "Profile file";
		secondaryTitle = "Upload your file";
		supportedFileText = "File supported: Pdf, Docx, Xls, Txt";
		dragDropText = "Drag & Drop your file here";
		rejectUploadText = "The Maximum size is 5MB";
	}

	const handleDone = () => {
		onSubmit();
	};
	return (
		<div className={s.container}>
			<h6>{primaryTitle}</h6>
			<h4>{secondaryTitle}</h4>
			<Uploader>
				{acceptedFiles.map((file, index) => (
					<Controller
						name={`items.${index}.file`}
						control={control}
						key={fields[index].id}
						render={({ field: { onChange } }) => (
							<Uploader.Progress key={fields[index].id} file={file}>
								{({ progress, fileUrl }) => {
									useEffect(() => {
										if (Boolean(fileUrl)) {
											onChange({ name: file.name, path: fileUrl });
										}
									}, [fileUrl]);

									return (
										<FileCard
											onRemoveFile={() =>
												handleDeleteAcceptedFiles(file, index)
											}
											sibbling={`items.${index}`}
											control={control}
											file={file}
											progress={progress}
											errors={errors.items?.[index]}
											isFiletypesInputReadonly={isFiletypesInputReadonly}
											item={fields[index]}
										/>
									);
								}}
							</Uploader.Progress>
						)}
					/>
				))}

				{!(uploadOneFile && acceptedFiles.length === 1) && (
					<Uploader.Input
						onDrop={onDrop}
						multiple={!uploadOneFile}
						maxFiles={5}
						onDropRejected={() => toaster.danger(rejectUploadText)}
					>
						{({ isDragActive }) =>
							Boolean(fields.length) ? (
								<div
									className={cx(s.shortUploaderBox, {
										[s.isDragActive]: isDragActive
									})}
								>
									<UploadFileIcon />
									<span className="text">
										Drag & drop your file here or{" "}
										<span className={s.browse}> Browse</span>
									</span>
								</div>
							) : (
								<div
									className={cx(s.uploadBox, {
										[s.isDragActive]: isDragActive
									})}
								>
									<UploadFileIcon />
									<span className={s.black}>{dragDropText}</span>
									<span className={s.grey}>{supportedFileText}</span>
									<button>Choose file</button>
									<span className={s.grey}>Maximun size: 5MB </span>
								</div>
							)
						}
					</Uploader.Input>
				)}
			</Uploader>
			<ActionButton
				disabled={acceptedFiles.length === 0}
				className={s.doneAction}
				onClick={handleDone}
			>
				Done
			</ActionButton>
		</div>
	);
};

export default UploadBox;
