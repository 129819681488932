import _get from "lodash/get";
import { createSelector } from "reselect";

const getMissions = state => _get(state, "contract");
const getFilter = state => _get(state, "contract.filter");

export const missionsListSelector = createSelector(
	getMissions,
	missions => missions
);

export const filterSelector = createSelector(getFilter, filter => filter);
