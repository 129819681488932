import React from "react";
import styled from "styled-components";
import Icon from "common/styled/icons";
import { Button } from "common/styled/buttons";
import folder from "static/images/folder.jpg";

export const EmailTitle = styled.div`
	font-size: 13.3px;
	font-weight: 500;
	margin-bottom: 20px;
	display: inline-block;
`;
export const EmailContent = styled.div`
	line-height: 2;
	padding: 10px 0px;
`;

const EmptyFolder = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	height: 450px;
	justify-content: space-evenly;
	padding-top: 60px;
	background-color: white;
`;

const Text = styled.div`
	font-size: 24px;
	width: 410px;
	text-align: center;
`;

export const Wrapper = styled.div`
	margin-top: 50px;
`;

export default function NoListMessage({ message, onClick, buttonText }) {
	return (
		<EmptyFolder id="empty-message">
			<img alt="folder" src={folder} width="104px" />
			<Text>{message}</Text>
			<Button onClick={() => onClick()} size={14} id="create-business-unit">
				<Icon className="fa fa-plus" />
				{buttonText}
			</Button>
		</EmptyFolder>
	);
}
