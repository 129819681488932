import React from "react";
import styles from "./add-timesheet-entries-drawer-head.module.scss";
import { IconButton } from "common/IconButton";
import { ReactComponent as CloseIcon } from "static/icons/close-icon.svg";

const AddTimesheetEntriesDrawerHead = ({ onClose }) => {
	return (
		<div className={styles.container}>
			<div className={styles.title}>Add entries</div>
			<div className={styles.imgContainer}>
				<IconButton onClick={onClose} className={styles.closeButton}>
					<CloseIcon className={styles.closeIcon} width={12} height={12} />
				</IconButton>
			</div>
		</div>
	);
};

export default AddTimesheetEntriesDrawerHead;
