import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useMutation, useQuery } from "react-query";
import _get from "lodash/get";
import Select from "react-select";
import { browserHistory } from "react-router";
import _concat from "lodash/concat";
import _isEmpty from "lodash/isEmpty";
import {
	JOB_REQUEST_RICHEDITOR_BLOCKSTYLES,
	JOB_REQUEST_RICHEDITOR_INLINESTYLES,
	JOB_DESCRIPTION_LENGTH,
	COMPANY_ADDRESS,
	SEARCH_ADDRESS,
	REMOTE,
	REMOTE_LABEL,
	DATE_FORMAT,
	EUR_PER_HOUR,
	DATE_SECOND_FORMAT,
	FULL_TIME,
	PART_TIME,
	REQUEST,
	CONTINENTS,
	EUR_PER_DAY
} from "config";
import RichEditor from "common/RichEditor/RichEditor";

import {
	CreateJobContainer,
	InputContainer,
	LocationContainer,
	SelectContainer,
	ActionsContainer,
	TimesheetReporting
} from "./Styled";
import AddressBox from "common/AddressBox/AddressChooser";
import DatePickerField from "common/DatePickerField";
import { getTrueType, utcTimeStamp } from "config/helpers";
import CurrencyField from "common/NewCurrencyField";
import { handleAddressObject } from "config/helpers";
import { selectAuthenticatedUser } from "modules/job/selectors";
import {
	CREATE_NEW_JOB_PRICING,
	EDIT_JOB,
	GET_COUNTRIES
} from "config/api-endpoints";
import { client } from "lib/api-client";
import { LoaderFull } from "common/Loader";
import toaster from "common/Toaster";
import Tooltip from "rc-tooltip";
import FormGroup from "modules/TimesheetTags/components/timesheetReporter/form/formGroup/FormGroup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const createJobPricing = data => {
	client(CREATE_NEW_JOB_PRICING, {
		body: data
	});
};

const editJob = data =>
	client(EDIT_JOB, {
		body: data
	});
const getCountries = () => client(GET_COUNTRIES, { body: { all: true } });

const getAddressOnly = (data = {}) => {
	return {
		street: data.street || data.company_street,
		number: data.number || data.company_number,
		box: data.box || data.company_box || "",
		city: data.city || data.company_city,
		zip: data.zip || data.company_zip,
		country: data.country || data.company_country,
		longitude: data.longitude || data.company_longitude,
		latitude: data.latitude || data.company_latitude,
		iso_country: data.iso_country || data.company_iso_country
	};
};
const options = [
	{ value: "daily", label: "Daily" },
	{ value: "hourly", label: "Hourly" }
];
const DAILY = "daily";
const HOURLY = "hourly";
const JobDetails = ({
	_id,
	jobId,
	jobTitle,
	jobDescription,
	location_type,
	addressObj,
	contract_type,
	full_time,
	hourly_budget,
	start_date,
	end_date,
	authUser,
	jobStep,
	applicantsLocation,
	reporting_settings
}) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [addressType, setAddressType] = useState(COMPANY_ADDRESS);
	const [address, setAddress] = useState(getAddressOnly(authUser));
	const [addressTypo, setAddressTypo] = useState(false);
	const [addressError, setAddressError] = useState(false);
	const [contractType, setContractType] = useState("freelance");
	const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
	const [applicantLocation, setApplicantLocation] = useState({});
	const [timeCommitment, setTimeCommitment] = useState(FULL_TIME);
	const [hourlyRate, setHourlyRate] = useState(0);
	const [dailyRate, setDailyRate] = useState(0);
	const startDateMinDate = window.moment().toDate();
	const [formError, setFormError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
		reset
	} = useForm({
		mode: "onBlur",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false
	});
	const rate = watch("rate");
	const [mutate] = useMutation(jobId ? editJob : createJobPricing, {
		onSuccess: () => {
			setIsLoading(false);

			browserHistory.push(`/job/${_id}`);
		},
		onError: e => {
			setIsLoading(false);
			if (e && e.status === 422) {
				let errorMsg = _get(e, "detail");
				if (errorMsg) {
					toaster.danger(
						`${Object.keys(errorMsg)[0]} : ${Object.values(errorMsg)[0]}`
					);
				}
			} else {
				toaster.danger("An error has occurred");
			}
		}
	});

	const { data: countries = [] } = useQuery(["getCountries"], getCountries, {
		refetchOnWindowFocus: false
	});

	const ApplicantData = _concat(
		{
			value: { type: "any_where", code: "", name: "" },
			label: "Anywhere"
		},
		CONTINENTS,
		countries.map(item => ({
			label: item.country,
			value: {
				type: "country",
				code: item.code,
				name: item.country
			}
		}))
	);

	useEffect(() => {
		if (_id) {
			setTitle(jobTitle);
			setDescription(jobDescription);
			setContractType(contract_type);
			setStartDate(start_date);
			setEndDate(end_date);
			setTimeCommitment(full_time ? FULL_TIME : PART_TIME);
			setApplicantLocation(
				Boolean(applicantsLocation && applicantsLocation[0]?.type)
					? {
							label: applicantsLocation[0]?.name
								? applicantsLocation[0].name
								: "Anywhere",
							value: applicantsLocation[0]
					  }
					: {}
			);
			if (hourly_budget && _get(reporting_settings, "rate_type") === HOURLY) {
				setHourlyRate(hourly_budget);
			}
			if (hourly_budget && _get(reporting_settings, "rate_type") === DAILY) {
				setDailyRate(hourly_budget);
			}
			if (jobStep === 2 && location_type === undefined) {
				location_type = COMPANY_ADDRESS;
			}
			location_type === "company_address"
				? setAddress(authUser)
				: setAddress(addressObj);
			if (location_type) {
				setAddressType(location_type);
			}
		}
	}, [_id]);
	const endDateMinDate = startDate
		? getTrueType(startDate) === "number"
			? window.moment
					.unix(startDate)
					.add(1, "day")
					.toDate()
			: window
					.moment(startDate, DATE_FORMAT)
					.add(1, "day")
					.toDate()
		: startDateMinDate;

	const getAddress = () => {
		return `${authUser.company_street ? authUser.company_street : ""} ${
			authUser.company_number ? authUser.company_number : ""
		} ${authUser.company_street || authUser.company_number ? "," : ""} ${
			authUser.company_zip ? authUser.company_zip : ""
		} ${authUser.company_city ? authUser.company_city : ""} ${
			authUser.company_country ? authUser.company_country : ""
		}`;
	};

	const onChangeAddress = address => {
		if (
			address.country !== _get(address, "country") ||
			address.city !== _get(address, "city")
		) {
			if (
				address.longitude === _get(address, "city") ||
				address.latitude === _get(address, "latitude")
			) {
				address.latitude = 0;
				address.longitude = 0;
			}
		}
		setAddress(address);
	};

	const changeLocationType = value => {
		if (value === SEARCH_ADDRESS) {
			onChangeAddress({
				street: "",
				number: "",
				box: "",
				city: "",
				zip: "",
				country: "",
				longitude: "",
				formatted_address: "",
				latitude: "",
				iso_country: ""
			});
		}
		if (value === COMPANY_ADDRESS || value === REMOTE) {
			onChangeAddress({
				street: authUser.company_street,
				number: authUser.company_number,
				box: authUser.company_box,
				city: authUser.company_city,
				zip: authUser.company_zip,
				country: authUser.company_country,
				longitude: authUser.company_longitude,
				latitude: authUser.company_latitude,
				iso_country: authUser.company_iso_country
			});
		}
	};

	const isValidAddress = () => {
		let valid = true;
		const requiredFields = ["city", "country"];
		requiredFields.forEach(field => {
			if (!address[field]) {
				valid = false;
			}
		});
		return valid;
	};

	const changeStartEstimation = value => {
		const end =
			typeof endDate === "number" ? window.moment.unix(endDate) : endDate;
		if (
			end &&
			window
				.moment(window.moment(value, DATE_FORMAT))
				.isAfter(window.moment(end, DATE_FORMAT), "day")
		) {
			setEndDate("");
		}
		setStartDate(value);
		return value;
	};

	const changeEndEstimation = value => {
		setEndDate(value);
		return value;
	};

	useEffect(() => {
		if (!_isEmpty(errors)) validateForm();
	}, [errors]);
	const validateForm = async () => {
		if (!isValidAddress(address) && addressType === SEARCH_ADDRESS) {
			return setAddressError(true);
		}

		if (
			!title ||
			!description ||
			!startDate ||
			!endDate ||
			(_get(rate, "value") === DAILY && !dailyRate) ||
			(_get(rate, "value") === HOURLY && !hourlyRate)
		) {
			return setFormError(true);
		}
		const finalAddress =
			addressType === SEARCH_ADDRESS && (await handleAddressObject(address));

		if (finalAddress.addressTypo) {
			setAddressTypo(true);
			return setAddressError(true);
		}
	};

	const submit = async fields => {
		if (!isValidAddress(address) && addressType === SEARCH_ADDRESS) {
			return setAddressError(true);
		}
		if (
			!title ||
			!description ||
			!startDate ||
			!endDate ||
			(_get(rate, "value") === DAILY && !dailyRate) ||
			(_get(rate, "value") === HOURLY && !hourlyRate)
		) {
			return setFormError(true);
		}

		const finalAddress =
			addressType === SEARCH_ADDRESS && (await handleAddressObject(address));

		if (finalAddress.addressTypo) {
			setAddressTypo(true);
			return setAddressError(true);
		}
		setAddress(finalAddress);

		const start_date =
			typeof startDate === "number"
				? startDate
				: utcTimeStamp({
						date: startDate,
						format: DATE_SECOND_FORMAT
				  });

		const end_date =
			typeof endDate === "number"
				? endDate
				: utcTimeStamp({
						date: endDate,
						isStart: false,
						format: DATE_SECOND_FORMAT
				  });

		let jobObj = {
			step: 2,
			box: _get(address, "box", ""),
			job_type: REQUEST,
			start_date,
			end_date,
			full_time: timeCommitment === FULL_TIME,
			part_time: timeCommitment === PART_TIME,
			contract_type: contractType,
			description,
			title,
			applicants_location: [applicantLocation.value],
			budget: _get(fields, "rate.value") === DAILY ? dailyRate : hourlyRate,
			location_type: addressType,
			time_type: timeCommitment,
			rate_type: _get(fields, "rate.value"),
			hours:
				_get(fields, "rate.value") === DAILY
					? _get(fields, "hours") &&
					  window.moment(_get(fields, "hours")).hours() * 60 +
							window.moment(_get(fields, "hours")).minutes()
					: undefined,
			allow_overtime:
				_get(fields, "rate.value") === DAILY
					? _get(fields, "submission")
					: undefined
		};

		if (_id) {
			jobObj.id = _id;
		}

		if (addressType !== REMOTE) {
			jobObj = {
				...jobObj,
				...getAddressOnly(address)
			};
		}

		setIsLoading(true);
		mutate(jobObj);
	};

	return (
		<CreateJobContainer>
			{isLoading && <LoaderFull />}
			<div className="page">Page 2 of 2 </div>
			<div className="title">Describe your request</div>
			<div className="description">
				Use the following form to describe your request.
			</div>
			<div className="job">
				<div className="first-section">
					<InputContainer>
						<div className="input-label">Request title</div>
						<input
							type="text"
							value={title}
							onChange={e => setTitle(_get(e, "target.value"))}
							className="request-title"
							name="title"
						/>
					</InputContainer>
					{formError && !title && (
						<span className="help-block inline-error">
							Please fill in this field
						</span>
					)}
					<RichEditor
						tabIndex={0}
						height="100px"
						maxLength={JOB_DESCRIPTION_LENGTH}
						label={"Request description"}
						placeholder="Description of the request, tasks to perform etc..."
						blockStylesTypes={JOB_REQUEST_RICHEDITOR_BLOCKSTYLES}
						inlineStylesTypes={JOB_REQUEST_RICHEDITOR_INLINESTYLES}
						validationError={false}
						input={{
							value: description,
							onChange: desc => setDescription(desc),
							name: "description"
						}}
						meta={{
							touched: false,
							error: false
						}}
					/>
					{formError && !description && (
						<span className="help-block inline-error description-error">
							Please fill in this field
						</span>
					)}
					<LocationContainer>
						<div className="location-title">Location</div>
						<div className="location-options">
							<div
								className={`option ${
									addressType === COMPANY_ADDRESS ? "selected" : ""
								}`}
								onClick={() => {
									setAddressType(COMPANY_ADDRESS);
									changeLocationType(COMPANY_ADDRESS);
								}}
							>
								<span className="icon-experience" />
								Use company address
							</div>
							<div
								className={`option ${
									addressType === SEARCH_ADDRESS ? "selected" : ""
								}`}
								onClick={() => {
									setAddressType(SEARCH_ADDRESS);
									changeLocationType(SEARCH_ADDRESS);
								}}
							>
								<span className="icon-edit-2" />
								Use another address
							</div>
							<div
								className={`option ${addressType === REMOTE ? "selected" : ""}`}
								onClick={() => {
									setAddressType(REMOTE);
									changeLocationType(REMOTE);
								}}
							>
								<span className="icon-remote" />
								{REMOTE_LABEL}
							</div>
						</div>
						{addressType === COMPANY_ADDRESS && (
							<div className="company-address">
								<div className="address-tag">Company Address</div>
								<div>{getAddress()}</div>
							</div>
						)}
						{addressType === SEARCH_ADDRESS && (
							<div className="address-box">
								<AddressBox
									setAddress={onChangeAddress}
									isRequired={true}
									showAutoComplete
									name="location"
									address={address}
									addressTypo={addressTypo}
									error={addressError}
									disabled={false}
								/>
							</div>
						)}
					</LocationContainer>

					<SelectContainer width="50%" margin="10px 0 0 0">
						<div className="select-label">
							Applicants must be based in
							<Tooltip
								placement="top"
								overlay="Applications that don't match this location will be rejected automatically."
							>
								<span className="icon-info"></span>
							</Tooltip>
						</div>

						<Select
							options={ApplicantData}
							name="applicants_location"
							value={applicantLocation}
							clearable={false}
							placeholder={""}
							searchable={true}
							onChange={selected => setApplicantLocation(selected)}
							onBlur={() => {}}
						/>
					</SelectContainer>
				</div>
				<div className="second-section details">
					<div className="contract-type">
						<SelectContainer>
							<div className="select-label">Contract type </div>
							<Select
								options={[
									{
										label: "Freelancer / consultant",
										value: "freelance"
									},
									{
										label: "Temp worker / interim",
										value: "interim"
									}
								]}
								name={"subsector"}
								value={contractType}
								clearable={false}
								placeholder={""}
								searchable={true}
								onChange={selected => {
									setContractType(_get(selected, "value"));
								}}
								onBlur={() => {}}
							/>
						</SelectContainer>
					</div>
					<div className="row">
						<div className="col-sm-6">
							<DatePickerField
								tabIndex={0}
								classes="inner-icon right-icon"
								icon="fa fa-calendar"
								name="start_date"
								label="Expected start date"
								minDate={startDateMinDate}
								onChange={changeStartEstimation}
								input={{
									value: startDate,
									onChange: changeStartEstimation
								}}
							/>
							{formError && !startDate && (
								<span className="help-block inline-error">
									Please fill in this field
								</span>
							)}
						</div>
						<div className="col-sm-6">
							<DatePickerField
								tabIndex={0}
								classes="inner-icon right-icon"
								icon="fa fa-calendar"
								name="end_date"
								onChange={changeEndEstimation}
								minDate={endDateMinDate}
								label="Expected end date"
								input={{
									value: endDate,
									onChange: changeEndEstimation
								}}
							/>
							{formError && !endDate && (
								<span className="help-block inline-error">
									Please fill in this field
								</span>
							)}
						</div>
					</div>
					<LocationContainer>
						<div className="location-title">Commitment</div>
						<div className="location-options time-options">
							<div
								className={`option ${
									timeCommitment === FULL_TIME ? "selected" : ""
								}`}
								onClick={() => setTimeCommitment(FULL_TIME)}
							>
								Full time
							</div>
							<div
								className={`option ${
									timeCommitment === PART_TIME ? "selected" : ""
								}`}
								onClick={() => setTimeCommitment(PART_TIME)}
							>
								Part time
							</div>
						</div>
					</LocationContainer>
					<TimesheetReporting>
						<FormGroup
							options={options}
							errors={errors}
							control={control}
							rate={rate}
							defaultInput={reporting_settings}
							reset={reset}
						/>
					</TimesheetReporting>
					{_get(rate, "value") &&
						(_get(rate, "value") !== DAILY ? (
							<div className="curency-field">
								<CurrencyField
									tabIndex={0}
									name="hourly_budget"
									label="Expected hourly rate"
									suffix={EUR_PER_HOUR}
									tooltipTitle="The rate you think will be acceptable for
									the mission. This value won’t be communicated
									to applicants but will be used by our automatic
									matching tool to inform freelancers about your
									request, as long as the expected rate falls within
									their financial expectations."
									input={{
										value: hourlyRate,
										onChange: rate => setHourlyRate(rate)
									}}
									meta={{
										touched: false,
										error: formError && !hourlyRate
									}}
								/>
								{formError && !hourlyRate && (
									<span className="help-block inline-error toleft">
										Please fill in this field
									</span>
								)}
							</div>
						) : (
							<div className="curency-field">
								<CurrencyField
									tabIndex={0}
									name="daily_budget"
									label="Expected daily rate"
									suffix={EUR_PER_DAY}
									tooltipTitle="The daily rate you think will be acceptable for
									the mission. This value won’t be communicated
									to applicants but will be used by our automatic
									matching tool to inform freelancers about your
									request, as long as the expected rate falls within
									their financial expectations."
									input={{
										value: dailyRate,
										onChange: rate => setDailyRate(rate)
									}}
									meta={{
										touched: false,
										error: formError && !dailyRate
									}}
								/>
								{formError && !dailyRate && (
									<span className="help-block inline-error toleft">
										Please fill in this field
									</span>
								)}
							</div>
						))}
				</div>
			</div>
			<ActionsContainer>
				<button
					className="back-to-list-btn"
					onClick={() => {
						browserHistory.push(`/jobs/post/profile${_id ? `/${_id}` : ""}`);
					}}
				>
					Back to list
				</button>
				<button className="create-request" onClick={handleSubmit(submit)}>
					Submit
				</button>
			</ActionsContainer>
		</CreateJobContainer>
	);
};
const schema = yup.object().shape({
	rate: yup.object().required("Please choose a rate type"),
	hours: yup.date().when("rate.value", {
		is: "daily",
		then: yup
			.date()
			.required("Please type hour")
			.test("oneOfRequired", "Please type hour", function(item) {
				return (
					window.moment(item).hours() !== 0 ||
					window.moment(item).minutes() !== 0
				);
			})
	}),
	submission: yup.boolean()
});
const mapStateToProps = state => ({
	authUser: selectAuthenticatedUser(state)
});

export default connect(mapStateToProps)(JobDetails);
