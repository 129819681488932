import React from "react";
import { useMutation } from "react-query";
import _get from "lodash/get";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { ReactComponent as InterviewIcon } from "static/icons/interview-icon.svg";
import Header from "./DrawerHeader";
import { AddNoteRendrer } from "common/AddNote";
import { TEXTAREA_MAX_LENGTH } from "config";
import { AddNoteContainer, CancelButton, ActionButton } from "./styles";
import { CANCEL_INTERVIEW } from "config/api-endpoints";
import { client } from "lib/api-client";
import useVacancyStore from "modules/vacancy/components/vacancyView/vacancyStore/index.js";
import Loader from "common/Loader";
import { fetchPoke } from "modules/vacancy/actions";
import toaster from "common/Toaster";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";

const cancel_interview = ({
	cancel_reason,
	interview_id,
	poke_id,
	token_invitation_id
}) =>
	client(CANCEL_INTERVIEW, {
		body: {
			cancel_reason,
			interview_id,
			poke_id,
			token_invitation_id
		}
	});

export default function CancelInterview({
	poke,
	onSuccess,
	isFromVacanciesTable
}) {
	const { setDrawerState, drawerState } = useVacancyStore();
	const dispatch = useDispatch();

	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: false
	});
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();
	const [mutate, { isLoading }] = useMutation(cancel_interview, {
		onSuccess: () => {
			setDrawerState({ open: false });
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(_get(drawerState, "application_id"));
			onSuccess();
			toaster.success("The interview has successfully been cancelled.");
			if (_get(poke, "_id"))
				return dispatch(fetchPoke({ id: _get(poke, "_id") }));
		}
	});

	const handleCancel = () => {
		if (isFromCardActions) {
			setDrawerState({ open: false });
		} else {
			setDrawerState({ open: false });
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(_get(drawerState, "application_id"));
		}
	};

	const handleReject = handleSubmit(values => {
		mutate({
			cancel_reason: _get(values, "comment.value", ""),
			interview_id: poke
				? _get(poke, "interview._id")
				: _get(drawerState, "poke.interview._id"),
			poke_id: _get(poke, "_id")
				? _get(poke, "_id")
				: _get(drawerState, "poke._id"),
			token_invitation_id: poke
				? _get(poke, "interview.token_invitation_id")
				: _get(drawerState, "poke.interview.token_invitation_id")
		});
	});

	return (
		<AddNoteContainer isLoading={isLoading}>
			{isLoading && (
				<div className="loader-container">
					<Loader />
				</div>
			)}
			<Header icon={<InterviewIcon />} title="Interview" onClose={handleCancel}>
				<CancelButton
					className="btn-cancel"
					margin="0 8px 0 0"
					onClick={handleCancel}
				>
					Cancel
				</CancelButton>

				<ActionButton className="btn-action" onClick={handleReject}>
					Cancel interview
				</ActionButton>
			</Header>
			<div className="box">
				<h4 className="box-title">Cancel interview</h4>
				<span className="box-sub-title">{`Use the box below to let the candidate know why you have decided to cancel the interview.`}</span>
				<div className="note">
					<AddNoteRendrer>
						<Controller
							control={control}
							name="comment"
							render={({ field: { onChange, value, name } }) => (
								<AddNoteRendrer.MentionBox
									input={{
										value,
										onChange,
										name,
										placeholder:
											"Your feedback that will be sent to the candidate..."
									}}
									meta={{
										touched: Boolean(_get(errors, "comment.value.message", "")),
										error: _get(errors, "comment.value.message")
									}}
									label="Other"
									maxLength={TEXTAREA_MAX_LENGTH}
								/>
							)}
						/>
					</AddNoteRendrer>
				</div>
				<div className="actions">
					<ActionButton margin="0 0 10px 0" onClick={handleReject}>
						Cancel interview
					</ActionButton>
					<CancelButton backgroundColor="white" onClick={handleCancel}>
						Cancel
					</CancelButton>
				</div>
			</div>
		</AddNoteContainer>
	);
}
const schema = yup.object().shape({
	comment: yup.object().shape({
		value: yup.string().required("Please fill the comment content")
	})
});
