import React from "react";
import Modal from "common/modal";
import { ConfirmChannelsStyles, Table, Row } from "./styled";
import { get } from "lodash";
import CurrencyFormatter from "common/CurrencyFormater";
import { EUR } from "config";
import { Scrollbars } from "react-custom-scrollbars";

export default function ConfirmChannels({
	onClose,
	data,
	isNewCampaing,
	memoizedCreateCampaign,
	onSave,
	status,
	isLoading,
	getListChannelsLoader,
	createCampaignLoader
}) {
	return (
		<Modal
			onClose={onClose}
			className="ModalSelectChannels"
			loading={
				status === "loading" ||
				isLoading ||
				getListChannelsLoader ||
				createCampaignLoader
			}
			showButtonOnRight
			firstButton={{
				action: isNewCampaing ? memoizedCreateCampaign : onSave,
				label: "Confirm & Publish",
				type: "primary"
			}}
			style={{ width: "700px" }}
		>
			<ConfirmChannelsStyles>
				<span className="title">Confirm campaign related costs</span>
				<span className="sub_title">
					You are about to publish your vacancy on paid job-boards. This will
					induce the following costs:
				</span>

				<Table>
					<Row header>
						<span>Channel</span>
						<span>Duration</span>
						<span className="price-formatted">Cost</span>
					</Row>
					<Scrollbars
						autoHide
						autoHideTimeout={5000}
						autoHideDuration={200}
						autoHeight
						autoHeightMax={300}
					>
						{data.map((item, index) => (
							<Row key={index}>
								<span>
									{get(item, "item.title")
										? get(item, "item.title")
										: get(item, "item.channel.name")}
								</span>
								<span>{`${get(item, "item.duration.period")} ${get(
									item,
									"item.duration.range"
								)}`}</span>
								<CurrencyFormatter
									euro={get(item, "item.vonq_price[0].amount", 0)}
									symbol={EUR}
								/>
							</Row>
						))}
					</Scrollbars>
				</Table>

				<div className="total">
					<span className="total-label">TOTAL COST</span>
					<span className="value">
						<CurrencyFormatter
							euro={data.reduce(
								(totalCost, amount) =>
									totalCost + get(amount, "item.vonq_price[0].amount", 0),
								0
							)}
							symbol={EUR}
						/>
					</span>
				</div>
			</ConfirmChannelsStyles>
		</Modal>
	);
}
