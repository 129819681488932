import React from "react";
import cx from "classnames";
import Box from "modules/home/components/Box.jsx";
import styles from "modules/home/components/overview/overview.module.scss";
import Loader from "common/Loader";
const OverView = ({
	children,
	headerStart,
	headerEnd,
	totalJobs,
	title,
	isLoading
}) => {
	return (
		<Box headerStart={headerStart} headerEnd={headerEnd}>
			{isLoading ? (
				<div className={styles.loaderContainer}>
					<Loader />
				</div>
			) : (
				<div>
					<div className={styles.flex}>
						<span className={cx(styles.overviewFont, styles.count)}>
							{totalJobs}
						</span>
						<span className={cx(styles.overviewFont, styles.type)}>
							{title}
						</span>
					</div>
					<div className={styles.cardsContainer}>{children}</div>
				</div>
			)}
		</Box>
	);
};

export default OverView;
