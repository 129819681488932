import React from "react";
import style from "./styles.module.scss";
import _get from "lodash/get";
import _slice from "lodash/slice";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import { formatDate } from "common/Functions";
import { DATA_DD_MM_YYYY } from "config";
import { useSelector } from "react-redux";
import useWeather from "modules/home/api/UseWeather";
import Loader from "common/Loader";
import cx from "classnames";
import useApiIp from "modules/home/api/UseApiIp";

import EmptyState from "./emptyState/EmptyState";

const ItemForecast = ({ item }) => {
	return (
		<li className={style.forecastItem}>
			<span className={style.icon}>
				<img src={_get(item, "day.condition.icon")} />
			</span>
			<span>
				<span className={style.date}>
					{formatDate(_get(item, "date_epoch"), true, DATA_DD_MM_YYYY)}
				</span>
				<span className={style.label}>{_get(item, "day.condition.text")}</span>
			</span>
		</li>
	);
};
const CurrentCity = ({ temp, label, icon }) => {
	return (
		<div className={style.currentDay}>
			<span className={style.icon}>
				<img src={icon} />
			</span>
			<span>
				<span className={style.label}>{label}</span>
				<span className={style.temp}>
					{temp}
					<sup>°</sup>
				</span>
			</span>
		</div>
	);
};
const Weather = () => {
	const ipData = useApiIp();
	const { data, isLoading } = useWeather({
		days: 7,
		ip: ipData.data?.data?.ip
	});
	const NOW = formatDate(
		new Date().setHours(0, 0, 0, 0) / 1000,
		true,
		DATA_DD_MM_YYYY
	);
	const firstName = useSelector(state => state.auth.user.first_name);

	if (isLoading)
		return (
			<div className={cx(style.weatherContent, style.loaderContainer)}>
				<Loader />{" "}
			</div>
		);
	if (_isEmpty(data))
		return (
			<div className={cx(style.weatherContent, style.emptyState)}>
				<EmptyState date={NOW} username={firstName} />
			</div>
		);
	return (
		<div className={style.weatherContent}>
			<span className={style.date}>
				{formatDate(
					_get(data, "data.current.last_updated_epoch", ""),
					true,
					DATA_DD_MM_YYYY
				)}
			</span>
			{firstName && <h1 className={style.titleWeather}>Hello {firstName}!</h1>}
			<CurrentCity
				label={_get(data, "data.current.condition.text", "")}
				icon={_get(data, "data.current.condition.icon", "")}
				temp={_get(data, "data.current.temp_c", "")}
			/>
			<ul className={style.forecast}>
				{_map(_slice(_get(data, "data.forecast.forecastday", []), 1, 3), el => (
					<ItemForecast key={el.date} item={el} />
				))}
			</ul>
		</div>
	);
};

export default Weather;
