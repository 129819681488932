import styled from "styled-components";

export const SectionBanner = styled.div`
	border-radius: 2px;
	box-shadow: 0 0 7.4px 0.1px #e4f0fc;
	border: solid 1px #2570b7;
	background-color: #e4f0fc;
	padding: 16px 20px;
	margin-bottom: 0;
	text-align: justify;
	position: relative;
	svg {
		width: 55px;
		height: 46px;
		float: left;
		margin-right: 12px;
	}
	.icon-close2 {
		color: #2570b7;
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 10px;
		cursor: pointer;
	}
	.content-banner {
		color: #144069;
		font-size: 13px;
		font-weight: 400;
		line-height: 1.6;
		text-transform: none;
		margin: 2px;
		width: 742px;
	}
`;
