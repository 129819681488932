import { endOfMonth, getWeeksInMonth } from "@internationalized/date";
import { useCalendarGrid, useLocale } from "react-aria";
import React from "react";
import { CalendarCell } from "../CalendarCell";
import { CalendarGridHeader } from "../CalendarGridHeader";
import styles from "./calendar-grid.module.scss";

export function CalendarGrid({
	state,
	offset = {},
	Icon,
	buttonProps,
	buttonPosition
}) {
	let startDate = state.visibleRange.start.add(offset);
	let endDate = endOfMonth(startDate);
	const { locale } = useLocale();

	let { gridProps, headerProps, weekDays } = useCalendarGrid(
		{
			startDate,
			endDate
		},
		state
	);

	// Get the number of weeks in the month so we can render the proper number of rows.
	let weeksInMonth = getWeeksInMonth(startDate, locale);

	return (
		<div className={styles.root}>
			<CalendarGridHeader
				buttonPosition={buttonPosition}
				buttonProps={buttonProps}
				Icon={Icon}
				state={state}
				offset={offset}
			/>
			<table {...gridProps} cellPadding="0" cellSpacing="0">
				<thead className={styles.tableHeader} {...headerProps}>
					<tr>
						{weekDays.map((day, index) => (
							<th className={styles.th} key={index}>
								{day}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{[...new Array(weeksInMonth).keys()].map(weekIndex => (
						<tr key={weekIndex}>
							{state
								.getDatesInWeek(weekIndex, startDate)
								.map((date, i) =>
									date ? (
										<CalendarCell
											key={i}
											state={state}
											date={date}
											currentMonth={startDate}
										/>
									) : (
										<td key={i} />
									)
								)}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default CalendarGrid;
