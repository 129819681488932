import { useMutation } from "react-query";
import { ADD_TIMESHEET_ENTRIES } from "config/api-endpoints";
import { client } from "lib/api-client";

const saveEntries = data => {
	return client(ADD_TIMESHEET_ENTRIES, { body: data });
};

export const useSaveEntries = (options = {}) => {
	return useMutation(saveEntries, {
		...options
	});
};
