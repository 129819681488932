import { useEffect } from "react";

const useReportingSettings = (
	reportingSetting,
	currenciesList,
	setValue,
	setRateType
) => {
	const updateRateType = currentRateType => {
		setValue("rate_type", currentRateType);
		setValue(
			"timesheet_frequency",
			reportingSetting?.settings?.timesheet_frequency
		);
		setRateType(currentRateType);

		if (currentRateType === "daily") {
			setValue(
				"hours",
				window.moment({
					hours: reportingSetting?.settings?.hours_representation.split(":")[0],
					minutes: reportingSetting?.settings?.hours_representation.split(
						":"
					)[1]
				})
			);
			setValue("allowSubmission", reportingSetting?.settings?.allow_overtime);
		}
	};

	const updateCurrency = currencySetting => {
		if (currencySetting && currenciesList) {
			const currentCurreny = currenciesList?.find(
				({ code }) => code == currencySetting?.code
			);
			setValue("currency", {
				label: `${currentCurreny.symbol} - ${currentCurreny.name}`,
				value: currentCurreny.code
			});
		}
	};

	useEffect(() => {
		const currentRateType = reportingSetting?.settings?.rate_type;
		const currencySetting = reportingSetting?.settings?.currency;

		if (currentRateType) {
			updateRateType(currentRateType);
		}

		if (currencySetting) {
			updateCurrency(currencySetting);
		}
	}, [reportingSetting, currenciesList, setValue, setRateType]);
};

export default useReportingSettings;
