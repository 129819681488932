import _get from "lodash/get";
import get from "lodash/get";
import {
	EMPLOYMENT_TYPE,
	DATE_FORMAT,
	NO_DATA_PLACEHOLDER,
	REJECTED,
	CANCELLED
} from "config";
import { convertNoteDescriptionToText, getFullName } from "config/helpers";

function isDateValid(date, isTimestamp = false, format = DATE_FORMAT) {
	if (isTimestamp) return window.moment.unix(date).isValid();
	return window.moment(date, format, true).isValid();
}

export const formatDate = (date, isTimestamp = true, format = DATE_FORMAT) => {
	const _isDateValid = isDateValid(date, isTimestamp, format);
	if (_isDateValid) {
		if (isTimestamp) return window.moment.unix(date).format(format);
		return window.moment(date).format(format);
	}
	return NO_DATA_PLACEHOLDER;
};

export const formatSalary = value => {
	return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const getEmploymentType = empType => {
	const employment = EMPLOYMENT_TYPE.find(type => type.value === empType);
	return _get(employment, "label");
};

export const formatInteractionsData = data => {
	return data.map(item => {
		const fullName =
			get(item, "user_action.first_name", "") +
			" " +
			get(item, "user_action.last_name", "");
		const status = get(item, "history_type", "");
		const title = get(item, "job.title", "");

		return {
			user: fullName,
			vacancy: title,
			interaction: status,
			date: formatDate(item.created_at)
		};
	});
};

export const formatProcesses = data => {
	return data.map(item => {
		const status =
			item.status === REJECTED || item.status === CANCELLED
				? "Withdrawn/Rejected"
				: item.last_step?.type || "";

		return {
			...item,
			status,
			creation_date: formatDate(item.created_at),
			date_reached: item.last_step
				? formatDate(item.last_step.date_reached)
				: NO_DATA_PLACEHOLDER
		};
	});
};

export const formatEvaluations = data => {
	return data.map(item => {
		const evaluation_note =
			item.evaluation_type === "quick" ? "Quick" : "Template";

		return {
			_id: item._id,
			vacancy_title: item.job.title,
			added_by: getFullName(item.created_by),
			evaluation_date: formatDate(item.created_at),
			evaluation_score: item.evaluation_score,
			evaluation_type: evaluation_note,
			evaluation_note: convertNoteDescriptionToText(item.evaluation_note)
		};
	});
};
