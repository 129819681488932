import { createSelector } from "reselect";
import _get from "lodash/get";
import _find from "lodash/find";

export const getGlobalOptions = (data = [], tag = null) => {
	return data.map(option => ({
		...option,
		value: tag !== null ? option[tag] : option,
		label: option.name
	}));
};

const getSkills = state => _get(state, "job.skills", []);
export const getLoadedCategories = state => _get(state, "job.categories", []);
const getLanguages = state => _get(state, "job.languages");
const getInAction = state => _get(state, "job.inAction");
const getStopJob = state => _get(state, "job.stopJob");
const getJobToPublish = state => _get(state, "job.jobToPublish");

export const selectCategories = state =>
	getGlobalOptions(state.job.categories, "name");

export const selectSubCategories = state =>
	getGlobalOptions(state.job.subcategories, "_id");

export const selectFunctions = state =>
	getGlobalOptions(state.job.functions, "name");

export const selectData = state => state.job.data;

export const getSelectedSkills = state => state.job.data.skills;

export const getSelectedLanguages = state => state.job.data.languages;

export const selectJob = state => state.job;

const getUser = state => _get(state, "auth.user");

const getJobInfo = state => _get(state, "job.data");

const getJobErrors = state => _get(state, "job.errors");

const getBidsList = state => _get(state, "job.bids_list");

const getTotalBids = state => _get(state, "job.total_bids");

const getDataJobList = state => _get(state, "job.list_data");

const getCurrenciesList = state => _get(state, "job.currencies");

const getCampaignsPerformance = state =>
	_get(state, "job.campaigns_performance");

export const notSelectedElements = (
	list,
	selected,
	selectedKey = "_id",
	listKey = "_id"
) => {
	if (list?.length) {
		if (selected?.length) {
			return list.filter(
				item => _find(selected, { [selectedKey]: item[listKey] }) === undefined
			);
		}
		return list;
	}
	return [];
};

/*
	getComputedList
	@param {array} skills
	@param {array} selected
	@return {array} returns a list of the not selected elements
 */
export const getComputedList = (list, selected, key = "name") => {
	return notSelectedElements(list, selected).map(option => ({
		value: option,
		label: option[key],
		_id: option._id
	}));
};

export const selectFormatedSkillsList = createSelector(getSkills, skills =>
	skills.map(skill => ({ ...skill, score: 0 }))
);

export const selectFormatedLanguagesList = createSelector(
	getLanguages,
	getSelectedLanguages,
	(languages, selected) => getComputedList(languages, selected, "language")
);

export const selectAuthenticatedUser = createSelector(getUser, user => user);

export const selectJobInformation = createSelector(getJobInfo, job => job);

export const selectJobErrors = createSelector(getJobErrors, errors => errors);

export const jobDataSelector = createSelector(selectData, job => job);

export const inActionSelector = createSelector(
	getInAction,
	inAction => inAction
);
export const stopJobSelector = createSelector(getStopJob, stopJob => stopJob);
export const jobToPublishSelector = createSelector(
	getJobToPublish,
	jobToPublish => jobToPublish
);

export const bidsListSelector = createSelector(
	getBidsList,
	bids_list => bids_list
);

export const totalBidsSelector = createSelector(
	getTotalBids,
	total_bids => total_bids
);

export const dataListJobSelector = createSelector(
	getDataJobList,
	jobList => jobList
);

export const currenciesListSelector = createSelector(
	getCurrenciesList,
	currencies => currencies
);

export const campaignsPerformanceSelector = createSelector(
	getCampaignsPerformance,
	campaignsPerformance => campaignsPerformance
);
