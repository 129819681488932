import React from "react";
import { DatePicker } from "common/DatePicker";
import { OPERATORS } from "common/FilterDrawer/utils";
import { Picker } from "common/Picker";
import { RangeDatePicker } from "common/RangeDatePicker";
import { CustomDropDown } from "common/CustomDropDown";

const BooleanComponent = props => (
	<CustomDropDown withBooleanOptions {...props} />
);

const PickerWithOneValue = props => <Picker {...props} limit={1} />;
PickerWithOneValue.displayName = "Picker";

export const LIMIT = 8;

export const NOTES_FILTER_ID = "NOTES_FILTER_ID";
export const VACANCY_NOTES_FILTER_ID = "VACANCY_NOTES_FILTER_ID";

export const OPTION_TYPES = {
	date: "date",
	multipleSelect2: "multipleSelect2",
	multipleSelect3: "multipleSelect3"
};

export const SOURCES = {};

export const OPERATORS_BY_TYPE = {
	[OPTION_TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[OPTION_TYPES.multipleSelect2]: [
		OPERATORS.is,
		OPERATORS.isNot,
		OPERATORS.anyOf,
		OPERATORS.isEmpty
	],
	[OPTION_TYPES.multipleSelect3]: [
		OPERATORS.is,
		OPERATORS.isNot,
		OPERATORS.anyOf
	]
};
export const COMPONENT_BY_OPERATOR = {
	[OPTION_TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	},
	[OPTION_TYPES.multipleSelect2]: {
		default: Picker,
		[OPERATORS.isEmpty.value]: BooleanComponent,
		[OPERATORS.is.value]: PickerWithOneValue
	},
	[OPTION_TYPES.multipleSelect3]: {
		default: Picker,
		[OPERATORS.is.value]: PickerWithOneValue
	}
};
