import React from "react";
import _get from "lodash/get";
import cx from "classnames";
import styles from "./bid-details-processes-card.module.scss";
import { ReactComponent as CoinsIcon } from "static/icons/coins.svg";
import { ReactComponent as CoinsStackedIcon } from "static/icons/coins-stacked.svg";
import { ReactComponent as AlarmClockIcon } from "static/icons/alarm-clock.svg";
import { ReactComponent as MessageTextSquareIcon } from "static/icons/message-text-square.svg";
import {
	ACCEPTED,
	APPLICATION_CANCELLED_LABEL,
	APPLICATION_REJECTED_LABEL,
	CLIENT,
	DAILY,
	HOURLY,
	SIGNED,
	CURRENCY_SUFFIX
} from "config";
import { BidDetailsProcessesCard } from ".";
import { formatDate } from "common/Functions";
import { parseMentions, shouldDisplayFreelancerCost } from "config/helpers";
import Description from "common/Description";
import BidDetailsRejectedMessage from "./BidDetailsRejectedMessage";
import BidDetailsCongratulationMassage from "./BidDetailsCongratulationMassage";
import CurrencyFormatterRender from "common/CurrencyFormatterRender";
import { useIsDisplayCostEnabled } from "hooks/useIsDisplayCostEnabled";

export default function BidDetailsProcessesContractCard({
	data,
	isFetching,
	user,
	isOldProposition,
	displayStatus,
	statusTooltip
}) {
	const rateType = _get(data, "reporting_settings.rate_type");
	const rateTitle = rateType === DAILY ? "Daily rate" : "Hourly rate";
	const rateValue = _get(
		data,
		isOldProposition
			? "contract.old_proposition.rate"
			: "contract.new_proposition.rate",
		0
	);
	const costTitle = rateType === DAILY ? "Daily Cost" : "Hourly Cost";
	const costValue = _get(
		data,
		isOldProposition
			? "contract.old_proposition.cost"
			: "contract.new_proposition.cost",
		0
	);
	const suffix =
		CURRENCY_SUFFIX[_get(data, "reporting_settings.rate_type", HOURLY)];
	const currencyCode = _get(data, "reporting_settings.currency.code");
	const displayCostEnabled =
		useIsDisplayCostEnabled() && shouldDisplayFreelancerCost(data);
	const startEndDate =
		formatDate(
			_get(
				data,
				isOldProposition
					? "contract.old_proposition.start_date"
					: "contract.new_proposition.start_date"
			)
		) +
		" - " +
		formatDate(
			_get(
				data,
				isOldProposition
					? "contract.old_proposition.end_date"
					: "contract.new_proposition.end_date"
			)
		);
	const description =
		_get(data, "contract.description_other_terms") ||
		_get(data, "contract.description");
	const clientName = `${_get(data, "contract.client.first_name")} ${_get(
		data,
		"contract.client.last_name"
	)}`;
	const candidateFullName = `${data.profile_detail.first_name} ${data.profile_detail.last_name}`;
	const isLastUserSenderClient =
		_get(data, "contract.last_user_contract_action.type") === CLIENT;
	const labelTwo =
		_get(data, "contract.client._id") === user.id ? "Your" : `${clientName}'s`;

	const defaultTitle = !isOldProposition
		? // when contracts are signed, we look for the other user, because the user sender is now the one who accepted not the one who sent the proposition
		  isLastUserSenderClient
			? `${labelTwo} proposition`
			: `${candidateFullName}'s Proposition`
		: "Previous proposition";

	const isContractAccepted = _get(data, "contract.status") === ACCEPTED;
	const isContractSigned = _get(data, "contract.signed_status") === SIGNED;
	const isContractAcceptedNotSigned = isContractAccepted && !isContractSigned;
	const isBidRejected = [
		APPLICATION_CANCELLED_LABEL,
		APPLICATION_REJECTED_LABEL
	].includes(_get(data, "visible_status"));

	return (
		<BidDetailsProcessesCard
			title={defaultTitle}
			status={displayStatus && _get(data, "visible_status")}
			statusTooltip={statusTooltip}
			isFetching={isFetching}
		>
			<div
				className={cx(
					styles.content,
					isFetching ? styles.content_loading : undefined
				)}
			>
				{!isOldProposition && (
					<>
						{isBidRejected && (
							<BidDetailsRejectedMessage
								title="Contract cancelled"
								text={`Your contract with ${candidateFullName} has been ${
									isLastUserSenderClient ? "rejected" : "cancelled"
								}.`}
							/>
						)}
						{isContractAcceptedNotSigned && (
							<BidDetailsCongratulationMassage
								primaryText="Congratulations, All contracts are signed."
								secondText={"You reached an agreement on the terms"}
							/>
						)}
					</>
				)}

				{(isBidRejected || isContractAcceptedNotSigned) && (
					<div className={styles.insideDivider}></div>
				)}
				<div className={styles.item}>
					<div className={styles.labelHead}>
						<CoinsIcon size={20} />
						<div className={styles.label}>{rateTitle}</div>
					</div>
					<div className={styles.value}>
						<CurrencyFormatterRender
							currency={currencyCode}
							isCent
							value={rateValue}
							suffix={suffix}
						/>
					</div>
				</div>
				{displayCostEnabled && (
					<div className={styles.item}>
						<div className={styles.labelHead}>
							<CoinsStackedIcon size={20} />
							<div className={styles.label}>{costTitle}</div>
						</div>
						<div className={styles.value}>
							<CurrencyFormatterRender
								currency={currencyCode}
								isCent
								value={costValue}
								suffix={suffix}
							/>
						</div>
					</div>
				)}
				<div className={styles.item}>
					<div className={styles.labelHead}>
						<AlarmClockIcon size={20} />
						<div className={styles.label}>Start & end date</div>
					</div>
					<div className={styles.value}>{startEndDate}</div>
				</div>
				{!isOldProposition && description?.length > 0 && (
					<div className={cx(styles.item, styles.item__message)}>
						<div className={styles.labelHead}>
							<MessageTextSquareIcon size={20} />
							<div className={styles.label}>Additional comments</div>
						</div>
						<Description
							description={parseMentions(description)}
							max={230}
							parseMarkdown={true}
							showMoreText="View more"
							classNameContent={styles.value}
							viewMoreClassName={styles.viewMore}
						/>
					</div>
				)}
			</div>
		</BidDetailsProcessesCard>
	);
}
