/* eslint-disable react/display-name*/
import React, { useState } from "react";
import loadable from "loadable-components";
import { CreateRequestInfoIcon } from "../CreateRequestInfoIcon";
import { CreateRequestAddButton } from "../CreateRequestAddButton";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { useFormContext } from "react-hook-form";
import styles from "./profile-step-skills-field.module.scss";
import { CreateRequestEditButton } from "../CreateRequestEditButton";

const SkillsPickerModal = loadable(() =>
	import("../SkillsPickerModal/SkillsPickerModal")
);

const ProfileStepSkillsField = ({ onSave: onSaveProp, category }) => {
	const [displaySkillsModal, setDisplaySkillsModal] = useState(false);
	const { watch } = useFormContext();
	const skillsList = watch("skills");

	const handleAddSkillsButtonClick = () => setDisplaySkillsModal(true);

	const handleSkillsModalCancelClick = () => setDisplaySkillsModal(false);

	const handleSkillsModalEscapeKeyDown = () => setDisplaySkillsModal(false);

	const onSave = list => {
		setDisplaySkillsModal(false);
		onSaveProp(list);
	};

	return (
		<CreateRequestFieldContainer>
			<CreateRequestLabelContainer>
				<CreateRequestFieldLabel>Skills</CreateRequestFieldLabel>
				<CreateRequestInfoIcon>
					The skills are an essential part of the candidate/vacancy matching
					process. Be as precise as possible (without being too demanding) in
					the selection of the skills needed and the weight/importance they have
					for the role.
				</CreateRequestInfoIcon>
			</CreateRequestLabelContainer>
			{skillsList?.length > 0 && (
				<div className={styles.skillsRows}>
					<div className={styles.skillsContainer}>
						<div className={styles.categoryLabel}>{category.label}</div>
						<div className={styles.skillsList}>
							{skillsList?.map(({ _id, name, proficiency, mustHave }) => (
								<div key={_id} className={styles.skillTag}>
									{name}
									{mustHave && "*"} ({proficiency}/5)
								</div>
							))}
						</div>
					</div>
				</div>
			)}

			{skillsList?.length > 0 ? (
				<CreateRequestEditButton onClick={handleAddSkillsButtonClick} />
			) : (
				<CreateRequestAddButton onClick={handleAddSkillsButtonClick} />
			)}
			{displaySkillsModal && (
				<SkillsPickerModal
					onCancel={handleSkillsModalCancelClick}
					onEscapeKeyDown={handleSkillsModalEscapeKeyDown}
					skillsList={skillsList}
					onSave={onSave}
					category={category}
				/>
			)}
		</CreateRequestFieldContainer>
	);
};

export default ProfileStepSkillsField;
