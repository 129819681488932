import React, { useRef } from "react";
import classes from "../../../../modules/SearchCandidate/components/TableContainer/table-container.module.scss";
import MaximizeIcons from "../../icons/Maximize";
import DotHorzIcons from "../../icons/DotsHor";
import { CANDIDATE_POOL } from "../../../../config";
getPermanentProfileRoute;
import { historyPush } from "../../../../config/helpers";
import usePoolStore from "modules/SearchCandidate/hooks/zustandPool.js";
import { useMutation } from "react-query";
import toaster from "../../../Toaster";
import get from "lodash/get";
import { resendActivateEmail } from "modules/SearchCandidate/api/useResendAvtivationEmail";
import { usePopper } from "react-popper";
import Portal from "../../../Portal";
import { useToggle } from "react-use";
import { Item } from "@react-stately/collections";
import s from "../MenuColumn/menu-column.module.scss";
import Menu from "../Menu/Menu";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import Hide from "../../icons/Hide";
import Delete from "../../icons/Delete";
import ResendActivation from "../../icons/ResendActivation";
import ViewProfile from "../../icons/ViewProfile";
import Checkbox from "./../Checkbox/Checkbox";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import {
	HOW_TO_SOURCE_AND_INVITE_CANDIDATE,
	HOW_TO_WORK_WITH_NOTES
} from "config/onboarding";
import { useWorkWithNotesTour } from "modules/user-onboarding/workflows/work-with-notes";

const SelectColumn = props => {
	const { data, handleSetSelectedCandidate } = props;
	const [showMenu, setShowMenu] = useToggle(false);
	const { setCurrentId } = usePoolStore(state => ({
		setCurrentId: state.setCurrentId
	}));
	const { isSelected, Id, onCheckboxChange } = data;
	const [mutate] = useMutation(resendActivateEmail, {
		onSuccess: () => {
			toaster.success("The activation email has been resent.");
		},
		onError: error => {
			toaster.danger(
				get(error, "detail.name", get(error, "detail", get(error, "title")))
			);
		}
	});
	const goToProfile = id =>
		historyPush(
			getPermanentProfileRoute(`${id}?list=${CANDIDATE_POOL}&source=list`),
			true
		);
	const handleClickMenu = key => {
		if (key === "View full profile") {
			goToProfile(data.profileId);
		} else if (key === "Hide permanently") {
			handleSetSelectedCandidate({
				profileId: data.profileId,
				name: `${data["Last name"]} ${data["First name"]}`,
				type: "HIDE"
			});
		} else if (key === "Delete") {
			handleSetSelectedCandidate({
				profileId: data.profileId,
				name: `${data["Last name"]} ${data["First name"]}`,
				type: "DELETE"
			});
		} else {
			mutate({ profile_ids: [data.profileId] });
		}
	};

	const quickViewHandler = () => {
		setCurrentId(data.profileId);
	};
	const referenceElement = useRef();
	const popperElement = useRef();
	const { styles, attributes } = usePopper(
		referenceElement.current,
		popperElement.current,
		{
			placement: "right-start",
			modifiers: [
				{
					name: "offset",
					options: {
						offset: [-9, 7]
					}
				},
				{
					name: "flip",
					options: {
						fallbackPlacements: ["top", "right"]
					}
				}
			]
		}
	);
	useOnClickOutside(popperElement, () => {
		setShowMenu(false);
	});

	useWorkWithNotesTour({
		openQuickViewDrawer: quickViewHandler
	});

	return (
		<div
			className={classes.selectIconsContainer}
			data-onboarding-step={`${HOW_TO_SOURCE_AND_INVITE_CANDIDATE}-2`}
		>
			<div>
				<Checkbox
					className={classes.checkboxSvg}
					id={Id}
					checked={
						typeof data.isSelected === "object"
							? data.isSelected.selected
							: isSelected
					}
					onChange={() => {
						typeof data.isSelected === "object"
							? data.isSelected.onCheckboxChange({
									target: { checked: !data.isSelected.selected }
							  })
							: onCheckboxChange({ target: { checked: !isSelected } });
					}}
				/>
			</div>
			{!props.hideExpand && (
				<button
					className={classes.quickView}
					onClick={quickViewHandler}
					data-onboarding-step={`${HOW_TO_WORK_WITH_NOTES}-0`}
				>
					<MaximizeIcons
						width="1.125rem"
						height="1.125rem"
						color="#A3A3A3"
						stroke="1.5"
						marginRight="0.5rem"
						marginLeft="1rem"
					/>
				</button>
			)}

			{!props.hideDots && (
				<button
					className={classes.dotsHorizontal}
					ref={referenceElement}
					onClick={setShowMenu}
				>
					<DotHorzIcons
						width="1.125rem"
						height="1.125rem"
						color="#A3A3A3"
						stroke="1.5"
					/>
				</button>
			)}
			<Portal>
				<div
					style={{ ...styles.popper, display: showMenu ? "block" : "none" }}
					{...attributes.popper}
					ref={popperElement}
					className={classes.popperContainer}
				>
					<Menu aria-label="Actions" onAction={handleClickMenu}>
						<Item key="View full profile">
							<ViewProfile
								width="1.125rem"
								height="1.125rem"
								color="currentColor"
								stroke="1.5"
							/>
							<div className={s.headerItem}>View full profile</div>
						</Item>
						<Item key="Hide permanently">
							<Hide
								width="1.125rem"
								height="1.125rem"
								color="currentColor"
								stroke="1.5"
							/>
							<div className={s.headerItem}>Hide permanently</div>
						</Item>
						{data.can_delete && (
							<Item key="Delete">
								<Delete
									width="1.125rem"
									height="1.125rem"
									color="currentColor"
									stroke="1.5"
								/>
								<div className={s.headerItem}>Delete</div>
							</Item>
						)}
						{data.can_resend_activation_email && (
							<Item key="resend">
								<ResendActivation
									width="1.125rem"
									height="1.125rem"
									color="currentColor"
									stroke="1.5"
								/>
								<div className={s.headerItem}>Resend Activation code</div>{" "}
							</Item>
						)}
					</Menu>
				</div>
			</Portal>
		</div>
	);
};

export default SelectColumn;
