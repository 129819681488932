import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Element, scroller } from "react-scroll";
import { connect } from "react-redux";
import { compose } from "redux";
import TimePicker from "rc-time-picker";
import StyledSelect from "common/StyledSelect";
import FormField from "common/FormField";
import Tooltip from "common/Tooltip";
import DatePickerField from "common/DatePickerField";
import { closeReasonModal, disputeEntry } from "../actions";
import RichEditor from "common/RichEditor/RichEditor";
import {
	HOURS_INCORRECT,
	PRICE_INCORRECT,
	DATE_INCORRECT,
	DATE_FORMAT,
	CLIENT,
	WORKTIME,
	SUBMITTED
} from "config";

class ReasonModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			description: props.initialValues.dispute_description || "",
			time: props.initialValues.dispute_hours || null,
			invalid: false
		};
		this.getReasons = this.getReasons.bind(this);
		this.fileDispute = this.fileDispute.bind(this);
	}

	componentDidMount() {
		scroller.scrollTo("reasons", {
			offset: -160,
			smooth: true
		});
	}

	getReasons() {
		const timePrice =
			this.props.view === WORKTIME
				? { value: HOURS_INCORRECT, label: `Incorrect time` }
				: { value: PRICE_INCORRECT, label: `Incorrect price` };
		const reasons = [
			timePrice,
			{ value: DATE_INCORRECT, label: `Incorrect Date` }
		];
		return reasons;
	}

	fileDispute(formProps) {
		const payload = {};
		this.setState({ invalid: false });
		payload.reason =
			typeof formProps.reason === "object"
				? formProps.reason.value
				: formProps.reason;

		if (
			(payload.reason === HOURS_INCORRECT && !this.state.time) ||
			!this.state.description
		) {
			return this.setState({ invalid: true });
		}

		if (payload.reason === DATE_INCORRECT)
			payload.dispute_date = formProps.dispute_date;
		else if (payload.reason === HOURS_INCORRECT)
			payload.dispute_hours =
				typeof this.state.time === "object"
					? this.state.time.hours() * 60 + this.state.time.minutes()
					: this.state.time;
		else if (payload.reason === PRICE_INCORRECT)
			payload.dispute_price = formProps.dispute_price;

		payload.dispute_description = this.state.description;
		this.props.dispatch(disputeEntry(payload));
	}

	getTimeValue(val) {
		const hours = (val / 60).toFixed(0);
		const minutes = val % 60;
		return `${hours < 10 ? `0${hours}` : hours}:${
			minutes < 10 ? `0${minutes}` : minutes
		}`;
	}

	renderOptions(reason) {
		reason = typeof reason === "object" ? reason.value : reason;
		const disabled =
			this.props.initialValues &&
			(this.props.initialValues.status !== SUBMITTED ||
				this.props.userType !== CLIENT);
		let defaultTimeValue = null;
		if (
			this.props.userType === CLIENT &&
			this.props.initialValues.dispute_hours
		) {
			defaultTimeValue = window.moment(
				`01/01/1971 ${this.getTimeValue(
					this.props.initialValues.dispute_hours
				)}`,
				`${DATE_FORMAT} HH:mm`
			);
		}
		if (reason && reason === DATE_INCORRECT) {
			return (
				<div className="form-group">
					<label htmlFor="date">Correct date</label>
					{!disabled && (
						<Field
							classes="inner-icon left-icon"
							disabled={disabled}
							icon="fa fa-calendar"
							name="dispute_date"
							component={DatePickerField}
						/>
					)}
					{disabled && (
						<input
							type="text"
							disabled
							value={window.moment
								.unix(this.props.initialValues.dispute_date)
								.format(DATE_FORMAT)}
						/>
					)}
				</div>
			);
		} else if (reason && reason === HOURS_INCORRECT) {
			return (
				<div className="form-group">
					<label>Correct time</label>
					<div className="input-box">
						{!disabled && (
							<TimePicker
								name="dispute_hours"
								disabled={disabled}
								format="HH:mm"
								defaultValue={defaultTimeValue}
								showSecond={false}
								className="time-picker"
								readOnly={true}
								onChange={time => this.setState({ time })}
							/>
						)}
						{!disabled && this.state.invalid && !this.state.time && (
							<Tooltip text="Please specify a valid time" />
						)}
						{disabled && (
							<input
								type="text"
								disabled
								value={this.getTimeValue(
									this.props.initialValues.dispute_hours
								)}
							/>
						)}
					</div>
				</div>
			);
		} else if (reason && reason === PRICE_INCORRECT) {
			return (
				<div className="form-group">
					<Field
						label="Correct Price"
						disabled={disabled}
						name="dispute_price"
						component={FormField}
					/>
				</div>
			);
		}
	}

	render() {
		const { view, handleSubmit, initialValues, reason, userType } = this.props;
		const disabled =
			initialValues &&
			(initialValues.status !== SUBMITTED || userType !== CLIENT);
		return (
			<div className="reason-modal">
				<Element name="reasons">
					<div className="title">
						{view} dispute
						<div
							className="close-btn"
							onClick={() => this.props.dispatch(closeReasonModal())}
						>
							<span className="close">×</span>
						</div>
					</div>
					<div className="content">
						<form onSubmit={handleSubmit(this.fileDispute)}>
							<div className="form-group">
								<Field
									placeholder="Reason"
									disabled={disabled}
									name="reason"
									label="Reason"
									options={this.getReasons()}
									position="bottom"
									component={StyledSelect}
								/>
							</div>
							{this.renderOptions(reason)}
							<div className="form-group">
								<label htmlFor="editor">Description</label>
								{disabled && (
									<textarea
										id="editor"
										cols="30"
										rows="10"
										readOnly={true}
										disabled={true}
										value={initialValues.dispute_description || ""}
									/>
								)}
								{!disabled && (
									<RichEditor
										disabled={disabled}
										onChange={description => this.setState({ description })}
										defaultValue={initialValues.dispute_description || ""}
									/>
								)}
								{!disabled && this.state.invalid && !this.state.description && (
									<Tooltip text="Please specify a valid description" />
								)}
							</div>
							<div className="actions">
								{this.props.userType === CLIENT && !disabled && (
									<button
										className="btn btn-primary large"
										type="submit"
										disabled={disabled}
									>
										Save
									</button>
								)}
								<button
									className="btn btn-white large"
									type="button"
									onClick={() => this.props.dispatch(closeReasonModal())}
								>
									{disabled ? "Close" : "Cancel"}
								</button>
							</div>
						</form>
					</div>
				</Element>
			</div>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.reason) {
		errors.reason = "Please provide a reason";
	}
	if (!formProps.dispute_date) {
		errors.dispute_date = "Please provide the correct date";
	}
	if (!formProps.dispute_price) {
		errors.dispute_price = "Please provide the correct price";
	}
	if (formProps.dispute_price && !/^\d+$/.test(formProps.dispute_price)) {
		errors.dispute_price = "Price must be a number";
	}
	return errors;
};

const selector = formValueSelector("dispute");

const mapStateToProps = state => {
	return {
		reason: selector(state, "reason"),
		initialValues: JSON.parse(JSON.stringify(state.timesheet.editItem)),
		view: state.timesheet.view
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "dispute",
		validate,
		touchOnBlur: false
	})
)(ReasonModal);
