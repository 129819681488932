import React, { useEffect } from "react";
import { Overlay, useModalOverlay } from "react-aria";
import { Button } from "common/Button";
import styles from "./delete-gdpr-candidate-modal.module.scss";
import { ReactComponent as NotificationIcon } from "static/icons/notification-icon-triangle.svg";
import { ReactComponent as TrashIcon } from "static/icons/trash-icon-grey.svg";
import { ReactComponent as CloseIcon } from "static/icons/cross-black.svg";
import { IconButton } from "common/IconButton";

const DeleteGdprCandidateModal = ({
	onClose,
	open,
	onDelete,
	deletedCandidatesCounter
}) => {
	let ref = React.useRef();
	let { modalProps, underlayProps } = useModalOverlay(
		{ onClose },
		{ isOpen: open },
		ref
	);

	useEffect(() => {
		document.addEventListener("keydown", function(evt) {
			if (evt.key === "Escape") {
				onClose();
			}
		});
	}, []);

	return (
		<Overlay>
			<div className={styles.wrapper} ref={ref} {...underlayProps}>
				<div {...modalProps} className={styles.container}>
					<div className={styles.header}>
						<h2 className={styles.title}>Delete candidate</h2>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</div>
					<div className={styles.descriptionContainer}>
						<NotificationIcon className={styles.notificationIcon} />
						<div className={styles.description}>
							You&apos;re about to delete {deletedCandidatesCounter} candidates
							permanently. Are you sure you want to proceed?
						</div>
					</div>
					<div className={styles.buttons}>
						<Button
							rootClassName={styles.cancel}
							text="Cancel"
							variant="outlined"
							onClick={onClose}
						/>
						<Button
							className={styles.deleteButton}
							text="Delete"
							icon={<TrashIcon className={styles.trashIcon} />}
							onClick={onDelete}
						/>
					</div>
				</div>
			</div>
		</Overlay>
	);
};

export default DeleteGdprCandidateModal;
