import React, { useState } from "react";
import get from "lodash/get";
import toaster from "common/Toaster";
import { ReactComponent as EnterIcon } from "static/icons/enter-icon.svg";
import CreatableSelect from "./CreatableSelect";
import { Box, Flex, Text } from "rebass";

const createOption = label => ({
	label,
	value: label
});

const MultiSelectTextInput = ({ setTags, tags, allTags }) => {
	const [inputValue, setInputValue] = useState("");
	const handleChange = value => {
		setTags(value);
	};
	const handleInputChange = inputValue => {
		if (inputValue.length <= 20) {
			setInputValue(inputValue);
		}
	};
	const handleKeyDown = event => {
		if (!inputValue) {
			return;
		}
		switch (event.key) {
			case "Enter":
			case "Tab":
				if (
					!allTags.some(
						tag =>
							get(tag, "label", get(tag, "name"))
								.toLowerCase()
								.localeCompare(inputValue.toLowerCase()) === 0
					)
				) {
					setTags(tags => [...tags, createOption(inputValue)]);
					setInputValue("");
				} else {
					toaster.danger(
						`The tag ${inputValue} already exists, please choose another tag name.`
					);
				}
				event.preventDefault();
		}
	};
	const isTagExist = allTags.some(
		tag =>
			get(tag, "label", get(tag, "name"))
				.toLowerCase()
				.localeCompare(inputValue.toLowerCase()) === 0
	);
	return (
		<Box>
			<CreatableSelect
				inputValue={inputValue}
				isClearable
				onChange={handleChange}
				onInputChange={handleInputChange}
				onKeyDown={handleKeyDown}
				value={tags}
				setValue={setTags}
				isTagExist={isTagExist}
			/>

			<Flex mt="10px" justifyContent="space-between">
				<Flex alignItems={"center"}>
					<Text
						sx={{
							fontFamily: "BasierCircle",
							fontStyle: "normal",
							fontWeight: 600,
							fontSize: "12px",
							lineHeight: "22px",
							color: "#A0A3BD",
							marginRight: "8px"
						}}
					>
						to add new tag
					</Text>
					<EnterIcon />
				</Flex>
				<Text
					sx={{
						fontFamily: "BasierCircle",
						fontStyle: "normal",
						fontWeight: 600,
						fontSize: "12px",
						lineHeight: "22px",
						color: inputValue.length === 20 ? "#FD6950" : "#6E7191"
					}}
				>
					{inputValue.length}/20
				</Text>
			</Flex>
		</Box>
	);
};

export default MultiSelectTextInput;
