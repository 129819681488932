import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { trimTextContentFromAnchor } from "@lexical/selection";
import { $restoreEditorState } from "@lexical/utils";
import { $getSelection, $isRangeSelection, RootNode } from "lexical";
import { useEffect } from "react";

export function MaxLengthPlugin({ maxLength, setTextLength }) {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		let lastRestoredEditorState = null;
		return editor.registerNodeTransform(RootNode, rootNode => {
			const selection = $getSelection();
			if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
				return;
			}
			const prevEditorState = editor.getEditorState();
			const prevTextContent = prevEditorState.read(() =>
				rootNode.getTextContent()
			);
			const textContent = rootNode.getTextContent();
			if (prevTextContent !== textContent) {
				const textLength = textContent.length;
				const delCount = textLength - maxLength;
				const anchor = selection.anchor;
				if (delCount > 0) {
					// Restore the old editor state instead if the last
					// text content was already at the limit.
					setTextLength?.(prevTextContent.length);
					if (
						prevTextContent.length === maxLength &&
						lastRestoredEditorState !== prevEditorState
					) {
						lastRestoredEditorState = prevEditorState;
						$restoreEditorState(editor, prevEditorState);
					} else {
						trimTextContentFromAnchor(editor, anchor, delCount);
					}
				} else {
					setTextLength?.(textLength);
				}
			}
		});
	}, [editor, maxLength, setTextLength]);

	return null;
}
