export const MISSION_TIMESHEETS_COLUMNS = [
	{
		colId: "period",
		headerName: "Period"
	},
	{
		colId: "submitted_date",
		headerName: "Submitted date"
	},
	{
		colId: "approval_date",
		headerName: "Approval date"
	},
	{
		colId: "status",
		headerName: "Status"
	},
	{
		colId: "total_amount",
		headerName: "Total amount"
	},
	{
		colId: "files",
		headerName: "Files"
	}
];

export const MISSION_EXTENSIONS_COLUMNS = [
	{
		colId: "_action",
		headerName: ""
	},
	{
		colId: "extended_by",
		headerName: "Extended by"
	},
	{
		colId: "start_date",
		headerName: "Start date"
	},
	{
		colId: "end_date",
		headerName: "End date"
	},
	{
		colId: "daily_rate",
		headerName: "Daily rate"
	},
	{
		colId: "daily_cost",
		headerName: "Daily cost"
	},
	{
		colId: "hourly_rate",
		headerName: "Hourly rate"
	},
	{
		colId: "hourly_cost",
		headerName: "Hourly cost"
	}
];

export const MONTHLY = "monthly";
export const COMMENT_MAX_LENGTH = 500;
