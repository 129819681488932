//useGetPermanentEvaluationsList uses the hook useFilter and useGetPermanentEvaluations doesn't need filter
import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { EVALUATIONS_DATA_ENDPOINT } from "config/api-endpoints";

const getData = body => client(EVALUATIONS_DATA_ENDPOINT, { body });

export const GET_PERMANENT_EVALUATIONS_LIST_QUERY_KEY =
	"GET_PERMANENT_EVALUATIONS_LIST_QUERY_KEY";

const useGetPermanentEvaluations = (body, options = {}) =>
	useQuery(
		[GET_PERMANENT_EVALUATIONS_LIST_QUERY_KEY, body],
		() => getData(body),
		{
			refetchOnWindowFocus: false,
			retry: 0,
			...options
		}
	);

export default useGetPermanentEvaluations;
