import { OPERATORS } from "common/FilterDrawer/utils";
import { CustomInput } from "common/CustomInput";
import { CustomMap } from "common/CustomMap";
import { Picker } from "common/Picker";
import { FreelancersCurrencyInput } from "../components/FreelancersCurrencyInput";
import { FreelancersBooleanSelect } from "../components/FreelancersBooleanSelect";
import { DatePicker } from "common/DatePicker";
import { RangeDatePicker } from "common/RangeDatePicker";

export const COMPANY_DEFAULT_AVATAR =
	"https://mcusercontent.com/88c83061cea4f39823efacefc/images/b3b4f65b-96e6-4614-bf86-0bd0350d4861.png";

export const FREELANCERS_MAX_SELECT = 24;

export const FREELANCERS_PAGE_SIZES = [50, 24, 12];

export const FREELANCERS_FILTER_ID = "FREELANCERS_FILTER_ID";

export const FREELANCERS_MODULE_ID = "FREELANCERS_MODULE_ID";

export const DISPLAY_TYPE = {
	table: "table",
	card: "card"
};

export const API_DATA_TYPE = "search_freelancers_table";

export const FREELANCERS_PAGE_SIZE = 24;

export const TYPES = {
	text: "text",
	availability: "availability",
	postcode: "postcode",
	timeCommitment: "timeCommitment",
	number: "number",
	array: "array",
	boolean: "boolean",
	date: "date",
	address: "address"
};

export const MAP_TYPE_OPERATORS = {
	[TYPES.text]: [OPERATORS.contains, OPERATORS.notContains],
	[TYPES.availability]: [OPERATORS.is, OPERATORS.isNot, OPERATORS.anyOf],
	[TYPES.postcode]: [
		OPERATORS.contains,
		OPERATORS.notContains,
		OPERATORS.startWith
	],
	[TYPES.timeCommitment]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.number]: [
		OPERATORS.eq,
		OPERATORS.lessThan,
		OPERATORS.greaterThan,
		OPERATORS.isEmpty
	],
	[TYPES.array]: [OPERATORS.is, OPERATORS.anyOf, OPERATORS.isNot],
	[TYPES.boolean]: [OPERATORS.is, OPERATORS.isNot],
	[TYPES.date]: [
		OPERATORS.is,
		OPERATORS.before,
		OPERATORS.after,
		OPERATORS.between
	],
	[TYPES.address]: [OPERATORS.is]
};

export const MAP_TYPE_COMPONENT = {
	[TYPES.text]: {
		default: CustomInput
	},
	[TYPES.availability]: {
		default: Picker
	},
	[TYPES.address]: {
		default: CustomMap
	},
	[TYPES.postcode]: {
		default: CustomInput
	},
	[TYPES.timeCommitment]: {
		default: Picker
	},
	[TYPES.number]: {
		default: FreelancersCurrencyInput,
		[OPERATORS.isEmpty.value]: FreelancersBooleanSelect
	},
	[TYPES.array]: {
		default: Picker
	},
	[TYPES.boolean]: {
		default: FreelancersBooleanSelect
	},
	[TYPES.date]: {
		default: DatePicker,
		[OPERATORS.between.value]: RangeDatePicker
	}
};

export const columnSortMap = {
	_id: "_id",
	first_name: "first_name",
	last_name: "last_name",
	email: "email",
	seniority: "seniority",
	functions: "functions",
	skills: "skills",
	category: "category",
	sub_category: "sub_category",
	availability: "availability",
	phone: "phone",
	location: "location",
	postcode: "postcode",
	notes: "notes",
	interviewed: "interviewed",
	languages: "languages",
	bid_received: " bid_received",
	on_mission: " on_mission",
	source: " source",
	company: " company",
	created_on: " created_on",
	company_address: "company_address",
	vat_number: "vat_number",
	preferred_location: "preferred_location",
	time_commitment: "time_commitment",
	website: "website",
	minimum_hourly_rate: "minimum_hourly_rate",
	created_by: "created_by"
};

export const columnFiltersMap = {
	_id: "_id",
	first_name: "first_name",
	last_name: "last_name",
	email: "email",
	seniority: "seniority",
	functions: "functions",
	skills: "skills",
	category: "category",
	sub_category: "sub_category",
	availability: "availability",
	phone: "phone",
	location: "location",
	postcode: "postcode",
	notes: "notes",
	interviewed: "interviewed",
	languages: "languages",
	bid_received: " bid_received",
	on_mission: " on_mission",
	source: " source",
	company: "company",
	created_on: " created_on",
	company_address: "company_address",
	vat_number: "vat_number",
	preferred_location: "preferred_location",
	time_commitment: "time_commitment",
	website: "website",
	minimum_hourly_rate: "minimum_hourly_rate",
	created_by: "created_by"
};

export const CHECK_FILTER_COLUMNS = [
	"first_name",
	"last_name",
	"email",
	"phone",
	"website",
	"company",
	"vat_number"
];
