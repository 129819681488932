import create from "zustand";

const initialialState = {
	open: false
};

const useAddContractStore = create(set => ({
	...initialialState,
	setvalue: (key, value) => set({ [key]: value }),
	reset: () => set(initialialState)
}));

export default useAddContractStore;
