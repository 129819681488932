import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { Filter } from "../Styled";
import { FUNCTIONS, SKILLS, LANGUAGES, LOCATION } from "config/index";
import Location from "./Location";
import Functions from "./Functions";
import Skills from "./Skills";
import Languages from "./Languages";
import DistanceFilter from "./DistanceFilter";
import { TAGS } from "config";
import Tags from "./Tags";

const FilterItem = ({
	title,
	items,
	filterType,
	deleteFilter,
	openModal,
	distance,
	updateDistance,
	isClient,
	residence_only,
	updateResidenceOnly,
	deletTag
}) => {
	const showDistance =
		items?.city || items?.number || items?.street || items?.zip;

	const renderItems = () => {
		switch (filterType) {
			case FUNCTIONS:
				return (
					<Functions
						funcs={items}
						deleteFilter={deleteFilter}
						openModal={openModal}
					/>
				);
			case SKILLS:
				return (
					<Skills
						skills={items}
						deleteFilter={deleteFilter}
						openModal={openModal}
					/>
				);
			case LANGUAGES:
				return (
					<Languages
						languages={items}
						deleteFilter={deleteFilter}
						openModal={openModal}
					/>
				);
			case LOCATION:
				return (
					<>
						<Location location={items} />
						<DistanceFilter
							showDistance={showDistance}
							distance={distance}
							updateDistance={updateDistance}
							isClient={isClient}
							residence_only={residence_only}
							updateResidenceOnly={updateResidenceOnly}
						/>
					</>
				);
			case TAGS:
				return (
					<>
						<Tags tags={items} deleteFilter={deletTag} openModal={openModal} />
					</>
				);
			default:
				return;
		}
	};
	return (
		<Filter className={`filter-${title}`}>
			<div className="title">
				{title}
				{(_get(items, "length") > 0 || _get(items, "country")) && (
					<span className="icon-wrapper" onClick={openModal}>
						<span className={"icon-edit-2"} />
					</span>
				)}
			</div>
			{_get(items, "length") > 0 || _get(items, "country") ? (
				renderItems(items, filterType, deleteFilter)
			) : (
				<button className="btn btn-gray" onClick={openModal}>
					<span>+</span>
					<span>Add {filterType}</span>
				</button>
			)}
		</Filter>
	);
};

FilterItem.propTypes = {
	title: PropTypes.string,
	items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	filterType: PropTypes.string
};

export default FilterItem;
