/* eslint-disable */
import Tooltip from "rc-tooltip";
import React from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import Description from "common/Description";
import { TabsContainer } from "./jonDescriptionTabs.style";
import _get from "lodash/get";
import Function from "modules/vacancy-view/components/jobDetails/jobFunctions.js";
import ListItem from "common/ListItem";
import {
	MAX_LANGUAGE_WEIGHT,
	MAX_SKILL_WEIGHT,
	LANGUAGES,
	SKILLS,
	VACANCY,
	EDUCATION_LEVELS
} from "config";
import sortBy from "lodash/sortBy";
import { ReactComponent as Bookmark } from "static/icons/bookmarkFlag.svg";
import style from "./job-details.module.scss";
import cx from "classnames";
import { isHTML } from "config/helpers";
import LexicalContentView from "common/LexicalContentView";

export default function RequestViewMain({ job }) {
	const renderCrumb = ({ value = "", max = 18 }) => {
		if (value.length > max) {
			return (
				<Tooltip
					placement="top"
					mouseEnterDelay={0}
					mouseLeaveDelay={0}
					overlay={<div>{value}</div>}
				>
					<li>{`${value.substring(0, max)}...`}</li>
				</Tooltip>
			);
		}
		return <li>{value}</li>;
	};

	const renderCrumbs = () => {
		return (
			<ul className={"crumbs main"}>
				{renderCrumb({
					value: job.category.name
				})}
				{renderCrumb({ value: job.subcategory.name })}
				{renderCrumb({ value: job.function })}
				{renderCrumb({ value: job.seniority })}
			</ul>
		);
	};

	const renderJobDetails = () => {
		const description = job.description;

		return (
			<div className="section description">
				<div className={style.jobTitle}>Request title </div>
				<h2 className={style.title}>{job.title}</h2>
				{isHTML(description) ? (
					<LexicalContentView description={description} />
				) : (
					<Description description={job.description} parseMarkdown={true} />
				)}
			</div>
		);
	};

	return (
		<div className={cx("content-inner job-details-container", style.container)}>
			<div style={{ padding: "15px" }}>
				<h1 className={style.aboutJobTitle}>About this request</h1>
				<p className={style.descriptionStyle}> Description</p>
			</div>
			{renderJobDetails()}
			<div id="can-do" className="section action-hover">
				<div className="section-title">Function</div>
				<div className="section-content">{renderCrumbs()}</div>
			</div>{" "}
			<ListItem
				title={"Skills"}
				list={_get(job, "skills")}
				categoryName={job.category?.name}
				scoreMax={MAX_SKILL_WEIGHT}
				type={SKILLS}
				jobType={VACANCY}
				isVacancy={true}
			/>
			<ListItem
				title={"Languages"}
				list={_get(job, "languages")}
				scoreMax={MAX_LANGUAGE_WEIGHT}
				type={LANGUAGES}
			/>
		</div>
	);
}
