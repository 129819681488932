import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";

export const EMAILS_LIST_LIMIT = 50;

export const MARK_AS_READ_ACTION = "mark_as_read";
export const MARK_AS_UNREAD_ACTION = "mark_as_unread";
export const MARK_AS_ARCHIVED_ACTION = "mark_as_archived";
export const MARK_AS_UNARCHIVED_ACTION = "mark_as_unarchived";
export const SOFT_DELETE_ACTION = "soft_delete";
export const PERMANENTLY_DELETE_ACTION = "permanently_delete";
export const MARK_AS_STARRED_ACTION = "mark_as_starred";
export const MARK_AS_UNSTARRED_ACTION = "mark_as_unstarred";
export const EMPTY_TRASH = "empty_trash";
export const REPLY_ACTION = "reply";
export const REPLY_TO_ALL_ACTION = "reply_to_all";
export const FORWARD_ACTION = "forward";

export const ACTIONS_BY_VIEW = {
	[MESSAGING_TOOL_LINKS.inbox.slug]: {
		[MARK_AS_ARCHIVED_ACTION]: "Archive",
		[SOFT_DELETE_ACTION]: "Delete",
		[MARK_AS_READ_ACTION]: "Mark as read",
		[MARK_AS_UNREAD_ACTION]: "Mark as unread"
	},
	[MESSAGING_TOOL_LINKS.sent.slug]: {
		[MARK_AS_ARCHIVED_ACTION]: "Archive",
		[SOFT_DELETE_ACTION]: "Delete",
		[MARK_AS_READ_ACTION]: "Mark as read",
		[MARK_AS_UNREAD_ACTION]: "Mark as unread"
	},
	[MESSAGING_TOOL_LINKS.schedule.slug]: {
		[MARK_AS_ARCHIVED_ACTION]: "Archive",
		[SOFT_DELETE_ACTION]: "Delete",
		[MARK_AS_READ_ACTION]: "Mark as read",
		[MARK_AS_UNREAD_ACTION]: "Mark as unread"
	},
	[MESSAGING_TOOL_LINKS.draft.slug]: {
		[MARK_AS_ARCHIVED_ACTION]: "Archive",
		[SOFT_DELETE_ACTION]: "Delete",
		[MARK_AS_READ_ACTION]: "Mark as read",
		[MARK_AS_UNREAD_ACTION]: "Mark as unread"
	},
	[MESSAGING_TOOL_LINKS.archive.slug]: {
		[SOFT_DELETE_ACTION]: "Delete",
		[MARK_AS_READ_ACTION]: "Mark as read",
		[MARK_AS_UNREAD_ACTION]: "Mark as unread"
	},
	[MESSAGING_TOOL_LINKS.trash.slug]: {
		[PERMANENTLY_DELETE_ACTION]: "Permanently delete",
		[MARK_AS_READ_ACTION]: "Mark as read",
		[MARK_AS_UNREAD_ACTION]: "Mark as unread"
	}
};

export const ACTION_SUCCESS_MESSAGE = {
	[MARK_AS_READ_ACTION]: {
		single: "Message marked as read",
		multiple: "Messages marked as read"
	},
	[MARK_AS_UNREAD_ACTION]: {
		single: "Message marked as unread",
		multiple: "Messages marked as unread"
	},
	[MARK_AS_ARCHIVED_ACTION]: {
		single: "Message archived",
		multiple: "Messages archived"
	},
	[MARK_AS_UNARCHIVED_ACTION]: {
		single: "Message unarchived",
		multiple: "Messages unarchived"
	},
	[SOFT_DELETE_ACTION]: {
		single: "Message moved to Trash",
		multiple: "Messages moved to Trash"
	},
	[PERMANENTLY_DELETE_ACTION]: {
		single: "Message permanently deleted",
		multiple: "Messages permanently deleted"
	},
	[EMPTY_TRASH]: {
		single: "Messages permanently deleted"
	},
	[MARK_AS_STARRED_ACTION]: {
		single: "Message starred",
		multiple: "Messages starred"
	},
	[MARK_AS_UNSTARRED_ACTION]: {
		single: "Message unstarred",
		multiple: "Messages unstarred"
	}
};

export const EMPTY_STATE_DESCRIPTION = {
	inbox:
		"Your inbox is empty. Start receiving emails by sending messages or waiting for new ones to arrive.",
	sent:
		"You haven't sent any emails yet. Once you send messages, they'll appear here.",
	scheduled:
		"you don't have any scheduled messages. Plan ahead by scheduling messages to be sent at a later time.",
	draft:
		"You don't have any drafts saved. Start composing your thoughts and save drafts for later.",
	archive:
		"No messages in your archive. Archived messages are stored here for safekeeping. ",
	trash:
		"No emails in the trash. Deleted messages will be moved here, giving you a chance to recover them."
};
