import { useAddVacancyVacancyInformations } from "./useAddVacancyVacancyInformations";
import { buildEditVacancyInformationsPayload } from "../utils/helper";

export const useCreateVacancy = () => {
	const [
		addVacancyVacancyInformations,
		{ isLoading }
	] = useAddVacancyVacancyInformations();

	const onPublish = (values, vacancyId, options) => {
		const body = buildEditVacancyInformationsPayload(values, vacancyId);

		addVacancyVacancyInformations(body, options);
	};

	return [onPublish, isLoading];
};
