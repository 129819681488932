/* eslint-disable react/display-name */
import React from "react";
import Modal from "common/modal";

import styles from "./create-user.module.scss";
import coverInviteSuccessModal from "static/images/inviteSuccess.jpg";

export default ({ onClose, user }) => {
	return (
		<Modal
			size={"530px"}
			padding={"0px"}
			borderRadius={"12px"}
			buttonCloseClassName={"hide"}
			style={{
				background: "#FFF",
				boxShadow:
					"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
				overflow: "hidden"
			}}
		>
			<div className={styles.imageCover}>
				<img alt="Welcome to Wiggli" src={coverInviteSuccessModal} />
			</div>
			<div className={styles.inviteSuccesContainer}>
				<h3>A user invitation email has successfully been sent</h3>
				<p>
					to: <span>@{`${user?.first_name} ${user?.last_name}`}</span>
					<br />
					For security reasons, invitation links expire after 72 hours.
				</p>
				<button onClick={onClose}>Close window</button>
			</div>
		</Modal>
	);
};
