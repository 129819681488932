import React, { Component } from "react";
import { FREELANCER, CLIENT, CONSULTANCY } from "config";
import WindowSizeProvider from "common/WindowSizeProvider";
import cx from "classnames";

class VatDescription extends Component {
	render() {
		const { type, isVatOK } = this.props;
		if (isVatOK) {
			return (
				<p className="description">
					Congratulations, your professional data has successfully been
					retrieved from the <br />
					European VAT database! To access the platform, please fill the first
					name, last name <br />
					and e-mail address fields in; then choose your password.
				</p>
			);
		}
		return (
			<WindowSizeProvider>
				{isMobile => (
					<div>
						{type === FREELANCER && (
							<p
								className={cx("description", {
									["mobileDescription"]: isMobile
								})}
							>
								Please provide us with the details of the company/legal entity
								via which you intend to work.
							</p>
						)}
						{type === CONSULTANCY && (
							<p
								className={cx("description", {
									["mobileDescription"]: isMobile
								})}
							>
								Please provide us with the details of the consultancy that you
								represent.
							</p>
						)}
						{type === CLIENT && (
							<p
								className={cx("description", {
									["mobileDescription"]: isMobile
								})}
							>
								Please provide us with the details of your company.
							</p>
						)}
					</div>
				)}
			</WindowSizeProvider>
		);
	}
}

export default VatDescription;
