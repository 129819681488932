import React from "react";
import styles from "./empty-state.module.scss";
import companiesStyles from "../companies.module.scss";
import { ReactComponent as SearchCompaniesEmptyIcon } from "static/icons/search-companies-empty-state-icon.svg";
import { EB_URL } from "config/api-config";

export default function CompaniesEmptyState() {
	return (
		<div className={styles.container}>
			<SearchCompaniesEmptyIcon />
			<div className={styles.cta}>
				<div className={styles.heading}>
					<h1>Welcome to your preferred companies page !</h1>
					<p>
						Browse through companies and start following the ones you’re
						interested in.
						<br /> You’ll be notified whenever they open a new vacancy.
					</p>
				</div>

				<div>
					<button
						onClick={() => window.open(EB_URL + "/companies", "_blank")}
						className={`${companiesStyles.btn} ${companiesStyles.btn__xl} ${companiesStyles.btn__green}`}
					>
						Discover new companies
					</button>
				</div>
			</div>
		</div>
	);
}
