import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_ACTIVE_JOBS } from "config/api-endpoints";

const getRequests = () => {
	return client(GET_ACTIVE_JOBS, {
		body: {}
	});
};

export const GET_ACTIVE_REQUESTS_QUERY_KEY = "GET_ACTIVE_REQUESTS_QUERY_KEY";

function useGetRequests() {
	return useQuery(GET_ACTIVE_REQUESTS_QUERY_KEY, getRequests, {
		refetchOnWindowFocus: false
	});
}

export default useGetRequests;
