import React from "react";
import Modal from "common/modal";
import ProposeInterview from "./ProposeInterview";
export const ProposeInterviewModal = props => {
	const { active, title, onClose, onPropose, user, interview } = props;
	/* const proposeBtn = {
		label: "Propose",
		action: onPropose
	}; */
	return (
		<Modal active={active} title={title} onClose={onClose} showActions={false}>
			<ProposeInterview
				user={user}
				interview={interview}
				handleBackStep={onClose}
				submitInterviewForm={onPropose}
			/>
		</Modal>
	);
};

export default ProposeInterviewModal;
