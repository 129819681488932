import React from "react";
import styles from "./questionnare-form-checkbox.module.scss";
import cx from "classnames";
import { QuestionnaireFormLabel } from "../QuestionnaireFormLabel";

export default function QuestionnaireFormCheckBox({
	description,
	value,
	onChange,
	isReadOnly = false,
	is_required: isRequired,
	className
}) {
	return (
		<label
			className={cx(styles.checkboxContainer, {
				[styles.isReadOnly]: isReadOnly
			})}
		>
			<input
				type="checkbox"
				checked={value}
				onChange={onChange}
				disabled={isReadOnly}
				className={cx({
					[styles.checkboxReadOnlyChecked]: isReadOnly && value
				})}
			/>
			<span className={styles.checkmark}></span>
			<QuestionnaireFormLabel
				isRequired={isRequired}
				description={description}
				className={className}
			/>
		</label>
	);
}
