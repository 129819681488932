import React, { Component } from "react";
import { connect } from "react-redux";
import FreelancerBidsList from "./FreelancerBidsList";
import { freelancerBidsList, filterFreelancerBidsList } from "../../actions";
import { bidListSelector } from "../../selectors";

export const getFreelancerBidsListRoute = () => {
	return "/jobs/freelancer/bids";
};

export const getFreelancerBidsListPermission = () => {
	return {
		type: [
			{
				name: "freelancer",
				roles: ["admin"]
			}
		]
	};
};

class FreelancerBidsListContainer extends Component {
	componentDidMount() {
		this.filterFreelancerBidsList({ offset: 0 }, "active_jobs");
	}

	getBidsList = payload => {
		this.props.dispatch(freelancerBidsList(payload));
	};

	filterFreelancerBidsList = (payload, status) => {
		this.props.dispatch(filterFreelancerBidsList(payload, status));
	};

	render() {
		const { bids, route } = this.props;
		return (
			<FreelancerBidsList
				route={route}
				bids={bids}
				getBidsList={this.getBidsList}
				filterFreelancerBidsList={this.filterFreelancerBidsList}
			/>
		);
	}
}

const mapStateToProps = state => ({
	bids: bidListSelector(state)
});

export default connect(mapStateToProps)(FreelancerBidsListContainer);
