import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
	reduxForm,
	formValueSelector,
	Field,
	initialize,
	change
} from "redux-form";
import { Link } from "react-router";
import BodyClassName from "react-body-classname";
import _get from "lodash/get";
import Tooltip from "rc-tooltip";
import { Helmet } from "react-helmet";
import { userSelector } from "../../selectors/ProfileSelector";
import {
	ADMIN,
	DATE_FORMAT,
	DEP_MAX_LIST,
	ADMIN_MAX_LENGTH,
	PM_MAX_LENGTH,
	CONSULTANCY,
	CLIENT,
	PROFILE,
	ASK_QUESTION,
	SUPER_ADMIN
} from "config";
import { excerpt, objectCleaner, parsePhoneNumber } from "common/Functions";
import Dashboard from "common/dashboard/Dashboard";
import Intro from "common/dashboard/Intro";
import ProfileImage from "../avatar/profileAvatar";
import { getRolesLabel } from "config/helpers";
import PhoneNumberField from "common/PhoneNumberField";
import FormField from "common/FormField";
import { updateClientProfileInfo } from "../../actions";
import { Tag } from "common/styled/CommonStyled";
import DepartmentsListModal from "./../modals/DepartmentsListModal";
import { EditIcon } from "../../../../common/styled/icons";
import { toggleHelpModal } from "../../../app/actions/ListsActions";
import Breadcumb from "modules/company/components/BreadcumbSettingPages";

class ProfileDefault extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editMode: false,
			showDepartmentModal: false
		};
	}

	componentDidMount() {
		const { user } = this.props;
		this.props.initializeReduxForm({
			first_name: _get(user, "first_name"),
			last_name: _get(user, "last_name"),
			phone: _get(user, "phone"),
			email: _get(user, "email"),
			user_function: _get(user, "user_function")
		});
	}

	updateBirthDay = date => {
		return window.moment(date, DATE_FORMAT).unix();
	};

	editSwitcher = formFields => {
		const { editMode } = this.state;
		if (editMode) {
			return this.__updateProfileInfo(formFields);
		} else this.setState({ editMode: true });
	};

	__updateProfileInfo = formFields => {
		const { user, changeReduxForm, updateClientProfileInfo } = this.props;
		const data = objectCleaner({
			...user,
			...formFields,
			birthday: this.updateBirthDay(formFields.birth_date),
			phone: _get(formFields, "phone.formattedValue")
		});

		updateClientProfileInfo(data).then(() => {
			changeReduxForm("phone", _get(formFields, "phone.formattedValue"));
			this.setState({ editMode: false });
		});
	};

	departmentRender = (breakOn = null) => {
		const {
			user: { groups },
			user
		} = this.props;
		return groups.map((dep, index) => {
			if (!breakOn || (breakOn && index < breakOn)) {
				const maxLength =
					dep.role_name === ADMIN ? ADMIN_MAX_LENGTH : PM_MAX_LENGTH;
				const showTooltip = _get(dep, "name", "").length > maxLength;

				return showTooltip ? (
					<Tooltip
						key={dep.id}
						placement="top"
						overlay={<div>{dep.name}</div>}
						trigger="hover"
						destroyTooltipOnHide={true}
						mouseLeaveDelay={0}
						overlayStyle={{ zIndex: 99999 }}
					>
						<Tag margin={"0 8px 8px 0"} key={dep.name}>
							<strong>{getRolesLabel(dep.role_name)}</strong> -{" "}
							{excerpt(`${dep.name}`, maxLength)}
						</Tag>
					</Tooltip>
				) : user.type === CONSULTANCY ? (
					<Tag margin={"0 8px 8px 0"} key={dep.name}>
						<strong>Business Unit Manager</strong> - {dep.name}
					</Tag>
				) : (
					<Tag margin={"0 8px 8px 0"} key={dep.name}>
						<strong>{getRolesLabel(dep.role_name)}</strong> - {dep.name}
					</Tag>
				);
			} else if (breakOn && index === breakOn) {
				return (
					<Tag
						animated
						cursor="pointer"
						margin={"0 8px 8px 0"}
						key="more"
						onClick={this.toggleDepartmentListModel}
					>
						+ {groups.length - breakOn}
					</Tag>
				);
			}

			return true;
		});
	};

	toggleDepartmentListModel = () => {
		this.setState(prevState => {
			return { showDepartmentModal: !prevState.showDepartmentModal };
		});
	};

	render() {
		const { editMode, showDepartmentModal } = this.state;
		const {
			route: { title },
			formFields,
			handleSubmit,
			user
		} = this.props;
		const subTitleConsultancy = (
			<p>
				You can modify, change or delete your personal information at any time
				by{" "}
				<Link
					className="link"
					onClick={() => this.props.dispatch(toggleHelpModal(ASK_QUESTION))}
				>
					contacting us
				</Link>{" "}
				or by modifying your information directly on this page.
			</p>
		);
		return (
			<BodyClassName className="my-company my-profile">
				<>
					<div>
						{title && (
							<Helmet>
								<title>{title}</title>
							</Helmet>
						)}
						{user.type === CONSULTANCY ? (
							<div className="dashboard-box">
								<Dashboard />
								<Intro
									icon="fa fa-file-text-o"
									title="My profile"
									subTitle={user.type === CONSULTANCY && subTitleConsultancy}
								/>
							</div>
						) : null}
						{user.type === CLIENT ? (
							<Breadcumb
								parentItem="Settings"
								currentItem="My Profile"
								pathCurrentItem="/settings/myprofile"
							/>
						) : (
							<></>
						)}
						<div
							className={
								user.type === CONSULTANCY ? "container" : "full-container"
							}
							style={{
								marginTop: user.type === CONSULTANCY ? "0px" : "20px",
								padding: user.type === CONSULTANCY ? "0px" : "0px 15px"
							}}
						>
							<div className="left-side profile-side">
								<div className="avatar-side bg-white">
									<ProfileImage user={user} type={PROFILE} />
								</div>
								<div className="profile-role company-type">
									<div className="bg-white">
										<span className="title">
											{user.type === CONSULTANCY ? "MY ROLE" : "MY ROLES"}
										</span>
										{!user.is_member ? (
											user.type === CONSULTANCY ? (
												<Tag>
													<span>Consultancy Director</span>
												</Tag>
											) : user.role_name === ADMIN ? (
												<Tag>
													<span>Super Admin</span> - owner
												</Tag>
											) : (
												<Tag>
													<span>Super Admin</span> - all
												</Tag>
											)
										) : user.groups.length > 0 ? (
											this.departmentRender(DEP_MAX_LIST)
										) : user.role_name === SUPER_ADMIN ? (
											<Tag>
												<span>Super Admin</span> - all
											</Tag>
										) : (
											""
										)}
									</div>
								</div>

								<div className="additional-info bg-white">
									<button
										onClick={handleSubmit(this.editSwitcher)}
										className={`btn ${
											editMode ? "btn-primary-new" : "btn-normal"
										} pull-right-btn`}
									>
										{editMode ? (
											"Save changes"
										) : (
											<div>
												<EditIcon icon={"icon-edit"} /> Edit profile
											</div>
										)}
									</button>
									<div className={editMode ? "editable-item" : "item"}>
										<span className="title-label">First Name</span>
										{formFields.first_name && !editMode && (
											<p>{formFields.first_name}</p>
										)}
										{editMode && (
											<Field
												autoFocus={true}
												name="first_name"
												component={FormField}
												readOnly
											/>
										)}
									</div>
									<div className={editMode ? "editable-item" : "item"}>
										<span className="title-label">Last Name</span>
										{formFields.last_name && !editMode && (
											<p>{formFields.last_name}</p>
										)}
										{editMode && (
											<Field name="last_name" component={FormField} readOnly />
										)}
									</div>
									<div className={editMode ? "editable-item" : "item"}>
										<span className="title-label">Mobile number</span>
										{editMode ? (
											<Field
												name="phone"
												component={PhoneNumberField}
												placeholder=" "
											/>
										) : (
											formFields.phone && (
												<p>{parsePhoneNumber(formFields.phone)}</p>
											)
										)}
									</div>
									<div className={editMode ? "editable-item" : "item"}>
										<span className="title-label">Email</span>
										{editMode ? (
											<Field readOnly name="email" component={FormField} />
										) : (
											<p>{formFields.email}</p>
										)}
									</div>
									{user.type !== CONSULTANCY && (
										<div className={editMode ? "editable-item" : "item"}>
											<span className="title-label">Job title</span>
											{!editMode && formFields.user_function && (
												<p>{formFields.user_function}</p>
											)}
											{editMode && (
												<Field name="user_function" component={FormField} />
											)}
										</div>
									)}
								</div>
							</div>
						</div>
						{showDepartmentModal && (
							<DepartmentsListModal
								getDepartments={this.departmentRender}
								toggleModal={this.toggleDepartmentListModel}
							/>
						)}
					</div>
				</>
			</BodyClassName>
		);
	}
}

const selector = formValueSelector("member-profile");

const mapStateToProps = state => {
	return {
		user: userSelector(state),
		formFields: selector(
			state,
			"phone_country",
			"phone",
			"birth_date",
			"first_name",
			"last_name",
			"user_function",
			"email"
		)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		initializeReduxForm: data => dispatch(initialize("member-profile", data)),
		updateClientProfileInfo: data =>
			dispatch(updateClientProfileInfo(data, "member-profile")),
		changeReduxForm: (fieldName, value) =>
			dispatch(change("member-profile", fieldName, value))
	};
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form: "member-profile",
		touchOnBlur: false
	})
)(ProfileDefault);
