/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { DatePicker } from "common/DatePicker";
import styles from "./create-request-dates-fields.module.scss";
import { ReactComponent as CalendarIcon } from "static/icons/calendar-icon-grey.svg";
import { CreateRequestFieldContainer } from "../CreateRequestFieldContainer";
import { CreateRequestFieldLabel } from "../CreateRequestFieldLabel";
import { CreateRequestLabelContainer } from "../CreateRequest$LabelContainer";
import { today, getLocalTimeZone } from "@internationalized/date";
import { CreateRequestFieldError } from "../CreateRequestFieldError";
import { Controller } from "react-hook-form";
import useElements from "hooks/useLayoutElements";

const CreateRequestDatesFields = ({
	control,
	startDateError,
	endDateError,
	startDate
}) => {
	const { mainElement } = useElements();

	return (
		<div className={styles.container}>
			<CreateRequestFieldContainer>
				<CreateRequestLabelContainer>
					<CreateRequestFieldLabel>Start date</CreateRequestFieldLabel>
				</CreateRequestLabelContainer>
				<Controller
					name="startDate"
					control={control}
					render={({ field }) => (
						<DateController
							portalContainer={mainElement}
							minValue={today(getLocalTimeZone())}
							{...field}
						/>
					)}
				/>
				<CreateRequestFieldError error={startDateError} />
			</CreateRequestFieldContainer>
			<CreateRequestFieldContainer>
				<CreateRequestLabelContainer>
					<CreateRequestFieldLabel>End Date</CreateRequestFieldLabel>
				</CreateRequestLabelContainer>
				<Controller
					name="endDate"
					control={control}
					render={({ field }) => (
						<DateController
							portalContainer={mainElement}
							minValue={startDate?.add({ days: 1 })}
							{...field}
						/>
					)}
				/>
				<CreateRequestFieldError error={endDateError} />
			</CreateRequestFieldContainer>
		</div>
	);
};

export default CreateRequestDatesFields;

const DateController = forwardRef((props, ref) => {
	return (
		<DatePicker
			rootClassName={styles.datePickerRoot}
			inputClassName={styles.datePickerInput}
			ref={ref}
			triggerIcon={<CalendarIcon />}
			{...props}
		/>
	);
});
