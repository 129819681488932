/* eslint-disable react/display-name */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { onlineUserSelector } from "../modules/user/selectors/selectors";

const withUser = WrappedComponent => props => {
	return <WrappedComponent {...props} />;
};

const mapStateToProps = state => ({
	user: onlineUserSelector(state)
});

export default compose(connect(mapStateToProps), withUser);
