import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import _get from "lodash/get";
import PropTypes from "prop-types";

import { addNotePoke, addNoteBid } from "../../actions";
import Modal from "common/modal";
import MentionsInput from "common/MentionsInput";
import { TEXTAREA_MAX_LENGTH } from "config";
import { formatDate } from "common/Functions";
import {
	NoteCreated,
	NoteDescription,
	NoteDescriptionWrapper,
	WarningTextNote
} from "../../styled";
import { fetchBid } from "modules/bids/actions/bidActions";
import { convertNoteDescriptionToText } from "config/helpers";

function PokeNoteModal({
	onClose,
	active,
	addNotePokeAction,
	addNoteBid,
	fetchBid,
	pokeId,
	handleSubmit,
	note: { data },
	usersList,
	bidId,
	isBid
}) {
	const addNote = fields => {
		if (isBid) {
			const payload = {
				bid_id: bidId,
				description: fields.mention.value,
				mentioned_users: fields.mention.mentions
			};
			addNoteBid(payload).then(() => {
				onClose();
				fetchBid(payload.bid_id);
			});
		} else {
			const payload = {
				poke_id: pokeId,
				description: fields.mention.value,
				mentioned_users: fields.mention.mentions
			};
			addNotePokeAction(payload).then(() => {
				onClose();
			});
		}
	};

	const users = usersList.map(u => ({ id: u._id, display: u.user_name }));
	const mentionData = [
		{ id: "all", display: "All (all department)" },
		...users
	];
	const ClientNoteFullName = `${_get(data, "user.first_name")} ${_get(
		data,
		"user.last_name"
	)}`;
	const created_at = _get(data, "created_at");
	const value = _get(data, "description");
	return (
		<Modal
			active={active}
			onClose={onClose}
			title={data ? "Note" : "Add note to application"}
			showSecondButton={!data}
			firstButton={{
				action: () => onClose(),
				label: data ? "Close" : "Cancel",
				type: "outlined",
				large: true
			}}
			secondButton={{
				action: handleSubmit(addNote),
				label: "Save Note",
				type: "primary",
				large: true
			}}
		>
			<div className="content">
				{data ? (
					<>
						<NoteCreated>
							<span>{`${ClientNoteFullName} on`}</span>
							{formatDate(created_at)}
						</NoteCreated>
						<NoteDescriptionWrapper>
							<NoteDescription>
								{convertNoteDescriptionToText(value)}
							</NoteDescription>
						</NoteDescriptionWrapper>
					</>
				) : (
					<div className="row">
						<WarningTextNote className="col-md-11">
							<span>Important</span> : the notes added to the application are
							intended to help you keep track of the process with this
							candidate. The content of these notes will never be displayed to
							the applicant.
						</WarningTextNote>
						<div className="col-md-11">
							<Field
								name="mention"
								component={MentionsInput}
								maxLength={TEXTAREA_MAX_LENGTH}
								data={mentionData}
							/>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
}

const mapDispatchToProps = dispatch => ({
	addNotePokeAction: payload => dispatch(addNotePoke(payload)),
	addNoteBid: payload => dispatch(addNoteBid(payload)),
	fetchBid: id => dispatch(fetchBid(id))
});

const validate = formProps => {
	const errors = {};
	if (!formProps.mention || !formProps.mention.value) {
		errors.mention = "Please fill out this field.";
	}
	return errors;
};

PokeNoteModal.propTypes = {
	pokeId: PropTypes.string,
	addNotePokeAction: PropTypes.func,
	onClose: PropTypes.func
};

export default compose(
	connect(null, mapDispatchToProps),
	reduxForm({
		form: "addNote-poke",
		validate,
		touchOnBlur: false
	})
)(PokeNoteModal);
