/* eslint-disable no-prototype-builtins */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";

import { Link } from "react-router";
import { reduxForm, Field, setSubmitFailed, change } from "redux-form";
import _get from "lodash/get";

import FormField from "common/FormField";
import { createToken } from "../actions/authActions";
import { Button } from "common/styled/buttons";
import { emailRegex, FIRST_LOGIN, FREELANCER } from "config";
import { searchParser } from "config/helpers";
import { getRecoverPasswordRoute } from "./Authentication/recover/RecoverPassword";

class LoginForm extends Component {
	componentDidUpdate(prevProps) {
		if (
			_get(this.props, "newEmail") &&
			_get(prevProps.newEmail) !== _get(this.props, "newEmail")
		) {
			this.props.dispatch(change("signin", "email", this.props.newEmail));
		}
	}

	createToken = formProps => {
		const searchParams = searchParser();
		if (
			!formProps.hasOwnProperty("email") ||
			!formProps.hasOwnProperty("password")
		) {
			return this.props.dispatch(
				setSubmitFailed("signin", "email", "password")
			);
		}
		if (this.props.type === FREELANCER) {
			this.props.showModal(FIRST_LOGIN);
		}
		return this.props.dispatch(
			createToken(
				formProps,
				this.props.shouldRedirect,
				this.props.redirectTo,
				null,
				searchParams
			)
		);
	};

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	render() {
		const { handleSubmit, styleGreen } = this.props;
		return (
			<form className="login-form" onSubmit={handleSubmit(this.createToken)}>
				{styleGreen ? (
					<>
						<div className="formFieldStyle">
							<Field
								name="email"
								label="Email"
								normalize={this.normalizeEmail}
								type="text"
								component={FormField}
							/>
						</div>
						<div className="formFieldStyle">
							<Field
								name="password"
								label="Password"
								type="password"
								component={FormField}
							/>
						</div>
					</>
				) : (
					<>
						<div className="fields-box">
							<Field
								name="email"
								label="Email"
								normalize={this.normalizeEmail}
								type="text"
								component={FormField}
							/>
						</div>
						<div className="fields-box">
							<Field
								name="password"
								label="PassWord"
								type="password"
								component={FormField}
							/>
						</div>
					</>
				)}

				<div className="actions">
					<Button color={styleGreen ? "greenButton" : "primary"} block>
						{styleGreen ? "Log in & Apply" : "Login"}
					</Button>
					<Link
						to={getRecoverPasswordRoute()}
						className={styleGreen ? "forgot-pass" : "signin-forgot-password"}
					>
						Forgot password?
					</Link>
				</div>
			</form>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Please enter your email.";
	} else if (!emailRegex.test(formProps.email)) {
		errors.email = "Please enter a valid email.";
	}
	if (!formProps.password || formProps.password.trim() === "") {
		errors.password = "Please enter your password.";
	}
	return errors;
};

LoginForm.defaultProps = {
	shouldRedirect: true
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			change
		},
		dispatch
	);

export default compose(
	connect(null, mapDispatchToProps),
	reduxForm({
		form: "signin",
		validate,
		touchOnBlur: false
	})
)(LoginForm);
