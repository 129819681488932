import React, { useMemo } from "react";
import s from "./rest-fields.module.scss";
import AddressComposer from "common/AddressComposer";
import Tag from "../../../view-supplier/Tag";
import _map from "lodash/map";

function RestFieldsData({ data }) {
	const expertise = useMemo(() => {
		if (!data) return [];
		const functions = data?.functions?.map(f => f.parent_sector.name) ?? [];
		const skills = data?.skills?.map(s => s.name) ?? [];
		return [...functions, ...skills];
	}, [data?.functions, data?.skills]);
	return (
		<div className={s.root}>
			<h4>Industries</h4>
			<p>
				{data?.industries?.map(industry => (
					<Tag key={industry._id}>{industry.name}</Tag>
				))}
			</p>
			<h4>Services proposed</h4>
			<p>
				{_map(data?.services, service => (
					<Tag key={service?.value ?? service}>{service?.label ?? service}</Tag>
				))}
			</p>
			<h4>Expertise/Categories covered</h4>
			<p>
				{expertise?.map(e => (
					<Tag key={e}>{e}</Tag>
				))}
			</p>
			<h4>Number of employees</h4>
			<p>
				{data?.size?.from} - {data?.size?.to}
			</p>
			<h4>Headquarters address</h4>
			<div style={{ marginBottom: "2rem" }}>
				<AddressComposer addressData={data} />
			</div>
			<h4>Other offices</h4>
			<p>
				{data?.locations?.offices?.map(office => (
					<Tag key={office.latitude}>
						{office.city ? office.city + "," : ""} {office.country}
					</Tag>
				))}
			</p>
			<h4>Geographic coverage</h4>
			<p>
				{data?.locations?.geo_coverage?.map(office => (
					<Tag key={office.latitude}>
						{office.city ? office.city + "," : ""} {office.country}
					</Tag>
				))}
			</p>
			<h4>Spoken languages</h4>
			<p>
				{data?.languages?.map(l => (
					<Tag variant={"outlined"} key={l._id}>
						{l.name}
					</Tag>
				))}
			</p>
		</div>
	);
}

export default RestFieldsData;
