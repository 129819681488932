import React, { useState } from "react";
import Steps from "./components/Stepper";
import { POKE, INTERVIEW, PREVIEW } from "config";
import { capitalize } from "common/Functions";
import Modal from "common/modal/StepsModal";
import PokeStep from "./components/PokeStep";
import InterviewStep from "./components/InterviewStep";
import ModalTitle from "./components/ModalTitle";
import PreviewStep from "./components/PreviewStep";

const InterviewModal = ({
	vacancies,
	company_name,
	first_name,
	toggleModal,
	changeReduxForm,
	vacancyId,
	user,
	companyType,
	sendInterview,
	enableAdditionalComment,
	attendeesList,
	candidate_first_name,
	fetchAttendees
}) => {
	const [startTime, saveStartTime] = useState(null);
	const [endTime, saveEndTime] = useState(null);
	const [currentStep, setCurrentStep] = useState(0);
	const [pokeStepPassed, setPokeStepPassed] = useState(false);
	const [interviewStepPassed, setInterviewStepPassed] = useState(false);
	const [previewStepPassed, setPreviewStepPassed] = useState(false);
	const [previousDate, setPreviousDate] = useState(null);
	const [interviewFormValues, setInterviewFormValues] = useState({});
	const [pokeFormValues, setPokeFormValues] = useState({});
	const [interviewSkipped, setInterviewSkipped] = useState(false);

	const [additionalComment, setAdditionalComment] = useState("");
	const [interviewDescription, setInterviewDescription] = useState("");
	const [selectedAttendees, setSelectedAttendees] = useState([
		{
			value: user.id,
			label: `${user.first_name} ${user.last_name}`
		}
	]);
	const lastStep = currentStep == 2;
	const firstStep = currentStep == 0;
	const { comment } = pokeFormValues;
	const defaultStatus = "wait";
	const pokeStatus = pokeStepPassed ? "finish" : "process";
	const interviewStatus = !pokeStepPassed
		? defaultStatus
		: !interviewStepPassed
		? "process"
		: interviewSkipped
		? "error"
		: "finish";
	const previewStatus = !interviewStepPassed
		? defaultStatus
		: previewStepPassed
		? "finish"
		: "process";

	const [selectedVacancy, changeSelectedVacancy] = useState("");
	const steps = [
		{
			label: capitalize(POKE),
			status: pokeStatus
		},
		{
			label: capitalize(INTERVIEW),
			status: interviewStatus
		},
		{
			label: capitalize(PREVIEW),
			status: previewStatus
		}
	];

	const submitInterviewData = () => {
		let ApiData;
		const { vacancy } = pokeFormValues;

		ApiData = {
			vacancy_id: vacancy._id,
			additional_comment: enableAdditionalComment && additionalComment,
			interview: {
				...interviewFormValues
			}
		};
		delete ApiData.interview.isCalendarChanged;
		sendInterview(ApiData);
	};

	const handleSubmitModal = values => {
		lastStep ? submitInterviewData() : setCurrentStep(currentStep + 1);
		const { isCalendarChanged } = values;
		switch (currentStep) {
			case 0:
				setPokeStepPassed(true);
				setPokeFormValues(values);
				fetchAttendees(selectedVacancy);
				break;
			case 1:
				setInterviewStepPassed(true);
				!isCalendarChanged && setInterviewSkipped(true);
				!isCalendarChanged && setPreviousDate(null);
				!isCalendarChanged && saveStartTime(null);
				!isCalendarChanged && saveEndTime(null);
				setInterviewFormValues(values);
				break;
			case 2:
				setPreviewStepPassed(true);
				break;
		}
	};
	const handleCloseModal = (toCloseModal = true) => {
		toCloseModal && toggleModal();
		firstStep ? toggleModal() : setCurrentStep(currentStep - 1);
		handleBackStep();
	};

	const handleBackStep = () => {
		switch (currentStep) {
			case 0:
				setPokeStepPassed(false);
				break;
			case 1:
				setInterviewStepPassed(false);
				setPokeStepPassed(false);
				break;
			case 2:
				setPreviewStepPassed(false);
				setInterviewStepPassed(false);
				interviewSkipped && setInterviewSkipped(false);
				break;
		}
	};

	const profile = "test profile";
	const is_edit = "false";
	const is_decline = "false";
	const job_title = "test job";
	const userConsultancy = "";

	const displayStepComponent = () => {
		switch (currentStep) {
			case 0:
				return (
					<PokeStep
						onSubmit={handleSubmitModal}
						handleBackStep={handleCloseModal}
						vacancies={vacancies}
						changeSelectedVacancy={changeSelectedVacancy}
						vacancyId={vacancyId}
						selectedVacancy={selectedVacancy}
						changeReduxForm={changeReduxForm}
						comment={comment}
						enableAdditionalComment={enableAdditionalComment}
						setAdditionalComment={value => setAdditionalComment(value)}
						additionalComment={additionalComment}
					/>
				);
			case 1:
				return (
					<InterviewStep
						profile={profile}
						is_edit={is_edit}
						is_decline={is_decline}
						job_title={job_title}
						userConsultancy={userConsultancy}
						companyType={companyType}
						user={user}
						submitInterviewForm={handleSubmitModal}
						handleBackStep={handleCloseModal}
						previousValues={interviewFormValues}
						previousDate={previousDate}
						setPreviousDate={setPreviousDate}
						saveStartTime={saveStartTime}
						saveEndTime={saveEndTime}
						previousStartTime={startTime}
						previousEndTime={endTime}
						attendeesList={attendeesList}
						setSelectedAttendees={setSelectedAttendees}
						selectedAttendees={selectedAttendees}
						setInterviewDescription={setInterviewDescription}
						interviewDescription={interviewDescription}
					/>
				);
			case 2:
				return (
					<PreviewStep
						additionalComment={additionalComment}
						handleBackStep={handleCloseModal}
						handleSubmitModal={handleSubmitModal}
						interviewSkipped={interviewSkipped}
						selectedVacancy={selectedVacancy}
						company_name={company_name}
						user={first_name}
						user_name={`${user.first_name} ${user.last_name}`}
						candidate_first_name={candidate_first_name}
					/>
				);
		}
	};
	return (
		<Modal
			active={true}
			title={null}
			onClose={handleCloseModal}
			showActions={false}
		>
			<Steps steps={steps} />
			<ModalTitle currentStep={currentStep} />
			{displayStepComponent()}
		</Modal>
	);
};

export default InterviewModal;
