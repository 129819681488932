/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { usePaginatedQuery } from "react-query";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";
import { client } from "lib/api-client";
import { useDebounce } from "react-use";
import isEqual from "lodash/isEqual";
import { TASKS_LIST_FILTER_ID } from "../utils/constant";
import { LIST_TASKS } from "config/api-endpoints";
import { getSearchTasksPayload } from "../utils/helpers";
import { getValidFilters } from "common/FilterDrawer/utils";

const filterData = body => {
	return client(LIST_TASKS, {
		body
	});
};

export const GET_TASKS_LIST_QUERY_KEY = "GET_TASKS_LIST_QUERY_KEY";

const useGetTasksList = () => {
	const {
		setQueryError,
		setIsLoading,
		setIsFetching,
		getState,
		setData
	} = useFilters();

	const {
		query,
		logicalOperator,
		filters,
		sortBy,
		offset,
		filterChangeTrigger,
		limit
	} = getState(TASKS_LIST_FILTER_ID);

	const previousFilters = useRef();
	const [body, setBody] = useState(formatBody());

	const res = usePaginatedQuery(
		[GET_TASKS_LIST_QUERY_KEY, body],
		() => filterData(body),
		{
			onError: e => {
				if (e?.detail?.keywords) {
					setQueryError(true);
				}
			},
			refetchOnWindowFocus: false,
			enabled: !!body.limit
		}
	);

	const { refetch, isFetching, isLoading, isFetched, data } = res;

	useEffect(() => {
		setIsLoading(isLoading);
		setIsFetching(isFetching && !isFetched);
	}, [isLoading, isFetching, refetch, isFetched]);

	useEffect(() => {
		if (data) {
			setData(data);
			setQueryError(false);
		}
	}, [data]);

	useEffect(() => {
		const validFilters = getValidFilters(filters);
		const globalFilter = {
			validFilters,
			sortBy,
			offset,
			logicalOperator,
			limit
		};

		const isFiltersChanged = !isEqual(previousFilters.current, globalFilter);

		if (isFiltersChanged) {
			previousFilters.current = globalFilter;
		}
		if (
			(filterChangeTrigger !== "change" &&
				filterChangeTrigger !== "newFilter" &&
				isFiltersChanged) ||
			filterChangeTrigger === "reset" ||
			filterChangeTrigger === "limitChange"
		) {
			setBody(formatBody());
		}
	}, [filters, sortBy, offset, filterChangeTrigger, logicalOperator, limit]);

	useDebounce(
		() => {
			if (filterChangeTrigger === "change") {
				setBody(formatBody());
			}
		},
		500,
		[filters, query]
	);

	function formatBody() {
		return getSearchTasksPayload({
			filters,
			logicalOperator,
			offset,
			sortBy,
			limit,
			query
		});
	}

	return res;
};

export default useGetTasksList;
