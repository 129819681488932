import { useQuery } from "react-query";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";
const getOpeningReasonsList = () => {
	return client(NEW_FILTER_SETTINGS, {
		body: { list: "custom_fields_manager", tag: "vacancy_opening" }
	});
};

export const GET_OPENING_REASONS_LIST_QUERY_KEY =
	"GET_OPENING_REASONS_LIST_QUERY_KEY";
export const useGetOpeningReasonsList = (options = {}) => {
	const res = useQuery(
		GET_OPENING_REASONS_LIST_QUERY_KEY,
		getOpeningReasonsList,
		{
			refetchOnWindowFocus: false,
			...options
		}
	);
	return res;
};
