import Immutable from "seamless-immutable";
import * as types from "../actions/actionsTypes";
import { PROFILE, ALL } from "../../../config";

const InitialState = Immutable({
	isFetching: false,
	step: PROFILE,
	data: {
		group_id: "",
		title: "",
		category: "",
		subcategory: "",
		seniority: "",
		description: "",
		function: [],
		functions: [],
		languages: [],
		skills: [],
		street: "",
		city: "",
		number: "",
		country: "",
		iso_country: "",
		zip: "",
		latitude: "",
		longitude: "",
		box: "",
		start_date: "",
		end_date: "",
		estimation: 0,
		estimation_type: "",
		project_estimation: "",
		full_time: true,
		part_time: false,
		hours_per_day: "",
		days_per_month: "",
		hourly_budget: "",
		payment_term: ""
	},
	categories: [],
	groups: [],
	subcategories: [],
	functions: [],
	languages: [],
	campaigns_performance: {},
	skills: [],
	list_data: {
		size: 10,
		total: 0,
		offset: 0,
		isPagination: false,
		listJobs: [],
		filterByStatus: ALL
	},
	stopJob: {},
	jobToPublish: null,
	bids_list: [],
	total_bids: null,
	currencies: []
});

export default function jobReducer(state = InitialState, action) {
	const data = state.data.asMutable({ deep: true });
	switch (action.type) {
		case types.TOGGLE_FETCHING:
			return Immutable.merge(state, { isFetching: !state.isFetching });
		case types.JOB_DATA:
			return Immutable.merge(state, { data: action.data });
		case types.SET_CATEGORIES:
			return Immutable.merge(state, { categories: action.categories });
		case types.SET_GROUPS:
			return Immutable.merge(state, { groups: action.groups });
		case types.SET_SUBCATEGORIES:
			return Immutable.merge(state, { subcategories: action.subcategories });
		case types.SET_FUNCTIONS:
			return Immutable.merge(state, { functions: action.functions });
		case types.PUSH_TO_FIELD:
			data[action.field] = [
				...data[action.field],
				{ name: action.item.label, score: 1 }
			];
			return Immutable.merge(state, { data });
		case types.SET_SCORE:
			const item = data[action.field].find(
				item => item._id === action.item._id
			);
			item.score = action.score;
			return Immutable.merge(state, {
				data
			});
		case types.SET_JOB_DATA:
			return Immutable.merge(state, {
				data: { ...action.data }
			});
		case types.RESET_JOB_DATA:
			return Immutable.merge(state, {
				data: { ...InitialState.data },
				functions: [],
				skills: []
			});
		case types.SET_JOB_DATA_FIELD:
			return Immutable.merge(state, {
				data: { ...state.data, [action.field]: action.data }
			});
		case types.SET_SKILLS:
			return Immutable.merge(state, { skills: action.data });
		case types.SET_LANGUAGES:
			return Immutable.merge(state, { languages: action.data });
		case types.FILTER_BY_STATUS:
			return Immutable.merge(state, {
				list_data: {
					...state.list_data,
					filterByStatus: action.status
				}
			});
		case types.SET_LIST_JOBS:
			return Immutable.merge(state, {
				list_data: {
					offset: action.data.offset,
					size: action.data.size,
					total: action.data.total,
					isPagination: action.data.total > action.data.size,
					listJobs: action.data.data,
					filterByStatus: state.list_data.filterByStatus
				}
			});
		case types.UNPUBLISH_JOB:
			return Immutable.merge(state, {
				stopJob: action.data
			});
		case types.PUBLISH_JOB_WARNING:
			return Immutable.merge(state, {
				jobToPublish: action.job
			});
		case types.SET_BIDS_LIST:
			return Immutable.merge(state, {
				bids_list: action.list,
				total_bids: action.total
			});
		case types.SET_LIST_CURRENCIES:
			return Immutable.merge(state, {
				currencies: action.data
			});
		case types.SET_CAMPAIGNS_PERFORMANCE:
			return Immutable.merge(state, {
				campaigns_performance: action.data
			});
		default:
			return state;
	}
}
