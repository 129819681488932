import React, { PureComponent } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import FormField from "common/FormField";
import { Flex, Box } from "rebass";
import get from "lodash/get";
import DatePickerField from "common/DatePickerField.new";
import S3Uploader from "common/S3Uploader";
import {
	DATE_FORMAT,
	TYPE_PDF,
	EUR,
	TYPE_JPEG,
	TYPE_PNG,
	TYPE_PJPEG,
	MAX_CURRENCY_VALUE,
	TIMESHEET_TAGS_STATUS
} from "config";
import toaster from "common/Toaster";
import CurrencyField from "common/NewCurrencyField";
import { Button, OutlineButton } from "common/styled/buttons";
import { FloatItem, Td } from "../TimesheetStyled";
import { utcTimeStamp } from "config/helpers";
import StyledSelect from "common/StyledSelect";
import {
	timesheetTagsSelectSelector,
	timesheetTagsProjectNameSelector,
	featureTimesheetTagsSelector
} from "../../timesheetSelector";
import { resetCurrentDocumentModeEdit } from "../../actions";

class EditExpense extends PureComponent {
	state = {
		uploading: false
	};

	componentDidMount() {
		const { date, description, tag, price } = this.props.item;
		description &&
			this.props.initialize({
				date: window.moment.unix(date),
				description,
				tag,
				price
			});
	}

	handleSubmit = formProps => {
		const {
			currentDocument,
			tagOptions,
			featureTimesheetTags,
			dispatch,
			item,
			isEditHasTag
		} = this.props;
		const payload = { ...formProps };

		const { tag } = item;

		featureTimesheetTags === TIMESHEET_TAGS_STATUS.active &&
			get(tagOptions, "length", 0) > 0 &&
			(payload.tag =
				typeof payload.tag === "string"
					? payload.tag
					: get(payload, "tag.value", ""));
		if (
			item.date !==
			utcTimeStamp({
				date: payload.date,
				format: DATE_FORMAT,
				manageTime: false
			})
		) {
			const current = window.moment();

			payload.date.set({
				hour: current.get("hour"),
				minute: current.get("minute"),
				second: current.get("second")
			});
		}

		payload.date = utcTimeStamp({
			date: payload.date,
			format: DATE_FORMAT,
			manageTime: false
		});

		payload.document = currentDocument;
		payload.expense_id = this.props.id;
		if (tag && isEditHasTag && !payload.tag) {
			const errors = {};
			return new Promise((_, reject) => {
				errors.tag = "Tag field can't be empty";
				reject(new SubmissionError(errors));
			});
		}
		this.props.onUpdateExpense(payload);
		dispatch(resetCurrentDocumentModeEdit());
	};

	progressComponent = data => {
		if (data) {
			return (
				<div className="file-progress-box">
					<div className="file-progress-bar">
						<div
							className="file-sr-only"
							style={{ width: `${data.percentCompleted}%` }}
						/>
					</div>
				</div>
			);
		}
	};

	uploadDone = data => {
		this.setState({ uploading: false });
		this.props.onUploadDone(data);
	};

	uploadError = error => {
		toaster.danger(error);
	};

	render() {
		const {
			month,
			disabled,
			currentDocument,
			projectName,
			tagOptions,
			tagOverlayText,
			getInitialVisibleMonth,
			cancelEdit,
			isClient,
			dirty,
			dispatch,
			hasTag,
			canApproveTags,
			isEditHasTag,
			isLastItem,
			reportingSettings
		} = this.props;
		const filled = currentDocument;

		const isApply = [currentDocument, dirty].some(i => i);

		return (
			<React.Fragment>
				<Td>
					<FloatItem>
						<Field
							classes="inner-icon left-icon"
							disabled={disabled}
							icon="icon-calendar"
							name="date"
							placeholder="Date"
							component={DatePickerField}
							minDate={window.moment.unix(month.start_date).toDate()}
							maxDate={window.moment.unix(month.end_date).toDate()}
							initialVisibleMonth={getInitialVisibleMonth}
						/>
					</FloatItem>
				</Td>
				<Td>
					<FloatItem>
						<Field
							disabled={disabled}
							type="text"
							placeholder="Description"
							name="description"
							position="bottom"
							component={FormField}
							autoComplete="off"
						/>
					</FloatItem>
				</Td>
				{isEditHasTag ? (
					<Td>
						<FloatItem width={"100%"}>
							<Field
								placeholder={projectName || "TAG"}
								name="tag"
								options={
									isClient
										? canApproveTags.length > 0
											? canApproveTags
											: tagOptions
										: tagOptions
								}
								disabled={disabled}
								position="bottom"
								toolTipOverlay={tagOverlayText}
								component={StyledSelect}
								menuPosition={isLastItem ? "top" : "bottom"}
							/>
						</FloatItem>
					</Td>
				) : hasTag ? (
					<Td></Td>
				) : null}
				<Td verticalAlign="top">
					<Box
						style={{
							padding: "0"
						}}
						className="add-new-box"
					>
						<FloatItem width={"100%"} className="upload-holder">
							<div
								style={{
									width: "100%",
									height: "24px",
									position: "relative"
								}}
								className="form-goup"
							>
								<S3Uploader
									id="document-uploader"
									onStart={() => this.setState({ uploading: true })}
									className={`${filled ? "filled" : ""}`}
									onSuccess={this.uploadDone}
									onError={this.uploadError}
									folder="timesheet"
									chooseButton={false}
									cancelButton={false}
									text={{
										placeholder: "",
										icon: { class: "icon-upload" }
									}}
									multiple={false}
									progressComponent={this.progressComponent}
									autoSubmit={true}
									accept={[TYPE_PDF, TYPE_JPEG, TYPE_PNG, TYPE_PJPEG]}
									disabled={disabled}
								/>
								{filled && <i className="fa fa-check" />}
							</div>
						</FloatItem>
					</Box>
				</Td>

				<Td></Td>
				<Td>
					<FloatItem>
						<Field
							disabled={disabled}
							type="text"
							placeholder="Amount"
							name="price"
							position="bottom"
							suffix={reportingSettings?.currency?.symbol ?? EUR}
							component={CurrencyField}
							editExpense
						/>
					</FloatItem>
				</Td>
				<Td />
				<Td verticalAlign="top" colSpan={2}>
					<Flex sx={{ gap: 2 }}>
						<Button
							color="success"
							small
							onClick={this.props.handleSubmit(this.handleSubmit)}
							key="accept"
							disabled={!isApply}
							style={{ width: "100%" }}
						>
							Apply
						</Button>
						<OutlineButton
							onClick={() => {
								dispatch(resetCurrentDocumentModeEdit());
								cancelEdit();
							}}
							style={{ width: "100%" }}
							small
						>
							cancel
						</OutlineButton>
					</Flex>
				</Td>
			</React.Fragment>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.date) {
		errors.date = "Field can't be empty";
	}
	if (!formProps.description || formProps.description.trim() === "") {
		errors.description = "Field can't be empty";
	}
	if (!(formProps.price >= 0)) {
		errors.price = "Field can't be empty";
	}
	if (
		formProps.price >= 0 &&
		(formProps.price < 0 || formProps.price >= MAX_CURRENCY_VALUE)
	) {
		errors.price = "The price should be between 1 and 1000€";
	}
	return errors;
};

const mapStateToProps = state => {
	return {
		editItem: state.timesheet.editItem,
		featureTimesheetTags: featureTimesheetTagsSelector(state),
		tagOptions: timesheetTagsSelectSelector(state),
		projectName: timesheetTagsProjectNameSelector(state),
		initialValues: {
			date: state.timesheet.editItem
				? window.moment.unix(state.timesheet.editItem.date).format(DATE_FORMAT)
				: "",
			description: state.timesheet.editItem
				? state.timesheet.editItem.description
				: "",
			price: state.timesheet.editItem ? state.timesheet.editItem.price : "",
			document: state.timesheet.editItem
				? state.timesheet.editItem.document
				: ""
		}
	};
};

export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: "edit-expense",
		validate,
		touchOnBlur: false
	})
)(EditExpense);
