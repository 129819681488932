import { VACANCY_STEP_CARDS_IDS } from "modules/vacancy/create-vacancy/utils/constants";
import React, { useState, useEffect, useRef } from "react";
import styles from "./vacancy-step-menu.module.scss";
import useLayoutElements from "hooks/useLayoutElements";
import cx from "classnames";
import { getUrlHash } from "config/helpers";
import { useInView } from "react-intersection-observer";

const LINKS = [
	{ id: VACANCY_STEP_CARDS_IDS.description, label: "Description" },
	{ id: VACANCY_STEP_CARDS_IDS.applicants, label: "Applicants" },
	{ id: VACANCY_STEP_CARDS_IDS.salary, label: "Salary" },
	{ id: VACANCY_STEP_CARDS_IDS.additionalInfo, label: "Additional info" }
];

const THRESHOLD_LIST = [0.25, 0.5, 0.75, 1];

const VacancyStepMenu = () => {
	const linksContainerRef = useRef();
	const actifLinkRef = useRef();
	const [actifBarStyle, setActifBarStyle] = useState({});
	const [actifLinkId, setActifLinkId] = useState();

	const { ref: descriptionCardRef, entry: descriptionEntry } = useInView({
		threshold: THRESHOLD_LIST
	});

	let descriptionRatio = descriptionEntry?.intersectionRatio;

	const { ref: applicantsCardRef, entry: applicantsEntry } = useInView({
		threshold: THRESHOLD_LIST
	});

	let applicantsRatio = applicantsEntry?.intersectionRatio;

	const { ref: salaryCardRef, entry: salaryEntry } = useInView({
		threshold: THRESHOLD_LIST
	});

	let salaryRatio = salaryEntry?.intersectionRatio;

	const { ref: additionalInfoCardRef, entry: additionalInfoEntry } = useInView({
		threshold: THRESHOLD_LIST
	});

	let additionalInfoRatio = additionalInfoEntry?.intersectionRatio;

	const {
		header: headerElement,
		scrollarea: scrollareaElement
	} = useLayoutElements();

	useEffect(() => {
		const cardId = getUrlHash();

		if (cardId && cardId !== VACANCY_STEP_CARDS_IDS.description) {
			setTimeout(() => {
				scrollToCard(cardId);
			}, 100);
		} else {
			window.scroll({
				top: 0,
				behavior: "smooth"
			});
		}

		descriptionCardRef(
			document.getElementById(VACANCY_STEP_CARDS_IDS.description)
		);

		salaryCardRef(document.getElementById(VACANCY_STEP_CARDS_IDS.salary));

		applicantsCardRef(
			document.getElementById(VACANCY_STEP_CARDS_IDS.applicants)
		);

		additionalInfoCardRef(
			document.getElementById(VACANCY_STEP_CARDS_IDS.additionalInfo)
		);
	}, []);

	useEffect(() => {
		if (!actifLinkRef.current) return;
		const linksContainer = linksContainerRef.current;
		const link = actifLinkRef.current;

		const style = {
			//The height of actif bar should be equal to the the position of actif link inside its container
			height: link.offsetTop + link.offsetHeight - linksContainer.offsetTop
		};

		setActifBarStyle(style);
	}, [actifLinkId]);

	useEffect(() => {
		let newActifLinkId = VACANCY_STEP_CARDS_IDS.description;
		let maxRatio = descriptionRatio;

		if (applicantsRatio > maxRatio) {
			newActifLinkId = VACANCY_STEP_CARDS_IDS.applicants;
			maxRatio = applicantsRatio;
		}

		if (salaryRatio > maxRatio) {
			newActifLinkId = VACANCY_STEP_CARDS_IDS.salary;
			maxRatio = salaryRatio;
		}

		if (additionalInfoRatio > maxRatio) {
			newActifLinkId = VACANCY_STEP_CARDS_IDS.additionalInfo;
			maxRatio = additionalInfoRatio;
		}

		if (newActifLinkId !== actifLinkId) {
			setActifLinkId(newActifLinkId);
		}
	}, [descriptionRatio, applicantsRatio, salaryRatio, additionalInfoRatio]);

	const onLinkClick = (e, id) => {
		e.preventDefault();
		scrollToCard(id);
		history.pushState(null, null, `#${id}`);
	};

	const scrollToCard = id => {
		var { top } = document.getElementById(id).getBoundingClientRect();

		scrollareaElement.scrollBy({
			top: top - (headerElement.offsetHeight + 20),
			behavior: "smooth"
		});
	};

	const actifLinkIndex = LINKS.findIndex(({ id }) => id === actifLinkId);

	return (
		<div className={styles.container}>
			<div className={styles.menu}>
				<div className={styles.inactifBar}>
					<div style={actifBarStyle} className={styles.actifBar} />
				</div>
				<div ref={linksContainerRef} className={styles.itemsList}>
					{LINKS.map(({ id, label }, index) => {
						const isActif = actifLinkIndex === index;
						const greenLink = index <= actifLinkIndex;

						const classes = cx(styles.link, {
							[styles.greenLink]: greenLink
						});

						return (
							<button
								key={id}
								ref={isActif ? actifLinkRef : null}
								onClick={e => onLinkClick(e, id)}
								className={classes}
							>
								{label}
							</button>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default VacancyStepMenu;
