import React from "react";
import style from "./missions-list-table-empty-state.module.scss";
import { ReactComponent as EmptyIcon } from "static/icons/emptyState.svg";
import { useFilters } from "common/FilterDrawer/hooks/useFilter";

const MissionsListTableEmptyState = () => {
	const { clearFilters } = useFilters();
	return (
		<div className={style.emptyState}>
			<EmptyIcon />
			<p className={style.textGras}>No results found</p>
			<p className={style.textNormal}>
				No missions matching your search criteria.
			</p>
			<button className={style.btnClear} onClick={clearFilters}>
				Clear filters
			</button>
		</div>
	);
};
export default MissionsListTableEmptyState;
