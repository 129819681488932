import { client } from "lib/api-client";
import { useQuery } from "react-query";
import { MISSION_EXTENSIONS } from "config/api-endpoints";

export const MISSION_EXTENSIONS_QUERY_KEY = "MISSION_EXTENSIONS_QUERY_KEY";

const getMissionExtension = contract_id =>
	client(MISSION_EXTENSIONS, {
		body: { contract_id }
	});

const useGetMissionExtensions = contract_id => {
	return useQuery(
		[MISSION_EXTENSIONS_QUERY_KEY, { contract_id }],
		() => getMissionExtension(contract_id),
		{
			refetchOnWindowFocus: false,
			enabled: !!contract_id
		}
	);
};

export default useGetMissionExtensions;
