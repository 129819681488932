import { LIST_NOTIFICATIONS } from "config/api-endpoints";
import { client } from "lib/api-client";
import { useQuery } from "react-query";
import get from "lodash/get";

const getMissionNootifications = (_, body) =>
	client(LIST_NOTIFICATIONS, {
		body: {
			...body
		}
	});

export const GET_MISSION_NOTIFICATIONS_KEY = "GET_MISSION_NOTIFICATIONS_KEY";

const useGetMissionNotifications = (body, options = {}) => {
	const result = useQuery(
		[GET_MISSION_NOTIFICATIONS_KEY, body],
		getMissionNootifications,
		{
			...options,
			retry: 0,
			refetchOnWindowFocus: false
		}
	);
	return {
		...result,
		total: get(result, "[0].pagination.total", 0)
	};
};

export default useGetMissionNotifications;
