import React, { useEffect } from "react";
import Portal from "common/Portal";
import AddShortListModal from "modules/candidate/components/Modals/AddShortListModal";
import { connect } from "react-redux";
import { VACANCY_POKE } from "config";
import { filterSettingsSelectors } from "modules/app/selectors";
import { getFilterSettings } from "modules/app/actions/ListsActions";

const ShortlistModal = ({
	vacancyId = "",
	profileID,
	nextAction,
	onClose,
	displayShortlistModal,
	getFilterSettings,
	settings: { vacancies }
}) => {
	const fetchVacancies = () => {
		getFilterSettings({
			tag: VACANCY_POKE,
			profile_id: profileID
		});
	};

	useEffect(() => {
		if (displayShortlistModal) {
			fetchVacancies();
		}
	}, [displayShortlistModal]);

	return (
		displayShortlistModal && (
			<Portal>
				<AddShortListModal
					onClose={onClose}
					vacancyId={vacancyId}
					profile_id={profileID}
					vacancies={vacancies}
					nextAction={nextAction}
				/>
			</Portal>
		)
	);
};

const mapStateToProps = state => {
	return {
		settings: filterSettingsSelectors(state)
	};
};
const mapDispatchToProps = dispatch => {
	return {
		getFilterSettings: payload => dispatch(getFilterSettings(payload))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistModal);
