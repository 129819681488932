import React from "react";
import { CONSULTANCY } from "config";
import BidMaker from "common/BidMaker";

export const ApplyJobButton = ({ userAccountStatus, job }) => {
	const isConsultancy = job?.account_type === CONSULTANCY;

	return isConsultancy || job?.profile_completed ? (
		<BidMaker
			id={job._id}
			bid_id={job.bid_id}
			canMakeBid={isConsultancy || job.can_make_bid.can_bid}
			hasRequiredMustHave={job.can_make_bid.must_have}
			isConsultancy={isConsultancy}
			userAccountStatus={userAccountStatus}
		/>
	) : (
		<></>
	);
};

export default ApplyJobButton;
