/* eslint-disable */
import React, { useEffect, useState } from "react";
import RequestViewStage from "../RequestViewStage";
import styles from "./request-view-kanban.module.scss";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { DROPPABLE_TYPE_CARD, DROPPABLE_TYPE_STAGE } from "../../constants";
import { ReactComponent as NewStep } from "static/icons/add-icon-grey.svg";
import {
	useTriggers,
	useCanDragCard,
	useReorderCards,
	usePreparePayload,
	useKanbanStyles
} from "../../hooks/useKanbanHelpers";
import {
	canDragStage,
	invalidateStagesDataQuery,
	reorderStages
} from "../../helpers";
import { REQUEST_VIEW_KANBAN_SETTINGS_QUERY } from "../../hooks/useTempKanbanSettingsApi";
import useRequestViewKanbanStore from "../../store/useRequestViewKanbanStore";
import useTempKanbanMoveElement from "../../hooks/useTempKanbanMoveElement";
import BodyClassName from "react-body-classname";

import { queryCache } from "react-query";
import get from "lodash/get";
import useShowAddNewPipelineBidStepDrawer from "modules/RequestView/hooks/useShowAddNewPipelineBidStepDrawer";
import {
	DRAWER_PROPOSE_CONTRACT,
	DRAWER_REJECT_APPLICATION
} from "modules/RequestView/components/drawers/constants";
import { useTempDrawerHelpers } from "modules/RequestView/components/drawers/hooks/useTempDrawerHelpers";
import useRequestViewDrawerStore from "modules/RequestView/components/drawers/store/useRequestViewDrawerStore";
import { BidDetailsDrawer } from "modules/RequestView/components/BidDetailsDrawer";
import loadable from "loadable-components";
import { getParamFromUrl } from "config/helpers";
import useOpenBidDetailsDrawer from "modules/RequestView/components/BidDetailsDrawer/hooks/useOpenBidDetailsDrawer";

const AddNewStepPipelineDrawer = loadable(() =>
	import(
		"modules/RequestView/components/AddNewStepPipelineDrawer/AddNewStepPipelineDrawer"
	)
);

const ProposeInterviewToFreelancerDrawer = loadable(() =>
	import(
		"modules/RequestView/components/ProposeInterviewToFreelancerDrawer/ProposeInterviewToFreelancerDrawer"
	)
);

const ProposeTempContractDrawer = loadable(() =>
	import(
		"modules/RequestView/components/drawers/components/ProposeTempContractDrawer/index"
	)
);

const RejectTempApplicationDrawer = loadable(() =>
	import(
		"modules/RequestView/components/drawers/components/RejectTempApplicationDrawer/index"
	)
);

const StartMissionConfirmModal = loadable(() =>
	import(
		"modules/RequestView/components/drawers/components/StartMissionConfirmModal"
	)
);

export default function RequestViewKanban() {
	let { pathname } = window.location;
	const jobId = pathname.split("/")[pathname.split("/").length - 1];
	const { open, setBidId, setOpen } = useOpenBidDetailsDrawer();
	const [placeholderProps, setPlaceholderProps] = useState({});

	const { kanbanStageStyles } = useKanbanStyles({ showBanner: false });
	const [moveElementMutation] = useTempKanbanMoveElement();

	const {
		kanbanStages,
		setKanbanStages,
		cleanKanbanStore
	} = useRequestViewKanbanStore();

	// cleanup store on unmount
	useEffect(() => {
		const bidId = getParamFromUrl("bid_id");
		setBidId(bidId || null);
		setOpen(!!bidId);
		return () => cleanKanbanStore();
	}, []);

	const {
		show: showAddNewStepDrawer,
		setShowAddNewStepDrawer
	} = useShowAddNewPipelineBidStepDrawer();

	const {
		startMissionModal,
		toggleStartMissionModal,
		setStartMissionModalPayload
	} = useRequestViewDrawerStore();

	const [
		showProposeTempInterviewDrawer,
		setShowProposeTempInterviewDrawer
	] = useState(false);

	const { isOpen: isOpenRejectApplication } = useTempDrawerHelpers({
		drawerType: DRAWER_REJECT_APPLICATION
	});
	const { isOpen: isOpenProposeContract } = useTempDrawerHelpers({
		drawerType: DRAWER_PROPOSE_CONTRACT
	});

	const { canDragCard, getStartMissionInfo } = useCanDragCard();
	const { handleTriggers } = useTriggers({
		onInterviewDrop: () => setShowProposeTempInterviewDrawer(true)
	});
	const { reorderCards } = useReorderCards();
	const { preparePayload } = usePreparePayload();

	const cleanDraggedCardPlaceholder = () => setPlaceholderProps({});

	const onDragEnd = result => {
		if (!result.destination) return;

		const isDragCard = get(result, "type") === DROPPABLE_TYPE_CARD;
		const isDragStage = get(result, "type") === DROPPABLE_TYPE_STAGE;

		const sourceIndex = get(result, "source.index", 0);
		const destinationIndex = get(result, "destination.index", 0);
		const sourceDroppableId = get(result, "source.droppableId", "");
		const destinationDroppableId = get(result, "destination.droppableId", "");
		const isSamePosition =
			sourceDroppableId === destinationDroppableId &&
			sourceIndex === destinationIndex;

		if (isDragCard && !isSamePosition) {
			cleanDraggedCardPlaceholder();

			const canDrag = canDragCard({
				startIndex: sourceIndex,
				endIndex: destinationIndex,
				oldStage: sourceDroppableId,
				newStage: destinationDroppableId
			});

			const startMissionInfo = getStartMissionInfo({
				startIndex: sourceIndex,
				oldStage: sourceDroppableId,
				newStage: destinationDroppableId
			});

			if (canDrag) {
				const reorderElementPayload = {
					startIndex: sourceIndex,
					endIndex: destinationIndex,
					oldStage: sourceDroppableId,
					newStage: destinationDroppableId
				};

				// setup payload
				const payload = preparePayload({
					jobId,
					tag: "card",
					...reorderElementPayload
				});

				// reorder cards
				reorderCards(reorderElementPayload);

				if (!startMissionInfo.isSigned && startMissionInfo.nextStageIsMission) {
					setStartMissionModalPayload({
						jobId,
						reorderElementPayload,
						moveElementPayload: payload,
						contractId: startMissionInfo.contractId,
						stageIds: [sourceDroppableId, destinationDroppableId]
					});
					toggleStartMissionModal(true);
				} else {
					moveElementMutation(payload, {
						onSuccess: () => {
							invalidateStagesDataQuery({
								jobId,
								kanbanStages,
								invalidateStagesIds: [sourceDroppableId, destinationDroppableId]
							});
						},
						onError: () => {
							// undo card reordering
							reorderCards({
								startIndex: reorderElementPayload.endIndex,
								endIndex: reorderElementPayload.startIndex,
								oldStage: reorderElementPayload.newStage,
								newStage: reorderElementPayload.oldStage
							});
						}
					});
				}

				// fire triggers
				if (sourceDroppableId !== destinationDroppableId) {
					handleTriggers({
						newStage: destinationDroppableId,
						endIndex: destinationIndex,
						jobId
					});
				}
			}
		}

		if (isDragStage && !isSamePosition) {
			const canDrag = canDragStage({
				kanbanStages,
				startIndex: sourceIndex,
				endIndex: destinationIndex
			});
			if (canDrag) {
				// setup payload
				const payload = preparePayload({
					jobId,
					tag: "step",
					startIndex: sourceIndex,
					endIndex: destinationIndex
				});

				// reorder stages
				const updatedKanbanStages = reorderStages({
					kanbanStages,
					startIndex: sourceIndex,
					endIndex: destinationIndex
				});
				setKanbanStages(updatedKanbanStages);

				moveElementMutation(payload, {
					onSettled: () => {
						queryCache.invalidateQueries([
							REQUEST_VIEW_KANBAN_SETTINGS_QUERY,
							{ job_id: jobId }
						]);
					}
				});
			}
		}
	};

	const onDragStart = result => {
		const isDragCard = get(result, "type") === DROPPABLE_TYPE_CARD;
		if (isDragCard) cleanDraggedCardPlaceholder();
	};

	const onDragUpdate = result => {
		const isDragCard = get(result, "type") === DROPPABLE_TYPE_CARD;
		const sourceIndex = get(result, "source.index", 0);
		const destinationIndex = get(result, "destination.index", 0);
		const sourceDroppableId = get(result, "source.droppableId", "");
		const destinationDroppableId = get(result, "destination.droppableId", "");

		if (isDragCard) {
			if (!result.destination) {
				cleanDraggedCardPlaceholder();
			} else {
				const sourceStageContainer = document.getElementById(sourceDroppableId);
				const destinationStageContainer = document.getElementById(
					destinationDroppableId
				);

				const sourceStageItems = [...sourceStageContainer.children];
				const destinationStageItems = [...destinationStageContainer.children];
				const movedCard = sourceStageItems[sourceIndex];
				const { clientHeight, clientWidth } = movedCard;

				let clientY = 0;
				const paddingTop = parseFloat(
					window.getComputedStyle(sourceStageContainer).paddingTop
				);
				const paddingLeft = parseFloat(
					window.getComputedStyle(sourceStageContainer).paddingLeft
				);

				// same stage
				if (destinationDroppableId === sourceDroppableId) {
					sourceStageItems.splice(sourceIndex, 1);
					const sourceStageItemsUpdated = [
						...sourceStageItems.slice(0, destinationIndex),
						movedCard,
						...sourceStageItems.slice(destinationIndex + 1)
					];

					clientY =
						paddingTop +
						sourceStageItemsUpdated
							.slice(0, destinationIndex)
							.reduce((total, curr) => {
								return total + curr.clientHeight;
							}, 0);
				} else {
					// different stage
					clientY =
						paddingTop +
						destinationStageItems
							.slice(0, destinationIndex)
							.reduce((total, curr) => {
								return total + curr.clientHeight;
							}, 0);
				}

				setPlaceholderProps({
					clientHeight,
					clientWidth,
					clientY: clientY + 6,
					clientX: paddingLeft,
					clientHeight: clientHeight - 12
				});
			}
		}
	};

	return (
		<>
			<BodyClassName className="request-view-kanban">
				<div className={styles.root}>
					<DragDropContext
						onDragEnd={onDragEnd}
						onDragStart={onDragStart}
						onDragUpdate={onDragUpdate}
					>
						<Droppable
							direction={"horizontal"}
							type={DROPPABLE_TYPE_STAGE}
							droppableId={"REQUEST_VIEW_KANBAN"}
						>
							{provided => {
								return (
									<div className={styles.container}>
										<div
											ref={provided.innerRef}
											{...provided.droppableProps}
											className={styles.kanbanContainer}
										>
											{kanbanStages.map((stage, index) => {
												return (
													<RequestViewStage
														index={index}
														jobId={jobId}
														key={stage._id}
														stageConfig={stage}
														styles={kanbanStageStyles}
														placeholderProps={placeholderProps}
													/>
												);
											})}

											{provided.placeholder}
										</div>
									</div>
								);
							}}
						</Droppable>
					</DragDropContext>
					{!!kanbanStages.length && (
						<div className={styles.addNewStep}>
							<button
								className={styles.addNewStepCta}
								onClick={() => setShowAddNewStepDrawer(true)}
							>
								<NewStep fill="#334155" height={20} width={20} />
								Add new step
							</button>
						</div>
					)}
				</div>
			</BodyClassName>

			<div>
				{startMissionModal.isOpen && <StartMissionConfirmModal />}
				<ProposeTempContractDrawer isOpen={isOpenProposeContract} />
				<RejectTempApplicationDrawer isOpen={isOpenRejectApplication} />

				{showProposeTempInterviewDrawer && (
					<ProposeInterviewToFreelancerDrawer
						onClose={() => setShowProposeTempInterviewDrawer(false)}
						onSuccess={() => setShowProposeTempInterviewDrawer(false)}
					/>
				)}

				{showAddNewStepDrawer && (
					<AddNewStepPipelineDrawer
						onClose={() => setShowAddNewStepDrawer(false)}
						onAdd={() => setShowAddNewStepDrawer(false)}
					/>
				)}

				{open && <BidDetailsDrawer />}
			</div>
		</>
	);
}
