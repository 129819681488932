import React, { useEffect, useMemo } from "react";
import style from "./styles.module.scss";
import Select from "react-select";
import { Controller } from "react-hook-form";
import _get from "lodash/get";
import _split from "lodash/split";
import TimePicker from "common/TimePicker";
import { TIMESHEET_FREQUENCY_OPTIONS } from "config";

const DAILY = "daily";
const DEFAULT_HOURS = 8;
const DEFAULt_MINUTES = 0;

const FormGroup = ({
	options,
	errors,
	control,
	defaultInput,
	rate,
	reset,
	currencies
}) => {
	useEffect(() => {
		const hours = window.moment({
			hours: getItem("hours") || DEFAULT_HOURS,
			minutes: getItem("minutes") || DEFAULt_MINUTES
		});

		reset({
			rate: getItem("rate"),
			hours,
			currency: getItem("currency"),
			submission: getItem("submission")
		});
	}, [defaultInput]);

	const getItem = value => {
		switch (value) {
			case "hours":
				return _get(defaultInput, "settings.rate_type") === DAILY ||
					_get(defaultInput, "rate_type") === DAILY
					? _split(
							_get(defaultInput, "settings.hours_representation") ||
								_get(defaultInput, "hours_representation"),
							":"
					  )[0]
					: "";

			case "minutes":
				return _get(defaultInput, "settings.rate_type") === DAILY ||
					_get(defaultInput, "rate_type") === DAILY
					? _split(
							_get(defaultInput, "settings.hours_representation") ||
								_get(defaultInput, "hours_representation"),
							":"
					  ).pop()
					: "";

			case "rate":
				return options.filter(
					el =>
						el.value ===
						(_get(defaultInput, "settings.rate_type") ||
							_get(defaultInput, "rate_type"))
				)[0];
			case "currency": {
				const currency = currencies.find(
					c => c._id === defaultInput?.settings?.currency?._id
				);
				return {
					label: `${currency?.symbol} ${currency?.name}`,
					value: currency?._id
				};
			}
			case "submission":
				return (
					_get(defaultInput, "settings.allow_overtime") ||
					_get(defaultInput, "allow_overtime")
				);
			case "timesheet_frequency": {
				const option = TIMESHEET_FREQUENCY_OPTIONS.find(
					o => o.value === defaultInput?.settings?.timesheet_frequency
				);
				return option;
			}
			default:
				break;
		}
	};

	const currenciesOptions = useMemo(() => {
		return currencies.map(c => ({
			value: c._id,
			label: `${c.symbol} ${c.name}`
		}));
	}, [currencies]);

	return (
		<>
			<div className={style.formGroup}>
				<div className={style.selectBox}>
					<label className="select-label">Daily or Hourly rate</label>
					<Controller
						control={control}
						name="rate"
						defaultValue={getItem("rate")}
						render={({ field: { onChange, value, name } }) => (
							<Select
								options={options}
								clearable={true}
								onChange={onChange}
								value={value}
								name={name}
								isSearchable={false}
							/>
						)}
					/>
					<span className={style.error}>
						{_get(errors, "rate.message") && "Please choose a rate type"}
					</span>
				</div>
				{_get(rate, "value") === DAILY && (
					<div className={style.hours}>
						<label className="select-label">Hours per day</label>
						<div>
							<Controller
								control={control}
								name="hours"
								defaultValue={window.moment({
									hours: getItem("hours") || DEFAULT_HOURS,
									minutes: getItem("minutes") || DEFAULt_MINUTES
								})}
								render={({ field: { onChange, value, name } }) => (
									<TimePicker onChange={onChange} value={value} name={name} />
								)}
							/>
						</div>
						<span className={style.error}>
							{(_get(errors, "hours.message") ||
								_get(errors, "minutes.message")) &&
								"You must type a valid hour"}
						</span>
					</div>
				)}
				<div className={style.selectBox}>
					<label className="select-label">Timesheet frequency</label>
					<Controller
						control={control}
						name="timesheet_frequency"
						defaultValue={getItem("timesheet_frequency")}
						render={({ field: { onChange, value, name } }) => (
							<Select
								options={TIMESHEET_FREQUENCY_OPTIONS}
								clearable={false}
								onChange={onChange}
								value={value}
								name={name}
							/>
						)}
					/>
				</div>
				{!!currencies?.length && (
					<div className={style.selectBox}>
						<label className="select-label">Currency</label>
						<Controller
							control={control}
							name="currency"
							defaultValue={getItem("currency")}
							render={({ field: { onChange, value, name } }) => (
								<Select
									options={currenciesOptions}
									clearable={false}
									onChange={onChange}
									value={value}
									name={name}
									isSearchable={true}
								/>
							)}
						/>
					</div>
				)}
			</div>
			{_get(rate, "value") === DAILY && (
				<div>
					<Controller
						control={control}
						name="submission"
						defaultValue={getItem("submission")}
						render={({ field: { onChange, value, name } }) => (
							<div className="checkbox checkbox-primary">
								<input
									type="checkbox"
									name={name}
									id="allow-submission"
									value={value}
									checked={value}
									onChange={({ target: { checked } }) => onChange(checked)}
									className="styled"
								/>
								<label htmlFor="allow-submission">
									{" "}
									Allow submission of overtime (hourly input), on top of the
									amount of hours per day.
								</label>
							</div>
						)}
					/>
				</div>
			)}
		</>
	);
};

export default FormGroup;
