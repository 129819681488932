import React, { useMemo, useState } from "react";
import { Flex, Box, Text } from "rebass";
import { Input } from "@rebass/forms";
import Modal from "common/modal/Modal";
import useAddVatNumber from "modules/bids/components/modals/hooks/useAddVatNumber";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toaster from "common/Toaster";
import { client } from "lib/api-client";
import { useDispatch } from "react-redux";
import { getMyProfile } from "modules/user/actions/authActions";
import FilesUploader from "modules/bids/components/createbid/FilesUploader";
import Scrollbars from "react-custom-scrollbars";
import { REQUIRED_DOCUMENTS } from "config/api-endpoints";
import { getFileName } from "common/Functions";
import useSubmitTimesheet from "modules/timesheets/hooks/useSubmitTimesheet";
import get from "lodash/get";

const uploadFile = (timesheet_id, file) =>
	client(REQUIRED_DOCUMENTS, {
		body: {
			action: "upload",
			timesheet_id,
			files: {
				_id: file._id,
				name: getFileName(file.name),
				path: file.path
			}
		}
	});

const AddVatNumberModal = ({
	timeSheetId,
	onClose,
	toggleAddVatModal,
	requiredFiles,
	companyVat,
	loadTimeSheet,
	...props
}) => {
	const [files, setFiles] = useState(requiredFiles);
	const [addVatNumber, { isLoading }] = useAddVatNumber();
	const [hasRequired, setHasRequired] = useState(false);
	const [vatError, setVatError] = useState("");
	const dispatch = useDispatch();
	const [
		onSubmitTimesheet,
		{ isLoading: isLoadingSubmit }
	] = useSubmitTimesheet();

	const onSavingSuccess = () => {
		loadTimeSheet();
		toaster.success(`Status: Your file item was saved successfully`);
	};

	const onSavingError = () => {
		toaster.danger(`Status: an error occured while saving your file`);
	};

	const {
		handleSubmit,
		control,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(
			yup.object().shape({
				vat: !companyVat && yup.string().required("VAT number is required")
			})
		)
	});

	const hasEmpty = useMemo(
		() => Array.isArray(files) && files.some(f => !f.path && f.is_required),
		[files]
	);

	const onDoneUploading = file => {
		const fs = [...files];
		const index = fs.findIndex(f => f._id === file._id);
		if (index !== -1) {
			fs[index] = { ...fs[index], ...file };
			setFiles(fs);
			setHasRequired(false);
		}
		uploadFile(timeSheetId, file)
			.then(onSavingSuccess)
			.catch(onSavingError);
	};

	const onFileNameChange = (_id, value) => {
		const fs = [...files];
		const index = files.findIndex(f => f._id === _id);
		if (index !== -1) {
			fs[index].name = value;
			setFiles(fs);
		}
	};

	const onSubmit = values => {
		if (!hasEmpty) {
			if (!companyVat) {
				addVatNumber(
					{ vat: values.vat },
					{
						onSuccess: () => {
							toaster.success("VAT number saved successfully");
							onSubmitTimesheet(
								{
									timesheet_id: timeSheetId
								},
								{
									onSuccess: () => {
										toggleAddVatModal();
										dispatch(getMyProfile());
										loadTimeSheet();
										toaster.success("Timesheet submitted successfully");
									}
								}
							);
						},
						onError: error => {
							let errorMessage = get(error, "detail");

							if (error.status === 422) {
								if (typeof errorMessage === "object") {
									Object.keys(errorMessage).forEach(name => {
										get(errorMessage, name) &&
											setVatError(
												typeof get(errorMessage, name) === "string"
													? get(errorMessage, name)
													: get(errorMessage, name)[0]
											);
									});
								} else {
									toaster.danger(errorMessage);
								}
								return;
							} else {
								toaster.danger("An error has occurred");
							}
						}
					}
				);
			} else {
				onSubmitTimesheet(
					{
						timesheet_id: timeSheetId
					},
					{
						onSuccess: () => {
							toggleAddVatModal();
							dispatch(getMyProfile());
							loadTimeSheet();
							toaster.success("Timesheet submitted successfully");
						}
					}
				);
			}
			setHasRequired(false);
		} else {
			setHasRequired(true);
		}
	};

	return (
		<Modal
			onClose={() => {
				onClose();
				setHasRequired(false);
			}}
			isLoading={isLoading || isLoadingSubmit}
			{...props}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				{files.length > 0 && (
					<Box mt={10} mb={60}>
						<Text
							mb={35}
							as={"h2"}
							sx={{
								fontSize: "37.5px",
								fontWeight: "300",
								letterSpacing: "-0.49px",
								color: "rgb(253, 104, 73)"
							}}
						>
							Required document(s)
						</Text>
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "16px",
								letterSpacing: "-0.21px",
								color: "rgb(80, 92, 104)",
								width: "80%"
							}}
							mb={44}
						>
							The company your applying is requiring that you provide the
							following documents to move to the next stage:
						</Text>
						<Flex mb={15}>
							<Box width={1 / 3} mr={16}>
								<Text
									sx={{
										fontFamily: "Roboto",
										fontSize: "14px",
										fontWeight: "bold",
										letterSpacing: "-0.18px",
										color: "#58626b"
									}}
								>
									DOCUMENT TYPE
								</Text>
							</Box>
							<Box width={1 / 3} mr={16}>
								<Text
									sx={{
										fontFamily: "Roboto",
										fontSize: "14px",
										fontWeight: "bold",
										letterSpacing: "-0.18px",
										color: "#58626b"
									}}
								>
									NAME YOUR FILE
								</Text>
							</Box>
							<Box width={1 / 3}>
								<Text
									sx={{
										fontFamily: "Roboto",
										fontSize: "14px",
										fontWeight: "bold",
										letterSpacing: "-0.18px",
										color: "#58626b"
									}}
								>
									UPLOAD YOUR FILE
								</Text>
							</Box>
						</Flex>
						<Scrollbars
							autoHide
							autoHideTimeout={5000}
							autoHideDuration={200}
							autoHeight
							autoHeightMax={260}
							style={{ zIndex: 1, paddingBottom: "16px" }}
							renderTrackHorizontal={props => (
								<div
									{...props}
									style={{ display: "none" }}
									className="track-horizontal"
								/>
							)}
							renderView={props => (
								<div
									{...props}
									style={{ ...props.style, overflowX: "hidden" }}
								/>
							)}
						>
							{files.map(file => (
								<FilesUploader
									key={file._id}
									_id={file._id}
									name={file.name}
									path={file.path || undefined}
									isRequired={file.is_required}
									onDoneUploading={onDoneUploading}
									onNameChange={onFileNameChange}
								/>
							))}
						</Scrollbars>
						{hasRequired && hasEmpty ? (
							<Box
								sx={{
									color: "#242f43",
									fontWeight: 400,
									backgroundColor: "#fff6f9",
									border: "1px solid #f75356",
									borderRadius: 4,
									padding: 3,
									marginTop: 3
								}}
							>
								<strong>Missing some mandatory documents.</strong> Please upload
								all the required elements.
							</Box>
						) : null}
					</Box>
				)}

				{!companyVat && (
					<Box mt={10} mb={75}>
						<Text
							mb={35}
							as={"h2"}
							sx={{
								fontSize: "37.5px",
								fontWeight: "300",
								letterSpacing: "-0.49px",
								color: "rgb(253, 104, 73)"
							}}
						>
							Add VAT
						</Text>
						<Text
							sx={{
								fontFamily: "Roboto",
								fontSize: "16px",
								letterSpacing: "-0.21px",
								color: "rgb(80, 92, 104)",
								width: "80%"
							}}
							mb={44}
						>
							You need to add your VAT number in order to proceed
						</Text>
						<Flex mb={15} flexDirection={"column"}>
							<Text
								sx={{
									fontFamily: "Roboto",
									fontSize: "14px",
									fontWeight: "bold",
									letterSpacing: "-0.18px",
									color: "#58626b",
									marginBottom: "10px"
								}}
							>
								VAT number <span style={{ color: "red" }}>*</span>
							</Text>
							<Controller
								name={"vat"}
								control={control}
								render={({ field: { onChange, value } }) => (
									<>
										<Input
											onChange={onChange}
											type="text"
											placeholder="Type VAT number here"
											value={value}
											style={{
												border: (errors.vat || vatError) && "1px solid red"
											}}
										/>
										{errors.vat && (
											<Text sx={{ color: "red", marginTop: "5px" }}>
												{errors.vat.message}
											</Text>
										)}
										{vatError && (
											<Text sx={{ color: "red", marginTop: "5px" }}>
												{vatError}
											</Text>
										)}
									</>
								)}
							/>
						</Flex>
					</Box>
				)}
				<div className="actions">
					<div className="row">
						<div className="col-md-3">
							<button
								className="btn btn-outline-new btn-block"
								onClick={() => {
									onClose();
									setHasRequired(false);
								}}
							>
								Cancel
							</button>
						</div>
						<div className="col-md-4">
							<button
								type="submit"
								className="btn btn-primary-new btn-block"
								disabled={isLoading}
							>
								Save and submit
							</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export default AddVatNumberModal;
