import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import FormField from "common/FormField";
import { emailRegex, MAX_LENGTH_255 } from "config";
import { getPolicyRoute } from "common/privacyPolicy/components/PolicyContainer";
import { Link } from "react-router";
import styles from "./style.module.scss";
import cx from "classnames";
import { Box, Flex } from "rebass";

class AskQuestionModal extends Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		isLoggedIn: PropTypes.bool.isRequired
	};

	componentDidMount() {
		if (this.props.fields) {
			this.props.initialize(this.props.fields);
		}
	}

	normalizeEmail = value => {
		return value.toLowerCase();
	};

	render() {
		const { onSubmit, handleSubmit, isLoggedIn, fields, onClose } = this.props;
		return (
			<Flex
				as={"form"}
				flexDirection={"column"}
				height={"100%"}
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className={styles.intro}>
					{
						"Leave us your question below and we'll be sure to get back to you with an answer as soon as possible."
					}
				</div>
				<Box flexGrow={1}>
					{!isLoggedIn && (
						<>
							<div className={styles.form_field}>
								<Field
									label="Full name"
									name="name"
									placeholder="Your full name"
									component={FormField}
								/>
							</div>
							<div className={styles.form_field}>
								<Field
									label="Email"
									name="email"
									normalize={this.normalizeEmail}
									placeholder="Your email"
									type="email"
									component={FormField}
								/>
							</div>
						</>
					)}
					<div className={styles.form_field}>
						<Field
							label="Subject"
							name="subject"
							placeholder="Enter subject here"
							component={FormField}
							readOnly={
								fields &&
								(fields.editable ? !fields.editable : !!fields.subject)
							}
						/>
					</div>
					<div className={cx(styles.form_field, styles.form_field_min_height)}>
						<Field
							label="Ask a question"
							name="message"
							placeholder="Enter your question here"
							type="textarea"
							component={FormField}
							maxLength={MAX_LENGTH_255}
							readOnly={
								fields &&
								(fields.editable ? !fields.editable : !!fields.message)
							}
						/>
					</div>
					<div className={styles.privacy_notice}>
						We use the information you provide us to enable us to deal with your
						query. For more information about what we do with personal data,
						please see our&nbsp;
						<Link href={getPolicyRoute()} target="_blank">
							Privacy Policy.
						</Link>
					</div>
				</Box>
				<Box>
					<button className={styles.button}>Submit Your Question</button>
					<button
						onClick={onClose}
						className={cx(styles.button, styles.button_outline)}
					>
						Cancel
					</button>
				</Box>
			</Flex>
		);
	}
}

const validate = formProps => {
	const errors = {};
	const fields = ["name", "subject", "message"];
	for (const field of fields) {
		if (!formProps[field] || formProps[field].trim() === "") {
			errors[field] = "Field can't be empty";
		}
	}
	if (!formProps.email || formProps.email.trim() === "") {
		errors.email = "Field can't be empty";
	} else if (formProps.email && !emailRegex.test(formProps.email)) {
		errors.email = "This email address is invalid";
	}
	return errors;
};

export default reduxForm({
	form: "help-ask-question",
	validate,
	touchOnBlur: false
})(AskQuestionModal);
