import { useMutation } from "react-query";
import { ADD_COMPANY_VAT_ENDPOINT } from "config/api-endpoints";
import { client } from "lib/api-client";

const addVatNumberSettings = body => client(ADD_COMPANY_VAT_ENDPOINT, { body });

const useAddVatNumber = () => {
	return useMutation(addVatNumberSettings, {});
};

export default useAddVatNumber;
