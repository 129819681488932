import React from "react";
import cx from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import style from "../../my-company.module.scss";

const OfficesEmptyState = () => {
	return (
		<>
			<div className={style.headerBlock}>
				<h3>Invoicing details</h3>
			</div>
			<div className={style.card}>
				<div className={style.field}>
					<div className={cx(style.infoLabel, style.fullWidth)}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
				<div className={style.field}>
					<div className={cx(style.infoLabel, style.fullWidth)}>
						<SkeletonTheme style={{ borderRadius: "3px" }} color="#E5E5E5">
							<Skeleton width={"100%"} height={16} />
						</SkeletonTheme>
					</div>
				</div>
			</div>
		</>
	);
};

export default OfficesEmptyState;
