import {
	TYPE_PDF,
	TYPE_DOC,
	TYPE_DOCX,
	TYPE_PNG,
	TYPE_JPEG,
	TYPE_PJPEG,
	TYPE_XLS,
	TYPE_XLSX,
	TYPE_PPT,
	TYPE_PPTX
} from "config";

export const QUESTIONNARE_YES_NO_OPTIONS = [
	{ value: true, label: "Yes" },
	{ value: false, label: "No" }
];

export const QUESTIONNARE_TYPE_OF_FIELDS = {
	YES_NO: "yes_no",
	MULTIPLE_CHOICE: "multiple_choice",
	FILE_UPLOAD: "file_upload",
	CHECKBOX: "checkbox",
	SIMPLE_TEXT: "simple_text"
};

export const ALLOWED_FILES_TYPES = [
	TYPE_PDF,
	TYPE_DOC,
	TYPE_DOCX,
	TYPE_PNG,
	TYPE_JPEG,
	TYPE_PJPEG,
	TYPE_XLS,
	TYPE_XLSX,
	TYPE_PPT,
	TYPE_PPTX
];

export const FILE_TYPES = {
	PDF: "PDF",
	DOC: "DOC",
	DOCX: "DOCX",
	PNG: "PNG",
	JPEG: "JPEG",
	PJPEG: "PJPEG",
	XLS: "XLS",
	XLSX: "XLSX",
	PPT: "PPT",
	PPTX: "PPTX"
};
