import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import _get from "lodash/get";
import Modal from "common/modal";
import { NoteContainer } from "../styled";
import { formatDate } from "common/Functions";
import Description from "common/Description";
import EmptyComponent from "common/EmptyComponent";
import { parseMentions } from "config/helpers";
import { USER_HIREME_NAME } from "config";

export default function NotesModal({ notes, onClose }) {
	const hasNotes = notes.length > 0;
	const renderNote = note => {
		const superAdmin = _get(note, "user.isSuperAdmin") === true;
		return note.description ? (
			<NoteContainer key={note._id}>
				<div>
					{!superAdmin ? (
						<strong>
							{_get(note, "user.first_name")} {_get(note, "user.last_name")}{" "}
						</strong>
					) : (
						<strong> {USER_HIREME_NAME} </strong>
					)}
					on {formatDate(note.created_at, true)}
				</div>
				<Description
					description={parseMentions(_get(note, "description", ""))}
					max={300}
					parseMarkdown={true}
				/>
			</NoteContainer>
		) : (
			<EmptyComponent
				className="no-border small-size"
				title="No notes available yet."
			/>
		);
	};
	return (
		<Modal
			active={notes !== undefined}
			onClose={onClose}
			title={"Notes"}
			fixed
			firstButton={{
				label: "Close",
				type: "outlined",
				action: onClose
			}}
			showSecondButton={false}
		>
			<Scrollbars
				autoHide
				autoHideTimeout={5000}
				autoHideDuration={200}
				autoHeight
				autoHeightMin={hasNotes ? 115 : 160}
				autoHeightMax={500}
				style={{ marginBottom: 20 }}
			>
				{hasNotes ? (
					notes.map(note => renderNote(note))
				) : (
					<EmptyComponent
						className="no-border small-size"
						title="No notes available yet."
					/>
				)}
			</Scrollbars>
		</Modal>
	);
}
