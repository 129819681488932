/* eslint-disable react/display-name */
import Tooltip from "common/Tippy";
import { useGetUser } from "hooks/useGetUser";
import React from "react";
import styles from "./vacancy-view-attendees-picker.module.scss";
import cx from "classnames";
import { ReactComponent as AddIcon } from "static/icons/add-icon-circle.svg";
import { CustomAvatar } from "common/CustomAvatar";
import { Picker } from "common/Picker";
import { Controller, useFormContext } from "react-hook-form";
import { getAttendees } from "../helper/utils";

const VacancyViewAttendeesPicker = ({ job, selectedAttendees }) => {
	const connectedUser = useGetUser();
	const { control } = useFormContext();

	return (
		<div className={styles.container}>
			<div>
				<div className={styles.collaboratorsContainer}>
					{!!selectedAttendees?.[0]?.children?.length && (
						<div className={styles.images}>
							{selectedAttendees[0].children.map(({ avatar, id, label }, i) => {
								return (
									<Tooltip key={id} content={label} theme="dark">
										<div className={styles.avatarWrapper}>
											<CustomAvatar
												src={avatar}
												variant="small"
												fullName={label}
												rootClassName={cx(styles.collaboratorPicture, {
													[styles.firstCollaboratorPicture]: i === 0
												})}
												imgClassName={cx(styles.picture)}
												avatarClassName={cx(
													styles.avatarWithBorder,
													styles.avatar,
													{
														[styles.firstCollaboratorPicture]: i === 0
													}
												)}
												avatarSize={30}
												rootStyle={{
													zIndex: i
												}}
												avatarStyle={{
													zIndex: i
												}}
											/>
										</div>
									</Tooltip>
								);
							})}
						</div>
					)}

					<Controller
						control={control}
						name="attendees"
						render={({ field: { onChange, value } }) => (
							<div>
								<Picker
									PrimaryHeaderContent={() => "collaborators remaining"}
									SecondaryHeaderContent={() => "collaborators selected"}
									displayRating={false}
									displaySelectedValues={false}
									addButtonComponent={props => (
										<AddIcon {...props} className={styles.editIcon} />
									)}
									placement="top"
									isMultipleSelect
									onConfirm={onChange}
									queryFn={() => getAttendees(connectedUser, job._id)}
									value={value}
									key="collaborators"
									useServerFilter={false}
									inlineChips
								/>
							</div>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

export default VacancyViewAttendeesPicker;
