import React from "react";

const GlobeIcon = ({ className }) => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<rect width="40" height="40" rx="20" fill="#F8FAFC" />
			<path
				d="M11.076 15.4828L15.364 18.5457C15.5872 18.705 15.6987 18.7847 15.8155 18.8031C15.9182 18.8192 16.0234 18.8029 16.1165 18.7565C16.2222 18.7037 16.3045 18.594 16.469 18.3747L17.3751 17.1665C17.4216 17.1045 17.4449 17.0735 17.4722 17.0467C17.4965 17.023 17.5232 17.0017 17.5517 16.9834C17.5839 16.9627 17.6193 16.947 17.6902 16.9155L21.5588 15.1961C21.7192 15.1248 21.7993 15.0892 21.8598 15.0335C21.9133 14.9843 21.9554 14.924 21.9832 14.8568C22.0146 14.7809 22.0204 14.6934 22.0321 14.5183L22.3154 10.2694M21.5 21.5L24.116 22.6211C24.4195 22.7512 24.5713 22.8163 24.6517 22.9243C24.7222 23.0191 24.7569 23.1358 24.7496 23.2537C24.7413 23.3881 24.6497 23.5255 24.4665 23.8002L23.2375 25.6438C23.1507 25.774 23.1072 25.8391 23.0499 25.8863C22.9991 25.928 22.9406 25.9593 22.8777 25.9784C22.8067 26 22.7284 26 22.5719 26H20.5766C20.3693 26 20.2656 26 20.1774 25.9653C20.0995 25.9347 20.0305 25.885 19.9768 25.8208C19.916 25.7481 19.8832 25.6497 19.8177 25.453L19.1048 23.3144C19.0661 23.1983 19.0468 23.1403 19.0417 23.0814C19.0372 23.0291 19.0409 22.9764 19.0528 22.9253C19.0662 22.8677 19.0935 22.813 19.1482 22.7036L19.6897 21.6206C19.7997 21.4005 19.8547 21.2905 19.9395 21.2222C20.0141 21.162 20.1046 21.1246 20.1999 21.1143C20.3081 21.1027 20.4248 21.1416 20.6582 21.2194L21.5 21.5ZM30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20Z"
				stroke="#334155"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default GlobeIcon;
