import React from "react";
import Avatar from "common/Avatar";
import GlobalTooltip from "common/GlobalTooltip";
import styles from "./attendees-user.module.scss";

const renderAvatar = attendee => (
	<div className={styles.avatar}>
		<Avatar
			key={attendee?._id}
			name={`${attendee.first_name} ${attendee.last_name}`}
			avatar={attendee.avatar}
			size={26}
		/>
	</div>
);

const AttendeesUsers = ({ attendeesUsers }) => {
	const hasMore = attendeesUsers.length > 2;
	const attendees = hasMore ? attendeesUsers.slice(0, 2) : attendeesUsers;
	const rest = hasMore ? attendeesUsers.slice(2) : [];
	const renderRest =
		rest &&
		rest.map(user => (
			<div key={user._id} className={styles.flex}>
				{renderAvatar(user)}
				<p>
					{user.first_name} {user.last_name}
				</p>
			</div>
		));
	return (
		<div className={styles.container}>
			{attendees?.length > 0 &&
				attendees.map(attendee => renderAvatar(attendee))}
			{rest.length > 0 && (
				<GlobalTooltip
					placement={"bottom"}
					maxWidth={"12rem"}
					align={{
						offset: [0, 0]
					}}
					overlay={<div>{renderRest}</div>}
				>
					<span>+{rest.length}</span>
				</GlobalTooltip>
			)}
		</div>
	);
};

export default AttendeesUsers;
