import React, { forwardRef } from "react";
import styles from "./icon-button.module.scss";
import cx from "classnames";

const IconButton = (
	{ onClick, className, children, isDisabled, ...rest },
	ref
) => {
	return (
		<button
			ref={ref}
			className={cx(styles.button, className, {
				[styles.button__disabled]: isDisabled
			})}
			onClick={e => {
				if (!isDisabled && onClick) onClick();
				e.stopPropagation();
			}}
			{...rest}
		>
			{children}
		</button>
	);
};

export default forwardRef(IconButton);
