import React from "react";
import styles from "./filter-drawer-header.module.scss";

const FilterDrawerHeader = ({ title, onClose }) => {
	return (
		<div className={styles.container}>
			<div className={styles.title}>{title}</div>
			<div className={styles.imgContainer}>
				<div className={styles.icon} onClick={onClose}>
					<svg
						width="2.5rem"
						height="2.5rem"
						viewBox="0 0 2.5rem 2.5rem"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M24.7918 10.209L10.2085 24.7923M10.2085 10.209L24.7918 24.7923"
							stroke="#525252"
							strokeWidth="2.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};

export default FilterDrawerHeader;
