import React from "react";

export default function edit({ fill = "#ffffff" }) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.8502 6.68333L9.21685 4.05M1.646 14.2542L3.87404 14.0066C4.14625 13.9764 4.28236 13.9612 4.40958 13.9201C4.52245 13.8835 4.62986 13.8319 4.7289 13.7666C4.84053 13.693 4.93736 13.5961 5.13103 13.4025L13.8252 4.70833C14.5524 3.98116 14.5524 2.80217 13.8252 2.075C13.098 1.34782 11.919 1.34782 11.1918 2.075L2.4977 10.7691C2.30403 10.9628 2.2072 11.0596 2.13358 11.1713C2.06827 11.2703 2.01665 11.3777 1.98011 11.4906C1.93893 11.6178 1.9238 11.7539 1.89356 12.0261L1.646 14.2542Z"
				stroke={fill}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
