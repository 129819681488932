import { OPERATORS } from "common/FilterDrawer/utils";
import { TYPES } from "./constants";
import { NEW_FILTER_SETTINGS } from "config/api-endpoints";
import { client } from "lib/api-client";
import preferenceIcon from "static/icons/preferences-icon.svg";
import personIcon from "static/icons/person-icon.svg";
import fileIcon from "static/icons/file-icon-1.svg";
import moment from "moment";
import { v4 as uuid } from "uuid";

const getFilterData = ({ tag }) => {
	return client(NEW_FILTER_SETTINGS, {
		body: {
			list: "timesheets",
			tag
		}
	});
};

export const COLUMNS = [
	{
		id: 1,
		label: "Timesheet details",
		icon: fileIcon,
		children: [
			{
				label: "Mission title",
				name: "mission_title",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Mission reference",
				name: "mission_reference",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Timesheet manager",
				name: "timesheet_manager",
				type: TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFilterData({ tag: "managers" })
								.then(res => {
									resolve([
										{
											id: 1,
											label: "manager_ts",
											children: res.managers.map(item => ({
												label: item.name,
												id: item._id
											}))
										}
									]);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Managers remained",
					secondaryHeaderContent: () => "Managers selected",
					useServerFilter: false
				}
			},
			{
				label: "Status",
				name: "status",
				type: TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFilterData({ tag: "status" })
								.then(res => {
									resolve([
										{
											id: 1,
											label: "status",
											children: res.status.map(item => ({
												label: item.label,
												id: item.value
											}))
										}
									]);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Status remained",
					secondaryHeaderContent: () => "Status selected",
					useServerFilter: false
				}
			},
			{
				label: "Currency",
				name: "currency",
				type: TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFilterData({ tag: "currencies" })
								.then(res => {
									resolve([
										{
											id: 1,
											label: "currency",
											children: res.map(item => ({
												label: `${item.symbol} - ${item.name}`,
												id: item.code
											}))
										}
									]);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Currencies remained",
					secondaryHeaderContent: () => "Currencies selected",
					useServerFilter: false
				}
			},
			{
				label: "Period",
				name: "period",
				type: TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							Promise.all([
								getFilterData({ tag: "months" }),
								getFilterData({ tag: "weeks" })
							])
								.then(([{ months }, { weeks }]) => {
									const mappedMonths = months.map(item => ({
										label: moment(item, "MM-YYYY").format("MMMM YYYY"),
										value: item,
										id: uuid()
									}));
									const mappedWeeks = weeks.map(item => ({
										label: "Week " + moment(item, "WW-YYYY").format("WW, YYYY"),
										value: item,
										id: uuid()
									}));
									resolve([
										{
											id: 1,
											label: "Months",
											children: mappedMonths
										},
										{
											id: 2,
											label: "Weeks",
											children: mappedWeeks
										}
									]);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: ({ parent }) => `${parent} remained`,
					secondaryHeaderContent: ({ parent }) => `${parent} selected`,
					useServerFilter: false
				}
			},
			{
				label: "Start date",
				name: "start_date",
				type: TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "End date",
				name: "end_date",
				type: TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "Submission date",
				name: "submission_date",
				type: TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "Approval date",
				name: "approval_date",
				type: TYPES.date,
				operator: OPERATORS.is
			},
			{
				label: "Timesheet frequency",
				name: "timesheet_frequency",
				type: TYPES.frequencySelect,
				operator: OPERATORS.is
			},
			{
				label: "PO Number",
				name: "po_number",
				type: TYPES.text,
				operator: OPERATORS.contains
			}
		]
	},
	{
		id: 2,
		label: "Consultant details",
		icon: personIcon,
		children: [
			{
				label: "Consultant",
				name: "consultant",
				type: TYPES.multipleSelect,
				operator: OPERATORS.is,
				payload: {
					queryFn: () => {
						return new Promise((resolve, reject) => {
							getFilterData({ tag: "consultants" })
								.then(res => {
									resolve([
										{
											id: 1,
											label: "user_id",
											children: res.consultants.map(item => ({
												label: item.name,
												id: item._id
											}))
										}
									]);
								})
								.catch(e => reject(e));
						});
					},
					displayRating: false,
					primaryHeaderContent: () => "Consultants remained",
					secondaryHeaderContent: () => "Consultants selected",
					useServerFilter: false
				}
			},
			{
				label: "Profile type",
				name: "profile_type",
				type: TYPES.profileTypeSelect,
				operator: OPERATORS.is
			},
			{
				label: "External timesheet manager",
				name: "external_manager",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "Accounting manager",
				name: "accounting_manager",
				type: TYPES.text,
				operator: OPERATORS.contains
			},
			{
				label: "External total amount",
				name: "external_total_amount",
				type: TYPES.amount,
				operator: OPERATORS.eq
			}
		]
	},
	{
		id: 3,
		label: "Preferences",
		icon: preferenceIcon,
		children: [
			{
				label: "Files",
				name: "files",
				type: TYPES.boolean,
				operator: OPERATORS.isEmpty
			},
			{
				label: "Total amount",
				name: "total_amount",
				type: TYPES.amount,
				operator: OPERATORS.eq
			},
			{
				label: "Work time",
				name: "work_time",
				type: TYPES.number,
				operator: OPERATORS.eq
			},
			{
				label: "Expenses",
				name: "expenses",
				type: TYPES.amount,
				operator: OPERATORS.eq
			},
			{
				label: "Overtime",
				name: "over_time",
				type: TYPES.number,
				operator: OPERATORS.eq
			}
		]
	}
];
