import React from "react";

const RatingIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 500 500">
		<g>
			<g>
				<g>
					<path
						className="notification-icon-origin"
						d="M333.2,306.7H164.9v-40.5c0-33.2,27-60.3,60.3-60.3h47.7c33.2,0,60.3,27,60.3,60.3V306.7z M178.1,293.6h142
				v-27.4c0-26-21.1-47.1-47.1-47.1h-47.7c-26,0-47.1,21.1-47.1,47.1V293.6z"
					/>
				</g>
				<g>
					<path
						className="notification-icon-origin"
						d="M249,197.3c-25.4,0-46.1-20.7-46.1-46.1c0-25.4,20.7-46.1,46.1-46.1c25.4,0,46.1,20.7,46.1,46.1
				C295.1,176.6,274.5,197.3,249,197.3z M249,120.1c-17.2,0-31.1,14-31.1,31.1c0,17.2,14,31.1,31.1,31.1s31.1-14,31.1-31.1
				C280.2,134,266.2,120.1,249,120.1z"
					/>
				</g>
			</g>
			<g>
				<g>
					<g>
						<polygon
							className="notification-icon"
							points="160.9,328 152.2,344.3 131.5,348.9 144.4,365.6 143.8,386.5 163.1,378.7 181.8,386.5 181.8,367.7
					194.2,348.9 174.8,345.1 165.9,328 				"
						/>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="notification-icon-origin"
										d="M195,355.1c1.7-1.7,2.3-4.1,1.6-6.4c-0.7-2.3-2.7-3.9-5-4.2l-15.6-2.3c-0.1,0-0.3-0.1-0.3-0.2l-7-14.1
								c-1.1-2.1-3.2-3.5-5.6-3.5c-2.4,0-4.5,1.3-5.6,3.5l-7,14.1c-0.1,0.1-0.2,0.2-0.3,0.2l-15.6,2.3c-2.4,0.4-4.3,2-5,4.2
								c-0.7,2.3-0.1,4.7,1.6,6.4l11.3,11c0.1,0.1,0.2,0.2,0.1,0.4l-2.7,15.5c-0.4,2.4,0.5,4.7,2.5,6.1c1.1,0.8,2.4,1.2,3.7,1.2
								c1,0,2-0.3,2.9-0.7l13.9-7.3c0.1-0.1,0.3-0.1,0.4,0l13.9,7.3c2.1,1.1,4.6,0.9,6.6-0.5c1.9-1.4,2.9-3.7,2.5-6.1l-2.7-15.5
								c0-0.1,0-0.3,0.1-0.4L195,355.1z M145.8,383.4c-0.2-0.1-0.2-0.3-0.2-0.4l2.7-15.5c0.4-2-0.3-4.1-1.8-5.5l-11.3-11
								c-0.1-0.1-0.2-0.3-0.1-0.4c0.1-0.2,0.2-0.3,0.4-0.3l15.6-2.3c2-0.3,3.8-1.6,4.7-3.4l7-14.1c0.1-0.2,0.2-0.3,0.4-0.3
								c0.2,0,0.3,0.1,0.4,0.3l7,14.1c0.9,1.8,2.7,3.1,4.7,3.4l15.6,2.3c0.2,0,0.3,0.1,0.3,0.3c0.1,0.2,0,0.3-0.1,0.4l-11.3,11
								c-1.5,1.4-2.1,3.5-1.8,5.5l2.7,15.5c0,0.2,0,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0l-13.9-7.3c-1.8-0.9-4-1-5.8,0l-13.9,7.3
								C146.1,383.5,145.9,383.5,145.8,383.4z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<polygon
							className="notification-icon"
							points="246.8,328 238.2,344.3 217.5,348.9 230.4,365.6 229.7,386.5 249,378.7 267.8,386.5 267.8,367.7
					280.2,348.9 260.8,345.1 251.9,328 				"
						/>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="notification-icon-origin"
										d="M281,355.1c1.7-1.7,2.3-4.1,1.6-6.4c-0.7-2.3-2.7-3.9-5-4.2l-15.6-2.3c-0.1,0-0.3-0.1-0.3-0.2l-7-14.1
								c-1.1-2.1-3.2-3.5-5.6-3.5c-2.4,0-4.5,1.3-5.6,3.5l-7,14.1c-0.1,0.1-0.2,0.2-0.3,0.2l-15.6,2.3c-2.4,0.4-4.3,2-5,4.2
								c-0.7,2.3-0.1,4.7,1.6,6.4l11.3,11c0.1,0.1,0.2,0.2,0.1,0.4l-2.7,15.5c-0.4,2.4,0.5,4.7,2.5,6.1c1.1,0.8,2.4,1.2,3.7,1.2
								c1,0,2-0.3,2.9-0.7l13.9-7.3c0.1-0.1,0.3-0.1,0.4,0l13.9,7.3c2.1,1.1,4.6,0.9,6.6-0.5c1.9-1.4,2.9-3.7,2.5-6.1l-2.7-15.5
								c0-0.1,0-0.3,0.1-0.4L281,355.1z M231.8,383.4c-0.2-0.1-0.2-0.3-0.2-0.4l2.7-15.5c0.4-2-0.3-4.1-1.8-5.5l-11.3-11
								c-0.1-0.1-0.2-0.3-0.1-0.4c0.1-0.2,0.2-0.3,0.4-0.3l15.6-2.3c2-0.3,3.8-1.6,4.7-3.4l7-14.1c0.1-0.2,0.2-0.3,0.4-0.3
								c0.2,0,0.3,0.1,0.4,0.3l7,14.1c0.9,1.8,2.7,3.1,4.7,3.4l15.6,2.3c0.2,0,0.3,0.1,0.3,0.3c0.1,0.2,0,0.3-0.1,0.4l-11.3,11
								c-1.5,1.4-2.1,3.5-1.8,5.5l2.7,15.5c0,0.2,0,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0l-13.9-7.3c-1.8-0.9-4-1-5.8,0l-13.9,7.3
								C232,383.5,231.9,383.5,231.8,383.4z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
				<g>
					<g>
						<polygon
							className="notification-icon"
							points="332.8,328.5 324.2,344.8 303.5,349.4 316.3,366.1 315.7,387 335,379.2 353.7,387 353.7,368.2
					366.2,349.4 346.8,345.6 337.9,328.5 				"
						/>
					</g>
					<g>
						<g>
							<g>
								<g>
									<path
										className="notification-icon-origin"
										d="M366.9,355.6c1.7-1.7,2.3-4.1,1.6-6.4c-0.7-2.3-2.7-3.9-5-4.2l-15.6-2.3c-0.1,0-0.3-0.1-0.3-0.2l-7-14.1
								c-1.1-2.1-3.2-3.5-5.6-3.5c-2.4,0-4.5,1.3-5.6,3.5l-7,14.1c-0.1,0.1-0.2,0.2-0.3,0.2l-15.6,2.3c-2.4,0.4-4.3,2-5,4.2
								c-0.7,2.3-0.1,4.7,1.6,6.4l11.3,11c0.1,0.1,0.2,0.2,0.1,0.4l-2.7,15.5c-0.4,2.4,0.5,4.7,2.5,6.1c1.1,0.8,2.4,1.2,3.7,1.2
								c1,0,2-0.3,2.9-0.7l13.9-7.3c0.1-0.1,0.3-0.1,0.4,0l13.9,7.3c2.1,1.1,4.6,0.9,6.6-0.5c1.9-1.4,2.9-3.7,2.5-6.1l-2.7-15.5
								c0-0.1,0-0.3,0.1-0.4L366.9,355.6z M317.7,383.9c-0.2-0.1-0.2-0.3-0.2-0.4l2.7-15.5c0.4-2-0.3-4.1-1.8-5.5l-11.3-11
								c-0.1-0.1-0.2-0.3-0.1-0.4c0.1-0.2,0.2-0.3,0.4-0.3l15.6-2.3c2-0.3,3.8-1.6,4.7-3.4l7-14.1c0.1-0.2,0.2-0.3,0.4-0.3
								c0.2,0,0.3,0.1,0.4,0.3l7,14.1c0.9,1.8,2.7,3.1,4.7,3.4l15.6,2.3c0.2,0,0.3,0.1,0.3,0.3c0.1,0.2,0,0.3-0.1,0.4l-11.3,11
								c-1.5,1.4-2.1,3.5-1.8,5.5l2.7,15.5c0,0.2,0,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0l-13.9-7.3c-1.8-0.9-4-1-5.8,0l-13.9,7.3
								C318,384,317.9,384,317.7,383.9z"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
export default RatingIcon;
