import * as types from "./actionTypes";
import _get from "lodash/get";
import {
	READ_MY_PROFILE,
	VIEW_PROFILE,
	JOB_CV,
	UPDATE_LANGUAGES,
	ADD_SKILLS,
	UPDATE_SKILLS,
	LIST_SKILLS,
	REMOVE_EDUCATION,
	REMOVE_EXPERIENCE
} from "config/api-endpoints";
import { SKILLS, EDUCATIONS, EXPERIENCES } from "config";
import { SECTION } from "../config";
import AxiosHelper from "config/axios-helper";

function writeJobs(jobs) {
	return {
		type: types.WRITE_JOBS,
		jobs
	};
}

function _doneLoading(data, ownCV) {
	return {
		type: types.LOADED_PROFILE,
		data,
		ownCV
	};
}

export function changeAvatar(avatar) {
	return {
		type: types.CHANGE_AVATAR,
		avatar
	};
}

export const updateEditSkills = skills => ({
	type: types.UPDATE_SELECTED_SKILLS,
	skills
});

export function editLanguages(list = [], saving = false) {
	return {
		type: types.UPDATE_SELECTED_LANGUAGES,
		languages: list,
		saving
	};
}

export const updateLanguages = (list = [], userId) => {
	return AxiosHelper.__post({
		url: UPDATE_LANGUAGES,
		data: { languages: list, user_id: userId },
		next: () => editLanguages(list, true),
		toastMessage: "Languages updated",
		isModal: true
	});
};

export function createSkills(skills, userId) {
	return AxiosHelper.__post({
		data: { skills, user_id: userId },
		url: ADD_SKILLS,
		toastMessage: "Skills added!",
		next: () => dispatch => {
			dispatch(closeModal());
			return dispatch(reloadSection(SKILLS, userId));
		}
	});
}

export function updateSkills(skills, userId) {
	return AxiosHelper.__post({
		data: { skills, user_id: userId },
		url: UPDATE_SKILLS,
		toastMessage: "Skills updated!",
		isModal: true,
		next: () => dispatch => {
			dispatch(closeModal());
			return dispatch(reloadSection(SKILLS, userId));
		}
	});
}

export function suggestSkills(term) {
	return (dispatch, getState) => {
		const sector_id = _get(getState(), "cv.headline.sector.parent_sector._id");
		return dispatch(
			AxiosHelper.__post({
				isModal: true,
				url: LIST_SKILLS,
				REQUEST_SUCCESS: types.PUT_SUGGESTED_SKILLS,
				data: {
					sector_id: sector_id,
					no_limit: true,
					term,
					offset: 0
				}
			})
		);
	};
}

export function loadJobs() {
	return AxiosHelper.__post({
		url: JOB_CV,
		next: writeJobs
	});
}

function _reloadSection(section) {
	return {
		type: types.RELOAD_SECTION,
		section
	};
}

function _reloadedSection(data) {
	return {
		type: types.RELOADED_SECTION,
		data
	};
}

// exported functions
export function changeTab(tab) {
	return {
		type: types.CHANGE_TAB,
		tab
	};
}

export function showModal(modal, data) {
	return {
		type: types.SHOW_MODAL,
		modal,
		data
	};
}

export function closeModal() {
	return {
		type: types.CLOSE_MODAL
	};
}

export function loadProfile(id) {
	return AxiosHelper.__post({
		url: id ? VIEW_PROFILE : READ_MY_PROFILE,
		data: id ? { id } : {},
		PRE_REQUEST: types.LOAD_PROFILE,
		next: data => _doneLoading(data, !id)
	});
}
export function loadProfileUser(data) {
	return AxiosHelper.__post({
		url: VIEW_PROFILE,
		data: data,
		PRE_REQUEST: types.LOAD_PROFILE,
		next: resp => _doneLoading(resp, !data.id)
	});
}

export function reloadSection(section, userId) {
	let data = {};
	if (userId) {
		if (section === "headline") {
			data.id = userId;
		} else {
			data.user_id = userId;
		}
	}
	return dispatch => {
		dispatch(_reloadSection(section));
		return dispatch(
			AxiosHelper.__post({
				url: SECTION[section],
				data
			})
		).then(data => {
			data = Object.prototype.hasOwnProperty.call(data, section)
				? data[section]
				: data;
			dispatch(_reloadedSection(data));
			return data;
		});
	};
}

export function removeEducation(id, userId) {
	return AxiosHelper.__post({
		data: { education_id: id, user_id: userId },
		url: REMOVE_EDUCATION,
		toastMessage: "Education removed",
		isModal: true,
		next: () => dispatch => {
			dispatch(closeModal());
			return dispatch(reloadSection(EDUCATIONS, userId));
		}
	});
}

/* experience actions */

export function removeExperience(id, userId) {
	return AxiosHelper.__post({
		isModal: true,
		data: { experience_id: id, user_id: userId },
		url: REMOVE_EXPERIENCE,
		toastMessage: "Experience removed",
		next: () => dispatch => {
			dispatch(closeModal());
			return dispatch(reloadSection(EXPERIENCES, userId));
		}
	});
}

export function clearProfileData() {
	return {
		type: types.CLEAR_PROFILE_DATA
	};
}

export function updateCvFile(data) {
	return {
		type: types.PROCESSED_CV_FILE,
		data
	};
}

export function setFileProcessingStatus(data) {
	return {
		type: types.UPDATE_IS_PROCESSING,
		data
	};
}
