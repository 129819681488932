import React, { useEffect } from "react";
import BodyClassName from "react-body-classname";
import { TabPanel, TabPanels, Tabs } from "@reach/tabs";
import styles from "./request-details.module.scss";
import cx from "classnames";
import { connect } from "react-redux";
import _get from "lodash/get";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { VACANCY, MARK_AS_FILLED, REQUEST } from "config";
import { getLastJob } from "./components/actions";
import PublishJob from "./components/modals/PublishJob";
import { getListAttendees } from "modules/candidate/redux/actions";
import {
	inActionSelector,
	stopJobSelector,
	jobToPublishSelector
} from "./components/selectors";
import { change } from "redux-form";
import { attendeesListSelector } from "modules/candidate/redux/selectors";
import StopVacancy from "modules/vacancy/components/modals/StopVacancy";
import MarkAsFilled from "../vacancy/components/modals/MarkAsFilled";
import { fetchCurrentCompany } from "modules/company/actions/identityActions";
import { addHashToUrl, getUrlHash } from "config/helpers";
import StopJob from "./components/modals/StopJob";
import { RequestViewHead } from "./components/jobView/RequestViewHead";
import { useSetRequestDetailsBreadCrumbs } from "./components/jobView/useSetRequestDetailsBreadCrumbs";
import { RequestDetailsTab } from "./components/RequestDetailsTab";
import useRequestViewCurrentTab from "modules/RequestView/hooks/useRequestViewCurrentTab";
import RequestViewKanban from "./components/kanban/components/RequestViewKanban";
import RequestViewPerformance from "./components/kanban/components/RequestViewPerformance";
import { useGetRequestPerformance } from "./components/kanban/hooks/useGetRequestPerformance";
import { jobDataSelector } from "modules/job/selectors";
import useCurrentRequest from "./hooks/useCurrentRequest";
import useRequestViewKanbanStore from "./components/kanban/store/useRequestViewKanbanStore";
import { useTempKanbanSettingsApi } from "./components/kanban/hooks/useTempKanbanSettingsApi";

export const getJobViewRoute = id => {
	return `/job/${id !== undefined ? `${id}` : `:id`}`;
};
export const getVacancyViewRoute = id => {
	return `/vacancy/${id !== undefined ? `${id}` : `:id`}`;
};

export const getJobViewPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

const RequestView = props => {
	const { setRequest } = useCurrentRequest();
	const { setKanbanStages } = useRequestViewKanbanStore();
	useEffect(() => {
		const { id } = props.params;
		getLastJob({ id, job_type: REQUEST });
		setRequest(id);
	}, []);

	useTempKanbanSettingsApi(
		{ job_id: props.params.id },
		{
			onSuccess: data => setKanbanStages(data)
		}
	);

	useSetRequestDetailsBreadCrumbs(props?.job?.title);
	const { indexTab, setIndexTab } = useRequestViewCurrentTab();
	const { refetch: refetchPerformanceData } = useGetRequestPerformance({
		id: props.params.id
	});

	const reload = () => {
		const { getLastJob } = props;

		getLastJob({
			id: props.params.id,
			job_type: props.job.type
		});
		refetchPerformanceData();
	};

	const getActionModal = () => {
		const { stopJob, jobToPublish, job } = props;
		const isVacancy = job.type === VACANCY;
		if (stopJob && stopJob.id && !isVacancy) {
			return <StopJob onSuccess={reload} />;
		}
		if (stopJob && stopJob.id && stopJob.mode !== MARK_AS_FILLED && isVacancy) {
			return <StopVacancy onSuccess={reload} />;
		}
		if (stopJob && stopJob.id && stopJob.mode === MARK_AS_FILLED && isVacancy) {
			return (
				<MarkAsFilled
					candidates={[{ label: "Imad", value: 1 }]}
					onSuccess={reload}
				/>
			);
		}
		if (jobToPublish) {
			return <PublishJob job={jobToPublish} />;
		}
	};

	const onTabChange = value => {
		setIndexTab(value);
		addHashToUrl(value);
	};

	useEffect(() => {
		const id = getUrlHash();
		if (id) {
			setIndexTab(parseInt(id));
		} else {
			setIndexTab(0);
		}
	}, []);

	const { connectedUser, job, getLastJob } = props;

	return (
		<BodyClassName className="my-job gray-bg job-view">
			<Tabs onChange={onTabChange} index={indexTab}>
				<div className={cx("view-job-details", styles.subContainer)}>
					{job.id && (
						<RequestViewHead connectedUser={connectedUser} job={job} />
					)}
					{job.id && (
						<div className={cx(styles.tabs_container, styles.panelContainer)}>
							<TabPanels>
								<TabPanel key="pipeline">
									<RequestViewKanban jobId={job.id} />
								</TabPanel>
								<TabPanel key="performance">
									<RequestViewPerformance jobId={job.id} indexTab={indexTab} />
								</TabPanel>
								<TabPanel key="request_details">
									<RequestDetailsTab job={job} />
								</TabPanel>
								<TabPanel key="notes"></TabPanel>

								<TabPanel key="withdrawn"></TabPanel>

								<TabPanel key="activities"></TabPanel>
							</TabPanels>
						</div>
					)}
					{getActionModal()}
				</div>
			</Tabs>
		</BodyClassName>
	);
};

const mapStateToProps = state => {
	return {
		inAction: inActionSelector(state),
		stopJob: stopJobSelector(state),
		jobToPublish: jobToPublishSelector(state),
		connectedUser: {
			company_identity_status: _get(
				state,
				"auth.user.company_identity_status",
				""
			),
			company_payment_status: _get(
				state,
				"auth.user.company_payment_status",
				""
			),
			company_information_status: _get(
				state,
				"auth.user.company_information_status",
				""
			)
		},
		user: onlineUserSelector(state),
		attendeesList: attendeesListSelector(state),
		job: jobDataSelector(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getListAttendees: payload => dispatch(getListAttendees(payload)),
		changeReduxForm: (field, value) =>
			dispatch(change("send-poke", field, value)),
		getLastJob: payload => dispatch(getLastJob(payload)),
		fetchCurrentCompany: () => dispatch(fetchCurrentCompany(false))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestView);
