import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { GET_CONTRACT_DETAILS } from "config/api-endpoints";

const getContractDetails = (_key, body) =>
	client(GET_CONTRACT_DETAILS, {
		body
	});

const useGetContractDetails = (options, queryOptions = {}) =>
	useQuery(["@VonqContract/getDetails", options], getContractDetails, {
		refetchOnWindowFocus: false,
		retry: 0,
		...queryOptions
	});

export default useGetContractDetails;
