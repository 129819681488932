import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import BodyClassName from "react-body-classname";
import _ from "lodash";
import Tooltip from "rc-tooltip";
import styled from "styled-components";
import { browserHistory } from "react-router";
import { LEVEL, MAX_LANGUAGE_WEIGHT, ADMIN, REQUEST } from "config";
//components
import MenuWithCTA from "common/MenuWithCTA";
import StyledSelect from "common/StyledSelect";
import { getGlobalOptions } from "../../selectors";
import LeaveHook from "common/LeaveHook";
import { getCreateJobRoute } from "../CreateJob";
import EditableList from "common/editablelist/EditableList";
import { getLanguageTooltip, getSkillsTooltip } from "config/helpers";
import { getJobListContainerRoute } from "../joblist/jobListContainer";
import { Button, ButtonsContainer, OutlinedLink } from "common/styled/buttons";
import Icon from "common/styled/icons";
import Skills from "common/Skills";
import GlobalTooltip from "common/GlobalTooltip";
import SkillsModal from "../SkillsModal";
import { scrollToFirstError } from "common/Functions";
import Star from "common/Icons/Star";

const CTA = styled(Button)`
	padding: 10px 35px;
	letter-spacing: 2px;
	font-weight: bold;
`;

const CTAIcon = styled(Icon)`
	position: absolute;
	right: -3px;
	top: 7px;
	font-size: 10px !important;
`;

class JobProfile extends Component {
	constructor() {
		super();
		this.state = {
			showErrors: {},
			showSkillsModal: false
		};
	}

	componentDidUpdate({ formFields }) {
		const { subcategory } = formFields;
		const newSubcategory = _.get(this.props, "formFields.subcategory");
		if (newSubcategory && newSubcategory !== subcategory) {
			this.getFunctions({ value: newSubcategory._id || newSubcategory.value });
		}
	}

	getFunctions = ({ value }) => {
		if (!value) return;
		const data = {
			sector_id: value,
			no_limit: true,
			offset: 0
		};
		this.props.getFunctions(data);
	};

	onSubmitJob = (formProps = {}) => {
		const { createJob, params } = this.props;
		const data = JSON.parse(JSON.stringify(this.props.data));
		const payload = {
			category: formProps.category.label,
			subcategory: formProps.subcategory.label,
			seniority: formProps.seniority,
			function: formProps.function,
			languages: data.languages,
			skills: data.skills.map(s => {
				delete s.status;
				delete s.parent_sector;
				s.must_have = s.must_have || false;
				return s;
			}),
			id: data.id || data._id,
			step: 1,
			group_id: formProps.group_id.value,
			job_type: REQUEST
		};
		return createJob(payload, !!params.id);
	};

	handleCategoryChange = () => {
		this.props.changeReduxForm("subcategory", "");
		this.props.changeReduxForm("function", "");
		this.props.changeReduxForm("seniority", "");
		// empty selected skills
		if (this.props.data.skills.length) {
			this.props.setJobDataField([], "skills");
		}
		// empty skills list
		if (this.props.skills.length) {
			this.props.setSkills([]);
		}
	};

	handleSubcategoryChange = () => {
		this.props.changeReduxForm("function", "");
		this.props.changeReduxForm("seniority", "");
	};

	handleFunctionChange = () => {
		this.props.changeReduxForm("seniority", "");
	};

	loadSkills = ({ term }) => {
		const sector = this.props.formFields.category;

		const category = _.find(this.props.categories, {
			_id: sector._id
		});

		if (!category) return;

		this.props.getSkills({
			sector_id: category._id,
			limit: 150,
			offset: 0,
			term: term
		});
	};

	handleSkillScoreChange = (skill, score) => {
		this.props.setScore(skill, score, "skills");
	};

	openSkillsModal = () => {
		if (!this.props.formFields.category) {
			return;
		}
		this.setState({ showSkillsModal: true });
	};

	addSkills = skills => {
		skills && this.props.setJobDataField(skills, "skills");
		this.setState({ showSkillsModal: false });
	};

	removeSkill = skill => {
		const skills = [...this.props.data.skills];
		_.remove(skills, option => option._id === skill._id);
		this.props.setJobDataField(skills, "skills");
		if (!skills.length) this.props.changeReduxForm("skills", "");
	};

	addLanguage = language => {
		language &&
			this.props.setJobDataField(
				[
					...this.props.data.languages,
					{ score: 1, name: language.label, _id: language._id }
				],
				"languages"
			);
	};

	removeLanguage = language => {
		const languages = [...this.props.data.languages];
		_.remove(languages, option => option._id === language._id);
		this.props.setJobDataField(languages, "languages");
		if (!languages.length) this.props.changeReduxForm("languages", "");
	};

	handleLanguageScoreChange = (language, score) => {
		this.props.setScore(language, score, "languages");
	};

	handleClickChange = ({ name, disabled }) => {
		if (disabled && name) {
			if (!_.has(this.state.showErrors, name)) {
				return this.setState({ showErrors: { [name]: true } });
			}
		} else {
			if (_.size(this.state.showErrors)) {
				return this.setState({ showErrors: {} });
			}
		}
	};

	handleProposeSkill = e => {
		e.preventDefault();
		const { formFields, togglePropose } = this.props;
		togglePropose(_.get(formFields, "category._id"), this.state.term);
	};

	getListRoute = () => {
		return getJobListContainerRoute();
	};

	render() {
		const {
			handleSubmit,
			submitting,
			formFields,
			data,
			functions,
			categories,
			languages,
			skills,
			dirty,
			connectedUser: { active_role },
			submitFailed
		} = this.props;

		const skillsError = submitFailed && data.skills.length === 0;
		const groups = JSON.parse(JSON.stringify(this.props.groups));
		const { showSkillsModal } = this.state;

		const subcategoryActive = !(
			formFields.category && formFields.category !== "0"
		);

		const functionActive = !(
			formFields.category &&
			formFields.category !== "0" &&
			formFields.subcategory &&
			formFields.subcategory !== "0"
		);

		const subcategories = _.get(
			_.find(categories, { name: _.get(formFields, "category.label") }),
			"children",
			[]
		);

		const departmentTooltip =
			"By selecting a department, you will allow this request and the following processes to appear only for the users in this department. Learn more about departments and roles in our FAQ.";

		const skillsTooltip = `The skills are an essential part of the
					 	candidate/request matching process and matching score calculation.
						Be as precise as possible in the selection of the skills needed and
						the weight/importance they have. A good skills selection will
						maximise your chances to find a good match faster.`;

		return (
			<BodyClassName className="job-term">
				<div className="container">
					{showSkillsModal && (
						<SkillsModal
							loadSkills={this.loadSkills}
							skills={skills}
							initialSkills={data.skills}
							sector={this.props.formFields.category}
							onSave={this.addSkills}
							contentHeight={84}
							closeModal={() => this.setState({ showSkillsModal: false })}
							isRequest={true}
							showMustHave={true}
						/>
					)}
					<LeaveHook dirty={dirty} route={getCreateJobRoute()}>
						<div className="row">
							<div className="col-md-5 col-sm-12">
								<Field
									name="group_id"
									label="Department"
									options={getGlobalOptions(groups, "_id")}
									position={false}
									searchable={true}
									clearable={false}
									component={MenuWithCTA}
									cta={
										active_role !== ADMIN
											? null
											: () => (
													<CTA
														size={14}
														small
														large
														onClick={() =>
															browserHistory.push("/settings/users")
														}
													>
														Create a new department
														<CTAIcon className="icon-newtab-arrow" />
													</CTA>
											  )
									}
									toolTipOverlay={departmentTooltip}
								/>
								<Field
									name="category"
									label="Category"
									options={getGlobalOptions(categories, "name")}
									rawData={categories}
									position={false}
									grouped={true}
									component={StyledSelect}
									onChange={this.handleCategoryChange}
									valueField="name"
									onClick={this.handleClickChange}
									showErrorContainer={true}
								/>

								<Field
									disabled={subcategoryActive}
									name="subcategory"
									label="Subcategory"
									options={getGlobalOptions(subcategories, "name")}
									position={false}
									component={StyledSelect}
									onChange={this.handleSubcategoryChange}
									onClick={this.handleClickChange}
									valueField="_id"
									showErrors={this.state.showErrors.subcategory}
									showErrorContainer={true}
								/>
								<div className="functions">
									<div className="row">
										<div className="col-sm-7">
											<Field
												disabled={functionActive}
												name="function"
												label="function"
												options={getGlobalOptions(functions, "name")}
												position={false}
												simpleValue={true}
												component={StyledSelect}
												onChange={this.handleFunctionChange}
												onClick={this.handleClickChange}
												showErrors={this.state.showErrors.function}
												toolTipOverlay="Select the function that best describes your
												need and set the seniority that you want your candidates to
												have in that function (this is not a global seniority value)."
												showErrorContainer={true}
											/>
										</div>
										<div className="col-sm-5">
											<Field
												disabled={functionActive}
												name="seniority"
												options={LEVEL}
												position={false}
												component={StyledSelect}
												onClick={this.handleClickChange}
												label="Seniority"
												valueField="label"
												simpleValue={true}
												showErrors={this.state.showErrors.seniority}
												showErrorContainer={true}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-9 col-sm-12">
								<div
									className={
										skillsError
											? "section action-hover skillsError"
											: "section action-hover"
									}
									style={{ minHeight: 180 }}
								>
									{data.skills.length > 0 && (
										<div className="section-edit pull-right">
											<button
												className="btn btn-sm"
												onClick={this.openSkillsModal}
											>
												<i className="fa fa-pencil" />
											</button>
										</div>
									)}
									<div className="section-title">
										<label>
											Skills <GlobalTooltip overlay={skillsTooltip} />
										</label>
									</div>
									<Skills
										skills={data.skills}
										max={12}
										showMoreText={"•••"}
										showLessText={"↑"}
										readLessLinkClassName={"tag link"}
										readMoreLinkClassName={"tag link"}
										renderOption={skill =>
											skill.must_have ? (
												<Tooltip
													placement="top"
													overlay={
														<div>
															<div>
																This skill was marked as a{" "}
																<strong>MUST HAVE</strong>.
															</div>
															Freelancers and/or consultants lacking this skill
															won’t be able to apply.
														</div>
													}
													trigger="hover"
													destroyTooltipOnHide={true}
													mouseLeaveDelay={0}
													overlayClassName="g-tool-white"
													key={skill._id}
												>
													<div className="tag">
														<Star className="must_have_star" />
														{`${skill.name} ( ${skill.score} / 5 )`}
													</div>
												</Tooltip>
											) : (
												<Tooltip
													placement="top"
													overlay={<div>{getSkillsTooltip(skill.score)}</div>}
													trigger="hover"
													destroyTooltipOnHide={true}
													mouseLeaveDelay={0}
													overlayClassName="g-tool-white"
													key={skill._id}
												>
													<div className="tag">
														{`${skill.name} ( ${skill.score} / 5 )`}
													</div>
												</Tooltip>
											)
										}
									/>
									{!data.skills.length && (
										<div className="empty-section">
											<div
												className="add btn-center-block"
												onClick={
													this.props.formFields.category
														? this.openSkillsModal
														: null
												}
											>
												<i className="fa fa-plus" /> Add skills
											</div>
										</div>
									)}
								</div>
								{skillsError && (
									<div className="row">
										<div className="col-md-11">
											<div className="help-block inline-error">
												Value is required and can`t be empty
											</div>
										</div>
									</div>
								)}
								<div className="form-group selectable-control dropdown-profile">
									<Field
										name="languages"
										label="Languages"
										options={languages}
										simpleValue={false}
										position={false}
										itemsLabel="language"
										scorePath="score"
										idPath="_id"
										labelName="name"
										component={EditableList}
										maxScore={MAX_LANGUAGE_WEIGHT}
										onRowChange={this.addLanguage}
										removeSelected={this.removeLanguage}
										tooltipFormater={getLanguageTooltip}
										scoreLabel="PROFICIENCY"
										handleScoreChange={this.handleLanguageScoreChange}
										selectedOptions={data.languages}
										onSelectResetsInput={true}
										placeholder="Begin typing the language you would like to add, then select the closest match from the drop-down list."
										toolTipOverlay="Languages are another fundamental part of the matching process.
										Although they are weighted less than skills in the matching score calculation,
										it is still important to properly outline your linguistic skills."
									/>
								</div>
							</div>
							<div className="col-xs-12">
								<div className="job-action">
									<div className="pull-right">
										<ButtonsContainer>
											<OutlinedLink
												large
												has-icon
												size={14}
												to={this.getListRoute()}
											>
												<Icon moveOnHover className="icon-arrow-left" />
												Back
											</OutlinedLink>
											<Button
												bordered
												large
												size={14}
												disabled={submitting}
												onClick={handleSubmit(this.onSubmitJob)}
											>
												Next step
											</Button>
										</ButtonsContainer>
									</div>
								</div>
							</div>
						</div>
					</LeaveHook>
				</div>
			</BodyClassName>
		);
	}
}

const validate = formProps => {
	const errors = {};
	if (!formProps.group_id) errors.group_id = "Please select a department.";
	if (formProps.category) {
		if (!formProps.function) errors.function = "Please select a function.";
		if (!formProps.seniority) errors.seniority = "Please select a seniority.";
		if (!formProps.skills) errors.skills = "Please select at least one skill.";
		if (!formProps.subcategory) {
			errors.seniority = "Please begin by selecting a subcategory.";
			errors.function = "Please begin by selecting a subcategory.";
			errors.subcategory = "Please select a subcategory.";
		}
	} else {
		errors.category = "Please select a category.";
		errors.subcategory = "Please begin by selecting a category.";
		errors.function = "Please begin by selecting a category.";
		errors.seniority = "Please begin by selecting a category.";
		errors.skills = "Please begin by selecting a category.";
	}

	if (!formProps.languages)
		errors.languages = "Please select at least one language.";

	return errors;
};

export { CTA, CTAIcon };

export default reduxForm({
	form: "createJobTerm",
	validate,
	touchOnBlur: false,
	onSubmitFail: errors => scrollToFirstError(errors)
})(JobProfile);
