import React, { Component } from "react";
import { Element } from "react-scroll";
import _get from "lodash/get";
import Phone from "react-intl-tel-input";
import "libphonenumber-js";
import "react-intl-tel-input/dist/main.css";
import GlobalTooltip from "./GlobalTooltip";
import {
	PREFERRED_COUNTRIES,
	PHONE_DEFAULT_COUNTRY,
	PHONE_DEFAULT_CODE
} from "config";
import { getUserCountryData } from "config/helpers";

class PhoneNumberField extends Component {
	state = {
		value: "",

		defaultCountry: "",
		defaultValue: ""
	};

	async componentDidMount() {
		const data = await getUserCountryData();
		if (data.error) {
			return this.setState({
				defaultCountry: PHONE_DEFAULT_COUNTRY,
				defaultValue: PHONE_DEFAULT_CODE
			});
		}

		this.setState({
			defaultCountry: data.countryCode,
			defaultValue: data.countryNumber
		});
	}

	handler = (isValid, value, countryData, number) => {
		const { input } = this.props;
		value = value.replace(`+${countryData.dialCode}`, "");
		const countryCode = _get(countryData, "iso2", PHONE_DEFAULT_COUNTRY);
		this.setState({ value, defaultCountry: countryCode });
		if (input && input.onChange) {
			return input.onChange({
				status: isValid,
				value,
				countryData,
				number,

				formattedValue: `${countryCode.toUpperCase()} +${
					countryData.dialCode
				}${value}`
			});
		}
	};

	getInitiaValue(position, defaultValue = this.props.defaultValue) {
		const phoneNumber = _get(this.props.input, "value") || defaultValue;
		let number = phoneNumber;
		if (typeof phoneNumber === "object") {
			number = _get(phoneNumber, "formattedValue", "");
		}
		const list = number && number.includes(" ") ? number.split(" ") : [];

		return list.length ? list[position] : number;
	}

	handleInputChange = e => {
		if (!/^\d*$/gi.test(e.target.value)) e.preventDefault();
	};

	render() {
		const {
			input: { name },
			placeholder,
			label,
			labelWithAsterisk = false,
			toolTipOverlay,
			meta: { touched, error },
			classes,
			hasError,
			isErrorBlock = true,
			onBlur,
			disabled
		} = this.props;

		const { value, defaultValue, defaultCountry } = this.state;

		const has_error =
			hasError || (touched && error) ? "has-error has-feedback" : "";

		const hasValue = this.getInitiaValue(0) || defaultValue;

		return (
			<Element
				className={`form-group ${has_error} ${classes}`}
				name={name}
				scrollto="scroll-to-error"
			>
				{label && (
					<label htmlFor={name}>
						{label} {!!labelWithAsterisk && <span className="asterisk">*</span>}
						{toolTipOverlay && (
							<GlobalTooltip maxWidth="475px" overlay={toolTipOverlay} />
						)}
					</label>
				)}
				<div>
					{hasValue && (
						<Phone
							placeholder={placeholder}
							defaultCountry={this.getInitiaValue(0) || defaultCountry}
							preferredCountries={PREFERRED_COUNTRIES}
							onPhoneNumberChange={this.handler}
							css={["intl-tel-input", "form-control"]}
							defaultValue={this.getInitiaValue(1) || `+${defaultValue}`}
							value={value}
							utilsScript={"libphonenumber-js"}
							formatOnInit={false}
							separateDialCode={true}
							ref={ref => (this._ref = ref)}
							onPhoneNumberBlur={onBlur}
							disabled={disabled}
							excludeCountries={["eh"]}
						/>
					)}
				</div>
				{isErrorBlock && (
					<div className="help-block inline-error">{touched && error}</div>
				)}
			</Element>
		);
	}
}

PhoneNumberField.defaultProps = {
	classes: "",
	meta: {},
	input: {},
	placeholder: "Enter phone number",
	hasError: false
};

export default PhoneNumberField;
