import cx from "classnames";
import { CustomTextArea } from "common/CustomTextArea";
import { AdvancedTabCardHeader } from "common/FilterDrawer/AdvancedTabCardHeader";
import React, { memo, useState } from "react";
import icon from "static/icons/error-icon-red.svg";
import { useFilters } from "../hooks/useFilter";
import styles from "./advanced-tabs-keywords-card.module.scss";

const AdvancedTabsKeywordsCard = ({ placeHolder }) => {
	const [displayBody, setDisplayBody] = useState(true);
	const { setQuery, getState, currentFilterId } = useFilters();
	const { query, queryError } = getState(currentFilterId);
	const handleChange = e => {
		setQuery(e.target.value);
	};

	const handleHeaderClick = () => {
		setDisplayBody(displayBody => !displayBody);
	};

	return (
		<div className={styles.container}>
			<div className={styles.container}>
				<AdvancedTabCardHeader
					onClick={handleHeaderClick}
					title="Keywords"
					iconDirection={displayBody ? "bottom" : "right"}
				/>
			</div>
			{displayBody && (
				<div className={styles.textAreaContainer}>
					<CustomTextArea
						className={cx(styles.input, { [styles.inputError]: queryError })}
						value={query}
						onChange={handleChange}
						placeholder={placeHolder}
					/>
					{queryError && (
						<div className={styles.error}>
							<img className={styles.icon} src={icon} alt={""} /> This is not a
							valid boolean search.
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default memo(AdvancedTabsKeywordsCard);
