import React, { useState, useEffect } from "react";
import classnames from "./render-selected-vacancy.module.scss";
import { ReactComponent as AddIcon } from "static/icons/add-icon.svg";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { ReactComponent as ArrowRight } from "static/icons/arrow-right-list-filter-icon.svg";
import { ReactComponent as DeleteRow } from "static/icons/close-icon-tasks.svg";
import ShowMoreModal from "../ShowMoreModal";
import Portal from "common/ListFilter/components/Portal";

const RenderSelectedVacancy = ({
	value,
	clickHandler,
	deleteFilterValue,
	showMoreTitle
}) => {
	const [showMoreVacancy, setShowMoreVacancy] = useState(false);
	const [focusedNode, setfocusedNode] = useState(false);
	const id = uuid();
	useEffect(() => {
		id && setfocusedNode(document.getElementById(id));
	}, [id]);

	let nodeRect = { x: 0, y: 0 };
	if (focusedNode) {
		nodeRect = focusedNode.getBoundingClientRect();
	}

	const openMenu = e => {
		e.stopPropagation();
		clickHandler();
	};
	const openModal = e => {
		e.stopPropagation();
		setShowMoreVacancy(true);
	};
	const closeModal = () => setShowMoreVacancy(false);

	return (
		<button onClick={openMenu} className={classnames.vacancyContainer}>
			<div className={classnames.menuLabel}>
				{value.slice(0, 1).map((val, index) => (
					<div className={classnames.listVacancy} key={get(val, "value")}>
						<span>{get(val, "title", "").slice(0, 20)}</span>
						<button
							onClick={e => {
								e.stopPropagation();
								deleteFilterValue(index);
							}}
						>
							<DeleteRow />
						</button>
					</div>
				))}
				{Boolean(get(value, "length", 0) > 1) && (
					<button onClick={openModal} className={classnames.otherVacancy}>
						+{get(value, "length") - 1}
					</button>
				)}
				<div id={id} className={classnames.positionRelative}></div>
				<div onClick={clickHandler} className={classnames.addVacancy}>
					<AddIcon />
				</div>
			</div>
			<ArrowRight className={classnames.icon} />

			{showMoreVacancy && get(value, "length", 0) && (
				<Portal id={id} nodeRect={nodeRect}>
					<ShowMoreModal
						title={showMoreTitle}
						data={value}
						closeModal={closeModal}
						deleteFilterValue={deleteFilterValue}
					/>
				</Portal>
			)}
		</button>
	);
};

export default RenderSelectedVacancy;
