import React from "react";
import Select, { Async, Creatable } from "react-select";
import Tooltip from "rc-tooltip";
import cx from "classnames";
import { ReactComponent as InfoIcon } from "static/icons/info-icon.svg";
import s from "./select.module.scss";

const SelectField = ({
	value,
	onChange,
	label,
	description,
	options,
	multi,
	labelKey,
	valueKey,
	searchable,
	error,
	isRequired,
	type = "notAsync",
	onSearch,
	className
}) => {
	return (
		<div className={cx(s.root, className)}>
			{label && (
				<label>
					<span>
						{label} {isRequired && <span>*</span>}
					</span>
					{description && (
						<Tooltip
							placement="right"
							overlay={
								<div dangerouslySetInnerHTML={{ __html: description }} />
							}
							trigger="hover"
							destroyTooltipOnHide={true}
							mouseLeaveDelay={0}
							overlayClassName="g-tool-white"
						>
							<InfoIcon />
						</Tooltip>
					)}
				</label>
			)}
			{type === "notAsync" ? (
				<Select
					value={value}
					onChange={onChange}
					options={options}
					labelKey={labelKey}
					valueKey={valueKey}
					searchable={searchable}
					multi={multi}
					className={cx(s.select, {
						[s.selectError]: Boolean(error)
					})}
					autosize={false}
				/>
			) : type === "creatable" ? (
				<Creatable
					value={value}
					onChange={onChange}
					options={options}
					labelKey={labelKey}
					valueKey={valueKey}
					searchable={searchable}
					multi={multi}
					placeholder="Enter values"
					className={cx(s.select, s.creatable, {
						[s.selectError]: Boolean(error)
					})}
					autosize={false}
				/>
			) : (
				<Async
					onChange={onChange}
					loadOptions={onSearch}
					className={cx(s.select, {
						[s.selectError]: Boolean(error)
					})}
					filterOptions={options => {
						return options;
					}}
					autoload={true}
					value={value}
					labelKey={labelKey}
					valueKey={valueKey}
					autosize={false}
				/>
			)}
			{error && <span className={s.error}>{error}</span>}
		</div>
	);
};

export default SelectField;
