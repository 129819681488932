import React, { useEffect } from "react";
import Drawer from "rc-drawer";
import toast from "react-hot-toast";
import get from "lodash/get";
import cx from "classnames";
import Tooltip from "rc-tooltip";
import { Controller, useForm } from "react-hook-form";
import { queryCache } from "react-query";
import { ReactComponent as InfoIcon } from "static/icons/info-icon.svg";
import useAddContractStore from "../../../../store/useAddContractStore";
import { ReactComponent as QuitIcon } from "static/icons/quite-icon.svg";
import s from "./add-contract.module.scss";
import "./addContract.scss";
import SelectField from "../../../../components/SelectField";
import useAddContract from "../../../../api/result/useAddContract";
import Loader from "common/Loader";
import useGetContractDetails from "../../../../api/result/useGetContractDetails";
import toaster from "common/Toaster";
import { convertHTMLtoText, searchParser } from "config/helpers";
import { getListContractQueryKey } from "api/useGetListContract";
import useGetContractsGroupsList from "modules/vonqContract/api/useGetContractsGroupList";
import { ContractCredentialsFieldsList } from "modules/vonqContract/components/ContractCredentialsFieldsList";
import InputField from "modules/vonqContract/components/InputFiled";
import ContractsGroupSelect from "modules/vonqContract/components/ContractsGroupSelect/ContractsGroupSelect";

const AddContractDrawer = () => {
	const { open, reset, channel, setvalue } = useAddContractStore();

	const { data, isLoading } = useGetContractDetails(
		{
			channel_id: channel
		},
		{
			enabled: open
		}
	);

	const {
		control,
		formState: { errors },
		handleSubmit,
		reset: resetForm,
		setError,
		clearErrors,
		watch
	} = useForm();

	const {
		data: contractsGroupsData,
		refetch: refetchContractsGroupsList
	} = useGetContractsGroupsList(
		{
			tag: "filter_groups",
			channel_id: channel
		},
		{ enabled: open }
	);

	const [addContract, { isLoading: isAdding }] = useAddContract({
		onSuccess: () => {
			queryCache.invalidateQueries(getListContractQueryKey);
			refetchContractsGroupsList();
			toaster.success("Contract has successfully been added");
			clearForm();
			reset();
		},
		onError: res => {
			//VONQ API is not stable, we are using all possible cases to extract error messages
			if (res.status === 422) {
				let errorMessages = {};

				try {
					if (res?.errors?.credentials) {
						errorMessages = res.errors.credentials;
					} else if (res.errors && typeof res.errors === "object") {
						errorMessages = res.errors;
					} else if (res.detail && typeof res.detail === "object") {
						errorMessages = res.detail;
					} else if (typeof res.detail === "string") {
						errorMessages = JSON.parse(res.detail).credentials;
					}
					for (const key in errorMessages) {
						let newKey = key === "group_id" ? "group" : key;

						setError(newKey, {
							type: "manual",
							message: Array.isArray(errorMessages[key])
								? errorMessages[key][0]
								: errorMessages[key]
						});
					}
				} catch {
					toast("An error was occurred.", { isFailed: true });
				}
			} else {
				toast(res.title, { isFailed: true });
			}
		}
	});

	useEffect(() => {
		const { channelId } = searchParser();

		if (channelId) {
			setvalue("open", true);
			setvalue("channel", channelId);
		}
	}, []);

	useEffect(() => {
		const { hash, formValues } = searchParser();

		if (hash && data) {
			const oauthCredential = data.contract_credentials?.find(
				({ url, name }) => !!url && name != "feed-random"
			);

			const values = JSON.parse(formValues);
			resetForm({
				...values,
				[`credentials.${oauthCredential.name}`]: hash
			});
		}
	}, [data]);

	const onSubmit = values => {
		const body = {
			...values,
			channel_id: channel,
			group_id: values.group._id
		};

		delete body.group;
		addContract(body);
	};

	const onClose = () => {
		reset();
		clearForm();
	};

	const clearForm = () => {
		const fields = {
			group: "",
			alias: ""
		};

		data.contract_credentials.forEach(field => {
			fields[`credentials.${field.name}`] = "";
		});

		data.contract_facets?.forEach(field => {
			fields[`facets.${field.name}`] = "";
		});

		resetForm(fields);
	};

	const getInstructionsText = () => {
		return (
			<div>
				{convertHTMLtoText(data.setup_instructions)}
				{data.feed_url && <a href={data.feed_url}>URL</a>}
			</div>
		);
	};

	return (
		<Drawer
			open={open}
			width={`650px`}
			height={"100%"}
			placement={"right"}
			style={{ zIndex: 1000 }}
			level={"root"}
			maskClosable={true}
			onClose={onClose}
			classname="addContract"
		>
			{(isAdding || isLoading) && (
				<div className={s.loaderContainer}>
					<Loader />
				</div>
			)}
			<div className={cx(s.container, { [s.isBlur]: isAdding || isLoading })}>
				<div className={s.header}>
					<label>Adding a new contract</label>
					<button onClick={onClose}>
						<QuitIcon />
					</button>
				</div>
				<div className={s.subHeader}>
					Add contract for {get(data, "name", "---")}
				</div>
				{Boolean(get(data, "contract_credentials.length", 0)) && (
					<div className={s.credentialsContainer}>
						<div className={s.descriptionWrapper}>
							<h6>Credentials</h6>
							{data.setup_instructions && (
								<Tooltip
									overlay={
										<div className={s.tooltipOverlay}>
											{getInstructionsText()}
										</div>
									}
									trigger="click"
									destroyTooltipOnHide={true}
									mouseLeaveDelay={0}
									placement="bottom"
									overlayClassName="g-tool-white"
								>
									<InfoIcon />
								</Tooltip>
							)}
						</div>
						<ContractCredentialsFieldsList
							fields={data.contract_credentials}
							control={control}
							errors={errors}
							clearErrors={clearErrors}
							channelId={channel}
							formValues={watch()}
						/>
					</div>
				)}
				{Boolean(get(data, "contract_facets.length", 0)) && (
					<div className={s.credentialsContainer}>
						<h6>Options</h6>
						<div className={s.fieldsContainer}>
							{get(data, "contract_facets", []).map((field, i) =>
								field.type === "SELECT" ? (
									<Controller
										key={i}
										name={`facets.${field.name}`}
										control={control}
										rules={{
											required: field.required
												? "* Required field"
												: field.required
										}}
										render={({ field: { onChange, value } }) => (
											<SelectField
												value={value}
												onChange={({ key }) => {
													onChange(key);
													clearErrors(field.name);
												}}
												label={field.label}
												options={field.options}
												description={field.description}
												labelKey="label"
												valueKey="key"
												isRequired={field.required}
												error={
													get(errors, `facets.${field.name}.message`) ||
													errors[field.name]?.message
												}
											/>
										)}
									/>
								) : (
									<div key={i}>OTHER INPUT </div>
								)
							)}
						</div>
					</div>
				)}

				<div className={s.credentialsContainer}>
					<div className={s.descriptionWrapper}>
						<h6>Other informations</h6>
					</div>
					<div className={s.fieldsContainer}>
						<Controller
							name="alias"
							control={control}
							rules={{
								required: "This field is required."
							}}
							render={({ field: { onChange, value } }) => (
								<InputField
									value={value}
									onChange={e => {
										if (errors["alias"]?.type === "manual") {
											clearErrors("alias");
										}
										onChange(e);
									}}
									type="text"
									label={
										<>
											Alias <span style={{ color: "red" }}>*</span>
										</>
									}
									error={errors["alias"]?.message}
								/>
							)}
						/>
						{contractsGroupsData && (
							<ContractsGroupSelect
								control={control}
								clearErrors={clearErrors}
								contractsGroupsList={contractsGroupsData}
								error={errors["group"]}
							/>
						)}
					</div>
				</div>
				<div className={s.actions}>
					<button className={s.primary} onClick={handleSubmit(onSubmit)}>
						Add contract
					</button>
					<button className={s.secondary} onClick={onClose}>
						Cancel
					</button>
				</div>
			</div>
		</Drawer>
	);
};

export default AddContractDrawer;
