import React from "react";
import { useToggle } from "react-use";
import SizeFilterDialog from "./SizeFilterDialog";
import FilterCard from "../../../shared/FilterCard";
import Portal from "../../../../../common/Portal";

function SizeFilter({ store }) {
	const [on, toggle] = useToggle(false);
	const { size, setSize } = store(state => ({
		size: state.size,
		setSize: state.setSize
	}));
	return (
		<>
			<FilterCard
				name={"Company Size"}
				onEdit={toggle}
				onRemove={() => setSize(undefined)}
				value={size}
			>
				<div style={{ marginTop: "2rem" }}>
					{size?.from} - {size?.to}
				</div>
			</FilterCard>
			<Portal>
				<SizeFilterDialog
					store={store}
					isOpen={on}
					onClose={() => toggle(false)}
				/>
			</Portal>
		</>
	);
}

export default SizeFilter;
