import { useMutation } from "react-query";
import { SEND_REQUEST } from "config/api-endpoints";
import { client } from "lib/api-client";

const sendRequest = data =>
	client(SEND_REQUEST, {
		body: data
	});

export const useSendRequest = (options = {}) => {
	return useMutation(sendRequest, options);
};
