import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { LIST_SUPPLIERS_ENDPOINT } from "config/api-endpoints";
import { WELCOME_PAGE_MY_SUPPLIERS } from "config";
import { mySuppliersListKey } from "modules/home/api/queriesKeys.js";

const listMySuppliers = (key, { start_date, end_date }) =>
	client(LIST_SUPPLIERS_ENDPOINT, {
		body: {
			start_date,
			end_date,
			tag: WELCOME_PAGE_MY_SUPPLIERS
		}
	});

const useMySuppliers = (options, queryOptions = {}) =>
	useQuery([mySuppliersListKey, options], listMySuppliers, {
		refetchOnWindowFocus: false,
		retry: 0,
		staleTime: Infinity,
		...queryOptions
	});
export default useMySuppliers;
