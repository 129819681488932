import React, { Children, cloneElement, useState } from "react";
import { Flex } from "rebass";
import { ListTabs } from "./listTabs";

const Tabs = ({ children, activeTab, setActiveTab }) => {
	const [tabs, setTabs] = useState([]);

	return (
		<Flex flex={1} flexDirection="column" height="100%">
			<Flex px={24} py={2} sx={{ borderBottom: "1px solid #dfe3e8" }}>
				{tabs.map(({ id, title }) => (
					<ListTabs
						key={id}
						clickHandler={() => setActiveTab(id)}
						title={title}
						isActiveTab={id === activeTab}
					/>
				))}
			</Flex>
			{Children.map(children, child =>
				cloneElement(child, { setTabs, activeTab })
			)}
		</Flex>
	);
};

export default Tabs;
