/* eslint-disable react/display-name */

import React, { useEffect } from "react";
import { useTour } from "@reactour/tour";
import { HOW_TO_SOURCE_AND_INVITE_CANDIDATE } from "../../../../config/onboarding";
import styles from "../../components/TourContentComponent/tour-content-component.module.scss";
import TourContentComponent from "../../components/TourContentComponent";
import useOnboardingStore from "../../hooks/useOnboardingStore";
import Portal from "common/Portal";
import { browserHistory } from "react-router";
import isElementLoaded from "../../helpers/isElementLoaded";

const selectorForStep = stepNumber =>
	`[data-onboarding-step=${HOW_TO_SOURCE_AND_INVITE_CANDIDATE}-${stepNumber}]`;

const steps = [
	{
		selector: selectorForStep(0),
		title: "Add candidates",
		description: `This will take you to a new page where you can fill in all the necessary details about the candidate, add the candidate to your list and begin the invitation process`,
		stepInteraction: false,
		position: p => [p.left - 300, p.bottom + 16],
		arrow: "top-right",
		action: element => {
			element.classList.add(styles.highlighted);
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	},
	{
		selector: selectorForStep(1),
		title: "Search & Filter",
		description: `Our search bar and advanced filter options make it effortless for you to find the best candidates that match your job requirements.`,
		stepInteraction: false,
		position: p => [p.left + 300, p.bottom + 16],
		arrow: "top-left",
		action: element => {
			element.classList.add(styles.highlighted);
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	},
	{
		selector: selectorForStep(2),
		title: "Select and quick view profile ",
		description: `The Actions section in the candidate table lets you select multiple candidates and apply actions such as sending invitations or quick viewing resumes...`,
		stepInteraction: false,
		position: p => [p.right + 16, p.bottom - 16],
		arrow: "left-top",
		action: element => {
			element.classList.add(styles.highlighted);
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	},
	{
		selector: selectorForStep(3),
		title: "Process candidates",
		description: `Easily add to vacancy or send vacancy descriptions to your selected candidates, making it quick and easy to invite the top candidates to your vacancy.`,
		stepInteraction: false,
		position: p => [p.left - 420, p.top + 16],
		arrow: "right-top",
		action: element => {
			element.classList.add(styles.highlighted);
			element.querySelector("button").click();
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	},
	{
		selector: selectorForStep(4),
		title: "Process candidates",
		description: `Easily add to vacancy or send vacancy descriptions to your selected candidates, making it quick and easy to invite the top candidates to your vacancy.`,
		stepInteraction: false,
		position: p => [p.left - 416, p.top + 300],
		arrow: "right-top"
	},
	{
		selector: selectorForStep(5),
		title: "Add to vacancy",
		description: `Adding candidate to your vacancy is made even easier with the "Add to Vacancy" button on the candidate details page.`,
		stepInteraction: false,
		position: p => [p.left - 300, p.bottom + 16],
		arrow: "top-right",
		action: element => {
			element.classList.add(styles.highlighted);
		},
		actionAfter: element => {
			element.classList.remove(styles.highlighted);
		}
	}
];

export const useSourceCandidatesTour = (props = {}) => {
	const store = useOnboardingStore();
	const tour = useTour();

	useEffect(() => {
		if (store.workflowId === HOW_TO_SOURCE_AND_INVITE_CANDIDATE) {
			if (!tour.isOpen && store.currentStep === 0) {
				tour.setSteps(steps);
				browserHistory.push("/candidates/search");
			}

			if (!tour.isOpen && store.currentStep === 0) {
				isElementLoaded(selectorForStep(0)).then(() => {
					tour.setCurrentStep(0);
					tour.setIsOpen(true);
				});
			}

			if (store.currentStep === 1 && tour.currentStep === 0) {
				tour.setCurrentStep(1);
			}

			if (store.currentStep === 2 && tour.currentStep === 1) {
				tour.setCurrentStep(2);
			}

			if (
				store.currentStep === 3 &&
				tour.currentStep === 2 &&
				props.checkSecondAndFirstItems
			) {
				props.checkSecondAndFirstItems();
				tour.setCurrentStep(3);
			}

			if (
				store.currentStep === 4 &&
				tour.currentStep === 3 &&
				props.openDrawerShortList
			) {
				props.openDrawerShortList();
				setTimeout(() => {
					tour.setCurrentStep(4);
				}, 300);
			}

			if (store.currentStep === 5 && tour.currentStep === 4) {
				browserHistory.push("/permanent/view/fake-id");
			}

			if (store.currentStep === 5 && tour.currentStep === 4) {
				isElementLoaded(selectorForStep(5)).then(() => {
					tour.setCurrentStep(5);
				});
			}
		}
	}, [
		store.workflowId,
		store.currentStep,
		tour.currentStep,
		tour.isOpen,
		props.isReady
	]);

	return null;
};

export const providerConfig = {
	ContentComponent: TourContentComponent,
	onClickMask: () => null,
	className: styles.popover,
	maskClassName: styles.mask,
	padding: 0,
	scrollSmooth: true,
	disableKeyboardNavigation: true,
	Wrapper: ({ children }) => <Portal>{children}</Portal>
};
